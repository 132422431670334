import { TYPE_ACTIONS } from '@constants/common';
import { ACTIONS, FOLDER, UPLOADSOURCE } from '@constants/content/contentLibrary';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { ConfirmModal } from '@cores/Modal';
import { BlockCreatorRequestDto } from '@models/creator/summary';
import {
	creatorBlock,
	deleteContentRequest,
	moveContentRequest,
	rejectContentRequest,
} from '@stores/actions';
import { isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

type PropTypes = {
	handleSaveData: (val?: boolean) => void;
};

const ConfirmModalAction = (props: PropTypes) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		setIsLoading,
		setTypeConfirm,
		onClose,
		typeConfirm,
		isLoading,
		isAdditionalLoading,
		currentMedia,
		setIsBlockDelete,
		handleClearActionChangeCustomField,
		setIsAdditionalLoading,
	} = useContext(ContentDetailContext);

	const { handleSaveData } = props;
	const isBlock: boolean = typeConfirm === ACTIONS.BLOCK;

	const onConfirmUnblock = () => {
		const requestData: BlockCreatorRequestDto = {
			isBlock: false,
			isDelete: false,
			...renderOtherParamBlock(),
		};

		dispatch(creatorBlock(requestData));
	};

	const onConfirmBlock = (isDelete?: boolean) => {
		setIsBlockDelete(!!isDelete);

		const requestData: BlockCreatorRequestDto = {
			isBlock: true,
			isDelete: !!isDelete,
			...renderOtherParamBlock(),
		};

		if (isDelete) setIsLoading(true);
		else setIsAdditionalLoading(true);

		dispatch(creatorBlock(requestData));
	};

	const renderOtherParamBlock = () => {
		const { uploadSource } = currentMedia || {};
		const listSocialUsername: string[] = [];

		const requestData: any = {
			isAll: false,
			isCalledFromProfile: true,
			requestBody: {
				ids: [],
				listSocialUsername: [],
			},
		};

		if (uploadSource === UPLOADSOURCE.SOCIAL_SEARCH) {
			if (currentMedia && 'social' in currentMedia && !isEmpty(currentMedia.social)) {
				const { socialUsername, socialSource } = currentMedia.social;
				listSocialUsername.push(socialUsername);
				requestData.requestBody.listSocialUsername = listSocialUsername;
				requestData.socialType = socialSource.toUpperCase();
			}
		} else {
			requestData.requestBody.ids = currentMedia?.creatorId ? [currentMedia?.creatorId] : [];
		}
		return requestData;
	};

	const onConfirmDelete = () => {
		dispatch(deleteContentRequest({ contentId: currentMedia?.id }));
	};

	const onConfirmReject = () => {
		dispatch(rejectContentRequest([currentMedia?.id]));
	};

	const onConfirmUnreject = () => {
		const requestData = {
			folder: FOLDER.ALL.toLowerCase(),
			contents: [currentMedia?.id],
			unreject: true,
		};

		dispatch(moveContentRequest(requestData));
	};

	const renderPropOtherConfirmModal = useMemo(() => {
		const result: any = {
			props: { onSubmit: () => onConfirmBlock(true), okText: t('button.yes') },
			content: t('content_management.confirm.block'),
		};
		if (typeConfirm === ACTIONS.UNBLOCK) {
			result.content = t('content_management.confirm.unblock');
			result.props.onSubmit = onConfirmUnblock;
		}
		if (typeConfirm === ACTIONS.DELETE) {
			result.content = t('content_management.confirm.delete');
			result.props.onSubmit = onConfirmDelete;
			result.props.okText = t('button.delete');
		}
		if (typeConfirm === ACTIONS.REJECT) {
			result.content = t('content_management.confirm.move_content_archive');
			result.props.onSubmit = onConfirmReject;
			result.props.okText = t('button.archive');
		}

		if (typeConfirm === ACTIONS.UNREJECT) {
			result.content = t('content_management.confirm.unreject');
			result.props.onSubmit = onConfirmUnreject;
			result.props.okText = t('button.restore');
		}
		if (
			typeConfirm === TYPE_ACTIONS.SAVE_CHANGES ||
			typeConfirm === TYPE_ACTIONS.SAVE_AND_CLOSE ||
			typeConfirm === TYPE_ACTIONS.SAVE_AND_MOVE
		) {
			result.content = t('content_management.confirm.save_field_name_custom');
			result.props.onOtherConfirm = () => handleSaveData();
			result.props.onClickBtnCancel = () => handleSaveData(true);
			result.props.onSubmit = () => handleClearActionChangeCustomField(true);
			result.props.otherConfirmText = t('button.continue');
			result.props.cancelText = t('button.cancel_changes');
			result.props.okText = t('button.no');
			result.props.confirmLoading = false;
			result.props.confirmOtherLoading = isLoading;
			result.props.typeOk = 'ghost';
		}
		return result;
	}, [typeConfirm, isLoading]);

	return (
		<ConfirmModal
			width={isBlock ? 500 : 400}
			confirmLoading={isLoading}
			maskClosable={false}
			confirmOtherLoading={isAdditionalLoading}
			isOpen={!!typeConfirm}
			otherConfirmText={isBlock ? t('button.block_not_delete') : ''}
			onOtherConfirm={() => onConfirmBlock(false)}
			onClickBtnCancel={() => setTypeConfirm('')}
			onClose={onClose}
			{...renderPropOtherConfirmModal.props}>
			{renderPropOtherConfirmModal?.content}
		</ConfirmModal>
	);
};

export default ConfirmModalAction;
