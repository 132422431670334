/**
 * @prettier
 */

import { STATUSCODE } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { TremendousPayloadDto, TremendousStoreDto } from '@models/settings/tremendous/store';

import { Action, handleActions } from 'redux-actions';
import {
	changeFieldTremendousTypes,
	createOrderTremendousTypes,
	fetchTremendousInfoTypes,
	saveTremendousDefaultTypes,
} from './tremendous.types';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';

const initialState: TremendousStoreDto = {
	tremendousDefault: null,
	tremendousInfo: null,

	fetchingTremendous: false,

	saveTremendousDefaultStatus: NONE,
	createOrderTremendousStatus: NONE,

	error: null,
};

const getTremendousInfoRequest = (state: TremendousStoreDto, { payload }: Action<any>) => {
	const other = payload?.isClearAll ? { tremendousDefault: null, tremendousInfo: null } : {};
	return {
		...state,
		fetchingTremendous: true,
		...other,
	};
};

const getTremendousInfoSucceeded = (
	state: TremendousStoreDto,
	{ payload }: Action<TremendousPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload.data;

	const other: any = { tremendousInfo: result };
	if (payload?.isDefault) {
		other.tremendousDefault = result;
	}
	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingTremendous: false,
			error: null,
			...other,
		};
	}

	return {
		...state,
		fetchingTremendous: false,
		error: status,
		...other,
	};
};

const getTremendousInfoFailed = (state: TremendousStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.isDefault) {
		return {
			...state,
			fetchingTremendous: false,
		};
	}
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			fetchingTremendous: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingTremendous: false,
	};
};

// save tremendous default
const saveTremendousDefaultRequest = (state: TremendousStoreDto) => ({
	...state,
	saveTremendousDefaultStatus: IN_PROGRESS,
});

const saveTremendousDefaultSucceeded = (
	state: TremendousStoreDto,
	{ payload }: Action<TremendousPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			saveTremendousDefaultStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		saveTremendousDefaultStatus: FAILED,
		error: status,
	};
};

const saveTremendousDefaultFailed = (
	state: TremendousStoreDto,
	{ payload }: Action<TremendousPayloadDto>,
) => {
	const result = {
		...state,
		saveTremendousDefaultStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const saveTremendousDefaultEnd = (state: TremendousStoreDto) => ({
	...state,
	saveTremendousDefaultStatus: NONE,
});

// create order tremendous
const createOrderTremendousRequest = (state: TremendousStoreDto) => ({
	...state,
	createOrderTremendousStatus: IN_PROGRESS,
});

const createOrderTremendousSucceeded = (
	state: TremendousStoreDto,
	{ payload }: Action<TremendousPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			createOrderTremendousStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		createOrderTremendousStatus: FAILED,
		error: status,
	};
};

const createOrderTremendousFailed = (
	state: TremendousStoreDto,
	{ payload }: Action<TremendousPayloadDto>,
) => {
	const result = {
		...state,
		createOrderTremendousStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const createOrderTremendousEnd = (state: TremendousStoreDto) => ({
	...state,
	createOrderTremendousStatus: NONE,
});

const changeTremendousField = (state: TremendousStoreDto, { payload }: Action<any>) => ({
	...state,
	...payload,
});

const socialSearchReducers = handleActions<any>(
	{
		[fetchTremendousInfoTypes.FETCH_TREMENDOUS_INFO_REQUEST]: getTremendousInfoRequest,
		[fetchTremendousInfoTypes.FETCH_TREMENDOUS_INFO_SUCCEEDED]: getTremendousInfoSucceeded,
		[fetchTremendousInfoTypes.FETCH_TREMENDOUS_INFO_FAILED]: getTremendousInfoFailed,

		[saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_REQUEST]: saveTremendousDefaultRequest,
		[saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_SUCCEEDED]:
			saveTremendousDefaultSucceeded,
		[saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_FAILED]: saveTremendousDefaultFailed,
		[saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_END]: saveTremendousDefaultEnd,

		[createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_REQUEST]: createOrderTremendousRequest,
		[createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_SUCCEEDED]:
			createOrderTremendousSucceeded,
		[createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_FAILED]: createOrderTremendousFailed,
		[createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_END]: createOrderTremendousEnd,

		[changeFieldTremendousTypes.CHANGE_TREMENDOUS_FIELD]: changeTremendousField,
	},
	initialState,
);

export default socialSearchReducers;
