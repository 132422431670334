import THEME from '@constants/themes/themes';
import { Popover } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import { SketchPicker } from 'react-color';
import styled from 'styled-components';

const StyledPopover = styled(Popover)`
	&.popover_custom_color {
		display: flex;
		align-items: center;
		.ant-popover-inner-content {
			padding: 0;
		}
	}
	&.disabled {
		pointer-events: none;
	}
`;

const StyledCustomPickerColor = styled.div`
	&.disabled {
		opacity: 0.6;
	}
	.ant-popover-inner-content {
		padding: 0;
	}
`;

type PropCustomPickerColor = {
	[other: string]: any;
	children: React.ReactNode;
	onChange: (val: string) => void;
	color?: string;
};
const CustomPickerColor: FC<PropCustomPickerColor> = (props) => {
	const { children, color, disabled, onChange, ...other } = props;
	const [colorPicker, setColorPicker] = useState<string | undefined>(color || undefined);

	const onChangeColor = (val: any) => {
		const colorChange =
			val?.rgb?.a === 1
				? val?.hex?.toUpperCase()
				: `rgba(${val?.rgb?.r}, ${val?.rgb?.b}, ${val?.rgb?.g}, ${val?.rgb?.a})`;
		setColorPicker(colorChange);
	};

	useEffect(() => {
		if (color !== colorPicker) {
			setColorPicker(color);
		}
	}, [color]);

	const onChangeComplete = (val: any) => {
		const colorChange =
			val?.rgb?.a === 1
				? val?.hex?.toUpperCase()
				: `rgba(${val?.rgb?.r}, ${val?.rgb?.g}, ${val?.rgb?.b}, ${val?.rgb?.a})`;

		onChange(colorChange);
	};
	return (
		<StyledCustomPickerColor className={disabled ? 'disabled' : ''}>
			<StyledPopover
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				placement="topLeft"
				className={`popover_custom_color ${disabled ? 'disabled' : ''}`}
				title={null}
				content={
					<SketchPicker
						color={color || THEME.colors.orangeBase}
						onChange={onChangeColor}
						onChangeComplete={onChangeComplete}
						{...other}
					/>
				}
				trigger="click">
				{children}
			</StyledPopover>
		</StyledCustomPickerColor>
	);
};

export default CustomPickerColor;
