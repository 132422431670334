import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { OFFERS_REWARD } from '@constants/messages';
import { StyledBtn, StyledTitle } from '@styled/Content/ContentLibrary/ContentStyled';
import { useTranslation } from 'react-i18next';
import THEME from '@constants/themes/themes';
import _ from 'lodash';
import { IconEdit } from '@assets/icons';
import { RewardContainerDto } from '@models/messages/summary';

const StyledAppliedReward = styled.div``;

const StyledTextContainter = styled.div`
	margin-bottom: 16px;
`;

const StyledContent = styled.p`
	margin-top: 16px;
	font-weight: normal !important;

	&:not(.custom) {
		text-transform: none;
	}

	&.custom {
		text-transform: capitalize;
	}
`;

const StyledEditBtn = styled(StyledBtn)`
	margin-top: 24px;

	svg {
		fill: ${THEME.colors.gray5} !important;
	}
`;

type AppliedRewardProps = {
	appliedReward: any;
	handleEditReward: (appliedReward: RewardContainerDto) => void;
};

const AppliedReward = (props: AppliedRewardProps) => {
	const { appliedReward, handleEditReward } = props;
	const { t } = useTranslation();

	const renderAppliedReward = () => {
		if (!_.isEmpty(appliedReward)) {
			const { rewardType, rewardValue } = appliedReward;

			switch (rewardType) {
				case OFFERS_REWARD.DISCOUNT.value:
					if (rewardValue?.discountType === OFFERS_REWARD.PERCENTAGE.value.toUpperCase())
						return renderPercentageOffer();
					if (rewardValue?.discountType === OFFERS_REWARD.FIXED.value.toUpperCase())
						return renderFixOrGiftCardOffer();
					break;

				case OFFERS_REWARD.GIFT_CARD.value:
					return renderFixOrGiftCardOffer();
					break;

				case OFFERS_REWARD.PRODUCT.value:
					return renderProductOffer();
					break;

				default:
					break;
			}
		}

		return null;
	};

	const renderFixOrGiftCardOffer = () => {
		const { rewardValue, rewardType } = appliedReward;
		const isGiftCard = rewardType === OFFERS_REWARD.GIFT_CARD.value;
		const value = isGiftCard ? rewardValue?.estimatedCashValue : rewardValue?.discountAmount;

		return renderText(t('email_builder.amount'), `$ ${value || 0}`);
	};

	const renderProductOffer = () => {
		const {
			rewardValue: {
				discountAmount = 0,
				actualCashValue = 0,
				description = '',
				product = {},
			} = {},
		} = appliedReward;

		return (
			<React.Fragment>
				<Row>
					<Col span={12}>
						{renderText(t('email_builder.amount'), `$ ${discountAmount || 0}`)}
					</Col>
					<Col span={12}>
						{renderText(
							t('email_builder.actual_cash_value'),
							`$ ${actualCashValue}` || 0,
						)}
					</Col>
				</Row>
				{renderText(t('email_builder.product_URL'), product?.webUrl || '')}
				{renderText(t('email_builder.product_description'), description || '')}
			</React.Fragment>
		);
	};

	const renderPercentageOffer = () => {
		const { rewardValue: { estimatedCashValue = 0, actualCashValue = 0 } = {} } = appliedReward;

		return (
			<Row>
				<Col span={12}>
					{renderText(t('email_builder.estimated_cash_value'), `$ ${estimatedCashValue}`)}
				</Col>
				<Col span={12}>
					{renderText(t('email_builder.actual_cash_value'), `$ ${actualCashValue}`)}
				</Col>
			</Row>
		);
	};

	const renderSelectedOffer = () => {
		const {
			rewardType,
			rewardValue: { discountType = '', title = '', estimatedCashValue = 0 } = {},
		} = appliedReward;

		let selectOffer: string = '';

		if (
			discountType === OFFERS_REWARD.FIXED.value.toUpperCase() ||
			discountType === OFFERS_REWARD.PERCENTAGE.value.toUpperCase()
		) {
			selectOffer = `${discountType.toLowerCase()} Discount / ${title}`;
		} else if (rewardType === OFFERS_REWARD.GIFT_CARD.value) {
			selectOffer = `${rewardType} / $ ${estimatedCashValue}`;
		} else {
			selectOffer = `${rewardType} / ${title}`;
		}

		return renderText(t('email_builder.select_offer'), selectOffer);
	};

	const renderText = (title: string, content: string | number) => {
		return (
			<StyledTextContainter>
				<StyledTitle color={THEME.colors.gray1}>{title}</StyledTitle>
				<StyledContent
					className={title === t('email_builder.select_offer') ? 'custom' : ''}>
					{content}
				</StyledContent>
			</StyledTextContainter>
		);
	};

	const editReward = () => {
		handleEditReward(appliedReward);
	};

	return (
		<StyledAppliedReward>
			{!_.isUndefined(appliedReward) && (
				<React.Fragment>
					{renderSelectedOffer()}
					{renderAppliedReward()}
					{appliedReward.rewardType !== OFFERS_REWARD.PRODUCT.value &&
						renderText(
							t('email_builder.discount_code'),
							appliedReward.rewardValue.codeValue,
						)}
					<StyledEditBtn width="80px" icon={<IconEdit />} onClick={editReward}>
						{t('button.edit')}
					</StyledEditBtn>
				</React.Fragment>
			)}
		</StyledAppliedReward>
	);
};

export default AppliedReward;
