import { createAction } from 'redux-actions';
import { CampaignTypes } from './campaign.types';

export const getCampaignListAPI = createAction(CampaignTypes.GET_CAMPAIGN_LIST);
export const getCampaignListAPISuccess = createAction(CampaignTypes.GET_CAMPAIGN_LIST_SUCCESS);
export const getCampaignListAPIFailed = createAction(CampaignTypes.GET_CAMPAIGN_LIST_FAILED);

// fetch detail campaign
export const getDetailCampaignRequest = createAction(CampaignTypes.GET_DETAIL_CAMPAIGN_REQUEST);
export const getDetailCampaignSuccess = createAction(CampaignTypes.GET_DETAIL_CAMPAIGN_SUCCESS);
export const getDetailCampaignFailed = createAction(CampaignTypes.GET_DETAIL_CAMPAIGN_FAILED);

// save campaign detail
export const saveCampaignDetail = createAction(CampaignTypes.SAVE_CAMPAIGN_DETAIL);

export const createCampaignAPIRequest = createAction(CampaignTypes.CREATE_CAMPAIGN_REQUEST);
export const createCampaignAPISuccess = createAction(CampaignTypes.CREATE_CAMPAIGN_SUCCESS);
export const createCampaignAPIFailed = createAction(CampaignTypes.CREATE_CAMPAIGN_FAILED);
export const createCampaignAPIEnded = createAction(CampaignTypes.CREATE_CAMPAIGN_ENDED);

export const updateCampaignRequest = createAction(CampaignTypes.UPDATE_CAMPAIGN_REQUEST);
export const updateCampaignSuccess = createAction(CampaignTypes.UPDATE_CAMPAIGN_SUCCESS);
export const updateCampaignFailed = createAction(CampaignTypes.UPDATE_CAMPAIGN_FAILED);
export const updateCampaignEnded = createAction(CampaignTypes.UPDATE_CAMPAIGN_ENDED);

// suggest img campaign
export const suggestImgCampaignRequest = createAction(CampaignTypes.SUGGEST_IMG_CAMPAIGN_REQUEST);
export const suggestImgCampaignSuccess = createAction(CampaignTypes.SUGGEST_IMG_CAMPAIGN_SUCCESS);
export const suggestImgCampaignFailed = createAction(CampaignTypes.SUGGEST_IMG_CAMPAIGN_FAILED);
export const suggestImgCampaignEnded = createAction(CampaignTypes.SUGGEST_IMG_CAMPAIGN_ENDED);

// upload imgs campaign
export const uploadImgsCampaignRequest = createAction(CampaignTypes.UPLOAD_IMGS_CAMPAIGN_REQUEST);
export const uploadImgsCampaignSuccess = createAction(CampaignTypes.UPLOAD_IMGS_CAMPAIGN_SUCCESS);
export const uploadImgsCampaignFailed = createAction(CampaignTypes.UPLOAD_IMGS_CAMPAIGN_FAILED);
export const uploadImgsCampaignEnded = createAction(CampaignTypes.UPLOAD_IMGS_CAMPAIGN_ENDED);

export const getListContentCampaignAPI = createAction(CampaignTypes.GET_LIST_CONTENT_CAMPAIGN);
export const getListContentCampaignAPISuccess = createAction(
	CampaignTypes.GET_LIST_CONTENT_CAMPAIGN_SUCCESS,
);
export const getListContentCampaignAPIFailed = createAction(
	CampaignTypes.GET_LIST_CONTENT_CAMPAIGN_FAILED,
);
export const deleteCampaignAPIRequest = createAction(CampaignTypes.DELETE_CAMPAIGN_REQUEST);
export const deleteCampaignAPISuccess = createAction(CampaignTypes.DELETE_CAMPAIGN_SUCCESS);
export const deleteCampaignAPIFailed = createAction(CampaignTypes.DELETE_CAMPAIGN_FAILED);
export const deleteCampaignAPIEnded = createAction(CampaignTypes.DELETE_CAMPAIGN_ENDED);

export const getRecommendationCampaignRequest = createAction(
	CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_REQUEST,
);
export const getRecommendationCampaignSucceeded = createAction(
	CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_SUCCEEDED,
);
export const getRecommendationCampaignFailed = createAction(
	CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_FAILED,
);
export const getRecommendationCampaignEnd = createAction(
	CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_END,
);

export const updateCampaignLandingPageRequest = createAction(
	CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_REQUEST,
);
export const updateCampaignLandingPageSucceeded = createAction(
	CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_SUCCEEDED,
);
export const updateCampaignLandingPageFailed = createAction(
	CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_FAILED,
);
export const updateCampaignLandingPageEnd = createAction(
	CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_END,
);

export const storeCampaignDataRequest = createAction(CampaignTypes.STORE_CAMPAIGN_DATA);
