const createTemplateTypes = {
	CREATE_TEMPLATE_REQUEST: 'CREATE_TEMPLATE_REQUEST',
	CREATE_TEMPLATE_SUCCEEDED: 'CREATE_TEMPLATE_SUCCEEDED',
	CREATE_TEMPLATE_FAILED: 'CREATE_TEMPLATE_FAILED',
	CREATE_TEMPLATE_END: 'CREATE_TEMPLATE_END',
};

const updateTemplateTermsTypes = {
	UPDATE_TEMPLATE_TERMS_REQUEST: 'UPDATE_TEMPLATE_TERMS_REQUEST',
	UPDATE_TEMPLATE_TERMS_SUCCEEDED: 'UPDATE_TEMPLATE_TERMS_SUCCEEDED',
	UPDATE_TEMPLATE_TERMS_FAILED: 'UPDATE_TEMPLATE_TERMS_FAILED',
	UPDATE_TEMPLATE_TERMS_END: 'UPDATE_TEMPLATE_TERMS_END',
};

const fetchDetailTemplateTypes = {
	FETCH_DETAIL_TEMPLATE_REQUEST: 'FETCH_DETAIL_TEMPLATE_REQUEST',
	FETCH_DETAIL_TEMPLATE_SUCCEEDED: 'FETCH_DETAIL_TEMPLATE_SUCCEEDED',
	FETCH_DETAIL_TEMPLATE_FAILED: 'FETCH_DETAIL_TEMPLATE_FAILED',
};

const fetchListTemplateTypes = {
	FETCH_LIST_TEMPLATE_REQUEST: 'FETCH_LIST_TEMPLATE_REQUEST',
	FETCH_LIST_TEMPLATE_SUCCEEDED: 'FETCH_LIST_TEMPLATE_SUCCEEDED',
	FETCH_LIST_TEMPLATE_FAILED: 'FETCH_LIST_TEMPLATE_FAILED',
};

const fetchTemplateHistoryTypes = {
	FETCH_TEMPLATE_HISTORY_REQUEST: 'FETCH_TEMPLATE_HISTORY_REQUEST',
	FETCH_TEMPLATE_HISTORY_SUCCEEDED: 'FETCH_TEMPLATE_HISTORY_SUCCEEDED',
	FETCH_TEMPLATE_HISTORY_FAILED: 'FETCH_TEMPLATE_HISTORY_FAILED',
};

const fetchWidgetInTemplateTypes = {
	FETCH_WIDGET_IN_TEMPLATE_REQUEST: 'FETCH_WIDGET_IN_TEMPLATE_REQUEST',
	FETCH_WIDGET_IN_TEMPLATE_SUCCEEDED: 'FETCH_WIDGET_IN_TEMPLATE_SUCCEEDED',
	FETCH_WIDGET_IN_TEMPLATE_FAILED: 'FETCH_WIDGET_IN_TEMPLATE_FAILED',
};

const fetchListWidgetLiveTypes = {
	FETCH_LIST_WIDGET_LIVE_TEMPLATE_REQUEST: 'FETCH_LIST_WIDGET_LIVE_TEMPLATE_REQUEST',
	FETCH_LIST_WIDGET_LIVE_TEMPLATE_SUCCEEDED: 'FETCH_LIST_WIDGET_LIVE_TEMPLATE_SUCCEEDED',
	FETCH_LIST_WIDGET_LIVE_TEMPLATE_FAILED: 'FETCH_LIST_WIDGET_LIVE_TEMPLATE_FAILED',
};

const fetchEntribeStandardTermsTypes = {
	FETCH_ENTRIBE_STANDARD_TERMS_REQUEST: 'FETCH_ENTRIBE_STANDARD_TERMS_REQUEST',
	FETCH_ENTRIBE_STANDARD_TERMS_SUCCEEDED: 'FETCH_ENTRIBE_STANDARD_TERMS_SUCCEEDED',
	FETCH_ENTRIBE_STANDARD_TERMS_FAILED: 'FETCH_ENTRIBE_STANDARD_TERMS_FAILED',
};

const changeTemplateToWidgetsTypes = {
	CHANGE_TEMPLATE_TO_WIDGETS_REQUEST: 'CHANGE_TEMPLATE_TO_WIDGETS_REQUEST',
	CHANGE_TEMPLATE_TO_WIDGETS_SUCCEEDED: 'CHANGE_TEMPLATE_TO_WIDGETS_SUCCEEDED',
	CHANGE_TEMPLATE_TO_WIDGETS_FAILED: 'CHANGE_TEMPLATE_TO_WIDGETS_FAILED',
	CHANGE_TEMPLATE_TO_WIDGETS_END: 'CHANGE_TEMPLATE_TO_WIDGETS_END',
};

const changeTemplateStatusTypes = {
	CHANGE_TEMPLATE_STATUS_REQUEST: 'CHANGE_TEMPLATE_STATUS_REQUEST',
	CHANGE_TEMPLATE_STATUS_SUCCEEDED: 'CHANGE_TEMPLATE_STATUS_SUCCEEDED',
	CHANGE_TEMPLATE_STATUS_FAILED: 'CHANGE_TEMPLATE_STATUS_FAILED',
	CHANGE_TEMPLATE_STATUS_END: 'CHANGE_TEMPLATE_STATUS_END',
};

const duplicateTemplateTermsTypes = {
	DUPLICATE_TEMPLATE_TERMS_REQUEST: 'DUPLICATE_TEMPLATE_TERMS_REQUEST',
	DUPLICATE_TEMPLATE_TERMS_SUCCEEDED: 'DUPLICATE_TEMPLATE_TERMS_SUCCEEDED',
	DUPLICATE_TEMPLATE_TERMS_FAILED: 'DUPLICATE_TEMPLATE_TERMS_FAILED',
	DUPLICATE_TEMPLATE_TERMS_END: 'DUPLICATE_TEMPLATE_TERMS_END',
};

const changeDefaultTemplateTypes = {
	CHANGE_DEFAULT_TEMPLATE_REQUEST: 'CHANGE_DEFAULT_TEMPLATE_REQUEST',
	CHANGE_DEFAULT_TEMPLATE_SUCCEEDED: 'CHANGE_DEFAULT_TEMPLATE_SUCCEEDED',
	CHANGE_DEFAULT_TEMPLATE_FAILED: 'CHANGE_DEFAULT_TEMPLATE_FAILED',
	CHANGE_DEFAULT_TEMPLATE_END: 'CHANGE_DEFAULT_TEMPLATE_END',
};

const fetchTemplateWidgetTypes = {
	FETCH_TEMPLATE_WIDGET_REQUEST: 'FETCH_TEMPLATE_WIDGET_REQUEST',
	FETCH_TEMPLATE_WIDGET_SUCCEEDED: 'FETCH_TEMPLATE_WIDGET_SUCCEEDED',
	FETCH_TEMPLATE_WIDGET_FAILED: 'FETCH_TEMPLATE_WIDGET_FAILED',
};

const fetchTemplateDefaultTypes = {
	FETCH_TEMPLATE_DEFAULT_REQUEST: 'FETCH_TEMPLATE_DEFAULT_REQUEST',
	FETCH_TEMPLATE_DEFAULT_SUCCEEDED: 'FETCH_TEMPLATE_DEFAULT_SUCCEEDED',
	FETCH_TEMPLATE_DEFAULT_FAILED: 'FETCH_TEMPLATE_DEFAULT_FAILED',
};

const changeDataStoreTypes = {
	CHANGE_FIELD_DATA_CONTENT_LICENSING: 'CHANGE_FIELD_DATA_CONTENT_LICENSING',
	RESET_STORE_CONTENT_LICENSING: 'RESET_STORE_CONTENT_LICENSING',
};

export {
	createTemplateTypes,
	changeDataStoreTypes,
	fetchDetailTemplateTypes,
	fetchListTemplateTypes,
	fetchTemplateHistoryTypes,
	fetchWidgetInTemplateTypes,
	updateTemplateTermsTypes,
	fetchListWidgetLiveTypes,
	changeTemplateToWidgetsTypes,
	fetchEntribeStandardTermsTypes,
	changeTemplateStatusTypes,
	duplicateTemplateTermsTypes,
	changeDefaultTemplateTypes,
	fetchTemplateWidgetTypes,
	fetchTemplateDefaultTypes,
};
