import { MAX_SIZE_LOGO } from '@constants/content/widgetManager';
import { SizeImageDto } from '@models/content/widgetManager';
import { StyledHeader, StyledLogo } from '@styled/Content/WidgetManager/DetailWidgetStyled';
import React, { useMemo } from 'react';

type HeaderPropDto = {
	srcLogo?: string;
	sizeLogo?: SizeImageDto;
	isMobile?: boolean;
};

const HeaderWidgetDefault = (props: HeaderPropDto) => {
	const { srcLogo, sizeLogo, isMobile } = props;

	const widthImg = useMemo(() => {
		const result = isMobile ? sizeLogo?.widthLogoMobile : sizeLogo?.widthLogoDesktop;
		return result || result === 0 ? `${result}px` : 'unset';
	}, [sizeLogo?.widthLogoDesktop, sizeLogo?.widthLogoMobile, isMobile]);

	return (
		<StyledHeader>
			{srcLogo && <StyledLogo width={widthImg} src={srcLogo} alt="logo" />}
		</StyledHeader>
	);
};

export default HeaderWidgetDefault;
