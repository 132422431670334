import { IconLinkUpload } from '@assets/icons';
import { ImageContentCampaignDefault } from '@assets/images';
import CampaignDetailContext from '@contexts/Campaign/CampaignDetail';
import {
	StyledBtnStatic,
	StyledDescMockup,
	StyledFooterStatic,
	StyledFrameLarge,
	StyledFrameSmall,
	StyledImageIcon,
	StyledImgMockup,
	StyledStaticBtnIcon,
	StyledTitleMockup,
	StyledWrapperBox,
	StyledWrapperBtnStatic,
	StyledWrapperContainerMockup,
	StyledWrapperFooterStatic,
	StyledWrapperImgMockup,
} from '@styled/Campaign/CampaignDetailStyled';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { renderHashtag } from '@utils/campaign';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
	header: string;
	description: string;
	url1?: string;
	url2?: string;
	url3?: string;
};
const MockupPost = (props: PropTypes) => {
	const { header, description, url1, url2, url3 } = props;
	const { t } = useTranslation();
	const { hashtagFooter } = useContext(CampaignDetailContext);

	const listImg = [
		{
			id: 1,
			className: 'img_1',
			url: url1,
		},
		{
			id: 2,
			className: 'img_2',
			url: url2,
		},
		{
			id: 3,
			className: 'img_3',
			url: url3,
		},
	];

	return (
		<StyledWrapperContainerMockup id="mockup_post">
			<StyledWrapperBox className="post">
				{listImg?.map((item) => (
					<StyledFrameSmall key={item?.id} className={item?.className}>
						<StyledWrapperImgMockup>
							{
								<StyledImgMockup
									src={item?.url || ImageContentCampaignDefault}
									alt={item?.className}
								/>
							}
						</StyledWrapperImgMockup>
					</StyledFrameSmall>
				))}
			</StyledWrapperBox>
			<StyledTitleMockup>
				{header || t('campaign_detail.placeholder.header_cta')}
			</StyledTitleMockup>

			<StyledDescMockup style={{ marginTop: '8px' }}>
				{description || t('campaign_detail.placeholder.body_cta')}
			</StyledDescMockup>
			<StyledWrapperFooterStatic>
				<StyledWrapperBtnStatic>
					<StyledStaticBtnIcon>
						<StyledBtnStatic>
							<StyledIcon margin="0 5px 0 0">
								<IconLinkUpload />
							</StyledIcon>
							{t('button.upload_here')}
						</StyledBtnStatic>
					</StyledStaticBtnIcon>
				</StyledWrapperBtnStatic>
				{!!hashtagFooter.length && (
					<StyledFooterStatic>
						{t('campaign_detail.or_tag_footer', { tag: renderHashtag(hashtagFooter) })}
					</StyledFooterStatic>
				)}
			</StyledWrapperFooterStatic>
		</StyledWrapperContainerMockup>
	);
};

export default MockupPost;
