import THEME from '@constants/themes/themes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledWrapperSwitch = styled.div<{ width?: string }>`
	position: relative;
	display: inline-flex;
	height: 26px;
	align-items: center;
	cursor: pointer;
	&.disabled {
		pointer-events: none;
	}
`;

const StyledBgDefault = styled.div<{ width?: string }>`
	width: ${({ width }) => width || '72px'};
	height: 24px;
	background: ${THEME.colors.darkBlue3};
	border: 1px solid transparent;
	border-radius: 2px;
`;

const StyledSwitchIcon = styled.div<{ checked?: boolean; width?: string; toggleWidth?: string }>`
	position: absolute;
	top: 0;
	bottom: 0;
	cursor: pointer;
	border-radius: 2px;
	width: ${({ toggleWidth }) => toggleWidth || '40px'};
	height: 26px;
	transition: 0.3s ease-out;
	transform: translateX(0px);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	${({ checked, width }) => checked && `transform: translateX(${width || '32px'})`};
	background: ${({ checked }) => (checked ? THEME.colors.orangeBase : THEME.colors.gray1)};
`;
type PropSwitch = {
	checked: boolean;
	setChecked: (val: boolean) => void;
	disabled?: boolean;
	className?: string;
	text?: {
		on?: string;
		off?: string;
	};
	[key: string]: any;
};

const CustomSwitch = (props: PropSwitch) => {
	const { t } = useTranslation();

	const {
		checked,
		setChecked,
		disabled = false,
		className,
		text: { on = t('button.on'), off = t('button.off') } = {},
		otherSwitchProps,
	} = props;

	const textChecked = useMemo(() => {
		return checked ? on : off;
	}, [checked]);

	return (
		<StyledWrapperSwitch
			className={`${className} ${disabled ? 'disabled' : ''}`}
			onClick={() => setChecked(!checked)}>
			<StyledBgDefault width={otherSwitchProps?.totalWidth} />
			<StyledSwitchIcon checked={checked} {...otherSwitchProps}>
				{textChecked}
			</StyledSwitchIcon>
		</StyledWrapperSwitch>
	);
};

export default CustomSwitch;
