import { createAction } from 'redux-actions';
import {
	changeFieldTremendousTypes,
	createOrderTremendousTypes,
	fetchTremendousInfoTypes,
	saveTremendousDefaultTypes,
} from './tremendous.types';

// Fetch function
export const fetchTremendousInfoRequest = createAction(
	fetchTremendousInfoTypes.FETCH_TREMENDOUS_INFO_REQUEST,
);
export const fetchTremendousInfoSucceeded = createAction(
	fetchTremendousInfoTypes.FETCH_TREMENDOUS_INFO_SUCCEEDED,
);
export const fetchTremendousInfoFailed = createAction(
	fetchTremendousInfoTypes.FETCH_TREMENDOUS_INFO_FAILED,
);

// Save tremendous default
export const saveTremendousDefaultRequest = createAction(
	saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_REQUEST,
);
export const saveTremendousDefaultSucceeded = createAction(
	saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_SUCCEEDED,
);
export const saveTremendousDefaultFailed = createAction(
	saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_FAILED,
);
export const saveTremendousDefaultEnd = createAction(
	saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_END,
);

// Create order tremendous
export const createOrderTremendousRequest = createAction(
	createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_REQUEST,
);
export const createOrderTremendousSucceeded = createAction(
	createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_SUCCEEDED,
);
export const createOrderTremendousFailed = createAction(
	createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_FAILED,
);
export const createOrderTremendousEnd = createAction(
	createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_END,
);

// change field tremendous
export const changeTremendousField = createAction(
	changeFieldTremendousTypes.CHANGE_TREMENDOUS_FIELD,
);
