import { IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@assets/icons';
import ContentDetail from '@components/ContentGlobal/ContentDetail';
import SocialDetails from '@components/SocialSearch/SocialDetails';
import { KEY_MESSAGE, MOVE_CONTENT } from '@constants/content/contentLibrary';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { LoadingWrapper } from '@cores/index';
import { ContentDetailRef } from '@models/content/contentLibrary/ref';
import {
	ContentRootStateDto,
	ContentStoreDto,
	ImgVersionDto,
} from '@models/content/contentLibrary/store';
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
	ContentVerificationScoreDto,
} from '@models/content/contentLibrary/summary';
import { SocialDetailRef } from '@models/socialsearch/ref';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { createImgVersionEnd, reviewContentEnd, updateImgVersionEnd } from '@stores/actions';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import {
	StyledModal,
	StyledSlideControl,
} from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type SocialProps = {
	socialContent: SocialContentContainerDto | null;
	activeSocialType: string;
	updateMediaImgUrl: (id: number, imgUrl: string, idConvert: string) => void;
	socialBlockCreatorDisable?: boolean;
	socialBookmarkDisable?: boolean;
	pauseNextContentAfterImport?: boolean;
	pauseNextContentAfterBlockContent?: boolean;
	idFeed?: number | null;
};

type ContentCDMProps = {
	content?: ContentContainerDto | null;
	onChangeAfterAction?: (
		action: string,
		contentsAfterAction?: number[],
		content?: ContentReviewDto,
	) => void;
	onChangeAfterBlock?: (currentMedia: ContentDto, isDelete: boolean, isBlock: boolean) => void;
	updateAfterReviewingContent: (reviewedContents: ContentReviewDto[]) => void;
	updateVerificationScrore?: (
		content: ContentDto,
		verificationScore: ContentVerificationScoreDto,
	) => void;
	folderId?: number | string;
	markAsFavoriteContent?: (content: ContentDto) => void;
	setContentIdMove?: (val: number | null) => void;
	contentDetailDto?: ContentDto;
	updateAfterChangeVersion?: (contentId: number, version: ImgVersionDto) => void;
	CDMBlockCreatorDisabled?: boolean;
	isDashboardAmbassador?: boolean;
	handleChangeFieldContent?: (val: any, id: number) => void;
	enableRotateImage?: boolean;
};
type PropTypes = {
	visible: boolean;
	onCancel: () => void;
	isSocial?: boolean;
	isFirstContent: boolean;
	isLastContent: boolean;
	moveContent: (action: string, changeIndex?: boolean) => void;
	socialProps?: SocialProps;
	contentProps?: ContentCDMProps;
};

const ModalMediaDetail = (props: PropTypes) => {
	const {
		visible,
		onCancel,
		isSocial = false,
		isFirstContent,
		isLastContent,
		moveContent,
		contentProps,
		socialProps,
	} = props;

	const {
		activeSocialType = '',
		socialContent,
		updateMediaImgUrl,
		pauseNextContentAfterBlockContent,
		pauseNextContentAfterImport,
		socialBlockCreatorDisable,
		socialBookmarkDisable,
		idFeed,
	} = socialProps || {};

	const {
		content,
		updateAfterReviewingContent,
		CDMBlockCreatorDisabled,
		folderId,
		contentDetailDto,
		markAsFavoriteContent,
		onChangeAfterAction,
		onChangeAfterBlock,
		setContentIdMove,
		updateAfterChangeVersion,
		updateVerificationScrore,
		isDashboardAmbassador,
		handleChangeFieldContent,
		enableRotateImage,
	} = contentProps || {};

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [idContentUpdate, setIdContentUpdate] = useState<number | undefined>(undefined);

	const {
		reviewContentStatus,
		reviewedContent,
		updateImgVersionStatus,
		idContentVersion,
		imgVersionDetail,
		createImgVersionStatus,
	}: ContentStoreDto = useSelector((state: ContentRootStateDto) => state.content);

	// effect
	useEffect(() => {
		if (reviewContentStatus === SUCCEEDED && reviewedContent) {
			handleAfterReview([reviewedContent]);
		}

		if (reviewContentStatus === FAILED || reviewContentStatus === SUCCEEDED) {
			dispatch(reviewContentEnd());
		}
	}, [reviewContentStatus]);

	useEffect(() => {
		const listOf = [createImgVersionStatus, updateImgVersionStatus];
		if (listOf.includes(SUCCEEDED) && imgVersionDetail) {
			if (
				updateAfterChangeVersion &&
				typeof updateAfterChangeVersion === 'function' &&
				idContentVersion &&
				imgVersionDetail.isDefault
			) {
				updateAfterChangeVersion(idContentVersion, imgVersionDetail);
			}
		}
		if (listOf.includes(SUCCEEDED) || listOf.includes(FAILED)) {
			if (createImgVersionStatus === NONE) {
				dispatch(updateImgVersionEnd());
			} else {
				dispatch(createImgVersionEnd());
			}
			setIdContentUpdate(undefined);
		}
	}, [updateImgVersionStatus, createImgVersionStatus]);

	// ref
	const refContentDetail = useRef<ContentDetailRef | null>(null);
	const refSocialDetail = useRef<SocialDetailRef | null>(null);
	const handleCancel = async () => {
		if (isSocial) {
			if (socialContent && refSocialDetail?.current?.handleCancelModal) {
				refSocialDetail.current.handleCancelModal();
			}
		} else {
			if ((content || contentDetailDto) && refContentDetail?.current?.handleCancelModal) {
				await refContentDetail.current.handleCancelModal();
			}
		}

		if (
			!isSocial &&
			(content || contentDetailDto) &&
			refContentDetail?.current?.isBlockCancel &&
			refContentDetail.current.isBlockCancel()
		) {
			return;
		}
		await onCancel();
	};

	// func social
	const handleUpdateImgUrl = (id: number, imgUrl: string, idConvert: string) => {
		if (updateMediaImgUrl && typeof updateMediaImgUrl === 'function') {
			updateMediaImgUrl(id, imgUrl, idConvert);
		}
	};
	// func content
	const changeIdMove = (val: number | null) => {
		if (setContentIdMove && typeof setContentIdMove === 'function') {
			setContentIdMove(val);
		}
	};

	const handleAfterReview = (reviewedContents: ContentReviewDto[]) => {
		if (updateAfterReviewingContent && typeof updateAfterReviewingContent === 'function') {
			updateAfterReviewingContent(reviewedContents);
		}
	};

	const handleAfterAction = (
		action: string,
		contentsAfterAction?: number[],
		content?: ContentReviewDto,
	) => {
		if (onChangeAfterAction && typeof onChangeAfterAction === 'function') {
			onChangeAfterAction(action, contentsAfterAction, content);
		}
	};

	const handleMoveContent = (action: string) => {
		if (isSocial) {
			if (socialContent && refSocialDetail?.current?.handleMoveContent) {
				refSocialDetail.current.handleMoveContent(action);
			}
		} else {
			if ((content || contentDetailDto) && refContentDetail?.current?.handleMoveContent) {
				refContentDetail.current.handleMoveContent(action);
			}
		}
	};

	return (
		<StyledModal
			className="modal-content-detail"
			wrapClassName="modal_wrapper_global_content"
			centered={true}
			visible={visible}
			onCancel={handleCancel}
			closable={false}
			footer={null}
			destroyOnClose={true}
			keyboard={false}>
			{!isFirstContent && (
				<StyledSlideControl
					positionArrow="left"
					onClick={() => handleMoveContent(MOVE_CONTENT.PREVIOUS)}>
					<IconKeyboardArrowLeft />
				</StyledSlideControl>
			)}

			{!isLastContent && (
				<StyledSlideControl
					positionArrow="right"
					onClick={() => handleMoveContent(MOVE_CONTENT.NEXT)}>
					<IconKeyboardArrowRight />
				</StyledSlideControl>
			)}
			<StyledWrapperContent width="100%" height="100%">
				{isSocial && socialContent && (
					<SocialDetails
						activeSocialType={activeSocialType}
						handleCancel={onCancel}
						isFirstContent={isFirstContent}
						isLastContent={isLastContent}
						isVisible={visible}
						ref={refSocialDetail}
						moveContent={moveContent}
						socialContent={socialContent}
						updateMediaImgUrl={handleUpdateImgUrl}
						idFeed={idFeed}
						pauseNextContentAfterImport={pauseNextContentAfterImport}
						socialBlockCreatorDisable={socialBlockCreatorDisable}
						socialBookmarkDisable={socialBookmarkDisable}
						pauseNextContentAfterBlockContent={pauseNextContentAfterBlockContent}
					/>
				)}
				{!isSocial && (content || contentDetailDto) && (
					<ContentDetail
						content={content || null}
						handleCancel={onCancel}
						isFirstContent={isFirstContent}
						isLastContent={isLastContent}
						isVisible={visible}
						ref={refContentDetail}
						moveContent={moveContent}
						onChangeAfterAction={handleAfterAction}
						setContentIdMove={changeIdMove}
						updateAfterReviewingContent={handleAfterReview}
						folderId={folderId}
						handleChangeFieldContent={handleChangeFieldContent}
						contentDetailDto={contentDetailDto}
						markAsFavoriteContent={markAsFavoriteContent}
						onChangeAfterBlock={onChangeAfterBlock}
						updateAfterChangeVersion={updateAfterChangeVersion}
						enableRotateImage={enableRotateImage}
						updateVerificationScrore={updateVerificationScrore}
						CDMBlockCreatorDisabled={CDMBlockCreatorDisabled}
						isDashboardAmbassador={isDashboardAmbassador}
						setIdContentUpdate={setIdContentUpdate}
						idContentUpdate={idContentUpdate}
					/>
				)}
			</StyledWrapperContent>
		</StyledModal>
	);
};

export default ModalMediaDetail;
