import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { OPTION_CALL_TO_ACTION, TOTAL_ROW, TYPE_THEME } from '@constants/publish/gallery';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { StyledImageTheme } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledBlockThemeMosaic } from '@styled/Publish/Gallery/MosaicGalleryStyled';
import { StyledWrapperThumbnail } from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import { checkExistExplicit } from '@utils/content';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ImageTheme from './ImageTheme';

type PropBlockThemeMosaic = {
	listContent: ContentDto[];
	idxFull: number | undefined;
	renderIconVideo: (val: ContentDto) => ReactNode;
	handleSelectContent: (val: ContentDto) => void;
	cursor: string;
	renderMarkHover: (val: ContentDto) => JSX.Element | null;
	isGif: boolean;
	metadata: any;
	heightThumb: number;
	isScreenSmall: boolean;
};

const BlockThemeMosaic = (props: PropBlockThemeMosaic) => {
	const { t } = useTranslation();
	const {
		listContent = [],
		idxFull,
		renderIconVideo,
		handleSelectContent,
		cursor,
		renderMarkHover,
		isGif,
		metadata,
		heightThumb,
		isScreenSmall,
	} = props;

	return (
		<StyledBlockThemeMosaic
			className={isScreenSmall ? 'block_small' : ''}
			height={heightThumb}
			theme={metadata?.theme}>
			{listContent?.map((item: ContentDto, idx: number) => {
				if (
					metadata?.theme === TYPE_THEME.SIGNAGE &&
					item.name === OPTION_CALL_TO_ACTION.QRCode &&
					item.contentUrl === ''
				) {
					return (
						<>
							<StyledWrapperThumbnail
								key={item?.id}
								keyStart={idxFull ? idxFull / TOTAL_ROW.MOSAIC + 1 : undefined}
								cursor={cursor}
								className={`${idxFull === idx ? 'item_full' : ''}`}>
								<StyledImageTheme src={item.thumbnail} alt="imgTheme" />
								{checkExistExplicit(item?.review?.flags) && (
									<ExplicitContent
										zIndex={3335}
										sizeIcon={idxFull === idx ? 72 : 48}
										fontSize={idxFull === idx ? '16px' : '14px'}
									/>
								)}
							</StyledWrapperThumbnail>
						</>
					);
				}

				return (
					<>
						<StyledWrapperThumbnail
							onClick={() => handleSelectContent(item)}
							key={item?.id}
							keyStart={idxFull ? idxFull / TOTAL_ROW.MOSAIC + 1 : undefined}
							cursor={cursor}
							className={`${idxFull === idx ? 'item_full' : ''}`}>
							<ImageTheme content={item} isGif={isGif} />

							{renderIconVideo(item)}
							{renderMarkHover(item)}
							{checkExistExplicit(item?.review?.flags) && (
								<ExplicitContent
									zIndex={3335}
									sizeIcon={idxFull === idx ? 72 : 48}
									fontSize={idxFull === idx ? '16px' : '14px'}
								/>
							)}
						</StyledWrapperThumbnail>
					</>
				);
			})}
		</StyledBlockThemeMosaic>
	);
};

export default BlockThemeMosaic;
