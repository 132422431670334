import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import DrawerFilterAmbassador from '@components/Filter/DrawerFilterAmbassador';
import {
	COL_MEDIA,
	DEFAULT_FILTER_AMBASSADOR,
	DEFAULT_PARAM_SOCIAL_ACTIVITY,
	ID_CONTAINER_SOCIAL_ACTIVITY,
} from '@constants/ambassador';
import AmbassadorDashboardManagerContext from '@contexts/AmbassadorDashboard';
import SocialActivityManagerContext from '@contexts/AmbassadorDashboard/socialActivity';
import { LoadingWrapper } from '@cores/index';
import { FilterAmbassadorTypes, ParamPostActivityTypes } from '@models/ambassador';
import { AmbassadorManagerStoreDto } from '@models/ambassador/stores';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { fetchContentRequest, getAmbassadorPostsRequest } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledContainerSocial,
	StyledWrapperBoxRecent,
	StyledWrapperSession,
} from '@styled/AmbassadorDashboard';
import { StyledEmpty, StyledWrapper, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { concat, isEmpty, isEqual } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import ActionSocial from './ActionSocial';
import SummarySocial from './SummarySocial';
import { unstable_batchedUpdates } from 'react-dom';
import THEME from '@constants/themes/themes';
import { ParamRequestContentDto } from '@models/publishing/galleries';
import { PARAMS_DEFAULT_CONTENT } from '@constants/publish/gallery';
import { FolderItemDto } from '@models/common/summary';
import { CONTENT_FOLDER } from '@constants/content/contentLibrary';
import { ContentContainerDto } from '@models/content/contentLibrary/summary';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import SummaryContent from './SummaryContent';
import { FIRST_PAGE_INDEX } from '@constants/common';

type PropTypes = {
	heightSpace?: number;
};

const SocialActivity = (props: PropTypes) => {
	const dispatch = useDispatch();
	const { heightSpace = 0 } = props;

	const { loadingAmbassadorPost, posts }: AmbassadorManagerStoreDto = useSelector(
		(state: any) => state.ambassadors,
	);
	const { fetchingContent, content } = useSelector((state: ContentRootStateDto) => state.content);
	const { fetchingCampaignSimple }: UserStoreType = useSelector((state: any) => state.creator);
	const { folderSystem = [] } = useSelector((state: any) => state.sidebar);

	const { showRecentBox, campaignIdSelected, segmentIdSelected, setShowRecentBox } = useContext(
		AmbassadorDashboardManagerContext,
	);
	const hasFilter = campaignIdSelected || segmentIdSelected;

	const refContainer = useRef<HTMLDivElement | null>(null);
	const scrollbars = useRef<any>({});
	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [paramSocial, setParamSocial] = useState<ParamPostActivityTypes>({
		...DEFAULT_PARAM_SOCIAL_ACTIVITY,
	});
	const [paramsContent, setParamsContent] = useState<
		ParamRequestContentDto & { campaigns?: string[]; creatorSegments?: string[] }
	>({
		...PARAMS_DEFAULT_CONTENT,
		search: '',
	});
	const [filter, setFilter] = useState<FilterAmbassadorTypes>({ ...DEFAULT_FILTER_AMBASSADOR });
	const [socialList, setSocialList] = useState<SocialContentContainerDto[]>([]);
	const [contentList, setContentList] = useState<ContentContainerDto[]>([]);
	const [col, setCol] = useState<number>(COL_MEDIA.C2);
	const [thumbFull, setThumbFull] = useState(false);
	const [isSubmission, setIsSubmission] = useState<boolean>(true);

	useEffect(() => {
		return () => {
			setParamSocial({ ...DEFAULT_PARAM_SOCIAL_ACTIVITY });
			setFilter({ ...DEFAULT_FILTER_AMBASSADOR });
			setCol(COL_MEDIA.C2);
		};
	}, []);

	useEffect(() => {
		const folderSelect = folderSystem?.find(
			(item: FolderItemDto) =>
				item?.name.toLowerCase() === CONTENT_FOLDER.SUBMISSIONS.toLowerCase(),
		);
		const folderId: string = folderSelect ? folderSelect?.id?.toString() : '';

		if (folderId && folderId !== paramsContent?.folder) {
			setParamsContent({ ...paramsContent, folder: folderId });
		}
	}, [folderSystem]);

	useEffect(() => {
		if (isSubmission && paramsContent.folder) {
			const { campaignIds, sources, ...otherFilter } = filter;
			const payload = {
				...otherFilter,
				campaigns: campaignIds.map((id) => id.toString()),
				...paramsContent,
			};

			dispatch(
				fetchContentRequest({
					...paramsContent,
					...payload,
				}),
			);
			if (paramsContent.page === FIRST_PAGE_INDEX) {
				setContentList([]);
			}
		}
	}, [paramsContent, filter]);

	useEffect(() => {
		if (!isSubmission) {
			dispatch(getAmbassadorPostsRequest({ ...filter, ...paramSocial }));
			if (paramSocial.page === FIRST_PAGE_INDEX) {
				setSocialList([]);
			}
		}
	}, [paramSocial, filter]);

	useEffect(() => {
		if (!isEmpty(posts)) {
			const { listSocialMediaResponse } = posts;

			if (listSocialMediaResponse) {
				let newSocialList = [...socialList, ...listSocialMediaResponse];
				if (paramSocial.page === FIRST_PAGE_INDEX) {
					newSocialList = [...listSocialMediaResponse];
				}

				setSocialList(newSocialList);
			}
		}
	}, [posts]);

	useEffect(() => {
		if (paramsContent?.page === FIRST_PAGE_INDEX) {
			setContentList(content);
		} else {
			const newContentList = concat(contentList, content);
			setContentList(newContentList);
		}
	}, [content]);

	useEffect(() => {
		if (!campaignIdSelected) {
			if (paramSocial.campaignIds) {
				delete paramSocial.campaignIds;
				setParamSocial({
					...paramSocial,
					page: FIRST_PAGE_INDEX,
					socialUsernames: null,
					campaignHashtags: null,
					campaignMentionUsernames: null,
				});
				handleClearCampaign();
			}
			if (paramsContent.campaigns) {
				delete paramsContent.campaigns;
				setParamsContent({ ...paramsContent, page: FIRST_PAGE_INDEX });
				handleClearCampaign();
			}
		}
		if (campaignIdSelected) {
			setParamSocial({
				...paramSocial,
				page: FIRST_PAGE_INDEX,
				campaignIds: [campaignIdSelected],
				socialUsernames: null,
				campaignHashtags: null,
				campaignMentionUsernames: null,
			});

			setParamsContent({
				...paramsContent,
				page: FIRST_PAGE_INDEX,
				campaigns: [campaignIdSelected.toString()],
			});

			handleClearCampaign();
		}
	}, [campaignIdSelected]);

	useEffect(() => {
		if (!segmentIdSelected) {
			if (paramSocial.segmentIds) {
				delete paramSocial.segmentIds;
				setParamSocial({
					...paramSocial,
					page: FIRST_PAGE_INDEX,
					socialUsernames: null,
					campaignHashtags: null,
					campaignMentionUsernames: null,
				});
			}
			if (paramsContent.creatorSegments) {
				delete paramsContent.creatorSegments;

				setParamsContent({ ...paramsContent, page: FIRST_PAGE_INDEX });
			}
		}
		if (segmentIdSelected) {
			setParamSocial({
				...paramSocial,
				page: FIRST_PAGE_INDEX,
				segmentIds: [segmentIdSelected],
				socialUsernames: null,
				campaignHashtags: null,
				campaignMentionUsernames: null,
			});
			setParamsContent({
				...paramsContent,
				page: FIRST_PAGE_INDEX,
				creatorSegments: [segmentIdSelected.toString()],
			});
		}
	}, [segmentIdSelected]);

	useEffect(() => {
		if (showRecentBox && isSubmission) {
			handleChangeTab(false);
		}
	}, [showRecentBox]);

	const handleClearCampaign = () => {
		if (filter.campaignIds.length > 0) {
			setFilter({ ...filter, campaignIds: [] });
		}
	};

	const resetFilter = () => {
		setFilter({ ...DEFAULT_FILTER_AMBASSADOR });
		if (isSubmission) {
			setParamsContent({
				...paramsContent,
				page: FIRST_PAGE_INDEX,
				folder: paramsContent.folder,
				search: '',
			});
		} else {
			setParamSocial({
				...paramSocial,
				page: FIRST_PAGE_INDEX,
				socialUsernames: null,
				campaignHashtags: null,
				campaignMentionUsernames: null,
			});
		}
	};

	const handleChangeTab = (isSub: boolean) => {
		setIsSubmission(isSub);
		setSocialList([]);
		setContentList([]);
		if (isSub && showRecentBox) {
			setShowRecentBox(false);
		}
		if (isSub) {
			setParamsContent({
				...paramsContent,
				folder: paramsContent.folder,
				page: FIRST_PAGE_INDEX,
				search: '',
			});
		} else {
			setParamSocial({
				...paramSocial,
				page: FIRST_PAGE_INDEX,
				socialUsernames: null,
				campaignHashtags: null,
				campaignMentionUsernames: null,
			});
		}
	};

	const onScrollStop = () => {
		if (isSubmission) {
			if (scrollbars.current !== null && !fetchingContent && content.length > 0) {
				const scrollbarTop = scrollbars.current.getValues().top;
				if (scrollbarTop >= 0.75) {
					setParamsContent({ ...paramsContent, page: paramsContent.page + 1 });
				}
			}
			return;
		}
		if (
			scrollbars.current !== null &&
			!loadingAmbassadorPost &&
			posts?.listSocialMediaResponse?.length > 0
		) {
			const scrollbarTop = scrollbars.current.getValues().top;
			const {
				socialUsernames = null,
				campaignHashtags = null,
				campaignMentionUsernames = null,
			} = posts;

			if (scrollbarTop >= 0.75) {
				setParamSocial({
					...paramSocial,
					page: paramSocial.page + 1,
					socialUsernames,
					campaignHashtags,
					campaignMentionUsernames,
				});
			}
		}
	};

	const handleApplyFilter = (val: FilterAmbassadorTypes) => {
		unstable_batchedUpdates(() => {
			setFilter(val);
			if (isSubmission) {
				setParamsContent({ ...paramsContent, page: FIRST_PAGE_INDEX });
			} else {
				setParamSocial({
					...paramSocial,
					page: FIRST_PAGE_INDEX,
					socialUsernames: null,
					campaignHashtags: null,
					campaignMentionUsernames: null,
				});
			}
		});
	};

	const valContext = {
		socialList,
		setSocialList,
		col,
		setCol,
		isFiltering: !isEqual(filter, DEFAULT_FILTER_AMBASSADOR),
		handleToggleFilter: () => setOpenFilter(!openFilter),
		resetFilter,
		openFilter,
		paramSocial,
		setParamSocial,
		setThumbFull,
		thumbFull,
		isSubmission,
		setIsSubmission,
		contentList,
		setContentList,
		handleChangeTab,
	};

	return (
		<SocialActivityManagerContext.Provider value={valContext}>
			<StyledWrapperSession
				bgColor={hasFilter ? THEME.colors.darkBlue6 : undefined}
				height={`calc(100vh - 204px - ${heightSpace}px)`}>
				<LoadingWrapper
					isLoading={loadingAmbassadorPost || fetchingCampaignSimple || fetchingContent}
					sizeLoading={'normal'}>
					<DrawerFilterAmbassador
						visible={openFilter}
						onClose={() => setOpenFilter(false)}
						filter={filter}
						handleApplyFilter={handleApplyFilter}
						getContainer={refContainer.current || false}
						isSubmission={isSubmission}
						disabledCampaign={
							paramSocial?.campaignIds && paramSocial?.campaignIds?.length > 0
						}
					/>
					<StyledWrapperContent
						height="100%"
						overflow="auto"
						width="100%"
						padding="0 16px">
						<ActionSocial />
						<StyledContainerSocial
							id={ID_CONTAINER_SOCIAL_ACTIVITY}
							className="custom_scroll_bar"
							ref={refContainer}>
							{(isSubmission && contentList.length > 0) ||
							(socialList.length > 0 && !isSubmission) ? (
								<Scrollbars
									className="scrollbar_custom_wrapper"
									renderTrackVertical={() => <div />}
									ref={(el) => {
										scrollbars.current = el;
									}}
									onScrollStop={onScrollStop}
									style={{ height: '100%' }}
									renderThumbHorizontal={() => <div />}
									renderThumbVertical={() => <div />}>
									<StyledWrapperBoxRecent
										className={showRecentBox ? 'active_box' : ''}>
										{isSubmission ? (
											<SummaryContent folderId={paramsContent.folder} />
										) : (
											<SummarySocial />
										)}
									</StyledWrapperBoxRecent>
								</Scrollbars>
							) : (
								((!loadingAmbassadorPost && !isSubmission) ||
									(!fetchingContent && isSubmission)) && (
									<StyledWrapper
										backgroundColor={
											hasFilter ? THEME.colors.darkBlue6 : undefined
										}
										height="100%"
										className="height_full">
										<StyledEmpty image={IconEmpty} />
									</StyledWrapper>
								)
							)}
						</StyledContainerSocial>
					</StyledWrapperContent>
				</LoadingWrapper>
			</StyledWrapperSession>
		</SocialActivityManagerContext.Provider>
	);
};

export default SocialActivity;
