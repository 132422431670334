import THEME from '@constants/themes/themes';
import { StyledText } from '@styled/Common/CommonStyled';
import { Image, Modal, Table } from 'antd';
import styled from 'styled-components';

const StyledMediaRecognitionModal = styled(Modal)`
	.ant-modal-body {
		padding: 0;
	}
`;

const StyledChooseServices = styled.div``;

const StyledWarning = styled.div<{ bgcolor?: string }>`
	padding: 18px;
	background: ${({ bgcolor }) => bgcolor || 'rgba(251, 188, 5, 0.1)'};
	margin: 0 0 24px 0;
`;

const StyledServiceTable = styled(({ ...props }) => <Table {...props} />)`
	margin-top: 20px;

	.ant-table table {
		thead.ant-table-thead {
			tr {
				th {
					line-height: unset !important;
				}
			}
		}

		tbody.ant-table-tbody {
			tr {
				td:not(.ant-table-selection-column) {
					line-height: unset !important;
				}
			}
		}
	}
`;

const StyledLogoDetection = styled.div``;

const StyledUploadList = styled.div`
	width: calc(100% - 80px);
	min-height: 116px;
	overflow: auto;
	white-space: nowrap;
	padding: 12px 0;
	margin: 4px 0 0 16px;
`;

const StyledLogoWrapper = styled.div`
	position: relative;
	display: inline-block;
	margin-right: 10px;
	width: 86px;
	height: 86px;
	cursor: pointer;
	border: 3px solid transparent;

	&.active {
		border-color: ${THEME.colors.orangeBase};
		border-radius: 2px;
	}

	img {
		border-radius: 2px;
	}
`;

const StyledPreviewLogo = styled(Image)`
	width: 100%;
	height: 300px;
`;

const StyledAnalyzeContent = styled.div`
	${StyledText} {
		font-size: 16px;
	}
`;

export {
	StyledMediaRecognitionModal,
	StyledChooseServices,
	StyledWarning,
	StyledServiceTable,
	StyledPreviewLogo,
	StyledAnalyzeContent,
	StyledLogoDetection,
	StyledLogoWrapper,
	StyledUploadList,
};
