import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import CustomSelection from '@components/CustomLibrary/CustomSelection';
import FilterSelect, { FilterSelectTypes } from '@components/Filter/FilterSelect';
import { FULL_RECORD, TYPE_ACTIONS } from '@constants/common';
import { FILTER_BY, SYSTEM_FOLDER_ALL } from '@constants/content/contentLibrary';
import { PARAMS_DEFAULT_CONTENT, TYPE_GET_CONTENT } from '@constants/publish/gallery';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { SelectionCustomRef } from '@models/common/ref';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import { ParamRequestContentDto } from '@models/publishing/galleries';
import {
	fetchContentRequest,
	reviewMultiContentsEnd,
	reviewMultiContentsRequest,
} from '@stores/actions';
import { StyledTitle, StyledTitleClick, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledSearch } from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledEmpty, StyledWrapper } from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledAction,
	StyledUpdateListContentModal,
	StyledWrapperAction,
	StyledWrapperListContent,
} from '@styled/Publish/Gallery/UpdateListContentModalStyled';
import { getContentByType, getSocialS3URL } from '@utils/common';
import { handleSelectItemWithLib } from '@utils/funcHelper';
import { renderThumbVertical } from '@utils/renderComponent';
import { Button, Col, Row, message } from 'antd';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectableGroup, createSelectable } from 'react-selectable-fast';
import ThemeItem from '../ViewGallery/ThemeItem';

type PropsChangeListContentModal = {
	[other: string]: any;
	visible: boolean;
	typeGetContent: string;
	galleryId: number;
	onCancel: () => void;
	handleResetData: (countImg: number, countVid: number) => void;
};

const SelectableItem = createSelectable(ThemeItem);

const UpdateListContentModal = (props: PropsChangeListContentModal) => {
	const { visible, typeGetContent, galleryId, onCancel, handleResetData, ...other } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const scrollbars = useRef<any>({});
	const selectionRef = useRef<any>({});
	const refEventSelect = useRef<
		SelectionCustomRef | undefined
	>() as RefObject<SelectionCustomRef>;

	const { fetchingContent, content, reviewMultiContentsStatus } = useSelector(
		(state: any) => state.content,
	);
	const { albums }: AlbumsStoreDto = useSelector((state: any) => state.albumManager);

	const [searchValue, setSearchValue] = useState<string>('');
	const [contentSelecting, setContentSelecting] = useState<ContentDto[]>([]);
	const [listContent, setListContent] = useState<ContentDto[]>([]);

	const [listRemove, setListRemove] = useState<number[]>([]);
	const [listAdd, setListAdd] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
	const [countImg, setCountImg] = useState<number>(0);
	const [countVid, setCountVid] = useState<number>(0);
	const [albumSelect, setAlbumSelect] = useState<FilterSelectTypes | null>(null);

	const defaultParamContent = useMemo(() => {
		return {
			...PARAMS_DEFAULT_CONTENT,
			verified: true,
			customTerms: true,
			search: '',
			folder: SYSTEM_FOLDER_ALL.id,
		};
	}, []);
	const [paramContent, setParamContent] = useState<ParamRequestContentDto>({
		...defaultParamContent,
	});

	const onPressSearch = (value: string) => {
		handleClearFormBeforeSearch();
		setParamContent((prev) => ({ ...prev, search: value || '', page: 1 }));
	};

	const onChangeSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(evt.target.value);
	};

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			if (scrollbarTop >= 0.75 && content?.length > 0) {
				setParamContent((prev) => ({ ...prev, page: prev.page + 1 }));
			}
		}
	};

	const handleSelection = (selectingItems: any) => {
		const selectContent: Array<ContentDto> = [];
		selectingItems.map((selectingItem: any) => {
			const { props } = selectingItem;
			selectContent.push(props.content);
		});
		if (
			refEventSelect?.current?.handleSectionCustom &&
			typeof refEventSelect.current.handleSectionCustom === 'function'
		) {
			refEventSelect.current.handleSectionCustom(selectContent.map((item) => item.id));
		}

		if (!refEventSelect?.current?.isShift) {
			setContentSelecting(selectContent);
		}
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		const contents: ContentDto[] = [];
		listContent.forEach((item) => {
			if (list.includes(item.id)) {
				contents.push(item);
			}
		});
		setContentSelecting(contents);
	};

	const formatListContent = (list: ContentContainerDto[]) => {
		return list.map((data: any, index: number) => {
			const contentTemp: ContentDto = getContentByType(data);
			const thumbnail: string = getSocialS3URL(contentTemp.thumbnail);
			return { ...contentTemp, thumbnail };
		});
	};

	const onCheckCount = () => {
		let imgNum = 0;
		let vidNum = 0;
		listContent?.forEach((item) => {
			if (listAdd?.includes(item?.id)) {
				if (item?.mediaType === FILTER_BY.VIDEO.value) {
					vidNum += 1;
				} else {
					imgNum += 1;
				}
			}
			if (listRemove?.includes(item?.id)) {
				if (item?.mediaType === FILTER_BY.VIDEO.value) {
					vidNum -= 1;
				} else {
					imgNum -= 1;
				}
			}
		});
		setCountImg(imgNum);
		setCountVid(vidNum);
	};

	const handleAddContent = () => {
		const dataChange = handleFormatListSelection(TYPE_ACTIONS.ADD);
		setListAdd([...listAdd, ...dataChange.add]);
		setListRemove([...dataChange.remove]);
		handleClearSelection();
	};

	const handleRemoveContent = () => {
		const dataChange = handleFormatListSelection(TYPE_ACTIONS.REMOVE);
		setListAdd([...dataChange.add]);
		setListRemove([...listRemove, ...dataChange.remove]);
		handleClearSelection();
	};

	const handleFormatListSelection = (type: string) => {
		const newList: Array<number> = [];
		let newListFilter: Array<number> =
			type === TYPE_ACTIONS.REMOVE ? [...listAdd] : [...listRemove];
		contentSelecting?.forEach((item: ContentDto) => {
			const galleries: Array<number> = item?.review?.galleries || [];
			if (
				(type === TYPE_ACTIONS.REMOVE &&
					galleries?.includes(galleryId) &&
					!listRemove?.includes(item?.id)) ||
				(type === TYPE_ACTIONS.ADD &&
					!galleries?.includes(galleryId) &&
					!listAdd?.includes(item?.id))
			) {
				newList.push(item?.id);
			}

			newListFilter = newListFilter?.filter((id) => id !== item?.id);
		});
		if (type === TYPE_ACTIONS.ADD) {
			return { add: newList, remove: newListFilter };
		}
		return { add: newListFilter, remove: newList };
	};

	const onRequestAddContent = () => {
		dispatch(
			reviewMultiContentsRequest({
				params: {
					galleries: [galleryId.toString()],
					isRemove: false,
					contentIds: listAdd,
				},
			}),
		);
	};
	const handleSaveChange = () => {
		setVisibleConfirm(false);
		onCheckCount();
		if (listRemove?.length > 0) {
			const requestData: any = {
				params: {
					galleries: [galleryId.toString()],
					isRemove: true,
					contentIds: listRemove,
				},
			};
			if (listAdd?.length > 0) {
				requestData.reviewSucceeded = () =>
					setTimeout(() => {
						onRequestAddContent();
					}, 0);
			}

			dispatch(
				reviewMultiContentsRequest({
					...requestData,
				}),
			);
		} else {
			if (listAdd?.length > 0) {
				onRequestAddContent();
			}
		}
	};

	const handleCheckExist = (contentCompare: ContentDto) => {
		const galleries: Array<number> = contentCompare?.review?.galleries || [];

		if (
			listAdd?.includes(contentCompare?.id) ||
			(galleries?.includes(galleryId) && !listRemove?.includes(contentCompare?.id))
		) {
			return true;
		}

		return false;
	};

	const handleClearSelection = () => {
		setContentSelecting([]);
		if (
			selectionRef?.current &&
			selectionRef.current.clearSelection &&
			typeof selectionRef.current.clearSelection === 'function'
		) {
			selectionRef.current.clearSelection();
		}
	};

	const onChangeAlbum = (val: FilterSelectTypes | null) => {
		setAlbumSelect(val);
		setParamContent({ ...paramContent, page: 1 });
	};

	const handleSelectAll = () => {
		setContentSelecting([...listContent]);
		if (
			selectionRef?.current &&
			selectionRef.current.selectAll &&
			typeof selectionRef.current.selectAll === 'function'
		) {
			selectionRef.current.selectAll();
		}
	};

	const handleClearFormBeforeSearch = () => {
		setListContent([]);
		setListRemove([]);
		setListAdd([]);
		setCountImg(0);
		setCountVid(0);
		handleClearSelection();
	};

	const handleClearForm = () => {
		handleClearFormBeforeSearch();
		setParamContent({ ...defaultParamContent });
		setAlbumSelect(null);
		setSearchValue('');
	};

	const handleClose = () => {
		handleClearForm();
		onCancel();
	};

	const renderContentPopupConfirm = () => {
		return (
			<>
				{t('gallery_manager.message.message_publish_content_gallery')}
				<br />
				{t('gallery_manager.message.quest_publish_content_gallery')}
			</>
		);
	};

	useEffect(() => {
		if (content && typeGetContent === TYPE_GET_CONTENT.UPDATE && !fetchingContent) {
			const newContentFormat = formatListContent(content);
			if (paramContent?.page === 1) {
				setListContent(newContentFormat);
			} else {
				setListContent([...listContent, ...newContentFormat]);
			}
		}
	}, [content]);

	useEffect(() => {
		if (visible) {
			const paramRequest = { ...paramContent };
			if (albumSelect) {
				paramRequest.folder = albumSelect.value.toString();
				paramRequest.maxRecords = FULL_RECORD;
			}
			if (paramRequest.page === 1) {
				handleClearSelection();
			}
			dispatch(fetchContentRequest(paramRequest));
		}
	}, [visible, paramContent, albumSelect]);

	useEffect(() => {
		if (reviewMultiContentsStatus === IN_PROGRESS) {
			setIsLoading(true);
		}

		if (reviewMultiContentsStatus === SUCCEEDED) {
			message.success(t('gallery_manager.message.change_saved'));

			handleResetData(countImg, countVid);
		}

		if (reviewMultiContentsStatus === SUCCEEDED || reviewMultiContentsStatus === FAILED) {
			dispatch(reviewMultiContentsEnd());
			setIsLoading(false);
			handleClose();
		}
	}, [reviewMultiContentsStatus]);

	const renderButtonSelectAll = () => {
		return (
			<StyledTitleClick
				textDecoration="underline"
				display="inline"
				fontSize="12px"
				className="text_action"
				onClick={() => handleSelectAll()}
				fontWeight="700">
				{t('button.select_all')}
			</StyledTitleClick>
		);
	};

	return (
		<>
			<CustomSelection
				ref={refEventSelect}
				ids={listContent.map((item) => item.id)}
				selectIds={contentSelecting.map((item) => item.id)}
				refSelection={selectionRef}
				handleSelectNewList={handleSelectNewList}
			/>
			<ConfirmModal
				width={600}
				isOpen={visibleConfirm}
				onClose={() => setVisibleConfirm(false)}
				cancelText={t('gallery_manager.button.cancel')}
				okText={t('gallery_manager.button.publish')}
				onSubmit={handleSaveChange}>
				{renderContentPopupConfirm()}
			</ConfirmModal>
			<StyledUpdateListContentModal
				{...other}
				visible={visible}
				centered={true}
				width={'calc(100vw - 300px)'}
				onCancel={handleClose}
				footer={[
					<Button onClick={() => handleClose()}>
						{t('gallery_manager.button.cancel')}
					</Button>,
					listAdd.length > 0 || listRemove.length > 0 ? (
						<StyledTitle
							margin="0 13px"
							className="unset_height"
							color={THEME.colors.gray1}
							fontSize="14px"
							display="inline-flex">
							{t('social_aggregator.unsaved_changes')}
						</StyledTitle>
					) : null,
					<Button
						loading={isLoading}
						onClick={() => setVisibleConfirm(true)}
						disabled={listAdd?.length === 0 && listRemove?.length === 0}
						type="primary">
						{t('gallery_manager.button.save_change')}
					</Button>,
				]}
				title={t('gallery_manager.modal.add_form_content_lib')}>
				<LoadingWrapper
					bgColorLoading={'transparent'}
					isLoading={fetchingContent || isLoading}>
					<StyledWrapperAction className="custom_scroll_bar">
						<StyledWrapperContent
							className="left_elm"
							margin="0 16px 0 0"
							display="inline-flex"
							alignItems="center">
							<StyledSearch
								width={'250px'}
								className="search-custom"
								placeholder={t('gallery_manager.modal.place_holder_search_content')}
								onSearch={onPressSearch}
								onChange={onChangeSearch}
								enterButton
								value={searchValue}
							/>
							<FilterSelect
								showSearch
								selectedValue={albumSelect}
								onChange={onChangeAlbum}
								options={albums?.map((item) => ({
									value: item?.id,
									name: item?.name,
								}))}
								placeholderDefault={t('gallery_manager.modal.filter_by_album')}
								subApplyFilter={t('gallery_manager.modal.showing_content') + ' '}
								suffixFilter={' ' + t('gallery_manager.modal.album')}
								maxWidthText="250px"
							/>
						</StyledWrapperContent>

						<StyledAction className="right_elm">
							<StyledAction>
								{contentSelecting.length > 0 && (
									<>
										<StyledTitle
											className="unset_height"
											display="inline"
											fontSize="12px"
											margin="0 5px 0 0">
											{t('gallery_manager.modal.num_selected', {
												val: contentSelecting.length,
											})}
										</StyledTitle>

										{contentSelecting.length > 0 &&
											albumSelect &&
											renderButtonSelectAll()}
										<StyledTitle
											fontWeight="700"
											className="unset_height"
											display="inline"
											margin="0 5px">
											/
										</StyledTitle>
										<StyledTitleClick
											textDecoration="underline"
											display="inline"
											fontSize="12px"
											className="text_action"
											onClick={() => handleClearSelection()}
											fontWeight="700">
											{t('button.clear_selection')}
										</StyledTitleClick>
									</>
								)}
								{contentSelecting?.length === 0 &&
									albumSelect &&
									renderButtonSelectAll()}
							</StyledAction>
							<Button
								style={{ marginLeft: '12px' }}
								onClick={handleAddContent}
								disabled={contentSelecting?.length === 0}>
								{t('gallery_manager.button.add_to_gallery')}
							</Button>
							<Button
								onClick={handleRemoveContent}
								disabled={contentSelecting?.length === 0}
								style={{ marginLeft: '12px' }}>
								{t('gallery_manager.button.remove_from_gallery')}
							</Button>
						</StyledAction>
					</StyledWrapperAction>
					<StyledWrapperListContent>
						{listContent.length > 0 ? (
							<Scrollbars
								className="media-list-scrollbar"
								renderTrackVertical={() => <div />}
								ref={(el) => {
									scrollbars.current = el;
								}}
								onScrollStop={onScrollStop}
								style={{ height: '100%' }}
								renderThumbVertical={renderThumbVertical}>
								<SelectableGroup
									className="selectable-group"
									clickClassName="tick"
									ref={selectionRef}
									enableDeselect={true}
									tolerance={0}
									deselectOnEsc={true}
									allowClickWithoutSelected={true}
									selectOnClick={false}
									onSelectionFinish={handleSelection}>
									<Row gutter={[12, 12]}>
										{listContent?.map((item: ContentDto) => (
											<Col key={item?.id} span={4}>
												<SelectableItem
													id={item.id}
													isExist={handleCheckExist(item)}
													content={item}
													handleSelect={() =>
														handleSelectItemWithLib(
															selectionRef,
															item.id,
														)
													}
												/>
											</Col>
										))}
									</Row>
								</SelectableGroup>
							</Scrollbars>
						) : (
							!fetchingContent && (
								<StyledWrapper marginTop={'0'}>
									<StyledEmpty image={IconEmpty} />
								</StyledWrapper>
							)
						)}
					</StyledWrapperListContent>
				</LoadingWrapper>
			</StyledUpdateListContentModal>
		</>
	);
};

export default UpdateListContentModal;
