const languageAmazon = [
	{
		code: 'af',
		name: 'Afrikaans',
	},

	{
		code: 'am',
		name: 'Amharic',
	},
	{
		code: 'ar',
		name: 'Arabic',
	},
	{
		code: 'as',
		name: 'Assamese',
	},
	{
		code: 'az',
		name: 'Azerbaijani',
	},
	{
		code: 'ba',
		name: 'Bashkir',
	},
	{
		code: 'be',
		name: 'Belarusian',
	},
	{
		code: 'bn',
		name: 'Bengali',
	},
	{ code: 'bs', name: 'Bosnian' },
	{
		code: 'bg',
		name: 'Bulgarian',
	},
	{
		code: 'ca',
		name: 'Catalan',
	},
	{
		code: 'ceb',
		name: 'Cebuano',
	},
	{
		code: 'cs',
		name: 'Czech',
	},
	{
		code: 'cv',
		name: 'Chuvash',
	},
	{
		code: 'cy',
		name: 'Welsh',
	},
	{
		code: 'da',
		name: 'Danish',
	},
	{
		code: 'de',
		name: 'German',
	},
	{
		code: 'el',
		name: 'Greek',
	},
	{
		code: 'en',
		name: 'English',
	},
	{
		code: 'eo',
		name: 'Esperanto',
	},
	{
		code: 'et',
		name: 'Estonian',
	},
	{
		code: 'eu',
		name: 'Basque',
	},
	{
		code: 'fa',
		name: 'Persian',
	},
	{
		code: 'fi',
		name: 'Finnish',
	},
	{
		code: 'fr',
		name: 'French',
	},
	{
		code: 'gd',
		name: 'Scottish Gaelic',
	},
	{
		code: 'ga',
		name: 'Irish',
	},
	{
		code: 'gl',
		name: 'Galician',
	},
	{
		code: 'gu',
		name: 'Gujarati',
	},
	{
		code: 'ht',
		name: 'Haitian',
	},
	{
		code: 'he',
		name: 'Hebrew',
	},
	{
		code: 'ha',
		name: 'Hausa',
	},
	{
		code: 'hi',
		name: 'Hindi',
	},
	{
		code: 'hr',
		name: 'Croatian',
	},
	{
		code: 'hu',
		name: 'Hungarian',
	},
	{
		code: 'hy',
		name: 'Armenian',
	},
	{
		code: 'ilo',
		name: 'Iloko',
	},
	{
		code: 'id',
		name: 'Indonesian',
	},
	{
		code: 'is',
		name: 'Icelandic',
	},
	{
		code: 'it',
		name: 'Italian',
	},
	{
		code: 'jv',
		name: 'Javanese',
	},
	{
		code: 'ja',
		name: 'Japanese',
	},
	{
		code: 'kn',
		name: 'Kannada',
	},
	{
		code: 'ka',
		name: 'Georgian',
	},
	{
		code: 'kk',
		name: 'Kazakh',
	},
	{
		code: 'km',
		name: 'Central Khmer',
	},
	{
		code: 'ky',
		name: 'Kirghiz',
	},
	{
		code: 'ko',
		name: 'Korean',
	},
	{
		code: 'ku',
		name: 'Kurdish',
	},
	{
		code: 'lo',
		name: 'Lao',
	},
	{
		code: 'la',
		name: 'Latin',
	},
	{
		code: 'lv',
		name: 'Latvian',
	},
	{
		code: 'lt',
		name: 'Lithuanian',
	},
	{
		code: 'lb',
		name: 'Luxembourgish',
	},
	{
		code: 'ml',
		name: 'Malayalam',
	},
	{
		code: 'mt',
		name: 'Maltese',
	},
	{
		code: 'mr',
		name: 'Marathi',
	},
	{
		code: 'mk',
		name: 'Macedonian',
	},
	{
		code: 'mg',
		name: 'Malagasy',
	},
	{
		code: 'mn',
		name: 'Mongolian',
	},
	{
		code: 'ms',
		name: 'Malay',
	},
	{
		code: 'my',
		name: 'Burmese',
	},
	{
		code: 'ne',
		name: 'Nepali',
	},
	{
		code: 'new',
		name: 'Newari',
	},
	{
		code: 'nl',
		name: 'Dutch',
	},
	{
		code: 'no',
		name: 'Norwegian',
	},
	{
		code: 'or',
		name: 'Oriya',
	},
	{
		code: 'om',
		name: 'Oromo',
	},
	{
		code: 'pa',
		name: 'Punjabi',
	},
	{
		code: 'pl',
		name: 'Polish',
	},
	{
		code: 'pt',
		name: 'Portuguese',
	},
	{
		code: 'ps',
		name: 'Pushto',
	},
	{
		code: 'qu',
		name: 'Quechua',
	},
	{
		code: 'ro',
		name: 'Romanian',
	},
	{
		code: 'ru',
		name: 'Russian',
	},
	{
		code: 'sa',
		name: 'Sanskrit',
	},
	{
		code: 'si',
		name: 'Sinhala',
	},
	{
		code: 'sk',
		name: 'Slovak',
	},
	{
		code: 'sl',
		name: 'Slovenian',
	},
	{
		code: 'sd',
		name: 'Sindhi',
	},
	{
		code: 'so',
		name: 'Somali',
	},
	{
		code: 'es',
		name: 'Spanish',
	},
	{
		code: 'sq',
		name: 'Albanian',
	},
	{
		code: 'sr',
		name: 'Serbian',
	},
	{
		code: 'su',
		name: 'Sundanese',
	},
	{
		code: 'sw',
		name: 'Swahili',
	},
	{
		code: 'sv',
		name: 'Swedish',
	},
	{
		code: 'ta',
		name: 'Tamil',
	},
	{
		code: 'tt',
		name: 'Tatar',
	},
	{
		code: 'te',
		name: 'Telugu',
	},
	{
		code: 'tg',
		name: 'Tajik',
	},
	{
		code: 'tl',
		name: 'Tagalog',
	},
	{
		code: 'th',
		name: 'Thai',
	},
	{
		code: 'tk',
		name: 'Turkmen',
	},
	{
		code: 'tr',
		name: 'Turkish',
	},
	{
		code: 'ug',
		name: 'Uighur',
	},
	{
		code: 'uk',
		name: 'Ukrainian',
	},
	{
		code: 'ur',
		name: 'Urdu',
	},
	{
		code: 'uz',
		name: 'Uzbek',
	},
	{
		code: 'vi',
		name: 'Vietnamese',
	},
	{
		code: 'yi',
		name: 'Yiddish',
	},
	{
		code: 'yo',
		name: 'Yoruba',
	},
	{
		code: 'zh',
		name: 'Chinese (Simplified)',
	},
	{
		code: 'zh-TW',
		name: 'Chinese (Traditional)',
	},
];

export { languageAmazon };
