const STATUS_TEMPLATE = {
	APPROVED: 'APPROVED',
	DRAFT: 'DRAFT',
	ARCHIVED: 'ARCHIVED',
};

const DISPLAY_TYPES = {
	POPUP: 'POPUP',
	IN_LINE: 'IN_LINE',
	REDIRECT: 'REDIRECT',
};

const RULES_TERMS = {
	AGREE: 'Agree',
	READ: 'Read',
};

const TERMS_TYPE = {
	STANDARD: 'STANDARD',
	CUSTOM: 'CUSTOM',
};

const TERMS_DATA = {
	CUSTOM: {
		displayType: DISPLAY_TYPES.POPUP,
		isTermsMandated: true,
		name: 'New Terms',
		documentHeader: '',
		documentBody: '',
		agreementMessage: '',
		hyperlinkText: '',
		isMustView: false,
		isEntribeStandardTerms: false,
		tags: {
			licensingPeriod: null,
			approvedCountries: null,
			approvedMarketingChannels: null,
		},
		isAdd: true,
	},
};

const KEY_LICENSING_TERMS = {
	AGREEMENT_MESSAGE: 'agreementMessage',
	HYPERLINK_TEXT: 'hyperlinkText',
	HYPERLINK_URL: 'hyperlinkUrl',
	DOCUMENT_HEADER: 'documentHeader',
	DISPLAY_TYPE: 'displayType',
	RULES: 'Rules',
	DOCUMENT_BODY: 'documentBody',
	LICENSING_PERIOD: 'licensingPeriod',
	APPROVED_COUNTRIES: 'approvedCountries',
	APPROVED_MARKETING_CHANNELS: 'approvedMarketingChannels',
	IS_TERMS_MANDATED: 'isTermsMandated',
	IS_MUST_VIEW: 'isMustView',
	TAGS: 'tags',
	TEXT_MUST_VIEW: 'textMustView',
};

const HEIGHT_OVERLAY = {
	ONE_ITEM: 32,
	EMPTY: 200,
	MORE: 7,
};

const SPACE_TERMS = 63;

const MANAGE_TERMS_TEMPLATE_WIDGET = {
	GET: 'GET_WIDGET_INFO_BASIC',
	ASSIGN: 'ASSIGN_TO_WIDGET',
	REMOVE: 'REMOVE_FROM_WIDGET',
	REPLACE: 'REPLACE_TO_WIDGET',
};

const DEFAULT_HEIGHT_HISTORY = '150px';

const DEFAULT_HEIGHT_CDM_HISTORY = '200px';

const HEIGHT_MODAL_PREVIEW_TERMS = '85vh';

export {
	STATUS_TEMPLATE,
	KEY_LICENSING_TERMS,
	DISPLAY_TYPES,
	RULES_TERMS,
	TERMS_TYPE,
	TERMS_DATA,
	HEIGHT_OVERLAY,
	MANAGE_TERMS_TEMPLATE_WIDGET,
	SPACE_TERMS,
	DEFAULT_HEIGHT_HISTORY,
	DEFAULT_HEIGHT_CDM_HISTORY,
	HEIGHT_MODAL_PREVIEW_TERMS,
};
