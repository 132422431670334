import { IconKeyboardArrowRight } from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { UPLOADSOURCE } from '@constants/content/contentLibrary';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { StyledIcon, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledCollapse,
	StyledCollapseInfo,
	StyledInfoItem,
	StyledLabelInfo,
	StyledOptionInfo,
	SwapperSessionDetail,
} from '@styled/ContentLibrary/ContentDetailStyled';
import { getDirectUploadSourceText } from '@utils/content';
import { Tooltip } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const { Panel } = StyledCollapse;

type PropTypes = {};

const OtherDetail = (props: PropTypes) => {
	const { t } = useTranslation();

	const { currentMedia } = useContext(ContentDetailContext);

	const renderListOption = () => {
		const { social, uploadDate, uploadSource, uploadMethod } = currentMedia || {};
		const titleSource = getDirectUploadSourceText(uploadSource, uploadMethod);

		const result = [
			{
				title: t('social_search.details.post_date'),
				info: social?.timestamp
					? convertUtcToLocalTimeWithFormat(social?.timestamp, TYPE_FORMAT_DATE.TIME)
					: '',
				id: 1,
			},
			{
				title: t('content_detail.date_added'),
				info: uploadDate
					? convertUtcToLocalTimeWithFormat(uploadDate, TYPE_FORMAT_DATE.TIME)
					: '',
				id: 2,
			},
			{
				title: t('content_detail.original_source'),
				info: t(titleSource),
				id: 3,
			},
		];
		return result;
	};

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo>
				<StyledTitle>{t('content_detail.other_details')}</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	return (
		<SwapperSessionDetail>
			<StyledCollapse
				className="collapse_content_detail"
				expandIconPosition="right"
				style={{ marginBottom: '0' }}
				defaultActiveKey={'1'}>
				<Panel header={renderHeaderPanel()} key={'1'}>
					{renderListOption()?.map((item) => (
						<StyledOptionInfo key={item?.id}>
							<StyledLabelInfo>{item?.title}</StyledLabelInfo>
							{item?.info && (
								<Tooltip
									placement="topLeft"
									getPopupContainer={(triggerNode: HTMLElement) =>
										triggerNode.parentNode as HTMLElement
									}
									overlayClassName="content-info-tooltip"
									title={item?.info}>
									<StyledInfoItem>{item?.info}</StyledInfoItem>
								</Tooltip>
							)}
						</StyledOptionInfo>
					))}
				</Panel>
			</StyledCollapse>
		</SwapperSessionDetail>
	);
};

export default OtherDetail;
