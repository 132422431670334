import {
	IconCustomTerms,
	IconLightBulb,
	IconNoRights,
	IconOriginalError,
	IconRightClear,
	IconRightsPending,
} from '@assets/icons';
import { MARK_AS, THUMBNAIL_BEHAVIOR } from '@constants/content/contentLibrary';
import THEME from '@constants/themes/themes';
import { StyledIconWrapper } from '@styled/Common/CommonStyled';
import {
	StyledMarkAs,
	StyledMarkAsItem,
	StyledTextIcon,
} from '@styled/Content/ContentLibrary/MediaItemStyled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const ThumbnailBehaviorChip = (props: any) => {
	const { content, convertHeight, onChangeStatusRecently } = props;
	const { thumbnailBehavior } = useSelector((state: any) => state.content);

	const { t } = useTranslation();

	const renderChipItem = (
		text: string,
		icon: JSX.Element,
		convertHeight: number,
		onClick?: () => void,
		className?: string,
	) => {
		if (onClick) {
			return (
				<StyledMarkAsItem
					onClick={(e: any) => {
						e.stopPropagation();

						onClick();
					}}
					key={text}
					className={className || ''}
					height={convertHeight}>
					<StyledTextIcon>{text}</StyledTextIcon>
					{icon}
				</StyledMarkAsItem>
			);
		}
		return (
			<StyledMarkAsItem className={className || ''} key={text} height={convertHeight}>
				<StyledTextIcon>{text}</StyledTextIcon>
				{icon}
			</StyledMarkAsItem>
		);
	};

	if (content) {
		const { review, detected } = content;
		const { verified = false, pending, isRecentlyAdded, contacted, customTerms } = review;
		const markAsList: JSX.Element[] = [];
		if (isRecentlyAdded) {
			markAsList.push(
				renderChipItem(
					t('content_detail.newly_added'),
					<StyledIconWrapper color={THEME.colors.blueBase}>
						<IconOriginalError />
					</StyledIconWrapper>,
					convertHeight,
					onChangeStatusRecently,
					'recently-added-svg',
				),
			);
		} else {
			if (
				thumbnailBehavior.includes(THUMBNAIL_BEHAVIOR.HIGHLIGHT_PENDING_CONTENT) &&
				pending
			) {
				markAsList.push(
					renderChipItem(
						MARK_AS.PENDING.additionalLabel,
						<StyledIconWrapper color={THEME.colors.gray5}>
							<IconOriginalError />
						</StyledIconWrapper>,
						convertHeight,
					),
				);
			}

			if (
				thumbnailBehavior.includes(THUMBNAIL_BEHAVIOR.SHOW_RIGHTS_CLEARANCE) &&
				(!thumbnailBehavior.includes(THUMBNAIL_BEHAVIOR.HIGHLIGHT_PENDING_CONTENT) ||
					!pending)
			) {
				if (verified) {
					markAsList.push(
						renderChipItem(
							t('content_detail.rights_granted'),
							<IconRightClear />,
							convertHeight,
						),
					);
				} else {
					if (customTerms) {
						markAsList.push(
							renderChipItem(
								t('content_detail.custom_terms'),
								<IconCustomTerms />,
								convertHeight,
							),
						);
					} else {
						if (contacted)
							markAsList.push(
								renderChipItem(
									t('content_detail.right_pending'),
									<IconRightsPending />,
									convertHeight,
								),
							);
						else
							markAsList.push(
								renderChipItem(
									t('content_detail.no_rights'),
									<IconNoRights />,
									convertHeight,
								),
							);
					}
				}
			}

			if (thumbnailBehavior.includes(THUMBNAIL_BEHAVIOR.SHOW_ANALYSIS_STATUS) && detected) {
				markAsList.push(
					renderChipItem(
						t('content_detail.analyzed'),
						<StyledIconWrapper>
							<IconLightBulb />
						</StyledIconWrapper>,
						convertHeight,
					),
				);
			}
		}

		return <StyledMarkAs height={convertHeight}>{markAsList}</StyledMarkAs>;
	}

	return null;
};
