import { PLACEHOLDER_IMAGE_IN_BASE64, TIME_OUT, UNIT_VALUE } from '@constants/common';
import { ACCEPT_FILES, MEDIA_TYPE } from '@constants/content/contentLibrary';
import THEME from '@constants/themes/themes';
import { checkIsUrl } from '@helpers/base64Helper';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { StyledSkeletonImage } from '@styled/Common/CommonStyled';
import {
	StyledImage,
	StyledVideo,
	StyledWrapperPosition,
} from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	getSocialS3URL,
	handleContentImageLoadedSocialError,
	handleLoadedVideoSocialError,
} from '@utils/common';
import { fetchPosterOfVideo } from '@utils/funcHelper';
import { convertSocialId } from '@utils/socialSearch';
import { message } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
	imgRef: any;
	videoRef: any;
	setDimension: (val: number) => void;
	updateMediaUrl: (val: string) => void;
	socialContent: SocialContentContainerDto;
	setLoading: (val: boolean) => void;
	loadSkeleton?: boolean;
	[other: string]: any;
};

// unmount component remove event
const SocialMediaLoading = (props: PropTypes) => {
	const { socialContent } = props;
	const [view, setView] = useState(true);
	useEffect(() => {
		return () => {
			setView(false);
		};
	}, [socialContent.id]);

	useEffect(() => {
		if (!view) {
			setView(true);
		}
	}, [view]);

	return view ? <SocialThumb {...props} /> : null;
};

const SocialThumb = (props: PropTypes) => {
	const { t } = useTranslation();
	const {
		imgRef,
		setDimension,
		videoRef,
		updateMediaUrl,
		socialContent,
		setLoading,
		loadSkeleton,
		...other
	} = props;

	const isVideo = socialContent.mediaType === MEDIA_TYPE.VIDEO;

	const urlThumbnailDefault = useMemo(() => {
		const elm: any = document.querySelector(
			`#thumbnail_social_${convertSocialId(socialContent)} img`,
		);

		return elm?.src || '';
	}, [socialContent.id]);

	const refTime = useRef<any>(null);
	const refMount = useRef<boolean>(false);
	const [loadedError, setLoadError] = useState<boolean>(true);
	const [urlThumbnail, setUrlThumbnail] = useState<string>(urlThumbnailDefault);
	const [visibleImage, setVisibleImage] = useState<boolean>(false);
	const [loadedSuccess, setLoadedSuccess] = useState<boolean>(false);
	const [isRendered, setIsRendered] = useState<boolean>(false);

	const hasLoadingSkeleton = useMemo(() => {
		if (visibleImage) {
			return visibleImage;
		}
		return !!loadSkeleton && !isRendered;
	}, [loadSkeleton, isRendered, visibleImage]);

	const handleCheckUrl = (url: string, callback: (val: string) => void, retry?: boolean) => {
		const img = new Image();
		img.src = url;

		img.onload = () => {
			callback(url);
		};
		img.onerror = async (e) => {
			if (retry) {
				const src = await handleContentImageLoadedSocialError(
					{ target: { src: '' } },
					socialContent,
				);
				if (src) {
					callback(src);
				}
			} else {
				setLoadedSuccess(false);
			}
		};
	};

	const handleUpdateThumbnail = (val: string) => {
		handleChangeLoadedSuccess(false);
		if (refMount.current) {
			setUrlThumbnail(val);
			setIsRendered(false);
			updateMediaUrl(val);
		}
	};

	const handleChangeLoadedSuccess = (val: boolean) => {
		setLoadedSuccess(val);
		setLoading(!val);
	};

	useEffect(() => {
		handleChangeLoadedSuccess(false);
		refMount.current = true;

		const changeUrlThumbnail = (val: string) => {
			if (refMount.current) {
				setUrlThumbnail(val);
				setIsRendered(false);
			}
		};
		changeUrlThumbnail(urlThumbnailDefault);

		if (socialContent.mediaType === MEDIA_TYPE.IMAGE) {
			const callback = (val: string) => {
				handleCheckUrl(val, handleUpdateThumbnail);
			};
			handleCheckUrl(getSocialS3URL(socialContent.imgUrl), callback, true);
		}
		if (socialContent.mediaType === MEDIA_TYPE.VIDEO) {
			const img = new Image();
			img.src = urlThumbnailDefault;

			img.onerror = () => {
				changeUrlThumbnail(PLACEHOLDER_IMAGE_IN_BASE64);
			};
		}
		return () => {
			setLoadError(true);
			setLoadedSuccess(false);
			setVisibleImage(false);
			setLoading(false);
			refMount.current = false;
			setIsRendered(false);
			if (refTime.current) {
				clearTimeout(refTime.current);
			}
		};
	}, [socialContent.id]);

	const detectDimension = () => {
		if (imgRef.current) {
			const heightTemp: number | undefined = imgRef.current?.offsetHeight;
			setDimension(heightTemp || 0);
		}
		if (videoRef.current) {
			const heightTemp: number | undefined = videoRef.current?.offsetHeight;

			videoRef.current.poster = urlThumbnail;

			fetchPosterOfVideo(videoRef.current);

			setDimension(heightTemp || 0);
		}
	};

	useEffect(() => {
		if (loadedSuccess) {
			setLoading(false);

			detectDimension();
		}
	}, [loadedSuccess]);

	useEffect(() => {
		if (socialContent.imgUrl) {
			if (refTime.current) {
				clearTimeout(refTime.current);
			}

			if (!loadedSuccess && refMount.current) {
				setLoading(true);
				const objInfo = {
					timeout: TIME_OUT.SOCIAL,
					message: t('feeds.message_image_unavailable'),
				};

				if (socialContent.mediaType === MEDIA_TYPE.VIDEO) {
					objInfo.timeout = TIME_OUT.VIDEO;
					objInfo.message = t('feeds.message_video_unavailable');
				}

				refTime.current = setTimeout(() => {
					message.warning(objInfo.message);
					setLoading(false);
				}, objInfo.timeout);
			}
		}
	}, [socialContent.imgUrl, loadedSuccess]);

	const handleRender = () => {
		setTimeout(() => {
			setIsRendered(true);
		}, UNIT_VALUE.S_100);
	};

	const onLoadHandler = (data: any) => {
		if (imgRef.current) {
			const width: number = imgRef.current.naturalWidth;
			const height: number = imgRef.current.naturalHeight;
			setVisibleImage(false);

			handleChangeLoadedSuccess(true);
			handleRender();
		}
	};

	const handleVideoError = async (e: any) => {
		setIsRendered(false);
		if (loadedError) {
			setLoadError(false);

			const src = await handleLoadedVideoSocialError(null, socialContent);

			if (src && refMount.current) {
				updateMediaUrl(src);
			}
		}
	};

	const detectVideoResolution = (e: any) => {
		if (videoRef?.current) {
			const width: number = videoRef.current?.videoWidth;
			const height: number = videoRef.current?.videoHeight;
			videoRef.current.poster = '';
			setVisibleImage(false);
			handleChangeLoadedSuccess(true);
			handleRender();
		}
	};

	useEffect(() => {
		if (isVideo && videoRef.current && typeof videoRef.current?.load === 'function') {
			videoRef.current.load();
		}
	}, [socialContent.imgUrl]);

	const renderVideoTag = () => {
		const hasHttp = checkIsUrl(socialContent?.imgUrl);
		const otherProps: any = {};
		if (hasHttp) {
			otherProps.src = getSocialS3URL(socialContent?.imgUrl);
		}

		return (
			<StyledVideo
				id="video-detail_detect"
				ref={(element) => {
					videoRef.current = element;
				}}
				className={`${hasLoadingSkeleton ? 'had_loading' : ''}`}
				onLoadedMetadata={(e) => {
					detectVideoResolution(e);
				}}
				onError={handleVideoError}
				poster={urlThumbnail}
				controls>
				<source
					src={`${getSocialS3URL(socialContent?.imgUrl)}#t=0.01`}
					type={ACCEPT_FILES.MINE_TYPE_MP4}
				/>
			</StyledVideo>
		);
	};

	return (
		<StyledWrapperPosition className="position_media">
			{isVideo && renderVideoTag()}
			{!isVideo && (
				<StyledImage
					id="image-detail"
					ref={(element) => {
						imgRef.current = element;
					}}
					alt={t('feeds.image_unavailable')}
					className={`${visibleImage ? ' place_img' : ''} ${
						hasLoadingSkeleton ? 'had_loading' : ''
					}`}
					src={urlThumbnail}
					visibleImage={visibleImage}
					loading="lazy"
					onLoad={onLoadHandler}
					onError={async (e: any) => {
						if (loadedError) {
							setIsRendered(false);
							setVisibleImage(true);
						}
					}}
					{...other}
				/>
			)}
			{hasLoadingSkeleton && (
				<StyledSkeletonImage
					className="ant-skeleton-active"
					bgColor={THEME.colors.skeletonBgDark}
					bgImg={THEME.colors.linearGradientDark}
				/>
			)}
		</StyledWrapperPosition>
	);
};

export default SocialMediaLoading;
