import THEME from '@constants/themes/themes';
import { Modal } from 'antd';
import styled, { css } from 'styled-components';

const square = 595;
const landscape = 579;
const portrait = 712;

const widthContent = 350;

const squareSizeStyles = css`
	width: auto;
	height: auto;
	min-width: 400px;
	min-height: 400px;
	max-width: ${square}px;
	max-height: ${square}px;
`;

const landscapeSizeStyles = css`
	width: auto;
	height: auto;
	min-width: ${landscape}px;
	min-height: 379px;
	max-width: 779px;
	max-height: ${landscape}px;
`;

const portraitSizeStyles = css`
	width: auto;
	height: auto;
	min-width: 318px;
	min-height: 512px;
	max-width: 518px;
	max-height: ${portrait}px;
`;

const StyledPreviewThumbnailModal = styled(Modal)`
	max-width: 1200px;
	.ant-modal-body {
		padding: 0;
	}
	.ant-modal-content {
		background: transparent;
		box-shadow: none;
	}
`;

const StyledWrapperContainer = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	margin: auto;
`;

const StyledWrapperPopup = styled.div`
	position: relative;
`;

const StyledIconChangeContent = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 32px;
	height: 32px;
	cursor: pointer;
	z-index: 7777;
	&.prev {
		left: 0;
	}
	&.next {
		right: 0;
	}
	svg {
		fill: ${THEME.colors.white} !important;
		width: 32px;
		height: 32px;
	}
`;

const StyledIconClose = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 24px;
	height: 24px;
	margin-right: 12px;
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	svg {
		width: 18px;
		height: 18px;
		fill: ${THEME.colors.gray5};
	}
`;

const StyledThumbnail = styled.div`
	height: 100%;
	max-width: calc(100% - ${widthContent}px);
	background: ${THEME.colors.darkBlue2};
`;

const StyledWrapperContent = styled.div`
	padding: 0 30px 30px 30px;
	height: calc(100% - 40px);
	overflow: auto;
`;

const StyledFooterLightBox = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	width: 100%;
`;

const StyledLinkFooterEntribe = styled.a`
	font-size: 12px;
	font-style: italic;
	font-weight: 400;
	color: ${THEME.colors.white};
`;

const StyledContainer = styled.div<{ height: number }>`
	width: ${widthContent}px;
	background: rgba(22, 22, 22, 0.5);
	height: ${({ height }) => height}px;
	position: relative;
	padding-top: 40px;
	word-break: break-all;
`;

const StyledImg = styled.img<{ rotate?: number }>`
	transform: rotate(${({ rotate }) => rotate || 0}deg);
	height: 100%;
	width: 100%;
	object-fit: cover;
	&.square {
		${squareSizeStyles}
	}

	&.landscape {
		${landscapeSizeStyles}
	}

	&.portrait {
		${portraitSizeStyles}
	}
`;

const StyledNameUser = styled.a`
	display: inline-flex;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${THEME.colors.white};
	svg {
		margin-right: 5px;
	}
	&:hover {
		color: ${THEME.colors.orangeBase};
		svg {
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}
`;

const StyledWrapperUserName = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
	svg {
		margin-right: 5px;
	}
	&.size_16 {
		margin-bottom: 8px;
		margin-left: 12px;
		svg {
			width: 16px;
			height: 16px;
		}
	}
`;

const StyledCaption = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: ${THEME.colors.white};
	margin-bottom: 10px;
`;

const StyledWrapperEditor = styled.div`
	position: relative;
	min-height: 212px;
	&:hover {
		border: 2px solid ${THEME.colors.orangeBase};
		border-radius: 8px;
		.icon_orange {
			display: block;
		}
	}
	.icon_orange {
		display: none;
	}
	&.show_edit {
		border: 2px solid ${THEME.colors.orangeBase};
		border-radius: 8px;
		height: 212px;
	}

	.tox-edit-area__iframe {
		background-color: transparent;
		color: ${THEME.colors.gray5};
	}
	.tox-editor-header {
		width: 224px;
		background-color: transparent;
	}
	.tox-tinymce {
		border: 0px;
		.tox-toolbar__primary {
			border: 0px !important;
			background: transparent;
		}
	}
	.editor_gallery {
		color: white;
	}
`;

const StyledContentCustomDetail = styled.div`
	padding: 16px;
	color: ${THEME.colors.gray5};
	a {
		color: ${THEME.colors.blueBase};
		text-decoration: underline;
	}
`;

const StyledActionEditor = styled.div`
	position: absolute;
	bottom: 1px;
	right: 1px;
	height: 55px;
	display: inline-flex;
	align-items: center;
	padding: 8px;
`;

export {
	StyledPreviewThumbnailModal,
	StyledWrapperPopup,
	StyledThumbnail,
	StyledImg,
	StyledWrapperContent,
	StyledContainer,
	StyledNameUser,
	StyledCaption,
	StyledIconChangeContent,
	StyledFooterLightBox,
	StyledIconClose,
	StyledWrapperUserName,
	StyledWrapperContainer,
	StyledLinkFooterEntribe,
	StyledWrapperEditor,
	StyledActionEditor,
	StyledContentCustomDetail,
};
