import { CREATOR_ACTIONS, IMG_LOCAL_DEFAULT } from '@constants/campaign';
import { TYPE_ACTIONS } from '@constants/common';
import { CampaignEmailTemplateDto, ContentImgDto, ImgFileCampaign } from '@models/campaign';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import * as React from 'react';

export type CampaignDetailContextType = {
	hashtagFooter: string[];
	typeCampaign: string;
	handleReset: () => void;
	img1: ContentImgDto | null;
	img2: ContentImgDto | null;
	img3: ContentImgDto | null;
	setImg1: (val: ContentImgDto | null) => void;
	setImg2: (val: ContentImgDto | null) => void;
	setImg3: (val: ContentImgDto | null) => void;
	headerVal: string;
	descVal: string;
	handleRedirectCampaignPage: () => void;
	handleSubmit: (val: string, restore?: boolean) => void;
	setTypeAction: (val: string) => void;
	handleMockup: () => void;
	handleValidateForm: () => void;
	handleUpdateSuggestId: (val: number) => void;
	creatorActions: string[];
	setCreatorActions: (action: string[]) => void;
	setHashtagFooter: (term: string[]) => void;
	campaignEmailTemplate?: CampaignEmailTemplateDto;
	setPreviewTemplate: (template: EmailTemplatesDataDto) => void;
	onPreviewTemplate: (fieldName: any) => void;
	disabledFields: string[];
	hasChange: boolean;
	setHasChange: (hasChange: boolean) => void;
};

// Default context values
const values: CampaignDetailContextType = {
	hashtagFooter: [],
	typeCampaign: TYPE_ACTIONS.CREATE,
	handleReset: () => {},
	img1: null,
	img2: null,
	img3: null,
	setImg1: () => {},
	setImg2: () => {},
	setImg3: () => {},
	headerVal: '',
	descVal: '',
	handleRedirectCampaignPage: () => {},
	handleSubmit: () => {},
	setTypeAction: () => {},
	handleMockup: () => {},
	handleValidateForm: () => {},
	handleUpdateSuggestId: () => {},
	creatorActions: [CREATOR_ACTIONS.UPLOAD_CONTENT_DIRECTLY],
	setCreatorActions: () => {},
	setHashtagFooter: () => {},
	campaignEmailTemplate: {},
	setPreviewTemplate: () => {},
	onPreviewTemplate: () => {},
	disabledFields: [],
	hasChange: false,
	setHasChange: () => {},
};

const CampaignDetailContext = React.createContext(values);
export default CampaignDetailContext;
