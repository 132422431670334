import { IconKeyboardArrowRight } from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { DEFAULT_VERSION_ID, UPLOADSOURCE, UPLOADMETHOD } from '@constants/content/contentLibrary';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { ContentRootStateDto, ContentStoreDto } from '@models/content/contentLibrary/store';
import { StyledIcon, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledCollapse,
	StyledCollapseInfo,
	StyledInfoItem,
	StyledLabelInfo,
	StyledOptionInfo,
	SwapperSessionDetail,
} from '@styled/ContentLibrary/ContentDetailStyled';
import { getDirectUploadSourceText } from '@utils/content';
import { Tooltip } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Panel } = StyledCollapse;

type PropTypes = {};

const VersionDetails = (props: PropTypes) => {
	const { t } = useTranslation();
	const { imgVersions }: ContentStoreDto = useSelector(
		(state: ContentRootStateDto) => state.content,
	);
	const { currentMedia, versionImgSelected } = useContext(ContentDetailContext);

	const renderListOption = () => {
		const { uploadLocation, uploadDate, uploadSource, uploadMethod } = currentMedia || {};
		const { name, createAt, author, method } = versionImgSelected || {};
		const titleSource = getDirectUploadSourceText(uploadSource, uploadMethod);
		const thumbVersion0 =
			imgVersions?.length > 0
				? imgVersions.find((ver) => ver.id === DEFAULT_VERSION_ID)
				: null;
		const isVersion0 = thumbVersion0?.id === versionImgSelected?.id;

		const methodVersion = method || t('content_detail.edited_in_app');

		const result = [
			{
				title: t('content_detail.version_displayed'),
				info: isVersion0 ? t('content_detail.original') : name,
				id: 1,
			},
			{
				title: t('content_detail.version_created'),
				info: createAt
					? convertUtcToLocalTimeWithFormat(createAt, TYPE_FORMAT_DATE.TIME)
					: '',
				id: 2,
			},
			{
				title: t('content_detail.version_author'),
				info: author,
				id: 3,
			},
			{
				title: t('content_detail.version_method'),
				info: isVersion0 ? '' : methodVersion,
				id: 4,
			},
			{
				title: t('content_detail.original_added'),
				info: uploadDate
					? convertUtcToLocalTimeWithFormat(uploadDate, TYPE_FORMAT_DATE.TIME)
					: '',
				style: { marginTop: '24px' },
				id: 5,
			},
			{
				title: t('content_detail.original_source'),
				info: t(titleSource),
				id: 6,
			},
			{
				title: t('content_detail.upload_location'),
				info: isVersion0 ? uploadLocation : '',
				id: 7,
			},
		];
		return result;
	};

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo>
				<StyledTitle>{t('content_detail.version_details')}</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	return (
		<SwapperSessionDetail>
			<StyledCollapse
				className="collapse_content_detail"
				expandIconPosition="right"
				style={{ marginBottom: '0' }}
				defaultActiveKey={'1'}>
				<Panel header={renderHeaderPanel()} key={'1'}>
					{renderListOption()?.map((item) => (
						<StyledOptionInfo style={item?.style || {}} key={item?.id}>
							<StyledLabelInfo>{item?.title}</StyledLabelInfo>
							{item?.info && (
								<Tooltip
									placement="topLeft"
									getPopupContainer={(triggerNode: HTMLElement) =>
										triggerNode.parentNode as HTMLElement
									}
									overlayClassName="content-info-tooltip"
									title={item?.info}>
									<StyledInfoItem>{item?.info}</StyledInfoItem>
								</Tooltip>
							)}
						</StyledOptionInfo>
					))}
				</Panel>
			</StyledCollapse>
		</SwapperSessionDetail>
	);
};

export default VersionDetails;
