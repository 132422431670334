import { IconAddCircleOutline, IconDelete, IconEdit, IconMoreVert, IconShare } from '@assets/icons';
import { TYPE_ACTIONS } from '@constants/common';
import { MAX_RECORD_ALL, MAX_RECORD_SUGGEST_DEFAULT, TYPE_ALBUM } from '@constants/content/albums';
import { PARAMS_DEFAULT_CONTENT } from '@constants/publish/gallery';
import { FAILED, SUCCEEDED } from '@constants/status';
import AlbumManagerContext from '@contexts/Content/AlbumManager';
import SearchBar from '@cores/SearchBar';
import { getUser } from '@helpers/userHelpers';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import { exportAlbumContentsEnd, exportAlbumContentsRequest } from '@stores/actions';
import { StyledSubMenu } from '@styled/Common/CommonStyled';
import { StyledAction } from '@styled/Content/AlbumManager/AlbumModalStyled';
import { StyledWrapperHeader } from '@styled/Content/AlbumManager/HeaderAlbumStyled';
import { StyledButtonMore, StyledDropdown } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { Button, Menu, Tooltip, message } from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const HeaderAlbums = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const user = getUser();
	const { brandName, email } = user;
	const { statusExportAlbumContents }: AlbumsStoreDto = useSelector(
		(state: any) => state.albumManager,
	);

	const {
		setVisibleAlbumPopup,
		setParamAlbums,
		albumsSelected,
		albumsEnable,
		setTypeConfirm,
		hasSystemAlbum,
		setShowSuggest,
		showSuggest,
		setParamAlbumSuggest,
		paramAlbums,
		paramAlbumSuggest,
		hasFavoriteAlbum,
		listAlbum,
		setVisibleShareModal,
	} = useContext(AlbumManagerContext);

	const onPressSearch = (value: string) => {
		setParamAlbums((prev: any) => ({ ...prev, page: 1, search: value?.trim() || '' }));
	};

	const onExportContent = (otherParam: {
		isExportFileExcel?: boolean;
		isExportFileCsv?: boolean;
	}) => {
		const albumDetail = listAlbum.find((album) => album.id === albumsSelected[0]);
		const params = {
			brandName,
			accountEmail: email,
			albumName: albumDetail?.name,
			webUrl: window.location.href,
			...otherParam,
		};

		const obj: any = {};
		if (albumDetail?.type === TYPE_ALBUM.VERIFIED_ALBUM) {
			obj.customTerms = true;
			obj.verified = true;
		}
		const body = {
			...PARAMS_DEFAULT_CONTENT,
			...obj,
			search: '',
			folder: albumDetail?.id?.toString() || '',
		};
		dispatch(exportAlbumContentsRequest({ params, body }));
	};

	useEffect(() => {
		if (statusExportAlbumContents === SUCCEEDED) {
			message.config({
				duration: 6,
			});
			message.success(t('albums.message.export_album_content_email'));
		}
		if (statusExportAlbumContents === FAILED || statusExportAlbumContents === SUCCEEDED) {
			dispatch(exportAlbumContentsEnd());
			message.config({
				duration: 3,
			});
		}
	}, [statusExportAlbumContents]);

	const actionMoreObj: ActionsTypes[] = [
		{
			id: 1,
			name: t('albums.button.show_all_suggestion'),
			click: () => {
				setParamAlbumSuggest({ maxRecords: MAX_RECORD_ALL });
				setShowSuggest(true);
			},
			disabled:
				!!paramAlbums?.search ||
				(paramAlbumSuggest?.maxRecords === MAX_RECORD_ALL && showSuggest),
		},
		{
			id: 2,
			name: t('albums.button.export_album'),

			click: () => {},
			children: [
				{
					id: 3,
					name:
						albumsSelected?.length === 1 ? (
							t('albums.button.microsoft_excel')
						) : (
							<Tooltip title={t('albums.message.required_album_selected_export')}>
								{t('albums.button.microsoft_excel')}
							</Tooltip>
						),
					click: () => {
						onExportContent({ isExportFileExcel: true });
					},
					disabled: albumsSelected.length !== 1,
				},
				{
					id: 4,
					name:
						albumsSelected?.length === 1 ? (
							t('albums.button.comma_separated_values')
						) : (
							<Tooltip title={t('albums.message.required_album_selected_export')}>
								{t('albums.button.comma_separated_values')}
							</Tooltip>
						),
					click: () => {
						onExportContent({ isExportFileCsv: true });
					},
					disabled: albumsSelected.length !== 1,
				},
			],
		},
	];

	const renderMenuAction = (actionList: ActionsTypes[]) => {
		return (
			<Menu>
				{actionList.map((item: ActionsTypes) => {
					if (item?.hidden) {
						return null;
					}
					const menuItem = (action: ActionsTypes) => {
						return (
							<Menu.Item
								key={action.id}
								onClick={action.click}
								disabled={action?.disabled}>
								{action.name}
							</Menu.Item>
						);
					};
					if (item.children) {
						return (
							<StyledSubMenu
								key={item.id}
								popupOffset={[0, 0]}
								className="hidden_icon"
								title={item.name}>
								{item?.children.map((ch) => menuItem(ch))}
							</StyledSubMenu>
						);
					}
					return menuItem(item);
				})}
			</Menu>
		);
	};

	return (
		<StyledWrapperHeader className="custom_scroll_bar">
			<StyledAction>
				<SearchBar
					placeholder={t('albums.placeholder.search_albums')}
					onSearch={onPressSearch}
					enterButton
				/>
				<Button
					disabled={!!paramAlbums?.search}
					onClick={() => {
						if (!showSuggest) {
							setParamAlbumSuggest({ maxRecords: MAX_RECORD_SUGGEST_DEFAULT });
						}
						setShowSuggest(!showSuggest);
					}}
					style={{ marginLeft: '10px' }}>
					{t(
						`albums.button.${
							showSuggest ? 'hide_suggested_album' : 'show_suggested_album'
						}`,
					)}
				</Button>
				<StyledDropdown
					className="dropdown_action"
					overlay={renderMenuAction(actionMoreObj)}
					getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
					placement="bottomLeft"
					trigger={['click']}>
					<StyledButtonMore>
						<IconMoreVert />
					</StyledButtonMore>
				</StyledDropdown>
			</StyledAction>
			<StyledAction className="right_elm">
				<Button
					icon={<IconDelete />}
					disabled={albumsSelected?.length === 0 || hasSystemAlbum || hasFavoriteAlbum}
					onClick={() => setTypeConfirm(TYPE_ACTIONS.REMOVE_ALBUM)}
					style={{ marginLeft: '10px' }}>
					{t('albums.button.delete_album')}
				</Button>
				<Button
					icon={<IconEdit />}
					disabled={albumsSelected?.length !== 1}
					onClick={() => setVisibleAlbumPopup(TYPE_ACTIONS.EDIT)}
					style={{ marginLeft: '10px' }}>
					{t('albums.button.edit_album')}
				</Button>
				<Button
					style={{ marginLeft: '10px' }}
					icon={<IconShare />}
					onClick={() => {
						setVisibleShareModal(true);
					}}>
					{t('albums.button.share')}
				</Button>

				<Button
					icon={<IconAddCircleOutline />}
					onClick={() => setVisibleAlbumPopup(TYPE_ACTIONS.CREATE)}
					style={{ marginLeft: '10px' }}>
					{t('albums.button.create_new_album')}
				</Button>
			</StyledAction>
		</StyledWrapperHeader>
	);
};

export default HeaderAlbums;
