import { IconClick, IconFire, IconFollowPerson, IconImgSubmission, IconInbox } from '@assets/icons';
import MetricCard from '@components/SocialSearch/Feeds/MetricFeeds/MetricCard';
import { LIMIT_VAL } from '@constants/ambassador';
import { ROUTE_PATH, TYPE_FORMAT_DATE } from '@constants/common';
import THEME from '@constants/themes/themes';
import AmbassadorDashboardManagerContext from '@contexts/AmbassadorDashboard';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { AmbassadorManagerStoreDto } from '@models/ambassador/stores';
import { CardMetricType } from '@models/socialsearch/summary';
import { UserStoreType } from '@stores/creator/creator.types';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledContainerMetrics } from '@styled/SocialSearch/Feeds';
import { abbreviateNumber } from '@utils/common';
import { Tooltip } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MetricAmbassador = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { loadingAmbassadorMetric, ambassadorMetric }: AmbassadorManagerStoreDto = useSelector(
		(state: any) => state?.ambassadors,
	);
	const {
		allCreator,
		allCreatorDelta,
		allPotentialReach,
		allPotentialReachDelta,
		allSocialPosts,
		allSocialPostsDelta,
		allTotalLikesAndComments,
		allTotalLikesAndCommentsDelta,
		campaignGoals,
		allSubmissions,
		allSubmissionsDelta,
		lastUpdated,
		beforeUpdated,
	} = ambassadorMetric;
	const { creatorsGoals, socialPostsGoals, submissionsGoals } = campaignGoals || {};

	const { segmentList = [] }: UserStoreType = useSelector((state: any) => state.creator);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { ambassadorMetricsRefreshUpdateAt = 0 } = clientSettings || {};

	const { setShowRecentBox, campaignIdSelected, segmentIdSelected } = useContext(
		AmbassadorDashboardManagerContext,
	);
	const hasFilter = campaignIdSelected || segmentIdSelected;
	const filterOnlySegment = segmentIdSelected && !campaignIdSelected;

	const renderSubDetail = (val: number, sub?: string) => {
		const obj = {
			colorSubValue: THEME.colors.gray1,
			subValue: '',
		};

		if (val || val === 0) {
			obj.subValue = `(${val >= 0 ? '+' : ''}${abbreviateNumber(val)}${sub || ''})`;
		}
		if (val) {
			obj.colorSubValue = val < 0 ? THEME.colors.redBase : THEME.colors.greenBase;
		}
		return obj;
	};

	const renderNumberFormat = (val: number) => {
		return abbreviateNumber(val, val < LIMIT_VAL.S100k ? 1 : 0);
	};

	const renderDetailValue = (val: number, goal?: number) => {
		if (!hasFilter) {
			return renderNumberFormat(val);
		}
		if (filterOnlySegment) {
			return renderNumberFormat(val);
		}
		return `${renderNumberFormat(val)} / ${renderNumberFormat(goal || 0)}`;
	};

	const checkGoals = (val?: number, goal?: number) => {
		if (!val || !campaignIdSelected) {
			return false;
		}
		if (!goal) {
			return true;
		}
		return val >= goal;
	};

	const dateTime = convertUtcToLocalTimeWithFormat(lastUpdated, TYPE_FORMAT_DATE.TIME);
	const dateBefore = convertUtcToLocalTimeWithFormat(beforeUpdated, TYPE_FORMAT_DATE.TIME);

	const listCard: CardMetricType[] = [
		{
			id: 2,
			name: t('sidebar.creators'),
			value: renderDetailValue(allCreator, creatorsGoals),
			icon: <IconFollowPerson />,
			loadingValue: loadingAmbassadorMetric,
			...renderSubDetail(allCreatorDelta),
			tooltipTitle: t('ambassador.tooltip.ambassador_creators', {
				time1: dateTime,
				time2: dateBefore,
			}),
			full: checkGoals(allCreator, creatorsGoals),
			click: () => history.push(ROUTE_PATH.CREATORS_SUMMARY),
		},
		{
			id: 10,
			name: t('sidebar.submissions'),
			value: renderDetailValue(allSubmissions, submissionsGoals),
			icon: <IconImgSubmission />,
			loadingValue: loadingAmbassadorMetric,
			...renderSubDetail(allSubmissionsDelta),
			tooltipTitle: t('ambassador.tooltip.ambassador_submissions', {
				time1: dateTime,
				time2: dateBefore,
			}),
			full: checkGoals(allSubmissions, submissionsGoals),
			click: () => history.push(ROUTE_PATH.SUBMISSIONS),
		},
		{
			id: 3,
			name: t('ambassador.social_posts'),
			value: renderDetailValue(allSocialPosts, socialPostsGoals),
			icon: <IconInbox />,
			loadingValue: loadingAmbassadorMetric,
			...renderSubDetail(allSocialPostsDelta),
			tooltipTitle: t('ambassador.tooltip.ambassador_social_posts', {
				time1: dateTime,
				time2: dateBefore,
			}),
			click: () => setShowRecentBox(true),
			full: checkGoals(allSocialPosts, socialPostsGoals),
		},
		{
			id: 4,
			name: t('ambassador.likes_comments'),
			value: renderNumberFormat(allTotalLikesAndComments),
			icon: <IconClick />,
			loadingValue: loadingAmbassadorMetric,
			...renderSubDetail(allTotalLikesAndCommentsDelta, '%'),
			tooltipTitle: t('ambassador.tooltip.ambassador_likes_comments', {
				time1: dateTime,
				time2: dateBefore,
			}),
		},
		{
			id: 5,
			name: t('ambassador.potential_reach'),
			value: renderNumberFormat(allPotentialReach),
			icon: <IconFire />,
			loadingValue: loadingAmbassadorMetric,
			...renderSubDetail(allPotentialReachDelta, '%'),
			tooltipTitle: t('ambassador.tooltip.ambassador_potential_reach', {
				time1: dateTime,
				time2: dateBefore,
			}),
		},
	];

	return (
		<StyledWrapperContent margin="12px 0" width="100%">
			<StyledContainerMetrics>
				{listCard.map((item) => {
					if (item?.hidden) {
						return null;
					}
					const { click, ...otherItem } = item;
					const otherProps: any = {};
					if (click && typeof click === 'function') {
						otherProps.onClick = click;
						otherProps.style = { cursor: 'pointer' };
					}
					if (!item.tooltipTitle) {
						return (
							<MetricCard
								bgColor={
									hasFilter ? THEME.colors.darkBlue6 : THEME.colors.darkBlue2
								}
								key={otherItem.id}
								{...otherProps}
								card={otherItem}
							/>
						);
					}
					return (
						<Tooltip
							placement="bottom"
							key={otherItem.id}
							title={otherItem.tooltipTitle}>
							<MetricCard
								bgColor={
									hasFilter ? THEME.colors.darkBlue6 : THEME.colors.darkBlue2
								}
								card={otherItem}
								{...otherProps}
							/>
						</Tooltip>
					);
				})}
			</StyledContainerMetrics>
		</StyledWrapperContent>
	);
};

export default MetricAmbassador;
