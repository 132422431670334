import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Component
import { Select } from 'antd';
import GenerateReport from '@layout/Header/Filter/Creators/Dashboard';

// Hook
import { useTranslation } from 'react-i18next';

// Model
import { ItemListContent, StateCampaign } from '@models/campaign';

// Icons

// Constant
import THEME from '@constants/themes/themes';
import { StyledSelect } from '@styled/Common/CommonStyled';
import { useDispatch, useSelector } from 'react-redux';
import { IconCancel } from '@assets/icons';
import { SelectValue } from 'antd/lib/select';
import {
	StyledAction,
	StyledFilterContainer,
	StyledIcon,
	StyledText,
} from '@styled/Campaign/CampaignStyled';
import { useHistory, useLocation } from 'react-router-dom';
import { getSegment, saveFilterDashboardDefault } from '@stores/actions';
import FilterSelect, { FilterSelectTypes, GroupOption } from '@components/Filter/FilterSelect';

const { Option, OptGroup } = Select;

type TopActionProps = {
	getDashboardData: (params?: any) => void;
};

const groups = {
	creatorSegment: 'creatorSegment',
	campaign: 'campaign',
};

const TopAction = (props: TopActionProps) => {
	const { t } = useTranslation();
	const { getDashboardData } = props;
	const dispatch = useDispatch();
	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);
	const { filterDefault } = useSelector((state: any) => state.dashboard);
	const { segmentList = [] } = useSelector((state: any) => state.creator);

	const [filterSelect, setFilterSelect] = useState<FilterSelectTypes | null>(null);

	const onChangeFilter = (val: FilterSelectTypes | null) => {
		setFilterSelect(val);
		if (val) {
			if (val?.group === groups?.campaign) {
				getDashboardData({ campaignId: val?.value });
			}
			if (val?.group === groups?.creatorSegment) {
				getDashboardData({ segmentId: val?.value });
			}
		} else {
			getDashboardData();
		}
	};

	useEffect(() => {
		if (filterDefault?.campaign) {
			const { campaign } = filterDefault || {};
			setFilterSelect({ value: campaign.id, group: groups?.campaign, name: campaign.name });
			dispatch(saveFilterDashboardDefault());
		}
	}, [filterDefault]);

	useEffect(() => {
		dispatch(getSegment({ page: 1, pageRecords: 9999 }));
	}, []);

	const listGroup = [
		{
			group: groups.campaign,
			options:
				campaignListContent?.map((item) => ({
					value: item?.id || '',
					name: item.name || '',
				})) || [],
			title: t('dashboard.top_action.campaign'),
		},
		{
			group: groups.creatorSegment,
			options:
				segmentList?.map((item: any) => ({
					value: item?.id || '',
					name: item?.title || '',
				})) || [],
			title: t('dashboard.top_action.creator_segment'),
		},
	];

	return (
		<StyledAction>
			<GenerateReport />
			<FilterSelect
				isGroup={true}
				selectedValue={filterSelect}
				onChange={onChangeFilter}
				listGroup={listGroup}
				placeholderDefault={t('dashboard.top_action.no_filter')}
				placeholderOpen={t('dashboard.top_action.filter_by_campaign_segment')}
				subApplyFilter={
					t('dashboard.top_action.filtering', {
						name: t(
							`dashboard.top_action.${
								filterSelect?.group === groups?.campaign
									? 'campaign'
									: 'creator_segment'
							}`,
						),
					}) + ' '
				}
			/>
		</StyledAction>
	);
};

export default React.memo(TopAction);
