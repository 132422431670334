const ambassadorTypes = {
	GET_AMBASSADOR_POSTS_REQUEST: 'GET_AMBASSADOR_POSTS_REQUEST',
	GET_AMBASSADOR_POSTS_SUCCESS: 'GET_AMBASSADOR_POSTS_SUCCESS',
	GET_AMBASSADOR_POSTS_FAILED: 'GET_AMBASSADOR_POSTS_FAILED',

	GET_AMBASSADOR_LEADER_BOARD_REQUEST: 'GET_AMBASSADOR_LEADER_BOARD_REQUEST',
	GET_AMBASSADOR_LEADER_BOARD_SUCCESS: 'GET_AMBASSADOR_LEADER_BOARD_SUCCESS',
	GET_AMBASSADOR_LEADER_BOARD_FAILED: 'GET_AMBASSADOR_LEADER_BOARD_FAILED',

	GET_AMBASSADOR_METRIC_REQUEST: 'GET_AMBASSADOR_METRIC_REQUEST',
	GET_AMBASSADOR_METRIC_SUCCESS: 'GET_AMBASSADOR_METRIC_SUCCESS',
	GET_AMBASSADOR_METRIC_FAILED: 'GET_AMBASSADOR_METRIC_FAILED',

	GET_AMBASSADOR_GRAPH_POSTS_REQUEST: 'GET_AMBASSADOR_GRAPH_POSTS_REQUEST',
	GET_AMBASSADOR_GRAPH_POSTS_SUCCESS: 'GET_AMBASSADOR_GRAPH_POSTS_SUCCESS',
	GET_AMBASSADOR_GRAPH_POSTS_FAILED: 'GET_AMBASSADOR_GRAPH_POSTS_FAILED',

	GET_AMBASSADOR_GRAPH_VOTES_REQUEST: 'GET_AMBASSADOR_GRAPH_VOTES_REQUEST',
	GET_AMBASSADOR_GRAPH_VOTES_SUCCESS: 'GET_AMBASSADOR_GRAPH_VOTES_SUCCESS',
	GET_AMBASSADOR_GRAPH_VOTES_FAILED: 'GET_AMBASSADOR_GRAPH_VOTES_FAILED',

	GET_AMBASSADOR_REPORT_REQUEST: 'GET_AMBASSADOR_REPORT_REQUEST',
	GET_AMBASSADOR_REPORT_SUCCESS: 'GET_AMBASSADOR_REPORT_SUCCESS',
	GET_AMBASSADOR_REPORT_FAILED: 'GET_AMBASSADOR_REPORT_FAILED',

	REFRESH_METRIC_AMBASSADOR_REQUEST: 'REFRESH_METRIC_AMBASSADOR_REQUEST',
	REFRESH_METRIC_AMBASSADOR_SUCCESS: 'REFRESH_METRIC_AMBASSADOR_SUCCESS',
	REFRESH_METRIC_AMBASSADOR_FAILED: 'REFRESH_METRIC_AMBASSADOR_FAILED',

	RESET_FIELDS_AMBASSADOR: 'RESET_FIELDS_AMBASSADOR',
};

export { ambassadorTypes };
