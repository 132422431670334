import { TYPE_THEME } from '@constants/publish/gallery';
import THEME from '@constants/themes/themes';
import styled from 'styled-components';

const StyledBlockThemeMosaic = styled.div<{ theme?: string; height: number }>`
	position: relative;
	display: grid;
	grid-template-columns: ${({ theme, height }) =>
		theme === TYPE_THEME.SIGNAGE ? `repeat(4, ${height}px)` : `repeat(4, ${height}px)`};
	grid-template-rows: ${({ theme, height }) =>
		theme === TYPE_THEME.SIGNAGE ? `repeat(2, ${height}px)` : `repeat(2, ${height}px)`};
	grid-auto-flow: column dense;
	gap: 12px;
	margin-left: 0px;
	justify-content: center;
	&.block_small {
		grid-template-columns: ${({ theme, height }) =>
			theme === TYPE_THEME.SIGNAGE ? `repeat(3, ${height}px)` : `repeat(3, ${height}px)`};
	}
	.slick-cloned {
		display: none !important;
	}
`;

const StyledQRCode = styled.div<{ right?: string; position?: string }>`
	width: 80px;
	height: 80px;
	background-color: #fff;
	border-radius: 2px;

	svg {
		width: 100%;
		height: 100%;
	}
`;

const StyledSignageMessage = styled.div<{ ctaBackground: string; fontQRCodeColor: string }>`
	position: sticky;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-color: ${({ ctaBackground }) => ctaBackground || THEME.colors.white};
	font-size: 16px;
	color: ${({ fontQRCodeColor }) => fontQRCodeColor || THEME.colors.black};
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	width: calc(100% - 20px);
	padding: 12px;
	margin-left: 10px;
	text-align: right;
`;

const StyledSignageMessageText = styled.div<{ padding?: string }>`
	font-family: 'Ropa Sans', sans-serif;
	display: -webkit-box;
	max-width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	white-space: pre-line;
	margin: 0;
	${({ padding }) => `padding: ${padding}`}; // add padding-right to show ellipsis
	font-size: 22px;
	line-height: 1.15;
`;

export { StyledBlockThemeMosaic, StyledQRCode, StyledSignageMessage, StyledSignageMessageText };
