import { ImagePublishWidget } from '@assets/images';
import { genericWidgetUrl } from '@config/index';
import { TYPE_ACTIONS } from '@constants/common';
import {
	DEFAULT_ADVANCE_SETTING_WIDGET,
	KEY_ADVANCE_SETTING,
	TAB_WIDGET,
} from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import { RefConfigWidgetBuilderStep } from '@models/content/widgetManager/ref';
import { publishWidgetBuilderRequest } from '@stores/actions';
import { StyledCol, StyledIcon, StyledRow } from '@styled/Common/CommonStyled';
import {
	StyledFormConfigUrl,
	StyledFormItem,
	StyledInputUrl,
	StyledUrlDefault,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { validateSpecialCharacters } from '@utils/common';
import { Form, message } from 'antd';
import {
	Ref,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import EditConfirmationScreen from './EditConfirmationScreen';
import { StyledLink, StyledWrapperLink } from '@styled/Content/WidgetManager/CurrentDesignStyled';
import { IconEdit } from '@assets/icons';

const StyledStep = styled.div`
	padding: 16px 24px;
`;

const StyledTitleStep = styled.span`
	font-size: 1.714rem;
	font-weight: 800;
	color: ${THEME.colors.gray5};
	display: block;
`;

const StyledNotifySubPublish = styled.span`
	font-size: 0.714rem;
	line-height: 1.2;
	color: ${THEME.colors.gray1};
	margin-top: 2px;
`;

const StyledLogoPublishWidget = styled.img`
	width: 100%;
	max-width: 325px;
`;

const StyledWrapperLogo = styled.div`
	width: 100%;
	margin: auto;
	text-align: center;
	margin: 16px auto;
`;

const StyledLabelCustomize = styled.div`
	margin-top: 16px 24px 0 0;
	font-size: 1.143rem;
	line-height: 1.5;
	color: ${THEME.colors.gray5};
`;

const StyledTextCustomize = styled.div`
	font-size: 1rem;
	line-height: 1.71;
	color: ${THEME.colors.gray5};
	margin-top: 16px;
`;

const StyledContactText = styled.span`
	color: ${THEME.colors.gray1};
	display: block;
`;

const StyledLinkMail = styled.a``;

type PropsSettingStepDto = {
	handleActionStep: (val: number) => void;
};

const SettingStep5 = forwardRef(
	(props: PropsSettingStepDto, ref: Ref<RefConfigWidgetBuilderStep>) => {
		const { t } = useTranslation();
		const dispatch = useDispatch();

		const { handleActionStep } = props;
		const {
			detailWidget,
			setDetailWidget,
			setTypeConfirm,
			hasChangeCustomField,
			setDataPublishNoDraft,
		} = useContext(WidgetBuilderContext);

		const [form] = Form.useForm();
		const [isEditUrl, setIsEditUrl] = useState<boolean>(false);

		const { url, metadata, status, draftId = -1, referenceId, ...otherDetail } = detailWidget;
		const { body } = metadata || {};
		const { advancedSettings } = body || {};
		const {
			confirmationMessage,
			confirmationButtonText,
			socialMessage,
			visibleSocialButtons = false,
		} = advancedSettings || {};

		const validateForm = async (val?: number) => {
			await form.validateFields();
			if (val === 4) {
				form.submit();
			} else {
				handleActionStep(val || 4);
			}
		};

		useImperativeHandle(ref, () => ({
			validateAllFieldCustom: validateForm,
		}));

		useEffect(() => {
			if (!url) {
				setIsEditUrl(true);
			}
			return () => {
				setIsEditUrl(false);
			};
		}, []);

		const initialValues = useMemo(() => {
			const result: any = { url: '' };
			if (url) {
				const idx = url?.lastIndexOf('/');
				if (idx !== -1) {
					result.url = url?.slice(idx + 1);
				}
			}

			result[KEY_ADVANCE_SETTING.CONFIRMATION_MESSAGE] =
				confirmationMessage || DEFAULT_ADVANCE_SETTING_WIDGET.CONFIRMATION_MESSAGE;
			result[KEY_ADVANCE_SETTING.CONFIRMATION_BUTTON_TEXT] =
				confirmationButtonText || DEFAULT_ADVANCE_SETTING_WIDGET.CONFIRMATION_BUTTON_TEXT;
			result[KEY_ADVANCE_SETTING.VISIBLE_SOCIAL_BUTTONS] = visibleSocialButtons || false;
			result[KEY_ADVANCE_SETTING.SOCIAL_MESSAGE] =
				socialMessage || DEFAULT_ADVANCE_SETTING_WIDGET.SOCIAL_MESSAGE;

			form.setFieldsValue({ ...result });
			return result;
		}, []);

		const onValuesChange = (val: any) => {
			const keyName = Object.keys(val)[0];
			const formObject: any = {};

			if (keyName === 'url') {
				const valUrlChange = val?.url?.toLowerCase() || '';

				const valFormat = validateSpecialCharacters(valUrlChange) || '';

				const urlPublish: string = valFormat ? `${genericWidgetUrl}/${valFormat}` : '';

				formObject.url = validateSpecialCharacters(val?.url);
			}

			if (Object.values(KEY_ADVANCE_SETTING)?.includes(keyName)) {
				const valueFormat = val[keyName];
				let socialMessageTemp = socialMessage;
				const detailWidgetMetadataTemp = {
					...metadata,
					body: {
						...metadata.body,
						advancedSettings: {
							...metadata.body.advancedSettings,
							[keyName]: val[keyName],
						},
					},
				};

				if (keyName === KEY_ADVANCE_SETTING.VISIBLE_SOCIAL_BUTTONS && !socialMessageTemp) {
					socialMessageTemp = DEFAULT_ADVANCE_SETTING_WIDGET.SOCIAL_MESSAGE;
					detailWidgetMetadataTemp.body.advancedSettings = {
						...detailWidgetMetadataTemp.body.advancedSettings,
						socialMessage: socialMessageTemp,
					};
				}

				setDetailWidget((prev: WidgetBuilderDetailDto) => ({
					...prev,
					metadata: { ...detailWidgetMetadataTemp },
				}));
				formObject[keyName] = valueFormat;
			}

			form.setFieldsValue({ ...formObject });
		};

		const handleFinishForm = (values: any) => {
			const { url: urlForm, ...otherChange } = values;
			const urlChange = isEditUrl ? urlForm : url;

			let urlPublish: string = `${genericWidgetUrl}/${urlChange?.toLowerCase() || ''}`;

			if (!urlChange) {
				urlPublish = '';
			}

			const dataRequest: WidgetBuilderDetailDto = {
				...otherDetail,
				status: TAB_WIDGET.live,
				url: urlPublish,
				metadata: {
					...metadata,
					otherInfo: { ...metadata?.otherInfo },
					body: {
						...metadata.body,
						advancedSettings: { ...otherChange },
					},
				},
			};

			if (!urlChange) {
				message.error(t('content.widget_manager.widget_builder.widget_url_required'));
				return;
			}

			if (referenceId) {
				if (urlPublish !== url && isEditUrl) {
					setDetailWidget((prev: WidgetBuilderDetailDto) => ({
						...prev,
						url: urlPublish,
					}));
				}
				setTypeConfirm(TYPE_ACTIONS.PUBLISH);
				return;
			} else {
				if (hasChangeCustomField) {
					setDataPublishNoDraft({
						...dataRequest,
						metadata: JSON.stringify(dataRequest?.metadata),
					});
					setTypeConfirm(TYPE_ACTIONS.CHANGE_CUSTOM_FIELD);
					return;
				}
			}

			dispatch(
				publishWidgetBuilderRequest({
					...dataRequest,
					metadata: JSON.stringify(dataRequest?.metadata),
				}),
			);
		};

		return (
			<StyledStep>
				<StyledTitleStep>
					{t('content.widget_manager.widget_builder.here_is_your_widget')}
				</StyledTitleStep>
				<StyledNotifySubPublish>
					{t('content.widget_manager.widget_builder.notify_widget_publish')}
				</StyledNotifySubPublish>
				<StyledWrapperLogo>
					<StyledLogoPublishWidget src={ImagePublishWidget} alt="image_widget_publish" />
				</StyledWrapperLogo>
				<StyledLabelCustomize>
					{t('content.widget_manager.widget_builder.customize_your_widget_url')}
				</StyledLabelCustomize>
				<Form
					form={form}
					initialValues={initialValues}
					onValuesChange={onValuesChange}
					onFinish={handleFinishForm}>
					<StyledFormConfigUrl>
						{isEditUrl ? (
							<StyledRow justify="space-between">
								<StyledCol md={16} xxl={12} padding="6px 0">
									<StyledUrlDefault>{`${genericWidgetUrl}/`}</StyledUrlDefault>
								</StyledCol>
								<StyledCol md={8} xxl={12} padding="0">
									<StyledFormItem name="url" margin={'0'}>
										<StyledInputUrl
											placeholder={t(
												'content.widget_manager.widget_builder.your_brand',
											)}
										/>
									</StyledFormItem>
								</StyledCol>
							</StyledRow>
						) : (
							<StyledWrapperLink padding="0 40px 0 0">
								<StyledLink
									margin="0 4px 0 0"
									display="inline"
									target="_blank"
									href={url}>
									{url}
								</StyledLink>
								<StyledIcon
									position="relative"
									top="3px"
									size={18}
									onClick={() => setIsEditUrl(true)}
									className="icon_hover">
									<IconEdit />
								</StyledIcon>
							</StyledWrapperLink>
						)}
					</StyledFormConfigUrl>
					{<EditConfirmationScreen />}
				</Form>
				<StyledTextCustomize>
					{t('content.widget_manager.widget_builder.need_other_customize')}
					<br />
					{t('content.widget_manager.widget_builder.we_happy')}
					<StyledContactText>
						{t('content.widget_manager.widget_builder.contact_our_team')}{' '}
						<StyledLinkMail
							href={`mailto:${t('content.widget_manager.widget_builder.sale_url')}`}
							target="_blank">
							{t('content.widget_manager.widget_builder.sale_text_url')}
						</StyledLinkMail>{' '}
						{t('content.widget_manager.widget_builder.to_discuss_whitelabeling')}
					</StyledContactText>
				</StyledTextCustomize>
			</StyledStep>
		);
	},
);

export default SettingStep5;
