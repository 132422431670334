import { MFA_ACTION_TYPES, MFA_SECTION } from '@constants/settings/security';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import RegistrationAuthenticatorAppContext from '@contexts/Settings/RegistrationAuthenticatorApp';
import {
	confirmMFAPairingEnd,
	pairAuthenticatorAppEnd,
	pairAuthenticatorAppRequest,
	storeUserData,
	verifyPasswordEnd,
} from '@stores/actions';
import { UserStoreType } from '@stores/user/user.types';
import { StyledText } from '@styled/Common/CommonStyled';
import { StyledModal } from '@styled/Settings/UserProfileStyled';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmPairing from './ConfirmPairing';
import EnterPassword from './EnterPassword';
import PairingAuthenticatorAppCode from './PairingAuthenticatorAppCode';

type PairAuthenticatorAppModalProps = {
	visible: boolean;
	onCancel: () => void;
	isDisableMFA?: boolean;
	setActionType?: (type: string) => void;
};

const PairAuthenticatorAppModal = (props: PairAuthenticatorAppModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { visible, onCancel, isDisableMFA = false, setActionType } = props;

	const {
		error,
		verifyPasswordStatus,
		pairAuthenticatorAppStatus,
		authenticatorAppInfo,
		confirmMFAPairingStatus,
	}: UserStoreType = useSelector((state: any) => state.user);
	const [currentSection, setCurrentSection] = useState<string | number>(
		MFA_SECTION.ENTER_PASSWORD,
	);

	useEffect(() => {
		return () => {
			resetData();
		};
	}, [visible]);

	useEffect(() => {
		if (error) {
			message.destroy();
			message.error(error).then(() => {
				dispatch(
					storeUserData({
						error: null,
					}),
				);
			});
		}
	}, [error]);

	useEffect(() => {
		if (verifyPasswordStatus === FAILED || verifyPasswordStatus === SUCCEEDED) {
			dispatch(verifyPasswordEnd());
		}

		if (verifyPasswordStatus === SUCCEEDED) {
			if (!isDisableMFA) {
				dispatch(pairAuthenticatorAppRequest());
			} else {
				if (setActionType && typeof setActionType === 'function') {
					setActionType(MFA_ACTION_TYPES.DISABLE_MFA);
				}
			}
		}
	}, [verifyPasswordStatus]);

	useEffect(() => {
		if (pairAuthenticatorAppStatus === SUCCEEDED && authenticatorAppInfo) {
			setCurrentSection(MFA_SECTION.PAIR_AUTHENTICATOR_APP);
		}
	}, [pairAuthenticatorAppStatus, authenticatorAppInfo]);

	useEffect(() => {
		if (confirmMFAPairingStatus === SUCCEEDED) {
			message.info(t('setting.user_profile.message.authentication_succeeded'));
		}
	}, [confirmMFAPairingStatus]);

	const resetData = () => {
		setCurrentSection(MFA_SECTION.ENTER_PASSWORD);
		dispatch(confirmMFAPairingEnd());
		dispatch(pairAuthenticatorAppEnd());
	};

	const compatibleComponent = () => {
		switch (currentSection) {
			case MFA_SECTION.ENTER_PASSWORD:
				return {
					title: t('setting.user_profile.mfa_verification'),
					content: <EnterPassword />,
				};

			case MFA_SECTION.PAIR_AUTHENTICATOR_APP:
				return {
					title: t('setting.user_profile.pair_authenticator_app'),
					content: <PairingAuthenticatorAppCode />,
				};

			default:
				return {
					title: t('setting.user_profile.confirm_registration_title'),
					content: <ConfirmPairing />,
				};
		}
	};

	const isLoading = verifyPasswordStatus === IN_PROGRESS;
	const contextValues = {
		setCurrentSection,
		onCancel,
	};

	return (
		<RegistrationAuthenticatorAppContext.Provider value={contextValues}>
			<StyledModal
				title={
					<StyledText fontSize="20px" padding="4px 0">
						{compatibleComponent().title}
					</StyledText>
				}
				visible={visible}
				footer={false}
				closable={false}
				centered
				width={590}>
				{compatibleComponent().content}
			</StyledModal>
		</RegistrationAuthenticatorAppContext.Provider>
	);
};

export default PairAuthenticatorAppModal;
