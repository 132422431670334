import { DownOutlined } from '@ant-design/icons';
import THEME from '@constants/themes/themes';
import { StyledIcon, StyledSelect, StyledText } from '@styled/Common/CommonStyled';
import { notFoundContent } from '@utils/renderComponent';
import { SelectProps } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { JsxElement } from 'typescript';

const StyledWrapperFilter = styled.div<{ width: string }>`
	position: relative;
	display: flex;
	cursor: pointer;
	align-items: center;
	border: 1px solid ${THEME.colors.darkBlue4};
	border-radius: 2px;
	height: 32px;
	padding: 0 11px;
	text-overflow: ellipsis;
	overflow: hidden;
	justify-content: space-between;
	width: ${({ width }) => width};
	&:hover {
		border-color: ${THEME.colors.orangeBase};
	}
`;
export type OptionTypes = {
	label: string | number;
	value: string | number;
	[other: string]: any;
};

type PropTypes = {
	options: OptionTypes[];
	value?: OptionTypes | null;
	onChange?: (val: OptionTypes | null) => void;
	defaultValue?: OptionTypes | null;
	renderTextApply?: (
		text: string | number,
		option: OptionTypes,
	) => JsxElement | ReactNode | string | number;
	width?: string;
	[other: string]: any;
};

const SelectSingle = (props: PropTypes & SelectProps<any>) => {
	const {
		options,
		value,
		onChange: onChangeCallBack,
		renderTextApply,
		width = '200px',
		defaultValue,
		...other
	} = props;
	const { t } = useTranslation();

	const [visible, setVisible] = useState<boolean>(false);
	const [optionSelect, setOptionSelect] = useState<OptionTypes | null | undefined>(defaultValue);

	useEffect(() => {
		if (value && value?.value !== optionSelect?.value) {
			setOptionSelect(value);
		}
	}, [value]);

	if (!visible && optionSelect) {
		return (
			<StyledWrapperFilter width={width} onClick={() => setVisible(true)}>
				<StyledText whiteSpace={'nowrap'} className="overflow">
					{renderTextApply && typeof renderTextApply === 'function'
						? renderTextApply(optionSelect.label, optionSelect)
						: optionSelect.label}
				</StyledText>
				<StyledIcon size={10}>
					<DownOutlined />
				</StyledIcon>
			</StyledWrapperFilter>
		);
	}

	const onChange = (value: any, option: any) => {
		setOptionSelect(option);
		setVisible(false);
		if (onChangeCallBack && typeof onChangeCallBack === 'function') {
			onChangeCallBack(option);
		}
	};

	return (
		<StyledSelect
			open={visible}
			onDropdownVisibleChange={(open: boolean) => setVisible(open)}
			notFoundContent={notFoundContent(t)}
			placeholder={t('gallery_manager.placeholder_select')}
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
			virtual={false}
			optionFilterProp="label"
			options={options}
			dropdownClassName="dropdown-menu dropdown-custom-content"
			onChange={onChange}
			value={optionSelect?.value}
			width={width}
			{...other}
		/>
	);
};

export default SelectSingle;
