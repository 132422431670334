import { Modal } from 'antd';
import styled from 'styled-components';

const StyledActivationEmailModal = styled(Modal)`
	.ant-modal-body {
		padding: 0;
	}
`;

export { StyledActivationEmailModal };
