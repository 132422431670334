import { StyledTable } from '@components/CustomTable';
import THEME from '@constants/themes/themes';
import SearchBar from '@cores/SearchBar';
import { StyledButton, StyledIcon, StyledSelect, StyledText } from '@styled/Common/CommonStyled';
import { StyledSlideControl } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { Modal } from 'antd';
import styled from 'styled-components';

const StyledTaskList = styled(StyledTable)`
	.selectable-select-all.selectable-button {
		position: fixed;

		.ant-table-selection {
			margin-top: -5px;
		}
	}

	.ant-table-body {
		height: calc(100vh - 255px);
	}

	.ant-table
		table
		tbody.ant-table-tbody
		tr:not(.ant-table-expanded-row):not(.ant-table-expanded-row):hover
		> td {
		background-color: #4d2d22 !important;
	}
`;

const StyledDescription = styled(StyledText)`
	white-space: initial;
	line-height: normal;
	height: 100%;
	overflow: auto;
	cursor: pointer;
	padding: 8px 18px 8px 8px;
	border: 1px solid transparent;
	border-radius: 2px;

	${StyledIcon} {
		opacity: 0;
		position: absolute;
		bottom: 8px;
		right: 10px;
		transition: all 0.4s;

		&:hover {
			svg {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}

	&.description_in_list {
		height: 50px;
	}

	&.description_detail {
		border: 1px solid ${THEME.colors.darkBlue4};
		height: 130px;

		&:hover {
			border: 1px solid ${THEME.colors.darkBlue4};

			${StyledIcon} {
				opacity: 1;
			}
		}
	}
`;

const StyledTaskStatus = styled.div<{ bgColor?: string; color?: string; width?: string }>`
	width: ${({ width }) => width || '175px'};
	height: 32px;
	padding: 0 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
	${({ color }) => color && `color: ${color}`};
	cursor: pointer;
`;

const StyledAssignedUser = styled(StyledSelect)`
	.ant-select-selector {
		border: unset !important;
		background-color: rgba(255, 255, 255, 0.08) !important;
		border-radius: 8px !important;
	}

	.ant-select-arrow {
		margin: -9.5px 4px 0 0 !important;
	}
`;

const StyledTaskDetail = styled(Modal)`
	.ant-modal-body {
		padding: 0;
	}

	&.task_details {
		.ant-modal-footer {
			justify-content: space-between;
		}
	}
`;

const StyledTaskEditorWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	.tox-editor-header {
		padding: 0 !important;

		.tox-toolbar {
			&__primary {
				border-radius: unset;
			}

			&__group {
				padding: 0;

				.tox-tbtn:not(.tox-split-button__chevron) {
					width: 26px;
					height: 35px;

					svg {
						width: 22px;
						height: 22px;
						fill: ${THEME.colors.gray1};

						path {
							fill: ${THEME.colors.gray1};
						}
					}
				}

				.tox-split-button__chevron {
					width: 10px;
					margin-left: -2px;

					svg {
						transform: scale(0.75);
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}
`;

const StyledDescriptionWrapper = styled.div<{ height?: string }>`
	position: relative;
	${({ height }) => height && `height: ${height}`};

	.tox-editor-header {
		.tox-toolbar__primary {
			padding: 0 0 0 10px !important;
		}
	}

	*::-moz-selection {
		color: ${THEME.colors.white};
		background: ${THEME.colors.orangeBase};
	}

	*::selection {
		color: ${THEME.colors.white};
		background: ${THEME.colors.orangeBase};
	}

	&.description_in_details {
		*::selection {
			background-color: transparent !important;
		}
	}
`;

const StyledSaveButton = styled(StyledButton)`
	position: absolute;
	bottom: 9px;
	right: 10px;
	z-index: 99;
	height: 24px;
	width: 50px;
`;

const StyledButtonGroupInEditor = styled.div`
	position: absolute;
	bottom: 9px;
	right: 64px;
	z-index: 99;
	height: 24px;
	width: 50px;
	display: flex;

	${StyledButton} {
		height: 24px;
		width: 50px;
	}
`;

const StyledTaskArrow = styled(StyledSlideControl)`
	${({ positionArrow }) => positionArrow && `${positionArrow}: -8.5rem`};
`;

const StyledMentionDropdown = styled.div`
	display: none;
	position: absolute;
	color: ${THEME.colors.gray5};
	background-color: #203241;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	max-height: 150px;
	overflow-y: auto;
	z-index: 1000;
	width: 150px;

	&::-webkit-scrollbar-track {
		background: transparent;
	}
`;

const StyledMentionDropdownItem = styled.div`
	display: flex;
	justify-content: flex-start;
	border-left: 2px solid transparent;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	align-items: center;

	&.active,
	&:hover {
		border-left: 2px solid ${THEME.colors.orangeBase};
	}

	&.active {
		background-color: rgba(255, 255, 255, 0.2);
	}
`;

const StyledHistoryItem = styled.div`
	position: relative;
	margin: 8px 0;

	${StyledIcon}.more_actions_icon {
		position: absolute;
		right: -2px;
		top: -2px;
		opacity: 0;
	}

	span.mentioned_email_task {
		background-color: unset !important;
		color: unset !important;
		padding: 0 !important;
	}

	&:hover {
		${StyledIcon}.more_actions_icon {
			opacity: 1;
		}

		.notify_via_email {
			* {
				color: ${THEME.colors.orangeBase};

				svg {
					fill: ${THEME.colors.orangeBase};
				}
			}
		}
	}
`;

const StyledReminderEmailModal = styled(Modal)`
	min-width: 610px;

	.ant-modal-body {
		padding: 0;
	}
`;

const StyledSearchDescription = styled(SearchBar)`
	input::placeholder {
		color: ${THEME.colors.gray3} !important;
	}
`;

const StyledCommentText = styled.div<{ fontStyle?: string; padding?: string }>`
	font-style: ${({ fontStyle }) => fontStyle || 'italic'};
	position: relative;
	${({ padding }) => padding && `padding: ${padding}`};

	p {
		margin: 0;
	}
`;

export {
	StyledAssignedUser,
	StyledButtonGroupInEditor,
	StyledCommentText,
	StyledDescription,
	StyledDescriptionWrapper,
	StyledHistoryItem,
	StyledMentionDropdown,
	StyledMentionDropdownItem,
	StyledReminderEmailModal,
	StyledSaveButton,
	StyledSearchDescription,
	StyledTaskArrow,
	StyledTaskDetail,
	StyledTaskEditorWrapper,
	StyledTaskList,
	StyledTaskStatus,
};
