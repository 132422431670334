import { message, Tooltip } from 'antd';
import * as React from 'react';
import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Helper
import { deleteRequest, postRequest } from '@helpers/requestHelpers';

// Constant
import { CREATOR_API_URL } from '@constants/APIs';
import { WIDTH_COLUMNS } from '@constants/creator';
import { DEFAULT_ERROR } from '@constants/errors';
import { CreatorSegmentPaginator as defaultPage } from '@constants/paginator';

// Components
import ActionList from '@components/Creators/Segments/ActionList';
import SegmentList from '@components/Creators/Segments/SegmentList';

// Context
import CreatorSegmentsContext from '@contexts/Creators/Segments';

// Model
import { MESSAGE_TYPE, ROUTE_PATH, TYPE_FORMAT_DATE } from '@constants/common';
import THEME from '@constants/themes/themes';
import Header from '@cores/Header';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { ParamSegmentDto, SegmentsColumnsDto } from '@models/creator/segments';
import HeaderDto from '@models/header';
import { getContentTagsTypes } from '@stores/content/contentLibrary/content.types';
import headerTypes from '@stores/header/header.types';
import { StyledText } from '@styled/Common/CommonStyled';
import { destroyGeneralMessage, generalMessage, renderCopiedText } from '@utils/renderComponent';
import { Link } from 'react-router-dom';

type SegmentsProps = {
	filter: HeaderDto;
	t: any;
	creator: any;
	getSegment: Function;
	fetchContentTagsRequest: Function;
};

type SegmentsStates = {
	columns: SegmentsColumnsDto[];
	selectedRow: Array<any>;
	isSelectAll: boolean;
	paramSegment: ParamSegmentDto;
};

const StyledWrapper = styled.div`
	height: calc(100vh - 148px);
`;

const StyledLink = styled(Link)`
	color: ${THEME.colors.gray5};
`;

const StyledTotal = styled.div`
	width: fit-content;
	height: 100%;
	line-height: initial;
	cursor: pointer;

	&:hover {
		color: ${THEME.colors.orangeBase};
	}
`;
class CreatorsSegments extends PureComponent<SegmentsProps, SegmentsStates> {
	constructor(props: SegmentsProps | Readonly<SegmentsProps>) {
		super(props);
		const { t } = this.props;

		this.state = {
			isSelectAll: false,
			columns: [
				{
					title: t('creator_segment.segment_name'),
					dataIndex: 'title',
					sorter: () => {},
					render: (title: string) => renderCopiedText(title),
				},
				{
					title: t('creator_segment.total_creators'),
					width: WIDTH_COLUMNS,
					sorter: () => {},
					render: (record: any) => this.renderTotalCreator(record),
				},
				{
					title: t('creator_segment.total_uploads'),
					width: WIDTH_COLUMNS,
					sorter: () => {},
					render: (record: any) => this.renderTotalContent(record),
				},
				{
					title: t('creator_segment.date_updated'),
					dataIndex: 'lastUpdate',
					sorter: () => {},
					render: (createAt: string) =>
						renderCopiedText(
							convertUtcToLocalTimeWithFormat(
								new Date(createAt).getTime(),
								TYPE_FORMAT_DATE.TIME,
							),
						),
				},
			],
			selectedRow: [],
			paramSegment: {
				page: 1,
				pageRecords: defaultPage.pageSize,
				title: '',
			},
		};
	}

	componentDidMount(): void {
		this.props.fetchContentTagsRequest();
	}

	componentDidUpdate(
		prevProps: Readonly<SegmentsProps>,
		prevState: Readonly<SegmentsStates>,
		snapshot?: any,
	): void {}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		console.log(error);
	}

	componentWillUnmount(): void {
		destroyGeneralMessage();
	}

	/**
	 * @param selectedRow
	 */
	changeSelectedRow = (selectedRow: any) => {
		const { creator: { segmentData = [] } = {} } = this.props;
		let isSelectAll = false;

		if (selectedRow.length === segmentData.length) {
			isSelectAll = true;
		}

		this.setState({ selectedRow: selectedRow, isSelectAll });
	};

	handleChangeParams = (params: ParamSegmentDto) => {
		this.setState({ paramSegment: params });
	};

	changeIsSelectAll = () => {
		const { isSelectAll } = this.state;
		this.setState({ isSelectAll: !isSelectAll });
	};

	getSelectedSegment = (selectedSegmentId: any) => {
		const { creator } = this.props;
		const { segmentData } = creator;
		const selectedSegment = segmentData.filter((segment: any) => {
			if (segment.id === selectedSegmentId) {
				return segment.title;
			}
			return null;
		});
		return selectedSegment;
	};

	handleDuplicateSegment = () => {
		const { paramSegment, selectedRow } = this.state;
		const { creator, t } = this.props;
		const { segmentData } = creator;
		const selectedSegmentId = selectedRow[0];
		const selectedSegment = segmentData.filter((segment: any) => {
			if (segment.id === selectedSegmentId) {
				return segment.title;
			}
			return null;
		});

		const url = `${CREATOR_API_URL}/segment/duplicate-segment?id=${selectedSegmentId}&newTitle=${selectedSegment[0].title} - duplicated`;

		postRequest(url)
			.then((response) => {
				if (response.status === 200) {
					message.success(t('creator_segment.list.duplicate_success'));
					this.handleChangeParams({ ...paramSegment });
				} else {
					generalMessage(response.body.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
				}
			})
			.catch((err) => {
				message.error(DEFAULT_ERROR);
			});
	};

	handleDeleteSegment = () => {
		const { paramSegment, selectedRow, isSelectAll } = this.state;
		const { t } = this.props;

		const handleNotificationSegment = (rowNum: number) => {
			if (rowNum > 1)
				return `${t('notification.delete_segments_success', { segment: rowNum })}`;
			return `${t('notification.delete_segment_success', { segment: rowNum })}`;
		};

		const deleteMessage = isSelectAll
			? t('notification.delete_all_segment_success')
			: handleNotificationSegment(selectedRow.length);

		const url = `${CREATOR_API_URL}/segment/delete?isAll=${isSelectAll}`;

		deleteRequest(url, selectedRow)
			.then((response) => {
				if (response.status === 200) {
					message.info(deleteMessage);
					this.handleChangeParams({ ...paramSegment });
					this.setState({ selectedRow: [] });
				} else {
					generalMessage(response.body.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
				}
			})
			.catch((err) => {
				message.error(DEFAULT_ERROR);
			});
	};

	renderSegmentName = (name: string) => {
		return (
			<Tooltip placement="topLeft" title={name}>
				{name}
			</Tooltip>
		);
	};

	renderTotalCreator = (record: any) => {
		const { getSegment, filter } = this.props;
		return (
			<StyledLink
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					e.stopPropagation();
				}}
				style={{ display: 'inline-block' }}
				to={{ pathname: ROUTE_PATH.CREATORS_SUMMARY, state: { segmentId: record?.id } }}>
				<StyledTotal>
					<StyledText>{record.totalCreatorProfiles}</StyledText>
				</StyledTotal>
			</StyledLink>
		);
	};

	renderTotalContent = (record: any) => {
		const { id } = record;
		return (
			<StyledLink
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					e.stopPropagation();
				}}
				style={{ display: 'inline-block' }}
				to={{ pathname: ROUTE_PATH.SUBMISSIONS, state: { idSegment: id } }}>
				<StyledTotal>
					<StyledText>{record.totalUploads}</StyledText>
				</StyledTotal>
			</StyledLink>
		);
	};

	render(): React.ReactNode {
		const { columns, selectedRow, isSelectAll, paramSegment } = this.state;

		return (
			<CreatorSegmentsContext.Provider
				value={{
					handleChangeParamSegment: this.handleChangeParams,
					handleDuplicateSegment: this.handleDuplicateSegment,
					handleDeleteSegment: this.handleDeleteSegment,
					paramSegment,
				}}>
				<div className="main-content creator-segment">
					<Header>
						<ActionList
							selectedSegments={selectedRow}
							selectedSegmentData={this.getSelectedSegment(selectedRow[0])}
						/>
					</Header>
					<StyledWrapper>
						<SegmentList
							columns={columns}
							isSelectAll={isSelectAll}
							changeIsSelectAll={this.changeIsSelectAll}
							changeSelectedRow={this.changeSelectedRow}
						/>
					</StyledWrapper>
				</div>
			</CreatorSegmentsContext.Provider>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		getSegment: (payload: any) => dispatch({ type: headerTypes.HEADER_SUCCEEDED, payload }),
		fetchContentTagsRequest: () =>
			dispatch({ type: getContentTagsTypes.GET_CONTENT_TAGS_REQUEST }),
	};
};

const mapStateToProps = (state: any) => ({
	filter: state.header,
	creator: state.creator,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreatorsSegments));
