import THEME from '@constants/themes/themes';
import { StyledButton } from '@styled/Common/CommonStyled';
import { Image } from 'antd';
import styled, { css } from 'styled-components';

const StyledStartOverButton = styled(StyledButton)<{ backgroundUrl?: string }>`
	font-size: 16px;
	border-radius: 36px;
	padding: 24px 0;
	pointer-events: none;
	color: ${THEME.colors.white};
	border: unset;
	&.has_url {
		background-color: unset;
		${({ backgroundUrl }) => backgroundUrl && `background-image: url(${backgroundUrl})`};
		background-repeat: no-repeat;
		background-size: cover;
	}
`;

const StyledThumbnail = styled(Image)<{ isMobile?: boolean }>`
	width: 100%;
	height: 275px;
	border-radius: 16px;
	object-fit: cover;

	${({ isMobile }) =>
		isMobile &&
		css`
			height: 350px;
			border-radius: 6.2px;
		`}
`;

export { StyledStartOverButton, StyledThumbnail };
