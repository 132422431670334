import { platformURL, s3URL, urlGalleryTag, urlUploaderTag } from '@config/index';
import { UPLOAD_IMGS_CAMPAIGN } from '@constants/APIs';
import { ROUTE_PATH } from '@constants/common';
import {
	ADDITIONAL_FONTS,
	BOOTSTRAP_CDN,
	CONFIRMATION_MODAL_TYPES,
	ELEMENT_SELECTOR,
	GOOGLE_FONT,
	canvasCss,
	cssText,
} from '@constants/landingPageManager';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { postRequest } from '@helpers/requestHelpers';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { generateRandomElementIdentification } from '@utils/funcHelper';
import grapesjs from 'grapesjs';
import grapesJsCustomCode from 'grapesjs-custom-code';
import grapesJsNewsLetter from 'grapesjs-preset-newsletter';
import 'grapesjs/dist/css/grapes.min.css';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import loadBlocks from './blocks';
import loadCommands from './commands';
import loadComponents from './components';
import loadPanels from './panels';
import loadRte from './richTextEditor';
import loadTraits from './trait';
import { getEmptyProjectData } from './template';

type LandingPageBuilderProps = {
	projectData?: string;
	height?: string;
	hasChange?: boolean;
	setHasChange?: (change: boolean) => void;
};

const LandingPageBuilder = forwardRef((props: LandingPageBuilderProps, ref: any) => {
	const { height, projectData, setHasChange, hasChange } = props;

	const { landingPageTemplate } = useSelector((state: any) => state.campaign);
	const [builderEditor, setBuilderEditor] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [confirmationModalType, setConfirmationModalType] = useState<string>('');

	const { t } = useTranslation();

	const labels = {
		container: t('landing_page_builder.container'),
		columns: t('landing_page_builder.columns'),
		heading: t('landing_page_builder.heading'),
		text: t('landing_page_builder.text'),
		link: t('landing_page_builder.link'),
		uploaderButton: t('landing_page_builder.uploader_button'),
		galleryIframe: t('landing_page_builder.gallery_tag'),
		bulletedList: t('landing_page_builder.bulleted_list'),
		image: t('landing_page_builder.image'),
		customHTML: t('landing_page_builder.custom_html'),
	};

	useImperativeHandle(ref, () => ({ getMetadata, clearAllComponents }));

	useEffect(() => {
		initGrapesJs();
	}, []);

	useEffect(() => {
		if (landingPageTemplate) {
			window.open(`${platformURL}${ROUTE_PATH.CAMPAIGN_LANDING_PAGE}`, '_blank');
		}
	}, [landingPageTemplate]);

	const initGrapesJs = () => {
		setIsLoading(true);
		const editor = grapesjs.init({
			container: '#gjs',
			height: '100%',
			forceClass: true,
			fromElement: true,
			avoidInlineStyle: true,
			plugins: [grapesJsNewsLetter, grapesJsCustomCode],
			pluginsOpts: {
				[grapesJsNewsLetter]: {
					blocks: [],
				},
				[grapesJsCustomCode]: {
					blockCustomCode: false,
				},
			},
			canvas: {
				styles: [
					BOOTSTRAP_CDN.CSS,
					GOOGLE_FONT.IBM_FLEX_SERIF,
					GOOGLE_FONT.IBM_FLEX_SANS,
					GOOGLE_FONT.ROPA_SANS,
				],
				scripts: [
					urlUploaderTag,
					urlGalleryTag,
					BOOTSTRAP_CDN.BUNDLE,
					BOOTSTRAP_CDN.POPPER,
					BOOTSTRAP_CDN.MAIN,
				],
			},
			colorPicker: {
				appendTo: 'parent',
				offset: { top: 35, left: -160 },
			},
			assetManager: {
				uploadName: 'files',
				uploadFile: (e: any) => handleImageUpload(e, editor),
			},
		});

		setBuilderEditor(editor);

		editor.Config.canvasCss += canvasCss;
		let customizeCss = cssText;
		customizeCss += handleModalStyle();
		editor.addComponents(`<style>${customizeCss}</style>`);

		editor.on('load', () => {
			addFonts(editor);
			initTemplate(editor);
			editor.runCommand('core:component-outline');
			// Turn on component settings for body
			editor.getWrapper().set('stylable', true);
		});

		editor.onReady(() => {
			setIsLoading(false);
		});

		editor.on('component:add', (component: any) => {
			const additionalClasses = generateRandomElementIdentification(5);
			component.addClass(additionalClasses);
			detectHasChange();
		});

		editor.on('component:update', () => {
			detectHasChange();
		});

		editor.on('component:selected', (component: any) => {
			const domElement = component.getEl();
			const style = window.getComputedStyle(domElement);
			let { fontFamily } = style;

			if (fontFamily) {
				const fontProperty = editor.StyleManager.getProperty('typography', 'font-family');
				if (fontFamily.includes('"')) {
					fontFamily = fontFamily.replaceAll('"', '');
				}
				fontProperty.set('default', fontFamily);
				editor.StyleManager.render();
			}
		});

		editor.on('run:preview:before', () => {
			const iframe: any = document.getElementsByClassName(`${ELEMENT_SELECTOR.GJS_FRAME}`)[0];
			const modalRootEl = iframe?.contentWindow?.document.querySelectorAll(
				`.${ELEMENT_SELECTOR.UPLOADER_MODAL}`,
			)[0];

			customizeCss += handleModalStyle('block');
			editor.addComponents(`<style>${customizeCss}</style>`);

			if (modalRootEl) {
				const uploaderEl = iframe?.contentWindow?.document.querySelectorAll(
					`[id^="${ELEMENT_SELECTOR.UPLOADER_BTN}"]`,
				);
				if (uploaderEl) {
					uploaderEl.forEach((el: HTMLDivElement) => {
						el.remove();
					});
				}
				modalRootEl.remove();
			}
		});

		editor.on('stop:preview:before', () => {
			customizeCss += handleModalStyle();
			editor.addComponents(`<style>${customizeCss}</style>`);
		});

		const config = {
			setConfirmationModalType,
		};

		loadPanels(editor);
		loadCommands(editor, config);
		loadBlocks(editor, labels);
		loadComponents(editor);
		loadRte(editor);
		loadTraits(editor, {});
	};

	const detectHasChange = () => {
		if (setHasChange && !hasChange) {
			setHasChange(true);
		}
	};

	const initTemplate = (editor: any) => {
		editor.loadProjectData(projectData);
	};

	const handleModalStyle = (display?: string) => {
		return `.${ELEMENT_SELECTOR.UPLOADER_MODAL} { display: ${display || 'none'}; }`;
	};

	const addFonts = (editor: any) => {
		const fonts = [...ADDITIONAL_FONTS];
		const fontProperty = editor.StyleManager.getProperty('typography', 'font-family');
		const list: { value: string; name: string }[] = [];

		fontProperty.set('list', list);
		fonts.forEach((font) => {
			fontProperty.addOption(font);
		});
		editor.StyleManager.render();
	};

	const handleImageUpload = (e: any, editor: any) => {
		const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
		const formData = new FormData();
		Array.from(files).forEach((file: any) => {
			formData.append('files', file);
		});
		setIsLoading(true);
		postRequest(UPLOAD_IMGS_CAMPAIGN, formData, 'multipart/form-data')
			.then((response: any) => {
				const imgList = response.body.data.result;
				if (imgList.length) {
					imgList.forEach((imgURL: string) => {
						editor.AssetManager.add(s3URL + imgURL);
					});
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getMetadata = () => {
		const projectData = builderEditor.getProjectData();
		const { pages } = projectData || {};
		pages[0].frames.forEach((frame: any) => {
			const components = frame.component.components;
			if (components) {
				const filteredComponents = components?.filter(
					(comp: any) => !(comp.type === 'custom-code' && comp.components.length === 0),
				);
				frame.component.components = filteredComponents;
			}
		});

		return {
			projectData,
			cssString: builderEditor.getCss(),
			htmlString: builderEditor.getHtml(),
		};
	};

	const clearAllComponents = () => {
		if (builderEditor) {
			builderEditor.loadProjectData(getEmptyProjectData());
		}
		setConfirmationModalType('');
	};

	const renderConfirmationModal = () => {
		const content: string = t('landing_page_builder.message.clear_all');
		const onSubmit = clearAllComponents;
		const okText = t('button.yes');
		const isOpen: boolean = confirmationModalType === CONFIRMATION_MODAL_TYPES.CLEAR_ALL;

		return (
			<ConfirmModal
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => {
					setConfirmationModalType('');
				}}>
				{content}
			</ConfirmModal>
		);
	};

	return (
		<LoadingWrapper isLoading={isLoading} bgColorLoading="rgba(32, 50, 65, 0.3)">
			<StyledWrapperContent height={height || 'calc(100vh - 80px) !important'} id="gjs" />
			{renderConfirmationModal()}
		</LoadingWrapper>
	);
});

export default LandingPageBuilder;
