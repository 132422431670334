import { STATUSCODE } from '@constants/APIs';
import { FAILED } from '@constants/creator';
import { DEFAULT_ERROR } from '@constants/errors';
import { IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { GuestPortalItemDto, GuestPortalStoreDto } from '@models/settings/guestPortal';
import { Action, handleActions } from 'redux-actions';
import {
	createGuestPortalTypes,
	deleteGuestPortalTypes,
	getGuestPortalListTypes,
	shareGuestPortalTypes,
	storeDataGuestPortalTypes,
	updateGuestPortalTypes,
} from './guestportal.types';
import { isJSONString } from '@utils/common';

const initialState: GuestPortalStoreDto = {
	getGuestPortalListStatus: NONE,
	deleteGuestPortalStatus: NONE,
	updateGuestPortalStatus: NONE,
	createGuestPortalStatus: NONE,
	shareGuestPortalStatus: NONE,
};

const getGuestPortalListRequest = (state: GuestPortalStoreDto) => ({
	...state,
	getGuestPortalListStatus: IN_PROGRESS,
});

const getGuestPortalListSucceeded = (state: GuestPortalStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	const guestPortalResult = {
		...result,
		guestPortalResponses:
			result?.response?.map((item: GuestPortalItemDto) => ({
				...item,
				metadata: isJSONString(item.metadata) ? JSON.parse(item.metadata) : {},
			})) || [],
	};

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			getGuestPortalListStatus: SUCCEEDED,
			guestPortalResult,
		};
	}

	return {
		...state,
		getGuestPortalListStatus: FAILED,
		guestPortalError: status,
	};
};

const getGuestPortalListFailed = (state: GuestPortalStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			getGuestPortalListStatus: FAILED,
			guestPortalError: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		getGuestPortalListStatus: FAILED,
	};
};

const getGuestPortalListEnd = (state: GuestPortalStoreDto) => ({
	...state,
	getGuestPortalListStatus: NONE,
});

const createGuestPortalRequest = (state: GuestPortalStoreDto) => ({
	...state,
	createGuestPortalStatus: IN_PROGRESS,
});

const createGuestPortalSucceeded = (state: GuestPortalStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			createGuestPortalStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		createGuestPortalStatus: FAILED,
		guestPortalError: status,
	};
};

const createGuestPortalFailed = (state: GuestPortalStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			createGuestPortalStatus: FAILED,
			guestPortalError: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		createGuestPortalStatus: FAILED,
	};
};

const createGuestPortalEnd = (state: GuestPortalStoreDto) => ({
	...state,
	createGuestPortalStatus: NONE,
});

const updateGuestPortalRequest = (state: GuestPortalStoreDto) => ({
	...state,
	updateGuestPortalStatus: IN_PROGRESS,
});

const updateGuestPortalSucceeded = (state: GuestPortalStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: updatedGuestPortal = {},
		isUpdatedStatus = false,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateGuestPortalStatus: SUCCEEDED,
			updatedGuestPortal: {
				...updatedGuestPortal,
				metadata: isJSONString(updatedGuestPortal.metadata)
					? JSON.parse(updatedGuestPortal.metadata)
					: {},
				isUpdatedStatus,
			},
		};
	}

	return {
		...state,
		updateGuestPortalStatus: FAILED,
		guestPortalError: status,
	};
};

const updateGuestPortalFailed = (state: GuestPortalStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			updateGuestPortalStatus: FAILED,
			guestPortalError: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		updateGuestPortalStatus: FAILED,
	};
};

const updateGuestPortalEnd = (state: GuestPortalStoreDto) => ({
	...state,
	updateGuestPortalStatus: NONE,
	updatedGuestPortal: null,
});

const deleteGuestPortalRequest = (state: GuestPortalStoreDto) => ({
	...state,
	deleteGuestPortalStatus: IN_PROGRESS,
});

const deleteGuestPortalSucceeded = (state: GuestPortalStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deleteGuestPortalStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		deleteGuestPortalStatus: FAILED,
		guestPortalError: status,
	};
};

const deleteGuestPortalFailed = (state: GuestPortalStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			deleteGuestPortalStatus: FAILED,
			guestPortalError: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		deleteGuestPortalStatus: FAILED,
	};
};

const deleteGuestPortalEnd = (state: GuestPortalStoreDto) => ({
	...state,
	deleteGuestPortalStatus: NONE,
});

const shareGuestPortalRequest = (state: GuestPortalStoreDto) => ({
	...state,
	shareGuestPortalStatus: IN_PROGRESS,
});

const shareGuestPortalSucceeded = (state: GuestPortalStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			shareGuestPortalStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		shareGuestPortalStatus: FAILED,
		guestPortalError: status,
	};
};

const shareGuestPortalFailed = (state: GuestPortalStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			shareGuestPortalStatus: FAILED,
			guestPortalError: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		shareGuestPortalStatus: FAILED,
	};
};

const shareGuestPortalEnd = (state: GuestPortalStoreDto) => ({
	...state,
	shareGuestPortalStatus: NONE,
});

const storeDataGuestPortal = (state: GuestPortalStoreDto, { payload }: Action<any>) => ({
	...state,
	...payload,
});

const guestPortalReducers = handleActions<any>(
	{
		[getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_REQUEST]: getGuestPortalListRequest,
		[getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_SUCCEEDED]: getGuestPortalListSucceeded,
		[getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_FAILED]: getGuestPortalListFailed,
		[getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_END]: getGuestPortalListEnd,

		[createGuestPortalTypes.CREATE_GUEST_PORTAL_REQUEST]: createGuestPortalRequest,
		[createGuestPortalTypes.CREATE_GUEST_PORTAL_SUCCEEDED]: createGuestPortalSucceeded,
		[createGuestPortalTypes.CREATE_GUEST_PORTAL_FAILED]: createGuestPortalFailed,
		[createGuestPortalTypes.CREATE_GUEST_PORTAL_END]: createGuestPortalEnd,

		[updateGuestPortalTypes.UPDATE_GUEST_PORTAL_REQUEST]: updateGuestPortalRequest,
		[updateGuestPortalTypes.UPDATE_GUEST_PORTAL_SUCCEEDED]: updateGuestPortalSucceeded,
		[updateGuestPortalTypes.UPDATE_GUEST_PORTAL_FAILED]: updateGuestPortalFailed,
		[updateGuestPortalTypes.UPDATE_GUEST_PORTAL_END]: updateGuestPortalEnd,

		[deleteGuestPortalTypes.DELETE_GUEST_PORTAL_REQUEST]: deleteGuestPortalRequest,
		[deleteGuestPortalTypes.DELETE_GUEST_PORTAL_SUCCEEDED]: deleteGuestPortalSucceeded,
		[deleteGuestPortalTypes.DELETE_GUEST_PORTAL_FAILED]: deleteGuestPortalFailed,
		[deleteGuestPortalTypes.DELETE_GUEST_PORTAL_END]: deleteGuestPortalEnd,

		[shareGuestPortalTypes.SHARE_GUEST_PORTAL_REQUEST]: shareGuestPortalRequest,
		[shareGuestPortalTypes.SHARE_GUEST_PORTAL_SUCCEEDED]: shareGuestPortalSucceeded,
		[shareGuestPortalTypes.SHARE_GUEST_PORTAL_FAILED]: shareGuestPortalFailed,
		[shareGuestPortalTypes.SHARE_GUEST_PORTAL_END]: shareGuestPortalEnd,

		[storeDataGuestPortalTypes.STORE_DATA_GUEST_PORTAL]: storeDataGuestPortal,
	},
	initialState,
);

export default guestPortalReducers;
