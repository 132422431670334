import { IconInfo, IconMoreVert } from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { mediaHubURL } from '@config/index';
import {
	DEFAULT_WIDTH_COL,
	LIMIT,
	PATTERNS,
	ROUTE_PATH,
	TYPE_FORMAT_DATE,
} from '@constants/common';
import { Paginator } from '@constants/paginator';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import { LoadingWrapper } from '@cores/index';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { getUser } from '@helpers/userHelpers';
import { ActionsTypes } from '@models/common/summary';
import { GuestDto, InviteGuestDto } from '@models/content/albums/albumManager';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import { AccountPayloadDto } from '@models/settings/socialSearch/accountuser.ts/store';
import {
	deleteGuestEnd,
	deleteGuestRequest,
	fetchGuestListRequest,
	sendGuestPassCodeEnd,
	sendGuestPassCodeRequest,
} from '@stores/actions';
import {
	StyledButton,
	StyledDropdown,
	StyledEmpty,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledIcon,
	StyledLinkHref,
	StyledSection,
	StyledSelect,
	StyledText,
	StyledTooltip,
} from '@styled/Common/CommonStyled';
import {
	StyledGuestTable,
	StyledRadio,
	StyledRadioGroup,
	StyledShareMediaHubModal,
} from '@styled/Content/AlbumManager/ShareMediaHubModalStyled';
import { StyledDirectLink } from '@styled/Content/ContentLibrary/ContentStyled';
import { encode } from '@utils/common';
import history from '@utils/history';
import { menuActions } from '@utils/renderComponent';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type ShareMediaHubModalProps = {
	isAlbumDetail?: boolean;
	visible: boolean;
	onCancel: () => void;
	selectedAlbumIds: number[];
};

const ShareMediaHubModal = (props: ShareMediaHubModalProps) => {
	const { isAlbumDetail, visible, onCancel, selectedAlbumIds = [] } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = useForm();
	const user = getUser();
	const { clientId, accountId, brandName } = user;
	const {
		fetchingGuestList = false,
		guestData,
		sendGuestPassCodeStatus = NONE,
		deleteGuestStatus = NONE,
		error,
	}: AlbumsStoreDto = useSelector((state: any) => state.albumManager);

	const RADIO_VALUE = {
		ALL: 'ALL',
		SPECIFIC: 'SPECIFIC',
	};

	const FIELD_NAME = {
		EMAILS: 'emails',
		SHARE_OPTION: 'shareOption',
	};

	// The default payload which is used to fetch guest list
	const defaultPayload = {
		page: Paginator.defaultPage,
		maxRecords: Paginator.pageSize,
		clientId,
	};

	const initPayload = {
		emails: [],
		brandId: clientId,
		sharedAlbums: isAlbumDetail ? selectedAlbumIds : [],
		invitedBy: accountId,
	};
	const [guestList, setGuestList] = useState<GuestDto[]>([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [inviteGuestPayload, setInviteGuestPayload] = useState<InviteGuestDto>(initPayload);
	const [payload, setPayload] = useState<AccountPayloadDto>(defaultPayload);

	const defaultValue = isAlbumDetail ? RADIO_VALUE.SPECIFIC : RADIO_VALUE.ALL;
	const [shareOption, setShareOption] = useState<string>(defaultValue);

	const getGuestList = () => {
		dispatch(fetchGuestListRequest({ ...payload }));
	};

	useEffect(() => {
		if (guestData?.guestResponses) {
			setGuestList(
				payload.page === Paginator.defaultPage
					? [...guestData?.guestResponses]
					: [...guestList, ...guestData?.guestResponses],
			);
		}
	}, [guestData]);

	useEffect(() => {
		getGuestList();
	}, [payload]);

	useEffect(() => {
		if (clientId && accountId) {
			setInviteGuestPayload({
				...inviteGuestPayload,
				brandId: clientId,
				invitedBy: accountId,
			});
		}
	}, [clientId, accountId]);

	useEffect(() => {
		if (sendGuestPassCodeStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.sent'),
				}),
			);
			onCancel();
		}

		if (sendGuestPassCodeStatus === SUCCEEDED || sendGuestPassCodeStatus === FAILED) {
			dispatch(sendGuestPassCodeEnd());
		}
	}, [sendGuestPassCodeStatus]);

	const resetData = () => {
		setSelectedRowKeys([]);
	};

	useEffect(() => {
		if (deleteGuestStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.deleted'),
				}),
			);
			const listTemp = guestList.filter((item: GuestDto) => {
				return !selectedRowKeys.includes(item.id);
			});
			setGuestList([...listTemp]);
			resetData();
		}

		if (deleteGuestStatus === SUCCEEDED || deleteGuestStatus === FAILED) {
			dispatch(deleteGuestEnd());
		}
	}, [deleteGuestStatus]);

	useEffect(() => {
		if (error) {
			message.error(error);
		}
	}, [error]);

	const TABLE_ID = 'guest_table';
	// Handle infinite scroll
	useEffect(() => {
		const checkedElement = document.querySelector<HTMLElement>(`#${TABLE_ID} .ant-table-body`);

		if (checkedElement) {
			const handleScrollList = (event: Event) => {
				const { clientHeight, scrollTop, scrollHeight } = event.target as HTMLDivElement;
				const scrollbarTop = (clientHeight + scrollTop) / scrollHeight;

				if (scrollbarTop === 1 && !fetchingGuestList) {
					handleLoadMore();
				}
			};

			checkedElement.addEventListener('scroll', handleScrollList);
			return () => checkedElement.removeEventListener('scroll', handleScrollList);
		}
		return () => {};
	}, [fetchingGuestList, guestData, payload]);

	const handleLoadMore = () => {
		if (guestData?.guestResponses && guestData?.guestResponses?.length > 0) {
			setPayload({ ...payload, page: payload.page + 1 });
		}
	};

	const columns = [
		{
			title: t('albums.modal.media_hub_guests'),
			dataIndex: 'email',
			key: 'email',
			width: DEFAULT_WIDTH_COL + 50,
			render: (email: string) => <StyledTooltip title={email}>{email}</StyledTooltip>,
			ellipsis: true,
		},
		{
			title: t('albums.modal.last_visit'),
			dataIndex: 'lastLogin',
			key: 'lastLogin',
			render: (lastLogin: number) =>
				convertUtcToLocalTimeWithFormat(lastLogin, TYPE_FORMAT_DATE.TIME) || '------',
			width: DEFAULT_WIDTH_COL + 35,
		},
		{
			title: () => renderActions(),
			key: 'actions',
			render: (record: GuestDto) => renderTooltip(record),
		},
	];

	const renderTooltip = (record: GuestDto) => {
		const { invitedBy, invitedAt } = record;

		return (
			<StyledTooltip
				title={t('albums.modal.invited_by', {
					email: invitedBy,
					dateTime: convertUtcToLocalTimeWithFormat(invitedAt, TYPE_FORMAT_DATE.TIME),
				})}
				placement="right">
				<StyledIcon className="light_icon_hover">
					<IconInfo />
				</StyledIcon>
			</StyledTooltip>
		);
	};

	const handleRemoveAccess = () => {
		if (selectedRowKeys.length === 0) {
			message.info(t('albums.message.select_guest'));
		} else {
			const payload = {
				clientId,
				params: selectedRowKeys,
			};
			dispatch(deleteGuestRequest(payload));
		}
	};

	const renderActions = () => {
		const actions: ActionsTypes[] = [
			{
				id: t('albums.modal.remove_access'),
				name: t('albums.modal.remove_access'),
				click: handleRemoveAccess,
			},
		];

		return (
			<StyledDropdown
				className="dropdown_action"
				overlay={menuActions(actions)}
				trigger={['click']}
				placement="bottomCenter">
				<StyledIcon>
					<IconMoreVert />
				</StyledIcon>
			</StyledDropdown>
		);
	};

	const handleDirectAccountSetting = () => {
		history.push(ROUTE_PATH.SETTINGS_ACCOUNT_USERS);
	};

	const renderPeopleWithAccess = () => {
		const mediaHubLink = `${mediaHubURL}/${clientId}/${encode(brandName)}`;

		const propsMessage: any = {
			i18nKey: 'albums.modal.share_media_hub_desc',
			components: {
				linkToMediaHub: (
					<StyledLinkHref
						className="customized"
						color={THEME.colors.white}
						target="_blank"
						href={mediaHubLink}
					/>
				),
				linkToAccountSetting: (
					<StyledLinkHref
						className="customized"
						color={THEME.colors.white}
						onClick={() => handleDirectAccountSetting()}
					/>
				),
				span: <span />,
			},
		};

		return (
			<>
				<StyledText fontSize="16px">{t('albums.modal.people_access')}</StyledText>
				<StyledSection bgColor="rgba(24, 144, 255, 0.1)" padding="5px 15px" margin="10px 0">
					<StyledFlex>
						<StyledIcon margin="0 11px 0 0" fillPath={THEME.colors.blueBase}>
							<IconInfo />
						</StyledIcon>
						<Trans t={t} {...propsMessage} />
					</StyledFlex>
				</StyledSection>
				{renderGuestTable()}
			</>
		);
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const renderGuestTable = () => {
		return (
			<StyledGuestTable
				id={TABLE_ID}
				locale={{
					emptyText: !fetchingGuestList ? <StyledEmpty image={IconEmpty} /> : <></>,
				}}
				dataSource={guestList}
				loading={fetchingGuestList}
				columns={columns}
				rowSelection={rowSelection}
				pagination={false}
				scroll={{ x: 'fit-content', y: 280, scrollToFirstRowOnChange: false }}
			/>
		);
	};

	const setFields = (field: string, value: any) => {
		form.setFieldsValue({
			[field]: value,
		});
	};

	const renderInviteGuest = () => {
		return (
			<>
				<StyledText fontSize="16px">{t('albums.modal.invite_guests')}</StyledText>
				<StyledFormItem
					name={FIELD_NAME.EMAILS}
					rules={[
						{
							required: true,
							message: t('validation.required', {
								field: 'email(s)',
							}),
						},
						() => ({
							validator(_: any, value: string) {
								const regex = PATTERNS.EMAIL;
								message.destroy();

								if (
									value?.length > 0 &&
									value?.length <= LIMIT.SHARED_GUEST_EMAIL
								) {
									const checkedValue = value[value.length - 1];
									if (!regex.test(checkedValue)) {
										message.error(
											t('validation.invalid_message', {
												field: t('albums.modal.emails'),
											}),
										);
										setFields('emails', value.slice(0, value.length - 1));
										return Promise.reject();
									}
								} else if (value?.length > LIMIT.SHARED_GUEST_EMAIL) {
									message.error(
										t('albums.message.guest_limit', {
											limit: LIMIT.SHARED_GUEST_EMAIL,
										}),
									);
									setFields('emails', value.slice(0, value.length - 1));
									return Promise.reject();
								}

								return Promise.resolve();
							},
						}),
					]}>
					<StyledSelect
						margin="6px 0 0 0"
						placeholder={t('albums.placeholder.add_email')}
						mode="tags"
						notFoundContent={null}
						autoFocus={true}
						open={false}
					/>
				</StyledFormItem>
			</>
		);
	};

	const renderShareOptions = () => {
		const radioList = [
			{
				label: t('albums.modal.share_all_album'),
				value: RADIO_VALUE.ALL,
				isDisabled: isAlbumDetail,
			},
			{
				label: t('albums.modal.share_specific_album', {
					value: selectedAlbumIds.length,
				}),
				value: RADIO_VALUE.SPECIFIC,
				isDisabled: selectedAlbumIds.length === 0,
			},
		];

		return (
			<StyledFormItem name={FIELD_NAME.SHARE_OPTION}>
				<StyledRadioGroup defaultValue={defaultValue}>
					{radioList?.map((item: any) => (
						<StyledRadio
							isDisabled={item.isDisabled}
							key={item?.value}
							value={item?.value}>
							{item?.label}
						</StyledRadio>
					))}
				</StyledRadioGroup>
			</StyledFormItem>
		);
	};

	const renderButtonGroup = () => {
		return (
			<StyledFlex margin="24px 0 0 0">
				<StyledButton
					onClick={() => handleCopyShareableLink(shareOption === RADIO_VALUE.ALL)}
					width="48%">
					{t('albums.button.copy_link_media_hub')}
				</StyledButton>
				<StyledButton width="48%" type="primary" onClick={form.submit}>
					{t('albums.button.invite_guests')}
				</StyledButton>
			</StyledFlex>
		);
	};

	const onValuesChange = (changedValue: any) => {
		if (FIELD_NAME.EMAILS in changedValue) {
			setInviteGuestPayload({ ...inviteGuestPayload, ...changedValue });
		}

		if (FIELD_NAME.SHARE_OPTION in changedValue) {
			setShareOption(changedValue[FIELD_NAME.SHARE_OPTION]);
			setInviteGuestPayload({
				...inviteGuestPayload,
				sharedAlbums:
					changedValue[FIELD_NAME.SHARE_OPTION] === RADIO_VALUE.SPECIFIC
						? [...selectedAlbumIds]
						: [],
			});
		}
	};

	const handleInviteGuest = () => {
		dispatch(sendGuestPassCodeRequest(inviteGuestPayload));
	};

	const handleDirectAlbum = (url: string) => {
		message.destroy('message_redirect_album');
		const linkElement = document.createElement('a');
		linkElement.href = url;
		linkElement.target = '_blank';
		document.body.appendChild(linkElement);
		linkElement.click();
		document.body.removeChild(linkElement);
	};

	const handleCopyShareableLink = (isAll?: boolean) => {
		const input = document.createElement('input');
		let text = 'preview_shared_album';
		if (selectedAlbumIds?.length > 1 || isAll) {
			text = 'preview_shared_albums';
		}

		let urlCopy = `${mediaHubURL}/${clientId}/${encode(brandName)}`;

		if (!isAll) {
			if (selectedAlbumIds?.length === 1) {
				urlCopy += `/${selectedAlbumIds[0]}?source=${encode(
					selectedAlbumIds[0]?.toString(),
				)}`;
			} else {
				const jsonString = JSON.stringify({ albumIds: selectedAlbumIds }) || '';
				urlCopy += `/?q=${jsonString}`;
			}
		}

		const propsMessage: any = {
			values: { text: t(`albums.message.${text}`) },
			i18nKey: 'albums.message.copy_albums_to_clipboard',
			components: {
				linkTo: <StyledDirectLink onClick={() => handleDirectAlbum(urlCopy)} />,
			},
		};

		input.value = urlCopy;
		document.body.appendChild(input);
		input.select();
		document.execCommand('Copy');
		input.remove();
		message.config({
			maxCount: 1,
		});
		message.success({
			content: <Trans t={t} {...propsMessage} />,
			duration: 3,
			key: 'message_redirect_album',
		});
	};

	const isLoading = sendGuestPassCodeStatus === IN_PROGRESS;

	return (
		<StyledShareMediaHubModal
			title={<StyledText fontSize="20px">{t('albums.modal.share_via_media_hub')}</StyledText>}
			visible={visible}
			footer={false}
			onCancel={() => {
				setGuestList([]);
				onCancel();
			}}>
			<LoadingWrapper isLoading={isLoading}>
				<StyledForm
					form={form}
					onFinish={handleInviteGuest}
					onValuesChange={onValuesChange}>
					{renderPeopleWithAccess()}
					{renderInviteGuest()}
					{renderShareOptions()}
					{renderButtonGroup()}
				</StyledForm>
			</LoadingWrapper>
		</StyledShareMediaHubModal>
	);
};

export default ShareMediaHubModal;
