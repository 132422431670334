import { IconCancel, IconCheckCircle, IconCustomTerms, IconError } from '@assets/icons';
import { MANUAL_UPLOAD_CONTENT, STATUSCODE } from '@constants/APIs';
import { postRequest } from '@helpers/requestHelpers';
import { ContentUploadingType } from '@models/content/contentLibrary/store';
import { StyledIcon, StyledWrapperAction } from '@styled/Common/CommonStyled';
import { convertParamSearch } from '@utils/common';
import { convertFormDataToObject } from '@utils/funcHelper';
import { Progress, Tooltip } from 'antd';
import axios from 'axios';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledWrapperItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
	&:first-child {
		margin-top: 0;
	}
`;

const StyledName = styled.div`
	max-width: 165px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledWrapperPercent = styled.div`
	width: calc(100% - 165px - 24px - 16px - 16px);
	margin-left: 16px;
`;

const StyledProgress = styled(Progress)``;

type PropTypes = {
	[other: string]: any;
	content: ContentUploadingType;
	getCancelToken: () => any;
	callbackUploadEnd: () => void;
	handleCancelToken: () => void;
	refTotalTime: any;
	setTimeRemainEnd: (val: number) => void;
};

type ProgressItemUpload = {
	id: number;
	remainTime: number;
	progress: number;
	error?: boolean;
};

const ProgressItem = (props: PropTypes) => {
	const {
		content,
		getCancelToken,
		callbackUploadEnd,
		handleCancelToken,
		refTotalTime,
		setTimeRemainEnd,
		...other
	} = props;
	const objectData = convertFormDataToObject(content.data);
	const { fileMedia = {} } = objectData;
	const [progressInfo, setProgressInfo] = useState<ProgressItemUpload | null>(null);
	const [completed, setCompleted] = useState(true);
	const [isError, setIsError] = useState<boolean>(false);

	useEffect(() => {
		if (!content.uploading) {
			content.uploading = true;
			setCompleted(false);
			setIsError(false);
			handleUploadSingleContent();
		}
	}, [content]);

	useEffect(() => {
		return () => {
			setIsError(false);
			setProgressInfo(null);
		};
	}, []);

	const onUploadProgress = ({
		startTime,
		content,
		dataProgress,
	}: {
		dataProgress: any;
		startTime: number;
		content: ContentUploadingType;
	}) => {
		const { loaded, total } = dataProgress;

		const { id } = content;
		const percentageProgress = Math.floor((loaded / total) * 100);

		const endTime = new Date().getTime();
		const duration = (endTime - startTime) / 1000;
		const bps = loaded / duration;
		const time = (total - loaded) / bps;

		setProgressInfo({
			id: content.id,
			remainTime: time,
			progress: percentageProgress,
		});

		const arr: any[] = refTotalTime?.current?.arr || [];
		const idx = arr.findIndex((item: any) => item?.id === id);
		if (idx === -1) {
			arr.push({ id, time });
		} else {
			arr[idx] = { id, time };
		}
		let max = 0;
		arr.forEach((item) => {
			if (max < item.time) {
				max = item.time;
			}
		});

		refTotalTime.current.arr = arr;

		setTimeRemainEnd(Math.ceil(max));
	};

	const handleUploadSingleContent = () => {
		const { creatorId, customTerms, data, ...other } = content;
		const cancelToken = getCancelToken();

		const url = `${MANUAL_UPLOAD_CONTENT}?${convertParamSearch({ creatorId, customTerms })}`;
		const startTime = new Date().getTime();

		postRequest(url, data, undefined, undefined, undefined, undefined, cancelToken, (e: any) =>
			onUploadProgress({ dataProgress: e, startTime, content }),
		)
			.then((response) => {
				if (response?.status !== STATUSCODE.SUCCESS) {
					setIsError(true);
				}
			})
			.catch((e) => {
				setIsError(true);
			})
			.finally(() => {
				setCompleted(true);
				callbackUploadEnd();
			});
	};

	const renderIcon = () => {
		let icon = <IconCancel />;
		if (isError) {
			return <IconError />;
		}

		if (progressInfo?.progress === 100) {
			if (completed) {
				icon = <IconCheckCircle />;
			} else {
				icon = <IconCustomTerms />;
			}
		}
		return icon;
	};

	return (
		<StyledWrapperItem {...other}>
			<StyledWrapperAction>
				<StyledIcon margin="0 16px 0 0" onClick={() => handleCancelToken()} size={24}>
					{renderIcon()}
				</StyledIcon>
				<Tooltip placement="top" title={fileMedia?.name || ''}>
					<StyledName>{fileMedia?.name || ''}</StyledName>
				</Tooltip>
			</StyledWrapperAction>
			<StyledWrapperPercent>
				<StyledProgress
					status={isError ? 'exception' : undefined}
					percent={progressInfo?.progress || 0}
					showInfo={false}
				/>
			</StyledWrapperPercent>
		</StyledWrapperItem>
	);
};

export default ProgressItem;
