import { IconNoteMessage } from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { ROW_TEXTAREA } from '@constants/publish/gallery';
import { NONE } from '@constants/status';
import THEME from '@constants/themes/themes';
import GalleryManagerContext from '@contexts/Publishing/Galleries';
import { FolderGalleryDto, MetadataGalleryDto } from '@models/publishing/galleries';
import { fetchMetricsGalleryRequest, updateDetailFolderGalleryRequest } from '@stores/actions';
import { StyledDatePicker, StyledIcon, StyledWrapperContent } from '@styled/Common/CommonStyled';
import {
	StyledButtonInfo,
	StyledGalleryItem,
	StyledInput,
	StyledLinkGallery,
	StyledNameVisitor,
	StyledTextArea,
	StyledTitle,
} from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledFormItem } from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import { kFormatter } from '@utils/common';
import { Button, Col, Form, Row } from 'antd';
import moment from 'moment-timezone';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PreviewEmbedModal from '../PreviewEmbedModal';
import ContentItem from './ContentItem';

type PropsGalleryItem = {
	gallery: FolderGalleryDto;
};

type PropContentItem = {
	label: ReactNode;
	content: ReactNode;
	marginContent?: string;
};

const fieldChange = {
	name: 'name',
	description: 'description',
};

const GalleryItem = (props: PropsGalleryItem) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { statusChangeDetailGallery, metricsGallery } = useSelector(
		(state: any) => state.galleryManager,
	);
	const { avgTimeSpent = '', clicks = 0, ctaClicks = 0, visitors = 0 } = metricsGallery;

	const { gallerySelect } = useContext(GalleryManagerContext);

	const { gallery } = props;
	const { name = '', metadata = {} } = gallery;
	const { description = '' } = metadata;

	const [form] = Form.useForm();

	const renderDefaultRangeDate = () => {
		const date = new Date();
		const y = date.getFullYear();
		const m = date.getMonth();
		const firstDay = new Date(y, m, 1);
		date.setHours(23, 59, 59, 999);
		firstDay.setHours(0, 0, 0, 0);
		return {
			start: firstDay.getTime(),
			end: date.getTime(),
		};
	};

	const [dateRange, setDateRange] = useState<number[]>([
		renderDefaultRangeDate().start,
		renderDefaultRangeDate().end,
	]);
	const [dateRangeChange, setDateRangeChange] = useState<number[]>([...dateRange]);
	const [openRangeDate, setOpenRangeDate] = useState<boolean>(false);
	const [viewEmbed, setViewEmbed] = useState<boolean>(false);

	const renderContentBasic = (name: string) => {
		if (name === fieldChange?.name) {
			return (
				<StyledFormItem
					rules={[
						{
							required: true,
							message: t('gallery_manager.required'),
						},
					]}
					name={name}>
					<StyledInput
						onBlur={onSaveChange}
						disabled={statusChangeDetailGallery !== NONE}
					/>
				</StyledFormItem>
			);
		}

		return (
			<StyledFormItem name={name}>
				<StyledTextArea
					onBlur={onSaveChange}
					rows={ROW_TEXTAREA.GALLERY_DESC}
					disabled={statusChangeDetailGallery !== NONE}
					autoSize={{
						minRows: ROW_TEXTAREA.GALLERY_DESC,
						maxRows: ROW_TEXTAREA.GALLERY_DESC,
					}}
				/>
			</StyledFormItem>
		);
	};

	const onSaveChange = () => {
		const values = form.getFieldsValue();
		const descTrim = values[fieldChange.description]?.trim() || '';

		form.setFieldsValue({
			[fieldChange?.description]: descTrim,
		});

		if (descTrim !== description) {
			form.submit();
		}
	};

	const listContentBasic = [
		{
			label: t('gallery_manager.description'),
			content: renderContentBasic(fieldChange.description),
			key: 2,
		},
		{
			label: t('gallery_manager.gallery_url'),
			content: (
				<StyledLinkGallery href={gallery?.url} target="_blank">
					{gallery?.url}
				</StyledLinkGallery>
			),
			key: 3,
		},
	];

	const visitorInsights = [
		{
			id: 1,
			name: t('gallery_manager.visitors'),
			value: kFormatter(visitors || 0),
			col: 4,
		},
		{
			id: 2,
			name: t('gallery_manager.avg_time_spent'),
			value: avgTimeSpent || '',
			col: 8,
		},
		{
			id: 3,
			name: t('gallery_manager.clicks'),
			value: kFormatter(clicks || 0),
			col: 4,
		},
		{
			id: 4,
			name: t('gallery_manager.cta_clicks'),
			value: kFormatter(ctaClicks || 0),
			col: 8,
		},
	];

	const onValuesChange = (val: any) => {};

	const handleFinishForm = (values: any) => {
		const { reorder, ...otherDetail } = gallerySelect || {};
		const metadataObj: MetadataGalleryDto = {
			...gallerySelect?.metadata,
			description: values?.description,
		};

		dispatch(
			updateDetailFolderGalleryRequest({
				params: {
					...otherDetail,
					metadata: JSON.stringify(metadataObj),
				},
				isAuto: true,
			}),
		);
	};

	const handleResetField = () => {
		form.setFieldsValue({ [fieldChange.description]: description });
	};

	const handleChangeRangeDate = (val: any) => {
		const start = new Date(val[0]);
		const end = new Date(val[1]);
		start.setHours(0, 0, 0, 0);
		end.setHours(23, 59, 59, 999);
		setDateRangeChange([start.getTime(), end.getTime()]);
	};

	const handleGetMetricsGallery = () => {
		dispatch(
			fetchMetricsGalleryRequest({
				galleryId: gallerySelect?.id,
				params: { startTime: dateRange[0], endTime: dateRange[1] },
			}),
		);
	};

	useEffect(() => {
		handleResetField();
	}, [name, description]);

	useEffect(() => {
		if (statusChangeDetailGallery === NONE) {
			handleResetField();
		}
	}, [statusChangeDetailGallery]);

	useEffect(() => {
		if (gallerySelect?.id === gallery?.id) {
			handleGetMetricsGallery();
		}
	}, [gallerySelect?.id, dateRange]);

	useEffect(() => {
		let hasChange = false;
		dateRange?.forEach((item, idx) => {
			if (item !== dateRangeChange[idx]) {
				hasChange = true;
			}
		});
		if (!openRangeDate && hasChange) {
			setDateRange([...dateRangeChange]);
		}
	}, [openRangeDate]);

	return (
		<StyledGalleryItem className="custom_scroll_bar">
			{viewEmbed && (
				<PreviewEmbedModal
					visible={viewEmbed}
					onCancel={() => setViewEmbed(false)}
					gallery={gallery}
				/>
			)}
			<StyledTitle fontSize="16px" margin="0 0 16px 0">
				{t('gallery_manager.visitor_insights')}
				<StyledButtonInfo style={{ marginLeft: '4px' }}>
					{t('gallery_manager.beta')}
					<StyledIcon marginSvg="0 0 0 4px" size={12}>
						<IconNoteMessage />
					</StyledIcon>
				</StyledButtonInfo>
			</StyledTitle>
			<StyledDatePicker
				style={{ marginBottom: '12px' }}
				bgColor={THEME.colors.darkBlue3}
				format={TYPE_FORMAT_DATE.MONTH_DAY}
				onChange={handleChangeRangeDate}
				value={[
					moment(new Date(dateRangeChange[0]), TYPE_FORMAT_DATE.MONTH_DAY),
					moment(new Date(dateRangeChange[1]), TYPE_FORMAT_DATE.MONTH_DAY),
				]}
				onOpenChange={setOpenRangeDate}
				open={openRangeDate}
			/>
			<Row gutter={[8, 8]}>
				{visitorInsights?.map((item) => (
					<Col key={item?.id} span={item?.col}>
						<StyledNameVisitor title={item.name}>{item?.name}</StyledNameVisitor>
						<StyledNameVisitor title={item?.value || ''} className="fw_bold">
							{item?.value}
						</StyledNameVisitor>
					</Col>
				))}
			</Row>
			<Form
				form={form}
				onFinish={handleFinishForm}
				initialValues={{ description }}
				onValuesChange={onValuesChange}>
				{listContentBasic?.map((item) => (
					<ContentItem key={item?.key} label={item?.label} content={item?.content} />
				))}
				<StyledWrapperContent margin="8px 0 0 0" width="100%">
					<Button
						onClick={() => setViewEmbed(true)}
						style={{ width: '100%' }}
						type="primary">
						{t('button.embed')}
					</Button>
				</StyledWrapperContent>
			</Form>
		</StyledGalleryItem>
	);
};

export default GalleryItem;
