import { TIME_TYPE, TYPE_FORMAT_DATE, UNIT_VALUE } from '@constants/common';
import { getTimeZones } from '@vvo/tzdb';
import moment from 'moment-timezone';

const convertDateTime = (date: number, isUtc?: boolean, showText?: boolean, format?: string) => {
	if (!date) {
		return '';
	}
	if (!isUtc) {
		return moment(date).format(format || TYPE_FORMAT_DATE.TIME);
	}
	const timeUtc = moment(date)
		.utc()
		.format(format || TYPE_FORMAT_DATE.TIME);
	if (showText) {
		return `${timeUtc} UTC`;
	}
	return timeUtc;
};

const convertDateDefault = (date?: number, isUtc?: boolean, format?: string) => {
	if (!date) {
		return '';
	}

	if (!isUtc) {
		return moment(date).format(format || TYPE_FORMAT_DATE.MONTH_DAY);
	}

	return moment(date)
		.utc()
		.format(format || TYPE_FORMAT_DATE.MONTH_DAY);
};

const convertTime = (hours?: number | null) => {
	if (!hours) {
		return '';
	}

	const result = {
		type: TIME_TYPE.DAYS,
		value: Math.round(hours / 24),
	};

	if (hours < UNIT_VALUE.S_24) {
		result.type = TIME_TYPE.HOURS;
		result.value = hours;
	}
	if (hours >= UNIT_VALUE.S_168) {
		result.type = TIME_TYPE.WEEKS;
		result.value = Math.round(hours / 168);
	}

	return { ...result, suffix: result?.value !== 1 };
};

const timeZones = getTimeZones();

const convertUtcToLocalTimeWithFormat = (utcTimestamp: number, format?: string): string => {
	if (!utcTimestamp) return '';

	const hasTime = format && format.toLowerCase().includes('hh');
	const tz = moment.tz.guess();
	let timezoneAbbr = '';
	const timezoneItem = timeZones.find((timeZone) => {
		return tz === timeZone.name || timeZone.group.includes(tz);
	});

	if (timezoneItem) {
		timezoneAbbr = timezoneItem.abbreviation;
	} else {
		timezoneAbbr = new Date()
			?.toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
			?.substring(4);
	}

	const localDateTime = moment
		.utc(utcTimestamp)
		.local()
		.format(format || TYPE_FORMAT_DATE.MONTH_DAY);

	return `${localDateTime}${hasTime ? ` ${timezoneAbbr}` : ''}`;
};

export { convertDateDefault, convertDateTime, convertTime, convertUtcToLocalTimeWithFormat };
