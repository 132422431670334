import THEME from '@constants/themes/themes';
import styled from 'styled-components';

const StyledWrapperSummary = styled.div`
	padding: 24px 20px;
	background-color: ${THEME.colors.darkBlue2};
	border-radius: 2px;
	margin-top: 10px;
`;

const StyledCardTemplate = styled.div`
	padding: 15px;
	border-radius: 5px;
	background-color: ${THEME.colors.darkBlue3};
`;

const StyledDescTerms = styled.div`
	padding: 6px 12px;
	border-radius: 4px;
	max-height: 80px;
	overflow: hidden auto;
	background-color: ${THEME.colors.gray5};
`;

export { StyledWrapperSummary, StyledCardTemplate, StyledDescTerms };
