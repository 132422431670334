import { CaretRightOutlined } from '@ant-design/icons';
import { IconCheckActive } from '@assets/icons';
import DetectThumbnailContent from '@components/ContentGlobal/DetectThumbnailContent';
import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { FILTER_BY, MEDIA_TYPE } from '@constants/content/contentLibrary';
import { ContentDto } from '@models/content/contentLibrary/summary';
import {
	StyledStatusActive,
	StyledThemeGallery,
} from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledIconPlay } from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import { getSocialS3URL } from '@utils/common';
import { checkExistExplicit } from '@utils/content';
import { useTranslation } from 'react-i18next';

type PropsThemeItem = {
	content: ContentDto;
	isExist?: boolean;
	[other: string]: any;
};

const ThemeItem = (props: PropsThemeItem) => {
	const { t } = useTranslation();
	const { content, isExist = false, ...other } = props;

	const renderIconVideo = () => {
		if (content?.mediaType === FILTER_BY.VIDEO.value) {
			return (
				<StyledIconPlay className="icon_play">
					<CaretRightOutlined />
				</StyledIconPlay>
			);
		}
		return '';
	};
	return (
		<StyledThemeGallery style={{ cursor: 'pointer' }} {...other} className={'theme-item'}>
			{renderIconVideo()}
			{isExist && (
				<StyledStatusActive>
					<IconCheckActive />
				</StyledStatusActive>
			)}
			<DetectThumbnailContent
				thumbnail={getSocialS3URL(content?.thumbnail)}
				dynamicThumbnail={getSocialS3URL(content?.dynamicThumbnail)}
				isVideo={content?.mediaType === MEDIA_TYPE.VIDEO}
				contentExternalUrl={content?.contentExternalUrl}
				social={content?.social}
				className="placeholder_bg_img"
			/>
			{checkExistExplicit(content?.review?.flags) && (
				<ExplicitContent zIndex={777} sizeIcon={48} fontSize="14px" />
			)}
		</StyledThemeGallery>
	);
};

export default ThemeItem;
