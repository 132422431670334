import THEME from '@constants/themes/themes';
import {
	CustomFieldsContentDto,
	DataInfoAdditionalType,
	DataItemAdditionalType,
} from '@models/content/contentLibrary/summary';
import { StyledAdditionalInfo } from '@styled/ContentLibrary/ContentDetailStyled';
import { formatDataAdditional } from '@utils/content';
import { Col, Row, Tooltip } from 'antd';
import { ReactNode, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import FormInputField from './FormInputField';
import FormTextareaField from './FormTextareaField';
import FormDropdownField from './FormDropdownField';
import { TYPE_FORM_CUSTOM_FIELDS } from '@constants/contentLibrary/contentDetail';
import CustomFieldData from './CustomFieldData';

const StyledItemInfo = styled.div``;

const StyledLabel = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray1};
	margin-bottom: 12px;
`;

const StyledContent = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray5};
	display: inline-block;
`;

const StyledTagItem = styled.div`
	min-width: 50px;
	max-width: 90px;
	padding: 2px 6px;
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue3};
	color: ${THEME.colors.gray5};
	margin-right: 5px;
	margin-bottom: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
`;

type PropsAdditionalInfo = {
	additionalFields: CustomFieldsContentDto[];
	[other: string]: any;
};

export const TooltipContent = (props: { name: string; children: ReactNode }) => {
	const { name, children } = props;
	return (
		<Tooltip
			placement="topLeft"
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
			overlayClassName="content-info-tooltip"
			title={
				<div
					style={{ whiteSpace: 'pre-wrap' }}
					dangerouslySetInnerHTML={{ __html: name }}
				/>
			}>
			{children}
		</Tooltip>
	);
};

const AdditionalInfo = (props: PropsAdditionalInfo) => {
	const { additionalFields, ...other } = props;
	const dataInfo: DataInfoAdditionalType = useMemo(() => {
		const result: DataInfoAdditionalType = formatDataAdditional(additionalFields);

		return result;
	}, [additionalFields]);

	return (
		<StyledAdditionalInfo {...other}>
			<Row gutter={[18, 0]}>
				<CustomFieldData
					data={dataInfo.single}
					type={TYPE_FORM_CUSTOM_FIELDS.input}
					isMulti={false}
				/>
				<CustomFieldData
					data={dataInfo.multi}
					type={TYPE_FORM_CUSTOM_FIELDS.dropdown}
					isMulti={true}
				/>
				<CustomFieldData
					data={dataInfo.paragraph}
					type={TYPE_FORM_CUSTOM_FIELDS.paragraph}
					isMulti={false}
				/>
			</Row>
		</StyledAdditionalInfo>
	);
};

export default AdditionalInfo;
