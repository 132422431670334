import THEME from '@constants/themes/themes';
import { StyledSwitch } from '@styled/Common/CommonStyled';
import { Form, Table, Select, Modal } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div<{ height?: string; margin?: string; padding?: string }>`
	height: ${({ height }) => height || 'calc(100vh - 185px)'};
	margin: ${({ margin }) => margin || 'unset'};
	padding: ${({ padding }) => padding || 'unset'};
	background-color: ${THEME.colors.darkBlue2};
`;

export const StyledForm = styled(({ ...props }) => <Form {...props} />)`
	position: relative;

	.ant-form-item {
		display: inline-block;
		margin-right: 16px;

		&-label {
			padding: unset;
		}
	}
`;

export const StyledAction = styled.div<{ margin?: string }>`
	display: flex;
	justify-content: space-between;
	margin: ${({ margin }) => margin || 'unset'};
`;

export const StyledTitle = styled.span<{ fontSize?: string }>`
	font-size: ${({ fontSize }) => fontSize || '1rem'};
`;

export const StyledTable = styled(({ border, ...props }) => <Table {...props} />)`
	border: ${({ border }) => border || 'none'};
	border-radius: ${({ border }) => (border ? '2px' : 'none')};

	.ant-checkbox-wrapper {
		align-items: center;

		.ant-checkbox {
			top: 0;
		}
	}

	.ant-table {
		display: block;
		max-width: 100%;
		overflow-x: auto;
		white-space: nowrap;
		.ant-table-body {
			height: calc(100vh - 370px);
		}
	}

	.ant-spin-nested-loading {
		> div {
			> .ant-spin {
				max-height: unset;
			}
		}
	}

	.disabled-row {
		pointer-events: none;
		opacity: ${THEME.disabledOpacity};
	}
`;

export const StyledWrapperTable = styled.div<{ border?: string }>`
	.ant-table-wrapper {
		border: ${({ border }) => border || 'none'};
		border-radius: ${({ border }) => (border ? '2px' : 'none')};

		.ant-checkbox-wrapper {
			align-items: center;

			.ant-checkbox {
				top: 0;
			}
		}

		.ant-table {
			display: block;
			max-width: 100%;
			overflow-x: auto;
			white-space: nowrap;
			.ant-table-body {
				height: calc(100vh - 370px);
			}
		}

		.disabled-row {
			pointer-events: none;
			opacity: ${THEME.disabledOpacity};
		}
	}
`;

export const StyledSwitchStatus = styled(StyledSwitch)`
	height: 16px;
	min-width: 36px !important;
	line-height: 16px;

	.ant-switch-handle {
		width: 14px;
		height: 14px;
		top: 1px;
	}

	&.ant-switch-checked {
		background: rgba(226, 88, 43, 0.5);

		.ant-switch-handle {
			left: calc(100% - 16px);

			&::before {
				background-color: ${THEME.colors.orangeBase};
			}
		}
	}

	&.ant-switch-disabled {
		opacity: 1;
		cursor: unset;

		* {
			cursor: auto;
		}
	}
`;

export const StyledPagination = styled.div<{ bottom?: number }>`
	background-color: ${THEME.colors.darkBlue1};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 82px;
	position: absolute;
	bottom: ${({ bottom }) => `${bottom || 0}px`};
	left: 0;
	z-index: 333;
`;

export const StyledSelect = styled(Select)`
	&.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
		.ant-select-selector {
			cursor: auto;
		}
	}
`;

export const StyledNotificationModal = styled(Modal)`
	.ant-modal-content {
		width: 600px;
		background-color: ${THEME.colors.white};
		color: ${THEME.colors.darkBlue1};

		.ant-modal-body {
			padding: 0;
		}
	}
`;

export const StyledNotificationWrapper = styled.div`
	padding: 16px 20px;
	min-height: 150px;
`;
