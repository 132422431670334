import THEME from '@constants/themes/themes';
import { LoadingWrapper } from '@cores/index';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledDirectLink } from '@styled/Content/ContentLibrary/ContentStyled';
import { Button, Modal } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
	.ant-modal-body {
		padding: 15px 17px;
	}
`;

type PropTypes = {
	handleDownload: () => void;
	handleImport: () => void;
	isLoading?: boolean;
	[other: string]: any;
};
const ImportModal = (props: PropTypes) => {
	const { t } = useTranslation();
	const { handleDownload, handleImport, isLoading, ...other } = props;

	return (
		<StyledModal
			title={t('creator_summary.modal.importing_list')}
			width={500}
			centered
			{...other}
			footer={[
				<Button onClick={() => handleDownload()} disabled={isLoading}>
					{t('button.download_template')}
				</Button>,
				<Button onClick={() => handleImport()} type="primary">
					{t('button.choose_file_import')}
				</Button>,
			]}>
			<LoadingWrapper isLoading={isLoading}>
				<StyledWrapperContent fontSize="14px" lineHeight="18px" color={THEME.colors.white}>
					<Trans
						t={t}
						values={{}}
						i18nKey={'creator_summary.modal.import_info'}
						components={{
							elm: (
								<StyledDirectLink
									style={{ margin: '0' }}
									onClick={() => handleDownload()}
								/>
							),
						}}
					/>
					<br />
					<br />
					{t('creator_summary.modal.sub_import_info')}
				</StyledWrapperContent>
			</LoadingWrapper>
		</StyledModal>
	);
};

export default ImportModal;
