import { IconClose, IconMoreVert, IconStar } from '@assets/icons';
import { DIRECT_MESSAGE_LIMIT } from '@constants/common';
import { MAX_LENGTH_CHARACTER } from '@constants/content/contentLibrary';
import { SELECTOR_ELEMENTS, UPLOADER_LINK } from '@constants/messages';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { ParamRequestListBasic } from '@models/common/summary';
import { DirectMessageDto } from '@models/messages/summary';
import { ActionsTypes } from '@models/socialsearch/summary';
import {
	createDirectMessageRequest,
	deleteDMTemplateEnd,
	deleteDMTemplateRequest,
	getDirectMessageDetail,
	getDirectMessageDetailEnd,
	getDirectMessageEnd,
	getDirectMessageRequest,
	updateDirectMessageRequest,
} from '@stores/actions';
import {
	StyledForm,
	StyledFormItem,
	StyledIcon,
	StyledIconWrapper,
	StyledInput,
	StyledSpace,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledContainer } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	StyledBtn,
	StyledBtnGhost,
	StyledTitle,
} from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledContainerMessage,
	StyledCustomizedTextArea,
	StyledMessageTemplates,
	StyledPreviewMessage,
	StyledRadio,
	StyledSend,
	StyledTextDM,
	StyledTitleContainer,
	StyledUploaderLink,
} from '@styled/Messages/DirectMessages';
import { useScrollToLoadMore } from '@utils/customHooks';
import { menuActions } from '@utils/renderComponent';
import { Dropdown, Radio, RadioChangeEvent, message } from 'antd';
import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type RequestRightsDMProps = {
	height?: number;
	handleRequestRights: () => void;
	goToSocialProfile?: () => void;
	currentContent?: any;
	getSelectId: boolean;
	markAsContacted?: () => void;
	socialType?: string;
	isLoading: boolean;
	setIsLoading: (val: boolean) => void;
};

const ACTION_DIRECT_MESSAGE = {
	SAVE_CHANGES: 'save_changes',
	SAVE_AS: 'save_as',
	DELETE: 'delete',
	SET_AS_DEFAULT: 'set_as_default',
	RENAME: 'RENAME',
};

const RequestRightsDM = (props: RequestRightsDMProps) => {
	const {
		height,
		handleRequestRights,
		goToSocialProfile,
		currentContent,
		getSelectId,
		markAsContacted,
		socialType,
		isLoading,
		setIsLoading,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		directMessageList = [],
		getDirectMessageStatus = false,
		getDirectMessageDetailStatus,
		directMessageDetail,
		deleteDirectMessageStatus = NONE,
		error,
	} = useSelector((state: any) => state.messages);

	const [currentDirectMessage, setCurrentDirectMessage] = useState<DirectMessageDto | null>(null);
	const [directMessageArr, setDirectMessageArr] = useState<DirectMessageDto[]>([]);
	const [confirmationModalType, setConfirmationModalType] = useState<string>('');
	const [payload, setPayload] = useState<ParamRequestListBasic>({
		page: 1,
		maxRecords: 50,
	});
	const element = document.getElementById(SELECTOR_ELEMENTS.DM_CONTAINER_ID);

	useEffect(() => {
		return () => {
			dispatch(getDirectMessageEnd());
		};
	}, [currentContent]);

	useEffect(() => {
		if (payload) {
			fetchDirectMessage();
		}
	}, [payload]);

	useEffect(() => {
		if (directMessageList && directMessageList.length) {
			if (payload.page === 1) {
				setCurrentDirectMessage(directMessageList[0]);
				if (element) {
					element.scrollTop = 0;
				}
			}

			setDirectMessageArr(
				payload.page === 1
					? directMessageList
					: [...directMessageArr, ...directMessageList],
			);
		}
	}, [directMessageList]);

	useEffect(() => {
		if (getDirectMessageDetailStatus === SUCCEEDED && directMessageDetail) {
			setIsLoading(false);
			const textarea = document.createElement('textarea');
			textarea.value = directMessageDetail.template
				? directMessageDetail.template.replace(/^"(.*)"$/, '$1')
				: JSON.parse(directMessageDetail.template);
			document.body.appendChild(textarea);
			textarea.select();
			document.execCommand('copy');
			if (markAsContacted) {
				markAsContacted();
			}
			if (goToSocialProfile) {
				goToSocialProfile();
			}
			dispatch(getDirectMessageDetailEnd());
			textarea.remove();
		}

		if (getDirectMessageDetailStatus === FAILED) {
			setIsLoading(false);
		}
	}, [getDirectMessageDetailStatus]);

	useEffect(() => {
		if (
			deleteDirectMessageStatus &&
			(deleteDirectMessageStatus === SUCCEEDED || deleteDirectMessageStatus === FAILED)
		) {
			setIsLoading(false);
		}

		if (error && deleteDirectMessageStatus === FAILED) {
			message.error(error);
			dispatch(deleteDMTemplateEnd());
		}

		if (deleteDirectMessageStatus === SUCCEEDED) {
			let directMessageArrTemp = directMessageArr;
			directMessageArrTemp = directMessageArrTemp.filter(
				(item: DirectMessageDto) => item.id !== currentDirectMessage?.id,
			);
			setDirectMessageArr(directMessageArrTemp);
			setCurrentDirectMessage(directMessageArrTemp[0]);
			setConfirmationModalType('');
			message.success(t('message.action_success', { action: t('action.deleted') }));
			dispatch(deleteDMTemplateEnd());
		}
	}, [deleteDirectMessageStatus, error]);

	useEffect(() => {
		if (
			getDirectMessageStatus &&
			(getDirectMessageStatus === SUCCEEDED || getDirectMessageStatus === FAILED)
		) {
			setIsLoading(false);
		}
	}, [getDirectMessageStatus]);

	const handleLoadMore = () => {
		if (directMessageList && directMessageList?.length > 0 && payload) {
			setPayload({ ...payload, page: payload.page + 1 });
		}
	};

	useScrollToLoadMore(element, handleLoadMore);

	const fetchDirectMessage = () => {
		setIsLoading(true);
		dispatch(
			getDirectMessageRequest({
				...payload,
			}),
		);
	};

	const onChange = (evt: RadioChangeEvent) => {
		const { value } = evt.target;

		const messageIndex = directMessageArr.findIndex((item: any) => item.id === value);
		setCurrentDirectMessage(directMessageArr[messageIndex]);
	};

	const handleDirectSocialProfile = () => {
		let payload;
		if (getSelectId) {
			const { social: { contentId = 0, socialUsername = '', socialSource = '' } = {} } =
				currentContent;

			payload = {
				selectId: contentId,
				username: socialUsername,
				socialSource: socialSource.toUpperCase(),
			};
		} else {
			const { username } = currentContent;
			payload = {
				username,
				socialSource: socialType?.toUpperCase(),
			};
		}

		setIsLoading(true);
		dispatch(getDirectMessageDetail({ ...payload, directMessage: currentDirectMessage }));
	};

	const handleSaveDirectMessage = (action: string) => {
		const templatePayload = {
			...currentDirectMessage,
			messageTemplate: currentDirectMessage?.template || '',
		};

		delete templatePayload.template;

		const payload = {
			template: templatePayload,
			onSucceeded: (result: any) => updateSucceeded(result, action),
			onFailed,
		};
		let index: number = directMessageArr.length;
		setIsLoading(true);

		switch (action) {
			case ACTION_DIRECT_MESSAGE.SAVE_AS:
				if (index >= DIRECT_MESSAGE_LIMIT) {
					setIsLoading(false);
					message.error(t('message_templates.validation.direct_message_limit'));
					return;
				}

				setIsLoading(false);

				delete payload.template.id;
				payload.template.name = `Custom_${index === 1 ? index : index + 1}`;

				if (checkExistedTemplateName(directMessageArr, payload.template.name) !== -1) {
					do {
						index += 1;
						payload.template.name = `Custom_${index}`;
					} while (
						checkExistedTemplateName(directMessageArr, payload.template.name) !== -1
					);
				}

				payload.onSucceeded = saveAsTemplateSucceeded;
				dispatch(createDirectMessageRequest(payload));
				break;

			case ACTION_DIRECT_MESSAGE.SET_AS_DEFAULT: {
				payload.template.default = true;
				dispatch(updateDirectMessageRequest(payload));
				break;
			}

			case ACTION_DIRECT_MESSAGE.RENAME: {
				dispatch(updateDirectMessageRequest(payload));
				break;
			}

			case ACTION_DIRECT_MESSAGE.DELETE: {
				dispatch(
					deleteDMTemplateRequest({
						directMessage: currentDirectMessage,
					}),
				);
				break;
			}

			case ACTION_DIRECT_MESSAGE.SAVE_CHANGES:
				dispatch(updateDirectMessageRequest(payload));
				break;

			default:
				break;
		}
	};

	const actionOnDirectMessage = (type: string) => {
		switch (true) {
			case type === ACTION_DIRECT_MESSAGE.DELETE: {
				if (currentDirectMessage?.default) {
					message.destroy();
					message.info(t('message.delete_default_direct_message'));
					return;
				}
				setConfirmationModalType(ACTION_DIRECT_MESSAGE.DELETE);
				break;
			}

			default: {
				setConfirmationModalType(type);
				break;
			}
		}
	};

	const actionsObj: ActionsTypes[] = [
		{
			name: t('button.save_changes_DM'),
			click: () => handleSaveDirectMessage(ACTION_DIRECT_MESSAGE.SAVE_CHANGES),
		},
		{
			name: t('button.save_as_new_template'),
			click: () => handleSaveDirectMessage(ACTION_DIRECT_MESSAGE.SAVE_AS),
		},
		{
			name: t('button.set_as_default'),
			click: () => handleSaveDirectMessage(ACTION_DIRECT_MESSAGE.SET_AS_DEFAULT),
		},
		{
			name: t('button.rename_template'),
			click: () => actionOnDirectMessage(ACTION_DIRECT_MESSAGE.RENAME),
		},
		{
			name: t('button.delete_template'),
			click: () => actionOnDirectMessage(ACTION_DIRECT_MESSAGE.DELETE),
		},
	];

	const checkExistedTemplateName = (list: DirectMessageDto[], itemNameCreated: string) => {
		return list.findIndex(
			(item: DirectMessageDto) => item.name.toLowerCase() === itemNameCreated.toLowerCase(),
		);
	};

	const updateSucceeded = (result: DirectMessageDto, action: string) => {
		let directMessageArrTemp = directMessageArr;
		const findIndex = directMessageArrTemp.findIndex(
			(element: DirectMessageDto) => result?.id === element.id,
		);

		if (result) {
			directMessageArrTemp[findIndex] = result;
			if (action === ACTION_DIRECT_MESSAGE.SET_AS_DEFAULT) {
				directMessageArrTemp = directMessageArrTemp.map((dm: DirectMessageDto) => {
					if (dm.id !== result.id && dm.default) {
						dm.default = false;
					}
					return dm;
				});
			}
		}
		setDirectMessageArr(directMessageArrTemp);
		setCurrentDirectMessage(directMessageArrTemp[findIndex]);
		setIsLoading(false);
		if (confirmationModalType) {
			setConfirmationModalType('');
		}
		message.destroy();
		message.success(t('message.save_success'));
	};

	const saveAsTemplateSucceeded = () => {
		setPayload({ ...payload, page: 1 });
		setIsLoading(false);
		message.success(t('message.save_success'));
	};

	const onFailed = (error: string) => {
		setIsLoading(false);
		message.error(error);
	};

	const handleChangeDirectMessage = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (currentDirectMessage)
			setCurrentDirectMessage({ ...currentDirectMessage, template: evt.target.value });
	};

	const addUploaderLink = () => {
		if (currentDirectMessage) {
			const addedTemplate = currentDirectMessage.template + UPLOADER_LINK;
			setCurrentDirectMessage({ ...currentDirectMessage, template: addedTemplate });
		}
	};

	const onCancelConfirmationModal = () => {
		if (confirmationModalType === ACTION_DIRECT_MESSAGE.RENAME && currentDirectMessage) {
			const originalDirectMessage = directMessageArr.find(
				(item: DirectMessageDto) => item.id === currentDirectMessage.id,
			);
			if (originalDirectMessage) {
				setCurrentDirectMessage(originalDirectMessage);
			}
		}

		setConfirmationModalType('');
	};

	const renderConfirmationModal = () => {
		let content: ReactNode = '';
		let onSubmit: () => void;
		let onClose = () => {
			setConfirmationModalType('');
		};
		let okText = t('button.delete');
		const isOpen: boolean = !!confirmationModalType;

		if (confirmationModalType === ACTION_DIRECT_MESSAGE.DELETE) {
			content = t('message.delete_direct_message');
			okText = t('button.delete');
			onSubmit = () => handleSaveDirectMessage(ACTION_DIRECT_MESSAGE.DELETE);
		}

		if (confirmationModalType === ACTION_DIRECT_MESSAGE.RENAME) {
			content = (
				<StyledForm
					align="left"
					initialValues={{
						directMessageName: currentDirectMessage?.name,
					}}
					onFinish={() => {
						handleSaveDirectMessage(ACTION_DIRECT_MESSAGE.RENAME);
					}}>
					<StyledFormItem
						height="32px"
						name="directMessageName"
						margin="0"
						rules={[
							{
								required: true,
								message: t('validate.required'),
							},
						]}>
						<StyledInput
							autoFocus
							bgColor={THEME.colors.darkBlue3}
							maxLength={MAX_LENGTH_CHARACTER}
							value={currentDirectMessage?.name}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const { value } = e.target;
								if (currentDirectMessage) {
									const directMessageTemp = { ...currentDirectMessage };
									directMessageTemp.name = value;
									setCurrentDirectMessage(directMessageTemp);
								}
							}}
						/>
					</StyledFormItem>
				</StyledForm>
			);
			okText = t('button.save');
			onSubmit = () => handleSaveDirectMessage(ACTION_DIRECT_MESSAGE.RENAME);
			onClose = onCancelConfirmationModal;
		}

		return (
			<ConfirmModal
				width={350}
				confirmLoading={deleteDirectMessageStatus === IN_PROGRESS || isLoading}
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={onClose}>
				{content}
			</ConfirmModal>
		);
	};

	return (
		<LoadingWrapper isLoading={false} sizeLoading="medium">
			<StyledContainerMessage>
				<StyledTitleContainer>
					<StyledTitle fontSize="1.143rem" fontWeight="600">
						{t('message_templates.request_rights')}
					</StyledTitle>
					<IconClose className="icon-close" onClick={handleRequestRights} />
				</StyledTitleContainer>

				<StyledWrapperContent height={'calc(100% - 56px)'}>
					<StyledContainer
						id={SELECTOR_ELEMENTS.DM_CONTAINER_ID}
						className={'custom_scroll_bar container_custom'}
						height={height || 0}
						margin="0 0 8px 0"
						padding="0">
						{directMessageArr.length ? (
							<StyledRadio value={currentDirectMessage?.id} onChange={onChange}>
								<StyledSpace width="100%" direction="vertical">
									{directMessageArr.map((item: any) => (
										<Radio key={item.id} value={item.id}>
											<p>{item.name}</p>
											<StyledTextDM>{item.template}</StyledTextDM>
											{item?.default && (
												<StyledWrapperContent
													position="absolute"
													right="10px"
													top="10px">
													<StyledIcon
														fillPath={THEME.colors.yellowBase}
														size={20}>
														<IconStar />
													</StyledIcon>
												</StyledWrapperContent>
											)}
										</Radio>
									))}
								</StyledSpace>
							</StyledRadio>
						) : null}
					</StyledContainer>
					<StyledPreviewMessage>
						<StyledCustomizedTextArea
							id="direct-message-content"
							value={
								!_.isEmpty(currentDirectMessage)
									? currentDirectMessage?.body || currentDirectMessage?.template
									: ''
							}
							onChange={handleChangeDirectMessage}
						/>

						<StyledUploaderLink>
							<StyledBtnGhost
								width="95%"
								margin="10px auto"
								onClick={addUploaderLink}
								type="ghost">
								{t('button.add_uploader_link')}
							</StyledBtnGhost>
						</StyledUploaderLink>

						<StyledSend>
							<Dropdown
								overlay={menuActions(actionsObj)}
								getPopupContainer={(triggerNode: HTMLElement) =>
									triggerNode.parentNode as HTMLElement
								}
								placement="topLeft"
								trigger={['click']}>
								<StyledIconWrapper cursor="pointer" margin="0 8px 0 2px">
									<IconMoreVert />
								</StyledIconWrapper>
							</Dropdown>

							<StyledBtn onClick={handleDirectSocialProfile} width="90%" type="ghost">
								{t('button.copy_and_go_profile')}
							</StyledBtn>
						</StyledSend>
					</StyledPreviewMessage>
				</StyledWrapperContent>
			</StyledContainerMessage>

			{renderConfirmationModal()}
		</LoadingWrapper>
	);
};

export default RequestRightsDM;
