import {
	IconAltAdd,
	IconGridActiveLayout,
	IconGridLayout,
	IconMosaicTheme,
	IconMosaicThemeActive,
	IconSignageTheme,
	IconSignageThemeActive,
	IconSliderActiveLayout,
	IconSliderLayout,
} from '@assets/icons';
import { PARAMS_CREATE_DEFAULT, TYPE_THEME } from '@constants/publish/gallery';
import { NONE } from '@constants/status';
import GalleryManagerContext from '@contexts/Publishing/Galleries';
import { FolderGalleryDto } from '@models/publishing/galleries';
import {
	changeGallerySelect,
	createFolderGalleryRequest,
	updateDetailFolderGalleryRequest,
} from '@stores/actions';
import {
	StyledButtonFull,
	StyledCollapse,
	StyledFooterSideBar,
	StyledGlobalInfo,
	StyledInput,
	StyledPanelCollapse,
	StyledQuantityTheme,
	StyledSidebarGalleries,
	StyledTitleGallery,
	StyleNameGallery,
} from '@styled/Publish/Gallery/GalleryManagerStyled';
import { hashCode } from '@utils/common';
import { renderThumbVertical } from '@utils/renderComponent';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import GalleryItem from './GalleryItem';
import { TIME_OUT } from '@constants/common';

const SidebarGalleries = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const scrollbars = useRef<any>({});
	const refTime = useRef<any>(null);

	const { folderGallery, loadingGallery, statusChangeDetailGallery, isAutoSave } = useSelector(
		(state: any) => state.galleryManager,
	);

	const {
		gallerySelect,
		handleChangeGallerySelect,
		listFolderGallery,
		paramFolder,
		setParamFolder,
	} = useContext(GalleryManagerContext);

	const [keyCollapse, setKeyCollapse] = useState<number | string>('');
	const [isEditName, setIsEditName] = useState<boolean>(false);
	const [count, setCount] = useState<number>(0);
	const [keyClick, setKeyClick] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (folderGallery?.length > 0 && !gallerySelect && paramFolder?.page === 1) {
			setKeyCollapse(folderGallery[0]?.id);
		}
	}, [folderGallery]);

	useEffect(() => {
		dispatch(changeGallerySelect(gallerySelect));
	}, [gallerySelect]);

	useEffect(() => {
		if (gallerySelect && gallerySelect?.id !== +keyCollapse) {
			setKeyCollapse(gallerySelect?.id || '');
		}
	}, [gallerySelect?.id]);

	useEffect(() => {
		if (refTime.current) {
			clearTimeout(refTime.current);
		}
		refTime.current = setTimeout(() => {
			if (count === 1) {
				setCount(0);
				onSelectGallery(keyClick === keyCollapse ? undefined : keyClick);
			}
		}, TIME_OUT.DELAY_SELECT);

		if (count === 2) {
			setCount(0);
			setIsEditName(true);
		}
	}, [count]);

	const onSaveNameGallery = (nameChange: string, galleryChange: FolderGalleryDto) => {
		if (nameChange !== galleryChange?.name) {
			const { reorder, ...otherDetail } = galleryChange || {};

			dispatch(
				updateDetailFolderGalleryRequest({
					params: {
						...otherDetail,
						metadata: JSON.stringify(otherDetail?.metadata),
						name: nameChange,
					},
					isAuto: true,
				}),
			);
		}
	};

	const renderTotalContent = (item: FolderGalleryDto) => {
		const { numberOfImages, numberOfVideos } = item;
		let textPhoto = 'albums.photo';
		let textVideo = 'albums.video';
		if (numberOfVideos > 1 || numberOfVideos === 0) {
			textVideo = 'albums.videos';
		}
		if (numberOfImages > 1 || numberOfImages === 0) {
			textPhoto = 'albums.photos';
		}

		return `${numberOfImages} ${t(textPhoto)} / ${numberOfVideos} ${t(textVideo)}`;
	};

	const renderHeader = (item: FolderGalleryDto) => {
		const { theme: themeItem = TYPE_THEME.GRID } = item?.metadata;
		const isActive = gallerySelect?.id === item?.id;

		return (
			<StyledTitleGallery>
				{(!themeItem || themeItem === TYPE_THEME.GRID) && (
					<>{isActive ? <IconGridActiveLayout /> : <IconGridLayout />}</>
				)}
				{themeItem === TYPE_THEME.SLIDER && (
					<>{isActive ? <IconSliderActiveLayout /> : <IconSliderLayout />}</>
				)}
				{themeItem === TYPE_THEME.MOSAIC && (
					<>{isActive ? <IconMosaicThemeActive /> : <IconMosaicTheme />}</>
				)}
				{themeItem === TYPE_THEME.SIGNAGE && (
					<>{isActive ? <IconSignageThemeActive /> : <IconSignageTheme />}</>
				)}

				<StyledGlobalInfo style={{ width: 'calc(100% - 118px)' }}>
					<StyleNameGallery
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							if (isActive) {
								e.preventDefault();
								e.stopPropagation();
								setCount((prev) => prev + 1);
								setKeyClick(item?.id?.toString());
							}
						}}
						isActive={isActive}>
						{isEditName && isActive ? (
							<StyledInput
								onClick={(e: React.MouseEvent<HTMLInputElement>) => {
									e.preventDefault();
									e.stopPropagation();
								}}
								defaultValue={item?.name}
								onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
									setIsEditName(false);
									const valueChange = e?.target?.value?.trim() || '';
									onSaveNameGallery(valueChange, gallerySelect);
								}}
								autoFocus
								disabled={statusChangeDetailGallery !== NONE}
							/>
						) : (
							item?.name
						)}
					</StyleNameGallery>
					<StyledQuantityTheme>{renderTotalContent(item)}</StyledQuantityTheme>
				</StyledGlobalInfo>
			</StyledTitleGallery>
		);
	};

	const onSelectGallery = (key: any) => {
		setKeyCollapse(key);

		const galleryItem = listFolderGallery?.find((item: FolderGalleryDto) => item?.id === +key);
		if (galleryItem && galleryItem?.id !== gallerySelect?.id) {
			handleChangeGallerySelect(galleryItem);
		}
	};

	const onCreateGallery = () => {
		dispatch(createFolderGalleryRequest());
	};

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			if (scrollbarTop >= 0.75 && !loadingGallery && folderGallery?.length > 0) {
				setParamFolder((prev: any) => ({ ...prev, page: prev.page + 1 }));
			}
		}
	};

	return (
		<StyledSidebarGalleries>
			<Scrollbars
				className="scrollbar_custom_wrapper"
				renderTrackVertical={() => <div />}
				ref={(el) => {
					scrollbars.current = el;
				}}
				onScrollStop={onScrollStop}
				style={{ height: 'calc(100vh - 148px)' }}
				renderThumbVertical={renderThumbVertical}>
				<StyledCollapse
					expandIconPosition="right"
					activeKey={[keyCollapse]}
					accordion
					onChange={onSelectGallery}
					className="collapse_gallery">
					{listFolderGallery?.map((item: FolderGalleryDto) => (
						<StyledPanelCollapse header={renderHeader(item)} key={item?.id}>
							<GalleryItem gallery={item} />
						</StyledPanelCollapse>
					))}
				</StyledCollapse>
			</Scrollbars>
			<StyledFooterSideBar>
				<StyledButtonFull onClick={onCreateGallery}>
					<IconAltAdd />
					{t('gallery_manager.button.create_gallery')}
				</StyledButtonFull>
			</StyledFooterSideBar>
		</StyledSidebarGalleries>
	);
};

export default SidebarGalleries;
