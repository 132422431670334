import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
	Paginator as defaultCreatorPage,
	CreatorSegmentPaginator as defaultSegmentPage,
} from '@constants/paginator';
import { Modal } from '@cores/Modal';
import { Col, Pagination, Row, Spin, Tabs, Tooltip, message } from 'antd';

import THEME from '@constants/themes/themes';
import LoadingWrapper from '@cores/LoadingWrapper';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { SegmentsDto } from '@models/creator/segments';
import { SummaryDto } from '@models/creator/summary';
import { creatorFindProfiles, creatorGetSegment } from '@stores/actions';
import { StyledTable, StyledTag } from '@styled/Common/CommonStyled';
import { StyledSearch } from '@styled/Content/ContentLibrary/ActionBarStyled';
import {
	StyledTabs,
	StyledTitle,
	StyledWrapper,
} from '@styled/Content/ContentLibrary/ContentStyled';

const { TabPane } = Tabs;
const StyledModal = styled(Modal)``;

const StyledSearchContainer = styled.div`
	float: left;
	margin: 8px 8px 24px 0;
`;

const StyledSelected = styled.div`
	border: 1px solid ${THEME.colors.darkBlue3};
	padding: 5px;
	margin: 16px 0;
	max-height: 145px;
	min-height: 44px;
	overflow: auto;
`;

const StyledPagination = styled.div`
	width: 100%;
	display: flex;
	padding: 16px 0;
	align-items: center;
	justify-content: center;

	.ant-pagination-item,
	.ant-pagination-item-link {
		background-color: transparent !important;
		border: none;
		min-width: none;
	}

	.ant-pagination-item-link {
		background-color: ${THEME.colors.darkBlue2};
	}
`;

type EditRecipientsProps = {
	isVisible: boolean;
	selectedCreators: SummaryDto[];
	selectedSegments: SegmentsDto[];
	onClose: () => void;
	handleSaveRecipients: (creators: SummaryDto[], segments: SegmentsDto[]) => void;
};

type TabsDto = {
	key: string;
	title: string;
};

const EditRecipientsModal = (props: EditRecipientsProps) => {
	const { t } = useTranslation();
	const tableRef = useRef<any>();

	const {
		isVisible,
		onClose,
		handleSaveRecipients,
		selectedCreators: selectedCreatorsProp,
		selectedSegments: selectedSegmentsProp,
	} = props;

	const creatorColumns = [
		{
			title: t('creator_summary.first_name'),
			dataIndex: 'firstName',
			width: 150,
			render: (firstName: string) => renderName(firstName),
			key: 'firstName',
		},
		{
			title: t('creator_summary.last_name'),
			dataIndex: 'lastName',
			width: 150,
			render: (lastName: string) => renderName(lastName),
			key: 'lastName',
		},
		{
			title: t('creator_summary.email'),
			dataIndex: 'email',
			width: 250,
			key: 'email',
		},
		{
			title: t('creator_summary.instagram'),
			dataIndex: 'instagramUsername',
			show: false,
			width: 150,
		},
		{
			title: t('creator_summary.additional_direct_uploads'),
			dataIndex: 'directUploads',
			align: 'center' as 'center',
			show: true,
			width: 150,
		},
		{
			title: t('creator_summary.location'),
			dataIndex: 'location',
			width: 150,
			show: true,
		},
		{
			title: t('creator_summary.date'),
			dataIndex: 'createAt',
			show: true,
			render: (createAt: string) => renderDate(createAt),
			width: 150,
		},
	];

	const segmentColumns = [
		{
			title: t('rewards.creator_segments'),
			dataIndex: 'title',
			show: true,
		},
		{
			title: t('rewards.creators'),
			dataIndex: 'totalCreatorProfiles',
			show: true,
			align: 'left',
		},
	];

	const TAB = {
		CREATORS: 'creators',
		SEGMENTS: 'segments',
	};

	const tabs: TabsDto[] = [
		{
			key: TAB.CREATORS,
			title: t('email_builder.all_creators'),
		},
		{
			key: TAB.SEGMENTS,
			title: t('sidebar.creators_segments'),
		},
	];

	const selectedSegmentsOriginal = selectedSegmentsProp;
	const selectedCreatorsOriginal = selectedCreatorsProp;

	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState<string>('');
	const [selectedSegments, setSelectedSegments] =
		useState<SegmentsDto[]>(selectedSegmentsOriginal);
	const [selectedCreators, setSelectedCreators] =
		useState<SummaryDto[]>(selectedCreatorsOriginal);
	const [activeTab, setActiveTab] = useState<string>(TAB.CREATORS);

	const creatorDataDefault = {
		currentPage: 1,
		columns: creatorColumns,
		selectedRowKeys: [],
		dataSource: [],
	};

	const segmentDataDefault = {
		currentPage: 1,
		columns: segmentColumns,
		selectedRowKeys: [],
		dataSource: [],
	};

	const [creatorData, setCreatorData] = useState<any>(creatorDataDefault);
	const [segmentData, setSegmentData] = useState<any>(segmentDataDefault);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isCreatorTab: boolean = activeTab === TAB.CREATORS;

	const {
		fetchingProfiles,
		profilesData = [],
		totalRecords = 0,
		fetchingSegment,
		segmentData: segmentList = [],
		totalSegments = 0,
	} = useSelector((state: any) => state.creator);

	useEffect(() => {
		setActiveTab(TAB.CREATORS);
	}, []);

	useEffect(() => {
		if (isVisible) {
			getCreators();
			getSegments();
			initSelectedRowKeys();
		}

		if (!isVisible) {
			setActiveTab(TAB.CREATORS);
		}
	}, [isVisible]);

	useEffect((): any => {
		setCreatorData({ ...creatorData, dataSource: profilesData });
	}, [profilesData]);

	useEffect((): any => {
		setSegmentData({ ...segmentData, dataSource: segmentList });
	}, [segmentList]);

	const initSelectedRowKeys = () => {
		setSelectedCreators(selectedCreatorsOriginal);
		setSelectedSegments(selectedSegmentsOriginal);
	};

	const getCreators = (pageNumber?: number) => {
		const params = {
			search: searchValue,
			maxRecords: defaultCreatorPage.pageSize - 150,
			page: pageNumber ? pageNumber : defaultCreatorPage.defaultPage,
		};

		dispatch(creatorFindProfiles(params));
	};

	const getSegments = (pageNumber?: number) => {
		const params = {
			pageRecords: defaultSegmentPage.pageSize,
			page: pageNumber ? pageNumber : defaultSegmentPage.defaultPage,
		};
		dispatch(creatorGetSegment(params));
	};

	const renderName = (name: string) => {
		return (
			<Tooltip placement="topLeft" title={name}>
				{name}
			</Tooltip>
		);
	};

	const renderDate = (date: string) => {
		return convertUtcToLocalTimeWithFormat(new Date(date).getTime());
	};

	const onPressSearch = (value: string) => {
		getCreators();
	};

	const onChangeSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(evt.target.value);
	};

	const resetSearchValue = () => {
		setSearchValue('');
	};

	const handleCurrentTab = (key: string) => {
		resetSearchValue();
		setActiveTab(key);
	};

	const onSelect = (record: any, selected: boolean, selectedRows: any, nativeEvent: any) => {
		let selectedTemp: any[] = [];

		if (selected) {
			if (isCreatorTab) {
				selectedTemp = handleSelectedRecord(selectedCreators, record);
				setSelectedCreators(selectedTemp);
			} else {
				selectedTemp = handleSelectedRecord(selectedSegments, record);
				setSelectedSegments(selectedTemp);
			}
		} else {
			if (isCreatorTab) {
				selectedTemp = handleDeselectedRecord(selectedCreators, record, isCreatorTab);
				setSelectedCreators(selectedTemp);
			} else {
				selectedTemp = handleDeselectedRecord(selectedSegments, record);
				setSelectedSegments(selectedTemp);
			}
		}
	};

	const handleSelectedRecord = (selectedList: any[], selectedRecord: any) => {
		const selectedTemp = [...selectedList];
		selectedTemp.push(selectedRecord);

		return selectedTemp;
	};

	const handleDeselectedRecord = (
		selectedList: any[],
		selectedRecord: any,
		isCreatorTab?: boolean,
	) => {
		const tempArray = selectedList.filter((item: any) => {
			if (!_.isUndefined(item)) {
				if (!_.isUndefined(isCreatorTab) && isCreatorTab) {
					return selectedRecord.creatorId !== item.creatorId;
				}

				if (_.isUndefined(isCreatorTab)) {
					return selectedRecord.id !== item.id;
				}
			}

			return null;
		});

		return tempArray;
	};

	const onSelectAll = (selected: boolean, selectedRows: any, changeRows: any) => {
		let selectedTemp: any[] = [];

		if (selected) {
			if (isCreatorTab) {
				selectedTemp = _.concat(selectedRows, selectedCreators);
				setSelectedCreators(_.unionBy(selectedTemp));
			} else {
				selectedTemp = _.concat(selectedRows, selectedSegments);
				setSelectedSegments(_.unionBy(selectedTemp));
			}
		} else {
			if (isCreatorTab) {
				const ids: number[] = changeRows.map((item: SummaryDto) => item.creatorId);

				selectedTemp = selectedCreators.filter((creator: SummaryDto) => {
					if (!_.isUndefined(creator)) {
						return !ids.includes(creator.creatorId);
					}

					return null;
				});

				setSelectedCreators(selectedTemp);
			} else {
				const ids: number[] = changeRows.map((item: SegmentsDto) => item.id);

				selectedTemp = selectedSegments.filter((segment: SegmentsDto) => {
					if (!_.isUndefined(segment)) {
						return !ids.includes(segment.id);
					}

					return null;
				});

				setSelectedSegments(selectedTemp);
			}
		}
	};

	const getSelectedRowKeys = (listType: string) => {
		let selectedTemp: any[] = [];

		if (listType === TAB.CREATORS) {
			if (selectedCreators.length) {
				selectedTemp = selectedCreators.map((creator: SummaryDto) => {
					if (!_.isUndefined(creator)) {
						return creator.creatorId;
					}

					return null;
				});
			}
		} else {
			if (selectedSegments.length) {
				selectedTemp = selectedSegments.map((segment: SegmentsDto) => {
					if (!_.isUndefined(segment)) {
						return segment.id;
					}

					return null;
				});
			}
		}

		return selectedTemp;
	};

	const rowSelection = {
		selectedRowKeys: isCreatorTab
			? getSelectedRowKeys(TAB.CREATORS)
			: getSelectedRowKeys(TAB.SEGMENTS),
		onSelect: onSelect,
		onSelectAll: onSelectAll,
	};

	const removeSelectedTag = (element: any, type: string) => {
		if (type === 'creator') {
			const tempArray = selectedCreators.filter(
				(item: any) => element.creatorId !== item.creatorId,
			);
			const selectedRowKeysTemp = creatorData.selectedRowKeys.filter(
				(creator: number) => creator !== element.creatorId,
			);
			setSelectedCreators(tempArray);
			setCreatorData({ ...creatorData, selectedRowKeys: selectedRowKeysTemp });
		} else {
			const tempArray = selectedSegments.filter((item: any) => element.id !== item.id);
			const selectedRowKeysTemp = segmentData.selectedRowKeys.filter(
				(segment: number) => segment !== element.id,
			);
			setSelectedSegments(tempArray);
			setSegmentData({ ...segmentData, selectedRowKeys: selectedRowKeysTemp });
		}
	};

	const tableLoading = {
		spinning: isCreatorTab ? fetchingProfiles : fetchingSegment,
		indicator: <Spin />,
	};

	const onChangePage = (pageNumber: number) => {
		if (isCreatorTab) {
			setCreatorData({ ...creatorData, currentPage: pageNumber });
			getCreators(pageNumber);
		} else {
			setSegmentData({ ...segmentData, currentPage: pageNumber });
			getSegments(pageNumber);
		}
	};

	const onSaveRecipients = () => {
		setIsLoading(true);
		handleSaveRecipients(selectedCreators, selectedSegments);

		setTimeout(() => {
			setIsLoading(false);
			resetSearchValue();
			handleCloseModal();
			message.success(t('email_builder.save_success'));
		}, 1500);
	};

	const handleCloseModal = () => {
		setCreatorData(creatorDataDefault);
		setSegmentData(segmentDataDefault);
		setSelectedCreators(selectedCreatorsOriginal);
		setSelectedSegments(selectedSegmentsOriginal);
		resetSearchValue();
		onClose();
	};

	return (
		<StyledModal
			width={1200}
			padding="24px 24px 0 24px"
			backgroundColor={THEME.colors.darkBlue2}
			title={t('email_builder.edit_recipients_title')}
			isOpen={isVisible}
			onClose={handleCloseModal}
			okText={t('content_management.save_changes')}
			onSubmit={onSaveRecipients}
			closable={true}>
			<LoadingWrapper isLoading={isLoading}>
				<Row gutter={[18, 18]}>
					<Col span={16}>
						<StyledTabs
							activeKey={activeTab}
							onChange={handleCurrentTab}
							height="400px"
							padding="8px 24px"
							paddingContainer="0">
							{tabs.map((tab) => (
								<TabPane tab={tab.title} key={tab.key} closable={false}>
									<StyledSearchContainer>
										{!(activeTab === 'segments') && (
											<StyledSearch
												width="200px"
												padding="8px 0"
												className="search-custom"
												placeholder="Search"
												onSearch={onPressSearch}
												onChange={onChangeSearch}
												enterButton
												value={searchValue}
											/>
										)}
									</StyledSearchContainer>

									<StyledWrapper height="calc(100% - 50px)">
										<StyledTable
											loading={tableLoading}
											id="edit-recipients-table"
											ref={tableRef}
											columns={
												isCreatorTab
													? creatorData.columns
													: segmentData.columns
											}
											dataSource={
												isCreatorTab
													? creatorData.dataSource
													: segmentData.dataSource
											}
											pagination={false}
											scroll={{
												scrollToFirstRowOnChange: true,
												y: 235,
											}}
											rowSelection={rowSelection}
											rowKey={isCreatorTab ? 'creatorId' : 'id'}
										/>
									</StyledWrapper>
								</TabPane>
							))}
						</StyledTabs>
						<StyledPagination>
							<Pagination
								total={isCreatorTab ? totalRecords : totalSegments}
								pageSize={
									isCreatorTab
										? defaultCreatorPage.pageSize - 150
										: defaultSegmentPage.pageSize
								}
								defaultPageSize={
									isCreatorTab
										? defaultCreatorPage.pageSize - 150
										: defaultSegmentPage.pageSize
								}
								current={
									isCreatorTab ? creatorData.currentPage : segmentData.currentPage
								}
								showSizeChanger={false}
								showQuickJumper
								onChange={onChangePage}
								showTotal={(total, range) =>
									`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${
										total || 0
									} ${t(`${total > 1 ? 'pagination.items' : 'pagination.item'}`)}`
								}
							/>
						</StyledPagination>
					</Col>
					<Col span={8}>
						<StyledTitle>{t('email_builder.selected_creators')}</StyledTitle>
						<StyledSelected>
							{selectedCreators.length
								? selectedCreators.map((creator: SummaryDto) => {
										if (!_.isUndefined(creator)) {
											return (
												<StyledTag
													key={creator.creatorId}
													closable
													onClose={() =>
														removeSelectedTag(creator, 'creator')
													}>
													{creator.email}
												</StyledTag>
											);
										}
										return null;
								  })
								: null}
						</StyledSelected>
						<StyledTitle>{t('email_builder.selected_segment')}</StyledTitle>
						<StyledSelected>
							{selectedSegments.length
								? selectedSegments.map((segment: SegmentsDto) => {
										if (!_.isUndefined(segment)) {
											return (
												<StyledTag
													key={segment.id}
													closable
													onClose={() =>
														removeSelectedTag(segment, 'segment')
													}>
													{segment.title}
												</StyledTag>
											);
										}
										return null;
								  })
								: null}
						</StyledSelected>
					</Col>
				</Row>
			</LoadingWrapper>
		</StyledModal>
	);
};

export default EditRecipientsModal;
