import THEME from '@constants/themes/themes';
import { OptionTypeItem } from '@models/filter';
import { StyledText } from '@styled/Common/CommonStyled';
import {
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledSpace,
} from '@styled/Content/ContentLibrary/FilterStyled';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';

type PropTypes = {
	list?: OptionTypeItem[];
	checkboxDisabled?: string | null;
	tooltipDisabled?: ReactNode;
	[other: string]: any;
	otherCheckbox?: any;
	direction?: 'vertical' | 'horizontal';
	gapSpace?: string;
};
const CheckboxGroup = (props: PropTypes) => {
	const {
		list = [],
		checkboxDisabled,
		tooltipDisabled,
		otherCheckbox = {},
		direction,
		gapSpace,
		...other
	} = props;

	const renderList = () => {
		return list.map((checkbox: OptionTypeItem) => {
			const content = (
				<StyledCheckbox
					value={checkbox.value}
					key={checkbox.value}
					disabled={checkboxDisabled === checkbox.value || !!checkbox.disabled}
					borderColorDisabled={THEME.colors.darkBlue1}
					className="checkbox_default checkbox_custom half_width checkbox_dark"
					{...otherCheckbox}>
					<StyledText title={checkbox.name} className="overflow" whiteSpace="nowrap">
						{checkbox.name}
					</StyledText>
				</StyledCheckbox>
			);
			if (tooltipDisabled && checkbox.disabled) {
				return <Tooltip title={tooltipDisabled}>{content}</Tooltip>;
			}
			return content;
		});
	};
	return (
		<StyledCheckboxGroup className="checkbox_group_full checkbox_group_custom" {...other}>
			{direction && (
				<StyledSpace gap={gapSpace} direction={direction}>
					{renderList()}
				</StyledSpace>
			)}
			{!direction && renderList()}
		</StyledCheckboxGroup>
	);
};

export default CheckboxGroup;
