import { TYPE_FORM_CUSTOM_FIELDS } from '@constants/contentLibrary/contentDetail';
import THEME from '@constants/themes/themes';
import { DataItemAdditionalType } from '@models/content/contentLibrary/summary';
import { Col } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import FormDropdownField from '../FormDropdownField';
import FormInputField from '../FormInputField';
import FormTextareaField from '../FormTextareaField';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATH } from '@constants/common';

const StyledLabel = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray1};
	margin-bottom: 12px;
`;

type PropTypes = {
	data: DataItemAdditionalType[];
	type: string;
	isMulti?: boolean;
};
const CustomFieldData = (props: PropTypes) => {
	const { data, type, isMulti } = props;
	const { pathname } = useLocation();

	const componentVal = useMemo(() => {
		return data?.map((item) => {
			const enableEdit = !!item?.key && pathname !== ROUTE_PATH.DASHBOARD;
			return (
				<Col span={type === TYPE_FORM_CUSTOM_FIELDS.input ? 12 : 24} key={Math.random()}>
					<FormInputField
						name={`${type}_label_${item.key}`}
						value={item?.name}
						enableEdit={enableEdit}
						isLabel
					/>
					{isMulti && (
						<FormDropdownField
							values={item?.values}
							name={`${type}_${item.key}`}
							enableEdit={enableEdit}
						/>
					)}
					{!isMulti && (
						<>
							{type === TYPE_FORM_CUSTOM_FIELDS.input && (
								<FormInputField
									name={`${type}_${item.key}`}
									value={item?.values[0]}
									enableEdit={enableEdit}
								/>
							)}
							{type === TYPE_FORM_CUSTOM_FIELDS.paragraph && (
								<FormTextareaField
									name={`${type}_${item.key}`}
									value={item?.values[0]}
									enableEdit={enableEdit}
								/>
							)}
						</>
					)}
				</Col>
			);
		});
	}, [JSON.stringify(data)]);

	return <>{componentVal}</>;
};

export default CustomFieldData;
