import { DownOutlined } from '@ant-design/icons';
import {
	IconColorYouTube,
	IconGoogleDrive,
	IconColorInstagram,
	IconPracticalTiktok,
	IconUploadDragger,
} from '@assets/icons';
import {
	CLIENT_NAME,
	EMAIL_OPT_IN,
	HEIGHT_DRAG_AND_DROP,
	METADATA_WIDGET,
	REDUNDANT,
	TYPE_CUSTOM_FIELD,
} from '@constants/content/widgetManager';
import { DISPLAY_TYPES } from '@constants/licensingTemplate';
import THEME from '@constants/themes/themes';
import { getUser } from '@helpers/userHelpers';
import {
	FieldItemWidgetDto,
	TermConditionItemDto,
	WidgetBuilderDetailDto,
} from '@models/content/widgetManager';
import {
	StyledIcon,
	StyledText,
	StyledWrapperContent,
	StyledWrapperTitle,
	StyledTitle as StyledTitleCommons,
} from '@styled/Common/CommonStyled';
import { StyledSelect } from '@styled/Content/WidgetManager';
import {
	StyledButtonSubmit,
	StyledCheckbox,
	StyledGoogleTranslate,
	StyledInputItem,
	StyledLink,
	StyledMessage,
	StyledRequiredCheckbox,
	StyledTextAreaItem,
	StyledTextTerm,
	StyledTitle,
	StyledUpload,
} from '@styled/Content/WidgetManager/DetailWidgetStyled';
import { StyledFormItemCustomWidget as StyledFormItem } from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { handleRedirectLink } from '@utils/common';
import { Col, Form, Row, Tooltip } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PreviewTermsModal from '../PreviewTermsModal';
import { getUrlS3 } from '@utils/funcHelper';
import { s3URL } from '@config/index';

const StyledSubText = styled.div<{ isMobile?: boolean; color?: string }>`
	color: ${({ color }) => color || THEME.colors.gray1};
	font-size: ${({ isMobile }) => (isMobile ? '1.068rem' : '1rem')};
	line-height: 1.33;
`;

const StyledSubContentText = styled.div<{ isMobile?: boolean; color?: string }>`
	color: ${({ color }) => color || THEME.colors.gray1};
	font-size: ${({ isMobile }) => (isMobile ? '0.9rem' : '0.875rem')};
	line-height: 1.33;
`;

const StyledBodyForm = styled.div``;

const StyledWrapperSession = styled.div<{ marginTop?: string; marginBottom?: string }>`
	${({ marginTop }) => marginTop && `margin-top: ${marginTop}`}
	${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`}
`;

const StyledListItem = styled.div`
	min-height: ${HEIGHT_DRAG_AND_DROP.desktop + 29}px;
`;

const StyledListTermCondition = styled.div`
	margin-top: 18px;
`;

const StyledImgButton = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 34px;
	overflow: hidden;
`;

export const StyledIconUpload = styled(({ fillIcon, ...props }) => (
	<IconUploadDragger {...props} />
))`
	& > path {
		fill: ${({ fillIcon }) => fillIcon || THEME.colors.orangeBase};
	}
`;

type BodyPropDto = {
	isMobile?: boolean;
	detailWidget: WidgetBuilderDetailDto;
	isMoreField: boolean;
	setIsMoreField: (val: boolean) => void;
	enableTerms?: boolean;
};

const BodyWidgetDefault = (props: BodyPropDto) => {
	const { t } = useTranslation();
	const user = getUser();
	const { brandName } = user;
	const { detailWidget, isMobile = false, setIsMoreField, isMoreField, enableTerms } = props;
	const { metadata } = detailWidget;
	const { header, body, colors } = metadata;
	const { title, message, dropzoneText } = header;
	const { listFields, basicFields, termsConditions = [], emailOptIn = {} } = body;
	const {
		highlight,
		bgFileDropZone,
		bgSubmit,
		colorUploadIcon = THEME?.colors.orangeBase,
		enableSharing = false,
		enableSharingGoogleDrive = undefined,
		enableSharingYouTube = undefined,
		enableSharingInstagram = undefined,
		buttonUrl,
	} = colors;
	const { name: emailOptInType = '', fieldValues = {} } = emailOptIn || {};
	const formattedFieldValues = {
		...fieldValues,
		text: `${fieldValues?.text?.replace(CLIENT_NAME, brandName) || ''} `,
	};

	const refListField = useRef<any>({});

	const renderTitle = (
		<StyledTitle fontWeight="700" isMobile={isMobile}>
			{title}
		</StyledTitle>
	);

	const [termsView, setTermsView] = useState<TermConditionItemDto | null>(null);

	const renderMessage = (
		<StyledMessage
			fontWeight="400"
			isMobile={isMobile}
			dangerouslySetInnerHTML={{ __html: message }}
		/>
	);

	const CheckboxItem = (propCheckbox: any) => {
		const { isTerms, ...other } = propCheckbox;
		const classInlineTerms =
			propCheckbox?.type === DISPLAY_TYPES.IN_LINE ? 'inline_text_terms' : '';

		return (
			<StyledCheckbox
				className={`${propCheckbox?.type === DISPLAY_TYPES.IN_LINE ? 'overflow_text' : ''}`}
				bgCheckbox={THEME.colors.white}
				notEvent={true}
				isMobile={isMobile}>
				{isTerms && enableTerms && propCheckbox?.type === DISPLAY_TYPES.IN_LINE ? (
					<Tooltip style={{ pointerEvents: 'all' }} title={propCheckbox?.text || ''}>
						<StyledTextTerm
							style={{ pointerEvents: 'all' }}
							onClick={(e: React.MouseEvent<HTMLElement>) => {
								e.stopPropagation();
								e.preventDefault();
							}}
							className={`text_term ${classInlineTerms}`}>{`${propCheckbox?.text} `}</StyledTextTerm>
					</Tooltip>
				) : (
					<>
						<StyledTextTerm
							className={`text_term ${classInlineTerms}`}>{`${propCheckbox?.text} `}</StyledTextTerm>
						<StyledLink
							style={
								propCheckbox?.type !== DISPLAY_TYPES.IN_LINE &&
								isTerms &&
								enableTerms
									? { pointerEvents: 'all' }
									: {}
							}
							onClick={(e: React.MouseEvent<HTMLElement>) => {
								if (isTerms && enableTerms) {
									if (propCheckbox?.type === DISPLAY_TYPES.POPUP) {
										e.stopPropagation();
										e.preventDefault();
										setTermsView(propCheckbox);
									}
									if (propCheckbox?.type === DISPLAY_TYPES.REDIRECT) {
										e.stopPropagation();
										e.preventDefault();
										handleRedirectLink(propCheckbox?.url || '');
									}
								}
							}}
							color={highlight}>
							{propCheckbox?.textLink}
						</StyledLink>
					</>
				)}
				{propCheckbox?.required && (
					<StyledRequiredCheckbox color={highlight}>
						{t('content.widget_manager.required.start')}
					</StyledRequiredCheckbox>
				)}
			</StyledCheckbox>
		);
	};

	const renderUpload = () => {
		const iconProps = {
			size: 34,
			margin: '0 12px',
		};

		const iconList = [
			{
				icon: <IconUploadDragger />,
				isShown: true,
				otherProps: { size: 28, fillPath: colorUploadIcon },
			},
			{
				icon: <IconGoogleDrive />,
				isShown: enableSharingGoogleDrive,
				otherProps: { size: 32, margin: '0 10px' },
			},
			{
				icon: <IconColorInstagram />,
				isShown: enableSharingInstagram,
				otherProps: { size: 32, margin: '0 10px' },
			},
			{
				icon: <IconColorYouTube />,
				isShown: enableSharingYouTube,
				otherProps: { size: 36, margin: '0 10px' },
			},
			{
				icon: <IconPracticalTiktok />,
				isShown: enableSharing,
				otherProps: { size: 32, margin: '0 10px 0 6px' },
			},
		];

		return (
			<StyledUpload bgColor={bgFileDropZone} isMobile={isMobile}>
				<StyledWrapperTitle justifyContent="center">
					{iconList.map((item) => {
						return (
							item?.isShown && (
								<StyledIcon
									key={Math.random()}
									{...iconProps}
									{...item?.otherProps}>
									{item.icon}
								</StyledIcon>
							)
						);
					})}
				</StyledWrapperTitle>
				<StyledTitleCommons
					className="unset_height overflow_more_line word_break"
					moreLine={2}
					fontWeight="400"
					textAlign={'center'}
					margin="8px 0 0 0"
					color={THEME.colors.darkBlue4}>
					{dropzoneText === undefined
						? METADATA_WIDGET.header.dropzoneText
						: dropzoneText}
				</StyledTitleCommons>
			</StyledUpload>
		);
	};

	const renderListField = () => {
		return [...basicFields, ...listFields]?.map((item: FieldItemWidgetDto) => {
			if (listFieldInput.includes(item?.type)) {
				return (
					<StyledFormItem isMobile={isMobile} key={Math.random()} name={item?.fieldName}>
						<StyledInputItem
							notEvent={true}
							isMobile={isMobile}
							placeholder={`${item?.name}${item?.required ? '*' : ''}`}
						/>
					</StyledFormItem>
				);
			}
			if (item?.type === TYPE_CUSTOM_FIELD.paragraph) {
				return (
					<StyledFormItem isMobile={isMobile} key={Math.random()} name={item?.fieldName}>
						<StyledTextAreaItem
							rows={3}
							autoSize={{ minRows: 3, maxRows: 3 }}
							notEvent={true}
							isMobile={isMobile}
							placeholder={`${item?.name}${item?.required ? '*' : ''}`}
						/>
					</StyledFormItem>
				);
			}
			if (item.type === TYPE_CUSTOM_FIELD.mailAddress) {
				const arrField = [
					{
						id: 1,
						name: item.street,
					},
					{
						id: 2,
						name: item.state,
						isDropdown: true,
					},
					{
						id: 3,
						name: item.city,
						isDropdown: true,
					},
					{
						id: 4,
						name: item.zipCode,
					},
				];
				return (
					<StyledWrapperContent key={Math.random()}>
						{arrField.map((f) => (
							<StyledFormItem isMobile={isMobile} key={f.id}>
								{f.isDropdown && (
									<StyledSelect
										width="100%"
										notEvent={true}
										isMobile={isMobile}
										open={false}
										getPopupContainer={(triggerNode: HTMLElement) =>
											triggerNode.parentNode as HTMLElement
										}
										placeholder={`${f?.name}${item?.required ? '*' : ''}`}
										virtual={false}
										className="dropdown-select_detail_widget"
										optionFilterProp="label"
										dropdownClassName={'dropdown-menu'}
									/>
								)}
								{!f.isDropdown && (
									<StyledInputItem
										notEvent={true}
										isMobile={isMobile}
										placeholder={`${f?.name}${item?.required ? '*' : ''}`}
									/>
								)}
							</StyledFormItem>
						))}
					</StyledWrapperContent>
				);
			}
			if (item?.type === TYPE_CUSTOM_FIELD.dropdown) {
				return (
					<StyledFormItem isMobile={isMobile} key={Math.random()} name={item?.fieldName}>
						<StyledSelect
							width="100%"
							notEvent={true}
							isMobile={isMobile}
							open={false}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							placeholder={`${item?.name}${item?.required ? '*' : ''}`}
							virtual={false}
							className="dropdown-select_detail_widget"
							optionFilterProp="label"
							dropdownClassName={'dropdown-menu'}
						/>
					</StyledFormItem>
				);
			}
			return null;
		});
	};

	const listFieldInput = useMemo(() => {
		return [
			TYPE_CUSTOM_FIELD.email,
			TYPE_CUSTOM_FIELD.phone,
			TYPE_CUSTOM_FIELD.instagram,
			TYPE_CUSTOM_FIELD.tiktok,
			TYPE_CUSTOM_FIELD.twitter,
			TYPE_CUSTOM_FIELD.shortAnswer,
		];
	}, []);

	useEffect(() => {
		if (!isMobile && refListField?.current) {
			const compareHeight: boolean =
				refListField?.current?.clientHeight - REDUNDANT > HEIGHT_DRAG_AND_DROP.desktop;

			if (isMoreField !== compareHeight) {
				setIsMoreField(compareHeight);
			}
		}
	}, [refListField?.current, isMobile, listFields, basicFields]);

	const renderEmailOptText = () => {
		return (
			emailOptInType === EMAIL_OPT_IN.OPT_IN_SUBMISSION.name && (
				<StyledWrapperContent margin="16px 0 0 0">
					<StyledText fontSize="10px" color={THEME.colors.gray1}>
						{formattedFieldValues?.text}
						<StyledLink color={highlight}>{formattedFieldValues?.textLink}</StyledLink>
					</StyledText>
				</StyledWrapperContent>
			)
		);
	};

	const renderButtonSubmit = (
		<>
			<StyledButtonSubmit
				backgroundImage={getUrlS3(buttonUrl || '')}
				className={buttonUrl ? 'has_url' : ''}
				backgroundColor={bgSubmit}
				isMobile={isMobile}>
				{t('content.widget_manager.button.submit')}
			</StyledButtonSubmit>
			{renderEmailOptText()}
			<StyledGoogleTranslate isMobile={isMobile}>
				<StyledIcon fillPath="rgba(0, 0, 0, 0.25)">
					<DownOutlined style={{ fontSize: '13px' }} />
				</StyledIcon>
				<div className="goog-te-gadget">
					<div>
						<StyledInputItem
							isMobile={isMobile}
							placeholderColor="rgb(103, 115, 125)"
							placeholder={t('google_translate.select_language')}
						/>
					</div>
					{t('google_translate.powered_by')}
					<span style={{ whiteSpace: 'nowrap' }}>
						<a
							className="VIpgJd-ZVi9od-l4eHX-hSRGPd"
							href="https://translate.google.com"
							rel="noreferrer"
							target="_blank">
							<img
								src="https://www.gstatic.com/images/branding/googlelogo/1x/googlelogo_color_42x16dp.png"
								alt="Google Translate"
							/>
							{t('google_translate.translate')}
						</a>
					</span>
				</div>
			</StyledGoogleTranslate>
		</>
	);

	const renderEmailOptInCheckbox = () => {
		return (
			emailOptInType === EMAIL_OPT_IN.ASK_USER.name && (
				<StyledFormItem className="form_checkbox" key={Math.random()}>
					<CheckboxItem {...formattedFieldValues} />
				</StyledFormItem>
			)
		);
	};

	const renderBodyDesktop = (
		<StyledBodyForm>
			{(title || message) && !isMoreField && (
				<StyledWrapperSession marginTop="32px">
					{title && renderTitle}
					{message && renderMessage}
				</StyledWrapperSession>
			)}

			<Row gutter={[32, 32]} style={{ marginTop: '32px' }}>
				<Col span={12}>
					{isMoreField && (
						<StyledWrapperSession marginBottom="22px">
							{title && renderTitle}
							{message && renderMessage}
						</StyledWrapperSession>
					)}
					<StyledFormItem name="fileUpload">{renderUpload()}</StyledFormItem>
					<StyledListTermCondition>
						{termsConditions?.map((item: TermConditionItemDto) => (
							<StyledFormItem
								className="form_checkbox"
								key={Math.random()}
								name={item?.fieldName}>
								<CheckboxItem {...item} isTerms />
							</StyledFormItem>
						))}
						{renderEmailOptInCheckbox()}
					</StyledListTermCondition>
				</Col>
				<Col span={12}>
					<StyledListItem ref={refListField}>{renderListField()}</StyledListItem>
					{renderButtonSubmit}
				</Col>
			</Row>
		</StyledBodyForm>
	);

	const renderBodyMobile = (
		<StyledBodyForm>
			{(title || message) && (
				<StyledWrapperSession marginTop="32px">
					{title && renderTitle}
					{message && renderMessage}
				</StyledWrapperSession>
			)}

			<StyledWrapperSession marginTop="32px">
				{renderListField()}
				<StyledFormItem isMobile={isMobile} name="fileUpload">
					{renderUpload()}
				</StyledFormItem>
				<StyledListTermCondition style={{ marginTop: '32px' }}>
					{termsConditions?.map((item: TermConditionItemDto) => (
						<StyledFormItem
							className="form_checkbox"
							isMobile={isMobile}
							key={Math.random()}
							name={item?.fieldName}>
							<CheckboxItem isTerms {...item} />
						</StyledFormItem>
					))}
					{renderEmailOptInCheckbox()}
				</StyledListTermCondition>

				{renderButtonSubmit}
			</StyledWrapperSession>
		</StyledBodyForm>
	);

	return (
		<Form style={{ fontStyle: 'normal' }} layout="vertical">
			<PreviewTermsModal
				visible={!!termsView}
				onCancel={() => setTermsView(null)}
				title={termsView?.textLink || ''}
				content={termsView?.textPopup || ''}
				textMustView={termsView?.textMustView || ''}
			/>
			{isMobile ? renderBodyMobile : renderBodyDesktop}
		</Form>
	);
};

export default BodyWidgetDefault;
