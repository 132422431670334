import { LIST_TAB_SOCIAL, TAB_SOCIAL_SETTING } from '@constants/settings/socialAggregator';
import SettingSocialAggregatorContext from '@contexts/Settings/SettingSocialAggregator';
import {
	StyledTabSetting,
	StyledWrapperTabSetting,
} from '@styled/Settings/SettingAggregatorStyled';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const TabSocial = () => {
	const { t } = useTranslation();
	const { onChangeTab, activeTab } = useContext(SettingSocialAggregatorContext);

	const listTab = useMemo(() => {
		return LIST_TAB_SOCIAL.map((item) => ({
			...item,
			isActive: item.value === activeTab,
		}));
	}, [LIST_TAB_SOCIAL, activeTab]);

	return (
		<StyledWrapperTabSetting margin="26px 0 0 0">
			{listTab.map((item) => (
				<StyledTabSetting
					onClick={() => onChangeTab(item.value)}
					className={item.isActive ? 'active' : ''}
					key={item.value}>
					{t(item.name)}
				</StyledTabSetting>
			))}
		</StyledWrapperTabSetting>
	);
};

export default TabSocial;
