import { STATUSCODE } from '@constants/APIs';
import { ROUTE_PATH } from '@constants/common';
import { removeAll } from '@helpers/storageHelpers';
import axios, { CancelToken } from 'axios';
import { getUserToken } from './userHelpers';

type MethodType = 'post' | 'get' | 'put' | 'delete' | 'patch';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { response } = error;
		const status = response ? response.status : null;

		if (status === STATUSCODE.FAILED401) {
			removeAll();

			window.location.href = ROUTE_PATH.LOGIN;
		}

		return response;
	},
);

const requestHelpers =
	(method: MethodType) =>
	async (
		apiUrl: string,
		params?: any,
		contentType?: string,
		isEncode?: boolean,
		alternativeToken?: string,
		otherParams?: any,
		cancelToken?: CancelToken,
		onUploadProgress?: any,
	) => {
		const url = new URL(apiUrl);
		const { customHeaders } = otherParams || {};

		const userToken = getUserToken();
		let headers = {
			'Content-Type': contentType || 'application/json',
			Authorization: '',
		};

		// TODO, need to update once we have offical security solution from BE
		if (userToken) {
			headers.Authorization = `Bearer ${getUserToken()}`;
		}
		if (alternativeToken) {
			headers.Authorization = `Bearer ${alternativeToken}`;
		}
		if (customHeaders) {
			headers = { ...headers, ...customHeaders };
		}

		if (method === 'get' && params && Object.keys(params).length) {
			Object.keys(params).forEach((key: string) => {
				if (!isEncode) {
					url.searchParams.append(key, params[key]);
				} else {
					url.searchParams.append(key, encodeURIComponent(params[key]));
				}
			});
		}
		const other: any = {};

		if (cancelToken) {
			other.cancelToken = cancelToken;
		}
		if (onUploadProgress && typeof onUploadProgress === 'function') {
			other.onUploadProgress = onUploadProgress;
		}

		return axiosInstance
			.request({
				method,
				url: url.toString(),
				data: params,
				headers,
				...other,
			})
			.then(async (response) => ({
				status: response?.status,
				body: response,
			}))
			.catch((e) => {
				throw new Error(e);
			});
	};

export const getRequest = requestHelpers('get');
export const postRequest = requestHelpers('post');
export const putRequest = requestHelpers('put');
export const deleteRequest = requestHelpers('delete');
export const patchRequest = requestHelpers('patch');
