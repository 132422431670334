import { MARK_AS } from '@constants/content/contentLibrary';
import Wrapper from '@cores/Wrapper';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { StyledTagAI } from '@styled/ContentLibrary/ContentDetailStyled';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ReadOnlyStatusProps = {
	content: ContentDto;
};

const ReadOnlyStatus = (props: ReadOnlyStatusProps) => {
	const { content } = props;
	const { t } = useTranslation();

	const status = useMemo(() => {
		const statusList: string[] = [];

		if (!_.isEmpty(content)) {
			const { verified, gallery, customTerms, published } = content.review;
			if (verified) {
				statusList.push(t('content_detail.rights_granted'));
			} else {
				if (customTerms) {
					statusList.push(t('content_detail.custom_terms'));
				}
			}
			if (gallery) {
				statusList.push(MARK_AS.GALLERY.label);
			}
			if (published) {
				statusList.push(MARK_AS.PUBLISHED.label);
			}
		}

		return statusList;
	}, [content]);

	return (
		<Wrapper mt={status.length ? -3 : 'unset'} mb={14}>
			{status.map((item: string) => {
				return <StyledTagAI key={item}>{item}</StyledTagAI>;
			})}
		</Wrapper>
	);
};

export default ReadOnlyStatus;
