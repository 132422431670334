import { apiURL } from '../config';
import { REACT_APP_API_URL } from '../env.config';

export const appUrl = REACT_APP_API_URL || window.location.origin;

// StatusCode
export enum STATUSCODE {
	SUCCESS = 200,
	FAILED404 = 404,
	FAILED400 = 400,
	FAILED401 = 401,
	FAILED431 = 431,
}

export const API_MEDIA = `${apiURL}/social/media/base64?url=`;

export const SAMPLE = `${apiURL}/users`;

export const LOGOUT_SUCCESS = 'Sign-out succeed';

export const USER_LOGIN = `${apiURL}/common/auth/login`;
export const USER_LOGOUT = `${apiURL}/common/auth/logout`;
export const FETCH_CLIENT_SETTINGS = `${apiURL}/common/settings/client/`;
export const FOLDER_SYSTEM = `${apiURL}/content/settings/client/folder-default`;
export const COMMON_CLIENT = `${apiURL}/common/client`;
export const ACCOUNT_INFO_API = `${apiURL}/common/account/me`;

export const CREATOR_API_URL = `${apiURL}/creator`;
export const CREATOR_GET_CREATOR_LIST_URL = `${CREATOR_API_URL}/get`;
export const CREATOR_FIND_PROFILES_URL = `${CREATOR_API_URL}/find-profiles`;
export const CREATOR_SEGMENT = `${CREATOR_API_URL}/segment`;
export const CREATOR_SEGMENT_LIST = `${CREATOR_SEGMENT}/segments`;
export const CREATOR_SEGMENT_SIMPLE = `${CREATOR_SEGMENT}/simple`;
export const CREATOR_SEGMENT_FIND_PROFILES = `${CREATOR_SEGMENT}/find-profiles`;
export const CREATOR_EXPORT_PROFILES_URL = `${CREATOR_API_URL}/export-creator`;
export const CREATOR_IMPORT_PROFILES = `${CREATOR_API_URL}/import-creator`;
export const CREATOR_BLOCK_PROFILES = `${CREATOR_API_URL}/status-block`;
export const CREATOR_CHANGE_STATUS_PROFILES = `${CREATOR_API_URL}/status`;
export const CREATOR_DASHBOARD_STATISTIC_URL = `${CREATOR_API_URL}/dashboard/statistic`;
export const CREATOR_DASHBOARD_LEADERBOARD_URL = `${CREATOR_API_URL}/dashboard/creators`;
export const CREATOR_DASHBOARD_LOCATION_URL = `${CREATOR_API_URL}/dashboard/location`;
export const CREATOR_STATUS_RECENTLY_ADDED_URL = `${CREATOR_API_URL}/status-recently-added`;
export const CREATOR_LOCATION = `${CREATOR_API_URL}/location`;
export const CREATOR_COMPLETED_ACTIONS = `${apiURL}/content/creator`;
export const INVALID_CREATOR_SOCIAL_USERNAME = `${apiURL}/social/user-profile/valid`;
export const CREATOR_TEMPLATE = `${CREATOR_API_URL}/download-creator-template`;

export const LIST_ACCOUNT = `${apiURL}/common/client/`;
export const SWITCH_ACCOUNT = `${apiURL}/common/client/switch`;
export const ACTIVE_ACCOUNT = `${apiURL}/common/auth/active`;
export const CREATE_ACCOUNT = `${apiURL}/common/account/create`;
export const UPDATE_PROFILE_USER = `${apiURL}/common/account/update`;
export const RENEW_INVITE_ACCOUNT = `${apiURL}/common/auth/renew-invite`;
export const CHANGE_PASSWORD_API = `${apiURL}/common/account/change-pwd`;
export const REGISTER_ACCOUNT = `${apiURL}/common/auth/register`;
export const FORGOT_PASSWORD = `${apiURL}/common/password/forgot`;
export const API_UPDATE_SETTING = `${apiURL}/common/account/update/settings`;

export const API_TREMENDOUS_INFO = `${apiURL}/common/integration/tremendous/info`;
export const API_TREMENDOUS_SETTING = `${apiURL}/common/integration/tremendous/settings`;
export const API_TREMENDOUS_ORDER = `${apiURL}/reward/tremendous/order`;

// content export
export const CONTENT_EXPORT = `${apiURL}/content/export-report-stats`;

// Content
export const CONTENT_API_URL = `${apiURL}/content`;
export const CONTENT_MEDIA_API = `${CONTENT_API_URL}/media`;
export const FETCH_CONTENT = `${CONTENT_API_URL}/get`;
export const FETCH_CONTENT_DATA = `${CONTENT_API_URL}/get-data`;
export const REJECT_CONTENT = `${CONTENT_API_URL}/reject`;
export const MOVE_CONTENT = `${CONTENT_API_URL}/move`;
export const DELETE_CONTENT = `${CONTENT_API_URL}`;
export const REVIEW_CONTENT = `${CONTENT_API_URL}`;
export const MULTIPLE_REVIEW_CONTENT = `${CONTENT_API_URL}/bulk-review`;
export const FILTER_CONTENT = `${CONTENT_API_URL}/filter`;
export const CONTENT_VERIFICATION_SCORE = `${CONTENT_API_URL}/public/image-verification-score`;
export const CONTENT_DASHBOARD_STATISTIC_URL = `${CONTENT_API_URL}/dashboard/statistic`;
export const CONTENT_DASHBOARD_GRAPH_URL = `${CONTENT_API_URL}/dashboard/graph`;
export const CONTENT_DASHBOARD_RECENT_SUBMISSIONS_URL = `${CONTENT_API_URL}/dashboard/content`;
export const CONTENT_STATUS_RECENTLY_ADDED = `${CONTENT_API_URL}/status-recently-added`;
export const PUBLISH_LOG = `${CONTENT_API_URL}/publish-log`;
export const MARK_FAVORITE_CONTENT = `${CONTENT_API_URL}/favorite`;
export const API_RATIO_RESOLUTION = `${CONTENT_API_URL}/aspect-ratio-and-resolution`;
export const CONTACTED_CONTENT = `${CONTENT_API_URL}/status-contacted`;
export const API_CONTENT_DASHBOARD_LOCATION = `${CONTENT_API_URL}/dashboard/location`;
export const API_CONTENT_EMOJI = `${CONTENT_API_URL}/emoji`;
export const API_TOTAL_CONTENT = `${CONTENT_API_URL}/total-content`;
export const API_VERSION_CONTENT = `${CONTENT_API_URL}/media-version`;
export const API_ROTATE_IMAGE = `${CONTENT_API_URL}/rotate-image`;
export const API_SAVE_IMG_ORIENTATION = `${CONTENT_API_URL}/save-image-orientation`;
export const FETCH_SOCIAL_POST = `${apiURL}/social/posts/search`;
export const URL_CONTENT_EXTERNAL_API = `${CONTENT_API_URL}/public/video/gif`;
export const API_DEVICE_MAKE = `${CONTENT_API_URL}/device-makers`;
export const API_DEVICE_MODEL = `${CONTENT_API_URL}/device-models`;
export const API_SHOT_LOCATION = `${CONTENT_API_URL}/shot-countries`;

// Rewards
export const REWARD_API_URL = `${apiURL}/reward`;
export const REWARD_GENERATE_CODE = `${REWARD_API_URL}/code/generate`;

// Social Content
export const SOCIAL_SEARCH_API_URL = `${apiURL}/social`;
export const FETCH_SOCIAL_CONTENT = `${SOCIAL_SEARCH_API_URL}/search`;
export const IMPORT_SOCIAL_CONTENT = `${SOCIAL_SEARCH_API_URL}/import-media/`;
export const BLOCK_SOCIAL_CONTENT = `${SOCIAL_SEARCH_API_URL}/block-media/`;
export const BLOCK_SOCIAL_USERNAME = `${SOCIAL_SEARCH_API_URL}/block-username/`;
export const GET_SOCIAL_SEARCH_FILTER = `${SOCIAL_SEARCH_API_URL}/filter`;
export const SAVE_SOCIAL_SEARCH_FILTER = `${SOCIAL_SEARCH_API_URL}/filter`;
export const DELETE_SOCIAL_SEARCH_FILTER = `${SOCIAL_SEARCH_API_URL}/filter`;
export const KEY_WORD = `${apiURL}/common/settings/client/keyword`;
export const PREVIEW_TIKTOK_URL = `${SOCIAL_SEARCH_API_URL}/callback/media/tiktok/dynamic-thumbnail`;
export const SOCIAL_TIKTOK_CONTENT = `${SOCIAL_SEARCH_API_URL}/callback/media/tiktok/content-url`;
export const SOCIAL_YOUTUBE_CONTENT = `${SOCIAL_SEARCH_API_URL}/callback/media/youtube/content-url`;
export const SOCIAL_INSTAGRAM_VIDEO = `${SOCIAL_SEARCH_API_URL}/callback/media/instagram/video-data`;
export const SOCIAL_IMAGE_DATA = `${SOCIAL_SEARCH_API_URL}/callback/media/image-data`;
export const SOCIAL_REDIRECTED_URL = `${SOCIAL_SEARCH_API_URL}/callback/media/redirected-url`;

// Save Facebook token
export const SAVE_FACEBOOK_TOKEN = `${apiURL}/common/client/facebook-token`;

// Messages/Email
export const MESSAGE = `${apiURL}/mail/email`;
export const GET_DYNAMIC_FIELDS = `${apiURL}/mail/template/dynamic-field`;
export const GET_TEMPLATES = `${apiURL}/mail/template/active-template`;
export const GET_EMAIL_EVENT = `${apiURL}/mail/email/email-event`;
export const GET_EMAIL_EVENT_DETAIL = `${apiURL}/mail/email/email-event-detail`;
export const DELETE_EMAIL = `${apiURL}/mail/email/delete`;
export const GET_EVENT_CREATOR = `${apiURL}/mail/email/event-creator`;
export const GET_EMAIL_TEMPLATES = `${apiURL}/mail/template`;
export const SEND_TEST_MAIL = `${apiURL}/mail/template/send`;
export const UPDATE_EMAIL_TEMPLATES = `${apiURL}/mail/template?id`;
export const CREATE_EMAIL_TEMPLATES = `${apiURL}/mail/template`;
export const RESTORE_EMAIL_TEMPLATE = (id: number, isDefault: boolean = false) =>
	`${apiURL}/mail/template/restore?id=${id}&default=${isDefault}`;
export const SAVE_AS_TEMPLATE = `${apiURL}/mail/template`;
export const DIRECT_MESSAGE = `${apiURL}/mail/message/direct`;
export const GET_DIRECT_MESSAGE = `${apiURL}/mail/message/direct/replace`;
export const MAIL_HISTORY_CREATOR = `${apiURL}/mail/history/creator`;
export const CREATOR_LIST_LITE = `${apiURL}/creator/find-lite-profiles`;
export const MANUAL_UPLOAD_CONTENT = `${apiURL}/content/manual-import/upload-content`;
export const CREATE_CREATOR = `${apiURL}/creator/create`;
export const UPDATE_APPROVAL_STATUS = `${apiURL}/creator/status-approval`;

// Setting:
// 1) Setting - Social Search
export const CLIENT_SETTINGS_HASH_TAGS = `${apiURL}/common/settings/client/hashtags`;
export const CLIENT_SETTINGS_MENTION_USERNAMES = (type: string) =>
	`${apiURL}/common/settings/client/${type}/usernames`;
export const FETCH_SOCIAL_BLOCKED_USERNAMES = `${apiURL}/common/settings/client/exclude-usernames`;
export const FETCH_BAD_WORDS = `${apiURL}/common/settings/client/exclude-terms`;
export const SOCIAL_FEED_API = `${apiURL}/social/feed`;
export const SOCIAL_FEED_IMPORT_MEDIA = `${SOCIAL_FEED_API}/import-media`;
export const SOCIAL_FEED_BLOCK_CONTENT = `${SOCIAL_FEED_API}/block-media`;
export const SOCIAL_FEED_STATS = `${SOCIAL_FEED_API}/stats`;
export const SOCIAL_FEED_BLOCK_USERNAME = `${SOCIAL_FEED_API}/block-username`;
export const IMPORT_USERNAME_SOCIAL_FEED_API = `${SOCIAL_FEED_API}/import-usernames`;

// 2) Setting - Content
export const GET_CONTENT_SETTINGS = `${apiURL}/content/settings/client/`;
export const UPDATE_CONTENT_VALUES = `${apiURL}/content/settings/client/content-value`;
export const CREATE_FOLDER = `${apiURL}/content/settings/client/folder`;
export const FOLDER_SETTINGS = `${apiURL}/content/settings/client/folder`;
export const UPDATE_FLAGS = `${apiURL}/common/settings/client/flag`;
export const UPDATE_LABELS = `${apiURL}/common/settings/client/label`;
export const API_REPLACE_BLOCK_USERNAME = `${apiURL}/common/settings/client/replace/exclude-usernames`;
export const API_REPLACE_BLOCK_TERMS = `${apiURL}/common/settings/client/replace/exclude-terms`;
export const API_CHECK_TOP_RATE = `${GET_CONTENT_SETTINGS}check-top-rated-folder`;

// 3) Setting - Publish
export const GET_CHANELS_SETTINGS = `${apiURL}/common/settings/client/channel`;
export const GET_CALENDAR_SETTINGS = `${apiURL}/common/settings/client/calendar`;

// 4) Setting - Campaign
export const GET_CAMPAIGN_LIST_SETTING = `${apiURL}/campaign`;
export const GET_CAMPAIGN_DETAIL = `${GET_CAMPAIGN_LIST_SETTING}/details`;
export const GET_LIST_CONTENT_CAMPAIGN = `${apiURL}/campaign/simple`;
export const GET_LIST_WIDGET_CAMPAIGN = `${apiURL}/campaign/widget-campaign`;
export const SUGGEST_IMG_CAMPAIGN = `${apiURL}/content/suggest-image-for-campaign`;
export const UPLOAD_IMGS_CAMPAIGN = `${GET_CAMPAIGN_LIST_SETTING}/upload-images`;
export const GET_RECOMMENDATION_CAMPAIGN = `${GET_CAMPAIGN_LIST_SETTING}/recommendations`;
export const CAMPAIGN_LANDING_PAGE = `${GET_CAMPAIGN_LIST_SETTING}/community`;

// Landing Page Manager
export const LANDING_PAGE = `${apiURL}/campaign/community`;

// 4) Setting - Account and Users
export const ACCOUNT_USERS = `${apiURL}/common/account/`;
export const REVOKE_ACCOUNT_USERS = `${apiURL}/common/account/revoke`;

// Content widget manager
export const WIDGET_BUILDER_GET = `${apiURL}/widget/builder/get`;
export const WIDGET_BUILDER = `${apiURL}/widget/builder`;
export const WIDGET_BUILDER_DETAIL = `${apiURL}/widget/builder/detail`;
export const WIDGET_BUILDER_UPDATE_STATUS_RECENTLY_UPDATED = `${apiURL}/widget/builder/status-recently-updated`;
export const WIDGET_CUSTOM_FIELD = `${apiURL}/widget/builder/custom-fields`;
export const WIDGET_DEFAULT_API = `${WIDGET_BUILDER}/terms-template/widget-default`;
export const WIDGET_FONT_API = `${WIDGET_BUILDER}/fonts`;
export const RESUME_LIVE_DRAFT = `${WIDGET_BUILDER}/resume-live-draft`;
export const CLONE_LIVE_WIDGET = `${WIDGET_BUILDER}/clone-live`;

// Publish Gallery
export const FOLDER_GALLERY = `${apiURL}/content/settings/client/folder/gallery`;
export const FOLDER_CONTENT_GALLERY = `${apiURL}/content/gallery`;
export const ACCOUNT_USER_PERMISSION = `${apiURL}/common/account/permission`;
export const GALLERY_REVIEW = `${apiURL}/content/gallery-review`;
export const PRODUCT_DEPICTED = `${CONTENT_API_URL}/product-depicted`;
export const ASSIGN_ALBUM_GALLERY = `${CONTENT_API_URL}/gallery/assign-album-to-gallery`;
export const UN_ASSIGN_ALBUM_GALLERY = `${CONTENT_API_URL}/gallery/unassign-album-to-gallery`;
export const API_REMOVE_ALL_CONTENT_GALLERY = `${CONTENT_API_URL}/gallery/remove-all-content`;
export const CUSTOM_PROPERTIES = `${apiURL}/content/custom-properties/name`;

// Google Drive
export const GOOGLE_API = {
	UPLOAD_FILES: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
	SCOPE: 'https://www.googleapis.com/auth/drive.file',
	AUTH_TOKEN: 'https://oauth2.googleapis.com/token',
	REVOKE_TOKEN: ' https://oauth2.googleapis.com/revoke?token',
};

// Albums Manager
export const FOLDER_ALBUM = `${apiURL}/content/settings/client/folder/album`;
export const FOLDER_ALBUM_SUGGEST = `${FOLDER_ALBUM}/suggestion`;
export const FOLDER_ALBUM_SUGGEST_STATS = `${FOLDER_ALBUM_SUGGEST}/stats`;
export const EXPORT_ALBUM_CONTENT_API = `${apiURL}/content/export-contents`;
export const ALBUM_LIST_LITE_API = `${apiURL}/content/hub/album/lite`;

// Notification
export const NOTIFICATION_API_URL = `${apiURL}/notification`;
export const GET_ALL_MESSAGE_NOTIFICAION = `${NOTIFICATION_API_URL}/all`;
export const UPDATE_STATUS_NOTIFICAION = `${NOTIFICATION_API_URL}/update-status`;
export const CHECK_LIMIT_NOTIFICATION = `${apiURL}/common/admin/client-limit-notification`;
export const PUBLISH_TERMS_CONDITION = `${apiURL}/content/hub/download-terms-and-conditions`;
export const TASK_NOTIFICATION = `${apiURL}/tasks/notification`;
export const MARK_TASK_NOTIFICATION_AS_READ = `${TASK_NOTIFICATION}/mark-all-as-read`;
export const PREVIEW_TERMS_CONDITION = `${apiURL}/notification/public/terms-manager/preview-terms-and-conditions`;
export const GENERATE_PDF_TERMS_CONDITION = `${apiURL}/terms-manager/terms/generate-pdf`;

// location
export const CONTENT_LOCATION = `${apiURL}/content/upload-location`;

// custom fields
export const CONTENT_CUSTOM_FIELD_API = `${apiURL}/content/custom-fields`;

// tags
export const CONTENT_TAGS = `${CONTENT_API_URL}/tags`;

// terms
export const TERMS_CONTENT = `${CONTENT_API_URL}/terms`;

// Guest
export const GUEST = `${COMMON_CLIENT}/guests`;
export const SEND_GUEST_PASS_CODE = `${COMMON_CLIENT}/send-guests-passcode`;

// Contrast checker
export const CONTRAST_CHECKER_API = 'https://webaim.org/resources/contrastchecker/';

// Logo detection
export const MEDIA_DETECTION = `${CONTENT_API_URL}/detection`;
export const DETECTION_SERVICE_LIST = `${apiURL}/common/detection-services`;
export const ESTIMATE_COST = `${MEDIA_DETECTION}/cost-estimation`;
export const DETECTION_LABEL_LIST = `${MEDIA_DETECTION}/labels-filter`;

// MFA
export const VERIFY_PASSWORD = `${apiURL}/common/account/invalidate-password`;
export const MFA = `${apiURL}/common/account/mfa`;
export const PAIR_AUTHENTICATOR_APP = `${MFA}/qrcode`;
export const CONFIRM_MFA_CODE = `${MFA}/verify`;
export const LOGIN_WITH_MFA = `${apiURL}/common/auth/mfa/verify`;
export const DISABLE_MFA = `${MFA}/disable`;

// Ambassador
export const AMBASSADOR_POSTS_API = `${apiURL}/social/ambassador/posts/search`;
export const AMBASSADOR_CREATOR = `${apiURL}/creator/ambassador`;
export const AMBASSADOR_LEADER_BOARD_API = `${AMBASSADOR_CREATOR}/leaderboard`;
export const AMBASSADOR_METRIC_API = `${AMBASSADOR_CREATOR}/metric-cards`;
export const AMBASSADOR_GRAPH_POSTS_API = `${AMBASSADOR_CREATOR}/graph/social-posts`;
export const AMBASSADOR_GRAPH_VOTES_API = `${AMBASSADOR_CREATOR}/graph/likes-comments`;
export const AMBASSADOR_SOCIAL = `${apiURL}/social/ambassador/`;
export const AMBASSADOR_REPORT_API = `${AMBASSADOR_CREATOR}/generate-report`;
export const AMBASSADOR_REFRESH_METRICS_API = `${AMBASSADOR_CREATOR}/refresh-metrics`;
export const AMBASSADOR_CREATOR_REFRESH_METRICS_API = (creatorId: string | number) =>
	`${AMBASSADOR_CREATOR}/${creatorId}/refresh-metrics`;
export const RECENT_SOCIAL_POST_API = `${apiURL}/social/ambassador/recent-posts`;

// Task manager
export const TASK = `${apiURL}/tasks`;
export const TASK_LIST = `${TASK}/get`;
export const ACCOUNT_LIST_LITE = `${apiURL}/common/account/lite`;
export const MARK_TASK_AS_DONE = `${TASK}/mark-as-done`;

// Guest portal
export const GUEST_PORTAL = `${apiURL}/common/guest/portal`;

// Content Licensing
export const CREATE_TEMPLATE_API = `${apiURL}/terms-manager/terms-template`;
export const DETAIL_TEMPLATE_API = `${CREATE_TEMPLATE_API}/detail`;
export const LIST_TEMPLATE_API = `${CREATE_TEMPLATE_API}/get`;
export const TEMPLATE_HISTORY_API = `${CREATE_TEMPLATE_API}/history`;
export const WIDGET_IN_TEMPLATE_API = `${apiURL}/widget/builder/terms-template`;
export const LIST_WIDGET_LIVE_API = `${WIDGET_IN_TEMPLATE_API}/widget`;
export const ENTRIBE_STANDARD_TERMS_API = `${apiURL}/terms-manager/entribe-standard-terms`;
export const WIDGET_TEMPLATE_ASSIGN_API = `${CREATE_TEMPLATE_API}/widgets`;
export const CHANGE_TEMPLATE_STATUS_API = `${CREATE_TEMPLATE_API}/change-status`;
export const DUPLICATE_TEMPLATE_API = `${CREATE_TEMPLATE_API}/duplicate`;
export const CHANGE_DEFAULT_TEMPLATE_API = `${CREATE_TEMPLATE_API}/set-default`;
export const TEMPLATE_WIDGET_API = `${CREATE_TEMPLATE_API}/lite`;
export const TEMPLATE_DEFAULT_API = `${CREATE_TEMPLATE_API}/default`;
