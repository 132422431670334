import { IconError } from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import THEME from '@constants/themes/themes';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { MailMessageCreatorType } from '@models/creator/summary';
import { StyledIcon, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledDrawer, StyledWrapperMailHistoryInfo } from '@styled/Creators/SummaryStyled';
import { Tooltip } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

type PropTypes = {
	visible: boolean;
	mailInfo: MailMessageCreatorType;
	[other: string]: any;
};

const MailHistoryDrawer = (props: PropTypes) => {
	const { mailInfo, ...other } = props;
	const { error, body, success, createAt } = mailInfo;
	const { t } = useTranslation();

	const renderIcon = () => {
		if (!success) {
			const icon = (
				<StyledIcon cursor="unset" size={20} margin="0 5px 0 0">
					<IconError />
				</StyledIcon>
			);
			if (error) {
				return <Tooltip title={error}>{icon}</Tooltip>;
			}
			return icon;
		}

		return '';
	};

	return (
		<StyledDrawer
			{...other}
			getContainer={false}
			className="hidden_border pt_16"
			title={
				<StyledWrapperContent
					display="inline-flex"
					alignItems="center"
					fontSize="12px"
					color={THEME.colors.white}>
					{renderIcon()}
					<Trans
						t={t}
						values={{
							val: convertUtcToLocalTimeWithFormat(createAt, TYPE_FORMAT_DATE.TIME),
						}}
						i18nKey={'creator_profile.sent_on'}
						components={{
							elm: <b style={{ marginLeft: '4px' }} />,
						}}
					/>
				</StyledWrapperContent>
			}>
			<StyledWrapperMailHistoryInfo dangerouslySetInnerHTML={{ __html: body }} />
		</StyledDrawer>
	);
};

export default MailHistoryDrawer;
