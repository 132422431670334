import { IconClose } from '@assets/icons';
import FormDetailFeed from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed';
import { PARAMS_DEFAULT } from '@constants/common';
import { NAME_FILTER_CONTENT, STATUS_FILTER } from '@constants/filter/filterContent';
import {
	ACTION_TYPE_ON_FILTER,
	CREATOR_TYPE_SETTING,
	DEFAULT_FORM_SETTING,
	NAME_SETTING_FEED,
	STATUS_SETTING,
} from '@constants/settings/socialAggregator';
import { DEFAULT_MEDIA, OPERATOR } from '@constants/social_search';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import FeedsManagerContext from '@contexts/SocialSearch/Feeds';
import { ConfirmModal } from '@cores/Modal';
import { RefResetFilterContent } from '@models/filter/ref';
import { SocialSearchDto } from '@models/settings/socialSearch';
import {
	FeedDto,
	FieldFormSettingFeed,
} from '@models/settings/socialSearch/socialAggregator/summary';
import { SocialContentStoreDto } from '@models/socialsearch/store';
import { updateFeedEnded, updateFeedRequest } from '@stores/actions';
import { resetSocialSearchField } from '@stores/socialsearch/socialsearch.actions';
import { StyledButton, StyledIcon, StyledText, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledFilterContent,
	StyledFooterFilter,
	StyledForm,
	StyledTabs,
	StyledWrapperFilter,
} from '@styled/Filter/FilterContentStyled';
import { initialFormValues, processRequestValues } from '@utils/socialSearch';
import { message } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
	Ref,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const { TabPane } = StyledTabs;

const TYPE_TAB = {
	default: 'default',
	selectFilter: 'selectFilter',
};

const MODAL_TYPES = {
	UPDATE_FEED_SETTINGS: 'UPDATE_FEED_SETTINGS',
};

type PropsFilterContent = {
	isHidden: boolean;
	closeFilters: () => void;
	handleFilterContent: (val: FieldFormSettingFeed) => void;
};

const FilterFeed = forwardRef((props: PropsFilterContent, ref: Ref<RefResetFilterContent>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { detailFeed }: SocialContentStoreDto = useSelector((state: any) => state.socialContent);
	const {
		setActionTypeOnFilter,
		actionTypeOnFilter,
		setTypeCollapse,
		setParamSocial,
		paramSocial,
	} = useContext(FeedsManagerContext);
	const { state } = useLocation<any>();
	const history = useHistory();
	const { updateFeedStatus, error }: SocialSearchDto = useSelector(
		(state: any) => state.settings.socialSearch,
	);
	const { isHidden, closeFilters, handleFilterContent } = props;

	const [activeTab, setActiveTab] = useState<string>(TYPE_TAB.default);
	const [listSourceType, setListSourceType] = useState([
		...DEFAULT_FORM_SETTING[NAME_SETTING_FEED.SOURCES],
	]);
	const [listCaptionType, setListCaptionType] = useState([
		...DEFAULT_FORM_SETTING[NAME_SETTING_FEED.CAPTION_SENTIMENT],
	]);
	const [isActive, setIsActive] = useState<boolean>(true);
	const [typeIncluded, setTypeIncluded] = useState<string>(OPERATOR.OR);
	const [disabledCheck, setDisabledCheck] = useState<string[]>([]);
	const [typeCreator, setTypeCreator] = useState<string>(CREATOR_TYPE_SETTING.ALL);
	const [confirmationModalType, setConfirmationModalType] = useState<string>('');

	const [form] = StyledForm.useForm();
	const feedDetailFormRef = useRef<any>();

	useEffect(() => {
		if (detailFeed) {
			onInputValueFormDefault(detailFeed);
		}
	}, [detailFeed]);

	useEffect(() => {
		if (updateFeedStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.updated_feed'),
				}),
			);
			if (feedDetailFormRef && feedDetailFormRef.current) {
				feedDetailFormRef.current.scrollTopForm();
			}

			setTypeCollapse('');
		}
		if (error && updateFeedStatus === FAILED) {
			message.error(error);
		}
		if (updateFeedStatus === FAILED || updateFeedStatus === SUCCEEDED) {
			setConfirmationModalType('');
			dispatch(updateFeedEnded());
		}
	}, [updateFeedStatus, error]);

	const onInputValueFormDefault = (val: FeedDto | null) => {
		setTypeIncluded(val?.queryOperator || OPERATOR.OR);
		let sourceTypes: string[] = DEFAULT_FORM_SETTING.sources;
		let captionTypes: string[] = DEFAULT_FORM_SETTING.captionSentiments;

		if (val) {
			setIsActive(val?.status === STATUS_SETTING.LIVE);
			const initialValues = initialFormValues(val, form);
			sourceTypes = initialValues.sourceTypes;
			captionTypes = initialValues.captionTypes;
			setDisabledCheck(initialValues.disabledField);
			setTypeCreator(initialValues.usernameConjunctionType);
		} else {
			setIsActive(true);
			form.resetFields();
			setDisabledCheck([]);
			setTypeCreator(CREATOR_TYPE_SETTING.ALL);
		}
		setListCaptionType(captionTypes);
		setListSourceType(sourceTypes);
	};

	const arrTab = [
		{
			title: t('social_search.filter.title'),
			value: TYPE_TAB.default,
		},
	];

	const formatDataRequestFilter = (values: FieldFormSettingFeed) => {
		const { postDate = [], ...otherRequest } = values;
		const dataRequest: any = {};

		if (postDate?.length > 0) {
			dataRequest.fromDate = moment.utc(postDate[0]).startOf('day').valueOf();
			dataRequest.toDate = moment.utc(postDate[1]).endOf('day').valueOf();
		} else {
			if (paramSocial?.fromDate || paramSocial?.toDate) {
				delete paramSocial.fromDate;
				delete paramSocial.toDate;
			}
		}

		return { ...otherRequest, ...dataRequest };
	};

	useImperativeHandle(ref, () => ({
		clearFilterUsingRef: handleResetForm,
		handleResetValues: onInputValueFormDefault,
	}));

	useEffect(() => {
		if (state) {
			const otherFieldForm: any = {};
			if (state?.idCampaign) {
				otherFieldForm[NAME_FILTER_CONTENT.CAMPAIGNS] = [state?.idCampaign?.toString()];
			}

			if (state?.needReview) {
				otherFieldForm[NAME_FILTER_CONTENT.STATUS] = STATUS_FILTER.NEEDS_REVIEW;
			}

			if (!_.isEmpty(otherFieldForm)) {
				form.setFieldsValue(otherFieldForm);
				form.submit();
			}
			history.replace({ state: null });
		}
	}, [state]);

	const renderTabName = (item: { title: string }) => {
		const { title } = item;
		return (
			<StyledTitle>
				<StyledText fontSize="18px">{title}</StyledText>
			</StyledTitle>
		);
	};

	const handleFinishForm = (values: FieldFormSettingFeed) => {
		const dataRequest = processRequestValues(values, detailFeed, isActive, typeIncluded);
		dispatch(
			resetSocialSearchField({
				socialContent: { ...DEFAULT_MEDIA },
			}),
		);
		switch (actionTypeOnFilter) {
			case ACTION_TYPE_ON_FILTER.FILTER_FEED: {
				handleFilterContent(formatDataRequestFilter(dataRequest));
				break;
			}

			case ACTION_TYPE_ON_FILTER.UPDATE_FEED: {
				if (detailFeed) {
					const updatedValues = { ...detailFeed, ...dataRequest };
					dispatch(updateFeedRequest({ ...updatedValues }));
					setParamSocial({
						...updatedValues,
						maxRecords: PARAMS_DEFAULT.maxRecords,
						page: PARAMS_DEFAULT.page,
					});
				}
				break;
			}

			default: {
				break;
			}
		}
	};

	const handleResetForm = () => {
		form.resetFields();
		closeFilters();
	};

	const handleApplyFilter = () => {
		setActionTypeOnFilter(ACTION_TYPE_ON_FILTER.FILTER_FEED);
		setTimeout(() => {
			form.submit();
			closeFilters();
		}, 0);
	};

	const handleUpdateFeedSettings = () => {
		setActionTypeOnFilter(ACTION_TYPE_ON_FILTER.UPDATE_FEED);
		setTimeout(() => {
			form.submit();
		}, 0);
	};

	const renderConfirmationModal = () => {
		let content: string = '';
		let onSubmit: () => void;
		const okText = t('button.update');
		const isOpen: boolean = confirmationModalType === MODAL_TYPES.UPDATE_FEED_SETTINGS;

		if (confirmationModalType === MODAL_TYPES.UPDATE_FEED_SETTINGS) {
			content = t('social_search.confirm.update_feed_settings');
			onSubmit = handleUpdateFeedSettings;
		}

		return (
			<ConfirmModal
				confirmLoading={updateFeedStatus === IN_PROGRESS}
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => {
					setConfirmationModalType('');
				}}>
				{content}
			</ConfirmModal>
		);
	};

	const formDetailFeedProps = {
		detailFeed,
		handleFinishForm,
		hasTerms: true,
		typeIncluded,
		setTypeIncluded,
		listCaptionType,
		setListCaptionType,
		listSourceType,
		setListSourceType,
		disabledCheck,
		setDisabledCheck,
		setTypeCreator,
		typeCreator,
	};

	return (
		<StyledFilterContent height="100%" className={isHidden ? 'hidden' : ''}>
			<StyledIcon
				onClick={closeFilters}
				style={{ position: 'absolute', top: '14px', right: '14px', zIndex: 99 }}>
				<IconClose />
			</StyledIcon>
			<StyledTabs
				activeKey={activeTab}
				className="custom-tabs_filter"
				onTabClick={(tab: string) => setActiveTab(tab)}>
				{arrTab.map((item: any) => (
					<TabPane tab={renderTabName(item)} key={item.value} closable={false}>
						<StyledWrapperFilter className="custom_scroll_bar feed_filter">
							<FormDetailFeed
								ref={feedDetailFormRef}
								form={form}
								isFilterComponent={true}
								formDetailFeedProps={{
									...formDetailFeedProps,
								}}
							/>
						</StyledWrapperFilter>
					</TabPane>
				))}
			</StyledTabs>
			<StyledFooterFilter>
				<StyledButton
					width="55%"
					onClick={() => {
						setConfirmationModalType(MODAL_TYPES.UPDATE_FEED_SETTINGS);
					}}
					type="default">
					{t('button.update_feed_settings')}
				</StyledButton>
				<StyledButton width="40%" onClick={handleApplyFilter} type="primary">
					{t('button.apply_filter')}
				</StyledButton>
			</StyledFooterFilter>
			{renderConfirmationModal()}
		</StyledFilterContent>
	);
});

export default FilterFeed;
