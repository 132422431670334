import { IconKeyboardArrowRight, IconQuestionCircle } from '@assets/icons';
import {
	ALL_COUNTRY,
	KEY_FIELD_FORM_TERM,
	MARKETING_CHANNELS,
} from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import { OptionTypeItem } from '@models/filter';
import {
	StyledIcon,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledSelect } from '@styled/Content/ContentLibrary/FilterStyled';
import {
	StyledFormItem,
	StyledInputCustomField,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { StyledCollapse } from '@styled/ContentLibrary/ContentDetailStyled';
import { notFoundContent } from '@utils/renderComponent';
import { Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countries } from 'countries-list';
import { SelectOptionDto } from '@models/common/summary';
import { TermConditionItemDto } from '@models/content/widgetManager';
import { getUser } from '@helpers/userHelpers';
import { ROLE } from '@constants/settings';

const { Panel } = StyledCollapse;

type PropTypes = {
	keyIndex: number | string;
	getValueField: (val: string) => any;
	setFieldsValue: (val: any) => void;
	term: TermConditionItemDto;
};
const OtherDetailTerm = (props: PropTypes) => {
	const { keyIndex, getValueField, setFieldsValue, term } = props;
	const { t } = useTranslation();

	const user = getUser();
	const disabled = user.role !== ROLE.EREVIEWER.value;

	const [collapse, setCollapse] = useState<boolean>(false);
	const nameLicensingPeriod = useMemo(
		() => `${KEY_FIELD_FORM_TERM.licensingPeriod}${keyIndex}`,
		[keyIndex],
	);
	const nameApprovedCountries = useMemo(
		() => `${KEY_FIELD_FORM_TERM.approvedCountries}${keyIndex}`,
		[keyIndex],
	);
	const nameApprovedMarketingChannels = useMemo(
		() => `${KEY_FIELD_FORM_TERM.approvedMarketingChannels}${keyIndex}`,
		[keyIndex],
	);

	const listCountry = useMemo(() => {
		const country: any = countries;
		const listKey: string[] = Object.keys(countries);
		const result = listKey.map((key) => {
			return { value: key, label: country[key].name };
		});
		return [
			{ value: ALL_COUNTRY, label: t('content.widget_manager.widget_builder.global') },
			...result,
		];
	}, [countries]);

	useEffect(() => {
		setFieldsValue({
			[nameLicensingPeriod]: term.licensingPeriod,
			[nameApprovedCountries]: term.approvedCountries,
			[nameApprovedMarketingChannels]: term.approvedMarketingChannels,
		});
	}, [term, collapse]);

	const renderHeader = () => {
		return (
			<StyledWrapperTitle>
				<StyledTitle>{t('rewards.steps.details')}</StyledTitle>

				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledWrapperTitle>
		);
	};

	const renderInput = (name: string, placeholder: string) => {
		return (
			<StyledInputCustomField
				placeholder={placeholder}
				value={getValueField(name)}
				id={name}
				type="number"
				disabled={disabled}
				className="input_hidden_box"
			/>
		);
	};

	const renderSelect = (name: string, options: SelectOptionDto[], placeholder: string) => {
		return (
			<StyledSelect
				mode={'multiple'}
				options={options}
				showSearch
				virtual={false}
				allowClear
				optionFilterProp={'label'}
				notFoundContent={notFoundContent(t)}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				disabled={disabled}
				value={getValueField(name)}
				placeholder={placeholder}
				showArrow
				className="select_group_tags select_detail_terms"
				dropdownClassName="dropdown-menu dropdown-custom-content"
			/>
		);
	};

	const arrField = [
		{
			id: 1,
			content: renderInput(
				nameLicensingPeriod,
				t('content.widget_manager.widget_builder.in_perpetuity'),
			),
			title: t('content.widget_manager.widget_builder.licensing_period'),
			tooltipTitle: t('content.widget_manager.widget_builder.sub_licensing'),
			rules: [
				{
					validator: (_: any, value: any) => {
						const valNum = +value;

						if (valNum < 0) {
							setFieldsValue({ [nameLicensingPeriod]: -valNum.toString() });
						}
						return Promise.resolve();
					},
				},
			],
			name: nameLicensingPeriod,
		},
		{
			id: 2,
			content: renderSelect(
				nameApprovedCountries,
				listCountry,
				t('content.widget_manager.widget_builder.global'),
			),
			title: t('content.widget_manager.widget_builder.approved_countries'),
			tooltipTitle: t('content.widget_manager.widget_builder.sub_country'),
			name: nameApprovedCountries,
		},
		{
			id: 3,
			content: renderSelect(
				nameApprovedMarketingChannels,
				MARKETING_CHANNELS.map((mar) => ({ value: mar, label: mar })),
				t('content.widget_manager.widget_builder.marketing_channels'),
			),
			title: t('content.widget_manager.widget_builder.approved_marketing'),
			tooltipTitle: t('content.widget_manager.widget_builder.sub_marketing'),
			name: nameApprovedMarketingChannels,
		},
	];

	return (
		<StyledWrapperContent>
			<StyledCollapse
				style={{ margin: '0' }}
				className={'collapse_terms_detail'}
				onChange={(key: string | string[]) => {
					setCollapse(!collapse);
				}}
				activeKey={collapse ? ['1'] : []}>
				<Panel header={renderHeader()} key="1">
					{arrField.map((field) => {
						const { id, content, tooltipTitle, title, ...otherField } = field;
						return (
							<StyledFormItem
								key={field.id}
								{...otherField}
								rules={field?.rules || []}
								margin="12px 0 0 0"
								label={
									<StyledWrapperTitle
										fontSize="14px"
										lineHeight="20px"
										justifyContent="left">
										{title}
										<Tooltip title={tooltipTitle}>
											<StyledIcon fillPath={THEME.colors.gray1} size={16}>
												<IconQuestionCircle />
											</StyledIcon>
										</Tooltip>
									</StyledWrapperTitle>
								}>
								{field.content}
							</StyledFormItem>
						);
					})}
				</Panel>
			</StyledCollapse>
		</StyledWrapperContent>
	);
};

export default OtherDetailTerm;
