import { Action, handleActions } from 'redux-actions';

import { DEFAULT_ERROR } from '@constants/errors';
import { STATUSCODE } from '@constants/APIs';
import { IN_PROGRESS, SUCCEEDED, FAILED, NONE } from '@constants/status';
import { MessagesRootStateDto } from '@models/messages/store';
import {
	emailTemplateTypes,
	generateCodeTypes,
	getDynamicFieldsTypes,
	getEmailEventTypes,
	getRewardOffersTypes,
	getTemplatesTypes,
	saveDraftEmailTypes,
	sendEmailRewardTypes,
	getEmailEvenDetailTypes,
	getEventCreatorTypes,
	saveAsTemplateTypes,
	deleteEmailTypes,
	getDirectMessageTypes,
	sendTestMailTemplate,
	getDirectMessageTemplate,
	getDirectMessageDetailTypes,
	storeMessageTemplateDataTypes,
	searchMessageTemplateTypes,
	deleteDirectMessageTemplateTypes,
} from './messages.types';
import { EventDto } from '@models/messages/log';
import { EMAIL_TEMPLATE_FOLDER_TYPES } from '@constants/messages';
import { EmailTemplatesDataDto, PayloadGetDirectMessageTemplate } from '@models/messages/summary';

const initialState: MessagesRootStateDto = {
	getEmailEventStatus: NONE,
	getDynamicFieldsStatus: NONE,
	dynamicFields: [],
	error: undefined,
	rewardOffers: [],
	emailTemplate: {
		folder: '1',
		getEmailTemplateStatus: false,
		error: null,
		isSelectTemplate: false,
		restoreLoading: false,
		restoreTemplateStatus: 0,
		automated: {
			selectAutomatedEmailTemplate: 0,
			templates: [],
			editTemplateSuccessfully: 0,
			trigger: '',
			turnOnOffAction: false,
			isEnabled: null,
			isArchived: false,
		},
		custom: {
			selectCustomEmailTemplate: 0,
			editTemplateSuccessfully: 0,
			createTemplateLoading: false,
			createTemplateStatus: 0,
			templates: [],
			isArchived: false,
		},
		archived: {
			selectArchivedEmailTemplate: 0,
			templates: [],
		},
	},
	emailLog: {
		pageRecords: 0,
		page: 0,
		totalRecords: 0,
		totalPages: 0,
		events: [],
	},
	isFailedFilterEmailLog: false,
	directMessageTemplate: {
		isLoadingDirectTemplate: false,
		dataDirectTemplate: [],
	},
	deleteDirectMessageStatus: NONE,
};

const getDynamicFieldsRequest = (state: MessagesRootStateDto) => ({
	...state,
	getDynamicFieldsStatus: IN_PROGRESS,
});

const getDynamicFieldsSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result: dynamicFields = [],
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			getDynamicFieldsStatus: SUCCEEDED,
			dynamicFields,
			error: null,
		};
	}

	return {
		...state,
		getDynamicFieldsStatus: FAILED,
		dynamicFields: null,
		error: status,
	};
};

const getDynamicFieldsFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			getDynamicFieldsStatus: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		getDynamicFieldsStatus: FAILED,
	};
};

const getTemplatesRequest = (state: MessagesRootStateDto) => ({
	...state,
	getTemplatesStatus: IN_PROGRESS,
});

const getTemplatesSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result: templates = [],
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			getTemplatesStatus: SUCCEEDED,
			templates,
			error: null,
		};
	}

	return {
		...state,
		getTemplatesStatus: FAILED,
		templates: null,
		error: status,
	};
};

const getTemplatesFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			getTemplatesStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		getTemplatesStatus: FAILED,
	};
};

const sendEmailRewardRequest = (state: MessagesRootStateDto) => ({
	...state,
	sendEmailStatus: IN_PROGRESS,
});

const sendEmailRewardSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result: sentEmailResult = {},
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			sendEmailStatus: SUCCEEDED,
			sentEmailResult,
			error: null,
		};
	}

	return {
		...state,
		sendEmailStatus: FAILED,
		error: status,
	};
};

const sendEmailRewardFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			sendEmailStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		sendEmailStatus: FAILED,
	};
};

const sendEmailRewardEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		error: null,
		sendEmailStatus: NONE,
		sentEmailResult: null,
	};
};

const saveDraftEmailRequest = (state: MessagesRootStateDto) => ({
	...state,
	saveDraftEmailStatus: IN_PROGRESS,
});

const saveDraftEmailSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			saveDraftEmailStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		saveDraftEmailStatus: FAILED,
		error: status,
	};
};

const saveDraftEmailFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			saveDraftEmailStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		saveDraftEmailStatus: FAILED,
	};
};

const saveDraftEmailEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		error: null,
		saveDraftEmailStatus: NONE,
	};
};

const generateCodeRequest = (state: MessagesRootStateDto) => ({
	...state,
	generateCodeStatus: IN_PROGRESS,
});

const generateCodeSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result,
		isUpdate,
	} = payload;

	const generatedCode = { ...result, isUpdate };

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			generatedCode,
			generateCodeStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		generateCodeStatus: FAILED,
		error: status,
	};
};

const generateCodeFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			generateCodeStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		generateCodeStatus: FAILED,
	};
};

const generateCodeEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		error: null,
		generatedCode: null,
		generateCodeStatus: NONE,
	};
};

const getRewardOffersRequest = (state: MessagesRootStateDto) => ({
	...state,
	getRewardOffersStatus: IN_PROGRESS,
});

const getRewardOffersSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			rewardOffers: result.listRuleRewardDTO,
			getRewardOffersStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		getRewardOffersStatus: FAILED,
		error: status,
	};
};

const getRewardOffersFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			getRewardOffersStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		getRewardOffersStatus: FAILED,
	};
};

const getRewardOffersEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		error: null,
		getRewardOffersStatus: NONE,
	};
};

const getEmailEvent = (state: MessagesRootStateDto) => ({
	...state,
	getEmailEventStatus: IN_PROGRESS,
});

const getEmailEventSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result = {},
		isFailed: isFailedFilterEmailLog = false,
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			getEmailEventStatus: SUCCEEDED,
			emailLog: { ...result },
			isFailedFilterEmailLog,
			error: null,
		};
	}

	return {
		...state,
		getEmailEventStatus: FAILED,
		emailLog: {},
		isFailedFilterEmailLog: false,
		error: status,
	};
};

const getEmailEventFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			getEmailEventStatus: FAILED,
			emailLog: {},
			isFailedFilterEmailLog: false,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		getEmailEventStatus: FAILED,
		isFailedFilterEmailLog: false,
		emailLog: {},
	};
};

const getEmailEventDetail = (state: MessagesRootStateDto) => ({
	...state,
	// getEmailEventStatus: IN_PROGRESS,
	isGetMailStatus: false,
});

const resetStatusDBLickEmail = (state: MessagesRootStateDto) => ({
	...state,
	isGetMailStatus: false,
});

const getEmailEventDetailSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const { data, isGetMailStatus } = payload;
	const {
		status: { code = 0, status = '' },
		result = {},
	} = data;

	const { emailLog: { events = [] } = {} } = state;

	const newEvents = events.map((event: EventDto) => (event.id === result.id ? result : event));

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			// getEmailEventStatus: SUCCEEDED,
			emailLog: { ...state.emailLog, events: newEvents },
			error: null,
			isGetMailStatus,
		};
	}

	return {
		...state,
		// getEmailEventStatus: FAILED,
		error: status,
		isGetMailStatus: false,
	};
};

const getEmailEventDetailFailed = (
	state: MessagesRootStateDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			// getEmailEventStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		// getEmailEventStatus: FAILED,
	};
};

const getEventCreator = (state: MessagesRootStateDto) => ({
	...state,
	// getEmailEventStatus: IN_PROGRESS,
});

const getEventCreatorSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result = {},
		requestData = {},
	} = payload;

	const { segmentId, id: eventId, page } = requestData;
	const isFirstPage = page === 1;
	const { emailLog: { events = [] } = {} } = state;

	const tempEvents = [...events];

	let temp = [];
	let newEvents: EventDto[] = [];
	if (segmentId) {
		newEvents = tempEvents.map((event: EventDto) => {
			const { segments = [] } = event;
			if (event.id === eventId) {
				temp = segments.map((segment: any) => {
					if (segment.segmentId === segmentId) {
						if (isFirstPage) {
							return { ...segment, pagingCreator: result };
						} else {
							return {
								...segment,
								pagingCreator: {
									...segment.pagingCreator,
									creators: [
										...segment.pagingCreator.creators,
										...result.creators,
									],
								},
							};
						}
					} else return { ...segment };
				});
				return { ...event, segments: [...temp] };
			}
			return event;
		});
	} else {
		newEvents = tempEvents.map((event: EventDto) => {
			const { pagingCreator = {} } = event;
			const clonePagingCreator = { ...pagingCreator } || {};
			const { creators = [] } = clonePagingCreator;
			if (event.id === eventId) {
				if (isFirstPage) {
					return { ...event, pagingCreator: { ...result } };
				}
				return {
					...event,
					pagingCreator: {
						...result,
						creators: [...creators, ...result.creators],
					},
				};
			}
			return event;
		});
	}

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			emailLog: { ...state.emailLog, events: [...newEvents] },
			error: null,
		};
	}

	return {
		...state,
		error: status,
	};
};

const getEventCreatorFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
	};
};

const deleteEmail = (state: MessagesRootStateDto) => ({
	...state,
});

const deleteEmailSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
	} = payload;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			error: null,
		};
	}

	return {
		...state,
		error: status,
	};
};

const deleteEmailFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
	};
};

// *****************************************************************
// ******************* MESSAGE - EMAIL TEMPLATES *******************
// *****************************************************************

const selectTabFolderEmailTemplate = (
	state: MessagesRootStateDto,
	{ payload }: Action<{ selectId: string }>,
) => {
	const { selectId = '' } = payload;

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			isSelectTemplate: false,
			folder: selectId,
		},
	};
};
const selectAutomatedEmailTemplate = (
	state: MessagesRootStateDto,
	{ payload }: Action<{ selectId: number }>,
) => {
	const { selectId = '' } = payload;

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			isSelectTemplate: true,
			automated: {
				...state.emailTemplate.automated,
				selectAutomatedEmailTemplate: selectId,
			},
		},
	};
};

const selectCustomEmailTemplate = (
	state: MessagesRootStateDto,
	{ payload }: Action<{ selectId: number }>,
) => {
	const { selectId = '' } = payload;

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			isSelectTemplate: true,
			custom: {
				...state.emailTemplate.custom,
				selectCustomEmailTemplate: selectId,
			},
		},
	};
};

const selectArchivedEmailTemplate = (
	state: MessagesRootStateDto,
	{ payload }: Action<{ selectId: number }>,
) => {
	const { selectId = '' } = payload;

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			isSelectTemplate: true,
			archived: {
				...state.emailTemplate.archived,
				selectArchivedEmailTemplate: selectId,
			},
		},
	};
};

const sendTestMailEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		sendTestEmailStatus: NONE,
		sentTestEmailResult: null,
	};
};

const sendTestMailRequest = (state: MessagesRootStateDto) => ({
	...state,
	sendTestEmailStatus: IN_PROGRESS,
});

const sendTestMailSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result: sentTestEmailResult = [],
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			sendTestEmailStatus: SUCCEEDED,
			sentTestEmailResult,
			error: null,
		};
	}

	return {
		...state,
		sendTestEmailStatus: FAILED,
		sentTestEmailResult: null,
		error: status,
	};
};

const sendTestMailFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			sendTestEmailStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		sendTestEmailStatus: FAILED,
	};
};

const getEmailTemplatesRequest = (state: MessagesRootStateDto) => ({
	...state,
	emailTemplate: {
		...state.emailTemplate,
		getEmailTemplateStatus: true,
		automated: {
			...state.emailTemplate.automated,
			selectAutomatedEmailTemplate: 0,
			editTemplateSuccessfully: 0,
			isArchived: false,
		},
		custom: {
			...state.emailTemplate.custom,
			selectCustomEmailTemplate: 0,
			editTemplateSuccessfully: 0,
			createTemplateLoading: false,
			createTemplateStatus: 0,
			isArchived: false,
		},
		archived: {
			...state.emailTemplate.archived,
			selectArchivedEmailTemplate: 0,
		},
	},
});

const updateEmailTemplatesRequest = (state: MessagesRootStateDto) => ({
	...state,
	emailTemplate: {
		...state.emailTemplate,
		getEmailTemplateStatus: true,
		automated: {
			...state.emailTemplate.automated,
			editTemplateSuccessfully: 0,
			trigger: '',
			isEnabled: null,
			isArchived: false,
		},
		custom: {
			...state.emailTemplate.custom,
			editTemplateSuccessfully: 0,
			isArchived: false,
		},
	},
});

const enableTemplateAction = (state: MessagesRootStateDto, { payload }: Action<boolean>) => ({
	...state,
	emailTemplate: {
		...state.emailTemplate,
		getEmailTemplateStatus: true,
		automated: {
			...state.emailTemplate.automated,
			turnOnOffAction: payload,
		},
	},
});

const restoreEmailTemplatesRequest = (state: MessagesRootStateDto) => ({
	...state,
	emailTemplate: {
		...state.emailTemplate,
		restoreLoading: true,
		restoreTemplateStatus: 0,
	},
});

const restoreEmailTemplatesSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			emailTemplate: {
				...state.emailTemplate,
				restoreLoading: false,
				restoreTemplateStatus: code,
			},
		};
	}

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			restoreLoading: false,
			restoreTemplateStatus: code,
			error: status,
		},
	};
};

const restoreEmailTemplatesFailed = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	if (payload.data) {
		const { message = '', status = {}, error = '' } = payload.data;

		return {
			...state,
			emailTemplate: {
				...state.emailTemplate,
				restoreLoading: false,
				restoreTemplateStatus: status?.code,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}
	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			restoreLoading: false,
			restoreTemplateStatus: 0,
		},
	};
};

const getEmailTemplatesSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result: emailTemplates = [],
	} = payload.data;
	const { type } = payload;

	if (code === STATUSCODE.SUCCESS) {
		const data = {
			...state,
			emailTemplate: {
				...state.emailTemplate,
				getEmailTemplateStatus: false,
				restoreTemplateStatus: 0,
				error: null,
				automated: {
					...state.emailTemplate.automated,
					templates: [],
					turnOnOffAction: false,
				},
				custom: {
					...state.emailTemplate.custom,
					templates: [],
				},
				archived: {
					...state.emailTemplate.archived,
					templates: [],
				},
			},
		};

		if (!type) {
			data.emailTemplate.allEmailTemplates = emailTemplates;
			return data;
		}

		if (type === EMAIL_TEMPLATE_FOLDER_TYPES.DEFAULT) {
			data.emailTemplate.automated = {
				...state.emailTemplate.automated,
				turnOnOffAction: false,
				templates: emailTemplates,
			};
		} else if (type === EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM) {
			data.emailTemplate.custom = {
				...state.emailTemplate.custom,
				templates: emailTemplates,
			};
		} else {
			data.emailTemplate.archived = {
				...state.emailTemplate.archived,
				templates: emailTemplates,
			};
		}

		return data;
	}

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			getEmailTemplateStatus: false,
			error: status,
			automated: {
				...state.emailTemplate.automated,
				templates: null,
				turnOnOffAction: false,
			},
			custom: {
				...state.emailTemplate.custom,
				templates: null,
			},
			archived: {
				...state.emailTemplate.archived,
				templates: null,
			},
		},
	};
};

const createEmailTemplatesRequest = (state: MessagesRootStateDto) => ({
	...state,
	emailTemplate: {
		...state.emailTemplate,

		custom: {
			...state.emailTemplate.custom,
			createTemplateLoading: true,
		},
	},
});

const createEmailTemplatesSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		actionType = '',
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			emailTemplate: {
				...state.emailTemplate,
				custom: {
					...state.emailTemplate.custom,
					createTemplateLoading: false,
					createTemplateStatus: code,
				},
			},
			actionType,
		};
	}

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			getEmailTemplateStatus: false,
			error: status,
			custom: {
				...state.emailTemplate.custom,
				createTemplateLoading: false,
				createTemplateStatus: code,
			},
		},
	};
};

const updateEmailTemplatesSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result: { trigger = '', enabled = false } = {},
		type,
		typeOrigin,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		if (type === EMAIL_TEMPLATE_FOLDER_TYPES.ARCHIVED) {
			switch (typeOrigin) {
				case EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM:
					return {
						...state,
						emailTemplate: {
							...state.emailTemplate,
							getEmailTemplateStatus: false,
							error: null,
							custom: {
								...state.emailTemplate.custom,
								editTemplateSuccessfully: code,
								isArchived: true,
							},
						},
					};

				default:
					return {
						...state,
						emailTemplate: {
							...state.emailTemplate,
							getEmailTemplateStatus: false,
							error: null,
							automated: {
								...state.emailTemplate.automated,
								editTemplateSuccessfully: code,
								isArchived: true,
							},
						},
					};
			}
		} else if (type === EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM) {
			return {
				...state,
				emailTemplate: {
					...state.emailTemplate,
					getEmailTemplateStatus: false,
					error: null,
					custom: {
						...state.emailTemplate.custom,
						editTemplateSuccessfully: code,
					},
				},
			};
		} else {
			return {
				...state,
				emailTemplate: {
					...state.emailTemplate,
					getEmailTemplateStatus: false,
					error: null,
					automated: {
						...state.emailTemplate.automated,
						editTemplateSuccessfully: code,
						isEnabled: enabled,
						trigger,
					},
				},
			};
		}
	}

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			getEmailTemplateStatus: false,
			error: status,
			automated: {
				...state.emailTemplate.automated,
				editTemplateSuccessfully: 0,
				trigger: '',
			},
		},
	};
};

const getEmailTemplatesFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			emailTemplate: {
				...state.emailTemplate,
				getEmailTemplateStatus: false,
				custom: {
					createTemplateLoading: false,
					createTemplateStatus: FAILED,
				},
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			custom: {
				createTemplateLoading: false,
				createTemplateStatus: FAILED,
			},
			getEmailTemplateStatus: false,
		},
	};
};

const saveAsTemplateRequest = (state: MessagesRootStateDto) => ({
	...state,
	saveAsTemplateStatus: IN_PROGRESS,
});

const saveAsTemplateSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			saveAsTemplateStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		saveAsTemplateStatus: FAILED,
		error: status,
	};
};

const saveAsTemplateFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;

		return {
			...state,
			saveAsTemplateStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		saveAsTemplateStatus: FAILED,
	};
};

const saveAsTemplateEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		error: null,
		saveAsTemplateStatus: NONE,
	};
};

const getDirectMessageRequest = (state: MessagesRootStateDto) => ({
	...state,
	getDirectMessageStatus: IN_PROGRESS,
});

const getDirectMessageSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			directMessageList: result,
			getDirectMessageStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		getDirectMessageStatus: FAILED,
		error: status,
	};
};

const getDirectMessageFailed = (state: MessagesRootStateDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;
		return {
			...state,
			getDirectMessageStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		getDirectMessageStatus: FAILED,
	};
};

const getDirectMessageEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		error: null,
		directMessageList: [],
		getDirectMessageStatus: NONE,
	};
};

const getDirectMessageDetail = (state: MessagesRootStateDto) => ({
	...state,
	getDirectMessageDetailStatus: IN_PROGRESS,
});

const getDirectMessageDetailSucceeded = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			directMessageDetail: result,
			getDirectMessageDetailStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		getDirectMessageDetailStatus: FAILED,
		error: status,
	};
};

const getDirectMessageDetailFailed = (
	state: MessagesRootStateDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload) {
		const { status = {}, error = '' } = payload.data;
		return {
			...state,
			getDirectMessageDetailStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		getDirectMessageDetailStatus: FAILED,
	};
};

const getDirectMessageDetailEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		error: null,
		directMessageDetail: {},
		getDirectMessageDetailStatus: NONE,
	};
};

const getDirectMessageTemplateRequest = (state: MessagesRootStateDto) => {
	return {
		...state,
		directMessageTemplate: {
			...state.directMessageTemplate,
			isLoadingDirectTemplate: true,
		},
	};
};

const getDirectMessageTemplateSuccessed = (
	state: MessagesRootStateDto,
	{ payload }: Action<PayloadGetDirectMessageTemplate>,
) => {
	const { error, result } = payload;
	return {
		...state,
		directMessageTemplate: {
			dataDirectTemplate: result,
			isLoadingDirectTemplate: false,
			error: error,
		},
	};
};

const getDirectMessageTemplateFailed = (
	state: MessagesRootStateDto,
	{ payload }: Action<PayloadGetDirectMessageTemplate>,
) => {
	const { error } = payload;
	return {
		...state,
		directMessageTemplate: {
			...state.directMessageTemplate,
			isLoadingDirectTemplate: false,
			error: error,
		},
	};
};

const getSelectedKeyDMTemplate = (state: MessagesRootStateDto, { payload }: Action<number>) => {
	return {
		...state,
		directMessageTemplate: { ...state.directMessageTemplate, selectKeyDM: payload },
	};
};

const deleteDMTemplateRequest = (state: MessagesRootStateDto) => {
	return {
		...state,
		directMessageTemplate: {
			...state.directMessageTemplate,
			isLoadingDirectTemplate: true,
		},
		deleteDirectMessageStatus: IN_PROGRESS,
	};
};
const deleteDMTemplateSucceeded = (state: MessagesRootStateDto) => {
	return {
		...state,
		directMessageTemplate: {
			...state.directMessageTemplate,
			isLoadingDirectTemplate: false,
		},
		deleteDirectMessageStatus: SUCCEEDED,
	};
};
const deleteDMTemplateFailed = (state: MessagesRootStateDto, { payload }: Action<any>) => {
	const result = {
		...state,
		deleteDirectMessageStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload) {
		const { status } = payload;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const deleteDMTemplateEnd = (state: MessagesRootStateDto) => {
	return {
		...state,
		deleteDirectMessageStatus: NONE,
		error: null,
	};
};

const createDMTemplateRequest = (state: MessagesRootStateDto) => {
	return {
		...state,
		directMessageTemplate: {
			...state.directMessageTemplate,
			isLoadingDirectTemplate: true,
		},
	};
};
const createDMTemplateSuccess = (state: MessagesRootStateDto) => {
	return {
		...state,
		directMessageTemplate: {
			...state.directMessageTemplate,
			isLoadingDirectTemplate: false,
		},
	};
};
const createDMTemplateSuccessFail = (state: MessagesRootStateDto) => {
	return {
		...state,
		directMessageTemplate: {
			...state.directMessageTemplate,
			isLoadingDirectTemplate: false,
		},
	};
};

const searchMessageTemplate = (
	state: MessagesRootStateDto,
	{ payload }: Action<{ searchedValue: string; type: string }>,
) => {
	const { searchedValue, type } = payload;
	const formattedType = type.toLowerCase();
	let searchedTemplate = state.emailTemplate[formattedType].templates;

	searchedTemplate = searchedValue
		? searchedTemplate.filter(
				(item: EmailTemplatesDataDto) =>
					item?.title?.toLowerCase()?.includes(searchedValue) ||
					item?.description?.toLowerCase()?.includes(searchedValue) ||
					item?.subject?.toLowerCase()?.includes(searchedValue) ||
					item?.body?.toLowerCase()?.includes(searchedValue),
		  )
		: searchedTemplate;

	const data = {
		...state,
		emailTemplate: {
			...state.emailTemplate,
			getEmailTemplateStatus: false,
			restoreTemplateStatus: 0,
			error: null,
			[formattedType]: {
				...state.emailTemplate[formattedType],
				templates: searchedTemplate,
			},
		},
	};

	return data;
};

const storeMessageTemplate = (state: MessagesRootStateDto, { payload }: Action<any>) => ({
	...state,
	...payload,
});

const messagesReducers = handleActions<any>(
	{
		[getDynamicFieldsTypes.GET_DYNAMIC_FIELDS_REQUEST]: getDynamicFieldsRequest,
		[getDynamicFieldsTypes.GET_DYNAMIC_FIELDS_SUCCEEDED]: getDynamicFieldsSucceeded,
		[getDynamicFieldsTypes.GET_DYNAMIC_FIELDS_FAILED]: getDynamicFieldsFailed,

		[getTemplatesTypes.GET_TEMPLATES_REQUEST]: getTemplatesRequest,
		[getTemplatesTypes.GET_TEMPLATES_SUCCEEDED]: getTemplatesSucceeded,
		[getTemplatesTypes.GET_TEMPLATES_FAILED]: getTemplatesFailed,

		[sendEmailRewardTypes.SEND_EMAIL_REWARD_REQUEST]: sendEmailRewardRequest,
		[sendEmailRewardTypes.SEND_EMAIL_REWARD_SUCCEEDED]: sendEmailRewardSucceeded,
		[sendEmailRewardTypes.SEND_EMAIL_REWARD_FAILED]: sendEmailRewardFailed,
		[sendEmailRewardTypes.SEND_EMAIL_REWARD_END]: sendEmailRewardEnd,

		[getEmailEventTypes.GET_EMAIL_EVENT]: getEmailEvent,
		[getEmailEventTypes.GET_EMAIL_EVENT_SUCCEEDED]: getEmailEventSucceeded,
		[getEmailEventTypes.GET_EMAIL_EVENT_FAILED]: getEmailEventFailed,

		[getEmailEvenDetailTypes.GET_EMAIL_EVENT_DETAIL]: getEmailEventDetail,
		[getEmailEvenDetailTypes.GET_EMAIL_EVENT_DETAIL_SUCCEEDED]: getEmailEventDetailSucceeded,
		[getEmailEvenDetailTypes.GET_EMAIL_EVENT_DETAIL_FAILED]: getEmailEventDetailFailed,
		[getEmailEvenDetailTypes.RESET_DB_CLICK_GET_MAIL]: resetStatusDBLickEmail,

		[getEventCreatorTypes.GET_EVENT_CREATOR]: getEventCreator,
		[getEventCreatorTypes.GET_EVENT_CREATOR_SUCCEEDED]: getEventCreatorSucceeded,
		[getEventCreatorTypes.GET_EVENT_CREATOR_FAILED]: getEventCreatorFailed,

		[deleteEmailTypes.DELETE_EMAIL]: deleteEmail,
		[deleteEmailTypes.DELETE_EMAIL_SUCCEEDED]: deleteEmailSucceeded,
		[deleteEmailTypes.DELETE_EMAIL_FAILED]: deleteEmailFailed,

		[saveDraftEmailTypes.SAVE_DRAFT_EMAIL_REQUEST]: saveDraftEmailRequest,
		[saveDraftEmailTypes.SAVE_DRAFT_EMAIL_SUCCEEDED]: saveDraftEmailSucceeded,
		[saveDraftEmailTypes.SAVE_DRAFT_EMAIL_FAILED]: saveDraftEmailFailed,
		[saveDraftEmailTypes.SAVE_DRAFT_EMAIL_END]: saveDraftEmailEnd,

		[generateCodeTypes.GENERATE_CODE_REQUEST]: generateCodeRequest,
		[generateCodeTypes.GENERATE_CODE_SUCCEEDED]: generateCodeSucceeded,
		[generateCodeTypes.GENERATE_CODE_FAILED]: generateCodeFailed,
		[generateCodeTypes.GENERATE_CODE_END]: generateCodeEnd,

		[getRewardOffersTypes.GET_REWARD_OFFERS_REQUEST]: getRewardOffersRequest,
		[getRewardOffersTypes.GET_REWARD_OFFERS_SUCCEEDED]: getRewardOffersSucceeded,
		[getRewardOffersTypes.GET_REWARD_OFFERS_FAILED]: getRewardOffersFailed,
		[getRewardOffersTypes.GET_REWARD_OFFERS_END]: getRewardOffersEnd,

		[emailTemplateTypes.SELECT_TAB_FOLDER_EMAIL_TEMPLATE]: selectTabFolderEmailTemplate,
		[emailTemplateTypes.SELECT_AUTOMATED_EMAIL_TEMPLATE]: selectAutomatedEmailTemplate,
		[emailTemplateTypes.SELECT_CUSTOM_EMAIL_TEMPLATE]: selectCustomEmailTemplate,
		[emailTemplateTypes.SELECT_ARCHIVED_EMAIL_TEMPLATE]: selectArchivedEmailTemplate,
		[emailTemplateTypes.GET_EMAIL_TEMPLATES_REQUEST]: getEmailTemplatesRequest,
		[emailTemplateTypes.CREATE_EMAIL_TEMPLATES_REQUEST]: createEmailTemplatesRequest,
		[emailTemplateTypes.UPDATE_EMAIL_TEMPLATES_REQUEST]: updateEmailTemplatesRequest,
		[emailTemplateTypes.ENABLE_TEMPLATES_ACTION_REQUEST]: enableTemplateAction,
		[emailTemplateTypes.RESTORE_EMAIL_TEMPLATES_REQUEST]: restoreEmailTemplatesRequest,
		[emailTemplateTypes.RESTORE_EMAIL_TEMPLATES_SUCCEEDED]: restoreEmailTemplatesSucceeded,
		[emailTemplateTypes.RESTORE_EMAIL_TEMPLATES_FAILED]: restoreEmailTemplatesFailed,
		[emailTemplateTypes.GET_EMAIL_TEMPLATES_SUCCEEDED]: getEmailTemplatesSucceeded,
		[emailTemplateTypes.UPDATE_EMAIL_TEMPLATES_SUCCEEDED]: updateEmailTemplatesSucceeded,
		[emailTemplateTypes.CREATE_EMAIL_TEMPLATES_SUCCEEDED]: createEmailTemplatesSucceeded,
		[emailTemplateTypes.GET_EMAIL_TEMPLATES_FAILED]: getEmailTemplatesFailed,
		[sendTestMailTemplate.SEND_TEST_MAIL_END]: sendTestMailEnd,

		[sendTestMailTemplate.SEND_TEST_MAIL_REQUEST]: sendTestMailRequest,
		[sendTestMailTemplate.SEND_TEST_MAIL_SUCCEEDED]: sendTestMailSucceeded,
		[sendTestMailTemplate.SEND_TEST_MAIL_FAILED]: sendTestMailFailed,

		[saveAsTemplateTypes.SAVE_AS_TEMPLATE_REQUEST]: saveAsTemplateRequest,
		[saveAsTemplateTypes.SAVE_AS_TEMPLATE_SUCCEEDED]: saveAsTemplateSucceeded,
		[saveAsTemplateTypes.SAVE_AS_TEMPLATE_FAILED]: saveAsTemplateFailed,
		[saveAsTemplateTypes.SAVE_AS_TEMPLATE_END]: saveAsTemplateEnd,

		[getDirectMessageTypes.GET_DIRECT_MESSAGE_REQUEST]: getDirectMessageRequest,
		[getDirectMessageTypes.GET_DIRECT_MESSAGE_SUCCEEDED]: getDirectMessageSucceeded,
		[getDirectMessageTypes.GET_DIRECT_MESSAGE_FAILED]: getDirectMessageFailed,
		[getDirectMessageTypes.GET_DIRECT_MESSAGE_END]: getDirectMessageEnd,

		[getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL]: getDirectMessageDetail,
		[getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL_SUCCEEDED]:
			getDirectMessageDetailSucceeded,
		[getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL_FAILED]:
			getDirectMessageDetailFailed,
		[getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL_END]: getDirectMessageDetailEnd,

		[getDirectMessageTemplate.GET_DIRECT_MESSAGE_TEMPLATE_REQUEST]:
			getDirectMessageTemplateRequest,
		[getDirectMessageTemplate.GET_DIRECT_MESSAGE_TEMPLATE_SUCCESSED]:
			getDirectMessageTemplateSuccessed,
		[getDirectMessageTemplate.GET_DIRECT_MESSAGE_TEMPLATE_FAILED]:
			getDirectMessageTemplateFailed,
		[getDirectMessageTemplate.GET_SELECTED_KEY_DM_TEMPLATE]: getSelectedKeyDMTemplate,

		[deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_REQUEST]: deleteDMTemplateRequest,
		[deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_SUCCEEDED]: deleteDMTemplateSucceeded,
		[deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_FAILED]: deleteDMTemplateFailed,
		[deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_END]: deleteDMTemplateEnd,

		[getDirectMessageTemplate.CREATE_DM_TEMPLATE_REQUEST]: createDMTemplateRequest,
		[getDirectMessageTemplate.CREATE_DM_TEMPLATE_SUCCESSED]: createDMTemplateSuccess,
		[getDirectMessageTemplate.CREATE_DM_TEMPLATE_FAILED]: createDMTemplateSuccessFail,

		[searchMessageTemplateTypes.SEARCH_MESSAGE_TEMPLATE]: searchMessageTemplate,
		[storeMessageTemplateDataTypes.STORE_MESSAGE_TEMPLATE_DATA]: storeMessageTemplate,
	},
	initialState,
);

export default messagesReducers;
