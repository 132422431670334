import { KEYBOARD, TYPE_ACTIONS } from '@constants/common';
import {
	ACTIONS,
	DEFAULT_VERSION_ID,
	FORM_CONTENT_DETAIL,
	ROTATE,
} from '@constants/content/contentLibrary';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { downloadFile } from '@helpers/downloadFileHelper';
import { ContentRootStateDto, ContentStoreDto } from '@models/content/contentLibrary/store';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { SettingRootStateDto } from '@models/settings/setting';
import {
	createImgVersionEnd,
	creatorBlockEnd,
	deleteContentEnd,
	fetchContentTagsRequest,
	getMediaDetectionRequest,
	getTermsConditionsEnd,
	moveContentEnd,
	rejectContentEnd,
	rotateImageEnd,
	storeContentData,
	updateEmojiEnd,
	updateFlagsEnd,
	updateLabelsEnd,
} from '@stores/actions';
import { FormInstance, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type PropTypes = {
	form: FormInstance<any>;
};

const EffectFunc = (props: PropTypes) => {
	const { form } = props;
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const {
		setIsLoading,
		handleChangeVersion,
		setTypeConfirm,
		onClose,
		typeConfirm,
		handleChangeAfterAction,
		currentMedia,
		idContentUpdate,
		setIdContentUpdate,
		hideMessage,
		setParamPublish,
		setHideMessage,
		isClose,
		setIsClose,
		handleCancel,
		onChangeAfterBlock,
		isBlockDelete,
		setIsBlockDelete,
		updateVerificationScrore,
		versionImgSelected,
		setVersionImgSelected,
		collapseTerm,
		fieldSelect,
		funcSelectField,
		setCollapseTerm,
		funcSelectFlagField,
		handleClearActionChangeCustomField,
		setCurrentMedia,
		setTypeAction,
		typeAction,
		otherLoading,
		setOtherLoading,
		isRotateRequest,
		setIsRotateRequest,
	} = useContext(ContentDetailContext);
	const {
		rejectContentStatus,
		deleteContentStatus,
		reviewContentStatus,
		reviewedContent,
		moveContentStatus,
		verificationScore,
		getTermsConditionsStatus,
		termsConditions,
		createPublishLogStatus,
		updatePublishLogStatus,
		removePublishLogStatus,
		updateEmojiStatus,
		idContentVersion,
		createImgVersionStatus,
		updateImgVersionStatus,
		imgVersionDetail,
		imgVersions,
		loadingImgVersion,
		error,
		contentDetection,
		isUploadVideo,
		rotateImgStatus,
		rotateImgRes,
		isRequestDetail,
		contentIdsRequest,
		getMediaDetectionStatus,
	}: ContentStoreDto = useSelector((state: ContentRootStateDto) => state.content);

	const { loadingLabels, loadingFlags } = useSelector(
		(state: SettingRootStateDto) => state.settings.content,
	);

	const { creatorBlockStatus } = useSelector((state: any) => state.creator);
	const [isMoreAction, setIsMoreAction] = useState<boolean>(false);

	useEffect(() => {
		return () => {
			setIsMoreAction(false);
			dispatch(
				storeContentData({
					contentDetection: null,
				}),
			);
		};
	}, [currentMedia?.id]);

	useEffect(() => {
		const listStatus = [
			createPublishLogStatus,
			updatePublishLogStatus,
			removePublishLogStatus,
			loadingLabels,
			loadingFlags,
			rejectContentStatus,
			deleteContentStatus,
			moveContentStatus,
			creatorBlockStatus,
			createImgVersionStatus,
			updateImgVersionStatus,
		];
		if (listStatus.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus.includes(FAILED)) {
			message.error(error);
		}
		if (listStatus.includes(FAILED) || listStatus.includes(SUCCEEDED)) {
			setIsLoading(false);
		}
	}, [
		createPublishLogStatus,
		updatePublishLogStatus,
		removePublishLogStatus,
		loadingLabels,
		loadingFlags,
		rejectContentStatus,
		deleteContentStatus,
		moveContentStatus,
		creatorBlockStatus,
		createImgVersionStatus,
		updateImgVersionStatus,
	]);

	useEffect(() => {
		if (createImgVersionStatus === SUCCEEDED && imgVersionDetail) {
			handleChangeVersion(imgVersionDetail, idContentVersion);
			if (imgVersionDetail?.isDefault) {
				if (isUploadVideo) {
					message.info({
						content: t('content_detail.message.video_upload_progress'),
					});
				} else {
					message.success(t('content_detail.message.upload_version_success'));
				}
			}

			if (!loadingImgVersion && currentMedia?.id === idContentVersion) {
				const newList = [...imgVersions];
				const imgVersionChange = imgVersionDetail?.isDefault
					? newList?.map((item) => ({ ...item, isDefault: false }))
					: newList;
				dispatch(
					storeContentData({ imgVersions: [...imgVersionChange, imgVersionDetail] }),
				);
			}
		}
		if (createImgVersionStatus === SUCCEEDED || createImgVersionStatus === FAILED) {
			dispatch(createImgVersionEnd());
		}
	}, [createImgVersionStatus]);

	useEffect(() => {
		const listStatus = [loadingLabels, loadingFlags];
		if (listStatus.includes(SUCCEEDED)) {
			dispatch(fetchContentTagsRequest());
		}
		if (listStatus.includes(FAILED) || listStatus.includes(SUCCEEDED)) {
			dispatch(updateLabelsEnd());
			dispatch(updateFlagsEnd());
		}
	}, [loadingLabels, loadingFlags]);

	useEffect(() => {
		if (
			rotateImgStatus === SUCCEEDED &&
			currentMedia &&
			versionImgSelected &&
			rotateImgRes.length > 0
		) {
			const itemFind = rotateImgRes.find((res) => res.id === currentMedia.id);

			if (itemFind) {
				imgVersions.forEach((imgV) => {
					if (imgV.isDefault && !isRequestDetail) {
						imgV.rotate = ((imgV?.rotate || 0) - ROTATE.D_90) % 360;
					}
				});

				if (versionImgSelected.isDefault) {
					const newRotate = ((currentMedia?.rotate || 0) - ROTATE.D_90) % 360;
					const other: any = {
						contentUrl: itemFind.contentUrl,
						thumbnail: itemFind.thumbnail,
					};
					if (!isRequestDetail) {
						other.rotate = newRotate;
					}
					setCurrentMedia({
						...currentMedia,
						...other,
					});
					setVersionImgSelected({
						...versionImgSelected,
						...other,
					});
				}
			}
		}

		if (rotateImgStatus === SUCCEEDED || rotateImgStatus === FAILED) {
			setOtherLoading(false);
			dispatch(rotateImageEnd());
		}
	}, [rotateImgStatus]);

	useEffect(() => {
		const listStatus = [
			rejectContentStatus,
			deleteContentStatus,
			moveContentStatus,
			creatorBlockStatus,
		];
		if (listStatus.includes(FAILED) || listStatus.includes(SUCCEEDED)) {
			onClose();
			setTypeConfirm('');
		}
	}, [rejectContentStatus, deleteContentStatus, moveContentStatus, creatorBlockStatus]);

	useEffect(() => {
		if (typeConfirm) {
			if (moveContentStatus === SUCCEEDED && typeConfirm === ACTIONS.UNREJECT) {
				message.success(t('content_management.notification.unreject_success'));
				handleChangeAfterAction(ACTIONS.UNREJECT);
			}
		}
		if (moveContentStatus === FAILED || moveContentStatus === SUCCEEDED) {
			dispatch(moveContentEnd());
		}
	}, [moveContentStatus]);

	useEffect(() => {
		if (typeConfirm) {
			if (rejectContentStatus === SUCCEEDED) {
				message.success(t('content_management.notification.reject_success'));
				handleChangeAfterAction(ACTIONS.REJECT);
			}
			if (rejectContentStatus === FAILED || rejectContentStatus === SUCCEEDED) {
				dispatch(rejectContentEnd());
			}
		}
	}, [rejectContentStatus]);

	useEffect(() => {
		if (updateEmojiStatus === FAILED || updateEmojiStatus === SUCCEEDED) {
			dispatch(updateEmojiEnd());
		}
	}, [updateEmojiStatus]);

	useEffect(() => {
		if (typeConfirm) {
			if (deleteContentStatus === SUCCEEDED) {
				message.success(t('content_management.notification.delete_success'));
				handleChangeAfterAction(ACTIONS.DELETE);
			}
			if (deleteContentStatus === FAILED || deleteContentStatus === SUCCEEDED) {
				dispatch(deleteContentEnd());
			}
		}
	}, [deleteContentStatus]);

	useEffect(() => {
		if (reviewContentStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (
			reviewContentStatus === SUCCEEDED &&
			reviewedContent &&
			currentMedia &&
			idContentUpdate === currentMedia?.id
		) {
			handleResetPublishList();
			setCurrentMedia({
				...currentMedia,
				review: reviewedContent,
			});
		}

		if (reviewContentStatus === FAILED || reviewContentStatus === SUCCEEDED) {
			setIsLoading(false);
			handleClearActionChangeCustomField(); // clear hideMessage
			if (rotateImgStatus === IN_PROGRESS && !hideMessage) {
				setHideMessage(false);
			}
			if (idContentUpdate) {
				setIdContentUpdate(undefined);
			}
			if (isClose) {
				setIsClose(false);
				handleCancel();
			}
		}
	}, [reviewContentStatus]);

	useEffect(() => {
		if (reviewContentStatus === IN_PROGRESS && rotateImgStatus === IN_PROGRESS) {
			setIsMoreAction(true);
		}

		if (!hideMessage) {
			if (isMoreAction) {
				if (
					(reviewContentStatus === SUCCEEDED && rotateImgStatus === NONE) ||
					(reviewContentStatus === NONE && rotateImgStatus === SUCCEEDED) ||
					(reviewContentStatus === SUCCEEDED && rotateImgStatus === SUCCEEDED)
				) {
					message.success(t('content_management.notification.review_success'));
					setHideMessage(true);
					setIsMoreAction(false);
				}
			} else {
				if (reviewContentStatus === SUCCEEDED && reviewedContent) {
					message.success(t('content_management.notification.review_success'));
				}
				if (reviewContentStatus === FAILED || reviewContentStatus === SUCCEEDED) {
					setHideMessage(true);
				}
			}
		}
	}, [reviewContentStatus, rotateImgStatus]);

	useEffect(() => {
		if (creatorBlockStatus === SUCCEEDED) {
			if (onChangeAfterBlock && currentMedia) {
				if (typeConfirm === ACTIONS.UNBLOCK) {
					onChangeAfterBlock(currentMedia, false, false);
				} else {
					onChangeAfterBlock(currentMedia, true, isBlockDelete);
				}
			}
		}

		if (creatorBlockStatus === FAILED || creatorBlockStatus === SUCCEEDED) {
			dispatch(creatorBlockEnd());
			setIsBlockDelete(false);
		}
	}, [creatorBlockStatus]);

	useEffect(() => {
		if (
			verificationScore &&
			verificationScore.contentId === currentMedia?.id &&
			updateVerificationScrore &&
			currentMedia
		) {
			updateVerificationScrore(currentMedia, verificationScore);
		}
	}, [verificationScore]);

	useEffect(() => {
		if (getTermsConditionsStatus && getTermsConditionsStatus === SUCCEEDED && termsConditions) {
			downloadFile(termsConditions, `${currentMedia?.name}'s Terms Info.pdf`);
			setIsLoading(false);
			message.success(t('content_management.download.success'));
			dispatch(getTermsConditionsEnd());
		}

		if (getTermsConditionsStatus === FAILED || getTermsConditionsStatus === SUCCEEDED) {
			setIsLoading(false);
		}
	}, [getTermsConditionsStatus]);

	useEffect(() => {
		if (currentMedia && versionImgSelected?.id === DEFAULT_VERSION_ID && !contentDetection) {
			getMediaDetection(currentMedia);
		}
	}, [currentMedia?.id, versionImgSelected, contentDetection]);

	useEffect(() => {
		if (currentMedia?.id) {
			const onKeydown = (e: KeyboardEvent) => {
				if (e.altKey && e.shiftKey) {
					const container: any = document.getElementById('body_content_detail');
					const onHandleKeyStar = (star: number | null) => {
						const elmStar: any = document.getElementById('star_detail_content');

						if (elmStar) {
							elmStar.scrollIntoViewIfNeeded(true);
							elmStar.blur();
						}
						const activeElement = document.activeElement;
						const inputs = ['input', 'select', 'button', 'textarea', 'video'];
						const isFocusElement =
							activeElement &&
							inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;
						if (isFocusElement) {
							(document.activeElement as any).blur();
						}
						form.setFieldsValue({ star });
					};

					if (container) {
						if (e.code === KEYBOARD.KEY_N.STR) {
							funcSelectField('note_content_detail', '');
						}
					}

					switch (e.code) {
						case KEYBOARD.KEY_0.STR:
							onHandleKeyStar(null);
							break;
						case KEYBOARD.KEY_1.STR:
							onHandleKeyStar(1);
							break;
						case KEYBOARD.KEY_2.STR:
							onHandleKeyStar(2);
							break;
						case KEYBOARD.KEY_3.STR:
							onHandleKeyStar(3);
							break;
						case KEYBOARD.KEY_4.STR:
							onHandleKeyStar(4);
							break;
						case KEYBOARD.KEY_5.STR:
							onHandleKeyStar(5);
							break;
						case KEYBOARD.KEY_S.STR:
							if (container) {
								funcSelectField(
									'status_content_detail',
									FORM_CONTENT_DETAIL.STATUS,
								);
							}

							break;
						case KEYBOARD.KEY_C.STR:
							if (container) {
								funcSelectField(
									'campaigns_content_detail',
									FORM_CONTENT_DETAIL.CAMPAIGNS,
								);
							}

							break;
						case KEYBOARD.KEY_F.STR:
							setCollapseTerm(true);
							if (container) {
								funcSelectFlagField(!collapseTerm);
							}

							break;
						case KEYBOARD.KEY_L.STR:
							if (container) {
								funcSelectField('tags_content_detail', FORM_CONTENT_DETAIL.TAGS);
							}
							break;

						default:
							break;
					}
				}
			};
			window.addEventListener('keydown', onKeydown);
			return () => window.removeEventListener('keydown', onKeydown);
		}
		return () => {};
	}, [currentMedia?.id, collapseTerm, fieldSelect]);

	const getMediaDetection = (content: ContentDto) => {
		const { id, detected } = content;
		if (detected) {
			dispatch(getMediaDetectionRequest({ contentId: id }));
		}
	};

	const handleResetPublishList = () => {
		const galleriesChange: number[] =
			reviewedContent?.galleries?.map((item: string | number) => +item) || [];
		const galleriesBefore: number[] =
			currentMedia?.review?.galleries?.map((item) => +item) || [];
		let hasChange = galleriesChange?.length !== galleriesBefore?.length;
		galleriesBefore?.forEach((item: number) => {
			if (!galleriesChange?.includes(item)) {
				hasChange = true;
			}
		});

		if (hasChange) {
			setParamPublish((prev: any) => ({ ...prev, page: 1 }));
		}
	};

	return <></>;
};

export default EffectFunc;
