import { IconAdd } from '@assets/icons';
import { STATUSCODE } from '@constants/APIs';
import { ACTION_ON_MESSAGE, EMAIL_TEMPLATE_FOLDER_TYPES, emailTemplate } from '@constants/messages';
import { FAILED } from '@constants/status';
import { ConfirmModal, Empty } from '@cores/index';
import { getUser } from '@helpers/userHelpers';
import { EmailTemplateItemDto, EmailTemplatesDataDto } from '@models/messages/summary';
import {
	createEmailTemplatesRequest,
	getDynamicFieldsRequest,
	getEmailTemplatesRequest,
	selectCustomEmailTemplate,
	storeMessageTemplateData,
	updateEmailTemplatesRequest,
} from '@stores/actions';
import {
	StyledEmpty,
	StyledText,
	StyledWrapper,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledButtonAdd,
	StyledCenterContainer,
	StyledForm,
	StyledFormItem,
	StyledInput,
} from '@styled/Messages/EmailTemplatesStyled';
import { Form, message } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditTemplateModal from '../../EditTemplateModal';
import MenuTemplates from '../MenuTemplates';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';

const Custom: React.FC = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [isRequired, setIsRequired] = useState<boolean>(true);
	const [visible, setVisible] = useState<boolean>(false);
	const [payloadArchived, setPayloadArchived] = useState<any>({});
	const [titleTemplate, setTitleTemplate] = useState<string>('');
	const [template, setTemplate] = useState<EmailTemplatesDataDto>(emailTemplate);
	const [modalType, setModalType] = useState<string>('');

	const ACTION_ON_TEMPLATE = {
		ARCHIVE: 'ARCHIVE',
		CREATE: 'CREATE',
	};

	const {
		emailTemplate: {
			getEmailTemplateStatus = false,
			custom: {
				templates = [],
				createTemplateStatus = 0,
				createTemplateLoading = false,
				editTemplateSuccessfully = 0,
				isArchived = false,
			} = {},
			error = null,
		} = {},
		actionType = '',
	} = useSelector((state: any) => state.messages);
	const { t } = useTranslation();

	useEffect(() => {
		if (editTemplateSuccessfully === STATUSCODE.SUCCESS) {
			if (isArchived) {
				message.success(t('email_templates.notification.archived_successfully'));
			} else {
				message.success(t('email_templates.notification.save_changes_successfully'));
				setVisible(false);
			}
			dispatch(getEmailTemplatesRequest({ type: EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM }));
			setModalType('');
		}
	}, [editTemplateSuccessfully]);

	useEffect(() => {
		if (createTemplateStatus === FAILED || createTemplateStatus === STATUSCODE.SUCCESS) {
			if (createTemplateStatus === STATUSCODE.SUCCESS) {
				if (actionType && actionType === ACTION_ON_MESSAGE.DUPLICATE) {
					message.success(
						t('email_templates.notification.duplicate_template_successfully'),
					);
				} else {
					message.success(t('email_templates.notification.create_template_successfully'));
				}
			}

			form.resetFields();
			setModalType('');
			dispatch(
				storeMessageTemplateData({
					actionType: '',
				}),
			);
			dispatch(getEmailTemplatesRequest({ type: EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM }));
		}
	}, [createTemplateStatus]);

	useEffect(() => {
		if (error) {
			setModalType('');
		}
	}, [error]);

	useEffect(() => {
		updateTemplateStateAfterEdit();
	}, [getEmailTemplateStatus]);

	const updateTemplateStateAfterEdit = () => {
		const findTemplate = templates.find(
			(item: EmailTemplatesDataDto) => item.id === template.id,
		);

		if (findTemplate) {
			setTemplate(findTemplate);
		}
	};

	const onSelectItem = (selectId: number) => {
		dispatch(selectCustomEmailTemplate({ selectId }));
	};

	const onConfirmFinish = ({ title }: { title: string }) => {
		setModalType(ACTION_ON_TEMPLATE.CREATE);
		setTitleTemplate(title);
	};

	const onSubmitCreateTemplate = (params?: EmailTemplateItemDto) => {
		const { email = '' } = getUser();
		const payload = params || {
			title: titleTemplate,
			type: EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM,
			from: email,
		};

		dispatch(createEmailTemplatesRequest(payload));
	};

	const onEdit = (id: number) => {
		const findTemplate = templates.find(
			(template: EmailTemplatesDataDto) => template.id === id,
		);
		setTemplate(findTemplate);
		dispatch(getDynamicFieldsRequest());
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const onClickAction = (data: EmailTemplatesDataDto, action: string) => {
		const payloadArchived = {
			...data,
			type: EMAIL_TEMPLATE_FOLDER_TYPES.ARCHIVED,
			typeOrigin: EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM,
		};

		switch (action) {
			case 'archived':
				setModalType(ACTION_ON_TEMPLATE.ARCHIVE);

				setPayloadArchived(payloadArchived);
				break;
			default:
				break;
		}
	};

	const onSubmitArchived = () => {
		dispatch(updateEmailTemplatesRequest(payloadArchived));
	};

	const renderConfirmModal = () => {
		let content: ReactNode = <></>;
		let onSubmit: () => void;
		let okText = t('button.yes');
		let confirmLoading;
		const isOpen: boolean =
			modalType === ACTION_ON_TEMPLATE.CREATE || modalType === ACTION_ON_TEMPLATE.ARCHIVE;

		if (modalType === ACTION_ON_TEMPLATE.ARCHIVE) {
			content = t('email_templates.confirm.archived_template');
			onSubmit = onSubmitArchived;
			confirmLoading = getEmailTemplateStatus;

			if (payloadArchived && payloadArchived?.campaignsName.length) {
				content = (
					<StyledWrapperContent textAlign="left">
						{t('email_templates.confirm.archived_template_assigned_campaign')}
						<StyledWrapperContent padding="8px 24px" maxHeight="200px" overflow="auto">
							{payloadArchived?.campaignsName?.map((item: string, index: number) => (
								<StyledText key={Math.random()} margin="0 0 4px 0">{`${
									index + 1
								}. ${item}`}</StyledText>
							))}
						</StyledWrapperContent>
						{t('content.widget_manager.popup.are_you_sure_proceed')}
					</StyledWrapperContent>
				);
				okText = t('button.archive_template');
			}
		}

		if (modalType === ACTION_ON_TEMPLATE.CREATE) {
			content = t('email_templates.confirm.create_template');
			onSubmit = onSubmitCreateTemplate;
			confirmLoading = createTemplateLoading;
		}

		return (
			<ConfirmModal
				width={400}
				confirmLoading={confirmLoading}
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => {
					setModalType('');
				}}>
				{content}
			</ConfirmModal>
		);
	};

	return (
		<StyledWrapperContent height="100%">
			<StyledForm isCustom={true} form={form} onFinish={onConfirmFinish}>
				<StyledFormItem
					className="email-template-input"
					label={t('email_templates.create_new_template')}
					name="title"
					rules={[
						{
							required: isRequired,
							validateTrigger: 'onsubmit',
							message: t('email_templates.validation.please_input_new_template'),
						},
					]}>
					<StyledInput
						placeholder={t('email_templates.create_new_template_placeholder')}
					/>
				</StyledFormItem>
				<StyledFormItem width="fit-content">
					<StyledButtonAdd
						onClick={() => {
							const isEmpty = form.getFieldValue('title') === undefined;
							if (isEmpty) {
								setIsRequired(true);
								setTimeout(() => {
									setIsRequired(false);
									form.validateFields(['title']);
								}, 2000);
							}
						}}
						htmlType="submit"
						icon={<IconAdd />}
					/>
				</StyledFormItem>
			</StyledForm>
			{templates.length > 0 ? (
				<MenuTemplates
					onEdit={onEdit}
					onClickAction={onClickAction}
					dataTemplate={templates}
					onSelectItem={onSelectItem}
					isCustom={true}
					onCreateTemplate={onSubmitCreateTemplate}
				/>
			) : (
				<StyledCenterContainer>
					<Empty />
				</StyledCenterContainer>
			)}
			<EditTemplateModal visible={visible} template={template} handleCancel={handleCancel} />
			{renderConfirmModal()}
		</StyledWrapperContent>
	);
};

export default Custom;
