import { Collapse } from 'antd';
import * as React from 'react';

// Icon

// Types

// Styled
import { FAILED, SUCCEEDED } from '@constants/status';
import { updateCreatorProfileEnd, updateCreatorProfileRequest } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import { StyledTextArea } from '@styled/ContentLibrary/ContentDetailStyled';
import { StyledCollapse } from '@styled/Creators/ContactCardStyled';
import { StyledContactBlock, StyledHeader } from '@styled/Creators/NotesStyled';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { Panel } = Collapse;

const Notes = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { updateCreatorProfileStatus, creatorProfile }: UserStoreType = useSelector(
		(state: any) => state.creator,
	);
	const { additionalInfo = '' } = creatorProfile || {};

	const refTextarea = React.useRef<any | null>(null);

	const [noteVal, setNoteVal] = React.useState<string>(additionalInfo);

	React.useEffect(() => {
		const textareaElm = refTextarea?.current?.resizableTextArea?.textArea;

		const isUpdate = !textareaElm || !textareaElm?.contains(document.activeElement);

		if (isUpdate) {
			if (noteVal && additionalInfo) {
				if (noteVal !== additionalInfo) {
					setNoteVal(additionalInfo?.trim() || '');
				}
			} else {
				setNoteVal(additionalInfo || '');
			}
		}
	}, [additionalInfo]);

	const onCheckClickOutside = (e: any) => {
		const textareaElm = refTextarea?.current?.resizableTextArea?.textArea;

		const targetElement = e.target;
		if (!targetElement.isConnected) return;
		if (textareaElm && textareaElm.contains(targetElement)) {
			return;
		}

		handleSaveNote(noteVal);
	};

	React.useEffect(() => {
		if (refTextarea?.current) {
			window.addEventListener('click', onCheckClickOutside);
			return () => {
				window.removeEventListener('click', onCheckClickOutside);
			};
		}
		return () => {};
	}, [additionalInfo, noteVal]);

	React.useEffect(() => {
		if (updateCreatorProfileStatus === SUCCEEDED || updateCreatorProfileStatus === FAILED) {
			dispatch(updateCreatorProfileEnd());
		}
	}, [updateCreatorProfileStatus]);

	const handleSaveNote = (val: string) => {
		if (additionalInfo?.trim() !== val?.trim()) {
			dispatch(
				updateCreatorProfileRequest({
					creatorId: creatorProfile?.id,
					skipRes: true,
					dataRequest: { additionalInfo: val?.trim() },
				}),
			);
		}
	};

	const onChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setNoteVal(e.target.value);
	};

	const onPressEnter = (e: any) => {
		handleSaveNote(e.target.value);
	};

	const renderHeader = () => <StyledHeader>{t('creator_profile.notes.header')}</StyledHeader>;
	return (
		<StyledContactBlock>
			<StyledCollapse bordered={false} expandIconPosition="right">
				<Panel header={renderHeader()} key="1">
					<StyledTextArea
						value={noteVal}
						ref={refTextarea}
						onPressEnter={onPressEnter}
						onChange={onChangeNote}
						autoSize={{ minRows: 6, maxRows: 6 }}
						rows={6}
					/>
				</Panel>
			</StyledCollapse>
		</StyledContactBlock>
	);
};

export default React.memo(Notes);
