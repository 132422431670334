import { IconClose, IconCopy } from '@assets/icons';
import CustomSwitch from '@components/CustomSwitch';
import { guestPortalURL } from '@config/index';
import { PATTERNS } from '@constants/common';
import { GUEST_PORTAL_FIELD_NAME, GUEST_PORTAL_MODAL_TYPE } from '@constants/settings/guestPortal';
import { IN_PROGRESS } from '@constants/status';
import THEME from '@constants/themes/themes';
import ButtonGroup from '@cores/ButtonGroup';
import LoadingWrapper from '@cores/LoadingWrapper';
import { AlbumSuggestDto } from '@models/content/albums/albumManager';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import {
	GuestPortalItemDto,
	GuestPortalStoreDto,
	GuestTabConfigurationDto,
} from '@models/settings/guestPortal';
import { createGuestPortalRequest, updateGuestPortalRequest } from '@stores/actions';
import {
	StyledButton,
	StyledCol,
	StyledDivider,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledIcon,
	StyledInput,
	StyledModal,
	StyledRadioGroup,
	StyledRow,
	StyledSelect,
	StyledSpace,
	StyledText,
	StyledTextArea,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { validateInputToPattern } from '@utils/common';
import { getPathnameFromURL } from '@utils/funcHelper';
import { message, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import _, { isUndefined } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import GuestPortalStatus from '../GuestPortalStatus';

type GuestPortalDetailModalProps = {
	guestPortalDetail?: any;
	onCancel: () => void;
	visible: boolean;
	setConfirmationModalType?: (type: string) => void;
	handleCopiedClipboardMessage: (utl: string) => void;
};

const GuestPortalDetailModal = forwardRef((props: GuestPortalDetailModalProps, ref: any) => {
	const {
		guestPortalDetail,
		onCancel,
		visible,
		setConfirmationModalType,
		handleCopiedClipboardMessage,
	} = props;
	const { t } = useTranslation();
	const [form] = useForm();
	const dispatch = useDispatch();
	const { createGuestPortalStatus, updateGuestPortalStatus }: GuestPortalStoreDto = useSelector(
		(state: any) => state.guestPortal,
	);
	const { albumListLiteResult = [] }: AlbumsStoreDto = useSelector(
		(state: any) => state.albumManager,
	);
	const isLoading = createGuestPortalStatus === IN_PROGRESS;

	const [currentGuestPortal, setCurrentGuestPortal] = useState<GuestPortalItemDto>(
		_.cloneDeep(guestPortalDetail),
	);

	useImperativeHandle(ref, () => ({
		publishChanges: form.submit,
	}));

	useEffect(() => {
		if (guestPortalDetail) {
			setCurrentGuestPortal(_.cloneDeep(guestPortalDetail));
			initValues(guestPortalDetail);
		}
	}, [guestPortalDetail]);

	const initValues = (data: GuestPortalItemDto) => {
		const mediaHubConfig =
			data.metadata?.[GUEST_PORTAL_FIELD_NAME.TAB_CONFIGURATION]?.[
				GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB
			];
		const otherValues = {
			url: getPathnameFromURL(data.url),
			isAllAlbums: isUndefined(mediaHubConfig?.[GUEST_PORTAL_FIELD_NAME.IS_ALL_ALBUMS])
				? true
				: mediaHubConfig?.[GUEST_PORTAL_FIELD_NAME.IS_ALL_ALBUMS],
			selectedAlbums: mediaHubConfig?.[GUEST_PORTAL_FIELD_NAME.SELECTED_ALBUMS] || [],
		};

		form.setFieldsValue({ ...data, ...otherValues });
	};

	const isAddingNewGuestPortal = useMemo(() => {
		return !currentGuestPortal.id;
	}, [currentGuestPortal]);

	const renderTitle = () => {
		return isAddingNewGuestPortal ? (
			<StyledText fontSize="20px">
				{t('setting.guest_portal.create_a_guest_portal')}
			</StyledText>
		) : (
			<StyledFlex>
				<StyledText fontSize="20px">
					{t('setting.guest_portal.configure_a_guest_portal')}
				</StyledText>

				<StyledWrapperContent display="flex">
					<GuestPortalStatus
						guestPortalDetail={currentGuestPortal}
						styles={{ margin: '0 18px 0 0' }}
						setConfirmationModalType={setConfirmationModalType}
					/>
					<StyledIcon onClick={onCancel} size={20}>
						<IconClose />
					</StyledIcon>
				</StyledWrapperContent>
			</StyledFlex>
		);
	};

	const renderFooter = () => {
		return isAddingNewGuestPortal ? (
			<>
				<StyledButton onClick={onCancel}>{t('button.cancel')}</StyledButton>
				<StyledButton loading={isLoading} onClick={onSaveChanges} type="primary">
					{t('button.publish')}
				</StyledButton>
			</>
		) : (
			<ButtonGroup
				isLoading={
					updateGuestPortalStatus === IN_PROGRESS &&
					currentGuestPortal.url === guestPortalDetail.url
				}
				hasChanges={
					!_.isEqual(
						JSON.stringify(guestPortalDetail),
						JSON.stringify(currentGuestPortal),
					)
				}
				onSubmit={onSaveChanges}
				onCancel={() => {
					const resetValues = _.cloneDeep(guestPortalDetail);
					setCurrentGuestPortal(resetValues);
					initValues(resetValues);
				}}
			/>
		);
	};

	const formatOptions = (list: AlbumSuggestDto[]) => {
		return list.map((item) => ({
			label: item.name,
			value: item.id,
		}));
	};

	const renderTabConfiguration = () => {
		const {
			metadata: {
				tabConfiguration: {
					creatorsTab: { status: creatorsTabStatus = false } = {},
					mediaHubTab: { isAllAlbums = true, status: mediaHubTabStatus = false } = {},
				} = {},
			} = {},
		} = currentGuestPortal || {};
		const mediaLibraryOptions = [
			{
				label: t('setting.guest_portal.show_all_public_albums'),
				value: true,
			},
			{
				label: t('setting.guest_portal.show_selected_album'),
				value: false,
			},
		];

		const tabList = [
			{
				id: 1,
				label: t('setting.guest_portal.creators_tab'),
				description: t('setting.guest_portal.creators_tab_desc'),
				name: GUEST_PORTAL_FIELD_NAME.CREATORS_TAB,
				checked: creatorsTabStatus,
			},
			{
				id: 2,
				label: t('setting.guest_portal.media_library'),
				description: t('setting.guest_portal.media_library_desc'),
				name: GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB,
				checked: mediaHubTabStatus,
				children: (
					<StyledWrapperContent padding="6px 0">
						<StyledFormItem
							margin="4px 0 6px 0"
							name={GUEST_PORTAL_FIELD_NAME.IS_ALL_ALBUMS}>
							<StyledRadioGroup>
								<StyledSpace gap="0" direction="vertical">
									{mediaLibraryOptions?.map((item) => {
										return (
											<Radio key={item.label} value={item.value}>
												{item?.label}
											</Radio>
										);
									})}
								</StyledSpace>
							</StyledRadioGroup>
						</StyledFormItem>
						<StyledFormItem
							margin="0"
							label={t('setting.guest_portal.included_albums')}
							name={GUEST_PORTAL_FIELD_NAME.SELECTED_ALBUMS}
							rules={[
								{
									required: !isAllAlbums,
									message: t('message.required', {
										field: t('setting.guest_portal.included_albums'),
									}),
								},
							]}
							isDisabled={isAllAlbums}>
							<StyledSelect
								mode="multiple"
								options={formatOptions(albumListLiteResult)}
								showArrow
								showSearch
								optionFilterProp="label"
							/>
						</StyledFormItem>
					</StyledWrapperContent>
				),
				otherProps: {
					className: !mediaHubTabStatus ? 'disabled' : '',
				},
			},
		];

		return tabList.map((tabItem, index) => {
			return (
				<StyledWrapperContent id={tabItem.name}>
					<StyledFlex justify="flex-start" align="center">
						<StyledText margin="0 12px 0 0">{tabItem.label}</StyledText>
						<CustomSwitch
							checked={tabItem.checked}
							setChecked={(value: boolean) => {
								changeTabStatus(value, tabItem.name);
							}}
							text={{ on: t('button.show'), off: t('button.hide') }}
							otherSwitchProps={{
								width: '40px',
								toggleWidth: '45px',
								totalWidth: '80px',
							}}
						/>
					</StyledFlex>
					<StyledWrapperContent margin="4px 0 0 0" {...tabItem?.otherProps}>
						<StyledText fontWeight={400} color={THEME.colors.gray3}>
							{tabItem.description}
						</StyledText>
						{tabItem.children}
					</StyledWrapperContent>
					{index !== tabList.length - 1 && <StyledDivider />}
				</StyledWrapperContent>
			);
		});
	};

	const changeTabStatus = (value: boolean, fieldName: string) => {
		const guestPortalTemp = { ...currentGuestPortal };
		const metadata = guestPortalTemp[GUEST_PORTAL_FIELD_NAME.METADATA];
		const tabConfiguration: GuestTabConfigurationDto =
			metadata[GUEST_PORTAL_FIELD_NAME.TAB_CONFIGURATION] || {};
		const previousValues = tabConfiguration?.[fieldName] || {};
		tabConfiguration[fieldName] = { ...previousValues, status: value };

		if (fieldName === GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB) {
			tabConfiguration[fieldName][GUEST_PORTAL_FIELD_NAME.SELECTED_ALBUMS] = [];
			tabConfiguration[fieldName][GUEST_PORTAL_FIELD_NAME.IS_ALL_ALBUMS] = true;

			if (!value) {
				form.setFieldsValue({
					[GUEST_PORTAL_FIELD_NAME.SELECTED_ALBUMS]: [],
					[GUEST_PORTAL_FIELD_NAME.IS_ALL_ALBUMS]: true,
				});
			}
		}

		metadata.tabConfiguration = { ...tabConfiguration }; // In case existing metadata is empty
		setCurrentGuestPortal({ ...guestPortalTemp, metadata });
	};

	const onFinish = () => {
		const payload = _.cloneDeep({ ...currentGuestPortal }) as any;

		payload.metadata = JSON.stringify(payload.metadata);

		if (payload.url) {
			payload.url = payload.url.toLowerCase();
		}

		if (isAddingNewGuestPortal) {
			dispatch(createGuestPortalRequest({ ...payload }));
		} else {
			dispatch(
				updateGuestPortalRequest({ id: payload?.id, params: { ...payload, status: null } }),
			);
		}
	};

	const onValuesChange = (changedValues: any) => {
		const fieldName = Object.keys(changedValues)[0];
		const currentGuestPortalTemp = { ...currentGuestPortal, ...changedValues };
		const metadata = currentGuestPortalTemp[GUEST_PORTAL_FIELD_NAME.METADATA];
		const tabConfig = metadata[GUEST_PORTAL_FIELD_NAME.TAB_CONFIGURATION];

		if (fieldName === GUEST_PORTAL_FIELD_NAME.URL) {
			currentGuestPortalTemp.url = `${guestPortalURL}/${changedValues[fieldName]}`;
		}

		if (
			fieldName === GUEST_PORTAL_FIELD_NAME.IS_ALL_ALBUMS ||
			fieldName === GUEST_PORTAL_FIELD_NAME.SELECTED_ALBUMS
		) {
			tabConfig[GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB] = {
				...tabConfig[GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB],
				[fieldName]: changedValues[fieldName],
			};
			if (
				fieldName === GUEST_PORTAL_FIELD_NAME.IS_ALL_ALBUMS &&
				changedValues[fieldName] &&
				tabConfig[GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB][
					GUEST_PORTAL_FIELD_NAME.SELECTED_ALBUMS
				]
			) {
				form.setFieldsValue({
					[GUEST_PORTAL_FIELD_NAME.SELECTED_ALBUMS]: [],
				});
				tabConfig[GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB][
					GUEST_PORTAL_FIELD_NAME.SELECTED_ALBUMS
				] = [];
			}
			delete currentGuestPortalTemp[fieldName];
		}

		setCurrentGuestPortal({ ...currentGuestPortalTemp, metadata });
	};

	const onSaveChanges = () => {
		const { metadata: { tabConfiguration = {} } = {} } = currentGuestPortal || {};
		// At least one tab is turned on
		const isAllTabsTurnedOff =
			!tabConfiguration ||
			Object.keys(tabConfiguration).every((key) => {
				return !tabConfiguration[key].status;
			});

		if (isAllTabsTurnedOff) {
			message.info(t('setting.guest_portal.message.at_least_one_tab'));
			return;
		}

		if (
			currentGuestPortal.url !== guestPortalDetail.url &&
			setConfirmationModalType &&
			!isAddingNewGuestPortal
		) {
			setConfirmationModalType(GUEST_PORTAL_MODAL_TYPE.CHANGING_URL);
			return;
		}

		form.submit();
	};

	return (
		<StyledModal
			title={renderTitle()}
			width={688}
			visible={visible}
			centered
			footer={renderFooter()}
			onCancel={onCancel}
			closable={isAddingNewGuestPortal}
			minHeightFooter="57px"
			zIndex={1000}
			maskClosable={updateGuestPortalStatus !== IN_PROGRESS}>
			<LoadingWrapper
				sizeLoading="medium"
				isLoading={updateGuestPortalStatus === IN_PROGRESS}
				bgColorLoading={THEME.colors.transparent}>
				<StyledForm
					layout="vertical"
					form={form}
					requiredMark={false}
					onFinish={onFinish}
					onValuesChange={onValuesChange}>
					<StyledRow gutter={[12, 12]}>
						<StyledCol span={8}>
							<StyledFormItem
								label={t('setting.guest_portal.name')}
								name={GUEST_PORTAL_FIELD_NAME.PORTAL_NAME}
								rules={[
									{
										required: true,
										message: t('message.required', {
											field: t('setting.guest_portal.name'),
										}),
									},
								]}>
								<StyledInput
									placeholder={t('setting.guest_portal.placeholder.name')}
								/>
							</StyledFormItem>
						</StyledCol>
						<StyledCol span={16}>
							<StyledFormItem
								label={t('setting.guest_portal.description_optional')}
								name={GUEST_PORTAL_FIELD_NAME.DESCRIPTION}>
								<StyledTextArea
									rows={1}
									maxHeight="100px"
									placeholder={t('setting.guest_portal.placeholder.description')}
								/>
							</StyledFormItem>
						</StyledCol>
					</StyledRow>

					<StyledSpace direction="horizontal" align="center">
						<StyledText>{`${t('setting.guest_portal.url')}:`}</StyledText>
						<StyledText>{`${guestPortalURL}/`}</StyledText>
						<StyledFormItem margin="2px 0 0 0" name={GUEST_PORTAL_FIELD_NAME.URL}>
							<StyledInput
								onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
									validateInputToPattern(event, PATTERNS.ALPHABETS_NUMBER);
								}}
							/>
						</StyledFormItem>
						<StyledIcon
							size={22}
							margin="8px 0 0 0"
							onClick={() => {
								if (guestPortalDetail.url) {
									handleCopiedClipboardMessage(guestPortalDetail.url);
								}
							}}>
							<IconCopy />
						</StyledIcon>
					</StyledSpace>

					<StyledDivider />

					{renderTabConfiguration()}
				</StyledForm>
			</LoadingWrapper>
		</StyledModal>
	);
});

export default GuestPortalDetailModal;
