import THEME from '@constants/themes/themes';
import { StyledIconWrapper, StyledText } from '@styled/Common/CommonStyled';
import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

const StyledAlbumModal = styled(Modal)`
	.ant-modal-title {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: ${THEME.colors.gray5};
	}
	.ant-modal-body {
		padding: 22px 22px 6px;
	}
	.ant-modal-footer {
		border: 0;
		padding: 22px;
	}
`;

const StyledFormItem = styled(Form.Item)<{ margin?: string; showRequired?: boolean }>`
	margin: ${({ margin }) => margin || '0 0 12px 0'};
	display: block;
	&.form-item_album {
		margin: 24px 0 0 0;
		&:first-child {
			margin: 0;
		}
	}
	.ant-form-item-label {
		height: 22px;
		padding: 0;
		margin: 0 0 6px 0;

		label {
			width: 100%;
		}
		& > label {
			&.ant-form-item-required {
				&:not(.ant-form-item-required-mark-optional) {
					&::before {
						display: none;
					}
					&::after {
						display: ${({ showRequired }) => (showRequired ? 'inline-block' : 'none')};
						color: ${THEME.colors.redBase};
						font-size: 14px;
						font-family: SimSun, sans-serif;
						line-height: 1;
						content: '*';
					}
				}
			}
		}
	}
	&.unset_height {
		.ant-form-item-label {
			height: unset;
			label {
				height: unset;
			}
		}
	}
	&.label_full {
		.ant-form-item-label {
			width: 100%;
		}
	}
`;

const StyledWrapperLabel = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
	.ant-checkbox-wrapper {
		width: auto;
		margin: 0 !important;
	}
	&.width_full {
		width: 100%;
	}
`;

const StyledLabelField = styled.label<{ required?: boolean }>`
	font-weight: 700;
	font-size: 12px;
	line-height: 20px;
	position: relative;
	color: ${THEME.colors.gray5};

	&::after {
		display: ${({ required }) => (required ? 'inline-block' : 'none')};
		bottom: 3px;
		color: ${THEME.colors.redBase};
		position: absolute;
		margin-left: 3px;
		font-size: 14px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: '*';
	}
`;

const StyledInput = styled(Input)`
	&::placeholder {
		color: ${THEME.colors.gray1};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}
`;

const StyledTextArea = styled(TextArea)`
	border: 1px solid #344552;
	border-radius: 2px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	&::placeholder {
		color: ${THEME.colors.gray1};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}
`;

const StyledWrapperAction = styled.div<{ margin?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	${({ margin }) => margin && `margin: ${margin}`}
`;

const StyledAction = styled.div`
	display: inline-flex;
	align-items: center;
	.ant-btn {
		&:not(.ant-btn-primary) {
			&:hover {
				border-color: ${THEME.colors.orangeBase};
				color: ${THEME.colors.orangeBase};
			}
		}
	}
`;

const StyledInfoMoreSmart = styled.div`
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;
	color: ${THEME.colors.white};
`;

const StyledSubContent = styled.div`
	margin-top: 8px;
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;
	color: ${THEME.colors.gray5};
`;

const StyledWrapperContentConfirm = styled.div`
	color: ${THEME.colors.gray5};
`;

const StyledChosenFilterWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	border: 1px solid ${THEME.colors.darkBlue4};
	border-radius: 2px;
	height: 32px;
	padding: 0 11px;
	text-overflow: ellipsis;
	overflow: hidden;
	&.disabled {
		pointer-events: none;
	}

	${StyledIconWrapper} {
		position: absolute;
		right: 10px;
		top: 52%;
		transform: translateY(-50%);
	}

	${StyledText} {
		width: 95%;
		display: inline-block;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export {
	StyledAction,
	StyledAlbumModal,
	StyledChosenFilterWrapper,
	StyledFormItem,
	StyledInfoMoreSmart,
	StyledInput,
	StyledLabelField,
	StyledSubContent,
	StyledTextArea,
	StyledWrapperAction,
	StyledWrapperContentConfirm,
	StyledWrapperLabel,
};
