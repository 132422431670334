import { Button, Col, Form, Input, Modal, Row, Select, message } from 'antd';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// Models
import { CreatorDetailProps } from '@models/creator/segments';

// Context
import CreatorSummaryContext from '@contexts/Creators/Summary';

// API
import { postRequest, putRequest } from '@helpers/requestHelpers';

// Redux
import {
	creatorFindProfiles,
	creatorGetProfile,
	resetSelectedRows,
	storeCreatorData,
} from '@stores/actions';

// Constants
import { CREATOR_API_URL } from '@constants/APIs';
import { MESSAGE_TYPE, PATTERNS } from '@constants/common';
import { BASIC_CREATOR_INFO_FIELD, SOCIAL_CREATOR_INFO_FIELD } from '@constants/creator';
import { DEFAULT_ERROR } from '@constants/errors';
import { SOCIAL_PROFILE } from '@constants/social_search';
import THEME from '@constants/themes/themes';
import { LoadingWrapper } from '@cores/index';
import { validateRequiredValue } from '@utils/creator';
import { dataState } from '@utils/dataStateUSA';
import { destroyGeneralMessage, generalMessage } from '@utils/renderComponent';
import { StyledModal, StyledWrapperContent } from '@styled/Common/CommonStyled';

const { Option } = Select;

const StyledForm = styled(Form)`
	.ant-form-item {
		flex-direction: column;
		margin-bottom: 12px;
	}
	.ant-form-item-label {
		padding: 0;
	}
	.ant-form-item-explain {
		position: absolute;
		right: 0;
	}
`;

const StyledInput = styled(Input)`
	height: 32px;
	&.ant-input-disabled {
		background-color: ${THEME.colors.darkBlue2};
	}
`;

const StyledSelect = styled(Select)`
	margin-right: 8px;
	width: 160px;

	.ant-select-selector {
		background-color: transparent !important;
	}
`;

const CreatorDetailModal = ({
	visible,
	onClose,
	modalType,
	creatorData,
	callback,
	isFetching,
}: CreatorDetailProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { paramSummaryContact: requestDataFindProfiles } = useContext(CreatorSummaryContext);
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [validateFB, setValidateFB] = useState<boolean>(false);
	const [nameState, setNameState] = useState<string>('');
	const [listCity, setListCity] = useState<string[]>([]);

	useEffect(() => {
		return () => {
			destroyGeneralMessage();
		};
	}, []);

	useEffect(() => {
		if (modalType === 'edit') {
			const { address, ...otherData } = creatorData;
			form.setFieldsValue(otherData);
			let dataAddress: any = '';
			try {
				dataAddress = JSON.parse(address);
			} catch (error) {
				dataAddress = address;
			}

			if (typeof dataAddress === 'object') {
				form.setFieldsValue(dataAddress);
				if (dataAddress?.state) {
					const newListCity =
						dataState?.find((item: any) => item?.state === dataAddress?.state)?.city ||
						[];

					setListCity(newListCity);
				}
			} else {
				form.setFieldsValue({ street: dataAddress });
			}
		}
		if (!visible) {
			handleReset();
		}
	}, [creatorData, visible]);

	useEffect(() => {
		if (nameState) {
			const newListCity =
				dataState?.find((item: any) => item?.state === nameState)?.city || [];
			setListCity(newListCity);
			form.setFieldsValue({ city: '' });
		}
	}, [nameState]);

	const handleCreateCreator = (params: any) => {
		const url = `${CREATOR_API_URL}/create`;
		setIsLoading(true);
		postRequest(url, params)
			.then((response) => {
				if (response.status === 200) {
					message.success(t('creator_summary.modal.add_creator_success'));
					dispatch(creatorFindProfiles(requestDataFindProfiles));
					handleCancel();
				} else {
					generalMessage(response.body.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				message.error(DEFAULT_ERROR);
			});
	};

	const handleEditCreator = (params: any) => {
		const url = `${CREATOR_API_URL}/${creatorData.clientId}`;
		const getProfileParams = {
			creatorId: creatorData.clientId,
		};
		setIsLoading(true);
		putRequest(url, params)
			.then((response) => {
				if (response.status === 200) {
					message.success(t('creator_summary.modal.edit_creator_success'));
					handleCancel();

					// Fetch APIs after editing
					if (callback === 'fetchingFindProfiles') {
						dispatch(creatorFindProfiles(requestDataFindProfiles));
						dispatch(resetSelectedRows(true));
					}
					if (callback === 'fetchCreatorDetails') {
						dispatch(creatorGetProfile(getProfileParams));
					}
					dispatch(
						storeCreatorData({
							updateBasicInformationCreator: true,
						}),
					);
				} else {
					generalMessage(response.body.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				message.error(DEFAULT_ERROR);
			});
	};

	const handleSubmit = () => {
		form.submit();
	};

	const handleProfile = () => {
		const values = form.getFieldsValue();
		const { state = '', city = '', street = '', ...valueOther } = values;
		const address = JSON.stringify({ state, city, street });
		const valueRequest = { address, ...valueOther };
		form.validateFields();
		if (modalType === 'add') {
			handleCreateCreator(valueRequest);
		}
		if (modalType === 'edit') {
			if (validateFB) return;
			handleEditCreator(valueRequest);
		}
	};

	const handleCancel = () => {
		handleReset();
		onClose();
	};

	const handleReset = () => {
		setListCity([]);
		form.resetFields();
	};

	const validateFBURL = (_: any, value: string) => {
		if (value) {
			const check = value.split('/');
			if (value.includes(SOCIAL_PROFILE.FACEBOOK) && check[3]) {
				setValidateFB(false);
				return Promise.resolve();
			} else {
				setValidateFB(true);
				return Promise.reject(new Error(t('creator_segment.modal.error_facebook')));
			}
		} else {
			setValidateFB(false);
			return Promise.resolve();
		}
	};

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const onValuesChange = (changedValue: any) => {
		const fieldName = Object.keys(changedValue)[0];
		const fieldList = Object.values(SOCIAL_CREATOR_INFO_FIELD);
		fieldList.push(BASIC_CREATOR_INFO_FIELD.EMAIL);
		if (fieldList.includes(fieldName)) {
			fieldList.forEach((item) => {
				const errorItem = form.getFieldError(item);
				if (errorItem.length && errorItem.includes(t('creator_summary.modal.required'))) {
					form.validateFields();
					return false;
				}

				return true;
			});
		}
	};

	const onBlur = (e: React.ChangeEvent<any>) => {
		const fieldName = e.target.getAttribute('id');
		const value = e.target.value;
		const hasSpaces =
			typeof value === 'string' && (value.endsWith(' ') || value.startsWith(' '));

		if (hasSpaces) {
			form.setFieldsValue({
				[fieldName]: value.trim(),
			});
		}
	};

	return (
		<StyledModal
			className="modal_empty"
			title={t(`creator_summary.modal.${modalType}_creator`)}
			visible={visible}
			onCancel={handleCancel}
			width={700}
			destroyOnClose
			centered
			footer={[
				<Button key="back" onClick={handleCancel}>
					{t('button.cancel')}
				</Button>,
				<Button onClick={handleSubmit} key="submit" type="primary" loading={isLoading}>
					{t(`button.${modalType}`)}
				</Button>,
			]}>
			<StyledWrapperContent
				maxHeight="calc(85vh - 150px)"
				overflow="auto"
				padding="24px"
				className="custom_scroll_bar">
				<LoadingWrapper isLoading={isFetching}>
					<StyledForm
						form={form}
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						requiredMark={false}
						onValuesChange={onValuesChange}
						onFinish={handleProfile}
						onBlur={onBlur}>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item
									label={t('creator_summary.email')}
									name="email"
									rules={[
										{
											type: 'email',
											message: t('creator_summary.modal.invalid_email'),
										},
										{
											validator: (_, value) =>
												validateRequiredValue(_, value, form, t),
										},
									]}
									normalize={(value: string) => value.trim()}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={t('creator_profile.contact.phone')}
									name="phone"
									rules={[
										{
											pattern: PATTERNS.PHONE_NUMBER,
											message: t('creator_summary.modal.invalid_phone'),
										},
									]}>
									<StyledInput />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={t('creator_summary.first_name')} name="firstName">
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={t('creator_summary.last_name')} name="lastName">
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={t('creator_profile.contact.instagram')}
									name="instagramUsername"
									rules={[
										{
											max: 30,
											message: t('creator_summary.modal.length_instagram'),
										},
										{
											validator: (_, value) =>
												validateRequiredValue(_, value, form, t),
										},
										{
											pattern: PATTERNS.INSTAGRAM_USERNAME,
											message: t('validation.invalid_message', {
												field: t('creator_profile.contact.instagram'),
											}),
										},
									]}
									normalize={(value: string) => value.trim()}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={t('creator_profile.contact.twitter')}
									name="twitterUsername"
									rules={[
										{
											max: 30,
											message: t('creator_summary.modal.length_twitter'),
										},
										{
											validator: (_, value) =>
												validateRequiredValue(_, value, form, t),
										},
										{
											pattern: PATTERNS.TWITTER_USERNAME,
											message: t('validation.invalid_message', {
												field: t('creator_profile.contact.twitter'),
											}),
										},
									]}
									normalize={(value: string) => value.trim()}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={t('creator_profile.contact.youtube')}
									name="youtubeUsername"
									rules={[
										{
											validator: (_, value) =>
												validateRequiredValue(_, value, form, t),
										},
									]}
									normalize={(value: string) => value.trim()}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={t('creator_profile.contact.tiktok')}
									name="tiktokUsername"
									rules={[
										{
											max: 30,
											message: t('creator_summary.modal.length_tiktok'),
										},
										{
											validator: (_, value) =>
												validateRequiredValue(_, value, form, t),
										},
										{
											pattern: PATTERNS.TIKTOK_USERNAME,
											message: t('validation.invalid_message', {
												field: t('creator_profile.contact.tiktok'),
											}),
										},
									]}
									normalize={(value: string) => value.trim()}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label={t('creator_profile.contact.facebook')}
									name="facebookUrl"
									rules={[{ validator: validateFBURL }]}
									normalize={(value: string) => value.trim()}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label={t('creator_profile.contact.address')}
									name="street">
									<Input.TextArea rows={3} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label={t('creator_profile.contact.state')} name="state">
									<StyledSelect
										notFoundContent={notFoundContent()}
										placeholder={''}
										onSelect={(val: any) => setNameState(val)}
										getPopupContainer={(triggerNode: HTMLElement) =>
											triggerNode.parentNode as HTMLElement
										}
										showSearch>
										{dataState.map((item: any) => {
											return (
												<Option key={item?.state} value={item.state}>
													{item?.state}
												</Option>
											);
										})}
									</StyledSelect>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label={t('creator_profile.contact.city')} name="city">
									<StyledSelect
										notFoundContent={notFoundContent()}
										placeholder={''}
										getPopupContainer={(triggerNode: HTMLElement) => {
											document
												.querySelectorAll(
													'.ant-select-selection-search-input',
												)
												.forEach((e) => {
													e.setAttribute('autocomplete', 'none');
												});
											return triggerNode.parentNode as HTMLElement;
										}}
										showSearch>
										{listCity.map((item: any) => {
											return (
												<Option key={item} value={item}>
													{item}
												</Option>
											);
										})}
									</StyledSelect>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label={t('creator_profile.contact.zip')} name="zipCode">
									<Input />
								</Form.Item>
							</Col>
						</Row>
					</StyledForm>
				</LoadingWrapper>
			</StyledWrapperContent>
		</StyledModal>
	);
};

export default React.memo(CreatorDetailModal);
