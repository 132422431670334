import { BODY_TEMPLATE, PARAM_TEMPLATE } from '@constants/settings/contentLicensing';
import {
	BodyTemplateTypes,
	ParamTemplateTypes,
	TemplateTypes,
} from '@models/settings/contentLicensing';
import React from 'react';

type ContentLicensingTypes = {
	setTypeAction: (val: string) => void;
	listTemplate: TemplateTypes[];
	setParamTemplate: (val: ParamTemplateTypes) => void;
	paramTemplate: ParamTemplateTypes;
	bodyTemplate: BodyTemplateTypes;
	setBodyTemplate: (val: BodyTemplateTypes) => void;
	setIdTemplateAction: (val: number | null) => void;
	setIsLoading: (val: boolean) => void;
	setLoadingOther: (val: boolean) => void;
	loadingOther: boolean;
};

const values: ContentLicensingTypes = {
	setTypeAction: () => {},
	listTemplate: [],
	setParamTemplate: () => {},
	paramTemplate: PARAM_TEMPLATE,
	bodyTemplate: BODY_TEMPLATE,
	setBodyTemplate: () => {},
	setIdTemplateAction: () => {},
	setIsLoading: () => {},
	loadingOther: false,
	setLoadingOther: () => {},
};

const ContentLicensingContext = React.createContext(values);
export default ContentLicensingContext;
