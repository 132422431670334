import { ParamSegmentDto } from '@models/creator/segments';
import { createContext } from 'react';
import { CreatorSegmentPaginator as defaultPage } from '@constants/paginator';

// Types
type CreatorSegmentsContextTypes = {
	handleChangeParamSegment: (val: ParamSegmentDto) => void;
	handleDuplicateSegment: Function;
	handleDeleteSegment: Function;
	paramSegment: ParamSegmentDto;
};

// Default value
const data: CreatorSegmentsContextTypes = {
	handleChangeParamSegment: () => {},
	handleDuplicateSegment: () => {},
	handleDeleteSegment: () => {},
	paramSegment: {
		page: 1,
		pageRecords: defaultPage.pageSize,
		title: '',
	},
};

const CreatorSegmentsContext = createContext(data);

export default CreatorSegmentsContext;
