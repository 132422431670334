import { IconCheckCircle, IconError, IconRightPending } from '@assets/icons';
import CustomTable from '@components/CustomTable';
import TremendousModal from '@components/Settings/Integrations/TremendousModal';
import { MODAL_TYPE, TYPE_FORMAT_DATE } from '@constants/common';
import { REWARD_STATUS, REWARD_TYPES } from '@constants/reward';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import LoadingWrapper from '@cores/LoadingWrapper';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { ActionsTypes } from '@models/common/summary';
import { RewardStore } from '@models/rewards/store';
import { RewardByCreatorItemDto } from '@models/rewards/summary';
import {
	changeTremendousField,
	getRewardByCreatorRequest,
	storeRewardData,
	updateRewardStatusEnd,
	updateRewardStatusRequest,
} from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledButton,
	StyledDivider,
	StyledDropdown,
	StyledFlex,
	StyledIcon,
	StyledText,
	StyledTooltip,
	StyledWrapper,
	StyledWrapperAction,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledDrawer } from '@styled/Creators/SummaryStyled';
import { customizeRenderEmpty, menuActions } from '@utils/renderComponent';
import { Button, ConfigProvider, Layout, Menu, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LogRewardModal from './LogRewardModal';

type ManageRewardsProps = {
	visible: boolean;
	onClose: () => void;
};

const ManageRewards = (props: ManageRewardsProps) => {
	const { visible, onClose } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);
	const {
		rewardByCreatorList,
		getRewardByCreatorStatus,
		updateRewardStatus,
		error,
	}: RewardStore = useSelector((state: any) => state.rewards);
	const { clientSettings } = useSelector((state: any) => state.sidebar);

	const { tremendousEnabled = false } = clientSettings || {};

	const [modalType, setModalType] = useState<string>('');

	useEffect(() => {
		if (visible) {
			getRewardList();
		}

		return () => {
			dispatch(
				storeRewardData({
					rewardByCreatorList: [],
				}),
			);
		};
	}, [visible]);

	useEffect(() => {
		if (updateRewardStatus === SUCCEEDED) {
			getRewardList();
			message.success(t('creator_summary.message.updated_successfully'));
		}

		if (updateRewardStatus === FAILED || updateRewardStatus === SUCCEEDED) {
			dispatch(updateRewardStatusEnd());
		}
	}, [updateRewardStatus, error]);

	useEffect(() => {
		if (error) {
			message.error(error).then(() => {
				dispatch(
					storeRewardData({
						error: null,
					}),
				);
			});
		}
	}, [error]);

	useEffect(() => {
		return () => {
			dispatch(changeTremendousField({ tremendousDefault: null, tremendousInfo: null }));
		};
	}, []);

	const getRewardList = () => {
		if (creatorProfile) {
			dispatch(
				getRewardByCreatorRequest({
					creatorId: creatorProfile.id,
				}),
			);
		}
	};

	const renderStatus = (status: string, rewardItem: RewardByCreatorItemDto) => {
		let actions: ActionsTypes[] = [];
		const arrActions = [
			{
				name: REWARD_STATUS.FAILED.label,
				click: () => onChangeRewardStatus(REWARD_STATUS.FAILED.value, rewardItem),
			},
			{
				name: REWARD_STATUS.SENT.label,
				click: () => onChangeRewardStatus(REWARD_STATUS.SENT.value, rewardItem),
			},
			{
				name: REWARD_STATUS.PENDING.label,
				click: () => onChangeRewardStatus(REWARD_STATUS.PENDING.value, rewardItem),
			},
			{
				name: t('tremendous.get_latest_reward_status'),
				click: () => getRewardList(),
			},
		];

		let iconEl = <IconRightPending />;
		const otherProps = {
			fillPath: '',
		};
		const isTremendous = rewardItem.rewardType.toUpperCase() === REWARD_TYPES.TREMENDOUS;

		if (isTremendous) {
			actions = [arrActions[3]];
		}

		switch (status.toUpperCase()) {
			case REWARD_STATUS.FAILED.value: {
				iconEl = <IconError />;
				if (!isTremendous) {
					actions = [arrActions[1], arrActions[2]];
				}
				break;
			}
			case REWARD_STATUS.PENDING.value: {
				if (!isTremendous) {
					actions = [arrActions[0], arrActions[1]];
				}

				break;
			}

			case REWARD_STATUS.SENT.value: {
				iconEl = <IconCheckCircle />;
				otherProps.fillPath = THEME.colors.greenBase;
				if (!isTremendous) {
					actions = [arrActions[0], arrActions[2]];
				}

				break;
			}

			default: {
				iconEl = <></>;
				break;
			}
		}

		return (
			<StyledDropdown
				overlay={menuActions(actions)}
				trigger={['click']}
				placement="bottomLeft"
				arrow>
				<StyledTooltip
					title={
						<StyledText textTransform="capitalize">
							{`${t('content_detail.status')}: ${status}`}
						</StyledText>
					}
					trigger={['hover']}
					placement="topLeft">
					<StyledIcon size={24} {...otherProps}>
						{iconEl}
					</StyledIcon>
				</StyledTooltip>
			</StyledDropdown>
		);
	};

	const rewardColumns = [
		{
			title: '',
			dataIndex: 'status',
			key: 'status',
			width: 50,
			render: (status: string, reward: RewardByCreatorItemDto) =>
				renderStatus(status, reward),
		},
		{
			title: t('rewards.rule'),
			dataIndex: 'ruleTitle',
			key: 'ruleTitle',
			width: 150,
		},
		{
			title: t('rewards.type'),
			dataIndex: 'rewardType',
			key: 'rewardType',
			width: 150,
			render: (rewardType: string) => (
				<StyledText textTransform="capitalize">{rewardType}</StyledText>
			),
		},
		{
			title: t('rewards.est_value'),
			dataIndex: 'estimatedCashValue',
			key: 'estimatedCashValue',
			width: 150,
			render: (estimatedCashValue: number) => estimatedCashValue && `$${estimatedCashValue}`,
		},
		{
			title: t('rewards.log_date'),
			dataIndex: 'rewardDate',
			key: 'rewardDate',
			width: 150,
			render: (rewardDate: number) =>
				convertUtcToLocalTimeWithFormat(
					rewardDate,
					TYPE_FORMAT_DATE.MONTH_DAY_YEAR_TIME_DASH,
				),
		},
		{
			title: t('rewards.sent_date'),
			dataIndex: 'sentDate',
			key: 'sentDate',
			render: (sentDate: number) =>
				convertUtcToLocalTimeWithFormat(
					sentDate,
					TYPE_FORMAT_DATE.MONTH_DAY_YEAR_TIME_DASH,
				),
			width: 150,
		},
	];

	const onChangeRewardStatus = (status: string, rewardItem: RewardByCreatorItemDto) => {
		dispatch(
			updateRewardStatusRequest({
				rewardId: rewardItem.id,
				status,
			}),
		);
	};

	const renderTable = () => {
		return (
			<ConfigProvider renderEmpty={customizeRenderEmpty}>
				<CustomTable
					columns={rewardColumns}
					dataSource={rewardByCreatorList}
					pagination={false}
					scroll={{ y: 'calc(100vh - 300px)', x: 'max-content' }}
				/>
			</ConfigProvider>
		);
	};

	const onCancel = () => {
		setModalType('');
	};

	const handleModal = (type: string) => {
		setModalType(type);
	};

	const actionMoreObj: ActionsTypes[] = [
		{
			id: 1,
			name: t('button.via_tremendous'),
			click: () => {
				setModalType(MODAL_TYPE.TREMENDOUS_ORDER);
			},
		},
	];

	const renderMenuAction = (actionList: ActionsTypes[]) => {
		return (
			<Menu>
				{actionList.map((item: ActionsTypes) => {
					if (item?.hidden) {
						return null;
					}
					return (
						<Menu.Item key={item.id} onClick={item.click} disabled={item?.disabled}>
							{item.name}
						</Menu.Item>
					);
				})}
			</Menu>
		);
	};

	const isLoading = getRewardByCreatorStatus || updateRewardStatus === IN_PROGRESS;

	return (
		<StyledDrawer
			visible={visible}
			placement="right"
			getContainer={false}
			onClose={onClose}
			maskClosable={false}>
			<LoadingWrapper isLoading={isLoading}>
				<Layout>
					<StyledWrapper marginTop="16px">
						<StyledFlex>
							<StyledText
								className="overflow"
								whiteSpace="nowrap"
								margin="18px 26px"
								title={t('sidebar.rewards_history')}
								fontSize="20px">
								{t('sidebar.rewards_history')}
							</StyledText>
							<StyledWrapperAction>
								{tremendousEnabled && (
									<StyledDropdown
										className="dropdown_action"
										overlay={renderMenuAction(actionMoreObj)}
										getPopupContainer={(triggerNode: HTMLElement) =>
											triggerNode as HTMLElement
										}
										placement="bottomLeft"
										trigger={['click']}>
										<Button>{t('button.send_reward')}</Button>
									</StyledDropdown>
								)}

								<StyledButton
									margin="0 46px 0 8px"
									onClick={() => handleModal(MODAL_TYPE.LOG_REWARD)}>
									{t('button.log_reward')}
								</StyledButton>
							</StyledWrapperAction>
						</StyledFlex>
						<StyledDivider margin="0" />
						<StyledWrapperContent
							overflow="auto"
							height="calc(100vh - 220px)"
							padding="4px 26px">
							{renderTable()}
						</StyledWrapperContent>
					</StyledWrapper>
				</Layout>
			</LoadingWrapper>

			{modalType === MODAL_TYPE.TREMENDOUS_ORDER && (
				<TremendousModal
					visible={modalType === MODAL_TYPE.TREMENDOUS_ORDER}
					handleClose={() => setModalType('')}
					callbackCreateOrderSuccess={() => getRewardList()}
				/>
			)}

			{modalType === MODAL_TYPE.LOG_REWARD && (
				<LogRewardModal
					visible={modalType === MODAL_TYPE.LOG_REWARD}
					onCancel={onCancel}
					getRewardList={getRewardList}
				/>
			)}
		</StyledDrawer>
	);
};

export default ManageRewards;
