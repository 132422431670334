const PARAMS_DEFAULT_ALBUMS = {
	page: 1,
	maxRecords: 50,
	search: '',
};

const MAX_RECORD_SUGGEST_DEFAULT = 2;

const MAX_CONTENT_ALBUMS = 6;

const FORM_ALBUM_MODAL = {
	NAME: 'name',
	DESC: 'description',
	SHARE: 'share',
	FILTER: 'filterId',
};

const OPTION_SHARING = {
	YES: true,
	NO: false,
};

const TYPE_SYSTEM_ALBUM_SUGGEST = {
	TAG: 'system-suggested-tag-album',
	PENDING: 'system-suggested-pending-album',
	OUTDATE: 'system-suggested-outdated-album',
	NO_FLAG: 'system-suggested-no-flags-album',
	FLAGGED: 'system-suggested-flagged-album',
	TOP_RATED: 'system-suggested-top-rated-album',
};

const TYPE_ALBUM = {
	DEFAULT: 'album',
	SMART_ALBUM: 'smart-album',
	VERIFIED_ALBUM: 'system-verified-album',
	FAVORITE_ALBUM: 'system-favorite-album',
	...TYPE_SYSTEM_ALBUM_SUGGEST,
};

const LIST_TYPE_ALBUM_SUGGEST = Object.values(TYPE_SYSTEM_ALBUM_SUGGEST);

const COL_CONTENT = {
	MIN: 4,
	MAX: 6,
};

const DEFAULT_PARAMS_CONTENT = {
	maxRecords: 50,
	page: 1,
	sortBy: 'createdAt',
};

const GUTTER_LIST_CONTENT = 16;

const defaultSiderWidth = 404;

const fileNameDefault = '_upload source_date imported';

const MAX_RECORD_ALL = -1;

const DEFAULT_WIDTH_VIEW = 100;

const FILTER_BY_TYPE = {
	labels: 'labels',
	flags: 'flags',
};

const MONTH_FILTER = -6;

const HEIGHT_THUMBNAIL_ALBUM = 90;

const DEFAULT_ALBUM_TOP_RATE = {
	type: TYPE_ALBUM.SMART_ALBUM,
	name: 'Five Star Content',
	metadata: {
		description: 'This is a system-generated album of content that was rated 5 stars.',
		share: true,
		filterId: 0,
	},
};

export {
	PARAMS_DEFAULT_ALBUMS,
	DEFAULT_ALBUM_TOP_RATE,
	FORM_ALBUM_MODAL,
	OPTION_SHARING,
	MAX_CONTENT_ALBUMS,
	COL_CONTENT,
	GUTTER_LIST_CONTENT,
	DEFAULT_PARAMS_CONTENT,
	defaultSiderWidth,
	fileNameDefault,
	MAX_RECORD_SUGGEST_DEFAULT,
	MAX_RECORD_ALL,
	LIST_TYPE_ALBUM_SUGGEST,
	TYPE_SYSTEM_ALBUM_SUGGEST,
	DEFAULT_WIDTH_VIEW,
	FILTER_BY_TYPE,
	MONTH_FILTER,
	TYPE_ALBUM,
	HEIGHT_THUMBNAIL_ALBUM,
};
