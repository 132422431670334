import styled from 'styled-components';

const StyledWrapperPublishFile = styled.div`
	width: 100%;
	max-width: 1600px;
	height: 100vh;
	padding: 16px 32px;
	overflow: auto;
	margin: auto;
	border-radius: 4px;
`;

export { StyledWrapperPublishFile };
