import {
	IconAddCircle,
	IconEmail,
	IconExpandMore,
	IconFlag,
	IconInstagram,
	IconShortAnswer,
	IconSort,
	IconTiktok,
	IconTwitter,
} from '@assets/icons';
import {
	FIELD_NAME_DEFAULT,
	GROUP_CUSTOM_FIELD,
	GROUP_DEFAULT_CUSTOM,
	KEY_FIELDS,
	LIST_BASIC_CUSTOM_FIELD,
	LIST_CUSTOM_FIELD,
	TYPE_CUSTOM_FIELD,
} from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { FieldItemWidgetDto, GroupFieldCustomDto } from '@models/content/widgetManager';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import { StyledTitle, StyledWrapperContent } from '@styled/Common/CommonStyled';
import {
	StyledAddCustomField,
	StyledBtnAddCustom,
	StyledDropdown,
	StyledDropdownCustomField,
	StyledIconPhone,
	StyledItemAddCustomField,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { generalKeyField } from '@utils/funcHelper';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type PropAddCustomFieldDto = {
	handleUpdateListField: (val: FieldItemWidgetDto[], key: string) => void;
	handleUpdateFieldBasic: (val: FieldItemWidgetDto) => void;
	setIdFocus: (val: string) => void;
};

const AddCustomField = (props: PropAddCustomFieldDto) => {
	const { handleUpdateListField, handleUpdateFieldBasic, setIdFocus } = props;
	const { customFields }: WidgetManagerStoreDto = useSelector(
		(state: any) => state?.widgetManager,
	);

	const { detailWidget } = useContext(WidgetBuilderContext);

	const { listFields = [], basicFields = [] } = detailWidget?.metadata?.body || {};

	const { t } = useTranslation();

	const [listCustomDefault, setListCustomDefault] = useState<FieldItemWidgetDto[]>([]);

	useEffect(() => {
		if (basicFields) {
			const listKeyMiss: FieldItemWidgetDto[] = [];
			LIST_BASIC_CUSTOM_FIELD?.forEach((field: FieldItemWidgetDto) => {
				if (
					!basicFields?.some(
						(item: FieldItemWidgetDto) => item?.fieldName === field?.fieldName,
					)
				) {
					listKeyMiss.push(field);
				}
			});
			setListCustomDefault(listKeyMiss);
		}
	}, [basicFields]);

	const renderIcon = (type: string) => {
		let icon = null;
		switch (type) {
			case TYPE_CUSTOM_FIELD.email:
				icon = <IconEmail />;
				break;
			case TYPE_CUSTOM_FIELD.instagram:
				icon = <IconInstagram />;
				break;
			case TYPE_CUSTOM_FIELD.tiktok:
				icon = <IconTiktok />;
				break;

			case TYPE_CUSTOM_FIELD.twitter:
				icon = <IconTwitter />;
				break;
			case TYPE_CUSTOM_FIELD.dropdown:
				icon = <IconExpandMore />;
				break;
			case TYPE_CUSTOM_FIELD.paragraph:
				icon = <IconSort />;
				break;
			case TYPE_CUSTOM_FIELD.phone:
				icon = <StyledIconPhone />;
				break;
			case TYPE_CUSTOM_FIELD.shortAnswer:
				icon = <IconShortAnswer />;
				break;
			case TYPE_CUSTOM_FIELD.mailAddress:
				icon = <IconFlag />;
				break;
			default:
				break;
		}
		return icon;
	};

	const onAddCustomField = (item: FieldItemWidgetDto) => {
		handleUpdateListField([...listFields, { ...item }], KEY_FIELDS.LIST_FIELDS);
	};

	const onAddBasicField = (item: FieldItemWidgetDto) => {
		let newList = [...basicFields];
		if (item?.fieldName === FIELD_NAME_DEFAULT.firstName) {
			newList = [...newList.slice(0, 1), { ...item }, ...newList?.slice(1)];
		} else {
			newList.push({ ...item });
		}
		handleUpdateListField(newList, KEY_FIELDS.BASIC_FIELDS);
		handleUpdateFieldBasic(item);
	};

	const renderLabel = (type: string, isCustomField?: boolean) => {
		let labelInput = 'short_answer';
		switch (type) {
			case TYPE_CUSTOM_FIELD.email:
				labelInput = 'email';
				break;
			case TYPE_CUSTOM_FIELD.instagram:
				labelInput = 'instagram_username';
				break;
			case TYPE_CUSTOM_FIELD.tiktok:
				labelInput = 'tiktok_username';
				break;
			case TYPE_CUSTOM_FIELD.twitter:
				labelInput = 'twitter_username';
				break;
			case TYPE_CUSTOM_FIELD.shortAnswer:
				labelInput = 'short_answer';
				break;
			case TYPE_CUSTOM_FIELD.phone:
				labelInput = 'phone_number';
				break;
			case TYPE_CUSTOM_FIELD.dropdown:
				labelInput = 'dropdown_options';
				break;
			case TYPE_CUSTOM_FIELD.paragraph:
				labelInput = 'content_description';
				if (isCustomField) {
					labelInput = 'paragraph';
				}
				break;
			case TYPE_CUSTOM_FIELD.mailAddress:
				labelInput = 'mailing_address';
				break;
			default:
				break;
		}
		return labelInput;
	};

	const listAddStand: FieldItemWidgetDto[] = useMemo(() => {
		let result: FieldItemWidgetDto[] = [...LIST_CUSTOM_FIELD];

		const listOnlyOne = [
			TYPE_CUSTOM_FIELD.twitter,
			TYPE_CUSTOM_FIELD.instagram,
			TYPE_CUSTOM_FIELD.tiktok,
			TYPE_CUSTOM_FIELD.phone,
			TYPE_CUSTOM_FIELD.mailAddress,
			TYPE_CUSTOM_FIELD.paragraph,
		];
		listOnlyOne.forEach((itemCheck: string) => {
			if (
				listFields?.some(
					(item: FieldItemWidgetDto) => item?.type === itemCheck && !item.key,
				)
			) {
				result = result?.filter((item) => item.type !== itemCheck);
			}
		});

		return result;
	}, [LIST_CUSTOM_FIELD, listFields]);

	const listGroupFieldCustom: GroupFieldCustomDto[] = useMemo(() => {
		const result: GroupFieldCustomDto[] = cloneDeep(GROUP_DEFAULT_CUSTOM);

		if (customFields.content.length > 0) {
			result.unshift({
				group: GROUP_CUSTOM_FIELD.CUSTOM_CONTENT_FIELD,
				options: customFields.content.map((field) => ({
					...field,
					customCreated: true,
				})),
			});
		}

		if (customFields.creator.length > 0) {
			result.unshift({
				group: GROUP_CUSTOM_FIELD.CUSTOM_CREATOR_FIELD,
				options: customFields.creator.map((field) => ({
					...field,
					customCreated: true,
				})),
			});
		}

		return result;
	}, [customFields]);

	const renderTextGroup = (group: string) => {
		if (group === GROUP_CUSTOM_FIELD.CUSTOM_CREATOR_FIELD) {
			return t('content.widget_manager.widget_builder.use_exist_creator');
		}
		if (group === GROUP_CUSTOM_FIELD.CUSTOM_CONTENT_FIELD) {
			return t('content.widget_manager.widget_builder.use_exist_content');
		}

		return t('content.widget_manager.widget_builder.create_new_custom');
	};

	const renderGroup = () => {
		return (
			<StyledDropdownCustomField className="custom_scroll_bar">
				{listGroupFieldCustom?.map((group) => (
					<StyledWrapperContent key={group.group}>
						<StyledTitle
							fontSize="14px"
							color={THEME.colors.gray3}
							fontWeight="700"
							padding="8px 13px"
							className="unset_height">
							{renderTextGroup(group.group)}
						</StyledTitle>
						{group.options?.map((item) => {
							let disabled = false;
							if (group.group !== GROUP_CUSTOM_FIELD.CUSTOM_FIELD) {
								disabled = listFields.some(
									(cus: FieldItemWidgetDto) => cus.key === item.key,
								);
							}
							return (
								<StyledItemAddCustomField
									onClick={() => {
										const key = item?.key || generalKeyField();
										onAddCustomField({
											...item,
											key,
										});
										setIdFocus(key);
									}}
									className={disabled ? 'disabled' : ''}
									key={item?.type}>
									{renderIcon(item?.type)}
									<StyledTitle
										className="unset_height overflow_text"
										maxWidth="calc(100% - 40px)"
										fontSize="1rem">
										{group?.group === GROUP_CUSTOM_FIELD.CUSTOM_FIELD
											? t(
													`content.widget_manager.widget_builder.label_field.${renderLabel(
														item?.type,
														true,
													)}`,
											  )
											: item.label}
									</StyledTitle>
								</StyledItemAddCustomField>
							);
						})}
					</StyledWrapperContent>
				))}
			</StyledDropdownCustomField>
		);
	};

	const menu = (
		<StyledDropdownCustomField className="custom_scroll_bar">
			{listAddStand?.map((item) => (
				<StyledItemAddCustomField onClick={() => onAddCustomField(item)} key={item?.type}>
					{renderIcon(item?.type)}
					<StyledTitle
						className="unset_height overflow_text"
						maxWidth="calc(100% - 40px)"
						fontSize="1rem">
						{t(
							`content.widget_manager.widget_builder.label_field.${renderLabel(
								item?.type,
								false,
							)}`,
						)}
					</StyledTitle>
				</StyledItemAddCustomField>
			))}
			{listCustomDefault?.map((item) => (
				<StyledItemAddCustomField onClick={() => onAddBasicField(item)} key={item.name}>
					{renderIcon(item?.type)}
					<StyledTitle
						className="unset_height overflow_text"
						maxWidth="calc(100% - 40px)"
						fontSize="1rem">
						{item?.name}
					</StyledTitle>
				</StyledItemAddCustomField>
			))}
		</StyledDropdownCustomField>
	);

	const arrBtnCustom = [
		{
			id: 1,
			menu,
			text: t('content.widget_manager.widget_builder.add_standard_field'),
		},
		{
			id: 2,
			menu: renderGroup(),
			text: t('content.widget_manager.widget_builder.add_custom_field'),
		},
	];

	return (
		<StyledAddCustomField>
			{arrBtnCustom?.map((item, idx) => (
				<StyledDropdown
					arrow
					key={item.id}
					className="dropdown_w_auto"
					placement="topLeft"
					overlayClassName="w_full"
					overlay={item.menu}
					getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
					trigger={['click']}>
					<StyledBtnAddCustom margin={idx ? '16px 0 0 0' : '0'}>
						<IconAddCircle />
						{item.text}
					</StyledBtnAddCustom>
				</StyledDropdown>
			))}
		</StyledAddCustomField>
	);
};

export default AddCustomField;
