import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getRequest } from '@helpers/requestHelpers';
import genericApiSaga from '@helpers/genericApiSaga';
import { generateReportTypes, getDashboardTypes } from './dashboard.types';
import { CONTENT_EXPORT } from '@constants/APIs';
import * as actions from './dashboard.actions';

export function* fetchDashboard({ payload }: any) {
	const { url, params, getDashboardSucceeded, getDashboardFailed } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url, params),
		*completed(response: any) {
			getDashboardSucceeded(response.data.result);
		},
		*failed(response: any) {
			getDashboardFailed(response.message);
		},
	});
}

export function* generateReport() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CONTENT_EXPORT),
		*completed(response: any) {
			yield put(actions.generateReportCompleted(response.data));
		},
		*failed(response: any) {
			yield put(actions.generateReportFailed(response));
		},
	});
}

export function* watcherDashboardSaga() {
	yield takeEvery(getDashboardTypes.GET_DASHBOARD, fetchDashboard);
	yield takeLatest(generateReportTypes.GENERATE_REPORT_REQUEST, generateReport);
}
