/**
 * @prettier
 */

import THEME from '@constants/themes/themes';
import { Collapse } from 'antd';
import styled from 'styled-components';

const StyledContactBlock = styled.div`
	margin-top: 16px;
	border-radius: 2px;
`;

const StyledCollapse = styled(Collapse)`
	background-color: ${THEME.colors.darkBlue2};
	.ant-collapse-header {
		color: ${THEME.colors.gray5} !important;
		font-weight: 600;
	}
	.ant-collapse-content {
		background-color: ${THEME.colors.darkBlue2};
	}
	.ant-collapse-item {
		border: none;
	}
`;

const StyledHeader = styled.div`
	display: flex;
	svg {
		margin-right: 5px;
	}
`;

const StyledNotes = styled.div`
	border-radius: 2px;
	border: solid 1px ${THEME.colors.darkBlue4};
	padding: 6px 12px;
	word-break: break-all;
	height: 150px;
	max-height: 150px;
	overflow: auto;
`;

export { StyledCollapse, StyledContactBlock, StyledHeader, StyledNotes };
