import qa from './qa.json';
import prod from './prod.json';

const Env = process.env.REACT_APP_ENV || 'prod';
const isProd = Env.includes('prod');

const apiURL = isProd ? prod.API_URL : qa.API_URL;
const s3URL = isProd ? prod.S3_URL : qa.S3_URL;
const socialS3URL = isProd ? prod.SOCIAL_S3_URL : qa.SOCIAL_S3_URL;
const genericWidgetUrl = isProd ? prod.GENERIC_WIDGET_URL : qa.GENERIC_WIDGET_URL;
const platformURL = isProd ? prod.PLATFORM_URL : qa.PLATFORM_URL;
const mediaHubURL = isProd ? prod.MEDIA_HUB : qa.MEDIA_HUB;
const linkEntribe = isProd ? prod.ENTRIBE : qa.ENTRIBE;
const google = {
	clientId: isProd ? prod.GOOGLE.CLIENT_ID : qa.GOOGLE.CLIENT_ID,
	clientSecret: isProd ? prod.GOOGLE.CLIENT_SECRET : qa.GOOGLE.CLIENT_SECRET,
	developerKey: isProd ? prod.GOOGLE.DEVELOPER_KEY : qa.GOOGLE.DEVELOPER_KEY,
};
const timestamp = new Date().getTime();
const keyPendo = isProd ? prod.KEY_PENDO : qa.KEY_PENDO;
const urlGallerySupport = isProd ? prod.URL_GALLERY_SUPPORT : qa.URL_GALLERY_SUPPORT;
const urlUploaderSupport = isProd ? prod.URL_UPLOADER_SUPPORT : qa.URL_UPLOADER_SUPPORT;
const urlGalleryTag = isProd
	? `${prod.GALLERY_TAG}?t=${timestamp}`
	: `${qa.GALLERY_TAG}?t=${timestamp}`;
const urlUploaderTag = isProd ? prod.WIDGET_TAG : qa.WIDGET_TAG;
const COOKIE_DOMAIN = isProd ? prod.COOKIE_DOMAIN : qa.COOKIE_DOMAIN;
const UPLOAD_SUPPORT = isProd ? prod.UPLOAD_SUPPORT : qa.UPLOAD_SUPPORT;
const tremendousCampaignsURL = isProd ? prod.TREMENDOUS_CAMPAIGNS : qa.TREMENDOUS_CAMPAIGNS;
const tremendousURL = isProd ? prod.TREMENDOUS : qa.TREMENDOUS;
const communityURL = isProd ? prod.COMMUNITY : qa.COMMUNITY;
const releaseVersion = '2.2.5';
const tinyMCEAPIKey = isProd ? prod.TINYMCE_API_KEY : qa.TINYMCE_API_KEY;
const guestPortalURL = isProd ? prod.GUEST : qa.GUEST;

export {
	apiURL,
	s3URL,
	socialS3URL,
	Env,
	genericWidgetUrl,
	platformURL,
	mediaHubURL,
	linkEntribe,
	google,
	keyPendo,
	urlGallerySupport,
	urlGalleryTag,
	urlUploaderTag,
	urlUploaderSupport,
	COOKIE_DOMAIN,
	UPLOAD_SUPPORT,
	tremendousCampaignsURL,
	tremendousURL,
	communityURL,
	releaseVersion,
	tinyMCEAPIKey,
	guestPortalURL,
};
