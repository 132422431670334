import { IconCopy, IconEdit, IconNoteMessage } from '@assets/icons';
import CheckRedirectDetail from '@components/Content/WidgetManager/DetailWidget/CheckRedirectDetail';
import { WIDGET_BUILDER, WIDGET_BUILDER_DETAIL } from '@constants/APIs';
import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { TAB_WIDGET } from '@constants/content/widgetManager';
import { DEFAULT_ERROR } from '@constants/errors';
import THEME from '@constants/themes/themes';
import { ConfirmModal } from '@cores/Modal';
import { getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import {
	MetadataWidgetBuilder,
	WidgetBasicInfoTypes,
	WidgetBuilderDetailNoIdDto,
	WidgetBuilderDetailNoIdRequestDto,
	WidgetBuilderDetailRequestDto,
} from '@models/content/widgetManager';
import { createDraftWidgetBuilderRequest } from '@stores/actions';
import {
	StyledIcon,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyleStatus, StyledWidgetItem } from '@styled/LicensingTemplate/TemplateDetail';
import { onCopyValue } from '@utils/funcHelper';
import { message } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ActionEdit from './ActionEdit';

type PropTypes = {
	listWidget: WidgetBasicInfoTypes[];
	onPreviewWidget: (id: number) => void;
	onRemove?: (widget: WidgetBasicInfoTypes) => void;
	isSummary?: boolean;
	disabled?: boolean;
	setLoading?: (val: boolean) => void;
};
const ListWidgetPreview = (props: PropTypes) => {
	const {
		listWidget,
		onPreviewWidget,
		onRemove,
		isSummary = false,
		disabled,
		setLoading,
	} = props;
	const { t } = useTranslation();
	const history = useHistory();

	const [typeConfirm, setTypeConfirm] = useState<string>('');

	const handleRemove = (widget: WidgetBasicInfoTypes) => {
		if (onRemove && typeof onRemove === 'function') {
			onRemove(widget);
		}
	};

	const handleCopy = (val: string) => {
		onCopyValue(val);
		message.success(t('content_licensing.message.copy_widget'));
	};

	const renderContentPopupConfirm = () => {};

	const otherPropConfirm = useMemo(() => {
		const result: any = {};

		return result;
	}, [typeConfirm]);

	return (
		<>
			<ConfirmModal
				width={450}
				maskClosable={true}
				isOpen={typeConfirm ? true : false}
				onClose={() => setTypeConfirm('')}
				{...otherPropConfirm}>
				{renderContentPopupConfirm()}
			</ConfirmModal>
			{listWidget?.length > 0 && (
				<StyledWrapperContent margin="6px 0">
					<StyleStatus className={'warning'}>
						<StyledIcon cursor="unset" size={20}>
							<IconNoteMessage />
						</StyledIcon>
						<StyledTitle
							margin="0 8px"
							className="unset_height"
							color={THEME.colors.blueBase}
							fontSize="14px">
							{t('content_licensing.add_upload_widgets')}
						</StyledTitle>
					</StyleStatus>
				</StyledWrapperContent>
			)}
			<StyledWrapperContent
				width="100%"
				maxHeight="80px"
				className="custom_scroll_bar"
				overflow="auto">
				{listWidget.map((item) => (
					<StyledWidgetItem key={item.id}>
						<StyledTitle
							className="unset_height overflow_text"
							fontSize="14px"
							lineHeight="20px"
							maxWidth={`calc(100% - ${isSummary ? '130px' : '166px'})`}>
							{item.name}
						</StyledTitle>
						<StyledWrapperAction>
							<StyledTitle
								className="unset_height text_action"
								fontSize="14px"
								textDecoration="underline"
								onClick={() => onPreviewWidget(item.id)}
								lineHeight="20px">
								{t('button.preview')}
							</StyledTitle>
							{isSummary && <ActionEdit widgetId={item.id} setLoading={setLoading} />}
							{!isSummary && (
								<StyledTitle
									onClick={() => handleRemove(item)}
									margin="0 0 0 12px"
									textDecoration="underline"
									className={`unset_height text_action ${
										disabled ? 'disabled' : ''
									}`}
									fontSize="14px"
									lineHeight="20px">
									{t('button.remove')}
								</StyledTitle>
							)}
							<StyledIcon
								fillPath={THEME.colors.gray3}
								size={18}
								onClick={() => handleCopy(item.url || '')}
								className="icon_hover"
								margin="0 0 0 12px">
								<IconCopy />
							</StyledIcon>
						</StyledWrapperAction>
					</StyledWidgetItem>
				))}
			</StyledWrapperContent>
		</>
	);
};

export default ListWidgetPreview;
