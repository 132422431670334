import THEME from '@constants/themes/themes';
import SettingSocialAggregatorContext from '@contexts/Settings/SettingSocialAggregator';
import { StyledTitle, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { Button } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const EmptyConfig = () => {
	const { t } = useTranslation();
	const { onChangeStatusCreate } = useContext(SettingSocialAggregatorContext);

	return (
		<StyledWrapperContent
			textAlign="center"
			borderRadius="2px"
			margin="0 auto"
			padding="36px 30px 30px"
			background={THEME.colors.darkBlue2}>
			<StyledTitle margin="0 0 30px 0" className="unset_height">
				{t('social_aggregator.no_feeds')}
			</StyledTitle>
			<Button
				onClick={() => onChangeStatusCreate(true)}
				style={{ display: 'inline-flex' }}
				type="primary">
				{t('button.create_social_feed')}
			</Button>
		</StyledWrapperContent>
	);
};

export default EmptyConfig;
