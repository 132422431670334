import { IconAltAdd, IconDelete } from '@assets/icons';
import THEME from '@constants/themes/themes';
import {
	StyledIcon,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { DraggerProps } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { isEqual } from 'lodash';
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const StyledUpload = styled(Dragger)<{ height?: number }>`
	.ant-upload-btn {
		padding: 0 !important;
	}
	&.ant-upload {
		height: ${({ height }) => (height ? `${height}px` : '100px')};
		background-color: rgba(32, 50, 65, 0.5);
		&.upload_custom_widget {
			background-color: rgba(32, 50, 65, 0.5);
		}
		&.ant-upload-drag {
			border-color: ${THEME.colors.gray1};
			.ant-upload-btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				.ant-upload-drag-container {
					width: 100%;
				}
			}
		}
		.ant-upload-btn {
			background-color: ${THEME.colors.darkBlue3};
		}
	}
`;

// const StyledWrapperContent = styled.div`
// text-align: center;`;

type PropTypes = {
	textUpload?: ReactNode;
	children?: ReactNode;
	subText?: ReactNode;
	height?: number;
	fillIcon?: string;
};

const UploadMultiFile = (props: PropTypes & DraggerProps) => {
	const { textUpload, children, subText, height, fillIcon, fileList, ...other } = props;
	const { t } = useTranslation();
	const refUpload = useRef<any>({});

	const [fileUpload, setFileUpload] = useState<UploadFile<any>[]>([]);

	useEffect(() => {
		if (fileList) {
			setFileUpload(fileList);
		}
	}, [fileList]);

	const propsCustom = {
		showUploadList: {
			removeIcon: <IconDelete />,
		},
	};

	const onChangeFile = (values: any) => {
		if (!isEqual(values.fileList, fileUpload)) {
			setFileUpload([...fileUpload, values.fileList]);
		}
	};

	return (
		<StyledUpload
			{...propsCustom}
			{...other}
			showUploadList={false}
			height={height}
			action={''}>
			{children}
			{!children && (
				<StyledWrapperContent
					flexDirection="column"
					display="flex"
					alignItems="center"
					justifyContent="center">
					<StyledWrapperAction>
						<StyledIcon margin="0 4px 0 0" fill={fillIcon}>
							<IconAltAdd />
						</StyledIcon>
						{textUpload}
					</StyledWrapperAction>
					{subText}
				</StyledWrapperContent>
			)}
		</StyledUpload>
	);
};

export default UploadMultiFile;
