import { IconError } from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { TYPE_FORMAT_DATE } from '@constants/common';
import {
	CREATOR_PROFILE_MODAL_TYPE,
	DEFAULT_HISTORY,
	HEIGHT_HISTORY_CREATOR,
	ID_ELM_CREATOR,
	PARAM_MESSAGE_HISTORY,
} from '@constants/creator';
import CreatorProfileContext from '@contexts/Creators/Profile';
import { LoadingWrapper } from '@cores/index';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { HistoryCreatorParams } from '@models/creator/summary';
import { fetchMessageHistoryCreatorRequest, storeCreatorData } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledEmpty,
	StyledFlex,
	StyledIcon,
	StyledText,
	StyledTitle,
	StyledWrapper,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledCollapse } from '@styled/Creators/ContactCardStyled';
import { StyledMessageItemCreator, StyledWrapperBox } from '@styled/Creators/SummaryStyled';
import { renderThumbVertical } from '@utils/renderComponent';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useContext, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type PropTypes = {
	creatorId: number;
};
const MessageHistory = (props: PropTypes) => {
	const { creatorId, ...other } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { historyCreator, loadingMessageHistoryCreator }: UserStoreType = useSelector(
		(state: any) => state.creator,
	);

	const { records } = historyCreator;

	const scrollbarRef = useRef<any>({});
	const [paramHistory, setParamHistory] = useState<HistoryCreatorParams>({
		...PARAM_MESSAGE_HISTORY,
		creatorId: -1,
	});

	const {
		focusMessageHistory,
		collapseMessage,
		setCollapseMessage,
		setModalType,
		mailHistory,
		modalType,
		setMailHistory,
		messageHistoryCreator,
		setMessageHistoryCreator,
		setFocusHistory,
	} = useContext(CreatorProfileContext);

	useEffect(() => {
		if (creatorId) {
			setParamHistory({ ...paramHistory, creatorId, page: PARAM_MESSAGE_HISTORY.page });
		}

		return () => {
			dispatch(
				storeCreatorData({
					historyCreator: DEFAULT_HISTORY,
					messageHistoryCreator: [],
				}),
			);
			setFocusHistory(false);
		};
	}, [creatorId]);

	useEffect(() => {
		if (paramHistory?.creatorId && paramHistory.creatorId !== -1) {
			dispatch(fetchMessageHistoryCreatorRequest(paramHistory));
		}
	}, [paramHistory]);

	useEffect(() => {
		if (modalType !== CREATOR_PROFILE_MODAL_TYPE.VIEW_MESSAGE_HISTORY_DETAIL && mailHistory) {
			setMailHistory(null);
		}
	}, [modalType]);

	useEffect(() => {
		if (paramHistory.page === 1) {
			setMessageHistoryCreator([...records]);
		} else {
			setMessageHistoryCreator([...messageHistoryCreator, ...records]);
		}
	}, [historyCreator]);

	const onScrollStop = () => {
		if (scrollbarRef.current !== null) {
			const scrollbarTop = scrollbarRef.current.getValues().top;
			if (scrollbarTop >= 0.75 && records?.length > 0 && !loadingMessageHistoryCreator) {
				setParamHistory((prev: any) => ({ ...prev, page: prev.page + 1 }));
			}
		}
	};

	const renderHeader = () => {
		return (
			<StyledFlex justify="flex-start">
				<StyledText margin="0px">{t('creator_profile.message_history')}</StyledText>
			</StyledFlex>
		);
	};

	const renderHeight = () => {
		return messageHistoryCreator.length >= 5
			? `${HEIGHT_HISTORY_CREATOR}px`
			: `${(HEIGHT_HISTORY_CREATOR / 5) * messageHistoryCreator.length}px`;
	};

	return (
		<StyledWrapperBox
			className={focusMessageHistory ? 'active_box' : ''}
			id={ID_ELM_CREATOR.ID_CREATOR_MESSAGE_HISTORY}
			margin="16px 0 0 0">
			<LoadingWrapper sizeLoading={'medium'} isLoading={loadingMessageHistoryCreator}>
				<StyledCollapse
					activeKey={collapseMessage ? ['1'] : []}
					bordered={false}
					onChange={() => {
						setCollapseMessage(!collapseMessage);
					}}
					expandIconPosition="right">
					<CollapsePanel header={renderHeader()} key="1">
						{!loadingMessageHistoryCreator && messageHistoryCreator.length === 0 && (
							<StyledWrapper height="100%" className="height_full">
								<StyledEmpty description={t('common.no_data')} image={IconEmpty} />
							</StyledWrapper>
						)}
						{messageHistoryCreator.length > 0 && (
							<Scrollbars
								ref={(el) => {
									scrollbarRef.current = el;
								}}
								className="scrollbar_custom_wrapper"
								renderTrackVertical={() => <div />}
								onScrollStop={onScrollStop}
								style={{
									maxHeight: `${HEIGHT_HISTORY_CREATOR}px`,
									height: renderHeight(),
								}}
								renderThumbVertical={renderThumbVertical}>
								<StyledWrapperContent>
									{messageHistoryCreator.map((item, idx) => (
										<StyledMessageItemCreator
											onClick={() => {
												setMailHistory(item);
												setModalType(
													CREATOR_PROFILE_MODAL_TYPE.VIEW_MESSAGE_HISTORY_DETAIL,
												);
											}}
											id={idx === 0 ? ID_ELM_CREATOR.ID_FIRST_HISTORY : ''}
											className={mailHistory?.id === item?.id ? 'active' : ''}
											key={item.id}>
											<StyledTitle
												className="unset_height"
												fontSize="14px"
												width="fit-content"
												margin="0 8px 0 0">
												{convertUtcToLocalTimeWithFormat(
													item.createAt,
													TYPE_FORMAT_DATE.TIME,
												)}
											</StyledTitle>
											{!item?.success && (
												<StyledIcon size={20} margin="0 8px 0 0">
													<IconError />
												</StyledIcon>
											)}
											<StyledTitle
												width={`calc(100% - 180px - 8px - ${
													item?.success ? '0px' : '28px'
												})`}
												className="unset_height overflow_text"
												fontSize="14px">
												{item.subject}
											</StyledTitle>
										</StyledMessageItemCreator>
									))}
								</StyledWrapperContent>
							</Scrollbars>
						)}
					</CollapsePanel>
				</StyledCollapse>
			</LoadingWrapper>
		</StyledWrapperBox>
	);
};

export default MessageHistory;
