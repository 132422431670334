import { SORT_BY } from '@constants/ambassador';
import { ALIGN, DEFAULT_WIDTH_COL, ROUTE_PATH, VALUE_EMPTY } from '@constants/common';
import THEME from '@constants/themes/themes';
import AmbassadorDashboardManagerContext from '@contexts/AmbassadorDashboard';
import SelectSingle from '@cores/SelectSearch/SelectSingle';
import { AmbassadorLeaderBoardDto, AmbassadorManagerStoreDto } from '@models/ambassador/stores';
import { ColumnDto } from '@models/common/summary';
import { StyledWrapperSession } from '@styled/AmbassadorDashboard';
import {
	StyledEmpty,
	StyledSelect,
	StyledTable,
	StyledTitle,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { abbreviateNumber } from '@utils/common';
import { renderCopiedText, renderRating } from '@utils/renderComponent';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';

const LeaderBoard = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const { setSortByLeaderBoard, campaignSelectValue, sortByLeaderBoard, segmentSelectValue } =
		useContext(AmbassadorDashboardManagerContext);
	const { leaderBoards, loadingAmbassadorLeaderBoard }: AmbassadorManagerStoreDto = useSelector(
		(state: any) => state?.ambassadors,
	);

	const hasFilter = campaignSelectValue || segmentSelectValue;

	const arrOption = useMemo(() => {
		return [
			{
				value: SORT_BY.SUBMISSIONS,
				label: t('ambassador.submissions'),
			},
			{
				value: SORT_BY.POSTS,
				label: t('ambassador.posts'),
			},
			{
				value: SORT_BY.VOTES,
				label: t('ambassador.votes'),
			},
			{
				value: SORT_BY.ASR,
				label: t('ambassador.average_star_rating'),
			},
		];
	}, []);

	const columns = [
		{
			title: t('dashboard.leaderboard.creator_name'),
			dataIndex: 'name',
			show: true,
			ellipsis: true,
			render: (val: string) => renderCopiedText(val || VALUE_EMPTY),
			width: 160,
		},
		{
			title: t('content_management.folder.submissions'),
			dataIndex: 'submissions',
			show: true,
			ellipsis: true,
			width: 85,
			align: ALIGN.CENTER,
			render: (val: number) => renderCopiedText(abbreviateNumber(val)),
		},
		{
			title: t('creator_profile.social_stats.posts'),
			dataIndex: 'posts',
			show: true,
			ellipsis: true,
			width: 70,
			align: ALIGN.CENTER,
			render: (val: number) => renderCopiedText(abbreviateNumber(val)),
		},
		{
			title: t('creator_summary.votes'),
			dataIndex: 'vote',
			show: true,
			width: 50,
			ellipsis: true,
			align: ALIGN.CENTER,
			render: (val: number) => renderCopiedText(abbreviateNumber(val)),
		},
		{
			title: t('dashboard.leaderboard.avg_star_rating'),
			dataIndex: 'avgStar',
			align: ALIGN.CENTER,
			width: 100,
			render: (avgStar: number) => renderRating(avgStar, { size: 14, margin: '0 4px 0 0' }),
		},
	];

	return (
		<StyledWrapperSession
			bgColor={hasFilter ? THEME.colors.darkBlue6 : undefined}
			height="328px">
			<StyledWrapperTitle padding="12px 12px 4px 12px">
				<StyledTitle
					className="overflow_text"
					maxWidth="calc(100% - 250px)"
					display="block"
					fontSize="16px"
					lineHeight="22px"
					title={t('ambassador.leaderboard')}
					color={THEME.colors.gray5}>
					{t('ambassador.leaderboard')}
				</StyledTitle>
				<SelectSingle
					width="250px"
					value={
						arrOption?.find((item) => item?.value === sortByLeaderBoard) || undefined
					}
					onChange={(val) => setSortByLeaderBoard(val?.value?.toString() || '')}
					options={arrOption}
					renderTextApply={(val: any) => (
						<>{t('ambassador.sort_by_field', { field: val })}</>
					)}
				/>
			</StyledWrapperTitle>
			<StyledWrapperContent width="100%" height="calc(100% - 52px)" margin="12px 0 0 0">
				<StyledTable
					className="table_dashboard table_row_action"
					loading={loadingAmbassadorLeaderBoard}
					columns={columns}
					bgColor={hasFilter ? THEME.colors.darkBlue6 : undefined}
					dataSource={leaderBoards}
					onRow={(record: AmbassadorLeaderBoardDto, rowIndex: number) => {
						return {
							onClick: () => {
								history.push(
									`${ROUTE_PATH.CREATOR_DETAIL_EMPTY}${record?.creatorId}`,
								);
							},
						};
					}}
					pagination={false}
					scroll={{ y: '226px' }}
					locale={{
						emptyText: <StyledEmpty style={{ height: '199px' }} image={IconEmpty} />,
					}}
				/>
			</StyledWrapperContent>
		</StyledWrapperSession>
	);
};

export default LeaderBoard;
