import { ACCOUNT_LIST_LITE, MARK_TASK_AS_DONE, TASK, TASK_LIST } from '@constants/APIs';
import genericApiSaga from '@helpers/genericApiSaga';
import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import {
	AddCommentPayloadDto,
	TaskDetailDto,
	TaskHistoryPayloadDto,
	UpdateCommentPayloadDto,
	UpdateTaskPayloadDto,
} from '@models/taskmanager';
import { convertParamSearch } from '@utils/common';
import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './taskmanager.actions';
import {
	addCommentTypes,
	createTaskTypes,
	deleteCommentTypes,
	deleteTaskTypes,
	getAccountListLiteTypes,
	getTaskDetailsTypes,
	getTaskHistoryTypes,
	getTaskListTypes,
	markTaskAsDoneTypes,
	notifyMentionedUserTypes,
	sendReminderEmailTypes,
	updateCommentTypes,
	updateTaskTypes,
} from './taskmanager.types';

export function* getTaskList({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(TASK_LIST, payload),
		*completed(response: any) {
			yield put(actions.getTaskListSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getTaskListFailed(response));
		},
	});
}

export function* getAccountListLite() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(ACCOUNT_LIST_LITE),
		*completed(response: any) {
			yield put(actions.getAccountListLiteSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getAccountListLiteFailed(response));
		},
	});
}

export function* updateTask({
	payload,
}: Action<{
	id: number;
	params: UpdateTaskPayloadDto;
}>) {
	const { id, params } = payload;
	const url = `${TASK}/${id}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, params),
		*completed(response: any) {
			yield put(actions.updateTaskSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateTaskFailed(response));
		},
	});
}

export function* deleteTask({ payload }: Action<number[]>) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(TASK, payload),
		*completed(response: any) {
			yield put(actions.deleteTaskSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteTaskFailed(response));
		},
	});
}

export function* markTaskAsDone({ payload }: Action<number[]>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(MARK_TASK_AS_DONE, payload),
		*completed(response: any) {
			yield put(actions.markTaskAsDoneSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.markTaskAsDoneFailed(response));
		},
	});
}

export function* getTaskDetails({ payload }: Action<{ id: number }>) {
	const url = `${TASK}/${payload?.id}`;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.getTaskDetailsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getTaskDetailsFailed(response));
		},
	});
}

export function* getTaskHistory({ payload }: Action<TaskHistoryPayloadDto>) {
	const { taskId, ...others } = payload;
	const searchParams = convertParamSearch(others);
	const url = `${TASK}/${taskId}/histories?${searchParams}`;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.getTaskHistorySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getTaskHistoryFailed(response));
		},
	});
}

export function* createTask({ payload }: Action<TaskDetailDto>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(TASK, payload),
		*completed(response: any) {
			yield put(actions.createTaskSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.createTaskFailed(response));
		},
	});
}

export function* addComment({ payload }: Action<AddCommentPayloadDto>) {
	const { taskId, ...otherParams } = payload;
	const url = `${TASK}/${taskId}/comment`;
	yield genericApiSaga({
		gatewayCall: () => postRequest(url, otherParams),
		*completed(response: any) {
			yield put(actions.addCommentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.addCommentFailed(response));
		},
	});
}

export function* updateComment({ payload }: Action<UpdateCommentPayloadDto>) {
	const { historyId, ...otherParams } = payload;
	const url = `${TASK}/comment/${historyId}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, otherParams),
		*completed(response: any) {
			yield put(actions.updateCommentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateCommentFailed(response));
		},
	});
}

export function* deleteComment({
	payload,
}: Action<{
	historyId: number;
}>) {
	const { historyId } = payload;
	const url = `${TASK}/comment/${historyId}`;
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(url),
		*completed(response: any) {
			yield put(actions.deleteCommentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteCommentEnd(response));
		},
	});
}

export function* sendReminderEmail({ payload }: Action<{ taskIds: number[]; send: boolean }>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${TASK}/email-reminder`, payload),
		*completed(response: any) {
			yield put(actions.sendReminderEmailSucceeded({ ...response, isSend: payload.send }));
		},
		*failed(response: any) {
			yield put(actions.sendReminderEmailFailed(response));
		},
	});
}

export function* notifyMentionedUser({
	payload,
}: Action<{
	historyId: number;
	isSend: boolean;
}>) {
	const { historyId, isSend } = payload;
	const url = `${TASK}/comment/${historyId}/notify-mention-users?isSend=${isSend}`;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.notifyMentionedUserSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.notifyMentionedUserFailed(response));
		},
	});
}

export function* watcherTaskManagerSaga() {
	yield takeLatest(getTaskListTypes.GET_TASK_LIST_REQUEST, getTaskList);
	yield takeLatest(getAccountListLiteTypes.GET_ACCOUNT_LIST_LITE_REQUEST, getAccountListLite);
	yield takeLatest(updateTaskTypes.UPDATE_TASK_REQUEST, updateTask);
	yield takeLatest(deleteTaskTypes.DELETE_TASK_REQUEST, deleteTask);
	yield takeLatest(markTaskAsDoneTypes.MARK_TASK_AS_DONE_REQUEST, markTaskAsDone);
	yield takeLatest(getTaskDetailsTypes.GET_TASK_DETAILS_REQUEST, getTaskDetails);
	yield takeLatest(getTaskHistoryTypes.GET_TASK_HISTORY_REQUEST, getTaskHistory);
	yield takeLatest(createTaskTypes.CREATE_TASK_REQUEST, createTask);
	yield takeLatest(addCommentTypes.ADD_COMMENT_REQUEST, addComment);
	yield takeLatest(updateCommentTypes.UPDATE_COMMENT_REQUEST, updateComment);
	yield takeLatest(deleteCommentTypes.DELETE_COMMENT_REQUEST, deleteComment);
	yield takeLatest(sendReminderEmailTypes.SEND_REMINDER_EMAIL_REQUEST, sendReminderEmail);
	yield takeLatest(notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_REQUEST, notifyMentionedUser);
}
