import THEME from '@constants/themes/themes';
import { StyledFormItem, StyledIcon, StyledInput } from '@styled/Common/CommonStyled';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TooltipContent } from '..';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { IconEdit } from '@assets/icons';

const StyledContent = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray5};
	display: inline-block;
	margin-bottom: 12px;
	width: 100%;
	min-height: 20px;
`;

const StyledLabel = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray1};
	margin-bottom: 12px;
	position: relative;
	.ico_edit {
		opacity: 0;
		transition: 0.2s ease-out;
	}
	&:hover {
		.ico_edit {
			opacity: 1;
		}
	}
`;

type PropTypes = {
	name: string;
	value: string;
	enableEdit?: boolean;
	isLabel?: boolean;
};
const FormInputField = (props: PropTypes) => {
	const { t } = useTranslation();
	const { setField } = useContext(ContentDetailContext);

	const { name, value, enableEdit, isLabel } = props;
	const [isEdit, setIsEdit] = useState(false);

	const handleEdit = () => {
		setIsEdit(true);
		setField({ [name]: value });
	};

	if (isEdit) {
		return (
			<StyledFormItem margin="0 0 5px 0" name={name}>
				<StyledInput />
			</StyledFormItem>
		);
	}
	if (isLabel) {
		return (
			<StyledLabel>
				{value}
				{!!enableEdit && (
					<StyledIcon
						size={16}
						className="hover_level_svg ico_edit"
						onClick={() => handleEdit()}>
						<IconEdit />
					</StyledIcon>
				)}
			</StyledLabel>
		);
	}
	return (
		<TooltipContent name={value}>
			<StyledContent
				onClick={() => {
					if (enableEdit) {
						handleEdit();
					}
				}}>
				{value}
			</StyledContent>
		</TooltipContent>
	);
};

export default FormInputField;
