import { DEFAULT_ERROR } from '@constants/errors';
import { STATUSCODE } from '@constants/APIs';
import { NONE, IN_PROGRESS, SUCCEEDED, FAILED } from '@constants/status';
import { Action, handleActions } from 'redux-actions';
import {
	albumSelectTypes,
	createAlbumTypes,
	deleteGuestTypes,
	exportAlbumContentsTypes,
	fetchAlbumsSuggestTypes,
	fetchAlbumsTypes,
	fetchDetailAlbumTypes,
	fetchGuestListTypes,
	getAlbumListLiteTypes,
	removeAlbumsTypes,
	removeSuggestAlbumTypes,
	sendGuestPassCodeTypes,
	updateAlbumTypes,
} from './albumManager.types';
import { AlbumsPayloadDto, AlbumsStoreDto } from '@models/content/albums/stores';
import { message as messageAlert } from 'antd';
import { GuestDataDto, GuestDto } from '@models/content/albums/albumManager';

const initialState: AlbumsStoreDto = {
	loadingAlbums: false,
	loadingDetail: false,
	statusCreateAlbum: NONE,
	statusRemoveAlbum: NONE,
	statusExportAlbumContents: NONE,
	statusRemoveSuggestAlbum: NONE,
	statusUpdateAlbum: NONE,
	albums: [],
	albumsSuggest: [],
	albumDetail: null,
	albumCreate: null,
	hideMessage: false,
	error: '',
	fetchingGuestList: false,
	guestData: null,
	sendGuestPassCodeStatus: NONE,
	deleteGuestStatus: NONE,
};

// fetch albums
const getAlbumsRequest = (state: AlbumsStoreDto) => ({
	...state,
	loadingAlbums: true,
});

const getAlbumsSucceeded = (state: AlbumsStoreDto, { payload }: Action<AlbumsPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: albums,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingAlbums: false,
			albums,
			error: null,
		};
	}

	return {
		...state,
		loadingAlbums: false,
		albums: [],
		error: status,
	};
};

const getAlbumsFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingAlbums: false,
			albums: [],
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingAlbums: false,
		albums: [],
	};
};

// fetch albums suggest
const getAlbumsSuggestRequest = (state: AlbumsStoreDto) => ({
	...state,
	loadingAlbums: true,
});

const getAlbumsSuggestSucceeded = (
	state: AlbumsStoreDto,
	{ payload }: Action<AlbumsPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: albumsSuggest,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingAlbums: false,
			albumsSuggest,
			error: null,
		};
	}

	return {
		...state,
		loadingAlbums: false,
		albumsSuggest: [],
		error: status,
	};
};

const getAlbumsSuggestFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;
		const messageErr = error !== '' ? error : `${status} ${message}`;
		messageAlert.error(messageErr);
		return {
			...state,
			loadingAlbums: false,
			albumsSuggest: [],
			error: messageErr,
		};
	}

	return {
		...state,
		loadingAlbums: false,
		albumsSuggest: [],
	};
};

const createAlbumRequest = (state: AlbumsStoreDto, { payload }: Action<any>) => {
	const hideMessage = payload?.hideMessage;
	return {
		...state,
		hideMessage: !!hideMessage,
		statusCreateAlbum: IN_PROGRESS,
	};
};

const createAlbumSucceeded = (state: AlbumsStoreDto, { payload }: Action<AlbumsPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: albumCreate,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusCreateAlbum: SUCCEEDED,
			albumCreate,
			error: null,
		};
	}

	return {
		...state,
		statusCreateAlbum: FAILED,
		error: status,
		albumCreate: null,
	};
};

const createAlbumFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusCreateAlbum: FAILED,
			albumCreate: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		albumCreate: null,
		statusCreateAlbum: FAILED,
	};
};

const createAlbumEnd = (state: AlbumsStoreDto) => {
	return {
		...state,
		statusCreateAlbum: NONE,
		hideMessage: false,
		albumCreate: null,
	};
};

const removeAlbumsRequest = (state: AlbumsStoreDto) => ({
	...state,
	statusRemoveAlbum: IN_PROGRESS,
});

const removeAlbumsSucceeded = (state: AlbumsStoreDto, { payload }: Action<AlbumsPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusRemoveAlbum: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		statusRemoveAlbum: FAILED,
		error: status,
	};
};

const removeAlbumsFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusRemoveAlbum: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusRemoveAlbum: FAILED,
	};
};

const removeAlbumsEnd = (state: AlbumsStoreDto) => {
	return {
		...state,
		statusRemoveAlbum: NONE,
	};
};

// export album content
const exportAlbumContentsRequest = (state: AlbumsStoreDto) => ({
	...state,
	statusExportAlbumContents: IN_PROGRESS,
});

const exportAlbumContentsSucceeded = (
	state: AlbumsStoreDto,
	{ payload }: Action<AlbumsPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusExportAlbumContents: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		statusExportAlbumContents: FAILED,
		error: status,
	};
};

const exportAlbumContentsFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusExportAlbumContents: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusExportAlbumContents: FAILED,
	};
};

const exportAlbumContentsEnd = (state: AlbumsStoreDto) => {
	return {
		...state,
		statusExportAlbumContents: NONE,
	};
};

const removeSuggestAlbumRequest = (state: AlbumsStoreDto) => ({
	...state,
	statusRemoveSuggestAlbum: IN_PROGRESS,
});

const removeSuggestAlbumSucceeded = (
	state: AlbumsStoreDto,
	{ payload }: Action<AlbumsPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusRemoveSuggestAlbum: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		statusRemoveSuggestAlbum: FAILED,
		error: status,
	};
};

const removeSuggestAlbumFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusRemoveSuggestAlbum: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusRemoveSuggestAlbum: FAILED,
	};
};

const removeSuggestAlbumEnd = (state: AlbumsStoreDto) => {
	return {
		...state,
		statusRemoveSuggestAlbum: NONE,
	};
};

const updateAlbumRequest = (state: AlbumsStoreDto, { payload }: Action<any>) => {
	const hideMessage = payload?.hideMessage;

	return {
		...state,
		hideMessage: !!hideMessage,
		statusUpdateAlbum: IN_PROGRESS,
	};
};

const updateAlbumSucceeded = (state: AlbumsStoreDto, { payload }: Action<AlbumsPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: albumDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusUpdateAlbum: SUCCEEDED,
			albumDetail,
			error: null,
		};
	}

	return {
		...state,
		statusUpdateAlbum: FAILED,
		error: status,
	};
};

const updateAlbumFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusUpdateAlbum: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusUpdateAlbum: FAILED,
	};
};

const updateAlbumEnd = (state: AlbumsStoreDto) => {
	return {
		...state,
		statusUpdateAlbum: NONE,
		hideMessage: false,
	};
};

// fetch album detail
const getAlbumDetailRequest = (state: AlbumsStoreDto) => ({
	...state,
	loadingDetail: true,
});

const getAlbumDetailSucceeded = (state: AlbumsStoreDto, { payload }: Action<AlbumsPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: albumDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingDetail: false,
			albumDetail,
			error: null,
		};
	}

	return {
		...state,
		loadingDetail: false,
		albumDetail: null,
		error: status,
	};
};

const getAlbumDetailFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingDetail: false,
			albumDetail: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingDetail: false,
		albumDetail: null,
	};
};

// Fetch the list of guests
const fetchGuestListRequest = (state: AlbumsStoreDto) => ({
	...state,
	fetchingGuestList: true,
});

const fetchGuestListSucceeded = (state: AlbumsStoreDto, { payload }: Action<AlbumsPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const guestData: GuestDataDto = { ...result } as GuestDataDto;

		if (guestData && guestData?.guestResponses) {
			guestData?.guestResponses?.forEach((guest: GuestDto) => {
				guest.key = guest.id;
			});
		}

		return {
			...state,
			fetchingGuestList: false,
			guestData,
			error: null,
		};
	}

	return {
		...state,
		fetchingGuestList: false,
		guestData: null,
		error: status,
	};
};

const fetchGuestListFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			fetchingGuestList: false,
			guestData: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingGuestList: false,
		guestData: null,
	};
};

// Send guest passcode
const sendGuestPassCodeRequest = (state: AlbumsStoreDto) => {
	return {
		...state,
		sendGuestPassCodeStatus: IN_PROGRESS,
	};
};

const sendGuestPassCodeSucceeded = (
	state: AlbumsStoreDto,
	{ payload }: Action<AlbumsPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			sendGuestPassCodeStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		sendGuestPassCodeStatus: FAILED,
		error: status,
	};
};

const sendGuestPassCodeFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			sendGuestPassCodeStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		sendGuestPassCodeStatus: FAILED,
	};
};

const sendGuestPassCodeEnd = (state: AlbumsStoreDto) => {
	return {
		...state,
		sendGuestPassCodeStatus: NONE,
		error: null,
	};
};

// Delete guest
const deleteGuestRequest = (state: AlbumsStoreDto) => {
	return {
		...state,
		deleteGuestStatus: IN_PROGRESS,
	};
};

const deleteGuestSucceeded = (state: AlbumsStoreDto, { payload }: Action<AlbumsPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deleteGuestStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		deleteGuestStatus: FAILED,
		error: status,
	};
};

const deleteGuestFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			deleteGuestStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		deleteGuestStatus: FAILED,
	};
};

const deleteGuestEnd = (state: AlbumsStoreDto) => {
	return {
		...state,
		deleteGuestStatus: NONE,
		error: null,
	};
};

const changeAlbumSelect = (state: AlbumsStoreDto, { payload }: any) => {
	return {
		...state,
		albumDetail: payload,
	};
};

const getAlbumListLiteRequest = (state: AlbumsStoreDto) => {
	return {
		...state,
		getAlbumListLiteStatus: IN_PROGRESS,
	};
};

const getAlbumListLiteSucceeded = (
	state: AlbumsStoreDto,
	{ payload }: Action<AlbumsPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: albumListLiteResult = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			getAlbumListLiteStatus: SUCCEEDED,
			albumListLiteResult,
			error: null,
		};
	}

	return {
		...state,
		getAlbumListLiteStatus: FAILED,
		error: status,
	};
};

const getAlbumListLiteFailed = (state: AlbumsStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			getAlbumListLiteStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		getAlbumListLiteStatus: FAILED,
	};
};

const getAlbumListLiteEnd = (state: AlbumsStoreDto) => {
	return {
		...state,
		getAlbumListLiteStatus: NONE,
		error: null,
	};
};

const contentReducers = handleActions<any>(
	{
		[fetchAlbumsTypes.FETCH_ALBUMS_REQUEST]: getAlbumsRequest,
		[fetchAlbumsTypes.FETCH_ALBUMS_SUCCEEDED]: getAlbumsSucceeded,
		[fetchAlbumsTypes.FETCH_ALBUMS_FAILED]: getAlbumsFailed,

		[fetchAlbumsSuggestTypes.FETCH_ALBUMS_SUGGEST_REQUEST]: getAlbumsSuggestRequest,
		[fetchAlbumsSuggestTypes.FETCH_ALBUMS_SUGGEST_SUCCEEDED]: getAlbumsSuggestSucceeded,
		[fetchAlbumsSuggestTypes.FETCH_ALBUMS_SUGGEST_FAILED]: getAlbumsSuggestFailed,

		[createAlbumTypes.CREATE_ALBUM_REQUEST]: createAlbumRequest,
		[createAlbumTypes.CREATE_ALBUM_SUCCEEDED]: createAlbumSucceeded,
		[createAlbumTypes.CREATE_ALBUM_FAILED]: createAlbumFailed,
		[createAlbumTypes.CREATE_ALBUM_END]: createAlbumEnd,

		[removeAlbumsTypes.REMOVE_ALBUM_REQUEST]: removeAlbumsRequest,
		[removeAlbumsTypes.REMOVE_ALBUM_SUCCEEDED]: removeAlbumsSucceeded,
		[removeAlbumsTypes.REMOVE_ALBUM_FAILED]: removeAlbumsFailed,
		[removeAlbumsTypes.REMOVE_ALBUM_END]: removeAlbumsEnd,

		[exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_REQUEST]: exportAlbumContentsRequest,
		[exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_SUCCEEDED]: exportAlbumContentsSucceeded,
		[exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_FAILED]: exportAlbumContentsFailed,
		[exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_END]: exportAlbumContentsEnd,

		[removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_REQUEST]: removeSuggestAlbumRequest,
		[removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_SUCCEEDED]: removeSuggestAlbumSucceeded,
		[removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_FAILED]: removeSuggestAlbumFailed,
		[removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_END]: removeSuggestAlbumEnd,

		[updateAlbumTypes.UPDATE_ALBUM_REQUEST]: updateAlbumRequest,
		[updateAlbumTypes.UPDATE_ALBUM_SUCCEEDED]: updateAlbumSucceeded,
		[updateAlbumTypes.UPDATE_ALBUM_FAILED]: updateAlbumFailed,
		[updateAlbumTypes.UPDATE_ALBUM_END]: updateAlbumEnd,

		[fetchDetailAlbumTypes.FETCH_DETAIL_ALBUM_REQUEST]: getAlbumDetailRequest,
		[fetchDetailAlbumTypes.FETCH_DETAIL_ALBUM_SUCCEEDED]: getAlbumDetailSucceeded,
		[fetchDetailAlbumTypes.FETCH_DETAIL_ALBUM_FAILED]: getAlbumDetailFailed,

		[fetchGuestListTypes.FETCH_GUEST_LIST_REQUEST]: fetchGuestListRequest,
		[fetchGuestListTypes.FETCH_GUEST_LIST_SUCCEEDED]: fetchGuestListSucceeded,
		[fetchGuestListTypes.FETCH_GUEST_LIST_FAILED]: fetchGuestListFailed,

		[sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_REQUEST]: sendGuestPassCodeRequest,
		[sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_SUCCEEDED]: sendGuestPassCodeSucceeded,
		[sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_FAILED]: sendGuestPassCodeFailed,
		[sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_END]: sendGuestPassCodeEnd,

		[deleteGuestTypes.DELETE_GUEST_REQUEST]: deleteGuestRequest,
		[deleteGuestTypes.DELETE_GUEST_SUCCEEDED]: deleteGuestSucceeded,
		[deleteGuestTypes.DELETE_GUEST_FAILED]: deleteGuestFailed,
		[deleteGuestTypes.DELETE_GUEST_END]: deleteGuestEnd,

		[getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_REQUEST]: getAlbumListLiteRequest,
		[getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_SUCCEEDED]: getAlbumListLiteSucceeded,
		[getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_FAILED]: getAlbumListLiteFailed,
		[getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_END]: getAlbumListLiteEnd,

		[albumSelectTypes.UPDATE_ALBUM_SELECT]: changeAlbumSelect,
	},
	initialState,
);

export default contentReducers;
