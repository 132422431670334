import { CaretRightOutlined } from '@ant-design/icons';
import DetectThumbnailContent from '@components/ContentGlobal/DetectThumbnailContent';
import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { StyledImage } from '@components/Dashboard/Submission';
import ModalMediaDetail from '@components/ModalCustom/ModalMediaDetail';
import CardSocial from '@components/SocialSearch/Feeds/SummaryFeed/CardSocial';
import { FavoriteBehavior } from '@components/ThumbnailBehavior/FavoriteBehavior';
import { COL_MEDIA } from '@constants/ambassador';
import { ACTIONS, CONTENT_FOLDER, FILTER_BY, MEDIA_TYPE } from '@constants/content/contentLibrary';
import AmbassadorDashboardManagerContext from '@contexts/AmbassadorDashboard';
import SocialActivityManagerContext from '@contexts/AmbassadorDashboard/socialActivity';
import { ContentStoreDto, ImgVersionDto } from '@models/content/contentLibrary/store';
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
	ContentVerificationScoreDto,
} from '@models/content/contentLibrary/summary';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { reviewContentEnd, reviewMultiContentsEnd } from '@stores/actions';
import {
	StyledIconPlay,
	StyledMediaItemContainer,
} from '@styled/Content/ContentLibrary/MediaItemStyled';
import {
	filterContentListById,
	getContentByType,
	getSocialS3URL,
	updateFavoriteContentList,
} from '@utils/common';
import { checkExistExplicit } from '@utils/content';
import { addTimeStampFromUrl, checkPositionContent } from '@utils/funcHelper';
import { Col, Row } from 'antd';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSelectable } from 'react-selectable-fast';

const SelectableItem = createSelectable(CardSocial);

type PropTypes = {
	folderId: string;
};

const SummaryContent = (props: PropTypes) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { folderId } = props;

	// Reducers
	const {
		reviewContentStatus,
		reviewedContent,
		movedContent,
		rejectedContent,
		deletedContent,
	}: ContentStoreDto = useSelector((state: any) => state.content);

	const { col, contentList, setContentList, thumbFull } = useContext(
		SocialActivityManagerContext,
	);
	const { fullScreenSocial } = useContext(AmbassadorDashboardManagerContext);

	const refSocial = useRef<any>({});
	const [heightCard, setHeightCard] = useState<number>(0);
	const [socialDetail, setSocialDetail] = useState<SocialContentContainerDto | null>(null);
	const [content, setContent] = useState<ContentContainerDto | null>(null);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [isFirstContent, setIsFirstContent] = useState<boolean>(false);
	const [isLastContent, setIsLastContent] = useState<boolean>(false);
	const [contentIdMove, setContentIdMove] = useState<number | null>(null);
	const [socialId, setSocialId] = useState<string | null>(null);

	const [currentContent, setCurrentContent] = useState<ContentContainerDto | null>(null);

	const handleCloseContentDetails = () => {
		setCurrentContent(null);
		setCurrentIndex(0);
		setIsFirstContent(false);
		setIsLastContent(false);
	};

	const handleClickImage = (content: any, index: number) => {
		setCurrentIndex(index);
		setCurrentContent(content);

		if (index <= 0) {
			setIsFirstContent(true);
		}

		if (index >= contentList.length - 1) {
			setIsLastContent(true);
		}
	};

	const markAsFavoriteContent = (favoriteContent: ContentDto) => {
		const updatedList = updateFavoriteContentList(
			favoriteContent,
			contentList,
			CONTENT_FOLDER.SUBMISSIONS,
		);
		setContentList([...updatedList]);
	};

	const updateCreatorStatus = (
		content: ContentDto,
		currentContent: ContentDto,
		isBlock: boolean,
	) => {
		if (
			content.creatorId === currentContent?.creatorId ||
			(content.social !== null &&
				currentContent.social !== null &&
				content.social?.socialUsername === currentContent.social?.socialUsername)
		) {
			content.blocked = isBlock;
		}
	};

	const onChangeAfterBlock = (
		currentContent: ContentDto,
		isBlock: boolean,
		isDelete: boolean,
	) => {
		let contentListTemp: Array<ContentContainerDto> = [];
		contentListTemp = [...contentList];

		if (!isDelete) {
			contentListTemp.forEach((item: ContentContainerDto) => {
				const contentConvert = getContentByType(item);
				updateCreatorStatus(contentConvert, currentContent, isBlock);
			});
		} else {
			contentListTemp = contentListTemp.filter((item) => {
				const contentTemp = getContentByType(item);

				return (
					contentTemp.creatorId !== currentContent?.creatorId ||
					(contentTemp.social !== null &&
						currentContent.social !== null &&
						contentTemp.social?.socialUsername !==
							currentContent.social?.socialUsername)
				);
			});
		}

		setContentList(contentList);
		moveContentAfterAction(contentListTemp);
	};

	const moveContentAfterAction = (contentListTemp: ContentContainerDto[]) => {
		if (isLastContent) {
			handleCloseContentDetails();
		} else {
			setCurrentContent(contentListTemp[currentIndex]);
			if (currentIndex >= contentListTemp.length - 1) {
				setIsLastContent(true);
			}
		}
	};

	const onChangeAfterAction = async (action: string, contentsAfterAction?: number[]) => {
		let contentListTemp: Array<ContentContainerDto> = [];

		if (action === ACTIONS.DELETE) {
			contentListTemp = filterContentListById(contentList, deletedContent);
		}

		if (action === ACTIONS.REJECT || action === ACTIONS.UNREJECT) {
			contentListTemp = [...contentList];
			let responseContent: number[] = [];

			if (contentsAfterAction) {
				responseContent = contentsAfterAction;
			} else {
				if (action === ACTIONS.REJECT) {
					responseContent = rejectedContent || [];
				} else {
					responseContent = movedContent || [];
				}
			}

			if (responseContent.length) {
				responseContent.forEach((content: number) => {
					contentListTemp = contentListTemp.filter((item) => {
						const contentTemp = getContentByType(item);
						return contentTemp.id !== content;
					});
				});
			}
		}

		setContentList([...contentListTemp]); // update content list in Content.
		moveContentAfterAction([...contentListTemp]);
	};

	const updateVerificationScrore = (
		content: ContentDto,
		verificationScore: ContentVerificationScoreDto,
	) => {
		content.verificationScore = verificationScore;
	};

	const updateAfterReviewingContent = (reviewedContents: ContentReviewDto[]) => {
		dispatch(reviewMultiContentsEnd());
		dispatch(reviewContentEnd());

		let contentListTemp: Array<ContentContainerDto> = [];
		contentListTemp = [...contentList];

		contentListTemp.forEach((item) => {
			const content: ContentDto = getContentByType(item);

			reviewedContents.forEach((reviewed: ContentReviewDto) => {
				if (content.id === reviewed.contentId) {
					content.review = reviewed;
					content.review.isRecentlyAdded = false;
				}
			});
			const customCheck = content.review.custom;
			if (reviewedContents?.length === 1) {
				const customFieldUpdate = reviewedContents[0].custom;
				if (customFieldUpdate && customCheck) {
					customFieldUpdate.forEach((field) => {
						if (field.key && content.review.custom) {
							const idx = customCheck.findIndex((cField) => cField.key === field.key);
							if (idx !== -1) {
								const newCustoms = [...content.review.custom];
								newCustoms[idx].name = field.name;
								content.review.custom = newCustoms;
							}
						}
					});
				}
			}
		});

		if (contentIdMove) {
			contentListTemp = contentListTemp.filter((item) => {
				const contentTemp = getContentByType(item);
				return contentTemp.id !== contentIdMove;
			});
			setContentIdMove(null);
		}
		setContentList([...contentListTemp]);
		return null;
	};

	const handleUpdateNewVersion = (contentId: number, version: ImgVersionDto) => {
		const contentListTemp: Array<ContentContainerDto> = [...contentList];

		contentListTemp.forEach((item) => {
			const contentTemp: ContentDto = getContentByType(item);
			if (contentTemp?.id === contentId) {
				contentTemp.contentUrl = addTimeStampFromUrl(
					contentTemp.contentUrl,
					version?.contentURL,
					true,
				);
				contentTemp.thumbnail = addTimeStampFromUrl(
					contentTemp.thumbnail,
					version?.thumbnailURL,
					true,
				);
				contentTemp.contentMetadata = {
					...contentTemp.contentMetadata,
					...(version?.metadata || {}),
				};
			}
		});
	};

	const moveContent = (action: string) => {
		let currentIndexTemp: number = 0;
		const maxContentIndex = contentList.length - 1;

		if (action === 'next') {
			currentIndexTemp = currentIndex + 1;
		} else {
			currentIndexTemp = currentIndex - 1;
		}

		if (currentIndexTemp <= 0) {
			currentIndexTemp = 0;
		}

		if (currentIndexTemp >= maxContentIndex) {
			currentIndexTemp = maxContentIndex;
		}

		setCurrentIndex(currentIndexTemp);
		setCurrentContent(contentList[currentIndexTemp]);

		const res = checkPositionContent(currentIndexTemp, contentList.length);
		setIsFirstContent(res.isFirst);
		setIsLastContent(res.isLast);
	};

	const renderOtherProps = () => {
		const obj = {
			className: '',
			height: 'calc(9.5vw * (4 / 3))',
			heightMore: 'calc(8.5vw * (4 / 3))',
		};
		if (fullScreenSocial) {
			if (col > COL_MEDIA.C3) {
				obj.className = 'is_more';
			} else {
				obj.height = 'calc(10.5vw * (4 / 3))';
			}
		} else {
			if (col === COL_MEDIA.C3) {
				obj.className = 'is_more';
			}
		}
		return obj;
	};

	return (
		<>
			<ModalMediaDetail
				visible={!!currentContent}
				contentProps={{
					setContentIdMove,
					content: currentContent,
					onChangeAfterBlock,
					onChangeAfterAction,
					updateVerificationScrore,
					updateAfterReviewingContent,
					updateAfterChangeVersion: handleUpdateNewVersion,
					markAsFavoriteContent,
					folderId,
				}}
				isFirstContent={isFirstContent}
				isLastContent={isLastContent}
				moveContent={moveContent}
				onCancel={handleCloseContentDetails}
			/>
			<Row style={{ marginRight: 0 }} gutter={[12, 12]}>
				{contentList.map((item: ContentContainerDto, index: number) => {
					const contentConvert: ContentDto = getContentByType(item);

					return (
						<Col
							key={contentConvert.id}
							span={24 / col}
							onClick={() => handleClickImage(item, index)}>
							<StyledMediaItemContainer>
								{contentConvert?.mediaType &&
									contentConvert?.mediaType === FILTER_BY.VIDEO.value && (
										<StyledIconPlay className="center_child">
											<CaretRightOutlined />
										</StyledIconPlay>
									)}
								<StyledImage {...renderOtherProps()}>
									<DetectThumbnailContent
										thumbnail={getSocialS3URL(contentConvert?.thumbnail)}
										dynamicThumbnail={getSocialS3URL(
											contentConvert?.dynamicThumbnail,
										)}
										isVideo={contentConvert?.mediaType === MEDIA_TYPE.VIDEO}
										contentExternalUrl={contentConvert?.contentExternalUrl}
										social={contentConvert?.social}
									/>
								</StyledImage>

								<FavoriteBehavior
									content={contentConvert}
									markAsFavoriteContent={markAsFavoriteContent}
								/>
								{checkExistExplicit(contentConvert?.review?.flags) && (
									<ExplicitContent zIndex={777} sizeIcon={72} fontSize="16px" />
								)}
							</StyledMediaItemContainer>
						</Col>
					);
				})}
			</Row>
		</>
	);
};

export default SummaryContent;
