import { createContext } from 'react';

// Types
type RewardsHistoryContextTypes = {
	loadingDelete: boolean;
	loadingUpdate: boolean;
	selectedRowKeys: number[];
	getListRewardsParams: any;
	campaignList: any[];
	discountList: any[];
	handleChangeGetRewardsHistoryParams: Function;
	handleChangeSelectedRowKeys: Function;
	handleUpdateRewards: Function;
	handleDeleteRewards: Function;
};

// Default value
const data: RewardsHistoryContextTypes = {
	loadingDelete: false,
	loadingUpdate: false,
	selectedRowKeys: [],
	campaignList: [],
	discountList: [],
	getListRewardsParams: {},
	handleChangeGetRewardsHistoryParams: () => {},
	handleChangeSelectedRowKeys: () => {},
	handleUpdateRewards: (params: any) => {},
	handleDeleteRewards: () => {},
};

const RewardsHistoryContext = createContext(data);

export default RewardsHistoryContext;
