import React from 'react';
import { useSelector } from 'react-redux';
import AutomatedTemplates from './AutomatedTemplates';
import ArchivedTemplates from './ArchivedTemplates';
import CustomTemplates from './CustomTemplates';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';

const Previews = () => {
	const { emailTemplate: { folder = '' } = {} } = useSelector((state: any) => state.messages);

	return (
		<StyledWrapperContent height="100%">
			{folder === '1' && <AutomatedTemplates />}
			{folder === '2' && <CustomTemplates />}
			{folder === '3' && <ArchivedTemplates />}
		</StyledWrapperContent>
	);
};

export default Previews;
