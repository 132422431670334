import * as React from 'react';
import { Radio, Select, DatePicker, message, Button } from 'antd';
import styled from 'styled-components';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { headerSucceeded } from '@stores/header/header.actions';

// Models
import HeaderDto from '@models/header';
import { useTranslation } from 'react-i18next';
import { generateReport, generateReportEnd } from '@stores/actions';
import { FAILED, SUCCEEDED } from '@constants/status';
import { handleDownloadExcel } from '@helpers/base64Helper';

const StyledCreatorDashboard = styled.div`
	display: flex;
	align-items: center;
`;

const FilterCreatorDashboard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { generateReportStatus, generateReportFile } = useSelector(
		(state: any) => state.dashboard,
	);

	const handleGenerateReport = () => {
		dispatch(generateReport());
	};

	useEffect(() => {
		if (generateReportStatus === SUCCEEDED && generateReportFile) {
			handleDownloadExcel(generateReportFile, 'EnTribe_Report_Stats');
			message.info(t('dashboard.message_generate_report'));
		}
		if (generateReportStatus === SUCCEEDED || generateReportStatus === FAILED) {
			dispatch(generateReportEnd());
		}
	}, [generateReportStatus]);

	return (
		<StyledCreatorDashboard>
			<Button onClick={handleGenerateReport}>{t('dashboard.generate_report')}</Button>
		</StyledCreatorDashboard>
	);
};

export default React.memo(FilterCreatorDashboard);
