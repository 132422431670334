import styled from 'styled-components';
import { Input, Button, Checkbox, Popconfirm } from 'antd';
import THEME from '@constants/themes/themes';

const StyledActionBlock = styled.div`
	display: flex;
	flex-direction: row;
	height: 32px;
	align-items: center;
	justify-content: space-between;
`;

const StyledActionList = styled.div`
	display: flex;
	// justify-content: space-between;
	button {
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

const StyledSearch = styled(Input)`
	width: 200px !important;
	height: 32px;
	margin-right: 15px;
`;

const StyledToggleColumns = styled.div`
	button {
		width: 250px;
		display: flex;
		justify-content: space-between;
	}

	svg {
		margin-right: 0;
	}
`;

const StyledMenuColumns = styled.ul`
	list-type: none;
	display: flex;
	width: 250px;
	margin: 0;
	padding: 0;
	flex-direction: column;
	background-color: ${THEME.colors.darkBlue3};
`;

const StyledMenuColumnsItems = styled.li`
	padding: 6px 8px;
	height: 32px;
	display: flex;
	align-items: center;

	&:last-child {
		margin: 14px 0 18px 8px;
	}
`;

const StyledCheckboxItems = styled(Checkbox)`
	color: white;
	margin-left: 8px;
`;

const StyledButtonAction = styled(Button)`
	border-color: ${THEME.colors.darkBlue4};
`;

const StyledPopconfirm = styled(Popconfirm)`
	margin-right: 16px;
`;

export {
	StyledMenuColumns,
	StyledCheckboxItems,
	StyledButtonAction,
	StyledActionBlock,
	StyledActionList,
	StyledToggleColumns,
	StyledSearch,
	StyledMenuColumnsItems,
	StyledPopconfirm,
};
