/**
 * @prettier
 */

import styled from 'styled-components';
import { Collapse } from 'antd';
import THEME from '@constants/themes/themes';

const StyledContactBlock = styled.div`
	margin-top: 16px;
	border-radius: 2px;
`;

const StyledCollapse = styled(Collapse)<{ maxHeight?: string }>`
	background-color: ${THEME.colors.darkBlue2};
	.ant-collapse-header {
		color: ${THEME.colors.gray5} !important;

		* {
			font-size: 16px;
		}
	}
	.ant-collapse-content {
		padding: 10px 0 0 0;
		border-top: 1px solid ${THEME.colors.darkBlue3} !important;
		background-color: ${THEME.colors.darkBlue2};
		${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};  overflow: auto scroll`};

		&:hover {
			&::-webkit-scrollbar-thumb {
				visibility: unset;
			}
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			visibility: hidden;
		}
	}
	.ant-collapse-item {
		border: none;
	}
`;

const StyledHeader = styled.div`
	display: flex;
	svg {
		margin-right: 5px;
	}
`;

const fontText = () => `
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: normal;
	text-align: left;
`;

const StyledLabel = styled.div`
	${fontText()}
	color: ${THEME.colors.gray1};
`;

const StyledValue = styled.div`
	${fontText()}
	color: ${THEME.colors.white};
`;

export { StyledContactBlock, StyledCollapse, StyledHeader, StyledLabel, StyledValue };
