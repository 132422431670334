import GraphLikeComment from '@components/AmbassadorDashboard/GraphLikeComment';
import GraphSocialPost from '@components/AmbassadorDashboard/GraphSocialPost';
import LeaderBoard from '@components/AmbassadorDashboard/LeaderBoard';
import MetricAmbassador from '@components/AmbassadorDashboard/MetricAmbassador';
import SocialActivity from '@components/AmbassadorDashboard/SocialActivity';
import TopAction from '@components/AmbassadorDashboard/TopAction';
import { ValueSelectIdTypes } from '@components/Filter/FilterDropdownSelect';
import { FilterSelectTypes } from '@components/Filter/FilterSelect';
import { SORT_BY, SUB_ID } from '@constants/ambassador';
import { DEFAULT_MEDIA } from '@constants/social_search';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import AmbassadorDashboardManagerContext from '@contexts/AmbassadorDashboard';
import { LoadingWrapper } from '@cores/index';
import { AmbassadorManagerStoreDto } from '@models/ambassador/stores';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import {
	getAmbassadorLeaderBoardRequest,
	getAmbassadorMetricRequest,
	getListCampaignSimpleRequest,
	getSegment,
	resetFieldsAmbassadorData,
	updateAccountSettingEnd,
} from '@stores/actions';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { Col, Row, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const AmbassadorDashboard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		loadingAmbassadorReport,
		loadingRefreshMetricAmbassador,
		error = '',
	}: AmbassadorManagerStoreDto = useSelector((state: any) => state.ambassadors);
	const { updateAccountStatus, error: errorContent }: ContentStoreDto = useSelector(
		(state: any) => state.content,
	);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [fullScreenSocial, setFullScreenSocial] = useState<boolean>(false);
	const [sortByLeaderBoard, setSortByLeaderBoard] = useState<string>(SORT_BY.SUBMISSIONS);
	const [showRecentBox, setShowRecentBox] = useState<boolean>(false);
	const [campaignSelectValue, setCampaignSelectValue] = useState<ValueSelectIdTypes>(null);
	const [segmentSelectValue, setSegmentSelectValue] = useState<ValueSelectIdTypes>(null);
	const [fieldDisabled, setFieldDisable] = useState<string[]>([]);

	const campaignIdSelected = useMemo(() => {
		if (campaignSelectValue && typeof campaignSelectValue === 'string') {
			return +campaignSelectValue.slice(SUB_ID.CAMPAIGN.length);
		}
		return null;
	}, [campaignSelectValue]);

	const segmentIdSelected = useMemo(() => {
		if (segmentSelectValue && typeof segmentSelectValue === 'string') {
			return +segmentSelectValue.slice(SUB_ID.SEGMENT.length);
		}
		return null;
	}, [segmentSelectValue]);

	useEffect(() => {
		dispatch(
			getAmbassadorMetricRequest({
				campaignId: campaignIdSelected,
				segmentId: segmentIdSelected,
			}),
		);
	}, [campaignSelectValue, segmentSelectValue]);

	useEffect(() => {
		if (campaignSelectValue && sortByLeaderBoard === SORT_BY.TASKS) {
			setSortByLeaderBoard(SORT_BY.VOTES);
		}
	}, [campaignSelectValue]);

	useEffect(() => {
		dispatch(getListCampaignSimpleRequest({}));
		dispatch(getSegment());
		return () => {
			dispatch(resetFieldsAmbassadorData({ posts: { ...DEFAULT_MEDIA } }));
		};
	}, []);

	useEffect(() => {
		const otherRequest: any = campaignIdSelected ? { campaignId: campaignIdSelected } : {};
		if (segmentSelectValue) {
			otherRequest.segmentId = segmentIdSelected;
		}

		if (!campaignSelectValue || sortByLeaderBoard !== SORT_BY.TASKS) {
			dispatch(
				getAmbassadorLeaderBoardRequest({
					sortBy: sortByLeaderBoard,
					...otherRequest,
				}),
			);
		}
	}, [sortByLeaderBoard, campaignSelectValue, segmentSelectValue]);

	useEffect(() => {
		if (updateAccountStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (updateAccountStatus === FAILED) {
			message.error(errorContent);
		}
		if (updateAccountStatus === SUCCEEDED || updateAccountStatus === FAILED) {
			dispatch(updateAccountSettingEnd());
			setIsLoading(false);
		}
	}, [updateAccountStatus]);

	const valueContext = {
		fullScreenSocial,
		setFullScreenSocial,
		setSortByLeaderBoard,
		sortByLeaderBoard,
		showRecentBox,
		setShowRecentBox,
		campaignSelectValue,
		setCampaignSelectValue,
		campaignIdSelected,
		segmentSelectValue,
		setSegmentSelectValue,
		segmentIdSelected,
		fieldDisabled,
		setFieldDisable,
	};

	return (
		<AmbassadorDashboardManagerContext.Provider value={valueContext}>
			<LoadingWrapper
				isLoading={isLoading || loadingAmbassadorReport || loadingRefreshMetricAmbassador}>
				<StyledWrapperContent width="100%" height="100%">
					<TopAction />
					<MetricAmbassador />
					<StyledWrapperContent height="calc(100vh - 213px)" width="100%">
						<StyledWrapperContent
							justifyContent="space-between"
							display="flex"
							width="100%"
							height="100%">
							{!fullScreenSocial && (
								<StyledWrapperContent
									height="100%"
									width="40%"
									padding="0 6px 0 0"
									margin="0"
									className="custom_scroll_bar"
									overflow="auto overlay">
									<Row
										style={{
											width: '100%',
											margin: '0',
										}}
										gutter={[12, 12]}>
										<Col style={{ width: '100%', padding: '0' }} span={24}>
											<LeaderBoard />
										</Col>
										<Col style={{ width: '100%', padding: '0' }} span={24}>
											<GraphSocialPost />
										</Col>
										<Col style={{ width: '100%', padding: '0' }} span={24}>
											<GraphLikeComment />
										</Col>
									</Row>
								</StyledWrapperContent>
							)}
							<StyledWrapperContent
								width={fullScreenSocial ? '100%' : 'calc(60% - 6px)'}
								height="100%">
								<SocialActivity heightSpace={0} />
							</StyledWrapperContent>
						</StyledWrapperContent>
					</StyledWrapperContent>
				</StyledWrapperContent>
			</LoadingWrapper>
		</AmbassadorDashboardManagerContext.Provider>
	);
};

export default AmbassadorDashboard;
