import { KEY_FIELD_FORM_TERM } from '@constants/content/widgetManager';
import { RefConfigWidgetBuilderStep } from '@models/content/widgetManager/ref';
import { StyledDivider } from '@styled/Common/CommonStyled';
import { Ref, forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import EmailOptIns from './EmailOptIns';
import TemplateTerms from './TemplateTerms';

const StyledStep = styled.div`
	padding: 16px 24px;
`;

type PropSettingStep4 = {};

const arrKey = [
	KEY_FIELD_FORM_TERM.text,
	KEY_FIELD_FORM_TERM.textLink,
	KEY_FIELD_FORM_TERM.valueTextLink,
	KEY_FIELD_FORM_TERM.buttonText,
];

const SettingStep4 = forwardRef((props: PropSettingStep4, ref: Ref<RefConfigWidgetBuilderStep>) => {
	const { t } = useTranslation();
	const emailOptInsRef = useRef<any>();

	const { clientSettings } = useSelector((state: any) => state.sidebar);

	const validateCustomField = async () => {};

	useImperativeHandle(ref, () => ({
		validateAllFieldCustom: validateCustomField,
	}));

	return (
		<>
			<StyledStep>
				<TemplateTerms />

				<StyledDivider margin="24px auto 16px auto" />

				<EmailOptIns ref={emailOptInsRef} />
			</StyledStep>
		</>
	);
});

export default SettingStep4;
