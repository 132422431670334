import {
	IconClose,
	IconEdit,
	IconEye,
	IconInstagramWhite,
	IconKeyboardArrowLeft,
	IconKeyboardArrowRight,
	IconTwitterWhite,
} from '@assets/icons';
import { ImageLogoTextWhite } from '@assets/images';
import { linkEntribe } from '@config/index';
import { KEYBOARD, TYPE_ACTIONS } from '@constants/common';
import { MEDIA_TYPE, VIEW_TYPE } from '@constants/content/contentLibrary';
import {
	GALLERY_EDITOR_CONFIG,
	MEDIA_SIZE_DEFAULT,
	OPTION_LIGHTBOX,
} from '@constants/publish/gallery';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { URlAndNameInstagramTwitter } from '@models/publishing/galleries';
import {
	updateCustomDetailContentGalleryEnd,
	updateCustomDetailContentGalleryRequest,
} from '@stores/actions';
import {
	StyledIcon,
	StyledTitle,
	StyledWrapperContent as StyledWrapperContentCommons,
} from '@styled/Common/CommonStyled';
import { StyledImageLogoFooter } from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import {
	StyledActionEditor,
	StyledCaption,
	StyledContainer,
	StyledContentCustomDetail,
	StyledFooterLightBox,
	StyledIconChangeContent,
	StyledIconClose,
	StyledLinkFooterEntribe,
	StyledNameUser,
	StyledPreviewThumbnailModal,
	StyledThumbnail,
	StyledWrapperContainer,
	StyledWrapperContent,
	StyledWrapperEditor,
	StyledWrapperPopup,
	StyledWrapperUserName,
} from '@styled/Publish/Gallery/PreviewThumbnailModalStyled';
import { getSocialS3URL } from '@utils/common';
import { Button, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// style editor
import DetectContentUrl from '@components/ContentGlobal/ContentDetail/DetectContentUrl';
import EditorCustom from '@cores/EditorCustom';
import AdditionalContentGallery from './AdditionalContentGallery';
import { StyledMarkExplicit } from '@styled/Content/ContentLibrary/MediaItemStyled';
import THEME from '@constants/themes/themes';
import { checkExistExplicit } from '@utils/content';

type PropsPreviewThumbnail = {
	[other: string]: any;
	showDetailLightBox: Array<string>;
	contentSelect: ContentDto;
	onCancel: () => void;
	handleChangeContentSelect: (val: string) => void;
	infoUser: URlAndNameInstagramTwitter;
};
const PreviewThumbnailModal = (props: PropsPreviewThumbnail) => {
	const {
		showDetailLightBox,
		contentSelect,
		onCancel,
		handleChangeContentSelect,
		infoUser,
		...other
	} = props;

	const {
		creatorProfile,
		description = '',
		mediaType,
		contentUrl,
		review,
		additionalInfo,
	} = contentSelect;
	const { firstName = '', lastName = '' } = creatorProfile || {};
	const { productDepicted = '', custom: customList = [] } = review || {};
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { statusChangeCustomDetailContent, error } = useSelector(
		(state: any) => state.galleryManager,
	);

	const imgRef = useRef<any>({});
	const videoRef = useRef<any>({});
	const editorRef = useRef<any>({});

	const [sizeImg, setSizeImg] = useState<number>(MEDIA_SIZE_DEFAULT);
	const [classImage, setClassImage] = useState<string>(VIEW_TYPE.SQUARE);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [editorContent, setEditorContent] = useState<string>('');
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [visibleImage, setVisibleImage] = useState<boolean>(true);
	const [showExplicit, setShowExplicit] = useState<boolean>(false);

	useEffect(() => {
		if (contentSelect) {
			setEditorContent(productDepicted);
		}
	}, [contentSelect]);

	useEffect(() => {
		setShowExplicit(checkExistExplicit(contentSelect?.review?.flags));
	}, [contentSelect?.id, contentSelect?.review?.flags]);

	useEffect(() => {
		return () => {
			setClassImage(VIEW_TYPE.SQUARE);
			setSizeImg(MEDIA_SIZE_DEFAULT);
		};
	}, [contentUrl]);

	useEffect(() => {
		return () => {
			setVisibleImage(true);
		};
	}, []);

	useEffect(() => {
		if (statusChangeCustomDetailContent === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (statusChangeCustomDetailContent === FAILED) {
			message.error(error);
		}
		if (statusChangeCustomDetailContent === SUCCEEDED) {
			message.success(t('gallery_manager.message.change_saved'));
		}
		if (
			statusChangeCustomDetailContent === SUCCEEDED ||
			statusChangeCustomDetailContent === FAILED
		) {
			setIsLoading(false);
			setIsEdit(false);
			dispatch(updateCustomDetailContentGalleryEnd());
		}
	}, [statusChangeCustomDetailContent]);

	useEffect(() => {
		if (videoRef.current || imgRef.current) {
			const onKeyup = (e: KeyboardEvent) => {
				const onCheckEvent = (callback: Function) => {
					const activeElement = document.activeElement;
					const inputs = ['input', 'select', 'button', 'textarea', 'video'];
					const skipEvent =
						activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;

					if (!skipEvent) {
						e.preventDefault();
						e.stopPropagation();
						e.stopImmediatePropagation();
						callback();
					}
				};

				if (e.code === KEYBOARD.ARROW_LEFT.STR) {
					const func = () => {
						handleChangeContentSelect(TYPE_ACTIONS.PREV);
						setIsLoading(false);
						setIsEdit(false);
					};
					onCheckEvent(func);
				}
				if (e.code === KEYBOARD.ARROW_RIGHT.STR) {
					const func = () => {
						handleChangeContentSelect(TYPE_ACTIONS.NEXT);
						setIsLoading(false);
						setIsEdit(false);
					};
					onCheckEvent(func);
				}
			};
			window.addEventListener('keyup', onKeyup);
			return () => window.removeEventListener('keyup', onKeyup);
		}
		return () => {};
	}, [videoRef.current, imgRef.current, contentSelect?.id]);

	const renderThumbnail = () => {
		const url: string = getSocialS3URL(contentUrl);

		const isVideo = mediaType === MEDIA_TYPE.VIDEO;

		return (
			<DetectContentUrl
				isVideo={isVideo}
				visibleImage={visibleImage}
				setVisibleImage={setVisibleImage}
				contentUrl={url}
				classChangeSize={classImage}
				contentExternalUrl={contentSelect?.contentExternalUrl}
				onChangeMediaSize={setSizeImg}
				setClassImage={setClassImage}
				videoRef={videoRef}
				imgRef={imgRef}
				social={contentSelect?.social}>
				{isVideo && <track kind="captions" />}
				{showExplicit && (
					<StyledMarkExplicit zIndex={1234}>
						<StyledWrapperContentCommons textAlign="center" width="272px">
							<StyledIcon cursor="unset" fillPath={THEME.colors.gray4} size={120}>
								<IconEye />
							</StyledIcon>
							<StyledTitle margin="8px 0" className="unset_height" fontSize="24px">
								{t('content_management.warning')}
							</StyledTitle>
							<StyledTitle className="unset_height" fontSize="16px">
								{t('content_management.sub_sensitive_content')}
							</StyledTitle>
							<StyledTitle
								className="unset_height text_action"
								fontSize="16px"
								margin="20px 0 0 0"
								onClick={() => setShowExplicit(false)}
								fontWeight="700"
								textDecoration="underline">
								{t('content_management.view_content')}
							</StyledTitle>
						</StyledWrapperContentCommons>
					</StyledMarkExplicit>
				)}
			</DetectContentUrl>
		);
	};

	const onClosePreview = () => {
		setSizeImg(MEDIA_SIZE_DEFAULT);
		setClassImage(VIEW_TYPE.SQUARE);
		onCancel();
	};

	const handleBlockEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
	};

	const handleEditorContent = (content: string) => {
		setEditorContent(content);
	};

	const handleSaveCustomDetail = () => {
		const valueChange = editorContent?.toString()?.trim();
		if (valueChange === productDepicted) {
			setIsEdit(false);
			return message.error(t('content_management.notification.no_changes_warning'));
		}
		dispatch(
			updateCustomDetailContentGalleryRequest({
				id: contentSelect?.id,
				content: editorContent?.toString()?.trim() || '',
			}),
		);
		return null;
	};

	return (
		<StyledPreviewThumbnailModal
			onCancel={onClosePreview}
			width={1200}
			footer={null}
			closable={false}
			zIndex={1020}
			centered
			{...other}>
			<LoadingWrapper isLoading={isLoading}>
				<StyledWrapperPopup onClick={onClosePreview}>
					<StyledIconChangeContent
						onClick={(e) => {
							handleBlockEvent(e);
							handleChangeContentSelect(TYPE_ACTIONS.PREV);
						}}
						className="prev">
						<IconKeyboardArrowLeft />
					</StyledIconChangeContent>
					<StyledIconChangeContent
						onClick={(e) => {
							handleBlockEvent(e);
							handleChangeContentSelect(TYPE_ACTIONS.NEXT);
						}}
						className="next">
						<IconKeyboardArrowRight />
					</StyledIconChangeContent>
					<StyledWrapperContainer onClick={handleBlockEvent}>
						<StyledThumbnail style={{ height: `${sizeImg}px` }}>
							{renderThumbnail()}
						</StyledThumbnail>

						<StyledContainer height={sizeImg}>
							<StyledWrapperContent>
								{showDetailLightBox?.includes(
									OPTION_LIGHTBOX.showInstagramUsername,
								) &&
									infoUser?.instagramUsername && (
										<StyledWrapperUserName>
											<StyledNameUser
												href={infoUser?.hrefInstagram}
												target="_blank">
												<StyledIcon>
													<IconInstagramWhite />
												</StyledIcon>
												{infoUser?.instagramUsername}
											</StyledNameUser>
										</StyledWrapperUserName>
									)}
								{showDetailLightBox?.includes(
									OPTION_LIGHTBOX.showTwitterUsername,
								) &&
									infoUser?.twitterUsername && (
										<StyledWrapperUserName>
											<StyledNameUser
												href={infoUser?.hrefTwitter}
												target="_blank">
												<StyledIcon>
													<IconTwitterWhite />
												</StyledIcon>
												{infoUser?.twitterUsername}
											</StyledNameUser>
										</StyledWrapperUserName>
									)}
								{showDetailLightBox?.includes(OPTION_LIGHTBOX.customFields) &&
									customList &&
									customList.length > 0 && (
										<AdditionalContentGallery additionalFields={customList} />
									)}
								{showDetailLightBox?.includes(OPTION_LIGHTBOX.customDetails) && (
									<StyledWrapperEditor className={isEdit ? 'show_edit' : ''}>
										{!isEdit && (
											<>
												<StyledContentCustomDetail
													dangerouslySetInnerHTML={{
														__html: productDepicted,
													}}
												/>
												<StyledIcon
													className="icon_orange"
													onClick={() => {
														setIsEdit(true);
														setIsLoading(true);
													}}
													style={{
														position: 'absolute',
														top: '8px',
														right: '8px',
													}}>
													<IconEdit />
												</StyledIcon>
											</>
										)}
										{isEdit && (
											<>
												<EditorCustom
													editorContent={editorContent || ''}
													setEditorContent={(val: string) => {
														handleEditorContent(val);
													}}
													otherInit={{
														plugins: GALLERY_EDITOR_CONFIG.PLUGINS,
														toolbar: GALLERY_EDITOR_CONFIG.TOOLBAR,
													}}
													callbackInit={() => setIsLoading(false)}
												/>

												<StyledActionEditor
													onClick={handleSaveCustomDetail}>
													<Button type="primary">
														{t('button.save')}
													</Button>
												</StyledActionEditor>
											</>
										)}
									</StyledWrapperEditor>
								)}
								{showDetailLightBox?.includes(OPTION_LIGHTBOX.showCaption) &&
									description && (
										<StyledCaption>{`“ ${description} ”`}</StyledCaption>
									)}
								{showDetailLightBox?.includes(
									OPTION_LIGHTBOX.showFirstNameLastInitial,
								) &&
									(firstName || lastName) && (
										<StyledCaption>
											{`- ${firstName || ''} ${lastName?.slice(0, 1) || ''}.`}
										</StyledCaption>
									)}
							</StyledWrapperContent>
							<StyledIconClose onClick={() => onClosePreview()}>
								<IconClose />
							</StyledIconClose>
							<StyledFooterLightBox>
								<StyledLinkFooterEntribe href={linkEntribe} target="_blank">
									{t('content.widget_manager.widget_detail_setting.powered_by')}
									<StyledImageLogoFooter src={ImageLogoTextWhite} />
								</StyledLinkFooterEntribe>
							</StyledFooterLightBox>
						</StyledContainer>
					</StyledWrapperContainer>
				</StyledWrapperPopup>
			</LoadingWrapper>
		</StyledPreviewThumbnailModal>
	);
};

export default PreviewThumbnailModal;
