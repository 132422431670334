import THEME from '@constants/themes/themes';
import { StyledModal, StyledWrapperContent, StyledWrapperTitle } from '@styled/Common/CommonStyled';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
	max-height: 75vh;
	overflow: hidden auto;
	background-color: ${THEME.colors.white};
	color: ${THEME.colors.black};
	padding: 16px;
`;

type PropTypes = {
	visible: boolean;
	onCancel: () => void;
	content: string;
	title: string;
	textMustView?: string;
};

const PreviewTermsModal = (props: PropTypes) => {
	const { visible, onCancel, content, title, textMustView } = props;

	return (
		<StyledModal
			className="modal_empty modal_white"
			visible={visible}
			title={title}
			zIndex={1234}
			onCancel={onCancel}
			footer={
				textMustView && (
					<StyledWrapperTitle width="100%" justifyContent="center">
						<Button type="primary">{textMustView}</Button>
					</StyledWrapperTitle>
				)
			}
			centered>
			<StyledContainer
				className="custom_scroll_bar"
				dangerouslySetInnerHTML={{ __html: content }}
			/>
		</StyledModal>
	);
};

export default PreviewTermsModal;
