import { ConfigProvider, Form, Pagination } from 'antd';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Icon
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';

// Styled
import { StyledEmpty, StyledPagination, StyledWrapper } from '@styled/Rewards/StyledTable';

// Constant
import { Paginator } from '@constants/paginator';

// Context
import { StyledTable } from '@components/CustomTable';
import RewardsHistoryContext from '@contexts/Rewards/History';
import { RewardsHistorySortDto } from '@models/rewards/log';

type ActionProps = {
	// selectedSegments?: any;
	columns: any[];
};

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

const HistoryList = ({ columns }: ActionProps) => {
	const { t } = useTranslation();

	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const {
		selectedRowKeys,
		getListRewardsParams,
		handleChangeSelectedRowKeys,
		handleChangeGetRewardsHistoryParams,
	} = useContext(RewardsHistoryContext);
	const [pageCurrent, setPageCurrent] = useState<number>(1);

	const { rewardsHistoryList, fetchingRewardsHistory, totalRewardsHistoryRecords } = useSelector(
		(state: any) => state.rewards,
	);

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		handleChangeSelectedRowKeys(selectedRowKeys);
	};

	const onSelectAllChange = () => {};

	const onChangePage = (pageNumber: number) => {
		setPageCurrent(pageNumber);
		getRewardsData(pageNumber);
	};

	const getRewardsData = (pageNumber?: number) => {
		const { params } = getListRewardsParams;

		const payload = {
			params: {
				...params,
				maxRecords: Paginator.pageSize,
				page: pageNumber ? pageNumber : Paginator.defaultPage,
			},
		};
		handleChangeGetRewardsHistoryParams(payload);
		// dispatch(getRewardsHistory(params));
	};

	const onChangeTable = (pagination: any, filters: any, sorter: any, extra: any) => {
		const { field, order } = sorter;
		const { params } = getListRewardsParams;
		const sortOrders: RewardsHistorySortDto = {
			ascend: 'asc',
			descend: 'desc',
		};

		const sortFields: RewardsHistorySortDto = {
			id: 'id',
			contentId: 'contentId',
			campaignId: 'campaignId',
			campaignName: 'campaignName',
			creatorId: 'creatorId',
			emailHistoryId: 'emailHistoryId',
			rewardDate: 'rewardDate',
			estimatedCashValue: 'estimatedCashValue',
			actualCashValue: 'actualCashValue',
			discountAmount: 'discountAmount',
			status: 'status',
			discountType: 'discountType',
			creatorFirstName: 'creatorFirstName',
			creatorLastName: 'creatorLastName',
			creatorEmail: 'creatorEmail',
			codeValue: 'codeValue',
			ruleTitle: 'ruleTitle',
			ruleDescription: 'ruleDescription',
			rewardType: 'rewardType',
			productUrl: 'productUrl',
			productThumbnail: 'productThumbnail',
			productWebUrl: 'productWebUrl',
			creatorName: 'creatorName',
			ruleId: 'ruleId',
		};

		const payload = {
			params: {
				...params,
				sortOrder: sortOrders[order],
				sortField: sortFields[field],
			},
		};
		handleChangeGetRewardsHistoryParams(payload);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		onSelectAll: onSelectAllChange,
	};

	return (
		<ConfigProvider renderEmpty={customizeRenderEmpty}>
			<div>
				{' '}
				<StyledWrapper className="card-content card-bg">
					<StyledTable
						loading={fetchingRewardsHistory}
						dataSource={rewardsHistoryList}
						columns={columns}
						pagination={false}
						rowSelection={rowSelection}
						onChange={onChangeTable}
						scroll={{ y: 'calc(100vh - 242px)' }}
					/>
				</StyledWrapper>
				{/* <Affix offsetBottom={0}> */}
				<StyledPagination>
					<Pagination
						total={totalRewardsHistoryRecords}
						pageSize={Paginator.pageSize}
						defaultPageSize={Paginator.pageSize}
						current={getListRewardsParams.params.page}
						showSizeChanger={false}
						showQuickJumper
						onChange={onChangePage}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${total || 0} ${t(
								`${total > 1 ? 'pagination.items' : 'pagination.item'}`,
							)}`
						}
					/>
				</StyledPagination>
				{/* </Affix> */}
			</div>
		</ConfigProvider>
	);
};

export default React.memo(HistoryList);
