import styled from 'styled-components';
import THEME from '@constants/themes/themes';
import { Layout, Button, Tooltip } from 'antd';

export const StyledLayout = styled(Layout)`
	height: 100vh;
	position: relative;

	@media only screen and (max-width: 667px) {
		height: 100vh;
	}
`;

export const StyledFormWrapper = styled(Layout.Content)<{ isLoading?: boolean }>`
	background-color: ${({ isLoading }) => (isLoading ? 'none' : THEME.colors.darkBlue2)};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 100px 78px;
	width: 566px;

	#register-form {
		.ant-form-item {
			margin-bottom: 25px;

			&:nth-child(4) {
				margin-bottom: 38px;
			}
		}
	}

	@media only screen and (max-width: 667px) {
		padding: 50px 20px;
		width: 100%;
	}

	.ant-input-suffix {
		// padding-right: 0 !important;
	}

	.ant-form-item-children-icon {
		:not(.anticon-close-circle) {
			svg {
				fill: none;
			}
		}
		.anticon-close-circle {
			svg {
				// margin-left: 18px !important;
				fill: red;
			}
		}
	}
`;

export const StyledTitle = styled.div<{
	fontSize?: string;
	color?: string;
	margin?: string;
	cursor?: string;
	border?: string;
	fontWeight?: string;
	widthIcon?: string;
	height?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	font-stretch: normal;
	font-style: normal;
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	line-height: 1.429rem;
	letter-spacing: normal;
	margin: ${({ margin }) => margin || '0'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	cursor: ${({ cursor }) => cursor};
	${({ border }) => border && `border-${border}: 1px solid ${THEME.colors.darkBlue3}`}

	svg {
		width: ${({ widthIcon }) => widthIcon || '20px'};
		margin-right: 6px;
	}
	a {
		margin-left: 5px;
	}
`;

export const StyledButton = styled(Button)`
	width: 100%;
	height: 52px;
`;

export const StyledTooltip = styled(Tooltip)`
	&.ant-tooltip-open {
		position: absolute;
		right: 0;
		top: -13px;
	}
`;

export const StyledLogo = styled.div<{ width?: string }>`
	img {
		width: ${({ width }) => width || '238px'};
		object-fit: cover;
	}
`;
