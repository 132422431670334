import ApprovalStatus from '@components/Creators/ApprovalStatus';
import { STATUS_MODES } from '@constants/common';
import { CreatorProfileSummaryDto } from '@models/creator/profile';
import { StyledCol, StyledRow } from '@styled/Common/CommonStyled';
import { useTranslation } from 'react-i18next';

type CreatorStatusProps = {
	creatorDetails: CreatorProfileSummaryDto | null;
	setCreatorDetails: (creatorDetails: CreatorProfileSummaryDto) => void;
};

const CreatorStatus = (props: CreatorStatusProps) => {
	const { creatorDetails, setCreatorDetails } = props;
	const { t } = useTranslation();
	const { approvalStatus = STATUS_MODES.NONE } = creatorDetails || {};

	const statusList = [
		{
			label: t('creator_profile.approval'),
			status: approvalStatus,
			fieldName: 'approvalStatus',
		},
	];

	const switchStatus = (fieldName: string, status: string) => {
		if (creatorDetails) {
			const updatedDetails: CreatorProfileSummaryDto = { ...creatorDetails };
			updatedDetails[fieldName] = status.toUpperCase();
			setCreatorDetails(updatedDetails);
		}
	};

	const renderStatus = () => {
		return (
			<StyledRow>
				{statusList.map((item) => {
					return (
						<StyledCol span={12}>
							<StyledRow align="middle">
								<StyledCol span={6}>{item.label}</StyledCol>
								<StyledCol span={12}>
									<ApprovalStatus
										status={item.status}
										onChangeStatus={(status: string) =>
											switchStatus(item.fieldName, status)
										}
									/>
								</StyledCol>
							</StyledRow>
						</StyledCol>
					);
				})}
			</StyledRow>
		);
	};

	return <div>{renderStatus()}</div>;
};

export default CreatorStatus;
