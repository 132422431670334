import { LocationDashboardType } from '@models/dashboard';
import { createContext } from 'react';

// Types

type CreatorDashboardContextTypes = {
	isFetchingCreatorStatisticData: boolean;
	isFetchingCreatorLeaderboardData: boolean;
	isFetchingCreatorLocationData: boolean;
	isFetchingContentStatisticData: boolean;
	isFetchingContentGraphData: boolean;
	isFetchingContentRecentSubmissionsData: boolean;
	creatorStatisticData: any;
	creatorLeaderboardData: any;
	creatorLocationData: LocationDashboardType[];
	contentStatisticData: any;
	contentGraphData: any;
	contentRecentSubmissionsData: any;
	data: any;
	isFilterByCampaign: boolean;
};

// Default value
const data: CreatorDashboardContextTypes = {
	isFetchingCreatorStatisticData: true,
	isFetchingCreatorLeaderboardData: true,
	isFetchingCreatorLocationData: true,
	isFetchingContentStatisticData: true,
	isFetchingContentGraphData: true,
	isFetchingContentRecentSubmissionsData: true,
	creatorStatisticData: { creators: 0, estimatedTimeSaved: 0, rewards: 0 },
	creatorLeaderboardData: {},
	creatorLocationData: [],
	contentStatisticData: {},
	contentGraphData: {},
	contentRecentSubmissionsData: {},
	data: {},
	isFilterByCampaign: false,
};

const CreatorDashboardContext = createContext(data);

export default CreatorDashboardContext;
