import { DYNAMIC_FIELDS, OFFERS_REWARD } from '@constants/messages';
import { EmailPayloadDto } from '@models/messages/summary';
import _ from 'lodash';

const checkEmptyRewardValue = (appliedReward: any, field?: string) => {
	const {
		title,
		codeValue,
		estimatedCashValue,
		actualCashValue,
		discountAmount,
		description,
		product,
	} = appliedReward.rewardValue;
	const { rewardType } = appliedReward;

	switch (field) {
		case DYNAMIC_FIELDS.REWARD_TYPE:
			return _.isNull(rewardType) || _.isUndefined(rewardType) || rewardType === '';

		case DYNAMIC_FIELDS.REWARD_NAME:
			return _.isNull(title) || _.isUndefined(title) || title === '';

		case DYNAMIC_FIELDS.REWARD_AMOUNT: {
			const isGiftCard = rewardType === OFFERS_REWARD.GIFT_CARD.value;
			const value = isGiftCard ? estimatedCashValue : discountAmount;

			return _.isNull(value) || _.isUndefined(value);
		}

		case DYNAMIC_FIELDS.REWARD_CODE:
			return _.isNull(codeValue) || _.isUndefined(codeValue) || codeValue === '';

		case DYNAMIC_FIELDS.ESTIMATED_CASH_VALUE:
			return (
				_.isNull(estimatedCashValue) ||
				_.isUndefined(estimatedCashValue) ||
				estimatedCashValue === ''
			);

		case DYNAMIC_FIELDS.ACTUAL_CASH_VALUE:
			return (
				_.isNull(actualCashValue) ||
				_.isUndefined(actualCashValue) ||
				actualCashValue === ''
			);

		case DYNAMIC_FIELDS.REWARD_DESCRIPTION:
			return _.isNull(description) || _.isUndefined(description) || description === '';

		case DYNAMIC_FIELDS.REWARD_PRODUCT_LINK:
			return (
				_.isNull(product) ||
				(!_.isNull(product) &&
					(_.isNull(product?.webUrl) ||
						_.isUndefined(product?.webUrl) ||
						product?.webUrl === ''))
			);

		case DYNAMIC_FIELDS.PRODUCT_THUMBNAIL:
			return (
				_.isNull(product) ||
				(!_.isNull(product) &&
					(_.isNull(product?.thumbnail) ||
						_.isUndefined(product?.thumbnail) ||
						product?.thumbnail === ''))
			);

		default:
			return null;
	}
};

const validateAllDynamicFields = (
	emailRequest: EmailPayloadDto,
	creators: any[],
	appliedReward: any,
) => {
	const { body } = emailRequest;
	let emptyFields: string = '';

	if (
		body?.includes(DYNAMIC_FIELDS.FIRST_NAME) &&
		checkEmptyCreatorValue(creators, DYNAMIC_FIELDS.FIRST_NAME)
	) {
		emptyFields += `${DYNAMIC_FIELDS.FIRST_NAME}\n`;
	}

	if (
		body?.includes(DYNAMIC_FIELDS.LAST_NAME) &&
		checkEmptyCreatorValue(creators, DYNAMIC_FIELDS.LAST_NAME)
	) {
		emptyFields += `${DYNAMIC_FIELDS.LAST_NAME}\n`;
	}

	if (
		body?.includes(DYNAMIC_FIELDS.INSTAGRAM_USERNAME) &&
		checkEmptyCreatorValue(creators, DYNAMIC_FIELDS.INSTAGRAM_USERNAME)
	) {
		emptyFields += `${DYNAMIC_FIELDS.INSTAGRAM_USERNAME}\n`;
	}

	if (
		body?.includes(DYNAMIC_FIELDS.TIKTOK_USERNAME) &&
		checkEmptyCreatorValue(creators, DYNAMIC_FIELDS.TIKTOK_USERNAME)
	) {
		emptyFields += `${DYNAMIC_FIELDS.TIKTOK_USERNAME}\n`;
	}

	if (
		body?.includes(DYNAMIC_FIELDS.YOUTUBE_USERNAME) &&
		checkEmptyCreatorValue(creators, DYNAMIC_FIELDS.YOUTUBE_USERNAME)
	) {
		emptyFields += `${DYNAMIC_FIELDS.YOUTUBE_USERNAME}\n`;
	}

	if (
		body?.includes(DYNAMIC_FIELDS.TWITTER_USERNAME) &&
		checkEmptyCreatorValue(creators, DYNAMIC_FIELDS.TWITTER_USERNAME)
	) {
		emptyFields += `${DYNAMIC_FIELDS.TWITTER_USERNAME}\n`;
	}

	if (!_.isEmpty(appliedReward) || !_.isUndefined(appliedReward)) {
		if (
			body?.includes(DYNAMIC_FIELDS.REWARD_TYPE) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.REWARD_TYPE)
		) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_TYPE}\n`;
		}

		if (
			body?.includes(DYNAMIC_FIELDS.REWARD_NAME) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.REWARD_NAME)
		) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_NAME}\n`;
		}

		if (
			body?.includes(DYNAMIC_FIELDS.REWARD_AMOUNT) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.REWARD_AMOUNT)
		) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_AMOUNT}\n`;
		}

		if (
			body?.includes(DYNAMIC_FIELDS.REWARD_CODE) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.REWARD_CODE)
		) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_CODE}\n`;
		}

		if (
			body?.includes(DYNAMIC_FIELDS.ESTIMATED_CASH_VALUE) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.ESTIMATED_CASH_VALUE)
		) {
			emptyFields += `${DYNAMIC_FIELDS.ESTIMATED_CASH_VALUE}\n`;
		}

		if (
			body?.includes(DYNAMIC_FIELDS.ACTUAL_CASH_VALUE) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.ACTUAL_CASH_VALUE)
		) {
			emptyFields += `${DYNAMIC_FIELDS.ACTUAL_CASH_VALUE}\n`;
		}

		if (
			body?.includes(DYNAMIC_FIELDS.REWARD_PRODUCT_LINK) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.REWARD_PRODUCT_LINK)
		) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_PRODUCT_LINK}\n`;
		}

		if (
			body?.includes(DYNAMIC_FIELDS.PRODUCT_THUMBNAIL) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.PRODUCT_THUMBNAIL)
		) {
			emptyFields += `${DYNAMIC_FIELDS.PRODUCT_THUMBNAIL}\n`;
		}

		if (
			body?.includes(DYNAMIC_FIELDS.REWARD_DESCRIPTION) &&
			checkEmptyRewardValue(appliedReward, DYNAMIC_FIELDS.REWARD_DESCRIPTION)
		) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_DESCRIPTION}\n`;
		}
	} else if (_.isEmpty(appliedReward) || _.isUndefined(appliedReward)) {
		if (body?.includes(DYNAMIC_FIELDS.REWARD_TYPE)) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_TYPE}\n`;
		}

		if (body?.includes(DYNAMIC_FIELDS.REWARD_NAME)) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_NAME}\n`;
		}

		if (body?.includes(DYNAMIC_FIELDS.REWARD_AMOUNT)) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_AMOUNT}\n`;
		}

		if (body?.includes(DYNAMIC_FIELDS.REWARD_CODE)) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_CODE}\n`;
		}

		if (body?.includes(DYNAMIC_FIELDS.ESTIMATED_CASH_VALUE)) {
			emptyFields += `${DYNAMIC_FIELDS.ESTIMATED_CASH_VALUE}\n`;
		}

		if (body?.includes(DYNAMIC_FIELDS.ACTUAL_CASH_VALUE)) {
			emptyFields += `${DYNAMIC_FIELDS.ACTUAL_CASH_VALUE}\n`;
		}

		if (body?.includes(DYNAMIC_FIELDS.REWARD_PRODUCT_LINK)) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_PRODUCT_LINK}\n`;
		}

		if (body?.includes(DYNAMIC_FIELDS.PRODUCT_THUMBNAIL)) {
			emptyFields += `${DYNAMIC_FIELDS.PRODUCT_THUMBNAIL}\n`;
		}

		if (body?.includes(DYNAMIC_FIELDS.REWARD_DESCRIPTION)) {
			emptyFields += `${DYNAMIC_FIELDS.REWARD_DESCRIPTION}\n`;
		}
	}

	return emptyFields;
};

const checkEmptyCreatorValue = (creators: any, field?: string) => {
	return Object.values(creators).some((creator: any) => {
		switch (field) {
			case DYNAMIC_FIELDS.FIRST_NAME:
				return (
					_.isNull(creator?.firstName) ||
					_.isUndefined(creator?.firstName) ||
					creator?.firstName === ''
				);

			case DYNAMIC_FIELDS.LAST_NAME:
				return (
					_.isNull(creator?.lastName) ||
					_.isUndefined(creator?.lastName) ||
					creator?.lastName === ''
				);

			case DYNAMIC_FIELDS.INSTAGRAM_USERNAME:
				return (
					_.isNull(creator?.instagramUsername) ||
					_.isUndefined(creator?.instagramUsername) ||
					creator?.instagramUsername === ''
				);

			case DYNAMIC_FIELDS.YOUTUBE_USERNAME:
				return (
					_.isNull(creator?.youtubeUsername) ||
					_.isUndefined(creator?.youtubeUsername) ||
					creator?.youtubeUsername === ''
				);

			case DYNAMIC_FIELDS.TIKTOK_USERNAME:
				return (
					_.isNull(creator?.tiktokUsername) ||
					_.isUndefined(creator?.tiktokUsername) ||
					creator?.tiktokUsername === ''
				);

			case DYNAMIC_FIELDS.TWITTER_USERNAME:
				return (
					_.isNull(creator?.twitterUsername) ||
					_.isUndefined(creator?.twitterUsername) ||
					creator?.twitterUsername === ''
				);

			default:
				return null;
		}
	});
};

export { validateAllDynamicFields, checkEmptyCreatorValue };
