import { ItemCamPaign } from '@models/campaign';
import EmailBuilder from '@pages/Client/Messages/EmailBuilder';
import { StyledActivationEmailModal } from '@styled/Campaign/CampaignModalStyled';

type PropsActivationEmailModal = {
	campaign: ItemCamPaign;
	[other: string]: any;
};
const ActivationEmailModal = (props: PropsActivationEmailModal) => {
	const { campaign, ...other } = props;

	return (
		<StyledActivationEmailModal centered closable={false} {...other}>
			<EmailBuilder campaign={campaign} isPopup isActivationEmail isInvitationFromCreator />
		</StyledActivationEmailModal>
	);
};

export default ActivationEmailModal;
