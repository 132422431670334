import { LocationStateDto } from '@models/messages/summary';
import { Action, handleActions } from 'redux-actions';

import { STATUSCODE } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { GroupNotifyDto, NotificationStoreDto } from '@models/notification/stores';
import {
	checkLimitNotificationTypes,
	getAllNotificationTypes,
	getNotifyAccountTypes,
	getTaskNotificationTypes,
	markTaskNotificationAsReadTypes,
	toggleTaskNotificationStatusTypes,
	updateNotificationInfoTypes,
	updateStatusNotificationTypes,
} from './notification.types';
import { groupNotificationsByTime } from '@utils/funcHelper';

const initialState: NotificationStoreDto = {
	hasNotification: false,
	hasTaskNotification: false,
	loadingNotify: false,
	allMessage: [],
	loadingAccountNotify: false,
	accountNotification: [],
	limitNotificationMsg: '',
	updateNotificationStatus: NONE,
	fetchingTaskNotification: false,
	toggleTaskNotificationStatus: NONE,
	markTaskNotificationAsReadStatus: NONE,
};

// get notify
const getAllNotificationRequest = (state: NotificationStoreDto) => ({
	...state,
	loadingNotify: true,
});

const getAllNotificationSucceeded = (state: NotificationStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: { hasTaskNotification = false, notifications = [] } = {},
	} = payload.data;
	// TODO, will be update once we have offical response data format from BE
	const allMessage: GroupNotifyDto[] = [];
	notifications?.forEach((item: GroupNotifyDto) => {
		if (item?.data?.length > 0) {
			allMessage.push(item);
		}
	});
	const hasNotification = allMessage?.length > 0;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			allMessage,
			hasNotification,
			hasTaskNotification,
			loadingNotify: false,
		};
	}

	return {
		...state,
		hasNotification,
		hasTaskNotification,
		loadingNotify: false,
		allMessage: [],
		error: status,
	};
};

const getAllNotificationFailed = (state: NotificationStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		loadingNotify: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}
	return result;
};

// get notify account
const getNotificationAccountRequest = (state: NotificationStoreDto) => ({
	...state,
	loadingAccountNotify: true,
	accountNotification: [],
});

const getNotificationAccountSucceeded = (state: NotificationStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: accountNotification = [],
	} = payload.data;
	// TODO, will be update once we have offical response data format from BE

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			accountNotification,
			loadingAccountNotify: false,
		};
	}

	return {
		...state,
		loadingAccountNotify: false,
		accountNotification: [],
		error: status,
	};
};

const getNotificationAccountFailed = (state: NotificationStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		loadingAccountNotify: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}
	return result;
};

// reset notify account
const resetNotificationAccount = (state: NotificationStoreDto) => {
	return {
		...state,
		accountNotification: [],
	};
};

const updateStatusNotificationRequest = (state: NotificationStoreDto) => ({
	...state,
	updateNotificationStatus: IN_PROGRESS,
});

const updateStatusNotificationSucceeded = (
	state: NotificationStoreDto,
	{ payload }: Action<any>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;
	const { id, isAll } = payload;
	let { allMessage } = state;

	if (id) {
		allMessage?.forEach((item) => {
			if (item?.data?.some((notify) => notify?.id === id)) {
				item.data = item?.data?.filter((notify) => notify?.id !== id);
			}
		});
	}
	if (isAll === true) {
		allMessage = [];
	}

	allMessage = allMessage?.filter((item) => item?.data?.length > 0);
	const hasNotification = allMessage?.length > 0;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			allMessage,
			hasNotification,
			updateNotificationStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		updateNotificationStatus: FAILED,
		allMessage: [],
		hasNotification: false,
		error: status,
	};
};

const updateStatusNotificationFailed = (state: NotificationStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		updateNotificationStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}
	return result;
};

const updateStatusNotificationEnd = (state: NotificationStoreDto) => ({
	...state,
	updateNotificationStatus: NONE,
});

const updateNotificationInfo = (state: LocationStateDto, { payload }: any) => {
	const { notificationType, arrayId } = payload;

	return {
		...state,
		notificationType,
		arrayId,
	};
};

const checkLimitNotificationRequest = (state: NotificationStoreDto) => ({
	...state,
	checkLimitNotificationStatus: IN_PROGRESS,
});

const checkLimitNotificationSucceeded = (state: NotificationStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: limitNotificationMsg = '',
	} = payload.data;
	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			limitNotificationMsg,
			checkLimitNotificationStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		checkLimitNotificationStatus: FAILED,
		limitNotificationMsg: '',
		error: status,
	};
};

const checkLimitNotificationFailed = (state: NotificationStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		checkLimitNotificationStatus: FAILED,
		error: DEFAULT_ERROR,
	};
	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}
	return result;
};

const checkLimitNotificationEnd = (state: NotificationStoreDto) => ({
	...state,
	limitNotificationMsg: '',
	checkLimitNotificationStatus: NONE,
});

const getTaskNotificationRequest = (state: NotificationStoreDto) => ({
	...state,
	fetchingTaskNotification: true,
});

const getTaskNotificationSucceeded = (state: NotificationStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: taskNotificationData = {},
	} = payload.data;
	const { isShowAll } = payload;
	const notifications = taskNotificationData.notifications || [];
	const groupedNotifications = groupNotificationsByTime(notifications);

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingTaskNotification: false,
			taskNotificationData: {
				notifications: { ...groupedNotifications },
				isShowAll,
				currentPageNotifications: notifications,
			},
		};
	}

	return {
		...state,
		fetchingTaskNotification: false,
		error: status,
	};
};

const getTaskNotificationFailed = (
	state: NotificationStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			fetchingTaskNotification: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingTaskNotification: false,
	};
};

const getTaskNotificationEnd = (state: NotificationStoreDto) => ({
	...state,
	fetchingTaskNotification: false,
});

const toggleTaskNotificationStatusRequest = (state: NotificationStoreDto) => ({
	...state,
	toggleTaskNotificationStatus: IN_PROGRESS,
});

const toggleTaskNotificationStatusSucceeded = (
	state: NotificationStoreDto,
	{ payload }: Action<any>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			toggleTaskNotificationStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		toggleTaskNotificationStatus: FAILED,
		error: status,
	};
};

const toggleTaskNotificationStatusFailed = (
	state: NotificationStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			toggleTaskNotificationStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		toggleTaskNotificationStatus: FAILED,
	};
};

const toggleTaskNotificationStatusEnd = (state: NotificationStoreDto) => ({
	...state,
	toggleTaskNotificationStatus: NONE,
});

const markTaskNotificationAsReadRequest = (state: NotificationStoreDto) => ({
	...state,
	markTaskNotificationAsReadStatus: IN_PROGRESS,
});

const markTaskNotificationAsReadSucceeded = (
	state: NotificationStoreDto,
	{ payload }: Action<any>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			markTaskNotificationAsReadStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		markTaskNotificationAsReadStatus: FAILED,
		error: status,
	};
};

const markTaskNotificationAsReadFailed = (
	state: NotificationStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			markTaskNotificationAsReadStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		markTaskNotificationAsReadStatus: FAILED,
	};
};

const markTaskNotificationAsReadEnd = (state: NotificationStoreDto) => ({
	...state,
	markTaskNotificationAsReadStatus: NONE,
});

const changeFieldsStoreNotifyData = (state: NotificationStoreDto, { payload }: Action<any>) => ({
	...state,
	...payload,
});

const notificationReducers = handleActions<any>(
	{
		[getAllNotificationTypes.GET_NOTIFICATION_REQUEST]: getAllNotificationRequest,
		[getAllNotificationTypes.GET_NOTIFICATION_SUCCEEDED]: getAllNotificationSucceeded,
		[getAllNotificationTypes.GET_NOTIFICATION_FAILED]: getAllNotificationFailed,

		[getNotifyAccountTypes.GET_NOTIFICATION_ACCOUNT_REQUEST]: getNotificationAccountRequest,
		[getNotifyAccountTypes.GET_NOTIFICATION_ACCOUNT_SUCCEEDED]: getNotificationAccountSucceeded,
		[getNotifyAccountTypes.GET_NOTIFICATION_ACCOUNT_FAILED]: getNotificationAccountFailed,

		[getNotifyAccountTypes.RESET_NOTIFY_ACCOUNT]: resetNotificationAccount,

		[updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_REQUEST]:
			updateStatusNotificationRequest,
		[updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_SUCCEEDED]:
			updateStatusNotificationSucceeded,
		[updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_FAILED]:
			updateStatusNotificationFailed,
		[updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_END]: updateStatusNotificationEnd,

		[updateNotificationInfoTypes.UPDATE_NOTIFICATION_INFO]: updateNotificationInfo,
		[updateNotificationInfoTypes.CHANGE_FIELDS_STORE_NOTIFY]: changeFieldsStoreNotifyData,

		[checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_REQUEST]:
			checkLimitNotificationRequest,
		[checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_SUCCEEDED]:
			checkLimitNotificationSucceeded,
		[checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_FAILED]: checkLimitNotificationFailed,
		[checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_END]: checkLimitNotificationEnd,

		[getTaskNotificationTypes.GET_TASK_NOTIFICATION_REQUEST]: getTaskNotificationRequest,
		[getTaskNotificationTypes.GET_TASK_NOTIFICATION_SUCCEEDED]: getTaskNotificationSucceeded,
		[getTaskNotificationTypes.GET_TASK_NOTIFICATION_FAILED]: getTaskNotificationFailed,
		[getTaskNotificationTypes.GET_TASK_NOTIFICATION_END]: getTaskNotificationEnd,

		[toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_REQUEST]:
			toggleTaskNotificationStatusRequest,
		[toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_SUCCEEDED]:
			toggleTaskNotificationStatusSucceeded,
		[toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_FAILED]:
			toggleTaskNotificationStatusFailed,
		[toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_END]:
			toggleTaskNotificationStatusEnd,

		[markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_REQUEST]:
			markTaskNotificationAsReadRequest,
		[markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_SUCCEEDED]:
			markTaskNotificationAsReadSucceeded,
		[markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_FAILED]:
			markTaskNotificationAsReadFailed,
		[markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_END]:
			markTaskNotificationAsReadEnd,
	},
	initialState,
);
export default notificationReducers;
