import { TASK_PARAMS_DEFAULT } from '@constants/taskManager';
import {
	TaskDataDto,
	TaskItemDto,
	TaskListRequestPayloadDto,
	UpdateTaskPayloadDto,
} from '@models/taskmanager';
import React from 'react';

export type TaskManagerContextType = {
	taskManager: TaskDataDto;
	setTaskManager: (params: TaskDataDto) => void;
	requestPayload?: TaskListRequestPayloadDto;
	setRequestPayload: (params: TaskListRequestPayloadDto) => void;
	selectedRowKeys: React.Key[];
	setSelectedRowKeys: (keys: React.Key[]) => void;
	selectedTask: TaskItemDto | null;
	setSelectedTask: (task: TaskItemDto) => void;
	modalType: string;
	setModalType: (type: string) => void;
	assignedAccountOptions: any[];
	onDeleteTask: () => void;
	markTaskAsDone: () => void;
	handleUpdateTask: (payload: { id: number; params: UpdateTaskPayloadDto }) => void;
};

// Default context values
const values: TaskManagerContextType = {
	taskManager: {
		page: 0,
	},
	setTaskManager: () => {},
	requestPayload: TASK_PARAMS_DEFAULT,
	setRequestPayload: () => {},
	selectedRowKeys: [],
	setSelectedRowKeys: () => {},
	selectedTask: null,
	setSelectedTask: () => {},
	modalType: '',
	setModalType: () => {},
	assignedAccountOptions: [],
	onDeleteTask: () => {},
	markTaskAsDone: () => {},
	handleUpdateTask: () => {},
};

const TaskManagerContext = React.createContext(values);
export default TaskManagerContext;
