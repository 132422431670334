import * as React from 'react';
import styled from 'styled-components';

// Hooks
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { getRewards } from '@stores/rewards/rewards.actions';

// Constant
import { ACTIVE, DISCOUNT, DRAFT, GIFTCARD, PRODUCT } from '@constants/reward';

// Components
import ActionBar from '@components/Rewards/Inventory/ActionBar';
import Discount from '@components/Rewards/Inventory/Discount';
import FreeProducts from '@components/Rewards/Inventory/FreeProducts';
import GiftCards from '@components/Rewards/Inventory/GiftCards';
// refs
import { RefRewardActionEdit } from '@models/rewards/ref';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';

const StyledHeader = styled.div`
	width: 100%;
	padding: 0 16.5rem 0 0;
`;

const Inventory = () => {
	const dispatch = useDispatch();
	const refAction = React.useRef<RefRewardActionEdit>(null);
	const {
		rewardsList: {
			activeDiscountList,
			draftDiscountList,
			archivedDiscountList,
			activeGiftcardList,
			draftGiftcardList,
			archivedGiftcardList,
			activeProductList,
			draftProductList,
			archivedProductList,
		},
	} = useSelector((state: any) => state.rewards);

	useEffect(() => {
		fetchingRewardsList(ACTIVE, DISCOUNT);
		fetchingRewardsList(ACTIVE, GIFTCARD);
		fetchingRewardsList(ACTIVE, PRODUCT);
	}, []);

	const handleFetchDraftList = () => {
		fetchingRewardsList(DRAFT, DISCOUNT, true);
		fetchingRewardsList(DRAFT, GIFTCARD, true);
		fetchingRewardsList(DRAFT, PRODUCT, true);
	};

	const handleFetchActiveList = () => {
		fetchingRewardsList(ACTIVE, DISCOUNT, true);
		fetchingRewardsList(ACTIVE, GIFTCARD, true);
		fetchingRewardsList(ACTIVE, PRODUCT, true);
	};

	const handleChangeTab = (key: string, type: string) => {
		fetchingRewardsList(key, type);
	};

	const fetchingRewardsListOnScroll = (page: number, status: string, type: string) => {
		const params = {
			page,
			maxRecords: 8,
			status,
			type,
		};
		getRewardsList(params, true);
	};

	const fetchingRewardsList = (status: string, type: string, isResetList?: boolean) => {
		const params = {
			page: 1,
			maxRecords: 8,
			status,
			type,
			isResetList: isResetList || false,
		};
		getRewardsList(params, false);
	};

	const getRewardsList = (params: any, isSetOnScroll: boolean) => {
		const requestPayload = {
			params,
			isSetOnScroll,
		};
		dispatch(getRewards(requestPayload));
	};

	const handleEditReward = (rowSelect?: any) => {
		if (refAction?.current && refAction?.current?.handleEdit) {
			return refAction?.current?.handleEdit(rowSelect);
		}
		return null;
	};

	return (
		<div>
			<StyledHeader>
				<ActionBar
					handleFetchDraftList={handleFetchDraftList}
					handleFetchActiveList={handleFetchActiveList}
					ref={refAction}
				/>
			</StyledHeader>
			<StyledWrapperContent
				maxHeight="calc(100vh - 130px)"
				className="custom_scroll_bar"
				overflow="auto"
				width="100%">
				<Discount
					handleScroll={fetchingRewardsListOnScroll}
					handleChangeTab={handleChangeTab}
					data={{ activeDiscountList, draftDiscountList, archivedDiscountList }}
					handleEdit={handleEditReward}
				/>
				<GiftCards
					handleScroll={fetchingRewardsListOnScroll}
					handleChangeTab={handleChangeTab}
					data={{ activeGiftcardList, draftGiftcardList, archivedGiftcardList }}
					handleEdit={handleEditReward}
				/>
				<FreeProducts
					handleScroll={fetchingRewardsListOnScroll}
					handleChangeTab={handleChangeTab}
					data={{ activeProductList, draftProductList, archivedProductList }}
					handleEdit={handleEditReward}
				/>
			</StyledWrapperContent>
		</div>
	);
};

export default React.memo(Inventory);
