import * as React    from 'react';
import { Spin } from 'antd';
import styled   from 'styled-components';

const StyledLoading = styled( Spin )`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Loading = () => {
    return <StyledLoading size="large"/>;
};

export default Loading;
