const widgetManagerTypes = {
	FETCH_LIST_WIDGET_REQUEST: 'FETCH_LIST_WIDGET_REQUEST',
	FETCH_LIST_WIDGET_SUCCEEDED: 'FETCH_LIST_WIDGET_SUCCEEDED',
	FETCH_LIST_WIDGET_FAILED: 'FETCH_LIST_WIDGET_FAILED',
};

const createDraftWidgetBuilderTypes = {
	CREATE_DRAFT_WIDGET_BUILDER_REQUEST: 'CREATE_DRAFT_WIDGET_BUILDER_REQUEST',
	CREATE_DRAFT_WIDGET_BUILDER_SUCCEEDED: 'CREATE_DRAFT_WIDGET_BUILDER_SUCCEEDED',
	CREATE_DRAFT_WIDGET_BUILDER_FAILED: 'CREATE_DRAFT_WIDGET_BUILDER_FAILED',
	CREATE_DRAFT_WIDGET_BUILDER_END: 'CREATE_DRAFT_WIDGET_BUILDER_END',
};

const saveDraftWidgetBuilderTypes = {
	SAVE_DRAFT_WIDGET_BUILDER_REQUEST: 'SAVE_DRAFT_WIDGET_BUILDER_REQUEST',
	SAVE_DRAFT_WIDGET_BUILDER_SUCCEEDED: 'SAVE_DRAFT_WIDGET_BUILDER_SUCCEEDED',
	SAVE_DRAFT_WIDGET_BUILDER_FAILED: 'SAVE_DRAFT_WIDGET_BUILDER_FAILED',
	SAVE_DRAFT_WIDGET_BUILDER_END: 'SAVE_DRAFT_WIDGET_BUILDER_END',
};

const resumeLiveDraftTypes = {
	RESUME_LIVE_DRAFT_REQUEST: 'RESUME_LIVE_DRAFT_REQUEST',
	RESUME_LIVE_DRAFT_SUCCEEDED: 'RESUME_LIVE_DRAFT_SUCCEEDED',
	RESUME_LIVE_DRAFT_FAILED: 'RESUME_LIVE_DRAFT_FAILED',
	RESUME_LIVE_DRAFT_END: 'RESUME_LIVE_DRAFT_END',
};

const cloneLiveWidgetTypes = {
	CLONE_LIVE_WIDGET_REQUEST: 'CLONE_LIVE_WIDGET_REQUEST',
	CLONE_LIVE_WIDGET_SUCCEEDED: 'CLONE_LIVE_WIDGET_SUCCEEDED',
	CLONE_LIVE_WIDGET_FAILED: 'CLONE_LIVE_WIDGET_FAILED',
	CLONE_LIVE_WIDGET_END: 'CLONE_LIVE_WIDGET_END',
};

const uploadFontBuilderTypes = {
	UPLOAD_FONT_BUILDER_REQUEST: 'UPLOAD_FONT_BUILDER_REQUEST',
	UPLOAD_FONT_BUILDER_SUCCEEDED: 'UPLOAD_FONT_BUILDER_SUCCEEDED',
	UPLOAD_FONT_BUILDER_FAILED: 'UPLOAD_FONT_BUILDER_FAILED',
	UPLOAD_FONT_BUILDER_END: 'UPLOAD_FONT_BUILDER_END',
};

const fetchDetailBuilderTypes = {
	FETCH_DETAIL_BUILDER_REQUEST: 'FETCH_DETAIL_BUILDER_REQUEST',
	FETCH_DETAIL_BUILDER_SUCCEEDED: 'FETCH_DETAIL_BUILDER_SUCCEEDED',
	FETCH_DETAIL_BUILDER_FAILED: 'FETCH_DETAIL_BUILDER_FAILED',
	FETCH_DETAIL_BUILDER_END: 'FETCH_DETAIL_BUILDER_END',
};

const fetchDetailLiveWidgetTypes = {
	FETCH_DETAIL_LIVE_WIDGET_REQUEST: 'FETCH_DETAIL_LIVE_WIDGET_REQUEST',
	FETCH_DETAIL_LIVE_WIDGET_SUCCEEDED: 'FETCH_DETAIL_LIVE_WIDGET_SUCCEEDED',
	FETCH_DETAIL_LIVE_WIDGET_FAILED: 'FETCH_DETAIL_LIVE_WIDGET_FAILED',
};

const fetchDetailWidgetDefaultTypes = {
	FETCH_DETAIL_WIDGET_DEFAULT_REQUEST: 'FETCH_DETAIL_WIDGET_DEFAULT_REQUEST',
	FETCH_DETAIL_WIDGET_DEFAULT_SUCCEEDED: 'FETCH_DETAIL_WIDGET_DEFAULT_SUCCEEDED',
	FETCH_DETAIL_WIDGET_DEFAULT_FAILED: 'FETCH_DETAIL_WIDGET_DEFAULT_FAILED',
};

const uploadFileWidgetTypes = {
	UPLOAD_FILE_WIDGET_REQUEST: 'UPLOAD_FILE_WIDGET_REQUEST',
	UPLOAD_FILE_WIDGET_SUCCEEDED: 'UPLOAD_FILE_WIDGET_SUCCEEDED',
	UPLOAD_FILE_WIDGET_FAILED: 'UPLOAD_FILE_WIDGET_FAILED',
	UPLOAD_FILE_WIDGET_END: 'UPLOAD_FILE_WIDGET_END',
};

const removeWidgetBuilderTypes = {
	REMOVE_WIDGET_BUILDER_REQUEST: 'REMOVE_WIDGET_BUILDER_REQUEST',
	REMOVE_WIDGET_BUILDER_SUCCEEDED: 'REMOVE_WIDGET_BUILDER_SUCCEEDED',
	REMOVE_WIDGET_BUILDER_FAILED: 'REMOVE_WIDGET_BUILDER_FAILED',
	REMOVE_WIDGET_BUILDER_END: 'REMOVE_WIDGET_BUILDER_END',
};

const publishWidgetBuilderTypes = {
	PUBLISH_WIDGET_BUILDER_REQUEST: 'PUBLISH_WIDGET_BUILDER_REQUEST',
	PUBLISH_WIDGET_BUILDER_SUCCEEDED: 'PUBLISH_WIDGET_BUILDER_SUCCEEDED',
	PUBLISH_WIDGET_BUILDER_FAILED: 'PUBLISH_WIDGET_BUILDER_FAILED',
	PUBLISH_WIDGET_BUILDER_END: 'PUBLISH_WIDGET_BUILDER_END',
};

const updateStatusRecentlyUpdatedTypes = {
	UPDATE_STATUS_RECENTLY_UPDATED_REQUEST: 'UPDATE_STATUS_RECENTLY_UPDATED_REQUEST',
	UPDATE_STATUS_RECENTLY_UPDATED_SUCCEEDED: 'UPDATE_STATUS_RECENTLY_UPDATED_SUCCEEDED',
	UPDATE_STATUS_RECENTLY_UPDATED_FAILED: 'UPDATE_STATUS_RECENTLY_UPDATED_FAILED',
	UPDATE_STATUS_RECENTLY_UPDATED_END: 'UPDATE_STATUS_RECENTLY_UPDATED_END',
};

const updateStoreWidgetTypes = {
	UPDATE_STORE_WIDGET: 'UPDATE_STORE_WIDGET',
};

const fetchCustomFieldWidgetTypes = {
	FETCH_CUSTOM_FIELD_WIDGET_REQUEST: 'FETCH_CUSTOM_FIELD_WIDGET_REQUEST',
	FETCH_CUSTOM_FIELD_WIDGET_SUCCEEDED: 'FETCH_CUSTOM_FIELD_WIDGET_SUCCEEDED',
	FETCH_CUSTOM_FIELD_WIDGET_FAILED: 'FETCH_CUSTOM_FIELD_WIDGET_FAILED',
};

export {
	widgetManagerTypes,
	createDraftWidgetBuilderTypes,
	saveDraftWidgetBuilderTypes,
	fetchDetailBuilderTypes,
	uploadFileWidgetTypes,
	removeWidgetBuilderTypes,
	publishWidgetBuilderTypes,
	fetchDetailLiveWidgetTypes,
	updateStatusRecentlyUpdatedTypes,
	updateStoreWidgetTypes,
	fetchCustomFieldWidgetTypes,
	fetchDetailWidgetDefaultTypes,
	uploadFontBuilderTypes,
	resumeLiveDraftTypes,
	cloneLiveWidgetTypes,
};
