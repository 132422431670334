import { DEFAULT_TASK_NOTIFICATION_PAYLOAD, NOTIFICATION_TAB } from '@constants/notification';
import { TaskNotificationPayloadDto } from '@models/notification/summary';
import React from 'react';

export type NotificationContextType = {
	visible: boolean;
	activeTab: string;
	requestPayload?: TaskNotificationPayloadDto;
	showHeaderNotification?: boolean;
	setRequestPayload: (payload: TaskNotificationPayloadDto) => void;
};

// Default context values
const values: NotificationContextType = {
	visible: false,
	activeTab: NOTIFICATION_TAB.MENTIONS,
	showHeaderNotification: false,
	setRequestPayload: () => {},
};

const NotificationContext = React.createContext(values);
export default NotificationContext;
