import THEME from '@constants/themes/themes';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import {
	StyledDetailWidget,
	StyledWrapperDetailWidget,
} from '@styled/Content/WidgetManager/DetailWidgetStyled';
import { getUrlS3 } from '@utils/funcHelper';
import { useEffect, useMemo, useRef, useState } from 'react';
import ConfirmationScreen from '../ConfirmationScreen';
import BodyWidgetDefault from './BodyWidgetDefault';
import FooterWidgetDefault from './FooterWidgetDefault';
import HeaderWidgetDefault from './HeaderWidgetDefault';
import { fileToBase64 } from '@helpers/base64Helper';
import {
	FONT_FAMILY_FORMAT,
	FONT_FAMILY_WIDGET_NAME,
	FONT_STYLE,
	FONT_WEIGHT,
	ID_FONT_FAMILY_WIDGET,
	OTHER_FONT_FORMAT,
} from '@constants/content/widgetManager';
import { isNaN } from 'lodash';

type PropDetailWidgetDto = {
	isMobile: boolean;
	detailWidget: WidgetBuilderDetailDto;
	currentStep?: number;
	enableTerms?: boolean;
};

type SrcFontsTypes = {
	src: string;
	format: string;
};

type FormatMultiFonts = {
	fontWeight: string | number;
	fontStyle: string;
	fileFormat: SrcFontsTypes[];
};

const DetailWidget = (props: PropDetailWidgetDto) => {
	const { isMobile = false, detailWidget, currentStep = 0, enableTerms = false } = props;
	const { metadata } = detailWidget;
	const {
		bgUrl,
		bgColor,
		formBackground = THEME.colors.white,
		fileFontUploads,
		fileFontUrls,
		enableCustomFontFamily,
	} = metadata?.colors;
	const { srcLogo = '', sizeLogo, ...otherHeaderWidget } = metadata?.header;

	const refWrapper = useRef<any>({});
	const [isMoreField, setIsMoreField] = useState<boolean>(false);

	const bgFull = useMemo(() => {
		return getUrlS3(bgUrl);
	}, [bgUrl]);

	useEffect(() => {
		if (refWrapper?.current && bgFull) {
			const contentImage: HTMLImageElement = new window.Image();
			contentImage.src = bgFull;
			contentImage.onload = () => {
				const width: number = contentImage.width;
				const height: number = contentImage.height;
				const { clientHeight, clientWidth } = refWrapper?.current || {};

				if (!refWrapper?.current?.style && refWrapper.current) {
					refWrapper.current.style = {};
				}

				if (height && width && clientHeight && clientWidth) {
					if (width / height > clientWidth / clientHeight) {
						refWrapper.current.style.backgroundSize = 'auto 100%';
					} else {
						refWrapper.current.style.backgroundSize = '100%';
					}
				}
			};
		}
	}, [refWrapper?.current, bgFull, detailWidget]);

	const handleAddStyledFont = async (files: any[]) => {
		const arrFileFormat = await Promise.all(
			files.map(async (file) => {
				const nameFile = typeof file === 'string' ? file : file.name;
				const nameFileLowerCase = nameFile?.toLowerCase() || '';
				const result: any = typeof file === 'string' ? file : await fileToBase64(file);

				const isInvalidFormat =
					nameFileLowerCase.includes(OTHER_FONT_FORMAT.type) &&
					!nameFileLowerCase.includes(`${OTHER_FONT_FORMAT.type}?#`);
				const format =
					FONT_FAMILY_FORMAT.find((item) => nameFileLowerCase.includes(item.type))
						?.format || '';
				let fontWeight: string | number = FONT_WEIGHT.NORMAL;
				let fontStyle: string = FONT_STYLE.NORMAL;

				const numSlice = nameFileLowerCase.slice(
					nameFileLowerCase.lastIndexOf('-') + 1,
					nameFileLowerCase.lastIndexOf('.'),
				);
				const numFont = +numSlice;

				if (numSlice && !isNaN(+numSlice) && numFont >= 100 && numFont <= 900) {
					fontWeight = numFont;
				} else if (nameFileLowerCase.includes(FONT_WEIGHT.BOLD)) {
					fontWeight = FONT_WEIGHT.BOLD;
				}

				if (nameFileLowerCase.includes(FONT_STYLE.ITALIC)) {
					fontStyle = FONT_STYLE.ITALIC;
				}
				if (nameFileLowerCase.includes(FONT_STYLE.OBLIQUE)) {
					fontStyle = FONT_STYLE.OBLIQUE;
				}
				return {
					src: result,
					format: isInvalidFormat ? '' : format,
					fontWeight,
					fontStyle,
				};
			}),
		);

		if (arrFileFormat) {
			const invalidFormats = arrFileFormat.filter((f) => !f.format);
			const fileWithFormats = arrFileFormat.filter((f) => f.format);
			const arrFormatsMulti: FormatMultiFonts[] = [];
			fileWithFormats.forEach((f) => {
				const idx = arrFormatsMulti.findIndex(
					(fo) => fo.fontWeight === f.fontWeight && fo.fontStyle === f.fontStyle,
				);
				if (idx !== -1) {
					arrFormatsMulti[idx].fileFormat.push({ format: f.format, src: f.src });
				} else {
					arrFormatsMulti.push({
						fontWeight: f.fontWeight,
						fontStyle: f.fontStyle,
						fileFormat: [{ src: f.src, format: f.format }],
					});
				}
			});
			const newStyle = document.createElement('style');
			newStyle.id = ID_FONT_FAMILY_WIDGET;

			arrFormatsMulti.forEach((item) => {
				let strVal = '';

				item.fileFormat.forEach((sr, idx) => {
					strVal += `url(${sr.src}) format('${sr.format}')${
						idx === item.fileFormat.length - 1 ? ';' : ','
					}`;
				});
				newStyle.appendChild(
					document.createTextNode(
						'@font-face {font-family: ' +
							FONT_FAMILY_WIDGET_NAME +
							';font-style: ' +
							item.fontStyle +
							';font-weight: ' +
							item.fontWeight +
							';src:' +
							strVal +
							'}',
					),
				);
			});
			document.head.appendChild(newStyle);

			const elm = document.getElementById('widget_detail_thumbnail');
			if (elm) {
				elm.style.fontFamily = FONT_FAMILY_WIDGET_NAME;
			}
		}
	};

	const handleAddFont = async () => {
		const elmCss = document.getElementById(ID_FONT_FAMILY_WIDGET);
		const head = document.getElementsByTagName('head')[0];

		if (elmCss) {
			head.removeChild(elmCss);
		}
		let hasFile = false;
		if (enableCustomFontFamily) {
			if (fileFontUploads && fileFontUploads.length) {
				handleAddStyledFont(fileFontUploads);
				hasFile = true;
			}
			if (fileFontUrls && fileFontUrls.length) {
				handleAddStyledFont(fileFontUrls.map((file) => getUrlS3(file)));
				hasFile = true;
			}
		}

		if (!hasFile || !enableCustomFontFamily) {
			const elm = document.getElementById('widget_detail_thumbnail');
			if (elm) {
				elm.style.fontFamily = 'inherit';
			}
		}
	};

	useEffect(() => {
		handleAddFont();
	}, [fileFontUploads, fileFontUrls, enableCustomFontFamily]);

	return (
		<StyledWrapperDetailWidget
			id="widget_detail_thumbnail"
			ref={refWrapper}
			bgUrl={bgFull}
			bgColor={bgColor}>
			<StyledDetailWidget
				bgColor={formBackground}
				isMobile={isMobile}
				className={currentStep === 4 ? 'confirmation_screen' : ''}>
				{currentStep < 4 ? (
					<>
						<HeaderWidgetDefault
							isMobile={isMobile}
							sizeLogo={sizeLogo}
							srcLogo={getUrlS3(srcLogo)}
						/>
						<BodyWidgetDefault
							isMoreField={isMoreField}
							setIsMoreField={setIsMoreField}
							isMobile={isMobile}
							detailWidget={detailWidget}
							enableTerms={enableTerms}
						/>
						<FooterWidgetDefault detailWidget={detailWidget} isMobile={isMobile} />
					</>
				) : (
					<ConfirmationScreen detailWidget={detailWidget} isMobile={isMobile} />
				)}
			</StyledDetailWidget>
		</StyledWrapperDetailWidget>
	);
};

export default DetailWidget;
