import { FolderItemDto, WidgetLinkDto } from '@models/common/summary';
import { ClientSettingsType } from '@stores/sidebar/sidebar.types';
import { UserType } from '@stores/user/user.types';
import _isEmpty from 'lodash.isempty';
import { getObject, setObject } from './storageHelpers';
import { getTokenCookie, setTokenCookie } from './cookies';

export const getUserToken = () => {
	const result = getTokenCookie();

	return result;
};

export const setUser = (user: UserType) => {
	if (!_isEmpty(user)) {
		setObject('user', user);
		if (user?.token) {
			setTokenCookie(user.token);
		}
	}
};

export const getUser = () => {
	return getObject('user');
};

export const getPendoInitData = () => {
	return getObject('pendoInitData');
};

export const setPendoInitData = (pendoInitData: any) => {
	if (!_isEmpty(pendoInitData)) {
		setObject('pendoInitData', pendoInitData);
	}
};

export const setClientSettings = (clientSettings: ClientSettingsType) => {
	if (!_isEmpty(clientSettings)) {
		setObject('clientSettings', clientSettings);
	}
};

export const setFolderSystem = (folders: FolderItemDto[]) => {
	setObject('folderSystem', folders);
};

export const getFolderSystem = () => {
	const result = getObject('folderSystem');
	return Array.isArray(result) ? result : [];
};

export const getClientSettings = () => {
	const result = getObject('clientSettings');
	return result;
};

export const getDefaultWidgetLink = (clientSettings: ClientSettingsType) => {
	return clientSettings.widgetLinks?.find((item: WidgetLinkDto) => item?.isDefault);
};
