import { ImageFallback } from '@assets/images';
import { URL_CONTENT_EXTERNAL_API } from '@constants/APIs';
import { PLACEHOLDER_IMAGE_IN_BASE64 } from '@constants/common';
import { SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import { getRequest } from '@helpers/requestHelpers';
import { ContentSocialDto } from '@models/content/contentLibrary/summary';
import { StyledImage } from '@styled/Content/ContentLibrary/MediaItemStyled';
import { handleImageLoadedError, handleThumbImageLoadedError, isURl } from '@utils/common';
import { useEffect, useRef, useState } from 'react';

type PropTypes = {
	rotate?: number;
	thumbnail: string;
	dynamicThumbnail: string;
	scale?: number;
	social?: ContentSocialDto;
	isVideo?: boolean;
	contentExternalUrl?: string | null;
	callbackLoadError?: (val: string) => void;
	[other: string]: any;
};
const DetectThumbnailContent = (props: PropTypes) => {
	const {
		rotate,
		thumbnail,
		dynamicThumbnail,
		scale,
		social,
		isVideo,
		contentExternalUrl,
		callbackLoadError,
		...other
	} = props;
	const refError = useRef<boolean>(true);
	const refLoadImg = useRef<boolean>(true);
	const [urlContent, setUrlContent] = useState<string>(thumbnail);
	const [rotateChange, setRotateChange] = useState<number | undefined>(rotate);

	useEffect(() => {
		if (!isVideo || !dynamicThumbnail) {
			return;
		}
		const preloadImg = new Image();
		preloadImg.onload = (e: any) => {
			preloadImg.src = '';
			preloadImg.removeAttribute('src');
		};
		preloadImg.src = dynamicThumbnail;
	}, []);

	useEffect(() => {
		if (urlContent !== thumbnail) {
			refError.current = true;
			refLoadImg.current = true;
			setUrlContent(thumbnail);
		}
	}, [thumbnail]);

	useEffect(() => {
		if (rotateChange !== rotate && !refLoadImg.current) {
			setRotateChange(rotate);
		}
	}, [rotate]);

	const handleSaveUrl = (val: string) => {
		if (callbackLoadError && typeof callbackLoadError === 'function') {
			callbackLoadError(val);
		}
		setUrlContent(val);
	};

	const handleFetchUrl = async () => {
		await getRequest(URL_CONTENT_EXTERNAL_API, { url: contentExternalUrl })
			.then((response: any) => {
				const newUrl = response.body.data.result;
				if (response.status === 200 && newUrl) {
					handleSaveUrl(newUrl);
				} else {
					handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
				}
			})
			.catch((err: any) => {
				handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
			});
	};

	const handleError = async (e: any) => {
		if (refError.current) {
			refError.current = false;
			const socialSource = social?.socialSource ? social.socialSource.toLowerCase() : '';
			const isTikTok = SOCIAL_SOURCE.TIKTOK === socialSource;
			const isInstagram = !isTikTok && SOCIAL_SOURCE.INSTAGRAM === socialSource;
			if (social && (isTikTok || isInstagram)) {
				const newUrl = await handleThumbImageLoadedError(
					e,
					socialSource,
					isInstagram ? thumbnail : social.permalink,
				);
				handleSaveUrl(newUrl);
				return;
			}
			if (contentExternalUrl && isURl(contentExternalUrl)) {
				if (isVideo) {
					handleFetchUrl();
					return;
				}
			}

			const newUrl = await handleImageLoadedError(e, thumbnail, contentExternalUrl);

			handleSaveUrl(newUrl);
		} else {
			handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
		}
	};

	return (
		<StyledImage
			preview={false}
			width="100%"
			height="100%"
			rotate={rotateChange}
			loading="lazy"
			src={urlContent}
			scale={1}
			alt="img_theme"
			onLoad={(e: any) => {
				refLoadImg.current = false;
				if (rotate !== rotateChange) {
					setRotateChange(rotate);
				}
			}}
			onError={(e) => handleError(e)}
			onMouseOver={(e: any) => {
				if (!isVideo || !dynamicThumbnail || !refError.current) {
					return;
				}
				e.target.src = dynamicThumbnail;
			}}
			onMouseOut={(e: any) => {
				if (!isVideo || !urlContent || !refError.current) {
					return;
				}
				e.target.src = urlContent;
			}}
			fallback={ImageFallback}
			{...other}
		/>
	);
};

export default DetectThumbnailContent;
