import { IconAltAdd } from '@assets/icons';
import { TAB_WIDGET } from '@constants/content/widgetManager';
import WidgetManagerContext from '@contexts/Content/WidgetManager';
import { StyledEmpty, StyledText, StyledTitle } from '@styled/Content/WidgetManager';
import { Tabs } from 'antd';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InfoDetailWidget from './InfoDetailWidget';
import WidgetItem from './WidgetItem';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import {
	StyledButtonBuild,
	StyledCurrentDesign,
	StyledFooter,
	StyledListWidget,
	StyledTabs,
	StyledWrapper,
} from '@styled/Content/WidgetManager/CurrentDesignStyled';
import { WidgetBuilderDetailRequestDto } from '@models/content/widgetManager';
import Scrollbars from 'react-custom-scrollbars';
import { renderThumbVertical } from '@utils/renderComponent';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import { useSelector } from 'react-redux';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';

const { TabPane } = Tabs;

const CurrentDesign = () => {
	const { t } = useTranslation();
	const { templateDefault }: ContentLicensingStoreDto = useSelector(
		(state: any) => state.contentLicensing,
	);

	const refScrollbars = useRef<any>({});

	const listTabWidget = useMemo(
		() => [
			{ value: TAB_WIDGET.live, title: t('content.widget_manager.live_widget') },
			{ value: TAB_WIDGET.draft, title: t('content.widget_manager.draft_design') },
			{ value: TAB_WIDGET.custom, title: t('content.widget_manager.custom_widget') },
		],
		[],
	);

	const { fetchingWidgetManager, widgetManagers }: WidgetManagerStoreDto = useSelector(
		(state: any) => state.widgetManager,
	);

	const {
		widgetSelect = null,
		setWidgetSelect,
		listWidget,
		filter,
		setFilter,
		handleCreateNewWidgetBuilder,
	} = useContext(WidgetManagerContext);

	const changeTab = (tab: string) => {
		if (tab !== filter?.status) {
			setFilter({ ...filter, status: tab, widgetIds: [], page: 1 });
		}
	};

	const onScrollStop = () => {
		if (refScrollbars.current !== null) {
			const scrollbarTop = refScrollbars.current.getValues().top;
			if (scrollbarTop >= 0.75 && widgetManagers?.length > 0 && !fetchingWidgetManager) {
				setFilter({ ...filter, page: filter.page + 1 });
			}
		}
	};

	const renderTabName = (item: any) => {
		const { title, value } = item;
		return (
			<StyledTitle>
				<StyledText
					fontWeight={`${value === filter?.status ? 'bold' : 'normal'}`}
					fontSize="1rem">
					{title}
				</StyledText>
			</StyledTitle>
		);
	};

	return (
		<StyledCurrentDesign>
			<StyledTabs
				activeKey={filter?.status}
				className="custom-tabs"
				onTabClick={(tab: string) => changeTab(tab)}>
				{listTabWidget.map((item: any) => (
					<TabPane tab={renderTabName(item)} key={item.value} closable={false}>
						{listWidget.length > 0 && filter?.status === item.value ? (
							<StyledListWidget>
								<Scrollbars
									className="widget-list-scrollbar"
									ref={(el) => {
										refScrollbars.current = el;
									}}
									onScrollStop={onScrollStop}
									style={{ height: '100%' }}
									renderThumbVertical={renderThumbVertical}>
									{listWidget?.map((item: WidgetBuilderDetailRequestDto) => (
										<WidgetItem
											onSelectWidget={() => setWidgetSelect(item)}
											isSelect={widgetSelect?.id === item?.id}
											widgetItem={item}
											key={item?.id}
											showMoreAction={
												filter.status !== TAB_WIDGET.custom &&
												widgetSelect?.id === item.id
											}
										/>
									))}
								</Scrollbars>
							</StyledListWidget>
						) : (
							<StyledWrapper>
								<StyledEmpty image={IconEmpty} />
							</StyledWrapper>
						)}
					</TabPane>
				))}
			</StyledTabs>
			<StyledFooter>
				<StyledButtonBuild
					onClick={() =>
						handleCreateNewWidgetBuilder(undefined, templateDefault?.id || undefined)
					}>
					<IconAltAdd />
					{t('content.widget_manager.button.build_widget')}
				</StyledButtonBuild>
			</StyledFooter>
			{widgetSelect && <InfoDetailWidget />}
		</StyledCurrentDesign>
	);
};

export default CurrentDesign;
