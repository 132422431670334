import { KEY_POPUP_TREMENDOUS } from '@constants/settings/integrations';
import { StyledFormItem, StyledTitle } from '@styled/Common/CommonStyled';
import { Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FieldInfoTypes } from '..';
import { MAX_LENGTH_CHARACTER } from '@constants/content/contentLibrary';

type PropTypes = {
	value: string;
	name: string;
	fontSize?: string;
	setFieldValue: () => void;
	height?: string;
	rules?: any[];
	item: FieldInfoTypes;
};
const FieldEdit = (props: PropTypes) => {
	const { value, name, fontSize = '14px', setFieldValue, height, rules, item } = props;
	const [isEdit, setIsEdit] = useState<boolean>(false);

	useEffect(() => {
		if (isEdit) {
			setFieldValue();
		}
	}, [isEdit]);

	if (isEdit) {
		if (item?.arrField) {
			return (
				<div>
					{item.arrField.map((field) => (
						<StyledFormItem key={field.key} rules={rules || []} name={field.key}>
							<Input maxLength={MAX_LENGTH_CHARACTER} />
						</StyledFormItem>
					))}
				</div>
			);
		}
		return (
			<StyledFormItem rules={rules || []} name={name}>
				<Input maxLength={MAX_LENGTH_CHARACTER} />
			</StyledFormItem>
		);
	}
	return (
		<Tooltip title={value}>
			<StyledTitle
				style={{ minHeight: '20px', width: value?.trim() ? 'auto' : '100%' }}
				onDoubleClick={() => setIsEdit(true)}
				className={`overflow_text ${height ? '' : 'unset_height'}`}
				height={height}
				display={'block'}
				fontSize={fontSize}>
				{value}
			</StyledTitle>
		</Tooltip>
	);
};

export default FieldEdit;
