import { KEYBOARD, TIME_OUT } from '@constants/common';
import { StyledSelect } from '@styled/Common/CommonStyled';
import { notFoundContent } from '@utils/renderComponent';
import { Select, SelectProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionTypes } from './SelectSingle';
import { LoadingWrapper } from '..';

type PropTypes = {
	onAddOption: (val: OptionTypes) => void;
	valueIsNumber?: boolean;
	validateAdd?: (val: OptionTypes) => boolean;
	onSelect?: (val: OptionTypes) => void;
	options: OptionTypes[];
	onSearch?: (val: string) => void;
	loading?: boolean;
	setLoading?: (val: boolean) => void;
	onLoadMore?: () => void;
	hasApiSearch?: boolean;
};

const SelectSingleAddNew = (props: PropTypes & SelectProps<any>) => {
	const { t } = useTranslation();
	const {
		open,
		options,
		onAddOption,
		valueIsNumber = false,
		validateAdd,
		onSelect: onSelectProps,
		onSearch: onSearchProps,
		loading,
		value,
		onLoadMore,
		setLoading,
		hasApiSearch,
		...other
	} = props;
	const [visible, setVisible] = useState<boolean>(false);
	const refAdd = useRef<boolean>(false);
	const refSelect = useRef<any>({});
	const refEnter = useRef<boolean>(false);
	const refTimeSearch = useRef<any>({});
	const [selectValue, setSelectValue] = useState<OptionTypes | null>();
	const [optionAdd, setOptionAdd] = useState<OptionTypes | null>(null);

	useEffect(() => {
		if (open !== visible && typeof open === 'boolean') {
			setVisible(open);
		}
	}, [open]);

	useEffect(() => {
		if (setLoading && typeof setLoading === 'function') {
			setLoading(false);
		}
		setOptionAdd(null);

		if (optionAdd && !options.some((item) => item?.value === optionAdd.value)) {
			handleAddLocalOption(optionAdd);
			setSelectValue(optionAdd);
		}
	}, [options]);

	useEffect(() => {
		if (value && value !== selectValue?.value && options?.length > 0) {
			const selectNew: OptionTypes | null =
				options?.find((item) => item?.value === value) || null;
			setSelectValue(selectNew);
		}
	}, [value]);

	const handleAddLocalOption = (option: OptionTypes) => {
		let validate = true;

		if (validateAdd && typeof validateAdd === 'function') {
			validate = validateAdd(option);
		}
		if (validate) {
			onAddOption(option);
			setSelectValue(option);
		}
	};

	const onSelect = (values: any, option: any) => {
		refAdd.current = true;
		if (refTimeSearch.current) {
			clearTimeout(refTimeSearch.current);
		}
		setSelectValue(option);
		if (onSelectProps && typeof onSelectProps === 'function') {
			onSelectProps(option);
		}
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		const searchVal = (e.target as any)?.value || '';
		const searchFormat = searchVal ? searchVal?.trim() : '';
		const isEnter = e.key === KEYBOARD.ENTER.STR;

		refEnter.current = isEnter;

		if (
			!refAdd.current &&
			e.key === KEYBOARD.ENTER.STR &&
			!options?.some(
				(op) => op?.label?.toString()?.toLowerCase() === searchFormat.toLowerCase(),
			) &&
			searchFormat
		) {
			const optionAdd = {
				label: searchFormat,
				value: valueIsNumber ? Math.random() : searchFormat,
			};
			if (hasApiSearch) {
				onSearchVal(searchFormat);
				setOptionAdd(optionAdd);
			} else {
				handleAddLocalOption(optionAdd);
			}
		}
		refAdd.current = false;
	};

	const onSearchVal = (val: any, hasTimeOut?: boolean) => {
		if (setLoading && typeof setLoading === 'function') {
			setLoading(true);
		}

		if (onSearchProps && typeof onSearchProps === 'function') {
			if (hasTimeOut) {
				refTimeSearch.current = setTimeout(() => {
					onSearchProps(val);
				}, TIME_OUT.WAIT_SEARCH);
			} else {
				onSearchProps(val);
			}
		}
	};
	const onSearch = (val: any) => {
		if (refTimeSearch.current) {
			clearTimeout(refTimeSearch.current);
		}
		if (!refEnter.current) {
			onSearchVal(val, true);
		}
	};
	const onHandleLoading = (val: boolean) => {
		if (setLoading && typeof setLoading === 'function') {
			setLoading(val);
		}
	};

	const onScroll = (event: any) => {
		const target = event.target;

		const valScroll = target.scrollTop + target.offsetHeight;
		const valHeight = target.scrollHeight;

		if (
			!loading &&
			valScroll / valHeight >= 0.75 &&
			onLoadMore &&
			typeof onLoadMore === 'function'
		) {
			onHandleLoading(true);

			onLoadMore();
		}
	};

	return (
		<StyledSelect
			ref={refSelect}
			open={open}
			onDropdownVisibleChange={(open: boolean) => setVisible(open)}
			notFoundContent={notFoundContent(t)}
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
			virtual={false}
			optionFilterProp="label"
			options={options}
			showSearch
			dropdownRender={(menu: any) => (
				<LoadingWrapper sizeLoading={'default'} isLoading={loading}>
					{menu}
				</LoadingWrapper>
			)}
			onSearch={onSearch}
			loading={!!loading}
			onKeyDown={onKeyDown}
			dropdownClassName="dropdown-menu dropdown-custom-content"
			onSelect={onSelect}
			onPopupScroll={onScroll}
			value={selectValue?.value || undefined}
			{...other}
		/>
	);
};

export default SelectSingleAddNew;
