import {
	IconCustomTerms,
	IconFlag,
	IconKeyboardArrowRight,
	IconNoRights,
	IconPublishDetail,
	IconRightClear,
	IconRightsPending,
	IconUnPublishDetail,
} from '@assets/icons';
import { FORM_CONTENT_DETAIL, MEDIA_TYPE, UPLOADSOURCE } from '@constants/content/contentLibrary';
import { TYPE_USAGE_RESTRICTIONS, VERIFY_SCORE } from '@constants/contentLibrary/contentDetail';
import THEME from '@constants/themes/themes';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import { ContentDto, TermsContentDto } from '@models/content/contentLibrary/summary';
import { StyledIcon, StyledRow, StyledText } from '@styled/Common/CommonStyled';
import { StyledFormItem } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	StyledCollapse,
	StyledFlagged,
	StyledLabel,
	StyledLink,
	StyledName,
	StyledOverview,
	StyledPointScore,
	StyledTextArea,
	StyledWrapperTitle,
} from '@styled/ContentLibrary/ContentDetailStyled';
import { Spin, Tooltip } from 'antd';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PreviewTermsCDMModal from '../AdditionalInfo/PreviewTermsCDMModal';
import ContentFlags from '../ContentFlags';
import FormItemSelect from '../FormItemSelect';
import PDFTermModal from '../PdfTermModal';
import PublishLog from '../PublishLog';

const { Panel } = StyledCollapse;

type PropOverView = {
	optionData: Array<any>;
	currentMedia: ContentDto;
	otherPropSelectFlag: any;
};
const Overview = (props: PropOverView) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const { optionData, currentMedia, otherPropSelectFlag } = props;
	const isManualImport =
		currentMedia?.uploadSource === UPLOADSOURCE.MANUAL_IMPORT ||
		currentMedia?.uploadSource === UPLOADSOURCE.SCRIPT_IMPORT;

	const {
		verificationScore,
		termsContent,
		fetchVerificationScoreStatus,
		termsTemplateContent,
	}: ContentStoreDto = useSelector((state: any) => state.content);
	const {
		typeContent,
		collapseTerm,
		setCollapseTerm,
		handleFetchTerms,
		disableActionPage,
		isSocialContent,
	} = useContext(ContentDetailContext);

	const { review } = currentMedia;
	const { verified, contacted, published, customTerms, flags } = review || {};

	const [termsView, setTermsView] = useState<TermsContentDto | null>(null);
	const [openTermsTemplate, setOpenTermsTemplate] = useState<boolean>(false);

	const typeUsageRestrictions = useMemo(() => {
		let result = TYPE_USAGE_RESTRICTIONS.rightClear;
		if (!verified) {
			if (customTerms) {
				result = TYPE_USAGE_RESTRICTIONS.customTerms;
			} else {
				result = TYPE_USAGE_RESTRICTIONS.usageRestricted;
				if (contacted) {
					result = TYPE_USAGE_RESTRICTIONS.rightPending;
				}
			}
		}
		return result;
	}, [review]);

	const isFlagged = useMemo(() => flags && flags.length > 0, [flags]);

	const renderHeaderPanel = ({
		name,
		icon,
		hideArrow,
		isHasAsterisk,
	}: {
		name: string;
		icon: ReactNode;
		hideArrow?: boolean;
		isHasAsterisk?: boolean;
	}) => {
		return (
			<StyledWrapperTitle className={hideArrow ? 'no_action' : ''}>
				{renderTitle(name, icon)}
				{isHasAsterisk && isFlagged && (
					<>
						<StyledText
							fontSize="16px"
							margin="-5px 0 0 -5px"
							color={THEME.colors.white}>
							*
						</StyledText>
						{renderFlagged()}
					</>
				)}
				{!hideArrow && (
					<StyledIcon className="icon_arrow">
						<IconKeyboardArrowRight />
					</StyledIcon>
				)}
			</StyledWrapperTitle>
		);
	};

	const renderDataUsageRestrictions = () => {
		const data = {
			name: t('content_detail.rights_granted'),
			icon: <IconRightClear />,
		};
		if (typeUsageRestrictions === TYPE_USAGE_RESTRICTIONS.rightPending) {
			data.name = t('content_detail.right_pending');
			data.icon = <IconRightsPending />;
		}
		if (typeUsageRestrictions === TYPE_USAGE_RESTRICTIONS.usageRestricted) {
			data.name = t('content_detail.usage_restricted');
			data.icon = <IconNoRights />;
		}
		if (typeUsageRestrictions === TYPE_USAGE_RESTRICTIONS.customTerms) {
			data.name = t('content_detail.custom_terms');
			data.icon = <IconCustomTerms />;
		}
		return data;
	};

	const renderCheckPublish = () => {
		const data: any = {
			name: t('content_detail.published'),
			icon: <IconPublishDetail />,
		};
		if (!published) {
			data.name = t('content_detail.not_published');
			data.icon = <IconUnPublishDetail />;
		}
		return data;
	};

	const renderTitle = (name: ReactNode, icon: ReactNode) => {
		return (
			<StyledWrapperTitle>
				<StyledIcon size={36}>{icon}</StyledIcon>
				<StyledName>{name}</StyledName>
			</StyledWrapperTitle>
		);
	};

	const renderScore = () => {
		const currentVerificationScore =
			verificationScore && verificationScore.contentId === currentMedia.id
				? verificationScore
				: currentMedia.verificationScore;
		return {
			point: currentVerificationScore?.totalScore || null,
			fullMatch: currentVerificationScore?.fullMatchingImages?.length || null,
		};
	};
	const renderInfoScore = (infoScore: { point: number | null; fullMatch: number | null }) => {
		let classTheme = THEME.colors.gray5;

		if (infoScore.point) {
			if (infoScore?.point >= VERIFY_SCORE.medium && infoScore?.point < VERIFY_SCORE.large) {
				classTheme = 'yellow';
			} else if (infoScore?.point < VERIFY_SCORE.medium) {
				classTheme = 'red';
			} else {
				classTheme = 'green';
			}
		}

		return fetchVerificationScoreStatus && !infoScore.point ? (
			<StyledPointScore className="default">
				<Spin size="small" />
			</StyledPointScore>
		) : (
			<Tooltip
				placement="topLeft"
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				overlayClassName="content-info-tooltip"
				title={t('content_management.details.general_info.verification_score_tooltip', {
					numImages: infoScore?.fullMatch,
				})}>
				<StyledPointScore className={classTheme}>{infoScore?.point}</StyledPointScore>
			</Tooltip>
		);
	};

	const handleViewTerm = (item: TermsContentDto) => {
		setTermsView(item);
	};

	const renderFlagged = () => {
		return (
			<StyledFlagged>
				<StyledRow align="middle">
					<StyledIcon margin="0 0 0 -2px" fillPath="#EA4335">
						<IconFlag />
					</StyledIcon>
					<StyledText margin="0 0 0 8px">{t('content_detail.flagged')}</StyledText>
				</StyledRow>
			</StyledFlagged>
		);
	};

	const contentFlags = {
		otherPropSelectFlag,
	};

	return (
		<StyledOverview>
			<PDFTermModal
				title={termsView?.name}
				visible={termsView?.id}
				data={termsView?.signedContext || ''}
				onCancel={() => setTermsView(null)}
			/>
			{termsTemplateContent && openTermsTemplate && (
				<PreviewTermsCDMModal
					fileName={termsTemplateContent?.termsTemplateName}
					termsTemplateContent={termsTemplateContent}
					visible={openTermsTemplate}
					onCancel={() => setOpenTermsTemplate(false)}
					zIndex={9012}
				/>
			)}
			<StyledCollapse
				className={`collapse_content_detail ${
					isFlagged ? 'collapse_content_detail_flagged' : ''
				}`}
				onChange={(key: string | string[]) => {
					setCollapseTerm(!collapseTerm);
				}}
				activeKey={collapseTerm ? ['1'] : []}>
				<Panel
					header={renderHeaderPanel({
						...renderDataUsageRestrictions(),
						isHasAsterisk: true,
					})}
					key="1">
					<ContentFlags {...contentFlags} />
					<StyledFormItem
						style={{ margin: '16px 0 0' }}
						label={<StyledLabel>{t('content_detail.term_of_use')}</StyledLabel>}
						name={FORM_CONTENT_DETAIL.TERMS_OF_USE}>
						<StyledTextArea
							className="textarea_content"
							autoSize={{ minRows: 3, maxRows: 6 }}
							disabled={disableActionPage || !typeContent?.isSubmission}
						/>
					</StyledFormItem>
					{(typeContent?.isSubmission || disableActionPage) && (
						<>
							{currentMedia?.uploadSessionJson &&
								currentMedia?.uploadSessionJson?.termsIds &&
								currentMedia?.uploadSessionJson?.termsIds?.length > 0 && (
									<StyledLink
										style={{ marginTop: '8px', display: 'block' }}
										aria-hidden="true"
										onClick={() => {
											setOpenTermsTemplate(true);

											if (!termsTemplateContent) {
												handleFetchTerms();
											}
										}}
										target="_blank">
										{t('content_detail.text_view_terms')}
									</StyledLink>
								)}
							{!currentMedia?.uploadSessionJson &&
								termsContent?.map((item: TermsContentDto) => (
									<StyledLink
										style={{ marginTop: '8px', display: 'block' }}
										aria-hidden="true"
										key={item?.id}
										onClick={() => handleViewTerm(item)}
										target="_blank">
										{isManualImport
											? t('content_detail.text_name_terms', {
													val: item.name,
											  })
											: t('content_detail.text_view_terms')}
									</StyledLink>
								))}
						</>
					)}
				</Panel>
			</StyledCollapse>

			{currentMedia.mediaType !== MEDIA_TYPE.VIDEO &&
				!isSocialContent &&
				renderTitle(t('content_detail.verification_score'), renderInfoScore(renderScore()))}
			<StyledCollapse style={{ marginTop: '16px' }} className="collapse_content_detail">
				<Panel header={renderHeaderPanel({ ...renderCheckPublish() })} key="1">
					{published && (
						<FormItemSelect
							mode="multiple"
							style={{ margin: '16px 0 0' }}
							optionData={optionData}
							name={FORM_CONTENT_DETAIL.GALLERIES}
							disabled={typeContent?.isBookmark}
							label={t('content_detail.follow_gallery')}
						/>
					)}
					<PublishLog contentId={currentMedia?.id} disabled={disableActionPage} />
				</Panel>
			</StyledCollapse>
		</StyledOverview>
	);
};

export default Overview;
