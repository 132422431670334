import { IconArrowLine, IconLightBulb } from '@assets/icons';
import { CREATOR_PROFILE_MODAL_TYPE } from '@constants/creator';
import THEME from '@constants/themes/themes';
import CreatorProfileContext from '@contexts/Creators/Profile';
import { UserStoreType } from '@stores/creator/creator.types';
import { StyledFlex, StyledIcon, StyledText } from '@styled/Common/CommonStyled';
import { StyledCollapse, StyledContactBlock } from '@styled/Creators/ContactCardStyled';
import { StyledParticipation } from '@styled/Creators/RecommendationsStyled';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Recommendations = () => {
	const { t } = useTranslation();
	const { setModalType } = useContext(CreatorProfileContext);
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);

	const renderHeader = () => {
		return (
			<StyledFlex justify="flex-start">
				<StyledIcon size={26}>
					<IconLightBulb />
				</StyledIcon>
				<StyledText margin="0 0 0 6px">
					{t('creator_profile.recommendations.title')}
				</StyledText>
			</StyledFlex>
		);
	};

	const renderContent = () => {
		return creatorProfile?.recommendation ? (
			<StyledParticipation>
				<StyledText>
					{t('creator_profile.recommendations.has_not_participated_campaigns')}
				</StyledText>
				<StyledFlex
					justify="flex-start"
					onClick={() => {
						setModalType(CREATOR_PROFILE_MODAL_TYPE.CAMPAIGN_RECOMMENDATIONS);
					}}>
					<StyledText cursor="pointer" color={THEME.colors.gray3}>
						{t('creator_profile.recommendations.see_campaigns')}
					</StyledText>
					<StyledIcon margin="0 0 0 8px">
						<IconArrowLine />
					</StyledIcon>
				</StyledFlex>
			</StyledParticipation>
		) : (
			<StyledText>{t('creator_profile.recommendations.no_recommendations')}</StyledText>
		);
	};

	return (
		<StyledContactBlock style={{ opacity: creatorProfile?.recommendation ? 1 : '0.5' }}>
			<StyledCollapse bordered={false} expandIconPosition="right">
				<CollapsePanel header={renderHeader()} key="1">
					{renderContent()}
				</CollapsePanel>
			</StyledCollapse>
		</StyledContactBlock>
	);
};

export default Recommendations;
