import { COL_MEDIA } from '@constants/ambassador';
import { PARAM_DEFAULT_FEED } from '@constants/social_search/feeds';
import {
	ParamFilterFeed,
	ParamSocialFeed,
	SocialContentContainerDto,
	SocialContentFiltersDto,
} from '@models/socialsearch/summary';
import React from 'react';

export type FeedsManagerContextType = {
	setTypeCollapse: (val: string) => void;
	typeCollapse: string;
	socialFeedList: SocialContentContainerDto[];
	isFilter: boolean;
	filter: ParamFilterFeed;
	setFilter: (val: ParamFilterFeed) => void;
	paramSocial: ParamSocialFeed;
	setParamSocial: (val: ParamSocialFeed) => void;
	handleResetFilter: () => void;
	selectContentList: SocialContentContainerDto[];
	handleClearSelect: () => void;
	setTypeAction: (val: string) => void;
	handleImportAction: (filters?: SocialContentFiltersDto) => void;
	onSelectItems: (id?: string) => void;
	setSocialDetail: (val: SocialContentContainerDto | null) => void;
	socialDetail: SocialContentContainerDto | null;
	col: number;
	setCol: (val: number) => void;
	fullThumb: boolean;
	setFullThumb: (val: boolean) => void;
	setActionTypeOnFilter: (action: string) => void;
	actionTypeOnFilter: string;
};

// Default context values
const values: FeedsManagerContextType = {
	setTypeCollapse: () => {},
	typeCollapse: '',
	socialFeedList: [],
	isFilter: false,
	filter: {},
	setFilter: () => {},
	paramSocial: { ...PARAM_DEFAULT_FEED },
	setParamSocial: () => {},
	handleResetFilter: () => {},
	selectContentList: [],
	handleClearSelect: () => {},
	setTypeAction: () => {},
	handleImportAction: () => {},
	onSelectItems: () => {},
	setSocialDetail: () => {},
	socialDetail: null,
	col: COL_MEDIA.C3,
	setCol: () => {},
	fullThumb: false,
	setFullThumb: () => {},
	setActionTypeOnFilter: () => {},
	actionTypeOnFilter: '',
};

const FeedsManagerContext = React.createContext(values);
export default FeedsManagerContext;
