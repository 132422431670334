import THEME from '@constants/themes/themes';
import { StyledDropdown } from '@styled/Common/CommonStyled';
import React, { ReactNode, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
import { LoadingWrapper } from '..';

const StyledWrapperOverlay = styled.div`
	background-color: ${THEME.colors.darkBlue3};
	border: 1px solid ${THEME.colors.darkBlue4};
	max-width: 400px;
	text-align: left;
	box-shadow: rgb(163, 171, 177) 0px 0px 6px 0px;
`;

const StyledOverlay = styled.div<{ maxHeight: string; heightItem?: number }>`
	width: 100%;
	max-height: ${({ maxHeight }) => maxHeight};
	overflow: hidden auto;
	.ant-menu {
		background-color: ${THEME.colors.darkBlue3};
		margin: 0;
	}
	.ant-menu-item {
		&.ant-menu-item-only-child {
			&:not(.ant-menu-item-disabled) {
				&:hover {
					background-color: ${THEME.colors.orangeBase};
				}
			}
			&.ant-menu-item-disabled {
				cursor: not-allowed;
			}
			&:hover {
				svg {
					filter: unset !important;
				}
			}
			height: ${({ heightItem }) => `${heightItem || 32}px`};
			margin: 0;
			display: flex;
			align-items: center;
			padding: 6px 8px;
			background-color: transparent;

			.ant-menu-title-content {
				width: 100%;
				max-width: 350px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
`;

type PropTypes = {
	children: ReactNode;
	maxHeight?: string;
	overlay: ReactNode;
	onLoadMore: () => void;
	heightOverlay?: string;
	isLoading?: boolean;
	footerOverlay?: ReactNode | null;
	heightItem?: number;
	[other: string]: any;
};
const DropdownLoadMore = (props: PropTypes) => {
	const {
		children,
		maxHeight,
		overlay,
		onLoadMore,
		heightOverlay,
		isLoading = false,
		footerOverlay = null,
		heightItem = 32,
		...other
	} = props;
	const scrollbars = useRef<any>({});

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			if (scrollbarTop >= 0.75) {
				onLoadMore();
			}
		}
	};

	const overlayDropdown = (
		<StyledWrapperOverlay>
			<StyledOverlay
				heightItem={heightItem}
				style={{ height: `${heightOverlay || '100%'}` }}
				maxHeight={maxHeight || '220px'}
				className="custom_scroll_bar">
				<LoadingWrapper sizeLoading={'normal'} isLoading={isLoading}>
					<Scrollbars
						className="scrollbar_custom_wrapper"
						renderTrackVertical={() => <div />}
						ref={(el) => {
							scrollbars.current = el;
						}}
						onScrollStop={onScrollStop}
						style={{ height: `${'100%'}`, width: '100%' }}
						renderThumbHorizontal={() => <div />}
						renderThumbVertical={() => <div />}>
						{overlay}
					</Scrollbars>
				</LoadingWrapper>
			</StyledOverlay>
			{footerOverlay}
		</StyledWrapperOverlay>
	);

	return (
		<StyledDropdown
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
			placement="bottomRight"
			overlay={overlayDropdown}
			className="menu_item_full dropdown_action"
			trigger={['click']}
			{...other}>
			{children}
		</StyledDropdown>
	);
};

export default DropdownLoadMore;
