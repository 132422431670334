import PdfView from '@cores/PdfView';
import { formatBase64Pdf } from '@helpers/base64Helper';
import { Modal } from 'antd';
import styled from 'styled-components';

const StyledModalPdf = styled(Modal)`
	.ant-modal-title {
		text-align: center;
	}
	.ant-modal-body {
		max-height: 85vh;
		overflow: auto;
	}
`;

type PropsModalTypes = {
	data: string;
	[other: string]: any;
};
const PDFTermModal = (props: PropsModalTypes) => {
	const { data = '', title = '', ...other } = props;

	return (
		<StyledModalPdf centered width={'70%'} {...other} title={title} footer={null}>
			{data && <PdfView data={formatBase64Pdf(data)} fileName={title} />}
		</StyledModalPdf>
	);
};

export default PDFTermModal;
