import { CaretRightOutlined } from '@ant-design/icons';
import { IconCheckActive } from '@assets/icons';
import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { FILTER_BY, SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import { ContentDto } from '@models/content/contentLibrary/summary';
import {
	StyledImageTheme,
	StyledStatusActive,
	StyledThemeGallery,
} from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledIconPlay } from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import { handleImageLoadedError, handleThumbImageLoadedError } from '@utils/common';
import { checkExistExplicit } from '@utils/content';
import { useTranslation } from 'react-i18next';
import { TSelectableItemProps } from 'react-selectable-fast';

type PropsThemeItem = {
	content: ContentDto;
	isExist?: boolean;
	handleSelect: () => void;
	[other: string]: any;
};

const ThemeItem = (props: PropsThemeItem & TSelectableItemProps) => {
	const {
		content,
		selectableRef,
		isSelecting,
		isSelected,
		isExist = false,
		handleSelect,
	} = props;
	const { t } = useTranslation();

	const classNames = [
		'theme-item',
		isSelecting && 'theme-selecting',
		isSelected && 'theme-selected',
	]
		.filter(Boolean)
		.join(' ');

	const renderIconVideo = () => {
		if (content?.mediaType === FILTER_BY.VIDEO.value) {
			return (
				<StyledIconPlay className="icon_play">
					<CaretRightOutlined />
				</StyledIconPlay>
			);
		}
		return '';
	};
	return (
		<StyledThemeGallery
			className={classNames}
			isSelecting={isSelecting || isSelected}
			onClick={() => handleSelect()}
			ref={selectableRef}>
			{renderIconVideo()}
			{isExist && (
				<StyledStatusActive>
					<IconCheckActive />
				</StyledStatusActive>
			)}
			<StyledImageTheme
				src={content?.thumbnail}
				alt="img_theme"
				onError={(e) =>
					content?.social && content?.social.socialSource === SOCIAL_SOURCE.TIKTOK
						? handleThumbImageLoadedError(
								e,
								SOCIAL_SOURCE.TIKTOK,
								content?.social.permalink,
						  )
						: handleImageLoadedError(e, content?.thumbnail)
				}
			/>
			{checkExistExplicit(content?.review?.flags) && (
				<ExplicitContent zIndex={777} sizeIcon={48} fontSize="14px" />
			)}
		</StyledThemeGallery>
	);
};

export default ThemeItem;
