import { Button, Menu, Select, Tooltip, message } from 'antd';
import React, {
	Ref,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

// Styled
import {
	StyledBtn,
	StyledBtnCustom,
	StyledContainer,
	StyledContainerListOption,
	StyledIcon,
	StyledNameOption,
	StyledOptionItem,
	StyledSelect,
	StyledWrapperApplyAlbum,
	StyledWrapperOverlayDropdown,
} from '@styled/Content/ContentLibrary/ActionBarStyled';

import {
	StyledIcon as StyledIconCommon,
	StyledMenuItem,
	StyledSubMenu,
	StyledText,
	StyledTitle,
	StyledWrapperAction,
} from '@styled/Common/CommonStyled';

// Icons
import {
	IconCancel,
	IconCheck,
	IconFilter,
	IconGrid,
	IconLightBulb,
	IconLightBulbBasic,
	IconShare,
	IconSort,
} from '@assets/icons';
import ThumbnailBehavior from '@components/ThumbnailBehavior';
import {
	COL_GRID,
	DEFAULT_DOWNLOAD_BEHAVIOR,
	MESSAGE_KEY,
	TYPE_ACTIONS,
	TYPE_FILE,
} from '@constants/common';
import { TYPE_ALBUM } from '@constants/content/albums';
import { FOLDER, MEDIA_TYPE, ROTATE } from '@constants/content/contentLibrary';
import { SORT_DIRECTION, TYPE_SORT } from '@constants/filter/filterContent';
import { PARAMS_DEFAULT_CONTENT } from '@constants/publish/gallery';
import { ROLE } from '@constants/settings';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import ContentSummaryContext from '@contexts/Content/Summary';
import SearchBar from '@cores/SearchBar';
import { filterGalleryUnAssign } from '@helpers/arrayHelper';
import { getUser } from '@helpers/userHelpers';
import { CampaignDto, ItemListContent, StateCampaign } from '@models/campaign';
import { ActionsTypes, AlbumRespDto } from '@models/content/albums/albumManager';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import { RefContentSearch } from '@models/content/contentLibrary/ref';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { TypeSortDto } from '@models/filter';
import { FolderDto } from '@models/settings/content';
import { SettingRootStateDto } from '@models/settings/setting';
import {
	exportAlbumContentsEnd,
	exportAlbumContentsRequest,
	reviewMultiContentsRequest,
	rotateImageRequest,
	updateAccountSettingRequest,
} from '@stores/actions';
import { StyledDropdown } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledClearSelection } from '@styled/SocialSearch/Feeds';
import { convertToSingleContent, getContentByType } from '@utils/common';
import { handleRotateContentSelect, removeTimeStampUrl } from '@utils/funcHelper';
import { setDownloadBehavior, setDownloadMenuItem } from '@utils/renderComponent';
import { useDispatch, useSelector } from 'react-redux';
import ModalImportContent from '../ModalImportContent';

const { Option } = Select;

type ActionBarProps = {
	isMove: boolean;
	moveToList: string[];
	handleFilters: () => void;
	handleOpenActions: () => void;
	selectContentList: Array<any>;
	onChangeMoveTo: (value: any) => void;
	handleMoveTo: () => void;
	onDelete: () => void;
	handleSearch: (value: string) => void;
	onDownload: () => void;
};

interface ActionsTypeSort extends ActionsTypes {
	valueSort: TypeSortDto;
}

type RotateContentTypes = {
	id: number;
	url: string;
	thumbnailUrl: string;
};

const ActionBar = forwardRef((props: ActionBarProps, ref: Ref<RefContentSearch>) => {
	const { t } = useTranslation();
	// Reducers
	const dispatch = useDispatch();
	const { gridConfiguration, totalContent } = useSelector(
		(state: ContentRootStateDto) => state.content,
	);
	const { campaignListContent = [] }: CampaignDto = useSelector(
		(state: StateCampaign) => state.campaign,
	);

	const {
		albumList,
		handleAssignContentToAlbum,
		albumsSelect,
		setAlbumsSelect,
		setVisibleAlbum,
		isLoading,
		typeContent,
		isFiltering,
		resetContentFilters,
		setParamsContent,
		paramsContent,
		handleClickMarkAll,
		isAlbumSuggest,
		setTypeAction,
		setVisibleAlbumPopup,
		enableShare,
		setVisibleShareModal,
		setAnalyzeContent,
		contentList,
		setContentList,
		handleClearSelect,
		setSelectContentList,
		setIsLoading,
		setOtherLoading,
		contentIdSelect,
		setFindSimilar,
		findSimilar,
	} = useContext(ContentSummaryContext);
	const { isAlbumDetail, isSubmission, isArchive, isBookmark } = typeContent;

	const { albumDetail, statusExportAlbumContents, error }: AlbumsStoreDto = useSelector(
		(state: any) => state.albumManager,
	);

	const { folders = [] } = useSelector((state: SettingRootStateDto) => state.settings.content);

	const refSearch = useRef<any>({});

	const hiddenMoveTo = true;

	// Props
	const {
		isMove,
		moveToList,
		handleFilters,
		handleOpenActions,
		selectContentList,
		onChangeMoveTo,
		handleMoveTo,
		onDelete,
		handleSearch,
		onDownload,
	} = props;

	const user = getUser();
	const { role, brandName, email } = user;

	const [visibleImport, setVisibleImport] = useState<boolean>(false);
	const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false);
	const [galleryList, setGalleryList] = useState<FolderDto[]>([]);
	const [gallerySelect, setGallerySelect] = useState<FolderDto[]>([]);
	const [campaignSelect, setCampaignSelect] = useState<ItemListContent[]>([]);
	const [openKeysMenu, setOpenKeysMenu] = useState<string[]>([]);

	useImperativeHandle(ref, () => ({
		resetSearchValue,
	}));

	useEffect(() => {
		if (folders?.length > 0) {
			setGalleryList(filterGalleryUnAssign(folders));
		}
	}, [folders]);

	useEffect(() => {
		if (statusExportAlbumContents === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (statusExportAlbumContents === FAILED) {
			message.error(error);
		}
		if (statusExportAlbumContents === SUCCEEDED) {
			message.success(t('albums.message.export_album_content_email'), 6);
		}
		if (statusExportAlbumContents === FAILED || statusExportAlbumContents === SUCCEEDED) {
			dispatch(exportAlbumContentsEnd());
			setIsLoading(false);
		}
	}, [statusExportAlbumContents]);

	// Render components
	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	// Functions
	const onChangeMoveToFolder = (value: any) => {
		onChangeMoveTo(value);
	};

	const renderSelect = (options: any, title: string, placehoder: string) => {
		return (
			<React.Fragment>
				<StyledSelect
					notFoundContent={notFoundContent()}
					placeholder={placehoder}
					onChange={onChangeMoveToFolder}>
					{options.map((item: any) => {
						return (
							<Option key={item} value={item.toLowerCase()}>
								{item}
							</Option>
						);
					})}
				</StyledSelect>
				<StyledBtn width="58px" type="ghost" isDisabled={!isMove} onClick={handleMoveTo}>
					{title}
				</StyledBtn>
			</React.Fragment>
		);
	};

	const onChangeCol = () => {
		dispatch(
			updateAccountSettingRequest({
				gridConfiguration:
					gridConfiguration === COL_GRID.DEFAULT ? COL_GRID.MORE : COL_GRID.DEFAULT,
			}),
		);
	};

	// Handle change

	const onPressSearch = (value: string) => {
		if (value) {
			handleSearch(value.trim());
		} else {
			handleSearch('');
		}
	};

	const resetSearchValue = () => {
		if (refSearch?.current && refSearch?.current?.clearSearchBarRef) {
			refSearch?.current?.clearSearchBarRef();
		}
	};

	const handleSelectAlbum = (album: AlbumRespDto) => {
		let newListSelect = [...albumsSelect];
		if (newListSelect?.some((albumItem) => albumItem?.id === album?.id)) {
			newListSelect = newListSelect?.filter((item) => item?.id !== album?.id);
		} else {
			newListSelect.push(album);
		}
		setAlbumsSelect([...newListSelect]);
	};

	const isDisplay = selectContentList.length > 0;
	const isEReviewer = role.toLowerCase() === ROLE.EREVIEWER.value.toLowerCase();

	const actionsObj = () => {
		const result: ActionsTypes[] = [
			{
				id: -1,
				name: t('content_management.action_bar.create_new_album'),
				click: () => {
					setVisibleAlbumPopup(TYPE_ACTIONS.CREATE_ALBUM);
					setVisibleAlbum(false);
				},
				disabled: false,
			},
		];
		albumList?.forEach((album: AlbumRespDto) => {
			if (album?.type !== TYPE_ALBUM.VERIFIED_ALBUM) {
				result.push({
					id: album.id,
					name: album.name,
					click: (e) => {
						e.preventDefault();
						e.stopPropagation();
						handleSelectAlbum(album);
					},
				});
			}
		});

		return result;
	};

	const handleSelectGallery = (gallery: FolderDto) => {
		let newListSelect = [...gallerySelect];
		if (newListSelect?.some((galleryItem) => galleryItem?.id === gallery?.id)) {
			newListSelect = newListSelect?.filter((item) => item?.id !== gallery?.id);
		} else {
			newListSelect.push(gallery);
		}
		setGallerySelect([...newListSelect]);
	};

	const actionsGalleryObj = () => {
		return galleryList?.map((gallery: FolderDto) => {
			return {
				id: gallery.id,
				name: gallery.name,
				click: (e: any) => {
					e.preventDefault();
					e.stopPropagation();
					handleSelectGallery(gallery);
				},
			};
		});
	};

	const handleSelectCampaign = (campaign: ItemListContent) => {
		let newListSelect = [...campaignSelect];
		if (newListSelect?.some((campaignItem) => campaignItem?.id === campaign?.id)) {
			newListSelect = newListSelect?.filter((item) => item?.id !== campaign?.id);
		} else {
			newListSelect.push(campaign);
		}
		setCampaignSelect([...newListSelect]);
	};

	const actionsCampaignObj = () => {
		return campaignListContent?.map((campaign: ItemListContent) => {
			return {
				id: campaign.id,
				name: campaign.name,
				click: (e: any) => {
					e.preventDefault();
					e.stopPropagation();
					handleSelectCampaign(campaign);
				},
			};
		});
	};

	const handleSort = (type: TypeSortDto) => {
		setParamsContent((prev: any) => ({ ...prev, ...type, page: 1 }));
	};

	const dataContentRotate = useMemo(() => {
		const contentRotates: RotateContentTypes[] = [];
		selectContentList.forEach((item) => {
			const content: ContentDto = getContentByType(item);
			if (content?.mediaType === MEDIA_TYPE.IMAGE) {
				const arrNoSp = [TYPE_FILE.GIF, TYPE_FILE.WEBP];
				const fileExtension = `.${content?.contentMetadata?.fileExtension}`.toLowerCase();
				const contentUrl = removeTimeStampUrl(content?.contentUrl);
				const contentThumb = removeTimeStampUrl(content.thumbnail);
				const positionType = content?.contentUrl?.lastIndexOf('.');
				const type = content?.contentUrl?.slice(positionType);
				if (!arrNoSp.includes(type) && !arrNoSp.includes(fileExtension)) {
					contentRotates.push({
						id: content.id,
						url: contentUrl,
						thumbnailUrl: contentThumb,
					});
				}
			}
		});
		return contentRotates;
	}, [selectContentList]);

	const handleRotateContents = async () => {
		const ids = dataContentRotate.map((item) => item.id);
		const data = handleRotateContentSelect(contentList, ids);
		setContentList(data);
		setTimeout(() => {
			dispatch(
				rotateImageRequest({
					rotationAngleEnum: `${ROTATE.DEGREE_H}${ROTATE.D_90}`,
					ids,
				}),
			);
		}, 0);
	};

	const handleDownload = () => {
		onDownload();

		if (downloadBehavior.isDisplayMessage) {
			const config = {
				key: MESSAGE_KEY.DOWNLOAD_BOOKMARK_CONTENT,
				content: t('tooltip.download_multi_bookmark_content'),
			};
			message.info(config);
		}
	};

	const handleVisibleDropdown = (val: boolean) => {
		setVisibleDropdown(val);
		if (!val) {
			setOpenKeysMenu([]);
		}
	};

	const handleSaveAssignAlbum = () => {
		handleVisibleDropdown(false);
		handleAssignContentToAlbum([...albumsSelect]);
	};

	const handleSaveGallery = () => {
		handleVisibleDropdown(false);
		setSelectContentList([]);
		setGallerySelect([]);
		handleRequestReviewContent({ galleries: gallerySelect.map((item) => item.id) });
	};

	const handleRequestReviewContent = (otherField: any) => {
		setIsLoading(true);
		const requestData: any = {
			isRemove: false,
			contentIds: contentIdSelect,
			...otherField,
		};

		dispatch(
			reviewMultiContentsRequest({
				params: requestData,
			}),
		);
	};

	const onExportContent = (otherParam: {
		isExportFileExcel?: boolean;
		isExportFileCsv?: boolean;
	}) => {
		const params = {
			brandName,
			accountEmail: email,
			albumName: albumDetail?.name,
			webUrl: window.location.href,
			...otherParam,
		};

		const obj: any = {};
		if (albumDetail?.type === TYPE_ALBUM.VERIFIED_ALBUM) {
			obj.customTerms = true;
			obj.verified = true;
		}
		const body = {
			...PARAMS_DEFAULT_CONTENT,
			...obj,
			search: '',
			folder: albumDetail?.id?.toString() || '',
		};
		dispatch(exportAlbumContentsRequest({ params, body }));
	};

	const handleSaveCampaign = () => {
		handleVisibleDropdown(false);
		setSelectContentList([]);
		setCampaignSelect([]);
		handleRequestReviewContent({ campaigns: campaignSelect.map((item) => item.id) });
	};

	const downloadBehavior = useMemo(() => {
		if (selectContentList.length > 0)
			return setDownloadBehavior(convertToSingleContent(selectContentList), t);
		return DEFAULT_DOWNLOAD_BEHAVIOR;
	}, [selectContentList]);

	const actionShare: ActionsTypes[] | null = !downloadBehavior.disabled
		? setDownloadMenuItem(t)?.map((item: any) => {
				const action = handleDownload;
				return {
					...item,
					click: action,
					disabled: item?.name === t('button.download') && isArchive,
				};
		  })
		: null;

	const actionSort: ActionsTypeSort[] = [
		{
			id: 1,
			name: t('filter.sort.sort_date'),
			click: () => {},
			valueSort: { sortBy: TYPE_SORT.SORT_DATE, sortDirection: SORT_DIRECTION.DESC },
		},
		{
			id: 2,
			name: t('filter.sort.sort_date_reverse'),
			click: () => {},
			valueSort: { sortBy: TYPE_SORT.SORT_DATE, sortDirection: SORT_DIRECTION.ASC },
		},
		{
			id: 3,
			name: t('filter.sort.sort_rating'),
			click: () => {},
			valueSort: { sortBy: TYPE_SORT.SORT_RATING, sortDirection: SORT_DIRECTION.ASC },
		},
		{
			id: 4,
			name: t('filter.sort.sort_rating_reverse'),
			click: () => {},
			valueSort: { sortBy: TYPE_SORT.SORT_RATING, sortDirection: SORT_DIRECTION.DESC },
		},
	];

	const actionOther: ActionsTypes[] = [
		{
			id: 1,
			name: t('button.rotate'),
			click: () => handleRotateContents(),
			hidden: !isDisplay || dataContentRotate.length === 0,
			noVisible: true,
		},
		{
			id: 2,
			name: t('button.analyze'),
			click: () => handleAnalyzeContentModal(false),
			hidden: !isDisplay || !isEReviewer,
		},
		{
			id: 3,
			name: t('button.mark_up'),
			click: () => handleOpenActions(),
			hidden: isArchive || !isDisplay,
		},
		{
			id: 4,
			name: t('content_management.action_bar.add_to_album'),
			click: () => {},
			hidden: !(isDisplay && (isSubmission || isBookmark)),
			children: actionsObj(),
			isSelectMulti: true,
			idsSelected: albumsSelect.map((item) => item.id),
			onSave: handleSaveAssignAlbum,
		},
		{
			id: 5,
			name: t('gallery_manager.button.add_to_gallery'),
			click: () => {},
			hidden: !(isDisplay && (isSubmission || isBookmark)),
			children: actionsGalleryObj(),
			isSelectMulti: true,
			idsSelected: gallerySelect.map((item) => item.id),
			onSave: handleSaveGallery,
		},
		{
			id: 6,
			name: t('button.assign_campaign'),
			click: () => {},
			hidden: !(isDisplay && (isSubmission || isBookmark)),
			children: actionsCampaignObj(),
			isSelectMulti: true,
			idsSelected: campaignSelect.map((item) => item.id),
			onSave: handleSaveCampaign,
		},
		{
			id: 7,
			name: isAlbumDetail ? t('button.export_media') : t('button.export'),
			click: () => {},
			hidden: !(
				isDisplay &&
				!isBookmark &&
				!isAlbumSuggest &&
				actionShare &&
				!downloadBehavior.disabled
			),
			children: [...(actionShare || [])],
		},
		{
			id: 70,
			name: t('albums.button.export_album_spreadsheet'),
			click: () => {},
			hidden: !isAlbumDetail,
			children: [
				{
					id: 701,
					name: t('albums.button.microsoft_excel'),
					click: () => {
						onExportContent({ isExportFileExcel: true });
					},
				},
				{
					id: 702,
					name: t('albums.button.comma_separated_values'),
					click: () => {
						onExportContent({ isExportFileCsv: true });
					},
				},
			],
		},
		{
			id: 8,
			name: t('button.archive'),
			click: () => {
				onChangeMoveToFolder(FOLDER.REJECTED.toLowerCase());
				handleMoveTo();
			},
			hidden: !(isDisplay && (isSubmission || isBookmark)),
		},
		{
			id: 9,
			name: t('button.delete'),
			click: () => onDelete(),
			hidden: isAlbumDetail || !isDisplay,
		},
		{
			id: 10,
			name: t('button.import_content'),
			click: () => handleOpenImportContent(),
			hidden: !typeContent.isSubmission,
		},
		{
			id: 11,
			name: t(
				`content_management.action_bar.more.${
					isAlbumDetail ? 'analyze_all_contents_album' : 'analyze_all_contents'
				}`,
			),
			click: () => handleAnalyzeContentModal(true),
			hidden: !isEReviewer || !isAlbumDetail,
		},
		{
			id: 12,
			name: t('albums.button.remove_multi_content'),
			click: () => setTypeAction(TYPE_ACTIONS.REMOVE_OUT_ALBUM),
			hidden:
				!(isAlbumDetail && isDisplay) ||
				(isAlbumDetail &&
					(albumDetail?.type === TYPE_ALBUM.VERIFIED_ALBUM || isAlbumSuggest)),
		},
		{
			id: 13,
			name: t('button.restore'),
			click: () => {
				onChangeMoveToFolder(FOLDER.UNREJECTED.toLowerCase());
				handleMoveTo();
			},
			hidden: !isArchive || !isDisplay,
		},
	];

	const hasItem = actionOther.filter((item) => !item?.hidden).length > 0;

	const renderMenuAction = (
		listAction: ActionsTypes[],
		idsSelected: Array<string | number>,
		onSave: () => void,
	) => {
		return (
			<StyledWrapperOverlayDropdown>
				<StyledContainerListOption>
					{listAction.map((item: ActionsTypes) => {
						if (item?.hidden) {
							return null;
						}
						return (
							<StyledOptionItem key={item.id} onClick={item.click}>
								<StyledNameOption>{item.name}</StyledNameOption>
								{idsSelected.includes(item.id) && (
									<StyledIcon>
										<IconCheck />
									</StyledIcon>
								)}
							</StyledOptionItem>
						);
					})}
				</StyledContainerListOption>
				<StyledWrapperApplyAlbum>
					<Button
						type="primary"
						style={{ width: '90%' }}
						onClick={() => {
							onSave();
						}}
						loading={isLoading}
						disabled={idsSelected?.length === 0}>
						{t('button.save')}
					</Button>
				</StyledWrapperApplyAlbum>
			</StyledWrapperOverlayDropdown>
		);
	};

	const menuActionMore = (
		<Menu openKeys={openKeysMenu} onOpenChange={setOpenKeysMenu} defaultOpenKeys={[]}>
			{actionOther.map((item: ActionsTypes) => {
				if (item.hidden) {
					return null;
				}
				const menuItem = (action: ActionsTypes) => {
					return (
						<StyledMenuItem
							className="overlay"
							key={action.id}
							disabled={action?.disabled}
							onClick={(e: any) => {
								action.click(e);
								if (action?.noVisible) {
									handleVisibleDropdown(true);
								} else {
									handleVisibleDropdown(false);
								}
							}}>
							{action.name}
						</StyledMenuItem>
					);
				};
				if (item?.isSelectMulti) {
					return (
						<StyledSubMenu
							popupOffset={[0, 0]}
							key={item.id}
							className="hidden_icon"
							title={item.name}>
							{renderMenuAction(
								item.children || [],
								item.idsSelected || [],
								item.onSave,
							)}
						</StyledSubMenu>
					);
				}
				if (item?.children) {
					return (
						<StyledSubMenu
							key={item.id}
							popupOffset={[0, 0]}
							className="hidden_icon"
							title={item.name}>
							{item?.children.map((ch) => menuItem(ch))}
						</StyledSubMenu>
					);
				}
				return menuItem(item);
			})}
		</Menu>
	);

	const menuActionSort = (
		<Menu>
			{actionSort.map((item: ActionsTypeSort) => (
				<Menu.Item
					className={
						paramsContent?.sortBy === item.valueSort.sortBy &&
						paramsContent?.sortDirection === item.valueSort.sortDirection
							? 'active_menu_item'
							: ''
					}
					key={item.id}
					onClick={() => handleSort(item?.valueSort)}>
					{item.name}
				</Menu.Item>
			))}
		</Menu>
	);

	const handleShareAlbum = () => {
		setVisibleShareModal(true);
	};

	const propsThumbnail = isArchive ? {} : { handleClickMarkAll };

	const handleAnalyzeContentModal = (isAllContents: boolean) => {
		setAnalyzeContent({
			visible: true,
			isAllContents,
		});
	};

	const handleOpenImportContent = () => {
		setVisibleImport(true);
	};

	const handleChangeSearchSimilar = () => {
		setFindSimilar(!findSimilar);
	};

	return (
		<StyledContainer className="custom_scroll_bar">
			<StyledWrapperAction>
				{!isBookmark && isEReviewer && (
					<Tooltip
						title={
							findSimilar
								? t('content_management.sub_search_similar_on')
								: t('content_management.sub_search_similar_off')
						}>
						{findSimilar ? (
							<StyledIconCommon
								margin="0 8px 0 0"
								size={24}
								onClick={() => handleChangeSearchSimilar()}>
								<IconLightBulb />
							</StyledIconCommon>
						) : (
							<StyledIconCommon
								margin="0 8px 0 0"
								fill="none"
								className="hover_level_path"
								colorStrokeHover={THEME.colors.orangeBase}
								onClick={() => handleChangeSearchSimilar()}>
								<IconLightBulbBasic />
							</StyledIconCommon>
						)}
					</Tooltip>
				)}

				<SearchBar
					onSearch={onPressSearch}
					enterButton
					width={'250px'}
					placeholder={t('albums.placeholder.search_content')}
					ref={refSearch}
				/>
				<ModalImportContent
					visible={visibleImport}
					handleCancel={() => setVisibleImport(false)}
				/>
				{isDisplay && (
					<React.Fragment>
						{!hiddenMoveTo &&
							renderSelect(
								moveToList,
								t('content_management.action_bar.move'),
								t('content_management.placeholder.move_to'),
							)}
					</React.Fragment>
				)}
				{hasItem && (
					<StyledDropdown
						className="dropdown_action"
						placement="bottomLeft"
						overlay={menuActionMore}
						visible={visibleDropdown}
						onVisibleChange={(val: boolean) => {
							handleVisibleDropdown(val);
						}}
						trigger={['click']}>
						<StyledBtnCustom type="ghost">
							{t('content_management.action_bar.actions')}
						</StyledBtnCustom>
					</StyledDropdown>
				)}
				{selectContentList.length > 0 && (
					<StyledTitle
						fontSize="12px"
						lineHeight="16px"
						margin="0 0 0 8px"
						display="inline-flex">
						<Trans
							t={t}
							values={{
								num: selectContentList.length,
								more: selectContentList?.length === 1 ? '' : 's',
							}}
							i18nKey={'content.content_selected'}
							components={{
								elm: <StyledClearSelection onClick={() => handleClearSelect()} />,
							}}
						/>
					</StyledTitle>
				)}
			</StyledWrapperAction>

			<StyledWrapperAction margin="0 0 0 16px">
				<StyledText color={THEME.colors.gray5} fontSize="12px" margin="0 8px 0 0">
					{t('social_search.displaying_results', {
						value: `${contentList?.length} / ${totalContent || 0}`,
					})}
				</StyledText>

				{isAlbumDetail && (
					<>
						<Button
							disabled={
								downloadBehavior?.countContent > 0 || isAlbumSuggest || !enableShare
							}
							onClick={handleShareAlbum}
							icon={<IconShare />}
							style={{ marginLeft: '10px' }}>
							{t('albums.button.share_album')}
						</Button>
					</>
				)}

				<ThumbnailBehavior {...propsThumbnail} />
				<StyledBtnCustom icon={<IconGrid />} type="ghost" onClick={onChangeCol} />

				<StyledDropdown
					className="dropdown_action"
					placement="bottomLeft"
					overlay={menuActionSort}
					trigger={['click']}>
					<Button icon={<IconSort />} style={{ marginLeft: '8px' }} />
				</StyledDropdown>

				<Button
					icon={
						isFiltering ? (
							<StyledIconCommon
								className="icon_white"
								onClick={(e: React.MouseEvent<HTMLElement>) => {
									e.stopPropagation();
									e.preventDefault();
									resetContentFilters();
								}}>
								<IconCancel />
							</StyledIconCommon>
						) : (
							<IconFilter />
						)
					}
					style={{ marginLeft: '8px' }}
					type={isFiltering ? 'primary' : undefined}
					onClick={handleFilters}>
					{t('content_management.action_bar.filter')}
				</Button>
			</StyledWrapperAction>
		</StyledContainer>
	);
});

export default ActionBar;
