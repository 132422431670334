import React from 'react';
import { Button as Btn } from 'antd';
import styled from 'styled-components';
import { ButtonType, ButtonSize } from 'antd/lib/button';
import THEME from '@constants/themes/themes';

const StyledBtn = styled(Btn)<{ color?: string; txtcolor?: string }>`
	// background-color: ${({ color }) => color || THEME.colors.white};
	color: ${({ txtcolor }) => txtcolor || THEME.colors.gray5};

	&.ant-btn-ghost.ant-btn-loading {
		border: 1px solid transparent !important;
		color: ${({ txtcolor }) => txtcolor || THEME.colors.gray5};
	}
`;

type ButtonProps = {
	children?: any;
	type?: ButtonType;
	size?: ButtonSize;
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	loading?: boolean | { delay?: number };
	color?: string;
	txtcolor?: string;
	icon?: React.ReactNode;
	className?: string;
};

const Button = ({ children, ...rest }: ButtonProps) => {
	return <StyledBtn {...rest}>{children}</StyledBtn>;
};

Button.defaultProps = {
	type: 'primary',
	size: 'middle',
	disabled: false,
	loading: false,
	color: THEME.colors.white,
	txtcolor: '#e0e3e5',
	onClick: () => {},
};

export default Button;
