import {
	StyledContentItem,
	StyledLabel,
	StyledValueContent,
} from '@styled/Publish/Gallery/GalleryManagerStyled';
import React, { ReactNode } from 'react';

type PropContentItem = {
	label: ReactNode;
	content: ReactNode;
	marginContent?: string;
};

const ContentItem = ({ label, content, marginContent }: PropContentItem) => {
	return (
		<StyledContentItem>
			<StyledLabel>{label}</StyledLabel>
			<StyledValueContent margin={marginContent || ''}>{content}</StyledValueContent>
		</StyledContentItem>
	);
};

export default ContentItem;
