import { IconCreator, IconImage } from '@assets/icons';
import { KEY_FIELD_FORM_WIDGET } from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import { StyledIcon, StyledWrapperAction, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledFormItem } from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { Switch } from 'antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledSwitch = styled(Switch)`
	border-radius: 4px;
	.ant-switch-handle {
		display: none;
	}
	.ant-switch-inner {
		margin: 0 7px;
	}
	&.ant-switch-checked {
		background-color: ${THEME.colors.darkBlue2};
		&:focus {
			box-shadow: none;
		}
	}
	background-color: ${THEME.colors.darkBlue2};
`;

type PropTypes = {
	checked: boolean;
	keyIndex: number;
	hideTitle?: boolean;
	disabled?: boolean;
	[other: string]: any;
};
const SwitchMapping = (props: PropTypes) => {
	const { keyIndex, checked, hideTitle, disabled = false, ...other } = props;
	const { t } = useTranslation();

	return (
		<StyledWrapperContent display="flex" justifyContent="flex-end">
			<StyledFormItem
				margin="0"
				name={`${KEY_FIELD_FORM_WIDGET.mapping_type}${keyIndex}`}
				style={{ display: 'inline-flex', alignItems: 'center' }}
				marginLabel="0"
				fontSizeLabel={'12px'}
				label={
					hideTitle ? undefined : (
						<StyledWrapperContent
							onClick={(e: React.MouseEvent<HTMLElement>) => {
								e.preventDefault();
								e.stopPropagation();
							}}
							style={disabled ? { opacity: 0.4, cursor: 'not-allowed' } : {}}>
							{t('content.widget_manager.widget_builder.map_property')}
						</StyledWrapperContent>
					)
				}>
				<StyledSwitch
					checked={checked}
					checkedChildren={
						<StyledWrapperAction fontSize="12px">
							{t('content.widget_manager.widget_builder.content')}
							<StyledIcon marginSvg={'0 0 0 3px'} size={16} fill={THEME.colors.pink1}>
								<IconImage />
							</StyledIcon>
						</StyledWrapperAction>
					}
					unCheckedChildren={
						<StyledWrapperAction fontSize="12px">
							{t('content.widget_manager.widget_builder.creator')}
							<StyledIcon marginSvg={'0 0 0 3px'} size={14}>
								<IconCreator />
							</StyledIcon>
						</StyledWrapperAction>
					}
					disabled={disabled}
					{...other}
				/>
			</StyledFormItem>
		</StyledWrapperContent>
	);
};

export default SwitchMapping;
