import { IconFire, IconInfoTooltip } from '@assets/icons';
import { TIME_TYPE, TYPE_FORMAT_DATE, UNIT_VALUE } from '@constants/common';
import THEME from '@constants/themes/themes';
import { convertTime, convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { StyledFlex, StyledIcon, StyledText } from '@styled/Common/CommonStyled';
import { StyledRefreshingMetrics } from '@styled/Creators/SocialStatsStyled';
import { Popover } from 'antd';
import Tooltip, { TooltipPlacement } from 'antd/lib/tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type RefreshMetricsProps = {
	refreshMetricsEnabled: boolean;
	metricsRefreshUpdateAt: number | null;
	ambassadorMetricsRefreshInProgress?: boolean;
	onRefresh: () => void;
	placement?: TooltipPlacement;
};

const RefreshMetrics = (props: RefreshMetricsProps) => {
	const { t } = useTranslation();
	const {
		refreshMetricsEnabled,
		metricsRefreshUpdateAt = 0,
		ambassadorMetricsRefreshInProgress = false,
		onRefresh,
		placement = 'bottomRight',
	} = props;

	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { ambassadorMetricsWaitingPeriod, ambassadorMetricsRefreshAutomaticTimeEnabled = false } =
		clientSettings || {};

	const renderInProgressTooltip = () => {
		return (
			<StyledFlex>
				<StyledIcon size={24} fillPath={THEME.colors.blueBase}>
					<IconInfoTooltip />
				</StyledIcon>
				<StyledText>{t('ambassador.refresh_metrics_in_progress')}</StyledText>
			</StyledFlex>
		);
	};

	const renderDisabledTooltip = () => {
		let timeUnit = t('ambassador.time.hour');
		let nextDateTime = 0;
		const convertedTime = convertTime(ambassadorMetricsWaitingPeriod);
		const { type = '', value = '', suffix = false } = convertedTime || {};

		if (type === TIME_TYPE.DAYS) {
			timeUnit = t('ambassador.time.day');
		} else if (type === TIME_TYPE.WEEKS) {
			timeUnit = t('ambassador.time.week');
		}

		if (metricsRefreshUpdateAt) {
			nextDateTime =
				metricsRefreshUpdateAt +
				ambassadorMetricsWaitingPeriod * UNIT_VALUE.S_HOUR * UNIT_VALUE.S_1K;
		}

		return (
			<>
				<StyledFlex justify="flex-start">
					<StyledIcon fillPath="#7F61FB">
						<IconFire />
					</StyledIcon>
					<StyledText fontSize="16px" margin="0 0 0 8px">
						{t('ambassador.latest_ambassador_metrics')}
					</StyledText>
				</StyledFlex>

				<StyledText
					margin="8px 0 0 0"
					dangerouslySetInnerHTML={{
						__html: t('ambassador.tooltip.metric_hour', {
							nextDateTime: `${convertUtcToLocalTimeWithFormat(
								nextDateTime,
								TYPE_FORMAT_DATE.TIME_TO_SECONDS_12_HOUR,
							)}`,
							ambassadorMetricsWaitingPeriod: `${value} ${timeUnit}${
								suffix ? 's' : ''
							}`,
						}),
					}}
				/>
			</>
		);
	};

	const renderContent = () => {
		if (!refreshMetricsEnabled) {
			if (ambassadorMetricsRefreshInProgress) {
				return renderInProgressTooltip();
			}

			return renderDisabledTooltip();
		}

		return null;
	};

	const renderTextRefresh = () => {
		const elm = (
			<Tooltip title={t('creator_profile.social_stats.refresh_metrics_now')}>
				<StyledText
					isDisabled={ambassadorMetricsRefreshAutomaticTimeEnabled}
					cursor="pointer"
					margin="0 0 0 6px"
					fontSize="12px !important"
					className="italic underline hover overflow"
					whiteSpace="nowrap"
					onClick={(e) => {
						e.stopPropagation();
						if (!refreshMetricsEnabled) {
							return;
						}
						onRefresh();
					}}>
					{t('creator_profile.social_stats.refresh_metrics_now')}
				</StyledText>
			</Tooltip>
		);

		return (
			<StyledFlex>
				<StyledRefreshingMetrics
					style={{ maxWidth: '100%' }}
					margin="0"
					isDisabled={!refreshMetricsEnabled}>
					{!!metricsRefreshUpdateAt && (
						<Tooltip
							title={t('creator_profile.social_stats.last_updated', {
								date: convertUtcToLocalTimeWithFormat(
									metricsRefreshUpdateAt,
									TYPE_FORMAT_DATE.TIME_TO_SECONDS_12_HOUR,
								),
							})}>
							<StyledText
								className="italic overflow"
								fontSize="12px !important"
								whiteSpace="nowrap">
								{t('creator_profile.social_stats.last_updated', {
									date: convertUtcToLocalTimeWithFormat(
										metricsRefreshUpdateAt,
										TYPE_FORMAT_DATE.TIME_TO_SECONDS_12_HOUR,
									),
								})}
							</StyledText>
						</Tooltip>
					)}
					{!refreshMetricsEnabled && (
						<Popover
							overlayClassName="overlay_dark_blue3"
							content={renderContent}
							overlayInnerStyle={{
								width: ambassadorMetricsRefreshInProgress ? '250px' : '400px',
							}}
							placement={placement}
							trigger={['click']}>
							{elm}
						</Popover>
					)}
					{refreshMetricsEnabled && elm}
				</StyledRefreshingMetrics>
			</StyledFlex>
		);
	};

	return <>{renderTextRefresh()}</>;
};

export default RefreshMetrics;
