import THEME from '@constants/themes/themes';
import Wrapper from '@cores/Wrapper';
import { Button, Col, Form, Input, Menu, Radio } from 'antd';
import styled from 'styled-components';

const StyledCol = styled(Col)``;

const StyledWrapper = styled(({ ...props }) => <Wrapper {...props} />)<{
	bgColor?: string;
}>`
	height: calc(100vh - 82px);
	background-color: ${({ bgColor }) => bgColor || THEME.colors.darkBlue};
`;

const StyledDiv = styled.div<{
	marginBot?: string;
}>`
	margin-bottom: ${({ marginBot }) => marginBot || 0}px;
	&.direct-message-title {
		margin-bottom: 12px;
		font-family: ${THEME.fonts.primary};
		font-size: ${THEME.fontSizes.normal};
		font-weight: ${THEME.fontWeight.bold};
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.white};
	}
	&.direct-message-box-create {
		padding: 16px;
		background-color: ${THEME.colors.darkBlue};
	}
	&.direct-message-box-menu {
		overflow: hidden auto;
		height: calc(100vh - 295px);
		margin: 0 -16px;
	}
	&.direct-message-box-list-template {
		height: calc(100vh - 218px);
		padding: 16px;
		background-color: ${THEME.colors.darkBlue};
	}
	&.direct-message-view-template-title {
		height: 32px;
		width: 100%;
		padding: 5px 16px;
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: ${THEME.colors.darkBlue2};
	}
	&.direct-message-view-template-body {
		background-color: ${THEME.colors.white};
		color: black;
		height: calc(100vh - 70px);
		overflow-x: scroll;
		padding: 1rem;
	}
	&.direct-message-view-template-body-box {
		position: relative;
		display: flex;
		flex-flow: row;
		align-items: center;
		padding: 24px 18px 24px 0;
		& svg {
			margin-left: 50px;
		}
	}
	&.direct-message-view-template-body-box-icon {
		line-height: 0;
	}
	&.line {
		position: absolute;
		width: calc(100% - 16px);
		left: 8px;
		border: 1px solid ${THEME.colors.gray5};
	}
`;

const StyledButton = styled(Button)`
	background: transparent;
	&.direct-message-button-add svg {
		fill: ${THEME.colors.primary} !important;
	}
	&.direct-message-button-add:hover {
		background: transparent;
		& svg {
			fill: ${THEME.colors.primary}!important;
		}
	}
`;

const StyledInput = styled(Input)`
	&.direct-message-input-create {
		border-color: ${THEME.colors.darkBlue3};
		&:focus {
			border-color: ${THEME.colors.primary};
		}
	}
`;

const StyledForm = styled(Form)`
	&.direct-message-form-create {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		align-items: center;
	}
`;

const StyledFormItem = styled(Form.Item)`
	&.direct-message-formItem-input-create {
		width: 100%;
		padding-right: 8px;
	}
`;

const StyledMenu = styled(Menu)``;

const StyledMenuItem = styled(Menu.Item)`
	&.direct-message-menu-item {
		height: max-content;
		padding: 16px !important;
		display: flex;
		flex-flow: row;
		align-items: center;
		border-bottom: 1px solid ${THEME.colors.darkBlue3};
		& .direct-message-menu-item-svg {
			display: flex;
			order: 2;
			& svg {
				width: 20px;
				height: 20px;
				fill: ${THEME.colors.gray1} !important;
			}
		}
		& .ant-menu-title-content {
			order: 1;
		}
		&.ant-menu-item-selected {
			& .direct-message-menu-item-svg svg {
				fill: ${THEME.colors.gray5} !important;
			}
		}
	}
`;

const StyledText = styled.div`
	font-family: ${THEME.fonts.primary};
	font-size: ${THEME.fontSizes.normal};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: normal;
	&.direct-message-menu-item-title {
		font-weight: ${THEME.fontWeight.bold};
		color: ${THEME.colors.white};
	}
	&.direct-message-menu-item-content {
		width: 95%;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;

		word-break: break-word;
		white-space: initial;
		overflow-wrap: break-word;

		color: ${THEME.colors.gray5};
	}
	&.direct-message-menu-item-updated {
		color: ${THEME.colors.gray1};
		font-size: 0.857rem;
		line-height: 1.67;
	}
	&.direct-message-view-template-body-box-title {
		font-weight: ${THEME.fontWeight.bold};
		line-height: 1.25;
		font-size: 1.143rem;
		color: ${THEME.colors.darkBlue2};
	}
`;

const StyledContainerMessage = styled.div`
	height: 100%;
`;

const StyledRadio = styled(Radio.Group)`
	padding: 0 4px 0 0;

	p {
		margin: 0;
		font-size: 0.857rem;
	}

	.ant-radio-wrapper {
		width: 100%;
		margin: 0 0 6px 0;
		padding: 16px;
		border-radius: 5px;
		background-color: ${THEME.colors.darkBlue3};

		&.ant-radio-wrapper-checked {
			border: 1px solid ${THEME.colors.orangeBase};
		}
	}

	.ant-radio-inner,
	.ant-radio-button-wrapper {
		background-color: ${THEME.colors.darkBlue3};
	}
`;

const StyledMessageTemplates = styled.div``;

const StyledPreviewMessage = styled.div`
	position: absolute;
	width: 100%;
	margin: 0 auto;
	transform: translateZ(0);
	padding: 0;
	border-radius: 2px;
	overflow: auto;
	bottom: 16px;
	left: 0;
	overflow: hidden;

	.icon-send {
		margin-left: 8px;
		width: 18px;
		fill: ${THEME.colors.orangeBase};
	}

	.btn-custom {
		position: fixed;
		right: 50px;
		bottom: 24px;
	}
`;

const StyledCustomizedTextArea = styled(Input.TextArea)`
	max-height: 210px;
	min-height: 210px !important;
	padding: 50px 24px 50px 10px;
	font-size: 0.857rem;
	overflow: auto;
	border: 1px solid ${THEME.colors.darkBlue4};
`;

const StyledTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 16px 16px 0;

	.icon-close {
		cursor: pointer;
		width: 18px;
	}
`;

const StyledSend = styled.div`
	width: 95%;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
	position: absolute;
	right: 12px;
	bottom: 1px;
	padding: 10px 5px;
	align-items: center;
	background: ${THEME.colors.darkBlue2};
`;

const StyledUploaderLink = styled.div`
	width: 95%;
	position: absolute;
	left: 35%;
	transform: translateX(-35%);
	top: 1px;
	background: ${THEME.colors.darkBlue2};
`;

const StyledTextDM = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-all;
	white-space: pre-line;
`;

export {
	StyledCol,
	StyledWrapper,
	StyledDiv,
	StyledButton,
	StyledInput,
	StyledForm,
	StyledFormItem,
	StyledMenuItem,
	StyledMenu,
	StyledText,
	StyledTextDM,
	StyledUploaderLink,
	StyledSend,
	StyledTitleContainer,
	StyledPreviewMessage,
	StyledMessageTemplates,
	StyledContainerMessage,
	StyledRadio,
	StyledCustomizedTextArea,
};
