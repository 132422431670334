import ContentGlobal from '@components/ContentGlobal';
import { CONTENT_FOLDER } from '@constants/content/contentLibrary';
import { FAILED, SUCCEEDED } from '@constants/status';
import { getContentUnviewedStatusRecentlyAddedEnd } from '@stores/actions';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Submissions = () => {
	const dispatch = useDispatch();

	const refContent = useRef<any>({});

	const { unviewedStatus, contentIdsUnviewedContent = [] } = useSelector(
		(state: any) => state.content,
	);

	useEffect(() => {
		if (unviewedStatus === SUCCEEDED && contentIdsUnviewedContent.length > 0) {
			handleMessage();
		}
		if (unviewedStatus === SUCCEEDED || unviewedStatus === FAILED) {
			dispatch(getContentUnviewedStatusRecentlyAddedEnd());
		}
	}, [unviewedStatus]);

	const handleMessage = () => {
		if (refContent?.current?.handleMessageContent) {
			refContent?.current?.handleMessageContent();
		}
	};

	return <ContentGlobal ref={refContent} namePage={CONTENT_FOLDER.SUBMISSIONS} />;
};

export default Submissions;
