import { Button, Dropdown, Menu, Rate, Tooltip, message } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Helper
import { handleDownloadExcel } from '@helpers/base64Helper';
import { hanldeRoundAvgStar } from '@helpers/formatAvgRatingStar';

// Constants
import { CREATOR_API_URL } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';

// API
import { getRequest } from '@helpers/requestHelpers';

// Icon
import {
	IconAdd,
	IconAddCircle,
	IconCampaign,
	IconCheck,
	IconDownload,
	IconEdit,
	IconEmail,
	IconGroupAdd,
	IconLock,
	IconLockOpen,
	IconMoreVert,
	IconRemoveCircle,
	IconRewardDiscount,
	IconSchedule,
	IconSend,
} from '@assets/icons';
import profilePicture from '@assets/images/default-profile-picture.png';
import THEME from '@constants/themes/themes';

// Components
import CreatorDetailModal from '@components/Creators/CreatorDetailModal/CreatorDetailModal';
import { ConfirmModal } from '@cores/Modal';

// Types
import { ProfileActionsDto } from '@models/creator/profile';

// Styled
import { DURATION, KEY_MESSAGE, ROUTE_PATH, STATUS_FIELD } from '@constants/common';
import { CREATOR_PROFILE_MODAL_TYPE, ID_ELM_CREATOR } from '@constants/creator';
import { STATE_TYPE } from '@constants/messages';
import { ROLE } from '@constants/settings';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import CreatorProfileContext from '@contexts/Creators/Profile';
import LoadingWrapper from '@cores/LoadingWrapper';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { getUser } from '@helpers/userHelpers';
import { RecentSocialPostPayloadDto, SocialContentStoreDto } from '@models/socialsearch/store';
import { creatorBlockEnd, creatorChangeStatus } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	fetchRecentSocialPostEnd,
	fetchRecentSocialPostRequest,
} from '@stores/socialsearch/socialsearch.actions';
import {
	StyledBtnGray,
	StyledIcon,
	StyledTitle as StyledTitleCommon,
	StyledWrapperAction,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import {
	StyledGeneralInfoBlock,
	StyledInfoBlock,
	StyledInfoCol,
	StyledProfilePicture,
	StyledTitle,
	StyledWrapperProfile,
	StyledWrapperStatus,
} from '@styled/Creators/MetadataStyled';
import history from '@utils/history';
import { renderIconCreatorStatus } from '@utils/renderComponent';
import AddToSegmentModal from '../Summary/AddToSegmentModal';
import { SocialUsernamesDto } from '@models/common/summary';

type MetadataProps = {
	onChangeBlockStatus: (requestData: any) => void;
};

const Metadata = ({ onChangeBlockStatus }: MetadataProps) => {
	const { t } = useTranslation();

	const user = getUser();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isBlockedWithoutDeleting, setIsBlockedWithoutDeleting] = useState<boolean>(false);

	const [visibleEditCreatorModal, setVisibleEditCreatorModal] = useState<boolean>(false);
	const [visibleSegment, setVisibleSegment] = useState<boolean>(false);
	const [confirmationModalType, setConfirmationModalType] = useState<string>();

	const CONFIRMATION_MODAL_TYPE = {
		GET_RECENT_POST: 'GET_RECENT_POST',
		BLOCK_UNBLOCK_CREATOR: 'BLOCK_UNBLOCK_CREATOR',
	};

	const {
		isBlocking,
		creatorProfile,
		creatorBlockStatus,
		fetchingCreatorProfile,
	}: UserStoreType = useSelector((state: any) => state.creator);

	const { fetchRecentSocialPostStatus, recentSocialPostResult }: SocialContentStoreDto =
		useSelector((state: any) => state.socialContent);

	const {
		setModalType,
		modalType,
		setFocusHistory,
		setCollapseMessage,
		setMailHistory,
		messageHistoryCreator,
	} = React.useContext(CreatorProfileContext);

	const {
		id = 0,
		firstName = '',
		lastName = '',
		email = '',
		address = '',
		zipCode = '',
		phone = '',
		social: {
			instagramUsername = '',
			twitterUsername = '',
			youtubeUsername = '',
			tiktokUsername = '',
			facebookProfileUrl = '',
		} = {},
		statistic: { avgStar = 1 } = {},
		status: {
			active = true,
			unsubscribed = false,
			followed = false,
			blocked = false,
			new: newRecently = false,
		} = {},
	} = creatorProfile || {};

	const socialUsernames: SocialUsernamesDto = {
		instagram: instagramUsername,
		youtube: youtubeUsername,
		twitter: twitterUsername,
		tiktok: tiktokUsername,
	};

	const editModalData = {
		firstName,
		lastName,
		email,
		instagramUsername,
		twitterUsername,
		youtubeUsername,
		tiktokUsername,
		address,
		zipCode,
		facebookUrl: facebookProfileUrl,
		phone,
		clientId: id,
	};

	useEffect(() => {
		if (!isBlocking) {
			closeModalAfterCalledCreatorBlock(isBlocking);
		}
	}, [isBlocking]);

	useEffect(() => {
		if (creatorBlockStatus === SUCCEEDED || creatorBlockStatus === FAILED) {
			dispatch(creatorBlockEnd());
		}
	}, [creatorBlockStatus]);

	useEffect(() => {
		if (fetchRecentSocialPostStatus === SUCCEEDED && recentSocialPostResult) {
			message.success(recentSocialPostResult);
			onClose();
		}
		if (fetchRecentSocialPostStatus === SUCCEEDED || fetchRecentSocialPostStatus === FAILED) {
			dispatch(fetchRecentSocialPostEnd());
		}
	}, [fetchRecentSocialPostStatus, recentSocialPostResult]);

	const closeModalAfterCalledCreatorBlock = (isBlocking: boolean) => {
		if (!!confirmationModalType && !isBlocking) {
			onClose();
		}
	};

	const handleActionEdit = () => {
		setVisibleEditCreatorModal(true);
	};

	const handleCloseModalEditCreator = () => {
		setVisibleEditCreatorModal(false);
	};

	const handleActionExportData = () => {
		const webUrl = window.location.href;
		const url = `${CREATOR_API_URL}/export-creator-details?webUrl=${webUrl}`;

		const params = {
			id,
		};

		getRequest(url, params)
			.then((response) => {
				if (response.status === 200) {
					const {
						uploadAWS = false,
						message: messageExport = '',
						base64,
					} = response.body.data?.result;
					if (uploadAWS) {
						message.success(messageExport);
						return;
					}
					const nameFile = `${email}_EnTribe Creator Data_`;
					handleDownloadExcel(base64, '', nameFile);
					message.success(t('creator_profile.info.export_success'));
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
			})
			.catch((err) => {
				message.error(DEFAULT_ERROR);
			});
	};

	const handleChangeStatus = (status: string, value: boolean) => {
		const payload: any = {
			creatorStatus: STATUS_FIELD.IS_FOLLOWED,
			creatorStatusValue: value,
			isCalledFromProfile: true,
			requestBody: {
				creatorIds: [id],
			},
		};
		if (status === STATUS_FIELD.IS_FOLLOWED) {
			payload.noWaitFollow = true;
			const objMessage = {
				content: t('creator_profile.message.following'),
				key: KEY_MESSAGE.CREATOR_FOLLOW,
			};
			let keyDestroy = KEY_MESSAGE.CREATOR_UN_FOLLOW;
			if (!value) {
				objMessage.content = t('creator_profile.message.stop_following');
				objMessage.key = KEY_MESSAGE.CREATOR_UN_FOLLOW;
				keyDestroy = KEY_MESSAGE.CREATOR_FOLLOW;
			}

			message.destroy(keyDestroy);
			message.info({ ...objMessage, duration: DURATION.S_6 });
		}

		dispatch(creatorChangeStatus(payload));
	};

	const handleChangeBlockStatus = (isBlock: boolean, isDelete: boolean) => {
		const payload = {
			isBlock,
			isDelete,
			isCalledFromProfile: true,
			requestBody: {
				ids: [id],
				listSocialUsername: [],
			},
		};

		setIsBlockedWithoutDeleting(!isDelete);
		setIsLoading(isBlock ? isDelete : true);
		onChangeBlockStatus(payload);
	};

	const onConfirmBlock = (isBlock: boolean, isDelete?: boolean) => {
		const isDeleteTemp = isDelete !== undefined && !isDelete ? false : true;
		handleChangeBlockStatus(isBlock, isDeleteTemp);
	};
	const onConfirmUnblock = () => {
		handleChangeBlockStatus(false, false);
	};

	const onClose = () => {
		setIsLoading(false);
		setConfirmationModalType('');
		setIsBlockedWithoutDeleting(false);
	};

	const handleViewEmail = () => {
		setFocusHistory(true);
		setCollapseMessage(true);

		if (messageHistoryCreator?.length > 0) {
			setModalType(CREATOR_PROFILE_MODAL_TYPE.VIEW_MESSAGE_HISTORY_DETAIL);
			setMailHistory(messageHistoryCreator[0]);
		}
		const elmScroll = document.getElementById(ID_ELM_CREATOR.ID_CREATOR_MESSAGE_HISTORY);
		const elmFirstChild = document.getElementById(ID_ELM_CREATOR.ID_FIRST_HISTORY);

		if (elmScroll) {
			elmScroll.scrollIntoView();
			if (elmFirstChild) {
				elmFirstChild.scrollIntoView();
			}
		}
	};

	const fetchRecentSocialPost = () => {
		const payload: RecentSocialPostPayloadDto = [];
		Object.keys(socialUsernames).forEach((key: string) => {
			if (socialUsernames[key]) {
				payload.push({
					source: key.toUpperCase(),
					username: socialUsernames[key],
				});
			}
		});
		dispatch(fetchRecentSocialPostRequest(payload));
	};

	const renderConfirmModal = () => {
		const isBlocked = !blocked;
		const isOpen = !!confirmationModalType;
		let content = '';
		let okText = t('button.get_posts');
		let onSubmit = () => {};
		let onOtherConfirm = () => {};
		const isOkLoading = isLoading || fetchRecentSocialPostStatus === IN_PROGRESS;

		switch (confirmationModalType) {
			case CONFIRMATION_MODAL_TYPE.GET_RECENT_POST: {
				content = t('creator_profile.confirm.get_recent_social_post');
				onSubmit = () => fetchRecentSocialPost();
				break;
			}
			case CONFIRMATION_MODAL_TYPE.BLOCK_UNBLOCK_CREATOR: {
				okText = t('button.yes');
				content = isBlocked
					? t('creator_profile.confirm.block')
					: t('creator_profile.confirm.unblock');
				onSubmit = isBlocked ? () => onConfirmBlock(isBlocked) : onConfirmUnblock;
				onOtherConfirm = () => onConfirmBlock(true, false);
				break;
			}
			default:
				break;
		}

		return (
			<ConfirmModal
				width={
					confirmationModalType === CONFIRMATION_MODAL_TYPE.BLOCK_UNBLOCK_CREATOR &&
					isBlocked
						? 500
						: 400
				}
				confirmLoading={isOkLoading}
				confirmOtherLoading={isBlockedWithoutDeleting}
				okText={okText}
				isOpen={isOpen}
				otherConfirmText={
					isBlocked &&
					confirmationModalType === CONFIRMATION_MODAL_TYPE.BLOCK_UNBLOCK_CREATOR
						? t('button.block_not_delete')
						: ''
				}
				onSubmit={onSubmit}
				onOtherConfirm={onOtherConfirm}
				onClose={onClose}>
				{content}
			</ConfirmModal>
		);
	};

	const handleOpenMessage = () => {
		history.push({
			pathname: ROUTE_PATH.EMAIL_BUILDER,
			state: {
				type: STATE_TYPE.MESSAGE_FROM_CREATOR_PROFILE,
				creatorList: [creatorProfile],
				isBackPageBefore: true,
			},
		});
	};

	/**
	 * Render Menu
	 */
	const actionsObj: ProfileActionsDto[] = [
		{
			icon: <IconCampaign />,
			name: 'button.view_campaigns',
			click: () => {
				setModalType(CREATOR_PROFILE_MODAL_TYPE.CAMPAIGN_RECOMMENDATIONS);
			},
			hidden: !creatorProfile?.recommendation,
		},
		{
			icon: <IconEmail />,
			name: 'button.view_emails',
			click: handleViewEmail,
		},
		{
			icon: <IconRewardDiscount />,
			name: 'button.manage_rewards',
			click: () => {
				setModalType(CREATOR_PROFILE_MODAL_TYPE.MANAGE_REWARDS);
			},
		},
		{ icon: <IconEdit />, name: 'button.edit', click: handleActionEdit },
		{
			icon: followed ? <IconRemoveCircle /> : <IconAddCircle />,
			name: `${followed ? 'button.unfollow' : 'button.follow'}`,
			click: () => handleChangeStatus(STATUS_FIELD.IS_FOLLOWED, !followed),
		},
		{
			icon: blocked ? <IconLockOpen /> : <IconLock />,
			name: `${blocked ? 'button.unblock' : 'button.block'}`,
			click: () => {
				setConfirmationModalType(CONFIRMATION_MODAL_TYPE.BLOCK_UNBLOCK_CREATOR);
			},
		},
		{
			icon: <IconDownload />,
			name: 'button.export_data',
			click: handleActionExportData,
			hidden: user?.role !== ROLE.EREVIEWER.value,
		},
		{
			icon: <IconSend />,
			name: 'button.send_email',
			click: handleOpenMessage,
		},
		{
			icon: <IconGroupAdd />,
			name: 'button.add_to_segments',
			click: () => setVisibleSegment(true),
		},
		{
			icon: <IconSchedule />,
			name: 'button.get_recent_social_post',
			click: () => {
				setConfirmationModalType(CONFIRMATION_MODAL_TYPE.GET_RECENT_POST);
			},
			hidden: user?.role !== ROLE.EREVIEWER.value,
			disabled: Object.values(socialUsernames).every(
				(username) => username === null || username === undefined || username === '',
			),
		},
	];

	const menuActions = (
		<Menu>
			{actionsObj.map(
				(item: ProfileActionsDto) =>
					!item?.hidden && (
						<Menu.Item key={item.name} onClick={item.click} disabled={item.disabled}>
							{item.icon}
							<span>{t(item.name)}</span>
						</Menu.Item>
					),
			)}
		</Menu>
	);

	const handleViewDetails = () => {
		let type: string = CREATOR_PROFILE_MODAL_TYPE.VIEW_DETAILS;
		if (modalType === CREATOR_PROFILE_MODAL_TYPE.VIEW_DETAILS) {
			type = '';
		}
		setModalType(type);
	};

	const renderIndicator = () => {
		const itemIcon = renderIconCreatorStatus(
			{
				active,
				blocked,
				followed,
				newRecently,
				unsubscribed,
			},
			t,
		);
		return (
			<StyledWrapperStatus>
				<Tooltip title={itemIcon?.title}>
					<StyledIcon size={36}>{itemIcon?.icon}</StyledIcon>
				</Tooltip>
			</StyledWrapperStatus>
		);
	};

	return (
		<StyledInfoBlock className="creator-info-content">
			<LoadingWrapper
				isLoading={fetchingCreatorProfile}
				sizeLoading="medium"
				bgColorLoading="transparent">
				<StyledInfoCol>
					<StyledWrapperProfile>
						<StyledProfilePicture src={profilePicture} alt="" />
						{renderIndicator()}
					</StyledWrapperProfile>
					<StyledGeneralInfoBlock style={{ width: 'calc(100% - 120px)' }}>
						<StyledWrapperTitle>
							<Rate disabled allowHalf defaultValue={hanldeRoundAvgStar(avgStar)} />
						</StyledWrapperTitle>

						<StyledTitle className="text_overflow" level={3}>
							{firstName} {lastName}
							<StyledTitleCommon
								className="unset_height"
								display="inline"
								fontSize="12px"
								lineHeight="18px"
								margin="0 0 0 10px"
								position="relative"
								style={{ top: '-3px' }}
								color={THEME.colors.gray3}>
								{t('creator_profile.info.added_time', {
									time: creatorProfile?.createAt
										? convertUtcToLocalTimeWithFormat(creatorProfile.createAt)
										: '',
								})}
							</StyledTitleCommon>
						</StyledTitle>
						<StyledWrapperAction style={{ marginTop: '12px' }}>
							{followed ? (
								<StyledBtnGray
									icon={<IconCheck />}
									onClick={() =>
										handleChangeStatus(STATUS_FIELD.IS_FOLLOWED, false)
									}>
									{t('creator_profile.info.following')}
								</StyledBtnGray>
							) : (
								<Button
									icon={<IconAdd />}
									onClick={() =>
										handleChangeStatus(STATUS_FIELD.IS_FOLLOWED, true)
									}>
									{t('button.follow')}
								</Button>
							)}
							<Button style={{ marginLeft: '8px' }} onClick={handleViewDetails}>
								{t('creator_profile.view_details')}
							</Button>

							<Dropdown
								overlay={menuActions}
								placement="bottomRight"
								trigger={['click']}>
								<Button style={{ marginLeft: '8px' }} icon={<IconMoreVert />} />
							</Dropdown>
						</StyledWrapperAction>
					</StyledGeneralInfoBlock>
				</StyledInfoCol>

				{/* Modal  */}
				<CreatorDetailModal
					visible={visibleEditCreatorModal}
					onClose={handleCloseModalEditCreator}
					modalType="edit"
					creatorData={editModalData}
					callback="fetchCreatorDetails"
					isFetching={false}
				/>
				<AddToSegmentModal
					visible={visibleSegment}
					onClose={() => setVisibleSegment(false)}
					selectedCreator={[creatorProfile?.id]}
					isSelectAll={false}
				/>
				{renderConfirmModal()}
			</LoadingWrapper>
		</StyledInfoBlock>
	);
};

export default React.memo(Metadata);
