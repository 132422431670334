import { IconClose, IconSend } from '@assets/icons';
import THEME from '@constants/themes/themes';
import LoadingWrapper from '@cores/LoadingWrapper';
import { getDirectMessageEnd } from '@stores/actions';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledContainer } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	StyledBtn,
	StyledBtnGhost,
	StyledSection,
	StyledTitle,
} from '@styled/Content/ContentLibrary/ContentStyled';
import { Radio, RadioChangeEvent, Space } from 'antd';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledContainerMessage = styled.div`
	box-shadow: 0 -10px 30px 20px rgba(0, 0, 0, 0.25);
	height: 95%;
`;

const StyledRadio = styled(Radio.Group)`
	padding: 0 4px 0 16px;

	p {
		margin: 0;
		font-size: 0.857rem;
	}

	.ant-radio-wrapper {
		width: 100%;
		margin: 0 0 6px 0;
		padding: 16px;
		border-radius: 5px;
		background-color: ${THEME.colors.darkBlue3};

		&.ant-radio-wrapper-checked {
			border: 1px solid ${THEME.colors.orangeBase};
		}
	}

	.ant-radio-inner,
	.ant-radio-button-wrapper {
		background-color: ${THEME.colors.darkBlue3};
	}
`;

const StyledMessageTemplates = styled.div``;

const StyledTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;

	.icon-close {
		cursor: pointer;
		width: 18px;
	}
`;

type MessageTemplatesProps = {
	handleDirectMessage: () => void;
	openTemplateBuilder?: (template: any) => void;
	currentContent?: any;
};

const MessageTemplates = (props: MessageTemplatesProps) => {
	const { handleDirectMessage, openTemplateBuilder, currentContent } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { templates } = useSelector((state: any) => state.messages);

	const [currentDirectMessage, setCurrentDirectMessage] = useState<any>({});

	const isDisabledEmail = useMemo(() => {
		return currentContent && !currentContent?.creatorProfile?.email;
	}, [currentContent]);

	const isDisabledDM = useMemo(() => {
		return currentContent && !currentContent?.social;
	}, [currentContent]);

	useEffect(() => {
		if (!isDisabledEmail && isDisabledDM) {
			setCurrentDirectMessage(templates.length ? templates[0] : {});
		}

		return () => {
			dispatch(getDirectMessageEnd());
		};
	}, [currentContent]);

	const onChange = (evt: RadioChangeEvent) => {
		const { value } = evt.target;
		const messageIndex = templates.findIndex((item: any) => item.id === value);
		setCurrentDirectMessage(templates[messageIndex]);
	};

	const openTemplate = (isDraft?: boolean) => {
		if (openTemplateBuilder) {
			if (!_.isUndefined(isDraft) && isDraft) {
				openTemplateBuilder({});
				return;
			}

			openTemplateBuilder(currentDirectMessage);
		}
	};

	return (
		<LoadingWrapper heightLoading="calc(100% - 54px)" isLoading={false} sizeLoading="medium">
			<StyledContainerMessage style={{ height: '100%' }}>
				<StyledTitleContainer>
					<StyledTitle fontSize="1.143rem" fontWeight="600">
						{t('message_templates.email_template')}
					</StyledTitle>
					<IconClose className="icon-close" onClick={handleDirectMessage} />
				</StyledTitleContainer>

				<StyledMessageTemplates style={{ height: '100%' }}>
					<StyledWrapperContent
						className="custom_scroll_bar"
						height="calc(100% - 160px)"
						overflow="hidden auto"
						margin="0 0 8px 0"
						padding="0">
						{!_.isEmpty(currentDirectMessage) ? (
							<StyledRadio value={currentDirectMessage.id} onChange={onChange}>
								<Space direction="vertical">
									{templates.map((item: any) => (
										<Radio key={item.id} value={item.id}>
											<p>{item.title}</p>
											<p>{item.description}</p>
										</Radio>
									))}
								</Space>
							</StyledRadio>
						) : null}
					</StyledWrapperContent>
					<StyledSection
						width="100%"
						className="custom-section"
						bottomCustom="32px"
						padding="16px"
						borderPosition="top"
						heightSection="64px"
						flexDirection="row">
						<StyledBtn width="160px" type="primary" onClick={() => openTemplate()}>
							{t('message_templates.open_template')}
						</StyledBtn>

						<StyledSection padding="0" flexDirection="row">
							<StyledBtnGhost
								width="160px"
								type="ghost"
								icon={<IconSend />}
								onClick={() => openTemplate(true)}>
								{t('message_templates.draft')}
							</StyledBtnGhost>
						</StyledSection>
					</StyledSection>
				</StyledMessageTemplates>
			</StyledContainerMessage>
		</LoadingWrapper>
	);
};

export default MessageTemplates;
