import { SIZE_CTA } from '@constants/campaign';
import THEME from '@constants/themes/themes';
import { StyledFormItem } from '@styled/Common/CommonStyled';
import { Collapse, Modal, Upload } from 'antd';
import styled from 'styled-components';

const { Dragger } = Upload;

const StyledWrapperContainer = styled.div`
	background-color: ${THEME.colors.darkBlue2};
	border-radius: 2px;
`;

const StyledWrapperHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	font-size: 20px;
	line-height: 27px;
	color: ${THEME.colors.white};
	height: 46px;
`;

const StyledWrapperContent = styled.div<{ sliceHeight?: number }>`
	padding: 16px;
	height: ${({ sliceHeight }) => `calc(100vh - ${sliceHeight || 200}px)`};
	overflow-y: auto;
`;

const StyledWrapperAction = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	padding: 8px 16px;
`;

const StyledContent = styled.div``;

const StyledSubLimit = styled.div<{ margin?: string }>`
	text-align: right;
	font-size: 10px;
	line-height: 20px;
	color: ${THEME.colors.gray5};
	${({ margin }) => margin && `margin: ${margin}`};
`;

const StyledListUpload = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const StyledDraggerImg = styled(Dragger)<{ margin?: string }>`
	&.ant-upload {
		&.ant-upload-drag {
			width: 80px;
			height: 80px;
			position: relative;
			background: rgba(32, 50, 65, 0.5);
			border: 2px dashed ${THEME.colors.gray1};
			border-radius: 2px;
			margin: ${({ margin }) => margin || '0 16px 16px 0'};

			.ant-upload {
				padding: 0;
				width: 100%;
				height: 100%;
				.ant-upload-drag-container {
				}
			}
		}
	}

	&.ant-upload-picture-card-wrapper {
		width: auto;
	}
`;

const StyledWrapperChildUpload = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

const StyledImgUpload = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const StyledIconAdd = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const StyledUploadChild = styled.div`
	height: 76px;
	width: 76px;
	position: relative;
	overflow: hidden;
`;

const StyledIconRemoveUpload = styled.div`
	position: absolute;
	right: -12px;
	top: -12px;
	z-index: 21;
	cursor: pointer;
`;

const StyledLabelContent = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: ${THEME.colors.gray5};
	margin-bottom: 16px;
`;

const StyledSuggestImg = styled.div`
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		.text {
			color: ${THEME.colors.orangeBase};
		}
	}
`;

const StyledTextLink = styled.div<{ margin?: string }>`
	display: inline;
	font-size: 14px;
	text-decoration: underline;
	color: ${THEME.colors.gray5};
	${({ margin }) => margin && `margin: ${margin}`};
	&:hover {
		color: ${THEME.colors.orangeBase};
	}
`;

const StyledContainerMockup = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	.picture_1 {
		position: absolute;
		z-index: -1;
		visibility: hidden;
		transform: scale(${SIZE_CTA.REQUIRED_WIDTH / SIZE_CTA.WIDTH});
	}
`;

const StyledWrapperContainerMockup = styled.div`
	border-radius: 4px;
	background-color: ${THEME.colors.gray4};
	padding: 16px 24px;
	max-width: ${SIZE_CTA.WIDTH}px;
	width: ${SIZE_CTA.WIDTH}px;
	height: ${SIZE_CTA.HEIGHT}px;
	position: relative;
	overflow: hidden;
	font-family: 'Ropa Sans', sans-serif;
`;

const StyledTitleMockup = styled.div`
	font-size: 32px;
	font-weight: 400;
	line-height: 35px;
	color: ${THEME.colors.black};
	word-break: break-word;
	text-align: center;
	width: 100%;
	height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	&.title_share {
	}
`;

const StyledWrapperBox = styled.div<{ margin?: string }>`
	${({ margin }) => margin && `margin: ${margin}`};
	position: relative;
	&.share {
		width: 100%;
		height: 250px;
	}
	&.post {
		width: 100%;
		height: 240px;
		margin: 12px 0;
	}
`;

const StyledFrameLarge = styled.div`
	width: 182px;
	height: 215px;
	padding: 10px;
	position: absolute;
	border-radius: 1px;
	background-color: ${THEME.colors.white};

	&.img_1 {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 22;
	}
	&.img_2 {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-12.98deg);
		z-index: 19;
	}
`;

const StyledFrameSmall = styled.div`
	width: 121px;
	height: 143px;
	padding: 6px;
	position: absolute;
	border-radius: 1px;
	background-color: ${THEME.colors.white};

	&.img_1 {
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0) rotate(-2.64deg);
		z-index: 22;
	}
	&.img_2 {
		top: 50%;
		right: 0;
		transform: translate(0, -50%) rotate(15deg);
		z-index: 21;
	}
	&.img_3 {
		top: 0;
		left: 0;
		transform: rotate(-11.72deg);
		z-index: 20;
	}
`;

const StyledImgMockup = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const StyledWrapperImgMockup = styled.div`
	width: 100%;
	height: 80%;
	background-color: ${THEME.colors.gray4};
`;

const StyledDescMockup = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: ${THEME.colors.black};
	word-break: break-word;
	text-align: center;
	height: 84px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledWrapperBtnStatic = styled.div`
	margin: 12px auto 0 auto;
	text-align: center;
`;

const StyledCustomizedFormItem = styled(StyledFormItem)`
	position: relative;
	margin: 16px 0 0 0;

	.ant-form-item-control-input {
		position: relative;

		&::before {
			content: 'unique creators';
			position: absolute;
			right: 26px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 12px;
			z-index: 9;
		}
	}

	.ant-form-item-label {
		label {
			color: ${THEME.colors.gray3};
		}
	}
`;

const StyledBtnStatic = styled.div`
	background-color: transparent;
	display: flex;
	align-items: center;
	background-color: ${THEME.colors.white};
	min-height: 32px;
	padding: 0px 12px 0px 6px;
	border-radius: 4px;
	color: ${THEME.colors.black};
	font-size: 18px;
	text-transform: uppercase;
	filter: drop-shadow(0px 1.01242px 2.02483px #344552);
`;

const StyledStaticBtnIcon = styled.div`
	width: max-content;
	max-width: 100%;
	margin: 0 auto;
	display: inline-flex;
	align-items: center;
`;

const StyledFooterStatic = styled.div`
	font-size: 16px;
	line-height: 17px;
	color: ${THEME.colors.black};
	text-align: center;
	margin-top: 6px;
	padding: 0 8px;
`;

const StyledWrapperFooterStatic = styled.div`
	position: absolute;
	bottom: 0;
	z-index: 30;
	width: 100%;
	background-color: ${THEME.colors.gray4};
	margin-left: -24px;
	padding-bottom: 12px;
`;

const StyledImageIcon = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const StyledCampaignOption = styled.div<{ margin?: string }>`
	padding-left: 20px;
	border-left: 3px solid ${THEME.colors.darkBlue3};
	${({ margin }) => margin && `margin: ${margin}`};
`;

const StyledFormItemCampaign = styled(StyledFormItem)<{ margin?: string }>`
	margin: ${({ margin }) => margin || '0 0 8px 0'};

	.ant-form-item-label {
		label {
			color: ${THEME.colors.gray3};
		}
	}
`;

const StyledPreviewEmailTemplateModal = styled(Modal)`
	width: 60% !important;

	.ant-modal-body {
		max-height: calc(100vh - 320px);
		min-height: 500px;
		height: fit-content;
		overflow: auto;
	}
`;

const StyledPreviewTemplateContainer = styled.div`
	min-height: 500px;
	background-color: #f9f8f5;
	color: ${THEME.colors.black};
	padding: 24px;
`;

const StyledCollapse = styled(Collapse)`
	padding: 0;

	.ant-collapse-item {
		border: none;

		.ant-collapse-header {
			padding-left: 0;
		}
	}

	.ant-collapse-content-box {
		padding: 0;
	}
`;

export {
	StyledBtnStatic,
	StyledContainerMockup,
	StyledContent,
	StyledCustomizedFormItem,
	StyledDescMockup,
	StyledDraggerImg,
	StyledFooterStatic,
	StyledFrameLarge,
	StyledFrameSmall,
	StyledIconAdd,
	StyledIconRemoveUpload,
	StyledImageIcon,
	StyledImgMockup,
	StyledImgUpload,
	StyledLabelContent,
	StyledListUpload,
	StyledStaticBtnIcon,
	StyledSubLimit,
	StyledSuggestImg,
	StyledTextLink,
	StyledTitleMockup,
	StyledUploadChild,
	StyledWrapperAction,
	StyledWrapperBox,
	StyledWrapperBtnStatic,
	StyledWrapperChildUpload,
	StyledWrapperContainer,
	StyledWrapperContainerMockup,
	StyledWrapperContent,
	StyledWrapperFooterStatic,
	StyledWrapperHeader,
	StyledWrapperImgMockup,
	StyledCampaignOption,
	StyledFormItemCampaign,
	StyledPreviewEmailTemplateModal,
	StyledPreviewTemplateContainer,
	StyledCollapse,
};
