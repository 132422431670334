import { ImageReward } from '@assets/images';
import THEME from '@constants/themes/themes';
import TremendousModalContext from '@contexts/Settings/SettingIntegrations/TremendousModal';
import { TremendousStoreDto } from '@models/settings/tremendous/store';
import { UserStoreType } from '@stores/creator/creator.types';
import { StyledTitle, StyledWrapperContent, StyledWrapperTitle } from '@styled/Common/CommonStyled';
import { abbreviateNumber, kFormatter, numberWithCommas } from '@utils/common';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StepTremendous3 = () => {
	const { t } = useTranslation();
	const { tremendousDefault }: TremendousStoreDto = useSelector((state: any) => state.tremendous);
	const { tremendousSettings, campaigns } = tremendousDefault || {};
	const { rewardAmount } = tremendousSettings || {};
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);
	const { email = '' } = creatorProfile || {};

	const { orderDetail, fundingSourceTotal } = useContext(TremendousModalContext);

	const campaignDetail = useMemo(() => {
		if (orderDetail?.campaignId) {
			const result = campaigns?.find((ca) => ca?.id === orderDetail.campaignId);
			if (result) {
				return result;
			}
			return null;
		}
		return null;
	}, [orderDetail?.campaignId]);

	return (
		<>
			<StyledWrapperTitle alignItems="unset">
				<StyledWrapperContent margin="0 16px 0 0" width="calc(100% - 251px)">
					<StyledTitle
						className="unset_height"
						fontSize="20px"
						color={THEME.colors.white}>
						{t('tremendous.order_summary')}
					</StyledTitle>
					<StyledTitle
						className="unset_height"
						fontSize="48px"
						fontWeight="700"
						lineHeight="65px"
						margin="12px 0"
						color={THEME.colors.white}>
						{t('tremendous.uint_usd')}
						{numberWithCommas(orderDetail?.rewardAmount || 0)}
					</StyledTitle>
					<StyledTitle wordBreak={'break-all'} className="unset_height">
						{t('tremendous.to_user', { val: email })}
					</StyledTitle>
				</StyledWrapperContent>
				<img
					style={{ width: '235px', height: '100%', objectFit: 'cover' }}
					src={ImageReward}
					alt="reward"
				/>
			</StyledWrapperTitle>
			<StyledTitle className="unset_height">
				{t('tremendous.campaign_template_value', {
					id: campaignDetail?.id || '',
					template: campaignDetail?.name || '',
				})}
			</StyledTitle>
			<StyledTitle margin="8px 0 0 0" className="unset_height">
				{t('tremendous.funds_balance', {
					val: numberWithCommas(fundingSourceTotal || 0, 2),
				})}
			</StyledTitle>
		</>
	);
};

export default StepTremendous3;
