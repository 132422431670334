import * as React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Skeleton, Select, Radio, RadioChangeEvent } from 'antd';

// Styled
import {
	StyledChartWrapper,
	StyledRadioGroup,
	StyledSelect,
	StyledTextXAxis,
	StyledTitle,
} from '@styled/Dashboard/DashboardStyled';

// Hook
import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import CreatorDashboardContext from '@contexts/Creators/Dashboard';
import { StyledRow } from '@styled/Common/CommonStyled';
import { ChartOptionDto } from '@models/dashboard';

const colors = {
	label: '#e0e3e5',
	fill: '#4d5b67',
	stroke: '#1890ff',
	bgFill: '#0A477D',
};

const { Option } = Select;

const CustomizeDot = (props: any) => {
	const { cx, cy } = props; // { cx, cy, stroke, index, dataKey }

	return (
		<svg
			x={cx - 6}
			y={cy - 6}
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="6" cy="6" r="6" fill="#fff" />
		</svg>
	);
};

const CustomizeTooltip = (props: any) => {
	const { active, payload = [], label } = props;

	if (active) {
		return (
			<div className="chart-tooltip">
				<span>{label}</span>
				<span>{payload && payload[0] ? payload[0]?.value : null}</span>
			</div>
		);
	}
	return null;
};

const CustomizedXAxisTick = (props: any) => {
	const { x, y, payload } = props; // { x, y, stroke, payload }
	const { value } = payload;
	return (
		<g transform={`translate(${x},${y})`}>
			<StyledTextXAxis
				x={0}
				y={0}
				dy={16}
				textAnchor="middle"
				fill="#e0e3e5"
				fontSize="0.857rem">
				{value}
			</StyledTextXAxis>
		</g>
	);
};

const CustomizedYAxisTick = (props: any) => {
	const { x, y, payload } = props; // { x, y, stroke, payload }

	return (
		<g transform={`translate(${x},${y})`}>
			<text x={-25} y={4} textAnchor="middle" fill="#e0e3e5" fontSize="0.857rem">
				{payload.value}
			</text>
		</g>
	);
};

const ChartByDate = () => {
	const { t } = useTranslation();
	const { isFetchingContentGraphData, contentGraphData, isFilterByCampaign } =
		useContext(CreatorDashboardContext);
	const graph = contentGraphData || {};

	const radioGroup = [
		{
			title: 'button.monthly',
			value: 'monthly',
		},
		{
			title: 'button.weekly',
			value: 'weekly',
		},
		{
			title: 'button.daily',
			value: 'daily',
		},
	];

	const [chartOption, setChartOption] = useState<ChartOptionDto>({
		timeType: 'monthly',
		dataType: 'allContents',
	});

	const select = [
		{
			id: 1,
			title: 'dashboard.chart.all_contents',
			value: 'allContents',
		},
		{
			id: 2,
			title: 'dashboard.chart.direct_upload',
			value: 'directUpload',
		},
		{
			id: 3,
			title: 'ambassador.ambassador_post',
			value: 'socialUpload',
		},
		{
			id: 4,
			title: 'dashboard.chart.bookmarks',
			value: 'socialSearch',
		},
	];

	useEffect((): any => {
		setChartOption({
			timeType: 'monthly',
			dataType: 'allContents',
		});

		return () => null;
	}, [contentGraphData]);

	const chartData = React.useMemo(() => {
		if (graph && Object.keys(graph).includes(chartOption.timeType)) {
			const newChartData = graph[chartOption.timeType].map((item: any) => {
				const name = item.date.includes('-') ? item.date.split('-').pop() : item.date;

				return {
					name,
					uv: item[chartOption.dataType] || 0,
				};
			});

			return newChartData;
		}

		return [];
	}, [graph, chartOption]);

	const onChangeSelect = (value: any) => {
		setChartOption({ ...chartOption, dataType: value });
	};

	const handleChartOption = (value: RadioChangeEvent) => {
		setChartOption({ ...chartOption, timeType: value.target.value });
	};

	const renderMenuSort = () => {
		return (
			<StyledRow style={{ flexFlow: 'unset' }}>
				<StyledSelect
					isFilterByCampaign={isFilterByCampaign}
					onChange={onChangeSelect}
					value={chartOption.dataType}>
					{select.map((item: any) => (
						<Option value={item.value} key={item.id}>
							{t(`${item.title}`)}
						</Option>
					))}
				</StyledSelect>
				<StyledRadioGroup
					isFilterByCampaign={isFilterByCampaign}
					onChange={handleChartOption}
					value={chartOption.timeType}>
					{radioGroup.map((radioItem: any) => (
						<Radio.Button key={radioItem.value} value={radioItem.value}>
							{t(radioItem.title)}
						</Radio.Button>
					))}
				</StyledRadioGroup>
			</StyledRow>
		);
	};

	return (
		<StyledChartWrapper isFilterByCampaign={isFilterByCampaign}>
			<div className="chart-by-date">
				<div className="chart-title">
					<StyledTitle
						className="content_by_date"
						title={t('dashboard.leaderboard.content_by_date')}>
						{t('dashboard.leaderboard.content_by_date')}
					</StyledTitle>
					{renderMenuSort()}
				</div>
				{isFetchingContentGraphData ? (
					<Skeleton />
				) : (
					<div className="draw-chart">
						<ResponsiveContainer>
							<AreaChart
								height={265}
								data={chartData}
								margin={{ top: 10, right: 10, left: -10, bottom: 0 }}>
								<XAxis
									dataKey="name"
									stroke={colors.fill}
									interval={0}
									tick={<CustomizedXAxisTick />}
								/>
								<YAxis
									dataKey="uv"
									stroke={colors.fill}
									allowDecimals={false}
									tick={<CustomizedYAxisTick />}
								/>
								<Tooltip content={<CustomizeTooltip />} />
								<Area
									dataKey="uv"
									stroke={colors.stroke}
									fill={colors.bgFill}
									dot={CustomizeDot}
								/>
							</AreaChart>
						</ResponsiveContainer>
					</div>
				)}
			</div>
		</StyledChartWrapper>
	);
};

export default React.memo(ChartByDate);
