import { IconEmptyFavorite, IconFavorite } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { markFavoriteContentRequest } from '@stores/actions';
import { StyledIconWrapper } from '@styled/Common/CommonStyled';
import { StyledFavoriteContent } from '@styled/Content/ContentLibrary/MediaItemStyled';
import { useDispatch } from 'react-redux';

type FavoriteBehaviorProps = {
	content?: ContentDto;
	convertHeight?: number;
	markAsFavoriteContent: (content: ContentDto) => void;
};

export const FavoriteBehavior = (props: FavoriteBehaviorProps) => {
	const { content, convertHeight, markAsFavoriteContent } = props;
	const dispatch = useDispatch();

	const handleBlockEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
	};

	if (content) {
		const { review } = content;
		const { favorite = false } = review;

		const renderFavoriteIcon = () => {
			let favoriteIcon: JSX.Element = <IconEmptyFavorite />;
			if (favorite) {
				favoriteIcon = <IconFavorite />;
			}
			return favoriteIcon;
		};

		const handleFavoriteContent = (content: ContentDto) => {
			if (markAsFavoriteContent) {
				markAsFavoriteContent(content);
			}
			dispatch(
				markFavoriteContentRequest({
					isFavorite: !favorite,
					ids: [content.id],
				}),
			);
		};

		return (
			<StyledFavoriteContent onClick={handleBlockEvent} height={convertHeight}>
				<StyledIconWrapper
					onClick={() => handleFavoriteContent(content)}
					cursor="pointer"
					className={favorite ? '' : 'empty-favorite-icon'}
					color={favorite ? THEME.colors.orangeBase : 'transparent'}
					width="26px"
					height="26px">
					{renderFavoriteIcon()}
				</StyledIconWrapper>
			</StyledFavoriteContent>
		);
	}

	return null;
};
