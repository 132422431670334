import THEME from '@constants/themes/themes';
import styled from 'styled-components';

const StyledContainerSettingAggregator = styled.div`
	padding: 26px 110px 12px 90px;
	height: calc(100vh - 66px);
`;

const StyledWrapperTabSetting = styled.div<{ margin?: string }>`
	margin: ${({ margin }) => margin || '0px'};
`;

const StyledTabSetting = styled.div`
	font-weight: 400;
	font-size: 20px;
	line-height: 27px;
	color: ${THEME.colors.gray1};
	margin-top: 24px;
	cursor: pointer;
	&.active {
		cursor: unset;
		font-weight: 700;
		color: ${THEME.colors.white};
	}
	&:first-child {
		margin-top: 0;
	}
`;

const StyledDot = styled.div<{ width?: number; height?: number; color?: string; margin?: string }>`
	width: ${({ width }) => width || 8}px;
	height: ${({ height }) => height || 8}px;
	background-color: ${({ color }) => color || THEME.colors.greenBase};
	${({ margin }) => margin && `margin: ${margin}`};
	border-radius: 50%;
	&.absolute {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0, -50%);
	}
`;

const StyledNameFeed = styled.span`
	position: relative;
	padding-right: 12px;
	color: ${THEME.colors.gray5};
	width: 100%;
	&.color_gray {
		color: ${THEME.colors.gray3};
	}
`;

const StyledTagItem = styled.div`
	display: inline-block;

	background-color: ${THEME.colors.darkBlue3};
	color: ${THEME.colors.gray5};
	border-radius: 20px;
	padding: 4px 12px;
	font-size: 14px;
	line-height: 20px;
	max-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const StyledWrapperTagItem = styled.div`
	position: relative;
	display: inline-block;
	margin-inline-end: 6px;
	margin-bottom: 5px;
	.icon_hidden {
		display: none;
	}
	&:hover {
		.icon_hidden {
			display: block;
		}
	}
`;

export {
	StyledContainerSettingAggregator,
	StyledWrapperTabSetting,
	StyledTabSetting,
	StyledDot,
	StyledNameFeed,
	StyledTagItem,
	StyledWrapperTagItem,
};
