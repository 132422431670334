import { IconExpandMore, IconMoreVert } from '@assets/icons';
import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { STATUS_SETTING } from '@constants/settings/socialAggregator';
import THEME from '@constants/themes/themes';
import SettingSocialAggregatorContext from '@contexts/Settings/SettingSocialAggregator';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { SocialSearchDto } from '@models/settings/socialSearch';
import { FeedDto } from '@models/settings/socialSearch/socialAggregator/summary';
import {
	StyledDropdown,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledLinkView } from '@styled/Header';
import { StyledButtonMore } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledDot, StyledNameFeed } from '@styled/Settings/SettingAggregatorStyled';
import { renderNameFeed } from '@utils/funcHelper';
import { Button, Menu, Tooltip } from 'antd';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ActionConfig = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const { listFeed }: SocialSearchDto = useSelector((state: any) => state.settings.socialSearch);

	const {
		detailFeed,
		onSaveChange,
		hasChange,
		isCreate,
		onCancelForm,
		hasTerms,
		isActive,
		onChangeStatusCreate,
		onChangeDetail,
		typeIncluded,
		setTypeAction,
		onChangeStatus,
	} = useContext(SettingSocialAggregatorContext);

	const activeStatus = useMemo(() => {
		return (isCreate && isActive) || detailFeed?.status === STATUS_SETTING.LIVE;
	}, [isActive, isCreate, detailFeed?.status]);

	const detailChange =
		(detailFeed && (hasChange || typeIncluded !== detailFeed?.queryOperator)) || false;
	const enableChange = detailChange || isCreate;

	const listAction: ActionsTypes[] = [
		{
			id: 1,
			click: () => setTypeAction(TYPE_ACTIONS.REMOVE),
			name: t('button.delete_feed'),
		},
	];

	const menuActionFeeds = (
		<Menu>
			{listFeed?.map((item: FeedDto) => {
				const isDisabled = item?.status === STATUS_SETTING.DISABLED;
				return (
					<Menu.Item
						className={item.id === detailFeed?.id ? 'active_menu_item' : ''}
						onClick={() => onChangeDetail(item)}
						key={item?.id}>
						<StyledNameFeed className={isDisabled ? 'color_gray' : ''}>
							{renderNameFeed(item, true)}
							<StyledDot
								color={
									isDisabled ? THEME.colors.yellowBase : THEME.colors.greenBase
								}
								className="absolute"
								margin={'0'}
							/>
						</StyledNameFeed>
					</Menu.Item>
				);
			})}

			<Menu.Item onClick={() => onChangeStatusCreate(true)}>
				{t('button.add_new_feed')}
			</Menu.Item>
		</Menu>
	);

	const menuMoreAction = (
		<Menu>
			{listAction?.map((item: ActionsTypes) => (
				<Menu.Item onClick={item.click} key={item?.id}>
					{item?.name}
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<StyledWrapperContent
			style={{ borderBottom: `1px solid ${THEME.colors.darkBlue3}` }}
			padding="21px 0">
			<StyledWrapperTitle>
				{isCreate && (
					<StyledTitle className="unset_height" fontSize="20px">
						{t('social_aggregator.new_feed')}
					</StyledTitle>
				)}
				{detailFeed && (
					<StyledWrapperAction>
						<StyledDropdown
							className="dropdown_action"
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode as HTMLElement
							}
							placement="bottomLeft"
							overlayClassName="overlay_album"
							overlay={menuActionFeeds}
							overlayStyle={{ minWidth: '250px' }}
							trigger={['click']}>
							<StyledTitle fontSize="20px" cursor="pointer">
								{renderNameFeed(detailFeed)}
								<IconExpandMore
									style={{
										position: 'absolute',
										top: 0,
										right: '-28px',
										zIndex: 10,
									}}
								/>
							</StyledTitle>
						</StyledDropdown>
						{detailFeed?.status === STATUS_SETTING.LIVE && (
							<StyledLinkView
								margin="0 0 0 32px"
								onClick={() => {
									history.push(`${ROUTE_PATH.FEEDS}/${detailFeed.id}`);
								}}>
								{t('feeds.go_to_feed')}
							</StyledLinkView>
						)}
					</StyledWrapperAction>
				)}
				<StyledWrapperAction>
					{detailFeed && (
						<StyledDropdown
							className="dropdown_action"
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode?.parentNode as HTMLElement
							}
							placement="bottomLeft"
							overlayClassName="overlay_album"
							overlay={menuMoreAction}
							trigger={['click']}>
							<StyledButtonMore>
								<IconMoreVert />
							</StyledButtonMore>
						</StyledDropdown>
					)}
					<Tooltip title={t('social_aggregator.tooltip_enable_setting')}>
						{activeStatus && (
							<Button
								disabled={detailChange}
								onClick={() => onChangeStatus()}
								style={{ margin: '0 8px' }}>
								<StyledDot margin={'0 4px 0 0'} />
								{t('button.active')}
							</Button>
						)}

						{!activeStatus && (
							<Button
								disabled={detailChange}
								onClick={() => onChangeStatus()}
								style={{
									margin: '0 8px',
									backgroundColor: 'rgba(255, 170, 0, 0.05)',
									border: '1px solid rgba(255, 170, 0, 0.2)',
								}}>
								<StyledDot color={THEME.colors.yellowBase} margin={'0 4px 0 0'} />
								{t('button.disabled')}
							</Button>
						)}
					</Tooltip>

					{enableChange && (
						<>
							<Button onClick={() => onCancelForm()}>{t('button.cancel')}</Button>
							{hasTerms && (
								<>
									<StyledTitle
										margin="0 13px"
										className="unset_height"
										color={THEME.colors.gray1}
										fontSize="14px"
										display="inline-flex">
										{t('social_aggregator.unsaved_changes')}
									</StyledTitle>
									<Button onClick={onSaveChange} type="primary">
										{t('button.save_changes')}
									</Button>
								</>
							)}
						</>
					)}
				</StyledWrapperAction>
			</StyledWrapperTitle>
		</StyledWrapperContent>
	);
};

export default ActionConfig;
