import { StyledFlex, StyledTable, StyledText } from '@styled/Common/CommonStyled';
import styled, { css } from 'styled-components';
import { StyledCollapse } from './ContactCardStyled';
import THEME from '@constants/themes/themes';

const StyledSocialStatsCollapse = styled(StyledCollapse)`
	.ant-collapse-content {
		padding: 0;
		overflow: hidden;
	}

	.ant-collapse-content-box {
		padding: 0 !important;
		height: fit-content;
	}
`;

const StyledSocialStatsTable = styled(StyledTable)<{ rowHeight?: number }>`
	height: inherit;

	.ant-table {
		width: 100%;
		max-width: 100%;

		tbody.ant-table-tbody {
			tr {
				&.ant-table-row {
					${({ rowHeight }) => rowHeight && `height: ${rowHeight}px !important`};
				}

				td {
					&:first-child {
						text-overflow: unset;
						padding-left: 14px;
					}
				}
			}
		}

		&.ant-table-empty {
			table tbody.ant-table-tbody tr:hover td {
				background-color: transparent !important;
			}
		}
	}
`;

const StyledInvalidSocialUsername = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
`;

const StyledSocialUsername = styled.div`
	position: relative;
	max-width: calc(100% - 20px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	line-height: initial;

	a {
		color: ${THEME.colors.gray5};

		&:hover {
			color: ${THEME.colors.orangeBase};
		}
	}
`;

const StyledRefreshingMetrics = styled(StyledFlex)<{ isDisabled?: boolean }>`
	${({ isDisabled }) =>
		isDisabled &&
		css`
			${StyledText}:last-child {
				color: ${THEME.colors.gray3};
			}
		`}
`;

export {
	StyledSocialStatsCollapse,
	StyledSocialStatsTable,
	StyledSocialUsername,
	StyledInvalidSocialUsername,
	StyledRefreshingMetrics,
};
