import { Form, message, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Styled
import { mediaHubURL } from '@config/index';
import { LIMIT, PATTERNS } from '@constants/common';
import { FAILED, NONE, SUCCEEDED } from '@constants/status';
import AccountUserContext, { AccountUserContextDto } from '@contexts/Settings/AccountUser';
import { getUser } from '@helpers/userHelpers';
import { InviteGuestDto } from '@models/content/albums/albumManager';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import { sendGuestPassCodeEnd, sendGuestPassCodeRequest } from '@stores/actions';
import {
	StyledButton,
	StyledFormItem,
	StyledSection,
	StyledSelect,
	StyledText,
} from '@styled/Common/CommonStyled';
import { StyledForm } from '@styled/Settings/AccountUserStyled';
import { encode } from '@utils/common';

const InviteGuest = () => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const user = getUser();
	const { clientId, brandName, accountId } = user;
	const mediaHubLink = `${mediaHubURL}/${clientId}/${encode(brandName)}`;

	const { handleRefreshPage }: AccountUserContextDto = useContext(AccountUserContext);
	const { sendGuestPassCodeStatus = NONE, error }: AlbumsStoreDto = useSelector(
		(state: any) => state.albumManager,
	);

	const FIELD_NAME = {
		EMAILS: 'emails',
	};

	const initPayload = {
		emails: [],
		brandId: clientId,
		sharedAlbums: [],
		invitedBy: accountId,
	};
	const [inviteGuestPayload, setInviteGuestPayload] = useState<InviteGuestDto>(initPayload);

	useEffect(() => {
		if (sendGuestPassCodeStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.sent'),
				}),
			);
			handleRefreshPage();
			form.resetFields();
		}

		if (sendGuestPassCodeStatus === FAILED || sendGuestPassCodeStatus === SUCCEEDED) {
			dispatch(sendGuestPassCodeEnd());
		}
	}, [sendGuestPassCodeStatus]);

	useEffect(() => {
		if (error) {
			message.success(error);
		}
	}, [error]);

	useEffect(() => {
		if (accountId && clientId) {
			setInviteGuestPayload({
				...inviteGuestPayload,
				brandId: clientId,
				invitedBy: accountId,
			});
		}
	}, [accountId, clientId]);

	const handleInviteGuest = () => {
		dispatch(sendGuestPassCodeRequest(inviteGuestPayload));
	};

	const onValuesChange = (changedValue: any) => {
		setInviteGuestPayload({ ...inviteGuestPayload, ...changedValue });
	};

	const setFields = (field: string, value: any) => {
		form.setFieldsValue({
			[field]: value,
		});
	};

	return (
		<StyledForm
			form={form}
			onValuesChange={onValuesChange}
			onFinish={handleInviteGuest}
			requiredMark={false}>
			<Space>
				<StyledSection margin="4px 0 0 0">
					<StyledText>{t('albums.modal.invite_guests')}</StyledText>
					<StyledFormItem
						name={FIELD_NAME.EMAILS}
						rules={[
							{
								required: true,
								message: t('validation.required', {
									field: 'email(s)',
								}),
							},
							() => ({
								validator(_: any, value: string) {
									const regex = PATTERNS.EMAIL;
									message.destroy();

									if (
										value?.length > 0 &&
										value?.length <= LIMIT.SHARED_GUEST_EMAIL
									) {
										const checkedValue = value[value.length - 1];
										if (!regex.test(checkedValue)) {
											message.error(
												t('validation.invalid_message', {
													field: t('albums.modal.emails'),
												}),
											);
											setFields('emails', value.slice(0, value.length - 1));
											return Promise.reject();
										}
									} else if (value?.length > LIMIT.SHARED_GUEST_EMAIL) {
										message.error(
											t('albums.message.guest_limit', {
												limit: LIMIT.SHARED_GUEST_EMAIL,
											}),
										);
										setFields('emails', value.slice(0, value.length - 1));
										return Promise.reject();
									}

									return Promise.resolve();
								},
							}),
						]}>
						<StyledSelect
							className="invite_guest"
							width="434px"
							margin="6px 0 0 0"
							placeholder={t('albums.placeholder.add_email')}
							mode="tags"
							notFoundContent={null}
							autoFocus={true}
							maxTagCount={2}
						/>
					</StyledFormItem>
				</StyledSection>

				<StyledButton
					htmlType="submit"
					type="primary"
					padding="6px 16px"
					margin="0 0 -8px 0">
					{t('albums.button.send_guest_invite')}
				</StyledButton>
			</Space>

			<StyledSection
				width="433px"
				className="fixed-section overflow"
				style={{ maxWidth: 'calc(100% - 608px)' }}
				padding="5px 13px"
				margin="0"
				title={t('setting.account_user.guests_desc_no_link')}
				bgColor="rgba(24, 144, 255, 0.1)"
				dangerouslySetInnerHTML={{
					__html: t('setting.account_user.guests_desc', {
						mediaHubLink,
					}),
				}}
			/>
		</StyledForm>
	);
};

export default InviteGuest;
