import {
	IconNoteMessage,
	IconPending,
	IconRightsPending,
	IconTriangleWarning,
	IconWarning,
} from '@assets/icons';
import THEME from '@constants/themes/themes';
import { ParamSummaryContactDto } from '@models/creator/summary';
import { invalidateSocialUsernameRequest } from '@stores/actions';
import { StyledIcon, StyledLinkHref } from '@styled/Common/CommonStyled';
import { StyledPopover } from '@styled/Creators/SummaryStyled';

import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

type InvalidFlagProps = {
	margin?: string;
	payload: {
		source: string;
		username: string;
		isInvalid: boolean;
		isAmbassador: boolean;
		paramSummaryContactDto?: ParamSummaryContactDto;
	};
	updateSocialUsernameStatus?: (source: string) => void;
};

const InvalidFlag = (props: InvalidFlagProps) => {
	const { margin, payload, updateSocialUsernameStatus } = props;
	const dispatch = useDispatch();

	const invalidateCreatorSocialUsername = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		const { username, source, paramSummaryContactDto } = payload;
		if (updateSocialUsernameStatus) {
			updateSocialUsernameStatus(source);
		}
		dispatch(invalidateSocialUsernameRequest({ username, source, paramSummaryContactDto }));
	};

	const transProps: any = {
		i18nKey: 'creator_summary.tooltip.invalid_social_username',
		components: {
			linkTo: (
				<StyledLinkHref
					onClick={invalidateCreatorSocialUsername}
					color={THEME.colors.orangeBase}
				/>
			),
		},
	};

	return payload.isAmbassador && payload.isInvalid ? (
		<StyledPopover
			overlayClassName="overlay_dark_blue3"
			overlayInnerStyle={{ width: '300px' }}
			content={<Trans {...transProps} />}
			title={null}>
			<StyledIcon
				onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
				cursor="default"
				margin={margin || '0 8px 0 0'}
				fillPath="rgb(234, 67, 53)"
				size={20}>
				<IconNoteMessage />
			</StyledIcon>
		</StyledPopover>
	) : null;
};

export default InvalidFlag;
