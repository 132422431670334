const contentTypes = {
	FETCH_CONTENT_REQUEST: 'CONTENT_REQUEST',
	FETCH_CONTENT_SUCCEEDED: 'CONTENT_SUCCEEDED',
	FETCH_CONTENT_FAILED: 'CONTENT_FAILED',
};

const contentDetailTypes = {
	FETCH_CONTENT_DETAIL_REQUEST: 'FETCH_CONTENT_DETAIL_REQUEST',
	FETCH_CONTENT_DETAIL_SUCCEEDED: 'FETCH_CONTENT_DETAIL_SUCCEEDED',
	FETCH_CONTENT_DETAIL_FAILED: 'FETCH_CONTENT_DETAIL_FAILED',
};

const rejectContentTypes = {
	REJECT_CONTENT_REQUEST: 'REJECT_CONTENT_REQUEST',
	REJECT_CONTENT_SUCCEEDED: 'REJECT_CONTENT_SUCCEEDED',
	REJECT_CONTENT_FAILED: 'REJECT_CONTENT_FAILED',
	REJECT_CONTENT_END: 'REJECT_CONTENT_END',
};

const moveContentTypes = {
	MOVE_CONTENT_REQUEST: 'MOVE_CONTENT_REQUEST',
	MOVE_CONTENT_SUCCEEDED: 'MOVE_CONTENT_SUCCEEDED',
	MOVE_CONTENT_FAILED: 'MOVE_CONTENT_FAILED',
	MOVE_CONTENT_END: 'MOVE_CONTENT_END',
};

const deleteContentTypes = {
	DELETE_CONTENT_REQUEST: 'DELETE_CONTENT_REQUEST',
	DELETE_CONTENT_SUCCEEDED: 'DELETE_CONTENT_SUCCEEDED',
	DELETE_CONTENT_FAILED: 'DELETE_CONTENT_FAILED',
	DELETE_CONTENT_END: 'DELETE_CONTENT_END',
};

const deleteMultiContentsTypes = {
	DELETE_MULTI_CONTENTS_REQUEST: 'DELETE_MULTI_CONTENTS_REQUEST',
	DELETE_MULTI_CONTENTS_SUCCEEDED: 'DELETE_MULTI_CONTENTS_SUCCEEDED',
	DELETE_MULTI_CONTENTS_FAILED: 'DELETE_MULTI_CONTENTS_FAILED',
	DELETE_MULTI_CONTENTS_END: 'DELETE_MULTI_CONTENTS_END',
};

const reviewContentTypes = {
	REVIEW_CONTENT_REQUEST: 'REVIEW_CONTENT_REQUEST',
	REVIEW_CONTENT_SUCCEEDED: 'REVIEW_CONTENT_SUCCEEDED',
	REVIEW_CONTENT_FAILED: 'REVIEW_CONTENT_FAILED',
	REVIEW_CONTENT_END: 'REVIEW_CONTENT_END',
};

const reviewMultiContentsTypes = {
	REVIEW_MULTI_CONTENTS_REQUEST: 'REVIEW_MULTI_CONTENTS_REQUEST',
	REVIEW_MULTI_CONTENTS_SUCCEEDED: 'REVIEW_MULTI_CONTENTS_SUCCEEDED',
	REVIEW_MULTI_CONTENTS_FAILED: 'REVIEW_MULTI_CONTENTS_FAILED',
	REVIEW_MULTI_CONTENTS_END: 'REVIEW_MULTI_CONTENTS_END',
};

const getFilterTypes = {
	GET_FILTER_REQUEST: 'GET_FILTER_REQUEST',
	GET_FILTER_SUCCEEDED: 'GET_FILTER_SUCCEEDED',
	GET_FILTER_FAILED: 'GET_FILTER_FAILED',
};

const saveFilterTypes = {
	SAVE_FILTER_REQUEST: 'SAVE_FILTER_REQUEST',
	SAVE_FILTER_SUCCEEDED: 'SAVE_FILTER_SUCCEEDED',
	SAVE_FILTER_FAILED: 'SAVE_FILTER_FAILED',
	SAVE_FILTER_END: 'SAVE_FILTER_END',
};

const deleteFilterTypes = {
	DELETE_FILTER_REQUEST: 'DELETE_FILTER_REQUEST',
	DELETE_FILTER_SUCCEEDED: 'DELETE_FILTER_SUCCEEDED',
	DELETE_FILTER_FAILED: 'DELETE_FILTER_FAILED',
	DELETE_FILTER_END: 'DELETE_FILTER_END',
};

const resetStateTypes = {
	RESET_STATE_REQUEST: 'RESET_STATE_REQUEST',
};

const getVerificationScroreTypes = {
	GET_VERIFICATION_SCORE_REQUEST: 'GET_VERIFICATION_SCORE_REQUEST',
	GET_VERIFICATION_SCORE_SUCCEEDED: 'GET_VERIFICATION_SCORE_SUCCEEDED',
	GET_VERIFICATION_SCORE_FAILED: 'GET_VERIFICATION_SCORE_FAILED',
};

const getTermsConditionsTypes = {
	GET_TERMS_CONDITIONS_REQUEST: 'GET_TERMS_CONDITIONS_REQUEST',
	GET_TERMS_CONDITIONS_SUCCEEDED: 'GET_TERMS_CONDITIONS_SUCCEEDED',
	GET_TERMS_CONDITIONS_FAILED: 'GET_TERMS_CONDITIONS_FAILED',
	GET_TERMS_CONDITIONS_END: 'GET_TERMS_CONDITIONS_END',
};

const countDownloadContentTypes = {
	COUNT_DOWNLOAD_CONTENT_REQUEST: 'COUNT_DOWNLOAD_CONTENT_REQUEST',
	COUNT_DOWNLOAD_CONTENT_SUCCEEDED: 'COUNT_DOWNLOAD_CONTENT_SUCCEEDED',
	COUNT_DOWNLOAD_CONTENT_FAILED: 'COUNT_DOWNLOAD_CONTENT_FAILED',
	COUNT_DOWNLOAD_CONTENT_END: 'COUNT_DOWNLOAD_CONTENT_END',
};

const updateContentStatusRecentlyAddedType = {
	UPDATE_CONTENT_STATUS_RECENTLY_ADDED_REQUEST: 'UPDATE_CONTENT_STATUS_RECENTLY_ADDED_REQUEST',
	UPDATE_CONTENT_STATUS_RECENTLY_ADDED_SUCCEEDED:
		'UPDATE_CONTENT_STATUS_RECENTLY_ADDED_SUCCEEDED',
	UPDATE_CONTENT_STATUS_RECENTLY_ADDED_FAILED: 'UPDATE_CONTENT_STATUS_RECENTLY_ADDED_FAILED',
	UPDATE_CONTENT_STATUS_RECENTLY_ADDED_END: 'UPDATE_CONTENT_STATUS_RECENTLY_ADDED_END',
};

const getContentUnviewedStatusRecentlyAddedType = {
	GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST:
		'GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST',
	GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_SUCCEEDED:
		'GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_SUCCEEDED',
	GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_FAILED:
		'GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_FAILED',
	GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_END:
		'GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_END',
};

const getContentLocationTypes = {
	GET_CONTENT_LOCATION_REQUEST: 'GET_CONTENT_LOCATION_REQUEST',
	GET_CONTENT_LOCATION_SUCCEEDED: 'GET_CONTENT_LOCATION_SUCCEEDED',
	GET_CONTENT_LOCATION_FAILED: 'GET_CONTENT_LOCATION_FAILED',
};

const getContentCustomFieldsTypes = {
	GET_CONTENT_CUSTOM_FIELDS_REQUEST: 'GET_CONTENT_CUSTOM_FIELDS_REQUEST',
	GET_CONTENT_CUSTOM_FIELDS_SUCCEEDED: 'GET_CONTENT_CUSTOM_FIELDS_SUCCEEDED',
	GET_CONTENT_CUSTOM_FIELDS_FAILED: 'GET_CONTENT_CUSTOM_FIELDS_FAILED',
};

const getContentTagsTypes = {
	GET_CONTENT_TAGS_REQUEST: 'GET_CONTENT_TAGS_REQUEST',
	GET_CONTENT_TAGS_SUCCEEDED: 'GET_CONTENT_TAGS_SUCCEEDED',
	GET_CONTENT_TAGS_FAILED: 'GET_CONTENT_TAGS_FAILED',
};

const updateFilterContentDetailTypes = {
	UPDATE_FILTER_CONTENT_DETAIL_REQUEST: 'UPDATE_FILTER_CONTENT_DETAIL_REQUEST',
	UPDATE_FILTER_CONTENT_DETAIL_SUCCEEDED: 'UPDATE_FILTER_CONTENT_DETAIL_SUCCEEDED',
	UPDATE_FILTER_CONTENT_DETAIL_FAILED: 'UPDATE_FILTER_CONTENT_DETAIL_FAILED',
	UPDATE_FILTER_CONTENT_DETAIL_END: 'UPDATE_FILTER_CONTENT_DETAIL_END',
};

const getTermsContentTypes = {
	GET_TERMS_CONTENT_REQUEST: 'GET_TERMS_CONTENT_REQUEST',
	GET_TERMS_CONTENT_SUCCEEDED: 'GET_TERMS_CONTENT_SUCCEEDED',
	GET_TERMS_CONTENT_FAILED: 'GET_TERMS_CONTENT_FAILED',
};

const storeDataTypes = {
	STORE_DATA: 'STORE_DATA',
};

const getPublishLogTypes = {
	GET_PUBLISH_LOG_REQUEST: 'GET_PUBLISH_LOG_REQUEST',
	GET_PUBLISH_LOG_SUCCEEDED: 'GET_PUBLISH_LOG_SUCCEEDED',
	GET_PUBLISH_LOG_FAILED: 'GET_PUBLISH_LOG_FAILED',
};

const createPublishLogTypes = {
	CREATE_PUBLISH_LOG_REQUEST: 'CREATE_PUBLISH_LOG_REQUEST',
	CREATE_PUBLISH_LOG_SUCCEEDED: 'CREATE_PUBLISH_LOG_SUCCEEDED',
	CREATE_PUBLISH_LOG_FAILED: 'CREATE_PUBLISH_LOG_FAILED',
	CREATE_PUBLISH_LOG_END: 'CREATE_PUBLISH_LOG_END',
};

const updatePublishLogTypes = {
	UPDATE_PUBLISH_LOG_REQUEST: 'UPDATE_PUBLISH_LOG_REQUEST',
	UPDATE_PUBLISH_LOG_SUCCEEDED: 'UPDATE_PUBLISH_LOG_SUCCEEDED',
	UPDATE_PUBLISH_LOG_FAILED: 'UPDATE_PUBLISH_LOG_FAILED',
	UPDATE_PUBLISH_LOG_END: 'UPDATE_PUBLISH_LOG_END',
};

const removePublishLogTypes = {
	REMOVE_PUBLISH_LOG_REQUEST: 'REMOVE_PUBLISH_LOG_REQUEST',
	REMOVE_PUBLISH_LOG_SUCCEEDED: 'REMOVE_PUBLISH_LOG_SUCCEEDED',
	REMOVE_PUBLISH_LOG_FAILED: 'REMOVE_PUBLISH_LOG_FAILED',
	REMOVE_PUBLISH_LOG_END: 'REMOVE_PUBLISH_LOG_END',
};

const markFavoriteContentTypes = {
	MARK_FAVORITE_CONTENT_REQUEST: 'MARK_FAVORITE_CONTENT_REQUEST',
	MARK_FAVORITE_CONTENT_SUCCEEDED: 'MARK_FAVORITE_CONTENT_SUCCEEDED',
	MARK_FAVORITE_CONTENT_FAILED: 'MARK_FAVORITE_CONTENT_FAILED',
	MARK_FAVORITE_CONTENT_END: 'MARK_FAVORITE_CONTENT_END',
};

const ratioAndResolutionTypes = {
	GET_RATIO_RESOLUTION_REQUEST: 'GET_RATIO_RESOLUTION_REQUEST',
	GET_RATIO_RESOLUTION_SUCCEEDED: 'GET_RATIO_RESOLUTION_SUCCEEDED',
	GET_RATIO_RESOLUTION_FAILED: 'GET_RATIO_RESOLUTION_FAILED',
};

const updateContactedContentTypes = {
	UPDATE_CONTACTED_CONTENT_REQUEST: 'UPDATE_CONTACTED_CONTENT_REQUEST',
	UPDATE_CONTACTED_CONTENT_SUCCEEDED: 'UPDATE_CONTACTED_CONTENT_SUCCEEDED',
	UPDATE_CONTACTED_CONTENT_FAILED: 'UPDATE_CONTACTED_CONTENT_FAILED',
	UPDATE_CONTACTED_CONTENT_END: 'UPDATE_CONTACTED_CONTENT_END',
};

const updateAccountSettingTypes = {
	UPDATE_ACCOUNT_SETTING_REQUEST: 'UPDATE_ACCOUNT_SETTING_REQUEST',
	UPDATE_ACCOUNT_SETTING_SUCCEEDED: 'UPDATE_ACCOUNT_SETTING_SUCCEEDED',
	UPDATE_ACCOUNT_SETTING_FAILED: 'UPDATE_ACCOUNT_SETTING_FAILED',
	UPDATE_ACCOUNT_SETTING_END: 'UPDATE_ACCOUNT_SETTING_END',

	CHANGE_INFO_ACCOUNT_SETTING: 'CHANGE_INFO_ACCOUNT_SETTING',
};

const contentEmojiTypes = {
	FETCH_CONTENT_EMOJI_REQUEST: 'FETCH_CONTENT_EMOJI_REQUEST',
	FETCH_CONTENT_EMOJI_SUCCEEDED: 'FETCH_CONTENT_EMOJI_SUCCEEDED',
	FETCH_CONTENT_EMOJI_FAILED: 'FETCH_CONTENT_EMOJI_FAILED',
};

const updateEmojiTypes = {
	UPDATE_EMOJI_REQUEST: 'UPDATE_EMOJI_REQUEST',
	UPDATE_EMOJI_SUCCEEDED: 'UPDATE_EMOJI_SUCCEEDED',
	UPDATE_EMOJI_FAILED: 'UPDATE_EMOJI_FAILED',
	UPDATE_EMOJI_END: 'UPDATE_EMOJI_END',
};

const contentTotalTypes = {
	FETCH_TOTAL_CONTENT_REQUEST: 'FETCH_TOTAL_CONTENT_REQUEST',
	FETCH_TOTAL_CONTENT_SUCCEEDED: 'FETCH_TOTAL_CONTENT_SUCCEEDED',
	FETCH_TOTAL_CONTENT_FAILED: 'FETCH_TOTAL_CONTENT_FAILED',
};

const contentImgVersionTypes = {
	FETCH_VERSION_IMG_REQUEST: 'FETCH_VERSION_IMG_REQUEST',
	FETCH_VERSION_IMG_SUCCEEDED: 'FETCH_VERSION_IMG_SUCCEEDED',
	FETCH_VERSION_IMG_FAILED: 'FETCH_VERSION_IMG_FAILED',
};

const createImgVersionTypes = {
	CREATE_IMG_VERSION_REQUEST: 'CREATE_IMG_VERSION_REQUEST',
	CREATE_IMG_VERSION_SUCCEEDED: 'CREATE_IMG_VERSION_SUCCEEDED',
	CREATE_IMG_VERSION_FAILED: 'CREATE_IMG_VERSION_FAILED',
	CREATE_IMG_VERSION_END: 'CREATE_IMG_VERSION_END',
};

const updateImgVersionTypes = {
	UPDATE_IMG_VERSION_REQUEST: 'UPDATE_IMG_VERSION_REQUEST',
	UPDATE_IMG_VERSION_SUCCEEDED: 'UPDATE_IMG_VERSION_SUCCEEDED',
	UPDATE_IMG_VERSION_FAILED: 'UPDATE_IMG_VERSION_FAILED',
	UPDATE_IMG_VERSION_END: 'UPDATE_IMG_VERSION_END',
};

const rotateImgTypes = {
	ROTATE_IMAGE_REQUEST: 'ROTATE_IMAGE_REQUEST',
	ROTATE_IMAGE_SUCCEEDED: 'ROTATE_IMAGE_SUCCEEDED',
	ROTATE_IMAGE_FAILED: 'ROTATE_IMAGE_FAILED',
	ROTATE_IMAGE_END: 'ROTATE_IMAGE_END',
};

// Logo detection
const getDetectionServiceListTypes = {
	GET_DETECTION_SERVICE_LIST_REQUEST: 'GET_DETECTION_SERVICE_LIST_REQUEST',
	GET_DETECTION_SERVICE_LIST_SUCCEEDED: 'GET_DETECTION_SERVICE_LIST_SUCCEEDED',
	GET_DETECTION_SERVICE_LIST_FAILED: 'GET_DETECTION_SERVICE_LIST_FAILED',
	GET_DETECTION_SERVICE_LIST_END: 'GET_DETECTION_SERVICE_LIST_END',
};

const estimateCostTypes = {
	ESTIMATE_COST_REQUEST: 'ESTIMATE_COST_REQUEST',
	ESTIMATE_COST_SUCCEEDED: 'ESTIMATE_COST_SUCCEEDED',
	ESTIMATE_COST_FAILED: 'ESTIMATE_COST_FAILED',
	ESTIMATE_COST_END: 'ESTIMATE_COST_END',
};

const submitDetectionOrderTypes = {
	SUBMIT_DETECTION_ORDER_REQUEST: 'SUBMIT_DETECTION_ORDER_REQUEST',
	SUBMIT_DETECTION_ORDER_SUCCEEDED: 'SUBMIT_DETECTION_ORDER_SUCCEEDED',
	SUBMIT_DETECTION_ORDER_FAILED: 'SUBMIT_DETECTION_ORDER_FAILED',
	SUBMIT_DETECTION_ORDER_END: 'SUBMIT_DETECTION_ORDER_END',
};

const getMediaDetectionTypes = {
	GET_MEDIA_DETECTION_REQUEST: 'GET_MEDIA_DETECTION_REQUEST',
	GET_MEDIA_DETECTION_SUCCEEDED: 'GET_MEDIA_DETECTION_SUCCEEDED',
	GET_MEDIA_DETECTION_FAILED: 'GET_MEDIA_DETECTION_FAILED',
	GET_MEDIA_DETECTION_END: 'GET_MEDIA_DETECTION_END',
};

const getDetectionLabelListTypes = {
	GET_DETECTION_LABEL_LIST_REQUEST: 'GET_DETECTION_LABEL_LIST_REQUEST',
	GET_DETECTION_LABEL_LIST_SUCCEEDED: 'GET_DETECTION_LABEL_LIST_SUCCEEDED',
	GET_DETECTION_LABEL_LIST_FAILED: 'GET_DETECTION_LABEL_LIST_FAILED',
	GET_DETECTION_LABEL_LIST_END: 'GET_DETECTION_LABEL_LIST_END',
};

const fetchContentDataTypes = {
	FETCH_CONTENT_DATA_REQUEST: 'FETCH_CONTENT_DATA_REQUEST',
	FETCH_CONTENT_DATA_SUCCEEDED: 'FETCH_CONTENT_DATA_SUCCEEDED',
	FETCH_CONTENT_DATA_FAILED: 'FETCH_CONTENT_DATA_FAILED',
	FETCH_CONTENT_DATA_END: 'FETCH_CONTENT_DATA_END',
};

const saveUploadContentTypes = {
	SAVE_STATUS_UPLOAD_CONTENT: 'SAVE_STATUS_UPLOAD_CONTENT',
	RESET_LIST_CONTENT_AFTER_UPLOAD: 'RESET_LIST_CONTENT_AFTER_UPLOAD',
	REMOVE_SINGLE_UPLOAD_CONTENT: 'REMOVE_SINGLE_UPLOAD_CONTENT',
};

const fetchDeviceMakeTypes = {
	FETCH_DEVICE_MAKE_REQUEST: 'FETCH_DEVICE_MAKE_REQUEST',
	FETCH_DEVICE_MAKE_SUCCEEDED: 'FETCH_DEVICE_MAKE_SUCCEEDED',
	FETCH_DEVICE_MAKE_FAILED: 'FETCH_DEVICE_MAKE_FAILED',
};

const fetchDeviceModelTypes = {
	FETCH_DEVICE_MODEL_REQUEST: 'FETCH_DEVICE_MODEL_REQUEST',
	FETCH_DEVICE_MODEL_SUCCEEDED: 'FETCH_DEVICE_MODEL_SUCCEEDED',
	FETCH_DEVICE_MODEL_FAILED: 'FETCH_DEVICE_MODEL_FAILED',
};

const fetchShotCountriesTypes = {
	FETCH_SHOT_COUNTRIES_REQUEST: 'FETCH_SHOT_COUNTRIES_REQUEST',
	FETCH_SHOT_COUNTRIES_SUCCEEDED: 'FETCH_SHOT_COUNTRIES_SUCCEEDED',
	FETCH_SHOT_COUNTRIES_FAILED: 'FETCH_SHOT_COUNTRIES_FAILED',
};

export {
	contentTypes,
	rejectContentTypes,
	saveUploadContentTypes,
	moveContentTypes,
	deleteContentTypes,
	deleteMultiContentsTypes,
	reviewContentTypes,
	reviewMultiContentsTypes,
	getFilterTypes,
	saveFilterTypes,
	deleteFilterTypes,
	resetStateTypes,
	getVerificationScroreTypes,
	getTermsConditionsTypes,
	countDownloadContentTypes,
	updateContentStatusRecentlyAddedType,
	getContentUnviewedStatusRecentlyAddedType,
	getContentLocationTypes,
	storeDataTypes,
	updateFilterContentDetailTypes,
	getContentTagsTypes,
	getTermsContentTypes,
	getPublishLogTypes,
	createPublishLogTypes,
	updatePublishLogTypes,
	removePublishLogTypes,
	markFavoriteContentTypes,
	ratioAndResolutionTypes,
	updateContactedContentTypes,
	updateAccountSettingTypes,
	contentEmojiTypes,
	updateEmojiTypes,
	contentTotalTypes,
	contentImgVersionTypes,
	createImgVersionTypes,
	updateImgVersionTypes,
	getDetectionServiceListTypes,
	estimateCostTypes,
	submitDetectionOrderTypes,
	getDetectionLabelListTypes,
	getMediaDetectionTypes,
	contentDetailTypes,
	fetchContentDataTypes,
	rotateImgTypes,
	fetchDeviceMakeTypes,
	fetchDeviceModelTypes,
	fetchShotCountriesTypes,
	getContentCustomFieldsTypes,
};
