import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledLogo = styled.div`
	margin: 20px 24px;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 99;
	width: 35px;
	height: 35px;

	&.logo-text {
		margin: 20px 16px;
		width: 165px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const StyledLayoutContainer = styled(Layout)`
	min-height: 100vh;
	overflow: hidden;
`;

export const StyledContent = styled(Layout.Content)<{ background?: boolean }>`
	margin: 66px 16px 0 16px;
	${({ background }) => background && 'background-color: unset !important'}
`;

export const StyledSibarContainer = styled.div`
	background-color: #011627;
`;

export const StyledAnimationRing = styled.div`
	@keyframes ring {
		0% {
			transform: rotateZ(0);
		}
		2% {
			transform: rotateZ(30deg);
		}
		6% {
			transform: rotateZ(-28deg);
		}
		10% {
			transform: rotateZ(22deg);
		}
		14% {
			transform: rotateZ(-20deg);
		}
		18% {
			transform: rotateZ(16deg);
		}
		22% {
			transform: rotateZ(-14deg);
		}
		26% {
			transform: rotateZ(10deg);
		}
		30% {
			transform: rotateZ(-8deg);
		}
		34% {
			transform: rotateZ(4deg);
		}
		38% {
			transform: rotateZ(-2deg);
		}
		42% {
			transform: rotateZ(2deg);
		}
		46% {
			transform: rotateZ(-1deg);
		}

		50% {
			transform: rotateZ(0);
		}
		100% {
			transform: rotateZ(0);
		}
	}
	-webkit-animation: ring 4s 0.7s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	-moz-animation: ring 4s 0.7s ease-in-out infinite;
	-moz-transform-origin: 50% 4px;
	animation: ring 4s 0.7s ease-in-out infinite;
	transform-origin: 50% 4px;
`;
