import { IconCheck, IconClose, IconEdit } from '@assets/icons';
import ContentLicensingTerms from '@components/Settings/ContentLicensing/DetailTemplate/ContainerTemplate/ContentLicensingTerms';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { TermConditionItemDto, WidgetBuilderDetailDto } from '@models/content/widgetManager';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';
import { saveDraftWidgetBuilderRequest } from '@stores/actions';
import { StyledIcon, StyledSelect, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledIconTag } from '@styled/Content/WidgetManager';
import {
	StyledHeaderPreview,
	StyledPreviewDetailWidget,
	StyledPreviewFormTerms,
	StyledWrapperInput,
	StyledWrapperPreviewDetailWidget,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { Input, Select, message } from 'antd';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import DetailWidget from '../../DetailWidget';
import { renderScreenView } from '@utils/renderComponent';
import { EMAIL_OPT_IN } from '@constants/content/widgetManager';
import { convertWidgetTerms } from '@utils/funcHelper';

const StyledLabel = styled.label`
	margin-right: 4px;
	color: ${THEME.colors.gray1};
`;

const StyledName = styled.span`
	color: ${THEME.colors.gray5};
	display: inline-flex;
	align-items: center;
`;

const StyledLeftInfo = styled.div`
	font-size: 1.143rem;
	height: 34px;
	line-height: 2.13;
	display: inline-flex;
	align-items: center;
`;

export const StyledIconEdit = styled(IconEdit)`
	margin-left: 4px;
	width: 16px;
	height: 16px;
	cursor: pointer;
`;

const StyledActionName = styled.div`
	display: inline-flex;
`;

const { Option } = Select;

const PreviewDetailWidget = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { templateDetail }: ContentLicensingStoreDto = useSelector(
		(state: any) => state?.contentLicensing,
	);
	const { detailWidget, setIsMobile, isMobile, currentStep, termsSelected, setTermsSelected } =
		useContext(WidgetBuilderContext);
	const { name } = detailWidget;
	const optionView = renderScreenView(t);

	const refContainer = useRef<any>(null);
	const [nameWidget, setNameWidget] = useState<string>('');
	const [isEdit, setIsEdit] = useState<boolean>(false);

	const onChangeView = (val: any) => {
		if (val === optionView[0].value) {
			setIsMobile(false);
		} else {
			setIsMobile(true);
		}
	};

	const handleSaveName = () => {
		if (!nameWidget?.trim()) {
			setNameWidget('');
			return message.error(t('content.widget_manager.widget_builder.name_required'));
		}
		setIsEdit(false);
		const dataRequest = {
			...detailWidget,
			metadata: JSON.stringify(detailWidget?.metadata),
			name: nameWidget,
		};
		return dispatch(saveDraftWidgetBuilderRequest({ dataRequest }));
	};

	useEffect(() => {
		if (!isEdit) {
			setNameWidget(name);
		}
	}, [name]);

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const widgetPreview = useMemo(() => {
		const detailClone: WidgetBuilderDetailDto = cloneDeep(detailWidget);

		return convertWidgetTerms({ ...detailClone, terms: templateDetail?.terms || [] });
	}, [detailWidget, templateDetail]);

	return (
		<StyledPreviewDetailWidget ref={refContainer}>
			{termsSelected && (
				<StyledPreviewFormTerms>
					<StyledWrapperContent position="relative" width="100%" height="100%">
						<StyledIcon
							onClick={() => setTermsSelected(null)}
							size={24}
							className="absolute"
							top="24px"
							right="16px">
							<IconClose />
						</StyledIcon>
						<ContentLicensingTerms
							showTooltipRedirect
							templateId={templateDetail?.id}
							disabledEdit={true}
							terms={termsSelected}
						/>
					</StyledWrapperContent>
				</StyledPreviewFormTerms>
			)}
			<StyledHeaderPreview>
				<StyledLeftInfo>
					<StyledLabel>{t('content.widget_manager.widget_builder.editing')}</StyledLabel>
					{isEdit ? (
						<StyledWrapperInput>
							<Input
								value={nameWidget}
								onChange={(e) => setNameWidget(e.target.value)}
								onPressEnter={handleSaveName}
							/>
							<StyledActionName>
								<StyledIconTag
									onClick={handleSaveName}
									color={THEME.colors.orangeBase}
									icon={<IconCheck />}
								/>
								<StyledIconTag
									onClick={() => setIsEdit(false)}
									color={THEME.colors.orangeBase}
									icon={
										<IconClose
											onClick={() => {
												setIsEdit(false);
												setNameWidget(name);
											}}
										/>
									}
								/>
							</StyledActionName>
						</StyledWrapperInput>
					) : (
						<StyledName>
							{nameWidget}
							<StyledIconEdit onClick={() => setIsEdit(true)} />
						</StyledName>
					)}
				</StyledLeftInfo>
				<StyledSelect
					notFoundContent={notFoundContent()}
					defaultValue={optionView[0].value}
					onChange={onChangeView}
					width="170px"
					placeholder={t('content.widget_manager.placeholder')}>
					{optionView.map((item: any) => {
						return (
							<Option key={item?.value} value={item.value}>
								{item?.label}
							</Option>
						);
					})}
				</StyledSelect>
			</StyledHeaderPreview>
			<StyledWrapperPreviewDetailWidget className="custom_scroll_bar">
				<DetailWidget
					detailWidget={widgetPreview}
					isMobile={isMobile}
					currentStep={currentStep}
				/>
			</StyledWrapperPreviewDetailWidget>
		</StyledPreviewDetailWidget>
	);
};

export default PreviewDetailWidget;
