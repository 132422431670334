import styled from 'styled-components';
import { Row, Col, Tabs, Drawer, Empty, Layout } from 'antd';
import Wrapper from '@cores/Wrapper';
import Button from '@cores/Button';
import THEME from '@constants/themes/themes';

const { Sider, Header } = Layout;

const StyledContent = styled.div`
	&.slide_right_left {
		@keyframes slide-right {
			from {
				margin-left: 100%;
			}
			to {
				margin-left: 0%;
			}
		}
		position: relative;
		animation: 0.4s slide-right;
	}
`;

const StyledHeader = styled(Header)`
	padding: 0 20px 0 0;
	height: unset;
`;

const StyledRow = styled(Row)`
	margin-right: 0 !important;
`;

const StyledCol = styled(Col)<{ background?: boolean; padding?: string; margintop?: string }>`
	background-color: ${({ background }) => (background ? THEME.colors.darkBlue : 'unset')};
	padding: ${({ padding }) => `${padding} !important` || 'unset'};
	margin-top: ${({ margintop }) => margintop || 'unset'};
`;

const StyledSection = styled.div<{
	borderPosition?: string;
	heightSection?: string;
	flexDirection?: string;
	fontSize?: string;
	padding?: string;
}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: ${({ padding }) => padding || '16px 24px'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	height: ${({ heightSection }) => heightSection || 'auto'};
	flex-direction: ${({ flexDirection }) => flexDirection || 'unset'};
	${({ borderPosition }) =>
		borderPosition && `border-${borderPosition}:  1px solid ${THEME.colors.darkBlue3}`}
`;

const StyledWrapper = styled(Wrapper)`
	margin-top: 16px;
	height: 60vh;
	background-color: ${THEME.colors.darkBlue};
`;

const StyledTabs = styled(Tabs)`
	background: ${THEME.colors.darkBlue};
	margin: 0;
	height: calc(100vh - 98px);
	position: relative;

	.ant-tabs-nav {
		margin-bottom: 0 !important;
		border-bottom: 1px solid ${THEME.colors.darkBlue3};

		.ant-tabs-nav-list {
			padding-right: 12px;
		}
		.ant-tabs-tab {
			padding: 12px 8px;
			background: none;
			border: none;
			border-bottom: 2px solid transparent;
			margin: 0;

			&.ant-tabs-tab-active {
				border-bottom: 2px solid ${THEME.colors.primary};
				opacity: 1;

				.ant-tabs-tab-btn {
					color: ${THEME.colors.gray5};
				}
			}
		}

		.ant-tabs-nav-add {
			background: none;
			border: none;
		}

		&:before {
			border-bottom: none;
		}

		.ant-tabs-nav-wrap {
			padding-left: 15px;
			height: 56px;
		}
	}

	.ant-tabs-content {
		height: calc(100vh - 140px);
		/* overflow-y: hidden; */
	}
`;

const StyledDrawer = styled(Drawer)`
	.ant-drawer-content-wrapper {
		width: 420px !important;

		.ant-drawer-content {
			.ant-drawer-body {
				padding: 0;
			}
		}
	}
`;

const StyledBtn = styled(Button)`
	width: 120px;
`;

const StyledDrawerAction = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 54px;
`;

const StyledTitle = styled.div<{
	fontSize?: string;
	color?: string;
	margin?: string;
	cursor?: string;
	border?: string;
	fontWeight?: string;
}>`
	display: flex;
	align-items: center;
	height: 24px;
	font-stretch: normal;
	font-style: normal;
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	line-height: 1.429rem;
	letter-spacing: normal;
	margin: ${({ margin }) => margin || '0'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	cursor: ${({ cursor }) => cursor};
	${({ border }) => border && `border-${border}: 1px solid ${THEME.colors.darkBlue3}`}

	svg {
		width: 20px;
		margin-right: 6px;
	}
`;

const StyledEmpty = styled(Empty)`
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const StyledSider = styled(Sider)`
	margin-top: 50px;
	margin-left: 16px;
`;

export {
	StyledContent,
	StyledRow,
	StyledCol,
	StyledWrapper,
	StyledSection,
	StyledTabs,
	StyledDrawer,
	StyledBtn,
	StyledDrawerAction,
	StyledTitle,
	StyledEmpty,
	StyledSider,
	StyledHeader,
};
