import { IconSearch } from '@assets/icons';
import { CREATOR_GET_CREATOR_LIST_URL } from '@constants/APIs';
import { KEYBOARD, LIMIT, ROUTE_PATH, TIME_OUT } from '@constants/common';
import { DEFAULT_ERROR } from '@constants/errors';
import { LoadingWrapper } from '@cores/index';
import { putRequest } from '@helpers/requestHelpers';
import { SummaryDto } from '@models/creator/summary';
import { StyledAutoComplete, StyledIcon, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { convertParamSearch } from '@utils/common';
import { notFoundContent } from '@utils/renderComponent';
import { Input, message } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const StyledInput = styled(Input)``;

interface OptionType extends SummaryDto {
	value: number;
	label: ReactNode;
}

const SearchCreator = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const refTime = useRef<any>({});
	const refCancelToken = useRef<CancelTokenSource | undefined>(undefined);
	const [options, setOptions] = useState<OptionType[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [value, setValue] = useState<string | undefined>(undefined);
	const [valueSearch, setValueSearch] = useState<string | undefined>(undefined);
	const [visible, setVisible] = useState<boolean>(false);

	const handleCancelApi = () => {
		if (refCancelToken.current) {
			refCancelToken.current.cancel();
		}
	};

	const handleFetchCreator = (search: string) => {
		handleCancelApi();
		refCancelToken.current = axios.CancelToken.source();

		const param = {
			search,
			maxRecords: 1000,
			page: 1,
		};

		const url = `${CREATOR_GET_CREATOR_LIST_URL}?${convertParamSearch({ __a: 1 })}`;
		putRequest(
			url,
			param,
			undefined,
			undefined,
			undefined,
			undefined,
			refCancelToken.current.token,
		)
			.then((response) => {
				if (response.body) {
					if (response.status === 200) {
						const data: SummaryDto[] = response.body.data?.result?.records || [];
						setOptions(
							data.map((item) => ({
								...item,
								value: item?.creatorId,
								label: funcRenderName(item, search),
							})),
						);
					} else {
						message.error(response.body.data.error || DEFAULT_ERROR);
					}
				}
			})
			.finally(() => {
				refCancelToken.current = undefined;
				setLoading(false);
			});
	};

	const onChange = (val: string, noTimeout?: boolean) => {
		const valTrim = val ? val.trim() : '';

		setValueSearch(val);
		setValue(val);
		setOptions([]);
		handleCancelApi();

		if (refTime.current) {
			clearTimeout(refTime.current);
		}
		if (valTrim && valTrim.length >= LIMIT.MIN_SEARCH) {
			const handleFetch = () => {
				setLoading(true);
				handleFetchCreator(valTrim);
			};
			if (noTimeout) {
				handleFetch();
			} else {
				refTime.current = setTimeout(() => {
					handleFetch();
				}, TIME_OUT.WAIT_SEARCH);
			}
		}
	};

	const onKeyDown = (e: any) => {
		if (e.key === KEYBOARD.ENTER.STR) {
			if (!loading && options?.length === 0) {
				const valTrim = e.target?.value ? e.target.value.trim() : '';

				onChange(valTrim, true);
				e.preventDefault();
				e.stopPropagation();
			}
		}
	};

	const onSelect = (val: any, item: any) => {
		setValue(undefined);

		setTimeout(() => {
			history.push(`${ROUTE_PATH.CREATOR_DETAIL_EMPTY}${item?.creatorId}`);
		}, 0);
	};

	const funcRenderName = (option: SummaryDto, search: string) => {
		const { firstName, lastName, email, instagramUsername, twitterUsername, tiktokUsername } =
			option;
		if (!search) {
			return option.email;
		}

		const obj: any = {
			email,
			firstName,
			lastName,
			instagramUsername,
			twitterUsername,
			tiktokUsername,
		};
		const keyFind = Object.keys(obj).find((key) => obj[key]?.toLowerCase()?.includes(search));
		if (keyFind) {
			return obj[keyFind];
		}

		return option.email;
	};

	return (
		<StyledWrapperContent margin="0 0 0 5px">
			<StyledAutoComplete
				searchValue={value === undefined ? '' : value}
				value={valueSearch === undefined ? '' : valueSearch}
				options={options}
				style={{ width: 250 }}
				open={visible}
				onSelect={onSelect}
				onSearch={(val) => onChange(val)}
				notFoundContent={notFoundContent(t)}
				onDropdownVisibleChange={setVisible}
				dropdownRender={(menu) => (
					<LoadingWrapper sizeLoading={'small'} isLoading={loading}>
						{menu}
					</LoadingWrapper>
				)}
				onInputKeyDown={onKeyDown}>
				<StyledInput
					value={valueSearch}
					suffix={
						<StyledIcon size={16}>
							<IconSearch />
						</StyledIcon>
					}
					placeholder={t('creator_summary.jump_another_profile')}
				/>
			</StyledAutoComplete>
		</StyledWrapperContent>
	);
};

export default SearchCreator;
