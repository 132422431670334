import {
	IconInstagram,
	IconTikTok,
	IconTwitterWhite,
	IconYoutube,
	IconYoutubeBase,
} from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { SOCIAL } from '@constants/social_search';
import THEME from '@constants/themes/themes';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import {
	StyledCol,
	StyledIconWrapper,
	StyledLink,
	StyledRow,
	StyledText,
} from '@styled/Common/CommonStyled';
import { goToSocialProfile, handleRedirectLink } from '@utils/common';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ContentInfoDto = {
	title: string;
	info: string | number | null | undefined;
};

type PropTypes = {
	permalink?: string;
	username: string;
	activeSocialType: string;
	timestamp?: number;
};

const SocialProfile = (props: PropTypes) => {
	const { t } = useTranslation();
	const { permalink, username, activeSocialType, timestamp } = props;
	const onViewPost = () => {
		if (!permalink) return;
		handleRedirectLink(permalink);
	};

	const personalInfo = [
		{
			title: t('social_search.details.channel'),
			info:
				activeSocialType?.toLowerCase() === SOCIAL.TIKTOK.toLowerCase()
					? SOCIAL.TIKTOK
					: activeSocialType,
		},
		{
			title: t('social_search.details.username'),
			info: username,
		},
		{
			title: t('social_search.details.post_date'),
			info: timestamp
				? convertUtcToLocalTimeWithFormat(timestamp, TYPE_FORMAT_DATE.TIME)
				: '',
		},
	];

	const renderSocialIcon = () => {
		switch (activeSocialType) {
			case SOCIAL.INSTAGRAM.toLowerCase():
				return <IconInstagram />;
			case SOCIAL.TIKTOK.toLowerCase():
				return <IconTikTok />;
			case SOCIAL.YOUTUBE.toLowerCase():
				return <IconYoutubeBase />;
			default:
				return <IconTwitterWhite />;
		}
	};

	return (
		<StyledRow>
			<StyledCol span={6}>
				<StyledIconWrapper
					cursor="pointer"
					onClick={onViewPost}
					width="70px"
					className={'icon_hover'}
					height="70px">
					{renderSocialIcon()}
				</StyledIconWrapper>
			</StyledCol>
			<StyledCol span={18}>
				{personalInfo.map((item: ContentInfoDto) => {
					const { title, info } = item;
					let className = 'overflow ';

					if (title === t('social_search.details.channel')) {
						className += 'social-type';
					}

					return (
						<StyledRow key={title}>
							<StyledCol span={8}>
								<StyledText color={THEME.colors.gray1}>{title}</StyledText>
							</StyledCol>
							<StyledCol span={16}>
								{title === t('social_search.details.username') ? (
									<StyledLink
										className={className}
										onClick={
											activeSocialType
												? () =>
														goToSocialProfile(
															username,
															activeSocialType,
														)
												: null
										}
										underline={true}
										color={THEME.colors.blueBase}>
										{info}
									</StyledLink>
								) : (
									<StyledText className={className}>{info}</StyledText>
								)}
							</StyledCol>
						</StyledRow>
					);
				})}
			</StyledCol>
		</StyledRow>
	);
};

export default SocialProfile;
