/* eslint-disable import/no-mutable-exports */
declare global {
  interface Window {
    env: any;
  }
}

const domain = window.location.origin;
const env = window.env ? window.env : process.env;

let { REACT_APP_API_URL } = process.env;

if (env.REACT_APP_NODE_ENV === 'production') {
  REACT_APP_API_URL = `${domain}`;
}

export { REACT_APP_API_URL };
