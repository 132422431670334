import ActivationEmailModal from '@components/Campaigns/ActivationEmailModal';
import CTAContainer from '@components/Campaigns/CampaignDetail/CTA';
import Detail from '@components/Campaigns/CampaignDetail/Detail';
import PreviewEmailTemplate from '@components/Campaigns/CampaignDetail/Detail/PreviewEmailTemplate';
import {
	CAMPAIGN_DETAIL_FIELD_NAME,
	CAMPAIGN_DYNAMIC_FIELD,
	CREATOR_ACTIONS,
	DISABLED_FIELDS,
	MODAL_ACTION_TYPES,
	RANGE_GOALS,
	SIZE_CTA,
	TAB,
	TOTAL_SIZE,
} from '@constants/campaign';
import {
	FULL_RECORD,
	MESSAGE_TYPE,
	PARAMS_DEFAULT,
	ROUTE_PATH,
	SUB_PATH,
	TYPE_ACTIONS,
	TYPE_FILE,
} from '@constants/common';
import { DEFAULT_PAYLOAD_GETTING_LIST } from '@constants/landingPageManager';
import { CONFIG_TITLE } from '@constants/messages';
import { SPECIAL_CHARS } from '@constants/settings';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import CampaignDetailContext from '@contexts/Campaign/CampaignDetail';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { getUser } from '@helpers/userHelpers';
import {
	CampaignDetailFormDto,
	CampaignDto,
	CampaignEmailTemplateDto,
	CampaignRequestPayload,
	ContentImgDto,
	ImgCampaignDto,
	ItemCamPaign,
	MetadataCampaignDto,
} from '@models/campaign';
import { DynamicFieldValueDto, WidgetLinkDto } from '@models/common/summary';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import {
	clientSettingsRequest,
	createCampaignAPIEnded,
	createCampaignAPIRequest,
	deleteCampaignAPIEnded,
	deleteCampaignAPIRequest,
	fetchHashTagRequest,
	fetchListFeedRequest,
	getDetailCampaignRequest,
	getEmailTemplatesRequest,
	getLandingPageListLiteRequest,
	getListCampaignSimpleRequest,
	resetSocialSearchSetting,
	saveCampaignDetail,
	suggestImgCampaignEnded,
	suggestImgCampaignRequest,
	updateCampaignEnded,
	updateCampaignRequest,
	uploadImgsCampaignEnded,
	uploadImgsCampaignRequest,
} from '@stores/actions';
import {
	createCampaignDynamicFieldValue,
	formatCampaignEmailTemplateList,
	handleConvertHash,
	hasChangeInCampaignDetail,
} from '@utils/campaign';
import { dataURLtoFile, getSocialS3URL } from '@utils/common';
import { captureDOM, getUrlImgBase } from '@utils/funcHelper';
import { generalMessage } from '@utils/renderComponent';
import { Col, Form, Row, message } from 'antd';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CampaignDetail = () => {
	const history = useHistory();
	const { pathname } = history?.location;

	const { t } = useTranslation();

	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const {
		campaignDetail,
		loadingCampaignDetail,
		createCampaignStatus,
		deleteCampaignStatus,
		updateCampaignStatus,
		suggestImgStatus,
		uploadImgsCampaignStatus,
		imgsCampaignUpload,
		imgSuggestCampaign,
		error,
	}: CampaignDto = useSelector((state: any) => state.campaign);
	const { emailTemplate: { getEmailTemplateStatus = false, allEmailTemplates = [] } = {} } =
		useSelector((state: any) => state.messages);
	const { fetchingCampaignSimple } = useSelector((state: any) => state.creator);
	const { folderGallery } = useSelector((state: any) => state.galleryManager);

	const { widgetLinks = [] } = clientSettings;

	const widgetDefault = useMemo(() => {
		return widgetLinks?.find((item: WidgetLinkDto) => item?.isDefault);
	}, [widgetLinks]);

	const defaultForm: any = {
		name: '',
		description: '',
		rangeDate: [],
		hashtags: [],
		fileImage: null,
		widgetId: widgetDefault?.id,
		creatorsGoals: RANGE_GOALS.CREATOR,
		submissionsGoals: RANGE_GOALS.SUBMISSION,
		socialPostsGoals: RANGE_GOALS.SOCIAL_POSTS,
		[CAMPAIGN_DETAIL_FIELD_NAME.CONFIRMATION_TEMPLATE_ID]: 0,
		[CAMPAIGN_DETAIL_FIELD_NAME.REJECTION_TEMPLATE_ID]: 0,
		[CAMPAIGN_DETAIL_FIELD_NAME.COMMUNITY_ID]: 0,
	};

	const user = getUser();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hashtagFooter, setHashtagFooter] = useState<string[]>([]);
	const [typeCampaign, setTypeCampaign] = useState<string>('');
	const [img1, setImg1] = useState<ContentImgDto | null>(null);
	const [img2, setImg2] = useState<ContentImgDto | null>(null);
	const [img3, setImg3] = useState<ContentImgDto | null>(null);
	const [headerVal, setHeaderVal] = useState<string>('');
	const [descVal, setDescVal] = useState<string>('');
	const [typeUpdate, setTypeUpdate] = useState<string>('');
	const [typeAction, setTypeAction] = useState<string>('');
	const [suggestIds, setSuggestIds] = useState<number[]>([]);
	const [idxSuggest, setIdxSuggest] = useState<number>(-1);
	const [isRestore, setIsRestore] = useState<boolean>(false);
	const [creatorActions, setCreatorActions] = useState<string[]>([
		CREATOR_ACTIONS.UPLOAD_CONTENT_DIRECTLY,
	]);
	const [campaignEmailTemplate, setCampaignEmailTemplate] = useState<CampaignEmailTemplateDto>();
	const [previewTemplate, setPreviewTemplate] = useState<EmailTemplatesDataDto | null>(null);
	const [disabledFields, setDisabledFields] = useState<string[]>([]);
	const [hasChange, setHasChange] = useState<boolean>(false);

	useEffect(() => {
		dispatch(fetchHashTagRequest());
		dispatch(clientSettingsRequest());
		dispatch(fetchListFeedRequest({ ...PARAMS_DEFAULT, maxRecords: FULL_RECORD }));
		dispatch(
			getLandingPageListLiteRequest({
				...DEFAULT_PAYLOAD_GETTING_LIST,
				maxRecords: FULL_RECORD,
			}),
		);
		handleGetAllTemplates();

		return () => {
			setTypeCampaign('');
			handleClear();
			dispatch(resetSocialSearchSetting({ listFeedActive: [], listFeed: [] }));
		};
	}, []);

	useEffect(() => {
		const listStatus = [
			createCampaignStatus,
			updateCampaignStatus,
			deleteCampaignStatus,
			suggestImgStatus,
		];
		if (listStatus?.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus?.includes(FAILED)) {
			generalMessage(error || '', MESSAGE_TYPE.ERROR);
		}
		if (listStatus?.includes(SUCCEEDED) || listStatus?.includes(FAILED)) {
			setIsLoading(false);
		}
	}, [createCampaignStatus, updateCampaignStatus, deleteCampaignStatus, suggestImgStatus]);

	useEffect(() => {
		if (createCampaignStatus === SUCCEEDED || createCampaignStatus === FAILED) {
			dispatch(createCampaignAPIEnded());
		}

		if (createCampaignStatus === SUCCEEDED) {
			handleMessageCreate();
			handleRedirectCampaignPage(typeUpdate);
		}
	}, [createCampaignStatus]);

	useEffect(() => {
		if (updateCampaignStatus === SUCCEEDED || updateCampaignStatus === FAILED) {
			setHasChange(false);
			dispatch(updateCampaignEnded());
		}

		if (updateCampaignStatus === SUCCEEDED) {
			handleMessageCreate();
		}
	}, [updateCampaignStatus]);

	useEffect(() => {
		if (deleteCampaignStatus === SUCCEEDED || deleteCampaignStatus === FAILED) {
			dispatch(deleteCampaignAPIEnded());
		}

		if (deleteCampaignStatus === SUCCEEDED) {
			message.success(t('campaign.message.delete'));
			handleRedirectCampaignPage();
		}
	}, [deleteCampaignStatus]);

	useEffect(() => {
		if (suggestImgStatus === SUCCEEDED && imgSuggestCampaign) {
			handleChangeImgSuggest();
		}
		if (suggestImgStatus === SUCCEEDED || suggestImgStatus === FAILED) {
			dispatch(suggestImgCampaignEnded());
		}
	}, [suggestImgStatus]);

	useEffect(() => {
		if (uploadImgsCampaignStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (uploadImgsCampaignStatus === SUCCEEDED) {
			form.submit();
		}
		if (uploadImgsCampaignStatus === FAILED) {
			setIsLoading(false);
			message.error(error);
		}
		if (uploadImgsCampaignStatus === SUCCEEDED || uploadImgsCampaignStatus === FAILED) {
			dispatch(uploadImgsCampaignEnded());
		}
	}, [uploadImgsCampaignStatus]);

	useEffect(() => {
		const id = pathname?.slice(pathname.lastIndexOf('/') + 1);
		if (pathname && id) {
			if (id === SUB_PATH.CREATE) {
				dispatch(getListCampaignSimpleRequest({ status: TAB.inactive.key }));
				setTypeCampaign(TYPE_ACTIONS.CREATE);
			} else {
				setIsLoading(true);
				dispatch(getDetailCampaignRequest({ campaignId: id }));
			}
		}

		return () => {
			handleClear();
			setTypeCampaign('');
			setCreatorActions([CREATOR_ACTIONS.UPLOAD_CONTENT_DIRECTLY]);
		};
	}, [pathname]);

	useEffect(() => {
		handleFieldDetail();
		setIdxSuggest(-1);
	}, [campaignDetail, allEmailTemplates]);

	useEffect(() => {
		if (campaignDetail?.status && campaignDetail?.name) {
			setIsLoading(false);
			dispatch(getListCampaignSimpleRequest({ status: campaignDetail?.status }));
		}
	}, [campaignDetail?.status, campaignDetail?.name]);

	useEffect(() => {
		if (!getEmailTemplateStatus && allEmailTemplates.length) {
			const listTemp = formatCampaignEmailTemplateList(allEmailTemplates);
			setCampaignEmailTemplate(listTemp);
		}
	}, [allEmailTemplates, getEmailTemplateStatus]);

	const activationTemplate = useMemo(() => {
		return allEmailTemplates?.find(
			(item: EmailTemplatesDataDto) => item.configTitle === CONFIG_TITLE.CAMPAIGN_ACTIVATE,
		);
	}, [allEmailTemplates]);

	const handleGetAllTemplates = () => {
		dispatch(getEmailTemplatesRequest());
	};

	const handleFieldDetail = () => {
		if (campaignDetail) {
			const {
				name = '',
				description,
				hashtags,
				widgetId,
				submissionsGoals,
				creatorsGoals,
				status,
				metadata,
				socialPostsGoals,
				mentionUsernames,
				confirmationTemplateId = 0,
				rejectionTemplateId = 0,
				activationTemplateId,
				projectName,
				jobNumber,
				community,
			} = campaignDetail;

			let header = '';

			if (status) {
				setTypeCampaign(status);
			}
			let imgChange1 = null;
			let imgChange2 = null;
			let imgChange3 = null;

			const arrIds: number[] = [];
			const disabledFieldsTemp: string[] = [];
			const templateIds = [
				CAMPAIGN_DETAIL_FIELD_NAME.CONFIRMATION_TEMPLATE_ID,
				CAMPAIGN_DETAIL_FIELD_NAME.REJECTION_TEMPLATE_ID,
				CAMPAIGN_DETAIL_FIELD_NAME.ACTIVATION_TEMPLATE_ID,
			];

			if (metadata) {
				try {
					const metadataObj: MetadataCampaignDto = JSON.parse(metadata);
					header = metadataObj?.header || '';
					imgChange1 = metadataObj?.content?.img1 || null;
					imgChange2 = metadataObj?.content?.img2 || null;
					imgChange3 = metadataObj?.content?.img3 || null;

					const arrImgs = [
						metadataObj?.content?.img1 || null,
						metadataObj?.content?.img1 || null,
						metadataObj?.content?.img1 || null,
					];
					arrImgs?.forEach((item) => {
						if (item?.id) {
							arrIds.push(item.id);
						}
					});
				} catch (error) {
					console.log(error);
				}
			}

			const terms: string[] = [];
			const hashtagsTemp = hashtags ? handleConvertHash(hashtags) : [];
			const mentionUsernamesTemp = mentionUsernames
				? handleConvertHash(mentionUsernames)
				: [];

			if (hashtagsTemp.length) {
				hashtagsTemp.forEach((item) => {
					item = `${SPECIAL_CHARS.HASH}${item}`;
					terms.push(item);
				});
			}
			if (mentionUsernamesTemp.length) {
				mentionUsernamesTemp.forEach((item) => {
					item = `${SPECIAL_CHARS.AT}${item}`;
					terms.push(item);
				});
			}

			if (terms.length && !creatorActions.includes(CREATOR_ACTIONS.POST_TAG)) {
				setCreatorActions([...creatorActions, CREATOR_ACTIONS.POST_TAG]);
			}

			templateIds.forEach((item) => {
				if (!campaignDetail[item]) {
					disabledFieldsTemp.push(item);
				}
			});

			const descFormat = (description || '')?.slice(0, TOTAL_SIZE.DESCRIPTION) || '';

			const fieldValues: CampaignDetailFormDto = {
				name,
				description: descFormat,
				widgetId,
				submissionsGoals: submissionsGoals || 0,
				creatorsGoals: creatorsGoals || 0,
				socialPostsGoals: socialPostsGoals || 0,
				header,
				terms,
				confirmationTemplateId,
				rejectionTemplateId,
				activationTemplateId: activationTemplateId || activationTemplate?.id || 0,
				projectName,
				jobNumber,
			};

			fieldValues[CAMPAIGN_DETAIL_FIELD_NAME.COMMUNITY_ID] = community?.id || 0;

			form.setFieldsValue({
				...fieldValues,
			});

			const handleImg = (
				val: ImgCampaignDto | null,
				func: (val: ContentImgDto | null) => void,
			) => {
				if (val) {
					const urlBase = getUrlImgBase(val?.url);

					func({
						...val,
						urlBase,
						url: getSocialS3URL(urlBase),
					});
				}
			};

			handleImg(imgChange1, setImg1);
			handleImg(imgChange2, setImg2);
			handleImg(imgChange3, setImg3);

			if (terms) {
				setHashtagFooter(terms);
			}
			setHeaderVal(header);
			setDescVal(descFormat);
			setSuggestIds(arrIds);
			setDisabledFields(disabledFieldsTemp);
		} else {
			const defaultDisabledFields = [
				CAMPAIGN_DETAIL_FIELD_NAME.CONFIRMATION_TEMPLATE_ID,
				CAMPAIGN_DETAIL_FIELD_NAME.REJECTION_TEMPLATE_ID,
				CAMPAIGN_DETAIL_FIELD_NAME.COMMUNITY_ID,
			];
			setImg1(null);
			setImg2(null);
			setImg3(null);
			setHashtagFooter([]);
			setHeaderVal('');
			setDescVal('');
			setSuggestIds([]);
			form.setFieldsValue({
				[CAMPAIGN_DETAIL_FIELD_NAME.ACTIVATION_TEMPLATE_ID]: activationTemplate?.id || 0,
			});
			if (!activationTemplate) {
				defaultDisabledFields.push(CAMPAIGN_DETAIL_FIELD_NAME.ACTIVATION_TEMPLATE_ID);
			}
			setDisabledFields(defaultDisabledFields);
		}
	};

	const handleMessageCreate = () => {
		let valMessage = t('campaign.message.update');

		switch (typeUpdate) {
			case TAB.archives.key:
				valMessage = t('campaign.message.archive');
				break;
			case TAB.inactive.key:
				valMessage = t('campaign.message.save_draft');
				break;
			case TAB.active.key:
				valMessage = t('campaign.message.go_live');
				break;
			default:
				break;
		}
		if (isRestore) {
			valMessage = t('campaign.message.restore');
		}
		message.success(valMessage);
	};

	const handleRedirectCampaignPage = (status?: string) => {
		// timeout action after event state
		setTimeout(() => {
			history.push({
				pathname: ROUTE_PATH.CAMPAIGNS,
				state: { status: status || campaignDetail?.status },
			});
		}, 0);
	};

	const handleChangeImgSuggest = () => {
		if (imgSuggestCampaign) {
			handleUpdateSuggestId(imgSuggestCampaign.id);
			const url = getSocialS3URL(imgSuggestCampaign.thumbnail);
			const objImg = {
				id: imgSuggestCampaign.id,
				urlBase: imgSuggestCampaign.thumbnail,
				url,
			};
			if (idxSuggest === 0) {
				setImg1(objImg);
			}
			if (idxSuggest === 1) {
				setImg2(objImg);
			}
			if (idxSuggest === 2) {
				setImg3(objImg);
			}
		}
	};

	const handleUpdateSuggestId = (id: number) => {
		const ids = suggestIds?.filter((item) => item !== id);
		ids.push(id);
		setHasChange(true);
		setSuggestIds([...ids]);
	};

	const handleClear = () => {
		dispatch(saveCampaignDetail(null));
		handleReset();
	};

	const handleReset = () => {
		form.resetFields();
		handleFieldDetail();
		setIdxSuggest(-1);
		setHasChange(false);
	};

	const handleSubmit = (type: string, restore?: boolean) => {
		setTypeUpdate(type);
		setIsRestore(!!restore);
		handleValidateForm();
	};

	const handleCheckIdx = () => {
		const arr = [
			{ val: img1, key: 0 },
			{ val: img2, key: 1 },
			{ val: img3, key: 2 },
		];
		const arrSuggest = arr?.filter((item) => item?.val);

		let result = 0;
		if (arrSuggest?.length === 1) {
			result = arrSuggest[0].key;
		}
		if (arrSuggest.length > 1) {
			const itemFind = arrSuggest?.find((item) => item?.key > idxSuggest);
			if (itemFind) {
				result = itemFind.key;
			} else {
				result = arrSuggest[0].key;
			}
		}

		return result;
	};

	const handleSuggestImg = () => {
		setIdxSuggest(handleCheckIdx());
		dispatch(suggestImgCampaignRequest({ ids: suggestIds }));
	};

	const handleUploadImgsCampaign = ({
		urlShare,
		urlPost,
	}: {
		urlShare: string;
		urlPost: string;
	}) => {
		const nameCp =
			form.getFieldValue('name') || campaignDetail?.name || t('campaign_detail.campaign');
		const nameFile1 =
			t('campaign_detail.name_mockup_1', {
				name: nameCp,
			}) +
			Math.round(Math.random() * 10 ** 20).toString() +
			TYPE_FILE.PNG;
		const nameFile2 =
			t('campaign_detail.name_mockup_2', {
				name: nameCp,
			}) +
			Math.round(Math.random() * 10 ** 20).toString() +
			TYPE_FILE.PNG;
		const fileShare = dataURLtoFile(urlShare, nameFile1);
		const filePost = dataURLtoFile(urlPost, nameFile2);
		const formData = new FormData();
		const arrCheck = [img1, img2, img3];
		formData.append('files', fileShare);
		formData.append('files', filePost);

		arrCheck.forEach((item, idx) => {
			if (item?.file) {
				formData.append('files', item.file);
			}
		});

		const params: any = { files: formData };
		if (campaignDetail?.id) {
			params.campaignId = campaignDetail?.id;
		}

		dispatch(uploadImgsCampaignRequest({ ...params }));
	};

	const convertHashtagMentionUsername = (data: string, specialChar: string) => {
		return data
			? data
					.split(',')
					?.map((item: string) => `${specialChar}${item}`)
					.toString()
			: '';
	};

	const handleCheckMockupChange = () => {
		if (campaignDetail) {
			let obj: MetadataCampaignDto | null = null;
			let imgChange1 = null;
			let imgChange2 = null;
			let imgChange3 = null;

			if (campaignDetail?.metadata) {
				try {
					obj = JSON.parse(campaignDetail?.metadata);
					imgChange1 = obj?.content?.img1 || null;
					imgChange2 = obj?.content?.img2 || null;
					imgChange3 = obj?.content?.img3 || null;
				} catch (error) {
					console.log(error);
				}
			}
			const values = form.getFieldsValue();
			const headerChange = values?.header !== (obj?.header || '');
			const descChange = campaignDetail?.description !== (values?.description || '');
			const arrHashtag = convertHashtagMentionUsername(
				campaignDetail?.hashtags,
				SPECIAL_CHARS.HASH,
			);
			const arrMentionUsername = convertHashtagMentionUsername(
				campaignDetail?.mentionUsernames,
				SPECIAL_CHARS.AT,
			);
			const hashTagMentionUsername = arrHashtag.concat(
				arrHashtag && arrMentionUsername ? ',' : '',
				arrMentionUsername,
			);

			const hashtagChange = hashTagMentionUsername !== hashtagFooter.toString();

			const checkChangeImg = (imgAfter: string, imgBefore: string) => {
				if (!imgBefore && !imgAfter) {
					return false;
				}
				if ((imgBefore && !imgAfter) || (!imgBefore && imgAfter)) {
					return true;
				}

				if (
					!(imgAfter?.includes('?v=') || imgAfter?.includes('&v=')) &&
					!imgAfter?.includes('https://')
				) {
					return true;
				} else {
					const url1 = imgAfter?.slice(
						0,
						imgAfter.indexOf('?v=') || imgAfter.indexOf('&v='),
					);
					const url2 = imgBefore?.slice(
						0,
						imgBefore.indexOf('?v=') || imgBefore.indexOf('&v='),
					);
					if (url1 !== url2) {
						return true;
					}
					return false;
				}
			};

			const imgChange =
				checkChangeImg(getSocialS3URL(imgChange1?.url), img1?.url || '') ||
				checkChangeImg(getSocialS3URL(imgChange2?.url), img2?.url || '') ||
				checkChangeImg(getSocialS3URL(imgChange3?.url), img3?.url || '');

			return headerChange || descChange || imgChange || hashtagChange;
		}
		return true;
	};

	const handleValidateForm = () => {
		form.validateFields()
			.then(() => {
				setIsLoading(true);
				const otherProps = { scale: SIZE_CTA.REQUIRED_WIDTH / SIZE_CTA.WIDTH };
				const hasChangeImg = handleCheckMockupChange();
				if (hasChangeImg) {
					const capturePost = (urlShare: string) => {
						captureDOM({
							id: 'mockup_post',
							name: t('campaign_detail.name_mockup_2', {
								name: '',
							}),
							otherProps,
							noDownload: true,
							callback: (urlPost: string) =>
								handleUploadImgsCampaign({ urlShare, urlPost }),
						});
					};

					captureDOM({
						id: 'mockup_share',
						name: t('campaign_detail.name_mockup_1', {
							name: '',
						}),
						otherProps,
						noDownload: true,
						callback: capturePost,
					});
				} else {
					form.submit();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const onFinishForm = (values: any) => {
		const {
			description,
			name,
			widgetId,
			submissionsGoals,
			creatorsGoals,
			socialPostsGoals,
			header,
			terms,
			confirmationTemplateId,
			rejectionTemplateId,
			activationTemplateId,
			projectName,
			jobNumber,
			communityId,
		} = values;

		const hasChangeImg = handleCheckMockupChange();

		let metadataRequest: MetadataCampaignDto | null = null;
		if (hasChangeImg) {
			const arrCheck = [img1, img2, img3];

			let arrVal = imgsCampaignUpload.slice(2);

			arrCheck?.forEach((item, idx: number) => {
				if (item?.file) {
					item.url = arrVal[0];
					delete item.file;
					delete item.urlBase;
					arrVal = arrVal.slice(1);
				} else {
					if (item?.urlBase) {
						item.url = item.urlBase;
						delete item.urlBase;
					}
				}
			});

			metadataRequest = {
				header,
				content: {
					img1: arrCheck[0],
					img2: arrCheck[1],
					img3: arrCheck[2],
				},
				urlShare: imgsCampaignUpload[0],
				urlPost: imgsCampaignUpload[1],
			};
		}

		const dataRequest: CampaignRequestPayload = {
			widgetId,
			creatorsGoals: creatorsGoals || 0,
			submissionsGoals: submissionsGoals || 0,
			description: description || '',
			name,
			status: typeUpdate || (campaignDetail ? campaignDetail.status : ''),
			metadata: hasChangeImg
				? JSON.stringify({ ...metadataRequest })
				: campaignDetail?.metadata,
			hashtags: '',
			mentionUsernames: '',
			socialPostsGoals: socialPostsGoals || RANGE_GOALS.SOCIAL_POSTS,
			confirmationTemplateId,
			rejectionTemplateId,
			activationTemplateId,
			projectName,
			jobNumber,
		};

		dataRequest.communityId = communityId || null;

		if (terms) {
			const hashtags: string[] = [];
			const mentionUsernames: string[] = [];

			terms.forEach((item: string) => {
				const itemSubstr = item.substring(1);
				if (item.startsWith(SPECIAL_CHARS.HASH)) {
					hashtags.push(itemSubstr);
				} else if (item.startsWith(SPECIAL_CHARS.AT)) {
					mentionUsernames.push(itemSubstr);
				}
			});
			if (hashtags.length) {
				dataRequest.hashtags = hashtags.toString();
			}

			if (mentionUsernames.length) {
				dataRequest.mentionUsernames = mentionUsernames.toString();
			}
		} else {
			// When the option of Post and tag social media is not selected
			dataRequest.socialPostsGoals = RANGE_GOALS.SOCIAL_POSTS;
		}

		if (typeCampaign === TYPE_ACTIONS.CREATE) {
			dispatch(
				createCampaignAPIRequest({
					dataRequest,
				}),
			);
		} else {
			dataRequest.id = campaignDetail?.id;
			dispatch(
				updateCampaignRequest({
					dataRequest,
				}),
			);
		}
	};

	const handleDeleteCampaign = () => {
		dispatch(deleteCampaignAPIRequest({ campaignId: campaignDetail?.id }));
	};

	const onChangeForm = (field: any) => {
		const key = Object.keys(field)[0];

		if (key === CAMPAIGN_DETAIL_FIELD_NAME.HEADER) {
			if (field[key]?.length > TOTAL_SIZE.HEADER) {
				form.setFieldsValue({ [key]: field[key].slice(0, TOTAL_SIZE.HEADER) });
			} else {
				setHeaderVal(field[key]);
			}
		}
		if (key === CAMPAIGN_DETAIL_FIELD_NAME.DESCRIPTION) {
			if (field[key]?.length > TOTAL_SIZE.DESCRIPTION) {
				form.setFieldsValue({ [key]: field[key].slice(0, TOTAL_SIZE.DESCRIPTION) });
			} else {
				setDescVal(field[key]);
			}
		}
		if (key === CAMPAIGN_DETAIL_FIELD_NAME.TERMS) {
			setHashtagFooter(field[key]);
		}
		if (DISABLED_FIELDS.includes(key)) {
			let disabledFieldsTemp = [...disabledFields];
			if (!field[key] && !disabledFieldsTemp.includes(key)) {
				disabledFieldsTemp.push(key);
			} else if (field[key]) {
				disabledFieldsTemp = disabledFieldsTemp.filter((item) => item !== key);
			}
			setDisabledFields(disabledFieldsTemp);
		}

		if (campaignDetail) {
			setHasChange(hasChangeInCampaignDetail(campaignDetail, field));
		}
	};

	const handleMockup = () => {
		const otherProps = { scale: SIZE_CTA.REQUIRED_WIDTH / SIZE_CTA.WIDTH };
		const nameCp =
			form.getFieldValue('name') || campaignDetail?.name || t('campaign_detail.campaign');
		captureDOM({
			id: 'mockup_share',
			name:
				t('campaign_detail.name_mockup_1', {
					name: nameCp,
				}) + TYPE_FILE.PNG,
			otherProps,
		});
		captureDOM({
			id: 'mockup_post',
			name:
				t('campaign_detail.name_mockup_2', {
					name: nameCp,
				}) + TYPE_FILE.PNG,
			otherProps,
		});
	};

	const renderContentPopupConfirm = () => {
		if (typeAction === TYPE_ACTIONS.REMOVE) {
			return <>{t('campaign.popup_delete')}</>;
		}

		return '';
	};

	const otherPropConfirm = useMemo(() => {
		const result: any = {};
		if (typeAction === TYPE_ACTIONS.REMOVE) {
			result.okText = t('button.delete');
			result.onSubmit = handleDeleteCampaign;
		}

		return result;
	}, [typeAction]);

	const onClose = () => {
		setTypeAction('');
	};

	const onPreviewTemplate = (fieldName: string) => {
		const templateId = form.getFieldValue(fieldName);
		if (allEmailTemplates) {
			const previewTemplate = allEmailTemplates.find(
				(item: EmailTemplatesDataDto) => item.id === templateId,
			);
			setPreviewTemplate(previewTemplate);
		}
	};

	const handleReplaceDynamicField = (
		campaignParam: ItemCamPaign,
		template: EmailTemplatesDataDto,
	) => {
		const templateTemp = _.cloneDeep(template);
		const hasReplacedDynamicField = Object.values(CAMPAIGN_DYNAMIC_FIELD).some((item: string) =>
			templateTemp?.body?.includes(item),
		);

		if (hasReplacedDynamicField) {
			const campaignDynamicFields = createCampaignDynamicFieldValue(
				campaignParam,
				t,
				widgetLinks,
			);
			if (campaignDynamicFields && templateTemp) {
				campaignDynamicFields.forEach((item: DynamicFieldValueDto) => {
					templateTemp.body = templateTemp.body.replaceAll(
						item.dynamicField,
						item?.value || '',
					);
				});
			}
		}

		return templateTemp.body;
	};

	const valueContext = {
		hashtagFooter,
		typeCampaign,
		handleReset,
		img1,
		img2,
		img3,
		setImg1,
		setImg2,
		setImg3,
		headerVal,
		descVal,
		handleRedirectCampaignPage,
		handleSubmit,
		setTypeAction,
		handleMockup,
		handleValidateForm,
		handleUpdateSuggestId,
		creatorActions,
		setCreatorActions,
		setHashtagFooter,
		campaignEmailTemplate,
		setPreviewTemplate,
		onPreviewTemplate,
		disabledFields,
		hasChange,
		setHasChange,
	};

	return (
		<CampaignDetailContext.Provider value={valueContext}>
			<LoadingWrapper
				isLoading={
					isLoading ||
					loadingCampaignDetail ||
					fetchingCampaignSimple ||
					getEmailTemplateStatus
				}>
				<ConfirmModal
					width={450}
					isOpen={!!typeAction && typeAction !== MODAL_ACTION_TYPES.SEND_ACTIVATION_EMAIL}
					onClose={onClose}
					{...otherPropConfirm}>
					{renderContentPopupConfirm()}
				</ConfirmModal>
				<Form
					form={form}
					onFinish={onFinishForm}
					layout="vertical"
					onValuesChange={onChangeForm}
					initialValues={defaultForm}
					requiredMark={false}>
					<Row gutter={[16, 16]}>
						<Col span={6}>
							<Detail form={form} />
						</Col>
						<Col span={18}>
							<CTAContainer handleSuggestImg={handleSuggestImg} form={form} />
						</Col>
					</Row>
				</Form>
			</LoadingWrapper>

			{campaignDetail && typeAction === MODAL_ACTION_TYPES.SEND_ACTIVATION_EMAIL && (
				<ActivationEmailModal
					width={'calc(100vw - 300px)'}
					onCancel={onClose}
					visible={typeAction === MODAL_ACTION_TYPES.SEND_ACTIVATION_EMAIL}
					footer={null}
					campaign={campaignDetail}
				/>
			)}

			{previewTemplate && (
				<PreviewEmailTemplate
					template={{
						...previewTemplate,
						campaignId: campaignDetail?.id,
						body: campaignDetail
							? handleReplaceDynamicField(campaignDetail, previewTemplate)
							: previewTemplate?.body,
					}}
					onClose={() => {
						setPreviewTemplate(null);
					}}
				/>
			)}
		</CampaignDetailContext.Provider>
	);
};

export default CampaignDetail;
