import { Rate, Tooltip } from 'antd';
import * as React from 'react';
import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import ActionList from '@components/Creators/Summary/ActionList';
import SummaryList from '@components/Creators/Summary/SummaryList';

// Context
import CreatorSummaryContext from '@contexts/Creators/Summary';

// Model
import {
	KeyValueSortColumnType,
	ParamSummaryContactDto,
	SummaryColumnsDto,
	SummaryDto,
} from '@models/creator/summary';

// Styled
import { IconNoteMessage } from '@assets/icons';
import InvalidFlag from '@components/Creators/Summary/InvalidFlag';
import { DEFAULT_WIDTH_COL, ROUTE_PATH, TYPE_FORMAT_DATE } from '@constants/common';
import { SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import { STATE_TYPE } from '@constants/messages';
import { Paginator as defaultPage } from '@constants/paginator';
import THEME from '@constants/themes/themes';
import Header from '@cores/Header';
import { ConfirmModal } from '@cores/Modal';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { hanldeRoundAvgStar } from '@helpers/formatAvgRatingStar';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import { StatusCreatorTypes } from '@models/creator/profile';
import { UserStoreType } from '@stores/creator/creator.types';
import { StyledFlex, StyledIcon } from '@styled/Common/CommonStyled';
import { abbreviateNumber, replaceAtChar } from '@utils/common';
import history from '@utils/history';
import { renderIconCreatorStatus, renderCopiedText as renderText } from '@utils/renderComponent';
import _ from 'lodash';

type SummaryProps = {
	t: any;
	creator: UserStoreType;
	content: ContentStoreDto;
	exportProfiles: (payload: any) => void;
	creatorChangeStatus: (payload: any) => void;
	creatorBlock: (payload: any) => void;
	creatorDelete: (payload: any) => void;
	creatorImport: (payload: any) => void;
};

type SummaryStates = {
	columns: SummaryColumnsDto[];
	toggleColumns: SummaryColumnsDto[];
	selectedRow: Array<any>;
	isSelectAll: boolean;
	paramSummaryContact: ParamSummaryContactDto;
	contentModal: string;
	isConfirmModal: boolean;
	isLoading: boolean;
	okText: string;
	isBlock: boolean;
	onSubmit: () => void | null;
	selectedCreatorList: SummaryDto[];
};

const StyledRate = styled(Rate)`
	.ant-rate-star-full {
		svg {
			fill: ${THEME.colors.yellowBase};
		}
	}
	.ant-rate-star-zero {
		svg {
			display: none;
		}
	}
`;

const renderEmail = (email: string, record: SummaryDto) => {
	const { status } = record || {};
	return (
		<StyledFlex justify="flex-start">
			{status?.invalidEmail && (
				<StyledIcon
					onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
					cursor="default"
					margin="0 8px 0 0"
					fillPath="rgb(234, 67, 53)">
					<IconNoteMessage />
				</StyledIcon>
			)}
			{renderText(email)}
		</StyledFlex>
	);
};

const renderStatus = (statusObj: StatusCreatorTypes, t: any) => {
	const itemIcon = renderIconCreatorStatus(
		{
			active: statusObj.active,
			blocked: statusObj.blocked,
			followed: statusObj.followed,
			newRecently: statusObj.new,
			unsubscribed: statusObj.unsubscribed,
		},
		t,
	);
	return (
		<Tooltip title={itemIcon?.title}>
			<StyledIcon
				onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
				cursor="default"
				size={20}>
				{itemIcon?.icon}
			</StyledIcon>
		</Tooltip>
	);
};

class CreatorsSummary extends PureComponent<SummaryProps, SummaryStates> {
	constructor(props: SummaryProps | Readonly<SummaryProps>) {
		super(props);
		const { t, content } = this.props;
		this.state = {
			columns: [],
			paramSummaryContact: {
				page: 1,
				maxRecords: defaultPage.pageSize,
				search: '',
			},
			toggleColumns: [
				{
					title: '',
					dataIndex: 'status',
					width: 60,
					show: true,
					disabled: true,
					render: (status: any, record: any) => renderStatus(status, t),
					align: 'center',
				},
				{
					title: t('creator_summary.first_name'),
					dataIndex: 'firstName',
					show: this.checkShowColumn('firstName'),
					sorter: () => {},
					width: 150,
					disabled: true,
					render: (firstName: string) => renderText(firstName),
					ellipsis: true,
				},
				{
					title: t('creator_summary.last_name'),
					dataIndex: 'lastName',
					show: this.checkShowColumn('lastName'),
					sorter: () => {},
					width: 150,
					disabled: true,
					render: (lastName: string) => renderText(lastName),
					ellipsis: true,
				},
				{
					title: t('creator_summary.email'),
					dataIndex: 'email',
					show: this.checkShowColumn('email'),
					sorter: () => {},
					width: 250,
					disabled: true,
					render: (email: string, record: SummaryDto) => renderEmail(email, record),
					ellipsis: true,
				},
				{
					title: t('creator_summary.approval_status'),
					dataIndex: 'approvalStatus',
					show: this.checkShowColumn('approvalStatus'),
					sorter: () => {},
					width: DEFAULT_WIDTH_COL + 20,
				},
				{
					title: t('creator_summary.instagram'),
					dataIndex: 'instagramUsername',
					show: this.checkShowColumn('instagramUsername'),
					width: 250,
					sorter: () => {},
					render: (instagramUsername: string, record: SummaryDto) =>
						this.renderSocialUsername(
							instagramUsername,
							record,
							SOCIAL_SOURCE.INSTAGRAM,
						),
					ellipsis: true,
				},
				{
					title: t('creator_summary.twitter'),
					dataIndex: 'twitterUsername',
					show: this.checkShowColumn('twitterUsername'),
					width: 250,
					sorter: () => {},
					render: (twitterUsername: string, record: SummaryDto) =>
						this.renderSocialUsername(twitterUsername, record, SOCIAL_SOURCE.TWITTER),
					ellipsis: true,
				},
				{
					title: t('creator_summary.youtube'),
					dataIndex: 'youtubeUsername',
					show: this.checkShowColumn('youtubeUsername'),
					width: 250,
					sorter: () => {},
					render: (youtubeUsername: string, record: SummaryDto) =>
						this.renderSocialUsername(youtubeUsername, record, SOCIAL_SOURCE.YOUTUBE),
					ellipsis: true,
				},
				{
					title: t('creator_summary.tiktok'),
					dataIndex: 'tiktokUsername',
					show: this.checkShowColumn('tiktokUsername'),
					width: 250,
					sorter: () => {},
					render: (tiktokUsername: string, record: SummaryDto) =>
						this.renderSocialUsername(tiktokUsername, record, SOCIAL_SOURCE.TIKTOK),
					ellipsis: true,
				},
				{
					title: t('creator_summary.mailing_address'),
					dataIndex: 'address',
					render: (address: string, record: any) => {
						const { zipCode = '' } = record;

						let dataAddress;
						try {
							dataAddress = JSON.parse(address);
						} catch (error) {
							dataAddress = address;
						}
						if (!dataAddress) {
							return renderText(zipCode);
						}

						if (typeof dataAddress === 'object') {
							const { street = '', city = '', state = '' } = dataAddress;
							const listStr: string[] = [];
							if (street) {
								listStr.push(street);
							}
							if (city) {
								listStr.push(city);
							}
							if (state) {
								listStr.push(state);
							}
							if (zipCode) {
								listStr.push(zipCode);
							}
							let str = '';
							listStr.map((item: string, idx: number) => {
								if (idx !== 0) {
									str = `${str} - ${item}`;
								} else {
									str = item;
								}
							});
							return str;
						} else {
							if (!zipCode) {
								return renderText(dataAddress);
							} else {
								return renderText(`${dataAddress} - ${zipCode}`);
							}
						}
					},
					width: 350,
					show: this.checkShowColumn('address'),
					ellipsis: true,
				},
				{
					title: t('creator_summary.direct_uploads'),
					dataIndex: 'directUploads',
					align: 'center' as 'center',
					show: this.checkShowColumn('directUploads'),
					sorter: () => {},
					width: 150,
					render: (val: number) => renderText(val),
					ellipsis: true,
				},
				{
					title: t('creator_summary.votes'),
					dataIndex: 'totalVotes',
					align: 'center' as 'center',
					show: this.checkShowColumn('totalVotes'),
					sorter: () => {},
					width: 100,
					render: (votes: number) => {
						const formatVal = abbreviateNumber(votes);
						return renderText(formatVal);
					},
					ellipsis: true,
				},
				{
					title: t('ambassador.ambassador_post'),
					dataIndex: 'socialUploads',
					align: 'center' as 'center',
					show: this.checkShowColumn('socialUploads'),
					width: 250,
					sorter: () => {},
					render: (val: number) => renderText(val),
					ellipsis: true,
				},
				{
					title: t('creator_summary.likes_comments'),
					dataIndex: 'likesComments',
					align: 'center' as 'center',
					show: this.checkShowColumn('likesComments'),
					sorter: () => {},
					width: 150,
					render: (likesComments: number) => {
						const formatVal = abbreviateNumber(likesComments);
						return renderText(formatVal);
					},
					ellipsis: true,
				},
				{
					title: t('creator_summary.engagement_rate'),
					dataIndex: 'engagementRate',
					align: 'center' as 'center',
					show: this.checkShowColumn('engagementRate'),
					sorter: () => {},
					width: 180,
					render: (engagementRate: number) => {
						const formatVal = abbreviateNumber((engagementRate || 0) * 100, 1);
						return renderText(formatVal);
					},
					ellipsis: true,
				},
				{
					title: t('creator_summary.social_media_followers'),
					dataIndex: 'followers',
					align: 'center' as 'center',
					show: this.checkShowColumn('followers'),
					width: 250,
					sorter: () => {},
					render: (val: number) => renderText(val),
					ellipsis: true,
				},
				{
					title: t('creator_summary.rewards_offered'),
					dataIndex: 'rewards',
					align: 'center' as 'center',
					show: this.checkShowColumn('rewards'),
					width: 250,
					sorter: () => {},
					render: (val: number) => renderText(val),
					ellipsis: true,
				},
				{
					title: t('creator_summary.rewards_redeemed'),
					dataIndex: 'totalRedeemed',
					align: 'center' as 'center',
					show: this.checkShowColumn('totalRedeemed'),
					width: 250,
					sorter: () => {},
					render: (val: number) => renderText(val),
					ellipsis: true,
				},
				{
					title: t('creator_summary.rewards_value'),
					dataIndex: 'totalCashRedeemed',
					align: 'center' as 'center',
					show: this.checkShowColumn('totalCashRedeemed'),
					width: 250,
					sorter: () => {},
					render: (val: number) => renderText(val),
					ellipsis: true,
				},
				{
					title: t('creator_summary.total_content_value'),
					dataIndex: 'totalCashValueAllUploads',
					align: 'center' as 'center',
					width: 250,
					show: this.checkShowColumn('totalCashValueAllUploads'),
					sorter: (a: any, b: any) =>
						a.totalCashValueAllUploads - b.totalCashValueAllUploads,
					render: (val: number) => renderText(val),
					ellipsis: true,
				},
				{
					title: t('creator_summary.avg_content_rating'),
					dataIndex: 'avgRatingUpload',
					align: 'center' as 'center',
					show: this.checkShowColumn('avgRatingUpload'),
					width: 250,
					render: (avgRatingUpload: number) => {
						const formatAvgRatingStar = hanldeRoundAvgStar(avgRatingUpload);
						return (
							<div
								onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
								<StyledRate allowHalf disabled defaultValue={formatAvgRatingStar} />
							</div>
						);
					},
					sorter: () => {},
				},
				{
					title: t('creator_summary.location'),
					dataIndex: 'location',
					width: 250,
					show: this.checkShowColumn('location'),
					render: (val: string) => renderText(val),
					ellipsis: true,
				},
				{
					title: t('creator_summary.date'),
					dataIndex: 'createAt',
					show: this.checkShowColumn('createAt'),
					render: (createAt: string) =>
						renderText(
							convertUtcToLocalTimeWithFormat(
								new Date(createAt).getTime(),
								TYPE_FORMAT_DATE.TIME,
							),
						),
					width: 250,
					sorter: () => {},
					ellipsis: true,
				},
			],
			selectedRow: [],
			isSelectAll: false,
			isConfirmModal: false,
			contentModal: '',
			okText: '',
			isLoading: false,
			isBlock: false,
			onSubmit: () => {},
			selectedCreatorList: [],
		};
	}

	componentDidMount(): void {
		this.updateSortColumn();
	}

	componentDidUpdate(
		prevProps: Readonly<SummaryProps>,
		prevState: Readonly<SummaryStates>,
		snapshot?: any,
	): void {
		const { creator: { uploadAWS = false, isBlocking = false } = {} } = this.props;
		const {
			content: { creatorToggleColumn },
		} = this.props;
		if (uploadAWS !== prevProps.creator.uploadAWS) {
			this.resetSelectedAllRows();
		}

		if (prevProps.creator.isBlocking !== isBlocking) {
			if (!isBlocking) {
				this.closeModalAfterCalledCreatorBlock(isBlocking);
			}
		}
		if (!_.isEqual(prevProps.content.creatorToggleColumn, creatorToggleColumn)) {
			this.updateSortColumn();
		}
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		console.log(error);
	}

	componentWillUnmount(): void {}

	checkShowColumn = (dataIndex: string) => {
		const {
			content: { creatorToggleColumn = [] },
		} = this.props;

		return (
			creatorToggleColumn?.find(
				(item: KeyValueSortColumnType) => item?.dataIndex === dataIndex,
			)?.show || false
		);
	};

	updateSortColumn = () => {
		const {
			content: { creatorToggleColumn = [] },
		} = this.props;
		const toggleColumns: SummaryColumnsDto[] = [...this.state.toggleColumns];

		const newList: SummaryColumnsDto[] | any[] = creatorToggleColumn?.map(
			(item: KeyValueSortColumnType) => {
				const newObj =
					toggleColumns?.find((column) => column?.dataIndex === item?.dataIndex) || {};

				return { ...newObj, ...item };
			},
		);

		this.updateToggleColumns(newList);
		this.updateColumnsTable(newList);
	};

	renderSocialUsername = (socialUsername: string, creator: SummaryDto, source: string) => {
		const { status, socialUserProfileStatus } = creator || {};
		const { followed = false } = status || {};
		const {
			instagramInvalid = false,
			tiktokInvalid = false,
			twitterInvalid = false,
			youtubeInvalid = false,
		} = socialUserProfileStatus || {};
		let isInvalid = false;

		switch (true) {
			case source.includes(SOCIAL_SOURCE.INSTAGRAM):
				isInvalid = instagramInvalid;
				break;

			case source.includes(SOCIAL_SOURCE.TIKTOK):
				isInvalid = tiktokInvalid;
				break;

			case source.includes(SOCIAL_SOURCE.TWITTER):
				isInvalid = twitterInvalid;
				break;

			default:
				isInvalid = youtubeInvalid;
				break;
		}

		const payload = {
			username: socialUsername,
			source,
			isInvalid,
			isAmbassador: !!followed,
		};

		return (
			socialUsername && (
				<StyledFlex justify="flex-start">
					{<InvalidFlag payload={payload} />}
					{renderText(replaceAtChar(socialUsername))}
				</StyledFlex>
			)
		);
	};

	/**
	 * @param toggleColumns
	 */
	updateColumnsTable = (toggleColumns: SummaryColumnsDto[]) => {
		const columns: SummaryColumnsDto[] = toggleColumns.filter((item) => item.show);
		this.setState({ columns: columns });
	};

	updateToggleColumns = (listToggle: SummaryColumnsDto[]) => {
		this.setState({ toggleColumns: listToggle });
	};

	compareList = (prevList: string[], list: string[]) => {
		return _.isEqual(prevList, list);
	};

	/**
	 * @param selectedRow
	 */
	changeSelectedRow = (selectedRow: any) => {
		this.setState({ selectedRow: selectedRow });
	};

	resetSelectedAllRows = () => {
		const { creator: { uploadAWS = false } = {} } = this.props;

		if (uploadAWS) {
			this.setState({ isSelectAll: false });
		}
	};

	handleSelectFullDatabase = (val: boolean) => {
		this.setState({ isSelectAll: val });
	};

	handleChangeStatus = (data: any) => {
		const { paramSummaryContact, isSelectAll } = this.state;
		const { creatorChangeStatus } = this.props;
		const creatorIds = this.getSelectedFromList();

		const payload = {
			...data,
			isAll: isSelectAll,
			requestDataFindProfiles: paramSummaryContact,
			requestBody: {
				creatorIds: isSelectAll ? [] : creatorIds,
				...this.handleAddMoreFilterRequest(),
			},
		};

		creatorChangeStatus(payload);
	};

	openModal = (isBlock: boolean) => {
		const { t } = this.props;

		this.setState({
			isConfirmModal: true,
			contentModal: isBlock
				? t('creator_summary.confirm.block')
				: t('creator_summary.confirm.unblock'),
			okText: t('button.yes'),
			onSubmit: isBlock ? this.onConfirmBlock : this.onConfirmUnblock,
			isBlock,
		});
	};

	onClose = () => {
		this.setState({
			isLoading: false,
			isConfirmModal: false,
			contentModal: '',
			okText: '',
			onSubmit: () => {},
			isBlock: false,
		});
	};

	closeModalAfterCalledCreatorBlock = (isBlocking: boolean) => {
		const { isConfirmModal } = this.state;
		if (isConfirmModal && !isBlocking) {
			this.onClose();
		}
	};

	handleChangeBlockStatus = (isDelete: boolean) => {
		const { paramSummaryContact, isSelectAll, isBlock } = this.state;
		const { creatorBlock } = this.props;
		const ids = this.getSelectedFromList();

		const payload = {
			isBlock,
			isDelete,
			isAll: isSelectAll,
			requestDataFindProfiles: paramSummaryContact,
			requestBody: {
				ids: isSelectAll ? [] : ids,
				listSocialUsername: [],
				...this.handleAddMoreFilterRequest(),
			},
		};

		this.setState({ isLoading: true });
		creatorBlock(payload);
	};

	onConfirmBlock = (isDelete?: boolean) => {
		const isDeleteTemp = isDelete !== undefined && !isDelete ? false : true;
		this.handleChangeBlockStatus(isDeleteTemp);
	};

	onConfirmUnblock = () => {
		this.handleChangeBlockStatus(false);
	};

	renderConfirmModal = () => {
		const { t } = this.props;
		const { contentModal, isLoading, isConfirmModal, okText, onSubmit = () => {} } = this.state;

		const isBlock: boolean = contentModal === t('creator_summary.confirm.block');

		return (
			<ConfirmModal
				width={isBlock ? 500 : 400}
				confirmLoading={isLoading}
				okText={okText}
				isOpen={isConfirmModal}
				otherConfirmText={isBlock ? t('button.block_not_delete') : ''}
				onSubmit={onSubmit}
				onOtherConfirm={() => this.onConfirmBlock(false)}
				onClose={this.onClose}>
				{contentModal}
			</ConfirmModal>
		);
	};

	handleExportColumns = () => {
		const { exportProfiles } = this.props;
		const { isSelectAll } = this.state;

		const row = isSelectAll ? [] : this.getSelectedFromList();
		const payload: any = {
			id: row,
			isAll: isSelectAll,
			...this.handleAddMoreFilterRequest(),
		};
		exportProfiles(payload);
	};

	handleChangeParams = (params: ParamSummaryContactDto) => {
		this.setState({
			paramSummaryContact: { ...params },
		});
	};

	getSelectedFromList = () => {
		const { creator } = this.props;
		const { profilesData } = creator;
		const { selectedRow } = this.state;
		const result: number[] = [];
		profilesData.forEach((p) => {
			if (selectedRow?.includes(p.creatorId)) {
				result.push(p.creatorId);
			}
		});
		return result;
	};

	handleAddMoreFilterRequest = () => {
		const { paramSummaryContact } = this.state;
		const result: any = {};
		if (paramSummaryContact?.search) {
			result.search = paramSummaryContact.search;
		}
		if (paramSummaryContact.id) {
			result.segmentId = paramSummaryContact.id;
		}
		return result;
	};

	handleDeleteCreator = () => {
		const { paramSummaryContact, isSelectAll, selectedRow } = this.state;
		const { creatorDelete } = this.props;
		const row = this.getSelectedFromList();

		const payload: any = {
			isAll: isSelectAll,
			requestDataFindProfiles: paramSummaryContact,
			requestBody: {
				creatorIds: isSelectAll ? [] : row,
				...this.handleAddMoreFilterRequest(),
			},
		};

		creatorDelete(payload);
		this.setState({ selectedRow: selectedRow.filter((id) => !row.includes(id)) });
	};

	handleImportCreator = (file: any) => {
		const { paramSummaryContact } = this.state;
		const { creatorImport } = this.props;
		const payload = {
			file,
			requestDataFindProfiles: paramSummaryContact,
		};
		creatorImport(payload);
	};

	handleSelectedCreators = (selectedCreators: any) => {
		this.setState({
			selectedCreatorList: selectedCreators,
		});
	};

	handleOpenMessage = () => {
		const { selectedCreatorList } = this.state;

		history.push({
			pathname: ROUTE_PATH.EMAIL_BUILDER,
			state: {
				type: STATE_TYPE.MESSAGE_FROM_CREATOR_PROFILE,
				creatorList: selectedCreatorList,
				isBackPageBefore: true,
			},
		});
	};

	render(): React.ReactNode {
		const { columns, selectedRow, toggleColumns, isSelectAll, selectedCreatorList } =
			this.state;
		const { creator } = this.props;
		const { totalRecords } = creator;

		const selectOnView = this.getSelectedFromList();
		const isAllSelectOverDb = isSelectAll && selectOnView.length < totalRecords;

		return (
			<CreatorSummaryContext.Provider
				value={{
					paramSummaryContact: this.state.paramSummaryContact,
					handleChangeParamSummary: this.handleChangeParams,
					isAllSelectOverDb,
					selectOnView: this.getSelectedFromList(),
				}}>
				<div className="main-content creator-summary">
					<Header>
						<ActionList
							columns={toggleColumns}
							handleExportColumns={this.handleExportColumns}
							onChangeStatus={this.handleChangeStatus}
							handleChangeBlockStatus={this.openModal}
							handleDeleteCreator={this.handleDeleteCreator}
							onImportCreator={this.handleImportCreator}
							handleOpenMessage={this.handleOpenMessage}
						/>
					</Header>
					<SummaryList
						columns={columns}
						changeSelectedRow={this.changeSelectedRow}
						selectedRowKeys={selectedRow}
						handleSelectedCreators={this.handleSelectedCreators}
						selectedCreatorList={selectedCreatorList}
						handleSelectFullDatabase={this.handleSelectFullDatabase}
					/>
					{this.renderConfirmModal()}
				</div>
			</CreatorSummaryContext.Provider>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		exportProfiles: (payload: any) => dispatch({ type: 'CREATOR_EXPORT_PROFILES', payload }),
		creatorChangeStatus: (payload: any) =>
			dispatch({
				type: 'CREATOR_CHANGE_STATUS',
				payload: payload,
			}),
		creatorDelete: (payload: any) =>
			dispatch({
				type: 'CREATOR_DELETE',
				payload: payload,
			}),
		creatorImport: (payload: any) =>
			dispatch({
				type: 'CREATOR_IMPORT',
				payload: payload,
			}),
		creatorBlock: (payload: any) => {
			dispatch({
				type: 'CREATOR_BLOCK',
				payload: payload,
			});
		},
	};
};

const mapStateToProps = (state: any) => ({
	filter: state.header,
	creator: state.creator,
	content: state?.content,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreatorsSummary));
