import { CaretRightOutlined } from '@ant-design/icons';
import {
	IconAngry,
	IconBookmark,
	IconEye,
	IconHappy,
	IconInstagramWhite,
	IconLike,
	IconMixed,
	IconTikTok,
	IconTwitterWhite,
	IconYoutube,
} from '@assets/icons';
import { ImageFallback } from '@assets/images';
import { TIME_OUT, TYPE_FORMAT_DATE, UNIT_VALUE } from '@constants/common';
import { CAPTION_SENTIMENT_TYPE } from '@constants/settings/socialAggregator';
import { SOCIAL } from '@constants/social_search';
import { LINE_HEIGHT_DESC, SOCIAL_TYPE } from '@constants/social_search/feeds';
import THEME from '@constants/themes/themes';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import {
	StyledDescOverflow,
	StyledIcon,
	StyledTitle,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledImage, StyledMarkExplicit } from '@styled/Content/ContentLibrary/MediaItemStyled';
import { StyledIconPlay } from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import {
	StyledCardSocial,
	StyledIconBookmark,
	StyledTimestamp,
	StyledWrapperInfoSocial,
	StyledWrapperMetrics,
	StyledWrapperThumb,
} from '@styled/SocialSearch/Feeds';
import {
	abbreviateNumber,
	getContentByType,
	goToSocialProfile,
	handleLoadedSocialError,
	handleRedirectLink,
} from '@utils/common';
import { convertSocialId, renderUrlSocialThumbnail } from '@utils/socialSearch';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TSelectableItemProps } from 'react-selectable-fast';
import { checkExistExplicit } from '@utils/content';
import { useWindowSize } from '@utils/customHooks';

type PropTypes = {
	social: SocialContentContainerDto;
	heightCard: number;
	selectContent: (val: SocialContentContainerDto) => void;
	onSelectItems: (id?: string) => void;
	keyItem: number | string;
	thumbFull?: boolean;
	socialType?: string;
	noSelect?: boolean;
	[other: string]: any;
};

const CardSocial = (props: TSelectableItemProps & PropTypes) => {
	const { t } = useTranslation();
	const {
		social,
		heightCard,
		selectableRef,
		isSelecting,
		isSelected,
		selectContent,
		onSelectItems,
		thumbFull,
		socialType = SOCIAL_TYPE.SOCIAL_FEED,
		keyItem,
		noSelect = false,
	} = props;
	const firstUpdate = useRef(true);

	const refTime = useRef<any>({});
	const refInfoUser = useRef<HTMLDivElement | null>(null);
	const refFooterCard = useRef<HTMLDivElement | null>(null);
	const [isDrag, setIsDrag] = useState<boolean>(false);
	const [loadedErrorMedia, setLoaderErrorMedia] = useState(true);
	const [urlThumb, setThumbUrl] = useState<string>(renderUrlSocialThumbnail(social));
	const [rowDesc, setRowDesc] = useState<number>(0);
	const [showTime, setShowTime] = useState<boolean>(true);
	const [hideText, setHideText] = useState<boolean>(false);

	const [widthWindow, heightWindow] = useWindowSize();

	const isScreenSmall = useMemo(() => {
		return widthWindow < UNIT_VALUE.S_1400;
	}, [widthWindow]);

	useEffect(() => {
		delayShowingText();
	}, [thumbFull]);

	const checkExplicitContentDetected = () => {
		if (social?.content) {
			const contentChangeType: ContentDto = getContentByType(social.content);
			return checkExistExplicit(contentChangeType?.review?.flags);
		}
		return false;
	};

	const delayShowingText = () => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
		} else {
			setHideText(true);
			if (!thumbFull) {
				setTimeout(() => {
					setHideText(false);
				}, TIME_OUT.VISIBLE_TEXT);
			}
		}
	};

	useEffect(() => {
		return () => {
			setLoaderErrorMedia(false);
			setRowDesc(0);
			setShowTime(true);
			setHideText(false);
		};
	}, []);

	const classNames = [
		'media-item',
		isSelecting && 'media-selecting',
		isSelected && 'media-selected',
	]
		.filter(Boolean)
		.join(' ');

	const renderIcon = () => {
		let icon: React.ReactNode = '';

		switch (social.socialSource.toLowerCase()) {
			case SOCIAL.INSTAGRAM.toLowerCase():
				icon = <IconInstagramWhite />;
				break;
			case SOCIAL.TWITTER.toLowerCase():
				icon = <IconTwitterWhite />;
				break;
			case SOCIAL.TIKTOK.toLowerCase():
				icon = <IconTikTok />;
				break;

			case SOCIAL.YOUTUBE.toLowerCase():
			default:
				icon = <IconYoutube />;
				break;
		}
		return icon;
	};

	const renderIconCaptionSentiment = (caption?: string | null) => {
		let result: React.ReactNode = '';
		if (!caption) {
			return '';
		}

		switch (caption.toLowerCase()) {
			case CAPTION_SENTIMENT_TYPE.POSITIVE.toLowerCase():
				result = <IconHappy />;
				break;
			case CAPTION_SENTIMENT_TYPE.NEGATIVE.toLowerCase():
				result = <IconAngry />;
				break;
			case CAPTION_SENTIMENT_TYPE.MIXED.toLowerCase():
				result = <IconMixed />;
				break;
			case CAPTION_SENTIMENT_TYPE.NEUTRAL.toLowerCase():
				result = <IconMixed />;
				break;
			default:
				break;
		}
		if (result) {
			return <StyledIcon fill={THEME.colors.darkBlue3}>{result}</StyledIcon>;
		}
		return result;
	};

	const checkSocialType = useMemo(() => {
		return {
			isSocialPost: socialType === SOCIAL_TYPE.SOCIAL_POST,
		};
	}, [socialType]);

	const handleCheckHeightDesc = () => {
		const heightInfoUser = refInfoUser.current?.offsetHeight || 0;
		const heightInfoDate = refFooterCard.current?.offsetHeight || 0;
		// padding and margin
		const padding = isScreenSmall ? 8 : 12;
		const moreSpace = padding * 2 + 10;
		const desc = heightCard - heightInfoUser - heightInfoDate - moreSpace || 0;

		setShowTime(
			heightCard - moreSpace - heightInfoUser + (desc < 0 ? 10 : 0) >= heightInfoDate,
		);
		setRowDesc(desc < 0 ? 0 : Math.floor(desc / LINE_HEIGHT_DESC));
	};

	const otherFieldIcon =
		social.socialSource.toLowerCase() === SOCIAL.YOUTUBE.toLowerCase()
			? { className: 'absolute' }
			: { fillPath: THEME.colors.gray1 };

	useEffect(() => {
		if (!thumbFull) {
			handleCheckHeightDesc();
		}
	}, [isSelected, isSelecting, thumbFull, heightCard, isScreenSmall]);

	const other = selectableRef ? { ref: selectableRef } : {};

	return (
		<StyledCardSocial
			key={keyItem}
			className={classNames}
			onClick={(e: React.MouseEvent<HTMLDivElement>) => {
				if (noSelect) {
					selectContent(social);
				}
				if (refTime.current) {
					clearTimeout(refTime.current);
				}
				if (isDrag) {
					return;
				}
				onSelectItems(convertSocialId(social));

				if (e.detail === 2) {
					selectContent(social);
				}
			}}
			onMouseDown={() => {
				if (isDrag) {
					setIsDrag(false);
				}
			}}
			onMouseMove={() => {
				if (!isDrag) {
					setIsDrag(true);
				}
			}}
			{...other}
			height={heightCard}>
			<StyledWrapperThumb width={thumbFull ? '100%' : `${(heightCard * 6) / 5}px`}>
				{social?.mediaType && social?.mediaType.includes('video') && (
					<StyledIconPlay>
						<CaretRightOutlined />
					</StyledIconPlay>
				)}
				<StyledImage
					src={urlThumb}
					alt="image_social"
					preview={false}
					width="100%"
					height="100%"
					loading="lazy"
					id={`thumbnail_social_${convertSocialId(social)}`}
					onError={async (e) => {
						if (loadedErrorMedia) {
							setLoaderErrorMedia(false);
							const src = await handleLoadedSocialError(e, social);

							if (src) {
								setThumbUrl(src);
							}
						}
					}}
					fallback={ImageFallback}
				/>
				{checkSocialType.isSocialPost && social.imported && (
					<StyledIconBookmark>
						<IconBookmark />
					</StyledIconBookmark>
				)}
				{checkExplicitContentDetected() && (
					<StyledMarkExplicit zIndex={777}>
						<StyledWrapperContent textAlign="center">
							<StyledIcon fillPath={THEME.colors.gray4} size={72}>
								<IconEye />
							</StyledIcon>
							<StyledTitle
								className="unset_height"
								margin="8px 0 0 0"
								fontSize="16px">
								{t('content_management.sensitive_content')}
							</StyledTitle>
						</StyledWrapperContent>
					</StyledMarkExplicit>
				)}
			</StyledWrapperThumb>
			{!thumbFull && (
				<StyledWrapperInfoSocial
					className={hideText ? 'hide_text' : ''}
					widthExclude={(heightCard * 6) / 5}>
					<StyledWrapperTitle
						ref={refInfoUser}
						position="relative"
						justifyContent="unset">
						<StyledWrapperContent maxWidth={'calc(100% - 30px)'} textAlign="left">
							<StyledTitle
								onClick={(e: React.MouseEvent<HTMLElement>) => {
									e.preventDefault();
									e.stopPropagation();
									goToSocialProfile(social.username, social.socialSource);
								}}
								color={THEME.colors.gray3}
								className="unset_height overflow_text text_action"
								fontSize={
									checkSocialType.isSocialPost ? '12px' : '14px'
								}>{`@${social.username}`}</StyledTitle>
							{checkSocialType.isSocialPost && (
								<StyledWrapperMetrics>
									{!!social?.followers && (
										<StyledTitle
											className="overflow_text"
											display="block"
											height="unset"
											lineHeight="16px">
											{t('feeds.val_follower', {
												val: abbreviateNumber(social.followers),
											})}
										</StyledTitle>
									)}
									{!!social?.likes && (
										<StyledTitle height="unset" lineHeight="16px">
											<StyledIcon fillPath={THEME.colors.gray3} size={12}>
												<IconLike />
											</StyledIcon>
											{abbreviateNumber(social.likes)}
										</StyledTitle>
									)}
								</StyledWrapperMetrics>
							)}
						</StyledWrapperContent>
						<StyledIcon
							className="absolute icon_hover"
							top="-2px"
							right="0"
							onClick={(e: React.MouseEvent<HTMLElement>) => {
								e.preventDefault();
								e.stopPropagation();
								if (social.permalink) {
									handleRedirectLink(social.permalink);
								}
							}}
							size={24}
							{...otherFieldIcon}>
							{renderIcon()}
						</StyledIcon>
					</StyledWrapperTitle>
					{!!rowDesc && (
						<StyledWrapperContent margin="10px 0 0 0">
							<StyledDescOverflow
								color={THEME.colors.gray5}
								fontSize="12px"
								lineHeight={`${LINE_HEIGHT_DESC}px`}
								line={rowDesc}>
								{social.caption || ''}
							</StyledDescOverflow>
						</StyledWrapperContent>
					)}
					<StyledWrapperTitle
						ref={refFooterCard}
						className="absolute timestamp"
						style={showTime ? {} : { display: 'none' }}
						fontSize="12px"
						padding="0 9px"
						bottom={'12px'}
						left="0"
						width="100%"
						justifyContent="space-between">
						<StyledWrapperContent display="inline-flex">
							{renderIconCaptionSentiment(social?.captionSentiment)}
						</StyledWrapperContent>
						<StyledTimestamp
							title={convertUtcToLocalTimeWithFormat(
								social.timestamp,
								TYPE_FORMAT_DATE.TIME,
							)}>
							{convertUtcToLocalTimeWithFormat(
								social.timestamp,
								TYPE_FORMAT_DATE.TIME,
							)}
						</StyledTimestamp>
					</StyledWrapperTitle>
				</StyledWrapperInfoSocial>
			)}
		</StyledCardSocial>
	);
};

export default CardSocial;
