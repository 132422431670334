import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Styled
import styled from 'styled-components';
import { StyledTitle } from '@styled/Content/ContentLibrary/ContentStyled';

// Components
import Wrapper from '@cores/Wrapper';
import {
	IconDownload,
	IconOpenInNew,
	IconLock,
	IconDelete,
	IconFullScreen,
	IconFileCopy,
	IconRotateRight,
	IconArchive,
	IconFlag,
} from '@assets/icons';

import { ContentDto, TypeFolderContent } from '@models/content/contentLibrary/summary';
import { message, Tooltip } from 'antd';
import { ACTIONS, MEDIA_TYPE, UPLOADSOURCE } from '@constants/content/contentLibrary';
import { RenderViewPostIcon } from '@utils/renderComponent';
import { getDefaultWidgetLink } from '@helpers/userHelpers';
import { useSelector } from 'react-redux';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { useContext } from 'react';

const StyledWrapper = styled(Wrapper)<{ isDisableAction?: boolean }>`
	display: flex;
	justify-content: center;

	.action-icon:not(.fullscreen-mode) {
		pointer-events: ${({ isDisableAction }) => (isDisableAction ? 'none' : 'auto')};
		opacity: ${({ isDisableAction }) => (isDisableAction ? '0.5' : 'unset')};
		cursor: ${({ isDisableAction }) => (isDisableAction ? 'unset' : 'pointer')};
	}
`;

const StyledAction = styled(StyledTitle)<{ isDisabled?: boolean }>`
	color: #a3abb1;
	font-size: 0.857rem;
	margin-left: 12px;
	cursor: ${({ isDisabled }) => (isDisabled ? 'unset !important' : 'pointer')};
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none !important' : 'auto')};
	opacity: ${({ isDisabled }) => (isDisabled ? '0.5 !important' : 'unset')};

	&.ml_0 {
		margin-left: 0;
	}
	&.enable-action {
		pointer-events: auto;
	}

	&.disable-action {
		pointer-events: none !important;
		opacity: 0.5 !important;
	}
`;

type ActionContentDetailProps = {
	onFullScreen: () => void;
	onDownload: () => void;
	currentMedia: ContentDto;
	setTypeConfirm: (val: string) => void;
	onViewPost: () => void;
	handleFlagAction: () => void;
};

const ActionContentDetail = (props: ActionContentDetailProps) => {
	const { onFullScreen, currentMedia, onDownload, setTypeConfirm, onViewPost, handleFlagAction } =
		props;
	const { review, uploadSource, blocked, canBlock } = currentMedia;
	const { t } = useTranslation();
	const { clientSettings } = useSelector((state: any) => state.sidebar);

	const { isDisableAction, typeContent, CDMBlockCreatorDisabled } =
		useContext(ContentDetailContext);

	const renderRejectButton = () => {
		const isReject = review?.rejected;
		let tooltip: string = t('button.archive');
		let action: string = ACTIONS.REJECT;

		if (isReject) {
			tooltip = t('button.restore');
			action = ACTIONS.UNREJECT;
		}

		return (
			<Tooltip title={tooltip}>
				<StyledAction className="action-icon" onClick={() => setTypeConfirm(action)}>
					<IconArchive />
				</StyledAction>
			</Tooltip>
		);
	};

	const onCopyWidget = () => {
		const defaultWidgetLink = getDefaultWidgetLink(clientSettings);
		if (!currentMedia?.id || !defaultWidgetLink) return;

		const input = document.createElement('input');
		input.value = `${defaultWidgetLink?.url}?s=${currentMedia?.id}`;

		if (defaultWidgetLink?.url.includes('?cl=')) {
			input.value = `${defaultWidgetLink.url}&s=${currentMedia?.id}`;
		}

		document.body.appendChild(input);
		input.select();

		document.execCommand('Copy');
		input.remove();
		message.success(t('setting.message.copied_successfully'));
	};

	let tooltipBlock: string = '';
	let className: string = '';

	if (!isDisableAction) {
		if (canBlock && !CDMBlockCreatorDisabled) {
			if (!blocked) {
				tooltipBlock += 'button.block';
			} else {
				tooltipBlock += 'button.unblock';
			}
		} else {
			className = 'disable-action';
		}
	}

	const isDirectUpload: boolean = uploadSource === UPLOADSOURCE.DIRECT_UPLOAD;
	const socialSource: string =
		!_.isEmpty(currentMedia.social) && !isDirectUpload ? currentMedia.social.socialSource : '';

	const hiddenCopyUploadWidget = true;

	return (
		<StyledWrapper isDisableAction={isDisableAction}>
			{RenderViewPostIcon(socialSource, isDirectUpload, onViewPost)}

			<Tooltip title={t('button.full_screen')}>
				<StyledAction
					className={`action-icon ${socialSource ? '' : 'ml_0'}`}
					onClick={onFullScreen}>
					<IconFullScreen />
				</StyledAction>
			</Tooltip>

			<Tooltip title={t('button.flags')}>
				<StyledAction
					isDisabled={typeContent.isArchive}
					onClick={handleFlagAction}
					className="action-icon">
					<IconFlag />
				</StyledAction>
			</Tooltip>

			{!typeContent?.isBookmark && (
				<Tooltip title={t('button.download')}>
					<StyledAction
						isDisabled={typeContent.isArchive}
						onClick={onDownload}
						className="action-icon">
						<IconDownload />
					</StyledAction>
				</Tooltip>
			)}

			{!!typeContent?.isBookmark && !hiddenCopyUploadWidget && (
				<Tooltip title={t('button.copy_upload_widget_to_clipboard')}>
					<StyledAction className="action-icon">
						<IconFileCopy onClick={onCopyWidget} />
					</StyledAction>
				</Tooltip>
			)}

			{renderRejectButton()}

			<Tooltip title={t(tooltipBlock)}>
				<StyledAction
					className={`${className} action-icon`}
					onClick={() => setTypeConfirm(blocked ? ACTIONS.UNBLOCK : ACTIONS.BLOCK)}>
					<IconLock />
				</StyledAction>
			</Tooltip>

			<Tooltip title={t('button.delete')}>
				<StyledAction className="action-icon">
					<IconDelete onClick={() => setTypeConfirm(ACTIONS.DELETE)} />
				</StyledAction>
			</Tooltip>
		</StyledWrapper>
	);
};

export default ActionContentDetail;
