import THEME from '@constants/themes/themes';
import { StyledCollapse } from '@styled/Common/CommonStyled';
import styled from 'styled-components';

const StyledLandingPageCollapse = styled(StyledCollapse)`
	border: 0;

	& > .ant-collapse-item {
		position: relative;

		&:not(:first-child) {
			margin-top: 16px;
		}

		padding: 18px 12px;
		background: ${THEME.colors.darkBlue3};
		border-radius: 6px !important;
		border: 0;

		.ant-collapse-content {
			border: 0;
			background: transparent;

			.ant-collapse-content-box {
				padding: 12px 12px 0 12px;
			}

			&.ant-collapse-content-hidden:not(.header) {
				display: none;
			}
		}

		.ant-collapse-header {
			padding: 0;

			.anticon {
				svg {
					transform: rotate(-90deg);
				}
			}
		}
	}

	.ant-collapse-item {
		border-bottom: none !important;
	}
`;

export { StyledLandingPageCollapse };
