import ActionBar from '@components/TaskManager/ActionBar';
import TaskDetail from '@components/TaskManager/TaskDetail';
import ReminderEmail from '@components/TaskManager/TaskDetail/ReminderEmail';
import TaskList from '@components/TaskManager/TaskList';
import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import {
	TASK_ACTION_TYPE,
	TASK_DEFAULT_PAYLOAD,
	TASK_DETAIL_MODAL_TYPES,
	TASK_PARAMS_DEFAULT,
} from '@constants/taskManager';
import TaskManagerContext from '@contexts/TaskManager';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import {
	AccountItemLiteDto,
	TaskDataDto,
	TaskItemDto,
	TaskListRequestPayloadDto,
	UpdateTaskPayloadDto,
} from '@models/taskmanager';
import { TaskManagerStoreDto } from '@models/taskmanager/store';
import { getInfoUserRequest, storeUserData } from '@stores/actions';
import {
	createTaskEnd,
	deleteTaskEnd,
	deleteTaskRequest,
	getAccountListLiteRequest,
	getTaskListRequest,
	markTaskAsDoneEnd,
	markTaskAsDoneRequest,
	storeDataTaskManager,
	updateTaskEnd,
	updateTaskRequest,
} from '@stores/taskmanager/taskmanager.actions';
import { UserStoreType } from '@stores/user/user.types';
import { checkPositionContent } from '@utils/funcHelper';
import { customizeRenderEmpty } from '@utils/renderComponent';
import { ConfigProvider, message } from 'antd';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const TaskManager = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		taskData,
		accountListLite = [],
		fetchingTaskList = false,
		markTaskAsDoneStatus = NONE,
		deleteTaskStatus = NONE,
		createTaskStatus = NONE,
		updateTaskStatus = NONE,
		updatedTask,
		error,
	}: TaskManagerStoreDto = useSelector((state: any) => state?.taskManager);

	const { fetchingUser }: UserStoreType = useSelector((state: any) => state?.user);
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const [requestPayload, setRequestPayload] = useState<TaskListRequestPayloadDto>();
	const [taskManager, setTaskManager] = useState<TaskDataDto>({
		page: 0,
	});
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedTask, setSelectedTask] = useState<TaskItemDto | null>(null);
	const [modalType, setModalType] = useState<string>('');
	const [taskIdFromSearchParams, setTaskIdFromSearchParams] = useState<number>();

	useEffect(() => {
		dispatch(
			storeUserData({
				fetchingUser: true,
			}),
		);
		dispatch(
			storeDataTaskManager({
				fetchingTaskList: true,
			}),
		);
		dispatch(getAccountListLiteRequest());
		dispatch(getInfoUserRequest());

		return () => {
			dispatch(
				storeDataTaskManager({
					taskData: null,
				}),
			);
		};
	}, []);

	useEffect(() => {
		if (!fetchingUser) {
			handleGetTaskList();
		}
	}, [requestPayload]);

	useEffect(() => {
		if (taskData) {
			setTaskManager(taskData);
		}
	}, [taskData]);

	useEffect(() => {
		if (markTaskAsDoneStatus === SUCCEEDED) {
			message.success(t('task_manager.message.marked_as_done_successfully'));
			resetData();
		}

		if (markTaskAsDoneStatus === SUCCEEDED || markTaskAsDoneStatus === FAILED) {
			dispatch(markTaskAsDoneEnd());
		}
	}, [markTaskAsDoneStatus]);

	useEffect(() => {
		if (deleteTaskStatus === SUCCEEDED) {
			message.success(t('message.action_success', { action: t('action.deleted') }));
			resetData();
		}

		if (deleteTaskStatus === SUCCEEDED || deleteTaskStatus === FAILED) {
			dispatch(deleteTaskEnd());
		}
	}, [deleteTaskStatus]);

	useEffect(() => {
		if (createTaskStatus === SUCCEEDED) {
			message.success(t('message.action_success', { action: t('action.created') }));
			resetData();
		}

		if (createTaskStatus === SUCCEEDED || createTaskStatus === FAILED) {
			dispatch(createTaskEnd());
		}
	}, [createTaskStatus]);

	useEffect(() => {
		if (updateTaskStatus === SUCCEEDED) {
			const updatedData = _.cloneDeep(taskManager);
			if (updatedTask && updatedData?.tasks) {
				const index = updatedData.tasks.findIndex(
					(item: TaskItemDto) => item.id === updatedTask?.id,
				);
				if (index !== -1) {
					updatedData.tasks[index] = { ...updatedTask };
				}
				setTaskManager({ ...updatedData });
			}
		}

		if (updateTaskStatus === SUCCEEDED || updateTaskStatus === FAILED) {
			dispatch(updateTaskEnd());
		}
	}, [updateTaskStatus, updatedTask]);

	useEffect(() => {
		if (error) {
			message.error(error);
		}
	}, [error]);

	useEffect(() => {
		const taskId = searchParams.get('taskId');
		if (taskId) {
			setTaskIdFromSearchParams(+taskId);
		}
	}, [searchParams]);

	const handleGetTaskList = () => {
		if (requestPayload) {
			dispatch(getTaskListRequest({ ...requestPayload }));
		}
	};

	const onCancelTaskDetails = () => {
		setSelectedTask(null);
		setModalType('');
		setTaskIdFromSearchParams(undefined);
		history.replace({ pathname: `${ROUTE_PATH.TASK_MANAGER}` });
	};

	const onDeleteTask = () => {
		dispatch(deleteTaskRequest(selectedRowKeys));
	};

	const markTaskAsDone = () => {
		dispatch(markTaskAsDoneRequest(selectedRowKeys));
	};

	const resetData = () => {
		setSelectedRowKeys([]);
		setRequestPayload({ ...requestPayload, page: 1 });
		setModalType('');
	};

	const assignedAccountOptions = useMemo(() => {
		return accountListLite.map((item: AccountItemLiteDto) => ({
			value: item.email,
			label: item.fullName,
		}));
	}, [accountListLite]);

	const handleUpdateTask = (payload: { id: number; params: UpdateTaskPayloadDto }) => {
		dispatch(updateTaskRequest(payload));
	};

	const taskPosition = useMemo(() => {
		let positionRes = { isLast: false, isFirst: false };

		if (taskData?.tasks) {
			const index = taskData?.tasks?.findIndex(
				(item: TaskItemDto) => item.id === selectedTask?.id,
			);
			if (index !== -1) {
				positionRes = checkPositionContent(index, taskData?.tasks?.length || 0);
			}
		}

		return { ...positionRes };
	}, [selectedTask, taskData?.tasks]);

	const handleSwitchTask = (action: string) => {
		if (taskData?.tasks) {
			const currentIndex = taskData?.tasks?.findIndex(
				(item: TaskItemDto) => item.id === selectedTask?.id,
			);
			const switchedIndex =
				action === TYPE_ACTIONS.NEXT ? currentIndex + 1 : currentIndex - 1;
			const switchedTask = taskData?.tasks[switchedIndex];
			setSelectedTask(switchedTask);
		}
	};

	const renderConfirmationModal = () => {
		let content: string = '';
		let onSubmit: () => void;
		let okText = t('button.move');
		const isOpen: boolean = modalType === TASK_DETAIL_MODAL_TYPES.DELETE_TASK;

		if (modalType === TASK_DETAIL_MODAL_TYPES.DELETE_TASK) {
			content = t('task_manager.message.delete_task', {
				text: selectedRowKeys.length > 1 ? t('these') : t('this'),
				suffix: selectedRowKeys.length > 1 ? 's' : '',
			});
			okText = t('button.delete');
			onSubmit = onDeleteTask;
		}

		return (
			<ConfirmModal
				confirmLoading={deleteTaskStatus === IN_PROGRESS}
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => {
					setModalType('');
				}}>
				{content}
			</ConfirmModal>
		);
	};

	const contextValues = {
		taskManager,
		setTaskManager,
		requestPayload,
		setRequestPayload,
		selectedRowKeys,
		setSelectedRowKeys,
		selectedTask,
		setSelectedTask,
		modalType,
		setModalType,
		assignedAccountOptions,
		onDeleteTask,
		markTaskAsDone,
		handleUpdateTask,
	};

	return (
		<ConfigProvider renderEmpty={customizeRenderEmpty}>
			<TaskManagerContext.Provider value={contextValues}>
				<LoadingWrapper isLoading={fetchingTaskList}>
					<ActionBar />
					<TaskList />
					{(selectedTask ||
						(modalType && modalType === TASK_ACTION_TYPE.ADD_NEW_TASK) ||
						!!taskIdFromSearchParams) && (
						<TaskDetail
							onCancel={onCancelTaskDetails}
							taskId={
								taskIdFromSearchParams ? +taskIdFromSearchParams : selectedTask?.id
							}
							action={modalType}
							taskPosition={taskPosition}
							handleSwitchTask={handleSwitchTask}
							isDetailFromSearchParams={!!taskIdFromSearchParams}
						/>
					)}
				</LoadingWrapper>
				{modalType === TASK_DETAIL_MODAL_TYPES.SEND_REMINDER_EMAIL && (
					<ReminderEmail
						taskIds={selectedRowKeys}
						visible={modalType === TASK_DETAIL_MODAL_TYPES.SEND_REMINDER_EMAIL}
						onCancel={() => {
							setModalType('');
						}}
					/>
				)}
				{modalType &&
					modalType !== TASK_DETAIL_MODAL_TYPES.SEND_REMINDER_EMAIL &&
					renderConfirmationModal()}
			</TaskManagerContext.Provider>
		</ConfigProvider>
	);
};

export default TaskManager;
