import { createAction } from 'redux-actions';
import {
	getRewardByCreatorTypes,
	logRewardTypes,
	storeRewardDataTypes,
	updateRewardStatusTypes,
} from './rewards.types';

// Action constant
export const SET_INVENTORY_STATE = 'SET_INVENTORY_STATE';

export const UPDATE_REWARD_SELECT = 'UPDATE_REWARD_SELECT';

export const TOGGLE_COLUMN_LIST_REWARDS = 'TOGGLE_COLUMN_LIST_REWARDS';

export const GET_SEGMENT_REWARDS = 'GET_SEGMENT_REWARDS';
export const GET_SEGMENT_REWARDS_COMPLETED = 'GET_SEGMENT_REWARDS_COMPLETED';
export const GET_SEGMENT_REWARDS_FAILED = 'GET_SEGMENT_REWARDS_FAILED';

export const GET_REWARDS = 'GET_REWARDS';
export const GET_REWARDS_COMPLETED = 'GET_REWARDS_COMPLETED';
export const GET_REWARDS_FAILED = 'GET_REWARDS_FAILED';

export const CREATE_REWARD = 'CREATE_REWARD';
export const CREATE_REWARD_COMPLETED = 'CREATE_REWARD_COMPLETED';
export const CREATE_REWARD_FAILED = 'CREATE_REWARD_FAILED';

export const UPDATE_REWARD = 'UPDATE_REWARD';
export const UPDATE_REWARD_COMPLETED = 'UPDATE_REWARD_COMPLETED';
export const UPDATE_REWARD_FAILED = 'UPDATE_REWARD_FAILED';

export const SAVE_DRAFT_REWARD = 'SAVE_DRAFT_REWARD';
export const SAVE_DRAFT_REWARD_COMPLETED = 'SAVE_DRAFT_REWARD_COMPLETED';
export const SAVE_DRAFT_REWARD_FAILED = 'SAVE_DRAFT_REWARD_FAILED';

export const GO_LIVE_DRAFT_REWARD = 'GO_LIVE_DRAFT_REWARD';
export const GO_LIVE_DRAFT_REWARD_COMPLETED = 'GO_LIVE_DRAFT_REWARD_COMPLETED';
export const GO_LIVE_DRAFT_REWARD_FAILED = 'GO_LIVE_DRAFT_REWARD_FAILED';

export const GET_REWARDS_HISTORY = 'GET_REWARDS_HISTORY';
export const GET_REWARDS_HISTORY_COMPLETED = 'GET_REWARDS_HISTORY_COMPLETED';
export const GET_REWARDS_HISTORY_FAILED = 'GET_REWARDS_HISTORY_FAILED';

// Action function
export const setInventoryState = createAction(SET_INVENTORY_STATE);

export const updateRewardSelect = createAction(UPDATE_REWARD_SELECT);

export const handleColumnListRewards = createAction(TOGGLE_COLUMN_LIST_REWARDS);

export const getSegmentReward = createAction(GET_SEGMENT_REWARDS);
export const getSegmentRewardCompleted = createAction(GET_SEGMENT_REWARDS_COMPLETED);
export const getSegmentRewardFailed = createAction(GET_SEGMENT_REWARDS_FAILED);

export const getRewards = createAction(GET_REWARDS);
export const getRewardsCompleted = createAction(GET_REWARDS_COMPLETED);
export const getRewardsFailed = createAction(GET_REWARDS_FAILED);

export const createReward = createAction(CREATE_REWARD);
export const createRewardCompleted = createAction(CREATE_REWARD_COMPLETED);
export const createRewardFailed = createAction(CREATE_REWARD_FAILED);

export const updateReward = createAction(UPDATE_REWARD);
export const updateRewardCompleted = createAction(UPDATE_REWARD_COMPLETED);
export const updateRewardFailed = createAction(UPDATE_REWARD_FAILED);

export const saveDraftReward = createAction(SAVE_DRAFT_REWARD);
export const saveDraftRewardCompleted = createAction(SAVE_DRAFT_REWARD_COMPLETED);
export const saveDraftRewardFailed = createAction(SAVE_DRAFT_REWARD_FAILED);

export const goLiveDraftReward = createAction(GO_LIVE_DRAFT_REWARD);
export const goLiveDraftRewardCompleted = createAction(GO_LIVE_DRAFT_REWARD_COMPLETED);
export const goLiveDraftRewardFailed = createAction(GO_LIVE_DRAFT_REWARD_FAILED);

export const getRewardsHistory = createAction(GET_REWARDS_HISTORY);
export const getRewardsHistoryCompleted = createAction(GET_REWARDS_HISTORY_COMPLETED);
export const getRewardsHistoryFailed = createAction(GET_REWARDS_HISTORY_FAILED);

// Get Reward By Creator
export const getRewardByCreatorRequest = createAction(
	getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_REQUEST,
);
export const getRewardByCreatorSucceeded = createAction(
	getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_SUCCEEDED,
);
export const getRewardByCreatorFailed = createAction(
	getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_FAILED,
);
export const getRewardByCreatorEnd = createAction(
	getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_END,
);

// Update Reward Status
export const updateRewardStatusRequest = createAction(
	updateRewardStatusTypes.UPDATE_REWARD_STATUS_REQUEST,
);
export const updateRewardStatusSucceeded = createAction(
	updateRewardStatusTypes.UPDATE_REWARD_STATUS_SUCCEEDED,
);
export const updateRewardStatusFailed = createAction(
	updateRewardStatusTypes.UPDATE_REWARD_STATUS_FAILED,
);
export const updateRewardStatusEnd = createAction(updateRewardStatusTypes.UPDATE_REWARD_STATUS_END);

// Log Reward
export const logRewardRequest = createAction(logRewardTypes.LOG_REWARD_REQUEST);
export const logRewardSucceeded = createAction(logRewardTypes.LOG_REWARD_SUCCEEDED);
export const logRewardFailed = createAction(logRewardTypes.LOG_REWARD_FAILED);
export const logRewardEnd = createAction(logRewardTypes.LOG_REWARD_END);

export const storeRewardData = createAction(storeRewardDataTypes.STORE_REWARD_DATA_REQUEST);
