import { IconMoreVert } from '@assets/icons';
import {
	DEFAULT_TASK_FILTERS,
	TASK_ACTION_TYPE,
	TASK_DETAIL_MODAL_TYPES,
	TASK_MANAGER_FIELD_NAME,
	TASK_PARAMS_DEFAULT,
	TASK_STATUS,
} from '@constants/taskManager';
import THEME from '@constants/themes/themes';
import TaskManagerContext from '@contexts/TaskManager';
import { storeUserData } from '@stores/actions';
import { UserStoreType } from '@stores/user/user.types';
import {
	StyledButton,
	StyledCol,
	StyledDropdown,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledIcon,
	StyledRow,
	StyledSection,
	StyledSelect,
	StyledText,
} from '@styled/Common/CommonStyled';
import { StyledSearchDescription } from '@styled/TaskManager/TaskManagerStyled';
import { formatPayload } from '@utils/funcHelper';
import { menuActions } from '@utils/renderComponent';
import { useForm } from 'antd/lib/form/Form';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const ActionBar = () => {
	const [form] = useForm();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		requestPayload,
		setRequestPayload,
		setModalType,
		assignedAccountOptions,
		markTaskAsDone,
		selectedRowKeys,
	} = useContext(TaskManagerContext);
	const { user, fetchingUser }: UserStoreType = useSelector((state: any) => state.user);
	const searchBarRef = useRef<any>({});

	useEffect(() => {
		if (user && !fetchingUser) {
			let filters;
			const { accountSettings = {} } = user || {};
			if (accountSettings && accountSettings.taskFilters) {
				filters =
					accountSettings.taskFilters.find(
						(item: any) => item.clientId === user.clientId,
					) || {};

				filters = formatPayload(filters);
				delete filters.clientId;
				form.setFieldsValue({ ...filters });
			}
			setRequestPayload({ ...TASK_PARAMS_DEFAULT, ...filters });
		}
	}, [user, fetchingUser]);

	const renderActions = () => {
		const menuItems = [
			{
				id: 1,
				name: t('task_manager.send_reminder_email'),
				click: () => {
					setModalType(TASK_DETAIL_MODAL_TYPES.SEND_REMINDER_EMAIL);
				},
				disabled: !selectedRowKeys.length,
			},
			{
				id: 2,
				name: t('task_manager.mark_as_done'),
				click: markTaskAsDone,
				disabled: !selectedRowKeys.length,
			},
			{
				id: 3,
				name: t('button.delete'),
				click: () => {
					setModalType(TASK_DETAIL_MODAL_TYPES.DELETE_TASK);
				},
				disabled: !selectedRowKeys.length,
			},
		];

		return (
			<StyledDropdown overlay={menuActions(menuItems)} placement="bottomRight">
				<StyledIcon>
					<IconMoreVert />
				</StyledIcon>
			</StyledDropdown>
		);
	};

	const statusOptions = [
		{ value: TASK_STATUS.TO_DO, label: t('task_manager.status.to_do') },
		{ value: TASK_STATUS.IN_PROGRESS, label: t('task_manager.status.in_progress') },
		{ value: TASK_STATUS.DONE, label: t('task_manager.status.done') },
	];

	const dropdownList = [
		{
			id: 1,
			placeholder: t('task_manager.placeholder.status'),
			options: statusOptions,
			name: TASK_MANAGER_FIELD_NAME.STATUS,
		},
		{
			id: 2,
			placeholder: t('task_manager.placeholder.assigned_to'),
			options: assignedAccountOptions,
			name: TASK_MANAGER_FIELD_NAME.ASSIGNED_TO_EMAILS,
		},
		{
			id: 3,
			placeholder: t('task_manager.placeholder.assigned_by'),
			options: assignedAccountOptions,
			name: TASK_MANAGER_FIELD_NAME.ASSIGNED_BY_EMAILS,
		},
	];

	const onValuesChange = (changedValues: any) => {
		const fieldName = Object.keys(changedValues)[0];

		setRequestPayload({
			...requestPayload,
			[fieldName]: changedValues[fieldName],
			page: 1,
		});
	};

	const isFiltering = useMemo(() => {
		return (
			requestPayload &&
			(requestPayload.description ||
				(requestPayload.assignedByEmails && !!requestPayload.assignedByEmails.length) ||
				(requestPayload.assignedToEmails && !!requestPayload.assignedToEmails.length) ||
				(requestPayload.status && !!requestPayload.status?.length))
		);
	}, [requestPayload]);

	const clearFilters = () => {
		form.resetFields();
		const updatedUser = { ...user };
		const { accountSettings = {} } = updatedUser || {};
		if (accountSettings && accountSettings.taskFilters) {
			const index =
				accountSettings.taskFilters.findIndex(
					(item: any) => item.clientId === user?.clientId,
				) || {};
			if (index !== -1 && DEFAULT_TASK_FILTERS) {
				accountSettings.taskFilters[index] = {
					...DEFAULT_TASK_FILTERS,
				};
			}
		}
		if (searchBarRef && searchBarRef.current) {
			searchBarRef.current.setValueNotSearch('');
		}
		dispatch(storeUserData({ user: updatedUser }));
	};

	return (
		<StyledSection margin="0 0 16px 0">
			<StyledFlex align="center">
				<StyledForm onValuesChange={onValuesChange} form={form} layout="vertical">
					<StyledRow align="middle" gutter={[12, 12]}>
						{isFiltering && (
							<StyledCol>
								<StyledText cursor="pointer" onClick={clearFilters}>
									{t('task_manager.action_bar.clear_filters')}
								</StyledText>
							</StyledCol>
						)}
						<StyledCol>
							<StyledSearchDescription
								ref={searchBarRef}
								placeholder={t('task_manager.placeholder.search_task')}
								onSearch={(keyword: string) => {
									setRequestPayload({
										...requestPayload,
										description: keyword,
										page: 1,
									});
								}}
								style={{
									margin: '0 2px 0 0',
								}}
							/>
						</StyledCol>
						{dropdownList.map(
							(item: {
								id: number;
								placeholder: string;
								options: any;
								name: string;
							}) => {
								return (
									<StyledCol key={item.id}>
										<StyledFormItem margin="0" name={item.name}>
											<StyledSelect
												showArrow
												allowClear
												mode="multiple"
												maxTagCount={2}
												maxTagTextLength={10}
												placeholder={item.placeholder}
												minWidth="153px"
												maxWidth="300px"
												options={item?.options}
												bgSelector={THEME.colors.transparent}
											/>
										</StyledFormItem>
									</StyledCol>
								);
							},
						)}
					</StyledRow>
				</StyledForm>

				<StyledFlex>
					{renderActions()}
					<StyledButton
						margin="0 0 0 12px"
						type="primary"
						onClick={() => {
							setModalType(TASK_ACTION_TYPE.ADD_NEW_TASK);
						}}>
						{t('task_manager.create_new_task')}
					</StyledButton>
				</StyledFlex>
			</StyledFlex>
		</StyledSection>
	);
};

export default ActionBar;
