import { UNIT_VALUE } from '@constants/common';
import {
	CONTENT_FLAGS,
	EMPTY_NEW_LINE,
	FORM_CONTENT_DETAIL,
	MARK_AS,
	TEXT_SIZE,
	UPLOADSOURCE,
	UPLOADMETHOD,
	VIEW_TYPE,
} from '@constants/content/contentLibrary';
import {
	CustomFieldsContentDto,
	BoundingBoxDto,
	CoordinateDto,
	DataInfoAdditionalType,
	ContentDto,
	ContentReviewDto,
} from '@models/content/contentLibrary/summary';
import { getSocialS3URL } from './common';
import { TYPE_FORM_CUSTOM_FIELDS } from '@constants/contentLibrary/contentDetail';
import { isEqual } from 'lodash';
import { ValueTagDto } from '@models/filter';

const getDimension = (body: string) => {
	const bodyArr = body?.split(EMPTY_NEW_LINE) || [];
	let height = '';
	let width = '';

	bodyArr.forEach((item) => {
		if ((item.includes(TEXT_SIZE.IMAGE_H) || item.includes(TEXT_SIZE.VIDEO_H)) && !height) {
			height = item;
		}
		if ((item.includes(TEXT_SIZE.IMAGE_W) || item.includes(TEXT_SIZE.VIDEO_W)) && !width) {
			width = item;
		}
	});

	if (height) {
		const textFindH = height?.includes(TEXT_SIZE.IMAGE_H)
			? TEXT_SIZE.IMAGE_H
			: TEXT_SIZE.VIDEO_H;
		const idxPixelH = height?.includes(TEXT_SIZE.PIXEL)
			? height?.indexOf(TEXT_SIZE.PIXEL) - 1
			: height.length;

		height = height.slice(textFindH.length, idxPixelH) || '';
	}
	if (width) {
		const textFindW = width?.includes(TEXT_SIZE.IMAGE_W)
			? TEXT_SIZE.IMAGE_W
			: TEXT_SIZE.VIDEO_W;
		const idxPixelW = width?.includes(TEXT_SIZE.PIXEL)
			? width?.indexOf(TEXT_SIZE.PIXEL) - 1
			: width.length;

		width = width.slice(textFindW.length, idxPixelW) || '';
	}

	return {
		height,
		width,
	};
};

const getImageView = (width: number, height: number) => {
	let classImage: string = '';

	if (width === height) {
		classImage = VIEW_TYPE.SQUARE;
	} else if (width > height) {
		classImage = VIEW_TYPE.LANDSCAPE;
	} else if (width < height) {
		classImage = VIEW_TYPE.PORTRAIT;
	}

	return classImage;
};

const convertBoundingBox = (boundingBox: CoordinateDto[]) => {
	return {
		top: boundingBox[0].y,
		left: boundingBox[0].x,
		width: boundingBox[1].x - boundingBox[0].x,
		height: boundingBox[boundingBox.length - 1].y - boundingBox[0].y,
	};
};

const setDetectedLogoPosition = (
	contentRef: HTMLImageElement,
	naturalDetectedPosition: BoundingBoxDto,
): BoundingBoxDto => {
	let renderedSize: BoundingBoxDto = {
		top: 0,
		left: 0,
		height: 0,
		width: 0,
	};

	if (contentRef) {
		const elementDimension = {
			offsetWidth: contentRef.offsetWidth,
			offsetHeight: contentRef.offsetHeight,
			naturalWidth: contentRef.naturalWidth,
			naturalHeight: contentRef.naturalHeight,
		};

		const renderedRatio = {
			left: elementDimension.offsetWidth / elementDimension.naturalWidth,
			top: elementDimension.offsetHeight / elementDimension.naturalHeight,
		};

		renderedSize = {
			left: renderedRatio.left * naturalDetectedPosition.left,
			top: renderedRatio.top * naturalDetectedPosition.top,
			width: renderedRatio.left * naturalDetectedPosition.width,
			height: renderedRatio.top * naturalDetectedPosition.height,
		};
	}

	return renderedSize;
};

const formatDataAdditional = (additionalFields: CustomFieldsContentDto[]) => {
	const result: DataInfoAdditionalType = {
		single: [],
		multi: [],
		paragraph: [],
	};

	additionalFields?.forEach((item: CustomFieldsContentDto) => {
		const values = typeof item.values === 'string' ? [item.values || ''] : item.values || [];
		const { name = '', placeholder = '' } = item;
		const newItem = { ...item, values, name: name || placeholder };

		if (Array.isArray(item?.values)) {
			result.multi.push(newItem);
		} else if (values?.length === 1 && values[0]?.length > UNIT_VALUE.S_50) {
			result.paragraph.push(newItem);
		} else {
			result.single.push(newItem);
		}
	});

	return result;
};

const thumbnailContentInEmail = (thumbnailUrl?: string) => {
	return `<div id='content-thumbnail' style='text-align: center;'><img style='width: 300px; height: 300px; object-fit: cover; border-radius: 4px;' src='${getSocialS3URL(
		thumbnailUrl,
	)}'/></div>`;
};

const formatDetectionTime = (seconds: number) => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = Math.floor(seconds % 60);

	const formattedHours = hours < 10 ? `0${hours}` : hours;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
	const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

	if (hours > 0) {
		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	} else {
		return `${formattedMinutes}:${formattedSeconds}`;
	}
};

const formatReviewContentRequest = (values: any, contentMedia: ContentDto | null) => {
	const { markedAs = [], tags = [], star, ...other } = values;
	const customFields: CustomFieldsContentDto[] = contentMedia?.review?.custom || [];

	const contacted: boolean = markedAs.includes(MARK_AS.CONTACTED.label);
	const rejected: boolean = markedAs.includes(MARK_AS.REJECTED.label);
	const pending: boolean = markedAs.includes(MARK_AS.PENDING.additionalLabel);
	const affiliate: boolean = markedAs.includes(MARK_AS.AFFILIATE.label);
	let hasChangeLabelCustomField: boolean = false;

	const arrKey = Object.keys(other);
	const arrType = [
		TYPE_FORM_CUSTOM_FIELDS.input,
		TYPE_FORM_CUSTOM_FIELDS.dropdown,
		TYPE_FORM_CUSTOM_FIELDS.paragraph,
	];

	const funcFindItem = (key: string) => {
		return customFields.find((cus) => cus.key === key);
	};

	const arrCustom: CustomFieldsContentDto[] = [];

	arrKey.forEach((key) => {
		let idx = '';
		const isLabel = key.includes('_label_');
		arrType.forEach((type) => {
			if (key.includes(type)) {
				if (isLabel) {
					idx = key.slice(`${type}_label_`.length);
				} else {
					idx = key.slice(type.length + 1);
				}
			}
		});
		if (idx) {
			delete other[key];
			const itemFindByKey = funcFindItem(idx);

			if (itemFindByKey) {
				const idxFindExist = arrCustom.findIndex((cus) => cus.key === itemFindByKey.key);

				const valChange =
					typeof values[key] === 'string' ? values[key]?.trim() : values[key] || '';
				const valDefault =
					typeof itemFindByKey.values === 'string'
						? itemFindByKey.values.trim()
						: itemFindByKey.values || '';

				const onPushVal = () => {
					if (idxFindExist !== -1) {
						arrCustom[idxFindExist].values = valChange;
					} else {
						arrCustom.push({ ...itemFindByKey, values: valChange });
					}
				};
				if (isLabel) {
					if (valChange !== (itemFindByKey?.name?.trim() || '') && valChange) {
						hasChangeLabelCustomField = true;
						if (idxFindExist !== -1) {
							arrCustom[idxFindExist].name = valChange || '';
						} else {
							arrCustom.push({ ...itemFindByKey, name: valChange || '' });
						}
					}
				} else {
					if (typeof values[key] === 'object') {
						if (!isEqual(values[key], itemFindByKey?.values)) {
							onPushVal();
						}
					} else {
						if (valChange !== valDefault) {
							onPushVal();
						}
					}
				}
			}
		}
	});

	const arrLabel: string[] = [];
	const newLabels: string[] = [];
	tags?.forEach((item: string) => {
		try {
			const val: ValueTagDto = JSON.parse(item);
			if (val?.group === FORM_CONTENT_DETAIL.LABELS) {
				arrLabel.push(val?.val);
			}
		} catch (error) {
			newLabels.push(item);
			console.log(error);
		}
	});

	const requestData: ContentReviewDto = {
		...contentMedia?.review,
		...other,
		star: star || 0,
		labels: [...arrLabel, ...newLabels],
		contacted,
		rejected,
		pending,
		affiliate,
		hasChangeLabelCustomField,
	};

	if (arrCustom.length > 0) {
		requestData.custom = arrCustom;
	}

	if (!Object.keys(contentMedia?.review || {}).includes('note') && !requestData?.note) {
		delete requestData.note;
	}

	return requestData;
};

const checkListNotExist = (list: string[], listCheck: string[]) => {
	const result: string[] = [];
	listCheck.forEach((str) => {
		if (!list.includes(str)) {
			result.push(str);
		}
	});
	return result;
};

const removeDuplicateGroup = (list: string[], list2: string[]) => {
	const result = [...list];
	list2.forEach((item) => {
		if (!result.includes(item?.trim())) {
			result.push(item);
		}
	});
	return result;
};

const checkExistExplicit = (flags?: string[] | null) => {
	let result = false;
	if (!flags) {
		return result;
	}

	if (flags && flags.length > 0) {
		flags.forEach((f) => {
			if (
				f &&
				f.trim() &&
				f.trim().toLowerCase() === CONTENT_FLAGS.EXPLICIT_CONTENT.toLowerCase()
			) {
				result = true;
			}
		});
	}
	return result;
};

const getDirectUploadSourceText = (
	uploadSource: string | undefined,
	uploadMethod: string | undefined,
) => {
	let titleSource: string = '';
	switch (uploadSource) {
		case UPLOADSOURCE.SOCIAL_SEARCH:
			titleSource = 'content_management.details.bookmarks';
			break;
		case UPLOADSOURCE.SOCIAL_UPLOAD:
			titleSource = 'ambassador.ambassador_post';
			break;
		case UPLOADSOURCE.MANUAL_IMPORT:
			titleSource = 'content_management.details.manual_import';
			break;
		case UPLOADSOURCE.SCRIPT_IMPORT:
			titleSource = 'content_management.details.script_import';
			break;
		default:
			switch (uploadMethod) {
				case UPLOADMETHOD.TIKTOK_UPLOAD:
					titleSource = 'filter.filter_content.shared_tiktok';
					break;
				case UPLOADMETHOD.YOUTUBE_UPLOAD:
					titleSource = 'filter.filter_content.shared_youtube';
					break;
				case UPLOADMETHOD.GOOGLE_DRIVE_UPLOAD:
					titleSource = 'filter.filter_content.shared_google_drive';
					break;
				case UPLOADMETHOD.INSTAGRAM_UPLOAD:
					titleSource = 'filter.filter_content.shared_instagram';
					break;
				case UPLOADMETHOD.FACEBOOK_UPLOAD:
					titleSource = 'filter.filter_content.shared_facebook';
					break;
				case UPLOADMETHOD.TWITTER_UPLOAD:
					titleSource = 'filter.filter_content.shared_twitter';
					break;
				default:
					titleSource = 'content_management.details.direct_upload';
					break;
			}
			break;
	}
	return titleSource;
};

const changeAllTitleCase = (str: string) => {
	const splitStr = str
		.toLowerCase()
		.split(' ')
		.map((text) => {
			return text.charAt(0).toUpperCase() + text.substring(1);
		});

	return splitStr.join(' ');
};

export {
	convertBoundingBox,
	getDimension,
	getImageView,
	setDetectedLogoPosition,
	formatDataAdditional,
	thumbnailContentInEmail,
	formatDetectionTime,
	formatReviewContentRequest,
	checkExistExplicit,
	checkListNotExist,
	getDirectUploadSourceText,
	removeDuplicateGroup,
	changeAllTitleCase,
};
