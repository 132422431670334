import { IconMoreVert } from '@assets/icons';
import SocialDetailContext from '@contexts/SocialSearch/SocialDetail';
import { ActionsTypes } from '@models/socialsearch/summary';
import { StyledDropdown, StyledIconWrapper } from '@styled/Common/CommonStyled';
import {
	StyledBtn,
	StyledBtnGhost,
	StyledSection,
} from '@styled/Content/ContentLibrary/ContentStyled';
import { menuActions } from '@utils/renderComponent';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const SocialFooter = () => {
	const { t } = useTranslation();
	const {
		onSaveImport,
		onViewPost,
		onBlockContent,
		onBlockCreator,
		handleRequestRights,
		idFeed,
		socialBlockCreatorDisable,
		socialBookmarkDisable,
	} = useContext(SocialDetailContext);

	const actionsObj: ActionsTypes[] = [
		{
			name: t('button.view_post'),
			click: onViewPost,
		},
		{
			name: idFeed ? t('button.hide_post') : t('button.block_content'),
			click: onBlockContent,
		},
		{
			name: t('button.block_creator'),
			click: onBlockCreator,
			disabled: !!socialBlockCreatorDisable,
		},
	];

	return (
		<StyledSection
			className="custom-section"
			padding="0 0 0 -24px"
			bottomCustom="0px"
			borderPosition="top"
			heightSection="64px"
			flexDirection="row"
			justify="flex-start">
			<StyledBtn width="126px" type="primary" onClick={handleRequestRights}>
				{t('button.request_rights')}
			</StyledBtn>
			<StyledDropdown
				overlay={menuActions(actionsObj)}
				placement="topLeft"
				trigger={['click']}>
				<StyledIconWrapper cursor="pointer" margin="0 8px">
					<IconMoreVert />
				</StyledIconWrapper>
			</StyledDropdown>
			<StyledBtnGhost
				isDisabled={!!socialBookmarkDisable}
				type="ghost"
				onClick={onSaveImport}>
				{t('button.bookmark_this_post')}
			</StyledBtnGhost>
		</StyledSection>
	);
};

export default SocialFooter;
