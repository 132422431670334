import { IconEye } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { StyledIcon, StyledTitle, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledMarkExplicit } from '@styled/Content/ContentLibrary/MediaItemStyled';
import { useTranslation } from 'react-i18next';

export type ExplicitContentItemTypes = {
	zIndex?: number;
	sizeIcon: number;
	fontSize?: string;
	[other: string]: any;
};

const ExplicitContent = ({ zIndex, sizeIcon, fontSize, ...other }: ExplicitContentItemTypes) => {
	const { t } = useTranslation();
	return (
		<StyledMarkExplicit zIndex={zIndex} {...other}>
			<StyledWrapperContent textAlign="center" maxWidth="100%">
				<StyledIcon fillPath={THEME.colors.gray4} size={sizeIcon}>
					<IconEye />
				</StyledIcon>
				<StyledTitle
					className="unset_height overflow_text"
					padding="0 6px"
					margin="8px 0 0 0"
					fontSize={fontSize}>
					{t('content_management.sensitive_content')}
				</StyledTitle>
			</StyledWrapperContent>
		</StyledMarkExplicit>
	);
};

export default ExplicitContent;
