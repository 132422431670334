import {
	IconAltAdd,
	IconCancel,
	IconCheck,
	IconDelete,
	IconExpandMore,
	IconNote,
} from '@assets/icons';
import { WIDGET_BUILDER } from '@constants/APIs';
import { KEY_FIELD_FORM_TERM, TYPE_TERM } from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { putRequest } from '@helpers/requestHelpers';
import { ActionsTypes } from '@models/content/albums/albumManager';
import {
	CustomizedValuesTermItemDto,
	InvalidTypeDto,
	TermConditionItemDto,
} from '@models/content/widgetManager';
import { StyledDropdown, StyledIcon, StyledWrapperAction } from '@styled/Common/CommonStyled';
import {
	StyledNameOption,
	StyledOptionItem,
	StyledWrapperOverlayDropdown,
} from '@styled/Content/ContentLibrary/ActionBarStyled';
import {
	StyledEmptyUpload,
	StyledFilePreview,
	StyledIconRemoveCircle,
	StyledNameUpload,
	StyledUpload,
} from '@styled/Content/WidgetManager';
import { StyledCheckbox, StyledLink } from '@styled/Content/WidgetManager/DetailWidgetStyled';
import {
	StyledCustomFieldItem,
	StyledFieldItem,
	StyledFormItem,
	StyledInputCustomField,
	StyledLabelField,
	StyledTermItem,
	StyledTextArea,
	StyledWrapperLabel,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { validateSizeFile } from '@utils/common';
import { Upload, message } from 'antd';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtherDetailTerm from '../OtherDetailTerm';

type TermItemProps = {
	keyIndex: number | string;
	getValueField: (val: string) => any;
	handleRemoveField?: () => void;
	setFieldsValue: (val: any) => void;
	term: TermConditionItemDto;
	handleUpdateListTerms?: (val: TermConditionItemDto[]) => void;
	handleChangeRequired: (terms: TermConditionItemDto, keyIndex: number) => void;
	handleSwitchPopup?: () => void;
	handleVisibleButtonCreator?: (idx: number) => void;
	customizedValues?: CustomizedValuesTermItemDto;
	isTerms?: boolean;
};

const TermItem: FC<TermItemProps> = (props) => {
	const { t } = useTranslation();

	const {
		keyIndex,
		getValueField,
		handleRemoveField,
		setFieldsValue,
		handleSwitchPopup,
		handleUpdateListTerms,
		handleVisibleButtonCreator,
		handleChangeRequired,
		term,
		isTerms = true,
		customizedValues,
		...other
	} = props;

	const { detailWidget, setIsLoading } = useContext(WidgetBuilderContext);
	const { otherInfo = {}, body = {} } = detailWidget.metadata || {};

	const { termsConditions = [] } = body || {};
	const {
		requireCreator,
		type,
		required,
		nameFile = '',
		isDefault = false,
		url = '',
		text,
		textLink,
		textPopup,
		buttonText,
	} = term || {};
	const {
		linkTitle = '',
		hyperlinkSuffix = '',
		showRequiredCheckbox = true,
		allowRemoving = true,
		isTextAreaMainText = false,
	} = customizedValues || {};

	const [fileList, setFileList] = useState<any[]>([]);

	const nameText = useMemo(() => `${KEY_FIELD_FORM_TERM.text}${keyIndex}`, [keyIndex]);
	const nameLink = useMemo(() => `${KEY_FIELD_FORM_TERM.textLink}${keyIndex}`, [keyIndex]);
	const nameValue = useMemo(() => `${KEY_FIELD_FORM_TERM.valueTextLink}${keyIndex}`, [keyIndex]);
	const nameButtonText = useMemo(
		() => `${KEY_FIELD_FORM_TERM.buttonText}${keyIndex}`,
		[keyIndex],
	);

	const valueLink = useMemo(() => {
		let result: string = '';
		if (type === TYPE_TERM.link || type === TYPE_TERM.popupFile) {
			result = url || '';
		} else {
			result = textPopup || '';
		}
		return result;
	}, [type, term]);

	const isPopup = useMemo(() => {
		return type === TYPE_TERM.popupFile || type === TYPE_TERM.popupText;
	}, [type]);

	const hasInputRequireCreator = isPopup && requireCreator;

	const renderLabel = (index: number) => {
		let label = '';
		let suffixText = '';

		if (index === 0) {
			if (type === TYPE_TERM.text) {
				label = 'add_directly_uploader';
			} else {
				if (type !== TYPE_TERM.link) {
					label = 'link_to_popup';
				} else {
					label = linkTitle || (isDefault ? 'link_to_entribe' : 'link_to_webpage');
				}
			}
		}
		if (index === 1) {
			label = 'hyperlink_text';
			suffixText = hyperlinkSuffix;
		}
		if (index === 2) {
			if (type !== TYPE_TERM.link) {
				label = 'popup_text';
			} else {
				label = 'hyperlink_url';
				suffixText = hyperlinkSuffix;
			}
		}
		if (index === 3 && hasInputRequireCreator) {
			label = 'button_text_inside_popup';
		}
		return `${t(`content.widget_manager.widget_builder.${label}`)} ${suffixText}`;
	};

	const propsUpload = {
		beforeUpload: (file: any) => {
			const checkTypeFile =
				file.type === 'application/pdf' || file.type === 'application/vnd.ms-excel';

			if (!checkTypeFile) {
				message.error(
					t('content.widget_manager.message.upload_failed', { fileName: file.name }),
				);
			}
			return checkTypeFile ? true : Upload.LIST_IGNORE;
		},
		showUploadList: {
			removeIcon: <IconDelete />,
		},
	};

	const handleChangeUploadFile = async ({ fileList, file }: any) => {
		const formData = new FormData();
		formData.append('widgetId', detailWidget?.id);
		formData.append('file', file);
		setIsLoading(true);
		const resultValidate: InvalidTypeDto = validateSizeFile(file?.size);
		if (resultValidate?.invalid) {
			putRequest(`${WIDGET_BUILDER}/file`, formData)
				.then((response) => {
					setIsLoading(false);
					if (response.status === 200) {
						message.success(
							t('content.widget_manager.message.upload_success', {
								fileName: file.name,
							}),
						);
						if (response.body.data?.result) {
							setFieldsValue({
								[nameValue]: response.body.data?.result,
							});
							const newList = [
								...termsConditions.slice(0, keyIndex),
								{
									...term,
									url: response.body.data?.result,
									nameFile: file?.name,
								},
								...termsConditions.slice(+keyIndex + 1),
							];
							if (handleUpdateListTerms) {
								handleUpdateListTerms([...newList]);
							}
						}
					}
				})
				.catch((err) => {
					setIsLoading(false);
					message.error(err);
				});
		} else {
			setIsLoading(false);
			message.error(t(resultValidate.err, resultValidate?.params));
		}
	};

	const handleRemoveUploadedFile = () => {
		setFieldsValue({
			[nameValue]: '',
		});
		const newList = [
			...termsConditions.slice(0, keyIndex),
			{ ...term, url: '', nameFile: '' },
			...termsConditions.slice(+keyIndex + 1),
		];
		if (handleUpdateListTerms) {
			handleUpdateListTerms([...newList]);
		}
	};

	const handleUpdateRequireCreator = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (handleVisibleButtonCreator) {
			handleVisibleButtonCreator(+keyIndex);
		}
	};

	const renderFieldUrl = () => {
		if (type === TYPE_TERM.link) {
			return renderInputField(nameValue);
		}
		if (type === TYPE_TERM.popupText) {
			return (
				<StyledTextArea
					id={nameValue}
					autoSize={{ minRows: 3, maxRows: 3 }}
					value={getValueField(nameValue)}
					rows={3}
					placeholder={t('content.widget_manager.widget_builder.placeholder_body_popup')}
				/>
			);
		}
		if (type === TYPE_TERM.popupFile) {
			return (
				<StyledUpload
					{...propsUpload}
					showUploadList={false}
					maxCount={1}
					accept="application/pdf,application/vnd.ms-excel"
					className="upload_custom_widget"
					beforeUpload={() => false}
					fileList={fileList}
					onChange={handleChangeUploadFile}>
					{!nameFile ? (
						<StyledEmptyUpload>
							<StyledIcon>
								<IconAltAdd />
							</StyledIcon>
							{t('content.widget_manager.widget_builder.upload_pdf')}
						</StyledEmptyUpload>
					) : (
						<StyledFilePreview>
							<StyledIcon margin="0 4px 0 0">
								<IconNote />
							</StyledIcon>
							<StyledNameUpload line={2}>{nameFile}</StyledNameUpload>
							<IconCancel
								style={{ width: '16px', height: '16px', marginLeft: '6px' }}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									handleRemoveUploadedFile();
								}}
							/>
						</StyledFilePreview>
					)}
				</StyledUpload>
			);
		}
		return null;
	};

	const renderInputField = (name: string) => {
		let placeholder = 'add_main_text';

		switch (name) {
			case nameLink:
				placeholder = 'add_hyperlink_text';
				break;
			case nameValue:
				placeholder = 'placeholder_link';
				break;
			case nameButtonText:
				placeholder = 'add_button_text';
				break;
			default:
				break;
		}

		if (isTextAreaMainText && placeholder === 'add_main_text') {
			return (
				<StyledTextArea
					value={getValueField(name)}
					maxLength={otherInfo?.maxLengthShortText || 255}
					id={name}
					placeholder={t(`content.widget_manager.widget_builder.${placeholder}`)}
					autoSize={{ minRows: 3, maxRows: 4 }}
					rows={4}
				/>
			);
		}

		return (
			<StyledInputCustomField
				value={getValueField(name)}
				maxLength={otherInfo?.maxLengthShortText || 255}
				id={name}
				placeholder={t(`content.widget_manager.widget_builder.${placeholder}`)}
			/>
		);
	};

	const renderTextSwitch = () => {
		return (
			<StyledLink
				style={{ display: 'block' }}
				margin="14px 0 0 0"
				onClick={handleSwitchPopup}
				color={THEME.colors.orangeBase}>
				{t(
					`content.widget_manager.widget_builder.${
						type === TYPE_TERM.popupFile ? 'sub_edit_body' : 'sub_upload_pdf'
					}`,
				)}
			</StyledLink>
		);
	};

	const renderOtherPropCheckboxPopup = () => {
		if (hasInputRequireCreator) {
			return {
				bgCheckbox: THEME.colors.gray5,
				colorBorderCheckbox: THEME.colors.gray5,
			};
		}
		return {};
	};

	const listItem = [
		{
			name: nameText,
			content: renderInputField(nameText),
			value: text,
			key: 0,
		},
		{
			name: nameLink,
			content: renderInputField(nameLink),
			value: textLink,
			key: 1,
		},
		{
			name: nameValue,
			content: renderFieldUrl(),
			value: valueLink,
			key: 2,
		},
		{
			name: nameButtonText,
			content: renderInputField(nameButtonText),
			value: buttonText,
			key: 3,
		},
	];

	const actionMore: ActionsTypes[] = [
		{
			id: 26,
			name: t('content.widget_manager.widget_builder.require_creator'),
			click: handleUpdateRequireCreator,
		},
	];

	const menuActions = (
		<StyledWrapperOverlayDropdown width="350px">
			{actionMore.map((item: ActionsTypes) => (
				<StyledOptionItem key={item.id} onClick={item.click}>
					<StyledNameOption>{item.name}</StyledNameOption>
					{!!requireCreator && (
						<StyledIcon>
							<IconCheck />
						</StyledIcon>
					)}
				</StyledOptionItem>
			))}
		</StyledWrapperOverlayDropdown>
	);

	useEffect(() => {
		if (url) {
			const dataImage = [
				{
					name: nameFile,
					url,
				},
			];

			setFileList(dataImage);
		}
	}, [url]);

	useEffect(() => {
		const otherForm: any = {};

		if (type === TYPE_TERM.text) {
			otherForm[nameText] = text;
		} else {
			listItem.forEach((item) => {
				otherForm[item.name] = item.value;
			});
		}
		setFieldsValue({
			...otherForm,
		});
	}, [term, type]);

	return (
		<StyledFieldItem {...other}>
			<StyledCustomFieldItem>
				{type === TYPE_TERM.text && (
					<StyledTermItem>
						<StyledFormItem
							className="label_full"
							name={nameText}
							margin={'0 0 12px 0'}
							label={
								<StyledWrapperLabel className="width_full">
									<StyledLabelField>{renderLabel(0)}</StyledLabelField>

									<StyledCheckbox
										className="checkbox_full"
										onChange={(e: any) => {
											e.preventDefault();
											e.stopPropagation();
											handleChangeRequired(
												{ ...term, required: !required },
												+keyIndex,
											);
										}}
										checked={required}
									/>
								</StyledWrapperLabel>
							}
							rules={[
								{
									required: true,
									message: t('content.widget_manager.widget_builder.required'),
								},
							]}>
							<StyledTextArea
								maxLength={otherInfo?.maxLengthParagraph || 8000}
								autoSize={{ minRows: 3, maxRows: 3 }}
								value={getValueField(nameText)}
								rows={3}
								placeholder={t(
									'content.widget_manager.widget_builder.placeholder_directly_uploader',
								)}
							/>
						</StyledFormItem>
					</StyledTermItem>
				)}
				{type !== TYPE_TERM.text &&
					listItem?.map((item, idx) => {
						const isHidden =
							(idx === 0 && hasInputRequireCreator) ||
							(idx === listItem.length - 1 && !hasInputRequireCreator);
						return (
							<StyledTermItem
								className={`${isHidden ? 'hidden_input' : ''}`}
								key={item?.key}>
								<StyledFormItem
									className="label_full"
									name={item?.name}
									style={{ position: 'relative' }}
									margin={idx == listItem.length - 1 ? '0' : '0 0 12px 0'}
									label={
										<StyledWrapperLabel className="width_full">
											<StyledLabelField>{renderLabel(idx)}</StyledLabelField>
											{((idx === 0 && !hasInputRequireCreator) ||
												(hasInputRequireCreator && idx === 1)) && (
												<StyledWrapperAction>
													{isPopup && (
														<StyledDropdown
															className={`dropdown_action ${
																required ? '' : 'disabled'
															}`}
															overlay={menuActions}
															getPopupContainer={(
																triggerNode: HTMLElement,
															) =>
																triggerNode.parentNode?.parentNode
																	?.parentNode?.parentNode
																	?.parentNode as HTMLElement
															}
															placement="bottomLeft"
															trigger={['click']}>
															<StyledIcon margin="0 8px 0 0">
																<IconExpandMore />
															</StyledIcon>
														</StyledDropdown>
													)}
													{showRequiredCheckbox && (
														<StyledCheckbox
															className="checkbox_full"
															style={{ marginLeft: '8px' }}
															destroyPopupOnHide={true}
															{...renderOtherPropCheckboxPopup()}
															onChange={() => {
																const otherChange: any = {};
																if (requireCreator && required) {
																	otherChange.requireCreator =
																		false;
																	otherChange.buttonText = '';
																}

																handleChangeRequired(
																	{
																		...term,
																		required: !required,
																		...otherChange,
																	},
																	+keyIndex,
																);
															}}
															checked={required}
														/>
													)}
												</StyledWrapperAction>
											)}
										</StyledWrapperLabel>
									}
									rules={
										!renderLabel(idx).includes('optional')
											? [
													{
														required: isHidden ? false : true,
														message: t(
															'content.widget_manager.widget_builder.required',
														),
													},
											  ]
											: []
									}>
									{item?.content}
								</StyledFormItem>
								{idx === listItem.length - 2 &&
									!hasInputRequireCreator &&
									type !== TYPE_TERM.link &&
									renderTextSwitch()}
							</StyledTermItem>
						);
					})}
				{isTerms && (
					<OtherDetailTerm
						setFieldsValue={setFieldsValue}
						keyIndex={keyIndex}
						getValueField={getValueField}
						term={term}
					/>
				)}
			</StyledCustomFieldItem>
			{allowRemoving && <StyledIconRemoveCircle onClick={handleRemoveField} />}
		</StyledFieldItem>
	);
};

export default TermItem;
