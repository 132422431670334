import { ReactNode } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

interface PropsSortColumn {
	list: Array<any>;
	onSortEnd: (val: Array<any>) => void;
	children: ReactNode;
}

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const SortColumnWithChildren = (props: PropsSortColumn) => {
	const { list, onSortEnd, children } = props;
	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const quotes = reorder(list, result.source.index, result.destination.index);

		onSortEnd(quotes);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="list">
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{children}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default SortColumnWithChildren;
