export const Paginator = {
	pageSize: 200,
	defaultPage: 1,
	pageSizeOptions: [15, 30, 50, 100],
};

export const CreatorSegmentPaginator = {
	pageSize: 20,
	pageCurrent: 1,
	defaultPage: 1,
	pageSizeOptions: [15, 30, 50, 100],
};
