import { Layout, Row } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingWrapper } from '@cores/index';
import { StyledCol, StyledWrapper } from '@styled/Messages/EmailTemplatesStyled';
import FoldersTemplates from '@components/Messages/EmailTemplates/FoldersTemplates';
import Previews from '@components/Messages/EmailTemplates/Previews';

const EmailTemplates = () => {
	const { emailTemplate: { isSelectTemplate = false } = {} } = useSelector(
		(state: any) => state.messages,
	);

	return (
		<LoadingWrapper isLoading={false}>
			<Layout>
				<Row gutter={[16, 16]}>
					<StyledCol span={8}>
						<StyledWrapper>
							<FoldersTemplates />
						</StyledWrapper>
					</StyledCol>
					<StyledCol span={16}>
						<StyledWrapper bgColor={isSelectTemplate && 'transparent'}>
							<Previews />
						</StyledWrapper>
					</StyledCol>
				</Row>
			</Layout>
		</LoadingWrapper>
	);
};

export default EmailTemplates;
