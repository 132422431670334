const fetchAlbumsTypes = {
	FETCH_ALBUMS_REQUEST: 'FETCH_ALBUMS_REQUEST',
	FETCH_ALBUMS_SUCCEEDED: 'FETCH_ALBUMS_SUCCEEDED',
	FETCH_ALBUMS_FAILED: 'FETCH_ALBUMS_FAILED',
};

const fetchAlbumsSuggestTypes = {
	FETCH_ALBUMS_SUGGEST_REQUEST: 'FETCH_ALBUMS_SUGGEST_REQUEST',
	FETCH_ALBUMS_SUGGEST_SUCCEEDED: 'FETCH_ALBUMS_SUGGEST_SUCCEEDED',
	FETCH_ALBUMS_SUGGEST_FAILED: 'FETCH_ALBUMS_SUGGEST_FAILED',
};

const createAlbumTypes = {
	CREATE_ALBUM_REQUEST: 'CREATE_ALBUM_REQUEST',
	CREATE_ALBUM_SUCCEEDED: 'CREATE_ALBUM_SUCCEEDED',
	CREATE_ALBUM_FAILED: 'CREATE_ALBUM_FAILED',
	CREATE_ALBUM_END: 'CREATE_ALBUM_END',
};

const updateAlbumTypes = {
	UPDATE_ALBUM_REQUEST: 'UPDATE_ALBUM_REQUEST',
	UPDATE_ALBUM_SUCCEEDED: 'UPDATE_ALBUM_SUCCEEDED',
	UPDATE_ALBUM_FAILED: 'UPDATE_ALBUM_FAILED',
	UPDATE_ALBUM_END: 'UPDATE_ALBUM_END',
};

const exportAlbumContentsTypes = {
	EXPORT_ALBUM_CONTENTS_REQUEST: 'EXPORT_ALBUM_CONTENTS_REQUEST',
	EXPORT_ALBUM_CONTENTS_SUCCEEDED: 'EXPORT_ALBUM_CONTENTS_SUCCEEDED',
	EXPORT_ALBUM_CONTENTS_FAILED: 'EXPORT_ALBUM_CONTENTS_FAILED',
	EXPORT_ALBUM_CONTENTS_END: 'EXPORT_ALBUM_CONTENTS_END',
};

const fetchDetailAlbumTypes = {
	FETCH_DETAIL_ALBUM_REQUEST: 'FETCH_DETAIL_ALBUM_REQUEST',
	FETCH_DETAIL_ALBUM_SUCCEEDED: 'FETCH_DETAIL_ALBUM_SUCCEEDED',
	FETCH_DETAIL_ALBUM_FAILED: 'FETCH_DETAIL_ALBUM_FAILED',
};

const removeAlbumsTypes = {
	REMOVE_ALBUM_REQUEST: 'REMOVE_ALBUM_REQUEST',
	REMOVE_ALBUM_SUCCEEDED: 'REMOVE_ALBUM_SUCCEEDED',
	REMOVE_ALBUM_FAILED: 'REMOVE_ALBUM_FAILED',
	REMOVE_ALBUM_END: 'REMOVE_ALBUM_END',
};

const removeSuggestAlbumTypes = {
	REMOVE_SUGGEST_ALBUM_REQUEST: 'REMOVE_SUGGEST_ALBUM_REQUEST',
	REMOVE_SUGGEST_ALBUM_SUCCEEDED: 'REMOVE_SUGGEST_ALBUM_SUCCEEDED',
	REMOVE_SUGGEST_ALBUM_FAILED: 'REMOVE_SUGGEST_ALBUM_FAILED',
	REMOVE_SUGGEST_ALBUM_END: 'REMOVE_SUGGEST_ALBUM_END',
};

const albumSelectTypes = {
	UPDATE_ALBUM_SELECT: 'UPDATE_ALBUM_SELECT',
};

const listAlbumTypes = {
	UPDATE_LIST_ALBUM: 'UPDATE_LIST_ALBUM',
};

const fetchGuestListTypes = {
	FETCH_GUEST_LIST_REQUEST: 'FETCH_GUEST_LIST_REQUEST',
	FETCH_GUEST_LIST_SUCCEEDED: 'FETCH_GUEST_LIST_SUCCEEDED',
	FETCH_GUEST_LIST_FAILED: 'FETCH_GUEST_LIST_FAILED',
	FETCH_GUEST_LIST_END: 'FETCH_GUEST_LIST_END',
};

const sendGuestPassCodeTypes = {
	SEND_GUEST_PASS_CODE_REQUEST: 'SEND_GUEST_PASS_CODE_REQUEST',
	SEND_GUEST_PASS_CODE_SUCCEEDED: 'SEND_GUEST_PASS_CODE_SUCCEEDED',
	SEND_GUEST_PASS_CODE_FAILED: 'SEND_GUEST_PASS_CODE_FAILED',
	SEND_GUEST_PASS_CODE_END: 'SEND_GUEST_PASS_CODE_END',
};

const deleteGuestTypes = {
	DELETE_GUEST_REQUEST: 'DELETE_GUEST_REQUEST',
	DELETE_GUEST_SUCCEEDED: 'DELETE_GUEST_SUCCEEDED',
	DELETE_GUEST_FAILED: 'DELETE_GUEST_FAILED',
	DELETE_GUEST_END: 'DELETE_GUEST_END',
};

const getAlbumListLiteTypes = {
	GET_ALBUM_LIST_LITE_REQUEST: 'GET_ALBUM_LIST_LITE_REQUEST',
	GET_ALBUM_LIST_LITE_SUCCEEDED: 'GET_ALBUM_LIST_LITE_SUCCEEDED',
	GET_ALBUM_LIST_LITE_FAILED: 'GET_ALBUM_LIST_LITE_FAILED',
	GET_ALBUM_LIST_LITE_END: 'GET_ALBUM_LIST_LITE_END',
};

export {
	fetchAlbumsTypes,
	createAlbumTypes,
	fetchDetailAlbumTypes,
	removeAlbumsTypes,
	updateAlbumTypes,
	albumSelectTypes,
	listAlbumTypes,
	fetchAlbumsSuggestTypes,
	removeSuggestAlbumTypes,
	fetchGuestListTypes,
	sendGuestPassCodeTypes,
	deleteGuestTypes,
	exportAlbumContentsTypes,
	getAlbumListLiteTypes,
};
