const autoFocusAtEnd = (editor: any) => {
	if (editor) {
		const content = editor.getContent();

		if (content) {
			// Set the content to trigger an update in TinyMCE
			editor.setContent(content);
			// Focus at the end of the content
			const range = editor.selection.getRng();
			range.setStartAfter(range.startContainer);

			editor.selection.setRng(range);
		}
	}
};

const removeEmptyAtTheEndParagraphs = (htmlContent: string) => {
	// Create a temporary element to manipulate the content
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = htmlContent;

	const paragraphs = tempDiv.querySelectorAll('p');

	paragraphs.forEach((paragraph) => {
		let lastChild = paragraph.lastChild;

		// Because using shift + ENTER => <br />
		while (lastChild && lastChild.nodeName === 'BR') {
			paragraph.removeChild(lastChild);
			lastChild = paragraph.lastChild;
		}
	});

	const cleanedContent = tempDiv.innerHTML;
	return cleanedContent.trim();
};

export { autoFocusAtEnd, removeEmptyAtTheEndParagraphs };
