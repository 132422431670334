import { GUEST_PORTAL } from '@constants/APIs';
import genericApiSaga from '@helpers/genericApiSaga';
import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import { ParamRequestListBasic } from '@models/common/summary';
import { GuestPortalItemDto, ShareGuestPortalDto } from '@models/settings/guestPortal';
import { convertParamSearch } from '@utils/common';
import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './guestportal.actions';
import {
	createGuestPortalTypes,
	deleteGuestPortalTypes,
	getGuestPortalListTypes,
	shareGuestPortalTypes,
	updateGuestPortalTypes,
} from './guestportal.types';

export function* getGuestPortalList({ payload }: Action<ParamRequestListBasic>) {
	const searchParams = convertParamSearch(payload);
	const url = `${GUEST_PORTAL}?${searchParams}`;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.getGuestPortalListSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getGuestPortalListFailed(response));
		},
	});
}

export function* createGuestPortal({ payload }: Action<GuestPortalItemDto>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(GUEST_PORTAL, payload),
		*completed(response: any) {
			yield put(actions.createGuestPortalSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.createGuestPortalFailed(response));
		},
	});
}

export function* updateGuestPortal({
	payload,
}: Action<{
	id: number;
	params: GuestPortalItemDto;
}>) {
	const { id, params } = payload;
	const url = `${GUEST_PORTAL}/${id}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, params),
		*completed(response: any) {
			response.data.isUpdatedStatus = params.status !== null;
			yield put(actions.updateGuestPortalSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateGuestPortalFailed(response));
		},
	});
}

export function* deleteGuestPortal({ payload }: Action<number[]>) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(GUEST_PORTAL, payload),
		*completed(response: any) {
			yield put(actions.deleteGuestPortalSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteGuestPortalFailed(response));
		},
	});
}

export function* shareGuestPortal({ payload }: Action<ShareGuestPortalDto>) {
	const url = `${GUEST_PORTAL}/invite`;
	yield genericApiSaga({
		gatewayCall: () => postRequest(url, payload),
		*completed(response: any) {
			yield put(actions.shareGuestPortalSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.shareGuestPortalFailed(response));
		},
	});
}

export function* watcherGuestPortalSaga() {
	yield takeLatest(getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_REQUEST, getGuestPortalList);
	yield takeLatest(createGuestPortalTypes.CREATE_GUEST_PORTAL_REQUEST, createGuestPortal);
	yield takeLatest(updateGuestPortalTypes.UPDATE_GUEST_PORTAL_REQUEST, updateGuestPortal);
	yield takeLatest(deleteGuestPortalTypes.DELETE_GUEST_PORTAL_REQUEST, deleteGuestPortal);
	yield takeLatest(shareGuestPortalTypes.SHARE_GUEST_PORTAL_REQUEST, shareGuestPortal);
}
