import { IconGoogleDrive } from '@assets/icons';
import { google } from '@config/index';
import { GG_PICKER_CONFIGURATION, GOOGLE_PICKER_ACTIONS } from '@constants/common';
import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import GooglePicker from 'react-google-picker';
import { useTranslation } from 'react-i18next';

type GoogleFolderPickerProps = {
	setGoogleToken: (token: string) => void;
	handleGoogleDrive: (folderId: string[]) => void;
};

const GoogleFolderPicker = (props: GoogleFolderPickerProps) => {
	const { t } = useTranslation();
	const { setGoogleToken, handleGoogleDrive } = props;
	const [folderIds, setFolderIds] = useState<string[]>([]);

	useEffect(() => {
		if (folderIds.length) handleGoogleDrive(folderIds);
	}, [folderIds]);

	return (
		<GooglePicker
			clientId={google.clientId}
			developerKey={google.developerKey}
			onAuthFailed={(data: any) => {
				if (data) {
					message.error(t('message.authorize_gg_failed'));
				}
			}}
			viewId={GG_PICKER_CONFIGURATION.VIEW_ID}
			scope={GG_PICKER_CONFIGURATION.SCOPE}
			createPicker={(google: any, oauthToken: string) => {
				const googleViewId = google.picker.ViewId.FOLDERS;
				const docsView = new google.picker.DocsView(googleViewId)
					.setOwnedByMe(true)
					.setParent('root')
					.setIncludeFolders(true)
					.setMimeTypes('application/vnd.google-apps.folder')
					.setSelectFolderEnabled(true);

				const picker = new window.google.picker.PickerBuilder()
					.addView(docsView)
					.setOAuthToken(oauthToken)
					.setCallback((data: any) => {
						if (
							data.action &&
							data.action === GOOGLE_PICKER_ACTIONS.LOADED &&
							oauthToken
						) {
							setGoogleToken(oauthToken);
						}

						if (data.action && data.action === GOOGLE_PICKER_ACTIONS.PICKED) {
							const { docs } = data;
							const folderIds: string[] = [];
							docs.forEach((item: any) => {
								folderIds.push(item.id);
							});
							if (folderIds.length) setFolderIds(folderIds);
						}
					});

				picker.build().setVisible(true);
			}}>
			<Button icon={<IconGoogleDrive />}>{t('button.save_to_google_drive')}</Button>
			<div className="google" />
		</GooglePicker>
	);
};

export default GoogleFolderPicker;
