import {
	StyledEmpty,
	StyledIcon,
	StyledText,
	StyledTitle,
	StyledWrapper,
	StyledWrapperAction,
} from '@styled/Common/CommonStyled';
import {
	StyledButtonReset,
	StyledFilterContent,
	StyledFooterFilter,
	StyledForm,
	StyledTabs,
	StyledWrapperFilter,
} from '@styled/Filter/FilterContentStyled';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { useTranslation } from 'react-i18next';
import { IconCancel, IconClose, IconReset } from '@assets/icons';
import DetailFilter from './ContentFilterBookmark';
import {
	FILTER_PUBLISHED,
	FILTER_SOCIAL,
	NAME_FILTER_CONTENT,
	RIGHT_CLEAR_FILTER,
	STATUS_FILTER,
	TYPE_FILTER,
} from '@constants/filter/filterContent';
import { Button } from 'antd';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { useDispatch, useSelector } from 'react-redux';
import { FieldDefaultForm, FilterItemDto, FilterTypeDto, ValueTagDto } from '@models/filter';
import moment from 'moment-timezone';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { RefResetFilterContent } from '@models/filter/ref';
import { fetchContentTagsRequest, getListContentCampaignAPI } from '@stores/actions';
import { VAL_UN_RATE } from '@constants/content/contentLibrary';

const { TabPane } = StyledTabs;

const TYPE_TAB = {
	default: 'default',
	selectFilter: 'selectFilter',
};

type PropsFilterContent = {
	isHidden: boolean;
	closeFilters: () => void;
	handleFilterContent: (val: FilterTypeDto) => void;
};

const FilterContentBookmarks = forwardRef(
	(props: PropsFilterContent, ref: Ref<RefResetFilterContent>) => {
		const { t } = useTranslation();
		const dispatch = useDispatch();

		const { state } = useLocation<any>();
		const history = useHistory();

		const {} = useSelector((state: ContentRootStateDto) => state.content);

		const DEFAULT_FORM = {
			[NAME_FILTER_CONTENT.RATING]: 0,
			[NAME_FILTER_CONTENT.SOCIAL_CHANNEL]: [],
			[NAME_FILTER_CONTENT.TAGS]: [],
			[NAME_FILTER_CONTENT.CAMPAIGNS]: [],
			[NAME_FILTER_CONTENT.STATUS]: STATUS_FILTER.ANY,
			[NAME_FILTER_CONTENT.RIGHT_CLEAR]: [],
			[NAME_FILTER_CONTENT.KEYWORD]: '',
			[NAME_FILTER_CONTENT.UPLOAD_DATE]: [],
			[NAME_FILTER_CONTENT.NOT_RATE]: false,
		};

		const { isHidden, closeFilters, handleFilterContent } = props;

		const [form] = StyledForm.useForm();

		const [activeTab, setActiveTab] = useState<string>(TYPE_TAB.default);
		const [filterSelected, setFilterSelected] = useState<FilterItemDto | null>(null);

		const arrTab = [
			{
				title: t('filter.filter_content.new_filter'),
				value: TYPE_TAB.default,
			},
		];

		const formatDataRequestFilter = (values: FieldDefaultForm) => {
			const {
				tags,
				campaigns = [],
				rightClear = [],
				status,
				uploadDate = [],
				socialChannels = [],
				notRate = false,
				rating = 0,
				...otherRequest
			} = values;
			const dataRequest: FilterTypeDto = {};
			const arrLabel: string[] = [];
			const arrFlag: string[] = [];
			const arrGoogleAI: string[] = [];
			tags?.forEach((item) => {
				try {
					const val: ValueTagDto = JSON.parse(item);
					if (val?.group === NAME_FILTER_CONTENT.LABELS) {
						arrLabel.push(val?.val);
					}
					if (val?.group === NAME_FILTER_CONTENT.FLAGS) {
						arrFlag.push(val?.val);
					}
					if (val?.group === NAME_FILTER_CONTENT.GOOGLE_AI_TAGS) {
						arrGoogleAI.push(val?.val);
					}
				} catch (error) {
					console.log(error);
				}
			});

			const objArr: any = {
				labels: arrLabel,
				flags: arrFlag,
				googleAIHashtags: arrGoogleAI,
				socialChannels,
				campaigns,
			};

			Object.keys(objArr)?.forEach((item: string) => {
				if (objArr[item]?.length > 0) {
					dataRequest[item] = objArr[item];
				}
			});

			Object.keys(otherRequest)?.forEach((item) => {
				let val = otherRequest[item];
				if (typeof val === 'string') {
					val = val?.trim();
				}
				if (val) {
					dataRequest[item] = val;
				}
			});

			if (notRate || rating) {
				dataRequest.rating = notRate ? 0 : rating;
			}

			if (status === STATUS_FILTER.NEEDS_REVIEW) {
				dataRequest.needReview = true;
			}
			if (status === STATUS_FILTER.REVIEWED) {
				dataRequest.needReview = false;
			}

			if (uploadDate?.length > 0) {
				const start = new Date(uploadDate[0]);
				const end = new Date(uploadDate[1]);
				const startUtc = moment(start).utc().startOf('day');
				const endUtc = moment(end).utc().endOf('day');

				dataRequest.fromPostDate = startUtc?.valueOf();
				dataRequest.toPostDate = endUtc.valueOf();
			}

			if (rightClear?.includes(RIGHT_CLEAR_FILTER.APPROVED)) {
				dataRequest.verified = true;
			}
			if (rightClear?.includes(RIGHT_CLEAR_FILTER.CUSTOM_TERMS)) {
				dataRequest.customTerms = true;
				if (!Object.keys(dataRequest)?.includes('verified')) {
					dataRequest.verified = false;
				}
			}
			if (
				!rightClear?.includes(RIGHT_CLEAR_FILTER.NONE) ||
				!rightClear?.includes(RIGHT_CLEAR_FILTER.PENDING)
			) {
				if (rightClear?.includes(RIGHT_CLEAR_FILTER.NONE)) {
					dataRequest.contacted = false;
				}
				if (rightClear?.includes(RIGHT_CLEAR_FILTER.PENDING)) {
					dataRequest.contacted = true;
				}
			}

			return dataRequest;
		};

		useImperativeHandle(ref, () => ({
			clearFilterUsingRef: handleResetForm,
		}));

		useEffect(() => {
			if (state) {
				const otherFieldForm: any = {};
				if (state?.idCampaign) {
					otherFieldForm[NAME_FILTER_CONTENT.CAMPAIGNS] = [state?.idCampaign?.toString()];
				}

				if (state?.needReview) {
					otherFieldForm[NAME_FILTER_CONTENT.STATUS] = STATUS_FILTER.NEEDS_REVIEW;
				}

				if (!_.isEmpty(otherFieldForm)) {
					form.setFieldsValue(otherFieldForm);
					form.submit();
				}
				history.replace({ state: null });
			}
		}, [state]);

		useEffect(() => {
			dispatch(fetchContentTagsRequest());
			dispatch(getListContentCampaignAPI());
		}, []);

		const renderTabName = (item: any) => {
			const { title, value } = item;
			return (
				<StyledTitle>
					<StyledText
						fontWeight={`${value === activeTab ? 'bold' : 'normal'}`}
						fontSize="1rem">
						{title}
					</StyledText>
				</StyledTitle>
			);
		};

		const onValuesChange = (val: any) => {
			if (filterSelected) {
				setFilterSelected(null);
			}
		};

		const handleFinishForm = (values: any) => {
			const filterRequest: FilterTypeDto = formatDataRequestFilter(values);

			handleFilterContent(filterRequest);
		};

		const handleResetForm = () => {
			form.resetFields();
			setFilterSelected(null);
		};

		const handleApplyFilter = () => {
			form.submit();
			closeFilters();
		};

		const initialValues = {
			...DEFAULT_FORM,
		};

		return (
			<StyledFilterContent className={isHidden ? 'hidden' : ''}>
				<StyledIcon
					onClick={closeFilters}
					style={{ position: 'absolute', top: '14px', right: '14px', zIndex: 99 }}>
					<IconClose />
				</StyledIcon>
				<StyledTabs
					activeKey={activeTab}
					className="custom-tabs_filter"
					onTabClick={(tab: string) => setActiveTab(tab)}>
					{arrTab.map((item: any) => (
						<TabPane tab={renderTabName(item)} key={item.value} closable={false}>
							<StyledWrapperFilter className="custom_scroll_bar">
								<StyledForm
									form={form}
									initialValues={initialValues}
									onValuesChange={onValuesChange}
									onFinish={handleFinishForm}
									className={activeTab === TYPE_TAB.default ? '' : 'hidden'}>
									<DetailFilter />
								</StyledForm>
							</StyledWrapperFilter>
						</TabPane>
					))}
				</StyledTabs>
				<StyledFooterFilter>
					<Button onClick={handleApplyFilter} type="primary">
						{t('button.apply_filter')}
					</Button>
				</StyledFooterFilter>
			</StyledFilterContent>
		);
	},
);

export default FilterContentBookmarks;
