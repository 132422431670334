import { IN_PROGRESS } from '@constants/status';
import RegistrationAuthenticatorAppContext from '@contexts/Settings/RegistrationAuthenticatorApp';
import { verifyPasswordRequest } from '@stores/actions';
import { UserStoreType } from '@stores/user/user.types';
import {
	StyledButton,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type EnterPasswordProps = {};

const EnterPassword = (props: EnterPasswordProps) => {
	const [form] = useForm();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { verifyPasswordStatus }: UserStoreType = useSelector((state: any) => state.user);
	const { onCancel } = useContext(RegistrationAuthenticatorAppContext);

	useEffect(() => {
		return () => {
			form.resetFields();
		};
	}, []);

	const verifyPassword = (payload: { password: string }) => {
		dispatch(verifyPasswordRequest(payload));
	};

	const handleOnFinish = (values: any) => {
		verifyPassword(values);
	};

	const isLoading = verifyPasswordStatus === IN_PROGRESS;

	return (
		<>
			<StyledText margin="0 0 20px 0">{t('setting.user_profile.enter_password')}</StyledText>
			<StyledForm
				form={form}
				layout="vertical"
				requiredMark={false}
				onFinish={handleOnFinish}>
				<StyledFormItem
					rules={[
						{
							required: true,
							message: t('validation.required', {
								field: t('setting.user_profile.message.password'),
							}),
						},
					]}
					label={t('setting.user_profile.current_password')}
					name="password">
					<Input.Password autoFocus />
				</StyledFormItem>

				<StyledFlex justify="flex-end">
					<StyledButton margin="0 12px 0 0" onClick={onCancel}>
						{t('button.cancel')}
					</StyledButton>
					<StyledButton loading={isLoading} onClick={form.submit} type="primary">
						{t('button.continue')}
					</StyledButton>
				</StyledFlex>
			</StyledForm>
		</>
	);
};

export default EnterPassword;
