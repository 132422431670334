import { IconCheckCircle, IconWarningBasic } from '@assets/icons';
import RadioGroup from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed/RadioGroup';
import { linkEntribe } from '@config/index';
import { CREATOR_API_URL } from '@constants/APIs';
import { MESSAGE_TYPE, PATTERNS } from '@constants/common';
import { DEFAULT_ERROR } from '@constants/errors';
import {
	KEY_POPUP_TREMENDOUS,
	LIST_OPTION_SEND,
	SEND_TYPE,
} from '@constants/settings/integrations';
import THEME from '@constants/themes/themes';
import TremendousModalContext from '@contexts/Settings/SettingIntegrations/TremendousModal';
import { putRequest } from '@helpers/requestHelpers';
import { RefTremendousStep } from '@models/settings/tremendous/ref';
import { TremendousStoreDto } from '@models/settings/tremendous/store';
import { creatorGetProfile, storeCreatorData } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledFormItem,
	StyledIcon,
	StyledInput,
	StyledLinkHref,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { numberWithCommas } from '@utils/common';
import { limitNumberInput, validateFormAllFieldHelper } from '@utils/funcHelper';
import { generalMessage } from '@utils/renderComponent';
import { Col, Form, Row, message } from 'antd';
import React, { Ref, forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FieldEdit from './FieldEdit';

type PropStep1 = {
	handleStep: () => void;
};

export type FieldInfoTypes = {
	name: string;
	fontSize?: string;
	value: string;
	arrField?: Array<{ key: string; value: string }>;
	rules: any[];
};

export type DataRequestCreatorTypes = {
	firstName: string;
	lastName: string;
	email: string;
	instagramUsername: string;
	twitterUsername: string;
	youtubeUsername: string;
	tiktokUsername: string;
	address: string;
	zipCode: string;
	facebookUrl: string;
	phone: string;
};

type DataFormTypes = {
	rewardAmount: number;
	deliveryMethod: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	email?: string;
};

const StepTremendous1 = forwardRef((props: PropStep1, ref: Ref<RefTremendousStep>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { handleStep } = props;
	const { tremendousDefault }: TremendousStoreDto = useSelector((state: any) => state.tremendous);
	const { tremendousSettings } = tremendousDefault || {};
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);

	const { orderDetail, setOrderDetail, setIsLoading, fundingSourceTotal } =
		useContext(TremendousModalContext);

	const {
		firstName = '',
		lastName = '',
		email = '',
		address = '',
		zipCode = '',
		phone = '',
		social: {
			instagramUsername = '',
			twitterUsername = '',
			youtubeUsername = '',
			tiktokUsername = '',
			facebookProfileUrl = '',
		} = {},
	} = creatorProfile || {};

	const editModalData: DataRequestCreatorTypes = {
		firstName,
		lastName,
		email,
		instagramUsername,
		twitterUsername,
		youtubeUsername,
		tiktokUsername,
		address,
		zipCode,
		facebookUrl: facebookProfileUrl,
		phone,
	};

	const [form] = Form.useForm();

	useEffect(() => {
		const val = form.getFieldValue(KEY_POPUP_TREMENDOUS.AMOUNT);

		if (orderDetail?.rewardAmount !== val) {
			form.setFieldsValue({
				[KEY_POPUP_TREMENDOUS.AMOUNT]: orderDetail?.rewardAmount,
			});
		}
	}, [orderDetail]);

	const handleEditCreator = (params: DataRequestCreatorTypes) => {
		const url = `${CREATOR_API_URL}/${creatorProfile?.id}`;
		const getProfileParams = {
			creatorId: creatorProfile?.id,
		};
		setIsLoading(true);
		putRequest(url, params)
			.then((response) => {
				if (response.status === 200) {
					message.success(t('creator_summary.modal.edit_creator_success'));
					dispatch(creatorGetProfile(getProfileParams));
					dispatch(
						storeCreatorData({
							updateBasicInformationCreator: true,
						}),
					);
					handleStep();
				} else {
					generalMessage(response.body.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				message.error(DEFAULT_ERROR);
			});
	};

	const onValuesChange = (obj: any) => {
		const key = Object.keys(obj)[0];

		if (key === KEY_POPUP_TREMENDOUS.AMOUNT) {
			const amount = Math.floor(+obj[key] * 100) / 100;
			const res = limitNumberInput(amount) || 0;

			if (res !== +obj[key]) {
				form.setFieldsValue({ [KEY_POPUP_TREMENDOUS.AMOUNT]: res.toString() });
			}

			setOrderDetail({
				...orderDetail,
				rewardAmount: +(res || 0),
			});
		}
		if (key === KEY_POPUP_TREMENDOUS.TYPE_SEND) {
			setOrderDetail({
				...orderDetail,
				...obj,
			});
		}
	};

	const handleFinishForm = (values: DataFormTypes) => {
		const { rewardAmount, deliveryMethod, ...otherField } = values;

		const keyOther = Object.keys(otherField);
		if (keyOther.length > 0) {
			let hasChange = false;
			keyOther.forEach((key) => {
				if ((otherField as any)[key] !== (editModalData as any)[key]) {
					hasChange = true;
				}
			});
			if (hasChange) {
				handleEditCreator({ ...editModalData, ...otherField });
				return;
			}
		}
		handleStep();
	};

	useImperativeHandle(ref, () => ({
		validateAllField,
	}));

	const fieldInfo: FieldInfoTypes[] = [
		{
			name: KEY_POPUP_TREMENDOUS.NAME,
			fontSize: '24px',
			value: `${firstName} ${lastName}`,
			arrField: [
				{
					key: KEY_POPUP_TREMENDOUS.FIRST_NAME,
					value: firstName,
				},
				{
					key: KEY_POPUP_TREMENDOUS.LAST_NAME,
					value: lastName,
				},
			],
			rules: [],
		},
		{
			name: KEY_POPUP_TREMENDOUS.EMAIL,
			value: email,
			rules: [
				{
					required: true,
					message: t('validate.required'),
				},
				{
					type: 'email',
					message: t('creator_summary.modal.invalid_email'),
				},
			],
		},
		{
			name: KEY_POPUP_TREMENDOUS.PHONE_NUMBER,
			value: phone,
			rules: [
				{
					pattern: PATTERNS.PHONE_NUMBER,
					message: t('creator_summary.modal.invalid_phone'),
				},
			],
		},
	];

	const validateAllField = () => {
		validateFormAllFieldHelper(form);
	};

	const renderIcon = () => {
		const val = (orderDetail?.rewardAmount || 0) > (fundingSourceTotal || 0);
		return val ? <IconWarningBasic /> : <IconCheckCircle />;
	};

	return (
		<>
			<Form
				initialValues={{
					[KEY_POPUP_TREMENDOUS.TYPE_SEND]: SEND_TYPE.EMAIL,
				}}
				form={form}
				onValuesChange={onValuesChange}
				onFinish={handleFinishForm}>
				<Row gutter={[24, 24]}>
					<Col span={12}>
						<StyledFormItem
							className="hidden_start_required"
							labelCol={{ span: 24 }}
							labelHeight={'18px'}
							name={KEY_POPUP_TREMENDOUS.AMOUNT}
							rules={[
								{
									required: true,
									message: t('validate.required'),
								},

								() => ({
									validator(_: any, val: string) {
										const value = +val;

										if (value < 0) {
											form.setFieldsValue({
												[KEY_POPUP_TREMENDOUS.AMOUNT]: Math.abs(value),
											});
											return Promise.resolve();
										}
										if (value === 0 && val) {
											return Promise.reject(
												new Error(t('validate.value_greater', { min: 0 })),
											);
										}
										return Promise.resolve();
									},
								}),
							]}
							label={t('tremendous.reward_via_tremendous')}>
							<StyledInput
								placeholder={t('placeholder.unit_value')}
								className="hidden_arrow"
								type="number"
								prefix={'$'}
							/>
						</StyledFormItem>
						<StyledFormItem name={KEY_POPUP_TREMENDOUS.TYPE_SEND}>
							<RadioGroup
								direction="vertical"
								list={LIST_OPTION_SEND.map((item) => ({
									...item,
									name: t(item.name),
									disabled: item?.value === SEND_TYPE.SMS,
								}))}
							/>
						</StyledFormItem>
					</Col>
					<Col span={12}>
						<StyledTitle className="unset_height" fontSize="16px">
							{t('tremendous.recipient')}
						</StyledTitle>
						{fieldInfo.map((item, idx) => (
							<StyledWrapperTitle
								key={item.name}
								justifyContent="left"
								margin={idx ? '8px 0 0 0' : '12px 0 0 0'}>
								<FieldEdit
									name={item.name}
									value={item.value}
									rules={item.rules}
									item={item}
									height={idx === 0 ? '32px' : undefined}
									fontSize={item?.fontSize}
									setFieldValue={() => {
										if (item.arrField) {
											const obj: any = {};
											item.arrField.forEach((field) => {
												obj[field.key] = field.value;
											});
											form.setFieldsValue({ ...obj });
											return;
										}
										form.setFieldsValue({ [item.name]: item.value });
									}}
								/>
							</StyledWrapperTitle>
						))}
					</Col>
				</Row>
			</Form>
			<StyledTitle className="unset_height" fontSize="16px" margin="3px 0 8px 0">
				{t('tremendous.available_funds')}
			</StyledTitle>
			<StyledWrapperAction>
				<StyledIcon
					size={36}
					fillPath={THEME.colors.gray5}
					colorStroke={THEME.colors.gray5}>
					{renderIcon()}
				</StyledIcon>
				<StyledTitle margin="0 0 0 2px" fontSize="24px" className="unset_height">
					{`${t('tremendous.uint_usd')}${numberWithCommas(
						fundingSourceTotal || 0,
						2,
						true,
					)}`}
				</StyledTitle>
			</StyledWrapperAction>
			<StyledTitle className="unset_height" color={THEME.colors.gray3} margin="8px 0 0 0">
				<Trans
					t={t}
					components={{
						elm: (
							<StyledLinkHref
								hoverColor={THEME.colors.orangeBase}
								fontWeight="normal"
								margin="0 2px"
								underline
								href={linkEntribe}
								color="inherit"
								target="_blank"
								onClick={(e: React.MouseEvent<HTMLElement>) => {
									e.stopPropagation();
								}}
							/>
						),
					}}
					i18nKey="tremendous.sub_reward_tremendous"
				/>
			</StyledTitle>
		</>
	);
});

export default StepTremendous1;
