import { TYPE_FORMAT_DATE } from '@constants/common';
import { ACTION_TYPE_ON_LANDING_PAGE } from '@constants/landingPageManager';
import { IN_PROGRESS } from '@constants/status';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { getUser } from '@helpers/userHelpers';
import { LandingPageDto } from '@models/landingpagemanager';
import { LandingPageStoreDto } from '@models/landingpagemanager/stores';
import LandingPageBuilder from '@pages/Client/LandingPageBuilder';
import {
	publishLandingPageRequest,
	storeLandingPageDataRequest,
	updateLandingPageRequest,
} from '@stores/actions';
import { StyledButton, StyledFlex, StyledText } from '@styled/Common/CommonStyled';
import { StyledLandingPageModal } from '@styled/LandingPageManager/LandingPageModalStyled';
import { isJSONString } from '@utils/common';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type LandingPageModalProps = {
	visible: boolean;
	onCancel: () => void;
	currentLandingPage: LandingPageDto;
	renderStatusChip: (item: LandingPageDto) => void;
};

const CONFIRMATION_TYPE = {
	CLOSE_MODAL: 'CLOSE_MODAL',
};

const LandingPageModal = (props: LandingPageModalProps) => {
	const dispatch = useDispatch();
	const user = getUser();
	const { visible, onCancel, currentLandingPage, renderStatusChip } = props;
	const { publishLandingPageStatus, updateLandingPageStatus }: LandingPageStoreDto = useSelector(
		(state: any) => state.landingPage,
	);
	const { t } = useTranslation();
	const landingPageBuilderRef = useRef<any>();

	const [hasChange, setHasChange] = useState<boolean>(false);
	const [confirmationModalType, setConfirmationModalType] = useState<string>('');

	useEffect(() => {
		return () => {
			if (landingPageBuilderRef && landingPageBuilderRef.current) {
				landingPageBuilderRef.current.clearAllComponents();
			}
		};
	}, []);

	const parsedCurrentLandingPage = useMemo(() => {
		return currentLandingPage &&
			currentLandingPage.metadata &&
			isJSONString(currentLandingPage.metadata)
			? JSON.parse(currentLandingPage.metadata)
			: currentLandingPage.metadata;
	}, [currentLandingPage]);

	const renderTitle = () => {
		return (
			<StyledFlex width="97%">
				<StyledText fontSize="20px">
					{t('campaign.modal.edit_your_landing_page')}
				</StyledText>
				{renderStatusChip(currentLandingPage)}
			</StyledFlex>
		);
	};

	const handleActionOnLandingPage = (action: string) => {
		if (landingPageBuilderRef && landingPageBuilderRef.current) {
			let metadata = isJSONString(currentLandingPage.metadata)
				? JSON.parse(currentLandingPage.metadata)
				: currentLandingPage.metadata;
			const metadataBuilder = landingPageBuilderRef.current.getMetadata();
			metadata = JSON.stringify({ ...metadata, ...metadataBuilder });

			const otherParams = {
				metadata,
				name: currentLandingPage.name,
				description: currentLandingPage.description,
				url: currentLandingPage.url,
			};

			switch (action) {
				case ACTION_TYPE_ON_LANDING_PAGE.PUBLISH_CHANGES: {
					const payload = {
						id: currentLandingPage.referenceId || currentLandingPage.id,
						accountEmail: user.email,
						...otherParams,
					};

					dispatch(publishLandingPageRequest(payload));
					break;
				}

				case ACTION_TYPE_ON_LANDING_PAGE.SAVE_DRAFT: {
					const payload = {
						id: currentLandingPage.id,
						params: { metadata },
					};
					dispatch(
						storeLandingPageDataRequest({
							actionTypeOnLandingPage: ACTION_TYPE_ON_LANDING_PAGE.SAVE_DRAFT,
						}),
					);
					dispatch(updateLandingPageRequest(payload));
					break;
				}

				default:
					break;
			}
		}
	};

	const renderButtonGroup = () => {
		const { publishedBy, publishedAt } = currentLandingPage;

		return [
			publishedBy && publishedAt ? (
				<StyledFlex align="center" margin="0 12px">
					<StyledText>
						<i>
							{t('landing_page_manager.detail.last_published', {
								author: publishedBy,
								time: convertUtcToLocalTimeWithFormat(
									+publishedAt,
									TYPE_FORMAT_DATE.TIME_AT,
								),
							})}
						</i>
					</StyledText>
				</StyledFlex>
			) : null,
			hasChange ? (
				<StyledFlex align="center" margin="0 12px">
					<StyledText>{t('social_aggregator.unsaved_changes')}</StyledText>
				</StyledFlex>
			) : null,
			<StyledButton
				disabled={!hasChange}
				loading={false}
				onClick={() =>
					handleActionOnLandingPage(ACTION_TYPE_ON_LANDING_PAGE.PUBLISH_CHANGES)
				}>
				{t('gallery_manager.button.publish_change')}
			</StyledButton>,
			<StyledButton
				disabled={!hasChange}
				type={!hasChange ? 'default' : 'primary'}
				onClick={() => handleActionOnLandingPage(ACTION_TYPE_ON_LANDING_PAGE.SAVE_DRAFT)}>
				{t('button.save_draft')}
			</StyledButton>,
		];
	};

	const handleCloseModal = () => {
		if (hasChange) {
			setConfirmationModalType(CONFIRMATION_TYPE.CLOSE_MODAL);
		} else {
			closeModalImmediately();
		}
	};

	const closeModalImmediately = () => {
		if (landingPageBuilderRef && landingPageBuilderRef.current) {
			landingPageBuilderRef.current.clearAllComponents();
		}
		setConfirmationModalType('');
		onCancel();
	};

	const renderConfirmationModal = () => {
		let content: ReactNode;
		const okText: string = t('button.save_draft');
		const cancelText: string = t('landing_page_manager.button.close_without_saving');
		const otherProps: any = {};

		if (confirmationModalType === CONFIRMATION_TYPE.CLOSE_MODAL) {
			content = t('landing_page_manager.message.close_editor_modal');
			otherProps.otherConfirmText = t('landing_page_manager.button.publish_changes');
			otherProps.confirmLoading = updateLandingPageStatus === IN_PROGRESS;
			otherProps.confirmOtherLoading = publishLandingPageStatus === IN_PROGRESS;
			// Save Draft
			otherProps.onSubmit = () => {
				handleActionOnLandingPage(ACTION_TYPE_ON_LANDING_PAGE.SAVE_DRAFT);
			};
			// Publish changes
			otherProps.onOtherConfirm = () => {
				handleActionOnLandingPage(ACTION_TYPE_ON_LANDING_PAGE.PUBLISH_CHANGES);
			};
		}

		return (
			<ConfirmModal
				width={450}
				maskClosable={false}
				isOpen={!!confirmationModalType}
				onClose={closeModalImmediately}
				okText={okText}
				cancelText={cancelText}
				{...otherProps}>
				{content}
			</ConfirmModal>
		);
	};

	const isLoading =
		(publishLandingPageStatus === IN_PROGRESS && !confirmationModalType) ||
		updateLandingPageStatus === IN_PROGRESS;

	return (
		<StyledLandingPageModal
			visible={visible}
			width="80%"
			title={renderTitle()}
			onCancel={handleCloseModal}
			centered
			okText={t('button.save')}
			maskClosable={false}
			footer={renderButtonGroup()}
			zIndex={1010}>
			<LoadingWrapper isLoading={isLoading}>
				<LandingPageBuilder
					ref={landingPageBuilderRef}
					height="calc(100% - 100px)"
					projectData={parsedCurrentLandingPage?.projectData || {}}
					hasChange={hasChange}
					setHasChange={setHasChange}
				/>
			</LoadingWrapper>
			{confirmationModalType && renderConfirmationModal()}
		</StyledLandingPageModal>
	);
};

export default LandingPageModal;
