import { IconMoreVert } from '@assets/icons';
import { DEFAULT_GUEST_PORTAL, GUEST_PORTAL_MODAL_TYPE } from '@constants/settings/guestPortal';
import GuestPortalContext from '@contexts/Settings/GuestPortal';
import { ActionsTypes } from '@models/common/summary';
import {
	StyledButton,
	StyledDropdown,
	StyledFlex,
	StyledIcon,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { menuActions } from '@utils/renderComponent';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const GuestPortalActionBar = () => {
	const { t } = useTranslation();
	const { setGuestPortalDetail, setModalType, selectedGuestPortals, setConfirmationModalType } =
		useContext(GuestPortalContext);

	const actions: ActionsTypes[] = [
		{
			id: 1,
			name: t('button.create_new'),
			click: () => {
				setModalType(GUEST_PORTAL_MODAL_TYPE.ACTION_ON_GUEST);
				setGuestPortalDetail(DEFAULT_GUEST_PORTAL);
			},
		},
		{
			id: 2,
			name: t('button.edit'),
			click: () => {
				if (selectedGuestPortals.length) {
					setModalType(GUEST_PORTAL_MODAL_TYPE.ACTION_ON_GUEST);
					setGuestPortalDetail(selectedGuestPortals[0]);
				}
			},
			disabled: selectedGuestPortals.length !== 1,
		},
		{
			id: 3,
			name: t('button.delete'),
			click: () => {
				setConfirmationModalType(GUEST_PORTAL_MODAL_TYPE.DELETE);
			},
			disabled: !selectedGuestPortals.length,
		},
	];

	return (
		<StyledWrapperContent
			margin="0 0 16px 0"
			width="100%"
			display="flex"
			justifyContent="flex-end">
			<StyledFlex>
				<StyledDropdown placement="bottomRight" overlay={menuActions(actions)}>
					<StyledIcon>
						<IconMoreVert />
					</StyledIcon>
				</StyledDropdown>
				<StyledButton
					isDisabled={selectedGuestPortals.length !== 1}
					margin="0 0 0 8px"
					onClick={() => {
						setModalType(GUEST_PORTAL_MODAL_TYPE.SHARE);
					}}
					width="124px"
					type="primary">
					{t('button.share')}
				</StyledButton>
			</StyledFlex>
		</StyledWrapperContent>
	);
};

export default GuestPortalActionBar;
