export enum SOCIAL {
	INSTAGRAM = 'Instagram',
	TWITTER = 'Twitter',
	YOUTUBE = 'YouTube',
	TIKTOK = 'TikTok',
	COMBINED = 'Combined',
}

export const SOCIAL_UPCASE = {
	INSTAGRAM: 'INSTAGRAM',
	TWITTER: 'TWITTER',
	YOUTUBE: 'YOUTUBE',
	TIKTOK: 'TIKTOK',
	COMBINED: 'COMBINED',
	SUBMISSIONS: 'SUBMISSIONS',
};

export const SOURCE_SHOW_LOCAL_DEFAULT = [
	SOCIAL_UPCASE.SUBMISSIONS,
	SOCIAL_UPCASE.INSTAGRAM,
	SOCIAL_UPCASE.TIKTOK,
	SOCIAL_UPCASE.TWITTER,
	SOCIAL_UPCASE.YOUTUBE,
	SOCIAL_UPCASE.COMBINED,
];

export const SOCIAL_DETAILS = {
	POST_INFO: 'Post Information',
} as const;

export enum OPERATOR {
	OR = 'OR',
	AND = 'AND',
}

export const DEFAULT_MEDIA = {
	listSocialMediaResponse: [],
	nextPage: 0,
	totalHits: 0,
};

export const RATE_LIMIT = {
	IMPORT: 20,
	MAX_SEARCH_TERM: 99,
};

export const SOCIAL_PROFILE = {
	INSTAGRAM: 'https://www.instagram.com/',
	TWTTER: 'https://twitter.com/',
	TIKTOK: 'https://www.tiktok.com/@',
	YOUTUBE: 'https://www.youtube.com/@',
	FACEBOOK: 'https://www.facebook.com/',
};

export const SOCIAL_CHANNEL = {
	YOUTUBE: 'https://www.youtube.com/channel/',
};

export const SOCIAL_POST = {
	INSTAGRAM: 'https://www.instagram.com/p/',
	TWTTER: 'https://twitter.com/',
	TIKTOK: 'https://www.tiktok.com/@',
	YOUTUBE: 'https://www.youtube.com/watch?v=',
};
