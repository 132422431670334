import {
	IconCheck,
	IconCheckCircle,
	IconExpandMore,
	IconInfo,
	IconRightPending,
} from '@assets/icons';
import { ELEMENT_SELECTOR, TASK_STATUS } from '@constants/taskManager';
import THEME from '@constants/themes/themes';
import { ActionsTypes } from '@models/common/summary';
import {
	StyledDropdown,
	StyledFlex,
	StyledIcon,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledTaskStatus } from '@styled/TaskManager/TaskManagerStyled';
import { Menu } from 'antd';
import { Ref, forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TaskStatusProps = {
	status: string;
	[otherProp: string]: any;
};

const TaskStatus = forwardRef((props: TaskStatusProps, ref: Ref<any>) => {
	const { status, onChange, ...otherProps } = props;
	const { t } = useTranslation();
	const [visible, setVisible] = useState<boolean>(false);
	const [isScrolling, setIsScrolling] = useState(false);

	useEffect(() => {
		handleCloseDropdownWithScrolling();
		return () => {};
	}, [isScrolling]);

	const handleCloseDropdownWithScrolling = () => {
		const tableEl = document.getElementById(ELEMENT_SELECTOR.TASK_LIST_ID);
		const elementToFocus = document.getElementById(ELEMENT_SELECTOR.ELEMENT_TO_FOCUS_ID); // Replace with the actual ID

		if (tableEl && elementToFocus) {
			const handleScroll = () => {
				if (!isScrolling) {
					setIsScrolling(true);
					if (elementToFocus) {
						elementToFocus.focus();

						if (visible) {
							setVisible(false);
						}
					}
					// Set a timeout to stop the action after a delay (adjust the delay as needed)
					setTimeout(() => {
						setIsScrolling(false);
					}, 100); // Adjust the delay in milliseconds
				}
			};

			tableEl.addEventListener('scroll', handleScroll, true);
			return () => {
				tableEl.removeEventListener('scroll', handleScroll, true);
			};
		}

		return () => {};
	};

	const statusItems = [
		{
			id: t('task_manager.status.to_do'),
			name: t('task_manager.status.to_do'),
			value: TASK_STATUS.TO_DO,
		},
		{
			id: t('task_manager.status.in_progress'),
			name: t('task_manager.status.in_progress'),
			value: TASK_STATUS.IN_PROGRESS,
		},
		{
			id: t('task_manager.status.done'),
			name: t('task_manager.status.done'),
			value: TASK_STATUS.DONE,
		},
	];

	const menuActionsDropdown = () => (
		<Menu>
			{statusItems.map((item: ActionsTypes) => {
				if (item?.hidden) {
					return null;
				}
				return (
					<StyledWrapperContent
						className="hover_effect"
						padding="6px 8px 6px 6px"
						cursor="pointer"
						display="flex"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (item.value !== status) {
								onChange(item.value);
							}
							setVisible(false);
						}}>
						<span>{item.name}</span>
						{item?.name && status.toLowerCase() === item?.value?.toLowerCase() && (
							<StyledIcon className="icon-check" margin="0 4px 0 0">
								<IconCheck />
							</StyledIcon>
						)}
					</StyledWrapperContent>
				);
			})}
		</Menu>
	);

	const otherAttrs = useMemo(() => {
		const attrs = {
			color: THEME.colors.white,
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
			icon: <IconInfo />,
			text: t('task_manager.status.to_do'),
			size: 20,
		};

		switch (status.toLowerCase()) {
			case TASK_STATUS.IN_PROGRESS.toLowerCase(): {
				attrs.color = THEME.colors.yellowBase;
				attrs.backgroundColor = 'rgba(251, 188, 5, 0.08)';
				attrs.icon = <IconRightPending />;
				attrs.text = t('task_manager.status.in_progress');
				attrs.size = 20;
				break;
			}

			case TASK_STATUS.DONE.toLowerCase(): {
				attrs.color = THEME.colors.greenBase;
				attrs.backgroundColor = 'rgba(82, 196, 26, 0.08)';
				attrs.icon = <IconCheckCircle />;
				attrs.text = t('task_manager.status.done');
				attrs.size = 20;
				break;
			}

			default:
				break;
		}

		return { ...attrs };
	}, [status]);

	return (
		<>
			<StyledDropdown
				visible={visible}
				overlayClassName={`dropdown_chip_option ${ELEMENT_SELECTOR.TASK_STATUS}`}
				openClassName="dropdown_task_status_open"
				overlay={menuActionsDropdown()}
				placement="bottomRight"
				trigger={['click']}
				onVisibleChange={(visible) => {
					setVisible(visible);
				}}>
				<StyledTaskStatus
					width="100%"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					bgColor={otherAttrs.backgroundColor}
					color={otherAttrs.color}
					{...otherProps}>
					<StyledFlex>
						<StyledIcon fill={otherAttrs.color} size={otherAttrs.size || 24}>
							{otherAttrs.icon}
						</StyledIcon>
						<StyledText margin="0 0 0 6px">{otherAttrs.text}</StyledText>
					</StyledFlex>
					<StyledIcon fill={otherAttrs.color} size={20}>
						<IconExpandMore />
					</StyledIcon>
				</StyledTaskStatus>
			</StyledDropdown>
		</>
	);
});

export default TaskStatus;
