import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';

// Components
import { LoadingWrapper } from '@cores/index';
import Labels from '@components/Settings/Content/Labels';
import Flags from '@components/Settings/Content/Flags';
import ContentValue from '@components/Settings/Content/ContentValue';

// Styled
import { StyledWrapper, StyledCol, StyledRow } from '@styled/Settings/ContentStyled';

// Redux
import { getContentSettings } from '@stores/settings/settings.actions';
import Header from '@cores/Header';
import ActionBar from '@components/Settings/Content/ActionBar';

const SettingsContent: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getContentSettings());
	}, []);

	return (
		<LoadingWrapper isLoading={false}>
			<Layout>
				{/* <StyledScrollWrapper> */}
				<Header>
					<ActionBar />
				</Header>
				<StyledRow gutter={[16, 24]}>
					<StyledCol span={12}>
						<StyledWrapper>
							<Labels />
						</StyledWrapper>
					</StyledCol>
					<StyledCol span={12}>
						<StyledWrapper>
							<Flags />
						</StyledWrapper>
					</StyledCol>

					<StyledCol span={24}>
						<StyledWrapper>
							<ContentValue />
						</StyledWrapper>
					</StyledCol>
				</StyledRow>
				{/* </StyledScrollWrapper> */}
			</Layout>
		</LoadingWrapper>
	);
};

export default SettingsContent;
