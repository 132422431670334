import { Action } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
	AMBASSADOR_CREATOR_REFRESH_METRICS_API,
	AMBASSADOR_SOCIAL,
	CREATOR_API_URL,
	CREATOR_BLOCK_PROFILES,
	CREATOR_CHANGE_STATUS_PROFILES,
	CREATOR_COMPLETED_ACTIONS,
	CREATOR_EXPORT_PROFILES_URL,
	CREATOR_GET_CREATOR_LIST_URL,
	CREATOR_IMPORT_PROFILES,
	CREATOR_LIST_LITE,
	CREATOR_LOCATION,
	CREATOR_SEGMENT_FIND_PROFILES,
	CREATOR_SEGMENT_LIST,
	CREATOR_SEGMENT_SIMPLE,
	CREATOR_STATUS_RECENTLY_ADDED_URL,
	CREATOR_TEMPLATE,
	GET_LIST_CONTENT_CAMPAIGN,
	INVALID_CREATOR_SOCIAL_USERNAME,
	MAIL_HISTORY_CREATOR,
	UPDATE_APPROVAL_STATUS,
} from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { handleDownloadExcel } from '@helpers/base64Helper';
import genericApiSaga from '@helpers/genericApiSaga';
import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import * as actions from './creator.actions';
import {
	CREATOR_CHANGE_STATUS,
	CREATOR_DELETE,
	CREATOR_EXPORT_PROFILES,
	CREATOR_FIND_PROFILES,
	CREATOR_GET_PROFILE,
	CREATOR_GET_SEGMENT,
	CREATOR_IMPORT,
	GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST,
	GET_SEGMENT,
	UPDATE_STATUS_RECENTLY_ADDED_REQUEST,
	creatorChangeStatusCompleted,
	creatorChangeStatusFailed,
	creatorDeleteCompleted,
	creatorDeleteFailed,
	creatorExportProfilesCompleted,
	creatorExportProfilesFailed,
	creatorFindProfilesCompleted,
	creatorFindProfilesFailed,
	creatorGetProfileCompleted,
	creatorGetProfileFailed,
	creatorGetSegmentCompleted,
	creatorGetSegmentFailed,
	creatorImportCompleted,
	creatorImportFailed,
	getCreatorUnviewedStatusRecentlyAddedFailed,
	getCreatorUnviewedStatusRecentlyAddedSucceeded,
	getSegmentCompleted,
	getSegmentFailed,
	updateStatusRecentlyAddedCompleted,
	updateStatusRecentlyAddedFailed,
} from './creator.actions';

import { MESSAGE_TYPE } from '@constants/common';
import {
	CREATOR_BLOCK,
	creatorBlockCompleted,
	creatorBlockFailed,
	creatorResetUploadAWS,
} from '@stores/actions';
import { convertParamSearch } from '@utils/common';
import { generalMessage } from '@utils/renderComponent';
import { message } from 'antd';
import { HistoryCreatorParams, UpdateApprovalStatusDto } from '@models/creator/summary';

function* creatorFindProfiles({
	payload: { id, search, page, maxRecords, sortDirection, sortField, creatorIds, isActive },
}: any) {
	const dataParams: any = { search, id, page, pageRecords: maxRecords, sortField, sortDirection };
	const renderParam = (params: any) => {
		const searchParams = convertParamSearch(params);
		return searchParams;
	};

	let url = '';
	const allProfiles = id === undefined || id === '';
	let data: any;
	if (allProfiles) {
		const otherParam = renderParam({ __a: 1, sortField, sortDirection });

		url = `${CREATOR_GET_CREATOR_LIST_URL}?${otherParam}`;
		data = {
			maxRecords,
			page,
			search,
			creatorIds,
		};
		if (isActive) {
			data.isActive = isActive;
		}
	} else {
		url = `${CREATOR_SEGMENT_FIND_PROFILES}?${renderParam(dataParams)}`;
	}

	yield genericApiSaga({
		gatewayCall: () => (allProfiles ? putRequest(url, data) : getRequest(url)),
		*completed(response: any) {
			yield put(creatorFindProfilesCompleted(response.data));
		},
		*failed(response: any) {
			yield put(creatorFindProfilesFailed(response?.messages));
			generalMessage(response.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
		},
	});
}

function* creatorExportProfiles({ payload }: any) {
	const webUrl = window.location.href;
	const url = `${CREATOR_EXPORT_PROFILES_URL}?webUrl=${webUrl}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, payload),
		*completed(response: any) {
			const {
				data: {
					result: { base64 = '', uploadAWS = false },
				},
			} = response;

			yield put(creatorExportProfilesCompleted(response.data));

			if (!uploadAWS) {
				// < 10K RECORDS => DOWNLOAD FILE
				handleDownloadExcel(base64, 'EnTribe_Creator_List');
			} else {
				// DB HAS > 10k RECORDS => DISPLAY MESSAGE IN COMPONENT BY USING uploadAWS

				// NEED TO MODIFY THIS STATE uploadAWS = false IN REDUCER STORE
				// TO ENSURE THAT WE CAN DISPLAY MESSAGE IN COMPONENT IF WE IMPLEMENT NEXT EXPORTING ALL FILES
				yield put(creatorResetUploadAWS({ uploadAWS: false }));
			}
		},
		*failed(response: any) {
			yield put(creatorExportProfilesFailed(response?.messages));
			generalMessage(response.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
		},
	});
}

function* creatorGetProfile({ payload: { creatorId } }: any) {
	const url = `${CREATOR_API_URL}/${creatorId}`;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(creatorGetProfileCompleted(response.data));
		},
		*failed(response: any) {
			yield put(creatorGetProfileFailed(response?.messages));
			generalMessage(response.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
		},
	});
}

function* fetchCreatorListLite({ payload }: Action<{ page: number; maxRecords: number }>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CREATOR_LIST_LITE, payload),
		*completed(response: any) {
			yield put(actions.fetchCreatorListLiteCompleted(response.data));
		},
		*failed(response: any) {
			yield put(actions.fetchCreatorListLiteFailed(response?.messages));
			generalMessage(response.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
		},
	});
}

function* updateCreatorProfile({ payload: { creatorId, dataRequest } }: any) {
	const url = `${CREATOR_API_URL}/${creatorId}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, dataRequest),
		*completed(response: any) {
			yield put(actions.updateCreatorProfileSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.updateCreatorProfileFailed(response?.messages));
			generalMessage(
				response.error || response.data.error || DEFAULT_ERROR,
				MESSAGE_TYPE.ERROR,
			);
		},
	});
}

function* creatorBlock({ payload }: any) {
	const {
		requestBody,
		isBlock,
		isDelete,
		isAll = false,
		requestDataFindProfiles,
		isCalledFromProfile = false,
		socialType,
	} = payload;

	let url = `${CREATOR_BLOCK_PROFILES}?isBlock=${isBlock}&isDelete=${isDelete}&isAll=${isAll}`;

	if ('socialType' in payload && socialType !== '') {
		url += `&socialType=${socialType}`;
	}

	const findProfileData = {
		payload: {
			...requestDataFindProfiles,
		},
	};

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, requestBody),
		*completed(response: any) {
			if (!isCalledFromProfile) {
				yield call(creatorFindProfiles, findProfileData);
			}
			yield put(creatorBlockCompleted(response.data));
			message.success('Status updated!');
		},
		*failed(response: any) {
			yield put(creatorBlockFailed(response?.messages));
			generalMessage(response.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
		},
	});
}

function* creatorChangeStatus({
	payload: {
		requestBody,
		creatorStatus,
		creatorStatusValue,
		isAll = false,
		requestDataFindProfiles,
		isCalledFromProfile = false,
		noWaitFollow = false,
		messageComplete = '',
	},
}: any) {
	const url = `${CREATOR_CHANGE_STATUS_PROFILES}?creatorStatus=${creatorStatus}&creatorStatusValue=${creatorStatusValue}&isAll=${isAll}`;
	const findProfileData = {
		payload: {
			...requestDataFindProfiles,
		},
	};
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, requestBody),
		*completed(response: any) {
			if (!isCalledFromProfile) {
				yield call(creatorFindProfiles, findProfileData);
			}

			yield put(creatorChangeStatusCompleted({ ...response.data, noWaitFollow }));
			if (!noWaitFollow) {
				generalMessage(
					messageComplete || 'creator_profile.message.status_updated',
					MESSAGE_TYPE.SUCCESS,
				);
			}
		},
		*failed(response: any) {
			yield put(creatorChangeStatusFailed(response?.messages));
			generalMessage(response.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
		},
	});
}

function* creatorDelete({ payload: { requestBody, isAll, requestDataFindProfiles } }: any) {
	const url = `${CREATOR_API_URL}?isAll=${isAll}`;
	const findProfileData = {
		payload: {
			...requestDataFindProfiles,
		},
	};
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(url, requestBody),
		*completed(response: any) {
			yield call(creatorFindProfiles, findProfileData);
			yield put(creatorDeleteCompleted(response.data));
			message.success('Deleted successfully!');
		},
		*failed(response: any) {
			yield put(creatorDeleteFailed(response?.messages));
			generalMessage(response.error, MESSAGE_TYPE.ERROR);
		},
	});
}

function* creatorImport({ payload: { file, requestDataFindProfiles } }: any) {
	const url = `${CREATOR_IMPORT_PROFILES}`;
	const findProfileData = {
		payload: {
			...requestDataFindProfiles,
		},
	};

	const formData = new FormData();
	formData.append('file', file);

	yield genericApiSaga({
		gatewayCall: () => postRequest(url, formData),
		*completed(response: any) {
			if (file.size < 50000) {
				yield put(creatorImportCompleted(response.data));
				yield call(creatorFindProfiles, findProfileData);
			} else {
				message.warning(
					'Importing. We will notify you via email when the process is completed!',
				);
			}
		},
		*failed(response: any) {
			yield put(creatorImportFailed(response?.messages));
			generalMessage(response.error, MESSAGE_TYPE.ERROR);
		},
	});
}

function* creatorGetSegment({ payload }: any) {
	const searchParams = convertParamSearch(payload);
	const url = `${CREATOR_SEGMENT_LIST}?${searchParams}`;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(creatorGetSegmentCompleted(response.data));
		},
		*failed(response: any) {
			yield put(creatorGetSegmentFailed(response?.messages));
			generalMessage(response.error, MESSAGE_TYPE.ERROR);
		},
	});
}

function* createSegment({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${CREATOR_API_URL}/segment/create`, payload),
		*completed(response: any) {
			yield put(actions.createSegmentCompleted(response));
		},
		*failed(response: any) {
			yield put(actions.createSegmentFailed(response));
		},
	});
}

function* updateSegment({ payload }: any) {
	const url = `${CREATOR_API_URL}/segment/update?id=${payload.id}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, payload),
		*completed(response: any) {
			yield put(actions.updateSegmentCompleted(response));
		},
		*failed(response: any) {
			yield put(actions.updateSegmentFailed(response));
		},
	});
}

export function* getListCampaignSimple({ payload }: Action<any>) {
	const { status } = payload;
	let url = GET_LIST_CONTENT_CAMPAIGN;
	if (status) {
		url += `?status=${status}`;
	}
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.getListCampaignSimpleCompleted(response?.data));
		},
		*failed(response: any) {
			yield put(actions.getListCampaignSimpleFailed(response));
		},
	});
}

export function* getSegment(param?: any) {
	let paramRequest = '';
	if (param?.payload) {
		const searchParams = convertParamSearch(param?.payload);

		paramRequest += `?${searchParams}`;
	}
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${CREATOR_SEGMENT_SIMPLE}${paramRequest}`),
		*completed(response: any) {
			yield put(getSegmentCompleted(response));
		},
		*failed(response: any) {
			yield put(getSegmentFailed(response));
		},
	});
}

export function* fetchLocation() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CREATOR_LOCATION),
		*completed(response: any) {
			yield put(actions.getCreatorLocationSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getCreatorLocationFailed(response));
		},
	});
}

export function* updateStatusRecentlyAdded({ payload }: Action<any>) {
	const { isAll, creatorIds } = payload;
	const url =
		isAll === undefined
			? `${CREATOR_STATUS_RECENTLY_ADDED_URL}`
			: `${CREATOR_STATUS_RECENTLY_ADDED_URL}?isAll=${isAll}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, creatorIds),
		*completed(response: any) {
			yield put(updateStatusRecentlyAddedCompleted(response));
		},
		*failed(response: any) {
			yield put(updateStatusRecentlyAddedFailed(response));
		},
	});
}

export function* getCreatorUnviewedStatusRecentlyAdded() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CREATOR_STATUS_RECENTLY_ADDED_URL),
		*completed(response: any) {
			yield put(getCreatorUnviewedStatusRecentlyAddedSucceeded(response));
		},
		*failed(response: any) {
			yield put(getCreatorUnviewedStatusRecentlyAddedFailed(response));
		},
	});
}

function* getCompletedActions({
	payload,
}: Action<{ creatorId: number | string; page: number; maxRecords: number }>) {
	const { creatorId, page, maxRecords } = payload;
	yield genericApiSaga({
		gatewayCall: () =>
			getRequest(
				`${CREATOR_COMPLETED_ACTIONS}/${creatorId}/completed-actions?page=${page}&maxRecords=${maxRecords}`,
			),
		*completed(response: any) {
			yield put(actions.getCompletedActionsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getCompletedActionsFailed(response));
		},
	});
}

function* invalidateSocialUsername({
	payload,
}: Action<{
	username: string;
	source: string;
}>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(INVALID_CREATOR_SOCIAL_USERNAME, { ...payload }),
		*completed(response: any) {
			yield put(actions.invalidateSocialUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.invalidateSocialUsernameFailed(response));
		},
	});
}

function* getSocialStats({ payload }: Action<{ creatorId: number | string }>) {
	const { creatorId } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${AMBASSADOR_SOCIAL}${creatorId}/social-stats`),
		*completed(response: any) {
			yield put(actions.getSocialStatsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getSocialStatsEnd(response));
		},
	});
}

function* refreshSocialStats({ payload }: Action<{ creatorId: number | string }>) {
	const { creatorId } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${AMBASSADOR_CREATOR_REFRESH_METRICS_API(creatorId)}`),
		*completed(response: any) {
			yield put(actions.refreshSocialStatsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.refreshSocialStatsEnd(response));
		},
	});
}

function* downloadTemplate() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CREATOR_TEMPLATE),
		*completed(response: any) {
			yield put(actions.downloadTemplateCreatorSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.downloadTemplateCreatorFailed(response));
		},
	});
}

function* getMessageHistoryCreator({ payload }: Action<HistoryCreatorParams>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(MAIL_HISTORY_CREATOR, payload),
		*completed(response: any) {
			yield put(actions.fetchMessageHistoryCreatorSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchMessageHistoryCreatorFailed(response));
		},
	});
}

function* updateApprovalStatus({ payload }: Action<UpdateApprovalStatusDto>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(UPDATE_APPROVAL_STATUS, payload),
		*completed(response: any) {
			yield put(actions.updateApprovalStatusSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateApprovalStatusFailed(response));
		},
	});
}

function* createCreatorSagas() {
	yield takeLatest(CREATOR_FIND_PROFILES, creatorFindProfiles);
	yield takeLatest(CREATOR_EXPORT_PROFILES, creatorExportProfiles);
	yield takeLatest(CREATOR_GET_PROFILE, creatorGetProfile);
	yield takeLatest(actions.FETCH_CREATOR_LITE_REQUEST, fetchCreatorListLite);
	yield takeLatest(actions.UPDATE_CREATOR_PROFILE_REQUEST, updateCreatorProfile);
	yield takeLatest(CREATOR_CHANGE_STATUS, creatorChangeStatus);
	yield takeLatest(CREATOR_BLOCK, creatorBlock);
	yield takeLatest(CREATOR_DELETE, creatorDelete);
	yield takeLatest(CREATOR_IMPORT, creatorImport);
	yield takeLatest(CREATOR_GET_SEGMENT, creatorGetSegment);
	yield takeLatest(actions.GET_LIST_CAMPAIGN_SIMPLE_REQUEST, getListCampaignSimple);
	yield takeLatest(GET_SEGMENT, getSegment);
	yield takeLatest(UPDATE_STATUS_RECENTLY_ADDED_REQUEST, updateStatusRecentlyAdded);
	yield takeLatest(
		GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST,
		getCreatorUnviewedStatusRecentlyAdded,
	);
	yield takeLatest(actions.CREATE_SEGMENT, createSegment);
	yield takeLatest(actions.UPDATE_SEGMENT, updateSegment);
	yield takeLatest(actions.GET_LOCATION_CREATOR_REQUEST, fetchLocation);
	yield takeLatest(actions.GET_COMPLETED_ACTIONS_REQUEST, getCompletedActions);
	yield takeLatest(actions.INVALIDATE_SOCIAL_USERNAME_REQUEST, invalidateSocialUsername);
	yield takeLatest(actions.GET_SOCIAL_STATS_REQUEST, getSocialStats);
	yield takeLatest(actions.REFRESH_SOCIAL_STATS_REQUEST, refreshSocialStats);
	yield takeLatest(
		actions.downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_REQUEST,
		downloadTemplate,
	);
	yield takeLatest(actions.FETCH_MESSAGE_HISTORY_CREATOR_REQUEST, getMessageHistoryCreator);
	yield takeLatest(
		actions.updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_REQUEST,
		updateApprovalStatus,
	);
}

export default createCreatorSagas;
