import {
	CREATE_EMAIL_TEMPLATES,
	DELETE_EMAIL,
	DIRECT_MESSAGE,
	GET_DIRECT_MESSAGE,
	GET_DYNAMIC_FIELDS,
	GET_EMAIL_EVENT,
	GET_EMAIL_EVENT_DETAIL,
	GET_EMAIL_TEMPLATES,
	GET_EVENT_CREATOR,
	GET_TEMPLATES,
	MESSAGE,
	RESTORE_EMAIL_TEMPLATE,
	REWARD_API_URL,
	REWARD_GENERATE_CODE,
	SAVE_AS_TEMPLATE,
	SEND_TEST_MAIL,
	STATUSCODE,
	UPDATE_EMAIL_TEMPLATES,
} from '@constants/APIs';
import genericApiSaga from '@helpers/genericApiSaga';
import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import { omit } from 'lodash';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import { EMAIL_TEMPLATE_FOLDER_TYPES } from '@constants/messages';
import { SaveAsTemplatePayload } from '@models/messages/store';
import {
	DirectMessageDto,
	EmailPayloadDto,
	GenerateCodeDto,
	createDirectMessageTemplate,
	deleteDirectMessageTemplate,
} from '@models/messages/summary';
import { convertParamSearch } from '@utils/common';
import { Action } from 'redux-actions';
import * as actions from './messages.actions';
import {
	createDirectMessageTypes,
	deleteDirectMessageTemplateTypes,
	deleteEmailTypes,
	emailTemplateTypes,
	generateCodeTypes,
	getDirectMessageDetailTypes,
	getDirectMessageTemplate,
	getDirectMessageTypes,
	getDynamicFieldsTypes,
	getEmailEvenDetailTypes,
	getEmailEventTypes,
	getEventCreatorTypes,
	getRewardOffersTypes,
	getTemplatesTypes,
	saveAsTemplateTypes,
	saveDraftEmailTypes,
	sendEmailRewardTypes,
	sendTestMailTemplate,
	updateDirectMessageTypes,
} from './messages.types';
import { DEFAULT_ERROR } from '@constants/errors';
import { MESSAGE_TYPE } from '@constants/common';
import { generalMessage } from '@utils/renderComponent';

export function* getDynamicFields() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(GET_DYNAMIC_FIELDS),
		*completed(response: any) {
			yield put(actions.getDynamicFieldsSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.getDynamicFieldsFailed(response));
		},
	});
}

export function* getTemplates() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${GET_TEMPLATES}?builder=true`),
		*completed(response: any) {
			yield put(actions.getTemplatesSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.getTemplatesFailed(response));
		},
	});
}

export function* sendEmailReward({ payload }: Action<EmailPayloadDto>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${MESSAGE}/send`, payload),
		*completed(response: any) {
			yield put(actions.sendEmailRewardSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.sendEmailRewardFailed(response));
		},
	});
}

export function* saveDraftEmail({ payload }: Action<EmailPayloadDto>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${MESSAGE}/new-event`, payload),
		*completed(response: any) {
			yield put(actions.saveDraftEmailSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.saveDraftEmailFailed(response));
		},
	});
}

export function* getEmailEvent({ payload }: Action<any>) {
	const { params, creatorEmail = [] } = payload;
	const searchParams = convertParamSearch(params);

	const urlRequest = `${GET_EMAIL_EVENT}/filter?${searchParams}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(urlRequest, creatorEmail),
		*completed(response: any) {
			yield put(
				actions.getEmailEventSucceeded({
					...response.data,
					isFailed: params?.isFailed || false,
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.getEmailEventFailed(response));
		},
	});
}

export function* getEmailEventDetail({ payload }: Action<any>) {
	const { isGetMailStatus = false, ...dataRequest } = payload;

	yield genericApiSaga({
		gatewayCall: () => getRequest(GET_EMAIL_EVENT_DETAIL, dataRequest),
		*completed(response: any) {
			yield put(
				actions.getEmailEventDetailSucceeded({ data: response.data, isGetMailStatus }),
			);
		},
		*failed(response: any) {
			yield put(actions.getEmailEventDetailFailed(response));
		},
	});
}

export function* getEventCreator({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(GET_EVENT_CREATOR, payload),
		*completed(response: any) {
			yield put(actions.getEventCreatorSucceeded({ ...response.data, requestData: payload }));
		},
		*failed(response: any) {
			yield put(actions.getEventCreatorFailed(response));
		},
	});
}

export function* deleteEmail({ payload }: Action<any>) {
	const { selectedEmailList, type, onDeleteSucceeded, onDeleteFailed } = payload;
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${DELETE_EMAIL}?type=${type}`, selectedEmailList),
		*completed(response: any) {
			yield put(actions.deleteEmailSucceeded({ ...response.data, requestData: payload }));
			onDeleteSucceeded();
		},
		*failed(response: any) {
			yield put(actions.deleteEmailFailed(response));
			onDeleteFailed();
		},
	});
}

export function* generateCode({ payload }: Action<GenerateCodeDto>) {
	const { rewardType, generateType, ruleId, customCode, isUpdate } = payload;

	let requestParams: any = {
		generateType,
		ruleId,
	};

	if (customCode) {
		requestParams = { ...requestParams, customCode };
	}

	if ('creatorId' in payload) {
		requestParams = { ...requestParams, creatorId: payload.creatorId };
	}

	yield genericApiSaga({
		gatewayCall: () => postRequest(`${REWARD_GENERATE_CODE}?type=${rewardType}`, requestParams),
		*completed(response: any) {
			yield put(actions.generateCodeSucceeded({ ...response.data, isUpdate }));
		},
		*failed(response: any) {
			generalMessage(response.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
			yield put(actions.generateCodeFailed(response));
		},
	});
}
// *****************************************************************
// ******************* MESSAGE - EMAIL TEMPLATES *******************
// *****************************************************************

export function* getEmailTemplates({ payload }: Action<{ type?: string }>) {
	const url = `${GET_EMAIL_TEMPLATES}${payload?.type ? `?type=${payload?.type}` : ''}`;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(
				actions.getEmailTemplatesSucceeded({
					data: { ...response.data },
					type: payload?.type,
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.getEmailTemplatesFailed(response));
		},
	});
}

export function* getRewardOffers() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${REWARD_API_URL}/rule/group`),
		*completed(response: any) {
			yield put(actions.getRewardOffersSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.getRewardOffersFailed(response));
		},
	});
}

export function* sendTestMail({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(SEND_TEST_MAIL, payload),
		*completed(response: any) {
			yield put(actions.sendTestMailSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.sendTestMailFailed(response));
		},
	});
}

export function* editEmailTemplates({ payload }: Action<any>) {
	let type = '';
	const { id, type: newType, typeOrigin = '' } = payload;
	const url = `${UPDATE_EMAIL_TEMPLATES}=${id}`;

	const newPayload = omit(payload, 'typeOrigin');

	if (newType === 1) type = EMAIL_TEMPLATE_FOLDER_TYPES.DEFAULT;
	else if (newType === 2) type = EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM;
	else type = EMAIL_TEMPLATE_FOLDER_TYPES.ARCHIVED;

	const parrams = {
		...newPayload,
		type,
	};

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, parrams),
		*completed(response: any) {
			yield put(
				actions.updateEmailTemplatesSucceeded({
					data: { ...response.data, type, typeOrigin },
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.getEmailTemplatesFailed(response));
		},
	});
}

export function* saveAsTemplate({ payload }: Action<SaveAsTemplatePayload>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(SAVE_AS_TEMPLATE, payload),
		*completed(response: any) {
			yield put(actions.saveAsTemplateSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.saveAsTemplateFailed(response));
		},
	});
}

export function* createEmailTemplates({ payload }: Action<any>) {
	const url = CREATE_EMAIL_TEMPLATES;

	yield genericApiSaga({
		gatewayCall: () => postRequest(url, payload),
		*completed(response: any) {
			yield put(
				actions.createEmailTemplatesSucceeded({
					data: { ...response.data, actionType: payload?.actionType },
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.getEmailTemplatesFailed(response));
		},
	});
}

export function* restoreEmailTemplates({ payload }: Action<{ id: number; isDefault: boolean }>) {
	const { id = 0, isDefault = false } = payload;
	const url = RESTORE_EMAIL_TEMPLATE(id, isDefault);

	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(
				actions.restoreEmailTemplatesSucceeded({
					data: { ...response.data },
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.restoreEmailTemplatesFailed(response));
		},
	});
}

// Get direct message
export function* getDirectMessage({ payload }: Action<{ page: number; maxRecords: number }>) {
	const searchParams = convertParamSearch(payload);
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${DIRECT_MESSAGE}?${searchParams}`),
		*completed(response: any) {
			yield put(actions.getDirectMessageSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.getDirectMessageFailed(response));
		},
	});
}

// Get details of DM
export function* getDirectMessageDetail({
	payload,
}: Action<{
	selectId?: number;
	username: string;
	socialSource: string;
	directMessage: DirectMessageDto;
}>) {
	const { selectId = 0, username = '', socialSource = '', directMessage } = payload;
	let url: string = `${GET_DIRECT_MESSAGE}?userName=${username}&socialSource=${socialSource}`;

	if (selectId) url += `&selectId=${selectId}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, directMessage.template),
		*completed(response: any) {
			yield put(actions.getDirectMessageDetailSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.getDirectMessageDetailFailed(response));
		},
	});
}

// Update direct message
export function* updateDirectMessage({ payload }: Action<any>) {
	const { template, onSucceeded, onFailed } = payload;

	yield genericApiSaga({
		gatewayCall: () => putRequest(DIRECT_MESSAGE, template),
		*completed(response: any) {
			if (response) {
				const {
					status: { code = 0 },
					result,
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					onSucceeded(result);
				}
			}
		},
		*failed(response: any) {
			if (response) {
				const { status = {} } = response.data;
				let { error = '' } = response.data;

				error = error === null ? `${status.code} ${status.status}` : error;
				onFailed(error);
			}
		},
	});
}

// Create direct message
export function* createDirectMessage({ payload }: Action<any>) {
	const { template, onSucceeded, onFailed } = payload;

	yield genericApiSaga({
		gatewayCall: () => postRequest(DIRECT_MESSAGE, template),
		*completed(response: any) {
			if (response) {
				const {
					status: { code = 0 },
					result,
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					onSucceeded(result);
				}
			}
		},
		*failed(response: any) {
			if (response) {
				const { status = {} } = response.data;
				let { error = '' } = response.data;

				error = error === null ? `${status.code} ${status.status}` : error;
				onFailed(error);
			}
		},
	});
}

export function* getDirectMessageTemplateList() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${DIRECT_MESSAGE}`),
		*completed(response: any) {
			yield put(actions.getDirectMessageTemplateSuccessed(response.data));
		},
		*failed(response: any) {
			yield put(actions.getDirectMessageTemplateFailed(response.data));
		},
	});
}

export function* deleteDMTemplate({ payload }: Action<deleteDirectMessageTemplate>) {
	const { directMessage, onSucceeded } = payload;

	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${DIRECT_MESSAGE}?id=${directMessage.id}`),
		*completed(response: any) {
			yield put(actions.deleteDMTemplateSucceeded(response.data));
			if (onSucceeded) {
				onSucceeded();
			}
		},
		*failed(response: any) {
			yield put(actions.deleteDMTemplateFailed(response.data));
		},
	});
}

export function* createDMTemplate({ payload }: Action<createDirectMessageTemplate>) {
	const { name, handleCreateDMSuccess } = payload;
	const request = {
		name,
		messageTemplate: 'No content',
	};
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${DIRECT_MESSAGE}`, request),
		*completed(response: any) {
			yield put(actions.createDMTemplateSuccessed(response.data));
			handleCreateDMSuccess();
			yield put(actions.getDirectMessageTemplateRequest());
		},
		*failed(response: any) {
			yield put(actions.createDMTemplateFailed(response.data));
		},
	});
}

export function* watcherMessagesSagas() {
	yield takeLatest(getDynamicFieldsTypes.GET_DYNAMIC_FIELDS_REQUEST, getDynamicFields);
	yield takeLatest(getTemplatesTypes.GET_TEMPLATES_REQUEST, getTemplates);
	yield takeLatest(sendEmailRewardTypes.SEND_EMAIL_REWARD_REQUEST, sendEmailReward);
	yield takeLatest(saveDraftEmailTypes.SAVE_DRAFT_EMAIL_REQUEST, saveDraftEmail);
	yield takeEvery(getEmailEventTypes.GET_EMAIL_EVENT, getEmailEvent);
	yield takeEvery(getEmailEvenDetailTypes.GET_EMAIL_EVENT_DETAIL, getEmailEventDetail);
	yield takeEvery(getEventCreatorTypes.GET_EVENT_CREATOR, getEventCreator);
	yield takeLatest(deleteEmailTypes.DELETE_EMAIL, deleteEmail);
	yield takeLatest(generateCodeTypes.GENERATE_CODE_REQUEST, generateCode);
	yield takeLatest(getRewardOffersTypes.GET_REWARD_OFFERS_REQUEST, getRewardOffers);
	yield takeLatest(emailTemplateTypes.GET_EMAIL_TEMPLATES_REQUEST, getEmailTemplates);
	yield takeLatest(emailTemplateTypes.CREATE_EMAIL_TEMPLATES_REQUEST, createEmailTemplates);
	yield takeLatest(emailTemplateTypes.UPDATE_EMAIL_TEMPLATES_REQUEST, editEmailTemplates);
	yield takeLatest(saveAsTemplateTypes.SAVE_AS_TEMPLATE_REQUEST, saveAsTemplate);
	yield takeLatest(emailTemplateTypes.RESTORE_EMAIL_TEMPLATES_REQUEST, restoreEmailTemplates);
	yield takeLatest(sendTestMailTemplate.SEND_TEST_MAIL_REQUEST, sendTestMail);
	yield takeLatest(getDirectMessageTypes.GET_DIRECT_MESSAGE_REQUEST, getDirectMessage);
	yield takeLatest(getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL, getDirectMessageDetail);
	yield takeLatest(updateDirectMessageTypes.UPDATE_DIRECT_MESSAGE_REQUEST, updateDirectMessage);
	yield takeLatest(createDirectMessageTypes.CREATE_DIRECT_MESSAGE_REQUEST, createDirectMessage);
	yield takeLatest(
		getDirectMessageTemplate.GET_DIRECT_MESSAGE_TEMPLATE_REQUEST,
		getDirectMessageTemplateList,
	);
	yield takeLatest(deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_REQUEST, deleteDMTemplate);
	yield takeLatest(getDirectMessageTemplate.CREATE_DM_TEMPLATE_REQUEST, createDMTemplate);
}
