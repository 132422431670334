const fetchGalleryFolderTypes = {
	FETCH_FOLDER_GALLERY_REQUEST: 'FETCH_FOLDER_GALLERY_REQUEST',
	FETCH_FOLDER_GALLERY_SUCCEEDED: 'FETCH_FOLDER_GALLERY_SUCCEEDED',
	FETCH_FOLDER_GALLERY_FAILED: 'FETCH_FOLDER_GALLERY_FAILED',
};

const changeDetailFolderTypes = {
	UPDATE_DETAIL_FOLDER_GALLERY_REQUEST: 'UPDATE_DETAIL_FOLDER_GALLERY_REQUEST',
	UPDATE_DETAIL_FOLDER_GALLERY_SUCCEEDED: 'UPDATE_DETAIL_FOLDER_GALLERY_SUCCEEDED',
	UPDATE_DETAIL_FOLDER_GALLERY_FAILED: 'UPDATE_DETAIL_FOLDER_GALLERY_FAILED',
	UPDATE_DETAIL_FOLDER_GALLERY_END: 'UPDATE_DETAIL_FOLDER_GALLERY_END',
};

const createFolderGalleryTypes = {
	CREATE_FOLDER_GALLERY_REQUEST: 'CREATE_FOLDER_GALLERY_REQUEST',
	CREATE_FOLDER_GALLERY_SUCCEEDED: 'CREATE_FOLDER_GALLERY_SUCCEEDED',
	CREATE_FOLDER_GALLERY_FAILED: 'CREATE_FOLDER_GALLERY_FAILED',
	CREATE_FOLDER_GALLERY_END: 'CREATE_FOLDER_GALLERY_END',
};

const changeGallerySelectTypes = {
	CHANGE_GALLERY_SELECT: 'CHANGE_GALLERY_SELECT',
};

const fetchContentGalleryTypes = {
	FETCH_CONTENT_GALLERY_REQUEST: 'FETCH_CONTENT_GALLERY_REQUEST',
	FETCH_CONTENT_GALLERY_SUCCEEDED: 'FETCH_CONTENT_GALLERY_SUCCEEDED',
	FETCH_CONTENT_GALLERY_FAILED: 'FETCH_CONTENT_GALLERY_FAILED',
};

const fetchMetricsGalleryTypes = {
	FETCH_METRICS_GALLERY_REQUEST: 'FETCH_METRICS_GALLERY_REQUEST',
	FETCH_METRICS_GALLERY_SUCCEEDED: 'FETCH_METRICS_GALLERY_SUCCEEDED',
	FETCH_METRICS_GALLERY_FAILED: 'FETCH_METRICS_GALLERY_FAILED',
};

const resetFolderGalleryTypes = {
	RESET_FOLDER_GALLERY: 'RESET_FOLDER_GALLERY',
};

const changeCustomDetailContentGalleryTypes = {
	UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_REQUEST: 'UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_REQUEST',
	UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_SUCCEEDED:
		'UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_SUCCEEDED',
	UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_FAILED: 'UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_FAILED',
	UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_END: 'UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_END',
};

const assignAlbumToGalleryTypes = {
	ASSIGN_ALBUM_TO_GALLERY_REQUEST: 'ASSIGN_ALBUM_TO_GALLERY_REQUEST',
	ASSIGN_ALBUM_TO_GALLERY_SUCCEEDED: 'ASSIGN_ALBUM_TO_GALLERY_SUCCEEDED',
	ASSIGN_ALBUM_TO_GALLERY_FAILED: 'ASSIGN_ALBUM_TO_GALLERY_FAILED',
	ASSIGN_ALBUM_TO_GALLERY_END: 'ASSIGN_ALBUM_TO_GALLERY_END',
};

const unAssignAlbumToGalleryTypes = {
	UN_ASSIGN_ALBUM_TO_GALLERY_REQUEST: 'UN_ASSIGN_ALBUM_TO_GALLERY_REQUEST',
	UN_ASSIGN_ALBUM_TO_GALLERY_SUCCEEDED: 'UN_ASSIGN_ALBUM_TO_GALLERY_SUCCEEDED',
	UN_ASSIGN_ALBUM_TO_GALLERY_FAILED: 'UN_ASSIGN_ALBUM_TO_GALLERY_FAILED',
	UN_ASSIGN_ALBUM_TO_GALLERY_END: 'UN_ASSIGN_ALBUM_TO_GALLERY_END',
};

const removeAllContentGalleryTypes = {
	REMOVE_ALL_CONTENT_GALLERY_REQUEST: 'REMOVE_ALL_CONTENT_GALLERY_REQUEST',
	REMOVE_ALL_CONTENT_GALLERY_SUCCEEDED: 'REMOVE_ALL_CONTENT_GALLERY_SUCCEEDED',
	REMOVE_ALL_CONTENT_GALLERY_FAILED: 'REMOVE_ALL_CONTENT_GALLERY_FAILED',
	REMOVE_ALL_CONTENT_GALLERY_END: 'REMOVE_ALL_CONTENT_GALLERY_END',
};

const getCustomPropertiesTypes = {
	GET_CUSTOM_PROPERTIES_REQUEST: 'GET_CUSTOM_PROPERTIES_REQUEST',
	GET_CUSTOM_PROPERTIES_SUCCEEDED: 'GET_CUSTOM_PROPERTIES_SUCCEEDED',
	GET_CUSTOM_PROPERTIES_FAILED: 'GET_CUSTOM_PROPERTIES_FAILED',
	GET_CUSTOM_PROPERTIES_END: 'GET_CUSTOM_PROPERTIES_END',
};

export {
	fetchGalleryFolderTypes,
	changeDetailFolderTypes,
	createFolderGalleryTypes,
	changeGallerySelectTypes,
	fetchContentGalleryTypes,
	resetFolderGalleryTypes,
	changeCustomDetailContentGalleryTypes,
	fetchMetricsGalleryTypes,
	assignAlbumToGalleryTypes,
	unAssignAlbumToGalleryTypes,
	removeAllContentGalleryTypes,
	getCustomPropertiesTypes,
};
