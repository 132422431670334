import { IconInfoTooltip } from '@assets/icons';
import CustomSwitch from '@components/CustomSwitch';
import { FORM_ALBUM_MODAL, OPTION_SHARING, TYPE_ALBUM } from '@constants/content/albums';
import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import { AlbumDto, AlbumRespDto } from '@models/content/albums/albumManager';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import {
	createAlbumEnd,
	createAlbumRequest,
	updateAlbumEnd,
	updateAlbumRequest,
} from '@stores/actions';
import {
	StyledIcon,
	StyledIconWrapper,
	StyledRadioGroup,
	StyledText,
} from '@styled/Common/CommonStyled';
import {
	StyledAction,
	StyledAlbumModal,
	StyledChosenFilterWrapper,
	StyledFormItem,
	StyledInfoMoreSmart,
	StyledInput,
	StyledLabelField,
	StyledSubContent,
	StyledTextArea,
	StyledWrapperAction,
	StyledWrapperContentConfirm,
	StyledWrapperLabel,
} from '@styled/Content/AlbumManager/AlbumModalStyled';
import { StyledSelect } from '@styled/Content/ContentLibrary/FilterStyled';
import { Button, Form, message, Modal, Radio, Space, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectOptionNumberDto } from '@models/common/summary';
import { DownOutlined } from '@ant-design/icons';
import { StyledDirectLink } from '@styled/Content/ContentLibrary/ContentStyled';
import { useHistory } from 'react-router-dom';
import { AlbumsStoreDto } from '@models/content/albums/stores';

type PropAlbumModal = {
	[other: string]: any;
	onCancel: () => void;
	handleGetListAlbum?: (val: AlbumRespDto) => void;
	handleUpdateAlbum?: (val: AlbumRespDto) => void;
	albumDetail: AlbumDto | null;
	visible: boolean;
	messageText?: string;
	removeAlbum?: (val: string) => void;
	hiddenMessage?: boolean;
	filterIdDefault?: number;
	defaultName?: string;
};

type TypeOption = {
	label: string;
	value: string | number | any;
};

const AlbumModal = (props: PropAlbumModal) => {
	const {
		onCancel,
		handleGetListAlbum,
		albumDetail,
		removeAlbum,
		handleUpdateAlbum,
		messageText,
		hiddenMessage,
		visible,
		filterIdDefault,
		defaultName,
		...other
	} = props;

	const history = useHistory();

	const dispatch = useDispatch();
	const {
		statusCreateAlbum,
		error,
		statusUpdateAlbum,
		albumDetail: albumDetailRes,
		albumCreate,
		hideMessage,
	}: AlbumsStoreDto = useSelector((state: any) => state.albumManager);
	const { filterList } = useSelector((state: ContentRootStateDto) => state.content);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSmart, setIsSmart] = useState<boolean>(false);
	const [isDisplaySelect, setIsDisplaySelect] = useState<boolean>(false);
	const [chosenFilter, setChosenFilter] = useState<SelectOptionNumberDto | null>(null);
	const [typeAction, setTypeAction] = useState<string>('');

	const isSystemAlbum = useMemo(() => {
		if (albumDetail) {
			return (
				albumDetail.type === TYPE_ALBUM.FAVORITE_ALBUM ||
				albumDetail.type === TYPE_ALBUM.VERIFIED_ALBUM
			);
		}
		return false;
	}, [albumDetail]);

	const propsPopup = () => {
		const result: any = {
			okText: 'create_album',
			titleText: 'create_album',
			deleteText: 'delete_album',
		};
		if (visible) {
			if (albumDetail) {
				result.okText = 'save_changes';
				result.titleText = 'edit_album';
				if (handleUpdateAlbum && typeof handleUpdateAlbum === 'function') {
					result.funcUpdate = handleUpdateAlbum;
					result.funcRemove = removeAlbum;
				}
			} else {
				if (handleGetListAlbum && typeof handleGetListAlbum === 'function') {
					result.funcUpdate = handleGetListAlbum;
				}
			}
		}
		return result;
	};

	const handleDirectAlbum = () => {
		message.destroy('message_create_album_smart');
		history.push(`${ROUTE_PATH.ALBUMS}/${albumCreate?.id}`);
	};

	const renderMessageCreateAlbum = () => {
		if (!isSmart) {
			message.success(t(`${messageText || 'albums.message.create_successfully'}`));
		} else {
			const propsMessage: any = {
				values: { name: albumCreate?.name, text: t('albums.button.go_to_album') },
				i18nKey: 'albums.message.create_album_smart_success',
				components: {
					linkTo: <StyledDirectLink onClick={() => handleDirectAlbum()} />,
				},
			};

			message.success({
				content: <Trans t={t} {...propsMessage} />,
				duration: 3,
				key: 'message_create_album_smart',
			});
		}
	};

	useEffect(() => {
		if (statusCreateAlbum === SUCCEEDED) {
			if (!hideMessage) {
				renderMessageCreateAlbum();
			}
		}

		if (statusCreateAlbum === SUCCEEDED || statusCreateAlbum === FAILED) {
			dispatch(createAlbumEnd());
		}
	}, [statusCreateAlbum]);

	useEffect(() => {
		if (statusUpdateAlbum === SUCCEEDED) {
			if (!hideMessage) {
				message.success(t(`${messageText || 'albums.message.change_saved'}`));
			}
		}

		if (statusUpdateAlbum === SUCCEEDED || statusUpdateAlbum === FAILED) {
			dispatch(updateAlbumEnd());
		}
	}, [statusUpdateAlbum]);

	useEffect(() => {
		const listStatus = [statusCreateAlbum, statusUpdateAlbum];
		if (listStatus?.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus?.includes(FAILED)) {
			message.error(error);
		}

		if (listStatus?.includes(SUCCEEDED) && propsPopup()?.funcUpdate) {
			let album = albumDetailRes;
			if (statusCreateAlbum === SUCCEEDED) {
				album = albumCreate;
			}
			propsPopup().funcUpdate(album);
		}

		if (listStatus?.includes(SUCCEEDED) || listStatus?.includes(FAILED)) {
			setIsLoading(false);
			handleClose();
		}
	}, [statusCreateAlbum, statusUpdateAlbum]);

	useEffect(() => {
		let nameVal = defaultName || '';
		let descVal = '';
		let filterIdVal = null;
		let shareVal = OPTION_SHARING.NO;
		if (albumDetail) {
			const {
				name,
				metadata: { description = '', filterId, share },
			} = albumDetail;
			nameVal = name;
			descVal = description;
			filterIdVal = filterId || null;
			shareVal = !!share;

			setIsSmart(!!filterId);
		}

		form.setFieldsValue({
			[FORM_ALBUM_MODAL.NAME]: nameVal,
			[FORM_ALBUM_MODAL.DESC]: descVal,
			[FORM_ALBUM_MODAL.SHARE]: shareVal,
			[FORM_ALBUM_MODAL.FILTER]: filterIdVal,
		});
	}, [albumDetail?.id]);

	useEffect(() => {
		if (typeof filterIdDefault === 'number') {
			setIsSmart(true);
			form.setFieldsValue({
				[FORM_ALBUM_MODAL.FILTER]: filterIdDefault,
			});
		}
	}, [filterIdDefault]);

	const { t } = useTranslation();
	const [form] = Form.useForm();
	const initialValues = {
		[FORM_ALBUM_MODAL.NAME]: '',
		[FORM_ALBUM_MODAL.DESC]: '',
		[FORM_ALBUM_MODAL.SHARE]: OPTION_SHARING.NO,
	};

	const handleClose = () => {
		handleReset();
		onCancel();
	};

	const handleReset = () => {
		setIsDisplaySelect(false);
		setChosenFilter(null);
		setIsSmart(false);

		form.resetFields();
	};

	const handleSaveChange = () => {
		form.submit();
	};

	const handleFinishForm = async (values: any) => {
		const valueName = values[FORM_ALBUM_MODAL.NAME]?.trim() || '';
		const valueDesc = values[FORM_ALBUM_MODAL.DESC]?.trim() || '';
		form.setFieldsValue({
			[FORM_ALBUM_MODAL.NAME]: valueName,
			[FORM_ALBUM_MODAL.DESC]: valueDesc,
		});
		await form.validateFields();
		const metadataObj = {
			description: values?.description,
			filterId: isSmart ? values?.filterId || 0 : 0,
			share: !!values?.share,
		};
		const otherFieldChange: any = {
			type: isSmart ? TYPE_ALBUM.SMART_ALBUM : TYPE_ALBUM.DEFAULT,
			name: values?.name,
		};
		if (isSystemAlbum) {
			otherFieldChange.type = albumDetail?.type;
		}

		if (albumDetail) {
			const { loading, contents, totalContent, metadata, listContents, ...otherField } =
				albumDetail;
			const dataRequest = {
				...otherField,
				totalContent: 0,
				totalVideoContent: 0,
				metadata: JSON.stringify({ ...metadata, ...metadataObj }),
				...otherFieldChange,
			};
			setTypeAction('');
			dispatch(updateAlbumRequest({ ...dataRequest, hideMessage: !!hiddenMessage }));
		} else {
			dispatch(
				createAlbumRequest({
					...otherFieldChange,
					metadata: JSON.stringify(metadataObj),
					hideMessage: !!hiddenMessage,
				}),
			);
		}
	};

	const onCheckSaveChange = async () => {
		await form.validateFields();
		if (isSmart && albumDetail) {
			const {
				metadata: { filterId },
			} = albumDetail;

			if (!filterId) {
				return setTypeAction(TYPE_ACTIONS.CHANGE_SMART_ALBUM);
			} else {
				if (chosenFilter?.value && chosenFilter?.value !== filterId) {
					return setTypeAction(TYPE_ACTIONS.CHANGE_FILTER_SMART);
				}
			}
		}

		await handleSaveChange();
		return null;
	};

	const renderInputField = () => {
		return (
			<StyledInput
				disabled={isSystemAlbum}
				className="field_input_item"
				maxLength={255}
				placeholder={t('albums.placeholder.give_nickname')}
			/>
		);
	};

	const renderTextAreaField = () => {
		return (
			<StyledTextArea
				disabled={isSystemAlbum}
				placeholder={t('albums.placeholder.provide_desc')}
				autoSize={{ minRows: 3, maxRows: 3 }}
				rows={3}
			/>
		);
	};

	const renderGroupRadio = (list: Array<TypeOption>) => {
		return (
			<StyledRadioGroup>
				<Space direction="vertical">
					{list?.map((item) => (
						<Radio key={item?.value} value={item?.value}>
							{item?.label}
						</Radio>
					))}
				</Space>
			</StyledRadioGroup>
		);
	};

	const renderTooltipMoreInfoSmart = () => {
		return (
			<StyledInfoMoreSmart>
				{t('albums.modal.sub_info_smart_1')} <br />
				<br />
				{t('albums.modal.sub_info_smart_2')}
			</StyledInfoMoreSmart>
		);
	};

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const listOptionFilter = useMemo(() => {
		const result: SelectOptionNumberDto[] = (filterList || [])?.map((item) => {
			return { label: item?.name, value: item?.id };
		});

		if (filterIdDefault || albumDetail) {
			const filterTemp = result.find(
				(filter: SelectOptionNumberDto) =>
					filter.value === filterIdDefault ||
					filter.value === albumDetail?.metadata?.filterId,
			);

			if (filterTemp) setChosenFilter(filterTemp);
		}

		return result;
	}, [filterList, filterIdDefault]);

	const listOptionShare: Array<TypeOption> = [
		{
			label: t('albums.modal.anyone_external_link'),
			value: OPTION_SHARING.YES,
		},
		{
			label: t('albums.modal.cannot_share'),
			value: OPTION_SHARING.NO,
		},
	];

	const arrField = [
		{
			name: FORM_ALBUM_MODAL.NAME,
			rules: [
				{
					required: true,
					message: t('albums.validate.required'),
				},
			],
			label: t('albums.modal.album_nickname'),
			content: renderInputField(),
		},
		{
			name: FORM_ALBUM_MODAL.DESC,
			rules: [],
			label: t('albums.modal.album_description'),
			content: renderTextAreaField(),
		},
		{
			name: FORM_ALBUM_MODAL.SHARE,
			rules: [],
			label: t('albums.modal.set_sharing'),
			content: renderGroupRadio(listOptionShare),
		},
	];

	const onChangeSavedFilter = (value: any, option: any) => {
		setChosenFilter(option);
		setIsDisplaySelect(false);
	};

	const renderSavedFilter = () => {
		if (!isDisplaySelect && chosenFilter) {
			return (
				<StyledChosenFilterWrapper
					className={isSystemAlbum ? 'disabled' : ''}
					onClick={() => setIsDisplaySelect(true)}>
					<StyledText>
						{t('albums.modal.apply_saved_filter', {
							filter: chosenFilter.label,
						})}
					</StyledText>
					<StyledIconWrapper width="10px" height="10px">
						<DownOutlined />
					</StyledIconWrapper>
				</StyledChosenFilterWrapper>
			);
		}

		return (
			<StyledSelect
				defaultOpen={isDisplaySelect}
				onDropdownVisibleChange={(open: boolean) => setIsDisplaySelect(open)}
				notFoundContent={notFoundContent()}
				placeholder={t('albums.placeholder.choose_filter')}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				disabled={isSystemAlbum}
				virtual={false}
				optionFilterProp="label"
				options={listOptionFilter}
				dropdownClassName="dropdown-menu dropdown-custom-content"
				onChange={onChangeSavedFilter}
			/>
		);
	};

	return (
		<StyledAlbumModal
			{...other}
			centered={true}
			visible={visible}
			width={440}
			onCancel={handleClose}
			footer={[]}
			title={t(`albums.modal.${propsPopup()?.titleText}`)}>
			<Modal
				visible={
					typeAction === TYPE_ACTIONS.CHANGE_FILTER_SMART ||
					typeAction === TYPE_ACTIONS.CHANGE_SMART_ALBUM
				}
				onCancel={() => setTypeAction('')}
				centered
				footer={null}>
				<StyledWrapperContentConfirm>
					{typeAction === TYPE_ACTIONS.CHANGE_FILTER_SMART && (
						<>
							{t('albums.modal.sub_change_filter_smart')}
							<br />
							<br />
							{t('albums.modal.sub_continue')}
						</>
					)}
					{typeAction === TYPE_ACTIONS.CHANGE_SMART_ALBUM && (
						<>
							{t('albums.modal.sub_edit_smart_1')}
							<br />
							<br />
							{t('albums.modal.sub_edit_smart_2')}
						</>
					)}

					<StyledAction
						style={{ justifyContent: 'end', marginTop: '30px', width: '100%' }}>
						<Button
							onClick={() => {
								handleClose();
								setTypeAction('');
							}}>
							{t('button.cancel')}
						</Button>
						<Button
							type="primary"
							loading={isLoading}
							onClick={handleSaveChange}
							style={{ marginLeft: '10px' }}>
							{t('button.continue')}
						</Button>
					</StyledAction>
				</StyledWrapperContentConfirm>
			</Modal>
			<Form form={form} initialValues={initialValues} onFinish={handleFinishForm}>
				{arrField?.map((item) => (
					<StyledFormItem
						key={item?.name}
						name={item?.name}
						className="form-item_album"
						rules={item?.rules}
						label={
							<StyledWrapperLabel>
								<StyledLabelField>{item?.label}</StyledLabelField>
							</StyledWrapperLabel>
						}>
						{item?.content}
					</StyledFormItem>
				))}
				<StyledWrapperAction margin="24px 0 0 0">
					<StyledAction>
						<StyledLabelField style={{ marginRight: '8px' }}>
							{t('albums.modal.make_smart_album')}
						</StyledLabelField>
						<CustomSwitch
							disabled={isSystemAlbum}
							checked={isSmart}
							setChecked={setIsSmart}
						/>
					</StyledAction>
					{isSmart && (
						<StyledAction>
							<Tooltip placement="topLeft" title={renderTooltipMoreInfoSmart()}>
								<StyledIcon>
									<IconInfoTooltip />
								</StyledIcon>
							</Tooltip>
						</StyledAction>
					)}
				</StyledWrapperAction>
				{isSmart && (
					<>
						<StyledSubContent>{t('albums.modal.notify_smart_album')}</StyledSubContent>
						<StyledFormItem
							name={FORM_ALBUM_MODAL.FILTER}
							margin={'8px 0 0 0'}
							className="form-item_album"
							rules={[
								{
									required: true,
									message: t('albums.validate.required'),
								},
							]}>
							{renderSavedFilter()}
						</StyledFormItem>
					</>
				)}
			</Form>
			<StyledWrapperAction margin="30px 0 0 0">
				<StyledAction>
					{albumDetail && (
						<Button
							disabled={isSystemAlbum}
							onClick={() => propsPopup().funcRemove(TYPE_ACTIONS.REMOVE_ALBUM)}>
							{t('albums.button.delete_album')}
						</Button>
					)}
				</StyledAction>

				<StyledAction>
					<Button onClick={handleClose}>{t('albums.button.cancel')}</Button>
					<Button
						type="primary"
						loading={isLoading}
						onClick={onCheckSaveChange}
						style={{ marginLeft: '10px' }}>
						{t(`albums.button.${propsPopup()?.okText}`)}
					</Button>
				</StyledAction>
			</StyledWrapperAction>
		</StyledAlbumModal>
	);
};

export default AlbumModal;
