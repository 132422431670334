import { IconPhone } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { Collapse, Dropdown, Form, Input, Progress, Select, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export const StyledPreviewDetailWidget = styled.div`
	background: ${THEME.colors.darkBlue2};
	height: 100%;
	position: relative;
`;

export const StyledWrapperPreviewDetailWidget = styled.div`
	height: calc(100% - 54px);
	overflow-y: auto;
`;

export const StyledHeaderPreview = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 11px 15px 10px 7px;
`;

export const StyledWrapperInput = styled.div<{ borderColor?: string }>`
	width: fit-content;
	height: 28px;
	justify-content: space-between;
	position: relative;
	align-items: center;
	display: flex;
	padding: 4px 12px;
	border-radius: 20px;
	border: 1px solid ${({ borderColor }) => borderColor || 'transparent'};
	cursor: pointer;
	margin-right: 0;
	color: ${THEME.colors.gray5};
	background-color: ${THEME.colors.darkBlue3};
	font-size: 1rem;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledSettingDetailWidget = styled.div`
	background: ${THEME.colors.darkBlue2};
	height: 100%;
	position: relative;
`;

export const StyledBodyStep = styled.div`
	max-height: calc(100% - 156px);
	height: calc(100% - 156px);
	overflow: auto;
`;

export const StyledFooterStep = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 16px 9px 18px;
	border-top: 1px solid ${THEME.colors.darkBlue3};
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 30;
`;

export const StyledHeaderStep = styled.div`
	padding: 8px 16px;
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
	.ant-steps-item-icon {
		height: 32px !important;
		.ant-steps-icon {
			font-size: 0.714rem;
			font-weight: bold;
			top: -3px;
		}
	}
`;

export const StyledProgressDone = styled(Progress)`
	.ant-progress-text {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const StyledNumberStep = styled.span`
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border: 2.3px solid ${THEME.colors.darkBlue2};
	background: ${THEME.colors.orangeBase};
	font-weight: bold;
	font-size: 0.714rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: ${THEME.colors.gray5};
`;

export const StyledCustomColor = styled.div<{ marginTop?: string }>`
	width: 100%;
	display: flex;
	align-items: center;
	${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
	.children_popup {
		display: inline-flex;
		align-items: center;
	}
`;

export const StyledBoxColor = styled.div`
	background: ${THEME.colors.darkBlue4};
	border-radius: 8px;
	overflow: hidden;
	height: 42px;
	max-width: 118px;
	padding: 4px 4.7px;
	width: 118px;
	display: inline-flex;
	align-items: center;
	margin-right: 16px;
	cursor: pointer;
	&.disabled {
		pointer-events: none;
	}
`;

export const StyledResultColor = styled.span<{ bgColor: string }>`
	background: ${({ bgColor }) => bgColor};
	height: 34px;
	width: calc(100% - 27px);
	border-radius: 8px;
	margin-right: 4px;
`;

export const StyledBasicInfo = styled.div`
	padding: 16px 24px;
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
`;

export const StyledCustomInfo = styled.div`
	padding: 16px 24px;
`;

export const StyledLabelItem = styled.label`
	font-size: 1.143rem !important;
	color: ${THEME.colors.gray5};
	line-height: 2.13;
	height: 34px;
`;

export const StyledSubText = styled.span`
	font-size: 12px;
	display: block;
	margin-bottom: 16px;
	line-height: 1.2;
	color: ${THEME.colors.gray1};
`;

export const StyledListField = styled.div`
	margin-top: 16px;
	&.mt-10 {
		margin-top: 10px;
	}
	.mt_16 {
		margin-top: 16px;
	}
`;

export const StyledInput = styled(Input)`
	padding: 11px 10px;
	height: 41px;
	border-radius: 8px;
	background-color: ${THEME.colors.darkBlue3};
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.43;
	&.field_input_item {
		width: calc(100% - 32px);
		&:not(:first-child) {
			margin-top: 12px;
		}
		&.ant-input-disabled {
			background-color: ${THEME.colors.darkBlue3} !important;
		}
	}
	.ant-input {
		background-color: ${THEME.colors.darkBlue3};
		&.ant-input-disabled {
			background-color: ${THEME.colors.darkBlue3} !important;
		}
	}
`;

export const StyledInputCustomField = styled(Input)`
	padding: 6px 12px;
	font-size: 1rem;
	height: 32px;
	line-height: 1.43;
	border-radius: 2px;
	border: 1px solid ${THEME.colors.darkBlue4};
	background: ${THEME.colors.darkBlue3};
	color: ${THEME.colors.gray5};

	&.ant-input-disabled {
		background: ${THEME.colors.darkBlue3} !important;
	}
	&::placeholder {
		color: ${THEME.colors.gray1};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}
`;

export const StyledFieldItem = styled.div`
	display: flex;
	align-items: center;
	&.mt_16 {
		margin-top: 16px;
	}
	&:not(:last-child) {
		margin-bottom: 16px;
	}
	&.mb-12 {
		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}
	.hidden {
		display: none;
	}
	svg {
		margin-left: 8px;
	}
`;

export const StyledCustomFieldItem = styled.div<{ margin?: string; padding?: string }>`
	width: calc(100% - 32px);
	background-color: ${THEME.colors.darkBlue3};
	border-radius: 8px;
	${({ margin }) => margin && `margin: ${margin}`};
	padding: ${({ padding }) => padding || '15px'};
	.icon_drag {
		opacity: 0;
		transition: 0.15s ease-out;
	}
	&:hover {
		.icon_drag {
			opacity: 1;
		}
	}
`;

export const StyledLabelField = styled.label<{ required?: boolean; maxWidth?: string }>`
	font-size: 1rem;
	position: relative;
	line-height: 1.43;
	color: ${THEME.colors.gray5};
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
	&.custom_label {
		display: inline-flex;
		align-items: flex-start;
		&.overflow_text {
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&::after {
		display: ${({ required }) => (required ? 'inline-block' : 'none')};
		bottom: 3px;
		color: ${THEME.colors.redBase};
		position: absolute;
		margin-left: 3px;
		font-size: 14px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: '*';
	}
`;

export const StyledWrapperLabel = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
	position: relative;
	.ant-checkbox-wrapper {
		width: auto;
		margin: 0 !important;
	}
	&.width_full {
		width: 100%;
	}
`;

export const StyledTextArea = styled(TextArea)`
	background: ${THEME.colors.darkBlue3};
	font-size: 1rem;
	line-height: 1.43;
	color: ${THEME.colors.gray5};
	border-radius: 2px;
	border: 1px solid ${THEME.colors.darkBlue4};
	&::placeholder {
		color: ${THEME.colors.gray1};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}
`;

export const StyledMessageWarning = styled.div`
	font-size: 0.857rem;
	color: ${THEME.colors.redBase};
	margin-bottom: 16px;
`;

export const StyledCollapse = styled(Collapse)``;

export const StyledAddCustomField = styled(
	({ disabled, ...props }: { disabled?: boolean; [props: string]: any }) => <div {...props} />,
)`
	${({ disabled }) => disabled && 'cursor: not-allowed'};
	.dropdown_add_term {
		${({ disabled }) => disabled && 'pointer-events: none'};
	}
	margin-top: 16px;
	width: calc(100% - 32px);
	.ant-dropdown {
		& > .ant-dropdown-arrow {
			border-right-color: ${THEME.colors.gray3};
			border-bottom-color: ${THEME.colors.gray3};
			opacity: 0.4;
		}
	}
`;

export const StyledItemAddCustomField = styled.div`
	background-color: ${THEME.colors.darkBlue3};
	transition: 0.25s ease-out;
	padding: 8px 13px;
	display: flex;
	align-items: center;
	font-size: 1rem;
	color: ${THEME.colors.gray5};
	cursor: pointer;
	&.disabled {
		pointer-events: none;
		opacity: 0.6;
	}
	&:hover {
		background-color: ${THEME.colors.orangeBase};
	}
	svg {
		margin-right: 14px;
	}
`;

export const StyledIconPhone = styled(IconPhone)`
	path {
		fill: ${THEME.colors.gray2};
	}
`;

export const StyledDropdown = styled(Dropdown)`
	&.dropdown_w_auto {
		& > div {
			width: auto !important;
		}
	}
	.w_full {
		width: 100%;
	}
`;

export const StyledBtnAddCustom = styled.div<{ margin?: string }>`
	border-radius: 8px;
	font-size: 1rem;
	color: ${THEME.colors.gray5};
	display: flex;
	align-items: center;
	background-color: ${THEME.colors.darkBlue3};
	padding: 13px;
	${({ margin }) => margin && `margin: ${margin}`};
	cursor: pointer;
	& > svg {
		fill: ${THEME.colors.orangeBase} !important;
		margin-right: 8px;
	}
`;

export const StyledDropdownCustomField = styled.div`
	border-radius: 8px;
	background-color: ${THEME.colors.darkBlue3};
	overflow: hidden auto;
	max-height: 400px;
	box-shadow: 0 0 6px 0px ${THEME.colors.gray3};
`;

export const StyledFormItemCustomWidget = styled(Form.Item)<{ isMobile?: boolean }>`
	margin-bottom: ${({ isMobile }) => (isMobile ? '24px' : '29px')};
	&.form_checkbox {
		margin-bottom: ${({ isMobile }) => (isMobile ? '5px' : '10px')};
		.ant-form-item-control-input {
			min-height: unset;
		}
	}
	.ant-form-item-label {
		height: 22px;
		padding: 0;
		margin: 0 0 6px 0;

		label {
			width: 100%;
		}
	}
`;

export const StyledFormItem = styled(Form.Item)<{
	margin?: string;
	showRequired?: boolean;
	marginLabel?: string;
	fontSizeLabel?: string;
	heightLabel?: string;
}>`
	margin: ${({ margin }) => margin || '0 0 12px 0'};
	display: block;
	.ant-form-item-label {
		height: ${({ heightLabel }) => heightLabel || '22px'};
		padding: 0;
		margin: ${({ marginLabel }) => marginLabel || '0 0 6px 0'};

		label {
			width: 100%;
			height: unset;
		}
		& > label {
			${({ fontSizeLabel }) => fontSizeLabel && `font-size: ${fontSizeLabel}`};
			&.ant-form-item-required {
				&:not(.ant-form-item-required-mark-optional) {
					&::before {
						display: none;
					}
					&::after {
						display: ${({ showRequired }) => (showRequired ? 'inline-block' : 'none')};
						color: ${THEME.colors.redBase};
						font-size: 14px;
						font-family: SimSun, sans-serif;
						line-height: 1;
						content: '*';
					}
				}
			}
		}
	}
	&.label_full {
		.ant-form-item-label {
			width: 100%;
		}
	}
	&.hidden_form {
		display: none;
	}
`;

export const StyledTagDropdown = styled(Tag)`
	background-color: ${THEME.colors.darkBlue4};
	color: ${THEME.colors.gray5};
	margin-right: 5px !important;
	border: 0;
`;

export const StyledTermItem = styled.div`
	&.hidden_input {
		display: none;
	}
`;

export const StyledWrapperSetting = styled.div``;

export const StyledFormConfigUrl = styled.div`
	align-items: baseline;
	margin-top: 8px;
	height: 60px;
`;

export const StyledUrlDefault = styled.span`
	font-size: 1rem;
	line-height: 1.43;
	color: ${THEME.colors.gray5};
	margin-right: 24px;
`;

export const StyledInputUrl = styled(Input)<{ width?: string }>`
	width: ${({ width }) => `${width}` || '100%'};
	padding: 6px 12px;
	font-size: 1rem;
	line-height: 1.43;
	color: ${THEME.colors.gray5};
	::-webkit-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	input {
		&::-webkit-inner-spin-button {
			-webkit-appearance: none !important;
			margin: 0 !important;
		}
	}

	::placeholder {
		color: ${THEME.colors.gray1};
	}
`;

export const StyledAdvancedSetting = styled.div`
	margin-top: 8px;
`;

export const StyledSelect = styled(Select)<{ width?: string; margin?: string }>`
	padding: 0;
	margin: ${({ margin }) => margin || '0 0 12px 0'};
	width: ${({ width }) => `${width} !important` || '100%'};

	.ant-select-dropdown {
		&.dropdown-custom-content {
			overflow: auto !important;
			.rc-virtual-list-holder {
				max-height: calc(50vh - 160px) !important;
				.rc-virtual-list-holder-inner {
					transform: translateY(0px) !important;
				}
			}
			.rc-virtual-list-scrollbar {
				display: none !important;
			}
		}
	}
`;

export const StyledLineBox = styled.div`
	position: absolute;
	left: 0;
	width: 4px;
	height: 100%;
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue2};
`;

export const StyledWrapperListTerms = styled.div<{ maxHeight?: string }>`
	max-height: ${({ maxHeight }) => maxHeight || '400px'};
	overflow: hidden auto;
	width: 100%;
	margin-top: 16px;
	display: flex;
`;

export const StyledLineVertical = styled.div<{
	width?: string;
	color?: string;
	margin?: string;
	height?: string;
}>`
	width: ${({ width }) => width || '5px'};
	height: ${({ height }) => height || 'auto'};
	border-radius: 4px;
	background-color: ${({ color }) => color || THEME.colors.darkBlue3};
	${({ margin }) => margin && `margin: ${margin}`};
`;

export const StyledPreviewFormTerms = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 12;
`;
