import { Checkbox, Form, Input, message } from 'antd';
import _isEmpty from 'lodash.isempty';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { LoadingWrapper } from '@cores/index';
import { setUser } from '@helpers/userHelpers';
import {
	clientSettingsRequest,
	folderSystemRequest,
	userLogin,
	userLoginEnd,
} from '@stores/actions';

// Import styled components
import { StyledButton, StyledCustomTitle } from '@styled/Common/CommonStyled';
import {
	StyledFormWrapper,
	StyledImg,
	StyledLayout,
	StyledLink,
	StyledLogo,
} from '@styled/LoginStyled';

import { entribeLogo } from '@assets/images';
import ForgotPasswordWithLogin from '@components/Login/ForgotPasswordWithLogin';
import LoginWithMFA from '@components/Login/LoginWithMFA';
import { MESSAGE_KEY } from '@constants/common';
import { UserStoreType } from '@stores/user/user.types';
import { useDevice } from '@utils/renderComponent';
import { getAllCookie } from '@helpers/cookies';

type LoginProps = {};

const Login = ({}: LoginProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const {
		fetchingUser,
		user = null,
		error,
		mfaLoginToken,
	}: UserStoreType = useSelector((state: any) => state.user);

	const MODAL = {
		LOGIN_WITH_MFA: 'LOGIN_WITH_MFA',
		FORGOT_PASSWORD: 'FORGOT_PASSWORD',
	};

	const [modalType, setModalType] = useState<string | null>(null);
	const { isMobile } = useDevice();

	useEffect(() => {
		if (error) {
			message.error(error);
			dispatch(userLoginEnd());
		} else if (mfaLoginToken) {
			setModalType(MODAL.LOGIN_WITH_MFA);
		}
	}, [error, mfaLoginToken]);

	useEffect(() => {
		const config = {
			key: MESSAGE_KEY.OPTIMIZED_DEVICE,
			className: 'optimized-device',
			style: {
				textAlign: 'left',
				padding: '8px 16px',
			},
			content: t('message.optimized_device'),
			duration: 0,
		};

		if (isMobile) {
			message.info(config);
		} else {
			message.destroy(config.key);
		}
	}, [isMobile]);

	const handleSubmit = (values: any) => {
		if (!_isEmpty(values)) {
			const { username = '', password = '', remember = false } = values;
			const requestData = {
				role: 'CADMIN',
				remember,
				params: {
					username,
					password,
				},
			};
			dispatch(userLogin(requestData));
		}
	};

	const handleErr = (errors: unknown) => {
		console.log('Failed:', errors);
	};

	const handleEmailModal = () => {
		setModalType(MODAL.FORGOT_PASSWORD);
		form.resetFields();
	};

	const resetData = () => {
		setModalType(null);
		form.resetFields();
	};

	const modal = useMemo(() => {
		return {
			isForgotPassword: modalType === MODAL.FORGOT_PASSWORD,
			isMFA: modalType === MODAL.LOGIN_WITH_MFA,
		};
	}, [modalType]);

	if (user && !_isEmpty(user)) {
		setUser(user);
		return <Redirect to="/dashboard" />;
	}

	return (
		<LoadingWrapper isLoading={fetchingUser}>
			<StyledLayout isHidden={modal.isMFA}>
				<StyledFormWrapper className={isMobile ? 'login-mobile' : ''}>
					<StyledLogo>
						<StyledImg src={entribeLogo} alt={t('company_name')} />
					</StyledLogo>
					<StyledCustomTitle
						className="login-title"
						fontSize="1.714rem"
						fontWeight="700"
						margin="0 0 32px 0">
						{t('register.welcome')}
					</StyledCustomTitle>

					<Form
						name="login-form"
						layout="vertical"
						requiredMark={false}
						initialValues={{ remember: true }}
						onFinish={handleSubmit}
						onFinishFailed={handleErr}>
						<Form.Item
							label="Username"
							name="username"
							rules={[{ required: true, message: 'Please input your username!' }]}>
							<Input />
						</Form.Item>

						<Form.Item
							label="Password"
							name="password"
							rules={[{ required: true, message: 'Please input your password!' }]}>
							<Input.Password />
						</Form.Item>

						<Form.Item name="remember" valuePropName="checked">
							<Checkbox>{t('login.remember')}</Checkbox>
						</Form.Item>

						<Form.Item>
							<StyledButton width="100%" type="primary" htmlType="submit">
								{t('button.login')}
							</StyledButton>
						</Form.Item>
					</Form>

					<StyledCustomTitle className="forgot-password-hint" fontSize="16px">
						{t('login.forgot_password')}
						<StyledLink onClick={handleEmailModal}>{t('login.click_here')}</StyledLink>
					</StyledCustomTitle>
				</StyledFormWrapper>
			</StyledLayout>

			{modal.isForgotPassword && (
				<ForgotPasswordWithLogin visible={modal.isForgotPassword} onClose={resetData} />
			)}

			{modal.isMFA && <LoginWithMFA visible={modal.isMFA} onGoBack={resetData} />}
		</LoadingWrapper>
	);
};

export default React.memo(Login);
