import { SOCIAL, SOCIAL_UPCASE } from '@constants/social_search';
import { ConfigGraphTypes, FilterAmbassadorTypes } from '@models/ambassador';
import { AmbassadorMetric } from '@models/ambassador/stores';

const INTERVAL = {
	DAILY: 'DAILY',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY',
	SHOW_CUMULATIVE_DATA: 'SHOW_CUMULATIVE_DATA',
};

const NAME_CONFIG = {
	SOURCES: 'sources',
	INTERVAL: 'interval',
	SHOW_CUMULATIVE_DATA: 'isShowCumulativeData',
	SHOW_DYNAMIC_SCALE: 'isDynamicScale',
};

const DEFAULT_FORM_CONFIG: ConfigGraphTypes & any = {
	[NAME_CONFIG.SOURCES]: [
		SOCIAL_UPCASE.INSTAGRAM,
		SOCIAL_UPCASE.TIKTOK,
		SOCIAL_UPCASE.TWITTER,
		SOCIAL_UPCASE.YOUTUBE,
	],
	[NAME_CONFIG.INTERVAL]: INTERVAL.WEEKLY,
	[NAME_CONFIG.SHOW_CUMULATIVE_DATA]: false,
	[NAME_CONFIG.SHOW_DYNAMIC_SCALE]: false,
};

const SORT_BY = {
	TASKS: 'COMPLETED_TASKS',
	VOTES: 'VOTES',
	ASR: 'AVG_STAR',
	SUBMISSIONS: 'SUBMISSIONS',
	POSTS: 'POSTS',
};

const SUB_ID = {
	CAMPAIGN: 'campaignId_',
	SEGMENT: 'segmentId_',
	ACTIVE: 'active_',
	IN_ACTIVE: 'in_active_',
	OTHER: 'other_',
};

const NAME_FILTER = {
	SOURCES: 'sources',
	DATE_RANGE: 'dateRange',
	CAMPAIGN_ID: 'campaignIds',
};

const DEFAULT_PARAM_SOCIAL_ACTIVITY = {
	page: 1,
	maxRecords: 50,
};

const DEFAULT_FILTER_AMBASSADOR: FilterAmbassadorTypes = {
	sources: [
		SOCIAL_UPCASE.INSTAGRAM,
		SOCIAL_UPCASE.TIKTOK,
		SOCIAL_UPCASE.TWITTER,
		SOCIAL_UPCASE.YOUTUBE,
	],
	from: undefined,
	to: undefined,
	campaignIds: [],
};

const COL_MEDIA = {
	C3: 3,
	C2: 2,
	C4: 4,
	C6: 6,
};

const ID_CONTAINER_SOCIAL_ACTIVITY = 'container_social_activity';

const TYPE_SORT = {
	SORT_DATE: 'timestamp',
	FOLLOWER_COUNT: 'followers',
	LIKES: 'likes',
	COMMENTS: 'comments',
};

const AMBASSADOR_METRIC_DEFAULT: AmbassadorMetric = {
	campaigns: 0,
	ambassadors: 0,
	ambassadorsDelta: 0,
	socialPosts: 0,
	socialPostsDelta: 0,
	totalLikesAndComments: 0,
	totalLikesAndCommentsDelta: 0,
	potentialReach: 0,
	potentialReachDelta: 0,
	lastUpdated: 0,
	beforeUpdated: 0,
	submissions: 0,
	submissionsDelta: 0,
	campaignGoals: null,
	activeCampaigns: 0,
	allCreator: 0,
	allCreatorDelta: 0,
	allPotentialReach: 0,
	allPotentialReachDelta: 0,
	allSocialPosts: 0,
	allSocialPostsDelta: 0,
	allSubmissions: 0,
	allSubmissionsDelta: 0,
	allTotalLikesAndComments: 0,
	allTotalLikesAndCommentsDelta: 0,
	inactiveCampaigns: 0,
};

const LIMIT_VAL = {
	S1k: 1000,
	S5_9: 99999,
	S100k: 100000,
	S6_9: 999999,
	S1m: 1000000,
	DEFAULT_MIN_Y: 0,
	DEFAULT_MAX_Y: 3,
	DEFAULT_VAL: -1,
};

export {
	INTERVAL,
	LIMIT_VAL,
	DEFAULT_FORM_CONFIG,
	NAME_CONFIG,
	SORT_BY,
	DEFAULT_FILTER_AMBASSADOR,
	NAME_FILTER,
	TYPE_SORT,
	DEFAULT_PARAM_SOCIAL_ACTIVITY,
	ID_CONTAINER_SOCIAL_ACTIVITY,
	COL_MEDIA,
	AMBASSADOR_METRIC_DEFAULT,
	SUB_ID,
};
