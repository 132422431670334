import { IconKeyboardArrowRight, IconLightBulb, IconLightBulbBasic } from '@assets/icons';
import GalleryTag from '@components/ContentGlobal/ContentDetail/FormItemSelect/GalleryTag';
import { TYPE_GALLERY } from '@constants/content/contentLibrary';
import {
	DEFAULT_RANGE_RATING,
	FILTER_PUBLISHED,
	MARK_RATING,
	NAME_FILTER_CONTENT,
	RESOLUTION_RANK,
	RIGHT_CLEAR_FILTER,
	STATUS_FILTER,
	TYPE_FILTER,
} from '@constants/filter/filterContent';
import { TYPE_THEME } from '@constants/publish/gallery';
import { StateCampaign } from '@models/campaign';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { OptionFilterTag, OptionTypeItem } from '@models/filter';
import { FolderDto } from '@models/settings/content';
import { SettingRootStateDto } from '@models/settings/setting';
import {
	StyledDatePicker,
	StyledIcon,
	StyledTitle,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledSelect,
} from '@styled/Content/ContentLibrary/FilterStyled';
import { StyledCollapse, StyledCollapseInfo } from '@styled/ContentLibrary/ContentDetailStyled';
import {
	StyledDividerLine,
	StyledFormItem,
	StyledInput,
	StyledLabel,
	StyledRadioGroup,
	StyledRate,
	StyledTitleAdvanced,
	StyledWrapperAdvanced,
} from '@styled/Filter/FilterContentStyled';
import { Checkbox, Radio, Slider, Space, Tooltip } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { filterGalleryUnAssign } from '@helpers/arrayHelper';
import { StyledSlider } from '@styled/Content/WidgetManager';
import THEME from '@constants/themes/themes';
import { renderListContentSources } from '@utils/renderComponent';
import { getUser } from '@helpers/userHelpers';
import { ROLE } from '@constants/settings';
import CustomFieldFilter from './CustomFieldFilter';
import FilterContentContext from '@contexts/Filter/filterContent';
import { OPERATOR } from '@constants/social_search';

const { Panel } = StyledCollapse;
const { OptGroup, Option } = StyledSelect;

type PropsDefaultFilter = {
	isVerify?: boolean;
	isFindSimilar: boolean;
	setIsFindSimilar: (val: boolean) => void;
	form: any;
};
const DetailFilter = (props: PropsDefaultFilter) => {
	const { t } = useTranslation();
	const { state = {} } = useLocation<any>();
	const { filterSelected } = useContext(FilterContentContext);

	const user = getUser();
	const { role } = user;

	const isEReviewer = role.toLowerCase() === ROLE.EREVIEWER.value.toLowerCase();

	const { isVerify, isFindSimilar, setIsFindSimilar, form } = props;

	const [galleryList, setGalleryList] = useState<FolderDto[]>([]);
	const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
	const { folders = [] } = useSelector((state: SettingRootStateDto) => state.settings.content);
	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);
	const { segmentList = [] } = useSelector((state: any) => state.creator);
	const {
		contentLocation,
		tags,
		ratios,
		detectionServiceLabelList,
		deviceMakeList,
		deviceModelList,
		shotCountriesList,
	} = useSelector((state: ContentRootStateDto) => state.content);
	const { flags, labels, googleAIHashtags } = tags;

	useEffect(() => {
		if (folders?.length > 0) {
			setGalleryList(filterGalleryUnAssign(folders, true));
		}
	}, [folders]);

	useEffect(() => {
		if (state && !_.isEmpty(state)) {
			if (state?.locations || state?.idSegment) {
				setShowAdvanced(true);
			}
		}
	}, [state]);

	const listResolution = [
		{
			id: RESOLUTION_RANK.HIGH,
			title: t('filter.filter_content.high'),
		},
		{
			id: RESOLUTION_RANK.LOW,
			title: t('filter.filter_content.low'),
		},
		{
			id: RESOLUTION_RANK.MEDIUM,
			title: t('filter.filter_content.medium'),
		},
	];

	const renderRadioGroup = (list: Array<OptionTypeItem & any>, isVertical?: boolean) => {
		return (
			<StyledRadioGroup className="radio_group_default first_0">
				<Space direction={isVertical ? 'vertical' : 'horizontal'}>
					{list?.map((item) => (
						<Radio key={item?.value} value={item?.value}>
							{item?.name}
						</Radio>
					))}
				</Space>
			</StyledRadioGroup>
		);
	};

	const renderCheckboxGroup = (list: Array<OptionTypeItem>) => {
		return (
			<StyledCheckboxGroup className="checkbox_group_half">
				{list.map((checkbox: OptionTypeItem) => (
					<StyledCheckbox
						value={checkbox.value}
						key={checkbox.value}
						disabled={
							isVerify &&
							(checkbox.value === RIGHT_CLEAR_FILTER.APPROVED ||
								checkbox.value === RIGHT_CLEAR_FILTER.CUSTOM_TERMS)
						}
						className="checkbox_default checkbox_custom">
						{checkbox.name}
					</StyledCheckbox>
				))}
			</StyledCheckboxGroup>
		);
	};

	const renderRating = () => {
		return (
			<StyledSlider
				range
				min={DEFAULT_RANGE_RATING[0]}
				max={DEFAULT_RANGE_RATING[1]}
				defaultValue={[0, 5]}
				className="range_custom"
				marks={MARK_RATING}
			/>
		);
	};

	const renderNotRate = () => {
		return <Checkbox>{t('filter.filter_content.show_unrated')}</Checkbox>;
	};

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const renderListOption = (options: Array<any>, name: string) => {
		if (name === NAME_FILTER_CONTENT.TAGS) {
			return options?.map((item) => {
				return { name: item?.value, value: item?.value?.trim() };
			});
		}
		if (name === NAME_FILTER_CONTENT.GALLERIES || name === NAME_FILTER_CONTENT.CAMPAIGNS) {
			return options?.map((option) => ({
				label: option?.name,
				value: option?.id?.toString(),
			}));
		}
		if (
			name === NAME_FILTER_CONTENT.CREATOR_SEGMENT ||
			name === NAME_FILTER_CONTENT.RESOLUTION_QUALITY
		) {
			return options?.map((option) => ({
				label: option?.title,
				value: option?.id?.toString(),
			}));
		}
		if (
			name === NAME_FILTER_CONTENT.ASPECT_RATIOS ||
			name === NAME_FILTER_CONTENT.LOGOS_DETECTED ||
			name === NAME_FILTER_CONTENT.VIDEO_LABEL_DETECTED ||
			name === NAME_FILTER_CONTENT.SHOT_LOCATION ||
			name === NAME_FILTER_CONTENT.DEVICE_MAKES ||
			name === NAME_FILTER_CONTENT.DEVICE_MODELS
		) {
			return options?.map((option) => ({
				label: option,
				value: option,
			}));
		}
		return options;
	};

	const renderTagGallery = (val: any) => {
		const galleryItem: FolderDto | null =
			galleryList?.find((item: FolderDto) => item?.id === val?.value) || null;
		let theme = TYPE_THEME.GRID;
		if (galleryItem && galleryItem?.metadata) {
			const metadataObj = JSON.parse(galleryItem?.metadata);
			if (metadataObj?.theme) {
				theme = metadataObj?.theme;
			}
		}
		return <GalleryTag theme={theme} {...val} />;
	};

	const renderOtherPropSelect = (name: string) => {
		const result: any = {};
		if (name === NAME_FILTER_CONTENT.GALLERIES) {
			result.tagRender = renderTagGallery;
		}
		return result;
	};

	const renderSelect = (list: Array<any>, name: string) => {
		let placeholder = t('filter.filter_content.any');
		let mode: 'tags' | 'multiple' | undefined;
		const arrCheck = [
			{
				name: NAME_FILTER_CONTENT.TAGS,
				placeholder: t('filter.filter_content.any_tags'),
			},
			{
				name: NAME_FILTER_CONTENT.GALLERIES,
				placeholder: t('filter.filter_content.any_galleries'),
			},
			{
				name: NAME_FILTER_CONTENT.CAMPAIGNS,
				placeholder: t('filter.filter_content.any_campaigns'),
			},
			{
				name: NAME_FILTER_CONTENT.CREATOR_SEGMENT,
				placeholder: t('filter.filter_content.any_creator_segments'),
			},
			{
				name: NAME_FILTER_CONTENT.ASPECT_RATIOS,
				placeholder: t('filter.filter_content.any_ratio'),
			},
			{
				name: NAME_FILTER_CONTENT.RESOLUTION_QUALITY,
				placeholder: t('filter.filter_content.any_resolution'),
			},
			{
				name: NAME_FILTER_CONTENT.SHOT_LOCATION,
				placeholder: t('filter.filter_content.any_location'),
			},
			{
				name: NAME_FILTER_CONTENT.DEVICE_MAKES,
				placeholder: t('filter.filter_content.any_make'),
			},
			{
				name: NAME_FILTER_CONTENT.DEVICE_MODELS,
				placeholder: t('filter.filter_content.any_model'),
			},
		];
		const itemFind = arrCheck?.find((item) => item?.name === name);
		if (itemFind) {
			placeholder = itemFind?.placeholder;
		}

		if (name !== NAME_FILTER_CONTENT.UPLOAD_LOCATION) {
			mode = 'multiple';
		}

		return (
			<StyledSelect
				mode={mode}
				showSearch
				notFoundContent={notFoundContent()}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				placeholder={placeholder}
				virtual={false}
				optionFilterProp="label"
				showArrow
				options={renderListOption(list, name)}
				dropdownClassName="dropdown-menu dropdown-custom-content"
				className={`no_bg ${
					name === NAME_FILTER_CONTENT.GALLERIES ? 'select_gallery' : ''
				}`}
				{...renderOtherPropSelect(name)}
			/>
		);
	};

	const renderSelectGroup = (name: string) => {
		let mode: 'multiple' | 'tags' | undefined = 'multiple';
		let placeholder = t('filter.filter_content.any_tags');

		let child = (
			<>
				{listOptionFilterTag?.map((item) => (
					<OptGroup key={item?.nameGroup} label={item?.label}>
						{item?.options?.map((option) => {
							const val = JSON.stringify({
								val: option?.value,
								group: item?.nameGroup,
							});
							return (
								<Option key={val} value={val}>
									{option?.name}
								</Option>
							);
						})}
					</OptGroup>
				))}
			</>
		);

		if (name === NAME_FILTER_CONTENT.UPLOAD_LOCATION) {
			mode = 'multiple';
			placeholder = t('filter.filter_content.any');
			child = (
				<>
					{contentLocation?.map((item) => {
						if (!item?.state) {
							const val = item?.name
								? JSON.stringify({
										country: item?.abbreviation,
										state: null,
								  })
								: '';
							return (
								<Option key={val || Math.random()} value={val}>
									{item?.name}
								</Option>
							);
						}
						return (
							<OptGroup key={item?.abbreviation} label={item?.name}>
								{item?.state?.map((option) => {
									const val = JSON.stringify({
										country: item?.abbreviation,
										state: option?.abbreviation || null,
									});
									return (
										<Option key={val} value={val}>
											{option?.name}
										</Option>
									);
								})}
							</OptGroup>
						);
					})}
				</>
			);
		}

		return (
			<StyledSelect
				mode={mode}
				showSearch
				virtual={false}
				allowClear
				optionFilterProp={'children'}
				notFoundContent={notFoundContent()}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				placeholder={placeholder}
				showArrow
				className="select_group_tags no_bg"
				dropdownClassName="dropdown-menu dropdown-custom-content">
				{child}
			</StyledSelect>
		);
	};

	const renderSelectRangeDate = () => {
		return <StyledDatePicker className="no_bg" format={TYPE_FORMAT_DATE.MONTH_DAY} />;
	};

	const renderInput = (name: string) => {
		let placeholder = t('filter.filter_content.search');
		if (name === NAME_FILTER_CONTENT.KEYWORD) {
			placeholder = t('filter.filter_content.placeholder_keyword');
		}
		if (name === NAME_FILTER_CONTENT.CREATORS) {
			placeholder = t('filter.filter_content.placeholder_creator');
		}

		return <StyledInput className="no_bg" placeholder={placeholder} />;
	};

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo>
				<StyledTitle className="title_hover" fontSize="20px">
					{t('filter.filter_content.advanced_filters')}
				</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	const listQueryOperator: Array<OptionTypeItem & any> = [
		{
			name: (
				<Trans
					t={t}
					values={{}}
					i18nKey={'filter.filter_content.and_operator_desc'}
					components={{
						elm: <i />,
					}}
				/>
			),
			value: OPERATOR.AND,
		},
		{
			name: (
				<Trans
					t={t}
					values={{}}
					i18nKey={'filter.filter_content.or_operator_desc'}
					components={{
						elm: <i />,
					}}
				/>
			),
			value: OPERATOR.OR,
		},
	];

	const listType: Array<OptionTypeItem> = [
		{ name: t('filter.filter_content.all'), value: TYPE_FILTER.ALL },
		{ name: t('filter.filter_content.photo'), value: TYPE_FILTER.PHOTO },
		{ name: t('filter.filter_content.video'), value: TYPE_FILTER.VIDEO },
	];

	const listStatus: Array<OptionTypeItem> = [
		{ name: t('filter.filter_content.any'), value: STATUS_FILTER.ANY },
		{ name: t('filter.filter_content.needs_review'), value: STATUS_FILTER.NEEDS_REVIEW },
		{ name: t('filter.filter_content.reviewed'), value: STATUS_FILTER.REVIEWED },
	];

	const listRightClear: Array<OptionTypeItem> = [
		{ name: t('filter.filter_content.none'), value: RIGHT_CLEAR_FILTER.NONE },
		{ name: t('filter.filter_content.pending'), value: RIGHT_CLEAR_FILTER.PENDING },
		{ name: t('content_detail.rights_granted'), value: RIGHT_CLEAR_FILTER.APPROVED },
		{ name: t('filter.filter_content.custom_terms'), value: RIGHT_CLEAR_FILTER.CUSTOM_TERMS },
	];

	const listPublished: Array<OptionTypeItem> = [
		{ name: t('filter.filter_content.any'), value: FILTER_PUBLISHED.ANY },
		{ name: t('filter.filter_content.yes'), value: FILTER_PUBLISHED.YES },
		{ name: t('filter.filter_content.no'), value: FILTER_PUBLISHED.NO },
	];

	const listExported: Array<OptionTypeItem> = [
		{ name: t('filter.filter_content.any'), value: FILTER_PUBLISHED.ANY },
		{ name: t('filter.filter_content.yes'), value: FILTER_PUBLISHED.YES },
		{ name: t('filter.filter_content.no'), value: FILTER_PUBLISHED.NO },
	];

	const listOptionFilterTag: OptionFilterTag[] = [
		{
			nameGroup: NAME_FILTER_CONTENT.LABELS,
			options: renderListOption(labels || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.labels'),
		},
		{
			nameGroup: NAME_FILTER_CONTENT.FLAGS,
			options: renderListOption(flags || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.flags'),
		},
		{
			nameGroup: NAME_FILTER_CONTENT.GOOGLE_AI_TAGS,
			options: renderListOption(googleAIHashtags || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.google_AI_tags'),
		},
	];

	const listFormItem = [
		{
			name: NAME_FILTER_CONTENT.QUERY_OPERATOR,
			label: t('filter.filter_content.query_type'),
			content: renderRadioGroup(listQueryOperator, true),
		},
		{
			name: NAME_FILTER_CONTENT.MEDIA_TYPE,
			label: t('filter.filter_content.file_type'),
			content: renderRadioGroup(listType),
		},
		{
			name: NAME_FILTER_CONTENT.RANGE_RATING,
			label: t('filter.filter_content.star_rating'),
			content: renderRating(),
		},
		{
			name: NAME_FILTER_CONTENT.NOT_RATE,
			label: null,
			content: renderNotRate(),
			otherProps: { valuePropName: 'checked' },
		},
		{
			name: NAME_FILTER_CONTENT.TAGS,
			label: t('filter.filter_content.include_content_tags'),
			content: renderSelectGroup(NAME_FILTER_CONTENT.TAGS),
		},
		{
			name: NAME_FILTER_CONTENT.EXCLUDED_TAGS,
			label: t('filter.filter_content.excluded_content_tags'),
			content: renderSelectGroup(NAME_FILTER_CONTENT.EXCLUDED_TAGS),
		},
		{
			name: NAME_FILTER_CONTENT.STATUS,
			label: t('filter.filter_content.status'),
			content: renderRadioGroup(listStatus),
		},
		{
			name: NAME_FILTER_CONTENT.RIGHT_CLEAR,
			label: t('filter.filter_content.right_clear'),
			content: renderCheckboxGroup(listRightClear),
		},
		{
			name: NAME_FILTER_CONTENT.PUBLISHED,
			label: t('filter.filter_content.published'),
			content: renderRadioGroup(listPublished),
		},
		{
			name: NAME_FILTER_CONTENT.DOWNLOADED,
			label: t('filter.filter_content.exported'),
			content: renderRadioGroup(listExported),
		},
		{
			name: NAME_FILTER_CONTENT.GALLERIES,
			label: t('filter.filter_content.galleries'),
			content: renderSelect(galleryList, NAME_FILTER_CONTENT.GALLERIES),
		},
		{
			name: NAME_FILTER_CONTENT.CAMPAIGNS,
			label: t('filter.filter_content.campaigns'),
			content: renderSelect(campaignListContent || [], NAME_FILTER_CONTENT.CAMPAIGNS),
		},
	];

	const listFormAdvanced = [
		{
			name: NAME_FILTER_CONTENT.CONTENT_SOURCES,
			label: t('filter.filter_content.content_source'),
			content: renderSelect(renderListContentSources(t), NAME_FILTER_CONTENT.CONTENT_SOURCES),
		},
		{
			name: NAME_FILTER_CONTENT.UPLOAD_DATE,
			label: t('filter.filter_content.uploaded_between'),
			content: renderSelectRangeDate(),
		},
		{
			name: NAME_FILTER_CONTENT.SHOT_LOCATION,
			label: t('content_detail.shot_location'),
			content: renderSelect(shotCountriesList, NAME_FILTER_CONTENT.SHOT_LOCATION),
		},
		{
			name: NAME_FILTER_CONTENT.UPLOAD_LOCATION,
			label: t('filter.filter_content.upload_location'),
			content: renderSelectGroup(NAME_FILTER_CONTENT.UPLOAD_LOCATION),
		},
		{
			name: NAME_FILTER_CONTENT.LOGOS_DETECTED,
			label: t('filter.filter_content.logos_detected'),
			content: renderSelect(
				detectionServiceLabelList?.logoImageLabels || [],
				NAME_FILTER_CONTENT.LOGOS_DETECTED,
			),
		},
		{
			name: NAME_FILTER_CONTENT.VIDEO_LABEL_DETECTED,
			label: t('filter.filter_content.video_label_detected'),
			content: renderSelect(
				detectionServiceLabelList?.videoLabels || [],
				NAME_FILTER_CONTENT.VIDEO_LABEL_DETECTED,
			),
		},
		{
			name: NAME_FILTER_CONTENT.ASPECT_RATIOS,
			label: t('filter.filter_content.aspect_ratio'),
			content: renderSelect(ratios, NAME_FILTER_CONTENT.ASPECT_RATIOS),
		},
		{
			name: NAME_FILTER_CONTENT.RESOLUTION_QUALITY,
			label: t('filter.filter_content.img_video_resolution'),
			content: renderSelect(listResolution, NAME_FILTER_CONTENT.RESOLUTION_QUALITY),
		},

		{
			name: NAME_FILTER_CONTENT.DEVICE_MAKES,
			label: t('content_detail.device_make'),
			content: renderSelect(deviceMakeList, NAME_FILTER_CONTENT.DEVICE_MAKES),
		},
		{
			name: NAME_FILTER_CONTENT.DEVICE_MODELS,
			label: t('content_detail.device_model'),
			content: renderSelect(deviceModelList, NAME_FILTER_CONTENT.DEVICE_MODELS),
		},
		{
			name: NAME_FILTER_CONTENT.KEYWORD,
			label: t('filter.filter_content.keyword'),
			content: renderInput(NAME_FILTER_CONTENT.KEYWORD),
		},
		{
			name: NAME_FILTER_CONTENT.CREATORS,
			label: t('filter.filter_content.creators'),
			content: renderInput(NAME_FILTER_CONTENT.CREATORS),
		},
		{
			name: NAME_FILTER_CONTENT.CREATOR_SEGMENT,
			label: t('filter.filter_content.creator_segment'),
			content: renderSelect(segmentList || [], NAME_FILTER_CONTENT.CREATOR_SEGMENT),
		},
		{
			name: NAME_FILTER_CONTENT.ASSOCIATED_REWARDS,
			label: t('filter.filter_content.associated_rewards'),
			content: renderSelect([], NAME_FILTER_CONTENT.ASSOCIATED_REWARDS),
		},
	];

	const handleChangeFilterSimilar = () => {
		setIsFindSimilar(!isFindSimilar);
	};

	return (
		<>
			{listFormItem?.map((item) => {
				const elm = (
					<StyledFormItem
						style={
							item?.name === NAME_FILTER_CONTENT.TAGS && isEReviewer
								? { width: 'calc(100% - 32px)' }
								: {}
						}
						key={item?.name}
						label={item?.label ? <StyledLabel>{item?.label}</StyledLabel> : null}
						name={item?.name}
						{...item?.otherProps}>
						{item?.content}
					</StyledFormItem>
				);
				if (item?.name === NAME_FILTER_CONTENT.TAGS) {
					return (
						<StyledWrapperContent position="relative" key={item.name}>
							<StyledTitle
								fontSize="16px"
								margin="0 0 12px 0"
								color={THEME.colors.white}
								className="unset_height">
								{t('filter.filter_content.content_tags')}
							</StyledTitle>
							{elm}
							{isEReviewer && (
								<Tooltip
									title={
										isFindSimilar
											? t('content_management.sub_search_similar_on')
											: t('content_management.sub_search_similar_off')
									}>
									{isFindSimilar ? (
										<StyledIcon
											className="absolute"
											right="0"
											bottom="6px"
											margin="0 0 0 8px"
											size={24}
											onClick={() => handleChangeFilterSimilar()}>
											<IconLightBulb />
										</StyledIcon>
									) : (
										<StyledIcon
											margin="0 0 0 8px"
											fill="none"
											right="0"
											bottom="6px"
											className="hover_level_path absolute"
											colorStrokeHover={THEME.colors.orangeBase}
											onClick={() => handleChangeFilterSimilar()}>
											<IconLightBulbBasic />
										</StyledIcon>
									)}
								</Tooltip>
							)}
						</StyledWrapperContent>
					);
				}
				return elm;
			})}
			<StyledDividerLine margin="24px 0 14px 0" />
			<StyledTitleAdvanced
				onClick={() => setShowAdvanced(!showAdvanced)}
				className={showAdvanced ? 'active' : ''}>
				{renderHeaderPanel()}
			</StyledTitleAdvanced>
			<StyledWrapperAdvanced className={showAdvanced ? '' : 'hidden'}>
				<CustomFieldFilter
					fieldsEdit={filterSelected?.customFields || undefined}
					form={form}
					hasFilter={!!filterSelected}
				/>
				{listFormAdvanced?.map((item) => (
					<StyledFormItem
						key={item?.name}
						label={<StyledLabel>{item?.label}</StyledLabel>}
						name={item?.name}>
						{item?.content}
					</StyledFormItem>
				))}
			</StyledWrapperAdvanced>
		</>
	);
};

export default DetailFilter;
