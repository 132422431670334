import { GUEST_PORTAL_MODAL_TYPE } from '@constants/settings/guestPortal';
import { GuestPortalItemDto } from '@models/settings/guestPortal';
import {
	StyledMutableStatusButton,
	StyledStatusDot,
	StyledText,
} from '@styled/Common/CommonStyled';
import React from 'react';

type GuestPortalStatusProps = {
	guestPortalDetail: GuestPortalItemDto;
	styles?: any;
	setConfirmationModalType?: (type: string) => void;
	setGuestPortalDetail?: (guestPortal: GuestPortalItemDto) => void;
};

const GuestPortalStatus = (props: GuestPortalStatusProps) => {
	const { guestPortalDetail, styles, setConfirmationModalType, setGuestPortalDetail } = props;

	const switchGuestPortalStatus = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();
		if (setConfirmationModalType) {
			if (setGuestPortalDetail) {
				setGuestPortalDetail(guestPortalDetail);
			}
			setConfirmationModalType(GUEST_PORTAL_MODAL_TYPE.SWITCH_STATUS);
		}
	};

	return (
		<StyledMutableStatusButton
			onClick={switchGuestPortalStatus}
			status={guestPortalDetail.status}
			{...styles}>
			<StyledStatusDot status={guestPortalDetail.status} margin="0 8px 0 -2px" />
			<StyledText textTransform="capitalize">
				{guestPortalDetail.status?.toLowerCase()}
			</StyledText>
		</StyledMutableStatusButton>
	);
};

export default GuestPortalStatus;
