import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import { emailTemplate } from '@constants/messages';
import PreviewPanel from '../PreviewPanel';

const ArchivedTemplates: React.FC = () => {
	const {
		emailTemplate: {
			archived: { selectArchivedEmailTemplate = 0, templates: archivedTemplates = [] } = {},
		} = {},
	} = useSelector((state: any) => state.messages);
	const [template, setTemplate] = useState<EmailTemplatesDataDto>(emailTemplate);

	useEffect(() => {
		if (archivedTemplates.length) {
			getEmailTemplateBody();
		}
	}, [archivedTemplates]);

	useEffect(() => {
		if (selectArchivedEmailTemplate) {
			getEmailTemplateBody();
		}
	}, [selectArchivedEmailTemplate]);

	const getEmailTemplateBody = () => {
		const findTemplate = archivedTemplates.find(
			(item: EmailTemplatesDataDto) => item.id === selectArchivedEmailTemplate,
		);

		const setTL = selectArchivedEmailTemplate === 0 ? archivedTemplates[0] : findTemplate;

		setTemplate(setTL);
	};

	const renderTemplate = () => {
		return { __html: template?.body };
	};

	if (template.id === 0) return null;

	return (
		<PreviewPanel template={template}>
			<div dangerouslySetInnerHTML={renderTemplate()} />
		</PreviewPanel>
	);
};

export default ArchivedTemplates;
