import { IconCancel } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { ActionNoClickTypes, ActionsTypes } from '@models/content/albums/albumManager';
import { StyledFilterContainer, StyledText } from '@styled/Campaign/CampaignStyled';
import {
	StyledDropdown,
	StyledIcon,
	StyledMenuItem,
	StyledSelect,
	StyledSubMenu,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { Menu, Select } from 'antd';
import { isEqual } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type FilterSelectTypes = {
	value: string | number;
	name: string | number;
	group?: string;
};

export type GroupOption = {
	title: string;
	group: string;
	options: FilterSelectTypes[];
};

export type ValueSelectIdTypes = string | number | null | undefined;

type PropTypes = {
	selectedId?: ValueSelectIdTypes;
	onChange?: (val: ValueSelectIdTypes, groupId?: ValueSelectIdTypes) => void;
	subApplyFilter?: string;
	suffixFilter?: ReactNode;
	width?: string;
	maxWidth?: string;
	maxWidthText?: string;
	actions: ActionNoClickTypes[];
	buttonNode: ReactNode;
	disabled?: boolean;
	[other: string]: any;
};

const FilterDropdownSelect = (props: PropTypes) => {
	const {
		selectedId = null,
		subApplyFilter = '',
		suffixFilter = '',
		width = '230px',
		maxWidth = '',
		maxWidthText = '',
		onChange: onChangeCallback,
		actions,
		buttonNode,
		disabled = false,
		...other
	} = props;
	const { t } = useTranslation();
	const [filterId, setFilterIdSelect] = useState<ValueSelectIdTypes>(selectedId);

	const formatDataOption = (list: ActionNoClickTypes[], groupId?: ValueSelectIdTypes) => {
		let result: ActionNoClickTypes[] = [];
		list.forEach((action: ActionNoClickTypes) => {
			if (action.children) {
				if (action.children.length > 0) {
					result = result.concat(formatDataOption(action.children, action.groupId));
				}
			} else {
				result.push({ ...action, groupId });
			}
		});
		return result;
	};

	const getFilterSelect = () => {
		if (filterId) {
			const itemFind = formatDataOption(actions).find((item) => item?.id === filterId);

			if (itemFind) {
				return { name: itemFind.name, value: itemFind.id, group: itemFind.groupId };
			}
			return { name: '', value: '', group: '' };
		}
		return null;
	};

	useEffect(() => {
		if (!selectedId) {
			setFilterIdSelect(null);
		} else {
			if (typeof selectedId === 'number' || typeof selectedId === 'string') {
				if (selectedId !== filterId) {
					setFilterIdSelect(selectedId);
				}
			}
		}
	}, [selectedId]);

	const handleCheckCallback = (
		val: ActionNoClickTypes | null | undefined,
		groupId?: ValueSelectIdTypes,
	) => {
		if (onChangeCallback && typeof onChangeCallback === 'function') {
			if (val) {
				onChangeCallback(val?.id, groupId);
			} else {
				onChangeCallback(val);
			}
		}
	};

	const onChange = (action: ActionNoClickTypes, groupId?: ValueSelectIdTypes) => {
		const result = formatDataOption(actions).find((item) => item?.id === action.id);
		setFilterIdSelect(result?.id);
		handleCheckCallback(result, groupId);
	};

	const cancelFilter = () => {
		setFilterIdSelect(null);
		handleCheckCallback(null);
	};

	const menuActionMore = (
		<Menu>
			{actions.map((item: ActionNoClickTypes) => {
				if (item.hidden) {
					return null;
				}
				const menuItem = (action: ActionNoClickTypes) => {
					return (
						<StyledMenuItem
							className="overlay"
							key={action.id}
							disabled={action?.disabled}
							onClick={(e: any) => {
								onChange(action, item.groupId || item.id);
							}}>
							{action.name}
						</StyledMenuItem>
					);
				};

				if (item?.children) {
					return (
						<StyledSubMenu
							key={item.id}
							popupClassName={`sub_menu_item custom_scroll_bar ${
								item.children.length === 0 ? 'p_0' : ''
							}`}
							popupOffset={[0, 0]}
							className="hidden_icon sub_menu_custom"
							disabled={item.disabled}
							title={item.name}>
							{item?.children.map((ch) => menuItem(ch))}
						</StyledSubMenu>
					);
				}
				return menuItem(item);
			})}
		</Menu>
	);

	return (
		<>
			{filterId ? (
				<StyledFilterContainer>
					<StyledText maxWidth={maxWidthText}>
						{subApplyFilter}“{getFilterSelect()?.name}
					</StyledText>
					”{suffixFilter || ''}
					<StyledIcon margin="0 0 0 5px" size={16} fill={THEME.colors.white}>
						<IconCancel onClick={cancelFilter} />
					</StyledIcon>
				</StyledFilterContainer>
			) : (
				<StyledDropdown
					className={`dropdown_action ${disabled ? 'disabled_action' : ''}`}
					getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
					placement="bottomLeft"
					overlay={menuActionMore}
					disabled={disabled}
					trigger={['click']}
					{...other}>
					<StyledWrapperContent
						cursor="pointer"
						display="inline-flex"
						alignItems="center">
						{buttonNode}
					</StyledWrapperContent>
				</StyledDropdown>
			)}
		</>
	);
};

export default FilterDropdownSelect;
