import styled from 'styled-components';
import THEME from '@constants/themes/themes';
import { Button, Input, InputNumber, DatePicker, Select, Form } from 'antd';
import { WIDTH_SELECT } from '@constants/creator';

const { RangePicker } = DatePicker;

const StyledSelect = styled(Select)`
	width: ${WIDTH_SELECT}px;
`;

const StyledButtonText = styled(Button)`
	width: 125px;
`;

const StyledDatePicker = styled(DatePicker)`
	width: ${WIDTH_SELECT}px;
	background-color: ${THEME.colors.darkBlue2};
	height: 32px;
`;

const StyledRangePicker = styled(RangePicker)`
	width: ${WIDTH_SELECT}px;
	background-color: ${THEME.colors.darkBlue2};
	height: 32px;
	padding: 0 8px 0;
`;

const StyledInputNumber = styled(InputNumber)<{ width?: number }>`
	width: ${({ width }) => width || WIDTH_SELECT}px;
	border: 1px solid #344552;
`;

const StyledInput = styled(Input)<{ width?: number }>`
	width: ${({ width }) => width || WIDTH_SELECT}px;
	height: 32px;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
	}

	&.ant-input-disabled {
		background-color: ${THEME.colors.darkBlue2};
	}
`;

const StyledWrapperContactMatch = styled.div`
	position: relative;
`;
const StyledSpan = styled.span`
	position: absolute;
	left: 300px;
	top: 4px;
`;

const StyleIconRemove = styled.span`
	vertical-align: -0.3rem;
`;

const StyledFromItem = styled(Form.Item)`
	&.form_input_range {
		.ant-form-item-explain {
			width: ${WIDTH_SELECT / 2 - 15}px;
		}
	}
`;

export {
	StyledButtonText,
	StyledDatePicker,
	StyledRangePicker,
	StyledInputNumber,
	StyledInput,
	StyledSpan,
	StyleIconRemove,
	StyledSelect,
	StyledWrapperContactMatch,
	StyledFromItem,
};
