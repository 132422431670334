/**
 * @prettier
 */
import { Col, Row, Skeleton } from 'antd';
import * as React from 'react';
import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import Content from '@components/Creators/Profile/Content';
import Metadata from '@components/Creators/Profile/Metadata';
import MetricCards from '@components/Creators/Profile/MetricCards';
import Notes from '@components/Creators/Profile/Notes';

// Styled
import CompletedActions from '@components/Creators/Profile/CompletedActions';
import CreatorDetails from '@components/Creators/Profile/CreatorDetails';
import Recommendations from '@components/Creators/Profile/Recommendations';
import SocialStats from '@components/Creators/Profile/SocialStats';
import CreatorProfileContext from '@contexts/Creators/Profile';

import CampaignRecommendations from '@components/Creators/Profile/CampaignRecommendations';
import LoadingWrapper from '@cores/LoadingWrapper';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import MessageHistory from '@components/Creators/Profile/MessageHistory';
import { CREATOR_PROFILE_MODAL_TYPE, DEFAULT_HISTORY, ID_ELM_CREATOR } from '@constants/creator';
import MailHistoryDrawer from '@components/Creators/Profile/MailHistoryDrawer';
import { MailMessageCreatorType } from '@models/creator/summary';
import ManageRewards from '@components/Creators/Profile/ManageRewards';

type CreatorProfileProps = {
	computedMatch: any;
	t: any;
	creator: any;
	getProfile: (creatorId: number) => void;
	creatorBlock: (payload: any) => void;
	getCampaignList: () => void;
	storeCreatorData: (payload: any) => void;
};

type CreatorProfileStates = {
	modalType: string;
	focusMessageHistory: boolean;
	collapseMessage: boolean;
	mailHistory: MailMessageCreatorType | null;
	messageHistoryCreator: MailMessageCreatorType[];
};

const StyledRow = styled(Row)``;

class Profile extends PureComponent<CreatorProfileProps, CreatorProfileStates> {
	constructor(props: CreatorProfileProps | Readonly<CreatorProfileProps>) {
		super(props);

		this.state = {
			modalType: '',
			focusMessageHistory: false,
			collapseMessage: true,
			mailHistory: null,
			messageHistoryCreator: [],
		};
	}

	componentDidMount = () => {
		this.getProfileDetails();
		this.props.getCampaignList();
	};

	componentDidUpdate(
		prevProps: Readonly<CreatorProfileProps>,
		prevState: Readonly<CreatorProfileStates>,
		snapshot?: any,
	): void {
		const {
			getProfile,
			computedMatch: {
				params: { creatorId },
			},
		} = this.props;
		if (creatorId !== prevProps?.computedMatch?.params?.creatorId) {
			getProfile(creatorId);
			this.onClose();
		}
	}

	componentWillUnmount = () => {
		this.props.storeCreatorData({
			creatorProfile: null,
			completedActions: null,
			historyCreator: DEFAULT_HISTORY,
		});
	};

	getProfileDetails = () => {
		const {
			getProfile,
			computedMatch: {
				params: { creatorId },
			},
		} = this.props;

		getProfile(creatorId);
	};

	handleChangeBlockStatus = (payload: any) => {
		const { creatorBlock } = this.props;
		creatorBlock(payload);
	};

	contextValues = () => {
		const {
			modalType,
			focusMessageHistory,
			collapseMessage,
			mailHistory,
			messageHistoryCreator,
		} = this.state;

		return {
			modalType,
			setModalType: (type: string) => {
				this.setState({
					modalType: type,
				});
			},
			focusMessageHistory,
			setFocusHistory: (val: boolean) => {
				this.setState({ focusMessageHistory: val });
			},
			collapseMessage,
			setCollapseMessage: (val: boolean) => {
				this.setState({ collapseMessage: val });
			},
			mailHistory,
			setMailHistory: (val: MailMessageCreatorType | null) => {
				this.setState({ mailHistory: val });
			},
			messageHistoryCreator,
			setMessageHistoryCreator: (val: MailMessageCreatorType[]) => {
				this.setState({ messageHistoryCreator: val });
			},
		};
	};

	onClose = () => {
		/* eslint-disable */
		if (this.state.modalType) {
			this.setState({
				modalType: '',
			});
		}
		/* eslint-enable */
	};

	render(): React.ReactNode {
		const {
			t,
			computedMatch: {
				params: { creatorId },
			},
			creator: { creatorProfile },
		} = this.props;

		const { modalType, mailHistory } = this.state;

		return (
			<CreatorProfileContext.Provider value={this.contextValues()}>
				<LoadingWrapper isLoading={false}>
					<StyledRow gutter={16} className="main-content creator-profile-content">
						<Col span={12}>
							{creatorProfile ? (
								<>
									<Metadata onChangeBlockStatus={this.handleChangeBlockStatus} />
									<StyledWrapperContent
										height="calc(100vh - 230px)"
										width="100%"
										overflow="auto"
										id={ID_ELM_CREATOR.ID_CREATOR_SCROLL}
										className="custom_scroll_bar">
										<Recommendations />
										<SocialStats creatorId={creatorId} />
										<CompletedActions creatorId={creatorId} />
										<MessageHistory creatorId={creatorId} />
										<MetricCards />
										<Notes />
									</StyledWrapperContent>
								</>
							) : (
								<>
									<Skeleton active />
								</>
							)}
						</Col>

						<Col span={12} style={{ position: 'relative' }}>
							<Content creatorId={creatorId} />
							{modalType === CREATOR_PROFILE_MODAL_TYPE.CAMPAIGN_RECOMMENDATIONS && (
								<CampaignRecommendations
									visible={
										modalType ===
										CREATOR_PROFILE_MODAL_TYPE.CAMPAIGN_RECOMMENDATIONS
									}
									onClose={this.onClose}
								/>
							)}
							{creatorProfile && (
								<>
									{modalType === CREATOR_PROFILE_MODAL_TYPE.VIEW_DETAILS && (
										<CreatorDetails
											visible={
												modalType ===
												CREATOR_PROFILE_MODAL_TYPE.VIEW_DETAILS
											}
											onClose={this.onClose}
										/>
									)}
									{mailHistory &&
										modalType ===
											CREATOR_PROFILE_MODAL_TYPE.VIEW_MESSAGE_HISTORY_DETAIL && (
											<MailHistoryDrawer
												visible={
													modalType ===
													CREATOR_PROFILE_MODAL_TYPE.VIEW_MESSAGE_HISTORY_DETAIL
												}
												mailInfo={mailHistory}
												onClose={() =>
													this.setState({
														mailHistory: null,
														modalType: '',
													})
												}
											/>
										)}
									{modalType === CREATOR_PROFILE_MODAL_TYPE.MANAGE_REWARDS && (
										<ManageRewards
											visible={
												modalType ===
												CREATOR_PROFILE_MODAL_TYPE.MANAGE_REWARDS
											}
											onClose={this.onClose}
										/>
									)}
								</>
							)}
						</Col>
					</StyledRow>
				</LoadingWrapper>
			</CreatorProfileContext.Provider>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		getProfile: (creatorId: number) =>
			dispatch({ type: 'CREATOR_GET_PROFILE', payload: { creatorId } }),
		creatorBlock: (payload: any) =>
			dispatch({
				type: 'CREATOR_BLOCK',
				payload: payload,
			}),
		getCampaignList: () => dispatch({ type: 'GET_LIST_CONTENT_CAMPAIGN' }),
		storeCreatorData: (payload: any) =>
			dispatch({
				type: 'STORE_CREATOR_DATA',
				payload: payload,
			}),
	};
};

const mapStateToProps = (state: any) => ({
	filter: state.header,
	creator: state.creator,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Profile));
