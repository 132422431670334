import { ValueSelectIdTypes } from '@components/Filter/FilterDropdownSelect';
import { FilterSelectTypes } from '@components/Filter/FilterSelect';
import { SORT_BY } from '@constants/ambassador';
import React from 'react';

export type AmbassadorDashboardManagerContextType = {
	fullScreenSocial: boolean;
	setFullScreenSocial: (val: boolean) => void;
	setSortByLeaderBoard: (val: string) => void;
	sortByLeaderBoard: string;
	setShowRecentBox: (val: boolean) => void;
	showRecentBox: boolean;
	setCampaignSelectValue: (val: ValueSelectIdTypes) => void;
	campaignSelectValue: ValueSelectIdTypes;
	campaignIdSelected: number | null;
	segmentSelectValue: ValueSelectIdTypes;
	setSegmentSelectValue: (val: ValueSelectIdTypes) => void;
	segmentIdSelected: number | null;
	fieldDisabled: string[];
	setFieldDisable: (val: string[]) => void;
};

// Default context values
const values: AmbassadorDashboardManagerContextType = {
	fullScreenSocial: false,
	setFullScreenSocial: () => {},
	setSortByLeaderBoard: () => {},
	sortByLeaderBoard: SORT_BY.TASKS,
	setShowRecentBox: () => {},
	showRecentBox: false,
	setCampaignSelectValue: () => {},
	campaignSelectValue: null,
	campaignIdSelected: null,
	segmentSelectValue: null,
	setSegmentSelectValue: () => {},
	segmentIdSelected: null,
	fieldDisabled: [],
	setFieldDisable: () => {},
};

const AmbassadorDashboardManagerContext = React.createContext(values);
export default AmbassadorDashboardManagerContext;
