/**
 * @prettier
 */

import { Action } from 'redux-actions';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
	API_CONTENT_EMOJI,
	API_RATIO_RESOLUTION,
	API_TOTAL_CONTENT,
	API_UPDATE_SETTING,
	API_VERSION_CONTENT,
	CONTACTED_CONTENT,
	CONTENT_API_URL,
	CONTENT_LOCATION,
	CONTENT_STATUS_RECENTLY_ADDED,
	CONTENT_TAGS,
	CONTENT_VERIFICATION_SCORE,
	DELETE_CONTENT,
	DETECTION_SERVICE_LIST,
	ESTIMATE_COST,
	FETCH_CONTENT,
	FILTER_CONTENT,
	MEDIA_DETECTION,
	MARK_FAVORITE_CONTENT,
	MOVE_CONTENT,
	MULTIPLE_REVIEW_CONTENT,
	PUBLISH_LOG,
	REJECT_CONTENT,
	REVIEW_CONTENT,
	STATUSCODE,
	TERMS_CONTENT,
	DETECTION_LABEL_LIST,
	CONTENT_MEDIA_API,
	FETCH_CONTENT_DATA,
	API_ROTATE_IMAGE,
	GENERATE_PDF_TERMS_CONDITION,
	API_SAVE_IMG_ORIENTATION,
	API_DEVICE_MAKE,
	API_DEVICE_MODEL,
	API_SHOT_LOCATION,
	CONTENT_CUSTOM_FIELD_API,
} from '@constants/APIs';
import { FILTER_BY, SORT_BY } from '@constants/content/contentLibrary';
import { NONE } from '@constants/status';
import genericApiSaga from '@helpers/genericApiSaga';
import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import { getUser } from '@helpers/userHelpers';
import { ResponsePayloadDto } from '@models/common/store';
import {
	DetectionOrderPayloadDto,
	EstimatedCostPayloadDto,
	GetContentPayloadDto,
	GetContentVerificationScorePayloadDto,
	GetFilterPayloadDto,
	MoveContentPayloadDto,
	ReviewContentPayloadDto,
} from '@models/content/contentLibrary/store';
import {
	BodyPublishLogDto,
	DataRequestTermsContentTypes,
	ParamCreatePublishLogDto,
	ParamPublishLogDto,
	ParamUploadVersionType,
} from '@models/content/contentLibrary/summary';
import {
	contentEmojiTypes,
	contentImgVersionTypes,
	contentTotalTypes,
	contentTypes,
	countDownloadContentTypes,
	createImgVersionTypes,
	createPublishLogTypes,
	deleteContentTypes,
	deleteFilterTypes,
	deleteMultiContentsTypes,
	estimateCostTypes,
	getMediaDetectionTypes,
	getContentLocationTypes,
	getContentTagsTypes,
	getContentUnviewedStatusRecentlyAddedType,
	getDetectionServiceListTypes,
	getFilterTypes,
	getPublishLogTypes,
	getTermsConditionsTypes,
	getTermsContentTypes,
	getVerificationScroreTypes,
	markFavoriteContentTypes,
	moveContentTypes,
	ratioAndResolutionTypes,
	rejectContentTypes,
	removePublishLogTypes,
	reviewContentTypes,
	reviewMultiContentsTypes,
	saveFilterTypes,
	submitDetectionOrderTypes,
	updateContactedContentTypes,
	updateContentStatusRecentlyAddedType,
	updateEmojiTypes,
	updateFilterContentDetailTypes,
	updateAccountSettingTypes,
	updateImgVersionTypes,
	updatePublishLogTypes,
	getDetectionLabelListTypes,
	contentDetailTypes,
	fetchContentDataTypes,
	rotateImgTypes,
	fetchDeviceMakeTypes,
	fetchDeviceModelTypes,
	fetchShotCountriesTypes,
	getContentCustomFieldsTypes,
} from '@stores/content/contentLibrary/content.types';
import { convertParamSearch } from '@utils/common';
import * as actions from './content.actions';
import { formatPayload } from '@utils/funcHelper';
import { KeyValueSortColumnType } from '@models/creator/summary';
import { AccountSettingsRequest } from '@models/user/user';

export function* fetchContent({ payload }: any) {
	const {
		folder,
		page,
		maxRecords,
		creatorId,
		rating,
		contacted,
		published,
		gallery,
		verified,
		needReview,
		labels,
		flags,
		sortBy,
		search,
		mediaType,
		galleries,
		campaigns,
		creatorSegments,
		contentSources,
		createdAtOlderThan,
		contentIds,
		...otherParams
	} = payload;

	let params: GetContentPayloadDto = {
		folder,
		maxRecords,
		page,
		sortBy: SORT_BY.IMPORT_DATE.value, // default
		...otherParams,
	};

	if ('sortBy' in payload && sortBy !== SORT_BY.IMPORT_DATE.value) {
		params = { ...params, sortBy };
	}

	if ('search' in payload) {
		params = { ...params, search };
	}

	if ('creatorId' in payload) {
		params = { ...params, creatorId: Number(creatorId) };
	}

	if ('rating' in payload) {
		params = { ...params, rating: rating };
	}

	if ('contacted' in payload && contacted !== null) {
		params = { ...params, contacted };
	}

	if ('published' in payload && published !== null) {
		params = { ...params, published };
	}

	if ('gallery' in payload && gallery !== null) {
		params = { ...params, gallery };
	}

	if ('verified' in payload && verified !== null) {
		params = { ...params, verified };
	}

	if ('needReview' in payload && needReview !== null) {
		params = { ...params, needReview };
	}

	if (labels && labels.length) {
		params = { ...params, labels };
	}

	if (galleries && galleries.length) {
		params = { ...params, galleries: galleries || [] };
	}

	if (flags && flags.length) {
		params = { ...params, flags };
	}

	if (campaigns && campaigns.length) {
		params = { ...params, campaigns };
	}

	if (creatorSegments && creatorSegments.length) {
		params = { ...params, creatorSegments };
	}

	if ('mediaType' in payload && mediaType !== FILTER_BY.ALL.value) {
		params = { ...params, mediaType };
	}
	if (contentSources && contentSources?.length > 0) {
		params = { ...params, contentSources };
	}
	if (createdAtOlderThan) {
		params = { ...params, createdAtOlderThan };
	}

	if (contentIds && contentIds.length > 0) {
		params = { ...params, contentIds };
	}

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${FETCH_CONTENT}`, params),
		*completed(response: any) {
			yield put(actions.fetchContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchContentFailed(response));
		},
	});
}

function* fetchContentData({ payload }: Action<GetContentPayloadDto>) {
	const params: GetContentPayloadDto = formatPayload(payload);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${FETCH_CONTENT_DATA}`, params),
		*completed(response: any) {
			yield put(actions.fetchContentDataSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchContentDataFailed(response));
		},
	});
}

export function* fetchTotalContent({ payload }: Action<{ folderId: string; params?: string[] }>) {
	yield genericApiSaga({
		gatewayCall: () =>
			putRequest(`${API_TOTAL_CONTENT}?folderId=${payload?.folderId}`, payload?.params),
		*completed(response: any) {
			yield put(actions.fetchTotalContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchTotalContentFailed(response));
		},
	});
}

export function* rejectContent({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(REJECT_CONTENT, payload),
		*completed(response: any) {
			yield put(actions.rejectContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.rejectContentFailed(response));
		},
	});
}

export function* moveContent({ payload }: Action<MoveContentPayloadDto>) {
	const { folder, contents, unreject, moveContentSucceeded, onFailed } = payload;

	let unrejectTemp: boolean = false;

	if ('unreject' in payload && unreject) {
		unrejectTemp = true;
	}

	yield genericApiSaga({
		gatewayCall: () =>
			putRequest(`${MOVE_CONTENT}?unreject=${unrejectTemp}&folder=${folder}`, contents),
		*completed(response: any) {
			if (moveContentSucceeded) {
				const { result: movedContent = [] } = response.data;
				moveContentSucceeded(unrejectTemp, movedContent);
			}
			yield put(actions.moveContentSucceeded(response));
		},
		*failed(response: any) {
			if (onFailed) {
				const { status = {} } = response.data;
				let { error = '' } = response.data;

				error = error === null ? `${status.code} ${status.status}` : error;
				onFailed(error);
			}
			yield put(actions.moveContentFailed(response));
		},
	});
}

export function* deleteContent({ payload: { contentId } }: any) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${DELETE_CONTENT}/${contentId}`),
		*completed(response: any) {
			yield put(actions.deleteContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteContentFailed(response));
		},
	});
}

export function* deleteMultiContents({ payload: { contents } }: any) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${DELETE_CONTENT}`, contents),
		*completed(response: any) {
			yield put(actions.deleteMultiContentsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteMultiContentsFailed(response));
		},
	});
}

export function* reviewContent({ payload }: Action<ReviewContentPayloadDto>) {
	const { id, params, reviewContentSucceeded, onFailed } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${REVIEW_CONTENT}/${id}`, params),
		*completed(response: any) {
			if (reviewContentSucceeded) {
				const {
					status: { code = NONE },
				} = response.data;

				if (code === STATUSCODE.SUCCESS) {
					reviewContentSucceeded(params);
				}
			}

			yield put(actions.reviewContentSucceeded(response));
		},
		*failed(response: any) {
			if (onFailed) {
				if (response) {
					const { status = {} } = response.data;
					let { error = '' } = response.data;

					error = error === null ? `${status.code} ${status.status}` : error;
					onFailed(error);
				}
			}
			yield put(actions.reviewContentFailed(response));
		},
	});
}

export function* reviewMultiContents({ payload }: Action<any>) {
	const { params, reviewSucceeded } = payload;
	let requestURL: string = `${MULTIPLE_REVIEW_CONTENT}`;

	if ('isRemove' in params) {
		requestURL += `?isRemove=${params.isRemove}`;
		delete params.isRemove;
	}

	yield genericApiSaga({
		gatewayCall: () => putRequest(requestURL, params),
		*completed(response: any) {
			if ('reviewSucceeded' in payload) {
				if (response) {
					const {
						status: { code = 0 },
						result,
					} = response.data;

					// TODO, will be update once we have offical response data format from BE
					if (code === STATUSCODE.SUCCESS) {
						reviewSucceeded(result);
					}
				}
			} else {
				yield put(actions.reviewMultiContentsSucceeded(response));
			}
		},
		*failed(response: any) {
			yield put(actions.reviewMultiContentsFailed(response));
		},
	});
}

export function* getFilter({ payload }: Action<GetFilterPayloadDto>) {
	const { page, maxRecords } = payload;

	yield genericApiSaga({
		gatewayCall: () =>
			getRequest(`${FILTER_CONTENT}?page=${page}&maxRecords=${maxRecords}&sortBy=id`),
		*completed(response: any) {
			yield put(actions.getFilterSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getFilterFailed(response));
		},
	});
}

export function* saveFilter({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${FILTER_CONTENT}`, payload),
		*completed(response: any) {
			yield put(actions.saveFilterSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.saveFilterFailed(response));
		},
	});
}

export function* deleteFilter({ payload }: Action<number[]>) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${FILTER_CONTENT}`, payload),
		*completed(response: any) {
			yield put(actions.deleteFilterSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteFilterFailed(response));
		},
	});
}

export function* getContentVerificationScore({
	payload,
}: Action<GetContentVerificationScorePayloadDto>) {
	const { url, contentId } = payload;

	yield genericApiSaga({
		gatewayCall: () => getRequest(`${CONTENT_VERIFICATION_SCORE}?url=${url}`),
		*completed(response: any) {
			if (response?.data) {
				response.data.result.contentId = contentId;
			}
			yield put(actions.getVerificationScoreSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getVerificationScoreFailed());
		},
	});
}

export function* getTermsConditions({ payload: { contentId } }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${CONTENT_API_URL}/terms-conditions?contentId=${contentId}`),
		*completed(response: any) {
			if (response?.data) {
				response.data.contentId = contentId;
			}
			yield put(actions.getTermsConditionsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getTermsConditionsFailed(response));
		},
	});
}

export function* countDownloadContent({ payload }: Action<any>) {
	const { downloadType, contentIds, albumId } = payload;

	yield genericApiSaga({
		gatewayCall: () =>
			putRequest(
				`${CONTENT_API_URL}/track-download?downloadType=${downloadType}&albumId=${albumId}`,
				contentIds,
			),
		*failed(response: ResponsePayloadDto) {
			if (response) {
				const { error } = response.data;
				if (error) {
					console.log(error);
				}
			}
		},
	});
}

export function* updateContentStatusRecentlyAdded({ payload }: Action<any>) {
	const { isAll, isDirectUpload, contentIds } = payload;

	const url =
		isAll === undefined
			? `${CONTENT_STATUS_RECENTLY_ADDED}`
			: `${CONTENT_STATUS_RECENTLY_ADDED}?isAll=${isAll}&isDirectUpload=${
					isDirectUpload === undefined ? true : isDirectUpload
			  }`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, contentIds),
		*completed(response: ResponsePayloadDto) {
			yield put(actions.updateContentStatusRecentlyAddedSucceeded(response.data));
		},
		*failed(response: ResponsePayloadDto) {
			yield put(actions.updateContentStatusRecentlyAddedFailed(response.data));
		},
	});
}

export function* getContentUnviewedStatusRecentlyAdded({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${CONTENT_STATUS_RECENTLY_ADDED}`, payload),
		*completed(response: ResponsePayloadDto) {
			yield put(actions.getContentUnviewedStatusRecentlyAddedSucceeded(response.data));
		},
		*failed(response: ResponsePayloadDto) {
			yield put(actions.getContentUnviewedStatusRecentlyAddedFailed(response.data));
		},
	});
}

export function* fetchContentLocation() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CONTENT_LOCATION),
		*completed(response: any) {
			yield put(actions.fetchContentLocationSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchContentLocationFailed(response));
		},
	});
}

export function* fetchContentCustomFields() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CONTENT_CUSTOM_FIELD_API),
		*completed(response: any) {
			yield put(actions.fetchContentCustomFieldsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchContentCustomFieldsFailed(response));
		},
	});
}

export function* fetchContentTags() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CONTENT_TAGS),
		*completed(response: any) {
			yield put(actions.fetchContentTagsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchContentTagsFailed(response));
		},
	});
}

export function* changeFilterContent({ payload }: Action<any>) {
	const { id, ...otherParam } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${FILTER_CONTENT}?id=${id}`, otherParam),
		*completed(response: any) {
			yield put(actions.updateFilterContentDetailSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateFilterContentDetailFailed(response));
		},
	});
}

export function* fetchTermsContent({ payload }: Action<DataRequestTermsContentTypes>) {
	const { param, body } = payload;
	const convertParam = convertParamSearch(param);
	const url = body
		? `${GENERATE_PDF_TERMS_CONDITION}?${convertParam}`
		: `${TERMS_CONTENT}/${param?.contentId}`;
	yield genericApiSaga({
		gatewayCall: () => (body ? putRequest(url, body) : getRequest(url)),
		*completed(response: any) {
			yield put(
				actions.fetchTermsContentSucceeded({
					...response,
					isTermsTemplate: !!body,
					id: param.contentId,
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.fetchTermsContentFailed({ ...response, id: param.contentId }));
		},
	});
}

export function* fetchPublishLogContent({ payload }: Action<ParamPublishLogDto>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(PUBLISH_LOG, payload),
		*completed(response: any) {
			yield put(actions.fetchPublishLogContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchPublishLogContentFailed(response));
		},
	});
}

export function* getRatioResolution() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(API_RATIO_RESOLUTION),
		*completed(response: any) {
			yield put(actions.fetchRatioResolutionSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchRatioResolutionFailed(response));
		},
	});
}

export function* createPublishLogContent({
	payload,
}: Action<{ params: ParamCreatePublishLogDto; bodyRequest: BodyPublishLogDto }>) {
	const { params, bodyRequest } = payload;
	const searchParams = convertParamSearch(params);
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${PUBLISH_LOG}?${searchParams}`, bodyRequest),
		*completed(response: any) {
			yield put(actions.createPublishLogContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.createPublishLogContentFailed(response));
		},
	});
}

export function* updatePublishLogContent({
	payload,
}: Action<{ id: number; bodyRequest: BodyPublishLogDto }>) {
	const { id, bodyRequest } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${PUBLISH_LOG}?id=${id}`, bodyRequest),
		*completed(response: any) {
			yield put(actions.updatePublishLogContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updatePublishLogContentFailed(response));
		},
	});
}

export function* removePublishLogContent({ payload }: Action<{ id: number }>) {
	const { id } = payload;
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${PUBLISH_LOG}?id=${id}`),
		*completed(response: any) {
			yield put(actions.removePublishLogContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.removePublishLogContentFailed(response));
		},
	});
}

export function* markFavoriteContentRequest({
	payload,
}: Action<{ isFavorite: boolean; ids: number[] }>) {
	const { isFavorite, ids } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${MARK_FAVORITE_CONTENT}/${isFavorite}`, ids),
		*completed(response: ResponsePayloadDto) {
			const favoriteContent = {
				ids,
				isFavorite,
			};

			yield put(
				actions.markFavoriteContentSucceeded({
					...response,
					favoriteContent,
				}),
			);
		},
		*failed(response: ResponsePayloadDto) {
			yield put(actions.markFavoriteContentFailed(response));
		},
	});
}

export function* updateContactedContent({ payload: { contentId } }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${CONTACTED_CONTENT}?contentId=${contentId}`),
		*completed(response: any) {
			yield put(actions.updateContactedContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateContactedContentFailed(response));
		},
	});
}

export function* updateAccountSetting({ payload }: Action<AccountSettingsRequest>) {
	const storedUser = getUser();
	const { accountId } = storedUser;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${API_UPDATE_SETTING}?accountId=${accountId}`, payload),
		*completed(response: any) {
			yield put(actions.updateAccountSettingSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateAccountSettingFailed(response));
		},
	});
}

export function* fetchContentEmoji({ payload }: Action<{ contentId: number }>) {
	const { contentId } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${API_CONTENT_EMOJI}/${contentId}`),
		*completed(response: any) {
			yield put(actions.fetchContentEmojiSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchContentEmojiFailed(response));
		},
	});
}

export function* updateContentEmoji({
	payload,
}: Action<{ contentId: number; emojiEnum: string; isRemove: boolean }>) {
	const { contentId, ...other } = payload;
	const searchParams = convertParamSearch(other);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${API_CONTENT_EMOJI}/${contentId}?${searchParams}`),
		*completed(response: any) {
			yield put(actions.updateEmojiSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateEmojiFailed(response));
		},
	});
}

export function* fetchImgVersion({ payload }: Action<number>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${API_VERSION_CONTENT}/${payload}`),
		*completed(response: any) {
			yield put(actions.fetchImgVersionSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchImgVersionFailed(response));
		},
	});
}

export function* createImgVersion({
	payload,
}: Action<{ params: ParamUploadVersionType; dataRequest: any; isUploadVideo?: boolean }>) {
	const { params, dataRequest, isUploadVideo } = payload;
	const { contentId, ...other } = params;
	const searchParams = convertParamSearch(other) ? `?${convertParamSearch(other)}` : '';

	yield genericApiSaga({
		gatewayCall: () =>
			postRequest(`${API_VERSION_CONTENT}/${contentId}${searchParams}`, dataRequest),
		*completed(response: any) {
			yield put(
				actions.createImgVersionSucceeded({
					...response.data,
					id: contentId,
					isUploadVideo: !!isUploadVideo,
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.createImgVersionFailed(response));
		},
	});
}

export function* updateImgVersion({ payload }: Action<{ contentId: number; versionId: number }>) {
	const { contentId, versionId } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${API_VERSION_CONTENT}/${contentId}?versionId=${versionId}`),
		*completed(response: any) {
			yield put(actions.updateImgVersionSucceeded({ ...response.data, id: contentId }));
		},
		*failed(response: any) {
			yield put(actions.updateImgVersionFailed(response));
		},
	});
}

export function* rotateImage({
	payload,
}: Action<{
	rotationAngleEnum: string;
	ids: number[];
	isRequestDetail?: boolean;
}>) {
	const { rotationAngleEnum, ids, isRequestDetail } = payload;
	yield genericApiSaga({
		gatewayCall: () =>
			putRequest(`${API_ROTATE_IMAGE}?rotationAngleEnum=${rotationAngleEnum}`, ids),
		*completed(response: any) {
			yield put(actions.rotateImageSucceeded({ ...response.data, ids, isRequestDetail }));
		},
		*failed(response: any) {
			yield put(actions.rotateImageFailed(response));
		},
	});
}

function* getDetectionServiceList() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(DETECTION_SERVICE_LIST),
		*completed(response: any) {
			yield put(actions.getDetectionServiceListSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getDetectionServiceListFailed(response));
		},
	});
}

function* estimateCost({ payload }: Action<EstimatedCostPayloadDto>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(ESTIMATE_COST, payload),
		*completed(response: any) {
			yield put(
				actions.estimateCostSucceeded({
					...response,
					data: { ...response.data, skipDetected: payload.skipDetected },
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.estimateCostFailed(response));
		},
	});
}

function* submitDetectionOrder({ payload }: Action<DetectionOrderPayloadDto[]>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(MEDIA_DETECTION, payload),
		*completed(response: any) {
			let analyzedContentIds: number[] = [];
			payload.forEach((item: DetectionOrderPayloadDto) => {
				analyzedContentIds = analyzedContentIds.concat(item.contentIds);
			});
			yield put(
				actions.submitDetectionOrderSucceeded({
					...response,
					data: { ...response.data, analyzedContentIds },
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.submitDetectionOrderEnd(response));
		},
	});
}

function* getMediaDetection({ payload }: Action<{ contentId: number }>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${MEDIA_DETECTION}/${payload.contentId}`),
		*completed(response: any) {
			yield put(actions.getMediaDetectionSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getMediaDetectionFailed(response));
		},
	});
}

function* getDetectionLabelList({ payload }: Action<{ folderId: number }>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${DETECTION_LABEL_LIST}?folderId=${payload.folderId}`),
		*completed(response: any) {
			yield put(actions.getDetectionLabelListSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getDetectionLabelListFailed(response));
		},
	});
}

export function* fetchContentDetail({ payload }: Action<{ id: number }>) {
	const { id } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${CONTENT_MEDIA_API}/${id}`),
		*completed(response: any) {
			yield put(actions.fetchContentDetailSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchContentDetailFailed(response));
		},
	});
}

export function* fetchDeviceMake() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(API_DEVICE_MAKE),
		*completed(response: any) {
			yield put(actions.fetchDeviceMakeSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchDeviceMakeFailed(response));
		},
	});
}

export function* fetchDeviceModel() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(API_DEVICE_MODEL),
		*completed(response: any) {
			yield put(actions.fetchDeviceModelSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchDeviceModelFailed(response));
		},
	});
}

export function* fetchShotCountries() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(API_SHOT_LOCATION),
		*completed(response: any) {
			yield put(actions.fetchShotCountriesSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchShotCountriesFailed(response));
		},
	});
}

export function* watcherContentSaga() {
	yield takeLatest(contentTypes.FETCH_CONTENT_REQUEST, fetchContent);
	yield takeLatest(fetchContentDataTypes.FETCH_CONTENT_DATA_REQUEST, fetchContentData);
	yield takeLatest(contentTotalTypes.FETCH_TOTAL_CONTENT_REQUEST, fetchTotalContent);
	yield takeLatest(rejectContentTypes.REJECT_CONTENT_REQUEST, rejectContent);
	yield takeLatest(moveContentTypes.MOVE_CONTENT_REQUEST, moveContent);
	yield takeLatest(deleteContentTypes.DELETE_CONTENT_REQUEST, deleteContent);
	yield takeLatest(deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_REQUEST, deleteMultiContents);
	yield takeLatest(reviewContentTypes.REVIEW_CONTENT_REQUEST, reviewContent);
	yield takeLatest(reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_REQUEST, reviewMultiContents);
	yield takeLatest(getFilterTypes.GET_FILTER_REQUEST, getFilter);
	yield takeLatest(saveFilterTypes.SAVE_FILTER_REQUEST, saveFilter);
	yield takeLatest(deleteFilterTypes.DELETE_FILTER_REQUEST, deleteFilter);
	yield takeLatest(
		getVerificationScroreTypes.GET_VERIFICATION_SCORE_REQUEST,
		getContentVerificationScore,
	);
	yield takeLatest(getTermsConditionsTypes.GET_TERMS_CONDITIONS_REQUEST, getTermsConditions);
	yield takeLatest(
		countDownloadContentTypes.COUNT_DOWNLOAD_CONTENT_REQUEST,
		countDownloadContent,
	);
	yield takeLatest(
		updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_REQUEST,
		updateContentStatusRecentlyAdded,
	);
	yield takeLatest(
		getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST,
		getContentUnviewedStatusRecentlyAdded,
	);
	yield takeLatest(getContentLocationTypes.GET_CONTENT_LOCATION_REQUEST, fetchContentLocation);
	yield takeLatest(
		getContentCustomFieldsTypes.GET_CONTENT_CUSTOM_FIELDS_REQUEST,
		fetchContentCustomFields,
	);
	yield takeLatest(getContentTagsTypes.GET_CONTENT_TAGS_REQUEST, fetchContentTags);
	yield takeLatest(
		updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_REQUEST,
		changeFilterContent,
	);
	yield takeLatest(getTermsContentTypes.GET_TERMS_CONTENT_REQUEST, fetchTermsContent);
	yield takeLatest(getPublishLogTypes.GET_PUBLISH_LOG_REQUEST, fetchPublishLogContent);
	yield takeLatest(createPublishLogTypes.CREATE_PUBLISH_LOG_REQUEST, createPublishLogContent);
	yield takeLatest(updatePublishLogTypes.UPDATE_PUBLISH_LOG_REQUEST, updatePublishLogContent);
	yield takeLatest(removePublishLogTypes.REMOVE_PUBLISH_LOG_REQUEST, removePublishLogContent);
	yield takeLatest(
		markFavoriteContentTypes.MARK_FAVORITE_CONTENT_REQUEST,
		markFavoriteContentRequest,
	);
	yield takeLatest(ratioAndResolutionTypes.GET_RATIO_RESOLUTION_REQUEST, getRatioResolution);
	yield takeLatest(
		updateContactedContentTypes.UPDATE_CONTACTED_CONTENT_REQUEST,
		updateContactedContent,
	);
	yield takeLatest(
		updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_REQUEST,
		updateAccountSetting,
	);
	yield takeLatest(contentEmojiTypes.FETCH_CONTENT_EMOJI_REQUEST, fetchContentEmoji);
	yield takeLatest(updateEmojiTypes.UPDATE_EMOJI_REQUEST, updateContentEmoji);
	yield takeLatest(contentImgVersionTypes.FETCH_VERSION_IMG_REQUEST, fetchImgVersion);
	yield takeLatest(createImgVersionTypes.CREATE_IMG_VERSION_REQUEST, createImgVersion);
	yield takeLatest(updateImgVersionTypes.UPDATE_IMG_VERSION_REQUEST, updateImgVersion);
	yield takeEvery(rotateImgTypes.ROTATE_IMAGE_REQUEST, rotateImage);
	yield takeLatest(
		getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_REQUEST,
		getDetectionServiceList,
	);
	yield takeEvery(estimateCostTypes.ESTIMATE_COST_REQUEST, estimateCost);
	yield takeLatest(
		submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_REQUEST,
		submitDetectionOrder,
	);
	yield takeLatest(getMediaDetectionTypes.GET_MEDIA_DETECTION_REQUEST, getMediaDetection);
	yield takeLatest(
		getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_REQUEST,
		getDetectionLabelList,
	);
	yield takeLatest(contentDetailTypes.FETCH_CONTENT_DETAIL_REQUEST, fetchContentDetail);
	yield takeLatest(fetchDeviceMakeTypes.FETCH_DEVICE_MAKE_REQUEST, fetchDeviceMake);
	yield takeLatest(fetchDeviceModelTypes.FETCH_DEVICE_MODEL_REQUEST, fetchDeviceModel);
	yield takeLatest(fetchShotCountriesTypes.FETCH_SHOT_COUNTRIES_REQUEST, fetchShotCountries);
}
