import { TAB } from '@constants/campaign';
import { ItemCamPaign } from '@models/campaign';
import { deleteCampaignAPIRequest } from '@stores/actions';
import {
	StyledCol,
	StyledRowCardList,
	StyledWrapper,
	StyledEmpty,
} from '@styled/Campaign/CampaignStyled';
import { message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmModal } from '@cores/Modal';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
// Icons
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import CampaignModal from './CampaignModal';
import { destroyGeneralMessage, generalMessage, renderThumbVertical } from '@utils/renderComponent';
import { MESSAGE_TYPE } from '@constants/common';
import CardCampaign from './CardCampaign';
import ActivationEmailModal from './ActivationEmailModal';

const StyledScrollbars = styled(Scrollbars)`
	&.scrollbar_custom_wrapper {
		& > div {
			overflow-x: hidden !important;
		}
	}
`;

type CardProps = {
	checkAdd: boolean;
	cancelModal: () => void;
	dataParams: {
		page: number;
		status: string;
		maxRecords: number;
	};
	dataListAPI: ItemCamPaign[];
	hashTagListCampaign: string[] | [];
	handleChangeTab: (value: string) => void;
	handleScrollbars: (top: number) => void;
	isTabActive?: boolean;
};

const CampaignCardList = (props: CardProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const refModal = useRef<any>({});
	const {
		checkAdd,
		cancelModal,
		dataParams,
		dataListAPI: dataCampaignList = [],
		hashTagListCampaign = [],
		handleChangeTab,
		handleScrollbars,
		isTabActive = false,
	} = props;

	const { page = 0, status = '', maxRecords = 0 } = dataParams || {};

	const { loadingCampaign } = useSelector((state: any) => state.campaign);

	const scrollbars = useRef<any>({});

	const [isPopUpDelete, setIsPopUpDelete] = useState<boolean>(false);
	const [campaignSelected, setCampaignSelected] = useState<ItemCamPaign | any>(null);
	const [visibleCampaignModal, setVisibleCampaignModal] = useState<boolean>(false);
	const [visibleCampaignActivate, setVisibleCampaignActivate] = useState<boolean>(false);

	useEffect(() => {
		return () => {
			destroyGeneralMessage();
		};
	}, []);

	const showModal = (itemCard: ItemCamPaign) => {
		setCampaignSelected(itemCard);
		setVisibleCampaignModal(true);
	};

	const onGoLiveSuccessful = (status: string) => {
		if (status === TAB.active.key) {
			message.success(t('campaign.message.go_live'));
			if (handleChangeTab) {
				handleChangeTab(TAB.active.key);
			}
		}
		if (status === TAB.inactive.key) {
			message.success(t('campaign.message.save_draft'));
			if (handleChangeTab) {
				handleChangeTab(TAB.inactive.key);
			}
		}
	};

	const onUpdateCampaignFailed = (data: any) => {
		generalMessage(data?.error, MESSAGE_TYPE.ERROR);
	};

	const handleUpdateSuccessful = (statusAPI: string, statusName: string) => {
		if (statusName !== undefined && statusAPI === TAB.archives.key) {
			message.success(t('campaign.message.archive'));
			if (handleChangeTab) {
				handleChangeTab(TAB.archives.key);
			}
		} else if (statusName !== undefined && statusAPI === TAB.active.key) {
			message.success(t('campaign.message.go_live'));
			if (handleChangeTab) {
				handleChangeTab(TAB.active.key);
			}
		} else if (statusName !== undefined && statusAPI === TAB.inactive.key) {
			if (status === TAB.archives.key && statusAPI === TAB.inactive.key) {
				message.success(t('campaign.message.restore'));
				if (handleChangeTab) {
					handleChangeTab(TAB.inactive.key);
				}
			} else {
				message.success(t('campaign.message.save_draft'));
				if (handleChangeTab) {
					handleChangeTab(TAB.inactive.key);
				}
			}
		} else {
			message.success(t('campaign.message.updated'));
			if (handleChangeTab) {
				handleChangeTab(TAB.active.key);
			}
		}
	};

	const handleCancel = () => {
		setCampaignSelected(null);
		cancelModal();
		setVisibleCampaignModal(false);
	};

	const deleteCampaignSuccessful = () => {
		message.success(t('campaign.message.deleted'));
	};

	const handleDeleteCampaign = () => {
		const dataQuery = {
			campaignId: campaignSelected?.id,
			dataParams: { page, maxRecords, status },
			deleteCampaignSuccessful,
		};
		dispatch(deleteCampaignAPIRequest(dataQuery));
		onClearForm();
		setIsPopUpDelete(false);
	};

	const handleClosePopup = () => {
		setIsPopUpDelete(false);
	};

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			handleScrollbars(scrollbarTop);
		}
	};

	const onClearForm = () => {
		if (refModal?.current?.clearFormCampaign) {
			refModal?.current?.clearFormCampaign();
		}
	};

	const handleCancelActivate = () => {
		setCampaignSelected(null);
		setVisibleCampaignActivate(false);
	};

	const handleActivate = (campaign: ItemCamPaign) => {
		if (!campaign?.activationTemplateId) {
			message.destroy();
			message.error(t('campaign.message.no_activation_template'));
			return;
		}
		setCampaignSelected(campaign);
		setVisibleCampaignActivate(true);
	};

	return (
		<StyledScrollbars
			className="scrollbar_custom_wrapper"
			ref={(el) => {
				scrollbars.current = el;
			}}
			renderTrackVertical={() => <div />}
			onScrollStop={onScrollStop}
			style={{ height: 'calc(100vh - 142px)' }}
			renderThumbHorizontal={() => <div />}
			renderThumbVertical={renderThumbVertical}>
			<StyledRowCardList gutter={[16, 24]}>
				{dataCampaignList?.length === 0
					? !loadingCampaign && (
							<StyledWrapper>
								<StyledEmpty image={IconEmpty} />
							</StyledWrapper>
					  )
					: dataCampaignList.map((item: ItemCamPaign) => {
							return (
								<StyledCol span={6} key={item.id}>
									<CardCampaign
										isTabActive={isTabActive}
										key={item.id}
										showModal={showModal}
										data={item}
										handleActivate={() => handleActivate(item)}
									/>
								</StyledCol>
							);
					  })}

				<CampaignModal
					ref={refModal}
					visible={checkAdd || visibleCampaignModal}
					detailCampaign={campaignSelected}
					onClose={handleCancel}
					setIsPopUpDelete={setIsPopUpDelete}
					dataParams={dataParams}
					handleUpdateSuccessful={handleUpdateSuccessful}
					onGoLiveSuccessful={onGoLiveSuccessful}
					onUpdateCampaignFailed={onUpdateCampaignFailed}
					hashTagListCampaign={hashTagListCampaign}
				/>
				{visibleCampaignActivate && campaignSelected && (
					<ActivationEmailModal
						width={'calc(100vw - 300px)'}
						onCancel={handleCancelActivate}
						visible={visibleCampaignActivate}
						footer={null}
						campaign={campaignSelected}
					/>
				)}
				<ConfirmModal
					isOpen={isPopUpDelete}
					onClose={handleClosePopup}
					onSubmit={handleDeleteCampaign}>
					{t('campaign.popup_delete')}
				</ConfirmModal>
			</StyledRowCardList>
		</StyledScrollbars>
	);
};

export default CampaignCardList;
