import { DEFAULT_PARAMS_CONTENT } from '@constants/content/albums';
import { GetContentPayloadDto } from '@models/content/contentLibrary/store';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { createContext } from 'react';

// Types
type CreatorContentContextTypes = {
	setContentList: (contentList: ContentContainerDto[]) => void;
	contentList: ContentContainerDto[];
	markAsFavoriteContent: (content: ContentDto) => void;
	socialPostList: SocialContentContainerDto[];
	setParam: (param: GetContentPayloadDto) => void;
	param: GetContentPayloadDto;
	handleContentDetails: (idx: number) => void;
	setShowPostDetails: (isShow: boolean) => void;
	showPostDetails: boolean;
	tabType: {
		isPublished: boolean;
		isSocialPost: boolean;
	};
	selectContentList: ContentContainerDto[] | SocialContentContainerDto[];
};

// Default value
const data: CreatorContentContextTypes = {
	setContentList: () => {},
	contentList: [],
	markAsFavoriteContent: () => {},
	socialPostList: [],
	setParam: () => {},
	param: {
		page: 1,
		maxRecords: DEFAULT_PARAMS_CONTENT.maxRecords,
	},
	handleContentDetails: () => {},
	setShowPostDetails: () => {},
	showPostDetails: true,
	tabType: {
		isPublished: true,
		isSocialPost: false,
	},
	selectContentList: [],
};

const CreatorContentContext = createContext(data);

export default CreatorContentContext;
