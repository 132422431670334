import THEME from '@constants/themes/themes';
import Wrapper from '@cores/Wrapper';
import {
	Button,
	Card,
	Col,
	DatePicker,
	Empty,
	Form,
	Image,
	Input,
	Modal,
	Row,
	Select,
	Tabs,
	Tag,
	Typography,
	Upload,
} from 'antd';
import styled, { css } from 'styled-components';
import { StyledButton as StyledCommonButton } from '@styled/Common/CommonStyled';

const { TabPane } = Tabs;
const { Text } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const StyledSectionDiv = styled.div`
	width: 100%;
	height: 100%;
`;

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)<{
	background?: boolean;
	padding?: string;
	margintop?: string;
	paddingLeft?: string;
	paddingRight?: string;
}>`
	background-color: ${({ background }) => (background ? THEME.colors.darkBlue : 'unset')};
	padding: ${({ padding }) => `${padding} !important` || 'unset'};
	margin-top: ${({ margintop }) => margintop || 'unset'};
	padding-left: ${({ paddingLeft }) => `${paddingLeft} !important` || 'unset'};
	padding-right: ${({ paddingRight }) => `${paddingRight} !important` || 'unset'};
	&.col_widget_select {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
`;

const StyledWrapper = styled(Wrapper)`
	height: unset;
	height: calc(100vh - 80px);
	background-color: ${THEME.colors.darkBlue};
`;

const StyledRangePicker = styled(RangePicker)`
	&.range_date_picker {
		.ant-picker-cell-disabled {
			&::before {
				background: ${THEME.colors.gray1};
			}
		}
	}
`;

const StyledFormItem = styled(Form.Item)<{ colorLabel?: string }>`
	&.select_widget {
		width: 82%;
		max-width: 82%;
		.ant-form-item-control-input-content {
			display: flex;
			align-items: center;
		}
	}
	&.form_item_end {
		margin-bottom: 0;
	}
	.ant-form-item-label {
		& > label {
			${({ colorLabel }) => colorLabel && `color: ${colorLabel}`};

			&.ant-form-item-required {
				&:not(.ant-form-item-required-mark-optional) {
					&::before {
						display: none;
					}
					&::after {
						display: inline-block;
						margin-left: 4px;
						color: ${THEME.colors.redBase};
						font-size: 14px;
						font-family: SimSun, sans-serif;
						line-height: 1;
						content: '*';
					}
				}
			}
		}
	}
	.ant-form-item-control-input {
		input,
		textarea {
			&::-webkit-input-placeholder {
				color: ${THEME.colors.darkBlue5};
			}

			:-ms-input-placeholder {
				color: ${THEME.colors.darkBlue5};
			}

			::placeholder {
				color: ${THEME.colors.darkBlue5};
			}
		}
	}
`;

const StyledContentDetailModal = styled.div`
	padding: 24px;
	max-height: calc(100vh - 200px);
	overflow-y: auto;
`;

const StyledWidgetSession = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 24px;
`;

const StyledButtonPreview = styled(StyledCommonButton)<{ margin?: string; isDisabled?: boolean }>`
	padding-left: 14px;
	padding-right: 14px;
	background-color: ${THEME.colors.darkBlue4};
	border: 0;

	${({ isDisabled }) =>
		isDisabled &&
		css`
			pointer-events: none;
			opacity: ${THEME.disabledOpacity};
		`}

	${({ margin }) => margin && `margin: ${margin}`};
`;

const StyledLinkWidget = styled.div`
	width: 100%;
	cursor: pointer;
	text-decoration: underline;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: ${THEME.colors.blueBase};
`;

const StyledTab = styled(Tabs)`
	&.campaignPage-Tabs {
		height: 100%;
	}
	&.campaignPage-Tabs .ant-tabs-nav .ant-tabs-nav-wrap {
		padding: 0px 16px;
	}
	&.campaignPage-Tabs .ant-tabs-tab .ant-tabs-tab-btn {
		color: grey;
	}
	&.campaignPage-Tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
		color: white;
	}
	&.campaignPage-Tabs .ant-tabs-tab {
		width: 80px;
		margin: 0 0 0 15px;
		justify-content: center;
	}
	&.campaignPage-Tabs .ant-tabs-nav::before {
		border-bottom: 1px solid #8080805e;
	}
	&.campaignPage-Tabs .ant-tabs-content-holder {
		overflow-y: scroll;
		padding: 0px 8px;
		&::-webkit-scrollbar {
			width: 10px;
		}
		&::-webkit-scrollbar-thumb {
			background: ${THEME.colors.gray2};
		}
		&::-webkit-scrollbar-track {
			box-shadow: inset -18px -18px 5px ${THEME.colors.darkBlue};
		}
	}
`;
const StyledTabPane = styled(TabPane)``;

const StyledRowCardList = styled(Row)`
	padding: 16px;
`;

const StyledRowCard = styled(Card)`
	border-radius: 5px;
	border: 1px solid #203241;
	background-color: #203241;
	.ant-card-body {
		padding: 16px;
	}
`;

const StyledCartText = styled(Text)`
	&.card-campaign-title {
		width: 95%;
		padding-right: 5px;
		font-family: ${THEME.fonts.primary};
		font-size: 1.429rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.gray5};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&.card-campaign-value-name {
		overflow: hidden;

		font-family: ${THEME.fonts.primary};
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.gray5};
	}
	&.card-campaign-modal-text {
		display: block;
		font-family: ${THEME.fonts.primary};
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.gray5};
	}
	&.card-campaign-description-date {
		margin-top: 16px;
		font-family: ${THEME.fonts.primary};
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.57;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.gray5};
	}
	&.card-campaign-description-content {
		margin-top: 16px;
		font-family: ${THEME.fonts.primary};
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.gray5};
	}
	&.card-campaign-text-content2Text {
		position: absolute;
		top: calc(50% - 15px);
		left: calc(50% - 35px);

		font-family: ${THEME.fonts.primary};
		font-size: 0.857rem;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: normal;
		color: ${THEME.colors.gray5};
	}
`;
const StyledCardDiv = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	&.card-campaign-value {
		padding: 8px 16px;
		border: 1px solid ${THEME.colors.darkBlue4};
		border-radius: 5px;
		transition: all 0.35s ease-in-out 0s;
		&.active-link:hover {
			border: 1px solid ${THEME.colors.primary};
			& .card-campaign-value-hashtag {
				background-color: ${THEME.colors.primary};
			}
		}
	}

	&.card-campaign-hashTag {
		height: 100%;
		margin-bottom: 15px;
		flex-flow: row wrap;
		justify-content: flex-start;
	}
	&.card-campaign-description {
		flex-flow: row wrap;
		margin-bottom: 12px;
		& span {
			width: 100%;
			white-space: normal;
		}
		& a {
			max-width: calc(100% - 32px);
			word-break: break-word;
		}
	}
	&.card-campaign-Img {
		flex-flow: row wrap;
		justify-content: flex-start;
		margin-bottom: 0;
		& .card-campaign-Img-only1 {
			width: 100%;
			border-radius: 4px;
			overflow: hidden;
			margin-bottom: 0px;
			& .ant-image {
				height: 100%;
				width: 100%;
				& img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
		}

		& .card-campaign-Img-1 {
			width: 63%;
			margin-right: 8px;
			margin-bottom: 0px;
			& .ant-image {
				width: 100%;
			}
		}
		& .card-campaign-Img-2 {
			width: 31%;
			flex-flow: column;
			margin-bottom: 0px;
			margin-top: 5px;
		}
	}
	&.card-campaign-div-title {
		align-items: start;
	}
`;

const StyledImg = styled(Image)`
	&.card__img__content2 {
		margin-bottom: 7px;
	}
	&.img_campaign {
		object-fit: unset !important;
	}
`;

const StyledLink = styled.a`
	font-family: ${THEME.fonts.primary};
	font-size: 0.857rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: left;
	color: ${THEME.colors.blueBase};
`;

const StyledTag = styled(Tag)`
	&.card-campaign-value-hashtag {
		margin: 0;
		padding: 5px;

		border-radius: 5px;
		border: 1px solid #203241;

		font-family: ${THEME.fonts.primary};
		font-size: 1rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.57;
		letter-spacing: normal;
		color: #fff;

		background-color: #344552;
		color: ${THEME.colors.gray5};
	}
	&.card-campaign-hashTag-item {
		margin-bottom: 2px;
		border-radius: 2px;
		border: 1px solid #344552;

		font-family: ${THEME.fonts.primary};
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.gray5};
		background-color: #344552;
	}
`;

const StyledButton = styled(Button)<{ width?: string }>`
	${({ width }) => width && `width: ${width}`};

	&.card-campaign-buttonEdit {
		border: none;
	}

	&.card-campaign-add-btn {
		width: 74px;
		height: 32px;
		margin-right: 16px;

		font-family: ${THEME.fonts.primary};
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: center;
		color: ${THEME.colors.gray5};
		background-color: ${THEME.colors.primary};

		& svg {
			fill: ${THEME.colors.gray5} !important;
		}
	}

	&.card-campaign-modal-BtnUpload {
		width: 124px;
		height: 32px;
		padding: 6px 16px;
		border-radius: 2px;
		background-color: #203241;
		& span {
			font-family: ${THEME.fonts.primary};
			font-size: 1rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: normal;
			text-align: center;
			color: ${THEME.colors.gray5};
		}
	}

	&.gray-out {
		opacity: 0.6;
	}
	&.btn_overflow {
		& > span {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;

const StyledCardModalDiv = styled.div`
	margin-bottom: 24px;
	&.card-campaign-modal-date {
		margin-bottom: 0px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
	}
	&.card-campaign-modal-endDate {
		.ant-typography {
			display: block;
		}
	}
	&.card-campaign-modal-startDate {
		.ant-typography {
			display: block;
		}
	}
	&.card-campaign-modal-div-upload {
		position: relative;
	}
`;

const StyledCardModalSpan = styled.span`
	color: red;
`;
const StyledCardModal = styled(Modal)`
	& .ant-modal-title {
		font-family: ${THEME.fonts.primary};
		font-size: 1.143rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.gray5};
	}
	.ant-modal-body {
		padding: 0;
		.content_detail {
			padding: 24px;
		}
	}
`;

const StyledCardModalInput = styled(Input)`
	font-family: ${THEME.fonts.primary};
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: ${THEME.colors.gray5};

	&.card__modal_text_upload {
		margin-bottom: 16px;
	}
`;

const StyledCardModalInputTextArea = styled(TextArea)`
	font-family: ${THEME.fonts.primary};
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: ${THEME.colors.gray5};
`;

const StyledCardModalSelect = styled(Select)`
	margin-right: 8px;
`;

const StyledCardModalDatePicker = styled(DatePicker)`
	& .ant-picker-input input {
		margin: 6px;
		font-family: ${THEME.fonts.primary};
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: ${THEME.colors.gray5};
	}
	& .ant-picker-suffix svg {
		width: 16px;
		height: 16px;
	}
	& td.ant-picker-cell.ant-picker-cell-disabled {
		background: #7a777769;
		& .ant-picker-cell-inner {
			opacity: 0.2;
		}
	}
`;

const StyledCardModalUpload = styled(Upload)`
	&.card-campaign-modal-upload {
		display: flex;
		flex-flow: column;
	}
	& .ant-upload-list-text-container {
		padding: 6px;
		margin-bottom: 0px;
		border-radius: 2px;
		border: solid 1px ${THEME.colors.darkBlue3};
	}
	& .ant-upload.ant-upload-select.ant-upload-select-text {
		margin-top: 6px;
		order: 2;
	}
	& .ant-upload.ant-upload-select.ant-upload-select-text {
		order: 2;
	}
	& .ant-upload-list {
		order: 1;
	}
	& .ant-upload-list-item {
		height: unset;
		margin-top: 0;
	}
	& .ant-upload-text-icon {
		display: none;
	}
	& .ant-upload-list-item:hover .ant-upload-list-item-info {
		background-color: unset;
	}
	& .ant-upload-list-item-card-actions-btn {
		opacity: 1;
	}
`;

const StyledCardDivImg2 = styled.div`
	&.card-campaign-img2-content2 {
		position: relative;
	}
	&.card-campaign-text-content2 {
		width: 100%;
		height: 95%;

		position: absolute;
		top: 0;
		left: 0;

		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		opacity: 0.35;
		background-color: #67737d;
	}
`;

const StyledValidateName = styled.p`
	margin-bottom: 0;
	margin-top: 5px;
	font-family: ${THEME.fonts.primary};
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: red;
`;

const StyledEmpty = styled(Empty)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const StyledAction = styled.div`
	display: flex;
	margin: 0px 0 16px 0;
	justify-content: space-between;
`;

const StyledFilterContainer = styled.div<{ maxWidth?: string }>`
	background-color: ${THEME.colors.orangeBase};
	border-radius: 2px;
	height: 32px;
	display: flex;
	align-items: center;
	padding: 6px 12px;
	max-width: ${({ maxWidth }) => maxWidth || '550px'};
`;

const StyledText = styled.p<{ maxWidth?: string }>`
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
`;

const StyledIcon = styled.div`
	width: 20px;
	margin-left: 8px;
	display: flex;
	align-items: center;

	svg {
		cursor: pointer;
		width: 16px;
		fill: ${THEME.colors.white} !important;
	}
`;

const StyledSessionInfo = styled.div`
	margin-bottom: 12px;
`;

const StyledWrapperImgCampaign = styled.div``;

const StyledWrapperFlexBetween = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 12px;
`;

export {
	StyledSectionDiv,
	StyledRow,
	StyledCol,
	StyledWrapper,
	StyledEmpty,
	StyledTab,
	StyledTabPane,
	StyledRowCardList,
	StyledRowCard,
	StyledTag,
	StyledCardDiv,
	StyledCartText,
	StyledButton,
	StyledCardModalDiv,
	StyledCardModalInput,
	StyledCardModalSelect,
	StyledCardModalDatePicker,
	StyledCardModalSpan,
	StyledCardModal,
	StyledLink,
	StyledImg,
	StyledCardModalInputTextArea,
	StyledCardModalUpload,
	StyledCardDivImg2,
	StyledFormItem,
	StyledContentDetailModal,
	StyledWidgetSession,
	StyledButtonPreview,
	StyledLinkWidget,
	StyledRangePicker,
	StyledValidateName,
	StyledAction,
	StyledFilterContainer,
	StyledText,
	StyledIcon,
	StyledSessionInfo,
	StyledWrapperImgCampaign,
	StyledWrapperFlexBetween,
};
