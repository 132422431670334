/**
 * @prettier
 */

import { Action } from 'redux-actions';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
	BLOCK_SOCIAL_CONTENT,
	BLOCK_SOCIAL_USERNAME,
	DELETE_SOCIAL_SEARCH_FILTER,
	FETCH_SOCIAL_CONTENT,
	FETCH_SOCIAL_POST,
	GET_SOCIAL_SEARCH_FILTER,
	IMPORT_SOCIAL_CONTENT,
	KEY_WORD,
	PREVIEW_TIKTOK_URL,
	RECENT_SOCIAL_POST_API,
	SAVE_FACEBOOK_TOKEN,
	SAVE_SOCIAL_SEARCH_FILTER,
	SOCIAL_FEED_API,
	SOCIAL_FEED_BLOCK_CONTENT,
	SOCIAL_FEED_BLOCK_USERNAME,
	SOCIAL_FEED_IMPORT_MEDIA,
} from '@constants/APIs';
import genericApiSaga from '@helpers/genericApiSaga';
import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import { GetContentPayloadDto } from '@models/content/contentLibrary/store';
import {
	BlockSocialContentPayloadDto,
	BlockSocialUFeedUsernamePayloadDto,
	BlockSocialUsernamePayloadDto,
	GetSocialContentPayloadDto,
	ImportSocialContentPayloadDto,
	RecentSocialPostPayloadDto,
} from '@models/socialsearch/store';
import {
	blockSocialContentTypes,
	blockSocialUsernameTypes,
	contentFeedTypes,
	deleteSocialSearchFilterTypes,
	fetchMetricFeedTypes,
	fetchRecentSocialPostTypes,
	fetchSocialContentTypes,
	fetchSocialPostTypes,
	getKeywordTypes,
	getSocialSearchFilterTypes,
	getUrlPreviewTikTokTypes,
	importSocialContentTypes,
	saveFacebookTokenTypes,
	saveKeywordTypes,
	saveSocialSearchFilterTypes,
} from '@stores/socialsearch/socialsearch.types';
import * as actions from './socialsearch.actions';

export function* fetchSocialContent({ payload }: Action<GetSocialContentPayloadDto>) {
	delete payload.excludeSearch;

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${FETCH_SOCIAL_CONTENT}`, payload),
		*completed(response: any) {
			yield put(actions.fetchSocialContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchSocialContentFailed(response));
		},
	});
}

export function* fetchSocialContentFeed({ payload }: Action<any>) {
	const { id, ...others } = payload;

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${SOCIAL_FEED_API}/${id}/search`, { ...others }),
		*completed(response: any) {
			yield put(actions.fetchSocialContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchSocialContentFailed(response));
		},
	});
}

export function* fetchUrlPreviewTikTok({
	payload,
}: Action<{ url: string; id: number; callback?: (val: string) => void }>) {
	const { url, id, callback } = payload;

	yield genericApiSaga({
		gatewayCall: () => getRequest(`${PREVIEW_TIKTOK_URL}?url=${url}`),
		*completed(response: any) {
			if (callback && typeof callback === 'function') {
				callback(response?.data?.result || '');
			}
			yield put(actions.fetchUrlPreviewTikTokSucceeded({ ...response, id }));
		},
		*failed(response: any) {
			yield put(actions.fetchUrlPreviewTikTokFailed(response));
		},
	});
}

export function* importSocialContent({ payload }: Action<ImportSocialContentPayloadDto>) {
	const url = `${IMPORT_SOCIAL_CONTENT}${payload.socialSource.toLowerCase()}`;
	payload.socialSource = payload.socialSource.toUpperCase();

	yield genericApiSaga({
		gatewayCall: () => postRequest(url, payload),
		*completed(response: any) {
			if (response?.data) {
				response.data.ids = payload.ids;
			}
			yield put(actions.importSocialContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.importSocialContentFailed(response));
		},
	});
}

export function* importSocialFeedContent({ payload }: Action<ImportSocialContentPayloadDto>) {
	const { socialSource, ...other } = payload;
	yield genericApiSaga({
		gatewayCall: () => postRequest(SOCIAL_FEED_IMPORT_MEDIA, other),
		*completed(response: any) {
			if (response?.data) {
				response.data.ids = payload.ids;
			}
			yield put(actions.importSocialContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.importSocialContentFailed(response));
		},
	});
}

export function* blockSocialContent({ payload }: Action<BlockSocialContentPayloadDto>) {
	const url = `${BLOCK_SOCIAL_CONTENT}${payload.socialSource.toLowerCase()}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, payload.ids),
		*completed(response: any) {
			if (response?.data) {
				response.data.ids = payload.ids;
			}
			yield put(actions.blockSocialContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.blockSocialContentFailed(response));
		},
	});
}

export function* blockSocialFeedContent({ payload }: Action<BlockSocialContentPayloadDto>) {
	const { id } = payload;
	const url = id ? `${SOCIAL_FEED_API}/${id}/block-media` : SOCIAL_FEED_BLOCK_CONTENT;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, payload.ids),
		*completed(response: any) {
			if (response?.data) {
				response.data.ids = payload.ids;
			}
			yield put(actions.blockSocialContentSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.blockSocialContentFailed(response));
		},
	});
}

export function* blockSocialUsername({ payload }: Action<BlockSocialUsernamePayloadDto>) {
	const url = `${BLOCK_SOCIAL_USERNAME}${payload.socialSource.toLowerCase()}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, payload),
		*completed(response: any) {
			if (response?.data) {
				response.data.usernames = payload.usernames;
			}
			yield put(actions.blockSocialUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.blockSocialUsernameFailed(response));
		},
	});
}

export function* blockSocialFeedUsername({ payload }: Action<BlockSocialUFeedUsernamePayloadDto>) {
	const { id, ...other } = payload;
	const url = id ? `${SOCIAL_FEED_API}/${id}/block-username` : SOCIAL_FEED_BLOCK_USERNAME;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, other),
		*completed(response: any) {
			if (response?.data) {
				response.data.usernames = payload.usernames.map((item) => item.username);
			}

			yield put(actions.blockSocialUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.blockSocialUsernameFailed(response));
		},
	});
}

export function* getSocialSearchFilter({ payload }: Action<any>) {
	const { page, maxRecords } = payload;

	yield genericApiSaga({
		gatewayCall: () =>
			getRequest(
				`${GET_SOCIAL_SEARCH_FILTER}?page=${page}&maxRecords=${maxRecords}&sortBy=id`,
			),
		*completed(response: any) {
			yield put(actions.getSocialSearchFilterSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getSocialSearchFilterFailed(response));
		},
	});
}

export function* saveSocialSearchFilter({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${SAVE_SOCIAL_SEARCH_FILTER}`, payload),
		*completed(response: any) {
			yield put(actions.saveSocialSearchFilterSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.saveSocialSearchFilterFailed(response));
		},
	});
}

export function* deleteSocialSearchFilter({ payload }: Action<number[]>) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${DELETE_SOCIAL_SEARCH_FILTER}`, payload),
		*completed(response: any) {
			yield put(actions.deleteSocialSearchFilterSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteSocialSearchFilterFailed(response));
		},
	});
}

export function* saveTokenFacebook({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(SAVE_FACEBOOK_TOKEN, payload),
		*completed(response: any) {
			yield put(actions.saveFacebookTokenSuccess(response));
		},
		*failed(response: any) {
			yield put(actions.saveFacebookTokenFailed(response));
		},
	});
}

export function* getKeyword({ payload }: Action<any>) {
	const { keyword, page, maxRecord } = payload;
	const url: string = `${KEY_WORD}?keyword=${encodeURIComponent(
		keyword,
	)}&page=${page}&maxRecord=${maxRecord}`;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.getKeywordSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getKeywordFailed(response));
		},
	});
}

export function* saveKeyword({ payload }: Action<any>) {
	const { keyword } = payload;

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${KEY_WORD}?keyword=${encodeURIComponent(keyword)}`),
		*completed(response: any) {
			yield put(actions.saveKeywordSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.saveKeywordFailed(response));
		},
	});
}

export function* fetchMetricFeed({ payload }: Action<any>) {
	const { id, ...others } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${SOCIAL_FEED_API}/${id}/filter/stats`, others),
		*completed(response: any) {
			yield put(actions.fetchMetricFeedSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchMetricFeedFailed(response));
		},
	});
}

function* fetchSocialPost({ payload }: Action<GetContentPayloadDto>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(FETCH_SOCIAL_POST, { ...payload }),
		*completed(response: any) {
			yield put(actions.fetchSocialPostSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchSocialPostFailed(response));
		},
	});
}

function* fetchRecentSocialPost({ payload }: Action<RecentSocialPostPayloadDto>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(RECENT_SOCIAL_POST_API, payload),
		*completed(response: any) {
			yield put(actions.fetchRecentSocialPostSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchRecentSocialPostFailed(response));
		},
	});
}

export function* watcherSocialSearchSaga() {
	yield takeLatest(fetchSocialContentTypes.FETCH_SOCIAL_CONTENT_REQUEST, fetchSocialContent);
	yield takeLatest(contentFeedTypes.FETCH_CONTENT_FEED_REQUEST, fetchSocialContentFeed);
	yield takeEvery(getUrlPreviewTikTokTypes.GET_URL_PREVIEW_TIKTOK_REQUEST, fetchUrlPreviewTikTok);
	yield takeLatest(importSocialContentTypes.IMPORT_SOCIAL_CONTENT_REQUEST, importSocialContent);
	yield takeLatest(
		importSocialContentTypes.IMPORT_SOCIAL_FEED_CONTENT_REQUEST,
		importSocialFeedContent,
	);
	yield takeLatest(blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_REQUEST, blockSocialContent);
	yield takeLatest(
		blockSocialContentTypes.BLOCK_SOCIAL_FEED_CONTENT_REQUEST,
		blockSocialFeedContent,
	);

	yield takeLatest(blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_REQUEST, blockSocialUsername);
	yield takeLatest(
		blockSocialUsernameTypes.BLOCK_SOCIAL_FEED_USERNAME_REQUEST,
		blockSocialFeedUsername,
	);
	yield takeLatest(
		getSocialSearchFilterTypes.GET_SOCIAL_SEARCH_FILTER_REQUEST,
		getSocialSearchFilter,
	);
	yield takeLatest(
		saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_REQUEST,
		saveSocialSearchFilter,
	);
	yield takeLatest(
		deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_REQUEST,
		deleteSocialSearchFilter,
	);
	yield takeLatest(saveFacebookTokenTypes.SAVE_FACEBOOK_TOKEN_REQUEST, saveTokenFacebook);
	yield takeLatest(getKeywordTypes.GET_KEY_WORD_REQUEST, getKeyword);
	yield takeLatest(saveKeywordTypes.SAVE_KEY_WORD_REQUEST, saveKeyword);
	yield takeLatest(fetchMetricFeedTypes.FETCH_METRIC_FEED_REQUEST, fetchMetricFeed);
	yield takeLatest(fetchSocialPostTypes.FETCH_SOCIAL_POST_REQUEST, fetchSocialPost);
	yield takeLatest(
		fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_REQUEST,
		fetchRecentSocialPost,
	);
}
