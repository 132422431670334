import { IconAltAdd } from '@assets/icons';
import CampaignCardList from '@components/Campaigns';
import { TAB, TAB_LIST } from '@constants/campaign';
import { LoadingWrapper } from '@cores/index';
import {
	clientSettingsRequest,
	fetchHashTagRequest,
	getCampaignListAPI,
	storeCampaignDataRequest,
} from '@stores/actions';
import {
	StyledButton,
	StyledCol,
	StyledRow,
	StyledSectionDiv,
	StyledTab,
	StyledTabPane,
	StyledWrapper,
} from '@styled/Campaign/CampaignStyled';
// Icons

import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ItemCamPaign } from '@models/campaign';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_PATH, SUB_PATH } from '@constants/common';

const CampaignPage: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { state = {} }: any = location;

	const [add, setAdd] = useState(false);
	const [paramsRequest, setParamsRequest] = useState({
		page: 1,
		maxRecords: 10,
		status: TAB.active.key,
	});

	const { loadingCampaign, campaignList = [] } = useSelector((state: any) => state.campaign);
	const { hashTagList = [], subHashTagList = [] } = useSelector(
		(state: any) => state.settings.socialSearch,
	);

	const [isCallAPI, setIsCallAPI] = useState<boolean>(false);

	const [dataCampaign, setDataCampaign] = useState<ItemCamPaign[]>([]);

	const hashTagListCampaign = [...hashTagList, ...subHashTagList];

	useEffect(() => {
		dispatch(fetchHashTagRequest());
		dispatch(clientSettingsRequest());
		return () => {
			dispatch(storeCampaignDataRequest({ campaignList: [] }));
		};
	}, []);

	useEffect(() => {
		if (state?.status) {
			setParamsRequest((prev) => ({ ...prev, status: state?.status }));
			history.replace({ state: null });
		}
	}, [state?.status]);

	useEffect(() => {
		getCampaignList(paramsRequest);
	}, [paramsRequest]);

	useEffect(() => {
		if (campaignList && campaignList.length > 0) {
			setIsCallAPI(true);
		}

		if (paramsRequest?.page === 1) {
			setDataCampaign(campaignList);
		} else {
			const newCampaignList = _.concat(dataCampaign, campaignList);
			setDataCampaign(newCampaignList);
		}
	}, [campaignList]);

	const getCampaignList = (data: object) => {
		const params = data;
		dispatch(getCampaignListAPI({ params }));
	};

	const handleAdd = () => {
		history.push(`${ROUTE_PATH.CAMPAIGNS}/${SUB_PATH.CREATE}`);
	};

	const handleCancel = () => {
		setAdd(false);
	};
	const operations = (
		<StyledButton className="card-campaign-add-btn" onClick={handleAdd}>
			<IconAltAdd />
			{t('campaign.add')}
		</StyledButton>
	);

	const changeTab = (status: string) => {
		setDataCampaign([]);
		setParamsRequest((prev) => ({ ...prev, status, page: 1 }));
	};

	const handleScrollbars = (scrollbarTop: number) => {
		if (scrollbarTop >= 0.75 && isCallAPI) {
			setParamsRequest((prev) => ({ ...prev, page: prev.page + 1 }));
			setIsCallAPI(false);
		}
	};

	const renderTabPanel = (name: string, key: string) => {
		return (
			<StyledTabPane tab={name} key={key}>
				{paramsRequest?.status === key && (
					<CampaignCardList
						checkAdd={add}
						isTabActive={key === TAB_LIST[0]?.key}
						key={key}
						handleScrollbars={handleScrollbars}
						cancelModal={handleCancel}
						dataParams={paramsRequest}
						dataListAPI={dataCampaign}
						hashTagListCampaign={hashTagListCampaign}
						handleChangeTab={changeTab}
					/>
				)}
			</StyledTabPane>
		);
	};

	return (
		<LoadingWrapper isLoading={loadingCampaign}>
			<Layout className="campaignPage">
				<StyledSectionDiv>
					<StyledRow gutter={[16, 24]}>
						<StyledCol span={24}>
							<StyledWrapper>
								<StyledTab
									defaultActiveKey={TAB_LIST[0].key}
									activeKey={paramsRequest.status}
									onChange={changeTab}
									className="campaignPage-Tabs"
									tabBarExtraContent={operations}>
									{TAB_LIST.map((panel) => renderTabPanel(panel.name, panel.key))}
								</StyledTab>
							</StyledWrapper>
						</StyledCol>
					</StyledRow>
				</StyledSectionDiv>
			</Layout>
		</LoadingWrapper>
	);
};

export default CampaignPage;
