import { STATUSCODE } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { DEFAULT_FILTERS } from '@constants/publish/gallery';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { FolderGalleryStoreDto, GalleryPayloadDto } from '@models/publishing/galleries/stores';
import _ from 'lodash';
import { Action, handleActions } from 'redux-actions';
import {
	assignAlbumToGalleryTypes,
	changeCustomDetailContentGalleryTypes,
	changeDetailFolderTypes,
	changeGallerySelectTypes,
	createFolderGalleryTypes,
	fetchContentGalleryTypes,
	fetchGalleryFolderTypes,
	fetchMetricsGalleryTypes,
	getCustomPropertiesTypes,
	removeAllContentGalleryTypes,
	resetFolderGalleryTypes,
	unAssignAlbumToGalleryTypes,
} from './galleryManager.types';

const DEFAULT_METRICS = {
	avgTimeSpent: '',
	clicks: 0,
	id: undefined,
	ctaClicks: 0,
	visitors: 0,
};
const initialState: FolderGalleryStoreDto = {
	loadingGallery: false,
	loadingContentGallery: false,
	loadingMetric: false,
	statusChangeDetailGallery: NONE,
	customDetail: '',
	statusChangeCustomDetailContent: NONE,
	statusCreateFolder: NONE,
	galleryDetail: null,
	gallerySelectDetail: null,
	folderGallery: [],
	contentGallery: [],
	error: '',
	isAutoSave: false,
	metricsGallery: { ...DEFAULT_METRICS },
	statusAssignAlbumGallery: NONE,
	statusUnassignAlbumGallery: NONE,
	statusRemoveAllContentGallery: NONE,
	customPropertiesList: [btoa(DEFAULT_FILTERS.CREATOR.fieldName)],
	getCustomPropertiesStatus: false,
	fetchFolderGalleryStatus: NONE,
};

// fetch folder gallery
const getFolderGalleryRequest = (state: FolderGalleryStoreDto) => ({
	...state,
	loadingGallery: true,
	fetchFolderGalleryStatus: IN_PROGRESS,
});

const getFolderGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: folderGallery,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingGallery: false,
			folderGallery,
			error: null,
			fetchFolderGalleryStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		loadingGallery: false,
		folderGallery: [],
		error: status,
		fetchFolderGalleryStatus: FAILED,
	};
};

const getFolderGalleryFailed = (state: FolderGalleryStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingGallery: false,
			folderGallery: [],
			error: error !== '' ? error : `${status} ${message}`,
			fetchFolderGalleryStatus: FAILED,
		};
	}

	return {
		...state,
		loadingGallery: false,
		folderGallery: [],
		fetchFolderGalleryStatus: FAILED,
	};
};

const resetFolderGallery = (state: FolderGalleryStoreDto) => ({
	...state,
	folderGallery: [],
	fetchFolderGalleryStatus: NONE,
});

// fetch content gallery
const getContentGalleryRequest = (state: FolderGalleryStoreDto) => ({
	...state,
	loadingContentGallery: true,
});

const getContentGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: contentGallery,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingContentGallery: false,
			contentGallery,
			error: null,
		};
	}

	return {
		...state,
		loadingContentGallery: false,
		contentGallery: [],
		error: status,
	};
};

const getContentGalleryFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingContentGallery: false,
			contentGallery: [],
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingContentGallery: false,
		contentGallery: [],
	};
};

// fetch metrics gallery
const getMetricsGalleryRequest = (state: FolderGalleryStoreDto) => ({
	...state,
	loadingMetric: true,
	metricsGallery: DEFAULT_METRICS,
});

const getMetricsGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: metricsGallery,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingMetric: false,
			metricsGallery,
			error: null,
		};
	}

	return {
		...state,
		loadingMetric: false,
		error: status,
	};
};

const getMetricsGalleryFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingMetric: false,
			metricsGallery: DEFAULT_METRICS,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		metricsGallery: DEFAULT_METRICS,
		loadingMetric: false,
	};
};

// change detail folder gallery
const changeDetailFolderGalleryRequest = (state: FolderGalleryStoreDto, { payload }: any) => {
	const { isAuto = false } = payload;
	return {
		...state,
		statusChangeDetailGallery: IN_PROGRESS,
		isAutoSave: isAuto,
	};
};

const changeDetailFolderGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: galleryDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusChangeDetailGallery: SUCCEEDED,
			galleryDetail,
			error: null,
		};
	}

	return {
		...state,
		statusChangeDetailGallery: FAILED,
		galleryDetail: null,
		error: status,
	};
};

const changeDetailFolderGalleryFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusChangeDetailGallery: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		galleryDetail: null,
		statusChangeDetailGallery: FAILED,
	};
};

const changeDetailFolderGalleryEnd = (state: FolderGalleryStoreDto) => {
	return {
		...state,
		statusChangeDetailGallery: NONE,
		isAutoSave: false,
	};
};

// create folder gallery
const createFolderGalleryRequest = (state: FolderGalleryStoreDto) => ({
	...state,
	statusCreateFolder: IN_PROGRESS,
});

const createFolderGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: galleryDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusCreateFolder: SUCCEEDED,
			galleryDetail,
			error: null,
		};
	}

	return {
		...state,
		statusCreateFolder: FAILED,
		error: status,
	};
};

const createFolderGalleryFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusCreateFolder: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusCreateFolder: FAILED,
	};
};

const createFolderGalleryEnd = (state: FolderGalleryStoreDto) => {
	return {
		...state,
		statusCreateFolder: NONE,
	};
};

// assign album to gallery
const assignAlbumGalleryRequest = (state: FolderGalleryStoreDto) => ({
	...state,
	statusAssignAlbumGallery: IN_PROGRESS,
});

const assignAlbumGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusAssignAlbumGallery: SUCCEEDED,
			gallerySelectDetail: { ...result, metadata: JSON.parse(result.metadata || '') },
			error: null,
		};
	}

	return {
		...state,
		statusAssignAlbumGallery: FAILED,
		error: status,
	};
};

const assignAlbumGalleryFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusAssignAlbumGallery: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusAssignAlbumGallery: FAILED,
	};
};

const assignAlbumGalleryEnd = (state: FolderGalleryStoreDto) => {
	return {
		...state,
		statusAssignAlbumGallery: NONE,
	};
};

// unassign album to gallery
const unAssignAlbumGalleryRequest = (state: FolderGalleryStoreDto) => ({
	...state,
	statusUnassignAlbumGallery: IN_PROGRESS,
});

const unAssignAlbumGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusUnassignAlbumGallery: SUCCEEDED,
			gallerySelectDetail: { ...result, metadata: JSON.parse(result.metadata || '') },
			error: null,
		};
	}

	return {
		...state,
		statusUnassignAlbumGallery: FAILED,
		error: status,
	};
};

const unAssignAlbumGalleryFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusUnassignAlbumGallery: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusUnassignAlbumGallery: FAILED,
	};
};

const unAssignAlbumGalleryEnd = (state: FolderGalleryStoreDto) => {
	return {
		...state,
		statusUnassignAlbumGallery: NONE,
	};
};

// unassign album to gallery
const removeAllContentGalleryRequest = (state: FolderGalleryStoreDto) => ({
	...state,
	statusRemoveAllContentGallery: IN_PROGRESS,
});

const removeAllContentGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusRemoveAllContentGallery: SUCCEEDED,
			gallerySelectDetail: {
				...state?.gallerySelectDetail,
				numberOfImages: 0,
				numberOfVideos: 0,
				reorder: [],
				totalContent: 0,
			},
			error: null,
		};
	}

	return {
		...state,
		statusRemoveAllContentGallery: FAILED,
		error: status,
	};
};

const removeAllContentGalleryFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusRemoveAllContentGallery: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusRemoveAllContentGallery: FAILED,
	};
};

const removeAllContentGalleryEnd = (state: FolderGalleryStoreDto) => {
	return {
		...state,
		statusRemoveAllContentGallery: NONE,
	};
};

const changeGallerySelect = (state: FolderGalleryStoreDto, { payload }: any) => {
	return {
		...state,
		gallerySelectDetail: payload,
	};
};

// change detail folder gallery
const changeCustomDetailContentGalleryRequest = (state: FolderGalleryStoreDto) => {
	return {
		...state,
		statusChangeCustomDetailContent: IN_PROGRESS,
	};
};

const changeCustomDetailContentGallerySucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: customDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusChangeCustomDetailContent: SUCCEEDED,
			customDetail,
			error: null,
		};
	}

	return {
		...state,
		statusChangeCustomDetailContent: FAILED,
		error: status,
	};
};

const changeCustomDetailContentGalleryFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusChangeCustomDetailContent: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusChangeCustomDetailContent: FAILED,
	};
};

const changeCustomDetailContentGalleryEnd = (state: FolderGalleryStoreDto) => {
	return {
		...state,
		statusChangeCustomDetailContent: NONE,
	};
};

// Get custom properties
const getCustomPropertiesRequest = (state: FolderGalleryStoreDto) => ({
	...state,
	getCustomPropertiesStatus: true,
});

const getCustomPropertiesSucceeded = (
	state: FolderGalleryStoreDto,
	{ payload }: Action<GalleryPayloadDto>,
) => {
	let { customPropertiesList = [] } = state;
	const {
		status: { code = NONE, status = '' },
		result = [],
	} = payload.data;

	if (result) {
		customPropertiesList = _.concat(customPropertiesList, result);
	}

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			getCustomPropertiesStatus: false,
			customPropertiesList: customPropertiesList.filter((item: string) => item),
			error: null,
		};
	}

	return {
		...state,
		getCustomPropertiesStatus: false,
		error: status,
	};
};

const getCustomPropertiesFailed = (
	state: FolderGalleryStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			getCustomPropertiesStatus: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		getCustomPropertiesStatus: false,
	};
};

const getCustomPropertiesEnd = (state: FolderGalleryStoreDto) => ({
	...state,
	customPropertiesList: [btoa(DEFAULT_FILTERS.CREATOR.fieldName)],
});

const contentReducers = handleActions<any>(
	{
		[fetchGalleryFolderTypes.FETCH_FOLDER_GALLERY_REQUEST]: getFolderGalleryRequest,
		[fetchGalleryFolderTypes.FETCH_FOLDER_GALLERY_SUCCEEDED]: getFolderGallerySucceeded,
		[fetchGalleryFolderTypes.FETCH_FOLDER_GALLERY_FAILED]: getFolderGalleryFailed,

		[fetchContentGalleryTypes.FETCH_CONTENT_GALLERY_REQUEST]: getContentGalleryRequest,
		[fetchContentGalleryTypes.FETCH_CONTENT_GALLERY_SUCCEEDED]: getContentGallerySucceeded,
		[fetchContentGalleryTypes.FETCH_CONTENT_GALLERY_FAILED]: getContentGalleryFailed,

		[fetchMetricsGalleryTypes.FETCH_METRICS_GALLERY_REQUEST]: getMetricsGalleryRequest,
		[fetchMetricsGalleryTypes.FETCH_METRICS_GALLERY_SUCCEEDED]: getMetricsGallerySucceeded,
		[fetchMetricsGalleryTypes.FETCH_METRICS_GALLERY_FAILED]: getMetricsGalleryFailed,

		[resetFolderGalleryTypes.RESET_FOLDER_GALLERY]: resetFolderGallery,

		[changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_REQUEST]:
			changeDetailFolderGalleryRequest,
		[changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_SUCCEEDED]:
			changeDetailFolderGallerySucceeded,
		[changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_FAILED]:
			changeDetailFolderGalleryFailed,
		[changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_END]: changeDetailFolderGalleryEnd,

		[createFolderGalleryTypes.CREATE_FOLDER_GALLERY_REQUEST]: createFolderGalleryRequest,
		[createFolderGalleryTypes.CREATE_FOLDER_GALLERY_SUCCEEDED]: createFolderGallerySucceeded,
		[createFolderGalleryTypes.CREATE_FOLDER_GALLERY_FAILED]: createFolderGalleryFailed,
		[createFolderGalleryTypes.CREATE_FOLDER_GALLERY_END]: createFolderGalleryEnd,

		[assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_REQUEST]: assignAlbumGalleryRequest,
		[assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_SUCCEEDED]: assignAlbumGallerySucceeded,
		[assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_FAILED]: assignAlbumGalleryFailed,
		[assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_END]: assignAlbumGalleryEnd,

		[unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_REQUEST]:
			unAssignAlbumGalleryRequest,
		[unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_SUCCEEDED]:
			unAssignAlbumGallerySucceeded,
		[unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_FAILED]: unAssignAlbumGalleryFailed,
		[unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_END]: unAssignAlbumGalleryEnd,

		[removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_REQUEST]:
			removeAllContentGalleryRequest,
		[removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_SUCCEEDED]:
			removeAllContentGallerySucceeded,
		[removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_FAILED]:
			removeAllContentGalleryFailed,
		[removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_END]: removeAllContentGalleryEnd,

		[changeGallerySelectTypes.CHANGE_GALLERY_SELECT]: changeGallerySelect,

		[changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_REQUEST]:
			changeCustomDetailContentGalleryRequest,
		[changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_SUCCEEDED]:
			changeCustomDetailContentGallerySucceeded,
		[changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_FAILED]:
			changeCustomDetailContentGalleryFailed,
		[changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_END]:
			changeCustomDetailContentGalleryEnd,

		[getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_REQUEST]: getCustomPropertiesRequest,
		[getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_SUCCEEDED]: getCustomPropertiesSucceeded,
		[getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_FAILED]: getCustomPropertiesFailed,
		[getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_END]: getCustomPropertiesEnd,
	},
	initialState,
);

export default contentReducers;
