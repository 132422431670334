import { IconCheck, IconChip } from '@assets/icons';
import { THUMBNAIL_BEHAVIOR } from '@constants/content/contentLibrary';
import { setObject } from '@helpers/storageHelpers';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { storeContentData } from '@stores/actions';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { StyledBtn } from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledDropdown } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { getThumbnailBehavior } from '@utils/common';
import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

type PropThumbnailBehavior = {
	handleClickMarkAll?: () => void;
};

const ThumbnailBehavior = (props: PropThumbnailBehavior) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { handleClickMarkAll } = props;

	const [visibleChipOption, setVisibleChipOption] = useState<boolean>(false);
	const [thumbnailBehavior, setThumbnailBehavior] = useState<string[]>([]);

	useEffect(() => {
		const savedThumbnailBehavior = getThumbnailBehavior();
		saveThumbnail(savedThumbnailBehavior);
	}, []);

	const saveThumbnail = (val: string[]) => {
		dispatch(storeContentData({ thumbnailBehavior: val }));
		setThumbnailBehavior(val);
	};

	const handleChipOption = (type: string) => {
		let options = [...thumbnailBehavior];
		const index = options.findIndex((option: string) => option === type);

		if (index === -1) options.push(type);
		else options = options.filter((option: string) => option !== type);

		saveThumbnail(options);
		setObject(THUMBNAIL_BEHAVIOR.SAVED, options);

		setVisibleChipOption(false);
	};

	const handleClearMark = () => {
		if (handleClickMarkAll && typeof handleClickMarkAll === 'function') {
			handleClickMarkAll();
		}
	};

	const actionsThumbnailChip: ActionsTypes[] = [
		{
			id: THUMBNAIL_BEHAVIOR.SHOW_RIGHTS_CLEARANCE,
			name: t('content_management.action_bar.show_right_clearance'),
			click: () => handleChipOption(THUMBNAIL_BEHAVIOR.SHOW_RIGHTS_CLEARANCE),
		},
		{
			id: THUMBNAIL_BEHAVIOR.SHOW_ANALYSIS_STATUS,
			name: t('content_management.action_bar.show_analysis'),
			click: () => handleChipOption(THUMBNAIL_BEHAVIOR.SHOW_ANALYSIS_STATUS),
		},
		{
			id: THUMBNAIL_BEHAVIOR.HIGHLIGHT_PENDING_CONTENT,
			name: t('content_management.action_bar.highlight_content'),
			click: () => handleChipOption(THUMBNAIL_BEHAVIOR.HIGHLIGHT_PENDING_CONTENT),
		},
		{
			id: THUMBNAIL_BEHAVIOR.CLEAR_ALL_RECENTLY_STATUS,
			name: t('content_management.action_bar.clear_all_recently'),
			click: handleClearMark,
			hidden: !handleClickMarkAll,
		},
	];

	const menuActionsDropdown = () => (
		<Menu>
			{actionsThumbnailChip.map((item: ActionsTypes) => {
				if (item?.hidden) {
					return null;
				}
				return (
					<Menu.Item key={item?.id} onClick={item.click}>
						<span>{item.name}</span>
						{thumbnailBehavior.includes(item?.id?.toString()) && (
							<StyledIcon className="icon-check">
								<IconCheck />
							</StyledIcon>
						)}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	return (
		<StyledDropdown
			overlayClassName="dropdown_chip_option"
			onVisibleChange={(visible) => {
				setVisibleChipOption(visible);
			}}
			overlay={menuActionsDropdown}
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
			placement="bottomLeft"
			trigger={['click']}>
			<StyledBtn
				visible={visibleChipOption}
				className="btn-chip"
				icon={<IconChip />}
				type="ghost"
			/>
		</StyledDropdown>
	);
};

export default ThumbnailBehavior;
