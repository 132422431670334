import { IconArrowLine } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { StyledIcon, StyledWrapperIconAnimation } from '@styled/Common/CommonStyled';
import React from 'react';
import styled from 'styled-components';

type PropTypes = {
	[other: string]: any;
	rotate?: number;
};

const ErrorValidateIcon = (props: PropTypes) => {
	const { className = '', rotate = 180, ...other } = props;
	return (
		<StyledWrapperIconAnimation className={`icon_position ${className}`} {...other}>
			<StyledIcon fillPath={THEME.colors.orangeBase} rotate={rotate}>
				<IconArrowLine />
			</StyledIcon>
		</StyledWrapperIconAnimation>
	);
};

export default ErrorValidateIcon;
