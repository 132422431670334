import { SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import { SPECIAL_CHARS } from '@constants/settings';
import {
	CAPTION_SENTIMENT_TYPE,
	CREATOR_TYPE_SETTING,
	DEFAULT_FORM_SETTING,
	LIST_CAPTION_SENTIMENT,
	LIST_SOURCE,
	NAME_SETTING_FEED,
	OPTION_ALL,
	SOURCE_TYPE,
	STATUS_SETTING,
} from '@constants/settings/socialAggregator';
import { OPERATOR, SOCIAL, SOCIAL_PROFILE } from '@constants/social_search';
import { ContentDto } from '@models/content/contentLibrary/summary';
import {
	FeedDto,
	FieldFormSettingFeed,
	ParamDataRequestFeed,
} from '@models/settings/socialSearch/socialAggregator/summary';
import { ImportSocialContentPayloadDto } from '@models/socialsearch/store';
import {
	SocialCardDto,
	SocialContentContainerDto,
	SocialContentFiltersDto,
} from '@models/socialsearch/summary';
import { concat } from 'lodash';
import { getContentByType, getSocialS3URL } from './common';
import { convertSocialUsername } from './funcHelper';

const isHashtag = (value: string) => value.charAt(0) === SPECIAL_CHARS.HASH;

const isUsername = (value: string) => value.charAt(0) === SPECIAL_CHARS.AT;

const formatSocialSearchPayload = (
	importSocialContentPayload: ImportSocialContentPayloadDto,
	payload: SocialContentFiltersDto,
) => {
	if (payload) {
		Object.keys(payload).forEach((key: string) => {
			if (
				payload[key] === 0 ||
				(payload[key] &&
					((typeof payload[key] === 'object' && payload[key].length > 0) ||
						typeof payload[key] === 'string' ||
						typeof payload[key] === 'number'))
			) {
				importSocialContentPayload[key] = payload[key];
			}
		});
	}
};

const getSearchTerms = (clientSettings: any, activeType?: string) => {
	const subhashtags =
		clientSettings.subHashtags && clientSettings.subHashtags.length > 0
			? clientSettings.subHashtags.join(',#').split(',')
			: [];
	if (subhashtags.length > 0) {
		subhashtags[0] = `#${subhashtags[0]}`;
		subhashtags.sort((a: any, b: any) => a.toLowerCase().localeCompare(b.toLowerCase()));
	}

	let mentionUsernamesSource = [];
	if (activeType === SOCIAL.INSTAGRAM.toLowerCase()) {
		mentionUsernamesSource = clientSettings.instagramMentionUsernames;
	} else if (activeType === SOCIAL.TWITTER.toLowerCase()) {
		mentionUsernamesSource = clientSettings.twitterMentionUsernames;
	} else if (activeType === SOCIAL.TIKTOK.toLowerCase()) {
		mentionUsernamesSource = [];
	}
	const mentionUsernames =
		mentionUsernamesSource?.length > 0 ? mentionUsernamesSource.join(',@').split(',') : [];
	if (mentionUsernames.length > 0) {
		mentionUsernames[0] = `@${mentionUsernames[0]}`;
		mentionUsernames.sort((a: any, b: any) => a.toLowerCase().localeCompare(b.toLowerCase()));
	}

	const otherArr = clientSettings.hashtags ? [`#${clientSettings.hashtags}`] : [];

	const result = concat(otherArr, subhashtags, mentionUsernames);

	return result;
};

const renderUrlSocialThumbnail = (content: SocialContentContainerDto | SocialCardDto) => {
	const lowcaseSocialSource = content?.socialSource.toLowerCase();
	return getSocialS3URL(
		lowcaseSocialSource === SOCIAL_SOURCE.TIKTOK.toLowerCase() ||
			lowcaseSocialSource === SOCIAL_SOURCE.YOUTUBE.toLowerCase()
			? content.dynamicThumbnail
			: content.thumbnail,
	);
};

const convertSocialId = (social: SocialContentContainerDto) => {
	let result = `${social.id}_${social.mediaId}`;
	if (social?.content) {
		const item: ContentDto = getContentByType(social.content);
		result += `_${item.id}`;
	}
	return result;
};

const goToSocialNewTab = (socialUsername?: string, socialSource?: string) => {
	let link: string = '';

	if (socialSource === SOCIAL.INSTAGRAM.toLocaleLowerCase()) {
		link = `${SOCIAL_PROFILE.INSTAGRAM}${socialUsername}/`;
	} else if (socialSource === SOCIAL.TWITTER.toLocaleLowerCase()) {
		link = `${SOCIAL_PROFILE.TWTTER}${socialUsername}`;
	} else if (socialSource === SOCIAL.TIKTOK.toLocaleLowerCase()) {
		link = `${SOCIAL_PROFILE.TIKTOK}${socialUsername}?lang=en`;
	} else if (socialSource === SOCIAL.YOUTUBE.toLocaleLowerCase()) {
		link = `${SOCIAL_PROFILE.YOUTUBE}${socialUsername}`;
	}

	if (link) {
		window.open(link, '_blank');
	}
};

const processRequestValues = (
	values: FieldFormSettingFeed,
	detailFeed: FeedDto | null,
	isActive: boolean,
	typeIncluded: string,
) => {
	const { searchTerms, sources, captionSentiments, usernameConjunctionType, creators, ...other } =
		values;

	const dataRequest: ParamDataRequestFeed & any = {
		...other,
		status: isActive ? STATUS_SETTING.LIVE : STATUS_SETTING.DISABLED,
		queryOperator: typeIncluded || OPERATOR.OR,
		sources: sources.filter((item) => item !== OPTION_ALL),
		captionSentiments: captionSentiments.filter((item) => item !== OPTION_ALL),
		excludedUsernames: [],
		includedUsernames: [],
		usernameConjunctionType,
	};

	if (searchTerms) {
		dataRequest.hashtags = [];
		dataRequest.mentionUsernames = [];
	}

	const convertTypeUsername = (list: string[]) => {
		return list.map((item) => ({
			username: item,
			sources: null,
		}));
	};
	if (detailFeed) {
		dataRequest.excludedUsernames = detailFeed.excludedUsernames;
		dataRequest.includedUsernames = detailFeed.includedUsernames;
	}

	switch (usernameConjunctionType) {
		case CREATOR_TYPE_SETTING.EXCLUDE:
			dataRequest.excludedUsernames = convertTypeUsername(creators || []);
			break;
		case CREATOR_TYPE_SETTING.INCLUDE:
			dataRequest.includedUsernames = convertTypeUsername(creators || []);
			break;
		default:
			break;
	}

	if (searchTerms) {
		const valueTerms = searchTerms?.slice(1) || '';

		if (searchTerms[0] === SPECIAL_CHARS.AT) {
			dataRequest.mentionUsernames = [valueTerms];
		} else {
			dataRequest.hashtags = [valueTerms];
		}
	}

	return { ...dataRequest };
};

const optimizeRequestPayload = (requestPayload: any) => {
	const newRequestPayload = { ...requestPayload };
	const { sources, captionSentiments } = newRequestPayload || {};

	if (sources && sources.includes(OPTION_ALL)) {
		newRequestPayload.sources = sources.filter((item: string) => item !== OPTION_ALL);
	}

	if (captionSentiments) {
		if (captionSentiments.includes('')) {
			newRequestPayload.captionSentiments = captionSentiments.filter(
				(item: string) => item !== '',
			);
		}
		if (!captionSentiments.includes(CAPTION_SENTIMENT_TYPE.UNDETERMINED)) {
			newRequestPayload.captionSentiments.push(CAPTION_SENTIMENT_TYPE.UNDETERMINED);
		}
	}
	return { ...newRequestPayload };
};

const initialFormValues = (val: FeedDto, form: any) => {
	let sourceTypes: string[] = DEFAULT_FORM_SETTING.sources;
	let captionTypes: string[] = DEFAULT_FORM_SETTING.captionSentiments;

	const {
		captionSentiments,
		excludedKeywords,
		hashtags,
		includedTerms,
		languages,
		mediaType,
		mentionUsernames,
		sources,
		usernameConjunctionType,
		excludedUsernames,
		includedUsernames,
		analysisLanguage,
	} = val;
	if (sources.length < LIST_SOURCE.length - 1) {
		sourceTypes = sources;
	}

	if (captionSentiments.length < LIST_CAPTION_SENTIMENT.length - 1) {
		captionTypes = captionSentiments;
	}
	const otherObj: any = {};
	if (usernameConjunctionType === CREATOR_TYPE_SETTING.EXCLUDE) {
		otherObj.creators = convertSocialUsername(excludedUsernames);
	}
	if (usernameConjunctionType === CREATOR_TYPE_SETTING.INCLUDE) {
		otherObj.creators = convertSocialUsername(includedUsernames);
	}

	const valSearchTerms =
		hashtags.length > 0
			? `${SPECIAL_CHARS.HASH}${hashtags[0]}`
			: `${SPECIAL_CHARS.AT}${mentionUsernames[0]}`;

	if (!captionTypes.includes(CAPTION_SENTIMENT_TYPE.UNDETERMINED)) {
		captionTypes.push(CAPTION_SENTIMENT_TYPE.UNDETERMINED);
	}

	form.setFieldsValue({
		captionSentiments: captionTypes,
		excludedKeywords,
		includedTerms,
		languages,
		mediaType,
		sources: sourceTypes,
		usernameConjunctionType,
		...otherObj,
		[NAME_SETTING_FEED.SEARCH_TERMS]: valSearchTerms,
		[NAME_SETTING_FEED.INCLUDE_POSTS_NOT_ANALYZED]: analysisLanguage,
		[NAME_SETTING_FEED.POST_DATE]: null,
	});
	const disabledField = [];
	if (sources?.length === 1) {
		disabledField.push(NAME_SETTING_FEED.SOURCES);
	}
	if (captionSentiments?.length === 1) {
		disabledField.push(NAME_SETTING_FEED.CAPTION_SENTIMENT);
	}

	return {
		disabledField,
		usernameConjunctionType,
		sourceTypes,
		captionTypes,
	};
};

const isAllSources = (sources: string[]) => {
	return [
		SOURCE_TYPE.YOUTUBE,
		SOURCE_TYPE.TIKTOK,
		SOURCE_TYPE.INSTAGRAM,
		SOURCE_TYPE.TWITTER,
	].every((type) => sources.includes(type));
};

export {
	convertSocialId,
	formatSocialSearchPayload,
	getSearchTerms,
	goToSocialNewTab,
	initialFormValues,
	isAllSources,
	isHashtag,
	isUsername,
	optimizeRequestPayload,
	processRequestValues,
	renderUrlSocialThumbnail,
};
