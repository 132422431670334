/**
 * @prettier
 */

import { Spin } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import Wrapper from '../Wrapper';

const StyledContainer = styled(Wrapper)<{ height?: string }>`
	position: relative;
	width: 100%;
	${({ height }) => height && `height: ${height}`};

	& > span {
		margin: auto;
		display: block;
		font-size: 30px;
	}
`;

const StyledLoading = styled.div<{ isLoading?: boolean; bgColorLoading?: string; zIndex?: number }>`
	display: ${({ isLoading }) => (isLoading === true ? 'flex' : 'none')};
	z-index: ${({ zIndex }) => zIndex || 9999};
	width: 100%;
	height: 100%;
	background-color: ${({ bgColorLoading }) => bgColorLoading || 'rgba(32, 50, 65, 0.1)'};
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	align-items: center;
	justify-content: center;
`;

type LoadingProps = {
	children?: any;
	isLoading?: boolean;
	sizeLoading?: any;
	heightLoading?: string;
	bgColorLoading?: string;
	zIndex?: number;
};

const LoadingWrapper = ({
	children,
	isLoading,
	sizeLoading,
	bgColorLoading,
	heightLoading,
	zIndex,
}: LoadingProps) => {
	return (
		<StyledContainer height={heightLoading}>
			<StyledLoading zIndex={zIndex} bgColorLoading={bgColorLoading} isLoading={isLoading}>
				<Spin size={_.isUndefined(sizeLoading) ? 'large' : sizeLoading} />
			</StyledLoading>
			{children}
		</StyledContainer>
	);
};

LoadingWrapper.defaultProps = {
	isLoading: true,
	sizeLoading: 'large',
};

export default LoadingWrapper;
