import React, { useEffect, useRef, useState } from 'react';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	StyledWrapper,
	StyledTitle,
	StyledForm,
	StyledWrapperBox,
	StyledInput,
	StyledButton,
	StyledFlex,
	StyledFormItem,
	StyledTextUploadCSV,
} from '@styled/Settings/SocialSearchStyled';
import { ExcludeSearchTermsDto } from '@models/settings/socialSearch';
import { IconAdd } from '@assets/icons';
import HashTag from '@components/Settings/HashTag';
import { SettingRootStateDto } from '@models/settings/setting';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmModal, LoadingWrapper } from '@cores/index';
import { modifyBadWords, replaceBlockTermsEnd, replaceBlockTermsRequest } from '@stores/actions';
import { STATUS } from '@constants/settings';
import { StyledUpload } from '@styled/Common/CommonStyled';
import { TYPE_CSV } from '@constants/common';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';

const ExcludedSearchTerms: React.FC = () => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const refInput = useRef<any>(null);
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isAddOrDelete, setIsAddOrDelete] = useState<string>('');
	const [tagId, setTagId] = useState<number>(-1);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		badWordList = [],
		loadingBadWord = false,
		statusCode = null,
		replaceBlockTermsStatus,
	} = useSelector((state: SettingRootStateDto) => state.settings.socialSearch);

	useEffect(() => {
		popupMessage();
	}, [loadingBadWord]);

	useEffect(() => {}, [form.resetFields()]);

	useEffect(() => {
		if (replaceBlockTermsStatus === IN_PROGRESS) {
			setIsLoading(true);
		}

		if (replaceBlockTermsStatus === SUCCEEDED) {
			message.success(t('setting.message.message_replace_block_keyword'));
		}
		if (replaceBlockTermsStatus === SUCCEEDED || replaceBlockTermsStatus === FAILED) {
			dispatch(replaceBlockTermsEnd());
			setIsLoading(false);
		}
	}, [replaceBlockTermsStatus]);

	const popupMessage = () => {
		if (statusCode === 200 && isAddOrDelete) {
			resetFields();
			if (isAddOrDelete === STATUS.ADD) {
				focusInput();

				message.success(t('setting.message.added_badword'));
			} else {
				message.success(t('setting.message.deleted_badword'));
			}
			setIsAddOrDelete('');
		}
	};

	const focusInput = () => {
		setTimeout(() => {
			if (refInput?.current?.focus) {
				refInput.current.focus();
			}
		}, 0);
	};

	const onFinish = ({ badWord = '' }: ExcludeSearchTermsDto) => {
		setIsAddOrDelete(STATUS.ADD);
		const badWords = [badWord, ...badWordList];
		dispatch(modifyBadWords(badWords));
	};

	const resetFields = () => {
		form.resetFields();
	};

	const checkDuplicateFolder = (value: string) => {
		const result = badWordList.some((word: string) => word === value);

		return result;
	};

	const renderExcludeTerms = () => (
		<StyledForm
			form={form}
			onFinish={onFinish}
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}>
			<StyledFlex>
				<StyledFormItem
					rules={[
						{
							required: true,
							message: t('setting.validation.please_input_badWord'),
						},
						() => ({
							validator(_: any, value: string) {
								if (!value || !checkDuplicateFolder(value)) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error(t('setting.validation.duplicated')),
								);
							},
						}),
					]}
					label={t('setting.socical_search_settings.terms_to_exclude')}
					name="badWord">
					<StyledInput
						ref={refInput}
						placeholder={t('setting.socical_search_settings.add_bad_word_placeholder')}
					/>
				</StyledFormItem>
				<StyledFormItem width="fit-content">
					<StyledButton htmlType="submit" icon={<IconAdd />} />
				</StyledFormItem>
			</StyledFlex>
		</StyledForm>
	);

	const renderHashTags = () => {
		const checkEmpty =
			(badWordList.length === 1 && badWordList[0] === '') || badWordList.length === 0;

		return (
			<StyledWrapperBox padding="12px 0" style={{ height: 'calc(100% - 115px)' }}>
				{!checkEmpty &&
					badWordList.map((word, index) => (
						<HashTag tagId={index} onClickTag={onClickTag} key={`${index + 0}`}>
							{word}
						</HashTag>
					))}
			</StyledWrapperBox>
		);
	};

	const onToggleOpenModal = (id: number = -1) => {
		setIsOpenModal(!isOpenModal);

		if (id > -1) {
			setTagId(id);
		} else {
			setTagId(-1);
		}
	};

	const onSubmitDelete = () => {
		if (tagId !== -1) {
			deleteExcludedSearchTerm(tagId);
		}
	};

	const renderConfirmModal = () => {
		return (
			<ConfirmModal
				width={400}
				okText={t('button.delete')}
				isOpen={isOpenModal}
				onSubmit={onSubmitDelete}
				onClose={onToggleOpenModal}>
				{t('setting.confirm.delete_badword')}
			</ConfirmModal>
		);
	};

	const deleteExcludedSearchTerm = (id: number) => {
		setIsAddOrDelete(STATUS.DELETE);
		const wordRemove = badWordList[id];
		const badWords = badWordList?.filter((item) => item !== wordRemove);
		dispatch(modifyBadWords(badWords));
		setIsOpenModal(false);
	};

	const onClickTag = (key: string, tagId: number) => {
		switch (key) {
			case 'delete':
				onToggleOpenModal(tagId);
				break;
			default:
				break;
		}
	};

	const handleChangeUploadFile = ({ fileList, file }: any) => {
		const formData = new FormData();
		formData.append('file', file);
		dispatch(replaceBlockTermsRequest(formData));
	};

	const renderUpload = () => {
		return (
			<StyledUpload
				showUploadList={false}
				maxCount={1}
				beforeUpload={() => false}
				accept={TYPE_CSV}
				onChange={handleChangeUploadFile}>
				<StyledTextUploadCSV>
					{t('setting.socical_search_settings.text_replace')}
				</StyledTextUploadCSV>
			</StyledUpload>
		);
	};

	return (
		<StyledWrapper style={{ height: '100%' }}>
			<StyledTitle fontSize="1.143rem" fontWeight="normal">
				{t('setting.socical_search_settings.exclude_search_terms')}
			</StyledTitle>
			<StyledWrapperBox style={{ height: 'calc(100% - 55px)' }}>
				<LoadingWrapper isLoading={loadingBadWord || isLoading}>
					{renderExcludeTerms()}
					{renderUpload()}
					{renderHashTags()}
				</LoadingWrapper>
			</StyledWrapperBox>
			{renderConfirmModal()}
		</StyledWrapper>
	);
};

export default ExcludedSearchTerms;
