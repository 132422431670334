import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButtonCopyWidgetURL, StyledContainer } from '@styled/Settings/ActionBarRightStyled';
import { useSelector } from 'react-redux';
import { Dropdown, Menu, message } from 'antd';
import { IconExpandMore } from '@assets/icons';
import { WidgetLinkDto } from '@models/common/summary';

type ActionBarProps = {};
const ActionBar = (props: ActionBarProps) => {
	const { t } = useTranslation();
	const { clientSettings } = useSelector((state: any) => state.sidebar);

	const { widgetLinks } = clientSettings;

	const onCopyWidgetURL = (val: any) => {
		const { key } = val;
		const widgetItem = widgetLinks?.find((item: WidgetLinkDto) => item?.id === +key);

		if (widgetItem) {
			const input = document.createElement('input');
			input.value = widgetItem?.url;
			document.body.appendChild(input);
			input.select();
			document.execCommand('Copy');
			input.remove();
			message.success(t('setting.message.copy_widget_success', { name: widgetItem?.name }));
		}
	};

	const menu = (
		<Menu onClick={onCopyWidgetURL}>
			{widgetLinks?.map((item: WidgetLinkDto) => (
				<Menu.Item key={item?.id}>{item?.name}</Menu.Item>
			))}
		</Menu>
	);

	return (
		<StyledContainer>
			<Dropdown overlay={menu} trigger={['click']}>
				<StyledButtonCopyWidgetURL onClick={onCopyWidgetURL}>
					{t('setting.sidebar_settings.copy_upload_widget_url')}
					<IconExpandMore />
				</StyledButtonCopyWidgetURL>
			</Dropdown>
		</StyledContainer>
	);
};

export default ActionBar;
