import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { StyledButton, StyledForm, StyledFormItem } from '@styled/Common/CommonStyled';
import { Avatar, Form, Input, message } from 'antd';
import {
	StyledFooterProfile,
	StyledGroupButton,
	StyledHeaderProfile,
} from '@styled/Settings/UserProfileStyled';
import { UserOutlined } from '@ant-design/icons';
import { getUser } from '@helpers/userHelpers';
import { updateProfile } from '@stores/actions';
import UserProfileContext from '@contexts/Settings/UserProfile';
import { PATTERNS } from '@constants/common';

const EditProfile = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const user = getUser();

	const { setIsLoading } = useContext(UserProfileContext);

	const onUpdateProfile = (values: any) => {
		const { accountId, clientId } = user;
		const params = {
			...values,
			id: accountId,
			clientId,
		};
		const payload = {
			params,
			onSucceeded,
			onFailed,
		};

		setIsLoading(true);
		dispatch(updateProfile(payload));
	};

	const onSucceeded = () => {
		setIsLoading(false);
		message.success(t('setting.account_user.message.save_success'));
	};

	const onFailed = (error: string) => {
		setIsLoading(false);
		message.error(error);
	};

	const resetData = () => {
		form.resetFields();
	};

	return (
		<React.Fragment>
			<StyledHeaderProfile>
				<Avatar size={90} icon={<UserOutlined />} />
			</StyledHeaderProfile>

			<StyledForm
				padding="24px 32px"
				layout="vertical"
				requiredMark={false}
				initialValues={user}
				form={form}
				onFinish={onUpdateProfile}
				autoComplete="off">
				<StyledFormItem
					label={t('register.first_name')}
					name="firstName"
					rules={[
						{
							required: true,
							message: t('setting.account_user.validation.required', {
								field: 'First Name',
							}),
						},
					]}>
					<Input />
				</StyledFormItem>

				<StyledFormItem
					label={t('register.last_name')}
					name="lastName"
					rules={[
						{
							required: true,
							message: t('setting.account_user.validation.required', {
								field: 'Last Name',
							}),
						},
					]}>
					<Input />
				</StyledFormItem>

				<StyledFormItem
					label={t('register.email')}
					name="email"
					rules={[
						{
							required: true,
							message: t('setting.account_user.validation.required', {
								field: 'Email',
							}),
						},
						{
							type: 'email',
							message: t('setting.account_user.validation.invalid_email'),
						},
					]}>
					<Input disabled />
				</StyledFormItem>
			</StyledForm>

			<StyledFooterProfile justify="flex-end">
				<StyledGroupButton justify="flex-end">
					<StyledButton onClick={resetData} margin="0 12px 0 0" width="87px" type="ghost">
						{t('button.reset')}
					</StyledButton>
					<StyledButton onClick={form.submit} width="87px" type="primary">
						{t('button.save')}
					</StyledButton>
				</StyledGroupButton>
			</StyledFooterProfile>
		</React.Fragment>
	);
};

export default EditProfile;
