import THEME from '@constants/themes/themes';
import { Layout, Menu } from 'antd';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Sidebars, { SidebarTypes } from '@constants/sidebar';

// Actions
import { NAME_NAVIGATION, ROUTE_PATH } from '@constants/common';
import { ROLE } from '@constants/settings';
import { getUser } from '@helpers/userHelpers';
import { fetchCollapseMenuRequest } from '@stores/actions';

const inlineIndentMenu = 18;
const heightMenuItem = 48;

const StyledLayoutSider = styled(
	({ isCollapsed, ...props }: { isCollapsed?: boolean; [other: string]: any }) => (
		<Layout.Sider {...props} />
	),
)`
	position: relative;
	flex: 0 0 208px;
	width: 208px;
	min-width: 208px;
	max-width: 208px;
	margin-top: 72px;

	.ant-menu.ant-menu-root {
		margin-bottom: 5rem;
	}

	.ant-layout-sider-children {
		width: inherit;
		position: fixed;
		overflow-y: auto;

		::-webkit-scrollbar-track {
			background: ${THEME.colors.darkBlue2};
		}

		.ant-menu-submenu {
			&:last-child {
				margin-bottom: 5rem;
				${({ isCollapsed }) => !isCollapsed && 'margin-top: 15px'};
			}
			.ant-menu-submenu-arrow {
				top: calc(50% + 2px);
			}
		}
		&::-webkit-scrollbar {
			width: 6px;
		}
		&:hover {
			&::-webkit-scrollbar-thumb {
				visibility: unset;
			}
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${THEME.colors.gray2};
			border-radius: 20px;
			visibility: hidden;
			border: 3px solid ${THEME.colors.gray2};
		}
	}
`;

const StyledNameSession = styled.div`
	height: ${heightMenuItem}px;
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	text-transform: uppercase;
	color: ${THEME.colors.gray1};
	padding: 0 ${inlineIndentMenu}px;
`;

const StyledSubMenu = styled(Menu.SubMenu)`
	.ant-menu-submenu-title {
		height: ${heightMenuItem}px !important;
		margin: 0 !important;
	}
`;

const StyledMenuItem = styled(Menu.Item)`
	margin: 0 !important;
	height: ${heightMenuItem}px !important;
`;

const Sidebar = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const history = useHistory();
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { ambassadorEnabled = false } = clientSettings || {};
	const user = getUser();
	const { role } = user;

	const listSubKey: string[] = useMemo(() => {
		const result: string[] = [];
		Sidebars?.forEach((item: SidebarTypes) => {
			if (item?.keyActive) {
				result?.push(item?.keyActive);
			}
			if (item?.child) {
				item?.child?.forEach((childItem: SidebarTypes) => {
					if (childItem?.keyActive) {
						result?.push(childItem?.keyActive);
					}
				});
			}
		});
		return result;
	}, [Sidebars]);

	const onRenderActiveKey = () => {
		if (
			pathname.includes(ROUTE_PATH.CREATOR_DETAIL_EMPTY) &&
			listSubKey?.includes(ROUTE_PATH.CREATORS_SUMMARY)
		) {
			return ROUTE_PATH.CREATORS_SUMMARY;
		}

		for (const item of listSubKey) {
			if (pathname?.includes(item)) {
				return item;
			}
		}
		return pathname;
	};

	const activeItem =
		pathname && pathname !== ROUTE_PATH.DEFAULT ? onRenderActiveKey() : ROUTE_PATH.DASHBOARD;

	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [openKey, setOpenKey] = useState<string[]>([]);

	useEffect(() => {
		if (activeItem === ROUTE_PATH.DASHBOARD) {
			setOpenKey([]);
		}
	}, [activeItem]);

	// Handle change
	const handleCollapsed = (collapsed: boolean, type: string) => {
		const payload = {
			collapsed,
		};
		setCollapsed(collapsed);
		dispatch(fetchCollapseMenuRequest(payload));
	};

	const onOpenChange = (openKeys: any) => {
		const latestOpenKey = openKeys.find((key: string) => openKey.indexOf(key) === -1);
		setOpenKey(latestOpenKey ? [latestOpenKey] : []);

		if (!onCheckRedirect(latestOpenKey)) {
			return;
		}

		switch (latestOpenKey) {
			case NAME_NAVIGATION.ACTIVATIONS:
				history.push(ROUTE_PATH.CAMPAIGNS);
				break;
			case NAME_NAVIGATION.SOCIAL_SEARCH:
				history.push(ROUTE_PATH.FEEDS);
				break;
			case NAME_NAVIGATION.CONTENT_LIBRARY:
				history.push(ROUTE_PATH.SUBMISSIONS);
				break;
			case NAME_NAVIGATION.CRM:
				history.push(
					ambassadorEnabled
						? ROUTE_PATH.AMBASSADOR_DASHBOARD
						: ROUTE_PATH.CREATORS_SUMMARY,
				);
				break;
			case NAME_NAVIGATION.MESSAGING:
				history.push(ROUTE_PATH.MESSAGE_LOG);
				break;
			case NAME_NAVIGATION.REWARDS:
				history.push(ROUTE_PATH.REWARDS_HISTORY);
				break;
			case NAME_NAVIGATION.SETTINGS:
				history.push(ROUTE_PATH.SETTINGS_ACCOUNT_USERS);
				break;
			default:
				break;
		}
	};

	const handleSidebar = (selectedItem: any) => {
		if (!_.isEmpty(selectedItem)) {
			const { keyPath } = selectedItem;
			if (keyPath.length === 1) {
				setOpenKey([]);
			}
		}
	};

	const handleTitleClick = ({ key }: any) => {
		onOpenChange([key]);
	};

	const onCheckRedirect = (name: string) => {
		const subMenuItem = Sidebars?.find((side) => side?.name === name);

		if (
			subMenuItem?.child &&
			subMenuItem?.child?.some((item) => pathname?.includes(item?.path))
		) {
			return false;
		}
		return true;
	};

	const handleOpenSubMenu = () => {
		Sidebars?.map((side) => {
			if (side?.child) {
				side?.child?.map((item) => {
					if (pathname?.includes(item?.path)) {
						setOpenKey([side?.name]);
					}
				});
			}
		});
	};

	useEffect(() => {
		handleOpenSubMenu();
	}, [pathname]);

	const customProps = collapsed
		? undefined
		: {
				openKeys: openKey,
				onOpenChange,
		  };

	const isHiddenSubMenu = (item: SidebarTypes) => {
		return (
			item.hidden ||
			(item.name === NAME_NAVIGATION.AMBASSADOR_DASHBOARD &&
				!clientSettings?.ambassadorEnabled) ||
			((item.name === NAME_NAVIGATION.LANDING_PAGES ||
				item.name === NAME_NAVIGATION.SETTING_GUEST_PORTALS) &&
				role !== ROLE.EREVIEWER.value)
		);
	};

	return (
		<StyledLayoutSider
			className="sidebar-menu"
			isCollapsed={collapsed}
			collapsible
			onCollapse={handleCollapsed}>
			<Menu
				selectedKeys={[activeItem]}
				mode="inline"
				{...customProps}
				inlineIndent={inlineIndentMenu}
				onSelect={handleSidebar}>
				{Sidebars &&
					Sidebars.length > 0 &&
					Sidebars.map(
						({ name, path, ico, child, keyActive, isSession }: SidebarTypes) => {
							const hasSub = child && child.length > 0;

							if (isSession) {
								if (collapsed) {
									return null;
								}
								return <StyledNameSession key={name}>{t(name)}</StyledNameSession>;
							}

							if (hasSub) {
								return (
									<StyledSubMenu
										key={`${path}${name}`}
										icon={ico}
										title={t(name)}
										onTitleClick={handleTitleClick}>
										{child &&
											child.length > 0 &&
											child.map((item: SidebarTypes) => {
												let name = item.name;
												const isHidden = isHiddenSubMenu(item);

												if (isHidden) {
													return null;
												}

												if (item.name === NAME_NAVIGATION.LANDING_PAGES) {
													name = 'sidebar.landing_pages';
												}

												return (
													<StyledMenuItem
														style={{
															pointerEvents:
																item?.keyActive === activeItem ||
																item.path === activeItem
																	? 'none'
																	: 'unset',
														}}
														key={item?.keyActive || item.path}>
														<Link to={item.path}>{t(name)}</Link>
													</StyledMenuItem>
												);
											})}
									</StyledSubMenu>
								);
							}

							return (
								<StyledMenuItem
									style={{
										pointerEvents:
											keyActive === activeItem || path === activeItem
												? 'none'
												: 'unset',
									}}
									key={keyActive || path}
									icon={ico}>
									<Link to={path}>{t(name)}</Link>
								</StyledMenuItem>
							);
						},
					)}
			</Menu>
		</StyledLayoutSider>
	);
};

export default Sidebar;
