import { MOVE_CONTENT } from '@constants/content/contentLibrary';
import FeedsManagerContext from '@contexts/SocialSearch/Feeds';
import { SocialContentStoreDto } from '@models/socialsearch/store';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { StyledContainerSummary } from '@styled/SocialSearch/Feeds';
import { checkPositionContent } from '@utils/funcHelper';
import { Col, Row } from 'antd';
import {
	Ref,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelectable } from 'react-selectable-fast';
import CardSocial from './CardSocial';
import ModalMediaDetail from '@components/ModalCustom/ModalMediaDetail';
import { ContentContainerDto } from '@models/content/contentLibrary/summary';
import { getRequest } from '@helpers/requestHelpers';
import { CONTENT_MEDIA_API } from '@constants/APIs';
import { convertSocialId } from '@utils/socialSearch';
import { RefSummaryFeed } from '@models/socialsearch/ref';
import { COL_MEDIA } from '@constants/ambassador';
import { useWindowSize } from '@utils/customHooks';

const SelectableItem = createSelectable(CardSocial);

type PropTypes = {};

const SummaryFeed = forwardRef((props: PropTypes, ref: Ref<RefSummaryFeed>) => {
	const { detailFeed }: SocialContentStoreDto = useSelector((state: any) => state.socialContent);
	const {
		typeCollapse,
		socialFeedList,
		onSelectItems,
		socialDetail,
		setSocialDetail,
		col,
		fullThumb,
		setFullThumb,
	} = useContext(FeedsManagerContext);
	const refSocial = useRef<any>({});
	const [heightCard, setHeightCard] = useState<number>(0);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [isFirstContent, setIsFirstContent] = useState<boolean>(false);
	const [isLastContent, setIsLastContent] = useState<boolean>(false);

	const [widthWindow, heightWindow] = useWindowSize();

	useImperativeHandle(ref, () => ({
		handleChangeContentSelect,
	}));

	const handleContentDetails = (index: number) => {
		setCurrentIndex(index);

		const res = checkPositionContent(index, socialFeedList.length);
		setIsFirstContent(res.isFirst);
		setIsLastContent(res.isLast);
	};

	const handleChangeContentSelect = (val: SocialContentContainerDto | null) => {
		if (!val) {
			setCurrentIndex(0);
			setIsFirstContent(false);
			setIsLastContent(false);
			setSocialDetail(null);
		} else {
			selectContent(val);
		}
	};

	const selectContent = (social: SocialContentContainerDto) => {
		setSocialDetail(social);
		handleContentDetails(
			socialFeedList.findIndex((item) => convertSocialId(item) === convertSocialId(social)),
		);
	};

	const moveContent = (action: string, changeIndex?: boolean) => {
		let currentIndexTemp: number = currentIndex;

		const maxContentIndex = socialFeedList.length - 1;

		if (action === MOVE_CONTENT.NEXT) {
			currentIndexTemp = currentIndex + 1;
		}
		if (action === MOVE_CONTENT.PREVIOUS) {
			currentIndexTemp = currentIndex - 1;
		}

		if (currentIndexTemp <= 0) {
			currentIndexTemp = 0;
		}

		if (currentIndexTemp >= maxContentIndex) {
			currentIndexTemp = maxContentIndex;
		}

		if (changeIndex) {
			setCurrentIndex(currentIndexTemp);
		}

		selectContent(socialFeedList[currentIndexTemp]);
	};

	const updateMediaImgUrl = (id: number, src: string, idConvert: string) => {
		socialFeedList.map((data: any, index: number) => {
			if (convertSocialId(data) === idConvert) {
				data.imgUrl = src;
				setSocialDetail({ ...data });
			}
		});
	};

	const handleSetWidthContent = () => {
		if (socialFeedList.length > 0 && refSocial?.current?.offsetWidth) {
			// exclude padding and margin
			const scale = typeCollapse ? 1.8 : 2;

			setHeightCard(
				Math.floor(
					refSocial.current.offsetWidth / (col === COL_MEDIA.C6 ? 1.2 : scale) - 20,
				),
			);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			handleSetWidthContent();
		}, 400);
	}, [typeCollapse, socialFeedList, col]);

	useEffect(() => {
		handleSetWidthContent();
	}, [widthWindow]);

	useEffect(() => {
		if (col === COL_MEDIA.C6) {
			setFullThumb(true);
		} else {
			setFullThumb(!!typeCollapse);
		}
	}, [typeCollapse]);

	return (
		<StyledContainerSummary>
			<ModalMediaDetail
				socialProps={{
					idFeed: detailFeed?.id,
					socialContent: socialDetail,
					activeSocialType: socialDetail?.socialSource?.toLowerCase() || '',
					updateMediaImgUrl,
				}}
				isFirstContent={isFirstContent}
				isLastContent={isLastContent}
				moveContent={moveContent}
				visible={!!socialDetail}
				onCancel={() => setSocialDetail(null)}
				isSocial={true}
			/>
			<Row gutter={[12, 12]}>
				{socialFeedList.map((item: SocialContentContainerDto) => (
					<Col
						ref={(element) => {
							refSocial.current = element;
						}}
						key={convertSocialId(item)}
						span={24 / col}>
						{heightCard > 0 && (
							<SelectableItem
								selectContent={selectContent}
								heightCard={heightCard}
								social={item}
								keyItem={convertSocialId(item)}
								thumbFull={!!fullThumb}
								onSelectItems={onSelectItems}
								id={convertSocialId(item)}
							/>
						)}
					</Col>
				))}
			</Row>
		</StyledContainerSummary>
	);
});

export default SummaryFeed;
