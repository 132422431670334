import { Viewer, Worker } from '@react-pdf-viewer/core';
import React from 'react';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

type PropTypes = {
	data: string;
	fileName: string;
};
const PdfView = (props: PropTypes) => {
	const { data, fileName } = props;
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		renderToolbar: () => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>,
	});

	const toolbarPluginInstance = toolbarPlugin({
		getFilePlugin: {
			fileNameGenerator: (file: any) => {
				return fileName;
			},
		},
	});
	const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
	const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
		...slot,
		Open: () => <></>,
	});
	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
			<Viewer plugins={[defaultLayoutPluginInstance, toolbarPluginInstance]} fileUrl={data} />
		</Worker>
	);
};

export default PdfView;
