import { FILTER_BY, SYSTEM_FOLDER_ALL } from '@constants/content/contentLibrary';
import { PARAMS_DEFAULT_CONTENT } from '@constants/publish/gallery';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import { ParamRequestContentDto } from '@models/publishing/galleries';
import { fetchContentRequest } from '@stores/actions';
import { StyledSearch } from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledEmpty, StyledWrapper } from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledAction,
	StyledUpdateListContentModal,
	StyledVerifyContent,
	StyledWrapperAction,
	StyledWrapperListContent,
} from '@styled/Publish/Gallery/UpdateListContentModalStyled';
import { getContentByType, getSocialS3URL, handleConvertGifToPng } from '@utils/common';
import { Button, Col, message, Row, Tabs } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSelectable } from 'react-selectable-fast';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { renderThumbVertical } from '@utils/renderComponent';
import { ConfirmModal } from '@cores/Modal';
import ThemeItem from './ThemeItem';
import { StyledTabs, StyledText, StyledTitle } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { FolderItemDto } from '@models/common/summary';
import { TYPE_ALBUM } from '@constants/content/albums';
import { ContentImgDto } from '@models/campaign';
import { TYPE_FILE } from '@constants/common';

const { TabPane } = Tabs;

type PropsChangeListContentModal = {
	[other: string]: any;
	visible: boolean;
	onCancel: () => void;
	idSelect?: number;
	onSaveContent: (val: ContentImgDto | null) => void;
};

const ContentListModal = (props: PropsChangeListContentModal) => {
	const { visible, onCancel, idSelect, onSaveContent, ...other } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const scrollbars = useRef<any>({});

	const { fetchingContent, content } = useSelector((state: any) => state.content);
	const { folderSystem = [] } = useSelector((state: any) => state.sidebar);

	const favoriteAlbum = folderSystem?.find(
		(item: FolderItemDto) => item?.type.toLowerCase() === TYPE_ALBUM.FAVORITE_ALBUM,
	);

	const [searchValue, setSearchValue] = useState<string>('');
	const [listContent, setListContent] = useState<ContentDto[]>([]);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
	const [contentSelectId, setContentSelectId] = useState<number | undefined>(idSelect);

	const defaultParamContent = useMemo(() => {
		return {
			...PARAMS_DEFAULT_CONTENT,
			verified: true,
			customTerms: true,
			search: '',
			folder: SYSTEM_FOLDER_ALL.id,
		};
	}, []);

	const handleConvertImg = (url: string) => {
		const timestamp = new Date().getTime();
		return url.includes('?') ? `${url}&v=${timestamp}` : `${url}?v=${timestamp}`;
	};

	const contentSelected = useMemo(() => {
		const valueSelect = listContent?.find((item) => item?.id === contentSelectId);

		if (contentSelectId && valueSelect) {
			return {
				id: valueSelect?.id,
				urlBase: handleConvertImg(handleConvertGifToPng(valueSelect?.thumbnailBase)),
				url: handleConvertImg(handleConvertGifToPng(valueSelect?.thumbnail)),
			};
		}
		return null;
	}, [contentSelectId, listContent]);

	const listTabContent = useMemo(
		() => [
			{ value: SYSTEM_FOLDER_ALL.id, title: t('content_detail.rights_granted') },
			{ value: favoriteAlbum?.id, title: t('gallery_manager.modal.favorites') },
		],
		[folderSystem],
	);

	const [paramContent, setParamContent] = useState<ParamRequestContentDto>({
		...defaultParamContent,
	});

	const onSaveChange = () => {
		onSaveContent(contentSelected);
		handleClose();
	};

	const onPressSearch = (value: string) => {
		handleClearFormBeforeSearch();

		setParamContent((prev) => ({ ...prev, search: value || '' }));
	};

	const onChangeSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(evt.target.value);
	};

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			if (scrollbarTop >= 0.75 && content?.length > 0) {
				setParamContent((prev) => ({ ...prev, page: prev.page + 1 }));
			}
		}
	};

	const formatListContent = (list: ContentContainerDto[]) => {
		return list.map((data: any, index: number) => {
			const contentTemp: ContentDto = getContentByType(data);
			const thumbnail: string = getSocialS3URL(contentTemp.thumbnail);
			return { ...contentTemp, thumbnail, thumbnailBase: contentTemp.thumbnail };
		});
	};

	const handleSaveChange = () => {
		setVisibleConfirm(false);
	};

	const handleClearFormBeforeSearch = () => {
		setListContent([]);
	};

	const handleClearForm = () => {
		handleClearFormBeforeSearch();

		setParamContent({ ...defaultParamContent });
		setSearchValue('');
		setContentSelectId(undefined);
	};

	const handleClose = () => {
		handleClearForm();
		onCancel();
	};

	const renderContentPopupConfirm = () => {
		return (
			<>
				{t('gallery_manager.message.message_publish_content_gallery')}
				<br />
				{t('gallery_manager.message.quest_publish_content_gallery')}
			</>
		);
	};

	useEffect(() => {
		if (content && !fetchingContent) {
			const newContentFormat = formatListContent(content);
			if (paramContent?.page === 1) {
				setListContent(newContentFormat);
			} else {
				setListContent([...listContent, ...newContentFormat]);
			}
		}
	}, [content]);

	useEffect(() => {
		if (visible) {
			dispatch(fetchContentRequest(paramContent));
		}
	}, [paramContent, visible]);

	useEffect(() => {
		if (visible) {
			setContentSelectId(idSelect);
		}
	}, [visible]);

	const renderTabName = (item: any) => {
		const { title, value } = item;
		return (
			<StyledTitle>
				<StyledText
					fontWeight={`${value === paramContent?.folder ? 'bold' : 'normal'}`}
					fontSize="1rem">
					{title}
				</StyledText>
			</StyledTitle>
		);
	};

	return (
		<>
			<StyledUpdateListContentModal
				{...other}
				visible={visible}
				centered={true}
				width={'calc(100% - 300px)'}
				onCancel={handleClose}
				onOk={onSaveChange}
				okButtonProps={{
					disabled: idSelect === contentSelectId || !contentSelectId,
					loading: isLoading,
				}}
				okText={t('gallery_manager.button.save_change')}
				title={t('campaign_detail.add_from_library')}>
				<LoadingWrapper
					bgColorLoading={'transparent'}
					isLoading={fetchingContent || isLoading}>
					<StyledTabs
						activeKey={paramContent?.folder}
						className="custom-tabs"
						onTabClick={(tab: string) => {
							setParamContent({ ...paramContent, folder: tab, search: '' });
							setSearchValue('');
						}}>
						{listTabContent.map((item: any) => (
							<TabPane tab={renderTabName(item)} key={item.value} closable={false}>
								<StyledWrapperAction>
									<StyledSearch
										width={'250px'}
										className="search-custom"
										placeholder={t(
											'gallery_manager.modal.place_holder_search_content',
										)}
										onSearch={onPressSearch}
										onChange={onChangeSearch}
										enterButton
										value={searchValue}
									/>
								</StyledWrapperAction>
								<StyledWrapperListContent>
									{listContent.length > 0 ? (
										<Scrollbars
											className="scrollbar_custom_wrapper"
											renderTrackVertical={() => <div />}
											ref={(el) => {
												scrollbars.current = el;
											}}
											onScrollStop={onScrollStop}
											style={{ height: '100%' }}
											renderThumbVertical={renderThumbVertical}>
											<Row style={{ margin: 0 }} gutter={[12, 12]}>
												{listContent?.map((item: ContentDto) => (
													<Col key={item?.id} span={4}>
														<ThemeItem
															onClick={() =>
																setContentSelectId(item?.id)
															}
															isExist={item?.id === contentSelectId}
															content={item}
														/>
													</Col>
												))}
											</Row>
										</Scrollbars>
									) : (
										!fetchingContent && (
											<StyledWrapper marginTop={'0'}>
												<StyledEmpty image={IconEmpty} />
											</StyledWrapper>
										)
									)}
								</StyledWrapperListContent>
							</TabPane>
						))}
					</StyledTabs>
				</LoadingWrapper>
			</StyledUpdateListContentModal>
		</>
	);
};

export default ContentListModal;
