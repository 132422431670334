export const CampaignTypes = {
	GET_CAMPAIGN_LIST: 'GET_CAMPAIGN_LIST',
	GET_CAMPAIGN_LIST_SUCCESS: 'GET_CAMPAIGN_LIST_SUCCESS',
	GET_CAMPAIGN_LIST_FAILED: 'GET_CAMPAIGN_LIST_FAILED',

	CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
	CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
	CREATE_CAMPAIGN_FAILED: 'CREATE_CAMPAIGN_FAILED',
	CREATE_CAMPAIGN_ENDED: 'CREATE_CAMPAIGN_ENDED',

	UPDATE_CAMPAIGN_REQUEST: 'UPDATE_CAMPAIGN_REQUEST',
	UPDATE_CAMPAIGN_SUCCESS: 'UPDATE_CAMPAIGN_SUCCESS',
	UPDATE_CAMPAIGN_FAILED: 'UPDATE_CAMPAIGN_FAILED',
	UPDATE_CAMPAIGN_ENDED: 'UPDATE_CAMPAIGN_ENDED',

	GET_LIST_CONTENT_CAMPAIGN: 'GET_LIST_CONTENT_CAMPAIGN',
	GET_LIST_CONTENT_CAMPAIGN_SUCCESS: 'GET_LIST_CONTENT_CAMPAIGN_SUCCESS',
	GET_LIST_CONTENT_CAMPAIGN_FAILED: 'GET_LIST_CONTENT_CAMPAIGN_FAILED',

	DELETE_CAMPAIGN_REQUEST: 'DELETE_CAMPAIGN_REQUEST',
	DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
	DELETE_CAMPAIGN_FAILED: 'DELETE_CAMPAIGN_FAILED',
	DELETE_CAMPAIGN_ENDED: 'DELETE_CAMPAIGN_ENDED',

	GET_DETAIL_CAMPAIGN_REQUEST: 'GET_DETAIL_CAMPAIGN_REQUEST',
	GET_DETAIL_CAMPAIGN_SUCCESS: 'GET_DETAIL_CAMPAIGN_SUCCESS',
	GET_DETAIL_CAMPAIGN_FAILED: 'GET_DETAIL_CAMPAIGN_FAILED',

	SUGGEST_IMG_CAMPAIGN_REQUEST: 'SUGGEST_IMG_CAMPAIGN_REQUEST',
	SUGGEST_IMG_CAMPAIGN_SUCCESS: 'SUGGEST_IMG_CAMPAIGN_SUCCESS',
	SUGGEST_IMG_CAMPAIGN_FAILED: 'SUGGEST_IMG_CAMPAIGN_FAILED',
	SUGGEST_IMG_CAMPAIGN_ENDED: 'SUGGEST_IMG_CAMPAIGN_ENDED',

	UPLOAD_IMGS_CAMPAIGN_REQUEST: 'UPLOAD_IMGS_CAMPAIGN_REQUEST',
	UPLOAD_IMGS_CAMPAIGN_SUCCESS: 'UPLOAD_IMGS_CAMPAIGN_SUCCESS',
	UPLOAD_IMGS_CAMPAIGN_FAILED: 'UPLOAD_IMGS_CAMPAIGN_FAILED',
	UPLOAD_IMGS_CAMPAIGN_ENDED: 'UPLOAD_IMGS_CAMPAIGN_ENDED',

	GET_RECOMMENDATION_CAMPAIGN_REQUEST: 'GET_RECOMMENDATION_CAMPAIGN_REQUEST',
	GET_RECOMMENDATION_CAMPAIGN_SUCCEEDED: 'GET_RECOMMENDATION_CAMPAIGN_SUCCEEDED',
	GET_RECOMMENDATION_CAMPAIGN_FAILED: 'GET_RECOMMENDATION_CAMPAIGN_FAILED',
	GET_RECOMMENDATION_CAMPAIGN_END: 'GET_RECOMMENDATION_CAMPAIGN_END',

	UPDATE_CAMPAIGN_LANDING_PAGE_REQUEST: 'UPDATE_CAMPAIGN_LANDING_PAGE_REQUEST',
	UPDATE_CAMPAIGN_LANDING_PAGE_SUCCEEDED: 'UPDATE_CAMPAIGN_LANDING_PAGE_SUCCEEDED',
	UPDATE_CAMPAIGN_LANDING_PAGE_FAILED: 'UPDATE_CAMPAIGN_LANDING_PAGE_FAILED',
	UPDATE_CAMPAIGN_LANDING_PAGE_END: 'UPDATE_CAMPAIGN_LANDING_PAGE_END',

	SAVE_CAMPAIGN_DETAIL: 'SAVE_CAMPAIGN_DETAIL',
	STORE_CAMPAIGN_DATA: 'STORE_CAMPAIGN_DATA',
};
