import { IconDragHandle, IconEdit, IconKeyboardArrowLeft } from '@assets/icons';
import RadioGroup from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed/RadioGroup';
import {
	ANSWER_DROPDOWN,
	ANSWER_TYPES,
	KEY_FIELD_FORM_WIDGET,
	MAPPING_FIELD,
	TYPE_ADD_FIELDS,
} from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { FieldItemWidgetDto } from '@models/content/widgetManager';
import {
	StyledIcon,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledIconRemoveCircle, StyledSelect } from '@styled/Content/WidgetManager';
import { StyledCheckbox } from '@styled/Content/WidgetManager/DetailWidgetStyled';
import {
	StyledCustomFieldItem,
	StyledFieldItem,
	StyledFormItem,
	StyledInputCustomField,
	StyledLabelField,
	StyledTagDropdown,
	StyledWrapperLabel,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwitchMapping from '../SwitchMapping';

const DropdownCustomField = ({
	field,
	handleRemoveField,
	keyIndex,
	setFieldsValue,
	getValueField,
	handleChangeRequired,
	onValuesChange,
	propSort,
	isExist,
	idFocus,
	clearIdFocus,
	...other
}: {
	field: FieldItemWidgetDto;
	handleRemoveField: () => void;
	setFieldsValue: (val: any) => void;
	getValueField: (val: string) => any;
	onValuesChange?: (val: any, obj?: any) => void;
	handleChangeRequired: () => void;
	keyIndex: number;
	isExist?: boolean;
	propSort: any;
	idFocus?: string;
	clearIdFocus?: () => void;
	[other: string]: any;
}) => {
	const { t } = useTranslation();
	const { detailWidget } = useContext(WidgetBuilderContext);
	const { otherInfo = {} } = detailWidget.metadata;
	const { label: labelField, typeAdd, answerType, listOption, name, customCreated } = field;

	const isCustom = typeAdd === TYPE_ADD_FIELDS.CUSTOM;
	const nameCheckbox = `${KEY_FIELD_FORM_WIDGET.checkbox_dropdown}${keyIndex}`;
	const nameDropdown = `${KEY_FIELD_FORM_WIDGET.dropdown}${keyIndex}`;
	const nameLabel = `${KEY_FIELD_FORM_WIDGET.label}${keyIndex}`;
	const keyName = `${KEY_FIELD_FORM_WIDGET.item}${keyIndex}`;

	const [listTag, setListTag] = useState<string[]>([]);
	const [showMore, setShowMore] = useState(true);

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	useEffect(() => {
		const otherCustom: any = {};
		if (isCustom) {
			otherCustom[nameCheckbox] = answerType;
			otherCustom[nameLabel] = labelField;
		}

		setFieldsValue({
			[keyName]: name,
			[`${KEY_FIELD_FORM_WIDGET.dropdown}${keyIndex}`]: listOption,
			...otherCustom,
		});
	}, [field, typeAdd]);

	useEffect(() => {
		if (field?.listOption) {
			setListTag(field?.listOption);
		}
	}, [field]);

	useEffect(() => {
		if (idFocus && isCustom) {
			const elm = document.getElementById(nameLabel);
			if (elm) {
				elm.focus();
			}
			if (clearIdFocus && typeof clearIdFocus === 'function') {
				clearIdFocus();
			}
		}
	}, [idFocus]);

	useEffect(() => {
		const elm = document.getElementById(`${KEY_FIELD_FORM_WIDGET.dropdown}${keyIndex}`);
		if (elm && otherInfo?.maxLengthShortText) {
			elm.setAttribute('maxlength', otherInfo?.maxLengthShortText);
		}
	}, [otherInfo?.maxLengthShortText]);

	const handleChange = (listOption: any, b: any) => {
		const keyForm = `${KEY_FIELD_FORM_WIDGET.dropdown}${keyIndex}`;
		const newListTag: string[] = [];

		listOption.forEach((c: string) => {
			if (c?.trim() && !newListTag.includes(c?.trim())) {
				newListTag.push(c?.trim());
			}
		});

		if (newListTag?.length < listTag.length) {
			setFieldsValue({ [keyForm]: listTag });
			return;
		}

		setFieldsValue({ [keyForm]: newListTag });
		setListTag(newListTag);
	};

	const tagRender = (propsTag: any) => {
		const { label, value, closable } = propsTag;
		const keyForm = `${KEY_FIELD_FORM_WIDGET.dropdown}${keyIndex}`;

		const onRemove = () => {
			const listOption = getValueField(keyForm)?.filter((item: string) => item !== value);

			if (onValuesChange && typeof onValuesChange === 'function') {
				onValuesChange({ [keyForm]: listOption });
			}
			setListTag(listOption);
			setFieldsValue({ [keyForm]: listOption });
		};
		return (
			<StyledTagDropdown closable={closable} onClose={onRemove} style={{ marginRight: 3 }}>
				{label}
			</StyledTagDropdown>
		);
	};

	const renderOption = () => {
		return listTag?.map((item) => ({ label: item, value: item }));
	};

	const renderStatusShowMore = () => {
		return (
			<StyledTitle
				cursor="pointer"
				className="unset_height"
				display="flex"
				fontSize="10px"
				height="20px"
				onClick={() => setShowMore(!showMore)}
				color={THEME.colors.gray2}
				style={{ alignItems: 'center', textTransform: 'uppercase' }}>
				{showMore ? t('button.show_less') : t('button.show_more')}
				<StyledIcon size={12} marginSvg={'0'} rotate={showMore ? 90 : -90}>
					<IconKeyboardArrowLeft />
				</StyledIcon>
			</StyledTitle>
		);
	};

	const renderContainerLabel = (label: string) => {
		return (
			<StyledWrapperContent>
				<StyledLabelField
					maxWidth={`calc(100% - 74px - ${isCustom ? '71px' : '0px'})`}
					className={`custom_label ${showMore ? '' : 'overflow_text'}`}>
					{label}
				</StyledLabelField>
				{!showMore && renderStatusShowMore()}
			</StyledWrapperContent>
		);
	};
	return (
		<StyledFieldItem {...other}>
			<StyledCustomFieldItem>
				<StyledWrapperLabel>
					{isCustom && (
						<StyledWrapperContent width="100%">
							{!showMore && renderContainerLabel(labelField || '')}

							<StyledFormItem
								className={showMore ? '' : 'hidden'}
								name={nameLabel}
								label={t(
									'content.widget_manager.widget_builder.custom_property_name',
								)}
								rules={[
									{
										required: true,
										message: t(
											'content.widget_manager.widget_builder.required',
										),
									},
								]}>
								<StyledInputCustomField
									maxLength={otherInfo?.maxLengthShortText || 255}
									value={labelField || ''}
									placeholder={t(
										'content.widget_manager.widget_builder.name_custom_property',
									)}
								/>
							</StyledFormItem>
						</StyledWrapperContent>
					)}
					{!isCustom && (
						<StyledLabelField maxWidth="calc(100% - 74px)" className="custom_label">
							{t('content.widget_manager.widget_builder.label_field.dropdown')}
						</StyledLabelField>
					)}
					<StyledWrapperAction
						style={{ position: 'absolute', right: 0, top: 0 }}
						height="24px">
						{propSort && (
							<StyledIcon
								{...propSort}
								className="icon_drag"
								style={{ margin: '0 6px 0 0' }}>
								<IconDragHandle />
							</StyledIcon>
						)}
						{!showMore && isCustom && (
							<StyledWrapperContent margin="0 6px 0 0">
								<SwitchMapping
									checked={field?.mapping === MAPPING_FIELD.CONTENT}
									keyIndex={keyIndex}
									disabled={!!isExist}
									hideTitle
								/>
							</StyledWrapperContent>
						)}
						<StyledCheckbox
							onChange={(e: any) => {
								e.preventDefault();
								e.stopPropagation();
								handleChangeRequired();
							}}
							checked={field?.required}
						/>
					</StyledWrapperAction>
				</StyledWrapperLabel>

				<StyledWrapperContent className={showMore ? '' : 'hidden'}>
					<StyledFormItem
						name={keyName}
						label={
							isCustom
								? t('content.widget_manager.widget_builder.form_placeholder_text')
								: undefined
						}
						rules={[
							{
								required: true,
								message: t('content.widget_manager.widget_builder.required'),
							},
						]}>
						<StyledInputCustomField
							maxLength={otherInfo?.maxLengthShortText || 255}
							id={keyName}
							placeholder={t(
								'content.widget_manager.widget_builder.enter_display_uploader',
							)}
						/>
					</StyledFormItem>
					{isCustom && (
						<StyledFormItem
							name={nameCheckbox}
							label={t('content.widget_manager.widget_builder.answer_type')}>
							<RadioGroup
								gapSpace="0px"
								direction="vertical"
								bgCheckbox={THEME.colors.darkBlue3}
								list={ANSWER_DROPDOWN.map((item) => ({
									...item,
									name: t(item.name),
								}))}
								radioValue={answerType}
							/>
						</StyledFormItem>
					)}
					<StyledLabelField>
						{t('content.widget_manager.widget_builder.label_field.dropdown_options')}
					</StyledLabelField>
					<StyledFormItem
						style={{ marginBottom: '0' }}
						name={nameDropdown}
						rules={[
							{
								validator: (_, value) => {
									if (!value || value.length < 2) {
										return Promise.reject(
											t('content.widget_manager.message.error_option', {
												count: 2,
											}),
										);
									}
									return Promise.resolve();
								},
							},
						]}>
						<StyledSelect
							mode={'tags'}
							width="100%"
							margin={'6px 0 0 0'}
							onChange={handleChange}
							showArrow={true}
							open={false}
							options={renderOption()}
							tagRender={tagRender}
							notFoundContent={notFoundContent()}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							maxLength={3}
							virtual={false}
							className="dropdown-custom-widget"
							optionFilterProp="label"
							dropdownClassName={'dropdown-menu'}
						/>
					</StyledFormItem>
				</StyledWrapperContent>
				<StyledWrapperTitle>
					{showMore && renderStatusShowMore()}
					{isCustom && showMore && (
						<SwitchMapping
							checked={field?.mapping === MAPPING_FIELD.CONTENT}
							keyIndex={keyIndex}
							disabled={!!isExist}
						/>
					)}
				</StyledWrapperTitle>
			</StyledCustomFieldItem>
			<StyledIconRemoveCircle onClick={handleRemoveField} />
		</StyledFieldItem>
	);
};

export default DropdownCustomField;
