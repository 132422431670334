const DELETE = 'DELETE';
const EDIT = 'EDIT';
const ADD = 'ADD';
const UPDATE = 'UPDATE';
const FOLDER = 'FOLDER';
const GALLERY = 'GALLERY';
const CUSTOM_FOLDER = 'custom';
const DEFAULT_GALLERY = 'default-gallery';
const CUSTOM_GALLERY = 'custom-gallery';
const SYSTEM_FOLDER = 'system';

const CONTENT_VALUE = {
	PHOTO: 'PHOTO',
	IMAGE: 'IMAGE',
	VIDEO: 'VIDEO',
	VERIFIED: 'VERIFIED',
	SELECTS: 'SELECTS',
};

const STATUS = {
	ADD: 'ADD',
	DELETE: 'DELETE',
};

const ROLE = {
	VIEWER: {
		label: 'Guest',
		value: 'VIEWER',
	},
	REVIEWER: {
		label: 'Reviewer',
		value: 'REVIEWER',
	},
	EREVIEWER: {
		label: 'EReviewer',
		value: 'EREVIEWER',
	},
	CADMIN: {
		label: 'CAdmin',
		value: 'CADMIN',
	},
};

const CLIENT_TYPE = {
	CLIENT: 'Client',
	DEMO: 'Demo',
	TESTING: 'Testing',
};

const ACCOUNT_STATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	PENDING: 'pending',
};

const USER_ACCESS_RULES = {
	CHANGE_REVIEWER_2_CADMIN: 'CHANGE REVIEWER TO CADMIN',
	CHANGE_REVIEWER_2_EREVIEWER: 'CHANGE REVIEWER TO EREVIEWER',
	CHANGE_CADMIN_2_REVIEWER: 'CHANGE CADMIN TO REVIEWER',
	CHANGE_CADMIN_2_EREVIEWER: 'CHANGE CADMIN TO EREVIEWER',
	CHANGE_EREVIEWER_2_CADMIN: 'CHANGE EREVIEWER TO CADMIN',
	CHANGE_EREVIEWER_2_REVIEWER: 'CHANGE EREVIEWER TO REVIEWER',
	DISABLE_OR_ENABLE_CADMIN: 'DISABLE OR ENABLE CADMIN',
	DISABLE_OR_ENABLE_REVIEWER: 'DISABLE OR ENABLE REVIEWER',
	DISABLE_OR_ENABLE_EREVIEWER: 'DISABLE OR ENABLE EREVIEWER',
	REMOVE_CADMIN: 'REMOVE CADMIN',
	REMOVE_REVIEWER: 'REMOVE REVIEWER',
	REMOVE_EREVIEWER: 'REMOVE EREVIEWER',
	INVITE_USER_AS_CADMIN: 'INVITE USER AS CADMIN',
	INVITE_USER_AS_REVIEWER: 'INVITE USER AS REVIEWER',
	INVITE_USER_AS_EREVIEWER: 'INVITE USER AS EREVIEWER',
	CHANGE_OTHER_USERS_PASSWORDS: 'CHANGE OTHER USERS PASSWORDS',
};

const SPECIAL_CHARS = {
	DASH: '_',
	HASH: '#',
	AT: '@',
	DOT: '.',
	NONE: '',
	DOLLAR: '$',
	PERCENTAGE: '%',
};

export {
	ACCOUNT_STATUS,
	ADD,
	CONTENT_VALUE,
	CUSTOM_FOLDER,
	CUSTOM_GALLERY,
	DEFAULT_GALLERY,
	DELETE,
	EDIT,
	FOLDER,
	GALLERY,
	ROLE,
	SPECIAL_CHARS,
	STATUS,
	SYSTEM_FOLDER,
	UPDATE,
	USER_ACCESS_RULES,
	CLIENT_TYPE,
};
