import { PARAMS_DEFAULT } from '@constants/common';
import { SYSTEM_FOLDER } from '@constants/settings';

enum DETAILS {
	GENERAL_INFORMATION = 'General Information',
	METADATA = 'EXIF Metadata',
	INSTAGRAM_METRICS = 'Instagram Metrics',
	HASHTAGS = 'Hashtags',
	IMAGE_RECOGNITION_AI = 'Image Recognition AI',
	ADDITIONAL_INFO = 'Additional Info',
}

const CONTENT_TYPE = {
	VERIFIED: 'verified',
	SELECT: 'select',
	CONTENT: 'content',
} as const;

const CONTENT_FLAGS = {
	EXPLICIT_CONTENT: 'explicit content',
};

const CONTENT_FOLDER = {
	BOOKMARKS: 'selects',
	SUBMISSIONS: 'submissions',
	FAVORITES: 'favorites',
	ARCHIVE: 'rejected',
	ALBUM_DETAIL: 'album_detail',
};

const VIEW_TYPE = {
	LANDSCAPE: 'landscape',
	PORTRAIT: 'portrait',
	SQUARE: 'square',
} as const;

enum UPLOADSOURCE {
	SOCIAL_SEARCH = 'social-search',
	SOCIAL_UPLOAD = 'social-upload',
	DIRECT_UPLOAD = 'direct-upload',
	MANUAL_IMPORT = 'manual-import',
	SCRIPT_IMPORT = 'script-import',
	SOCIAL_SEARCH_UPCASE = 'SOCIAL_SEARCH',
}

enum UPLOADMETHOD {
	DIRECT_UPLOAD = 'DIRECT_UPLOAD',
	MANUAL_IMPORT = 'MANUAL_IMPORT',
	TIKTOK_UPLOAD = 'TIKTOK_UPLOAD',
	GOOGLE_DRIVE_UPLOAD = 'GOOGLE_DRIVE_UPLOAD',
	TWITTER_UPLOAD = 'TWITTER_UPLOAD',
	INSTAGRAM_UPLOAD = 'INSTAGRAM_UPLOAD',
	YOUTUBE_UPLOAD = 'YOUTUBE_UPLOAD',
	FACEBOOK_UPLOAD = 'FACEBOOK_UPLOAD',
}

enum FOLDER {
	ALL = 'All',
	SELECTS = 'Selects',
	SUBMISSIONS = 'Submissions',
	GALLERY = 'Gallery',
	REJECTED = 'Rejected',
	UNREJECTED = 'Selects/Submissions',
	SOCIAL_POST = 'Social Posts',
	PUBLISHED = 'Published',
}

enum CREATORFOLDER {
	ALL = 'all',
	GALLERY = 'Gallery',
	REJECTED = 'Rejected',
}

const ROTATE_QUANTITY = {
	default: 0,
	end: 270,
	add: 90,
};
const MOVE_TO = [FOLDER.REJECTED];

const FOLDER_LIST = [
	FOLDER.ALL,
	FOLDER.SELECTS,
	FOLDER.SUBMISSIONS,
	FOLDER.GALLERY,
	FOLDER.REJECTED,
];

const SYSTEM_FOLDER_LIST = [
	{ name: FOLDER.ALL, type: SYSTEM_FOLDER, id: '0' },
	{ name: FOLDER.SELECTS, type: SYSTEM_FOLDER, id: '2' },
	{ name: FOLDER.SUBMISSIONS, type: SYSTEM_FOLDER, id: '1' },
	{ name: FOLDER.REJECTED, type: SYSTEM_FOLDER, id: '3' },
];

const SYSTEM_FOLDER_LIST_OBJ = {
	ALL: { name: FOLDER.ALL, type: SYSTEM_FOLDER, id: '0' },
	SELECTS: { name: FOLDER.SELECTS, type: SYSTEM_FOLDER, id: '2' },
	SUBMISSIONS: { name: FOLDER.SUBMISSIONS, type: SYSTEM_FOLDER, id: '1' },
	REJECTED: { name: FOLDER.REJECTED, type: SYSTEM_FOLDER, id: '3' },
	SOCIAL_POST: { name: FOLDER.SOCIAL_POST, type: SYSTEM_FOLDER, id: 'socialPost' },
	PUBLISHED: { name: FOLDER.PUBLISHED, type: SYSTEM_FOLDER, id: 'published' },
};

const SYSTEM_FOLDER_ALL = { name: FOLDER.ALL, type: SYSTEM_FOLDER, id: '0' };

const TYPE_GALLERY = {
	default: 'default-gallery',
	custom: 'custom-gallery',
};

const TYPE_FOLDER = {
	SYSTEM: 'default',
	CUSTOM: 'custom',
	ALBUM: 'album',
	GALLERY_DEFAULT: 'default-gallery',
	GALLERY_CUSTOM: 'custom-gallery',
};

const MOVE_CONTENT = {
	NEXT: 'next',
	PREVIOUS: 'previous',
	CHANGE: 'change',
} as const;

const TRIGGERED_ACTION = {
	BLOCK: 'block',
};

const ROTATE = {
	DEGREE_H: 'DEGREE_',
	D_90: 90,
	D_180: 180,
	D_270: 270,
};

const TYPE_COLLAPSE_ACTION = {
	FILTER: 'filter',
	ACTION: 'action',
	BOOKMARK_POST: 'BOOKMARK_POST',
};

enum ACTIONS {
	DELETE = 'delete',
	REJECT = 'reject',
	UNREJECT = 'unreject',
	BLOCK = 'block',
	UNBLOCK = 'unblock',
}

const MARK_AS = {
	CONTACTED: {
		label: 'Contacted',
		value: 'contacted',
		type: 'contact',
	},
	UNCONTACTED: {
		label: 'Uncontacted',
		value: 'unContacted',
		type: 'contact',
	},
	PUBLISHED: {
		label: 'Published',
		value: 'published',
		type: 'published',
	},
	UNPUBLISHED: {
		label: 'Unpublished',
		value: 'unPublished',
		type: 'published',
	},
	GALLERY: {
		label: 'Gallery',
		value: 'gallery',
		type: 'gallery',
	},
	NOGALLERY: {
		label: 'No Gallery',
		value: 'noGallery',
		type: 'gallery',
	},
	PENDING: {
		label: 'Pending',
		additionalLabel: 'Needs Review',
		value: 'pending',
	},
	REJECTED: {
		label: 'Archive',
		value: 'rejected',
	},
	VERIFIED: {
		label: 'Verified',
		value: 'verified',
		type: 'verified',
	},
	UNVERIFIED: {
		label: 'Unverified',
		value: 'unVerified',
		type: 'verified',
	},
	AFFILIATE: {
		label: 'Affiliate',
		value: 'affiliate',
	},
	NEEDS_REVIEW: {
		label: 'Needs Review',
		value: 'needs_review',
		type: 'review',
	},
	REVIEWED: {
		label: 'Reviewed',
		value: 'reviewed',
		type: 'review',
	},
	RECENTLY_ADDED: {
		label: 'Newly Added',
		value: 'recently_added',
		type: 'added',
	},
} as const;

const FILTER_BY = {
	ALL: {
		label: 'All',
		value: 'all',
	},
	PHOTO: {
		label: 'Photo',
		value: 'image',
	},
	VIDEO: {
		label: 'Video',
		value: 'video',
	},
} as const;

const SORT_BY = {
	IMPORT_DATE: {
		label: 'Import Date',
		value: 'createdAt',
	},
	STAR_RATING: {
		label: 'Star Rating',
		value: 'star',
	},
} as const;

const SELECT_TAGS = {
	LABELS: {
		name: 'labels',
		title: 'Labels',
	},
	FLAGS: {
		name: 'flags',
		title: 'Flags',
	},
	REWARDS: {
		name: 'rewards',
		title: 'Rewards',
	},
	GALLERIES: {
		name: 'galleries',
		title: 'Galleries',
	},
	CAMPAIGNS: {
		name: 'campaigns',
		title: 'Campaigns',
	},
	STATUS: {
		name: 'markedAs',
		title: 'Status',
		title_marked: 'Status',
	},
	SEGMENTS: {
		name: 'creatorSegments',
		title: 'Creator Segments',
	},
} as const;

const CHANGED_DETAILS = {
	NOTE: {
		name: 'note',
		title: 'Note',
	},
	STAR: {
		name: 'star',
		title: 'Ratings',
	},
	TERMS_OF_USE: {
		name: 'termsOfUse',
		title: 'termsOfUse',
	},
	FLAGS: {
		name: 'flags',
		title: 'Flags',
	},
} as const;

const FORM_CONTENT_DETAIL = {
	NOTE: 'note',
	STAR: 'star',
	TERMS_OF_USE: 'termsOfUse',
	LABELS: 'labels',
	FLAGS: 'flags',
	GOOGLE_AI_HASH_TAGS: 'googleAIHashtags',
	TAGS: 'tags',
	REWARDS: 'rewards',
	GALLERIES: 'galleries',
	CAMPAIGNS: 'campaigns',
	STATUS: 'markedAs',
	SEGMENTS: 'creatorSegments',
	ALT_TEXT: 'altText',
};

const CHANGED_DETAILS_CONTENT = {
	NOTE: {
		name: 'note',
		title: 'Note',
	},
	STAR: {
		name: 'star',
		title: 'Rate Content',
	},
	TERM: {
		name: 'term',
		title: 'Terms of Use',
	},
};

const MEDIA_TYPE = {
	VIDEO: 'video',
	IMAGE: 'image',
} as const;

const LOCAL_STORAGE = {
	FILTER_VALUES: 'filterValues',
	SAVED_FILTER: 'savedFilter',
	needReview: 'needReivew',
} as const;

const MEDIA_RESOLUTION = {
	LOW: {
		width: 480,
		height: 320,
		label: 'Low',
	},
	MEDIUM: {
		width: 1024,
		height: 768,
		label: 'Medium',
	},
	HIGH: {
		width: 1920,
		height: 1080,
		label: 'High',
	},
} as const;

const FILE_NAME = {
	CREATOR_NAME: {
		value: 'creator_name',
		label: 'Creator Name',
	},
	CREATOR_EMAIL: {
		value: 'creator_email',
		label: 'Creator Email',
	},
	SOCIAL_USERNAME: {
		value: 'social_username',
		label: 'Social Username',
		INSTAGRAM: {
			value: 'instagram',
			label: 'Instagram',
		},
		YOUTUBE: {
			value: 'youtube',
			label: 'Youtube',
		},
		TIKTOK: {
			value: 'tiktok',
			label: 'TikTok',
		},
		TWITTER: {
			value: 'twitter',
			label: 'Twitter',
		},
	},
	UPLOAD_DETAILS: {
		value: 'upload_details',
		label: 'Upload Details',
		IMPORT_DATE: {
			value: 'import_date',
			label: 'Import Date',
		},
		UPLOAD_SOURCE: {
			value: 'upload_source',
			label: 'Upload Source',
		},
		VERIFIED: {
			value: 'verified_status',
			label: 'Verified Status',
		},
		PUBLISHED: {
			value: 'published_status',
			label: 'Published Status',
		},
	},
} as const;

const FILE_NAME_STR = {
	CREATOR_NAME: 'creator name',
	CREATOR_EMAIL: 'creator email',
	YOUTUBE: 'youtube username',
	INSTAGRAM: 'instagram username',
	TWITTER: 'twitter username',
	TIKTOK: 'tiktok username',
	UPLOAD_SOURCE: 'upload source',
	IMPORT_DATE: 'date imported',
	VERIFIED_STATUS: 'verified status',
	PUBLISHED_STATUS: 'published status',
} as const;

const MAX_LENGTH_CHARACTER = 255;

const GUTTER_LIST_CONTENT = 16;

const GOOGLE_GRANT_TYPE = {
	REFRESH_TOKEN: 'refresh_token',
	AUTHORIZATION_CODE: 'authorization_code',
};

const markAsCheckboxes = [
	{
		label: MARK_AS.CONTACTED.label,
		value: MARK_AS.CONTACTED.value,
		type: MARK_AS.CONTACTED.type,
		disabled: false,
	},
	{
		label: MARK_AS.UNCONTACTED.label,
		value: MARK_AS.UNCONTACTED.value,
		type: MARK_AS.UNCONTACTED.type,
		disabled: false,
	},
	{
		label: MARK_AS.PUBLISHED.label,
		value: MARK_AS.PUBLISHED.value,
		type: MARK_AS.PUBLISHED.type,
		disabled: false,
	},
	{
		label: MARK_AS.UNPUBLISHED.label,
		value: MARK_AS.UNPUBLISHED.value,
		type: MARK_AS.UNPUBLISHED.type,
		disabled: false,
	},
	{
		label: MARK_AS.VERIFIED.label,
		value: MARK_AS.VERIFIED.value,
		disabled: false,
		type: MARK_AS.VERIFIED.type,
	},
	{
		label: MARK_AS.UNVERIFIED.label,
		value: MARK_AS.UNVERIFIED.value,
		type: MARK_AS.UNVERIFIED.type,
		disabled: false,
	},
	{
		label: MARK_AS.GALLERY.label,
		value: MARK_AS.GALLERY.value,
		type: MARK_AS.GALLERY.type,
		disabled: false,
	},
	{
		label: MARK_AS.NOGALLERY.label,
		value: MARK_AS.NOGALLERY.value,
		type: MARK_AS.NOGALLERY.type,
		disabled: false,
	},
	{
		label: MARK_AS.NEEDS_REVIEW.label,
		value: MARK_AS.NEEDS_REVIEW.value,
		type: MARK_AS.NEEDS_REVIEW.type,
		disabled: false,
	},
	{
		label: MARK_AS.REVIEWED.label,
		value: MARK_AS.REVIEWED.value,
		type: MARK_AS.REVIEWED.type,
		disabled: false,
	},
];

const THUMBNAIL_BEHAVIOR = {
	SHOW_RIGHTS_CLEARANCE: 'SHOW_RIGHTS_CLEARANCE',
	SHOW_ANALYSIS_STATUS: 'SHOW_ANALYSIS_STATUS',
	HIGHLIGHT_PENDING_CONTENT: 'HIGHLIGHT_PENDING_CONTENT',
	CLEAR_ALL_RECENTLY_STATUS: 'CLEAR_ALL_RECENTLY_STATUS',
	SAVED: 'thumbnailBehavior',
};

const SOCIAL_SOURCE = {
	TIKTOK: 'tiktok',
	INSTAGRAM: 'instagram',
	TWITTER: 'twitter',
	YOUTUBE: 'youtube',
};

const EMPTY_NEW_LINE = '\r\n';

const SUBTEXT_RESOLUTION = {
	START: 'X Resolution: ',
	DOTS: ' dots',
	DOTS_PER_INCH: ' dots per inch',
};

const DEVICE_KEY = {
	APPLE: {
		key: 'APPL',
		label: 'Apple',
	},
	UNKNOWN: {
		key: 'unknown',
		label: '',
	},
};

const TEXT_SIZE = {
	IMAGE_H: 'Image Height: ',
	VIDEO_H: 'Height: ',
	IMAGE_W: 'Image Width: ',
	VIDEO_W: 'Width: ',
	PIXEL: 'pixel',
};

const MAX_TEXTAREA = {
	MESSAGE_SIGNAGE: 100,
};

const TYPE_VOTE = {
	LOVE: 'LOVE',
	LIKE: 'LIKE',
	DISLIKE: 'DISLIKE',
};

const DEFAULT_EMOJI = {
	dislike: { total: 0, emails: [] },
	like: { total: 0, emails: [] },
	love: { total: 0, emails: [] },
};

const VAL_UN_RATE = -1;

const NAME_ZIP = 'entribeExport_';

const RATE_STAR = {
	ONE: 1,
	TWO: 2,
	THREE: 3,
	FOUR: 4,
	FIVE: 5,
};

const RECOGNITION_PROCESSES = {
	CHOOSE_SERVICE: 'CHOOSE_SERVICE',
	CHOOSE_LOGO: 'CHOOSE_LOGO',
	ANALYZE_CONTENT: 'ANALYZE_CONTENT',
	REVIEW_ORDER: 'REVIEW_ORDER',
	SUBMIT_ORDER: 'SUBMIT_ORDER',
};

const ANALYZED_CONTENT_OPTIONS = {
	SKIP_CONTENT_ANALYZED: 'skipAnalyzedContent',
	ALL_CONTENTS: 'allContents',
	LOGO_DETECTION: 'LOGO_DETECTION',
	VIDEO_LOGO_DETECTION: 'VIDEO_LOGO_DETECTION',
	VIDEO_LABEL_DETECTION: 'VIDEO_LABEL_DETECTION',
	VIDEO_EXPLICIT_CONTENT_DETECTION: 'VIDEO_EXPLICIT_CONTENT_DETECTION',
};

const DEFAULT_VERSION_ID = 1;

const DEFAULT_CONTENT_RESPONSE = {
	...PARAMS_DEFAULT,
	totalRecords: 0,
	totalPages: 0,
	result: [],
};

const ACCEPT_FILES = {
	IMAGE: 'image/*, .png, .jpg, .jpeg, .heic, .heif',
	VIDEO: 'video/mp4, video/x-m4v, video/3gp, video/3gp2, video/asf, video/asx, video/avi, video/m4v, video/mpe, video/mpg, video/wmv, video/mkv, .hevc, .HEVC, video/x-msvideo, .mp4, .avi, .mov, .3gp, .mkv, .wmv, .mpeg4, .m4v, video/*',
	HEIC: 'heic',
	HEIF: 'heif',
	XLS: '.xlsx, .xls',
	PDF: 'application/pdf, .pdf',
	FONT_FAMILY: 'font/*,.tff,.otf,.woff,.eot,.svg,.woff2,font/ttf',
	FONT_FAMILY2:
		'application/x-font-otf, application/x-font-woff, application/x-font-ttf, image/svg+xml, application/vnd.ms-fontobject',
	FONT_FAMILY3: 'image/svg+xml,font/woff,font/woff2,font/ttf,font/*',
	MINE_TYPE_MP4: 'video/mp4',
	MINE_TYPE_WEBM: 'video/webm',
};

const VALIDATE_FILE = {
	sizeDetail3Gb: 3 * 1024 * 1024 * 1024,
	size_3: 3,
	size_150: 150,
	width: 16000,
	height: 16000,
	sizeDetail150mb: 150 * 1024 * 1024,
	videoLength90m: 90 * 60 * 1000,
	size_90: 90,
	limitFile: 100,
};

const METHOD_VERSION = {
	EDIT: 'EDITED_IN_APP',
	UPLOAD: 'UPLOADED_NEW_VERSION',
};

const KEY_MESSAGE = {
	UPLOAD_VIDEO: 'key_upload_video',
};

const KEY_PARAM = {
	MEDIA_ID: 'mediaId',
};

const PERMISSION_PUBLISH = {
	PUBLISH: 'PUBLISH',
	NO_PUBLISH: 'NO_PUBLISH',
};

const LIST_OPTION_PERMISSION = [
	{
		value: PERMISSION_PUBLISH.PUBLISH,
		name: 'content.import_modal.have_publishing',
	},
	{
		value: PERMISSION_PUBLISH.NO_PUBLISH,
		name: 'content.import_modal.not_publishing',
	},
];

const KEY_IMPORT_CONTENT = {
	FILE_CONTENT: 'fileContent',
	CREATOR: 'creator',
	PUBLISH: 'publish',
	PDF_FILE: 'filePDF',
	NOTE: 'note',
};

const NOT_PUBLISH_TERMS = 'Not cleared for publishing or distribution.';

const SELECTOR_ELEMENTS = {
	VIDEO_CONTENT_DETAIL_ID: 'video_in_cdm',
	IMG_CONTENT_DETAIL_ID: 'content_in_cdm',
	IMG_CONTENT_THUMB_ID: 'content_thumb_id',
	SIDEBAR_CONTENT_ID: 'sidebar_content_id',
};

const CLASS_ELEMENT = {
	CLASS_ADD_SCROLL: 'overflow_scroll',
};

const LIMIT_SIZE = {
	WIDTH: '80vw',
	MAX_WIDTH: 2600,
	HEIGHT: '80vh',
	MAX_HEIGHT: 1600,
};

export {
	DETAILS,
	CONTENT_TYPE,
	LIST_OPTION_PERMISSION,
	KEY_IMPORT_CONTENT,
	PERMISSION_PUBLISH,
	UPLOADSOURCE,
	UPLOADMETHOD,
	FOLDER,
	CREATORFOLDER,
	FOLDER_LIST,
	CLASS_ELEMENT,
	KEY_PARAM,
	MOVE_TO,
	MOVE_CONTENT,
	SUBTEXT_RESOLUTION,
	TRIGGERED_ACTION,
	GUTTER_LIST_CONTENT,
	ACTIONS,
	MARK_AS,
	FILTER_BY,
	SELECT_TAGS,
	CHANGED_DETAILS,
	CHANGED_DETAILS_CONTENT,
	MEDIA_TYPE,
	SORT_BY,
	LOCAL_STORAGE,
	VIEW_TYPE,
	MEDIA_RESOLUTION,
	FILE_NAME,
	FILE_NAME_STR,
	MAX_LENGTH_CHARACTER,
	SYSTEM_FOLDER_LIST,
	SYSTEM_FOLDER_ALL,
	TYPE_GALLERY,
	ROTATE_QUANTITY,
	GOOGLE_GRANT_TYPE,
	TYPE_FOLDER,
	markAsCheckboxes,
	CONTENT_FOLDER,
	THUMBNAIL_BEHAVIOR,
	TYPE_COLLAPSE_ACTION,
	FORM_CONTENT_DETAIL,
	SOCIAL_SOURCE,
	EMPTY_NEW_LINE,
	TEXT_SIZE,
	MAX_TEXTAREA,
	TYPE_VOTE,
	DEFAULT_EMOJI,
	VAL_UN_RATE,
	NAME_ZIP,
	RATE_STAR,
	RECOGNITION_PROCESSES,
	ANALYZED_CONTENT_OPTIONS,
	DEFAULT_VERSION_ID,
	SYSTEM_FOLDER_LIST_OBJ,
	DEFAULT_CONTENT_RESPONSE,
	ACCEPT_FILES,
	VALIDATE_FILE,
	METHOD_VERSION,
	KEY_MESSAGE,
	NOT_PUBLISH_TERMS,
	ROTATE,
	SELECTOR_ELEMENTS,
	DEVICE_KEY,
	CONTENT_FLAGS,
	LIMIT_SIZE,
};
