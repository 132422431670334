import { Link }            from 'react-router-dom';
import { Result }          from 'antd';
import { Wrapper, Button } from '../../cores';

type PageNotFoundProps = {};

const PageNotFound = ( {}: PageNotFoundProps ) => {
    return (
        <Wrapper>
            <Result
                status="warning"
                title="404 Error, This is not the page you're looking for."
                extra={
                    <Link to="/dashboard">
                        <Button type="primary">Go to Main Page</Button>
                    </Link>
                }
            />
        </Wrapper>
    );
};

export default PageNotFound;
