import { IconCheck, IconClose, IconEdit } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { StyledIcon, StyledWrapperAction } from '@styled/Common/CommonStyled';
import { StyledIconTag } from '@styled/Content/WidgetManager';
import { StyledWrapperInput } from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLabel = styled.label`
	margin-right: 4px;
	color: ${THEME.colors.gray1};
`;

const StyledName = styled.span`
	color: ${THEME.colors.gray5};
	display: inline-flex;
	width: calc(100% - 54px);
	align-items: center;
`;

const StyledText = styled.span`
	max-width: calc(100% - 30px);
	&.hidden_text {
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
		text-overflow: ellipsis;
	}
`;

export const StyledIconEdit = styled(IconEdit)`
	margin-left: 4px;
	width: 16px;
	height: 16px;
	cursor: pointer;
`;

type PropTypes = {
	name: string;
	handleSave: (val: string) => void;
	hidePrefix?: boolean;
	style?: any;
	sizeIcon?: number;
	borderColor?: string;
	disabled?: boolean;
	hiddenOverflow?: boolean;
	width?: string;
};
const EditName = (props: PropTypes) => {
	const { t } = useTranslation();
	const {
		name,
		handleSave,
		hidePrefix = false,
		style = {},
		sizeIcon = 16,
		borderColor,
		disabled = false,
		hiddenOverflow = false,
		width,
	} = props;
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [nameTemplate, setNameTemplate] = useState<string>(name);

	const handleSaveName = () => {
		if (!nameTemplate?.trim()) {
			setNameTemplate('');
			message.error(t('content.widget_manager.widget_builder.name_required'));
			return;
		}
		setIsEdit(false);
		if (handleSave && typeof handleSave === 'function') {
			handleSave(nameTemplate?.trim());
		}
	};

	useEffect(() => {
		if (name !== nameTemplate) {
			setNameTemplate(name);
		}
	}, [name]);

	return (
		<StyledWrapperAction
			width={width || '100%'}
			style={{ minHeight: '28px', pointerEvents: disabled ? 'none' : 'unset' }}>
			{!hidePrefix && (
				<StyledLabel>{t('content.widget_manager.widget_builder.editing')}</StyledLabel>
			)}
			{isEdit ? (
				<StyledWrapperInput borderColor={borderColor}>
					<Input
						value={nameTemplate}
						onChange={(e) => setNameTemplate(e.target.value)}
						onPressEnter={handleSaveName}
					/>
					<StyledWrapperAction>
						<StyledIconTag
							onClick={handleSaveName}
							color={THEME.colors.orangeBase}
							icon={<IconCheck />}
						/>
						<StyledIconTag
							onClick={() => setIsEdit(false)}
							color={THEME.colors.orangeBase}
							icon={
								<IconClose
									onClick={() => {
										setIsEdit(false);
										setNameTemplate(name);
									}}
								/>
							}
						/>
					</StyledWrapperAction>
				</StyledWrapperInput>
			) : (
				<StyledName style={style}>
					<StyledText className={hiddenOverflow ? 'hidden_text' : ''}>
						{nameTemplate}
					</StyledText>
					{!disabled && (
						<StyledIcon
							onClick={() => setIsEdit(true)}
							margin="0 0 0 6px"
							size={sizeIcon}>
							<IconEdit />
						</StyledIcon>
					)}
				</StyledName>
			)}
		</StyledWrapperAction>
	);
};

export default EditName;
