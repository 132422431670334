import { createAction } from 'redux-actions';

// Action constant
export const FETCH_SAMPLE = 'FETCH_SAMPLE';
export const FETCH_SAMPLE_COMPLETED = 'FETCH_SAMPLE_COMPLETED';
export const FETCH_SAMPLE_FAILED = 'FETCH_SAMPLE_FAILED';

// Action function
export const fetchSample = createAction( FETCH_SAMPLE );
export const fetchSampleCompleted = createAction( FETCH_SAMPLE_COMPLETED );
export const fetchSampleFailed = createAction( FETCH_SAMPLE_FAILED );
