import CheckboxGroup from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed/CheckboxGroup';
import RadioGroup from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed/RadioGroup';
import { DEFAULT_FORM_CONFIG, INTERVAL, NAME_CONFIG } from '@constants/ambassador';
import { SOCIAL_UPCASE } from '@constants/social_search';
import { ConfigGraphTypes } from '@models/ambassador';
import { OptionTypeItem } from '@models/filter';
import { StyledDrawerGraphConfig, StyledWrapperActionConfig } from '@styled/AmbassadorDashboard';
import { StyledText, StyledWrapperAction, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledCheckbox } from '@styled/Content/ContentLibrary/FilterStyled';
import { StyledFormItem } from '@styled/Filter/FilterContentStyled';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
	visible: boolean;
	onClose: () => void;

	configSource: string[];
	configInterval: string;
	isShowCumulativeData: boolean;
	isShowDynamicScale: boolean;

	handleUpdateConfig: (val: ConfigGraphTypes) => void;
	isSocial?: boolean;
	[other: string]: any;
};

const DrawerGraphConfig = (props: PropTypes) => {
	const {
		visible,
		onClose,

		configSource,
		configInterval,
		isShowCumulativeData,
		isShowDynamicScale,

		handleUpdateConfig,
		isSocial,
		...other
	} = props;
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const arrSource: OptionTypeItem[] = useMemo(() => {
		const result: OptionTypeItem[] = [
			{
				value: SOCIAL_UPCASE.INSTAGRAM,
				name: t('ambassador.instagram_posts'),
			},
			{
				value: SOCIAL_UPCASE.TIKTOK,
				name: t('ambassador.tikTok_posts'),
			},
			{
				value: SOCIAL_UPCASE.TWITTER,
				name: t('ambassador.twitter_posts'),
			},
			{
				value: SOCIAL_UPCASE.YOUTUBE,
				name: t('ambassador.youtube_posts'),
			},
			{
				value: SOCIAL_UPCASE.COMBINED,
				name: t('ambassador.all_social_posts'),
			},
		];
		if (isSocial) {
			result.unshift({ value: SOCIAL_UPCASE.SUBMISSIONS, name: t('ambassador.submissions') });
		}
		return result;
	}, [isSocial]);

	const arrInterval = [
		{
			value: INTERVAL.DAILY,
			name: t('button.daily'),
		},
		{
			value: INTERVAL.WEEKLY,
			name: t('button.weekly'),
		},
		{
			value: INTERVAL.MONTHLY,
			name: t('button.monthly'),
		},
	];

	const handleFieldForm = () => {
		form.setFieldsValue({
			[NAME_CONFIG.SOURCES]: [...configSource],
			[NAME_CONFIG.INTERVAL]: configInterval,
			[NAME_CONFIG.SHOW_CUMULATIVE_DATA]: isShowCumulativeData,
			[NAME_CONFIG.SHOW_DYNAMIC_SCALE]: isShowDynamicScale,
		});
	};
	const handleClearOpenConfig = () => {
		onClose();
		handleFieldForm();
	};

	useEffect(() => {
		if (visible) {
			handleFieldForm();
		}
	}, [visible]);

	const onValuesChange = () => {};

	const handleFinishForm = (values: any) => {
		handleUpdateConfig(values);
		onClose();
	};

	return (
		<StyledDrawerGraphConfig
			title={t('ambassador.configure_graph')}
			placement="right"
			closable={true}
			onClose={handleClearOpenConfig}
			visible={visible}
			width={'100%'}
			{...other}>
			<StyledWrapperContent className="full" position="relative">
				<Form
					initialValues={{ ...DEFAULT_FORM_CONFIG }}
					form={form}
					onValuesChange={onValuesChange}
					onFinish={handleFinishForm}>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<StyledFormItem
								rules={[
									{
										required: true,
										message: t('campaign.validate.required', {
											field: t('ambassador.source'),
										}),
									},
								]}
								name={NAME_CONFIG.SOURCES}
								label={t('ambassador.series')}>
								<CheckboxGroup
									className="checkbox_group_full checkbox_group_custom vertical_checkbox"
									list={arrSource}
									otherCheckbox={{
										className:
											'checkbox_default checkbox_custom checkbox_dark mb_4',
									}}
									gapSpace="0px"
									direction="vertical"
								/>
							</StyledFormItem>
						</Col>
						<Col span={12}>
							<StyledFormItem
								margin="0 0 4px 0"
								name={NAME_CONFIG.INTERVAL}
								label={t('ambassador.edit_interval')}>
								<RadioGroup
									gapSpace="0px"
									direction="vertical"
									list={arrInterval}
								/>
							</StyledFormItem>
							<StyledFormItem
								valuePropName="checked"
								margin="0 0 8px 0"
								name={NAME_CONFIG.SHOW_CUMULATIVE_DATA}>
								<StyledCheckbox
									key={NAME_CONFIG.SHOW_CUMULATIVE_DATA}
									value={isShowCumulativeData}
									className="checkbox_default checkbox_custom checkbox_dark mb_4">
									<StyledText
										title={t('ambassador.show_cumulative_data')}
										className="overflow"
										whiteSpace="nowrap">
										{t('ambassador.show_cumulative_data')}
									</StyledText>
								</StyledCheckbox>
							</StyledFormItem>
							<StyledFormItem
								valuePropName="checked"
								name={NAME_CONFIG.SHOW_DYNAMIC_SCALE}>
								<StyledCheckbox
									key={NAME_CONFIG.SHOW_DYNAMIC_SCALE}
									value={isShowDynamicScale}
									className="checkbox_default checkbox_custom checkbox_dark mb_4">
									<StyledText
										title={t('ambassador.dynamic_scale')}
										className="overflow"
										whiteSpace="nowrap">
										{t('ambassador.dynamic_scale')}
									</StyledText>
								</StyledCheckbox>
							</StyledFormItem>
						</Col>
					</Row>
				</Form>
				<StyledWrapperActionConfig>
					<StyledWrapperAction>
						<Button onClick={() => handleFieldForm()}>{t('button.reset')}</Button>
						<Button
							style={{ marginLeft: '12px' }}
							onClick={() => form.submit()}
							type="primary">
							{t('button.apply')}
						</Button>
					</StyledWrapperAction>
				</StyledWrapperActionConfig>
			</StyledWrapperContent>
		</StyledDrawerGraphConfig>
	);
};

export default DrawerGraphConfig;
