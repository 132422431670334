import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { EMAIL_OPT_IN, TAB_WIDGET } from '@constants/content/widgetManager';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import {
	RefConfigWidgetBuilderStep,
	RefHandleActionChangeWidget,
} from '@models/content/widgetManager/ref';
import {
	StyledBodyStep,
	StyledFooterStep,
	StyledSettingDetailWidget,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { Button } from 'antd';
import React, {
	forwardRef,
	Ref,
	RefObject,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderStep from './HeaderStep';
import SettingStep1 from './SettingStep1';
import SettingStep2 from './SettingStep2';
import SettingStep3 from './SettingStep3';
import SettingStep4 from './SettingStep4';
import SettingStep5 from './SettingStep5';
import { useDispatch } from 'react-redux';
import { saveDraftWidgetBuilderRequest } from '@stores/actions';
import {
	WidgetBuilderDetailDto,
	WidgetBuilderDetailRequestDto,
} from '@models/content/widgetManager';
import _ from 'lodash';

const StyledRightAction = styled.div`
	display: inline-flex;
	align-items: center;
`;

const StyledButtonBack = styled(Button)`
	margin-right: 9px;
`;

const SettingDetailWidget = forwardRef((props, ref: Ref<RefHandleActionChangeWidget>) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const {
		currentStep,
		setCurrentStep,
		setTypeConfirm,
		setIsMobile,
		isMobile,
		hasChangeWidget,
		handleSaveAndCaptureThumbnail,
		detailWidget,
		setDetailWidget,
	} = useContext(WidgetBuilderContext);

	const { metadata } = detailWidget;
	const { emailOptIn = {} } = metadata.body || {};
	const { fileFontUploads } = metadata?.colors || {};

	const refConfigStep1 = useRef<
		RefConfigWidgetBuilderStep | undefined
	>() as RefObject<RefConfigWidgetBuilderStep>;
	const refConfigStep2 = useRef<
		RefConfigWidgetBuilderStep | undefined
	>() as RefObject<RefConfigWidgetBuilderStep>;
	const refConfigStep3 = useRef<
		RefConfigWidgetBuilderStep | undefined
	>() as RefObject<RefConfigWidgetBuilderStep>;
	const refConfigStep4 = useRef<
		RefConfigWidgetBuilderStep | undefined
	>() as RefObject<RefConfigWidgetBuilderStep>;
	const refConfigStep5 = useRef<
		RefConfigWidgetBuilderStep | undefined
	>() as RefObject<RefConfigWidgetBuilderStep>;

	const [stepFinish, setStepFinish] = useState<number>(0);

	useEffect(() => {
		initEmailOptIn();
	}, [detailWidget]);

	const initEmailOptIn = () => {
		if (!emailOptIn || _.isEmpty(emailOptIn)) {
			const emailOptIn = {
				name: EMAIL_OPT_IN.NOT_ADD_USER.name,
				optInMethod: EMAIL_OPT_IN.NOT_ADD_USER.method,
				fieldValues: {},
			};
			setDetailWidget((prev: WidgetBuilderDetailDto) => ({
				...prev,
				metadata: {
					...prev.metadata,
					body: {
						...prev.metadata.body,
						emailOptIn,
					},
				},
			}));
		}
	};

	const handleActionStep = ({ current, callBack }: { current: number; callBack?: Function }) => {
		if (hasChangeWidget) {
			const paramsRequest: any = { current };
			if (callBack && typeof callBack === 'function') {
				paramsRequest.callBack = callBack;
			}
			if (isMobile) {
				setIsMobile(false);
				setTimeout(() => {
					handleSaveAndCaptureThumbnail(paramsRequest);
					setIsMobile(true);
				}, 0);
			} else {
				handleSaveAndCaptureThumbnail(paramsRequest);
			}
		} else {
			setCurrentStep(current);
		}
	};

	const handleExitBuilder = () => {
		if (hasChangeWidget) {
			return setTypeConfirm(TYPE_ACTIONS.EXIT);
		}
		return history.push({
			pathname: ROUTE_PATH.WIDGET_MANAGER,
			state: {
				status: TAB_WIDGET.draft,
			},
		});
	};

	const handleChangeAction = (current: number) => {
		setStepFinish(current);

		if (currentStep === 0) {
			if (refConfigStep1?.current?.validateAllFieldCustom) {
				return refConfigStep1?.current?.validateAllFieldCustom();
			}
		}
		if (currentStep === 1) {
			if (
				refConfigStep2?.current?.validateAllFieldCustom &&
				fileFontUploads &&
				fileFontUploads.length > 0
			) {
				return refConfigStep2?.current?.validateAllFieldCustom();
			}
		}
		if (currentStep === 2) {
			if (refConfigStep3?.current?.validateAllFieldCustom) {
				return refConfigStep3?.current?.validateAllFieldCustom();
			}
		}

		if (currentStep === 4) {
			if (refConfigStep5?.current?.validateAllFieldCustom) {
				return refConfigStep5?.current?.validateAllFieldCustom(current);
			}
		}

		if (currentStep < 4) {
			return handleActionStep({ current });
		}

		return null;
	};

	const handleActionStep5 = (current: number) => {
		if (hasChangeWidget) {
			const dataRequest: WidgetBuilderDetailRequestDto = {
				...detailWidget,
				metadata: JSON.stringify(detailWidget?.metadata),
			};
			const paramsRequest: any = {
				dataRequest,
				callBack: () => setCurrentStep(current),
			};

			dispatch(saveDraftWidgetBuilderRequest(paramsRequest));
		} else {
			setCurrentStep(current);
		}
	};

	useImperativeHandle(ref, () => ({
		handleChangeAction,
	}));

	return (
		<StyledSettingDetailWidget>
			<HeaderStep handleChangeAction={handleChangeAction} />
			<StyledBodyStep className="custom_scroll_bar">
				{currentStep === 0 && (
					<SettingStep1
						ref={refConfigStep1}
						handleActionStep={() => handleActionStep({ current: stepFinish })}
					/>
				)}
				{currentStep === 1 && (
					<SettingStep2
						ref={refConfigStep2}
						handleActionStep={() => handleActionStep({ current: stepFinish })}
					/>
				)}
				{currentStep === 2 && (
					<SettingStep3
						handleActionStep={() => handleActionStep({ current: stepFinish })}
						ref={refConfigStep3}
					/>
				)}
				{currentStep === 3 && <SettingStep4 ref={refConfigStep4} />}
				{currentStep === 4 && (
					<SettingStep5 ref={refConfigStep5} handleActionStep={handleActionStep5} />
				)}
			</StyledBodyStep>
			<StyledFooterStep>
				<Button onClick={handleExitBuilder}>
					{t('content.widget_manager.button.exit')}
				</Button>
				<StyledRightAction>
					<StyledButtonBack
						disabled={currentStep === 0}
						onClick={() => handleChangeAction(currentStep - 1)}>
						{t('content.widget_manager.button.back')}
					</StyledButtonBack>
					<Button
						type="primary"
						onClick={() =>
							handleChangeAction(currentStep < 4 ? currentStep + 1 : currentStep)
						}>
						{t(`content.widget_manager.button.${currentStep < 4 ? 'next' : 'publish'}`)}
					</Button>
				</StyledRightAction>
			</StyledFooterStep>
		</StyledSettingDetailWidget>
	);
});

export default SettingDetailWidget;
