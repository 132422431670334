import { DEFAULT_COLUMN } from '@constants/publish/gallery';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { GalleryField } from '@models/publishing/galleries';
import React, { useEffect, useMemo, useState } from 'react';
import Gallery from 'react-photo-gallery';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import styled from 'styled-components';
import ThumbItem from './ThumbItem';

type PropSortGallery = {
	list: Array<ContentDto>;
	onSortEnd: SortEndHandler;
};

const SortablePhoto = SortableElement((item: any) => <ThumbItem {...item} />);
const SortableGallery = SortableContainer(
	({ items, listDefault }: { items: Array<GalleryField>; listDefault: Array<ContentDto> }) => {
		return (
			<Gallery
				columns={DEFAULT_COLUMN}
				photos={items}
				direction="column"
				renderImage={(props) => (
					<SortablePhoto
						items={items}
						{...props}
						listDefault={listDefault}
						key={Math.random()}
					/>
				)}
			/>
		);
	},
);

const defaultRatio = 1;

const SortableGalleryCustom = (props: PropSortGallery) => {
	const { list, onSortEnd } = props;

	const listGallery = useMemo(() => {
		return list?.map((item) => {
			return { ...item, src: item?.thumbnail, width: defaultRatio, height: defaultRatio };
		});
	}, [list]);

	if (listGallery?.length === 0) return null;
	return (
		<SortableGallery
			items={listGallery}
			listDefault={list}
			onSortEnd={onSortEnd}
			axis={'xy'}
			distance={1}
		/>
	);
};

export default SortableGalleryCustom;
