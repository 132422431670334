import THEME from '@constants/themes/themes';
import styled from 'styled-components';

const StyledSidebarInfo = styled.div`
	width: 360px;
	height: 100%;
	background-color: ${THEME.colors.darkBlue2};
	border-right: 1px solid ${THEME.colors.darkBlue1};
`;

export { StyledSidebarInfo };
