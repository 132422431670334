import CustomSelection from '@components/CustomLibrary/CustomSelection';
import CardSocial from '@components/SocialSearch/Feeds/SummaryFeed/CardSocial';
import { Paginator } from '@constants/paginator';
import { SOCIAL_TYPE } from '@constants/social_search/feeds';
import CreatorContentContext from '@contexts/Creators/Content';
import { SelectionCustomRef } from '@models/common/ref';
import { RefContentProfile } from '@models/creator/ref';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { StyledCol, StyledRow } from '@styled/Common/CommonStyled';
import { handleSelectItemWithLib } from '@utils/funcHelper';
import { renderThumbVertical } from '@utils/renderComponent';
import { convertSocialId } from '@utils/socialSearch';
import {
	Ref,
	RefObject,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { SelectableGroup, createSelectable } from 'react-selectable-fast';

const SelectableItem = createSelectable(CardSocial);

type SocialPostListProps = {
	handleSelecting: (socialPostList: SocialContentContainerDto[]) => void;
	handleScrollbars: (scrollbarTop: number) => void;
};

const SocialPostList = forwardRef((props: SocialPostListProps, ref: Ref<RefContentProfile>) => {
	const scrollbars = useRef<any>({});
	const selectionRef = useRef<any>({});
	const refSocial = useRef<any>({});
	const firstUpdate = useRef(true);
	const refEventSelect = useRef<
		SelectionCustomRef | undefined
	>() as RefObject<SelectionCustomRef>;

	const { socialPostList, param, handleContentDetails, showPostDetails, selectContentList } =
		useContext(CreatorContentContext);
	const { handleSelecting, handleScrollbars } = props;

	const [heightCard, setHeightCard] = useState<number>(0);

	useImperativeHandle(ref, () => ({
		clearSelecting,
	}));

	useEffect(() => {
		if (socialPostList.length && scrollbars.current && param.page === Paginator.defaultPage) {
			scrollbars.current.scrollToTop();
		}
	}, [socialPostList, param]);

	const handleSetWidthContent = () => {
		if (socialPostList.length > 0 && refSocial?.current?.offsetWidth) {
			// exclude padding and margin
			setHeightCard(
				Math.floor(refSocial.current.offsetWidth / (!showPostDetails ? 1.4 : 2) - 20),
			);
		}
	};

	useEffect(() => {
		handleSetWidthContent();
	}, [socialPostList, showPostDetails]);

	const handleSelection = (selectingItems: any) => {
		const selectContent: Array<SocialContentContainerDto> = [];
		selectingItems.map((selectingItem: any) => {
			const { props } = selectingItem;
			selectContent.push(props.social);
		});

		if (
			refEventSelect?.current?.handleSectionCustom &&
			typeof refEventSelect.current.handleSectionCustom === 'function'
		) {
			refEventSelect.current.handleSectionCustom(
				selectContent.map((item) => convertSocialId(item)),
			);
		}

		if (!refEventSelect?.current?.isShift) {
			handleSelecting(selectContent);
		}
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		const socials: SocialContentContainerDto[] = [];
		socialPostList.forEach((item) => {
			if (list.includes(convertSocialId(item))) {
				socials.push(item);
			}
		});
		handleSelecting(socials);
	};

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars?.current?.getValues().top;
			handleScrollbars(scrollbarTop);
		}
	};

	const onSelectItems = (id?: string) => {
		handleSelectItemWithLib(selectionRef, id);
	};

	const clearSelectionUsingRef = () => {
		if (selectionRef) {
			selectionRef.current.clearSelection();
		}
	};

	const clearSelecting = () => {
		handleSelecting([]);
		clearSelectionUsingRef();
	};

	return (
		<Scrollbars
			ref={(el) => {
				scrollbars.current = el;
			}}
			className="media-list-scrollbar"
			onScrollStop={onScrollStop}
			autoHeight
			autoHeightMin={100}
			autoHeightMax="calc(100vh - 170px)"
			renderTrackVertical={() => <div />}
			renderThumbVertical={renderThumbVertical}>
			<CustomSelection
				ref={refEventSelect}
				ids={socialPostList.map((item) => convertSocialId(item))}
				selectIds={selectContentList.map((item) => convertSocialId(item as any))}
				refSelection={selectionRef}
				handleSelectNewList={handleSelectNewList}
			/>
			<SelectableGroup
				className="selectable-group"
				clickClassName="tick"
				ref={selectionRef}
				enableDeselect={true}
				tolerance={0}
				deselectOnEsc={true}
				allowClickWithoutSelected={true}
				selectOnClick={false}
				onSelectionFinish={handleSelection}>
				<StyledRow gutter={[12, 8]}>
					{socialPostList.map((item: SocialContentContainerDto, idx: number) => (
						<StyledCol
							ref={(element) => {
								refSocial.current = element;
							}}
							key={convertSocialId(item)}
							span={showPostDetails ? 12 : 8}>
							{heightCard > 0 && (
								<SelectableItem
									selectContent={(val: SocialContentContainerDto) => {
										handleContentDetails(idx);
									}}
									heightCard={heightCard}
									social={item}
									id={convertSocialId(item)}
									thumbFull={!showPostDetails}
									keyItem={convertSocialId(item)}
									onSelectItems={() => onSelectItems(convertSocialId(item))}
									socialType={SOCIAL_TYPE.SOCIAL_POST}
								/>
							)}
						</StyledCol>
					))}
				</StyledRow>
			</SelectableGroup>
		</Scrollbars>
	);
});

export default SocialPostList;
