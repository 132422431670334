import { PARAMS_DEFAULT } from '@constants/common';
import { SORT_DIRECTION } from '@constants/filter/filterContent';
import { PARAMS_BASIC } from '@constants/publish/gallery';

const TEMPLATE_EDITOR_CONFIG = {
	CONTENT_STYLE:
		'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; margin: 0; background: red;}',
	PLUGINS: [
		'advlist autolink lists link image imagetools charmap print preview anchor',
		'searchreplace visualblocks code fullscreen',
		'insertdatetime media table paste code help wordcount',
	],
	TOOLBAR:
		'italic underline bold | forecolor |' +
		'bullist numlist | outdent indent |' +
		'formatselect | ',
	ICONS: {
		RESET: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.99967 4.16659C13.683 4.16659 16.6663 7.14992 16.6663 10.8333C16.6663 14.2333 14.1247 17.0333 10.833 17.4416V15.7583C13.1913 15.3583 14.9997 13.3083 14.9997 10.8333C14.9997 8.07492 12.758 5.83325 9.99967 5.83325C9.97979 5.83325 9.95801 5.83515 9.93523 5.83713C9.91028 5.83931 9.88415 5.84159 9.85801 5.84159L10.758 6.74159L9.58301 7.91659L6.66634 4.99992L9.58301 2.08325L10.758 3.26659L9.84967 4.17492C9.87467 4.17492 9.89967 4.17284 9.92467 4.17075C9.94967 4.16867 9.97467 4.16659 9.99967 4.16659ZM6.46634 7.29992C5.55801 8.20825 4.99967 9.45825 4.99967 10.8333C4.99967 13.3083 6.80801 15.3583 9.16634 15.7583V17.4416C5.87467 17.0333 3.33301 14.2333 3.33301 10.8333C3.33301 8.99159 4.08301 7.32492 5.28301 6.11659L6.46634 7.29992Z" fill="#E0E3E5"/></svg>',
	},
};

const PARAM_TEMPLATE = {
	...PARAMS_DEFAULT,
};

const PARAM_TEMPLATE_WIDGET = {
	...PARAMS_BASIC,
	sortBy: 'status',
	sortDirection: SORT_DIRECTION.ASC,
};

const BODY_TEMPLATE = {
	search: '',
	isShowApproved: false,
	isShowDrafts: false,
	isShowArchived: false,
};

export { TEMPLATE_EDITOR_CONFIG, PARAM_TEMPLATE, BODY_TEMPLATE, PARAM_TEMPLATE_WIDGET };
