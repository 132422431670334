import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LoadingWrapper } from '../../../../cores';

// Context
import MessageLogContext from '@contexts/Messages/Logs';

// Redux
import { getEmailEvent } from '@stores/actions';

// Components
import Logs from '@components/Messages/MessageLog/Logs';
import Preview from '@components/Messages/MessageLog/Preview';
import Recipients from '@components/Messages/MessageLog/Recipients';
import { EMAIL_LOG, TAB } from '@constants/messages';

const StyledWrapper = styled(Col)`
	height: calc(100vh - 66px);
`;

type GetEventEmailPayloadDto = {
	page: number;
	pageRecords: number;
	searchDate?: string;
	search: string;
	type: string;
};
const EmailLogs = () => {
	const dispatch = useDispatch();
	// const { onChangeIsSelectSentTab } = useContext(MessageLogContext);
	const [isSelectSentTab, setIsSelectSentTab] = useState<boolean>(true);
	const [selectedEmail, setSelectedEmail] = useState<number>();
	const [selectedEmailList, setSelectedEmailList] = useState<number[]>([]);
	const [isSelectDraftEmail, setIsSelectedDraftEmail] = useState<boolean>(false);
	const [eventEmailPayload, setEventEmailPayload] = useState<GetEventEmailPayloadDto>({
		page: 1,
		pageRecords: EMAIL_LOG.DEFAULT_PAGE_RECORD,
		search: '',
		type: TAB.SENT,
	});

	useEffect(() => {
		fetchEmailEvent(eventEmailPayload);
	}, [eventEmailPayload]);

	const fetchEmailEvent = (params: GetEventEmailPayloadDto) => {
		dispatch(getEmailEvent({ params: { ...params, sortBy: 'DESC' } }));
	};

	const handleChangeSelectedEmailList = (checked: boolean, id: number, onlyOne?: boolean) => {
		let newSelectedEmailList: any[] = [];

		const removeElement = (array: number[]) =>
			array.filter((emailId: number) => emailId !== id);

		const addElementToTop = (array: number[]) => [id, ...array];

		if (checked) {
			if (!selectedEmailList.includes(id)) {
				newSelectedEmailList = addElementToTop([...selectedEmailList]);
			} else {
				if (onlyOne) {
					newSelectedEmailList = removeElement([...selectedEmailList]);
					newSelectedEmailList = addElementToTop([...selectedEmailList]);
				}
			}
		} else {
			newSelectedEmailList = removeElement([...selectedEmailList]);
		}

		setSelectedEmailList(newSelectedEmailList);
	};

	const onResetSelectedEmailList = () => {
		setSelectedEmailList([]);
	};

	return (
		<MessageLogContext.Provider
			value={{
				isSelectSentTab,
				onChangeIsSelectSentTab: setIsSelectSentTab,
				eventEmailPayload,
				onChangeEventEmailPayload: (payload: GetEventEmailPayloadDto) =>
					setEventEmailPayload(payload),
				selectedEmail,
				onChangeSelectedEmail: (email: any) => setSelectedEmail(email),
				selectedEmailList,
				onChangeSelectedEmailList: (checked: boolean, id: number, onlyOne?: boolean) =>
					handleChangeSelectedEmailList(checked, id, onlyOne),
				onResetSelectedEmailList,
				isSelectDraftEmail,
				onIsSelectDraftEmail: (value: boolean) => setIsSelectedDraftEmail(value),
			}}>
			<LoadingWrapper isLoading={false}>
				<Row gutter={[16, 16]}>
					<StyledWrapper span={8}>
						<Logs />
					</StyledWrapper>
					<StyledWrapper span={16}>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<Recipients />
							</Col>
							<Col span={24}>
								<Preview />
							</Col>
						</Row>
					</StyledWrapper>
				</Row>
			</LoadingWrapper>
		</MessageLogContext.Provider>
	);
};

export default EmailLogs;
