import { IconMoreVert } from '@assets/icons';
import { Paginator } from '@constants/paginator';
import { SOCIAL } from '@constants/social_search';
import CreatorContentContext from '@contexts/Creators/Content';
import { ActionsTypes } from '@models/common/summary';
import { StyledDropdown, StyledIcon } from '@styled/Common/CommonStyled';
import { menuActions } from '@utils/renderComponent';
import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const SocialPostSource = () => {
	const { t } = useTranslation();
	const { param, setParam, showPostDetails, setShowPostDetails } =
		useContext(CreatorContentContext);
	const MORE_ACTION = {
		SHOW_POST_DETAILS: 'SHOW_POST_DETAILS',
		SHOW_AMBASSADOR_POST_ONLY: 'SHOW_AMBASSADOR_POST_ONLY',
	};

	const handleUpdatePayload = (socialType: string) => {
		const payloadTemp = { ...param, page: Paginator.defaultPage };

		if (payloadTemp.sources?.includes(socialType)) {
			payloadTemp.sources = payloadTemp.sources.filter(
				(source: string) => source !== socialType,
			);
		} else {
			payloadTemp.sources?.push(socialType);
		}

		setParam({ ...payloadTemp });
	};

	const actions: ActionsTypes[] = [
		{
			id: MORE_ACTION.SHOW_POST_DETAILS,
			name: t('creator_profile.ambassador.show_post_details'),
			click: () => setShowPostDetails(!showPostDetails),
		},
		{
			id: SOCIAL.INSTAGRAM,
			name: t('creator_profile.ambassador.show_instagram_posts'),
			click: () => handleUpdatePayload(SOCIAL.INSTAGRAM),
		},
		{
			id: SOCIAL.TIKTOK,
			name: t('creator_profile.ambassador.show_tiktok_posts'),
			click: () => handleUpdatePayload(SOCIAL.TIKTOK),
		},
		{
			id: SOCIAL.TWITTER,
			name: t('creator_profile.ambassador.show_twitter_posts'),
			click: () => handleUpdatePayload(SOCIAL.TWITTER),
		},
		{
			id: SOCIAL.YOUTUBE,
			name: t('creator_profile.ambassador.show_youtube_posts'),
			click: () => handleUpdatePayload(SOCIAL.YOUTUBE),
		},
		{
			id: MORE_ACTION.SHOW_AMBASSADOR_POST_ONLY,
			name: t('creator_profile.ambassador.show_ambassador_posts'),
			click: () => {
				setParam({ ...param, isShowAmbassadorPostOnly: !param.isShowAmbassadorPostOnly });
			},
		},
	];

	const checkedList = () => {
		const listTemp: string[] = _.cloneDeep(param?.sources) || [];
		if (showPostDetails) {
			listTemp.push(MORE_ACTION.SHOW_POST_DETAILS);
		}
		if (param.isShowAmbassadorPostOnly) {
			listTemp.push(MORE_ACTION.SHOW_AMBASSADOR_POST_ONLY);
		}
		return listTemp;
	};

	return (
		<StyledDropdown
			overlayClassName="dropdown_chip_option"
			overlay={menuActions(actions, checkedList())}
			trigger={['click']}>
			<StyledIcon margin="16px">
				<IconMoreVert />
			</StyledIcon>
		</StyledDropdown>
	);
};

export default SocialPostSource;
