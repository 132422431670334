import React, { useState, useEffect, useContext, useImperativeHandle, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Form, message, DatePicker, InputNumber, Row, Col, Slider } from 'antd';
import _ from 'lodash';
import THEME from '@constants/themes/themes';

// Styled
import {
	StyledText,
	StyledContainer,
	StyledInputNumber,
	StyledSelect,
	StyledFormItem,
	StyledSlider,
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledSliderWrapper,
	StyledPopconfirm,
} from '@styled/Rewards/FilterStyled';
import {
	StyledTitle,
	StyledSection,
	StyledBtn,
	StyledBtnGhost,
} from '@styled/Content/ContentLibrary/ContentStyled';

// Icons
import {
	IconFlag,
	IconGallery,
	IconLabel,
	IconRestartAlt,
	IconSort,
	IconCampaign,
	IconReward,
	IconClose,
} from '@assets/icons';
import RewardsHistoryContext from '@contexts/Rewards/History';
import {
	DISCOUNT,
	GIFTCARD,
	PRODUCT,
	CASH,
	CUSTOM,
	REDEEMED,
	SENT,
	CANCELLED,
	FAILED,
} from '@constants/reward';
import { TYPE_FORMAT_DATE } from '@constants/common';

const { Option } = Select;

type FilterProps = {
	closeFilters: () => void;
	// callAPIFilterContent?: (filters: any) => void;
	// resetContentFilters?: (isSwitchFolder?: boolean) => void;
};

const { RangePicker } = DatePicker;

const Filter = (props: FilterProps) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		discountList,
		campaignList,
		handleChangeGetRewardsHistoryParams,
		getListRewardsParams,
	} = useContext(RewardsHistoryContext);

	const [isReset, setIsReset] = useState<boolean>(false);
	const [estimatedCashValue, setEstimatedCashValue] = useState<any>({
		min: 0.0,
		max: 99999.0,
	});
	const [actualCashValue, setActualCashValue] = useState<any>({
		min: 0.0,
		max: 99999.0,
	});

	// Props
	const { closeFilters } = props;

	const rewardType = [
		{
			name: 'rewards.section.discount',
			value: DISCOUNT,
		},
		{
			name: 'rewards.section.gift_card',
			value: GIFTCARD,
		},
		{
			name: 'rewards.section.product',
			value: PRODUCT,
		},
		{
			name: 'rewards.section.cash',
			value: CASH,
		},
		{
			name: 'rewards.section.custom',
			value: CUSTOM,
		},
	];

	const rewardStatus = [
		{
			name: 'rewards_history.status.sent',
			value: SENT,
		},
		{
			name: 'rewards_history.status.cancelled',
			value: CANCELLED,
		},
		{
			name: 'rewards_history.status.redeemed',
			value: REDEEMED,
		},
		{
			name: 'rewards_history.status.failed',
			value: FAILED,
		},
	];

	useEffect(() => {
		if (isReset) {
			handleApplyFilter();
			setIsReset(false);
		}
	}, [isReset]);

	const resetFilters = () => {
		form.resetFields();
		setEstimatedCashValue({ min: 0.0, max: 99999.0 });
		setActualCashValue({ min: 0.0, max: 99999.0 });
		setIsReset(true);
	};

	const handleApplyFilter = () => {
		const values = form.getFieldsValue();
		const { params } = getListRewardsParams;

		const payload = {
			params: {
				...params,
				page: 1,
				filter: formatFilter(values),
			},
		};
		handleChangeGetRewardsHistoryParams(payload);
	};

	const formatFilter = (values: any) => {
		const { rewardDate, rewardType, rewardStatus, discountAmount, campaigns } = values;
		const filter: any = {};
		if (actualCashValue.min !== 0.0) {
			filter.minActual = actualCashValue.min;
		}
		if (actualCashValue.max !== 99999.0) {
			filter.maxActual = actualCashValue.max;
		}
		if (estimatedCashValue.min !== 0.0) {
			filter.minEstimate = estimatedCashValue.min;
		}
		if (estimatedCashValue.max !== 99999.0) {
			filter.maxEstimate = estimatedCashValue.max;
		}
		if (rewardDate) {
			filter.startDate = rewardDate[0]
				.set('hour', 0)
				.set('minute', 0)
				.set('second', 0)
				.valueOf();
			filter.endDate = rewardDate[1]
				.set('hour', 23)
				.set('minute', 59)
				.set('second', 59)
				.valueOf();
		}
		if (rewardType) {
			filter.rewardType = rewardType;
		}
		if (rewardStatus) {
			filter.rewardStatus = rewardStatus;
		}
		if (campaigns?.length > 0) {
			filter.campaignName = campaigns;
		}
		if (discountAmount?.length > 0) {
			filter.discountAmount = discountAmount.map((amount: string) => amount.slice(0, -4));
		}
		return filter;
	};

	const handleChangeEstimatedCashValue = (value: number, type: string) => {
		const temp = { ...estimatedCashValue };
		temp[`${type}`] = value;
		setEstimatedCashValue(temp);
	};

	const handleChangeEstimatedCashValueSlider = (value: number[]) => {
		const temp = { min: value[0], max: value[1] };
		setEstimatedCashValue(temp);
	};

	const handleChangeActualCashValue = (value: number, type: string) => {
		const temp = { ...actualCashValue };
		temp[`${type}`] = value;
		setActualCashValue(temp);
	};

	const handleChangeActualCashValueSlider = (value: number[]) => {
		const temp = { min: value[0], max: value[1] };
		setActualCashValue(temp);
	};

	return (
		<div className="filter-content">
			<StyledSection
				borderPosition="bottom"
				heightSection="54px"
				flexDirection="row"
				padding="16px 16px 16px 24px">
				<StyledTitle fontSize="1.143rem" fontWeight="600">
					{t('content_management.filter.title')}
				</StyledTitle>

				<StyledTitle
					onClick={closeFilters}
					cursor="pointer"
					fontSize="0.857rem"
					color={THEME.colors.gray3}>
					<IconClose />
				</StyledTitle>
			</StyledSection>

			<StyledContainer>
				<Form
					form={form}
					layout="vertical"
					// initialValues={filters}
					// onValuesChange={onchange}
				>
					<StyledFormItem
						name="rewardDate"
						label={t('rewards_history.filter.reward_date')}>
						<RangePicker format={TYPE_FORMAT_DATE.MONTH_DAY} />
					</StyledFormItem>

					<StyledFormItem
						name="rewardType"
						label={t('rewards_history.filter.reward_type')}>
						<StyledSelect
							virtual={false}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							dropdownClassName="dropdown-menu dropdown-custom-content">
							{rewardType.map((item) => (
								<Option value={item.value}>{t(`${item.name}`)}</Option>
							))}
						</StyledSelect>
					</StyledFormItem>

					<StyledFormItem
						name="rewardStatus"
						label={t('rewards_history.filter.reward_status')}>
						<StyledCheckboxGroup>
							<Row>
								{rewardStatus.map((item) => (
									<Col span={12}>
										<StyledCheckbox
											value={item.value}
											style={{ lineHeight: '32px' }}>
											{t(`${item.name}`)}
										</StyledCheckbox>
									</Col>
								))}
							</Row>
						</StyledCheckboxGroup>
					</StyledFormItem>

					<StyledFormItem
						name="discountAmount"
						label={t('rewards_history.filter.amount')}>
						<StyledSelect
							mode="multiple"
							showArrow={true}
							virtual={false}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							dropdownClassName="dropdown-menu dropdown-custom-content">
							{discountList.map((item: any) => (
								<Option value={item}>{item}</Option>
							))}
						</StyledSelect>
					</StyledFormItem>

					<span>{t('rewards_history.filter.estimated_cash_value')}</span>
					<StyledSliderWrapper>
						<InputNumber
							value={estimatedCashValue.min}
							step={0.1}
							onChange={(value) => handleChangeEstimatedCashValue(value, 'min')}
						/>
						<StyledSlider
							range
							step={0.1}
							min={0}
							max={99999}
							value={[estimatedCashValue.min, estimatedCashValue.max]}
							onChange={handleChangeEstimatedCashValueSlider}
						/>
						<InputNumber
							value={estimatedCashValue.max}
							step={0.1}
							onChange={(value) => handleChangeEstimatedCashValue(value, 'max')}
						/>
					</StyledSliderWrapper>

					<span>{t('rewards_history.filter.actual_cash_value')}</span>
					<StyledSliderWrapper>
						<InputNumber
							value={actualCashValue.min}
							step={0.1}
							onChange={(value) => handleChangeActualCashValue(value, 'min')}
						/>
						<StyledSlider
							range
							step={0.1}
							min={0}
							max={99999}
							value={[actualCashValue.min, actualCashValue.max]}
							onChange={handleChangeActualCashValueSlider}
						/>
						<InputNumber
							value={actualCashValue.max}
							step={0.1}
							onChange={(value) => handleChangeActualCashValue(value, 'max')}
						/>
					</StyledSliderWrapper>

					<StyledFormItem name="campaigns" label={t('rewards_history.filter.campaigns')}>
						<StyledSelect
							mode="multiple"
							virtual={false}
							showArrow={true}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							dropdownClassName="dropdown-menu dropdown-custom-content">
							{campaignList?.map((item) => (
								<Option value={item}>{item}</Option>
							))}
						</StyledSelect>
					</StyledFormItem>
				</Form>
			</StyledContainer>

			<StyledSection
				justify="space-between"
				borderPosition="top"
				heightSection="64px"
				flexDirection="row">
				<StyledBtn onClick={handleApplyFilter}>
					{t('content_management.filter.apply_filters')}
				</StyledBtn>

				<StyledTitle
					onClick={resetFilters}
					cursor="pointer"
					fontSize="0.857rem"
					color={THEME.colors.gray3}
					margin="0">
					<IconRestartAlt />
					{t('content_management.filter.reset_filters')}
				</StyledTitle>
			</StyledSection>
		</div>
	);
};

export default Filter;
