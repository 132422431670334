import THEME from '@constants/themes/themes';
import _ from 'lodash';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import LoadingWrapper from '@cores/LoadingWrapper';
import {
	StyledButton,
	StyledCol,
	StyledForm,
	StyledFormItem,
	StyledInput,
	StyledRow,
	StyledSelect,
} from '@styled/Common/CommonStyled';
import {
	StyledBtn,
	StyledSection,
	StyledTitle,
} from '@styled/Content/ContentLibrary/ContentStyled';
import { StyledCascader } from '@styled/Content/ContentLibrary/DownloadStyled';
import { StyledRadio } from '@styled/Messages/EmailBuilderStyled';
import { Col, Form, Input, message, Row, Select, Space } from 'antd';

import { IconClose } from '@assets/icons';
import { GENERATE_CODE_TYPE, OFFERS_REWARD } from '@constants/messages';

import { PATTERNS } from '@constants/common';
import { REWARD_STATUS } from '@constants/reward';
import { SPECIAL_CHARS } from '@constants/settings';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import Wrapper from '@cores/Wrapper';
import { GenerateCodeDto, ListRuleRewardDto, RewardContainerDto } from '@models/messages/summary';
import { RewardStore } from '@models/rewards/store';
import { generateCodeEnd, generateCodeRequest } from '@stores/actions';
import { StyledRadioButton } from '@styled/Content/ContentLibrary/FilterStyled';
import { validateInputNumber } from '@utils/common';
import { getOfferList } from '@utils/reward';

type ConfigureRewardProps = {
	handleClose?: () => void;
	processReward?: (reward: RewardContainerDto) => void;
	editedReward?: any;
	setIsEditReward?: (isEdit: boolean) => void;
	isCustomized?: boolean;
};

const { Option } = Select;

const ConfigureReward = forwardRef((props: ConfigureRewardProps, ref: Ref<any>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [rewardForm] = Form.useForm();

	const { rewardOffers, generateCodeStatus, generatedCode, getRewardOffersStatus } = useSelector(
		(state: any) => state.messages,
	);
	const { logRewardStatus }: RewardStore = useSelector((state: any) => state.rewards);

	const { handleClose, processReward, editedReward, setIsEditReward, isCustomized } = props;

	const [selectedReward, setSelectedReward] = useState<any>({});
	const [discountCode, setDiscountCode] = useState<any>({
		random: undefined,
		imported: undefined,
	});

	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEditOffer, setIsEditOffer] = useState<boolean>(true);
	const isEdit = !_.isUndefined(editedReward);

	useImperativeHandle(ref, () => ({
		handleSubmitReward,
	}));

	useEffect(() => {
		return () => {
			dispatch(generateCodeEnd());
			rewardForm.resetFields();
		};
	}, []);

	useEffect(() => {
		if (generateCodeStatus && generateCodeStatus === SUCCEEDED) {
			setIsLoading(false);

			if (generatedCode.isUpdate) {
				if (!isCustomized) {
					message.success(
						isEdit ? t('notification.save_success') : t('notification.apply_success'),
					);
				}
				handleProcessReward();
			} else {
				if (generatedCode && !_.isEmpty(generatedCode)) {
					let rewardValueTemp = selectedReward?.rewardValue;
					const addedCode = {
						codeValue: generatedCode.code,
						generateCodeType: GENERATE_CODE_TYPE.RANDOM,
					};
					rewardValueTemp = { ...rewardValueTemp, codeValue: addedCode.codeValue };
					rewardForm.setFieldsValue(addedCode);

					setDiscountCode({ ...discountCode, random: generatedCode.code });
					setSelectedReward({
						...selectedReward,
						rewardValue: rewardValueTemp,
						generateCodeType: addedCode.generateCodeType,
					});
				}
			}
		}

		if (generateCodeStatus && generateCodeStatus === FAILED) {
			setIsLoading(false);
		}
	}, [generateCodeStatus]);

	useEffect(() => {
		if (!_.isUndefined(editedReward)) {
			setSelectedReward(editedReward);
			setIsEditOffer(false);

			const { rewardValue, generateCodeType } = editedReward;
			let discountCodeTemp = discountCode;

			if (generateCodeType === GENERATE_CODE_TYPE.RANDOM) {
				discountCodeTemp = { ...discountCodeTemp, random: rewardValue.codeValue };
				setDiscountCode(discountCodeTemp);
			}
			if (generateCodeType === GENERATE_CODE_TYPE.IMPORTED) {
				discountCodeTemp = { ...discountCodeTemp, imported: rewardValue.codeValue };
				setDiscountCode(discountCodeTemp);
			}

			rewardForm.setFieldsValue({
				codeValue: rewardValue.codeValue,
				generateCodeType,
			});
		}
	}, [editedReward]);

	let isCheckImportedCode: boolean = false;

	if (!_.isEmpty(selectedReward)) {
		const { rewardValue } = selectedReward || {};

		isCheckImportedCode =
			rewardValue?.listRuleCode &&
			'imported' in rewardValue?.listRuleCode &&
			rewardValue?.listRuleCode?.imported.length;
	}

	const handlePopup = () => {
		setIsVisible(!isVisible);
	};

	const onChangeOffer = (value: string, selectedOptions: any) => {
		let selectedRewardTemp: any = {};
		let rewardValue: any = {};
		let rewardType = selectedOptions[0].value;
		const offerId = selectedOptions[1].value;

		if (!_.isEmpty(selectedReward)) {
			const { rewardValue: { id = 0 } = {} } = selectedReward;

			if (offerId !== id) {
				setDiscountCode({
					random: undefined,
					imported: undefined,
				});

				rewardForm.setFieldsValue({
					codeValue: '',
					actualCashValue: '',
					productUrl: '',
					productDescription: '',
				});

				selectedRewardTemp = { ...selectedRewardTemp, generateCodeType: '' };
				setIsEditOffer(true);
				dispatch(generateCodeEnd());
			}
		}

		if (
			rewardType === OFFERS_REWARD.PERCENTAGE.value ||
			rewardType === OFFERS_REWARD.FIXED.value
		) {
			rewardType = OFFERS_REWARD.DISCOUNT.value;
		}

		rewardOffers.forEach((element: any) => {
			const { listRule } = element;

			listRule.forEach((reward: ListRuleRewardDto) => {
				const { id, listRuleCode } = reward;

				if (id === offerId) {
					reward = { ...reward, listRuleCode: _.groupBy(listRuleCode, 'source') };
					rewardValue = reward;
				}
			});
		});

		const { estimatedCashValue, description, product } = rewardValue;

		rewardForm.setFieldsValue({
			actualCashValue: estimatedCashValue,
		});

		rewardValue = { ...rewardValue, actualCashValue: estimatedCashValue };

		if (rewardType === OFFERS_REWARD.PRODUCT.value) {
			rewardForm.setFieldsValue({
				productDescription: description,
				productUrl: product?.webUrl || '',
			});
		}

		selectedRewardTemp = {
			...selectedRewardTemp,
			rewardType,
			rewardValue,
		};

		resetFormData();
		setSelectedReward({ ...selectedRewardTemp });
	};

	const resetFormData = () => {
		if (isCustomized) {
			rewardForm.setFieldsValue({
				status: '',
			});
		}
	};

	const renderPercentageOffer = () => {
		const { rewardValue } = selectedReward;

		return (
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<StyledFormItem
						color={THEME.colors.gray1}
						name="estimatedCashValue"
						label={t('email_builder.estimated_cash_value')}>
						<span>$ {rewardValue?.estimatedCashValue}</span>
					</StyledFormItem>
				</Col>
				<Col span={12}>
					<StyledFormItem
						color={THEME.colors.gray1}
						name="actualCashValue"
						label={t('email_builder.actual_cash_value')}
						rules={[
							{
								required: true,
								message: t('email_builder.validation.actual_cash'),
							},
							{
								pattern: /^(\d*[1-9]\d*(\.\d+)?|0*\.\d*[1-9]\d*)$/,
								message: `${t(
									'email_builder.validation.actual_cash',
								)} is greater than 0`,
							},
						]}>
						<StyledInput
							prefix={SPECIAL_CHARS.DOLLAR}
							onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
								validateInputNumber(event, true);
							}}
						/>
					</StyledFormItem>
				</Col>
			</Row>
		);
	};

	const renderFixOrGiftCardOffer = () => {
		const { rewardValue, rewardType } = selectedReward;
		const isGiftCard = rewardType === OFFERS_REWARD.GIFT_CARD.value;
		const value = isGiftCard ? rewardValue?.estimatedCashValue : rewardValue?.discountAmount;

		return (
			<StyledFormItem
				color={THEME.colors.gray1}
				name={isGiftCard ? 'estimatedCashValue' : 'discountAmount'}
				label={t('email_builder.amount')}>
				<span>$ {value || 0}</span>
			</StyledFormItem>
		);
	};

	const renderProductOffer = () => {
		return (
			<React.Fragment>
				{renderPercentageOffer()}
				<StyledFormItem
					color={THEME.colors.gray1}
					name="productUrl"
					label={t('email_builder.product_URL')}
					rules={[
						{
							required: true,
							message: t('email_builder.validation.product_url'),
						},
					]}>
					<Input />
				</StyledFormItem>
				<StyledFormItem
					color={THEME.colors.gray1}
					name="productDescription"
					label={t('email_builder.product_description')}
					rules={[
						{
							required: true,
							message: t('email_builder.validation.product_desciption'),
						},
					]}>
					<Input.TextArea rows={3} />
				</StyledFormItem>
			</React.Fragment>
		);
	};

	const renderFormItem = () => {
		const { rewardType, rewardValue } = selectedReward;

		switch (rewardType) {
			case OFFERS_REWARD.DISCOUNT.value:
				if (rewardValue?.discountType === OFFERS_REWARD.PERCENTAGE.value.toUpperCase()) {
					return renderPercentageOffer();
				}
				if (rewardValue?.discountType === OFFERS_REWARD.FIXED.value.toUpperCase()) {
					return renderFixOrGiftCardOffer();
				}
				break;

			case OFFERS_REWARD.GIFT_CARD.value:
				return renderFixOrGiftCardOffer();

			case OFFERS_REWARD.PRODUCT.value:
				return renderProductOffer();

			default:
				break;
		}
		return null;
	};

	const generateCode = (generateType: string, customCode?: string, isUpdate?: boolean) => {
		setIsLoading(true);

		const { rewardValue } = selectedReward;
		let { rewardType } = selectedReward;

		if (rewardType === OFFERS_REWARD.GIFT_CARD.value) {
			rewardType = rewardType.replace('-', '');
		}

		let requestParams: GenerateCodeDto = {
			rewardType: rewardType.toUpperCase(),
			generateType,
			ruleId: rewardValue.id,
			isUpdate,
		};

		if (customCode) requestParams = { ...requestParams, customCode };
		dispatch(generateCodeRequest(requestParams));
	};

	const handleChangeOffer = (changedValues: any) => {
		let { rewardValue } = selectedReward;

		if (!('offer' in changedValues) && !('generateCodeType' in changedValues)) {
			if ('productDescription' in changedValues) {
				rewardValue = { ...rewardValue, description: changedValues.productDescription };
			}

			if ('productUrl' in changedValues) {
				let { product } = rewardValue;
				product = { ...product, webUrl: changedValues.productUrl };
				rewardValue = { ...rewardValue, product };
			}

			rewardValue = { ...rewardValue, ...changedValues };
			setSelectedReward({ ...selectedReward, rewardValue });
		}

		if ('generateCodeType' in changedValues) {
			let { codeValue } = rewardValue;

			if (changedValues.generateCodeType === GENERATE_CODE_TYPE.IMPORTED) {
				if (isCheckImportedCode) {
					codeValue = !_.isEmpty(discountCode.imported)
						? discountCode.imported
						: rewardValue.listRuleCode.imported[0].code;

					rewardValue = {
						...rewardValue,
						codeValue,
					};

					rewardForm.setFieldsValue({
						codeValue,
					});
				}
			}

			if (changedValues.generateCodeType === GENERATE_CODE_TYPE.RANDOM) {
				if (generatedCode && !_.isEmpty(generatedCode)) {
					codeValue = discountCode.random || generatedCode.code;

					rewardForm.setFieldsValue({
						codeValue,
					});

					rewardValue = {
						...rewardValue,
						codeValue,
					};
				}
			}

			setSelectedReward({
				...selectedReward,
				...changedValues,
				rewardValue,
			});
		}
	};

	const handleUpdateCode = () => {
		const { rewardValue } = selectedReward || {};
		let { generateCodeType } = selectedReward || {};
		const { codeValue } = rewardValue;

		if (
			(!generateCodeType && codeValue) ||
			(generateCodeType === GENERATE_CODE_TYPE.RANDOM &&
				generatedCode &&
				codeValue !== generatedCode.code)
		) {
			generateCodeType = GENERATE_CODE_TYPE.CUSTOM;
			generateCode(generateCodeType, codeValue, true);
		} else {
			handleProcessReward();
		}
	};

	const handleProcessReward = () => {
		if (!isCustomized) {
			handlePopup();
		}
		if (processReward) {
			processReward(selectedReward);
		}
	};

	const onApplyReward = () => {
		handleUpdateCode();
		if (setIsEditReward) {
			setIsEditReward(false);
		}
	};

	const initSelectOfferValue = () => {
		let offerTemp = [editedReward?.rewardType, editedReward?.rewardValue?.id];
		if (editedReward?.rewardType === OFFERS_REWARD.DISCOUNT.value) {
			offerTemp = [
				editedReward?.rewardValue?.discountType.toLowerCase(),
				editedReward?.rewardValue?.id,
			];
		}

		return offerTemp;
	};

	const initialValuesForm = () => {
		if (!_.isUndefined(editedReward) && !_.isEmpty(editedReward)) {
			const {
				rewardValue: {
					actualCashValue = '',
					description = '',
					codeValue = '',
					product = {},
				} = {},
			} = editedReward;

			const initValues = {
				offer: initSelectOfferValue(),
				codeValue,
				actualCashValue,
				productUrl: !_.isNull(product) ? product?.webUrl : '',
				productDescription: description,
			};

			return initValues;
		}

		return {};
	};

	const renderImportedCode = () => {
		const { rewardValue } = selectedReward;
		const importedCodeList = rewardValue.listRuleCode.imported;

		return (
			<Select value={importedCodeList[0].code}>
				{importedCodeList.map((importedCode: any) => {
					const { code } = importedCode;
					return (
						<Option key={code} value={code}>
							{code}
						</Option>
					);
				})}
			</Select>
		);
	};

	const copyCode = () => {
		const { rewardValue } = selectedReward;
		if (rewardValue?.codeValue) {
			navigator.clipboard.writeText(rewardValue?.codeValue);
			message.success(t('message.copied'));
		}
	};

	const handleSubmitReward = () => {
		rewardForm.submit();
	};

	const rewardStatusOptions = () => {
		return Object.values(REWARD_STATUS);
	};

	const isGeneralLoading =
		isLoading || logRewardStatus === IN_PROGRESS || getRewardOffersStatus === IN_PROGRESS;

	return (
		<LoadingWrapper isLoading={isGeneralLoading}>
			{!isCustomized && (
				<StyledSection heightSection="54px" flexDirection="row" padding="0 0 16px 0">
					<StyledTitle fontSize="1.143rem" fontWeight="600">
						{t('email_builder.configure_reward')}
					</StyledTitle>
					<IconClose className="icon-close" onClick={handleClose} />
				</StyledSection>
			)}

			<Wrapper height="fit-content">
				<StyledForm
					form={rewardForm}
					initialValues={initialValuesForm()}
					onValuesChange={handleChangeOffer}
					layout="vertical"
					requiredMark={false}
					onFinish={onApplyReward}>
					<StyledFormItem
						color={THEME.colors.gray1}
						name="offer"
						label={t('email_builder.select_offer')}
						rules={[
							{
								required: true,
								message: t('email_builder.validation.select_offer'),
							},
						]}>
						<StyledCascader
							allowClear={false}
							onChange={onChangeOffer}
							changeOnSelect={false}
							popupClassName="select-offers-cascader"
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							options={getOfferList(rewardOffers)}
							placeholder={t('content_management.placeholder.please_select')}
						/>
					</StyledFormItem>

					{renderFormItem()}

					{!_.isEmpty(selectedReward) &&
						selectedReward.rewardType !== OFFERS_REWARD.PRODUCT.value && (
							<React.Fragment>
								<StyledRow gutter={12}>
									<StyledCol span={12}>
										<StyledFormItem
											isDisabled={!isEditOffer}
											color={THEME.colors.gray1}
											name="codeValue"
											label={t('email_builder.discount_code')}
											rules={[
												{
													required: true,
													message: t(
														'email_builder.validation.discount_code',
													),
												},
												{
													pattern: PATTERNS.REWARD_DISCOUNT_CODE,
													message: t(
														'email_builder.validation.discount_code_rule',
													),
												},
											]}>
											{selectedReward.generateCodeType ===
											GENERATE_CODE_TYPE.IMPORTED ? (
												renderImportedCode()
											) : (
												<Input />
											)}
										</StyledFormItem>
									</StyledCol>
									<StyledCol>
										<StyledFormItem margin="30px 0">
											<StyledButton onClick={copyCode}>
												{t('button.copy_code')}
											</StyledButton>
										</StyledFormItem>
									</StyledCol>
								</StyledRow>

								{((!_.isEmpty(generatedCode) &&
									generatedCode.source !== GENERATE_CODE_TYPE.USER_DEFINE) ||
									discountCode.random ||
									discountCode.imported) && (
									<StyledFormItem
										isDisabled={!isEditOffer}
										color={THEME.colors.gray1}
										name="generateCodeType">
										<StyledRadioButton>
											<Space direction="vertical">
												<StyledRadio value={GENERATE_CODE_TYPE.RANDOM}>
													{t('email_builder.random_code')}
												</StyledRadio>

												<StyledRadio
													isDisabled={!isCheckImportedCode}
													value={GENERATE_CODE_TYPE.IMPORTED}>
													{t('email_builder.imported_code')}
												</StyledRadio>
											</Space>
										</StyledRadioButton>
									</StyledFormItem>
								)}

								{!discountCode.random && !discountCode.imported && (
									<StyledFormItem>
										<StyledBtn
											width="unset"
											padding="6px 16px"
											margin="16px 0 0 0"
											onClick={() => generateCode(GENERATE_CODE_TYPE.RANDOM)}>
											{t('email_builder.generate_code')}
										</StyledBtn>
									</StyledFormItem>
								)}
							</React.Fragment>
						)}

					{isCustomized && (
						<StyledFormItem
							name="status"
							color={THEME.colors.gray1}
							label={t('rewards_history.filter.reward_status')}
							rules={[
								{
									required: true,
									message: t('validate.common_required', {
										field: t('rewards_history.filter.reward_status'),
									}),
								},
							]}>
							<StyledSelect
								style={{ height: '30px' }}
								options={rewardStatusOptions()}
							/>
						</StyledFormItem>
					)}
				</StyledForm>
			</Wrapper>
		</LoadingWrapper>
	);
});

export default ConfigureReward;
