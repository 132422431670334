import { IconExpandMore } from '@assets/icons';
import { DEFAULT_WIDTH_COL, SORT_DIRECTION, TYPE_FORMAT_DATE } from '@constants/common';
import {
	ELEMENT_SELECTOR,
	TASK_DEFAULT_PAYLOAD,
	TASK_PARAMS_DEFAULT,
} from '@constants/taskManager';
import TaskManagerContext from '@contexts/TaskManager';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { AccountItemLiteDto, TaskItemDto, UpdateTaskPayloadDto } from '@models/taskmanager';
import { TaskManagerStoreDto } from '@models/taskmanager/store';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { StyledPagination } from '@styled/Settings/AccountUserStyled';
import { StyledAssignedUser, StyledTaskList } from '@styled/TaskManager/TaskManagerStyled';
import { renderCopiedText } from '@utils/renderComponent';
import { Affix, Pagination } from 'antd';
import { SelectValue } from 'antd/lib/select';
import _ from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TaskDescription from '../TaskDescription';
import TaskStatus from '../TaskStatus';

const TaskList = () => {
	const { t } = useTranslation();
	const { accountListLite = [] }: TaskManagerStoreDto = useSelector(
		(state: any) => state?.taskManager,
	);
	const {
		requestPayload,
		setRequestPayload,
		taskManager,
		setTaskManager,
		selectedRowKeys,
		setSelectedRowKeys,
		setSelectedTask,
		handleUpdateTask,
	} = useContext(TaskManagerContext);

	const renderOptions = (optionsList: AccountItemLiteDto[]) => {
		return optionsList?.map((item: AccountItemLiteDto) => ({
			label: item.fullName,
			value: item.email,
		}));
	};

	const updateTaskList = (
		changedValue: any,
		fieldName: string,
		record: TaskItemDto,
		list: TaskItemDto[],
	) => {
		const listTemp = _.cloneDeep(list);
		if (listTemp) {
			const index = listTemp?.findIndex((item: TaskItemDto) => item?.id === record?.id);
			if (index !== -1) {
				listTemp[index][fieldName] = changedValue;
			}
		}
		return listTemp;
	};

	const assignTo = (changedValue: SelectValue, record: TaskItemDto) => {
		const taskList = taskManager?.tasks;
		if (taskList && changedValue) {
			const payload = {
				id: record.id,
				params: {
					assignedToEmail: changedValue,
				} as UpdateTaskPayloadDto,
			};
			handleUpdateTask(payload);
			setTaskManager({
				...taskManager,
				tasks: updateTaskList(changedValue, 'assignedToEmail', record, taskList),
			});
		}
	};

	const renderAssignedTo = (assignedTo: string, record: TaskItemDto) => {
		return (
			<StyledAssignedUser
				dropdownClassName="assigned_user_select_dropdown"
				showSearch
				value={assignedTo}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
				suffixIcon={
					<StyledIcon size={20}>
						<IconExpandMore />
					</StyledIcon>
				}
				onChange={(changedValue: SelectValue) => assignTo(changedValue, record)}
				options={renderOptions(accountListLite)}
				optionFilterProp="label"
			/>
		);
	};

	const onChangeDescription = (value: string, record: TaskItemDto) => {
		const taskList = taskManager?.tasks;
		if (taskList) {
			const payload = {
				id: record.id,
				params: {
					description: value,
				} as UpdateTaskPayloadDto,
			};
			handleUpdateTask(payload);
			setTaskManager({
				...taskManager,
				tasks: updateTaskList(value, 'description', record, taskList),
			});
		}
	};

	const renderDescription = (description: string, record: TaskItemDto) => {
		return (
			<TaskDescription
				className="description_in_list"
				description={description}
				onChange={(value: string) => onChangeDescription(value, record)}
			/>
		);
	};

	const handleChangeStatus = (status: string, record: TaskItemDto) => {
		const taskList = taskManager?.tasks;
		if (taskList && status) {
			const payload = {
				id: record.id,
				params: {
					status,
				} as UpdateTaskPayloadDto,
			};
			handleUpdateTask(payload);
			setTaskManager({
				...taskManager,
				tasks: updateTaskList(status, 'status', record, taskList),
			});
		}
	};

	const renderTaskStatus = (status: string, record: TaskItemDto) => {
		return (
			<TaskStatus
				status={status}
				onChange={(status: string) => handleChangeStatus(status, record)}
			/>
		);
	};

	const renderDate = (date: number) => {
		return renderCopiedText(
			`${convertUtcToLocalTimeWithFormat(date, TYPE_FORMAT_DATE.TIME_AT)}`,
		);
	};

	const isResizeColumn = window.innerWidth <= 1440;

	const taskColumns: any[] = [
		{
			title: t('task_manager.task_list.status'),
			dataIndex: 'status',
			key: 'status',
			width: DEFAULT_WIDTH_COL + (isResizeColumn ? 25 : 40),
			fixed: 'left',
			render: (status: string, record: TaskItemDto) => renderTaskStatus(status, record),
		},
		{
			title: t('task_manager.task_list.description'),
			dataIndex: 'description',
			key: 'description',
			width: DEFAULT_WIDTH_COL * (isResizeColumn ? 2.35 : 2.5),
			fixed: 'left',
			render: (description: string, record: TaskItemDto) =>
				renderDescription(description, record),
		},
		{
			title: t('task_manager.task_list.assigned_to'),
			dataIndex: 'assignedToEmail',
			width: DEFAULT_WIDTH_COL + (isResizeColumn ? 45 : 75),
			key: 'assignedToEmail',
			render: (assignedToEmail: string, record: TaskItemDto) =>
				renderAssignedTo(assignedToEmail, record),
		},
		{
			title: t('task_manager.task_list.assigned_by'),
			dataIndex: 'assignedBy',
			width: DEFAULT_WIDTH_COL + (isResizeColumn ? 30 : 75),
			key: 'assignedBy',
			render: renderCopiedText,
		},
		{
			title: t('task_manager.task_list.last_updated'),
			dataIndex: 'updateAt',
			width: DEFAULT_WIDTH_COL + 80,
			key: 'updateAt',
			render: renderDate,
			sorter: () => {},
		},
		{
			title: '',
			dataIndex: '',
		},
	];

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const handleSelection = (
		selectingItems: any[],
		isShift?: boolean,
		callBack?: (val: Array<number | string>) => void,
	) => {
		const listKey: any[] = [];
		for (const selectItem of selectingItems) {
			const itemSelect = taskManager?.tasks?.find(
				(dataIndex: any) => dataIndex?.id === selectItem?.props?.id,
			);
			listKey.push(itemSelect?.id || '');
		}
		if (callBack && typeof callBack === 'function') {
			callBack(listKey);
		}

		if (!isShift) {
			onSelectChange(listKey);
		}
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		onSelectChange(list);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const onChangePage = (pageNumber: number) => {
		setRequestPayload({ ...requestPayload, page: pageNumber });
	};

	const onChange = (pagination: any, filters: any, sorter: any) => {
		if (sorter) {
			const { columnKey, order } = sorter;
			if (taskManager?.tasks?.length) {
				setRequestPayload({
					...requestPayload,
					sortBy: columnKey,
					sortDirection: SORT_DIRECTION[order],
					page: TASK_DEFAULT_PAYLOAD.page,
				});
			}
		}
	};

	return (
		<>
			<StyledTaskList
				id={ELEMENT_SELECTOR.TASK_LIST_ID}
				columns={taskColumns}
				dataSource={taskManager?.tasks || []}
				pagination={false}
				rowSelection={rowSelection}
				scroll={{ y: 'calc(100vh - 260px)', x: 'fit-content' }}
				rowKey="id"
				onRow={(record: any, rowIndex: any) => ({
					onClick: () => setSelectedTask(record),
				})}
				onChange={onChange}
			/>

			<Affix offsetBottom={0}>
				<StyledPagination bottom={14}>
					<Pagination
						total={taskManager?.totalRecords}
						pageSize={TASK_PARAMS_DEFAULT.maxRecords}
						defaultPageSize={TASK_PARAMS_DEFAULT.maxRecords}
						current={taskManager.page + 1}
						showSizeChanger={false}
						showQuickJumper
						onChange={onChangePage}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${total || 0} ${t(
								`${total > 1 ? 'pagination.items' : 'pagination.item'}`,
							)}`
						}
					/>
				</StyledPagination>
			</Affix>
			<input id="elementToFocusId" style={{ opacity: 0, height: '32px' }} />
		</>
	);
};

export default TaskList;
