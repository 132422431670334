import { IconKeyboardArrowRight } from '@assets/icons';
import { LIMIT_CHARACTERS } from '@constants/common';
import { FORM_CONTENT_DETAIL } from '@constants/content/contentLibrary';
import THEME from '@constants/themes/themes';
import {
	StyledDivider,
	StyledFormItem,
	StyledIcon,
	StyledText,
	StyledTextArea,
	StyledTitle,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledCollapse, StyledCollapseInfo } from '@styled/ContentLibrary/ContentDetailStyled';
import { useTranslation } from 'react-i18next';

const AlternativeText = () => {
	const { t } = useTranslation();

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo margin="0">
				<StyledTitle>{t('content_detail.alt_text')}</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	return (
		<>
			<StyledWrapperContent>
				<StyledCollapse
					className="collapse_content_detail"
					expandIconPosition="right"
					style={{ marginBottom: '0' }}
					defaultActiveKey={'1'}>
					<StyledCollapse.Panel header={renderHeaderPanel()} key={'1'}>
						<StyledWrapperContent padding="8px 0">
							<StyledText color={THEME.colors.gray1}>
								{t('content_detail.alt_text_description')}
							</StyledText>
							<StyledFormItem name={FORM_CONTENT_DETAIL.ALT_TEXT} margin="16px 0 0 0">
								<StyledTextArea maxLength={LIMIT_CHARACTERS.C_1000} />
							</StyledFormItem>
						</StyledWrapperContent>
					</StyledCollapse.Panel>
				</StyledCollapse>
			</StyledWrapperContent>
			<StyledDivider margin="16px 0" />
		</>
	);
};

export default AlternativeText;
