import * as React from 'react';
import { Button, Form, Input, Dropdown, Checkbox, AutoComplete } from 'antd';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getRequest } from '@helpers/requestHelpers';

// Icon
import {
	IconDelete,
	IconSearch,
	IconFilter,
	IconExpandMore,
	IconDragHandle,
	IconAction,
} from '@assets/icons';

// Styled
import { StyledBtnCustom } from '@styled/Content/ContentLibrary/ActionBarStyled';

// Model
import { RewardsLogColumnsDto } from '@models/rewards/log';

// Constant
import { CREATOR_API_URL } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import THEME from '@constants/themes/themes';

// Context
import CreatorSegmentsContext from '@contexts/Creators/Segments';
import RewardsHistoryContext from '@contexts/Rewards/History';

// Styled
import {
	StyledMenuColumns,
	StyledCheckboxItems,
	StyledButtonAction,
	StyledActionBlock,
	StyledActionList,
	StyledToggleColumns,
	StyledSearch,
	StyledMenuColumnsItems,
	StyledPopconfirm,
} from '@styled/Rewards/ActionBarStyled';
import SearchBar from '@cores/SearchBar';

type ActionProps = {
	selectedSegments?: any;
	columns: RewardsLogColumnsDto[];
	handleChangeToggleColumns: (toggleColumns: RewardsLogColumnsDto[]) => void;
	handleToggleFilters: () => void;
	handleToggleAction: () => void;
};

const ActionBar = ({
	selectedSegments,
	columns,
	handleChangeToggleColumns,
	handleToggleFilters,
	handleToggleAction,
}: ActionProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const {
		selectedRowKeys,
		getListRewardsParams,
		handleChangeGetRewardsHistoryParams,
		handleDeleteRewards,
		loadingDelete,
	} = useContext(RewardsHistoryContext);
	const [toggleColumns, setToggleColumns] = useState<RewardsLogColumnsDto[]>([]);
	const [visibleDropdown, setVisibleDropdown] = useState(false);
	const isSelected = selectedRowKeys.length > 0;

	useEffect((): any => {
		setToggleColumns(columns);
	}, [columns]);

	const handleChangeChecked = ($event: any) => {
		toggleColumns.forEach((item) => {
			if ((item?.key || item?.dataIndex) === $event.target.id) {
				item.show = $event.target.checked;
			}
		});

		setToggleColumns(toggleColumns);
	};

	/**
	 * Handle Toggle Columns
	 */
	const handleApplyColumns = () => {
		setVisibleDropdown(false);
		handleChangeToggleColumns(toggleColumns);
	};

	const handleCancelColumns = () => {
		setVisibleDropdown(false);
	};

	const handleVisibleChange = (flag: boolean) => {
		setVisibleDropdown(flag);
	};

	const handleDelete = () => {
		handleDeleteRewards();
	};

	const handlePressEnter = (value: string) => {
		const { params } = getListRewardsParams;
		const payload = {
			params: {
				...params,
				search: value,
			},
		};
		handleChangeGetRewardsHistoryParams(payload);
	};

	const menuColumns = (
		<StyledMenuColumns>
			{toggleColumns
				? toggleColumns.map((item: RewardsLogColumnsDto) => (
						<StyledMenuColumnsItems key={item?.key || item.dataIndex}>
							<IconDragHandle />
							<StyledCheckboxItems
								defaultChecked={item.show}
								id={item?.key || item.dataIndex}
								disabled={item.disabled}
								onChange={handleChangeChecked}>
								{item.title}
							</StyledCheckboxItems>
						</StyledMenuColumnsItems>
				  ))
				: null}
			<StyledMenuColumnsItems>
				<Button type={'primary'} onClick={handleApplyColumns}>
					{t('button.apply')}
				</Button>
				<StyledButtonAction
					style={{ marginLeft: '8px' }}
					className="ant-btn-cancel"
					onClick={handleCancelColumns}>
					{t('button.cancel')}
				</StyledButtonAction>
			</StyledMenuColumnsItems>
		</StyledMenuColumns>
	);

	return (
		<StyledActionBlock className="card-content">
			<StyledActionList>
				<SearchBar
					placeholder={t('content.search')}
					onSearch={handlePressEnter}
					enterButton
					width="100%"
					style={{ marginTop: '1px' }}
				/>
			</StyledActionList>

			<StyledActionList>
				{isSelected && (
					<StyledPopconfirm
						placement="bottom"
						title={t('popconfirm.delete_rewards')}
						onConfirm={handleDelete}
						okText={t('button.delete')}
						cancelText={t('button.cancel')}>
						<StyledButtonAction icon={<IconDelete />} loading={loadingDelete}>
							{t('button.delete')}
						</StyledButtonAction>
					</StyledPopconfirm>
				)}

				{isSelected && (
					<StyledButtonAction
						icon={<IconAction />}
						type="ghost"
						onClick={handleToggleAction}>
						{t('button.actions')}
					</StyledButtonAction>
				)}

				<StyledButtonAction
					icon={<IconFilter />}
					type="ghost"
					onClick={handleToggleFilters}>
					{t('button.filter')}
				</StyledButtonAction>

				<StyledToggleColumns>
					<Dropdown
						overlay={menuColumns}
						placement="bottomRight"
						onVisibleChange={handleVisibleChange}
						visible={visibleDropdown}
						trigger={['click']}>
						<Button>
							Toggle Columns
							<IconExpandMore />
						</Button>
					</Dropdown>
				</StyledToggleColumns>
			</StyledActionList>
		</StyledActionBlock>
	);
};

export default React.memo(ActionBar);
