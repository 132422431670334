import THEME from '@constants/themes/themes';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

const StyledPreviewEmbedModal = styled(Modal)`
	.ant-modal-header {
		border: 0;
		padding-bottom: 0;
	}
	.ant-modal-body {
		padding-top: 0;
		max-height: 80vh;
		overflow: auto;
	}
`;

const StyledTitleEmbed = styled.span`
	font-size: 24px;
	color: ${THEME.colors.gray5};
	width: 100%;
`;

const StyledLinkInfo = styled.a`
	font-weight: bold;
	color: ${THEME.colors.white};
	&:hover {
		color: ${THEME.colors.orangeBase};
	}
`;

const StyledButtonGray = styled(Button)`
	&.ant-btn {
		background-color: ${THEME.colors.gray1};
		color: ${THEME.colors.gray5};
		border: 0;

		&:focus {
			background-color: ${THEME.colors.gray1};
		}
		&:hover {
			background-color: ${THEME.colors.gray2};
		}
	}
`;

export { StyledPreviewEmbedModal, StyledTitleEmbed, StyledLinkInfo, StyledButtonGray };
