import {
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledBoxContent,
	StyledButtonGenerate,
	StyledInfoEmbed,
	StyledInput,
	StyledLinkGallery,
} from '@styled/Publish/Gallery/GalleryManagerStyled';
import {
	StyledButtonGray,
	StyledLinkInfo,
	StyledPreviewEmbedModal,
	StyledTitleEmbed,
} from '@styled/Publish/Gallery/PreviewEmbedModalStyled';
import { Button, Col, Form, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ContentItem from '../SidebarGalleries/ContentItem';
import { StyledDividerLine } from '@styled/Filter/FilterContentStyled';
import THEME from '@constants/themes/themes';
import {
	encodeToBase64,
	onChangeSizeIframe,
	onCopyValue,
	renderStringIframe,
} from '@utils/funcHelper';
import { FolderGalleryDto, SizeIframe } from '@models/publishing/galleries';
import {
	DEFAULT_SIZE_IFRAME,
	GALLERY_TAG_CLASS,
	MAX_SIZE_IFRAME,
	RANGE_COLUMN,
	SIZE_IFRAME,
	TYPE_THEME,
} from '@constants/publish/gallery';
import { urlGallerySupport, urlGalleryTag } from '@config/index';
import { getUser } from '@helpers/userHelpers';

type PropTypes = {
	visible: boolean;
	onCancel: () => void;
	gallery: FolderGalleryDto;
};
const PreviewEmbedModal = (props: PropTypes) => {
	const { visible, onCancel, gallery } = props;
	const { t } = useTranslation();

	const user = getUser();
	const { clientId } = user;

	const setDeviant = (rowNum: number) => {
		switch (rowNum) {
			case 1:
				return 100;

			case 2:
				return 75;

			default:
				return 50;
		}
	};

	const defaultSize = useMemo(() => {
		const paramSize: SizeIframe = {
			width: DEFAULT_SIZE_IFRAME.width,
			height: DEFAULT_SIZE_IFRAME.height,
		};
		const { theme = TYPE_THEME.GRID, uploaderLink = null, rows } = gallery?.metadata;
		const rowNum = rows || RANGE_COLUMN.default;
		const typeObj: any = {
			grid: !theme || theme === TYPE_THEME.GRID,
			slider: theme === TYPE_THEME.SLIDER,
			mosaic: theme === TYPE_THEME.MOSAIC,
			signage: theme === TYPE_THEME.SIGNAGE,
		};
		const heightObj: any = {
			grid: uploaderLink
				? DEFAULT_SIZE_IFRAME.GRID_NO_LINK?.height * rowNum + setDeviant(rowNum)
				: DEFAULT_SIZE_IFRAME.GRID_NO_LINK?.height * rowNum,
			slider: uploaderLink
				? DEFAULT_SIZE_IFRAME.SLIDER?.height
				: DEFAULT_SIZE_IFRAME.SLIDER_NO_LINK?.height,
			mosaic: uploaderLink
				? DEFAULT_SIZE_IFRAME.MOSAIC?.height
				: DEFAULT_SIZE_IFRAME.MOSAIC_NO_LINK?.height,
			signage: DEFAULT_SIZE_IFRAME.SIGNAGE?.height,
		};
		const themeCurrent = Object.keys(typeObj)?.find((item) => typeObj[item]) || TYPE_THEME.GRID;
		paramSize.height = heightObj[themeCurrent];

		return paramSize;
	}, []);

	const [iframeString, setIframeString] = useState<string>(
		renderStringIframe(defaultSize, gallery.url, gallery.name, false),
	);

	const [sizeFormIframe, setSizeFormIframe] = useState<SizeIframe>({ ...defaultSize });

	const [form] = Form.useForm();

	const onGenerateIframe = () => {
		setIframeString(renderStringIframe(sizeFormIframe, gallery.url, gallery.name));
	};

	const renderInput = (name: string) => {
		const typePercent = name === SIZE_IFRAME.width;

		return (
			<StyledInput
				height={'35px'}
				className="number_input"
				value={sizeFormIframe[name]}
				name={name}
				suffix={typePercent ? '%' : 'px'}
				onChange={(e) => {
					const obj = onChangeSizeIframe(e);
					setSizeFormIframe((prev) => ({ ...prev, ...obj }));
				}}
			/>
		);
	};

	const onClearForm = () => {
		form.resetFields();
		onCancel();
	};

	const handleCopy = (val: string) => {
		onCopyValue(val);
		message.success(t('campaign.message.copy_success'));
	};

	const renderSubInfo = (title: string, sub: string, link?: string) => {
		return (
			<StyledWrapperContent>
				<StyledTitle
					className="unset_height"
					fontSize="20px"
					color={THEME.colors.white}
					lineHeight="27px"
					display="inline-block"
					margin="30px 0 16px 0">
					{title}
				</StyledTitle>
				<StyledTitle
					fontSize="16px"
					className="unset_height"
					display="inline-block"
					color={THEME.colors.gray5}
					lineHeight="22px"
					margin="0 0 8px 0">
					{link ? (
						<Trans
							t={t}
							values={{}}
							i18nKey={sub}
							components={{
								elm: <StyledLinkInfo target="_blank" href={urlGallerySupport} />,
							}}
						/>
					) : (
						t(sub)
					)}
				</StyledTitle>
			</StyledWrapperContent>
		);
	};

	const renderInfoEmbedCode = (isIframe?: boolean) => {
		const scriptStr = `<script defer type="text/javascript" src="${urlGalleryTag}"></script>
        <div data-client-id="${encodeToBase64(clientId)}" data-gallery-id="${
			gallery?.id
		}" class="${GALLERY_TAG_CLASS}"></div>`;
		const valContent = isIframe ? iframeString : scriptStr;
		const textBtn = isIframe ? t('button.copy_iframe_code') : t('button.copy_embed_code');
		return (
			<StyledWrapperContent>
				<StyledBoxContent className="font_courier">{valContent}</StyledBoxContent>
				<Button
					onClick={() => handleCopy(valContent)}
					style={{ marginTop: '16px' }}
					type="primary">
					{textBtn}
				</Button>
			</StyledWrapperContent>
		);
	};

	return (
		<StyledPreviewEmbedModal
			centered
			footer={null}
			zIndex={1010}
			visible={visible}
			title={
				<>
					<StyledTitleEmbed>{t('gallery_manager.embed_your_gallery')}</StyledTitleEmbed>
					<StyledDividerLine margin="4px 0 0 0" />
				</>
			}
			onCancel={onClearForm}>
			<Form form={form} initialValues={{}}>
				{renderSubInfo(
					t('gallery_manager.embed_with_script'),
					'gallery_manager.sub_embed_script',
					'link',
				)}

				{renderInfoEmbedCode()}
				<StyledDividerLine margin="33px 0 0 0" />
				{renderSubInfo(
					t('gallery_manager.embed_with_iframe'),
					'gallery_manager.sub_embed_iframe',
					'link',
				)}

				<StyledWrapperContent margin="0 0 8px 0">
					<StyledInfoEmbed gutter={[24, 8]}>
						<Col span={7}>
							<ContentItem
								label={t('gallery_manager.width_frame')}
								content={renderInput('width')}
							/>
						</Col>
						<Col span={7}>
							<ContentItem
								label={t('gallery_manager.height_frame')}
								content={renderInput('height')}
							/>
						</Col>
						<Col style={{ display: 'flex', alignItems: 'end' }} span={10}>
							<StyledButtonGray
								onClick={() => onGenerateIframe()}
								style={{ width: '100%' }}
								type="primary">
								{t('button.refresh_iframe_code')}
							</StyledButtonGray>
						</Col>
					</StyledInfoEmbed>
				</StyledWrapperContent>
				{renderInfoEmbedCode(true)}
				<StyledDividerLine margin="33px 0 0 0" />
				{renderSubInfo(t('gallery_manager.share_link'), 'gallery_manager.sub_share')}
				<StyledWrapperContent>
					<ContentItem
						label={t('gallery_manager.gallery_url')}
						content={
							<StyledLinkGallery href={gallery?.url} target="_blank">
								{gallery?.url}
							</StyledLinkGallery>
						}
					/>
				</StyledWrapperContent>
				<StyledDividerLine margin="33px 0 0 0" />

				<StyledTitle
					className="unset_height"
					fontSize="20px"
					color={THEME.colors.white}
					lineHeight="27px"
					display="inline-block"
					margin="30px 0 16px 0">
					<Trans
						t={t}
						values={{}}
						i18nKey={'gallery_manager.sub_support'}
						components={{
							elm: <StyledLinkInfo href={urlGallerySupport} target="_blank" />,
						}}
					/>
				</StyledTitle>
			</Form>
		</StyledPreviewEmbedModal>
	);
};

export default PreviewEmbedModal;
