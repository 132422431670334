// Icons
import { FullscreenExitOutlined } from '@ant-design/icons';
import { IconClose, IconEye } from '@assets/icons';
// Components
import { ImageFallback } from '@assets/images';
import ActionContentDetail from '@components/Content/ContentLibrary/ContentDetails/ActionContentDetail';
import DownloadModal from '@components/Content/ContentLibrary/DownloadModal';
import MessageTemplates from '@components/MessageTemplates';
import ImageEditorModal from '@components/ModalCustom/ImageEditorModal';
import RequestRightsDM from '@components/RequestRightsDM';
import {
	GOOGLE_VISION_MAX_SUPPORTED_FILES_IN_MB,
	KEYBOARD,
	PARAMS_DEFAULT,
	ROUTE_PATH,
	TYPE_ACTIONS,
	UNIT_VALUE,
} from '@constants/common';
import {
	CONTENT_TYPE,
	DEFAULT_VERSION_ID,
	FORM_CONTENT_DETAIL,
	LIMIT_SIZE,
	MARK_AS,
	MEDIA_TYPE,
	METHOD_VERSION,
	MOVE_CONTENT,
	ROTATE,
	SOCIAL_SOURCE,
	UPLOADSOURCE,
} from '@constants/content/contentLibrary';
import { STATE_TYPE } from '@constants/messages';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import LoadingWrapper from '@cores/LoadingWrapper';
import { filterGalleryUnAssign } from '@helpers/arrayHelper';
import { StateCampaign } from '@models/campaign';
import {
	ContentRootStateDto,
	ContentStoreDto,
	ImgVersionDto,
	ReviewContentPayloadDto,
} from '@models/content/contentLibrary/store';
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
	ContentVerificationScoreDto,
	CustomFieldsContentDto,
	DataItemAdditionalType,
	DataRequestTermsContentTypes,
	LogoDetectionDto,
	ParamPublishLogDto,
	PublishLogDto,
	TagItemDto,
} from '@models/content/contentLibrary/summary';
import { FolderDto } from '@models/settings/content';
import { SettingRootStateDto } from '@models/settings/setting';
import {
	createImgVersionRequest,
	fetchContentEmojiRequest,
	fetchContentTagsRequest,
	fetchImgVersionRequest,
	fetchPublishLogContentRequest,
	fetchTermsContentRequest,
	getMediaDetectionEnd,
	getVerificationScoreRequest,
	markFavoriteContentRequest,
	reviewContentRequest,
	rotateImageRequest,
	storeContentData,
	updateImgVersionRequest,
} from '@stores/actions';
import { updateFlags, updateLabels } from '@stores/settings/settings.actions';
import {
	StyledDivider,
	StyledIcon,
	StyledSkeleton,
	StyledText,
	StyledTitle,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
// Styled
import {
	StyledCarousel,
	StyledCol,
	StyledContainerAction,
	StyledDetectedLogoPosition,
	StyledForm,
	StyledFormItem,
	StyledFullScreen,
	StyledIconClose,
	StyledImageContainer,
	StyledImageFull,
	StyledRow,
	StyledVideoFull,
} from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { StyledSection } from '@styled/Content/ContentLibrary/ContentStyled';
import { StyledTextArea } from '@styled/Content/ContentLibrary/FilterStyled';
import { StyledLabel } from '@styled/ContentLibrary/ContentDetailStyled';
import {
	getSocialS3URL,
	handleImageLoadedError,
	handleRedirectLink,
	handleThumbImageLoadedError,
} from '@utils/common';
import {
	checkExistExplicit,
	checkListNotExist,
	convertBoundingBox,
	formatDataAdditional,
	formatReviewContentRequest,
	setDetectedLogoPosition,
	thumbnailContentInEmail,
} from '@utils/content';
import history from '@utils/history';
import { Form, message } from 'antd';
import _ from 'lodash';
import React, {
	Ref,
	RefObject,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import SocialHashTagMention from '@components/SocialSearch/SocialDetails/SocialInfo/SocialHashTagMention';
import SocialProfile from '@components/SocialSearch/SocialDetails/SocialInfo/SocialProfile';
import { TYPE_FORM_CUSTOM_FIELDS } from '@constants/contentLibrary/contentDetail';
import { SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import { ContentDetailRef, ContentMediaRef } from '@models/content/contentLibrary/ref';
import { StyledMarkExplicit } from '@styled/Content/ContentLibrary/MediaItemStyled';
import { rotateImageToBase64 } from '@utils/funcHelper';
import { goToSocialNewTab } from '@utils/socialSearch';
import ConfirmModalAction from './ConfirmModalAction';
import CustomFieldSession from './CustomFieldSession';
import DescAndCreatorInfo from './DescAndCreatorInfo';
import DetailRateAndTag from './DetailRateAndTag';
import DetectContentUrl from './DetectContentUrl';
import EffectFunc from './EffectFunc';
import FileInformation from './FileInfomation';
import FooterAction from './FooterAction';
import FormItemSelect from './FormItemSelect';
import FullScreenVideo from './FullScreenVideo';
import HighLightSource from './HighLightSource';
import OtherDetail from './OtherDetail';
import Overview from './Overview';
import ReadOnlyStatus from './ReadOnlyStatus';
import VersionDetails from './VersionDetails';
import VoteContent from './VoteContent';
import AlternativeText from './AlternativeText';

const keyTag = {
	labels: 'labels',
	flags: 'flags',
};

type ContentDetailsProps = {
	isVisible: boolean;
	content?: ContentContainerDto | null;
	handleCancel: () => void;
	isFirstContent: boolean;
	isLastContent: boolean;
	moveContent: (action: string) => void;
	onChangeAfterAction: (
		action: string,
		contentsAfterAction?: number[],
		content?: ContentReviewDto,
	) => void;
	onChangeAfterBlock?: (currentMedia: ContentDto, isDelete: boolean, isBlock: boolean) => void;
	updateVerificationScrore?: (
		content: ContentDto,
		verificationScore: ContentVerificationScoreDto,
	) => void;
	updateAfterReviewingContent: (reviewedContents: ContentReviewDto[]) => void;
	folderId?: number | string;
	markAsFavoriteContent?: (content: ContentDto) => void;
	setContentIdMove: (val: number | null) => void;
	contentDetailDto?: ContentDto;
	updateAfterChangeVersion?: (contentId: number, version: ImgVersionDto) => void;
	CDMBlockCreatorDisabled?: boolean;
	isDashboardAmbassador?: boolean;
	setIdContentUpdate: (val: number | undefined) => void;
	idContentUpdate: number | undefined;
	handleChangeFieldContent?: (val: any, id: number) => void;
	enableRotateImage?: boolean;
};

const ContentDetail = forwardRef((props: ContentDetailsProps, ref: Ref<ContentDetailRef>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { pathname } = useLocation();

	const imgRef = useRef<HTMLImageElement[]>([]);
	const videoRef = useRef<HTMLVideoElement[]>([]);
	const refContentMedia = useRef<ContentMediaRef | undefined>() as RefObject<ContentMediaRef>;

	const [form] = Form.useForm();
	const [currentMedia, setCurrentMedia] = useState<ContentDto | null>(null);
	const [currentMediaList, setCurrentMediaList] = useState<Array<ContentDto>>([]); // contains carousel
	const [currentRef, setCurrentRef] = useState<number>(0); // contains carousel

	const [isCarousel, setIsCarousel] = useState<boolean>(false);
	const [isDisableAction, setIsDisableAction] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [otherLoading, setOtherLoading] = useState<boolean>(false);
	const [isAdditionalLoading, setIsAdditionalLoading] = useState<boolean>(false);
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
	const [isBlockDelete, setIsBlockDelete] = useState<boolean>(false);
	const [isDownload, setIsDownload] = useState<boolean>(false);
	const [isMessage, setIsMessage] = useState<boolean>(false);
	const [showRequestRight, setShowRequestRight] = useState<boolean>(false);
	const [isOverrideNeedReview, setIsOverrideNeedReview] = useState<boolean>(false);
	const [isContacted, setIsContacted] = useState<boolean>(false);

	const [typeConfirm, setTypeConfirm] = useState<string>('');
	const [visibleImage, setVisibleImage] = useState<boolean>(true);

	const [paramPublish, setParamPublish] = useState<ParamPublishLogDto>({
		contentId: null,
		page: 1,
		maxRecord: PARAMS_DEFAULT.maxRecords,
	});
	const [publishLogs, setPublishLogs] = useState<PublishLogDto[]>([]);
	const [isClose, setIsClose] = useState<boolean>(false);
	const [fieldSelect, setFieldSelect] = useState<string>('');
	const [collapseTerm, setCollapseTerm] = useState<boolean>(false);
	const [findFlag, setFindFlag] = useState<boolean>(false);
	const [typeAction, setTypeAction] = useState<string>('');
	const [versionImgSelected, setVersionImgSelected] = useState<ImgVersionDto | null>(null);
	const [showDetectedLogo, setShowDetectedLogo] = useState<boolean>(false);
	const [hideMessage, setHideMessage] = useState(true);

	const [customFields, setCustomFields] = useState<CustomFieldsContentDto[]>([]);
	const [standFields, setStandFields] = useState<CustomFieldsContentDto[]>([]);
	const [requestChange, setRequestChange] = useState<ContentReviewDto | null>(null);
	const [actionMove, setActionMove] = useState<string>('');
	const [imgUrlEdit, setImgUrlEdit] = useState<string>('');
	const [isRotateRequest, setIsRotateRequest] = useState<boolean>(false);
	const [showExplicit, setShowExplicit] = useState<boolean>(false);

	// Reducers
	const {
		fetchingTerms,
		fetchingPublishLog,
		publishLogs: listPublishLog,
		fetchingTags,
		tags,
		loadingContentEmoji,
		loadingImgVersion,
		imgVersions,
		error,
		contentDetection,
		updateImgVersionStatus,
		idContentVersion,
		imgVersionDetail,
	}: ContentStoreDto = useSelector((state: ContentRootStateDto) => state.content);

	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);
	const { folders = [] } = useSelector((state: SettingRootStateDto) => state.settings.content);

	const { flags: flagFull, labels: labelFull } = tags;

	const [galleryList, setGalleryList] = useState<FolderDto[]>([]);

	const typeContent = useMemo(() => {
		const isArchive = !!currentMedia?.review?.rejected;
		return {
			isArchive,
			isBookmark:
				currentMedia?.uploadSource === UPLOADSOURCE.SOCIAL_SEARCH ||
				currentMedia?.uploadSource === UPLOADSOURCE.SOCIAL_UPLOAD,
			isSubmission:
				currentMedia?.uploadSource === UPLOADSOURCE.DIRECT_UPLOAD ||
				currentMedia?.uploadSource === UPLOADSOURCE.MANUAL_IMPORT ||
				currentMedia?.uploadSource === UPLOADSOURCE.SCRIPT_IMPORT,
		};
	}, [currentMedia]);

	const isSocialContent = useMemo(() => {
		return typeContent.isBookmark && !!currentMedia?.social;
	}, [typeContent, currentMedia]);

	const dataStatus = typeContent?.isBookmark
		? [MARK_AS.CONTACTED.label, MARK_AS.REJECTED.label, MARK_AS.PENDING.additionalLabel]
		: [
				MARK_AS.CONTACTED.label,
				MARK_AS.REJECTED.label,
				MARK_AS.PENDING.additionalLabel,
				MARK_AS.AFFILIATE.label,
		  ];

	const disableActionPage =
		pathname === ROUTE_PATH.DASHBOARD || pathname === ROUTE_PATH.AMBASSADOR_DASHBOARD;
	// Props
	const {
		isVisible,
		content,
		handleCancel,
		moveContent,
		isFirstContent,
		isLastContent,
		onChangeAfterAction,
		onChangeAfterBlock,
		updateVerificationScrore,
		updateAfterReviewingContent,
		folderId,
		markAsFavoriteContent,
		contentDetailDto,
		setContentIdMove,
		updateAfterChangeVersion,
		CDMBlockCreatorDisabled,
		isDashboardAmbassador = false,
		setIdContentUpdate,
		idContentUpdate,
		handleChangeFieldContent,
		enableRotateImage,
	} = props;
	// Hook

	useImperativeHandle(ref, () => ({
		handleMoveContent,
		handleCancelModal,
		isBlockCancel: () => {
			const values = form.getFieldsValue();
			const dataFormat = formatDataRequest(values);
			const { hasChangeLabelCustomField, ...requestData } = dataFormat;
			return !!hasChangeLabelCustomField;
		},
	}));

	useEffect(() => {
		if (!_.isEmpty(content) && content) {
			handleInputContent(content);
		}

		if (!_.isEmpty(contentDetailDto) && contentDetailDto) {
			setCurrentMedia(contentDetailDto);
			setIsCarousel(false);
		}

		if (!isVisible) {
			setCurrentMedia(null);
			resetCurrentMedia();

			setVersionImgSelected(null);
		}
		return () => {
			setIsLoading(false);
		};
	}, [content, isVisible, contentDetailDto]);

	useEffect(() => {
		return () => {
			resetCurrentMedia();
			setVisibleImage(true);
		};
	}, []);

	useEffect(() => {
		return () => {
			resetCurrentMedia();
			setVisibleImage(true);
		};
	}, [isVisible]);

	const dataRequestTerms: DataRequestTermsContentTypes | null = useMemo(() => {
		if (currentMedia) {
			return {
				param: {
					contentId: currentMedia.id,
					uploadDate: currentMedia.uploadDate,
				},
				body: currentMedia.uploadSessionJson,
			};
		}
		return null;
	}, [currentMedia?.id]);

	const handleFetchTerms = () => {
		dispatch(fetchTermsContentRequest(dataRequestTerms));
	};

	useEffect(() => {
		if (currentMedia?.id && isVisible) {
			setParamPublish((prev: any) => ({ ...prev, contentId: currentMedia?.id, page: 1 }));
			if (!currentMedia?.uploadSessionJson) {
				handleFetchTerms();
			}
			dispatch(fetchContentTagsRequest());
			dispatch(fetchContentEmojiRequest({ contentId: currentMedia?.id }));

			setVisibleImage(true);
			setCollapseTerm(false);
			setFieldSelect('');

			setShowDetectedLogo(false);

			dispatch(fetchImgVersionRequest(currentMedia?.id));
		}
		return () => {
			setVersionImgSelected(null);
			setIsMessage(false);
			dispatch(
				storeContentData({
					imgVersions: [],
					termsContent: [],
					termsTemplateContent: null,
					contentDetection: undefined,
				}),
			);
		};
	}, [currentMedia?.id, isVisible]);

	useEffect(() => {
		if (currentMedia?.contentUrl && !typeContent?.isBookmark) {
			getImageVerificationScore(currentMedia);
		}
	}, [currentMedia?.contentUrl]);

	useEffect(() => {
		setShowExplicit(checkExistExplicit(currentMedia?.review?.flags));
	}, [currentMedia?.id, currentMedia?.review?.flags]);

	useEffect(() => {
		imgVersions.forEach((item) => {
			if (item?.isDefault) {
				if (!versionImgSelected) {
					item.rotate = currentMedia?.rotate;
					setVersionImgSelected(item);
				}
			}
		});
	}, [imgVersions]);

	useEffect(() => {
		if (paramPublish?.page === 1) {
			setPublishLogs([]);
		}
		if (paramPublish?.contentId) {
			dispatch(fetchPublishLogContentRequest({ ...paramPublish }));
		}
	}, [paramPublish]);

	useEffect(() => {
		let arr: PublishLogDto[] = [];
		if (paramPublish?.page === 1) {
			arr = [...listPublishLog];
		} else {
			arr = [...publishLogs, ...listPublishLog];
		}
		setPublishLogs([...arr]);
	}, [listPublishLog]);

	useEffect(() => {
		if (!fetchingPublishLog && paramPublish?.contentId) {
			const { published } = currentMedia?.review || {};
			const paramReview: ContentReviewDto = { ...currentMedia?.review };
			if (publishLogs?.length === 0 && published) {
				paramReview.published = false;
			}
			if (publishLogs?.length > 0 && !published) {
				paramReview.published = true;
			}
			if (paramReview?.published !== published) {
				updateAfterReviewingContent([paramReview]);
			}
		}
	}, [publishLogs]);

	useEffect(() => {
		if (folders?.length > 0) {
			setGalleryList(filterGalleryUnAssign(folders));
		}
	}, [folders]);

	useEffect(() => {
		if (currentMedia) {
			handleFieldData();
		}
	}, [currentMedia?.review, currentMedia?.additionalInfo]);

	useEffect(() => {
		if (currentMedia) {
			handleFieldCustomFields();
		}
	}, [currentMedia?.review?.custom, currentMedia?.review]);

	const handleFieldCustomFields = () => {
		const customDefault = currentMedia?.review?.custom || [];
		const listCustomField = customDefault.filter((cus) => cus?.key);
		setCustomFields(listCustomField);
		setStandFields(customDefault.filter((cus) => !cus?.key));
		const formatCustomField = formatDataAdditional(listCustomField);
		const dataForm: any = {};
		const handleAddValue = (arr: DataItemAdditionalType[], type: string) => {
			arr.forEach((cus) => {
				dataForm[`${type}_${cus.key}`] = cus.values;
				dataForm[`${type}_label_${cus.key}`] = cus.name;
			});
		};
		handleAddValue(formatCustomField.single, TYPE_FORM_CUSTOM_FIELDS.input);
		handleAddValue(formatCustomField.multi, TYPE_FORM_CUSTOM_FIELDS.dropdown);
		handleAddValue(formatCustomField.paragraph, TYPE_FORM_CUSTOM_FIELDS.paragraph);
		form.setFieldsValue(dataForm);
	};

	const handleFieldData = () => {
		imgRef.current = [];
		videoRef.current = [];

		const {
			star,
			note,
			labels,
			flags,
			contacted,
			pending,
			rejected,
			affiliate,
			campaigns,
			galleries,
			termsOfUse,
			altText,
		} = currentMedia?.review || {};
		const markedAs: string[] = [];
		let listCampaignDefault = campaigns || [];

		listCampaignDefault = listCampaignDefault.filter((item: any) =>
			campaignListContent.some((campaign) => campaign?.id.toString() === item?.toString()),
		);

		const arrList = [
			{
				value: contacted,
				label: MARK_AS.CONTACTED.label,
			},
			{
				value: pending,
				label: MARK_AS.PENDING.additionalLabel,
			},
			{
				value: rejected,
				label: MARK_AS.REJECTED.label,
			},
			{
				value: affiliate,
				label: MARK_AS.AFFILIATE.label,
			},
		];

		arrList?.forEach((item) => {
			if (item?.value) {
				markedAs.push(item?.label);
			}
		});

		const tags: string[] = [];
		labels?.forEach((item) => {
			tags.push(JSON.stringify({ val: item, group: FORM_CONTENT_DETAIL.LABELS }));
		});

		const dataForm: any = {
			galleries,
			tags,
			flags,
			note,
			star,
			termsOfUse,
			campaigns: listCampaignDefault,
			markedAs,
			altText,
		};

		form.setFieldsValue(dataForm);
	};

	useEffect(() => {
		if (!isVisible) {
			form.resetFields();
		}
	}, [isVisible]);

	useEffect(() => {
		if (!isVisible) {
			onClose();
		}
	}, [isVisible]);

	useEffect(() => {
		if (updateImgVersionStatus === SUCCEEDED && imgVersionDetail) {
			if (idContentVersion === currentMedia?.id && !loadingImgVersion) {
				setVersionImgSelected({ ...versionImgSelected, ...imgVersionDetail });

				const imgVersionsChange = imgVersions?.map((item: ImgVersionDto) => {
					if (item?.id === imgVersionDetail?.id) {
						return { ...imgVersionDetail };
					}
					if (imgVersionDetail?.isDefault) {
						return { ...item, isDefault: false };
					}
					return { ...item };
				});
				dispatch(storeContentData({ imgVersions: imgVersionsChange }));
				if (typeAction === TYPE_ACTIONS.ROTATE_90) {
					setTypeAction('');
					handleSaveRotate();
				}
			}
		}
	}, [updateImgVersionStatus]);

	useEffect(() => {
		if (videoRef.current || imgRef.current) {
			const onKeyup = (e: KeyboardEvent) => {
				const onCheckEvent = (callback: Function) => {
					const activeElement = document.activeElement;
					const inputs = ['input', 'select', 'button', 'textarea', 'video'];
					const skipEvent =
						activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;

					if (!skipEvent) {
						e.preventDefault();
						e.stopPropagation();
						e.stopImmediatePropagation();
						callback();
					}
				};
				if (
					e.code === KEYBOARD.SPACE.STR &&
					videoRef.current[currentRef] &&
					!isFullScreen
				) {
					const func = () => {
						const val = videoRef?.current[currentRef]?.paused || false;
						if (val) {
							videoRef.current[currentRef].play();
						} else {
							videoRef.current[currentRef].pause();
						}
					};
					onCheckEvent(func);
				}
				if (e.code === KEYBOARD.ARROW_LEFT.STR) {
					const func = () => {
						if (!isFirstContent) {
							handleMoveContent(MOVE_CONTENT.PREVIOUS);
						}
					};
					onCheckEvent(func);
				}
				if (e.code === KEYBOARD.ARROW_RIGHT.STR) {
					const func = () => {
						if (!isLastContent) {
							handleMoveContent(MOVE_CONTENT.NEXT);
						}
					};
					onCheckEvent(func);
				}
			};
			window.addEventListener('keyup', onKeyup);
			return () => window.removeEventListener('keyup', onKeyup);
		}
		return () => {};
	}, [
		videoRef.current,
		imgRef.current,
		currentRef,
		isFirstContent,
		isLastContent,
		versionImgSelected,
		currentMedia?.rotate,
		isFullScreen,
	]);

	useEffect(() => {
		if (findFlag && collapseTerm) {
			setTimeout(() => {
				handleFlag();
			}, 500);
		}
	}, [findFlag, collapseTerm]);

	// Render component
	const useKeypress = (key: string, action: () => void) => {
		useEffect(() => {
			const onKeyup = (e: KeyboardEvent) => {
				if (e.key === key) action();
			};
			window.addEventListener('keyup', onKeyup);
			return () => window.removeEventListener('keyup', onKeyup);
		}, []);
	};

	useKeypress('Escape', () => {
		handleCloseIcon(); // close modal by pressing Esc
	});

	const getImageVerificationScore = (media: any) => {
		if (media?.verificationScore || media?.mediaType === MEDIA_TYPE.VIDEO) {
			return;
		}
		const url: string =
			media.contentMetadata.fileSize >= GOOGLE_VISION_MAX_SUPPORTED_FILES_IN_MB
				? getSocialS3URL(media.thumbnail)
				: getSocialS3URL(media.contentUrl);
		const { id } = media;
		dispatch(
			getVerificationScoreRequest({
				url: url,
				contentId: id,
			}),
		);
	};

	const renderMedia = (media?: ContentDto, index?: number) => {
		if (!_.isUndefined(index)) {
			if (currentMedia?.id !== media?.id) {
				return <div />;
			}

			const isVideo = media?.mediaType === MEDIA_TYPE.VIDEO;
			const contentUrl =
				media?.id === currentMedia?.id && currentMedia?.imgReplace
					? currentMedia?.imgReplace
					: media?.contentUrl;
			return (
				<DetectContentUrl
					isVideo={isVideo}
					visibleImage={visibleImage}
					setVisibleImage={setVisibleImage}
					contentUrl={contentUrl || ''}
					currentRef={currentRef}
					loadSkeleton
					contentExternalUrl={media?.contentExternalUrl}
					// isReplaceImage={!!media?.imgReplace}
					rotate={currentMedia?.id === media?.id ? versionImgSelected?.rotate : null}
					ref={refContentMedia}
					videoRef={videoRef}
					isArrayElm
					imgRef={imgRef}
					currentActive={media?.id === currentMedia?.id}
					idContent={media?.id}
					social={media?.social}>
					{!typeContent.isBookmark && (
						<HighLightSource
							permalink={media?.social?.permalink || ''}
							socialSource={media?.social?.socialSource}
						/>
					)}
					{!isVideo && renderDetectionBoundingBox}
					{showExplicit && (
						<StyledMarkExplicit zIndex={1234}>
							<StyledWrapperContent textAlign="center" width="272px">
								<StyledIcon cursor="unset" fillPath={THEME.colors.gray4} size={160}>
									<IconEye />
								</StyledIcon>
								<StyledTitle
									margin="8px 0"
									className="unset_height"
									fontSize="24px">
									{t('content_management.warning')}
								</StyledTitle>
								<StyledTitle className="unset_height" fontSize="16px">
									{t('content_management.sub_sensitive_content')}
								</StyledTitle>
								<StyledTitle
									className="unset_height text_action"
									fontSize="16px"
									margin="20px 0 0 0"
									onClick={() => setShowExplicit(false)}
									fontWeight="700"
									textDecoration="underline">
									{t('content_management.view_content')}
								</StyledTitle>
							</StyledWrapperContent>
						</StyledMarkExplicit>
					)}
				</DetectContentUrl>
			);
		}

		return null;
	};

	const renderDetectionBoundingBox = useMemo(() => {
		if (
			contentDetection?.logoDetection &&
			versionImgSelected?.id === DEFAULT_VERSION_ID &&
			currentMedia?.mediaType === MEDIA_TYPE.IMAGE &&
			isVisible &&
			!visibleImage
		) {
			return contentDetection?.logoDetection?.map((item: LogoDetectionDto) => {
				const boundingBox = convertBoundingBox(item.boundingBox);
				return (
					<StyledDetectedLogoPosition
						showDetectedLogo={showDetectedLogo}
						detectedLogoPosition={setDetectedLogoPosition(
							imgRef.current[currentRef],
							boundingBox,
						)}>
						<StyledText className="label" fontSize="18px" color="#FFFF00">
							{`${item.label} - ${item.score.toFixed(2)}`}
						</StyledText>
					</StyledDetectedLogoPosition>
				);
			});
		}

		return null;
	}, [
		contentDetection?.logoDetection,
		showDetectedLogo,
		versionImgSelected,
		isVisible,
		visibleImage,
	]);

	const renderMedialist = (mediaList: Array<ContentDto>) => {
		return (
			<StyledCarousel
				className={'custom-carousel'}
				infinite={false}
				afterChange={onChangeMedia}
				beforeChange={onBeforeChange}>
				{mediaList.map((item, index) => {
					return renderMedia(item, index);
				})}
			</StyledCarousel>
		);
	};

	// Functions

	const handleInputContent = (content: ContentContainerDto) => {
		const { type } = content;
		let media: any = {};
		const mediaList: any = []; // display carousel
		const isVerified = type === CONTENT_TYPE.VERIFIED;
		const isSelect = type === CONTENT_TYPE.SELECT;
		const isContent = type === CONTENT_TYPE.CONTENT;

		if (isVerified) {
			const { content: tempContent, select } = content;

			if (tempContent && select) {
				mediaList.push(tempContent, select);
			}

			setCurrentMediaList(mediaList);
			setCurrentMedia(mediaList[0]);
			setIsCarousel(true);
		} else if (isSelect) {
			const { select } = content;

			if (select) {
				media = select;
			}

			setCurrentMedia(media);
			setIsCarousel(false);
		} else if (isContent) {
			const { content: contentInner } = content;

			if (content) {
				media = contentInner;
			}
			setCurrentMedia(media);
			setIsCarousel(false);
		}
	};

	const onChangeMedia = (currentItem: number) => {
		if (imgRef.current.length >= 2 || videoRef.current.length >= 2) {
			setCurrentRef(currentItem);
		} else {
			setCurrentRef(0);
		}

		if (currentItem === 1 && currentMediaList[currentItem].isInVerifiedBatch) {
			setIsDisableAction(true);
		} else {
			setIsDisableAction(false);
		}

		setIsCarousel(true);

		setCurrentMedia(currentMediaList[currentItem]);
	};

	const onBeforeChange = (from: number, to: number) => {
		if (videoRef.current.length >= 2) {
			if (!_.isNull(videoRef.current[from])) {
				videoRef.current[from].pause();
			}
		} else if (videoRef.current.length === 1) {
			if (!_.isNull(videoRef.current[0])) {
				videoRef.current[0].pause();
			}
		}
	};

	const handleMoveContent = async (action: string) => {
		const values = form.getFieldsValue();
		const dataFormat = formatDataRequest(values);
		const { hasChangeLabelCustomField, ...requestData } = dataFormat;
		const noChange =
			_.isEqual(currentMedia?.review, requestData) &&
			(currentMedia?.mediaType === MEDIA_TYPE.IMAGE ? versionImgSelected?.isDefault : true);
		if (hasChangeRotate()) {
			handleSaveRotate(true);
		}
		if (!noChange) {
			if (hasChangeLabelCustomField) {
				setActionMove(action);
				setRequestChange(requestData);
				return setTypeConfirm(TYPE_ACTIONS.SAVE_AND_MOVE);
			}
			setHideMessage(true);
			await handleUpdateContent({
				requestData,
				hasChangeId: true,
			});
		}

		if (
			requestData?.rejected === currentMedia?.review?.rejected ||
			action === MOVE_CONTENT.PREVIOUS
		) {
			await resetCurrentMedia();
			await moveContent(action);
			setVisibleImage(true);
		}
		return null;
	};

	const handleClearActionChangeCustomField = (unReset?: boolean) => {
		setRequestChange(null);
		setTypeConfirm('');
		setActionMove('');
		setHideMessage(true);
		if (!unReset) {
			handleResetData();
		}
	};

	const onFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};

	const onClose = () => {
		setIsLoading(false);
		setIsAdditionalLoading(false);
		setTypeConfirm('');
		setIsDisableAction(false);
		handleResetData();
	};

	const handleResetData = () => {
		form.resetFields();
		if (currentMedia) {
			handleFieldData();
		}
	};

	const resetCurrentMedia = (resetSize?: boolean) => {
		setCurrentMedia(null);
		setCurrentMediaList([]);
		setIsDisableAction(false);
		setIsCarousel(false);
		setShowDetectedLogo(false);
		if (videoRef?.current[currentRef]) {
			videoRef.current[currentRef].pause();
		}
		videoRef.current = [];
		setIsMessage(false);
		setCurrentRef(0);

		setIsOverrideNeedReview(false);

		if (!resetSize) {
			if (!isContacted) {
				// if user clicks request right and only re-render Terms

				setVisibleImage(true);
			} else {
				setIsContacted(false);
			}
		}
		dispatch(getMediaDetectionEnd());
	};

	const handleCloseIcon = () => {
		resetCurrentMedia();
		onClose();
		handleCancel();
	};

	const handleCancelModal = async () => {
		const values = form.getFieldsValue();
		const dataFormat = formatDataRequest(values);
		const { hasChangeLabelCustomField, ...requestData } = dataFormat;
		const noChange =
			_.isEqual(currentMedia?.review, requestData) &&
			(currentMedia?.mediaType === MEDIA_TYPE.IMAGE ? versionImgSelected?.isDefault : true);
		if (hasChangeRotate()) {
			handleSaveRotate();
		}
		if (noChange) {
			handleCancel();
		} else {
			if (hasChangeLabelCustomField) {
				setRequestChange(requestData);
				return setTypeConfirm(TYPE_ACTIONS.SAVE_AND_CLOSE);
			}
			setHideMessage(true);

			await handleUpdateContent({
				requestData,
				hasChangeId: true,
			});
			setIsClose(true);
		}

		return null;
	};

	const handleChangeAfterAction = async (action: string, contentsAfterAction?: number[]) => {
		await resetCurrentMedia();
		onClose();

		await onChangeAfterAction(action, contentsAfterAction || undefined);
	};

	const formatDataRequest = (values: any) => {
		const requestData = formatReviewContentRequest(values, currentMedia);

		const listNoExist = checkListNotExist(
			renderOptionTag(labelFull, FORM_CONTENT_DETAIL.LABELS),
			requestData?.labels || [],
		).map((str, idx) => ({
			key: str,
			value: str,
			type: FORM_CONTENT_DETAIL.LABELS,
			id: new Date().getTime() + idx,
		}));

		dispatch(storeContentData({ tags: { ...tags, labels: [...labelFull, ...listNoExist] } }));

		if (requestData?.labels) {
			saveLabel(requestData.labels);
		}
		if (requestData?.flags) {
			saveFlag(requestData.flags);
		}

		if (!Object.keys(currentMedia?.review || {}).includes('note') && !requestData?.note) {
			delete requestData.note;
		}

		return requestData;
	};

	const handleUpdateContent = async ({
		requestData,
		hasChangeId,
	}: {
		requestData: ContentReviewDto;
		hasChangeId?: boolean;
	}) => {
		setIdContentUpdate(currentMedia?.id);

		if (!versionImgSelected?.isDefault && versionImgSelected) {
			await dispatch(
				updateImgVersionRequest({
					contentId: currentMedia?.id,
					versionId: versionImgSelected?.id,
					hasChangeId: !!hasChangeId,
				}),
			);
			await handleUpdateReviewContent({ requestData });
		} else {
			await handleUpdateReviewContent({ requestData });
		}
	};

	const handleUpdateReviewContent = ({ requestData }: { requestData: ContentReviewDto }) => {
		const { review } = currentMedia || {};

		if (!_.isEqual(review, requestData)) {
			let payload: ReviewContentPayloadDto = {
				id: requestData.contentId,
				params: { ...requestData, additionalInfo: null },
			};

			if (!review?.rejected !== !requestData.rejected) {
				payload = { ...payload };
				setContentIdMove(currentMedia?.id || null);
			}

			if (review?.pending !== requestData.pending) {
				setIsOverrideNeedReview(true);
			} else {
				payload.params = { ...payload.params, pending: false };
			}

			dispatch(reviewContentRequest(payload));
		} else {
			if (!isOverrideNeedReview) {
				markAsContent();
			}

			if (isContacted) {
				markAsContent(MARK_AS.CONTACTED.value);
			}
		}
	};

	const saveFlag = (listFlag: string[]) => {
		saveTags(listFlag, flagFull, keyTag.flags);
	};

	const saveLabel = (listLabel: string[]) => {
		saveTags(listLabel, labelFull, keyTag.labels);
	};

	const saveTags = (listTags: string[], listFull: TagItemDto[], key: string) => {
		let newTags: string[] = [...listTags] || [];
		const funcSave = key === keyTag.labels ? updateLabels : updateFlags;

		listFull.forEach((existingLabel: TagItemDto) => {
			newTags = newTags.filter((tag) => {
				return existingLabel?.value !== tag;
			});
		});

		if (newTags.length > 0) {
			const payload = newTags.concat(listFull?.map((item) => item?.value));
			dispatch(funcSave(payload));
		}
	};

	const markAsContent = (label?: string) => {
		const contentTemp = currentMediaList.length ? currentMediaList[0] : currentMedia;

		const payload: ReviewContentPayloadDto = {
			id: contentTemp?.id,
			params: { ...contentTemp?.review },
		};

		if (!_.isUndefined(contentTemp)) {
			const { review = {} } = contentTemp || {};
			if (label && label === MARK_AS.CONTACTED.value) {
				if ('contacted' in review && !review.contacted) {
					payload.params.contacted = true;
					dispatch(reviewContentRequest(payload));
				}
			} else {
				if (review?.pending) {
					payload.params.pending = false;
					const values = form.getFieldsValue();
					const { markedAs = [] } = values || {};

					const selectedMarkedAsTemp: string[] = [...markedAs];
					const index = selectedMarkedAsTemp.indexOf(MARK_AS.PENDING.additionalLabel);

					if (index > -1) {
						selectedMarkedAsTemp.splice(index, 1);
					}
					form.setFieldsValue({ markedAs: selectedMarkedAsTemp });

					dispatch(reviewContentRequest(payload));
				}
			}
		}
	};

	const handleContactedContent = () => {
		if (typeContent.isBookmark) {
			setShowRequestRight(false);
			if (!currentMedia?.review?.contacted) {
				setIsContacted(true);
				markAsContent(MARK_AS.CONTACTED.value);
			}
		}
	};

	const onDownload = () => {
		setIsDownload(!isDownload);
	};

	const funcSelectField = (id: string, fieldName: string) => {
		const elm: any = document.getElementById(id);

		if (elm) {
			elm.scrollIntoViewIfNeeded(true);
			elm.focus();
			setFieldSelect(fieldName);
		}
	};

	const handleFlag = () => {
		funcSelectField('flags_content_detail', FORM_CONTENT_DETAIL.FLAGS);
		setFindFlag(false);
	};

	const funcSelectFlagField = (hasTimeout?: boolean) => {
		if (hasTimeout) {
			setFindFlag(true);
		} else {
			handleFlag();
		}
	};

	const handleFlagAction = () => {
		setCollapseTerm(true);
		funcSelectFlagField(!collapseTerm);
	};

	const handleFavoriteContent = (content: ContentDto, isFavorite: boolean) => {
		if (markAsFavoriteContent) {
			markAsFavoriteContent(content);
		}

		dispatch(
			markFavoriteContentRequest({
				ids: [content.id],
				isFavorite,
			}),
		);
	};

	const handleDirectMessage = () => {
		setIsDisableAction(!isMessage);
		setIsMessage(!isMessage);
	};

	const openTemplateBuilder = (template?: any) => {
		const templateTemp = {
			...template,
			body: `${thumbnailContentInEmail(currentMedia?.thumbnail)}${template?.body || ''}`,
		};

		history.push({
			pathname: ROUTE_PATH.EMAIL_BUILDER,
			state: {
				type: STATE_TYPE.MESSAGE_OPEN_TEMPLATE,
				content: [currentMedia],
				currentTemplate: {
					...templateTemp,
				},
				isBackPageBefore: true,
			},
		});
	};

	const goToSocialProfile = () => {
		const { social: { socialUsername = '', socialSource = '' } = {} } = currentMedia || {};
		goToSocialNewTab(socialUsername, socialSource);
	};

	const onViewPost = () => {
		if (!currentMedia?.social?.permalink) return;
		handleRedirectLink(currentMedia?.social?.permalink || '');
	};

	const hasChangeRotate = () => {
		return !!currentMedia?.imgReplace;
	};

	const handleActionEditImg = async () => {
		if (currentMedia && versionImgSelected) {
			setIsLoading(true);
			const url = getSocialS3URL(versionImgSelected?.contentURL);
			if (versionImgSelected?.rotate) {
				const imgReplace: any = await rotateImageToBase64(url, versionImgSelected.rotate);
				setImgUrlEdit(imgReplace);
				setIsLoading(false);
			} else {
				setImgUrlEdit(url);
				setIsLoading(false);
			}
		}
	};

	const handleRotateImage = async () => {
		if (currentMedia) {
			if (!versionImgSelected?.isDefault) {
				dispatch(
					updateImgVersionRequest({
						contentId: currentMedia?.id,
						versionId: versionImgSelected?.id,
						hasChangeId: false,
					}),
				);
				setTypeAction(TYPE_ACTIONS.ROTATE_90);
				return;
			}
			handleSaveRotate();
		}
	};

	const handleSaveRotate = (noLoading?: boolean) => {
		if (currentMedia) {
			if (!noLoading) {
				setOtherLoading(true);
			}

			dispatch(
				rotateImageRequest({
					rotationAngleEnum: `${ROTATE.DEGREE_H}${ROTATE.D_90}`,
					ids: [currentMedia.id],
					isRequestDetail: true,
				}),
			);
		}
	};

	const handleFinishForm = (values: any) => {
		const dataFormat = formatDataRequest(values);
		const { hasChangeLabelCustomField, ...requestData } = dataFormat;

		if (
			_.isEqual(currentMedia?.review, requestData) &&
			(currentMedia?.mediaType === MEDIA_TYPE.IMAGE ? versionImgSelected?.isDefault : true)
		) {
			if (hasChangeRotate()) {
				handleSaveRotate();
				return;
			} else {
				message.info(t('content_management.notification.no_changes_warning'));
				return;
			}
		}
		if (hasChangeRotate()) {
			handleSaveRotate();
		}

		if (hasChangeLabelCustomField) {
			setRequestChange(requestData);
			setTypeConfirm(TYPE_ACTIONS.SAVE_CHANGES);
			return;
		}
		setHideMessage(false);
		handleUpdateContent({ requestData });
	};

	const handleSaveData = async (noSave?: boolean) => {
		const otherField: any = {};
		setTypeConfirm('');
		setActionMove('');

		if (requestChange && !noSave) {
			if (
				typeConfirm === TYPE_ACTIONS.SAVE_AND_CLOSE ||
				typeConfirm === TYPE_ACTIONS.SAVE_AND_MOVE
			) {
				setHideMessage(true);
				otherField.hasChangeId = true;
			}
			if (typeConfirm === TYPE_ACTIONS.SAVE_CHANGES) {
				setHideMessage(false);
			}

			if (hasChangeRotate()) {
				handleSaveRotate();
			}
			await handleUpdateContent({
				requestData: requestChange,
				...otherField,
			});
		}

		if (noSave) {
			handleClearActionChangeCustomField();
		}

		if (typeConfirm === TYPE_ACTIONS.SAVE_AND_CLOSE) {
			if (noSave) {
				handleCancel();
			}

			setIsClose(true);
		}
		if (typeConfirm === TYPE_ACTIONS.SAVE_AND_MOVE) {
			if (
				requestChange?.rejected === currentMedia?.review?.rejected ||
				actionMove === MOVE_CONTENT.PREVIOUS
			) {
				await resetCurrentMedia();
				await moveContent(actionMove);
				setVisibleImage(true);
			}
		}

		return null;
	};

	const handleChangeVersion = (version: ImgVersionDto, idChange?: number | null) => {
		if (currentMedia) {
			setShowDetectedLogo(false);

			const isContentUpdate = idChange && currentMedia?.id === idChange;
			if (isContentUpdate || !idChange) {
				setVersionImgSelected(version);
				setCurrentMedia({
					...currentMedia,
					contentUrl: version?.contentURL,
					thumbnail: version?.thumbnailURL,
					rotate: version?.rotate || undefined,
				});

				setVisibleImage(true);
				if (isCarousel) {
					setCurrentMediaList(
						currentMediaList?.map((item, idx) => {
							if (idx === currentRef) {
								return {
									...item,
									contentUrl: version?.contentURL,
									thumbnail: version?.thumbnailURL,
									rotate: version?.rotate || undefined,
								};
							}
							return { ...item };
						}),
					);
				}
			}
		}
	};

	const renderOptionTag = (list: Array<TagItemDto>, type: string) => {
		const { labels = [], flags = [] } = currentMedia?.review || {};
		if (list?.length === 0) {
			if (type === FORM_CONTENT_DETAIL.LABELS) {
				return labels || [];
			}
			if (type === FORM_CONTENT_DETAIL.FLAGS) {
				return flags || [];
			}
		}
		return list?.map((item) => item?.value);
	};

	const renderOtherField = (fieldName: string) => {
		return {
			open: fieldSelect === fieldName,
			onBlur: () => setFieldSelect(''),
			onFocus: () => setFieldSelect(fieldName),
		};
	};

	const valuesContext = {
		paramPublish,
		setParamPublish,
		publishLogs,
		setPublishLogs,
		isLoading,
		setIsLoading,
		isDisableAction,
		typeContent,
		optionFlags: renderOptionTag(flagFull, FORM_CONTENT_DETAIL.FLAGS),
		setCollapseTerm,
		collapseTerm,
		currentMedia,
		setTypeAction,
		typeAction,
		versionImgSelected,
		handleChangeVersion,
		setShowDetectedLogo,
		showDetectedLogo,
		CDMBlockCreatorDisabled,
		setTypeConfirm,
		onClose,
		typeConfirm,
		handleChangeAfterAction,
		idContentUpdate,
		setIdContentUpdate,
		setVersionImgSelected,
		updateAfterChangeVersion,
		hideMessage,
		setHideMessage,
		isClose,
		setIsClose,
		handleCancel,
		isBlockDelete,
		setIsBlockDelete,
		onChangeAfterBlock,
		updateVerificationScrore,
		fieldSelect,
		funcSelectField,
		funcSelectFlagField,
		isDashboardAmbassador,
		setField: form.setFieldsValue,
		handleClearActionChangeCustomField,
		handleRotateImage,
		enableRotateImage,
		handleFetchTerms,
		disableActionPage,
		setCurrentMedia,
		handleActionEditImg,
		otherLoading,
		setOtherLoading,
		isRotateRequest,
		setIsRotateRequest,
		isAdditionalLoading,
		setIsAdditionalLoading,
		isSocialContent,
	};

	const loading =
		isLoading ||
		fetchingTerms ||
		fetchingTags ||
		loadingContentEmoji ||
		loadingImgVersion ||
		otherLoading;

	return (
		<StyledWrapperContent
			width={LIMIT_SIZE.WIDTH}
			height={LIMIT_SIZE.HEIGHT}
			maxWidth={`${LIMIT_SIZE.MAX_WIDTH}px`}
			maxHeight={`${LIMIT_SIZE.MAX_HEIGHT}px`}>
			{imgUrlEdit && (
				<ImageEditorModal
					visible={!!imgUrlEdit}
					onCancel={() => setImgUrlEdit('')}
					config={{
						source: imgUrlEdit,
					}}
					onSave={(file: any) => {
						const formData = new FormData();
						formData.append('file', file);

						dispatch(
							createImgVersionRequest({
								params: {
									contentId: currentMedia?.id,
									method: METHOD_VERSION.EDIT,
									isDefault: false,
								},
								dataRequest: formData,
							}),
						);
					}}
				/>
			)}

			<ContentDetailContext.Provider value={valuesContext}>
				<LoadingWrapper isLoading={false}>
					<EffectFunc form={form} />

					<VoteContent />

					{currentMedia && (
						<>
							<StyledRow style={{ height: '100%' }}>
								<StyledCol
									padding="0"
									style={{
										maxWidth: 'calc(100% - 420px)',
										maxHeight: '100%',
										width: '100%',
										overflow: 'hidden',
									}}>
									<StyledImageContainer
										style={{
											padding: '24px',
											justifyContent: 'center',
											width: '100%',
										}}>
										{!isCarousel &&
											!_.isEmpty(currentMedia) &&
											renderMedia(currentMedia, currentRef)}
										{isCarousel &&
											currentMediaList?.length > 0 &&
											renderMedialist(currentMediaList)}
									</StyledImageContainer>
								</StyledCol>

								<StyledCol
									padding="0 24px"
									width="420px"
									style={{ position: 'relative', height: '100%' }}>
									<StyledIcon
										onClick={handleCloseIcon}
										style={{
											position: 'absolute',
											top: '14px',
											right: '14px',
											zIndex: 7777,
										}}>
										<IconClose />
									</StyledIcon>
									<StyledSkeleton
										active
										className={`skeleton_show ${loading ? '' : 'hidden'}`}
										paragraph={{ rows: UNIT_VALUE.S_2 }}
									/>
									<StyledWrapperContent
										height="100%"
										width="100%"
										className={loading ? 'hidden' : ''}>
										<StyledSection
											padding="0"
											fontSize="1.143rem"
											borderPosition="bottom"
											heightSection="54px"
											flexDirection="row">
											<StyledContainerAction
												isDisableAction={
													isDisableAction || disableActionPage
												}>
												<ActionContentDetail
													currentMedia={currentMedia}
													setTypeConfirm={setTypeConfirm}
													onFullScreen={() => onFullScreen()}
													onDownload={() => onDownload()}
													onViewPost={onViewPost}
													handleFlagAction={handleFlagAction}
												/>
											</StyledContainerAction>
										</StyledSection>

										{!isMessage && !showRequestRight ? (
											<React.Fragment>
												{((imgRef && imgRef.current !== null) ||
													(videoRef && videoRef.current !== null)) && (
													<StyledWrapperContent
														id={'body_content_detail'}
														height="calc(100% - 118px)"
														overflow="hidden auto"
														className={'custom_scroll_bar'}>
														<StyledForm
															form={form}
															onFinish={handleFinishForm}
															layout="vertical"
															initialValues={{}}
															isDisableAction={isDisableAction}>
															{isSocialContent && (
																<SocialProfile
																	activeSocialType={
																		currentMedia.social
																			?.socialSource
																	}
																	timestamp={
																		currentMedia?.social
																			?.timestamp
																	}
																	username={
																		currentMedia?.social
																			?.socialUsername
																	}
																	permalink={
																		currentMedia?.social
																			?.permalink
																	}
																/>
															)}
															<Overview
																optionData={galleryList || []}
																currentMedia={currentMedia}
																otherPropSelectFlag={{
																	...renderOtherField(
																		FORM_CONTENT_DETAIL.FLAGS,
																	),
																	id: 'flags_content_detail',
																}}
															/>

															{isSocialContent && (
																<>
																	<StyledDivider
																		borderColor={
																			THEME.colors.darkBlue3
																		}
																		margin="16px 0"
																	/>
																	<SocialHashTagMention
																		caption={
																			currentMedia?.description ||
																			''
																		}
																		hashtags={
																			currentMedia?.hashtags ||
																			[]
																		}
																		mentionUsernames={
																			currentMedia?.mentionUsernames ||
																			[]
																		}
																	/>
																</>
															)}
															<DetailRateAndTag
																optionLabels={renderOptionTag(
																	labelFull,
																	FORM_CONTENT_DETAIL.LABELS,
																)}
																currentMedia={currentMedia}
																otherPropSelectTag={{
																	...renderOtherField(
																		FORM_CONTENT_DETAIL.TAGS,
																	),
																	id: 'tags_content_detail',
																}}
															/>
															{isSocialContent && <OtherDetail />}
															{!isSocialContent && (
																<>
																	<DescAndCreatorInfo />
																	<CustomFieldSession
																		customFields={customFields}
																	/>
																	<VersionDetails />
																	<FileInformation />
																	<AlternativeText />
																</>
															)}
															<FormItemSelect
																mode="multiple"
																optionData={dataStatus}
																name={FORM_CONTENT_DETAIL.STATUS}
																label={t('content_detail.status')}
																otherPropSelect={{
																	...renderOtherField(
																		FORM_CONTENT_DETAIL.STATUS,
																	),
																	id: 'status_content_detail',
																}}
															/>
															<ReadOnlyStatus
																content={currentMedia}
															/>
															<FormItemSelect
																mode="multiple"
																optionData={
																	campaignListContent || []
																}
																name={FORM_CONTENT_DETAIL.CAMPAIGNS}
																label={t(
																	'content_detail.campaigns',
																)}
																otherPropSelect={{
																	...renderOtherField(
																		FORM_CONTENT_DETAIL.CAMPAIGNS,
																	),
																	id: 'campaigns_content_detail',
																}}
															/>
															<StyledFormItem
																name={FORM_CONTENT_DETAIL.NOTE}
																label={
																	<StyledLabel>
																		{t('content_detail.notes')}
																	</StyledLabel>
																}>
																<StyledTextArea
																	id={'note_content_detail'}
																	disabled={isDisableAction}
																	rows={1}
																/>
															</StyledFormItem>
														</StyledForm>
													</StyledWrapperContent>
												)}

												<FooterAction
													onSaveChanges={() => form.submit()}
													handleDirectMessage={handleDirectMessage}
													currentMedia={currentMedia}
													onFullScreen={onFullScreen}
													setTypeConfirm={setTypeConfirm}
													onViewPost={onViewPost}
													onDownload={onDownload}
													onShowRequestRight={() =>
														setShowRequestRight(true)
													}
													handleResetData={handleResetData}
													markAsFavorite={handleFavoriteContent}
												/>
											</React.Fragment>
										) : (
											<>
												{isMessage && (
													<MessageTemplates
														currentContent={currentMedia}
														handleDirectMessage={handleDirectMessage}
														openTemplateBuilder={openTemplateBuilder}
													/>
												)}
												{showRequestRight && (
													<div style={{ height: 'calc(100% - 52px)' }}>
														<RequestRightsDM
															isLoading={isLoading}
															setIsLoading={setIsLoading}
															goToSocialProfile={goToSocialProfile}
															handleRequestRights={() =>
																setShowRequestRight(false)
															}
															currentContent={currentMedia}
															getSelectId={true}
															markAsContacted={handleContactedContent}
														/>
													</div>
												)}
											</>
										)}
									</StyledWrapperContent>
								</StyledCol>
							</StyledRow>

							<ConfirmModalAction handleSaveData={handleSaveData} />

							{isFullScreen && (
								<StyledFullScreen
									centered={true}
									closable={false}
									visible={isFullScreen}
									transitionName="none"
									onCancel={() => onFullScreen()}
									footer={false}>
									<StyledIconClose
										border="none"
										position="fixed"
										right={true}
										onClick={onFullScreen}>
										<FullscreenExitOutlined className="fullscreen-exit-icon" />
									</StyledIconClose>

									{currentMedia?.mediaType === MEDIA_TYPE.VIDEO ? (
										<StyledVideoFull height="100%" width="100%">
											<FullScreenVideo currentMedia={currentMedia} />
										</StyledVideoFull>
									) : (
										<StyledImageFull
											preview={false}
											fallback={ImageFallback}
											placeholder={<LoadingWrapper isLoading={true} />}
											src={getSocialS3URL(currentMedia?.contentUrl)}
											onError={(e) =>
												currentMedia?.social &&
												currentMedia?.social.socialSource ===
													SOCIAL_SOURCE.TIKTOK
													? handleThumbImageLoadedError(
															e,
															SOCIAL_SOURCE.TIKTOK,
															currentMedia?.social.permalink,
													  )
													: handleImageLoadedError(
															e,
															currentMedia?.contentUrl,
															currentMedia?.contentExternalUrl,
															SOCIAL_SOURCE.INSTAGRAM,
													  )
											}
										/>
									)}
								</StyledFullScreen>
							)}

							{isDownload && (
								<DownloadModal
									albumId={folderId}
									fileNameDefault={'_upload source_date imported'}
									onDownload={onDownload}
									isDownload={isDownload}
									selectContentList={content ? [content] : []}
									contentDetailDto={contentDetailDto}
								/>
							)}
						</>
					)}
				</LoadingWrapper>
			</ContentDetailContext.Provider>
		</StyledWrapperContent>
	);
});

export default ContentDetail;
