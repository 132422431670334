import { IconArrowLine, IconCheckCircle } from '@assets/icons';
import ActivationEmailModal from '@components/Campaigns/ActivationEmailModal';
import CardCampaign from '@components/Campaigns/CardCampaign';
import { ROUTE_PATH } from '@constants/common';
import THEME from '@constants/themes/themes';
import { LoadingWrapper } from '@cores/index';
import { CampaignDto, ItemCamPaign } from '@models/campaign';
import { getRecommendationCampaignEnd, getRecommendationCampaignRequest } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledCol,
	StyledIcon,
	StyledRow,
	StyledText,
	StyledWrapper,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledTabs } from '@styled/Creators/ContentStyled';
import { StyledOverlayCompletedCampaign } from '@styled/Creators/RecommendationsStyled';
import { StyledDrawer } from '@styled/Creators/SummaryStyled';
import history from '@utils/history';
import { Layout, Tabs, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type CampaignRecommendationsProps = {
	visible: boolean;
	onClose: () => void;
};

const CampaignRecommendations = (props: CampaignRecommendationsProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { visible, onClose } = props;
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);
	const { recommendationCampaignList = [], loadingCampaign = false }: CampaignDto = useSelector(
		(state: any) => state.campaign,
	);
	const TAB_PANES = {
		OPEN_CAMPAIGNS: 'OPEN_CAMPAIGNS',
		COMPLETED_CAMPAIGNS: 'COMPLETED_CAMPAIGNS',
		CAMPAIGNS: 'CAMPAIGNS',
	};

	const [activeTab, setActiveTab] = useState<string>(TAB_PANES.OPEN_CAMPAIGNS);
	const [campaignSelected, setCampaignSelected] = useState<ItemCamPaign | any>(null);
	const [visibleCampaignActivate, setVisibleCampaignActivate] = useState<boolean>(false);

	const listTabPane = [
		{
			tabId: TAB_PANES.OPEN_CAMPAIGNS,
			name: t('creator_profile.recommendations.open_campaigns'),
		},
		{
			tabId: TAB_PANES.COMPLETED_CAMPAIGNS,
			name: t('creator_profile.recommendations.completed_campaigns'),
		},
		{
			tabId: TAB_PANES.CAMPAIGNS,
			name: (
				<StyledIcon
					margin="6px 0 0 0"
					size={24}
					onClick={(e) => {
						e.stopPropagation();
						history.push(ROUTE_PATH.CAMPAIGNS);
					}}>
					<IconArrowLine />
				</StyledIcon>
			),
		},
	];

	useEffect(() => {
		if (visible) {
			getCampaigns(TAB_PANES.OPEN_CAMPAIGNS);
		}

		return () => {
			setActiveTab(TAB_PANES.OPEN_CAMPAIGNS);
			dispatch(getRecommendationCampaignEnd());
		};
	}, [visible]);

	const getCampaigns = (tab: string) => {
		dispatch(
			getRecommendationCampaignRequest({
				isOpenCampaignTab: tab === TAB_PANES.OPEN_CAMPAIGNS,
				campaignIds: creatorProfile?.campaignIds || [],
			}),
		);
	};

	const handleActivate = (campaign: ItemCamPaign) => {
		if (!campaign?.activationTemplateId) {
			message.destroy();
			message.error(t('campaign.message.no_activation_template'));
			return;
		}
		setCampaignSelected(campaign);
		setVisibleCampaignActivate(true);
	};

	const renderCampaign = () => {
		return (
			recommendationCampaignList &&
			recommendationCampaignList.map((campaign: ItemCamPaign) => {
				return (
					<StyledCol span={12} key={campaign.id} height="fit-content">
						<CardCampaign
							key={campaign.id}
							isTabActive={true}
							data={campaign}
							showModal={() => {}}
							handleActivate={() => handleActivate(campaign)}
							isRecommendationCampaign={true}
						/>
						{activeTab === TAB_PANES.COMPLETED_CAMPAIGNS && (
							<StyledOverlayCompletedCampaign>
								<StyledWrapperContent textAlign="center">
									<StyledIcon size={60} fill={THEME.colors.greenBase}>
										<IconCheckCircle />
									</StyledIcon>
									<StyledText fontSize="20px" fontWeight={700}>
										{t('creator_profile.recommendations.done')}
									</StyledText>
								</StyledWrapperContent>
							</StyledOverlayCompletedCampaign>
						)}
					</StyledCol>
				);
			})
		);
	};

	const renderTab = () => {
		return listTabPane.map((item) => {
			return (
				<Tabs.TabPane tab={item.name} key={item.tabId}>
					<LoadingWrapper isLoading={false}>
						<StyledRow
							style={{
								overflow: 'auto',
								height: '100%',
								width: '100%',
							}}
							gutter={[12, 12]}
							padding="24px 20px"
							margin="0 !important">
							{renderCampaign()}
						</StyledRow>
					</LoadingWrapper>
				</Tabs.TabPane>
			);
		});
	};

	const onChangeTab = (key: string) => {
		setActiveTab(key);
		dispatch(getRecommendationCampaignEnd({}));
		getCampaigns(key);
	};

	return (
		<StyledDrawer
			visible={visible}
			placement="right"
			getContainer={false}
			onClose={onClose}
			maskClosable={false}>
			<Layout>
				<LoadingWrapper isLoading={loadingCampaign}>
					<StyledWrapper marginTop="16px">
						<StyledTabs activeKey={activeTab} onChange={onChangeTab}>
							{renderTab()}
						</StyledTabs>
					</StyledWrapper>
				</LoadingWrapper>
			</Layout>
			{visibleCampaignActivate && campaignSelected && (
				<ActivationEmailModal
					width={'calc(100vw - 300px)'}
					onCancel={() => {
						setVisibleCampaignActivate(false);
					}}
					visible={visibleCampaignActivate}
					footer={null}
					campaign={campaignSelected}
					isInvitationFromCreator={true}
				/>
			)}
		</StyledDrawer>
	);
};

export default CampaignRecommendations;
