import { IconArrowLine, IconStar } from '@assets/icons';
import notification from '@assets/images/notification.png';
import { ROUTE_PATH, TYPE_FORMAT_DATE } from '@constants/common';
import {
	DEFAULT_TASK_NOTIFICATION_PAYLOAD,
	NOTIFICATION_ELEMENT_SELECTOR,
} from '@constants/notification';
import { FAILED, SUCCEEDED } from '@constants/status';
import { TASK_ACTION_TYPE } from '@constants/taskManager';
import THEME from '@constants/themes/themes';
import NotificationContext from '@contexts/Notification';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { NotificationStoreDto } from '@models/notification/stores';
import { TaskNotificationItemDto, TaskNotificationListDto } from '@models/notification/summary';
import {
	getTaskNotificationRequest,
	markTaskNotificationAsReadEnd,
	markTaskNotificationAsReadRequest,
	toggleTaskNotificationStatusEnd,
	toggleTaskNotificationStatusRequest,
} from '@stores/actions';
import {
	StyledFlex,
	StyledIcon,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledNotificationNumBackground,
	StyledNotificationNumWrapper,
	StyledNotificationPicture,
	StyledNotificationTextReadAll,
	StyledNotificationWrapper,
	StyledNotificationWrapperMessage,
	StyledRedirectTaskManager,
	StyledTitleGroupNotify,
	StyledWrapperIconBg,
	StyledWrapperMessage,
} from '@styled/Layout/Notify';
import { StyledCommentText } from '@styled/TaskManager/TaskManagerStyled';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

type TaskNotificationProps = {
	onClose: () => void;
};

const TaskNotification = (props: TaskNotificationProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		taskNotificationData,
		toggleTaskNotificationStatus,
		fetchingTaskNotification,
		markTaskNotificationAsReadStatus,
	}: NotificationStoreDto = useSelector((state: any) => state.notification);
	const history = useHistory();
	const { onClose } = props;
	const { requestPayload, activeTab, showHeaderNotification, visible, setRequestPayload } =
		useContext(NotificationContext);

	const DEFAULT_NOTIFICATIONS = {
		today: [],
		last7Days: [],
		older: [],
	};
	const [taskNotifications, setTaskNotifications] =
		useState<TaskNotificationListDto>(DEFAULT_NOTIFICATIONS);
	const [currentNotification, setCurrentNotification] = useState<TaskNotificationItemDto>();

	useEffect(() => {
		return () => {
			if (activeTab || !visible) {
				setTaskNotifications(DEFAULT_NOTIFICATIONS);
			}
		};
	}, [activeTab, visible]);

	useEffect(() => {
		if (toggleTaskNotificationStatus === SUCCEEDED) {
			if (currentNotification && currentNotification.notificationTypeByTime) {
				const fieldName = currentNotification.notificationTypeByTime;
				const updatedList = taskNotifications[fieldName];
				const index = taskNotifications[fieldName].findIndex(
					(item: TaskNotificationItemDto) => item.id === currentNotification.id,
				);
				if (index !== -1) {
					updatedList[index].read = true;
				}
				setTaskNotifications({ ...taskNotifications, [fieldName]: updatedList });
			}
		}
		if (toggleTaskNotificationStatus === FAILED || toggleTaskNotificationStatus === SUCCEEDED) {
			dispatch(toggleTaskNotificationStatusEnd());
		}
	}, [toggleTaskNotificationStatus]);

	useEffect(() => {
		if (taskNotificationData && taskNotificationData?.notifications) {
			const loadedNotifications: TaskNotificationListDto = taskNotificationData.notifications;
			const notifications: TaskNotificationListDto =
				requestPayload && requestPayload.page === 1
					? { ...loadedNotifications }
					: {
							today: [...taskNotifications.today, ...loadedNotifications.today],
							last7Days: [
								...taskNotifications.last7Days,
								...loadedNotifications.last7Days,
							],
							older: [...taskNotifications.older, ...loadedNotifications.older],
					  };
			setTaskNotifications(notifications);
		}
	}, [taskNotificationData]);

	useEffect(() => {
		if (markTaskNotificationAsReadStatus === SUCCEEDED) {
			if (taskNotificationData?.isShowAll) {
				const updatedList: TaskNotificationListDto = DEFAULT_NOTIFICATIONS;
				const keys = Object.keys(taskNotifications);
				keys.forEach((key: string) => {
					updatedList[key] = taskNotifications[key].map(
						(item: TaskNotificationItemDto) => {
							return { ...item, read: true };
						},
					);
				});
				setTaskNotifications(updatedList);
			} else {
				setTaskNotifications(DEFAULT_NOTIFICATIONS);
			}
		}
		if (
			markTaskNotificationAsReadStatus === FAILED ||
			markTaskNotificationAsReadStatus === SUCCEEDED
		) {
			dispatch(markTaskNotificationAsReadEnd());
		}
	}, [markTaskNotificationAsReadStatus]);

	const hasTaskNotificationInList = useMemo(() => {
		const keys = Object.keys(taskNotifications);
		const hasValues = keys.some((key: string) => taskNotifications[key].length);
		return hasValues;
	}, [taskNotifications]);

	const handleTaskNotificationStatus = (id: number) => {
		dispatch(toggleTaskNotificationStatusRequest({ id }));
	};

	const redirectToTaskDetail = (item?: TaskNotificationItemDto) => {
		onClose();
		if (item) {
			handleTaskNotificationStatus(item.id);
			history.push(`${ROUTE_PATH.TASK_MANAGER}?taskId=${item?.taskId}`);
			return;
		}
		history.push(ROUTE_PATH.TASK_MANAGER);
	};

	const renderGoToTaskManager = (item?: TaskNotificationItemDto) => {
		const color = !item ? THEME.colors.gray5 : THEME.colors.gray1;
		return (
			<StyledRedirectTaskManager
				className="redirect_to_task_manager"
				onClick={() => {
					redirectToTaskDetail(item);
				}}
				margin="4px 0 0 0"
				justify={!item ? 'center' : 'flex-start'}>
				<StyledText
					className="hover"
					cursor="pointer"
					textDecoration={!item ? 'unset' : 'underline'}
					color={color}>
					{t('notification.go_to_task_manager')}
				</StyledText>
				<StyledIcon fillPath={color} margin="0 0 0 5px" size={16}>
					<IconArrowLine />
				</StyledIcon>
			</StyledRedirectTaskManager>
		);
	};

	const renderTaskNotificationItem = (item: TaskNotificationItemDto) => {
		return (
			<StyledNotificationWrapperMessage margin="6px 0 0 0">
				<StyledWrapperMessage>
					<StyledWrapperContent width="calc(100% - 32px)">
						<StyledText
							fontSize="16px"
							dangerouslySetInnerHTML={{ __html: item.message }}
							margin="0 0 6px 0"
						/>
						{item.description && (
							<StyledWrapperContent margin="0 0 0 -8px">
								<StyledCommentText
									padding="0 10px"
									fontStyle="unset"
									dangerouslySetInnerHTML={{ __html: item.description || '' }}
								/>
							</StyledWrapperContent>
						)}

						<StyledText color={THEME.colors.gray1}>
							{convertUtcToLocalTimeWithFormat(item.createAt, TYPE_FORMAT_DATE.TIME)}
						</StyledText>
						{item.eventType === TASK_ACTION_TYPE.COMMENT_ADDED && (
							<StyledFlex
								className="redirect_to_task_manager"
								onClick={() => {
									redirectToTaskDetail(item);
								}}
								margin="4px 0 0 0"
								justify="flex-start">
								<StyledText
									cursor="pointer"
									textDecoration="underline"
									color={THEME.colors.gray1}>
									{t('notification.view_comment')}
								</StyledText>
								<StyledIcon margin="0 0 0 5px" size={16}>
									<IconArrowLine />
								</StyledIcon>
							</StyledFlex>
						)}
						{item.eventType === TASK_ACTION_TYPE.TASK_ASSIGNED &&
							renderGoToTaskManager(item)}
					</StyledWrapperContent>
					{!item.read && (
						<StyledWrapperIconBg>
							<StyledNotificationNumWrapper
								onClick={(e: React.MouseEvent<HTMLDivElement>) => {
									e.preventDefault();
									e.stopPropagation();
									handleTaskNotificationStatus(item.id);
									setCurrentNotification(item);
								}}>
								<StyledNotificationNumBackground />
							</StyledNotificationNumWrapper>
						</StyledWrapperIconBg>
					)}
				</StyledWrapperMessage>
			</StyledNotificationWrapperMessage>
		);
	};

	const renderGroupedNotification = () => {
		const keys = Object.keys(taskNotifications);
		return keys.map((key: string) => {
			return (
				!!taskNotifications[key].length && (
					<StyledWrapperContent margin="0 0 18px 0">
						<StyledTitleGroupNotify>
							{t(`notification.${key.toLowerCase()}`)}
						</StyledTitleGroupNotify>
						<StyledWrapperContent margin="8px 0 0 0">
							{taskNotifications[key].map((item: TaskNotificationItemDto) =>
								renderTaskNotificationItem(item),
							)}
						</StyledWrapperContent>
					</StyledWrapperContent>
				)
			);
		});
	};

	const showAllTaskNotifications = () => {
		dispatch(
			getTaskNotificationRequest({
				...DEFAULT_TASK_NOTIFICATION_PAYLOAD,
				isShowAll: true,
			}),
		);
	};

	const showUnreadTaskNotifications = () => {
		setRequestPayload({ ...DEFAULT_TASK_NOTIFICATION_PAYLOAD, isShowAll: false });
	};

	const markAllAsRead = () => {
		dispatch(markTaskNotificationAsReadRequest());
	};

	const renderOtherActions = () => {
		return (
			<StyledNotificationWrapper
				className={`${
					showHeaderNotification ? 'header_notification_others' : ''
				} header_notification_fixed`}>
				<StyledText
					className="hover"
					cursor="pointer"
					margin="0 16px 0 0"
					textDecoration="underline"
					onClick={
						taskNotificationData?.isShowAll
							? showUnreadTaskNotifications
							: showAllTaskNotifications
					}>
					{t(
						taskNotificationData?.isShowAll
							? 'notification.show_unread_only'
							: 'notification.show_all',
					)}
				</StyledText>
				{hasTaskNotificationInList && (
					<StyledText
						className="hover"
						cursor="pointer"
						textDecoration="underline"
						onClick={markAllAsRead}>
						{t('notification.mark_all_as_read')}
					</StyledText>
				)}
			</StyledNotificationWrapper>
		);
	};

	return (
		<>
			{showHeaderNotification && (
				<StyledNotificationWrapper className="header_notification" height="52px">
					{renderOtherActions()}
				</StyledNotificationWrapper>
			)}
			<StyledNotificationWrapper
				id={NOTIFICATION_ELEMENT_SELECTOR.TASK_NOTIFICATION_WRAPPER_ID}
				subtractedHeight={showHeaderNotification ? 42 : 0}>
				{hasTaskNotificationInList && <>{renderGroupedNotification()}</>}
				{renderOtherActions()}
				{!hasTaskNotificationInList && !fetchingTaskNotification && (
					<StyledWrapperContent
						height="100%"
						display="flex"
						alignItems="center"
						justifyContent="center">
						<StyledWrapperContent>
							<StyledNotificationPicture src={notification} alt="" />
							<StyledNotificationTextReadAll>
								{t('notification.no_unread_messages')}
							</StyledNotificationTextReadAll>
							{renderGoToTaskManager()}
						</StyledWrapperContent>
					</StyledWrapperContent>
				)}
				{taskNotificationData?.isShowAll && hasTaskNotificationInList && (
					<StyledWrapperContent
						margin="32px 0 18px 0"
						display="flex"
						justifyContent="center"
						textAlign="center">
						<StyledWrapperContent width="100%">
							<StyledText>
								{t('notification.all_notifications_last_90_days')}
							</StyledText>
							<StyledWrapperContent margin="6px 0 0 0">
								{Array(3).fill(
									<StyledIcon cursor="default" padding="0 4px" size={24}>
										<IconStar />
									</StyledIcon>,
								)}
							</StyledWrapperContent>
							{renderGoToTaskManager()}
						</StyledWrapperContent>
					</StyledWrapperContent>
				)}
			</StyledNotificationWrapper>
		</>
	);
};

export default TaskNotification;
