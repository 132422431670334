import SendTestEmailModal from '@components/Messages/EmailTemplates/Previews/SendTestEmailModal';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import {
	StyledPreviewEmailTemplateModal,
	StyledPreviewTemplateContainer,
} from '@styled/Campaign/CampaignDetailStyled';
import { StyledButton } from '@styled/Common/CommonStyled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type PreviewEmailTemplate = {
	template: EmailTemplatesDataDto;
	onClose: () => void;
};

const PreviewEmailTemplate = (props: PreviewEmailTemplate) => {
	const { template, onClose } = props;
	const { t } = useTranslation();
	const [actionType, setActionType] = useState<string>('');

	const ACTION_TYPES = {
		SEND_EMAIL_TEST: 'SEND_EMAIL_TEST',
	};

	const renderFooter = () => {
		return [
			<StyledButton
				onClick={() => {
					setActionType(ACTION_TYPES.SEND_EMAIL_TEST);
				}}>
				{t('email_templates.modal.send_test_email')}
			</StyledButton>,
			<StyledButton type="primary" onClick={onClose}>
				{t('button.close')}
			</StyledButton>,
		];
	};

	return (
		<>
			<StyledPreviewEmailTemplateModal
				title={t('campaign.modal.preview')}
				visible={true}
				footer={renderFooter()}
				centered
				onCancel={onClose}>
				<StyledPreviewTemplateContainer
					dangerouslySetInnerHTML={{ __html: template?.body || '' }}
				/>
			</StyledPreviewEmailTemplateModal>

			<SendTestEmailModal
				handleCancel={() => setActionType('')}
				visible={actionType === ACTION_TYPES.SEND_EMAIL_TEST}
				template={template}
			/>
		</>
	);
};

export default PreviewEmailTemplate;
