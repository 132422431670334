import { IconCancel, IconExpandMore, IconInfo } from '@assets/icons';
import { PATTERNS, ROUTE_PATH, TYPE_ACTIONS, TYPE_CSV, TYPE_FORMAT_DATE } from '@constants/common';
import { SPECIAL_CHARS } from '@constants/settings';
import {
	CAPTION_SENTIMENT_TYPE,
	CREATOR_TYPE_SETTING,
	DEFAULT_FORM_SETTING,
	LIST_CAPTION_SENTIMENT,
	LIST_MEDIA_TYPE,
	LIST_OPTION_CREATOR,
	LIST_SOURCE,
	NAME_SETTING_FEED,
	OPTION_ALL,
	SOURCE_TYPE,
} from '@constants/settings/socialAggregator';
import { OPERATOR } from '@constants/social_search';
import THEME from '@constants/themes/themes';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { OptionTypeItem } from '@models/filter';
import {
	StyledBoxDesc,
	StyledCheckbox,
	StyledDatePicker,
	StyledDescText,
	StyledDropdown,
	StyledIcon,
	StyledLink,
	StyledTitle,
	StyledUpload,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledFormItem,
	StyledInput,
	StyledLabel,
	StyledSourcesInfo,
} from '@styled/Filter/FilterContentStyled';
import { StyledTagItem, StyledWrapperTagItem } from '@styled/Settings/SettingAggregatorStyled';
import { StyledTextUploadCSV } from '@styled/Settings/SocialSearchStyled';
import { checkRegex, convertSocialUsername } from '@utils/funcHelper';
import { languageAmazon } from '@utils/languageAmazon';
import { Button, Col, Form, Menu, Row, message } from 'antd';
import { isEqual } from 'lodash';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CheckboxGroup from './CheckboxGroup';
import RadioGroup from './RadioGroup';
import SelectForm from './SelectForm';
import { isAllSources } from '@utils/socialSearch';

type PropTypes = {
	form: any;
	isFilterComponent?: boolean;
	formDetailFeedProps?: any;
};

const FormDetailFeed = forwardRef((props: PropTypes, ref: any) => {
	const { form, isFilterComponent, formDetailFeedProps } = props;

	const {
		detailFeed,
		handleFinishForm,
		hasTerms,
		setHasTerms,
		typeIncluded,
		setTypeIncluded,
		setHasChange,
		listCaptionType,
		setListCaptionType,
		listSourceType,
		setListSourceType,
		disabledCheck,
		setDisabledCheck,
		setTypeCreator,
		typeCreator,
		setFileCSVUpload,
		setTypeAction,
	} = formDetailFeedProps || {};

	const history = useHistory();
	const { t } = useTranslation();
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const {
		tiktokSearchEnabled = true,
		instagramSearchEnabled = true,
		sentimentAnalysisEnabled = true,
		youtubeSearchEnabled = false,
	} = clientSettings;
	const refScroll = useRef<any>({});
	const refUpload = useRef<any>({});

	const scrollTopForm = () => {
		if (refScroll?.current) {
			refScroll.current.scrollTop = 0;
		}
	};

	useEffect(() => {
		scrollTopForm();
	}, [detailFeed?.id]);

	useImperativeHandle(ref, () => ({
		scrollTopForm,
	}));

	const handleCheckRegexSearchTerms = (value: string) => {
		let valid = false;
		if (value) {
			if (value[0] === SPECIAL_CHARS.AT) {
				valid = checkRegex(PATTERNS.SOCIAL_USERNAME, value);
			}
			if (value[0] === SPECIAL_CHARS.HASH) {
				valid = checkRegex(PATTERNS.HASHTAG, value);
			}
		}
		return valid;
	};

	const handleChangeUploadFile = ({ fileList, file }: any) => {
		setFileCSVUpload(file);
		setTypeAction(TYPE_ACTIONS.UPLOAD);
	};

	const renderUpload = () => {
		return (
			<StyledUpload
				showUploadList={false}
				maxCount={1}
				ref={refUpload}
				beforeUpload={() => false}
				accept={TYPE_CSV}
				onChange={handleChangeUploadFile}>
				<StyledTextUploadCSV>{t('social_aggregator.text_upload_csv')}</StyledTextUploadCSV>
			</StyledUpload>
		);
	};

	const renderUploadCreator = () => {
		if (typeCreator !== CREATOR_TYPE_SETTING.ALL) {
			return renderUpload();
		}
		return undefined;
	};

	const renderSubContentCreators = () => {
		let result: ReactNode = '';
		if (typeCreator === CREATOR_TYPE_SETTING.INCLUDE) {
			result = t('social_aggregator.sub_form.sub_creator');
		}
		if (typeCreator === CREATOR_TYPE_SETTING.EXCLUDE) {
			result = (
				<Trans
					t={t}
					values={{}}
					i18nKey="social_aggregator.sub_form.sub_exclude"
					components={{
						elm: <b />,
					}}
				/>
			);
		}
		return result;
	};

	const listAction: ActionsTypes[] = [
		{
			id: OPERATOR.OR,
			click: () => setTypeIncluded(OPERATOR.OR),
			name: t('social_aggregator.at_least_one'),
		},
		{
			id: OPERATOR.AND,
			click: () => setTypeIncluded(OPERATOR.AND),
			name: t('social_aggregator.all'),
		},
	];

	const menuMoreActionOrAll = (
		<Menu>
			{listAction?.map((item: ActionsTypes) => (
				<Menu.Item
					className={item.id === typeIncluded ? 'active_menu_item' : ''}
					onClick={item.click}
					key={item?.id}>
					{item?.name}
				</Menu.Item>
			))}
		</Menu>
	);

	const arrField = [
		{
			id: 1,
			label: t('social_aggregator.search_terms'),
			content: (
				<StyledInput
					placeholder={t('social_aggregator.placeholder.enter_hashtag_mention')}
				/>
			),
			subContent: t('social_aggregator.sub_form.sub_search_terms'),
			name: NAME_SETTING_FEED.SEARCH_TERMS,
			rules: [
				{
					required: true,
					message: t('validate.required'),
				},
				() => ({
					validator: (_: any, value: string) => {
						const valid = !value || handleCheckRegexSearchTerms(value);

						if (!valid) {
							return Promise.reject(t('validate.validate_search_terms'));
						}
						return Promise.resolve();
					},
				}),
			],
			isHidden: isFilterComponent,
		},
		{
			id: 2,
			label: t('social_aggregator.sources'),
			content: (
				<CheckboxGroup
					list={LIST_SOURCE.map((item: OptionTypeItem) => {
						if (
							(item.value === SOURCE_TYPE.TIKTOK && !tiktokSearchEnabled) ||
							(item.value === SOURCE_TYPE.INSTAGRAM && !instagramSearchEnabled) ||
							(item.value === SOURCE_TYPE.YOUTUBE && !youtubeSearchEnabled) ||
							((!tiktokSearchEnabled ||
								!instagramSearchEnabled ||
								!youtubeSearchEnabled) &&
								item.value === SOURCE_TYPE.ALL) ||
							(isFilterComponent &&
								detailFeed &&
								!isAllSources(detailFeed.sources) &&
								!detailFeed.sources.includes(item.value))
						) {
							return { ...item, name: t(item.name), disabled: true };
						}
						return { ...item, name: t(item.name) };
					})}
					tooltipDisabled={isFilterComponent ? '' : t('validate.disabled_feed_checkbox')}
					disabled={!hasTerms}
					checkboxDisabled={
						disabledCheck && disabledCheck.includes(NAME_SETTING_FEED.SOURCES)
							? form.getFieldValue(NAME_SETTING_FEED.SOURCES)[0]
							: null
					}
					otherCheckbox={{
						fontSize: '12px',
					}}
				/>
			),
			subContent: t('social_aggregator.sub_form.sub_source'),
			name: NAME_SETTING_FEED.SOURCES,
		},
		{
			id: 2.1,
			label: t('social_search.filter.date_range'),
			content: <StyledDatePicker format={TYPE_FORMAT_DATE.MONTH_DAY} />,
			subContent: t('social_aggregator.sub_form.sub_search_terms'),
			name: NAME_SETTING_FEED.POST_DATE,
			isHidden: !isFilterComponent,
		},
		{
			id: 3,
			label: t('social_aggregator.media_type'),
			content: (
				<RadioGroup
					list={LIST_MEDIA_TYPE.map((item) => ({ ...item, name: t(item.name) }))}
					disabled={!hasTerms}
				/>
			),
			subContent: t('social_aggregator.sub_form.sub_media_type'),
			name: NAME_SETTING_FEED.MEDIA_TYPE,
		},
		{
			id: 4,
			label: (
				<StyledLabel
					className="align_center"
					lineHeight="1"
					margin={
						isFilterComponent && typeIncluded !== OPERATOR.AND
							? '0 0 6px 0'
							: '0 0 -6px 0'
					}>
					<Trans
						t={t}
						values={{ text: t('social_aggregator.must_contain') }}
						i18nKey="social_aggregator.posts_words"
						components={{
							elm: <b style={{ margin: '0 4px' }} />,
						}}
					/>

					<StyledDropdown
						className="dropdown_album dropdown_focus dropdown_action"
						placement="bottomLeft"
						overlayClassName="overlay_album"
						overlay={menuMoreActionOrAll}
						getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
						margin="0 4px"
						trigger={['click']}>
						<StyledIcon>
							<Button style={{ display: 'inline-flex' }}>
								{typeIncluded === OPERATOR.AND
									? t('social_aggregator.all')
									: t('social_aggregator.at_least_one')}
								<IconExpandMore style={{ marginLeft: '4px' }} />
							</Button>
						</StyledIcon>
					</StyledDropdown>
					{t('social_aggregator.of_these_words')}
				</StyledLabel>
			),
			content: (
				<SelectForm
					showArrow={false}
					disabled={!hasTerms}
					mode="tags"
					open={false}
					options={[]}
					placeholder={t('filter.filter_content.any')}
				/>
			),
			subContent: (
				<>
					{t('social_aggregator.sub_form.sub_included_terms_1')}
					<br /> <br />
					{t('social_aggregator.sub_form.sub_included_terms_2')}
				</>
			),
			title: t('social_aggregator.included_terms'),
			name: NAME_SETTING_FEED.INCLUDED_TERMS,
			classForm: 'unset_height',
		},
		{
			id: 5,
			label: (
				<StyledWrapperContent fontSize="14px">
					<Trans
						t={t}
						values={{ text: t('social_aggregator.exclude') }}
						i18nKey="social_aggregator.exclude_posts"
						components={{
							elm: <b />,
						}}
					/>
				</StyledWrapperContent>
			),
			content: (
				<SelectForm
					margin="2px 0 0 0"
					showArrow={false}
					disabled={!hasTerms}
					mode="tags"
					open={false}
					options={[]}
					placeholder={t('filter.filter_content.any')}
				/>
			),
			subContent: t('social_aggregator.sub_form.sub_excluded_terms'),
			title: (
				<StyledLabel className="size_16">
					{t('social_aggregator.excluded_terms')}
				</StyledLabel>
			),
			name: NAME_SETTING_FEED.EXCLUDE_TERMS,
		},
		{
			id: 8,
			title: t('filter.filter_content.creators'),
			subForm: (
				<StyledFormItem name={NAME_SETTING_FEED.USERNAME_CONJUNCTION_TYPE}>
					<RadioGroup
						direction="vertical"
						list={LIST_OPTION_CREATOR.map((item) => ({
							...item,
							name: t(item.name),
						}))}
						disabled={!hasTerms}
						gapSpace={isFilterComponent ? '4px' : '8px'}
					/>
				</StyledFormItem>
			),
			content:
				typeCreator !== CREATOR_TYPE_SETTING.ALL ? (
					<SelectForm
						margin={isFilterComponent ? '-10px 0 0 0' : 'unset'}
						showArrow={false}
						tagRender={(item: any) => {
							return (
								<StyledWrapperTagItem key={item.value}>
									<StyledTagItem>{item.label}</StyledTagItem>
									<StyledIcon
										className="icon_hidden"
										size={16}
										onClick={() => item.onClose()}
										style={{
											position: 'absolute',
											top: '-4px',
											right: '-4px',
										}}>
										<IconCancel />
									</StyledIcon>
								</StyledWrapperTagItem>
							);
						}}
						open={false}
						disabled={!hasTerms}
						mode="tags"
						className="h_item_28"
						options={[]}
						placeholder={t('filter.filter_content.any')}
					/>
				) : undefined,
			rules: [
				() => ({
					validator: (_: any, value: string) => {
						if (value.length > 0) {
							message.destroy();

							const regex = PATTERNS.USERNAME;
							const checkedValue = value[value.length - 1];
							if (!regex.test(checkedValue)) {
								message.error(t('validation.username_invalid'));
								form.setFieldsValue({
									[NAME_SETTING_FEED.CREATORS]: value.slice(0, value.length - 1),
								});
								return Promise.reject();
							}
							return Promise.resolve();
						}

						return Promise.resolve();
					},
				}),
			],
			subContent: renderSubContentCreators(),
			uploadContent: renderUploadCreator(),
			name: NAME_SETTING_FEED.CREATORS,
		},
		{
			id: 6,
			label: t('social_aggregator.caption_sentiment'),
			content: (
				<CheckboxGroup
					list={LIST_CAPTION_SENTIMENT.map((item) => {
						return {
							...item,
							name: t(item.name),
							disabled:
								!sentimentAnalysisEnabled ||
								item.value === CAPTION_SENTIMENT_TYPE.UNDETERMINED,
						};
					})}
					tooltipDisabled={
						sentimentAnalysisEnabled ? '' : t('validate.disabled_sentiment')
					}
					disabled={!hasTerms}
					checkboxDisabled={
						disabledCheck && disabledCheck.includes(NAME_SETTING_FEED.CAPTION_SENTIMENT)
							? form.getFieldValue(NAME_SETTING_FEED.CAPTION_SENTIMENT)[0]
							: null
					}
				/>
			),
			subContent: (
				<>
					{t('social_aggregator.sub_form.sub_caption_sentiment_1')}
					<br /> <br />
					{t('social_aggregator.sub_form.sub_caption_sentiment_2')}
				</>
			),
			name: NAME_SETTING_FEED.CAPTION_SENTIMENT,
		},
		{
			id: 7,
			label: t('social_aggregator.languages'),
			content: (
				<SelectForm
					disabled={!hasTerms}
					mode="multiple"
					options={languageAmazon.map((item) => ({ label: item.name, value: item.code }))}
					placeholder={t('filter.filter_content.any')}
				/>
			),
			subContent: (
				<>
					{t('social_aggregator.sub_form.sub_language_1')}
					<br /> <br />
					{t('social_aggregator.sub_form.sub_language_2')}
				</>
			),
			name: NAME_SETTING_FEED.LANGUAGE,
		},
		{
			id: 7.1,
			label: '',
			content: (
				<StyledCheckbox disabled={!hasTerms} borderColorDisabled={THEME.colors.darkBlue1}>
					{t('social_aggregator.sub_form.include_posts_not_analyzed')}
				</StyledCheckbox>
			),
			name: NAME_SETTING_FEED.INCLUDE_POSTS_NOT_ANALYZED,
			formItemProps: {
				valuePropName: 'checked',
			},
		},
	];

	const handleChangeCheckboxGroup = (
		val: string[],
		key: string,
		option: string[],
		setOption: (val: string[]) => void,
		maxValue: number,
	) => {
		let arrVal: string[] = DEFAULT_FORM_SETTING[key];

		if (option.includes(OPTION_ALL)) {
			const newList = val.filter((item: string) => item !== OPTION_ALL);
			arrVal = newList;
		} else {
			if (!val.includes(OPTION_ALL) && val.length < maxValue) {
				arrVal = val;
			}
		}

		setOption(arrVal);
		form.setFieldsValue({
			[key]: arrVal,
		});
		return arrVal.filter((item: string) => item !== OPTION_ALL);
	};

	const onValuesChange = (val: any, obj: any) => {
		const key = Object.keys(val)[0];

		if (key === NAME_SETTING_FEED.SEARCH_TERMS) {
			const valid = handleCheckRegexSearchTerms(val[key]);
			if (hasTerms !== valid) {
				setHasTerms(valid);
			}
		}

		if (key === NAME_SETTING_FEED.SOURCES || key === NAME_SETTING_FEED.CAPTION_SENTIMENT) {
			if (disabledCheck && val[key].length > 1 && disabledCheck.includes(key)) {
				setDisabledCheck(disabledCheck.filter((item: any) => item !== key));
			}
			if (
				val[key].length === 1 &&
				disabledCheck &&
				!disabledCheck.includes(key) &&
				val[key][0] !== OPTION_ALL
			) {
				setDisabledCheck([...disabledCheck, key]);
			}
		}

		const newObj = {
			...obj,
			[NAME_SETTING_FEED.SOURCES]: obj[NAME_SETTING_FEED.SOURCES].filter(
				(item: string) => item !== OPTION_ALL,
			),
			[NAME_SETTING_FEED.CAPTION_SENTIMENT]: obj[NAME_SETTING_FEED.CAPTION_SENTIMENT].filter(
				(item: string) => item !== OPTION_ALL,
			),
		};

		if (key === NAME_SETTING_FEED.SOURCES) {
			newObj[key] = handleChangeCheckboxGroup(
				val[key],
				key,
				listSourceType,
				setListSourceType,
				LIST_SOURCE.length - 1,
			);
		}

		if (key === NAME_SETTING_FEED.CAPTION_SENTIMENT) {
			newObj[key] = handleChangeCheckboxGroup(
				val[key],
				key,
				listCaptionType,
				setListCaptionType,
				LIST_CAPTION_SENTIMENT.length - 1,
			);
		}

		if (key === NAME_SETTING_FEED.USERNAME_CONJUNCTION_TYPE) {
			setTypeCreator(val[key]);
			if (detailFeed) {
				const { includedUsernames, excludedUsernames } = detailFeed;
				if (val[key] !== CREATOR_TYPE_SETTING.ALL) {
					const creators =
						val[key] === CREATOR_TYPE_SETTING.INCLUDE
							? convertSocialUsername(includedUsernames)
							: convertSocialUsername(excludedUsernames);

					form.setFieldsValue({
						[NAME_SETTING_FEED.CREATORS]: creators,
					});
					newObj[NAME_SETTING_FEED.CREATORS] = creators;
				} else {
					form.setFieldsValue({
						[NAME_SETTING_FEED.CREATORS]: [],
					});
					newObj[NAME_SETTING_FEED.CREATORS] = [];
				}
			}
		}

		if (detailFeed) {
			const {
				captionSentiments,
				excludedKeywords,
				hashtags,
				includedTerms,
				languages,
				mediaType,
				mentionUsernames,
				excludedUsernames,
				includedUsernames,
				usernameConjunctionType,
				sources,
			} = detailFeed;
			const searchTerms =
				hashtags?.length > 0
					? `${SPECIAL_CHARS.HASH}${hashtags[0]}`
					: `${SPECIAL_CHARS.AT}${mentionUsernames[0]}`;

			const otherObj: any = { creators: [] };
			if (usernameConjunctionType === CREATOR_TYPE_SETTING.EXCLUDE) {
				otherObj.creators = convertSocialUsername(excludedUsernames);
			}
			if (usernameConjunctionType === CREATOR_TYPE_SETTING.INCLUDE) {
				otherObj.creators = convertSocialUsername(includedUsernames);
			}
			const defaultFeed = {
				captionSentiments,
				excludedKeywords,
				languages,
				includedTerms,
				mediaType,
				sources,
				searchTerms,
				usernameConjunctionType,
				...otherObj,
			};
			const statusCompare = !isEqual(defaultFeed, newObj);

			if (setHasChange) {
				setHasChange(statusCompare);
			}
		}
	};

	const handleRedirectFeedConfig = () => {
		const state: any = {};
		if (detailFeed) {
			state.feedId = detailFeed.id;
		} else {
			state.openSetting = true;
		}
		history.push({
			pathname: ROUTE_PATH.SETTINGS_SOCIAL_AGGREGATOR,
			state,
		});
	};

	const renderSourcesInfo = () => {
		const propsMessage: any = {
			values: {},
			i18nKey: 'social_search.filter.widen_your_search',
			components: {
				updateFeed: (
					<StyledLink
						display="inline"
						fontWeight="bold"
						underline={true}
						onClick={() => handleRedirectFeedConfig()}
					/>
				),
			},
		};

		return (
			<StyledWrapperContent>
				<Trans t={t} {...propsMessage} />
			</StyledWrapperContent>
		);
	};

	return (
		<StyledWrapperContent
			overflow="hidden auto"
			padding="0"
			height="calc(100% - 75px)"
			ref={refScroll}
			className="custom_scroll_bar">
			<Form
				initialValues={{ ...DEFAULT_FORM_SETTING }}
				form={form}
				onValuesChange={onValuesChange}
				onFinish={handleFinishForm}>
				{arrField.map((item, idx) => {
					let padding =
						item.name === NAME_SETTING_FEED.CAPTION_SENTIMENT ||
						item.name === NAME_SETTING_FEED.SOURCES
							? '20px 6px 8px 0'
							: '20px 6px 20px 0';
					let margin =
						isFilterComponent && item.name === NAME_SETTING_FEED.CREATORS
							? '-16px 0 0 0'
							: 'unset';

					if (isFilterComponent) {
						padding = '6px 6px 16px 0';
					}

					if (item.name === NAME_SETTING_FEED.INCLUDE_POSTS_NOT_ANALYZED) {
						margin = !isFilterComponent ? '-54px 0 0 0' : '-32px 0 0 0';
					}

					return (
						!item?.isHidden && (
							<StyledWrapperContent
								style={
									idx !== 0 &&
									!isFilterComponent &&
									item.name !== NAME_SETTING_FEED.INCLUDE_POSTS_NOT_ANALYZED
										? { borderTop: `1px solid ${THEME.colors.darkBlue3}` }
										: {}
								}
								padding={padding}
								key={item.id}>
								<Row gutter={[20, 20]}>
									<Col span={isFilterComponent ? 24 : 12}>
										{item.title && (
											<StyledTitle fontSize="16px" className="unset_height">
												{item.title}
											</StyledTitle>
										)}
										{item?.subForm && (
											<StyledWrapperContent margin="8px 0">
												{item.subForm}
											</StyledWrapperContent>
										)}
										{item?.content && (
											<StyledFormItem
												margin={margin}
												rules={item.rules}
												name={item.name}
												className={item.classForm || ''}
												label={
													<StyledLabel className="size_16">
														{item?.label}
													</StyledLabel>
												}
												{...item?.formItemProps}>
												{item.content}
											</StyledFormItem>
										)}
									</Col>
									{item?.subContent && !isFilterComponent && (
										<Col span={12}>
											<StyledBoxDesc
												className="height_full"
												padding="4px 8px"
												background={THEME.colors.darkBlue3}
												borderRadius="2px"
												boxShadow="0px 4px 8px rgba(2, 12, 21, 0.2)"
												height={
													item?.uploadContent
														? 'calc(100% - 45px)'
														: '100%'
												}>
												<StyledDescText
													margin="0"
													display="inline"
													color={THEME.colors.gray5}
													fontSize="12px"
													lineHeight="16px">
													{item.subContent}
												</StyledDescText>
											</StyledBoxDesc>

											{item?.uploadContent && (
												<StyledWrapperContent margin="17px 0 0 0">
													{item.uploadContent}
												</StyledWrapperContent>
											)}
										</Col>
									)}
								</Row>
								{item.name === NAME_SETTING_FEED.SOURCES &&
									isFilterComponent &&
									detailFeed &&
									!isAllSources(detailFeed.sources) && (
										<StyledSourcesInfo>
											<StyledIcon
												margin="0 8px 0 0"
												size={22}
												fillPath={THEME.colors.blueBase}>
												<IconInfo />
											</StyledIcon>
											{renderSourcesInfo()}
										</StyledSourcesInfo>
									)}
							</StyledWrapperContent>
						)
					);
				})}
			</Form>
		</StyledWrapperContent>
	);
});

export default FormDetailFeed;
