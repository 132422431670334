/**
 * @prettier
 */

import { Action, handleActions } from 'redux-actions';
import {
	fetchSocialContentTypes,
	blockSocialContentTypes,
	blockSocialUsernameTypes,
	getSocialSearchFilterTypes,
	saveSocialSearchFilterTypes,
	deleteSocialSearchFilterTypes,
	resetSocialSearchStateTypes,
	importSocialContentTypes,
	saveFacebookTokenTypes,
	getKeywordTypes,
	saveKeywordTypes,
	typeUpdateStatusListSocial,
	getUrlPreviewTikTokTypes,
	saveFeedDetailTypes,
	contentFeedTypes,
	fetchMetricFeedTypes,
	fetchSocialPostTypes,
	fetchRecentSocialPostTypes,
} from '@stores/socialsearch/socialsearch.types';
import { DEFAULT_ERROR } from '@constants/errors';
import { STATUSCODE } from '@constants/APIs';
import { IN_PROGRESS, SUCCEEDED, FAILED, NONE } from '@constants/status';
import {
	PreviewUrlTikTok,
	SocialContentPayloadDto,
	SocialContentStoreDto,
} from '@models/socialsearch/store';
import { message } from 'antd';
import _ from 'lodash';
import { FeedDto } from '@models/settings/socialSearch/socialAggregator/summary';
import { DEFAULT_MEDIA } from '@constants/social_search';
import { NAME_SETTING_FEED } from '@constants/settings/socialAggregator';

const initialState: SocialContentStoreDto = {
	fetchingSocialContent: false,
	socialContent: {
		...DEFAULT_MEDIA,
	},
	socialPost: {
		...DEFAULT_MEDIA,
	},
	filterList: [],
	blockedSocialContent: [],
	blockedSocialUsername: [],
	importedSocialContent: [],
	importSocialContentStatus: NONE,
	blockSocialContentStatus: NONE,
	blockSocialUsernameStatus: NONE,
	statusUpdateSocialList: false,
	fetchingUrlPreviewTikTok: false,
	previewList: [],
	detailFeed: null,
	metricFeed: null,
	loadingMetricFeed: false,
	error: null,
	fetchingSocialPost: false,
	fetchRecentSocialPostStatus: NONE,
};

const fetchSocialContentRequest = (state: SocialContentStoreDto) => ({
	...state,
	fetchingSocialContent: true,
});

const fetchSocialContentSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: socialContent = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingSocialContent: false,
			socialContent,
			error: null,
		};
	}

	return {
		...state,
		fetchingSocialContent: false,
		socialContent: null,
		error: status,
	};
};

const fetchSocialContentFailed = (
	state: SocialContentStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			fetchingSocialContent: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingSocialContent: false,
	};
};

// fetch url preview tiktok

const fetchUrlPreviewTikTokRequest = (state: SocialContentStoreDto) => ({
	...state,
	fetchingUrlPreviewTikTok: true,
});

const fetchUrlPreviewTikTokSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: url = '',
	} = payload.data;
	const { id = 0 } = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingUrlPreviewTikTok: false,
			previewList: [...state.previewList, { id, url }],
			error: null,
		};
	}

	return {
		...state,
		fetchingUrlPreviewTikTok: false,
		error: status,
	};
};

const fetchUrlPreviewTikTokFailed = (
	state: SocialContentStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			fetchingUrlPreviewTikTok: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingUrlPreviewTikTok: false,
	};
};

// save url preview tiktok
const saveUrlPreviewTikTok = (
	state: SocialContentStoreDto,
	{ payload }: Action<PreviewUrlTikTok>,
) => {
	const newList: PreviewUrlTikTok[] = state?.previewList?.filter(
		(item) => item?.id !== payload?.id,
	);
	newList.push(payload);

	return {
		...state,
		previewList: newList,
	};
};

const blockSocialContentRequest = (state: SocialContentStoreDto) => ({
	...state,
	blockSocialContentStatus: IN_PROGRESS,
});

const blockSocialContentSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		ids: blockedSocialContent = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			blockedSocialContent,
			blockSocialContentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		blockSocialContentStatus: FAILED,
		blockedSocialContent,
		error: status,
	};
};

const blockSocialContentFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		blockSocialContentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const blockSocialContentEnd = (state: SocialContentStoreDto) => ({
	...state,
	error: null,
	blockSocialContentStatus: NONE,
	blockedSocialContent: [],
});

const importSocialContentRequest = (state: SocialContentStoreDto) => ({
	...state,
	importSocialContentStatus: IN_PROGRESS,
});

const importSocialContentEnd = (state: SocialContentStoreDto) => ({
	...state,
	importSocialContentStatus: NONE,
	importedSocialContent: [],
});

const importSocialContentSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = 0 },
		ids: importedSocialContent = [],
		error,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			importedSocialContent,
			importSocialContentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		importSocialContentStatus: FAILED,
		importedSocialContent,
		error: `${code} ${status} ${error}`,
	};
};

const importSocialContentFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		importSocialContentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const blockSocialUsernameRequest = (state: SocialContentStoreDto) => ({
	...state,
	blockSocialUsernameStatus: IN_PROGRESS,
});

const blockSocialUsernameSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		usernames: blockedSocialUsername = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		let { blockedSocialUsername } = state;
		blockedSocialUsername = payload.data?.usernames ? payload.data.usernames : [];
		return {
			...state,
			blockedSocialUsername,
			blockSocialUsernameStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		blockedSocialUsername,
		blockSocialUsernameStatus: FAILED,
		error: status,
	};
};

const blockSocialUsernameFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		blockSocialUsernameStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const blockSocialUsernameEnd = (state: SocialContentStoreDto) => ({
	...state,
	error: null,
	blockedSocialUsername: [],
	blockSocialUsernameStatus: NONE,
});

const updateStatusSocialList = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => ({
	...state,
	statusUpdateSocialList: payload,
});

const getSoicalSearchFilterRequest = (state: SocialContentStoreDto) => ({
	...state,
	actionFilterStatus: IN_PROGRESS,
});

const getSocialSearchFilterSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: filterList = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			filterList,
			actionFilterStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		actionFilterStatus: FAILED,
		filterList: null,
		error: status,
	};
};

const getSocialSearchFilterFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		actionFilterStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const saveSocialSearchFilterRequest = (state: SocialContentStoreDto) => ({
	...state,
	actionFilterStatus: IN_PROGRESS,
});

const saveSocialSearchFilterSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: savedFilter = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const { filterList } = state;
		if (filterList) {
			filterList.reverse().push(savedFilter);
			filterList.reverse();
		}

		return {
			...state,
			savedFilter,
			filterList,
			actionFilterStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		actionFilterStatus: FAILED,
		savedFilter: null,
		error: status,
	};
};

const saveSocialSearchFilterFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		actionFilterStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const saveSocialSearchFilterEnd = (state: SocialContentStoreDto) => ({
	...state,
	actionFilterStatus: NONE,
	savedFilter: {},
});

const deleteSocialSearchFilterRequest = (state: SocialContentStoreDto) => ({
	...state,
	actionFilterStatus: IN_PROGRESS,
});

const deleteSocialSearchFilterSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: deletedFilter = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		let { filterList } = state;

		if (filterList && deletedFilter !== null) {
			filterList = filterList.filter((item) => {
				return item.id !== deletedFilter[0];
			});
		}

		return {
			...state,
			filterList,
			deletedFilter,
			actionFilterStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		actionFilterStatus: FAILED,
		error: status,
	};
};

const deleteSocialSearchFilterFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		actionFilterStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const deleteSocialSearchFilterEnd = (state: SocialContentStoreDto) => ({
	...state,
	actionFilterStatus: NONE,
	deletedFilter: [],
});

const saveFacebookTokenRequest = (state: SocialContentStoreDto) => ({
	...state,
	saveFBTokenStatus: IN_PROGRESS,
});

const saveFacebookTokenSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: savedClientInfo = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			savedClientInfo,
			saveFBTokenStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		saveFBTokenStatus: FAILED,
		error: status,
	};
};

const saveFacebookTokenFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		saveFBTokenStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;
		message.error(error);
	}

	return result;
};

const getKeywordRequest = (state: SocialContentStoreDto) => ({
	...state,
	getKeywordStatus: IN_PROGRESS,
});

const getKeywordSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			keywordList: result,
			getKeywordStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		getKeywordStatus: FAILED,
		error: status,
	};
};

const getKeywordFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	if (payload) {
		if ('data' in payload && !_.isEmpty(payload.data)) {
			const { status, error = '' } = payload.data;

			return {
				...state,
				getKeywordStatus: FAILED,
				error: error === null ? `${status.code} ${status.status}` : error,
			};
		}
	}

	return {
		...state,
		getKeywordStatus: FAILED,
	};
};

const getKeywordEnd = (state: SocialContentStoreDto) => {
	return {
		...state,
		error: null,
		keywordList: undefined,
		getKeywordStatus: NONE,
	};
};

const saveKeywordRequest = (state: SocialContentStoreDto) => ({
	...state,
	saveKeywordStatus: IN_PROGRESS,
});

const saveKeywordSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			saveKeywordStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		saveKeywordStatus: FAILED,
		error: status,
	};
};

const saveKeywordFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	if (payload) {
		const { status, error = '' } = payload.data;

		return {
			...state,
			saveKeywordStatus: FAILED,
			error: error === null ? `${status.code} ${status.status}` : error,
		};
	}

	return {
		...state,
		saveKeywordStatus: FAILED,
	};
};

const saveKeywordEnd = (state: SocialContentStoreDto) => {
	return {
		...state,
		error: null,
		saveKeywordStatus: NONE,
	};
};

const resetSocialSearchStateRequest = (state: SocialContentStoreDto) => ({
	...initialState,
});

const resetSocialSearchField = (state: SocialContentStoreDto, { payload }: Action<any>) => ({
	...state,
	...payload,
});

const saveFeedDetail = (state: SocialContentStoreDto, { payload }: Action<FeedDto | null>) => {
	if (payload && !(NAME_SETTING_FEED.INCLUDE_POSTS_NOT_ANALYZED in payload)) {
		payload.analysisLanguage = true;
	}

	return {
		...state,
		detailFeed: payload,
	};
};

// fetch metric feed
const getMetricFeedRequest = (state: SocialContentStoreDto) => ({
	...state,
	loadingMetricFeed: true,
	metricFeed: null,
});

const getMetricFeedSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: metricFeed = null,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			metricFeed,
			loadingMetricFeed: false,
		};
	}

	return {
		...state,
		loadingMetricFeed: false,
		error: status,
	};
};

const getMetricFeedFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		loadingMetricFeed: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

// Fetch Social Post
const fetchSocialPostRequest = (state: SocialContentStoreDto) => ({
	...state,
	fetchingSocialPost: true,
});

const fetchSocialPostSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: socialPost = { ...DEFAULT_MEDIA },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialPost,
			fetchingSocialPost: false,
		};
	}

	return {
		...state,
		fetchingSocialPost: false,
		socialPost: null,
		error: status,
	};
};

const fetchSocialPostFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		fetchingSocialPost: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const fetchSocialPostEnd = (state: SocialContentStoreDto) => ({
	...state,
	fetchingSocialPost: false,
	socialPost: { ...DEFAULT_MEDIA },
});

const fetchRecentSocialPostRequest = (state: SocialContentStoreDto) => ({
	...state,
	fetchRecentSocialPostStatus: IN_PROGRESS,
});

const fetchRecentSocialPostSucceeded = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: recentSocialPostResult,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchRecentSocialPostStatus: SUCCEEDED,
			recentSocialPostResult,
		};
	}

	return {
		...state,
		fetchRecentSocialPostStatus: FAILED,
		error: status,
	};
};

const fetchRecentSocialPostFailed = (
	state: SocialContentStoreDto,
	{ payload }: Action<SocialContentPayloadDto>,
) => {
	const result = {
		...state,
		fetchRecentSocialPostStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const fetchRecentSocialPostEnd = (state: SocialContentStoreDto) => ({
	...state,
	fetchRecentSocialPostStatus: NONE,
	recentSocialPostResult: null,
});

const socialSearchReducers = handleActions<any>(
	{
		[fetchSocialContentTypes.FETCH_SOCIAL_CONTENT_REQUEST]: fetchSocialContentRequest,
		[fetchSocialContentTypes.FETCH_SOCIAL_CONTENT_SUCCEEDED]: fetchSocialContentSucceeded,
		[fetchSocialContentTypes.FETCH_SOCIAL_CONTENT_FAILED]: fetchSocialContentFailed,

		[getUrlPreviewTikTokTypes.GET_URL_PREVIEW_TIKTOK_REQUEST]: fetchUrlPreviewTikTokRequest,
		[getUrlPreviewTikTokTypes.GET_URL_PREVIEW_TIKTOK_SUCCEEDED]: fetchUrlPreviewTikTokSucceeded,
		[getUrlPreviewTikTokTypes.GET_URL_PREVIEW_TIKTOK_FAILED]: fetchUrlPreviewTikTokFailed,
		[getUrlPreviewTikTokTypes.SAVE_URL_PREVIEW_TIKTOK]: saveUrlPreviewTikTok,

		[importSocialContentTypes.IMPORT_SOCIAL_CONTENT_REQUEST]: importSocialContentRequest,
		[importSocialContentTypes.IMPORT_SOCIAL_FEED_CONTENT_REQUEST]: importSocialContentRequest,
		[importSocialContentTypes.IMPORT_SOCIAL_CONTENT_SUCCEEDED]: importSocialContentSucceeded,
		[importSocialContentTypes.IMPORT_SOCIAL_CONTENT_FAILED]: importSocialContentFailed,
		[importSocialContentTypes.IMPORT_SOCIAL_CONTENT_END]: importSocialContentEnd,

		[blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_REQUEST]: blockSocialContentRequest,
		[blockSocialContentTypes.BLOCK_SOCIAL_FEED_CONTENT_REQUEST]: blockSocialContentRequest,
		[blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_SUCCEEDED]: blockSocialContentSucceeded,
		[blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_FAILED]: blockSocialContentFailed,
		[blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_END]: blockSocialContentEnd,

		[blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_REQUEST]: blockSocialUsernameRequest,
		[blockSocialUsernameTypes.BLOCK_SOCIAL_FEED_USERNAME_REQUEST]: blockSocialUsernameRequest,
		[blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_SUCCEEDED]: blockSocialUsernameSucceeded,
		[blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_FAILED]: blockSocialUsernameFailed,
		[blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_END]: blockSocialUsernameEnd,

		[typeUpdateStatusListSocial.UPDATE_STATUS_SOCIAL_LIST]: updateStatusSocialList,

		[getSocialSearchFilterTypes.GET_SOCIAL_SEARCH_FILTER_REQUEST]: getSoicalSearchFilterRequest,
		[getSocialSearchFilterTypes.GET_SOCIAL_SEARCH_FILTER_SUCCEEDED]:
			getSocialSearchFilterSucceeded,
		[getSocialSearchFilterTypes.GET_SOCIAL_SEARCH_FILTER_FAILED]: getSocialSearchFilterFailed,

		[saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_REQUEST]:
			saveSocialSearchFilterRequest,
		[saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_SUCCEEDED]:
			saveSocialSearchFilterSucceeded,
		[saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_FAILED]:
			saveSocialSearchFilterFailed,
		[saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_END]: saveSocialSearchFilterEnd,

		[deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_REQUEST]:
			deleteSocialSearchFilterRequest,
		[deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_SUCCEEDED]:
			deleteSocialSearchFilterSucceeded,
		[deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_FAILED]:
			deleteSocialSearchFilterFailed,
		[deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_END]:
			deleteSocialSearchFilterEnd,

		[saveFacebookTokenTypes.SAVE_FACEBOOK_TOKEN_REQUEST]: saveFacebookTokenRequest,
		[saveFacebookTokenTypes.SAVE_FACEBOOK_TOKEN_SUCCEEDED]: saveFacebookTokenSucceeded,
		[saveFacebookTokenTypes.SAVE_FACEBOOK_TOKEN_FAILED]: saveFacebookTokenFailed,

		[getKeywordTypes.GET_KEY_WORD_REQUEST]: getKeywordRequest,
		[getKeywordTypes.GET_KEY_WORD_SUCCEEDED]: getKeywordSucceeded,
		[getKeywordTypes.GET_KEY_WORD_FAILED]: getKeywordFailed,
		[getKeywordTypes.GET_KEY_WORD_END]: getKeywordEnd,

		[saveKeywordTypes.SAVE_KEY_WORD_REQUEST]: saveKeywordRequest,
		[saveKeywordTypes.SAVE_KEY_WORD_SUCCEEDED]: saveKeywordSucceeded,
		[saveKeywordTypes.SAVE_KEY_WORD_FAILED]: saveKeywordFailed,
		[saveKeywordTypes.SAVE_KEY_WORD_END]: saveKeywordEnd,

		[resetSocialSearchStateTypes.RESET_SOCIAL_SEARCH_STATE_REQUEST]:
			resetSocialSearchStateRequest,
		[resetSocialSearchStateTypes.RESET_SOCIAL_SEARCH_FIELD]: resetSocialSearchField,
		[saveFeedDetailTypes.SAVE_FEED_DETAIL]: saveFeedDetail,

		[contentFeedTypes.FETCH_CONTENT_FEED_REQUEST]: fetchSocialContentRequest,

		[fetchMetricFeedTypes.FETCH_METRIC_FEED_REQUEST]: getMetricFeedRequest,
		[fetchMetricFeedTypes.FETCH_METRIC_FEED_SUCCEEDED]: getMetricFeedSucceeded,
		[fetchMetricFeedTypes.FETCH_METRIC_FEED_FAILED]: getMetricFeedFailed,

		[fetchSocialPostTypes.FETCH_SOCIAL_POST_REQUEST]: fetchSocialPostRequest,
		[fetchSocialPostTypes.FETCH_SOCIAL_POST_SUCCEEDED]: fetchSocialPostSucceeded,
		[fetchSocialPostTypes.FETCH_SOCIAL_POST_FAILED]: fetchSocialPostFailed,
		[fetchSocialPostTypes.FETCH_SOCIAL_POST_END]: fetchSocialPostEnd,

		[fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_REQUEST]: fetchRecentSocialPostRequest,
		[fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_SUCCEEDED]:
			fetchRecentSocialPostSucceeded,
		[fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_FAILED]: fetchRecentSocialPostFailed,
		[fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_END]: fetchRecentSocialPostEnd,
	},
	initialState,
);

export default socialSearchReducers;
