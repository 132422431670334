import { StyledFormItem, StyledSelect } from '@styled/Common/CommonStyled';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

type TagsInputProps = {
	form: any;
	fieldName: string;
	isRequired: boolean;
	regex: RegExp;
	maxTags?: number;
	errorMessage: {
		invalidValue: string;
		exceedMaxTags?: string;
		field: string;
	};
	placeholder: string;
	selectProps?: any;
};

const TagsInput = (props: TagsInputProps) => {
	const {
		form,
		fieldName,
		isRequired,
		regex,
		maxTags,
		errorMessage,
		placeholder = '',
		selectProps = {},
	} = props;
	const { t } = useTranslation();

	const setFieldValue = (field: string, value: any) => {
		form.setFieldsValue({
			[field]: value,
		});
	};

	return (
		<StyledFormItem
			name={fieldName}
			rules={[
				{
					required: isRequired,
					message: t('validation.required', {
						field: errorMessage.field,
					}),
				},
				() => ({
					validator(_: any, value: string) {
						message.destroy();
						const isValid = maxTags ? value?.length <= maxTags : true;
						if (value?.length > 0 && isValid) {
							const checkedValue = value[value.length - 1];
							if (!regex.test(checkedValue)) {
								message.error(errorMessage.invalidValue);

								setFieldValue(fieldName, value.slice(0, value.length - 1));
								return Promise.reject();
							}
						} else if (maxTags && value?.length > maxTags) {
							message.error(errorMessage.exceedMaxTags);
							setFieldValue(fieldName, value.slice(0, value.length - 1));
							return Promise.reject();
						}

						return Promise.resolve();
					},
				}),
			]}>
			<StyledSelect
				mode="tags"
				className="tags_input"
				placeholder={placeholder}
				notFoundContent={null}
				autoFocus
				maxTagCount={2}
				{...selectProps}
			/>
		</StyledFormItem>
	);
};

export default TagsInput;
