import CustomSwitch from '@components/CustomSwitch';
import { MFA_ACTION_TYPES } from '@constants/settings/security';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import { ConfirmModal } from '@cores/Modal';
import { getUser } from '@helpers/userHelpers';
import { disableMFAEnd, disableMFARequest } from '@stores/actions';
import { UserStoreType } from '@stores/user/user.types';
import {
	StyledButton,
	StyledCol,
	StyledDivider,
	StyledFlex,
	StyledRow,
	StyledSection,
	StyledText,
} from '@styled/Common/CommonStyled';
import { StyledSecurityWrapper } from '@styled/Settings/UserProfileStyled';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PairAuthenticatorAppModal from './PairingAuthenticatorAppModal';

const Security = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { confirmMFAPairingStatus, disableMFAStatus }: UserStoreType = useSelector(
		(state: any) => state.user,
	);
	const user = getUser();
	const { mfaEnabled = false } = user || {};
	const [mfaInfo, setMfaInfo] = useState<{
		isChecked: boolean;
		isChanging: boolean;
		isPairedMFA?: boolean;
	}>({
		isChecked: mfaEnabled,
		isChanging: false,
		isPairedMFA: mfaEnabled ? true : false,
	});

	const [actionType, setActionType] = useState<string | null>(null);

	useEffect(() => {
		return () => {
			resetData();
		};
	}, []);

	useEffect(() => {
		if (disableMFAStatus === SUCCEEDED) {
			resetData();
			message.info(t('setting.user_profile.message.disable_authentication_successfully'));
		}
		if (disableMFAStatus === SUCCEEDED || disableMFAStatus === FAILED) {
			dispatch(disableMFAEnd());
		}
	}, [disableMFAStatus]);

	useEffect(() => {
		if (confirmMFAPairingStatus === SUCCEEDED) {
			setMfaInfo({
				...mfaInfo,
				isChanging: false,
				isPairedMFA: true,
			});
		}
	}, [confirmMFAPairingStatus]);

	const resetData = () => {
		onCloseConfirmModal();
		setMfaInfo({
			isChanging: false,
			isChecked: false,
		});
	};

	const disableMFA = () => {
		setMfaInfo((prev) => {
			return { ...prev, isChecked: false, isChanging: false };
		});
		dispatch(disableMFARequest());
	};

	const passwordVerification = () => {
		setActionType(MFA_ACTION_TYPES.PASSWORD_VERIFICATION);
	};

	const onChangeMFAStatus = (isChecked: boolean) => {
		if (!isChecked) {
			passwordVerification();
		} else {
			setMfaInfo((prev) => {
				return { ...prev, isChecked, isChanging: true };
			});
		}
	};

	const onCloseConfirmModal = () => {
		setActionType(null);
	};

	const renderConfirmModal = () => {
		let content: string = '';
		let onSubmit: () => void;
		let okText = t('button.move');
		const isOpen: boolean = actionType === MFA_ACTION_TYPES.DISABLE_MFA;

		if (actionType === MFA_ACTION_TYPES.DISABLE_MFA) {
			content = t('setting.user_profile.message.disabled_confirmation');
			onSubmit = disableMFA;
			okText = t('button.continue');
		}
		const isLoading = disableMFAStatus === IN_PROGRESS;

		return (
			<ConfirmModal
				confirmLoading={isLoading}
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => onCloseConfirmModal()}>
				{content}
			</ConfirmModal>
		);
	};

	return (
		<StyledSecurityWrapper>
			<StyledSection margin="0" padding="24px">
				<StyledFlex justify="flex-start" margin="0 0 8px 0">
					<StyledText fontSize="20px" margin="0 12px 0 0">
						{t('setting.user_profile.two_step_authentication')}
					</StyledText>
					<CustomSwitch checked={mfaInfo.isChecked} setChecked={onChangeMFAStatus} />
				</StyledFlex>
				<StyledText>{t('setting.user_profile.two_step_authentication_desc')}</StyledText>
			</StyledSection>
			<StyledDivider margin="0" />
			{mfaInfo.isPairedMFA && (
				<StyledRow padding="24px" justify="space-between" align="middle">
					<StyledCol>{t('setting.user_profile.method_authenticator_app')}</StyledCol>
					<StyledCol>
						<StyledFlex>
							<StyledButton
								onClick={() => onChangeMFAStatus(true)}
								width="113px"
								margin="0 12px 0 0">
								{t('button.update')}
							</StyledButton>
							<StyledButton onClick={passwordVerification} width="113px">
								{t('button.remove')}
							</StyledButton>
						</StyledFlex>
					</StyledCol>
				</StyledRow>
			)}

			<PairAuthenticatorAppModal
				visible={
					(mfaInfo.isChecked && mfaInfo.isChanging) ||
					actionType === MFA_ACTION_TYPES.PASSWORD_VERIFICATION
				}
				onCancel={() => {
					setMfaInfo((prev) => {
						return { ...prev, isChecked: mfaEnabled, isChanging: false };
					});
					setActionType('');
				}}
				isDisableMFA={actionType === MFA_ACTION_TYPES.PASSWORD_VERIFICATION}
				setActionType={setActionType}
			/>

			{actionType && renderConfirmModal()}
		</StyledSecurityWrapper>
	);
};

export default Security;
