import THEME from '@constants/themes/themes';
import { Modal } from 'antd';
import styled from 'styled-components';

const StyledUpdateListContentModal = styled(Modal)`
	max-width: 1480px;
	.ant-modal-title {
		font-weight: 700;
		font-size: 20px;
		line-height: 27px;
		color: ${THEME.colors.gray5};
	}
	.ant-modal-body {
		padding: 10px 40px 24px 40px;
	}
`;

const StyledVerifyContent = styled.div`
	padding: 12px;
	border-bottom: 2px solid ${THEME.colors.orangeBase};
	display: inline-block;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
`;

const StyledWrapperAction = styled.div`
	display: inline-flex;
	overflow: auto hidden;
	white-space: nowrap;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin: 14px 0;
`;

const StyledAction = styled.div`
	display: inline-flex;
	align-items: center;
	.ant-btn {
		&:hover {
			border-color: ${THEME.colors.orangeBase};
			color: ${THEME.colors.orangeBase};
		}
	}
`;

const StyledWrapperListContent = styled.div`
	height: calc(100vh - 350px);
`;

export {
	StyledAction,
	StyledUpdateListContentModal,
	StyledVerifyContent,
	StyledWrapperAction,
	StyledWrapperListContent,
};
