import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Form, message } from 'antd';

// Components
import { LoadingWrapper, ConfirmModal } from '@cores/index';
import ListItem from '@components/Settings/Content/ListItem';

// Redux
import { updateLabels, updateLabelsEnd } from '@stores/settings/settings.actions';

// Model
import { SettingRootStateDto } from '@models/settings/setting';

// Styled
import {
	StyledWrapper,
	StyledTitle,
	StyledForm,
	StyledWrapperBox,
	StyledListWrapper,
	StyledInput,
	StyledButton,
	StyledFlex,
	StyleSubTitle,
	StyledFormItem,
} from '@styled/Settings/ContentStyled';

// Icons
import { IconAdd } from '@assets/icons';

// Constant
import { IN_PROGRESS, SUCCEEDED, FAILED } from '@constants/status';
import { DELETE, ADD, UPDATE } from '@constants/settings';

const Labels: React.FC = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { loadingLabels, labels = [] } = useSelector(
		(state: SettingRootStateDto) => state.settings.content,
	);
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isRequired, setIsRequired] = useState<boolean>(true);
	const [selectedFlag, setSelectedFlag] = useState<string>('');
	const [actionType, setActionType] = useState<string>('');

	useEffect(() => {
		if (loadingLabels === SUCCEEDED) {
			setIsOpenModal(false);
			form.resetFields();
			if (actionType !== '') {
				message.success(t(`setting.message.${actionType.toLowerCase()}_label_success`));
			}
		}
		if (loadingLabels === FAILED) {
			if (actionType !== '') {
				message.error(t(`setting.message.${actionType.toLowerCase()}_label_failed`));
			} else {
				message.error(t('setting.message.get_label_failed'));
			}
		}
		if (loadingLabels === FAILED || loadingLabels === SUCCEEDED) {
			dispatch(updateLabelsEnd());
		}
	}, [loadingLabels]);

	const onToggleOpenModal = (flag: string) => {
		setSelectedFlag(isOpenModal ? '' : flag);
		setIsOpenModal(!isOpenModal);
	};

	const onSubmitDelete = () => {
		const payload = labels.filter((label: string) => label !== selectedFlag);
		dispatch(updateLabels(payload));
		setActionType(DELETE);
	};

	const onFinish = (value: any) => {
		const payload = [value.label, ...labels];
		dispatch(updateLabels(payload));
		setActionType(ADD);
	};

	const checkDuplicateLabel = (value: string) => {
		const result = labels.some((item: any) => item === value);
		return result;
	};

	const onEditFolderName = (value: string, index: number) => {
		const check = checkDuplicateLabel(value);
		if (check) {
			message.error(t('setting.validation.duplicate_label'));
		} else {
			const cloneLabel = [...labels];
			cloneLabel.splice(index, 1, value);
			dispatch(updateLabels(cloneLabel));
			setActionType(UPDATE);
		}
	};

	const renderConfirmModal = () => {
		return (
			<ConfirmModal
				width={400}
				confirmLoading={loadingLabels === IN_PROGRESS}
				okText={t('button.delete')}
				isOpen={isOpenModal}
				onSubmit={onSubmitDelete}
				onClose={onToggleOpenModal}>
				{t('setting.confirm.delete_label')}
			</ConfirmModal>
		);
	};

	const renderAddForm = () => (
		<StyledForm
			form={form}
			onFinish={onFinish}
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}>
			<StyledFlex>
				<StyledFormItem
					label={
						<StyleSubTitle fontWeight={600}>
							{t('setting.content.add_labels')}
						</StyleSubTitle>
					}
					name="label"
					rules={[
						{
							required: isRequired,
							validateTrigger: 'onsubmit',
							message: t('setting.validation.label_required'),
						},
						() => ({
							validator(_: any, value: string) {
								if (!value || !checkDuplicateLabel(value)) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error(t('setting.validation.duplicate_label')),
								);
							},
						}),
					]}>
					<StyledInput placeholder={t('setting.content.add_label_placeholder')} />
				</StyledFormItem>
				<StyledFormItem width="fit-content">
					<StyledButton
						onClick={() => {
							const isEmpty = form.getFieldValue('label') === undefined;
							if (isEmpty) {
								setIsRequired(true);
								setTimeout(() => {
									setIsRequired(false);
									form.validateFields(['label']);
								}, 2000);
							}
						}}
						htmlType="submit"
						icon={<IconAdd />}
					/>
				</StyledFormItem>
			</StyledFlex>
		</StyledForm>
	);

	const renderLabels = () => {
		return (
			<StyledListWrapper padding="0">
				{labels.map((label: string, index: number) => (
					<ListItem
						index={index}
						onClickAction={onToggleOpenModal}
						onEdit={onEditFolderName}
						key={`${index + 1}`}>
						{label}
					</ListItem>
				))}
			</StyledListWrapper>
		);
	};

	return (
		<StyledWrapper>
			<StyledTitle fontSize="1.143rem" fontWeight="normal">
				{t('setting.content.labels')}
			</StyledTitle>
			<StyledWrapperBox>
				{renderAddForm()}
				<LoadingWrapper isLoading={loadingLabels === IN_PROGRESS}>
					{renderLabels()}
				</LoadingWrapper>
			</StyledWrapperBox>
			{renderConfirmModal()}
		</StyledWrapper>
	);
};

export default Labels;
