const fetchTremendousInfoTypes = {
	FETCH_TREMENDOUS_INFO_REQUEST: 'FETCH_TREMENDOUS_INFO_REQUEST',
	FETCH_TREMENDOUS_INFO_SUCCEEDED: 'FETCH_TREMENDOUS_INFO_SUCCEEDED',
	FETCH_TREMENDOUS_INFO_FAILED: 'FETCH_TREMENDOUS_INFO_FAILED',
};

const saveTremendousDefaultTypes = {
	SAVE_TREMENDOUS_DEFAULT_REQUEST: 'SAVE_TREMENDOUS_DEFAULT_REQUEST',
	SAVE_TREMENDOUS_DEFAULT_SUCCEEDED: 'SAVE_TREMENDOUS_DEFAULT_SUCCEEDED',
	SAVE_TREMENDOUS_DEFAULT_FAILED: 'SAVE_TREMENDOUS_DEFAULT_FAILED',
	SAVE_TREMENDOUS_DEFAULT_END: 'SAVE_TREMENDOUS_DEFAULT_END',
};

const createOrderTremendousTypes = {
	CREATE_ORDER_TREMENDOUS_REQUEST: 'CREATE_ORDER_TREMENDOUS_REQUEST',
	CREATE_ORDER_TREMENDOUS_SUCCEEDED: 'CREATE_ORDER_TREMENDOUS_SUCCEEDED',
	CREATE_ORDER_TREMENDOUS_FAILED: 'CREATE_ORDER_TREMENDOUS_FAILED',
	CREATE_ORDER_TREMENDOUS_END: 'CREATE_ORDER_TREMENDOUS_END',
};

const changeFieldTremendousTypes = {
	CHANGE_TREMENDOUS_FIELD: 'CHANGE_TREMENDOUS_FIELD',
};

export {
	fetchTremendousInfoTypes,
	saveTremendousDefaultTypes,
	createOrderTremendousTypes,
	changeFieldTremendousTypes,
};
