import { createAction } from 'redux-actions';
import {
	createGuestPortalTypes,
	deleteGuestPortalTypes,
	getGuestPortalListTypes,
	shareGuestPortalTypes,
	storeDataGuestPortalTypes,
	updateGuestPortalTypes,
} from './guestportal.types';

// Get guest portal list
export const getGuestPortalListRequest = createAction(
	getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_REQUEST,
);
export const getGuestPortalListSucceeded = createAction(
	getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_SUCCEEDED,
);
export const getGuestPortalListFailed = createAction(
	getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_FAILED,
);
export const getGuestPortalListEnd = createAction(
	getGuestPortalListTypes.GET_GUEST_PORTAL_LIST_END,
);

// Create guest portal
export const createGuestPortalRequest = createAction(
	createGuestPortalTypes.CREATE_GUEST_PORTAL_REQUEST,
);
export const createGuestPortalSucceeded = createAction(
	createGuestPortalTypes.CREATE_GUEST_PORTAL_SUCCEEDED,
);
export const createGuestPortalFailed = createAction(
	createGuestPortalTypes.CREATE_GUEST_PORTAL_FAILED,
);
export const createGuestPortalEnd = createAction(createGuestPortalTypes.CREATE_GUEST_PORTAL_END);

// Update guest portal
export const updateGuestPortalRequest = createAction(
	updateGuestPortalTypes.UPDATE_GUEST_PORTAL_REQUEST,
);
export const updateGuestPortalSucceeded = createAction(
	updateGuestPortalTypes.UPDATE_GUEST_PORTAL_SUCCEEDED,
);
export const updateGuestPortalFailed = createAction(
	updateGuestPortalTypes.UPDATE_GUEST_PORTAL_FAILED,
);
export const updateGuestPortalEnd = createAction(updateGuestPortalTypes.UPDATE_GUEST_PORTAL_END);

// Delete guest portal
export const deleteGuestPortalRequest = createAction(
	deleteGuestPortalTypes.DELETE_GUEST_PORTAL_REQUEST,
);
export const deleteGuestPortalSucceeded = createAction(
	deleteGuestPortalTypes.DELETE_GUEST_PORTAL_SUCCEEDED,
);
export const deleteGuestPortalFailed = createAction(
	deleteGuestPortalTypes.DELETE_GUEST_PORTAL_FAILED,
);
export const deleteGuestPortalEnd = createAction(deleteGuestPortalTypes.DELETE_GUEST_PORTAL_END);

// Share guest portal
export const shareGuestPortalRequest = createAction(
	shareGuestPortalTypes.SHARE_GUEST_PORTAL_REQUEST,
);
export const shareGuestPortalSucceeded = createAction(
	shareGuestPortalTypes.SHARE_GUEST_PORTAL_SUCCEEDED,
);
export const shareGuestPortalFailed = createAction(shareGuestPortalTypes.SHARE_GUEST_PORTAL_FAILED);
export const shareGuestPortalEnd = createAction(shareGuestPortalTypes.SHARE_GUEST_PORTAL_END);

export const storeDataGuestPortalRequest = createAction(
	storeDataGuestPortalTypes.STORE_DATA_GUEST_PORTAL,
);
