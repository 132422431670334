const hanldeRoundAvgStar = (avgRatingStar: number) => {
	let newAvgStar = 0;
	const integerNum = Math.floor(avgRatingStar);
	const quaterOfNum = integerNum + 0.25;
	const threeQuaterOfNum = quaterOfNum + 0.5;

	if (avgRatingStar >= threeQuaterOfNum) {
		newAvgStar = integerNum + 1;
	} else if (avgRatingStar >= quaterOfNum && avgRatingStar < threeQuaterOfNum) {
		newAvgStar = quaterOfNum + 0.25;
	} else {
		newAvgStar = integerNum;
	}

	return newAvgStar;
};

export { hanldeRoundAvgStar };