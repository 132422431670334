import styled, { css } from 'styled-components';
import {
	Modal,
	Row,
	Col,
	Collapse,
	Image,
	Tag,
	Form,
	Button,
	Carousel,
	Rate,
	Divider,
	Tooltip,
} from 'antd';
import Wrapper from '@cores/Wrapper';
import THEME from '@constants/themes/themes';
import { PLACEHOLDER_IMAGE_IN_BASE64 } from '@constants/common';
import { BoundingBoxDto } from '@models/content/contentLibrary/summary';
import { MEDIA_SIZE_DEFAULT } from '@constants/publish/gallery';

const square = 595;
const landscape = 579;
const portrait = 712;
// const subtractedHeight = 82;

const squareSizeStyles = css`
	width: auto;
	height: auto;
	min-width: 400px;
	min-height: 400px;
	max-width: 595px;
	max-height: 595px;
`;

const landscapeSizeStyles = css`
	width: auto;
	height: auto;
	min-width: 579px;
	min-height: 379px;
	max-width: 779px;
	max-height: 579px;
`;

const portraitSizeStyles = css`
	width: auto;
	height: auto;
	min-width: 318px;
	min-height: 512px;
	max-width: 518px;
	max-height: 712px;
`;

const StyledModal = styled(Modal)`
	&.modal-content-detail {
		width: auto !important;
		min-width: 400px;
		padding: 0;
		position: static;

		.ant-modal-content {
			position: static;
		}
	}
`;

const StyledContainer = styled(({ height, subtractedHeight, margin, padding, ...props }) => (
	<Wrapper {...props} />
))<{
	height: number;
	subtractedHeight: number;
	margin?: string;
	padding?: string;
}>`
	overflow-y: scroll;
	overflow-x: hidden;
	transition: max-height 0.5s;
	margin: ${({ margin }) => margin || 'unset'};
	padding: ${({ padding }) => padding || '16px 0 16px 0'};

	&.square {
		max-height: ${({ height, subtractedHeight }) =>
			height >= square
				? `calc(${square}px - ${subtractedHeight}px)`
				: `calc(${height}px - ${subtractedHeight}px)`};
	}

	&.landscape {
		max-height: ${({ height, subtractedHeight }) =>
			height >= landscape
				? `calc(${landscape}px - ${subtractedHeight}px)`
				: `calc(${height}px - ${subtractedHeight}px)`};
	}

	&.portrait {
		max-height: ${({ height, subtractedHeight }) =>
			height >= portrait
				? `calc(${portrait}px - ${subtractedHeight}px)`
				: `calc(${height}px - ${subtractedHeight}px)`};
	}
	&.container_custom {
		height: calc(100% - 234px);
		overflow: hidden auto;
	}

	::-webkit-scrollbar-track {
		background: ${THEME.colors.darkBlue2};
	}
`;

const StyledContainerAction = styled(({ isDisableAction, ...props }) => <div {...props} />)`
	float: right;
	pointer-events: ${({ isDisableAction }) => (isDisableAction ? 'none' : 'unset')};

	svg {
		margin-right: 0;
	}
`;

const StyledRow = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
`;

const StyledCol = styled(Col)<{ width?: string; background?: string; padding?: string }>`
	position: relative;
	padding: ${({ padding }) => padding || '24px'};
	width: ${({ width }) => width || 'auto'};
	background-color: ${({ background }) => background || `${THEME.colors.darkBlue2}`};
`;

const StyledImageContainer = styled.div`
	width: 100%;
	height: 100%;
	.ant-carousel {
		width: 100%;
		height: 100%;
	}
	.position_media {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const StyledImage = styled.img<{
	rotate?: number | null;
	visibleImage?: boolean;
}>`
	object-fit: contain;
	max-width: 100%;
	margin: 0 auto;
	max-height: 100%;

	transform: rotate(${({ rotate }) => rotate || 0}deg);
	background: ${({ visibleImage, rotate }) =>
		visibleImage || (rotate && rotate % 180 !== 0)
			? `url('${PLACEHOLDER_IMAGE_IN_BASE64}')
		no-repeat scroll 0 0`
			: 'none !important'};
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	opacity: 1;
	&.had_loading {
		opacity: 0 !important;
	}

	&.square {
		${squareSizeStyles}
	}

	&.landscape {
		${landscapeSizeStyles}
	}

	&.portrait {
		${portraitSizeStyles}
	}
	&.place_img {
		width: ${MEDIA_SIZE_DEFAULT}px;
		height: ${MEDIA_SIZE_DEFAULT}px;
	}
	&.responsive_size {
		max-height: calc(90vh - 48px);
	}
`;

const StyledVideo = styled.video<{ height?: string; width?: string }>`
	max-width: 100%;
	max-height: 100%;
	&.square {
		${squareSizeStyles}
	}

	&.landscape {
		${landscapeSizeStyles}
	}

	&.portrait {
		${portraitSizeStyles}
	}

	&.place_img {
		width: ${MEDIA_SIZE_DEFAULT}px;
		height: ${MEDIA_SIZE_DEFAULT}px;
	}
	${({ height }) => height && `height: ${height} !important`};
`;

const StyledForm = styled(({ isDisableAction, ...props }) => <Form {...props} />)`
	pointer-events: ${({ isDisableAction }) => (isDisableAction ? 'none' : 'unset')};
`;

const StyledFormItem = styled(({ isDisableAction, ...props }) => <Form.Item {...props} />)`
	margin: ${({ margin }) => margin || '0 0 16px 0'};

	&.mb_0 {
		margin-bottom: 0px;
	}

	&.form-item-star {
		.ant-form-item-control {
			.ant-form-item-control-input {
				min-height: unset;
			}
		}
	}

	.ant-form-item-label {
		height: 22px;
		padding: 0;
		margin: 0 0 8px 0;
	}

	pointer-events: ${({ isDisableAction }) => (isDisableAction ? 'none' : 'unset')};
`;

const StyledText = styled.p`
	display: inline-block;
	font-size: 0.857rem;
	padding: 0 7px;
	height: 22px;
	background-color: ${THEME.colors.darkBlue3};
	border-radius: 2px;
	margin-right: 5px;
`;

const StyledCollapse = styled(Collapse)<{
	padding?: string;
	paddingHeader?: string;
	isDisabledBtn?: boolean;
}>`
	padding: ${({ padding }) => padding || '0'};

	&,
	.ant-collapse-content,
	.ant-collapse-item {
		border: none;
	}

	.ant-collapse-content {
		.ant-collapse-content-box {
			padding: 0;
		}
	}

	.ant-collapse-item {
		&.custom-panel:not(.ant-collapse-item-active) {
			border-bottom: 0.5px solid ${THEME.colors.darkBlue3};
		}

		.ant-collapse-header {
			padding: ${({ paddingHeader }) =>
				paddingHeader ? `${paddingHeader} !important` : '12px 0 !important'};
		}

		&.ant-collapse-item-disabled {
			pointer-events: none;

			&:not(:last-child) {
				border-bottom: 0.5px solid ${THEME.colors.darkBlue3};
			}
		}
	}

	margin-bottom: 12px;

	.extra-btn {
		position: absolute;
		top: 24px;
		right: 18px;
		pointer-events: auto;

		&.disabled-btn {
			pointer-events: none !important;
		}

		svg {
			path {
				fill: ${THEME.colors.white};
			}
		}
	}
`;

const StyledSlideControl = styled.div<{ positionArrow?: string; isDisabled?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	${({ positionArrow }) => positionArrow && `${positionArrow}: 3.5rem`};
	cursor: pointer;
	width: 57px;
	height: 57px;
	line-height: 57px;
	background-color: ${THEME.colors.darkBlue2};
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};
	z-index: 1001;

	svg {
		width: 40px;
		height: 40px;
	}
`;

const StyledButton = styled(Button)<{ margin?: string }>`
	margin: ${({ margin }) => margin || 'none'};
`;

const StyledCheckableTag = styled(Tag.CheckableTag)`
	background-color: ${THEME.colors.darkBlue3};
	margin-bottom: 8px;

	&:hover {
		background-color: ${THEME.colors.orangeBase};
		color: #e0e3e5 !important;
	}

	&.ant-tag-checkable-checked {
		background-color: ${THEME.colors.orangeBase};
	}
`;

const StyledCarousel = styled(Carousel)`
	&.square {
		width: 595px !important;
	}

	&.landscape {
		width: 779px !important;
	}

	&.portrait {
		width: 518px !important;
	}

	&.default-carousel {
		img,
		video {
			opacity: 0 !important;
		}
	}

	&.custom-carousel {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		img,
		video {
			opacity: 1 !important;
		}
		.slick-list {
			height: 100%;
			width: 100%;
			/* display: flex;
			justify-content: center; */
			.slick-track {
				width: 100%;
				height: 100%;
			}
			.slick-slide {
				margin: 0 auto;
				height: 100%;
				width: 100%;
				& > div {
					margin: 0 auto;
					height: 100%;
					width: 100%;
					.position_media {
						margin: 0 auto;
						height: 100%;
						width: 100%;
					}
				}
				div {
					margin: 0 auto;
				}
				&:not(.slick-active) {
					.image-content-detail {
						visibility: hidden;
					}
					.video-content-detail {
						visibility: hidden;
					}
				}
			}
		}
	}

	text-align: center;

	> .slick-dots {
		margin: 0;

		li button {
			width: 10px;
			height: 10px;
			border-radius: 100%;
			border: 1px solid #fff;
			box-shadow: 0 0 4px 2px ${THEME.colors.gray1};
			background: ${THEME.colors.gray5};
			opacity: 0.6;

			&,
			&:hover {
				&:before {
					display: none;
				}
			}
		}

		li.slick-active {
			width: 14px;
			pointer-events: none;

			button {
				width: 10px;
				height: 10px;
				border-radius: 100%;
				border: 1px solid #fff;
				box-shadow: 0 0 4px 2px ${THEME.colors.gray1};
				background: ${THEME.colors.gray5};
			}
		}
	}
`;

const StyledIconClose = styled.div<{
	position?: string;
	left?: boolean;
	right?: boolean;
	border?: string;
}>`
	position: ${({ position }) => position || 'absolute'};
	cursor: pointer;
	top: 12.5px;
	${({ left }) => left && 'left: 12.5px'};
	${({ right }) => right && 'right: 12.5px'};
	display: flex;
	align-items: center;
	border-radius: 2px;
	border: ${({ border }) => border || `solid 1px ${THEME.colors.darkBlue4}`};
	z-index: 9;

	.fullscreen-exit-icon {
		font-size: 1.5rem;
		padding: 4px;
	}
`;

const StyledRate = styled(Rate)`
	&.rate_content {
		.ant-rate-star {
			&:not(:last-child) {
				margin-right: 12px;
			}
			.anticon-star {
				svg {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
`;

const StyledFullScreen = styled(Modal)`
	width: unset !important;
	padding: 0 !important;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-animation-name: animatetop;
	-webkit-animation-duration: 0.5s;
	animation-name: animatetop;
	animation-duration: 0.5s;

	.ant-modal-content {
		height: calc(100vh - 50px);
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent !important;

		.ant-modal-body {
			width: 100%;
			height: 100%;
			padding: 0;

			.ant-image {
				width: 100%;
				height: 100%;

				.ant-image-img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.fullscreen-close-icon {
				position: fixed;
				right: 0;
			}
		}
	}

	@keyframes animatetop {
		from {
			top: -300px;
			opacity: 0;
		}
		to {
			top: 0;
			opacity: 1;
		}
	}

	@keyframes animatetop {
		from {
			top: -300px;
			opacity: 0;
		}
		to {
			top: 0;
			opacity: 1;
		}
	}
`;

const StyledContentDetail = styled(Row)`
	.content-detail-info {
		font-size: 0.857rem;

		.info-title {
			color: ${THEME.colors.gray1};
			margin-bottom: 8px;
			padding: 0;
		}

		.info {
			cursor: pointer;
			height: 22px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.download-section {
			margin-bottom: 12px;

			.download-pdf {
				color: ${THEME.colors.blueBase};
				text-decoration: underline;
				cursor: pointer;
			}
			.download-pdf.disabled {
				pointer-events: none;
				cursor: default;
			}
		}

		.content-info-tooltip {
			font-size: 0.857rem !important;

			.ant-tooltip-inner {
				min-height: unset;
				padding: 2px 6px;
			}
		}
	}
`;

const StyledDivider = styled(Divider)`
	border-color: ${THEME.colors.darkBlue3};
	margin: 12px 0;
`;

const StyledImageFull = styled(Image)<{ rotate?: number; scale?: number }>`
	object-fit: contain;
	transform: ${({ rotate, scale }) => `rotate(${rotate || 0}deg) scale(${scale || 1})`};
`;

const StyledVideoFull = styled.div<{ height?: string; width?: string }>`
	height: ${({ height }) => height || '442px'};
	width: ${({ width }) => width || '660px !important'};
	margin: 0 auto;

	video {
		width: 100%;
		height: 100%;
	}
`;

const StyledTooptip = styled(Tooltip)``;

const StyledButtonSocialNetwork = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 4px 8px;
	width: 198px;
	color: ${THEME.colors.gray5};
	background-color: ${THEME.colors.darkBlue1};
	border-radius: 3px;
	cursor: pointer;
	position: absolute;
	top: 16px;
	right: 7px;
	z-index: 101;
	&:hover {
		color: ${THEME.colors.orangeBase};
	}
	&.color_path {
		&:hover {
			svg {
				path {
					fill: ${THEME.colors.orangeBase};
				}
			}
		}
	}
`;

const StyledWrapperPosition = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	&.position_media {
		.had_loading {
			opacity: 0 !important;
		}
	}
`;

const StyledWrapperVoteContent = styled.div`
	position: absolute;
	bottom: -77px;
	border-radius: 8px;
	&.has_scroll {
		position: fixed;
		bottom: 16px;
		z-index: 1331;
	}
`;

const StyledContainerVote = styled.div<{ disabled?: boolean }>`
	${({ disabled }) => disabled && 'pointer-events: none'};
	display: inline-flex;
	align-items: center;
	padding: 14px 22px;
	background-color: ${THEME.colors.darkBlue2};
`;

const StyledWrapperViewTooltip = styled.div``;

const StyledInfoEmail = styled.div`
	font-size: 14px;
	line-height: 20px;
	margin-top: 6px;
	color: ${THEME.colors.white};
	text-align: center;
	&:first-child {
		margin-top: 0;
	}
`;

const StyledVote = styled.div`
	display: inline-flex;
	align-items: center;
	margin-left: 10px;
	.icon_feel {
		cursor: pointer;
		transition: 0.25s ease-out;
		height: 45px;
		width: 45px;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		&.icon_dislike {
			svg {
				margin-right: 4px;
				margin-top: 2px;
			}
		}
		&.icon_like {
			svg {
				margin-bottom: 2px;
			}
		}
		svg {
			transition: 0.25s ease-out;
			path {
				transition: 0.25s ease-out;
			}
		}
		&:hover {
			background-color: rgba(103, 115, 125, 0.3);

			svg {
				transform: scale(1.1);
			}
		}
	}

	&:first-child {
		margin-left: 0;
	}
`;

const StyledNumVote = styled.span`
	margin-left: 4px;
	font-size: 14px;
	line-height: 20px;
	color: ${THEME.colors.white};
`;

const StyledWrapperListVersion = styled.div``;

const StyledDetectedLogoPosition = styled.div<{
	showDetectedLogo: boolean;
	detectedLogoPosition?: BoundingBoxDto;
}>`
	opacity: ${({ showDetectedLogo }) => (showDetectedLogo ? 1 : 0)};
	position: absolute;
	transition: opacity 0.75s;

	.label {
		position: absolute;
		top: -25px;
	}

	${({ detectedLogoPosition }) => css`
		top: ${detectedLogoPosition?.top}px;
		left: ${detectedLogoPosition?.left}px;
		width: ${detectedLogoPosition?.width}px;
		height: ${detectedLogoPosition?.height}px;
		border: 2px solid red;
	`}
`;

export {
	StyledModal,
	StyledContainer,
	StyledRow,
	StyledCol,
	StyledImageContainer,
	StyledImage,
	StyledForm,
	StyledFormItem,
	StyledText,
	StyledCollapse,
	StyledSlideControl,
	StyledButton,
	StyledCheckableTag,
	StyledCarousel,
	StyledContainerAction,
	StyledIconClose,
	StyledRate,
	StyledFullScreen,
	StyledVideo,
	StyledContentDetail,
	StyledDivider,
	StyledImageFull,
	StyledVideoFull,
	StyledTooptip,
	StyledButtonSocialNetwork,
	StyledWrapperPosition,
	StyledWrapperVoteContent,
	StyledContainerVote,
	StyledVote,
	StyledNumVote,
	StyledWrapperViewTooltip,
	StyledInfoEmail,
	StyledWrapperListVersion,
	StyledDetectedLogoPosition,
};
