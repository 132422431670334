import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, Tabs, message } from 'antd';
import _, { cloneDeep } from 'lodash';

// Icons
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';

// Components
import ContentList from '@components/Creators/Profile/Content/ContentList';
import { LoadingWrapper } from '@cores/index';

// Integrate API
import {
	fetchContentDataEnd,
	fetchContentDataRequest,
	resetStateRequest,
	reviewContentEnd,
	reviewMultiContentsEnd,
	storeCreatorData,
} from '@stores/actions';

// Models
import {
	ACTIONS,
	FOLDER,
	MOVE_CONTENT,
	SYSTEM_FOLDER_ALL,
	SYSTEM_FOLDER_LIST,
	SYSTEM_FOLDER_LIST_OBJ,
} from '@constants/content/contentLibrary';
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
	ContentVerificationScoreDto,
} from '@models/content/contentLibrary/summary';
// Styled
import ModalMediaDetail from '@components/ModalCustom/ModalMediaDetail';
import { CONTENT_MEDIA_API } from '@constants/APIs';
import { DEFAULT_PARAMS_CONTENT } from '@constants/content/albums';
import { Paginator } from '@constants/paginator';
import { SOCIAL } from '@constants/social_search';
import { FAILED, NONE, SUCCEEDED } from '@constants/status';
import CreatorContentContext from '@contexts/Creators/Content';
import { getRequest } from '@helpers/requestHelpers';
import { FolderItemDto } from '@models/common/summary';
import {
	ContentStoreDto,
	GetContentPayloadDto,
	ImgVersionDto,
} from '@models/content/contentLibrary/store';
import { TotalRecordStore } from '@models/creator/profile';
import { RefContentProfile } from '@models/creator/ref';
import { SocialContentStoreDto } from '@models/socialsearch/store';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	blockSocialContentEnd,
	fetchSocialPostEnd,
	fetchSocialPostRequest,
} from '@stores/socialsearch/socialsearch.actions';
import { StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledContent,
	StyledEmpty,
	StyledTabs,
	StyledWrapper,
} from '@styled/Creators/ContentStyled';
import {
	filterContentListById,
	getAlbumId,
	getContentByType,
	updateFavoriteContentList,
	updateFavoriteSocialList,
} from '@utils/common';
import { checkPositionContent } from '@utils/funcHelper';
import { convertSocialId } from '@utils/socialSearch';
import SocialPostList from './SocialPostList';
import SocialPostSource from './SocialPostSource';

type TabPaneType = {
	tabId: string;
	name: string;
	hidden?: boolean;
	fieldName: string;
};

type ContentProps = {
	creatorId?: number;
};

const { TabPane } = Tabs;

const Content = (props: ContentProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { creatorId = 0 } = props;

	// Reducers
	const {
		fetchingContentData,
		contentData,
		rejectedContent = [],
		deletedContent,
		reviewContentStatus,
		reviewedContent,
	}: ContentStoreDto = useSelector((state: any) => state.content);
	const {
		creatorProfile,
		updateBasicInformationCreator,
		updateCreatorStatusState,
	}: UserStoreType = useSelector((state: any) => state.creator);

	const {
		fetchingSocialPost,
		importSocialContentStatus,
		blockSocialContentStatus,
		error: errorSocialContent,
		blockedSocialContent,
	}: SocialContentStoreDto = useSelector((state: any) => state.socialContent);

	const { folderSystem } = useSelector((state: any) => state.sidebar);
	const { socialPost }: SocialContentStoreDto = useSelector((state: any) => state.socialContent);

	const {
		social: {
			instagramUsername = '',
			twitterUsername = '',
			youtubeUsername = '',
			tiktokUsername = '',
		} = {},
	} = creatorProfile || {};

	const renderTabItem = (key: string) => {
		const foundObject = folderSystem?.find((item: FolderItemDto) => item?.name === key);
		let name = foundObject?.name || '';
		if (name === FOLDER.REJECTED.toLowerCase()) {
			name = t('button.archived');
		}
		return {
			tabId: foundObject?.id.toString(),
			name: name?.charAt(0).toUpperCase() + name.slice(1),
			fieldName: name.toLowerCase(),
		};
	};

	const listTabPane = [
		{
			tabId: SYSTEM_FOLDER_LIST_OBJ.SUBMISSIONS.id,
			name: SYSTEM_FOLDER_LIST_OBJ.SUBMISSIONS.name,
			fieldName: SYSTEM_FOLDER_LIST_OBJ.SUBMISSIONS.name.toLowerCase(),
		},
		{
			tabId: SYSTEM_FOLDER_LIST_OBJ.SOCIAL_POST.id,
			name: SYSTEM_FOLDER_LIST_OBJ.SOCIAL_POST.name,
			hidden: !instagramUsername && !twitterUsername && !youtubeUsername && !tiktokUsername,
			fieldName: SYSTEM_FOLDER_LIST_OBJ.SOCIAL_POST.id,
		},
		{
			tabId: SYSTEM_FOLDER_LIST_OBJ.PUBLISHED.id,
			name: SYSTEM_FOLDER_LIST_OBJ.PUBLISHED.name,
			fieldName: SYSTEM_FOLDER_LIST_OBJ.PUBLISHED.id,
		},
		renderTabItem('rejected'),
	];

	const contentListRef = useRef<RefContentProfile>(null);
	const socialListRef = useRef<RefContentProfile>(null);

	const [contentList, setContentList] = useState<ContentContainerDto[]>([]);
	const [socialPostList, setSocialPostList] = useState<SocialContentContainerDto[]>([]);
	const [selectContentList, setSelectContentList] = useState<
		ContentContainerDto[] | SocialContentContainerDto[]
	>([]);
	const [colCount, setColCount] = useState<number>(3);
	const [contentIdMove, setContentIdMove] = useState<number | null>(null);
	const [param, setParam] = useState<GetContentPayloadDto>({
		page: Paginator.defaultPage,
		activeType: listTabPane[0].tabId,
		creatorId,
		maxRecords: DEFAULT_PARAMS_CONTENT.maxRecords,
	});
	const defaultTotalHits = {
		submissions: null,
		socialPost: null,
		published: null,
		archived: null,
	};
	const [totalHits, setTotalHits] = useState<TotalRecordStore>({ ...defaultTotalHits });

	const [isFirstContent, setIsFirstContent] = useState<boolean>(false);
	const [isLastContent, setIsLastContent] = useState<boolean>(false);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [content, setContent] = useState<ContentContainerDto | null>(null);
	const [socialDetail, setSocialDetail] = useState<SocialContentContainerDto | null>(null);
	const [socialId, setSocialId] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [showPostDetails, setShowPostDetails] = useState<boolean>(true);

	const tabType = useMemo(() => {
		return {
			isPublished:
				param?.activeType?.toLowerCase() ===
				SYSTEM_FOLDER_LIST_OBJ.PUBLISHED.id.toLowerCase(),
			isSocialPost:
				param?.activeType?.toLowerCase() ===
				SYSTEM_FOLDER_LIST_OBJ.SOCIAL_POST.id.toLowerCase(),
		};
	}, [param]);

	const folderId = useMemo(() => {
		if (content) {
			if (param?.activeType === SYSTEM_FOLDER_LIST[0].id.toString() || tabType.isPublished) {
				return getAlbumId(getContentByType(content), folderSystem);
			}

			return param?.activeType;
		}

		return undefined;
	}, [content, param, tabType]);

	useEffect(() => {
		if (creatorId !== param?.creatorId) {
			resetTotalHits();
			setParam({
				page: Paginator.defaultPage,
				activeType: listTabPane[0].tabId,
				creatorId,
				maxRecords: DEFAULT_PARAMS_CONTENT.maxRecords,
			});
			return () => {
				setContentIdMove(null);
				setColCount(3);
				setContentList([]);
				onClearSelect();
				dispatch(resetStateRequest());
			};
		}
		return () => {
			resetStoredData();
		};
	}, [creatorId]);

	useEffect(() => {
		if (contentList.length) {
			if (content) {
				setContent(cloneDeep(contentList[currentIndex]));
			}
		}
	}, [contentList]);

	useEffect(() => {
		if (tabType.isSocialPost) {
			if (reviewContentStatus === SUCCEEDED && reviewedContent) {
				updateAfterReviewingContent([reviewedContent]);
			}

			if (reviewContentStatus === FAILED || reviewContentStatus === SUCCEEDED) {
				dispatch(reviewContentEnd());
			}
		}
	}, [reviewContentStatus, tabType]);

	useEffect(() => {
		if (importSocialContentStatus === SUCCEEDED) {
			handleAfterImportSocial();
			message.success(t('social_search.message.import_bookmark_success'));
		}
	}, [importSocialContentStatus]);

	useEffect(() => {
		if (blockSocialContentStatus === SUCCEEDED) {
			handleAfterBlockContentSocial(blockedSocialContent);
			message.info(t('social_search.message.block_content_success'));
		}

		if (blockSocialContentStatus === FAILED && errorSocialContent) {
			message.error(errorSocialContent);
		}

		if (blockSocialContentStatus === SUCCEEDED || blockSocialContentStatus === FAILED) {
			dispatch(blockSocialContentEnd());
		}
	}, [blockSocialContentStatus]);

	useEffect(() => {
		if (updateBasicInformationCreator) {
			changeFolder(listTabPane[0].tabId);
			resetTotalHits();
			dispatch(storeCreatorData({ updateBasicInformationCreator: false }));
		}
	}, [updateBasicInformationCreator]);

	useEffect(() => {
		if (creatorProfile?.status && updateCreatorStatusState === SUCCEEDED) {
			resetStoredData();
			changeFolder(listTabPane[0].tabId);
			resetTotalHits();
		}
	}, [creatorProfile?.status?.followed, updateCreatorStatusState]);

	const resetStoredData = () => {
		dispatch(fetchSocialPostEnd());
		dispatch(fetchContentDataEnd());

		if (updateCreatorStatusState !== NONE) {
			dispatch(
				storeCreatorData({
					updateCreatorStatusState: NONE,
				}),
			);
		}
	};

	const resetTotalHits = () => {
		setTotalHits(defaultTotalHits);
	};

	const storeTotalRecord = (totalRecords: number | null) => {
		const totalHitsTemp = {
			...totalHits,
		};

		switch (param.activeType) {
			case SYSTEM_FOLDER_LIST_OBJ.SUBMISSIONS.id:
				totalHitsTemp[SYSTEM_FOLDER_LIST_OBJ.SUBMISSIONS.name.toLowerCase()] = totalRecords;
				break;

			case SYSTEM_FOLDER_LIST_OBJ.SOCIAL_POST.id:
				totalHitsTemp[SYSTEM_FOLDER_LIST_OBJ.SOCIAL_POST.id] = totalRecords;
				break;

			case SYSTEM_FOLDER_LIST_OBJ.PUBLISHED.id:
				totalHitsTemp[SYSTEM_FOLDER_LIST_OBJ.PUBLISHED.id] = totalRecords;
				break;

			default:
				totalHitsTemp[t('button.archived').toLowerCase()] = totalRecords;
				break;
		}

		setTotalHits({ ...totalHitsTemp });
	};

	useEffect(() => {
		if (!_.isEmpty(contentData)) {
			const { result = [], totalRecords = 0 } = contentData;

			if (result.length > 0 && result.length <= 4) {
				setColCount(1);
			}
			if (result.length > 4 && result.length < 8) {
				setColCount(2);
			}
			if (result.length >= 8 && colCount !== 3) {
				setColCount(3);
			}

			if (result) {
				const newContentList =
					param.page === Paginator.defaultPage
						? [...result]
						: [...contentList, ...result];
				setContentList(newContentList);
			}

			storeTotalRecord(totalRecords);
		}
	}, [contentData]);

	useEffect(() => {
		if (!_.isEmpty(socialPost)) {
			const { listSocialMediaResponse, totalHits: storedTotalHits = 0 } = socialPost;
			const isFirstPage = param.page === Paginator.defaultPage;

			if (listSocialMediaResponse) {
				const newSocialList = isFirstPage
					? [...listSocialMediaResponse]
					: [...socialPostList, ...listSocialMediaResponse];
				setSocialPostList(newSocialList);
			}

			if (isFirstPage) {
				storeTotalRecord(storedTotalHits);
			}
		}
	}, [socialPost]);

	useEffect(() => {
		if (tabType.isSocialPost) {
			callAPIfetchingSocialPost();
		} else {
			callAPIGetContent();
		}
	}, [param]);

	const callAPIfetchingSocialPost = () => {
		const { activeType, ...others } = param;

		if (others.sources) {
			others.sources = others.sources.map((item: string) => item.toUpperCase());
		}

		dispatch(
			fetchSocialPostRequest({
				...others,
			}),
		);
	};

	const callAPIGetContent = () => {
		const { activeType = '', sources = [], ...otherParam } = param || {};
		const activeTypeTemp = activeType.toString().toLowerCase();

		if (activeTypeTemp === SYSTEM_FOLDER_LIST_OBJ.PUBLISHED.id.toLowerCase()) {
			dispatch(
				fetchContentDataRequest({
					published: true,
					sortBy: 'id',
					folder: SYSTEM_FOLDER_ALL.id,
					...otherParam,
				}),
			);
		} else {
			const folderSelect: any = listTabPane?.find(
				(item: TabPaneType) => item?.tabId.toLowerCase() === activeTypeTemp,
			);

			let folderId: string = '';

			if (folderSelect) {
				folderId = folderSelect?.tabId.toString();
			}

			dispatch(
				fetchContentDataRequest({
					folder: folderId,
					...otherParam,
				}),
			);
		}
	};

	const initContentFolder = () => {
		setContentList([]);
		setSocialPostList([]);
		onClearSelect();
	};

	const changeFolder = (activeType: string) => {
		const defaultPayload = { ...param, activeType, page: Paginator.defaultPage };
		if (activeType.toLowerCase() === SYSTEM_FOLDER_LIST_OBJ.SOCIAL_POST.id.toLowerCase()) {
			defaultPayload.sources = param.sources || [
				SOCIAL.INSTAGRAM,
				SOCIAL.TIKTOK,
				SOCIAL.TWITTER,
				SOCIAL.YOUTUBE,
			];
		}

		delete defaultPayload.isShowAmbassadorPostOnly;
		setParam({ ...defaultPayload });
		initContentFolder();
	};

	const handleScrollbars = (scrollbarTop: number) => {
		if (
			scrollbarTop >= 0.75 &&
			((tabType.isSocialPost && socialPost.listSocialMediaResponse.length > 0) ||
				(!tabType.isSocialPost && contentData.result.length > 0))
		) {
			setParam({ ...param, page: param.page + 1 });
		}
	};

	// Drag to select
	const handleSelecting = (
		selectContent: Array<ContentContainerDto> | Array<SocialContentContainerDto>,
	) => {
		setSelectContentList(selectContent);
	};

	const updateContentList = (contentList: ContentContainerDto[]) => {
		setContentList(contentList);
	};

	const handleAfterBlockContentSocial = (ids: SocialContentContainerDto[]) => {
		let newSocialPosts = socialPostList;

		ids.forEach((k) => {
			newSocialPosts = newSocialPosts.filter(
				(item) => convertSocialId(item) !== convertSocialId(k),
			);
		});
		setSocialPostList(newSocialPosts);
		const itemFind = newSocialPosts[currentIndex];
		if (itemFind) {
			const res = checkPositionContent(currentIndex, newSocialPosts.length);
			setIsFirstContent(res.isFirst);
			setIsLastContent(res.isLast);
			onChangeSocialDetail(itemFind);
		}
	};

	const handleAfterImportSocial = () => {
		const socialSelect = socialPostList?.find((item) => convertSocialId(item) === socialId);
		if (socialSelect && socialId) {
			handleFetchContentDetail(socialSelect.mediaId, socialId);
		}
	};

	const handleFetchContentDetail = (id: string, soId: string) => {
		setLoading(true);
		getRequest(`${CONTENT_MEDIA_API}/${id}`)
			.then((res) => {
				const contentRes = res?.body?.data?.result;
				socialPostList.forEach((item) => {
					if (convertSocialId(item) === soId) {
						item.content = contentRes;
						item.imported = true;
						setSocialId(convertSocialId(item));
					}
				});

				setContent(contentRes);
				setSocialDetail(null);
			})
			.catch((error) => {
				console.log('err', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateAfterReviewingContent = (reviewedContents: ContentReviewDto[]) => {
		dispatch(reviewContentEnd());
		dispatch(reviewMultiContentsEnd());
		if (tabType?.isSocialPost) {
			if (contentIdMove) {
				socialPostList.forEach((item) => {
					if (item?.content) {
						const contentChangeType = getContentByType(item.content);
						if (contentChangeType?.id === contentIdMove) {
							item.imported = false;
							item.autoImported = false;
							item.content = null;
							if (socialId === convertSocialId(item)) {
								handleContentDetails(currentIndex + 1);
							}
						}
					}
				});

				setContentIdMove(null);
				return;
			}

			socialPostList.forEach((item) => {
				if (item?.content) {
					const contentChangeType: ContentDto = getContentByType(item.content);
					if (contentChangeType?.id === reviewedContents[0].contentId) {
						contentChangeType.review = reviewedContents[0];
						contentChangeType.review.isRecentlyAdded = false;
						if (convertSocialId(item) === socialId) {
							setContent(item.content);
						}
						const customCheck = contentChangeType.review.custom;
						if (reviewedContents?.length === 1) {
							const customFieldUpdate = reviewedContents[0].custom;
							if (customFieldUpdate && customCheck) {
								customFieldUpdate.forEach((field) => {
									if (field.key && contentChangeType.review.custom) {
										const idx = customCheck.findIndex(
											(cField) => cField.key === field.key,
										);
										if (idx !== -1) {
											const newCustoms = [...contentChangeType.review.custom];
											newCustoms[idx] = field;
											contentChangeType.review.custom = newCustoms;
										}
									}
								});
							}
						}
					}
				}
			});
			return;
		}
		let contentListTemp: Array<ContentContainerDto> = [];
		contentListTemp = _.cloneDeep(contentList);

		contentListTemp.forEach((item) => {
			const content = getContentByType(item);
			reviewedContents.forEach((reviewed: ContentReviewDto) => {
				if (content.id === reviewed.contentId) {
					content.review = reviewed;
					content.review.isRecentlyAdded = false;
				}
			});
		});

		if (contentIdMove) {
			contentListTemp = contentListTemp.filter((item) => {
				const contentTemp = getContentByType(item);
				return contentTemp.id !== contentIdMove;
			});
			onClearSelect();
			setContentIdMove(null);
		}

		setContentList([...contentListTemp]);
	};

	const onClearSelect = () => {
		if (contentListRef?.current?.clearSelecting) {
			contentListRef?.current?.clearSelecting();
		}
		if (socialListRef?.current?.clearSelecting) {
			socialListRef?.current?.clearSelecting();
		}
		setSelectContentList([]);
	};

	const handleContentDetails = (index: number) => {
		setCurrentIndex(index);
		const lengthList = tabType.isSocialPost ? socialPostList?.length : contentList.length;

		const res = checkPositionContent(index, lengthList);
		setIsFirstContent(res.isFirst);
		setIsLastContent(res.isLast);

		if (tabType.isSocialPost) {
			onChangeSocialDetail(socialPostList[index]);
		} else {
			setContent(contentList[index]);
		}
	};

	const onChangeSocialDetail = (social: SocialContentContainerDto | null) => {
		let socialNew = social;
		let contentNew = null;
		const newSocialId = social ? convertSocialId(social) : null;
		setSocialId(newSocialId);

		if (social && newSocialId !== socialId && social?.content) {
			socialNew = null;
			contentNew = social.content;
		}

		setSocialDetail(socialNew);
		setContent(contentNew);
	};

	const renderContentList = (tabItem: TabPaneType) => {
		if (
			param?.activeType?.toLowerCase() === tabItem.tabId.toLowerCase() &&
			!tabType.isSocialPost
		) {
			return (
				<ContentList
					handleSelecting={(contentList: Array<ContentContainerDto>) =>
						handleSelecting(contentList)
					}
					ref={contentListRef}
					colCount={colCount}
					isToggle={false}
					updateContentList={updateContentList}
					handleScrollbars={handleScrollbars}
					selectContentList={selectContentList}
					updateAfterReviewingContent={updateAfterReviewingContent}
					setContentIdMove={setContentIdMove}
				/>
			);
		}

		return (
			<SocialPostList
				handleSelecting={(socialPostList: Array<SocialContentContainerDto>) =>
					handleSelecting(socialPostList)
				}
				ref={socialListRef}
				handleScrollbars={handleScrollbars}
			/>
		);
	};

	const renderTabTitle = (item: TabPaneType) => {
		const total = !_.isNull(totalHits[item.fieldName]) ? `(${totalHits[item.fieldName]})` : '';
		return <StyledTitle>{`${item.name} ${total}`}</StyledTitle>;
	};

	const renderFolderList = () => {
		return listTabPane.map((item: TabPaneType) => {
			if (item?.hidden) {
				return null;
			}
			return (
				<TabPane tab={renderTabTitle(item)} key={item.tabId} closable={false}>
					<LoadingWrapper
						isLoading={fetchingContentData || fetchingSocialPost || loading}>
						{(!tabType.isSocialPost && contentList.length > 0) ||
						(tabType.isSocialPost && socialPostList.length > 0) ? (
							renderContentList(item)
						) : (
							<StyledWrapper>
								<StyledEmpty image={IconEmpty} />
							</StyledWrapper>
						)}
					</LoadingWrapper>
				</TabPane>
			);
		});
	};

	const markAsFavoriteContent = (favoriteContent: ContentDto) => {
		if (tabType.isSocialPost) {
			updateFavoriteSocialList(favoriteContent, socialPostList, socialId);
			return;
		}
		const updatedList = updateFavoriteContentList(favoriteContent, contentList);
		setContentList([...updatedList]);
	};

	const moveContent = (action: string) => {
		let currentIndexTemp: number = 0;
		const mediaLength = tabType.isSocialPost ? socialPostList?.length : contentList.length;
		const maxContentIndex = mediaLength - 1;

		if (action === MOVE_CONTENT.NEXT) {
			currentIndexTemp = currentIndex + 1;
		} else {
			currentIndexTemp = currentIndex - 1;
		}

		if (currentIndexTemp <= 0) {
			currentIndexTemp = 0;
		}

		if (currentIndexTemp >= maxContentIndex) {
			currentIndexTemp = maxContentIndex;
		}

		handleContentDetails(currentIndexTemp);
	};

	const handleCloseContentDetails = () => {
		setContent(null);
		setSocialDetail(null);
		setSocialId(null);
		setIsFirstContent(false);
		setIsLastContent(false);
	};

	const onChangeAfterAction = async (action: string, contentsAfterAction?: number[]) => {
		let contentListTemp: Array<ContentContainerDto> = [];
		if (tabType.isSocialPost && content) {
			return changeSocialAfterAction(action);
		}

		if (action === ACTIONS.DELETE) {
			contentListTemp = filterContentListById(contentList, deletedContent);
		}

		if (action === ACTIONS.REJECT && rejectedContent) {
			contentListTemp = [...contentList];
			rejectedContent?.forEach((content: number) => {
				contentListTemp = contentListTemp.filter((item) => {
					const contentTemp = getContentByType(item);
					return contentTemp.id !== content;
				});
			});
		}

		if (action === ACTIONS.UNREJECT && content) {
			contentListTemp = contentList?.filter((contentItem) => {
				const contentTemp = getContentByType(contentItem);
				const contentSelect = getContentByType(content);
				return contentTemp?.id !== contentSelect?.id;
			});
		}

		updateContentList([...contentListTemp]); // update content list in Content.

		moveContentAfterAction(contentListTemp);
		return null;
	};

	const changeSocialAfterAction = (action: string) => {
		const newList = socialPostList.filter((item) => convertSocialId(item) !== socialId);

		setSocialPostList(newList);
		if (isLastContent) {
			handleCloseContentDetails();
		} else {
			const res = checkPositionContent(currentIndex, newList.length);
			setIsFirstContent(res.isFirst);
			setIsLastContent(res.isLast);
			onChangeSocialDetail(newList[currentIndex]);
		}
	};

	const moveContentAfterAction = (contentListTemp: ContentContainerDto[]) => {
		onClearSelect();
		if (isLastContent) {
			handleCloseContentDetails();
		} else {
			setContent(contentListTemp[currentIndex]);
			if (currentIndex >= contentListTemp.length - 1) {
				setIsLastContent(true);
			}
		}
	};

	const handleUpdateNewVersion = (contentId: number, version: ImgVersionDto) => {
		if (tabType.isSocialPost) {
			socialPostList.forEach((item) => {
				if (item?.content) {
					const contentChangeType: ContentDto = getContentByType(item.content);
					if (contentChangeType?.id === contentId) {
						contentChangeType.contentUrl = version?.contentURL;
						contentChangeType.thumbnail = version?.thumbnailURL;
						contentChangeType.contentMetadata = {
							...contentChangeType.contentMetadata,
							...(version?.metadata || {}),
						};
						if (convertSocialId(item) === socialId) {
							setContent(item.content);
						}
					}
				}
			});
			return;
		}
		const contentListTemp: Array<ContentContainerDto> = [...contentList];
		contentListTemp.forEach((item) => {
			const contentTemp: ContentDto = getContentByType(item);
			if (contentTemp?.id === contentId) {
				contentTemp.contentUrl = version?.contentURL;
				contentTemp.thumbnail = version?.thumbnailURL;
				contentTemp.contentMetadata = {
					...contentTemp.contentMetadata,
					...(version?.metadata || {}),
				};
			}
		});
	};

	const updateMediaImgUrl = (id: number, src: string, idConvert: string) => {
		socialPostList.map((data: any, index: number) => {
			if (convertSocialId(data) === idConvert && index === currentIndex) {
				data.imgUrl = src;
				onChangeSocialDetail({ ...data });
			}
		});
	};

	const updateVerificationScrore = (
		content: ContentDto,
		verificationScore: ContentVerificationScoreDto,
	) => {
		if (tabType.isSocialPost) {
			socialPostList.forEach((item) => {
				if (item?.content) {
					const contentChangeType: ContentDto = getContentByType(item.content);
					if (convertSocialId(item) === socialId) {
						contentChangeType.verificationScore = verificationScore;
						setContent(item.content);
					}
				}
			});
		} else {
			content.verificationScore = verificationScore;
		}
	};

	const renderExtraButton = useMemo(() => {
		if (tabType.isSocialPost) {
			return <SocialPostSource />;
		}

		return null;
	}, [param]);

	const creatorContentValues = {
		setContentList,
		contentList,
		markAsFavoriteContent,
		socialPostList,
		setParam,
		param,
		handleContentDetails,
		showPostDetails,
		setShowPostDetails,
		tabType,
		selectContentList,
	};

	return (
		<CreatorContentContext.Provider value={creatorContentValues}>
			<ModalMediaDetail
				visible={!!socialDetail || !!content}
				contentProps={{
					setContentIdMove,
					content,
					onChangeAfterAction,
					updateAfterReviewingContent,
					folderId,
					updateAfterChangeVersion: handleUpdateNewVersion,
					markAsFavoriteContent,
					updateVerificationScrore,
					CDMBlockCreatorDisabled: true,
					isDashboardAmbassador: true,
				}}
				socialProps={{
					socialContent: socialDetail,
					activeSocialType: socialDetail?.socialSource?.toLowerCase() || '',
					updateMediaImgUrl,
					socialBlockCreatorDisable: true,
					pauseNextContentAfterImport: true,
					socialBookmarkDisable: true,
				}}
				isSocial={!content}
				isFirstContent={isFirstContent}
				isLastContent={isLastContent}
				moveContent={moveContent}
				onCancel={handleCloseContentDetails}
			/>
			<StyledContent>
				<Layout>
					<StyledWrapper>
						<StyledTabs
							activeKey={param.activeType}
							onTabClick={(tab: string) => changeFolder(tab)}
							tabBarExtraContent={renderExtraButton}>
							{renderFolderList()}
						</StyledTabs>
					</StyledWrapper>
				</Layout>
			</StyledContent>
		</CreatorContentContext.Provider>
	);
};

export default Content;
