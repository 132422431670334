import { removeTokenCookie } from './cookies';

/**
 * Return a value storaged in localStorage.
 * @param {String} key  key
 */
export function getValue(key: string) {
	return localStorage.getItem(key);
}

/**
 * Return a value storaged in localStorage.
 * @param {String} key  key
 * @param {String} data  Data as a string
 */
export function setValue(key: string, data: any) {
	return localStorage.setItem(key, data);
}

/**
 * Return an object from localStorage.
 * @param {String} key key
 */
export function getObject(key: string) {
	return JSON.parse(getValue(key) || '{}');
}

/**
 * Store an object into localStore.
 * @param {String} key  key
 * @param {Object} data  Object data to store
 */
export function setObject(key: string, data: any) {
	setValue(key, JSON.stringify(data));
}

/**
 * Remove a cached item by given key.
 * @param {String} key Key to remove
 */
export function remove(key: string) {
	localStorage.removeItem(key);
	return true;
}

export const removeAll = () => {
	removeTokenCookie();

	localStorage.clear();
};
