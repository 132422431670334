import { ImageSVG } from '@assets/icons/svg/grapesjs';

export const ImageBlock = (bm: any, label: string) => {
	bm.add('image', {
		label,
		media: ImageSVG,
		activate: true,
		content: {
			type: 'image',
		},
	});
};
