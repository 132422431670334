import { IconInfo } from '@assets/icons';
import { ANALYZED_CONTENT_OPTIONS } from '@constants/content/contentLibrary';
import MediaRecognitionModalContext from '@contexts/Content/MediaRecognitionModal';
import { Empty } from '@cores/index';
import { ColumnDto } from '@models/common/summary';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import {
	DetectionServiceDto,
	EstimatedServiceCostDto,
} from '@models/content/contentLibrary/summary';
import {
	StyledForm,
	StyledFormItem,
	StyledIcon,
	StyledRadioGroup,
	StyledSection,
	StyledText,
	StyledTooltip,
} from '@styled/Common/CommonStyled';
import {
	StyledChooseServices,
	StyledServiceTable,
} from '@styled/Content/ContentLibrary/MediaRecognitionModalStyled';
import { Radio, RadioChangeEvent } from 'antd';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ChooseServices = () => {
	const { t } = useTranslation();
	const { fetchingDetectionServiceList, detectionServiceList }: ContentStoreDto = useSelector(
		(state: any) => state.content,
	);
	const {
		selectedRowKeys,
		setSelectedRowKeys,
		estimatedCost,
		setAnalyzedOption,
		analyzedOption,
		detectionServiceDetail,
	} = useContext(MediaRecognitionModalContext);
	const { numberOfImages = 0, numberOfVideos = 0 } = detectionServiceDetail;

	const renderServiceValues = (
		_: number | string,
		record: DetectionServiceDto,
		isToBeProcessed?: boolean,
	) => {
		const { detectionServiceEstimateCost = [] } = detectionServiceDetail || {};
		const service = detectionServiceEstimateCost.find(
			(item: EstimatedServiceCostDto) => item.id === record.id,
		);

		return isToBeProcessed ? (
			service?.contentIds.length
		) : (
			<StyledText>${service?.estimateCost.toFixed(3) || 0}</StyledText>
		);
	};

	const servicesColumn: ColumnDto[] = [
		{
			title: t('content_management.media_recognition.column.image_service'),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t('content_management.media_recognition.column.to_be_processed'),
			dataIndex: 'type',
			key: 'type',
			render: (type: string, record: DetectionServiceDto) =>
				renderServiceValues(type, record, true),
			align: 'center',
		},
		{
			title: t('content_management.media_recognition.column.estimated_cost'),
			dataIndex: 'unitCost',
			key: 'unitCost',
			render: (unitCost: number, record: DetectionServiceDto) =>
				renderServiceValues(unitCost, record),
			align: 'center',
		},
		{
			title: '',
			key: 'actions',
			render: (record: DetectionServiceDto) => renderTooltip(record),
		},
	];

	const optionList = [
		{
			key: ANALYZED_CONTENT_OPTIONS.SKIP_CONTENT_ANALYZED,
			value: ANALYZED_CONTENT_OPTIONS.SKIP_CONTENT_ANALYZED,
			label: t('content_management.media_recognition.skip_content_analyzed'),
		},
		{
			key: ANALYZED_CONTENT_OPTIONS.ALL_CONTENTS,
			value: ANALYZED_CONTENT_OPTIONS.ALL_CONTENTS,
			label: t('content_management.media_recognition.all_selected_content'),
		},
	];

	const renderTooltip = (record: DetectionServiceDto) => {
		const { unitCost } = record;
		const isVideoService = record.type !== ANALYZED_CONTENT_OPTIONS.LOGO_DETECTION;
		return (
			<StyledTooltip
				title={() => {
					return (
						<>
							<StyledText
								dangerouslySetInnerHTML={{
									__html: t(
										`content_management.media_recognition.${
											isVideoService ? 'video' : 'image'
										}_service_used`,
										{
											href: record.reference,
										},
									),
								}}
							/>
							<StyledText>
								{t(
									`content_management.media_recognition.${
										isVideoService ? 'video_cost' : 'image_cost'
									}`,
									{
										unitCost,
									},
								)}
							</StyledText>
						</>
					);
				}}
				placement="right">
				<StyledIcon className="light_icon_hover">
					<IconInfo />
				</StyledIcon>
			</StyledTooltip>
		);
	};

	const renderOptions = () => {
		return (
			<>
				<StyledText margin="16px 0">
					{t('content_management.media_recognition.selected_content_desc', {
						numberOfImages,
						imageSuffix: numberOfImages === 1 ? '' : 's',
						numberOfVideos,
						videoSuffix: numberOfVideos === 1 ? '' : 's',
					})}
				</StyledText>
				<StyledForm>
					<StyledFormItem name="analyzedOption">
						<StyledRadioGroup
							className="block"
							defaultValue={analyzedOption}
							onChange={(changedValue: RadioChangeEvent) => {
								const { value } = changedValue.target;
								setAnalyzedOption(value);
							}}>
							{optionList.map((item: any) => {
								return (
									<Radio key={item.value} value={item.value}>
										{item.label}
									</Radio>
								);
							})}
						</StyledRadioGroup>
					</StyledFormItem>
				</StyledForm>
			</>
		);
	};

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const onSelectAllChange = () => {};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		onSelectAll: onSelectAllChange,
	};

	const renderServices = () => {
		return (
			<StyledSection margin="12px 0">
				<StyledText margin=" 0 0 12px 0" fontSize="16px">
					{t('content_management.media_recognition.choose_image_services')}
				</StyledText>
				<StyledServiceTable
					dataSource={detectionServiceList}
					columns={servicesColumn}
					locale={{ emptyText: <Empty /> }}
					rowSelection={rowSelection}
					pagination={false}
					loading={fetchingDetectionServiceList}
					rowKey="id"
				/>
			</StyledSection>
		);
	};

	return (
		<StyledChooseServices>
			{renderOptions()}
			{renderServices()}
		</StyledChooseServices>
	);
};

export default ChooseServices;
