import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Skeleton, Spin } from 'antd';

// Model
import { EventDto, PagingCreatorDto } from '@models/messages/log';

// Components
import CreatorItem from '@components/Messages/MessageLog/Logs/CreatorItem';
import CreatorSegmentList from '@components/Messages/MessageLog/Logs/SegmentList/CreatorSegmentList';
import CreatorNoSegmentList from '@components/Messages/MessageLog/Logs/SegmentList/CreatorNoSegmentList';

// Styled
import {
	StyledCollapse,
	StyledText,
	StyledSegmentsWrapper,
} from '@styled/Messages/MessageLogStyled';

// Redux
import { getEventCreator } from '@stores/actions';
import { EMAIL_LOG } from '@constants/messages';
import { StyledFlex } from '@styled/Settings/SocialSearchStyled';
import { useEffect, useState } from 'react';

type SegmentListProps = { data: EventDto };

const { Panel } = Collapse;

const keyNoSegments = 'no-segment';

const SegmentList = (props: SegmentListProps) => {
	const dispatch = useDispatch();

	const { data } = props;
	const { id = 0, segments = [], pagingCreator = {}, body = '', updateAt = 0 } = data;

	const [activeKey, setActiveKey] = useState<string[]>([]);

	const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

	const onClickPanel = (segmentId?: number | string | undefined) => {
		const payload: any = {
			page: EMAIL_LOG.DEFAULT_PAGE,
			pageRecords: EMAIL_LOG.DEFAULT_PAGE_RECORD,
			id,
			segmentId,
		};

		getCreator(payload);
	};

	const onLoadMore = (payload: any) => {
		getCreator(payload);
	};

	const getCreator = (payload: any) => {
		const tempPayload = { ...payload };

		Object.keys(tempPayload).forEach(
			(key) => tempPayload.segmentId === undefined && delete tempPayload.segmentId,
		);

		dispatch(getEventCreator(tempPayload));
	};

	const renderSegment = () =>
		!Array.isArray(segments)
			? null
			: segments.map((segment: any, index: number) => {
					return (
						<Panel
							header={
								<StyledFlex onClick={() => onClickPanel(segment.segmentId)}>
									<StyledText weight={600}>{segment.segmentTitle}</StyledText>
								</StyledFlex>
							}
							key={segment.segmentId.toString()}>
							<CreatorSegmentList
								showPreviewFirstCreator={
									activeKey?.length === 1 && activeKey[0] !== keyNoSegments
								}
								onLoadMore={onLoadMore}
								data={{ ...segment, body, segments, updateAt, eventId: id }}
							/>
						</Panel>
					);
			  });

	const renderNoSegment = () => {
		const { creators = [] } = pagingCreator;
		return (
			pagingCreator &&
			creators && (
				<Panel
					header={
						<StyledText weight={600} onClick={() => onClickPanel()}>
							No Segment
						</StyledText>
					}
					key={keyNoSegments}>
					<CreatorNoSegmentList
						showPreviewFirstCreator={
							activeKey?.length === 1 && activeKey[0] === keyNoSegments
						}
						onLoadMore={onLoadMore}
						data={{ pagingCreator, body, segments, updateAt, eventId: id }}
					/>
				</Panel>
			)
		);
	};

	const onChangeCollapse = (val: any) => {
		setActiveKey(val);
	};

	useEffect(() => {
		if (isFirstRender) {
			if (Array.isArray(segments) && segments?.length > 0) {
				if (activeKey[0] !== segments[0]?.segmentId.toString()) {
					setIsFirstRender(false);
					setActiveKey([segments[0]?.segmentId.toString()]);
					onClickPanel(segments[0]?.segmentId);
				}
			} else if (pagingCreator && activeKey[0] !== keyNoSegments) {
				setActiveKey([keyNoSegments]);
				setIsFirstRender(false);
				onClickPanel();
			}
		}
	}, [segments, pagingCreator]);

	return (
		<StyledSegmentsWrapper
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}>
			<StyledCollapse
				activeKey={activeKey}
				expandIconPosition="right"
				onChange={onChangeCollapse}
				isEvent={false}>
				{renderSegment()}
				{pagingCreator && renderNoSegment()}
			</StyledCollapse>
		</StyledSegmentsWrapper>
	);
};

export default SegmentList;
