import { useSelector } from 'react-redux';

// Components
import Email from '@components/Messages/MessageLog/Logs/EmailList/Email';

// Model
import { EventDto } from '@models/messages/log';

type EmailListProps = {
	onEditEmail: () => void;
};

const EmailList = (props: EmailListProps) => {
	const { onEditEmail } = props;
	const { emailLog } = useSelector((state: any) => state.messages);
	const { events = [] } = emailLog;

	return (
		<>
			{events.map((email: EventDto) => (
				<Email onEditEmail={onEditEmail} data={email} />
			))}
		</>
	);
};

export default EmailList;
