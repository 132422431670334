import { IconKeyboardArrowRight } from '@assets/icons';
import { CustomFieldsContentDto, ContentDto } from '@models/content/contentLibrary/summary';
import { StyledIcon, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledCollapse,
	StyledCollapseInfo,
	SwapperSessionDetail,
} from '@styled/ContentLibrary/ContentDetailStyled';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AdditionalInfo from '../AdditionalInfo';

const { Panel } = StyledCollapse;

type PropTypes = {
	customFields: CustomFieldsContentDto[];
};

const CustomFieldSession = (props: PropTypes) => {
	const { t } = useTranslation();
	const { customFields } = props;

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo>
				<StyledTitle className="unset_height">
					{t('gallery_manager.modal.custom_fields')}
				</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	return (
		<SwapperSessionDetail>
			<StyledCollapse
				className="collapse_content_detail"
				expandIconPosition="right"
				style={{ marginBottom: '0' }}
				defaultActiveKey={'1'}>
				<Panel header={renderHeaderPanel()} key={'1'}>
					{customFields?.length > 0 && (
						<AdditionalInfo
							style={{ marginBottom: '0' }}
							additionalFields={customFields}
						/>
					)}
				</Panel>
			</StyledCollapse>
		</SwapperSessionDetail>
	);
};

export default CustomFieldSession;
