import { AmbassadorManagerStoreDto, AmbassadorPayloadDto } from '@models/ambassador/stores';
import { Action, handleActions } from 'redux-actions';
import { ambassadorTypes } from './ambassador.types';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { STATUSCODE } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { DEFAULT_MEDIA } from '@constants/social_search';
import { AMBASSADOR_METRIC_DEFAULT } from '@constants/ambassador';

const initialState: AmbassadorManagerStoreDto = {
	loadingAmbassadorPost: false,
	loadingAmbassadorLeaderBoard: false,
	loadingAmbassadorMetric: false,
	loadingAmbassadorGraphPosts: false,
	loadingAmbassadorGraphVotes: false,
	loadingAmbassadorReport: false,
	loadingRefreshMetricAmbassador: false,
	posts: {
		...DEFAULT_MEDIA,
	},
	leaderBoards: [],
	ambassadorMetric: { ...AMBASSADOR_METRIC_DEFAULT },
	graphPosts: [],
	graphVotes: [],
	base64Report: '',
	error: '',
};

// get ambassador posts
const getAmbassadorPostsRequest = (state: AmbassadorManagerStoreDto) => ({
	...state,
	loadingAmbassadorPost: true,
});

const getAmbassadorPostsSucceeded = (
	state: AmbassadorManagerStoreDto,
	{ payload }: Action<AmbassadorPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: posts = DEFAULT_MEDIA,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingAmbassadorPost: false,
			posts,
			error: null,
		};
	}

	return {
		...state,
		loadingAmbassadorPost: false,
		posts,
		error: status,
	};
};

const getAmbassadorPostsFailed = (
	state: AmbassadorManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingAmbassadorPost: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingAmbassadorPost: false,
	};
};

// get ambassador leader board
const getAmbassadorLeaderBoardRequest = (state: AmbassadorManagerStoreDto) => ({
	...state,
	loadingAmbassadorLeaderBoard: true,
});

const getAmbassadorLeaderBoardSucceeded = (
	state: AmbassadorManagerStoreDto,
	{ payload }: Action<AmbassadorPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: leaderBoards = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingAmbassadorLeaderBoard: false,
			leaderBoards,
			error: null,
		};
	}

	return {
		...state,
		loadingAmbassadorLeaderBoard: false,
		leaderBoards,
		error: status,
	};
};

const getAmbassadorLeaderBoardFailed = (
	state: AmbassadorManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingAmbassadorLeaderBoard: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingAmbassadorLeaderBoard: false,
	};
};

// get ambassador metric
const getAmbassadorMetricRequest = (state: AmbassadorManagerStoreDto) => ({
	...state,
	loadingAmbassadorMetric: true,
});

const getAmbassadorMetricSucceeded = (
	state: AmbassadorManagerStoreDto,
	{ payload }: Action<AmbassadorPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: ambassadorMetric = { ...AMBASSADOR_METRIC_DEFAULT },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingAmbassadorMetric: false,
			ambassadorMetric,
			error: null,
		};
	}

	return {
		...state,
		loadingAmbassadorMetric: false,
		ambassadorMetric,
		error: status,
	};
};

const getAmbassadorMetricFailed = (
	state: AmbassadorManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingAmbassadorMetric: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingAmbassadorMetric: false,
	};
};

// get ambassador graph posts
const getAmbassadorGraphPostsRequest = (state: AmbassadorManagerStoreDto) => ({
	...state,
	loadingAmbassadorGraphPosts: true,
	graphPosts: [],
});

const getAmbassadorGraphPostsSucceeded = (
	state: AmbassadorManagerStoreDto,
	{ payload }: Action<AmbassadorPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	const graphPosts = (result as any)?.graph || [];

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingAmbassadorGraphPosts: false,
			graphPosts,
			error: null,
		};
	}

	return {
		...state,
		loadingAmbassadorGraphPosts: false,
		graphPosts,
		error: status,
	};
};

const getAmbassadorGraphPostsFailed = (
	state: AmbassadorManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingAmbassadorGraphPosts: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingAmbassadorGraphPosts: false,
	};
};

// get ambassador graph votes
const getAmbassadorGraphVotesRequest = (state: AmbassadorManagerStoreDto) => ({
	...state,
	loadingAmbassadorGraphVotes: true,
	graphVotes: [],
});

const getAmbassadorGraphVotesSucceeded = (
	state: AmbassadorManagerStoreDto,
	{ payload }: Action<AmbassadorPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	const graphVotes = (result as any)?.graph || [];
	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingAmbassadorGraphVotes: false,
			graphVotes,
			error: null,
		};
	}

	return {
		...state,
		loadingAmbassadorGraphVotes: false,
		graphVotes,
		error: status,
	};
};

const getAmbassadorGraphVotesFailed = (
	state: AmbassadorManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingAmbassadorGraphVotes: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingAmbassadorGraphVotes: false,
	};
};

// get ambassador report
const getAmbassadorReportRequest = (state: AmbassadorManagerStoreDto) => ({
	...state,
	loadingAmbassadorReport: true,
});

const getAmbassadorReportSucceeded = (
	state: AmbassadorManagerStoreDto,
	{ payload }: Action<AmbassadorPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: base64Report = '',
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingAmbassadorReport: false,
			base64Report,
			error: null,
		};
	}

	return {
		...state,
		loadingAmbassadorReport: false,
		base64Report,
		error: status,
	};
};

const getAmbassadorReportFailed = (
	state: AmbassadorManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingAmbassadorReport: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingAmbassadorReport: false,
	};
};

// refresh metric ambassador
const refreshMetricAmbassadorRequest = (state: AmbassadorManagerStoreDto) => ({
	...state,
	loadingRefreshMetricAmbassador: true,
});

const refreshMetricAmbassadorSucceeded = (
	state: AmbassadorManagerStoreDto,
	{ payload }: Action<AmbassadorPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingRefreshMetricAmbassador: false,
			error: null,
		};
	}

	return {
		...state,
		loadingRefreshMetricAmbassador: false,
		error: status,
	};
};

const refreshMetricAmbassadorFailed = (
	state: AmbassadorManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingRefreshMetricAmbassador: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingRefreshMetricAmbassador: false,
	};
};

// reset fields ambassador
const resetFieldsAmbassadorData = (state: AmbassadorManagerStoreDto, { payload }: Action<any>) => ({
	...state,
	...payload,
});

const ambassadorReducers = handleActions<any>(
	{
		[ambassadorTypes.GET_AMBASSADOR_POSTS_REQUEST]: getAmbassadorPostsRequest,
		[ambassadorTypes.GET_AMBASSADOR_POSTS_SUCCESS]: getAmbassadorPostsSucceeded,
		[ambassadorTypes.GET_AMBASSADOR_POSTS_FAILED]: getAmbassadorPostsFailed,

		[ambassadorTypes.GET_AMBASSADOR_LEADER_BOARD_REQUEST]: getAmbassadorLeaderBoardRequest,
		[ambassadorTypes.GET_AMBASSADOR_LEADER_BOARD_SUCCESS]: getAmbassadorLeaderBoardSucceeded,
		[ambassadorTypes.GET_AMBASSADOR_LEADER_BOARD_FAILED]: getAmbassadorLeaderBoardFailed,

		[ambassadorTypes.GET_AMBASSADOR_METRIC_REQUEST]: getAmbassadorMetricRequest,
		[ambassadorTypes.GET_AMBASSADOR_METRIC_SUCCESS]: getAmbassadorMetricSucceeded,
		[ambassadorTypes.GET_AMBASSADOR_METRIC_FAILED]: getAmbassadorMetricFailed,

		[ambassadorTypes.GET_AMBASSADOR_GRAPH_POSTS_REQUEST]: getAmbassadorGraphPostsRequest,
		[ambassadorTypes.GET_AMBASSADOR_GRAPH_POSTS_SUCCESS]: getAmbassadorGraphPostsSucceeded,
		[ambassadorTypes.GET_AMBASSADOR_GRAPH_POSTS_FAILED]: getAmbassadorGraphPostsFailed,

		[ambassadorTypes.GET_AMBASSADOR_GRAPH_VOTES_REQUEST]: getAmbassadorGraphVotesRequest,
		[ambassadorTypes.GET_AMBASSADOR_GRAPH_VOTES_SUCCESS]: getAmbassadorGraphVotesSucceeded,
		[ambassadorTypes.GET_AMBASSADOR_GRAPH_VOTES_FAILED]: getAmbassadorGraphVotesFailed,

		[ambassadorTypes.GET_AMBASSADOR_REPORT_REQUEST]: getAmbassadorReportRequest,
		[ambassadorTypes.GET_AMBASSADOR_REPORT_SUCCESS]: getAmbassadorReportSucceeded,
		[ambassadorTypes.GET_AMBASSADOR_REPORT_FAILED]: getAmbassadorReportFailed,

		[ambassadorTypes.REFRESH_METRIC_AMBASSADOR_REQUEST]: refreshMetricAmbassadorRequest,
		[ambassadorTypes.REFRESH_METRIC_AMBASSADOR_SUCCESS]: refreshMetricAmbassadorSucceeded,
		[ambassadorTypes.REFRESH_METRIC_AMBASSADOR_FAILED]: refreshMetricAmbassadorFailed,

		[ambassadorTypes.RESET_FIELDS_AMBASSADOR]: resetFieldsAmbassadorData,
	},
	initialState,
);

export default ambassadorReducers;
