import {
	IconArrowLine,
	IconClose,
	IconNoneNotification,
	IconNotification,
	IconTriangleWarning,
} from '@assets/icons';
import notification from '@assets/images/notification.png';
import { mediaHubURL } from '@config/index';
import { API_CHECK_TOP_RATE, STATUSCODE } from '@constants/APIs';
import { ROUTE_PATH } from '@constants/common';
import { DEFAULT_ALBUM_TOP_RATE } from '@constants/content/albums';
import {
	DEFAULT_TASK_NOTIFICATION_PAYLOAD,
	NOTIFICATION_ELEMENT_SELECTOR,
	NOTIFICATION_TAB,
	NotificationType,
	NotifyGroupType,
} from '@constants/notification';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import NotificationContext from '@contexts/Notification';
import LoadingWrapper from '@cores/LoadingWrapper';
import { getRequest } from '@helpers/requestHelpers';
import { getUser } from '@helpers/userHelpers';
import { TabPaneDto } from '@models/common/summary';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import {
	GroupNotifyDto,
	NotificationMessageDto,
	NotificationStoreDto,
} from '@models/notification/stores';
import { TaskNotificationPayloadDto } from '@models/notification/summary';
import {
	changeFieldsClientSetting,
	changeFieldsStoreNotifyData,
	createAlbumEnd,
	createAlbumRequest,
	getTaskNotificationRequest,
	updateStatusNotificationRequest,
	updateStatusRecentlyUpdatedRequest,
} from '@stores/actions';
import {
	StyledFlex,
	StyledIconWrapper,
	StyledLinkHref,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledDivider } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	StyledDirectLink,
	StyledWrapperIconClose,
} from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledContentSubType,
	StyledGroupNotify,
	StyledIcon,
	StyledMaskPopover,
	StyledNotification,
	StyledNotificationContent,
	StyledNotificationMessage,
	StyledNotificationNum,
	StyledNotificationNumBackground,
	StyledNotificationNumWrapper,
	StyledNotificationPicture,
	StyledNotificationTab,
	StyledNotificationTextReadAll,
	StyledNotificationWrapper,
	StyledNotificationWrapperMessage,
	StyledPopover,
	StyledSubType,
	StyledTextNotification,
	StyledTitleGroupNotify,
	StyledWrapperIconBg,
	StyledWrapperListMessage,
	StyledWrapperMessage,
} from '@styled/Layout/Notify';
import { StyledAnimationRing } from '@styled/Layout/StyledLayout';
import { encode, handleRedirectLink } from '@utils/common';
import { useScroll, useScrollToLoadMore } from '@utils/customHooks';
import { Tabs, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import TaskNotification from './TaskNotification';

const Notification = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { pathname } = location;

	const user = getUser();

	const {
		allMessage = [],
		hasNotification = false,
		hasTaskNotification = false,
		updateNotificationStatus,
		limitNotificationMsg = '',
		loadingNotify,
		fetchingTaskNotification = false,
		toggleTaskNotificationStatus = NONE,
		markTaskNotificationAsReadStatus = NONE,
		taskNotificationData,
	}: NotificationStoreDto = useSelector((state: any) => state.notification);
	const { statusCreateAlbum }: AlbumsStoreDto = useSelector((state: any) => state.albumManager);

	const [isLoadingNotify, setIsLoadingNotify] = useState<boolean>(false);
	const [visible, setVisible] = useState(false);
	const [isCreateAlbumNotify, setIsCreateAlbumNotify] = useState<boolean>(false);
	const [requestPayload, setRequestPayload] = useState<TaskNotificationPayloadDto>();
	const [activeTab, setActiveTab] = useState<string>(NOTIFICATION_TAB.MENTIONS);
	const notificationWrapperEl = document.getElementById(
		NOTIFICATION_ELEMENT_SELECTOR.TASK_NOTIFICATION_WRAPPER_ID,
	);
	const [showHeaderNotification, setShowHeaderNotification] = useState<boolean>(false);

	const handleLoadMore = () => {
		const notificationLength = taskNotificationData?.currentPageNotifications?.length || 0;
		if (
			!fetchingTaskNotification &&
			notificationLength > 0 &&
			!taskNotificationData?.isShowAll &&
			activeTab === NOTIFICATION_TAB.MENTIONS
		) {
			if (requestPayload && requestPayload.page) {
				setRequestPayload({ ...requestPayload, page: requestPayload.page + 1 });
			}
		}
	};

	const handleScroll = (scrollBarPosition: number) => {
		if (scrollBarPosition >= 80) {
			// Show UI
			setShowHeaderNotification(true);
		} else {
			setShowHeaderNotification(false);
		}
	};

	useScroll(notificationWrapperEl, handleScroll, [activeTab, notificationWrapperEl]);

	useScrollToLoadMore(notificationWrapperEl, handleLoadMore, [
		taskNotificationData,
		fetchingTaskNotification,
		activeTab,
	]);

	useEffect(() => {
		if (visible && activeTab === NOTIFICATION_TAB.MENTIONS) {
			setRequestPayload(DEFAULT_TASK_NOTIFICATION_PAYLOAD);
		}

		return () => {
			setRequestPayload(undefined);
			setShowHeaderNotification(false);
			dispatch(
				changeFieldsStoreNotifyData({
					taskNotificationData: null,
				}),
			);
			if (!visible) {
				setActiveTab(NOTIFICATION_TAB.MENTIONS);
			}
		};
	}, [visible, activeTab]);

	useEffect(() => {
		fetchTaskNotifications();
	}, [requestPayload]);

	useEffect(() => {
		if (isCreateAlbumNotify) {
			const listStatus = [statusCreateAlbum];
			if (listStatus?.includes(SUCCEEDED) || listStatus?.includes(FAILED)) {
				dispatch(createAlbumEnd());
				setIsCreateAlbumNotify(false);
				setIsLoadingNotify(false);
			}
		}
	}, []);

	useEffect(() => {
		if (isCreateAlbumNotify) {
			const listStatus = [statusCreateAlbum];
			if (listStatus?.includes(SUCCEEDED) || listStatus?.includes(FAILED)) {
				dispatch(createAlbumEnd());
				setIsCreateAlbumNotify(false);
				setIsLoadingNotify(false);
			}
		}
	}, [statusCreateAlbum]);

	useEffect(() => {
		const notifyRefreshMetric = allMessage?.find(
			(item) => item?.group === NotifyGroupType.REFRESH_METRICS,
		);
		if (notifyRefreshMetric) {
			dispatch(
				changeFieldsClientSetting({
					ambassadorMetricsRefreshUpdateAt: notifyRefreshMetric.data[0].updateAt,
					ambassadorMetricsRefreshInProgress: false,
				}),
			);
			handleMessageRefreshMetric(notifyRefreshMetric);
		}
	}, [allMessage]);

	useEffect(() => {
		if (pathname.includes(ROUTE_PATH.WIDGET_MANAGER)) {
			allMessage.forEach((item: GroupNotifyDto) => {
				if (item.group === NotifyGroupType.COLLECT) {
					const itemWidget = item?.data?.find(
						(notify) => notify?.type === NotificationType.WIDGET_PUBLISHED,
					);

					if (itemWidget && itemWidget?.widgetIds?.length > 0) {
						dispatch(updateStatusRecentlyUpdatedRequest(itemWidget.widgetIds));
					}
				}
			});
		}
	}, [pathname, allMessage]);

	const fetchTaskNotifications = () => {
		if (requestPayload) {
			dispatch(
				getTaskNotificationRequest({
					...requestPayload,
				}),
			);
		}
	};

	const handleMessageRefreshMetric = (notifyRefreshMetric: GroupNotifyDto) => {
		const handleCloseMessage = () => {
			message.destroy('refresh_metric_ambassador_dashboard');
		};
		const handleDirectDashboard = () => {
			handleCloseMessage();

			history.push(ROUTE_PATH.AMBASSADOR_DASHBOARD);
		};

		const propsMessage: any = {
			i18nKey: notifyRefreshMetric?.data[0]?.message || '',
			components: {
				linkTo: <StyledDirectLink onClick={handleDirectDashboard} />,
				wrapperIcon: <StyledWrapperIconClose />,
				icon: (
					<IconClose
						onClick={handleCloseMessage}
						className="ico_close"
						style={{
							height: '16px',
							width: '16px',
							position: 'absolute',
							top: '2px',
							left: 0,
							cursor: 'pointer',
						}}
					/>
				),
			},
		};
		message.info({
			content: <Trans t={t} {...propsMessage} />,
			duration: 10,
			key: 'refresh_metric_ambassador_dashboard',
		});
		const messageChange = allMessage?.filter(
			(item) => item?.group !== NotifyGroupType.REFRESH_METRICS,
		);
		dispatch(changeFieldsStoreNotifyData({ allMessage: messageChange }));
	};

	const handleUpdateStatusNotify = ({ id, isAll }: { id?: number; isAll?: boolean }) => {
		const payload: any = {};
		if (isAll) {
			payload.isAll = isAll;
		} else {
			payload.id = id;
		}
		dispatch(updateStatusNotificationRequest(payload));
	};

	const propsNotification = {
		i18nKey: 'notification.limit_content',
		components: {
			linkTo: <StyledLinkHref color={THEME.colors.blueBase} />,
		},
	};

	const handleClickMessageNotification = (item: NotificationMessageDto) => {
		const { id, type, creatorIds, contentIds, widgetIds } = item;
		if (id) {
			handleUpdateStatusNotify({ id });
		}
		setVisible(false);

		let path = '';
		let arrId: number[] | undefined;
		switch (type) {
			case NotificationType.WIDGET_PUBLISHED:
				path = ROUTE_PATH.WIDGET_MANAGER;
				arrId = widgetIds;
				break;
			case NotificationType.DIRECT_UPLOADED:
				arrId = contentIds;
				path = ROUTE_PATH.SUBMISSIONS;
				break;
			case NotificationType.SELECT_IMPORTED:
				arrId = contentIds;
				path = ROUTE_PATH.BOOKMARKS;
				break;
			case NotificationType.ALBUM_CREATED:
				arrId = [];
				path = ROUTE_PATH.ALBUMS;
				break;
			case NotificationType.CONTENT_ASSIGNED:
				path = ROUTE_PATH.GALLERY;
				arrId = contentIds;
				break;
			case NotificationType.CREATOR_ADDED:
				path = ROUTE_PATH.CREATORS_SUMMARY;
				arrId = creatorIds;
				message.destroy('message_mark_all_creator');
				break;

			default:
				break;
		}

		history.push({
			pathname: path,
			state: {
				arrayId: arrId,
			},
		});
	};

	const renderSubType = (type: string) => {
		let result = '';
		switch (type) {
			case NotificationType.WIDGET_PUBLISHED:
				result = t('notification.view_uploader');
				break;
			case NotificationType.DIRECT_UPLOADED:
				result = t('notification.view_submissions');
				break;
			case NotificationType.SELECT_IMPORTED:
				result = t('notification.go_bookmarks');
				break;
			case NotificationType.ALBUM_CREATED:
				result = t('notification.go_albums');
				break;
			case NotificationType.CONTENT_ASSIGNED:
				result = t('notification.go_galleries');
				break;
			case NotificationType.CREATOR_ADDED:
				result = t('notification.go_crm');
				break;
			case NotificationType.TOP_RATE:
				result = t('notification.preview_save_album');
				break;
			default:
				break;
		}
		return result;
	};

	const handleCreateAlbumTopRate = (
		notify: NotificationMessageDto,
		func: (val: number) => void,
	) => {
		const dataRequest = {
			...DEFAULT_ALBUM_TOP_RATE,
			metadata: {
				...DEFAULT_ALBUM_TOP_RATE.metadata,
				filterId: notify?.topRatedDTO?.filterId,
			},
		};
		setIsCreateAlbumNotify(true);
		dispatch(
			createAlbumRequest({
				...dataRequest,
				hideMessage: true,
				callback: (album: any) => {
					func(album?.id);
				},
				metadata: JSON.stringify(dataRequest.metadata),
			}),
		);
	};

	const handleSaveAlbum = async (notify: NotificationMessageDto) => {
		setIsLoadingNotify(true);
		handleUpdateStatusNotify({ id: notify.id });
		const redirectAlbum = (id: number) => {
			setVisible(false);
			setIsLoadingNotify(false);
			history.push(`${ROUTE_PATH.ALBUMS}/${id}`);
		};
		const albumTopRate = await handleGetAlbumTopRate();
		if (albumTopRate) {
			redirectAlbum(albumTopRate?.id);
		}
		if (albumTopRate === null) {
			handleCreateAlbumTopRate(notify, redirectAlbum);
		}
	};

	const handleDirectAlbum = (url: string) => {
		message.destroy('message_redirect_album');
		handleRedirectLink(url);
	};

	const handleShareAlbum = async (notify: NotificationMessageDto) => {
		handleUpdateStatusNotify({ id: notify.id });
		setIsLoadingNotify(true);
		const shareAlbum = (id: number) => {
			setVisible(false);
			setIsLoadingNotify(false);
			const input = document.createElement('input');
			const urlCopy = `${mediaHubURL}/${user?.clientId}/${encode(
				user?.brandName,
			)}/${id}?source=${encode(id?.toString())}`;

			const propsMessage: any = {
				values: { text: t('albums.message.preview_shared_album') },
				i18nKey: 'albums.message.copy_albums_to_clipboard',
				components: {
					linkTo: <StyledDirectLink onClick={() => handleDirectAlbum(urlCopy)} />,
				},
			};

			input.value = urlCopy;
			document.body.appendChild(input);
			input.select();
			document.execCommand('Copy');
			input.remove();
			message.success({
				content: <Trans t={t} {...propsMessage} />,
				duration: 3,
				key: 'message_redirect_album',
			});
		};

		const albumTopRate = await handleGetAlbumTopRate();

		if (albumTopRate) {
			shareAlbum(albumTopRate?.id);
		}
		if (albumTopRate === null) {
			handleCreateAlbumTopRate(notify, shareAlbum);
		}
	};

	const handleGetAlbumTopRate = async () => {
		const res = await getRequest(API_CHECK_TOP_RATE);
		const {
			status: { code, status },
			result,
		} = res.body.data;
		if (code !== STATUSCODE.SUCCESS) {
			message.error(status);
		} else {
			return result;
		}
		setIsLoadingNotify(false);

		return undefined;
	};

	const handleViewMoreSuggest = (notify: NotificationMessageDto) => {
		handleUpdateStatusNotify({ id: notify.id });
		setVisible(false);
		history.push({ pathname: ROUTE_PATH.ALBUMS, state: { showAllSuggest: true } });
	};

	const renderListSubSuggest = (notify: NotificationMessageDto) => {
		return [
			{
				id: 1,
				text: t('notification.preview_save_album'),
				click: () => handleSaveAlbum(notify),
			},
			{
				id: 2,
				text: t('notification.share_album'),
				click: () => handleShareAlbum(notify),
			},
			{
				id: 3,
				text: t('notification.view_more_album_suggestions'),
				click: () => handleViewMoreSuggest(notify),
			},
		];
	};

	const renderContentSub = (text: string, key: string | number, click?: () => void) => {
		return (
			<StyledSubType key={key}>
				<StyledContentSubType
					onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
						if (click && typeof click === 'function') {
							e.preventDefault();
							e.stopPropagation();
							click();
						}
					}}
					className="sub_type">
					{text}
					<StyledIcon size={16} style={{ margin: '0 0 0 5px' }}>
						<IconArrowLine />
					</StyledIcon>
				</StyledContentSubType>
			</StyledSubType>
		);
	};

	const renderNotification = () => {
		return hasNotification ? (
			<div>
				<StyledNotificationContent>
					{allMessage.map((item: GroupNotifyDto) => {
						if (item.group === NotifyGroupType.REFRESH_METRICS) {
							return null;
						}
						return (
							<StyledGroupNotify key={item?.group}>
								<StyledTitleGroupNotify>{item?.group}</StyledTitleGroupNotify>
								<StyledWrapperListMessage>
									{item?.data?.map((notify) => (
										<StyledNotificationWrapperMessage
											className={
												notify?.type === NotificationType.TOP_RATE
													? 'hidden_hover'
													: ''
											}
											onClick={
												notify?.type === NotificationType.TOP_RATE
													? undefined
													: () => handleClickMessageNotification(notify)
											}
											key={notify?.id}>
											<StyledWrapperMessage>
												<StyledNotificationMessage
													className="text_message"
													dangerouslySetInnerHTML={{
														__html: `${notify.message}`,
													}}
												/>
												<StyledWrapperIconBg>
													<StyledNotificationNumWrapper
														onClick={(
															e: React.MouseEvent<HTMLDivElement>,
														) => {
															e.preventDefault();
															e.stopPropagation();
															handleUpdateStatusNotify({
																id: notify.id,
															});
														}}>
														<StyledNotificationNumBackground />
													</StyledNotificationNumWrapper>
												</StyledWrapperIconBg>
											</StyledWrapperMessage>
											{notify?.type !== NotificationType.TOP_RATE &&
												renderContentSub(
													renderSubType(notify?.type),
													notify?.id,
												)}
											{notify?.type === NotificationType.TOP_RATE &&
												renderListSubSuggest(notify)?.map((item) =>
													renderContentSub(
														item?.text,
														item?.id,
														item.click,
													),
												)}
										</StyledNotificationWrapperMessage>
									))}
								</StyledWrapperListMessage>
							</StyledGroupNotify>
						);
					})}
				</StyledNotificationContent>
			</div>
		) : (
			<StyledWrapperContent
				height="100%"
				display="flex"
				alignItems="center"
				justifyContent="center">
				<div>
					<StyledNotificationPicture src={notification} alt="" />
					<StyledNotificationTextReadAll>
						{t('content.read_all_notification')}
					</StyledNotificationTextReadAll>
				</div>
			</StyledWrapperContent>
		);
	};

	const renderHeaderNotification = () => {
		return (
			<StyledNotificationWrapper
				className={`${
					showHeaderNotification ? 'header_notification_others' : ''
				} header_notification_fixed`}>
				{hasNotification && (
					<StyledText
						className="hover"
						cursor="pointer"
						margin="0 16px 0 0"
						textDecoration="underline"
						onClick={() => handleUpdateStatusNotify({ isAll: true })}>
						{t('content.mark_all_as_read')}
					</StyledText>
				)}
			</StyledNotificationWrapper>
		);
	};

	const renderOtherNotifications = () => {
		return (
			<>
				{showHeaderNotification && (
					<StyledNotificationWrapper className="header_notification" height="52px">
						{renderHeaderNotification()}
					</StyledNotificationWrapper>
				)}
				<StyledNotificationWrapper
					id={NOTIFICATION_ELEMENT_SELECTOR.TASK_NOTIFICATION_WRAPPER_ID}
					subtractedHeight={showHeaderNotification ? 42 : 0}>
					{limitNotificationMsg && (
						<>
							<StyledFlex margin="0 0 12px 0" justify="unset">
								<StyledIconWrapper margin="0 16px 0 0" width="24px" height="24px">
									<IconTriangleWarning />
								</StyledIconWrapper>
								<StyledText fontWeight="bold" fontSize="16px">
									{t('notification.limit_content_head')}
								</StyledText>
							</StyledFlex>
							<StyledText fontWeight={400} fontSize="16px">
								{<Trans {...propsNotification} />}
							</StyledText>
							<StyledDivider />
						</>
					)}
					{renderNotification()}
					{renderHeaderNotification()}
				</StyledNotificationWrapper>
			</>
		);
	};

	const onCloseNotification = () => {
		setVisible(false);
	};

	const listTabPane = [
		{
			value: NOTIFICATION_TAB.MENTIONS,
			title: t('notification.mentions'),
			component: <TaskNotification onClose={onCloseNotification} />,
		},
		{
			value: NOTIFICATION_TAB.OTHERS,
			title: t('notification.other'),
			component: renderOtherNotifications(),
		},
	];

	const renderTabTitle = (item: TabPaneDto) => {
		return (
			<StyledFlex>
				<StyledText margin="0 8px 0 0">{item.title}</StyledText>
				{hasNotification && item.value === NOTIFICATION_TAB.OTHERS && (
					<StyledNotificationNum position="unset" />
				)}
			</StyledFlex>
		);
	};

	const contentNotification = () => {
		const isLoading =
			updateNotificationStatus === IN_PROGRESS || loadingNotify || isLoadingNotify;
		const isMentionLoading =
			fetchingTaskNotification ||
			toggleTaskNotificationStatus === IN_PROGRESS ||
			markTaskNotificationAsReadStatus === IN_PROGRESS;

		return (
			<StyledNotification>
				<StyledNotificationTab
					activeKey={activeTab}
					height="80vh"
					onTabClick={(tab: string) => {
						setActiveTab(tab);
					}}
					tabBarExtraContent={{
						left: (
							<StyledTextNotification>
								{t('content.notifications')}
							</StyledTextNotification>
						),
					}}>
					{listTabPane.map((item: TabPaneDto) => (
						<Tabs.TabPane tab={renderTabTitle(item)} key={item.value} closable={false}>
							<LoadingWrapper
								isLoading={
									(item.value === NOTIFICATION_TAB.OTHERS && isLoading) ||
									isMentionLoading
								}
								sizeLoading="10px">
								{activeTab === item.value && item.component}
							</LoadingWrapper>
						</Tabs.TabPane>
					))}
				</StyledNotificationTab>
			</StyledNotification>
		);
	};

	const contextValues = {
		visible,
		activeTab,
		requestPayload,
		setRequestPayload,
		showHeaderNotification,
	};

	return (
		<NotificationContext.Provider value={contextValues}>
			{visible && <StyledMaskPopover />}
			<StyledPopover
				placement="bottomLeft"
				trigger="click"
				zIndex={5555}
				visible={visible}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				onVisibleChange={setVisible}
				content={contentNotification}>
				{hasNotification || hasTaskNotification ? (
					<StyledAnimationRing>
						<IconNotification />
					</StyledAnimationRing>
				) : (
					<IconNoneNotification />
				)}
				{(hasNotification || hasTaskNotification) && <StyledNotificationNum />}
			</StyledPopover>
		</NotificationContext.Provider>
	);
};

export default Notification;
