import React, { useState, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, message } from 'antd';

import { UserDataDto } from '@models/user/user';
import { activeAccount, createAccount, renewInviteAccount } from '@stores/actions';

// Components
import { LoadingWrapper } from '@cores/index';
import { renderErrorMessagePwd } from '@utils/renderComponent';

// Import styled components
import {
	StyledLayout,
	StyledFormWrapper,
	StyledTitle,
	StyledButton,
	StyledLogo,
} from '@styled/Register/RegisterStyled';
import { removeAll } from '@helpers/storageHelpers';
import { PATTERNS, ROUTE_PATH } from '@constants/common';
import { StyledFormItem } from '@styled/ChangePasswordStyled';
import { ROLE } from '@constants/settings';
import { mediaHubURL } from '@config/index';
import { encode } from '@utils/common';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { ImageLogoTextWhite } from '@assets/images';
import { StyledHref } from '@styled/Content/WidgetManager/CurrentDesignStyled';

type RegisterProps = {
	computedMatch?: any;
};

type AccountInfoType = {
	firstName: string;
	lastName: string;
	email: string;
	brandName: string;
};

const ACTIVE_ERROR = {
	BLANK: 600,
	NOT_FOUND: 601,
	EXPIRED: 602,
};

const Register = (props: RegisterProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [userData, setUserData] = useState<UserDataDto>();
	const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
	const [isActiveAccount, setIsActiveAccount] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [activeType, setActiveType] = useState<number>(0);
	const [accountInfo, setAccountInfo] = useState<AccountInfoType | null>(null);

	const token = props.computedMatch.params.token;
	useEffect(() => {
		checkStatus();
		return () => {
			setAccountInfo(null);
		};
	}, []);

	const fillExistingData = (data: UserDataDto, disabled?: boolean) => {
		if (disabled) {
			setAccountInfo(data);
		}
		form.setFieldsValue({
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
		});
	};

	const checkStatus = () => {
		removeAll();

		const checkNewAccount = (data: UserDataDto) => {
			setUserData(data);
			fillExistingData(data);
			setIsActiveAccount(false);
		};

		const checkExistingAccount = (data: UserDataDto) => {
			message.success(
				t('register.message.join_client_successfully', { user_role: data.role }),
			);
			setUserData(data);
			fillExistingData(data, true);
			setIsActiveAccount(false);
			setIsDisabled(true);
		};

		const checkStatusFailed = (error: any) => {
			setActiveType(error);
			setIsActiveAccount(false);
		};

		const payload = {
			params: {
				token,
			},
			checkStatusFailed,
			checkNewAccount,
			checkExistingAccount,
		};

		setIsActiveAccount(true);
		dispatch(activeAccount(payload));
	};

	const onFinish = (value: any) => {
		const createAccountSucceeded = () => {
			setIsCreatingAccount(false);
			message.success(t('register.message.create_account_successfully'));
			setTimeout(() => {
				goToLogin();
			}, 2000);
		};

		const createAccountFailed = () => {
			setIsCreatingAccount(false);
			message.error(t('register.message.create_account_failed'));
		};

		const payload = {
			params: {
				alternativeToken: token,
				clientId: userData?.clientId,
				userData: [{ ...userData, ...value, username: value.email, status: 'active' }],
			},
			createAccountSucceeded,
			createAccountFailed,
		};

		setIsCreatingAccount(true);
		dispatch(createAccount(payload));
	};

	const href = useMemo(() => {
		if (userData) {
			const { role, clientId, brandName } = userData;

			if (role === ROLE.VIEWER.value) {
				return `${mediaHubURL}/${clientId}/${encode(brandName)}`;
			}
		}

		return ROUTE_PATH.LOGIN;
	}, [userData]);

	const goToLogin = () => {
		if (href === ROUTE_PATH.LOGIN) history.push(href);
		else {
			window.location.href = href;
		}
	};

	const onClickRenewInvite = () => {
		const renewInviteSucceeded = () => {
			message.success(t('register.message.renew_invite_success'));
		};

		const renewInviteFailed = () => {
			message.error(t('register.message.renew_invite_failed'));
		};

		const payload = {
			params: {
				token,
			},
			renewInviteSucceeded,
			renewInviteFailed,
		};

		dispatch(renewInviteAccount(payload));
	};

	const renderInvalidNotification = () => (
		<React.Fragment>
			<StyledTitle fontSize="16px" margin="0 0 32px 0">
				{t('register.invalid_link')}
			</StyledTitle>
			<StyledButton type="primary" onClick={goToLogin}>
				{t('button.close')}
			</StyledButton>
		</React.Fragment>
	);

	const renderExpiredNotifition = () => (
		<React.Fragment>
			<StyledTitle fontSize="24px" fontWeight="700" margin="0 0 32px 0">
				{t('register.sorry')}
			</StyledTitle>
			<StyledTitle fontSize="16px" margin="0 0 32px 0">
				{t('register.expired_link')}
			</StyledTitle>
			<StyledButton type="primary" onClick={onClickRenewInvite}>
				{t('button.renew')}
			</StyledButton>
		</React.Fragment>
	);

	const renderExistAccount = () => (
		<StyledWrapperContent textAlign="center">
			<StyledLogo>
				<img src={ImageLogoTextWhite} alt="logo" />
			</StyledLogo>
			<StyledTitle fontSize="24px" fontWeight="700" margin="24px 0 16px 0">
				{t('register.you_are_in')}
			</StyledTitle>
			<StyledTitle
				fontSize="16px"
				fontWeight="400"
				style={{ display: 'inline-block' }}
				margin="0 0 24px 0">
				<Trans
					t={t}
					values={{
						email: accountInfo?.email,
						account: accountInfo?.brandName,
					}}
					i18nKey={'register.message_success_add'}
					components={{
						linkTo: <StyledHref style={{ fontWeight: 'normal' }} />,
					}}
				/>
			</StyledTitle>
			<StyledButton type="primary" onClick={goToLogin}>
				{t('button.go_to_login')}
			</StyledButton>
		</StyledWrapperContent>
	);

	const renderForm = () => (
		<React.Fragment>
			<StyledTitle fontSize="24px" fontWeight="700" margin="0 0 30px 0">
				{t('register.welcome')}
			</StyledTitle>
			<StyledTitle fontSize="16px" margin="0 0 30px 0">
				{t('register.instruction')}
			</StyledTitle>

			<Form
				name="register-form"
				layout="vertical"
				requiredMark={false}
				form={form}
				onFinish={onFinish}
				autoComplete="off">
				<StyledFormItem
					label={t('register.first_name')}
					name="firstName"
					hasFeedback
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(
								t('register.validate.required_first_name'),
							),
						},
					]}>
					<Input disabled={isDisabled} />
				</StyledFormItem>

				<StyledFormItem
					label={t('register.last_name')}
					name="lastName"
					hasFeedback
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(
								t('register.validate.required_last_name'),
							),
						},
					]}>
					<Input disabled={isDisabled} />
				</StyledFormItem>

				<StyledFormItem
					label={t('register.email')}
					name="email"
					hasFeedback
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(t('register.validate.required_email')),
						},
					]}>
					<Input disabled={true} />
				</StyledFormItem>

				<StyledFormItem
					label={t('register.password')}
					name="password"
					hasFeedback={form.getFieldError('password') ? true : false}
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(
								t('register.validate.required_password'),
							),
						},
						{
							pattern: PATTERNS.PASSWORD,
							message: renderErrorMessagePwd(t('format_password')),
						},
					]}>
					<Input.Password disabled={isDisabled} />
				</StyledFormItem>

				<StyledFormItem
					label={t('register.confirm_password')}
					name="confirmPassword"
					hasFeedback
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(
								t('register.validate.required_confirm_password'),
							),
						},
						({ getFieldValue }: any) => ({
							validator(_: any, value: string) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(
									renderErrorMessagePwd(
										t('register.validate.password_not_match'),
									),
								);
							},
						}),
					]}>
					<Input.Password disabled={isDisabled} />
				</StyledFormItem>

				<StyledFormItem>
					{isDisabled ? (
						<StyledButton type="primary" onClick={goToLogin}>
							{t('button.go_to_login')}
						</StyledButton>
					) : (
						<StyledButton type="primary" htmlType="submit" loading={isCreatingAccount}>
							{t('button.create_account').toUpperCase()}
						</StyledButton>
					)}
				</StyledFormItem>
			</Form>
			<StyledTitle fontSize="16px">
				{t('register.already_have_account')}
				<a href={href}>{t('register.login')}</a>
			</StyledTitle>
		</React.Fragment>
	);

	const renderNotification = () => {
		if (activeType === ACTIVE_ERROR.BLANK) {
			return renderInvalidNotification();
		}
		if (activeType === ACTIVE_ERROR.NOT_FOUND) {
			return renderInvalidNotification();
		}
		if (activeType === ACTIVE_ERROR.EXPIRED) {
			return renderExpiredNotifition();
		}
		if (isDisabled) {
			return renderExistAccount();
		}
		return renderForm();
	};

	return (
		<StyledLayout>
			<StyledFormWrapper isLoading={isActiveAccount}>
				<LoadingWrapper isLoading={isActiveAccount}>
					{!isActiveAccount && renderNotification()}
				</LoadingWrapper>
			</StyledFormWrapper>
		</StyledLayout>
	);
};

export default React.memo(Register);
