import { LandingPageDto } from '@models/landingpagemanager';
import * as React from 'react';

export type LandingPageManagerContextType = {
	landingPageList: LandingPageDto[];
	currentLandingPage: LandingPageDto | null;
	setCurrentLandingPage: (landingPage: LandingPageDto) => void;
	renderStatusChip: (landingPage: LandingPageDto) => void;
	confirmationModalType: string;
	setConfirmationModalType: (action: string) => void;
	handleActionOnLandingPageItem: (landingPage: LandingPageDto, actionType: string) => void;
	setHandledLandingPageItem: (landingPage: LandingPageDto) => void;
	handleLoadMoreLandingPageList: () => void;
};

// Default context values
const values: LandingPageManagerContextType = {
	landingPageList: [],
	currentLandingPage: null,
	setCurrentLandingPage: () => {},
	renderStatusChip: () => {},
	confirmationModalType: '',
	setConfirmationModalType: () => {},
	handleActionOnLandingPageItem: () => {},
	setHandledLandingPageItem: () => {},
	handleLoadMoreLandingPageList: () => {},
};

const LandingPageManagerContext = React.createContext(values);
export default LandingPageManagerContext;
