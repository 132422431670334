import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Constant
import THEME from '@constants/themes/themes';

// Styled
import { TYPE_FORMAT_DATE } from '@constants/common';
import MessageLogContext from '@contexts/Messages/Logs';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { StyledText } from '@styled/Messages/MessageLogStyled';

type RecipientsProps = {};

const StyledWrapper = styled.div`
	background-color: ${THEME.colors.darkBlue2};
	height: 32px;
	padding: 5px 14px;
`;

const StyledEllipsis = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 90%;
`;

const Recipients = (props: RecipientsProps) => {
	const { t } = useTranslation();

	const { selectedEmail = {}, isSelectDraftEmail = false } = useContext(MessageLogContext);
	const { updateAt = 0, segments } = selectedEmail;

	const renderText = () => {
		let text = t('email_log.recipients.saved_on');

		if (!isSelectDraftEmail) {
			text = t('email_log.recipients.mail_sent_on');
		}
		if (!selectedEmail?.success) {
			text = t('email_log.recipients.attempted_to_send_on');
		}

		return text;
	};

	const renderRecipientData = () => {
		return updateAt !== 0 ? (
			<>
				{renderText()}
				<StyledText weight={700}>
					{convertUtcToLocalTimeWithFormat(updateAt, TYPE_FORMAT_DATE.TIME)}
				</StyledText>{' '}
				{Array.isArray(segments) &&
					`to ${segments.map((segment: any) => segment.segmentTitle).join(', ')}`}
			</>
		) : null;
	};

	return (
		<StyledWrapper>
			<StyledEllipsis>{renderRecipientData()}</StyledEllipsis>
		</StyledWrapper>
	);
};

export default Recipients;
