import React, { useEffect, useState } from 'react';
import {
	StyledTag,
	StyledSpanTag,
	StyledFlex,
	StyledActionTag,
	StyledIconTag,
	StyledItemWrapper,
} from '@styled/Settings/ContentStyled';
import { IconDelete, IconEdit, IconCheck, IconClose } from '@assets/icons';

// Constant
import THEME from '@constants/themes/themes';
import { Input, message } from 'antd';
import { useSelector } from 'react-redux';
import { SettingRootStateDto } from '@models/settings/setting';
import { SUCCEEDED } from '@constants/status';
import { useTranslation } from 'react-i18next';
import { PATTERNS } from '@constants/common';

interface IListItem {
	children?: any;
	onClickAction: (flag: string) => void;
	onEdit: (value: string, index: number) => void;
	index: number;
	nameParent?: string | undefined;
}

const ListItem: React.FC<IListItem> = ({ children, onClickAction, onEdit, index, nameParent }) => {
	const { t } = useTranslation();
	const { loadingLabels, loadingFlags } = useSelector(
		(state: SettingRootStateDto) => state.settings.content,
	);
	const [isEdit, setIsEdit] = useState(false);
	const [value, setValue] = useState<string>(children);
	const alphaNumbericRegex = /^[a-zA-Z0-9_]*$/;
	const checkNameParent = 'flag';

	useEffect(() => {
		if (loadingLabels === SUCCEEDED) {
			setIsEdit(false);
		}
	}, [loadingLabels]);
	useEffect(() => {
		if (loadingFlags === SUCCEEDED) {
			setIsEdit(false);
		}
	}, [loadingFlags]);

	useEffect(() => {
		if (value !== children) {
			setValue(children);
		}
	}, [children]);

	const onClickDelete = () => {
		onClickAction(children);
	};

	const onClickEdit = (value: boolean) => {
		setIsEdit(value);
	};

	const onClickClose = () => {
		setIsEdit(false);
		setValue(children);
	};

	const validateName = (value: string) => {
		if (nameParent === checkNameParent) {
			if (PATTERNS.FLAG.test(value)) {
				onEdit(value, index);
			} else {
				message.error(t('setting.validation.no_special_character_required'));
			}
		} else {
			onEdit(value, index);
		}
	};

	const onChange = (e: any) => {
		setValue(e.target.value);
	};

	const onChangeFolderName = (e: any) => {
		const {
			target: { value },
		} = e;
		validateName(value);
	};

	const renderActionTag = () => (
		<>
			<StyledActionTag width="fit-content">
				<StyledFlex>
					{isEdit ? (
						<>
							<StyledIconTag
								onClick={() => {
									validateName(value);
								}}
								color={THEME.colors.orangeBase}
								icon={<IconCheck />}
							/>
							<StyledIconTag
								onClick={() => onClickClose()}
								color={THEME.colors.orangeBase}
								icon={<IconClose />}
							/>
						</>
					) : (
						<>
							<StyledIconTag
								onClick={() => onClickEdit(true)}
								color={THEME.colors.orangeBase}
								icon={<IconEdit />}
							/>
							<StyledIconTag
								onClick={() => onClickDelete()}
								color={THEME.colors.orangeBase}
								icon={<IconDelete />}
							/>
						</>
					)}
				</StyledFlex>
			</StyledActionTag>
		</>
	);

	const ellipsisText = () => {
		let width = 'initial';
		if (children.length > 15) {
			width = 'fit-content';
		}

		return width;
	};

	return (
		<StyledItemWrapper>
			<StyledFlex>
				<StyledTag>
					<StyledSpanTag maxWidth={ellipsisText()}>
						{isEdit ? (
							<Input
								value={value}
								onChange={onChange}
								onPressEnter={onChangeFolderName}
							/>
						) : (
							children
						)}
					</StyledSpanTag>
					{renderActionTag()}
				</StyledTag>
			</StyledFlex>
		</StyledItemWrapper>
	);
};

export default ListItem;
