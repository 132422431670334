import { ItemCamPaign } from '@models/campaign';
import {
	StyledButton,
	StyledButtonPreview,
	StyledCardModal,
	StyledCardModalInputTextArea,
	StyledCardModalSelect,
	StyledCardModalUpload,
	StyledContentDetailModal,
	StyledFormItem,
	StyledRangePicker,
	StyledWidgetSession,
} from '@styled/Campaign/CampaignStyled';
import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMGSTATUS, TAB, EMPTY_VALUE, RANGE_GOALS, MAX_SIZE_CAMPAIGN } from '@constants/campaign';
import { Button, Form, Input, InputNumber, message } from 'antd';
import { IconDelete } from '@assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetLinkDto } from '@models/common/summary';
import { s3URL } from '@config/index';
import moment from 'moment';
import { RefCampaignModal } from '@models/campaign/ref';
import { StyledDivider } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { StyledTitle } from '@styled/Common/CommonStyled';
import { TYPE_FORMAT_DATE } from '@constants/common';

const { Option } = StyledCardModalSelect;

type PropCampaignModalDto = {
	visible: boolean;
	onClose: () => void;
	detailCampaign?: ItemCamPaign;
	dataParams: any;
	setIsPopUpDelete: (val: boolean | any) => void;
	hashTagListCampaign: string[];
	onGoLiveSuccessful: (value: string) => void;
	onUpdateCampaignFailed: (value: string) => void;
	handleUpdateSuccessful: (statusAPI: any, status: string) => void;
};

const CampaignModal = forwardRef((props: PropCampaignModalDto, ref: Ref<RefCampaignModal>) => {
	const {
		visible,
		onClose,
		detailCampaign,
		dataParams: { status = '' } = {},
		setIsPopUpDelete,
		hashTagListCampaign = [],
		onGoLiveSuccessful,
		onUpdateCampaignFailed,
		handleUpdateSuccessful,
	} = props;

	const { clientSettings } = useSelector((state: any) => state.sidebar);

	const { widgetLinks = [] } = clientSettings;

	const widgetDefault = useMemo(() => {
		return widgetLinks?.find((item: WidgetLinkDto) => item?.isDefault);
	}, [widgetLinks]);

	const { t } = useTranslation();
	const defaultForm: any = {
		name: '',
		description: '',
		rangeDate: [],
		hashtags: [],
		fileImage: null,
		widgetId: widgetDefault?.id,
		creatorsGoals: RANGE_GOALS.CREATOR,
		submissionsGoals: RANGE_GOALS.SUBMISSION,
	};

	useImperativeHandle(ref, () => ({
		clearFormCampaign: handleClearFromAndClose,
	}));

	const dispatch = useDispatch();

	const [form] = Form.useForm();
	const [keyTab, setKeyTab] = useState<string>('');
	const [infoUpload, setInfoUpload] = useState<any>(null);
	const [fileListImg, setFileListImg] = useState<any>([]);

	const handleClearFromAndClose = () => {
		setFileListImg([]);
		form.resetFields();
		setInfoUpload(null);
		onClose();
	};

	const onSubmitForm = (key?: string) => {
		setKeyTab(key || '');
		form.submit();
	};

	const onFinishForm = (values: any) => {
		const formData = new FormData();
		const {
			fileImage,
			description,
			name,
			hashtags,
			rangeDate,
			widgetId,
			submissionsGoals,
			creatorsGoals,
		} = values;
		if (fileImage?.fileList.length > 0) {
			formData.append('file', fileImage?.fileList[0].originFileObj);
		}
		formData.append('description', description || '');
		formData.append('name', name);
		formData.append('hashtags', hashtags.join());

		if (keyTab) {
			formData.append('status', keyTab);
		} else {
			formData.append('status', status);
		}

		const dataQuery: any = {
			widgetId,
			submissionsGoals: submissionsGoals || 0,
			creatorsGoals: creatorsGoals || 0,
		};
		if (rangeDate?.length > 1) {
			dataQuery.startDate = new Date(rangeDate[0]).getTime();
			dataQuery.endDate = new Date(rangeDate[1]).getTime();
		}

		// if (!detailCampaign) {
		// 	dispatch(
		// 		createCampaignAPI({
		// 			dataQuery,
		// 			formData,
		// 			contentType: 'multipart/form-data',
		// 			onGoLiveSuccessful,
		// 			onUpdateCampaignFailed,
		// 		}),
		// 	);
		// 	handleClearFromAndClose();
		// } else {
		// 	dataQuery.campaignId = detailCampaign?.id;
		// 	dispatch(
		// 		editCampaignAPI({
		// 			dataQuery,
		// 			formData,
		// 			contentType: 'multipart/form-data',
		// 			handleUpdateSuccessful,
		// 			statusName: keyTab,
		// 			onUpdateCampaignFailed: onUpdateCampaignFailed,
		// 		}),
		// 	);
		// 	handleClearFromAndClose();
		// }
	};

	const renderButtonSaveDraft = () => {
		if (!detailCampaign || status === TAB.inactive.key) {
			return (
				<Button key="saveDraft" onClick={() => onSubmitForm(TAB.inactive.key)}>
					{t('campaign.save_draft')}
				</Button>
			);
		}
		return EMPTY_VALUE;
	};

	const renderButtonPrimary = () => {
		if (!detailCampaign) {
			return t('campaign.go_live');
		} else if (status === TAB.inactive.key) {
			return t('campaign.go_live');
		} else if (status === TAB.archives.key) {
			return t('campaign.restore');
		} else {
			return t('campaign.update');
		}
	};

	const renderHandleButtonPrimary = () => {
		let key = '';
		if (!detailCampaign) {
			key = TAB.active.key;
			// create
		} else {
			// edit
			if (status === TAB.inactive.key) {
				key = TAB.active.key;
			}
			if (status === TAB.archives.key) {
				key = TAB.inactive.key;
			}
		}
		onSubmitForm(key);
	};

	const onChangeForm = (name: any) => {
		const key = Object.keys(name)[0];

		if (key === 'fileImage') {
			let { fileList } = name.fileImage;
			fileList = fileList.map((file: any) => {
				if (file.response) {
					file.url = file.response.url;
				}
				return file;
			});
			if (name.fileImage.fileList.length > 1) {
				message.error(t('campaign.validate.checkImage'));

				form.setFieldsValue({ fileImage: infoUpload });
			} else {
				setInfoUpload(name.fileImage);
				setFileListImg(fileList);
			}
		}
	};

	const propsImage = {
		beforeUpload: (file: any) => {
			const checkTypeImg =
				file.type === 'image/png' ||
				file.type === 'image/jpeg' ||
				file.type === 'image/jpg';

			if (file?.size > MAX_SIZE_CAMPAIGN * 1024 * 1024) {
				message.error(t('campaign.message.warning_limit', { size: MAX_SIZE_CAMPAIGN }));
			}

			if (!checkTypeImg) {
				message.error(t('campaign.message.upload_error', { fileName: file.name }));
			}
			return checkTypeImg ? true : StyledCardModalUpload.LIST_IGNORE;
		},
		showUploadList: {
			removeIcon: <IconDelete />,
		},
	};

	const customRequest = ({ onSuccess }: any) => {
		setTimeout(() => {
			onSuccess(IMGSTATUS.DONE);
		}, 0);
	};

	const handleConvertHash = (hashtags: string) => {
		let newHash: Array<string> = [];
		if (hashtags.includes('[')) {
			newHash = hashtags.split('"');
			const newArr = newHash.filter((item: string) => item !== ',');
			newArr.splice(0, 1);
			newArr.splice(newArr.length - 1, 1);
			return newArr;
		} else {
			newHash = hashtags.split(',');
			return newHash;
		}
	};

	const onRedirectLinkWidget = () => {
		const linkElement = document.createElement('a');
		const widgetId = form.getFieldValue('widgetId');
		const urlDefault =
			widgetLinks?.find((item: WidgetLinkDto) => item?.id === widgetId)?.url || '';
		linkElement.href = urlDefault;
		if (detailCampaign?.id) {
			linkElement.href =
				urlDefault.indexOf('?') >= 0
					? `${urlDefault}&c=${detailCampaign?.id}`
					: `${urlDefault}?c=${detailCampaign?.id}`;
		}
		linkElement.target = '_blank';
		document.body.appendChild(linkElement);
		linkElement.click();
		document.body.removeChild(linkElement);
	};

	useEffect(() => {
		if (detailCampaign) {
			const {
				name = '',
				description,
				endDate,
				hashtags,
				imageUrl,
				id,
				startDate,
				widgetId,
				submissionsGoals,
				creatorsGoals,
			} = detailCampaign;
			if (imageUrl) {
				const nameImg = imageUrl.split('/');
				const dataImage = [
					{
						uid: `${id}`,
						name: nameImg[nameImg.length - 1],
						status: IMGSTATUS.DONE,
						url: `${s3URL}${imageUrl}`,
					},
				];

				setFileListImg(dataImage);
			}
			const arrHash = hashtags ? handleConvertHash(hashtags) : [];

			let rangeDate: any = [];
			if (startDate && endDate) {
				rangeDate = [moment(new Date(startDate)), moment(new Date(endDate))];
			}
			form.setFieldsValue({
				name,
				description,
				widgetId,
				rangeDate,
				hashtags: arrHash,
				submissionsGoals: submissionsGoals || 0,
				creatorsGoals: creatorsGoals || 0,
			});
		}
	}, [detailCampaign]);

	const ruleGoal: any = {
		type: 'number',
		min: RANGE_GOALS.MIN,
		max: RANGE_GOALS.MAX,
		message: t('campaign.validate.range_value', {
			min: RANGE_GOALS.MIN,
			max: RANGE_GOALS.MAX,
		}),
	};

	return (
		<StyledCardModal
			centered
			width={500}
			title={detailCampaign ? t('campaign.edit') : t('campaign.addCamp')}
			visible={visible}
			onCancel={handleClearFromAndClose}
			footer={[
				detailCampaign && status === TAB.archives.key && (
					<Button key="back" onClick={() => setIsPopUpDelete(true)}>
						{t('campaign.delete')}
					</Button>
				),
				status !== TAB.archives.key && detailCampaign && (
					<Button key="archive" onClick={() => onSubmitForm(TAB.archives.key)}>
						{t('campaign.archive')}
					</Button>
				),
				renderButtonSaveDraft(),
				<Button key="submit" type="primary" onClick={renderHandleButtonPrimary}>
					{renderButtonPrimary()}
				</Button>,
			]}>
			<Form
				form={form}
				onFinish={onFinishForm}
				layout="vertical"
				onValuesChange={onChangeForm}
				initialValues={defaultForm}>
				<StyledContentDetailModal>
					<StyledFormItem
						name="name"
						label={t('campaign.modal.campaign_nickname')}
						rules={[
							{
								required: true,
								message: t('campaign.validate.required', {
									field: t('campaign.modal.name_campaign'),
								}),
							},
						]}>
						<Input />
					</StyledFormItem>
					<StyledFormItem name="description" label={t('campaign.modal.call_to_action')}>
						<StyledCardModalInputTextArea maxLength={250} rows={4} />
					</StyledFormItem>
					<StyledWidgetSession>
						<StyledFormItem
							className="select_widget form_item_end"
							name="widgetId"
							label={t('campaign.modal.assigned_uploader')}>
							<StyledCardModalSelect
								style={{ width: '100%' }}
								getPopupContainer={(triggerNode: HTMLElement) =>
									triggerNode.parentNode as HTMLElement
								}
								virtual={false}
								showArrow={true}>
								{widgetLinks.map((item: WidgetLinkDto) => {
									return (
										<Option key={item?.id} value={item?.id}>
											{item?.name}
										</Option>
									);
								})}
							</StyledCardModalSelect>
						</StyledFormItem>
						<StyledButtonPreview onClick={onRedirectLinkWidget} type="primary">
							{t('campaign.modal.preview')}
						</StyledButtonPreview>
					</StyledWidgetSession>
					<StyledFormItem name="hashtags" label={t('campaign.modal.hashtag_campaign')}>
						<StyledCardModalSelect
							mode="multiple"
							allowClear
							showArrow={true}
							style={{ width: '100%' }}
							placeholder={t('campaign.modal.place_holder_select')}>
							{hashTagListCampaign.map((item) => {
								return (
									<Option key={item} value={item}>
										{item}
									</Option>
								);
							})}
						</StyledCardModalSelect>
					</StyledFormItem>
					<StyledFormItem
						name="fileImage"
						label={
							fileListImg?.length > 0 ? t('campaign.modal.placeholder_image') : ''
						}>
						<StyledCardModalUpload
							{...propsImage}
							className="card-campaign-modal-upload"
							customRequest={customRequest}
							fileList={fileListImg}>
							{fileListImg?.length === 0 && (
								<StyledButton
									style={{ width: '100%' }}
									className="card-campaign-modal-BtnUpload">
									{t('campaign.modal.text_upload_img')}
								</StyledButton>
							)}
						</StyledCardModalUpload>
					</StyledFormItem>
					<StyledFormItem name="rangeDate" label={t('campaign.modal.start_and_end_date')}>
						<StyledRangePicker
							allowClear
							format={TYPE_FORMAT_DATE.MONTH_DAY}
							className="range_date_picker"
						/>
					</StyledFormItem>
					<StyledDivider style={{ margin: '30px 0 24px' }} />
					<StyledTitle fontSize="16px" lineHeight="20px" margin="0 0 24px 0">
						{t('campaign.modal.set_goals_campaign')}
					</StyledTitle>
					<StyledFormItem
						name="submissionsGoals"
						label={t('campaign.modal.submissions_receive')}
						rules={[
							{
								required: true,
								message: t('campaign.validate.required', {
									field: t('campaign.modal.submissions_goals'),
								}),
							},
							{ ...ruleGoal },
						]}>
						<InputNumber />
					</StyledFormItem>
					<StyledFormItem
						style={{ margin: 0 }}
						name="creatorsGoals"
						label={t('campaign.modal.creators_participate')}
						rules={[
							{
								required: true,
								message: t('campaign.validate.required', {
									field: t('campaign.modal.creators_goals'),
								}),
							},
							{ ...ruleGoal },
						]}>
						<InputNumber />
					</StyledFormItem>
				</StyledContentDetailModal>
			</Form>
		</StyledCardModal>
	);
});

export default CampaignModal;
