import React, { useState, useEffect } from 'react';
import THEME from '@constants/themes/themes';
import { Modal } from '@cores/Modal';
import { Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplatesRequest, saveAsTemplateEnd, saveAsTemplateRequest } from '@stores/actions';
import { SUCCEEDED, FAILED } from '@constants/status';
import { EMAIL_TEMPLATE_FOLDER_TYPES } from '@constants/messages';

const StyledForm = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
	.ant-form-item-label {
		label {
			color: ${THEME.colors.gray1};
		}
	}

	textarea.ant-input {
		max-height: 113px;
	}
`;

type SaveTemplateProps = {
	isVisible: boolean;
	template: any;
	saveTemplateModal: () => void;
};

const SaveTemplateModal = (props: SaveTemplateProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const { isVisible, template, saveTemplateModal } = props;
	// Reducers
	const { templates, saveAsTemplateStatus, error } = useSelector((state: any) => state.messages);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (saveAsTemplateStatus === SUCCEEDED) {
			message.success(t('notification.save_success'));
			setIsLoading(false);

			dispatch(getTemplatesRequest());
			saveTemplateModal();
		}

		if (saveAsTemplateStatus === FAILED) {
			message.error(error);
			setIsLoading(false);
		}
	}, [saveAsTemplateStatus]);

	useEffect(() => {
		return () => {
			dispatch(saveAsTemplateEnd());
		};
	}, []);

	const saveAsTemplate = (values: any) => {
		const { subject, body } = template;
		const { title, description } = values;

		const isDuplicatedTemplate =
			templates.filter((data: any) => data.title.toUpperCase() === title.toUpperCase())
				.length > 0;

		if (_.isEqual(title, '') || _.isUndefined(title)) {
			message.error(t('message.blank_title'));
			return;
		}

		if (isDuplicatedTemplate) {
			message.error(t('message.duplicate_template_name'));
			return;
		}

		const payload = {
			title,
			body,
			subject,
			description,
			enabled: true,
			type: EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM,
		};

		setIsLoading(true);
		dispatch(saveAsTemplateRequest(payload));
	};

	const renderModalContent = () => {
		return (
			<StyledForm form={form} layout="vertical" onFinish={saveAsTemplate}>
				<StyledFormItem label={t('email_builder.template_name')} name="title">
					<Input />
				</StyledFormItem>
				<StyledFormItem label={t('common.description')} name="description">
					<Input.TextArea rows={3} />
				</StyledFormItem>
			</StyledForm>
		);
	};

	return (
		<Modal
			width={400}
			backgroundColor={THEME.colors.darkBlue2}
			title={t('email_builder.title_save_template')}
			okText={t('button.save_template')}
			cancelText={t('button.cancel')}
			isOpen={isVisible}
			closable={true}
			isLoading={isLoading}
			onClose={saveTemplateModal}
			onSubmit={() => form.submit()}>
			{renderModalContent()}
		</Modal>
	);
};

export default SaveTemplateModal;
