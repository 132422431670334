import { ArrowRightOutlined } from '@ant-design/icons';
import { IconMoreVert } from '@assets/icons';
import { KEYBOARD, TYPE_FORMAT_DATE } from '@constants/common';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import {
	KEYWORDS,
	TASK_ACTION_TYPE,
	TASK_DEFAULT_PAYLOAD,
	TASK_DETAIL_MODAL_TYPES,
} from '@constants/taskManager';
import THEME from '@constants/themes/themes';
import TaskDetailContext from '@contexts/TaskManager/TaskDetail';
import { ConfirmModal } from '@cores/Modal';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { getUser } from '@helpers/userHelpers';
import { TaskHistoryDataDto, TaskHistoryItemDto, TaskHistoryPayloadDto } from '@models/taskmanager';
import { TaskManagerStoreDto } from '@models/taskmanager/store';
import {
	addCommentEnd,
	deleteCommentEnd,
	deleteCommentRequest,
	getTaskHistoryRequest,
	notifyMentionedUserEnd,
	notifyMentionedUserRequest,
	storeDataTaskManager,
	updateCommentEnd,
} from '@stores/taskmanager/taskmanager.actions';
import {
	StyledDropdown,
	StyledFlex,
	StyledIcon,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledCommentText, StyledHistoryItem } from '@styled/TaskManager/TaskManagerStyled';
import { isEqualStringsReplacedSpace } from '@utils/common';
import { useScrollToLoadMore } from '@utils/customHooks';
import { menuActions } from '@utils/renderComponent';
import { Skeleton, message } from 'antd';
import {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TaskEditor from '../TaskEditor';

type TaskHistoryProps = {};

const TaskHistory = forwardRef((_: TaskHistoryProps, ref: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const historyCommentRef = useRef<any>();
	const {
		taskHistory,
		addCommentStatus,
		updateCommentStatus,
		updateTaskStatus,
		getTaskHistoryStatus,
		deleteCommentStatus,
		notifyMentionedUserStatus,
	}: TaskManagerStoreDto = useSelector((state: any) => state.taskManager);
	const { taskId } = useContext(TaskDetailContext);
	const user = getUser();

	const [historyData, setHistoryData] = useState<TaskHistoryDataDto>();
	const [historyPayload, setHistoryPayload] = useState<TaskHistoryPayloadDto | null>(null);
	const [confirmationModalType, setConfirmationModalType] = useState<string>('');
	const [currentHistory, setCurrentHistory] = useState<TaskHistoryItemDto>();

	const WRAPPER_ID = 'history_wrapper';
	const element = document.getElementById(WRAPPER_ID);

	useEffect(() => {
		if (taskId) {
			setTimeout(() => {
				setHistoryPayload(TASK_DEFAULT_PAYLOAD);
			}, 0);
		}

		return () => {
			setHistoryData(undefined);
			dispatch(storeDataTaskManager({ taskHistory: null }));
			setHistoryPayload(null);
			setCurrentHistory(undefined);
		};
	}, [taskId]);

	useEffect(() => {
		handleGetHistories();
	}, [historyPayload]);

	useEffect(() => {
		if (addCommentStatus === SUCCEEDED || updateTaskStatus === SUCCEEDED) {
			handleGetHistories(TASK_DEFAULT_PAYLOAD.page);
			if (element) {
				element.scrollTop = 0;
			}
		}

		if (addCommentStatus === SUCCEEDED || addCommentStatus === FAILED) {
			dispatch(addCommentEnd());
		}
	}, [addCommentStatus, updateTaskStatus]);

	useEffect(() => {
		if (updateCommentStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.edited'),
				}),
			);
			setCurrentHistory(undefined);
		}

		if (updateCommentStatus === SUCCEEDED || updateCommentStatus === FAILED) {
			dispatch(updateCommentEnd());
		}
	}, [updateCommentStatus]);

	useEffect(() => {
		if (deleteCommentStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.deleted'),
				}),
			);
			setCurrentHistory(undefined);
		}

		if (deleteCommentStatus === SUCCEEDED || deleteCommentStatus === FAILED) {
			dispatch(deleteCommentEnd());
		}
	}, [deleteCommentStatus]);

	useEffect(() => {
		if (notifyMentionedUserStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.notified'),
				}),
			);
			const updatedList = taskHistory?.histories || [];
			const index = updatedList?.findIndex(
				(item: TaskHistoryItemDto) => item.id === currentHistory?.id,
			);
			if (index !== -1 && updatedList && !updatedList[index].emailSent) {
				updatedList[index].emailSent = true;
				setHistoryData({ ...historyData, histories: updatedList });
			}
			setCurrentHistory(undefined);
		}

		if (notifyMentionedUserStatus === SUCCEEDED || notifyMentionedUserStatus === FAILED) {
			dispatch(notifyMentionedUserEnd());
		}
	}, [notifyMentionedUserStatus]);

	useEffect(() => {
		if (taskHistory) {
			const { histories = [], ...others } = taskHistory;
			const data = {
				...others,
				histories:
					historyData && historyData.histories && taskHistory.page !== 0
						? [...historyData.histories, ...histories]
						: [...histories],
			};

			setHistoryData(data);
		}
	}, [taskHistory]);

	const isEditComment = useMemo(
		() => (data: TaskHistoryItemDto) =>
			currentHistory?.id === data?.id &&
			confirmationModalType === TASK_DETAIL_MODAL_TYPES.EDIT_COMMENT,
		[currentHistory, confirmationModalType],
	);

	const hasChanges = () => {
		const commentContent = historyCommentRef?.current?.getEditorContent() || '';

		return (
			commentContent &&
			!isEqualStringsReplacedSpace(currentHistory?.eventDetail || '', commentContent) &&
			confirmationModalType === TASK_DETAIL_MODAL_TYPES.EDIT_COMMENT
		);
	};

	useImperativeHandle(ref, () => ({
		hasChanges,
	}));

	const handleLoadMore = () => {
		const { histories = [] } = taskHistory || {};
		if (histories && histories?.length > 0 && historyPayload) {
			setHistoryPayload({ ...historyPayload, page: historyPayload.page + 1 });
		}
	};

	useScrollToLoadMore(element, handleLoadMore, [taskId, taskHistory]);

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (e.key === KEYBOARD.ESCAPE.STR) {
				setCurrentHistory(undefined);
			}
		};
		window.addEventListener('keydown', onKeyDown);
		return () => window.removeEventListener('keydown', onKeyDown);
	}, [taskId, currentHistory]);

	const handleGetHistories = (page?: number) => {
		if (historyPayload) {
			dispatch(
				getTaskHistoryRequest({
					...historyPayload,
					taskId,
					page: page || historyPayload?.page,
				}),
			);
		}
	};

	const renderDate = (date: number) => {
		return `${convertUtcToLocalTimeWithFormat(date, TYPE_FORMAT_DATE.TIME)}`;
	};

	const menuItems = (data: TaskHistoryItemDto) => [
		{
			id: 1,
			name: t('button.edit'),
			click: () => {
				setCurrentHistory(data);
				setConfirmationModalType(TASK_DETAIL_MODAL_TYPES.EDIT_COMMENT);
			},
		},
		{
			id: 2,
			name: t('button.delete'),
			click: () => {
				setCurrentHistory(data);
				setConfirmationModalType(TASK_DETAIL_MODAL_TYPES.DELETE_COMMENT);
			},
		},
	];

	const notifyViaEmail = (data: TaskHistoryItemDto) => {
		dispatch(
			notifyMentionedUserRequest({
				historyId: data?.id,
				isSend: true,
			}),
		);
		setCurrentHistory(data);
	};

	const onEditComment = (data: TaskHistoryItemDto) => {
		const updatedList = historyData?.histories || [];
		const index = updatedList.findIndex((item: TaskHistoryItemDto) => item.id === data.id);
		if (index !== -1) {
			updatedList[index] = data;
		}
		setHistoryData({ ...historyData, histories: updatedList });
	};

	const renderTaskItem = (data: TaskHistoryItemDto) => {
		const { eventType, eventDetail, createAt, actorEmail, mentionUsers, emailSent } =
			data || {};
		const author = `${data.actorFirstName} ${data.actorLastName}`;

		const details = (
			<>
				<StyledText dangerouslySetInnerHTML={{ __html: eventDetail }} />
				<StyledText color={THEME.colors.gray1}>{renderDate(createAt)}</StyledText>
			</>
		);

		if (eventType === TASK_ACTION_TYPE.COMMENT_ADDED) {
			return (
				<StyledHistoryItem key={data?.id} tabIndex={0}>
					<StyledText>{author} commented</StyledText>

					{isEditComment(data) ? (
						<StyledWrapperContent
							id="task_editor_history"
							margin="4px 0"
							className="dark_bg"
							width="100%"
							height="200px">
							<TaskEditor
								ref={historyCommentRef}
								data={currentHistory}
								onCancel={() => {
									setCurrentHistory(undefined);
									setConfirmationModalType('');
								}}
								onSave={onEditComment}
							/>
						</StyledWrapperContent>
					) : (
						<StyledCommentText dangerouslySetInnerHTML={{ __html: eventDetail }} />
					)}
					<StyledText color={THEME.colors.gray1}>{renderDate(createAt)}</StyledText>
					{actorEmail === user.email && !isEditComment(data) && (
						<StyledDropdown
							overlay={menuActions(menuItems(data))}
							placement="bottomRight"
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode as HTMLElement
							}>
							<StyledIcon className="more_actions_icon" position="absolute">
								<IconMoreVert />
							</StyledIcon>
						</StyledDropdown>
					)}
					{eventDetail.includes(KEYWORDS.MENTIONED_USER) && !!mentionUsers.length && (
						<StyledFlex
							className="notify_via_email"
							onClick={() => notifyViaEmail(data)}
							margin="4px 0 0 0"
							justify="flex-start">
							<StyledText
								cursor="pointer"
								textDecoration="underline"
								color={THEME.colors.gray1}>
								{emailSent
									? t('task_manager.notify_via_email_again')
									: t('task_manager.notify_via_email')}
							</StyledText>
							<StyledIcon margin="0 0 0 6px">
								<ArrowRightOutlined />
							</StyledIcon>
						</StyledFlex>
					)}
				</StyledHistoryItem>
			);
		}

		return (
			<StyledWrapperContent margin="8px 0" key={data?.id}>
				{details}
			</StyledWrapperContent>
		);
	};

	const renderTaskHistories = () => {
		return (
			historyData?.histories &&
			historyData?.histories.map((item: TaskHistoryItemDto) => {
				return renderTaskItem(item);
			})
		);
	};

	const onDeleteComment = () => {
		if (currentHistory) {
			setConfirmationModalType('');
			const newHistoryList = historyData?.histories?.filter(
				(item: TaskHistoryItemDto) => item?.id !== currentHistory?.id,
			);
			setHistoryData({ ...historyData, histories: newHistoryList });
			dispatch(deleteCommentRequest({ historyId: currentHistory.id }));
		}
	};

	const renderConfirmationModal = () => {
		let content: string = '';
		let onSubmit: () => void;
		let okText = t('button.move');
		const isOpen: boolean =
			!!confirmationModalType &&
			confirmationModalType !== TASK_DETAIL_MODAL_TYPES.EDIT_COMMENT;

		if (confirmationModalType === TASK_DETAIL_MODAL_TYPES.DELETE_COMMENT) {
			content = t('task_manager.message.delete_comment');
			okText = t('button.delete');
			onSubmit = onDeleteComment;
		}

		return (
			<ConfirmModal
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => {
					setConfirmationModalType('');
					setCurrentHistory(undefined);
				}}>
				{content}
			</ConfirmModal>
		);
	};

	return (
		<StyledWrapperContent>
			<StyledText fontSize="16px">{t('task_manager.details.task_history')}</StyledText>
			<StyledWrapperContent
				id={WRAPPER_ID}
				className="custom_scroll_bar"
				maxHeight="375px"
				overflow="hidden auto"
				padding="0 2px 0 0">
				{getTaskHistoryStatus === IN_PROGRESS && historyPayload?.page === 1 ? (
					<Skeleton active />
				) : (
					renderTaskHistories()
				)}
			</StyledWrapperContent>
			{renderConfirmationModal()}
		</StyledWrapperContent>
	);
});

export default TaskHistory;
