/* eslint-disable @typescript-eslint/dot-notation */
import { Row, Col, Form, Input, Button, Select, Tag, Tabs, Switch, Rate, Popover } from 'antd';
import styled from 'styled-components';
import Wrapper from '@cores/Wrapper';
import THEME from '@constants/themes/themes';

const StyledWrapper = styled(Wrapper)`
	height: unset;
	background-color: ${THEME.colors.darkBlue};
`;

const StyledRow = styled(Row)`
	margin-left: 0 !important;
	margin-top: 48px;
`;

const StyledCol = styled(Col)<{ background?: boolean; padding?: string; margintop?: string }>`
	background-color: ${({ background }) => (background ? THEME.colors.darkBlue : 'unset')};
	padding: ${({ padding }) => `${padding} !important` || 'unset'};
	margin-top: ${({ margintop }) => margintop || 'unset'};
`;

const fontText = () => `
	width: 100%;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: ${THEME.colors.gray5};

	display: flex;
	align-items: center;
`;

const StyledTitle = styled.div<{
	fontSize?: string;
	fontWeight?: string | number;
}>`
	${fontText()}

	padding: 16px;
	border-bottom: ${`1px solid ${THEME.colors.darkBlue3}`};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
`;

const StyleSubTitle = styled.div<{
	fontSize?: string;
	fontWeight?: string | number;
	paddingTop?: string | number;
}>`
	${fontText()}

	padding-top: 12px;
	padding-bottom: 16px;
	line-height: 1.43;
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
`;

const StyledWrapperBox = styled.div<{
	padding?: string;
}>`
	width: 100%;
	height: auto;
	padding: ${({ padding }) => padding || '16px'};
`;

const StyledListWrapper = styled(StyledWrapperBox)`
	margin-top: 12px;
	overflow-y: auto;
	height: 35vh;
`;

const StyledForm = styled(({ ...props }) => <Form {...props} />)`
	.ant-form-item {
		width: 100%;
		/* flex-direction: column;
		margin-bottom: 12px; */
	}
	.ant-form-item-label {
		padding: 0;
	}
	/* .ant-form-item-control-input-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	} */
`;

const StyledFlex = styled.div<{ justify?: string; align?: string }>`
	width: 100%;
	position: relative;
	display: flex;
	justify-content: ${({ justify }) => justify || 'space-between'};
	align-items: ${({ align }) => align || 'flex-end'};
`;

const StyledInput = styled(Input)`
	height: 32px;
	padding: 0 12px;
	border: ${`1px solid ${THEME.colors.darkBlue3}`};

	::placeholder {
		color: ${THEME.colors.darkBlue5};
	}
`;

const StyledSelect = styled(({ ...props }) => <Select {...props} />)`
	width: 100%;
	height: 32px;

	.ant-select-selector {
		border-radius: 2px;
		padding: 0 12px;
		background-color: transparent !important;
		border: ${`1px solid ${THEME.colors.darkBlue3}`};
	}
`;

const StyledButton = styled(Button)`
	width: 32px;
	height: 32px;
	margin-left: 12px;
	margin-top: 12px;
	background-color: ${THEME.colors.orangeBase};

	svg {
		path {
			fill: ${THEME.colors.white} !important;
		}
	}
`;

const StyledIcon = styled.div`
	display: flex;
	align-items: center;
	svg {
		margin-right: 5px;
	}
`;

const StyledTag = styled(({ ...props }) => <Tag {...props} />)`
	width: fit-content;
	height: 28px;
	justify-content: space-between;
	position: relative;
	align-items: center;
	display: flex;
	padding: 4px 12px;
	border-radius: 20px;
	border: 1px solid transparent;
	cursor: pointer;
	margin-bottom: 12px;
	margin-right: 0;
	color: ${THEME.colors.gray5};
	background-color: ${THEME.colors.darkBlue3};
	font-size: 1rem;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledSpanTag = styled.span<{ maxWidth?: string }>`
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: ${({ maxWidth }) => maxWidth || 'initial'};
`;

const StyledActionTag = styled.div<{ width: string }>`
	display: none;
	align-items: center;
	width: ${({ width }) => width || '84px'};
	position: absolute;
	right: 0;
	top: 0;
	margin-right: 10px;
`;

const StyledSpanIcon = styled.span`
	display: flex;
	align-items: center;
	padding-left: 8px;

	svg {
		transform: scale(0.8);
	}
`;

const StyledItemWrapper = styled.div<{ active?: boolean }>`
	${({ active }) => (!active ? '&:hover' : '')} ${StyledTag} {
		width: 100%;
		border: 1px solid ${THEME.colors.orangeBase};
		color: ${THEME.colors.orangeBase};
	}
	${({ active }) => (!active ? '&:hover' : '')} ${StyledActionTag} {
		display: flex;
	}
	${({ active }) => (!active ? '&:hover' : '')} ${StyledIcon} {
		svg {
			margin-right: 5px;
			fill: ${THEME.colors.orangeBase} !important;
		}
	}
`;

const StyledFolderTitle = styled.div`
	display: flex;
`;

const StyledActiveTag = styled(StyledTag)``;

const StyledSmallActionTag = styled(StyledActionTag)`
	width: fit-content;
`;

const StyledIconTag = styled(({ color, ...props }) => <Tag {...props} />)`
	border: none;
	width: fit-content;
	height: auto;
	background: transparent;
	padding: 0;
	height: 24px;
	margin-right: 0;
	margin-left: 10px;
	display: flex;
	align-items: center;

	svg {
		path {
			${({ color }) => color && `fill: ${color}`};
		}
	}
`;

const StyledSpan = styled.span`
	display: flex;
	align-items: center;
	padding-left: 8px;

	svg {
		transform: scale(0.8);
	}
`;

const StyledToggle = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	.ant-switch {
		height: 18px !important;
	}
	.ant-switch-handle {
		height: 14px;
		width: 14px;
	}
`;

const StyledSwitch = styled(Switch)<{ marginLeft: string }>`
	margin-left: ${({ marginLeft }) => marginLeft || '0'};
	&.ant-switch {
		background-color: rgba(255, 255, 255, 0.5);
		.ant-switch-handle::before {
			background-color: white;
		}
	}
	&.ant-switch-checked {
		background-color: rgba(226, 88, 43, 0.5);
		.ant-switch-handle::before {
			background-color: ${THEME.colors.orangeBase};
		}
	}
`;

const StyledFormItem = styled(({ width, ...props }) => <Form.Item {...props} />)`
	/* .ant-form-item-explain {
		position: absolute;
	} */
	width: ${({ width }) => `${width} !important` || 'auto'};

	.ant-form-item-label > label {
		${fontText()}
		font-size: 1rem;
		line-height: 1.43;
		font-weight: normal;
	}

	.ant-form-item-label
		> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}
`;

const StyledTabs = styled(Tabs)`
	background: ${THEME.colors.darkBlue};
	margin: 0;

	.ant-tabs-nav {
		margin-bottom: 0 !important;
		border-bottom: 1px solid ${THEME.colors.darkBlue3};

		.ant-tabs-tab {
			padding: 17px 32px;
			background: none;
			border: none;
			border-bottom: 2px solid transparent;

			&.ant-tabs-tab-active {
				border-bottom: 2px solid ${THEME.colors.primary};
				opacity: 1;

				.ant-tabs-tab-btn {
					color: ${THEME.colors.gray5};
				}
			}
		}

		&:before {
			border-top: none;
			border-bottom: 0.9px solid ${THEME.colors.darkBlue3};
		}

		.ant-tabs-nav-wrap {
			padding-left: 15px;
			height: 36px;
		}
	}

	.ant-tabs-content {
		overflow-y: auto;
	}
`;

const StyledRowWrapper = styled.div`
	display: grid;
	height: 72px;
	align-items: center;
	grid-template-columns: 20% 20% 20% 20% 20%;
`;

const StyledColWrapper = styled.div`
	display: flex;
	height: 72px;
	justify-content: center;
	align-item: center;
	&:not(:last-child) {
		border-right: 1px solid ${THEME.colors.darkBlue4};
	}
	padding: 16px 24px;
`;

const StyledRate = styled(Rate)`
	display: flex;
	align-items: center;
	.ant-rate-star-zero {
		opacity: 0.5;
	}
`;

const StyledContentValueInput = styled(Input)`
	height: 100%;
	background-color: ${THEME.colors.darkBlue4};
	.ant-input {
		background-color: ${THEME.colors.darkBlue4};
	}
`;

const StyledScrollWrapper = styled.div`
	@media screen and (max-width: 1366px) {
		overflow-x: scroll;
		width: 150%;
	}
`;

const StyledPopover = styled(Popover)``;

const StyledUrl = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	span {
		margin-right: 70px;
	}
`;

export {
	StyledUrl,
	StyledPopover,
	StyledSpanIcon,
	StyledWrapper,
	StyledRow,
	StyledCol,
	StyledTitle,
	StyleSubTitle,
	StyledForm,
	StyledWrapperBox,
	StyledListWrapper,
	StyledScrollWrapper,
	StyledInput,
	StyledButton,
	StyledFlex,
	StyledSelect,
	StyledTag,
	StyledActiveTag,
	StyledActionTag,
	StyledSmallActionTag,
	StyledIconTag,
	StyledSpan,
	StyledToggle,
	StyledSwitch,
	StyledFormItem,
	StyledIcon,
	StyledTabs,
	StyledRowWrapper,
	StyledColWrapper,
	StyledRate,
	StyledContentValueInput,
	StyledItemWrapper,
	StyledFolderTitle,
	StyledSpanTag,
};
