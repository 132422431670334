import { IconAddCircle, IconMoreVert } from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { TYPE_ACTIONS, WIDTH_SCROLL } from '@constants/common';
import { TYPE_PUBLISH_LOG } from '@constants/contentLibrary/contentDetail';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { ConfirmModal } from '@cores/Modal';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { PublishLogDto } from '@models/content/contentLibrary/summary';
import { SummaryColumnsDto } from '@models/creator/summary';
import { removePublishLogContentEnd, removePublishLogContentRequest } from '@stores/actions';
import { StyledEmpty, StyledIcon, StyledTable, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledLink,
	StyledWrapperPublishList,
	StyledWrapperPublishLog,
	StyledWrapperTitle,
} from '@styled/ContentLibrary/ContentDetailStyled';
import { StyledDropdown } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { ConfigProvider, Menu, Spin, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PublishLogModal from '../PublishLogModal';
import { renderCopiedText } from '@utils/renderComponent';

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

type PropPublishLog = {
	contentId: number;
	disabled: boolean;
};
const PublishLog = (props: PropPublishLog) => {
	const { contentId, disabled } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		publishLogs: listPublishLog,
		fetchingPublishLog,
		removePublishLogStatus,
		publishLog,
		error,
	} = useSelector((state: any) => state.content);

	const { setParamPublish, isLoading, publishLogs, setPublishLogs } =
		useContext(ContentDetailContext);

	const [visiblePublishModal, setVisiblePublishModal] = useState<boolean>(false);
	const [publishSelected, setPublishSelected] = useState<PublishLogDto | null>(null);
	const [typeConfirm, setTypeConfirm] = useState<string>('');

	useEffect(() => {
		if (removePublishLogStatus === SUCCEEDED) {
			message.success(t('content_detail.message.remove_publish_log_success'));
			handleResetPublishLogs();
		}
		if (removePublishLogStatus === SUCCEEDED || removePublishLogStatus === FAILED) {
			dispatch(removePublishLogContentEnd());
			onCloseConfirm();
		}
	}, [removePublishLogStatus]);

	useEffect(() => {
		const node = document.querySelector<HTMLElement>('#table_publish_logs .ant-table-body');
		if (node) {
			const handleScrollList = () => {
				if (node.scrollHeight <= node.scrollTop + node.clientHeight + WIDTH_SCROLL) {
					onLoadMorePublishLog();
					node.scrollTop = node.scrollHeight;
				}
			};
			node.addEventListener('scroll', handleScrollList);
			return () => node.removeEventListener('scroll', handleScrollList);
		}
		return () => {};
	}, [listPublishLog, fetchingPublishLog]);

	const onLoadMorePublishLog = () => {
		if (listPublishLog?.length > 0 && !fetchingPublishLog) {
			setParamPublish((prev: any) => ({ ...prev, page: prev.page + 1 }));
		}
	};

	const handleResetPublishLogs = () => {
		setParamPublish((prev: any) => ({ ...prev, page: 1 }));
	};

	const handleDelete = (record: PublishLogDto) => {
		setPublishSelected(record);
		setTypeConfirm(TYPE_ACTIONS.REMOVE);
	};
	const handleEdit = (record: PublishLogDto) => {
		setPublishSelected(record);
		setVisiblePublishModal(true);
	};

	const actionsObj: ActionsTypes[] = [
		{
			id: 1,
			name: t('button.edit'),
			click: handleEdit,
			disabled,
		},
		{
			id: 2,
			name: t('button.delete'),
			click: handleDelete,
			disabled,
		},
	];

	const menuAction = (record: PublishLogDto) => {
		return (
			<Menu>
				{actionsObj.map((item: ActionsTypes) => {
					if (item?.hidden) {
						return null;
					}
					return (
						<Menu.Item
							key={item.id}
							onClick={() => item.click(record)}
							disabled={item?.disabled}>
							{item.name}
						</Menu.Item>
					);
				})}
			</Menu>
		);
	};

	const renderDate = (record: PublishLogDto) => {
		return (
			<StyledWrapperTitle style={{ justifyContent: 'space-between', height: '24px' }}>
				<span>
					{renderCopiedText(convertUtcToLocalTimeWithFormat(record?.publishDate * 1000))}
				</span>
				{record?.type === TYPE_PUBLISH_LOG.CUSTOM_LOG && (
					<StyledDropdown
						className="dropdown_action"
						overlay={() => menuAction(record)}
						getPopupContainer={(triggerNode: HTMLElement) =>
							triggerNode.parentNode?.parentNode?.parentNode as HTMLElement
						}
						placement="bottomLeft"
						trigger={['click']}>
						<StyledIcon
							className="hover_level_svg"
							onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => {
								e.preventDefault();
								e.stopPropagation();
							}}>
							<IconMoreVert />
						</StyledIcon>
					</StyledDropdown>
				)}
			</StyledWrapperTitle>
		);
	};

	const columns: SummaryColumnsDto[] = [
		{
			title: t('content_detail.publish_logs.date'),
			dataIndex: 'publishDate',
			show: true,
			width: 120,
			render: (val: number, record: PublishLogDto) => renderDate(record),
			ellipsis: true,
		},
		{
			title: t('content_detail.publish_logs.where'),
			dataIndex: 'publishTo',
			show: true,
			width: 150,
			ellipsis: true,
			render: (val: string) => renderCopiedText(val),
		},
		{
			title: t('content_detail.publish_logs.link'),
			dataIndex: 'link',
			show: true,
			width: 150,
			ellipsis: true,
			render: (val: string) => (
				<StyledLink target={'_blank'} href={val}>
					{val}
				</StyledLink>
			),
		},
		{
			title: t('content_detail.publish_logs.user'),
			dataIndex: 'userEmail',
			show: true,
			width: 200,
			ellipsis: true,
			render: (val: string) => renderCopiedText(val),
		},
	];

	const onConfirmDelete = () => {
		dispatch(removePublishLogContentRequest({ id: publishSelected?.id }));
	};

	const onCloseConfirm = () => {
		setTypeConfirm('');
		setPublishSelected(null);
		setVisiblePublishModal(false);
	};

	const renderConfirmModal = () => {
		const content = t('content_detail.confirm.delete');
		const onSubmit: () => void = onConfirmDelete;
		const okText = t('button.delete');

		return (
			<ConfirmModal
				confirmLoading={removePublishLogStatus === IN_PROGRESS}
				okText={okText}
				isOpen={!!typeConfirm}
				onSubmit={() => onSubmit()}
				onClose={onCloseConfirm}>
				{content}
			</ConfirmModal>
		);
	};

	const handleCancel = () => {
		setVisiblePublishModal(false);
		setPublishSelected(null);
	};

	const handleUpdateSuccess = () => {
		if (publishLog) {
			const newList = publishLogs?.map((item: PublishLogDto) => {
				if (publishLog?.id === item?.id) {
					return publishLog;
				}
				return item;
			});
			setPublishLogs([...newList]);
		}
	};

	const handleDeletePublish = () => {
		if (publishSelected) {
			handleDelete(publishSelected);
		}
	};

	const tableLoading = {
		spinning: fetchingPublishLog,
		indicator: <Spin size="small" />,
	};

	return (
		<StyledWrapperPublishLog>
			{renderConfirmModal()}
			<PublishLogModal
				handleCreateSuccess={handleResetPublishLogs}
				contentId={contentId}
				visible={visiblePublishModal}
				onCancel={handleCancel}
				detailPublish={publishSelected}
				handleUpdateSuccess={handleUpdateSuccess}
				handleDeletePublish={handleDeletePublish}
			/>
			<StyledWrapperTitle style={{ justifyContent: 'space-between', marginBottom: '16px' }}>
				<StyledTitle>{t('content_detail.publish_log')}</StyledTitle>
				<StyledIcon disabled={disabled} onClick={() => setVisiblePublishModal(true)}>
					<IconAddCircle />
				</StyledIcon>
			</StyledWrapperTitle>
			{publishLogs?.length > 0 && (
				<ConfigProvider renderEmpty={customizeRenderEmpty}>
					<StyledWrapperPublishList>
						<StyledTable
							loading={tableLoading}
							className="table_row_action"
							columns={columns}
							id={'table_publish_logs'}
							dataSource={publishLogs}
							onRow={(record: PublishLogDto, rowIndex: number) => {
								return {
									onDoubleClick: () => {
										if (
											record?.type === TYPE_PUBLISH_LOG.CUSTOM_LOG &&
											!disabled
										) {
											handleEdit(record);
										}
									},
									disabled,
								};
							}}
							pagination={false}
							scroll={{ y: '252px' }}
						/>
					</StyledWrapperPublishList>
				</ConfigProvider>
			)}
		</StyledWrapperPublishLog>
	);
};

export default PublishLog;
