import { takeLatest, put } from 'redux-saga/effects'

import headerTypes  from '@stores/header/header.types';
import * as actions from './header.actions';

export function* fetchHeader( action: any ) {
	try {
		const payload = action.data;

		yield put( actions.headerSucceeded( payload ) );
	} catch ( err ) {
		yield put( actions.headerFailed( err ) );
	}
}

export function* watcherHeaderSaga() {
	yield takeLatest( headerTypes.HEADER_REQUEST, fetchHeader );
}
