import {
	ADDRESS_CREATOR_INFO_FIELD,
	BASIC_CREATOR_INFO_FIELD,
	CUSTOM_CREATOR_FIELD,
	SOCIAL_CREATOR_INFO_FIELD,
} from '@constants/creator';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import ButtonGroup from '@cores/ButtonGroup/ButtonGroup';
import { ConfirmModal, LoadingWrapper } from '@cores/index';
import { getUser } from '@helpers/userHelpers';
import { CreatorProfileFormValuesDto } from '@models/content/contentLibrary/summary';
import { CreatorProfileSummaryDto, CustomPropertyDto } from '@models/creator/profile';
import { updateCreatorProfileEnd, updateCreatorProfileRequest } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledDivider,
	StyledForm,
	StyledText,
	StyledWrapper,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledDrawer } from '@styled/Creators/SummaryStyled';
import { dataState } from '@utils/dataStateUSA';
import { removeLeadingWhitespaceOnCopy } from '@utils/funcHelper';
import { Layout, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BasicInformation from './BasicInformation';
import CreatorStatus from './CreatorStatus';
import CustomInformation from './CustomInformation';

type CreatorDetailsProps = {
	visible: boolean;
	onClose: () => void;
};

const CreatorDetails = (props: CreatorDetailsProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = useForm();
	const { visible, onClose } = props;
	const { creatorProfile, updateCreatorProfileStatus = NONE }: UserStoreType = useSelector(
		(state: any) => state.creator,
	);
	const user = getUser();
	const [originalCreatorProfile, setOriginalCreatorProfile] =
		useState<CreatorProfileSummaryDto | null>(null);
	const [creatorDetails, setCreatorDetails] = useState<CreatorProfileSummaryDto | null>(null);
	const [requestPayload, setRequestPayload] = useState<CreatorProfileSummaryDto | null>(null);
	const [cityList, setCityList] = useState<string[]>([]);
	const [confirmationModalType, setConfirmationModalType] = useState<string>('');

	const MODAL_TYPES = {
		CUSTOM_FIELD: 'CUSTOM_FIELD',
	};

	const CUSTOM_FIELD_SUFFIX = '_value';

	const initialValues = () => {
		const {
			firstName = '',
			lastName = '',
			zipCode = '',
			phone = '',
			email = '',
			social: {
				instagramUsername = '',
				tiktokUsername = '',
				twitterUsername = '',
				youtubeUsername = '',
				facebookProfileUrl = '',
			} = {},
			creatorCustomProperties = [],
		} = creatorProfile || {};

		const values: CreatorProfileFormValuesDto = {
			firstName,
			lastName,
			email,
			phone,
			zipCode,
			instagramUsername,
			tiktokUsername,
			twitterUsername,
			youtubeUsername,
			facebookProfileUrl,
		};

		creatorCustomProperties?.forEach((item) => {
			values[item.key] = item.name;
			values[`${item.key}${CUSTOM_FIELD_SUFFIX}`] = item.values;
		});

		return values;
	};

	useEffect(() => {
		if (visible && creatorProfile) {
			setOriginalCreatorProfile(_.cloneDeep(creatorProfile));
			setCreatorDetails(_.cloneDeep(creatorProfile));
		}
		removeLeadingWhitespaceOnCopy();

		return () => {
			onCancelChanges();
		};
	}, [visible, creatorProfile]);

	useEffect(() => {
		if (creatorDetails) {
			const { address = '' } = creatorDetails || {};
			let dataAddress: any = '' || {};
			try {
				dataAddress = JSON.parse(address);
			} catch (error) {
				dataAddress = address;
			}

			if (typeof dataAddress === 'object') {
				form.setFieldsValue(dataAddress);
				if (dataAddress?.state) {
					const newCityList =
						dataState?.find((item) => item?.state === dataAddress?.state)?.city || [];

					setCityList(newCityList);
				}
			} else {
				form.setFieldsValue({ street: dataAddress });
			}
		}
	}, [creatorDetails]);

	useEffect(() => {
		if (updateCreatorProfileStatus === SUCCEEDED) {
			message.success(t('message.save_changes_successfully'));
			resetData();
			onClose();
		}

		if (updateCreatorProfileStatus === SUCCEEDED || updateCreatorProfileStatus === FAILED) {
			dispatch(updateCreatorProfileEnd());
		}
	}, [updateCreatorProfileStatus]);

	const hasChanges = !_.isEqual(originalCreatorProfile, creatorDetails);

	const onValuesChange = (changedValue: any) => {
		let fieldName = Object.keys(changedValue)[0];
		const value =
			typeof changedValue[fieldName] === 'string'
				? changedValue[fieldName].trim()
				: changedValue[fieldName];

		if (creatorDetails) {
			let requestPayloadTemp: any = { ...requestPayload };
			const creatorDetailsTemp: CreatorProfileSummaryDto = { ...creatorDetails };

			switch (true) {
				case Object.values(BASIC_CREATOR_INFO_FIELD).includes(fieldName): {
					requestPayloadTemp = {
						...requestPayloadTemp,
						[fieldName]: value,
					};
					creatorDetailsTemp[fieldName] = value;
					break;
				}
				case Object.values(SOCIAL_CREATOR_INFO_FIELD).includes(fieldName): {
					requestPayloadTemp = { ...requestPayloadTemp, [fieldName]: value };
					creatorDetailsTemp.social[fieldName] = value;
					break;
				}
				case Object.values(ADDRESS_CREATOR_INFO_FIELD).includes(fieldName): {
					const address = JSON.parse(creatorDetailsTemp.address);
					const changedAddress = {
						...requestPayloadTemp?.address,
						...changedValue, // not trim at here because values are dependencies in useEffect
					};
					if (fieldName === ADDRESS_CREATOR_INFO_FIELD.STATE) {
						changedAddress.city = '';
					}
					requestPayloadTemp.address = {
						...changedAddress,
					};
					creatorDetailsTemp.address = JSON.stringify({
						...address,
						...requestPayloadTemp.address,
					});
					break;
				}
				default: {
					const isUpdatingValues = fieldName.includes(CUSTOM_FIELD_SUFFIX);
					if (isUpdatingValues) {
						fieldName = fieldName.split(CUSTOM_FIELD_SUFFIX)[0];
					}

					const customizedPropertyIndex =
						creatorDetails?.creatorCustomProperties?.findIndex(
							(item) => item.key === fieldName,
						);

					if (
						creatorDetailsTemp.creatorCustomProperties &&
						!_.isUndefined(customizedPropertyIndex) &&
						customizedPropertyIndex !== -1
					) {
						if (isUpdatingValues) {
							creatorDetailsTemp.creatorCustomProperties[
								customizedPropertyIndex
							].values = changedValue[`${fieldName}${CUSTOM_FIELD_SUFFIX}`];
						} else {
							creatorDetailsTemp.creatorCustomProperties[
								customizedPropertyIndex
							].name = value;
						}

						requestPayloadTemp = {
							...requestPayloadTemp,
							creatorCustomProperties: creatorDetailsTemp.creatorCustomProperties,
						};
					}
					break;
				}
			}

			// Reset the email's status if the updated email is different from the old one
			if (BASIC_CREATOR_INFO_FIELD.EMAIL in requestPayloadTemp) {
				creatorDetailsTemp.status = {
					...creatorDetailsTemp.status,
					invalidEmail: value === originalCreatorProfile?.email,
				};
			}

			setCreatorDetails({ ...creatorDetailsTemp });
			setRequestPayload({ ...requestPayloadTemp });
		}
	};

	const onCancelChanges = () => {
		if (originalCreatorProfile) {
			setCreatorDetails(_.cloneDeep({ ...originalCreatorProfile }));
			resetData();
		}
	};

	const resetData = () => {
		setRequestPayload(null);
		form.resetFields();
	};

	const handleSaveChanges = () => {
		if (requestPayload && CUSTOM_CREATOR_FIELD.PROPERTIES in requestPayload) {
			const { creatorCustomProperties = [] } = originalCreatorProfile || {};
			const originalNames = creatorCustomProperties?.map(
				(item: CustomPropertyDto) => item.name,
			);
			const changedNames = requestPayload[CUSTOM_CREATOR_FIELD.PROPERTIES].map(
				(item: CustomPropertyDto) => item.name,
			);

			if (!_.isEqual(originalNames, changedNames)) {
				setConfirmationModalType(MODAL_TYPES.CUSTOM_FIELD);
				return;
			}
		}
		updateCreatorDetails();
	};

	const updateCreatorDetails = () => {
		const dataRequest: any = { ...requestPayload };

		if (BASIC_CREATOR_INFO_FIELD.ADDRESS in dataRequest) {
			const creatorAddress = JSON.parse(creatorDetails?.address || '') || {};
			const updatedAddress = {
				...creatorAddress,
				...dataRequest.address,
			};
			dataRequest.address = JSON.stringify({
				...updatedAddress,
				street: updatedAddress?.street?.trim(),
			});
		}

		if (
			creatorDetails &&
			creatorDetails?.approvalStatus !== originalCreatorProfile?.approvalStatus
		) {
			dataRequest.approvalStatus = creatorDetails.approvalStatus;
			dataRequest.updateBy = user.email;
		}

		dispatch(
			updateCreatorProfileRequest({
				creatorId: creatorProfile?.id,
				dataRequest,
			}),
		);
	};

	const onCloseConfirmationModal = () => {
		setConfirmationModalType('');
	};

	const renderConfirmationModal = () => {
		let content: string = '';
		let onSubmit: () => void;
		let okText = t('button.move');
		const isOpen: boolean = confirmationModalType === MODAL_TYPES.CUSTOM_FIELD;

		if (confirmationModalType === MODAL_TYPES.CUSTOM_FIELD) {
			content = t('creator_profile.message.changing_custom_field');
			okText = t('button.save_changes');
			onSubmit = updateCreatorDetails;
		}

		return (
			<ConfirmModal
				confirmLoading={updateCreatorProfileStatus === IN_PROGRESS}
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => onCloseConfirmationModal()}>
				{content}
			</ConfirmModal>
		);
	};

	const onBlur = (e: React.ChangeEvent<any>) => {
		const fieldName = e.target.getAttribute('id');
		const value = e.target.value;
		const hasSpaces =
			typeof value === 'string' && (value.endsWith(' ') || value.startsWith(' '));

		if (hasSpaces) {
			form.setFieldsValue({
				[fieldName]: value.trim(),
			});
		}
	};

	return (
		<StyledDrawer
			visible={visible}
			placement="right"
			getContainer={false}
			onClose={onClose}
			maskClosable={false}>
			<LoadingWrapper
				isLoading={updateCreatorProfileStatus === IN_PROGRESS && !confirmationModalType}>
				<Layout>
					<StyledWrapper marginTop="16px">
						<StyledText margin="18px 26px" fontSize="20px">
							{t('creator_profile.about_this_creator')}
						</StyledText>
						<StyledDivider margin="0" />
						<StyledWrapperContent
							overflow="auto"
							height="calc(100vh - 220px)"
							padding="18px 26px">
							<StyledForm
								form={form}
								onFinish={handleSaveChanges}
								onValuesChange={onValuesChange}
								initialValues={initialValues()}
								onBlur={onBlur}>
								<CreatorStatus
									creatorDetails={creatorDetails}
									setCreatorDetails={setCreatorDetails}
								/>
								<StyledDivider />
								<BasicInformation
									form={form}
									creatorDetails={creatorDetails}
									cityList={cityList}
								/>
								<StyledDivider />
								<CustomInformation form={form} creatorDetails={creatorDetails} />
							</StyledForm>
						</StyledWrapperContent>
						{hasChanges && (
							<StyledWrapperContent position="absolute" bottom="-40px" right="24px">
								<ButtonGroup
									onSubmit={form.submit}
									onCancel={onCancelChanges}
									hasChanges={hasChanges}
								/>
							</StyledWrapperContent>
						)}
					</StyledWrapper>
				</Layout>
			</LoadingWrapper>

			{renderConfirmationModal()}
		</StyledDrawer>
	);
};

export default CreatorDetails;
