const ACTIVE = 'ACTIVE';
const DRAFT = 'DRAFT';
const ARCHIVED = 'ARCHIVED';
const EXPIRED = 'EXPIRED';

const FIXED = 'FIXED';
const PERCENTAGE = 'PERCENTAGE';

const DISCOUNT = 'DISCOUNT';
const GIFTCARD = 'GIFTCARD';
const PRODUCT = 'PRODUCT';
const CASH = 'CASH';
const CUSTOM = 'CUSTOM';

const SUCCESSFUL = 'SUCCESSFUL';
const FETCHING = 'FETCHING';
const FAILED = 'FAILED';
const REDEEMED = 'REDEEMED';
const SENT = 'SENT';
const CANCELLED = 'CANCELLED';

const REWARD_TYPES = {
	FIXED_DISCOUNT: 'FIXED_DISCOUNT',
	PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
	GIFT_CARD: 'GIFT_CARD',
	FREE_PRODUCT: 'FREE_PRODUCT',
	TREMENDOUS: 'TREMENDOUS',
};

const COLLAPSE_KEY = {
	FREE_PRODUCTS: 'freeProducts',
	DISCOUNTS: 'discounts',
	GIFT_CARDS: 'giftCards',
};

const REWARD_STATUS = {
	SENT: {
		label: 'Sent',
		value: SENT,
	},
	PENDING: {
		label: 'Pending',
		value: 'PENDING',
	},
	FAILED: {
		label: 'Failed',
		value: 'FAILED',
	},
};

export {
	ACTIVE,
	DRAFT,
	ARCHIVED,
	EXPIRED,
	DISCOUNT,
	GIFTCARD,
	PRODUCT,
	CASH,
	CUSTOM,
	SUCCESSFUL,
	FETCHING,
	FAILED,
	PERCENTAGE,
	FIXED,
	REDEEMED,
	SENT,
	CANCELLED,
	REWARD_TYPES,
	REWARD_STATUS,
	COLLAPSE_KEY,
};
