export const SET_CONTENT_VALUES = 'SET_CONTENT_VALUES';

export const socialSearchTypes = {
	GET_HASHTAG: 'GET_HASHTAG',
	GET_HASHTAG_SUCCEEDED: 'GET_HASHTAG_SUCCEEDED',
	GET_HASHTAG_FAILED: 'GET_HASHTAG_FAILED',

	UPDATE_SUB_HASHTAG: 'UPDATE_SUB_HASHTAG',

	GET_SOCIAL_USERNAME: 'GET_SOCIAL_USERNAME',
	MODIFY_SOCIAL_USERNAME: 'MODIFY_SOCIAL_USERNAME',
	GET_SOCIAL_USERNAME_SUCCEEDED: 'GET_SOCIAL_USERNAME_SUCCEEDED',
	GET_SOCIAL_USERNAME_FAILED: 'GET_SOCIAL_USERNAME_FAILED',

	FETCH_INACTIVE_USERNAME: 'FETCH_INACTIVE_USERNAME',
	FETCH_INACTIVE_USERNAME_SUCCEEDED: 'FETCH_INACTIVE_USERNAME_SUCCEEDED',
	FETCH_INACTIVE_USERNAME_FAILED: 'FETCH_INACTIVE_USERNAME_FAILED',
	UPDATE_INACTIVE_USERNAME: 'UPDATE_INACTIVE_USERNAME',

	GET_SOCIAL_BLOCK_USERNAME: 'GET_SOCIAL_BLOCK_USERNAME',
	MODIFY_SOCIAL_BLOCK_USERNAME: 'MODIFY_SOCIAL_BLOCK_USERNAME',
	GET_SOCIAL_BLOCK_USERNAME_SUCCEEDED: 'GET_SOCIAL_BLOCK_USERNAME_SUCCEEDED',
	GET_SOCIAL_BLOCK_USERNAME_FAILED: 'GET_SOCIAL_BLOCK_USERNAME_FAILED',

	GET_BAD_WORDS: 'GET_BAD_WORDS',
	MODIFY_BAD_WORDS: 'MODIFY_BAD_WORDS',
	GET_BAD_WORDS_SUCCEEDED: 'GET_BAD_WORDS_SUCCEEDED',
	GET_BAD_WORDS_FAILED: 'GET_BAD_WORDS_FAILED',

	GET_LIST_FEED_REQUEST: 'GET_LIST_FEED_REQUEST',
	GET_LIST_FEED_SUCCEEDED: 'GET_LIST_FEED_SUCCEEDED',
	GET_LIST_FEED_FAILED: 'GET_LIST_FEED_FAILED',

	CREATE_FEED_REQUEST: 'CREATE_FEED_REQUEST',
	CREATE_FEED_SUCCEEDED: 'CREATE_FEED_SUCCEEDED',
	CREATE_FEED_FAILED: 'CREATE_FEED_FAILED',
	CREATE_FEED_ENDED: 'CREATE_FEED_ENDED',

	UPDATE_FEED_REQUEST: 'UPDATE_FEED_REQUEST',
	UPDATE_FEED_SUCCEEDED: 'UPDATE_FEED_SUCCEEDED',
	UPDATE_FEED_FAILED: 'UPDATE_FEED_FAILED',
	UPDATE_FEED_ENDED: 'UPDATE_FEED_ENDED',

	IMPORT_USERNAME_FEED_REQUEST: 'IMPORT_USERNAME_FEED_REQUEST',
	IMPORT_USERNAME_FEED_SUCCEEDED: 'IMPORT_USERNAME_FEED_SUCCEEDED',
	IMPORT_USERNAME_FEED_FAILED: 'IMPORT_USERNAME_FEED_FAILED',
	IMPORT_USERNAME_FEED_ENDED: 'IMPORT_USERNAME_FEED_ENDED',

	FETCH_STATS_SETTING_FEED_REQUEST: 'FETCH_STATS_SETTING_FEED_REQUEST',
	FETCH_STATS_SETTING_FEED_SUCCEEDED: 'FETCH_STATS_SETTING_FEED_SUCCEEDED',
	FETCH_STATS_SETTING_FEED_FAILED: 'FETCH_STATS_SETTING_FEED_FAILED',

	RESET_SOCIAL_SEARCH_SETTING: 'RESET_SOCIAL_SEARCH_SETTING',
};

export const contentSettingsTypes = {
	GET_CONTENT_SETTINGS: 'GET_CONTENT_SETTINGS',
	GET_CONTENT_SETTINGS_SUCCEEDED: 'GET_CONTENT_SETTINGS_SUCCEEDED',
	GET_CONTENT_SETTINGS_FAILED: 'GET_CONTENT_SETTINGS_FAILED',
	UPDATE_CONTENT_VALUES: 'UPDATE_CONTENT_VALUES',
	UPDATE_CONTENT_VALUES_SUCCEEDED: 'UPDATE_CONTENT_VALUES_SUCCEEDED',
	UPDATE_CONTENT_VALUES_FAILED: 'UPDATE_CONTENT_VALUES_FAILED',
	UPDATE_FLAGS: 'UPDATE_FLAGS',
	UPDATE_FLAGS_SUCCEEDED: 'UPDATE_FLAGS_SUCCEEDED',
	UPDATE_FLAGS_FAILED: 'UPDATE_FLAGS_FAILED',
	UPDATE_FLAGS_END: 'UPDATE_FLAGS_END',

	UPDATE_LABELS: 'UPDATE_LABELS',
	UPDATE_LABELS_SUCCEEDED: 'UPDATE_LABELS_SUCCEEDED',
	UPDATE_LABELS_FAILED: 'UPDATE_LABELS_FAILED',
	UPDATE_LABELS_END: 'UPDATE_LABELS_END',
	CREATE_FOLDER: 'CREATE_FOLDER',
	CREATE_FOLDER_SUCCEEDED: 'CREATE_FOLDER_SUCCEEDED',
	CREATE_FOLDER_FAILED: 'CREATE_FOLDER_FAILED',
	DELETE_FOLDER: 'DELETE_FOLDER',
	DELETE_FOLDER_SUCCEEDED: 'DELETE_FOLDER_SUCCEEDED',
	DELETE_FOLDER_FAILED: 'DELETE_FOLDER_FAILED',
	UPDATE_FOLDER: 'UPDATE_FOLDER',
	UPDATE_FOLDER_SUCCEEDED: 'UPDATE_FOLDER_SUCCEEDED',
	UPDATE_FOLDER_FAILED: 'UPDATE_FOLDER_FAILED',
	GET_FOLDER_SETTINGS: 'GET_FOLDER_SETTINGS',
	GET_FOLDER_SETTINGS_SUCCEEDED: 'GET_FOLDER_SETTINGS_SUCCEEDED',
	GET_FOLDER_SETTINGS_FAILED: 'GET_FOLDER_SETTINGS_FAILED',
};

export const publishSettingsTypes = {
	GET_CHANNELS_SETTINGS: 'GET_CHANNELS_SETTINGS',
	GET_CHANNELS_SETTINGS_SUCCEEDED: 'GET_CHANNELS_SETTINGS_SUCCEEDED',
	GET_CHANNELS_SETTINGS_FAILED: 'GET_CHANNELS_SETTINGS_FAILED',
	MODIFY_CHANNELS_SETTINGS: 'MODIFY_CHANNELS_SETTINGS',

	GET_CALENDAR_SETTINGS: 'GET_CALENDAR_SETTINGS',
	GET_CALENDAR_SETTINGS_SUCCEEDED: 'GET_CALENDAR_SETTINGS_SUCCEEDED',
	GET_CALENDAR_SETTINGS_FAILED: 'GET_CALENDAR_SETTINGS_FAILED',
	MODIFY_CALENDAR_SETTINGS: 'MODIFY_CALENDAR_SETTINGS',
};

export const CampaignTypes = {
	GET_CAMPAIGN_LIST: 'GET_CAMPAIGN_LIST',
	GET_CAMPAIGN_LIST_SUCCESS: 'GET_CAMPAIGN_LIST_SUCCESS',
	GET_CAMPAIGN_LIST_FAILED: 'GET_CAMPAIGN_LIST_FAILED',
};

export const accountUserTypes = {
	GET_ACCOUNT_LIST: 'GET_ACCOUNT_LIST',
	GET_ACCOUNT_LIST_SUCCEEDED: 'GET_ACCOUNT_LIST_SUCCEEDED',
	GET_ACCOUNT_LIST_FAILED: 'GET_ACCOUNT_LIST_FAILED',
	GET_ACCOUNT_LIST_END: 'GET_ACCOUNT_LIST',

	DELETE_ACCOUNT_USER: 'DELETE_ACCOUNT_USER',
	DELETE_ACCOUNT_USER_SUCCEEDED: 'DELETE_ACCOUNT_USER_SUCCEEDED',
	DELETE_ACCOUNT_USER_FAILED: 'DELETE_ACCOUNT_USER_FAILED',
	DELETE_ACCOUNT_USER_END: 'DELETE_ACCOUNT_USER_END',

	INVITE_USER: 'INVITE_USER',
	INVITE_USER_SUCCEEDED: 'INVITE_USER_SUCCEEDED',
	INVITE_USER_FAILED: 'INVITE_USER_FAILED',
	INVITE_USER_END: 'INVITE_USER_END',

	UPDATE_ACCOUNT_USER: 'UPDATE_ACCOUNT_USER',
	UPDATE_ACCOUNT_USER_SUCCEEDED: 'UPDATE_ACCOUNT_USER_SUCCEEDED',
	UPDATE_ACCOUNT_USER_FAILED: 'UPDATE_ACCOUNT_USER_FAILED',
	UPDATE_ACCOUNT_USER_END: 'UPDATE_ACCOUNT_USER_END',
};

export const storeDataTypes = {
	STORE_DATA: 'STORE_DATA',
};

export const removeFolderTypes = {
	REMOVE_FOLDER_REQUEST: 'REMOVE_FOLDER_REQUEST',
	REMOVE_FOLDER_SUCCEEDED: 'REMOVE_FOLDER_SUCCEEDED',
	REMOVE_FOLDER_FAILED: 'REMOVE_FOLDER_FAILED',
	REMOVE_FOLDER_END: 'REMOVE_FOLDER_END',
};

export const replaceBlockUsernameType = {
	REPLACE_BLOCK_USERNAME_REQUEST: 'REPLACE_BLOCK_USERNAME_REQUEST',
	REPLACE_BLOCK_USERNAME_SUCCEEDED: 'REPLACE_BLOCK_USERNAME_SUCCEEDED',
	REPLACE_BLOCK_USERNAME_FAILED: 'REPLACE_BLOCK_USERNAME_FAILED',
	REPLACE_BLOCK_USERNAME_END: 'REPLACE_BLOCK_USERNAME_END',
};

export const replaceBlockTermsType = {
	REPLACE_BLOCK_TERMS_REQUEST: 'REPLACE_BLOCK_TERMS_REQUEST',
	REPLACE_BLOCK_TERMS_SUCCEEDED: 'REPLACE_BLOCK_TERMS_SUCCEEDED',
	REPLACE_BLOCK_TERMS_FAILED: 'REPLACE_BLOCK_TERMS_FAILED',
	REPLACE_BLOCK_TERMS_END: 'REPLACE_BLOCK_TERMS_END',
};
