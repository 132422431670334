import { createAction } from 'redux-actions';
import { ambassadorTypes } from './ambassador.types';

// get ambassador posts
export const getAmbassadorPostsRequest = createAction(ambassadorTypes.GET_AMBASSADOR_POSTS_REQUEST);
export const getAmbassadorPostsSuccess = createAction(ambassadorTypes.GET_AMBASSADOR_POSTS_SUCCESS);
export const getAmbassadorPostsFailed = createAction(ambassadorTypes.GET_AMBASSADOR_POSTS_FAILED);

// get ambassador leader board
export const getAmbassadorLeaderBoardRequest = createAction(
	ambassadorTypes.GET_AMBASSADOR_LEADER_BOARD_REQUEST,
);
export const getAmbassadorLeaderBoardSuccess = createAction(
	ambassadorTypes.GET_AMBASSADOR_LEADER_BOARD_SUCCESS,
);
export const getAmbassadorLeaderBoardFailed = createAction(
	ambassadorTypes.GET_AMBASSADOR_LEADER_BOARD_FAILED,
);

// get ambassador metric
export const getAmbassadorMetricRequest = createAction(
	ambassadorTypes.GET_AMBASSADOR_METRIC_REQUEST,
);
export const getAmbassadorMetricSuccess = createAction(
	ambassadorTypes.GET_AMBASSADOR_METRIC_SUCCESS,
);
export const getAmbassadorMetricFailed = createAction(ambassadorTypes.GET_AMBASSADOR_METRIC_FAILED);

// get ambassador graph posts
export const getAmbassadorGraphPostsRequest = createAction(
	ambassadorTypes.GET_AMBASSADOR_GRAPH_POSTS_REQUEST,
);
export const getAmbassadorGraphPostsSuccess = createAction(
	ambassadorTypes.GET_AMBASSADOR_GRAPH_POSTS_SUCCESS,
);
export const getAmbassadorGraphPostsFailed = createAction(
	ambassadorTypes.GET_AMBASSADOR_GRAPH_POSTS_FAILED,
);

// get ambassador graph votes
export const getAmbassadorGraphVotesRequest = createAction(
	ambassadorTypes.GET_AMBASSADOR_GRAPH_VOTES_REQUEST,
);
export const getAmbassadorGraphVotesSuccess = createAction(
	ambassadorTypes.GET_AMBASSADOR_GRAPH_VOTES_SUCCESS,
);
export const getAmbassadorGraphVotesFailed = createAction(
	ambassadorTypes.GET_AMBASSADOR_GRAPH_VOTES_FAILED,
);

// get ambassador report
export const getAmbassadorReportRequest = createAction(
	ambassadorTypes.GET_AMBASSADOR_REPORT_REQUEST,
);
export const getAmbassadorReportSuccess = createAction(
	ambassadorTypes.GET_AMBASSADOR_REPORT_SUCCESS,
);
export const getAmbassadorReportFailed = createAction(ambassadorTypes.GET_AMBASSADOR_REPORT_FAILED);

// refresh metric ambassador
export const refreshMetricAmbassadorRequest = createAction(
	ambassadorTypes.REFRESH_METRIC_AMBASSADOR_REQUEST,
);
export const refreshMetricAmbassadorSuccess = createAction(
	ambassadorTypes.REFRESH_METRIC_AMBASSADOR_SUCCESS,
);
export const refreshMetricAmbassadorFailed = createAction(
	ambassadorTypes.REFRESH_METRIC_AMBASSADOR_FAILED,
);

// reset fields ambassador data
export const resetFieldsAmbassadorData = createAction(ambassadorTypes.RESET_FIELDS_AMBASSADOR);
