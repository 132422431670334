import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import {
	creatorBlockEnd,
	creatorFindProfiles,
	getCreatorUnviewedStatusRecentlyAddedEnd,
	getCreatorUnviewedStatusRecentlyAddedRequest,
	invalidateSocialUsernameEnd,
	updateAccountSettingEnd,
	updateApprovalStatusEnd,
	updateApprovalStatusRequest,
	updateNotificationInfo,
	updateStatusRecentlyAddedEnd,
	updateStatusRecentlyAddedRequest,
} from '@stores/actions';
import { Affix, ConfigProvider, Empty, Pagination, Spin, message } from 'antd';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Context
import CreatorSummaryContext from '@contexts/Creators/Summary';

// Model
import { SummaryDto, SummarySortDto } from '@models/creator/summary';
import HeaderDto from '@models/header';

// constant
import THEME from '@constants/themes/themes';

// components
import { IconClose } from '@assets/icons';
import { StyledTable } from '@components/CustomTable';
import { ROUTE_PATH, TOP_MESSAGE } from '@constants/common';
import { NotificationType } from '@constants/notification';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { getUser } from '@helpers/userHelpers';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import { LocationStateDto } from '@models/messages/summary';
import { UserStoreType } from '@stores/creator/creator.types';
import { UserRootStateDto } from '@stores/user/user.types';
import { StyledMarkAll } from '@styled/Common/CommonStyled';
import { StyledWrapperIconClose } from '@styled/Content/ContentLibrary/ContentStyled';
import ApprovalStatus from '../ApprovalStatus';

const StyledEmpty = styled(Empty)`
	margin: 0;

	.ant-empty-image {
		width: 100%;
		height: unset;
	}
`;

const StyledPagination = styled.div`
	background-color: ${THEME.colors.darkBlue1};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 82px;
	margin: 0;
	position: absolute;
	bottom: 0px;
	z-index: 333;
`;

const StyledWrapper = styled.div`
	&.summary_list {
		margin-bottom: 82px;
	}
	.ant-table-wrapper {
		margin-top: 50px;
		background-color: ${THEME.colors.darkBlue1};

		.ant-table {
			.ant-table-body {
				height: calc(100vh - 242px);
			}
		}
	}
`;

const StyledButtonSelectAll = styled.span`
	color: ${THEME.colors.blueBase};
	cursor: pointer;
	text-decoration-line: underline;
`;

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

const SummaryList = ({
	columns,
	changeSelectedRow,
	handleSelectedCreators,
	selectedRowKeys,
	handleSelectFullDatabase,
	selectedCreatorList,
}: any) => {
	const history = useHistory();

	const { t } = useTranslation();

	const { handleChangeParamSummary, paramSummaryContact, isAllSelectOverDb, selectOnView } =
		useContext(CreatorSummaryContext);
	const [dataSource, setDataSource] = useState<SummaryDto[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { state: stateLocation } = useLocation<LocationStateDto>();

	const {
		creatorIdsUnviewedContentStatusRecentlyAdded = [],
		updateRecentlyAddStatus,
		invalidateSocialUsernameStatus = NONE,
		error,
	}: UserStoreType = useSelector((state: any) => state.creator);

	const {
		fetchingProfiles,
		isResetSelectedRows,
		creatorBlockStatus,
		profilesData = [],
		totalRecords = 0,
		uploadAWS = false,
		statusExport = false,
		updateApprovalStatus = NONE,
	} = useSelector((state: any) => state.creator);
	const { updateAccountStatus, error: errorContent = '' }: ContentStoreDto = useSelector(
		(state: any) => state.content,
	);
	const { fetchingUser } = useSelector((state: UserRootStateDto) => state.user);

	const { creatorsSummary }: HeaderDto = useSelector((state: any) => state.header);
	const dispatch = useDispatch();

	useEffect(() => {
		if (uploadAWS) {
			message.success(t('creator_summary.export_file_success'), 3);
		}
	}, [uploadAWS]);

	useEffect(() => {
		if (isResetSelectedRows || creatorBlockStatus === 2) {
			handleClearSelection();
		}
		if (creatorBlockStatus === SUCCEEDED || creatorBlockStatus === FAILED) {
			dispatch(creatorBlockEnd());
		}
	}, [isResetSelectedRows, creatorBlockStatus]);

	useEffect(() => {
		if (updateAccountStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (updateAccountStatus === FAILED) {
			message.error(errorContent);
		}
		if (updateAccountStatus === SUCCEEDED || updateAccountStatus === FAILED) {
			dispatch(updateAccountSettingEnd());
			setIsLoading(false);
		}
	}, [updateAccountStatus]);

	useEffect(() => {
		if (updateApprovalStatus === FAILED || updateApprovalStatus === SUCCEEDED) {
			dispatch(updateApprovalStatusEnd());
		}
	}, [updateApprovalStatus]);

	const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any) => {
		changeSelectedRow(selectedRowKeys);
		handleSelectedCreators(selectedRows);
	};

	const onChangeTable = (pagination: any, filters: any, sorter: any, extra: any) => {
		const { field, order } = sorter;

		const sortOrders: SummarySortDto = {
			ascend: 'ASC',
			descend: 'DESC',
		};

		const sortFields: SummarySortDto = {
			firstName: 'FIRST_NAME',
			lastName: 'LAST_NAME',
			email: 'EMAIL',
			instagramUsername: 'INSTAGRAM_USERNAME',
			twitterUsername: 'TWITTER_USENAME',
			youtubeUsername: 'YOUTUBE_USERNAME',
			tiktokUsername: 'TIKTOK_USERNAME',
			createAt: 'DATE_ADD_TO_DATABASE',
			directUploads: 'TOTAL_DIRECT_UPLOADS',
			socialUploads: 'TOTAL_SOCIAL_UPLOADS',
			rewards: 'TOTAL_REWARDS_OFFERED',
			totalRedeemed: 'TOTAL_COUPONS_REDEEMED',
			totalCashRedeemed: 'TOTAL_CASH_VALUE_REDEEMED',
			avgRatingUpload: 'AVG_STAR',
			totalCashValueAllUploads: 'TOTAL_CONTENT_VALUE',
			totalVotes: 'TOTAL_VOTES',
			followers: 'FOLLOWERS',
			likesComments: 'LIKES_COMMENTS',
			engagementRate: 'ENGAGEMENT_RATE',
		};

		handleChangeParamSummary({
			...paramSummaryContact,
			sortField: sortFields[field],
			sortDirection: sortOrders[order],
			page: 1,
		});
	};

	const onChangePage = (page: number) => {
		handleChangeParamSummary({ ...paramSummaryContact, page });
	};

	const onRedirectCreatorProfile = (creatorId?: number | string) => {
		const path = `${ROUTE_PATH.CREATOR_DETAIL_EMPTY}${creatorId}`;
		history.push(path);
	};

	const handleSelection = (selectedRowKeys: React.Key[], selectedRows: any) => {
		const listKey: any[] = [];
		const listRow: any[] = [];

		selectedCreatorList?.map((creatorSelect: SummaryDto) => {
			if (!dataSource?.some((item: SummaryDto) => item?.key === creatorSelect?.key)) {
				listRow.push(creatorSelect);
				listKey.push(creatorSelect?.key);
			}
		});

		for (const selectKey of selectedRowKeys) {
			const itemSelect = dataSource?.find(
				(dataIndex: any) => dataIndex?.creatorId === selectKey,
			);
			listKey.push(itemSelect?.creatorId || '');
			listRow.push(itemSelect);
		}

		onSelectChange(listKey, listRow);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: handleSelection,
	};

	useEffect(() => {
		dispatch(creatorFindProfiles(paramSummaryContact));
	}, [paramSummaryContact]);

	useEffect(() => {
		if (invalidateSocialUsernameStatus === SUCCEEDED) {
			message.success(t('creator_summary.message.updated_successfully'));
			dispatch(creatorFindProfiles(paramSummaryContact));
		}

		if (invalidateSocialUsernameStatus === FAILED && error) {
			message.error(error);
		}

		if (
			invalidateSocialUsernameStatus === SUCCEEDED ||
			invalidateSocialUsernameStatus === FAILED
		) {
			dispatch(invalidateSocialUsernameEnd());
		}
	}, [invalidateSocialUsernameStatus, error]);

	useEffect((): any => {
		setDataSource(
			profilesData?.map((item: SummaryDto) => {
				return { ...item, key: item?.creatorId };
			}),
		);
	}, [profilesData]);

	useEffect((): any => {
		setDataSource(
			profilesData?.map((item: SummaryDto) => {
				return { ...item, key: item?.creatorId };
			}),
		);
	}, [profilesData]);

	useEffect(() => {
		if (!statusExport) {
			handleClearSelection();
		}
	}, [statusExport]);

	useEffect(() => {
		dispatch(getCreatorUnviewedStatusRecentlyAddedRequest());

		return () => {
			dispatch(getCreatorUnviewedStatusRecentlyAddedEnd());
			message.destroy('message_select_all');
			onCloseMessageMarkAll();
			dispatch(updateNotificationInfo({ notificationType: '', arrayId: [] }));
		};
	}, []);

	useEffect(() => {
		if (updateRecentlyAddStatus === SUCCEEDED || updateRecentlyAddStatus === FAILED) {
			dispatch(updateStatusRecentlyAddedEnd());
		}
	}, [updateRecentlyAddStatus]);

	useEffect(() => {
		if (stateLocation) {
			if (stateLocation?.arrayId) {
				if (
					stateLocation?.notificationType === NotificationType.CREATOR_ADDED &&
					stateLocation?.arrayId?.length > 0
				) {
					handleChangeParamSummary({
						...paramSummaryContact,
						creatorIds: stateLocation.arrayId,
					});
				}
			}
			if (stateLocation?.isActive) {
				handleChangeParamSummary({
					...paramSummaryContact,
					creatorIds: stateLocation.arrayId,
					isActive: true,
				});
			}
			history.replace({ state: undefined });
		}
	}, [stateLocation]);

	const handleUpdateStatusRecentlyAdded = (listIdCreatorId?: number[], isAll?: boolean) => {
		let payload;
		if (isAll && isAll === true) {
			payload = { isAll };
		} else {
			if (listIdCreatorId) {
				payload = { creatorIds: listIdCreatorId };
			}
		}
		dispatch(updateStatusRecentlyAddedRequest(payload));
	};

	const tableLoading = {
		spinning: fetchingProfiles || invalidateSocialUsernameStatus === IN_PROGRESS || isLoading,
		indicator: <Spin size="large" />,
	};

	const handleClearSelection = () => {
		handleSelectFullDatabase(false);
		onSelectChange([], []);
	};

	const handleClickSelect = () => {
		message.destroy('message_select_all');
		const propsMessage: any = {
			values: { countSelect: selectOnView?.length, total: totalRecords },
			i18nKey: 'creator_summary.message_select_all',
			components: {
				linkTo: <StyledButtonSelectAll onClick={() => handleSelectFullDatabase(true)} />,
			},
		};
		if (isAllSelectOverDb) {
			propsMessage.values = { total: totalRecords };
			propsMessage.i18nKey = 'creator_summary.message_select_all_clear';
			propsMessage.components = {
				linkTo: <StyledButtonSelectAll onClick={handleClearSelection} />,
			};
		}
		// duration Number.MAX_SAFE_INTEGER not apply for message
		return message.info({
			content: <Trans t={t} {...propsMessage} />,
			duration: 0,
			key: 'message_select_all',
		});
	};

	useEffect(() => {
		if (isAllSelectOverDb) {
			handleClickSelect();
		}
	}, [isAllSelectOverDb]);

	useEffect(() => {
		if (
			totalRecords > selectOnView?.length &&
			selectOnView?.length > 0 &&
			selectOnView?.length === dataSource?.length
		) {
			handleClickSelect();
		} else {
			message.destroy('message_select_all');
			handleSelectFullDatabase(false);
		}
	}, [selectedRowKeys]);

	useEffect(() => {
		if (creatorIdsUnviewedContentStatusRecentlyAdded?.length > 0) {
			handleDisplayMessageMarkAllCreator();
		}
	}, [creatorIdsUnviewedContentStatusRecentlyAdded]);

	const handleClickMarkAll = () => {
		handleUpdateStatusRecentlyAdded(undefined, true);
		onCloseMessageMarkAll();
	};

	const onCloseMessageMarkAll = () => {
		message.destroy('message_mark_all_creator');
	};

	const handleDisplayMessageMarkAllCreator = () => {
		const propsMessage: any = {
			values: { text: t('creator_summary.mark_all_as_reviewed') },
			i18nKey: 'creator_summary.message_mark_all',
			components: {
				linkTo: <StyledMarkAll onClick={() => handleClickMarkAll()} />,
				wrapperIcon: <StyledWrapperIconClose />,
				icon: (
					<IconClose
						onClick={onCloseMessageMarkAll}
						className="ico_close"
						style={{
							height: '16px',
							width: '16px',
							position: 'absolute',
							top: '2px',
							left: 0,
							cursor: 'pointer',
						}}
					/>
				),
			},
		};

		message.config({
			top: TOP_MESSAGE,
		});
		message.info({
			content: <Trans t={t} {...propsMessage} />,
			// don't dismiss if set to 0
			duration: 0,
			key: 'message_mark_all_creator',
		});
	};

	const handleChangeApprovalStatus = (record: SummaryDto, approvalStatus: string) => {
		const user = getUser();
		const listTemp = [...dataSource];
		const updatedCreatorIndex = listTemp.findIndex(
			(creator) => creator.creatorId === record.creatorId,
		);
		if (updatedCreatorIndex !== -1) {
			listTemp[updatedCreatorIndex].approvalStatus = approvalStatus;
		}

		const payload = {
			creatorIds: [record.creatorId],
			status: approvalStatus.toUpperCase(),
			updateBy: user.email,
		};

		setDataSource([...listTemp]);
		dispatch(updateApprovalStatusRequest(payload));
	};

	const renderApprovalStatus = (status: string, record: SummaryDto) => {
		return (
			<ApprovalStatus
				status={status || 'NONE'}
				onChangeStatus={(status: string) => handleChangeApprovalStatus(record, status)}
			/>
		);
	};

	const formattedColumn = React.useMemo(() => {
		const columnsTemp: any = [...columns];
		const approvalStatusIndex = columnsTemp.findIndex(
			(col: any) => col.dataIndex === 'approvalStatus',
		);

		if (approvalStatusIndex !== -1) {
			columnsTemp[approvalStatusIndex].render = (
				approvalStatus: string,
				record: SummaryDto,
			) => renderApprovalStatus(approvalStatus, record);
		}

		return [...columnsTemp];
	}, [columns, dataSource]);

	return (
		<ConfigProvider renderEmpty={customizeRenderEmpty}>
			<StyledWrapper className="card-content card-bg summary_list">
				<StyledTable
					loading={fetchingUser ? false : tableLoading}
					dataSource={dataSource}
					columns={formattedColumn}
					pagination={false}
					rowSelection={rowSelection}
					onChange={onChangeTable}
					className="table_row_action"
					scroll={{ y: 'calc(100vh - 242px)' }}
					rowKey={(record: any) => record?.creatorId}
					onRow={(record: any, rowIndex: any) => ({
						onClick: () => onRedirectCreatorProfile(record?.creatorId),
					})}
				/>
			</StyledWrapper>
			<Affix offsetBottom={0}>
				<StyledPagination>
					<Pagination
						total={totalRecords}
						pageSize={paramSummaryContact.maxRecords}
						defaultPageSize={paramSummaryContact.maxRecords}
						current={paramSummaryContact.page}
						showSizeChanger={false}
						showQuickJumper
						onChange={onChangePage}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${total || 0} ${t(
								`${total > 1 ? 'pagination.items' : 'pagination.item'}`,
							)}`
						}
					/>
				</StyledPagination>
			</Affix>
		</ConfigProvider>
	);
};

export default React.memo(SummaryList);
