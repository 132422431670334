import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import CustomSelection from '@components/CustomLibrary/CustomSelection';
import FilterFeed from '@components/Filter/FilterFeed';
import BookmarkPost from '@components/SocialSearch/BookmarkPost';
import ActionBarFeeds from '@components/SocialSearch/Feeds/ActionBarFeeds';
import MetricFeeds from '@components/SocialSearch/Feeds/MetricFeeds';
import SummaryFeed from '@components/SocialSearch/Feeds/SummaryFeed';
import { COL_MEDIA } from '@constants/ambassador';
import {
	DECLINED_STATUS,
	FULL_RECORD,
	PARAMS_DEFAULT,
	ROUTE_PATH,
	SCOPE_ACCESS_FB,
	TYPE_ACTIONS,
} from '@constants/common';
import { defaultSiderWidth } from '@constants/content/albums';
import { TYPE_COLLAPSE_ACTION } from '@constants/content/contentLibrary';
import { ACTION_TYPE_ON_FILTER, STATUS_SETTING } from '@constants/settings/socialAggregator';
import { DEFAULT_MEDIA, RATE_LIMIT } from '@constants/social_search';
import { ID_CONTAINER_FEED, ID_EMPTY, PARAM_DEFAULT_FEED } from '@constants/social_search/feeds';
import { CONNECTED, FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import FeedsManagerContext from '@contexts/SocialSearch/Feeds';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal, Modal } from '@cores/Modal';
import { ReponseLoginFacebook } from '@models/common/facebookSDK';
import { SelectionCustomRef } from '@models/common/ref';
import { SocialSearchDto } from '@models/settings/socialSearch';
import {
	FeedDto,
	FieldFormSettingFeed,
} from '@models/settings/socialSearch/socialAggregator/summary';
import { RefSummaryFeed } from '@models/socialsearch/ref';
import {
	BlockSocialContentPayloadDto,
	BlockSocialUFeedUsernamePayloadDto,
	ImportSocialContentPayloadDto,
	SocialContentStoreDto,
	UsernameSocialTypes,
} from '@models/socialsearch/store';
import {
	ParamFilterFeed,
	ParamSocialFeed,
	SocialContentContainerDto,
	SocialContentFiltersDto,
} from '@models/socialsearch/summary';
import {
	fetchListFeedRequest,
	getListContentCampaignAPI,
	resetSocialSearchSetting,
	updateLabels,
} from '@stores/actions';
import {
	blockSocialContentEnd,
	blockSocialFeedContentRequest,
	blockSocialFeedUsernameRequest,
	blockSocialUsernameEnd,
	fetchContentFeedRequest,
	fetchMetricFeedRequest,
	importSocialContentEnd,
	importSocialFeedContentRequest,
	resetSocialSearchField,
	saveFacebookTokenRequest,
	saveFeedDetail,
} from '@stores/socialsearch/socialsearch.actions';
import {
	StyledEmpty,
	StyledTitle,
	StyledWrapper,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledDirectLink, StyledSider } from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledContainerFeeds,
	StyledGoHere,
	StyledScrollContainer,
} from '@styled/SocialSearch/Feeds';
import { handleSelectItemWithLib } from '@utils/funcHelper';
import {
	convertSocialId,
	formatSocialSearchPayload,
	optimizeRequestPayload,
} from '@utils/socialSearch';
import { Layout, Tooltip, message } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SelectableGroup } from 'react-selectable-fast';

const Feeds = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const { pathname } = location;
	const history = useHistory();
	const dispatch = useDispatch();
	const { listFeedActive, loadingListFeed, listFeed, updateFeedStatus }: SocialSearchDto =
		useSelector((state: any) => state.settings.socialSearch);
	const { clientSettings } = useSelector((state: any) => state.sidebar);

	const {
		detailFeed,
		socialContent,
		fetchingSocialContent,
		importSocialContentStatus,
		importedSocialContent,
		blockSocialContentStatus,
		blockedSocialContent,
		blockSocialUsernameStatus,
		blockedSocialUsername,
		error,
	}: SocialContentStoreDto = useSelector((state: any) => state.socialContent);

	const filterRef = useRef<any>({});
	const scrollbars = useRef<any>({});
	const selectionRef = useRef<any>({});
	const bookmarkPostRef = useRef<any>({});
	const refSummary = useRef<RefSummaryFeed | null>(null);
	const refEventSelect = useRef<
		SelectionCustomRef | undefined
	>() as RefObject<SelectionCustomRef>;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [typeCollapse, setTypeCollapse] = useState<string>('');
	const [paramSocial, setParamSocial] = useState<ParamSocialFeed>({
		...PARAM_DEFAULT_FEED,
	});
	const [socialFeedList, setSocialFeedList] = useState<SocialContentContainerDto[]>([]);
	const [selectContentList, setSelectContentList] = useState<SocialContentContainerDto[]>([]);
	const [filter, setFilter] = useState<ParamFilterFeed>({});
	const [isConnected, setIsConnected] = useState<boolean>(true);
	const [modalLoginRequire, setModalLoginRequire] = useState<boolean>(false);
	const [typeAction, setTypeAction] = useState<string>('');
	const [usernameBlock, setUsernameBlock] = useState<UsernameSocialTypes[]>([]);
	const [socialDetail, setSocialDetail] = useState<SocialContentContainerDto | null>(null);
	const [col, setCol] = useState<number>(COL_MEDIA.C3);
	const [fullThumb, setFullThumb] = useState<boolean>(false);
	const [actionTypeOnFilter, setActionTypeOnFilter] = useState<string>('');

	useEffect(() => {
		dispatch(
			fetchListFeedRequest({
				...PARAMS_DEFAULT,
				maxRecords: FULL_RECORD,
			}),
		);
		dispatch(getListContentCampaignAPI());

		return () => {
			dispatch(
				resetSocialSearchField({
					socialContent: { ...DEFAULT_MEDIA },
					detailFeed: null,
					metricFeed: null,
				}),
			);
			setSocialFeedList([]);
			dispatch(resetSocialSearchSetting({ listFeedActive: [], listFeed: [] }));
		};
	}, []);

	useEffect(() => {
		const id = pathname.slice(pathname.indexOf(ROUTE_PATH.FEEDS) + 7);
		if (listFeedActive.length > 0) {
			if (!id) {
				const activeFeed = listFeedActive[0];
				handleChangeIdFeed(activeFeed);
				dispatch(saveFeedDetail(listFeedActive[0]));
				history.replace({ pathname: `${ROUTE_PATH.FEEDS}/${listFeedActive[0].id}` });
			} else {
				const activeFeed = listFeedActive?.find((item) => item.id === +id) || null;

				if (activeFeed) {
					handleChangeIdFeed(activeFeed);
					history.replace({ pathname: `${ROUTE_PATH.FEEDS}/${id}` });
					dispatch(saveFeedDetail(activeFeed));
				}
			}
		}
	}, [listFeedActive, pathname]);

	useEffect(() => {
		if (paramSocial.id && paramSocial.id !== ID_EMPTY) {
			setIsLoading(false);
			const requestPayload = optimizeRequestPayload({ ...paramSocial });
			if (paramSocial.page === PARAMS_DEFAULT.page) {
				scrollTopContent();
				dispatch(fetchMetricFeedRequest({ ...requestPayload }));
			}
			dispatch(fetchContentFeedRequest(requestPayload));
		}
		if (paramSocial?.page === PARAMS_DEFAULT.page) {
			handleClearSelect();
			setSocialFeedList([]);
			scrollTopContent();
		}
	}, [paramSocial]);

	const scrollTopContent = () => {
		if (scrollbars?.current) {
			scrollbars.current.scrollTop = 0;
		}
	};

	useEffect(() => {
		if (!isEmpty(socialContent)) {
			const { listSocialMediaResponse } = socialContent;

			if (listSocialMediaResponse) {
				let newSocialList = [...socialFeedList, ...listSocialMediaResponse];
				if (paramSocial.page === PARAMS_DEFAULT.page) {
					newSocialList = [...listSocialMediaResponse];
				}

				setSocialFeedList(newSocialList);
			}
		}
	}, [socialContent]);

	useEffect(() => {
		if (importSocialContentStatus === SUCCEEDED) {
			removeSelectedSocialList(importedSocialContent);
			renderMessageImportBookmark();
			if (bookmarkPostRef.current) {
				bookmarkPostRef.current.resetFilters();
			}
		}

		if (importSocialContentStatus === FAILED || importSocialContentStatus === SUCCEEDED) {
			dispatch(importSocialContentEnd());
		}
	}, [importSocialContentStatus]);

	useEffect(() => {
		if (blockSocialContentStatus === SUCCEEDED) {
			removeSelectedSocialList(blockedSocialContent);
			message.info(t('social_search.message.block_content_success'));
		}

		if (blockSocialContentStatus === SUCCEEDED || blockSocialContentStatus === FAILED) {
			dispatch(blockSocialContentEnd());
		}
	}, [blockSocialContentStatus]);

	useEffect(() => {
		if (blockSocialUsernameStatus === SUCCEEDED) {
			const socialContents: Array<SocialContentContainerDto> = [];
			socialFeedList.forEach((item) => {
				if (blockedSocialUsername.includes(item.username)) {
					socialContents.push(item);
				}
			});
			removeSelectedSocialList(socialContents);

			const listNew: FeedDto[] = listFeed.map((item) => {
				if (item.id === paramSocial.id) {
					return {
						...item,
						excludedUsernames: [...item.excludedUsernames, ...usernameBlock],
					};
				}
				return item;
			});
			const listFeedActive = listNew.filter(
				(item: FeedDto) => item.status === STATUS_SETTING.LIVE,
			);
			dispatch(resetSocialSearchSetting({ listFeedActive, listFeed: listNew }));

			message.info(t('social_search.message.block_creator_success'));
		}

		if (blockSocialUsernameStatus === FAILED || blockSocialUsernameStatus === SUCCEEDED) {
			dispatch(blockSocialUsernameEnd());
			setUsernameBlock([]);
		}
	}, [blockSocialUsernameStatus]);

	useEffect(() => {
		const listStatus = [
			importSocialContentStatus,
			blockSocialContentStatus,
			blockSocialUsernameStatus,
		];
		if (listStatus.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus.includes(FAILED)) {
			message.error(error);
		}
		if (listStatus.includes(FAILED) || listStatus.includes(SUCCEEDED)) {
			setIsLoading(false);
			setTypeAction('');
		}
	}, [importSocialContentStatus, blockSocialContentStatus, blockSocialUsernameStatus]);

	const removeSelectedSocialList = (ids: SocialContentContainerDto[]) => {
		if (!ids || ids?.length === 0) {
			return;
		}
		let contentListTemp: Array<SocialContentContainerDto> = [...socialFeedList];
		ids.forEach((k: SocialContentContainerDto) => {
			contentListTemp = contentListTemp.filter((item) => {
				return convertSocialId(item) !== convertSocialId(k);
			});
		});
		handleClearSelect();
		setSocialFeedList([...contentListTemp]);
		if (socialDetail) {
			const idx = socialFeedList?.findIndex(
				(item) => convertSocialId(item) === convertSocialId(socialDetail),
			);
			let contentNewSelect = null;
			if (idx <= contentListTemp.length - 1) {
				contentNewSelect = contentListTemp[idx];
			}
			if (refSummary && refSummary?.current?.handleChangeContentSelect) {
				refSummary.current.handleChangeContentSelect(contentNewSelect);
			}
		}
	};

	const handleDirectBookmark = () => {
		message.destroy('message_redirect_bookmark');
		history.push(ROUTE_PATH.BOOKMARKS);
	};

	const renderMessageImportBookmark = () => {
		const propsMessage: any = {
			i18nKey: 'social_search.message.import_content_success',
			components: {
				linkTo: <StyledDirectLink onClick={handleDirectBookmark} />,
			},
		};
		message.success({
			content: <Trans t={t} {...propsMessage} />,
			duration: 3,
			key: 'message_redirect_bookmark',
		});
	};

	const clearSocialListSelection = () => {
		if (selectionRef?.current && selectionRef.current.clearSelection) {
			selectionRef.current.clearSelection();
		}
	};

	const handleClearSelect = () => {
		clearSocialListSelection();
		setSelectContentList([]);
	};

	const handleChangeIdFeed = (activeFeed: FeedDto) => {
		// Check id to prevent the function from running after updating feed
		if (
			!paramSocial.id ||
			paramSocial?.id === ID_EMPTY ||
			(paramSocial?.id && paramSocial?.id !== activeFeed.id)
		) {
			setParamSocial({
				page: PARAMS_DEFAULT.page,
				maxRecords: PARAMS_DEFAULT.maxRecords,
				...activeFeed,
			});
			setFilter({});
			setActionTypeOnFilter('');
		}
	};

	const handleFilterContent = (requestData: FieldFormSettingFeed) => {
		if (!isEqual(requestData, paramSocial)) {
			unstable_batchedUpdates(() => {
				setParamSocial({ ...paramSocial, ...requestData, page: PARAMS_DEFAULT.page });
			});
		}
	};

	const onScrollStop = () => {
		const scrollbarTop =
			(scrollbars?.current?.scrollTop + scrollbars?.current?.clientHeight) /
				scrollbars?.current?.scrollHeight >=
			0.75;

		if (
			scrollbarTop &&
			socialContent.listSocialMediaResponse.length > 0 &&
			!fetchingSocialContent
		) {
			setIsLoading(true);
			setParamSocial({ ...paramSocial, page: paramSocial.page + 1 });
		}
	};

	const handleSelecting = (selectedSocial: Array<SocialContentContainerDto>) => {
		if (
			selectedSocial.length > selectContentList.length &&
			selectedSocial.length > RATE_LIMIT.IMPORT
		) {
			message.warning(t('social_search.message.import_content_rate_limit'));
		}

		setSelectContentList(selectedSocial);
	};

	const handleSelection = (selectingItems: any) => {
		const selectContent: Array<SocialContentContainerDto> = [];

		selectingItems.forEach((selectingItem: any) => {
			const { props } = selectingItem;
			selectContent.push(props.social);
		});

		if (
			refEventSelect?.current?.handleSectionCustom &&
			typeof refEventSelect.current.handleSectionCustom === 'function'
		) {
			refEventSelect.current.handleSectionCustom(
				selectContent.map((item) => convertSocialId(item)),
			);
		}

		if (!refEventSelect?.current?.isShift) {
			handleSelecting(selectContent);
		}
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		const socials: SocialContentContainerDto[] = [];
		socialFeedList.forEach((item) => {
			if (list.includes(convertSocialId(item))) {
				socials.push(item);
			}
		});
		handleSelecting(socials);
	};

	const onSelectItems = (id?: string) => {
		handleSelectItemWithLib(selectionRef, id);
	};

	const handleFilters = () => {
		let val = '';
		if (typeCollapse !== TYPE_COLLAPSE_ACTION.FILTER) {
			val = TYPE_COLLAPSE_ACTION.FILTER;
		}
		setTypeCollapse(val);
	};

	const handleResetFilter = () => {
		if (filterRef?.current && filterRef?.current?.clearFilterUsingRef) {
			filterRef?.current?.handleResetValues(detailFeed);
			setActionTypeOnFilter('');
			setParamSocial({
				page: PARAMS_DEFAULT.page,
				maxRecords: PARAMS_DEFAULT.maxRecords,
				id: paramSocial?.id,
				...detailFeed,
			});
		}
	};

	const saveLabelAndFlag = (labels: string[]) => {
		let newLabels: string[] = [...labels];
		clientSettings.labels.forEach((existingLabel: string) => {
			newLabels = newLabels.filter((label) => {
				return existingLabel !== label;
			});
		});
		if (newLabels.length > 0) {
			dispatch(updateLabels([...newLabels, ...clientSettings.labels]));
		}
	};

	const handleImportAction = (fields?: SocialContentFiltersDto) => {
		if (selectContentList.length === 0) {
			return;
		}
		if (selectContentList.length > RATE_LIMIT.IMPORT) {
			message.warning(t('social_search.message.import_content_rate_limit'));
			return;
		}
		setTypeCollapse('');

		const importSocialContentPayload: ImportSocialContentPayloadDto = {
			ids: selectContentList,
			socialSource: '',
		};

		if (fields) {
			formatSocialSearchPayload(importSocialContentPayload, fields);

			if (fields.labels) {
				saveLabelAndFlag(fields.labels);
			}
		}

		dispatch(importSocialFeedContentRequest(importSocialContentPayload));
	};

	const onConfirmBlockContent = (all?: boolean) => {
		const blockSocialContentPayload: BlockSocialContentPayloadDto = {
			socialSource: '',
			ids: [...selectContentList],
		};
		if (!all) {
			blockSocialContentPayload.id = detailFeed?.id;
		}
		dispatch(blockSocialFeedContentRequest(blockSocialContentPayload));
	};

	const onConfirmBlockCreator = (all?: boolean) => {
		const selectedContentListTemp: Array<UsernameSocialTypes> = selectContentList.map(
			(socialContent: SocialContentContainerDto) => {
				return { username: socialContent.username, source: socialContent.socialSource };
			},
		);

		const blockSocialUsernamePayload: BlockSocialUFeedUsernamePayloadDto = {
			usernames: selectedContentListTemp.filter(
				(value, index, usernameArray) => usernameArray.indexOf(value) === index,
			),
			isDelete: false,
		};
		if (!all) {
			blockSocialUsernamePayload.id = detailFeed?.id;
		}
		setUsernameBlock(blockSocialUsernamePayload.usernames);

		dispatch(blockSocialFeedUsernameRequest(blockSocialUsernamePayload));
	};

	const checkPermissions = (authResponse: any, isCallAPI: boolean) => {
		window.FB.api(`/${authResponse.userID}/permissions`, (response: any) => {
			if (response && !response.error) {
				const permissions = response.data;
				const findDeclinedPermission = permissions.find(
					(item: any) => item.status === DECLINED_STATUS,
				);

				if (!isEmpty(findDeclinedPermission)) {
					setIsConnected(false);
					setModalLoginRequire(true);
				} else {
					setIsConnected(true);
					setModalLoginRequire(false);
					message.info(t('notification.connected_FB'));

					if (isCallAPI) {
						const requestData = {
							userId: authResponse.userID,
							facebookToken: authResponse.accessToken,
						};

						dispatch(saveFacebookTokenRequest(requestData));
					}
				}
			}
		});
	};

	const handleLoginFacebook = async () => {
		try {
			window.FB.login(
				(response: ReponseLoginFacebook) => {
					const { authResponse, status } = response;

					if (status === CONNECTED) {
						checkPermissions(authResponse, true);
					} else {
						setIsConnected(false);
					}
				},
				{
					scope: SCOPE_ACCESS_FB,
					return_scopes: true,
					enable_profile_selector: true,
				},
			);
		} catch (error) {
			console.log('error', error);
		}
	};

	const renderInstagramConnect = () => {
		return (
			<Modal
				width={559}
				backgroundColor={THEME.colors.darkBlue3}
				okText={t('button.connect')}
				cancelText={t('button.later')}
				isOpen={modalLoginRequire}
				onSubmit={handleLoginFacebook}
				onClose={() => setModalLoginRequire(false)}>
				<StyledTitle margin="0 0 8px 0" fontSize="1.143rem" fontWeight="600">
					{t('link_instagram.title')}
				</StyledTitle>
				<p>{t('link_instagram.description')}</p>
			</Modal>
		);
	};

	const renderSider = () => {
		const isFilterTemp = typeCollapse === TYPE_COLLAPSE_ACTION.FILTER;
		const isBookmarkPost = typeCollapse === TYPE_COLLAPSE_ACTION.BOOKMARK_POST;
		const collapsed = isFilterTemp || isBookmarkPost;

		return (
			<StyledSider
				width={`${defaultSiderWidth}px`}
				margin={collapsed}
				collapsedWidth="0"
				style={{ margin: '0' }}
				trigger={null}
				collapsible
				collapsed={!collapsed}
				onCollapse={() => handleFilters()}>
				<FilterFeed
					isHidden={!isFilterTemp}
					ref={filterRef}
					closeFilters={() => setTypeCollapse('')}
					handleFilterContent={handleFilterContent}
				/>
				<BookmarkPost
					className="wrapper_bookmark_feed"
					classContainer="container_bookmark_feed"
					ref={bookmarkPostRef}
					isHidden={!isBookmarkPost}
					closeActions={() => setTypeCollapse('')}
					handleImportAction={handleImportAction}
					handleConnect={handleLoginFacebook}
					isConnected={isConnected}
				/>
			</StyledSider>
		);
	};

	const otherPropConfirm = useMemo(() => {
		const result: any = {};

		if (typeAction === TYPE_ACTIONS.BLOCK_CONTENT) {
			result.width = 550;

			result.okText = t('button.hide_from_this_feed');
			result.otherConfirmText = t('button.hide_from_all_feeds');
			result.onSubmit = () => onConfirmBlockContent();
			result.onOtherConfirm = () => onConfirmBlockContent(true);
			result.confirmOtherLoading = isLoading;
			result.confirmLoading = isLoading;
		}
		if (typeAction === TYPE_ACTIONS.BLOCK_CREATOR) {
			result.width = 550;
			result.okText = t('button.block_from_this_feed');
			result.otherConfirmText = t('button.block_from_all_feeds');
			result.onSubmit = () => onConfirmBlockCreator();
			result.onOtherConfirm = () => onConfirmBlockCreator(true);
			result.confirmOtherLoading = isLoading;
			result.confirmLoading = isLoading;
		}

		return result;
	}, [typeAction, isLoading]);

	const renderContentPopupConfirm = () => {
		let result = '';
		switch (typeAction) {
			case TYPE_ACTIONS.BLOCK_CONTENT:
				result = t('social_aggregator.block_content');
				break;
			case TYPE_ACTIONS.BLOCK_CREATOR:
				result = t('social_aggregator.block_creator');
				break;

			default:
				break;
		}

		return result;
	};

	useEffect(() => {
		const elm = document.getElementById(ID_CONTAINER_FEED);

		if (scrollbars.current && elm && !fetchingSocialContent && !isLoading) {
			elm.addEventListener('scroll', onScrollStop);
			document.addEventListener('dragend', onScrollStop);

			return () => {
				elm.removeEventListener('scroll', onScrollStop);
				document.addEventListener('dragend', onScrollStop);
			};
		}
		return () => {};
	}, [scrollbars.current, fetchingSocialContent, socialFeedList, isLoading, socialContent]);

	const handleRedirectFeed = (isCreate?: boolean) => {
		const state: any = {};
		if (isCreate) {
			state.isCreate = true;
		} else {
			state.openSetting = true;
		}
		history.push({
			pathname: ROUTE_PATH.SETTINGS_SOCIAL_AGGREGATOR,
			state: {
				...state,
			},
		});
	};

	const renderContentEmpty = () => {
		const result: any = {};
		if (listFeed.length === 0) {
			result.description = (
				<StyledWrapperContent fontSize="16px" lineHeight="22px" color={THEME.colors.gray5}>
					<div>{t('feeds.no_feed')}</div>
					<Trans
						t={t}
						values={{}}
						i18nKey="feeds.sub_go_here_create"
						components={{
							elm: <StyledGoHere onClick={() => handleRedirectFeed(true)} />,
						}}
					/>
					<Tooltip title={t('feeds.sub_question')}>
						<StyledTitle fontSize="16px" className="unset_height" margin="30px 0 0 0">
							{t('feeds.question_this')}
						</StyledTitle>
					</Tooltip>
				</StyledWrapperContent>
			);
		} else {
			if (listFeedActive.length === 0) {
				result.description = (
					<StyledWrapperContent
						fontSize="16px"
						lineHeight="22px"
						color={THEME.colors.gray5}>
						<div>{t('feeds.no_social')}</div>
						<Trans
							t={t}
							values={{}}
							i18nKey="feeds.sub_go_here_enable"
							components={{
								elm: <StyledGoHere onClick={() => handleRedirectFeed()} />,
							}}
						/>
					</StyledWrapperContent>
				);
			}
		}

		return result;
	};

	const valueContext = {
		setTypeCollapse,
		typeCollapse,
		socialFeedList,
		isFilter: actionTypeOnFilter === ACTION_TYPE_ON_FILTER.FILTER_FEED,
		filter,
		setFilter,
		paramSocial,
		setParamSocial,
		handleResetFilter,
		selectContentList,
		handleClearSelect,
		setTypeAction,
		handleImportAction,
		onSelectItems,
		setSocialDetail,
		socialDetail,
		col,
		setCol,
		fullThumb,
		setFullThumb,
		actionTypeOnFilter,
		setActionTypeOnFilter,
	};

	return (
		<FeedsManagerContext.Provider value={valueContext}>
			<LoadingWrapper
				zIndex={971}
				isLoading={
					isLoading ||
					loadingListFeed ||
					fetchingSocialContent ||
					updateFeedStatus === IN_PROGRESS
				}>
				{!loadingListFeed && (
					<>
						{modalLoginRequire && renderInstagramConnect()}
						<ConfirmModal
							width={450}
							isOpen={typeAction ? true : false}
							onClose={() => setTypeAction('')}
							confirmLoading={isLoading}
							{...otherPropConfirm}>
							{renderContentPopupConfirm()}
						</ConfirmModal>

						<>
							<MetricFeeds />
							<StyledContainerFeeds>
								<Layout
									style={{
										height: '100%',

										transition: '0.2s ease-out',
										backgroundColor: THEME.colors.darkBlue2,
									}}>
									<StyledWrapperContent
										position="relative"
										width="100%"
										height="100%">
										<ActionBarFeeds />

										<CustomSelection
											ref={refEventSelect}
											ids={socialFeedList.map((item) =>
												convertSocialId(item),
											)}
											selectIds={selectContentList.map((item) =>
												convertSocialId(item as any),
											)}
											refSelection={selectionRef}
											handleSelectNewList={handleSelectNewList}
										/>
										<StyledScrollContainer
											className="custom_scroll_bar"
											ref={(el) => {
												scrollbars.current = el;
											}}
											id={ID_CONTAINER_FEED}>
											{socialFeedList.length > 0 ? (
												<SelectableGroup
													className="selectable-group"
													clickClassName="tick"
													ref={selectionRef}
													scrollContainer={`#${ID_CONTAINER_FEED}`}
													enableDeselect={true}
													tolerance={12}
													deselectOnEsc={true}
													allowClickWithoutSelected={true}
													selectOnClick={false}
													key={'id'}
													onSelectionFinish={handleSelection}>
													<SummaryFeed ref={refSummary} />
												</SelectableGroup>
											) : (
												!fetchingSocialContent && (
													<StyledWrapper
														height="100%"
														className="height_full">
														<StyledEmpty
															{...renderContentEmpty()}
															image={IconEmpty}
														/>
													</StyledWrapper>
												)
											)}
										</StyledScrollContainer>
									</StyledWrapperContent>
									{renderSider()}
								</Layout>
							</StyledContainerFeeds>
						</>
					</>
				)}
			</LoadingWrapper>
		</FeedsManagerContext.Provider>
	);
};

export default Feeds;
