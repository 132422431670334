import { STATUSCODE } from '@constants/APIs';
import { EMAIL_TEMPLATE_FOLDER_TYPES } from '@constants/messages';
import { ConfirmModal, Empty } from '@cores/index';
import {
	getEmailTemplatesRequest,
	restoreEmailTemplatesRequest,
	selectArchivedEmailTemplate,
} from '@stores/actions';
import { StyledCenterContainer } from '@styled/Messages/EmailTemplatesStyled';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuTemplates from '../MenuTemplates';

const Archived: React.FC = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [templateId, setTemplateId] = useState<number>(0);
	const {
		emailTemplate: {
			restoreTemplateStatus = 0,
			restoreLoading = false,
			getEmailTemplateStatus = false,
			error = '',
			archived: { templates = [] } = {},
		} = {},
	} = useSelector((state: any) => state.messages);

	useEffect(() => {
		if (restoreTemplateStatus === STATUSCODE.SUCCESS) {
			message.success(t('email_templates.notification.restore_successfully'));
			dispatch(getEmailTemplatesRequest({ type: EMAIL_TEMPLATE_FOLDER_TYPES.ARCHIVED }));
			setOpenModal(false);
		} else if (restoreTemplateStatus === STATUSCODE.FAILED400) {
			message.error(error);
			setOpenModal(false);
		}
	}, [restoreTemplateStatus]);

	const onSelectItem = (selectId: number) => {
		dispatch(selectArchivedEmailTemplate({ selectId }));
	};

	const onClickRestoreTemplate = (id: number) => {
		setOpenModal(true);
		setTemplateId(id);
	};

	const onRestoreTemplate = () => {
		if (templateId)
			dispatch(restoreEmailTemplatesRequest({ id: templateId, isDefault: false }));
	};

	return (
		<>
			{templates.length > 0 ? (
				<MenuTemplates
					onClickRestoreTemplate={onClickRestoreTemplate}
					onSelectItem={onSelectItem}
					dataTemplate={templates}
				/>
			) : (
				<StyledCenterContainer>
					<Empty />
				</StyledCenterContainer>
			)}
			<ConfirmModal
				width={400}
				confirmLoading={getEmailTemplateStatus || restoreLoading}
				okText={t('button.yes')}
				cancelText={t('button.no')}
				isOpen={openModal}
				onSubmit={onRestoreTemplate}
				onClose={() => setOpenModal(false)}>
				{t('email_templates.confirm.restore_template')}
			</ConfirmModal>
		</>
	);
};

export default Archived;
