import { IconCopy, IconStar } from '@assets/icons';
import { communityURL } from '@config/index';
import { PATTERNS, TYPE_FORMAT_DATE } from '@constants/common';
import { MAX_LENGTH_CHARACTER } from '@constants/content/contentLibrary';
import { TYPE_CUSTOM_FIELD } from '@constants/content/widgetManager';
import {
	ACTION_TYPE_ON_LANDING_PAGE,
	FIELD_NAME,
	LANDING_PAGE_SELECTORS,
	LANDING_PAGE_STATUS,
} from '@constants/landingPageManager';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import LandingPageManagerContext from '@contexts/LandingPageManager';
import ButtonGroup from '@cores/ButtonGroup';
import EditableElement from '@cores/EditableElement';
import { ConfirmModal } from '@cores/Modal';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { getUser } from '@helpers/userHelpers';
import {
	InformationItemDto,
	LandingPageDto,
	LandingPagePayloadDto,
} from '@models/landingpagemanager';
import { LandingPageStoreDto } from '@models/landingpagemanager/stores';
import {
	clonePublishedLandingPageRequest,
	storeLandingPageDataRequest,
	updateLandingPageRequest,
} from '@stores/actions';
import {
	StyledButton,
	StyledCol,
	StyledForm,
	StyledFormItem,
	StyledIcon,
	StyledInput,
	StyledRow,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { validateInputToPattern } from '@utils/common';
import { getPathnameFromURL, onCopyValue, splitStringAtPosition } from '@utils/funcHelper';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import _ from 'lodash';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type LandingPageItemProps = {
	panelKey: number | string;
	landingPageItem: LandingPageDto;
};

const CONFIRMATION_TYPE = {
	CHANGE_URL: 'CHANGE_URL',
};

const LandingPageItem = (props: LandingPageItemProps) => {
	const dispatch = useDispatch();
	const [form] = useForm();
	const { landingPageItem, panelKey } = props;
	const user = getUser();

	useEffect(() => {
		if (panelKey !== landingPageItem.id) {
			handleReset();
		}
	}, [panelKey]);

	const { t } = useTranslation();
	const { setConfirmationModalType } = useContext(LandingPageManagerContext);
	const {
		updateLandingPageStatus,
		actionTypeOnLandingPage,
		isSettingDefaultLandingPage,
	}: LandingPageStoreDto = useSelector((state: any) => state.landingPage);

	const [currentValues, setCurrentValues] = useState<LandingPageDto>(
		_.cloneDeep(landingPageItem),
	);
	const [changedValues, setChangeValues] = useState<LandingPagePayloadDto>();
	const [confirmationModalItem, setConfirmationModalItem] = useState<string>('');

	useEffect(() => {
		if (landingPageItem) {
			const tempValues = { ..._.cloneDeep(landingPageItem), ...changedValues };

			setCurrentValues(tempValues);
			form.setFieldsValue({
				...tempValues,
				[FIELD_NAME.META_DESCRIPTION]: tempValues.metadata?.metaDescription,
				[FIELD_NAME.META_TITLE]: tempValues.metadata?.metaTitle,
				[FIELD_NAME.LANDING_PAGE_URL]: getPathnameFromURL(tempValues.url),
			});
		}
	}, [landingPageItem]);

	useEffect(() => {
		if (updateLandingPageStatus === SUCCEEDED) {
			if (
				actionTypeOnLandingPage ===
					ACTION_TYPE_ON_LANDING_PAGE.UPDATE_LANDING_PAGE_INFORMATION ||
				actionTypeOnLandingPage ===
					ACTION_TYPE_ON_LANDING_PAGE.UPDATE_LANDING_PAGE_INFORMATION_WITH_URL
			) {
				setConfirmationModalItem('');
			}
			if (!isSettingDefaultLandingPage) {
				setChangeValues({});
			}
			dispatch(
				storeLandingPageDataRequest({
					actionTypeOnLandingPage: null,
					updateLandingPageStatus: NONE,
					isSettingDefaultLandingPage: false,
				}),
			);
		}

		if (updateLandingPageStatus === FAILED) {
			handleReset();
			setConfirmationModalItem('');
		}
	}, [updateLandingPageStatus, isSettingDefaultLandingPage, actionTypeOnLandingPage]);

	const hasChanges = useMemo(() => {
		const ordinaryValues = _.cloneDeep(landingPageItem);
		let comparedValues = _.cloneDeep(landingPageItem);
		comparedValues = { ...comparedValues, ...changedValues };

		return !_.isEqual(ordinaryValues, comparedValues);
	}, [changedValues]);

	const urlEditElement = (
		<StyledRow align="middle">
			<StyledCol span={14}>
				<StyledText className="overflow">{`${communityURL}/`}</StyledText>
			</StyledCol>
			<StyledCol span={10}>
				<StyledFormItem margin="0" name={FIELD_NAME.LANDING_PAGE_URL}>
					<StyledInput
						maxLength={MAX_LENGTH_CHARACTER}
						autoFocus
						bgColor={THEME.colors.transparent}
						onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
							validateInputToPattern(event, PATTERNS.ALPHABETS_NUMBER);
						}}
					/>
				</StyledFormItem>
			</StyledCol>
		</StyledRow>
	);

	const handleCopyURL = () => {
		onCopyValue(currentValues.url);
		message.success(t('message.copied'));
	};

	const renderLandingPageItemDetail = () => {
		const { description, publishedAt, publishedBy, metadata } = currentValues || {};
		const { metaTitle = '', metaDescription = '' } = metadata || {};

		const list = [
			{
				title: t('landing_page_manager.detail.landing_page_url'),
				description: (
					<StyledRow justify="space-between">
						<StyledCol span={20}>
							<EditableElement
								form={form}
								onClick={() => {
									window.open(currentValues.url, '_blank');
								}}
								fieldName={FIELD_NAME.LANDING_PAGE_URL}
								info={
									<StyledText
										color={THEME.colors.blueBase}
										textDecoration="underline"
										padding="2px 0"
										className="overflow">
										{currentValues.url}
									</StyledText>
								}
								otherIconProps={{
									className: 'absolute',
									right: '-24px',
									top: '0',
								}}
								backgroundInput={THEME.colors.transparent}
								editElement={urlEditElement}
							/>
						</StyledCol>
						<StyledCol>
							<StyledIcon onClick={handleCopyURL}>
								<IconCopy />
							</StyledIcon>
						</StyledCol>
					</StyledRow>
				),
				fieldName: FIELD_NAME.LANDING_PAGE_URL,
			},
			{
				title: t('landing_page_manager.detail.meta_title'),
				description: metaTitle,
				fieldName: FIELD_NAME.META_TITLE,
			},
			{
				title: t('landing_page_manager.detail.meta_description'),
				description: metaDescription,
				fieldName: FIELD_NAME.META_DESCRIPTION,
			},
		];

		return (
			<>
				{renderContentItem(
					{
						title: t('landing_page_manager.detail.description'),
						description,
						fieldName: FIELD_NAME.DESCRIPTION,
					},
					{
						margin: '0 0 6px 0',
						maxLength: 255,
					},
				)}

				{publishedBy && publishedAt && (
					<StyledText margin="0 0 12px 0" color={THEME.colors.gray3} fontSize="12px">
						<i>
							{t('landing_page_manager.detail.latest_changes', {
								author: publishedBy,
								time: convertUtcToLocalTimeWithFormat(
									+publishedAt,
									TYPE_FORMAT_DATE.TIME_AT,
								),
							})}
						</i>
					</StyledText>
				)}

				<StyledWrapperContent
					padding="0 0 0 10px"
					margin="14px 0 0 0"
					borderLeft={`5px solid ${THEME.colors.darkBlue4}`}>
					{list.map((item, index) => {
						const key = `${index}_detail`;
						return renderContentItem(item, { key, margin: '0 0 10px 0' });
					})}
				</StyledWrapperContent>
			</>
		);
	};

	const renderContentItem = (item: InformationItemDto, otherProps: any) => {
		const otherElementProps: any = {};
		const MAX_CHARACTERS = 155;

		if (item.fieldName !== FIELD_NAME.LANDING_PAGE_URL) {
			otherElementProps.inputType = TYPE_CUSTOM_FIELD.paragraph;
			otherElementProps.inputHeight = '50px';
		}

		return (
			<StyledWrapperContent {...otherProps}>
				<StyledText fontSize="12px" color={THEME.colors.gray3}>
					{item.title}
				</StyledText>
				{item.fieldName !== FIELD_NAME.LANDING_PAGE_URL ? (
					<EditableElement
						form={form}
						info={splitStringAtPosition(item.description)}
						fieldName={item.fieldName}
						otherTextAreaProps={{
							autoSize: { minRows: 2, maxRows: 2 },
							rows: 2,
							maxLength: otherProps.maxLength || MAX_CHARACTERS,
						}}
						backgroundInput={THEME.colors.transparent}
						{...otherElementProps}
					/>
				) : (
					item.description
				)}
			</StyledWrapperContent>
		);
	};

	const handleEditLandingPage = () => {
		if (currentValues.referenceId) {
			setConfirmationModalType(ACTION_TYPE_ON_LANDING_PAGE.EDIT_LANDING_PAGE_WITH_REFERENCE);
		} else {
			if (currentValues.status === LANDING_PAGE_STATUS.PUBLISHED) {
				dispatch(clonePublishedLandingPageRequest({ id: currentValues.id }));
			} else {
				dispatch(
					storeLandingPageDataRequest({
						landingPageDetailResult: currentValues,
					}),
				);
			}
		}
	};

	const onValuesChange = (changedField: any) => {
		const fieldName = Object.keys(changedField)[0];
		const value = changedField[fieldName];
		const changedValuesTemp = { ...changedValues };

		if (fieldName === FIELD_NAME.META_DESCRIPTION || fieldName === FIELD_NAME.META_TITLE) {
			const metadataTemp = {
				...currentValues.metadata,
				[fieldName]: value,
			};
			changedValuesTemp.metadata = metadataTemp;
		} else if (fieldName === FIELD_NAME.LANDING_PAGE_URL) {
			changedValuesTemp[fieldName] = `${communityURL}/${value}`;
		} else {
			changedValuesTemp[fieldName] = value;
		}
		setChangeValues({
			...changedValuesTemp,
		});

		setCurrentValues({ ...currentValues, ...changedValuesTemp });
	};

	const handleReset = () => {
		form.setFieldsValue({
			...landingPageItem,
			[FIELD_NAME.LANDING_PAGE_URL]: getPathnameFromURL(landingPageItem.url),
		});
		setChangeValues({});
		setCurrentValues({ ...landingPageItem });
	};

	const handleSaveChanges = (_: any, action?: string) => {
		const params = { ...changedValues };

		if (changedValues?.metadata) {
			params.metadata = JSON.stringify(changedValues.metadata);
		}

		dispatch(
			storeLandingPageDataRequest({
				updatedLandingPage: currentValues,
				actionTypeOnLandingPage:
					action || ACTION_TYPE_ON_LANDING_PAGE.UPDATE_LANDING_PAGE_INFORMATION,
			}),
		);

		dispatch(
			updateLandingPageRequest({
				id: currentValues.id,
				params: { ...params, accountEmail: user.email },
			}),
		);
	};

	const onCloseConfirmationModal = () => {
		setConfirmationModalItem('');
	};

	const renderConfirmationModal = () => {
		const otherProps: any = {};
		const okText: string = t('landing_page_manager.button.publish_to_new_url');
		const cancelText: string = t('button.cancel');
		const content: ReactNode = (
			<StyledText
				dangerouslySetInnerHTML={{
					__html: t('landing_page_manager.message.change_url'),
				}}
			/>
		);

		if (confirmationModalItem === CONFIRMATION_TYPE.CHANGE_URL) {
			otherProps.onSubmit = () =>
				handleSaveChanges(
					{},
					ACTION_TYPE_ON_LANDING_PAGE.UPDATE_LANDING_PAGE_INFORMATION_WITH_URL,
				);
			otherProps.confirmLoading = updateLandingPageStatus === IN_PROGRESS;
		}

		return (
			<ConfirmModal
				width={480}
				maskClosable={true}
				isOpen={!!confirmationModalItem}
				onClose={onCloseConfirmationModal}
				okText={okText}
				cancelText={cancelText}
				{...otherProps}>
				{content}
			</ConfirmModal>
		);
	};

	const onSubmit = () => {
		if (
			!_.isEqual(currentValues.url, landingPageItem.url) &&
			landingPageItem.status !== LANDING_PAGE_STATUS.DRAFT
		) {
			setConfirmationModalItem(CONFIRMATION_TYPE.CHANGE_URL);
		} else {
			form.submit();
		}
	};

	const renderLandingPageName = () => {
		const className = 'landing_page_header_item';
		const handleStyleWithEditing = (isEdit: boolean) => {
			const elements = document.getElementsByClassName(className) as any;
			if (elements) {
				for (let index = 0; index < elements.length; index += 1) {
					const element = elements[index];
					element.style.width = isEdit ? '80%' : 'unset';
				}
			}
		};

		return (
			<StyledWrapperContent
				maxWidth="calc(100% - 70px)"
				className={`header ${className}`}
				position="absolute"
				top="20px"
				zIndex="99"
				display="inline">
				<EditableElement
					className={LANDING_PAGE_SELECTORS.LANDING_PAGE_NAME}
					form={form}
					info={
						<StyledWrapperContent maxWidth="90%" display="inline-flex">
							<StyledText
								className="overflow"
								title={currentValues.name}
								padding="4px 0"
								color={THEME.colors.white}
								fontSize="20px"
								fontWeight={600}>
								{currentValues.name}
							</StyledText>
							{currentValues.isDefault && (
								<StyledIcon
									className="star"
									margin="-2px 0 0 8px"
									fillPath={THEME.colors.yellowBase}>
									<IconStar />
								</StyledIcon>
							)}
						</StyledWrapperContent>
					}
					fieldName={FIELD_NAME.NAME}
					backgroundInput={THEME.colors.transparent}
					effectFunc={handleStyleWithEditing}
				/>
			</StyledWrapperContent>
		);
	};

	return (
		<>
			<StyledForm form={form} onValuesChange={onValuesChange} onFinish={handleSaveChanges}>
				{renderLandingPageName()}
				{renderLandingPageItemDetail()}
				<StyledWrapperContent margin="24px 0 0">
					{hasChanges ? (
						<ButtonGroup
							hasChanges={hasChanges}
							onCancel={handleReset}
							onSubmit={onSubmit}
							genericMargin="0 0 0 8px"
							margin="0 8px"
							width="100%"
							textAlign="right"
							isLoading={
								updateLandingPageStatus === IN_PROGRESS &&
								!isSettingDefaultLandingPage &&
								!confirmationModalItem
							}
							otherPropsText={{
								textAlign: 'center',
							}}
						/>
					) : (
						<StyledButton width="100%" type="primary" onClick={handleEditLandingPage}>
							{t('button.edit_this_page')}
						</StyledButton>
					)}
				</StyledWrapperContent>
			</StyledForm>
			{confirmationModalItem && renderConfirmationModal()}
		</>
	);
};
export default LandingPageItem;
