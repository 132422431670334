import { IconExpandMore, IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@assets/icons';
import { platformURL } from '@config/index';
import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import {
	ELEMENT_SELECTOR,
	TASK_ACTION_TYPE,
	TASK_DETAIL_MODAL_TYPES,
	TASK_STATUS,
} from '@constants/taskManager';
import TaskManagerContext from '@contexts/TaskManager';
import TaskDetailContext from '@contexts/TaskManager/TaskDetail';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { getUser } from '@helpers/userHelpers';
import { TaskDetailDto, UpdateTaskPayloadDto } from '@models/taskmanager';
import { TaskManagerStoreDto } from '@models/taskmanager/store';
import {
	createTaskRequest,
	deleteTaskRequest,
	getTaskDetailsRequest,
	storeDataTaskManager,
	updateTaskRequest,
} from '@stores/taskmanager/taskmanager.actions';
import {
	StyledButton,
	StyledCol,
	StyledFlex,
	StyledIcon,
	StyledRow,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledAssignedUser,
	StyledTaskArrow,
	StyledTaskDetail,
} from '@styled/TaskManager/TaskManagerStyled';
import { message } from 'antd';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TaskDescription from '../TaskDescription';
import TaskStatus from '../TaskStatus';
import ReminderEmail from './ReminderEmail';
import TaskEditor from './TaskEditor';
import TaskHistory from './TaskHistory';

type TaskDetailProps = {
	onCancel: () => void;
	taskId?: number;
	action?: string;
	taskPosition?: {
		isLast: boolean;
		isFirst: boolean;
	};
	handleSwitchTask: (action: string) => void;
	isDetailFromSearchParams?: boolean;
};

const TaskDetail = (props: TaskDetailProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const taskDescriptionRef = useRef<any>();
	const taskEditorRef = useRef<any>();
	const taskHistoryRef = useRef<any>();
	const { onCancel, taskId, action, taskPosition, handleSwitchTask, isDetailFromSearchParams } =
		props;
	const {
		taskDetails,
		createTaskStatus = NONE,
		getTaskDetailsStatus = NONE,
		deleteTaskStatus = NONE,
		addCommentStatus = NONE,
		updateCommentStatus = NONE,
		notifyMentionedUserStatus = NONE,
	}: TaskManagerStoreDto = useSelector((state: any) => state.taskManager);
	const { assignedAccountOptions = [] } = useContext(TaskManagerContext);

	const user = getUser();

	const defaultTask = {
		description: '',
		assignedToEmail: user.email,
		status: TASK_STATUS.TO_DO,
	};

	const [currentTask, setCurrentTask] = useState<TaskDetailDto>(_.cloneDeep(defaultTask));
	const [modalType, setModalType] = useState<string>('');
	const [actionOnTask, setActionOnTask] = useState<string>('');
	const [taskDescription, setTaskDescription] = useState<string>('');

	useEffect(() => {
		return () => {
			message.destroy();
		};
	}, []);

	useEffect(() => {
		if (taskId) {
			dispatch(getTaskDetailsRequest({ id: taskId }));

			return () => {
				setCurrentTask(_.cloneDeep(defaultTask));
			};
		}

		return () => {};
	}, [taskId]);

	useEffect(() => {
		if (taskDetails) {
			setCurrentTask(taskDetails);
			setTaskDescription(taskDetails?.description);

			return () => {
				dispatch(
					storeDataTaskManager({
						taskDetails: null,
					}),
				);
			};
		}

		return () => {
			dispatch(
				storeDataTaskManager({
					taskHistory: null,
				}),
			);
		};
	}, [taskDetails]);

	useEffect(() => {
		if (deleteTaskStatus === SUCCEEDED) {
			if (taskPosition?.isLast) {
				onCancel();
			} else {
				handleSwitchTask(TYPE_ACTIONS.NEXT);
			}
			setModalType('');
		}
	}, [deleteTaskStatus]);

	useEffect(() => {
		setCurrentTask({ ...currentTask, description: taskDescription });

		if (taskId && !_.isEqual(taskDescription, currentTask.description)) {
			handleUpdateTask({ ...currentTask, description: taskDescription });
		}
	}, [taskDescription, taskId]);

	const isAddingNew = useMemo(() => action && action === TASK_ACTION_TYPE.ADD_NEW_TASK, [action]);

	const showErrorMsg = () => {
		message.destroy();
		message.error(
			t('validate.common_required', {
				field: t('task_manager.details.task_description'),
			}),
		);
	};

	const handleAddNewTask = () => {
		if (!currentTask.description) {
			showErrorMsg();
			return;
		}
		dispatch(createTaskRequest(currentTask));
	};

	const handleUpdateTask = (updatedTask: UpdateTaskPayloadDto) => {
		if (taskId && updatedTask.description) {
			const payload = {
				id: taskId,
				params: { ...updatedTask },
			};
			dispatch(updateTaskRequest(payload));
		}
	};

	const renderFooter = () => {
		return isAddingNew
			? [
					<StyledButton isDisabled={createTaskStatus === IN_PROGRESS} onClick={onCancel}>
						{t('button.cancel')}
					</StyledButton>,
					<StyledButton
						loading={createTaskStatus === IN_PROGRESS}
						type="primary"
						onClick={handleAddNewTask}>
						{t('button.create')}
					</StyledButton>,
			  ]
			: [
					<StyledButton
						onClick={() => {
							setModalType(TASK_DETAIL_MODAL_TYPES.DELETE_TASK);
						}}>
						{t('task_manager.button.delete_task')}
					</StyledButton>,
					<StyledButton onClick={getSharableLink}>
						{t('task_manager.button.share_this_task')}
					</StyledButton>,
			  ];
	};

	const onDeleteTask = () => {
		if (taskId) {
			dispatch(deleteTaskRequest([taskId]));
		}
	};

	const renderConfirmationModal = () => {
		let content: string = '';
		let onSubmit: () => void;
		let okText = t('button.move');
		let cancelText = t('button.cancel');
		const isOpen: boolean =
			modalType === TASK_DETAIL_MODAL_TYPES.DELETE_TASK ||
			modalType === TASK_DETAIL_MODAL_TYPES.UNSAVED_CHANGES;

		if (modalType === TASK_DETAIL_MODAL_TYPES.DELETE_TASK) {
			content = t('task_manager.message.delete_task');
			okText = t('button.delete');
			onSubmit = onDeleteTask;
		}

		if (modalType === TASK_DETAIL_MODAL_TYPES.UNSAVED_CHANGES) {
			content = t('task_manager.message.unsaved_changes');
			okText = t('button.continue_without_saving');
			cancelText = t('button.go_back');
			onSubmit = () => {
				setModalType('');
				setActionOnTask('');
				onSwitchTask(actionOnTask);
			};
		}

		return (
			<ConfirmModal
				width={320}
				confirmLoading={deleteTaskStatus === IN_PROGRESS}
				okText={okText}
				cancelText={cancelText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => {
					setModalType('');
				}}>
				{content}
			</ConfirmModal>
		);
	};

	const getSharableLink = () => {
		if (taskId) {
			const textarea = document.createElement('textarea');
			textarea.value = `${platformURL}${ROUTE_PATH.TASK_MANAGER}?taskId=${taskId}`;
			document.body.appendChild(textarea);
			textarea.select();
			document.execCommand('copy');
			message.success(t('message.copied'));
		}
	};

	const detectUnsavedChanges = (action: string) => {
		let hasChanges = false;
		if (taskDescriptionRef.current) {
			hasChanges = taskDescriptionRef.current.hasDescriptionChanges;
		}
		if (taskEditorRef.current) {
			const hasComment = taskEditorRef.current.hasChanges();
			hasChanges = hasChanges || hasComment;
		}
		if (taskHistoryRef.current) {
			hasChanges = hasChanges || taskHistoryRef.current.hasChanges();
		}

		if (hasChanges) {
			setModalType(TASK_DETAIL_MODAL_TYPES.UNSAVED_CHANGES);
			setActionOnTask(action);
		} else {
			onSwitchTask(action);
		}
	};

	const onSwitchTask = (action: string) => {
		if (taskDescriptionRef.current) {
			taskDescriptionRef.current.cancelEditing();
		}
		if (action === TASK_ACTION_TYPE.CLOSE_DETAILS) {
			onCancel();
			return;
		}
		handleSwitchTask(action || actionOnTask);
	};

	const taskDetailContextValues = {
		taskId,
	};

	const isLoading =
		getTaskDetailsStatus === IN_PROGRESS ||
		addCommentStatus === IN_PROGRESS ||
		updateCommentStatus === IN_PROGRESS ||
		notifyMentionedUserStatus === IN_PROGRESS;

	return (
		<StyledTaskDetail
			className={`task_${taskId ? 'details' : 'create'}`}
			width={610}
			title={<StyledText fontSize="20px">{t('task_manager.details.title')}</StyledText>}
			visible={true}
			centered
			onCancel={() => {
				detectUnsavedChanges(TASK_ACTION_TYPE.CLOSE_DETAILS);
			}}
			footer={renderFooter()}
			keyboard={false}>
			{action !== TASK_ACTION_TYPE.ADD_NEW_TASK && !isDetailFromSearchParams && (
				<>
					{!taskPosition?.isFirst && (
						<StyledTaskArrow
							className={ELEMENT_SELECTOR.TASK_DETAIL_ARROW_BTN}
							positionArrow="left"
							onClick={() => {
								detectUnsavedChanges(TYPE_ACTIONS.PREV);
							}}>
							<IconKeyboardArrowLeft
								className={ELEMENT_SELECTOR.TASK_DETAIL_ARROW_BTN}
							/>
						</StyledTaskArrow>
					)}
					{!taskPosition?.isLast && (
						<StyledTaskArrow
							className={ELEMENT_SELECTOR.TASK_DETAIL_ARROW_BTN}
							positionArrow="right"
							onClick={() => {
								detectUnsavedChanges(TYPE_ACTIONS.NEXT);
							}}>
							<IconKeyboardArrowRight
								className={ELEMENT_SELECTOR.TASK_DETAIL_ARROW_BTN}
							/>
						</StyledTaskArrow>
					)}
				</>
			)}
			<TaskDetailContext.Provider value={taskDetailContextValues}>
				<LoadingWrapper isLoading={isLoading}>
					<StyledWrapperContent
						overflow="auto"
						className="custom_scroll_bar"
						maxHeight="calc(85vh - 130px)"
						padding="24px 24px 12px 24px">
						<StyledWrapperContent margin="0 0 16px 0">
							<StyledText fontSize="16px" margin="0 0 8px 0">
								{t('task_manager.task_list.description')}
							</StyledText>
							<TaskDescription
								ref={taskDescriptionRef}
								isDetails={true}
								isCreating={!taskId}
								description={currentTask?.description}
								className="description_detail"
								onChange={(value: string) => {
									if (taskId && !value) {
										showErrorMsg();
										return;
									}
									setTaskDescription(value);
								}}
							/>
						</StyledWrapperContent>

						<StyledRow gutter={[24, 24]}>
							<StyledCol span={taskId ? 12 : 24}>
								<StyledWrapperContent
									display={taskId ? 'unset' : 'flex'}
									justifyContent="space-between">
									<StyledWrapperContent
										margin={taskId ? '0 0 24px 0' : '0 12px 24px 0'}
										width="100%">
										<StyledText margin="0 0 8px 0" fontSize="16px">
											{t('task_manager.task_list.status')}
										</StyledText>
										<TaskStatus
											status={currentTask?.status || TASK_STATUS.TO_DO}
											width="100%"
											onChange={(status: string) => {
												setCurrentTask({ ...currentTask, status });
												handleUpdateTask({ ...currentTask, status });
											}}
										/>
									</StyledWrapperContent>

									<StyledWrapperContent width="100%">
										<StyledFlex>
											<StyledText margin="0 0 8px 0" fontSize="16px">
												{t('task_manager.task_list.assigned_to')}
											</StyledText>
											{taskId && (
												<StyledText
													margin="0 0 8px 0"
													fontSize="12px"
													textDecoration="underline"
													cursor="pointer"
													onClick={() => {
														setModalType(
															TASK_DETAIL_MODAL_TYPES.SEND_REMINDER_EMAIL,
														);
													}}>
													{t('task_manager.send_reminder_email')}
												</StyledText>
											)}
										</StyledFlex>
										<StyledAssignedUser
											value={currentTask?.assignedToEmail}
											suffixIcon={
												<StyledIcon size={20}>
													<IconExpandMore />
												</StyledIcon>
											}
											options={assignedAccountOptions}
											onSelect={(value: any) => {
												setCurrentTask({
													...currentTask,
													assignedToEmail: value,
												});
												handleUpdateTask({
													...currentTask,
													assignedToEmail: value,
												});
											}}
											showSearch
											optionFilterProp="label"
										/>
									</StyledWrapperContent>
								</StyledWrapperContent>

								{taskId && (
									<StyledWrapperContent
										className={taskId ? '' : 'disabled'}
										margin="16px 0">
										<StyledText fontSize="16px" margin="0 0 8px 0">
											{t('task_manager.details.add_a_comment')}
										</StyledText>
										<StyledWrapperContent
											className="dark_bg"
											width="100%"
											height="200px">
											<TaskEditor ref={taskEditorRef} taskId={taskId} />
										</StyledWrapperContent>
									</StyledWrapperContent>
								)}
							</StyledCol>
							{taskId && (
								<StyledCol className={taskId ? '' : 'disabled'} span={12}>
									<TaskHistory ref={taskHistoryRef} />
								</StyledCol>
							)}
						</StyledRow>
					</StyledWrapperContent>
				</LoadingWrapper>
				{modalType && renderConfirmationModal()}
				{taskId && modalType === TASK_DETAIL_MODAL_TYPES.SEND_REMINDER_EMAIL && (
					<ReminderEmail
						isDetails={true}
						taskIds={[taskId]}
						visible={modalType === TASK_DETAIL_MODAL_TYPES.SEND_REMINDER_EMAIL}
						onCancel={() => {
							setModalType('');
						}}
					/>
				)}
			</TaskDetailContext.Provider>
		</StyledTaskDetail>
	);
};

export default TaskDetail;
