import {
	IconArrowLine,
	IconCheckActive,
	IconRemoveCircle,
	IconRightPending,
	IconTriangleWarning,
} from '@assets/icons';
import { ROUTE_PATH, TYPE_ACTIONS, TYPE_FORMAT_DATE } from '@constants/common';
import { STATUS_TEMPLATE } from '@constants/licensingTemplate';
import { PARAM_TEMPLATE_WIDGET } from '@constants/settings/contentLicensing';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import DropdownLoadMore from '@cores/DropdownLoadMore';
import { Empty } from '@cores/index';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { ParamTemplateTypes, TemplateTypes, TermsTypes } from '@models/settings/contentLicensing';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';
import { fetchTemplateWidgetRequest } from '@stores/actions';
import {
	StyledBoxStatus,
	StyledDivider,
	StyledIcon,
	StyledLinkHref,
	StyledRedirectLink,
	StyledTitle,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import {
	StyledLabelItem,
	StyledLineVertical,
	StyledWrapperListTerms,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { StyledCollapse } from '@styled/ContentLibrary/ContentDetailStyled';
import { StyledWrapperNameTerms } from '@styled/LicensingTemplate/TemplateDetail';
import { StyledCenterContainer } from '@styled/Messages/EmailTemplatesStyled';
import { heightOverlayDropdown } from '@utils/funcHelper';
import { Button, Menu, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { Panel } = StyledCollapse;

type PropTypes = {};

const TemplateTerms = (props: PropTypes) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const { loadingTemplateWidget, templateWidgets, templateDetail }: ContentLicensingStoreDto =
		useSelector((state: any) => state?.contentLicensing);
	const { detailWidget, setDetailWidget, termsSelected, setTermsSelected, setTypeConfirm } =
		useContext(WidgetBuilderContext);

	const [paramTemplate, setParamTemplate] = useState<ParamTemplateTypes>(PARAM_TEMPLATE_WIDGET);
	const [templates, setTemplates] = useState<TemplateTypes[]>([]);
	const [templateAction, setTemplateAction] = useState<TemplateTypes | null>(null);
	const [openDropdown, setOpenDropdown] = useState<boolean>(false);

	const templateId = null;
	const { terms } = templateDetail || {};

	const hasTemplate = templateDetail && detailWidget?.termsTemplateId;

	useEffect(() => {
		if (paramTemplate?.page === 1) {
			setTemplates([]);
		}
		dispatch(fetchTemplateWidgetRequest(paramTemplate));
	}, [paramTemplate]);

	useEffect(() => {
		if (paramTemplate?.page === 1) {
			setTemplates(templateWidgets);
		} else {
			setTemplates([...templates, ...templateWidgets]);
		}
	}, [templateWidgets]);

	const onLoadMoreTemplate = () => {
		if (!loadingTemplateWidget && templateWidgets?.length > 0) {
			setParamTemplate({ ...paramTemplate, page: paramTemplate.page + 1 });
		}
	};

	const renderTooltipTitle = (template: TemplateTypes) => {
		if (template?.status === STATUS_TEMPLATE.APPROVED) {
			return t('content_licensing.publish_and_time', {
				name: template?.createBy,
				time: convertUtcToLocalTimeWithFormat(
					template.statusChangedAt || template.createAt,
					TYPE_FORMAT_DATE.TIME,
				),
			});
		}
		return (
			<Trans
				t={t}
				components={{
					elm: (
						<StyledLinkHref
							fontWeight="normal"
							underline
							href={''}
							color="inherit"
							onClick={(e: React.MouseEvent<HTMLElement>) => {
								e.preventDefault();
								e.stopPropagation();
								history.push(`${ROUTE_PATH.CONTENT_LICENSING}/${template.id}`);
							}}
						/>
					),
				}}
				i18nKey="content_licensing.draft_redirect"
			/>
		);
	};

	const menuActionAddWidget = (
		<Menu>
			{templates.map((item) => {
				if (item?.id === templateId) {
					return null;
				}
				const isApproved = item?.status === STATUS_TEMPLATE.APPROVED;
				return (
					<Menu.Item
						disabled={!isApproved}
						onClick={() => {
							if (isApproved) {
								setDetailWidget({ ...detailWidget, termsTemplateId: item.id });
								setTermsSelected(null);
								setOpenDropdown(false);
							} else {
								setOpenDropdown(true);
							}
						}}
						key={item.id}>
						<Tooltip title={renderTooltipTitle(item)}>
							<StyledWrapperTitle justifyContent="left" width="100%">
								<StyledIcon size={20}>
									{isApproved ? <IconCheckActive /> : <IconRightPending />}
								</StyledIcon>
								<StyledTitle
									margin="0 0 0 8px"
									className="unset_height overflow_text"
									fontSize="14px"
									lineHeight="20px"
									maxWidth="calc(100% - 28px)">
									{item.name}
								</StyledTitle>
							</StyledWrapperTitle>
						</Tooltip>
					</Menu.Item>
				);
			})}
			{templates.length === 0 && !loadingTemplateWidget && (
				<StyledCenterContainer>
					<Empty />
				</StyledCenterContainer>
			)}
		</Menu>
	);

	const renderHeaderPanel = () => {
		return (
			<StyledWrapperTitle>
				<StyledWrapperNameTerms width="100%">
					<StyledIcon size={20}>{<IconCheckActive />}</StyledIcon>
					<StyledTitle
						className="unset_height overflow_text"
						fontSize="14px"
						margin="0 0 0 4px"
						lineHeight="20px"
						maxWidth="calc(100% - 24px)">
						{`${templateDetail?.name}${
							templateDetail?.isDefault ? t('content_licensing.default_name') : ''
						} `}
					</StyledTitle>
				</StyledWrapperNameTerms>
			</StyledWrapperTitle>
		);
	};

	return (
		<>
			<StyledLabelItem>
				{t('content.widget_manager.widget_builder.term_and_condition')}
			</StyledLabelItem>
			{hasTemplate && (
				<>
					<StyledWrapperTitle position="relative" width="100%">
						<StyledWrapperContent width="calc(100% - 40px)">
							<StyledCollapse
								className="collapse_content_detail"
								expandIconPosition="right"
								style={{ marginBottom: '0' }}
								defaultActiveKey={'1'}>
								<Panel header={renderHeaderPanel()} key={'1'}>
									<StyledWrapperListTerms className="custom_scroll_bar">
										<StyledLineVertical />
										<StyledWrapperContent
											margin="0 0 0 8px"
											width={'calc(100% - 13px)'}>
											{terms?.map((item: TermsTypes, idx: number) => (
												<StyledWrapperNameTerms
													key={item.id}
													margin={idx === 0 ? '0' : '8px 0 0 0'}
													width="100%"
													onClick={() => setTermsSelected(item)}
													className={
														termsSelected?.id === item.id
															? 'disabled active'
															: ''
													}>
													{`${idx + 1}. ${item.name}`}
												</StyledWrapperNameTerms>
											))}
										</StyledWrapperContent>
									</StyledWrapperListTerms>
								</Panel>
							</StyledCollapse>
						</StyledWrapperContent>

						<StyledIcon
							onClick={() => {
								setTypeConfirm(TYPE_ACTIONS.REMOVE_TEMPLATE);
							}}
							className="absolute"
							top="15px"
							right="0"
							size={24}
							fill={THEME.colors.gray1}>
							<IconRemoveCircle />
						</StyledIcon>
					</StyledWrapperTitle>
				</>
			)}
			<StyledDivider margin="16px auto" />
			{!hasTemplate && (
				<StyledBoxStatus className="warning">
					<StyledIcon size={20}>
						<IconTriangleWarning />
					</StyledIcon>
					<StyledTitle
						className="unset_height"
						color={THEME.colors.yellowBase}
						margin="0 0 0 8px">
						{t('content_licensing.message.sub_warning_template')}
					</StyledTitle>
				</StyledBoxStatus>
			)}
			<DropdownLoadMore
				visible={openDropdown}
				onVisibleChange={setOpenDropdown}
				heightOverlay={heightOverlayDropdown(templates.length)}
				overlay={menuActionAddWidget}
				heightItem={40}
				footerOverlay={
					<StyledRedirectLink
						onClick={() => history.push(ROUTE_PATH.CONTENT_LICENSING)}
						style={{ padding: '2px 8px', height: '32px' }}>
						{t('button.go_template_manager')}
						<StyledIcon size={16} margin="0 0 0 4px">
							<IconArrowLine />
						</StyledIcon>
					</StyledRedirectLink>
				}
				isLoading={loadingTemplateWidget}
				onLoadMore={onLoadMoreTemplate}>
				<Button
					type={hasTemplate ? 'ghost' : 'primary'}
					style={{ width: '100%', marginTop: '16px' }}>
					{hasTemplate
						? t('button.use_different_terms')
						: t('button.add_licensing_terms')}
				</Button>
			</DropdownLoadMore>
		</>
	);
};

export default TemplateTerms;
