import * as React from 'react';
import { Rate, Table, Select, ConfigProvider } from 'antd';
import styled from 'styled-components';
import THEME from '@constants/themes/themes';

// Component
import Empty from '@cores/Empty';

// Hook
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Context
import CreatorDashboardContext from '@contexts/Creators/Dashboard';

// Model
import { SummaryDto } from '@models/creator/summary';

// icon
import { StyledChartWrapper, StyledSelect } from '@styled/Dashboard/DashboardStyled';
import { ALIGN, DEFAULT_WIDTH_COL, ROUTE_PATH } from '@constants/common';
import { renderCopiedText } from '@utils/renderComponent';

const { Option } = Select;

const StyledTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 16px;
	font-size: 1.143rem;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	box-sizing: content-box;
	height: 22px;
`;

const StyledTable = styled(Table)<{ isFilterByCampaign: boolean }>`
	.ant-table {
		display: block;
		max-width: -moz-fit-content;
		max-width: fit-content;
		margin: 0 auto;
		overflow-x: auto;
		white-space: nowrap;
		${({ isFilterByCampaign }) => isFilterByCampaign && 'background-color: #181d27 !important'};

		.ant-table-selection-column {
			width: 50px;
		}
		.ant-table-body {
			height: 255px;
		}
	}

	.ant-table-placeholder {
		height: 255px;
	}

	.ant-table-row,
	.ant-table-placeholder {
		background-color: ${({ isFilterByCampaign }) => isFilterByCampaign && '#181d27 !important'};
	}

	.ant-table-cell {
		height: 255px;
		font-size: 14px;
	}

	table thead.ant-table-thead tr th {
		font-weight: 500;
	}
`;

const StyledName = styled.span`
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
`;

const StyledRate = styled(Rate)`
	.ant-rate-star-full {
		svg {
			fill: ${THEME.colors.yellowBase};
		}
	}
	.ant-rate-star-zero {
		svg {
			opacity: 0.5;
		}
	}
`;

type LeaderBoardSortDto = {
	id: string;
	name: string;
};

const sortObj: LeaderBoardSortDto[] = [
	{
		id: 'sortByTotalDirectUpload',
		name: 'dashboard.leaderboard.number_direct_upload',
	},
	{ id: 'sortByStarRating', name: 'dashboard.leaderboard.avg_star_rating' },
	{
		id: 'sortByTotalSocialUpload',
		name: 'ambassador.ambassador_post',
	},
	{
		id: 'sortByTotalContentValue',
		name: 'dashboard.leaderboard.total_content_value',
	},
];

const LeaderBoard = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { isFetchingCreatorLeaderboardData, creatorLeaderboardData, isFilterByCampaign } =
		useContext(CreatorDashboardContext);
	const [sortType, setSortType] = useState<string>('sortByStarRating');

	const columns = [
		{
			title: t('dashboard.leaderboard.creator_name'),
			key: 'username',
			render: (data: any) => {
				return <StyledName>{renderCopiedText(setUsername(data))}</StyledName>;
			},
			width: DEFAULT_WIDTH_COL,
		},
		{
			title: t('dashboard.leaderboard.number_direct_upload'),
			dataIndex: 'totalDirectUploads',
			key: 'totalDirectUploads',
			width: DEFAULT_WIDTH_COL,
			align: ALIGN.CENTER,
			render: (val: number) => renderCopiedText(val),
		},
		{
			title: t('dashboard.leaderboard.avg_star_rating'),
			dataIndex: 'avgStar',
			key: 'avgStar',
			align: ALIGN.CENTER,
			width: DEFAULT_WIDTH_COL + 10,
			render: (avgStar: number) => renderRating(avgStar),
		},
		{
			title: t('ambassador.ambassador_post'),
			dataIndex: 'totalSocialUploads',
			key: 'totalSocialUploads',
			width: DEFAULT_WIDTH_COL,
			align: ALIGN.CENTER,
			render: (val: number) => renderCopiedText(val),
		},
		{
			title: t('dashboard.leaderboard.total_content_value'),
			dataIndex: 'totalContentValue',
			key: 'totalContentValue',
			width: DEFAULT_WIDTH_COL,
			align: ALIGN.CENTER,
			render: (value: string) => renderCopiedText(`$${value || 0}`),
		},
		{
			title: t('dashboard.leaderboard.rewards_sent'),
			dataIndex: 'rewardSent',
			key: 'rewardSent',
			width: DEFAULT_WIDTH_COL,
			align: ALIGN.CENTER,
			render: (value: string) => renderCopiedText(`$${value || 0}`),
		},
	];

	const setUsername = (data: any) => {
		if (data) {
			const {
				firstName,
				lastName,
				email,
				instagramUsername,
				twitterUsername,
				tiktokUsername,
				youtubeUsername,
			} = data || {};

			switch (true) {
				case !!firstName || !!lastName:
					return `${firstName || ''} ${lastName || ''}`;

				case !!email:
					return email;

				case !!instagramUsername:
					return instagramUsername;

				case !!twitterUsername:
					return twitterUsername;

				case !!tiktokUsername:
					return tiktokUsername;

				case !!youtubeUsername:
					return youtubeUsername;
			}
		}

		return null;
	};

	useEffect(() => {
		setSortType(sortObj[0].id);
	}, [creatorLeaderboardData]);

	creatorLeaderboardData[sortType]?.forEach((profile: any) => {
		profile.username = `${profile.firstName} ${profile.lastName}`;
	});

	const customizeRenderEmpty = () => <Empty />;

	const handleChangeSelect = (value: any) => {
		setSortType(value);
	};

	const onRedirectCreatorProfile = (record: SummaryDto) => {
		const { creatorId } = record;
		const path = `${ROUTE_PATH.CREATOR_DETAIL_EMPTY}${creatorId}`;
		history.push({
			pathname: path,
			state: {
				prevRoute: history.location.pathname,
			},
		});
	};

	const renderRating = (avgStar: number) => {
		let star = avgStar;
		const roundingStar = Math.floor(avgStar);
		const deviant = avgStar - roundingStar;
		if (deviant < 0.25) {
			star = roundingStar;
		} else if (deviant < 0.75) {
			star = roundingStar + 0.5;
		} else {
			star = roundingStar + 1;
		}
		return (
			<div
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					e.stopPropagation();
				}}>
				<StyledRate allowHalf disabled value={star} />
			</div>
		);
	};

	const renderMenuSort = () => {
		return (
			<StyledSelect
				isFilterByCampaign={isFilterByCampaign}
				value={sortType}
				style={{ width: 180 }}
				onChange={handleChangeSelect}>
				{sortObj
					? sortObj.map((item: LeaderBoardSortDto) => {
							if (
								isFilterByCampaign &&
								(item.id === 'sortByTotalContentValue' ||
									item.id === 'sortByTotalSocialUpload')
							) {
								return null;
							}

							return (
								<Option key={item.id} value={item.id}>
									{t(item.name)}
								</Option>
							);
					  })
					: null}
			</StyledSelect>
		);
	};

	return (
		<ConfigProvider renderEmpty={customizeRenderEmpty}>
			<StyledChartWrapper isFilterByCampaign={isFilterByCampaign}>
				<div className="card-content card-bg leader-board">
					<StyledTitle>
						{t('dashboard.leaderboard.title')} {renderMenuSort()}
					</StyledTitle>

					<StyledTable
						isFilterByCampaign={isFilterByCampaign}
						loading={isFetchingCreatorLeaderboardData}
						dataSource={creatorLeaderboardData[sortType]}
						columns={columns}
						className="table_row_action"
						scroll={{ y: 255 }}
						pagination={false}
						onRow={(record: any, rowIndex: any) => {
							return {
								onClick: () => {
									onRedirectCreatorProfile(record);
								},
							};
						}}
					/>
				</div>
			</StyledChartWrapper>
		</ConfigProvider>
	);
};

export default React.memo(LeaderBoard);
