import { getRequest, putRequest } from '@helpers/requestHelpers';
/**
 * @prettier
 */

import genericApiSaga from '@helpers/genericApiSaga';
import { put, takeLatest } from 'redux-saga/effects';

import {
	AMBASSADOR_GRAPH_POSTS_API,
	AMBASSADOR_GRAPH_VOTES_API,
	AMBASSADOR_LEADER_BOARD_API,
	AMBASSADOR_METRIC_API,
	AMBASSADOR_POSTS_API,
	AMBASSADOR_REFRESH_METRICS_API,
	AMBASSADOR_REPORT_API,
} from '@constants/APIs';
import * as actions from './ambassador.actions';
import { ambassadorTypes } from './ambassador.types';
import { ParamPostActivityTypes } from '@models/ambassador';
import { Action } from 'redux-actions';
import { convertParamSearch } from '@utils/common';
import { message } from 'antd';
import { generalMessage } from '@utils/renderComponent';
import { MESSAGE_TYPE } from '@constants/common';
import { DEFAULT_ERROR } from '@constants/errors';
import { changeFieldsClientSetting } from '@stores/actions';

export function* fetchAmbassadorPosts({ payload }: Action<ParamPostActivityTypes>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(AMBASSADOR_POSTS_API, payload),
		*completed(response: any) {
			yield put(actions.getAmbassadorPostsSuccess(response));
		},
		*failed(response: any) {
			yield put(actions.getAmbassadorPostsFailed(response));
		},
	});
}

export function* fetchAmbassadorLeaderBoard({
	payload,
}: Action<{ sortBy: string; campaignId?: number | null; segmentId?: number | null }>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(AMBASSADOR_LEADER_BOARD_API, payload),
		*completed(response: any) {
			yield put(actions.getAmbassadorLeaderBoardSuccess(response));
		},
		*failed(response: any) {
			yield put(actions.getAmbassadorLeaderBoardFailed(response));
		},
	});
}

export function* fetchAmbassadorMetric({
	payload,
}: Action<{ campaignId: number | null; segmentId: number | null }>) {
	const { campaignId, segmentId } = payload;
	let url: string = AMBASSADOR_METRIC_API;
	if (campaignId || segmentId) {
		url = `${AMBASSADOR_METRIC_API}?${convertParamSearch(payload)}`;
	}
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.getAmbassadorMetricSuccess(response));
		},
		*failed(response: any) {
			yield put(actions.getAmbassadorMetricFailed(response));
		},
	});
}

export function* fetchAmbassadorGraphPosts({
	payload,
}: Action<{
	interval: string;
	sources: string[];
	campaignId?: number;
	segmentId?: number;
}>) {
	const { sources, ...other } = payload;
	const searchParams = convertParamSearch(other);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${AMBASSADOR_GRAPH_POSTS_API}?${searchParams}`, sources),
		*completed(response: any) {
			yield put(actions.getAmbassadorGraphPostsSuccess(response));
		},
		*failed(response: any) {
			yield put(actions.getAmbassadorGraphPostsFailed(response));
		},
	});
}

export function* fetchAmbassadorGraphVotes({
	payload,
}: Action<{
	interval: string;
	sources: string[];
	campaignId?: number;
	segmentId?: number;
}>) {
	const { sources, ...other } = payload;

	const searchParams = convertParamSearch(other);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${AMBASSADOR_GRAPH_VOTES_API}?${searchParams}`, sources),
		*completed(response: any) {
			yield put(actions.getAmbassadorGraphVotesSuccess(response));
		},
		*failed(response: any) {
			yield put(actions.getAmbassadorGraphVotesFailed(response));
		},
	});
}

export function* fetchAmbassadorReport({ payload }: Action<number | undefined>) {
	const url = payload ? `${AMBASSADOR_REPORT_API}?campaignId=${payload}` : AMBASSADOR_REPORT_API;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.getAmbassadorReportSuccess(response));
		},
		*failed(response: any) {
			yield put(actions.getAmbassadorReportFailed(response));
		},
	});
}

function* refreshMetricAmbassador() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(AMBASSADOR_REFRESH_METRICS_API),
		*completed(response: any) {
			const {
				message: textMessage = '',
				ambassadorRefreshMetricsEnabled,
				ambassadorMetricsRefreshUpdateAt,
				ambassadorMetricsRefreshInProgress,
			} = response?.data?.result || {};
			message.info(textMessage);
			yield put(actions.refreshMetricAmbassadorSuccess(response));
			yield put(
				changeFieldsClientSetting({
					ambassadorRefreshMetricsEnabled,
					ambassadorMetricsRefreshUpdateAt,
					ambassadorMetricsRefreshInProgress,
				}),
			);
		},
		*failed(response: any) {
			generalMessage(response?.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);

			yield put(actions.refreshMetricAmbassadorFailed(response));
		},
	});
}

export function* ambassadorSaga() {
	yield takeLatest(ambassadorTypes.GET_AMBASSADOR_POSTS_REQUEST, fetchAmbassadorPosts);
	yield takeLatest(
		ambassadorTypes.GET_AMBASSADOR_LEADER_BOARD_REQUEST,
		fetchAmbassadorLeaderBoard,
	);
	yield takeLatest(ambassadorTypes.GET_AMBASSADOR_METRIC_REQUEST, fetchAmbassadorMetric);
	yield takeLatest(ambassadorTypes.GET_AMBASSADOR_GRAPH_POSTS_REQUEST, fetchAmbassadorGraphPosts);
	yield takeLatest(ambassadorTypes.GET_AMBASSADOR_GRAPH_VOTES_REQUEST, fetchAmbassadorGraphVotes);
	yield takeLatest(ambassadorTypes.GET_AMBASSADOR_REPORT_REQUEST, fetchAmbassadorReport);
	yield takeLatest(ambassadorTypes.REFRESH_METRIC_AMBASSADOR_REQUEST, refreshMetricAmbassador);
}
