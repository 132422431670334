import THEME from '@constants/themes/themes';
import { StyledFlex, StyledWrapperContent } from '@styled/Common/CommonStyled';
import styled from 'styled-components';

const StyledParticipation = styled.div`
	position: relative;
	transition: all 0.6s;
	padding: 9px;

	&:hover {
		background-color: rgba(226, 88, 43, 0.2);

		${StyledFlex} {
			p {
				color: ${THEME.colors.orangeBase} !important;
			}

			svg {
				path {
					fill: ${THEME.colors.orangeBase} !important;
				}
			}
		}
	}
`;

const StyledOverlayCompletedCampaign = styled.div`
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 12px);
	height: 100%;
	background-color: rgba(1, 22, 39, 0.5);
	padding: 0 6px;

	${StyledWrapperContent} {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

export { StyledOverlayCompletedCampaign, StyledParticipation };
