import { TYPE_FORMAT_DATE } from '@constants/common';
import {
	BETWEEN_FIELDS,
	FIELD_CONDITIONS,
	LOCATION,
	RANGE_CONDITIONS,
	SUBMITTED_TO_CAMPAIGN,
} from '@constants/creator';

export const formatRequestData = (data: any) => {
	const { fields } = data;
	const BLANK_CONDITIONS = ['is blank', 'is not blank'];

	if (!fields) {
		return [];
	}

	return fields.map((item: any) => {
		if (item?.field === 'Date Added') {
			if (item?.condition === 'is within') {
				const formatedDateValue = {
					start: item.value[0].format(TYPE_FORMAT_DATE.MONTH_DAY),
					end: item.value[1].format(TYPE_FORMAT_DATE.MONTH_DAY),
				};
				return { ...item, value: formatedDateValue };
			}
			return { ...item, value: item.value.format(TYPE_FORMAT_DATE.MONTH_DAY) };
		}

		if (BETWEEN_FIELDS.includes(item?.field)) {
			const isPercent = item?.field === FIELD_CONDITIONS.ENGAGEMENT;

			if (item?.condition === RANGE_CONDITIONS.BETWEEN) {
				const newItem = { ...item };
				delete newItem?.valueMore;
				const from = +newItem?.value;
				const to = +item?.valueMore;
				return {
					...newItem,
					value: { from: isPercent ? from / 100 : from, to: isPercent ? to / 100 : to },
				};
			}
			const value = +item?.value;

			return { ...item, value: isPercent ? value / 100 : value };
		}

		if (item?.field === SUBMITTED_TO_CAMPAIGN) {
			if (!item?.condition) {
				return { ...item, condition: '' };
			}
		}
		if (item?.field === LOCATION) {
			if (item?.value?.trim()) {
				const obj = JSON.parse(item?.value);
				const value = obj?.state ? `${obj?.country}-${obj?.state}` : obj?.country;
				return { ...item, value };
			}
		}

		if (item?.field === 'Status') {
			if (item?.value === 'active') {
				return {
					...item,
					value: {
						field: 'Active',
						value: 't.last_submission',
					},
				};
			}
			if (item?.value === 'inactive') {
				return {
					...item,
					value: {
						field: 'Inactive',
						value: 't.last_submission',
					},
				};
			}
			if (item?.value === 'followed') {
				return {
					...item,
					value: {
						field: 'Followed',
						value: 'st.is_followed',
					},
				};
			}
			if (item?.value === 'unsubscribed') {
				return {
					...item,
					value: {
						field: 'Unsubscribed',
						value: 'st.is_unsubscribed',
					},
				};
			}
		} else {
			if (BLANK_CONDITIONS.includes(item?.condition)) {
				return { ...item, value: '' };
			}
		}
		return { ...item };
	});
};

export const checkNullValue = (data: any) => {
	return data.every((item: any) => item.value !== null);
};

export const formatListCode = (codeData: any, selectedRowKeys: any) => {
	const data = codeData
		.map((code: any) => {
			const data = selectedRowKeys.map((item: any) => {
				let codeData = {};
				if (item === code.CODE) {
					codeData = {
						code: code.CODE,
						source: code.SOURCE,
					};
					return codeData;
				}
				return undefined;
			});
			return data;
		})
		.flat()
		.filter((item: any) => {
			return item !== undefined;
		});
	return data;
};

export const renderSelectedSegment = (segmentList: any, selectedRowKeys: any) => {
	const data = segmentList
		.map((segment: any) => {
			const data = selectedRowKeys.map((item: any) => {
				let title;
				if (item === segment.id) {
					title = segment.title;
				}
				return title;
			});
			return data;
		})
		.flat()
		.filter((item: any) => {
			return item !== undefined;
		});
	return data;
};
