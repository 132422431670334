import THEME from '@constants/themes/themes';
import { StyledFormItem, StyledInput } from '@styled/Common/CommonStyled';
import React, { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TooltipContent } from '..';
import ContentDetailContext from '@contexts/Content/ContentDetail';

const StyledContent = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray5};
	margin-bottom: 12px;
	width: 100%;
	min-height: 20px;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-inline-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
`;

type PropTypes = {
	name: string;
	value: string;
	enableEdit?: boolean;
};
const FormTextareaField = memo((props: PropTypes) => {
	const { t } = useTranslation();
	const { setField } = useContext(ContentDetailContext);

	const { name, value, enableEdit } = props;
	const [isEdit, setIsEdit] = useState(false);

	if (isEdit) {
		return (
			<StyledFormItem margin="0 0 5px 0" name={name}>
				<StyledInput.TextArea />
			</StyledFormItem>
		);
	}

	return (
		<TooltipContent name={value}>
			<StyledContent
				dangerouslySetInnerHTML={{ __html: value }}
				onClick={() => {
					if (enableEdit) {
						setIsEdit(true);
						setField({ [name]: value });
					}
				}}
			/>
		</TooltipContent>
	);
});

export default FormTextareaField;
