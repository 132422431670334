import * as React from 'react';
import { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PrivateLayout from '@layout/PrivateLayout';
import {
	getUser,
	getUserToken,
	setClientSettings,
	setFolderSystem,
	setPendoInitData,
} from '@helpers/userHelpers';
import _isEmpty from 'lodash.isempty';

// Loading
import Loading from '@components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAccountUserPermissionRequest,
	getInfoUserRequest,
	initPendoRequest,
} from '@stores/actions';
import { useDevice } from '@utils/renderComponent';
import { message } from 'antd';
import { MESSAGE_KEY, ROUTE_PATH, TYPE_OF } from '@constants/common';

const PrivateRoute = ({ ...rest }) => {
	const [isAuth, setIsAuth] = useState(false);
	const [loading, setLoading] = useState(true);
	const { clientSettings, folderSystem, pendoSettings } = useSelector(
		(state: any) => state.sidebar,
	);
	const { accountUserPermissions } = useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const { isMobile } = useDevice();
	const user = getUser();

	useEffect(() => {
		if (typeof isMobile === TYPE_OF.BOOLEAN && !isMobile) {
			message.destroy(MESSAGE_KEY.OPTIMIZED_DEVICE);
		}
	}, [isMobile]);

	useEffect(() => {
		const userToken = getUserToken();

		// TODO, we can call the authen API here to check current token is valid or not

		if (userToken) {
			setIsAuth(true);
			setLoading(false);
			dispatch(getInfoUserRequest());
		} else {
			setIsAuth(false);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (clientSettings) {
			setClientSettings(clientSettings);
			setFolderSystem(folderSystem);
			if (!accountUserPermissions || accountUserPermissions.length === 0) {
				dispatch(getAccountUserPermissionRequest());
			}
		}
	}, [clientSettings]);

	useEffect(() => {
		if (clientSettings && user) {
			checkToInitPendo();
		}
	}, [clientSettings, user]);

	const checkToInitPendo = () => {
		try {
			const { pendo = {} } = window;
			if (pendo && !_isEmpty(pendo)) {
				if (pendoSettings === null || _isEmpty(pendoSettings)) {
					const { accountId, username, firstName, lastName, clientId, email, brandName } =
						user;
					const initData = {
						visitor: {
							id: accountId,
							email,
							username,
							full_name: `${firstName} ${lastName}`,
						},
						account: {
							id: clientId,
							name: brandName,
							email: email,
						},
					};
					pendo.initialize(initData);
					setPendoInitData(initData);
					dispatch(initPendoRequest(initData));
					console.info('[Pendo] Sent tracking data!');
				}
			}
		} catch (error) {
			console.info('[Pendo] Could not send tracking data!');
		}
	};

	// TODO
	if (loading) {
		return <Loading />;
	}

	if (isAuth) {
		return <PrivateLayout component={rest.component} rest={rest} />;
	} else {
		return <Redirect to={ROUTE_PATH.LOGIN} />;
	}
};

export default PrivateRoute;
