const STORE_LOCAL = {
	EXPAND: 'store_local_expand',
	GRID_COL_DASHBOARD: 'grid_col_dashboard',
};

const COL_DASHBOARD = {
	DEFAULT: 2,
	MORE: 3,
};

const SCOPE_MAP = {
	WORLD: 'world',
	USA: 'usa',
};

const MAX_CONTENT_DASHBOARD = 24;

export { STORE_LOCAL, COL_DASHBOARD, MAX_CONTENT_DASHBOARD, SCOPE_MAP };
