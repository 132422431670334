import { HeadingSVG } from '@assets/icons/svg/grapesjs';

export const HeaderBlock = (bm: any, label: any) => {
	bm.add('header', {
		label: `<div>${label}</div>`,
		media: HeadingSVG,
		content: {
			type: 'header',
			content: 'Heading',
		},
	});
};

export default (domc: any) => {
	const textType = domc.getType('text');
	const textModel = textType.model;
	const textView = textType.view;

	domc.addType('header', {
		extend: 'text',
		model: {
			defaults: {
				'custom-name': 'Header',
				tagName: 'h1',
				editable: true,
				traits: [
					{
						type: 'select',
						options: [
							{ value: 'h1', name: 'H1' },
							{ value: 'h2', name: 'H2' },
							{ value: 'h3', name: 'H3' },
							{ value: 'h4', name: 'H4' },
						],
						label: 'Size',
						name: 'tagName',
						changeProp: 1,
					},
				].concat(textModel.prototype.defaults.traits),
			},
		},
		isComponent(el: any) {
			if (el && ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(el.tagName)) {
				return { type: 'header' };
			}

			return false;
		},
		view: textView,
	});
};
