// - Live -> active
// - Draft -> inactive
// - Archive > draft
const TAB = {
	active: { name: 'Active', key: 'active' },
	inactive: { name: 'Inactive', key: 'inactive' },
	archives: { name: 'Archives', key: 'draft' },
};

// - Live -> active
// - Draft -> inactive
// - Archive > draft
const TAB_LIST = [
	{
		name: 'Active',
		key: 'active',
	},
	{
		name: 'Inactive',
		key: 'inactive',
	},
	{
		name: 'Archives',
		key: 'draft',
	},
];

const TITLE_VALUE = {
	NAME: 'name',
	WIDGET: 'widget',
	HASHTAG: 'hashtag',
	DES: 'description',
	STAR: 'startDate',
	END: 'endDate',
};

const IMGSTATUS = { DONE: 'done', ERROR: 'error' };

const NA = 'N/A';
const EMPTY_VALUE = '';

const RANGE_GOALS = {
	SUBMISSION: 20,
	CREATOR: 5,
	SOCIAL_POSTS: 10,
	MIN: 1,
	MAX: 999999,
};

const TOTAL_SIZE = {
	HEADER: 30,
	DESCRIPTION: 125,
};

const SIZE_CTA = {
	WIDTH: 297,
	HEIGHT: 528,
	REQUIRED_WIDTH: 1080,
};

const MAX_SIZE_CAMPAIGN = 10;

const IMG_LOCAL_DEFAULT = {
	lc1: null,
	lc2: null,
	lc3: null,
};

const CREATOR_ACTIONS = {
	UPLOAD_CONTENT_DIRECTLY: 'UPLOAD_CONTENT_DIRECTLY',
	POST_TAG: 'POST_TAG',
};

const STATUS_TYPE = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
};

const MODAL_ACTION_TYPES = {
	SEND_ACTIVATION_EMAIL: 'SEND_ACTIVATION_EMAIL',
};

const CAMPAIGN_DETAIL_FIELD_NAME = {
	NAME: 'name',
	HEADER: 'header',
	DESCRIPTION: 'description',
	ACTIVATION_TEMPLATE_ID: 'activationTemplateId',
	CONFIRMATION_TEMPLATE_ID: 'confirmationTemplateId',
	REJECTION_TEMPLATE_ID: 'rejectionTemplateId',
	CREATOR_GOALS: 'creatorsGoals',
	SOCIAL_POST_GOALS: 'socialPostsGoals',
	TERMS: 'terms',
	SUBMISSION_GOALS: 'submissionsGoals',
	WIDGET_ID: 'widgetId',
	AVATAR: 'avatar',
	PROJECT_NAME: 'projectName',
	JOB_NUMBER: 'jobNumber',
	SOCIAL_CTA: 'socialCTA',
	CLIENT_NAME: 'clientName',
	IMAGE: 'image',
	CAMPAIGN_UPLOAD_WIDGET: 'widgetUrl',
	COMMUNITY_URL: 'communityUrl',
	COMMUNITY_ID: 'communityId',
};

const DISABLED_FIELDS = [
	CAMPAIGN_DETAIL_FIELD_NAME.ACTIVATION_TEMPLATE_ID,
	CAMPAIGN_DETAIL_FIELD_NAME.CONFIRMATION_TEMPLATE_ID,
	CAMPAIGN_DETAIL_FIELD_NAME.REJECTION_TEMPLATE_ID,
	CAMPAIGN_DETAIL_FIELD_NAME.COMMUNITY_ID,
];

const CAMPAIGN_DYNAMIC_FIELD = {
	IMAGE: '{{IMAGE}}',
	HEADER: '{{HEADER}}',
	CALL_TO_ACTION: '{{CALL TO ACTION}}',
	SOCIAL_CTA: '{{SOCIAL CTA}}',
	CLIENT_NAME: '{{CLIENT NAME}}',
	CAMPAIGN_UPLOAD_WIDGET: '{{CAMPAIGN UPLOAD WIDGET}}',
};

const CAMPAIGN_MODAL_TYPE = {
	EDIT_LANDING_PAGE: 'EDIT_LANDING_PAGE',
};

export {
	TAB,
	TAB_LIST,
	TITLE_VALUE,
	IMGSTATUS,
	NA,
	EMPTY_VALUE,
	RANGE_GOALS,
	MAX_SIZE_CAMPAIGN,
	TOTAL_SIZE,
	SIZE_CTA,
	IMG_LOCAL_DEFAULT,
	CREATOR_ACTIONS,
	STATUS_TYPE,
	MODAL_ACTION_TYPES,
	CAMPAIGN_DETAIL_FIELD_NAME,
	CAMPAIGN_DYNAMIC_FIELD,
	DISABLED_FIELDS,
	CAMPAIGN_MODAL_TYPE,
};
