import { Form, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IconAdd } from '@assets/icons';
import HashTag from '@components/Settings/HashTag';
import { PATTERNS, TYPE_CSV } from '@constants/common';
import { STATUS } from '@constants/settings';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import { ConfirmModal, LoadingWrapper } from '@cores/index';
import { SettingRootStateDto } from '@models/settings/setting';
import {
	modifySocialBlockedUsernameRequest,
	replaceBlockUsernameEnd,
	replaceBlockUsernameRequest,
} from '@stores/actions';
import { StyledUpload } from '@styled/Common/CommonStyled';
import {
	StyledButton,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledInput,
	StyledTextUploadCSV,
	StyledTitle,
	StyledWrapper,
	StyledWrapperBox,
} from '@styled/Settings/SocialSearchStyled';

const BlockedUsernames: React.FC = () => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const refInput = useRef<any>(null);

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [action, setAction] = useState<string>('');
	const [tagId, setTagId] = useState<number>(-1);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		blockedUsernameList = [],
		loadingBlockedUsername = false,
		statusCode = null,
		replaceBlockUsernameStatus,
	} = useSelector((state: SettingRootStateDto) => state.settings.socialSearch);

	useEffect(() => {
		popupMessage();
	}, [loadingBlockedUsername]);

	useEffect(() => {}, [form.resetFields()]);

	useEffect(() => {
		if (replaceBlockUsernameStatus === IN_PROGRESS) {
			setIsLoading(true);
		}

		if (replaceBlockUsernameStatus === SUCCEEDED) {
			message.success(t('setting.message.message_replace_block_username'));
		}
		if (replaceBlockUsernameStatus === SUCCEEDED || replaceBlockUsernameStatus === FAILED) {
			dispatch(replaceBlockUsernameEnd());
			setIsLoading(false);
		}
	}, [replaceBlockUsernameStatus]);

	const popupMessage = () => {
		if (statusCode === 200 && action) {
			resetFields();
			if (action === STATUS.ADD) {
				focusInput();
				message.success(t('setting.message.added_blocked_username'));
			} else {
				message.success(t('setting.message.deleted_blocked_username'));
			}
			setAction('');
		}
	};

	const focusInput = () => {
		setTimeout(() => {
			if (refInput?.current?.focus) {
				refInput.current.focus();
			}
		}, 0);
	};

	const onFinish = ({ userBlock = '' }: { userBlock: string }) => {
		setAction(STATUS.ADD);
		dispatch(
			modifySocialBlockedUsernameRequest({ usernames: [userBlock, ...blockedUsernameList] }),
		);
	};

	const resetFields = () => {
		form.resetFields();
	};

	const checkDuplicateFolder = (value: string) => {
		const result = blockedUsernameList.some((item: string) => item === value);

		return result;
	};

	const renderAddUsernameForm = () => (
		<StyledForm
			form={form}
			onFinish={onFinish}
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}
			initialValues={{ userBlock: '' }}>
			<StyledFlex>
				<StyledFormItem
					rules={[
						{
							required: true,
							message: t('setting.validation.please_input_username'),
						},
						{
							pattern: PATTERNS.USERNAME,
							message: t('validate.validate_username'),
						},
						() => ({
							validator(_: any, value: string) {
								if (!value || !checkDuplicateFolder(value)) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error(t('setting.validation.duplicated')),
								);
							},
						}),
					]}
					label={t('setting.socical_search_settings.add_username_to_exclude')}
					name="userBlock">
					<StyledInput
						ref={refInput}
						placeholder={t('setting.socical_search_settings.add_username_placeholder')}
					/>
				</StyledFormItem>
				<StyledFormItem width="fit-content">
					<StyledButton htmlType="submit" icon={<IconAdd />} />
				</StyledFormItem>
			</StyledFlex>
		</StyledForm>
	);

	const renderHashTags = () => (
		<StyledWrapperBox padding="12px 0" style={{ height: 'calc(100% - 115px)' }}>
			{blockedUsernameList.map((username, index) => (
				<HashTag tagId={index} onClickTag={onClickTag} key={username}>
					@{username}
				</HashTag>
			))}
		</StyledWrapperBox>
	);

	const onToggleOpenModal = (id: number = -1) => {
		setIsOpenModal(!isOpenModal);

		if (id > -1) {
			setTagId(id);
		} else {
			setTagId(-1);
		}
	};

	const onSubmitDelete = () => {
		if (tagId !== -1) {
			deleteBlockedUsername(tagId);
		}
	};

	const renderConfirmModal = () => {
		return (
			<ConfirmModal
				width={400}
				okText={t('button.delete')}
				isOpen={isOpenModal}
				onSubmit={onSubmitDelete}
				onClose={onToggleOpenModal}>
				{t('setting.confirm.delete_username')}
			</ConfirmModal>
		);
	};

	const deleteBlockedUsername = (id: number) => {
		const userDelete = blockedUsernameList[id];
		const usernames = blockedUsernameList.filter((item) => item !== userDelete);

		setAction(STATUS.DELETE);
		dispatch(modifySocialBlockedUsernameRequest({ usernames }));
		setIsOpenModal(false);
	};

	const onClickTag = (key: string, tagId: number) => {
		switch (key) {
			case 'delete':
				onToggleOpenModal(tagId);
				break;
			default:
				break;
		}
	};

	const handleChangeUploadFile = ({ fileList, file }: any) => {
		const formData = new FormData();
		formData.append('file', file);
		dispatch(replaceBlockUsernameRequest(formData));
	};

	const renderUpload = () => {
		return (
			<StyledUpload
				showUploadList={false}
				maxCount={1}
				beforeUpload={() => false}
				accept={TYPE_CSV}
				onChange={handleChangeUploadFile}>
				<StyledTextUploadCSV>
					{t('setting.socical_search_settings.text_replace')}
				</StyledTextUploadCSV>
			</StyledUpload>
		);
	};

	return (
		<StyledWrapper style={{ height: '100%' }}>
			<StyledTitle fontSize="1.143rem" fontWeight="normal">
				{t('setting.socical_search_settings.blocked_usernames')}
			</StyledTitle>
			<StyledWrapperBox style={{ height: 'calc(100% - 55px)' }}>
				<LoadingWrapper isLoading={loadingBlockedUsername || isLoading}>
					{renderAddUsernameForm()}
					{renderUpload()}
					{renderHashTags()}
				</LoadingWrapper>
			</StyledWrapperBox>
			{renderConfirmModal()}
		</StyledWrapper>
	);
};

export default BlockedUsernames;
