import { isInList } from '@helpers/arrayHelper';
import { putRequest } from '@helpers/requestHelpers';
import { Button, Dropdown, Menu, message } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Component
import FreeProductModal from './Modal/FreeProductModal';
import GiftCardModal from './Modal/GiftCardModal';
import InventoryDiscountModal from './Modal/InventoryDiscountModal';

// Icon
import { IconAdd, IconArchive, IconDelete, IconEdit } from '@assets/icons';

// Styled
import {
	StyledActionBlock,
	StyledActionList,
	StyledPopconfirm,
} from '@styled/Rewards/ActionBarStyled';

// Constant
import { REWARD_API_URL } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { FIXED, PERCENTAGE, REWARD_TYPES } from '@constants/reward';

// Redux
import { RefRewardActionEdit } from '@models/rewards/ref';
import { getSegmentReward } from '@stores/actions';
import { setInventoryState } from '@stores/rewards/rewards.actions';

type ActionProps = {
	handleFetchDraftList: () => void;
	handleFetchActiveList: () => void;
};

type RewardModalTypes = {
	id: number;
	name: string;
	click: () => void;
	disabled?: boolean;
};

const ActionBar = React.forwardRef(
	(
		{ handleFetchDraftList, handleFetchActiveList }: ActionProps,
		ref: React.Ref<RefRewardActionEdit>,
	) => {
		const { t } = useTranslation();
		const dispatch = useDispatch();
		const rewards = useSelector((state: any) => state.rewards);
		const [selectedRewards, setSelectedRewards] = useState<any[]>([]);
		const [isLoadingArchive, setIsLoadingArchive] = useState<boolean>(false);
		const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
		const [editedReward, setEditedReward] = useState<any>({});
		const [isDisabled, setIsDisabled] = useState<boolean>(false);
		const [actionType, setActionType] = useState<string>('');

		const isDisabledEditButton = selectedRewards.length === 1;
		const {
			inventory: {
				selectedDiscountRewards,
				selectedGiftCardsRewards,
				selectedProductRewards,
				selectedCashRewards,
				selectedCustomRewards,
			},
			rewardsList: {
				activeDiscountList,
				draftDiscountList,
				archivedDiscountList,
				activeGiftcardList,
				draftGiftcardList,
				archivedGiftcardList,
				activeProductList,
				draftProductList,
				archivedProductList,
				activeCashList,
				draftCashList,
				archivedCashList,
				activeCustomList,
				draftCustomList,
				archivedCustomList,
			},
		} = rewards;

		const test = () => {};

		const getSegmentList = () => {
			const params = {
				page: 1,
				pageRecords: 7,
			};
			dispatch(getSegmentReward(params));
		};

		React.useImperativeHandle(ref, () => ({
			handleEdit,
		}));

		useEffect(() => {
			setSelectedRewards([
				...selectedDiscountRewards,
				...selectedGiftCardsRewards,
				...selectedProductRewards,
				...selectedCashRewards,
				...selectedCustomRewards,
			]);
			setIsDisabled(checkArray());
		}, [
			selectedDiscountRewards,
			selectedGiftCardsRewards,
			selectedProductRewards,
			selectedCashRewards,
			selectedCustomRewards,
		]);

		useEffect(() => {
			cleanUpSelectedRewards();
		}, []);

		// Setting disabled for Archived and Delete buttons
		const getOccurrence = (array: any, value: number) => {
			return array.filter((v: number) => v === value).length;
		};

		const checkArray = () => {
			const lengthArray = [
				selectedDiscountRewards.length,
				selectedGiftCardsRewards.length,
				selectedProductRewards.length,
				selectedCashRewards.length,
				selectedCustomRewards.length,
			];

			return lengthArray.some((item: number) => getOccurrence(lengthArray, 0) === 4);
		};

		// Clean up
		const cleanUpSelectedRewards = () => {
			rewards.inventory.selectedDiscountRewards = [];
			rewards.inventory.selectedGiftCardsRewards = [];
			rewards.inventory.selectedProductRewards = [];
			rewards.inventory.selectedCashRewards = [];
			rewards.inventory.selectedCustomRewards = [];

			dispatch(setInventoryState(rewards));
		};

		const handleEdit = (rewardEditItem?: any) => {
			const totalDiscountList = [
				...activeDiscountList,
				...draftDiscountList,
				...archivedDiscountList,
			];
			const totalGiftcardList = [
				...activeGiftcardList,
				...draftGiftcardList,
				...archivedGiftcardList,
			];
			const totalProductList = [
				...activeProductList,
				...draftProductList,
				...archivedProductList,
			];
			const totalCashList = [...activeCashList, ...draftCashList, ...archivedCashList];
			const totalCustomList = [
				...activeCustomList,
				...draftCustomList,
				...archivedCustomList,
			];
			const totalList = [
				...totalDiscountList,
				...totalGiftcardList,
				...totalProductList,
				...totalCashList,
				...totalCustomList,
			];

			const editedRewards: any = rewardEditItem
				? [rewardEditItem]
				: totalList.filter((item: any) => item.id === selectedRewards[0]);

			if (isInList(totalDiscountList, editedRewards[0].id)) {
				if (editedRewards[0].discountType === FIXED) {
					setActionType(REWARD_TYPES.FIXED_DISCOUNT);
				}
				if (editedRewards[0].discountType === PERCENTAGE) {
					setActionType(REWARD_TYPES.PERCENTAGE_DISCOUNT);
				}
			}
			if (isInList(totalGiftcardList, editedRewards[0].id)) {
				setActionType(REWARD_TYPES.GIFT_CARD);
			}
			if (isInList(totalProductList, editedRewards[0].id)) {
				setActionType(REWARD_TYPES.FREE_PRODUCT);
			}

			getSegmentList();
			setEditedReward(editedRewards[0]);
		};

		const handleDelete = () => {
			const url = `${REWARD_API_URL}/rule/delete`;
			setIsLoadingDelete(true);
			putRequest(url, selectedRewards)
				.then((response) => {
					if (response.status === 200) {
						handleFetchDraftList();
						cleanUpSelectedRewards();
						message.success(t('rewards.message.delete_success'));
					} else {
						message.error(response.body.data.error || DEFAULT_ERROR);
					}
					setIsLoadingDelete(false);
				})
				.catch((err) => {
					message.error(DEFAULT_ERROR);
					setIsLoadingDelete(false);
				});
		};

		const handleArchive = () => {
			const url = `${REWARD_API_URL}/rule/archive`;
			setIsLoadingArchive(true);
			putRequest(url, selectedRewards)
				.then((response) => {
					if (response.status === 200) {
						handleFetchActiveList();
						cleanUpSelectedRewards();

						message.success(t('rewards.message.archived_success'));
					} else {
						message.error(response.body.data.error || DEFAULT_ERROR);
					}
					setIsLoadingArchive(false);
				})
				.catch((err) => {
					message.error(DEFAULT_ERROR);
					setIsLoadingArchive(false);
				});
		};

		// Action handle event
		const handleOpenModal = (type: string) => {
			setActionType(type);
			getSegmentList();
		};

		const handleCloseModal = () => {
			setEditedReward({});
			setActionType('');
		};

		const modalObj: RewardModalTypes[] = [
			{
				id: 1,
				name: 'button.fixed_discount',
				click: () => handleOpenModal(REWARD_TYPES.FIXED_DISCOUNT),
			},
			{
				id: 2,
				name: 'button.percentage_discount',
				click: () => handleOpenModal(REWARD_TYPES.PERCENTAGE_DISCOUNT),
			},
			{
				id: 3,
				name: 'button.gift_card',
				click: () => handleOpenModal(REWARD_TYPES.GIFT_CARD),
			},
			{
				id: 4,
				name: 'button.product',
				click: () => handleOpenModal(REWARD_TYPES.FREE_PRODUCT),
			},
		];

		const menuActions = (
			<Menu>
				{modalObj
					? modalObj.map((item: RewardModalTypes) => (
							<Menu.Item key={item.id} onClick={item.click} disabled={item.disabled}>
								<span>{t(item.name)}</span>
							</Menu.Item>
					  ))
					: null}
			</Menu>
		);

		return (
			<>
				<StyledActionBlock className="card-content">
					<StyledActionList>
						<Dropdown overlay={menuActions} placement="bottomLeft" trigger={['click']}>
							<Button icon={<IconAdd />}>{t('button.create_new')}</Button>
						</Dropdown>
						{isDisabledEditButton && (
							<Button icon={<IconEdit />} onClick={() => handleEdit()}>
								{t('button.edit')}
							</Button>
						)}
						{isDisabled && (
							<>
								<Button
									icon={<IconArchive />}
									onClick={handleArchive}
									loading={isLoadingArchive}>
									{t('button.archive')}
								</Button>
								<StyledPopconfirm
									getPopupContainer={(triggerNode: HTMLElement) =>
										triggerNode.parentNode as HTMLElement
									}
									placement="bottom"
									title={t('popconfirm.delete_rewards')}
									onConfirm={handleDelete}
									okText={t('button.delete')}
									cancelText={t('button.cancel')}>
									<Button icon={<IconDelete />} loading={isLoadingDelete}>
										{t('button.delete')}
									</Button>
								</StyledPopconfirm>
							</>
						)}
					</StyledActionList>
				</StyledActionBlock>
				<InventoryDiscountModal
					editedReward={editedReward}
					visible={
						actionType === REWARD_TYPES.FIXED_DISCOUNT ||
						actionType === REWARD_TYPES.PERCENTAGE_DISCOUNT
					}
					onClose={handleCloseModal}
					type={actionType}
				/>
				<GiftCardModal
					editedReward={editedReward}
					visible={actionType === REWARD_TYPES.GIFT_CARD}
					onClose={handleCloseModal}
				/>
				<FreeProductModal
					editedReward={editedReward}
					visible={actionType === REWARD_TYPES.FREE_PRODUCT}
					onClose={handleCloseModal}
				/>
			</>
		);
	},
);

export default React.memo(ActionBar);
