import THEME from '@constants/themes/themes';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { Form, Input, Radio, Rate, Tabs } from 'antd';
import styled from 'styled-components';

const StyledFilterContent = styled.div<{ height?: string }>`
	position: relative;
	height: ${({ height }) => height || 'calc(100vh - 130px)'};
	&.hidden {
		display: none;
	}
`;

const StyledFooterFilter = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 16px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${THEME.colors.darkBlue2};
	border-top: 1px solid ${THEME.colors.darkBlue3};
`;

const StyledTabs = styled(Tabs)`
	.ant-tabs-nav {
		margin: 7px 16px 0px;
	}
	.ant-tabs-content {
		.ant-tabs-tabpane {
			height: 100%;
		}
	}
`;

const StyledForm = styled(Form)`
	position: relative;
	&.hidden {
		display: none;
	}
`;

const StyledLabel = styled.div<{ margin?: string; lineHeight?: string }>`
	font-weight: 400;
	font-size: 14px;
	line-height: ${({ lineHeight }) => lineHeight || '20px'};
	color: ${THEME.colors.gray5};
	${({ margin }) => margin && `margin: ${margin}`};

	&.size_16 {
		font-size: 16px;
		line-height: 22px;
	}

	&.align_center {
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
	}
`;

const StyledLineVertical = styled.div`
	width: 2px;
	height: 100%;
	background-color: ${THEME.colors.darkBlue3};
	border-radius: 2px;
	&.line_filter {
		position: absolute;
		left: 0px;
		top: 0;
		height: calc(100% + 17px);
	}
`;

const StyledWrapperFilter = styled.div`
	padding: 10px 14px;
	height: calc(100vh - 260px);
	overflow-y: auto;

	&.selected_filter {
		background-color: rgba(226, 88, 43, 0.08);
		padding: 0;
	}
	&.feed_filter {
		padding: 20px 24px;
		height: calc(100vh - 200px);
	}
`;

const StyledChip = styled.div`
	padding: 2px 6px;
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue3};
	color: ${THEME.colors.blueBase};

	&.custom_chip {
		margin-left: 8px;
		margin-top: 8px;
	}
`;

const StyledTextChip = styled.span`
	color: ${THEME.colors.blueBase};
	font-size: 12px;
	line-height: 18px;
	cursor: pointer;
	position: relative;
	&:hover {
		color: ${THEME.colors.orangeBase};
		&::after {
			color: ${THEME.colors.blueBase};
		}
	}
	&.has_arrow {
		&::after {
			content: '>';
			margin-left: 4px;
		}
	}
`;

const StyledFormItem = styled(Form.Item)<{
	margin?: string;
	showRequired?: boolean;
	heightLabel?: string;
}>`
	margin: ${({ margin }) => margin || '0 0 16px 0'};
	display: block;
	&:last-child {
		margin-bottom: 0;
	}
	.ant-form-item-label {
		height: 22px;
		padding: 0;
		margin: 0 0 8px 0;

		label {
			width: 100%;
			height: ${({ heightLabel }) => heightLabel || '24px'};
		}
		& > label {
			&.ant-form-item-required {
				&:not(.ant-form-item-required-mark-optional) {
					&::before {
						display: none;
					}
					&::after {
						display: ${({ showRequired }) => (showRequired ? 'inline-block' : 'none')};
						color: ${THEME.colors.redBase};
						font-size: 14px;
						font-family: SimSun, sans-serif;
						line-height: 1;
						content: '*';
					}
				}
			}
		}
	}
	.ant-form-item-control-input {
		min-height: unset;
	}
	&.label_full {
		.ant-form-item-label {
			width: 100%;
		}
	}
	&.unset_height {
		.ant-form-item-label {
			height: auto;
			overflow: unset;
			label {
				height: auto;
			}
		}
	}
`;

const StyledRadioGroup = styled(Radio.Group)<{ bgCheckbox?: string }>`
	.ant-radio {
		.ant-radio-inner {
			width: 14px;
			height: 14px;
			&::after {
				top: 2px;
				left: 2px;
			}
		}
	}
	&.first_0 {
		margin-left: -8px;
	}
	&.radio_group_default {
		.ant-radio-wrapper {
			.ant-radio {
				padding: 0 8px;
			}
			span {
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				color: ${THEME.colors.gray3};
				padding: 0px;
			}
		}
	}
	.ant-radio-inner {
		background-color: ${({ bgCheckbox }) => bgCheckbox || THEME.colors.darkBlue1};
	}
	&.radio_group_dark {
		.ant-radio-wrapper {
			.ant-radio {
				padding: 0 8px;
			}
			&.ant-radio-wrapper-disabled {
				span {
					color: ${THEME.colors.gray3} !important;
				}

				.ant-radio-inner {
					background-color: ${THEME.colors.darkBlue1};

					&::after {
						background-color: ${THEME.colors.gray1};
					}
				}
			}
			&.ant-radio-wrapper-checked {
				span {
					color: ${THEME.colors.gray5} !important;
				}
			}

			span {
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				color: ${THEME.colors.gray3};
				padding: 0px;
			}
		}
	}
	&.radio_filter {
		.ant-radio-wrapper {
			width: 100%;
			& > span {
				&:not(.ant-radio) {
					width: calc(100% - 16px);
				}
			}
		}
	}
`;

const StyledRate = styled(Rate)<{ size?: number; margin?: string }>`
	&.ant-rate {
		.ant-rate-star {
			svg {
				width: ${({ size }) => size || 20}px;
			}
			&:not(:last-child) {
				${({ margin }) => margin && `margin: ${margin}`};
			}
		}
	}
	.ant-rate-star {
		&.ant-rate-star-zero {
			svg {
				path {
					fill: ${THEME.colors.gray1};
				}
			}
		}
	}
`;

const StyledInput = styled(Input)`
	height: 32px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	padding: 6px 12px;
	&.input_filter_name {
		height: 24px;
	}
	&::-webkit-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&::placeholder {
		color: ${THEME.colors.gray1};
	}
	&.no_bg {
		background-color: ${THEME.colors.transparent};
	}
`;

const StyledButtonReset = styled.div`
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	color: ${THEME.colors.gray3};
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	margin-right: 8px;
	svg {
		margin-right: 4px;
	}
	&:hover {
		color: ${THEME.colors.orangeBase};
		svg {
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}
`;

const StyledFilterItem = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	height: 30px;
	&:hover {
		background-color: rgba(226, 88, 43, 0.2);
		outline: 1px solid ${THEME.colors.orangeBase};
		border-radius: 20px;
		.name_filter {
			max-width: calc(100% - 82px);
		}
		.list_action {
			display: inline-flex;
		}
	}
	&.filter_edit {
		background-color: rgba(226, 88, 43, 0.2);
		outline: 1px solid ${THEME.colors.orangeBase};
		border-radius: 20px;
		.name_filter {
			max-width: calc(100% - 82px);
		}
		.list_action {
			display: inline-flex;
		}
	}
	.ant-radio-wrapper {
		height: 100%;
		padding: 2px 8px;
		margin-right: 0;
		align-items: center;
		& > span {
			padding-right: 0px;
		}
		.ant-radio {
			top: unset;
		}
	}
	.list_action {
		display: none;
	}
`;

const StyledWrapperItemFilter = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledNameFilter = styled.span`
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	&.name_filter {
		display: block;
	}
`;

const StyledDividerLine = styled.div<{ margin?: string }>`
	width: 100%;
	height: 1px;
	background-color: ${THEME.colors.darkBlue3};
	${({ margin }) => margin && `margin: ${margin}`};
`;

const StyledWrapperAdvanced = styled.div`
	margin-top: 16px;
	&.hidden {
		display: none;
	}
`;

const StyledTitleAdvanced = styled.div`
	width: 100%;
	cursor: pointer;
	.icon_arrow {
		transition: 0.2s ease-out;
	}
	&:hover {
		.title_hover {
			color: ${THEME.colors.orangeBase};
		}
		.icon_arrow {
			svg {
				fill: ${THEME.colors.orangeBase} !important;
			}
		}
	}
	&.active {
		.icon_arrow {
			transform: rotate(90deg);
		}
	}
`;

const StyledSourcesInfo = styled(StyledWrapperContent)`
	display: flex;
	background: rgba(24, 144, 255, 0.1);
	padding: 10px 12px;
`;

export {
	StyledFilterContent,
	StyledTabs,
	StyledWrapperFilter,
	StyledForm,
	StyledLabel,
	StyledFormItem,
	StyledRadioGroup,
	StyledRate,
	StyledInput,
	StyledFooterFilter,
	StyledButtonReset,
	StyledWrapperItemFilter,
	StyledNameFilter,
	StyledFilterItem,
	StyledDividerLine,
	StyledWrapperAdvanced,
	StyledTitleAdvanced,
	StyledChip,
	StyledTextChip,
	StyledSourcesInfo,
	StyledLineVertical,
};
