import {
	contentTypes,
	rejectContentTypes,
	moveContentTypes,
	deleteContentTypes,
	deleteMultiContentsTypes,
	resetStateTypes,
	reviewContentTypes,
	reviewMultiContentsTypes,
	getFilterTypes,
	saveFilterTypes,
	deleteFilterTypes,
	getVerificationScroreTypes,
	getTermsConditionsTypes,
	countDownloadContentTypes,
	updateContentStatusRecentlyAddedType,
	getContentUnviewedStatusRecentlyAddedType,
	getContentLocationTypes,
	storeDataTypes,
	updateFilterContentDetailTypes,
	getContentTagsTypes,
	getTermsContentTypes,
	getPublishLogTypes,
	createPublishLogTypes,
	updatePublishLogTypes,
	removePublishLogTypes,
	markFavoriteContentTypes,
	ratioAndResolutionTypes,
	updateContactedContentTypes,
	updateAccountSettingTypes,
	contentEmojiTypes,
	updateEmojiTypes,
	contentTotalTypes,
	contentImgVersionTypes,
	createImgVersionTypes,
	updateImgVersionTypes,
	getDetectionServiceListTypes,
	estimateCostTypes,
	submitDetectionOrderTypes,
	getMediaDetectionTypes,
	getDetectionLabelListTypes,
	contentDetailTypes,
	fetchContentDataTypes,
	saveUploadContentTypes,
	rotateImgTypes,
	fetchDeviceMakeTypes,
	fetchDeviceModelTypes,
	fetchShotCountriesTypes,
	getContentCustomFieldsTypes,
} from '@stores/content/contentLibrary/content.types';
import { createAction } from 'redux-actions';

// Action function
export const fetchContentRequest = createAction(contentTypes.FETCH_CONTENT_REQUEST);
export const fetchContentSucceeded = createAction(contentTypes.FETCH_CONTENT_SUCCEEDED);
export const fetchContentFailed = createAction(contentTypes.FETCH_CONTENT_FAILED);

// Action function
export const fetchContentDetailRequest = createAction(
	contentDetailTypes.FETCH_CONTENT_DETAIL_REQUEST,
);
export const fetchContentDetailSucceeded = createAction(
	contentDetailTypes.FETCH_CONTENT_DETAIL_SUCCEEDED,
);
export const fetchContentDetailFailed = createAction(
	contentDetailTypes.FETCH_CONTENT_DETAIL_FAILED,
);

// Content Emoji
export const fetchContentEmojiRequest = createAction(contentEmojiTypes.FETCH_CONTENT_EMOJI_REQUEST);
export const fetchContentEmojiSucceeded = createAction(
	contentEmojiTypes.FETCH_CONTENT_EMOJI_SUCCEEDED,
);
export const fetchContentEmojiFailed = createAction(contentEmojiTypes.FETCH_CONTENT_EMOJI_FAILED);

// Reject content
export const rejectContentRequest = createAction(rejectContentTypes.REJECT_CONTENT_REQUEST);
export const rejectContentSucceeded = createAction(rejectContentTypes.REJECT_CONTENT_SUCCEEDED);
export const rejectContentFailed = createAction(rejectContentTypes.REJECT_CONTENT_FAILED);
export const rejectContentEnd = createAction(rejectContentTypes.REJECT_CONTENT_END);

// Update emoji
export const updateEmojiRequest = createAction(updateEmojiTypes.UPDATE_EMOJI_REQUEST);
export const updateEmojiSucceeded = createAction(updateEmojiTypes.UPDATE_EMOJI_SUCCEEDED);
export const updateEmojiFailed = createAction(updateEmojiTypes.UPDATE_EMOJI_FAILED);
export const updateEmojiEnd = createAction(updateEmojiTypes.UPDATE_EMOJI_END);

// Move content
export const moveContentRequest = createAction(moveContentTypes.MOVE_CONTENT_REQUEST);
export const moveContentSucceeded = createAction(moveContentTypes.MOVE_CONTENT_SUCCEEDED);
export const moveContentFailed = createAction(moveContentTypes.MOVE_CONTENT_FAILED);
export const moveContentEnd = createAction(moveContentTypes.MOVE_CONTENT_END);

// Delete content
export const deleteContentRequest = createAction(deleteContentTypes.DELETE_CONTENT_REQUEST);
export const deleteContentSucceeded = createAction(deleteContentTypes.DELETE_CONTENT_SUCCEEDED);
export const deleteContentFailed = createAction(deleteContentTypes.DELETE_CONTENT_FAILED);
export const deleteContentEnd = createAction(deleteContentTypes.DELETE_CONTENT_END);

// Delete multi contents
export const deleteMultiContentsRequest = createAction(
	deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_REQUEST,
);
export const deleteMultiContentsSucceeded = createAction(
	deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_SUCCEEDED,
);
export const deleteMultiContentsFailed = createAction(
	deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_FAILED,
);
export const deleteMultiContentsEnd = createAction(
	deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_END,
);

// Review content
export const reviewContentRequest = createAction(reviewContentTypes.REVIEW_CONTENT_REQUEST);
export const reviewContentSucceeded = createAction(reviewContentTypes.REVIEW_CONTENT_SUCCEEDED);
export const reviewContentFailed = createAction(reviewContentTypes.REVIEW_CONTENT_FAILED);
export const reviewContentEnd = createAction(reviewContentTypes.REVIEW_CONTENT_END);

// Review multi contents
export const reviewMultiContentsRequest = createAction(
	reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_REQUEST,
);
export const reviewMultiContentsSucceeded = createAction(
	reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_SUCCEEDED,
);
export const reviewMultiContentsFailed = createAction(
	reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_FAILED,
);
export const reviewMultiContentsEnd = createAction(
	reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_END,
);

// Get filters
export const getFilterRequest = createAction(getFilterTypes.GET_FILTER_REQUEST);
export const getFilterSucceeded = createAction(getFilterTypes.GET_FILTER_SUCCEEDED);
export const getFilterFailed = createAction(getFilterTypes.GET_FILTER_FAILED);

// Save filter
export const saveFilterRequest = createAction(saveFilterTypes.SAVE_FILTER_REQUEST);
export const saveFilterSucceeded = createAction(saveFilterTypes.SAVE_FILTER_SUCCEEDED);
export const saveFilterFailed = createAction(saveFilterTypes.SAVE_FILTER_FAILED);
export const saveFilterEnd = createAction(saveFilterTypes.SAVE_FILTER_END);

// Delete filter
export const deleteFilterRequest = createAction(deleteFilterTypes.DELETE_FILTER_REQUEST);
export const deleteFilterSucceeded = createAction(deleteFilterTypes.DELETE_FILTER_SUCCEEDED);
export const deleteFilterFailed = createAction(deleteFilterTypes.DELETE_FILTER_FAILED);
export const deleteFilterEnd = createAction(deleteFilterTypes.DELETE_FILTER_END);

// Reset State
export const resetStateRequest = createAction(resetStateTypes.RESET_STATE_REQUEST);

// Get Verification Score
export const getVerificationScoreRequest = createAction(
	getVerificationScroreTypes.GET_VERIFICATION_SCORE_REQUEST,
);
export const getVerificationScoreSucceeded = createAction(
	getVerificationScroreTypes.GET_VERIFICATION_SCORE_SUCCEEDED,
);
export const getVerificationScoreFailed = createAction(
	getVerificationScroreTypes.GET_VERIFICATION_SCORE_FAILED,
);

// Get shot countries
export const fetchShotCountriesRequest = createAction(
	fetchShotCountriesTypes.FETCH_SHOT_COUNTRIES_REQUEST,
);
export const fetchShotCountriesSucceeded = createAction(
	fetchShotCountriesTypes.FETCH_SHOT_COUNTRIES_SUCCEEDED,
);
export const fetchShotCountriesFailed = createAction(
	fetchShotCountriesTypes.FETCH_SHOT_COUNTRIES_FAILED,
);

// Get device make
export const fetchDeviceMakeRequest = createAction(fetchDeviceMakeTypes.FETCH_DEVICE_MAKE_REQUEST);
export const fetchDeviceMakeSucceeded = createAction(
	fetchDeviceMakeTypes.FETCH_DEVICE_MAKE_SUCCEEDED,
);
export const fetchDeviceMakeFailed = createAction(fetchDeviceMakeTypes.FETCH_DEVICE_MAKE_FAILED);

// Get device model
export const fetchDeviceModelRequest = createAction(
	fetchDeviceModelTypes.FETCH_DEVICE_MODEL_REQUEST,
);
export const fetchDeviceModelSucceeded = createAction(
	fetchDeviceModelTypes.FETCH_DEVICE_MODEL_SUCCEEDED,
);
export const fetchDeviceModelFailed = createAction(fetchDeviceModelTypes.FETCH_DEVICE_MODEL_FAILED);

// Get Terms Conditions
export const getTermsConditionsRequest = createAction(
	getTermsConditionsTypes.GET_TERMS_CONDITIONS_REQUEST,
);
export const getTermsConditionsSucceeded = createAction(
	getTermsConditionsTypes.GET_TERMS_CONDITIONS_SUCCEEDED,
);
export const getTermsConditionsFailed = createAction(
	getTermsConditionsTypes.GET_TERMS_CONDITIONS_FAILED,
);
export const getTermsConditionsEnd = createAction(getTermsConditionsTypes.GET_TERMS_CONDITIONS_END);

// Count download content
export const countDownloadContentRequest = createAction(
	countDownloadContentTypes.COUNT_DOWNLOAD_CONTENT_REQUEST,
);
export const countDownloadContentSucceeded = createAction(
	countDownloadContentTypes.COUNT_DOWNLOAD_CONTENT_SUCCEEDED,
);
export const countDownloadContentFailed = createAction(
	countDownloadContentTypes.COUNT_DOWNLOAD_CONTENT_FAILED,
);
export const countDownloadContentEnd = createAction(
	countDownloadContentTypes.COUNT_DOWNLOAD_CONTENT_END,
);

// Update status recently added
export const updateContentStatusRecentlyAddedRequest = createAction(
	updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_REQUEST,
);
export const updateContentStatusRecentlyAddedSucceeded = createAction(
	updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_SUCCEEDED,
);
export const updateContentStatusRecentlyAddedFailed = createAction(
	updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_FAILED,
);
export const updateContentStatusRecentlyAddedEnd = createAction(
	updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_END,
);

// Count content unviewed status recently added
export const getContentUnviewedStatusRecentlyAddedRequest = createAction(
	getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST,
);
export const getContentUnviewedStatusRecentlyAddedSucceeded = createAction(
	getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_SUCCEEDED,
);
export const getContentUnviewedStatusRecentlyAddedFailed = createAction(
	getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_FAILED,
);
export const getContentUnviewedStatusRecentlyAddedEnd = createAction(
	getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_END,
);

// Get Content Location
export const fetchContentLocationRequest = createAction(
	getContentLocationTypes.GET_CONTENT_LOCATION_REQUEST,
);
export const fetchContentLocationSucceeded = createAction(
	getContentLocationTypes.GET_CONTENT_LOCATION_SUCCEEDED,
);
export const fetchContentLocationFailed = createAction(
	getContentLocationTypes.GET_CONTENT_LOCATION_FAILED,
);

// Get Content Custom Fields
export const fetchContentCustomFieldsRequest = createAction(
	getContentCustomFieldsTypes.GET_CONTENT_CUSTOM_FIELDS_REQUEST,
);
export const fetchContentCustomFieldsSucceeded = createAction(
	getContentCustomFieldsTypes.GET_CONTENT_CUSTOM_FIELDS_SUCCEEDED,
);
export const fetchContentCustomFieldsFailed = createAction(
	getContentCustomFieldsTypes.GET_CONTENT_CUSTOM_FIELDS_FAILED,
);

// Get Content Tags
export const fetchContentTagsRequest = createAction(getContentTagsTypes.GET_CONTENT_TAGS_REQUEST);
export const fetchContentTagsSucceeded = createAction(
	getContentTagsTypes.GET_CONTENT_TAGS_SUCCEEDED,
);
export const fetchContentTagsFailed = createAction(getContentTagsTypes.GET_CONTENT_TAGS_FAILED);

// Get Ratio Resolution
export const fetchRatioResolutionRequest = createAction(
	ratioAndResolutionTypes.GET_RATIO_RESOLUTION_REQUEST,
);
export const fetchRatioResolutionSucceeded = createAction(
	ratioAndResolutionTypes.GET_RATIO_RESOLUTION_SUCCEEDED,
);
export const fetchRatioResolutionFailed = createAction(
	ratioAndResolutionTypes.GET_RATIO_RESOLUTION_FAILED,
);

// Store data
export const storeContentData = createAction(storeDataTypes.STORE_DATA);

// Change filter content detail
export const updateFilterContentDetailRequest = createAction(
	updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_REQUEST,
);
export const updateFilterContentDetailSucceeded = createAction(
	updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_SUCCEEDED,
);
export const updateFilterContentDetailFailed = createAction(
	updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_FAILED,
);
export const updateFilterContentDetailEnd = createAction(
	updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_END,
);

// Get Terms Content
export const fetchTermsContentRequest = createAction(
	getTermsContentTypes.GET_TERMS_CONTENT_REQUEST,
);
export const fetchTermsContentSucceeded = createAction(
	getTermsContentTypes.GET_TERMS_CONTENT_SUCCEEDED,
);
export const fetchTermsContentFailed = createAction(getTermsContentTypes.GET_TERMS_CONTENT_FAILED);

// Get Publish Log Content
export const fetchPublishLogContentRequest = createAction(
	getPublishLogTypes.GET_PUBLISH_LOG_REQUEST,
);
export const fetchPublishLogContentSucceeded = createAction(
	getPublishLogTypes.GET_PUBLISH_LOG_SUCCEEDED,
);
export const fetchPublishLogContentFailed = createAction(getPublishLogTypes.GET_PUBLISH_LOG_FAILED);

// Fetch total content
export const fetchTotalContentRequest = createAction(contentTotalTypes.FETCH_TOTAL_CONTENT_REQUEST);
export const fetchTotalContentSucceeded = createAction(
	contentTotalTypes.FETCH_TOTAL_CONTENT_SUCCEEDED,
);
export const fetchTotalContentFailed = createAction(contentTotalTypes.FETCH_TOTAL_CONTENT_FAILED);

// Create Publish Log Content
export const createPublishLogContentRequest = createAction(
	createPublishLogTypes.CREATE_PUBLISH_LOG_REQUEST,
);
export const createPublishLogContentSucceeded = createAction(
	createPublishLogTypes.CREATE_PUBLISH_LOG_SUCCEEDED,
);
export const createPublishLogContentFailed = createAction(
	createPublishLogTypes.CREATE_PUBLISH_LOG_FAILED,
);
export const createPublishLogContentEnd = createAction(
	createPublishLogTypes.CREATE_PUBLISH_LOG_END,
);

// Create Publish Log Content
export const updatePublishLogContentRequest = createAction(
	updatePublishLogTypes.UPDATE_PUBLISH_LOG_REQUEST,
);
export const updatePublishLogContentSucceeded = createAction(
	updatePublishLogTypes.UPDATE_PUBLISH_LOG_SUCCEEDED,
);
export const updatePublishLogContentFailed = createAction(
	updatePublishLogTypes.UPDATE_PUBLISH_LOG_FAILED,
);
export const updatePublishLogContentEnd = createAction(
	updatePublishLogTypes.UPDATE_PUBLISH_LOG_END,
);

// Remove Publish Log Content
export const removePublishLogContentRequest = createAction(
	removePublishLogTypes.REMOVE_PUBLISH_LOG_REQUEST,
);
export const removePublishLogContentSucceeded = createAction(
	removePublishLogTypes.REMOVE_PUBLISH_LOG_SUCCEEDED,
);
export const removePublishLogContentFailed = createAction(
	removePublishLogTypes.REMOVE_PUBLISH_LOG_FAILED,
);
export const removePublishLogContentEnd = createAction(
	removePublishLogTypes.REMOVE_PUBLISH_LOG_END,
);

// Mark favorite content
export const markFavoriteContentRequest = createAction(
	markFavoriteContentTypes.MARK_FAVORITE_CONTENT_REQUEST,
);
export const markFavoriteContentSucceeded = createAction(
	markFavoriteContentTypes.MARK_FAVORITE_CONTENT_SUCCEEDED,
);
export const markFavoriteContentFailed = createAction(
	markFavoriteContentTypes.MARK_FAVORITE_CONTENT_FAILED,
);
export const markFavoriteContentEnd = createAction(
	markFavoriteContentTypes.MARK_FAVORITE_CONTENT_END,
);

// Make content contacted
export const updateContactedContentRequest = createAction(
	updateContactedContentTypes.UPDATE_CONTACTED_CONTENT_REQUEST,
);
export const updateContactedContentSucceeded = createAction(
	updateContactedContentTypes.UPDATE_CONTACTED_CONTENT_SUCCEEDED,
);
export const updateContactedContentFailed = createAction(
	updateContactedContentTypes.UPDATE_CONTACTED_CONTENT_FAILED,
);
export const updateContactedContentEnd = createAction(
	updateContactedContentTypes.UPDATE_CONTACTED_CONTENT_END,
);

// Change Grid
export const updateAccountSettingRequest = createAction(
	updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_REQUEST,
);
export const updateAccountSettingSucceeded = createAction(
	updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_SUCCEEDED,
);
export const updateAccountSettingFailed = createAction(
	updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_FAILED,
);
export const updateAccountSettingEnd = createAction(
	updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_END,
);
export const changeInfoAccountSetting = createAction(
	updateAccountSettingTypes.CHANGE_INFO_ACCOUNT_SETTING,
);

// Fetch content img version
export const fetchImgVersionRequest = createAction(
	contentImgVersionTypes.FETCH_VERSION_IMG_REQUEST,
);
export const fetchImgVersionSucceeded = createAction(
	contentImgVersionTypes.FETCH_VERSION_IMG_SUCCEEDED,
);
export const fetchImgVersionFailed = createAction(contentImgVersionTypes.FETCH_VERSION_IMG_FAILED);

// Create content img version
export const createImgVersionRequest = createAction(
	createImgVersionTypes.CREATE_IMG_VERSION_REQUEST,
);
export const createImgVersionSucceeded = createAction(
	createImgVersionTypes.CREATE_IMG_VERSION_SUCCEEDED,
);
export const createImgVersionFailed = createAction(createImgVersionTypes.CREATE_IMG_VERSION_FAILED);
export const createImgVersionEnd = createAction(createImgVersionTypes.CREATE_IMG_VERSION_END);

// Update content img version
export const updateImgVersionRequest = createAction(
	updateImgVersionTypes.UPDATE_IMG_VERSION_REQUEST,
);
export const updateImgVersionSucceeded = createAction(
	updateImgVersionTypes.UPDATE_IMG_VERSION_SUCCEEDED,
);
export const updateImgVersionFailed = createAction(updateImgVersionTypes.UPDATE_IMG_VERSION_FAILED);
export const updateImgVersionEnd = createAction(updateImgVersionTypes.UPDATE_IMG_VERSION_END);

// rotate image
export const rotateImageRequest = createAction(rotateImgTypes.ROTATE_IMAGE_REQUEST);
export const rotateImageSucceeded = createAction(rotateImgTypes.ROTATE_IMAGE_SUCCEEDED);
export const rotateImageFailed = createAction(rotateImgTypes.ROTATE_IMAGE_FAILED);
export const rotateImageEnd = createAction(rotateImgTypes.ROTATE_IMAGE_END);

// Logo detection
export const getDetectionServiceListRequest = createAction(
	getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_REQUEST,
);
export const getDetectionServiceListSucceeded = createAction(
	getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_SUCCEEDED,
);
export const getDetectionServiceListFailed = createAction(
	getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_FAILED,
);
export const getDetectionServiceListEnd = createAction(
	getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_END,
);

export const estimateCostRequest = createAction(estimateCostTypes.ESTIMATE_COST_REQUEST);
export const estimateCostSucceeded = createAction(estimateCostTypes.ESTIMATE_COST_SUCCEEDED);
export const estimateCostFailed = createAction(estimateCostTypes.ESTIMATE_COST_FAILED);
export const estimateCostEnd = createAction(estimateCostTypes.ESTIMATE_COST_END);

export const submitDetectionOrderRequest = createAction(
	submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_REQUEST,
);
export const submitDetectionOrderSucceeded = createAction(
	submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_SUCCEEDED,
);
export const submitDetectionOrderFailed = createAction(
	submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_FAILED,
);
export const submitDetectionOrderEnd = createAction(
	submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_END,
);

export const getMediaDetectionRequest = createAction(
	getMediaDetectionTypes.GET_MEDIA_DETECTION_REQUEST,
);
export const getMediaDetectionSucceeded = createAction(
	getMediaDetectionTypes.GET_MEDIA_DETECTION_SUCCEEDED,
);
export const getMediaDetectionFailed = createAction(
	getMediaDetectionTypes.GET_MEDIA_DETECTION_FAILED,
);
export const getMediaDetectionEnd = createAction(getMediaDetectionTypes.GET_MEDIA_DETECTION_END);

export const getDetectionLabelListRequest = createAction(
	getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_REQUEST,
);
export const getDetectionLabelListSucceeded = createAction(
	getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_SUCCEEDED,
);
export const getDetectionLabelListFailed = createAction(
	getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_FAILED,
);
export const getDetectionLabelListEnd = createAction(
	getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_END,
);

export const fetchContentDataRequest = createAction(
	fetchContentDataTypes.FETCH_CONTENT_DATA_REQUEST,
);
export const fetchContentDataSucceeded = createAction(
	fetchContentDataTypes.FETCH_CONTENT_DATA_SUCCEEDED,
);
export const fetchContentDataFailed = createAction(fetchContentDataTypes.FETCH_CONTENT_DATA_FAILED);
export const fetchContentDataEnd = createAction(fetchContentDataTypes.FETCH_CONTENT_DATA_END);

// content upload
export const saveUploadContentStatus = createAction(
	saveUploadContentTypes.SAVE_STATUS_UPLOAD_CONTENT,
);
export const resetListContentAfterUpload = createAction(
	saveUploadContentTypes.RESET_LIST_CONTENT_AFTER_UPLOAD,
);
export const removeSingleUploadContent = createAction(
	saveUploadContentTypes.REMOVE_SINGLE_UPLOAD_CONTENT,
);
