import { IconKeyboardArrowRight } from '@assets/icons';
import { DEVICE_KEY } from '@constants/content/contentLibrary';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { StyledDivider, StyledIcon, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledCollapse,
	StyledCollapseInfo,
	StyledInfoItem,
	StyledLabelInfo,
	StyledOptionInfo,
	SwapperSessionDetail,
} from '@styled/ContentLibrary/ContentDetailStyled';
import { Tooltip } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const { Panel } = StyledCollapse;

type Props = {};

const FileInformation = (props: Props) => {
	const { t } = useTranslation();

	const { versionImgSelected } = useContext(ContentDetailContext);

	const renderListOption = () => {
		const { metadata } = versionImgSelected || {};

		const {
			resolutionQuality,
			model,
			maker,
			shotLocation,
			fileExtension,
			resolution,
			aspectRatio,
			fileSize,
			durationInSeconds,
			dpi = 0,
			fileType,
		} = metadata || {};

		const makerDevice = maker ? maker : '';
		const modelDevice = model ? model : '';

		const textDpi = t(`content_detail.dpi_pixel${dpi && dpi > 1 ? 's' : ''}`, { val: dpi });
		const displayFileType = fileType ? fileType : fileExtension;
		const result = [
			{
				title: t('filter.filter_content.file_type'),
				info: displayFileType
					? `${t('content_detail.dot')}${displayFileType.toLowerCase()}`
					: '',
				id: 1,
			},
			{
				title: t('content_detail.file_size'),
				info: fileSize ? `${fileSize}MB` : '',
				id: 2,
			},
			{
				title: t('content_detail.resolution'),
				info: resolutionQuality,
				id: 3,
			},
			{
				title: t('content_detail.dimensions'),
				info: resolution ? `${resolution} ${t('content_detail.pixels')}` : '',
				id: 4,
			},
			{
				title: t('content_detail.dpi'),
				info: dpi ? textDpi : '',
				id: 5,
			},
			{
				title: t('content_detail.duration'),
				info: durationInSeconds,
				id: 6,
			},
			{
				title: t('content_detail.device_make'),
				info: makerDevice,
				id: 7,
				style: { marginTop: '24px' },
			},
			{
				title: t('content_detail.device_model'),
				info: modelDevice,
				id: 8,
			},
			{
				title: t('content_detail.shot_location'),
				info: shotLocation,
				id: 9,
			},
			{
				title: t('content_detail.aspect_ratio'),
				info: aspectRatio,
				id: 10,
			},
		];
		return result;
	};

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo>
				<StyledTitle>{t('content_detail.file_information')}</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	return (
		<>
			<SwapperSessionDetail>
				<StyledCollapse
					className="collapse_content_detail"
					expandIconPosition="right"
					style={{ marginBottom: '0' }}
					defaultActiveKey={'1'}>
					<Panel header={renderHeaderPanel()} key={'1'}>
						{renderListOption()?.map((item) => (
							<StyledOptionInfo style={item?.style || {}} key={item?.id}>
								<StyledLabelInfo>{item?.title}</StyledLabelInfo>
								{item?.info && (
									<Tooltip
										placement="topLeft"
										getPopupContainer={(triggerNode: HTMLElement) =>
											triggerNode.parentNode as HTMLElement
										}
										overlayClassName="content-info-tooltip"
										title={item?.info}>
										<StyledInfoItem>{item?.info}</StyledInfoItem>
									</Tooltip>
								)}
							</StyledOptionInfo>
						))}
					</Panel>
				</StyledCollapse>
			</SwapperSessionDetail>
			<StyledDivider margin="0 0 16px 0" />
		</>
	);
};

export default FileInformation;
