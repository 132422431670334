import ConfigureReward from '@components/Messages/EmailBuilder/ConfigureReward';
import { FAILED, SUCCEEDED } from '@constants/status';
import { RewardContainerDto } from '@models/messages/summary';
import { RewardStore } from '@models/rewards/store';
import { getRewardOffersRequest, logRewardEnd, logRewardRequest } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import { setRewardValues } from '@utils/reward';
import { Modal, message } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type LogRewardModalProps = {
	visible: boolean;
	onCancel: () => void;
	getRewardList: () => void;
};

const LogRewardModal = (props: LogRewardModalProps) => {
	const { visible, onCancel, getRewardList } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const configureRewardRef = useRef<any>();
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);
	const { logRewardStatus }: RewardStore = useSelector((state: any) => state.rewards);

	useEffect(() => {
		dispatch(getRewardOffersRequest());
	}, []);

	useEffect(() => {
		if (logRewardStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: 'Logged',
				}),
			);
			onCancel();
			if (getRewardList) {
				getRewardList();
			}
		}

		if (logRewardStatus === SUCCEEDED || logRewardStatus === FAILED) {
			dispatch(logRewardEnd());
		}
	}, [logRewardStatus]);

	const handleLogReward = () => {
		configureRewardRef?.current?.handleSubmitReward();
	};

	const processReward = (reward: RewardContainerDto) => {
		if (creatorProfile && reward) {
			const infoList: any = [
				{
					creatorId: creatorProfile.id,
				},
			];
			const loggedReward = setRewardValues(reward, infoList)[0];
			dispatch(logRewardRequest({ ...loggedReward }));
		}
	};

	return (
		<Modal
			title={t('rewards.log_a_new_reward')}
			visible={visible}
			centered
			okText={t('button.log_this_reward')}
			onOk={handleLogReward}
			onCancel={onCancel}>
			<ConfigureReward
				ref={configureRewardRef}
				isCustomized={true}
				processReward={processReward}
			/>
		</Modal>
	);
};

export default LogRewardModal;
