import { IconMoreVert } from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { INTERVAL, LIMIT_VAL } from '@constants/ambassador';
import { SOCIAL_UPCASE, SOURCE_SHOW_LOCAL_DEFAULT } from '@constants/social_search';
import THEME from '@constants/themes/themes';
import { ConfigGraphTypes } from '@models/ambassador';
import { AmbassadorGraphAllDto, AmbassadorGraphDto } from '@models/ambassador/stores';
import { ActionsTypes } from '@models/content/albums/albumManager';
import {
	StyledActionGraph,
	StyledDot,
	StyledGraphWrapper,
	StyledInfoType,
} from '@styled/AmbassadorDashboard';
import {
	StyledEmpty,
	StyledIcon,
	StyledTitle,
	StyledWrapper,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { Dropdown, Menu } from 'antd';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Area,
	AreaChart,
	ResponsiveContainer,
	Tooltip as TooltipRecharts,
	XAxis,
	YAxis,
} from 'recharts';
import DrawerGraphConfig from './DrawerGraphConfig';
import { abbreviateNumber } from '@utils/common';

const colors = {
	label: THEME.colors.gray5,
	fill: '#4d5b67',
	strokeSub: THEME.colors.white,
	strokeIn: THEME.colors.yellow1,
	strokeTi: THEME.colors.blueBase,
	strokeTw: THEME.colors.orangeBase,
	strokeYt: THEME.colors.redBase,
	strokeCb: THEME.colors.violetBase,
	bgFillSub: 'rgba(255,255,255, 0.5)',
	bgFillIn: 'rgba(251, 188, 5, 0.5)',
	bgFillTi: 'rgba(24, 144, 255, 0.5)',
	bgFillTw: 'rgba(226, 88, 43, 0.5)',
	bgFillYt: 'rgba(204, 0, 0, 0.5)',
	bgFillCb: 'rgba(127, 97, 251, 0.5)',
};

const CustomizedXAxisTick = (props: any) => {
	const { x, y, payload } = props; // { x, y, stroke, payload }
	const { value } = payload;

	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				style={{ whiteSpace: 'nowrap' }}
				dy={8}
				textAnchor="middle"
				fill={THEME.colors.gray5}
				fontSize="10px">
				{value}
			</text>
		</g>
	);
};

const CustomizedYAxisTick = (props: any) => {
	const { x, y, payload } = props; // { x, y, stroke, payload }

	return (
		<g transform={`translate(${x},${y})`}>
			<text x={-12} y={4} textAnchor="middle" fill={THEME.colors.gray5} fontSize="10px">
				{payload.value ? abbreviateNumber(payload.value) : payload.value}
			</text>
		</g>
	);
};

const CustomizeTooltip = (props: any) => {
	const { active, payload = [], label } = props;
	const { t } = useTranslation();

	if (active) {
		return (
			<div className="chart-tooltip">
				{payload?.map((item: any) => {
					let title = `${item?.dataKey?.charAt(0)?.toUpperCase()}${item?.dataKey?.slice(
						1,
					)}`;
					if (item?.dataKey === SOCIAL_UPCASE.TIKTOK.toLowerCase()) {
						title = t('button.tiktok');
					}
					if (item?.dataKey === SOCIAL_UPCASE.COMBINED.toLowerCase()) {
						title = t('ambassador.all_socials');
					}
					return (
						<StyledTitle
							fontSize="12px"
							className="unset_height"
							lineHeight="16px"
							key={item?.dataKey}
							color={item?.color}>{`${title}: ${item?.value || 0}`}</StyledTitle>
					);
				})}
			</div>
		);
	}
	return null;
};

type PropTypes = {
	title: ReactNode;

	configSource: string[];
	configInterval: string;
	isShowCumulativeData: boolean;
	isShowDynamicScale: boolean;

	handleUpdateConfig: (val: ConfigGraphTypes) => void;
	dataGraph: AmbassadorGraphDto[];
	isSocial?: boolean;
	loading?: boolean;
};

type DataFormatDto = {
	min: number;
	max: number;
	data: AmbassadorGraphAllDto[];
};

const GraphItem = (props: PropTypes) => {
	const {
		title,

		configInterval,
		configSource,
		isShowCumulativeData,
		isShowDynamicScale,
		handleUpdateConfig,
		dataGraph,
		loading = false,
		isSocial = false,
	} = props;
	const { t } = useTranslation();
	const refContainer = useRef<HTMLDivElement>(null);
	const [openConfig, setOpenConfig] = useState<boolean>(false);
	const [sourceShowLocal, setSourceShowLocal] = useState<string[]>([
		...SOURCE_SHOW_LOCAL_DEFAULT,
	]);

	const dataFormat: DataFormatDto = useMemo(() => {
		let min = LIMIT_VAL.DEFAULT_VAL;
		let max = LIMIT_VAL.DEFAULT_VAL;
		const configSourceLowercase = configSource.map((so) => so.toLowerCase());
		const sourceShowLocalLowercase = sourceShowLocal.map((so) => so.toLowerCase());

		const dataConfig: AmbassadorGraphAllDto[] = dataGraph.map(
			(item: AmbassadorGraphDto & any) => {
				let name = item.date;
				if (configInterval === INTERVAL.WEEKLY && name.includes('-')) {
					name = name.slice(name.indexOf('-') + 1);
				}

				let combined = 0;
				const arrSocial = [
					{ key: SOCIAL_UPCASE.INSTAGRAM, val: item.instagram },
					{ key: SOCIAL_UPCASE.TWITTER, val: item.twitter },
					{ key: SOCIAL_UPCASE.TIKTOK, val: item.tiktok },
					{ key: SOCIAL_UPCASE.YOUTUBE, val: item.youtube },
				];
				arrSocial.forEach((social) => {
					if (configSource.includes(social.key)) {
						combined += social.val || 0;
					}
				});

				const result = { ...item, date: name, combined };

				let keys = Object.keys(result).filter((key) => typeof result[key] === 'number');
				keys = keys.filter((key) => configSourceLowercase.includes(key));
				keys = keys.filter((key) => sourceShowLocalLowercase.includes(key));

				keys.forEach((key) => {
					if (min === LIMIT_VAL.DEFAULT_VAL || min > result[key]) {
						min = result[key];
					}
					if (max === LIMIT_VAL.DEFAULT_VAL || max < result[key]) {
						max = result[key];
					}
				});
				return result;
			},
		);
		if (min !== LIMIT_VAL.DEFAULT_VAL && (min === max || max - min < LIMIT_VAL.DEFAULT_MAX_Y)) {
			max = min + LIMIT_VAL.DEFAULT_MAX_Y;
		}
		return {
			data: dataConfig,
			min: min === LIMIT_VAL.DEFAULT_VAL ? LIMIT_VAL.DEFAULT_MIN_Y : min,
			max: max === LIMIT_VAL.DEFAULT_VAL ? LIMIT_VAL.DEFAULT_MAX_Y : max,
		};
	}, [dataGraph, configInterval, configSource, sourceShowLocal]);

	const actionsObj: ActionsTypes[] = [
		{
			id: 1,
			name: t('ambassador.configure_graph'),
			click: () => setOpenConfig(true),
		},
	];

	const menuActions = (
		<Menu>
			{actionsObj.map((item: ActionsTypes) => {
				if (item.hidden) {
					return null;
				}
				return (
					<Menu.Item disabled={item.disabled} key={item.id} onClick={item.click}>
						{item.name}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	const arrArea = [
		{
			id: 1,
			dataKey: 'submissions',
			stroke: colors.strokeSub,
			fill: colors.bgFillSub,
			type: SOCIAL_UPCASE.SUBMISSIONS,
			show: configSource.includes(SOCIAL_UPCASE.SUBMISSIONS),
			label: t('ambassador.submissions'),
		},
		{
			id: 2,
			dataKey: 'instagram',
			stroke: colors.strokeIn,
			fill: colors.bgFillIn,
			type: SOCIAL_UPCASE.INSTAGRAM,
			show: configSource.includes(SOCIAL_UPCASE.INSTAGRAM),
			label: t('content_detail.instagram'),
		},
		{
			id: 3,
			dataKey: 'tiktok',
			stroke: colors.strokeTi,
			fill: colors.bgFillTi,
			type: SOCIAL_UPCASE.TIKTOK,
			show: configSource.includes(SOCIAL_UPCASE.TIKTOK),
			label: t('button.tiktok'),
		},
		{
			id: 4,
			dataKey: 'twitter',
			stroke: colors.strokeTw,
			fill: colors.bgFillTw,
			type: SOCIAL_UPCASE.TWITTER,
			show: configSource.includes(SOCIAL_UPCASE.TWITTER),
			label: t('content_detail.twitter'),
		},
		{
			id: 5,
			dataKey: 'youtube',
			stroke: colors.strokeYt,
			fill: colors.bgFillYt,
			type: SOCIAL_UPCASE.YOUTUBE,
			show: configSource.includes(SOCIAL_UPCASE.YOUTUBE),
			label: t('button.youtube'),
		},
		{
			id: 6,
			dataKey: 'combined',
			stroke: colors.strokeCb,
			fill: colors.bgFillCb,
			type: SOCIAL_UPCASE.COMBINED,
			show: configSource.includes(SOCIAL_UPCASE.COMBINED),
			label: t('ambassador.all_socials'),
		},
	];

	const handleChangeShowSourceLocal = (key: string) => {
		if (sourceShowLocal.includes(key)) {
			setSourceShowLocal(sourceShowLocal.filter((source) => source !== key));
		} else {
			setSourceShowLocal([...sourceShowLocal, key]);
		}
	};

	return (
		<StyledGraphWrapper ref={refContainer}>
			<DrawerGraphConfig
				configInterval={configInterval}
				configSource={configSource}
				isShowCumulativeData={isShowCumulativeData}
				isShowDynamicScale={isShowDynamicScale}
				handleUpdateConfig={handleUpdateConfig}
				visible={openConfig}
				isSocial={isSocial}
				onClose={() => setOpenConfig(false)}
				getContainer={refContainer.current || false}
			/>
			<StyledTitle display="inline-block" fontSize="16px" color={THEME.colors.gray5}>
				{title}
			</StyledTitle>
			<StyledActionGraph>
				<Dropdown overlay={menuActions} placement="topRight" trigger={['hover']}>
					<StyledIcon style={{ alignItems: 'flex-start' }} className="icon_hover">
						<IconMoreVert />
					</StyledIcon>
				</Dropdown>
			</StyledActionGraph>

			<StyledWrapperContent width="100%" height="242px">
				{dataFormat.data.length === 0 && !loading && (
					<StyledWrapper className="height_full" marginTop={'0'}>
						<StyledEmpty image={IconEmpty} />
					</StyledWrapper>
				)}
				{dataFormat.data.length > 0 && !loading && (
					<ResponsiveContainer>
						<AreaChart
							data={dataFormat.data}
							margin={{ top: 10, right: 20, left: -30, bottom: 0 }}>
							<XAxis
								dataKey="date"
								stroke={colors.fill}
								interval={0}
								tick={<CustomizedXAxisTick />}
							/>
							<YAxis
								stroke={colors.fill}
								allowDecimals={false}
								interval={0}
								domain={
									isShowDynamicScale
										? [dataFormat.min, dataFormat.max]
										: undefined
								}
								allowDataOverflow={true}
								tick={<CustomizedYAxisTick />}
							/>
							<TooltipRecharts content={<CustomizeTooltip />} />
							{arrArea.map((item) => {
								if (!item.show || !sourceShowLocal.includes(item.type)) {
									return null;
								}
								return (
									<Area
										key={item.id}
										dataKey={item.dataKey}
										stroke={item.stroke}
										fill={item.fill}
										strokeWidth={2}
										className="123213"
									/>
								);
							})}
						</AreaChart>
					</ResponsiveContainer>
				)}
			</StyledWrapperContent>

			<StyledWrapperContent display="flex" alignItems="center" justifyContent="center">
				{arrArea?.map((item) => {
					if (!item.show) {
						return null;
					}
					const isShowLocal = sourceShowLocal.includes(item.type);
					return (
						<StyledInfoType
							onClick={() => handleChangeShowSourceLocal(item.type)}
							className={isShowLocal ? '' : 'hide_line'}
							key={item.type}>
							<StyledDot bgColor={isShowLocal ? item.stroke : THEME.colors.gray1} />
							{item.label}
						</StyledInfoType>
					);
				})}
			</StyledWrapperContent>
		</StyledGraphWrapper>
	);
};

export default GraphItem;
