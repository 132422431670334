import { API_MEDIA } from '@constants/APIs';
import { TYPE_FILE } from '@constants/common';
import LoadingWrapper from '@cores/LoadingWrapper';
import { fileToBase64 } from '@helpers/base64Helper';
import { getRequest } from '@helpers/requestHelpers';
import { dataURLtoFile } from '@utils/common';
import { Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
	max-width: 1200px;
	.ant-modal-body {
		height: 80vh;
		padding-bottom: 12px;
		.FIE_root {
			font-family: 'Open Sans', sans-serif !important;
		}
	}
	.ant-modal-footer {
		border: 0;
	}
`;

type ConfigImageEditorType = {
	source: string;
	[other: string]: any;
};

type PropTypes = {
	visible: boolean;
	onCancel: () => void;
	config: ConfigImageEditorType;
	onSave: (val: any) => void;
	showFooter?: boolean;
	[other: string]: any;
};

const ImageEditorModal = (props: PropTypes) => {
	const { config, onSave, visible, onCancel, showFooter = false, ...other } = props;
	const { source, ...otherConfig } = config;
	const { t } = useTranslation();
	const [fileBase64, setFileBase64] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const ref = useRef<any>({});

	const fetchBase64 = async () => {
		if (source) {
			setLoading(true);
			try {
				const data = await fetch(source, { method: 'HEAD' });
				const blob = await data.blob();

				const result: any = await fileToBase64(blob);
				if (result) {
					setFileBase64(source);
				}
				setLoading(false);
			} catch (error) {
				const res: any = await getRequest(`${API_MEDIA}${source}`);
				setFileBase64(res?.body?.data?.result);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (visible) {
			fetchBase64();
		} else {
			setFileBase64('');
		}
	}, [visible]);

	const otherProp = showFooter ? {} : { footer: null };

	return (
		<StyledModal
			width={'90vw'}
			centered
			visible={visible}
			okText={t('button.save_changes')}
			closable={false}
			onCancel={onCancel}
			{...other}
			onOk={() => {
				const editedImage = ref?.current();
				const file = dataURLtoFile(
					editedImage?.imageData?.imageBase64 || '',
					editedImage?.imageData?.name,
				);

				onSave(file);
				onCancel();
			}}
			{...otherProp}>
			<LoadingWrapper bgColorLoading={'transparent'} isLoading={loading}>
				{visible && fileBase64 && (
					<FilerobotImageEditor
						onBeforeSave={() => false}
						savingPixelRatio={4}
						previewPixelRatio={window.devicePixelRatio}
						useBackendTranslations={false}
						defaultSavedImageType={'jpg'}
						Text={{
							text: '',
							fontFamily: 'Open Sans, Sans-serif',
							fonts: [{ label: 'Open Sans', value: 'Open Sans, Sans-serif' }],
						}}
						onSave={(editedImageObject: any, designState: any) => {
							const file = dataURLtoFile(
								editedImageObject?.imageBase64 || '',
								editedImageObject?.name,
							);

							onSave(file);

							onCancel();
						}}
						getCurrentImgDataFnRef={ref}
						tabsIds={[
							TABS.FINETUNE,
							TABS.FILTERS,
							TABS.ANNOTATE,
							TABS.WATERMARK,
							TABS.RESIZE,
							TABS.ADJUST,
						]}
						Rotate={{ angle: 90, componentType: 'buttons' }}
						defaultTabId={TABS.FINETUNE}
						defaultToolId={TOOLS.BRIGHTNESS}
						onClose={() => onCancel()}
						Crop={{
							autoResize: true,
							ratio: 'custom',
							noPresets: true,
						}}
						{...otherConfig}
						source={fileBase64}
					/>
				)}
			</LoadingWrapper>
		</StyledModal>
	);
};

export default ImageEditorModal;
