import { EmailPayloadDto, RewardContainerDto, RewardPayloadDto } from '@models/messages/summary';
import * as React from 'react';

export type EmailBuilder = {
	emailRequest: EmailPayloadDto;
	setConfirmModal: (modalObject: any) => void;
	onLoading: (isLoading: boolean) => void;
	setEmailRequest?: (payload: EmailPayloadDto) => void;
	setGeneralAppliedReward?: (reward?: RewardContainerDto) => void;
	isPopup: boolean;
	isInvitationFromCreator?: boolean;
};

// Default context values
const values: EmailBuilder = {
	emailRequest: {},
	setConfirmModal: () => {},
	onLoading: () => {},
	isPopup: false,
	isInvitationFromCreator: false,
};

const EmailBuilderContext = React.createContext(values);
export default EmailBuilderContext;
