import THEME from '@constants/themes/themes';
import styled from 'styled-components';

const StyledLandingPageTemplate = styled.div`
	* {
		color: ${THEME.colors.black};
		border-collapse: separate;
		font-family: 'IBM Plex Sans', sans-serif;
	}

	background-color: ${THEME.colors.white};
	height: 100%;
	overflow: hidden;

	a body {
		height: max-content;
	}
`;

const StyledLandingPageTemplateIframe = styled.iframe`
	border: none;
`;

export { StyledLandingPageTemplate, StyledLandingPageTemplateIframe };
