import { createAction } from 'redux-actions';
import {
	albumSelectTypes,
	createAlbumTypes,
	deleteGuestTypes,
	exportAlbumContentsTypes,
	fetchAlbumsSuggestTypes,
	fetchAlbumsTypes,
	fetchDetailAlbumTypes,
	fetchGuestListTypes,
	getAlbumListLiteTypes,
	listAlbumTypes,
	removeAlbumsTypes,
	removeSuggestAlbumTypes,
	sendGuestPassCodeTypes,
	updateAlbumTypes,
} from './albumManager.types';

// Action function fetch albums
export const fetchAlbumsRequest = createAction(fetchAlbumsTypes.FETCH_ALBUMS_REQUEST);
export const fetchAlbumsSucceeded = createAction(fetchAlbumsTypes.FETCH_ALBUMS_SUCCEEDED);
export const fetchAlbumsFailed = createAction(fetchAlbumsTypes.FETCH_ALBUMS_FAILED);

// Action function fetch albums suggestion
export const fetchAlbumsSuggestRequest = createAction(
	fetchAlbumsSuggestTypes.FETCH_ALBUMS_SUGGEST_REQUEST,
);
export const fetchAlbumsSuggestSucceeded = createAction(
	fetchAlbumsSuggestTypes.FETCH_ALBUMS_SUGGEST_SUCCEEDED,
);
export const fetchAlbumsSuggestFailed = createAction(
	fetchAlbumsSuggestTypes.FETCH_ALBUMS_SUGGEST_FAILED,
);

// Action function create album
export const createAlbumRequest = createAction(createAlbumTypes.CREATE_ALBUM_REQUEST);
export const createAlbumSucceeded = createAction(createAlbumTypes.CREATE_ALBUM_SUCCEEDED);
export const createAlbumFailed = createAction(createAlbumTypes.CREATE_ALBUM_FAILED);
export const createAlbumEnd = createAction(createAlbumTypes.CREATE_ALBUM_END);

// Action function update album
export const updateAlbumRequest = createAction(updateAlbumTypes.UPDATE_ALBUM_REQUEST);
export const updateAlbumSucceeded = createAction(updateAlbumTypes.UPDATE_ALBUM_SUCCEEDED);
export const updateAlbumFailed = createAction(updateAlbumTypes.UPDATE_ALBUM_FAILED);
export const updateAlbumEnd = createAction(updateAlbumTypes.UPDATE_ALBUM_END);

// Action function remove album
export const removeAlbumsRequest = createAction(removeAlbumsTypes.REMOVE_ALBUM_REQUEST);
export const removeAlbumsSucceeded = createAction(removeAlbumsTypes.REMOVE_ALBUM_SUCCEEDED);
export const removeAlbumsFailed = createAction(removeAlbumsTypes.REMOVE_ALBUM_FAILED);
export const removeAlbumsEnd = createAction(removeAlbumsTypes.REMOVE_ALBUM_END);

// Action function export album contents
export const exportAlbumContentsRequest = createAction(
	exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_REQUEST,
);
export const exportAlbumContentsSucceeded = createAction(
	exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_SUCCEEDED,
);
export const exportAlbumContentsFailed = createAction(
	exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_FAILED,
);
export const exportAlbumContentsEnd = createAction(
	exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_END,
);

// Action function remove suggest album
export const removeSuggestAlbumRequest = createAction(
	removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_REQUEST,
);
export const removeSuggestAlbumSucceeded = createAction(
	removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_SUCCEEDED,
);
export const removeSuggestAlbumFailed = createAction(
	removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_FAILED,
);
export const removeSuggestAlbumEnd = createAction(removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_END);

// Action function fetch albums
export const fetchDetailAlbumRequest = createAction(
	fetchDetailAlbumTypes.FETCH_DETAIL_ALBUM_REQUEST,
);
export const fetchDetailAlbumSucceeded = createAction(
	fetchDetailAlbumTypes.FETCH_DETAIL_ALBUM_SUCCEEDED,
);
export const fetchDetailAlbumFailed = createAction(fetchDetailAlbumTypes.FETCH_DETAIL_ALBUM_FAILED);

// Action function update list album
export const updateListAlbum = createAction(listAlbumTypes.UPDATE_LIST_ALBUM);

// Action function update album select
export const updateAlbumSelect = createAction(albumSelectTypes.UPDATE_ALBUM_SELECT);

// Action function fetch guests
export const fetchGuestListRequest = createAction(fetchGuestListTypes.FETCH_GUEST_LIST_REQUEST);
export const fetchGuestListSucceeded = createAction(fetchGuestListTypes.FETCH_GUEST_LIST_SUCCEEDED);
export const fetchGuestListFailed = createAction(fetchGuestListTypes.FETCH_GUEST_LIST_FAILED);

// Action function send passcode to guest
export const sendGuestPassCodeRequest = createAction(
	sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_REQUEST,
);
export const sendGuestPassCodeSucceeded = createAction(
	sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_SUCCEEDED,
);
export const sendGuestPassCodeFailed = createAction(
	sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_FAILED,
);
export const sendGuestPassCodeEnd = createAction(sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_END);

// Action function delete guest
export const deleteGuestRequest = createAction(deleteGuestTypes.DELETE_GUEST_REQUEST);
export const deleteGuestSucceeded = createAction(deleteGuestTypes.DELETE_GUEST_SUCCEEDED);
export const deleteGuestFailed = createAction(deleteGuestTypes.DELETE_GUEST_FAILED);
export const deleteGuestEnd = createAction(deleteGuestTypes.DELETE_GUEST_END);

// Get album list lite
export const getAlbumListLiteRequest = createAction(
	getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_REQUEST,
);
export const getAlbumListLiteSucceeded = createAction(
	getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_SUCCEEDED,
);
export const getAlbumListLiteFailed = createAction(
	getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_FAILED,
);
export const getAlbumListLiteEnd = createAction(getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_END);
