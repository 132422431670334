import { GALLERY_EDITOR_CONFIG } from '@constants/publish/gallery';
import { IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { ELEMENT_SELECTOR } from '@constants/taskManager';
import EditorCustom from '@cores/EditorCustom';
import { TaskHistoryItemDto } from '@models/taskmanager';
import { TaskManagerStoreDto } from '@models/taskmanager/store';
import { addCommentRequest, updateCommentRequest } from '@stores/taskmanager/taskmanager.actions';
import { StyledSaveButton, StyledTaskEditorWrapper } from '@styled/TaskManager/TaskManagerStyled';
import { isEqualStringsReplacedSpace } from '@utils/common';
import { autoFocusAtEnd, removeEmptyAtTheEndParagraphs } from '@utils/editor';
import _ from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type TaskEditorProps = {
	taskId?: number;
	data?: TaskHistoryItemDto;
	onCancel?: () => void;
	onSave?: (data: TaskHistoryItemDto) => void;
};

const TaskEditor = forwardRef((props: TaskEditorProps, ref: any) => {
	const dispatch = useDispatch();
	const editorRef = useRef<any>(null);
	const { t } = useTranslation();
	const { taskId, data, onCancel, onSave } = props;
	const {
		accountListLite = [],
		addCommentStatus = NONE,
		updateCommentStatus = NONE,
	}: TaskManagerStoreDto = useSelector((state: any) => state.taskManager);

	const [changedContent, setChangedContent] = useState<string>('');

	const editorId = `task_editor_${data ? 'history_id' : 'id'}`;

	useEffect(() => {
		return () => {
			clearEditorContent();
		};
	}, [taskId, data]);

	const clearEditorContent = () => {
		if (editorRef.current) {
			editorRef.current.clearEditorContent();
		}
		setChangedContent('');
	};

	const getEditorContent = (type?: string) => {
		let content = '';

		if (editorRef.current) {
			content = editorRef?.current?.getEditorContent('raw');
		}

		return content;
	};

	const hasChanges = () => {
		const rawContent = getEditorContent();
		return data
			? !!changedContent && !isEqualStringsReplacedSpace(data?.eventDetail || '', rawContent)
			: !!changedContent;
	};

	useImperativeHandle(ref, () => ({
		clearEditorContent,
		getEditorContent,
		hasChanges,
	}));

	useEffect(() => {
		if (data) {
			const handleBodyClick = (e: MouseEvent | any) => {
				const editorContainer = document.getElementById(editorId); // Replace with the appropriate selector for your editor container
				const dropdown = document.getElementById(
					ELEMENT_SELECTOR.CUSTOM_MENTION_DROPDOWN_ID,
				);
				const isSvgElement = e.target instanceof SVGElement; // Options in menu bar of editor
				const isIgnoredElement = (target: Element | null | any) => {
					return (
						target?.classList.contains('tox-button') ||
						target?.classList.contains(ELEMENT_SELECTOR.TASK_DETAIL_ARROW_BTN) ||
						target?.classList.contains(ELEMENT_SELECTOR.MODAL_WRAP) ||
						target?.textContent?.includes(t('button.go_back'))
					);
				};

				if (
					editorContainer &&
					editorRef.current &&
					!editorContainer.contains(e.target as Node) &&
					!dropdown?.contains(e.target as Node) &&
					!isSvgElement &&
					!isIgnoredElement(e.target) &&
					onCancel
				) {
					onCancel();
				}
			};
			document.body.addEventListener('mousedown', handleBodyClick);
			return () => {
				document.body.removeEventListener('mousedown', handleBodyClick);
			};
		}

		return () => {};
	}, [editorRef, data]);

	useEffect(() => {
		if (addCommentStatus === SUCCEEDED) {
			if (editorRef.current) {
				clearEditorContent();
			}
		}
	}, [addCommentStatus]);

	const actionOnComment = () => {
		const content = editorRef?.current?.getEditorContent() || '';
		const comment = removeEmptyAtTheEndParagraphs(content);

		if (data) {
			if (hasChanges()) {
				if (onSave) {
					onSave({ ...data, eventDetail: comment });
				}
				dispatch(
					updateCommentRequest({
						historyId: data.id,
						comment,
					}),
				);
				return;
			}
			if (onCancel) {
				onCancel();
			}
		} else if (taskId && comment) {
			dispatch(
				addCommentRequest({
					taskId,
					comment,
				}),
			);
		}
	};

	const autoFocus: any = useMemo(() => (data && !_.isUndefined(data) ? true : false), [data]);
	const FIELD_NAMES = {
		FULL_NAME: 'fullName',
		EMAIL: 'email',
	};

	return (
		<StyledTaskEditorWrapper id={editorId}>
			<EditorCustom
				ref={editorRef}
				hasMentionFeat
				isPressingEnterToSave
				initialValue={data?.eventDetail}
				mentionList={accountListLite}
				otherInit={{
					auto_focus: autoFocus,
					body_class: 'task_comment_editor',
					plugins: GALLERY_EDITOR_CONFIG.PLUGINS,
					toolbar: GALLERY_EDITOR_CONFIG.TOOLBAR_TASK,
				}}
				otherSetup={(editor: any) => {
					editor.ui.registry.addButton('reset', {
						text: GALLERY_EDITOR_CONFIG.ICONS.RESET,
						tooltip: t('button.reset'),
						onAction: (_: any) => {
							editorRef.current.setContent('');
						},
					});
				}}
				fieldNames={{
					detectionData: FIELD_NAMES.EMAIL,
					displayedLabel: FIELD_NAMES.FULL_NAME,
					filtered: [FIELD_NAMES.EMAIL, FIELD_NAMES.FULL_NAME],
				}}
				isOtherConditionsToSave={
					addCommentStatus !== IN_PROGRESS && updateCommentStatus !== IN_PROGRESS
				}
				moreDependencies={[addCommentStatus, updateCommentStatus]}
				onSaveWithEnter={actionOnComment}
				setEditorContent={(val: string) => {
					setChangedContent(val);
				}}
			/>
			<StyledSaveButton
				id={ELEMENT_SELECTOR.SAVE_COMMENT_BUTTON_ID}
				type={hasChanges() ? 'primary' : 'default'}
				onClick={actionOnComment}>
				{t('button.save')}
			</StyledSaveButton>
		</StyledTaskEditorWrapper>
	);
});

export default TaskEditor;
