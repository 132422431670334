/**
 * @prettier
 */

import { useState, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Input } from 'antd';
import Button from '@cores/Button';
import { validateFolderName } from '@utils/formValidation';
import THEME from '@constants/themes/themes';
import { IconError } from '@assets/icons';
import { useTranslation } from 'react-i18next';

const StyledWrapper = styled.div`
	width: 350px;
	margin: 0 auto;
	color: ${THEME.colors.gray5};
`;

const StlyedModal = styled(Modal)`
	.ant-modal-title {
		font-size: 1.143rem;
		font-weight: 600;
	}

	.ant-modal-close-x {
		font-size: 1rem;

		&:hover {
			color: ${THEME.colors.gray5};
		}
	}
`;

const StyledInput = styled(Input)`
	height: 32px;
	padding: 0 12px;
`;

const StyledBtn = styled(Button)<{ borderColor?: string; hoverColor?: string }>`
	display: inline-block;
	padding: 0 12px;
	border-color: ${({ borderColor }) => borderColor || 'none'};

	&:hover {
		background-color: ${({ hoverColor }) => hoverColor || 'none'};
	}
`;

const StyledText = styled.p`
	padding: 0;
	margin: 6px 0;
`;

const StyledErrorMsg = styled.div`
	display: flex;
	align-items: center;
	height: 20px;
	margin: 6px 0 0 0;

	p {
		margin-left: 8px;
	}
`;

type Props = {
	isVisible: boolean;
	folderList: any;
	onClose: () => void;
	onSave: (folderName: string) => void;
};

const ModalAddFolder = (props: Props) => {
	const nodeRef = useRef(null);
	const [folderName, setFolderName] = useState<string>('');
	const [addFolderErrorMsg, setAddFolderErrorMsg] = useState<string>('');
	const { isVisible = false, onClose, onSave, folderList } = props;
	const { t } = useTranslation();

	const onChange = (evt: any) => {
		const { value } = evt.target;

		setFolderName(value);
	};

	const handleSave = () => {
		const errMsgs = validateFolderName(folderList, folderName);
		if (errMsgs) {
			setAddFolderErrorMsg(errMsgs);
			setTimeout(() => {
				reset();
			}, 4000);
		} else {
			onSave(folderName);
			reset();
		}
	};

	const handleCancel = () => {
		reset();
		onClose();
	};

	const reset = () => {
		setFolderName('');
		setAddFolderErrorMsg('');
	};

	return (
		<div ref={nodeRef} className="modal-add-folder">
			<StlyedModal
				title={t('content_management.folder_add.custom_folder')}
				visible={isVisible}
				onOk={() => handleSave()}
				onCancel={() => handleCancel()}
				footer={[
					<StyledBtn
						borderColor={THEME.colors.darkBlue4}
						hoverColor="transparent"
						type="ghost"
						onClick={handleCancel}>
						{t('button.cancel')}
					</StyledBtn>,
					<StyledBtn type="primary" onClick={handleSave}>
						{t('button.save')}
					</StyledBtn>,
				]}>
				<StyledWrapper ref={nodeRef}>
					<StyledText>{t('content_management.folder_add.folder_name')}</StyledText>
					<StyledInput
						value={folderName}
						onChange={onChange}
						placeholder={t('content_management.folder_add.folder_name_placeholder')}
					/>

					<StyledErrorMsg>
						{addFolderErrorMsg && (
							<>
								<IconError />
								<StyledText>{addFolderErrorMsg}</StyledText>
							</>
						)}
					</StyledErrorMsg>
				</StyledWrapper>
			</StlyedModal>
		</div>
	);
};

export default ModalAddFolder;
