import THEME from '@constants/themes/themes';
import { Modal } from 'antd';
import styled from 'styled-components';

const StyledModalPublishLog = styled(Modal)`
	.ant-modal-header {
		padding: 16px;
	}
	.ant-modal-body {
		padding: 16px;
	}
`;

const StyledTitleModal = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: ${THEME.colors.gray5};
`;

const StyledMoreInfo = styled.div`
	font-weight: 400;
	font-size: 10px;
	line-height: 14px;
	color: ${THEME.colors.gray1};
	display: inline-block;
	margin-top: 4px;
`;

const StyledSubInfo = styled.div`
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;
	color: ${THEME.colors.white};
`;

export { StyledModalPublishLog, StyledTitleModal, StyledMoreInfo, StyledSubInfo };
