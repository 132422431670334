import { Pagination as AntPagination } from 'antd';

type Props = {
  defaultCurrent: number;
  total: number;
  onChange: (page: number, pageSize?: number) => {};
  disabled: boolean;
};

const Pagination: React.FC<Props> = ({ defaultCurrent, total, onChange, ...rest }) => {
  // TODO
  return (
    <AntPagination {...rest} defaultCurrent={defaultCurrent} total={total} onChange={onChange} />
  );
};

Pagination.defaultProps = {
  disabled: false,
};

export default Pagination;
