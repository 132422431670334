import { GalleryTagSVG } from '@assets/icons/svg/grapesjs';

export const GalleryTagBlock = (bm: any, label: string) => {
	bm.add('gallery-tag', {
		label,
		media: GalleryTagSVG,
		content: {
			type: 'gallery-tag',
			attributes: { class: 'entribe_gallery_v100_2023' },
		},
	});
};

export default (domc: any, editor: any) => {
	const defaultType = domc.getType('default');

	domc.addType('gallery-tag', {
		model: {
			defaults: {
				'custom-name': 'Gallery Tag',
				tagName: 'div',
				style: {
					width: '100%',
					'min-height': '50px',
				},
				resizable: true,
				traits: [
					{
						type: 'tr-gallery-tag',
						label: 'Gallery Link',
						name: 'galleryLink',
						placeholder: 'Input a gallery link',
						changeProp: true,
					},
				],
			},
		},
		view: defaultType.view,
	});
};
