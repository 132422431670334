import { NOTIFICATION_ELEMENT_SELECTOR } from '@constants/notification';
import THEME from '@constants/themes/themes';
import { StyledFlex, StyledTabs, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { Popover } from 'antd';
import styled from 'styled-components';

const StyledPopover = styled(Popover)`
	display: inline-flex;
	align-items: center;
`;

const StyledNotification = styled.div``;

const StyledNotificationNum = styled.div<{ position?: string }>`
	position: ${({ position }) => position || 'absolute'};
	left: 17px;
	top: 8px;
	background-color: ${THEME.colors.orangeBase};
	font-size: 11px;
	color: ${THEME.colors.white};
	display: block;
	border-radius: 50%;
	width: 11px;
	height: 11px;
`;

const StyledNotificationHeader = styled.div`
	position: relative;
	width: 100%;
	border-bottom: 1px solid ${THEME.colors.darkBlue4};
`;

const StyledNotificationTab = styled(StyledTabs)<{ maxHeight?: string }>`
	${({ maxHeight }) => maxHeight && `max-height: ${maxHeight} !important`};

	.ant-tabs-nav {
		padding: 0 18px 0 24px;
	}

	.ant-tabs-nav-wrap {
		justify-content: flex-end;
	}

	.ant-tabs-tab {
		&-btn {
			color: ${THEME.colors.gray1};
		}
	}

	.ant-tabs-content {
		height: 100%;
	}
`;

const StyledWrapperListNotify = styled.div`
	padding: 22px 12px;
	height: 100%;
	overflow: hidden auto;
`;

const StyledTextNotification = styled.div`
	font-size: 24px;
	font-weight: 400;
	color: ${THEME.colors.gray5};
`;

const StyledTextMarkAllNotification = styled.div`
	font-size: 16px;
	font-weight: 400;
	color: ${THEME.colors.gray1};
	line-height: 24px;
	position: absolute;
	top: 20px;
	right: 24px;

	&.has_notify {
		color: ${THEME.colors.gray5};
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
`;

const StyledTextHelloNotification = styled.div`
	font-weight: 400;
	font-size: 16px;
	color: ${THEME.colors.gray1};
	margin-bottom: 28px;
	margin-left: 16px;
`;

const StyledNotificationContent = styled.div`
	overflow: auto;
`;

const StyledNotificationPicture = styled.img`
	display: block;
	margin: 0 auto;
`;

const StyledNotificationTextReadAll = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 34px;
	color: ${THEME.colors.gray5};
	text-align: center;
	margin-top: 6px;
	margin-bottom: 6px;
`;

const StyledGroupNotify = styled.div`
	margin-top: 28px;
	&:first-child {
		margin-top: 0;
	}
`;

const StyledTitleGroupNotify = styled.div`
	font-weight: 700;
	font-size: 16px;
	color: ${THEME.colors.gray5};
	text-transform: uppercase;
	margin-left: 16px;
`;

const StyledWrapperListMessage = styled.div`
	margin-top: 8px;
`;

const StyledNotificationWrapperMessage = styled.div<{ margin?: string }>`
	position: relative;
	margin: ${({ margin }) => margin || '4px 0 0 0'};
	padding: 4px 16px;
	&:first-child {
		margin-top: 0;
	}
	&:not(.hidden_hover) {
		&:hover {
			cursor: pointer;
			.text_message {
				color: ${THEME.colors.white};
			}
			.sub_type,
			.redirect_to_task_manager * {
				color: ${THEME.colors.orangeBase};
				svg {
					path {
						fill: ${THEME.colors.orangeBase} !important;
					}
				}
			}
		}
	}
	&:hover {
		background-color: rgba(226, 88, 43, 0.3);
		border-radius: 2px;
	}
`;

const StyledWrapperMessage = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;
`;

const StyledNotificationMessage = styled.p`
	margin: 0;
	width: calc(100% - 32px);
	font-size: 16px;
	color: ${THEME.colors.gray5};
`;

const StyledWrapperIconBg = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: end;
	width: 32px;
`;

const StyledNotificationNumWrapper = styled.div`
	width: 24px;
	height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: rgba(226, 88, 43, 0.3);
		border-radius: 50%;
	}
`;

const StyledNotificationNumBackground = styled.div`
	background-color: ${THEME.colors.orangeBase};
	color: ${THEME.colors.white};
	border-radius: 50%;
	width: 12px;
	height: 12px;
`;

const StyledSubType = styled.div``;

const StyledContentSubType = styled.span`
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	color: ${THEME.colors.gray1};
	&:hover {
		cursor: pointer;
		color: ${THEME.colors.orangeBase};
		svg {
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}
`;

const StyledIcon = styled.div<{ size?: number }>`
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 10px;
	cursor: pointer;
	svg {
		width: ${({ size }) => size || 30}px;
		height: ${({ size }) => size || 30}px;
	}

	.ant-popover-inner {
		background-color: ${THEME.colors.darkBlue2};
		box-shadow: 0px 0px 7px 7px rgba(0, 0, 0, 0.5);
	}
	.ant-popover-arrow {
		border-top-color: ${THEME.colors.darkBlue2} !important;
		border-left-color: ${THEME.colors.darkBlue2} !important;
	}

	.ant-popover-content {
		width: 480px;
		height: 235px;
	}
`;

const StyledMaskPopover = styled.div`
	background-color: rgba(2, 12, 21, 0.7);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2555;
	cursor: default;
`;

const StyledNotificationWrapper = styled(StyledWrapperContent)<{ subtractedHeight?: number }>`
	&#${NOTIFICATION_ELEMENT_SELECTOR.TASK_NOTIFICATION_WRAPPER_ID} {
		padding: 22px 12px;
		position: relative;
		width: 100%;
		height: ${({ subtractedHeight }) => `calc(100% - ${subtractedHeight || 0}px)`};
		overflow: auto;
	}

	&.header_notification {
		border-bottom: 1px solid ${THEME.colors.darkBlue3};
		transition: all 0.5s;

		.header_notification_mentions,
		.header_notification_others {
			position: relative;
			height: 100%;
			padding: 0 28px;
			left: unset;
			right: unset;
			top: unset;
			float: right;
			align-items: center;
			margin: 0 0 8px 0;
		}
	}

	.header_notification_fixed {
		display: flex;
		position: absolute;
		top: 20px;
		right: 24px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent !important;
	}
`;

const StyledRedirectTaskManager = styled(StyledFlex)`
	&:hover {
		color: ${THEME.colors.orangeBase};

		svg {
			&,
			path {
				fill: ${THEME.colors.orangeBase} !important;
			}
		}
	}
`;

export {
	StyledPopover,
	StyledNotification,
	StyledNotificationNum,
	StyledNotificationHeader,
	StyledWrapperListNotify,
	StyledTextNotification,
	StyledTextMarkAllNotification,
	StyledTextHelloNotification,
	StyledNotificationContent,
	StyledNotificationPicture,
	StyledNotificationTextReadAll,
	StyledGroupNotify,
	StyledTitleGroupNotify,
	StyledWrapperListMessage,
	StyledNotificationWrapperMessage,
	StyledWrapperMessage,
	StyledNotificationMessage,
	StyledWrapperIconBg,
	StyledNotificationNumWrapper,
	StyledNotificationNumBackground,
	StyledSubType,
	StyledContentSubType,
	StyledIcon,
	StyledMaskPopover,
	StyledNotificationTab,
	StyledNotificationWrapper,
	StyledRedirectTaskManager,
};
