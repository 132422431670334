import THEME from '@constants/themes/themes';
import {
	VideoLabelDetectionDto,
	VideoLabelDetectionSegmentDto,
} from '@models/content/contentLibrary/summary';
import { StyledText, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { formatDetectionTime } from '@utils/content';

export type DataDetectionItemTypes = {
	times: number[];
	description?: string;
};
type PropTypes = {
	dataDetections: DataDetectionItemTypes[];
	idElm: string;
};

const VideoLabelDetection = (props: PropTypes) => {
	const { dataDetections, idElm } = props;

	const seekVideo = (time: number) => {
		const videoEl: any = document.getElementById(idElm);

		if (videoEl) {
			videoEl.currentTime = time;
		}
	};

	const renderVideoLabelDetection = () => {
		return dataDetections.map((item: DataDetectionItemTypes) => {
			return (
				<StyledWrapperContent
					width="fit-content"
					background={THEME.colors.darkBlue3}
					padding="0 6px"
					flexWrap="wrap"
					display="inline-flex"
					borderRadius="2px"
					margin="0 6px 6px 0">
					{item.description && (
						<StyledText margin="0 2px 0 0">{item.description}</StyledText>
					)}
					{item.times.map((time: number) => {
						return (
							<StyledText
								onClick={() => seekVideo(time)}
								margin="0 0 0 4px"
								color={THEME.colors.blueBase}
								cursor="pointer">
								{`(${formatDetectionTime(time)})`}
							</StyledText>
						);
					})}
				</StyledWrapperContent>
			);
		});
	};

	return <StyledWrapperContent>{renderVideoLabelDetection()}</StyledWrapperContent>;
};

export default VideoLabelDetection;
