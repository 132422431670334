import { ANALYZED_CONTENT_OPTIONS } from '@constants/content/contentLibrary';
import { DetectionServiceDto, EstimatedCostDto } from '@models/content/contentLibrary/summary';
import React from 'react';

export type MediaRecognitionModalContextType = {
	setCurrentProcess: (process: string) => void;
	selectedRowKeys: React.Key[];
	setSelectedRowKeys: (keyList: React.Key[]) => void;
	estimatedCost: string | number;
	setAnalyzedOption: (option: string) => void;
	analyzedOption: string;
	selectedServiceList: DetectionServiceDto[];
	detectionServiceDetail: EstimatedCostDto;
};

// Default context values
const values: MediaRecognitionModalContextType = {
	setCurrentProcess: () => {},
	selectedRowKeys: [],
	setSelectedRowKeys: () => {},
	estimatedCost: 0,
	setAnalyzedOption: () => {},
	analyzedOption: ANALYZED_CONTENT_OPTIONS.SKIP_CONTENT_ANALYZED,
	selectedServiceList: [],
	detectionServiceDetail: {
		numberOfImages: 0,
		numberOfVideos: 0,
		contentIds: [],
		detectionServiceEstimateCost: [],
	},
};

const MediaRecognitionModalContext = React.createContext(values);
export default MediaRecognitionModalContext;
