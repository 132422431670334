import { IconCheckActive, IconCopy, IconEdit } from '@assets/icons';
import { ImageFallback, ImagePlaceholder } from '@assets/images';
import ProgressLine from '@components/CustomProgress/ProgressLine';
import { ROUTE_PATH } from '@constants/common';
import { ItemCamPaign, MetadataCampaignDto } from '@models/campaign';
import { saveFilterDashboardDefault } from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledButton,
	StyledCardDiv,
	StyledCartText,
	StyledImg,
	StyledLink,
	StyledRowCard,
	StyledSessionInfo,
	StyledWrapperFlexBetween,
	StyledWrapperImgCampaign,
} from '@styled/Campaign/CampaignStyled';
import { StyledIcon, StyledTitle, StyledWrapperTitle } from '@styled/Common/CommonStyled';
import { getSocialS3URL, handleImageLoadedError, kFormatter } from '@utils/common';
import { Button, Col, Popover, Row, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

type CardProps = {
	showModal: (data: ItemCamPaign) => void;
	data: ItemCamPaign;
	isTabActive?: boolean;
	handleActivate: () => void;
	isRecommendationCampaign?: boolean;
};

const CardCampaign = (props: CardProps) => {
	const { t } = useTranslation();

	const history = useHistory();
	const dispatch = useDispatch();

	const {
		showModal,
		data,
		isTabActive,
		handleActivate,
		isRecommendationCampaign = false,
	} = props;

	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { widgetLinks = [], defaultWidgetLink = '', ambassadorEnabled = false } = clientSettings;

	const [getHashTags, setGetHashTags] = useState<string[]>([]);

	const metadataObject: MetadataCampaignDto | null = useMemo(() => {
		let obj = null;
		if (data?.metadata) {
			try {
				obj = JSON.parse(data?.metadata);
			} catch (error) {
				console.log(error);
			}
		}
		return obj;
	}, [data]);

	const {
		id,
		hashtags = '',
		name,
		widgetId = 0,
		submissionsGoals = 0,
		creatorsGoals = 0,
		socialPostsGoals = 0,
		campaignStatisticsDTO: {
			submission = 0,
			pendingContent = 0,
			uniqueCreators = 0,
			ambassadorPosts = 0,
		} = {},
	} = data;

	useEffect(() => {
		handleConvertHash();
	}, [hashtags]);

	const hasCreatorEmail = useMemo(() => creatorProfile && creatorProfile.email, [creatorProfile]);

	const widgetLink = useMemo(() => {
		const widgetLinkObj = widgetLinks.find((widgetLink: any) => widgetLink.id === widgetId);
		const widgetBaseUrl = widgetLinkObj ? widgetLinkObj.url : defaultWidgetLink;

		return widgetBaseUrl?.indexOf('?') >= 0
			? `${widgetBaseUrl}&c=${id}`
			: `${widgetBaseUrl}?c=${id}`;
	}, [widgetLinks, widgetId, defaultWidgetLink, id]);

	const renderValue = (val: number) => {
		return val > 0 ? kFormatter(val) : 0;
	};

	const convertPercent = (val: number, total: number) => {
		let result = 0;
		if (total === 0) {
			if (val > 0) {
				result = 100;
			}
		} else {
			result = Math.floor((val / total) * 100);
		}
		return result > 100 ? 100 : result;
	};

	const handleRedirectSubmission = (pendingReview?: boolean) => {
		const result: any = {
			pathname: ROUTE_PATH.SUBMISSIONS,
			state: { idCampaign: id },
		};
		if (pendingReview) {
			result.state.needReview = true;
		}
		history.push(result);
	};

	const handleRedirectToAmbassadorDashboard = () => {
		if (!ambassadorEnabled) {
			return;
		}
		history.push({
			pathname: ROUTE_PATH.AMBASSADOR_DASHBOARD,
			state: {
				campaign: {
					value: id,
					name,
				},
			},
		});
	};

	const arrInfo = [
		{
			id: 1,
			title: t('campaign.submissions'),
			value: renderValue(submission),
			total: renderValue(submissionsGoals),
			percent: convertPercent(submission, submissionsGoals),
			titleTooltipPercent:
				t('campaign.count_submissions') + renderValue(submission)?.toString(),
			percentSuccess: convertPercent(pendingContent, submissionsGoals),
			titleTooltipSuccess:
				t('campaign.count_pending_review') + renderValue(pendingContent)?.toString(),
			isFull: !!submission && submission >= submissionsGoals,
			onClickPercent: () => handleRedirectSubmission(),
			onClickPercentSuccess: () => handleRedirectSubmission(true),
		},
		{
			id: 2,
			title: t('campaign.ambassador_posts'),
			value: renderValue(ambassadorPosts),
			total: renderValue(socialPostsGoals),
			percent: convertPercent(ambassadorPosts, socialPostsGoals),
			titleTooltipPercent:
				`${t('campaign.ambassador_posts')}: ` + renderValue(ambassadorPosts)?.toString(),
			isFull: !!ambassadorPosts && ambassadorPosts >= socialPostsGoals,
			onClickPercent: handleRedirectToAmbassadorDashboard,
		},
		{
			id: 3,
			title: t('campaign.creators'),
			value: renderValue(uniqueCreators),
			total: renderValue(creatorsGoals),
			titleTooltipPercent:
				t('campaign.count_creators') + renderValue(uniqueCreators)?.toString(),
			percent: convertPercent(uniqueCreators, creatorsGoals),
			isFull: !!uniqueCreators && uniqueCreators >= creatorsGoals,
			onClickPercent: () => handleCreators(uniqueCreators),
		},
	];

	const handleConvertHash = () => {
		let newHash: Array<string> = [];
		if (!hashtags) {
			setGetHashTags(newHash);
		} else if (hashtags.includes('[')) {
			newHash = hashtags.split('"');
			const newArr = newHash.filter((item: string) => item !== ',');
			newArr.splice(0, 1);
			newArr.splice(newArr.length - 1, 1);
			setGetHashTags(newArr);
		} else {
			newHash = hashtags.split(',');
			setGetHashTags(newHash);
		}
	};

	const handleCreators = (val: number) => {
		if (val && isTabActive) {
			history.push({
				pathname: ROUTE_PATH.CREATORS_SUMMARY,
				state: { campaignId: data?.id },
			});
		}
	};

	const handleViewResult = () => {
		dispatch(saveFilterDashboardDefault({ campaign: data }));
		setTimeout(() => {
			history.push({ pathname: ROUTE_PATH.DASHBOARD });
		}, 0);
	};

	const onCopy = () => {
		const input = document.createElement('input');
		input.value = widgetLink || '';
		document.body.appendChild(input);
		input.select();
		document.execCommand('Copy');
		input.remove();
		message.success(t('campaign.message.copy_success'));
	};

	const handleRenderImage = (url: string | undefined) => {
		return (
			<StyledCardDiv className="card-campaign-Img">
				<StyledCardDiv className="card-campaign-Img-only1">
					<StyledImg
						src={url ? getSocialS3URL(url) : ImagePlaceholder}
						alt={t('campaign.image_empty')}
						onError={(e) => handleImageLoadedError(e, url)}
						fallback={ImageFallback}
						className="img_campaign"
					/>
				</StyledCardDiv>
			</StyledCardDiv>
		);
	};

	const renderCreatorInvitationButton = () => {
		const element = (
			<StyledButton
				className={`btn_overflow ${hasCreatorEmail ? '' : 'gray-out'}`}
				width="100%"
				title={t('button.invite_to_this_campaign')}
				onClick={() => (hasCreatorEmail ? handleActivate() : null)}
				type="primary">
				{t('button.invite_to_this_campaign')}
			</StyledButton>
		);

		if (hasCreatorEmail) {
			return element;
		}

		return (
			<Popover
				placement="topLeft"
				overlayClassName="overlay_dark_blue2"
				overlayInnerStyle={{
					width: '350px',
				}}
				content={hasCreatorEmail ? '' : t('campaign.message.no_email_to_activate')}>
				{element}
			</Popover>
		);
	};

	return (
		<StyledRowCard>
			<StyledCardDiv className="card-campaign-div-title">
				<StyledCartText title={name} className="card-campaign-title">
					{name}
				</StyledCartText>
				{!isRecommendationCampaign && (
					<StyledButton
						className="card-campaign-buttonEdit"
						icon={<IconEdit />}
						onClick={() => history.push(`${ROUTE_PATH.CAMPAIGNS}/${data?.id}`)}
					/>
				)}
			</StyledCardDiv>
			<StyledWrapperImgCampaign>
				<Row gutter={[10, 0]}>
					<Col span={12}>{handleRenderImage(metadataObject?.urlShare)}</Col>
					<Col span={12}>{handleRenderImage(metadataObject?.urlPost)}</Col>
				</Row>
			</StyledWrapperImgCampaign>

			<StyledCardDiv className="card-campaign-description">
				{isTabActive && !isRecommendationCampaign && (
					<StyledWrapperFlexBetween>
						<StyledLink href={widgetLink} target="_blank">
							{widgetLink}
						</StyledLink>
						<StyledIcon onClick={() => onCopy()}>
							<IconCopy />
						</StyledIcon>
					</StyledWrapperFlexBetween>
				)}
			</StyledCardDiv>
			{arrInfo?.map((item) => {
				const { id, total, value, title, isFull, ...other } = item;
				return (
					<StyledSessionInfo key={id}>
						<StyledWrapperTitle style={{ marginBottom: '6px' }}>
							<StyledTitle width="60%">
								<StyledTitle
									display="block"
									title={title}
									maxWidth="calc(100% - 30px)"
									className="overflow_text">
									{title}
								</StyledTitle>
								{isFull && (
									<StyledIcon style={{ marginLeft: '4px' }}>
										<IconCheckActive />
									</StyledIcon>
								)}
							</StyledTitle>
							<StyledTitle
								maxWidth="40%"
								title={`${value}/${total}`}
								fontWeight="700">{`${value}/${total}`}</StyledTitle>
						</StyledWrapperTitle>
						<ProgressLine {...other} />
					</StyledSessionInfo>
				);
			})}
			<StyledWrapperTitle style={{ marginTop: '28px' }}>
				{isRecommendationCampaign ? (
					renderCreatorInvitationButton()
				) : (
					<>
						<Button onClick={() => handleActivate()} type="primary">
							{t('button.activate')}
						</Button>
						<Button onClick={handleViewResult}>{t('button.view_results')}</Button>
					</>
				)}
			</StyledWrapperTitle>
		</StyledRowCard>
	);
};

export default CardCampaign;
