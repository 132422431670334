import SidebarGalleries from '@components/Publish/Gallery/SidebarGalleries';
import ViewGallery from '@components/Publish/Gallery/ViewGallery';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import GalleryManagerContext from '@contexts/Publishing/Galleries';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import {
	FolderGalleryDto,
	FolderResponseDto,
	ParamRequestContentGallery,
	ParamRequestFolderDto,
	SizeIframe,
} from '@models/publishing/galleries';
import {
	updateDetailFolderGalleryEnd,
	createFolderGalleryEnd,
	removeFolderEnd,
	removeFolderRequest,
	fetchFolderGalleryRequest,
	fetchContentGalleryRequest,
	resetFolderGallery,
	assignAlbumToGalleryRequest,
	assignAlbumToGalleryEnd,
	unAssignAlbumToGalleryEnd,
	unAssignAlbumToGalleryRequest,
	removeAllContentGalleryEnd,
	removeAllContentGalleryRequest,
	getListContentCampaignAPI,
	reviewMultiContentsRequest,
	reviewMultiContentsEnd,
	getCustomPropertiesRequest,
	getCustomPropertiesEnd,
} from '@stores/actions';
import { StyledWrapper, StyledWrapperConfirm } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { getContentByType, getSocialS3URL } from '@utils/common';
import { message, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PreviewGalleryModal from '@components/Publish/Gallery/PreviewGalleryModal';
import {
	DEFAULT_SIZE_IFRAME,
	PARAMS_BASIC,
	RANGE_COLUMN,
	TAB_GALLERY,
	TYPE_GET_CONTENT,
	TYPE_THEME,
} from '@constants/publish/gallery';
import { ConfirmModal } from '@cores/Modal';
import { MESSAGE_TYPE, TYPE_ACTIONS } from '@constants/common';
import { destroyGeneralMessage, generalMessage } from '@utils/renderComponent';
import { AlbumRespDto } from '@models/content/albums/albumManager';
import { StyledTitle } from '@styled/Common/CommonStyled';
import _, { isEqual } from 'lodash';
import { FolderGalleryStoreDto } from '@models/publishing/galleries/stores';
import { MEDIA_TYPE } from '@constants/content/contentLibrary';
import { useHistory, useLocation } from 'react-router-dom';

const Galleries = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const history = useHistory();
	const location: any = useLocation();
	const { state } = location;

	const {
		loadingGallery,
		folderGallery,
		error = '',
		statusChangeDetailGallery,
		statusCreateFolder,
		galleryDetail,
		isAutoSave,
		loadingMetric,
		contentGallery,
		statusAssignAlbumGallery,
		statusUnassignAlbumGallery,
		statusRemoveAllContentGallery,
		gallerySelectDetail,
		loadingContentGallery,
	}: FolderGalleryStoreDto = useSelector((state: any) => state.galleryManager);

	const { loadingAlbums } = useSelector((state: any) => state.albumManager);

	const { loadingRemoveFolder, error: errorFolder = '' } = useSelector(
		(state: any) => state.settings.folder,
	);

	// Reducers
	const { fetchingContent, reviewMultiContentsStatus } = useSelector(
		(state: any) => state.content,
	);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [gallerySelect, setGallerySelect] = useState<FolderGalleryDto | null>(null);
	const [listFolderGallery, setListFolderGallery] = useState<Array<FolderGalleryDto>>([]);
	const [listContent, setListContent] = useState<ContentDto[]>([]);
	const [visiblePreview, setVisiblePreview] = useState<boolean>(false);
	const [activeTabGallery, setActiveTabGallery] = useState<string>(TAB_GALLERY.preview);

	const [typeConfirm, setTypeConfirm] = useState<string>('');
	const [typeGetContent, setTypeGetContent] = useState<string>(TYPE_GET_CONTENT.VIEW);
	const [albumSelect, setAlbumSelect] = useState<AlbumRespDto | null>(null);
	const [contentIdSelected, setContentIdSelected] = useState<number | null>(null);

	const [paramFolder, setParamFolder] = useState<ParamRequestFolderDto>({
		...PARAMS_BASIC,
	});

	const [paramContent, setParamContent] = useState<ParamRequestContentGallery>({
		isVerified: true,
		galleryId: null,
	});

	const formatListContent = (list: ContentContainerDto[]) => {
		return list.map((data: any, index: number) => {
			const contentTemp: ContentDto = getContentByType(data);

			const thumbnail: string = getSocialS3URL(contentTemp.thumbnail);
			return {
				...contentTemp,
				thumbnail,
			};
		});
	};

	const onRemoveGallery = () => {
		setTypeConfirm('');
		handleChangeGallerySelect(null);
		dispatch(removeFolderRequest([gallerySelect?.id]));
	};

	const handleChangeGallerySelect = (val: FolderGalleryDto | null) => {
		if (!val || !gallerySelect) {
			setGallerySelect(val);
		} else {
			if (!isEqual(val, gallerySelect)) {
				setGallerySelect(val);
			}
		}
	};

	const handleResetFolderList = () => {
		const elm: any = document.getElementsByClassName('collapse_gallery');
		if (elm[0] && elm[0].scrollIntoView) {
			elm[0].scrollIntoView({ behavior: 'smooth', top: 0 });
		}
		handleChangeGallerySelect(null);

		setParamFolder((prev) => ({ ...prev, page: 1 }));
	};

	const handleChangeGalleryAfterAssign = () => {
		const newList = listFolderGallery?.map((item) => {
			if (item?.id === gallerySelectDetail?.id) {
				return gallerySelectDetail;
			}
			return item;
		});
		setListFolderGallery([...newList]);
		setParamContent((prev: ParamRequestContentGallery) => ({ ...prev }));
	};

	const onAssignAlbumToGallery = (albumId?: number) => {
		setTypeConfirm('');
		dispatch(
			assignAlbumToGalleryRequest({
				albumId: albumId !== undefined ? albumId : albumSelect?.id,
				galleryId: gallerySelect?.id,
			}),
		);
	};

	const onUnAssignAlbum = () => {
		setTypeConfirm('');
		dispatch(unAssignAlbumToGalleryRequest(gallerySelect?.id));
	};

	const onRemoveAllContent = () => {
		setTypeConfirm('');
		dispatch(removeAllContentGalleryRequest(gallerySelect?.id));
	};

	const onRemoveContentGallery = () => {
		setIsLoading(true);
		setTypeConfirm('');

		const reviewSucceeded = () => {
			setTimeout(() => {
				setIsLoading(false);
				setListContent(listContent.filter((item) => item?.id !== contentIdSelected));
				message.success(t('gallery_manager.message.un_assign_content_success'));
				dispatch(reviewMultiContentsEnd());
			}, 0);
		};

		dispatch(
			reviewMultiContentsRequest({
				params: {
					galleries: [gallerySelect?.id.toString()],
					isRemove: true,
					contentIds: [contentIdSelected],
				},
				reviewSucceeded,
			}),
		);
	};

	useEffect(() => {
		dispatch(getListContentCampaignAPI());
		dispatch(getCustomPropertiesRequest());

		return () => {
			handleChangeGallerySelect(null);
			dispatch(resetFolderGallery());
			setListFolderGallery([]);
			destroyGeneralMessage();
			dispatch(getCustomPropertiesEnd());
		};
	}, []);

	useEffect(() => {
		const otherParam: any = {};
		if (state?.priorityId) {
			otherParam.priorityId = state?.priorityId;
			history.replace({ state: null });
		}
		dispatch(fetchFolderGalleryRequest({ ...paramFolder, ...otherParam }));
	}, [paramFolder]);

	useEffect(() => {
		if (paramContent?.galleryId) {
			setListContent([]);
			dispatch(fetchContentGalleryRequest(paramContent));
		}
	}, [paramContent]);

	useEffect(() => {
		if (contentGallery) {
			const newContentFormat = formatListContent(contentGallery);
			setListContent(newContentFormat);
		}
	}, [contentGallery]);

	useEffect(() => {
		if (statusChangeDetailGallery === IN_PROGRESS && !isAutoSave) {
			setIsLoading(true);
		}
		if (statusChangeDetailGallery === SUCCEEDED) {
			if (galleryDetail) {
				let metadataObj: any = {};
				if (galleryDetail?.metadata) {
					metadataObj = JSON.parse(galleryDetail?.metadata);
				}

				if (!isAutoSave) {
					message.success(t('gallery_manager.message.change_saved'));
				}

				const listFolderUpdate = listFolderGallery?.map((item: FolderGalleryDto) => {
					if (item?.id === galleryDetail?.id) {
						if (galleryDetail?.id === gallerySelect?.id) {
							handleChangeGallerySelect({ ...galleryDetail, metadata: metadataObj });
						}

						return { ...galleryDetail, metadata: metadataObj };
					}
					return { ...item };
				});
				setListFolderGallery(listFolderUpdate);
			}
		}

		if (statusChangeDetailGallery === FAILED) {
			generalMessage(error || '', MESSAGE_TYPE.ERROR);
		}

		if (statusChangeDetailGallery === SUCCEEDED || statusChangeDetailGallery === FAILED) {
			dispatch(updateDetailFolderGalleryEnd());
			setIsLoading(false);
		}
	}, [statusChangeDetailGallery]);

	useEffect(() => {
		if (statusCreateFolder === SUCCEEDED) {
			message.success(t('gallery_manager.message.create_successfully'));
			handleResetFolderList();
		}

		if (statusCreateFolder === SUCCEEDED || statusCreateFolder === FAILED) {
			dispatch(createFolderGalleryEnd());
		}
	}, [statusCreateFolder]);

	useEffect(() => {
		if (statusAssignAlbumGallery === SUCCEEDED) {
			message.success(t('gallery_manager.message.assign_success'));
			handleChangeGalleryAfterAssign();
		}

		if (statusAssignAlbumGallery === SUCCEEDED || statusAssignAlbumGallery === FAILED) {
			dispatch(assignAlbumToGalleryEnd());
		}
	}, [statusAssignAlbumGallery]);

	useEffect(() => {
		if (statusUnassignAlbumGallery === SUCCEEDED) {
			message.success(t('gallery_manager.message.un_assign_success'));
			handleChangeGalleryAfterAssign();
		}

		if (statusUnassignAlbumGallery === SUCCEEDED || statusUnassignAlbumGallery === FAILED) {
			dispatch(unAssignAlbumToGalleryEnd());
		}
	}, [statusUnassignAlbumGallery]);

	useEffect(() => {
		if (statusRemoveAllContentGallery === SUCCEEDED) {
			message.success(t('gallery_manager.message.remove_all_content_success'));
			handleChangeGalleryAfterAssign();
		}

		if (
			statusRemoveAllContentGallery === SUCCEEDED ||
			statusRemoveAllContentGallery === FAILED
		) {
			dispatch(removeAllContentGalleryEnd());
		}
	}, [statusRemoveAllContentGallery]);

	useEffect(() => {
		const listStatus = [
			statusCreateFolder,
			statusAssignAlbumGallery,
			statusUnassignAlbumGallery,
			statusRemoveAllContentGallery,
		];

		if (listStatus?.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus?.includes(FAILED)) {
			generalMessage(error || '', MESSAGE_TYPE.ERROR);
		}
		if (listStatus?.includes(SUCCEEDED) || listStatus?.includes(FAILED)) {
			setIsLoading(false);
		}
	}, [
		statusCreateFolder,
		statusAssignAlbumGallery,
		statusUnassignAlbumGallery,
		statusRemoveAllContentGallery,
	]);

	useEffect(() => {
		const listFolderFormat: Array<FolderGalleryDto> = folderGallery?.map(
			(item: FolderResponseDto) => {
				const { metadata } = item;
				let metadataObj = {};
				if (metadata) {
					metadataObj = JSON.parse(metadata);
				}
				return { ...item, metadata: metadataObj };
			},
		);

		if (listFolderFormat?.length > 0 && !gallerySelect) {
			handleChangeGallerySelect(listFolderFormat[0]);
		}

		if (paramFolder?.page === 1) {
			setListFolderGallery([...listFolderFormat]);
		} else {
			setListFolderGallery([...listFolderGallery, ...listFolderFormat]);
		}
	}, [folderGallery]);

	useEffect(() => {
		if (listFolderGallery?.length > 0 && gallerySelect) {
			const itemGallery = listFolderGallery?.find((item) => item?.id === gallerySelect?.id);
			if (itemGallery && !_.isEqual(itemGallery, gallerySelect)) {
				handleChangeGallerySelect(itemGallery);
			}
		}
	}, [listFolderGallery]);

	useEffect(() => {
		if (loadingRemoveFolder === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (loadingRemoveFolder === FAILED) {
			generalMessage(errorFolder, MESSAGE_TYPE.ERROR);
		}

		if (loadingRemoveFolder === SUCCEEDED) {
			message.success(t('gallery_manager.message.delete_success'));
			handleResetFolderList();
		}

		if (loadingRemoveFolder === SUCCEEDED || loadingRemoveFolder === FAILED) {
			dispatch(removeFolderEnd());
			setIsLoading(false);
			handleChangeGallerySelect(null);
		}
	}, [loadingRemoveFolder]);

	useEffect(() => {
		if (
			isLoading &&
			(reviewMultiContentsStatus === SUCCEEDED || reviewMultiContentsStatus === FAILED)
		) {
			dispatch(reviewMultiContentsEnd());
			setIsLoading(false);
		}
	}, [reviewMultiContentsStatus]);

	const otherPropConfirm = useMemo(() => {
		const result: any = {};
		if (typeConfirm === TYPE_ACTIONS.REMOVE) {
			result.okText = t('gallery_manager.button.delete');
			result.onSubmit = onRemoveGallery;
		}
		if (typeConfirm === TYPE_ACTIONS.ASSIGN) {
			result.okText = t('gallery_manager.button.replace_exist_content');
			result.onSubmit = onAssignAlbumToGallery;
		}
		if (typeConfirm === TYPE_ACTIONS.UN_ASSIGN) {
			result.onSubmit = onUnAssignAlbum;
		}
		if (typeConfirm === TYPE_ACTIONS.REMOVE_ALL) {
			result.onSubmit = onRemoveAllContent;
			result.okText = t('gallery_manager.remove_all_content');
		}
		if (typeConfirm === TYPE_ACTIONS.REMOVE_SINGLE_CONTENT) {
			result.onSubmit = onRemoveContentGallery;
		}
		return result;
	}, [typeConfirm]);

	const renderContentPopupConfirm = () => {
		let content: React.ReactNode = '';
		if (typeConfirm === TYPE_ACTIONS.REMOVE) {
			content = (
				<>
					{t('gallery_manager.message.are_you_remove', { name: gallerySelect?.name })}
					<br />
					{t('gallery_manager.message.sub_remove')}
				</>
			);
		}
		if (typeConfirm === TYPE_ACTIONS.ASSIGN) {
			content = (
				<>
					<StyledTitle fontSize="24px">
						{t('gallery_manager.replace_content')}
					</StyledTitle>
					<br />
					{t('gallery_manager.message.are_you_assign_album', { name: albumSelect?.name })}
				</>
			);
		}
		if (typeConfirm === TYPE_ACTIONS.REMOVE_ALL) {
			content = (
				<StyledWrapperConfirm>
					<StyledTitle fontSize="24px">
						{t('gallery_manager.remove_all_content')}
					</StyledTitle>
					<br />
					{t('gallery_manager.message.sub_remove_all_content')}
				</StyledWrapperConfirm>
			);
		}
		if (typeConfirm === TYPE_ACTIONS.UN_ASSIGN) {
			content = <>{t('gallery_manager.message.un_assign_album')}</>;
		}
		if (typeConfirm === TYPE_ACTIONS.REMOVE_SINGLE_CONTENT) {
			content = <>{t('gallery_manager.message.un_assign_content_gallery')}</>;
		}
		return content;
	};

	const valueContext = {
		gallerySelect,
		handleChangeGallerySelect,
		listFolderGallery,
		setListFolderGallery,
		listContent,
		setParamContent,
		paramContent,
		visiblePreview,
		setVisiblePreview,
		setActiveTabGallery,
		activeTabGallery,
		setTypeConfirm,
		typeGetContent,
		setTypeGetContent,
		paramFolder,
		setParamFolder,
		setListContent,
		setAlbumSelect,
		albumSelect,
		onAssignAlbumToGallery,
		setContentIdSelected,
	};

	return (
		<GalleryManagerContext.Provider value={valueContext}>
			<LoadingWrapper
				isLoading={
					isLoading ||
					loadingGallery ||
					loadingAlbums ||
					loadingContentGallery ||
					(typeGetContent === TYPE_GET_CONTENT.VIEW && fetchingContent)
				}>
				<Row gutter={[16, 16]}>
					<StyledWrapper span={16}>
						<ViewGallery />
					</StyledWrapper>
					<StyledWrapper span={8}>
						<SidebarGalleries />
					</StyledWrapper>
				</Row>
			</LoadingWrapper>
			{gallerySelect && visiblePreview && (
				<PreviewGalleryModal
					visible={visiblePreview}
					centered={true}
					onCancel={() => setVisiblePreview(false)}
					contentList={listContent}
					detailGallery={gallerySelect}
					setListContent={setListContent}
				/>
			)}
			<ConfirmModal
				width={450}
				isOpen={typeConfirm ? true : false}
				onClose={() => setTypeConfirm('')}
				cancelText={t('gallery_manager.button.cancel')}
				{...otherPropConfirm}>
				{renderContentPopupConfirm()}
			</ConfirmModal>
		</GalleryManagerContext.Provider>
	);
};

export default Galleries;
