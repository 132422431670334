import { createAction } from 'redux-actions';
import {
	socialSearchTypes,
	contentSettingsTypes,
	SET_CONTENT_VALUES,
	publishSettingsTypes,
	accountUserTypes,
	storeDataTypes,
	removeFolderTypes,
	replaceBlockUsernameType,
	replaceBlockTermsType,
} from './settings.types';

// Update state
export const setContentValues = createAction(SET_CONTENT_VALUES);

// *****************************************************************
// ******************** SETTING - SOCIAL SEARCH ********************
// *****************************************************************
// Get hashtag list
export const fetchHashTagRequest = createAction(socialSearchTypes.GET_HASHTAG);
export const fetchHashTagSucceeded = createAction(socialSearchTypes.GET_HASHTAG_SUCCEEDED);
export const fetchHashTagFailed = createAction(socialSearchTypes.GET_HASHTAG_FAILED);

// Get inActive username list
export const fetchInActiveUsernameRequest = createAction(socialSearchTypes.FETCH_INACTIVE_USERNAME);
export const fetchInActiveUsernameSucceeded = createAction(
	socialSearchTypes.FETCH_INACTIVE_USERNAME_SUCCEEDED,
);
export const fetchInActiveUsernameFailed = createAction(
	socialSearchTypes.FETCH_INACTIVE_USERNAME_FAILED,
);
// Update inactive username
export const updateInActiveUsernameRequest = createAction(
	socialSearchTypes.UPDATE_INACTIVE_USERNAME,
);
// Modify sub hashtag list
export const updateSubHashTagRequest = createAction(socialSearchTypes.UPDATE_SUB_HASHTAG);

// Get social usernames list
export const fetchSocialUsername = createAction(socialSearchTypes.GET_SOCIAL_USERNAME);
export const modifySocialUsernameRequest = createAction(socialSearchTypes.MODIFY_SOCIAL_USERNAME);
export const fetchSocialUsernameSucceeded = createAction(
	socialSearchTypes.GET_SOCIAL_USERNAME_SUCCEEDED,
);
export const fetchSocialUsernameFailed = createAction(socialSearchTypes.GET_SOCIAL_USERNAME_FAILED);

// Get blocked social usernames list
export const fetchSocialBlockedUsername = createAction(socialSearchTypes.GET_SOCIAL_BLOCK_USERNAME);
export const modifySocialBlockedUsernameRequest = createAction(
	socialSearchTypes.MODIFY_SOCIAL_BLOCK_USERNAME,
);
export const fetchSocialBlockedUsernameSucceeded = createAction(
	socialSearchTypes.GET_SOCIAL_BLOCK_USERNAME_SUCCEEDED,
);
export const fetchSocialBlockedUsernameFailed = createAction(
	socialSearchTypes.GET_SOCIAL_BLOCK_USERNAME_FAILED,
);

// Get bad words list
export const fetchBadWords = createAction(socialSearchTypes.GET_BAD_WORDS);
export const modifyBadWords = createAction(socialSearchTypes.MODIFY_BAD_WORDS);
export const fetchBadWordsSucceeded = createAction(socialSearchTypes.GET_BAD_WORDS_SUCCEEDED);
export const fetchBadWordsFailed = createAction(socialSearchTypes.GET_BAD_WORDS_FAILED);

// get list feed
export const fetchListFeedRequest = createAction(socialSearchTypes.GET_LIST_FEED_REQUEST);
export const fetchListFeedSucceeded = createAction(socialSearchTypes.GET_LIST_FEED_SUCCEEDED);
export const fetchListFeedFailed = createAction(socialSearchTypes.GET_LIST_FEED_FAILED);

// create feed
export const createFeedRequest = createAction(socialSearchTypes.CREATE_FEED_REQUEST);
export const createFeedSucceeded = createAction(socialSearchTypes.CREATE_FEED_SUCCEEDED);
export const createFeedFailed = createAction(socialSearchTypes.CREATE_FEED_FAILED);
export const createFeedEnded = createAction(socialSearchTypes.CREATE_FEED_ENDED);

// update feed
export const updateFeedRequest = createAction(socialSearchTypes.UPDATE_FEED_REQUEST);
export const updateFeedSucceeded = createAction(socialSearchTypes.UPDATE_FEED_SUCCEEDED);
export const updateFeedFailed = createAction(socialSearchTypes.UPDATE_FEED_FAILED);
export const updateFeedEnded = createAction(socialSearchTypes.UPDATE_FEED_ENDED);

// import username social feed
export const importUsernameFeedRequest = createAction(
	socialSearchTypes.IMPORT_USERNAME_FEED_REQUEST,
);
export const importUsernameFeedSucceeded = createAction(
	socialSearchTypes.IMPORT_USERNAME_FEED_SUCCEEDED,
);
export const importUsernameFeedFailed = createAction(socialSearchTypes.IMPORT_USERNAME_FEED_FAILED);
export const importUsernameFeedEnded = createAction(socialSearchTypes.IMPORT_USERNAME_FEED_ENDED);

// get stats setting feed
export const fetchStatsSettingFeedRequest = createAction(
	socialSearchTypes.FETCH_STATS_SETTING_FEED_REQUEST,
);
export const fetchStatsSettingFeedSucceeded = createAction(
	socialSearchTypes.FETCH_STATS_SETTING_FEED_SUCCEEDED,
);
export const fetchStatsSettingFeedFailed = createAction(
	socialSearchTypes.FETCH_STATS_SETTING_FEED_FAILED,
);

// reset setting
export const resetSocialSearchSetting = createAction(socialSearchTypes.RESET_SOCIAL_SEARCH_SETTING);

// *****************************************************************
// *********************** SETTING - CONTENT ***********************
// *****************************************************************
// Get content setting list
export const getContentSettings = createAction(contentSettingsTypes.GET_CONTENT_SETTINGS);
export const getContentSettingsSucceeded = createAction(
	contentSettingsTypes.GET_CONTENT_SETTINGS_SUCCEEDED,
);
export const getContentSettingsFailed = createAction(
	contentSettingsTypes.GET_CONTENT_SETTINGS_FAILED,
);

// Update content values
export const updateContentValues = createAction(contentSettingsTypes.UPDATE_CONTENT_VALUES);
export const updateContentValuesSucceeded = createAction(
	contentSettingsTypes.UPDATE_CONTENT_VALUES_SUCCEEDED,
);
export const updateContentValuesFailed = createAction(
	contentSettingsTypes.UPDATE_CONTENT_VALUES_FAILED,
);

// Update flags
export const updateFlags = createAction(contentSettingsTypes.UPDATE_FLAGS);
export const updateFlagsSucceeded = createAction(contentSettingsTypes.UPDATE_FLAGS_SUCCEEDED);
export const updateFlagsFailed = createAction(contentSettingsTypes.UPDATE_FLAGS_FAILED);
export const updateFlagsEnd = createAction(contentSettingsTypes.UPDATE_FLAGS_END);

// Update labels
export const updateLabels = createAction(contentSettingsTypes.UPDATE_LABELS);
export const updateLabelsSucceeded = createAction(contentSettingsTypes.UPDATE_LABELS_SUCCEEDED);
export const updateLabelsFailed = createAction(contentSettingsTypes.UPDATE_LABELS_FAILED);
export const updateLabelsEnd = createAction(contentSettingsTypes.UPDATE_LABELS_END);

// Create folder
export const createFolder = createAction(contentSettingsTypes.CREATE_FOLDER);
export const createFolderSucceeded = createAction(contentSettingsTypes.CREATE_FOLDER_SUCCEEDED);
export const createFolderFailed = createAction(contentSettingsTypes.CREATE_FOLDER_FAILED);

// Delete folder
export const deleteFolder = createAction(contentSettingsTypes.DELETE_FOLDER);
export const deleteFolderSucceeded = createAction(contentSettingsTypes.DELETE_FOLDER_SUCCEEDED);
export const deleteFolderFailed = createAction(contentSettingsTypes.DELETE_FOLDER_FAILED);

// Edit folder
export const updateFolder = createAction(contentSettingsTypes.UPDATE_FOLDER);
export const updateFolderSucceeded = createAction(contentSettingsTypes.UPDATE_FOLDER_SUCCEEDED);
export const updateFolderFailed = createAction(contentSettingsTypes.UPDATE_FOLDER_FAILED);
// *****************************************************************
// *********************** SETTING - PUBLISH ***********************
// *****************************************************************
// Get channels list
export const fetchChannelsRequest = createAction(publishSettingsTypes.GET_CHANNELS_SETTINGS);
export const fetchChannelsSucceeded = createAction(
	publishSettingsTypes.GET_CHANNELS_SETTINGS_SUCCEEDED,
);
export const fetchChannelsFailed = createAction(publishSettingsTypes.GET_CHANNELS_SETTINGS_FAILED);
export const modifyChannel = createAction(publishSettingsTypes.MODIFY_CHANNELS_SETTINGS);

// Get calendars list
export const fetchCalendarRequest = createAction(publishSettingsTypes.GET_CALENDAR_SETTINGS);
export const fetchCalendarSucceeded = createAction(
	publishSettingsTypes.GET_CALENDAR_SETTINGS_SUCCEEDED,
);
export const fetchCalendarFailed = createAction(publishSettingsTypes.GET_CALENDAR_SETTINGS_FAILED);
export const modifyCalendar = createAction(publishSettingsTypes.MODIFY_CALENDAR_SETTINGS);

export const getFolderSettings = createAction(contentSettingsTypes.GET_FOLDER_SETTINGS);
export const getFolderSettingsSucceeded = createAction(
	contentSettingsTypes.GET_FOLDER_SETTINGS_SUCCEEDED,
);
export const getFolderSettingsFailed = createAction(
	contentSettingsTypes.GET_FOLDER_SETTINGS_FAILED,
);

// Get account users
export const getAccountList = createAction(accountUserTypes.GET_ACCOUNT_LIST);
export const getAccountListSucceeded = createAction(accountUserTypes.GET_ACCOUNT_LIST_SUCCEEDED);
export const getAccountListFailed = createAction(accountUserTypes.GET_ACCOUNT_LIST_FAILED);
export const getAccountListEnd = createAction(accountUserTypes.GET_ACCOUNT_LIST_END);

// Delete account user
export const deleteAccountUser = createAction(accountUserTypes.DELETE_ACCOUNT_USER);
export const deleteAccountUserSucceeded = createAction(
	accountUserTypes.DELETE_ACCOUNT_USER_SUCCEEDED,
);
export const deleteAccountUserFailed = createAction(accountUserTypes.DELETE_ACCOUNT_USER_FAILED);
export const deleteAccountUserEnd = createAction(accountUserTypes.DELETE_ACCOUNT_USER_END);

// Update account user
export const updateAccountUser = createAction(accountUserTypes.UPDATE_ACCOUNT_USER);
export const updateAccountUserSucceeded = createAction(
	accountUserTypes.UPDATE_ACCOUNT_USER_SUCCEEDED,
);
export const updateAccountUserFailed = createAction(accountUserTypes.UPDATE_ACCOUNT_USER_FAILED);
export const updateAccountUserEnd = createAction(accountUserTypes.UPDATE_ACCOUNT_USER_END);

// Invite user
export const inviteUser = createAction(accountUserTypes.INVITE_USER);
export const inviteSucceeded = createAction(accountUserTypes.INVITE_USER_SUCCEEDED);
export const inviteUserFailed = createAction(accountUserTypes.INVITE_USER_FAILED);
export const inviteUserEnd = createAction(accountUserTypes.INVITE_USER_END);

// Store data
export const storeData = createAction(storeDataTypes.STORE_DATA);

// Action function remove folder
export const removeFolderRequest = createAction(removeFolderTypes.REMOVE_FOLDER_REQUEST);
export const removeFolderSucceeded = createAction(removeFolderTypes.REMOVE_FOLDER_SUCCEEDED);
export const removeFolderFailed = createAction(removeFolderTypes.REMOVE_FOLDER_FAILED);
export const removeFolderEnd = createAction(removeFolderTypes.REMOVE_FOLDER_END);

// Action function replace block username
export const replaceBlockUsernameRequest = createAction(
	replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_REQUEST,
);
export const replaceBlockUsernameSucceeded = createAction(
	replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_SUCCEEDED,
);
export const replaceBlockUsernameFailed = createAction(
	replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_FAILED,
);
export const replaceBlockUsernameEnd = createAction(
	replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_END,
);

// Action function replace block terms
export const replaceBlockTermsRequest = createAction(
	replaceBlockTermsType.REPLACE_BLOCK_TERMS_REQUEST,
);
export const replaceBlockTermsSucceeded = createAction(
	replaceBlockTermsType.REPLACE_BLOCK_TERMS_SUCCEEDED,
);
export const replaceBlockTermsFailed = createAction(
	replaceBlockTermsType.REPLACE_BLOCK_TERMS_FAILED,
);
export const replaceBlockTermsEnd = createAction(replaceBlockTermsType.REPLACE_BLOCK_TERMS_END);
