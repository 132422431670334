import { PARAMS_DEFAULT } from '@constants/common';
import { VIEW_TYPE } from '@constants/content/contentLibrary';
import { ImgVersionDto } from '@models/content/contentLibrary/store';
import {
	ContentDto,
	ContentVerificationScoreDto,
	ParamPublishLogDto,
	PublishLogDto,
	TypeFolderContent,
} from '@models/content/contentLibrary/summary';
import * as React from 'react';

export type ContentDetail = {
	paramPublish: ParamPublishLogDto;
	setParamPublish: (val: any) => void;
	publishLogs: PublishLogDto[];
	setPublishLogs: (val: PublishLogDto[]) => void;
	isLoading: boolean;
	setIsLoading: (val: boolean) => void;
	isDisableAction: boolean;
	typeContent: TypeFolderContent;
	optionFlags: any[];
	setCollapseTerm: (val: boolean) => void;
	collapseTerm: boolean;
	currentMedia: ContentDto | null;
	setTypeAction: (val: string) => void;
	typeAction: string;
	versionImgSelected: ImgVersionDto | null;
	handleChangeVersion: (val: ImgVersionDto, idChange?: number | null) => void;
	setShowDetectedLogo: (visible: boolean) => void;
	showDetectedLogo: boolean;
	CDMBlockCreatorDisabled?: boolean;
	setTypeConfirm: (val: string) => void;
	onClose: () => void;
	typeConfirm: string;
	handleChangeAfterAction: (action: string, contentsAfterAction?: number[]) => void;
	setIdContentUpdate: (val: number | undefined) => void;
	idContentUpdate: number | undefined;
	setVersionImgSelected: (val: ImgVersionDto | null) => void;
	hideMessage: boolean;
	setHideMessage: (val: boolean) => void;
	updateAfterChangeVersion?: (contentId: number, version: ImgVersionDto) => void;
	isClose: boolean;
	handleCancel: () => void;
	setIsClose: (val: boolean) => void;
	isBlockDelete: boolean;
	setIsBlockDelete: (val: boolean) => void;
	onChangeAfterBlock?: (currentMedia: ContentDto, isDelete: boolean, isBlock: boolean) => void;
	updateVerificationScrore?: (
		content: ContentDto,
		verificationScore: ContentVerificationScoreDto,
	) => void;
	fieldSelect: string;
	funcSelectFlagField: (hasTimeout?: boolean) => void;
	funcSelectField: (id: string, fieldName: string) => void;
	isDashboardAmbassador?: boolean;
	setField: (val: any) => void;
	handleClearActionChangeCustomField: (val?: boolean) => void;
	handleRotateImage: () => void;
	enableRotateImage?: boolean;
	handleFetchTerms: () => void;
	disableActionPage: boolean;
	setCurrentMedia: (val: ContentDto | null) => void;
	handleActionEditImg: () => void;
	otherLoading: boolean;
	setOtherLoading: (val: boolean) => void;
	isRotateRequest: boolean;
	setIsRotateRequest: (val: boolean) => void;
	isAdditionalLoading: boolean;
	setIsAdditionalLoading: (val: boolean) => void;
	isSocialContent: boolean;
};

// Default context values
const values: ContentDetail = {
	paramPublish: {
		contentId: null,
		page: 1,
		maxRecord: PARAMS_DEFAULT.maxRecords,
	},
	setParamPublish: () => {},
	publishLogs: [],
	setPublishLogs: () => {},
	isLoading: false,
	setIsLoading: () => {},
	isDisableAction: false,
	typeContent: {
		isArchive: false,
		isBookmark: false,
		isSubmission: false,
	},
	optionFlags: [],
	setCollapseTerm: () => {},
	collapseTerm: false,
	currentMedia: null,
	setTypeAction: () => {},
	typeAction: '',
	versionImgSelected: null,
	handleChangeVersion: () => {},
	setShowDetectedLogo: () => {},
	showDetectedLogo: false,
	CDMBlockCreatorDisabled: false,
	setTypeConfirm: () => {},
	onClose: () => {},
	typeConfirm: '',
	handleChangeAfterAction: () => {},
	setIdContentUpdate: () => {},
	idContentUpdate: undefined,
	updateAfterChangeVersion: () => {},
	setVersionImgSelected: () => {},
	hideMessage: false,
	setHideMessage: () => {},
	isClose: false,
	handleCancel: () => {},
	setIsClose: () => {},
	isBlockDelete: false,
	setIsBlockDelete: () => {},
	onChangeAfterBlock: () => {},
	updateVerificationScrore: () => {},
	fieldSelect: '',
	funcSelectField: () => {},
	funcSelectFlagField: () => {},
	isDashboardAmbassador: false,
	setField: () => {},
	handleClearActionChangeCustomField: () => {},
	handleRotateImage: () => {},
	enableRotateImage: false,
	handleFetchTerms: () => {},
	disableActionPage: false,
	setCurrentMedia: () => {},
	handleActionEditImg: () => {},
	otherLoading: false,
	setOtherLoading: () => {},
	isRotateRequest: false,
	setIsRotateRequest: () => {},
	isAdditionalLoading: false,
	setIsAdditionalLoading: () => {},
	isSocialContent: false,
};

const ContentDetailContext = React.createContext(values);
export default ContentDetailContext;
