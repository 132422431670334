import styled from 'styled-components';
import THEME from '@constants/themes/themes';
import { Cascader, Space, Button, Modal } from 'antd';

const StyledModal = styled(Modal)`
	.ant-form {
		width: 514px;

		.file-name.ant-form-item {
			margin-bottom: 16px;
		}

		.preview-file-name {
			color: ${THEME.colors.orangeBase};
			white-space: nowrap;
		}

		.default-file-name {
			overflow: hidden;
			white-space: pre;
			text-overflow: ellipsis;
			height: 30px;
			padding: 3px 11px;
			border: 1px solid #344552;
			border-radius: 2px;

			&.tooltip {
				cursor: pointer;
			}
		}

		.details-file-name {
			.ant-cascader-menu {
				width: 255px;

				.ant-cascader-menu-item-disabled {
					font-weight: 500;
					color: ${THEME.colors.gray5};
					cursor: default;
					opacity: 0.6;
				}
			}
		}
	}

	.google-drive-btn {
		height: 32px;
		margin: 0 8px;

		> div {
			padding: 0 !important;
			display: flex !important;
			margin-left: 8px !important;
		}
	}
`;

const StyledSpace = styled(Space)`
	display: block;
	position: relative;

	.ant-space-item {
		.remove-icon-container {
			display: inline;
			position: absolute;
			right: -30px;
			top: 60%;
			transform: translateY(-50%);

			&.first {
				top: 80%;
			}
		}
	}
`;

const StyledCascader = styled((props) => <Cascader {...props} />)`
	input {
		height: 34px;
	}
`;

const StyledPreviewFileName = styled.div`
	overflow: auto;
	padding: 6px;
	margin: 12px 0 18px 0;
	max-height: 110px;

	p:last-child {
		margin-bottom: 0;
	}

	&::-webkit-scrollbar {
		height: 6px;
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: ${THEME.colors.darkBlue3};
	}

	&::-webkit-scrollbar-thumb {
		border: none;
	}
`;

const StyledButtonText = styled(({ isDisabled, ...props }) => <Button {...props} />)`
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};
	opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
`;

const StyledErrorMsg = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: ${THEME.colors.redBase};
	font-size: 0.857rem;
	margin-top: 10px;

	p {
		margin: 0 8px;
	}
`;

export {
	StyledModal,
	StyledSpace,
	StyledCascader,
	StyledPreviewFileName,
	StyledButtonText,
	StyledErrorMsg,
};
