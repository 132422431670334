import { Pagination, Popconfirm, Tabs, Tooltip, message } from 'antd';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Components
import EmailList from '@components/Messages/MessageLog/Logs/EmailList';
import EventList from '@components/Messages/MessageLog/Logs/EventList';

// Styled
import {
	StyledButton,
	StyledPagination,
	StyledSection,
	StyledTabs,
	StyledWrapperFilter,
} from '@styled/Messages/MessageLogStyled';

// Context
import MessageLogContext from '@contexts/Messages/Logs';

// Icons
import { IconDelete, IconEdit, IconFilter } from '@assets/icons';

// Constant
import { CREATOR_API_URL } from '@constants/APIs';
import { ROUTE_PATH } from '@constants/common';
import { DEFAULT_ERROR } from '@constants/errors';
import { EMAIL_FILTER_TYPE, SORT_BY_EMAIL, STATE_TYPE, TAB } from '@constants/messages';
import { IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import SearchBar from '@cores/SearchBar';
import { getRequest } from '@helpers/requestHelpers';
import { EventDto } from '@models/messages/log';
import {
	deleteEmail,
	getEmailEvent,
	sendEmailRewardEnd,
	storeMessageTemplateData,
} from '@stores/actions';
import EmailLogFilter from './EmailLogFilter';
import LoadingWrapper from '@cores/LoadingWrapper';
import { FAILED } from '@constants/creator';
import { Paginator } from '@constants/paginator';

type ChooseTemplateProps = {};

type TabPaneType = {
	tabId: string;
	name: string;
	component: any;
};

const StyledWrapper = styled.div`
	position: relative;
	background-color: ${THEME.colors.darkBlue2};
	min-height: calc(100vh - 82px);
	height: max-content;
`;

const StyledPopconfirm = styled(Popconfirm)`
	button {
		width: 30px !important;
	}
`;

const StyledToolTip = styled(Tooltip)`
	button {
		width: 30px !important;
	}
`;

const { TabPane } = Tabs;

type TypeDefaultGetMail = {
	page: number;
	pageRecords?: number;
	type: string;
};
interface GetEventEmailPayloadDto extends TypeDefaultGetMail {
	searchDate?: string;
	search: string;
}

type TypeFilterEmail = {
	sortBy: string;
	filterBy: string[];
	creatorEmail: string[];
	rangeDate: any;
};

interface DataRequestFilterDto extends TypeDefaultGetMail {
	sortBy: string;
	fromDate?: number;
	toDate?: number;
	isAutomated?: boolean;
	isFailed?: boolean;
}

const listValueFilterBy = [
	EMAIL_FILTER_TYPE.FAILED.value,
	EMAIL_FILTER_TYPE.AUTOMATED.value,
	EMAIL_FILTER_TYPE.MANUALLY_SENT.value,
];

const Logs = (props: ChooseTemplateProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const { state } = useLocation<any>();

	const refSearch = useRef<any>(null);
	const refFilter = useRef<any>({});

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [visibleFilter, setVisibleFilter] = useState<boolean>(false);

	const {
		onChangeIsSelectSentTab,
		eventEmailPayload,
		onChangeEventEmailPayload,
		selectedEmailList,
		onResetSelectedEmailList,
		onChangeSelectedEmail,
	} = useContext(MessageLogContext);

	const {
		emailLog = {},
		getEmailEventStatus,
		sendEmailStatus,
		error,
	} = useSelector((state: any) => state.messages);
	const { pageRecords = 0, totalRecords = 0, events = [] } = emailLog;

	const [isLoadingEditing, setIsLoadingEditing] = useState<boolean>(false);

	const defaultFilter: TypeFilterEmail = {
		sortBy: SORT_BY_EMAIL.LATEST_FIRST.value,
		filterBy: [],
		creatorEmail: [],
		rangeDate: [],
	};

	const [filters, setFilters] = useState<TypeFilterEmail>(defaultFilter);

	const [paramsEmail, setParamsEmail] = useState<GetEventEmailPayloadDto>(eventEmailPayload);

	const [isSearch, setIsSearch] = useState<boolean>(true);

	const isDefaultFilter = useMemo(
		() => JSON.stringify(defaultFilter) === JSON.stringify(filters),
		[filters],
	);

	useEffect(() => {
		if (sendEmailStatus && sendEmailStatus === SUCCEEDED) {
			message.success(t('email_builder.send_mail_success'));
			onChangeEventEmailPayload({ ...paramsEmail, page: Paginator.defaultPage });
			dispatch(sendEmailRewardEnd());
			dispatch(
				storeMessageTemplateData({
					emailLog: {},
				}),
			);
		}

		if (sendEmailStatus && sendEmailStatus === FAILED) {
			if (error) {
				message.error(error);
			}
			dispatch(sendEmailRewardEnd());
		}
	}, [sendEmailStatus, error]);

	const resetData = () => {
		dispatch(
			storeMessageTemplateData({
				emailLog: {},
			}),
		);
	};

	const changeFolder = (tab: string) => {
		if (!isSearch) {
			setIsSearch(true);
		}
		setFilters(defaultFilter);
		setParamsEmail((prev: any) => ({ ...prev, type: tab }));
		onChangeIsSelectSentTab(tab === TAB.SENT);
		onResetSelectedEmailList();
		resetData();
	};

	const onChangePage = (page: number) => {
		setParamsEmail((prev: any) => ({ ...prev, page }));
		setCurrentPage(page);
	};

	const handlePressEnter = (val: string) => {
		handleSearch(val);
	};

	const handleSearch = (search: string) => {
		if (!isSearch) {
			setIsSearch(true);
		}
		setParamsEmail((prev: any) => ({ ...prev, search }));
		refFilter.current?.onClearForm();

		setFilters(defaultFilter);
	};

	const onDeleteEmail = () => {
		const onDeleteSucceeded = () => {
			message.success(t('email_log.message.delete_succeeded'));
			setParamsEmail({ ...paramsEmail });
			onChangeSelectedEmail({});
		};

		const onDeleteFailed = () => {
			message.error(t('email_log.message.delete_failed'));
		};

		const payload = {
			type: paramsEmail?.type,
			selectedEmailList,
			onDeleteSucceeded,
			onDeleteFailed,
		};
		dispatch(deleteEmail(payload));
	};

	const onEditEmail = async () => {
		const editingEmailId = selectedEmailList[0];

		const emailData = events.find((email: EventDto) => email.id === editingEmailId);

		const { segments } = emailData;

		setIsLoadingEditing((prevState) => !prevState);
		const newSegment = await Promise.all(
			segments
				? segments.map(async (segment: any): Promise<any> => {
						const count = await getTotalCreatorProfiles(segment.segmentId);
						return {
							...segment,
							totalCreatorProfiles: count,
						};
				  })
				: [],
		);
		setIsLoadingEditing((prevState) => !prevState);

		history.push({
			pathname: ROUTE_PATH.EMAIL_BUILDER,
			state: {
				type: STATE_TYPE.EDIT_DRAFT_EMAIL,
				draftEmail: { ...emailData, segments: [...newSegment] },
			},
		});
	};

	const getTotalCreatorProfiles = async (segmentId: number) => {
		const url = `${CREATOR_API_URL}/segment/segment-details?id=${segmentId}`;
		let creatorCount = 0;
		await getRequest(url)
			.then((response: any) => {
				{
					if (response.status === 200) {
						creatorCount = response.body.data.result.totalCreatorProfiles;
					} else {
						message.error(response.body.data.error || DEFAULT_ERROR);
					}
					return creatorCount;
				}
			})
			.catch((err: any) => {
				message.error(DEFAULT_ERROR);
			});
		return creatorCount;
	};

	const handleApplyFilter = (val: any, isClear?: boolean) => {
		setFilters((prev) => ({
			...prev,
			...val,
		}));
		if (isClear) {
			if (refSearch?.current?.setValueNotSearch) {
				refSearch.current.setValueNotSearch('');
			}
			setParamsEmail((prev) => ({
				...prev,
				search: '',
			}));
		}
	};

	const handleFilter = () => {
		const dataRequest: DataRequestFilterDto = {
			page: paramsEmail?.page,
			type: paramsEmail?.type,
			pageRecords: paramsEmail?.pageRecords,
			sortBy: filters?.sortBy,
		};
		if (filters?.rangeDate?.length === 2) {
			dataRequest.fromDate = new Date(filters?.rangeDate[0]).getTime();
			dataRequest.toDate = new Date(filters?.rangeDate[1]).getTime();
		}
		filters?.filterBy?.forEach((item) => {
			if (item === listValueFilterBy[0]) {
				dataRequest.isFailed = true;
			}
			if (item === listValueFilterBy[1]) {
				dataRequest.isAutomated = true;
			}
			if (item === listValueFilterBy[2]) {
				dataRequest.isAutomated = false;
			}
		});

		dispatch(getEmailEvent({ params: dataRequest, creatorEmail: filters?.creatorEmail }));
	};

	const tabPanel: TabPaneType[] = [
		{
			tabId: TAB.SENT,
			name: t('email_log.logs.sent'),
			component: <EventList />,
		},
		{
			tabId: TAB.DRAFT,
			name: t('email_log.logs.drafts'),
			component: <EmailList onEditEmail={onEditEmail} />,
		},
	];

	const renderEvents = () => {
		return tabPanel.map((item: TabPaneType) => (
			<TabPane tab={item.name} key={item.tabId} closable={false}>
				{item.component}
			</TabPane>
		));
	};

	useEffect(() => {
		if (state?.email) {
			if (refSearch?.current?.setValueSearch) {
				refSearch.current.setValueSearch(state?.email);
			}
			history.replace({ state: {} });
		}
	}, [state]);

	useEffect(() => {
		if (isSearch) {
			onResetSelectedEmailList();
			onChangeEventEmailPayload(paramsEmail);
		}
	}, [paramsEmail, filters]);

	useEffect(() => {
		if (!isSearch) {
			handleFilter();
			onResetSelectedEmailList();
		}
	}, [filters, paramsEmail?.page, paramsEmail?.pageRecords, paramsEmail?.type]);

	const isLoading = getEmailEventStatus === IN_PROGRESS || sendEmailStatus === IN_PROGRESS;

	return (
		<LoadingWrapper isLoading={isLoading}>
			<StyledWrapper>
				<StyledSection>
					<StyledWrapperFilter>
						<SearchBar
							width={'100%'}
							placeholder={t('email_log.logs.search')}
							onSearch={handlePressEnter}
							enterButton
							ref={refSearch}
							isSearchLeft
							suffix={
								paramsEmail?.type === TAB.SENT ? (
									<IconFilter
										className={`${
											visibleFilter || !isDefaultFilter ? 'active_icon' : ''
										} icon_filter`}
										onClick={() => setVisibleFilter(!visibleFilter)}
									/>
								) : null
							}
						/>
						<EmailLogFilter
							ref={refFilter}
							visibleFilter={visibleFilter}
							setVisibleFilter={setVisibleFilter}
							setIsSearch={setIsSearch}
							handleApplyFilter={handleApplyFilter}
							defaultFilter={defaultFilter}
						/>
					</StyledWrapperFilter>
					{paramsEmail?.type !== TAB.SENT && (
						<>
							<StyledToolTip placement="top" title={t('button.edit')}>
								<StyledButton
									type="ghost"
									shape="round"
									onClick={() => onEditEmail()}
									loading={isLoadingEditing}
									icon={<IconEdit />}
									disabled={selectedEmailList.length !== 1}
								/>
							</StyledToolTip>
							<Tooltip placement="top" title={t('button.delete')}>
								<StyledPopconfirm
									placement="topLeft"
									title={t('email_log.message.delete_confirm')}
									onConfirm={onDeleteEmail}
									okText={t('button.delete')}
									cancelText={t('button.cancel')}
									disabled={selectedEmailList.length === 0}>
									<StyledButton
										type="ghost"
										shape="round"
										icon={<IconDelete />}
										disabled={selectedEmailList.length === 0}
									/>
								</StyledPopconfirm>
							</Tooltip>
						</>
					)}
				</StyledSection>
				<StyledTabs onTabClick={(tab: string) => changeFolder(tab)}>
					{renderEvents()}
				</StyledTabs>

				<StyledPagination className="card-content">
					<Pagination
						size="small"
						showLessItems
						total={totalRecords}
						pageSize={pageRecords}
						defaultPageSize={pageRecords}
						current={currentPage}
						showSizeChanger={false}
						onChange={onChangePage}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${total || 0} ${t(
								`${total > 1 ? 'pagination.items' : 'pagination.item'}`,
							)}`
						}
					/>
				</StyledPagination>
			</StyledWrapper>
		</LoadingWrapper>
	);
};

export default Logs;
