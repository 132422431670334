import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, message } from 'antd';
import { Modal } from '@cores/Modal';

import { checkTokenReset, getEmailReset, resetPassword, sendEmailResetPwd } from '@stores/actions';

// Import styled components
import { StyledContainer, StyledContainerBtn } from '@styled/LoginStyled';
import { StyledButton, StyledCustomTitle } from '@styled/Common/CommonStyled';

import { StyledFormItem } from '@styled/ChangePasswordStyled';
import { renderErrorMessagePwd } from '@utils/renderComponent';
import { removeAll } from '@helpers/storageHelpers';
import { PATTERNS, ROUTE_PATH } from '@constants/common';
import { STATUSCODE } from '@constants/APIs';

// Models
import { ResponsePayloadDto } from '@models/common/store';
import _ from 'lodash';

const TYPE_TOKEN = {
	VALID: 'Valid token',
	INVALID: 'Invalid',
	EXPIRED: 'Expired',
};

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { t } = useTranslation();
	const history = useHistory();
	const [form] = Form.useForm();

	const [token, setToken] = useState<string>('');
	const [formType, setFormType] = useState<string>('');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isExpired, setIsExpired] = useState<boolean>();

	useEffect(() => {
		if (search) {
			const searchParams = new URLSearchParams(search);
			const tokenString = searchParams.get('token');

			if (tokenString) {
				setToken(tokenString);
				checkTokenExpiration(tokenString);
			}
		}
	}, []);

	const checkTokenExpiration = (token: string) => {
		removeAll();

		const payload = {
			params: { token },
			onSucceeded: checkTokenSucceeded,
			onFailed: checkTokenFailed,
		};

		dispatch(checkTokenReset(payload));
	};

	const checkTokenSucceeded = (result: ResponsePayloadDto) => {
		if (result !== null) {
			const {
				status: { code = 0 },
			} = result.data;

			if (code === STATUSCODE.SUCCESS) {
				setIsExpired(false);
			}
		}
	};

	const checkTokenFailed = (result: ResponsePayloadDto) => {
		if (result !== null) {
			const {
				status: { code = 0 },
			} = result.data;

			setIsExpired(true);

			if (code === STATUSCODE.FAILED404) {
				setFormType(TYPE_TOKEN.INVALID);
			}

			if (code === STATUSCODE.FAILED400) {
				setFormType(TYPE_TOKEN.EXPIRED);
			}
		}
	};

	const hanldeResetPassword = (values: any) => {
		const params = { ...values, token };
		const resetPasswordSucceeded = () => {
			message.success(t('message.reset_success'), 1.5).then(() => {
				setIsLoading(false);
				history.push(ROUTE_PATH.LOGIN);
			});
		};

		const payload = {
			params,
			onSucceeded: resetPasswordSucceeded,
			onFailed,
		};

		setIsLoading(true);
		dispatch(resetPassword(payload));
	};

	const resendLink = () => {
		const payload = {
			params: { token },
			onSucceeded: onGetEmailSucceeded,
			onFailed,
		};

		setIsLoading(true);
		dispatch(getEmailReset(payload));
	};

	const onGetEmailSucceeded = (result: string) => {
		if (result) {
			const payload = {
				params: { email: result },
				onSucceeded,
				onFailed,
			};

			setIsLoading(false);
			dispatch(sendEmailResetPwd(payload));
		}
	};

	const onFailed = (error: string) => {
		message.error(error);
		setIsLoading(false);
	};

	const onSucceeded = () => {
		message.info(t('forget_password.reset_link_success'));
	};

	const renderResetPassword = () => (
		<Modal
			width={400}
			title={t('forget_password.set_new_password')}
			okText={t('button.reset')}
			cancelText={''}
			isOpen={true}
			closable={false}
			isLoading={isLoading}
			onSubmit={() => form.submit()}>
			<Form form={form} layout="vertical" requiredMark={false} onFinish={hanldeResetPassword}>
				<StyledFormItem
					className="custom-form-item"
					name="newPassword"
					label={t('change_password.password')}
					hasFeedback
					rules={[
						{
							pattern: PATTERNS.PASSWORD,
							message: renderErrorMessagePwd(t('format_password')),
						},
						{
							required: true,
							message: renderErrorMessagePwd(
								t('change_password.validate.required_password'),
							),
						},
					]}>
					<Input.Password />
				</StyledFormItem>

				<StyledFormItem
					name="confirmPassword"
					label={t('change_password.confirm_password')}
					hasFeedback
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(
								t('change_password.validate.required_confirm_password'),
							),
						},
						({ getFieldValue }: any) => ({
							validator(_: any, value: string) {
								if (!value || getFieldValue('newPassword') === value) {
									return Promise.resolve();
								}
								return Promise.reject(
									renderErrorMessagePwd(
										t('change_password.validate.password_not_match'),
									),
								);
							},
						}),
					]}>
					<Input.Password />
				</StyledFormItem>
			</Form>
		</Modal>
	);

	const renderResendEmail = () => (
		<Modal width={400} isFooter={false} isOpen={true} closable={false} isLoading={isLoading}>
			<StyledContainer>
				<StyledCustomTitle fontSize="1.714rem" fontWeight="700" margin="0 0 32px 0">
					{t('register.sorry')}
				</StyledCustomTitle>

				{formType === TYPE_TOKEN.INVALID
					? t('forget_password.invalid_token')
					: t('forget_password.expired_link')}

				<StyledContainerBtn>{renderButton()}</StyledContainerBtn>
			</StyledContainer>
		</Modal>
	);

	const renderButton = () => {
		if (formType === TYPE_TOKEN.INVALID) {
			return (
				<StyledButton
					loading={isLoading}
					onClick={() => {
						history.push(ROUTE_PATH.LOGIN);
					}}
					margin="28px 0 0 0"
					type="primary">
					{t('button.go_login_page')}
				</StyledButton>
			);
		}

		return (
			<StyledButton
				loading={isLoading}
				onClick={resendLink}
				margin="28px 0 0 0"
				type="primary">
				{t('button.send_new_link')}
			</StyledButton>
		);
	};

	return (
		<React.Fragment>
			{!_.isUndefined(isExpired) && !isExpired && renderResetPassword()}
			{isExpired && renderResendEmail()}
		</React.Fragment>
	);
};

export default React.memo(ForgotPassword);
