import { STATUSCODE } from '@constants/APIs';
import { COL_DASHBOARD, STORE_LOCAL } from '@constants/dashboard';
import { DEFAULT_ERROR } from '@constants/errors';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { getObject, setObject } from '@helpers/storageHelpers';
import { Action, handleActions } from 'redux-actions';
import {
	generateReportTypes,
	saveExpandDashboardTypes,
	saveFilterDashboardDefaultTypes,
	saveGridColDashboardTypes,
} from './dashboard.types';

const defaultExpandStore = +getObject(STORE_LOCAL.EXPAND) || false;
const defaultGridColumnDashboard = +getObject(STORE_LOCAL.GRID_COL_DASHBOARD) || COL_DASHBOARD.MORE;

const initialState: any = {
	generateReportFile: '',
	generateReportStatus: NONE,
	filterDefault: undefined,
	expandDashBoard: defaultExpandStore,
	gridColumnDashboard: defaultGridColumnDashboard,
};

const generateReport = (state: any) => ({
	...state,
	generateReportStatus: IN_PROGRESS,
});

const generateReportCompleted = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: generateReportFile = '',
	} = payload;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			generateReportFile,
			generateReportStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		generateReportStatus: FAILED,
		error: status,
	};
};

const generateReportFailed = (state: any, { payload = DEFAULT_ERROR }: { payload: string }) => ({
	...state,
	generateReportStatus: FAILED,
	error: payload,
});

const generateReportEnd = (state: any) => {
	return {
		...state,
		error: null,
		generateReportStatus: NONE,
	};
};

const saveFilterDashboardDefault = (state: any, { payload }: any) => {
	const result = { ...state, filterDefault: undefined };
	if (payload) {
		result.filterDefault = { ...payload };
	}

	return {
		...result,
	};
};

const saveExpandDashboard = (state: any, { payload }: Action<boolean>) => {
	const valStore = payload ? 1 : 0;
	setObject(STORE_LOCAL.EXPAND, valStore);
	return {
		...state,
		expandDashBoard: payload,
	};
};

const saveGridColDashboard = (state: any, { payload }: Action<number>) => {
	setObject(STORE_LOCAL.GRID_COL_DASHBOARD, payload);
	return {
		...state,
		gridColumnDashboard: payload,
	};
};

const dashboardReducers = handleActions<any>(
	{
		[generateReportTypes.GENERATE_REPORT_REQUEST]: generateReport,
		[generateReportTypes.GENERATE_REPORT_COMPLETED]: generateReportCompleted,
		[generateReportTypes.GENERATE_REPORT_FAILED]: generateReportFailed,
		[generateReportTypes.GENERATE_REPORT_END]: generateReportEnd,

		[saveFilterDashboardDefaultTypes.SAVE_FILTER_DASHBOARD_DEFAULT]: saveFilterDashboardDefault,

		[saveExpandDashboardTypes.SAVE_EXPAND_DASHBOARD]: saveExpandDashboard,
		[saveGridColDashboardTypes.SAVE_GRID_COL_DASHBOARD]: saveGridColDashboard,
	},
	initialState,
);

export default dashboardReducers;
