import { TYPE_FORMAT_DATE } from '@constants/common';
import {
	FILTER_SOCIAL,
	NAME_FILTER_CONTENT,
	RIGHT_CLEAR_FILTER,
	STATUS_FILTER,
} from '@constants/filter/filterContent';
import { StateCampaign } from '@models/campaign';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { OptionFilterTag, OptionTypeItem } from '@models/filter';
import { StyledDatePicker } from '@styled/Common/CommonStyled';
import {
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledSelect,
} from '@styled/Content/ContentLibrary/FilterStyled';
import {
	StyledFormItem,
	StyledInput,
	StyledLabel,
	StyledRadioGroup,
	StyledRate,
} from '@styled/Filter/FilterContentStyled';
import { Checkbox, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { OptGroup, Option } = StyledSelect;

const DetailFilter = () => {
	const { t } = useTranslation();

	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);
	const { tags } = useSelector((state: ContentRootStateDto) => state.content);
	const { flags, labels, googleAIHashtags } = tags;

	const renderRadioGroup = (list: Array<OptionTypeItem>) => {
		return (
			<StyledRadioGroup className="radio_group_default first_0">
				{list?.map((item) => (
					<Radio key={item?.value} value={item?.value}>
						{item?.name}
					</Radio>
				))}
			</StyledRadioGroup>
		);
	};

	const renderCheckboxGroup = (list: Array<OptionTypeItem>, className?: string) => {
		return (
			<StyledCheckboxGroup className={className || ''}>
				{list.map((checkbox: OptionTypeItem) => (
					<StyledCheckbox
						value={checkbox.value}
						key={checkbox.value}
						className="checkbox_default">
						{checkbox.name}
					</StyledCheckbox>
				))}
			</StyledCheckboxGroup>
		);
	};

	const renderRating = () => {
		return <StyledRate />;
	};

	const renderNotRate = () => {
		return <Checkbox>{t('filter.filter_content.show_unrated')}</Checkbox>;
	};

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const renderListOption = (options: Array<any>, name: string) => {
		if (name === NAME_FILTER_CONTENT.TAGS) {
			return options?.map((item) => {
				return { name: item?.value, value: item?.value };
			});
		}

		if (name === NAME_FILTER_CONTENT.CAMPAIGNS) {
			return options?.map((option) => ({
				label: option?.name,
				value: option?.id?.toString(),
			}));
		}

		return options;
	};

	const renderSelect = (list: Array<any>, name: string) => {
		let placeholder = t('filter.filter_content.any');
		let mode: 'tags' | 'multiple' | undefined;
		if (name === NAME_FILTER_CONTENT.TAGS) {
			placeholder = t('filter.filter_content.any_tags');
		}

		if (name === NAME_FILTER_CONTENT.CAMPAIGNS) {
			placeholder = t('filter.filter_content.any_campaigns');
		}

		if (name !== NAME_FILTER_CONTENT.UPLOAD_LOCATION) {
			mode = 'multiple';
		}

		return (
			<StyledSelect
				mode={mode}
				showSearch
				notFoundContent={notFoundContent()}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				placeholder={placeholder}
				virtual={false}
				optionFilterProp="label"
				showArrow
				options={renderListOption(list, name)}
				dropdownClassName="dropdown-menu dropdown-custom-content"
			/>
		);
	};

	const renderSelectGroup = (list: OptionFilterTag[]) => {
		return (
			<StyledSelect
				mode={'multiple'}
				showSearch
				notFoundContent={notFoundContent()}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				filterOption={true}
				virtual={false}
				placeholder={t('filter.filter_content.any_tags')}
				showArrow
				optionFilterProp={'children'}
				className="select_group_tags"
				dropdownClassName="dropdown-menu dropdown-custom-content">
				{list?.map((item) => (
					<OptGroup key={item?.nameGroup} label={item?.label}>
						{item?.options?.map((option) => {
							const val = JSON.stringify({
								val: option?.value,
								group: item?.nameGroup,
							});
							return (
								<Option key={val} value={val}>
									{option?.name}
								</Option>
							);
						})}
					</OptGroup>
				))}
			</StyledSelect>
		);
	};

	const renderSelectRangeDate = () => {
		return <StyledDatePicker format={TYPE_FORMAT_DATE.MONTH_DAY} />;
	};

	const renderInput = (name: string) => {
		let placeholder = t('filter.filter_content.search');
		if (name === NAME_FILTER_CONTENT.KEYWORD) {
			placeholder = t('filter.filter_content.placeholder_keyword_bookmark');
		}
		if (name === NAME_FILTER_CONTENT.CREATORS) {
			placeholder = t('filter.filter_content.placeholder_creator');
		}

		return <StyledInput placeholder={placeholder} />;
	};

	const listStatus: Array<OptionTypeItem> = [
		{ name: t('filter.filter_content.any'), value: STATUS_FILTER.ANY },
		{ name: t('filter.filter_content.needs_review'), value: STATUS_FILTER.NEEDS_REVIEW },
		{ name: t('filter.filter_content.reviewed'), value: STATUS_FILTER.REVIEWED },
	];

	const listRightClear: Array<OptionTypeItem> = [
		{ name: t('filter.filter_content.none'), value: RIGHT_CLEAR_FILTER.NONE },
		{ name: t('filter.filter_content.pending'), value: RIGHT_CLEAR_FILTER.PENDING },
		{ name: t('content_detail.rights_granted'), value: RIGHT_CLEAR_FILTER.APPROVED },
		{ name: t('filter.filter_content.custom_terms'), value: RIGHT_CLEAR_FILTER.CUSTOM_TERMS },
	];

	const listOptionSocialChannel: Array<OptionTypeItem> = [
		{ name: t('filter.filter_bookmark.instagram'), value: FILTER_SOCIAL.INSTAGRAM },
		{ name: t('filter.filter_bookmark.twitter'), value: FILTER_SOCIAL.TWITTER },
		{ name: t('filter.filter_bookmark.tiktok'), value: FILTER_SOCIAL.TIKTOK },
		{ name: t('filter.filter_bookmark.youtube'), value: FILTER_SOCIAL.YOUTUBE },
	];

	const listOptionFilterTag: OptionFilterTag[] = [
		{
			nameGroup: NAME_FILTER_CONTENT.LABELS,
			options: renderListOption(labels || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.labels'),
		},
		{
			nameGroup: NAME_FILTER_CONTENT.FLAGS,
			options: renderListOption(flags || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.flags'),
		},
		{
			nameGroup: NAME_FILTER_CONTENT.GOOGLE_AI_TAGS,
			options: renderListOption(googleAIHashtags || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.google_AI_tags'),
		},
	];

	const listFormItem = [
		{
			name: NAME_FILTER_CONTENT.RATING,
			label: t('filter.filter_content.minimum_rating'),
			content: renderRating(),
		},
		{
			name: NAME_FILTER_CONTENT.NOT_RATE,
			label: null,
			content: renderNotRate(),
			otherProps: { valuePropName: 'checked' },
		},
		{
			name: NAME_FILTER_CONTENT.SOCIAL_CHANNEL,
			label: t('filter.filter_bookmark.social_channel'),
			content: renderCheckboxGroup(listOptionSocialChannel),
		},
		{
			name: NAME_FILTER_CONTENT.TAGS,
			label: t('filter.filter_content.content_tags'),
			content: renderSelectGroup(listOptionFilterTag),
		},
		{
			name: NAME_FILTER_CONTENT.CAMPAIGNS,
			label: t('filter.filter_content.campaigns'),
			content: renderSelect(campaignListContent || [], NAME_FILTER_CONTENT.CAMPAIGNS),
		},
		{
			name: NAME_FILTER_CONTENT.STATUS,
			label: t('filter.filter_content.status'),
			content: renderRadioGroup(listStatus),
		},
		{
			name: NAME_FILTER_CONTENT.RIGHT_CLEAR,
			label: t('filter.filter_content.right_clear'),
			content: renderCheckboxGroup(listRightClear, 'checkbox_group_half'),
		},
		{
			name: NAME_FILTER_CONTENT.KEYWORD,
			label: t('filter.filter_content.keyword'),
			content: renderInput(NAME_FILTER_CONTENT.KEYWORD),
		},
		{
			name: NAME_FILTER_CONTENT.UPLOAD_DATE,
			label: t('filter.filter_bookmark.post_date'),
			content: renderSelectRangeDate(),
		},
	];

	return (
		<>
			{listFormItem?.map((item) => (
				<StyledFormItem
					key={item?.name}
					label={item?.label ? <StyledLabel>{item?.label}</StyledLabel> : null}
					name={item?.name}
					{...item?.otherProps}>
					{item?.content}
				</StyledFormItem>
			))}
		</>
	);
};

export default DetailFilter;
