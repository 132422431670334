import { Col, Form, Input, Row, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { IconAddCircle, IconInfoTriangle, IconRemoveCircle } from '@assets/icons';
import {
	CONTENT_TYPE,
	FILE_NAME,
	FILE_NAME_STR,
	MEDIA_TYPE,
	SYSTEM_FOLDER_LIST,
	UPLOADSOURCE,
} from '@constants/content/contentLibrary';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import { getSocialS3URL } from '@utils/common';

// Libs
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

// Styled
import { GOOGLE_API } from '@constants/APIs';
import {
	DURATION,
	GOOGLE_DRIVE_DOMAIN,
	IMG_EXTENSION,
	MESSAGE_KEY,
	TYPE_FORMAT_DATE,
	VIDEO_EXTENSION,
} from '@constants/common';
import LoadingWrapper from '@cores/LoadingWrapper';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { postRequest } from '@helpers/requestHelpers';
import { MetaDataGGDriveDto } from '@models/common/summary';
import { countDownloadContentRequest } from '@stores/actions';
import { StyledButton } from '@styled/Common/CommonStyled';
import {
	StyledButtonText,
	StyledCascader,
	StyledErrorMsg,
	StyledModal,
	StyledPreviewFileName,
	StyledSpace,
} from '@styled/Content/ContentLibrary/DownloadStyled';
import { StyleIconRemove } from '@styled/Creators/ModalStyled';
import { renderNameZip } from '@utils/funcHelper';
import { useDispatch } from 'react-redux';
import GoogleFolderPicker from './GoogleFolderPicker';

type DownloadModalProps = {
	isDownload: boolean;
	onDownload: () => void;
	fileNameDefault: string;
	selectContentList: ContentContainerDto[];
	clearSelection?: () => void;
	albumId?: number | string;
	contentDetailDto?: ContentDto;
};

type FileNameDetailsDto = {
	value: string;
	label: string;
	disabled?: boolean | undefined;
	children?: {
		value: string;
		label: string;
		disabled?: boolean | undefined;
	}[];
};

type PreviewFileNameDto = {
	id: number;
	contentURI: string;
	fileName: string;
	fileExtension: string;
	type?: string;
	fileType?: string;
};

const DownloadModal = (props: DownloadModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const {
		isDownload,
		onDownload,
		fileNameDefault,
		selectContentList,
		clearSelection,
		albumId,
		contentDetailDto,
	} = props;

	const fileNameDetailsData = [
		{
			value: FILE_NAME.CREATOR_NAME.value,
			label: FILE_NAME.CREATOR_NAME.label,
		},
		{
			value: FILE_NAME.SOCIAL_USERNAME.value,
			label: FILE_NAME.SOCIAL_USERNAME.label,
			children: [
				{
					value: FILE_NAME.SOCIAL_USERNAME.INSTAGRAM.value,
					label: FILE_NAME.SOCIAL_USERNAME.INSTAGRAM.label,
				},
				{
					value: FILE_NAME.SOCIAL_USERNAME.TWITTER.value,
					label: FILE_NAME.SOCIAL_USERNAME.TWITTER.label,
				},
				{
					value: FILE_NAME.SOCIAL_USERNAME.YOUTUBE.value,
					label: FILE_NAME.SOCIAL_USERNAME.YOUTUBE.label,
				},
				{
					value: FILE_NAME.SOCIAL_USERNAME.TIKTOK.value,
					label: FILE_NAME.SOCIAL_USERNAME.TIKTOK.label,
				},
			],
		},
		{
			value: FILE_NAME.CREATOR_EMAIL.value,
			label: FILE_NAME.CREATOR_EMAIL.label,
		},
		{
			value: FILE_NAME.UPLOAD_DETAILS.value,
			label: FILE_NAME.UPLOAD_DETAILS.label,
			children: [
				{
					value: FILE_NAME.UPLOAD_DETAILS.IMPORT_DATE.value,
					label: FILE_NAME.UPLOAD_DETAILS.IMPORT_DATE.label,
					disabled: true,
				},
				{
					value: FILE_NAME.UPLOAD_DETAILS.UPLOAD_SOURCE.value,
					label: FILE_NAME.UPLOAD_DETAILS.UPLOAD_SOURCE.label,
					disabled: true,
				},
				{
					value: FILE_NAME.UPLOAD_DETAILS.VERIFIED.value,
					label: FILE_NAME.UPLOAD_DETAILS.VERIFIED.label,
				},
				{
					value: FILE_NAME.UPLOAD_DETAILS.PUBLISHED.value,
					label: FILE_NAME.UPLOAD_DETAILS.PUBLISHED.label,
				},
			],
		},
	];

	const initialFileNameDetails = [
		FILE_NAME.UPLOAD_DETAILS.UPLOAD_SOURCE.value,
		FILE_NAME.UPLOAD_DETAILS.IMPORT_DATE.value,
	];

	const [fileName, setFileName] = useState<string>('');
	const [fileNameDetails, setFileNameDetails] = useState<string[]>(initialFileNameDetails);
	const [fileNameDetailsOptions, setFileNameDetailsOptions] = useState<FileNameDetailsDto[]>([]);
	const [previewFileName, setPreviewFileName] = useState<PreviewFileNameDto[]>([]);
	const [customName, setCustomName] = useState<string>('');
	const [errorMsg, setErrorMsg] = useState<string>('');

	const [isAddNew, setIsAddNew] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [googleToken, setGoogleToken] = useState<string>('');

	const DOWNLOAD_TYPE = {
		GOOGLE_DRIVE: 'GOOGLE_DRIVE',
		DROPBOX: 'DROPBOX',
		LOCAL: 'LOCAL',
	};

	useEffect(() => {
		form.setFieldsValue({
			fileName: fileNameDefault,
			detailsFileName: [
				{
					detailsFileName_0: [
						FILE_NAME.UPLOAD_DETAILS.value,
						FILE_NAME.UPLOAD_DETAILS.UPLOAD_SOURCE.value,
					],
				},
				{
					detailsFileName_1: [
						FILE_NAME.UPLOAD_DETAILS.value,
						FILE_NAME.UPLOAD_DETAILS.IMPORT_DATE.value,
					],
				},
			],
		});

		setFileName(fileNameDefault);
		setFileNameDetailsOptions(fileNameDetailsData);

		return () => {
			message.destroy(MESSAGE_KEY.DOWNLOAD_BOOKMARK_CONTENT);
		};
	}, []);

	useEffect(() => {
		const previewFileNameTemp: PreviewFileNameDto[] = [];

		const handlePushFileName = (media: ContentDto) => {
			let clonedFileName: string = customName || '';

			if (media) {
				const { uploadSource } = media;

				let imgUrl: string = '';

				if (media !== null) {
					fileNameDetails.map((field: any) => {
						const value = addInfoToDownload(field, media);
						clonedFileName += '_' + value;
					});

					imgUrl = getSocialS3URL(media?.contentUrl);
				}

				clonedFileName =
					clonedFileName.charAt(0) === '_' ? clonedFileName.substr(1) : clonedFileName;

				if (
					uploadSource !== UPLOADSOURCE.SOCIAL_SEARCH &&
					uploadSource !== UPLOADSOURCE.SOCIAL_UPLOAD
				)
					previewFileNameTemp.push({
						id: media.id,
						contentURI: imgUrl,
						type: media?.mediaType,
						fileName: clonedFileName,
						fileType: media?.contentMetadata?.fileType,
						fileExtension: media?.contentMetadata?.fileExtension?.toLowerCase() || '',
					});
			}
		};

		selectContentList.map((selected: ContentContainerDto) => {
			const media = getContentByType(selected);
			if (media) {
				handlePushFileName(media);
			}
		});

		if (contentDetailDto) {
			handlePushFileName(contentDetailDto);
		}

		setPreviewFileName(previewFileNameTemp);
	}, [fileNameDetails, fileName, customName]);

	const getContentByType = (element: ContentContainerDto) => {
		const { content, select } = element;

		if (element.type === CONTENT_TYPE.VERIFIED || element.type === CONTENT_TYPE.CONTENT)
			return content;

		return select;
	};

	const addInfoToDownload = (field: string, currentUploadPreview: ContentDto | null) => {
		const invalidText = 'not available';

		if (currentUploadPreview) {
			const { creatorProfile, uploadDate, uploadSource, review, social } =
				currentUploadPreview;

			switch (field) {
				case FILE_NAME.CREATOR_NAME.value: {
					if (!_.isEmpty(creatorProfile)) {
						return creatorProfile?.firstName + ' ' + creatorProfile?.lastName;
					}

					return invalidText;
				}
				case FILE_NAME.SOCIAL_USERNAME.INSTAGRAM.value: {
					if (!_.isEmpty(creatorProfile) && creatorProfile?.instagramUsername !== null) {
						return creatorProfile?.instagramUsername;
					} else if (
						!_.isEmpty(social) &&
						social.socialSource === FILE_NAME.SOCIAL_USERNAME.INSTAGRAM.value
					) {
						return social?.socialUsername;
					}

					return invalidText;
				}
				case FILE_NAME.SOCIAL_USERNAME.TWITTER.value: {
					if (!_.isEmpty(creatorProfile) && creatorProfile?.twitterUsername !== null) {
						return creatorProfile?.twitterUsername;
					} else if (
						!_.isEmpty(social) &&
						social.socialSource === FILE_NAME.SOCIAL_USERNAME.TWITTER.value
					) {
						return social?.socialUsername;
					}
					return invalidText;
				}
				case FILE_NAME.SOCIAL_USERNAME.TIKTOK.value: {
					if (!_.isEmpty(creatorProfile) && creatorProfile?.tiktokUsername !== null) {
						return creatorProfile?.tiktokUsername;
					} else if (
						!_.isEmpty(social) &&
						social.socialSource === FILE_NAME.SOCIAL_USERNAME.TIKTOK.value
					) {
						return social?.socialUsername;
					}

					return invalidText;
				}
				case FILE_NAME.SOCIAL_USERNAME.YOUTUBE.value: {
					if (!_.isEmpty(creatorProfile) && creatorProfile?.youtubeUsername !== null) {
						return creatorProfile?.youtubeUsername;
					} else if (
						!_.isEmpty(social) &&
						social.socialSource === FILE_NAME.SOCIAL_USERNAME.YOUTUBE.value
					) {
						return social?.socialUsername;
					}

					return invalidText;
				}
				case FILE_NAME.CREATOR_EMAIL.value: {
					if (!_.isEmpty(creatorProfile)) {
						return creatorProfile?.email;
					}

					return invalidText;
				}
				case FILE_NAME.UPLOAD_DETAILS.IMPORT_DATE.value: {
					if (uploadDate) {
						const importedDate = convertUtcToLocalTimeWithFormat(
							uploadDate,
							TYPE_FORMAT_DATE.MONTH_TEXT,
						);
						return importedDate;
					}
					return invalidText;
				}
				case FILE_NAME.UPLOAD_DETAILS.UPLOAD_SOURCE.value: {
					if (uploadSource) {
						return uploadSource.replace('-', ' ');
					}
					return invalidText;
				}
				case FILE_NAME.UPLOAD_DETAILS.VERIFIED.value: {
					const { verified } = review;

					if (verified) {
						return 'verified';
					} else {
						return 'unverified';
					}
				}
				case FILE_NAME.UPLOAD_DETAILS.PUBLISHED.value: {
					const { published } = review;

					if (published) {
						return 'published';
					} else {
						return 'unpublished';
					}
				}
			}
		}

		return null;
	};

	const onChange = (changedValue: any) => {
		const changedValueTemp = fileNameDetails[changedValue[0].name[1]];

		if (changedValueTemp !== undefined) {
			const replaceValue: string = changedValue[0].value.find(
				(element: any) =>
					element !== FILE_NAME.UPLOAD_DETAILS.value &&
					element !== FILE_NAME.SOCIAL_USERNAME.value,
			);

			setFileName(fileName.replace(checkType(changedValueTemp), checkType(replaceValue)));

			const findIndex = fileNameDetails.findIndex(
				(element: string) => element === changedValueTemp,
			);

			fileNameDetails[findIndex] = replaceValue;

			fileNameDetailsOptions.forEach((element: any) => {
				if (element.children) {
					element.children.forEach((itemChildren: any) => {
						if (itemChildren.value === replaceValue) {
							itemChildren.disabled = true;
						}

						if (itemChildren.value === changedValueTemp) {
							itemChildren.disabled = false;
						}
					});
				} else {
					if (element.value === replaceValue) {
						element.disabled = true;
					}

					if (element.value === changedValueTemp) {
						element.disabled = false;
					}
				}
			});

			setFileNameDetailsOptions(fileNameDetailsOptions);
			setFileNameDetails(fileNameDetails);
		} else {
			if (changedValue[0].value.includes(undefined)) {
				setIsAddNew(false);
			} else {
				setIsAddNew(true);
			}
		}
	};

	const checkType = (field: string) => {
		let newNameValue: string = '';

		switch (field) {
			case FILE_NAME.CREATOR_NAME.value: {
				newNameValue = FILE_NAME_STR.CREATOR_NAME;
				break;
			}
			case FILE_NAME.CREATOR_EMAIL.value: {
				newNameValue = FILE_NAME_STR.CREATOR_EMAIL;
				break;
			}
			case FILE_NAME.SOCIAL_USERNAME.YOUTUBE.value: {
				newNameValue = FILE_NAME_STR.YOUTUBE;
				break;
			}
			case FILE_NAME.SOCIAL_USERNAME.INSTAGRAM.value: {
				newNameValue = FILE_NAME_STR.INSTAGRAM;
				break;
			}
			case FILE_NAME.SOCIAL_USERNAME.TWITTER.value: {
				newNameValue = FILE_NAME_STR.TWITTER;
				break;
			}
			case FILE_NAME.SOCIAL_USERNAME.TIKTOK.value: {
				newNameValue = FILE_NAME_STR.TIKTOK;
				break;
			}
			case FILE_NAME.UPLOAD_DETAILS.UPLOAD_SOURCE.value: {
				newNameValue = FILE_NAME_STR.UPLOAD_SOURCE;
				break;
			}
			case FILE_NAME.UPLOAD_DETAILS.IMPORT_DATE.value: {
				newNameValue = FILE_NAME_STR.IMPORT_DATE;
				break;
			}
			case FILE_NAME.UPLOAD_DETAILS.VERIFIED.value: {
				newNameValue = FILE_NAME_STR.VERIFIED_STATUS;
				break;
			}
			case FILE_NAME.UPLOAD_DETAILS.PUBLISHED.value: {
				newNameValue = FILE_NAME_STR.PUBLISHED_STATUS;
				break;
			}
		}

		return newNameValue;
	};

	const handleClickRemove = (name: number) => {
		let newFileName: string = '';
		newFileName = fileName.replace('_' + checkType(fileNameDetails[name]), '');

		const fileNameDetailsTemp = fileNameDetails.filter(
			(element: string) => element !== fileNameDetails[name],
		);

		handleFileNameDetails(fileNameDetails[name], false);

		setFileNameDetails(fileNameDetailsTemp);
		setFileName(newFileName);
	};

	const onChangeFileNameDetails = (value: string[]) => {
		const fileNameDetailsTemp = [...fileNameDetails];
		let newFileName: string = fileName;

		value.forEach((item: string) => {
			if (!fileNameDetailsTemp.includes(item)) {
				if (
					item != FILE_NAME.UPLOAD_DETAILS.value &&
					item != FILE_NAME.SOCIAL_USERNAME.value
				) {
					if (!fileNameDetailsTemp.includes(item)) {
						fileNameDetailsTemp.push(item);
						newFileName = newFileName + '_' + checkType(item);
						setFileName(newFileName);
					}
				}

				handleFileNameDetails(item, true);

				setFileNameDetails(fileNameDetailsTemp);
				setIsAddNew(true);
			}
		});
	};

	const handleFileNameDetails = (item: string, isDisabled: boolean) => {
		fileNameDetailsOptions.forEach((element: FileNameDetailsDto) => {
			if (element.children) {
				element.children.forEach((itemChildren: any) => {
					if (itemChildren.value === item) {
						itemChildren.disabled = isDisabled;
					}
				});
			} else {
				if (element.value === item) {
					element.disabled = isDisabled;
				}
			}
		});

		setFileNameDetailsOptions(fileNameDetailsOptions);
	};

	const onChangeCustomName = async (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = evt.target;
		await setCustomName(value);
	};

	const onCancel = async () => {
		setPreviewFileName([]);
		setCustomName('');
		setFileNameDetails(initialFileNameDetails);
		setFileNameDetailsOptions(fileNameDetailsData);
		setPreviewFileName([]);
		await onDownload();
	};

	const handleDownload = (
		fileList: PreviewFileNameDto[],
		folderName: string,
		downloadType?: string,
		folderIds?: string[],
	) => {
		const zip = new JSZip();

		const downloadInfo = getDownloadInfo(fileList);
		const isGoogleDrive = downloadType && downloadType === DOWNLOAD_TYPE.GOOGLE_DRIVE;

		setIsLoading(true);

		getMediaData(downloadInfo)
			.then((mediaData: any) => {
				if (fileList.length > 5) {
					if (customName === '' && folderName.charAt(0) === '_') {
						folderName = folderName.substr(1);
					}
					folderName = customName + folderName;
					const zipName = renderNameZip();

					// zip.folder(folderName);
					_.each(mediaData, (media: any) => {
						zip.file(media.id, media.data, {
							base64: true,
						});
					});

					if (!_.isEmpty(zip.files)) {
						zip.generateAsync({ type: 'blob' }).then((content: any) => {
							if (isGoogleDrive) downloadGoogleDrive(content, zipName, folderIds);
							else FileSaver.saveAs(content, zipName + '.zip');
						});
					}
				} else {
					mediaData.map((media: any) => {
						const fileTemp = new File([media.data], media.id, {
							type: 'text/json;charset=utf-8',
						});
						const blob = new Blob([fileTemp]);

						if (isGoogleDrive) downloadGoogleDrive(blob, media.id, folderIds);
						else FileSaver.saveAs(blob, media.id);
					});
				}

				setIsLoading(false);

				message
					.success(
						isGoogleDrive
							? t('message.saved_to_gg_drive')
							: t('content_management.download.success'),
						DURATION.S_3,
					)
					.then(() => {
						let downloadTypeTemp: string = DOWNLOAD_TYPE.LOCAL;
						if (isGoogleDrive) {
							downloadTypeTemp = DOWNLOAD_TYPE.GOOGLE_DRIVE;
							const anchorElement = document.createElement('a');
							anchorElement.id = 'anchorElement';
							anchorElement.href = GOOGLE_DRIVE_DOMAIN;
							anchorElement.target = '_blank';
							document.body.appendChild(anchorElement);
							anchorElement?.click();
							anchorElement.remove();
						}
						countDownloadContent(downloadTypeTemp);
					});
			})
			.catch((e) => {
				console.log('Error', e);
				setIsLoading(false);

				message.error(t('content_management.download.error'));
			});
	};

	const formatDownloadFile = (item: PreviewFileNameDto) => {
		let contentURI = item?.contentURI;
		const fileExtension = item.fileExtension;
		if (item.type === MEDIA_TYPE.VIDEO) {
			contentURI = contentURI.replace(VIDEO_EXTENSION.MP4, item.fileExtension);
		} else {
			const typeFile = contentURI.slice(contentURI.lastIndexOf('.') + 1);

			contentURI = contentURI.replace(typeFile, fileExtension);
		}

		return {
			contentURI,
			fileExtension,
		};
	};

	const getDownloadInfo = (fileList: PreviewFileNameDto[]) => {
		const data: any = [];

		_.each(fileList, (item: PreviewFileNameDto, index: number) => {
			let { fileName } = item;
			fileName = fileList.length > 5 ? `${fileName}_${index + 1}` : fileName;

			data.push({
				url: formatDownloadFile(item).contentURI,
				id: `${fileName}.${formatDownloadFile(item).fileExtension}`,
			});
		});

		return data;
	};

	// Request media url to get binary data
	const getMediaData = async (mediaInfo: any) => {
		const promises: any = [];

		_.each(mediaInfo, (info) => {
			const promise = new Promise((resolve, reject) => {
				const currDate = new Date();
				JSZipUtils.getBinaryContent(
					info.url + '?time=' + currDate.getTime(),
					(err: any, data: any) => {
						if (err) return reject(err);
						resolve({ id: info.id, data });
						return null;
					},
				);
			});

			promises.push(promise);
		});

		return Promise.all(promises);
	};

	const saveToDesktop = () => {
		if (fileName === '' && fileNameDetails.length === 0) {
			setErrorMsg(t('content_management.download.invalid_file_name'));

			setTimeout(() => {
				setErrorMsg('');
			}, 2500);
		} else {
			handleDownload(previewFileName, fileName);
			setIsLoading(true);
		}
	};

	const downloadGoogleDrive = (fileContent: Blob, fileName: string, folderIds?: string[]) => {
		let metadata: MetaDataGGDriveDto = {
			name: fileName, // Filename at Google Drive
		};

		if (folderIds) {
			metadata = { ...metadata, parents: folderIds };
		}

		const form = new FormData();
		form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
		form.append('file', fileContent);

		postRequest(
			GOOGLE_API.UPLOAD_FILES,
			form,
			'multipart/form-data',
			undefined,
			googleToken,
		).then(() => {
			setIsLoading(false);
		});
	};

	const handleGoogleDrive = (folderIds?: string[]) => {
		if (googleToken)
			handleDownload(previewFileName, fileName, DOWNLOAD_TYPE.GOOGLE_DRIVE, folderIds);
	};

	const countDownloadContent = (downloadType: string) => {
		const contentIds: number[] = [];

		if (contentDetailDto) {
			const { uploadSource } = contentDetailDto;
			if (
				uploadSource !== UPLOADSOURCE.SOCIAL_SEARCH &&
				uploadSource !== UPLOADSOURCE.SOCIAL_UPLOAD
			) {
				const { id } = contentDetailDto;
				contentIds.push(id);
			}
		}
		if (selectContentList) {
			selectContentList.forEach((content: ContentContainerDto) => {
				const contentTemp = getContentByType(content);
				if (contentTemp) {
					const { uploadSource } = contentTemp;
					if (
						uploadSource !== UPLOADSOURCE.SOCIAL_SEARCH &&
						uploadSource !== UPLOADSOURCE.SOCIAL_UPLOAD
					) {
						const { id } = contentTemp;
						contentIds.push(id);
					}
				}
			});

			const payload = {
				downloadType,
				contentIds,
				albumId: albumId || SYSTEM_FOLDER_LIST[0].id,
			};

			dispatch(countDownloadContentRequest(payload));
		}
	};

	let formatFileName: string = fileName;

	if (formatFileName.charAt(0) === '_') {
		formatFileName = formatFileName.substr(1);
	} else {
		formatFileName = fileName;
	}

	return (
		<StyledModal
			width="582px"
			title={t('content_management.download.title')}
			centered={true}
			visible={isDownload}
			onCancel={onCancel}
			footer={[
				<GoogleFolderPicker
					setGoogleToken={setGoogleToken}
					handleGoogleDrive={handleGoogleDrive}
				/>,
				<StyledButton margin="0 0 0 16px" onClick={saveToDesktop} type="primary">
					{t('button.save_to_desktop')}
				</StyledButton>,
			]}>
			<LoadingWrapper isLoading={isLoading}>
				<Form form={form} layout="vertical" onFieldsChange={onChange}>
					<p>{t('content_management.download.file_name')}</p>
					<Row gutter={8}>
						<Col span={6}>
							<Input value={customName} onChange={onChangeCustomName} />
						</Col>
						<Col span={18}>
							{fileNameDetails.length > 3 ? (
								<Tooltip
									getPopupContainer={(triggerNode: HTMLElement) =>
										triggerNode.parentNode as HTMLElement
									}
									title={formatFileName}>
									<p className="default-file-name tooltip"> {formatFileName} </p>
								</Tooltip>
							) : (
								<p className="default-file-name"> {formatFileName} </p>
							)}

							{errorMsg && (
								<StyledErrorMsg>
									<IconInfoTriangle />
									<p>{errorMsg}</p>
								</StyledErrorMsg>
							)}
						</Col>
					</Row>

					{(selectContentList.length > 0 || contentDetailDto) &&
						(fileName !== '' || customName !== '') && (
							<StyledPreviewFileName>
								{previewFileName.map((item: PreviewFileNameDto) => {
									const { fileName: name, contentURI } = item;
									const random = Math.random().toString(36).substr(2, 9);

									return (
										<p
											key={contentURI + '_' + random}
											className="preview-file-name">
											{name}.{formatDownloadFile(item).fileExtension}
										</p>
									);
								})}
							</StyledPreviewFileName>
						)}

					<p>{t('content_management.download.detail_file_name')}</p>

					<Form.List name="detailsFileName">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }) => {
									return (
										<StyledSpace key={key} style={{ marginBottom: 8 }}>
											<Form.Item
												{...restField}
												name={[name, `detailsFileName_${key}`]}>
												<StyledCascader
													allowClear={false}
													onChange={onChangeFileNameDetails}
													popupClassName="details-file-name"
													getPopupContainer={(triggerNode: HTMLElement) =>
														triggerNode.parentNode as HTMLElement
													}
													options={fileNameDetailsOptions}
													placeholder={t(
														'content_management.placeholder.please_select',
													)}
												/>
											</Form.Item>

											<StyleIconRemove className="remove-icon-container">
												<IconRemoveCircle
													onClick={() => {
														remove(name);
														handleClickRemove(name);
													}}
												/>
											</StyleIconRemove>
										</StyledSpace>
									);
								})}
								{fields.length < 6 && (
									<StyledButtonText
										isDisabled={!isAddNew}
										type="text"
										onClick={() => add()}
										block
										icon={<IconAddCircle />}>
										{t('content_management.download.add_new_field')}
									</StyledButtonText>
								)}
							</>
						)}
					</Form.List>
				</Form>
			</LoadingWrapper>
		</StyledModal>
	);
};

export default DownloadModal;
