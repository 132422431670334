import { WidgetLinkDto } from '@models/common/summary';

export type ClientSettingsType = {
	hashtags: string;
	subHashtags: string[];
	instagramMentionUsernames: string[];
	twitterMentionUsernames: string[];
	defaultMessageEmail: string;
	defaultMessageMobile: string;
	badWords: string[];
	defaultBadWords: string[];
	keywords: string[];
	channels: string[];
	calendars: string[];
	labels: string[];
	flags: string[];
	endCursor: string;
	featuresDisabled: string[];
	listExclude: BlockedSocialUsernameType[];
	reviewersLimit: number;
	groupsLimit: number;
	calendarsLimit: number;
	channelsLimit: number;
	hashtagsLimit: number;
	keywordsLimit: number;
	socialMediaLimit: number;
	mentionUsernameLimit: number;
	showNewSelectsNotMessaged: boolean;
	autoCopyDefaultMessage: boolean;
	defaultWidgetLink: string;
	defaultRegisterLink: string;
	defaultGalleryLink: string;
	logoUrl: string;
	senderAddress: string;
	widgetLinks: WidgetLinkDto[];
	instagramSearchEnabled: boolean;
	tiktokSearchEnabled: boolean;
	ambassadorEnabled: boolean;
	ambassadorMetricsRefreshUpdateAt: number;
	ambassadorRefreshMetricsEnabled: boolean;
	ambassadorMetricsWaitingPeriod: number;
};

export type BlockedSocialUsernameType = {
	id: number;
	username: string;
	type: string;
	creatorId: number | null;
};

const sidebarTypes = {
	SIDEBAR_REQUEST: 'SIDEBAR_REQUEST',
	SIDEBAR_SUCCEEDED: 'SIDEBAR_SUCCEEDED',
	SIDEBAR_FAILED: 'SIDEBAR_FAILED',

	CLIENT_SETTINGS_REQUEST: 'CLIENT_SETTINGS_REQUEST',
	CLIENT_SETTINGS_COMPLETED: 'CLIENT_SETTINGS_COMPLETED',
	CLIENT_SETTINGS_FAILED: 'CLIENT_SETTINGS_FAILED',
	CHANGE_CLIENT_SETTING: 'CHANGE_CLIENT_SETTING',
	CHANGE_FIELDS_CLIENT_SETTING: 'CHANGE_FIELDS_CLIENT_SETTING',

	FOLDER_SYSTEM_REQUEST: 'FOLDER_SYSTEM_REQUEST',
	FOLDER_SYSTEM_COMPLETED: 'FOLDER_SYSTEM_COMPLETED',
	FOLDER_SYSTEM_FAILED: 'FOLDER_SYSTEM_FAILED',

	PENDO_INIT_REQUEST: 'PENDO_INIT_REQUEST',
	PENDO_INIT_SUCCEEDED: 'PENDO_INIT_SUCCEEDED',
};

export default sidebarTypes;
