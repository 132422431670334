import { ColumnBlock, ColumnGridBlock } from '@components/LandingPageBuilder/Column';
import { ContainerBlock } from '@components/LandingPageBuilder/Container';
import { CustomHTMLBlock } from '@components/LandingPageBuilder/CustomHTML';
import { GalleryTagBlock } from '@components/LandingPageBuilder/GalleryTag';
import { HeaderBlock } from '@components/LandingPageBuilder/Heading';
import { ImageBlock } from '@components/LandingPageBuilder/Image';
import { ListBlock } from '@components/LandingPageBuilder/List';
import { TextBlock } from '@components/LandingPageBuilder/Text';
import { UploaderButtonBlock } from '@components/LandingPageBuilder/UploaderButton';

export default (editor: any, labels: any) => {
	const bm = editor.BlockManager;

	HeaderBlock(bm, labels.heading);
	TextBlock(bm, labels.text);
	ListBlock(bm, labels.bulletedList);
	ImageBlock(bm, labels.image);
	UploaderButtonBlock(bm, labels.uploaderButton);
	GalleryTagBlock(bm, labels.galleryIframe);
	ColumnGridBlock(bm, labels.columns);
	ColumnBlock(bm, labels.column);
	ContainerBlock(bm, labels.container);
	CustomHTMLBlock(bm, labels.customHTML);
};
