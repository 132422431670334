import { TYPE_GALLERY } from '@constants/content/contentLibrary';
import { FolderDto } from '@models/settings/content';

const isInList = (list: any[], selectedRow: number) => {
	const array = list.filter((item: any) => item.id === selectedRow);

	return array.length === 1 ? true : false;
};

const onRemoveKey = (array: React.Key[], idx: number) =>
	array.filter((item: React.Key) => item !== idx);
const onPushKeyToTop = (array: React.Key[], idx: number) => [idx, ...array];

const filterGalleryUnAssign = (folders: FolderDto[], hasAlbum?: boolean) => {
	return folders?.filter(
		(item) =>
			(item?.type === TYPE_GALLERY.default || item?.type === TYPE_GALLERY.custom) &&
			(!!hasAlbum || (!item?.albumAssigned && item?.albumAssigned !== 0)),
	);
};

export { isInList, onRemoveKey, onPushKeyToTop, filterGalleryUnAssign };
