import { THUMBNAIL_BEHAVIOR } from '@constants/content/contentLibrary';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { useSelector } from 'react-redux';

export const HighlightPendingContent = (props: { content?: ContentDto }) => {
	const { content } = props;
	const { thumbnailBehavior } = useSelector((state: any) => state.content);
	const isPending = content?.review.pending || undefined;
	if (thumbnailBehavior.includes(THUMBNAIL_BEHAVIOR.HIGHLIGHT_PENDING_CONTENT) && isPending) {
		return <div className="overlay" />;
	}
	return null;
};
