/**
 * @prettier
 */

import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';

import { API_TREMENDOUS_INFO, API_TREMENDOUS_ORDER, API_TREMENDOUS_SETTING } from '@constants/APIs';
import genericApiSaga from '@helpers/genericApiSaga';
import { getRequest, postRequest } from '@helpers/requestHelpers';

import * as actions from './tremendous.actions';
import {
	createOrderTremendousTypes,
	fetchTremendousInfoTypes,
	saveTremendousDefaultTypes,
} from './tremendous.types';
import { TremendousRequestDefaultTypes } from '@models/settings/tremendous/store';
import { generalMessage } from '@utils/renderComponent';
import { DEFAULT_ERROR } from '@constants/errors';
import { MESSAGE_TYPE } from '@constants/common';
import { TremendousOrderRequestTypes } from '@models/settings/tremendous';

export function* fetchTremendousInfo({
	payload,
}: Action<{ apiKey?: string; isDefault?: boolean }>) {
	const { apiKey, isDefault = false } = payload;
	const url = apiKey ? `${API_TREMENDOUS_INFO}?apiKey=${apiKey}` : API_TREMENDOUS_INFO;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.fetchTremendousInfoSucceeded({ ...response, isDefault }));
		},
		*failed(response: any) {
			yield put(actions.fetchTremendousInfoFailed({ ...response, isDefault }));
			if (!isDefault) {
				generalMessage(response.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
			}
		},
	});
}

export function* saveTremendousDefault({ payload }: Action<TremendousRequestDefaultTypes>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(API_TREMENDOUS_SETTING, payload),
		*completed(response: any) {
			yield put(actions.saveTremendousDefaultSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.saveTremendousDefaultFailed(response));
		},
	});
}

export function* createOrderTremendous({ payload }: Action<TremendousOrderRequestTypes>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(API_TREMENDOUS_ORDER, payload),
		*completed(response: any) {
			yield put(actions.createOrderTremendousSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.createOrderTremendousFailed(response));
		},
	});
}

export function* watcherTremendousSaga() {
	yield takeLatest(fetchTremendousInfoTypes.FETCH_TREMENDOUS_INFO_REQUEST, fetchTremendousInfo);
	yield takeLatest(
		saveTremendousDefaultTypes.SAVE_TREMENDOUS_DEFAULT_REQUEST,
		saveTremendousDefault,
	);
	yield takeLatest(
		createOrderTremendousTypes.CREATE_ORDER_TREMENDOUS_REQUEST,
		createOrderTremendous,
	);
}
