import { createAction } from 'redux-actions';
import {
	changeDataStoreTypes,
	changeDefaultTemplateTypes,
	changeTemplateStatusTypes,
	changeTemplateToWidgetsTypes,
	createTemplateTypes,
	duplicateTemplateTermsTypes,
	fetchDetailTemplateTypes,
	fetchEntribeStandardTermsTypes,
	fetchListTemplateTypes,
	fetchListWidgetLiveTypes,
	fetchTemplateDefaultTypes,
	fetchTemplateHistoryTypes,
	fetchTemplateWidgetTypes,
	fetchWidgetInTemplateTypes,
	updateTemplateTermsTypes,
} from './contentLicensing.types';

// Action function create template
export const createTemplateRequest = createAction(createTemplateTypes.CREATE_TEMPLATE_REQUEST);
export const createTemplateSucceeded = createAction(createTemplateTypes.CREATE_TEMPLATE_SUCCEEDED);
export const createTemplateFailed = createAction(createTemplateTypes.CREATE_TEMPLATE_FAILED);
export const createTemplateEnd = createAction(createTemplateTypes.CREATE_TEMPLATE_END);

// Action function update template
export const updateTemplateTermsRequest = createAction(
	updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_REQUEST,
);
export const updateTemplateTermsSucceeded = createAction(
	updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_SUCCEEDED,
);
export const updateTemplateTermsFailed = createAction(
	updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_FAILED,
);
export const updateTemplateTermsEnd = createAction(
	updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_END,
);

// Action function fetch detail template
export const fetchDetailTemplateRequest = createAction(
	fetchDetailTemplateTypes.FETCH_DETAIL_TEMPLATE_REQUEST,
);
export const fetchDetailTemplateSucceeded = createAction(
	fetchDetailTemplateTypes.FETCH_DETAIL_TEMPLATE_SUCCEEDED,
);
export const fetchDetailTemplateFailed = createAction(
	fetchDetailTemplateTypes.FETCH_DETAIL_TEMPLATE_FAILED,
);

// Action function fetch list template
export const fetchListTemplateRequest = createAction(
	fetchListTemplateTypes.FETCH_LIST_TEMPLATE_REQUEST,
);
export const fetchListTemplateSucceeded = createAction(
	fetchListTemplateTypes.FETCH_LIST_TEMPLATE_SUCCEEDED,
);
export const fetchListTemplateFailed = createAction(
	fetchListTemplateTypes.FETCH_LIST_TEMPLATE_FAILED,
);

// Action function fetch template history
export const fetchTemplateHistoryRequest = createAction(
	fetchTemplateHistoryTypes.FETCH_TEMPLATE_HISTORY_REQUEST,
);
export const fetchTemplateHistorySucceeded = createAction(
	fetchTemplateHistoryTypes.FETCH_TEMPLATE_HISTORY_SUCCEEDED,
);
export const fetchTemplateHistoryFailed = createAction(
	fetchTemplateHistoryTypes.FETCH_TEMPLATE_HISTORY_FAILED,
);

// Action function fetch widget in template
export const fetchWidgetInTemplateRequest = createAction(
	fetchWidgetInTemplateTypes.FETCH_WIDGET_IN_TEMPLATE_REQUEST,
);
export const fetchWidgetInTemplateSucceeded = createAction(
	fetchWidgetInTemplateTypes.FETCH_WIDGET_IN_TEMPLATE_SUCCEEDED,
);
export const fetchWidgetInTemplateFailed = createAction(
	fetchWidgetInTemplateTypes.FETCH_WIDGET_IN_TEMPLATE_FAILED,
);

// Action function fetch list widget live
export const fetchListWidgetLiveRequest = createAction(
	fetchListWidgetLiveTypes.FETCH_LIST_WIDGET_LIVE_TEMPLATE_REQUEST,
);
export const fetchListWidgetLiveSucceeded = createAction(
	fetchListWidgetLiveTypes.FETCH_LIST_WIDGET_LIVE_TEMPLATE_SUCCEEDED,
);
export const fetchListWidgetLiveFailed = createAction(
	fetchListWidgetLiveTypes.FETCH_LIST_WIDGET_LIVE_TEMPLATE_FAILED,
);

// Action function fetch entribe standard terms
export const fetchEntribeStandardTermsRequest = createAction(
	fetchEntribeStandardTermsTypes.FETCH_ENTRIBE_STANDARD_TERMS_REQUEST,
);
export const fetchEntribeStandardTermsSucceeded = createAction(
	fetchEntribeStandardTermsTypes.FETCH_ENTRIBE_STANDARD_TERMS_SUCCEEDED,
);
export const fetchEntribeStandardTermsFailed = createAction(
	fetchEntribeStandardTermsTypes.FETCH_ENTRIBE_STANDARD_TERMS_FAILED,
);

// Action function change template to widget
export const changeTemplateToWidgetsRequest = createAction(
	changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_REQUEST,
);
export const changeTemplateToWidgetsSucceeded = createAction(
	changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_SUCCEEDED,
);
export const changeTemplateToWidgetsFailed = createAction(
	changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_FAILED,
);
export const changeTemplateToWidgetsEnd = createAction(
	changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_END,
);

// Action function change template status
export const changeTemplateStatusRequest = createAction(
	changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_REQUEST,
);
export const changeTemplateStatusSucceeded = createAction(
	changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_SUCCEEDED,
);
export const changeTemplateStatusFailed = createAction(
	changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_FAILED,
);
export const changeTemplateStatusEnd = createAction(
	changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_END,
);

// Action function change template status
export const duplicateTemplateTermsRequest = createAction(
	duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_REQUEST,
);
export const duplicateTemplateTermsSucceeded = createAction(
	duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_SUCCEEDED,
);
export const duplicateTemplateTermsFailed = createAction(
	duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_FAILED,
);
export const duplicateTemplateTermsEnd = createAction(
	duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_END,
);

// Action function change default template
export const changeDefaultTemplateRequest = createAction(
	changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_REQUEST,
);
export const changeDefaultTemplateSucceeded = createAction(
	changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_SUCCEEDED,
);
export const changeDefaultTemplateFailed = createAction(
	changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_FAILED,
);
export const changeDefaultTemplateEnd = createAction(
	changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_END,
);

// Action function fetch template widget
export const fetchTemplateWidgetRequest = createAction(
	fetchTemplateWidgetTypes.FETCH_TEMPLATE_WIDGET_REQUEST,
);
export const fetchTemplateWidgetSucceeded = createAction(
	fetchTemplateWidgetTypes.FETCH_TEMPLATE_WIDGET_SUCCEEDED,
);
export const fetchTemplateWidgetFailed = createAction(
	fetchTemplateWidgetTypes.FETCH_TEMPLATE_WIDGET_FAILED,
);

// Action function fetch template default
export const fetchTemplateDefaultRequest = createAction(
	fetchTemplateDefaultTypes.FETCH_TEMPLATE_DEFAULT_REQUEST,
);
export const fetchTemplateDefaultSucceeded = createAction(
	fetchTemplateDefaultTypes.FETCH_TEMPLATE_DEFAULT_SUCCEEDED,
);
export const fetchTemplateDefaultFailed = createAction(
	fetchTemplateDefaultTypes.FETCH_TEMPLATE_DEFAULT_FAILED,
);

// Action change field data
export const changeFieldDataContentLicensing = createAction(
	changeDataStoreTypes.CHANGE_FIELD_DATA_CONTENT_LICENSING,
);
export const resetStoreContentLicensing = createAction(
	changeDataStoreTypes.RESET_STORE_CONTENT_LICENSING,
);
