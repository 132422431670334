import { MESSAGE_TYPE, TIME_OUT } from '@constants/common';
import { EMAIL_TEMPLATE_FOLDER_TYPES } from '@constants/messages';
import SearchBar from '@cores/SearchBar';
import { LoadingWrapper } from '@cores/index';
import {
	getEmailTemplatesRequest,
	searchMessageTemplate,
	selectTabFolderEmailTemplate,
	storeMessageTemplateData,
} from '@stores/actions';
import {
	StyledForm,
	StyledSearchBlock,
	StyledTabs,
	StyledWrapper,
} from '@styled/Messages/EmailTemplatesStyled';
import { destroyGeneralMessage, generalMessage } from '@utils/renderComponent';
import { Form, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Archived from './Archived';
import Automated from './Automated';
import Custom from './Custom';

const { TabPane } = Tabs;

const FoldersTemplates: React.FC = () => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const searchRef = useRef<any>({});
	const { emailTemplate } = useSelector((state: any) => state.messages);

	const {
		getEmailTemplateStatus: loadingGetTemplates = false,
		custom: { createTemplateLoading = false } = {},
		folder = '',
		error = null,
	} = emailTemplate || {};

	const [templateType, setTemplateType] = useState<string>(EMAIL_TEMPLATE_FOLDER_TYPES.AUTOMATED);

	useEffect(() => {
		getEmailTemplatesByType();
		if (folder !== '1') {
			dispatch(selectTabFolderEmailTemplate({ selectId: '1' }));
		}

		return () => {
			destroyGeneralMessage();
		};
	}, []);

	useEffect(() => {
		if (error) {
			generalMessage(error, MESSAGE_TYPE.ERROR);
		}
	}, [error]);

	const getEmailTemplatesByType = (type: string = EMAIL_TEMPLATE_FOLDER_TYPES.DEFAULT) => {
		dispatch(getEmailTemplatesRequest({ type }));
	};

	const resetSearchedValue = () => {
		if (searchRef?.current && searchRef?.current?.clearSearchBarRef) {
			searchRef?.current?.clearSearchBarRef();
		}
	};

	const onChangeTab = (selectId: string) => {
		resetSearchedValue();
		let templateTypeTemp = EMAIL_TEMPLATE_FOLDER_TYPES.AUTOMATED;
		dispatch(selectTabFolderEmailTemplate({ selectId }));

		if (selectId === '1') {
			getEmailTemplatesByType(EMAIL_TEMPLATE_FOLDER_TYPES.DEFAULT);
		} else if (selectId === '2') {
			getEmailTemplatesByType(EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM);
			templateTypeTemp = EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM;
		} else {
			getEmailTemplatesByType(EMAIL_TEMPLATE_FOLDER_TYPES.ARCHIVED);
			templateTypeTemp = EMAIL_TEMPLATE_FOLDER_TYPES.ARCHIVED;
		}

		setTemplateType(templateTypeTemp);
	};

	const onSearchTemplate = (value: string) => {
		if (value) {
			dispatch(
				storeMessageTemplateData({
					emailTemplate: {
						...emailTemplate,
						getEmailTemplateStatus: true,
					},
				}),
			);
			setTimeout(() => {
				dispatch(
					searchMessageTemplate({
						searchedValue: value.toLowerCase(),
						type: templateType,
					}),
				);
			}, TIME_OUT.VISIBLE_TEXT);
		} else {
			const type =
				templateType === EMAIL_TEMPLATE_FOLDER_TYPES.AUTOMATED
					? EMAIL_TEMPLATE_FOLDER_TYPES.DEFAULT
					: templateType;
			getEmailTemplatesByType(type);
		}
	};

	return (
		<StyledWrapper>
			<StyledSearchBlock>
				<StyledForm form={form}>
					<SearchBar
						ref={searchRef}
						placeholder={t('email_templates.search_templates')}
						enterButton
						width={'100%'}
						onSearch={onSearchTemplate}
					/>
				</StyledForm>
			</StyledSearchBlock>
			<LoadingWrapper isLoading={loadingGetTemplates || createTemplateLoading}>
				<StyledTabs defaultActiveKey="1" onChange={onChangeTab}>
					<TabPane tab="Defaults" key="1">
						<Automated />
					</TabPane>
					<TabPane tab="Custom" key="2">
						<Custom />
					</TabPane>
					<TabPane tab="Archived" key="3">
						<Archived />
					</TabPane>
				</StyledTabs>
			</LoadingWrapper>
		</StyledWrapper>
	);
};

export default FoldersTemplates;
