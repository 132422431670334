import TremendousModalContext from '@contexts/Settings/SettingIntegrations/TremendousModal';
import { StyledStepItem, StyledSteps } from '@styled/Content/WidgetManager';
import {
	StyledHeaderStep,
	StyledNumberStep,
	StyledProgressDone,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const HeaderStep = () => {
	const { t } = useTranslation();

	const { currentStep, handleChangeAction } = useContext(TremendousModalContext);

	const listStep = [t('tremendous.amount'), t('tremendous.campaign'), t('tremendous.review')];

	return (
		<StyledHeaderStep style={{ padding: 0, border: 0 }}>
			<StyledSteps
				current={currentStep}
				percent={60}
				labelPlacement="vertical"
				onChange={handleChangeAction}>
				{listStep?.map((item, idx) => {
					const otherPropStepItem: any = {};
					if (idx < currentStep) {
						otherPropStepItem.icon = (
							<StyledProgressDone
								type="circle"
								width={40}
								strokeWidth={4}
								percent={100}
								format={() => <StyledNumberStep>{idx + 1}</StyledNumberStep>}
							/>
						);
					}
					return <StyledStepItem {...otherPropStepItem} key={item} title={item} />;
				})}
			</StyledSteps>
		</StyledHeaderStep>
	);
};

export default HeaderStep;
