import { PLACEHOLDER_IMAGE_IN_BASE64 } from '@constants/common';
import { ACCEPT_FILES, SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { StyledVideo } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { getSocialS3URL, handleImageLoadedError, handleVideoLoadedError } from '@utils/common';
import React, { useEffect, useRef, useState } from 'react';

type PropTypes = {
	currentMedia: ContentDto;
};

const FullScreenVideo = (props: PropTypes) => {
	const { currentMedia } = props;
	const refError = useRef<any>(true);
	const refVideo = useRef<HTMLVideoElement | null>(null);

	const [srcContent, setSrcContent] = useState<string>(getSocialS3URL(currentMedia.contentUrl));

	useEffect(() => {
		if (refVideo?.current && typeof refVideo?.current?.load === 'function') {
			refVideo.current.load();
		}
	}, [srcContent]);

	const processVideoLoadedError = async (e: any, contentDto: ContentDto) => {
		if (!refError.current) {
			return;
		}
		refError.current = false;
		const socialSource = contentDto.social ? contentDto.social.socialSource.toUpperCase() : '';
		if (!contentDto.social) {
			const src = await handleImageLoadedError(
				e,
				currentMedia?.contentUrl,
				currentMedia?.contentExternalUrl,
				null,
				true,
			);
			setSrcContent(src || PLACEHOLDER_IMAGE_IN_BASE64);
		} else if (
			socialSource === SOCIAL_SOURCE.TIKTOK.toUpperCase() ||
			socialSource === SOCIAL_SOURCE.YOUTUBE.toUpperCase()
		) {
			const src = await handleVideoLoadedError(
				e,
				contentDto.social.socialSource.toLowerCase(),
				contentDto.social.permalink,
				contentDto.social,
			);
			setSrcContent(src || PLACEHOLDER_IMAGE_IN_BASE64);
		} else if (contentDto.social.socialSource === SOCIAL_SOURCE.INSTAGRAM.toUpperCase()) {
			const src = await handleVideoLoadedError(
				e,
				SOCIAL_SOURCE.INSTAGRAM,
				contentDto.social.permalink,
				contentDto.social,
			);
			setSrcContent(src || PLACEHOLDER_IMAGE_IN_BASE64);
		}
	};
	return (
		<StyledVideo
			ref={refVideo}
			onError={(e) => processVideoLoadedError(e, currentMedia)}
			controls>
			<source src={`${srcContent}#t=0.01`} type={ACCEPT_FILES.MINE_TYPE_MP4} />
		</StyledVideo>
	);
};

export default FullScreenVideo;
