import { createAction } from 'redux-actions';
import {
	generateReportTypes,
	getDashboardTypes,
	saveExpandDashboardTypes,
	saveFilterDashboardDefaultTypes,
	saveGridColDashboardTypes,
} from './dashboard.types';

// Generate report

export const generateReport = createAction(generateReportTypes.GENERATE_REPORT_REQUEST);
export const generateReportCompleted = createAction(generateReportTypes.GENERATE_REPORT_COMPLETED);
export const generateReportFailed = createAction(generateReportTypes.GENERATE_REPORT_FAILED);
export const generateReportEnd = createAction(generateReportTypes.GENERATE_REPORT_END);

// Update state
export const getDashboard = createAction(getDashboardTypes.GET_DASHBOARD);

// Save campaign Id default
export const saveFilterDashboardDefault = createAction(
	saveFilterDashboardDefaultTypes.SAVE_FILTER_DASHBOARD_DEFAULT,
);

// Save expand dashboard
export const saveExpandDashboard = createAction(saveExpandDashboardTypes.SAVE_EXPAND_DASHBOARD);
export const saveGridColDashboard = createAction(saveGridColDashboardTypes.SAVE_GRID_COL_DASHBOARD);
