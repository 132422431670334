import PreviewDetailWidget from '@components/Content/WidgetManager/WidgetBuilder/PreviewDetailWidget';
import SettingDetailWidget from '@components/Content/WidgetManager/WidgetBuilder/SettingDetailWidget';
import { MESSAGE_TYPE, ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import {
	DEFAULT_ADVANCE_SETTING_WIDGET,
	DEFAULT_TIME_OUT_WIDGET,
	TAB_WIDGET,
} from '@constants/content/widgetManager';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { ConfirmModal } from '@cores/Modal';
import { LoadingWrapper } from '@cores/index';
import {
	MetadataWidgetBuilder,
	TermConditionItemDto,
	WidgetBuilderDetailDto,
	WidgetBuilderDetailRequestDto,
} from '@models/content/widgetManager';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import { ParamSaveAndCaptureWidget } from '@models/content/widgetManager/summary';
import { TermsTypes } from '@models/settings/contentLicensing';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';
import {
	changeFieldDataContentLicensing,
	clientSettingsRequest,
	fetchDetailLiveWidgetRequest,
	fetchDetailTemplateRequest,
	fetchDetailWidgetBuilderRequest,
	publishWidgetBuilderEnd,
	publishWidgetBuilderRequest,
	saveDraftWidgetBuilderEnd,
	saveDraftWidgetBuilderRequest,
	updateStoreWidget,
} from '@stores/actions';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledEmpty, StyledWrapper } from '@styled/Content/WidgetManager';
import { randomId } from '@utils/common';
import { destroyGeneralMessage, generalMessage } from '@utils/renderComponent';
import { Row, message } from 'antd';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SettingWidgetManager = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const history = useHistory();
	const { pathname, state } = history?.location as any;

	const refStep = useRef<any>({});

	const {
		statusDraftWidgetBuilder,
		statusGetWidgetDetail,
		statusUploadLogo,
		widgetBuilder,
		statusPublishWidget,
		loadingWidget = false,
		liveWidget,
		loadingCustomFieldWidget,
		error = '',
	}: WidgetManagerStoreDto = useSelector((state: any) => state?.widgetManager);
	const { loadingDetail }: ContentLicensingStoreDto = useSelector(
		(state: any) => state?.contentLicensing,
	);

	const [currentStep, setCurrentStep] = useState<number>(0);
	const [isMobile, setIsMobile] = useState<boolean>(false);

	const [typeConfirm, setTypeConfirm] = useState<string>('');

	const [funcCallBack, setFuncCallBack] = useState<any>(null);

	const [detailWidget, setDetailWidget] = useState<WidgetBuilderDetailDto | any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [termsSelected, setTermsSelected] = useState<TermsTypes | null>(null);
	const [hasChangeCustomField, setHasChangeCustomField] = useState<boolean>(false);
	const [dataPublishNoDraft, setDataPublishNoDraft] =
		useState<WidgetBuilderDetailRequestDto | null>(null);

	useEffect(() => {
		return () => {
			destroyGeneralMessage();
			dispatch(updateStoreWidget({ widgetBuilder: null }));
			dispatch(
				changeFieldDataContentLicensing({
					templateDefault: null,
					templateDetail: null,
				}),
			);
			setHasChangeCustomField(false);
			setDataPublishNoDraft(null);
		};
	}, []);

	useEffect(() => {
		if (state?.step) {
			setCurrentStep(state?.step - 1);
			history.replace({ state: {} });
		}
	}, [state]);

	const handleCloseConfirm = () => {
		setTypeConfirm('');
		setDataPublishNoDraft(null);
	};

	const handleCancelPopup = () => {
		handleCloseConfirm();
		switch (typeConfirm) {
			case TYPE_ACTIONS.EXIT:
				return history.push(ROUTE_PATH.WIDGET_MANAGER);
			default:
				break;
		}
		return null;
	};

	const handleSaveAndCaptureThumbnail = ({ current, callBack }: ParamSaveAndCaptureWidget) => {
		setIsLoading(true);
		html2canvas(document.getElementById('widget_detail_thumbnail') || document.body, {
			useCORS: true,
			allowTaint: false,
		}).then((canvas) => {
			const thumbnail: any = canvas
				.toDataURL('image/png')
				.replace('image/png', 'image/octet-stream');

			const { termsConditions = [], advancedSettings = {} } =
				detailWidget?.metadata?.body || {};
			const {
				googleTranslate,
				confirmationButtonText,
				confirmationMessage,
				visibleSocialButtons = false,
				socialMessage,
			} = advancedSettings || {};
			const listTermUpdateId: TermConditionItemDto = termsConditions?.map(
				(item: TermConditionItemDto) => {
					return { ...item, id: randomId() };
				},
			);

			const advancedSettingsChange = {
				googleTranslate: googleTranslate || DEFAULT_ADVANCE_SETTING_WIDGET.GOOGLE_TRANSLATE,
				confirmationMessage:
					confirmationMessage || DEFAULT_ADVANCE_SETTING_WIDGET.CONFIRMATION_MESSAGE,
				confirmationButtonText:
					confirmationButtonText ||
					DEFAULT_ADVANCE_SETTING_WIDGET.CONFIRMATION_BUTTON_TEXT,
				visibleSocialButtons,
				socialMessage: socialMessage || DEFAULT_ADVANCE_SETTING_WIDGET.SOCIAL_MESSAGE,
			};

			const detailRequest: WidgetBuilderDetailDto = {
				...detailWidget,
				metadata: {
					...detailWidget?.metadata,
					header: {
						...detailWidget?.metadata?.header,
						message: detailWidget?.metadata?.header?.message?.trim(),
						title: detailWidget?.metadata?.header?.title?.trim(),
					},
					body: {
						...detailWidget?.metadata?.body,
						termsConditions: listTermUpdateId,
						advancedSettings: {
							...advancedSettingsChange,
						},
					},
					otherInfo: {
						...detailWidget?.metadata?.otherInfo,
						timeout: DEFAULT_TIME_OUT_WIDGET,
					},
					thumbnail,
				},
			};

			const dataRequest: WidgetBuilderDetailRequestDto = {
				...detailRequest,
				metadata: JSON.stringify(detailRequest?.metadata),
			};

			const paramsRequest: any = { dataRequest };
			if (typeof current === 'number') {
				paramsRequest.callBack = () => setCurrentStep(current);
			}

			if (callBack && typeof callBack === 'function') {
				paramsRequest.callBack = callBack;
			}

			dispatch(saveDraftWidgetBuilderRequest(paramsRequest));
		});
	};

	const onSubmitConfirm = () => {
		setTypeConfirm('');

		switch (typeConfirm) {
			case TYPE_ACTIONS.EXIT:
				return handleSaveAndExit();
			case TYPE_ACTIONS.PUBLISH:
				return handlePublishWidget();
			case TYPE_ACTIONS.CHANGE_CUSTOM_FIELD:
				return handlePublishWidget(dataPublishNoDraft);
			case TYPE_ACTIONS.REMOVE_TEMPLATE:
				setTermsSelected(null);
				dispatch(
					changeFieldDataContentLicensing({
						templateDetail: null,
					}),
				);

				return setDetailWidget({ ...detailWidget, termsTemplateId: null });
			default:
				break;
		}
		return null;
	};

	const handlePublishWidget = (dataPublish?: WidgetBuilderDetailRequestDto | null) => {
		if (dataPublish) {
			dispatch(
				publishWidgetBuilderRequest({
					...dataPublish,
				}),
			);
			setDataPublishNoDraft(null);
			return;
		}
		const { url, metadata, referenceId, ...otherDetail } = detailWidget;

		const dataRequest: WidgetBuilderDetailDto = {
			...otherDetail,
			status: TAB_WIDGET.live,
			url,
			metadata: { ...metadata, otherInfo: { ...metadata?.otherInfo } },
		};
		dispatch(
			publishWidgetBuilderRequest({
				...dataRequest,
				metadata: JSON.stringify(dataRequest?.metadata),
			}),
		);
	};

	const handleSaveAndExit = () => {
		const callBack = () => {
			dispatch(saveDraftWidgetBuilderEnd());
			handleCancelPopup();
		};
		setFuncCallBack(() => callBack);

		if (refStep?.current?.handleChangeAction && currentStep < 4) {
			refStep?.current?.handleChangeAction(currentStep);
		} else {
			handleSaveAndCaptureThumbnail({});
		}
	};

	const hasChangeWidget = useMemo(() => {
		if (detailWidget && widgetBuilder) {
			const widgetBuilderDefault = {
				...widgetBuilder,
				metadata: JSON.parse(widgetBuilder?.metadata),
			};

			return !_.isEqual(detailWidget, widgetBuilderDefault);
		}
		return false;
	}, [widgetBuilder, detailWidget]);

	useEffect(() => {
		if (detailWidget?.termsTemplateId) {
			dispatch(fetchDetailTemplateRequest(detailWidget?.termsTemplateId));
		}
	}, [detailWidget?.termsTemplateId]);

	const handleUpdateDetail = () => {
		if (widgetBuilder) {
			const { metadata, ...otherDetail } = widgetBuilder;
			const metadataObj: MetadataWidgetBuilder = JSON.parse(metadata);
			const dataResp: WidgetBuilderDetailDto = {
				...otherDetail,
				metadata: metadataObj,
			};

			setDetailWidget(dataResp);
			const referenceIdChange = widgetBuilder?.referenceId;
			const oldReferenceIdChange = detailWidget?.referenceId;
			if (referenceIdChange && referenceIdChange !== oldReferenceIdChange) {
				dispatch(fetchDetailLiveWidgetRequest({ widgetId: +referenceIdChange }));
			}
		}
	};

	useEffect(() => {
		const id = pathname?.slice(pathname.lastIndexOf('/') + 1);
		if (pathname && id) {
			dispatch(fetchDetailWidgetBuilderRequest({ widgetId: +id }));
		}
	}, [pathname]);

	useEffect(() => {
		if (statusGetWidgetDetail === SUCCEEDED) {
			handleUpdateDetail();
		}
	}, [statusGetWidgetDetail]);

	useEffect(() => {
		if (statusDraftWidgetBuilder === SUCCEEDED) {
			handleUpdateDetail();
			message.success(t('content.widget_manager.message.change_success'));
		}

		if (statusDraftWidgetBuilder === SUCCEEDED || statusDraftWidgetBuilder === FAILED) {
			if (funcCallBack && typeof funcCallBack === 'function') {
				funcCallBack();
				setFuncCallBack(null);
			}
		}
	}, [statusDraftWidgetBuilder]);

	useEffect(() => {
		const listStatus = [statusGetWidgetDetail, statusDraftWidgetBuilder];
		if (listStatus?.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus?.includes(FAILED)) {
			message.error(error);
		}
		if (listStatus?.includes(SUCCEEDED) || listStatus?.includes(FAILED)) {
			dispatch(saveDraftWidgetBuilderEnd());
			setIsLoading(false);
		}
	}, [statusGetWidgetDetail, statusDraftWidgetBuilder]);

	useEffect(() => {
		if (statusPublishWidget === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (statusPublishWidget === SUCCEEDED) {
			message.success(t('content.widget_manager.message.publish_success'));
			history.push(ROUTE_PATH.WIDGET_MANAGER);
			dispatch(clientSettingsRequest());
		}
		if (statusPublishWidget === FAILED) {
			generalMessage(error, MESSAGE_TYPE.ERROR);
		}
		if (statusPublishWidget === SUCCEEDED || statusPublishWidget === FAILED) {
			dispatch(publishWidgetBuilderEnd());
			setIsLoading(false);
		}
	}, [statusPublishWidget]);

	const otherPropConfirm = useMemo(() => {
		const result: any = {
			props: {
				okText: t('content.widget_manager.button.save_and_exit'),
				cancelText: t('content.widget_manager.button.exit_without_save'),
			},
			content: t('content.widget_manager.popup.message_exit'),
		};

		if (typeConfirm === TYPE_ACTIONS.CHANGE_CUSTOM_FIELD) {
			result.props.okText = t('content.widget_manager.button.publish');
			result.props.cancelText = t('content.widget_manager.button.cancel');
			result.content = t('content.widget_manager.popup.change_custom_field_all_content');
		}
		if (typeConfirm === TYPE_ACTIONS.PUBLISH) {
			result.props.okText = t('content.widget_manager.button.publish');
			result.props.cancelText = t('content.widget_manager.button.cancel');
			result.content = (
				<>
					{hasChangeCustomField && (
						<>
							{t('content.widget_manager.popup.change_custom_field_all_content')}
							<br /> <br />
						</>
					)}
					{t('content.widget_manager.popup.publish_change_live')}
					<br />
					{t('content.widget_manager.popup.are_you_sure_proceed')}
				</>
			);
			if (detailWidget?.url !== liveWidget?.url) {
				result.props.okText = t('content.widget_manager.button.publish_new_url');
				result.content = (
					<>
						{hasChangeCustomField && (
							<>
								{t('content.widget_manager.popup.change_custom_field_all_content')}
								<br /> <br />
							</>
						)}
						{t('content.widget_manager.popup.publish_change_url')}
						<br />
						{t('content.widget_manager.popup.are_you_sure_proceed')}
					</>
				);
			}
		}
		if (typeConfirm === TYPE_ACTIONS.REMOVE_TEMPLATE) {
			result.content = (
				<StyledWrapperContent textAlign="left">
					{t('content_licensing.message.sub_remove_template')} <br /> <br />
					{t('content_licensing.message.quest_remove_template')}
				</StyledWrapperContent>
			);
			result.props.okText = t('button.remove_terms');
			result.props.cancelText = t('content.widget_manager.button.cancel');
		}
		return result;
	}, [typeConfirm]);

	const valueContext = {
		currentStep,
		setCurrentStep,
		detailWidget,
		setDetailWidget,
		isMobile,
		setIsMobile,
		setTypeConfirm,
		hasChangeWidget,
		handleSaveAndCaptureThumbnail,
		setIsLoading,
		termsSelected,
		setTermsSelected,
		hasChangeCustomField,
		setHasChangeCustomField,
		dataPublishNoDraft,
		setDataPublishNoDraft,
	};

	return (
		<WidgetBuilderContext.Provider value={valueContext}>
			<LoadingWrapper
				isLoading={
					statusUploadLogo === IN_PROGRESS ||
					isLoading ||
					loadingWidget ||
					loadingDetail ||
					loadingCustomFieldWidget
				}>
				{detailWidget ? (
					<Row gutter={[16, 16]}>
						<StyledWrapper span={8}>
							<SettingDetailWidget ref={refStep} />
						</StyledWrapper>

						<StyledWrapper span={16}>
							<PreviewDetailWidget />
						</StyledWrapper>
					</Row>
				) : (
					<StyledEmpty />
				)}

				<ConfirmModal
					width={450}
					isOpen={typeConfirm ? true : false}
					confirmLoading={statusDraftWidgetBuilder === IN_PROGRESS || isLoading}
					onClose={() => handleCloseConfirm()}
					onClickBtnCancel={handleCancelPopup}
					onSubmit={onSubmitConfirm}
					{...otherPropConfirm.props}>
					{otherPropConfirm?.content}
				</ConfirmModal>
			</LoadingWrapper>
		</WidgetBuilderContext.Provider>
	);
};

export default SettingWidgetManager;
