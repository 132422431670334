import { PATTERNS } from '@constants/common';
import {
	FORM_CONTENT_DETAIL,
	MEDIA_TYPE,
	SELECTOR_ELEMENTS,
} from '@constants/content/contentLibrary';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { ContentRootStateDto, ContentStoreDto } from '@models/content/contentLibrary/store';
import { StyledText, StyledWrapperContent } from '@styled/Common/CommonStyled';
import {
	StyledContentFlagged,
	StyledContentFlags,
} from '@styled/ContentLibrary/ContentDetailStyled';
import { validateInputToPattern } from '@utils/common';
import { sortNumber } from '@utils/funcHelper';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FormItemSelect from '../FormItemSelect';
import VideoLabelDetection from '../VideoLabelDetection';

type ContentFlagsProps = {
	otherPropSelectFlag: any;
};

const ContentFlags = (props: ContentFlagsProps) => {
	const { t } = useTranslation();
	const { optionFlags, currentMedia } = useContext(ContentDetailContext);
	const { otherPropSelectFlag } = props;
	const { mediaType } = currentMedia || {};
	const { contentDetection }: ContentStoreDto = useSelector(
		(state: ContentRootStateDto) => state.content,
	);

	const { videoExplicitContentDetection } = contentDetection || {};
	const { flags } = currentMedia?.review || {};

	const renderCaution = () => {
		return (
			flags &&
			flags?.length > 0 && (
				<StyledContentFlagged>
					<StyledText
						margin="14px 0"
						dangerouslySetInnerHTML={{ __html: t('content_detail.caution') }}
					/>
				</StyledContentFlagged>
			)
		);
	};

	return (
		<StyledContentFlags>
			{renderCaution()}
			<FormItemSelect
				mode="tags"
				optionData={optionFlags || []}
				name={FORM_CONTENT_DETAIL.FLAGS}
				label={t('content_detail.flags')}
				otherPropSelect={{
					...otherPropSelectFlag,
					onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
						validateInputToPattern(event, PATTERNS.FLAG);
					},
				}}
			/>
			{mediaType === MEDIA_TYPE.VIDEO &&
				videoExplicitContentDetection &&
				videoExplicitContentDetection?.length > 0 && (
					<StyledWrapperContent margin="0 0 16px 0">
						<VideoLabelDetection
							idElm={SELECTOR_ELEMENTS.VIDEO_CONTENT_DETAIL_ID}
							dataDetections={[
								{
									description: t(
										'content_management.media_recognition.explicit_content',
									),
									times: videoExplicitContentDetection.sort(sortNumber),
								},
							]}
						/>
					</StyledWrapperContent>
				)}
		</StyledContentFlags>
	);
};

export default ContentFlags;
