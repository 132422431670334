import { TooltipContent } from '@components/ContentGlobal/ContentDetail/AdditionalInfo';
import THEME from '@constants/themes/themes';
import {
	CustomFieldsContentDto,
	DataInfoAdditionalType,
	DataItemAdditionalType,
} from '@models/content/contentLibrary/summary';
import { StyledTitle, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { formatDataAdditional } from '@utils/content';
import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const StyledTagItem = styled.div`
	min-width: 50px;
	max-width: 90px;
	padding: 2px 6px;
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue3};
	color: ${THEME.colors.gray5};
	margin-right: 5px;
	margin-bottom: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
`;

type PropTypes = {
	additionalFields: CustomFieldsContentDto[];
};

const AdditionalContentGallery = (props: PropTypes) => {
	const { additionalFields } = props;
	const dataInfo: DataInfoAdditionalType = useMemo(() => {
		const result: DataInfoAdditionalType = formatDataAdditional(additionalFields);

		return result;
	}, [additionalFields]);

	const renderDataItem = (data: DataItemAdditionalType[], isMulti?: boolean) => {
		return data?.map((item) => (
			<Col span={24} key={Math.random()}>
				<StyledTitle className="unset_height" color={THEME.colors.white} fontWeight="700">
					{item?.name}
				</StyledTitle>
				{isMulti && (
					<StyledWrapperContent margin="4px 0 0 0">
						{item?.values?.map((val) => (
							<TooltipContent key={Math.random()} name={val}>
								<StyledTagItem>{val}</StyledTagItem>
							</TooltipContent>
						))}
					</StyledWrapperContent>
				)}
				{!isMulti && (
					<TooltipContent name={item?.values[0]}>
						<StyledTitle className="unset_height" color={THEME.colors.white}>
							{item?.values[0]}
						</StyledTitle>
					</TooltipContent>
				)}
			</Col>
		));
	};

	return (
		<StyledWrapperContent margin="0 0 10px 0" width="100%">
			<Row gutter={[18, 18]}>
				{renderDataItem(dataInfo.single)}
				{renderDataItem(dataInfo.multi, true)}
				{renderDataItem(dataInfo.paragraph)}
			</Row>
		</StyledWrapperContent>
	);
};

export default AdditionalContentGallery;
