import { createAction } from 'redux-actions';

// Action constant
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_COMPLETED = 'USER_LOGIN_COMPLETED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGIN_END = 'USER_LOGIN_END';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_COMPLETED = 'USER_LOGOUT_COMPLETED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const SWITCH_CLIENT = 'SWITCH_CLIENT';
export const ACTIVE_ACCOUNT = 'ACTIVE_ACCOUNT';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const RENEW_INVITE_ACCOUNT = 'RENEW_INVITE_ACCOUNT';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_COMPLETED = 'CHANGE_PASSWORD_COMPLETED';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_COMPLETED = 'UPDATE_PROFILE_COMPLETED';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const SEND_EMAIL_RESET_PWD = 'SEND_EMAIL_RESET_PWD';
export const SEND_EMAIL_RESET_PWD_COMPLETED = 'SEND_EMAIL_RESET_PWD_COMPLETED';
export const SEND_EMAIL_RESET_PWD_FAILED = 'SEND_EMAIL_RESET_PWD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_COMPLETED = 'RESET_PASSWORD_COMPLETED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const CHECK_TOKEN_RESET = 'CHECK_TOKEN_RESET';
export const CHECK_TOKEN_RESET_COMPLETED = 'CHECK_TOKEN_RESET_COMPLETED';
export const CHECK_TOKEN_RESET_FAILED = 'CHECK_TOKEN_RESET_FAILED';

export const GET_EMAIL_RESET = 'GET_EMAIL_RESET';
export const GET_EMAIL_RESET_COMPLETED = 'GET_EMAIL_RESET_COMPLETED';
export const GET_EMAIL_RESET_FAILED = 'GET_EMAIL_RESET_FAILED';

export const GET_ACCOUNT_USER_PERMISSION = 'GET_ACCOUNT_USER_PERMISSION';
export const GET_ACCOUNT_USER_PERMISSION_SUCCEEDED = 'GET_ACCOUNT_USER_PERMISSION_SUCCEEDED';
export const GET_ACCOUNT_USER_PERMISSION_FAILED = 'GET_ACCOUNT_USER_PERMISSION_FAILED';
export const GET_ACCOUNT_USER_PERMISSION_END = 'GET_ACCOUNT_USER_PERMISSION_END';

// MFA - Verify password
export const VERIFY_PASSWORD_REQUEST = 'VERIFY_PASSWORD_REQUEST';
export const VERIFY_PASSWORD_SUCCEEDED = 'VERIFY_PASSWORD_SUCCEEDED';
export const VERIFY_PASSWORD_FAILED = 'VERIFY_PASSWORD_FAILED';
export const VERIFY_PASSWORD_END = 'VERIFY_PASSWORD_END';

// MFA - Register authenticator app
export const PAIR_AUTHENTICATOR_APP_REQUEST = 'PAIR_AUTHENTICATOR_APP_REQUEST';
export const PAIR_AUTHENTICATOR_APP_SUCCEEDED = 'PAIR_AUTHENTICATOR_APP_SUCCEEDED';
export const PAIR_AUTHENTICATOR_APP_FAILED = 'PAIR_AUTHENTICATOR_APP_FAILED';
export const PAIR_AUTHENTICATOR_APP_END = 'PAIR_AUTHENTICATOR_APP_END';

// MFA - Confirm registration authenticator app
export const CONFIRM_MFA_PAIRING_REQUEST = 'CONFIRM_MFA_PAIRING_REQUEST';
export const CONFIRM_MFA_PAIRING_SUCCEEDED = 'CONFIRM_MFA_PAIRING_SUCCEEDED';
export const CONFIRM_MFA_PAIRING_FAILED = 'CONFIRM_MFA_PAIRING_FAILED';
export const CONFIRM_MFA_PAIRING_END = 'CONFIRM_MFA_PAIRING_END';

// MFA - Remove authenticator app
export const REMOVE_AUTHENTICATOR_APP_REQUEST = 'REMOVE_AUTHENTICATOR_APP_REQUEST';
export const REMOVE_AUTHENTICATOR_APP_SUCCEEDED = 'REMOVE_AUTHENTICATOR_APP_SUCCEEDED';
export const REMOVE_AUTHENTICATOR_APP_FAILED = 'REMOVE_AUTHENTICATOR_APP_FAILED';
export const REMOVE_AUTHENTICATOR_APP_END = 'REMOVE_AUTHENTICATOR_APP_END';

// MFA -Login with MFA
export const LOGIN_WITH_MFA_REQUEST = 'LOGIN_WITH_MFA_REQUEST';
export const LOGIN_WITH_MFA_SUCCEEDED = 'LOGIN_WITH_MFA_SUCCEEDED';
export const LOGIN_WITH_MFA_FAILED = 'LOGIN_WITH_MFA_FAILED';
export const LOGIN_WITH_MFA_END = 'LOGIN_WITH_MFA_END';

// MFA - Disable MFA
export const DISABLE_MFA_REQUEST = 'DISABLE_MFA_REQUEST';
export const DISABLE_MFA_SUCCEEDED = 'DISABLE_MFA_SUCCEEDED';
export const DISABLE_MFA_FAILED = 'DISABLE_MFA_FAILED';
export const DISABLE_MFA_END = 'DISABLE_MFA_END';

// get info user
export const GET_INFO_USER_REQUEST = 'GET_INFO_USER_REQUEST';
export const GET_INFO_USER_SUCCEEDED = 'GET_INFO_USER_SUCCEEDED';
export const GET_INFO_USER_FAILED = 'GET_INFO_USER_FAILED';

export const STORE_USER_DATA = 'STORE_USER_DATA';

// Action function
export const userLogin = createAction(USER_LOGIN);
export const userLoginCompleted = createAction(USER_LOGIN_COMPLETED);
export const userLoginFailed = createAction(USER_LOGIN_FAILED);
export const userLoginEnd = createAction(USER_LOGIN_END);

export const userLogout = createAction(USER_LOGOUT);
export const userLogoutCompleted = createAction(USER_LOGOUT_COMPLETED);
export const userLogoutFailed = createAction(USER_LOGOUT_FAILED);

export const getInfoUserRequest = createAction(GET_INFO_USER_REQUEST);
export const getInfoUserSucceeded = createAction(GET_INFO_USER_SUCCEEDED);
export const getInfoUserFailed = createAction(GET_INFO_USER_FAILED);

export const getAccount = createAction(GET_ACCOUNT);
export const switchClient = createAction(SWITCH_CLIENT);
export const activeAccount = createAction(ACTIVE_ACCOUNT);
export const createAccount = createAction(CREATE_ACCOUNT);
export const renewInviteAccount = createAction(RENEW_INVITE_ACCOUNT);

export const changePassword = createAction(CHANGE_PASSWORD);
export const changePasswordCompleted = createAction(CHANGE_PASSWORD_COMPLETED);
export const changePasswordFailed = createAction(CHANGE_PASSWORD_FAILED);

export const updateProfile = createAction(UPDATE_PROFILE);
export const updateProfileSucceeded = createAction(UPDATE_PROFILE_COMPLETED);
export const updateProfileFailed = createAction(UPDATE_PROFILE_FAILED);

export const sendEmailResetPwd = createAction(SEND_EMAIL_RESET_PWD);
export const sendEmailResetPwdCompleted = createAction(SEND_EMAIL_RESET_PWD_COMPLETED);
export const sendEmailResetPwdFailed = createAction(SEND_EMAIL_RESET_PWD_FAILED);

export const resetPassword = createAction(RESET_PASSWORD);
export const resetPasswordCompleted = createAction(RESET_PASSWORD_COMPLETED);
export const resetPasswordFailed = createAction(RESET_PASSWORD_FAILED);

export const checkTokenReset = createAction(CHECK_TOKEN_RESET);
export const checkTokenResetCompleted = createAction(CHECK_TOKEN_RESET_COMPLETED);
export const checkTokenResetFailed = createAction(CHANGE_PASSWORD_FAILED);

export const getEmailReset = createAction(GET_EMAIL_RESET);
export const getEmailResetCompleted = createAction(GET_EMAIL_RESET_COMPLETED);
export const getEmailResetFailed = createAction(GET_EMAIL_RESET_FAILED);

// Action function remove folder
export const getAccountUserPermissionRequest = createAction(GET_ACCOUNT_USER_PERMISSION);
export const getAccountUserPermissionSucceeded = createAction(
	GET_ACCOUNT_USER_PERMISSION_SUCCEEDED,
);
export const getAccountUserPermissionFailed = createAction(GET_ACCOUNT_USER_PERMISSION_FAILED);
export const getAccountUserPermissionEnd = createAction(GET_ACCOUNT_USER_PERMISSION_END);

// MFA - Verify password
export const verifyPasswordRequest = createAction(VERIFY_PASSWORD_REQUEST);
export const verifyPasswordSucceeded = createAction(VERIFY_PASSWORD_SUCCEEDED);
export const verifyPasswordFailed = createAction(VERIFY_PASSWORD_FAILED);
export const verifyPasswordEnd = createAction(VERIFY_PASSWORD_END);

// MFA - Register authenticator app
export const pairAuthenticatorAppRequest = createAction(PAIR_AUTHENTICATOR_APP_REQUEST);
export const pairAuthenticatorAppSucceeded = createAction(PAIR_AUTHENTICATOR_APP_SUCCEEDED);
export const pairAuthenticatorAppFailed = createAction(PAIR_AUTHENTICATOR_APP_FAILED);
export const pairAuthenticatorAppEnd = createAction(PAIR_AUTHENTICATOR_APP_END);

// MFA - Confirm registration authenticator app
export const confirmMFAPairingRequest = createAction(CONFIRM_MFA_PAIRING_REQUEST);
export const confirmMFAPairingSucceeded = createAction(CONFIRM_MFA_PAIRING_SUCCEEDED);
export const confirmMFAPairingFailed = createAction(CONFIRM_MFA_PAIRING_FAILED);
export const confirmMFAPairingEnd = createAction(CONFIRM_MFA_PAIRING_END);

// MFA - Remove authenticator app
export const removeAuthenticatorAppRequest = createAction(REMOVE_AUTHENTICATOR_APP_REQUEST);
export const removeAuthenticatorAppSucceeded = createAction(REMOVE_AUTHENTICATOR_APP_SUCCEEDED);
export const removeAuthenticatorAppFailed = createAction(REMOVE_AUTHENTICATOR_APP_FAILED);
export const removeAuthenticatorAppEnd = createAction(REMOVE_AUTHENTICATOR_APP_END);

// MFA - Login with MFA
export const loginWithMFARequest = createAction(LOGIN_WITH_MFA_REQUEST);
export const loginWithMFASucceeded = createAction(LOGIN_WITH_MFA_SUCCEEDED);
export const loginWithMFAFailed = createAction(LOGIN_WITH_MFA_FAILED);
export const loginWithMFAEnd = createAction(LOGIN_WITH_MFA_END);

// MFA - Disable MFA
export const disableMFARequest = createAction(DISABLE_MFA_REQUEST);
export const disableMFASucceeded = createAction(DISABLE_MFA_SUCCEEDED);
export const disableMFAFailed = createAction(DISABLE_MFA_FAILED);
export const disableMFAEnd = createAction(DISABLE_MFA_END);

export const storeUserData = createAction(STORE_USER_DATA);
