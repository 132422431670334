import { getClientSettings } from '@helpers/userHelpers';

const MAX_FOLDER_NUMBERS = 999;
const ACTIVE_TYPE = ['All', 'Selects', 'Submissions', 'Rejected'];

const validateFolderName = (folders: Array<any>, name: string, editingFolder?: string) => {
	if (!folders || !folders.length) {
		return '';
	}

	name = name.trim();
	// Edit case, remove current editing folder in the list to check duplicate
	if (editingFolder) {
		folders = folders.filter((data: any) => data.name !== editingFolder);
		// Add new
	} else {
		const clientSettings = getClientSettings();
		const rateLimit =
			clientSettings && clientSettings.folderLimit
				? Math.max(clientSettings.folderLimit, MAX_FOLDER_NUMBERS)
				: MAX_FOLDER_NUMBERS;
		if (folders.length >= rateLimit) {
			return `Can only add ${rateLimit} folders`;
		}
	}

	let errMsg = '';
	const folderNameReg = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g);
	const isDuplicatedSystemFolder =
		ACTIVE_TYPE.filter((data) => data.toUpperCase() === name.toUpperCase()).length > 0;
	const isDuplicatedCustomFolder =
		folders.filter((data) => data && data.toUpperCase() === name.toUpperCase()).length > 0;

	if (!name) {
		errMsg = 'Folder name can not be blank';
	} else if (/\s/.test(name)) {
		errMsg = 'Folder name can not contain space';
	} else if (name.length > 15) {
		errMsg = 'Folder name max length is 15';
	} else if (folderNameReg.test(name)) {
		errMsg = 'Folder name can not contain special characters';
	} else if (isDuplicatedCustomFolder || isDuplicatedSystemFolder) {
		errMsg = 'Folder name is duplicated';
	}

	return errMsg;
};

const validateFilterName = (filters: any[], name: string, editingFolder?: string) => {
	name = name.trim();
	// Edit case, remove current editing folder in the list to check duplicate
	if (editingFolder) {
		filters = filters.filter((data: any) => data.name !== editingFolder);
		// Add new
	} else {
		if (filters.length >= MAX_FOLDER_NUMBERS) {
			return `Can only add ${MAX_FOLDER_NUMBERS} filters.`;
		}
	}

	let errMsg = '';

	const isDuplicatedCustomFilter =
		filters.filter((data) => data.name && data.name.toUpperCase() === name.toUpperCase())
			.length > 0;

	if (!name) {
		errMsg = 'form_validation.save_filter.blank_name';
	} else if (name.length > 30) {
		errMsg = 'form_validation.save_filter.max_filter_name';
	} else if (isDuplicatedCustomFilter) {
		errMsg = 'form_validation.save_filter.duplicate_filter_name';
	}

	return errMsg;
};

export { validateFolderName, validateFilterName };
