import {
	AccountPayloadDto,
	InvitedUserDto,
} from '@models/settings/socialSearch/accountuser.ts/store';
import * as React from 'react';

export type AccountUserContextDto = {
	roles: any[];
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
	accountPayload?: AccountPayloadDto;
	setAccountPayload: (accountPayload: AccountPayloadDto) => void;
	selectedRowKeys: React.Key[];
	setSelectedRowKeys: (keys: React.Key[]) => void;
	handleRefreshPage: () => void;
	handleInviteUser: (values: InvitedUserDto) => void;
};

// Default context values
const values: AccountUserContextDto = {
	roles: [],
	setIsLoading: () => {},
	isLoading: false,
	setAccountPayload: () => {},
	selectedRowKeys: [],
	setSelectedRowKeys: () => {},
	handleRefreshPage: () => {},
	handleInviteUser: () => {},
};

const AccountUserContext = React.createContext(values);
export default AccountUserContext;
