/*
 * @prettier
 */
import { Tabs } from 'antd';
import styled from 'styled-components';
import THEME from '../../constants/themes/themes';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
	margin: 0;

	.ant-tabs-nav {
		margin-bottom: 0 !important;
		.ant-tabs-tab {
			padding: 12px 20px;

			&.ant-tabs-tab-active {
				border-bottom-color: ${THEME.colors.primary};
				opacity: 1;
				.ant-tabs-tab-btn {
					color: #fff;
				}
			}
		}

		&:before {
			border-bottom: none;
		}
	}
`;

type TabPaneType = {
	tabId: string;
	title: string;
	content: any;
};

type TabProps = {
	listTabPane: any;
	type?: 'line' | 'card' | 'editable-card';
	size?: 'small' | 'middle' | 'large' | undefined;
	hideAdd?: boolean;
};

const Tab = ({ listTabPane, ...rest }: TabProps) => {
	return (
		<StyledTabs {...rest} defaultActiveKey="0">
			{listTabPane.map((item: TabPaneType) => (
				<TabPane tab={item.title} key={item.tabId}>
					{item.content}
				</TabPane>
			))}
		</StyledTabs>
	);
};

Tab.defaultProps = {
	type: 'line',
	size: 'middle',
	hideAdd: true,
};

export default Tab;
