import AlbumList from '@components/Content/AlbumManager/AlbumList';
import AlbumModal from '@components/Content/AlbumManager/AlbumModal';
import HeaderAlbums from '@components/Content/AlbumManager/HeaderAlbums';
import { mediaHubURL } from '@config/index';
import { FOLDER_ALBUM, FOLDER_ALBUM_SUGGEST_STATS } from '@constants/APIs';
import { FULL_RECORD, PARAMS_DEFAULT, TYPE_ACTIONS } from '@constants/common';
import {
	MAX_CONTENT_ALBUMS,
	MAX_RECORD_ALL,
	MAX_RECORD_SUGGEST_DEFAULT,
	PARAMS_DEFAULT_ALBUMS,
	TYPE_ALBUM,
} from '@constants/content/albums';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import AlbumManagerContext from '@contexts/Content/AlbumManager';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { getRequest, putRequest } from '@helpers/requestHelpers';
import { getUser } from '@helpers/userHelpers';
import {
	AlbumDto,
	AlbumRespDto,
	MetadataAlbum,
	ParamRequestAlbumsDto,
} from '@models/content/albums/albumManager';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import { getFilterRequest } from '@stores/actions';
import {
	createAlbumEnd,
	fetchAlbumsRequest,
	fetchAlbumsSuggestRequest,
	removeAlbumsEnd,
	removeAlbumsRequest,
	removeSuggestAlbumEnd,
} from '@stores/content/albumManager/albumManager.action';
import { StyledDirectLink } from '@styled/Content/ContentLibrary/ContentStyled';
import { convertParamSearch, encode, getContentByType, getSocialS3URL } from '@utils/common';
import { message } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ShareMediaHubModal from '../../../../components/Content/AlbumManager/ShareMediaHubModal';
import { AlbumsStoreDto } from '@models/content/albums/stores';

const AlbumManager = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const refBlock = useRef<any>(true);
	const refBlockSuggestion = useRef<any>(true);

	const history = useHistory();
	const location = useLocation();
	const { state: stateLocation }: any = location;
	const infoUser = getUser();

	const {
		loadingAlbums,
		albums,
		error,
		statusRemoveAlbum,
		albumsSuggest,
		statusRemoveSuggestAlbum,
		statusCreateAlbum,
		statusExportAlbumContents,
	}: AlbumsStoreDto = useSelector((state: any) => state.albumManager);
	const { actionFilterStatus } = useSelector((state: ContentRootStateDto) => state.content);

	const [paramAlbums, setParamAlbums] = useState<ParamRequestAlbumsDto>({
		...PARAMS_DEFAULT_ALBUMS,
	});
	const [paramAlbumSuggest, setParamAlbumSuggest] = useState<{ maxRecords: number }>({
		maxRecords: MAX_RECORD_SUGGEST_DEFAULT,
	});

	const [showSuggest, setShowSuggest] = useState<boolean>(false);
	const [visibleAlbumPopup, setVisibleAlbumPopup] = useState<string>('');
	const [listAlbum, setListAlbum] = useState<AlbumDto[]>([]);
	const [listSuggestAlbum, setListSuggestAlbum] = useState<AlbumDto[]>([]);
	const [albumsSelected, setAlbumsSelected] = useState<number[]>([]);
	const [albumsEnable, setAlbumsEnable] = useState<number[]>([]);
	const [typeConfirm, setTypeConfirm] = useState<string>('');
	const [detailAlbum, setDetailAlbum] = useState<AlbumDto | null>(null);
	const [detailAlbumSuggest, setDetailAlbumSuggest] = useState<AlbumDto | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [albumSuggestSelect, setAlbumSuggestSelect] = useState<AlbumDto | null>(null);
	const [visibleShareModal, setVisibleShareModal] = useState<boolean>(false);

	const onClosePopup = () => {
		setVisibleAlbumPopup('');
	};

	const handleResetListAlbum = () => {
		setParamAlbums((prev) => ({ ...prev, page: 1 }));
	};

	const formatListContent = (list: ContentContainerDto[]) => {
		return list.map((data: ContentContainerDto, index: number) => {
			const contentTemp: ContentDto = getContentByType(data);
			const thumbnail: string = getSocialS3URL(contentTemp.thumbnail);
			return { ...contentTemp, thumbnail };
		});
	};

	useEffect(() => {
		dispatch(
			getFilterRequest({
				...PARAMS_DEFAULT,
				maxRecords: FULL_RECORD,
			}),
		);
		return () => {
			refBlock.current = true;
			refBlockSuggestion.current = true;

			setShowSuggest(false);
			setListSuggestAlbum([]);
			setListAlbum([]);
			setParamAlbumSuggest({ maxRecords: MAX_RECORD_SUGGEST_DEFAULT });
		};
	}, []);

	useEffect(() => {
		if (stateLocation && stateLocation?.showAllSuggest) {
			setParamAlbumSuggest({ maxRecords: MAX_RECORD_ALL });
			setShowSuggest(true);
			history.replace({ state: undefined });
		}
	}, [stateLocation]);

	useEffect(() => {
		if (detailAlbum) {
			handleUpdateListAlbum(listAlbum, detailAlbum, setListAlbum);
		}
	}, [detailAlbum]);

	useEffect(() => {
		if (detailAlbumSuggest) {
			handleUpdateListAlbum(listSuggestAlbum, detailAlbumSuggest, setListSuggestAlbum);
		}
	}, [detailAlbumSuggest]);

	const resetAlbumSelect = () => {
		setAlbumsSelected([]);
		setAlbumsEnable([]);
	};

	const handleUpdateListAlbum = (
		list: Array<AlbumDto>,
		detail: AlbumDto,
		funcUpdate: (val: Array<AlbumDto>) => void,
	) => {
		const idx = list?.findIndex((item: AlbumDto) => item?.id === detail?.id);
		if (idx !== -1) {
			list[idx] = { ...list[idx], ...detail };
			funcUpdate([...list]);
		}
	};

	const getDetailAlbum = async (album: AlbumRespDto) => {
		const paramRequest = {
			maxRecords: MAX_CONTENT_ALBUMS,
			page: 1,
		};
		const { metadata, id: albumId } = album;

		const otherInfo = {
			metadata: {},
			loading: false,
		};
		if (metadata) {
			otherInfo.metadata = JSON.parse(metadata);
		}
		let result = { ...album, ...otherInfo, contents: [] };
		await getRequest(`${FOLDER_ALBUM}/${albumId}`, paramRequest)
			.then((response) => {
				if (response.status === 200) {
					if (response.body.data?.result) {
						const { listContents } = response.body.data?.result;
						result = {
							...response.body.data?.result,
							...otherInfo,
							contents: formatListContent(listContents),
						};
					}
				}
			})
			.catch((err) => {
				message.error(err);
			});
		return result;
	};

	const getDetailAlbumSuggest = async (album: AlbumRespDto) => {
		const paramRequest: any = {
			maxRecords: MAX_CONTENT_ALBUMS,
			page: 1,
		};
		const { listContents, ...otherDetail } = album;
		const { metadata } = album;
		const searchParams = convertParamSearch(paramRequest);

		const otherInfo = {
			metadata: {},
			loading: false,
		};
		if (metadata) {
			otherInfo.metadata = JSON.parse(metadata);
		}
		let result = { ...album, ...otherInfo, contents: [] };
		await putRequest(`${FOLDER_ALBUM_SUGGEST_STATS}?${searchParams}`, otherDetail)
			.then((response) => {
				if (response.status === 200) {
					if (response.body.data?.result) {
						const { listContents: contents } = response.body.data?.result;

						result = {
							...response.body.data?.result,
							...otherInfo,
							contents: formatListContent(contents),
						};
					}
				}
			})
			.catch((err) => {
				message.error(err);
			});
		return result;
	};

	const handleFetchSequentiallyDetailAlbum = async (list: AlbumRespDto[]) => {
		await list.reduce((prev: any, task: AlbumRespDto) => {
			return prev
				.then(() => (refBlock?.current ? null : getDetailAlbum(task)))
				.then((res: any) => {
					if (res) {
						setDetailAlbum(res);
					}
				})
				.catch((err: any) => {});
		}, Promise.resolve());
	};
	const handleFetchSequentiallyDetailSuggest = async (list: AlbumRespDto[]) => {
		await list.reduce((prev: any, task: AlbumRespDto) => {
			return prev
				.then(() => (refBlockSuggestion?.current ? null : getDetailAlbumSuggest(task)))
				.then((res: any) => {
					if (res) {
						setDetailAlbumSuggest(res);
					}
				})
				.catch((err: any) => {});
		}, Promise.resolve());
	};

	const handleRemoveAlbum = () => {
		setTypeConfirm('');
		dispatch(removeAlbumsRequest(albumsSelected));
	};

	const handleUpdateAlbum = (albumRes: AlbumRespDto) => {
		if (albumRes) {
			const idx = listAlbum?.findIndex((album) => album?.id === albumRes?.id);
			if (idx !== -1) {
				const listAlbumChange = [...listAlbum];
				const albumDetailBefore = listAlbumChange[idx];
				const { totalContent = 0, metadata, totalVideoContent, ...otherChange } = albumRes;

				let metadataObj: MetadataAlbum = {};
				if (metadata) {
					metadataObj = JSON.parse(metadata);
				}
				if (albumDetailBefore?.metadata?.filterId !== metadataObj?.filterId) {
					setParamAlbums((prev) => ({ ...prev, page: 1 }));
				} else {
					listAlbumChange[idx] = {
						...listAlbumChange[idx],
						...otherChange,
						metadata: metadataObj,
					};
					const enableNewList = albumsEnable?.filter((item) => item !== albumRes?.id);
					if (metadataObj?.share) {
						enableNewList.push(albumRes?.id);
					}
					setListAlbum([...listAlbumChange]);
					setAlbumsEnable(enableNewList);
				}
			}
		}
	};

	const renderContentPopupConfirm = () => {
		let result = <></>;
		if (typeConfirm === TYPE_ACTIONS.REMOVE_ALBUM) {
			result = (
				<>
					{t('albums.message.are_you_remove', {
						name: '',
					})}
				</>
			);
		}
		return result;
	};

	const hasSystemAlbum = useMemo(() => {
		const albumSystem = listAlbum?.find((album) => album?.type === TYPE_ALBUM.VERIFIED_ALBUM);

		if (albumSystem) {
			if (albumsSelected?.some((idSelect) => idSelect === albumSystem?.id)) {
				return true;
			}
		}
		return false;
	}, [albumsSelected]);

	const hasFavoriteAlbum = useMemo(() => {
		const albumSystem = listAlbum?.find((album) => album?.type === TYPE_ALBUM.FAVORITE_ALBUM);

		if (albumSystem) {
			if (albumsSelected?.some((idSelect) => idSelect === albumSystem?.id)) {
				return true;
			}
		}
		return false;
	}, [albumsSelected]);

	const albumDetailEdit = useMemo(() => {
		if (albumsSelected?.length === 1) {
			const albumDetail = listAlbum?.find((album) => album?.id === albumsSelected[0]) || null;
			return albumDetail;
		}
		return null;
	}, [listAlbum, albumsSelected]);

	const otherPropConfirm = useMemo(() => {
		const result: any = {};
		if (typeConfirm === TYPE_ACTIONS.REMOVE_ALBUM) {
			result.okText = t('albums.button.delete');
			result.onSubmit = handleRemoveAlbum;
		}
		return result;
	}, [typeConfirm]);

	// effect
	useEffect(() => {
		if (albums) {
			const albumFormat: AlbumDto[] = albums?.map((album: AlbumRespDto) => {
				const { metadata } = album;
				let metadataObj = {};

				if (metadata) {
					metadataObj = JSON.parse(metadata);
				}

				return {
					...album,
					metadata: metadataObj,
					loading: true,
					contents: [],
					totalContent: 0,
					totalVideoContent: 0,
				};
			});

			refBlock.current = false;
			const fullList = paramAlbums?.page === 1 ? albumFormat : [...listAlbum, ...albumFormat];

			handleFetchSequentiallyDetailAlbum(albums);
			setListAlbum([...fullList]);
		}
	}, [albums]);

	useEffect(() => {
		if (albumsSuggest) {
			const albumFormat: AlbumDto[] = albumsSuggest?.map((album: AlbumRespDto) => {
				const { metadata } = album;
				let metadataObj = {};
				try {
					if (metadata) {
						metadataObj = JSON.parse(metadata);
					}
				} catch (error) {
					console.log(error);
				}

				return {
					...album,
					metadata: metadataObj,
					loading: true,
					contents: formatListContent(album?.listContents || []),
				};
			});
			refBlockSuggestion.current = false;
			handleFetchSequentiallyDetailSuggest(albumsSuggest);
			setListSuggestAlbum(albumFormat);
		}
	}, [albumsSuggest]);

	useEffect(() => {
		if (paramAlbums?.page === 1) {
			refBlock.current = true;
			resetAlbumSelect();
			setListAlbum([]);
		}

		if (paramAlbums?.search) {
			setListSuggestAlbum([]);
		}

		dispatch(fetchAlbumsRequest({ ...paramAlbums }));
	}, [paramAlbums]);

	useEffect(() => {
		refBlockSuggestion.current = true;
		if (showSuggest) {
			dispatch(fetchAlbumsSuggestRequest({ ...paramAlbumSuggest }));
		}
	}, [paramAlbumSuggest, showSuggest]);

	useEffect(() => {
		if (!showSuggest) {
			setListSuggestAlbum([]);
		}
	}, [showSuggest]);

	useEffect(() => {
		if (!visibleAlbumPopup) {
			if (statusCreateAlbum === IN_PROGRESS) {
				setIsLoading(true);
			}

			if (statusCreateAlbum === FAILED) {
				message.error(error);
			}

			if (statusCreateAlbum === SUCCEEDED) {
				handleResetListAlbum();
				message.success(
					t('albums.message.album_insert_success', { name: albumSuggestSelect?.name }),
				);
			}

			if (statusCreateAlbum === SUCCEEDED || statusCreateAlbum === FAILED) {
				setIsLoading(false);
				setAlbumSuggestSelect(null);
				dispatch(createAlbumEnd());
			}
		}
	}, [statusCreateAlbum, visibleAlbumPopup]);

	useEffect(() => {
		if (statusRemoveAlbum === SUCCEEDED) {
			message.success(t('albums.message.remove_success'));
			setVisibleAlbumPopup('');
			handleResetListAlbum();
		}
		if (statusRemoveAlbum === SUCCEEDED || statusRemoveAlbum === FAILED) {
			dispatch(removeAlbumsEnd());
		}
	}, [statusRemoveAlbum]);

	useEffect(() => {
		if (statusRemoveSuggestAlbum === SUCCEEDED) {
			message.success(t('albums.message.remove_success'));
		}
		if (statusRemoveSuggestAlbum === SUCCEEDED || statusRemoveSuggestAlbum === FAILED) {
			dispatch(removeSuggestAlbumEnd());
		}
	}, [statusRemoveSuggestAlbum]);

	useEffect(() => {
		const listStatus = [
			statusRemoveSuggestAlbum,
			statusRemoveAlbum,
			actionFilterStatus,
			statusExportAlbumContents,
		];
		if (listStatus?.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus?.includes(FAILED)) {
			message.error(error);
		}

		if (listStatus?.includes(SUCCEEDED) || listStatus?.includes(FAILED)) {
			setIsLoading(false);
		}
	}, [
		statusRemoveAlbum,
		statusRemoveSuggestAlbum,
		actionFilterStatus,
		statusExportAlbumContents,
	]);

	// values context
	const valueContext = {
		paramAlbums,
		setParamAlbums,
		setVisibleAlbumPopup,
		listAlbum,
		setListAlbum,
		setAlbumsSelected,
		albumsSelected,
		setAlbumsEnable,
		albumsEnable,
		setTypeConfirm,
		hasSystemAlbum,
		listSuggestAlbum,
		setShowSuggest,
		showSuggest,
		setParamAlbumSuggest,
		setListSuggestAlbum,
		paramAlbumSuggest,
		setAlbumSuggestSelect,
		hasFavoriteAlbum,
		setVisibleShareModal,
		setIsLoading,
	};

	return (
		<AlbumManagerContext.Provider value={valueContext}>
			<LoadingWrapper isLoading={loadingAlbums || isLoading}>
				<HeaderAlbums />
				<AlbumList />
			</LoadingWrapper>
			{visibleAlbumPopup && (
				<AlbumModal
					visible={visibleAlbumPopup ? true : false}
					handleGetListAlbum={handleResetListAlbum}
					onCancel={onClosePopup}
					albumDetail={visibleAlbumPopup === TYPE_ACTIONS.EDIT ? albumDetailEdit : null}
					removeAlbum={setTypeConfirm}
					handleUpdateAlbum={handleUpdateAlbum}
				/>
			)}
			<ConfirmModal
				width={450}
				isOpen={typeConfirm ? true : false}
				onClose={() => setTypeConfirm('')}
				cancelText={t('gallery_manager.button.cancel')}
				{...otherPropConfirm}>
				{renderContentPopupConfirm()}
			</ConfirmModal>
			{visibleShareModal && (
				<ShareMediaHubModal
					visible={visibleShareModal}
					onCancel={() => setVisibleShareModal(false)}
					selectedAlbumIds={albumsEnable}
				/>
			)}
		</AlbumManagerContext.Provider>
	);
};

export default AlbumManager;
