import { OptionTypeItem } from '@models/filter';
import { StyledSpace } from '@styled/Content/ContentLibrary/FilterStyled';
import { StyledRadioGroup } from '@styled/Filter/FilterContentStyled';
import { Radio, Space } from 'antd';
import React from 'react';

type PropTypes = {
	list: OptionTypeItem[];
	direction?: 'vertical' | 'horizontal';
	gapSpace?: string;
	bgCheckbox?: string;
	radioValue?: any;
	[other: string]: any;
};
const RadioGroup = (props: PropTypes) => {
	const {
		list,
		direction = 'horizontal',
		gapSpace,
		bgCheckbox = undefined,
		radioValue,
		...other
	} = props;

	const otherProp = radioValue ? { value: radioValue } : {};

	return (
		<StyledRadioGroup
			{...other}
			bgCheckbox={bgCheckbox}
			{...otherProp}
			className="radio_group_dark first_0">
			<StyledSpace gap={gapSpace} direction={direction}>
				{list?.map((item) => (
					<Radio key={item?.value} disabled={!!item?.disabled} value={item?.value}>
						{item?.name}
					</Radio>
				))}
			</StyledSpace>
		</StyledRadioGroup>
	);
};

export default RadioGroup;
