import {
	IconAddCircleWhite,
	IconAnalyzed,
	IconFilter,
	IconGrid,
	IconHashTag,
	IconMatches,
	IconPotential,
	IconSort,
} from '@assets/icons';
import { CardMetricType } from '@models/socialsearch/summary';
import { StyledContainerMetrics, StyledWrapperItemCard } from '@styled/SocialSearch/Feeds';
import { abbreviateNumber, numberWithCommas } from '@utils/common';
import { Button, Col, Menu, Row, Tooltip } from 'antd';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MetricCard from './MetricCard';
import { SocialContentStoreDto } from '@models/socialsearch/store';
import { useSelector } from 'react-redux';
import { ROLE, SPECIAL_CHARS } from '@constants/settings';
import FeedsManagerContext from '@contexts/SocialSearch/Feeds';
import { isEmpty } from 'lodash';
import {
	StyledButton,
	StyledDropdown,
	StyledIconWrapper,
	StyledText,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { ActionsTypeSort } from '@models/common/summary';
import { SORT_BY, SORT_DIRECTION } from '@constants/filter/filterContent';
import { TypeSortDto } from '@models/filter';
import { StyledBtnCustom } from '@styled/Content/ContentLibrary/ActionBarStyled';
import { TYPE_COLLAPSE_ACTION } from '@constants/content/contentLibrary';
import { COL_MEDIA } from '@constants/ambassador';
import { getUser } from '@helpers/userHelpers';

const MetricFeeds = () => {
	const { t } = useTranslation();
	const { metricFeed, detailFeed, socialContent, loadingMetricFeed }: SocialContentStoreDto =
		useSelector((state: any) => state.socialContent);
	const user = getUser();
	const {
		filter,
		socialFeedList,
		setParamSocial,
		paramSocial,
		isFilter,
		setTypeCollapse,
		typeCollapse,
		handleResetFilter,
		setFullThumb,
		setCol,
		col,
	} = useContext(FeedsManagerContext);

	const searchTerms = useMemo(() => {
		const result = {
			type: SPECIAL_CHARS.HASH,
			value: '',
		};
		if (detailFeed) {
			const { hashtags, mentionUsernames } = detailFeed;
			if (hashtags.length > 0) {
				result.value = `${SPECIAL_CHARS.HASH}${hashtags[0]}`;
			} else {
				result.value = `${SPECIAL_CHARS.AT}${mentionUsernames[0]}`;
				result.type = SPECIAL_CHARS.AT;
			}
		}
		return result;
	}, [detailFeed]);

	const listCard: CardMetricType[] = [
		{
			id: 1,
			name: t('feeds.search_term'),
			value: searchTerms.value,
			icon: <IconHashTag />,
			tooltipTitle: t('feeds.sub_search_term'),
			loadingValue: loadingMetricFeed,
		},
		{
			id: 2,
			name: t('feeds.posts_analyzed'),
			value: numberWithCommas(metricFeed?.analyzedPosts),
			icon: <IconAnalyzed />,
			tooltipTitle: t('feeds.sub_posts_analyzed'),
			loadingValue: loadingMetricFeed,
		},
		{
			id: 3,
			name: t('feeds.matches_found'),
			value: abbreviateNumber(metricFeed?.matchesFound, 1),
			icon: <IconMatches />,
			tooltipTitle: t('feeds.sub_matches_found'),
			loadingValue: loadingMetricFeed,
		},
		{
			id: 4,
			name: t('campaign.unique'),
			value: abbreviateNumber(metricFeed?.potentialReach, 1),
			icon: <IconPotential />,
			disabled: !isEmpty(filter),
			tooltipTitle: t('feeds.sub_unique_creators'),
			loadingValue: loadingMetricFeed,
		},
	];

	const actionSort: ActionsTypeSort[] = [
		{
			id: 1,
			name: t('feeds.sort.post_date_desc'),
			click: () => {},
			valueSort: { sortBy: SORT_BY.TIME_STAMP, sortDirection: SORT_DIRECTION.DESC },
		},
		{
			id: 2,
			name: t('feeds.sort.post_date_asc'),
			click: () => {},
			valueSort: { sortBy: SORT_BY.TIME_STAMP, sortDirection: SORT_DIRECTION.ASC },
		},
		{
			id: 3,
			name: t('feeds.sort.creator_follower_count_desc'),
			click: () => {},
			valueSort: { sortBy: SORT_BY.FOLLOWERS, sortDirection: SORT_DIRECTION.DESC },
			hidden: true,
		},
		{
			id: 4,
			name: t('feeds.sort.creator_follower_count_asc'),
			click: () => {},
			valueSort: { sortBy: SORT_BY.FOLLOWERS, sortDirection: SORT_DIRECTION.ASC },
			hidden: true,
		},
	];

	const handleSort = (type: TypeSortDto) => {
		setParamSocial({ ...paramSocial, ...type, page: 1 });
	};

	const menuActionSort = (
		<Menu>
			{actionSort.map((item: ActionsTypeSort) => {
				if (item?.hidden) {
					return null;
				}
				return (
					<Menu.Item
						className={
							paramSocial?.sortBy === item.valueSort.sortBy &&
							paramSocial?.sortDirection === item.valueSort.sortDirection
								? 'active_menu_item'
								: ''
						}
						key={item.id}
						onClick={() => handleSort(item?.valueSort)}>
						{item.name}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	const handleCollapse = () => {
		const valCollapse =
			typeCollapse === TYPE_COLLAPSE_ACTION.FILTER ? '' : TYPE_COLLAPSE_ACTION.FILTER;

		setTypeCollapse(valCollapse);
	};

	const renderFilterButton = () => {
		if (isFilter) {
			return (
				<StyledBtnCustom
					padding="0 6px"
					className="active-filter"
					onClick={handleCollapse}
					icon={
						<StyledIconWrapper
							onClick={(e: React.MouseEvent<HTMLDivElement>) => {
								e.stopPropagation();
								handleResetFilter();
							}}
							margin="-2px 0 0 -2px"
							cursor="pointer"
							width="18px"
							height="18px">
							<IconAddCircleWhite />
						</StyledIconWrapper>
					}
					type="ghost">
					{t('social_search.filter.filters_applied')}
				</StyledBtnCustom>
			);
		}

		return (
			<StyledBtnCustom
				className={typeCollapse === TYPE_COLLAPSE_ACTION.FILTER ? 'active-filter' : ''}
				icon={<IconFilter />}
				type="ghost"
				onClick={handleCollapse}>
				{t('button.filter')}
			</StyledBtnCustom>
		);
	};

	const onChangeGrid = () => {
		const valChange = col === COL_MEDIA.C3 ? COL_MEDIA.C6 : COL_MEDIA.C3;
		setCol(valChange);
		if (!typeCollapse) {
			setFullThumb(valChange === COL_MEDIA.C6);
		}
	};

	return (
		<StyledContainerMetrics>
			{listCard.map((item) => {
				if (!item.tooltipTitle) {
					return <MetricCard key={item.id} card={item} />;
				}
				return (
					<Tooltip key={item.id} title={item.tooltipTitle}>
						<MetricCard card={item} />
					</Tooltip>
				);
			})}
			<StyledWrapperItemCard padding="4px 12px 4px 17px" textAlign="right">
				<StyledText
					margin="0 0 16px 0"
					className="italic"
					lineHeight="16px"
					fontSize="12px">
					{t('social_aggregator.displaying_matches', {
						value: `${numberWithCommas(socialFeedList.length)} /${numberWithCommas(
							socialContent.totalHits,
						)}`,
					})}
				</StyledText>
				<StyledWrapperTitle justifyContent="right">
					<Tooltip title={t('ambassador.toggle_grid')}>
						<StyledButton icon={<IconGrid />} onClick={() => onChangeGrid()} />
					</Tooltip>
					{user.role.toLowerCase() === ROLE.EREVIEWER.value.toLowerCase() && (
						<Tooltip title={t('button.filter')}>{renderFilterButton()}</Tooltip>
					)}
				</StyledWrapperTitle>
			</StyledWrapperItemCard>
		</StyledContainerMetrics>
	);
};

export default MetricFeeds;
