import { IconAltAdd, IconCancel, IconDelete, IconNote } from '@assets/icons';
import CustomSwitch from '@components/CustomSwitch';
import { TYPE_IMAGE } from '@constants/common';
import {
	KEY_VALIDATE_STEP_1,
	MAX_SIZE_LOGO,
	METADATA_WIDGET,
	STATUS_UPLOAD_FILE_WIDGET,
	UPLOADER_FIELD_NAME,
} from '@constants/content/widgetManager';
import { GALLERY_EDITOR_CONFIG } from '@constants/publish/gallery';
import { CLIENT_TYPE } from '@constants/settings';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import EditorCustom from '@cores/EditorCustom';
import { getUser } from '@helpers/userHelpers';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import { RefConfigWidgetBuilderStep } from '@models/content/widgetManager/ref';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import { uploadFileWidgetBuilderEnd, uploadFileWidgetBuilderRequest } from '@stores/actions';
import { UserRootStateDto, UserStoreType } from '@stores/user/user.types';
import {
	StyledCol,
	StyledRow,
	StyledSection,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledEmptyUpload,
	StyledFilePreview,
	StyledInput,
	StyledNameUpload,
	StyledSlider,
	StyledTextArea,
	StyledUpload,
} from '@styled/Content/WidgetManager';
import {
	StyledFormItem,
	StyledLabelField,
	StyledLabelItem,
	StyledWrapperLabel,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { validateSizeFileImage } from '@utils/common';
import { getUrlS3 } from '@utils/funcHelper';
import { Form, Upload, message } from 'antd';
import {
	Ref,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

type StepItemDto = {
	key: number | string | any;
	content: any;
	title: string | any;
	show?: boolean;
};

const StyledSettingStep = styled.div`
	padding: 16px 24px;
`;

const StyledIcon = styled.span`
	margin-right: 4px;
	display: inline-flex;
`;

const StyleSessionItem = styled.div`
	&:not(:first-child) {
		margin-top: 16px;
	}
`;

type PropSettingStep1 = {
	handleActionStep: () => void;
};

const SettingStep1 = forwardRef((props: PropSettingStep1, ref: Ref<RefConfigWidgetBuilderStep>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		statusUploadLogo,
		urlFile = '',
		error = '',
	}: WidgetManagerStoreDto = useSelector((state: any) => state?.widgetManager);
	const { currentClient }: UserStoreType = useSelector((state: UserRootStateDto) => state.user);

	const { handleActionStep } = props;

	const [form] = Form.useForm();

	const { detailWidget, setDetailWidget, setIsLoading, isMobile } =
		useContext(WidgetBuilderContext);
	const { metadata } = detailWidget;
	const { otherInfo = {}, colors = {}, header = {} } = metadata;
	const {
		srcLogo = '',
		nameImage = '',
		sizeLogo,
		title = '',
		dropzoneText,
		message: messageValue = '',
	} = header || {};
	const {
		enableSharing = false,
		enableSharingGoogleDrive = undefined,
		enableSharingYouTube = undefined,
		enableSharingInstagram = undefined,
	} = colors || {};

	const [showAdjustLogoSize, setShowAdjustLogoSize] = useState<boolean>(false);
	const [nameFile, setNameFile] = useState<string>('');

	const editorRef = useRef<any>();

	const defaultDropzoneText = useMemo(() => {
		return dropzoneText === undefined ? METADATA_WIDGET?.header?.dropzoneText : dropzoneText;
	}, [dropzoneText]);

	const initialValues = useMemo(() => {
		const result: any = {
			title,
			message: messageValue,
			dropzoneText: defaultDropzoneText,
		};
		return result;
	}, [title, messageValue, dropzoneText]);

	const isClientType = useMemo(() => {
		if (currentClient) {
			return (
				currentClient.orgType &&
				currentClient.orgType.toLowerCase() === CLIENT_TYPE.CLIENT.toLowerCase()
			);
		}
		return false;
	}, [currentClient]);

	useImperativeHandle(ref, () => ({
		validateAllFieldCustom: validateCustomField,
	}));

	const validateCustomField = async () => {
		const values = form.getFieldsValue();

		const listKey = Object.keys(values);
		const dataFormat: any = {};
		listKey?.forEach((item) => {
			if (values[item] && typeof values[item] === 'string') {
				dataFormat[item] = values[item]?.trim();
			} else {
				dataFormat[item] = values[item];
			}
		});
		form.setFieldsValue(dataFormat);
		await form.validateFields();

		form.submit();
	};

	const handleChangeUploadFile = async ({ fileList, file }: any) => {
		const formData = new FormData();
		formData.append('widgetId', detailWidget?.id);
		formData.append('file', file);
		const resultValidate = await validateSizeFileImage(file, {
			textSize: t('content_detail.type_mb'),
		});

		if (resultValidate?.valid) {
			setNameFile(file?.name);
			dispatch(
				uploadFileWidgetBuilderRequest({
					formData,
					keyStatus: STATUS_UPLOAD_FILE_WIDGET.logoFile,
				}),
			);
		} else {
			message.error(t(resultValidate.err, resultValidate?.params));
		}
	};

	useEffect(() => {
		if (statusUploadLogo === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (statusUploadLogo === SUCCEEDED && urlFile) {
			const contentImage: HTMLImageElement = new window.Image();
			contentImage.src = getUrlS3(urlFile);
			contentImage.onload = () => {
				const width: number = contentImage.width;
				const timeWidthDesktop =
					Math.round((width / MAX_SIZE_LOGO.widthDesktop) * 100) / 100 || 1;
				const timeWidthMobile =
					Math.round((width / MAX_SIZE_LOGO.widgetMobile) * 100) / 100 || 1;

				const widthLogoMobile = Math.round(width / timeWidthMobile);
				const widthLogoDesktop = Math.round(width / timeWidthDesktop);

				setIsLoading(false);
				handleValueHeader({
					srcLogo: urlFile,
					nameImage: nameFile || 'logo.png',
					sizeLogo: {
						widthLogoMobile: Math.round(widthLogoMobile / 2),
						maxWidthLogoMobile: MAX_SIZE_LOGO.widgetMobile,
						maxWidthLogoDesktop: MAX_SIZE_LOGO.widthDesktop,
						widthLogoDesktop: Math.round(widthLogoDesktop / 2),
					},
				});
			};
		}
		if (statusUploadLogo === FAILED) {
			setIsLoading(false);
			message.error(error);
		}
		if (statusUploadLogo === SUCCEEDED || statusUploadLogo === FAILED) {
			dispatch(uploadFileWidgetBuilderEnd());
			setNameFile('');
		}
	}, [statusUploadLogo]);

	const handleRemoveUploadedFile = () => {
		handleValueHeader({ srcLogo: '', nameImage: '', sizeLogo: undefined });
	};

	const propsImage = {
		beforeUpload: (file: any) => {
			const checkTypeImg =
				file.type === 'image/png' ||
				file.type === 'image/jpeg' ||
				file.type === 'image/jpg';

			if (!checkTypeImg) {
				message.error(
					t('content.widget_manager.message.upload_failed', { fileName: file.name }),
				);
			}
			return checkTypeImg ? true : Upload.LIST_IGNORE;
		},
		showUploadList: {
			removeIcon: <IconDelete />,
		},
	};

	const renderUpload = () => {
		return (
			<StyledUpload
				{...propsImage}
				showUploadList={false}
				maxCount={1}
				beforeUpload={() => false}
				accept={TYPE_IMAGE}
				onChange={handleChangeUploadFile}>
				{!srcLogo ? (
					<StyledEmptyUpload>
						<StyledIcon>
							<IconAltAdd />
						</StyledIcon>
						{t('content.widget_manager.widget_builder.upload_logo')}
					</StyledEmptyUpload>
				) : (
					<StyledFilePreview>
						<StyledIcon>
							<IconNote />
						</StyledIcon>
						<StyledNameUpload line={2}>{nameImage}</StyledNameUpload>
						<IconCancel
							style={{ width: '16px', height: '16px', marginLeft: '6px' }}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								handleRemoveUploadedFile();
							}}
						/>
					</StyledFilePreview>
				)}
			</StyledUpload>
		);
	};

	const renderInput = (val?: string) => {
		return (
			<StyledInput
				value={val}
				className="field_input_item"
				maxLength={otherInfo?.maxLengthShortText || 255}
				placeholder={t('content.widget_manager.widget_builder.enter_field_name')}
			/>
		);
	};

	const renderTextArea = (row: number) => {
		return (
			<StyledTextArea
				value={messageValue}
				placeholder={t('content.widget_manager.widget_builder.enter_field_name')}
				autoSize={{ minRows: row || 3, maxRows: row || 3 }}
				maxLength={otherInfo?.maxLengthParagraph || 8000}
				rows={row || 3}
			/>
		);
	};

	const handleEditorContent = (content: string) => {
		handleValueHeader({ [KEY_VALIDATE_STEP_1.message]: content });
	};

	const renderEditor = () => {
		return (
			<StyledWrapperContent className="dark_bg" width="100%" height="200px">
				<EditorCustom
					editorContent={messageValue || ''}
					setEditorContent={(val: string) => {
						handleEditorContent(val);
					}}
					otherInit={{
						plugins: GALLERY_EDITOR_CONFIG.PLUGINS,
						toolbar: GALLERY_EDITOR_CONFIG.TOOLBAR_WIDGET,
					}}
					callbackInit={() => setIsLoading(false)}
				/>
			</StyledWrapperContent>
		);
	};

	const renderAdjustLogoSize = () => {
		const max = isMobile ? sizeLogo?.maxWidthLogoMobile : sizeLogo?.maxWidthLogoDesktop;
		const valueReality = isMobile ? sizeLogo?.widthLogoMobile : sizeLogo?.widthLogoDesktop;

		const keyUpdate = isMobile ? 'widthLogoMobile' : 'widthLogoDesktop';

		return (
			<StyledSlider
				min={0}
				getTooltipPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
				max={max}
				value={valueReality}
				tipFormatter={(val) => `${val}px`}
				onChange={(val: number) => {
					setDetailWidget((prev: WidgetBuilderDetailDto) => ({
						...prev,
						metadata: {
							...prev.metadata,
							header: {
								...prev.metadata.header,
								sizeLogo: {
									...prev.metadata.header?.sizeLogo,
									[keyUpdate]: val,
								},
							},
						},
					}));
				}}
				tooltipVisible
			/>
		);
	};

	const onValuesChange = (val: any) => {
		const keyName = Object.keys(val)[0];
		handleValueHeader({ [keyName]: val[keyName] });
	};

	const handleValueHeader = (val: any) => {
		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				header: {
					...prev.metadata.header,
					...val,
				},
			},
		}));
	};

	const handleEnableOtherSources = (val: boolean, fieldName: string) => {
		const other: any = { [fieldName]: val };
		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				colors: {
					...prev.metadata.colors,
					...other,
				},
			},
		}));
	};

	const handleFinishForm = () => {
		handleActionStep();
	};

	const listFormItemStep1: StepItemDto[] = [
		{
			title: t('content.widget_manager.widget_builder.add_logo'),
			content: renderUpload(),
			key: KEY_VALIDATE_STEP_1.logo,
		},
		{
			key: KEY_VALIDATE_STEP_1.progressLogo,
			title: t('content.widget_manager.widget_builder.adjust_logo_size'),
			content: renderAdjustLogoSize(),
		},
		{
			title: t('content.widget_manager.widget_builder.add_header'),
			content: renderInput(title),
			key: KEY_VALIDATE_STEP_1.title,
		},
		{
			title: t('content.widget_manager.widget_builder.add_welcome_message'),
			content: renderEditor(),
			key: KEY_VALIDATE_STEP_1.message,
		},
		{
			title: t('content.widget_manager.widget_builder.add_dropzone_text'),
			content: renderInput(defaultDropzoneText),
			key: KEY_VALIDATE_STEP_1.dropzoneText,
		},
	];

	const otherSources = [
		{
			label: t('content.widget_manager.widget_builder.enable_share_google_drive'),
			value: enableSharingGoogleDrive,
			fieldName: UPLOADER_FIELD_NAME.ENABLE_SHARING_GOOGLE_DRIVE,
		},
		{
			label: t('content.widget_manager.widget_builder.enable_share_instagram'),
			value: enableSharingInstagram,
			fieldName: UPLOADER_FIELD_NAME.ENABLE_SHARING_INSTAGRAM,
			hide: isClientType,
		},
		{
			label: t('content.widget_manager.widget_builder.enable_share_youtube'),
			value: enableSharingYouTube,
			fieldName: UPLOADER_FIELD_NAME.ENABLE_SHARING_YOUTUBE,
		},
		{
			label: t('content.widget_manager.widget_builder.enable_share_tiktok'),
			value: enableSharing,
			fieldName: UPLOADER_FIELD_NAME.ENABLE_SHARING_TIKTOK,
		},
	];

	useEffect(() => {
		if (srcLogo && !showAdjustLogoSize) {
			setShowAdjustLogoSize(true);
		}
		if (!srcLogo && showAdjustLogoSize) {
			setShowAdjustLogoSize(false);
		}
	}, [srcLogo]);

	return (
		<StyledSettingStep>
			<Form
				form={form}
				initialValues={initialValues}
				onValuesChange={onValuesChange}
				onFinish={handleFinishForm}>
				{listFormItemStep1?.map((item) => {
					if (!showAdjustLogoSize && item?.key === KEY_VALIDATE_STEP_1.progressLogo) {
						return null;
					}
					if (
						item?.key === KEY_VALIDATE_STEP_1.title ||
						item?.key === KEY_VALIDATE_STEP_1.dropzoneText
					) {
						return (
							<StyledFormItem
								name={item?.key}
								key={item?.key}
								margin={'16px 0 0 0'}
								label={
									<StyledWrapperLabel>
										<StyledLabelField>{item?.title}</StyledLabelField>
									</StyledWrapperLabel>
								}>
								{item?.content}
							</StyledFormItem>
						);
					}
					return (
						<StyleSessionItem key={item?.key}>
							<StyledLabelItem>{item?.title}</StyledLabelItem>
							{item?.content}
						</StyleSessionItem>
					);
				})}

				<StyledSection margin="24px 0 0 0">
					{otherSources.map((item) => {
						if (item.hide) {
							return null;
						}
						return (
							<StyledRow margin="12px 0 0 0">
								<StyledCol span={12}>
									<StyledLabelField style={{ marginRight: '24px' }}>
										{item.label}
									</StyledLabelField>
								</StyledCol>
								<StyledCol>
									<CustomSwitch
										checked={item.value}
										setChecked={(val: boolean) =>
											handleEnableOtherSources(val, item.fieldName)
										}
									/>
								</StyledCol>
							</StyledRow>
						);
					})}
				</StyledSection>
			</Form>
		</StyledSettingStep>
	);
});

export default SettingStep1;
