import { IconAddCircleWhite, IconSearch } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { RefClearSearchBar } from '@models/common/ref';
import { StyledIconSearch } from '@styled/Common/CommonStyled';
import { Input } from 'antd';
import { InputProps, SearchProps } from 'antd/lib/input';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import styled from 'styled-components';

const DEFAULT_HEIGHT = 32;

const DEFAULT_WIDTH = '200px';

const BORDER_WIDTH = 1;

const StyledWrapperSearchBar = styled.div<{ width?: string; height?: number; margin?: string }>`
	position: relative;
	width: ${({ width }) => width || DEFAULT_WIDTH};
	height: ${({ height }) => (height ? height + BORDER_WIDTH : DEFAULT_HEIGHT + BORDER_WIDTH)}px;
	color: ${THEME.colors.white};
	margin: ${({ margin }) => margin || '0 8px 0 0'};
	display: inline-flex;
	align-items: center;
`;

const StyledSearch = styled(Input)<{
	width?: string;
	padding?: string;
	height?: number;
}>`
	width: ${({ width }) => width || '100%'};
	padding: ${({ padding }) => padding || 'unset'};

	&,
	.ant-input,
	.ant-input-group-addon,
	.ant-input-search-button {
		height: ${({ height }) => height || DEFAULT_HEIGHT}px;
		background: transparent;
	}

	.ant-btn {
		border-left: none;
		border-color: ${THEME.colors.darkBlue4};
		padding: 0;

		&:focus {
			border-color: none;
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}
	.ant-input-wrapper {
		.ant-btn {
			border: 0px !important;
		}
	}
	&.ant-input-affix-wrapper {
		background-color: transparent;
		padding-right: 4px;
		padding-left: 8px;
		border-radius: 3px;

		height: ${({ height }) => height || DEFAULT_HEIGHT}px;
		&-focused {
			box-shadow: unset !important;
			border-color: ${THEME.colors.orangeBase};
		}
		.ant-input {
			border-right: none;
			box-shadow: none;
			border-color: ${THEME.colors.darkBlue4};
			&.ant-input-disabled {
				background-color: transparent !important;
			}
		}
		&:hover {
			.ant-input {
				border-color: ${THEME.colors.orangeBase};
			}
		}
	}

	.ant-btn.ant-btn-primary:focus,
	.ant-btn.ant-btn-primary:hover {
		background-color: transparent;
	}
`;

const StyledWrapperValue = styled.div<{ height?: number }>`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: ${THEME.colors.orangeBase};
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	opacity: 0;
	z-index: -1;
	transition: 0.3s;
	padding: 0 8px 0 12px;
	height: ${({ height }) => (height ? height + BORDER_WIDTH : DEFAULT_HEIGHT + BORDER_WIDTH)}px;
	&.show_value {
		opacity: 1;
		z-index: 1;
	}
`;

const StyledValue = styled.div`
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
`;

type PropSearch = {
	onSearch?: (val: string) => void;
	onChange?: (val: string) => void;
	onClear?: () => void;
	style?: Object;
	isSearchLeft?: boolean;
};

const SearchBar = forwardRef(
	(
		props:
			| PropSearch
			| React.ForwardRefExoticComponent<SearchProps & React.RefAttributes<InputProps>>
			| any,
		ref: Ref<RefClearSearchBar>,
	) => {
		const {
			value = '',
			width = DEFAULT_WIDTH,
			onSearch,
			onChange,
			onClear,
			style = {},
			isSearchLeft = false,
			...other
		} = props;
		const [searchValue, setSearchValue] = useState<string>('');
		const [isSearch, setIsSearch] = useState<boolean>(false);

		useEffect(() => {
			setSearchValue(value || '');
			if (!value) {
				setIsSearch(false);
			}
		}, [value]);

		useImperativeHandle(ref, () => ({
			clearSearchBarRef: handleClear,
			setValueSearch: onPressEnterSearch,
			setValueNotSearch,
		}));

		const setValueNotSearch = (val: string) => {
			setSearchValue(val || '');
			setIsSearch(!!val);
		};

		const onPressEnterSearch = (val: string) => {
			if (onSearch && typeof onSearch === 'function') {
				if (isSearch || val) {
					onSearch(val);
				}
			}
			if (val !== searchValue) {
				setSearchValue(val || '');
			}

			setIsSearch(!!val);
		};

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (onChange && typeof onChange === 'function') {
				onChange(searchValue);
			}

			setSearchValue(e?.target?.value);
		};

		const handleClear = () => {
			onPressEnterSearch('');
			if (onClear && typeof onClear === 'function') {
				onClear();
			}
		};

		const renderOtherProp = useMemo(() => {
			const elmSearch = (
				<StyledIconSearch onClick={() => onPressEnterSearch(searchValue)}>
					<IconSearch />{' '}
				</StyledIconSearch>
			);
			if (isSearchLeft) {
				return {
					prefix: elmSearch,
				};
			}
			return {
				suffix: elmSearch,
			};
		}, [isSearchLeft, searchValue]);

		return (
			<StyledWrapperSearchBar width={width} style={style}>
				<StyledSearch
					value={searchValue}
					onPressEnter={(e: React.ChangeEvent<HTMLInputElement>) =>
						onPressEnterSearch(e?.target?.value)
					}
					onChange={handleChange}
					{...renderOtherProp}
					{...other}
				/>
				<StyledWrapperValue className={isSearch ? 'show_value' : ''}>
					<StyledValue>{searchValue}</StyledValue>
					<StyledIconSearch onClick={handleClear}>
						<IconAddCircleWhite />
					</StyledIconSearch>
				</StyledWrapperValue>
			</StyledWrapperSearchBar>
		);
	},
);

export default SearchBar;
