import { LANDING_PAGE } from '@constants/APIs';
import genericApiSaga from '@helpers/genericApiSaga';
import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import { ListRequestPayloadDto, UpdateLandingPageDto } from '@models/landingpagemanager';
import { convertParamSearch } from '@utils/common';
import _ from 'lodash';
import { Action } from 'redux-actions';
import { put, takeEvery } from 'redux-saga/effects';
import * as actions from './landingPageManager.actions';
import {
	clonePublishedLandingPageTypes,
	createLandingPageTypes,
	deleteLandingPageTypes,
	getLandingPageDetailTypes,
	getLandingPageListLiteTypes,
	getLandingPageListTypes,
	publishLandingPageTypes,
	resumeLandingPageDraftTypes,
	unPublishLandingPageTypes,
	updateLandingPageTypes,
} from './landingPageManager.types';

function* getLandingPageList({ payload }: Action<ListRequestPayloadDto>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(LANDING_PAGE, payload),
		*completed(response: any) {
			yield put(actions.getLandingPageListSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getLandingPageListFailed(response));
		},
	});
}

function* getLandingPageListLite({ payload }: Action<ListRequestPayloadDto>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${LANDING_PAGE}/lite`, payload),
		*completed(response: any) {
			yield put(actions.getLandingPageListLiteSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getLandingPageListLiteFailed(response));
		},
	});
}

function* getLandingPageDetail({ payload }: Action<{ id: number }>) {
	const { id } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${LANDING_PAGE}/${id}`),
		*completed(response: any) {
			yield put(actions.getLandingPageDetailSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getLandingPageDetailFailed(response));
		},
	});
}

function* updateLandingPage({ payload }: Action<UpdateLandingPageDto>) {
	const { params = {}, id, ...others } = payload || {};
	const searchParams = convertParamSearch(others);

	const url = `${LANDING_PAGE}/${id}${!_.isEmpty(others) ? `?${searchParams}` : ''}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, params),
		*completed(response: any) {
			yield put(actions.updateLandingPageSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateLandingPageFailed(response));
		},
	});
}

function* publishLandingPage({ payload }: Action<any>) {
	const { id, accountEmail, ...otherParams } = payload;
	const url = `${LANDING_PAGE}/${id}/publish?accountEmail=${accountEmail}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, otherParams),
		*completed(response: any) {
			yield put(actions.publishLandingPageSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.publishLandingPageFailed(response));
		},
	});
}

function* createLandingPage({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(LANDING_PAGE, payload),
		*completed(response: any) {
			if (response) {
				response.data.isDuplicated = payload.isDuplicated;
				yield put(actions.createLandingPageSucceeded(response));
			}
		},
		*failed(response: any) {
			yield put(actions.createLandingPageFailed(response));
		},
	});
}

function* resumeLandingPageDraft({ payload }: Action<{ id: number }>) {
	const { id } = payload;
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${LANDING_PAGE}/${id}/resume-live-draft`),
		*completed(response: any) {
			yield put(actions.resumeLandingPageDraftSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.resumeLandingPageDraftFailed(response));
		},
	});
}

function* clonePublishedLandingPage({ payload }: Action<{ id: number }>) {
	const { id } = payload;
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${LANDING_PAGE}/${id}/clone-live`),
		*completed(response: any) {
			yield put(actions.clonePublishedLandingPageSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.clonePublishedLandingPageFailed(response));
		},
	});
}

function* deleteLandingPage({ payload }: Action<{ id: number }>) {
	const { id } = payload || {};
	const url = `${LANDING_PAGE}/${id}`;

	yield genericApiSaga({
		gatewayCall: () => deleteRequest(url),
		*completed(response: any) {
			yield put(actions.deleteLandingPageSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteLandingPageFailed(response));
		},
	});
}

function* unPublishLandingPage({ payload }: Action<{ id: number }>) {
	const { id } = payload || {};
	const url = `${LANDING_PAGE}/${id}/unpublish`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(actions.unPublishLandingPageSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.unPublishLandingPageFailed(response));
		},
	});
}

export function* watcherLandingPageManagerSaga() {
	yield takeEvery(getLandingPageListTypes.GET_LANDING_PAGE_LIST_REQUEST, getLandingPageList);
	yield takeEvery(
		getLandingPageListLiteTypes.GET_LANDING_PAGE_LIST_LITE_REQUEST,
		getLandingPageListLite,
	);
	yield takeEvery(
		getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_REQUEST,
		getLandingPageDetail,
	);
	yield takeEvery(updateLandingPageTypes.UPDATE_LANDING_PAGE_REQUEST, updateLandingPage);
	yield takeEvery(publishLandingPageTypes.PUBLISH_LANDING_PAGE_REQUEST, publishLandingPage);
	yield takeEvery(createLandingPageTypes.CREATE_LANDING_PAGE_REQUEST, createLandingPage);
	yield takeEvery(
		resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_REQUEST,
		resumeLandingPageDraft,
	);
	yield takeEvery(
		clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_REQUEST,
		clonePublishedLandingPage,
	);
	yield takeEvery(deleteLandingPageTypes.DELETE_LANDING_PAGE_REQUEST, deleteLandingPage);
	yield takeEvery(unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_REQUEST, unPublishLandingPage);
}
