import styled from 'styled-components';
import { Input, Layout } from 'antd';
import THEME from '@constants/themes/themes';
import { RESOLUTIONS } from '@constants/common';

export const StyledLayout = styled(Layout)<{ isHidden?: boolean }>`
	height: 100vh;
	position: relative;
	${({ isHidden }) => `opacity: ${isHidden ? 0 : 1}`};
`;

export const StyledContainer = styled.div`
	text-align: center;
`;

export const StyledContainerBtn = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledFormWrapper = styled(Layout.Content)`
	background-color: ${THEME.colors.darkBlue2} !important;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 566px;
	transform: translate(-50%, -50%);
	padding: 74px 100px;
	color: white;
	display: flex;
	justify-content: center;
	flex-direction: column;

	&.login-mobile {
		width: 100%;
		position: relative;
		padding: 100px 34px;
	}

	.ant-form-item-children-icon {
		:not(.anticon-close-circle) {
			svg {
				fill: none;
			}
		}
		.anticon-close-circle {
			svg {
				fill: red;
			}
		}
	}

	@media (max-width: ${RESOLUTIONS.TABLET_MIN + 100}px) {
		padding: 100px 50px;
		width: 55%;
	}

	@media (max-width: ${RESOLUTIONS.TABLET_MIN - 50}px) {
		width: 65%;
	}

	@media (max-width: ${RESOLUTIONS.MOBILE_MIN}px) {
		.login-title {
			font-size: 20px;
		}

		.forgot-password-hint {
			font-size: 14px;
		}
	}
`;

export const StyledLogo = styled.div`
	width: 80px;
	height: 80px;
	margin: 0 auto 24px auto;
`;

export const StyledImg = styled.img`
	width: 100%;
	height: 100%;
`;

export const StyledLink = styled.span`
	color: ${THEME.colors.blueBase};
	text-decoration: underline;
	cursor: pointer;
	padding: 0 6px;
`;

export const StyledInputMFACode = styled(Input)`
	width: 110px;
	padding: 8px;
	font-size: 24px;
`;
