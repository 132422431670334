import { DEFAULT_ERROR } from '@constants/errors';
import { STATUSCODE } from '@constants/APIs';
import { NONE, IN_PROGRESS, SUCCEEDED, FAILED } from '@constants/status';
import { Action, handleActions } from 'redux-actions';
import {
	changeDataStoreTypes,
	changeDefaultTemplateTypes,
	changeTemplateStatusTypes,
	changeTemplateToWidgetsTypes,
	createTemplateTypes,
	duplicateTemplateTermsTypes,
	fetchDetailTemplateTypes,
	fetchEntribeStandardTermsTypes,
	fetchListTemplateTypes,
	fetchListWidgetLiveTypes,
	fetchTemplateDefaultTypes,
	fetchTemplateHistoryTypes,
	fetchTemplateWidgetTypes,
	fetchWidgetInTemplateTypes,
	updateTemplateTermsTypes,
} from './contentLicensing.types';
import {
	ContentLicensingPayloadDto,
	ContentLicensingStoreDto,
} from '@models/settings/contentLicensing/stores';
import { STATUS_TEMPLATE } from '@constants/licensingTemplate';

const initialState: ContentLicensingStoreDto = {
	loadingDetail: false,
	loadingTemplate: false,
	loadingTemplateHistory: false,
	loadingWidgetInTemplate: false,
	loadingListWidgetLive: false,
	loadingEntribeStandardTerms: false,
	loadingTemplateWidget: false,
	loadingTemplateDefault: false,
	statusCreateTemplate: NONE,
	statusUpdateTemplate: NONE,
	statusChangeTemplateToWidget: NONE,
	statusChangeTemplateStatus: NONE,
	statusDuplicateTemplateTerms: NONE,
	statusChangeDefaultTemplate: NONE,
	templateDetail: null,
	templateCreate: null,
	templateDefault: null,
	templateHistory: [],
	dataWidgetLive: [],
	templateInWidget: [],
	templates: [],
	templateWidgets: [],
	entribeStandardTerms: null,
	error: '',
};

// create template
const createTemplateRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		statusCreateTemplate: IN_PROGRESS,
	};
};

const createTemplateSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: templateCreate,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusCreateTemplate: SUCCEEDED,
			templateCreate,
			error: null,
		};
	}

	return {
		...state,
		statusCreateTemplate: FAILED,
		error: status,
		templateCreate: null,
	};
};

const createTemplateFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusCreateTemplate: FAILED,
			templateCreate: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		templateCreate: null,
		statusCreateTemplate: FAILED,
	};
};

const createTemplateEnd = (state: ContentLicensingStoreDto) => {
	return {
		...state,
		statusCreateTemplate: NONE,
		templateCreate: null,
	};
};

// update template
const updateTemplateTermsRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		statusUpdateTemplate: IN_PROGRESS,
	};
};

const updateTemplateTermsSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: templateDetail,
	} = payload.data;
	const { noSave, noChangeTerms } = payload;

	// TODO, will be update once we have offical response data format from BE
	const other: any = {};
	if (!noSave) {
		other.templateDetail = {
			...templateDetail,
			terms: noChangeTerms ? state?.templateDetail?.terms : templateDetail?.terms,
		};
	}

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusUpdateTemplate: SUCCEEDED,
			...other,
			error: null,
		};
	}

	return {
		...state,
		statusUpdateTemplate: FAILED,
		error: status,
	};
};

const updateTemplateTermsFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusUpdateTemplate: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusUpdateTemplate: FAILED,
	};
};

const updateTemplateTermsEnd = (state: ContentLicensingStoreDto) => {
	return {
		...state,
		statusUpdateTemplate: NONE,
	};
};

// fetch detail template
const getDetailTemplateRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		loadingDetail: true,
	};
};

const getDetailTemplateSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: templateDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingDetail: false,
			templateDetail,
			error: null,
		};
	}

	return {
		...state,
		loadingDetail: false,
		error: status,
		templateDetail: null,
	};
};

const getDetailTemplateFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingDetail: false,
			templateDetail: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		templateDetail: null,
		loadingDetail: false,
	};
};

// fetch list template
const getListTemplateRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		loadingTemplate: true,
	};
};

const getListTemplateSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: templates,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingTemplate: false,
			templates,
			error: null,
		};
	}

	return {
		...state,
		loadingTemplate: false,
		error: status,
		templates: [],
	};
};

const getListTemplateFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingTemplate: false,
			templates: [],
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		templates: [],
		loadingTemplate: false,
	};
};

// fetch template history
const getTemplateHistoryRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		loadingTemplateHistory: true,
	};
};

const getTemplateHistorySucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: templateHistory,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingTemplateHistory: false,
			templateHistory,
			error: null,
		};
	}

	return {
		...state,
		loadingTemplateHistory: false,
		error: status,
		templateHistory: [],
	};
};

const getTemplateHistoryFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingTemplateHistory: false,
			templateHistory: [],
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		templateHistory: [],
		loadingTemplateHistory: false,
	};
};

// fetch widget in template
const getWidgetInTemplateRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		loadingWidgetInTemplate: true,
	};
};

const getWidgetInTemplateSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: templateInWidget,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingWidgetInTemplate: false,
			templateInWidget,
			error: null,
		};
	}

	return {
		...state,
		loadingWidgetInTemplate: false,
		error: status,
		templateInWidget: [],
	};
};

const getWidgetInTemplateFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingWidgetInTemplate: false,
			templateInWidget: [],
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		templateInWidget: [],
		loadingWidgetInTemplate: false,
	};
};

// fetch list widget live
const getListWidgetLiveRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		loadingListWidgetLive: true,
	};
};

const getListWidgetLiveSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: dataWidgetLive,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingListWidgetLive: false,
			dataWidgetLive,
			error: null,
		};
	}

	return {
		...state,
		loadingListWidgetLive: false,
		error: status,
		dataWidgetLive: [],
	};
};

const getListWidgetLiveFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingListWidgetLive: false,
			dataWidgetLive: [],
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		dataWidgetLive: [],
		loadingListWidgetLive: false,
	};
};

// fetch entribe standard terms
const getEntribeStandardTermsRequest = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<any>,
) => {
	return {
		...state,
		loadingEntribeStandardTerms: true,
	};
};

const getEntribeStandardTermsSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: entribeStandardTerms,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingEntribeStandardTerms: false,
			entribeStandardTerms,
			error: null,
		};
	}

	return {
		...state,
		loadingEntribeStandardTerms: false,
		error: status,
		entribeStandardTerms: null,
	};
};

const getEntribeStandardTermsFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingEntribeStandardTerms: false,
			entribeStandardTerms: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		entribeStandardTerms: null,
		loadingEntribeStandardTerms: false,
	};
};

// change template to widget
const changeTemplateToWidgetsRequest = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<any>,
) => {
	return {
		...state,
		statusChangeTemplateToWidget: IN_PROGRESS,
	};
};

const changeTemplateToWidgetsSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;
	const { templateInWidget } = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusChangeTemplateToWidget: SUCCEEDED,
			templateInWidget: templateInWidget || state?.templateInWidget,
			error: null,
		};
	}

	return {
		...state,
		statusChangeTemplateToWidget: FAILED,
		error: status,
	};
};

const changeTemplateToWidgetsFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusChangeTemplateToWidget: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusChangeTemplateToWidget: FAILED,
	};
};

const changeTemplateToWidgetsEnd = (state: ContentLicensingStoreDto) => {
	return {
		...state,
		statusChangeTemplateToWidget: NONE,
	};
};

// change template status
const changeTemplateStatusRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		statusChangeTemplateStatus: IN_PROGRESS,
	};
};

const changeTemplateStatusSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;
	const { templateStatusEnum = STATUS_TEMPLATE.DRAFT } = payload;
	const { templateDetail, templateInWidget } = state;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusChangeTemplateStatus: SUCCEEDED,
			templateDetail: { ...(templateDetail || {}), status: templateStatusEnum },
			templateInWidget:
				templateStatusEnum !== STATUS_TEMPLATE.APPROVED ? [] : templateInWidget,
			error: null,
		};
	}

	return {
		...state,
		statusChangeTemplateStatus: FAILED,
		error: status,
	};
};

const changeTemplateStatusFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusChangeTemplateStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusChangeTemplateStatus: FAILED,
	};
};

const changeTemplateStatusEnd = (state: ContentLicensingStoreDto) => {
	return {
		...state,
		statusChangeTemplateStatus: NONE,
	};
};

// duplicate template terms
const duplicateTemplateTermsRequest = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<any>,
) => {
	return {
		...state,
		statusDuplicateTemplateTerms: IN_PROGRESS,
	};
};

const duplicateTemplateTermsSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusDuplicateTemplateTerms: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		statusDuplicateTemplateTerms: FAILED,
		error: status,
	};
};

const duplicateTemplateTermsFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusDuplicateTemplateTerms: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusDuplicateTemplateTerms: FAILED,
	};
};

const duplicateTemplateTermsEnd = (state: ContentLicensingStoreDto) => {
	return {
		...state,
		statusDuplicateTemplateTerms: NONE,
	};
};

// change default template terms
const changeDefaultTemplateRequest = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<any>,
) => {
	return {
		...state,
		statusChangeDefaultTemplate: IN_PROGRESS,
	};
};

const changeDefaultTemplateSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusChangeDefaultTemplate: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		statusChangeDefaultTemplate: FAILED,
		error: status,
	};
};

const changeDefaultTemplateFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusChangeDefaultTemplate: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusChangeDefaultTemplate: FAILED,
	};
};

const changeDefaultTemplateEnd = (state: ContentLicensingStoreDto) => {
	return {
		...state,
		statusChangeDefaultTemplate: NONE,
	};
};

// fetch template widget
const getTemplateWidgetRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		loadingTemplateWidget: true,
	};
};

const getTemplateWidgetSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: templateWidgets,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingTemplateWidget: false,
			templateWidgets,
			error: null,
		};
	}

	return {
		...state,
		loadingTemplateWidget: false,
		error: status,
		templateWidgets: [],
	};
};

const getTemplateWidgetFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingTemplateWidget: false,
			templateWidgets: [],
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		templateWidgets: [],
		loadingTemplateWidget: false,
	};
};

// fetch template default
const getTemplateDefaultRequest = (state: ContentLicensingStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		loadingTemplateDefault: true,
	};
};

const getTemplateDefaultSucceeded = (
	state: ContentLicensingStoreDto,
	{ payload }: Action<ContentLicensingPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: templateDefault,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingTemplateDefault: false,
			templateDefault,
			error: null,
		};
	}

	return {
		...state,
		loadingTemplateDefault: false,
		error: status,
		templateDefault: null,
	};
};

const getTemplateDefaultFailed = (
	state: ContentLicensingStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingTemplateDefault: false,
			templateDefault: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		templateDefault: null,
		loadingTemplateDefault: false,
	};
};

// update store
const changeFieldDataStore = (state: ContentLicensingStoreDto, { payload }: any) => {
	return {
		...state,
		...payload,
	};
};

const resetStoreData = (state: ContentLicensingStoreDto) => {
	return {
		...initialState,
	};
};

const contentLicensingReducers = handleActions<any>(
	{
		[createTemplateTypes.CREATE_TEMPLATE_REQUEST]: createTemplateRequest,
		[createTemplateTypes.CREATE_TEMPLATE_SUCCEEDED]: createTemplateSucceeded,
		[createTemplateTypes.CREATE_TEMPLATE_FAILED]: createTemplateFailed,
		[createTemplateTypes.CREATE_TEMPLATE_END]: createTemplateEnd,

		[updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_REQUEST]: updateTemplateTermsRequest,
		[updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_SUCCEEDED]: updateTemplateTermsSucceeded,
		[updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_FAILED]: updateTemplateTermsFailed,
		[updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_END]: updateTemplateTermsEnd,

		[fetchDetailTemplateTypes.FETCH_DETAIL_TEMPLATE_REQUEST]: getDetailTemplateRequest,
		[fetchDetailTemplateTypes.FETCH_DETAIL_TEMPLATE_SUCCEEDED]: getDetailTemplateSucceeded,
		[fetchDetailTemplateTypes.FETCH_DETAIL_TEMPLATE_FAILED]: getDetailTemplateFailed,

		[fetchListTemplateTypes.FETCH_LIST_TEMPLATE_REQUEST]: getListTemplateRequest,
		[fetchListTemplateTypes.FETCH_LIST_TEMPLATE_SUCCEEDED]: getListTemplateSucceeded,
		[fetchListTemplateTypes.FETCH_LIST_TEMPLATE_FAILED]: getListTemplateFailed,

		[fetchTemplateHistoryTypes.FETCH_TEMPLATE_HISTORY_REQUEST]: getTemplateHistoryRequest,
		[fetchTemplateHistoryTypes.FETCH_TEMPLATE_HISTORY_SUCCEEDED]: getTemplateHistorySucceeded,
		[fetchTemplateHistoryTypes.FETCH_TEMPLATE_HISTORY_FAILED]: getTemplateHistoryFailed,

		[fetchWidgetInTemplateTypes.FETCH_WIDGET_IN_TEMPLATE_REQUEST]: getWidgetInTemplateRequest,
		[fetchWidgetInTemplateTypes.FETCH_WIDGET_IN_TEMPLATE_SUCCEEDED]:
			getWidgetInTemplateSucceeded,
		[fetchWidgetInTemplateTypes.FETCH_WIDGET_IN_TEMPLATE_FAILED]: getWidgetInTemplateFailed,

		[fetchListWidgetLiveTypes.FETCH_LIST_WIDGET_LIVE_TEMPLATE_REQUEST]:
			getListWidgetLiveRequest,
		[fetchListWidgetLiveTypes.FETCH_LIST_WIDGET_LIVE_TEMPLATE_SUCCEEDED]:
			getListWidgetLiveSucceeded,
		[fetchListWidgetLiveTypes.FETCH_LIST_WIDGET_LIVE_TEMPLATE_FAILED]: getListWidgetLiveFailed,

		[fetchEntribeStandardTermsTypes.FETCH_ENTRIBE_STANDARD_TERMS_REQUEST]:
			getEntribeStandardTermsRequest,
		[fetchEntribeStandardTermsTypes.FETCH_ENTRIBE_STANDARD_TERMS_SUCCEEDED]:
			getEntribeStandardTermsSucceeded,
		[fetchEntribeStandardTermsTypes.FETCH_ENTRIBE_STANDARD_TERMS_FAILED]:
			getEntribeStandardTermsFailed,

		[changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_REQUEST]:
			changeTemplateToWidgetsRequest,
		[changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_SUCCEEDED]:
			changeTemplateToWidgetsSucceeded,
		[changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_FAILED]:
			changeTemplateToWidgetsFailed,
		[changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_END]: changeTemplateToWidgetsEnd,

		[changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_REQUEST]: changeTemplateStatusRequest,
		[changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_SUCCEEDED]: changeTemplateStatusSucceeded,
		[changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_FAILED]: changeTemplateStatusFailed,
		[changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_END]: changeTemplateStatusEnd,

		[duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_REQUEST]:
			duplicateTemplateTermsRequest,
		[duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_SUCCEEDED]:
			duplicateTemplateTermsSucceeded,
		[duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_FAILED]: duplicateTemplateTermsFailed,
		[duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_END]: duplicateTemplateTermsEnd,

		[changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_REQUEST]: changeDefaultTemplateRequest,
		[changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_SUCCEEDED]:
			changeDefaultTemplateSucceeded,
		[changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_FAILED]: changeDefaultTemplateFailed,
		[changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_END]: changeDefaultTemplateEnd,

		[fetchTemplateWidgetTypes.FETCH_TEMPLATE_WIDGET_REQUEST]: getTemplateWidgetRequest,
		[fetchTemplateWidgetTypes.FETCH_TEMPLATE_WIDGET_SUCCEEDED]: getTemplateWidgetSucceeded,
		[fetchTemplateWidgetTypes.FETCH_TEMPLATE_WIDGET_FAILED]: getTemplateWidgetFailed,

		[fetchTemplateDefaultTypes.FETCH_TEMPLATE_DEFAULT_REQUEST]: getTemplateDefaultRequest,
		[fetchTemplateDefaultTypes.FETCH_TEMPLATE_DEFAULT_SUCCEEDED]: getTemplateDefaultSucceeded,
		[fetchTemplateDefaultTypes.FETCH_TEMPLATE_DEFAULT_FAILED]: getTemplateDefaultFailed,

		[changeDataStoreTypes.CHANGE_FIELD_DATA_CONTENT_LICENSING]: changeFieldDataStore,
		[changeDataStoreTypes.RESET_STORE_CONTENT_LICENSING]: resetStoreData,
	},
	initialState,
);

export default contentLicensingReducers;
