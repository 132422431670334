import { FilterItemDto } from '@models/filter';
import React from 'react';

export type FilterContentContextTypes = {
	handleSelectFilter: (val: number) => void;
	isLoading: boolean;
	typeConfirm: string;
	handleActionConfirm: (val: string) => void;
	handleOverwrite: () => void;
	hasChangeFilter: boolean;
	filterSelected: FilterItemDto | null;
	setFilterItemRequest: (val: FilterItemDto | null) => void;
	handleSelectFilterRemove: (val: FilterItemDto) => void;
	filterItemRequest: FilterItemDto | null;
	setVisibleCreateAlbum: (val: boolean) => void;
};

// Default context values
const values: FilterContentContextTypes = {
	handleSelectFilter: () => {},
	isLoading: false,
	typeConfirm: '',
	handleActionConfirm: () => {},
	handleOverwrite: () => {},
	hasChangeFilter: false,
	filterSelected: null,
	setFilterItemRequest: () => {},
	handleSelectFilterRemove: () => {},
	filterItemRequest: null,
	setVisibleCreateAlbum: () => {},
};

const FilterContentContext = React.createContext(values);
export default FilterContentContext;
