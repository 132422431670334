import { IconStar } from '@assets/icons';
import THEME from '@constants/themes/themes';
import Wrapper from '@cores/Wrapper';
import { Button, Collapse, Modal, Tabs } from 'antd';
import styled from 'styled-components';

export const StyledCurrentDesign = styled.div`
	background: ${THEME.colors.darkBlue2};
	height: 100%;
	position: relative;
`;

export const StyledListWidget = styled.div`
	padding: 8px 16px;
	height: calc(100vh - 203px);
	overflow: hidden;
	.widget-list-scrollbar {
		& > div {
			&:hover {
				&::-webkit-scrollbar-thumb {
					visibility: unset;
				}
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				visibility: hidden;
			}
			&:last-child {
				width: 0 !important;
			}
			&:first-child {
				margin: 0 !important;
				overflow: hidden auto !important;
			}
		}
	}
`;

export const StyledButtonBuild = styled(Button)`
	width: 100%;
`;

export const StyledFooter = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 16px;
	background: ${THEME.colors.darkBlue2};
`;

export const StyledWrapper = styled(Wrapper)`
	height: unset;
	margin-top: 50px;
	background-color: ${THEME.colors.darkBlue};
`;

export const StyledTabs = styled(Tabs)`
	.ant-tabs-nav {
		margin: 9px 16px 0px;
	}
	.ant-tabs-content {
		.ant-tabs-tabpane {
			height: 100%;
		}
	}
`;

export const StyledInfoDetailWidget = styled.div`
	position: absolute;
	left: 0;
	overflow: hidden;
	visibility: visible;
	opacity: 1;
	z-index: 200;
	transition: 0.25s ease-out;
	.content_detail,
	.bg-mask {
		visibility: visible;
		background-color: ${THEME.colors.darkBlue2};
		transition: 0.25s ease-out;
	}

	.content_detail {
		width: 100%;
		background: ${THEME.colors.darkBlue2};
	}

	&.hidden_detail {
		height: 0;
		width: 0;
		.content_detail,
		.bg-mask {
			height: 0;
			opacity: 0;
			visibility: hidden;
		}
	}
	&.show_detail {
		height: 100%;
		width: 100%;
		top: 0;
		.content_detail {
			opacity: 1;
			position: absolute;
			bottom: 0;
		}
		.bg-mask {
			opacity: 0.9;
			height: 100%;
		}
	}
`;

export const StyledTitle = styled.div`
	padding: 11px 16px;
	font-size: 1.143rem;
	font-weight: 600;
	color: ${THEME.colors.gray5};
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
	.icon-close {
		cursor: pointer;
	}
`;

export const StyledQrCode = styled.div`
	display: flex;
	margin-top: 7px;
	#qr-code_widget {
		margin-right: 8px;
		padding: 10px;
		background-color: ${THEME.colors.white};
	}
`;

export const StyledMask = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(1, 22, 39, 0.25);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const StyledWidgetItem = styled.div`
	position: relative;
	overflow: hidden;
	border-radius: 12px;
	background: #fff;
	height: 190px;
	width: 100%;
	color: ${THEME.colors.orangeBase};
	margin-top: 16px;
	cursor: pointer;
`;

export const StyledIconStar = styled(IconStar)`
	margin-left: 6px;
	&.star_default {
		fill: ${THEME.colors.googleYellow};
	}
`;

export const StyledEmbedUploaderModal = styled(Modal)`
	.ant-modal-header {
		padding-bottom: 0;
		border-bottom: 0;
	}

	.ant-modal-body {
		padding: 0 32px 32px;
		max-height: 80vh;
		overflow: auto;
	}
`;

export const StyledSessionInfo = styled.div<{ width?: string; padding?: string; margin?: string }>`
	width: ${({ width }) => width || '100%'};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ margin }) => margin && `margin: ${margin}`};
`;

export const StyledLink = styled.a<{ display?: string; margin?: string }>`
	font-size: 1rem;
	color: ${THEME.colors.blueBase};
	text-decoration: underline;
	display: ${({ display }) => display || 'block'};
	margin: ${({ margin }) => margin || '7px 0 0 0'};
`;

export const StyledWrapperLink = styled.div<{ margin?: string; padding?: string }>`
	position: relative;
	margin: ${({ margin }) => margin || '7px 0 0 0'};
	${({ padding }) => padding && `padding: ${padding}`};
`;

export const StyledWrapperQrCode = styled.div`
	padding: 10px;
	background-color: ${THEME.colors.white};
	width: 197px;
	height: 197px;
`;

export const StyledCollapse = styled(Collapse)``;

export const StyledHref = styled.a`
	font-weight: bold;
	color: ${THEME.colors.gray5};
`;
