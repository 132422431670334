import sidebarTypes, { ClientSettingsType } from '@stores/sidebar/sidebar.types';
import { handleActions, Action } from 'redux-actions';
import { ResponsePayloadDto } from '@models/common/store';
import { DEFAULT_ERROR } from '@constants/errors';
import { getClientSettings, getFolderSystem, setClientSettings } from '@helpers/userHelpers';
import _isEmpty from 'lodash.isempty';
import { genericWidgetUrl } from '../../config';
import { getObject } from '@helpers/storageHelpers';
import { FolderItemDto } from '@models/common/summary';

const clientSettings: ClientSettingsType = getClientSettings();
const folderSystem: FolderItemDto[] = getFolderSystem();
const initialState: any = {
	isCollapsedMenu: false,
	fetchingClientSettings: true,
	fetchingFolderSystem: true,
	folderSystem: folderSystem || [],
	pendoSettings: null,
	clientSettings: _isEmpty(clientSettings)
		? {
				autoCopyDefaultMessage: true,
				badWords: [],
				calendars: [],
				calendarsLimit: 0,
				channels: [],
				channelsLimit: 0,
				defaultBadWords: [],
				defaultMessageEmail: '',
				defaultMessageMobile: '',
				endCursor: '',
				featuresDisabled: [],
				flags: [],
				groupsLimit: 0,
				hashtags: '',
				hashtagsLimit: 0,
				instagramMentionUsernames: [],
				keywords: [],
				keywordsLimit: 0,
				labels: [],
				mentionUsernameLimit: 0,
				reviewersLimit: 0,
				showNewSelectsNotMessaged: true,
				socialMediaLimit: 0,
				subHashtags: [],
				twitterMentionUsernames: [],
				listExclude: [],
				facebookLoginNeeded: false,
				defaultWidgetLink: null,
				defaultRegisterLink: null,
				defaultGalleryLink: null,
				logoUrl: null,
				senderAddress: null,
				widgetLinks: [],
				socialPostsAnalysisLimit: 0,
				socialFeedsLimit: 0,
				instagramSearchEnabled: true,
				tiktokSearchEnabled: true,
				sentimentAnalysisEnabled: true,
				ambassadorEnabled: false,
				ambassadorMetricsRefreshUpdateAt: 0,
				ambassadorRefreshMetricsEnabled: false,
				ambassadorMetricsRefreshInProgress: false,
				ambassadorMetricsWaitingPeriod: 0,
		  }
		: clientSettings,
};

const fetchCollapseMenuRequest = (state: any, { payload }: any) => {
	return {
		...state,
	};
};

const fetchCollapseMenuSucceeded = (state: any, { payload }: any) => {
	const { collapsed } = payload;

	return {
		...state,
		isCollapsedMenu: collapsed,
	};
};

const initPendoRequest = (state: any, { payload }: any) => {
	return {
		...state,
	};
};

const initPendoSucceeded = (state: any, payload: any) => {
	return {
		...state,
		pendoSettings: payload,
	};
};

const fetchClientSettings = (state: any) => ({
	...state,
	fetchingClientSettings: true,
});

const clientSettingsSucceded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: clientSettings = {},
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === 200) {
		if (!clientSettings.defaultWidgetLink) {
			const user = getObject('user');
			clientSettings.defaultWidgetLink = `${genericWidgetUrl}?cl=${user.clientId}`;
		}
		return {
			...state,
			fetchingClientSettings: false,
			clientSettings,
			error: null,
		};
	}

	return {
		...state,
		fetchingClientSettings: false,
		clientSettings: null,
		error: status,
	};
};

const clientSettingsFailed = (state: any, { payload }: Action<ResponsePayloadDto>) => {
	const result = {
		...state,
		fetchingClientSettings: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const changeClientSettings = (state: any, { payload }: any) => {
	return {
		...state,
		clientSettings: payload,
	};
};

const changeFieldsClientSettings = (state: any, { payload }: any) => {
	const obj = { ...state?.clientSettings, ...payload };
	setClientSettings(obj);
	return {
		...state,
		clientSettings: obj,
	};
};

const folderSystemRequest = (state: any) => ({
	...state,
	fetchingFolderSystem: true,
});

const folderSystemSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: folderSystem = {},
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === 200) {
		return {
			...state,
			fetchingFolderSystem: false,
			folderSystem,
			error: null,
		};
	}

	return {
		...state,
		fetchingFolderSystem: false,
		folderSystem: [],
		error: status,
	};
};

const folderSystemFailed = (state: any, { payload }: Action<ResponsePayloadDto>) => {
	const result = {
		...state,
		fetchingFolderSystem: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const sidebarReducers = handleActions<any>(
	{
		[sidebarTypes.SIDEBAR_REQUEST]: fetchCollapseMenuRequest,
		[sidebarTypes.SIDEBAR_SUCCEEDED]: fetchCollapseMenuSucceeded,

		[sidebarTypes.CLIENT_SETTINGS_REQUEST]: fetchClientSettings,
		[sidebarTypes.CLIENT_SETTINGS_COMPLETED]: clientSettingsSucceded,
		[sidebarTypes.CLIENT_SETTINGS_FAILED]: clientSettingsFailed,
		[sidebarTypes.CHANGE_CLIENT_SETTING]: changeClientSettings,
		[sidebarTypes.CHANGE_FIELDS_CLIENT_SETTING]: changeFieldsClientSettings,

		[sidebarTypes.FOLDER_SYSTEM_REQUEST]: folderSystemRequest,
		[sidebarTypes.FOLDER_SYSTEM_COMPLETED]: folderSystemSucceeded,
		[sidebarTypes.FOLDER_SYSTEM_FAILED]: folderSystemFailed,

		[sidebarTypes.PENDO_INIT_REQUEST]: initPendoRequest,
		[sidebarTypes.PENDO_INIT_SUCCEEDED]: initPendoSucceeded,
	},
	initialState,
);

export default sidebarReducers;
