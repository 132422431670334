import { IconTrashWhite } from '@assets/icons';
import { UNIT_VALUE } from '@constants/common';
import { SAVED_CONTENT_FILTER_TYPES } from '@constants/filter/filterContent';
import FilterContentContext from '@contexts/Filter/filterContent';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { StyledSelect } from '@styled/Content/ContentLibrary/FilterStyled';
import { notFoundContent } from '@utils/renderComponent';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Option } = StyledSelect;

type PropTypes = {};
const SelectorSavedFilter = (props: PropTypes) => {
	const { handleSelectFilter, handleSelectFilterRemove, hasChangeFilter } =
		useContext(FilterContentContext);
	const { filterList = [] } = useSelector((state: ContentRootStateDto) => state.content);
	const { t } = useTranslation();

	return (
		<StyledSelect
			mode={undefined}
			showSearch
			notFoundContent={notFoundContent(t)}
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
			placeholder={t('filter.filter_content.choose_saved_filter')}
			virtual={false}
			optionFilterProp="children"
			showArrow
			onSelect={(value: any) => handleSelectFilter(value)}
			dropdownClassName="dropdown-menu dropdown-custom-content">
			<Option
				disabled={!hasChangeFilter}
				className="option_custom"
				key={UNIT_VALUE.SM_1}
				value={UNIT_VALUE.SM_1}>
				{t('filter.filter_content.create_new_saved_filter')}
			</Option>

			{filterList.map((f) => (
				<Option className="option_custom" key={f.id} value={f.id}>
					{f.name}
					{f.type === SAVED_CONTENT_FILTER_TYPES.CUSTOM && (
						<StyledIcon
							className="absolute icon_remove"
							top="4px"
							onClick={(e: React.MouseEvent<HTMLElement>) => {
								e.stopPropagation();
								e.preventDefault();
								handleSelectFilterRemove(f);
							}}
							size={20}
							right="2px">
							<IconTrashWhite />
						</StyledIcon>
					)}
				</Option>
			))}
		</StyledSelect>
	);
};

export default SelectorSavedFilter;
