import { IconMoreVert, IconStar } from '@assets/icons';
import PreviewWidgetModal from '@components/ModalCustom/PreviewWidgetModal';
import { DETAIL_TEMPLATE_API } from '@constants/APIs';
import { MESSAGE_TYPE, ROUTE_PATH, TYPE_FORMAT_DATE } from '@constants/common';
import { DEFAULT_ERROR } from '@constants/errors';
import { DISPLAY_TYPES, STATUS_TEMPLATE } from '@constants/licensingTemplate';
import THEME from '@constants/themes/themes';
import ContentLicensingContext from '@contexts/Settings/ContentLicensing';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { getRequest } from '@helpers/requestHelpers';
import { getUser } from '@helpers/userHelpers';
import { ActionNoClickTypes, ActionsTypes } from '@models/content/albums/albumManager';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import { TemplateTypes } from '@models/settings/contentLicensing';
import {
	changeDefaultTemplateRequest,
	duplicateTemplateTermsRequest,
	fetchDetailLiveWidgetRequest,
	updateStoreWidget,
} from '@stores/actions';
import {
	StyledDivider,
	StyledDropdown,
	StyledIcon,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledCardTemplate, StyledDescTerms } from '@styled/LicensingTemplate';
import { StyleStatus } from '@styled/LicensingTemplate/TemplateDetail';
import { StyledButtonMore } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { convertWidgetTerms } from '@utils/funcHelper';
import { generalMessage, renderListStatusTemplate } from '@utils/renderComponent';
import { Button, Menu, message } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ListWidgetPreview from '../../ListWidgetPreview';

type PropTypes = {
	template: TemplateTypes;
	[other: string]: any;
};
const CardTemplate = (props: PropTypes) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { liveWidget }: WidgetManagerStoreDto = useSelector((state: any) => state?.widgetManager);
	const { setIdTemplateAction, setIsLoading, setLoadingOther } =
		useContext(ContentLicensingContext);

	const [detailTemplate, setDetailTemplate] = useState<TemplateTypes | null>(null);

	const liveWidgetPreview = useMemo(() => {
		if (liveWidget && detailTemplate) {
			const { metadata } = liveWidget;
			return convertWidgetTerms({
				...liveWidget,
				terms: detailTemplate?.terms,
				metadata: JSON.parse(metadata),
			});
		}
		return null;
	}, [liveWidget, detailTemplate]);

	const { template } = props;

	const { name, status, createAt, createBy, terms, isDefault, widgetInfo, statusChangedAt } =
		template;

	const user = getUser();

	const [visiblePreviewWidget, setVisiblePreview] = useState<boolean>(false);

	const arrOptionMore: ActionsTypes[] = [
		{
			id: 1,
			click: () => {
				setIdTemplateAction(template?.id);
				dispatch(
					changeDefaultTemplateRequest({
						accountEmail: user?.email,
						termsTemplateId: template?.id,
					}),
				);
			},
			name: t('button.set_default'),
			hidden: isDefault || status !== STATUS_TEMPLATE.APPROVED,
		},
		{
			id: 2,
			click: () =>
				dispatch(
					duplicateTemplateTermsRequest({
						accountEmail: user?.email,
						termsTemplateId: template?.id,
					}),
				),
			name: t('button.duplicate'),
		},
	];

	const menuActionMore = (
		<Menu>
			{arrOptionMore.map((item: ActionsTypes) => {
				if (item?.hidden) {
					return null;
				}
				return (
					<Menu.Item key={item.id} onClick={item.click}>
						{item.name}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	const infoStatus = useMemo(() => {
		const arrOption: ActionNoClickTypes[] = renderListStatusTemplate(t);

		return arrOption.find((item) => item?.status === status) || arrOption[0];
	}, [status]);

	const subText = terms.map((item) => {
		const textAgree = item.agreementMessage ? ` “${item.agreementMessage || ''}` : '';
		const textLink = item?.displayType === DISPLAY_TYPES.IN_LINE ? '' : item?.hyperlinkText;
		const name = `${item.name || ''}${textAgree} `;
		const nodeLink = textLink && (
			<span style={{ textDecoration: 'underline' }}>{`${textLink}`}</span>
		);

		const data = {
			node: (
				<>
					{name}
					{nodeLink}
					{textAgree ? '“' : ''}
				</>
			),
			id: item.id,
		};
		if (item?.isMustView || item?.isTermsMandated) {
			data.node = (
				<>
					{`*${name}`}
					{nodeLink}
					{textAgree ? '“' : ''}
				</>
			);
		}
		return data;
	});

	const getDetailTemplate = async () => {
		getRequest(`${DETAIL_TEMPLATE_API}?termsTemplateId=${template?.id}`)
			.then((response) => {
				if (response.status === 200) {
					setDetailTemplate(response.body.data?.result);
				} else {
					generalMessage(response.body.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				message.error(DEFAULT_ERROR);
			})
			.finally(() => {
				setLoadingOther(false);
			});
	};

	const onPreviewWidget = (widgetId: number) => {
		setLoadingOther(true);
		dispatch(fetchDetailLiveWidgetRequest({ widgetId }));
		setVisiblePreview(true);
		getDetailTemplate();
	};

	const onClosePreview = () => {
		dispatch(updateStoreWidget({ liveWidget: null }));
		setVisiblePreview(false);
		setDetailTemplate(null);
	};

	const renderMoreInfo = () => {
		const time = statusChangedAt || createAt;
		const obj = {
			creator: t('content_licensing.updated_by', { val: createBy }),
			time: t('content_licensing.last_update', {
				val: convertUtcToLocalTimeWithFormat(time, TYPE_FORMAT_DATE.TIME),
			}),
		};
		if (status === STATUS_TEMPLATE.APPROVED) {
			obj.creator = t('content_licensing.approved_by', { val: createBy });
			obj.time = t('content_licensing.approved_date', {
				val: convertUtcToLocalTimeWithFormat(time, TYPE_FORMAT_DATE.TIME),
			});
		}
		if (status === STATUS_TEMPLATE.ARCHIVED) {
			obj.creator = t('content_licensing.archived_by', { val: createBy });
			obj.time = t('content_licensing.archived_date', {
				val: convertUtcToLocalTimeWithFormat(time, TYPE_FORMAT_DATE.TIME),
			});
		}
		return obj;
	};

	return (
		<>
			{liveWidgetPreview && (
				<PreviewWidgetModal
					visible={visiblePreviewWidget}
					widget={liveWidgetPreview}
					zIndex={1030}
					onCancel={() => onClosePreview()}
					enableTerms
				/>
			)}
			<StyledCardTemplate>
				<StyledWrapperTitle margin="0 0 8px 0">
					<StyledTitle
						fontSize="20px"
						lineHeight="unset"
						maxWidth={`calc(100% - 32px - ${isDefault ? '24px' : '0px'})`}
						className="unset_height overflow_text">
						{name}
					</StyledTitle>
					<StyledWrapperAction>
						{isDefault && (
							<StyledIcon fill={THEME.colors.yellowBase} margin="0 4px 0 0" size={20}>
								<IconStar />
							</StyledIcon>
						)}
						<StyledDropdown
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode as HTMLElement
							}
							placement="bottomRight"
							overlay={menuActionMore}
							trigger={['click']}>
							<StyledButtonMore>
								<IconMoreVert />
							</StyledButtonMore>
						</StyledDropdown>
					</StyledWrapperAction>
				</StyledWrapperTitle>
				<StyledWrapperContent margin="6px 0 0 0">
					<StyleStatus className={infoStatus.class}>
						<StyledIcon cursor="unset" size={20} {...infoStatus.styleIcon}>
							{infoStatus.icon}
						</StyledIcon>
						<StyledTitle
							margin="0 8px"
							className="unset_height"
							color={infoStatus.theme}
							fontSize="14px">
							{infoStatus.name}
						</StyledTitle>
					</StyleStatus>
				</StyledWrapperContent>
				<StyledTitle
					margin="8px 0 0 0"
					fontSize="14px"
					maxWidth="100%"
					className="unset_height overflow_text">
					{renderMoreInfo().creator}
				</StyledTitle>
				<StyledTitle
					margin="8px 0 0 0"
					fontSize="12px"
					maxWidth="100%"
					className="unset_height overflow_text">
					{renderMoreInfo().time}
				</StyledTitle>
				<StyledDivider margin="12px 0" />
				<StyledDescTerms className="custom_scroll_bar">
					{subText.map((item, idx) => (
						<StyledTitle
							margin={idx ? '4px 0 0 0' : '0'}
							fontSize="14px"
							maxWidth="100%"
							color={THEME.colors.darkBlue2}
							className="unset_height overflow_text"
							key={item.id}>
							{item.node}
						</StyledTitle>
					))}
				</StyledDescTerms>
				{status === STATUS_TEMPLATE.APPROVED && (
					<>
						<StyledDivider margin="12px 0" />
						<ListWidgetPreview
							listWidget={widgetInfo || []}
							onPreviewWidget={onPreviewWidget}
							isSummary
							setLoading={setIsLoading}
						/>
					</>
				)}
				<Button
					onClick={() => {
						history.push(`${ROUTE_PATH.CONTENT_LICENSING}/${template.id}`);
					}}
					style={{ width: '100%', marginTop: '16px' }}>
					{t('button.open')}
				</Button>
			</StyledCardTemplate>
		</>
	);
};

export default CardTemplate;
