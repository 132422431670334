import ModalMediaDetail from '@components/ModalCustom/ModalMediaDetail';
import CardSocial from '@components/SocialSearch/Feeds/SummaryFeed/CardSocial';
import { MOVE_CONTENT } from '@constants/content/contentLibrary';
import { SOCIAL_TYPE } from '@constants/social_search/feeds';
import { FAILED, SUCCEEDED } from '@constants/status';
import SocialActivityManagerContext from '@contexts/AmbassadorDashboard/socialActivity';
import { ContentStoreDto, ImgVersionDto } from '@models/content/contentLibrary/store';
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
	ContentVerificationScoreDto,
} from '@models/content/contentLibrary/summary';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { reviewContentEnd, reviewMultiContentsEnd } from '@stores/actions';
import { getContentByType, updateFavoriteSocialList } from '@utils/common';
import { useWindowSize } from '@utils/customHooks';
import { checkPositionContent } from '@utils/funcHelper';
import { convertSocialId } from '@utils/socialSearch';
import { Col, Row } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelectable } from 'react-selectable-fast';

const SelectableItem = createSelectable(CardSocial);

type PropTypes = {};

const SummarySocial = (props: PropTypes) => {
	const dispatch = useDispatch();
	// Reducers
	const { reviewContentStatus, reviewedContent }: ContentStoreDto = useSelector(
		(state: any) => state.content,
	);

	const { col, socialList, setSocialList, thumbFull } = useContext(SocialActivityManagerContext);

	const refSocial = useRef<any>({});
	const [heightCard, setHeightCard] = useState<number>(0);
	const [socialDetail, setSocialDetail] = useState<SocialContentContainerDto | null>(null);
	const [content, setContent] = useState<ContentContainerDto | null>(null);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [isFirstContent, setIsFirstContent] = useState<boolean>(false);
	const [isLastContent, setIsLastContent] = useState<boolean>(false);
	const [contentIdMove, setContentIdMove] = useState<number | null>(null);
	const [socialId, setSocialId] = useState<string | null>(null);

	const [widthWindow, heightWindow] = useWindowSize();

	useEffect(() => {
		if (reviewContentStatus === SUCCEEDED && reviewedContent) {
			updateAfterReviewingContent([reviewedContent]);
		}

		if (reviewContentStatus === FAILED || reviewContentStatus === SUCCEEDED) {
			dispatch(reviewContentEnd());
		}
	}, [reviewContentStatus]);

	const handleContentDetails = (index: number) => {
		setCurrentIndex(index);

		const res = checkPositionContent(index, socialList.length);
		setIsFirstContent(res.isFirst);
		setIsLastContent(res.isLast);
		onChangeSocialDetail(socialList[index]);
	};

	const onChangeSocialDetail = (social: SocialContentContainerDto | null) => {
		let socialNew = social;
		let contentNew = null;
		const newSocialId = social ? convertSocialId(social) : null;
		setSocialId(newSocialId);

		if (social && newSocialId !== socialId && social?.content) {
			socialNew = null;
			contentNew = social.content;
		}

		setSocialDetail(socialNew);
		setContent(contentNew);
	};

	const moveContent = (action: string, changeIndex?: boolean) => {
		let currentIndexTemp: number = currentIndex;

		const maxContentIndex = socialList.length - 1;

		if (action === MOVE_CONTENT.NEXT) {
			currentIndexTemp = currentIndex + 1;
		}
		if (action === MOVE_CONTENT.PREVIOUS) {
			currentIndexTemp = currentIndex - 1;
		}

		if (currentIndexTemp <= 0) {
			currentIndexTemp = 0;
		}

		if (currentIndexTemp >= maxContentIndex) {
			currentIndexTemp = maxContentIndex;
		}

		if (changeIndex) {
			setCurrentIndex(currentIndexTemp);
		}

		handleContentDetails(currentIndexTemp);
	};

	const handleCloseContentDetails = () => {
		setContent(null);
		setSocialDetail(null);
		setSocialId(null);
		setIsFirstContent(false);
		setIsLastContent(false);
	};

	const onChangeAfterAction = async (action: string, contentsAfterAction?: number[]) => {
		const newList = socialList.filter((item) => convertSocialId(item) !== socialId);

		const res = checkPositionContent(currentIndex, newList.length);
		setIsFirstContent(res.isFirst);
		setIsLastContent(res.isLast);
		setSocialList(newList);

		if (res.isLast) {
			handleCloseContentDetails();
		} else {
			onChangeSocialDetail(newList[currentIndex]);
		}
	};

	const updateAfterReviewingContent = (reviewedContents: ContentReviewDto[]) => {
		dispatch(reviewContentEnd());
		dispatch(reviewMultiContentsEnd());
		if (contentIdMove) {
			socialList.forEach((item) => {
				if (item?.content) {
					const contentChangeType = getContentByType(item.content);
					if (contentChangeType?.id === contentIdMove) {
						item.imported = false;
						item.autoImported = false;
						item.content = null;
						if (socialId === convertSocialId(item)) {
							handleContentDetails(currentIndex + 1);
						}
					}
				}
			});

			setContentIdMove(null);
			return;
		}

		socialList.forEach((item) => {
			if (item?.content) {
				const contentChangeType: ContentDto = getContentByType(item.content);
				if (contentChangeType?.id === reviewedContents[0].contentId) {
					contentChangeType.review = reviewedContents[0];
					contentChangeType.review.isRecentlyAdded = false;
					if (convertSocialId(item) === socialId) {
						setContent(item.content);
					}
					const customCheck = contentChangeType.review.custom;
					if (reviewedContents?.length === 1) {
						const customFieldUpdate = reviewedContents[0].custom;
						if (customFieldUpdate && customCheck) {
							customFieldUpdate.forEach((field) => {
								if (field.key && contentChangeType.review.custom) {
									const idx = customCheck.findIndex(
										(cField) => cField.key === field.key,
									);
									if (idx !== -1) {
										const newCustoms = [...contentChangeType.review.custom];
										newCustoms[idx] = field;
										contentChangeType.review.custom = newCustoms;
									}
								}
							});
						}
					}
				}
			}
		});
	};

	const handleUpdateNewVersion = (contentId: number, version: ImgVersionDto) => {
		socialList.forEach((item) => {
			if (item?.content) {
				const contentChangeType: ContentDto = getContentByType(item.content);
				if (contentChangeType?.id === contentId) {
					contentChangeType.contentUrl = version?.contentURL;
					contentChangeType.thumbnail = version?.thumbnailURL;
					if (convertSocialId(item) === socialId) {
						setContent(item.content);
					}
				}
			}
		});
	};

	const markAsFavoriteContent = (favoriteContent: ContentDto) => {
		updateFavoriteSocialList(favoriteContent, socialList, socialId);
	};

	const updateVerificationScrore = (
		content: ContentDto,
		verificationScore: ContentVerificationScoreDto,
	) => {
		socialList.forEach((item) => {
			if (item?.content) {
				const contentChangeType: ContentDto = getContentByType(item.content);
				if (convertSocialId(item) === socialId) {
					contentChangeType.verificationScore = verificationScore;
					setContent(item.content);
				}
			}
		});
	};

	const handleSetWidthContent = () => {
		if (socialList.length > 0 && refSocial?.current?.offsetWidth) {
			// exclude padding and margin
			setHeightCard(Math.floor(refSocial.current.offsetWidth / (thumbFull ? 1.4 : 2) - 20));
		}
	};

	const updateMediaImgUrl = (id: number, src: string, idConvert: string) => {
		socialList.map((data: any, index: number) => {
			if (convertSocialId(data) === idConvert && index === currentIndex) {
				data.imgUrl = src;
				onChangeSocialDetail({ ...data });
			}
		});
	};

	useEffect(() => {
		handleSetWidthContent();
	}, [col, socialList, thumbFull, widthWindow]);

	return (
		<>
			<ModalMediaDetail
				visible={!!content || !!socialDetail}
				isSocial={!content}
				socialProps={{
					socialContent: socialDetail,
					activeSocialType: socialDetail?.socialSource?.toLowerCase() || '',
					updateMediaImgUrl,
					socialBlockCreatorDisable: true,
					pauseNextContentAfterImport: true,
					socialBookmarkDisable: true,
				}}
				contentProps={{
					setContentIdMove,
					content,
					updateAfterReviewingContent,
					CDMBlockCreatorDisabled: true,
					isDashboardAmbassador: true,
					markAsFavoriteContent,
					onChangeAfterAction,
					updateVerificationScrore,
				}}
				isFirstContent={isFirstContent}
				isLastContent={isLastContent}
				moveContent={moveContent}
				onCancel={handleCloseContentDetails}
			/>
			<Row style={{ marginRight: 0 }} gutter={[12, 12]}>
				{socialList.map((item: SocialContentContainerDto, idx: number) => (
					<Col
						ref={(element) => {
							refSocial.current = element;
						}}
						key={convertSocialId(item)}
						span={24 / col}>
						{heightCard > 0 && (
							<CardSocial
								isSelected={false}
								isSelecting={false}
								selectableRef={() => null}
								selectContent={() => handleContentDetails(idx)}
								heightCard={heightCard}
								social={item}
								keyItem={convertSocialId(item)}
								thumbFull={thumbFull}
								onSelectItems={() => {}}
								noSelect={true}
								socialType={SOCIAL_TYPE.SOCIAL_POST}
							/>
						)}
					</Col>
				))}
			</Row>
		</>
	);
};

export default SummarySocial;
