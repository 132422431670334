import headerTypes from '@stores/header/header.types';
import HeaderDto from '@models/header';

const INITIAL_STATE: HeaderDto = {
	content: {
		search: '',
	},
	creatorsDashboard: {
		selected: 'all',
		byDate: {
			startDate: '',
			endDate: '',
		},
	},
	creatorsSummary: {
		search: '',
		segmentFilter: '',
		scrollSegmentPage: 1,
		isAddToNewSegment: false,
	},
};

const headerReducers = (state: HeaderDto = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case headerTypes.HEADER_REQUEST:
			return {
				...state,
			};
		case headerTypes.HEADER_SUCCEEDED:
			return {
				...action.payload,
			};
		case headerTypes.HEADER_FAILED:
			return {
				...action.payload,
			};
		case headerTypes.HEADER_GET_FILTER_SEGMENT:
			return {
				...state,
				creatorsSummary: {
					...state.creatorsSummary,
					isAddToNewSegment: action.payload,
				},
			};
		default:
			return state;
	}
};

export default headerReducers;
