import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import EditProfile from '@components/Settings/UserProfile/EditProfile';
import THEME from '@constants/themes/themes';
import { LoadingWrapper } from '@cores/index';
import { StyledRow, StyledText, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledContainer, StyledMenuItem, StyledCol } from '@styled/Settings/UserProfileStyled';

import UserProfileContext from '@contexts/Settings/UserProfile';
import { MenuComponentItemDto } from '@models/common/summary';
import Security from '@components/Settings/UserProfile/Security';
import { useSelector } from 'react-redux';
import { UserStoreType } from '@stores/user/user.types';
import { IN_PROGRESS } from '@constants/status';
import { releaseVersion } from '@config/index';

const SettingsUserProfile = () => {
	const { t } = useTranslation();
	const { disableMFAStatus }: UserStoreType = useSelector((state: any) => state.user);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const SECTIONS = {
		PROFILE: 'PROFILE',
		SECURITY: 'SECURITY',
	};
	const [currentSection, setCurrentSection] = useState<string | number>(SECTIONS.PROFILE);
	const menu: MenuComponentItemDto[] = [
		{
			id: SECTIONS.PROFILE,
			label: 'setting.user_profile.edit_profile',
		},
		{
			id: SECTIONS.SECURITY,
			label: 'setting.user_profile.security',
		},
	];

	const renderEquivalentContent = () => {
		switch (currentSection) {
			case SECTIONS.PROFILE:
				return <EditProfile />;

			default:
				return <Security />;
		}
	};

	const contextValues = {
		setIsLoading,
	};

	const isAdditionalLoading = isLoading || disableMFAStatus === IN_PROGRESS;

	return (
		<UserProfileContext.Provider value={contextValues}>
			<LoadingWrapper isLoading={isAdditionalLoading}>
				<StyledRow>
					<StyledCol span={6}>
						<StyledContainer>
							{menu.map((item: MenuComponentItemDto) => {
								const { id, label } = item;
								return (
									<StyledMenuItem
										className={currentSection === id ? 'active' : ''}
										onClick={() => {
											setCurrentSection(id);
										}}
										key={id}>
										{t(label)}
									</StyledMenuItem>
								);
							})}
						</StyledContainer>
					</StyledCol>
					<StyledCol span={11}>
						<StyledContainer
							padding="0"
							background={THEME.colors.darkBlue2}
							width="100%">
							{renderEquivalentContent()}
						</StyledContainer>
					</StyledCol>
				</StyledRow>
				{currentSection === SECTIONS.SECURITY && (
					<StyledWrapperContent position="fixed" bottom="32px" right="32px">
						<StyledText>{`${t(
							'setting.user_profile.entribe_version',
						)}: ${releaseVersion}`}</StyledText>
					</StyledWrapperContent>
				)}
			</LoadingWrapper>
		</UserProfileContext.Provider>
	);
};

export default SettingsUserProfile;
