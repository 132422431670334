import { urlGalleryTag, urlUploaderTag } from '@config/index';
import { BOOTSTRAP_CDN, GOOGLE_FONT } from '@constants/landingPageManager';
import THEME from '@constants/themes/themes';
import LandingPageManagerContext from '@contexts/LandingPageManager';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import {
	StyledLandingPageTemplate,
	StyledLandingPageTemplateIframe,
} from '@styled/LandingPageManager/LandingPageTemplateStyled';
import { isJSONString } from '@utils/common';
import React, { useContext, useMemo } from 'react';

const LandingPagePreview = () => {
	const { currentLandingPage } = useContext(LandingPageManagerContext);
	const DEFAULT_VALUES = {
		htmlString: '',
		cssString: '',
	};

	const srcDoc = useMemo(() => {
		if (currentLandingPage) {
			const metadata =
				isJSONString(currentLandingPage.metadata) && currentLandingPage
					? JSON.parse(currentLandingPage.metadata)
					: DEFAULT_VALUES;
			const { metaTitle = '', metaDescription = '' } = metadata || {};

			let customizedCss = metadata.cssString;
			// Revert initial css that avoids breaking UI when the uploader is shown.
			customizedCss = customizedCss?.replace(
				'body{padding-top:15px;padding-right:15px;padding-bottom:15px;padding-left:15px;overflow:auto !important;}',
				'body{padding: 15px;}',
			);

			return `<!DOCTYPE html>
                    <html lang='en'>
                        <head>
                        	<title>${metaTitle}</title>
							<meta name="description" content=${metaDescription}>
                            <script src="${urlUploaderTag}"></script>
                            <script defer type="text/javascript" src="${urlGalleryTag}"></script>
                            <link href="${BOOTSTRAP_CDN.CSS}" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
                            <link rel="preconnect" href="https://fonts.googleapis.com">
                            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                            <link href="${GOOGLE_FONT.IBM_FLEX_SERIF}" rel="stylesheet">
                            <link href="${GOOGLE_FONT.IBM_FLEX_SANS}" rel="stylesheet">
                            <style>${customizedCss}</style>
                        </head>
                        <body>
                            ${metadata.htmlString}
                            <script src="${BOOTSTRAP_CDN.BUNDLE}" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
                            <script src="${BOOTSTRAP_CDN.POPPER}" integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r" crossorigin="anonymous"></script>
                            <script src="${BOOTSTRAP_CDN.MAIN}" integrity="sha384-0pUGZvbkm6XF6gxjEnlmuGrJXVbNuzT9qBBavbLwCsOGabYfZo0T0to5eqruptLy" crossorigin="anonymous"></script>
                        </body>
                    </html>`;
		}

		return null;
	}, [currentLandingPage]);

	return (
		<StyledWrapperContent height="100%" background={THEME.colors.darkBlue2} padding="18px">
			<StyledLandingPageTemplate>
				{srcDoc && (
					<StyledLandingPageTemplateIframe
						title="campaign-community"
						width="100%"
						height="100%"
						srcDoc={srcDoc}
					/>
				)}
			</StyledLandingPageTemplate>
		</StyledWrapperContent>
	);
};

export default LandingPagePreview;
