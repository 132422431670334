import { IconAltAdd, IconCheck, IconMoreVert } from '@assets/icons';
import { ROUTE_PATH } from '@constants/common';
import { FAILED, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import ContentLicensingContext from '@contexts/Settings/ContentLicensing';
import SearchBar from '@cores/SearchBar/SearchBar';
import { getUser } from '@helpers/userHelpers';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';
import {
	createTemplateEnd,
	createTemplateRequest,
} from '@stores/contentLicensing/contentLicensing.action';
import {
	StyledDropdown,
	StyledIcon,
	StyledWrapperAction,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import {
	StyledContainerListOption,
	StyledNameOption,
	StyledOptionItem,
	StyledWrapperOverlayDropdown,
} from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledButtonMore } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { Button, Menu } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ActionBar = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { statusCreateTemplate, error, templateCreate }: ContentLicensingStoreDto = useSelector(
		(state: any) => state?.contentLicensing,
	);
	const { bodyTemplate, setBodyTemplate, paramTemplate, setParamTemplate } =
		useContext(ContentLicensingContext);
	const { isShowApproved, isShowArchived, isShowDrafts } = bodyTemplate;
	const history = useHistory();

	const user = getUser();

	const onPressSearch = (value: string) => {
		setBodyTemplate({ ...bodyTemplate, search: value?.trim() || '' });
	};
	const handleFilter = (field: any) => {
		setBodyTemplate({ ...bodyTemplate, ...field });
		setParamTemplate({ ...paramTemplate, page: 1 });
	};

	const actionFilter: ActionsTypes[] = [
		{
			id: 1,
			name: t('content_licensing.show_approved'),
			click: () => handleFilter({ isShowApproved: !isShowApproved }),
			active: isShowApproved,
		},
		{
			id: 2,
			name: t('content_licensing.show_drafts'),
			click: () => handleFilter({ isShowDrafts: !isShowDrafts }),
			active: isShowDrafts,
		},
		{
			id: 3,
			name: t('content_licensing.show_archived'),
			click: () => handleFilter({ isShowArchived: !isShowArchived }),
			active: isShowArchived,
		},
	];

	const menuActions = (
		<StyledWrapperOverlayDropdown>
			<StyledContainerListOption>
				{actionFilter.map((item: ActionsTypes) => {
					if (item?.hidden) {
						return null;
					}
					return (
						<StyledOptionItem key={item.id} onClick={item.click}>
							<StyledNameOption>{item.name}</StyledNameOption>
							{item?.active && (
								<StyledIcon>
									<IconCheck />
								</StyledIcon>
							)}
						</StyledOptionItem>
					);
				})}
			</StyledContainerListOption>
		</StyledWrapperOverlayDropdown>
	);

	return (
		<StyledWrapperTitle>
			<SearchBar
				style={{ marginRight: '16px' }}
				placeholder={t('placeholder.search_template_widget')}
				onSearch={onPressSearch}
				enterButton
				width={'320px'}
			/>
			<StyledWrapperAction>
				<StyledDropdown
					className="dropdown_action"
					getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
					placement="bottomRight"
					overlay={menuActions}
					trigger={['click']}>
					<StyledButtonMore>
						<IconMoreVert />
					</StyledButtonMore>
				</StyledDropdown>
				<Button
					style={{ marginLeft: '8px' }}
					icon={
						<StyledIcon fill={THEME.colors.white}>
							<IconAltAdd />
						</StyledIcon>
					}
					onClick={() => dispatch(createTemplateRequest(user?.email))}
					type="primary">
					{t('button.create_new_template')}
				</Button>
			</StyledWrapperAction>
		</StyledWrapperTitle>
	);
};

export default ActionBar;
