import { MailMessageCreatorType } from '@models/creator/summary';
import { createContext } from 'react';

// Types
type CreatorProfileContextTypes = {
	modalType: string;
	setModalType: (type: string) => void;
	setFocusHistory: (val: boolean) => void;
	focusMessageHistory: boolean;
	collapseMessage: boolean;
	setCollapseMessage: (val: boolean) => void;
	mailHistory: MailMessageCreatorType | null;
	setMailHistory: (val: MailMessageCreatorType | null) => void;
	messageHistoryCreator: MailMessageCreatorType[];
	setMessageHistoryCreator: (val: MailMessageCreatorType[]) => void;
};

// Default value
const data: CreatorProfileContextTypes = {
	modalType: '',
	setModalType: () => {},
	setFocusHistory: () => {},
	focusMessageHistory: false,
	collapseMessage: false,
	setCollapseMessage: () => {},
	mailHistory: null,
	setMailHistory: () => {},
	messageHistoryCreator: [],
	setMessageHistoryCreator: () => {},
};

const CreatorProfileContext = createContext(data);

export default CreatorProfileContext;
