import { IconNoteMessage } from '@assets/icons';
import { PATTERNS } from '@constants/common';
import { SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import {
	ADDRESS_CREATOR_INFO_FIELD,
	BASIC_CREATOR_INFO_FIELD,
	SOCIAL_CREATOR_INFO_FIELD,
	TRIMMED_CREATOR_INFO_FIELD,
} from '@constants/creator';
import { SOCIAL_PROFILE } from '@constants/social_search';
import THEME from '@constants/themes/themes';
import EditableElement from '@cores/EditableElement';
import { CreatorProfileSummaryDto } from '@models/creator/profile';
import {
	StyledFlex,
	StyledFormItem,
	StyledIcon,
	StyledSelect,
	StyledText,
	StyledTextArea,
} from '@styled/Common/CommonStyled';
import { replaceAtChar } from '@utils/common';
import { formatDisplayedAddress, validateFBURL, validateRequiredValue } from '@utils/creator';
import { dataState } from '@utils/dataStateUSA';
import { notFoundContent } from '@utils/renderComponent';
import { Col, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

type BasicInformationProps = {
	creatorDetails: CreatorProfileSummaryDto | null;
	cityList: string[];
	form: any;
};

const BasicInformation = (props: BasicInformationProps) => {
	const { t } = useTranslation();
	const { creatorDetails, form, cityList = [] } = props;
	const {
		firstName = '',
		lastName = '',
		zipCode = '',
		phone = '',
		email = '',
		social: {
			instagramUsername = '',
			tiktokUsername = '',
			twitterUsername = '',
			youtubeUsername = '',
			facebookProfileUrl = '',
		} = {},
		address = '',
		status,
	} = creatorDetails || {};

	const TYPE = {
		BASIC_INFO: 'BASIC_INFO',
		SOCIAL_INFO: 'SOCIAL_INFO',
	};

	const editableAddress = () => {
		return (
			<>
				<Col span={24}>
					<StyledFormItem name={ADDRESS_CREATOR_INFO_FIELD.STREET}>
						<StyledTextArea rows={3} autoFocus />
					</StyledFormItem>
				</Col>
				<Row gutter={[12, 12]}>
					<Col span={8}>
						<StyledFormItem name={ADDRESS_CREATOR_INFO_FIELD.STATE}>
							<StyledSelect
								notFoundContent={notFoundContent(t)}
								placeholder={''}
								getPopupContainer={(triggerNode: HTMLElement) =>
									triggerNode.parentNode as HTMLElement
								}
								showSearch>
								{dataState.map((item) => {
									return (
										<Select.Option key={item?.state} value={item.state}>
											{item?.state}
										</Select.Option>
									);
								})}
							</StyledSelect>
						</StyledFormItem>
					</Col>
					<Col span={8}>
						<StyledFormItem name="city">
							<StyledSelect
								notFoundContent={notFoundContent(t)}
								placeholder={''}
								getPopupContainer={(triggerNode: HTMLElement) => {
									document
										.querySelectorAll('.ant-select-selection-search-input')
										.forEach((e) => {
											e.setAttribute('autocomplete', 'none');
										});
									return triggerNode.parentNode as HTMLElement;
								}}
								showSearch>
								{cityList.map((item) => {
									return (
										<Select.Option key={item} value={item}>
											{item}
										</Select.Option>
									);
								})}
							</StyledSelect>
						</StyledFormItem>
					</Col>
					<Col span={8}>
						<StyledFormItem name={BASIC_CREATOR_INFO_FIELD.ZIP_CODE}>
							<Input />
						</StyledFormItem>
					</Col>
				</Row>
			</>
		);
	};

	const creatorInfo = [
		{
			label: t('creator_profile.contact.firstName'),
			value: firstName,
			fieldName: BASIC_CREATOR_INFO_FIELD.FIRST_NAME,
		},
		{
			label: t('creator_profile.contact.lastName'),
			value: lastName,
			fieldName: BASIC_CREATOR_INFO_FIELD.LAST_NAME,
		},
		{
			label: t('creator_profile.contact.email'),
			value: email,
			fieldName: 'email',
			rules: [
				{
					type: 'email',
					message: t('creator_summary.modal.invalid_email'),
				},
				{
					validator: (_: any, value: string) => validateRequiredValue(_, value, form, t),
				},
			],
		},
		{
			label: t('creator_profile.contact.youtube'),
			socialUsername: youtubeUsername,
			source: SOCIAL_SOURCE.YOUTUBE,
			fieldName: SOCIAL_CREATOR_INFO_FIELD.YOUTUBE,
			type: TYPE.SOCIAL_INFO,
		},
		{
			label: t('creator_profile.contact.instagram'),
			socialUsername: instagramUsername,
			source: SOCIAL_SOURCE.INSTAGRAM,
			fieldName: SOCIAL_CREATOR_INFO_FIELD.INSTAGRAM,
			type: TYPE.SOCIAL_INFO,
			rules: [
				{
					max: 30,
					message: t('creator_summary.modal.length_instagram'),
				},
				{
					pattern: PATTERNS.INSTAGRAM_USERNAME,
					message: t('validation.invalid_message', {
						field: t('creator_profile.contact.instagram'),
					}),
				},
			],
		},
		{
			label: t('creator_profile.contact.facebook'),
			value: facebookProfileUrl,
			onclick: () => {
				window.open(facebookProfileUrl, '_blank');
			},
			textColor: THEME.colors.orangeBase,
			fieldName: 'facebookProfileUrl',
			rules: [
				{
					validator: (_: any, value: string) => validateFBURL(_, value, t),
				},
			],
		},
		{
			label: t('creator_profile.contact.tiktok'),
			socialUsername: tiktokUsername,
			source: SOCIAL_SOURCE.TIKTOK,
			fieldName: SOCIAL_CREATOR_INFO_FIELD.TIKTOK,
			type: TYPE.SOCIAL_INFO,
			rules: [
				{ max: 30, message: t('creator_summary.modal.length_tiktok') },
				{
					pattern: PATTERNS.TIKTOK_USERNAME,
					message: t('validation.invalid_message', {
						field: t('creator_profile.contact.tiktok'),
					}),
				},
			],
		},
		{
			label: t('creator_profile.contact.phone'),
			value: phone,
			fieldName: 'phone',
			rules: [
				{
					pattern: PATTERNS.PHONE_NUMBER,
					message: t('creator_summary.modal.invalid_phone'),
				},
			],
		},
		{
			label: t('creator_profile.contact.twitter'),
			socialUsername: twitterUsername,
			source: SOCIAL_SOURCE.TWITTER,
			fieldName: SOCIAL_CREATOR_INFO_FIELD.TWITTER,
			type: TYPE.SOCIAL_INFO,
			rules: [
				{ max: 30, message: t('creator_summary.modal.length_twitter') },
				{
					pattern: PATTERNS.TWITTER_USERNAME,
					message: t('validation.invalid_message', {
						field: t('creator_profile.contact.twitter'),
					}),
				},
			],
		},
		{
			label: t('creator_summary.mailing_address'),
			value: formatDisplayedAddress(address, zipCode, true),
			fieldName: 'mailingAddress',
			span: {
				container: 24,
				label: 5,
				value: 18,
			},
			editElement: editableAddress(),
		},
	];

	const renderSocialUsername = (socialItem: {
		socialUsername: string;
		source: string;
		fieldName: string;
		rules: any;
		otherFormItemProps: any;
	}) => {
		const { socialUsername, source, fieldName, rules, otherFormItemProps } = socialItem || {};
		let href = '';

		switch (true) {
			case source.includes(SOCIAL_SOURCE.INSTAGRAM): {
				href = SOCIAL_PROFILE.INSTAGRAM;
				break;
			}
			case source.includes(SOCIAL_SOURCE.TIKTOK): {
				href = SOCIAL_PROFILE.TIKTOK;
				break;
			}

			case source.includes(SOCIAL_SOURCE.TWITTER): {
				href = SOCIAL_PROFILE.TWTTER;
				break;
			}

			default:
				href = SOCIAL_PROFILE.YOUTUBE;
				break;
		}

		if (rules) {
			rules.push({
				validator: (_: any, value: string) => validateRequiredValue(_, value, form, t),
			});
		}

		return (
			<StyledFlex justify="flex-start">
				<EditableElement
					form={form}
					onClick={() => {
						if (!socialUsername) {
							return;
						}
						window.open(`${href}${replaceAtChar(socialUsername)}`, '_blank');
					}}
					info={replaceAtChar(socialUsername) || ''}
					textColor={THEME.colors.orangeBase}
					fieldName={fieldName}
					rules={rules}
					otherFormItemProps={otherFormItemProps}
				/>
			</StyledFlex>
		);
	};

	return (
		<Row gutter={[8, 8]}>
			{creatorInfo.map((item) => {
				const otherFormItemProps: any = {};
				if (TRIMMED_CREATOR_INFO_FIELD.includes(item.fieldName)) {
					otherFormItemProps.normalize = (value: string) => value.trim();
				}

				const editableElement = (
					<EditableElement
						form={form}
						info={item?.value || ''}
						fieldName={item.fieldName}
						rules={item.rules}
						editElement={item?.editElement}
						otherFormItemProps={otherFormItemProps}
					/>
				);

				if (item.type === TYPE.SOCIAL_INFO) {
					const socialItem = {
						socialUsername: item?.socialUsername || '',
						source: item?.source || '',
						fieldName: item.fieldName,
						rules: item?.rules || [],
						otherFormItemProps,
					};
					return (
						<Col span={item?.span || 12} key={item.source}>
							<Row>
								<Col span={6}>
									<StyledText
										className="overflow"
										whiteSpace="nowrap"
										title={item.label}
										margin="2px 0 0 0"
										color={THEME.colors.gray1}>
										{item.label}
									</StyledText>
								</Col>
								<Col span={18}>{renderSocialUsername(socialItem)}</Col>
							</Row>
						</Col>
					);
				}

				return (
					<Col key={item.fieldName} span={item?.span?.container || 12}>
						<Row>
							<Col span={item?.span?.label || 6}>
								<StyledText
									className="overflow"
									whiteSpace="nowrap"
									title={item.label}
									margin="2px 0 0 0"
									color={THEME.colors.gray1}>
									{item.label}
								</StyledText>
							</Col>
							<Col span={item?.span?.value || 18}>
								{item.label === t('creator_profile.contact.email') ? (
									<StyledFlex justify="flex-start">
										{status?.invalidEmail && (
											<StyledIcon
												margin="0 8px 0 0"
												fillPath="rgb(234, 67, 53)">
												<IconNoteMessage />
											</StyledIcon>
										)}
										{editableElement}
									</StyledFlex>
								) : (
									editableElement
								)}
							</Col>
						</Row>
					</Col>
				);
			})}
		</Row>
	);
};

export default BasicInformation;
