import IntegrationsTab from '@components/Settings/Integrations/IntegrationsTab';
import Tremendous from '@components/Settings/Integrations/Tremendous';
import { TYPE_ACTIONS } from '@constants/common';
import { INTEGRATIONS_TAB } from '@constants/settings/integrations';
import SettingIntegrationsContext from '@contexts/Settings/SettingIntegrations';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { TremendousStoreDto } from '@models/settings/tremendous/store';
import { UserRootStateDto } from '@stores/user/user.types';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledContainerSettingAggregator } from '@styled/Settings/SettingAggregatorStyled';
import { Col, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SettingIntegrations = () => {
	const { t } = useTranslation();
	const { fetchingTremendous }: TremendousStoreDto = useSelector(
		(state: any) => state.tremendous,
	);
	const { fetchingUser } = useSelector((state: UserRootStateDto) => state.user);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [typeAction, setTypeAction] = useState<string>('');
	const [activeTab, setActiveTab] = useState<string>(INTEGRATIONS_TAB.TREMENDOUS);

	const otherPropConfirm = useMemo(() => {
		const result: any = {};

		return result;
	}, [typeAction]);

	const renderContentPopupConfirm = () => {
		if (typeAction === TYPE_ACTIONS.REMOVE) {
			return (
				<>
					{t('social_aggregator.message.message_remove')} <br />
				</>
			);
		}

		if (typeAction === TYPE_ACTIONS.UPLOAD) {
			return (
				<>
					{t('social_aggregator.message.message_override_upload')} <br />
				</>
			);
		}
		return '';
	};

	const valueContext = {
		typeAction,
		setTypeAction,
		activeTab,
		setActiveTab,
		isLoading,
		setIsLoading,
	};

	const loading = isLoading || fetchingTremendous;
	return (
		<SettingIntegrationsContext.Provider value={valueContext}>
			<ConfirmModal
				width={450}
				isOpen={typeAction ? true : false}
				onClose={() => setTypeAction('')}
				confirmLoading={isLoading}
				{...otherPropConfirm}>
				{renderContentPopupConfirm()}
			</ConfirmModal>

			<StyledContainerSettingAggregator>
				<Row style={{ height: '100%' }} gutter={[24, 24]}>
					<Col style={{ height: '100%' }} span={6}>
						<IntegrationsTab />
					</Col>
					<Col style={{ height: '100%' }} span={18}>
						<LoadingWrapper
							heightLoading="auto"
							isLoading={fetchingUser ? false : loading}>
							<StyledWrapperContent height="100%">
								{activeTab === INTEGRATIONS_TAB.TREMENDOUS && <Tremendous />}
							</StyledWrapperContent>
						</LoadingWrapper>
					</Col>
				</Row>
			</StyledContainerSettingAggregator>
		</SettingIntegrationsContext.Provider>
	);
};

export default SettingIntegrations;
