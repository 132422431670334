import TremendousModalContext from '@contexts/Settings/SettingIntegrations/TremendousModal';
import { StyledDivider, StyledModal, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledModalTremendous } from '@styled/Settings/IntegrationsStyled';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderStep from './HeaderStep';
import THEME from '@constants/themes/themes';
import StepTremendous1 from './StepTremendous1';
import { Button, message } from 'antd';
import StepTremendous2 from './StepTremendous2';
import StepTremendous3 from './StepTremendous3';
import { TremendousStoreDto } from '@models/settings/tremendous/store';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeTremendousField,
	createOrderTremendousEnd,
	createOrderTremendousRequest,
	fetchTremendousInfoRequest,
} from '@stores/actions';
import LoadingWrapper from '@cores/LoadingWrapper';
import { RefTremendousStep } from '@models/settings/tremendous/ref';
import { TremendousOrderRequestTypes } from '@models/settings/tremendous';
import { DEFAULT_ORDER_TREMENDOUS, SEND_TYPE } from '@constants/settings/integrations';
import { UserStoreType } from '@stores/creator/creator.types';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';

type PropTypes = {
	visible: boolean;
	handleClose: () => void;
	callbackCreateOrderSuccess?: () => void;
	[other: string]: any;
};

const TremendousModal = (props: PropTypes) => {
	const { visible, handleClose, callbackCreateOrderSuccess, ...other } = props;
	const {
		fetchingTremendous,
		tremendousDefault,
		createOrderTremendousStatus,
		error,
	}: TremendousStoreDto = useSelector((state: any) => state.tremendous);
	const { tremendousSettings, fundingSources } = tremendousDefault || {};
	const { campaignId, fundingSourceId, rewardAmount } = tremendousSettings || {};

	const { creatorProfile, fetchingCreatorProfile }: UserStoreType = useSelector(
		(state: any) => state.creator,
	);
	const { firstName = '', lastName = '', email = '' } = creatorProfile || {};

	const refStep1 = useRef<RefTremendousStep | undefined>() as RefObject<RefTremendousStep>;
	const refStep2 = useRef<RefTremendousStep | undefined>() as RefObject<RefTremendousStep>;

	const [currentStep, setCurrentStep] = useState<number>(2);
	const [stepFinish, setStepFinish] = useState<number>(0);
	const [orderDetail, setOrderDetail] = useState<TremendousOrderRequestTypes>({
		...DEFAULT_ORDER_TREMENDOUS,
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const fundingSourceTotal = useMemo(() => {
		let result = 0;
		const findItem = fundingSources?.find((item) => item?.id === fundingSourceId);
		if (findItem) {
			result = +(findItem.meta.available_cents || 0) / 100;
		}
		return result;
	}, [fundingSourceId]);

	useEffect(() => {
		if (visible && !tremendousDefault) {
			dispatch(fetchTremendousInfoRequest({ isDefault: true }));
		}
	}, [visible]);

	useEffect(() => {
		if (tremendousSettings) {
			setOrderDetail({ ...orderDetail, campaignId, fundingSourceId, rewardAmount });
		}
	}, [tremendousDefault]);

	useEffect(() => {
		if (createOrderTremendousStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (createOrderTremendousStatus === FAILED) {
			message.error(error);
		}

		if (createOrderTremendousStatus === SUCCEEDED) {
			message.success(t('message.create_successfully', { val: t('tremendous.order') }));
			callbackCreateOrder();
			handleClose();
		}
		if (createOrderTremendousStatus === SUCCEEDED || createOrderTremendousStatus === FAILED) {
			setIsLoading(false);
			dispatch(createOrderTremendousEnd());
		}
	}, [createOrderTremendousStatus]);

	const callbackCreateOrder = () => {
		if (callbackCreateOrderSuccess && typeof callbackCreateOrderSuccess === 'function') {
			callbackCreateOrderSuccess();
		}
	};

	const handleGoBack = () => {
		if (currentStep === 2) {
			handleChangeAction(0);
			return;
		}
		handleChangeAction(currentStep - 1);
	};

	const handleSubmit = () => {
		if (currentStep !== 2) {
			handleChangeAction(currentStep + 1);
			return;
		}
		if (!firstName?.trim() || !lastName?.trim()) {
			message.error(t('tremendous.message.error_required_order'));
			return;
		}
		if ((orderDetail?.rewardAmount || 0) > fundingSourceTotal) {
			message.error(t('tremendous.message.insufficient_balance'));
			return;
		}
		dispatch(
			createOrderTremendousRequest({
				...orderDetail,
				recipientId: creatorProfile?.id,
				recipientEmail: email,
				recipientFirstName: firstName,
				recipientLastName: lastName,
			}),
		);
	};

	const renderFooter = () => {
		const result = [
			<Button onClick={() => handleClose()}>{t('button.cancel')}</Button>,
			<Button onClick={() => handleSubmit()} type="primary">
				{currentStep === 2 ? t('button.create_order') : t('button.next')}
			</Button>,
		];

		if (currentStep) {
			result.splice(
				1,
				0,
				<Button onClick={() => handleGoBack()}>
					{currentStep === 1 ? t('button.go_back') : t('button.edit_this_reward')}
				</Button>,
			);
		}
		return result;
	};

	const handleStep = () => {
		setCurrentStep(stepFinish);
	};

	const handleChangeAction = (current: number) => {
		setStepFinish(current);

		if (currentStep === 0) {
			if (refStep1?.current?.validateAllField) {
				refStep1?.current?.validateAllField();
				return;
			}
		}
		if (currentStep === 1) {
			if (refStep2?.current?.validateAllField) {
				refStep2?.current?.validateAllField();
				return;
			}
		}
		setCurrentStep(current);
	};

	const valueContext = {
		currentStep,
		handleChangeAction,
		orderDetail,
		setOrderDetail,
		isLoading,
		setIsLoading,
		fundingSourceTotal,
	};

	return (
		<StyledModalTremendous
			{...other}
			centered={true}
			visible={visible}
			width={630}
			onCancel={handleClose}
			maskClosable={false}
			closable={false}
			footer={renderFooter()}
			title={undefined}>
			<TremendousModalContext.Provider value={valueContext}>
				<LoadingWrapper
					heightLoading="auto"
					isLoading={fetchingTremendous || isLoading || fetchingCreatorProfile}>
					<StyledWrapperContent padding="16px 24px" width="100%">
						<HeaderStep />
					</StyledWrapperContent>
					<StyledDivider margin="0" borderColor={THEME.colors.darkBlue3} />
					<StyledWrapperContent
						className="custom_scroll_bar"
						overflow="auto"
						padding="16px 24px"
						maxHeight="75vh">
						{currentStep === 0 && (
							<StepTremendous1 ref={refStep1} handleStep={handleStep} />
						)}
						{currentStep === 1 && (
							<StepTremendous2 handleStep={handleStep} ref={refStep2} />
						)}
						{currentStep === 2 && <StepTremendous3 />}
					</StyledWrapperContent>
				</LoadingWrapper>
			</TremendousModalContext.Provider>
		</StyledModalTremendous>
	);
};

export default TremendousModal;
