import { TYPE_FORMAT_DATE } from '@constants/common';
import { FORM_PUBLISH_LOG, TYPE_PUBLISH_LOG } from '@constants/contentLibrary/contentDetail';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import { PublishLogDto } from '@models/content/contentLibrary/summary';
import {
	createPublishLogContentEnd,
	createPublishLogContentRequest,
	updatePublishLogContentEnd,
	updatePublishLogContentRequest,
} from '@stores/actions';
import { StyledSelectDate } from '@styled/Common/CommonStyled';
import {
	StyledAction,
	StyledFormItem,
	StyledLabelField,
	StyledTextArea,
	StyledWrapperAction,
	StyledWrapperLabel,
} from '@styled/Content/AlbumManager/AlbumModalStyled';
import {
	StyledModalPublishLog,
	StyledMoreInfo,
	StyledSubInfo,
	StyledTitleModal,
} from '@styled/ContentLibrary/PublishLogModalStyled';
import { Button, Form, message, Tooltip } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type PropsPublishLogPopup = {
	onCancel: () => void;
	contentId: number;
	handleCreateSuccess: () => void;
	handleUpdateSuccess: () => void;
	handleDeletePublish: () => void;
	detailPublish: PublishLogDto | null;
	visible: boolean;
	[other: string]: any;
};
const PublishLogModal = (props: PropsPublishLogPopup) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		publishLogs: listPublishLog,
		fetchingPublishLog,
		publishLog,
		createPublishLogStatus,
		updatePublishLogStatus,
		error,
	} = useSelector((state: any) => state.content);

	const {
		onCancel,
		contentId,
		handleCreateSuccess,
		handleUpdateSuccess,
		detailPublish,
		visible,
		handleDeletePublish,
		...other
	} = props;

	const [form] = Form.useForm();

	useEffect(() => {
		if (createPublishLogStatus === SUCCEEDED) {
			message.success(t('content_detail.message.created_publish_log_success'));
			handleCreateSuccess();
		}
		if (createPublishLogStatus === SUCCEEDED || createPublishLogStatus === FAILED) {
			dispatch(createPublishLogContentEnd());
			handleClose();
		}
	}, [createPublishLogStatus]);

	useEffect(() => {
		if (updatePublishLogStatus === SUCCEEDED) {
			message.success(t('content_detail.message.updated_publish_log_success'));
			handleUpdateSuccess();
		}
		if (updatePublishLogStatus === SUCCEEDED || updatePublishLogStatus === FAILED) {
			dispatch(updatePublishLogContentEnd());
			handleClose();
		}
	}, [updatePublishLogStatus]);

	useEffect(() => {
		if (detailPublish && visible) {
			const date = new Date(detailPublish?.publishDate * 1000);

			form.setFieldsValue({
				[FORM_PUBLISH_LOG.LINK]: detailPublish?.link,
				[FORM_PUBLISH_LOG.WHERE]: detailPublish?.publishTo,
				[FORM_PUBLISH_LOG.DATE]: moment(date, TYPE_FORMAT_DATE.MONTH_DAY),
			});
		}
	}, [detailPublish]);

	useEffect(() => {
		if (!visible) {
			form.resetFields();
		}
	}, [visible]);

	const handleClose = () => {
		form.resetFields();
		onCancel();
	};

	const arrSubText = [
		t('content_detail.publish_modal.sub_info_1'),
		t('content_detail.publish_modal.sub_info_2'),
		t('content_detail.publish_modal.sub_info_3'),
	];
	const renderSubText = (
		<StyledSubInfo>
			{t('content_detail.publish_modal.title_sub_info')}
			<ul>
				{arrSubText?.map((item) => (
					<li key={item}>{item}</li>
				))}
			</ul>
		</StyledSubInfo>
	);

	const renderDateField = () => {
		return (
			<StyledSelectDate
				format={TYPE_FORMAT_DATE.MONTH_DAY}
				style={{ width: '280px' }}
				placeholder={t('content_detail.publish_modal.placeholder_date')}
			/>
		);
	};

	const renderTextAreaField = (placeholder: string) => {
		return (
			<StyledTextArea
				placeholder={placeholder}
				autoSize={{ minRows: 3, maxRows: 3 }}
				rows={3}
			/>
		);
	};

	const onValuesChange = () => {};
	const handleFinishForm = async (values: any) => {
		const valueLink = values[FORM_PUBLISH_LOG.LINK]?.trim() || '';
		const publishTo = values[FORM_PUBLISH_LOG.WHERE]?.trim() || '';
		const publishDate = Math.round(new Date(values[FORM_PUBLISH_LOG.DATE]).getTime() / 1000);
		form.setFieldsValue({
			[FORM_PUBLISH_LOG.LINK]: valueLink,
			[FORM_PUBLISH_LOG.WHERE]: publishTo,
		});
		await form.validateFields();
		if (detailPublish) {
			dispatch(
				updatePublishLogContentRequest({
					id: detailPublish?.id,
					bodyRequest: { publishTo, link: valueLink, publishDate },
				}),
			);
		} else {
			dispatch(
				createPublishLogContentRequest({
					params: { contentId, type: TYPE_PUBLISH_LOG.CUSTOM_LOG },
					bodyRequest: { publishTo, link: valueLink, publishDate },
				}),
			);
		}
	};

	const arrField = [
		{
			name: FORM_PUBLISH_LOG.DATE,
			rules: [
				{
					required: true,
					message: t('content_detail.validate.required'),
				},
			],
			label: t('content_detail.publish_modal.publish_date'),
			content: renderDateField(),
		},
		{
			name: FORM_PUBLISH_LOG.WHERE,
			rules: [
				{
					required: true,
					message: t('content_detail.validate.required'),
				},
			],
			label: t('content_detail.publish_modal.where_title'),
			content: renderTextAreaField(t('content_detail.publish_modal.placeholder_where')),
		},
		{
			name: FORM_PUBLISH_LOG.LINK,
			rules: [
				{
					required: true,
					message: t('content_detail.validate.required'),
				},
			],
			label: t('content_detail.publish_modal.product_link'),
			content: renderTextAreaField(t('content_detail.publish_modal.placeholder_link')),
		},
	];

	const initialValues = {
		[FORM_PUBLISH_LOG.DATE]: '',
		[FORM_PUBLISH_LOG.LINK]: '',
		[FORM_PUBLISH_LOG.WHERE]: '',
	};

	return (
		<StyledModalPublishLog
			centered={true}
			width={410}
			visible={visible}
			onCancel={handleClose}
			title={
				<>
					<StyledTitleModal>
						{t(
							`content_detail.publish_modal.${
								detailPublish ? 'edit_publish_record' : 'title_publish'
							}`,
						)}
					</StyledTitleModal>
					{!detailPublish && (
						<Tooltip
							overlayInnerStyle={{ minWidth: '330px' }}
							placement="topLeft"
							title={renderSubText}>
							<StyledMoreInfo>
								{t('content_detail.publish_modal.why_publish')}
							</StyledMoreInfo>
						</Tooltip>
					)}
				</>
			}
			footer={
				<StyledWrapperAction style={{ width: '100%' }}>
					<StyledAction>
						{detailPublish && (
							<Button onClick={handleDeletePublish}>{t('button.delete')}</Button>
						)}
					</StyledAction>
					<StyledAction>
						<Button onClick={handleClose}>{t('button.cancel')}</Button>
						<Button
							loading={
								createPublishLogStatus === IN_PROGRESS ||
								updatePublishLogStatus === IN_PROGRESS
							}
							onClick={() => form.submit()}
							type="primary">
							{t('button.save_changes')}
						</Button>
					</StyledAction>
				</StyledWrapperAction>
			}
			{...other}>
			<Form
				form={form}
				initialValues={initialValues}
				onValuesChange={onValuesChange}
				onFinish={handleFinishForm}>
				{arrField?.map((item) => (
					<StyledFormItem
						key={item?.name}
						name={item?.name}
						className="form-item_album"
						rules={item?.rules}
						label={
							<StyledWrapperLabel>
								<StyledLabelField>{item?.label}</StyledLabelField>
							</StyledWrapperLabel>
						}>
						{item?.content}
					</StyledFormItem>
				))}
			</Form>
		</StyledModalPublishLog>
	);
};

export default PublishLogModal;
