import { renderThumbVertical } from '@utils/renderComponent';
import { Col, Row } from 'antd';
import React, { useContext, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import CardTemplate from './CardTemplate';
import { StyledWrapperSummary } from '@styled/LicensingTemplate';
import { STATUS_TEMPLATE } from '@constants/licensingTemplate';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';
import { useSelector } from 'react-redux';
import ContentLicensingContext from '@contexts/Settings/ContentLicensing';
import { TemplateTypes } from '@models/settings/contentLicensing';
import { StyledCenterContainer } from '@styled/Messages/EmailTemplatesStyled';
import { Empty } from '@cores/index';

const LicensingSummary = () => {
	const { error, loadingTemplate, templates }: ContentLicensingStoreDto = useSelector(
		(state: any) => state?.contentLicensing,
	);
	const { listTemplate, paramTemplate, setParamTemplate } = useContext(ContentLicensingContext);
	const scrollbars = useRef<any>({});

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			if (scrollbarTop >= 0.75 && !loadingTemplate && templates.length > 0) {
				setParamTemplate({ ...paramTemplate, page: paramTemplate.page + 1 });
			}
		}
	};

	return (
		<StyledWrapperSummary>
			<Scrollbars
				className="scrollbar_custom_wrapper"
				ref={(el) => {
					scrollbars.current = el;
				}}
				renderTrackVertical={() => <div />}
				onScrollStop={onScrollStop}
				style={{ height: 'calc(100vh - 170px)', margin: '0 -12px' }}
				renderThumbHorizontal={() => <div />}
				renderThumbVertical={renderThumbVertical}>
				<Row gutter={[24, 24]} style={{ margin: '0' }}>
					{listTemplate?.length === 0
						? !loadingTemplate && (
								<StyledCenterContainer>
									<Empty />
								</StyledCenterContainer>
						  )
						: listTemplate.map((item: TemplateTypes) => {
								return (
									<Col span={8} key={item.id}>
										<CardTemplate template={item} key={item.id} />
									</Col>
								);
						  })}
				</Row>
			</Scrollbars>
		</StyledWrapperSummary>
	);
};

export default LicensingSummary;
