import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tabs, message } from 'antd';

// Components
import ContentValueTable from './ContentValueTable';
import { LoadingWrapper } from '@cores/index';
import { IconInfo } from '@assets/icons';

// Constant
import { CONTENT_VALUE } from '@constants/settings';
import { IN_PROGRESS, SUCCEEDED, FAILED } from '@constants/status';

// Model
import { SettingRootStateDto } from '@models/settings/setting';

// Styled
import {
	StyledWrapper,
	StyledTitle,
	StyledWrapperBox,
	StyledTabs,
	StyledSpanIcon,
} from '@styled/Settings/ContentStyled';

const { TabPane } = Tabs;

const ContentValue: React.FC = () => {
	const { t } = useTranslation();
	const { loadingContentValues, contentValues: { verified = [], selects = [] } = {} } =
		useSelector((state: SettingRootStateDto) => state.settings.content);
	const [actionType, setActionType] = useState<string>('');

	const tabs: any[] = [
		{
			key: CONTENT_VALUE.VERIFIED,
			title: t('setting.content.verified_content'),
			data: verified,
		},
		{
			key: CONTENT_VALUE.SELECTS,
			title: t('setting.content.selects_an_social_uploads'),
			data: selects,
		},
	];

	useEffect(() => {
		if (loadingContentValues === SUCCEEDED) {
			if (actionType !== '') {
				message.success(t('setting.message.update_content_value_success'));
			}
		}
		if (loadingContentValues === FAILED) {
			if (actionType !== '') {
				message.error('setting.message.update_content_value_failed');
			} else {
				message.error('setting.message.get_content_value_failed');
			}
		}
	}, [loadingContentValues]);

	const handleSetActionType = (type: string) => {
		setActionType(type);
	};

	return (
		<StyledWrapper>
			<StyledTitle fontSize="1.143rem" fontWeight="normal">
				{t('setting.content.content_values')}
				<StyledSpanIcon>
					<IconInfo />
				</StyledSpanIcon>
			</StyledTitle>

			<StyledWrapperBox padding="0">
				<LoadingWrapper isLoading={loadingContentValues === IN_PROGRESS}>
					<StyledTabs>
						{tabs.map((tab) => (
							<TabPane tab={tab.title} key={tab.key} closable={false}>
								<ContentValueTable
									data={tab.data}
									type={tab.key}
									handleSetActionType={handleSetActionType}
								/>
							</TabPane>
						))}
					</StyledTabs>
				</LoadingWrapper>
			</StyledWrapperBox>
		</StyledWrapper>
	);
};

export default ContentValue;
