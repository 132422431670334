import Wrapper from '../Wrapper';

type Props = {};

const Editor: React.FC<Props> = ({}) => {
  // TODO
  return <Wrapper />;
};

Editor.defaultProps = {};

export default Editor;
