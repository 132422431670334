import { StyledModal } from '@styled/Common/CommonStyled';
import styled from 'styled-components';

const StyledLandingPageModal = styled(StyledModal)`
	&.ant-modal {
		padding-bottom: unset;
	}

	.ant-modal-header {
		padding: 12px 20px;

		.ant-modal-title {
			font-size: 20px;
		}
	}

	.ant-modal-close {
		margin-top: -4px;
	}

	.ant-modal-body {
		height: calc(100vh - 130px);
		padding: 0 20px;
	}
`;

export { StyledLandingPageModal };
