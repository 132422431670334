import CheckboxGroup from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed/CheckboxGroup';
import RadioGroup from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed/RadioGroup';
import { DEFAULT_FORM_CONFIG, INTERVAL, NAME_FILTER } from '@constants/ambassador';
import { SOCIAL, SOCIAL_UPCASE } from '@constants/social_search';
import { ConfigGraphTypes, FilterAmbassadorTypes } from '@models/ambassador';
import { OptionTypeItem } from '@models/filter';
import { StyledDrawerGraphConfig, StyledWrapperActionConfig } from '@styled/AmbassadorDashboard';
import {
	StyledDatePicker,
	StyledIcon,
	StyledRedirectLink,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledFormItem } from '@styled/Filter/FilterContentStyled';
import { Button, Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { ROUTE_PATH, TYPE_FORMAT_DATE } from '@constants/common';
import { StyledSelect } from '@styled/Content/ContentLibrary/FilterStyled';
import { notFoundContent } from '@utils/renderComponent';
import { isEqual } from 'lodash';
import { UserStoreType } from '@stores/creator/creator.types';
import { useSelector } from 'react-redux';
import { IconArrowLine } from '@assets/icons';
import { useHistory } from 'react-router-dom';
import THEME from '@constants/themes/themes';

type PropTypes = {
	visible: boolean;
	onClose: () => void;
	filter: FilterAmbassadorTypes;
	handleApplyFilter: (val: FilterAmbassadorTypes) => void;
	disabledCampaign?: boolean;
	isSubmission?: boolean;
	[other: string]: any;
};

const DrawerFilterAmbassador = (props: PropTypes) => {
	const {
		visible,
		onClose,
		handleApplyFilter,
		filter,
		disabledCampaign,
		isSubmission = false,
		...other
	} = props;
	const { t } = useTranslation();

	const history = useHistory();

	const { listCampaign }: UserStoreType = useSelector((state: any) => state.creator);

	const [form] = Form.useForm();

	const arrSource: OptionTypeItem[] = [
		{
			value: SOCIAL_UPCASE.INSTAGRAM,
			name: t('content_detail.instagram'),
		},
		{
			value: SOCIAL_UPCASE.TIKTOK,
			name: t('button.tiktok'),
		},
		{
			value: SOCIAL_UPCASE.TWITTER,
			name: t('content_detail.twitter'),
		},
		{
			value: SOCIAL_UPCASE.YOUTUBE,
			name: t('button.youtube'),
		},
	];

	useEffect(() => {
		const fields = form.getFieldsValue();
		const obj = handleFormatData(fields);
		if (visible && !isEqual(obj, filter)) {
			handleFieldForm();
		}
	}, [visible, filter, isSubmission]);

	const handleFieldForm = () => {
		const { sources, campaignIds = [], to, from } = filter;
		const fields: any = {
			[NAME_FILTER.SOURCES]: sources,
			[NAME_FILTER.CAMPAIGN_ID]: campaignIds[0] || undefined,
			[NAME_FILTER.DATE_RANGE]: [],
		};
		if (to && from) {
			fields[NAME_FILTER.DATE_RANGE] = [
				moment(new Date(from), TYPE_FORMAT_DATE.MONTH_DAY),
				moment(new Date(to), TYPE_FORMAT_DATE.MONTH_DAY),
			];
		}

		form.setFieldsValue({
			...fields,
		});
	};

	const handleFormatData = (values: any) => {
		const {
			sources = arrSource.map((source) => source.value),
			dateRange = [],
			campaignIds,
		} = values;
		const dataFormat: FilterAmbassadorTypes = {
			sources,
			campaignIds: campaignIds ? [campaignIds] : [],
			from: undefined,
			to: undefined,
		};
		if (dateRange?.length > 0) {
			const start = new Date(dateRange[0]);
			const end = new Date(dateRange[1]);
			start.setHours(0, 0, 0, 0);
			end.setHours(23, 59, 59, 999);

			dataFormat.from = start?.getTime();
			dataFormat.to = end.getTime();
		}

		return dataFormat;
	};
	const handleClearOpenConfig = () => {
		onClose();
		handleFieldForm();
	};

	const onValuesChange = () => {};

	const handleFinishForm = (values: any) => {
		handleApplyFilter(handleFormatData(values));

		onClose();
	};

	const renderSelect = (list: Array<any>, disabled?: boolean) => {
		const placeholder = t('filter.filter_content.any');

		return (
			<StyledSelect
				mode={undefined}
				showSearch
				allowClear
				notFoundContent={notFoundContent(t)}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				disabled={!!disabled}
				placeholder={placeholder}
				virtual={false}
				optionFilterProp="label"
				showArrow
				options={list}
				dropdownClassName="dropdown-menu dropdown-custom-content"
			/>
		);
	};

	const listForm = [
		{
			name: NAME_FILTER.SOURCES,
			label: t('content_detail.source'),
			content: (
				<CheckboxGroup
					className="checkbox_group_full checkbox_group_custom vertical_checkbox"
					list={arrSource}
					otherCheckbox={{
						className:
							'checkbox_default checkbox_custom checkbox_dark fix_content mb_4',
					}}
					gapSpace="0px"
					direction="vertical"
				/>
			),
			rules: [
				{
					required: true,
					message: t('campaign.validate.required', {
						field: t('ambassador.sources'),
					}),
				},
			],
			hidden: isSubmission,
		},
		{
			name: NAME_FILTER.DATE_RANGE,
			label: isSubmission ? t('creator_summary.date') : t('filter.filter_bookmark.post_date'),
			content: <StyledDatePicker format={TYPE_FORMAT_DATE.MONTH_DAY} />,
		},
		{
			name: NAME_FILTER.CAMPAIGN_ID,
			label: t('content_detail.campaigns'),
			content: renderSelect(
				listCampaign.map((item) => ({ value: item.id, label: item.name })),
				disabledCampaign,
			),
		},
	];

	return (
		<StyledDrawerGraphConfig
			title={t('ambassador.edit_filters')}
			placement="right"
			closable={true}
			className="drawer_none"
			onClose={handleClearOpenConfig}
			visible={visible}
			maskStyle={{ backgroundColor: 'rgb(1, 22, 39, 0.3)' }}
			width={345}
			{...other}>
			<StyledWrapperContent className="full" position="relative">
				<StyledWrapperContent
					overflow="scroll"
					className="custom_scroll_bar"
					maxHeight="calc(100% - 64px)"
					padding="20px 16px 8px 16px">
					<Form
						initialValues={{ ...DEFAULT_FORM_CONFIG }}
						form={form}
						onValuesChange={onValuesChange}
						onFinish={handleFinishForm}>
						{listForm.map((item) => {
							if (item.hidden) {
								return null;
							}
							return (
								<StyledFormItem key={item.name} name={item.name} label={item.label}>
									{item.content}
								</StyledFormItem>
							);
						})}
					</Form>
					{isSubmission && (
						<>
							<StyledTitle margin="16px 0 0 0" className="unset_height">
								{t('filter.filter_content.want_advanced_filters')}
							</StyledTitle>
							<StyledRedirectLink
								onClick={() => history.push(ROUTE_PATH.SUBMISSIONS)}
								className="orange_field"
								style={{
									cursor: 'pointer',
									marginTop: '3px',
								}}>
								{t('button.go_content_library')}
								<StyledIcon
									fillPath={THEME.colors.orangeBase}
									size={16}
									margin="0 0 0 4px">
									<IconArrowLine />
								</StyledIcon>
							</StyledRedirectLink>
						</>
					)}
				</StyledWrapperContent>

				<StyledWrapperActionConfig>
					<StyledWrapperAction>
						<Button
							style={{ marginLeft: '12px' }}
							onClick={() => form.submit()}
							type="primary">
							{t('content_management.filter.apply_filters')}
						</Button>
					</StyledWrapperAction>
				</StyledWrapperActionConfig>
			</StyledWrapperContent>
		</StyledDrawerGraphConfig>
	);
};

export default DrawerFilterAmbassador;
