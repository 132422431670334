/**
 * @prettier
 */

import styled from 'styled-components';
import { Rate, Typography, Col } from 'antd';
import THEME from '@constants/themes/themes';

const StyledRating = styled(Rate)`
	.ant-rate {
		.ant-rate-star-zero {
			svg {
				fill: ${THEME.colors.gray5} !important;
			}
		}
	}
`;

const StyledDivPointer = styled.div`
	cursor: pointer;
`;

const StyledInfoBlock = styled.div`
	padding: 16px;
	display: flex;
	justify-content: space-between;
	border-radius: 2px;
`;

const StyledInfoCol = styled.div<{ flexDirection?: string; alignItems?: string }>`
	display: flex;
	flex-direction: ${({ flexDirection }) => flexDirection || 'none'};
	align-items: ${({ alignItems }) => alignItems || 'none'};
	width: 100%;
	position: relative;
`;

const StyledGeneralInfoBlock = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledTitle = styled(Typography.Title)`
	margin: 0 16px 0 0 !important;
	color: ${THEME.colors.gray5} !important;
	font-weight: 500 !important;
	font-size: 1.714rem !important;
	line-height: 1.42 !important;
	height: 34px;
	&.text_overflow {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const StyledIconCol = styled(Col)<{ fill?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	svg {
		margin-right: 5px;
		fill: ${({ fill }) => fill || 'none'} !important;
		height: 16px;
		width: 16px;
	}
`;

const StyledWrapperProfile = styled.div`
	position: relative;
`;

const StyledWrapperStatus = styled.div`
	position: absolute;
	left: -9px;
	top: -9px;
`;

const StyledProfilePicture = styled.img`
	object-fit: cover;
	height: 96px;
	width: 96px;
	border-radius: 50%;
	margin-right: 24px;
`;

export {
	StyledRating,
	StyledInfoBlock,
	StyledGeneralInfoBlock,
	StyledTitle,
	StyledIconCol,
	StyledProfilePicture,
	StyledInfoCol,
	StyledDivPointer,
	StyledWrapperProfile,
	StyledWrapperStatus,
};
