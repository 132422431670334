import { IconCancel } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { StyledFilterContainer, StyledText } from '@styled/Campaign/CampaignStyled';
import { StyledIcon, StyledSelect } from '@styled/Common/CommonStyled';
import { Select } from 'antd';
import { isEqual } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option, OptGroup } = Select;

export type FilterSelectTypes = {
	value: string | number;
	name: string | number;
	group?: string;
};

export type GroupOption = {
	title: string;
	group: string;
	options: FilterSelectTypes[];
};

type ValueSelect = FilterSelectTypes | string | number | null;

type PropTypes = {
	isGroup?: boolean;
	listGroup?: GroupOption[];
	options?: FilterSelectTypes[];
	placeholderDefault?: string;
	placeholderOpen?: string;
	selectedValue?: ValueSelect;
	onChange?: (val: FilterSelectTypes | null) => void;
	subApplyFilter?: string;
	suffixFilter?: ReactNode;
	width?: string;
	maxWidth?: string;
	maxWidthText?: string;
	[other: string]: any;
};
const FilterSelect = (props: PropTypes) => {
	const {
		isGroup,
		listGroup,
		options,
		placeholderDefault = '',
		placeholderOpen = '',
		selectedValue = null,
		subApplyFilter = '',
		suffixFilter = '',
		width = '230px',
		maxWidth = '',
		maxWidthText = '',
		onChange: onChangeCallback,
		...other
	} = props;
	const { t } = useTranslation();
	const [filterSelect, setFilterSelect] = useState<FilterSelectTypes | null>(null);
	const [placeholder, setPlaceholder] = useState<string>(placeholderDefault);

	useEffect(() => {
		if (!selectedValue) {
			setFilterSelect(null);
		} else {
			if (typeof selectedValue === 'number' || typeof selectedValue === 'string') {
				if (selectedValue !== filterSelect?.value) {
					setFilterSelect({
						value: selectedValue,
						name: options?.find((item) => item?.value === selectedValue)?.name || '',
					});
				}
			} else {
				if (!isEqual(selectedValue, filterSelect)) {
					setFilterSelect(selectedValue);
				}
			}
		}
	}, [selectedValue]);

	const handleCheckCallback = (val: FilterSelectTypes | null) => {
		if (onChangeCallback && typeof onChangeCallback === 'function') {
			onChangeCallback(val);
		}
	};

	const onChange = (value: any, option: any) => {
		let result = null;
		if (isGroup) {
			result = JSON.parse(value || {});
		} else {
			result = options?.find((item) => item?.value === value) || null;
		}
		setFilterSelect(result);
		handleCheckCallback(result);
	};

	const handlePlaceholder = (isOpen: boolean) => {
		const textOpen = placeholderOpen || placeholderDefault;
		setPlaceholder(isOpen ? textOpen : placeholderDefault);
	};

	const cancelFilter = () => {
		setFilterSelect(null);
		handleCheckCallback(null);
	};

	const renderValue = () => {
		if (isGroup) {
			return filterSelect ? JSON.stringify(filterSelect) : undefined;
		}
		return filterSelect?.value;
	};

	return (
		<>
			{filterSelect ? (
				<StyledFilterContainer>
					<StyledText maxWidth={maxWidthText}>
						{subApplyFilter}“{filterSelect?.name}
					</StyledText>
					”{suffixFilter || ''}
					<StyledIcon margin="0 0 0 5px" size={16} fill={THEME.colors.white}>
						<IconCancel onClick={cancelFilter} />
					</StyledIcon>
				</StyledFilterContainer>
			) : (
				<StyledSelect
					onChange={onChange}
					value={renderValue()}
					onDropdownVisibleChange={handlePlaceholder}
					width={width}
					maxWidth={maxWidth}
					optionFilterProp="children"
					placeholder={placeholder}
					{...other}>
					{isGroup &&
						listGroup?.map((item) => (
							<OptGroup key={item.title} label={item.title}>
								{item?.options?.map((option) => {
									const val = JSON.stringify({
										value: option?.value,
										group: item?.group,
										name: option?.name,
									});
									return (
										<Option key={val} value={val}>
											{option?.name}
										</Option>
									);
								})}
							</OptGroup>
						))}
					{!isGroup &&
						options?.map((item) => (
							<Option key={item.value} value={item.value}>
								{item.name}
							</Option>
						))}
				</StyledSelect>
			)}
		</>
	);
};

export default FilterSelect;
