import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';

// draw map
import { dataSetMap } from '@utils/datamap';
import DataMapLocation from '@components/Dashboard/ChartByLocation/data/DataMap';

// Context
import CreatorDashboardContext from '@contexts/Creators/Dashboard';

// Styled
import { StyledChartWrapper } from '@styled/Dashboard/DashboardStyled';
import { LocationDashboardType } from '@models/dashboard';

// define data
type RangeColorTypes = {
	color: string;
};

type DataMapDto = {
	[key: string]: any;
};

const rangeColor: RangeColorTypes[] = [
	{ color: '#203241' },
	{ color: '#4d5b67' },
	{ color: '#808a93' },
	{ color: '#ccd0d4' },
];

// styled
const StyledRangeColor = styled.div`
	display: flex;
	height: 12px;
	margin: 0 8px;
`;

const StyledBlockColor = styled.div<{ color?: string }>`
	width: 20px;
	height: 12px;
	margin-right: 1px;
	background-color: ${({ color }) => color};

	&:last-child {
		margin-right: 0;
	}
`;

const StyledTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.143rem;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	box-sizing: content-box;
	height: 22px;
`;

const ChartByLocation = () => {
	const { t } = useTranslation();
	const { creatorLocationData, isFilterByCampaign } = useContext(CreatorDashboardContext);
	const location: LocationDashboardType[] = creatorLocationData || [];

	const [dataSetCountries, setDataSetCountries] = useState<DataMapDto>(
		_.cloneDeep(dataSetMap.dataSetCountries),
	);
	const [dataSetUSA, setDataSetUSA] = useState<DataMapDto>(_.cloneDeep(dataSetMap.dataSetUSA));

	useEffect(() => {
		if (!location || location.length === 0) {
			return;
		}
		setDataSetCountries({});
		setDataSetUSA({});
	}, []);

	useEffect(() => {
		if (!location || location.length === 0) {
			return;
		}
		const newDataSet: DataMapDto = { ...dataSetCountries };
		if (Object.keys(newDataSet).length !== 0) {
			location.map((item: LocationDashboardType) => {
				if (!newDataSet[item.countryISO3Code]) {
					return null;
				}
				newDataSet[item.countryISO3Code].numberOfThings += item.totalDirectUpload;
				newDataSet[item.countryISO3Code].fillKey = handleFillColor(
					newDataSet,
					newDataSet[item.countryISO3Code].numberOfThings,
				);
				return newDataSet[item.countryISO3Code];
			});

			setDataSetCountries(newDataSet);
		}

		const newDataSetUSA: DataMapDto = { ...dataSetUSA };
		if (Object.keys(newDataSetUSA).length !== 0) {
			location.map((item: LocationDashboardType) => {
				if (item.countryISO3Code === 'USA') {
					if (!item.state || !newDataSetUSA[item.state]) {
						return null;
					}
					newDataSetUSA[item.state].numberOfThings += item.totalDirectUpload;
					newDataSetUSA[item.state].state = item.state;
					newDataSetUSA[item.state].fillKey = handleFillColor(
						newDataSetUSA,
						newDataSetUSA[item.state].numberOfThings,
					);
				}

				return newDataSetUSA[item.state || ''] || null;
			});

			setDataSetUSA(newDataSetUSA);
		}
	}, [location]);

	const handleFillColor = (dataSet: DataMapDto, value: number) => {
		const mapValues: number[] = [];
		Object.values(dataSet).forEach((value: any) => {
			mapValues.push(value.numberOfThings);
		});
		const maxValue = Math.max(...mapValues);
		const avgValue = Math.round(maxValue / 4);

		if (value <= avgValue) {
			return 'Minor';
		}
		if (value > avgValue && value <= avgValue * 2) {
			return 'Medium';
		}
		if (value > avgValue * 2 && value <= avgValue * 3) {
			return 'Major';
		}
		if (value > avgValue * 3 && value <= maxValue) {
			return 'High';
		}
		return 'Minor';
	};

	return (
		<StyledChartWrapper isFilterByCampaign={isFilterByCampaign}>
			<div className="chart-by-location">
				<div className="chart-title">
					<StyledTitle>{t('dashboard.leaderboard.uploads_by_location')}</StyledTitle>
					<div className="range-color">
						<span>{t('content.low')}</span>
						<StyledRangeColor>
							{rangeColor.map((item: RangeColorTypes) => (
								<StyledBlockColor key={item.color} color={item.color} />
							))}
						</StyledRangeColor>
						<span>{t('content.high')}</span>
					</div>
				</div>

				<DataMapLocation
					locationList={location}
					dataSetCountries={dataSetCountries}
					dataSetUSA={dataSetUSA}
				/>
			</div>
		</StyledChartWrapper>
	);
};

export default React.memo(ChartByLocation);
