const getAllNotificationTypes = {
	GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
	GET_NOTIFICATION_SUCCEEDED: 'GET_NOTIFICATION_SUCCEEDED',
	GET_NOTIFICATION_FAILED: 'GET_NOTIFICATION_FAILED',
};

const getNotifyAccountTypes = {
	GET_NOTIFICATION_ACCOUNT_REQUEST: 'GET_NOTIFICATION_ACCOUNT_REQUEST',
	GET_NOTIFICATION_ACCOUNT_SUCCEEDED: 'GET_NOTIFICATION_ACCOUNT_SUCCEEDED',
	GET_NOTIFICATION_ACCOUNT_FAILED: 'GET_NOTIFICATION_ACCOUNT_FAILED',

	RESET_NOTIFY_ACCOUNT: 'RESET_NOTIFY_ACCOUNT',
};

const updateStatusNotificationTypes = {
	UPDATE_STATUS_NOTIFICATION_REQUEST: 'UPDATE_STATUS_NOTIFICATION_REQUEST',
	UPDATE_STATUS_NOTIFICATION_SUCCEEDED: 'UPDATE_STATUS_NOTIFICATION_SUCCEEDED',
	UPDATE_STATUS_NOTIFICATION_FAILED: 'UPDATE_STATUS_NOTIFICATION_FAILED',
	UPDATE_STATUS_NOTIFICATION_END: 'UPDATE_STATUS_NOTIFICATION_END',
};

const updateNotificationInfoTypes = {
	UPDATE_NOTIFICATION_INFO: 'UPDATE_NOTIFICATION_INFO',
	CHANGE_FIELDS_STORE_NOTIFY: 'CHANGE_FIELDS_STORE_NOTIFY',
};

const checkLimitNotificationTypes = {
	CHECK_LIMIT_NOTIFICATION_REQUEST: 'CHECK_LIMIT_NOTIFICATION_REQUEST',
	CHECK_LIMIT_NOTIFICATION_SUCCEEDED: 'CHECK_LIMIT_NOTIFICATION_SUCCEEDED',
	CHECK_LIMIT_NOTIFICATION_FAILED: 'CHECK_LIMIT_NOTIFICATION_FAILED',
	CHECK_LIMIT_NOTIFICATION_END: 'CHECK_LIMIT_NOTIFICATION_END',
};

const getTaskNotificationTypes = {
	GET_TASK_NOTIFICATION_REQUEST: 'GET_TASK_NOTIFICATION_REQUEST',
	GET_TASK_NOTIFICATION_SUCCEEDED: 'GET_TASK_NOTIFICATION_SUCCEEDED',
	GET_TASK_NOTIFICATION_FAILED: 'GET_TASK_NOTIFICATION_FAILED',
	GET_TASK_NOTIFICATION_END: 'GET_TASK_NOTIFICATION_END',
};

const toggleTaskNotificationStatusTypes = {
	TOGGLE_TASK_NOTIFICATION_STATUS_REQUEST: 'TOGGLE_TASK_NOTIFICATION_STATUS_REQUEST',
	TOGGLE_TASK_NOTIFICATION_STATUS_SUCCEEDED: 'TOGGLE_TASK_NOTIFICATION_STATUS_SUCCEEDED',
	TOGGLE_TASK_NOTIFICATION_STATUS_FAILED: 'TOGGLE_TASK_NOTIFICATION_STATUS_FAILED',
	TOGGLE_TASK_NOTIFICATION_STATUS_END: 'TOGGLE_TASK_NOTIFICATION_STATUS_END',
};

const markTaskNotificationAsReadTypes = {
	MARK_TASK_NOTIFICATION_AS_READ_REQUEST: 'MARK_TASK_NOTIFICATION_AS_READ_REQUEST',
	MARK_TASK_NOTIFICATION_AS_READ_SUCCEEDED: 'MARK_TASK_NOTIFICATION_AS_READ_SUCCEEDED',
	MARK_TASK_NOTIFICATION_AS_READ_FAILED: 'MARK_TASK_NOTIFICATION_AS_READ_FAILED',
	MARK_TASK_NOTIFICATION_AS_READ_END: 'MARK_TASK_NOTIFICATION_AS_READ_END',
};

export {
	getAllNotificationTypes,
	updateStatusNotificationTypes,
	updateNotificationInfoTypes,
	checkLimitNotificationTypes,
	getNotifyAccountTypes,
	toggleTaskNotificationStatusTypes,
	markTaskNotificationAsReadTypes,
	getTaskNotificationTypes,
};
