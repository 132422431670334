import {
	IconClose,
	IconDelete,
	IconKeyboardArrowLeft,
	IconKeyboardArrowRight,
	IconStar,
} from '@assets/icons';
import { TAB_WIDGET, TYPE_CHANGE } from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetManagerContext from '@contexts/Content/WidgetManager';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { WidgetBuilderDetailRequestDto } from '@models/content/widgetManager';
import {
	StyledIconStar,
	StyledInfoDetailWidget,
	StyledLink,
	StyledQrCode,
	StyledTitle,
} from '@styled/Content/WidgetManager/CurrentDesignStyled';
import { captureDOM } from '@utils/funcHelper';
import { Button } from 'antd';
import QRCode from 'qrcode.react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledMask = styled.div``;

const StyledInfoDetail = styled.div``;

const StyledContent = styled.div`
	padding: 16px;
	max-height: calc(100vh - 194px);
	overflow: auto;
`;

const StyledLabel = styled.span`
	font-size: 0.857rem;
	color: ${THEME.colors.gray1};
`;

const StyledName = styled.div`
	font-size: 1rem;
	color: ${THEME.colors.white};
	margin-top: 7px;
	display: flex;
	align-items: center;
`;

const StyledItem = styled.div`
	&:not(first-child) {
		margin-top: 16px;
	}
`;

const StyledFooterAction = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px;
	border-top: 1px solid ${THEME.colors.darkBlue3};
`;

const StyledGroupAction = styled.div`
	display: flex;
	.ml-16 {
		margin-left: 16px;
	}
`;

const InfoDetailWidget = () => {
	const { t } = useTranslation();

	const {
		widgetSelect = null,
		setWidgetSelect,
		hideDetail = true,
		setHideDetail,
		listWidget,
		handleCheckMessageRemove,
		handleSetDefaultWidget,
	} = useContext(WidgetManagerContext);

	const downloadQR = () => {
		captureDOM({
			id: 'qr-code_widget',
			name: t('content.widget_manager.detail.image_qr_widget'),
		});
	};

	const renderName = (name: string, showStar?: boolean) => (
		<StyledName>
			{name}
			{showStar && <StyledIconStar className="star_default" />}
		</StyledName>
	);

	const renderQrCode = (url: string) => {
		return (
			<StyledQrCode>
				<QRCode size={210} id="qr-code_widget" value={url} />
				<Button onClick={downloadQR} type="primary">
					{t('content.widget_manager.button.download_qr_code')}
				</Button>
			</StyledQrCode>
		);
	};

	const renderLinkWidget = (url: string) => {
		return (
			<StyledLink target="_blank" href={url}>
				{url}
			</StyledLink>
		);
	};

	const infoDetail = [
		{
			label: t('content.widget_manager.detail.widget_name'),
			content: renderName(
				widgetSelect?.name || '',
				widgetSelect?.status === TAB_WIDGET.live && widgetSelect?.isDefault,
			),
			key: 1,
		},
		{
			label: t('content.widget_manager.detail.last_update'),
			content: renderName(
				widgetSelect?.updateAt
					? convertUtcToLocalTimeWithFormat(widgetSelect?.updateAt)
					: '',
			),
			key: 2,
		},
		{
			label: t('content.widget_manager.detail.qr_code'),
			content: renderQrCode(widgetSelect?.url || ''),
			key: 3,
			hideContent: !widgetSelect?.url,
		},
		{
			label: t('content.widget_manager.detail.widget_base'),
			content: renderLinkWidget(widgetSelect?.url || ''),
			key: 4,
			hideContent: !widgetSelect?.url,
		},
	];

	const keySelect = useMemo(() => {
		return listWidget?.findIndex(
			(item: WidgetBuilderDetailRequestDto) => item?.id === widgetSelect?.id,
		);
	}, [listWidget, widgetSelect]);

	const handleChangeSelectWidget = (type: string) => () => {
		if (
			keySelect === -1 ||
			(type === TYPE_CHANGE.back && keySelect === 0) ||
			(type === TYPE_CHANGE.next && keySelect === listWidget?.length - 1)
		) {
			return;
		}
		let keyNew = keySelect;
		if (type === TYPE_CHANGE.back) {
			keyNew -= 1;
		} else {
			keyNew += 1;
		}
		setWidgetSelect(listWidget[keyNew]);
	};

	return (
		<StyledInfoDetailWidget className={`${hideDetail ? 'hidden_detail' : 'show_detail'}`}>
			<StyledMask onClick={() => setHideDetail(true)} className="bg-mask" />
			<StyledInfoDetail className="content_detail">
				<StyledTitle>
					{t('content.widget_manager.widget_detail')}
					<IconClose className="icon-close" onClick={() => setHideDetail(true)} />
				</StyledTitle>
				<StyledContent className="custom_scroll_bar">
					{infoDetail?.map((item) => {
						if (widgetSelect?.status === TAB_WIDGET.draft && item?.hideContent) {
							return null;
						}
						return (
							<StyledItem key={item?.key}>
								<StyledLabel>{item?.label}</StyledLabel>
								{item?.content}
							</StyledItem>
						);
					})}
				</StyledContent>
				<StyledFooterAction>
					<StyledGroupAction>
						<Button
							disabled={
								(widgetSelect?.isDefault &&
									widgetSelect?.status === TAB_WIDGET.live) ||
								widgetSelect?.status === TAB_WIDGET.custom
							}
							onClick={() => handleCheckMessageRemove()}
							icon={<IconDelete />}>
							{widgetSelect?.status === TAB_WIDGET.live && !widgetSelect?.isDefault
								? ''
								: t('content.widget_manager.button.delete_widget')}
						</Button>
						{widgetSelect?.status === TAB_WIDGET.live && !widgetSelect?.isDefault && (
							<Button
								type="primary"
								className="ml-16"
								onClick={handleSetDefaultWidget}
								icon={<IconStar style={{ fill: THEME.colors.gray5 }} />}>
								{t('button.set_default')}
							</Button>
						)}
					</StyledGroupAction>

					<StyledGroupAction>
						<Button
							onClick={handleChangeSelectWidget(TYPE_CHANGE.back)}
							icon={<IconKeyboardArrowLeft />}
							disabled={keySelect <= 0}>
							{t('content.widget_manager.button.back')}
						</Button>
						<Button
							onClick={handleChangeSelectWidget(TYPE_CHANGE.next)}
							disabled={keySelect === -1 || keySelect === listWidget?.length - 1}
							className="ml-16"
							icon={<IconKeyboardArrowRight />}>
							{t('content.widget_manager.button.next')}
						</Button>
					</StyledGroupAction>
				</StyledFooterAction>
			</StyledInfoDetail>
		</StyledInfoDetailWidget>
	);
};

export default InfoDetailWidget;
