import { CONFIRMATION_MODAL_TYPES, GJS_COMMAND } from '@constants/landingPageManager';

export default (editor: any, config: any) => {
	const { setConfirmationModalType } = config;
	const cm = editor.Commands;
	cm.add(GJS_COMMAND.CANVAS_CLEAR, () => {
		if (setConfirmationModalType) {
			setConfirmationModalType(CONFIRMATION_MODAL_TYPES.CLEAR_ALL);
		}
	});
};
