export const getTaskListTypes = {
	GET_TASK_LIST_REQUEST: 'GET_TASK_LIST_REQUEST',
	GET_TASK_LIST_SUCCEEDED: 'GET_TASK_LIST_SUCCEEDED',
	GET_TASK_LIST_FAILED: 'GET_TASK_LIST_FAILED',
	GET_TASK_LIST_END: 'GET_TASK_LIST_END',
};

export const getAccountListLiteTypes = {
	GET_ACCOUNT_LIST_LITE_REQUEST: 'GET_ACCOUNT_LIST_LITE_REQUEST',
	GET_ACCOUNT_LIST_LITE_SUCCEEDED: 'GET_ACCOUNT_LIST_LITE_SUCCEEDED',
	GET_ACCOUNT_LIST_LITE_FAILED: 'GET_ACCOUNT_LIST_LITE_FAILED',
	GET_ACCOUNT_LIST_LITE_END: 'GET_ACCOUNT_LIST_LITE_END',
};

export const updateTaskTypes = {
	UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',
	UPDATE_TASK_SUCCEEDED: 'UPDATE_TASK_SUCCEEDED',
	UPDATE_TASK_FAILED: 'UPDATE_TASK_FAILED',
	UPDATE_TASK_END: 'UPDATE_TASK_END',
};

export const deleteTaskTypes = {
	DELETE_TASK_REQUEST: 'DELETE_TASK_REQUEST',
	DELETE_TASK_SUCCEEDED: 'DELETE_TASK_SUCCEEDED',
	DELETE_TASK_FAILED: 'DELETE_TASK_FAILED',
	DELETE_TASK_END: 'DELETE_TASK_END',
};

export const markTaskAsDoneTypes = {
	MARK_TASK_AS_DONE_REQUEST: 'MARK_TASK_AS_DONE_REQUEST',
	MARK_TASK_AS_DONE_SUCCEEDED: 'MARK_TASK_AS_DONE_SUCCEEDED',
	MARK_TASK_AS_DONE_FAILED: 'MARK_TASK_AS_DONE_FAILED',
	MARK_TASK_AS_DONE_END: 'MARK_TASK_AS_DONE_END',
};

export const getTaskDetailsTypes = {
	GET_TASK_DETAILS_REQUEST: 'GET_TASK_DETAILS_REQUEST',
	GET_TASK_DETAILS_SUCCEEDED: 'GET_TASK_DETAILS_SUCCEEDED',
	GET_TASK_DETAILS_FAILED: 'GET_TASK_DETAILS_FAILED',
	GET_TASK_DETAILS_END: 'GET_TASK_DETAILS_END',
};

export const getTaskHistoryTypes = {
	GET_TASK_HISTORY_REQUEST: 'GET_TASK_HISTORY_REQUEST',
	GET_TASK_HISTORY_SUCCEEDED: 'GET_TASK_HISTORY_SUCCEEDED',
	GET_TASK_HISTORY_FAILED: 'GET_TASK_HISTORY_FAILED',
	GET_TASK_HISTORY_END: 'GET_TASK_HISTORY_END',
};

export const createTaskTypes = {
	CREATE_TASK_REQUEST: 'CREATE_TASK_REQUEST',
	CREATE_TASK_SUCCEEDED: 'CREATE_TASK_SUCCEEDED',
	CREATE_TASK_FAILED: 'CREATE_TASK_FAILED',
	CREATE_TASK_END: 'CREATE_TASK_END',
};

export const addCommentTypes = {
	ADD_COMMENT_REQUEST: 'ADD_COMMENT_REQUEST',
	ADD_COMMENT_SUCCEEDED: 'ADD_COMMENT_SUCCEEDED',
	ADD_COMMENT_FAILED: 'ADD_COMMENT_FAILED',
	ADD_COMMENT_END: 'ADD_COMMENT_END',
};

export const updateCommentTypes = {
	UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
	UPDATE_COMMENT_SUCCEEDED: 'UPDATE_COMMENT_SUCCEEDED',
	UPDATE_COMMENT_FAILED: 'UPDATE_COMMENT_FAILED',
	UPDATE_COMMENT_END: 'UPDATE_COMMENT_END',
};

export const deleteCommentTypes = {
	DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
	DELETE_COMMENT_SUCCEEDED: 'DELETE_COMMENT_SUCCEEDED',
	DELETE_COMMENT_FAILED: 'DELETE_COMMENT_FAILED',
	DELETE_COMMENT_END: 'DELETE_COMMENT_END',
};

export const sendReminderEmailTypes = {
	SEND_REMINDER_EMAIL_REQUEST: 'SEND_REMINDER_EMAIL_REQUEST',
	SEND_REMINDER_EMAIL_SUCCEEDED: 'SEND_REMINDER_EMAIL_SUCCEEDED',
	SEND_REMINDER_EMAIL_FAILED: 'SEND_REMINDER_EMAIL_FAILED',
	SEND_REMINDER_EMAIL_END: 'SEND_REMINDER_EMAIL_END',
};

export const notifyMentionedUserTypes = {
	NOTIFY_MENTIONED_USER_REQUEST: 'NOTIFY_MENTIONED_USER_REQUEST',
	NOTIFY_MENTIONED_USER_SUCCEEDED: 'NOTIFY_MENTIONED_USER_SUCCEEDED',
	NOTIFY_MENTIONED_USER_FAILED: 'NOTIFY_MENTIONED_USER_FAILED',
	NOTIFY_MENTIONED_USER_END: 'NOTIFY_MENTIONED_USER_END',
};

export const storeDataTaskManagerTypes = {
	STORE_DATA_TASK_MANAGER: 'STORE_DATA_TASK_MANAGER',
};
