import {
	IconMoreVert,
	IconOriginalError,
	IconRadioChecked,
	IconRadioUnChecked,
	IconRecentlyAdded,
	IconRemoveCircle,
} from '@assets/icons';
import { STATUS_SETTING, TAB_WIDGET } from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetManagerContext from '@contexts/Content/WidgetManager';
import { ActionsTypes } from '@models/content/albums/albumManager';
import {
	MetadataWidgetBuilder,
	WidgetBuilderDetailRequestDto,
} from '@models/content/widgetManager';
import {
	StyledDropdown,
	StyledIcon,
	StyledIconWrapper,
	StyledLinkHref,
} from '@styled/Common/CommonStyled';
import {
	StyledIconStar,
	StyledMask,
	StyledWidgetItem,
} from '@styled/Content/WidgetManager/CurrentDesignStyled';
import { StyledButtonMore } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { Menu, Tooltip } from 'antd';
import { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledActionMask = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	.icon_more {
		transform: rotate(90deg);
		fill: ${THEME.colors.white};
	}
`;

const StyledWrapperNameWidget = styled.div`
	width: 100%;
	min-height: 50px;
	background-color: ${THEME.colors.darkBlue3};
	color: ${THEME.colors.white};
	padding: 4px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const getWidthForNameWidget = (isDefault?: boolean, hasSpace?: boolean) => {
	if (isDefault && !hasSpace) {
		return '90%';
	}
	if (hasSpace) {
		return '60%';
	}
	return '100%';
};

const StyledNameWidget = styled.div<{ isDefault?: boolean; hasSpace?: boolean }>`
	width: ${({ isDefault, hasSpace }) => getWidthForNameWidget(isDefault, hasSpace)};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1.429rem;
	font-weight: bold;
`;

const StyledImage = styled.img`
	width: 100%;
`;

const StyledWidgetItemCustom = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${THEME.colors.white};
`;

const StyledWrapperRecentlyUpdated = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
`;

const StyledMessageRecentlyUpdated = styled.div`
	display: flex;
	align-items: center;
`;

const StyledWrapperStatus = styled.div`
	display: inline-flex;
	align-items: center;
`;

const StyledStatus = styled.div`
	margin-left: 12px;
`;

const StyledWrapperTooltip = styled.div`
	text-align: right;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: ${THEME.colors.gray5};
	span {
		display: inline-flex;
		align-items: center;
	}
`;

type PropWidgetItemDto = {
	isSelect?: boolean;
	onSelectWidget: () => void;
	widgetItem: WidgetBuilderDetailRequestDto;
	showMoreAction: boolean;
};

const WidgetItem = (props: PropWidgetItemDto) => {
	const { isSelect = false, onSelectWidget, widgetItem, showMoreAction } = props;
	const { name, metadata, status, isDefault, isRecentlyUpdated, widgetSettingsDTO } = widgetItem;
	const { status: statusSetting } = widgetSettingsDTO || {};
	const metadataObject: MetadataWidgetBuilder = JSON.parse(metadata);
	const { t } = useTranslation();
	const {
		filter,
		handleSetDefaultWidget,
		handleCheckMessageRemove,
		widgetSelect,
		handleDuplicate,
	} = useContext(WidgetManagerContext);

	const propsInfoDisable = {
		i18nKey: 'content.widget_manager.message.info_disabled',
		components: {
			linkTo: <StyledLinkHref color={THEME.colors.gray5} />,
		},
		values: { email: t('email.email_success') },
	};

	const disabled = useMemo(() => {
		return statusSetting === STATUS_SETTING.DISABLED || statusSetting === STATUS_SETTING.CLOSED;
	}, [statusSetting]);

	const actionsObj: ActionsTypes[] = [
		{
			id: 1,
			name: t('button.set_default'),
			click: handleSetDefaultWidget,
			hidden: widgetSelect?.status !== TAB_WIDGET.live || widgetSelect?.isDefault,
		},
		{
			id: 2,
			name: t('content.widget_manager.button.delete_uploader'),
			click: () => handleCheckMessageRemove(),
			hidden: widgetSelect?.isDefault,
		},
		{
			id: 3,
			name: t('button.duplicate'),
			click: () => handleDuplicate(),
		},
	];

	const menuActions = (
		<Menu>
			{actionsObj.map((item: ActionsTypes) => {
				if (item.hidden) {
					return null;
				}
				return (
					<Menu.Item key={item.id} disabled={!!item.disabled} onClick={item.click}>
						{item.name}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	return (
		<StyledWidgetItem onClick={onSelectWidget}>
			{status === TAB_WIDGET.custom ? (
				<StyledWidgetItemCustom />
			) : (
				<StyledImage src={metadataObject?.thumbnail} alt="image" />
			)}
			<StyledMask>
				<StyledActionMask>
					{isSelect ? <IconRadioChecked /> : <IconRadioUnChecked />}
				</StyledActionMask>
				<StyledWrapperNameWidget>
					<StyledNameWidget
						hasSpace={isRecentlyUpdated || disabled}
						isDefault={isDefault}>
						{name}
					</StyledNameWidget>
					<StyledWrapperStatus>
						{status === TAB_WIDGET.live && isDefault && (
							<StyledIconStar className="star_default" />
						)}
						<StyledStatus>
							{isRecentlyUpdated && (
								<StyledWrapperRecentlyUpdated>
									<StyledMessageRecentlyUpdated>
										{t('content.widget_manager.recently_updated')}
										<StyledIconWrapper
											width="16px"
											height="16px"
											margin="0 0 0 6px"
											color={THEME.colors.blueBase}>
											<IconOriginalError />
										</StyledIconWrapper>
									</StyledMessageRecentlyUpdated>
								</StyledWrapperRecentlyUpdated>
							)}
							{disabled && (
								<StyledWrapperTooltip>
									<Tooltip
										overlayInnerStyle={{ minWidth: '250px' }}
										title={<Trans t={t} {...propsInfoDisable} />}
										placement="topRight">
										{t(
											`button.${
												statusSetting === STATUS_SETTING.DISABLED
													? 'disabled'
													: 'closed'
											}`,
										)}
										<StyledIcon
											margin={'0 0 0 4px'}
											size={16}
											fill={THEME.colors.yellowBase}>
											<IconRemoveCircle />
										</StyledIcon>
									</Tooltip>
								</StyledWrapperTooltip>
							)}
						</StyledStatus>
						{showMoreAction && (
							<StyledDropdown
								className="dropdown_action"
								overlay={menuActions}
								getPopupContainer={(triggerNode: HTMLElement) =>
									triggerNode as HTMLElement
								}
								placement="bottomRight"
								trigger={['click']}>
								<StyledButtonMore>
									<IconMoreVert />
								</StyledButtonMore>
							</StyledDropdown>
						)}
					</StyledWrapperStatus>
				</StyledWrapperNameWidget>
			</StyledMask>
		</StyledWidgetItem>
	);
};

export default WidgetItem;
