import { IconKeyboardArrowRight } from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { DEFAULT_HEIGHT_HISTORY } from '@constants/licensingTemplate';
import THEME from '@constants/themes/themes';
import { LoadingWrapper } from '@cores/index';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { TemplateHistoryTypes } from '@models/settings/contentLicensing/stores';
import { StyledIcon, StyledTitle, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledCollapse, StyledCollapseInfo } from '@styled/ContentLibrary/ContentDetailStyled';
import { Tooltip } from 'antd';
import { useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

const { Panel } = StyledCollapse;

type PropTypes = {
	height?: string;
	loadingTemplateHistory?: boolean;
	onLoadMore: () => void;
	historyList: TemplateHistoryTypes[];
};

const HistoryTemplate = (props: PropTypes) => {
	const { height, loadingTemplateHistory, onLoadMore, historyList } = props;

	const { t } = useTranslation();

	const scrollbars = useRef<any>({});

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			if (scrollbarTop >= 0.75) {
				onLoadMore();
			}
		}
	};

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo>
				<StyledTitle className="unset_height">
					{t('content_licensing.template_history')}
				</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	return (
		<StyledCollapse
			className="collapse_content_detail"
			expandIconPosition="right"
			style={{ marginBottom: '0' }}
			defaultActiveKey={'1'}>
			<Panel header={renderHeaderPanel()} key={'1'}>
				<StyledWrapperContent
					width="100%"
					height={height || DEFAULT_HEIGHT_HISTORY}
					className="custom_scroll_bar"
					overflow="auto">
					<LoadingWrapper sizeLoading={'normal'} isLoading={loadingTemplateHistory}>
						<Scrollbars
							className="scrollbar_custom_wrapper"
							renderTrackVertical={() => <div />}
							ref={(el) => {
								scrollbars.current = el;
							}}
							onScrollStop={onScrollStop}
							style={{ height: height || DEFAULT_HEIGHT_HISTORY, width: '100%' }}
							renderThumbHorizontal={() => <div />}
							renderThumbVertical={() => <div />}>
							{historyList.map((template, idx) => (
								<StyledWrapperContent
									margin={idx ? '16px 0 0 0' : '0'}
									key={template.id}
									width="100%">
									<Tooltip
										title={
											<StyledTitle
												className="unset_height"
												fontSize="12px"
												dangerouslySetInnerHTML={{
													__html: template.description,
												}}
												color={THEME.colors.white}
											/>
										}>
										<StyledTitle
											className="unset_height overflow_more_line"
											fontSize="12px"
											dangerouslySetInnerHTML={{
												__html: template.description,
											}}
											color={THEME.colors.white}
										/>
									</Tooltip>

									<StyledTitle
										className="unset_height"
										fontSize="12px"
										color={THEME.colors.gray1}>
										{convertUtcToLocalTimeWithFormat(
											template.createAt,
											TYPE_FORMAT_DATE.TIME,
										)}
									</StyledTitle>
								</StyledWrapperContent>
							))}
						</Scrollbars>
					</LoadingWrapper>
				</StyledWrapperContent>
			</Panel>
		</StyledCollapse>
	);
};

export default HistoryTemplate;
