import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Form, message, DatePicker, Input, Row, Col, Slider, InputNumber } from 'antd';
import _ from 'lodash';
import THEME from '@constants/themes/themes';

// Constant
import { FIXED, REDEEMED, SENT, CANCELLED, FAILED } from '@constants/reward';

// Context
import RewardsHistoryContext from '@contexts/Rewards/History';

// Styled
import {
	StyledText,
	StyledContainer,
	StyledRadioButton,
	StyledSelect,
	StyledFormItem,
	StyledSlider,
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledContentPopover,
	StyledPopconfirm,
} from '@styled/Rewards/FilterStyled';
import {
	StyledTitle,
	StyledSection,
	StyledBtn,
	StyledBtnGhost,
} from '@styled/Content/ContentLibrary/ContentStyled';

// Icons
import {
	IconFlag,
	IconGallery,
	IconLabel,
	IconRestartAlt,
	IconSort,
	IconCampaign,
	IconReward,
	IconClose,
} from '@assets/icons';
import { StyledInput } from '@styled/Common/CommonStyled';
import { SPECIAL_CHARS } from '@constants/settings';
import { validateInputNumber } from '@utils/common';

const { Option } = Select;

type FilterProps = {
	closeFilters: () => void;
	// callAPIFilterContent?: (filters: any) => void;
	// resetContentFilters?: (isSwitchFolder?: boolean) => void;
};

const Actions = (props: FilterProps) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { loadingUpdate, handleUpdateRewards, selectedRowKeys } =
		useContext(RewardsHistoryContext);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	// Props
	const { closeFilters } = props;

	const statusType = [
		{
			name: 'rewards_history.status.redeemed',
			value: REDEEMED,
		},
		{
			name: 'rewards_history.status.sent',
			value: SENT,
		},
		{
			name: 'rewards_history.status.cancelled',
			value: CANCELLED,
		},
		{
			name: 'rewards_history.status.failed',
			value: FAILED,
		},
	];

	useEffect(() => {
		if (selectedRowKeys.length === 0) {
			resetFilters();
			setIsDisabled(true);
		}
	}, [selectedRowKeys]);

	const handleFormChange = () => {
		const { status, actualCashValue } = form.getFieldsValue();
		if (!status && !actualCashValue) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	};

	const resetFilters = () => {
		form.resetFields();
	};

	const onSaveRewardsInfo = () => {
		form.validateFields().then(() => {
			const values = form.getFieldsValue();
			handleUpdateRewards(values);
		});
	};

	return (
		<div className="filter-content">
			<StyledSection
				borderPosition="bottom"
				heightSection="54px"
				flexDirection="row"
				padding="16px 16px 16px 24px">
				<StyledTitle fontSize="1.143rem" fontWeight="600">
					{t('rewards_history.actions')}
				</StyledTitle>

				<StyledTitle
					onClick={closeFilters}
					cursor="pointer"
					fontSize="0.857rem"
					color={THEME.colors.gray3}>
					<IconClose />
				</StyledTitle>
			</StyledSection>

			<StyledContainer>
				<Form
					form={form}
					layout="vertical"
					onFieldsChange={handleFormChange}
					// initialValues={filters}
					// onValuesChange={onchange}
				>
					<StyledFormItem name="status" label={t('rewards_history.filter.status')}>
						<StyledSelect
							virtual={false}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							dropdownClassName="dropdown-menu dropdown-custom-content">
							{statusType.map((item) => (
								<Option value={item.value}>{t(`${item.name}`)}</Option>
							))}
						</StyledSelect>
					</StyledFormItem>

					<StyledFormItem
						name="actualCashValue"
						label={t('rewards_history.filter.actual_cash_value')}>
						<StyledInput
							prefix={SPECIAL_CHARS.DOLLAR}
							onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
								validateInputNumber(event, true);
							}}
						/>
					</StyledFormItem>
				</Form>
			</StyledContainer>

			<StyledSection
				justify="space-between"
				borderPosition="top"
				heightSection="64px"
				flexDirection="row">
				<StyledBtn
					onClick={onSaveRewardsInfo}
					loading={loadingUpdate}
					disabled={isDisabled}>
					{t('button.save')}
				</StyledBtn>
			</StyledSection>
		</div>
	);
};

export default Actions;
