import THEME from '@constants/themes/themes';
import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledProgressWrapper = styled.div<{
	height?: number;
	trailColor?: string;
	borderRadius?: string;
}>`
	width: 100%;
	height: ${({ height }) => height || 15}px;
	background-color: ${({ trailColor }) => trailColor || THEME.colors.darkBlue4};
	border-radius: ${({ borderRadius }) => borderRadius || '8px'};
	position: relative;
`;

const StyledPerCent = styled.div<{
	strokeColor?: string;
	borderRadius?: string;
	width?: number;
	hoverColor?: string;
}>`
	width: ${({ width }) => width || 0}%;
	background-color: ${({ strokeColor }) => strokeColor || THEME.colors.gray3};
	border-radius: ${({ borderRadius }) => borderRadius || '8px'};
	height: 100%;

	&:hover {
		background-color: ${({ hoverColor }) => hoverColor || THEME.colors.orangeBase};
	}
`;

const StyledSuccess = styled.div<{
	successColor?: string;
	borderRadius?: string;
	width?: number;
	hoverColor?: string;
}>`
	width: ${({ width }) => width || 0}%;
	background-color: ${({ successColor }) => successColor || THEME.colors.gray5};
	border-radius: ${({ borderRadius }) => borderRadius || '8px'};
	position: absolute;
	left: 0;
	height: 100%;
	top: 0;
	z-index: 20;
	&:hover {
		background-color: ${({ hoverColor }) => hoverColor || THEME.colors.orangeBase};
	}
`;

type PropProgress = {
	height?: number;
	trailColor?: string;
	borderRadius?: string;
	strokeColor?: string;
	successColor?: string;
	showTooltip?: boolean;
	titleTooltipPercent?: string;
	titleTooltipSuccess?: string;
	hoverColor?: string;
	percentSuccess?: number;
	percent?: number;
	onClickPercent?: () => void;
	onClickPercentSuccess?: () => void;
	[other: string]: any;
};

type PropWrapperTooltip = {
	showTooltip?: boolean;
	title?: string;
	[other: string]: any;
};

const WrapperTooltip = (props: PropWrapperTooltip) => {
	const { showTooltip, children, title = '' } = props;
	if (showTooltip) {
		return (
			<Tooltip title={title} placement="topRight">
				{children}
			</Tooltip>
		);
	}
	return <>{children}</>;
};

const ProgressLine = (props: PropProgress) => {
	const {
		height,
		trailColor,
		borderRadius,
		strokeColor,
		successColor,
		showTooltip = true,
		titleTooltipPercent = '',
		titleTooltipSuccess = '',
		hoverColor,
		percent = 0,
		percentSuccess = 0,
		onClickPercent,
		onClickPercentSuccess,
		...other
	} = props;

	const handleClickPercent = () => {
		if (onClickPercent && typeof onClickPercent === 'function') {
			return onClickPercent();
		}
		return null;
	};

	const handleClickPercentSuccess = () => {
		if (onClickPercentSuccess && typeof onClickPercentSuccess === 'function') {
			return onClickPercentSuccess();
		}
		return null;
	};
	return (
		<StyledProgressWrapper
			height={height}
			trailColor={trailColor}
			borderRadius={borderRadius}
			{...other}>
			<WrapperTooltip showTooltip={showTooltip} title={titleTooltipPercent}>
				<StyledPerCent
					hoverColor={hoverColor}
					strokeColor={strokeColor}
					borderRadius={borderRadius}
					onClick={handleClickPercent}
					width={percent}
				/>
			</WrapperTooltip>
			<WrapperTooltip title={titleTooltipSuccess} showTooltip={showTooltip}>
				<StyledSuccess
					hoverColor={hoverColor}
					successColor={successColor}
					borderRadius={borderRadius}
					onClick={handleClickPercentSuccess}
					width={percentSuccess}
				/>
			</WrapperTooltip>
		</StyledProgressWrapper>
	);
};

export default ProgressLine;
