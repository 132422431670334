import React, { Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import EmailBuilderEditor from '@components/Messages/EmailBuilder/EmailBuilderEditor';
import EmailBuilderPanel from '@components/Messages/EmailBuilder/EmailBuilderPanel';
import SaveTemplateModal from '@components/Messages/EmailBuilder/SaveTemplateModal';
import { CAMPAIGN_DYNAMIC_FIELD } from '@constants/campaign';
import { DURATION, ROUTE_PATH } from '@constants/common';
import { CONFIG_TITLE, EMAIL_ACTION, SENDER_EMAIL_ADDRESS, STATE_TYPE } from '@constants/messages';
import { FAILED, SUCCEEDED } from '@constants/status';
import EmailBuilderContext from '@contexts/Messages/EmailBuilder';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { ItemCamPaign } from '@models/campaign';
import { DynamicFieldValueDto } from '@models/common/summary';
import { SegmentsDto } from '@models/creator/segments';
import {
	CreatorDto,
	EmailPayloadDto,
	EmailTemplateDto,
	EmailTemplatesDataDto,
	LocationStateDto,
	RewardContainerDto,
	SegmentDto,
} from '@models/messages/summary';
import {
	getDynamicFieldsRequest,
	getRewardOffersRequest,
	getTemplatesRequest,
	saveDraftEmailEnd,
	saveDraftEmailRequest,
	sendEmailRewardEnd,
	sendEmailRewardRequest,
	storeMessageTemplateData,
} from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledCol,
	StyledDirectLink,
	StyledRow,
} from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledConfirmModalContent,
	StyledInput,
	StyledSubject,
} from '@styled/Messages/EmailBuilderStyled';
import { createCampaignDynamicFieldValue } from '@utils/campaign';
import { setRewardValues } from '@utils/reward';
import { message } from 'antd';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router';
import { StyledTitle } from '@styled/Common/CommonStyled';

type EmailBuilderProps = {
	isPopup?: boolean;
	isActivationEmail?: boolean;
	campaign?: ItemCamPaign;
	isInvitationFromCreator?: boolean;
};

const EmailBuilder = forwardRef((props: EmailBuilderProps, ref: Ref<any>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const { isPopup = false, isActivationEmail, campaign, isInvitationFromCreator } = props;

	const { state } = useLocation<LocationStateDto>();

	const [isBackPageBefore, setBackPageBefore] = useState<boolean>(false);
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { widgetLinks = [] } = clientSettings;

	let rewardContent: any = [];
	let actionType: string = '';

	if (state) {
		const { content = [], type = '' } = state;
		rewardContent = content;
		actionType = type;
	}

	const isRewardContent = actionType === STATE_TYPE.REWARD && rewardContent.length;

	// Reducers
	const { sendEmailStatus, saveDraftEmailStatus, error, templates, sentEmailResult, eventId } =
		useSelector((state: any) => state.messages);

	const emailBuilderPanelRef = useRef<any>(null);
	const emailBuilderEditorRef = useRef<any>(null);

	const requestData: EmailPayloadDto = {
		from: clientSettings.senderAddress ? clientSettings.senderAddress : SENDER_EMAIL_ADDRESS,
		subject: '',
		body: '',
		creators: [],
		segments: [],
		rewards: [],
		newUpdate: true,
		content: true,
	};

	const [template, setTemplate] = useState<any>();
	const [emailRequest, setEmailRequest] = useState<EmailPayloadDto>(requestData);
	const [appliedReward, setAppliedReward] = useState<RewardContainerDto>();

	const [isSaveTemplate, setIsSaveTemplate] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [confirmModal, setConfirmModal] = useState<any>({
		isVisible: false,
		type: '',
	});

	useEffect(() => {
		if (actionType === STATE_TYPE.EDIT_DRAFT_EMAIL) {
			const { draftEmail = {} } = state;
			const newEmail: any = { ...draftEmail };
			const requestData: EmailPayloadDto = {
				from: newEmail.mailFrom,
				subject: newEmail.subject,
				body: newEmail.body,
			};

			setEmailRequest((prevState) => ({ ...prevState, ...requestData }));
			setTemplate(requestData);
		}

		if (actionType === STATE_TYPE.MESSAGE_OPEN_TEMPLATE) {
			const { currentTemplate } = state;

			if (!_.isEmpty(currentTemplate)) {
				const requestData: EmailPayloadDto = {
					subject: currentTemplate?.subject,
					body: currentTemplate?.body,
				};

				setEmailRequest((prevState) => ({ ...prevState, ...requestData }));
				setTemplate(currentTemplate);
			}
		}

		dispatch(getDynamicFieldsRequest());
		dispatch(getTemplatesRequest());
		dispatch(getRewardOffersRequest());

		return () => {
			history.replace({ state: {} });
			setEmailRequest(requestData);
			dispatch(
				storeMessageTemplateData({
					templates: null,
				}),
			);
		};
	}, []);

	useEffect(() => {
		if (templates?.length > 0 && isActivationEmail && campaign) {
			let template;
			if (campaign?.activationTemplateId) {
				template = templates.find(
					(item: EmailTemplatesDataDto) => item?.id === campaign?.activationTemplateId,
				);
			} else {
				template = templates.find(
					(item: EmailTemplatesDataDto) =>
						item?.configTitle === CONFIG_TITLE.CAMPAIGN_ACTIVATE,
				);
			}

			if (template) {
				template.body = handleReplaceDynamicField(campaign, template);
				handleTemplateBuilder(template);
			}
		}
	}, [templates, isActivationEmail, campaign]);

	useEffect(() => {
		if (state?.isBackPageBefore) {
			setBackPageBefore(state?.isBackPageBefore);
		}
	}, [state]);

	useEffect(() => {
		if (eventId) {
			history.push(ROUTE_PATH.MESSAGE_LOG);
		}
	}, [eventId]);

	useEffect(() => {
		if (sendEmailStatus && sendEmailStatus === SUCCEEDED) {
			messageSendingEmail();
			setIsLoading(false);
			setConfirmModal({ ...confirmModal, isVisible: false });

			dispatch(sendEmailRewardEnd());
			setBackPageBefore(false);

			if (isRewardContent) {
				history.push(ROUTE_PATH.SUBMISSIONS);
			} else if (isBackPageBefore) {
				history.goBack();
			}
		}

		if (sendEmailStatus && sendEmailStatus === FAILED) {
			if (error) {
				message.error(error);
			}
			setIsLoading(false);
			setConfirmModal({ ...confirmModal, isVisible: false });
			dispatch(sendEmailRewardEnd());
		}
	}, [sendEmailStatus, sentEmailResult]);

	const previewSentEmail = () => {
		message.destroy();
		dispatch(
			storeMessageTemplateData({
				eventId: sentEmailResult?.eventId,
			}),
		);
	};

	const messageSendingEmail = () => {
		message.destroy();
		const { valid = 0, invalid = 0 } = sentEmailResult || {};
		const propsMessage: any = {
			i18nKey: 'email_builder.notification.sent_email_result',
			components: {
				linkTo: <StyledDirectLink onClick={previewSentEmail} />,
			},
			values: {
				valid,
				invalid,
			},
		};
		message.info({
			content: <Trans t={t} {...propsMessage} />,
			duration: DURATION.S_6,
			key: 'message_redirect_bookmark',
			style: {
				width: '420px',
				margin: '0 auto',
			},
		});
	};

	useEffect(() => {
		if (saveDraftEmailStatus && saveDraftEmailStatus === SUCCEEDED) {
			message.success(t('email_builder.save_success'));
			setIsLoading(false);
			dispatch(saveDraftEmailEnd());
		}

		if (saveDraftEmailStatus && saveDraftEmailStatus === FAILED) {
			if (error) {
				message.error(error);
			}
			setIsLoading(false);
			dispatch(saveDraftEmailEnd());
		}
	}, [saveDraftEmailStatus]);

	const handleReplaceDynamicField = (
		campaignParam: ItemCamPaign,
		template: EmailTemplatesDataDto,
	) => {
		const hasReplacedDynamicField = Object.values(CAMPAIGN_DYNAMIC_FIELD).some((item: string) =>
			template?.body?.includes(item),
		);

		if (hasReplacedDynamicField) {
			const campaignDynamicFields = createCampaignDynamicFieldValue(
				campaignParam,
				t,
				widgetLinks,
			);
			if (campaignDynamicFields && template) {
				campaignDynamicFields.forEach((item: DynamicFieldValueDto) => {
					template.body = template?.body?.replaceAll(
						item.dynamicField,
						item?.value || '',
					);
				});
			}
		}

		return template?.body;
	};

	const onChangeSubject = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setEmailRequest({ ...emailRequest, subject: evt.target.value });
	};

	const saveTemplateModal = () => {
		setIsSaveTemplate(!isSaveTemplate);
	};

	const onOpenReward = () => {
		if (isRewardContent) {
			if (checkRef(emailBuilderPanelRef)) {
				emailBuilderPanelRef.current.transformSection('reward');
			}
		}
	};

	const checkRef = (ref: any) => {
		if (ref && !_.isEmpty(ref.current)) return true;
		return false;
	};

	const handleLoading = (isLoading: boolean) => {
		setIsLoading(isLoading);
	};

	const handleTemplateBuilder = (template: EmailTemplateDto) => {
		let originalEmailRequest = { ...emailRequest };

		originalEmailRequest = {
			...originalEmailRequest,
			subject: template ? template.subject : '',
		};

		if (campaign) {
			originalEmailRequest.campaignId = campaign.id;
		}

		setEmailRequest({ ...originalEmailRequest });
		setTemplate({ ...template }); // set template in case of going back choose a template
	};

	const handleChangeTemplate = (body: string) => {
		setEmailRequest({
			...emailRequest,
			body,
		});
	};

	const handlePayloadSendMail = (payload: any) => {
		const { creators, segments, rewards } = payload;

		setEmailRequest((emailRequest) => ({
			...emailRequest,
			creators,
			segments,
			rewards,
		}));
	};

	const insertDynamicField = (field: string) => {
		if (!_.isEmpty(emailBuilderEditorRef.current)) {
			emailBuilderEditorRef.current.insertDynamicField(field);
		}
	};

	const convertCreators = (creatorRecipients: any) => {
		const creatorsTemp: CreatorDto[] = [];
		creatorRecipients.forEach((creator: any) => {
			if (!_.isUndefined(creator) && !_.isNull(creator.email)) {
				creatorsTemp.push({
					id: creator.creatorId || creator.id,
					email: creator.email,
				});
			}
		});

		return creatorsTemp;
	};

	const convertSegments = (segmentRecipients: any) => {
		const segmentsTemp: SegmentDto[] = [];
		segmentRecipients.forEach((segment: SegmentsDto) => {
			if (!_.isUndefined(segment)) {
				segmentsTemp.push({
					id: segment.id,
					title: segment.title,
				});
			}
		});

		return segmentsTemp;
	};

	const handleSendEmail = (actionType?: string, contentCount?: number, isSaveDraft?: boolean) => {
		setIsLoading(false);
		const { creators, segments } = emailRequest;

		let emailPayloadTemp = {
			...emailRequest,
			creators: creators,
			segments: segments,
			newUpdate: !_.isUndefined(isSaveDraft) ? false : true,
		};

		if (contentCount && contentCount > 0) {
			const { rewards } = emailRequest;
			emailPayloadTemp = { ...emailPayloadTemp, rewards };
		} else {
			if (appliedReward) {
				emailPayloadTemp = {
					...emailPayloadTemp,
					rewards: processRewardWithoutContent(),
					content: true,
				};
			} else {
				emailPayloadTemp = { ...emailPayloadTemp, content: false };
			}
		}

		setEmailRequest(emailPayloadTemp);

		if (actionType === EMAIL_ACTION.SAVE_DRAFT) {
			const { body, subject, rewards } = emailPayloadTemp;
			let requestTemp = { ...requestData, content: false, newUpdate: false };

			// Draft email returns null, default value is empty -> sync data
			if (_.isNull(body)) {
				requestTemp = { ...requestTemp, body };
			}

			if (_.isNull(subject)) {
				requestTemp = { ...requestTemp, subject };
			}

			if (_.isUndefined(rewards)) {
				requestTemp = { ...requestTemp, rewards };
			}

			if (JSON.stringify(emailPayloadTemp) === JSON.stringify(requestTemp)) {
				message.error(t('email_builder.validation.blank_email'));
				return;
			}

			dispatch(saveDraftEmailRequest(emailPayloadTemp));
		} else {
			executeSendEmail(emailPayloadTemp);
		}
	};

	const processRewardWithoutContent = () => {
		let rewardsTemp: any[] = [];
		const { creators } = emailRequest;

		if (creators?.length) {
			creators.forEach((creator: any) => {
				const rewardedCreator = {
					contentId: null,
					campaignId: null,
					campaignName: null,
					creatorId: creator.creatorId,
					creatorFirstName: creator?.firstName,
					creatorLastName: creator?.lastName,
					creatorEmail: creator.email,
					creatorName: creator?.firstName + ' ' + creator?.lastName,
					deleted: true,
				};
				rewardsTemp.push(rewardedCreator);
			});
		}
		rewardsTemp = appliedReward ? setRewardValues(appliedReward, rewardsTemp) : rewardsTemp;
		return rewardsTemp;
	};

	const executeSendEmail = (emailPayloadTemp: EmailPayloadDto) => {
		const { subject, body, creators, segments } = emailPayloadTemp;

		if (subject === '' && body === '') {
			message.error(t('email_builder.notification.lack_body_subject'));
			onClose();
			return;
		}

		if (
			(creators?.length === 0 && segments?.length === 0) ||
			(creators?.length === 0 && !('segments' in emailPayloadTemp))
		) {
			message.error(t('email_builder.notification.recipients'));
			onClose();
			return;
		}

		if (subject === '') {
			setConfirmModal({
				isVisible: true,
				type:
					t('email_builder.subject_text') +
					t('email_builder.notification.subject_or_body'),
			});

			return;
		}

		if (body === '') {
			setConfirmModal({
				isVisible: true,
				type:
					t('email_builder.body_text') + t('email_builder.notification.subject_or_body'),
			});

			return;
		}

		setIsLoading(true);

		emailPayloadTemp = {
			...emailPayloadTemp,
			creators: creators ? convertCreators(creators) : [],
			segments: segments ? convertSegments(segments) : [],
		};

		if (segments?.length) {
			if (appliedReward) {
				message.info(t('message.reward_segment'));
				setIsLoading(false);
				setConfirmModal({ ...confirmModal, isVisible: false });
				return;
			}
		}

		dispatch(sendEmailRewardRequest(emailPayloadTemp));
	};

	const getAppliedReward = (reward: RewardContainerDto) => {
		setAppliedReward(reward);
	};

	const onClose = () => {
		setConfirmModal({ ...confirmModal, isVisible: false });
	};

	const contextValues = {
		emailRequest,
		setConfirmModal,
		onLoading: setIsLoading,
		setEmailRequest,
		setGeneralAppliedReward: setAppliedReward,
		isPopup: isPopup || false,
		isInvitationFromCreator,
	};

	return (
		<EmailBuilderContext.Provider value={contextValues}>
			<LoadingWrapper isLoading={isLoading}>
				<StyledRow gutter={32}>
					<StyledCol span={16}>
						{(!isPopup || isActivationEmail) && (
							<StyledSubject>
								<StyledTitle width="62px" className="subject-title">
									{t('email_builder.subject')}
								</StyledTitle>
								<StyledTitle width="calc(100% - 62px)" className="subject-content">
									<StyledInput
										placeholder={t('email_builder.no_subject')}
										value={emailRequest.subject}
										onChange={onChangeSubject}
									/>
								</StyledTitle>
							</StyledSubject>
						)}
						<EmailBuilderEditor
							ref={emailBuilderEditorRef}
							handleLoading={handleLoading}
							onOpenReward={onOpenReward}
							template={template}
							handleChangeTemplate={handleChangeTemplate}
						/>
					</StyledCol>

					<StyledCol padding="0" background={true} span={8}>
						<EmailBuilderPanel
							ref={emailBuilderPanelRef}
							handleSendEmail={handleSendEmail}
							saveTemplateModal={saveTemplateModal}
							handleTemplateBuilder={handleTemplateBuilder}
							handlePayloadSendMail={handlePayloadSendMail}
							insertDynamicField={insertDynamicField}
							getAppliedReward={getAppliedReward}
						/>
					</StyledCol>
				</StyledRow>

				{isSaveTemplate && (
					<SaveTemplateModal
						isVisible={isSaveTemplate}
						template={emailRequest}
						saveTemplateModal={saveTemplateModal}
					/>
				)}

				{confirmModal.isVisible && (
					<ConfirmModal
						width={420}
						confirmLoading={isLoading}
						isOpen={confirmModal.isVisible}
						cancelText={t('button.no')}
						onClose={onClose}
						onSubmit={() => {
							if ('onSubmit' in confirmModal) {
								const { onSubmit } = confirmModal;
								onSubmit();
								return;
							}
							setIsLoading(true);
							message.info(t('email_builder.notification.checking_recipient_emails'));
							dispatch(sendEmailRewardRequest(emailRequest));
						}}>
						<StyledConfirmModalContent>{confirmModal.type}</StyledConfirmModalContent>
					</ConfirmModal>
				)}
			</LoadingWrapper>
		</EmailBuilderContext.Provider>
	);
});
export default EmailBuilder;
