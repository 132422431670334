import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { CreatorSegmentPaginator as defaultPage } from '@constants/paginator';
import { Affix, ConfigProvider, Empty, Pagination, Spin } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

// Models
import { SegmentsDto, SegmentSortDto } from '@models/creator/segments';

// Hooks
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Context
import CreatorSegmentsContext from '@contexts/Creators/Segments';

// Redux
import { creatorGetSegment } from '@stores/actions';

// constants
import THEME from '@constants/themes/themes';
// components
import { StyledTable } from '@components/CustomTable';
import { ROUTE_PATH } from '@constants/common';
import { UserRootStateDto } from '@stores/user/user.types';
import { useHistory } from 'react-router-dom';

const StyledEmpty = styled(Empty)`
	margin: 0;

	.ant-empty-image {
		width: 100%;
		height: unset;
	}
`;

const StyledPagination = styled.div`
	background-color: ${THEME.colors.darkBlue1};
	height: 50px;
	align-items: center;
	justify-content: center;
	margin: 0;
	position: absolute;
	bottom: 16px;
	z-index: 333;
`;

const StyledWrapperTable = styled.div`
	.ant-table-wrapper {
		margin-top: 50px;
		background-color: ${THEME.colors.darkBlue1};

		.ant-checkbox {
			top: 0;
		}

		.ant-table {
			.ant-table-body {
				height: calc(100vh - 242px);
			}
		}
	}
`;

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

type SegmentProps = {
	columns: Array<any>;
	isSelectAll: boolean;
	changeSelectedRow: (selectedRowKeys: React.Key[]) => void;
	changeIsSelectAll: () => void;
};

const SegmentList = ({
	columns,
	changeSelectedRow,
	changeIsSelectAll,
	isSelectAll,
}: SegmentProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();

	const { handleChangeParamSegment, paramSegment } = useContext(CreatorSegmentsContext);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [dataSource, setDataSource] = useState<SegmentsDto[]>([]);

	const {
		fetchingSegment,
		segmentData = [],
		totalSegments = 0,
	} = useSelector((state: any) => state.creator);
	const { fetchingUser } = useSelector((state: UserRootStateDto) => state.user);

	useEffect((): any => {
		segmentData?.forEach((profile: SegmentsDto) => {
			profile.key = profile.id;
		});
		// If is select all check box is ticked, then we add all the keys to the selection
		if (isSelectAll) {
			changeIsSelectAll();
		}
		setDataSource(segmentData);
	}, [segmentData]);

	useEffect(() => {
		setSelectedRowKeys([]);
	}, [fetchingSegment]);

	useEffect(() => {
		dispatch(creatorGetSegment(paramSegment));
	}, [paramSegment]);

	const onChangeTable = (pagination: any, filters: any, sorter: any, extra: any) => {
		const { field, order } = sorter;

		const sortOrders: SegmentSortDto = {
			ascend: 'ASC',
			descend: 'DESC',
		};

		const sortFields: SegmentSortDto = {
			title: 'TITLE',
			lastUpdate: 'UPDATE_AT',
			totalCreatorProfiles: 'TOTAL_PROFILES',
			totalUploads: 'TOTAL_UPLOADS',
		};

		handleChangeParamSegment({
			...paramSegment,
			page: 1,
			sortDirection: sortOrders[order],
			sortField: sortFields[field],
		});
	};

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(selectedRowKeys);
		changeSelectedRow(selectedRowKeys);
	};

	const onChangePage = (pageNumber: number) => {
		handleChangeParamSegment({ ...paramSegment, page: pageNumber });
		if (isSelectAll) {
			onSelectChange([]);
		}
	};

	const onSelectAllChange = () => {
		changeIsSelectAll();
	};

	const tableLoading = {
		spinning: fetchingSegment,
		indicator: <Spin size="large" />,
	};

	const rowSelection = {
		selectedRowKeys,
		onSelectAll: onSelectAllChange,
		onChange: onSelectChange,
	};

	return (
		<>
			<ConfigProvider renderEmpty={customizeRenderEmpty}>
				<StyledWrapperTable className="card-content card-bg">
					<StyledTable
						loading={fetchingUser ? false : tableLoading}
						dataSource={dataSource}
						columns={columns}
						pagination={false}
						sticky
						className="table_row_action"
						rowSelection={rowSelection}
						onChange={onChangeTable}
						scroll={{ y: 'calc(100vh - 242px)' }}
						onRow={(record: any, rowIndex: any) => ({
							onClick: () =>
								history.push({
									pathname: ROUTE_PATH.CREATORS_SUMMARY,
									state: { segmentId: record?.id },
								}),
						})}
					/>
				</StyledWrapperTable>
				<Affix offsetBottom={0}>
					<StyledPagination className="card-content">
						<Pagination
							total={totalSegments}
							pageSize={defaultPage.pageSize}
							defaultPageSize={defaultPage.pageSize}
							current={paramSegment.page}
							showSizeChanger={false}
							showQuickJumper
							onChange={onChangePage}
							showTotal={(total, range) =>
								`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${
									total || 0
								} ${t(`${total > 1 ? 'pagination.items' : 'pagination.item'}`)}`
							}
						/>
					</StyledPagination>
				</Affix>
			</ConfigProvider>
		</>
	);
};

export default React.memo(SegmentList);
