/**
 * @prettier
 */

import THEME from '@constants/themes/themes';
import Button from '@cores/Button';
import Wrapper from '@cores/Wrapper';
import { Cascader, Empty, Input, Select } from 'antd';
import styled from 'styled-components';

const square = 595;
const landscape = 579;
const portrait = 712;

const StyledContainer = styled(Wrapper)`
	padding: 0;
	margin-bottom: 16px;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	width: 100%;
	display: inline-flex;
	overflow: auto hidden;
	white-space: nowrap;
	flex-wrap: nowrap;
`;

const StyledWrapperOverlayDropdown = styled.div<{ width?: string; bgColor?: string }>`
	background: ${({ bgColor }) => bgColor || THEME.colors.darkBlue3};
	width: ${({ width }) => width || '250px'};
	text-align: left;
	border-radius: 2px;
	overflow: hidden;
`;

const StyledContainerListOption = styled.div`
	width: 100%;
	max-height: 50vh;
	overflow-y: auto;
`;

const StyledWrapperApplyAlbum = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 6px 12px;
	border-top: 1px solid ${THEME.colors.gray1};
`;

const StyledOptionItem = styled.div<{ bgColor?: string; bgActive?: string; padding?: string }>`
	width: 100%;
	height: 32px;
	padding: ${({ padding }) => padding || '6px 8px'};
	background: ${({ bgColor }) => bgColor || THEME.colors.darkBlue3};
	cursor: pointer;
	transition: 0.2s;
	display: flex;
	align-items: center;
	&.disabled {
		pointer-events: none !important;
		cursor: default;
	}
	&.not_action {
		cursor: default;
	}

	&:hover {
		background: ${({ bgActive }) => bgActive || THEME.colors.orangeBase};
	}
	&.active {
		background: ${({ bgActive }) => bgActive || THEME.colors.orangeBase};
	}
`;

const StyledIcon = styled.span`
	width: 30px;
	display: inline-flex;
	align-items: center;
`;

const StyledNameOption = styled.span`
	width: calc(100% - 30px);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
`;

const StyledAction = styled.div`
	display: flex;
	align-items: center;
`;

const StyledBtn = styled(({ width, height, isDisabled, ...props }) => <Button {...props} />)`
	margin-left: 8px;
	border-color: ${THEME.colors.darkBlue4};
	padding: 0 12px;
	${({ width }) => width && `width: ${width}`};
	height: ${({ height }) => height || '32px'};
	color: ${THEME.colors.gray5};
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	transition: all 0.3s !important;
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};

	&.btn-chip {
		svg {
			fill: unset !important;

			path {
				${({ visible }) => visible && `stroke: ${THEME.colors.orangeBase}`};
			}
		}

		&:hover {
			svg {
				&,
				path {
					fill: unset !important;
					stroke: ${THEME.colors.orangeBase};
				}
			}
		}
	}

	svg {
		fill: ${THEME.colors.gray2};
	}

	&.ant-btn-ghost {
		&:hover {
			background-color: transparent;
			color: ${THEME.colors.orangeBase};
		}
	}

	&.ant-btn-icon-only {
		font-size: unset;
		padding: 3px;
	}
`;

const StyledBtnCustom = styled(StyledBtn)`
	&:hover,
	&:focus {
		svg {
			&,
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}

	&.active-filter {
		background-color: ${THEME.colors.orangeBase};
		color: ${THEME.colors.gray5};

		&,
		&:hover,
		&:focus {
			background-color: ${THEME.colors.orangeBase};
			color: ${THEME.colors.gray5};

			svg {
				&,
				path {
					fill: ${THEME.colors.gray5} !important;
				}
			}
		}
	}
`;

const StyledCustomSelect = styled(Select)<{
	heightParentContains: number;
	subtractedHeight: number;
}>`
	margin-right: 8px;
	width: 160px;

	&.select_gallery {
		.ant-select-selection-overflow-item {
			height: 40px;
			min-height: 22px;
			&.ant-select-selection-overflow-item-suffix {
				height: auto;
			}
		}
	}

	&.select_group_tags {
		.ant-select-dropdown {
			.ant-select-item {
				&.ant-select-item-group {
					font-weight: bold;
					color: ${THEME.colors.gray1};
				}
			}
		}
	}

	.ant-select-selector {
		background-color: transparent !important;
	}
	.ant-select-dropdown {
		&.dropdown-custom-content_detail {
			overflow: auto !important;
			.rc-virtual-list-holder {
				max-height: 50vh;
				.rc-virtual-list-holder-inner {
					transform: translateY(0px) !important;
				}
			}
			.rc-virtual-list-scrollbar {
				display: none !important;
			}
			&.type_square {
				.rc-virtual-list-holder {
					max-height: min(
						${({ heightParentContains, subtractedHeight }) =>
							heightParentContains >= square
								? `(${square / 2}px - ${subtractedHeight / 2 + 35}px)`
								: `(${heightParentContains / 2}px - ${
										subtractedHeight / 2 + 35
								  }px)`},
						50vh - 73px
					) !important;
				}
			}
			&.type_landscape {
				.rc-virtual-list-holder {
					max-height: min(
						${({ heightParentContains, subtractedHeight }) =>
							heightParentContains >= landscape
								? `(${landscape / 2}px - ${subtractedHeight / 2 + 35}px)`
								: `(${heightParentContains / 2}px - ${
										subtractedHeight / 2 + 35
								  }px)`},
						50vh - 73px
					) !important;
				}
			}
			&.type_portrait {
				.rc-virtual-list-holder {
					max-height: min(
						${({ heightParentContains, subtractedHeight }) =>
							heightParentContains >= portrait
								? `(${portrait / 2}px - ${subtractedHeight / 2 + 35}px)`
								: `(${heightParentContains / 2}px - ${
										subtractedHeight / 2 + 35
								  }px)`},
						50vh - 73px
					) !important;
				}
			}
		}
	}
`;

const StyledSelect = styled(Select)`
	margin-right: 8px;
	width: 160px;

	.ant-select-selector {
		background-color: transparent !important;
	}
`;

const StyledCascader = styled((props) => <Cascader {...props} />)`
	width: 160px;
	margin-right: 8px;

	.ant-input {
		height: 32px;
	}

	.ant-cascader-picker-arrow {
		color: unset;
	}

	&:hover {
		.ant-cascader-picker-clear {
			color: #e0e3e5;
		}
	}
`;

const StyledSearch = styled(Input.Search)<{ width?: string; padding?: string; margin?: string }>`
	width: ${({ width }) => width || '200px !important'};
	padding: ${({ padding }) => padding || 'unset'};
	margin: ${({ margin }) => margin || '0 8px 0 0'};

	&,
	.ant-input,
	.ant-input-group-addon,
	.ant-input-search-button {
		height: 32px;
		background: transparent;
	}

	.ant-input {
		border-right: none;
		box-shadow: none;
		border-color: ${THEME.colors.darkBlue4};
	}

	.ant-btn {
		border-left: none;
		border-color: ${THEME.colors.darkBlue4};
		padding: 0;

		&:focus {
			border-color: none;
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.ant-btn.ant-btn-primary:focus,
	.ant-btn.ant-btn-primary:hover {
		background-color: transparent;
	}
`;

const StyledEmpty = styled(Empty)`
	margin: 0;

	.ant-empty-image {
		width: 100%;
		height: unset;
	}
`;

export {
	StyledAction,
	StyledBtn,
	StyledBtnCustom,
	StyledCascader,
	StyledContainer,
	StyledContainerListOption,
	StyledCustomSelect,
	StyledEmpty,
	StyledIcon,
	StyledNameOption,
	StyledOptionItem,
	StyledSearch,
	StyledSelect,
	StyledWrapperApplyAlbum,
	StyledWrapperOverlayDropdown,
};
