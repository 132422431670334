import { IconDragHandle } from '@assets/icons';
import { SummaryColumnsDto } from '@models/creator/summary';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const StyledMenuColumnsItems = styled.li`
	padding: 6px 8px;
	height: 32px;
	display: flex;
	align-items: center;
	z-index: 3000 !important;
`;

const StyledCheckboxItems = styled(Checkbox)`
	color: white;
	margin-left: 8px;
`;

interface PropsSortColumn extends CheckedEvent {
	list: Array<SummaryColumnsDto>;
	onSortEnd: (val: Array<SummaryColumnsDto>) => void;
}

interface PropsItemTypes extends CheckedEvent {
	item: SummaryColumnsDto;
	index: number;
}

type CheckedEvent = {
	handleChangeChecked: (e: CheckboxChangeEvent) => void;
};
const reorder = (list: Array<SummaryColumnsDto>, startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const ItemSort = ({ item, index, handleChangeChecked }: PropsItemTypes) => {
	return (
		<Draggable draggableId={item.dataIndex || ''} index={index}>
			{(provided) => (
				<StyledMenuColumnsItems ref={provided.innerRef} {...provided.draggableProps}>
					<StyledIcon {...provided.dragHandleProps}>
						<IconDragHandle />
					</StyledIcon>
					<StyledCheckboxItems
						checked={item.show}
						id={item.dataIndex}
						disabled={item.disabled}
						onChange={(e: CheckboxChangeEvent) => {
							handleChangeChecked(e);
						}}>
						{item.title}
					</StyledCheckboxItems>
				</StyledMenuColumnsItems>
			)}
		</Draggable>
	);
};

export const ColumnSortList = ({
	list,
	handleChangeChecked,
}: {
	list: Array<SummaryColumnsDto>;
	handleChangeChecked: (e: CheckboxChangeEvent) => void;
}) => {
	return (
		<>
			{list.map((item: SummaryColumnsDto, index: number) => (
				<ItemSort
					handleChangeChecked={handleChangeChecked}
					item={item}
					index={index}
					key={item.dataIndex}
				/>
			))}
		</>
	);
};

const SortColumn = (props: PropsSortColumn) => {
	const { list, onSortEnd, handleChangeChecked } = props;
	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const quotes = reorder(list, result.source.index, result.destination.index);

		onSortEnd(quotes);
	};
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="list">
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						<ColumnSortList handleChangeChecked={handleChangeChecked} list={list} />
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default SortColumn;
