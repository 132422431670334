// Icons
import { IconCampaign, IconClose, IconFlag, IconLabel, IconNote } from '@assets/icons';
import { defaultRowTextArea } from '@constants/common';
import {
	CHANGED_DETAILS,
	MAX_LENGTH_CHARACTER,
	SELECT_TAGS,
} from '@constants/content/contentLibrary';
import THEME from '@constants/themes/themes';
import { StateCampaign } from '@models/campaign';
import { RefSocialSearchActions } from '@models/socialsearch/ref';
// Models
import { SocialContentFiltersDto } from '@models/socialsearch/summary';
import { StyledButton } from '@styled/Common/CommonStyled';
import {
	StyledBtn,
	StyledBtnGhost,
	StyledSection,
	StyledTitle,
} from '@styled/Content/ContentLibrary/ContentStyled';
// Styled
import {
	StyledContainer,
	StyledFormItem,
	StyledSelect,
	StyledText,
	StyledTextArea,
	StyledSocialActions,
} from '@styled/Content/ContentLibrary/FilterStyled';
import { Form, Rate } from 'antd';
import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type BookmarkPostProps = {
	closeActions: () => void;
	handleConnect: () => void;
	handleImportAction: (filters?: SocialContentFiltersDto) => void;
	isConnected: boolean;
	isHidden?: boolean;
	className?: string;
	classContainer?: string;
};

const BookmarkPost = forwardRef((props: BookmarkPostProps, ref: Ref<RefSocialSearchActions>) => {
	const { t } = useTranslation();
	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const labels: string[] = clientSettings.labels ? clientSettings.labels : [];

	const formItemSelectTags = [
		{
			name: SELECT_TAGS.LABELS.name,
			head: {
				icon: <IconLabel />,
				title: 'social_search.details.tags',
			},
			options: labels,
		},
		{
			name: SELECT_TAGS.CAMPAIGNS.name,
			head: {
				icon: <IconCampaign />,
				title: 'social_search.actions.campaigns',
			},
			options: campaignListContent || [],
		},
		{
			name: t('social_search.actions.notes'),
			head: {
				icon: '',
				title: CHANGED_DETAILS.NOTE.title,
			},
			options: [],
		},
	];
	const [form] = Form.useForm();

	// Props
	const {
		closeActions,
		handleImportAction,
		handleConnect,
		isConnected,
		isHidden = false,
		classContainer = '',
		className = '',
	} = props;

	useImperativeHandle(ref, () => ({
		resetFilters,
	}));

	const renderLabel = (icon: any, text: any) => (
		<StyledTitle>
			{icon}
			<StyledText fontSize="1rem">{t(text)}</StyledText>
		</StyledTitle>
	);

	const onValuesChange = (valuesChange: any) => {};

	const onFinish = (values: SocialContentFiltersDto) => {
		handleImportAction(values);
	};

	const resetFilters = () => {
		form.resetFields();
	};

	// Render components
	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const renderListOption = (options: any[], name: string) => {
		if (name === SELECT_TAGS.CAMPAIGNS.name) {
			return options?.map((option) => ({ label: option?.name, value: option?.id }));
		} else {
			return options?.map((option) => ({ label: option, value: option }));
		}
	};

	return (
		<StyledSocialActions className={`${className} ${isHidden ? 'hidden' : ''} filter-content`}>
			<StyledSection borderPosition="bottom" heightSection="54px" flexDirection="row">
				<StyledTitle fontSize="1.143rem" fontWeight="600">
					{t('social_search.actions.rate_tag')}
				</StyledTitle>

				<StyledTitle
					onClick={closeActions}
					cursor="pointer"
					fontSize="0.857rem"
					color={THEME.colors.gray3}>
					<IconClose />
				</StyledTitle>
			</StyledSection>

			{isConnected && (
				<StyledContainer className={classContainer}>
					<Form
						form={form}
						layout="vertical"
						onValuesChange={onValuesChange}
						onFinish={onFinish}>
						<StyledFormItem
							name={CHANGED_DETAILS.STAR.name}
							label={renderLabel(null, CHANGED_DETAILS.STAR.title)}>
							<Rate />
						</StyledFormItem>
						{formItemSelectTags.map((item) => {
							const { name, head, options } = item;

							if (name === t('social_search.actions.notes')) {
								return (
									<StyledFormItem
										key={name}
										name={CHANGED_DETAILS.NOTE.name}
										label={renderLabel(head.icon, head.title)}>
										<StyledTextArea
											maxLength={MAX_LENGTH_CHARACTER}
											rows={defaultRowTextArea}
										/>
									</StyledFormItem>
								);
							}

							return (
								<StyledFormItem
									key={name}
									name={name}
									label={renderLabel(head.icon, head.title)}>
									<StyledSelect
										mode={
											name === SELECT_TAGS.LABELS.name ? 'tags' : 'multiple'
										}
										showArrow={true}
										notFoundContent={notFoundContent()}
										getPopupContainer={(triggerNode: HTMLElement) =>
											triggerNode.parentNode as HTMLElement
										}
										virtual={false}
										optionFilterProp="label"
										options={renderListOption(options, name)}
										dropdownClassName="dropdown-menu dropdown-custom-content"
									/>
								</StyledFormItem>
							);
						})}
					</Form>
				</StyledContainer>
			)}

			{!isConnected && (
				<StyledContainer>
					<StyledTitle margin="0 0 8px 0" fontSize="1.143rem" fontWeight="600">
						{t('link_instagram.title')}
					</StyledTitle>
					<p>{t('link_instagram.description')}</p>
					<StyledBtn onClick={handleConnect}>{t('button.connect')}</StyledBtn>
				</StyledContainer>
			)}

			<StyledSection
				isDisableAction={!isConnected}
				borderPosition="top"
				heightSection="64px"
				flexDirection="row">
				<StyledBtnGhost
					padding="0 12px"
					width="unset"
					type="ghost"
					onClick={() => {
						handleImportAction();
					}}>
					{t('button.bookmark_without_saving')}
				</StyledBtnGhost>
				<StyledBtn padding="0 12px" width="unset" onClick={() => form.submit()}>
					{t('button.save_bookmark')}
				</StyledBtn>
			</StyledSection>
		</StyledSocialActions>
	);
});

export default BookmarkPost;
