import {
	DEFAULT_EMAIL_OPT_IN,
	EMAIL_OPT_IN,
	EMAIL_OPT_IN_FIELD_VALUES,
	KEY_FIELD_FORM_TERM,
} from '@constants/content/widgetManager';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { SelectOptionDto } from '@models/common/summary';
import {
	MetadataEmailOptInDto,
	TermConditionItemDto,
	WidgetBuilderDetailDto,
} from '@models/content/widgetManager';
import { RefEmailOptIn } from '@models/content/widgetManager/ref';
import { StyledForm, StyledRadioButton, StyledRadioGroup } from '@styled/Common/CommonStyled';
import { StyledLabelItem, StyledSubText } from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { RadioChangeEvent, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Ref, forwardRef, useContext, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TermItem from '../TermItem';

const EmailOptIns = forwardRef((_, ref: Ref<RefEmailOptIn>) => {
	const { t } = useTranslation();
	const [form] = useForm();
	const { detailWidget, setDetailWidget } = useContext(WidgetBuilderContext);
	const { metadata } = detailWidget;

	const { emailOptIn = {} } = metadata.body || {};
	const { name = '', fieldValues = {} } = emailOptIn || {};

	useImperativeHandle(ref, () => ({
		validateFields,
	}));

	const validateFields = async () => {
		await form.validateFields();
	};

	const radioOptions = [
		{
			label: t('content.widget_manager.widget_builder.email_opts.do_not_add_user'),
			value: EMAIL_OPT_IN.NOT_ADD_USER.name,
		},
		{
			label: t(
				'content.widget_manager.widget_builder.email_opts.marketing_list_without_telling_them',
			),
			value: EMAIL_OPT_IN.OPT_IN_AUTOMATICALLY.name,
		},
		{
			label: t('content.widget_manager.widget_builder.email_opts.marketing_list_submission'),
			value: EMAIL_OPT_IN.OPT_IN_SUBMISSION.name,
		},
		{
			label: t('content.widget_manager.widget_builder.email_opts.ask_user'),
			value: EMAIL_OPT_IN.ASK_USER.name,
		},
	];

	const initialValues = useMemo(() => {
		const result: any = {};

		if (name) {
			const fieldName = name.toLowerCase();
			result[`${KEY_FIELD_FORM_TERM.text}${fieldName}`] = fieldValues?.text || '';
			result[`${KEY_FIELD_FORM_TERM.textLink}${fieldName}`] = fieldValues?.textLink || '';
			result[`${KEY_FIELD_FORM_TERM.valueTextLink}${fieldName}`] = fieldValues?.url || '';
		}

		return result;
	}, [emailOptIn]);

	const onChangeEmailOptIn = (e: RadioChangeEvent) => {
		const { value } = e.target;
		const emailOptInTemp = {
			name: value,
			optInMethod: EMAIL_OPT_IN[value.toUpperCase()].method,
			fieldValues: {},
		};

		if (EMAIL_OPT_IN_FIELD_VALUES.includes(value)) {
			emailOptInTemp.fieldValues = {
				...DEFAULT_EMAIL_OPT_IN.fieldValues,
				required: value === EMAIL_OPT_IN.ASK_USER.name,
			};
		}

		handleUpdateDetailWidget(emailOptInTemp);
	};

	const handleUpdateDetailWidget = (updatedData: MetadataEmailOptInDto) => {
		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				body: {
					...prev.metadata.body,
					emailOptIn: updatedData,
				},
			},
		}));
	};

	const renderOptInDetail = (optItem: SelectOptionDto) => {
		const { value } = optItem;

		const customizedValues = {
			linkTitle: 'otp_in_text',
			showRequiredCheckbox: emailOptIn?.name === EMAIL_OPT_IN.ASK_USER.name,
			hyperlinkSuffix: t('content.widget_manager.widget_builder.optional'),
			allowRemoving: false,
			isTextAreaMainText: true,
		};

		return (
			<TermItem
				setFieldsValue={(val: any) => form.setFieldsValue(val)}
				getValueField={(val: string) => form.getFieldValue(val)}
				keyIndex={value.toLowerCase()}
				term={emailOptIn?.fieldValues}
				handleChangeRequired={handleChangeRequired}
				customizedValues={customizedValues}
				isTerms={false}
			/>
		);
	};

	const handleChangeRequired = (changedItem: TermConditionItemDto) => {
		const changedValues = {
			...emailOptIn,
			fieldValues: { ...changedItem },
		};
		handleUpdateDetailWidget(changedValues);
	};

	const onValuesChange = (changedValue: any) => {
		const keyName = Object.keys(changedValue)[0];
		const value = changedValue[keyName];
		const detailTemp = { ...emailOptIn };

		if (keyName.indexOf(KEY_FIELD_FORM_TERM.text) === 0) {
			detailTemp.fieldValues.text = value;
		}
		if (keyName.indexOf(KEY_FIELD_FORM_TERM.textLink) === 0) {
			detailTemp.fieldValues.textLink = value;
		}
		if (keyName.indexOf(KEY_FIELD_FORM_TERM.valueTextLink) === 0) {
			detailTemp.fieldValues.url = value;
		}
		handleUpdateDetailWidget(detailTemp);
	};

	return (
		<StyledForm form={form} onValuesChange={onValuesChange} initialValues={initialValues}>
			<StyledLabelItem>
				{t('content.widget_manager.widget_builder.email_opt_in')}
			</StyledLabelItem>
			<StyledSubText>
				{t('content.widget_manager.widget_builder.email_opt_in_sub')}
			</StyledSubText>
			<StyledRadioGroup
				value={emailOptIn?.name || EMAIL_OPT_IN.OPT_IN_SUBMISSION}
				className="block"
				onChange={onChangeEmailOptIn}>
				<Space style={{ width: '100%' }} direction="vertical">
					{radioOptions.map((item) => {
						if (item.value === EMAIL_OPT_IN.OPT_IN_SUBMISSION.name) {
							return (
								<>
									<StyledRadioButton value={item?.value}>
										{item?.label}
									</StyledRadioButton>
									{name === EMAIL_OPT_IN.OPT_IN_SUBMISSION.name &&
										renderOptInDetail(item)}
								</>
							);
						}

						if (item.value === EMAIL_OPT_IN.ASK_USER.name) {
							return (
								<>
									<StyledRadioButton value={item?.value}>
										{item?.label}
									</StyledRadioButton>
									{name === EMAIL_OPT_IN.ASK_USER.name && renderOptInDetail(item)}
								</>
							);
						}

						return (
							<StyledRadioButton value={item.value}>{item.label}</StyledRadioButton>
						);
					})}
				</Space>
			</StyledRadioGroup>
		</StyledForm>
	);
});

export default EmailOptIns;
