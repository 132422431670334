import { useDispatch, useSelector } from 'react-redux';

// Components
import Event from '@components/Messages/MessageLog/Logs/EventList/Event';
// Models
import { EventDto } from '@models/messages/log';
import { storeMessageTemplateData } from '@stores/actions';
import { useEffect } from 'react';

type EventListProps = {};

const EventList = (props: EventListProps) => {
	const dispatch = useDispatch();
	const { emailLog = {} } = useSelector((state: any) => state.messages);
	const { events = [] } = emailLog;

	useEffect(() => {
		return () => {
			dispatch(
				storeMessageTemplateData({
					eventId: null,
				}),
			);
		};
	}, [emailLog]);

	return (
		<>
			{events.map((event: EventDto) => (
				<Event key={event?.id} data={event} />
			))}
		</>
	);
};

export default EventList;
