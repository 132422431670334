import { IconAdd, IconRemove, IconSuggestAlbum } from '@assets/icons';
import {
	CAMPAIGN_DETAIL_FIELD_NAME,
	MODAL_ACTION_TYPES,
	TAB,
	TOTAL_SIZE,
} from '@constants/campaign';
import THEME from '@constants/themes/themes';
import CampaignDetailContext from '@contexts/Campaign/CampaignDetail';
import { CampaignDto, ContentImgDto } from '@models/campaign';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import {
	StyledContainerMockup,
	StyledContent,
	StyledDraggerImg,
	StyledFormItemCampaign,
	StyledIconAdd,
	StyledIconRemoveUpload,
	StyledImgUpload,
	StyledLabelContent,
	StyledListUpload,
	StyledSubLimit,
	StyledSuggestImg,
	StyledTextLink,
	StyledUploadChild,
	StyledWrapperChildUpload,
	StyledWrapperContainer,
	StyledWrapperContent,
	StyledWrapperHeader,
} from '@styled/Campaign/CampaignDetailStyled';
import {
	StyledButtonPreview,
	StyledCardModalInputTextArea,
	StyledCardModalSelect,
	StyledFormItem,
} from '@styled/Campaign/CampaignStyled';
import { StyledFlex, StyledIcon } from '@styled/Common/CommonStyled';
import { StyledDividerLine } from '@styled/Filter/FilterContentStyled';
import { Button, Col, Input, Row, message } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ContentListModal from '../ContentListModal';
import MockupPost from './MockupPost';
import MockupShare from './MockupShare';
import LandingPage from './LandingPage';
import { getUser } from '@helpers/userHelpers';
import { ROLE } from '@constants/settings';

type PropTypes = {
	form: any;
	handleSuggestImg: () => void;
};
const CTAContainer = (props: PropTypes) => {
	const { form, handleSuggestImg } = props;
	const { campaignDetail }: CampaignDto = useSelector((state: any) => state.campaign);
	const user = getUser();

	const {
		img1,
		img2,
		img3,
		setImg1,
		setImg2,
		setImg3,
		headerVal,
		descVal,
		typeCampaign,
		handleMockup,
		handleUpdateSuggestId,
		setTypeAction,
		campaignEmailTemplate = {},
		onPreviewTemplate,
		disabledFields = [],
		setHasChange,
	} = useContext(CampaignDetailContext);

	const { t } = useTranslation();

	const [uploadSelect, setUploadSelect] = useState<{
		val: ContentImgDto | null;
		func: (val: ContentImgDto | null) => void;
	} | null>(null);

	const listUpload = [
		{
			id: 1,
			val: img1,
			func: setImg1,
		},
		{
			id: 2,
			val: img2,
			func: setImg2,
		},
		{
			id: 3,
			val: img3,
			func: setImg3,
		},
	];

	const getBase64 = (img: RcFile, callback: (url: string) => void) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result as string));
		reader.readAsDataURL(img);
	};

	const handleChange = (info: any, callback: (val: ContentImgDto | null) => void) => {
		if (info.file) {
			getBase64(info.file as RcFile, (url) => {
				callback({ url, file: info.file });
			});
		}
	};

	const renderUpload = (
		val: ContentImgDto | null,
		func: (val: ContentImgDto | null) => void,
		key: number,
	) => {
		return (
			<StyledDraggerImg
				key={key}
				name={CAMPAIGN_DETAIL_FIELD_NAME.AVATAR}
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				accept="image/png, image/jpeg"
				beforeUpload={() => false}
				onChange={(info: any) => handleChange(info, func)}>
				<StyledWrapperChildUpload
					onClick={(e: React.MouseEvent<HTMLDivElement>) => {
						e.preventDefault();
						e.stopPropagation();
						setUploadSelect({
							val,
							func: (val: ContentImgDto | null) => {
								func(val);
							},
						});
					}}>
					{val && (
						<StyledIconRemoveUpload
							onClick={(e: React.MouseEvent<HTMLDivElement>) => {
								e.preventDefault();
								e.stopPropagation();
								setHasChange(true);
								func(null);
							}}>
							<IconRemove />
						</StyledIconRemoveUpload>
					)}
					<StyledUploadChild>
						{val ? (
							<>
								<StyledImgUpload src={val?.url} alt="avatar" />
							</>
						) : (
							<StyledIconAdd>
								<IconAdd />
							</StyledIconAdd>
						)}
					</StyledUploadChild>
				</StyledWrapperChildUpload>
			</StyledDraggerImg>
		);
	};

	const onSaveContent = (val: ContentImgDto | null) => {
		if (uploadSelect) {
			if (val?.id) {
				handleUpdateSuggestId(val?.id);
			}
			uploadSelect?.func(val);
		}
	};

	const handleSendActivationEmail = () => {
		if (!campaignDetail?.activationTemplateId) {
			message.destroy();
			message.error(t('campaign.message.no_activation_template'));
			return;
		}
		setTypeAction(MODAL_ACTION_TYPES.SEND_ACTIVATION_EMAIL);
	};

	return (
		<StyledWrapperContainer>
			<ContentListModal
				visible={!!uploadSelect}
				onCancel={() => setUploadSelect(null)}
				idSelect={uploadSelect?.val?.id}
				onSaveContent={onSaveContent}
			/>
			<StyledWrapperHeader>
				{t('campaign_detail.build_your_CTA')}
				<StyledFlex gap="12px">
					<Button onClick={handleMockup}>{t('button.export_mockups')}</Button>
					{campaignDetail && (
						<Button onClick={handleSendActivationEmail} type="primary">
							{t('button.send_activation_email')}
						</Button>
					)}
				</StyledFlex>
			</StyledWrapperHeader>
			<StyledDividerLine />
			<StyledWrapperContent sliceHeight={151}>
				<Row gutter={[24, 24]}>
					<Col
						span={8}
						style={typeCampaign === TAB.archives.key ? { pointerEvents: 'none' } : {}}>
						<StyledContent>
							<StyledFormItem
								colorLabel={THEME.colors.gray3}
								name={CAMPAIGN_DETAIL_FIELD_NAME.HEADER}
								label={t('campaign_detail.header')}>
								<Input
									placeholder={t('campaign_detail.placeholder.header_cta')}
									maxLength={TOTAL_SIZE.HEADER}
								/>
							</StyledFormItem>
							<StyledSubLimit
								margin={
									'-24px 0 0 0'
								}>{`${headerVal.length}/${TOTAL_SIZE.HEADER}`}</StyledSubLimit>
							<StyledFormItem
								colorLabel={THEME.colors.gray3}
								name={CAMPAIGN_DETAIL_FIELD_NAME.DESCRIPTION}
								label={t('campaign.modal.call_to_action')}>
								<StyledCardModalInputTextArea
									maxLength={TOTAL_SIZE.DESCRIPTION}
									rows={4}
									placeholder={t('campaign_detail.placeholder.body_cta')}
								/>
							</StyledFormItem>
							<StyledSubLimit
								margin={
									'-24px 0 0 0'
								}>{`${descVal.length}/${TOTAL_SIZE.DESCRIPTION}`}</StyledSubLimit>
							<StyledLabelContent>
								{t('campaign_detail.sample_images')}
							</StyledLabelContent>
							<StyledListUpload>
								{listUpload?.map((item, idx: number) =>
									renderUpload(item?.val, item?.func, item.id),
								)}
							</StyledListUpload>
							<StyledSuggestImg onClick={handleSuggestImg}>
								<StyledIcon fill={'unset'}>
									<IconSuggestAlbum />
								</StyledIcon>
								<StyledTextLink className="text" margin="0 0 0 5px">
									{t('campaign_detail.suggest_an_image')}
								</StyledTextLink>
							</StyledSuggestImg>
						</StyledContent>
						<StyledDividerLine margin="18px 0" />
						<StyledContent>
							<Row gutter={16} align="bottom">
								<Col span={18}>
									<StyledFormItemCampaign
										className="form_item_end"
										name={CAMPAIGN_DETAIL_FIELD_NAME.ACTIVATION_TEMPLATE_ID}
										label={t('campaign.modal.activation_email')}
										margin="0">
										<StyledCardModalSelect
											style={{ width: '100%' }}
											getPopupContainer={(triggerNode: HTMLElement) =>
												triggerNode.parentNode as HTMLElement
											}
											virtual={false}
											showArrow
											showSearch
											optionFilterProp="label"
											options={campaignEmailTemplate?.activation?.map(
												(item: EmailTemplatesDataDto) => {
													return {
														label: item.title,
														value: item.id,
													};
												},
											)}
										/>
									</StyledFormItemCampaign>
								</Col>

								<Col span={6}>
									<StyledButtonPreview
										isDisabled={disabledFields.includes(
											CAMPAIGN_DETAIL_FIELD_NAME.ACTIVATION_TEMPLATE_ID,
										)}
										onClick={() =>
											onPreviewTemplate(
												CAMPAIGN_DETAIL_FIELD_NAME.ACTIVATION_TEMPLATE_ID,
											)
										}
										type="primary">
										{t('campaign.modal.view')}
									</StyledButtonPreview>
								</Col>
							</Row>
						</StyledContent>
						{user && user.role.toLowerCase() === ROLE.EREVIEWER.value.toLowerCase() && (
							<LandingPage form={form} />
						)}
					</Col>
					<Col span={16}>
						<StyledContainerMockup>
							<MockupShare
								header={headerVal}
								description={descVal}
								url1={img1?.url}
								url2={img2?.url}
							/>
							<MockupPost
								header={headerVal}
								description={descVal}
								url1={img1?.url}
								url2={img2?.url}
								url3={img3?.url}
							/>
						</StyledContainerMockup>
					</Col>
				</Row>
			</StyledWrapperContent>
		</StyledWrapperContainer>
	);
};

export default CTAContainer;
