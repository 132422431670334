import { all, fork } from 'redux-saga/effects';

// Saga
import { watcherHeaderSaga } from '@stores/header/header.saga';
import { watcherContentSaga } from '@stores/content/contentLibrary/content.saga';
import { watcherSidebarSaga } from '@stores/sidebar/sidebar.saga';
import createLoginSagas from './user/user.saga';
import createCreatorSagas from './creator/creator.saga';
import createRewardsSagas from './rewards/rewards.saga';
import { watcherSocialSearchSaga } from '@stores/socialsearch/socialsearch.saga';
import { watcherMessagesSagas } from '@stores/messages/messages.saga';
import { watcherSettingSocialSearchSaga } from '@stores/settings/settings.saga';
import { watcherCampaignSaga } from './campaign/campaign.sagas';
import { watcherDashboardSaga } from '@stores/dashboard/dashboard.saga';
import { createWidgetSaga } from './content/widgetManager/widgetManager.saga';
import { createGallerySaga } from './publishing/galleries/galleryManager.saga';
import { createAlbumSaga } from './content/albumManager/albumManager.saga';
import { watcherNotificationSagas } from './notification/notification.saga';
import { ambassadorSaga } from './ambassador/ambassador.sagas';
import { watcherTaskManagerSaga } from './taskmanager/taskmanager.saga';
import { contentLicensingSaga } from './contentLicensing/contentLicensing.saga';
import { watcherTremendousSaga } from './tremendous/tremendous.saga';
import { watcherLandingPageManagerSaga } from './landingPageManager/landingPageManager.saga';
import { watcherGuestPortalSaga } from './guestportal/guestportal.saga';

export default function* rootSaga() {
	yield all([
		watcherHeaderSaga(),
		watcherContentSaga(),
		watcherSidebarSaga(),
		createLoginSagas(),
		createCreatorSagas(),
		createRewardsSagas(),
		fork(watcherSocialSearchSaga),
		watcherMessagesSagas(),
		watcherSettingSocialSearchSaga(),
		watcherCampaignSaga(),
		watcherDashboardSaga(),
		createWidgetSaga(),
		createGallerySaga(),
		createAlbumSaga(),
		watcherNotificationSagas(),
		ambassadorSaga(),
		watcherTaskManagerSaga(),
		contentLicensingSaga(),
		watcherTremendousSaga(),
		watcherLandingPageManagerSaga(),
		watcherGuestPortalSaga(),
	]);
}
