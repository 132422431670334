import { WIDTH_API_KEY } from '@constants/settings/integrations';
import THEME from '@constants/themes/themes';
import { Input, Modal } from 'antd';
import styled from 'styled-components';

const StyledInputPassword = styled(Input.Password)`
	width: fit-content;
	max-width: 100%;
	min-width: ${WIDTH_API_KEY}px;
	&.disabled_input {
		input {
			pointer-events: none;
		}
	}
`;

const StyledBoxKey = styled.div`
	height: 32px;
	padding: 6px 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid ${THEME.colors.darkBlue4};
	border-radius: 2px;
	width: fit-content;
	max-width: 100%;
	min-width: 334px;
	.text_key {
		color: ${THEME.colors.gray5};
		font-size: 14px;
		max-width: calc(100% - 24px);
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const StyledModalTremendous = styled(Modal)`
	.ant-modal-body {
		padding: 0;
	}
`;

export { StyledBoxKey, StyledModalTremendous, StyledInputPassword };
