import { DEFAULT_ERROR } from '@constants/errors';
import { FAILED, FETCHING, SUCCESSFUL } from '@constants/reward';
import { IN_PROGRESS, NONE, SUCCEEDED, FAILED as NUM_FAILED } from '@constants/status';
import { RewardStore } from '@models/rewards/store';
import { Action, handleActions } from 'redux-actions';
import * as actions from './rewards.actions';
import {
	SegmentsDto,
	getRewardByCreatorTypes,
	logRewardTypes,
	storeRewardDataTypes,
	updateRewardStatusTypes,
} from './rewards.types';

const initialState: RewardStore = {
	error: '',
	fetchingSegment: false,
	fetchingRewardsHistory: false,
	loadingReward: false,
	fetchingCreateReward: FAILED,
	fetchingSaveDraft: FAILED,
	searchDictionary: [],
	segmentList: [],
	totalRewardsHistoryRecords: 0,
	rewardsHistoryList: [],
	inventory: {
		selectedDiscountRewards: [],
		selectedGiftCardsRewards: [],
		selectedProductRewards: [],
		selectedCashRewards: [],
		selectedCustomRewards: [],
	},
	resetStatus: {
		resetDiscountList: false,
		resetGiftcardList: false,
		resetProductList: false,
	},
	listTypeEmptyReward: [],
	rewardsList: {
		activeDiscountList: [],
		draftDiscountList: [],
		archivedDiscountList: [],
		activeGiftcardList: [],
		draftGiftcardList: [],
		archivedGiftcardList: [],
		activeProductList: [],
		draftProductList: [],
		archivedProductList: [],
		activeCashList: [],
		draftCashList: [],
		archivedCashList: [],
		activeCustomList: [],
		draftCustomList: [],
		archivedCustomList: [],
	},
	toggleColumnListRewards: [
		'ruleTitle',
		'rewardType',
		'estimatedCashValue',
		'discountAmount',
		'codeValue',
		'creatorName',
		'rewardDate',
		'status',
	],
	getRewardByCreatorStatus: false,
	updateRewardStatus: NONE,
	logRewardStatus: NONE,
};

const setInventoryState = (state: any, action: any) => ({
	...action.payload,
});

const updateRewardSelect = (state: any, action: any) => {
	return {
		...state,
		inventory: {
			...state.inventory,
			...action.payload,
		},
	};
};

const handleColumnListRewards = (state: any, action: any) => {
	return { ...state, toggleColumnListRewards: action.payload };
};

const getSegmentReward = (state: any) => ({
	...state,
	fetchingSegment: true,
});

const getSegmentRewardCompleted = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: { segments = [] },
	} = payload;
	const { segmentList } = state;

	segments?.forEach((profile: SegmentsDto) => {
		profile.key = profile.id;
	});

	if (code === 200) {
		return {
			...state,
			fetchingSegment: false,
			segmentList: [...segmentList, ...segments],
			error: null,
		};
	}

	return {
		...state,
		fetchingSegment: false,
		segmentList: null,
		error: status,
	};
};

const getSegmentRewardFailed = (state: any, { payload = DEFAULT_ERROR }: { payload: string }) => ({
	...state,
	fetchingSegment: false,
	error: payload,
});

const createReward = (state: any) => ({
	...state,
	fetchingCreateReward: FETCHING,
});

const createRewardCompleted = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload;

	if (code === 200) {
		return {
			...state,
			fetchingCreateReward: SUCCESSFUL,
			error: null,
		};
	}

	return {
		...state,
		fetchingCreateReward: FAILED,

		error: status,
	};
};

const createRewardFailed = (state: any, { payload = DEFAULT_ERROR }: { payload: string }) => ({
	...state,
	fetchingCreateReward: FAILED,
	error: payload,
});

const updateReward = (state: any) => ({
	...state,
	fetchingCreateReward: FETCHING,
});

const updateRewardCompleted = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload;

	if (code === 200) {
		return {
			...state,
			fetchingCreateReward: SUCCESSFUL,
			error: null,
		};
	}

	return {
		...state,
		fetchingCreateReward: FAILED,
		error: status,
	};
};

const updateRewardFailed = (state: any, { payload = DEFAULT_ERROR }: { payload: string }) => ({
	...state,
	fetchingCreateReward: FAILED,
	error: payload,
});

const saveDraftReward = (state: any) => ({
	...state,
	fetchingSaveDraft: FETCHING,
});

const saveDraftRewardCompleted = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload;

	if (code === 200) {
		return {
			...state,
			fetchingSaveDraft: SUCCESSFUL,
			error: null,
		};
	}

	return {
		...state,
		fetchingSaveDraft: FAILED,

		error: status,
	};
};

const saveDraftRewardFailed = (state: any, { payload = DEFAULT_ERROR }: { payload: string }) => ({
	...state,
	fetchingSaveDraft: FAILED,
	error: payload,
});

const getRewards = (state: any) => ({
	...state,
	loadingReward: true,
});

const getRewardsCompleted = (state: any, { payload }: any) => {
	const {
		type,
		status: rewardStatus,
		isSetOnScroll,
		isResetList,
		response,
		maxRecords,
	} = payload;
	const {
		status: { code = 0, status = '' },
		result,
	} = response;

	const { rewardsList, resetStatus } = state;
	const rewardsListData = {
		...rewardsList,
	};

	const listType = `${rewardStatus.toLowerCase()}${type[0].toUpperCase()}${type
		.slice(1)
		.toLowerCase()}List`;

	const resetListType = `reset${type[0].toUpperCase()}${type.slice(1).toLowerCase()}List`;

	result.forEach((item: any) => {
		item.key = item.id;
	});

	if (isResetList) {
		resetStatus[`${resetListType}`] = true;
	}

	if (isSetOnScroll) {
		rewardsListData[`${listType}`] = [...rewardsListData[`${listType}`], ...result];
	} else {
		rewardsListData[`${listType}`] = result;
	}

	let listTypeEmptyReward = [...state?.listTypeEmptyReward];
	if (result?.length < maxRecords) {
		if (!listTypeEmptyReward?.some((typeReward) => typeReward === listType)) {
			listTypeEmptyReward.push(listType);
		}
	} else {
		listTypeEmptyReward = listTypeEmptyReward.filter((typeReward) => typeReward !== listType);
	}

	if (code === 200) {
		return {
			...state,
			fetchingSegment: false,
			loadingReward: false,
			rewardsList: { ...rewardsList, ...rewardsListData },
			listTypeEmptyReward,
			resetStatus,
			error: null,
		};
	}

	return {
		...state,
		fetchingSegment: false,
		loadingReward: false,
		segmentList: null,
		error: status,
	};
};

const getRewardsFailed = (state: any, { payload = DEFAULT_ERROR }: { payload: string }) => ({
	...state,
	fetchingSegment: false,
	loadingReward: false,
	error: payload,
});

const goLiveDraftReward = (state: any) => ({
	...state,
	fetchingCreateReward: FETCHING,
});

const goLiveDraftRewardCompleted = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload;

	if (code === 200) {
		return {
			...state,
			fetchingCreateReward: SUCCESSFUL,
			error: null,
		};
	}

	return {
		...state,
		fetchingCreateReward: FAILED,
		error: status,
	};
};

const goLiveDraftRewardFailed = (state: any, { payload = DEFAULT_ERROR }: { payload: string }) => ({
	...state,
	fetchingCreateReward: FAILED,
	error: payload,
});

const getRewardsHistory = (state: any) => ({
	...state,
	fetchingRewardsHistory: true,
});

const getRewardsHistoryCompleted = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: { rewardResponses, totalRecords },
	} = payload;

	rewardResponses.forEach((reward: any) => {
		reward.key = reward.id;
		reward.fullName = `${reward.creatorFirstName} ${reward.creatorLastName}`;
	});

	const searchDictionary = rewardResponses
		.map((item: any) => {
			return [
				{ label: item.creatorEmail, value: item.creatorEmail },
				{ label: item.creatorName, value: item.creatorName },
			];
		})
		.flat()
		.filter((item: any) => item.label !== null);

	if (code === 200) {
		return {
			...state,
			fetchingRewardsHistory: false,
			rewardsHistoryList: rewardResponses,
			totalRewardsHistoryRecords: totalRecords,
			searchDictionary,
			error: null,
		};
	}

	return {
		...state,
		fetchingRewardsHistory: false,
		rewardsHistoryList: null,
		error: status,
	};
};

const getRewardsHistoryFailed = (state: any, { payload = DEFAULT_ERROR }: { payload: string }) => ({
	...state,
	fetchingRewardsHistory: false,
	error: payload,
});

const getRewardByCreatorRequest = (state: RewardStore) => ({
	...state,
	getRewardByCreatorStatus: true,
});

const getRewardByCreatorSucceeded = (state: RewardStore, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: rewardByCreatorList = [],
	} = payload.data;

	if (code === 200) {
		return {
			...state,
			getRewardByCreatorStatus: false,
			rewardByCreatorList,
			error: null,
		};
	}

	return {
		...state,
		getRewardByCreatorStatus: false,
		error: status,
	};
};

const getRewardByCreatorFailed = (state: RewardStore, { payload }: Action<any>) => {
	const result = {
		...state,
		getRewardByCreatorStatus: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const getRewardByCreatorEnd = (state: any) => ({
	...state,
	getRewardByCreatorStatus: false,
});

const updateRewardStatusRequest = (state: RewardStore) => ({
	...state,
	updateRewardStatus: IN_PROGRESS,
});

const updateRewardStatusSucceeded = (state: RewardStore, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload.data;

	if (code === 200) {
		return {
			...state,
			updateRewardStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		updateRewardStatus: NUM_FAILED,
		error: status,
	};
};

const updateRewardStatusFailed = (state: RewardStore, { payload }: Action<any>) => {
	const result = {
		...state,
		updateRewardStatus: NUM_FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const updateRewardStatusEnd = (state: any) => ({
	...state,
	updateRewardStatus: NONE,
});

const logRewardRequest = (state: RewardStore) => ({
	...state,
	logRewardStatus: IN_PROGRESS,
});

const logRewardSucceeded = (state: RewardStore, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload.data;

	if (code === 200) {
		return {
			...state,
			logRewardStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		logRewardStatus: NUM_FAILED,
		error: status,
	};
};

const logRewardFailed = (state: RewardStore, { payload }: Action<any>) => {
	const result = {
		...state,
		logRewardStatus: NUM_FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const logRewardEnd = (state: any) => ({
	...state,
	logRewardStatus: NONE,
	error: null,
});

const storeRewardData = (state: RewardStore, { payload }: Action<any>) => ({
	...state,
	...payload,
});

export default handleActions<any>(
	{
		[actions.SET_INVENTORY_STATE]: setInventoryState,
		[actions.UPDATE_REWARD_SELECT]: updateRewardSelect,
		[actions.TOGGLE_COLUMN_LIST_REWARDS]: handleColumnListRewards,
		[actions.GET_SEGMENT_REWARDS]: getSegmentReward,
		[actions.GET_SEGMENT_REWARDS_COMPLETED]: getSegmentRewardCompleted,
		[actions.GET_SEGMENT_REWARDS_FAILED]: getSegmentRewardFailed,
		[actions.CREATE_REWARD]: createReward,
		[actions.CREATE_REWARD_COMPLETED]: createRewardCompleted,
		[actions.CREATE_REWARD_FAILED]: createRewardFailed,
		[actions.UPDATE_REWARD]: updateReward,
		[actions.UPDATE_REWARD_COMPLETED]: updateRewardCompleted,
		[actions.UPDATE_REWARD_FAILED]: updateRewardFailed,
		[actions.GET_REWARDS]: getRewards,
		[actions.GET_REWARDS_COMPLETED]: getRewardsCompleted,
		[actions.GET_REWARDS_FAILED]: getRewardsFailed,
		[actions.GET_REWARDS_HISTORY]: getRewardsHistory,
		[actions.GET_REWARDS_HISTORY_COMPLETED]: getRewardsHistoryCompleted,
		[actions.GET_REWARDS_HISTORY_FAILED]: getRewardsHistoryFailed,
		[actions.SAVE_DRAFT_REWARD]: saveDraftReward,
		[actions.SAVE_DRAFT_REWARD_COMPLETED]: saveDraftRewardCompleted,
		[actions.SAVE_DRAFT_REWARD_FAILED]: saveDraftRewardFailed,
		[actions.GO_LIVE_DRAFT_REWARD]: goLiveDraftReward,
		[actions.GO_LIVE_DRAFT_REWARD_COMPLETED]: goLiveDraftRewardCompleted,
		[actions.GO_LIVE_DRAFT_REWARD_FAILED]: goLiveDraftRewardFailed,

		[getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_REQUEST]: getRewardByCreatorRequest,
		[getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_SUCCEEDED]: getRewardByCreatorSucceeded,
		[getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_FAILED]: getRewardByCreatorFailed,
		[getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_END]: getRewardByCreatorEnd,

		[updateRewardStatusTypes.UPDATE_REWARD_STATUS_REQUEST]: updateRewardStatusRequest,
		[updateRewardStatusTypes.UPDATE_REWARD_STATUS_SUCCEEDED]: updateRewardStatusSucceeded,
		[updateRewardStatusTypes.UPDATE_REWARD_STATUS_FAILED]: updateRewardStatusFailed,
		[updateRewardStatusTypes.UPDATE_REWARD_STATUS_END]: updateRewardStatusEnd,

		[logRewardTypes.LOG_REWARD_REQUEST]: logRewardRequest,
		[logRewardTypes.LOG_REWARD_SUCCEEDED]: logRewardSucceeded,
		[logRewardTypes.LOG_REWARD_FAILED]: logRewardFailed,
		[logRewardTypes.LOG_REWARD_END]: logRewardEnd,

		[storeRewardDataTypes.STORE_REWARD_DATA_REQUEST]: storeRewardData,
	},
	initialState,
);
