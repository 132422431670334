import DetailWidget from '@components/Content/WidgetManager/DetailWidget';
import { SCREEN_VIEW } from '@constants/content/widgetManager';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import {
	StyledModal,
	StyledSelect,
	StyledTitle,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { notFoundContent, renderScreenView } from '@utils/renderComponent';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = StyledSelect;

type PropTypes = {
	visible: boolean;
	onCancel: () => void;
	widget: WidgetBuilderDetailDto;
	enableTerms?: boolean;
	[other: string]: any;
};

const PreviewWidgetModal = (props: PropTypes) => {
	const { visible, onCancel, widget, enableTerms = false, ...other } = props;
	const { t } = useTranslation();
	const options = renderScreenView(t);

	const [screenView, setScreenView] = useState<string>(options[0].value);

	const onChangeView = (val: any) => {
		setScreenView(val);
	};

	return (
		<StyledModal
			width={'800px'}
			centered
			visible={visible}
			closable={false}
			onCancel={onCancel}
			className="modal_custom"
			maxHeight="80vh"
			padding={'0px'}
			footer={null}
			title={
				<StyledWrapperTitle>
					<StyledTitle
						fontSize="20px"
						className="unset_height overflow_text"
						maxWidth={'calc(100% - 160px)'}>
						{widget.name}
					</StyledTitle>
					<StyledSelect
						notFoundContent={notFoundContent(t)}
						defaultValue={screenView}
						value={screenView}
						onChange={onChangeView}
						width="170px"
						placeholder={t('content.widget_manager.placeholder')}>
						{options.map((item: any) => {
							return (
								<Option key={item?.value} value={item.value}>
									{item?.label}
								</Option>
							);
						})}
					</StyledSelect>
				</StyledWrapperTitle>
			}
			{...other}>
			<DetailWidget
				detailWidget={widget}
				isMobile={screenView === SCREEN_VIEW.MOBILE}
				enableTerms={enableTerms}
			/>
		</StyledModal>
	);
};

export default PreviewWidgetModal;
