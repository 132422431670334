import {
	IconCheck,
	IconCheckCircle,
	IconExpandMore,
	IconInfo,
	IconNoteMessage,
	IconRightPending,
} from '@assets/icons';
import { STATUS_MODES } from '@constants/common';
import { APPROVAL_STATUS_LIST } from '@constants/creator';
import THEME from '@constants/themes/themes';
import { ActionsTypes } from '@models/common/summary';
import {
	StyledDropdown,
	StyledFlex,
	StyledIcon,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledTaskStatus } from '@styled/TaskManager/TaskManagerStyled';
import { Menu } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ApprovalStatusProps = {
	status: string;
	onChangeStatus: (status: string) => void;
	[key: string]: any;
};

const ApprovalStatus = (props: ApprovalStatusProps) => {
	const { t } = useTranslation();
	const { status, onChangeStatus, ...otherProps } = props;
	const [visible, setVisible] = useState<boolean>(false);

	const otherAttrs = useMemo(() => {
		const attrs = {
			color: THEME.colors.white,
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
			icon: <IconInfo />,
			text: '',
			size: 20,
		};

		switch (status.toLowerCase()) {
			case STATUS_MODES.PENDING: {
				attrs.color = THEME.colors.yellowBase;
				attrs.backgroundColor = 'rgba(251, 188, 5, 0.08)';
				attrs.icon = <IconRightPending />;
				attrs.text = t('status.pending');
				attrs.size = 20;
				break;
			}

			case STATUS_MODES.APPROVED: {
				attrs.color = THEME.colors.greenBase;
				attrs.backgroundColor = 'rgba(82, 196, 26, 0.08)';
				attrs.icon = <IconCheckCircle />;
				attrs.text = t('status.approved');
				attrs.size = 20;
				break;
			}

			case STATUS_MODES.REJECTED: {
				attrs.color = THEME.colors.red2;
				attrs.backgroundColor = 'rgba(234, 67, 53, 0.08)';
				attrs.icon = <IconNoteMessage />;
				attrs.text = t('status.rejected');
				attrs.size = 20;
				break;
			}

			default:
				break;
		}

		return { ...attrs };
	}, [status]);

	const menuActionsDropdown = () => (
		<Menu>
			{APPROVAL_STATUS_LIST.map((item: ActionsTypes) => {
				if (item?.hidden) {
					return null;
				}
				return (
					<StyledWrapperContent
						className="hover_effect"
						padding="6px 8px 6px 6px"
						cursor="pointer"
						display="flex"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setVisible(false);

							if (item.value !== status && onChangeStatus) {
								onChangeStatus(item.value);
							}
						}}>
						<span>{t(`${item.name}`)}</span>
						{item?.name && status.toLowerCase() === item?.value?.toLowerCase() && (
							<StyledIcon className="icon-check" margin="0 4px 0 0">
								<IconCheck />
							</StyledIcon>
						)}
					</StyledWrapperContent>
				);
			})}
		</Menu>
	);

	return (
		<StyledDropdown
			visible={visible}
			trigger={['click']}
			overlayClassName="dropdown_chip_option"
			overlay={menuActionsDropdown}
			onVisibleChange={(visible) => {
				setVisible(visible);
			}}
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}>
			<StyledTaskStatus
				width="100%"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
				bgColor={otherAttrs.backgroundColor}
				color={otherAttrs.color}
				{...otherProps}>
				<StyledFlex>
					<StyledIcon fillPath={otherAttrs.color} size={otherAttrs.size || 24}>
						{otherAttrs.icon}
					</StyledIcon>
					<StyledText margin="0 0 0 6px">{otherAttrs.text}</StyledText>
				</StyledFlex>
				<StyledIcon fillPath={otherAttrs.color} size={20}>
					<IconExpandMore />
				</StyledIcon>
			</StyledTaskStatus>
		</StyledDropdown>
	);
};

export default ApprovalStatus;
