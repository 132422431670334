import THEME from '@constants/themes/themes';
import { Col, Input, Row } from 'antd';
import styled from 'styled-components';

const StyledWrapperAlbumList = styled.div`
	margin: 22px 0;
	background: ${THEME.colors.darkBlue2};
	border-radius: 2px;
	height: calc(100% - 76px);
`;

const StyledAlbumList = styled.div`
	margin-bottom: 12px;
	padding: 28px 32px 0 32px;
`;

const StyledRow = styled(Row)`
	width: 100%;
	height: 100%;
	&.row_album {
		margin: 0 !important;
	}
`;

const StyledCol = styled(Col)`
	&.col_color {
		background-color: rgba(1, 22, 39, 0.5);
		&.col_right {
			border-radius: 0 4.6976px 4.6976px 0;
			padding-right: 0 !important;
		}
		&.col_left {
			border-radius: 4.6976px 0 0 4.6976px;
			padding-left: 0 !important;
		}
	}
`;

const StyledSearch = styled(Input.Search)<{ width?: string; padding?: string; margin?: string }>`
	width: ${({ width }) => width || '200px !important'};
	padding: ${({ padding }) => padding || 'unset'};
	margin: ${({ margin }) => margin || '0 8px 0 0'};
	&.search_opacity {
		.ant-input-disabled {
			opacity: 0.5;
			background-color: transparent !important;
		}
		.ant-input-group-addon {
			left: 0 !important;
		}
	}

	&,
	.ant-input,
	.ant-input-group-addon,
	.ant-input-search-button {
		height: 32px;
		background: transparent;
	}

	.ant-input {
		border-right: none;
		box-shadow: none;
		border-color: ${THEME.colors.darkBlue4};
	}

	.ant-btn {
		border-left: none;
		border-color: ${THEME.colors.darkBlue4};
		padding: 0;

		&:focus {
			border-color: none;
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.ant-btn.ant-btn-primary:focus,
	.ant-btn.ant-btn-primary:hover {
		background-color: transparent;
	}
`;

const StyledFlexAlignCenter = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

const StyledDivider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${THEME.colors.darkBlue3};
	margin: 16px 0;
`;

const StyledWrapperTooltip = styled.div`
	padding: 8px;
`;

const StyledTitleTooltip = styled.div`
	font-weight: 400;
	font-size: 20px;
	line-height: 18px;
	margin-bottom: 15px;
	color: ${THEME.colors.white};
	display: flex;
	align-items: center;
	svg {
		margin-right: 10px;
	}
`;

const StyledSubTooltip = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.white};
`;

export {
	StyledWrapperAlbumList,
	StyledRow,
	StyledAlbumList,
	StyledSearch,
	StyledCol,
	StyledFlexAlignCenter,
	StyledDivider,
	StyledWrapperTooltip,
	StyledTitleTooltip,
	StyledSubTooltip,
};
