import { FullscreenExitOutlined } from '@ant-design/icons';
// Icons
import { IconClose, IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@assets/icons';
import { ImageFallback } from '@assets/images';
import RequestRightsDM from '@components/RequestRightsDM';
import SocialFooter from '@components/SocialSearch/SocialDetails/SocialFooter';
import SocialInfo from '@components/SocialSearch/SocialDetails/SocialInfo';
import SocialMediaLoading from '@components/SocialSearch/SocialMediaLoading';
import { BLOCK_ACTIONS, KEYBOARD, UNIT_VALUE } from '@constants/common';
// Components
import { LIMIT_SIZE, MEDIA_TYPE, MOVE_CONTENT, VIEW_TYPE } from '@constants/content/contentLibrary';
import { SOCIAL, SOCIAL_PROFILE } from '@constants/social_search';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import SocialDetailContext from '@contexts/SocialSearch/SocialDetail';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { SocialDetailRef } from '@models/socialsearch/ref';
import {
	BlockSocialContentPayloadDto,
	BlockSocialUFeedUsernamePayloadDto,
	BlockSocialUsernamePayloadDto,
	ImportSocialContentPayloadDto,
	SocialContentRootStateDto,
} from '@models/socialsearch/store';
import { ImportedSocialDto, SocialContentContainerDto } from '@models/socialsearch/summary';
import { updateFlags, updateLabels } from '@stores/settings/settings.actions';
import {
	blockSocialContentRequest,
	blockSocialFeedContentRequest,
	blockSocialFeedUsernameRequest,
	blockSocialUsernameRequest,
	importSocialContentEnd,
	importSocialContentRequest,
	updateStatusSocialList,
} from '@stores/socialsearch/socialsearch.actions';
import { StyledCloseIcon, StyledSkeleton, StyledWrapperContent } from '@styled/Common/CommonStyled';
// Styled
import {
	StyledCol,
	StyledFullScreen,
	StyledIconClose,
	StyledImageContainer,
	StyledImageFull,
	StyledModal,
	StyledRow,
	StyledSlideControl,
	StyledVideoFull,
} from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	getSocialS3URL,
	handleLoadedVideoSocialError,
	handleImageLoadedError,
	handleRedirectLink,
} from '@utils/common';
import { convertSocialId, formatSocialSearchPayload } from '@utils/socialSearch';
import { Form, message } from 'antd';
import _ from 'lodash';
import React, {
	Ref,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const MEDIA_SIZE_DEFAULT = 579;

type ContentDetailsProps = {
	isVisible: boolean;
	socialContent: SocialContentContainerDto;
	handleCancel: () => void;
	isFirstContent: boolean;
	isLastContent: boolean;
	moveContent: (action: string, changeIndex: boolean) => void;
	activeSocialType: string;
	updateMediaImgUrl: (id: number, imgUrl: string, idConvert: string) => void;
	idFeed?: number | null;
	pauseNextContentAfterImport?: boolean;
	pauseNextContentAfterBlockContent?: boolean;
	socialBlockCreatorDisable?: boolean;
	socialBookmarkDisable?: boolean;
};

const SocialDetails = forwardRef((props: ContentDetailsProps, ref: Ref<SocialDetailRef>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const imgRef = useRef<HTMLImageElement | null>(null);
	const videoRef = useRef<HTMLVideoElement | null>(null);

	// Reducers
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { statusUpdateSocialList } = useSelector((state: any) => state.socialContent);
	const {
		blockSocialContentStatus,
		blockSocialUsernameStatus,
		importSocialContentStatus,
		importedSocialContent,
		blockedSocialContent,
		blockedSocialUsername,
	} = useSelector((state: SocialContentRootStateDto) => state.socialContent);

	// Props
	const {
		isVisible,
		// socialContent,
		moveContent,
		isFirstContent,
		isLastContent,
		handleCancel,
		activeSocialType,
		updateMediaImgUrl,
		idFeed,
		pauseNextContentAfterImport = false,
		pauseNextContentAfterBlockContent = false,
		socialBlockCreatorDisable,
		socialBookmarkDisable,
		socialContent,
	} = props;

	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isAdditionalLoading, setIsAdditionalLoading] = useState<boolean>(false);

	const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
	const [isMessage, setIsMessage] = useState<boolean>(false);

	const [dimension, setDimension] = useState<number>(MEDIA_SIZE_DEFAULT);
	const [confirmationType, setConfirmationType] = useState<string | null>(null);

	const [payload, setPayload] = useState<ImportedSocialDto | null>(null);
	const [loadedErrorMedia, setLoaderErrorMedia] = useState(true);

	// Hook

	useImperativeHandle(ref, () => ({
		handleMoveContent,
		handleCancelModal,
	}));

	useEffect(() => {
		if (!isVisible) {
			resetCurrentMedia();
		}
	}, [socialContent, isVisible]);

	useEffect(() => {
		return () => {
			setIsMessage(false);
		};
	}, [socialContent?.id]);

	useEffect(() => {
		if (!isVisible) {
			onClose();
		}
	}, [isVisible]);

	useEffect(() => {
		if (importSocialContentStatus === IN_PROGRESS) {
			setIsLoading(true);
		}

		if (importSocialContentStatus === FAILED || importSocialContentStatus === SUCCEEDED) {
			setIsLoading(false);
			dispatch(importSocialContentEnd());
		}
	}, [importSocialContentStatus, pauseNextContentAfterImport]);

	useEffect(() => {
		if (blockSocialContentStatus === SUCCEEDED || blockSocialContentStatus === FAILED) {
			resetState();
		}
	}, [blockSocialContentStatus, pauseNextContentAfterBlockContent]);

	useEffect(() => {
		if (blockSocialUsernameStatus === SUCCEEDED || blockSocialUsernameStatus === FAILED) {
			dispatch(updateStatusSocialList(false));
			resetState();
		}
	}, [blockSocialUsernameStatus, blockedSocialUsername, statusUpdateSocialList]);

	useEffect(() => {
		const onKeyup = (e: KeyboardEvent) => {
			const onCheckEvent = (callback: Function) => {
				const activeElement = document.activeElement;
				const inputs = ['input', 'select', 'button', 'textarea', 'video'];
				const skipEvent =
					activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;

				if (!skipEvent) {
					e.preventDefault();
					e.stopPropagation();
					e.stopImmediatePropagation();
					callback();
				}
			};
			if (e.code === KEYBOARD.SPACE.STR && videoRef.current) {
				const func = () => {
					const val = videoRef?.current?.paused || false;
					if (val) {
						videoRef?.current?.play();
					} else {
						videoRef?.current?.pause();
					}
				};
				onCheckEvent(func);
			}
			if (e.code === KEYBOARD.ARROW_LEFT.STR) {
				const func = () => {
					if (!isFirstContent) {
						handleMoveContent(MOVE_CONTENT.PREVIOUS);
					}
				};
				onCheckEvent(func);
			}
			if (e.code === KEYBOARD.ARROW_RIGHT.STR) {
				const func = () => {
					if (!isLastContent) {
						handleMoveContent(MOVE_CONTENT.NEXT);
					}
				};
				onCheckEvent(func);
			}
		};
		window.addEventListener('keyup', onKeyup);
		return () => window.removeEventListener('keyup', onKeyup);
	}, [videoRef.current, imgRef.current, socialContent.id, isFirstContent, isLastContent]);

	const resetState = () => {
		setConfirmationType(null);
		setIsLoading(false);
		setIsAdditionalLoading(false);
	};

	// Render component
	const useKeypress = (key: string, action: () => void) => {
		useEffect(() => {
			const onKeyup = (e: KeyboardEvent) => {
				if (e.key === key) action();
			};
			window.addEventListener('keyup', onKeyup);
			return () => window.removeEventListener('keyup', onKeyup);
		}, []);
	};

	useKeypress('Escape', () => {
		handleCancel(); // close modal by pressing Esc
	});

	const confirmModal = useMemo(() => {
		const result: any = {};
		let content: string = '';

		if (confirmationType === BLOCK_ACTIONS.CONTENT) {
			result.okText = t('button.block');
			result.onSubmit = () => onConfirmBlockContent();
			result.confirmLoading = isLoading;
			content = t('social_search.confirm.block_content');
			if (idFeed) {
				result.width = 550;

				result.okText = t('button.hide_from_this_feed');
				result.otherConfirmText = t('button.hide_from_all_feeds');
				result.onSubmit = () => onConfirmBlockContent();
				result.onOtherConfirm = () => onConfirmBlockContent(true);
				result.confirmOtherLoading = isLoading;
				result.confirmLoading = isLoading;
				content = t('social_aggregator.block_content');
			}
		}
		if (confirmationType === BLOCK_ACTIONS.CREATOR) {
			result.width = 500;
			result.okText = t('button.yes');
			result.otherConfirmText = t('button.block_not_delete');
			result.onSubmit = () => onConfirmBlockCreator(true);
			result.onOtherConfirm = () => onConfirmBlockCreator(false);
			result.confirmOtherLoading = isAdditionalLoading;
			result.confirmLoading = isLoading;
			content = t('content_management.confirm.block');
			if (idFeed) {
				result.width = 550;
				result.okText = t('button.block_from_this_feed');
				result.otherConfirmText = t('button.block_from_all_feeds');
				result.onSubmit = () => onConfirmBlockCreator(true);
				result.onOtherConfirm = () => onConfirmBlockCreator(true, true);
				result.confirmOtherLoading = isLoading;
				result.confirmLoading = isLoading;
				content = t('social_aggregator.block_creator');
			}
		}

		return {
			otherProps: { ...result },
			content,
		};
	}, [confirmationType, isAdditionalLoading, isLoading, idFeed]);

	const renderConfirmModal = () => {
		return (
			<ConfirmModal isOpen={confirmationType} onClose={onClose} {...confirmModal.otherProps}>
				{confirmModal.content}
			</ConfirmModal>
		);
	};

	// Functions

	const handleMoveContent = async (action: string) => {
		await resetCurrentMedia();

		await moveContent(action, true);
	};

	const moveNextContent = (triggeredAction?: string) => {
		const action = triggeredAction || MOVE_CONTENT.NEXT;
		resetCurrentMedia();
		moveContent(action, false);
	};

	const onBlockContent = () => {
		setConfirmationType(BLOCK_ACTIONS.CONTENT);
	};

	const onBlockCreator = () => {
		setConfirmationType(BLOCK_ACTIONS.CREATOR);
	};

	const onFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};

	const onViewPost = () => {
		if (!socialContent?.permalink) return;
		handleRedirectLink(socialContent?.permalink || '');
	};

	const onConfirmBlockContent = (isAll?: boolean) => {
		const blockSocialContentPayload: BlockSocialContentPayloadDto = {
			socialSource: activeSocialType,
			ids: [socialContent],
		};
		setIsLoading(true);
		if (idFeed) {
			if (!isAll) {
				blockSocialContentPayload.id = idFeed;
			}
			dispatch(
				blockSocialFeedContentRequest({
					...blockSocialContentPayload,
					socialSource: '',
				}),
			);
		} else {
			dispatch(blockSocialContentRequest(blockSocialContentPayload));
		}
	};

	const onConfirmBlockCreator = (isDeleteContent: boolean, isAll?: boolean) => {
		const blockSocialUsernamePayload: BlockSocialUsernamePayloadDto = {
			usernames: [socialContent.username],
			socialSource: activeSocialType,
			isDelete: isDeleteContent,
		};

		if (isDeleteContent) {
			setIsLoading(true);
		} else {
			setIsAdditionalLoading(true);
		}
		dispatch(updateStatusSocialList(true));
		if (idFeed) {
			const dataRequest: BlockSocialUFeedUsernamePayloadDto = {
				usernames: [
					{ source: socialContent.socialSource, username: socialContent.username },
				],
				isDelete: false,
			};
			if (!isAll) {
				dataRequest.id = idFeed;
			}
			dispatch(blockSocialFeedUsernameRequest({ ...dataRequest }));
		} else {
			dispatch(blockSocialUsernameRequest(blockSocialUsernamePayload));
		}
	};

	const onClose = () => {
		resetState();
	};

	const resetCurrentMedia = () => {
		form.resetFields();
		setDimension(MEDIA_SIZE_DEFAULT);
		setLoaderErrorMedia(true);
	};

	const handleCancelModal = async () => {
		message.destroy();
		handleCancel();
	};

	const onSaveImport = () => {
		const importSocialContentPayload: ImportSocialContentPayloadDto = {
			ids: [socialContent],
			socialSource: activeSocialType,
		};
		if (payload) {
			formatSocialSearchPayload(importSocialContentPayload, payload);
		}

		if (importSocialContentPayload.labels) {
			saveLabel(importSocialContentPayload.labels);
		}
		socialContent.flags = importSocialContentPayload.flags || [];
		setIsLoading(true);
		dispatch(importSocialContentRequest(importSocialContentPayload));
	};

	const saveFlag = (flags: string[]) => {
		let newFlags: string[] = [...flags] || [];

		clientSettings.flags.forEach((existingFlag: string) => {
			newFlags = newFlags.filter((flag) => {
				return existingFlag !== flag;
			});
		});

		if (newFlags.length > 0) {
			const payload = newFlags.concat(clientSettings.flags);
			dispatch(updateFlags(payload));
		}
	};

	const saveLabel = (labels: string[]) => {
		let newLabels: string[] = [...labels] || [];

		clientSettings.labels.forEach((existingLabel: string) => {
			newLabels = newLabels.filter((label) => {
				return existingLabel !== label;
			});
		});

		if (newLabels.length > 0) {
			const payload = newLabels.concat(clientSettings.labels);
			dispatch(updateLabels(payload));
		}
	};

	const handleRequestRights = () => {
		setIsMessage(!isMessage);
	};

	const goToSocialProfile = () => {
		const { username } = socialContent;
		let link: string = '';

		if (activeSocialType === SOCIAL.INSTAGRAM.toLocaleLowerCase()) {
			link = `${SOCIAL_PROFILE.INSTAGRAM}${username}/`;
		} else if (activeSocialType === SOCIAL.TWITTER.toLocaleLowerCase()) {
			link = `${SOCIAL_PROFILE.TWTTER}${username}`;
		} else if (activeSocialType === SOCIAL.TIKTOK.toLocaleLowerCase()) {
			link = `${SOCIAL_PROFILE.TIKTOK}${username}`;
		} else if (activeSocialType === SOCIAL.YOUTUBE.toLocaleLowerCase()) {
			link = `${SOCIAL_PROFILE.YOUTUBE}${username}`;
		}

		window.open(link, '_blank');
	};

	const handleVideoLoadedError = async (e: any, socialContent: SocialContentContainerDto) => {
		if (loadedErrorMedia) {
			setLoaderErrorMedia(false);
			const src = await handleLoadedVideoSocialError(e, socialContent);

			updateMediaImgUrl(socialContent.id, src, convertSocialId(socialContent));
		}
	};

	const contextValues = {
		dimension,
		socialContent: socialContent,
		activeSocialType,
		payload,
		setPayload,
		onSaveImport,
		onViewPost,
		onBlockContent,
		onBlockCreator,
		handleRequestRights,
		idFeed,
		socialBlockCreatorDisable,
		socialBookmarkDisable,
		pauseNextContentAfterBlockContent,
	};

	return (
		<StyledWrapperContent
			width={LIMIT_SIZE.WIDTH}
			height={LIMIT_SIZE.HEIGHT}
			maxWidth={`${LIMIT_SIZE.MAX_WIDTH}px`}
			maxHeight={`${LIMIT_SIZE.MAX_HEIGHT}px`}>
			<SocialDetailContext.Provider value={contextValues}>
				<LoadingWrapper isLoading={false}>
					<StyledRow style={{ height: '100%' }}>
						<StyledCloseIcon
							isDisabled={isMessage}
							onClick={handleCancelModal}
							right="10px"
							style={{ zIndex: 7777 }}
							top="10px">
							<IconClose />
						</StyledCloseIcon>

						<StyledCol
							padding="0"
							style={{
								maxWidth: 'calc(100% - 420px)',
								maxHeight: '100%',
								width: '100%',
								overflow: 'hidden',
							}}>
							<StyledImageContainer
								style={{
									padding: '24px',
									justifyContent: 'center',
									width: '100%',
								}}>
								{!_.isEmpty(socialContent) && (
									<SocialMediaLoading
										imgRef={imgRef}
										loadSkeleton
										setLoading={setIsLoading}
										setDimension={setDimension}
										socialContent={socialContent}
										updateMediaUrl={(val: string) => {
											updateMediaImgUrl(
												socialContent.id,
												val,
												convertSocialId(socialContent),
											);
										}}
										videoRef={videoRef}
									/>
								)}
							</StyledImageContainer>
						</StyledCol>

						<StyledCol
							padding="0 24px"
							width="420px"
							style={{ position: 'relative', height: '100%' }}>
							<StyledSkeleton
								active
								className={`skeleton_show ${isLoading ? '' : 'hidden'}`}
								paragraph={{ rows: UNIT_VALUE.S_2 }}
							/>
							<StyledWrapperContent
								height="100%"
								width="100%"
								className={isLoading ? 'hidden' : ''}>
								{!isMessage ? (
									<React.Fragment>
										<SocialInfo />
										<SocialFooter />
									</React.Fragment>
								) : (
									<RequestRightsDM
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										goToSocialProfile={goToSocialProfile}
										handleRequestRights={handleRequestRights}
										currentContent={socialContent}
										socialType={activeSocialType}
										getSelectId={false}
									/>
								)}
							</StyledWrapperContent>
						</StyledCol>
					</StyledRow>

					{confirmationType && renderConfirmModal()}

					{isFullScreen && (
						<StyledFullScreen
							centered={true}
							closable={false}
							visible={isFullScreen}
							transitionName="none"
							onCancel={() => onFullScreen()}
							footer={false}>
							<StyledIconClose
								border="none"
								position="fixed"
								right={true}
								onClick={onFullScreen}>
								<FullscreenExitOutlined className="fullscreen-exit-icon" />
							</StyledIconClose>

							{socialContent?.mediaType === MEDIA_TYPE.VIDEO ? (
								<StyledVideoFull height="100%" width="100%">
									<video
										src={getSocialS3URL(socialContent?.imgUrl)}
										autoPlay
										controls
										onError={(e) => handleVideoLoadedError(e, socialContent)}>
										<track kind="captions" />
									</video>
								</StyledVideoFull>
							) : (
								<StyledImageFull
									preview={false}
									fallback={ImageFallback}
									placeholder={<LoadingWrapper isLoading={true} />}
									src={getSocialS3URL(socialContent?.imgUrl)}
									onError={(e: any) =>
										handleImageLoadedError(e, socialContent?.imgUrl)
									}
								/>
							)}
						</StyledFullScreen>
					)}
				</LoadingWrapper>
			</SocialDetailContext.Provider>
		</StyledWrapperContent>
	);
});

export default SocialDetails;
