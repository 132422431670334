import { COL_MEDIA, DEFAULT_PARAM_SOCIAL_ACTIVITY } from '@constants/ambassador';
import { ParamPostActivityTypes } from '@models/ambassador';
import { ContentContainerDto } from '@models/content/contentLibrary/summary';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import React from 'react';

export type SocialActivityManagerContextType = {
	socialList: SocialContentContainerDto[];
	setSocialList: (val: SocialContentContainerDto[]) => void;
	col: number;
	setCol: (val: number) => void;
	isFiltering: boolean;
	handleToggleFilter: () => void;
	resetFilter: () => void;
	openFilter: boolean;
	paramSocial: ParamPostActivityTypes;
	setParamSocial: (val: ParamPostActivityTypes) => void;
	setThumbFull: (val: boolean) => void;
	thumbFull: boolean;
	isSubmission: boolean;
	setIsSubmission: (val: boolean) => void;
	contentList: ContentContainerDto[];
	setContentList: (val: ContentContainerDto[]) => void;
	handleChangeTab: (val: boolean) => void;
};

// Default context values
const values: SocialActivityManagerContextType = {
	socialList: [],
	setSocialList: () => {},
	col: COL_MEDIA.C2,
	setCol: () => {},
	isFiltering: false,
	handleToggleFilter: () => {},
	resetFilter: () => {},
	openFilter: false,
	paramSocial: { ...DEFAULT_PARAM_SOCIAL_ACTIVITY },
	setParamSocial: () => {},
	setThumbFull: () => {},
	thumbFull: false,
	isSubmission: true,
	setIsSubmission: () => {},
	contentList: [],
	setContentList: () => {},
	handleChangeTab: () => {},
};

const SocialActivityManagerContext = React.createContext(values);
export default SocialActivityManagerContext;
