import { FieldFormSettingFeed } from '@models/settings/socialSearch/socialAggregator/summary';

const NAME_SETTING_FEED = {
	SEARCH_TERMS: 'searchTerms',
	SOURCES: 'sources',
	MEDIA_TYPE: 'mediaType',
	INCLUDED_TERMS: 'includedTerms',
	EXCLUDE_TERMS: 'excludedKeywords',
	CAPTION_SENTIMENT: 'captionSentiments',
	LANGUAGE: 'languages',
	CREATORS: 'creators',
	USERNAME_CONJUNCTION_TYPE: 'usernameConjunctionType',
	INCLUDE_POSTS_NOT_ANALYZED: 'analysisLanguage',
	POST_DATE: 'postDate',
};

const OPTION_ALL = 'ALL';

const SOURCE_TYPE = {
	ALL: OPTION_ALL,
	INSTAGRAM: 'INSTAGRAM',
	TIKTOK: 'TIKTOK',
	TWITTER: 'TWITTER',
	YOUTUBE: 'YOUTUBE',
};

const STATUS_SETTING = {
	LIVE: 'LIVE',
	DISABLED: 'DISABLED',
	DELETED: 'DELETED',
};

const LIST_SOURCE = [
	{
		value: OPTION_ALL,
		name: 'social_aggregator.all',
	},
	{
		value: SOURCE_TYPE.YOUTUBE,
		name: 'button.youtube',
	},
	{
		value: SOURCE_TYPE.INSTAGRAM,
		name: 'filter.filter_bookmark.instagram',
	},
	{
		value: SOURCE_TYPE.TWITTER,
		name: 'filter.filter_bookmark.twitter',
	},
	{
		value: SOURCE_TYPE.TIKTOK,
		name: 'button.tiktok',
	},
];

const CAPTION_SENTIMENT_TYPE = {
	ANY: OPTION_ALL,
	POSITIVE: 'POSITIVE',
	NEUTRAL: 'NEUTRAL',
	MIXED: 'MIXED',
	NEGATIVE: 'NEGATIVE',
	UNDETERMINED: 'UNDETERMINED',
};

const LIST_CAPTION_SENTIMENT = [
	{
		value: OPTION_ALL,
		name: 'filter.filter_content.any',
	},
	{
		value: CAPTION_SENTIMENT_TYPE.POSITIVE,
		name: 'social_aggregator.positive',
	},
	{
		value: CAPTION_SENTIMENT_TYPE.NEUTRAL,
		name: 'social_aggregator.neutral',
	},
	{
		value: CAPTION_SENTIMENT_TYPE.MIXED,
		name: 'social_aggregator.mixed',
	},
	{
		value: CAPTION_SENTIMENT_TYPE.NEGATIVE,
		name: 'social_aggregator.negative',
	},
	{
		value: CAPTION_SENTIMENT_TYPE.UNDETERMINED,
		name: 'social_aggregator.undetermined', // Changed from Blank to Undetermined EP-4582
	},
];

const INCLUDE_POSTS_LANGUAGE = [
	{
		value: true,
		name: 'social_aggregator.sub_form.include_posts_not_analyzed',
	},
];

const MEDIA_TYPE_SETTING = {
	ALL: 'ALL',
	VIDEO: 'VIDEO',
	IMAGE: 'IMAGE',
};

const CREATOR_TYPE_SETTING = {
	ALL: 'ALL',
	INCLUDE: 'INCLUDE',
	EXCLUDE: 'EXCLUDE',
};

const DEFAULT_FORM_SETTING: FieldFormSettingFeed & any = {
	[NAME_SETTING_FEED.SEARCH_TERMS]: '',
	[NAME_SETTING_FEED.SOURCES]: [...LIST_SOURCE.map((item) => item.value)],
	[NAME_SETTING_FEED.MEDIA_TYPE]: MEDIA_TYPE_SETTING.ALL,
	[NAME_SETTING_FEED.INCLUDED_TERMS]: [],
	[NAME_SETTING_FEED.EXCLUDE_TERMS]: [],
	[NAME_SETTING_FEED.CAPTION_SENTIMENT]: [...LIST_CAPTION_SENTIMENT.map((item) => item.value)],
	[NAME_SETTING_FEED.LANGUAGE]: [],
	[NAME_SETTING_FEED.USERNAME_CONJUNCTION_TYPE]: CREATOR_TYPE_SETTING.ALL,
	[NAME_SETTING_FEED.INCLUDE_POSTS_NOT_ANALYZED]: true,
};

const TAB_SOCIAL_SETTING = {
	POSTS_ANALYZED: 'POSTS_ANALYZED',
	SOCIAL_FEEDS: 'SOCIAL_FEEDS',
	UNIVERSAL_BLOCK_LISTS: 'UNIVERSAL_BLOCK_LISTS',
};

const LIST_TAB_SOCIAL = [
	{
		value: TAB_SOCIAL_SETTING.POSTS_ANALYZED,
		name: 'feeds.posts_analyzed',
	},
	{
		value: TAB_SOCIAL_SETTING.SOCIAL_FEEDS,
		name: 'social_aggregator.configure_social_feeds',
	},
	{
		value: TAB_SOCIAL_SETTING.UNIVERSAL_BLOCK_LISTS,
		name: 'social_aggregator.universal_block_lists',
	},
];

const LIST_MEDIA_TYPE = [
	{
		value: MEDIA_TYPE_SETTING.ALL,
		name: 'filter.filter_content.all',
	},
	{
		value: MEDIA_TYPE_SETTING.IMAGE,
		name: 'filter.filter_content.photo',
	},
	{
		value: MEDIA_TYPE_SETTING.VIDEO,
		name: 'filter.filter_content.video',
	},
];

const LIST_OPTION_CREATOR = [
	{
		value: CREATOR_TYPE_SETTING.ALL,
		name: 'social_aggregator.allow_all_creators',
	},
	{
		value: CREATOR_TYPE_SETTING.INCLUDE,
		name: 'social_aggregator.only_these_creators',
	},
	{
		value: CREATOR_TYPE_SETTING.EXCLUDE,
		name: 'social_aggregator.exclude_these_creators',
	},
];

const ACTION_TYPE_ON_FILTER = {
	FILTER_FEED: 'FILTER_FEED',
	UPDATE_FEED: 'UPDATE_FEED',
};

export {
	NAME_SETTING_FEED,
	STATUS_SETTING,
	LIST_TAB_SOCIAL,
	TAB_SOCIAL_SETTING,
	SOURCE_TYPE,
	LIST_SOURCE,
	LIST_MEDIA_TYPE,
	LIST_CAPTION_SENTIMENT,
	CAPTION_SENTIMENT_TYPE,
	OPTION_ALL,
	DEFAULT_FORM_SETTING,
	LIST_OPTION_CREATOR,
	CREATOR_TYPE_SETTING,
	INCLUDE_POSTS_LANGUAGE,
	ACTION_TYPE_ON_FILTER,
};
