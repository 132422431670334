import { LIST_INTEGRATIONS_TAB } from '@constants/settings/integrations';
import SettingIntegrationsContext from '@contexts/Settings/SettingIntegrations';
import {
	StyledTabSetting,
	StyledWrapperTabSetting,
} from '@styled/Settings/SettingAggregatorStyled';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const IntegrationsTab = () => {
	const { t } = useTranslation();
	const { setActiveTab, activeTab } = useContext(SettingIntegrationsContext);

	const listTab = useMemo(() => {
		return LIST_INTEGRATIONS_TAB.map((item) => ({
			...item,
			isActive: item.value === activeTab,
		}));
	}, [LIST_INTEGRATIONS_TAB, activeTab]);

	return (
		<StyledWrapperTabSetting>
			{listTab.map((item) => (
				<StyledTabSetting
					onClick={() => setActiveTab(item.value)}
					className={item.isActive ? 'active' : ''}
					key={item.value}>
					{t(item.name)}
				</StyledTabSetting>
			))}
		</StyledWrapperTabSetting>
	);
};

export default IntegrationsTab;
