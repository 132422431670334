import { Action } from 'redux-actions';
import { takeLatest, put } from 'redux-saga/effects';

import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import { SOCIAL } from '@constants/social_search';
import genericApiSaga from '@helpers/genericApiSaga';
import {
	socialSearchTypes,
	contentSettingsTypes,
	publishSettingsTypes,
	accountUserTypes,
	removeFolderTypes,
	replaceBlockUsernameType,
	replaceBlockTermsType,
} from './settings.types';
import {
	FETCH_SOCIAL_BLOCKED_USERNAMES,
	FETCH_BAD_WORDS,
	GET_CONTENT_SETTINGS,
	UPDATE_CONTENT_VALUES,
	UPDATE_LABELS,
	UPDATE_FLAGS,
	GET_CHANELS_SETTINGS,
	GET_CALENDAR_SETTINGS,
	CREATE_FOLDER,
	FOLDER_SETTINGS,
	ACCOUNT_USERS,
	STATUSCODE,
	REVOKE_ACCOUNT_USERS,
	UPDATE_PROFILE_USER,
	CLIENT_SETTINGS_HASH_TAGS,
	CLIENT_SETTINGS_MENTION_USERNAMES,
	API_REPLACE_BLOCK_USERNAME,
	SOCIAL_FEED_API,
	SOCIAL_FEED_STATS,
	IMPORT_USERNAME_SOCIAL_FEED_API,
	API_REPLACE_BLOCK_TERMS,
} from '@constants/APIs';
import * as actions from './settings.actions';
import { BlockedUsernamesMODE, ParamUpdateHashtagType } from '@models/settings/socialSearch';
import { ResponsePayloadDto } from '@models/common/store';
import { AccountPayloadDto } from '@models/settings/socialSearch/accountuser.ts/store';
import { clientSettingsRequest } from '@stores/actions';
import { ParamRequestListBasic } from '@models/common/summary';
import {
	ParamDataRequestFeed,
	ParamUpdateRequestFeed,
} from '@models/settings/socialSearch/socialAggregator/summary';
import { generalMessage } from '@utils/renderComponent';
import { DEFAULT_ERROR } from '@constants/errors';
import { DURATION, MESSAGE_TYPE } from '@constants/common';

export function* fetchHashTagRequest() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${CLIENT_SETTINGS_HASH_TAGS}`),
		*completed(response: any) {
			yield put(actions.fetchHashTagSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchHashTagFailed(response));
		},
	});
}

export function* updateSubHashTagRequest({ payload }: Action<ParamUpdateHashtagType>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(CLIENT_SETTINGS_HASH_TAGS, payload),
		*completed(response: any) {
			yield put(actions.fetchHashTagSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchHashTagFailed(response));
		},
	});
}

export function* fetchInActiveUsernameRequest({ payload }: any) {
	const url = `${CLIENT_SETTINGS_MENTION_USERNAMES(payload)}`;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.fetchInActiveUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchInActiveUsernameFailed(response));
		},
	});
}

export function* updateInActiveUsernameRequest({ payload }: any) {
	const { type = 'instagram', inactiveUsernames = [], usernames = [] } = payload;
	const url = `${CLIENT_SETTINGS_MENTION_USERNAMES(type)}`;
	yield genericApiSaga({
		gatewayCall: () =>
			putRequest(url, { inactiveUsernames: inactiveUsernames, usernames: usernames }),
		*completed(response: any) {
			yield put(actions.fetchInActiveUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchInActiveUsernameFailed(response));
		},
	});
}

export function* fetchSocialUsernameRequest({ payload }: Action<{ type: string }>) {
	const { type = SOCIAL.INSTAGRAM } = payload;

	yield genericApiSaga({
		gatewayCall: () => getRequest(`${CLIENT_SETTINGS_MENTION_USERNAMES(type)}`),
		*completed(response: any) {
			yield put(actions.fetchSocialUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchSocialUsernameFailed(response));
		},
	});
}

export function* modifySocialUsernameRequest({
	payload,
}: Action<{ type: string; usernames: string[]; inactiveUsernames: string[] }>) {
	const { type = SOCIAL.INSTAGRAM, usernames = [], inactiveUsernames = [] } = payload;
	const url = `${CLIENT_SETTINGS_MENTION_USERNAMES(type)}`;

	yield genericApiSaga({
		gatewayCall: () =>
			putRequest(url, { usernames: usernames, inactiveUsernames: inactiveUsernames }),
		*completed(response: any) {
			yield put(actions.fetchSocialUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchSocialUsernameFailed(response));
		},
	});
}

export function* fetchSocialBlockedUsernameRequest() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${FETCH_SOCIAL_BLOCKED_USERNAMES}`),
		*completed(response: any) {
			yield put(actions.fetchSocialBlockedUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchSocialBlockedUsernameFailed(response));
		},
	});
}

export function* modifySocialBlockedUsernameRequest({
	payload,
}: Action<{
	usernames: string[];
}>) {
	const { usernames = [] } = payload;

	yield genericApiSaga({
		gatewayCall: () => {
			return putRequest(`${FETCH_SOCIAL_BLOCKED_USERNAMES}`, usernames);
		},
		*completed(response: any) {
			yield put(actions.fetchSocialBlockedUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchSocialBlockedUsernameFailed(response));
		},
	});
}

export function* fetchBadWordsRequest() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${FETCH_BAD_WORDS}`),
		*completed(response: any) {
			yield put(actions.fetchBadWordsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchBadWordsFailed(response));
		},
	});
}

export function* fetchListFeed({ payload }: Action<ParamRequestListBasic>) {
	const { isActive, ...other } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(SOCIAL_FEED_API, other),
		*completed(response: any) {
			yield put(
				actions.fetchListFeedSucceeded({
					...response,
					data: { ...response.data, isActiveFeed: isActive },
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.fetchListFeedFailed(response));
		},
	});
}

export function* createFeed({ payload }: Action<ParamDataRequestFeed>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(SOCIAL_FEED_API, payload),
		*completed(response: any) {
			yield put(actions.createFeedSucceeded(response));
		},
		*failed(response: any) {
			generalMessage(response.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR, {
				duration: DURATION.S_3,
			});
			yield put(actions.createFeedFailed(response));
		},
	});
}

export function* updateFeed({ payload }: Action<ParamUpdateRequestFeed>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(SOCIAL_FEED_API, payload),
		*completed(response: any) {
			yield put(actions.updateFeedSucceeded(response));
		},
		*failed(response: any) {
			generalMessage(response.data.error || DEFAULT_ERROR, MESSAGE_TYPE.ERROR, {
				duration: DURATION.S_3,
			});

			yield put(actions.updateFeedFailed(response));
		},
	});
}

export function* importUsernameFeed({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(IMPORT_USERNAME_SOCIAL_FEED_API, payload),
		*completed(response: any) {
			yield put(actions.importUsernameFeedSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.importUsernameFeedFailed(response));
		},
	});
}

export function* fetchStatsSettingFeed({
	payload,
}: Action<{ fromDate?: number; toDate?: number }>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(SOCIAL_FEED_STATS, payload),
		*completed(response: any) {
			yield put(actions.fetchStatsSettingFeedSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchStatsSettingFeedFailed(response));
		},
	});
}

export function* getContentSettings() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${GET_CONTENT_SETTINGS}`),
		*completed(response: any) {
			yield put(actions.getContentSettingsSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.getContentSettingsFailed(response.data));
		},
	});
}

export function* updateContentValues({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${UPDATE_CONTENT_VALUES}`, payload),
		*completed(response: any) {
			yield put(actions.updateContentValuesSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.updateContentValuesFailed(response.data));
		},
	});
}

export function* updateFlags({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${UPDATE_FLAGS}`, payload),
		*completed(response: any) {
			yield put(actions.updateFlagsSucceeded(response.data));
			yield put(clientSettingsRequest());
		},
		*failed(response: any) {
			yield put(actions.updateFlagsFailed(response.data));
		},
	});
}

export function* updateLabels({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${UPDATE_LABELS}`, payload),
		*completed(response: any) {
			yield put(actions.updateLabelsSucceeded(response.data));
			yield put(clientSettingsRequest());
		},
		*failed(response: any) {
			yield put(actions.updateLabelsFailed(response.data));
		},
	});
}

export function* createFolder({ payload }: any) {
	const createGallery = payload.makeItGallery ? '&makeItGallery=true' : '';
	const url = `${CREATE_FOLDER}?folderName=${payload.folderName}${createGallery}`;
	yield genericApiSaga({
		gatewayCall: () => postRequest(url),
		*completed(response: any) {
			yield put(actions.createFolderSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.createFolderFailed(response.data));
		},
	});
}

export function* deleteFolder({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(CREATE_FOLDER, payload),
		*completed(response: any) {
			yield put(actions.deleteFolderSucceeded({ ...response.data, id: payload[0] }));
		},
		*failed(response: any) {
			yield put(actions.deleteFolderFailed(response.data));
		},
	});
}

export function* updateFolder({ payload }: any) {
	const { folderName, folderId, isCustomGallery } = payload;
	const url = `${CREATE_FOLDER}?folderId=${folderId}&folderName=${folderName}&isCustomGallery=${isCustomGallery}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(actions.updateFolderSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.updateFolderFailed(response.data));
		},
	});
}

export function* modifyBadWords({ payload }: Action<string[]>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(FETCH_BAD_WORDS, payload),
		*completed(response: any) {
			yield put(actions.fetchBadWordsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchBadWordsFailed(response));
		},
	});
}

export function* fetchChannelsRequest() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(GET_CHANELS_SETTINGS),
		*completed(response: any) {
			yield put(actions.fetchChannelsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchChannelsFailed(response));
		},
	});
}

export function* modifyChannel({ payload }: Action<{ channels: string }>) {
	const url = `${GET_CHANELS_SETTINGS}?channels=${payload.channels}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(actions.fetchChannelsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchChannelsFailed(response));
		},
	});
}

export function* fetchCalendarRequest() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(GET_CALENDAR_SETTINGS),
		*completed(response: any) {
			yield put(actions.fetchCalendarSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchCalendarFailed(response));
		},
	});
}

export function* modifyCalendar({ payload }: Action<{ calendars: string }>) {
	const url = `${GET_CALENDAR_SETTINGS}?calendars=${payload.calendars}`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(actions.fetchCalendarSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchCalendarFailed(response));
		},
	});
}

export function* getFolderSettings() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${FOLDER_SETTINGS}`),
		*completed(response: any) {
			yield put(actions.getFolderSettingsSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.getFolderSettingsFailed(response.data));
		},
	});
}

export function* updateAccountUser({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${UPDATE_PROFILE_USER}`, payload),
		*completed(response: any) {
			yield put(actions.updateAccountUserSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.updateAccountUserFailed(response.data));
		},
	});
}

export function* getAccountList({ payload }: Action<AccountPayloadDto>) {
	const { clientId, page, maxRecords, keyword } = payload;
	let url = `${ACCOUNT_USERS}?clientId=${clientId}&page=${page}&maxRecords=${maxRecords}`;

	if ('keyword' in payload) {
		url += `&keyword=${keyword}`;
	}

	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: ResponsePayloadDto) {
			yield put(actions.getAccountListSucceeded(response));
		},
		*failed(response: ResponsePayloadDto) {
			yield put(actions.getAccountListFailed(response));
		},
	});
}

export function* deleteAccountUser({ payload }: Action<any>) {
	const { params, onDeleteSucceeded, onDeleteFailed } = payload;

	yield genericApiSaga({
		gatewayCall: () => putRequest(REVOKE_ACCOUNT_USERS, params),
		*completed(response: ResponsePayloadDto) {
			if (response) {
				const {
					status: { code = 0 },
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					onDeleteSucceeded();
				}
			}
		},
		*failed(response: ResponsePayloadDto) {
			if (response) {
				const {
					status: { code = 0, status = '' },
				} = response.data;
				let { error = '' } = response.data;

				error = error === null ? `${code} ${status}` : error;
				onDeleteFailed(error);
			}
		},
	});
}

export function* inviteUser({ payload }: Action<any>) {
	const { params, onInviteSucceeded, onInviteFailed } = payload;

	yield genericApiSaga({
		gatewayCall: () => postRequest(`${ACCOUNT_USERS}invite`, [params]),
		*completed(response: ResponsePayloadDto) {
			if (response) {
				const {
					status: { code = 0 },
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					onInviteSucceeded(response, params);
				}
			}
		},
		*failed(response: ResponsePayloadDto) {
			if (response) {
				const {
					status: { code = 0, status = '' },
				} = response.data;
				let { error = '' } = response.data;

				error = error === null ? `${code} ${status}` : error;
				onInviteFailed(error);
			}
		},
	});
}

export function* removeFolder({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(CREATE_FOLDER, payload),
		*completed(response: any) {
			yield put(actions.removeFolderSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.removeFolderFailed(response));
		},
	});
}

export function* replaceBlockUsername({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(API_REPLACE_BLOCK_USERNAME, payload),
		*completed(response: any) {
			yield put(actions.replaceBlockUsernameSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.replaceBlockUsernameFailed(response));
		},
	});
}

export function* replaceBlockTerms({ payload }: any) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(API_REPLACE_BLOCK_TERMS, payload),
		*completed(response: any) {
			yield put(actions.replaceBlockTermsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.replaceBlockTermsFailed(response));
		},
	});
}

export function* watcherSettingSocialSearchSaga() {
	yield takeLatest(socialSearchTypes.GET_HASHTAG, fetchHashTagRequest);
	yield takeLatest(socialSearchTypes.UPDATE_SUB_HASHTAG, updateSubHashTagRequest);
	yield takeLatest(socialSearchTypes.GET_SOCIAL_USERNAME, fetchSocialUsernameRequest);
	yield takeLatest(socialSearchTypes.FETCH_INACTIVE_USERNAME, fetchInActiveUsernameRequest);
	yield takeLatest(socialSearchTypes.UPDATE_INACTIVE_USERNAME, updateInActiveUsernameRequest);
	yield takeLatest(socialSearchTypes.MODIFY_SOCIAL_USERNAME, modifySocialUsernameRequest);
	yield takeLatest(
		socialSearchTypes.GET_SOCIAL_BLOCK_USERNAME,
		fetchSocialBlockedUsernameRequest,
	);
	yield takeLatest(
		socialSearchTypes.MODIFY_SOCIAL_BLOCK_USERNAME,
		modifySocialBlockedUsernameRequest,
	);
	yield takeLatest(socialSearchTypes.MODIFY_BAD_WORDS, modifyBadWords);
	yield takeLatest(socialSearchTypes.GET_BAD_WORDS, fetchBadWordsRequest);
	yield takeLatest(socialSearchTypes.GET_LIST_FEED_REQUEST, fetchListFeed);
	yield takeLatest(socialSearchTypes.CREATE_FEED_REQUEST, createFeed);
	yield takeLatest(socialSearchTypes.UPDATE_FEED_REQUEST, updateFeed);
	yield takeLatest(socialSearchTypes.IMPORT_USERNAME_FEED_REQUEST, importUsernameFeed);
	yield takeLatest(socialSearchTypes.FETCH_STATS_SETTING_FEED_REQUEST, fetchStatsSettingFeed);

	yield takeLatest(contentSettingsTypes.GET_CONTENT_SETTINGS, getContentSettings);
	yield takeLatest(contentSettingsTypes.UPDATE_CONTENT_VALUES, updateContentValues);
	yield takeLatest(contentSettingsTypes.UPDATE_FLAGS, updateFlags);
	yield takeLatest(contentSettingsTypes.UPDATE_LABELS, updateLabels);
	yield takeLatest(contentSettingsTypes.CREATE_FOLDER, createFolder);
	yield takeLatest(contentSettingsTypes.DELETE_FOLDER, deleteFolder);
	yield takeLatest(contentSettingsTypes.UPDATE_FOLDER, updateFolder);
	yield takeLatest(publishSettingsTypes.GET_CHANNELS_SETTINGS, fetchChannelsRequest);
	yield takeLatest(publishSettingsTypes.MODIFY_CHANNELS_SETTINGS, modifyChannel);
	yield takeLatest(publishSettingsTypes.GET_CALENDAR_SETTINGS, fetchCalendarRequest);
	yield takeLatest(publishSettingsTypes.MODIFY_CALENDAR_SETTINGS, modifyCalendar);
	yield takeLatest(contentSettingsTypes.GET_FOLDER_SETTINGS, getFolderSettings);
	yield takeLatest(accountUserTypes.GET_ACCOUNT_LIST, getAccountList);
	yield takeLatest(accountUserTypes.DELETE_ACCOUNT_USER, deleteAccountUser);
	yield takeLatest(accountUserTypes.INVITE_USER, inviteUser);
	yield takeLatest(accountUserTypes.UPDATE_ACCOUNT_USER, updateAccountUser);

	yield takeLatest(removeFolderTypes.REMOVE_FOLDER_REQUEST, removeFolder);
	yield takeLatest(replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_REQUEST, replaceBlockUsername);
	yield takeLatest(replaceBlockTermsType.REPLACE_BLOCK_TERMS_REQUEST, replaceBlockTerms);
}
