import { Collapse } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Styled
import {
	StyledCollapse,
	StyledDate,
	StyledEventWrapper,
	StyledFlex,
	StyledText,
	StyledTitle,
} from '@styled/Messages/MessageLogStyled';

// Icons

// Components
import SegmentList from '@components/Messages/MessageLog/Logs/SegmentList';

// Context
import { TYPE_FORMAT_DATE } from '@constants/common';
import { EMAIL_LOG } from '@constants/messages';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { MessagesRootStateDto } from '@models/messages/store';
import { getEmailEventDetail } from '@stores/actions';
import { useTranslation } from 'react-i18next';

type EmailProps = {
	data: any;
};

const { Panel } = Collapse;

const Event = (props: EmailProps) => {
	const dispatch = useDispatch();
	const { eventId }: MessagesRootStateDto = useSelector((state: any) => state.messages);
	const { isFailedFilterEmailLog = false } = useSelector((state: any) => state.messages);

	const { t } = useTranslation();
	const { data = {} } = props;
	const { id = 0, subject = '', updateAt = 0 } = data;

	useEffect(() => {
		if (eventId && eventId === id) {
			handleExpand();
		}
	}, [eventId]);

	const renderHeader = () => {
		return (
			<StyledFlex padding="12px 45px 12px 14px" justifyContent="flex-start">
				<StyledTitle>
					<StyledText weight={800}>
						{subject || `(${t('email_log.logs.no_subject')})`}
					</StyledText>
				</StyledTitle>
				<StyledDate>
					{convertUtcToLocalTimeWithFormat(updateAt, TYPE_FORMAT_DATE.TIME) || ''}
				</StyledDate>
			</StyledFlex>
		);
	};

	const handleExpand = () => {
		const payload: any = { id, pageRecords: EMAIL_LOG.DEFAULT_PAGE_RECORD };
		if (isFailedFilterEmailLog) {
			payload.isFailed = isFailedFilterEmailLog;
		}
		dispatch(getEmailEventDetail(payload));
	};

	const onToggleCollapse = (val: any) => {
		if (val) {
			handleExpand();
		}
	};

	return (
		<StyledEventWrapper>
			<StyledCollapse
				onChange={onToggleCollapse}
				key={'event'}
				accordion
				expandIconPosition="right"
				isEvent={true}
				destroyInactivePanel={true}
				defaultActiveKey={eventId && eventId === id ? id : null}>
				<Panel header={renderHeader()} key={id}>
					<SegmentList data={data} />
				</Panel>
			</StyledCollapse>
		</StyledEventWrapper>
	);
};

export default Event;
