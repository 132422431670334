import GuestPortalActionBar from '@components/Settings/GuestPortal/GuestPortalActionBar';
import GuestPortalDetailModal from '@components/Settings/GuestPortal/GuestPortalDetailModal';
import GuestPortalList from '@components/Settings/GuestPortal/GuestPortalList';
import GuestPortalShareModal from '@components/Settings/GuestPortal/GuestPortalShareModal';
import { DURATION, PARAM_ALL, STATUS_MODES } from '@constants/common';
import { Paginator } from '@constants/paginator';
import {
	DEFAULT_PAYLOAD_GETTING_LIST,
	GUEST_PORTAL_MODAL_TYPE,
} from '@constants/settings/guestPortal';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import GuestPortalContext from '@contexts/Settings/GuestPortal';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { getUser } from '@helpers/userHelpers';
import { ParamRequestListBasic } from '@models/common/summary';
import { GuestPortalItemDto, GuestPortalStoreDto } from '@models/settings/guestPortal';
import {
	createGuestPortalEnd,
	deleteGuestPortalEnd,
	deleteGuestPortalRequest,
	getAlbumListLiteRequest,
	getGuestPortalListRequest,
	storeDataGuestPortalRequest,
	updateGuestPortalEnd,
	updateGuestPortalRequest,
} from '@stores/actions';
import { StyledLinkHref, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { onCopyValue } from '@utils/funcHelper';
import { message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const GuestPortal = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		createGuestPortalStatus,
		updateGuestPortalStatus,
		getGuestPortalListStatus,
		updatedGuestPortal,
		deleteGuestPortalStatus,
		guestPortalError,
	}: GuestPortalStoreDto = useSelector((state: any) => state.guestPortal);
	const guestPortalDetailModalRef = useRef<any>();
	const user = getUser();

	const [guestPortalDetail, setGuestPortalDetail] = useState<GuestPortalItemDto | null>(null);
	const [modalType, setModalType] = useState<string>('');
	const [confirmationModalType, setConfirmationModalType] = useState<string>('');
	const [selectedGuestPortals, setSelectedGuestPortals] = useState<GuestPortalItemDto[]>([]);
	const [requestPayload, setRequestPayload] = useState<ParamRequestListBasic>({
		...DEFAULT_PAYLOAD_GETTING_LIST,
	});

	useEffect(() => {
		dispatch(
			getAlbumListLiteRequest({
				clientId: user.clientId,
				params: {
					...PARAM_ALL,
				},
			}),
		);
		dispatch(
			storeDataGuestPortalRequest({
				guestPortalResult: null,
			}),
		);
	}, []);

	useEffect(() => {
		if (createGuestPortalStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.created'),
				}),
			);
			setRequestPayload({ ...requestPayload, page: Paginator.defaultPage });
			resetData(true);
			setModalType('');
		}

		if (createGuestPortalStatus === SUCCEEDED || createGuestPortalStatus === FAILED) {
			dispatch(createGuestPortalEnd());
		}
	}, [createGuestPortalStatus]);

	useEffect(() => {
		if (updateGuestPortalStatus === SUCCEEDED && updatedGuestPortal) {
			message.success(
				t('message.action_success', {
					action: t('action.updated'),
				}),
			);
			if (modalType === GUEST_PORTAL_MODAL_TYPE.ACTION_ON_GUEST) {
				if (!updatedGuestPortal.isUpdatedStatus) {
					setModalType('');
				}
				delete updatedGuestPortal.isUpdatedStatus;
				setGuestPortalDetail(updatedGuestPortal);
			}
			resetData();
		}

		if (updateGuestPortalStatus === SUCCEEDED || updateGuestPortalStatus === FAILED) {
			dispatch(updateGuestPortalEnd());
		}
	}, [updateGuestPortalStatus, updatedGuestPortal, modalType]);

	useEffect(() => {
		if (deleteGuestPortalStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.deleted'),
				}),
			);
			resetData();
		}
		if (deleteGuestPortalStatus === SUCCEEDED || deleteGuestPortalStatus === FAILED) {
			dispatch(deleteGuestPortalEnd());
		}
	}, [deleteGuestPortalStatus]);

	useEffect(() => {
		if (guestPortalError) {
			message.error(guestPortalError).then(() => {
				dispatch(
					storeDataGuestPortalRequest({
						guestPortalError: null,
					}),
				);
			});
		}
	}, [guestPortalError]);

	useEffect(() => {
		getGuestPortal();
	}, [requestPayload]);

	const resetData = (isRemainedData?: boolean) => {
		if (!isRemainedData) {
			getGuestPortal();
		}

		setConfirmationModalType('');
		setSelectedGuestPortals([]);
	};

	const getGuestPortal = (page?: number) => {
		dispatch(
			getGuestPortalListRequest({ ...requestPayload, page: page || requestPayload?.page }),
		);
	};

	const onCancel = () => {
		setGuestPortalDetail(null);
		setModalType('');
	};

	const handleGuestPortalStatus = (status: string) => {
		const payload = {
			id: guestPortalDetail?.id,
			params: {
				status: status.toUpperCase(),
			},
		};
		dispatch(updateGuestPortalRequest(payload));
	};

	const handleDeleteGuestPortal = () => {
		const payload = selectedGuestPortals.map(
			(guestPortal: GuestPortalItemDto) => guestPortal.id,
		);
		dispatch(deleteGuestPortalRequest(payload));
	};

	const handleCopiedClipboardMessage = (url: string) => {
		const propsMessage: any = {
			i18nKey: 'setting.guest_portal.message.copied_clipboard',
			components: {
				linkTo: (
					<StyledLinkHref
						fontWeight="normal"
						underline={true}
						color={THEME.colors.blueBase}
						onClick={() => {
							window.open(url, '_blank');
						}}
					/>
				),
			},
		};

		onCopyValue(url);
		message.success({
			content: <Trans t={t} {...propsMessage} />,
			duration: DURATION.S_3,
		});
	};

	const renderConfirmationModal = () => {
		let content: string = '';
		let onSubmit: () => void;
		let okText = t('button.continue');
		const isOpen: boolean = !!confirmationModalType;
		const confirmLoading =
			updateGuestPortalStatus === IN_PROGRESS || deleteGuestPortalStatus === IN_PROGRESS;

		switch (confirmationModalType) {
			case GUEST_PORTAL_MODAL_TYPE.SWITCH_STATUS: {
				if (guestPortalDetail) {
					const status =
						guestPortalDetail.status?.toLowerCase() === STATUS_MODES.ACTIVE
							? STATUS_MODES.DISABLED
							: STATUS_MODES.ACTIVE;
					content = t('setting.guest_portal.message.switch_guest_portal_status', {
						status,
					});
					onSubmit = () => handleGuestPortalStatus(status);
				}

				break;
			}

			case GUEST_PORTAL_MODAL_TYPE.DELETE: {
				content = t('setting.guest_portal.message.delete_guest_portal', {
					text: selectedGuestPortals.length > 1 ? t('these') : t('this'),
					suffix: selectedGuestPortals.length > 1 ? 's' : '',
				});
				okText = t('button.delete');
				onSubmit = handleDeleteGuestPortal;
				break;
			}

			case GUEST_PORTAL_MODAL_TYPE.CHANGING_URL: {
				content = t('setting.guest_portal.message.changing_url');
				okText = t('landing_page_manager.button.publish_to_new_url');
				onSubmit = () => {
					if (guestPortalDetailModalRef && guestPortalDetailModalRef.current) {
						guestPortalDetailModalRef.current.publishChanges();
					}
				};
				break;
			}

			default:
				break;
		}

		return (
			<ConfirmModal
				confirmLoading={confirmLoading}
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => {
					setConfirmationModalType('');
				}}>
				{content}
			</ConfirmModal>
		);
	};

	const contextValues = {
		setGuestPortalDetail,
		setModalType,
		selectedGuestPortals,
		setSelectedGuestPortals,
		handleGuestPortalStatus,
		confirmationModalType,
		setConfirmationModalType,
		requestPayload,
		setRequestPayload,
		handleCopiedClipboardMessage,
	};

	const isWrapperLoading =
		getGuestPortalListStatus === IN_PROGRESS || deleteGuestPortalStatus === IN_PROGRESS;

	return (
		<GuestPortalContext.Provider value={contextValues}>
			<LoadingWrapper zIndex={100} isLoading={isWrapperLoading}>
				<StyledWrapperContent padding="24px 0 0 0">
					<GuestPortalActionBar />
					<GuestPortalList />
					{modalType === GUEST_PORTAL_MODAL_TYPE.ACTION_ON_GUEST && (
						<GuestPortalDetailModal
							ref={guestPortalDetailModalRef}
							visible={modalType === GUEST_PORTAL_MODAL_TYPE.ACTION_ON_GUEST}
							onCancel={onCancel}
							guestPortalDetail={guestPortalDetail}
							setConfirmationModalType={setConfirmationModalType}
							handleCopiedClipboardMessage={handleCopiedClipboardMessage}
						/>
					)}
					{modalType === GUEST_PORTAL_MODAL_TYPE.SHARE && (
						<GuestPortalShareModal
							onCancel={onCancel}
							guestPortalDetail={selectedGuestPortals[0]}
							handleCopiedClipboardMessage={handleCopiedClipboardMessage}
						/>
					)}
					{confirmationModalType && renderConfirmationModal()}
				</StyledWrapperContent>
			</LoadingWrapper>
		</GuestPortalContext.Provider>
	);
};

export default GuestPortal;
