import { takeLatest, put } from 'redux-saga/effects';
import sidebarTypes from '@stores/sidebar/sidebar.types';
import * as actions from './sidebar.actions';
import genericApiSaga from '@helpers/genericApiSaga';
import { getRequest } from '@helpers/requestHelpers';
import { FETCH_CLIENT_SETTINGS, FOLDER_SYSTEM } from '@constants/APIs';

export function* fetchCollapseMenu({ payload }: any) {
	try {
		yield put(actions.fetchCollapseMenuSucceeded(payload));
	} catch (err) {
		yield put(actions.fetchCollapseMenuFailed(err));
	}
}

export function* initPendoRequest({ payload }: any) {
	try {
		yield put(actions.initPendoSucceeded(payload));
	} catch (err) {
		console.log('initPendoRequest - error: ', err);
	}
}

function* clientSettingRequest() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${FETCH_CLIENT_SETTINGS}`, {}),
		*completed(response: any) {
			yield put(actions.clientSettingsSucceded(response.data));
		},
		*failed(response: any) {
			yield put(actions.clientSettingsFailed(response));
		},
	});
}

function* fetchFolderSystemRequest() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${FOLDER_SYSTEM}`),
		*completed(response: any) {
			yield put(actions.folderSystemSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actions.folderSystemFailed(response));
		},
	});
}

export function* watcherSidebarSaga() {
	yield takeLatest(sidebarTypes.SIDEBAR_REQUEST, fetchCollapseMenu);
	yield takeLatest(sidebarTypes.CLIENT_SETTINGS_REQUEST, clientSettingRequest);
	yield takeLatest(sidebarTypes.FOLDER_SYSTEM_REQUEST, fetchFolderSystemRequest);
	yield takeLatest(sidebarTypes.PENDO_INIT_REQUEST, initPendoRequest);
}
