import {
	IconAmbassadorActive,
	IconAmbassadorInactive,
	IconBlockRed,
	IconCheck,
	IconCheckActive,
	IconCreatorActive,
	IconCreatorUnsubscribed,
	IconCustomTerms,
	IconError,
	IconInstagram,
	IconNoteMessage,
	IconRightsPending,
	IconTiktok,
	IconTwitter,
	IconYoutube,
} from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import {
	DURATION,
	KEYBOARD,
	LINK_EMAIL,
	MESSAGE_KEY,
	MESSAGE_TYPE,
	RESOLUTIONS,
	ROUTE_PATH,
} from '@constants/common';
import { UPLOADSOURCE } from '@constants/content/contentLibrary';
import { SCREEN_VIEW } from '@constants/content/widgetManager';
import { CONTENT_SOURCES } from '@constants/filter/filterContent';
import {
	DISPLAY_TYPES,
	RULES_TERMS,
	STATUS_TEMPLATE,
	TERMS_TYPE,
} from '@constants/licensingTemplate';
import { SOCIAL } from '@constants/social_search';
import THEME from '@constants/themes/themes';
import { ResponseDto } from '@models/common/store';
import { CustomizedMessageDto } from '@models/common/summary';
import { ActionNoClickTypes } from '@models/content/albums/albumManager';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { OptionTypeItem } from '@models/filter';
import { ActionsTypes } from '@models/socialsearch/summary';
import { StyledTooltip } from '@styled/ChangePasswordStyled';
import {
	StyledAction,
	StyledEmpty,
	StyledIcon,
	StyledLinkHref,
	StyledLinkTo,
	StyledTextSelection,
} from '@styled/Common/CommonStyled';
import { StyledDirectLink } from '@styled/Content/ContentLibrary/ContentStyled';
import { StyledRate } from '@styled/Filter/FilterContentStyled';
import { Menu, Popover, Tooltip, message } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import history from './history';

export const RenderViewPostIcon = (
	activeSocialType: string,
	isDisabled?: boolean,
	onViewPost?: () => void,
) => {
	const { t } = useTranslation();

	let icon: JSX.Element = <></>;

	if (activeSocialType === SOCIAL.INSTAGRAM.toLowerCase()) {
		icon = <IconInstagram />;
	} else if (activeSocialType === SOCIAL.TIKTOK.toLowerCase()) {
		icon = <IconTiktok />;
	} else if (activeSocialType === SOCIAL.YOUTUBE.toLowerCase()) {
		icon = <IconYoutube />;
	} else if (activeSocialType === SOCIAL.TWITTER.toLowerCase()) {
		icon = <IconTwitter />;
	}

	const onRedirectViewPost = () => {
		if (activeSocialType && onViewPost && typeof onViewPost === 'function') {
			return onViewPost();
		}
		return null;
	};

	return (
		<Tooltip title={t('button.view_post')}>
			<StyledAction
				onClick={onRedirectViewPost}
				className="action-icon ml_0"
				isDisabled={isDisabled}>
				{icon}
			</StyledAction>
		</Tooltip>
	);
};

export const renderErrorMessagePwd = (error: string, placement?: TooltipPlacement) => {
	let className = 'error-tooltip';
	className += placement ? ` ${placement}` : '';

	return (
		<StyledTooltip
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
			visible={true}
			destroyTooltipOnHide={true}
			overlayClassName={className}
			placement={placement || 'right'}
			title={<div style={{ whiteSpace: 'pre-line' }}>{error}</div>}
		/>
	);
};

export const renderThumbVertical = (props: any) => {
	const { style, ...other } = props;
	return (
		<div
			{...other}
			style={{
				...style,
				backgroundColor: THEME.colors.gray2,
				borderRadius: '20px',
				overflowX: 'hidden',
			}}
		/>
	);
};

export const menuActions = (actionsObj: ActionsTypes[], checkList?: any[]) => (
	<Menu>
		{actionsObj.map((item: any) => {
			if (item?.hidden) {
				return null;
			}
			return (
				<Menu.Item
					key={item.name}
					onClick={(e: any) => {
						if (e && e.domEvent) {
							e.domEvent.stopPropagation();
						}
						item?.click();
					}}
					disabled={item?.disabled}>
					{item.name}
					{checkList && checkList.includes(item?.id?.toString()) && (
						<StyledIcon className="icon-check">
							<IconCheck />
						</StyledIcon>
					)}
				</Menu.Item>
			);
		})}
	</Menu>
);

export const GENERAL_MSG_KEY = 'general-message';

type OtherMessage = {
	key?: string;
	duration?: number;
	values?: any;
	href?: string;
};

export const generalMessage = (
	content: ResponseDto | string,
	type: string,
	other?: OtherMessage,
) => {
	const { key = '', duration = undefined, values = undefined, href = undefined } = other || {};
	let config: CustomizedMessageDto = {
		key: key || GENERAL_MSG_KEY,
	};

	if (typeof content === 'string') {
		const otherProp: any = {};
		if (href !== undefined) {
			otherProp.href = href;
		}
		const contentProps: any = {
			i18nKey: content,
			components: {
				linkToEmail: <StyledLinkTo {...otherProp} />,
			},
		};
		if (values) {
			contentProps.values = values;
		}

		const timeShow = content.includes(LINK_EMAIL) ? DURATION.S_10 : DURATION.S_3;
		config = {
			...config,
			content: <Trans {...contentProps} />,
			duration: duration !== undefined ? duration : timeShow,
		};
	}

	switch (type) {
		case MESSAGE_TYPE.ERROR:
			message.error(config).then(() => {
				message.destroy(config.key);
			});
			break;

		case MESSAGE_TYPE.SUCCESS:
			message.success(config).then(() => {
				message.destroy(config.key);
			});
			break;

		case MESSAGE_TYPE.WARNING:
			message.warning(config).then(() => {
				message.destroy(config.key);
			});
			break;
		case MESSAGE_TYPE.INFO:
			message.info(config).then(() => {
				message.destroy(config.key);
			});
			break;
		default:
			break;
	}
};

export const destroyGeneralMessage = () => {
	message.destroy(GENERAL_MSG_KEY);
};

export const useDevice = () => {
	const [isMobile, setIsMobile] = useState<boolean>();

	useLayoutEffect(() => {
		const setDevice = () => {
			if (window.innerWidth <= RESOLUTIONS.MOBILE) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		window.addEventListener('resize', setDevice);
		setDevice();
		return () => window.removeEventListener('resize', setDevice);
	}, []);

	return { isMobile };
};

export const setDownloadBehavior = (selectedContents: ContentDto[], t: any) => {
	let title: string = '';
	let countContent: number = 0;
	let isExistBookmarkContent: boolean = false;

	if (selectedContents.length > 0) {
		countContent = selectedContents.length;

		selectedContents.forEach((item: ContentDto) => {
			const { uploadSource } = item;

			if (
				uploadSource === UPLOADSOURCE.SOCIAL_SEARCH ||
				uploadSource === UPLOADSOURCE.SOCIAL_UPLOAD
			) {
				isExistBookmarkContent = true;
				countContent -= 1;
			}
		});

		if (isExistBookmarkContent && countContent === 0) {
			title =
				selectedContents.length > 1
					? t('tooltip.download_multi_bookmark_content')
					: t('tooltip.download_bookmark_content');
		} else {
			title = '';
		}
	}

	return {
		title,
		disabled: title.length > 0,
		countContent,
		isDisplayMessage: isExistBookmarkContent && countContent > 0,
	};
};

export const setDownloadMenuItem = (t: any) => {
	return [
		{
			id: 1,
			name: t('button.download'),
			click: () => {},
		},
		{
			id: 2,
			name: t('button.export_gg_drive'),
			click: () => {},
		},
	];
};

export const favoriteMessage = (isFavorite: boolean, t: any) => {
	const handleDirectToAlbum = () => {
		history.push(ROUTE_PATH.FAVORITE);
	};

	const propsMessage: any = {
		i18nKey: isFavorite
			? 'message.favorite_content_succeeded'
			: 'message.un_favorite_content_succeeded',
		components: {
			linkTo: <StyledDirectLink onClick={handleDirectToAlbum} />,
		},
	};

	message.success({
		content: <Trans t={t} {...propsMessage} />,
		duration: DURATION.S_3,
		key: MESSAGE_KEY.FAVORITE_CONTENT,
	});
};

export const notFoundContent = (t: any) => {
	return (
		<div className="select-empty">
			<span className="select-empty-text">{t('common.no_data')}</span>
		</div>
	);
};

export const renderIconCreatorStatus = (
	{
		newRecently,
		blocked,
		unsubscribed,
		followed,
		active,
	}: {
		newRecently: boolean;
		blocked: boolean;
		unsubscribed: boolean;
		followed: boolean;
		active: boolean;
	},
	t: any,
) => {
	const otherKey = {
		components: {
			elm: <b />,
		},
	};
	const arr = [
		{
			val: newRecently,
			icon: <IconNoteMessage />,
			title: t('creator_summary.tooltip.recently_added'),
		},
		{
			val: blocked,
			icon: <IconBlockRed />,
			title: t('creator_summary.tooltip.blocked'),
		},
		{
			val: unsubscribed,
			icon: <IconCreatorUnsubscribed />,
			title: t('creator_summary.tooltip.unsubscribed'),
		},
		{
			val: followed && active,
			icon: <IconAmbassadorActive />,
			title: (
				<Trans t={t} {...otherKey} i18nKey={'creator_summary.tooltip.ambassador_active'} />
			),
		},
		{
			val: followed && !active,
			icon: <IconAmbassadorInactive />,
			title: (
				<Trans
					t={t}
					{...otherKey}
					i18nKey={'creator_summary.tooltip.ambassador_inactive'}
				/>
			),
		},
		{
			val: active,
			icon: <IconCreatorActive />,
			title: <Trans t={t} {...otherKey} i18nKey={'creator_summary.tooltip.creator_active'} />,
		},
		{
			val: !active,
			icon: <IconRightsPending />,
			title: (
				<Trans t={t} {...otherKey} i18nKey={'creator_summary.tooltip.creator_inactive'} />
			),
		},
	];
	return arr.find((item) => item?.val === true);
};

export const renderRating = (avgStar: number, style?: { size?: number; margin?: string }) => {
	let star = avgStar;
	const roundingStar = Math.floor(avgStar);
	const deviant = avgStar - roundingStar;
	if (deviant < 0.25) {
		star = roundingStar;
	} else if (deviant < 0.75) {
		star = roundingStar + 0.5;
	} else {
		star = roundingStar + 1;
	}
	return (
		<div onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
			<StyledRate size={style?.size} margin={style?.margin} allowHalf disabled value={star} />
		</div>
	);
};

export const renderContentWithPopover = (content: string, otherProps?: any) => {
	const { width = 300, placement = 'topLeft' } = otherProps || {};
	return (
		<Popover
			content={content}
			placement={placement}
			overlayInnerStyle={{
				width,
			}}>
			{content}
		</Popover>
	);
};

export const renderListStatusTemplate = (t: any) => {
	const arrOption: ActionNoClickTypes[] = [
		{
			id: 1,
			name: t('content_licensing.approved'),
			status: STATUS_TEMPLATE.APPROVED,
			icon: <IconCheckActive />,
			class: 'publish_status',
			theme: THEME.colors.greenBase,
			styleIcon: {
				fill: THEME.colors.greenBase,
			},
		},
		{
			id: 2,
			name: t('content_licensing.draft'),
			status: STATUS_TEMPLATE.DRAFT,
			icon: <IconCustomTerms />,
			class: 'draft_status',
			theme: THEME.colors.yellowBase,
			hidden: true,
			styleIcon: {
				fillPath: THEME.colors.yellowBase,
			},
		},
		{
			id: 3,
			name: t('content_licensing.archived'),
			status: STATUS_TEMPLATE.ARCHIVED,
			icon: <IconError />,
			class: 'archive_status',
			theme: THEME.colors.red1,
			styleIcon: {
				fill: THEME.colors.red1,
			},
		},
	];
	return arrOption;
};

export const renderListAddTerms = (t: any) => {
	const arrOption: ActionNoClickTypes[] = [
		{
			id: 1,
			name: t('button.add_enTribe_standard_terms'),
			status: TERMS_TYPE.STANDARD,
		},
		{
			id: 2,
			name: t('button.add_custom_terms'),
			status: TERMS_TYPE.CUSTOM,
		},
	];
	return arrOption;
};

export const renderScreenView = (t: any) => {
	const optionView = [
		{ label: t('content.widget_manager.desktop_view'), value: SCREEN_VIEW.DESKTOP },
		{ label: t('content.widget_manager.mobile_view'), value: SCREEN_VIEW.MOBILE },
	];
	return optionView;
};

export const renderListDisplayContentLicensing = (t: any) => {
	const listDisplay = [
		{
			id: 1,
			title: t('content_licensing.popup'),
			val: DISPLAY_TYPES.POPUP,
		},
		{
			id: 2,
			title: t('content_licensing.in_line'),
			val: DISPLAY_TYPES.IN_LINE,
		},
		{
			id: 3,
			title: t('content_licensing.redirect'),
			val: DISPLAY_TYPES.REDIRECT,
		},
	];
	return listDisplay;
};

export const renderListContentSources = (t: any) => {
	return [
		{
			label: t('filter.filter_content.direct_upload'),
			value: CONTENT_SOURCES.ALL_DIRECT_UPLOAD,
		},
		{
			label: t('filter.filter_content.shared_google_drive'),
			value: CONTENT_SOURCES.SHARED_FROM_GOOGLE_DRIVE,
		},
		{
			label: t('filter.filter_content.shared_youtube'),
			value: CONTENT_SOURCES.SHARED_FROM_YOUTUBE,
		},
		{
			label: t('filter.filter_content.shared_tiktok'),
			value: CONTENT_SOURCES.SHARED_FROM_TIKTOK,
		},
		{
			label: t('filter.filter_content.shared_instagram'),
			value: CONTENT_SOURCES.SHARED_FROM_INSTAGRAM,
		},
		{
			label: t('filter.filter_content.manual_import'),
			value: CONTENT_SOURCES.MANUAL_IMPORT,
		},
		{
			label: t('filter.filter_content.script_import'),
			value: CONTENT_SOURCES.SCRIPT_IMPORT,
		},
	];
};

export const renderListRulesContentLicensing = (t: any) => {
	const listRules: OptionTypeItem[] = [
		{
			name: t('content_licensing.agree_order'),
			value: RULES_TERMS.AGREE,
		},
		{
			name: t('content_licensing.must_read_terms'),
			value: RULES_TERMS.READ,
		},
	];
	return listRules;
};

export const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

export const formatTextIncludeHyperlink = (text: string) => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.split(urlRegex).map((part: any) => {
		if (part.match(urlRegex)) {
			return (
				<StyledLinkHref
					color={THEME.colors.blueBase}
					href={part}
					target="_blank"
					fontWeight="normal"
					underline={true}
					onClick={() => window.open(part)}>
					{part}
				</StyledLinkHref>
			);
		}
		return part;
	});
};

export const FunctionalCopySelection = ({ name }: { name: string | number | undefined | null }) => {
	const refShift = useRef<boolean>(false);
	useEffect(() => {
		const onKeyup = (e: KeyboardEvent) => {
			if (e.key === KEYBOARD.SHIFT.STR) {
				refShift.current = false;
			}
		};
		window.addEventListener('keyup', onKeyup);
		return () => window.removeEventListener('keyup', onKeyup);
	}, [refShift.current]);

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (e.key === KEYBOARD.SHIFT.STR) {
				refShift.current = true;
			}
		};
		window.addEventListener('keydown', onKeyDown);
		return () => window.removeEventListener('keydown', onKeyDown);
	}, [refShift.current]);

	return (
		<StyledTextSelection
			onClick={(e: React.MouseEvent<HTMLElement>) => {
				if (!refShift.current) {
					e.stopPropagation();
					e.preventDefault();
				}
			}}
			onMouseDownCapture={(e) => {
				if (!refShift.current) {
					e.stopPropagation();
				}
			}}
			title={name ? name.toString() : ''}
			className={'overflow_text copy_text_selection'}>
			{name}
		</StyledTextSelection>
	);
};

export const renderCopiedText = (name: string | number | undefined | null) => {
	return <FunctionalCopySelection name={name} />;
};
