import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import { emailTemplate } from '@constants/messages';
import PreviewPanel from '../PreviewPanel';

const CustomTemplates: React.FC = () => {
	const {
		emailTemplate: {
			getEmailTemplateStatus = false,
			custom: { selectCustomEmailTemplate = 0, templates: customTemplates = [] } = {},
		} = {},
	} = useSelector((state: any) => state.messages);
	const [template, setTemplate] = useState<EmailTemplatesDataDto>(emailTemplate);

	useEffect(() => {
		if (customTemplates.length) {
			getEmailTemplateBody();
		}
	}, [customTemplates]);

	useEffect(() => {
		if (selectCustomEmailTemplate) {
			getEmailTemplateBody();
		}
	}, [selectCustomEmailTemplate]);

	useEffect(() => {
		updateTemplateStateAfterEdit();
	}, [getEmailTemplateStatus]);

	const updateTemplateStateAfterEdit = () => {
		const findTemplate = customTemplates.find(
			(item: EmailTemplatesDataDto) => item.id === template.id,
		);

		if (findTemplate) {
			setTemplate(findTemplate);
		}
	};

	const getEmailTemplateBody = () => {
		const findTemplate = customTemplates.find(
			(item: EmailTemplatesDataDto) => item.id === selectCustomEmailTemplate,
		);

		const setTL = selectCustomEmailTemplate === 0 ? customTemplates[0] : findTemplate;

		setTemplate(setTL);
	};

	const renderTemplate = () => {
		return { __html: template?.body };
	};

	if (template.id === 0) return null;

	return (
		<PreviewPanel template={template}>
			<div dangerouslySetInnerHTML={renderTemplate()} />
		</PreviewPanel>
	);
};

export default CustomTemplates;
