import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { StyledStepItem, StyledSteps } from '@styled/Content/WidgetManager';
import {
	StyledHeaderStep,
	StyledNumberStep,
	StyledProgressDone,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

type PropsHeaderStep = {
	handleChangeAction: (val: number) => void;
	[other: string]: any;
};

const HeaderStep = (props: PropsHeaderStep) => {
	const { t } = useTranslation();
	const { handleChangeAction, ...other } = props;

	const { currentStep } = useContext(WidgetBuilderContext);

	const listStep = [
		t('content.widget_manager.widget_builder.steps.header'),
		t('content.widget_manager.widget_builder.steps.styles'),
		t('content.widget_manager.widget_builder.steps.fields'),
		t('content.widget_manager.widget_builder.steps.term'),
		t('content.widget_manager.widget_builder.steps.review'),
	];

	return (
		<StyledHeaderStep {...other}>
			<StyledSteps
				current={currentStep}
				percent={60}
				labelPlacement="vertical"
				onChange={handleChangeAction}>
				{listStep?.map((item, idx) => {
					const otherPropStepItem: any = {};
					if (idx < currentStep) {
						otherPropStepItem.icon = (
							<StyledProgressDone
								type="circle"
								width={40}
								strokeWidth={4}
								percent={100}
								format={() => <StyledNumberStep>{idx + 1}</StyledNumberStep>}
							/>
						);
					}
					return <StyledStepItem {...otherPropStepItem} key={item} title={item} />;
				})}
			</StyledSteps>
		</StyledHeaderStep>
	);
};

export default HeaderStep;
