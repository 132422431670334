import { IconAdd, IconCampaign, IconRightPending } from '@assets/icons';
import LandingPageList from '@components/LandingPageManager/LandingPageList';
import LandingPageModal from '@components/LandingPageManager/LandingPageModal';
import LandingPagePreview from '@components/LandingPageManager/LandingPagePreview';
import { DURATION } from '@constants/common';
import {
	ACTION_TYPE_ON_LANDING_PAGE,
	DEFAULT_LANDING_PAGE_VALUES,
	DEFAULT_PAYLOAD_GETTING_LIST,
	LANDING_PAGE_SELECTORS,
	LANDING_PAGE_STATUS,
} from '@constants/landingPageManager';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import LandingPageManagerContext from '@contexts/LandingPageManager';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ConfirmModal } from '@cores/Modal';
import { getUser } from '@helpers/userHelpers';
import { TabDto, WidgetLinkDto } from '@models/common/summary';
import {
	LandingPageDto,
	ListRequestPayloadDto,
	UpdateLandingPageDto,
} from '@models/landingpagemanager';
import { LandingPageStoreDto } from '@models/landingpagemanager/stores';
import {
	clientSettingsRequest,
	clonePublishedLandingPageEnded,
	clonePublishedLandingPageRequest,
	createLandingPageEnd,
	createLandingPageRequest,
	deleteLandingPageEnded,
	deleteLandingPageRequest,
	fetchFolderGalleryRequest,
	getLandingPageListRequest,
	publishLandingPageRequest,
	resumeLandingPageDraftEnded,
	resumeLandingPageDraftRequest,
	storeLandingPageDataRequest,
	unPublishLandingPageEnded,
	unPublishLandingPageRequest,
	updateLandingPageRequest,
} from '@stores/actions';
import {
	StyledButton,
	StyledCol,
	StyledIcon,
	StyledLinkHref,
	StyledRow,
	StyledTabs,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { encodeToBase64 } from '@utils/funcHelper';
import { Tabs, message } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	defaultCampaignLandingPageTemplate,
	getLandingPageBuilderTemplate,
} from '../LandingPageBuilder/template';

const TAB = {
	PUBLISHED: 'PUBLISHED',
	DRAFT: 'DRAFT',
};

const LandingPageManager = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		landingPageListResult = [],
		gettingLandingPageList = false,
		createLandingPageStatus,
		landingPageDetailResult,
		resumeLandingPageDraftStatus,
		clonePublishedLandingPageStatus,
		updateLandingPageStatus,
		actionTypeOnLandingPage,
		updatedLandingPage,
		publishLandingPageStatus,
		publishedLandingPageResult,
		unPublishLandingPageStatus,
		deleteLandingPageStatus,
		duplicatedLandingPageDetailResult,
		error,
	}: LandingPageStoreDto = useSelector((state: any) => state.landingPage);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { folderGallery = null } = useSelector((state: any) => state.galleryManager);
	const user = getUser();

	const [listRequestPayload, setListRequestPayload] = useState<ListRequestPayloadDto>(
		DEFAULT_PAYLOAD_GETTING_LIST,
	);
	const [activeTab, setActiveTab] = useState<string>(TAB.PUBLISHED);
	const [landingPageList, setLandingPageList] = useState<LandingPageDto[]>([]);
	const [currentLandingPage, setCurrentLandingPage] = useState<LandingPageDto | null>(null);
	const [confirmationModalType, setConfirmationModalType] = useState<string>('');
	const [handledLandingPageItem, setHandledLandingPageItem] = useState<LandingPageDto | null>(
		null,
	);

	const tabs: TabDto[] = [
		{
			key: TAB.PUBLISHED,
			title: t('landing_page_manager.status.published'),
		},
		{
			key: TAB.DRAFT,
			title: t('landing_page_manager.status.draft'),
		},
	];

	useEffect(() => {
		dispatch(clientSettingsRequest());
		dispatch(
			fetchFolderGalleryRequest({
				page: 1,
				maxRecords: 1,
			}),
		);

		return () => {
			setCurrentLandingPage(null);
			setLandingPageList([]);
			dispatch(
				storeLandingPageDataRequest({
					landingPageListResult: [],
					error: null,
				}),
			);
		};
	}, []);

	useEffect(() => {
		if (activeTab) {
			setLandingPageList([]);
		}
	}, [activeTab]);

	useEffect(() => {
		if (landingPageListResult) {
			const listTemp =
				listRequestPayload.page === 1
					? [...landingPageListResult]
					: [...landingPageList, ...landingPageListResult];
			setLandingPageList(listTemp);

			if (!currentLandingPage) {
				setCurrentLandingPage(listTemp[0]);
			}
		}
	}, [landingPageListResult]);

	useEffect(() => {
		getLandingPageList();
	}, [listRequestPayload]);

	useEffect(() => {
		if (createLandingPageStatus === SUCCEEDED) {
			const handleInsertItem = (insertValue: LandingPageDto) => {
				const listTemp = [...landingPageList];
				listTemp.unshift(insertValue);
				setLandingPageList(listTemp);
				setCurrentLandingPage(insertValue);
			};

			if (landingPageDetailResult) {
				if (activeTab === TAB.DRAFT) {
					handleInsertItem(landingPageDetailResult);
					return;
				}

				setCurrentLandingPage(landingPageDetailResult);
			}

			if (duplicatedLandingPageDetailResult && activeTab === TAB.DRAFT) {
				handleInsertItem(duplicatedLandingPageDetailResult);
			}

			if (activeTab !== TAB.DRAFT) {
				setActiveTab(TAB.DRAFT);
				setListRequestPayload({
					...DEFAULT_PAYLOAD_GETTING_LIST,
					statuses: [TAB.DRAFT],
				});
			}
		}

		if (createLandingPageStatus === SUCCEEDED || createLandingPageStatus === FAILED) {
			dispatch(createLandingPageEnd());
		}
	}, [createLandingPageStatus, landingPageDetailResult, duplicatedLandingPageDetailResult]);

	useEffect(() => {
		if (
			resumeLandingPageDraftStatus === SUCCEEDED ||
			clonePublishedLandingPageStatus === SUCCEEDED
		) {
			setConfirmationModalType('');
		}

		if (resumeLandingPageDraftStatus === SUCCEEDED || resumeLandingPageDraftStatus === FAILED) {
			dispatch(resumeLandingPageDraftEnded());
		}

		if (
			clonePublishedLandingPageStatus === SUCCEEDED ||
			clonePublishedLandingPageStatus === FAILED
		) {
			dispatch(clonePublishedLandingPageEnded());
		}
	}, [resumeLandingPageDraftStatus, clonePublishedLandingPageStatus]);

	useEffect(() => {
		if (updateLandingPageStatus === SUCCEEDED && updatedLandingPage) {
			let listTemp = [...landingPageList];
			const resetValues = {
				actionTypeOnLandingPage: null,
				updatedLandingPage: null,
				updateLandingPageStatus: NONE,
			};

			switch (actionTypeOnLandingPage) {
				case ACTION_TYPE_ON_LANDING_PAGE.SAVE_DRAFT: {
					if (activeTab === TAB.DRAFT) {
						const landingPageItemIndex = listTemp.findIndex(
							(item) => item.id === updatedLandingPage.id,
						);
						if (landingPageItemIndex !== -1) {
							listTemp[landingPageItemIndex] = { ...updatedLandingPage };
						}
						setCurrentLandingPage(updatedLandingPage);
					} else {
						const landingPageItemIndex = listTemp.findIndex(
							(item) => item.id === updatedLandingPage.referenceId,
						);
						if (landingPageItemIndex !== -1 && updatedLandingPage.referenceId) {
							listTemp[landingPageItemIndex].referenceId =
								updatedLandingPage.referenceId;
						}
					}
					message.success(
						t('message.action_success', {
							action: t('action.saved'),
						}),
					);
					onCancelEditModal();
					break;
				}

				case ACTION_TYPE_ON_LANDING_PAGE.SET_AS_DEFAULT: {
					listTemp = listTemp.map((item) => ({ ...item, isDefault: false }));
					const landingPageItemIndex = listTemp.findIndex(
						(item) => item.id === updatedLandingPage.id,
					);
					if (landingPageItemIndex !== -1) {
						listTemp[landingPageItemIndex].isDefault = true;
					}
					break;
				}

				case ACTION_TYPE_ON_LANDING_PAGE.UPDATE_LANDING_PAGE_INFORMATION: {
					const landingPageItemIndex = listTemp.findIndex(
						(item) => item.id === updatedLandingPage.id,
					);
					if (landingPageItemIndex !== -1) {
						listTemp[landingPageItemIndex] = { ...updatedLandingPage };
					}
					message.success(
						t('message.action_success', {
							action: t('action.updated'),
						}),
					);
					break;
				}

				case ACTION_TYPE_ON_LANDING_PAGE.UPDATE_LANDING_PAGE_INFORMATION_WITH_URL: {
					const element = document.getElementById(
						LANDING_PAGE_SELECTORS.LANDING_PAGE_LIST_ID,
					);
					const landingPageItemIndex = listTemp.findIndex(
						(item) => item.id === updatedLandingPage.id,
					);
					listTemp.splice(landingPageItemIndex, 1);
					listTemp.unshift(updatedLandingPage);
					if (element) {
						element.scrollTop = 0;
					}
					message.success(
						t('message.action_success', {
							action: t('action.updated'),
						}),
					);

					break;
				}

				default:
					break;
			}

			setLandingPageList(listTemp);
			setConfirmationModalType('');
			setHandledLandingPageItem(null);
			dispatch(
				storeLandingPageDataRequest({
					...resetValues,
				}),
			);
		}
	}, [updateLandingPageStatus, actionTypeOnLandingPage, updatedLandingPage]);

	useEffect(() => {
		if (publishLandingPageStatus === SUCCEEDED && publishedLandingPageResult) {
			dispatch(
				storeLandingPageDataRequest({
					landingPageDetailResult: null,
					createLandingPageStatus: NONE,
				}),
			);

			if (activeTab !== TAB.PUBLISHED) {
				setActiveTab(TAB.PUBLISHED);
			}
			resetData([TAB.PUBLISHED]);

			const msgProps: any = {
				i18nKey: 'landing_page_manager.message.published_successfully',
				components: {
					linkTo: (
						<StyledLinkHref
							fontWeight="normal"
							margin="0 2px"
							underline
							href={publishedLandingPageResult.url}
							color={THEME.colors.blueBase}
							target="_blank"
						/>
					),
				},
			};

			setConfirmationModalType('');
			message.success({
				content: <Trans t={t} {...msgProps} />,
				duration: DURATION.S_10,
				key: 'landing_page_manager.message.published_successfully',
			});
		}

		if (publishLandingPageStatus === SUCCEEDED || publishLandingPageStatus === FAILED) {
			dispatch(
				storeLandingPageDataRequest({
					publishLandingPageStatus: NONE,
					publishedLandingPageResult: null,
				}),
			);
		}
	}, [publishLandingPageStatus]);

	useEffect(() => {
		if (deleteLandingPageStatus === SUCCEEDED && updatedLandingPage) {
			let listTemp = [...landingPageList];
			const deletedIndex = listTemp.findIndex((item) => item.id === updatedLandingPage.id);
			message.success(
				t('message.action_success', {
					action: t('action.deleted'),
				}),
			);

			if (updatedLandingPage.id === currentLandingPage?.id) {
				if (listTemp.length > 1) {
					setCurrentLandingPage(
						listTemp[deletedIndex > 0 ? deletedIndex - 1 : deletedIndex + 1],
					);
				} else {
					setCurrentLandingPage(null);
				}
			}

			listTemp = listTemp.filter((item) => item.id !== updatedLandingPage.id);
			setLandingPageList(listTemp);
			setConfirmationModalType('');
		}

		if (deleteLandingPageStatus === SUCCEEDED || deleteLandingPageStatus === FAILED) {
			dispatch(deleteLandingPageEnded());
		}
	}, [deleteLandingPageStatus, updatedLandingPage]);

	useEffect(() => {
		if (unPublishLandingPageStatus === SUCCEEDED) {
			setActiveTab(TAB.DRAFT);
			resetData([TAB.DRAFT]);
			message.success(
				t('message.action_success', {
					action: t('action.unpublished'),
				}),
			);
		}

		if (unPublishLandingPageStatus === SUCCEEDED || unPublishLandingPageStatus === FAILED) {
			dispatch(unPublishLandingPageEnded());
		}
	}, [unPublishLandingPageStatus]);

	useEffect(() => {
		if (error) {
			message.error({
				content: error,
				duration: DURATION.S_6,
				onClose: () => {
					storeLandingPageDataRequest({
						error: null,
					});
				},
			});
		}
	}, [error]);

	const getLandingPageList = () => {
		const payload = { ...listRequestPayload };
		if (activeTab === TAB.PUBLISHED) {
			payload.sortBy = 'publishedAt';
		} else {
			delete payload.sortBy;
		}
		dispatch(getLandingPageListRequest(payload));
	};

	const createNewLandingPage = () => {
		const uploader = clientSettings?.widgetLinks?.find(
			(item: WidgetLinkDto) => item?.isDefault,
		);
		const projectInfo = {
			clientId: encodeToBase64(user.clientId),
			uploaderLink: uploader?.url,
			gid: folderGallery[0]?.id,
			galleryLink: folderGallery[0]?.url,
		};
		const projectData = getLandingPageBuilderTemplate(projectInfo);

		const metadata = JSON.stringify({
			projectData,
			...DEFAULT_LANDING_PAGE_VALUES.metadata,
			...defaultCampaignLandingPageTemplate(projectInfo),
		});
		const payload = { description: '', metadata };
		dispatch(createLandingPageRequest({ ...payload }));
	};

	const statusIcon = (landingPageItem: LandingPageDto) => {
		switch (landingPageItem?.status) {
			case LANDING_PAGE_STATUS.PUBLISHED: {
				return {
					text: t('landing_page_manager.status.published'),
					icon: <IconCampaign />,
					iconColor: THEME.colors.blueBase,
					bgColor: 'rgba(24, 144, 255, 0.1)',
				};
			}

			default: {
				return {
					text: t('landing_page_manager.status.draft'),
					icon: <IconRightPending />,
					iconColor: THEME.colors.yellowBase,
					bgColor: 'rgba(251, 188, 5, 0.08)',
					textColor: THEME.colors.yellowBase,
				};
			}
		}
	};

	const renderStatusChip = (landingPageItem: LandingPageDto) => {
		const { text, icon, iconColor, bgColor, textColor } = statusIcon(landingPageItem) || {};

		return (
			<StyledWrapperContent
				display="inline-flex"
				alignItems="center"
				borderRadius="2px"
				color={textColor || THEME.colors.gray3}
				fontSize="12px"
				padding="0 4px 0 6px"
				background={bgColor}>
				{text}
				<StyledIcon size={16} margin="0 0 0 4px" fillPath={iconColor}>
					{icon}
				</StyledIcon>
			</StyledWrapperContent>
		);
	};

	const onTabClick = (tab: string) => {
		setActiveTab(tab);
		setLandingPageList([]);
		resetData([tab.toUpperCase()]);
	};

	const handleActionOnLandingPageItem = (item: LandingPageDto, actionType: string) => {
		let payload: UpdateLandingPageDto = {
			id: item.id,
		};

		switch (actionType) {
			case ACTION_TYPE_ON_LANDING_PAGE.DELETE: {
				dispatch(deleteLandingPageRequest(payload));
				return;
			}

			case ACTION_TYPE_ON_LANDING_PAGE.SET_AS_DEFAULT: {
				payload.isSetDefault = true;
				break;
			}

			case ACTION_TYPE_ON_LANDING_PAGE.UNPUBLISH: {
				dispatch(unPublishLandingPageRequest(payload));
				return;
			}

			case ACTION_TYPE_ON_LANDING_PAGE.DUPLICATE: {
				payload = {
					...item,
					metadata: JSON.stringify(item.metadata),
					name: `${item.name} (${t('button.duplicate').toLowerCase()})`,
					isDuplicated: true,
				};
				setCurrentLandingPage(null);
				dispatch(createLandingPageRequest(payload));
				return;
			}

			case ACTION_TYPE_ON_LANDING_PAGE.PUBLISH_CHANGES: {
				payload = {
					id: item.referenceId || item.id,
					accountEmail: user.email,
					metadata: JSON.stringify(item.metadata),
					name: item.name,
					description: item.description,
					url: item.url,
				};
				dispatch(publishLandingPageRequest(payload));
				return;
			}

			default:
				break;
		}

		dispatch(
			storeLandingPageDataRequest({
				actionTypeOnLandingPage: actionType,
				updatedLandingPage: item,
			}),
		);
		dispatch(updateLandingPageRequest({ ...payload }));
	};

	const onCloseConfirmationModal = () => {
		setConfirmationModalType('');
	};

	const continueWithDraft = () => {
		if (currentLandingPage) {
			dispatch(
				resumeLandingPageDraftRequest({
					id: currentLandingPage.id,
				}),
			);
		}
	};

	const startOver = () => {
		if (currentLandingPage) {
			dispatch(
				clonePublishedLandingPageRequest({
					id: currentLandingPage.id,
				}),
			);
		}
	};

	const renderConfirmationModal = () => {
		let content: ReactNode;
		let okText: string = t('content.widget_manager.button.continue_draft');
		let cancelText: string = t('content.widget_manager.button.start_over');
		const otherProps: any = {};

		if (
			confirmationModalType === ACTION_TYPE_ON_LANDING_PAGE.EDIT_LANDING_PAGE_WITH_REFERENCE
		) {
			content = (
				<StyledText
					dangerouslySetInnerHTML={{
						__html: t('landing_page_manager.message.edit_landing_page'),
					}}
				/>
			);
			otherProps.onClickBtnCancel = startOver;
			otherProps.onSubmit = continueWithDraft;
			otherProps.cancelLoading = clonePublishedLandingPageStatus === IN_PROGRESS;
			otherProps.confirmLoading = resumeLandingPageDraftStatus === IN_PROGRESS;
		}

		if (confirmationModalType === ACTION_TYPE_ON_LANDING_PAGE.DELETE) {
			cancelText = t('button.cancel');
			okText = t('button.continue');
			if (handledLandingPageItem) {
				otherProps.onSubmit = () =>
					handleActionOnLandingPageItem(
						handledLandingPageItem,
						ACTION_TYPE_ON_LANDING_PAGE.DELETE,
					);
			}
			otherProps.textAlign = 'left';
			otherProps.confirmLoading = deleteLandingPageStatus === IN_PROGRESS;

			if (handledLandingPageItem?.campaigns) {
				content = (
					<>
						<StyledText>
							{t('landing_page_manager.message.landing_page_assigned_campaign')}
						</StyledText>
						<StyledWrapperContent
							maxHeight="100px"
							overflow="auto"
							textAlign="left"
							margin="12px 0 12px 24px"
							fontStyle="italic">
							{handledLandingPageItem?.campaigns?.map((campaign, index) => {
								return (
									<StyledText className="overflow">{`${
										index + 1
									}. ${campaign}`}</StyledText>
								);
							})}
						</StyledWrapperContent>
						<StyledText>
							{t('landing_page_manager.message.delete_without_campaign')}
						</StyledText>
					</>
				);
			} else {
				otherProps.textAlign = 'center';
				content = (
					<StyledText>
						{t('landing_page_manager.message.delete_without_campaign')}
					</StyledText>
				);
				okText = t('button.delete');
			}
		}

		if (confirmationModalType === ACTION_TYPE_ON_LANDING_PAGE.UNPUBLISH) {
			content = t('landing_page_manager.message.unpublish_landing_page');
			okText = t('landing_page_manager.button.unpublish');
			cancelText = t('button.cancel');
			otherProps.confirmLoading = unPublishLandingPageStatus === IN_PROGRESS;

			if (handledLandingPageItem) {
				otherProps.onSubmit = () =>
					handleActionOnLandingPageItem(
						handledLandingPageItem,
						ACTION_TYPE_ON_LANDING_PAGE.UNPUBLISH,
					);
			}
		}

		if (confirmationModalType === ACTION_TYPE_ON_LANDING_PAGE.PUBLISH_CHANGES) {
			okText = t('button.publish');
			cancelText = t('button.cancel');

			if (handledLandingPageItem) {
				content = t('landing_page_manager.message.publish_changes', {
					url: handledLandingPageItem.url,
				});
				otherProps.otherConfirmText = t('landing_page_manager.button.continue_editing');
				otherProps.onSubmit = () =>
					handleActionOnLandingPageItem(
						handledLandingPageItem,
						ACTION_TYPE_ON_LANDING_PAGE.PUBLISH_CHANGES,
					);
				otherProps.onOtherConfirm = () => {
					setConfirmationModalType('');
					dispatch(
						storeLandingPageDataRequest({
							landingPageDetailResult: handledLandingPageItem,
						}),
					);
				};
				otherProps.confirmLoading = publishLandingPageStatus === IN_PROGRESS;
			}
		}

		return (
			<ConfirmModal
				width={450}
				maskClosable={true}
				isOpen={!!confirmationModalType}
				onClose={onCloseConfirmationModal}
				okText={okText}
				cancelText={cancelText}
				{...otherProps}>
				{content}
			</ConfirmModal>
		);
	};

	const resetData = (statuses: string[]) => {
		setCurrentLandingPage(null);
		setListRequestPayload({
			...DEFAULT_PAYLOAD_GETTING_LIST,
			statuses,
		});
		setConfirmationModalType('');
	};

	const onCancelEditModal = () => {
		dispatch(
			storeLandingPageDataRequest({
				landingPageDetailResult: null,
			}),
		);
	};

	const handleLoadMoreLandingPageList = () => {
		if (landingPageListResult.length > 0) {
			setListRequestPayload({ ...listRequestPayload, page: listRequestPayload.page + 1 });
		}
	};

	const contextValues = {
		landingPageList,
		currentLandingPage,
		setCurrentLandingPage,
		renderStatusChip,
		confirmationModalType,
		setConfirmationModalType,
		handleActionOnLandingPageItem,
		setHandledLandingPageItem,
		handleLoadMoreLandingPageList,
	};

	const isGeneralLoading =
		gettingLandingPageList ||
		createLandingPageStatus === IN_PROGRESS ||
		(updateLandingPageStatus === IN_PROGRESS &&
			actionTypeOnLandingPage !== ACTION_TYPE_ON_LANDING_PAGE.SAVE_DRAFT &&
			actionTypeOnLandingPage !==
				ACTION_TYPE_ON_LANDING_PAGE.UPDATE_LANDING_PAGE_INFORMATION &&
			!confirmationModalType);

	return (
		<LandingPageManagerContext.Provider value={contextValues}>
			<LoadingWrapper isLoading={isGeneralLoading} zIndex={1000}>
				<StyledRow justify="space-between" gutter={[16, 16]} height="calc(100vh - 82px)">
					<StyledCol height="100%" span={16}>
						<LandingPagePreview />
					</StyledCol>
					<StyledCol height="100%" span={8}>
						<StyledTabs
							onTabClick={onTabClick}
							height="100%"
							contentHeight="100%"
							activeKey={activeTab}
							fontWeight={600}>
							{tabs.map((tab: TabDto) => {
								return (
									<Tabs.TabPane key={tab.key} tab={tab.title}>
										{tab.key === activeTab ? <LandingPageList /> : null}
									</Tabs.TabPane>
								);
							})}
						</StyledTabs>

						<StyledWrapperContent
							width="90%"
							position="absolute"
							bottom="14px"
							left="50%"
							transform="translateX(-50%)">
							<StyledButton
								width="100%"
								type="default"
								onClick={createNewLandingPage}>
								<StyledIcon>
									<IconAdd />
								</StyledIcon>
								{t('landing_page_manager.button.build_a_new_landing_page')}
							</StyledButton>
						</StyledWrapperContent>
					</StyledCol>
				</StyledRow>
				{landingPageDetailResult && (
					<LandingPageModal
						visible={!!landingPageDetailResult}
						currentLandingPage={landingPageDetailResult}
						onCancel={onCancelEditModal}
						renderStatusChip={renderStatusChip}
					/>
				)}
				{confirmationModalType && renderConfirmationModal()}
			</LoadingWrapper>
		</LandingPageManagerContext.Provider>
	);
};

export default LandingPageManager;
