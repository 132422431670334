import { LOGOUT_SUCCESS, STATUSCODE } from '@constants/APIs';
import { ROUTE_PATH } from '@constants/common';
import { DEFAULT_ERROR } from '@constants/errors';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { removeAll } from '@helpers/storageHelpers';
import { getUser, setUser } from '@helpers/userHelpers';
import { ResponsePayloadDto } from '@models/common/store';
import history from '@utils/history';
import { Action, handleActions } from 'redux-actions';
import * as actions from './user.actions';
import { UserStoreType } from './user.types';

const initialState: UserStoreType = {
	fetchingUser: false,
	user: null,
	error: null,
	fetchingAccountUserPermission: false,
	accountUserPermissions: [],
	pairAuthenticatorAppStatus: NONE,
	verifyPasswordStatus: NONE,
	confirmMFAPairingStatus: NONE,
	disableMFAStatus: NONE,
	currentClient: null,
};

const userLogin = (state: UserStoreType) => ({
	...state,
	fetchingUser: true,
});

const userLoginCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: user = {},
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === 200) {
		setUser(user);

		return {
			...state,
			fetchingUser: false,
			user,
			error: null,
		};
	}

	return {
		...state,
		fetchingUser: false,
		user: null,
		error: status,
	};
};

const userLoginFailed = (state: UserStoreType, { payload }: { payload: any }) => {
	const { error = '', status: { code = 0 } = {} } = payload.data;
	let result: UserStoreType = {
		...state,
		fetchingUser: false,
		error,
	};
	removeAll();

	if (code === STATUSCODE.FAILED431) {
		result = { ...result, mfaLoginToken: error, error: null };
	}

	return result;
};

const userLoginEnd = (state: UserStoreType) => ({
	...state,
	fetchingUser: false,
	error: null,
});

const userLogout = (state: UserStoreType) => ({
	...state,
	fetchingUser: true,
});

const userLogoutCompleted = (state: UserStoreType, { payload }: Action<string>) => {
	let result = {
		...state,
		fetchingUser: false,
	};

	if (payload === LOGOUT_SUCCESS) {
		result = { ...result, user: null };
		removeAll();
		history.push(ROUTE_PATH.LOGIN);
	}

	return result;
};

const userLogoutFailed = (state: UserStoreType, { payload }: Action<ResponsePayloadDto>) => {
	const result = {
		...state,
		fetchingUser: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}

		// Token expired.

		removeAll();

		history.push(ROUTE_PATH.LOGIN);
	}

	return result;
};

// get info user
const getInfoUserRequest = (state: UserStoreType) => ({
	...state,
	fetchingUser: true,
});

const getInfoUserSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: user = {},
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === 200) {
		setUser(user);

		return {
			...state,
			fetchingUser: false,
			user,
			error: null,
		};
	}

	return {
		...state,
		fetchingUser: false,
		user: null,
		error: status,
	};
};

const getInfoUserFailed = (state: UserStoreType, { payload }: { payload: any }) => {
	const { error = '', status: { code = 0 } = {} } = payload.data;
	const result: UserStoreType = {
		...state,
		fetchingUser: false,
		error,
	};
	removeAll();

	history.push(ROUTE_PATH.LOGIN);

	return result;
};

const updateProfileSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0 },
		result,
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const storedUser = getUser();
		const updatedUser = { ...storedUser, ...result };
		setUser(updatedUser);

		return {
			...state,
			user: updatedUser,
			error: null,
		};
	}

	return {
		...state,
	};
};

const fetchAccountUserPermissionSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: accountUserPermissions = [],
	} = payload;

	if (code === 200) {
		return {
			...state,
			fetchingAccountUserPermission: false,
			accountUserPermissions,
			error: null,
		};
	}

	return {
		...state,
		fetchingAccountUserPermission: false,
		accountUserPermissions: [],
		error: status,
	};
};

const fetchAccountUserPermissionFailed = (state: UserStoreType, { payload }: { payload: any }) => {
	const { error = '' } = payload.data || {};

	return {
		...state,
		fetchingAccountUserPermission: false,
		accountUserPermissions: [],
		error,
	};
};

// Verify password
const verifyPasswordRequest = (state: UserStoreType) => {
	return {
		...state,
		verifyPasswordStatus: IN_PROGRESS,
	};
};

const verifyPasswordSucceeded = (state: UserStoreType, { payload }: Action<ResponsePayloadDto>) => {
	const {
		status: { code = 0 },
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			verifyPasswordStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		verifyPasswordStatus: FAILED,
	};
};

const verifyPasswordFailed = (state: UserStoreType, { payload }: Action<ResponsePayloadDto>) => {
	const result = {
		...state,
		verifyPasswordStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const verifyPasswordEnd = (state: UserStoreType) => ({
	...state,
	verifyPasswordStatus: NONE,
	error: null,
});

// Register authenticator app
const pairAuthenticatorAppRequest = (state: UserStoreType) => {
	return {
		...state,
		pairAuthenticatorAppStatus: IN_PROGRESS,
	};
};

const pairAuthenticatorAppSucceeded = (
	state: UserStoreType,
	{ payload }: Action<ResponsePayloadDto>,
) => {
	const {
		status: { code = 0 },
		result: authenticatorAppInfo = {},
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			pairAuthenticatorAppStatus: SUCCEEDED,
			authenticatorAppInfo,
		};
	}

	return {
		...state,
		pairAuthenticatorAppStatus: FAILED,
	};
};

const pairAuthenticatorAppFailed = (
	state: UserStoreType,
	{ payload }: Action<ResponsePayloadDto>,
) => {
	const result = {
		...state,
		pairAuthenticatorAppStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const pairAuthenticatorAppEnd = (state: UserStoreType) => ({
	...state,
	pairAuthenticatorAppStatus: NONE,
	authenticatorAppInfo: null,
});

// Confirm registration authenticator app
const confirmMFAPairingRequest = (state: UserStoreType) => {
	return {
		...state,
		confirmMFAPairingStatus: IN_PROGRESS,
	};
};

const confirmMFAPairingSucceeded = (
	state: UserStoreType,
	{ payload }: Action<ResponsePayloadDto>,
) => {
	const {
		status: { code = 0 },
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		let result = {
			...state,
			confirmMFAPairingStatus: SUCCEEDED,
		};
		const user = getUser();
		if (user) {
			const updatedUser = { ...user, mfaEnabled: true };
			setUser(updatedUser);
			result = { ...result, user: updatedUser };
		}

		return result;
	}

	return {
		...state,
		confirmMFAPairingStatus: FAILED,
	};
};

const confirmMFAPairingFailed = (state: UserStoreType, { payload }: Action<ResponsePayloadDto>) => {
	const result = {
		...state,
		confirmMFAPairingStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const confirmMFAPairingEnd = (state: UserStoreType) => ({
	...state,
	confirmMFAPairingStatus: NONE,
});

// Login with MFA
const loginWithMFARequest = (state: UserStoreType) => {
	return {
		...state,
		fetchingUser: IN_PROGRESS,
	};
};

const loginWithMFAEnd = (state: UserStoreType) => {
	return {
		...state,
		mfaLoginToken: null,
	};
};

// Disable MFA
const disableMFARequest = (state: UserStoreType) => {
	return {
		...state,
		disableMFAStatus: IN_PROGRESS,
	};
};

const disableMFASucceeded = (state: UserStoreType, { payload }: Action<ResponsePayloadDto>) => {
	const {
		status: { code = 0 },
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		let result = {
			...state,
			disableMFAStatus: SUCCEEDED,
		};
		const user = getUser();
		if (user) {
			const updatedUser = { ...user, mfaEnabled: false };
			result = { ...result, user: updatedUser };
			setUser(updatedUser);
		}

		return result;
	}

	return {
		...state,
		disableMFAStatus: FAILED,
	};
};

const disableMFAFailed = (state: UserStoreType, { payload }: Action<ResponsePayloadDto>) => {
	const result = {
		...state,
		disableMFAStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const disableMFAEnd = (state: UserStoreType) => ({
	...state,
	disableMFAStatus: NONE,
});

const storeUserData = (state: UserStoreType, { payload }: Action<any>) => {
	if ('user' in payload) {
		setUser(payload.user);
	}

	return {
		...state,
		...payload,
	};
};

export default handleActions<any>(
	{
		[actions.USER_LOGIN]: userLogin,
		[actions.USER_LOGIN_COMPLETED]: userLoginCompleted,
		[actions.USER_LOGIN_FAILED]: userLoginFailed,
		[actions.USER_LOGIN_END]: userLoginEnd,

		[actions.USER_LOGOUT]: userLogout,
		[actions.USER_LOGOUT_COMPLETED]: userLogoutCompleted,
		[actions.USER_LOGOUT_FAILED]: userLogoutFailed,

		[actions.GET_INFO_USER_REQUEST]: getInfoUserRequest,
		[actions.GET_INFO_USER_SUCCEEDED]: getInfoUserSucceeded,
		[actions.GET_INFO_USER_FAILED]: getInfoUserFailed,

		[actions.UPDATE_PROFILE_COMPLETED]: updateProfileSucceeded,

		[actions.GET_ACCOUNT_USER_PERMISSION_SUCCEEDED]: fetchAccountUserPermissionSucceeded,
		[actions.GET_ACCOUNT_USER_PERMISSION_FAILED]: fetchAccountUserPermissionFailed,

		[actions.VERIFY_PASSWORD_REQUEST]: verifyPasswordRequest,
		[actions.VERIFY_PASSWORD_SUCCEEDED]: verifyPasswordSucceeded,
		[actions.VERIFY_PASSWORD_FAILED]: verifyPasswordFailed,
		[actions.VERIFY_PASSWORD_END]: verifyPasswordEnd,

		[actions.PAIR_AUTHENTICATOR_APP_REQUEST]: pairAuthenticatorAppRequest,
		[actions.PAIR_AUTHENTICATOR_APP_SUCCEEDED]: pairAuthenticatorAppSucceeded,
		[actions.PAIR_AUTHENTICATOR_APP_FAILED]: pairAuthenticatorAppFailed,
		[actions.PAIR_AUTHENTICATOR_APP_END]: pairAuthenticatorAppEnd,

		[actions.CONFIRM_MFA_PAIRING_REQUEST]: confirmMFAPairingRequest,
		[actions.CONFIRM_MFA_PAIRING_SUCCEEDED]: confirmMFAPairingSucceeded,
		[actions.CONFIRM_MFA_PAIRING_FAILED]: confirmMFAPairingFailed,
		[actions.CONFIRM_MFA_PAIRING_END]: confirmMFAPairingEnd,

		[actions.DISABLE_MFA_REQUEST]: disableMFARequest,
		[actions.DISABLE_MFA_SUCCEEDED]: disableMFASucceeded,
		[actions.DISABLE_MFA_FAILED]: disableMFAFailed,
		[actions.DISABLE_MFA_END]: disableMFAEnd,

		[actions.LOGIN_WITH_MFA_REQUEST]: loginWithMFARequest,
		[actions.LOGIN_WITH_MFA_END]: loginWithMFAEnd,

		[actions.STORE_USER_DATA]: storeUserData,
	},
	initialState,
);
