import { IconExpandMore } from '@assets/icons';
import CustomPickerColor from '@components/CustomPickerColor';
import { GROUP_HIGH_LIGHT, KEY_HIGH_LIGHT_GROUP } from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import {
	StyledBoxColor,
	StyledCustomColor,
	StyledResultColor,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import React, { useContext } from 'react';
import styled from 'styled-components';

const StyledContentColor = styled.div`
	&.disabled {
		opacity: 0.6;
	}
`;

const StyledTextColor = styled.div`
	font-size: 1rem;
	color: ${THEME.colors.white};
`;

type PropsColorDto = {
	color: string;
	title: string;
	marginTop?: string;
	keyPopup: string;
	placement?: 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight' | string;
	disabled?: boolean;
	[other: string]: any;
};

const CustomColorItem = (props: PropsColorDto) => {
	const {
		color,
		title,
		keyPopup,
		placement = 'bottomRight',
		disabled = false,
		marginTop,
		...other
	} = props;

	const { setDetailWidget } = useContext(WidgetBuilderContext);

	const onChangeColor = (colorChange: string) => {
		const resultChange: any = {};
		if (keyPopup === KEY_HIGH_LIGHT_GROUP) {
			resultChange[GROUP_HIGH_LIGHT.highlight] = colorChange;
			resultChange[GROUP_HIGH_LIGHT.iconUpload] = colorChange;
		} else {
			resultChange[keyPopup] = colorChange;
		}

		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				colors: {
					...prev.metadata.colors,
					...resultChange,
				},
			},
		}));
	};

	return (
		<StyledCustomColor marginTop={marginTop} {...other}>
			<CustomPickerColor
				disabled={disabled}
				onChange={onChangeColor}
				color={color}
				placement={placement}>
				<StyledBoxColor className={`${disabled ? 'disabled' : ''}`}>
					<StyledResultColor bgColor={color} />
					<IconExpandMore />
				</StyledBoxColor>
			</CustomPickerColor>
			<StyledContentColor className={disabled ? 'disabled' : ''}>
				<StyledTextColor>{title}</StyledTextColor>
				<StyledTextColor>{color.toUpperCase()}</StyledTextColor>
			</StyledContentColor>
		</StyledCustomColor>
	);
};

export default CustomColorItem;
