export const convertBase64toPDF = (base64: string) => {
	if (!base64) return '';
	const byteArray = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
	const blob = new Blob([byteArray], { type: 'application/octet-stream' });
	const url = window.URL.createObjectURL(blob);
	return url;
};

export const downloadFile = (base64: string, fileName: string) => {
	const url = convertBase64toPDF(base64);

	const anchorElem = document.createElement('a');
	anchorElem.setAttribute('style', 'display: none');
	anchorElem.href = url;
	anchorElem.download = fileName;
	document.body.appendChild(anchorElem);
	anchorElem.click();
	document.body.removeChild(anchorElem);
	setTimeout(() => {
		window.URL.revokeObjectURL(url);
	}, 1000);
};
