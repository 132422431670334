import styled from 'styled-components';
import {
    color,
    typography,
    position,
    border,
    background,
    space,
    flexbox,
    grid,
    layout,
    shadow,
}             from 'styled-system';

type WrapperProps = {
    borderBottom?: string;
    borderTop?: string;
    borderLeft?: string;
    maxWidth?: string;
    border?: string;
    margin?: string;
    ml?: string | number;
    mt?: string | number;
    mr?: string | number;
    mb?: string | number;
    m?: string | number;
    p?: string | number;
    pt?: string | number;
    pb?: string | number;
    pl?: string | number;
    pr?: string | number;
    px?: string | number;
    py?: string | number;
    bg?: string;
    width?: string | number;
    height?: string | number;
    minWidth?: string | number;
    minHeight?: string | number;
    lineHeight?: number;
    justifyContent?: string;
    alignItems?: string;
    display?: string;
    position?: string;
    boxSizing?: string;
    onClick?: ( event: React.MouseEvent<HTMLButtonElement> ) => void;
};

const Wrapper = styled.div<WrapperProps>`
    box-sizing: ${ ( { boxSizing } ) => boxSizing };
    ${ color }
    ${ typography }
    ${ position }
    ${ border }
    ${ background }
    ${ space }
    ${ flexbox }
    ${ grid }
    ${ layout }
    ${ shadow }
`;

Wrapper.defaultProps = {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    boxSizing: 'border-box',
    height: '100%'
};

export default Wrapper;
