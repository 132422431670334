import { IconClose } from '@assets/icons';
import AlbumModal from '@components/Content/AlbumManager/AlbumModal';
import { FULL_RECORD, TYPE_ACTIONS, TYPE_FORMAT_DATE, UNIT_VALUE } from '@constants/common';
import { FORM_CONTENT_DETAIL, VAL_UN_RATE } from '@constants/content/contentLibrary';
import {
	DEFAULT_RANGE_RATING,
	FILTER_PUBLISHED,
	NAME_FILTER_CONTENT,
	RIGHT_CLEAR_FILTER,
	SAVED_CONTENT_FILTER_TYPES,
	STATUS_FILTER,
	TYPE_FILTER,
} from '@constants/filter/filterContent';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import LoadingWrapper from '@cores/LoadingWrapper';
import { filterGalleryUnAssign } from '@helpers/arrayHelper';
import { StateCampaign } from '@models/campaign';
import { ContentRootStateDto, CustomFieldItemDto } from '@models/content/contentLibrary/store';
import {
	FieldDefaultForm,
	FilterItemDto,
	FilterTypeDto,
	LogoDetectionLabelsDto,
	ValueTagDto,
	VideoLabelDetectionDto,
} from '@models/filter';
import { RefResetFilterContent } from '@models/filter/ref';
import { SettingRootStateDto } from '@models/settings/setting';
import {
	deleteFilterEnd,
	deleteFilterRequest,
	fetchContentCustomFieldsRequest,
	fetchContentLocationRequest,
	fetchContentTagsRequest,
	fetchRatioResolutionRequest,
	getFilterRequest,
	getFolderSettings,
	getListContentCampaignAPI,
	getSegment,
	saveFilterEnd,
	saveFilterRequest,
	storeContentData,
	updateFilterContentDetailEnd,
	updateFilterContentDetailRequest,
} from '@stores/actions';
import {
	StyledDivider,
	StyledIcon,
	StyledPopconfirm,
	StyledTitle,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledContentPopover, StyledSelect } from '@styled/Content/ContentLibrary/FilterStyled';
import {
	StyledFilterContent,
	StyledFooterFilter,
	StyledForm,
	StyledInput,
	StyledLineVertical,
	StyledTabs,
	StyledWrapperFilter,
} from '@styled/Filter/FilterContentStyled';
import { addMonths } from '@utils/common';
import { validateFilterName } from '@utils/formValidation';
import { Affix, Button, Col, Input, Row, message } from 'antd';
import _, { uniq } from 'lodash';
import moment from 'moment-timezone';
import React, {
	Ref,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ROLE } from '@constants/settings';
import FilterContentContext, { FilterContentContextTypes } from '@contexts/Filter/filterContent';
import { ConfirmModal } from '@cores/Modal';
import { getUser } from '@helpers/userHelpers';
import { TagItemDto } from '@models/content/contentLibrary/summary';
import { checkListNotExist } from '@utils/content';
import DetailFilter from './ContentFilter';
import SelectedFilterInfo from './SelectedFilterInfo';
import SelectorSavedFilter from './SelectorSavedFilter';
import SubFilterAlbumInfo from './SubFilterAlbumInfo';
import { OPERATOR } from '@constants/social_search';

const { TabPane } = StyledTabs;

const { Option } = StyledSelect;

const TYPE_TAB = {
	default: 'default',
	selectFilter: 'selectFilter',
};

const FORMAT_DATE = TYPE_FORMAT_DATE.MONTH_DAY;

type PropsFilterContent = {
	isHidden: boolean;
	closeFilters: () => void;
	handleFilterContent: (val: FilterTypeDto) => void;
	handleResetAlbum: () => void;
	isVerify?: boolean;
	resetContentFilters: () => void;
};

const FilterContent = forwardRef((props: PropsFilterContent, ref: Ref<RefResetFilterContent>) => {
	const { t } = useTranslation();

	const {
		isHidden,
		closeFilters,
		handleFilterContent,
		handleResetAlbum,
		isVerify,
		resetContentFilters,
	} = props;

	const user = getUser();
	const { role } = user;

	const isEReviewer = role.toLowerCase() === ROLE.EREVIEWER.value.toLowerCase();
	const dispatch = useDispatch();

	const { state = {}, pathname } = useLocation<any>();
	const history = useHistory();

	const {
		filterList = [],
		actionFilterStatus,
		fetchingLocation,
		fetchingContentCustomFields,
		fetchingFilter,
		savedFilter,
		tags,
		contentLocation,
	} = useSelector((state: ContentRootStateDto) => state.content);
	const { folders = [] } = useSelector((state: SettingRootStateDto) => state.settings.content);

	const { segmentList = [] } = useSelector((state: any) => state.creator);
	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);

	const { flags: flagFull, labels: labelFull, googleAIHashtags: googleAIHashtagFull } = tags;

	const DEFAULT_FORM: FieldDefaultForm & any = {
		[NAME_FILTER_CONTENT.QUERY_OPERATOR]: OPERATOR.AND,
		[NAME_FILTER_CONTENT.MEDIA_TYPE]: TYPE_FILTER.ALL,
		[NAME_FILTER_CONTENT.RANGE_RATING]: [0, 5],
		[NAME_FILTER_CONTENT.NOT_RATE]: false,
		[NAME_FILTER_CONTENT.TAGS]: [],
		[NAME_FILTER_CONTENT.EXCLUDED_TAGS]: [],
		[NAME_FILTER_CONTENT.STATUS]: STATUS_FILTER.ANY,
		[NAME_FILTER_CONTENT.RIGHT_CLEAR]: isVerify
			? [RIGHT_CLEAR_FILTER.APPROVED, RIGHT_CLEAR_FILTER.CUSTOM_TERMS]
			: [],
		[NAME_FILTER_CONTENT.PUBLISHED]: FILTER_PUBLISHED.ANY,
		[NAME_FILTER_CONTENT.DOWNLOADED]: FILTER_PUBLISHED.ANY,
		[NAME_FILTER_CONTENT.GALLERIES]: [],
		[NAME_FILTER_CONTENT.CAMPAIGNS]: [],

		[NAME_FILTER_CONTENT.UPLOAD_DATE]: [],
		[NAME_FILTER_CONTENT.UPLOAD_LOCATION]: [],
		[NAME_FILTER_CONTENT.KEYWORD]: '',
		[NAME_FILTER_CONTENT.CREATORS]: '',
		[NAME_FILTER_CONTENT.CREATOR_SEGMENT]: [],
		[NAME_FILTER_CONTENT.ASSOCIATED_REWARDS]: [],
		[NAME_FILTER_CONTENT.ASPECT_RATIOS]: [],
		[NAME_FILTER_CONTENT.RESOLUTION_QUALITY]: [],
		[NAME_FILTER_CONTENT.SHOT_LOCATION]: [],
		[NAME_FILTER_CONTENT.DEVICE_MAKES]: [],
		[NAME_FILTER_CONTENT.DEVICE_MODELS]: [],
		[NAME_FILTER_CONTENT.CUSTOM_FIELDS]: [],
	};

	const [form] = StyledForm.useForm();

	const refSidebar = useRef<HTMLDivElement | null>(null);
	const refElmScroll = useRef<HTMLDivElement | null>(null);

	const [filterSelected, setFilterSelected] = useState<FilterItemDto | null>(null);
	const [typeConfirm, setTypeConfirm] = useState<string>('');
	const [filterName, setFilterName] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filterItemRequest, setFilterItemRequest] = useState<FilterItemDto | null>(null);
	const [visibleCreateAlbum, setVisibleCreateAlbum] = useState<boolean>(false);
	const [isFindSimilar, setIsFindSimilar] = useState<boolean>(false);
	const [hasChangeFilter, setHasChangeFilter] = useState<boolean>(false);
	const [visibleModalConfirm, setVisibleModalConfirm] = useState<boolean>(false);
	const [blockLoading, setBlockLoading] = useState<boolean>(false);

	const handleResetForm = (clickClear?: boolean, noResetAction?: boolean) => {
		form.resetFields();
		if (clickClear) {
			onCheckVerify();
		}

		setFilterSelected(null);
		if (!noResetAction) {
			handleResetAction();
		}
		setIsFindSimilar(false);
	};

	const handleResetAction = () => {
		handleActionConfirm('');
		setBlockLoading(false);
		setFilterName('');
		if (typeConfirm !== TYPE_ACTIONS.REMOVE || filterSelected) {
			setHasChangeFilter(false);
		}
		setFilterItemRequest(null);
	};

	const handleUpdateFilterSelect = () => {
		if (savedFilter) {
			setFilterSelected(savedFilter);
		}
	};

	const onCheckVerify = () => {
		if (isVerify) {
			const values = form.getFieldsValue();

			const otherFieldForm: any = {
				rightClear: [RIGHT_CLEAR_FILTER.APPROVED, RIGHT_CLEAR_FILTER.CUSTOM_TERMS],
			};

			form.setFieldsValue({ ...values, ...otherFieldForm });
			form.submit();
		} else {
			form.resetFields();
		}
	};

	useImperativeHandle(ref, () => ({ clearFilterUsingRef: handleResetForm }));

	useEffect(() => {
		onCheckVerify();
	}, [isVerify]);

	useEffect(() => {
		if (state && !_.isEmpty(state)) {
			const otherFieldForm: any = {};

			if (state?.idCampaign) {
				otherFieldForm[NAME_FILTER_CONTENT.CAMPAIGNS] = [state?.idCampaign?.toString()];
			}

			if (state?.needReview) {
				otherFieldForm[NAME_FILTER_CONTENT.STATUS] = STATUS_FILTER.NEEDS_REVIEW;
			}

			if (state?.idSegment) {
				otherFieldForm[NAME_FILTER_CONTENT.CREATOR_SEGMENT] = [
					state?.idSegment?.toString(),
				];
			}

			if (state.customTerms && state.verified) {
				otherFieldForm[NAME_FILTER_CONTENT.RIGHT_CLEAR] = [
					RIGHT_CLEAR_FILTER.APPROVED,
					RIGHT_CLEAR_FILTER.CUSTOM_TERMS,
				];
			}

			if (state?.locations) {
				const valLocation = JSON.stringify({
					country: state?.locations?.country,
					state: state?.locations?.state,
				});
				otherFieldForm[NAME_FILTER_CONTENT.UPLOAD_LOCATION] = [valLocation];
			}

			if (state?.rating || state?.rating === VAL_UN_RATE) {
				let noRate = false;
				let rangeRate = [state?.rating, state?.rating];

				if (state?.rating === VAL_UN_RATE) {
					rangeRate = DEFAULT_RANGE_RATING;
					noRate = true;
				}
				otherFieldForm[NAME_FILTER_CONTENT.NOT_RATE] = noRate;
				otherFieldForm[NAME_FILTER_CONTENT.RANGE_RATING] = [...rangeRate];
			}

			if (!_.isEmpty(otherFieldForm)) {
				form.setFieldsValue({ ...otherFieldForm });

				form.submit();
			}

			history.replace({ state: null });
		}
	}, [state]);

	useEffect(() => {
		if (filterSelected && filterList.length > 0) {
			const itemFind = filterList.find((f) => f.id === filterSelected.id);
			if (itemFind) {
				setFilterSelected(itemFind);
			}
		}
	}, [filterList]);

	useEffect(() => {
		dispatch(getListContentCampaignAPI());
		dispatch(getFolderSettings());
		dispatch(getSegment());
		dispatch(
			getFilterRequest({
				page: 1,
				maxRecords: FULL_RECORD,
			}),
		);
		dispatch(fetchContentLocationRequest());
		dispatch(fetchContentCustomFieldsRequest());
		dispatch(fetchContentTagsRequest());
		dispatch(fetchRatioResolutionRequest());
	}, []);

	const handleAddListTag = async (
		labels: string[],
		flags: string[],
		googleAIHashtags: string[],
	) => {
		const listLabelNoExist = await getListNoExist(
			labelFull,
			uniq(labels),
			FORM_CONTENT_DETAIL.LABELS,
		);
		const listFlagsNoExist = await getListNoExist(
			flagFull,
			uniq(flags),
			FORM_CONTENT_DETAIL.FLAGS,
		);
		const listGoogleHashtagNoExist = await getListNoExist(
			googleAIHashtagFull,
			uniq(googleAIHashtags),
			FORM_CONTENT_DETAIL.GOOGLE_AI_HASH_TAGS,
		);

		dispatch(
			storeContentData({
				tags: {
					...tags,
					labels: [...labelFull, ...listLabelNoExist],
					flags: [...flagFull, ...listFlagsNoExist],
					googleAIHashtags: [...googleAIHashtagFull, ...listGoogleHashtagNoExist],
				},
			}),
		);
	};

	useEffect(() => {
		if (filterSelected) {
			const {
				id,
				name,
				galleries,
				campaigns,
				creatorSegments,
				labels,
				flags,
				googleAIHashtags,
				excludedLabels,
				excludedFlags,
				excludedGoogleAIHashtags,
				contacted,
				locations = [],
				unContacted,
				gallery,
				needReview,
				published,
				sortBy,
				verified,
				sortDirection,
				from,
				to,
				customTerms,
				downloaded,
				mediaType = '',
				rating,
				toRating,
				logoDetectionLabels,
				videoDetectionLabels,
				isAdvancedSearch,
				folders: folderFilters,
				customFields,
				queryOperator,
				...otherFilter
			} = filterSelected;

			const galleryList = filterGalleryUnAssign(folders, true);

			const renderOptionFilter = (listFilter: Array<string | number>, listAll: any[]) => {
				return listFilter?.filter((item: string | number) =>
					listAll?.some((gl: any) => gl?.id?.toString() === item?.toString()),
				);
			};

			handleAddListTag(
				[...(labels || []), ...(excludedLabels || [])],
				[...(flags || []), ...(excludedFlags || [])],
				[...(googleAIHashtags || []), ...(excludedGoogleAIHashtags || [])],
			);

			const objSelectMulti: any = {
				galleries: renderOptionFilter(galleries || [], galleryList),
				campaigns: renderOptionFilter(campaigns || [], campaignListContent),
				creatorSegments: renderOptionFilter(creatorSegments || [], segmentList),
				labels,
				flags,
				googleAIHashtags,
				excludedLabels,
				excludedFlags,
				excludedGoogleAIHashtags,
				logoDetectionLabels: logoDetectionLabels?.map((item: LogoDetectionLabelsDto) => {
					return item.logoLabel;
				}),
				videoDetectionLabels: videoDetectionLabels?.map((item: VideoLabelDetectionDto) => {
					return item.videoLabel;
				}),
			};
			const arrKey = Object.keys(objSelectMulti);
			const listFields: FieldDefaultForm = {
				...DEFAULT_FORM,
				...otherFilter,
				[NAME_FILTER_CONTENT.MEDIA_TYPE]: mediaType || TYPE_FILTER.ALL,
				[NAME_FILTER_CONTENT.RIGHT_CLEAR]: [],
				[NAME_FILTER_CONTENT.GROUP_CUSTOM_FIELDS]: [],
				notRate: false,
				[NAME_FILTER_CONTENT.QUERY_OPERATOR]: queryOperator || OPERATOR.AND,
			};
			const listValueTag: string[] = [];
			const listValueExcludedTag: string[] = [];

			let valLocation: string[] = [];
			let arrDate: any[] = [];

			const arrKeyTag = [
				NAME_FILTER_CONTENT.LABELS,
				NAME_FILTER_CONTENT.FLAGS,
				NAME_FILTER_CONTENT.GOOGLE_AI_TAGS,
			];

			const arrKeyCheck = [
				{ group: NAME_FILTER_CONTENT.LABELS, keyCheck: NAME_FILTER_CONTENT.LABELS },
				{
					group: NAME_FILTER_CONTENT.LABELS,
					keyCheck: NAME_FILTER_CONTENT.EXCLUDED_LABELS,
				},
				{ group: NAME_FILTER_CONTENT.FLAGS, keyCheck: NAME_FILTER_CONTENT.FLAGS },
				{ group: NAME_FILTER_CONTENT.FLAGS, keyCheck: NAME_FILTER_CONTENT.EXCLUDED_FLAGS },
				{
					group: NAME_FILTER_CONTENT.GOOGLE_AI_TAGS,
					keyCheck: NAME_FILTER_CONTENT.GOOGLE_AI_TAGS,
				},
				{
					group: NAME_FILTER_CONTENT.GOOGLE_AI_TAGS,
					keyCheck: NAME_FILTER_CONTENT.EXCLUDED_GOOGLE_AI_TAGS,
				},
			];

			arrKey?.forEach((key) => {
				const checkFind = arrKeyCheck.find((ck) => ck.keyCheck === key);

				if (checkFind) {
					const isExcluded = !arrKeyTag.includes(key);
					objSelectMulti[key]?.forEach((item: string) => {
						const valueTag = JSON.stringify({ val: item, group: checkFind.group });
						if (isExcluded) {
							listValueExcludedTag.push(valueTag);
						} else {
							listValueTag.push(valueTag);
						}
					});
				} else {
					listFields[key] = objSelectMulti[key]?.map((item: any) => item?.toString());
				}
			});

			listFields.customFields = (customFields || [])?.map((cus) => ({
				field: JSON.stringify({ name: cus?.name, type: cus?.type || null }),
				values: cus.values || [],
			}));

			if (!toRating) {
				if (rating) {
					listFields.rangeRating = [0, rating];
				} else {
					listFields.rangeRating = [...DEFAULT_RANGE_RATING];
				}
				if (toRating !== 0 && rating === 0) {
					listFields.notRate = true;
				}
			} else {
				listFields.rangeRating = [rating || 0, toRating];
			}

			if (typeof needReview === 'boolean') {
				listFields[NAME_FILTER_CONTENT.STATUS] = needReview
					? STATUS_FILTER.NEEDS_REVIEW
					: STATUS_FILTER.REVIEWED;
			}
			if (typeof published === 'boolean') {
				listFields[NAME_FILTER_CONTENT.PUBLISHED] = published
					? FILTER_PUBLISHED.YES
					: FILTER_PUBLISHED.NO;
			}

			if (typeof downloaded === 'boolean') {
				listFields[NAME_FILTER_CONTENT.DOWNLOADED] = downloaded
					? FILTER_PUBLISHED.YES
					: FILTER_PUBLISHED.NO;
			}

			if (unContacted) {
				listFields.rightClear.push(RIGHT_CLEAR_FILTER.PENDING);
				listFields.rightClear.push(RIGHT_CLEAR_FILTER.NONE);
			} else {
				if (typeof contacted === 'boolean') {
					if (contacted) {
						listFields.rightClear.push(RIGHT_CLEAR_FILTER.PENDING);
					} else {
						listFields.rightClear.push(RIGHT_CLEAR_FILTER.NONE);
					}
				}
			}
			if (verified) {
				listFields.rightClear.push(RIGHT_CLEAR_FILTER.APPROVED);
			}
			if (customTerms) {
				listFields.rightClear.push(RIGHT_CLEAR_FILTER.CUSTOM_TERMS);
			}

			if (locations?.length > 0) {
				const locationFull: string[] = [];
				contentLocation.forEach((lo) => {
					if (!lo?.state) {
						if (lo?.name) {
							locationFull.push(
								JSON.stringify({
									country: lo?.abbreviation,
									state: null,
								}),
							);
						}
					} else {
						lo.state?.forEach((st) => {
							locationFull.push(
								JSON.stringify({
									country: lo?.abbreviation,
									state: st?.abbreviation || null,
								}),
							);
						});
					}
				});
				// remove location no exist
				valLocation = locations
					.map((lo) =>
						JSON.stringify({
							country: lo?.country,
							state: lo?.state,
						}),
					)
					.filter((str) => locationFull.includes(str));
			}

			if (from && to) {
				arrDate = [
					moment(new Date(from), FORMAT_DATE).utc(),
					moment(new Date(to), FORMAT_DATE).utc(),
				];
			}

			if (filterSelected?.type === SAVED_CONTENT_FILTER_TYPES.SYSTEM_OUTDATED_CONTENT) {
				arrDate = [
					moment(new Date('January 01, 2020'), FORMAT_DATE),
					moment(addMonths(new Date(), -6), FORMAT_DATE),
				];
			}

			form.setFieldsValue({
				...otherFilter,
				...listFields,
				[NAME_FILTER_CONTENT.TAGS]: listValueTag,
				[NAME_FILTER_CONTENT.EXCLUDED_TAGS]: listValueExcludedTag,
				[NAME_FILTER_CONTENT.UPLOAD_LOCATION]: valLocation,
				[NAME_FILTER_CONTENT.UPLOAD_DATE]: arrDate,
			});
			if (isEReviewer) {
				setIsFindSimilar(!!isAdvancedSearch);
			}
		}
	}, [filterSelected]);

	useEffect(() => {
		if (typeConfirm) {
			if (actionFilterStatus === IN_PROGRESS && !blockLoading) {
				setIsLoading(true);
			}

			if (actionFilterStatus === SUCCEEDED) {
				if (typeConfirm === TYPE_ACTIONS.OVERWRITE || typeConfirm === TYPE_ACTIONS.EDIT) {
					message.success(t('content_management.notification.review_success'));
					handleUpdateFilterSelect();
				}
				if (typeConfirm === TYPE_ACTIONS.CREATE_FILTER) {
					handleUpdateFilterSelect();
					message.success(t('content_management.notification.save_filter_success'));
				}
				if (typeConfirm === TYPE_ACTIONS.REMOVE) {
					message.success(t('content_management.notification.delete_filter_success'));
					handleResetAlbum();
					if (filterItemRequest?.id === filterSelected?.id) {
						handleResetForm(undefined, true);
						handleApplyFilter();
					}
				}
			}

			if (actionFilterStatus === FAILED || actionFilterStatus === SUCCEEDED) {
				handleResetAction();
				setIsLoading(false);

				if (typeConfirm === TYPE_ACTIONS.CREATE_FILTER) {
					dispatch(saveFilterEnd());
				}
				if (typeConfirm === TYPE_ACTIONS.REMOVE) {
					dispatch(deleteFilterEnd());
				}
				if (typeConfirm === TYPE_ACTIONS.EDIT || typeConfirm === TYPE_ACTIONS.OVERWRITE) {
					dispatch(updateFilterContentDetailEnd());
				}
			}
		}
	}, [actionFilterStatus]);

	const handleSelectFilter = (key: number) => {
		if (key === UNIT_VALUE.SM_1) {
			handleActionConfirm(TYPE_ACTIONS.CREATE_FILTER);
		} else {
			if (typeConfirm) {
				handleActionConfirm('');
			}
			const filterItem: FilterItemDto | null =
				filterList?.find((item) => item?.id === key) || null;
			if (filterItem) {
				const { id, name, type, folders: folderFilters, ...filterRequest } = filterItem;
				const filterFormat = handleClearFieldFilter(filterRequest);
				handleFilterContent(filterFormat);
				setFilterSelected(filterItem);
				setHasChangeFilter(false);
			}
		}
	};

	const formatDataRequestFilter = (values: FieldDefaultForm) => {
		const {
			tags,
			galleries,
			campaigns = [],
			creatorSegments = [],
			rightClear = [],
			status,
			published,
			rewards = [],
			locations,
			mediaType,
			downloaded,
			aspectRatios,
			resolutions,
			uploadDate: uploadDateDefault,
			notRate,
			resolutionQuality,
			rangeRating,
			excludedTags = [],
			deviceMakers = [],
			deviceModels = [],
			shotCountries = [],
			customFields,
			...otherRequest
		} = values;
		let { uploadDate = [] } = values;

		const dataRequest: FilterTypeDto = {
			needReview: null,
			published: null,
			downloaded: null,
		};
		const arrLabel: string[] = [];
		const arrFlag: string[] = [];
		const arrGoogleAI: string[] = [];
		const arrExcludedLabel: string[] = [];
		const arrExcludedFlag: string[] = [];
		const arrExcludedGoogleAI: string[] = [];

		const formatValueTag = (
			fields: string[],
			arrLb: string[],
			arrFl: string[],
			arrGA: string[],
		) => {
			fields?.forEach((item) => {
				try {
					const val: ValueTagDto = JSON.parse(item);
					if (val?.group === NAME_FILTER_CONTENT.LABELS) {
						arrLb.push(val?.val);
					}
					if (val?.group === NAME_FILTER_CONTENT.FLAGS) {
						arrFl.push(val?.val);
					}
					if (val?.group === NAME_FILTER_CONTENT.GOOGLE_AI_TAGS) {
						arrGA.push(val?.val);
					}
				} catch (error) {
					console.log(error);
				}
			});
		};
		formatValueTag(tags, arrLabel, arrFlag, arrGoogleAI);
		formatValueTag(excludedTags || [], arrExcludedLabel, arrExcludedFlag, arrExcludedGoogleAI);

		const objArr: any = {
			labels: arrLabel,
			flags: arrFlag,
			googleAIHashtags: arrGoogleAI,
			excludedLabels: arrExcludedLabel,
			excludedFlags: arrExcludedFlag,
			excludedGoogleAIHashtags: arrExcludedGoogleAI,
			galleries,
			campaigns,
			creatorSegments,
			aspectRatios,
			resolutions,
			resolutionQuality,
			deviceMakers,
			deviceModels,
			shotCountries,
		};

		Object.keys(objArr)?.forEach((item: string) => {
			if (objArr[item]?.length > 0) {
				dataRequest[item] = objArr[item];
			}
		});

		Object.keys(otherRequest)?.forEach((item) => {
			let val = otherRequest[item];
			if (typeof val === 'string') {
				val = val?.trim();
			}
			if (
				(item === NAME_FILTER_CONTENT.LOGOS_DETECTED ||
					item === NAME_FILTER_CONTENT.VIDEO_LABEL_DETECTED) &&
				val
			) {
				const convertedData: any[] = [];
				val.forEach((itemValue: string) => {
					convertedData.push({
						[item === NAME_FILTER_CONTENT.LOGOS_DETECTED ? 'logoLabel' : 'videoLabel']:
							itemValue,
					});
				});
				dataRequest[item] = convertedData;
			} else {
				dataRequest[item] = val;
			}
		});

		if (rangeRating) {
			dataRequest.rating = rangeRating[0];
			dataRequest.toRating = rangeRating[1];
		}

		if (notRate) {
			dataRequest.rating = DEFAULT_RANGE_RATING[0];
			dataRequest.toRating = null;
		}

		if (status === STATUS_FILTER.NEEDS_REVIEW) {
			dataRequest.needReview = true;
		}
		if (status === STATUS_FILTER.REVIEWED) {
			dataRequest.needReview = false;
		}
		if (published === FILTER_PUBLISHED.YES) {
			dataRequest.published = true;
		}
		if (published === FILTER_PUBLISHED.NO) {
			dataRequest.published = false;
		}
		if (downloaded === FILTER_PUBLISHED.YES) {
			dataRequest.downloaded = true;
		}
		if (downloaded === FILTER_PUBLISHED.NO) {
			dataRequest.downloaded = false;
		}

		if (customFields && customFields.length > 0) {
			const arrCustomFields: CustomFieldItemDto[] = [];
			customFields.forEach((cus) => {
				const obj = JSON.parse(cus.field);
				const itemCheck = arrCustomFields.find(
					(f) => f.type === obj.type && f.name === obj.name,
				);
				if (!itemCheck) {
					arrCustomFields.push({ ...obj, values: cus.values });
				}
			});
			if (arrCustomFields.length === customFields.length) {
				dataRequest.customFields = arrCustomFields;
			} else {
				message.error(t('filter.filter_content.each_different_custom_fields'));
				return null;
			}
		}

		if (filterSelected?.type === SAVED_CONTENT_FILTER_TYPES.SYSTEM_OUTDATED_CONTENT) {
			uploadDate = [
				new Date('December 01, 2020').getTime(),
				addMonths(new Date(), -6).getTime(),
			];
			dataRequest.verified = false;
			dataRequest.customTerms = false;
			dataRequest.contacted = false;
		}
		if (uploadDate?.length > 0) {
			const start = new Date(uploadDate[0]);
			const end = new Date(uploadDate[1]);
			const startUtc = moment(start).utc().startOf('day');
			const endUtc = moment(end).utc().endOf('day');

			dataRequest.from = startUtc?.valueOf();
			dataRequest.to = endUtc.valueOf();
		}

		if (mediaType !== TYPE_FILTER.ALL) {
			dataRequest.mediaType = mediaType;
		}
		if (locations) {
			try {
				dataRequest.locations = locations.map((lo) => JSON.parse(lo));
			} catch (error) {
				console.log(error);
			}
		}
		if (rightClear?.includes(RIGHT_CLEAR_FILTER.APPROVED)) {
			dataRequest.verified = true;
		}
		if (rightClear?.includes(RIGHT_CLEAR_FILTER.CUSTOM_TERMS)) {
			dataRequest.customTerms = true;
			if (!Object.keys(dataRequest)?.includes('verified')) {
				dataRequest.verified = false;
			}
		}
		if (
			!rightClear?.includes(RIGHT_CLEAR_FILTER.NONE) ||
			!rightClear?.includes(RIGHT_CLEAR_FILTER.PENDING)
		) {
			if (rightClear?.includes(RIGHT_CLEAR_FILTER.NONE)) {
				dataRequest.contacted = false;
			}
			if (rightClear?.includes(RIGHT_CLEAR_FILTER.PENDING)) {
				dataRequest.contacted = true;
			}
		}

		if (filterSelected && !_.isNull(filterSelected.flagged)) {
			dataRequest.flagged = filterSelected.flagged;
		}

		if (dataRequest.verified === false && dataRequest.contacted === false) {
			delete dataRequest.contacted;
		}

		if (isEReviewer && isFindSimilar) {
			dataRequest.isAdvancedSearch = isFindSimilar;
		}

		return dataRequest;
	};

	const getListNoExist = (list: TagItemDto[], listCheck: string[], type: string) => {
		return checkListNotExist(
			list.map((item) => item?.value),
			listCheck || [],
		).map((str, idx) => ({
			key: str,
			value: str,
			type,
			id: Math.random() * 10 ** 20 + idx,
		}));
	};

	const handleClearFieldFilter = (fields: FilterTypeDto) => {
		const { rating, toRating, ...otherField } = fields;

		const result: FilterTypeDto = {};

		const isDefaultRate =
			rating === DEFAULT_RANGE_RATING[0] && toRating === DEFAULT_RANGE_RATING[1];
		if (!isDefaultRate) {
			result.rating = rating;
			result.toRating = toRating;
		}

		Object.keys(otherField)?.forEach((key) => {
			const val = otherField[key];
			if (val && typeof val === 'object') {
				if (Object.keys(val).length > 0) {
					result[key] = otherField[key];
				}
			} else if (val || typeof val === 'boolean' || typeof val === 'number') {
				result[key] = otherField[key];
			}
		});
		if (Object.keys(result).length === 1 && result?.queryOperator === OPERATOR.AND) {
			delete result.queryOperator;
		}

		return result;
	};

	const onValuesChange = (val: any, val2: any) => {
		const key = Object.keys(val)[0];

		if (key === NAME_FILTER_CONTENT.RANGE_RATING) {
			const dataRate = filterSelected
				? [filterSelected.rating, filterSelected.toRating]
				: DEFAULT_RANGE_RATING;
			if (
				val.rangeRating.length > 1 &&
				(val.rangeRating[0] !== dataRate[0] || val.rangeRating[1] !== dataRate[1])
			) {
				setHasChangeFilter(true);
			}
		} else {
			setHasChangeFilter(true);
		}
	};

	const handleFinishForm = (values: any) => {
		const filterRequest = formatDataRequestFilter(values);

		if (filterRequest) {
			const filterFormat = handleClearFieldFilter(filterRequest);

			handleFilterContent(filterFormat);
		}
	};

	const handleApplyFilter = () => {
		form.submit();
	};

	const handleOverwriteFilter = () => {
		handleEditFilter(filterSelected?.name || '');
		setTypeConfirm(TYPE_ACTIONS.OVERWRITE);
	};

	const handleEditFilter = (name: string) => {
		onSaveFilter(name);
	};

	const handleActionConfirm = (type: string) => {
		setTypeConfirm(type);
		setVisibleModalConfirm(!!type);
	};

	const handleDeleteFilter = () => {
		setBlockLoading(true);

		dispatch(deleteFilterRequest([filterItemRequest?.id]));
	};

	const onSaveFilter = (name?: string) => {
		if (hasChangeFilter) {
			setBlockLoading(true);
			handleApplyFilter();
		}
		setVisibleModalConfirm(false);

		const values: FieldDefaultForm = form.getFieldsValue();
		const { rightClear } = values;

		const filterRequest: FilterTypeDto | null = formatDataRequestFilter(values);

		if (!filterRequest) {
			return;
		}
		const filterFormat = handleClearFieldFilter(filterRequest);

		const rightClearNone = rightClear?.includes(RIGHT_CLEAR_FILTER.NONE);
		const rightClearPending = rightClear?.includes(RIGHT_CLEAR_FILTER.PENDING);
		// unContacted: Only to distinguish between selected and unselected, does not affect the value
		// (has NONE and PENDING) same (!NONE and !PENDING)
		if (rightClearNone && rightClearPending) {
			filterFormat.unContacted = true;
		}

		const dataRequest = { ...filterFormat, name: name || filterName };

		if (name) {
			const { id, ...other } = filterSelected || {};
			dispatch(
				updateFilterContentDetailRequest({
					id: filterSelected?.id,
					...dataRequest,
				}),
			);
			return;
		}
		if (filterList) {
			const errMsgsTemp = validateFilterName(filterList, filterName);

			if (errMsgsTemp) {
				message.error(t(errMsgsTemp));
			} else if (_.isEmpty(filterFormat)) {
				message.error(t('content_management.notification.save_filter_warning'));
			} else {
				dispatch(saveFilterRequest(dataRequest));
			}
		}
	};
	const handleClosePopconfirm = () => {
		handleActionConfirm('');
		setFilterItemRequest(null);
		if (filterName) {
			setFilterName('');
		}
	};

	const handleSelectFilterRemove = (filter: FilterItemDto) => {
		handleActionConfirm(TYPE_ACTIONS.REMOVE);
		setFilterItemRequest(filter);
	};

	const formSaveFilter = (
		<StyledContentPopover>
			<StyledTitle margin="0 0 6px 0">
				{t('content_management.filter.filter_set')}
			</StyledTitle>
			<StyledInput
				className="no_bg"
				placeholder="Please input filter's name"
				value={filterName}
				onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
					setFilterName(evt.target.value);
				}}
			/>
		</StyledContentPopover>
	);

	const otherPropModal = useMemo(() => {
		const result: any = {
			cancelText: t('button.cancel'),
			okText: t('button.yes'),
			onClickBtnCancel: handleClosePopconfirm,
			onSubmit: handleDeleteFilter,
		};

		if (typeConfirm === TYPE_ACTIONS.CREATE_FILTER) {
			result.okText = t('button.save');
			result.onSubmit = onSaveFilter;
		}
		if (typeConfirm === TYPE_ACTIONS.EDIT) {
			result.okText = t('button.save');
			result.onSubmit = () => handleEditFilter(filterName);
		}
		return result;
	}, [typeConfirm, filterName]);

	const renderContentPopupConfirm = () => {
		if (typeConfirm === TYPE_ACTIONS.REMOVE) {
			if (filterItemRequest?.id === filterSelected?.id) {
				return <>{t('content_management.confirm.delete_filter')}</>;
			}
			return (
				<>
					{t('content_management.confirm.delete_filter_name', {
						name: filterItemRequest?.name,
					})}
				</>
			);
		}
		if (typeConfirm === TYPE_ACTIONS.CREATE_FILTER || typeConfirm === TYPE_ACTIONS.EDIT) {
			return formSaveFilter;
		}
		return '';
	};

	const otherPropsStyle = () => {
		if (!filterSelected) {
			return {};
		}
		return {
			height: 'calc(100% - 42px)',
			overflow: 'hidden auto',
			width: '100%',
			className: 'custom_scroll_bar',
			padding: '0px 14px 10px 14px',
		};
	};

	useEffect(() => {
		if (refSidebar?.current && refElmScroll?.current && filterSelected) {
			const heightSidebar = refSidebar.current.offsetHeight + UNIT_VALUE.S_14;
			refElmScroll.current.style.height = `calc(100% - ${heightSidebar}px)`;
		} else {
			if (refElmScroll.current) {
				refElmScroll.current.style.height = 'auto';
			}
		}
	}, [filterSelected]);

	const valueContexts: FilterContentContextTypes = {
		handleSelectFilter,
		filterSelected,
		handleOverwrite: handleOverwriteFilter,
		hasChangeFilter,
		isLoading,
		handleActionConfirm,
		typeConfirm,
		setFilterItemRequest,
		handleSelectFilterRemove,
		filterItemRequest,
		setVisibleCreateAlbum,
	};

	return (
		<FilterContentContext.Provider value={valueContexts}>
			<StyledFilterContent className={isHidden ? 'hidden' : ''}>
				{visibleCreateAlbum && filterSelected && (
					<AlbumModal
						visible={visibleCreateAlbum}
						handleGetListAlbum={() => {
							handleResetAlbum();
							dispatch(
								getFilterRequest({
									page: 1,
									maxRecords: FULL_RECORD,
								}),
							);
						}}
						onCancel={() => {
							setVisibleCreateAlbum(false);
						}}
						albumDetail={null}
						defaultName={filterSelected?.name}
						filterIdDefault={filterSelected?.id}
					/>
				)}
				<ConfirmModal
					width={450}
					maskClosable={true}
					confirmLoading={actionFilterStatus === IN_PROGRESS}
					isOpen={visibleModalConfirm}
					onClose={handleClosePopconfirm}
					{...otherPropModal}>
					{renderContentPopupConfirm()}
				</ConfirmModal>
				<LoadingWrapper
					isLoading={isLoading || fetchingLocation || fetchingContentCustomFields}>
					<StyledIcon
						onClick={closeFilters}
						style={{ position: 'absolute', top: '14px', right: '14px', zIndex: 99 }}>
						<IconClose />
					</StyledIcon>

					<StyledWrapperTitle
						style={{ borderBottom: `1px solid ${THEME.colors.darkBlue3}` }}
						padding="16px 16px 10px 24px">
						<StyledTitle className="unset_height" fontSize="20px">
							{t('filter.filter_content.filter_content')}
						</StyledTitle>
						<StyledTitle
							margin="0 32px 0 0"
							color={THEME.colors.gray3}
							cursor="pointer"
							className="unset_height"
							fontSize="12px"
							onClick={() => resetContentFilters()}
							lineHeight="18px">
							{t('filter.filter_content.clear_all')}
						</StyledTitle>
					</StyledWrapperTitle>
					<StyledWrapperContent padding="10px">
						<StyledWrapperFilter
							className={`custom_scroll_bar ${
								filterSelected ? 'selected_filter' : ''
							}`}>
							{!filterSelected && (
								<>
									<SelectorSavedFilter />
									<StyledDivider
										margin="14px 0"
										borderColor={THEME.colors.darkBlue3}
									/>
								</>
							)}
							{filterSelected && (
								<StyledWrapperContent padding="10px 14px 0px 14px" ref={refSidebar}>
									<SelectedFilterInfo
										handleActionEdit={() => {
											setFilterName(filterSelected.name);
											handleActionConfirm(TYPE_ACTIONS.EDIT);
										}}
									/>
									<SubFilterAlbumInfo />
								</StyledWrapperContent>
							)}

							<StyledWrapperContent ref={refElmScroll} {...otherPropsStyle()}>
								<StyledForm
									form={form}
									style={filterSelected ? { paddingLeft: '14px' } : {}}
									initialValues={{ ...DEFAULT_FORM }}
									onValuesChange={onValuesChange}
									onFinish={handleFinishForm}>
									<DetailFilter
										form={form}
										isFindSimilar={isFindSimilar}
										setIsFindSimilar={setIsFindSimilar}
										isVerify={isVerify}
									/>
									{filterSelected && (
										<StyledLineVertical className="line_filter" />
									)}
								</StyledForm>
							</StyledWrapperContent>
						</StyledWrapperFilter>
					</StyledWrapperContent>
					<StyledFooterFilter>
						<Row style={{ width: '100%' }} gutter={[8, 8]}>
							<Col span={8}>
								<Button
									disabled={!hasChangeFilter}
									onClick={handleApplyFilter}
									style={{ width: '100%' }}
									type="primary">
									{t('button.apply')}
								</Button>
							</Col>
							<Col span={8}>
								<Button
									disabled={
										!hasChangeFilter ||
										filterSelected?.type !== SAVED_CONTENT_FILTER_TYPES.CUSTOM
									}
									style={{ width: '100%' }}
									onClick={() => handleOverwriteFilter()}>
									{t('button.overwrite')}
								</Button>
							</Col>
							<Col span={8}>
								<Button
									style={{ width: '100%' }}
									disabled={!hasChangeFilter}
									onClick={() => handleActionConfirm(TYPE_ACTIONS.CREATE_FILTER)}>
									{t('button.save_as_new')}
								</Button>
							</Col>
						</Row>
					</StyledFooterFilter>
				</LoadingWrapper>
			</StyledFilterContent>
		</FilterContentContext.Provider>
	);
});

export default FilterContent;
