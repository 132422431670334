import { getRequest } from '@helpers/requestHelpers';
import { Button, message, Popconfirm } from 'antd';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Icon
import { IconDelete, IconEdit, IconFileCopy, IconGroupAdd, IconSend } from '@assets/icons';

// Models
import { SegmentConditionDto } from '@models/creator/segments';

// Constant
import { CREATOR_API_URL } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';

// Context
import SegmentModal from '@components/Creators/Segments/SegmentModal';
import { TYPE_ACTIONS } from '@constants/common';
import {
	CONTACTS_MATCH,
	CONTENT_TAGS,
	STATUS,
	SUBMITTED_TO_CAMPAIGN,
	TYPE_SEGMENT,
} from '@constants/creator';
import { NAME_FILTER_CONTENT } from '@constants/filter/filterContent';
import CreatorSegmentsContext from '@contexts/Creators/Segments';
import SearchBar from '@cores/SearchBar';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { OptionFilterTag } from '@models/filter';
import { StyledFlex } from '@styled/Common/CommonStyled';
import { useSelector } from 'react-redux';
import { UserStoreType } from '@stores/creator/creator.types';

// Styled
const StyledActionBlock = styled.div`
	display: flex;
	flex-direction: row;
	height: 33px;
	align-items: center;
	justify-content: space-between;
`;

const StyledActionList = styled.div`
	display: flex;

	button {
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

const StyledPopconfirm = styled(Popconfirm)`
	margin-right: 16px;
`;

type ActionProps = {
	selectedSegments: any;
	selectedSegmentData: any;
};

const ActionList = ({ selectedSegments, selectedSegmentData }: ActionProps) => {
	const { t } = useTranslation();
	const { segmentData = [] }: UserStoreType = useSelector((state: any) => state.creator);
	const { handleDuplicateSegment, handleDeleteSegment, paramSegment, handleChangeParamSegment } =
		useContext(CreatorSegmentsContext);

	const [selectedRow, setSelectedRow] = useState<number[]>([]);
	const [segments, setSegments] = useState<SegmentConditionDto[]>([]);
	const [editedSegments, setEditedSegments] = useState<SegmentConditionDto[]>([]);
	const [typeAction, setTypeAction] = useState<string>('');

	const { tags } = useSelector((state: ContentRootStateDto) => state.content);
	const { flags, labels, googleAIHashtags } = tags;

	const hasDefault = React.useMemo(() => {
		let result = false;
		segmentData.forEach((item) => {
			if (selectedRow.includes(item.id) && item?.type === TYPE_SEGMENT.DEFAULT) {
				result = true;
			}
		});
		return result;
	}, [segmentData, selectedRow]);

	const isDisabled = React.useMemo(() => {
		return (
			selectedRow.length > 1 ||
			(selectedSegmentData != null &&
				selectedSegmentData.length > 0 &&
				selectedSegmentData[0].type === TYPE_SEGMENT.DEFAULT)
		);
	}, [selectedRow, selectedSegmentData]);

	/**
	 * Update selected row
	 */
	useEffect((): any => {
		setSelectedRow(selectedSegments);
	}, [selectedSegments]);

	useEffect(() => {
		getConditionList();
	}, []);

	const getConditionList = () => {
		const url = `${CREATOR_API_URL}/segment/conditions`;

		getRequest(url)
			.then((response) => {
				if (response.status === 200) {
					setSegments(response.body.data.result?.segments);
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
			})
			.catch((err) => message.error(DEFAULT_ERROR));
	};

	const getSegmentDetails = () => {
		const url = `${CREATOR_API_URL}/segment/segment-details?id=${selectedRow[0]}`;
		getRequest(url)
			.then((response) => {
				if (response.status === 200) {
					setEditedSegments(response.body.data.result);
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
			})
			.catch((err) => message.error(DEFAULT_ERROR));
	};

	const renderListOption = (options: Array<any>, name: string | number) => {
		if (name === NAME_FILTER_CONTENT.TAGS) {
			return options?.map((item) => {
				return { name: item?.value, value: item?.value };
			});
		}
		if (name === NAME_FILTER_CONTENT.GALLERIES || name === NAME_FILTER_CONTENT.CAMPAIGNS) {
			return options?.map((option) => ({
				label: option?.name,
				value: option?.id?.toString(),
			}));
		}
		if (name === NAME_FILTER_CONTENT.CREATOR_SEGMENT) {
			return options?.map((option) => ({
				label: option?.title,
				value: option?.id?.toString(),
			}));
		}
		if (name === CONTENT_TAGS) {
			return options?.map((item) => ({ name: item?.value, value: item?.value }));
		}
		if (name === SUBMITTED_TO_CAMPAIGN) {
			return options?.map((item) => ({ label: item?.name, value: item?.id }));
		}

		if (name === CONTACTS_MATCH || name === STATUS) {
			return options?.map((item) => ({ label: t(item?.name), value: item?.value }));
		}

		return options;
	};

	const listOptionContentTags: OptionFilterTag[] = [
		{
			nameGroup: NAME_FILTER_CONTENT.LABELS,
			options: renderListOption(labels || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.labels'),
		},
		{
			nameGroup: NAME_FILTER_CONTENT.FLAGS,
			options: renderListOption(flags || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.flags'),
		},
		{
			nameGroup: NAME_FILTER_CONTENT.GOOGLE_AI_TAGS,
			options: renderListOption(googleAIHashtags || [], NAME_FILTER_CONTENT.TAGS),
			label: t('filter.filter_content.google_AI_tags'),
		},
	];

	/**
	 * Handle button
	 */

	// Handle button edit
	const handleEdit = () => {
		setTypeAction(TYPE_ACTIONS.EDIT);
		getSegmentDetails();
	};

	const handleConfirmDuplicate = () => {
		handleDuplicateSegment();
	};

	const onPressSearch = (value: string) => {
		handleChangeParamSegment({ ...paramSegment, page: 1, title: value?.trim() || '' });
	};

	// Handle button message
	const handleMessage = () => {};

	/**
	 * Handle button delete
	 */
	const handleDelete = () => {
		handleDeleteSegment();
	};

	return (
		<StyledActionBlock className="card-content">
			<StyledFlex style={{ width: '100%' }}>
				<StyledActionList>
					<SearchBar
						style={{ marginRight: '16px' }}
						placeholder={t('placeholder.search')}
						onSearch={onPressSearch}
						enterButton
					/>
					{selectedRow && selectedRow.length > 0 ? (
						<>
							<Button icon={<IconEdit />} onClick={handleEdit} disabled={isDisabled}>
								{t('button.edit')}
							</Button>

							<StyledPopconfirm
								placement="topLeft"
								title={t('popconfirm.duplicate')}
								onConfirm={handleConfirmDuplicate}
								okText={t('button.duplicate')}
								cancelText={t('button.cancel')}
								trigger={isDisabled ? 'none' : 'click'}>
								<Button icon={<IconFileCopy />} disabled={isDisabled}>
									{t('button.duplicate')}
								</Button>
							</StyledPopconfirm>

							<Button icon={<IconSend />} onClick={handleMessage}>
								{t('button.message')}
							</Button>

							<StyledPopconfirm
								placement="topLeft"
								title={t('popconfirm.delete')}
								onConfirm={handleDelete}
								okText={t('button.delete')}
								cancelText={t('button.cancel')}
								trigger={hasDefault ? 'none' : 'click'}>
								<Button disabled={hasDefault} icon={<IconDelete />}>
									{t('button.delete')}
								</Button>
							</StyledPopconfirm>
						</>
					) : null}
				</StyledActionList>
				<Button
					type="primary"
					icon={<IconGroupAdd />}
					onClick={() => setTypeAction(TYPE_ACTIONS.CREATE)}>
					{t('button.create_segment')}
				</Button>
			</StyledFlex>

			<SegmentModal
				visible={typeAction === TYPE_ACTIONS.EDIT || typeAction === TYPE_ACTIONS.CREATE}
				typeAction={typeAction}
				segments={segments}
				editedSegment={editedSegments}
				onGetSegment={() => handleChangeParamSegment({ ...paramSegment })}
				onClose={() => setTypeAction('')}
				listOptionContentTags={listOptionContentTags}
				renderListOption={renderListOption}
			/>
		</StyledActionBlock>
	);
};

export default React.memo(ActionList);
