import {
	CHECK_LIMIT_NOTIFICATION,
	GET_ALL_MESSAGE_NOTIFICAION,
	MARK_TASK_NOTIFICATION_AS_READ,
	NOTIFICATION_API_URL,
	TASK_NOTIFICATION,
	UPDATE_STATUS_NOTIFICAION,
} from '@constants/APIs';
import genericApiSaga from '@helpers/genericApiSaga';
import { getRequest, putRequest } from '@helpers/requestHelpers';
import {
	GetNotificationPayloadDto,
	NotificationStatusPayloadDto,
} from '@models/notification/stores';
import { TaskNotificationPayloadDto } from '@models/notification/summary';
import { convertParamSearch } from '@utils/common';
import { Action } from 'redux-actions';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from './notification.actions';
import {
	checkLimitNotificationTypes,
	getAllNotificationTypes,
	getNotifyAccountTypes,
	getTaskNotificationTypes,
	markTaskNotificationAsReadTypes,
	toggleTaskNotificationStatusTypes,
	updateStatusNotificationTypes,
} from './notification.types';

export function* getAll({ payload }: Action<GetNotificationPayloadDto>) {
	const { accountId, clientId } = payload || {};
	const customHeaders = { 'x-client-id': clientId };

	yield genericApiSaga({
		gatewayCall: () =>
			accountId && clientId
				? getRequest(
						`${GET_ALL_MESSAGE_NOTIFICAION}?accountId=${accountId}`,
						undefined,
						undefined,
						undefined,
						undefined,
						{ customHeaders },
				  )
				: getRequest(GET_ALL_MESSAGE_NOTIFICAION),
		*completed(response: any) {
			if (accountId) {
				response = { ...response, accountId };
			}
			yield put(actions.getAllNotificationSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getAllNotificationFailed(response));
		},
	});
}

export function* getNotifyAccount({ payload }: Action<{ accountId: number }>) {
	const { accountId } = payload || {};

	yield genericApiSaga({
		gatewayCall: () => getRequest(`${NOTIFICATION_API_URL}/${accountId}`),
		*completed(response: any) {
			yield put(actions.fetchNotificationAccountSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchNotificationAccountFailed(response));
		},
	});
}

export function* updateStatus({ payload }: Action<NotificationStatusPayloadDto>) {
	const { id, isAll } = payload;
	let url = UPDATE_STATUS_NOTIFICAION;
	if (id) {
		url += `?id=${id}`;
	}
	if (isAll) {
		url += `?isAll=${isAll}`;
	}
	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(actions.updateStatusNotificationSucceeded({ ...response, id, isAll }));
		},
		*failed(response: any) {
			yield put(actions.updateStatusNotificationSucceeded(response));
		},
	});
}

export function* checkLimitNotificationRequest({ payload }: Action<NotificationStatusPayloadDto>) {
	const searchParams = convertParamSearch(payload);
	const url = `${CHECK_LIMIT_NOTIFICATION}?${searchParams}`;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.checkLimitNotificationSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.checkLimitNotificationFailed(response));
		},
	});
}

export function* getTaskNotification({ payload }: Action<TaskNotificationPayloadDto>) {
	const searchParams = convertParamSearch(payload);
	const url = `${TASK_NOTIFICATION}?${searchParams}`;
	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(
				actions.getTaskNotificationSucceeded({ ...response, isShowAll: payload.isShowAll }),
			);
		},
		*failed(response: any) {
			yield put(actions.getTaskNotificationFailed(response));
		},
	});
}

export function* toggleTaskNotificationStatus({ payload }: Action<{ id: number }>) {
	const url = `${TASK_NOTIFICATION}/${payload.id}/toggle-status`;
	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(actions.toggleTaskNotificationStatusSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.toggleTaskNotificationStatusFailed(response));
		},
	});
}

export function* markTaskNotificationAsRead() {
	yield genericApiSaga({
		gatewayCall: () => putRequest(MARK_TASK_NOTIFICATION_AS_READ),
		*completed(response: any) {
			yield put(actions.markTaskNotificationAsReadSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.markTaskNotificationAsReadFailed(response));
		},
	});
}

export function* watcherNotificationSagas() {
	yield takeEvery(getAllNotificationTypes.GET_NOTIFICATION_REQUEST, getAll);
	yield takeLatest(getNotifyAccountTypes.GET_NOTIFICATION_ACCOUNT_REQUEST, getNotifyAccount);
	yield takeLatest(
		updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_REQUEST,
		updateStatus,
	);
	yield takeLatest(
		checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_REQUEST,
		checkLimitNotificationRequest,
	);
	yield takeLatest(getTaskNotificationTypes.GET_TASK_NOTIFICATION_REQUEST, getTaskNotification);
	yield takeLatest(
		toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_REQUEST,
		toggleTaskNotificationStatus,
	);
	yield takeLatest(
		markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_REQUEST,
		markTaskNotificationAsRead,
	);
}
