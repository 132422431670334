import { Button, Dropdown, Menu, message } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Component
import CreatorDetailModal from '@components/Creators/CreatorDetailModal/CreatorDetailModal';
import AddToSegmentModal from './AddToSegmentModal';
import ImportResultsModal from './ImportResultsModal';

// Redux
import {
	downloadTemplateCreatorEnd,
	downloadTemplateCreatorRequest,
	headerGetFilterSegments,
	updateAccountSettingRequest,
} from '@stores/actions';
import { useDispatch, useSelector } from 'react-redux';

// Icon
import { IconExpandMore, IconGroupAdd, IconMoreVert, IconSend } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { useEffect, useRef, useState } from 'react';

// Types
import FilterSelect, { FilterSelectTypes } from '@components/Filter/FilterSelect';
import { CREATOR_API_URL, CREATOR_SEGMENT_SIMPLE } from '@constants/APIs';
import { TYPE_ACTIONS } from '@constants/common';
import { DATA_INDEX, FILE_NAME_IMPORT, TITLE_SEGMENT, TYPE_SEGMENT } from '@constants/creator';
import { DEFAULT_ERROR } from '@constants/errors';
import CreatorSummaryContext from '@contexts/Creators/Summary';
import { ConfirmModal } from '@cores/Modal';
import SearchBar from '@cores/SearchBar/SearchBar';
import { getRequest } from '@helpers/requestHelpers';
import { CreatorProfileSummaryDto } from '@models/creator/profile';
import { SimpleSegmentsDto } from '@models/creator/segments';
import { ParamSummaryContactDto, SummaryColumnsDto } from '@models/creator/summary';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { ColumnSortList } from './SortColumn';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import { UserStoreType } from '@stores/creator/creator.types';
import { handleDownloadExcel } from '@helpers/base64Helper';
import ImportModal from './ImportModal';
import { ACCEPT_FILES } from '@constants/content/contentLibrary';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import SortColumnWithChildren from '@components/CustomLibrary/CustomSortColumn';

// Styled
const StyledActionBlock = styled.div`
	display: flex;
	flex-direction: row;
	height: 34px;
	align-items: center;
	justify-content: space-between;
`;

const StyledActionList = styled.div`
	display: flex;
	align-items: center;

	button {
		margin-right: 16px;
	}
`;

const StyledToggleColumns = styled.div`
	display: inline-flex;
	align-items: center;
	button {
		width: 250px;
		display: flex;
		justify-content: space-between;
	}

	svg {
		margin-right: 0;
	}
`;

const StyledMenuColumns = styled.ul`
	list-style-type: none;
	display: flex;
	width: 250px;
	margin: 0;
	padding: 0;
	flex-direction: column;
	background-color: ${THEME.colors.darkBlue3};
`;

const StyledMenuColumnsItems = styled.li`
	padding: 6px 8px;
	height: 32px;
	display: flex;
	align-items: center;

	&:last-child {
		margin: 14px 0 18px 8px;
	}
`;

const StyledButtonAction = styled(Button)`
	margin-left: 12px;
`;

const StyledUploadInput = styled.input`
	display: none;
`;

type ActionsTypes = {
	id: number;
	icon?: React.ReactNode;
	name: string;
	click: React.EventHandler<any>;
	disabled?: boolean;
	childNode?: React.ReactNode;
	hidden?: boolean;
};

type ActionProps = {
	columns: SummaryColumnsDto[];
	handleExportColumns: () => void;
	onChangeStatus: (requestData: any) => void;
	handleChangeBlockStatus: (requestData: any) => void;
	handleDeleteCreator: () => void;
	onImportCreator: (file: any) => void;
	handleOpenMessage: () => void;
};

const ActionList = ({
	columns,
	handleExportColumns,
	onChangeStatus,
	handleDeleteCreator,
	onImportCreator,
	handleChangeBlockStatus,
	handleOpenMessage,
}: ActionProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const location: any = useLocation();
	const { state } = location || {};

	const uploadRef = useRef<any>(null);
	const [toggleColumns, setToggleColumns] = useState<SummaryColumnsDto[]>([]);
	const [visibleDropdown, setVisibleDropdown] = useState(false);
	const [visibleAddToSegmentModal, setVisibleAddToSegmentModal] = useState(false);
	const [visibleAddCreatorModal, setVisibleAddCreatorModal] = useState(false);
	const [visibleImportModal, setVisibleImportModal] = useState(false);
	const [modalType, setModalType] = useState<string>('');
	const [editModalData, setEditModalData] = useState<any>({});
	const [filterSelect, setFilterSelect] = useState<FilterSelectTypes | null>(null);
	const [isLoadingList, setIsLoadingSegmentList] = useState<boolean>(false);
	const [segmentData, setSegmentData] = useState<SimpleSegmentsDto[]>([]);
	const [typeAction, setTypeAction] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [visibleImport, setVisibleImport] = useState<boolean>(false);

	const { handleChangeParamSummary, paramSummaryContact, isAllSelectOverDb, selectOnView } =
		React.useContext(CreatorSummaryContext);

	const {
		creatorsSummary: { scrollSegmentPage = 0, isAddToNewSegment = false },
	} = useSelector((state: any) => state.header);
	const { updateAccountStatus }: ContentStoreDto = useSelector((state: any) => state.content);
	const {
		downloadTemplateCreatorStatus,
		base64TemplateCreator,
		error,
		totalRecords,
	}: UserStoreType = useSelector((state: any) => state.creator);

	useEffect(() => {
		if (downloadTemplateCreatorStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (downloadTemplateCreatorStatus === FAILED) {
			message.error(error);
		}
		if (downloadTemplateCreatorStatus === SUCCEEDED && base64TemplateCreator) {
			handleDownloadExcel(base64TemplateCreator, FILE_NAME_IMPORT);
		}
		if (
			downloadTemplateCreatorStatus === SUCCEEDED ||
			downloadTemplateCreatorStatus === FAILED
		) {
			dispatch(downloadTemplateCreatorEnd());
			setIsLoading(false);
		}
	}, [downloadTemplateCreatorStatus]);
	/**
	 * Handle Actions List
	 */
	const handleActionEdit = () => {
		handleFetchDetailProfile();
		setVisibleAddCreatorModal(true);
		setModalType('edit');
	};

	const handleFormatData = (profile: CreatorProfileSummaryDto) => {
		const { social } = profile;
		const formatedData = {
			firstName: profile.firstName,
			lastName: profile.lastName,
			email: profile.email,
			instagramUsername: social?.instagramUsername,
			twitterUsername: social?.twitterUsername,
			youtubeUsername: social?.youtubeUsername,
			tiktokUsername: social?.tiktokUsername,
			address: profile.address,
			zipCode: profile.zipCode,
			facebookUrl: social?.facebookProfileUrl,
			phone: profile.phone,
			clientId: profile.id,
		};
		setEditModalData(formatedData);
	};

	const handleFetchDetailProfile = () => {
		setIsLoading(true);
		getRequest(`${CREATOR_API_URL}/${selectOnView[0]}`)
			.then((res) => {
				handleFormatData(res.body.data.result);
			})
			.catch((error) => {
				console.log('err', error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleActionFollow = () => {
		handleChangeStatus('IS_FOLLOWED', true);
	};

	const handleActionUnFollow = () => {
		handleChangeStatus('IS_FOLLOWED', false);
	};

	const handleActionBlock = () => {
		handleChangeBlockStatus(true);
	};

	const handleActionUnblock = () => {
		handleChangeBlockStatus(false);
	};

	const handleActionExport = () => {
		handleExportColumns();
	};

	/**
	 * Handle Toggle Columns
	 */

	const changeToggleColumns = () => {
		const dataRequest = toggleColumns?.map((item) => {
			return { dataIndex: item?.dataIndex, show: item?.show };
		});
		dispatch(updateAccountSettingRequest({ creatorToggleColumn: dataRequest }));
	};

	const handleApplyColumns = () => {
		setVisibleDropdown(false);
		changeToggleColumns();
	};

	const handleCancelColumns = () => {
		setToggleColumns(_.cloneDeep(columns));
		setVisibleDropdown(false);
	};

	const handleVisibleChange = (flag: boolean) => {
		setVisibleDropdown(flag);
	};

	const handleChangeChecked = ($event: CheckboxChangeEvent) => {
		const newList = toggleColumns.map((item) => {
			if (item.dataIndex === $event.target.id) {
				item.show = $event.target.checked;
			}
			return item;
		});

		setToggleColumns(newList);
	};

	const handleChangeStatus = (status: string, value: boolean) => {
		const payload = {
			creatorStatus: status,
			creatorStatusValue: value,
		};
		onChangeStatus(payload);
	};

	const handleConfirmDelete = () => {
		handleDeleteCreator();
	};

	const onClickImport = () => {
		const divElement = uploadRef.current;
		divElement.click();
		setVisibleImport(false);
	};

	const handleDownloadTemplate = () => {
		dispatch(downloadTemplateCreatorRequest());
	};

	const handleImportCreator = (file: any) => {
		if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
			if (file.size < 50000) {
				setVisibleImportModal(true);
				setVisibleImport(false);
			}
			onImportCreator(file);
		} else {
			message.error(t('creator_summary.message.required_xls'));
		}
	};

	const handleCloseImportModal = () => {
		setVisibleImportModal(false);
		uploadRef.current.value = null;
	};

	const handleOpenModalAddToSegment = () => {
		setVisibleAddToSegmentModal(true);
	};

	const handleCloseModalAddToSegment = () => {
		setVisibleAddToSegmentModal(false);
	};

	const handleOpenModalAddCreator = () => {
		setVisibleAddCreatorModal(true);
		setModalType('add');
	};

	const handleCloseModalAddCreator = () => {
		setVisibleAddCreatorModal(false);
		setModalType('');
		setEditModalData({});
	};

	const handleSearch = (search: string) => {
		const paramChange: ParamSummaryContactDto = {
			...paramSummaryContact,
			search,
			page: 1,
		};
		if (Object.keys(paramSummaryContact)?.includes('isActive')) {
			delete paramChange.isActive;
		}

		handleChangeParamSummary({ ...paramChange });
	};

	const onChangeFilter = (val: FilterSelectTypes | null) => {
		setFilterSelect(val);
		const idSegmentFilter = val ? +val?.value : undefined;

		const paramChange: ParamSummaryContactDto = {
			...paramSummaryContact,

			page: 1,
			id: idSegmentFilter,
		};
		if (Object.keys(paramSummaryContact)?.includes('isActive')) {
			delete paramChange.isActive;
		}

		handleChangeParamSummary({ ...paramChange });
	};

	const onSortEnd = (list: Array<SummaryColumnsDto>) => {
		const newList = [...list];

		if (toggleColumns?.length > 0) {
			newList.unshift(toggleColumns[0]);
		}
		setToggleColumns(newList);
	};

	const getSegmentList = () => {
		const url = `${CREATOR_SEGMENT_SIMPLE}`;
		const payload = {
			page: 1,
			pageRecords: 9999,
		};

		// Reset when a new segment is created

		setIsLoadingSegmentList(true);
		getRequest(url, payload)
			.then((response) => {
				if (response.status === 200) {
					const data: SimpleSegmentsDto[] = [...response.body.data.result];

					setSegmentData(data);
					let key: 'id' | 'campaignId' | null = null;
					const stateData = state?.segmentId || state?.campaignId || null;
					let itemFind = null;
					if (state?.segmentId) {
						key = 'id';
					}
					if (state?.campaignId) {
						key = 'campaignId';
					}
					if (state?.following) {
						itemFind = data?.find(
							(item) =>
								item?.type === TYPE_SEGMENT.DEFAULT &&
								item.title === TITLE_SEGMENT.FOLLOWING,
						);
					}

					if (key || itemFind) {
						const itemSelect =
							itemFind ||
							data?.find((item) => item[key || 'id'] === stateData) ||
							null;

						if (itemSelect) {
							const itemFilter: FilterSelectTypes = {
								value: itemSelect?.id || '',
								name: itemSelect?.title || '',
							};
							onChangeFilter(itemFilter);
						} else {
							message.config({
								top: 60,
							});
							message.warning({
								content: t('creator_summary.message_filter_removed'),
								key: 'message_filter_removed',
							});
						}
						history.replace({ state: {} });
					}
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
				setIsLoadingSegmentList(false);
			})
			.catch((err) => {
				message.error(DEFAULT_ERROR);
				setIsLoadingSegmentList(false);
			});
	};

	/**
	 * Render Menu
	 */
	const actionsObj: ActionsTypes[] = [
		{
			id: 1,
			name: 'button.edit_creator_info',
			click: handleActionEdit,
			disabled: isAllSelectOverDb || selectOnView?.length !== 1,
		},
		{
			id: 6,
			name: 'button.export',
			click: handleActionExport,
			disabled: selectOnView?.length === 0,
		},
		{
			id: 2,
			name: 'button.follow',
			click: handleActionFollow,
			disabled: selectOnView?.length === 0,
		},
		{
			id: 3,
			name: 'button.unfollow',
			click: handleActionUnFollow,
			disabled: selectOnView?.length === 0,
		},
		{
			id: 4,
			name: 'button.block',
			click: handleActionBlock,
			disabled: selectOnView?.length === 0,
		},
		{
			id: 5,
			name: 'button.unblock',
			click: handleActionUnblock,
			disabled: selectOnView?.length === 0,
		},
		{
			id: 7,
			name: 'button.delete',
			click: () => setTypeAction(TYPE_ACTIONS.REMOVE),
			disabled:
				selectOnView?.length === 0 ||
				(isAllSelectOverDb && !paramSummaryContact.id && !paramSummaryContact.search),
		},
		{
			id: 8,
			name: 'button.add',
			click: handleOpenModalAddCreator,
		},
		{
			id: 9,
			name: 'button.import_list',
			click: () => setVisibleImport(true),
		},
	];

	const menuActions = (
		<Menu>
			{actionsObj.map((item: ActionsTypes) => {
				if (item.hidden) {
					return null;
				}
				return (
					<Menu.Item disabled={item.disabled} key={item.id} onClick={item.click}>
						{item.icon}
						<span>{t(item.name)}</span>
					</Menu.Item>
				);
			})}
		</Menu>
	);

	const listSortColumns = toggleColumns?.filter((item) => item?.dataIndex !== DATA_INDEX.STATUS);

	const menuColumns = (
		<StyledMenuColumns>
			<StyledWrapperContent
				className="custom_scroll_bar"
				width="100%"
				overflow="auto"
				maxHeight="calc(70vh - 64px)"
				height="100%">
				<SortColumnWithChildren onSortEnd={onSortEnd} list={listSortColumns}>
					<ColumnSortList
						handleChangeChecked={handleChangeChecked}
						list={listSortColumns}
					/>
				</SortColumnWithChildren>
			</StyledWrapperContent>

			<StyledMenuColumnsItems>
				<Button
					type={'primary'}
					loading={updateAccountStatus === IN_PROGRESS}
					onClick={handleApplyColumns}>
					{t('button.apply')}
				</Button>
				<StyledButtonAction className="ant-btn-cancel" onClick={handleCancelColumns}>
					{t('button.cancel')}
				</StyledButtonAction>
			</StyledMenuColumnsItems>
		</StyledMenuColumns>
	);

	const renderContentPopupConfirm = () => {
		if (typeAction === TYPE_ACTIONS.REMOVE) {
			if (paramSummaryContact?.id) {
				return (
					<>
						{t('creator_summary.delete_creator_segment', {
							val: isAllSelectOverDb ? totalRecords : selectOnView.length,
						})}
						<br />
						{t('creator_summary.quest_continue')}
					</>
				);
			}
			return <>{t('creator_summary.delete_selected_creator')}</>;
		}

		return '';
	};

	const onSubmitDelete = () => {
		setTypeAction('');
		handleConfirmDelete();
	};

	const otherPropConfirm = React.useMemo(() => {
		const result: any = {};
		if (typeAction === TYPE_ACTIONS.REMOVE) {
			result.onClickBtnCancel = () => setTypeAction('');
			result.onSubmit = onSubmitDelete;
			if (paramSummaryContact?.id) {
				result.okText = t('button.permanently_delete');
			}
		}

		return result;
	}, [typeAction]);

	/**
	 * Update toggle columns
	 */
	useEffect((): any => {
		setToggleColumns(_.cloneDeep(columns));
	}, [columns]);

	/**
	 * Update selected row
	 */

	useEffect(() => {
		getSegmentList();
	}, []);

	useEffect(() => {
		// In the "Add to Segment" modal,
		// if user choose to create a new segment then press the "Add" button,
		// the "Filter by segment" field should be fetch again to get new data.

		if (isAddToNewSegment) {
			getSegmentList();
			dispatch(headerGetFilterSegments(false));
		}
	}, [isAddToNewSegment]);

	return (
		<StyledActionBlock className="card-content">
			<ImportModal
				visible={visibleImport}
				onCancel={() => setVisibleImport(false)}
				handleImport={() => onClickImport()}
				handleDownload={() => handleDownloadTemplate()}
				isLoading={isLoading}
			/>
			<StyledActionList>
				<SearchBar
					placeholder={t('content.search')}
					onSearch={handleSearch}
					enterButton
					style={{ marginRight: '15px' }}
				/>

				<StyledUploadInput
					type="file"
					ref={uploadRef}
					accept={ACCEPT_FILES.XLS}
					onChange={(e: any) => handleImportCreator(e.target.files[0])}
				/>

				{selectOnView && selectOnView.length !== 0 ? (
					<>
						<Button
							disabled={isAllSelectOverDb}
							icon={<IconGroupAdd />}
							onClick={handleOpenModalAddToSegment}>
							{t('button.add_to_segments')}
						</Button>

						<Button
							disabled={isAllSelectOverDb}
							icon={<IconSend />}
							onClick={handleOpenMessage}>
							{t('button.message')}
						</Button>
					</>
				) : null}
				<Dropdown overlay={menuActions} placement="bottomRight" trigger={['click']}>
					<Button icon={<IconMoreVert />} />
				</Dropdown>
			</StyledActionList>

			<StyledToggleColumns>
				<FilterSelect
					showSearch
					selectedValue={filterSelect}
					onChange={onChangeFilter}
					options={segmentData?.map((item) => ({ value: item?.id, name: item?.title }))}
					loading={isLoadingList}
					placeholderDefault={t('creator_summary.placeholder_filter_segment')}
					subApplyFilter={t('creator_summary.filter_by_segment') + ' '}
				/>

				<Dropdown
					overlay={menuColumns}
					placement="bottomRight"
					onVisibleChange={handleVisibleChange}
					visible={visibleDropdown}
					trigger={['click']}>
					<Button style={{ marginLeft: '11px' }}>
						{t('button.toggle_columns')}
						<IconExpandMore />
					</Button>
				</Dropdown>
			</StyledToggleColumns>
			<AddToSegmentModal
				visible={visibleAddToSegmentModal}
				onClose={handleCloseModalAddToSegment}
				selectedCreator={selectOnView}
				isSelectAll={isAllSelectOverDb}
			/>

			<CreatorDetailModal
				visible={visibleAddCreatorModal}
				onClose={handleCloseModalAddCreator}
				modalType={modalType}
				creatorData={editModalData}
				callback="fetchingFindProfiles"
				isFetching={isLoading}
			/>
			<ConfirmModal
				width={450}
				isOpen={!!typeAction}
				textAlign={
					typeAction === TYPE_ACTIONS.REMOVE && paramSummaryContact?.id
						? 'left'
						: 'center'
				}
				onClose={() => setTypeAction('')}
				{...otherPropConfirm}>
				{renderContentPopupConfirm()}
			</ConfirmModal>

			<ImportResultsModal visible={visibleImportModal} onClose={handleCloseImportModal} />
		</StyledActionBlock>
	);
};

export default React.memo(ActionList);
