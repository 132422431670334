import { ProjectTemplateInfoDto } from '@models/landingpagemanager';
import { generateRandomElementIdentification } from '@utils/funcHelper';

export const getLandingPageBuilderTemplate = (templateInfo: ProjectTemplateInfoDto) => {
	const { clientId = 0, gid = 0, uploaderLink = '', galleryLink = '' } = templateInfo || {};
	const uploaderBtnId = generateRandomElementIdentification(6);

	return {
		assets: [
			{
				type: 'image',
				src: 'https://d3tyva4y54cpi2.cloudfront.net/client/595/campaign/0/image/logo-text-black.0a923b73.png',
				unitDim: 'px',
				height: 0,
				width: 0,
			},
		],
		styles: [
			{
				selectors: [],
				selectorsAdd: 'body *',
				style: { 'font-size': '18px', 'font-family': 'IBM Plex Sans, sans-serif' },
			},
			{
				selectors: [],
				selectorsAdd: 'body',
				style: {
					'padding-top': '15px',
					'padding-right': '15px',
					'padding-bottom': '15px',
					'padding-left': '15px',
					overflow: 'auto !important',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'p',
				style: {
					'margin-top': '0px',
					'margin-right': '0px',
					'margin-bottom': '0px',
					'margin-left': '0px',
					'padding-top': '0px',
					'padding-right': '0px',
					'padding-bottom': '0px',
					'padding-left': '0px',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'h1, h2, h3, h4',
				style: { 'font-family': 'IBM Plex Serif, sans-serif', 'font-weight': '600' },
			},
			{ selectors: [], selectorsAdd: 'h1', style: { 'font-size': '60px' } },
			{ selectors: [], selectorsAdd: 'h2', style: { 'font-size': '44px' } },
			{ selectors: [], selectorsAdd: 'h3', style: { 'font-size': '32px' } },
			{ selectors: [], selectorsAdd: 'h4', style: { 'font-size': '24px' } },
			{
				selectors: [],
				selectorsAdd: '.entribe_gallery_v100_2023 h1',
				style: { 'font-weight': 'normal' },
			},
			{
				selectors: [],
				selectorsAdd: 'body *',
				style: { 'font-size': '16px' },
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'h1',
				style: { 'font-size': '33px' },
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'h2',
				style: { 'font-size': '28px' },
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'h3',
				style: { 'font-size': '23px' },
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'h4',
				style: { 'font-size': '19px' },
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'img:not(.footer img, .slick-slide img)',
				style: { 'max-width': '100%', height: 'auto !important' },
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'button',
				style: { 'max-width': '100%' },
				mediaText: 'only screen and (max-width: 576px)',
				atRuleType: 'media',
			},
			{
				selectors: ['container'],
				style: { width: '100% !important' },
				mediaText: 'only screen and (max-width: 576px)',
				atRuleType: 'media',
			},
			{ selectors: ['entribe-popup_uploader-modal-root'], style: { display: 'none' } },
			{ selectors: ['container', 'CQhWs'], style: { 'text-align': 'center' } },
			{ selectors: ['#iegg'], style: { color: 'black' } },
			{ selectors: ['uXgqG'], style: { width: '470px', height: '97px' } },
			{ selectors: ['AJAQB'], style: { color: '#142b49', margin: '16px auto 24px auto' } },
			{ selectors: ['ZAvKp'], style: { margin: '0 auto 0 auto', width: '85%' } },
			{
				selectors: ['custom_widget', 'btn', 'btn-primary', 'lYIFC'],
				style: {
					margin: '24px 0 24px 0',
					width: '639.094px',
					height: '47px',
					'border-radius': '24px 24px 24px 24px',
					border: '0 none black',
					'background-color': '#f89b29',
				},
			},
			{ selectors: ['#ifyg'], style: { width: '100%', 'min-height': '50px' } },
			{
				selectors: ['entribe_gallery_v100_2023', 'maDii'],
				style: { margin: '12px 0 12px 0' },
			},
			{ selectors: ['xLEbI'], style: { padding: '80px 0 30px 0' } },
			{ selectors: ['bJKjf'], style: { margin: '0 0 16px 0' } },
			{ selectors: ['OIvtQ'], style: { margin: '0 0 16px 0' } },
			{ selectors: ['WhSyR'], style: { margin: '0 0 16px 0' } },
			{ selectors: ['kcdLa'], style: { margin: '0 0 16px 0' } },
			{ selectors: ['NlInq'], style: { color: '#142b49', margin: '0 0 20px 0' } },
			{
				selectors: ['container', 'lkjcR'],
				style: { width: '80%', margin: '18px auto 15px auto' },
			},
		],
		pages: [
			{
				frames: [
					{
						component: {
							type: 'wrapper',
							stylable: true,
							classes: ['xLEbI', 'htGrD', 'Njnmx'],
							attributes: { id: 'iq2y' },
							_undoexc: ['status', 'open'],
							components: [
								{
									type: 'container',
									classes: ['container', 'CQhWs'],
									components: [
										{
											type: 'image',
											resizable: { ratioDefault: 1 },
											classes: ['uXgqG'],
											attributes: {
												id: 'iegg',
												src: 'https://d3tyva4y54cpi2.cloudfront.net/client/595/campaign/0/image/logo-text-black.0a923b73.png',
											},
											_undoexc: ['status', 'open'],
										},
										{
											type: 'header',
											content: 'Heading',
											classes: ['AJAQB'],
										},
										{
											type: 'text',
											content:
												'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
											classes: ['ZAvKp'],
										},
										{
											tagName: 'button',
											type: 'uploader-button',
											classes: [
												'custom_widget',
												'btn',
												'btn-primary',
												'lYIFC',
												'xXPnx',
												'qHqih',
												'KwzAt',
											],
											attributes: {
												id: `widget_id_${uploaderBtnId}`,
												'data-link-uploader': `${uploaderLink}`,
												onclick: `widgetUploader.popup('widget_id_${uploaderBtnId}')`,
												'data-close-outside': 1,
											},
											components: [
												{
													type: 'text',
													classes: ['zhyku'],
													components: [
														{
															type: 'textnode',
															content: 'SHARE YOUR PHOTOS',
															classes: ['hxroP'],
															_undoexc: ['status', 'open'],
														},
													],
												},
											],
											uploaderLink: `${uploaderLink}`,
										},
									],
								},
								{
									type: 'container',
									classes: ['BwdOJ', 'container-fluid'],
									components: [
										{
											type: 'gallery-tag',
											style: '',
											classes: ['entribe_gallery_v100_2023', 'maDii'],
											attributes: {
												'data-client-id': `${clientId}`,
												'data-gallery-id': `${gid}`,
												id: 'ifyg',
											},
											galleryLink,
										},
									],
								},
								{
									type: 'container',
									classes: ['container', 'lkjcR'],
									components: [
										{
											tagName: 'h2',
											type: 'header',
											content: 'Heading',
											classes: ['NlInq'],
										},
										{
											type: 'list',
											classes: ['HwKBJ'],
											components: [
												{
													tagName: 'li',
													type: 'text',
													classes: ['bJKjf'],
													components: [
														{
															type: 'textnode',
															content: 'Item 1',
															classes: ['rDFJW'],
															_undoexc: ['status', 'open'],
														},
													],
												},
												{
													tagName: 'li',
													type: 'text',
													classes: ['OIvtQ'],
													components: [
														{
															type: 'textnode',
															content: 'Item 2',
															classes: ['YCmwQ'],
															_undoexc: ['status', 'open'],
														},
													],
												},
												{
													tagName: 'li',
													type: 'text',
													classes: ['WhSyR'],
													components: [
														{
															type: 'textnode',
															content: 'Item 3',
															classes: ['tFumz'],
															_undoexc: ['status', 'open'],
														},
													],
												},
												{
													tagName: 'li',
													type: 'text',
													classes: ['kcdLa'],
													components: [
														{
															type: 'textnode',
															content: 'Item 4',
															classes: ['waZtp'],
															_undoexc: ['status', 'open'],
														},
													],
												},
												{
													tagName: 'li',
													type: 'text',
													classes: ['kcdLa'],
													components: [
														{
															type: 'textnode',
															content: 'Item 5',
															classes: ['ZBINo'],
															_undoexc: ['status', 'open'],
														},
													],
												},
												{
													type: 'textnode',
													content: ' ',
													classes: ['lzmHQ'],
													_undoexc: ['status', 'open'],
												},
												{
													tagName: 'NULL',
													type: 'comment',
													content: ' Add more items as needed ',
													classes: ['rejUd'],
													_undoexc: ['status', 'open'],
												},
											],
										},
									],
								},
							],
						},
					},
				],
				type: 'main',
				id: 'WwTq0WEqCDgaJaMs',
			},
		],
	};
};

export const getEmptyProjectData = () => {
	return {
		assets: [],
		styles: [
			{
				selectors: [],
				selectorsAdd: 'body *',
				style: {
					'font-size': '18px',
					'font-family': 'IBM Plex Sans, sans-serif',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'body',
				style: {
					'padding-top': '15px',
					'padding-right': '15px',
					'padding-bottom': '15px',
					'padding-left': '15px',
					overflow: 'auto !important',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'p',
				style: {
					'margin-top': '0px',
					'margin-right': '0px',
					'margin-bottom': '0px',
					'margin-left': '0px',
					'padding-top': '0px',
					'padding-right': '0px',
					'padding-bottom': '0px',
					'padding-left': '0px',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'h1, h2, h3, h4',
				style: {
					'font-family': '"IBM Plex Serif", sans-serif',
					'font-weight': '600',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'h1',
				style: {
					'font-size': '60px',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'h2',
				style: {
					'font-size': '44px',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'h3',
				style: {
					'font-size': '32px',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'h4',
				style: {
					'font-size': '24px',
				},
			},
			{
				selectors: [],
				selectorsAdd: '.entribe_gallery_v100_2023 h1',
				style: {
					'font-weight': 'normal',
				},
			},
			{
				selectors: [],
				selectorsAdd: 'body *',
				style: {
					'font-size': '16px',
				},
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'h1',
				style: {
					'font-size': '33px',
				},
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'h2',
				style: {
					'font-size': '28px',
				},
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'h3',
				style: {
					'font-size': '23px',
				},
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'h4',
				style: {
					'font-size': '19px',
				},
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'img:not(.footer img, .slick-slide img)',
				style: {
					'max-width': '100%',
					height: 'auto !important',
				},
				mediaText: 'only screen and (max-width: 768px)',
				atRuleType: 'media',
			},
			{
				selectors: [],
				selectorsAdd: 'button',
				style: {
					'max-width': '100%',
				},
				mediaText: 'only screen and (max-width: 576px)',
				atRuleType: 'media',
			},
			{
				selectors: ['container'],
				style: {
					width: '100% !important',
				},
				mediaText: 'only screen and (max-width: 576px)',
				atRuleType: 'media',
			},
			{
				selectors: ['entribe-popup_uploader-modal-root'],
				style: {
					display: 'none',
				},
			},
			{
				selectors: ['container', 'CQhWs'],
				style: {
					'text-align': 'center',
				},
			},
			{
				selectors: ['uXgqG'],
				style: {
					width: '470px',
					height: '97px',
				},
			},
			{
				selectors: ['AJAQB'],
				style: {
					color: '#142b49',
					margin: '16px auto 24px auto',
				},
			},
			{
				selectors: ['ZAvKp'],
				style: {
					margin: '0 auto 0 auto',
					width: '85%',
				},
			},
			{
				selectors: ['custom_widget', 'btn', 'btn-primary', 'lYIFC'],
				style: {
					margin: '24px 0 24px 0',
					width: '639.094px',
					height: '47px',
					'border-radius': '24px 24px 24px 24px',
					border: '0 none black',
					'background-color': '#f89b29',
				},
			},
			{
				selectors: ['entribe_gallery_v100_2023', 'maDii'],
				style: {
					margin: '12px 0 12px 0',
				},
			},
			{
				selectors: ['xLEbI'],
				style: {
					padding: '80px 0 30px 0',
				},
			},
			{
				selectors: ['bJKjf'],
				style: {
					margin: '0 0 16px 0',
				},
			},
			{
				selectors: ['OIvtQ'],
				style: {
					margin: '0 0 8px 0',
				},
			},
			{
				selectors: ['WhSyR'],
				style: {
					margin: '0 0 16px 0',
				},
			},
			{
				selectors: ['kcdLa'],
				style: {
					margin: '0 0 16px 0',
				},
			},
			{
				selectors: ['NlInq'],
				style: {
					color: '#142b49',
					margin: '0 0 20px 0',
				},
			},
			{
				selectors: ['container', 'lkjcR'],
				style: {
					width: '80%',
					margin: '18px auto 15px auto',
				},
			},
		],
		pages: [
			{
				frames: [
					{
						component: {
							type: 'wrapper',
							stylable: true,
							classes: [generateRandomElementIdentification(5)],
							attributes: {
								id: 'iq2y',
							},
							_undoexc: ['status', 'open'],
						},
					},
				],
				type: 'main',
				id: `WwTq0WEqCDgaJaMs_${generateRandomElementIdentification(5)}`,
			},
		],
	};
};

export const defaultCampaignLandingPageTemplate = (templateInfo: ProjectTemplateInfoDto) => {
	return {
		cssString:
			'* { box-sizing: border-box; } body {margin: 0;}body *{font-size:18px;font-family: IBM Plex Sans, sans-serif;}body{padding-top:15px;padding-right:15px;padding-bottom:15px;padding-left:15px;overflow:auto !important;}p{margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;}h1, h2, h3, h4{font-family: IBM Plex Serif, Arial, sans-serif;font-weight:600;}h1{font-size:60px;}h2{font-size:44px;}h3{font-size:32px;}h4{font-size:24px;}.entribe_gallery_v100_2023 h1{font-weight:normal;}.container.CQhWs{text-align:center;}#iegg{color:black;}.uXgqG{width:470px;height:97px;}.AJAQB{color:#142b49;margin:16px auto 24px auto;}.ZAvKp{margin:0 auto 0 auto;width:85%;}.custom_widget.btn.btn-primary.lYIFC{margin:24px 0 24px 0;width:639.094px;height:47px;border-radius:24px 24px 24px 24px;border:0 none black;background-color:#f89b29;}#ifyg{width:100%;min-height:50px;}.entribe_gallery_v100_2023.maDii{margin:12px 0 12px 0;}.xLEbI{padding:80px 0 30px 0;}.bJKjf{margin:0 0 16px 0;}.OIvtQ{margin:0 0 16px 0;}.WhSyR{margin:0 0 16px 0;}.kcdLa{margin:0 0 16px 0;}.NlInq{color:#142b49;margin:0 0 20px 0;}.container.lkjcR{width:80%;margin:18px auto 15px auto;}@media only screen and (max-width: 768px){body *{font-size:16px;}h1{font-size:33px;}h2{font-size:28px;}h3{font-size:23px;}h4{font-size:19px;}img:not(.footer img, .slick-slide img){max-width:100%;height:auto !important;}}@media only screen and (max-width: 576px){button{max-width:100%;}.container{width:100% !important;}}',
		htmlString: `<body id="iq2y" class="xLEbI htGrD Njnmx ALXzI"><div class="container CQhWs BeXmL"><img id="iegg" src="https://d3tyva4y54cpi2.cloudfront.net/client/595/campaign/0/image/logo-text-black.0a923b73.png" class="uXgqG yIPSp"/><h1 class="AJAQB DsOvT">Heading</h1><p class="ZAvKp MSNEK">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><button class="custom_widget btn btn-primary lYIFC xXPnx qHqih KwzAt jldkI" id="widget_id_IkMnsE" data-link-uploader="${templateInfo.uploaderLink}" onclick="widgetUploader.popup('widget_id_IkMnsE')" data-close-outside="1"><p class="zhyku Higly">SHARE YOUR PHOTOS</p></button></div><div class="BwdOJ container-fluid MBdQa"><div data-client-id="${templateInfo.clientId}" data-gallery-id="${templateInfo.gid}" id="ifyg" class="entribe_gallery_v100_2023 maDii bNwPJ"></div></div><div class="container lkjcR QcEes"><h2 class="NlInq RgTDa">Heading</h2><ul class="HwKBJ tiioF"><li class="bJKjf sQXdj">Item 1</li><li class="OIvtQ ZMbIn">Item 2</li><li class="WhSyR gpsji">Item 3</li><li class="kcdLa zFnKO">Item 4</li><li class="kcdLa KHmtS">Item 5</li> <!-- Add more items as needed --></ul></div></body>
`,
	};
};
