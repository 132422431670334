import {
	IconArrowCircleDown,
	IconArrowCircleUp,
	IconInstagram,
	IconRightPending,
	IconTikTok,
	IconTwitter,
	IconYoutube,
} from '@assets/icons';
import RefreshMetrics from '@components/AmbassadorDashboard/RefreshMetrics';
import { DEFAULT_WIDTH_COL } from '@constants/common';
import { SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import { SOCIAL_PROFILE } from '@constants/social_search';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import { Empty, LoadingWrapper } from '@cores/index';
import { SocialStatTypes } from '@models/ambassador';
import {
	getSocialStatsRequest,
	refreshSocialStatsEnd,
	refreshSocialStatsRequest,
	storeCreatorData,
} from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import { StyledFlex, StyledIcon, StyledText } from '@styled/Common/CommonStyled';
import { StyledContactBlock } from '@styled/Creators/ContactCardStyled';
import {
	StyledInvalidSocialUsername,
	StyledSocialStatsCollapse,
	StyledSocialStatsTable,
	StyledSocialUsername,
} from '@styled/Creators/SocialStatsStyled';
import { abbreviateNumber, replaceAtChar } from '@utils/common';
import { Collapse, Popover, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InvalidFlag from '../Summary/InvalidFlag';

type SocialStatsProps = {
	creatorId: number;
};

type IconTypes = {
	size: number;
	fill?: string;
	fillPath?: string;
};

const SocialStats = (props: SocialStatsProps) => {
	const { creatorId } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		socialStats,
		fetchingSocialStats,
		refreshSocialStatsStatus,
		invalidateSocialUsernameStatus,
		creatorProfile,
		refreshSocialStatsResult,
		error,
	}: UserStoreType = useSelector((state: any) => state.creator);

	const { socialUserProfileStatus } = creatorProfile || {};

	const {
		instagramInvalid = false,
		tiktokInvalid = false,
		twitterInvalid = false,
		youtubeInvalid = false,
	} = socialUserProfileStatus || {};

	const DEFAULT_REQUEST_PAYLOAD = {
		creatorId: null,
	};
	const [requestPayload, setRequestPayload] = useState<any>(DEFAULT_REQUEST_PAYLOAD);

	const socialUsernames = [
		creatorProfile?.social?.instagramUsername,
		creatorProfile?.social?.twitterUsername,
		creatorProfile?.social?.tiktokUsername,
		creatorProfile?.social?.youtubeUsername,
	];

	useEffect(() => {
		initRequestPayload();
		return () => {
			resetData();
		};
	}, [creatorId, ...socialUsernames]);

	const initRequestPayload = () => {
		if (creatorId) {
			setRequestPayload({ ...DEFAULT_REQUEST_PAYLOAD, creatorId });
		}
	};

	useEffect(() => {
		if (refreshSocialStatsStatus === SUCCEEDED && refreshSocialStatsResult) {
			message.info(refreshSocialStatsResult.message);
			initRequestPayload();
		}
		if (refreshSocialStatsStatus === FAILED && error) {
			message.error(error);
		}
		if (refreshSocialStatsStatus === SUCCEEDED || refreshSocialStatsStatus === FAILED) {
			dispatch(refreshSocialStatsEnd());
		}
	}, [refreshSocialStatsStatus, refreshSocialStatsResult, error]);

	useEffect(() => {
		if (requestPayload.creatorId) {
			dispatch(getSocialStatsRequest(requestPayload));
		}
	}, [requestPayload]);

	const resetData = () => {
		dispatch(
			storeCreatorData({
				socialStats: null,
			}),
		);
	};

	const renderSocialIcon = (socialSource: string) => {
		let icon = <></>;
		const props: IconTypes = {
			size: 24,
			fill: THEME.colors.gray5,
		};

		switch (socialSource.toLowerCase()) {
			case SOCIAL_SOURCE.INSTAGRAM:
				icon = <IconInstagram />;
				props.size = 22;
				break;

			case SOCIAL_SOURCE.TIKTOK:
				icon = <IconTikTok />;
				props.fillPath = THEME.colors.gray1;
				break;

			case SOCIAL_SOURCE.TWITTER:
				icon = <IconTwitter />;
				break;

			default:
				icon = <IconYoutube />;
				break;
		}

		return <StyledIcon {...props}>{icon}</StyledIcon>;
	};

	const renderColumnTitleWithTooltip = (title: number, tooltip: string) => {
		return (
			<Popover overlayClassName="overlay_dark_blue3" title={null} content={tooltip}>
				{title}
			</Popover>
		);
	};

	const renderEngagementRate = (value: number) => {
		let icon = <></>;
		switch (true) {
			case value < 0.01: {
				icon = <IconArrowCircleDown />;
				break;
			}

			case value >= 0.01 && value < 0.03: {
				icon = <IconRightPending />;
				break;
			}
			default: {
				icon = <IconArrowCircleUp />;
				break;
			}
		}

		const displayedValue = value * 100;

		return (
			<StyledFlex justify="center">
				<StyledText margin="0 4px 0 0">
					{displayedValue > 0 ? displayedValue.toFixed(2) : displayedValue}%
				</StyledText>
				<StyledIcon size={23}>{icon}</StyledIcon>
			</StyledFlex>
		);
	};

	const columns = [
		{
			title: '',
			dataIndex: 'socialSource',
			render: (socialSource: string) => renderSocialIcon(socialSource),
			width: 40,
		},
		{
			title: t('creator_profile.social_stats.username'),
			dataIndex: 'username',
			render: (username: string, record: SocialStatTypes) =>
				renderSocialUsername(username, record),
			width: DEFAULT_WIDTH_COL + 25,
		},
		{
			title: renderColumnTitleWithTooltip(
				t('creator_profile.social_stats.posts'),
				t('creator_profile.social_stats.tooltip.posts'),
			),
			dataIndex: 'ambassadorPosts',
			align: 'center',
			width: 75,
		},
		{
			title: renderColumnTitleWithTooltip(
				t('creator_profile.social_stats.likes_comments'),
				t('creator_profile.social_stats.tooltip.likes_comments'),
			),
			dataIndex: 'likesComments',
			align: 'center',
			width: DEFAULT_WIDTH_COL - 25,
		},
		{
			title: renderColumnTitleWithTooltip(
				t('creator_profile.social_stats.engagement_rate'),
				t('creator_profile.social_stats.tooltip.engagement_rate'),
			),
			dataIndex: 'engagementRate',
			render: (engagementRate: number) => renderEngagementRate(engagementRate),
			align: 'center',
			width: 100,
		},
		{
			title: t('creator_profile.social_stats.followers'),
			dataIndex: 'followers',
			render: (followers: number) => abbreviateNumber(followers),
			align: 'center',
			width: 100,
		},
	];

	const updateSocialUsernameStatus = (source: string) => {
		const fieldName = `${source}Invalid`;
		if (creatorProfile) {
			dispatch(
				storeCreatorData({
					creatorProfile: {
						...creatorProfile,
						socialUserProfileStatus: {
							...creatorProfile.socialUserProfileStatus,
							[fieldName]: false,
						},
					},
				}),
			);
		}
	};

	const renderSocialUsername = (socialUsername: string, record: SocialStatTypes) => {
		const { socialSource = '' } = record || {};
		let isInvalid = false;
		let href = '';

		switch (socialSource.toLowerCase()) {
			case SOCIAL_SOURCE.INSTAGRAM: {
				isInvalid = instagramInvalid;
				href = SOCIAL_PROFILE.INSTAGRAM;
				break;
			}
			case SOCIAL_SOURCE.TIKTOK: {
				isInvalid = tiktokInvalid;
				href = SOCIAL_PROFILE.TIKTOK;
				break;
			}

			case SOCIAL_SOURCE.TWITTER: {
				isInvalid = twitterInvalid;
				href = SOCIAL_PROFILE.TWTTER;
				break;
			}

			default: {
				isInvalid = youtubeInvalid;
				href = SOCIAL_PROFILE.YOUTUBE;
				break;
			}
		}

		const payload = {
			username: socialUsername,
			source: socialSource.toLowerCase(),
			isInvalid,
			isAmbassador: true,
		};

		return (
			socialUsername && (
				<>
					<StyledSocialUsername>
						<a href={`${href}${replaceAtChar(socialUsername)}`} target="_blank">
							{replaceAtChar(socialUsername)}
						</a>
					</StyledSocialUsername>
					<StyledInvalidSocialUsername>
						<InvalidFlag
							payload={payload}
							updateSocialUsernameStatus={updateSocialUsernameStatus}
						/>
					</StyledInvalidSocialUsername>
				</>
			)
		);
	};

	const refreshSocialStats = () => {
		dispatch(refreshSocialStatsRequest({ creatorId }));
	};

	const renderHeader = () => {
		return (
			<StyledFlex>
				<StyledText
					whiteSpace="nowrap"
					width="auto"
					title={t('creator_profile.social_stats.title')}
					style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '20%' }}>
					{t('creator_profile.social_stats.title')}
				</StyledText>
				{socialStats && (
					<RefreshMetrics
						refreshMetricsEnabled={socialStats?.ambassadorRefreshMetricsEnabled}
						metricsRefreshUpdateAt={socialStats?.metricsRefreshAt}
						onRefresh={refreshSocialStats}
						placement="topRight"
					/>
				)}
			</StyledFlex>
		);
	};

	const isLoading =
		(!socialStats && fetchingSocialStats) ||
		refreshSocialStatsStatus === IN_PROGRESS ||
		invalidateSocialUsernameStatus === IN_PROGRESS;

	return (
		<StyledContactBlock>
			<LoadingWrapper sizeLoading="medium" isLoading={isLoading}>
				<StyledSocialStatsCollapse
					bordered={false}
					expandIconPosition="right"
					defaultActiveKey={1}>
					<Collapse.Panel key={1} header={renderHeader()}>
						<StyledSocialStatsTable
							id={'table_social_stats'}
							columns={columns}
							dataSource={socialStats?.creatorSocialStats || []}
							pagination={false}
							locale={{ emptyText: socialStats ? <Empty /> : <></> }}
							scroll={{ y: 'calc(250px - 44px)', x: 'fit-content' }}
						/>
					</Collapse.Panel>
				</StyledSocialStatsCollapse>
			</LoadingWrapper>
		</StyledContactBlock>
	);
};

export default SocialStats;
