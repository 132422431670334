import styled from 'styled-components';
import { Upload, Switch, Layout, Steps, Typography, Form, Select, Modal } from 'antd';
import Wrapper from '@cores/Wrapper';
import THEME from '@constants/themes/themes';
import { IconInfo, IconCancel } from '@assets/icons';

const { Sider } = Layout;
const { Step } = Steps;
const { Dragger } = Upload;

const StyledIconInfo = styled(IconInfo)`
	fill: ${THEME.colors.darkBlue1} !important;
	height: 12px;
`;

const StyledSider = styled(({ margin, ...props }) => <Sider {...props} />)`
	margin-top: 50px;
	margin-left: ${({ margin }) => (margin ? '16px' : 'unset')};
	transition: all 0.15s;
`;

const StyledTableWrapper = styled(Wrapper)`
	// display: flex;
	// flex-direction: row;
`;

const StyledSteps = styled(Steps)`
	margin-bottom: 48px;
	.ant-steps-item-title {
		font-size: 14px;
	}
	.ant-steps-item-active {
		.ant-steps-item-tail::after {
			background-color: ${THEME.colors.darkBlue3};
		}
		.ant-steps-item-title {
			color: white !important;
		}
	}
	.ant-steps-item-wait {
		.ant-steps-item-icon {
			background-color: ${THEME.colors.darkBlue3};
			.ant-steps-icon {
				color: white;
			}
		}
		.ant-steps-item-tail::after {
			background-color: ${THEME.colors.darkBlue3};
		}
	}

	.ant-progress-circle-trail {
		stroke: none;
	}

	.ant-progress-circle-path {
		stroke: ${THEME.colors.orangeBase} !important;
	}

	// .ant-steps-item-finish {
	// 	.anticon-check {
	// 		display: none;
	// 	}
	// 	.ant-steps-icon::after {
	// 		content: '1';
	// 	}
	// }
`;

const StyledStepItem = styled(Step)`
	.ant-steps-item-finish {
		.anticon-check {
			display: none;
		}
		.ant-steps-icon::after {
			content: '1';
		}
	}
`;

const StyledSwitch = styled(Switch)`
	&.ant-switch {
		background-color: rgba(255, 255, 255, 0.5);
		.ant-switch-handle::before {
			background-color: white;
		}
	}
	&.ant-switch-checked {
		background-color: rgba(226, 88, 43, 0.5);
		.ant-switch-handle::before {
			background-color: ${THEME.colors.orangeBase};
		}
	}
`;

const StyledBlock = styled.div`
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
`;

const StyledTitle = styled(Typography.Title)`
	margin: 0 16px 16px 0 !important;
	color: ${THEME.colors.gray5} !important;
	font-weight: 500 !important;
`;

const StyledForm = styled(Form)`
	.ant-form-item {
		flex-direction: column;
		margin-bottom: 12px;
	}
	.ant-form-item-label {
		padding: 0;
	}
	.ant-form-item-explain {
		position: absolute;
		right: 0;
	}

	svg {
		fill: white !important;
		&:hover {
			fill: white !important;
		}
	}
`;

const StyledUpload = styled(Dragger)`
	margin-top: 16px;
	margin-bottom: 16px;
	.ant-upload-btn {
		padding: 0 !important;
	}
	&.ant-upload {
		height: 100px;
		background-color: rgb(103, 115, 125, 0.5);
	}
`;

const StyledColBorder = styled.div`
	height: 100%;
	padding: 24px;
	border: 1px solid rgb(103, 115, 125, 0.5);
	border-radius: 5px;
`;

const StyledCol24Border = styled.div`
	padding: 24px;
	border: 1px solid rgb(103, 115, 125, 0.5);
	border-radius: 5px;
`;

const StyledSmallText = styled.p`
	font-size: 0.857 rem;
	color: ${THEME.colors.gray1};
`;

const StyledIconCancel = styled(IconCancel)`
	cursor: pointer;
	height: 17px;
`;

const StyledFilePreview = styled.div`
	display: flex;
	justify-content: center;
	align-item: center;
`;

const StyledSelect = styled(Select)`
	overflow-y: auto;
	max-height: 100px;
`;

const StyledModal = styled(Modal)`
	.ant-modal-close {
		top: -10px;
		right: -10px;
	}
`;

export {
	StyledSider,
	StyledTableWrapper,
	StyledStepItem,
	StyledSteps,
	StyledSwitch,
	StyledBlock,
	StyledTitle,
	StyledForm,
	StyledUpload,
	StyledColBorder,
	StyledCol24Border,
	StyledIconInfo,
	StyledSmallText,
	StyledIconCancel,
	StyledFilePreview,
	StyledSelect,
	StyledModal,
};
