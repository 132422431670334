import { PARAMS_DEFAULT } from '@constants/common';
import { ParamRequestListBasic } from '@models/common/summary';
import { WidgetBuilderTemplateDto } from '@models/content/widgetManager';
import {
	FormatDataTermsTypes,
	ParamRequestHistoryTemplateTypes,
	ParamRequestLiveWidgetTemplateTypes,
	TermsTypes,
} from '@models/settings/contentLicensing';
import { TemplateHistoryTypes } from '@models/settings/contentLicensing/stores';
import React from 'react';

type LicensingTemplateDetailTypes = {
	onSubmitFormTemplate: () => void;
	listTerms: TermsTypes[];
	setListTerms: (val: TermsTypes[]) => void;
	handleChangeFieldTerms: (id: number, field: any, noChange?: boolean) => void;
	handleRemoveTerms: (id: number) => void;
	handleAddTerms: (terms: TermsTypes) => void;
	setIdTermsSelected: (val: number | null) => void;
	termsSelected: TermsTypes | null;
	getFormatListTerms: () => FormatDataTermsTypes;
	hasChangeTerms: boolean;
	setHasChangeTerms: (val: boolean) => void;
	setParamWidgetLive: (val: ParamRequestLiveWidgetTemplateTypes) => void;
	paramWidgetLive: ParamRequestLiveWidgetTemplateTypes;
	listWidgetLive: WidgetBuilderTemplateDto[];
	setListWidgetLive: (val: WidgetBuilderTemplateDto[]) => void;
	statusTemplate: {
		isDraft: boolean;
		isApproved: boolean;
		isArchived: boolean;
	};
	setTypeConfirm: (val: string) => void;
	typeConfirm: string;
	setIsLoading: (val: boolean) => void;
	isLoading: boolean;
	historyList: TemplateHistoryTypes[];
	paramHistory: ParamRequestHistoryTemplateTypes;
	setParamHistory: (val: ParamRequestHistoryTemplateTypes) => void;
	onCancelTemplate: () => void;
	setIsSaveName: (val: boolean) => void;
	isDetectHeight: boolean;
	setIsDetectHeight: (val: boolean) => void;
	showValidate: boolean;
	setShowValidate: (val: boolean) => void;
	handleCheckValidateField: (id: number, key: string, isCheckList?: boolean) => boolean;
	setIsCancel: (val: boolean) => void;
	isCancel: boolean;
};

const values: LicensingTemplateDetailTypes = {
	onSubmitFormTemplate: () => {},
	listTerms: [],
	setListTerms: () => {},
	handleChangeFieldTerms: () => {},
	handleRemoveTerms: () => {},
	handleAddTerms: () => {},
	setIdTermsSelected: () => {},
	termsSelected: null,
	getFormatListTerms: () => ({ data: [], errorRequired: false, termsValidates: [] }),
	hasChangeTerms: false,
	setHasChangeTerms: () => {},
	setParamWidgetLive: () => {},
	paramWidgetLive: { ...PARAMS_DEFAULT, excludedTermsTemplateId: -1 },
	listWidgetLive: [],
	setListWidgetLive: () => {},
	statusTemplate: {
		isArchived: false,
		isDraft: false,
		isApproved: false,
	},
	setTypeConfirm: () => {},
	typeConfirm: '',
	setIsLoading: () => {},
	isLoading: false,
	historyList: [],
	paramHistory: {
		...PARAMS_DEFAULT,
		termsTemplateId: -1,
	},
	setParamHistory: () => {},
	onCancelTemplate: () => {},
	setIsSaveName: () => {},
	isDetectHeight: false,
	setIsDetectHeight: () => {},
	showValidate: false,
	setShowValidate: () => {},
	handleCheckValidateField: () => false,
	setIsCancel: () => {},
	isCancel: false,
};

const LicensingTemplateDetailContext = React.createContext(values);
export default LicensingTemplateDetailContext;
