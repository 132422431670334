import {
	CREATOR_TYPE_SETTING,
	DEFAULT_FORM_SETTING,
	NAME_SETTING_FEED,
	TAB_SOCIAL_SETTING,
} from '@constants/settings/socialAggregator';
import { FeedDto } from '@models/settings/socialSearch/socialAggregator/summary';
import React from 'react';

type SettingSocialAggregatorTypes = {
	detailFeed: FeedDto | null;
	onSaveChange: () => void;
	hasChange: boolean;
	handleFinishForm: (val: any) => void;
	hasTerms: boolean;
	setHasTerms: (val: boolean) => void;
	isCreate: boolean;
	onChangeStatusCreate: (val: boolean) => void;
	onCancelForm: () => void;
	isActive: boolean;
	setIsActive: (val: boolean) => void;
	setHasChange: (val: boolean) => void;
	onChangeDetail: (val: FeedDto | null) => void;
	typeIncluded: string;
	setTypeIncluded: (val: string) => void;
	setTypeAction: (val: string) => void;
	onChangeTab: (val: string) => void;
	activeTab: string;
	onChangeStatus: () => void;
	setListSourceType: (val: string[]) => void;
	setListCaptionType: (val: string[]) => void;
	listCaptionType: string[];
	listSourceType: string[];
	disabledCheck: string[];
	setDisabledCheck: (val: string[]) => void;
	typeCreator: string;
	setTypeCreator: (val: string) => void;
	setFileCSVUpload: (val: any) => void;
	fileCSVUpload: any;
};

const values: SettingSocialAggregatorTypes = {
	detailFeed: null,
	onSaveChange: () => {},
	hasChange: false,
	handleFinishForm: () => {},
	hasTerms: false,
	setHasTerms: () => {},
	isCreate: false,
	onChangeStatusCreate: () => {},
	onCancelForm: () => {},
	isActive: true,
	setIsActive: () => {},
	setHasChange: () => {},
	onChangeDetail: () => {},
	typeIncluded: '',
	setTypeIncluded: () => {},
	setTypeAction: () => {},
	onChangeTab: () => {},
	activeTab: TAB_SOCIAL_SETTING.POSTS_ANALYZED,
	onChangeStatus: () => {},
	setListSourceType: () => {},
	setListCaptionType: () => {},
	listCaptionType: [...DEFAULT_FORM_SETTING[NAME_SETTING_FEED.CAPTION_SENTIMENT]],
	listSourceType: [...DEFAULT_FORM_SETTING[NAME_SETTING_FEED.SOURCES]],
	disabledCheck: [],
	setDisabledCheck: () => {},
	typeCreator: CREATOR_TYPE_SETTING.ALL,
	setTypeCreator: () => {},
	fileCSVUpload: null,
	setFileCSVUpload: () => {},
};

const SettingSocialAggregatorContext = React.createContext(values);
export default SettingSocialAggregatorContext;
