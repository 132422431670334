import { s3URL, urlGalleryTag } from '@config/index';
import { LIMIT_NUMBER_INPUT, TYPE_FORMAT_DATE } from '@constants/common';
import { ACCEPT_FILES, MEDIA_TYPE, NAME_ZIP, ROTATE } from '@constants/content/contentLibrary';
import { MAX_SIZE_IFRAME, SIZE_IFRAME } from '@constants/publish/gallery';
import { SPECIAL_CHARS } from '@constants/settings';
import {
	ContentThumbRotateResDto,
	GetContentPayloadDto,
} from '@models/content/contentLibrary/store';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import { LocationDashboardType } from '@models/dashboard';
import { SizeIframe } from '@models/publishing/galleries';
import {
	FeedDto,
	UsernameConjunctionDto,
} from '@models/settings/socialSearch/socialAggregator/summary';
import heic2any from 'heic2any';
import html2canvas from 'html2canvas';
import moment from 'moment-timezone';
import { getContentByType } from './common';
import { DISPLAY_TYPES, HEIGHT_OVERLAY } from '@constants/licensingTemplate';
import { TaskNotificationItemDto } from '@models/notification/summary';
import { NOTIFICATION_TYPE_BY_TIME } from '@constants/notification';
import { TermConditionItemDto, WidgetBuilderDetailDto } from '@models/content/widgetManager';
import { EMAIL_OPT_IN } from '@constants/content/widgetManager';
import { TermsTypes } from '@models/settings/contentLicensing';
import { isNaN } from 'lodash';

export const captureDOM = ({
	id,
	name,
	otherProps = {},
	callback,
	noDownload,
}: {
	id: string;
	name: string;
	otherProps?: any;
	callback?: (val: string) => void;
	noDownload?: boolean;
}) => {
	html2canvas(document.getElementById(id) || document.body, {
		useCORS: true,
		allowTaint: false,
		...otherProps,
	}).then((canvas) => {
		const pngUrl: any = canvas
			.toDataURL('image/png')
			.replace('image/png', 'image/octet-stream');

		if (callback && typeof callback === 'function') {
			callback(canvas.toDataURL('image/png'));
		}
		if (!noDownload) {
			const downloadLink = document.createElement('a');
			downloadLink.href = pngUrl;
			downloadLink.download = name;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	});
};

export const renderNameZip = () => {
	const nameDate = moment(new Date()).format(TYPE_FORMAT_DATE.MONTH_TEXT);
	return `${NAME_ZIP}${nameDate}`;
};

export const renderCountryHasContent = (list: LocationDashboardType[], code: string) => {
	const itemFind = list?.find((item) => item?.countryISO3Code === code);
	if (itemFind?.totalDirectUpload) {
		return itemFind?.country;
	}
	return '';
};

export const renderStateUsHasContent = (list: LocationDashboardType[], state: string) => {
	const itemFind = list?.find((item) => item?.state === state);
	if (itemFind?.totalDirectUpload) {
		return itemFind?.state;
	}
	return '';
};

export const checkRegex = (reg: any, val: string) => {
	const regex = new RegExp(reg);
	return regex.test(val);
};

export const renderNameFeed = (feed: FeedDto, isOption?: boolean) => {
	const valSearchTerms = renderNameSearchTerm(feed);
	return isOption ? `${valSearchTerms} (${feed.name})` : `${valSearchTerms} ${feed.name}`;
};

export const renderNameSearchTerm = (feed: FeedDto) => {
	return feed.hashtags.length > 0
		? `${SPECIAL_CHARS.HASH}${feed.hashtags[0]}`
		: `${SPECIAL_CHARS.AT}${feed.mentionUsernames[0]}`;
};

export const onCopyValue = (val: string) => {
	const input = document.createElement('input');
	input.value = val || '';
	document.body.appendChild(input);
	input.select();
	document.execCommand('Copy');
	input.remove();
};

export const renderStringIframe = (
	size: SizeIframe,
	url: string,
	name: string,
	hasTime?: boolean,
) => {
	const keyParam = url?.includes('?') ? '&' : '?';
	return `<iframe scrolling="no" frameborder="0" src="${url}${
		hasTime ? `${keyParam}time=${new Date().getTime()}` : ''
	}" height="${size?.height}px" width="${size?.width}%" title="${name}"></iframe>`;
};

export const onChangeSizeIframe = (e: any) => {
	const { name, value } = e.target;

	if (isNaN(+value) || Math.abs(+value) > MAX_SIZE_IFRAME.height) {
		return {};
	}

	if (name === SIZE_IFRAME.width) {
		if (Math.abs(+value) > MAX_SIZE_IFRAME.width) {
			return {};
		}
	}
	return { [name]: Math.abs(value) };
};

export const runScriptTag = () => {
	const ID_SCRIPT = 'id_script_tag';
	const elm = document.getElementById(ID_SCRIPT);

	if (elm) {
		elm.remove();
	}

	const script = document.createElement('script');

	script.src = urlGalleryTag;

	script.id = ID_SCRIPT;
	script.type = 'text/javascript';
	script.defer = true;

	document.body.appendChild(script);
};

export const checkPositionContent = (index: number, countContent: number) => {
	const result = {
		isLast: index >= countContent - 1,
		isFirst: index <= 0,
	};

	return result;
};

export const fetchPosterOfVideo = (elm: HTMLVideoElement, callback?: (val: string) => void) => {
	const canvas = document.createElement('canvas');
	const ctx: any = canvas.getContext('2d');
	const videoElm = document.createElement('video');

	if (elm && elm.src) {
		videoElm.autoplay = true;
		videoElm.muted = true;
		videoElm.src = elm.src;
		videoElm.crossOrigin = 'anonymous';

		videoElm.onloadeddata = () => {
			canvas.width = elm.videoWidth;
			canvas.height = elm.videoHeight;

			ctx.drawImage(videoElm, 0, 0, elm.videoWidth, elm.videoHeight);
			videoElm.pause();

			try {
				if (canvas?.toDataURL && canvas?.toDataURL('image/png')) {
					const imgUrl = canvas?.toDataURL('image/png');

					elm.poster = imgUrl;

					if (callback && typeof callback === 'function') {
						callback(imgUrl);
					}
				}
			} catch (error: any) {
				console.error('error: ', error);
			}
		};
	}
};

export const convertSocialUsername = (list: UsernameConjunctionDto[]) => {
	return list.map((item) => item.username);
};

export const encodeToBase64 = (val: string | number | undefined | null) => {
	if (!val) {
		return '';
	}
	return btoa(val.toString());
};

export const decodeFromBase64 = (val: string | number | undefined | null) => {
	if (!val) {
		return '';
	}
	return atob(val.toString());
};

export const getUrlS3 = (url: string) => {
	const urlBase = getUrlImgBase(url);
	if (!urlBase) {
		return '';
	}
	return `${s3URL}${urlBase}`;
};

export const getUrlImgBase = (val: string | undefined) => {
	if (!val) {
		return '';
	}
	let result = val;
	const arrCheck = ['https://', 'http://'];
	arrCheck.forEach((item) => {
		if (val.includes(item)) {
			const bodyLink = val.slice(item.length);
			result = bodyLink.slice(bodyLink.indexOf('/'));
		}
	});

	return result;
};

export const sortStringArray = (list: string[]) => {
	const compareLowerCase = (a: string, b: string) => {
		return a.toLowerCase().localeCompare(b.toLowerCase());
	};
	return list.sort(compareLowerCase);
};

export const sortNumber = (a: number, b: number) => {
	return a - b;
};

export const formatPayload = (payload: GetContentPayloadDto | any) => {
	const payloadTemp: any = {};

	if (payload) {
		Object.keys(payload).forEach((key: string) => {
			if (
				payload[key] === 0 ||
				(payload[key] &&
					((typeof payload[key] === 'object' && Object.keys(payload[key]).length > 0) ||
						typeof payload[key] === 'string' ||
						typeof payload[key] === 'number' ||
						typeof payload[key] === 'boolean'))
			) {
				payloadTemp[key] = payload[key];
			}
		});
	}
	return { ...payloadTemp };
};

export const generalKeyField = () => {
	const time = new Date().getTime();
	const random = Math.round(Math.random() * 10 ** 15);
	return (time + random).toString();
};

export const isValidDimensionImg = (file: any, width: number, height: number) => {
	const img = new Image();
	const promise = new Promise((resolve, reject) => {
		img.onload = () => {
			if (img.width > width || img.height > height) {
				resolve(false);
			}
			resolve(true);
		};
		// Reject promise on error
		img.onerror = reject;
	});
	img.src = window.URL.createObjectURL(file);
	return promise;
};

export const convertHeicToJpg: any = (file: any) => {
	return new Promise((resolve, reject) => {
		try {
			heic2any({ blob: file, toType: 'image/jpeg' }).then((fileBlob: any) => {
				const convertedFile = new File([fileBlob], file.name, {
					type: 'image/jpeg',
				});

				resolve(convertedFile);
			});
		} catch (e) {
			reject(e);
		}
	});
};

export const convertFileUploads = async (file: any) => {
	try {
		const fileName = file.name.toLowerCase();
		const isHeic = fileName.includes(ACCEPT_FILES.HEIC);
		const extensionIndex = fileName.lastIndexOf(
			`.${isHeic ? ACCEPT_FILES.HEIC : ACCEPT_FILES.HEIF}`,
		);
		if (
			extensionIndex > -1 &&
			(!file.type ||
				file.type.includes(ACCEPT_FILES.HEIC) ||
				file.type.includes(ACCEPT_FILES.HEIF))
		) {
			const fileConvert = await convertHeicToJpg(file);
			return fileConvert;
		}

		return file;
	} catch (e) {
		return file;
	}
};

export const convertMultiFileUploads = (fileUploads: any[]) => {
	return Promise.all(
		fileUploads.map(async (file) => {
			const fileName = file.name.toLowerCase();
			const isHeic = fileName.includes(ACCEPT_FILES.HEIC);
			const extensionIndex = fileName.lastIndexOf(
				`.${isHeic ? ACCEPT_FILES.HEIC : ACCEPT_FILES.HEIF}`,
			);

			if (
				extensionIndex > -1 &&
				(!file.type ||
					file.type.includes(ACCEPT_FILES.HEIC) ||
					file.type.includes(ACCEPT_FILES.HEIF))
			) {
				const fileConvert = await convertHeicToJpg(file);

				return fileConvert;
			}

			return file;
		}),
	);
};

export const handleSelectItemWithLib = (selectionRef: any, id: number | string | any) => {
	let selectItem: any = null;
	if (id === '' || id === null || id === undefined) {
		return;
	}

	selectionRef.current?.registry?.forEach((registry: any) => {
		if (id === registry?.props?.id) {
			registry.state.isSelected = !registry.state.isSelected;

			selectItem = registry;
		}
	});

	if (!selectItem) {
		return;
	}

	if (selectItem.state.isSelected) {
		selectionRef.current.selectedItems.add(selectItem);
	} else {
		selectionRef.current.selectedItems.delete(selectItem);
	}
	const newList = Array.from(selectionRef.current.selectedItems);

	selectionRef.current.props.onSelectionFinish(newList);
};

export const sortListIds = (ids: Array<KeyType>, listSelected: Array<KeyType>) => {
	const result: Array<KeyType> = [];

	ids.forEach((co) => {
		if (listSelected.includes(co)) {
			result.push(co);
		}
	});

	return result;
};

export const handleSelectMultiWithLib = (
	refSelection: any,
	listId: Array<string | number | string>,
	callFinish?: boolean,
) => {
	if (refSelection) {
		refSelection.current?.registry?.forEach((registry: any) => {
			const idCheck = registry?.props?.id;
			if (registry.state.isSelected) {
				if (!listId.includes(idCheck)) {
					registry.state.isSelected = false;
					refSelection.current.selectedItems.delete(registry);
				}
			} else {
				if (listId.includes(idCheck)) {
					registry.state.isSelected = true;
					refSelection.current.selectedItems.add(registry);
				}
			}
		});

		const newList = Array.from(refSelection.current.selectedItems);

		if (callFinish) {
			refSelection.current.props.onSelectionFinish(newList);
		}
	}
};

export const convertFormDataToObject = (data: any) => {
	return Object.fromEntries(data.entries());
};

export const renderTextTime = (n: number, t: any) => {
	const second = n % 60;
	let min = (n - second) / 60;

	let hour = (min - (min % 60)) / 60;
	min %= 60;

	const day = (hour - (hour % 24)) / 24;
	hour %= 24;
	const renderMore = (val: number) => {
		return val !== 1 ? 's' : '';
	};

	const hourText = `${hour} ${t('content.import_modal.hour')}${renderMore(hour)}`;
	const minText = `${min} ${t('content.import_modal.min')}${renderMore(min)}`;
	const secondText = `${second} ${t('content.import_modal.second')}${renderMore(second)}`;
	const dayText = `${day} ${t('content.import_modal.day')}${renderMore(day)}`;

	if (day > 0) {
		if (hour > 0) {
			return `${dayText} ${hourText}`;
		}
		return dayText;
	}
	if (hour > 0) {
		if (min > 0) {
			return `${hourText} ${minText}`;
		}
		return hourText;
	}
	if (min > 0) {
		if (second > 0) {
			return `${minText} ${secondText}`;
		}
		return minText;
	}
	return secondText;
};

export const addTimeStampUrl = (url: string | null | undefined) => {
	if (!url) {
		return '';
	}
	const KEY_TIME = '?timestamp=';
	const idx = url.indexOf(KEY_TIME);
	const dateTime = new Date().getTime();

	if (idx !== -1) {
		return url.slice(0, idx + KEY_TIME.length) + dateTime;
	}
	return url + KEY_TIME + dateTime;
};

export const removeTimeStampUrl = (url: string | null | undefined) => {
	if (!url) {
		return '';
	}
	const KEY_TIME = '?timestamp=';
	const idx = url.indexOf(KEY_TIME);
	if (idx !== -1) {
		return url.slice(0, idx);
	}
	return url;
};

export const addTimeStampFromUrl = (
	urlCheck: string | null | undefined,
	resultUrl: string | null | undefined,
	noAddNew?: boolean,
) => {
	if (!urlCheck || !resultUrl) {
		return '';
	}
	const KEY_TIME = '?timestamp=';
	const idx = urlCheck.indexOf(KEY_TIME);
	const dateTime = new Date().getTime();

	if (idx === -1) {
		if (noAddNew) {
			return resultUrl;
		}
		return resultUrl + KEY_TIME + dateTime;
	}
	const valDate = urlCheck.slice(idx);

	return resultUrl + valDate;
};

export const handleRotateContentSelect = (list: ContentContainerDto[], ids: number[]) => {
	list.forEach((item: ContentContainerDto, idx) => {
		const contentTemp: ContentDto = getContentByType(item);

		if (ids.includes(contentTemp.id) && contentTemp?.mediaType === MEDIA_TYPE.IMAGE) {
			contentTemp.rotate = (+(contentTemp.rotate || 0) + ROTATE.D_90) % 360;
		}
	});
	return list;
};

export const addTimeStampContentList = (list: ContentContainerDto[], isRemoveReplace?: boolean) => {
	list.forEach((item: ContentContainerDto, idx) => {
		const contentTemp: ContentDto = getContentByType(item);
		if (contentTemp?.mediaType === MEDIA_TYPE.IMAGE) {
			contentTemp.thumbnail = addTimeStampUrl(contentTemp.thumbnail);
			contentTemp.contentUrl = addTimeStampUrl(contentTemp.contentUrl);
			if (isRemoveReplace) {
				delete contentTemp.thumbnailReplace;
				delete contentTemp.imgReplace;
			}
		}
	});
	return list;
};

export const addTimeStampContentSelect = (list: ContentContainerDto[], ids: number[]) => {
	list.forEach((item: ContentContainerDto, idx) => {
		const contentTemp: ContentDto = getContentByType(item);

		if (ids.includes(contentTemp.id) && contentTemp?.mediaType === MEDIA_TYPE.IMAGE) {
			contentTemp.thumbnail = addTimeStampUrl(contentTemp.thumbnail);
			contentTemp.contentUrl = addTimeStampUrl(contentTemp.contentUrl);
			delete contentTemp.thumbnailReplace;
			delete contentTemp.imgReplace;
		}
	});
	return list;
};

export const replaceThumbContentSelect = (
	list: ContentContainerDto[],
	contentRes: ContentThumbRotateResDto[],
	hasRotate?: boolean,
) => {
	list.forEach((item: ContentContainerDto, idx) => {
		const contentTemp: ContentDto = getContentByType(item);
		const contentFindRes = contentRes.find((res) => res.id === contentTemp.id);

		if (contentFindRes && contentTemp?.mediaType === MEDIA_TYPE.IMAGE) {
			contentTemp.thumbnail = contentFindRes.thumbnail;
			contentTemp.contentUrl = contentFindRes.contentUrl;

			if (hasRotate) {
				contentTemp.rotate = (+(contentTemp.rotate || 0) - ROTATE.D_90) % 360;
			}
			delete contentTemp.thumbnailReplace;
			delete contentTemp.imgReplace;
		}
	});
	return list;
};

export const heightOverlayDropdown = (length: number) => {
	if (length === 0) {
		return `${HEIGHT_OVERLAY.EMPTY}px`;
	}
	if (length > HEIGHT_OVERLAY.MORE) {
		return `${HEIGHT_OVERLAY.MORE * HEIGHT_OVERLAY.ONE_ITEM}px`;
	}
	return `${HEIGHT_OVERLAY.ONE_ITEM * length}px`;
};

export const groupNotificationsByTime = (notifications: TaskNotificationItemDto[]) => {
	const localTimeZone = moment.tz.guess();
	const todayStart = moment().startOf('day');
	const last7DaysStart = moment().subtract(7, 'days').startOf('day');
	const today: TaskNotificationItemDto[] = [];
	const last7Days: TaskNotificationItemDto[] = [];
	const older: TaskNotificationItemDto[] = [];

	notifications.forEach((notification: TaskNotificationItemDto) => {
		const localTimestamp = moment(notification.createAt).tz(localTimeZone);
		if (localTimestamp.isSame(todayStart, 'day')) {
			today.push({
				...notification,
				notificationTypeByTime: NOTIFICATION_TYPE_BY_TIME.TODAY,
			});
		} else if (localTimestamp.isBetween(last7DaysStart, todayStart)) {
			last7Days.push({
				...notification,
				notificationTypeByTime: NOTIFICATION_TYPE_BY_TIME.LAST_7_DAYS,
			});
		} else {
			older.push({
				...notification,
				notificationTypeByTime: NOTIFICATION_TYPE_BY_TIME.OLDER,
			});
		}
	});

	return { today, last7Days, older };
};

export const convertWidgetTerms = (widget: WidgetBuilderDetailDto) => {
	const { metadata, terms } = widget;
	const { termsConditions: termsOlds = [] } = metadata.body;
	const emailOptIns =
		termsOlds.filter((item) => item.fieldName === EMAIL_OPT_IN.FIELD_NAME) || [];
	const termConditions: TermConditionItemDto[] = (terms || []).map((item: TermsTypes) => ({
		...item,
		text: item.agreementMessage || '',
		textLink: item.hyperlinkText || '',
		type: item.displayType || '',
		url: item.hyperlinkUrl || '',
		required: !!item.isTermsMandated,
		textPopup: item?.displayType === DISPLAY_TYPES.POPUP ? item?.documentBody || '' : '',
	}));
	metadata.body.termsConditions = [...termConditions, ...emailOptIns];
	return { ...widget, metadata };
};

export const getUrlImageById = (query: string) => {
	const elm: any = document.querySelector(query);

	if (elm) {
		return elm.src;
	}
	return '';
};

export const downloadFile = (link: string, fileName: string) => {
	const a = document.createElement('a'); // Create <a>
	a.href = link; // Image Base64 Goes here
	a.download = fileName; // File name Here
	a.click();
};

export const rotateImageToBase64 = (link: string, rotate: number) => {
	return new Promise((resolve, reject) => {
		try {
			const canvas = document.createElement('canvas');
			const ctx: any = canvas.getContext('2d');

			const image = new Image();
			image.src = link;
			image.crossOrigin = 'anonymous';
			image.onload = () => {
				canvas.width = rotate % 180 === 0 ? image.width : image.height;
				canvas.height = rotate % 180 === 0 ? image.height : image.width;

				ctx.translate(canvas.width / 2, canvas.height / 2);
				ctx.rotate((rotate * Math.PI) / 180);
				ctx.drawImage(image, image.width / -2, image.height / -2);

				const base64 = canvas.toDataURL();
				resolve(base64);
			};
			image.onerror = () => {
				resolve(undefined);
			};
		} catch (e) {
			resolve(undefined);
		}
	});
};

export const generateRandomElementIdentification = (length: number) => {
	const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let result = '';
	for (let i = 0; i < length; i += 1) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return result;
};

export const capitalizeFirstLetter = (value: string) => {
	if (!value) {
		return value;
	}
	return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
};

export const validateFormAllFieldHelper = async (form: any) => {
	const values = form.getFieldsValue();

	const listKey = Object.keys(values);
	const dataFormat: any = {};
	listKey?.forEach((item) => {
		if (values[item] && typeof values[item] === 'string') {
			dataFormat[item] = values[item]?.trim();
		} else {
			dataFormat[item] = values[item];
		}
	});
	form.setFieldsValue(dataFormat);
	await form.validateFields();

	form.submit();
};

export const extractCLandGID = (link: string) => {
	if (link.includes('cl') && link.includes('gid')) {
		const url = new URL(link);
		const cl = url.searchParams.get('cl');
		const gid = url.searchParams.get('gid');
		return { cl, gid };
	}

	return null;
};

export const limitNumberInput = (val: string | number | null) => {
	const num = +(val || 0);
	const arrNum = num.toString().split('.');
	if (
		arrNum[0].length > LIMIT_NUMBER_INPUT ||
		(arrNum[0].length === LIMIT_NUMBER_INPUT && arrNum[1])
	) {
		let loopVal = Math.floor(num);
		while (loopVal.toString().length > LIMIT_NUMBER_INPUT) {
			loopVal = Math.floor(loopVal / 10);
		}
		return loopVal;
	}

	return val;
};

export const getNumberFromString = (str?: string | null) => {
	if (!str) {
		return 0;
	}

	const matches = str.match(/\d+/g);

	if (matches && matches[0]) {
		const result = +matches[0];
		if (isNaN(result)) {
			return 0;
		}
		return result;
	}
	return 0;
};

export const getFileNameFromUrl = (name?: string | null) => {
	if (!name) {
		return '';
	}
	const idx = name.lastIndexOf('/');
	if (idx !== -1) {
		return name.slice(idx + 1);
	}
	return name;
};

export const removeLeadingWhitespaceOnCopy = () => {
	document.addEventListener('copy', (event: ClipboardEvent) => {
		const selection = document.getSelection();
		if (selection) {
			event?.clipboardData?.setData('text/plain', selection?.toString().trim());
			event.preventDefault();
		}
	});
};

export const isValidHttpUrl = (url: string) => {
	let parsedUrl;
	try {
		parsedUrl = new URL(url);
	} catch (_) {
		return false;
	}
	return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
};

export const getPathnameFromURL = (url: string) => {
	if (!url || !isValidHttpUrl(url)) {
		return '';
	}

	const parsedUrl = new URL(url);
	const pathname = parsedUrl.pathname;
	return pathname.substring(1);
};

export const splitStringAtPosition = (description: any, maxCharacterShown?: number) => {
	const MAX_CHARACTERS_SHOWN = 80;
	maxCharacterShown = maxCharacterShown || MAX_CHARACTERS_SHOWN;
	return typeof description === 'string' && description?.length > maxCharacterShown
		? `${description.substring(0, maxCharacterShown)}...`
		: description;
};
