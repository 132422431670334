import { PLACEHOLDER_IMAGE_IN_BASE64, UNIT_VALUE } from '@constants/common';
import { ACCEPT_FILES, SELECTOR_ELEMENTS, SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import THEME from '@constants/themes/themes';
import { checkIsUrl } from '@helpers/base64Helper';
import { ContentMediaRef } from '@models/content/contentLibrary/ref';
import { ContentSocialDto } from '@models/content/contentLibrary/summary';
import { StyledSkeletonImage } from '@styled/Common/CommonStyled';
import {
	StyledImage,
	StyledVideo,
	StyledWrapperPosition,
} from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	getSocialS3URL,
	handleImageLoadedError,
	handleThumbImageLoadedError,
	handleVideoLoadedError,
} from '@utils/common';
import { getImageView } from '@utils/content';
import { useWindowSize } from '@utils/customHooks';
import { fetchPosterOfVideo } from '@utils/funcHelper';
import { isNull } from 'lodash';
import {
	ReactNode,
	Ref,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';

type PropTypes = {
	children: ReactNode;
	contentUrl: string;
	isVideo?: boolean;
	currentRef?: number;
	setClassImage?: (val: string) => void;
	classThumb?: string;
	classChangeSize?: string;
	rotate?: number | null;
	social?: ContentSocialDto;
	contentExternalUrl?: string | null;
	onChangeMediaSize?: (val: number) => void;
	setVisibleImage: (val: boolean) => void;
	visibleImage: boolean;
	callbackLoadError?: (val: string) => void;
	currentActive?: boolean;
	idContent?: number;
	isReplaceImage?: boolean;
	// group
	isArrayElm?: boolean;
	imgRef: any;
	videoRef: any;
	loadSkeleton?: boolean;
};

const DetectContentUrl = forwardRef((props: PropTypes, ref: Ref<ContentMediaRef>) => {
	const {
		children,
		contentUrl,
		isVideo,
		currentRef = 0,
		setClassImage,
		classThumb = '',
		classChangeSize = '',
		social,
		rotate,
		contentExternalUrl,
		onChangeMediaSize,
		visibleImage,
		setVisibleImage,
		imgRef,
		videoRef,
		callbackLoadError,
		currentActive = true,
		isArrayElm,
		idContent,
		isReplaceImage,
		loadSkeleton,
	} = props;

	useImperativeHandle(ref, () => ({}));

	const refError = useRef<any>(true);
	const refLoadImg = useRef<any>(true);
	const refSource = useRef<any>({});

	const [widthWindow, heightWindow] = useWindowSize();

	const [srcContent, setSrcContent] = useState<string>(getSocialS3URL(contentUrl));
	const [rotateChange, setRotateChange] = useState<number | undefined | null>(rotate);
	const [isRendered, setIsRendered] = useState<boolean>(false);

	const hasLoadingSkeleton = useMemo(() => {
		if (visibleImage) {
			return visibleImage;
		}
		return !!loadSkeleton && !isRendered;
	}, [loadSkeleton, isRendered, visibleImage]);

	useEffect(() => {
		const newUrl = getSocialS3URL(contentUrl);

		if (srcContent !== newUrl) {
			refError.current = true;
			refLoadImg.current = true;

			setIsRendered(false);
			setVisibleImage(true);
			setSrcContent(newUrl);
		}

		return () => {
			setIsRendered(false);
		};
	}, [contentUrl]);

	useEffect(() => {
		if (rotateChange !== rotate && !refLoadImg.current) {
			setRotateChange(rotate);
		}
	}, [rotate]);

	useEffect(() => {
		if (isVideo) {
			detectVideoView();
		} else {
			detectImage();
		}
	}, [rotateChange]);

	useEffect(() => {
		return () => {
			setVisibleImage(true);
			setIsRendered(false);
			refError.current = true;
		};
	}, []);

	useEffect(() => {
		if (!visibleImage) {
			if (isVideo) {
				if (videoRef.current) {
					let heightTemp = 0;
					if (isArrayElm) {
						heightTemp = videoRef.current[currentRef]?.offsetHeight || 0;
					} else {
						heightTemp = videoRef.current?.offsetHeight || 0;
					}
					if (heightTemp) {
						handleMediaSize(heightTemp);
					}
				}
			}
			if (!isVideo) {
				if (imgRef.current) {
					let heightTemp = 0;
					if (isArrayElm) {
						heightTemp = imgRef.current[currentRef]?.offsetHeight || 0;
					} else {
						heightTemp = imgRef.current?.offsetHeight || 0;
					}
					if (heightTemp) {
						handleMediaSize(heightTemp);
					}
				}
			}
		}
	}, [classChangeSize, visibleImage]);

	const handleRender = () => {
		setTimeout(() => {
			setIsRendered(true);
		}, UNIT_VALUE.S_100);
	};

	const handleMediaSize = (val: number) => {
		if (onChangeMediaSize && typeof onChangeMediaSize === 'function') {
			onChangeMediaSize(val);
		}
	};

	const handleChangeClass = (val: string) => {
		if (setClassImage && typeof setClassImage === 'function') {
			setClassImage(val);
		}
	};

	const detectVideoView = () => {
		if (videoRef.current && videoRef?.current) {
			let width = 0;
			let height = 0;
			if (isArrayElm) {
				if (videoRef?.current[currentRef]) {
					width = videoRef.current[currentRef]?.videoWidth;
					height = videoRef.current[currentRef]?.videoHeight;
				} else {
					return;
				}
			} else {
				width = videoRef.current?.videoWidth;
				height = videoRef.current?.videoHeight;
			}
			handleChangeClass(getImageView(width, height));

			setVisibleImage(false);
			handleRender();
		}
	};

	const detectImage = () => {
		if (contentUrl && imgRef.current) {
			let width = 0;
			let height = 0;

			if (isArrayElm) {
				if (imgRef.current[currentRef]) {
					width = imgRef.current[currentRef].naturalWidth;
					height = imgRef.current[currentRef].naturalHeight;
				} else {
					return;
				}
			} else {
				width = imgRef.current.naturalWidth;
				height = imgRef.current.naturalHeight;
			}
			if (rotate && rotate % 180 !== 0) {
				handleChangeClass(getImageView(height, width));
			} else {
				handleChangeClass(getImageView(width, height));
			}

			setVisibleImage(false);
			handleRender();
		}
	};

	const processVideoLoadedError = async (e: any) => {
		const socialSource = social ? social.socialSource.toUpperCase() : '';
		if (!social) {
			const src = await handleImageLoadedError(e, contentUrl, contentExternalUrl, null, true);
			handleSaveUrl(src);
		} else if (
			socialSource === SOCIAL_SOURCE.TIKTOK.toUpperCase() ||
			socialSource === SOCIAL_SOURCE.YOUTUBE.toUpperCase()
		) {
			const src = await handleVideoLoadedError(
				e,
				socialSource.toLowerCase(),
				social.permalink,
				social,
			);

			handleSaveUrl(src);
		} else if (socialSource === SOCIAL_SOURCE.INSTAGRAM.toUpperCase()) {
			const src = await handleVideoLoadedError(
				e,
				SOCIAL_SOURCE.INSTAGRAM,
				social.permalink,
				social,
			);
			handleSaveUrl(src);
		}
	};

	const handleSaveUrl = (val: string) => {
		if (callbackLoadError && typeof callbackLoadError === 'function') {
			callbackLoadError(val);
		}

		setVisibleImage(true);
		setIsRendered(false);
		setSrcContent(val || PLACEHOLDER_IMAGE_IN_BASE64);
	};

	const handleError = async (e: any) => {
		setIsRendered(false);
		if (isReplaceImage) {
			handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
		}
		if (refError.current) {
			refError.current = false;
			if (isVideo) {
				processVideoLoadedError(e);
			} else {
				if (social && SOCIAL_SOURCE.TIKTOK === social.socialSource) {
					const newUrl = await handleThumbImageLoadedError(
						e,
						SOCIAL_SOURCE.TIKTOK,
						social.permalink,
					);
					handleSaveUrl(newUrl);
					return;
				}

				const newUrl = await handleImageLoadedError(
					e,
					srcContent,
					contentExternalUrl,
					social?.socialSource,
				);

				handleSaveUrl(newUrl);
			}
		} else {
			handleSaveUrl(PLACEHOLDER_IMAGE_IN_BASE64);
		}
	};

	const renderClassName = (isVideo?: boolean) => {
		const classThumb = isVideo ? 'video-content-detail' : 'image-content-detail';

		return `${classThumb} ${classChangeSize} ${classThumb} ${
			visibleImage ? ' place_img' : ''
		} ${hasLoadingSkeleton ? 'had_loading' : ''}`;
	};

	useEffect(() => {
		const elmVideo = document.getElementById(
			SELECTOR_ELEMENTS.VIDEO_CONTENT_DETAIL_ID,
		) as HTMLVideoElement | null;

		if (isVideo && elmVideo && typeof elmVideo?.load === 'function') {
			elmVideo.load();
		}
	}, [srcContent]);

	const renderVideoTag = () => {
		return (
			<StyledVideo
				id={SELECTOR_ELEMENTS.VIDEO_CONTENT_DETAIL_ID}
				ref={(element) => {
					if (!isNull(element)) {
						if (isArrayElm) {
							const includeVideo = videoRef.current.includes(element);
							const idx = videoRef.current.indexOf(element);
							if (includeVideo) {
								videoRef.current[idx] = element;
							} else {
								videoRef.current.push(element);
							}
						} else {
							videoRef.current = element;
						}
					}
				}}
				onLoadedMetadata={() => {
					if (currentActive) {
						detectVideoView();
					}
				}}
				preload={'auto'}
				tabIndex={-1}
				playsInline
				className={renderClassName(true)}
				onError={(e) => {
					handleError(e);
				}}
				autoPlay={false}
				controls>
				<source
					ref={refSource}
					src={`${srcContent}#t=0.01`}
					type={ACCEPT_FILES.MINE_TYPE_MP4}
				/>
			</StyledVideo>
		);
	};
	return (
		<StyledWrapperPosition className="position_media">
			{isVideo && renderVideoTag()}
			{!isVideo && (
				<StyledImage
					ref={(element) => {
						if (!isNull(element)) {
							if (isArrayElm) {
								if (!imgRef.current.includes(element)) {
									imgRef.current.push(element);
								}
							} else {
								imgRef.current = element;
							}
						}
					}}
					className={renderClassName()}
					visibleImage={visibleImage}
					rotate={rotate}
					src={srcContent}
					onError={(e) => handleError(e)}
					onLoad={() => {
						refLoadImg.current = false;
						if (rotate !== rotateChange) {
							setRotateChange(rotate);
						}
						detectImage();
					}}
				/>
			)}

			{hasLoadingSkeleton && (
				<StyledSkeletonImage
					className="ant-skeleton-active"
					bgColor={THEME.colors.skeletonBgDark}
					bgImg={THEME.colors.linearGradientDark}
				/>
			)}
			{!hasLoadingSkeleton && <>{children}</>}
		</StyledWrapperPosition>
	);
});

export default DetectContentUrl;
