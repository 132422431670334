import THEME from '@constants/themes/themes';
import { Modal as AntModal } from 'antd';
import Button from '@cores/Button';
import styled from 'styled-components';
import { StyledBtnGhost } from '@styled/Content/ContentLibrary/ContentStyled';

const StyledModal = styled(AntModal)<{
	backgroundColor?: string;
	padding?: string;
	height?: number;
	borderHeader?: string;
}>`
	.ant-modal-content {
		background-color: ${({ backgroundColor }) => backgroundColor || 'none'};

		.ant-modal-body {
			height: ${({ height }) => `${height}px` || 'unset'};
			padding: ${({ padding }) => padding || '24px'};
		}

		.ant-modal-header {
			background-color: ${({ backgroundColor }) => backgroundColor || 'none'};
			border-bottom: ${({ borderHeader }) =>
				borderHeader || `1px solid ${THEME.colors.darkBlue3}`};
		}

		.ant-modal-footer {
			padding: 12px 24px;
			border-color: ${THEME.colors.darkBlue4};

			.ant-btn {
				&.ant-btn-ghost {
					:hover {
						color: unset;
					}
				}
			}

			.ant-btn-loading-icon {
				svg {
					fill: #e0e3e5;
				}
			}
		}
	}
`;

type Props = {
	title?: string;
	isOpen: boolean;
	onSubmit?: Function;
	onClose?: Function;
	width?: number;
	height?: number;
	okText?: string;
	cancelText?: string;
	backgroundColor?: string;
	closable?: boolean;
	borderHeader?: string;
	isFooter?: boolean;
	padding?: string;
	isLoading?: boolean;
	destroyOnClose?: boolean;
};

const Modal: React.FC<Props> = ({
	children,
	title,
	isOpen,
	onSubmit,
	onClose,
	width,
	height,
	okText,
	cancelText,
	backgroundColor,
	borderHeader,
	closable,
	isFooter,
	padding,
	isLoading,
	destroyOnClose,
}) => {
	const handleOk = () => {
		if (typeof onSubmit === 'function') {
			onSubmit();
		}
	};

	const handleCancel = () => {
		if (typeof onClose === 'function') {
			onClose();
		}
	};

	return (
		<StyledModal
			centered={true}
			backgroundColor={backgroundColor}
			borderHeader={borderHeader}
			width={width}
			height={height}
			title={title || null}
			visible={isOpen}
			onOk={handleOk}
			onCancel={handleCancel}
			closable={closable}
			padding={padding}
			footer={
				isFooter
					? [
							<StyledBtnGhost
								type="ghost"
								disabled={isLoading}
								onClick={handleCancel}>
								{cancelText}
							</StyledBtnGhost>,
							<Button type="primary" loading={isLoading} onClick={handleOk}>
								{okText}
							</Button>,
					  ]
					: null
			}
			destroyOnClose={destroyOnClose}>
			{children}
		</StyledModal>
	);
};

Modal.defaultProps = {
	onSubmit: () => {},
	onClose: () => {},
	closable: false,
	isFooter: true,
	isLoading: false,
	destroyOnClose: false,
};

export default Modal;
