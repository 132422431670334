export type ThemeType = {
	colors: {
		primary: string;
		secondary: string;
		dark: string;
		gray: string;
		lightGray: string;
		lighterGray: string;
		white: string;
		blue: string;
		lightBlue: string;
		darkBlue: string;
		darkerBlue: string;
		black: string;
		blueBase: string;
		greenBase: string;
		green1: string;
		redBase: string;
		red1: string;
		red2: string;
		orangeBase: string;
		yellowBase: string;
		yellow1: string;
		violetBase: string;
		darkBlue1: string;
		darkBlue2: string;
		darkBlue3: string;
		darkBlue4: string;
		darkBlue5: string;
		darkBlue6: string;
		gray1: string;
		gray2: string;
		gray3: string;
		gray4: string;
		gray5: string;
		gray6: string;
		grayCustom1: string;
		googleYellow: string;
		pink1: string;
		transparent: string;
		skeletonBgDark: string;
		linearGradientDark: string;
	};
	fonts: {
		primary: string;
		secondary: string;
	};
	fontSizes: {
		h1: string;
		h2: string;
		h3: string;
		h4: string;
		h5: string;
		large: string;
		normal: string;
		small: string;
		xLarge: string;
	};
	fontWeight: {
		normal: number;
		bold: number;
		h2: number;
		h1: number;
	};
	spaces: Array<string>;
	disabledOpacity: number;
	hoveredOpacity: number;
};

const THEME: ThemeType = {
	colors: {
		primary: '#e2582b',
		secondary: '#70C2F2',
		dark: '#020c15',
		gray: '#575757',
		lightGray: '#C4C4C4',
		lighterGray: '#F5F5F5',
		white: '#FFFFFF',
		blue: '#1392DB',
		lightBlue: '#A8DFFF',
		darkBlue: '#021627',
		darkerBlue: '#020c15',
		black: '#000',
		blueBase: '#1890ff',
		greenBase: '#52c41a',
		green1: '#34A853',
		redBase: '#f5222d',
		red1: '#EA4335',
		red2: 'rgb(234,67,53)',
		orangeBase: '#e2582b',
		yellowBase: '#faad14',
		yellow1: '#F89B29',
		violetBase: '#7f61fb',
		darkBlue1: '#020c15',
		darkBlue2: '#011627',
		darkBlue3: '#203241',
		darkBlue4: '#344552',
		darkBlue5: '#4d5b67',
		darkBlue6: '#181d27',
		gray1: '#67737d',
		gray2: '#808a93',
		gray3: '#a3abb1',
		gray4: '#ccd0d4',
		gray5: '#e0e3e5',
		gray6: '#f0f0f0',
		grayCustom1: '#82898e',
		googleYellow: '#fbbc05',
		pink1: '#FF0F7B',
		transparent: 'transparent',
		skeletonBgDark: 'rgba(190, 190, 190, 0.2)',
		linearGradientDark:
			'linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%)',
	},
	fonts: {
		primary: 'Open Sans',
		secondary: 'Open Sans',
	},
	fontSizes: {
		h1: '56px',
		h2: '42px',
		h3: '24px',
		h4: '22px',
		h5: '18px',
		large: '16px',
		normal: '14px',
		small: '12px',
		xLarge: '18px',
	},
	spaces: ['0px', '2px', '4px', '8px', '12px', '16px', '20px', '24px', '28px', '32px'],
	fontWeight: {
		normal: 400,
		bold: 600,
		h2: 700,
		h1: 900,
	},
	disabledOpacity: 0.6,
	hoveredOpacity: 0.8,
};

export default THEME;
