import THEME from '@constants/themes/themes';
import { StyledInputNumber } from '@styled/Common/CommonStyled';
import { Checkbox, Form, Input, Modal, Radio, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

const POSITION_ARROW = {
	SLIDER: 8,
	GRID: -7,
};
const WIDTH_SIDERBAR = 360;

const WIDTH_PREVIEW = 892;

const StyledPreviewGalleryModal = styled(Modal)`
	max-width: 1350px;
	.ant-modal-body {
		padding: 0;
		display: flex;
		height: calc(100vh - 150px);
	}
`;

const StyledPreviewUploaderModal = styled(Modal)`
	.ant-modal-body {
		padding: 0;
		height: 85vh;
	}
`;

const StyledIframeUploader = styled.iframe`
	width: 100%;
	height: 100%;
	border: 0;
	border-radius: 8px;
	overflow: hidden;
`;

const StyledSidebarPreview = styled.div`
	width: ${WIDTH_SIDERBAR}px;
	position: relative;
	border-right: 1px solid ${THEME.colors.darkBlue3};
`;

const StyleNameGallery = styled.div`
	padding: 26px 33px;
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	color: ${THEME.colors.white};
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
`;

const StyledContainerPreview = styled.div`
	width: calc(100% - ${WIDTH_SIDERBAR}px);
	overflow: auto;
	height: 100%;
`;

const StyledNameCustom = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: ${THEME.colors.gray5};
	margin-bottom: 8px;
`;

const StyledCustomGallery = styled.div`
	padding: 20px 33px;
	height: 100%;
	overflow: auto;
`;

const StyledThemeItem = styled.div`
	cursor: pointer;
	text-align: center;
	&:not(:first-child) {
		margin-left: 24px;
	}
`;

const StyleUploader = styled.div``;

const StyleUploaderItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	&:not(:first-child) {
		margin-top: 12px;
	}
`;

const StyledTheme = styled.div`
	display: flex;
`;

const StyledNameTheme = styled.div`
	font-size: 14px;
	line-height: 21px;
	color: ${THEME.colors.gray1};
`;

const StyledCustomItem = styled.div<{ margin?: string }>`
	margin: ${({ margin }) => (margin ? margin : '16px 0 0 0')};
`;

const StyledNameUploader = styled.span`
	margin-left: 10px;
	font-size: 12px;
	line-height: 16px;
`;

const StyledActionFooter = styled.div`
	position: absolute;
	bottom: 12px;
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 8px 0px;
	background: ${THEME.colors.darkBlue2};
`;

const StyledCustomHyperLink = styled.div`
	margin-top: 16px;
`;

const StyledFormItem = styled(Form.Item)<{ margin?: string; showRequired?: boolean }>`
	margin: ${({ margin }) => margin || '0 0 12px 0'};
	display: block;
	&.custom_form_preview {
		margin-bottom: 16px;
		.ant-form-item-control-input {
			min-height: unset;
		}
		.ant-form-item-label {
			margin: 0;
			& > label {
				&::after {
					display: none;
				}
			}
		}
	}
	.ant-form-item-label {
		height: unset;
		padding: 0;
		margin: 0 0 6px 0;
		width: 100%;

		label {
			width: 100%;
		}
		& > label {
			height: unset;
			min-height: 32px;
			&.ant-form-item-required {
				&:not(.ant-form-item-required-mark-optional) {
					&::before {
						display: none;
					}
					&::after {
						display: ${({ showRequired }) => (showRequired ? 'inline-block' : 'none')};
						color: ${THEME.colors.redBase};
						font-size: 14px;
						font-family: SimSun, sans-serif;
						line-height: 1;
						content: '*';
					}
				}
			}
		}
	}
	&.label_full {
		.ant-form-item-label {
			width: 100%;
		}
	}
`;

const StyledSelect = styled(Select)<{
	width?: string;
	margin?: string;
	notEvent?: boolean;
	isMobile?: boolean;
}>`
	padding: 0;
	margin: ${({ margin }) => margin || 'unset'};
	width: ${({ width }) => `${width} !important` || '100%'};
	${({ notEvent }) => notEvent && 'pointer-events: none'};

	.ant-select-dropdown {
		&.dropdown-custom-content {
			overflow: auto !important;
			.rc-virtual-list-holder {
				max-height: calc(50vh - 160px) !important;
				.rc-virtual-list-holder-inner {
					transform: translateY(0px) !important;
				}
			}
			.rc-virtual-list-scrollbar {
				display: none !important;
			}
		}
	}
`;

const StyledInput = styled(Input)``;

const StyleLabelForm = styled.div<{ margin?: string }>`
	width: fit-content;
	font-size: 12px;
	line-height: 16px;
	color: ${THEME.colors.gray1};
	${({ margin }) => margin && `margin: ${margin}`};
`;

const StyledWrapperLabelForm = styled.div<{ marginBottom?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: ${({ marginBottom }) => marginBottom || '16px'};
`;

const StyledTitleLabel = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: ${THEME.colors.gray5};
	margin-right: 8px;
`;

const StyledWrapperUploadLink = styled.div`
	margin-bottom: 16px;
`;

const StyledContainerLabel = styled.div`
	width: 100%;
`;

const StyledSubLabel = styled.div`
	margin-bottom: 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${THEME.colors.gray5};
`;

const StyledWrapperPreview = styled.div`
	height: 100%;
	overflow: auto;
`;

const StyledPreviewGallery = styled.div`
	width: 100%;
	height: 100%;
	padding: 40px;
`;

const StyleContainerCarousel = styled.div<{ rows: number; bgColor?: string }>`
	width: 100%;
	background: ${({ bgColor }) => bgColor || THEME.colors.white};
	position: relative;
	text-align: center;
	.slider_custom_gallery {
		min-height: 206px;
		.slick-track {
			min-width: 100% !important;
		}
		.slick-slide {
			min-height: 200px;
			overflow: hidden;
		}
	}
	&.slider_carousel {
		max-width: 1200px;
		overflow: hidden;
		min-height: 375px;
		max-height: unset;
		width: 100%;
		&.theme_grid {
			.wrapper_slider {
				min-height: ${({ rows }) => 200 * rows}px;
				height: ${({ rows }) => 200 * rows}px;
				overflow: hidden;
				.slick-list {
					height: ${({ rows }) => 200 * rows}px;
					overflow: hidden;
				}
			}
		}

		.slick-track {
			margin: unset;
		}

		&.theme_slider {
			padding: 48px 24px;
			.slick-list {
				height: 200px !important;
				overflow: hidden;
				.slick-slide {
					height: 200px;
				}
			}
			.image_carousel {
				transition: 0.15s;
				padding: 32px 16px;

				&.image_left {
					padding: 32px 16px 32px 0;

					.explicit_text {
						margin-top: -12px;
					}
				}
				&.image_right {
					padding: 32px 0 32px 16px;
				}
				&.image_carousel_center {
					padding: 0;
				}
				&.image_carousel_left {
					padding: 16px 16px 16px 0;

					.explicit_icon,
					.explicit_text {
						margin-top: -8px;
					}
				}
				&.image_carousel_right {
					padding: 16px 0px 16px 16px;

					.explicit_icon,
					.explicit_text {
						margin-top: -8px;
					}
				}
			}
		}

		&.theme_mosaic {
			.wrapper_slider {
				min-height: 400px;
				max-height: 442px;
				overflow: hidden;
			}
		}

		&.theme_signage {
			padding: 15px !important;

			.wrapper_slider {
				min-height: 400px;
				max-height: 442px;
				overflow: hidden;
			}
		}

		&:not(.theme_slider) {
			.slick-list {
				height: fit-content !important;
				overflow: hidden;
			}

			.icon_play {
				transform: translate(-50%, -50%);
			}
			.slick-prev {
				left: ${POSITION_ARROW.GRID}px;
			}
			.slick-next {
				right: ${POSITION_ARROW.GRID}px;
			}
			padding: 10px 20px;
			.footer_slider {
				margin: 6px;
				justify-content: left;
			}
			.btn-arrow_left {
				left: ${POSITION_ARROW.GRID}px;
			}
			.btn-arrow_right {
				right: ${POSITION_ARROW.GRID}px;
			}
			.slick-list {
				.image_carousel {
					padding: 6px;
				}
			}
		}
	}

	.slick-prev {
		left: ${-POSITION_ARROW.SLIDER}px;
		z-index: 50;
	}
	.slick-next {
		right: ${-POSITION_ARROW.SLIDER}px;
		z-index: 50;
	}
	.slick-arrow {
		width: 24px;
		height: 24px;
		&::before {
			display: none;
		}
		&.slick-disabled {
			pointer-events: none;
		}
	}
`;

const StyledWrapperNextPrev = styled.div``;

const StyledWrapperSlider = styled.div`
	position: relative;
	min-height: 200px;
	padding: 0 10px;
	.icon-slick_arrow {
		width: 24px;
		height: 24px;
	}
`;

const StyledWrapperImageCarousel = styled.div`
	height: 200px;
	overflow: hidden;
	display: inline-flex !important;
`;

const StyledIconPlay = styled.div`
	position: absolute;
	height: 30px;
	width: 30px;
	left: 50%;
	top: 50%;
	background-color: ${THEME.colors.darkBlue2};
	border-radius: 50%;
	transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
	transform: translate(-50%, -50%);
	svg {
		fill: ${THEME.colors.gray5};
		width: 16px !important;
	}
	display: inline-flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
`;

const StyledWrapperThumbnail = styled.div<{ keyStart?: number; cursor?: string }>`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 2px;
	overflow: hidden;
	cursor: ${({ cursor }) => cursor || 'unset'};
	word-break: break-all;
	&:hover {
		.mark_hover {
			opacity: 1;
		}
	}
	&.item_full {
		grid-column: ${({ keyStart }) => keyStart || 1} / span 2;
		grid-row: 1 / span 2;
	}
`;

const StyledMarkHoverThumbnail = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	opacity: 0;
	background: rgba(255, 255, 255, 0.75);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: left;
	z-index: 3334;
	transition: 0.25s ease-out;
`;

const StyledInfoItem = styled.a`
	display: inline-flex;
	align-items: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: ${THEME.colors.darkBlue4};
	svg {
		path {
			fill: ${THEME.colors.darkBlue4};
		}
	}
	&:hover {
		color: ${THEME.colors.orangeBase};
		svg {
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}
`;

const StyledWrapperCarousel = styled.div`
	/* width: ${WIDTH_PREVIEW}px; */
	width: 100%;
	padding: 0px;
	justify-content: center;
	display: flex;
	align-items: center;
	min-height: 100%;
	margin: 0 auto;
`;

const StyledBgEmpty = styled.div`
	width: 100%;
	background: transparent;
	height: 100%;
	border-radius: 2px;
`;

const StyledFooterCarousel = styled.div`
	margin: 16px auto;
	color: ${THEME.colors.black};
	font-style: italic;
	font-weight: 400;
	font-size: 8.70505px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 10px;
	transition: 0.25s ease-out;
`;

const StyledImageLogoFooter = styled.img`
	height: 14px;
	margin-left: 7px;
`;

const StyledHyperlinkWidget = styled.a`
	font-family: 'Ropa Sans', sans-serif;
	font-size: 1.5rem;
	color: ${THEME.colors.black};
	text-decoration: underline;
	text-align: center;
	margin-bottom: 12px;
	display: inline-block;
`;

const StyledRadioGroup = styled(Radio.Group)``;

const StyledRadio = styled(Radio)`
	.ant-radio-inner {
		&::after {
			left: 2.5px;
		}
	}
`;

const StyledCheckboxGroup = styled(Checkbox.Group)<{ gap?: string }>`
	&.custom_checkbox {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&.overflow {
		max-height: 185px;
		overflow: auto;
	}
`;

const StyledCheckbox = styled(Checkbox)<{ fontSize?: string }>`
	width: auto;
	margin-right: 15px;
	margin-left: 0 !important;
	color: ${THEME.colors.gray3};

	.ant-checkbox + span {
		font-size: ${({ fontSize }) => fontSize || '1rem'};
	}

	.ant-checkbox-inner {
	}

	.ant-checkbox-disabled + span {
		color: ${THEME.colors.darkBlue5} !important;
	}
`;

const StyledForm = styled(Form)`
	&.custom_form_preview {
		height: calc(100% - 156px);
	}
`;

const StyledInputSpeed = styled(StyledInputNumber)`
	position: relative;

	&::before {
		position: absolute;
		content: 'seconds';
		right: 26px;
		top: 50%;
		transform: translateY(-50%);
	}
`;

const StyledTextArea = styled(TextArea)``;

const StyledWrapperSelectBg = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 6px 0 16px 0;
	.popover_custom_color {
		margin-right: 0;
	}
`;

export {
	StyledPreviewGalleryModal,
	StyledIframeUploader,
	StyledPreviewUploaderModal,
	StyledSidebarPreview,
	StyleNameGallery,
	StyledContainerPreview,
	StyledNameCustom,
	StyledCustomGallery,
	StyledCustomItem,
	StyledThemeItem,
	StyledTheme,
	StyledNameTheme,
	StyleUploader,
	StyleUploaderItem,
	StyledNameUploader,
	StyledActionFooter,
	StyledCustomHyperLink,
	StyledFormItem,
	StyledInput,
	StyleLabelForm,
	StyledSelect,
	StyledWrapperPreview,
	StyleContainerCarousel,
	StyledWrapperCarousel,
	StyledFooterCarousel,
	StyledImageLogoFooter,
	StyledBgEmpty,
	StyledWrapperImageCarousel,
	StyledHyperlinkWidget,
	StyledWrapperSlider,
	StyledIconPlay,
	StyledWrapperThumbnail,
	StyledMarkHoverThumbnail,
	StyledRadioGroup,
	StyledRadio,
	StyledCheckboxGroup,
	StyledCheckbox,
	StyledForm,
	StyledWrapperLabelForm,
	StyledContainerLabel,
	StyledSubLabel,
	StyledWrapperUploadLink,
	StyledTitleLabel,
	StyledInfoItem,
	StyledPreviewGallery,
	StyledWrapperNextPrev,
	StyledInputSpeed,
	StyledTextArea,
	StyledWrapperSelectBg,
};
