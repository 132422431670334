import {
	IconSocialFacebook,
	IconSocialInstagram,
	IconSocialPinterest,
	IconSocialTikTok,
	IconSocialTwitter,
} from '@assets/icons';
import { ImagePlaceholder } from '@assets/images';
import { SOCIAL_NETWORK_URL } from '@constants/common';
import THEME from '@constants/themes/themes';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import {
	StyledCol,
	StyledFlex,
	StyledIcon,
	StyledLink,
	StyledRow,
} from '@styled/Common/CommonStyled';
import {
	StyledStartOverButton,
	StyledThumbnail,
} from '@styled/Content/WidgetManager/ConfirmationScreenStyled';
import { StyledMessage, StyledTitle } from '@styled/Content/WidgetManager/DetailWidgetStyled';
import { getUrlS3 } from '@utils/funcHelper';
import { useMemo } from 'react';
import FooterWidgetDefault from '../DetailWidget/FooterWidgetDefault';
import HeaderWidgetDefault from '../DetailWidget/HeaderWidgetDefault';

type ConfirmationScreenProps = {
	isMobile: boolean;
	detailWidget: WidgetBuilderDetailDto;
};

const ConfirmationScreen = (props: ConfirmationScreenProps) => {
	const { isMobile = false, detailWidget } = props;
	const { metadata } = detailWidget;
	const { bgSubmit = THEME.colors.orangeBase, buttonUrl } = metadata?.colors;
	const { srcLogo = '', sizeLogo } = metadata?.header;
	const {
		advancedSettings: {
			confirmationMessage = '',
			confirmationButtonText = '',
			socialMessage = '',
			visibleSocialButtons = false,
		} = {},
	} = metadata?.body;

	const socialList = [
		{
			id: 1,
			icon: <IconSocialInstagram />,
			href: SOCIAL_NETWORK_URL.INSTAGRAM,
		},
		{
			id: 2,
			icon: <IconSocialTikTok />,
			href: SOCIAL_NETWORK_URL.TIKTOK,
		},
		{
			id: 3,
			icon: <IconSocialTwitter />,
			href: SOCIAL_NETWORK_URL.TWITTER,
		},
		{
			id: 4,
			icon: <IconSocialFacebook />,
			href: SOCIAL_NETWORK_URL.FACEBOOK,
		},
		{
			id: 5,
			icon: <IconSocialPinterest />,
			href: SOCIAL_NETWORK_URL.PINTEREST,
		},
	];

	const dummyThumbnail = useMemo(() => {
		const list = [ImagePlaceholder];
		if (!isMobile) {
			list.push(ImagePlaceholder);
		}
		return list;
	}, [isMobile]);

	return (
		<>
			<HeaderWidgetDefault
				isMobile={isMobile}
				sizeLogo={sizeLogo}
				srcLogo={getUrlS3(srcLogo)}
			/>
			<StyledTitle margin="20px 0 0 0">{confirmationMessage}</StyledTitle>
			<StyledRow
				gutter={12}
				margin="24px auto 24px auto !important"
				width={isMobile ? '100%' : 'calc(100% - 100px)'}
				justify="center">
				{dummyThumbnail.map((item) => {
					return (
						<StyledCol className="none_line_height" span={isMobile ? 22 : 10}>
							<StyledThumbnail isMobile={isMobile} preview={false} src={item} />
						</StyledCol>
					);
				})}
			</StyledRow>
			{visibleSocialButtons && (
				<>
					<StyledMessage fontWeight="400">{socialMessage}</StyledMessage>
					<StyledFlex justify="center" margin="24px 0 24px 0">
						{socialList.map((item) => {
							return (
								<StyledLink href={item.href} target="_blank">
									<StyledIcon size={36} margin={isMobile ? '0 12px' : '0 18px'}>
										{item.icon}
									</StyledIcon>
								</StyledLink>
							);
						})}
					</StyledFlex>
				</>
			)}
			<StyledStartOverButton
				margin={isMobile ? '24px auto 0 auto' : '24px auto'}
				width={isMobile ? '100%' : 'calc(100% - 100px)'}
				type="default"
				backgroundUrl={getUrlS3(buttonUrl || '')}
				className={buttonUrl ? 'has_url' : ''}
				bgColor={bgSubmit}>
				{confirmationButtonText}
			</StyledStartOverButton>
			<FooterWidgetDefault
				detailWidget={detailWidget}
				isMobile={isMobile}
				isConfirmationScreen={true}
			/>
		</>
	);
};

export default ConfirmationScreen;
