import THEME from '@constants/themes/themes';
import Wrapper from '@cores/Wrapper';
import styled from 'styled-components';

const StyledContainer = styled(Wrapper)`
	padding: 0;
	margin-bottom: 16px;
	display: flex;
	justify-content: flex-end;
`;

const StyledButtonCopyWidgetURL = styled.div`
	border: 1px solid ${THEME.colors.darkBlue3};
	border-radius: 2px;
	color: ${THEME.colors.gray5};
	padding: 6px 15px 6px 16px;
	height: 32px;
	cursor: pointer;
	font-size: 1rem;
	font-weight: normal;
	font-style: normal;
	line-height: 1.43;
	display: flex;
	align-items: center;
	text-align: center;
	svg {
		margin-left: 16px;
	}
`;

export { StyledContainer, StyledButtonCopyWidgetURL };
