import { STATUS_MODES } from '@constants/common';
import THEME from '@constants/themes/themes';
import Wrapper from '@cores/Wrapper';
import { AlignItemsDto, JustifyContentDto } from '@models/common/style';
import {
	AutoComplete,
	Button,
	Checkbox,
	Col,
	Collapse,
	DatePicker,
	Divider,
	Drawer,
	Dropdown,
	Empty,
	Form,
	Input,
	InputNumber,
	Menu,
	Modal,
	Popconfirm,
	Radio,
	Row,
	Select,
	Skeleton,
	Slider,
	Space,
	Switch,
	Table,
	Tabs,
	Tag,
	Tooltip,
	Upload,
} from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import TextArea from 'antd/lib/input/TextArea';
import SkeletonImage from 'antd/lib/skeleton/Image';
import { isUndefined } from 'lodash';
import styled, { css } from 'styled-components';

const StyledSlider = styled(Slider)`
	.ant-slider-rail {
		height: 1px;
	}

	.ant-slider-track {
		height: 2px;
	}

	.ant-slider-handle {
		margin-top: -4px;
	}
`;

const StyledSelectDate = styled(DatePicker)`
	background: ${THEME.colors.darkBlue2};
	border-radius: 2px;
	width: 100%;
	.ant-picker-input {
		input {
			line-height: 32px;
			&::-webkit-input-placeholder {
				color: ${THEME.colors.gray1};
			}

			&:-ms-input-placeholder {
				color: ${THEME.colors.gray1};
			}

			&::placeholder {
				color: ${THEME.colors.gray1};
			}
		}
	}
`;

const StyledTextSelection = styled.div`
	padding: 4px 6px;
	margin-left: -6px;
	display: inline-block;
	cursor: text;
	&.overflow_text {
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		height: 19px;
		line-height: 19px;
		padding: 0 6px;
	}
	::-moz-selection {
		color: ${THEME.colors.white};
		background: ${THEME.colors.orangeBase};
	}

	::selection {
		color: ${THEME.colors.white};
		background: ${THEME.colors.orangeBase};
	}
`;

const StyledTitle = styled.div<{
	fontSize?: string;
	color?: string;
	margin?: string;
	cursor?: string;
	border?: string;
	fontWeight?: string;
	widthIcon?: string;
	height?: string;
	lineHeight?: string;
	padding?: string;
	display?: string;
	textDecoration?: string;
	fontStyle?: string;
	width?: string;
	maxWidth?: string;
	position?: string;
	colorDisabled?: string;
	moreLine?: number;
	wordBreak?: string;
	textAlign?: string;
}>`
	display: ${({ display }) => display || 'flex'};
	align-items: center;
	height: ${({ height }) => height || '24px'};
	font-stretch: normal;
	font-style: ${({ fontStyle }) => fontStyle || 'normal'};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	line-height: ${({ lineHeight }) => lineHeight || '1.429rem'};
	letter-spacing: normal;
	margin: ${({ margin }) => margin || '0'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	cursor: ${({ cursor }) => cursor};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ border }) => border && `border-${border}: 1px solid ${THEME.colors.darkBlue3}`};
	${({ textDecoration }) => textDecoration && `text-decoration: ${textDecoration}`};
	${({ width }) => width && `width: ${width}`};
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
	${({ position }) => position && `position: ${position}`};
	${({ wordBreak }) => wordBreak && `word-break: ${wordBreak}`};
	${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

	svg {
		width: ${({ widthIcon }) => widthIcon || '20px'};
		margin-right: 6px;
	}
	&.unset_height {
		height: unset;
		display: ${({ display }) => display || 'block'};
	}
	&.word_break {
		word-break: break-word;
	}
	&.text_action {
		cursor: pointer;
		&:hover {
			color: ${THEME.colors.orangeBase};
		}
	}
	&.overflow_text {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	&.overflow_more_line {
		display: -webkit-box;
		-webkit-line-clamp: ${({ moreLine }) => moreLine || 3};
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&.disabled {
		pointer-events: none;
		color: ${({ colorDisabled }) => colorDisabled || `${THEME.colors.gray5}`};
		opacity: 0.5;
		&:hover {
			color: inherit;
		}
	}
`;

const StyledTitleClick = styled.div<{
	fontSize?: string;
	color?: string;
	margin?: string;
	cursor?: string;
	border?: string;
	fontWeight?: string;
	height?: string;
	lineHeight?: string;
	padding?: string;
	display?: string;
	textDecoration?: string;
	fontStyle?: string;
}>`
	display: ${({ display }) => display || 'block'};
	font-style: ${({ fontStyle }) => fontStyle || 'normal'};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	margin: ${({ margin }) => margin || '0'};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	cursor: ${({ cursor }) => cursor};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ border }) => border && `border-${border}: 1px solid ${THEME.colors.darkBlue3}`};
	${({ textDecoration }) => textDecoration && `text-decoration: ${textDecoration}`};
	${({ height }) => height && `height: ${height}`};
	${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};

	&.word_break {
		word-break: break-word;
	}
	&.text_action {
		cursor: pointer;
		&:hover {
			color: ${THEME.colors.orangeBase};
		}
	}
	&.overflow_text {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
`;

const StyledSelect = styled(Select)<{
	width?: string;
	margin?: string;
	minWidth?: string;
	maxWidth?: string;
	bgSelector?: string;
}>`
	padding: 0;
	margin: ${({ margin }) => margin || 'unset'};
	width: ${({ width }) => (width ? `${width} !important` : '100%')};
	${({ minWidth }) => minWidth && `min-width: ${minWidth} !important`};
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth} !important`};

	&.invite_guest,
	&.tags_input {
		.ant-select-selector {
			height: 34px;
			overflow: hidden auto;
		}
	}

	&.keyword_operator {
		margin: 0;

		.ant-select-selector {
			border: none;
			padding: 0 10px 0 0;

			.ant-select-selection-item {
				font-weight: bold;
			}
		}

		&.ant-select-focused {
			outline: none !important;
			box-shadow: none !important;
			border: none !important;
		}
	}

	.ant-select-dropdown {
		&.dropdown-custom-content {
			overflow: auto !important;
			.rc-virtual-list-holder {
				max-height: calc(50vh - 160px) !important;
				.rc-virtual-list-holder-inner {
					transform: translateY(0px) !important;
					.ant-select-item-group {
						font-size: 10px;
						font-weight: 700;
						line-height: 20px;
					}
				}
			}
			.rc-virtual-list-scrollbar {
				display: none !important;
			}
		}
	}

	&.select_search {
		.ant-select-arrow {
			top: 45%;
		}
		.ant-select-selection-placeholder {
			color: ${THEME.colors.darkBlue5};
			font-size: 14px;
			font-weight: 400;
		}
	}

	.ant-select-selector {
		${({ bgSelector }) => bgSelector && `background-color: ${bgSelector} !important`};
	}
`;

const StyledDatePicker = styled(DatePicker.RangePicker)<{ bgColor?: string }>`
	&.ant-picker-range {
		${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
		input {
			text-overflow: ellipsis;
		}
	}
	.ant-picker-clear {
		${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
	}
	.ant-picker-panel-container {
		background: ${THEME.colors.darkBlue3};
		border-radius: 2px;
	}
	.ant-picker-input {
		input {
			&::-webkit-input-placeholder {
				color: ${THEME.colors.gray1};
			}

			&:-ms-input-placeholder {
				color: ${THEME.colors.gray1};
			}

			&::placeholder {
				color: ${THEME.colors.gray1};
			}
		}
	}
	&.no_bg {
		background-color: ${THEME.colors.transparent};
	}
`;

const StyledButton = styled(Button)<{
	isDisabled?: boolean;
	width?: string;
	margin?: string;
	padding?: string;
	bgColor?: string;
	border?: string;
	color?: string;
	colorActive?: string;
	bgColorActive?: string;
	borderColorActive?: string;
}>`
	${({ width }) => width && `width: ${width}`};
	margin: ${({ margin }) => margin || 'none'};
	padding: ${({ padding }) => padding || 'none'};
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
	opacity: ${({ isDisabled }) => (isDisabled ? THEME.disabledOpacity : 1)};
	&.ant-btn {
		${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
		${({ color }) => color && `color: ${color}`};
		${({ border }) => border && `border: ${border}`};
	}

	&.active {
		color: ${({ colorActive }) => colorActive || THEME.colors.orangeBase};
		border-color: ${({ bgColorActive }) => bgColorActive || THEME.colors.orangeBase};
		${({ bgColorActive }) => bgColorActive && `background-color: ${bgColorActive}`};

		svg {
			&,
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}

	&:not(.ant-btn.ant-btn-primary):hover,
	&:not(.ant-btn.ant-btn-primary):focus {
		&:not([disabled]) {
			svg {
				&,
				path {
					fill: ${THEME.colors.orangeBase};
				}
			}
		}
	}
`;

const StyledAction = styled(StyledTitle)<{ isDisabled?: boolean }>`
	color: #a3abb1;
	font-size: 0.857rem;
	margin-left: 16px;
	cursor: ${({ isDisabled }) => (isDisabled ? 'unset !important' : 'pointer')};
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none !important' : 'auto')};
	opacity: ${({ isDisabled }) => (isDisabled ? '0.5 !important' : 'unset')};

	&.ml_0 {
		margin-left: 0;
	}
	&.enable-action {
		pointer-events: auto;
	}

	&.disable-action {
		pointer-events: none !important;
		opacity: 0.5 !important;
	}
`;

const StyledForm = styled(Form)<{ padding?: string; align?: string }>`
	padding: ${({ padding }) => padding || 'unset'};
	${({ align }) => align && `text-align: ${align}`};

	.ant-form-item-explain.ant-form-item-explain-error {
		font-size: 13px;
	}
`;

const StyledFormItem = styled(
	({ color, isDisabled, labelHeight, fontSizeLabel, height, bgColor, ...props }) => (
		<Form.Item {...props} />
	),
)`
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};
	opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
	${({ margin }) => margin && `margin: ${margin} !important`};
	${({ padding }) => padding && `padding: ${padding}`};

	.ant-form-item-control {
		${({ height }) => height && `height: ${height}`};
	}

	.ant-form-item-label {
		label {
			color: ${({ color }) => color || 'unset'};
			${({ labelHeight }) => labelHeight && `height: ${labelHeight}`};
			${({ fontSizeLabel }) => fontSizeLabel && `font-size: ${fontSizeLabel}`};
		}
	}

	.ant-form-item-control-input-content {
		${({ height }) => height && `height: ${height}`};
		${({ bgColor }) => bgColor && `background: ${bgColor}`};
	}
	.select-offers-cascader {
		max-width: 100%;
		& > div {
			display: grid;
			grid-template-columns: auto auto;
		}
		.ant-cascader-menu {
			max-width: 100%;
			.ant-cascader-menu-item-disabled {
				font-weight: 500;
				color: ${THEME.colors.gray5};
				cursor: default;
				opacity: 0.6;
			}
		}
	}

	.ant-input.ant-input-disabled {
		opacity: ${THEME.disabledOpacity};
	}

	&.ant-form-item-with-help {
		${({ marginHasError }) => marginHasError && `margin: ${marginHasError} !important`};
	}
	&.hidden_start_required {
		.ant-form-item-label {
			label {
				&::before {
					display: none;
				}
			}
		}
	}
`;

const StyledContainerMessage = styled.div`
	height: calc(100vh - 130px);
`;

const StyledTag = styled(Tag)<{ border?: string; margin?: string }>`
	border: ${({ border }) => border || 'none'};
	margin: ${({ margin }) => margin || '6px'};
`;

const StyledTable = styled(({ border, bgColor, ...props }) => <Table {...props} />)`
	border: ${({ border }) => border || 'none'};
	border-radius: ${({ border }) => (border ? '2px' : 'none')};

	.ant-table {
		display: block;
		max-width: -moz-fit-content;
		max-width: fit-content;
		margin: 0 auto;
		overflow-x: auto;
		white-space: nowrap;
		.ant-table-selection-column {
			width: 50px;
		}
	}

	.ant-spin-nested-loading {
		> div {
			> .ant-spin {
				max-height: unset;
			}
		}
	}

	.ant-table-body {
		${({ bodyTableHeight }) => bodyTableHeight && `height: ${bodyTableHeight} !important`};
	}

	&.table_dashboard {
		th.ant-table-cell,
		td.ant-table-cell {
			font-size: 11px;
			font-weight: 400;
		}
		.ant-table {
			${({ bgColor }) => bgColor && `background: ${bgColor}`};
		}
		.ant-table-tbody {
			tr {
				& > td {
					padding: 12px 6px;
				}
			}
		}
	}
`;

const StyledSwitch = styled(Switch)<{ padding?: string; margin?: string }>`
	padding: ${({ padding }) => padding || 'unset'};
	margin: ${({ margin }) => margin || 'unset'};
	background-color: ${THEME.colors.gray2};
	height: 21px;
	line-height: 21px;

	&.ant-switch-checked {
		background-color: ${THEME.colors.orangeBase};
	}

	.ant-switch-handle {
		width: 16px;
		height: 16px;

		&::before {
			background-color: ${THEME.colors.gray5};
		}
	}
`;

const StyledTooltip = styled(Tooltip)<{ backgroundColor?: string; innerWidth?: string }>`
	.ant-tooltip-inner {
		${({ innerWidth }) => innerWidth && `width: ${innerWidth}`};
	}

	&.tooltip_custom {
		cursor: unset;
		.ant-tooltip-inner {
			max-height: 280px;
			overflow: hidden auto;
			min-width: 250px;
			padding: 10px 12px;
			&::-webkit-scrollbar {
				width: 6px;
			}
			&::-webkit-scrollbar-thumb {
				background: ${({ backgroundColor }) => backgroundColor || THEME.colors.gray2};
				border: 3px solid ${THEME.colors.gray2};
			}
		}
	}
`;

const StyledEmpty = styled(Empty)`
	margin: 0;

	.ant-empty-image {
		width: 100%;
		height: unset;
	}
`;

const StyledWrapper = styled(Wrapper)<{
	isOpenSider?: boolean;
	marginTop?: string;
	backgroundColor?: string;
	padding?: string;
}>`
	height: unset;
	margin-top: ${({ marginTop }) => marginTop || '50px'};
	background-color: ${({ backgroundColor }) => backgroundColor || THEME.colors.darkBlue};
	&.height_full {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: ${({ marginTop }) => marginTop || '0px'};
	}
	position: relative;
	${({ padding }) => padding && `padding: ${padding}`};
`;

const StyledRow = styled(Row)<{
	padding?: string;
	margin?: string;
	width?: string;
	height?: string;
}>`
	${({ width }) => width && `width: ${width}`};
	${({ height }) => height && `height: ${height}`};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ padding }) => padding && `padding: ${padding}`};

	&.row_absolute {
		position: absolute;
		&.right_top {
			right: 0;
			top: 0;
		}
	}
`;

const StyledCol = styled(Col)<{
	display?: string;
	padding?: string;
	margin?: string;
	height?: string;
	align?: string;
	bgColor?: string;
}>`
	${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
	${({ display }) => display && `display: ${display}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ height }) => height && `height: ${height}`};
	${({ align }) => align && `text-align: ${align}`};

	&.none_line_height {
		line-height: 0;
	}

	&.disabled {
		opacity: 0.6;
		pointer-events: none;
	}
`;

const StyledCustomTitle = styled.div<{
	fontSize?: string;
	color?: string;
	margin?: string;
	cursor?: string;
	border?: string;
	fontWeight?: string;
	widthIcon?: string;
	height?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${({ height }) => height || '24px'};
	font-stretch: normal;
	font-style: normal;
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	line-height: 1.429rem;
	letter-spacing: normal;
	margin: ${({ margin }) => margin || '0'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	cursor: ${({ cursor }) => cursor};
	${({ border }) => border && `border-${border}: 1px solid ${THEME.colors.darkBlue3}`}

	svg {
		width: ${({ widthIcon }) => widthIcon || '20px'};
		margin-right: 6px;
	}
	a {
		margin-left: 5px;
	}
`;

const StyledIconSearch = styled.div`
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	svg {
		width: 18px;
		height: 18px;
	}
`;

const StyledIcon = styled.div<{
	display?: string;
	size?: number;
	fillPath?: string;
	colorStroke?: string;
	colorStrokeHover?: string;
	cursor?: string;
	marginSvg?: string;
	margin?: string;
	disabled?: boolean;
	fill?: string;
	top?: string;
	bottom?: string;
	right?: string;
	left?: string;
	bgColor?: string;
	padding?: string;
	position?: string;
	zIndex?: number;
	opacity?: number;
	rotate?: number;
}>`
	display: ${({ display }) => display || 'inline-flex'};
	align-items: center;
	cursor: ${({ cursor }) => cursor || 'pointer'};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ disabled }) => disabled && 'pointer-events: none'};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
	${({ position }) => position && `position: ${position}`};
	${({ opacity }) => opacity && `opacity: ${opacity}`};
	${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`};
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&.icon_hover {
		&:hover {
			svg {
				path {
					fill: ${THEME.colors.orangeBase} !important;
				}
			}
		}
	}
	&.light_icon_hover {
		&:hover {
			svg {
				path {
					fill: ${THEME.colors.gray5} !important;
				}
			}
		}
	}
	svg {
		${({ size }) => size && `width: ${size}px !important`};
		${({ size }) => size && `height: ${size}px !important`};
		${({ fill }) => fill && `fill: ${fill} !important`};
		path {
			${({ fillPath }) => fillPath && `fill: ${fillPath} !important`};
			${({ colorStroke }) => colorStroke && `stroke: ${colorStroke}`};
		}
	}
	&.icon_arrow {
		&.p_bottom {
			transform: rotate(90deg);
			transition: 0.15s ease-out;
		}
	}
	&.hover_level_path {
		&:hover {
			svg {
				path {
					${({ colorStrokeHover }) => colorStrokeHover && `stroke: ${colorStrokeHover}`};
				}
			}
		}
	}

	&.hover_level_svg {
		&:hover {
			svg {
				fill: ${THEME.colors.orangeBase} !important;
			}
		}
	}

	&.icon_white {
		svg {
			path {
				fill: ${THEME.colors.white} !important;
			}
		}
	}
	&.icon_gray {
		svg {
			path {
				fill: ${THEME.colors.gray5} !important;
			}
		}
	}
	&.icon_orange {
		svg {
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}
	&.ic_mr {
		svg {
			margin-right: 4px;
		}
	}
	&.transparent {
		svg {
			fill: transparent;
		}
	}
	svg {
		${({ marginSvg }) => marginSvg && `margin: ${marginSvg} !important`};
	}
	&.absolute {
		position: absolute;
		${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
		${({ top }) => top && `top: ${top}`};
		${({ right }) => right && `right: ${right}`};
		${({ left }) => left && `left: ${left}`};
		${({ bottom }) => bottom && `bottom: ${bottom}`};
	}
	&.ic_sort_by {
		transform: rotate(90deg);
		transition: 0.2s ease-out;
		&.swap {
			transform: rotate(-90deg);
		}
	}
`;

const StyledWrapperIconAnimation = styled.div<{
	top?: string;
	bottom?: string;
	right?: string;
	left?: string;
	zIndex?: number;
}>`
	display: inline-flex;
	align-items: center;
	@keyframes positionRing {
		0% {
			transform: translateX(0px);
		}
		50% {
			transform: translateX(4px);
		}
		100% {
			transform: translateX(0px);
		}
	}
	&.icon_position {
		-webkit-animation: positionRing 1s 0.7s ease-in-out infinite;
		-webkit-transform-origin: 50% 4px;
		-moz-animation: positionRing 1s 0.7s ease-in-out infinite;
		-moz-transform-origin: 50% 4px;
		animation: positionRing 1s 0.7s ease-in-out infinite;
	}
	&.absolute {
		position: absolute;
		${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
		${({ top }) => top && `top: ${top}`};
		${({ right }) => right && `right: ${right}`};
		${({ left }) => left && `left: ${left}`};
		${({ bottom }) => bottom && `bottom: ${bottom}`};
	}
`;

const StyledMarkAll = styled.span`
	margin: 0 6px;
	color: ${THEME.colors.blueBase};
	cursor: pointer;
	text-align: left;
	text-decoration-line: underline;
`;

const StyledFlex = styled.div<{
	justify?: JustifyContentDto;
	align?: AlignItemsDto;
	margin?: string;
	padding?: string;
	gap?: string;
	width?: string;
}>`
	position: relative;
	display: flex;
	justify-content: ${({ justify }) => justify || 'space-between'};
	align-items: ${({ align }) => align || 'center'};
	margin: ${({ margin }) => margin || 'unset'};
	padding: ${({ padding }) => padding || 'unset'};
	${({ gap }) => gap && `gap: ${gap}`};
	${({ width }) => width && `width: ${width}`};

	.ant-dropdown {
		min-width: unset;
		width: 200px !important;
		height: 300px !important;
	}
`;

const StyledText = styled.p<{
	position?: string;
	display?: string;
	fontSize?: string;
	fontWeight?: string | number;
	color?: string | number;
	margin?: string;
	padding?: string;
	cursor?: string;
	textTransform?: string;
	lineHeight?: string;
	whiteSpace?: string;
	opacity?: number;
	isDisabled?: boolean;
	textDecoration?: string;
	fontStyle?: string;
	width?: string;
	lineClamp?: number;
	textAlign?: string;
}>`
	${({ position }) => position && `position: ${position}`};
	${({ display }) => display && `display: ${display}`};
	${({ width }) => width && `width: ${width}`};
	${({ color }) => color && `color: ${color}`};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
	margin: ${({ margin }) => margin || '0'};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ opacity }) => (opacity || opacity === 0) && `opacity: ${opacity}`};
	${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
	${({ cursor }) => cursor && `cursor: ${cursor}`};
	${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};
	${({ textDecoration }) => textDecoration && `text-decoration: ${textDecoration}`};
	${({ fontStyle }) => fontStyle && `font-style: ${fontStyle}`};
	${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

	${({ isDisabled }) =>
		isDisabled &&
		css`
			pointer-events: none;
			opacity: ${THEME.disabledOpacity};
		`}

	&.italic {
		font-style: italic;
	}

	&.underline {
		text-decoration: underline;
	}

	&.overflow {
		overflow: hidden;
		white-space: ${({ whiteSpace }) => whiteSpace || 'pre'};
		text-overflow: ellipsis;
	}

	${({ lineClamp }) =>
		lineClamp &&
		css`
			-webkit-line-clamp: ${lineClamp};
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
		`}

	&.social-type {
		text-transform: capitalize;
	}

	&.hover {
		&:hover {
			color: ${THEME.colors.orangeBase};
		}
	}

	&.active {
		color: ${THEME.colors.orangeBase};
		font-weight: 600;
	}
`;

const StyledIconWrapper = styled.div<{
	width?: string;
	height?: string;
	margin?: string;
	color?: string;
	padding?: string;
	display?: string;
	cursor?: string;
}>`
	display: ${({ display }) => display || 'inline-flex'};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ cursor }) => cursor && `cursor: ${cursor}`};

	svg {
		${({ width }) => width && `width: ${width}`};
		${({ height }) => height && `height: ${height}`};

		&,
		path {
			${({ color }) => color && `fill: ${color}`};
			/* ${({ color }) => color && `stroke: ${color}`}; */
		}
	}

	&.icon_hover {
		svg {
			path {
				fill: ${THEME.colors.gray1};
			}
		}
		&:hover {
			svg {
				path {
					fill: ${THEME.colors.orangeBase};
				}
			}
		}
	}
`;

const StyledSection = styled.section<{
	margin?: string;
	bgColor?: string;
	padding?: string;
	width?: string;
}>`
	margin: ${({ margin }) => margin || '24px 0 10px 0'};
	${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ width }) => width && `width: ${width}`};

	&.fixed-section {
		top: 40%;
		transform: translateY(-40%);
		position: absolute;
		right: 0;
	}
	&.overflow {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const StyledCloseIcon = styled(StyledIcon)<{ right?: string; top?: string; isDisabled?: boolean }>`
	position: absolute;
	${({ right }) => right && `right: ${right}`};
	${({ top }) => top && `top: ${top}`};
	z-index: 99;
	cursor: pointer;
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
	opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
`;

const StyledLink = styled(({ ...props }) => <StyledText {...props} />)`
	${({ display }) => display && `display: ${display}`};
	${({ color }) => color && `color: ${color}`};
	${({ underline }) => underline && 'text-decoration: underline'};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	cursor: pointer;
`;

const StyledLinkHref = styled.a<{
	color?: string;
	underline?: string | boolean;
	fontWeight?: string;
	margin?: string;
	hoverColor?: string;
	whiteSpace?: string;
}>`
	${({ color }) => color && `color: ${color} !important`};
	${({ underline }) => underline && 'text-decoration: underline'};
	cursor: pointer;
	font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
	${({ margin }) => margin && `margin: ${margin}`};

	&:hover {
		${({ hoverColor }) => hoverColor && `color: ${hoverColor} !important`};
	}

	&.overflow {
		overflow: hidden;
		white-space: ${({ whiteSpace }) => whiteSpace || 'pre'};
		text-overflow: ellipsis;
	}

	&.customized {
		&,
		&:hover {
			${({ color }) => color && `color: ${color}`};
			text-decoration: underline;
			font-weight: normal;
		}
	}

	&.disabled_url {
		cursor: not-allowed;
		opacity: ${THEME.disabledOpacity};

		&:hover {
			${({ color }) => color && `color: ${color}`};
		}
	}

	&.inner_pointer {
		* {
			cursor: pointer !important;
		}
	}

	${({ underline }) =>
		underline &&
		css`
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		`}
`;

const StyledRadioGroup = styled(Radio.Group)<{ fontSize?: string }>`
	&.block {
		display: block;
	}
	&.radio_light {
		.ant-radio {
			.ant-radio-inner {
				background-color: ${THEME.colors.darkBlue3};
			}
		}
	}
	.ant-radio-wrapper {
		${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	}
`;

const StyledRadioButton = styled(Radio)<{ bgInner?: string }>`
	.ant-radio-inner {
		${({ bgInner }) => bgInner && `background-color: ${bgInner}`};
	}
`;

const StyledWrapperAction = styled.div<{
	alignItems?: string;
	padding?: string;
	margin?: string;
	color?: string;
	fontSize?: string;
	background?: string;
	fontWeight?: string;
	width?: string;
	justifyContent?: string;
	display?: string;
	height?: string;
}>`
	display: ${({ display }) => display || 'inline-flex'};
	align-items: ${({ alignItems }) => alignItems || 'center'};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ color }) => color && `color: ${color}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	${({ background }) => background && `background: ${background}`};
	${({ width }) => width && `width: ${width}`};
	${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
	${({ display }) => display && `display: ${display}`};
	${({ height }) => height && `height: ${height}`};

	&.list_action {
		.icon_hover {
			margin-right: 5px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
`;

const StyledPopconfirm = styled(Popconfirm)``;

const StyledWrapperTitle = styled.div<{
	justifyContent?: string;
	padding?: string;
	margin?: string;
	color?: string;
	fontSize?: string;
	background?: string;
	fontWeight?: string;
	top?: string;
	bottom?: string;
	right?: string;
	left?: string;
	width?: string;
	position?: string;
	lineHeight?: string;
	height?: string;
	alignItems?: string;
	flexDirection?: string;
	flexWrap?: string;
	border?: string;
}>`
	display: flex;
	align-items: ${({ alignItems }) => alignItems || 'center'};
	justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ color }) => color && `color: ${color}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	${({ background }) => background && `background: ${background}`};
	${({ width }) => width && `width: ${width}`};
	${({ position }) => position && `position: ${position}`};
	${({ top }) => top && `top: ${top}`};
	${({ bottom }) => bottom && `bottom: ${bottom}`};
	${({ right }) => right && `right: ${right}`};
	${({ left }) => left && `left: ${left}`};
	${({ height }) => height && `height: ${height}`};
	${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
	${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
	${({ border }) => border && `border: ${border}`};

	&.absolute {
		position: absolute;
		${({ top }) => top && `top: ${top}`};
		${({ bottom }) => bottom && `bottom: ${bottom}`};
		${({ right }) => right && `right: ${right}`};
		${({ left }) => left && `left: ${left}`};
	}
	&.overflow {
		overflow: auto;
		white-space: nowrap;
	}
`;

const StyledLinkTo = styled.a`
	color: ${THEME.colors.blueBase};
	cursor: pointer;
	text-align: left;
	text-decoration-line: underline;
`;

const StyledDropdown = styled(Dropdown)<{ margin?: string }>`
	&.disabled {
		pointer-events: none;
	}

	&.dropdown_action {
		position: relative;
		margin: ${({ margin }) => margin || '0 0 0 4px'};

		&.disabled {
			cursor: not-allowed;
			opacity: ${THEME.disabledOpacity};

			&:hover,
			&:focus {
				border-color: ${THEME.colors.darkBlue4} !important;
				color: unset;

				svg {
					&,
					path {
						fill: ${THEME.colors.gray5} !important;
					}
				}
			}
		}
		&.disabled_action {
			opacity: 0.5;
			cursor: unset;
		}

		.ant-dropdown-menu {
			padding: 0;
		}
		.ant-dropdown-menu-item {
			&.ant-dropdown-menu-item-disabled {
				&:hover {
					background: unset;
					color: ${THEME.colors.gray5};
				}
			}
			.ant-dropdown-menu-title-content {
				width: 100%;
			}
		}
		.ant-dropdown-menu-submenu {
			max-height: 50vh;
			overflow: auto;
			&.ant-dropdown-menu-submenu-disabled {
				.ant-dropdown-menu-submenu-title {
					background: unset;
					opacity: 0.4;
					color: unset;
				}
			}
		}
	}
	&.dropdown_assign_album {
		.ant-dropdown {
			.ant-dropdown-menu {
				max-height: 50vh;
				width: 200px;
				overflow: auto;
			}
		}
	}

	.dropdown_chip_option {
		${StyledIcon} {
			position: absolute;
			right: 0;
		}
	}
	&.dropdown_focus {
		.ant-btn {
			padding-left: 0;
			padding-right: 0;
			outline: 0;
			border-color: ${THEME.colors.transparent};
			margin: 2px;
		}
		&.ant-dropdown-open {
			.ant-btn {
				box-shadow: 0 0 0 2px rgba(226, 88, 43, 0.2);
			}
		}
		&:not(.ant-dropdown-open) {
			.ant-btn {
				border-color: ${THEME.colors.transparent};
			}
		}
	}
	&.menu_item_full {
		.ant-dropdown-menu-title-content {
			width: 100%;
		}
	}
`;

const StyledInputNumber = styled(InputNumber)<{ padding?: string }>`
	${({ padding }) => padding && `padding: ${padding}`};
`;

const StyledUpload = styled(Upload)``;

const StyledWrapperContent = styled.div<{
	padding?: string;
	margin?: string;
	color?: string;
	fontSize?: string;
	background?: string;
	fontWeight?: string;
	width?: string;
	height?: string;
	textAlign?: string;
	borderRadius?: string;
	overflow?: string;
	lineHeight?: string;
	boxShadow?: string;
	display?: string;
	alignItems?: string;
	justifyContent?: string;
	maxWidth?: string;
	maxHeight?: string;
	top?: string;
	bottom?: string;
	right?: string;
	left?: string;
	position?: string;
	flexWrap?: string;
	flexDirection?: string;
	pointerEvents?: string;
	cursor?: string;
	border?: string;
	opacity?: number;
	borderLeft?: string;
	transform?: string;
	zIndex?: string;
	fontStyle?: string;
	filter?: string;
}>`
	${({ width }) => width && `width: ${width}`};
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
	${({ height }) => height && `height: ${height}`};
	${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ color }) => color && `color: ${color}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	${({ background }) => background && `background: ${background}`};
	${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
	${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
	${({ overflow }) => overflow && `overflow: ${overflow}`};
	${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
	${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
	${({ display }) => display && `display: ${display}`};
	${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
	${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
	${({ position }) => position && `position: ${position}`};
	${({ top }) => top && `top: ${top}`};
	${({ bottom }) => bottom && `bottom: ${bottom}`};
	${({ right }) => right && `right: ${right}`};
	${({ left }) => left && `left: ${left}`};
	${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
	${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
	${({ pointerEvents }) => pointerEvents && `pointer-events: ${pointerEvents}`};
	${({ cursor }) => cursor && `cursor: ${cursor}`};
	${({ border }) => border && `border: ${border}`};
	${({ borderLeft }) => borderLeft && `border-left: ${borderLeft}`};
	${({ opacity }) => !isUndefined(opacity) && `opacity: ${opacity}`};
	${({ transform }) => transform && `transform: ${transform}`};
	${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
	${({ fontStyle }) => fontStyle && `font-style: ${fontStyle}`};
	${({ filter }) => filter && `filter: ${filter}`};

	&.full {
		width: 100%;
		height: 100%;
	}

	&.disabled {
		opacity: ${({ opacity }) => opacity || 0.6};
		pointer-events: none;
	}

	&.hover_effect {
		&:hover {
			background-color: ${THEME.colors.orangeBase};
		}
	}
	&.hidden {
		display: none;
	}
`;

const StyledDescText = styled.p<{
	padding?: string;
	margin?: string;
	color?: string;
	fontSize?: string;
	background?: string;
	fontWeight?: string;
	width?: string;
	height?: string;
	textAlign?: string;
	borderRadius?: string;
	overflow?: string;
	lineHeight?: string;
	boxShadow?: string;
	display?: string;
	alignItems?: string;
	justifyContent?: string;
}>`
	${({ width }) => width && `width: ${width}`};
	${({ height }) => height && `height: ${height}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ color }) => color && `color: ${color}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	${({ background }) => background && `background: ${background}`};
	${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
	${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
	${({ overflow }) => overflow && `overflow: ${overflow}`};
	${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
	${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
	${({ display }) => display && `display: ${display}`};
	${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
	${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
	&.height_full {
		height: ${({ height }) => height || '100%'};
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
`;

const StyledBoxDesc = styled.div<{
	padding?: string;
	margin?: string;
	color?: string;
	fontSize?: string;
	background?: string;
	fontWeight?: string;
	width?: string;
	height?: string;
	textAlign?: string;
	borderRadius?: string;
	overflow?: string;
	lineHeight?: string;
	boxShadow?: string;
}>`
	${({ width }) => width && `width: ${width}`};
	${({ height }) => height && `height: ${height}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ margin }) => margin && `margin: ${margin}`};
	${({ color }) => color && `color: ${color}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	${({ background }) => background && `background: ${background}`};
	${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
	${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
	${({ overflow }) => overflow && `overflow: ${overflow}`};
	${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
	${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
	&.height_full {
		height: ${({ height }) => height || '100%'};
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
`;

const StyledDescOverflow = styled.div<{
	line?: number;
	fontSize?: string;
	lineHeight?: string;
	color?: string;
}>`
	display: -webkit-box;
	-webkit-line-clamp: ${({ line }) => line || 2};
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-align: left;
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
	${({ color }) => color && `color: ${color}`};

	position: relative;
	&.qoutes {
		word-break: break-all;
		&::after {
			content: '“';
			position: absolute;
			bottom: 0;
			right: 0;
		}
		&::before {
			content: '”';
		}
	}
`;

const StyledDivider = styled(Divider)<{ width?: string; margin?: string; borderColor?: string }>`
	width: ${({ width }) => width || '100%'};
	min-width: 95%;
	margin: ${({ margin }) => margin || '24px auto'};
	border-top: 1px solid ${({ borderColor }) => borderColor || THEME.colors.darkBlue4};
`;

const StyledTabs = styled(
	({
		margin,
		padding,
		paddingContainer,
		paddingWrapper,
		border,
		height,
		borderNav,
		...props
	}) => <Tabs {...props} />,
)`
	position: relative;
	background: ${THEME.colors.darkBlue};
	margin: 0;
	height: ${({ height }) => height || 'calc(100vh - 130px)'};
	border: ${({ border }) => border || 'unset'};
	border-radius: ${({ border }) => (border !== '' ? '2px' : 'unset')};

	.ant-tabs-content {
		${(props) => props.contentHeight && `height:${props.contentHeight}`};
	}

	.ant-tabs-nav {
		margin-bottom: 0 !important;
		border-bottom: 1px solid ${THEME.colors.darkBlue3};
		border-left: ${({ borderNav }) => borderNav || 'unset'};
		border-right: ${({ borderNav }) => borderNav || 'unset'};
		border-top: ${({ borderNav }) => borderNav || 'unset'};

		.ant-tabs-tab {
			padding: ${({ padding }) => padding || '12px 20px'};
			margin: ${({ margin }) => margin || 'unset'};
			background: none;
			border: none;
			border-bottom: 2px solid transparent;

			&.ant-tabs-tab-active {
				border-bottom: 2px solid ${THEME.colors.primary};
				opacity: 1;
				${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};

				.ant-tabs-tab-btn {
					color: ${THEME.colors.gray5};

					svg {
						margin-right: 8px;
						filter: invert(40%) sepia(100%) saturate(1737%) hue-rotate(345deg)
							brightness(93%) contrast(91%);
					}
				}
			}
		}

		.ant-tabs-nav-add {
			background: none;
			border: none;
		}

		&:before {
			border-bottom: none;
		}

		.ant-tabs-nav-wrap {
			padding: ${({ paddingContainer }) => paddingContainer || '0 0 0 15px'};
			height: 54px;
		}
	}
`;

const StyledAutoComplete = styled(AutoComplete)``;

const StyledBtnGray = styled(Button)`
	background-color: ${THEME.colors.gray5};
	color: ${THEME.colors.darkBlue2};
	border: 0;
	box-shadow: none;
	outline: none;
	&:focus,
	&:hover {
		background-color: ${THEME.colors.orangeBase};
		color: ${THEME.colors.gray5};
	}
`;

const StyledCheckboxGroup = styled(Checkbox.Group)<{ width?: string }>`
	${({ width }) => width && `width: ${width}`};

	.ant-checkbox-wrapper {
		margin-left: 0;
	}
`;

const StyledCheckbox = styled(Checkbox)<{ margin?: string; borderColorDisabled?: string }>`
	${({ margin }) => margin && `margin: ${margin}`};

	&.disabled {
		pointer-events: none;
		cursor: default;
	}

	.ant-checkbox-disabled {
		.ant-checkbox-inner {
			&::after {
				border-color: ${({ borderColorDisabled }) =>
					borderColorDisabled || THEME.colors.darkBlue5} !important;
			}
		}
	}
`;

const StyledInput = styled(Input)<{ height?: number; bgColor?: string }>`
	&.ant-input {
		${({ height }) => height && `height: ${height}px !important`};
		${({ bgColor }) => bgColor && `background-color: ${bgColor}`};

		&.hidden_arrow {
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			&[type='number'] {
				-moz-appearance: textfield;
			}
		}
	}
	&.ant-input-affix-wrapper {
		${({ height }) => height && `height: ${height}px !important`};
		&.hidden_arrow {
			input {
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				&[type='number'] {
					-moz-appearance: textfield;
				}
			}
		}
	}

	&.ant-input-affix-wrapper-disabled {
		background-color: transparent !important;
	}
	&.field_input_transparent {
		background-color: transparent;
		padding: 6px 12px;
		height: 32px;
		line-height: 20px;
		font-size: 12px;
	}
`;

const StyledTextArea = styled(TextArea)<{ bgColor?: string; maxHeight?: string }>`
	${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
	${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};

	&.textarea_content_licensing {
		pointer-events: auto !important;
		font-size: 12px;

		&.ant-input {
			background-color: transparent !important;

			&::-webkit-scrollbar-track {
				background: transparent;
			}

			&.ant-input:disabled {
				cursor: default;
			}

			&.ant-input-disabled {
				opacity: unset;
			}
		}
	}
`;

const StyledModal = styled(Modal)<{
	maxHeight?: string;
	padding?: string;
	minHeightFooter?: string;
	footerJustify?: string;
}>`
	.ant-modal-body {
		overflow: auto;
		${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
		${({ padding }) => padding && `padding: ${padding}`};
	}
	&.modal_empty {
		.ant-modal-body {
			padding: 0;
		}
	}
	&.modal_white {
		.ant-modal-header {
			background-color: ${THEME.colors.white};
			.ant-modal-title {
				color: ${THEME.colors.black};
			}
		}
		.ant-modal-footer {
			background-color: ${THEME.colors.white};
		}
		.ant-modal-header {
			border-bottom-color: ${THEME.colors.gray6};
		}
		.ant-modal-footer {
			border-top-color: ${THEME.colors.gray6};
		}
	}
	&.modal_preview_terms {
		max-width: 1650px;
		width: 85vw !important;
		.ant-modal-body {
			padding: 0;
		}
		.ant-modal-close-x {
			width: 40px;
			height: 40px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
	}

	.ant-modal-footer {
		${({ minHeightFooter }) => minHeightFooter && `min-height: ${minHeightFooter}`};
		${({ footerJustify }) => footerJustify && `justify-content: ${footerJustify}`};
	}
`;

const StyledSubMenu = styled(Menu.SubMenu)`
	&.hidden_icon {
		.ant-dropdown-menu-submenu-expand-icon {
			display: none;
		}
	}
`;

const StyledMenuItem = styled(Menu.Item)`
	&.ant-dropdown-menu-item {
		&.overlay {
			.ant-dropdown-menu-title-content {
				max-width: 250px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
			}
		}
	}
`;

const StyledRedirectLink = styled.div`
	display: inline-flex;
	align-items: center;
	color: ${THEME.colors.gray5};
	&:hover {
		color: ${THEME.colors.orangeBase};
	}
	&.has_icon_lv_svg {
		&:hover {
			svg {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}
	&.orange_field {
		color: ${THEME.colors.orangeBase};
	}
`;

const StyledDrawer = styled(Drawer)`
	&.dra_child {
		/* position: absolute; */
		&.p_0 {
			.ant-drawer-body {
				padding: 0;
			}
		}
	}
`;

const StyledBoxStatus = styled.div`
	padding: 10px 16px;
	display: flex;
	align-items: center;
	border-radius: 2px;
	&.warning {
		background: rgba(250, 173, 20, 0.08);
	}
`;

const StyledSpace = styled(Space)<{ width?: string; gap?: string }>`
	${({ width }) => width && `width: ${width}`};
	${({ gap }) => gap && `gap: ${gap} !important`};
`;

const StyledCollapse = styled(Collapse)<{
	bgColor?: string;
	borderRadius?: string;
	padding?: string;
	margin?: string;
}>`
	${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
	${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
	${({ padding }) => padding && `padding: ${padding}`};
	${({ margin }) => margin && `margin: ${margin}`};
`;

const StyledPanel = styled(CollapsePanel)``;

const StyledSkeleton = styled(Skeleton)`
	&.skeleton_show {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		z-index: 4567;
	}
	&.hidden {
		display: none;
	}
`;

const StyledSkeletonImage = styled(SkeletonImage)<{
	bgColor?: string;
	position?: string;
	width?: string;
	borderRadius?: string;
	bgImg?: string;
	height?: string;
	zIndex?: number;
}>`
	background-color: ${({ bgColor }) => bgColor || 'rgba(238, 238, 238, 0.6);'};
	position: ${({ position }) => position || 'absolute'};
	width: ${({ width }) => `${width || '100%'} !important`};
	height: 100%;
	top: 0;
	left: 0;
	z-index: ${({ zIndex }) => zIndex || 1235};

	${({ borderRadius }) =>
		borderRadius &&
		css`
			&,
			.ant-skeleton-image {
				border-radius: ${borderRadius};
			}
		`}

	&.ant-skeleton-active {
		.ant-skeleton-image {
			${({ bgImg }) => bgImg && `background-image: ${bgImg} !important`};
		}
	}

	.ant-skeleton-image {
		width: 100% !important;
		height: ${({ height }) => `${height || '100%'} !important`};

		svg {
			display: none;
		}
	}
`;

const handleBGColor = (type?: string) => {
	switch (type?.toLowerCase()) {
		case STATUS_MODES.ACTIVE:
			return 'rgba(82, 196, 26, 0.05)';
		case STATUS_MODES.IN_ACTIVE:
		case STATUS_MODES.DISABLED:
			return 'rgba(245, 34, 45, 0.05)';
		default:
			return 'rgba(250, 173, 20, 0.05)';
	}
};

const handleBorderColor = (type?: string) => {
	switch (type?.toLowerCase()) {
		case STATUS_MODES.ACTIVE:
			return 'rgba(82, 196, 26, 0.2)';
		case STATUS_MODES.IN_ACTIVE:
		case STATUS_MODES.DISABLED:
			return 'rgba(245, 34, 45, 0.2)';

		default:
			return 'rgba(250, 173, 20, 0.2)';
	}
};

const handleColorStatus = (type?: string) => {
	switch (type?.toLowerCase()) {
		case STATUS_MODES.ACTIVE:
			return THEME.colors.greenBase;
		case STATUS_MODES.IN_ACTIVE:
		case STATUS_MODES.DISABLED:
			return THEME.colors.redBase;
		default:
			return THEME.colors.yellowBase;
	}
};

const StyledStatusDot = styled.div<{ status?: string; margin?: string }>`
	width: 9px;
	height: 9px;
	margin: ${({ margin }) => margin || '0 8px'};
	border-radius: 50%;
	background-color: ${({ status }) => handleColorStatus(status)};
`;

const StyledMutableStatusButton = styled(({ status, isDisabled, ...props }) => <div {...props} />)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 105px;
	height: 33px;
	border-radius: 2px;
	font-size: ${({ fontSize }) => `${fontSize || '14px'}`};
	background-color: ${({ status }) => handleBGColor(status)};
	border: ${({ status }) => `1px solid ${handleBorderColor(status)}`};
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};
	opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
	text-transform: capitalize;
	${({ margin }) => margin && `margin: ${margin}`};
	cursor: pointer;
`;

export {
	StyledAction,
	StyledAutoComplete,
	StyledSkeleton,
	StyledSkeletonImage,
	StyledBoxDesc,
	StyledBoxStatus,
	StyledBtnGray,
	StyledButton,
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledCloseIcon,
	StyledCol,
	StyledContainerMessage,
	StyledCustomTitle,
	StyledDatePicker,
	StyledDescOverflow,
	StyledDescText,
	StyledDivider,
	StyledDrawer,
	StyledDropdown,
	StyledEmpty,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledIcon,
	StyledIconSearch,
	StyledIconWrapper,
	StyledInput,
	StyledInputNumber,
	StyledLink,
	StyledLinkHref,
	StyledLinkTo,
	StyledMarkAll,
	StyledMenuItem,
	StyledModal,
	StyledPopconfirm,
	StyledRadioButton,
	StyledRadioGroup,
	StyledRedirectLink,
	StyledRow,
	StyledSection,
	StyledSelect,
	StyledSelectDate,
	StyledSlider,
	StyledSubMenu,
	StyledSwitch,
	StyledTable,
	StyledTabs,
	StyledTag,
	StyledText,
	StyledTextArea,
	StyledTextSelection,
	StyledTitle,
	StyledTitleClick,
	StyledTooltip,
	StyledUpload,
	StyledWrapper,
	StyledWrapperAction,
	StyledWrapperContent,
	StyledWrapperTitle,
	StyledWrapperIconAnimation,
	StyledSpace,
	StyledPanel,
	StyledCollapse,
	StyledStatusDot,
	StyledMutableStatusButton,
};
