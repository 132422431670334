import { WidgetBuilderDetailRequestDto } from '@models/content/widgetManager';
import { ParamSaveAndCaptureWidget } from '@models/content/widgetManager/summary';
import { TermsTypes } from '@models/settings/contentLicensing';
import * as React from 'react';

export type WidgetBuilderContextType = {
	setCurrentStep: (val: number) => void;
	currentStep: number;
	detailWidget: any;
	setDetailWidget: (val: any) => void;
	setIsMobile: (val: boolean) => void;
	isMobile: boolean;
	setTypeConfirm: (val: string) => void;
	hasChangeWidget: boolean;
	handleSaveAndCaptureThumbnail: ({ current, callBack }: ParamSaveAndCaptureWidget) => void;
	setIsLoading: (val: boolean) => void;
	termsSelected: TermsTypes | null;
	setTermsSelected: (val: TermsTypes | null) => void;
	hasChangeCustomField: boolean;
	setHasChangeCustomField: (val: boolean) => void;
	dataPublishNoDraft: WidgetBuilderDetailRequestDto | null;
	setDataPublishNoDraft: (val: WidgetBuilderDetailRequestDto | null) => void;
};

// Default context values
const values: WidgetBuilderContextType = {
	setCurrentStep: () => {},
	setDetailWidget: () => {},
	setTypeConfirm: () => {},
	currentStep: 0,
	detailWidget: null,
	setIsMobile: () => {},
	isMobile: false,
	hasChangeWidget: false,
	handleSaveAndCaptureThumbnail: () => {},
	setIsLoading: () => {},
	termsSelected: null,
	setTermsSelected: () => {},
	hasChangeCustomField: false,
	setHasChangeCustomField: () => {},
	dataPublishNoDraft: null,
	setDataPublishNoDraft: () => {},
};

const WidgetBuilderContext = React.createContext(values);
export default WidgetBuilderContext;
