import { Collapse, ConfigProvider, Tabs } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Icon
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';

// Styled
import { StyledCollapse, StyledEmpty, StyledTabs } from '@styled/Rewards/StyledTable';

// Redux
import { setInventoryState, updateRewardSelect } from '@stores/rewards/rewards.actions';

// Constant
import { ACTIVE, ARCHIVED, COLLAPSE_KEY, DRAFT, GIFTCARD } from '@constants/reward';
// components
import { StyledTable } from '@components/CustomTable';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { renderCopiedText } from '@utils/renderComponent';

type GiftCardsProps = {
	handleChangeTab: (key: string, type: string) => void;
	handleScroll: (page: number, status: string, type: string) => void;
	handleEdit: (rowSelect?: any) => void;
	data: any;
};

type TabsDto = {
	key: string;
	title: string;
	data: any[];
};

const { TabPane } = Tabs;

const { Panel } = Collapse;

const StyledWrapper = styled.div`
	margin-top: 16px;
`;

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

const GiftCards = ({ data, handleChangeTab, handleScroll, handleEdit }: GiftCardsProps) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const rewards = useSelector((state: any) => state.rewards);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [activeTab, setActiveTab] = useState<string>(ACTIVE);
	const [activePage, setActivePage] = useState<number>(1);
	const [draftPage, setDraftPage] = useState<number>(1);
	const [archivedPage, setArchivedPage] = useState<number>(1);
	const [collapseActive, setCollapseActive] = useState<string[]>([]);

	const { activeGiftcardList, draftGiftcardList, archivedGiftcardList } = data;
	const {
		resetStatus: { resetGiftcardList },
		listTypeEmptyReward = [],
		loadingReward,
	} = rewards;

	const listKeyData = {
		active: 'activeGiftcardList',
		draft: 'draftGiftcardList',
		archived: 'archivedGiftcardList',
	};

	const tabs: TabsDto[] = [
		{
			key: ACTIVE,
			title: t('rewards.tabs.active'),
			data: activeGiftcardList,
		},
		{
			key: DRAFT,
			title: t('rewards.tabs.draft'),
			data: draftGiftcardList,
		},
		{
			key: ARCHIVED,
			title: t('rewards.tabs.archived'),
			data: archivedGiftcardList,
		},
	];

	const columns: any[] = [
		{
			title: t('rewards.amount'),
			dataIndex: 'estimatedCashValue',
			render: (estimatedCashValue: number) =>
				estimatedCashValue && renderCopiedText(`$${estimatedCashValue}`),
			show: true,
			width: 150,
			align: 'right',
		},
		{
			title: t('rewards.remaining_gift_cards'),
			dataIndex: 'remainingOffers',
			show: true,
			width: 200,
			align: 'right',
			render: (val: number) => renderCopiedText(val),
		},
		{
			title: t('rewards.offer_end_date'),
			dataIndex: 'dateExpires',
			show: true,
			render: (dateExpires: string) =>
				dateExpires &&
				renderCopiedText(
					convertUtcToLocalTimeWithFormat(
						new Date(dateExpires).getTime(),
						TYPE_FORMAT_DATE.TIME,
					),
				),
		},
	];

	const resetScrollOnTop = () => {
		const node = document.querySelector<HTMLElement>(
			`#tab_gift_cards_${activeTab} .ant-table-body`,
		);
		if (node) {
			node.scrollTop = 0;
		}
	};

	// Reset rewards list
	useEffect(() => {
		if (resetGiftcardList) {
			rewards.resetStatus.resetGiftcardList = false;
			dispatch(setInventoryState(rewards));
			setActivePage(1);
			setDraftPage(1);
			setArchivedPage(1);
			resetScrollOnTop();
			setInventoryStoreState([]);
		}
	}, [resetGiftcardList]);

	useEffect(() => {
		const node = document.querySelector<HTMLElement>(
			`#tab_gift_cards_${activeTab} .ant-table-body`,
		);
		if (node) {
			const handleScrollList = () => {
				if (node.scrollHeight <= node.scrollTop + node.clientHeight && !loadingReward) {
					onScrollBottomRewardList();
					node.scrollTop = node.scrollHeight;
				}
			};
			node.addEventListener('scroll', handleScrollList);
			return () => node.removeEventListener('scroll', handleScrollList);
		}
		return () => {};
	}, [
		activeTab,
		listKeyData,
		listTypeEmptyReward,
		activePage,
		draftPage,
		archivedPage,
		loadingReward,
		collapseActive,
	]);

	const onCheckEmpty = (key: string) =>
		listTypeEmptyReward.some((typeReward: string) => typeReward === key);

	const onScrollBottomRewardList = () => {
		if (activeTab === ACTIVE && !onCheckEmpty(listKeyData.active)) {
			handleScroll(activePage + 1, ACTIVE, GIFTCARD);
			setActivePage(activePage + 1);
		}
		if (activeTab === DRAFT && !onCheckEmpty(listKeyData.draft)) {
			handleScroll(draftPage + 1, DRAFT, GIFTCARD);
			setDraftPage(draftPage + 1);
		}
		if (activeTab === ARCHIVED && !onCheckEmpty(listKeyData.archived)) {
			handleScroll(archivedPage + 1, ARCHIVED, GIFTCARD);
			setArchivedPage(archivedPage + 1);
		}
	};

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setInventoryStoreState(selectedRowKeys);
		setSelectedRowKeys(selectedRowKeys);
	};

	const onSelectAllChange = () => {};

	const handleCurrentTab = (key: string) => {
		// Clean up before changing tab

		rewards.rewardsList.giftcardList = [];
		dispatch(setInventoryState(rewards));
		resetScrollOnTop();
		setActivePage(1);
		setDraftPage(1);
		setArchivedPage(1);
		setActiveTab(key);
		setSelectedRowKeys([]);
		// Remove non-selected tab's data
		setInventoryStoreState([]);
		handleChangeTab(key, GIFTCARD);
	};

	const setInventoryStoreState = (data: any[]) => {
		dispatch(updateRewardSelect({ selectedGiftCardsRewards: data }));
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		onSelectAll: onSelectAllChange,
	};

	return (
		<StyledWrapper>
			<ConfigProvider renderEmpty={customizeRenderEmpty}>
				<StyledCollapse
					onChange={(key: any) => setCollapseActive(key)}
					activeKey={collapseActive}
					bordered={false}
					className="hidden_content"
					expandIconPosition="right">
					<Panel header={t('rewards.section.gift_cards')} key={COLLAPSE_KEY.GIFT_CARDS} />
				</StyledCollapse>
				<StyledTabs
					style={
						collapseActive.includes(COLLAPSE_KEY.GIFT_CARDS)
							? { display: 'block' }
							: { display: 'none' }
					}
					defaultActiveKey={ACTIVE}
					onChange={handleCurrentTab}>
					{tabs.map((tab, idx: number) => (
						<TabPane tab={tab.title} key={tab.key} closable={false}>
							<StyledTable
								id={`tab_gift_cards_${tab.key}`}
								className="table_row_action"
								dataSource={tab.data}
								columns={columns}
								pagination={false}
								rowSelection={rowSelection}
								scroll={{ y: 300 }}
								onRow={(record: any, rowIndex: any) => ({
									onClick: () => handleEdit(record),
								})}
							/>
						</TabPane>
					))}
				</StyledTabs>
			</ConfigProvider>
		</StyledWrapper>
	);
};

export default React.memo(GiftCards);
