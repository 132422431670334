import { IconRemoveCircle } from '@assets/icons';
import THEME from '@constants/themes/themes';
import Empty from '@cores/Empty';
import { Col, Input, Select, Slider, Steps, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Dragger from 'antd/lib/upload/Dragger';
import styled from 'styled-components';

const { Step } = Steps;

const variantMobile = 50;
const variantDesktop = 100;

export const StyledWrapper = styled(Col)`
	height: calc(100vh - 82px);
`;

export const StyledHeaderAction = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 6px;
	background: ${THEME.colors.darkBlue2};
`;

export const StyledActionRight = styled.div`
	display: flex;
	button {
		margin-left: 7px;
	}
`;

export const StyledBodyPreviewWidget = styled.div<{ overflow?: string }>`
	height: calc(100% - 52px);
	position: relative;
	overflow: ${({ overflow }) => overflow || 'auto'};
	background-color: ${THEME.colors.darkBlue2};

	.container {
		display: none;
	}
`;

export const StyledSlider = styled(Slider)`
	&.slider_row {
		width: 200px;
		margin: 8px 0 0 0;
		.ant-tooltip-inner {
			min-width: 40px;
			text-align: center;
			height: 32px;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}
	}
	.ant-slider-handle {
		margin-top: -2.3px;
	}
	&.range_custom {
		.ant-slider-dot {
			&:not(.ant-slider-dot-active) {
				background-color: ${THEME.colors.darkBlue3};
				border-color: ${THEME.colors.darkBlue3};
			}
			&.ant-slider-dot-active {
				background-color: transparent;
			}
		}
	}
`;

export const StyledTitle = styled.div<{
	fontSize?: string;
	color?: string;
	margin?: string;
	cursor?: string;
	border?: string;
	fontWeight?: string;
	widthIcon?: string;
	height?: string;
}>`
	display: flex;
	align-items: center;
	height: ${({ height }) => height || '24px'};
	font-stretch: normal;
	font-style: normal;
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	line-height: 1.429rem;
	letter-spacing: normal;
	margin: ${({ margin }) => margin || '0'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	cursor: ${({ cursor }) => cursor};
	${({ border }) => border && `border-${border}: 1px solid ${THEME.colors.darkBlue3}`}

	svg {
		width: ${({ widthIcon }) => widthIcon || '20px'};
		margin-right: 6px;
	}
`;

export const StyledText = styled.span<{ fontSize?: string; fontWeight?: string }>`
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
`;

export const StyledEmpty = styled(Empty)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const StyledSteps = styled(Steps)`
	.ant-steps-item-title {
		font-size: 14px;
	}
	&.ant-steps-label-vertical {
		.ant-steps-item-content {
			width: auto;
			min-width: 30px;
			text-align: left;
		}
		.ant-steps-item-tail {
			margin-left: 16px;
		}
		.ant-steps-item-icon {
			margin-left: 0px;
		}
	}
	.ant-steps-item-active {
		.ant-steps-item-tail::after {
			background-color: ${THEME.colors.darkBlue3};
		}
		.ant-steps-item-title {
			color: white !important;
		}
	}
	.ant-steps-item-wait {
		.ant-steps-item-icon {
			background-color: ${THEME.colors.darkBlue3};
			.ant-steps-icon {
				color: white;
			}
		}
		.ant-steps-item-tail::after {
			background-color: ${THEME.colors.darkBlue3};
		}
	}

	.ant-progress-circle-trail {
		stroke: none;
	}

	.ant-progress-circle-path {
		stroke: ${THEME.colors.orangeBase} !important;
	}
`;

export const StyledStepItem = styled(Step)`
	.ant-steps-item-finish {
		.anticon-check {
			display: none;
		}
		.ant-steps-icon::after {
			content: '1';
		}
	}
`;

export const StyledUpload = styled(Dragger)<{ height?: string }>`
	.ant-upload-btn {
		padding: 0 !important;
	}
	&.ant-upload {
		height: ${({ height }) => height || '100px'};
		background-color: rgba(32, 50, 65, 0.5);
		&.upload_custom_widget {
			background-color: rgba(32, 50, 65, 0.5);
		}
		&.ant-upload-drag {
			border-color: ${THEME.colors.gray1};
			.ant-upload-btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				.ant-upload-drag-container {
					width: 100%;
				}
			}
		}
	}
`;

export const StyledNameUpload = styled.span<{ line?: number }>`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre-line;
	max-width: calc(100% - 70px);
	display: -webkit-box;
	-webkit-line-clamp: ${({ line }) => line || 1};
	-webkit-box-orient: vertical;
	word-break: break-word;
`;

export const StyledEmptyUpload = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	color: ${THEME.colors.gray1};
	padding: 12px;
`;

export const StyledFilePreview = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
`;

export const StyledTextArea = styled(TextArea)<{ bgColor?: string }>`
	font-family: ${THEME.fonts.primary};
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: ${THEME.colors.gray5};
	${({ bgColor }) => bgColor && `background: ${bgColor}`};

	&::placeholder {
		color: ${THEME.colors.gray1};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}
`;

export const StyledIconTag = styled(({ color, ...props }) => <Tag {...props} />)`
	border: none;
	width: fit-content;
	height: auto;
	background: transparent;
	padding: 0;
	height: 24px;
	margin-right: 0;
	margin-left: 10px;
	display: flex;
	align-items: center;

	svg {
		path {
			${({ color }) => color && `fill: ${color}`};
		}
	}
`;

export const StyledSelect = styled(Select)<{
	width?: string;
	margin?: string;
	notEvent?: boolean;
	isMobile?: boolean;
}>`
	padding: 0;
	margin: ${({ margin }) => margin || 'unset'};
	width: ${({ width }) => `${width} !important` || '100%'};
	${({ notEvent }) => notEvent && 'pointer-events: none'};

	&.dropdown-custom-widget {
		.ant-select-selector {
			display: block;
			height: 88px;
			overflow-y: auto;
			border-radius: 2px;
			border: 1px solid ${THEME.colors.darkBlue4};
			background: ${THEME.colors.darkBlue3};
			.ant-select-selection-item {
				background: ${THEME.colors.darkBlue4};
			}
		}
	}
	&.dropdown-select_detail_widget {
		.ant-select-selector {
			height: ${({ isMobile }) => (isMobile ? '44px' : '32px')};
			border-radius: ${({ isMobile }) => (isMobile ? '24px' : '16px')};
			border: 1px solid #d9e4ef;
			background-color: #f5f9fd !important;
			font-size: ${({ isMobile }) => (isMobile ? '1.068rem' : '0.857rem')};

			.ant-select-selection-item {
				background: ${THEME.colors.darkBlue4};
			}
			.ant-select-selection-placeholder {
				color: ${THEME.colors.darkBlue4};
				display: inline-flex;
				align-items: center;
			}
		}
		.ant-select-arrow {
			.anticon-down {
				svg {
					fill: ${THEME.colors.darkBlue4};
				}
			}
		}
	}
	.ant-select-dropdown {
		&.dropdown-custom-content {
			overflow: auto !important;
			.rc-virtual-list-holder {
				max-height: calc(50vh - 160px) !important;
				.rc-virtual-list-holder-inner {
					transform: translateY(0px) !important;
				}
			}
			.rc-virtual-list-scrollbar {
				display: none !important;
			}
		}
	}
	.dropdown-menu-hidden {
		display: none;
	}
`;

export const StyledInput = styled(Input)`
	padding: 11px 10px;
	height: 41px;
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue2};
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.43;
	color: ${THEME.colors.gray5};

	.ant-input {
		background-color: ${THEME.colors.darkBlue2};
	}

	&::placeholder {
		color: ${THEME.colors.gray1};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}
`;

export const StyledIconRemoveCircle = styled(IconRemoveCircle)`
	cursor: pointer;
`;

export const StyledIframe = styled.iframe<{ height?: number; isMobile?: boolean }>`
	border: 0;
	height: ${({ height, isMobile }) =>
		height ? `${isMobile ? height + variantMobile : height + variantDesktop}px` : '100vh'};
	min-height: 100%;
`;

export const StyledMarkIframe = styled.div<{ height?: number; isMobile?: boolean }>`
	position: absolute;
	min-height: 100%;

	height: ${({ height, isMobile }) =>
		height ? `${isMobile ? height + variantMobile : height + variantDesktop}px` : '100vh'};

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1002;
	background: ${THEME.colors.black};
	opacity: 0.05;
`;

export const StyledWrapperContentIframe = styled.div<{ isMobile?: boolean }>`
	width: ${({ isMobile }) => (isMobile ? '350px' : '100%')};
	margin: auto;
	position: relative;
	min-height: 100%;
	height: 100%;
`;

export const StyledWrapperIframe = styled.div`
	height: 100%;
	width: 100%;
	overflow: auto;
	background: ${THEME.colors.white};
`;

export const StyledWrapperMessage = styled.div`
	text-align: left;
`;

export const StyledConfirmRemoveLiveWidget = styled.div`
	margin-bottom: 20px;
`;

export const StyledNameCampaign = styled.p`
	margin-bottom: 5px;
`;

export const StyledSubRemove = styled.div`
	margin-top: 20px;
`;
