import { TYPE_CUSTOM_FIELD } from '@constants/content/widgetManager';
import { CUSTOM_FIELD_SUFFIX } from '@constants/creator';
import THEME from '@constants/themes/themes';
import EditableElement from '@cores/EditableElement';
import { CreatorProfileSummaryDto } from '@models/creator/profile';
import {
	StyledFormItem,
	StyledSelect,
	StyledTag,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { notFoundContent } from '@utils/renderComponent';
import { Select } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type CustomInformationProps = {
	creatorDetails: CreatorProfileSummaryDto | null;
	form: any;
};

const CustomInformation = (props: CustomInformationProps) => {
	const { t } = useTranslation();
	const { creatorDetails, form } = props;

	const renderCustomInformation = () => {
		return creatorDetails?.creatorCustomProperties?.map((item) => {
			let childElement = item.values;
			const otherProps: {
				editElement?: ReactNode;
			} = {};
			const labelRules = [
				{
					required: true,
					message: t('creator_summary.modal.required'),
				},
			];

			if (
				item.type.toLowerCase() === TYPE_CUSTOM_FIELD.dropdown &&
				typeof item.values === 'object'
			) {
				childElement = (
					<>
						{item?.values?.map((value: string, index: number) => {
							return (
								<StyledTag key={value} margin={!index ? '4px 4px 4px 0' : '4px'}>
									{value}
								</StyledTag>
							);
						})}
					</>
				);

				otherProps.editElement = (
					<StyledFormItem name={`${item.key}${CUSTOM_FIELD_SUFFIX}`}>
						<StyledSelect
							mode="tags"
							notFoundContent={notFoundContent(t)}
							placeholder={''}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							showSearch
							autoFocus>
							{item.values.map((option: string) => {
								return (
									<Select.Option key={option} value={option}>
										{option}
									</Select.Option>
								);
							})}
						</StyledSelect>
					</StyledFormItem>
				);
			}

			return (
				<StyledWrapperContent key={item.key} margin="12px 0">
					<EditableElement
						form={form}
						textColor={THEME.colors.gray1}
						info={item.name}
						placeholder={item.placeholder || ''}
						fieldName={item.key}
						rules={labelRules}
					/>
					<EditableElement
						form={form}
						textColor={THEME.colors.gray5}
						info={childElement}
						fieldName={`${item.key}${CUSTOM_FIELD_SUFFIX}`}
						inputType={item?.type}
						{...otherProps}
					/>
				</StyledWrapperContent>
			);
		});
	};

	return <>{renderCustomInformation()}</>;
};

export default CustomInformation;
