import { Layout } from 'antd';
import styled     from 'styled-components';

const StyledLayoutContainer = styled( Layout )`
  min-height: 100vh;
`;

type PublicLayoutProps = {
    component: any;
    rest: any;
}

const PublicLayout = ( { component: Component, rest }: PublicLayoutProps ) => {
	return (
		<StyledLayoutContainer>
			<Component { ...rest } />
		</StyledLayoutContainer>
	);
};

export default PublicLayout;
