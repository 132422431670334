import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { Layout, message } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ActionBar from '@components/Rewards/History/ActionBar';
import Filter from '@components/Rewards/History/Filter';
import HistoryList from '@components/Rewards/History/HistoryList';
import Header from '@cores/Header';

// Context
import RewardsHistoryContext from '@contexts/Rewards/History';

// Constant
import { Paginator } from '@constants/paginator';

// Redux
import { getRewardsHistory, handleColumnListRewards } from '@stores/rewards/rewards.actions';

// Styled
import { StyledSider } from '@styled/Rewards/RewardsStyled';

// Model
import { RewardsLogColumnsDto } from '@models/rewards/log';

// Icon
import Actions from '@components/Rewards/History/Actions';
import { REWARD_API_URL } from '@constants/APIs';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { defaultSiderWidth } from '@constants/content/albums';
import { DEFAULT_ERROR } from '@constants/errors';
import { FIXED } from '@constants/reward';
import { deleteRequest, getRequest, putRequest } from '@helpers/requestHelpers';
import { renderCopiedText } from '@utils/renderComponent';

const { Content } = Layout;

const History = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { toggleColumnListRewards } = useSelector((state: any) => state.rewards);

	const [columns, setColumns] = useState<RewardsLogColumnsDto[]>([]);
	const [fetchingUpdateRewardsHistory, setFetchingUpdateRewardsHistory] =
		useState<boolean>(false);
	const [fetchingDeleteRewardsHistory, setFetchingDeleteRewardsHistory] =
		useState<boolean>(false);
	const [filterCollapsed, setFilterCollapsed] = useState<boolean>(false);
	const [actionCollapsed, setActionCollapsed] = useState<boolean>(false);
	const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
	const [discountList, setDiscountList] = useState<any[]>([]);
	const [campaignList, setCampaignList] = useState<any[]>([]);
	const [toggleColumns, setToggleColumns] = useState<RewardsLogColumnsDto[]>([
		{
			title: t('rewards.rule'),
			dataIndex: 'ruleTitle',
			show: toggleColumnListRewards.includes('ruleTitle'),
			sorter: () => {},
			width: 250,
			disabled: true,
			render: (val: string) => renderCopiedText(val),
		},
		{
			title: t('rewards.type'),
			dataIndex: 'rewardType',
			show: toggleColumnListRewards.includes('rewardType'),
			width: 150,
			sorter: () => {},
			render: (rewardsType: string) =>
				rewardsType &&
				renderCopiedText(
					`${rewardsType
						.toLowerCase()
						.split(' ')
						.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
						.join(' ')}`,
				),
			disabled: true,
		},
		{
			title: t('rewards.estimated_value'),
			dataIndex: 'estimatedCashValue',
			show: toggleColumnListRewards.includes('estimatedCashValue'),
			align: 'right' as 'right',
			sorter: () => {},
			render: (estimatedCashValue: number) =>
				estimatedCashValue && renderCopiedText(`$${estimatedCashValue}`),
			width: 250,
			disabled: true,
		},
		{
			title: t('rewards.discount_amount'),
			dataIndex: 'discountAmount',
			show: toggleColumnListRewards.includes('discountAmount'),
			width: 250,
			align: 'right' as 'right',
			sorter: () => {},
			render: (discountAmount: number, record: any) =>
				discountAmount &&
				renderCopiedText(
					`${
						record.discountType === FIXED.toLowerCase() || record.discountType === FIXED
							? `$${discountAmount} Off`
							: `${discountAmount}% Off`
					}`,
				),
		},
		{
			title: t('rewards.code'),
			dataIndex: 'codeValue',
			show: toggleColumnListRewards.includes('codeValue'),
			width: 250,
			sorter: () => {},
			render: (val: string) => renderCopiedText(val),
		},
		{
			title: t('rewards.campaign'),
			dataIndex: 'campaignName',
			show: toggleColumnListRewards.includes('campaignName'),
			width: 250,
			sorter: () => {},
			render: (val: string) => renderCopiedText(val),
		},
		{
			title: t('rewards.creator_name'),
			dataIndex: 'creatorName',
			show: toggleColumnListRewards.includes('creatorName'),
			width: 250,
			disabled: true,
			sorter: () => {},
			render: (val: string) => renderCopiedText(val),
		},
		{
			title: t('rewards.creator_email'),
			dataIndex: 'creatorEmail',
			show: toggleColumnListRewards.includes('creatorEmail'),
			width: 250,
			sorter: () => {},
			render: (val: string) => renderCopiedText(val),
		},
		{
			title: t('rewards.actual_value'),
			dataIndex: 'actualCashValue',
			align: 'right' as 'right',
			show: toggleColumnListRewards.includes('actualCashValue'),
			render: (actualCashValue: number) =>
				actualCashValue && renderCopiedText(`$${actualCashValue}`),
			width: 250,
			sorter: () => {},
		},
		{
			title: t('rewards.date'),
			dataIndex: 'rewardDate',
			show: toggleColumnListRewards.includes('rewardDate'),
			width: 250,
			render: (rewardDate: string) =>
				renderCopiedText(
					convertUtcToLocalTimeWithFormat(
						new Date(rewardDate).getTime(),
						TYPE_FORMAT_DATE.TIME,
					),
				),
			sorter: () => {},
		},
		{
			title: t('rewards.status'),
			dataIndex: 'status',
			render: (status: string) =>
				renderCopiedText(`${status[0].toUpperCase()}${status.substr(1)}`),
			show: toggleColumnListRewards.includes('status'),
			width: 150,
			sorter: () => {},
		},
		{
			title: t('rewards.product_id'),
			dataIndex: 'productResponse',
			show: toggleColumnListRewards.includes('productResponse'),
			width: 250,
			key: 'productResponseId',
			render: (productResponse: any) =>
				productResponse && renderCopiedText(`${productResponse.originalId}`),
		},
		{
			title: t('rewards.product_link'),
			dataIndex: 'productResponse',
			show: toggleColumnListRewards.includes('productResponseLink'),
			width: 250,
			key: 'productResponseLink',
			render: (productResponse: any) =>
				productResponse && renderCopiedText(`${productResponse.webUrl}`),
		},
	]);

	const [getListRewardsParams, setGetListRewardsParams] = useState<any>({
		params: {
			page: Paginator.defaultPage,
			maxRecords: Paginator.pageSize,
			search: '',
			sortOrder: undefined,
			sortField: undefined,
			filter: {},
		},
	});

	useEffect(() => {
		if (selectedRowKeys.length === 0) {
			setActionCollapsed(false);
		}
	}, [selectedRowKeys]);

	useEffect(() => {
		dispatch(getRewardsHistory(getListRewardsParams));
		setSelectRowKeys([]);
	}, [getListRewardsParams]);

	useEffect(() => {
		getDiscountAndCampaign();
		updateColumnsTable(toggleColumns);
	}, []);

	useEffect(() => {
		const columnShow: RewardsLogColumnsDto[] = [...toggleColumns];
		for (const columnItem of columnShow) {
			columnItem.show = toggleColumnListRewards.includes(
				columnItem?.key || columnItem?.dataIndex,
			);
		}

		setToggleColumns(columnShow);
		updateColumnsTable(columnShow);
	}, [toggleColumnListRewards]);

	const changeToggleColumns = (toggleColumns: RewardsLogColumnsDto[]) => {
		const columnShow: RewardsLogColumnsDto[] = toggleColumns.filter((item) => item.show);
		const listKeyShow: string[] = [];
		for (const columnItem of columnShow) {
			listKeyShow.push(columnItem?.key || columnItem?.dataIndex || '');
		}
		dispatch(handleColumnListRewards(listKeyShow));
	};

	const updateColumnsTable = (toggleColumns: RewardsLogColumnsDto[]) => {
		const columns: RewardsLogColumnsDto[] = toggleColumns.filter((item) => item.show);
		setColumns(columns);
	};

	const handleToggleFilters = () => {
		setFilterCollapsed(!filterCollapsed);

		if (!filterCollapsed) {
			setActionCollapsed(false);
		}
	};

	const handleToggleAction = () => {
		setActionCollapsed(!actionCollapsed);

		if (!actionCollapsed) {
			setFilterCollapsed(false);
		}
	};

	const handleCloseSiders = () => {
		setActionCollapsed(false);
		setFilterCollapsed(false);
	};

	const handleChangeSelectedRowKeys = (key: number[]) => {
		setSelectRowKeys(key);
	};

	const handleChangeGetRewardsHistoryParams = (params: any) => {
		setGetListRewardsParams(params);
	};

	const getDiscountAndCampaign = () => {
		const url = `${REWARD_API_URL}/filter`;

		getRequest(url)
			.then((response) => {
				if (response.status === 200) {
					setDiscountList(response.body.data.result.availableDiscounts);
					setCampaignList(response.body.data.result.availableCampaigns);
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
			})
			.catch((err) => {
				message.error(DEFAULT_ERROR);
			});
	};

	const handleUpdateRewards = (params: any) => {
		const payload = {
			...params,
			ids: selectedRowKeys,
		};
		const url = `${REWARD_API_URL}/update`;

		setFetchingUpdateRewardsHistory(true);
		putRequest(url, payload)
			.then((response) => {
				if (response.status === 200) {
					message.success(t('rewards_history.message.rewards_updated'));
					dispatch(getRewardsHistory(getListRewardsParams));
					setSelectRowKeys([]);
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
				setFetchingUpdateRewardsHistory(false);
			})
			.catch((err) => {
				setFetchingUpdateRewardsHistory(false);
				message.error(DEFAULT_ERROR);
			});
	};

	const handleDeleteRewards = () => {
		const payload = selectedRowKeys;
		const url = `${REWARD_API_URL}/`;

		setFetchingDeleteRewardsHistory(true);
		deleteRequest(url, payload)
			.then((response) => {
				if (response.status === 200) {
					message.success(t('rewards_history.message.rewards_deleted'));
					dispatch(getRewardsHistory(getListRewardsParams));
					setSelectRowKeys([]);
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
				setFetchingDeleteRewardsHistory(false);
			})
			.catch((err) => {
				setFetchingDeleteRewardsHistory(false);
				message.error(DEFAULT_ERROR);
			});
	};

	return (
		<RewardsHistoryContext.Provider
			value={{
				loadingDelete: fetchingDeleteRewardsHistory,
				loadingUpdate: fetchingUpdateRewardsHistory,
				selectedRowKeys: selectedRowKeys,
				discountList: discountList,
				campaignList: campaignList,
				getListRewardsParams: getListRewardsParams,
				handleChangeGetRewardsHistoryParams: handleChangeGetRewardsHistoryParams,
				handleUpdateRewards: handleUpdateRewards,
				handleDeleteRewards: handleDeleteRewards,
				handleChangeSelectedRowKeys: handleChangeSelectedRowKeys,
			}}>
			<div className="main-content creator-summary">
				<Header>
					<ActionBar
						columns={toggleColumns}
						handleToggleFilters={handleToggleFilters}
						handleToggleAction={handleToggleAction}
						handleChangeToggleColumns={changeToggleColumns}
					/>
				</Header>
				<Layout>
					<Content>
						<HistoryList columns={columns} />
					</Content>
					<StyledSider
						width={`${defaultSiderWidth}px`}
						margin={filterCollapsed}
						collapsedWidth="0"
						trigger={null}
						collapsible
						reverseArrow={true}
						collapsed={!filterCollapsed}>
						<Filter closeFilters={handleCloseSiders} />
					</StyledSider>
					<StyledSider
						width={`${defaultSiderWidth}px`}
						margin={actionCollapsed}
						collapsedWidth="0"
						trigger={null}
						collapsible
						reverseArrow={true}
						collapsed={!actionCollapsed}>
						<Actions closeFilters={handleCloseSiders} />
					</StyledSider>
				</Layout>
			</div>
		</RewardsHistoryContext.Provider>
	);
};

export default React.memo(History);
