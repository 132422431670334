import { IconDislike, IconHeart, IconLike } from '@assets/icons';
import { UNIT_VALUE } from '@constants/common';
import { TYPE_VOTE } from '@constants/content/contentLibrary';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { getObject } from '@helpers/storageHelpers';
import { ContentRootStateDto, ContentStoreDto } from '@models/content/contentLibrary/store';
import { updateEmojiRequest } from '@stores/actions';
import { StyledIcon, StyledTooltip } from '@styled/Common/CommonStyled';
import {
	StyledContainerVote,
	StyledInfoEmail,
	StyledNumVote,
	StyledVote,
	StyledWrapperViewTooltip,
	StyledWrapperVoteContent,
} from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { abbreviateNumber } from '@utils/common';
import { useWindowSize } from '@utils/customHooks';
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type VoteTypes = {
	id: string;
	ico: JSX.Element;
	val: number;
	classIcon: string;
	listEmail: Array<string>;
};

const VoteContent = () => {
	const infoUser = getObject('user');

	const dispatch = useDispatch();

	const { isDisableAction, currentMedia } = useContext(ContentDetailContext);
	const { loadingContentEmoji, contentEmoji }: ContentStoreDto = useSelector(
		(state: ContentRootStateDto) => state.content,
	);

	const [visibleTooltipVote, setVisibleTooltipVote] = useState<string>('');

	const [widthWindow, heightWindow] = useWindowSize();

	const listVote = [
		{
			id: TYPE_VOTE.LOVE,
			ico: <IconHeart />,
			val: contentEmoji?.love.emails?.length || 0,
			classIcon: 'icon_love',
			listEmail: contentEmoji?.love.emails || [],
			visible: visibleTooltipVote === TYPE_VOTE.LOVE,
		},
		{
			id: TYPE_VOTE.LIKE,
			ico: <IconLike />,
			val: contentEmoji?.like.emails?.length || 0,
			classIcon: 'icon_like',
			listEmail: contentEmoji?.like.emails || [],
			visible: visibleTooltipVote === TYPE_VOTE.LIKE,
		},
		{
			id: TYPE_VOTE.DISLIKE,
			ico: <IconDislike />,
			val: contentEmoji?.dislike.emails?.length || 0,
			classIcon: 'icon_dislike',
			listEmail: contentEmoji?.dislike.emails || [],
			visible: visibleTooltipVote === TYPE_VOTE.DISLIKE,
		},
	];

	const handleVote = (vote: VoteTypes, isRemove: boolean) => {
		const length = vote?.listEmail?.length;
		if (!isRemove) {
			setVisibleTooltipVote(vote?.id);
		} else {
			if (length - 1 === 0) {
				setVisibleTooltipVote('');
			}
		}
		dispatch(
			updateEmojiRequest({ contentId: currentMedia?.id, emojiEnum: vote?.id, isRemove }),
		);
	};

	const renderVote = (vote: VoteTypes) => {
		const isActive = vote?.listEmail?.includes(infoUser?.email);
		return (
			<StyledVote className={isActive ? 'active' : ''} key={vote?.id}>
				<StyledIcon
					onClick={() => handleVote(vote, isActive)}
					className={`icon_feel ${vote.classIcon}`}>
					{vote?.ico}
				</StyledIcon>
				<StyledNumVote>{abbreviateNumber(vote?.val)}</StyledNumVote>
			</StyledVote>
		);
	};

	const hasScroll = useMemo(() => {
		return heightWindow * UNIT_VALUE.S_01 < UNIT_VALUE.S_77;
	}, [heightWindow]);

	return (
		<StyledWrapperVoteContent className={hasScroll ? 'has_scroll' : ''}>
			<StyledContainerVote disabled={loadingContentEmoji || isDisableAction}>
				{listVote?.map((item) => {
					return (
						<StyledTooltip
							className="tooltip_custom"
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode as HTMLElement
							}
							onVisibleChange={(val: boolean) => {
								if (item?.val) {
									let valChange = item?.id;
									if (!val) {
										valChange = '';
									}
									setVisibleTooltipVote(valChange);
								}
							}}
							visible={item?.visible}
							key={item?.id}
							title={
								<StyledWrapperViewTooltip>
									{item?.listEmail?.map((item) => (
										<StyledInfoEmail key={item}>{item}</StyledInfoEmail>
									))}
								</StyledWrapperViewTooltip>
							}>
							{renderVote(item)}
						</StyledTooltip>
					);
				})}
			</StyledContainerVote>
		</StyledWrapperVoteContent>
	);
};

export default VoteContent;
