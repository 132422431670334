import { IconFileCopy, IconRestore } from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { ACTION_ON_MESSAGE, EMAIL_TEMPLATE_FOLDER_TYPES } from '@constants/messages';
import THEME from '@constants/themes/themes';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { EmailTemplateItemDto, EmailTemplatesDataDto } from '@models/messages/summary';
import { StyledIcon, StyledTooltip } from '@styled/Common/CommonStyled';
import {
	StyledActionFlex,
	StyledActionSwitch,
	StyledAutomatedActions,
	StyledButtonRestore,
	StyledIconArchive,
	StyledIconEdit,
	StyledMenu,
	StyledText,
} from '@styled/Messages/EmailTemplatesStyled';
import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AssociatedCampaignChip from './AssociatedCampaignChip';

interface IMenuTemplates {
	dataTemplate: EmailTemplatesDataDto[];
	onEdit?: (selectId: number) => void;
	onClickAction?: (data: EmailTemplatesDataDto, action: string) => void;
	onClickRestoreTemplate?: (id: number) => void;
	onSelectItem: (selectId: number) => void;
	isAutomated?: boolean;
	isCustom?: boolean;
	onCreateTemplate?: (template: EmailTemplateItemDto) => void;
}

const MenuTemplates: React.FC<IMenuTemplates> = ({
	dataTemplate,
	onSelectItem,
	onEdit = () => {},
	onClickAction = () => {},
	onClickRestoreTemplate = () => {},
	isAutomated = false,
	isCustom = false,
	onCreateTemplate = () => {},
}) => {
	const { t } = useTranslation();
	const { emailTemplate: { folder = '' } = {} } = useSelector((state: any) => state.messages);

	const [selectedKey, setSelectedKey] = useState<number | null>(null);

	useEffect(() => {
		setSelectedKey(dataTemplate[0].id);
	}, [folder]);

	const onSelect = (e: any) => {
		setSelectedKey(+e.key);
		onSelectItem(+e.key);
	};

	const onClickEdit = (id: number) => {
		onEdit(id);
	};

	const renderActions = (data: EmailTemplatesDataDto) => {
		const { enabled = false, id } = data;
		if (isCustom || isAutomated) {
			return (
				<StyledActionFlex gap="12px">
					{isAutomated && (
						<StyledActionSwitch
							onChange={() => onClickAction(data, 'switch')}
							defaultChecked={enabled}
							checked={enabled}
						/>
					)}
					<StyledTooltip title={t('button.edit')}>
						<StyledIconEdit
							className="email-template-icon-edit"
							onClick={() => onClickEdit(id)}
						/>
					</StyledTooltip>
					{isCustom && (
						<StyledTooltip title={t('button.duplicate')}>
							<StyledIcon size={20} onClick={() => handleDuplicateTemplate(data)}>
								<IconFileCopy />
							</StyledIcon>
						</StyledTooltip>
					)}
					<StyledTooltip title={t('button.archive')}>
						<StyledIconArchive
							className="email-template-icon-archive"
							onClick={() => onClickAction(data, 'archived')}
						/>
					</StyledTooltip>
				</StyledActionFlex>
			);
		}

		return (
			<StyledButtonRestore icon={<IconRestore />} onClick={() => onClickRestoreTemplate(id)}>
				Restore
			</StyledButtonRestore>
		);
	};

	const handleDuplicateTemplate = (data: EmailTemplatesDataDto) => {
		const { body, description, enabled, from, subject } = data;
		const copiedSuffix = ' - Copy';
		const originalDuplicatedTitle = `${data.title}${copiedSuffix}`;
		let duplicatedTitle = originalDuplicatedTitle;
		const titleList = dataTemplate.map((item) => item.title);

		/* eslint-disable */
		while (titleList.includes(duplicatedTitle)) {
			const foundItem = titleList.find((item) => item === duplicatedTitle);
			if (foundItem) {
				const elements = foundItem.split(copiedSuffix);
				const duplicatedIndex = (elements[elements.length - 1] || '0').trim();
				duplicatedTitle = `${originalDuplicatedTitle}${+duplicatedIndex + 1}`;
			}
		}
		/* eslint-enable */

		if (onCreateTemplate && typeof onCreateTemplate === 'function') {
			const duplicatedTemplate = {
				body,
				description,
				enabled,
				from,
				subject,
				title: duplicatedTitle,
				configTitle: duplicatedTitle,
				type: EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM,
				actionType: ACTION_ON_MESSAGE.DUPLICATE,
			};
			onCreateTemplate(duplicatedTemplate);
		}
	};

	return (
		<StyledMenu
			mode="inline"
			onSelect={onSelect}
			selectedKeys={`${selectedKey}`}
			isCustom={isCustom}>
			{dataTemplate.map((data) => {
				const {
					title = '',
					description = '',
					updateAt = 0,
					campaignsName = [],
				} = data || {};
				const showAssociatedCampaign = isCustom && !!campaignsName.length;

				return (
					<Menu.Item
						className="email-template-menu-item"
						key={data.id}
						icon={
							<StyledAutomatedActions margin={!description ? '-20px 0 0 0' : 'unset'}>
								{renderActions(data)}
							</StyledAutomatedActions>
						}>
						<StyledText fontWeight="bold">{title}</StyledText>
						<StyledText color={THEME.colors.gray5}>{description}</StyledText>
						<StyledText
							title={convertUtcToLocalTimeWithFormat(updateAt, TYPE_FORMAT_DATE.TIME)}
							className="automated-date last-updated overflow"
							fontSize="0.857rem"
							style={{
								maxWidth: `${
									showAssociatedCampaign ? 'calc(100% - 72px)' : '100%'
								}`,
							}}
							color={THEME.colors.gray1}>
							{t('email_templates.last_update')}{' '}
							{convertUtcToLocalTimeWithFormat(updateAt, TYPE_FORMAT_DATE.TIME)}
						</StyledText>
						{showAssociatedCampaign && <AssociatedCampaignChip template={data} />}
					</Menu.Item>
				);
			})}
		</StyledMenu>
	);
};

export default MenuTemplates;
