import { Modal, Radio, Table } from 'antd';
import styled from 'styled-components';

const StyledShareMediaHubModal = styled(Modal)``;

const StyledGuestTable = styled(Table)`
	margin: 20px 0;

	.ant-table table {
		thead.ant-table-thead {
			tr {
				th {
					line-height: unset !important;
				}
			}
		}

		tbody.ant-table-tbody {
			tr {
				td:not(.ant-table-selection-column) {
					line-height: unset !important;
				}
			}
		}
	}
`;

const StyledRadioGroup = styled(Radio.Group)`
	&.ant-radio-group {
		display: block !important;
	}
`;

const StyledRadio = styled(Radio)<{ isDisabled?: boolean }>`
	cursor: ${({ isDisabled }) => (isDisabled ? 'unset !important' : 'pointer')};
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none !important' : 'auto')};
	opacity: ${({ isDisabled }) => (isDisabled ? '0.5 !important' : 'unset')};
`;

export { StyledShareMediaHubModal, StyledGuestTable, StyledRadioGroup, StyledRadio };
