import {
	IconArrowLine,
	IconCancel,
	IconFilter,
	IconFullScreen,
	IconGrid,
	IconSort,
} from '@assets/icons';
import { COL_MEDIA, TYPE_SORT } from '@constants/ambassador';
import { FIRST_PAGE_INDEX } from '@constants/common';
import { SORT_DIRECTION } from '@constants/filter/filterContent';
import THEME from '@constants/themes/themes';
import AmbassadorDashboardManagerContext from '@contexts/AmbassadorDashboard';
import SocialActivityManagerContext from '@contexts/AmbassadorDashboard/socialActivity';
import { AmbassadorManagerStoreDto } from '@models/ambassador/stores';
import { ActionsTypeSort } from '@models/common/summary';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { TypeSortDto } from '@models/filter';
import { StyledTitleTab } from '@styled/AmbassadorDashboard';
import {
	StyledButton,
	StyledDropdown,
	StyledIcon,
	StyledText,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { Button, Menu, Tooltip } from 'antd';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type PropTypes = {};

const ActionSocial = (props: PropTypes) => {
	const { t } = useTranslation();
	const {
		handleToggleFilter,
		isFiltering,
		openFilter,
		resetFilter,
		paramSocial,
		setParamSocial,
		col,
		setCol,
		thumbFull,
		setThumbFull,
		isSubmission,
		handleChangeTab,
		contentList,
		socialList,
	} = useContext(SocialActivityManagerContext);
	const { setFullScreenSocial, fullScreenSocial } = useContext(AmbassadorDashboardManagerContext);

	const { posts }: AmbassadorManagerStoreDto = useSelector((state: any) => state.ambassadors);
	const { totalContent } = useSelector((state: ContentRootStateDto) => state.content);

	const handleSort = (val: TypeSortDto) => {
		if (val.sortBy !== paramSocial.sortBy) {
			setParamSocial({ ...paramSocial, page: FIRST_PAGE_INDEX, ...val });
		} else {
			setParamSocial({
				...paramSocial,
				page: FIRST_PAGE_INDEX,
				sortDirection:
					paramSocial.sortDirection === SORT_DIRECTION.DESC
						? SORT_DIRECTION.ASC
						: SORT_DIRECTION.DESC,
			});
		}
	};

	const onChangeScreenSocial = () => {
		setFullScreenSocial(!fullScreenSocial);
		if (fullScreenSocial) {
			setCol(col === COL_MEDIA.C6 ? COL_MEDIA.C3 : COL_MEDIA.C2);
		} else {
			setCol(col === COL_MEDIA.C2 ? COL_MEDIA.C3 : COL_MEDIA.C6);
		}
	};

	const onChangeGrid = () => {
		setThumbFull(!thumbFull);
		if (fullScreenSocial) {
			setCol(col === COL_MEDIA.C6 ? COL_MEDIA.C3 : COL_MEDIA.C6);
		} else {
			setCol(col === COL_MEDIA.C2 ? COL_MEDIA.C3 : COL_MEDIA.C2);
		}
	};

	const actionSort: ActionsTypeSort[] = [
		{
			id: 1,
			name: t('ambassador.sort.date'),
			click: () => {},
			valueSort: { sortBy: TYPE_SORT.SORT_DATE, sortDirection: SORT_DIRECTION.DESC },
		},

		{
			id: 2,
			name: t('ambassador.sort.follow'),
			click: () => {},
			valueSort: { sortBy: TYPE_SORT.FOLLOWER_COUNT, sortDirection: SORT_DIRECTION.DESC },
		},
		{
			id: 3,
			name: t('ambassador.sort.likes'),
			click: () => {},
			valueSort: { sortBy: TYPE_SORT.LIKES, sortDirection: SORT_DIRECTION.DESC },
		},
		{
			id: 4,
			name: t('ambassador.sort.comments'),
			click: () => {},
			valueSort: { sortBy: TYPE_SORT.COMMENTS, sortDirection: SORT_DIRECTION.DESC },
		},
	];

	const menuActionSort = (
		<Menu>
			{actionSort.map((item: ActionsTypeSort) => (
				<Menu.Item
					disabled={!!item.disabled}
					key={item.id}
					onClick={() => handleSort(item?.valueSort)}>
					<StyledTitle fontSize="0.875rem" display="inline" margin="0 16px 0 0">
						{`${t('ambassador.sort_by')} ${item.name}`}
					</StyledTitle>
					{paramSocial?.sortBy === item.valueSort.sortBy && (
						<StyledIcon
							size={16}
							top="5px"
							right="3px"
							className={`absolute ic_sort_by ${
								paramSocial.sortDirection === SORT_DIRECTION.ASC ? 'swap' : ''
							}`}
							margin="0 0 5px 0">
							<IconArrowLine />
						</StyledIcon>
					)}
				</Menu.Item>
			))}
		</Menu>
	);

	const objResult = useMemo(() => {
		if (isSubmission) {
			return {
				value: contentList.length,
				total: totalContent || 0,
			};
		}
		return { value: socialList.length, total: posts?.totalHits || 0 };
	}, [isSubmission, posts, totalContent, contentList, socialList]);

	return (
		<StyledWrapperTitle padding="16px 8px">
			<StyledWrapperAction style={{ maxWidth: '47%', width: '360px' }}>
				<StyledTitleTab
					title={t('ambassador.content_submissions')}
					style={{ maxWidth: 'calc(60% - 10px)' }}
					onClick={() => handleChangeTab(true)}
					className={isSubmission ? 'active' : ''}>
					{t('ambassador.content_submissions')}
				</StyledTitleTab>

				<StyledWrapperContent display="inline-flex" fontSize="20px" margin="0 6px">
					/
				</StyledWrapperContent>
				<StyledTitleTab
					title={t('ambassador.social_activity')}
					style={{ maxWidth: 'calc(40% - 10px)' }}
					className={isSubmission ? '' : 'active'}
					onClick={() => handleChangeTab(false)}>
					{t('ambassador.social_activity')}
				</StyledTitleTab>
			</StyledWrapperAction>

			<StyledWrapperAction
				style={{ maxWidth: '53%', width: 'calc(100% - 372px)' }}
				justifyContent="end">
				<StyledText
					title={t('social_search.displaying_results', {
						value: `${objResult.value} / ${objResult.total}`,
					})}
					className="overflow"
					whiteSpace="nowrap"
					color={THEME.colors.gray5}
					fontSize="12px"
					style={{ maxWidth: 'calc(100% - 203px)' }}
					margin="0 8px 0 0">
					{t('social_search.displaying_results', {
						value: `${objResult.value} / ${objResult.total}`,
					})}
				</StyledText>

				<Tooltip title={t('ambassador.expand')}>
					<StyledButton
						disabled={openFilter}
						onClick={() => onChangeScreenSocial()}
						icon={
							<StyledIcon size={19}>
								<IconFullScreen />
							</StyledIcon>
						}
					/>
				</Tooltip>

				<Tooltip title={t('ambassador.toggle_grid')}>
					<StyledButton
						disabled={openFilter}
						style={{ marginLeft: '8px' }}
						icon={<IconGrid />}
						onClick={() => onChangeGrid()}
					/>
				</Tooltip>

				<StyledDropdown
					className="dropdown_action"
					placement="bottomLeft"
					overlay={menuActionSort}
					trigger={['click']}>
					<Tooltip title={t('ambassador.sort_text')}>
						<Button
							disabled={openFilter || isSubmission}
							icon={<IconSort />}
							style={{ marginLeft: '8px' }}
						/>
					</Tooltip>
				</StyledDropdown>
				<Tooltip title={t('content_management.action_bar.filter')}>
					<StyledButton
						icon={
							isFiltering ? (
								<StyledIcon
									className="icon_white"
									onClick={(e: React.MouseEvent<HTMLElement>) => {
										e.stopPropagation();
										e.preventDefault();
										resetFilter();
									}}>
									<IconCancel />
								</StyledIcon>
							) : (
								<IconFilter />
							)
						}
						style={{ marginLeft: '8px' }}
						type={isFiltering ? 'primary' : undefined}
						onClick={handleToggleFilter}>
						{t('content_management.action_bar.filter')}
					</StyledButton>
				</Tooltip>
			</StyledWrapperAction>
		</StyledWrapperTitle>
	);
};

export default ActionSocial;
