import { IconCopy } from '@assets/icons';
import { MFA_SECTION } from '@constants/settings/security';
import RegistrationAuthenticatorAppContext from '@contexts/Settings/RegistrationAuthenticatorApp';
import { UserStoreType } from '@stores/user/user.types';
import {
	StyledButton,
	StyledFlex,
	StyledIcon,
	StyledRow,
	StyledText,
} from '@styled/Common/CommonStyled';
import {
	StyledRegistrationAuthenticatorCol,
	StyledSecretKey,
} from '@styled/Settings/UserProfileStyled';
import { Image, message } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const PairingAuthenticatorAppCode = () => {
	const { t } = useTranslation();
	const { authenticatorAppInfo }: UserStoreType = useSelector((state: any) => state.user);
	const { secretKey = '', qrCode = '' } = authenticatorAppInfo || {};
	const { setCurrentSection, onCancel } = useContext(RegistrationAuthenticatorAppContext);

	const [enterCodeManually, setEnterCodeManually] = useState<boolean>(false);

	const renderQRCode = () => {
		return (
			<StyledRow justify="start" gutter={12}>
				<StyledRegistrationAuthenticatorCol span={14}>
					<Image
						preview={false}
						src={qrCode}
						width={301}
						height={301}
						alt="mfa-qa-code"
					/>
				</StyledRegistrationAuthenticatorCol>
				<StyledRegistrationAuthenticatorCol span={10}>
					<StyledText
						cursor="pointer"
						fontSize="16px"
						onClick={() => setEnterCodeManually(true)}>
						<u>{t('setting.user_profile.enter_code_manually')}</u>
					</StyledText>
					{renderButtonGroup()}
				</StyledRegistrationAuthenticatorCol>
			</StyledRow>
		);
	};

	const copySecretKey = () => {
		if (secretKey) {
			navigator.clipboard.writeText(secretKey);
			message.success(t('message.copied'));
		}
	};

	const renderManualCode = () => {
		return (
			<>
				<StyledSecretKey>
					<StyledText fontSize="18px">{secretKey}</StyledText>
					<StyledIcon onClick={copySecretKey}>
						<IconCopy />
					</StyledIcon>
				</StyledSecretKey>
				<StyledFlex margin="46px 0 0 0">
					<StyledText
						cursor="pointer"
						fontSize="16px"
						onClick={() => setEnterCodeManually(false)}>
						<u>{t('setting.user_profile.scan_qr_code_instead')}</u>
					</StyledText>
					{renderButtonGroup()}
				</StyledFlex>
			</>
		);
	};

	const renderButtonGroup = () => {
		return (
			<StyledFlex justify="flex-end">
				<StyledButton margin="0 12px 0 0" onClick={onCancel}>
					{t('button.cancel')}
				</StyledButton>
				<StyledButton
					type="primary"
					onClick={() => setCurrentSection(MFA_SECTION.CONFIRM_PAIRING)}>
					{t('button.continue')}
				</StyledButton>
			</StyledFlex>
		);
	};

	return (
		<div>
			<StyledText margin="0 0 13px 0">
				{t('setting.user_profile.download_authenticator_app')}
			</StyledText>
			{!enterCodeManually ? renderQRCode() : renderManualCode()}
		</div>
	);
};

export default PairingAuthenticatorAppCode;
