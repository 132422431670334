import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Empty } from 'antd';

// Constant
import THEME from '@constants/themes/themes';

// Styled
import { StyledText } from '@styled/Messages/MessageLogStyled';

// Context
import MessageLogContext from '@contexts/Messages/Logs';
import { DynamicFieldDto } from '@models/messages/summary';

type RecipientsProps = {};

const StyledWrapper = styled.div`
	background-color: ${THEME.colors.white};
	color: black;
	height: calc(100vh - 116px);
	overflow-x: scroll;
	padding: 10px;
`;

const StyledPreview = styled.div`
	height: auto;
	width: 100%;
`;

const StyledFlex = styled.div<{ justifyContent?: string; alignItem?: string }>`
	display: flex;
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
	align-items: ${({ alignItem }) => alignItem || 'center'};
`;

const StyledEmpty = styled(Empty)``;

const Preview = (props: RecipientsProps) => {
	const [formattedBody, setFormattedBody] = useState<string>('');
	const { selectedEmail = {} } = useContext(MessageLogContext);
	const { body = '', dynamicFields = [] } = selectedEmail;

	useEffect(() => {
		formatBody(body);
	}, [selectedEmail]);

	const formatBody = (body: string) => {
		let formattedBody = body;
		if (dynamicFields) {
			dynamicFields.map((item: any) => {
				if (item) {
					formattedBody = formattedBody.replaceAll(item.field, item.value);
				}
			});
		}
		setFormattedBody(formattedBody);
	};

	const renderTemplate = () => {
		return { __html: formattedBody };
	};

	return (
		<StyledWrapper>
			{selectedEmail ? (
				<StyledPreview dangerouslySetInnerHTML={renderTemplate()} />
			) : (
				<StyledPreview>
					<StyledEmpty />
				</StyledPreview>
			)}
		</StyledWrapper>
	);
};

export default Preview;
