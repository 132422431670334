import { IconKeyboardArrowRight } from '@assets/icons';
import { ROUTE_PATH } from '@constants/common';
import { SOCIAL, SOCIAL_PROFILE } from '@constants/social_search';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { StyledIcon, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledCollapse,
	StyledCollapseInfo,
	StyledDescription,
	StyledLabel,
	StyledLabelInfo,
	StyledLink,
	StyledOptionInfo,
	StyledSubDesc,
	SwapperSessionDetail,
} from '@styled/ContentLibrary/ContentDetailStyled';
import { replaceAtChar } from '@utils/common';
import { Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const { Panel } = StyledCollapse;

type PropTypes = {};

const DescAndCreatorInfo = (props: PropTypes) => {
	const { t } = useTranslation();
	const { currentMedia, isSocialContent } = useContext(ContentDetailContext);
	const { creatorProfile, social, creatorId, description } = currentMedia || {};

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo>
				<StyledTitle>{t('content_detail.creator_info')}</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	const renderDataCreatorInfo = () => {
		const creatorName = creatorProfile
			? `${creatorProfile?.firstName || ''} ${creatorProfile?.lastName || ''}`
			: '';
		const instagramUsername =
			social?.socialSource === SOCIAL.INSTAGRAM.toLowerCase() ? social?.socialUsername : '';
		const twitterUsername =
			social?.socialSource === SOCIAL.TWITTER.toLowerCase() ? social?.socialUsername : '';
		const tiktokUsername =
			social?.socialSource === SOCIAL.TIKTOK.toLowerCase() ? social?.socialUsername : '';
		const youtubeUsername =
			social?.socialSource === SOCIAL.YOUTUBE.toLowerCase() ? social?.socialUsername : '';

		const formatInstagramUsername = replaceAtChar(
			instagramUsername || creatorProfile?.instagramUsername || '',
		);

		const result = [
			{
				title: t('content_detail.name'),
				info: creatorName,
				href: `${ROUTE_PATH.CREATOR_DETAIL_EMPTY}${creatorId}`,
			},
			{
				title: t('content_detail.email'),
				info: creatorProfile?.email,
				href: `${ROUTE_PATH.CREATOR_DETAIL_EMPTY}${creatorId}`,
			},
			{
				title: t('content_detail.instagram'),
				info: formatInstagramUsername,
				href: `${SOCIAL_PROFILE.INSTAGRAM}${formatInstagramUsername}`,
			},
			{
				title: t('content_detail.twitter'),
				info: twitterUsername || creatorProfile?.twitterUsername,
				href: `${SOCIAL_PROFILE.TWTTER}${
					twitterUsername || creatorProfile?.twitterUsername
				}`,
			},
			{
				title: t('content_detail.tiktok'),
				info: tiktokUsername || creatorProfile?.tiktokUsername,
				href: `${SOCIAL_PROFILE.TIKTOK}${tiktokUsername || creatorProfile?.tiktokUsername}`,
			},
			{
				title: t('content_detail.youtube'),
				info: youtubeUsername || creatorProfile?.youtubeUsername,
				href: `${SOCIAL_PROFILE.YOUTUBE}${
					youtubeUsername || creatorProfile?.youtubeUsername
				}`,
			},
		];
		return result;
	};

	return (
		<>
			{!isSocialContent && (
				<StyledDescription>
					<StyledLabel style={{ marginBottom: '9px' }}>
						{t('content_detail.description')}
					</StyledLabel>
					<StyledSubDesc>{description}</StyledSubDesc>
				</StyledDescription>
			)}
			<SwapperSessionDetail>
				<StyledCollapse
					style={{ marginTop: '0px' }}
					className="collapse_content_detail"
					expandIconPosition="right"
					defaultActiveKey={'1'}>
					<Panel header={renderHeaderPanel()} key={'1'}>
						{renderDataCreatorInfo()?.map((item) => (
							<StyledOptionInfo key={item?.title}>
								<StyledLabelInfo>{item?.title}</StyledLabelInfo>
								<Tooltip
									placement="topLeft"
									getPopupContainer={(triggerNode: HTMLElement) =>
										triggerNode.parentNode as HTMLElement
									}
									overlayClassName="content-info-tooltip"
									title={item?.info}>
									{item?.href && (
										<StyledLink target={'_blank'} href={item?.href}>
											{item?.info}
										</StyledLink>
									)}
									{!item?.href && (
										<StyledTitle className="unset_height">
											{item.info}
										</StyledTitle>
									)}
								</Tooltip>
							</StyledOptionInfo>
						))}
					</Panel>
				</StyledCollapse>
			</SwapperSessionDetail>
		</>
	);
};

export default DescAndCreatorInfo;
