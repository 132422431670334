import { StyledButton, StyledText, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type ButtonGroupPropsType = {
	hasChanges: boolean;
	onCancel: () => void;
	submitText?: string;
	onSubmit: () => void;
	isLoading?: boolean;
	margin?: string;
	genericMargin?: string;
	otherPropsText?: any;
	[key: string]: any;
};

const StyledGroupButton = styled.div<{
	justify?: string;
	align?: string;
	isAlwaysDisplayed?: boolean;
	hasChanges: boolean | null;
}>`
	display: flex;
	justify-content: ${({ justify }) => justify || 'space-between'};
	${({ align }) => align && `align-items: ${align}`};
	visibility: ${({ hasChanges, isAlwaysDisplayed }) =>
		`${hasChanges || isAlwaysDisplayed ? 'unset' : 'hidden'}`};

	* {
		transition: none;
	}
`;

const ButtonGroup = (props: ButtonGroupPropsType) => {
	const { t } = useTranslation();
	const {
		hasChanges,
		onCancel,
		submitText = t('button.save_changes'),
		onSubmit,
		isLoading = false,
		margin = '0 12px',
		genericMargin,
		otherPropsText,
		...others
	} = props;

	return (
		<StyledWrapperContent
			margin={genericMargin}
			display={hasChanges ? 'inline-block' : 'none'}
			{...others}>
			<StyledGroupButton
				justify="flex-end"
				align="center"
				isAlwaysDisplayed={submitText === t('button.add')}
				hasChanges={hasChanges}>
				<StyledButton isDisabled={isLoading} onClick={onCancel}>
					{t('button.cancel')}
				</StyledButton>
				{submitText !== t('button.add') && (
					<StyledText
						fontSize={others.fontSizeText}
						lineHeight="1.2"
						margin={margin}
						color="rgb(103, 115, 125)"
						{...otherPropsText}>
						{t('social_aggregator.unsaved_changes')}
					</StyledText>
				)}
				<StyledButton
					loading={isLoading}
					width={submitText === t('button.add') ? '87px' : 'unset'}
					isDisabled={others.isDisabledSubmitBtn}
					onClick={onSubmit}
					type="primary">
					{submitText}
				</StyledButton>
			</StyledGroupButton>
		</StyledWrapperContent>
	);
};

export default ButtonGroup;
