import { DEFAULT_ERROR } from '@constants/errors';
import { STATUSCODE } from '@constants/APIs';
import { NONE, IN_PROGRESS, SUCCEEDED, FAILED } from '@constants/status';
import { WidgetManagerStoreDto, WidgetPayloadDto } from '@models/content/widgetManager/stores';
import { Action, handleActions } from 'redux-actions';
import {
	cloneLiveWidgetTypes,
	createDraftWidgetBuilderTypes,
	fetchCustomFieldWidgetTypes,
	fetchDetailBuilderTypes,
	fetchDetailLiveWidgetTypes,
	fetchDetailWidgetDefaultTypes,
	publishWidgetBuilderTypes,
	removeWidgetBuilderTypes,
	resumeLiveDraftTypes,
	saveDraftWidgetBuilderTypes,
	updateStatusRecentlyUpdatedTypes,
	updateStoreWidgetTypes,
	uploadFileWidgetTypes,
	uploadFontBuilderTypes,
	widgetManagerTypes,
} from './widgetManager.types';
import {
	DEFAULT_STORE_CUSTOM_FIELD,
	STATUS_UPLOAD_FILE_WIDGET,
} from '@constants/content/widgetManager';

const initialState: WidgetManagerStoreDto = {
	fetchingWidgetManager: false,
	loadingWidget: false,
	loadingCustomFieldWidget: false,
	loadingDetailWidgetDefault: false,
	statusDraftWidgetBuilder: NONE,
	statusGetWidgetDetail: NONE,
	statusCreateWidgetBuilder: NONE,
	resumeLiveDraftStatus: NONE,
	cloneLiveWidgetStatus: NONE,
	[STATUS_UPLOAD_FILE_WIDGET.bgFile]: NONE,
	[STATUS_UPLOAD_FILE_WIDGET.logoFile]: NONE,
	statusRemoveWidget: NONE,
	statusPublishWidget: NONE,
	statusUploadFontBuilder: NONE,
	statusButtonFile: NONE,
	urlFontBuilders: [],
	widgetManagers: [],
	liveWidget: null,
	urlFile: '',
	widgetBuilder: null,
	widgetDraftRes: null,
	customFields: {
		...DEFAULT_STORE_CUSTOM_FIELD,
	},
	detailWidgetDefault: null,
	error: '',
};

const getListWidgetManagerRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	fetchingWidgetManager: true,
});

const getListWidgetManagerSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: widgetManagers = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingWidgetManager: false,
			widgetManagers,
			error: '',
		};
	}

	return {
		...state,
		fetchingWidgetManager: false,
		widgetManagers: [],
		error: status,
	};
};

const getListWidgetManagerFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			fetchingWidgetManager: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingWidgetManager: false,
	};
};

const createDraftWidgetBuilderRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	statusCreateWidgetBuilder: IN_PROGRESS,
});

const createDraftWidgetBuilderSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: widgetBuilder,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusCreateWidgetBuilder: SUCCEEDED,
			widgetBuilder,
			error: '',
		};
	}

	return {
		...state,
		statusCreateWidgetBuilder: FAILED,
		widgetBuilder: null,
		error: status,
	};
};

const createDraftWidgetBuilderFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusCreateWidgetBuilder: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusCreateWidgetBuilder: FAILED,
	};
};

const createDraftWidgetBuilderEnd = (state: WidgetManagerStoreDto) => {
	return {
		...state,
		statusCreateWidgetBuilder: NONE,
	};
};

const saveDraftWidgetBuilderRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	statusDraftWidgetBuilder: IN_PROGRESS,
});

const saveDraftWidgetBuilderSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: widgetBuilder,
	} = payload.data;

	let keyStatus = 'statusDraftWidgetBuilder';

	if (state?.statusGetWidgetDetail === IN_PROGRESS) {
		keyStatus = 'statusGetWidgetDetail';
	}

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			[keyStatus]: SUCCEEDED,
			widgetBuilder,
			error: '',
		};
	}

	return {
		...state,
		[keyStatus]: FAILED,
		widgetBuilder: null,
		error: status,
	};
};

const saveDraftWidgetBuilderFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	let keyStatus = 'statusDraftWidgetBuilder';
	if (state?.statusGetWidgetDetail === IN_PROGRESS) {
		keyStatus = 'statusGetWidgetDetail';
	}
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			[keyStatus]: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		[keyStatus]: FAILED,
	};
};

const saveDraftWidgetBuilderEnd = (state: WidgetManagerStoreDto) => {
	return {
		...state,
		statusDraftWidgetBuilder: NONE,
		statusGetWidgetDetail: NONE,
	};
};

// resume live draft
const resumeLiveDraftRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	resumeLiveDraftStatus: IN_PROGRESS,
});

const resumeLiveDraftSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: widgetDraftRes,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			resumeLiveDraftStatus: SUCCEEDED,
			widgetDraftRes,
			error: '',
		};
	}

	return {
		...state,
		resumeLiveDraftStatus: FAILED,
		widgetDraftRes: null,
		error: status,
	};
};

const resumeLiveDraftFailed = (state: WidgetManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			resumeLiveDraftStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		resumeLiveDraftStatus: FAILED,
	};
};

const resumeLiveDraftEnd = (state: WidgetManagerStoreDto) => {
	return {
		...state,
		widgetDraftRes: null,
		resumeLiveDraftStatus: NONE,
	};
};

// clone live widget
const cloneLiveWidgetRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	cloneLiveWidgetStatus: IN_PROGRESS,
});

const cloneLiveWidgetSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: widgetDraftRes,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			cloneLiveWidgetStatus: SUCCEEDED,
			widgetDraftRes,
			error: '',
		};
	}

	return {
		...state,
		cloneLiveWidgetStatus: FAILED,
		widgetDraftRes: null,
		error: status,
	};
};

const cloneLiveWidgetFailed = (state: WidgetManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			cloneLiveWidgetStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		cloneLiveWidgetStatus: FAILED,
	};
};

const cloneLiveWidgetEnd = (state: WidgetManagerStoreDto) => {
	return {
		...state,
		widgetDraftRes: null,
		cloneLiveWidgetStatus: NONE,
	};
};

const getDetailWidgetBuilderRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	statusGetWidgetDetail: IN_PROGRESS,
});

const getDetailLiveWidgetRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	loadingWidget: true,
});

const getDetailLiveWidgetSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: liveWidget,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingWidget: false,
			liveWidget,
			error: '',
		};
	}

	return {
		...state,
		loadingWidget: false,
		liveWidget: null,
		error: status,
	};
};

const getDetailLiveWidgetFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingWidget: false,
			liveWidget: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingWidget: false,
		liveWidget: null,
	};
};

const uploadFileWidgetBuilderRequest = (state: WidgetManagerStoreDto, { payload }: any) => {
	const { keyStatus = '' } = payload;
	return {
		...state,
		[keyStatus]: IN_PROGRESS,
	};
};

const uploadFileWidgetBuilderSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: urlFile,
	} = payload.data;

	let keyStatus = STATUS_UPLOAD_FILE_WIDGET.logoFile;
	if (state?.statusUploadBgFile === IN_PROGRESS) {
		keyStatus = STATUS_UPLOAD_FILE_WIDGET.bgFile;
	}
	if (state?.statusButtonFile === IN_PROGRESS) {
		keyStatus = STATUS_UPLOAD_FILE_WIDGET.buttonFile;
	}

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			[keyStatus]: SUCCEEDED,
			urlFile,
			error: '',
		};
	}

	return {
		...state,
		[keyStatus]: FAILED,
		urlFile: '',
		error: status,
	};
};

const uploadFileWidgetBuilderFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	let keyStatus = STATUS_UPLOAD_FILE_WIDGET.logoFile;
	if (state?.statusUploadBgFile === IN_PROGRESS) {
		keyStatus = STATUS_UPLOAD_FILE_WIDGET.bgFile;
	}
	if (state?.statusButtonFile === IN_PROGRESS) {
		keyStatus = STATUS_UPLOAD_FILE_WIDGET.buttonFile;
	}

	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			[keyStatus]: FAILED,
			urlFile: '',
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		urlFile: '',
		[keyStatus]: FAILED,
	};
};

const uploadFileWidgetBuilderEnd = (state: WidgetManagerStoreDto) => {
	let keyStatus = STATUS_UPLOAD_FILE_WIDGET.logoFile;
	if (state?.statusUploadBgFile !== NONE) {
		keyStatus = STATUS_UPLOAD_FILE_WIDGET.bgFile;
	}
	if (state?.statusButtonFile !== NONE) {
		keyStatus = STATUS_UPLOAD_FILE_WIDGET.buttonFile;
	}
	return {
		...state,
		urlFile: '',
		[keyStatus]: NONE,
	};
};

// upload font builder
const uploadFontBuilderRequest = (state: WidgetManagerStoreDto) => {
	return {
		...state,
		statusUploadFontBuilder: IN_PROGRESS,
	};
};

const uploadFontBuilderSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: urlFontBuilders = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusUploadFontBuilder: SUCCEEDED,
			urlFontBuilders,
			error: '',
		};
	}

	return {
		...state,
		statusUploadFontBuilder: FAILED,
		urlFontBuilders: [],
		error: status,
	};
};

const uploadFontBuilderFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusUploadFontBuilder: FAILED,
			urlFontBuilders: [],
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		urlFontBuilders: [],
		statusUploadFontBuilder: FAILED,
	};
};

const uploadFontBuilderEnd = (state: WidgetManagerStoreDto) => {
	return {
		...state,
		urlFontBuilders: [],
		statusUploadFontBuilder: NONE,
	};
};

// remove widget builder
const removeWidgetBuilderRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	statusRemoveWidget: IN_PROGRESS,
});

const removeWidgetBuilderSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: urlFile,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusRemoveWidget: SUCCEEDED,
			error: '',
		};
	}

	return {
		...state,
		statusRemoveWidget: FAILED,
		error: status,
	};
};

const removeWidgetBuilderFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusRemoveWidget: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusRemoveWidget: FAILED,
	};
};

const removeWidgetBuilderEnd = (state: WidgetManagerStoreDto) => ({
	...state,
	statusRemoveWidget: NONE,
});

const publishWidgetBuilderRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	statusPublishWidget: IN_PROGRESS,
});

const publishWidgetBuilderSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			statusPublishWidget: SUCCEEDED,
			error: '',
		};
	}

	return {
		...state,
		statusPublishWidget: FAILED,
		error: status,
	};
};

const publishWidgetBuilderFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			statusPublishWidget: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		statusPublishWidget: FAILED,
	};
};

const publishWidgetBuilderEnd = (state: WidgetManagerStoreDto) => ({
	...state,
	statusPublishWidget: NONE,
});

// fetch custom field widet
const getCustomFieldWidgetRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	loadingCustomFieldWidget: true,
});

const getCustomFieldWidgetSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: customFields = { ...DEFAULT_STORE_CUSTOM_FIELD },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingCustomFieldWidget: false,
			customFields,
			error: '',
		};
	}

	return {
		...state,
		customFields,
		loadingCustomFieldWidget: false,
		error: status,
	};
};

const getCustomFieldWidgetFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingCustomFieldWidget: false,
			customFields: DEFAULT_STORE_CUSTOM_FIELD,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingCustomFieldWidget: false,
		customFields: DEFAULT_STORE_CUSTOM_FIELD,
	};
};

// fetch detail widget default
const getDetailWidgetDefaultRequest = (state: WidgetManagerStoreDto) => ({
	...state,
	loadingDetailWidgetDefault: true,
});

const getDetailWidgetDefaultSucceeded = (
	state: WidgetManagerStoreDto,
	{ payload }: Action<WidgetPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: detailWidgetDefault,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingDetailWidgetDefault: false,
			detailWidgetDefault,
			error: '',
		};
	}

	return {
		...state,
		detailWidgetDefault: null,
		loadingDetailWidgetDefault: false,
		error: status,
	};
};

const getDetailWidgetDefaultFailed = (
	state: WidgetManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingDetailWidgetDefault: false,
			detailWidgetDefault: null,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingDetailWidgetDefault: false,
		detailWidgetDefault: null,
	};
};

const changeStoreWidget = (state: WidgetManagerStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		...payload,
	};
};

const contentReducers = handleActions<any>(
	{
		[widgetManagerTypes.FETCH_LIST_WIDGET_REQUEST]: getListWidgetManagerRequest,
		[widgetManagerTypes.FETCH_LIST_WIDGET_SUCCEEDED]: getListWidgetManagerSucceeded,
		[widgetManagerTypes.FETCH_LIST_WIDGET_FAILED]: getListWidgetManagerFailed,

		[createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_REQUEST]:
			createDraftWidgetBuilderRequest,
		[createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_SUCCEEDED]:
			createDraftWidgetBuilderSucceeded,
		[createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_FAILED]:
			createDraftWidgetBuilderFailed,
		[createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_END]:
			createDraftWidgetBuilderEnd,

		[saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_REQUEST]:
			saveDraftWidgetBuilderRequest,
		[saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_SUCCEEDED]:
			saveDraftWidgetBuilderSucceeded,
		[saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_FAILED]:
			saveDraftWidgetBuilderFailed,
		[saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_END]: saveDraftWidgetBuilderEnd,

		[resumeLiveDraftTypes.RESUME_LIVE_DRAFT_REQUEST]: resumeLiveDraftRequest,
		[resumeLiveDraftTypes.RESUME_LIVE_DRAFT_SUCCEEDED]: resumeLiveDraftSucceeded,
		[resumeLiveDraftTypes.RESUME_LIVE_DRAFT_FAILED]: resumeLiveDraftFailed,
		[resumeLiveDraftTypes.RESUME_LIVE_DRAFT_END]: resumeLiveDraftEnd,

		[cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_REQUEST]: cloneLiveWidgetRequest,
		[cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_SUCCEEDED]: cloneLiveWidgetSucceeded,
		[cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_FAILED]: cloneLiveWidgetFailed,
		[cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_END]: cloneLiveWidgetEnd,

		[fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_REQUEST]: getDetailWidgetBuilderRequest,
		[fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_SUCCEEDED]: saveDraftWidgetBuilderSucceeded,
		[fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_FAILED]: saveDraftWidgetBuilderFailed,
		[fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_END]: saveDraftWidgetBuilderEnd,

		[fetchDetailLiveWidgetTypes.FETCH_DETAIL_LIVE_WIDGET_REQUEST]: getDetailLiveWidgetRequest,
		[fetchDetailLiveWidgetTypes.FETCH_DETAIL_LIVE_WIDGET_SUCCEEDED]:
			getDetailLiveWidgetSucceeded,
		[fetchDetailLiveWidgetTypes.FETCH_DETAIL_LIVE_WIDGET_FAILED]: getDetailLiveWidgetFailed,

		[uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_REQUEST]: uploadFileWidgetBuilderRequest,
		[uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_SUCCEEDED]: uploadFileWidgetBuilderSucceeded,
		[uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_FAILED]: uploadFileWidgetBuilderFailed,
		[uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_END]: uploadFileWidgetBuilderEnd,

		[uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_REQUEST]: uploadFontBuilderRequest,
		[uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_SUCCEEDED]: uploadFontBuilderSucceeded,
		[uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_FAILED]: uploadFontBuilderFailed,
		[uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_END]: uploadFontBuilderEnd,

		[removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_REQUEST]: removeWidgetBuilderRequest,
		[removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_SUCCEEDED]: removeWidgetBuilderSucceeded,
		[removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_FAILED]: removeWidgetBuilderFailed,
		[removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_END]: removeWidgetBuilderEnd,

		[publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_REQUEST]: publishWidgetBuilderRequest,
		[publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_SUCCEEDED]: publishWidgetBuilderSucceeded,
		[publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_FAILED]: publishWidgetBuilderFailed,
		[publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_END]: publishWidgetBuilderEnd,

		[fetchCustomFieldWidgetTypes.FETCH_CUSTOM_FIELD_WIDGET_REQUEST]:
			getCustomFieldWidgetRequest,
		[fetchCustomFieldWidgetTypes.FETCH_CUSTOM_FIELD_WIDGET_SUCCEEDED]:
			getCustomFieldWidgetSucceeded,
		[fetchCustomFieldWidgetTypes.FETCH_CUSTOM_FIELD_WIDGET_FAILED]: getCustomFieldWidgetFailed,

		[fetchDetailWidgetDefaultTypes.FETCH_DETAIL_WIDGET_DEFAULT_REQUEST]:
			getDetailWidgetDefaultRequest,
		[fetchDetailWidgetDefaultTypes.FETCH_DETAIL_WIDGET_DEFAULT_SUCCEEDED]:
			getDetailWidgetDefaultSucceeded,
		[fetchDetailWidgetDefaultTypes.FETCH_DETAIL_WIDGET_DEFAULT_FAILED]:
			getDetailWidgetDefaultFailed,

		[updateStoreWidgetTypes.UPDATE_STORE_WIDGET]: changeStoreWidget,
	},
	initialState,
);

export default contentReducers;
