import THEME from '@constants/themes/themes';
import styled from 'styled-components';
import { Dropdown, Layout, Select, Typography } from 'antd';
import { IconKeyboardArrowLeft } from '@assets/icons';

const StyledWrapperHeaderFeeds = styled.div`
	display: flex;
	align-items: center;
`;

const StyledLinkView = styled.div<{ margin?: string }>`
	color: ${THEME.colors.gray1};
	text-decoration: underline;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	cursor: pointer;
	${({ margin }) => margin && `margin: ${margin}`};
	&:hover {
		color: ${THEME.colors.orangeBase};
	}
`;

const StyledHeader = styled(({ collapse, ...props }) => <Layout.Header {...props} />)`
	position: fixed;
	width: 100%;
	padding: ${(props) => (props.collapse ? '16px 90px 16px 20px' : '16px 210px 16px 20px')};
	z-index: 991;
	display: flex;
	align-items: center;
	justify-content: space-between;
	h3 {
		font-size: ${THEME.fontSizes.h3};
	}
`;

const StyledTitleSearch = styled.div`
	display: flex;
	align-items: center;
	height: 34px;
`;

const StyledTitle = styled(Typography.Title)`
	margin: 0 16px 0 0 !important;
	color: ${THEME.colors.gray5} !important;
	display: inline-flex;
	.overlay_album {
		&.ant-dropdown {
			min-width: unset !important;
			width: 250px;
			.ant-dropdown-menu-item {
				width: 100%;
				.ant-dropdown-menu-title-content {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
					display: inline;
				}
			}
		}
		margin: 0;
		.ant-dropdown-menu {
			max-height: 50vh;
			overflow: auto;
			li {
				margin: 0;
				padding: 6px 8px;
				.ant-dropdown-menu-title-content {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					display: block;
				}
			}
		}
	}
`;

const StyledLeftArrow = styled(IconKeyboardArrowLeft)`
	fill: ${THEME.colors.orangeBase} !important;
	cursor: pointer;
`;

const StyledSearch = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledSelect = styled(Select)`
	margin-right: 9px;
	width: 170px;

	.ant-select-selector {
		.ant-select-selection-placeholder {
			color: ${THEME.colors.gray3};
		}

		.ant-select-selection-item,
		.ant-select-selection-placeholder {
			color: ${THEME.colors.gray3};
			font-size: 1rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
		}
	}
`;

const StyledSwitchAccount = styled.div`
	position: absolute;
	align-items: center;
	display: inline-flex;
	visibility: hidden;
	opacity: 0;
	&.show_switch {
		position: static;
		visibility: unset;
		opacity: 1;
		transition: 0.3s ease-out;
	}
`;

const StyledProfile = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledName = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.143rem;
	line-height: 1.25;
	height: 20px;
	color: ${THEME.colors.gray5};
`;

const StyledBrandName = styled.div`
	display: inline-flex;
	align-items: center;
	font-size: 0.857rem;
	opacity: 0.7;
	height: 20px;
	line-height: 1.67;
	color: ${THEME.colors.gray5};
	svg {
		width: 16px;
		height: 16px;
	}
`;

const StyledContainerProfile = styled.div`
	display: inline-flex;
	align-items: center;
`;

const StyledInfoProfile = styled.div`
	text-align: left;
`;

const StyledAccount = styled.div`
	display: flex;
	justify-content: space-between;
	.ant-popover-inner-content {
		padding: 0;
	}
`;

const StyledDropdown = styled(Dropdown)<{ margin?: string }>`
	&.dropdown_album {
		position: relative;
		.overlay_album {
			&.ant-dropdown {
				min-width: unset !important;
				width: 250px;
			}
		}

		margin: ${({ margin }) => margin || '0 0 0 4px'};
		.ant-dropdown-menu {
			max-height: 50vh;
			overflow: auto;
			li {
				margin: 0;
				padding: 6px 8px;
				.ant-dropdown-menu-title-content {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					display: block;
				}
			}
		}
	}
	.ant-dropdown-menu-item {
		&.ant-dropdown-menu-item-disabled {
			&:hover {
				background: unset;
				color: ${THEME.colors.gray5};
			}
		}
	}
`;

const StyledWrapperTitle = styled.div<{ margin?: string; cursor?: string }>`
	display: inline;
	max-width: 50vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	cursor: ${({ cursor }) => cursor || 'pointer'};
	${({ margin }) => margin && `margin: ${margin}`};

	padding-right: 24px;
`;

const StyledNameTitle = styled.span`
	&.has_event {
		cursor: pointer;
		&:hover {
			color: ${THEME.colors.orangeBase};
		}
	}
`;

export {
	StyledHeader,
	StyledTitleSearch,
	StyledTitle,
	StyledLeftArrow,
	StyledSearch,
	StyledSelect,
	StyledSwitchAccount,
	StyledProfile,
	StyledName,
	StyledBrandName,
	StyledContainerProfile,
	StyledInfoProfile,
	StyledAccount,
	StyledDropdown,
	StyledWrapperTitle,
	StyledNameTitle,
	StyledWrapperHeaderFeeds,
	StyledLinkView,
};
