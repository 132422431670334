import { DEFAULT_PAYLOAD_GETTING_LIST } from '@constants/landingPageManager';
import { ParamRequestListBasic } from '@models/common/summary';
import { GuestPortalItemDto } from '@models/settings/guestPortal';
import * as React from 'react';

export type GuestPortal = {
	setGuestPortalDetail: (detail: GuestPortalItemDto) => void;
	setModalType: (type: string) => void;
	selectedGuestPortals: GuestPortalItemDto[];
	setSelectedGuestPortals: (list: GuestPortalItemDto[]) => void;
	handleGuestPortalStatus: (status: string, record?: GuestPortalItemDto) => void;
	setConfirmationModalType: (type: string) => void;
	confirmationModalType: string;
	requestPayload: ParamRequestListBasic;
	setRequestPayload: (payload: ParamRequestListBasic) => void;
	handleCopiedClipboardMessage: (url: string) => void;
};

// Default context values
const values: GuestPortal = {
	setGuestPortalDetail: () => {},
	setModalType: () => {},
	selectedGuestPortals: [],
	setSelectedGuestPortals: () => {},
	handleGuestPortalStatus: () => {},
	setConfirmationModalType: () => {},
	confirmationModalType: '',
	requestPayload: DEFAULT_PAYLOAD_GETTING_LIST,
	setRequestPayload: () => {},
	handleCopiedClipboardMessage: () => {},
};

const GuestPortalContext = React.createContext(values);
export default GuestPortalContext;
