import * as React from 'react';

export type RegistrationAuthenticatorApp = {
	setCurrentSection: (section: string) => void;
	onCancel: () => void;
};

// Default context values
const values: RegistrationAuthenticatorApp = {
	setCurrentSection: () => {},
	onCancel: () => {},
};

const RegistrationAuthenticatorAppContext = React.createContext(values);
export default RegistrationAuthenticatorAppContext;
