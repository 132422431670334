import LoadingWrapper from '@cores/LoadingWrapper';
import { NotificationMessageDto } from '@models/notification/stores';
import { AccountUserDto } from '@models/settings/socialSearch/accountuser.ts/summary';
import { fetchNotificationAccountRequest, getAllNotificationRequest } from '@stores/actions';
import { StyledText } from '@styled/Common/CommonStyled';
import {
	StyledNotificationModal,
	StyledNotificationWrapper,
} from '@styled/Settings/AccountUserStyled';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type NotificationModalProps = {
	account: AccountUserDto;
	onCancel: () => void;
};

const NotificationModal = (props: NotificationModalProps) => {
	const { account, onCancel } = props;
	const { accountNotification, loadingAccountNotify } = useSelector(
		(state: any) => state.notification,
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		if (account) {
			const { id } = account;
			dispatch(fetchNotificationAccountRequest({ accountId: id }));
		}
	}, [account]);

	return (
		<StyledNotificationModal
			closeIcon={() => <></>}
			onCancel={onCancel}
			visible={!!account}
			centered
			footer={false}>
			<LoadingWrapper isLoading={loadingAccountNotify} sizeLoading="default">
				<StyledNotificationWrapper>
					<StyledText fontWeight="bold" margin="0 0 28px 0" fontSize="20px">
						{t('setting.account_user.notification', {
							email: account.email,
						})}
					</StyledText>
					{accountNotification.map((item: NotificationMessageDto) => (
						<StyledText
							margin="0 0 8px 16px"
							fontSize="18px"
							dangerouslySetInnerHTML={{
								__html: `${item.message}`,
							}}
						/>
					))}
				</StyledNotificationWrapper>
			</LoadingWrapper>
		</StyledNotificationModal>
	);
};

export default NotificationModal;
