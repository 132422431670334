export enum NotificationType {
	DIRECT_UPLOADED = 'direct-uploaded',
	SELECT_IMPORTED = 'select-imported',
	CONTENT_ASSIGNED = 'content-assigned',
	CREATOR_ADDED = 'creator-added',
	WIDGET_PUBLISHED = 'widget-published',
	ALBUM_CREATED = 'album-created',
	TOP_RATE = 'top-rated',
}

export const NotifyGroupType = {
	MANAGE_CREATORS: 'MANAGE CREATORS',
	REFRESH_METRICS: 'REFRESH METRICS',
	COLLECT: 'COLLECT',
	PUBLISH: 'PUBLISH',
	ORGANIZE: 'ORGANIZE',
};

export const NOTIFICATION_TAB = {
	MENTIONS: 'mentions',
	OTHERS: 'others',
};

export const NOTIFICATION_TYPE_BY_TIME = {
	TODAY: 'today',
	LAST_7_DAYS: 'last7Days',
	OLDER: 'older',
};

export const DEFAULT_TASK_NOTIFICATION_PAYLOAD = {
	page: 1,
	maxRecords: 100,
	isShowAll: true,
};

export const NOTIFICATION_ELEMENT_SELECTOR = {
	TASK_NOTIFICATION_WRAPPER_ID: 'task_notification_wrapper_id',
};
