import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { TAB_WIDGET } from '@constants/content/widgetManager';
import WidgetManagerContext from '@contexts/Content/WidgetManager';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { WidgetBuilderDetailRequestDto } from '@models/content/widgetManager';
import { cloneLiveWidgetRequest } from '@stores/actions';
import { StyledSelect } from '@styled/Common/CommonStyled';
import {
	StyledActionRight,
	StyledBodyPreviewWidget,
	StyledEmpty,
	StyledHeaderAction,
	StyledIframe,
	StyledMarkIframe,
	StyledWrapperContentIframe,
	StyledWrapperIframe,
} from '@styled/Content/WidgetManager';
import { convertWidgetTerms } from '@utils/funcHelper';
import { renderScreenView } from '@utils/renderComponent';
import { Button, Menu } from 'antd';
import { cloneDeep } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DetailWidget from '../DetailWidget';
import EmbedUploaderModal from '../EmbedUploaderModal';

const { Option } = StyledSelect;

type PropViewDetailWidgetDto = {};

const ViewDetailWidget = (props: PropViewDetailWidgetDto) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [viewEmbedPopup, setViewEmbedPopup] = useState<boolean>(false);
	const optionView = renderScreenView(t);

	const {
		hideDetail = true,
		setHideDetail,
		setIsMobile,
		isMobile,
		widgetSelect,
		setTypeConfirm,
		filter,
		handleCheckMessageRemove,
		handleDuplicate,
	} = useContext(WidgetManagerContext);

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const onChangeView = (val: any) => {
		if (val === optionView[0].value) {
			setIsMobile(false);
		} else {
			setIsMobile(true);
		}
	};

	const handleEditDesign = () => {
		if (widgetSelect?.status === TAB_WIDGET.live && widgetSelect) {
			if (widgetSelect.referenceId) {
				setTypeConfirm(TYPE_ACTIONS.EDIT_DESIGN);
			} else {
				dispatch(cloneLiveWidgetRequest({ widgetId: widgetSelect?.id }));
			}
		} else {
			history.push(`${ROUTE_PATH.WIDGET_MANAGER}/${widgetSelect?.id}`);
		}
	};

	const sizeIframe = useMemo(() => {
		if (widgetSelect) {
			if (widgetSelect?.metadata) {
				const metadataObj = JSON.parse(widgetSelect?.metadata);
				return {
					desktopHeight: +metadataObj?.desktopHeight,
					mobileHeight: +metadataObj?.mobileHeight,
				};
			}
		}
		return null;
	}, [widgetSelect]);

	const actionsObj: ActionsTypes[] = [
		{
			id: 1,
			name: t('button.delete'),
			click: () => handleCheckMessageRemove(),
			disabled: widgetSelect?.status === TAB_WIDGET.custom || widgetSelect?.isDefault,
		},
		{
			id: 2,
			name: t('button.duplicate'),
			click: () => handleDuplicate(),
			disabled: widgetSelect?.status === TAB_WIDGET.custom,
		},
	];

	const menuActions = (
		<Menu>
			{actionsObj.map((item: ActionsTypes) => (
				<Menu.Item key={item.id} disabled={!!item.disabled} onClick={item.click}>
					{item.name}
				</Menu.Item>
			))}
		</Menu>
	);

	const widgetPreview = useMemo(() => {
		if (widgetSelect) {
			const detailClone: WidgetBuilderDetailRequestDto = cloneDeep(widgetSelect);
			const { metadata } = detailClone;

			return convertWidgetTerms({ ...detailClone, metadata: JSON.parse(metadata) });
		}
		return null;
	}, [widgetSelect]);

	return (
		<>
			{widgetSelect && (
				<EmbedUploaderModal
					visible={viewEmbedPopup}
					onCancel={() => setViewEmbedPopup(false)}
					widgetSelect={widgetSelect}
				/>
			)}
			<StyledHeaderAction>
				<StyledSelect
					notFoundContent={notFoundContent()}
					defaultValue={optionView[0].value}
					onChange={onChangeView}
					width="170px"
					placeholder={t('content.widget_manager.placeholder')}>
					{optionView.map((item: any) => {
						return (
							<Option key={item?.value} value={item.value}>
								{item?.label}
							</Option>
						);
					})}
				</StyledSelect>
				<StyledActionRight>
					<Button
						disabled={!widgetSelect || filter?.status === TAB_WIDGET.custom}
						onClick={handleEditDesign}>
						{t('content.widget_manager.button.edit_design')}
					</Button>
					{filter?.status === TAB_WIDGET.draft && (
						<Button
							disabled={!widgetSelect}
							onClick={() => setHideDetail(!hideDetail)}
							type="primary">
							{t(
								`content.widget_manager.button.${
									hideDetail ? 'view_detail' : 'hide_detail'
								}`,
							)}
						</Button>
					)}
					{filter?.status !== TAB_WIDGET.draft && (
						<Button
							disabled={!widgetSelect}
							onClick={() => setViewEmbedPopup(true)}
							type="primary">
							{t('button.share_embed')}
						</Button>
					)}
				</StyledActionRight>
			</StyledHeaderAction>
			<StyledBodyPreviewWidget
				className="custom_scroll_bar"
				overflow={filter?.status === TAB_WIDGET.custom ? 'unset' : 'auto'}>
				{filter?.status === TAB_WIDGET.custom && widgetSelect?.url && (
					<StyledWrapperIframe>
						<StyledWrapperContentIframe isMobile={isMobile}>
							<StyledIframe
								width={'100%'}
								isMobile={isMobile}
								src={widgetSelect?.url}
								height={
									isMobile ? sizeIframe?.mobileHeight : sizeIframe?.desktopHeight
								}
							/>
							<StyledMarkIframe
								height={
									isMobile ? sizeIframe?.mobileHeight : sizeIframe?.desktopHeight
								}
								isMobile={isMobile}
							/>
						</StyledWrapperContentIframe>
					</StyledWrapperIframe>
				)}
				{filter?.status !== TAB_WIDGET.custom && (
					<>
						{widgetPreview ? (
							<DetailWidget detailWidget={widgetPreview} isMobile={isMobile} />
						) : (
							<StyledEmpty />
						)}
					</>
				)}
			</StyledBodyPreviewWidget>
		</>
	);
};

export default ViewDetailWidget;
