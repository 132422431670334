import { IconCheckCircle, IconInfo } from '@assets/icons';
import { ANALYZED_CONTENT_OPTIONS, RECOGNITION_PROCESSES } from '@constants/content/contentLibrary';
import { IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import MediaRecognitionModalContext from '@contexts/Content/MediaRecognitionModal';
import {
	ContentStoreDto,
	DetectionOrderPayloadDto,
	EstimatedCostPayloadDto,
} from '@models/content/contentLibrary/store';
import {
	ContentContainerDto,
	DetectionServiceDto,
	EstimatedCostDto,
	EstimatedServiceCostDto,
} from '@models/content/contentLibrary/summary';
import {
	estimateCostEnd,
	estimateCostRequest,
	getDetectionServiceListRequest,
	submitDetectionOrderEnd,
	submitDetectionOrderRequest,
} from '@stores/actions';
import {
	StyledButton,
	StyledFlex,
	StyledIcon,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledMediaRecognitionModal,
	StyledWarning,
} from '@styled/Content/ContentLibrary/MediaRecognitionModalStyled';
import { getContentByType } from '@utils/common';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ChooseServices from './ChooseServices';
import Summary from './Summary';

type MediaRecognitionModalProps = {
	visible: boolean;
	onCancel: () => void;
	selectedContentList: ContentContainerDto[];
	isAllContents: boolean;
};

const MediaRecognitionModal = (props: MediaRecognitionModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { visible, onCancel, selectedContentList, isAllContents } = props;
	const { albumDetail } = useSelector((state: any) => state.albumManager);
	const {
		submitDetectionOrderStatus,
		estimatedCostDetail,
		detectionServiceList,
	}: ContentStoreDto = useSelector((state: any) => state.content);

	const detectionServiceDetailDefault = {
		totalContents: 0,
		contentIds: [],
		detectionServiceEstimateCost: [],
		numberOfImages: 0,
		numberOfVideos: 0,
	};

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [currentProcess, setCurrentProcess] = useState<string>(
		RECOGNITION_PROCESSES.CHOOSE_SERVICE,
	);
	const [analyzedOption, setAnalyzedOption] = useState<string>(
		ANALYZED_CONTENT_OPTIONS.SKIP_CONTENT_ANALYZED,
	);
	const [detectionServiceDetail, setDetectionServiceDetail] = useState<EstimatedCostDto>(
		detectionServiceDetailDefault,
	);

	useEffect(() => {
		dispatch(submitDetectionOrderEnd()); // This func contains values used to update the content list and display the successful message so it is called here
		dispatch(getDetectionServiceListRequest());
		// Call 2 functions to support changing analyzing options without calling API again
		getEstimatedCost(false);
		getEstimatedCost(true);

		return () => {
			dispatch(estimateCostEnd());
		};
	}, []);

	useEffect(() => {
		if (estimatedCostDetail) {
			setDetectionServiceDetail({ ...estimatedCostDetail[analyzedOption] });
		}
	}, [estimatedCostDetail, analyzedOption]);

	const getEstimatedCost = (skipDetected: boolean) => {
		let payload: EstimatedCostPayloadDto = {
			skipDetected,
		};

		const contentIds: number[] = [];
		selectedContentList.forEach((content: ContentContainerDto) => {
			const contentTemp = getContentByType(content);
			contentIds.push(contentTemp.id);
		});

		payload = {
			...payload,
			folderId: isAllContents ? albumDetail?.id : null,
			contentIds: isAllContents ? [] : contentIds,
		};

		dispatch(estimateCostRequest(payload));
	};

	const selectedServiceList = useMemo(() => {
		return detectionServiceList.filter((service: DetectionServiceDto) =>
			selectedRowKeys.includes(service.id),
		);
	}, [selectedRowKeys, detectionServiceList]);

	const estimatedCost = useMemo(() => {
		let total = 0;
		const { detectionServiceEstimateCost = [] } = detectionServiceDetail;
		selectedRowKeys.forEach((id: React.Key) => {
			const selectedService = detectionServiceEstimateCost.find(
				(service: EstimatedServiceCostDto) => service.id === id,
			);
			if (selectedService) {
				total += selectedService.estimateCost;
			}
		});
		return total.toFixed(3);
	}, [selectedRowKeys, detectionServiceDetail]);

	const handleSubmitDetectionOrder = () => {
		if (selectedRowKeys && detectionServiceDetail) {
			const { detectionServiceEstimateCost = [] } = detectionServiceDetail;
			const payload: DetectionOrderPayloadDto[] = [];
			selectedRowKeys.forEach((id: React.Key) => {
				const selectedService = detectionServiceEstimateCost.find(
					(service: EstimatedServiceCostDto) => service.id === id,
				);
				if (selectedService) {
					payload.push({
						contentIds: selectedService.contentIds,
						detectionServiceId: +id,
					});
				}
			});

			dispatch(submitDetectionOrderRequest(payload));
		}
	};

	const disableReviewOrder = useMemo(() => {
		const { detectionServiceEstimateCost = [] } = detectionServiceDetail || {};

		return (
			selectedRowKeys.length === 0 ||
			selectedRowKeys.every((item: React.Key) => {
				const service = detectionServiceEstimateCost.find(
					(serviceItem: EstimatedServiceCostDto) => serviceItem.id === +item,
				);
				return service && service.contentIds.length === 0;
			})
		);
	}, [selectedRowKeys, detectionServiceDetail]);

	const renderElement = () => {
		switch (true) {
			case currentProcess === RECOGNITION_PROCESSES.CHOOSE_SERVICE:
				return {
					content: <ChooseServices />,
					buttonGroup: (
						<StyledFlex margin="8px 0 0 0">
							<StyledButton
								isDisabled={disableReviewOrder}
								width="calc(50% - 8px)"
								onClick={() =>
									setCurrentProcess(RECOGNITION_PROCESSES.ANALYZE_CONTENT)
								}>
								{t('button.review_order')}
							</StyledButton>
							<StyledButton width="calc(50% - 8px)" type="primary" onClick={onCancel}>
								{t('button.cancel')}
							</StyledButton>
						</StyledFlex>
					),
				};

			default:
				return {
					content: <Summary />,
					buttonGroup: (
						<StyledFlex margin="8px 0 0 0">
							<StyledButton
								loading={submitDetectionOrderStatus === IN_PROGRESS}
								width="calc(50% - 8px)"
								onClick={handleSubmitDetectionOrder}>
								{t('button.submit_order')}
							</StyledButton>
							<StyledButton
								width="calc(50% - 8px)"
								type="primary"
								onClick={() => {
									setCurrentProcess(RECOGNITION_PROCESSES.CHOOSE_SERVICE);
								}}>
								{t('button.go_back')}
							</StyledButton>
						</StyledFlex>
					),
				};
		}
	};

	const renderWarning = () => {
		return submitDetectionOrderStatus !== SUCCEEDED ? (
			<StyledWarning>
				<StyledFlex justify="center" align="center" margin="0 0 16px 0">
					<StyledIcon fill={THEME.colors.yellowBase}>
						<IconInfo />
					</StyledIcon>
					<StyledText margin="0 8px" fontWeight={700} fontSize="20px">
						{t('content_management.media_recognition.wait')}
					</StyledText>
					<StyledIcon fill={THEME.colors.yellowBase}>
						<IconInfo />
					</StyledIcon>
				</StyledFlex>
				<StyledText>{t('content_management.media_recognition.wait_desc')}</StyledText>
			</StyledWarning>
		) : (
			<StyledWarning bgcolor="rgba(52, 168, 83, 0.1)">
				<StyledFlex justify="flex-start" align="center" margin="0 0 16px 0">
					<StyledIcon fill={THEME.colors.greenBase}>
						<IconCheckCircle />
					</StyledIcon>
					<StyledText margin="0 8px" fontWeight={700} fontSize="20px">
						{t('content_management.media_recognition.submitted_order')}
					</StyledText>
				</StyledFlex>
				<StyledText>
					{t('content_management.media_recognition.submitted_order_desc')}
				</StyledText>
			</StyledWarning>
		);
	};

	const contextValues = {
		setCurrentProcess,
		selectedRowKeys,
		setSelectedRowKeys,
		estimatedCost,
		setAnalyzedOption,
		analyzedOption,
		selectedServiceList,
		detectionServiceDetail,
	};

	return (
		<MediaRecognitionModalContext.Provider value={contextValues}>
			<StyledMediaRecognitionModal
				visible={visible}
				onCancel={onCancel}
				title={
					<StyledText fontSize="20px">
						{t('content_management.media_recognition.choose_services_title')}
					</StyledText>
				}
				footer={false}
				centered>
				<StyledWrapperContent
					width="100%"
					maxHeight="calc(75vh - 94px)"
					className="custom_scroll_bar"
					overflow="auto"
					padding="16px 24px">
					{renderWarning()}
					{renderElement().content}
				</StyledWrapperContent>
				<StyledWrapperContent padding="8px 24px 24px 24px">
					{currentProcess === RECOGNITION_PROCESSES.CHOOSE_SERVICE && (
						<StyledText
							fontSize="16px"
							dangerouslySetInnerHTML={{
								__html: t(
									'content_management.media_recognition.estimated_total_cost',
									{
										cost: estimatedCost,
									},
								),
							}}
						/>
					)}
					{submitDetectionOrderStatus !== SUCCEEDED ? (
						renderElement().buttonGroup
					) : (
						<StyledFlex justify="flex-end" margin="0">
							<StyledButton width="calc(50% - 8px)" onClick={onCancel} type="primary">
								{t('button.close')}
							</StyledButton>
						</StyledFlex>
					)}
				</StyledWrapperContent>
			</StyledMediaRecognitionModal>
		</MediaRecognitionModalContext.Provider>
	);
};

export default MediaRecognitionModal;
