import { ColumnGridSVG, ColumnSVG } from '@assets/icons/svg/grapesjs';

export const ColumnGridBlock = (bm: any, label: any) => {
	bm.add('columnGrid', {
		label: 'Columns',
		media: ColumnGridSVG,
		content:
			'<div class="container"><div class="row"><div class="col-md-6 col-xs-12"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntut labore et dolore magna aliqua</p></div><div class="col-md-6 col-xs-12"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p></div></div></div>',
	});
};

export const ColumnBlock = (bm: any, label: any) => {
	bm.add('column').set({
		label: 'Column',
		media: ColumnSVG,
		content: {
			type: 'column',
			classes: ['col'],
		},
	});
};

export default (domc: any, editor: any) => {
	const defaultType = domc.getType('default');
	const defaultModel = defaultType.model;
	const defaultView = defaultType.view;
	const spans = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

	domc.addType('column', {
		isComponent(el: any) {
			let match = false;
			if (el && el.classList) {
				el.classList.forEach((klass: any) => {
					if (klass == 'col' || klass.match(/^col-/)) {
						match = true;
					}
				});
			}
			if (match) return { type: 'column' };
			return false;
		},
		model: {
			defaults: {
				'custom-name': 'Column',
				resizable: {
					updateTarget: (el: any, rect: any, opt: any) => {
						const selected = editor.getSelected();
						if (!selected) {
							return;
						}

						// compute the current screen size (bootstrap semantic)
						const docWidth = el.getRootNode().body.offsetWidth;
						let currentSize = '';
						if (docWidth >= 1200) {
							currentSize = 'xl';
						} else if (docWidth >= 992) {
							currentSize = 'lg';
						} else if (docWidth >= 768) {
							currentSize = 'md';
						} else if (docWidth >= 576) {
							currentSize = 'sm';
						}

						// compute the threshold when add on remove 1 col span to the element
						const row = el.parentElement;
						const oneColWidth = row.offsetWidth / 12;
						// the threshold is half one column width
						const threshold = oneColWidth * 0.5;

						// check if we are growing or shrinking the column
						const grow = rect.w > el.offsetWidth + threshold;
						const shrink = rect.w < el.offsetWidth - threshold;
						if (grow || shrink) {
							let testRegexp = new RegExp('^col-' + currentSize + '-\\d{1,2}$');
							if (!currentSize) {
								testRegexp = new RegExp('^col-\\d{1,2}$');
							}
							let found = false;
							const sizesSpans: any = {};
							let oldSpan = 0;
							let oldClass = null;
							for (const cl of el.classList) {
								if (cl.indexOf('col-') === 0) {
									/* eslint-disable */

									let [_, size, span] = cl.split('-');
									if (!span) {
										span = size;
										size = '';
									}
									sizesSpans[size] = span;
									if (size === currentSize) {
										// found the col-XX-99 class
										oldClass = cl;
										oldSpan = span;
										found = true;
									}
									/* eslint-enable */
								}
							}

							if (!found) {
								const sizeOrder = ['', 'xs', 'sm', 'md', 'lg', 'xl'];
								for (const s of sizeOrder) {
									if (sizesSpans[s]) {
										oldSpan = sizesSpans[s];
										found = true;
									}
									if (s === currentSize) {
										break;
									}
								}
							}

							let newSpan = Number(oldSpan);
							if (grow) {
								newSpan += 1;
							} else {
								newSpan -= 1;
							}
							if (newSpan > 12) {
								newSpan = 12;
							}
							if (newSpan < 1) {
								newSpan = 1;
							}

							let newClass = 'col-' + currentSize + '-' + newSpan;
							if (!currentSize) {
								newClass = 'col-' + newSpan;
							}
							// update the class
							selected.addClass(newClass);
							if (oldClass && oldClass !== newClass) {
								selected.removeClass(oldClass);
							}
							// notify the corresponding trait to update its value accordingly
							// selected.getTrait((currentSize || 'xs') + '_width').view.postUpdate();
						}
					},
					tl: 0,
					tc: 0,
					tr: 0,
					cl: 0,
					cr: 1,
					bl: 0,
					bc: 0,
					br: 0,
				},
				traits: [
					{
						id: 'xs_width',
						type: 'class_select',
						options: [
							{ value: 'col', name: 'Equal' },
							{ value: 'col-auto', name: 'Variable' },
							...spans.map((i) => {
								return { value: 'col-' + i, name: i + '/12' };
							}),
						],
						label: '< 576px',
					},
					{
						id: 'sm_width',
						type: 'class_select',
						options: [
							{ value: '', name: 'None' },
							{ value: 'col-sm', name: 'Equal' },
							{ value: 'col-sm-auto', name: 'Variable' },
							...spans.map((i) => {
								return { value: 'col-sm-' + i, name: i + '/12' };
							}),
						],
						label: '≥ 576px',
					},
					{
						id: 'md_width',
						type: 'class_select',
						options: [
							{ value: '', name: 'None' },
							{ value: 'col-md', name: 'Equal' },
							{ value: 'col-md-auto', name: 'Variable' },
							...spans.map((i) => {
								return { value: 'col-md-' + i, name: i + '/12' };
							}),
						],
						label: '≥ 768px',
					},
					{
						id: 'lg_width',
						type: 'class_select',
						options: [
							{ value: '', name: 'None' },
							{ value: 'col-lg', name: 'Equal' },
							{ value: 'col-lg-auto', name: 'Variable' },
							...spans.map((i) => {
								return { value: 'col-lg-' + i, name: i + '/12' };
							}),
						],
						label: '≥ 992px',
					},
					{
						id: 'xl_width',
						type: 'class_select',
						options: [
							{ value: '', name: 'None' },
							{ value: 'col-xl', name: 'Equal' },
							{ value: 'col-xl-auto', name: 'Variable' },
							...spans.map((i) => {
								return { value: 'col-xl-' + i, name: i + '/12' };
							}),
						],
						label: '≥ 1200px',
					},
				].concat(defaultModel.prototype.defaults.traits),
			},
		},
		view: defaultView,
	});
};
