import { IconKeyboardArrowRight } from '@assets/icons';
import { MAX_LENGTH_CHARACTER } from '@constants/content/contentLibrary';
import {
	DEFAULT_ADVANCE_SETTING_WIDGET,
	KEY_ADVANCE_SETTING,
} from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import {
	StyledIcon,
	StyledRadioButton,
	StyledRadioGroup,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledTextArea } from '@styled/Content/WidgetManager';
import {
	StyledCustomFieldItem,
	StyledFormItem,
	StyledInputCustomField,
	StyledLabelItem,
	StyledWrapperSetting,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { Space } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type EditConfirmationScreenProps = {};

const EditConfirmationScreen = (props: EditConfirmationScreenProps) => {
	const { t } = useTranslation();
	const { detailWidget } = useContext(WidgetBuilderContext);

	const { id, url, metadata, status, draftId = -1, ...otherDetail } = detailWidget;
	const { otherInfo, body } = metadata || {};
	const { advancedSettings } = body || {};
	const {
		confirmationMessage,
		confirmationButtonText,
		socialMessage,
		visibleSocialButtons = false,
	} = advancedSettings || {};

	const [isEditSetting, setIsEditSetting] = useState<boolean>(false);

	const renderInput = (value: string) => {
		return (
			<StyledInputCustomField
				maxLength={MAX_LENGTH_CHARACTER}
				value={value}
				placeholder={t('content.widget_manager.widget_builder.enter_field_name')}
			/>
		);
	};

	const renderTextArea = (row: number, value: string) => {
		return (
			<StyledTextArea
				value={value}
				placeholder={t('content.widget_manager.widget_builder.enter_field_name')}
				autoSize={{ minRows: row || 3, maxRows: row || 3 }}
				maxLength={otherInfo?.maxLengthParagraph || 8000}
				rows={row || 3}
				bgColor={THEME.colors.darkBlue3}
			/>
		);
	};

	const listSetting = [
		{
			id: 1,
			title: t('content.widget_manager.widget_builder.confirmation_header'),
			value: confirmationMessage || DEFAULT_ADVANCE_SETTING_WIDGET.CONFIRMATION_MESSAGE,
			name: KEY_ADVANCE_SETTING.CONFIRMATION_MESSAGE,
		},
		{
			id: 2,
			title: t('content.widget_manager.widget_builder.confirmation_button_text'),
			value:
				confirmationButtonText || DEFAULT_ADVANCE_SETTING_WIDGET.CONFIRMATION_BUTTON_TEXT,
			name: KEY_ADVANCE_SETTING.CONFIRMATION_BUTTON_TEXT,
		},
		{
			id: 3,
			title: t('content.widget_manager.widget_builder.share_to_social_button'),
			value: visibleSocialButtons || false,
			name: KEY_ADVANCE_SETTING.VISIBLE_SOCIAL_BUTTONS,
			message: {
				title: t('content.widget_manager.widget_builder.social_message'),
				value: socialMessage || DEFAULT_ADVANCE_SETTING_WIDGET.SOCIAL_MESSAGE,
				name: KEY_ADVANCE_SETTING.SOCIAL_MESSAGE,
			},
			optionList: [
				{
					id: 1,
					value: true,
					title: t('content.widget_manager.widget_builder.show'),
				},
				{
					id: 1,
					value: false,
					title: t('content.widget_manager.widget_builder.hide'),
				},
			],
		},
	];

	return (
		<>
			<StyledWrapperSetting>
				<StyledWrapperTitle
					style={{ cursor: 'pointer', margin: '0 0 15px 0' }}
					onClick={() => setIsEditSetting(!isEditSetting)}>
					<StyledLabelItem style={{ cursor: 'pointer' }}>
						{t('content.widget_manager.widget_builder.edit_confirmation_page')}
					</StyledLabelItem>
					<StyledIcon style={{ transform: `rotate(${isEditSetting ? 90 : 0}deg)` }}>
						<IconKeyboardArrowRight />
					</StyledIcon>
				</StyledWrapperTitle>
				{listSetting?.map((item) => {
					if (item.name === KEY_ADVANCE_SETTING.VISIBLE_SOCIAL_BUTTONS) {
						const { message } = item || {};

						return (
							<StyledCustomFieldItem padding={isEditSetting ? '15px' : '0'}>
								<StyledFormItem
									className={isEditSetting ? '' : 'hidden_form'}
									name={item?.name}
									key={item?.id}
									margin={'0'}
									label={item?.title}
									rules={[
										{
											required: true,
											message: t(
												'content.widget_manager.widget_builder.required',
											),
										},
									]}>
									<StyledRadioGroup>
										<Space direction="horizontal">
											{item?.optionList?.map((option) => {
												return (
													<StyledRadioButton
														bgInner={THEME.colors.darkBlue3}
														key={option?.id}
														value={option?.value}>
														{option.title}
													</StyledRadioButton>
												);
											})}
										</Space>
									</StyledRadioGroup>
								</StyledFormItem>

								<StyledFormItem
									className={isEditSetting ? '' : 'hidden_form'}
									name={message?.name}
									key={message?.name}
									margin="15px 0 0 0"
									label={message?.title}
									rules={[
										{
											required: true,
											message: t(
												'content.widget_manager.widget_builder.required',
											),
										},
									]}>
									{renderTextArea(1, message?.value)}
								</StyledFormItem>
							</StyledCustomFieldItem>
						);
					}

					return (
						<StyledCustomFieldItem
							margin="0 0 15px 0"
							padding={isEditSetting ? '15px' : '0'}>
							<StyledFormItem
								label={item?.title}
								name={item?.name}
								key={item?.id}
								className={isEditSetting ? '' : 'hidden_form'}
								margin={'0'}
								rules={[
									{
										required: true,
										message: t(
											'content.widget_manager.widget_builder.required',
										),
									},
								]}>
								{renderInput(item?.value)}
							</StyledFormItem>
						</StyledCustomFieldItem>
					);
				})}
			</StyledWrapperSetting>
		</>
	);
};

export default EditConfirmationScreen;
