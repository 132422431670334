import { Collapse, ConfigProvider, Tabs, Tooltip } from 'antd';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Icon
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';

// Redux
import { setInventoryState, updateRewardSelect } from '@stores/rewards/rewards.actions';

// Styled
import { StyledCollapse, StyledEmpty, StyledTabs } from '@styled/Rewards/StyledTable';
// Constant
import { StyledTable } from '@components/CustomTable';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { ACTIVE, ARCHIVED, COLLAPSE_KEY, DISCOUNT, DRAFT, FIXED } from '@constants/reward';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { renderCopiedText } from '@utils/renderComponent';

type DiscountProps = {
	handleChangeTab: (key: string, type: string) => void;
	handleScroll: (page: number, status: string, type: string) => void;
	handleEdit: (rowSelect?: any) => void;
	data: any;
};

type TabsDto = {
	key: string;
	title: string;
	data: any[];
};

const { TabPane } = Tabs;

const { Panel } = Collapse;

const StyledWrapper = styled.div`
	margin-top: 16px;
`;

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

const Discount = ({ data, handleChangeTab, handleScroll, handleEdit }: DiscountProps) => {
	const { t } = useTranslation();
	const refReward = useRef<any>([]);

	const dispatch = useDispatch();
	const rewards = useSelector((state: any) => state.rewards);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [activeTab, setActiveTab] = useState<string>(ACTIVE);
	const [activePage, setActivePage] = useState<number>(1);
	const [draftPage, setDraftPage] = useState<number>(1);
	const [archivedPage, setArchivedPage] = useState<number>(1);
	const [collapseActive, setCollapseActive] = useState<string[]>([]);

	const { activeDiscountList, draftDiscountList, archivedDiscountList } = data;
	const {
		resetStatus: { resetDiscountList },
		listTypeEmptyReward = [],
		loadingReward,
	} = rewards;

	const listKeyData = {
		active: 'activeDiscountList',
		draft: 'draftDiscountList',
		archived: 'archivedDiscountList',
	};

	const tabs: TabsDto[] = [
		{
			key: ACTIVE,
			title: t('rewards.tabs.active'),
			data: activeDiscountList,
		},
		{
			key: DRAFT,
			title: t('rewards.tabs.draft'),
			data: draftDiscountList,
		},
		{
			key: ARCHIVED,
			title: t('rewards.tabs.archived'),
			data: archivedDiscountList,
		},
	];

	const columns: any[] = [
		{
			title: t('rewards.title'),
			dataIndex: 'title',
			show: true,
			width: 250,
			render: (title: string) => renderCopiedText(title),
		},
		{
			title: t('rewards.estimated_cash_value'),
			dataIndex: 'estimatedCashValue',
			show: true,
			width: 200,
			align: 'right',
			render: (estimatedCashValue: number) =>
				estimatedCashValue && renderCopiedText(`$${estimatedCashValue}`),
		},
		{
			title: t('rewards.remaining_offers'),
			dataIndex: 'remainingOffers',
			show: true,
			width: 200,
			align: 'right',
			render: (val: number) => renderCopiedText(val),
		},
		{
			title: t('rewards.discount_amount'),
			dataIndex: 'discountAmount',
			show: false,
			align: 'right',
			width: 150,
			render: (discountAmount: number, record: any) =>
				discountAmount &&
				renderCopiedText(
					`${
						record.discountType === FIXED.toLowerCase() || record.discountType === FIXED
							? `$${discountAmount} off`
							: `${discountAmount}% off`
					}`,
				),
		},
		{
			title: t('rewards.offer_end_date'),
			dataIndex: 'dateExpires',
			show: false,
			render: (dateExpires: string) =>
				dateExpires &&
				renderCopiedText(
					convertUtcToLocalTimeWithFormat(
						new Date(dateExpires).getTime(),
						TYPE_FORMAT_DATE.TIME,
					),
				),
		},
	];

	// Reset rewards list
	useEffect(() => {
		if (resetDiscountList) {
			rewards.resetStatus.resetDiscountList = false;
			dispatch(setInventoryState(rewards));
			setActivePage(1);
			setDraftPage(1);
			setArchivedPage(1);
			resetScrollOnTop();
			setInventoryStoreState([]);
		}
	}, [resetDiscountList]);

	// Handle infinite scroll
	useEffect(() => {
		const node = document.querySelector<HTMLElement>(
			`#tab_discounts_${activeTab} .ant-table-body`,
		);

		if (node) {
			const handleScrollList = () => {
				if (node.scrollHeight <= node.scrollTop + node.clientHeight && !loadingReward) {
					onScrollBottomRewardList();
					node.scrollTop = node.scrollHeight;
				}
			};
			node.addEventListener('scroll', handleScrollList);
			return () => node.removeEventListener('scroll', handleScrollList);
		}
		return () => {};
	}, [
		activeTab,
		listKeyData,
		listTypeEmptyReward,
		activePage,
		draftPage,
		archivedPage,
		loadingReward,
		collapseActive,
	]);

	const resetScrollOnTop = () => {
		const node = document.querySelector<HTMLElement>(
			`#tab_discounts_${activeTab} .ant-table-body`,
		);
		if (node) {
			node.scrollTop = 0;
		}
	};

	const onCheckEmpty = (key: string) =>
		listTypeEmptyReward.some((typeReward: string) => typeReward === key);

	const onScrollBottomRewardList = () => {
		if (activeTab === ACTIVE && !onCheckEmpty(listKeyData.active)) {
			handleScroll(activePage + 1, ACTIVE, DISCOUNT);
			setActivePage(activePage + 1);
		}
		if (activeTab === DRAFT && !onCheckEmpty(listKeyData.draft)) {
			handleScroll(draftPage + 1, DRAFT, DISCOUNT);
			setDraftPage(draftPage + 1);
		}
		if (activeTab === ARCHIVED && !onCheckEmpty(listKeyData.archived)) {
			handleScroll(archivedPage + 1, ARCHIVED, DISCOUNT);
			setArchivedPage(archivedPage + 1);
		}
	};

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setInventoryStoreState(selectedRowKeys);
		setSelectedRowKeys(selectedRowKeys);
	};

	const onSelectAllChange = () => {};

	const handleCurrentTab = (key: string) => {
		// Clean up before changing tab

		rewards.rewardsList.discountList = [];
		dispatch(setInventoryState(rewards));
		resetScrollOnTop();
		setActivePage(1);
		setDraftPage(1);
		setArchivedPage(1);
		setActiveTab(key);
		setSelectedRowKeys([]);

		// Remove non-selected tab's data
		setInventoryStoreState([]);
		handleChangeTab(key, DISCOUNT);
	};

	const setInventoryStoreState = (data: any[]) => {
		dispatch(updateRewardSelect({ selectedDiscountRewards: data }));
	};

	const renderSegmentName = (name: string) => {
		return (
			<Tooltip placement="topLeft" title={name}>
				{name}
			</Tooltip>
		);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		onSelectAll: onSelectAllChange,
	};

	return (
		<StyledWrapper style={{ margin: '0' }}>
			<ConfigProvider renderEmpty={customizeRenderEmpty}>
				<StyledCollapse
					onChange={(key: any) => {
						setCollapseActive(key);
					}}
					className="hidden_content"
					activeKey={collapseActive}
					bordered={false}
					expandIconPosition="right">
					<Panel header={t('rewards.section.discounts')} key={COLLAPSE_KEY.DISCOUNTS} />
				</StyledCollapse>
				<StyledTabs
					style={
						collapseActive.includes(COLLAPSE_KEY.DISCOUNTS)
							? { display: 'block' }
							: { display: 'none' }
					}
					defaultActiveKey={ACTIVE}
					onChange={handleCurrentTab}>
					{tabs.map((tab, idx: number) => (
						<TabPane tab={tab.title} key={tab.key} closable={false}>
							<StyledTable
								id={`tab_discounts_${tab.key}`}
								className="table_row_action"
								dataSource={tab.data}
								columns={columns}
								pagination={false}
								rowSelection={rowSelection}
								scroll={{ scrollToFirstRowOnChange: false, y: 300 }}
								onRow={(record: any, rowIndex: any) => ({
									onClick: () => handleEdit(record),
								})}
							/>
						</TabPane>
					))}
				</StyledTabs>
			</ConfigProvider>
		</StyledWrapper>
	);
};

export default React.memo(Discount);
