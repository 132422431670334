import { STATUS_MODES } from '@constants/common';

export const GUEST_PORTAL_FIELD_NAME = {
	PORTAL_NAME: 'name',
	DESCRIPTION: 'description',
	CREATORS_TAB: 'creatorsTab',
	URL: 'url',
	MEDIA_HUB_TAB: 'mediaHubTab',
	METADATA: 'metadata',
	TAB_CONFIGURATION: 'tabConfiguration',
	EMAIL: 'emails',
	NOTE: 'note',
	IS_ALL_ALBUMS: 'isAllAlbums',
	SELECTED_ALBUMS: 'selectedAlbums',
};

export const MEDIA_HUB_OPTIONS = {
	ALL_ALBUMS: 'ALL_ALBUMS',
	SELECTED_ALBUMS: 'SELECTED_ALBUMS',
};

export const DEFAULT_GUEST_PORTAL = {
	name: '',
	description: '',
	url: '',
	metadata: {
		tabConfiguration: {
			creatorsTab: {
				status: true,
			},
		},
	},
	status: STATUS_MODES.ACTIVE.toUpperCase(),
};

export const GUEST_PORTAL_MODAL_TYPE = {
	SHARE: 'SHARE',
	ACTION_ON_GUEST: 'ACTION_ON_GUEST',
	SWITCH_STATUS: 'SWITCH_STATUS',
	DELETE: 'DELETE',
	CHANGING_URL: 'CHANGING_URL',
};

export const DEFAULT_PAYLOAD_GETTING_LIST = {
	page: 1,
	maxRecords: 200,
};
