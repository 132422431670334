import { TEMPLATE_EDITOR_CONFIG } from '@constants/settings/contentLicensing';
import EditorCustom from '@cores/EditorCustom';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import React, { useEffect } from 'react';

type PropTypes = {
	disabledEdit: boolean;
	errorBody: boolean;
	documentChange: string;
	refUpdate: any;
	onEdit: (val: string, noChange?: boolean) => void;
};

const EditorDocumentBody = (props: PropTypes) => {
	const { disabledEdit, errorBody, documentChange = '', refUpdate, onEdit } = props;

	return (
		<StyledWrapperContent
			style={disabledEdit ? { pointerEvents: 'auto' } : {}}
			width="100%"
			height="300px"
			className={`form_editor ${errorBody ? 'error_editor' : ''}`}>
			<EditorCustom
				disabled={disabledEdit}
				id={'editor_template'}
				editorContent={documentChange || ''}
				setEditorContent={(val: string) => {
					onEdit(val, refUpdate.current);

					refUpdate.current = false;
				}}
				otherInit={{
					plugins: TEMPLATE_EDITOR_CONFIG.PLUGINS,
					toolbar: TEMPLATE_EDITOR_CONFIG.TOOLBAR,
				}}
				onInit={() => {
					setTimeout(() => {
						refUpdate.current = false;
					}, 0);
				}}
			/>
		</StyledWrapperContent>
	);
};

export default React.memo(EditorDocumentBody);
