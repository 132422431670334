import { Col, Row } from 'antd';
import React, {
	forwardRef,
	Ref,
	RefObject,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Libraries
import { Scrollbars } from 'react-custom-scrollbars';
import { createSelectable, SelectableGroup } from 'react-selectable-fast';

// Components
import MediaItem from '@components/Content/ContentLibrary/Media/MediaItem';

// Models
import ModalMediaDetail from '@components/ModalCustom/ModalMediaDetail';
import { ACTIONS, CONTENT_TYPE, GUTTER_LIST_CONTENT } from '@constants/content/contentLibrary';
import ContentSummaryContext from '@contexts/Content/Summary';
import { RefMediaList } from '@models/content/contentLibrary/ref';
import { ImgVersionDto } from '@models/content/contentLibrary/store';
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
	ContentVerificationScoreDto,
} from '@models/content/contentLibrary/summary';
import {
	convertToSingleContent,
	filterContentListById,
	getContentByType,
	getSocialS3URL,
} from '@utils/common';
import {
	addTimeStampFromUrl,
	checkPositionContent,
	handleSelectItemWithLib,
} from '@utils/funcHelper';
import { renderThumbVertical } from '@utils/renderComponent';
import { KEYBOARD } from '@constants/common';
import { SelectionCustomRef } from '@models/common/ref';
import CustomSelection from '@components/CustomLibrary/CustomSelection';

const SelectableItem = createSelectable(MediaItem);

const StyledRow = styled(Row)`
	row-gap: 15px !important;
`;

const StyledCol = styled(Col)`
	text-align: center;
`;

type MediaListProps = {
	mediaList: any;
	colCount: number;
	isToggle: boolean;
	handleSelecting: (contentList: ContentContainerDto[]) => void;
	updateContentList: (contentList: ContentContainerDto[]) => void;
	handleScrollbars: (scrollbarTop: number) => void;
	updateAfterReviewingContent: (val: ContentReviewDto[]) => void;
};

const MediaList = forwardRef((props: MediaListProps, ref: Ref<RefMediaList>) => {
	// Props
	const {
		handleSelecting,
		colCount,
		mediaList = [],
		isToggle,
		updateContentList,
		handleScrollbars,
		updateAfterReviewingContent,
	} = props;

	// Reducers
	const { movedContent, rejectedContent, deletedContent } = useSelector(
		(state: any) => state.content,
	);
	const { isCollapsedMenu } = useSelector((state: any) => state.sidebar);
	const {
		paramsContent,
		markAsFavoriteContent,
		setContentIdMove,
		contentList,
		setContentList,
		namePage,
		selectContentList,
		contentDetail,
		setContentDetail,
		typeContent,
	} = useContext(ContentSummaryContext);

	const contentRef = useRef<any>({});
	const scrollbars = useRef<any>({});
	const selectionRef = useRef<any>({});
	const refEventSelect = useRef<
		SelectionCustomRef | undefined
	>() as RefObject<SelectionCustomRef>;

	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [widthContent, setWidthContent] = useState<number>(0);
	const [isFirstContent, setIsFirstContent] = useState<boolean>(false);
	const [isLastContent, setIsLastContent] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		clearSelectionUsingRef,
	}));

	useEffect(() => {
		if (mediaList.length) {
			handleSetWidthContent();
			if (contentDetail) {
				const contentDto: ContentDto = getContentByType(contentDetail);
				mediaList.forEach((item: ContentContainerDto, idx: number) => {
					const contentTemp: ContentDto = getContentByType(item);
					if (contentTemp?.id === contentDto?.id) {
						handleContentDetails(idx);
					}
				});
			}
		}
	}, [mediaList]);

	useEffect(() => {
		handleSetWidthContent();
	}, [colCount]);

	useEffect(() => {
		setTimeout(() => {
			handleSetWidthContent();
		}, 400);
	}, [isToggle, isCollapsedMenu]);

	// Functions

	const handleSetWidthContent = () => {
		if (contentRef.current && contentRef.current.offsetWidth !== null) {
			setWidthContent(contentRef.current.offsetWidth);
		}
	};

	const handleContentDetails = (index: number) => {
		setCurrentIndex(index);
		const res = checkPositionContent(index, mediaList.length);
		setIsFirstContent(res.isFirst);
		setIsLastContent(res.isLast);
	};

	const handleCloseContentDetails = () => {
		setContentDetail(null);
		setCurrentIndex(0);
		setIsFirstContent(false);
		setIsLastContent(false);
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		const contents: ContentContainerDto[] = [];
		contentList.forEach((item) => {
			const contentTemp: ContentDto = getContentByType(item);
			if (list.includes(contentTemp.id)) {
				contents.push(item);
			}
		});
		handleSelecting(contents);
	};

	const handleSelection = (selectingItems: any) => {
		const selectContent: Array<ContentContainerDto> = [];
		selectingItems.map((selectingItem: any) => {
			const { props } = selectingItem;
			selectContent.push(props.content);
		});
		if (
			refEventSelect?.current?.handleSectionCustom &&
			typeof refEventSelect.current.handleSectionCustom === 'function'
		) {
			refEventSelect.current.handleSectionCustom(
				convertToSingleContent(selectContent).map((item) => item.id),
			);
		}

		if (!refEventSelect?.current?.isShift) {
			handleSelecting(selectContent);
		}
	};

	const moveContent = (action: string) => {
		let currentIndexTemp: number = 0;
		const maxContentIndex = mediaList.length - 1;

		if (action === 'next') {
			currentIndexTemp = currentIndex + 1;
		} else {
			currentIndexTemp = currentIndex - 1;
		}

		if (currentIndexTemp <= 0) {
			currentIndexTemp = 0;
		}

		if (currentIndexTemp >= maxContentIndex) {
			currentIndexTemp = maxContentIndex;
		}

		setCurrentIndex(currentIndexTemp);
		setContentDetail(mediaList[currentIndexTemp]);

		const res = checkPositionContent(currentIndexTemp, mediaList.length);
		setIsFirstContent(res.isFirst);
		setIsLastContent(res.isLast);
	};

	const clearSelectionUsingRef = () => {
		if (selectionRef) {
			selectionRef.current.clearSelection();
		}
	};

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			handleScrollbars(scrollbarTop);
		}
	};

	// Render components

	const renderContent = () => {
		return mediaList.map((data: any, index: number) => {
			let contentTemp: ContentDto = getContentByType(data);
			const thumbnail: string = getSocialS3URL(contentTemp.thumbnail);
			contentTemp = { ...contentTemp, thumbnail };

			return renderCol(index, props.colCount, data);
		});
	};

	const renderCol = (index: number, colCount: number, fullContent: ContentContainerDto) => {
		const padding: number = 16;
		const otherProps = {
			contentList,
			setContentList,
			namePage,
		};

		const widthNum = widthContent;
		const heightNum = (3 / 4) * widthContent - padding;

		const size = {
			height: heightNum + 'px',
			width: widthNum + 'px',
			widthNum,
			heightNum,
		};

		if (colCount === 6) {
			size.height = widthContent - padding + 'px';
		}

		fullContent = { ...fullContent };
		const idContent = getContentByType(fullContent).id;

		return (
			<StyledCol
				ref={(element) => {
					contentRef.current = element;
				}}
				span={24 / colCount}>
				{widthContent > 0 && (
					<SelectableItem
						selectContent={(content: ContentContainerDto) => {
							setContentDetail(content);
							handleContentDetails(index);
						}}
						selectContentUpdate={() => handleSelectItemWithLib(selectionRef, idContent)}
						widthContent={widthContent - GUTTER_LIST_CONTENT}
						content={fullContent}
						sizeImage={size}
						isMoreContent={colCount === 6}
						id={idContent}
						markAsFavoriteContent={markAsFavoriteContent}
						{...otherProps}
					/>
				)}
			</StyledCol>
		);
	};

	const onChangeAfterAction = async (action: string, contentsAfterAction?: number[]) => {
		let contentListTemp: Array<ContentContainerDto> = [];

		if (action === ACTIONS.DELETE) {
			contentListTemp = filterContentListById(mediaList, deletedContent);
		}

		if (action === ACTIONS.REJECT || action === ACTIONS.UNREJECT) {
			contentListTemp = [...mediaList];
			let responseContent: number[] = [];

			if (contentsAfterAction) {
				responseContent = contentsAfterAction;
			} else {
				if (action === ACTIONS.REJECT) {
					responseContent = rejectedContent;
				} else {
					responseContent = movedContent;
				}
			}

			if (responseContent.length) {
				responseContent.forEach((content: number) => {
					contentListTemp = contentListTemp.filter((item) => {
						const contentTemp = getContentByType(item);
						return contentTemp.id !== content;
					});
				});
			}
		}

		updateContentList([...contentListTemp]); // update content list in Content.
		moveContentAfterAction([...contentListTemp]);
	};

	const onChangeAfterBlock = (
		currentContent: ContentDto,
		isBlock: boolean,
		isDelete: boolean,
	) => {
		let contentListTemp: Array<ContentContainerDto> = [];
		contentListTemp = [...mediaList];

		if (!isDelete) {
			contentListTemp.forEach((item: ContentContainerDto) => {
				if (item.type === CONTENT_TYPE.VERIFIED) {
					const { content, select } = item;
					if (content) {
						updateCreatorStatus(content, currentContent, isBlock);
					}

					if (select) {
						updateCreatorStatus(select, currentContent, isBlock);
					}
				} else if (item.type === CONTENT_TYPE.CONTENT) {
					const { content } = item;
					if (content) {
						updateCreatorStatus(content, currentContent, isBlock);
					}
				} else if (item.type === CONTENT_TYPE.SELECT) {
					const { select } = item;
					if (select) {
						updateCreatorStatus(select, currentContent, isBlock);
					}
				}
			});
		} else {
			contentListTemp = contentListTemp.filter((item) => {
				const contentTemp = getContentByType(item);

				return (
					contentTemp.creatorId !== currentContent?.creatorId ||
					(contentTemp.social !== null &&
						currentContent.social !== null &&
						contentTemp.social?.socialUsername !==
							currentContent.social?.socialUsername)
				);
			});
		}

		updateContentList([...contentListTemp]);
		moveContentAfterAction(contentListTemp);
	};

	const handleClearSelection = () => {
		clearSelectionUsingRef();
		handleSelecting([]);
	};

	const moveContentAfterAction = (contentListTemp: ContentContainerDto[]) => {
		handleClearSelection();
		if (isLastContent) {
			handleCloseContentDetails();
		} else {
			setContentDetail(contentListTemp[currentIndex]);
			if (currentIndex >= contentListTemp.length - 1) {
				setIsLastContent(true);
			}
		}
	};

	const updateCreatorStatus = (
		content: ContentDto,
		currentContent: ContentDto,
		isBlock: boolean,
	) => {
		if (
			content.creatorId === currentContent?.creatorId ||
			(content.social !== null &&
				currentContent.social !== null &&
				content.social?.socialUsername === currentContent.social?.socialUsername)
		) {
			content.blocked = isBlock;
		}
	};

	const updateVerificationScrore = (
		content: ContentDto,
		verificationScore: ContentVerificationScoreDto,
	) => {
		content.verificationScore = verificationScore;
	};

	const handleUpdateNewVersion = (contentId: number, version: ImgVersionDto) => {
		const contentListTemp: Array<ContentContainerDto> = [...mediaList];

		contentListTemp.forEach((item) => {
			const contentTemp: ContentDto = getContentByType(item);
			if (contentTemp?.id === contentId) {
				contentTemp.contentUrl = addTimeStampFromUrl(
					contentTemp.contentUrl,
					version?.contentURL,
					true,
				);
				contentTemp.thumbnail = addTimeStampFromUrl(
					contentTemp.thumbnail,
					version?.thumbnailURL,
					true,
				);
				contentTemp.contentMetadata = {
					...contentTemp.contentMetadata,
					...(version?.metadata || {}),
				};
			}
		});
	};

	const handleChangeFieldContent = (val: any, id: number) => {
		mediaList.forEach((item: ContentContainerDto) => {
			const contentTemp: ContentDto = getContentByType(item);
			if (contentTemp?.id === id) {
				const arrKey = Object.keys(val);
				arrKey.forEach((key) => {
					contentTemp[key] = val[key];
				});
			}
		});
	};

	return (
		<React.Fragment>
			<Scrollbars
				className="media-list-scrollbar"
				renderTrackVertical={() => <div />}
				ref={(el) => {
					scrollbars.current = el;
				}}
				onScrollStop={onScrollStop}
				style={{ height: 'calc(100% - 10px)' }}
				renderThumbVertical={renderThumbVertical}>
				<SelectableGroup
					className="selectable-group"
					clickClassName="tick"
					ref={selectionRef}
					enableDeselect={true}
					tolerance={0}
					deselectOnEsc={true}
					allowClickWithoutSelected={true}
					selectOnClick={false}
					onSelectionFinish={handleSelection}>
					<StyledRow gutter={GUTTER_LIST_CONTENT}>{renderContent()}</StyledRow>
				</SelectableGroup>

				<CustomSelection
					ref={refEventSelect}
					ids={convertToSingleContent(contentList).map((item) => item.id)}
					selectIds={convertToSingleContent(selectContentList).map((item) => item.id)}
					refSelection={selectionRef}
					handleSelectNewList={handleSelectNewList}
				/>
				<ModalMediaDetail
					visible={!!contentDetail}
					contentProps={{
						setContentIdMove,
						content: contentDetail,
						updateAfterReviewingContent,
						markAsFavoriteContent,
						onChangeAfterAction,
						updateVerificationScrore,
						updateAfterChangeVersion: handleUpdateNewVersion,
						folderId: paramsContent.folder,
						onChangeAfterBlock,
						handleChangeFieldContent,
						enableRotateImage: typeContent.isSubmission,
					}}
					isFirstContent={isFirstContent}
					isLastContent={isLastContent}
					moveContent={moveContent}
					onCancel={handleCloseContentDetails}
				/>
			</Scrollbars>
		</React.Fragment>
	);
});

export default MediaList;
