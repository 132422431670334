import * as React from 'react';
import { Fragment, Component } from 'react';
// icons
import { ReactComponent as IconZoomIn } from '@assets/icons/svg/general-icon-add-circle-outline.svg';
import { ReactComponent as IconZoomOut } from '@assets/icons/svg/general-icon-remove-circle-outline.svg';

// draw map
import DrawMap from '@components/Dashboard/ChartByLocation/data/DrawMap';
import { SCOPE_MAP } from '@constants/dashboard';
import { renderCountryHasContent, renderStateUsHasContent } from '@utils/funcHelper';
import { withRouter } from 'react-router-dom';
import { ROUTE_PATH } from '@constants/common';

class DataMapLocation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			scopeMap: SCOPE_MAP.WORLD,
			scale: 100,
			center: [0, 36],
			dataSet: props.dataSetCountries,
			dataSetUSA: props.dataSetUSA,
			dataSetWorld: props.dataSetCountries,
		};
	}

	handleZoom = (type) => {
		const { scopeMap, dataSetWorld } = this.state;
		let { scale } = this.state;

		if (type === 'in') {
			scale += 100;
		} else if (type === 'out') {
			scale -= 100;
		}

		if (scopeMap === SCOPE_MAP.WORLD) {
			if (scale >= 400) {
				scale = 400;
			} else if (scale <= 100) {
				scale = 100;
			}
		} else {
			if (scale >= 700) {
				scale = 700;
			} else if (scale <= 200) {
				this.setState({ scopeMap: SCOPE_MAP.WORLD, dataSet: dataSetWorld });
				this.setState({ scale: 100, center: [19, -3] });
				return;
			}
		}

		this.setState({ scale });
	};

	rescaleWorld = (datamap) => {
		const { scopeMap, dataSetWorld } = this.state;
		const scalePercent = d3.event.scale.toFixed(1);

		datamap.svg
			.selectAll('g')
			.attr(
				'transform',
				'translate(' + d3.event.translate + ') scale(' + d3.event.scale + ')',
			);

		if (scopeMap === SCOPE_MAP.USA && Number(scalePercent) < 0.7) {
			this.setState({
				scale: 100,
				center: [19, -3],
				scopeMap: SCOPE_MAP.WORLD,
				dataSet: dataSetWorld,
			});
		}
	};

	done = (dataMap) => {
		const { dataSetUSA, scopeMap } = this.state;
		const { locationList, history } = this.props;
		const redRaw = () => {
			this.rescaleWorld(dataMap);
		};

		dataMap.svg.call(d3.behavior.zoom().on('zoom', redRaw));

		dataMap.svg.selectAll('.datamaps-subunit').on('click', (data) => {
			if (data.id === 'USA') {
				this.setState({ scopeMap: SCOPE_MAP.USA, dataSet: dataSetUSA });
				this.setState({ scale: 500, center: [-92, 36] });
			} else {
				const route = {
					pathname: ROUTE_PATH.SUBMISSIONS,
				};
				if (scopeMap === SCOPE_MAP.USA) {
					const country = 'US';
					const state = renderStateUsHasContent(locationList, data?.id);

					if (state) {
						route.state = {
							locations: {
								country,
								state,
							},
						};
						history.push(route);
					}
				} else {
					const country = renderCountryHasContent(locationList, data?.id);
					const state = null;
					if (country) {
						route.state = {
							locations: {
								country,
								state,
							},
						};
						history.push(route);
					}
				}
			}
		});
	};

	setProjection = (element) => {
		const { scale, center } = this.state;

		const projection = d3.geo
			.equirectangular()
			.center(center)
			.rotate([4.4, 0])
			.scale(scale)
			.translate([element.offsetWidth / 2, element.offsetHeight / 2]);
		const path = d3.geo.path().projection(projection);

		return { path, projection };
	};

	zoomIn = () => {
		this.handleZoom('in');
	};

	zoomOut = () => {
		this.handleZoom('out');
	};

	render() {
		const { scopeMap, dataSet } = this.state;
		return (
			<Fragment>
				<div className="draw-chart">
					<DrawMap
						scope={scopeMap}
						responsive={false}
						done={(dataMap) => this.done(dataMap)}
						setProjection={(e) => this.setProjection(e)}
						geographyConfig={{
							hideAntarctica: true,
							borderWidth: 1,
							borderOpacity: 1,
							borderColor: '#011627',
							popupOnHover: true,
							highlightOnHover: true,
							highlightFillColor: '#e2582b',
							highlightBorderColor: '#011627',
							highlightBorderWidth: 1,
							highlightBorderOpacity: 1,
							popupTemplate: (geography, data) => {
								return `
                                    <div class="chart-tooltip">
                                        <span>${geography.properties.name} ${data.numberOfThings}</span>
                                    </div>
                                `;
							},
						}}
						fills={{
							Minor: '#203241',
							Medium: '#4d5b67',
							Major: '#808a93',
							High: '#ccd0d4',
							defaultFill: '#203241',
						}}
						data={dataSet}
						height={265}
					/>
				</div>
				<div className="zoom-map">
					<IconZoomIn onClick={this.zoomIn} />
					<IconZoomOut onClick={this.zoomOut} />
				</div>
			</Fragment>
		);
	}
}

export default React.memo(withRouter(DataMapLocation));
