import { createAction } from 'redux-actions';
import {
	assignAlbumToGalleryTypes,
	changeCustomDetailContentGalleryTypes,
	changeDetailFolderTypes,
	changeGallerySelectTypes,
	createFolderGalleryTypes,
	fetchContentGalleryTypes,
	fetchGalleryFolderTypes,
	fetchMetricsGalleryTypes,
	getCustomPropertiesTypes,
	removeAllContentGalleryTypes,
	resetFolderGalleryTypes,
	unAssignAlbumToGalleryTypes,
} from './galleryManager.types';

// Action function fetch list
export const fetchFolderGalleryRequest = createAction(
	fetchGalleryFolderTypes.FETCH_FOLDER_GALLERY_REQUEST,
);
export const fetchFolderGallerySucceeded = createAction(
	fetchGalleryFolderTypes.FETCH_FOLDER_GALLERY_SUCCEEDED,
);
export const fetchFolderGalleryFailed = createAction(
	fetchGalleryFolderTypes.FETCH_FOLDER_GALLERY_FAILED,
);

export const resetFolderGallery = createAction(resetFolderGalleryTypes.RESET_FOLDER_GALLERY);

// Action function fetch content gallery
export const fetchContentGalleryRequest = createAction(
	fetchContentGalleryTypes.FETCH_CONTENT_GALLERY_REQUEST,
);
export const fetchContentGallerySucceeded = createAction(
	fetchContentGalleryTypes.FETCH_CONTENT_GALLERY_SUCCEEDED,
);
export const fetchContentGalleryFailed = createAction(
	fetchContentGalleryTypes.FETCH_CONTENT_GALLERY_FAILED,
);

// Action function fetch metrics gallery
export const fetchMetricsGalleryRequest = createAction(
	fetchMetricsGalleryTypes.FETCH_METRICS_GALLERY_REQUEST,
);
export const fetchMetricsGallerySucceeded = createAction(
	fetchMetricsGalleryTypes.FETCH_METRICS_GALLERY_SUCCEEDED,
);
export const fetchMetricsGalleryFailed = createAction(
	fetchMetricsGalleryTypes.FETCH_METRICS_GALLERY_FAILED,
);

// Action function update detail folder gallery
export const updateDetailFolderGalleryRequest = createAction(
	changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_REQUEST,
);
export const updateDetailFolderGallerySucceeded = createAction(
	changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_SUCCEEDED,
);
export const updateDetailFolderGalleryFailed = createAction(
	changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_FAILED,
);
export const updateDetailFolderGalleryEnd = createAction(
	changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_END,
);

// Action function assign album to gallery
export const assignAlbumToGalleryRequest = createAction(
	assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_REQUEST,
);
export const assignAlbumToGallerySucceeded = createAction(
	assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_SUCCEEDED,
);
export const assignAlbumToGalleryFailed = createAction(
	assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_FAILED,
);
export const assignAlbumToGalleryEnd = createAction(
	assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_END,
);

// Action function unassign album to gallery
export const unAssignAlbumToGalleryRequest = createAction(
	unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_REQUEST,
);
export const unAssignAlbumToGallerySucceeded = createAction(
	unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_SUCCEEDED,
);
export const unAssignAlbumToGalleryFailed = createAction(
	unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_FAILED,
);
export const unAssignAlbumToGalleryEnd = createAction(
	unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_END,
);

// Action function remove all content gallery
export const removeAllContentGalleryRequest = createAction(
	removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_REQUEST,
);
export const removeAllContentGallerySucceeded = createAction(
	removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_SUCCEEDED,
);
export const removeAllContentGalleryFailed = createAction(
	removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_FAILED,
);
export const removeAllContentGalleryEnd = createAction(
	removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_END,
);

// Action function create folder gallery
export const createFolderGalleryRequest = createAction(
	createFolderGalleryTypes.CREATE_FOLDER_GALLERY_REQUEST,
);
export const createFolderGallerySucceeded = createAction(
	createFolderGalleryTypes.CREATE_FOLDER_GALLERY_SUCCEEDED,
);
export const createFolderGalleryFailed = createAction(
	createFolderGalleryTypes.CREATE_FOLDER_GALLERY_FAILED,
);
export const createFolderGalleryEnd = createAction(
	createFolderGalleryTypes.CREATE_FOLDER_GALLERY_END,
);

// Action function update gallery select
export const changeGallerySelect = createAction(changeGallerySelectTypes.CHANGE_GALLERY_SELECT);

// Action function update custom detail content gallery
export const updateCustomDetailContentGalleryRequest = createAction(
	changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_REQUEST,
);
export const updateCustomDetailContentGallerySucceeded = createAction(
	changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_SUCCEEDED,
);
export const updateCustomDetailContentGalleryFailed = createAction(
	changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_FAILED,
);
export const updateCustomDetailContentGalleryEnd = createAction(
	changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_END,
);

// Get custom properties
export const getCustomPropertiesRequest = createAction(
	getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_REQUEST,
);
export const getCustomPropertiesSucceeded = createAction(
	getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_SUCCEEDED,
);
export const getCustomPropertiesFailed = createAction(
	getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_FAILED,
);
export const getCustomPropertiesEnd = createAction(
	getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_END,
);
