import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
import { CONTENT_THUMBNAIL_SECTION, EMAIL_BUILDER } from '@constants/messages';
import EmailBuilderContext from '@contexts/Messages/EmailBuilder';
import { uploadImageTinyMCE } from '@helpers/base64Helper';
import { Editor } from '@tinymce/tinymce-react';
import {
	Ref,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import SkinCustom from '../../../../assets/styles/components/messages/skin-email-builder/skin.min.css';
import { tinyMCEAPIKey } from '@config/index';

type EmailBuilderEditorProps = {
	onOpenReward: () => void;
	handleLoading: (isLoading: boolean) => void;
	template: any;
	handleChangeTemplate: (template: string) => void;
};

const EmailBuilderEditor = forwardRef((props: EmailBuilderEditorProps, ref: Ref<any>) => {
	const editorRef = useRef<any>();
	const { onOpenReward, handleLoading, template, handleChangeTemplate } = props;

	const { isPopup } = useContext(EmailBuilderContext);

	const [editorContent, setEditorContent] = useState<string>('');

	useEffect(() => {
		handleLoading(true);
	}, []);

	useEffect(() => {
		if (template) {
			setEditorContent(template.body || '');
		}
	}, [template]);

	useImperativeHandle(ref, () => ({
		insertDynamicField,
	}));

	const handleEditorContent = (content: string) => {
		if (content.includes(CONTENT_THUMBNAIL_SECTION)) {
			content = content.replace(CONTENT_THUMBNAIL_SECTION, '');
		}
		setEditorContent(content);
		handleChangeTemplate(content);
	};

	const insertDynamicField = (field: string) => {
		if (editorRef.current) {
			editorRef.current.execCommand('mceInsertContent', false, field);
		}
	};

	return (
		<div className="email-builder-editor">
			<Editor
				apiKey={tinyMCEAPIKey[Math.floor(Math.random() * tinyMCEAPIKey.length)]}
				onInit={(evt, editor) => {
					editorRef.current = editor;
				}}
				onEditorChange={handleEditorContent}
				value={editorContent}
				init={{
					skin_url: SkinCustom,
					content_style: [contentCss, contentUiCss].join('\n'),
					body_class: 'editor_builder',
					height: `calc(100vh - ${isPopup ? 152 : 130}px)`,
					menubar: false,
					statusbar: false,
					object_resizing: true,
					theme_modern_toolbar_location: 'bottom',
					toolbar_location: 'bottom',
					toolbar_sticky: true,
					image_advtab: true,
					valid_children: '*[*]',
					extended_valid_elements: '*[*]',
					plugins: EMAIL_BUILDER.PLUGINS,
					toolbar: EMAIL_BUILDER.TOOLBAR,
					quickbars_selection_toolbar:
						'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
					/* without images_upload_url set, Upload tab won't show up */
					automatic_uploads: true,
					file_picker_types: 'image',
					file_picker_callback: (cb, value, meta) => {
						uploadImageTinyMCE(cb);
					},
					convert_urls: false,
					setup: (editor) => {
						editor.on('init', () => {
							// all your after init logics here.
							handleLoading(false);
						});

						/* adding a group toolbar button alignment */
						editor.ui.registry.addMenuButton('alignment', {
							icon: 'align-left',
							tooltip: 'Alignment',
							fetch: (callback: any) => {
								const items = [
									{
										type: 'menuitem',
										icon: 'align-left',
										onAction: () => {
											editor.execCommand('JustifyLeft');
										},
									},
									{
										type: 'menuitem',
										icon: 'align-justify',
										onAction: () => {
											editor.execCommand('JustifyFull');
										},
									},
									{
										type: 'menuitem',
										icon: 'align-center',
										onAction: () => {
											editor.execCommand('JustifyCenter');
										},
									},
									{
										type: 'menuitem',
										icon: 'align-right',
										onAction: () => {
											editor.execCommand('JustifyRight');
										},
									},
								];

								callback(items);
							},
						});

						editor.ui.registry.addButton('reward', {
							text: EMAIL_BUILDER.ICONS.REWARD,
							tooltip: 'Reward',
							onAction: (_) => {
								onOpenReward();
							},
						});
					},
				}}
			/>
		</div>
	);
});

export default EmailBuilderEditor;
