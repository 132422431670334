import { createAction } from 'redux-actions';
import {
	cloneLiveWidgetTypes,
	createDraftWidgetBuilderTypes,
	fetchCustomFieldWidgetTypes,
	fetchDetailBuilderTypes,
	fetchDetailLiveWidgetTypes,
	fetchDetailWidgetDefaultTypes,
	publishWidgetBuilderTypes,
	removeWidgetBuilderTypes,
	resumeLiveDraftTypes,
	saveDraftWidgetBuilderTypes,
	updateStatusRecentlyUpdatedTypes,
	updateStoreWidgetTypes,
	uploadFileWidgetTypes,
	uploadFontBuilderTypes,
	widgetManagerTypes,
} from './widgetManager.types';

// Action function fetch list
export const fetchListWidgetRequest = createAction(widgetManagerTypes.FETCH_LIST_WIDGET_REQUEST);
export const fetchListWidgetSucceeded = createAction(
	widgetManagerTypes.FETCH_LIST_WIDGET_SUCCEEDED,
);
export const fetchListWidgetFailed = createAction(widgetManagerTypes.FETCH_LIST_WIDGET_FAILED);

// Action function create draft widget
export const createDraftWidgetBuilderRequest = createAction(
	createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_REQUEST,
);
export const createDraftWidgetBuilderSucceeded = createAction(
	createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_SUCCEEDED,
);
export const createDraftWidgetBuilderFailed = createAction(
	createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_FAILED,
);
export const createDraftWidgetBuilderEnd = createAction(
	createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_END,
);
// Action function save draft widget
export const saveDraftWidgetBuilderRequest = createAction(
	saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_REQUEST,
);
export const saveDraftWidgetBuilderSucceeded = createAction(
	saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_SUCCEEDED,
);
export const saveDraftWidgetBuilderFailed = createAction(
	saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_FAILED,
);
export const saveDraftWidgetBuilderEnd = createAction(
	saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_END,
);

// Action function resume live draft
export const resumeLiveDraftRequest = createAction(resumeLiveDraftTypes.RESUME_LIVE_DRAFT_REQUEST);
export const resumeLiveDraftSucceeded = createAction(
	resumeLiveDraftTypes.RESUME_LIVE_DRAFT_SUCCEEDED,
);
export const resumeLiveDraftFailed = createAction(resumeLiveDraftTypes.RESUME_LIVE_DRAFT_FAILED);
export const resumeLiveDraftEnd = createAction(resumeLiveDraftTypes.RESUME_LIVE_DRAFT_END);

// Action function clone live widget
export const cloneLiveWidgetRequest = createAction(cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_REQUEST);
export const cloneLiveWidgetSucceeded = createAction(
	cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_SUCCEEDED,
);
export const cloneLiveWidgetFailed = createAction(cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_FAILED);
export const cloneLiveWidgetEnd = createAction(cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_END);

// Action function upload font widget
export const uploadFontBuilderRequest = createAction(
	uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_REQUEST,
);
export const uploadFontBuilderSucceeded = createAction(
	uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_SUCCEEDED,
);
export const uploadFontBuilderFailed = createAction(
	uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_FAILED,
);
export const uploadFontBuilderEnd = createAction(uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_END);

// Action function save draft widget
export const fetchDetailWidgetBuilderRequest = createAction(
	fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_REQUEST,
);
export const fetchDetailWidgetBuilderSucceeded = createAction(
	fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_SUCCEEDED,
);
export const fetchDetailWidgetBuilderFailed = createAction(
	fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_FAILED,
);
export const fetchDetailWidgetBuilderEnd = createAction(
	fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_END,
);

// Action function fetch detail live widget
export const fetchDetailLiveWidgetRequest = createAction(
	fetchDetailLiveWidgetTypes.FETCH_DETAIL_LIVE_WIDGET_REQUEST,
);
export const fetchDetailLiveWidgetSucceeded = createAction(
	fetchDetailLiveWidgetTypes.FETCH_DETAIL_LIVE_WIDGET_SUCCEEDED,
);
export const fetchDetailLiveWidgetFailed = createAction(
	fetchDetailLiveWidgetTypes.FETCH_DETAIL_LIVE_WIDGET_FAILED,
);

// Action function upload file widget
export const uploadFileWidgetBuilderRequest = createAction(
	uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_REQUEST,
);
export const uploadFileWidgetBuilderSucceeded = createAction(
	uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_SUCCEEDED,
);
export const uploadFileWidgetBuilderFailed = createAction(
	uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_FAILED,
);
export const uploadFileWidgetBuilderEnd = createAction(
	uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_END,
);

// Action function  remove widget
export const removeWidgetBuilderRequest = createAction(
	removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_REQUEST,
);
export const removeWidgetBuilderSucceeded = createAction(
	removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_SUCCEEDED,
);
export const removeWidgetBuilderFailed = createAction(
	removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_FAILED,
);
export const removeWidgetBuilderEnd = createAction(
	removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_END,
);

// Action function  publish widget
export const publishWidgetBuilderRequest = createAction(
	publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_REQUEST,
);
export const publishWidgetBuilderSucceeded = createAction(
	publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_SUCCEEDED,
);
export const publishWidgetBuilderFailed = createAction(
	publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_FAILED,
);
export const publishWidgetBuilderEnd = createAction(
	publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_END,
);

// Action function update status recently updated
export const updateStatusRecentlyUpdatedRequest = createAction(
	updateStatusRecentlyUpdatedTypes.UPDATE_STATUS_RECENTLY_UPDATED_REQUEST,
);
export const updateStatusRecentlyUpdatedSucceeded = createAction(
	updateStatusRecentlyUpdatedTypes.UPDATE_STATUS_RECENTLY_UPDATED_SUCCEEDED,
);
export const updateStatusRecentlyUpdatedFailed = createAction(
	updateStatusRecentlyUpdatedTypes.UPDATE_STATUS_RECENTLY_UPDATED_FAILED,
);
export const updateStatusRecentlyUpdatedEnd = createAction(
	updateStatusRecentlyUpdatedTypes.UPDATE_STATUS_RECENTLY_UPDATED_END,
);

// Action function fetch custom field widget
export const fetchCustomFieldWidgetRequest = createAction(
	fetchCustomFieldWidgetTypes.FETCH_CUSTOM_FIELD_WIDGET_REQUEST,
);
export const fetchCustomFieldWidgetSucceeded = createAction(
	fetchCustomFieldWidgetTypes.FETCH_CUSTOM_FIELD_WIDGET_SUCCEEDED,
);
export const fetchCustomFieldWidgetFailed = createAction(
	fetchCustomFieldWidgetTypes.FETCH_CUSTOM_FIELD_WIDGET_FAILED,
);

// Action function fetch widget default
export const fetchDetailWidgetDefaultRequest = createAction(
	fetchDetailWidgetDefaultTypes.FETCH_DETAIL_WIDGET_DEFAULT_REQUEST,
);
export const fetchDetailWidgetDefaultSucceeded = createAction(
	fetchDetailWidgetDefaultTypes.FETCH_DETAIL_WIDGET_DEFAULT_SUCCEEDED,
);
export const fetchDetailWidgetDefaultFailed = createAction(
	fetchDetailWidgetDefaultTypes.FETCH_DETAIL_WIDGET_DEFAULT_FAILED,
);

// Action function update store widget
export const updateStoreWidget = createAction(updateStoreWidgetTypes.UPDATE_STORE_WIDGET);
