import { createAction } from 'redux-actions';
import {
	addCommentTypes,
	createTaskTypes,
	deleteCommentTypes,
	deleteTaskTypes,
	getAccountListLiteTypes,
	getTaskDetailsTypes,
	getTaskHistoryTypes,
	getTaskListTypes,
	markTaskAsDoneTypes,
	notifyMentionedUserTypes,
	sendReminderEmailTypes,
	storeDataTaskManagerTypes,
	updateCommentTypes,
	updateTaskTypes,
} from './taskmanager.types';

// Get task list
export const getTaskListRequest = createAction(getTaskListTypes.GET_TASK_LIST_REQUEST);
export const getTaskListSucceeded = createAction(getTaskListTypes.GET_TASK_LIST_SUCCEEDED);
export const getTaskListFailed = createAction(getTaskListTypes.GET_TASK_LIST_FAILED);
export const getTaskListEnd = createAction(getTaskListTypes.GET_TASK_LIST_END);

// Get account list lite
export const getAccountListLiteRequest = createAction(
	getAccountListLiteTypes.GET_ACCOUNT_LIST_LITE_REQUEST,
);
export const getAccountListLiteSucceeded = createAction(
	getAccountListLiteTypes.GET_ACCOUNT_LIST_LITE_SUCCEEDED,
);
export const getAccountListLiteFailed = createAction(
	getAccountListLiteTypes.GET_ACCOUNT_LIST_LITE_FAILED,
);
export const getAccountListLiteEnd = createAction(
	getAccountListLiteTypes.GET_ACCOUNT_LIST_LITE_END,
);

// Update task
export const updateTaskRequest = createAction(updateTaskTypes.UPDATE_TASK_REQUEST);
export const updateTaskSucceeded = createAction(updateTaskTypes.UPDATE_TASK_SUCCEEDED);
export const updateTaskFailed = createAction(updateTaskTypes.UPDATE_TASK_FAILED);
export const updateTaskEnd = createAction(updateTaskTypes.UPDATE_TASK_END);

// Delete task
export const deleteTaskRequest = createAction(deleteTaskTypes.DELETE_TASK_REQUEST);
export const deleteTaskSucceeded = createAction(deleteTaskTypes.DELETE_TASK_SUCCEEDED);
export const deleteTaskFailed = createAction(deleteTaskTypes.DELETE_TASK_FAILED);
export const deleteTaskEnd = createAction(deleteTaskTypes.DELETE_TASK_END);

// Mark task as DONE
export const markTaskAsDoneRequest = createAction(markTaskAsDoneTypes.MARK_TASK_AS_DONE_REQUEST);
export const markTaskAsDoneSucceeded = createAction(
	markTaskAsDoneTypes.MARK_TASK_AS_DONE_SUCCEEDED,
);
export const markTaskAsDoneFailed = createAction(markTaskAsDoneTypes.MARK_TASK_AS_DONE_FAILED);
export const markTaskAsDoneEnd = createAction(markTaskAsDoneTypes.MARK_TASK_AS_DONE_END);

// Get task details
export const getTaskDetailsRequest = createAction(getTaskDetailsTypes.GET_TASK_DETAILS_REQUEST);
export const getTaskDetailsSucceeded = createAction(getTaskDetailsTypes.GET_TASK_DETAILS_SUCCEEDED);
export const getTaskDetailsFailed = createAction(getTaskDetailsTypes.GET_TASK_DETAILS_FAILED);
export const getTaskDetailsEnd = createAction(getTaskDetailsTypes.GET_TASK_DETAILS_END);

// Get task history
export const getTaskHistoryRequest = createAction(getTaskHistoryTypes.GET_TASK_HISTORY_REQUEST);
export const getTaskHistorySucceeded = createAction(getTaskHistoryTypes.GET_TASK_HISTORY_SUCCEEDED);
export const getTaskHistoryFailed = createAction(getTaskHistoryTypes.GET_TASK_HISTORY_FAILED);
export const getTaskHistoryEnd = createAction(getTaskHistoryTypes.GET_TASK_HISTORY_END);

// Create task
export const createTaskRequest = createAction(createTaskTypes.CREATE_TASK_REQUEST);
export const createTaskSucceeded = createAction(createTaskTypes.CREATE_TASK_SUCCEEDED);
export const createTaskFailed = createAction(createTaskTypes.CREATE_TASK_FAILED);
export const createTaskEnd = createAction(createTaskTypes.CREATE_TASK_END);

// Add comment
export const addCommentRequest = createAction(addCommentTypes.ADD_COMMENT_REQUEST);
export const addCommentSucceeded = createAction(addCommentTypes.ADD_COMMENT_SUCCEEDED);
export const addCommentFailed = createAction(addCommentTypes.ADD_COMMENT_FAILED);
export const addCommentEnd = createAction(addCommentTypes.ADD_COMMENT_END);

// Update comment
export const updateCommentRequest = createAction(updateCommentTypes.UPDATE_COMMENT_REQUEST);
export const updateCommentSucceeded = createAction(updateCommentTypes.UPDATE_COMMENT_SUCCEEDED);
export const updateCommentFailed = createAction(updateCommentTypes.UPDATE_COMMENT_FAILED);
export const updateCommentEnd = createAction(updateCommentTypes.UPDATE_COMMENT_END);

// Delete comment
export const deleteCommentRequest = createAction(deleteCommentTypes.DELETE_COMMENT_REQUEST);
export const deleteCommentSucceeded = createAction(deleteCommentTypes.DELETE_COMMENT_SUCCEEDED);
export const deleteCommentFailed = createAction(deleteCommentTypes.DELETE_COMMENT_FAILED);
export const deleteCommentEnd = createAction(deleteCommentTypes.DELETE_COMMENT_END);

// Send reminder email
export const sendReminderEmailRequest = createAction(
	sendReminderEmailTypes.SEND_REMINDER_EMAIL_REQUEST,
);
export const sendReminderEmailSucceeded = createAction(
	sendReminderEmailTypes.SEND_REMINDER_EMAIL_SUCCEEDED,
);
export const sendReminderEmailFailed = createAction(
	sendReminderEmailTypes.SEND_REMINDER_EMAIL_FAILED,
);
export const sendReminderEmailEnd = createAction(sendReminderEmailTypes.SEND_REMINDER_EMAIL_END);

// Notify mentioned user
export const notifyMentionedUserRequest = createAction(
	notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_REQUEST,
);
export const notifyMentionedUserSucceeded = createAction(
	notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_SUCCEEDED,
);
export const notifyMentionedUserFailed = createAction(
	notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_FAILED,
);
export const notifyMentionedUserEnd = createAction(
	notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_END,
);

export const storeDataTaskManager = createAction(storeDataTaskManagerTypes.STORE_DATA_TASK_MANAGER);
