import THEME from '@constants/themes/themes';
import { StyledWrapperContent, StyledWrapperTitle } from '@styled/Common/CommonStyled';
import { Button, message } from 'antd';
import React, {
	Ref,
	RefObject,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RefContainerTemplate } from '@models/settings/contentLicensing/ref';
import LicensingTemplateDetailContext from '@contexts/Settings/ContentLicensing/LicensingTemplateDetail';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import { useDispatch, useSelector } from 'react-redux';
import PreviewWidgetModal from '@components/ModalCustom/PreviewWidgetModal';
import { MetadataWidgetBuilder, TermConditionItemDto } from '@models/content/widgetManager';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';
import { SPACE_TERMS } from '@constants/licensingTemplate';
import { useHistory, useLocation } from 'react-router-dom';
import { ParamUpdateTemplateTypes } from '@models/settings/contentLicensing';
import { updateTemplateTermsRequest } from '@stores/actions';
import { getUser } from '@helpers/userHelpers';
import { EMAIL_OPT_IN } from '@constants/content/widgetManager';
import { convertWidgetTerms } from '@utils/funcHelper';
import ContentLicensingTerms from './ContentLicensingTerms';

type PropTypes = {};

const ContainerTemplate = forwardRef((props: PropTypes, ref: Ref<RefContainerTemplate>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { state = {} } = location;
	const { termsIdRedirect } = (state || {}) as any;
	const user = getUser();

	const { detailWidgetDefault }: WidgetManagerStoreDto = useSelector(
		(state: any) => state?.widgetManager,
	);
	const { templateDetail }: ContentLicensingStoreDto = useSelector(
		(state: any) => state?.contentLicensing,
	);
	const {
		termsSelected,
		handleChangeFieldTerms,
		getFormatListTerms,
		statusTemplate,
		listTerms,
		setIdTermsSelected,
		handleCheckValidateField,
		isCancel,
		setIsCancel,
	} = useContext(LicensingTemplateDetailContext);

	const refContainerTemplate = useRef<
		RefContainerTemplate | undefined
	>() as RefObject<RefContainerTemplate>;

	const [visiblePreviewWidget, setVisiblePreviewWidget] = useState<boolean>(false);

	const widgetPreview = useMemo(() => {
		if (detailWidgetDefault) {
			const { metadata } = detailWidgetDefault;

			return convertWidgetTerms({
				...detailWidgetDefault,
				metadata: JSON.parse(metadata),
				terms: listTerms || [],
			});
		}
		return null;
	}, [detailWidgetDefault, listTerms]);

	useEffect(() => {
		if (templateDetail && termsIdRedirect) {
			setIdTermsSelected(termsIdRedirect);
			history.replace({ state: {} });
		}
	}, [state, templateDetail]);

	useImperativeHandle(ref, () => ({
		onSubmit,
	}));

	const onSubmit = () => {
		if (
			refContainerTemplate &&
			refContainerTemplate?.current?.onSubmit &&
			typeof refContainerTemplate.current.onSubmit === 'function'
		) {
			refContainerTemplate.current.onSubmit();
		} else {
			const formatData = getFormatListTerms();

			// if (formatData.errorRequired) {
			// 	message.error(t('message.required_some_field'));
			// 	return;
			// }
			const dataListTerms = formatData.data.map((item) => {
				const { isAdd, id, ...other } = item;

				if (isAdd) {
					return other;
				}
				return item;
			});
			const dataRequest: ParamUpdateTemplateTypes = {
				accountEmail: user?.email,
				termsTemplateId: templateDetail?.id || 0,
				body: {
					terms: dataListTerms,
				},
			};
			dispatch(updateTemplateTermsRequest(dataRequest));
		}
	};

	return (
		<StyledWrapperContent width="100%" height="100%">
			{widgetPreview && (
				<PreviewWidgetModal
					visible={visiblePreviewWidget}
					widget={widgetPreview}
					zIndex={1030}
					onCancel={() => setVisiblePreviewWidget(false)}
					enableTerms
				/>
			)}
			{termsSelected && (
				<>
					<StyledWrapperTitle
						height="55px"
						margin="0 0 8px 0"
						padding="11px 32px"
						background={THEME.colors.darkBlue2}
						justifyContent="end">
						<Button onClick={() => setVisiblePreviewWidget(true)}>
							{t('button.preview')}
						</Button>
					</StyledWrapperTitle>
					<ContentLicensingTerms
						className="set_height"
						space={SPACE_TERMS}
						handleChangeFieldTerms={handleChangeFieldTerms}
						terms={termsSelected}
						disabledEdit={!statusTemplate.isDraft}
						getFormatListTerms={getFormatListTerms}
						ref={refContainerTemplate}
						templateId={templateDetail?.id}
						isCancel={isCancel}
						setIsCancel={setIsCancel}
						handleCheckValidateField={handleCheckValidateField}
					/>
				</>
			)}
		</StyledWrapperContent>
	);
});

export default ContainerTemplate;
