const TYPE_USAGE_RESTRICTIONS = {
	rightClear: 'rightsCleared',
	rightPending: 'rightsPending',
	usageRestricted: 'usageRestricted',
	customTerms: 'customTerms',
};

const VERIFY_SCORE = {
	medium: 60,
	large: 80,
};

const FORM_PUBLISH_LOG = {
	DATE: 'date',
	WHERE: 'where',
	LINK: 'link',
};

const TYPE_PUBLISH_LOG = {
	CUSTOM_LOG: 'CUSTOM_LOG',
	SYSTEM_LOG: 'SYSTEM_LOG',
};

const DETECTED_FILE_TYPE = 'Detected File Type Name: ';

const SPACE_NEW_LINE = '\r\n';

const TYPE_FORM_CUSTOM_FIELDS = {
	input: 'input_custom_field',
	paragraph: 'paragraph_custom_field',
	dropdown: 'dropdown_custom_field',
};

export {
	TYPE_USAGE_RESTRICTIONS,
	VERIFY_SCORE,
	FORM_PUBLISH_LOG,
	TYPE_PUBLISH_LOG,
	DETECTED_FILE_TYPE,
	SPACE_NEW_LINE,
	TYPE_FORM_CUSTOM_FIELDS,
};
