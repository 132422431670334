import {
	HEIGHT_DRAG_AND_DROP,
	MAX_SIZE_SCREEN_DETAIL_WIDGET,
	OPACITY_UPLOAD,
	PADDING_WIDGET,
} from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { convertToRgbA } from '@utils/common';
import { Checkbox, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export const StyledWrapperDetailWidget = styled.div<{ bgUrl?: string; bgColor: string }>`
	padding: 15px 17px;
	background: ${({ bgUrl, bgColor }) => (bgUrl ? `url(${bgUrl})` : bgColor)};
	min-height: 100%;
	background-size: 100%;
	background-repeat: no-repeat;
`;

export const StyledDetailWidget = styled.div<{ isMobile?: boolean; bgColor?: string }>`
	background: ${({ bgColor }) => bgColor || THEME.colors.white};
	max-width: ${MAX_SIZE_SCREEN_DETAIL_WIDGET.widthDesktop}px;
	border-radius: 16px;
	padding: 20px ${({ isMobile }) => (isMobile ? PADDING_WIDGET.mobile : PADDING_WIDGET.desktop)}px;
	min-height: 100%;
	width: ${({ isMobile }) =>
		isMobile ? `${MAX_SIZE_SCREEN_DETAIL_WIDGET.widgetMobile}px` : '100%'};
	margin: auto;
	transition: ease-out 0.3s;

	&.confirmation_screen {
		color: ${THEME.colors.black};
		text-align: center;
	}
`;

export const StyledHeader = styled.div`
	margin: auto;
	text-align: center;
`;

export const StyledLogo = styled.img`
	overflow: hidden;
	transition: ease-out 0.3s;
`;

export const StyledTitle = styled.div<{ isMobile?: boolean; margin?: string; fontWeight?: string }>`
	font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
	text-align: center;
	word-break: break-word;
	color: ${THEME.colors.black};
	font-size: ${({ isMobile }) => (isMobile ? '2.136rem' : '1.714rem')};
	${({ margin }) => margin && `margin: ${margin}`};
	line-height: 1.25;
`;

export const StyledMessage = styled.div<{ isMobile?: boolean; fontWeight?: string }>`
	text-align: center;
	word-break: break-word;
	color: ${THEME.colors.black};
	font-size: ${({ isMobile }) => (isMobile ? '1.335rem' : '1.143rem')};
	margin-top: ${({ isMobile }) => (isMobile ? '9px' : '11px')};
	white-space: normal;
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	p {
		margin: 0;
	}
`;

export const StyledInputItem = styled(
	({
		isMobile,
		notEvent,
		...props
	}: {
		isMobile?: boolean;
		notEvent?: boolean;
		[props: string]: any;
	}) => <Input {...props} />,
)`
	${({ notEvent }) => notEvent && 'pointer-events: none'};
	width: 100%;
	border-radius: ${({ isMobile }) => (isMobile ? '24px' : '16px')};
	border: 1px solid #d9e4ef;
	background-color: #f5f9fd;
	color: ${THEME.colors.gray1};

	height: ${({ isMobile }) => (isMobile ? '42px' : '32px')};
	padding: ${({ isMobile }) => (isMobile ? '5px 13px' : '6px 16px')};
	font-size: ${({ isMobile }) => (isMobile ? '1.068rem' : '0.857rem')};
	${({ isMobile }) => isMobile && 'line-height: 1.875'};
	&:first-child {
		${({ isMobile }) => !isMobile && 'margin-top: 0'};
	}
	&::placeholder {
		color: ${({ placeholderColor }) => placeholderColor || `${THEME.colors.darkBlue4}`};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.darkBlue4};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.darkBlue4};
	}
`;

export const StyledTextAreaItem = styled(
	({
		isMobile,
		notEvent,
		...props
	}: {
		isMobile?: boolean;
		notEvent?: boolean;
		[props: string]: any;
	}) => <TextArea {...props} />,
)`
	width: 100%;
	border-radius: ${({ isMobile }) => (isMobile ? '24px' : '16px')};
	border: 1px solid #d9e4ef;
	background-color: #f5f9fd;
	color: ${THEME.colors.gray1};
	padding: ${({ isMobile }) => (isMobile ? '5px 13px' : '6px 16px')};
	font-size: ${({ isMobile }) => (isMobile ? '1.068rem' : '0.857rem')};
	${({ isMobile }) => isMobile && 'line-height: 1.875'};
	${({ notEvent }) => notEvent && 'pointer-events: none'};
	&::placeholder {
		color: ${THEME.colors.darkBlue4};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.darkBlue4};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.darkBlue4};
	}
`;

export const StyledCheckbox = styled(
	({
		isMobile,
		notEvent,
		bgCheckbox,
		colorBorderCheckbox,
		...other
	}: {
		isMobile?: boolean;
		notEvent?: boolean;
		bgCheckbox?: string;
		colorBorderCheckbox?: string;
		[other: string]: any;
	}) => <Checkbox {...other} />,
)`
	display: flex;
	color: ${THEME.colors.darkBlue2};
	${({ notEvent }) => notEvent && 'pointer-events: none'};

	.ant-checkbox-inner {
		${({ isMobile }) => isMobile && 'width: 12px; height: 12px;'};
	}
	&.ant-checkbox-wrapper {
		font-size: ${({ isMobile }) => (isMobile ? '1.068rem' : '1rem')};
		word-break: break-word;

		&:not(:first-child) {
			margin-top: 11px;
		}
		margin-left: 0;
	}
	&.checkbox_full {
		justify-content: flex-end;
		display: inline-flex;
	}

	&.overflow_text {
		.ant-checkbox {
			& + span {
				width: calc(100% - 20px);
				display: flex;
				align-items: start;
			}
		}
		.text_term {
			max-width: calc(100% - 10px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			&.inline_text_terms {
				display: -webkit-inline-box;
				-webkit-line-clamp: 40;
				-webkit-box-orient: vertical;
				white-space: unset;
			}
		}
	}

	.ant-checkbox-checked {
		&:hover {
			&::after {
				${({ colorBorderCheckbox }) =>
					colorBorderCheckbox && `border-color: ${colorBorderCheckbox}`};
			}
		}
	}
	.ant-checkbox {
		.ant-checkbox-inner {
			${({ bgCheckbox }) => bgCheckbox && `background-color: ${bgCheckbox}`};
			${({ colorBorderCheckbox }) =>
				colorBorderCheckbox && `border-color: ${colorBorderCheckbox}`};
		}
	}
`;

export const StyledTextTerm = styled.span`
	color: ${THEME.colors.darkBlue2};
	font-weight: 400;
`;

export const StyledRequiredCheckbox = styled.span<{ color?: string }>`
	color: ${({ color }) => color || THEME.colors.orangeBase};
	margin-left: 2px;
`;

export const StyledLink = styled.span<{ color?: string; margin?: string; required?: boolean }>`
	text-decoration: underline;
	color: ${({ color }) => color || THEME.colors.orangeBase};
	cursor: pointer;
	${({ margin }) => margin && `margin: ${margin}`};
	font-weight: 400;
	&::after {
		display: ${({ required }) => (required ? 'inline-block' : 'none')};
		bottom: 3px;
		color: ${({ color }) => color || THEME.colors.orangeBase};
		position: absolute;
		margin-left: 3px;
		font-size: 14px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: '*';
	}
`;

export const StyledUpload = styled.div<{ bgColor: string; isMobile?: boolean }>`
	display: flex;
	border: 1px dashed #d9e4ef;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: ${({ bgColor }) => bgColor};
	padding: 10px;
	height: ${({ isMobile }) =>
		isMobile ? HEIGHT_DRAG_AND_DROP.mobile : HEIGHT_DRAG_AND_DROP.desktop}px;
`;

export const StyledButtonSubmit = styled.div<{
	backgroundColor?: string;
	isMobile?: boolean;
	margin?: string;
	width?: string;
	backgroundImage?: string;
}>`
	${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
	width: 100%;
	color: ${THEME.colors.white};
	border-radius: 34px;
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	justify-content: center;
	border: 0;
	height: ${({ isMobile }) => (isMobile ? '40px' : '52px')};
	font-size: ${({ isMobile }) => (isMobile ? '1.335rem' : '1.143rem')};
	${({ isMobile }) => isMobile && 'margin-top: 32px;'};
	${({ width }) => width && `width: ${width}`};
	${({ margin }) => margin && `margin: ${margin}`};
	&.no_border {
		border-radius: 0;
	}
	&.has_url {
		background-color: unset;
		${({ backgroundImage }) => backgroundImage && `background-image: url(${backgroundImage})`};
		background-repeat: no-repeat;
		background-size: cover;
	}
`;

export const StyledWrapperTabDrag = styled.div<{ isMobile?: boolean }>`
	border-radius: 8px 8px 0px 0px;
	height: 45px;
	display: flex;
	width: 100%;
	overflow: hidden;
	margin-bottom: ${({ isMobile }) => (isMobile ? '12px' : '7px')};
`;

export const StyledGoogleTranslate = styled.div<{ isMobile?: boolean }>`
	margin-top: 16px;
	font-size: ${({ isMobile }) => (isMobile ? '1.068rem' : '1rem')};
	pointer-events: none;
	position: relative;

	${StyledIcon} {
		position: absolute;
		right: 10px;
		top: ${({ isMobile }) => (isMobile ? '15px' : '10px')};
		z-index: 99;
	}
	a {
		font-size: 12px;
		font-weight: bold;
		color: #444;
		text-decoration: none;
	}

	.goog-te-gadget {
		width: 100%;
		font-size: 11px;
		color: #666;
		white-space: nowrap;

		img {
			margin-right: 3px;
			width: 37px;
			height: 14px;
		}
	}

	.goog-te-gadget .goog-te-combo {
		font-family: 'Open Sans', 'Montserrat', 'Arial', 'sans-serif' !important;
		padding: 0 10px 0 10px;
		width: ${({ isMobile }) => (isMobile ? '310px' : '286px')};
		height: ${({ isMobile }) => (isMobile ? '42px' : '32px')};
		border-radius: ${({ isMobile }) => (isMobile ? '24px' : '16px')};
		border: 1px solid #d9e4ef;
		background-color: #f5f9fd;
		color: ${THEME.colors.gray1};
		font-size: ${({ isMobile }) => (isMobile ? '1.068rem' : '0.857rem')};

		-webkit-appearance: none;
		-moz-appearance: none;

		option {
			color: rgba(0, 0, 0, 0.85);
			font-size: 14px;
			background-color: #fff;
		}

		&:hover {
			border-color: #40a9ff;
			border-right-width: 1px;
		}

		&:focus {
			border-color: #40a9ff;
			box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
			border-right-width: 1px;
			outline: 0;
		}

		&:focus-visible {
			outline: none;
		}
	}

	.goog-logo-link {
		font-weight: 600 !important;

		&:focus-visible {
			font-weight: bold !important;
		}
	}
`;

export const StyledTabColor = styled(
	({ color, ...other }: { color?: string; [other: string]: any }) => <div {...other} />,
)`
	width: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	height: 45px;
	font-size: 14px;
	line-height: 20px;
	background-color: ${({ color }) => color || 'unset'};
	color: ${THEME.colors.white};
	border-bottom: 2px solid ${({ color }) => color || THEME.colors.orangeBase};

	&.custom {
		background-color: ${({ color }) =>
			color ? convertToRgbA(color, OPACITY_UPLOAD) : 'unset'};
		color: ${({ color }) => color || THEME.colors.white};
	}
`;
