import { Col, message, Row } from 'antd';
import * as React from 'react';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import ChartByDate from '@components/Dashboard/ChartByDate';
import ChartByLocation from '@components/Dashboard/ChartByLocation';
import LeaderBoard from '@components/Dashboard/LeaderBoard';
import Submission from '@components/Dashboard/Submission';
import TopContent from '@components/Dashboard/TopContent';

// Context
import CreatorDashboardContext from '@contexts/Creators/Dashboard';

// Constant
import {
	API_CONTENT_DASHBOARD_LOCATION,
	CONTENT_DASHBOARD_GRAPH_URL,
	CONTENT_DASHBOARD_RECENT_SUBMISSIONS_URL,
	CONTENT_DASHBOARD_STATISTIC_URL,
	CREATOR_DASHBOARD_LEADERBOARD_URL,
	CREATOR_DASHBOARD_STATISTIC_URL,
} from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';

// Styled
import TopAction from '@components/Dashboard/TopAction';
import Header, { StyledHeader } from '@cores/Header';
import LoadingWrapper from '@cores/LoadingWrapper';
import {
	DashboardPayloadDto,
	FilterDashboardDefaultDto,
	LocationDashboardType,
} from '@models/dashboard';
import { StyledSkeleton } from '@styled/Dashboard/DashboardStyled';
import { MAX_CONTENT_DASHBOARD } from '@constants/dashboard';

const StyledWrapper = styled.div`
	height: calc(100vh - 66px);
	position: relative;
	padding-top: 50px;
	overflow: hidden auto;
`;

type DashboardProps = {
	getDashboard: Function;
	getListContentCampaignAPI: Function;
	filterDefault: FilterDashboardDefaultDto | undefined;
	expandDashBoard: boolean;
};

type DashboardStates = {
	[key: string]: any;
	isFetchingCreatorStatisticData: boolean;
	isFetchingCreatorLeaderboardData: boolean;
	isFetchingCreatorLocationData: boolean;
	creatorStatisticData: object;
	creatorLeaderboardData: object;
	creatorLocationData: LocationDashboardType[];
	contentStatisticData: object;
	contentGraphData: object;
	contentRecentSubmissionsData: object;
	data: object;
	isFilterByCampaign: boolean;
};
class Dashboard extends PureComponent<DashboardProps, DashboardStates> {
	constructor(props: DashboardProps | Readonly<DashboardProps>) {
		super(props);

		this.state = {
			isFetchingCreatorStatisticData: false,
			isFetchingCreatorLeaderboardData: false,
			isFetchingCreatorLocationData: false,
			isFetchingContentStatisticData: false,
			isFetchingContentGraphData: false,
			isFetchingContentRecentSubmissionsData: false,
			isFilterByCampaign: false,
			creatorStatisticData: { creators: 0, estimatedTimeSaved: 0, rewards: 0 },
			creatorLeaderboardData: {},
			creatorLocationData: [],
			data: {},
			contentStatisticData: {},
			contentGraphData: {},
			contentRecentSubmissionsData: {},
		};
	}

	componentDidMount(): void {
		const { getListContentCampaignAPI, filterDefault } = this.props;
		const { campaign } = filterDefault || {};

		this.onGetDashboard(campaign?.id ? { campaignId: campaign.id } : undefined);

		getListContentCampaignAPI();
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		console.log(error);
	}

	componentWillUnmount(): void {}

	setLoading = (type: any, status: boolean) => {
		this.setState({
			[type]: status,
		});
	};

	// Get all segments data
	onGetDashboard = (payload?: DashboardPayloadDto) => {
		this.setLoading('isFetchingCreatorStatisticData', true);
		this.setLoading('isFetchingCreatorLeaderboardData', true);
		this.setLoading('isFetchingCreatorLocationData', true);
		this.setLoading('isFetchingContentStatisticData', true);
		this.setLoading('isFetchingContentGraphData', true);
		this.setLoading('isFetchingContentRecentSubmissionsData', true);

		if (payload) {
			const { campaignId, segmentId } = payload || {};
			if (campaignId || segmentId) {
				this.setState({ isFilterByCampaign: true });
			}
		} else {
			this.setState({ isFilterByCampaign: false });
		}

		this.fetchDashboardByCreator(payload);
		this.fetchDashboardByContent(payload);
	};

	fetchDashboardByCreator = (requestPayload?: DashboardPayloadDto) => {
		const { getDashboard } = this.props;

		let params = {};

		if (requestPayload) {
			const { date, campaignId, segmentId } = requestPayload || {};
			if (date) params = { ...params, ...date };
			if (campaignId) params = { ...params, campaignId };
			if (segmentId) params = { ...params, segmentId };
		}

		// statistic
		getDashboard({
			url: `${CREATOR_DASHBOARD_STATISTIC_URL}`,
			params,
			getDashboardSucceeded: (data: any) => {
				this.setState({
					creatorStatisticData: data,
					isFetchingCreatorStatisticData: false,
				});
			},
			getDashboardFailed: (error: any) => {
				this.setState({
					isFetchingCreatorStatisticData: false,
				});
				message.error(error || DEFAULT_ERROR);
			},
		});
		// creators	leaderboard
		getDashboard({
			url: `${CREATOR_DASHBOARD_LEADERBOARD_URL}`,
			params,
			getDashboardSucceeded: (data: any) => {
				this.setState({
					creatorLeaderboardData: data,
					isFetchingCreatorLeaderboardData: false,
				});
			},
			getDashboardFailed: (error: any) => {
				this.setState({
					isFetchingCreatorLeaderboardData: false,
				});
				message.error(error || DEFAULT_ERROR);
			},
		});
		// location
		getDashboard({
			url: `${API_CONTENT_DASHBOARD_LOCATION}`,
			params,
			getDashboardSucceeded: (data: any) => {
				this.setState({
					creatorLocationData: data,
					isFetchingCreatorLocationData: false,
				});
			},
			getDashboardFailed: (error: any) => {
				this.setState({
					isFetchingCreatorLocationData: false,
				});
				message.error(error || DEFAULT_ERROR);
			},
		});
	};

	fetchDashboardByContent = (requestPayload?: DashboardPayloadDto) => {
		const { getDashboard } = this.props;

		let params = {};

		if (requestPayload) {
			const { date, campaignId, segmentId } = requestPayload || {};
			if (date) params = { ...params, ...date };
			if (campaignId) params = { ...params, campaignId };
			if (segmentId) params = { ...params, segmentId };
		}

		getDashboard({
			url: `${CONTENT_DASHBOARD_STATISTIC_URL}`,
			params,
			getDashboardSucceeded: (data: any) => {
				this.setState({
					contentStatisticData: data,
					isFetchingContentStatisticData: false,
				});
			},
			getDashboardFailed: (error: any) => {
				this.setState({
					isFetchingContentStatisticData: false,
				});
				message.error(error || DEFAULT_ERROR);
			},
		});

		getDashboard({
			url: `${CONTENT_DASHBOARD_GRAPH_URL}`,
			params,
			getDashboardSucceeded: (data: any) => {
				this.setState({
					contentGraphData: data,
					isFetchingContentGraphData: false,
				});
			},
			getDashboardFailed: (error: any) => {
				this.setState({
					isFetchingContentGraphData: false,
				});
				message.error(error || DEFAULT_ERROR);
			},
		});

		getDashboard({
			url: `${CONTENT_DASHBOARD_RECENT_SUBMISSIONS_URL}`,
			params: { ...params, numberContent: MAX_CONTENT_DASHBOARD },
			getDashboardSucceeded: (data: any) => {
				this.setState({
					contentRecentSubmissionsData: data,
					isFetchingContentRecentSubmissionsData: false,
				});
			},
			getDashboardFailed: (error: any) => {
				this.setState({
					isFetchingContentRecentSubmissionsData: false,
				});
				message.error(error || DEFAULT_ERROR);
			},
		});
	};

	render(): React.ReactNode {
		const {
			data,
			isFetchingCreatorStatisticData,
			isFetchingCreatorLeaderboardData,
			isFetchingCreatorLocationData,
			isFetchingContentStatisticData,
			isFetchingContentGraphData,
			isFetchingContentRecentSubmissionsData,
			isFilterByCampaign,
			creatorStatisticData,
			creatorLeaderboardData,
			creatorLocationData,
			contentStatisticData,
			contentGraphData,
			contentRecentSubmissionsData,
		} = this.state;

		const { expandDashBoard } = this.props;

		return (
			<CreatorDashboardContext.Provider
				value={{
					isFetchingCreatorStatisticData,
					isFetchingCreatorLeaderboardData,
					isFetchingCreatorLocationData,
					isFetchingContentStatisticData,
					isFetchingContentGraphData,
					isFetchingContentRecentSubmissionsData,
					creatorStatisticData,
					creatorLeaderboardData,
					creatorLocationData,
					contentStatisticData,
					contentGraphData,
					contentRecentSubmissionsData,
					data,
					isFilterByCampaign,
				}}>
				<LoadingWrapper isLoading={false}>
					<StyledHeader>
						<TopAction getDashboardData={this.onGetDashboard} />
					</StyledHeader>

					<StyledWrapper className="main-content custom_scroll_bar">
						<TopContent />

						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Submission />
							</Col>
							<Col span={12}>
								{expandDashBoard ? (
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<ChartByDate />
										</Col>
										<Col span={24}>
											<StyledSkeleton
												isFilterByCampaign={isFilterByCampaign}
												loading={isFetchingCreatorLocationData}>
												<ChartByLocation />
											</StyledSkeleton>
										</Col>
									</Row>
								) : (
									<ChartByDate />
								)}
							</Col>
							{!expandDashBoard && (
								<>
									<Col span={12}>
										<LeaderBoard />
									</Col>
									<Col span={12}>
										<StyledSkeleton
											isFilterByCampaign={isFilterByCampaign}
											loading={isFetchingCreatorLocationData}>
											<ChartByLocation />
										</StyledSkeleton>
									</Col>
								</>
							)}
						</Row>
					</StyledWrapper>
				</LoadingWrapper>
			</CreatorDashboardContext.Provider>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		creatorImport: (payload: any) =>
			dispatch({
				type: 'CREATOR_IMPORT',
				payload: payload,
			}),
		getDashboard: (payload: any) =>
			dispatch({
				type: 'GET_DASHBOARD',
				payload: payload,
			}),
		getListContentCampaignAPI: () =>
			dispatch({
				type: 'GET_LIST_CONTENT_CAMPAIGN',
			}),
	};
};

const mapStateToProps = (state: any) => ({
	filter: state.header,
	filterDefault: state?.dashboard?.filterDefault,
	expandDashBoard: state?.dashboard?.expandDashBoard,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
