import { PREVIEW_TERMS_CONDITION, PUBLISH_TERMS_CONDITION } from '@constants/APIs';
import { ROUTE_PATH } from '@constants/common';
import { DEFAULT_ERROR } from '@constants/errors';
import { LoadingWrapper } from '@cores/index';
import { getRequest } from '@helpers/requestHelpers';
import { StyledWrapperPublishFile } from '@styled/PublishStyled';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Import the styles
import { NAME_FILE_TERMS } from '@constants/publish/terms-conditions';
import PdfView from '@cores/PdfView';
import { formatBase64Pdf } from '@helpers/base64Helper';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const DownloadTermsCondition = () => {
	const location = useLocation();
	const { pathname } = location;
	const [termsBase64, setTermsBase64] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const fetchTerms = async (hashCode: string) => {
		setLoading(true);
		await getRequest(`${PUBLISH_TERMS_CONDITION}/${hashCode}`)
			.then((response: any) => {
				if (response.status === 200) {
					const base64 = response.body.data?.result;
					if (base64) {
						setTermsBase64(base64);
					}
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
			})
			.catch((err: any) => {
				message.error(DEFAULT_ERROR);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchPreviewTerms = async (hashCode: string) => {
		setLoading(true);
		await getRequest(`${PREVIEW_TERMS_CONDITION}/${hashCode}`)
			.then((response: any) => {
				if (response.status === 200) {
					const base64 = response.body.data?.result;
					if (base64) {
						setTermsBase64(base64);
					}
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
			})
			.catch((err: any) => {
				message.error(DEFAULT_ERROR);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleHashCore = (subPath: string, fetchFunc: (val: string) => void) => {
		if (pathname.includes(subPath)) {
			const hashCode = pathname.slice(subPath.length);
			if (hashCode) {
				fetchFunc(hashCode);
			}
		}
	};
	useEffect(() => {
		handleHashCore(ROUTE_PATH.DOWNLOAD_TERMS_CONDITIONS_EMPTY, fetchTerms);
		handleHashCore(ROUTE_PATH.PREVIEW_TERMS_CONDITIONS_EMPTY, fetchPreviewTerms);
	}, [pathname]);

	return (
		<LoadingWrapper isLoading={loading}>
			<StyledWrapperPublishFile>
				{termsBase64 && (
					<PdfView data={formatBase64Pdf(termsBase64)} fileName={NAME_FILE_TERMS} />
				)}
			</StyledWrapperPublishFile>
		</LoadingWrapper>
	);
};

export default DownloadTermsCondition;
