import styled from 'styled-components';
import { Col, Modal } from 'antd';
import THEME from '@constants/themes/themes';
import { StyledFlex, StyledIcon } from '@styled/Common/CommonStyled';

const paddingDefault = '24px 32px';

const StyledCol = styled(Col)`
	display: flex;
	justify-content: center;
`;

const StyledContainer = styled.div<{
	padding?: string;
	height?: string;
	background?: string;
	width?: string;
}>`
	height: ${({ height }) => height || '100%'};
	background-color: ${({ background }) => background || 'none'};
	padding: ${({ padding }) => padding || `${paddingDefault}`};
	width: ${({ width }) => width || 'unset'};
	margin: 0 auto;
`;

const StyledMenuItem = styled.p`
	font-size: 1.429rem;
	padding: 0;
	margin: 0 0 24px 0;
	cursor: pointer;
	color: #67737d;

	&.active {
		font-weight: bold;
		color: ${THEME.colors.white};
	}
`;

const StyledHeaderProfile = styled.div`
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
	padding: ${paddingDefault};
`;

const StyledFooterProfile = styled.div<{ justify?: string }>`
	border-top: 1px solid ${THEME.colors.darkBlue3};
	padding: ${paddingDefault};
	justify-content: ${({ justify }) => justify || 'space-between'};
`;

const StyledGroupButton = styled.div<{ justify?: string }>`
	display: flex;
	justify-content: ${({ justify }) => justify || 'space-between'};
`;

const StyledSecurityWrapper = styled.div``;

const StyledModal = styled(Modal)``;

const StyledRegistrationAuthenticatorCol = styled(Col)`
	position: relative;

	${StyledFlex} {
		position: absolute;
		bottom: 6px;
		right: 0;
	}
`;

const StyledSecretKey = styled.div`
	position: relative;
	border: 1px solid #344552;
	border-radius: 2px;
	padding: 8px 12px;

	${StyledIcon} {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}
`;

export {
	StyledCol,
	StyledMenuItem,
	StyledContainer,
	StyledHeaderProfile,
	StyledFooterProfile,
	StyledGroupButton,
	StyledSecurityWrapper,
	StyledModal,
	StyledRegistrationAuthenticatorCol,
	StyledSecretKey,
};
