import { MEDIA_SIZE_DEFAULT } from '@constants/publish/gallery';
import { ImportedSocialDto, SocialContentContainerDto } from '@models/socialsearch/summary';
import React from 'react';

export type SocialDetailContextType = {
	dimension: number;
	socialContent: SocialContentContainerDto | null;
	activeSocialType?: string;
	payload: ImportedSocialDto | null;
	setPayload: (payload: ImportedSocialDto) => void;
	onSaveImport: () => void;
	onViewPost: () => void;
	onBlockContent: () => void;
	onBlockCreator: () => void;
	handleRequestRights: () => void;
	idFeed?: number | null | undefined;
	socialBlockCreatorDisable?: boolean;
	socialBookmarkDisable?: boolean;
};

// Default context values
const values: SocialDetailContextType = {
	dimension: MEDIA_SIZE_DEFAULT,
	socialContent: null,
	payload: null,
	setPayload: () => {},
	onSaveImport: () => {},
	onViewPost: () => {},
	onBlockContent: () => {},
	onBlockCreator: () => {},
	handleRequestRights: () => {},
	idFeed: undefined,
	socialBlockCreatorDisable: false,
	socialBookmarkDisable: false,
};

const SocialDetailContext = React.createContext(values);
export default SocialDetailContext;
