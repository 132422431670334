import { IconClose, IconExpandMore } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import { StyledIcon, StyledWrapperAction } from '@styled/Common/CommonStyled';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ProgressItem from './ProgressItem';
import {
	removeSingleUploadContent,
	resetListContentAfterUpload,
	saveUploadContentStatus,
} from '@stores/actions';
import { ConfirmModal } from '@cores/Modal';
import { HEAD_REF, ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { renderTextTime } from '@utils/funcHelper';
import { useLocation } from 'react-router-dom';

const StyledWrapperUpload = styled.div`
	position: fixed;
	bottom: 0;
	left: 180px;
	z-index: 999;
	width: 500px;
	background-color: ${THEME.colors.darkBlue3};
	border-radius: 8px;
`;

const StyledHeader = styled.div`
	font-size: 20px;
	color: ${THEME.colors.white};
	padding: 17px 26px;
	border-bottom: 1px solid ${THEME.colors.gray1};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledWrapperList = styled.div`
	padding: 7px 24px;
	display: none;
	max-height: 147px;
	overflow: auto;
	&.show {
		display: block;
	}
`;

type ProgressItemUpload = {
	id: number;
	remainTime: number;
	progress: number;
};

const UploadContentInfo = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const { pathname } = location;

	const { contentUploading }: ContentStoreDto = useSelector((state: any) => state.content);

	const [showMore, setShowMore] = useState<boolean>(true);
	const refCancelToken = useRef<any>({});
	const refTotalTime = useRef<any>({ arr: [] });
	const [timeRemainEnd, setTimeRemainEnd] = useState<number>(0);
	const [typeAction, setTypeAction] = useState<string>('');
	const [isCompleted, setIsCompleted] = useState<boolean>(false);

	const handleCancelAll = () => {
		const arrKey = Object.keys(refCancelToken.current);
		arrKey.forEach((key) => {
			refCancelToken.current[key]?.cancel();
			refCancelToken.current[key] = undefined;
			delete refCancelToken.current[key];
		});
		refTotalTime.current.arr = [];
		handleClear();
		dispatch(resetListContentAfterUpload(true));
	};

	const handleClear = () => {
		dispatch(saveUploadContentStatus({ list: [] }));
		setTypeAction('');
	};

	const getCancelToken = (id: number) => {
		const cancelToken = axios.CancelToken.source();
		refCancelToken.current[`${HEAD_REF}${id}`] = cancelToken;
		if (isCompleted) {
			setIsCompleted(false);
		}
		return cancelToken.token;
	};

	const removeRefInfo = (id: number) => {
		refCancelToken.current[`${HEAD_REF}${id}`] = undefined;
		delete refCancelToken.current[`${HEAD_REF}${id}`];
		const times = refTotalTime.current.arr?.filter((t: any) => t?.id !== id);
		refTotalTime.current.arr = times;

		if (times.length === 0) {
			setTimeRemainEnd(0);
		}
	};

	const callbackUploadEnd = (id: number) => {
		removeRefInfo(id);
		if (Object.keys(refCancelToken.current).length === 0) {
			if (!isCompleted) {
				setIsCompleted(true);
			}
			if (pathname.includes(ROUTE_PATH.SUBMISSIONS)) {
				dispatch(resetListContentAfterUpload(true));
			}
		}
	};

	const handleCancelToken = (id: number) => {
		refCancelToken.current[`${HEAD_REF}${id}`]?.cancel();
		dispatch(removeSingleUploadContent(id));

		removeRefInfo(id);
	};

	return (
		<StyledWrapperUpload>
			<ConfirmModal
				width={450}
				maskClosable={true}
				isOpen={typeAction ? true : false}
				onClose={() => setTypeAction('')}
				okText={t('button.close')}
				onSubmit={() => handleCancelAll()}
				cancelText={t('button.no')}>
				{t('content.import_modal.message_remove')}
			</ConfirmModal>
			<StyledHeader>
				<span>
					{timeRemainEnd === 0 && isCompleted
						? t('content.import_modal.uploads_complete')
						: t('content.import_modal.upload_progress', {
								val:
									timeRemainEnd === 0
										? t('content.import_modal.waiting')
										: `${renderTextTime(timeRemainEnd, t)} ${t(
												'content.import_modal.left',
										  )}`,
						  })}
				</span>
				<StyledWrapperAction>
					<StyledIcon
						onClick={() => setShowMore(!showMore)}
						className={showMore ? '' : 'ic_sort_by swap'}
						margin="0 6px 0 0">
						<IconExpandMore />
					</StyledIcon>
					<StyledIcon
						onClick={() => {
							if (timeRemainEnd === 0 && isCompleted) {
								handleClear();
							} else {
								setTypeAction(TYPE_ACTIONS.REMOVE_ALL);
							}
						}}>
						<IconClose />
					</StyledIcon>
				</StyledWrapperAction>
			</StyledHeader>
			<StyledWrapperList className={showMore ? 'show' : ''}>
				{contentUploading.map((item) => {
					return (
						<ProgressItem
							key={item.id}
							getCancelToken={() => getCancelToken(item.id)}
							content={item}
							handleCancelToken={() => handleCancelToken(item.id)}
							callbackUploadEnd={() => callbackUploadEnd(item.id)}
							refTotalTime={refTotalTime}
							setTimeRemainEnd={setTimeRemainEnd}
						/>
					);
				})}
			</StyledWrapperList>
		</StyledWrapperUpload>
	);
};

export default UploadContentInfo;
