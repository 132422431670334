import { IconAccount, IconAdd, IconInfo } from '@assets/icons';
import { EMAIL_ACTION, SENDER_EMAIL_ADDRESS, STATE_TYPE } from '@constants/messages';
import THEME from '@constants/themes/themes';
import EmailBuilderContext from '@contexts/Messages/EmailBuilder';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { SegmentsDto } from '@models/creator/segments';
import { SummaryDto } from '@models/creator/summary';
import {
	CreatorDto,
	EmailTemplateDto,
	LocationStateDto,
	RewardContainerDto,
	RewardPayloadDto,
} from '@models/messages/summary';
import { UserStoreType } from '@stores/creator/creator.types';
import { StyledTooltip, StyledTitle as StyledTitleCommons } from '@styled/Common/CommonStyled';
import { StyledCollapse } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	StyledBtn,
	StyledBtnGhost,
	StyledSection,
	StyledTitle,
} from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledContainer,
	StyledDynamicField,
	StyledEmailAdress,
	StyledHeaderPanel,
	StyledPanelFooter,
	StyledRecipient,
	StyledSenderAddress,
	StyledWrapperEmail,
} from '@styled/Messages/EmailBuilderStyled';
import { Collapse, message } from 'antd';
import _ from 'lodash';
import {
	Ref,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import AppliedReward from '../AppliedReward';
import ChooseTemplate from '../ChooseTemplate';
import ConfigureReward from '../ConfigureReward';
import EditRecipientsModal from '../EditRecipientsModal';
import { validateAllDynamicFields } from './FunctionalEmailBuilder';
import { setRewardValues } from '@utils/reward';

const { Panel } = Collapse;

type EmailBuilderPanelProps = {
	saveTemplateModal: () => void;
	handleTemplateBuilder: (template: any) => void;
	handleSendEmail: (actionType?: string, isContentReward?: number, isSaveDraft?: boolean) => void;
	handlePayloadSendMail: (creators: any) => void;
	insertDynamicField: (field: string) => void;
	getAppliedReward: (reward: RewardContainerDto) => void;
};

const EmailBuilderPanel = forwardRef((props: EmailBuilderPanelProps, ref: Ref<any>) => {
	const CURRENT_SECTION = {
		DEFAULT: 'default',
		REWARD: 'reward',
		CHOOSE_TEMPLATE: 'choose_template',
	};

	const { t } = useTranslation();
	const { state } = useLocation<LocationStateDto>();
	const configureRewardRef = useRef<any>();

	const {
		saveTemplateModal,
		handleTemplateBuilder,
		handleSendEmail,
		handlePayloadSendMail,
		insertDynamicField,
		getAppliedReward,
	} = props;

	// Reducers
	const { dynamicFields, templates } = useSelector((state: any) => state.messages);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);

	const {
		emailRequest,
		setEmailRequest,
		onLoading,
		setConfirmModal,
		setGeneralAppliedReward,
		isPopup,
		isInvitationFromCreator,
	} = useContext(EmailBuilderContext);

	const [currentSection, setCurrentSection] = useState<string>(CURRENT_SECTION.DEFAULT);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [isEditRecipient, setIsEditRecipient] = useState<boolean>(false); // true = disabled
	const [isEditReward, setIsEditReward] = useState<boolean>(false);
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const [currentTemplate, setCurrentTemplate] = useState<any>();
	const [creators, setCreators] = useState<any[]>([]);
	const [segments, setSegments] = useState<SegmentsDto[]>([]);
	const [rewards, setRewards] = useState<RewardPayloadDto[]>([]);
	const [appliedReward, setAppliedReward] = useState<any>();
	const [originalTemplate, setOriginalTemplate] = useState<any>();
	const [activeKey, setActiveKey] = useState<number[] | string[]>([]);

	const panel = [
		{
			id: 1,
			title: 'email_builder.insert_dynamic_fields',
		},
		{
			id: 2,
			title: 'email_builder.recipients',
		},
		{
			id: 3,
			title: 'email_builder.reward_applied',
		},
	];

	useEffect(() => {
		const keys: number[] = [];
		if (appliedReward) keys.push(panel[2].id);
		if (creators.length || segments.length) keys.push(panel[1].id);
		setActiveKey(keys);
	}, [appliedReward, creators, segments]);

	let rewardContent: any = [];
	let actionType: string = '';

	if (state) {
		const { content = [], type = '' } = state;

		actionType = type;
		if (actionType === STATE_TYPE.REWARD) {
			rewardContent = content;
		}
	}

	useImperativeHandle(ref, () => ({
		transformSection,
		resetData,
	}));

	const isRewardContent = actionType === STATE_TYPE.REWARD && rewardContent.length > 0;

	useEffect(() => {
		const formatSegmentTitle = (array: any[]) => {
			let newArray = [];
			newArray = array?.map((segment: any) => {
				const newSegment = {
					...segment,
					title: segment.segmentTitle,
					id: segment.segmentId,
				};
				delete newSegment.segmentTitle;
				delete newSegment.segmentId;
				delete newSegment.pagingCreator;

				return newSegment;
			});
			return newArray;
		};

		if (actionType === STATE_TYPE.EDIT_DRAFT_EMAIL) {
			const { draftEmail = {} } = state;
			const newEmail: any = { ...draftEmail };
			setCreators(newEmail.pagingCreator.creators || []);
			setSegments(formatSegmentTitle(newEmail.segments) || []);
			const payloadSendMail = {
				creators: newEmail.pagingCreator.creators || [],
				segments: formatSegmentTitle(newEmail.segments) || [],
			};
			handlePayloadSendMail(payloadSendMail);
		}

		if (actionType === STATE_TYPE.MESSAGE_OPEN_TEMPLATE) {
			const { content, currentTemplate } = state;
			const creatorsTemp: CreatorDto[] = [];

			content.map((content: ContentDto) => {
				const { creatorProfile } = content;

				creatorsTemp.push({
					id: creatorProfile?.creatorId,
					email: creatorProfile.email,
				});

				setCreators([creatorProfile]);
			});

			setCurrentTemplate(currentTemplate);
			setIsEditRecipient(true);
			handlePayloadSendMail({ creators: creatorsTemp });
		}

		if (actionType === STATE_TYPE.MESSAGE_FROM_CREATOR_PROFILE) {
			let { creatorList } = state;
			const creatorsTemp: CreatorDto[] = [];

			creatorList = creatorList.map((creator: any) => {
				if (!('creatorId' in creator)) {
					return { ...creator, creatorId: creator.id };
				}
				return { ...creator };
			});

			creatorList.map((creator: any) => {
				const { creatorId, email } = creator;

				creatorsTemp.push({
					id: creatorId,
					email,
				});
			});

			setCreators(creatorList);
			handlePayloadSendMail({ creators: creatorsTemp });
		}
	}, []);

	useEffect(() => {
		if (isInvitationFromCreator && creatorProfile) {
			const creatorList = [];
			const creator = {
				creatorId: creatorProfile?.id,
				email: creatorProfile?.email,
			};
			creatorList.push(creator);
			setCreators(creatorList);
			handlePayloadSendMail({ creators: creatorList });
		}
	}, [isInvitationFromCreator, creatorProfile]);

	useEffect(() => {
		if (state) {
			if (isRewardContent) {
				handleContentReward();
			}
		}

		// click menu item when rewarding
		if ((_.isUndefined(state) || _.isEmpty(state)) && !isInvitationFromCreator) {
			resetData();
		}
	}, [state, templates, isInvitationFromCreator]);

	useEffect(() => {
		if (currentSection !== CURRENT_SECTION.DEFAULT) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [currentSection]);

	const resetData = () => {
		const templateTemp = {
			body: '',
			enabled: true,
			title: 'none',
			subject: '',
			type: 1,
		};

		setCreators([]);
		setSegments([]);
		setAppliedReward(undefined);
		setCurrentTemplate('');
		setIsEditRecipient(false);
		handleTemplateBuilder(templateTemp);
		setCurrentSection(CURRENT_SECTION.DEFAULT);
	};

	const handleContentReward = () => {
		const creatorsTemp: CreatorDto[] = [];
		const rewardsTemp: any[] = [];

		setCurrentSection(CURRENT_SECTION.REWARD);
		setIsEditRecipient(true);

		rewardContent.map((content: ContentDto) => {
			const { creatorProfile } = content;

			creatorsTemp.push({
				id: creatorProfile.creatorId,
				email: creatorProfile.email,
			});

			rewardsTemp.push({
				contentId: content.id,
				campaignId: 0,
				campaignName: 'string',
				creatorId: creatorProfile.creatorId,
				creatorFirstName: creatorProfile?.firstName,
				creatorLastName: creatorProfile?.lastName,
				creatorEmail: creatorProfile.email,
				creatorName: creatorProfile?.firstName + ' ' + creatorProfile?.lastName,
				deleted: true,
			});

			setCreators([creatorProfile]);
			setRewards(rewardsTemp);
		});

		handlePayloadSendMail({ creators: creatorsTemp, rewards: rewardsTemp });

		if (templates && templates.length > 0) {
			const rewardTemplate = templates.find((item: any) => {
				if (item.configTitle) {
					const title = item.configTitle.toLowerCase();
					return title.includes(STATE_TYPE.REWARD);
				}

				return null;
			});

			if (rewardTemplate) setCurrentTemplate(rewardTemplate);
			handleTemplateBuilder(rewardTemplate);
		}
	};

	const renderEmailBuilderPanel = (title: string, existData?: boolean) => {
		if (title === 'email_builder.insert_dynamic_fields') {
			return (
				<StyledSection
					displayType="block"
					padding="16px 16px 16px 24px"
					borderPosition="bottom"
					flexDirection="row">
					<span>{t('email_builder.description_insert_fields')}</span>
					<br />

					{dynamicFields.map((item: any) => {
						return (
							<StyledDynamicField
								onClick={() => chooseDynamicField(item.field)}
								key={item.field}>
								{item.field}
							</StyledDynamicField>
						);
					})}
				</StyledSection>
			);
		}

		if (title === 'email_builder.recipients') {
			if (existData) {
				return (
					<StyledSection
						displayType="block"
						padding="0 16px 16px 24px"
						borderPosition="bottom"
						flexDirection="row">
						<StyledRecipient>
							{rewardContent.length ? (
								<StyledWrapperEmail>
									{rewardContent.map((content: ContentDto) => {
										const { creatorProfile } = content;
										return (
											!_.isNull(creatorProfile) && (
												<StyledEmailAdress key={creatorProfile.creatorId}>
													<IconAccount className="account-icon" />
													<StyledTitleCommons
														className="overflow_text unset_height"
														maxWidth="calc(100% - 30px)"
														title={creatorProfile.email || ''}>
														{creatorProfile.email}
													</StyledTitleCommons>
												</StyledEmailAdress>
											)
										);
									})}
								</StyledWrapperEmail>
							) : (
								<StyledWrapperEmail>
									{creators.map((creator: SummaryDto) => {
										if (!_.isUndefined(creator)) {
											const { email } = creator;
											return (
												!_.isNull(email) && (
													<StyledEmailAdress>
														<IconAccount className="account-icon" />
														<StyledTitleCommons
															className="overflow_text unset_height"
															maxWidth="calc(100% - 30px)"
															title={email || ''}>
															{email}
														</StyledTitleCommons>
													</StyledEmailAdress>
												)
											);
										}

										return null;
									})}
								</StyledWrapperEmail>
							)}

							{actionType !== 'reward' && (
								<StyledCollapse
									padding="0 24px 0 0"
									expandIconPosition="right"
									defaultActiveKey={['top_creator', 'reviewer']}>
									{segments.map((segment: SegmentsDto) => {
										if (!_.isUndefined(segment)) {
											const { id, title } = segment;
											return (
												<Panel
													header={
														<StyledHeaderPanel>
															<StyledTitle fontWeight="600">
																{t('email_builder.segment')}
															</StyledTitle>
															<span>{title}</span>
														</StyledHeaderPanel>
													}
													key={id}>
													{/* {renderPanelContent(data)} */}
												</Panel>
											);
										}

										return null;
									})}
								</StyledCollapse>
							)}
						</StyledRecipient>
					</StyledSection>
				);
			}

			return null;
		}

		if (title === 'email_builder.reward_applied') {
			if (appliedReward && !isEditReward) {
				return (
					<StyledSection
						displayType="block"
						padding="16px 16px 16px 24px"
						borderPosition="bottom"
						flexDirection="row">
						<AppliedReward
							handleEditReward={handleEditReward}
							appliedReward={appliedReward}
						/>
					</StyledSection>
				);
			}

			return null;
		}

		return null;
	};

	const renderHeaderPanel = (title: string, existData?: boolean) => {
		return (
			<>
				<StyledTitle fontSize="1.143rem" fontWeight="600">
					{t(title)}
				</StyledTitle>

				{!existData && title === t('email_builder.recipients') && (
					<StyledTitle>{t('email_builder.none_added')}</StyledTitle>
				)}

				{!existData && title === t('email_builder.reward_applied') && (
					<StyledTitle>{t('email_builder.optional')}</StyledTitle>
				)}
			</>
		);
	};

	const transformSection = (section: string) => {
		setCurrentSection(section);

		if (section === CURRENT_SECTION.CHOOSE_TEMPLATE) {
			if (!_.isEmpty(emailRequest)) {
				const templateTemp = {
					body: emailRequest.body,
					subject: emailRequest.subject,
				};

				setOriginalTemplate(templateTemp);
			}
		}
	};

	const handleClose = () => {
		setCurrentSection(CURRENT_SECTION.DEFAULT);
	};

	const handleEditRecipientsModal = () => {
		setIsVisible(!isVisible);
	};

	const chooseDynamicField = (value: string) => {
		navigator.clipboard.writeText(value);
		insertDynamicField(value);
	};

	const handleTemplate = (template: EmailTemplateDto) => {
		setCurrentTemplate(template);
		handleTemplateBuilder(template);
	};

	const handleSaveRecipients = (
		creatorRecipients: SummaryDto[],
		segmentRecipients: SegmentsDto[],
	) => {
		setCreators(creatorRecipients);
		setSegments(segmentRecipients);
		const payloadSendMail = {
			creators: creatorRecipients,
			segments: segmentRecipients,
		};

		handlePayloadSendMail(payloadSendMail);
	};

	const processReward = (configureReward: RewardContainerDto) => {
		setCurrentSection(CURRENT_SECTION.DEFAULT);
		setAppliedReward(configureReward);
		getAppliedReward(configureReward);

		const rewardsTemp: RewardPayloadDto[] = setRewardValues(configureReward, rewards);
		const payloadSendMail = {
			creators,
			segments,
			rewards: rewardsTemp,
		};
		handlePayloadSendMail(payloadSendMail);
	};

	const handleEditReward = () => {
		setCurrentSection(CURRENT_SECTION.REWARD);
		setIsEditReward(true);
	};

	const handleCheckSendEmail = () => {
		handleSendEmail(EMAIL_ACTION.SAVE_DRAFT, rewardContent.length, true);
	};

	const handleExecuteSendEmail = () => {
		if (
			validateAllDynamicFields(emailRequest, creators, appliedReward) !== '' &&
			creators.length
		) {
			setConfirmModal({
				isVisible: true,
				onSubmit: () => {
					onLoading(true);
					handleSendEmail(EMAIL_ACTION.SEND_EMAIL, rewardContent.length);
				},
				type:
					t('email_builder.validation.dynamic_fields_description') +
					'\n\n' +
					validateAllDynamicFields(emailRequest, creators, appliedReward) +
					'\n' +
					t('email_builder.validation.dynamic_fields_confirm'),
			});
		} else {
			handleSendEmail(EMAIL_ACTION.SEND_EMAIL, rewardContent.length);
		}
	};

	const confirmSendEmail = () => {
		let countCreators: number = creators.length;

		segments.forEach((segment: SegmentsDto) => {
			const { totalCreatorProfiles } = segment;
			countCreators += totalCreatorProfiles;
		});
		setConfirmModal({
			isVisible: true,
			onSubmit: () => {
				handleExecuteSendEmail();
			},
			type: t('email_builder.notification.confirm_sending', { countCreators }),
		});
	};

	const handleSaveAsTemplate = () => {
		const { subject, body } = emailRequest;

		if (_.isEqual(subject, '')) {
			message.error(t('message.blank_subject'));
			return;
		}

		if (_.isEqual(body, '')) {
			message.error(t('message.blank_body'));
			return;
		}

		saveTemplateModal();
	};

	const renderExtraButton = (action: () => void, id: number) => {
		return (
			<>
				<StyledBtn className="extra-btn" width="32px" type="primary" icon={<IconAdd />} />
				<StyledBtn
					padding="0 6px"
					width="fit-content"
					onClick={(e) => {
						e.stopPropagation();
						action();
					}}
					className={'extra-btn extra-btn-hidden'}
					type="primary"
					icon={<IconAdd />}>
					{id === 2 ? t('button.add_recipients') : t('button.log_reward')}
				</StyledBtn>
			</>
		);
	};

	const existReward = useMemo(() => {
		return !_.isUndefined(appliedReward) && !_.isEmpty(appliedReward);
	}, [appliedReward]);

	const handleCloseConfigureReward = () => {
		if (isEditReward && appliedReward) {
			setAppliedReward(undefined);
			if (setGeneralAppliedReward) setGeneralAppliedReward(undefined);
			if (setEmailRequest) setEmailRequest({ ...emailRequest, rewards: [] });
		}
		setCurrentSection(CURRENT_SECTION.DEFAULT);
		setIsEditReward(false);
	};

	const renderPanel = (panel: any) => {
		const { id, title } = panel;
		const existRecipient = creators.length > 0 || segments.length > 0;

		if (id === 2) {
			return (
				<Panel
					className="custom-panel"
					collapsible={existRecipient ? 'header' : 'disabled'}
					showArrow={existRecipient}
					header={renderHeaderPanel(t(title), existRecipient)}
					key={id}
					extra={
						existRecipient ? null : renderExtraButton(handleEditRecipientsModal, id)
					}>
					{renderEmailBuilderPanel(title, existRecipient)}
				</Panel>
			);
		}

		if (id === 3) {
			return (
				<Panel
					className="custom-panel"
					showArrow={!isEditReward && existReward}
					header={
						currentSection === CURRENT_SECTION.REWARD &&
						(!existReward || isEditReward) ? (
							<>
								<ConfigureReward
									ref={configureRewardRef}
									editedReward={isEditReward ? appliedReward : undefined}
									handleClose={handleCloseConfigureReward}
									setIsEditReward={setIsEditReward}
									processReward={processReward}
								/>
							</>
						) : (
							renderHeaderPanel(t(title), existReward)
						)
					}
					key={id}
					extra={
						existReward || currentSection === CURRENT_SECTION.REWARD
							? null
							: renderExtraButton(() => {
									setCurrentSection(CURRENT_SECTION.REWARD);
							  }, id)
					}>
					{renderEmailBuilderPanel(title)}
				</Panel>
			);
		}

		return (
			<Panel className="custom-panel" header={renderHeaderPanel(t(title))} key={id}>
				{renderEmailBuilderPanel(title)}
			</Panel>
		);
	};

	const isSendEmail = creators.length > 0 || segments.length > 0;
	const existDataSendEmail = !isSendEmail && !isRewardContent;

	const handleCollapsePanel = (keys: any) => {
		let activeKeyTemp: any = [...activeKey];

		if (keys.includes(panel[2].id.toString()) && !appliedReward) {
			activeKeyTemp = activeKeyTemp.filter(
				(key: string | number) => key.toString() !== panel[2].id.toString(),
			);
			keys = keys.length === 0 ? [] : [...activeKeyTemp];
		}

		setActiveKey([...keys]);
	};

	const renderGeneralPanel = () => {
		return currentSection === CURRENT_SECTION.CHOOSE_TEMPLATE ? (
			<>
				<ChooseTemplate
					handleTemplate={handleTemplate}
					handleClose={handleClose}
					template={currentTemplate}
					originalTemplate={originalTemplate}
				/>
			</>
		) : (
			<>
				<StyledContainer subtractedHeight={isPopup ? 232 : 265}>
					<StyledCollapse
						paddingHeader="12px 24px"
						expandIconPosition="right"
						activeKey={activeKey}
						onChange={handleCollapsePanel}>
						{panel.map((panel: any) => {
							return renderPanel(panel);
						})}
					</StyledCollapse>
				</StyledContainer>

				<StyledSenderAddress>
					<StyledTooltip
						overlayClassName="custom-tooltip"
						title={t('email_builder.tooltip.sender_address')}>
						<IconInfo className="info-icon" />
					</StyledTooltip>
					<StyledTitleCommons className="overflow_text unset_height" width="110px">
						{t('email_builder.sender_address')}
					</StyledTitleCommons>

					<StyledTitleCommons
						className="overflow_text unset_height"
						width="calc(100% - 152px)"
						title={
							clientSettings.senderAddress
								? clientSettings.senderAddress
								: SENDER_EMAIL_ADDRESS
						}
						color={THEME.colors.blueBase}
						margin="0 0 0 12px"
						fontWeight="600">
						{clientSettings.senderAddress
							? clientSettings.senderAddress
							: SENDER_EMAIL_ADDRESS}
					</StyledTitleCommons>
				</StyledSenderAddress>

				<StyledPanelFooter
					justify={
						currentSection === CURRENT_SECTION.REWARD || isEditReward || !isSendEmail
							? 'end'
							: 'space-between'
					}
					padding="16px"
					borderPosition="top"
					className="custom_scroll_bar"
					heightSection="60px"
					flexDirection="row">
					{currentSection === CURRENT_SECTION.REWARD || isEditReward ? (
						<StyledBtn
							className="btn-submit"
							key="submit"
							width="80px"
							type="primary"
							onClick={configureRewardRef?.current?.handleSubmitReward}>
							{!appliedReward ? t('button.apply') : t('button.save')}
						</StyledBtn>
					) : (
						<>
							<StyledBtnGhost
								onClick={() => handleCheckSendEmail()}
								width="109px"
								type="ghost">
								{t('button.save_draft')}
							</StyledBtnGhost>
							{isSendEmail && (
								<StyledBtnGhost
									isDisabled={isEditRecipient}
									width="109px"
									type="ghost"
									onClick={handleEditRecipientsModal}>
									{t('button.edit_recipients')}
								</StyledBtnGhost>
							)}
							<StyledBtn
								pointer={!isSendEmail}
								padding="0 6px"
								margin={!existDataSendEmail ? '0' : '0 0 0 12px'}
								width="109px"
								onClick={() => confirmSendEmail()}>
								{t('button.send_mail')}
							</StyledBtn>
						</>
					)}
				</StyledPanelFooter>
			</>
		);
	};

	return (
		<>
			{!isPopup && (
				<StyledSection
					className="custom-section-header"
					isDisableAction={isDisabled}
					borderPosition="bottom"
					heightSection="54px"
					width="100%"
					flexDirection="row"
					padding="16px">
					<StyledBtnGhost
						isDisabled={isRewardContent}
						type="ghost"
						icon={<IconAdd />}
						onClick={() => transformSection(CURRENT_SECTION.CHOOSE_TEMPLATE)}>
						{t('button.choose_template')}
					</StyledBtnGhost>
					<StyledBtn
						className="text_overflow mw_50"
						width="auto"
						padding="0 12px"
						onClick={handleSaveAsTemplate}>
						{t('button.save_as_template')}
					</StyledBtn>
				</StyledSection>
			)}
			{renderGeneralPanel()}
			<EditRecipientsModal
				isVisible={isVisible}
				onClose={handleEditRecipientsModal}
				handleSaveRecipients={handleSaveRecipients}
				selectedCreators={creators}
				selectedSegments={segments}
			/>
		</>
	);
});

export default EmailBuilderPanel;
