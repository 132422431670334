import { s3URL } from '@config/index';
import { CAMPAIGN_DETAIL_FIELD_NAME, CAMPAIGN_DYNAMIC_FIELD } from '@constants/campaign';
import { CONFIG_TITLE, EMAIL_TEMPLATE_TYPES } from '@constants/messages';
import { SPECIAL_CHARS } from '@constants/settings';
import { getUser } from '@helpers/userHelpers';
import { ItemCamPaign } from '@models/campaign';
import { DynamicFieldValueDto, WidgetLinkDto } from '@models/common/summary';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import { getSocialS3URL } from './common';

const handleConvertHash = (hashtags: string) => {
	let newHash: Array<string> = [];
	if (hashtags.includes('[')) {
		newHash = hashtags.split('"');
		const newArr = newHash.filter((item: string) => item !== ',');
		newArr.splice(0, 1);
		newArr.splice(newArr.length - 1, 1);
		return newArr;
	} else {
		newHash = hashtags.split(',');
		return newHash;
	}
};

const formatCampaignEmailTemplateList = (allEmailTemplates: EmailTemplatesDataDto[]) => {
	const defaultValue = {
		id: 0,
		title: 'None',
		configTitle: 'None',
		body: '',
	};

	const customTemplate = allEmailTemplates.filter(
		(item: EmailTemplatesDataDto) => item.type === EMAIL_TEMPLATE_TYPES.CUSTOM,
	);

	const confirmation = allEmailTemplates.filter(
		(item: EmailTemplatesDataDto) =>
			item.type === EMAIL_TEMPLATE_TYPES.DEFAULT &&
			item.configTitle === CONFIG_TITLE.UPLOAD_CONFIRMATION,
	);

	const rejection = allEmailTemplates.filter(
		(item: EmailTemplatesDataDto) =>
			item.type === EMAIL_TEMPLATE_TYPES.DEFAULT &&
			item.configTitle === CONFIG_TITLE.REJECTION_EMAIL,
	);

	const data = {
		confirmation: [defaultValue, ...confirmation, ...customTemplate],
		rejection: [defaultValue, ...rejection, ...customTemplate],
		activation: [defaultValue, ...customTemplate],
	};

	return data;
};

const createCampaignDynamicFieldValue = (
	campaign: ItemCamPaign,
	t: TFunction<'translation', undefined>,
	widgetLinks?: WidgetLinkDto[],
) => {
	const user = getUser();
	const formattedCampaign: any = { ...campaign, clientName: user.brandName };
	const {
		hashtags = '',
		metadata = '',
		description = '',
		mentionUsernames = '',
		widgetId,
	} = campaign || {};
	const defaultValues = {
		defaultHeader: t('campaign_detail.placeholder.header_cta'),
		defaultDescription: t('campaign_detail.placeholder.body_cta'),
	};
	const convertedHashtags = hashtags ? hashtags?.split(',') : [];
	const convertedMentionUsernames = mentionUsernames ? mentionUsernames?.split(',') : [];
	const defaultPlaceholder = `${s3URL}/img_content_default.png`;
	formattedCampaign[CAMPAIGN_DETAIL_FIELD_NAME.SOCIAL_CTA] = '';

	if (convertedHashtags.length) {
		convertedHashtags.forEach((item: string) => {
			formattedCampaign[
				CAMPAIGN_DETAIL_FIELD_NAME.SOCIAL_CTA
			] += `${SPECIAL_CHARS.HASH}${item} `;
		});
	}

	if (convertedMentionUsernames.length) {
		convertedMentionUsernames.forEach((item: string) => {
			formattedCampaign[
				CAMPAIGN_DETAIL_FIELD_NAME.SOCIAL_CTA
			] += `${SPECIAL_CHARS.AT}${item} `;
		});
	}

	if (formattedCampaign[CAMPAIGN_DETAIL_FIELD_NAME.SOCIAL_CTA]) {
		const searchTerm = formattedCampaign[CAMPAIGN_DETAIL_FIELD_NAME.SOCIAL_CTA];
		formattedCampaign[CAMPAIGN_DETAIL_FIELD_NAME.SOCIAL_CTA] = t(
			'campaign_detail.or_tag_footer',
			{
				tag: searchTerm,
			},
		);
	}

	if (widgetId && widgetLinks) {
		let widgetUrl = widgetLinks.find((item) => item.id === widgetId)?.url;
		widgetUrl =
			widgetUrl && widgetUrl.indexOf('?') >= 0
				? `${widgetUrl}&c=${campaign?.id}`
				: `${widgetUrl}?c=${campaign?.id}`;
		formattedCampaign[CAMPAIGN_DETAIL_FIELD_NAME.CAMPAIGN_UPLOAD_WIDGET] = widgetUrl;
	}

	if (metadata) {
		const metadataTemp = JSON.parse(metadata);
		formattedCampaign[CAMPAIGN_DETAIL_FIELD_NAME.HEADER] =
			metadataTemp?.header || defaultValues.defaultHeader;
		const imgUrl =
			metadataTemp?.content?.img1?.url ||
			metadataTemp?.content?.img2?.url ||
			metadataTemp?.content?.img3?.url;

		formattedCampaign.image = `<img style='object-fit: cover;' width='472px' height='314px' src=${
			getSocialS3URL(imgUrl) || defaultPlaceholder
		} />`;
	}

	if (!description) {
		formattedCampaign[CAMPAIGN_DETAIL_FIELD_NAME.DESCRIPTION] =
			defaultValues.defaultDescription;
	}

	let campaignDynamicFields: DynamicFieldValueDto[] = [
		{
			fieldName: CAMPAIGN_DETAIL_FIELD_NAME.IMAGE,
			dynamicField: CAMPAIGN_DYNAMIC_FIELD.IMAGE,
		},
		{
			fieldName: CAMPAIGN_DETAIL_FIELD_NAME.HEADER,
			dynamicField: CAMPAIGN_DYNAMIC_FIELD.HEADER,
		},
		{
			fieldName: CAMPAIGN_DETAIL_FIELD_NAME.DESCRIPTION,
			dynamicField: CAMPAIGN_DYNAMIC_FIELD.CALL_TO_ACTION,
		},
		{
			fieldName: CAMPAIGN_DETAIL_FIELD_NAME.SOCIAL_CTA,
			dynamicField: CAMPAIGN_DYNAMIC_FIELD.SOCIAL_CTA,
		},
		{
			fieldName: CAMPAIGN_DETAIL_FIELD_NAME.CLIENT_NAME,
			dynamicField: CAMPAIGN_DYNAMIC_FIELD.CLIENT_NAME,
		},
		{
			fieldName: CAMPAIGN_DETAIL_FIELD_NAME.CAMPAIGN_UPLOAD_WIDGET,
			dynamicField: CAMPAIGN_DYNAMIC_FIELD.CAMPAIGN_UPLOAD_WIDGET,
		},
	];

	campaignDynamicFields = campaignDynamicFields.map((item: DynamicFieldValueDto) => {
		return {
			...item,
			value: formattedCampaign[item.fieldName],
		};
	});

	return [...campaignDynamicFields];
};

const renderHashtag = (hashtagFooter: string[]) => {
	let hashTag = '';
	hashtagFooter?.forEach((item: string) => {
		hashTag += `${item} `;
	});

	if (hashTag.length > 35) {
		hashTag = `${hashTag.substring(0, 35)}...`;
	}
	return hashTag;
};

const hasChangeInCampaignDetail = (campaignDetail: ItemCamPaign, changedValue: any) => {
	const key = Object.keys(changedValue)[0];
	const originalCampaignDetail = _.cloneDeep({
		...campaignDetail,
		metadata: JSON.parse(campaignDetail?.metadata || ''),
	});
	if (!originalCampaignDetail.metadata.header) {
		originalCampaignDetail.metadata.header = '';
	}
	if (!originalCampaignDetail.metadata.description) {
		originalCampaignDetail.metadata.description = '';
	}
	const comparedCampaignDetail: any = _.cloneDeep({
		...originalCampaignDetail,
	});
	if (
		key !== CAMPAIGN_DETAIL_FIELD_NAME.HEADER &&
		key !== CAMPAIGN_DETAIL_FIELD_NAME.DESCRIPTION
	) {
		comparedCampaignDetail[key] = changedValue[key];
	} else {
		comparedCampaignDetail.metadata[key] = changedValue[key];
	}

	return !_.isEqual(
		JSON.stringify(originalCampaignDetail),
		JSON.stringify(comparedCampaignDetail),
	);
};

export {
	createCampaignDynamicFieldValue,
	formatCampaignEmailTemplateList,
	handleConvertHash,
	hasChangeInCampaignDetail,
	renderHashtag,
};
