import { MAX_RECORD_SUGGEST_DEFAULT, PARAMS_DEFAULT_ALBUMS } from '@constants/content/albums';
import { AlbumDto, ParamRequestAlbumsDto } from '@models/content/albums/albumManager';
import React from 'react';

export type AlbumManagerContextType = {
	paramAlbums: ParamRequestAlbumsDto;
	setParamAlbums: (val: any) => void;
	setVisibleAlbumPopup: (val: string) => void;
	listAlbum: AlbumDto[];
	listSuggestAlbum: AlbumDto[];
	setListAlbum: (val: any) => void;
	albumsSelected: Array<number>;
	setAlbumsSelected: (val: number[]) => void;
	setAlbumsEnable: (val: number[]) => void;
	albumsEnable: Array<number>;
	setTypeConfirm: (val: string) => void;
	hasSystemAlbum: boolean;
	setShowSuggest: (val: boolean) => void;
	showSuggest: boolean;
	setParamAlbumSuggest: (val: any) => void;
	setListSuggestAlbum: (val: Array<AlbumDto>) => void;
	paramAlbumSuggest: { maxRecords: number };
	setAlbumSuggestSelect: (val: AlbumDto | null) => void;
	hasFavoriteAlbum: boolean;
	setVisibleShareModal: (visible: boolean) => void;
	setIsLoading: (val: boolean) => void;
};

// Default context values
const values: AlbumManagerContextType = {
	paramAlbums: PARAMS_DEFAULT_ALBUMS,
	setParamAlbums: () => {},
	setVisibleAlbumPopup: () => {},
	listAlbum: [],
	listSuggestAlbum: [],
	setListAlbum: () => {},
	albumsSelected: [],
	setAlbumsSelected: () => {},
	albumsEnable: [],
	setAlbumsEnable: () => {},
	setTypeConfirm: () => {},
	hasSystemAlbum: false,
	setShowSuggest: () => {},
	showSuggest: false,
	setParamAlbumSuggest: () => {},
	setListSuggestAlbum: () => {},
	paramAlbumSuggest: { maxRecords: MAX_RECORD_SUGGEST_DEFAULT },
	setAlbumSuggestSelect: () => {},
	hasFavoriteAlbum: false,
	setVisibleShareModal: () => {},
	setIsLoading: () => {},
};

const AlbumManagerContext = React.createContext(values);
export default AlbumManagerContext;
