import ProgressLine from '@components/CustomProgress/ProgressLine';
import { NA } from '@constants/campaign';
import { STATUS_SETTING } from '@constants/settings/socialAggregator';
import THEME from '@constants/themes/themes';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { SocialSearchDto } from '@models/settings/socialSearch';
import { StyledDescText, StyledTitle, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { numberWithCommas } from '@utils/common';
import { Col, Row } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const GeneralFeeds = () => {
	const { t } = useTranslation();
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { listFeed, statsSettingFeed }: SocialSearchDto = useSelector(
		(state: any) => state.settings.socialSearch,
	);

	const valueActive = useMemo(() => {
		return listFeed.filter((item) => item.status === STATUS_SETTING.LIVE).length;
	}, [listFeed]);

	const fieldValue = useMemo(() => {
		const result = {
			limitFeed: clientSettings?.socialFeedsLimit || 0,
			percentFeed: clientSettings?.socialFeedsLimit
				? Math.round((valueActive / clientSettings.socialFeedsLimit) * 100)
				: 0,
			activeFeed: valueActive,
			limitAnalyzed: clientSettings?.socialPostsAnalysisLimit || 0,
			percentAnalyzed: clientSettings?.socialPostsAnalysisLimit
				? Math.round(
						((statsSettingFeed?.analyzedPosts || 0) /
							clientSettings.socialPostsAnalysisLimit) *
							100,
				  )
				: 0,
			activePost: statsSettingFeed?.analyzedPosts || 0,
			fromDate: statsSettingFeed
				? convertUtcToLocalTimeWithFormat(statsSettingFeed.fromDate)
				: NA,
			toDate: statsSettingFeed
				? convertUtcToLocalTimeWithFormat(statsSettingFeed.toDate)
				: NA,
			renewDate: statsSettingFeed
				? convertUtcToLocalTimeWithFormat(statsSettingFeed.renewDate)
				: NA,
		};

		return result;
	}, [clientSettings, valueActive, statsSettingFeed]);

	const listInfo = [
		{
			id: 1,
			title: 'social_aggregator.active_feeds',
			sub: 'social_aggregator.sub_active_feeds',
			valSub: {
				val: numberWithCommas(fieldValue.limitFeed),
			},
			percent: fieldValue.percentFeed,
			value: numberWithCommas(fieldValue.activeFeed),
		},
		{
			id: 2,
			title: 'social_aggregator.posts_analyzed',
			sub: 'social_aggregator.sub_analyzed',
			valSub: {
				val: numberWithCommas(fieldValue.limitAnalyzed),
				percent: `${fieldValue.percentAnalyzed}%`,
			},
			percent: fieldValue.percentAnalyzed,
			value: numberWithCommas(fieldValue.activePost),
		},
	];

	return (
		<StyledWrapperContent
			borderRadius="2px"
			width="100%"
			background={THEME.colors.darkBlue2}
			padding="30px 40px">
			<Row style={{ width: '100%' }} gutter={[54, 12]}>
				{listInfo.map((item) => (
					<Col span={12} key={item.id}>
						<StyledWrapperContent>
							<StyledTitle
								lineHeight={'22px'}
								margin="0 0 7px 0"
								color={THEME.colors.gray5}
								className="unset_height">
								{`${t(item.title)} `}
								<b style={{ marginLeft: '5px' }}>{item.value}</b>
							</StyledTitle>
							<ProgressLine
								height={11}
								strokeColor={THEME.colors.orangeBase}
								percent={item.percent}
							/>
							<StyledTitle
								lineHeight={'16px'}
								fontSize="12px"
								margin="6px 0 0 0"
								color={THEME.colors.white}
								className="unset_height">
								{t(item.sub, { ...item.valSub })}
							</StyledTitle>
						</StyledWrapperContent>
					</Col>
				))}
			</Row>
			<StyledDescText
				margin="20px 0 0 0"
				padding="15px 20px"
				background={THEME.colors.darkBlue3}
				color={THEME.colors.white}
				fontSize="16px"
				lineHeight="22px"
				borderRadius="2px"
				boxShadow="0px 4px 8px rgba(2, 12, 21, 0.2)">
				{t('social_aggregator.current_usage_period', {
					start: fieldValue.fromDate,
					end: fieldValue.toDate,
				})}
				<br />
				{t('social_aggregator.limits_reset', {
					time: fieldValue.renewDate,
				})}
			</StyledDescText>
		</StyledWrapperContent>
	);
};

export default GeneralFeeds;
