import { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PublicLayout from '@layout/PublicLayout';
import { getUserToken } from '@helpers/userHelpers';
import { ROUTE_PATH } from '@constants/common';

const PublicRoute = ({ ...rest }) => {
	const [isAuth, setIsAuth] = useState(false);
	const location = useLocation();
	const { pathname } = location;

	useEffect(() => {
		const userToken = getUserToken();

		if (userToken) {
			setIsAuth(true);
		} else {
			setIsAuth(false);
		}
	}, []);

	const checkPathPublish = () => {
		return pathname.includes(ROUTE_PATH.LOGIN) || pathname === ROUTE_PATH.DEFAULT;
	};

	if (isAuth && checkPathPublish()) {
		return <Redirect to="/dashboard" />;
	} else {
		return <PublicLayout component={rest.component} rest={rest} />;
	}
};

export default PublicRoute;
