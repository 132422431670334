import { getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
/**
 * @prettier
 */

import genericApiSaga from '@helpers/genericApiSaga';
import { Action } from 'redux-actions';
import { takeLatest, put } from 'redux-saga/effects';
import {
	assignAlbumToGalleryTypes,
	changeCustomDetailContentGalleryTypes,
	changeDetailFolderTypes,
	createFolderGalleryTypes,
	fetchContentGalleryTypes,
	fetchGalleryFolderTypes,
	fetchMetricsGalleryTypes,
	getCustomPropertiesTypes,
	removeAllContentGalleryTypes,
	unAssignAlbumToGalleryTypes,
} from './galleryManager.types';
import {
	API_REMOVE_ALL_CONTENT_GALLERY,
	ASSIGN_ALBUM_GALLERY,
	CUSTOM_PROPERTIES,
	FOLDER_CONTENT_GALLERY,
	FOLDER_GALLERY,
	PRODUCT_DEPICTED,
	UN_ASSIGN_ALBUM_GALLERY,
} from '@constants/APIs';
import * as actions from './galleryManager.action';
import {
	ParamRequestContentGallery,
	ParamRequestFolderDto,
	ParamRequestMetricGallery,
} from '@models/publishing/galleries';
import {
	ParamAssignAlbumGallery,
	ParamFolderPayloadDto,
} from '@models/publishing/galleries/stores';
import { convertParamSearch } from '@utils/common';

export function* fetchFolderGallery({ payload }: Action<ParamRequestFolderDto>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(FOLDER_GALLERY, payload),
		*completed(response: any) {
			yield put(actions.fetchFolderGallerySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchFolderGalleryFailed(response));
		},
	});
}

export function* fetchContentGallery({ payload }: Action<ParamRequestContentGallery>) {
	const { galleryId, isVerified = undefined } = payload;
	let url = `${FOLDER_CONTENT_GALLERY}/${galleryId}`;
	if (typeof isVerified === 'boolean') {
		url += `?isVerified=${isVerified}`;
	}
	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(actions.fetchContentGallerySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchContentGalleryFailed(response));
		},
	});
}

export function* fetchMetricsGallery({ payload }: Action<ParamRequestMetricGallery>) {
	const { galleryId, params } = payload;
	const { startTime, endTime } = params;

	yield genericApiSaga({
		gatewayCall: () =>
			getRequest(`${FOLDER_CONTENT_GALLERY}/${galleryId}/metrics/${startTime}/${endTime}`),
		*completed(response: any) {
			yield put(actions.fetchMetricsGallerySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchMetricsGalleryFailed(response));
		},
	});
}

export function* updateDetailFolderGallery({ payload }: Action<ParamFolderPayloadDto>) {
	const { params, noUpdateOrder = true } = payload;
	const paramRequest = { ...params };
	if (noUpdateOrder) {
		delete paramRequest?.reorder;
	}
	yield genericApiSaga({
		gatewayCall: () => putRequest(FOLDER_GALLERY, paramRequest),
		*completed(response: any) {
			yield put(actions.updateDetailFolderGallerySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateDetailFolderGalleryFailed(response));
		},
	});
}

export function* createFolderGallery() {
	yield genericApiSaga({
		gatewayCall: () => postRequest(FOLDER_GALLERY, {}),
		*completed(response: any) {
			yield put(actions.createFolderGallerySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.createFolderGalleryFailed(response));
		},
	});
}

export function* updateCustomDetailContentGallery({ payload }: Action<any>) {
	const { content, id } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${PRODUCT_DEPICTED}/${id}`, { content }),
		*completed(response: any) {
			yield put(
				actions.updateCustomDetailContentGallerySucceeded({
					...response,
					data: {
						...response?.data,
						result: content,
					},
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.updateCustomDetailContentGalleryFailed(response));
		},
	});
}

export function* assignAlbumToGallery({ payload }: Action<ParamAssignAlbumGallery>) {
	const searchParams = convertParamSearch(payload);
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${ASSIGN_ALBUM_GALLERY}?${searchParams}`),
		*completed(response: any) {
			yield put(actions.assignAlbumToGallerySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.assignAlbumToGalleryFailed(response));
		},
	});
}

export function* unAssignAlbumToGallery({ payload }: Action<number>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${UN_ASSIGN_ALBUM_GALLERY}?galleryId=${payload}`),
		*completed(response: any) {
			yield put(actions.unAssignAlbumToGallerySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.unAssignAlbumToGalleryFailed(response));
		},
	});
}

export function* removeAllContentGallery({ payload }: Action<number>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${API_REMOVE_ALL_CONTENT_GALLERY}?galleryId=${payload}`),
		*completed(response: any) {
			yield put(actions.removeAllContentGallerySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.removeAllContentGalleryFailed(response));
		},
	});
}

export function* getCustomProperties() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(CUSTOM_PROPERTIES),
		*completed(response: any) {
			yield put(actions.getCustomPropertiesSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getCustomPropertiesFailed(response));
		},
	});
}

export function* createGallerySaga() {
	yield takeLatest(fetchGalleryFolderTypes.FETCH_FOLDER_GALLERY_REQUEST, fetchFolderGallery);
	yield takeLatest(
		changeDetailFolderTypes.UPDATE_DETAIL_FOLDER_GALLERY_REQUEST,
		updateDetailFolderGallery,
	);
	yield takeLatest(createFolderGalleryTypes.CREATE_FOLDER_GALLERY_REQUEST, createFolderGallery);
	yield takeLatest(fetchContentGalleryTypes.FETCH_CONTENT_GALLERY_REQUEST, fetchContentGallery);
	yield takeLatest(fetchMetricsGalleryTypes.FETCH_METRICS_GALLERY_REQUEST, fetchMetricsGallery);
	yield takeLatest(
		changeCustomDetailContentGalleryTypes.UPDATE_CUSTOM_DETAIL_CONTENT_GALLERY_REQUEST,
		updateCustomDetailContentGallery,
	);
	yield takeLatest(
		assignAlbumToGalleryTypes.ASSIGN_ALBUM_TO_GALLERY_REQUEST,
		assignAlbumToGallery,
	);
	yield takeLatest(
		unAssignAlbumToGalleryTypes.UN_ASSIGN_ALBUM_TO_GALLERY_REQUEST,
		unAssignAlbumToGallery,
	);
	yield takeLatest(
		removeAllContentGalleryTypes.REMOVE_ALL_CONTENT_GALLERY_REQUEST,
		removeAllContentGallery,
	);
	yield takeLatest(getCustomPropertiesTypes.GET_CUSTOM_PROPERTIES_REQUEST, getCustomProperties);
}
