import { STATUSCODE } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { CampaignDto, CampaignPayloadDto, ResponseDto } from '@models/campaign';
import { Action, handleActions } from 'redux-actions';
import { CampaignTypes } from './campaign.types';

const initialState: CampaignDto = {
	loadingCampaign: false,
	loadingCampaignDetail: false,
	suggestImgStatus: NONE,
	updateCampaignStatus: NONE,
	createCampaignStatus: NONE,
	deleteCampaignStatus: NONE,
	uploadImgsCampaignStatus: NONE,
	imgsCampaignUpload: [],
	imgSuggestCampaign: null,
	campaignDetail: null,
	campaignList: [],
	message: '',
	error: null,
	campaignListContent: [],
	updateCampaignLandingPageStatus: NONE,
};

const fetchCampaignList = (state: CampaignDto) => ({
	...state,
	loadingCampaign: true,
});

const fetchCampaignListSuccess = (state: CampaignDto, { payload }: Action<CampaignPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: campaignList = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			campaignList,
			loadingCampaign: false,
		};
	}

	return {
		...state,
		loadingCampaign: false,
		error: status,
	};
};

const fetchCampaignListFailed = (state: CampaignDto, { payload }: any) => {
	const result = {
		...state,
		loadingCampaign: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
			result.message = `${code} ${error}`;
		}
	}

	return result;
};

// fetch campaign detail
const fetchDetailCampaignRequest = (state: CampaignDto) => ({
	...state,
	loadingCampaignDetail: true,
});

const fetchDetailCampaignSuccess = (state: CampaignDto, { payload }: Action<ResponseDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: campaignDetail = null,
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingCampaignDetail: false,
			campaignDetail,
			error: null,
		};
	}

	return {
		...state,
		loadingCampaignDetail: false,
		campaignDetail,
		error: status,
	};
};

const fetchDetailCampaignFailed = (state: CampaignDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingCampaignDetail: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingCampaignDetail: false,
	};
};

// suggest img campaign
const suggestImgCampaignRequest = (state: CampaignDto) => ({
	...state,
	suggestImgStatus: IN_PROGRESS,
	imgSuggestCampaign: null,
});

const suggestImgCampaignSuccess = (state: CampaignDto, { payload }: Action<ResponseDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: imgSuggestCampaign = null,
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			suggestImgStatus: SUCCEEDED,
			imgSuggestCampaign,
			error: null,
		};
	}

	return {
		...state,
		suggestImgStatus: FAILED,
		error: status,
	};
};

const suggestImgCampaignFailed = (state: CampaignDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			suggestImgStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		suggestImgStatus: FAILED,
	};
};

const suggestImgCampaignEnd = (state: CampaignDto) => {
	return {
		...state,
		suggestImgStatus: NONE,
	};
};

// save campaign detail
const saveCampaignDetail = (state: CampaignDto, { payload }: Action<any>) => {
	return {
		...state,
		campaignDetail: payload,
	};
};

// create campaign
const createCampaignRequest = (state: CampaignDto) => ({
	...state,
	createCampaignStatus: IN_PROGRESS,
});

const createCampaignSuccess = (state: CampaignDto, { payload }: Action<CampaignPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: campaignDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			createCampaignStatus: SUCCEEDED,
			campaignDetail,
			error: null,
		};
	}

	return {
		...state,
		createCampaignStatus: FAILED,
		error: status,
	};
};

const createCampaignFailed = (state: CampaignDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			createCampaignStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		createCampaignStatus: FAILED,
	};
};

const createCampaignEnd = (state: CampaignDto) => {
	return {
		...state,
		createCampaignStatus: NONE,
	};
};

// update campaign
const updateCampaignRequest = (state: CampaignDto) => ({
	...state,
	updateCampaignStatus: IN_PROGRESS,
});

const updateCampaignSuccess = (state: CampaignDto, { payload }: Action<CampaignPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: campaignDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateCampaignStatus: SUCCEEDED,
			campaignDetail,
			error: null,
		};
	}

	return {
		...state,
		updateCampaignStatus: FAILED,
		error: status,
	};
};

const updateCampaignFailed = (state: CampaignDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			updateCampaignStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		updateCampaignStatus: FAILED,
	};
};

const updateCampaignEnd = (state: CampaignDto) => {
	return {
		...state,
		updateCampaignStatus: NONE,
	};
};

// delete campaign
const deleteCampaignRequest = (state: CampaignDto) => ({
	...state,
	deleteCampaignStatus: IN_PROGRESS,
});

const deleteCampaignSuccess = (state: CampaignDto, { payload }: Action<CampaignPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deleteCampaignStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		deleteCampaignStatus: FAILED,
		error: status,
	};
};

const deleteCampaignFailed = (state: CampaignDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			deleteCampaignStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		deleteCampaignStatus: FAILED,
	};
};

const deleteCampaignEnd = (state: CampaignDto) => {
	return {
		...state,
		deleteCampaignStatus: NONE,
	};
};

// upload imgs campaign
const uploadImgsCampaignRequest = (state: CampaignDto) => ({
	...state,
	imgsCampaignUpload: [],
	uploadImgsCampaignStatus: IN_PROGRESS,
});

const uploadImgsCampaignSuccess = (state: CampaignDto, { payload }: Action<CampaignPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: imgsCampaignUpload = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			uploadImgsCampaignStatus: SUCCEEDED,
			imgsCampaignUpload,
			error: null,
		};
	}

	return {
		...state,
		uploadImgsCampaignStatus: FAILED,
		error: status,
	};
};

const uploadImgsCampaignFailed = (state: CampaignDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			uploadImgsCampaignStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		uploadImgsCampaignStatus: FAILED,
	};
};

const uploadImgsCampaignEnd = (state: CampaignDto) => {
	return {
		...state,
		uploadImgsCampaignStatus: NONE,
	};
};

const fetchListContentCampaign = (state: CampaignDto) => ({ ...state });

const fetchListContentCampaignSuccess = (state: CampaignDto, { payload }: Action<ResponseDto>) => ({
	...state,
	campaignListContent: payload.result,
});

const fetchListContentCampaignFailed = (state: CampaignDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
	};
};

const getRecommendationCampaign = (state: CampaignDto) => ({
	...state,
	loadingCampaign: true,
});

const getRecommendationCampaignSucceeded = (
	state: CampaignDto,
	{ payload }: Action<CampaignPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: recommendationCampaignList = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			recommendationCampaignList,
			loadingCampaign: false,
		};
	}

	return {
		...state,
		loadingCampaign: false,
		error: status,
	};
};

const getRecommendationCampaignFailed = (state: CampaignDto, { payload }: any) => {
	const result = {
		...state,
		loadingCampaign: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
			result.message = `${code} ${error}`;
		}
	}

	return result;
};

const getRecommendationCampaignEnd = (state: CampaignDto) => ({
	...state,
	loadingCampaign: false,
	recommendationCampaignList: null,
});

const updateCampaignLandingPageRequest = (state: CampaignDto) => ({
	...state,
	updateCampaignLandingPageStatus: IN_PROGRESS,
});

const updateCampaignLandingPageSucceeded = (
	state: CampaignDto,
	{ payload }: Action<CampaignPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateCampaignLandingPageStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		updateCampaignLandingPageStatus: FAILED,
		error: status,
	};
};

const updateCampaignLandingPageFailed = (state: CampaignDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			updateCampaignLandingPageStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		updateCampaignLandingPageStatus: FAILED,
	};
};

const updateCampaignLandingPageEnd = (state: CampaignDto) => {
	return {
		...state,
		updateCampaignLandingPageStatus: NONE,
	};
};

const storeCampaignData = (state: CampaignDto, { payload }: any) => ({
	...state,
	...payload,
});

const settingReducers = handleActions<any>(
	{
		[CampaignTypes.GET_CAMPAIGN_LIST]: fetchCampaignList,
		[CampaignTypes.GET_CAMPAIGN_LIST_SUCCESS]: fetchCampaignListSuccess,
		[CampaignTypes.GET_CAMPAIGN_LIST_FAILED]: fetchCampaignListFailed,

		[CampaignTypes.GET_DETAIL_CAMPAIGN_REQUEST]: fetchDetailCampaignRequest,
		[CampaignTypes.GET_DETAIL_CAMPAIGN_SUCCESS]: fetchDetailCampaignSuccess,
		[CampaignTypes.GET_DETAIL_CAMPAIGN_FAILED]: fetchDetailCampaignFailed,

		[CampaignTypes.SUGGEST_IMG_CAMPAIGN_REQUEST]: suggestImgCampaignRequest,
		[CampaignTypes.SUGGEST_IMG_CAMPAIGN_SUCCESS]: suggestImgCampaignSuccess,
		[CampaignTypes.SUGGEST_IMG_CAMPAIGN_FAILED]: suggestImgCampaignFailed,
		[CampaignTypes.SUGGEST_IMG_CAMPAIGN_ENDED]: suggestImgCampaignEnd,

		[CampaignTypes.SAVE_CAMPAIGN_DETAIL]: saveCampaignDetail,

		[CampaignTypes.CREATE_CAMPAIGN_REQUEST]: createCampaignRequest,
		[CampaignTypes.CREATE_CAMPAIGN_SUCCESS]: createCampaignSuccess,
		[CampaignTypes.CREATE_CAMPAIGN_FAILED]: createCampaignFailed,
		[CampaignTypes.CREATE_CAMPAIGN_ENDED]: createCampaignEnd,

		[CampaignTypes.UPDATE_CAMPAIGN_REQUEST]: updateCampaignRequest,
		[CampaignTypes.UPDATE_CAMPAIGN_SUCCESS]: updateCampaignSuccess,
		[CampaignTypes.UPDATE_CAMPAIGN_FAILED]: updateCampaignFailed,
		[CampaignTypes.UPDATE_CAMPAIGN_ENDED]: updateCampaignEnd,

		[CampaignTypes.GET_LIST_CONTENT_CAMPAIGN]: fetchListContentCampaign,
		[CampaignTypes.GET_LIST_CONTENT_CAMPAIGN_SUCCESS]: fetchListContentCampaignSuccess,
		[CampaignTypes.GET_LIST_CONTENT_CAMPAIGN_FAILED]: fetchListContentCampaignFailed,

		[CampaignTypes.DELETE_CAMPAIGN_REQUEST]: deleteCampaignRequest,
		[CampaignTypes.DELETE_CAMPAIGN_SUCCESS]: deleteCampaignSuccess,
		[CampaignTypes.DELETE_CAMPAIGN_FAILED]: deleteCampaignFailed,
		[CampaignTypes.DELETE_CAMPAIGN_ENDED]: deleteCampaignEnd,

		[CampaignTypes.UPLOAD_IMGS_CAMPAIGN_REQUEST]: uploadImgsCampaignRequest,
		[CampaignTypes.UPLOAD_IMGS_CAMPAIGN_SUCCESS]: uploadImgsCampaignSuccess,
		[CampaignTypes.UPLOAD_IMGS_CAMPAIGN_FAILED]: uploadImgsCampaignFailed,
		[CampaignTypes.UPLOAD_IMGS_CAMPAIGN_ENDED]: uploadImgsCampaignEnd,

		[CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_REQUEST]: getRecommendationCampaign,
		[CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_SUCCEEDED]: getRecommendationCampaignSucceeded,
		[CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_FAILED]: getRecommendationCampaignFailed,
		[CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_END]: getRecommendationCampaignEnd,

		[CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_REQUEST]: updateCampaignLandingPageRequest,
		[CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_SUCCEEDED]: updateCampaignLandingPageSucceeded,
		[CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_FAILED]: updateCampaignLandingPageFailed,
		[CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_END]: updateCampaignLandingPageEnd,

		// Store data
		[CampaignTypes.STORE_CAMPAIGN_DATA]: storeCampaignData,
	},
	initialState,
);

export default settingReducers;
