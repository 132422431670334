import { ImageLogoTextBlack } from '@assets/images';
import { TYPE_ACTIONS, UNIT_VALUE } from '@constants/common';
import {
	DEFAULT_CENTER,
	MIN_CONTENT,
	OPTION_CALL_TO_ACTION,
	QUANTITY_THEME_MOSAIC,
	TYPE_THEME,
} from '@constants/publish/gallery';
import { SOCIAL, SOCIAL_PROFILE } from '@constants/social_search';
import { SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import { ContentDto } from '@models/content/contentLibrary/summary';
import {
	MetadataGalleryDto,
	URlAndNameInstagramTwitter,
	UploaderLinkDto,
} from '@models/publishing/galleries';
import {
	StyledQRCode,
	StyledSignageMessage,
	StyledSignageMessageText,
} from '@styled/Publish/Gallery/MosaicGalleryStyled';
import {
	StyleContainerCarousel,
	StyledFooterCarousel,
	StyledHyperlinkWidget,
	StyledImageLogoFooter,
	StyledPreviewGallery,
	StyledWrapperCarousel,
	StyledWrapperPreview,
} from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import { formatUrl } from '@utils/common';
import { QRCodeSVG } from 'qrcode.react';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useWindowSize } from '@utils/customHooks';
import PreviewThumbnailModal from '../PreviewThumbnailModal';
import SliderGallery from './SliderGallery';

type PropPreviewGallery = {
	listGallery: ContentDto[];
	isReset?: boolean;
	theme: string;
	uploaderLink: UploaderLinkDto | null;
	rows: number;
	showDetailOnHover: Array<string> | null | undefined;
	showDetailLightBox: Array<string> | null | undefined;
	autoScroll: boolean;
	setListContent: (val: ContentDto[]) => void;
	isGif: boolean;
	metadata: MetadataGalleryDto;
	setOpenUploader: (val: boolean) => void;
};
const PreviewGallery = (props: PropPreviewGallery) => {
	const {
		listGallery,
		isReset,
		theme,
		uploaderLink = null,
		rows,
		showDetailOnHover,
		showDetailLightBox,
		autoScroll,
		setListContent,
		isGif,
		metadata,
		setOpenUploader,
	} = props;

	const { visibleBg, galleryBackground, ctaBackground, fontQRCodeColor } = metadata;
	const { fetchingContent } = useSelector((state: any) => state.content);
	const { statusChangeCustomDetailContent, customDetail } = useSelector(
		(state: any) => state.galleryManager,
	);

	const { t } = useTranslation();

	const [listGalleryCustom, setListGalleryCustom] = useState<Array<ContentDto | null>>([]);
	const [listGalleryMosaicCustom, setListGalleryMosaicCustom] = useState<ContentDto[][]>([]);
	const [listMosaic, setListMosaic] = useState<ContentDto[]>([]);

	const [idxCenter, setIdxCenter] = useState<number>(DEFAULT_CENTER.NORMAL);
	const [contentSelect, setContentSelect] = useState<ContentDto | null>(null);

	const [widthWindow, heightWindow] = useWindowSize();

	const isScreenSmall = useMemo(() => {
		return widthWindow < UNIT_VALUE.S_1550;
	}, [widthWindow]);

	const refSlider = useRef<any>({});

	const themeType = useMemo(() => {
		return {
			isSignage: theme === TYPE_THEME.SIGNAGE,
			isSlider: theme === TYPE_THEME.SLIDER,
			isGrid: theme === TYPE_THEME.GRID || !theme,
			isMosaic: theme === TYPE_THEME.MOSAIC,
		};
	}, [theme]);

	const defaultCenter = useMemo(() => {
		if (isScreenSmall) {
			return DEFAULT_CENTER.MIN;
		}
		return DEFAULT_CENTER.NORMAL;
	}, [isScreenSmall]);

	useEffect(() => {
		const lengthGallery = listGallery?.length;

		const newList: Array<ContentDto | null> = [...listGallery];
		if (
			lengthGallery > 0 &&
			lengthGallery < MIN_CONTENT.SLIDER &&
			theme === TYPE_THEME.SLIDER
		) {
			for (let i = 0; i < MIN_CONTENT.SLIDER - lengthGallery; i += 1) {
				if (i % 2 === 0) {
					newList.push(null);
				} else {
					newList.unshift(null);
				}
			}
		}
		setListGalleryCustom(newList);
	}, [listGallery, theme]);

	useEffect(() => {
		if (statusChangeCustomDetailContent === SUCCEEDED && contentSelect) {
			const contentChange: ContentDto = {
				...contentSelect,
				review: { ...contentSelect?.review, productDepicted: customDetail },
			};
			const newList = listGallery?.map((item) => {
				if (item?.id === contentChange?.id) {
					return contentChange;
				}
				return item;
			});

			setListContent([...newList]);
			setContentSelect(contentChange);
		}
	}, [statusChangeCustomDetailContent]);

	const handleFormatListMosaic = () => {
		const listMosaicTemp = [...listGallery];
		if (isScreenSmall) {
			let newCountMiss =
				QUANTITY_THEME_MOSAIC.SMALL - (listMosaicTemp.length % QUANTITY_THEME_MOSAIC.SMALL);
			// Loop for => group 1: 3 items, group 2: 3 items, group 3: 6 items
			if ((newCountMiss + listMosaicTemp.length) % 12 === 9) {
				newCountMiss += 3;
			}
			if (listMosaicTemp.length <= QUANTITY_THEME_MOSAIC.SMALL) {
				setListMosaic([...listMosaicTemp]);
			} else {
				setListMosaic([...listMosaicTemp, ...listMosaicTemp.slice(0, newCountMiss)]);
			}
			return;
		}
		const newCountMiss = MIN_CONTENT.MOSAIC - (listMosaicTemp.length % MIN_CONTENT.MOSAIC);

		if (listMosaicTemp.length <= MIN_CONTENT.MOSAIC) {
			setListMosaic([...listMosaicTemp]);
		} else {
			setListMosaic([...listMosaicTemp, ...listMosaicTemp.slice(0, newCountMiss)]);
		}
	};

	useEffect(() => {
		const newListMosaic: ContentDto[][] | any[] = [];
		let itemGroupMosaic: ContentDto[] = [];
		listMosaic.forEach((item: ContentDto, idx: number) => {
			if (isScreenSmall) {
				if (Math.floor(idx / QUANTITY_THEME_MOSAIC.FULL) % 2 !== 0) {
					if (idx % QUANTITY_THEME_MOSAIC.FULL === 0) {
						newListMosaic.push(itemGroupMosaic);
						itemGroupMosaic = [item];
					} else {
						itemGroupMosaic.push(item);
					}
				} else {
					if (itemGroupMosaic?.length < QUANTITY_THEME_MOSAIC.SMALL) {
						itemGroupMosaic.push(item);
					} else {
						newListMosaic.push(itemGroupMosaic);
						itemGroupMosaic = [item];
					}
				}
			} else {
				if (itemGroupMosaic?.length < QUANTITY_THEME_MOSAIC.NORMAL) {
					itemGroupMosaic.push(item);
				} else {
					newListMosaic.push(itemGroupMosaic);
					itemGroupMosaic = [item];
				}
			}
			if (idx === listMosaic?.length - 1 && itemGroupMosaic?.length > 0) {
				newListMosaic.push(itemGroupMosaic);
			}
		});

		setListGalleryMosaicCustom(newListMosaic);
	}, [listMosaic, isScreenSmall]);

	useEffect(() => {
		if (isReset && !fetchingContent && listGalleryCustom?.length > 0) {
			resetSliderScroll();
		}
	}, [isReset, listGalleryCustom, fetchingContent]);

	useEffect(() => {
		if (themeType.isMosaic || themeType.isSignage) {
			if (isReset) {
				handleFormatListMosaic();
			} else {
				setListMosaic([]);
			}
		}
	}, [isReset, listGallery, theme, metadata]);

	const handleSlickGotoStart = () => {
		if (refSlider?.current?.slickGoTo && typeof refSlider?.current?.slickGoTo === 'function') {
			refSlider?.current?.slickGoTo(0);
		}
	};

	const resetSliderScroll = () => {
		setTimeout(() => {
			setIdxCenter(defaultCenter);
		}, 0);
		handleSlickGotoStart();
	};

	useEffect(() => {
		setTimeout(() => {
			resetSliderScroll();
		}, 200);
	}, [theme, listGalleryCustom, rows]);

	useEffect(() => {
		resetSliderScroll();
	}, []);

	const handleChangeContentSelect = (type: string) => {
		const idxCurrent = listGallery?.findIndex((item) => item?.id === contentSelect?.id);
		if (idxCurrent !== -1) {
			let idxChange = idxCurrent + 1;
			if (type === TYPE_ACTIONS.NEXT) {
				if (idxCurrent === listGallery?.length - 1) {
					idxChange = 0;
				}
			} else {
				idxChange = (idxCurrent ? idxCurrent : listGallery?.length) - 1;
			}
			setContentSelect(listGallery[idxChange]);
		}
	};

	const renderInfoUser = (content: ContentDto): URlAndNameInstagramTwitter => {
		const { social, creatorProfile } = content;
		const instagramUsername =
			social && social.socialSource === SOCIAL.INSTAGRAM.toLowerCase()
				? social.socialUsername
				: null;
		const twitterUsername =
			social && social.socialSource === SOCIAL.TWITTER.toLowerCase()
				? social.socialUsername
				: null;
		const hrefInstagram = `${SOCIAL_PROFILE.INSTAGRAM}${
			instagramUsername || creatorProfile?.instagramUsername || ''
		}`;
		const hrefTwitter = `${SOCIAL_PROFILE.TWTTER}${
			twitterUsername || creatorProfile?.twitterUsername || ''
		}`;
		return {
			hrefInstagram,
			hrefTwitter,
			instagramUsername: instagramUsername || creatorProfile?.instagramUsername || '',
			twitterUsername: twitterUsername || creatorProfile?.twitterUsername || '',
		};
	};

	const renderQrCode = (url: string) => {
		if (url) {
			return <QRCodeSVG value={url} bgColor={ctaBackground} fgColor={fontQRCodeColor} />;
		}
		return null;
	};

	const renderCarouselTheme = () => {
		const hasSignageMessage =
			metadata?.uploaderLink?.options &&
			metadata?.uploaderLink?.options.includes(OPTION_CALL_TO_ACTION.message);

		const hasQRCode =
			metadata?.uploaderLink?.options &&
			metadata?.uploaderLink?.options.includes(OPTION_CALL_TO_ACTION.QRCode);

		return (
			<StyledWrapperCarousel>
				<StyleContainerCarousel
					rows={rows}
					bgColor={visibleBg ? galleryBackground : THEME.colors.transparent}
					className={`slider_carousel ${!theme ? 'theme_grid' : `theme_${theme}`}`}>
					{!themeType.isSignage && (
						<StyledHyperlinkWidget
							target={'_blank'}
							onClick={(e: React.MouseEvent<HTMLElement>) => {
								if (metadata?.isUploaderPopup) {
									e.preventDefault();
									e.stopPropagation();
									setOpenUploader(true);
								}
							}}
							href={formatUrl(uploaderLink?.url)}>
							{uploaderLink?.text}
						</StyledHyperlinkWidget>
					)}
					<SliderGallery
						isGif={isGif}
						isScreenSmall={isScreenSmall}
						listGallery={listGallery}
						listGalleryCustom={listGalleryCustom}
						listGalleryMosaicCustom={listGalleryMosaicCustom}
						metadata={metadata}
						refSlider={refSlider}
						renderInfoUser={renderInfoUser}
						rows={rows}
						setContentSelect={setContentSelect}
						showDetailLightBox={showDetailLightBox}
						showDetailOnHover={showDetailOnHover}
						theme={theme}
						themeType={themeType}
						autoScroll={autoScroll}
						idxCenter={idxCenter}
						setIdxCenter={setIdxCenter}
					/>

					{themeType.isSignage && (hasSignageMessage || hasQRCode) && (
						<StyledSignageMessage
							ctaBackground={ctaBackground}
							fontQRCodeColor={fontQRCodeColor}>
							{hasSignageMessage && (
								<StyledSignageMessageText padding={hasQRCode ? '0 14px 0 0' : '0'}>
									{metadata?.uploaderLink?.message}
								</StyledSignageMessageText>
							)}
							{hasQRCode && (
								<StyledQRCode>
									{renderQrCode(metadata?.uploaderLink?.url || '')}
								</StyledQRCode>
							)}
						</StyledSignageMessage>
					)}

					{!themeType.isSignage && (
						<StyledFooterCarousel className="footer_slider">
							{t('content.widget_manager.widget_detail_setting.powered_by')}
							<StyledImageLogoFooter src={ImageLogoTextBlack} />
						</StyledFooterCarousel>
					)}
				</StyleContainerCarousel>
			</StyledWrapperCarousel>
		);
	};

	return (
		<StyledPreviewGallery>
			<StyledWrapperPreview className="custom_scroll_bar">
				{renderCarouselTheme()}
				{contentSelect && (
					<PreviewThumbnailModal
						visible={!!contentSelect}
						contentSelect={contentSelect}
						onCancel={() => setContentSelect(null)}
						showDetailLightBox={showDetailLightBox || []}
						handleChangeContentSelect={handleChangeContentSelect}
						infoUser={renderInfoUser(contentSelect)}
					/>
				)}
			</StyledWrapperPreview>
		</StyledPreviewGallery>
	);
};

export default memo(PreviewGallery);
