/* eslint-disable @typescript-eslint/dot-notation */
import { Row, Col, Form, Input, Button, Select, Tag } from 'antd';
import styled from 'styled-components';
import Wrapper from '@cores/Wrapper';
import THEME from '@constants/themes/themes';

const breakpoint = {
	device: {
		xs: '320px',
		sm: '768px',
		lg: '1366px',
		xl: '1660px',
	},
};

const StyledWrapper = styled(Wrapper)`
	height: unset;
	height: calc(100vh - 80px);
	background-color: ${THEME.colors.darkBlue};
`;

const StyledSectionDiv = styled.div`
	width: 100%;
	@media screen and (min-width: ${breakpoint.device.xl}) {
		overflow: hidden;
	}
	@media screen and (max-width: ${breakpoint.device.xl}) {
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
	}
`;

const StyledRow = styled(Row)`
	margin-left: 0 !important;

	@media screen and (max-width: ${breakpoint.device.xl}) {
		min-width: calc(1366px + 150px);
	}
`;

const StyledCol = styled(Col)<{
	background?: boolean;
	padding?: string;
	margintop?: string;
	paddingLeft?: string;
	paddingRight?: string;
}>`
	background-color: ${({ background }) => (background ? THEME.colors.darkBlue : 'unset')};
	padding: ${({ padding }) => `${padding} !important` || 'unset'};
	margin-top: ${({ margintop }) => margintop || 'unset'};
	padding-left: ${({ paddingLeft }) => `${paddingLeft} !important` || 'unset'};
	padding-right: ${({ paddingRight }) => `${paddingRight} !important` || 'unset'};
`;

const fontText = () => `
	width: 100%;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: ${THEME.colors.gray5};

	display: flex;
	align-items: center;
`;

const StyledTitle = styled.div<{
	fontSize?: string;
	fontWeight?: string | number;
}>`
	${fontText()}

	padding: 16px;
	border-bottom: ${`1px solid ${THEME.colors.darkBlue3}`};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
`;

const StyleSubTitle = styled.div<{
	fontSize?: string;
	fontWeight?: string | number;
	paddingTop?: string | number;
}>`
	${fontText()}

	padding-top: 12px;
	padding-bottom: 16px;
	line-height: 1.43;
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
`;

const StyledWrapperBox = styled.div<{
	padding?: string;
	calcHeight?: string;
}>`
	width: 100%;
	${({ padding }) => {
		if (padding) return `padding: ${padding}px`;
		return 'padding: 16px';
	}};

	${({ calcHeight }) => {
		// Calculation rule
		// 1) header page: 130px
		// 2) whole page: 100vh
		// 3) elements (label text, input,...): ${calcHeight}px
		if (calcHeight) return `height: calc(100vh - ${calcHeight}px - 130px)`;
		return 'height: auto';
	}};
	overflow-y: auto;
`;

const StyledForm = styled(({ ...props }) => <Form {...props} />)`
	.ant-form-item {
		width: 100%;
	}
	.ant-form-item-label {
		padding: 0;
	}
`;

const StyledFormItem = styled(({ width, ...props }) => <Form.Item {...props} />)`
	width: ${({ width }) => `${width} !important` || 'auto'};

	.ant-form-item-label > label {
		${fontText()}
		font-size: 1rem;
		line-height: 1.43;
		font-weight: normal;
	}

	.ant-form-item-label
		> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}

	.ant-form-item-explain,
	.ant-form-item-explain-error {
		position: relative;
		left: 0;
		bottom: 0;
		z-index: 9999;
	}
`;

const StyledFlex = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
`;

const StyledInput = styled(Input)`
	height: 32px;
	padding: 0 12px;
	border: ${`1px solid ${THEME.colors.darkBlue3}`};

	::placeholder {
		color: ${THEME.colors.darkBlue5};
	}
`;

const StyledSelect = styled(({ ...props }) => <Select {...props} />)`
	width: 100%;
	height: 32px;

	.ant-select-selector {
		border-radius: 2px;
		padding: 0 12px;
		background-color: transparent !important;
		border: ${`1px solid ${THEME.colors.darkBlue3}`};
	}
`;

const StyledButton = styled(Button)`
	width: 32px !important;
	height: 32px;
	margin-left: 12px;
	margin-top: 32px;
	background-color: ${THEME.colors.orangeBase};

	svg {
		path {
			fill: ${THEME.colors.white} !important;
		}
	}
`;

const tagStyle = () => `
	width: fit-content;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 4px 12px;
	border-radius: 20px;
	border: 1px solid transparent;
	cursor: pointer;
	margin-bottom: 12px;
	margin-right: 0;
	color: ${THEME.colors.gray5};
	background-color: ${THEME.colors.darkBlue3};
	font-size: 1rem;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledTag = styled(({ ...props }) => <Tag {...props} />)`
	${tagStyle()}
	margin-bottom: 0;
`;

const StyledDefaultTag = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-items: center;
	background: transparent;
	width: 100%;
`;

const StyledActiveTag = styled(({ ...props }) => <Tag {...props} />)`
	${tagStyle()}
	margin-bottom: 0;
`;

const StyledActionTag = styled.div<{ width: string }>`
	display: none;
	align-items: center;
	width: ${({ width }) => width || '84px'};
	position: absolute;
	right: 0;
	top: 0;
	margin-right: 10px;
`;

const StyledTagInfo = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const StyledActionDefault = styled.div`
	display: inline-flex;
	align-items: center;
`;

const StyledIconTag = styled(({ color, ...props }) => <Tag {...props} />)`
	border: none;
	width: fit-content;
	height: auto;
	background: transparent;
	padding: 0;
	padding-right: 4px;
	padding-left: 12px;
	height: 24px;
	margin-right: 0;

	svg {
		path {
			${({ color }) => color && `fill: ${color}`};
		}
	}

	&.icon_clock {
		svg {
			path {
				fill: ${THEME.colors.gray5};
			}
		}
	}
`;

const StyledSpanIcon = styled.span`
	display: flex;
	align-items: center;
	padding-left: 8px;

	svg {
		transform: scale(0.8);
	}
`;

const StyledSpanTag = styled.span<{ maxWidth?: string }>`
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
`;

const StyledSpanDefaultTag = styled.span`
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
`;

const StyledItemWrapper = styled.div<{ maxWidth?: string }>`
	margin-bottom: 12px;
	&:hover ${StyledTag} {
		width: 100%;
		border: 1px solid ${THEME.colors.orangeBase};
		color: ${THEME.colors.orangeBase};
	}
	&:hover ${StyledActiveTag} {
		width: 100%;
		border: 1px solid ${THEME.colors.orangeBase};
		color: ${THEME.colors.orangeBase};
	}
	&:hover ${StyledActionDefault} {
		display: none;
	}
	&:hover ${StyledSpanDefaultTag} {
		max-width: 90%;
	}
	&:hover ${StyledActionTag} {
		display: flex;
	}
	&:hover ${StyledSpanTag} {
		max-width: ${({ maxWidth }) => maxWidth || '90%'};
	}
	&:hover ${StyledTagInfo} {
		.icon_clock {
			display: none;
		}
	}
`;

const StyledTextUploadCSV = styled.div<{ margin?: string }>`
	color: ${THEME.colors.gray5};
	text-decoration: underline;
	cursor: pointer;
	margin: ${({ margin }) => margin || '0 0 12px 0'};
`;

export {
	StyledWrapper,
	StyledItemWrapper,
	StyledRow,
	StyledCol,
	StyledTitle,
	StyleSubTitle,
	StyledForm,
	StyledWrapperBox,
	StyledInput,
	StyledButton,
	StyledFlex,
	StyledSelect,
	StyledTag,
	StyledDefaultTag,
	StyledActiveTag,
	StyledIconTag,
	StyledSpanIcon,
	StyledSpanTag,
	StyledFormItem,
	StyledActionTag,
	StyledTagInfo,
	StyledActionDefault,
	StyledSectionDiv,
	StyledSpanDefaultTag,
	StyledTextUploadCSV,
};
