import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

// Import i18n
import { I18nextProvider } from 'react-i18next';
import i18n from '@utils/i18n';

// Import store
import store from '@stores/stores';

// Import app
import App from './App';

// Import style
import '@assets/styles/antd.less';
import '@assets/styles/styles.scss';
import './index.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Styled
import THEME from '@constants/themes/themes';
import { ConfigProvider } from 'antd';
import { customizeRenderEmpty } from '@utils/renderComponent';

const GlobalStyles = createGlobalStyle`
  body {}
`;

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store()}>
				<GlobalStyles />
				<ConfigProvider renderEmpty={customizeRenderEmpty}>
					<ThemeProvider theme={THEME}>
						<I18nextProvider i18n={i18n}>
							<App />
						</I18nextProvider>
					</ThemeProvider>
				</ConfigProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
