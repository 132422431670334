import { createAction } from 'redux-actions';
import {
	createDirectMessageTypes,
	deleteDirectMessageTemplateTypes,
	deleteEmailTypes,
	emailTemplateTypes,
	generateCodeTypes,
	getDirectMessageDetailTypes,
	getDirectMessageTemplate,
	getDirectMessageTypes,
	getDynamicFieldsTypes,
	getEmailEvenDetailTypes,
	getEmailEventTypes,
	getEventCreatorTypes,
	getRewardOffersTypes,
	getTemplatesTypes,
	saveAsTemplateTypes,
	saveDraftEmailTypes,
	searchMessageTemplateTypes,
	sendEmailRewardTypes,
	sendTestMailTemplate,
	storeMessageTemplateDataTypes,
	updateDirectMessageTypes,
	updateTemplateTypes,
} from './messages.types';

// Get dynamic fields
export const getDynamicFieldsRequest = createAction(
	getDynamicFieldsTypes.GET_DYNAMIC_FIELDS_REQUEST,
);
export const getDynamicFieldsSucceeded = createAction(
	getDynamicFieldsTypes.GET_DYNAMIC_FIELDS_SUCCEEDED,
);
export const getDynamicFieldsFailed = createAction(getDynamicFieldsTypes.GET_DYNAMIC_FIELDS_FAILED);

// Get templates
export const getTemplatesRequest = createAction(getTemplatesTypes.GET_TEMPLATES_REQUEST);
export const getTemplatesSucceeded = createAction(getTemplatesTypes.GET_TEMPLATES_SUCCEEDED);
export const getTemplatesFailed = createAction(getTemplatesTypes.GET_TEMPLATES_FAILED);

// Update template
export const updateTemplateRequest = createAction(updateTemplateTypes.UPDATE_TEMPLATE_REQUEST);
export const updateTemplateSucceeded = createAction(updateTemplateTypes.UPDATE_TEMPLATE_SUCCEEDED);
export const updateTemplateFailed = createAction(updateTemplateTypes.UPDATE_TEMPLATE_FAILED);

// Send email reward
export const sendEmailRewardRequest = createAction(sendEmailRewardTypes.SEND_EMAIL_REWARD_REQUEST);
export const sendEmailRewardSucceeded = createAction(
	sendEmailRewardTypes.SEND_EMAIL_REWARD_SUCCEEDED,
);
export const sendEmailRewardFailed = createAction(sendEmailRewardTypes.SEND_EMAIL_REWARD_FAILED);
export const sendEmailRewardEnd = createAction(sendEmailRewardTypes.SEND_EMAIL_REWARD_END);

// Save draft email
export const saveDraftEmailRequest = createAction(saveDraftEmailTypes.SAVE_DRAFT_EMAIL_REQUEST);
export const saveDraftEmailSucceeded = createAction(saveDraftEmailTypes.SAVE_DRAFT_EMAIL_SUCCEEDED);
export const saveDraftEmailFailed = createAction(saveDraftEmailTypes.SAVE_DRAFT_EMAIL_FAILED);
export const saveDraftEmailEnd = createAction(saveDraftEmailTypes.SAVE_DRAFT_EMAIL_END);

// Get email event
export const getEmailEvent = createAction(getEmailEventTypes.GET_EMAIL_EVENT);
export const getEmailEventSucceeded = createAction(getEmailEventTypes.GET_EMAIL_EVENT_SUCCEEDED);
export const getEmailEventFailed = createAction(getEmailEventTypes.GET_EMAIL_EVENT_FAILED);

// Get email event detail
export const getEmailEventDetail = createAction(getEmailEvenDetailTypes.GET_EMAIL_EVENT_DETAIL);
export const getEmailEventDetailSucceeded = createAction(
	getEmailEvenDetailTypes.GET_EMAIL_EVENT_DETAIL_SUCCEEDED,
);
export const getEmailEventDetailFailed = createAction(
	getEmailEvenDetailTypes.GET_EMAIL_EVENT_DETAIL_FAILED,
);
export const resetStatusDBLickEmail = createAction(getEmailEvenDetailTypes.RESET_DB_CLICK_GET_MAIL);

// Get event creator
export const getEventCreator = createAction(getEventCreatorTypes.GET_EVENT_CREATOR);
export const getEventCreatorSucceeded = createAction(
	getEventCreatorTypes.GET_EVENT_CREATOR_SUCCEEDED,
);
export const getEventCreatorFailed = createAction(getEventCreatorTypes.GET_EVENT_CREATOR_FAILED);

// Delete email
export const deleteEmail = createAction(deleteEmailTypes.DELETE_EMAIL);
export const deleteEmailSucceeded = createAction(deleteEmailTypes.DELETE_EMAIL_SUCCEEDED);
export const deleteEmailFailed = createAction(deleteEmailTypes.DELETE_EMAIL_FAILED);

// Generate code
export const generateCodeRequest = createAction(generateCodeTypes.GENERATE_CODE_REQUEST);
export const generateCodeSucceeded = createAction(generateCodeTypes.GENERATE_CODE_SUCCEEDED);
export const generateCodeFailed = createAction(generateCodeTypes.GENERATE_CODE_FAILED);
export const generateCodeEnd = createAction(generateCodeTypes.GENERATE_CODE_END);

// Get reward to select offer
export const getRewardOffersRequest = createAction(getRewardOffersTypes.GET_REWARD_OFFERS_REQUEST);
export const getRewardOffersSucceeded = createAction(
	getRewardOffersTypes.GET_REWARD_OFFERS_SUCCEEDED,
);
export const getRewardOffersFailed = createAction(getRewardOffersTypes.GET_REWARD_OFFERS_FAILED);
export const getRewardOffersEnd = createAction(getRewardOffersTypes.GET_REWARD_OFFERS_END);

// *****************************************************************
// ******************* MESSAGE - EMAIL TEMPLATES *******************
// *****************************************************************
export const selectTabFolderEmailTemplate = createAction(
	emailTemplateTypes.SELECT_TAB_FOLDER_EMAIL_TEMPLATE,
);
export const selectAutomatedEmailTemplate = createAction(
	emailTemplateTypes.SELECT_AUTOMATED_EMAIL_TEMPLATE,
);
export const selectCustomEmailTemplate = createAction(
	emailTemplateTypes.SELECT_CUSTOM_EMAIL_TEMPLATE,
);
export const selectArchivedEmailTemplate = createAction(
	emailTemplateTypes.SELECT_ARCHIVED_EMAIL_TEMPLATE,
);

export const getEmailTemplatesRequest = createAction(
	emailTemplateTypes.GET_EMAIL_TEMPLATES_REQUEST,
);
export const createEmailTemplatesRequest = createAction(
	emailTemplateTypes.CREATE_EMAIL_TEMPLATES_REQUEST,
);
export const updateEmailTemplatesRequest = createAction(
	emailTemplateTypes.UPDATE_EMAIL_TEMPLATES_REQUEST,
);

export const restoreEmailTemplatesRequest = createAction(
	emailTemplateTypes.RESTORE_EMAIL_TEMPLATES_REQUEST,
);
export const restoreEmailTemplatesSucceeded = createAction(
	emailTemplateTypes.RESTORE_EMAIL_TEMPLATES_SUCCEEDED,
);
export const restoreEmailTemplatesFailed = createAction(
	emailTemplateTypes.RESTORE_EMAIL_TEMPLATES_FAILED,
);

export const enableTemplateAction = createAction(
	emailTemplateTypes.ENABLE_TEMPLATES_ACTION_REQUEST,
);

export const updateEmailTemplatesSucceeded = createAction(
	emailTemplateTypes.UPDATE_EMAIL_TEMPLATES_SUCCEEDED,
);

export const createEmailTemplatesSucceeded = createAction(
	emailTemplateTypes.CREATE_EMAIL_TEMPLATES_SUCCEEDED,
);

export const getEmailTemplatesSucceeded = createAction(
	emailTemplateTypes.GET_EMAIL_TEMPLATES_SUCCEEDED,
);
export const getEmailTemplatesFailed = createAction(emailTemplateTypes.GET_EMAIL_TEMPLATES_FAILED);

export const sendTestMailEnd = createAction(sendTestMailTemplate.SEND_TEST_MAIL_END);

export const sendTestMailRequest = createAction(sendTestMailTemplate.SEND_TEST_MAIL_REQUEST);

export const sendTestMailSucceeded = createAction(sendTestMailTemplate.SEND_TEST_MAIL_SUCCEEDED);

export const sendTestMailFailed = createAction(sendTestMailTemplate.SEND_TEST_MAIL_FAILED);

// Save as template
export const saveAsTemplateRequest = createAction(saveAsTemplateTypes.SAVE_AS_TEMPLATE_REQUEST);
export const saveAsTemplateSucceeded = createAction(saveAsTemplateTypes.SAVE_AS_TEMPLATE_SUCCEEDED);
export const saveAsTemplateFailed = createAction(saveAsTemplateTypes.SAVE_AS_TEMPLATE_FAILED);
export const saveAsTemplateEnd = createAction(saveAsTemplateTypes.SAVE_AS_TEMPLATE_END);

// Get direct message
export const getDirectMessageRequest = createAction(
	getDirectMessageTypes.GET_DIRECT_MESSAGE_REQUEST,
);
export const getDirectMessageSucceeded = createAction(
	getDirectMessageTypes.GET_DIRECT_MESSAGE_SUCCEEDED,
);
export const getDirectMessageFailed = createAction(getDirectMessageTypes.GET_DIRECT_MESSAGE_FAILED);
export const getDirectMessageEnd = createAction(getDirectMessageTypes.GET_DIRECT_MESSAGE_END);

// Get direct message
export const getDirectMessageDetail = createAction(
	getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL,
);
export const getDirectMessageDetailSucceeded = createAction(
	getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL_SUCCEEDED,
);
export const getDirectMessageDetailFailed = createAction(
	getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL_FAILED,
);
export const getDirectMessageDetailEnd = createAction(
	getDirectMessageDetailTypes.GET_DIRECT_MESSAGE_DETAIL_END,
);

// Update direct message
export const updateDirectMessageRequest = createAction(
	updateDirectMessageTypes.UPDATE_DIRECT_MESSAGE_REQUEST,
);
export const updateDirectMessageSucceeded = createAction(
	updateDirectMessageTypes.UPDATE_DIRECT_MESSAGE_SUCCEEDED,
);
export const updateDirectMessageFailed = createAction(
	updateDirectMessageTypes.UPDATE_DIRECT_MESSAGE_FAILED,
);
export const updateDirectMessageEnd = createAction(
	updateDirectMessageTypes.UPDATE_DIRECT_MESSAGE_END,
);

// Create direct message
export const createDirectMessageRequest = createAction(
	createDirectMessageTypes.CREATE_DIRECT_MESSAGE_REQUEST,
);
export const createDirectMessageSucceeded = createAction(
	createDirectMessageTypes.CREATE_DIRECT_MESSAGE_REQUEST,
);
export const createDirectMessageFailed = createAction(
	createDirectMessageTypes.CREATE_DIRECT_MESSAGE_FAILED,
);

// *************************************************************************
// ******************* MESSAGE - DIRECT MASSAGE TEMPLATE *******************
// *************************************************************************

export const getDirectMessageTemplateRequest = createAction(
	getDirectMessageTemplate.GET_DIRECT_MESSAGE_TEMPLATE_REQUEST,
);

export const getDirectMessageTemplateSuccessed = createAction(
	getDirectMessageTemplate.GET_DIRECT_MESSAGE_TEMPLATE_SUCCESSED,
);
export const getDirectMessageTemplateFailed = createAction(
	getDirectMessageTemplate.GET_DIRECT_MESSAGE_TEMPLATE_FAILED,
);

export const getSelectKeyDirectTemplate = createAction(
	getDirectMessageTemplate.GET_SELECTED_KEY_DM_TEMPLATE,
);

export const deleteDMTemplateRequest = createAction(
	deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_REQUEST,
);

export const deleteDMTemplateSucceeded = createAction(
	deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_SUCCEEDED,
);

export const deleteDMTemplateFailed = createAction(
	deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_FAILED,
);

export const deleteDMTemplateEnd = createAction(
	deleteDirectMessageTemplateTypes.DELETE_DM_TEMPLATE_END,
);

export const createDMTemplateRequest = createAction(
	getDirectMessageTemplate.CREATE_DM_TEMPLATE_REQUEST,
);

export const createDMTemplateSuccessed = createAction(
	getDirectMessageTemplate.CREATE_DM_TEMPLATE_SUCCESSED,
);

export const createDMTemplateFailed = createAction(
	getDirectMessageTemplate.CREATE_DM_TEMPLATE_FAILED,
);

export const searchMessageTemplate = createAction(
	searchMessageTemplateTypes.SEARCH_MESSAGE_TEMPLATE,
);

// is used to store or reset any data
export const storeMessageTemplateData = createAction(
	storeMessageTemplateDataTypes.STORE_MESSAGE_TEMPLATE_DATA,
);
