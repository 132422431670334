import styled from 'styled-components';
import THEME from '@constants/themes/themes';
import { Radio, Select, Skeleton } from 'antd';

export const StyledChartWrapper = styled.div<{ isFilterByCampaign?: boolean }>`
	width: 100%;
	display: flex;
	height: 353px;
	flex-direction: row;
	justify-content: space-between;
	border-radius: 8px;
	overflow: hidden;
	&.h_full_submission {
		height: 722px;
	}

	.card-content,
	.chart-by-date,
	.chart-by-location {
		background-color: ${({ isFilterByCampaign }) =>
			isFilterByCampaign ? '#181d27 !important' : `${THEME.colors.darkBlue2} !important`};
	}

	.chart-by-date,
	.chart-by-location {
		position: relative;
		width: 100%;
		padding: 12px;

		.chart-title {
			display: flex;
			align-items: center;
			height: 32px;
			margin-bottom: 16px;
			justify-content: space-between;
		}

		.draw-chart {
			width: 97%;
			height: 265px;
			.recharts-surface {
				overflow: inherit !important;
			}
		}
	}

	.chart-by-location {
		.chart-title {
			.range-color {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.country-map {
			svg {
				width: inherit;
			}
		}

		.zoom-map {
			display: flex;
			flex-direction: column;
			position: absolute;
			bottom: 24px;
			right: 16px;

			svg {
				cursor: pointer;

				&:first-child {
					margin-bottom: 8px;
				}
			}
		}
	}
`;

export const StyledSelect = styled(({ ...props }) => <Select {...props} />)`
	width: 150px;
	margin: 0 10px;

	.ant-select-selector {
		background-color: ${({ isFilterByCampaign }) => isFilterByCampaign && '#181d27 !important'};
	}
`;

export const StyledTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.143rem;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	box-sizing: content-box;
	height: 22px;
	&.content_by_date {
		display: block;
		max-width: calc(100% - 400px);
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export const StyledRadioGroup = styled(Radio.Group)<{ isFilterByCampaign?: boolean }>`
	height: 32px;

	label {
		justify-content: center;
		border-color: ${THEME.colors.darkBlue4} !important;
		height: 100% !important;
		background: ${({ isFilterByCampaign }) =>
			isFilterByCampaign ? '#181d27 !important' : `${THEME.colors.darkBlue2} !important`};

		&.ant-radio-button-wrapper-checked {
			border-color: ${THEME.colors.orangeBase} !important;
		}
	}
`;

export const StyledTextXAxis = styled.text`
	white-space: nowrap;
`;

export const StyledSkeleton = styled(Skeleton)<{ isFilterByCampaign?: Boolean }>`
	background-color: ${({ isFilterByCampaign }) =>
		isFilterByCampaign ? '#181d27 !important' : `${THEME.colors.darkBlue2} !important`};
	height: 100%;
	padding: 12px;
`;
