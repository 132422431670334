import THEME from '@constants/themes/themes';
import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

const StyledWrapperTemplateDetail = styled.div``;

const StyledSiderTemplate = styled(Sider)`
	background-color: ${THEME.colors.darkBlue2};
	height: calc(100vh - 80px);
`;

const StyledBodySider = styled.div`
	padding: 16px 24px;
	height: 100%;
	overflow: hidden auto;
	&.has_footer {
		height: calc(100% - 56px);
	}
`;

const StyleStatus = styled.div`
	height: 36px;
	border-radius: 8px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&.pointer {
		cursor: pointer;
	}
	&.draft_status {
		background: rgba(250, 173, 20, 0.08);
	}
	&.publish_status {
		background: rgba(82, 196, 26, 0.08);
	}
	&.archive_status {
		background: rgba(234, 67, 53, 0.08);
	}
	&.warning {
		background: rgba(24, 144, 255, 0.08);
	}
`;

const StyledWrapperNameTerms = styled.div<{ width?: string; margin?: string }>`
	background: ${THEME.colors.darkBlue3};
	height: 54px;
	display: inline-flex;
	align-items: center;
	padding: 16px;
	color: ${THEME.colors.gray5};
	width: ${({ width }) => width || 'calc(100% - 40px)'};
	${({ margin }) => margin && `margin: ${margin}`};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: 0.15s ease-out;
	border-radius: 8px;
	cursor: pointer;

	&.disabled {
		pointer-events: none;
	}
	&.active {
		background: rgba(226, 88, 43, 0.7);
	}
`;

const StyledBtnAddTerms = styled.div`
	background: ${THEME.colors.darkBlue3};
	height: 46px;
	padding: 11px;
	width: 100%;
	display: inline-flex;
	align-items: center;
	border-radius: 8px;
	margin-top: 16px;
	font-size: 14px;
	color: ${THEME.colors.gray5};
	cursor: pointer;
`;

const StyledWrapperContentLicensingTerms = styled.div<{ space: number }>`
	max-height: ${({ space }) => `calc(100% - ${space}px)`};
	background-color: ${THEME.colors.darkBlue3};
	border-radius: 8px;
	&.set_height {
		height: ${({ space }) => `calc(100% - ${space}px)`};
	}
`;
const StyledBodyLicensingTerms = styled.div<{
	space: number;
	wrapperHeight: string;
	moreSpace: number;
}>`
	max-height: ${({ space, wrapperHeight, moreSpace }) =>
		`calc(${wrapperHeight} - 151px - ${space}px + ${moreSpace}px)`};
	overflow: auto;
	width: 100%;
	padding: 16px 24px 24px 24px;
`;

const StyledWidgetItem = styled.div`
	width: 100%;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export {
	StyledWrapperTemplateDetail,
	StyledSiderTemplate,
	StyledBodySider,
	StyleStatus,
	StyledWrapperNameTerms,
	StyledBtnAddTerms,
	StyledWrapperContentLicensingTerms,
	StyledBodyLicensingTerms,
	StyledWidgetItem,
};
