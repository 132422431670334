import styled, { css } from 'styled-components';
import {
	IconLock,
	IconAddCircle,
	IconRssFeed,
	IconRemoveCircle,
	IconCampaignOutLine,
} from '@assets/icons';
import THEME from '@constants/themes/themes';
import { Drawer, Popover } from 'antd';

const StyledStatus = styled.div`
	display: flex;
	flex-decoration: row;
	align-items: center;
`;

const StyledIcon = css`
	width: 20px;
	height: 20px;
	margin-right: 8px;

	&:last-child {
		margin-right: 0;
	}
`;

const StyledLock = styled(IconLock)<{ lock?: boolean }>`
	${StyledIcon}
	fill: ${({ lock }) => (lock ? THEME.colors.redBase : THEME.colors.gray2)} !important;
	display: ${({ lock }) => (lock ? 'block' : 'none')} !important;
`;

const StyledAdd = styled(IconAddCircle)<{ newSts?: boolean }>`
	${StyledIcon}
	fill: ${({ newSts }) => (newSts ? THEME.colors.blueBase : THEME.colors.gray2)} !important;
	display: ${({ newSts }) => (newSts ? 'block' : 'none')} !important;
`;

const StyledIconRemove = styled(IconRemoveCircle)<{ remove?: boolean }>`
	${StyledIcon}
	fill: ${({ remove }) => (remove ? THEME.colors.blueBase : THEME.colors.gray2)} !important;
	display: ${({ remove }) => (remove ? 'block' : 'none')} !important;
`;

const StyledRss = styled(IconRssFeed)<{ follow?: boolean }>`
	${StyledIcon}
	fill:  ${({ follow }) => (follow ? THEME.colors.greenBase : THEME.colors.gray2)} !important;
	display: ${({ follow }) => (follow ? 'block' : 'none')} !important;
`;

const StyleUnSubscribe = styled(IconCampaignOutLine)<{ unsubscribe?: boolean }>`
	${StyledIcon}
	fill:  ${({ unsubscribe }) =>
		unsubscribe ? THEME.colors.greenBase : THEME.colors.gray2} !important;
	display: ${({ unsubscribe }) => (unsubscribe ? 'block' : 'none')} !important;
`;

const StyledPopover = styled(Popover)``;

const StyledDrawer = styled(Drawer)`
	position: absolute;
	padding: 0 8px;
	z-index: 99;

	&.hidden_border {
		.ant-drawer-header {
			border: 0 !important;
		}
		&.pt_16 {
			.ant-drawer-content-wrapper {
				padding-top: 16px;
			}
		}
	}

	&.ant-drawer-open {
		.ant-drawer-content-wrapper {
			width: calc(100% - 16px) !important;
			right: 8px;
		}
	}

	.ant-drawer-content-wrapper {
		width: 100% !important;
		height: 100%;
		right: -16px;

		.ant-drawer-header-no-title {
			.ant-drawer-close {
				margin: 15px 6px 0 0;
			}
		}
	}

	.ant-drawer-body {
		padding: 0;
		overflow: hidden;
	}
`;

const StyledWrapperBox = styled.div<{ margin?: string }>`
	border: 2px solid ${THEME.colors.transparent};
	border-radius: 2px;
	${({ margin }) => margin && `margin: ${margin}`};
	&.active_box {
		border-color: ${THEME.colors.orangeBase};
	}
`;

const StyledMessageItemCreator = styled.div`
	width: 100%;
	padding: 6px 7px;
	border-radius: 2px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	margin-bottom: 4px;
	&:last-child {
		margin-bottom: 0px;
	}
	&:hover {
		background-color: rgba(226, 88, 43, 0.3);
	}
	&.active {
		background-color: rgba(226, 88, 43, 0.3);
		cursor: unset;
	}
`;

const StyledWrapperMailHistoryInfo = styled.div`
	width: 100%;
	max-height: 100%;
	overflow: auto;
	background-color: ${THEME.colors.white};
	color: ${THEME.colors.black};
	padding-bottom: 32px;
`;

export {
	StyledStatus,
	StyledIcon,
	StyledLock,
	StyledAdd,
	StyledIconRemove,
	StyledRss,
	StyleUnSubscribe,
	StyledPopover,
	StyledDrawer,
	StyledMessageItemCreator,
	StyledWrapperBox,
	StyledWrapperMailHistoryInfo,
};
