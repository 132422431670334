import * as React from 'react';

export type UserProfile = {
	setIsLoading: (isLoading: boolean) => void;
};

// Default context values
const values: UserProfile = {
	setIsLoading: () => {},
};

const UserProfileContext = React.createContext(values);
export default UserProfileContext;
