import { Table } from 'antd';
import React, {
	forwardRef,
	Ref,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
// constants
import THEME from '@constants/themes/themes';
// styled
import { CLASS_KEEP_SHIFT, ID_TABLE_CUSTOM, TIME_OUT } from '@constants/common';
import { RefRewardList } from '@models/content/contentLibrary/ref';
import _ from 'lodash';
import { createSelectable, DeselectAll, SelectableGroup, SelectAll } from 'react-selectable-fast';
import styled from 'styled-components';
import { handleSelectItemWithLib, handleSelectMultiWithLib, sortListIds } from '@utils/funcHelper';
import { SelectionCustomRef } from '@models/common/ref';
import CustomSelection from '@components/CustomLibrary/CustomSelection';

export const StyledTable = styled(Table)`
	margin-top: 16px;
	.ant-table {
		display: block;
		max-width: -moz-fit-content;
		max-width: fit-content;
		margin: 0 auto;
		overflow-x: auto;
		white-space: nowrap;
		.ant-table-selection-column {
			width: 50px;
		}

		.ant-table-header {
			.ant-table-selection-column {
				.ant-checkbox-wrapper {
					.ant-checkbox {
						margin-bottom: 5px !important;
					}
				}
			}
		}

		.ant-table-cell {
			&.recently-added {
				overflow: unset;
			}
		}

		.ant-table-tbody {
			.ant-table-selection-column {
				.ant-checkbox-wrapper {
					.ant-checkbox {
						margin: 0;
					}
				}
			}
		}

		.ant-checkbox-wrapper {
			display: inline-flex;
			align-items: center;
		}

		.ant-table-thead {
			.selectable-select-all {
				margin: 0;
				.ant-table-cell {
					display: flex;
					align-items: center;
					background: ${THEME.colors.darkBlue3};
				}
			}
		}
		&.${CLASS_KEEP_SHIFT} {
			.ant-table-cell {
				.ant-checkbox-wrapper {
					pointer-events: none;
				}
			}
		}
	}

	.ant-spin-nested-loading {
		> div {
			> .ant-spin {
				max-height: unset;
			}
		}
	}

	.ant-table-cell-ellipsis .ant-table-column-title {
		overflow: unset;
	}

	.ant-table-column-sorter-down.active,
	.ant-table-column-sorter-up.active {
		svg {
			fill: ${THEME.colors.orangeBase} !important;
		}
	}
`;

const StyleRowTable = styled.tr`
	&:not(.row-selected) {
		&:not(.row-selecting) {
			.ant-checkbox {
				.ant-checkbox-inner {
					background-color: transparent;
					border-color: ${THEME.colors.white};
				}
				&:hover {
					.ant-checkbox-inner {
						border-color: ${THEME.colors.orangeBase};
					}
				}
			}
		}
		&.row-selecting {
			.ant-checkbox {
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 1px solid ${THEME.colors.orangeBase};
					border-radius: 2px;
					visibility: hidden;
					content: '';
				}
				.ant-checkbox-inner {
					background-color: ${THEME.colors.orangeBase};
					border-color: ${THEME.colors.orangeBase};
					&::after {
						position: absolute;
						display: table;
						border: 2px solid #011627;
						border-top: 0;
						border-left: 0;
						transform: rotate(45deg) scale(1) translate(-50%, -50%);
						opacity: 1;
						transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
						content: ' ';
					}
				}
			}
		}
	}
`;

// The condition to use SHIFT event => ensure to a component is rendered completely
const CustomTable = forwardRef((props: any, ref: Ref<RefRewardList>) => {
	const {
		onDoubleClickRow,
		handleSelection,
		dataSource,
		otherPropSelectGroup = {},
		rowSelection = {},
		clearSelection = true,
		keyId = 'id',
		handleSelectWithShift,
		onClickRow: onClickRowProps,
		id: tableId = ID_TABLE_CUSTOM,
		...other
	} = props;

	const selectionRef = useRef<any>({});
	const refEventSelect = useRef<
		SelectionCustomRef | undefined
	>() as React.RefObject<SelectionCustomRef>;
	const refClick = useRef<any>(false);

	const [keepShift, setKeepShift] = useState(false);

	const hasSelectionShift = handleSelectWithShift && typeof handleSelectWithShift === 'function';

	const onCheckDBClick = (record: any) => {
		if (
			dataSource &&
			dataSource?.length > 0 &&
			onDoubleClickRow &&
			typeof onDoubleClickRow === 'function'
		) {
			return onDoubleClickRow(record);
		}

		return null;
	};

	const onChangeSelect = (record: any) => {
		refClick.current = true;

		onSelectItems(record[keyId]);
	};

	const onCheckDataEmpty = (listSelection: any[]) => {
		if (!dataSource || dataSource?.length === 0) {
			return null;
		}
		if (handleSelection && typeof handleSelection === 'function') {
			handleSelection(
				listSelection,
				refEventSelect?.current?.isShift,
				refEventSelect?.current?.handleSectionCustom,
			);
		}

		return null;
	};

	useImperativeHandle(ref, () => ({
		clearSelectionUsingRef,
	}));

	const clearSelectionUsingRef = () => {
		if (selectionRef) {
			selectionRef?.current?.clearSelection();
		}
	};

	const onSelectItems = (id?: number | string) => {
		handleSelectItemWithLib(selectionRef, id);
	};

	const onSelectList = (ids: number[]) => {
		selectionRef.current?.registry?.forEach((regis: any) => {
			if (ids?.includes(regis?.props?.id)) {
				regis.state.isSelected = true;
				selectionRef.current.selectedItems.add(regis);
			}
		});
		const newList = Array.from(selectionRef.current.selectedItems);

		return selectionRef.current.props.onSelectionFinish(newList);
	};

	useEffect(() => {
		if (clearSelection) {
			clearSelectionUsingRef();
		} else {
			const result: number[] = [];
			rowSelection?.selectedRowKeys?.map((key: number) => {
				if (dataSource?.some((item: any) => item?.key === key)) {
					result.push(key);
				}
			});
			onSelectList(result);
		}
	}, [dataSource]);

	const rowSelectionProps = () => {
		if (!_.isEmpty(rowSelection)) {
			return {
				rowSelection: {
					...rowSelection,
					onSelect: onChangeSelect,
					onChange: (selectItems: any[], val2: any) => {
						if (
							keepShift &&
							!refClick.current &&
							refEventSelect?.current?.handleSectionCustom &&
							typeof refEventSelect?.current?.handleSectionCustom === 'function'
						) {
							if (selectItems.length >= rowSelection.selectedRowKeys.length) {
								refEventSelect?.current?.handleSectionCustom(selectItems);
							} else {
								const ids: Array<KeyType> = dataSource.map(
									(item: any) => item[keyId],
								);
								const sortIds = sortListIds(ids, selectItems);
								const idxEnd = ids.findIndex(
									(id) => id === sortIds[sortIds.length - 1],
								);
								if (idxEnd !== -1) {
									const newSelected = [...sortIds, ids[idxEnd + 1]];
									refEventSelect?.current?.handleSectionCustom(
										newSelected,
										ids[idxEnd + 1],
									);
								}
							}
						}
						refClick.current = false;
					},
				},
			};
		}

		return {};
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		if (hasSelectionShift) {
			handleSelectWithShift(list);
		}
	};

	const handleClickRow = (id?: number | string) => {
		if (
			onClickRowProps &&
			typeof onClickRowProps === 'function' &&
			!refEventSelect.current?.isShift
		) {
			onClickRowProps(id);
			return;
		}
		onSelectItems(id);
	};

	return (
		<>
			{hasSelectionShift && (
				<CustomSelection
					ref={refEventSelect}
					ids={dataSource.map((item: any) => item[keyId])}
					selectIds={rowSelection.selectedRowKeys}
					refSelection={selectionRef}
					handleSelectNewList={handleSelectNewList}
					elmAddClass={document.getElementById(tableId)}
					keepShift={keepShift}
					setKeepShift={setKeepShift}
				/>
			)}
			<SelectableGroup
				className="selectable-group"
				clickClassName="tick"
				ref={selectionRef}
				enableDeselect={true}
				style={{ padding: 0 }}
				tolerance={0}
				deselectOnEsc={true}
				selectOnClick={false}
				onSelectionFinish={onCheckDataEmpty}
				{...otherPropSelectGroup}>
				<StyledTable
					id={tableId}
					components={{ body: { row: RowTable }, header: { row: HeaderTable } }}
					dataSource={dataSource}
					onRow={(record: any, rowIndex: any) => ({
						onDoubleClickRow: () => onCheckDBClick(record),
						onClick: handleClickRow,
						id: record[keyId],
						keepShift:
							keepShift &&
							handleSelectWithShift &&
							typeof handleSelectWithShift === 'function',
					})}
					onHeaderRow={(column: any, rowIndex: any) => ({
						isSelectAll:
							dataSource?.length === selectionRef?.current?.selectedItems?.size,
					})}
					{...rowSelectionProps()}
					{...other}
				/>
			</SelectableGroup>
		</>
	);
});

const RowSession = (props: any) => {
	const {
		children,
		selectableRef,
		onDoubleClickRow,
		className,
		isSelecting,
		isSelected,
		onClick,
		keepShift,
	} = props;

	const refTime = useRef<any>({});
	const refMove = useRef<any>(false);
	const refTimeMove = useRef<any>({});

	const [isDrag, setIsDrag] = useState(false);

	const onClickRow = () => {
		if (!onClick) {
			return null;
		}

		return onClick(props?.id);
	};

	const classNameRow = [
		'row-item',
		isSelecting && 'row-selecting',
		isSelected && 'row-selected',
		keepShift ? 'not_event_checkbox' : '',
		className,
	]
		.filter(Boolean)
		.join(' ');

	const hasDoubleClick = onDoubleClickRow && typeof onDoubleClickRow === 'function';

	const onDBClickRow = () => {
		if (hasDoubleClick) {
			onDoubleClickRow();
		}
	};

	return (
		<StyleRowTable
			className={classNameRow}
			ref={selectableRef}
			onClick={(e: React.MouseEvent<HTMLDivElement>) => {
				refMove.current = false;
				if (refTime.current) {
					clearTimeout(refTime.current);
				}
				if (refTimeMove.current) {
					clearTimeout(refTimeMove.current);
				}
				if (isDrag) {
					return;
				}
				e.preventDefault();
				e.stopPropagation();

				if (keepShift || !hasDoubleClick) {
					onClickRow();
					return;
				}

				refTime.current = setTimeout(() => {
					if (e.detail === 1) {
						onClickRow();
					}
				}, TIME_OUT.DELAY_SELECT);

				if (e.detail === 2) {
					onDBClickRow();
				}
			}}
			onMouseDown={() => {
				refMove.current = true;
				if (isDrag) {
					setIsDrag(false);
				}
			}}
			onMouseUp={() => {
				refMove.current = false;
			}}
			onMouseMove={() => {
				if (refMove.current && !isDrag) {
					refTimeMove.current = setTimeout(() => {
						setIsDrag(true);
					}, 80);
				}
			}}>
			{children}
		</StyleRowTable>
	);
};

const SelectItem = createSelectable(RowSession);

const HeaderTable = (props: any) => {
	const { isSelectAll } = props;

	return (
		<tr>
			{props?.children?.map((ceil: any, idx: number) => {
				if (idx === 0) {
					const SessionCheckBox = isSelectAll ? DeselectAll : SelectAll;
					return (
						<SessionCheckBox key={Math.random()} className="selectable-button">
							{ceil}
						</SessionCheckBox>
					);
				}

				return ceil;
			})}
		</tr>
	);
};

const RowTable = (props: any) => {
	const { children, onDoubleClickRow, className, onClick, ...other } = props;

	return (
		<SelectItem
			onDoubleClickRow={onDoubleClickRow}
			onClick={onClick}
			className={className}
			{...other}>
			{children}
		</SelectItem>
	);
};

export default CustomTable;
