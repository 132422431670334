const INTEGRATIONS_TAB = {
	TREMENDOUS: 'TREMENDOUS',
};

const LIST_INTEGRATIONS_TAB = [
	{
		value: INTEGRATIONS_TAB.TREMENDOUS,
		name: 'tremendous.tab_tremendous',
	},
];

const DEFAULT_HIDE_KEY = '*';

const KEY_FIELD_TREMENDOUS = {
	AMOUNT: 'rewardAmount',
	CAMPAIGN: 'campaignId',
	FUNDING_SOURCE: 'fundingSourceId',
	API_KEY: 'apiKey',
};

const KEY_POPUP_TREMENDOUS = {
	AMOUNT: 'rewardAmount',
	CAMPAIGN: 'campaignId',
	TYPE_SEND: 'deliveryMethod',
	NAME: 'name',
	EMAIL: 'email',
	PHONE_NUMBER: 'phone',
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
};

const SEND_TYPE = {
	EMAIL: 'EMAIL',
	SMS: 'SMS',
};

const DEFAULT_ORDER_TREMENDOUS = {
	deliveryMethod: SEND_TYPE.EMAIL,
};

const LIST_OPTION_SEND = [
	{
		value: SEND_TYPE.EMAIL,
		name: 'tremendous.send_via_email',
	},
	{
		value: SEND_TYPE.SMS,
		name: 'tremendous.send_via_text',
	},
];

const STATUS_TREMENDOUS = {
	APPROVED: 'APPROVED',
};

const WIDTH_API_KEY = 334;

export {
	INTEGRATIONS_TAB,
	LIST_INTEGRATIONS_TAB,
	DEFAULT_HIDE_KEY,
	KEY_FIELD_TREMENDOUS,
	KEY_POPUP_TREMENDOUS,
	LIST_OPTION_SEND,
	SEND_TYPE,
	WIDTH_API_KEY,
	STATUS_TREMENDOUS,
	DEFAULT_ORDER_TREMENDOUS,
};
