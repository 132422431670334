import THEME from '@constants/themes/themes';
import { StyledIcon } from '@styled/Common/CommonStyled';
import styled from 'styled-components';

const StyledContainerFeeds = styled.div`
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue2};
	margin-top: 12px;
	height: calc(100vh - 158px);
	.selectable-group {
		padding: 0;
	}
	.wrapper_bookmark_feed {
		height: 100%;
		.container_bookmark_feed {
			height: calc(100% - 118px);
		}
	}
`;

const StyledScrollContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden overlay;
`;

const StyledContainerSummary = styled.div`
	padding: 12px 34px;
	width: 100%;
	background-color: ${THEME.colors.darkBlue2};
`;

const StyledContainerMetrics = styled.div`
	margin: 0 -6px;
	display: flex;
`;

const StyledCardFeed = styled.div<{ bgColor?: string }>`
	border-radius: 8px;
	background-color: ${({ bgColor }) => bgColor || THEME.colors.darkBlue2};
	padding: 14px 14px 14px 28px;
	display: flex;
	align-items: center;
	&.disabled {
		pointer-events: none;
		opacity: 0.7;
		background-color: ${THEME.colors.darkBlue3};
	}
`;

const StyledWrapperItemCard = styled.div<{ padding?: string; textAlign?: string }>`
	width: 20%;
	padding: ${({ padding }) => padding || '0 6px'};
	text-align: ${({ textAlign }) => textAlign || 'left'};
`;

const StyledInfoCard = styled.div`
	color: ${THEME.colors.gray5};
	margin-left: 14px;
	width: calc(100% - 60px);
`;

const StyledNameCard = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
const StyledValueCard = styled.div`
	font-weight: 400;
	font-size: 24px;
	line-height: 34px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledWrapperActionBar = styled.div`
	display: flex;
	justify-content: end;
	padding: 16px 34px;
	height: 64px;
	transition: 0.2s ease-out;
	position: absolute;
	width: 100%;
	z-index: 100;
	right: 0;
	background-color: ${THEME.colors.darkBlue2};
	&.hidden {
		visibility: hidden;
		height: 0;
		padding: 0;
	}
`;

const StyledCardSocial = styled.div<{ height?: number }>`
	border-radius: 4px;
	overflow: hidden;
	height: ${({ height }) => height || 0}px;
	display: flex;
	width: 100%;
	background-color: ${THEME.colors.darkBlue3};
	transition: width 1s, height 1s;
	flex-direction: unset;
`;

const StyledWrapperInfoSocial = styled.div<{ widthExclude?: number }>`
	height: 100%;
	width: calc(100% - ${({ widthExclude }) => widthExclude || 0}px);
	padding: 12px 10px 12px 12px;
	color: ${THEME.colors.white};
	transition: width 1s, height 1s;
	position: relative;
	&.hide_text {
		visibility: hidden;
	}

	@media screen and (max-width: 1400px) {
		padding: 8px;

		.timestamp {
			bottom: 2px;
		}
	}
`;

const StyledWrapperThumb = styled.div<{ width?: string }>`
	height: 100%;
	width: ${({ width }) => width || '0px'};
	transition: 1s ease-out;
	position: relative;
`;

const StyledThumbSocial = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const StyledWrapperInfoUser = styled.div`
	display: flex;
	align-items: center;
`;

const StyledClearSelection = styled.span`
	cursor: pointer;
	text-decoration: underline;
	margin-left: 4px;
	font-weight: bold;
`;

const StyledGoHere = styled.span`
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	&:hover {
		color: ${THEME.colors.orangeBase};
	}
`;

const StyledWrapperMetrics = styled.div`
	&,
	* {
		font-size: 12px;
		color: ${THEME.colors.gray3};
	}
`;

const StyledIconBookmark = styled(StyledIcon)`
	position: absolute;
	top: -3.5px;
	left: 8px;
`;

const StyledTimestamp = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: calc(100% - 28px);
	color: ${THEME.colors.gray1};
`;

export {
	StyledCardFeed,
	StyledCardSocial,
	StyledClearSelection,
	StyledContainerFeeds,
	StyledContainerMetrics,
	StyledContainerSummary,
	StyledGoHere,
	StyledInfoCard,
	StyledNameCard,
	StyledScrollContainer,
	StyledThumbSocial,
	StyledValueCard,
	StyledWrapperActionBar,
	StyledWrapperInfoSocial,
	StyledWrapperInfoUser,
	StyledWrapperItemCard,
	StyledWrapperMetrics,
	StyledWrapperThumb,
	StyledIconBookmark,
	StyledTimestamp,
};
