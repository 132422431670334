import { IconFlag, IconInstagram, IconTikTok, IconTwitterWhite, IconYoutube } from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import {
	CHANGED_DETAILS,
	MAX_LENGTH_CHARACTER,
	SELECT_TAGS,
} from '@constants/content/contentLibrary';
import { SOCIAL } from '@constants/social_search';
import THEME from '@constants/themes/themes';
import SocialDetailContext from '@contexts/SocialSearch/SocialDetail';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { StateCampaign } from '@models/campaign';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import {
	StyledCol,
	StyledFormItem,
	StyledIcon,
	StyledIconWrapper,
	StyledLink,
	StyledRow,
	StyledSection,
	StyledTag,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledCustomSelect } from '@styled/Content/ContentLibrary/ActionBarStyled';
import {
	StyledContainer,
	StyledForm,
	StyledRate,
} from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { StyledTitle } from '@styled/Content/ContentLibrary/ContentStyled';
import { StyledTextArea } from '@styled/Content/ContentLibrary/FilterStyled';
import { StyledContentFlagged, StyledFlagged } from '@styled/ContentLibrary/ContentDetailStyled';
import { goToSocialProfile } from '@utils/common';
import { Form } from 'antd';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SocialProfile from './SocialProfile';
import SocialHashTagMention from './SocialHashTagMention';
import { removeDuplicateGroup } from '@utils/content';

const { OptGroup } = StyledCustomSelect;

const SocialInfo = () => {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { dimension, socialContent, activeSocialType, payload, setPayload, onViewPost } =
		useContext(SocialDetailContext);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);
	const { labels, flags: flagsFull } = clientSettings;
	const { flags } = socialContent || {};

	const hasFlags = useMemo(() => {
		return !!payload?.flags?.length;
	}, [payload]);

	useEffect(() => {
		form.setFieldsValue({ [CHANGED_DETAILS.FLAGS.name]: flags });
		setPayload({ ...(payload || {}), flags });
	}, [flags]);

	useEffect(() => {
		form.resetFields();
	}, [socialContent]);

	const formItems = [
		{
			id: CHANGED_DETAILS.STAR.name,
			head: {
				title: t('social_search.details.rating'),
			},
			data: [],
		},
		{
			id: SELECT_TAGS.LABELS.name,
			head: {
				title: t('social_search.details.tags'),
			},
			data: labels || [],
		},
		{
			id: SELECT_TAGS.CAMPAIGNS.name,
			head: {
				title: t('social_search.actions.campaigns'),
			},
			data: campaignListContent || [],
		},
		{
			id: CHANGED_DETAILS.NOTE.name,
			head: {
				title: t('social_search.details.note'),
			},
			data: ['Note'],
		},
	];

	const renderHeaderPanel = (title: string) => {
		return (
			<StyledTitle color={THEME.colors.gray1} fontSize="14px" widthIcon="16px">
				{t(title)}
			</StyledTitle>
		);
	};

	const renderSocialIcon = () => {
		switch (activeSocialType) {
			case SOCIAL.INSTAGRAM.toLowerCase():
				return <IconInstagram />;
			case SOCIAL.TIKTOK.toLowerCase():
				return <IconTikTok />;
			case SOCIAL.YOUTUBE.toLowerCase():
				return <IconYoutube />;
			default:
				return <IconTwitterWhite />;
		}
	};

	const renderBasicInfo = (socialContent: SocialContentContainerDto) => {
		const { caption, hashtags, mentionUsernames, username, timestamp } = socialContent;
		const personalInfo = [
			{
				title: t('social_search.details.channel'),
				info:
					activeSocialType === SOCIAL.TIKTOK.toLowerCase()
						? SOCIAL.TIKTOK
						: activeSocialType,
			},
			{
				title: t('social_search.details.username'),
				info: username,
			},
			{
				title: t('social_search.details.post_date'),
				info: convertUtcToLocalTimeWithFormat(timestamp, TYPE_FORMAT_DATE.TIME),
			},
		];

		return (
			<>
				<StyledWrapperContent>
					{hasFlags && (
						<>
							<StyledFlagged style={{ position: 'initial', margin: '16px 0' }}>
								<StyledRow align="middle">
									<StyledIcon margin="0 0 0 -2px" fillPath={THEME.colors.red1}>
										<IconFlag />
									</StyledIcon>
									<StyledText margin="0 0 0 8px">
										{t('content_detail.flagged')}
									</StyledText>
								</StyledRow>
							</StyledFlagged>
							<StyledContentFlagged style={{ margin: '0' }}>
								<StyledText
									margin="14px 0"
									dangerouslySetInnerHTML={{
										__html: t('content_detail.caution'),
									}}
								/>
							</StyledContentFlagged>
						</>
					)}
					<StyledFormItem
						margin="0 0 16px 0"
						name={CHANGED_DETAILS.FLAGS.name}
						label={renderHeaderPanel(t('social_search.details.flags'))}>
						<StyledCustomSelect
							mode={'tags'}
							showArrow={true}
							heightParentContains={400}
							notFoundContent={notFoundContent()}
							subtractedHeight={82}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							id={CHANGED_DETAILS.FLAGS.name}
							virtual={false}
							defaultValue={flags}
							className="select_group_tags"
							optionFilterProp={'children'}
							options={removeDuplicateGroup(flagsFull, flags || [])?.map((f) => ({
								value: f,
								label: f,
							}))}
							dropdownClassName={'dropdown-menu dropdown-custom-content_detail'}
						/>
					</StyledFormItem>
				</StyledWrapperContent>

				<SocialHashTagMention
					caption={caption || ''}
					hashtags={hashtags || []}
					mentionUsernames={mentionUsernames || []}
				/>
			</>
		);
	};

	const renderListOption = (options: any[], name: string) => {
		if (name === SELECT_TAGS.CAMPAIGNS.name) {
			return options?.map((option) => ({ label: option?.name, value: option?.id }));
		} else {
			return options?.map((option) => ({ label: option, value: option }));
		}
	};

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const renderCheckableTag = (item: any) => {
		const { id, head, data } = item;

		return (
			<StyledFormItem
				margin="0 0 8px 0"
				name={id}
				key={id}
				label={renderHeaderPanel(head.title)}>
				<StyledCustomSelect
					mode={
						id === SELECT_TAGS.LABELS.name || id === SELECT_TAGS.FLAGS.name
							? 'tags'
							: 'multiple'
					}
					showArrow={true}
					notFoundContent={notFoundContent()}
					getPopupContainer={(triggerNode: HTMLElement) =>
						triggerNode.parentNode as HTMLElement
					}
					virtual={false}
					subtractedHeight={82}
					heightParentContains={dimension}
					optionFilterProp="label"
					options={renderListOption(data, id)}
					dropdownClassName={'dropdown-menu dropdown-custom-content_detail'}
				/>
			</StyledFormItem>
		);
	};

	const handleChangeDetails = (changedValue: any) => {
		const payloadTemp = {
			...payload,
			...changedValue,
		};
		setPayload(payloadTemp);
	};

	const handleRating = (value: number) => {
		setPayload({ ...payload, star: value });
	};

	return (
		<StyledWrapperContent padding="16px 0 0 0" height={'calc(100% - 64px)'} width="100%">
			<StyledForm
				style={{ height: '100%' }}
				form={form}
				layout="vertical"
				onValuesChange={handleChangeDetails}>
				{socialContent && (
					<SocialProfile
						activeSocialType={activeSocialType || ''}
						username={socialContent.username}
						timestamp={socialContent.timestamp}
						permalink={socialContent?.permalink}
					/>
				)}
				<StyledWrapperContent
					padding="8px 0 16px 0"
					height="calc(100% - 84px)"
					width="100%"
					className="custom_scroll_bar"
					overflow="hidden auto">
					{socialContent && renderBasicInfo(socialContent)}

					{formItems.map((item) => {
						const { id, head } = item;

						if (id === CHANGED_DETAILS.STAR.name) {
							return (
								<StyledFormItem
									margin="0 0 16px 0"
									name={id}
									key={id}
									label={renderHeaderPanel(head.title)}>
									<StyledIconWrapper width="24px" height="24px">
										<StyledRate onChange={handleRating} />
									</StyledIconWrapper>
								</StyledFormItem>
							);
						}

						if (id === CHANGED_DETAILS.NOTE.name) {
							return (
								<StyledFormItem
									name={id}
									key={id}
									label={renderHeaderPanel(head.title)}>
									<StyledTextArea maxLength={MAX_LENGTH_CHARACTER} rows={3} />
								</StyledFormItem>
							);
						}

						return renderCheckableTag(item);
					})}
				</StyledWrapperContent>
			</StyledForm>
		</StyledWrapperContent>
	);
};

export default SocialInfo;
