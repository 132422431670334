export const getGuestPortalListTypes = {
	GET_GUEST_PORTAL_LIST_REQUEST: 'GET_GUEST_PORTAL_LIST_REQUEST',
	GET_GUEST_PORTAL_LIST_SUCCEEDED: 'GET_GUEST_PORTAL_LIST_SUCCEEDED',
	GET_GUEST_PORTAL_LIST_FAILED: 'GET_GUEST_PORTAL_LIST_FAILED',
	GET_GUEST_PORTAL_LIST_END: 'GET_GUEST_PORTAL_LIST_END',
};

export const createGuestPortalTypes = {
	CREATE_GUEST_PORTAL_REQUEST: 'CREATE_GUEST_PORTAL_REQUEST',
	CREATE_GUEST_PORTAL_SUCCEEDED: 'CREATE_GUEST_PORTAL_SUCCEEDED',
	CREATE_GUEST_PORTAL_FAILED: 'CREATE_GUEST_PORTAL_FAILED',
	CREATE_GUEST_PORTAL_END: 'CREATE_GUEST_PORTAL_END',
};

export const updateGuestPortalTypes = {
	UPDATE_GUEST_PORTAL_REQUEST: 'UPDATE_GUEST_PORTAL_REQUEST',
	UPDATE_GUEST_PORTAL_SUCCEEDED: 'UPDATE_GUEST_PORTAL_SUCCEEDED',
	UPDATE_GUEST_PORTAL_FAILED: 'UPDATE_GUEST_PORTAL_FAILED',
	UPDATE_GUEST_PORTAL_END: 'UPDATE_GUEST_PORTAL_END',
};

export const deleteGuestPortalTypes = {
	DELETE_GUEST_PORTAL_REQUEST: 'DELETE_GUEST_PORTAL_REQUEST',
	DELETE_GUEST_PORTAL_SUCCEEDED: 'DELETE_GUEST_PORTAL_SUCCEEDED',
	DELETE_GUEST_PORTAL_FAILED: 'DELETE_GUEST_PORTAL_FAILED',
	DELETE_GUEST_PORTAL_END: 'DELETE_GUEST_PORTAL_END',
};

export const shareGuestPortalTypes = {
	SHARE_GUEST_PORTAL_REQUEST: 'SHARE_GUEST_PORTAL_REQUEST',
	SHARE_GUEST_PORTAL_SUCCEEDED: 'SHARE_GUEST_PORTAL_SUCCEEDED',
	SHARE_GUEST_PORTAL_FAILED: 'SHARE_GUEST_PORTAL_FAILED',
	SHARE_GUEST_PORTAL_END: 'SHARE_GUEST_PORTAL_END',
};

export const storeDataGuestPortalTypes = {
	STORE_DATA_GUEST_PORTAL: 'STORE_DATA_GUEST_PORTAL',
};
