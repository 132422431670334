import { Affix, ConfigProvider, Dropdown, Pagination } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Styled
import { IconMoreVert } from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { StyledTable } from '@components/CustomTable';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { Paginator as defaultPage } from '@constants/paginator';
import { ROLE } from '@constants/settings';
import AccountUserContext from '@contexts/Settings/AccountUser';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { ColumnDto } from '@models/common/summary';
import { GuestDto } from '@models/content/albums/albumManager';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import { ActionsTypes } from '@models/socialsearch/summary';
import { StyledEmpty, StyledIconWrapper } from '@styled/Common/CommonStyled';
import { StyledPagination, StyledWrapperTable } from '@styled/Settings/AccountUserStyled';
import { menuActions, renderCopiedText } from '@utils/renderComponent';

const AccountGuestList = () => {
	const { t } = useTranslation();

	const {
		accountPayload,
		setAccountPayload,
		selectedRowKeys,
		setSelectedRowKeys,
		handleInviteUser,
	} = useContext(AccountUserContext);

	const { guestData }: AlbumsStoreDto = useSelector((state: any) => state.albumManager);

	const [userSelect, setUserSelect] = useState<GuestDto | null>(null);

	const widthColumnDefault = 150;
	const columns: ColumnDto[] = [
		{
			title: t('setting.account_user.info.email'),
			dataIndex: 'email',
			key: 'email',
			render: (val: string) => renderCopiedText(val),
		},
		{
			title: t('albums.modal.last_visit'),
			dataIndex: 'lastLogin',
			render: (lastLogin: number) =>
				renderCopiedText(
					convertUtcToLocalTimeWithFormat(lastLogin, TYPE_FORMAT_DATE.TIME) || '------',
				),
			key: 'lastLogin',
		},
		{
			title: t('setting.account_user.info.role'),
			dataIndex: 'role',
			render: () => <>{renderCopiedText(ROLE.VIEWER.label)}</>,
			key: 'role',
		},
		{
			title: '',
			width: widthColumnDefault,
			key: 'actions',
			render: (record: GuestDto) => renderActions(record),
		},
	];

	const actionsObj: ActionsTypes[] = [
		{
			name: t('setting.account_user.actions.invite_user_to_access_portal'),
			click: () => {
				if (userSelect) {
					const { email } = userSelect;
					const payload = {
						email,
						roleName: ROLE.REVIEWER.value,
					};
					handleInviteUser(payload);
				}
			},
		},
	];

	const renderActions = (record: GuestDto) => {
		return (
			<Dropdown overlay={menuActions(actionsObj)} placement="topLeft" trigger={['click']}>
				<StyledIconWrapper
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						e.preventDefault();
						e.stopPropagation();
						setUserSelect(record);
					}}
					cursor="pointer"
					margin="0 8px">
					<IconMoreVert />
				</StyledIconWrapper>
			</Dropdown>
		);
	};

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const onSelectAllChange = () => {};

	const onChangePage = (pageNumber: number) => {
		if (accountPayload) {
			setAccountPayload({ ...accountPayload, page: pageNumber });
		}
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

	return (
		<>
			<StyledWrapperTable>
				<StyledTable
					dataSource={guestData?.guestResponses || []}
					columns={columns}
					pagination={false}
					rowSelection={rowSelection}
					scroll={{ y: 'calc(100vh - 370px)' }}
				/>
			</StyledWrapperTable>
			<Affix offsetBottom={0}>
				<StyledPagination>
					<Pagination
						total={guestData?.totalRecords}
						pageSize={defaultPage.pageSize}
						defaultPageSize={defaultPage.pageSize}
						current={accountPayload?.page}
						showSizeChanger={false}
						showQuickJumper
						onChange={onChangePage}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${total || 0} ${t(
								`${total > 1 ? 'pagination.items' : 'pagination.item'}`,
							)}`
						}
					/>
				</StyledPagination>
			</Affix>
		</>
	);
};

export default AccountGuestList;
