import { hashCode, sortListByKey } from '@utils/common';
/**
 * @prettier
 */

import { STATUSCODE } from '@constants/APIs';
import { COL_GRID } from '@constants/common';
import {
	ANALYZED_CONTENT_OPTIONS,
	DEFAULT_CONTENT_RESPONSE,
	DEFAULT_EMOJI,
} from '@constants/content/contentLibrary';
import { DEFAULT_ERROR } from '@constants/errors';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { getObject } from '@helpers/storageHelpers';
import { getUser, setUser } from '@helpers/userHelpers';
import {
	ContentPayloadDataDto,
	ContentPayloadDto,
	ContentStoreDto,
	ContentUploadingType,
	ImgVersionDto,
	ParamEmojiDto,
	ResRatioAndResolution,
} from '@models/content/contentLibrary/store';
import { DataRequestTermsContentTypes, TagItemDto } from '@models/content/contentLibrary/summary';
import {
	contentDetailTypes,
	contentEmojiTypes,
	contentImgVersionTypes,
	contentTotalTypes,
	contentTypes,
	createImgVersionTypes,
	createPublishLogTypes,
	deleteContentTypes,
	deleteFilterTypes,
	deleteMultiContentsTypes,
	estimateCostTypes,
	fetchContentDataTypes,
	getMediaDetectionTypes,
	getContentLocationTypes,
	getContentTagsTypes,
	getContentUnviewedStatusRecentlyAddedType,
	getDetectionLabelListTypes,
	getDetectionServiceListTypes,
	getFilterTypes,
	getPublishLogTypes,
	getTermsConditionsTypes,
	getTermsContentTypes,
	getVerificationScroreTypes,
	markFavoriteContentTypes,
	moveContentTypes,
	ratioAndResolutionTypes,
	rejectContentTypes,
	removePublishLogTypes,
	resetStateTypes,
	reviewContentTypes,
	reviewMultiContentsTypes,
	saveFilterTypes,
	storeDataTypes,
	submitDetectionOrderTypes,
	updateContentStatusRecentlyAddedType,
	updateEmojiTypes,
	updateFilterContentDetailTypes,
	updateAccountSettingTypes,
	updateImgVersionTypes,
	updatePublishLogTypes,
	saveUploadContentTypes,
	rotateImgTypes,
	fetchDeviceMakeTypes,
	fetchDeviceModelTypes,
	fetchShotCountriesTypes,
	getContentCustomFieldsTypes,
} from '@stores/content/contentLibrary/content.types';
import { message } from 'antd';
import { Action, handleActions } from 'redux-actions';
import { DEFAULT_TOGGLE_COLUMN } from '@constants/creator';

const storedUser = getUser();

const defaultGridConfig = storedUser?.accountSettings?.gridConfiguration || COL_GRID.DEFAULT;
const defaultColumnCreator =
	storedUser?.accountSettings?.creatorToggleColumn || DEFAULT_TOGGLE_COLUMN;

const initialState: ContentStoreDto = {
	fetchingContent: false,
	content: [],
	rejectedContent: [],
	error: null,
	contentIdsUnviewedContent: [],
	unviewedStatus: NONE,
	fetchingLocation: false,
	contentLocation: [],
	fetchingTags: false,
	fetchingTerms: false,
	termsContent: [],
	termsTemplateContent: null,
	fetchingFilter: false,
	tags: {
		labels: [],
		flags: [],
		googleAIHashtags: [],
	},
	thumbnailBehavior: [],
	fetchingPublishLog: false,
	publishLogs: [],
	publishLog: null,
	createPublishLogStatus: NONE,
	updatePublishLogStatus: NONE,
	removePublishLogStatus: NONE,
	fetchVerificationScoreStatus: false,
	termsConditions: undefined,
	rejectContentStatus: NONE,
	deleteContentStatus: NONE,
	markFavoriteContentStatus: NONE,
	fetchRatioResolution: false,
	gridConfiguration: defaultGridConfig,
	updateAccountStatus: NONE,
	creatorToggleColumn: defaultColumnCreator,
	ratios: [],
	resolutions: [],
	loadingContentEmoji: false,
	updateEmojiStatus: NONE,
	contentEmoji: DEFAULT_EMOJI,
	totalContent: 0,
	loadingTotalContent: false,
	loadingImgVersion: false,
	imgVersions: [],
	createImgVersionStatus: NONE,
	updateImgVersionStatus: NONE,
	imgVersionDetail: null,
	hasChangeId: false,
	detectionServiceList: [],
	estimateCostStatus: NONE,
	fetchingDetectionServiceList: false,
	submitDetectionOrderStatus: NONE,
	getMediaDetectionStatus: NONE,
	getDetectionLabelListStatus: NONE,
	mediaDetail: null,
	loadingMediaDetail: false,
	fetchingContentData: false,
	contentData: { ...DEFAULT_CONTENT_RESPONSE },
	actionFilterStatus: NONE,
	updateFilterStatus: NONE,
	idContentVersion: null,
	isUploadVideo: false,
	contentUploading: [],
	resetContentAfterUpload: false,
	rotateImgStatus: NONE,
	rotateImgRes: [],
	contentIdRequest: null,
	contentIdsRequest: [],
	isRequestDetail: false,
	fetchingDeviceMake: false,
	fetchingDeviceModel: false,
	fetchingShotCountries: false,
	deviceMakeList: [],
	deviceModelList: [],
	shotCountriesList: [],
	contentCustomFields: { dropdown: [], paragraph: [], shortAnswer: [] },
	fetchingContentCustomFields: false,
};

const fetchContentRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingContent: true,
});

const fetchContentSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: contentResult = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingContent: false,
			content: contentResult?.listContentResponse || [],
			totalContent: contentResult?.totalHits,
			error: null,
		};
	}

	return {
		...state,
		fetchingContent: false,
		content: null,
		error: status,
	};
};

const fetchContentFailed = (state: ContentStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			fetchingContent: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingContent: false,
	};
};

// fetch detail content
const fetchContentDetailRequest = (state: ContentStoreDto) => ({
	...state,
	loadingMediaDetail: true,
});

const fetchContentDetailSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: mediaDetail,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingMediaDetail: false,
			mediaDetail,
			error: null,
		};
	}

	return {
		...state,
		loadingMediaDetail: false,
		mediaDetail: null,
		error: status,
	};
};

const fetchContentDetailFailed = (state: ContentStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			loadingMediaDetail: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		loadingMediaDetail: false,
	};
};

// Fetch content data
const fetchContentDataRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingContentData: true,
});

const fetchContentDataSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: contentDataResult,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingContentData: false,
			contentData: {
				result: contentDataResult.listContentResponse || [],
				totalRecords: contentDataResult.totalHits || 0,
			},
			error: null,
		};
	}

	return {
		...state,
		fetchingContentData: false,
		contentData: { ...DEFAULT_CONTENT_RESPONSE },
		error: status,
	};
};

const fetchContentDataFailed = (state: ContentStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			fetchingContentData: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingContentData: false,
	};
};

const fetchContentDataEnd = (state: ContentStoreDto) => ({
	...state,
	fetchingContentData: false,
	contentData: { ...DEFAULT_CONTENT_RESPONSE },
});

const rejectContentRequest = (state: ContentStoreDto) => ({
	...state,
	rejectContentStatus: IN_PROGRESS,
});

const rejectContentSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: rejectedContent = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			rejectedContent,
			rejectContentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		rejectContentStatus: FAILED,
		rejectedContent: null,
		error: status,
	};
};

const rejectContentFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		rejectContentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const rejectContentEnd = (state: ContentStoreDto) => {
	return {
		...state,
		rejectContentStatus: NONE,
	};
};

const moveContentRequest = (state: ContentStoreDto) => ({
	...state,
	moveContentStatus: IN_PROGRESS,
});

const moveContentSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: movedContent = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			movedContent,
			moveContentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		moveContentStatus: FAILED,
		movedContent: null,
		error: status,
	};
};

const moveContentFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		moveContentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const moveContentEnd = (state: ContentStoreDto) => {
	return {
		...state,
		moveContentStatus: NONE,
		movedContent: null,
	};
};

const deleteContentRequest = (state: ContentStoreDto) => ({
	...state,
	deleteContentStatus: IN_PROGRESS,
});

const deleteContentSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: deletedContent = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deletedContent,
			deleteContentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		deleteContentStatus: FAILED,
		deletedContent: null,
		error: status,
	};
};

const deleteContentFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		deleteContentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const deleteContentEnd = (state: ContentStoreDto) => {
	return {
		...state,
		deleteContentStatus: NONE,
	};
};

const deleteMultiContentsRequest = (state: ContentStoreDto) => ({
	...state,
	deleteMultiContentsStatus: IN_PROGRESS,
});

const deleteMultiContentsSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deleteMultiContentsStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		deleteMultiContentsStatus: FAILED,
		error: status,
	};
};

const deleteMultiContentsFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		deleteMultiContentsStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const deleteMultiContentsEnd = (state: ContentStoreDto) => {
	return {
		...state,
		deleteMultiContentsStatus: NONE,
	};
};

const reviewContentRequest = (state: ContentStoreDto) => ({
	...state,
	reviewContentStatus: IN_PROGRESS,
});

const reviewContentSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: reviewedContent = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			reviewedContent,
			reviewContentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		reviewContentStatus: FAILED,
		reviewedContent: null,
		error: status,
	};
};

const reviewContentFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		reviewContentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const reviewContentEnd = (state: ContentStoreDto) => ({
	...state,
	reviewContentStatus: NONE,
	reviewedContent: undefined,
});

const reviewMultiContentsRequest = (state: ContentStoreDto) => ({
	...state,
	reviewMultiContentsStatus: IN_PROGRESS,
});

const reviewMultiContentsSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: reviewedContents = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			reviewedContents,
			reviewMultiContentsStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		reviewMultiContentsStatus: FAILED,
		reviewedContent: null,
		error: status,
	};
};

const reviewMultiContentsFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		reviewMultiContentsStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const reviewMultiContentsEnd = (state: ContentStoreDto) => ({
	...state,
	reviewMultiContentsStatus: NONE,
	reviewedContents: undefined,
});

const getFilterRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingFilter: true,
});

const getFilterSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: filterList = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			filterList,
			fetchingFilter: false,
		};
	}

	return {
		...state,
		fetchingFilter: false,
		filterList: null,
		error: status,
	};
};

const getFilterFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		fetchingFilter: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const saveFilterRequest = (state: ContentStoreDto) => ({
	...state,
	actionFilterStatus: IN_PROGRESS,
});

const saveFilterSucceeded = (state: ContentStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: savedFilter = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const { filterList } = state;
		if (filterList) {
			filterList.unshift(savedFilter);
		}

		return {
			...state,
			savedFilter,
			filterList,
			actionFilterStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		actionFilterStatus: FAILED,
		savedFilter: null,
		error: status,
	};
};

const saveFilterFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		actionFilterStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const saveFilterEnd = (state: ContentStoreDto) => ({
	...state,
	actionFilterStatus: NONE,
	savedFilter: null,
});

// update filter
const updateFilterRequest = (state: ContentStoreDto) => ({
	...state,
	actionFilterStatus: IN_PROGRESS,
});

const updateFilterSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: savedFilter = {},
		id,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const { filterList } = state;
		const newList = filterList?.map((item) => {
			if (item.id === id) {
				return { id, ...savedFilter };
			}
			return item;
		});

		return {
			...state,
			savedFilter,
			filterList: newList,
			actionFilterStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		actionFilterStatus: FAILED,
		savedFilter: null,
		error: status,
	};
};

const updateFilterFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		actionFilterStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const updateFilterEnd = (state: ContentStoreDto) => ({
	...state,
	actionFilterStatus: NONE,
	savedFilter: null,
});

const changeFilterRequest = (state: ContentStoreDto) => ({
	...state,
	actionFilterStatus: IN_PROGRESS,
});

const changeFilterSucceeded = (state: ContentStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: savedFilter,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const { filterList } = state;
		const filterListChange = filterList?.map((item) => {
			if (item?.id === savedFilter?.id) {
				return savedFilter;
			}
			return item;
		});
		return {
			...state,
			filterList: filterListChange,
			actionFilterStatus: SUCCEEDED,
			savedFilter,
		};
	}

	return {
		...state,
		actionFilterStatus: FAILED,
		error: status,
		savedFilter: null,
	};
};

const changeFilterFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		actionFilterStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const changeFilterEnd = (state: ContentStoreDto) => ({
	...state,
	actionFilterStatus: NONE,
	savedFilter: null,
});

const deleteFilterRequest = (state: ContentStoreDto) => ({
	...state,
	actionFilterStatus: IN_PROGRESS,
});

const deleteFilterSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: deletedFilter = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		let { filterList } = state;

		if (filterList && deletedFilter !== null) {
			filterList = filterList.filter((item) => {
				return item.id !== deletedFilter[0];
			});
		}

		return {
			...state,
			filterList,
			deletedFilter,
			actionFilterStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		actionFilterStatus: FAILED,
		error: status,
	};
};

const deleteFilterFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		actionFilterStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	message.error(result.error);
	return result;
};

const deleteFilterEnd = (state: ContentStoreDto) => ({
	...state,
	actionFilterStatus: NONE,
	deletedFilter: [],
});

const resetStateRequest = (state: ContentStoreDto) => {
	return {
		...initialState,
		gridConfiguration: state?.gridConfiguration,
		contentUploading: state?.contentUploading,
	};
};

const getContentVerificationScoreRequest = (state: ContentStoreDto) => ({
	...state,
	fetchVerificationScoreStatus: true,
});

const getContentVerificationScoreSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	return {
		...state,
		verificationScore: payload.data.result,
		fetchVerificationScoreStatus: false,
	};
};

const getContentVerificationScoreFailed = (state: ContentStoreDto) => {
	const result = {
		...state,
		fetchVerificationScoreStatus: false,
		error: DEFAULT_ERROR,
	};
	return result;
};

// get content location
const fetchContentLocationRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingLocation: true,
});

const fetchContentLocationSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	return {
		...state,
		contentLocation: payload.data.result,
		fetchingLocation: false,
	};
};

const fetchContentLocationFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		contentLocation: [],
		fetchingLocation: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

// get content custom fields
const fetchContentCustomFieldsRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingContentCustomFields: true,
});

const fetchContentCustomFieldsSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	return {
		...state,
		contentCustomFields: payload?.data?.result || {
			dropdown: [],
			paragraph: [],
			shortAnswer: [],
		},
		fetchingContentCustomFields: false,
	};
};

const fetchContentCustomFieldsFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		contentCustomFields: { dropdown: [], paragraph: [], shortAnswer: [] },
		fetchingContentCustomFields: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

// fetch content Emoji
const fetchContentEmojiRequest = (state: ContentStoreDto) => ({
	...state,
	loadingContentEmoji: true,
	contentEmoji: DEFAULT_EMOJI,
});

const fetchContentEmojiSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: contentEmoji = DEFAULT_EMOJI,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			loadingContentEmoji: false,
			contentEmoji,
			error: null,
		};
	}

	return {
		...state,
		loadingContentEmoji: false,
		error: status,
	};
};

const fetchContentEmojiFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		loadingContentEmoji: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

const fetchContentTagsRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingTags: true,
});

const fetchContentTagsSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const clientSettings = getObject('clientSettings');
	const { labels: labelSettings = [], flags: flagSettings = [] } = clientSettings;
	const { labels = [], flags = [] } = payload?.data?.result || {};
	const listLabel: TagItemDto[] = [...labels];
	const listFlag: TagItemDto[] = [...flags];
	labelSettings?.forEach((item: string) => {
		if (!listLabel?.some((label) => label?.value === item)) {
			listLabel.push({ id: hashCode(item), key: item, value: item, type: 'labels' });
		}
	});
	flagSettings?.forEach((item: string) => {
		if (!listFlag?.some((flag) => flag?.value === item)) {
			listFlag.push({ id: hashCode(item), key: item, value: item, type: 'flags' });
		}
	});

	return {
		...state,
		tags: {
			...payload.data.result,
			labels: sortListByKey(listLabel, 'value'),
			flags: sortListByKey(listFlag, 'value'),
		},
		fetchingTags: false,
	};
};

const fetchContentTagsFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		tags: {
			labels: [],
			flags: [],
			googleAIHashtags: [],
		},
		fetchingTags: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

const fetchTermsContentRequest = (
	state: ContentStoreDto,
	{ payload }: Action<DataRequestTermsContentTypes>,
) => ({
	...state,
	fetchingTerms: !!payload?.body,
	termsContent: [],
	termsTemplateContent: null,
	contentIdRequest: payload.param.contentId,
});

const fetchTermsContentSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		isTermsTemplate,
		data: { result },
		id,
	} = payload;
	if (id !== state?.contentIdRequest) {
		return { ...state, fetchingTerms: false };
	}
	const other = isTermsTemplate ? { termsTemplateContent: result } : { termsContent: result };
	return {
		...state,
		...other,
		fetchingTerms: false,
		contentIdRequest: null,
	};
};

const fetchTermsContentFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		termsContent: [],
		termsTemplateContent: null,
		fetchingTerms: false,
		error: DEFAULT_ERROR,
		contentIdRequest: payload?.id === state?.contentIdRequest ? null : state?.contentIdRequest,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

const fetchRatioResolutionRequest = (state: ContentStoreDto) => ({
	...state,
	fetchRatioResolution: true,
});

const fetchRatioResolutionSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const { result = [] } = payload?.data;
	const ratios: string[] = [];
	const resolutions: string[] = [];
	result?.forEach((item: ResRatioAndResolution) => {
		if (!ratios?.includes(item?.aspectRatio)) {
			ratios.push(item?.aspectRatio);
		}
		if (!resolutions?.includes(item?.resolution)) {
			resolutions.push(item?.resolution);
		}
	});
	ratios.sort();
	resolutions.sort();

	return {
		...state,
		ratios,
		resolutions,
		fetchRatioResolution: false,
	};
};

const fetchRatioResolutionFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		termsContent: [],
		fetchRatioResolution: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

const getTermsConditionsRequest = (state: ContentStoreDto) => ({
	...state,
	getTermsConditionsStatus: IN_PROGRESS,
});

const getTermsConditionsSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	return {
		...state,
		termsConditions: payload.data.result,
		getTermsConditionsStatus: SUCCEEDED,
	};
};

const getTermsConditionsFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		getTermsConditionsStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

const getTermsConditionsEnd = (state: ContentStoreDto) => ({
	...state,
	getTermsConditionsStatus: NONE,
	termsConditions: undefined,
});

const updateContentStatusRecentlyAddedRequest = (state: ContentStoreDto, { payload }: any) => {
	return {
		...state,
		updateStatusRecentlyAddedStatus: IN_PROGRESS,
	};
};

const updateContentStatusRecentlyAddedSucceeded = (state: ContentStoreDto, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateStatusRecentlyAddedStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		updateStatusRecentlyAddedStatus: FAILED,
	};
};

const updateContentStatusRecentlyAddedFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		updateStatusRecentlyAddedStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

const updateContentStatusRecentlyAddedEnd = (state: ContentStoreDto) => ({
	...state,
	updateStatusRecentlyAddedStatus: NONE,
});

const getContentUnviewedStatusRecentlyAddedRequest = (state: ContentStoreDto) => ({
	...state,
	unviewedStatus: IN_PROGRESS,
});

const getContentUnviewedStatusRecentlyAddedSucceeded = (
	state: ContentStoreDto,
	{ payload }: any,
) => {
	const {
		status: { code = 0, status = '' },
		result: contentIdsUnviewedContent = [],
	} = payload;
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			contentIdsUnviewedContent,
			unviewedStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		contentIdsUnviewedContent: [],
		unviewedStatus: FAILED,
	};
};

const getContentUnviewedStatusRecentlyAddedFailed = (
	state: ContentStoreDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	unviewedStatus: FAILED,
	error: payload,
	contentIdsUnviewedContent: [],
});

const getContentUnviewedStatusRecentlyAddedEnd = (state: ContentStoreDto) => ({
	...state,
	contentIdsUnviewedContent: [],
	unviewedStatus: NONE,
});

const storeData = (state: ContentStoreDto, { payload }: any) => {
	return {
		...state,
		...payload,
	};
};

const fetchPublishLogContentRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingPublishLog: true,
	publishLogs: [],
});

const fetchPublishLogContentSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	return {
		...state,
		publishLogs: payload.data.result,
		fetchingPublishLog: false,
	};
};

const fetchPublishLogContentFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		fetchingPublishLog: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

// fetch total content
const fetchTotalContentRequest = (state: ContentStoreDto) => ({
	...state,
	loadingTotalContent: true,
	totalContent: 0,
});

const fetchTotalContentSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const { result: totalContent = 0 } = payload.data;
	return {
		...state,
		loadingTotalContent: false,
		totalContent,
	};
};

const fetchTotalContentFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		loadingTotalContent: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

const createPublishLogRequest = (state: ContentStoreDto) => ({
	...state,
	createPublishLogStatus: IN_PROGRESS,
});

const createPublishLogSucceeded = (state: ContentStoreDto, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			createPublishLogStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		createPublishLogStatus: FAILED,
	};
};

const createPublishLogFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		createPublishLogStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const createPublishLogEnd = (state: ContentStoreDto) => ({
	...state,
	createPublishLogStatus: NONE,
});

const changePublishLogRequest = (state: ContentStoreDto) => ({
	...state,
	updatePublishLogStatus: IN_PROGRESS,
});

const changePublishLogSucceeded = (state: ContentStoreDto, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: publishLog,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updatePublishLogStatus: SUCCEEDED,
			publishLog,
		};
	}

	return {
		...state,
		updatePublishLogStatus: FAILED,
	};
};

const changePublishLogFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		updatePublishLogStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const changePublishLogEnd = (state: ContentStoreDto) => ({
	...state,
	updatePublishLogStatus: NONE,
	publishLog: null,
});

const removePublishLogRequest = (state: ContentStoreDto) => ({
	...state,
	removePublishLogStatus: IN_PROGRESS,
});

const removePublishLogSucceeded = (state: ContentStoreDto, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			removePublishLogStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		removePublishLogStatus: FAILED,
	};
};

const removePublishLogFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		removePublishLogStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const removePublishLogEnd = (state: ContentStoreDto) => ({
	...state,
	removePublishLogStatus: NONE,
});

const markFavoriteContentRequest = (state: ContentStoreDto) => ({
	...state,
	markFavoriteContentStatus: IN_PROGRESS,
});

const markFavoriteContentSucceeded = (state: ContentStoreDto, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload.data;
	const { favoriteContent } = payload;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			markFavoriteContentStatus: SUCCEEDED,
			favoriteContent,
		};
	}

	return {
		...state,
		markFavoriteContentStatus: FAILED,
	};
};

const markFavoriteContentFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		markFavoriteContentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const markFavoriteContentEnd = (state: ContentStoreDto) => ({
	...state,
	markFavoriteContentStatus: NONE,
	favoriteContent: null,
});

// change account info
const updateAccountSettingRequest = (state: ContentStoreDto) => ({
	...state,
	updateAccountStatus: IN_PROGRESS,
});

const updateAccountSettingSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result = {},
	} = payload.data;
	const storedUser = getUser();
	const updatedUser = {
		...storedUser,
		accountSettings: {
			...storedUser?.accountSettings,
			...result,
		},
	};

	setUser(updatedUser);

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateAccountStatus: SUCCEEDED,
			gridConfiguration: result.gridConfiguration,
			creatorToggleColumn: result.creatorToggleColumn,
		};
	}

	return {
		...state,
		updateAccountStatus: FAILED,
	};
};

const updateAccountSettingFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		updateAccountStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const updateAccountSettingEnd = (state: ContentStoreDto) => ({
	...state,
	updateAccountStatus: NONE,
});

const changeInfoAccountSetting = (state: ContentStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		...payload,
	};
};

// update emoji
const updateEmojiRequest = (state: ContentStoreDto, { payload }: Action<ParamEmojiDto>) => {
	const user = getObject('user');
	const { emojiEnum, isRemove } = payload;
	let contentEmoji: any = state?.contentEmoji;

	const arrKey = Object.keys(contentEmoji);
	arrKey.forEach((key: string) => {
		const includeEmail = contentEmoji[key]?.emails?.includes(user?.email);
		if (includeEmail) {
			contentEmoji = {
				...contentEmoji,
				[key]: {
					emails: contentEmoji[key]?.emails?.filter(
						(item: string) => item !== user?.email,
					),
					total: contentEmoji[key]?.total - 1,
				},
			};
		}
	});
	const obj: any = contentEmoji[emojiEnum.toLowerCase()];
	if (!isRemove) {
		obj.emails.unshift(user?.email);
		obj.total += 1;
	}
	return {
		...state,
		updateEmojiStatus: IN_PROGRESS,
		contentEmoji: { ...contentEmoji, [emojiEnum.toLowerCase()]: obj },
	};
};

const updateEmojiSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateEmojiStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		updateEmojiStatus: FAILED,
	};
};

const updateEmojiFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		updateEmojiStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const updateEmojiEnd = (state: ContentStoreDto) => ({
	...state,
	updateEmojiStatus: NONE,
});

// fetch content img version
const getImgVersionRequest = (state: ContentStoreDto) => ({
	...state,
	loadingImgVersion: true,
	imgVersions: [],
});

const getImgVersionSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const { result: imgVersions = [] } = payload.data;
	return {
		...state,
		loadingImgVersion: false,
		imgVersions,
	};
};

const getImgVersionFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		loadingImgVersion: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	message.error(result.error);
	return result;
};

// create img version
const createImgVersionRequest = (state: ContentStoreDto) => {
	return {
		...state,
		createImgVersionStatus: IN_PROGRESS,
		imgVersionDetail: null,
	};
};

const createImgVersionSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDataDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: imgVersionDetail,
		id: idContentVersion,
		isUploadVideo = false,
	} = payload;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			imgVersionDetail,
			idContentVersion,
			isUploadVideo,
			createImgVersionStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		createImgVersionStatus: FAILED,
	};
};

const createImgVersionFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		createImgVersionStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const createImgVersionEnd = (state: ContentStoreDto) => ({
	...state,
	createImgVersionStatus: NONE,
	imgVersionDetail: null,
	idContentVersion: null,
	isUploadVideo: false,
});

// update img version
const updateImgVersionRequest = (state: ContentStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		updateImgVersionStatus: IN_PROGRESS,
		imgVersionDetail: null,
		hasChangeId: payload.hasChangeId,
	};
};

const updateImgVersionSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDataDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: imgVersionDetail,
		id: idContentVersion,
	} = payload;

	const imgVersions = (state?.imgVersions || [])?.map((item: ImgVersionDto) => {
		if (item?.id === imgVersionDetail?.id) {
			return { ...imgVersionDetail };
		}
		return { ...item, isDefault: false };
	});
	const other = state?.hasChangeId ? {} : { imgVersions };

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			imgVersionDetail,
			idContentVersion,
			updateImgVersionStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		updateImgVersionStatus: FAILED,
	};
};

const updateImgVersionFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		updateImgVersionStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const updateImgVersionEnd = (state: ContentStoreDto) => ({
	...state,
	updateImgVersionStatus: NONE,
	imgVersionDetail: null,
	hasChangeId: false,
	idContentVersion: null,
});

// rotate image
const rotateImgRequest = (state: ContentStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		rotateImgStatus: IN_PROGRESS,
		contentIdsRequest: payload.ids || [],
	};
};

const rotateImgSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDataDto>) => {
	const {
		status: { code = 0, status = '' },
		result: rotateImgRes = [],
		isRequestDetail = false,
	} = payload;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			rotateImgStatus: SUCCEEDED,
			isRequestDetail,
			rotateImgRes,
		};
	}

	return {
		...state,
		rotateImgStatus: FAILED,
	};
};

const rotateImgFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		rotateImgStatus: FAILED,
		error: DEFAULT_ERROR,
		isRequestDetail: false,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const rotateImgEnd = (state: ContentStoreDto) => ({
	...state,
	rotateImgStatus: NONE,
	contentIdsRequest: [],
	rotateImgRes: [],
});

// Logo detection list
const getDetectionServiceListRequest = (state: ContentStoreDto) => {
	return {
		...state,
		fetchingDetectionServiceList: true,
	};
};

const getDetectionServiceListSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = 0 },
		result: detectionServiceList = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			detectionServiceList,
			fetchingDetectionServiceList: false,
		};
	}

	return {
		...state,
		fetchingDetectionServiceList: false,
	};
};

const getDetectionServiceListFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		fetchingDetectionServiceList: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const getDetectionServiceListEnd = (state: ContentStoreDto) => ({
	...state,
	detectionServiceList: [],
	fetchingDetectionServiceList: false,
});

// Estimate cost
const estimateCostRequest = (state: ContentStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		estimateCostStatus: IN_PROGRESS,
	};
};

const estimateCostSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = 0 },
		result: estimatedCost = {},
		skipDetected,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			estimatedCostDetail: {
				...state?.estimatedCostDetail,
				[skipDetected
					? ANALYZED_CONTENT_OPTIONS.SKIP_CONTENT_ANALYZED
					: ANALYZED_CONTENT_OPTIONS.ALL_CONTENTS]: estimatedCost,
			},
			estimateCostStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		estimateCostStatus: FAILED,
	};
};

const estimateCostFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		estimateCostStatus: FAILED,
		estimatedCostDetail: null,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const estimateCostEnd = (state: ContentStoreDto) => ({
	...state,
	estimateCostStatus: NONE,
	estimatedCostDetail: null,
});

// Submit detection order
const submitDetectionOrderRequest = (state: ContentStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		submitDetectionOrderStatus: IN_PROGRESS,
	};
};

const submitDetectionOrderSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = 0 },
		analyzedContentIds = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			submitDetectionOrderStatus: SUCCEEDED,
			analyzedContentIds,
		};
	}

	return {
		...state,
		submitDetectionOrderStatus: FAILED,
	};
};

const submitDetectionOrderFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		submitDetectionOrderStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const submitDetectionOrderEnd = (state: ContentStoreDto) => ({
	...state,
	submitDetectionOrderStatus: NONE,
	analyzedContentIds: null,
});

// Get bounding box of a detected logo
const getMediaDetectionRequest = (state: ContentStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		getMediaDetectionStatus: IN_PROGRESS,
	};
};

const getMediaDetectionSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = 0 },
		result,
	} = payload.data;
	const { logoDetection, videoLabelDetection, videoExplicitContentDetection } = result || {};

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			contentDetection: {
				logoDetection: logoDetection ? logoDetection.detections : null,
				videoLabelDetection: videoLabelDetection ? videoLabelDetection.detections : null,
				videoExplicitContentDetection: videoExplicitContentDetection || null,
			},
			getMediaDetectionStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		getMediaDetectionStatus: FAILED,
	};
};

const getMediaDetectionFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		getMediaDetectionStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const getMediaDetectionEnd = (state: ContentStoreDto) => ({
	...state,
	getMediaDetectionStatus: NONE,
	boundingBoxContent: null,
});

// Get the list of detection label
const getDetectionLabelListRequest = (state: ContentStoreDto, { payload }: Action<any>) => {
	return {
		...state,
		getDetectionLabelListStatus: IN_PROGRESS,
	};
};

const getDetectionLabelListSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = 0 },
		result: detectionServiceLabelList,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			detectionServiceLabelList,
			getDetectionLabelListStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		getDetectionLabelListStatus: FAILED,
	};
};

const getDetectionLabelListFailed = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const result = {
		...state,
		getDetectionLabelListStatus: FAILED,
		estimatedCostDetail: null,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const getDetectionLabelListEnd = (state: ContentStoreDto) => ({
	...state,
	getDetectionLabelListStatus: NONE,
	boundingBoxContent: null,
});

// save upload content
const saveUploadContent = (
	state: ContentStoreDto,
	{ payload }: Action<{ list: ContentUploadingType[]; isAdd?: boolean; other?: any }>,
) => {
	const other = payload?.other || {};
	if (payload.isAdd) {
		return {
			...state,
			...other,
			contentUploading: [...state.contentUploading, ...payload.list],
		};
	}
	return {
		...state,
		...other,
		contentUploading: payload.list,
	};
};

const resetListContentAfterUpload = (state: ContentStoreDto, { payload }: Action<boolean>) => {
	return {
		...state,
		resetContentAfterUpload: payload,
	};
};

const removeSingleContentStatus = (state: ContentStoreDto, { payload }: Action<number>) => {
	const contentUploading = state.contentUploading.filter((item) => item?.id !== payload);

	return {
		...state,
		contentUploading,
	};
};

// fetch device make
const getDeviceMakeRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingDeviceMake: true,
});

const getDeviceMakeSucceeded = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const {
		status: { code = NONE, status = '' },
		result: deviceMakeList = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deviceMakeList,
			fetchingDeviceMake: false,
		};
	}

	return {
		...state,
		fetchingDeviceMake: false,
		deviceMakeList: [],
		error: status,
	};
};

const getDeviceMakeFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		fetchingDeviceMake: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

// fetch device model
const getDeviceModelRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingDeviceModel: true,
});

const getDeviceModelSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: deviceModelList = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deviceModelList,
			fetchingDeviceModel: false,
		};
	}

	return {
		...state,
		fetchingDeviceModel: false,
		deviceModelList: [],
		error: status,
	};
};

const getDeviceModelFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		fetchingDeviceModel: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

// fetch shot countries
const getShotCountriesRequest = (state: ContentStoreDto) => ({
	...state,
	fetchingShotCountries: true,
});

const getShotCountriesSucceeded = (
	state: ContentStoreDto,
	{ payload }: Action<ContentPayloadDto>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: shotCountriesList = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			shotCountriesList,
			fetchingShotCountries: false,
		};
	}

	return {
		...state,
		fetchingShotCountries: false,
		shotCountriesList: [],
		error: status,
	};
};

const getShotCountriesFailed = (state: ContentStoreDto, { payload }: Action<ContentPayloadDto>) => {
	const result = {
		...state,
		fetchingShotCountries: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { status } = payload.data;

		if (status) {
			const { code, status: error = '' } = status;
			result.error = `${code} ${error}`;
		}
	}

	return result;
};

const contentReducers = handleActions<any>(
	{
		[contentTypes.FETCH_CONTENT_REQUEST]: fetchContentRequest,
		[contentTypes.FETCH_CONTENT_SUCCEEDED]: fetchContentSucceeded,
		[contentTypes.FETCH_CONTENT_FAILED]: fetchContentFailed,

		[contentDetailTypes.FETCH_CONTENT_DETAIL_REQUEST]: fetchContentDetailRequest,
		[contentDetailTypes.FETCH_CONTENT_DETAIL_SUCCEEDED]: fetchContentDetailSucceeded,
		[contentDetailTypes.FETCH_CONTENT_DETAIL_FAILED]: fetchContentDetailFailed,

		[fetchContentDataTypes.FETCH_CONTENT_DATA_REQUEST]: fetchContentDataRequest,
		[fetchContentDataTypes.FETCH_CONTENT_DATA_SUCCEEDED]: fetchContentDataSucceeded,
		[fetchContentDataTypes.FETCH_CONTENT_DATA_FAILED]: fetchContentDataFailed,
		[fetchContentDataTypes.FETCH_CONTENT_DATA_END]: fetchContentDataEnd,

		[rejectContentTypes.REJECT_CONTENT_REQUEST]: rejectContentRequest,
		[rejectContentTypes.REJECT_CONTENT_SUCCEEDED]: rejectContentSucceeded,
		[rejectContentTypes.REJECT_CONTENT_FAILED]: rejectContentFailed,
		[rejectContentTypes.REJECT_CONTENT_END]: rejectContentEnd,

		[moveContentTypes.MOVE_CONTENT_REQUEST]: moveContentRequest,
		[moveContentTypes.MOVE_CONTENT_SUCCEEDED]: moveContentSucceeded,
		[moveContentTypes.MOVE_CONTENT_FAILED]: moveContentFailed,
		[moveContentTypes.MOVE_CONTENT_END]: moveContentEnd,

		[deleteContentTypes.DELETE_CONTENT_REQUEST]: deleteContentRequest,
		[deleteContentTypes.DELETE_CONTENT_SUCCEEDED]: deleteContentSucceeded,
		[deleteContentTypes.DELETE_CONTENT_FAILED]: deleteContentFailed,
		[deleteContentTypes.DELETE_CONTENT_END]: deleteContentEnd,

		[deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_REQUEST]: deleteMultiContentsRequest,
		[deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_SUCCEEDED]: deleteMultiContentsSucceeded,
		[deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_FAILED]: deleteMultiContentsFailed,
		[deleteMultiContentsTypes.DELETE_MULTI_CONTENTS_END]: deleteMultiContentsEnd,

		[reviewContentTypes.REVIEW_CONTENT_REQUEST]: reviewContentRequest,
		[reviewContentTypes.REVIEW_CONTENT_SUCCEEDED]: reviewContentSucceeded,
		[reviewContentTypes.REVIEW_CONTENT_FAILED]: reviewContentFailed,
		[reviewContentTypes.REVIEW_CONTENT_END]: reviewContentEnd,

		[reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_REQUEST]: reviewMultiContentsRequest,
		[reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_SUCCEEDED]: reviewMultiContentsSucceeded,
		[reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_FAILED]: reviewMultiContentsFailed,
		[reviewMultiContentsTypes.REVIEW_MULTI_CONTENTS_END]: reviewMultiContentsEnd,

		[getFilterTypes.GET_FILTER_REQUEST]: getFilterRequest,
		[getFilterTypes.GET_FILTER_SUCCEEDED]: getFilterSucceeded,
		[getFilterTypes.GET_FILTER_FAILED]: getFilterFailed,

		[saveFilterTypes.SAVE_FILTER_REQUEST]: saveFilterRequest,
		[saveFilterTypes.SAVE_FILTER_SUCCEEDED]: saveFilterSucceeded,
		[saveFilterTypes.SAVE_FILTER_FAILED]: saveFilterFailed,
		[saveFilterTypes.SAVE_FILTER_END]: saveFilterEnd,

		[deleteFilterTypes.DELETE_FILTER_REQUEST]: deleteFilterRequest,
		[deleteFilterTypes.DELETE_FILTER_SUCCEEDED]: deleteFilterSucceeded,
		[deleteFilterTypes.DELETE_FILTER_FAILED]: deleteFilterFailed,
		[deleteFilterTypes.DELETE_FILTER_END]: deleteFilterEnd,

		[resetStateTypes.RESET_STATE_REQUEST]: resetStateRequest,

		[getVerificationScroreTypes.GET_VERIFICATION_SCORE_REQUEST]:
			getContentVerificationScoreRequest,
		[getVerificationScroreTypes.GET_VERIFICATION_SCORE_SUCCEEDED]:
			getContentVerificationScoreSucceeded,
		[getVerificationScroreTypes.GET_VERIFICATION_SCORE_FAILED]:
			getContentVerificationScoreFailed,

		[getTermsConditionsTypes.GET_TERMS_CONDITIONS_REQUEST]: getTermsConditionsRequest,
		[getTermsConditionsTypes.GET_TERMS_CONDITIONS_SUCCEEDED]: getTermsConditionsSucceeded,
		[getTermsConditionsTypes.GET_TERMS_CONDITIONS_FAILED]: getTermsConditionsFailed,
		[getTermsConditionsTypes.GET_TERMS_CONDITIONS_END]: getTermsConditionsEnd,

		[updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_REQUEST]:
			updateContentStatusRecentlyAddedRequest,
		[updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_SUCCEEDED]:
			updateContentStatusRecentlyAddedSucceeded,
		[updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_FAILED]:
			updateContentStatusRecentlyAddedFailed,
		[updateContentStatusRecentlyAddedType.UPDATE_CONTENT_STATUS_RECENTLY_ADDED_END]:
			updateContentStatusRecentlyAddedEnd,

		[getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST]:
			getContentUnviewedStatusRecentlyAddedRequest,
		[getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_SUCCEEDED]:
			getContentUnviewedStatusRecentlyAddedSucceeded,
		[getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_FAILED]:
			getContentUnviewedStatusRecentlyAddedFailed,
		[getContentUnviewedStatusRecentlyAddedType.GET_CONTENT_UNVIEWED_STATUS_RECENTLY_ADDED_END]:
			getContentUnviewedStatusRecentlyAddedEnd,

		[getContentLocationTypes.GET_CONTENT_LOCATION_REQUEST]: fetchContentLocationRequest,
		[getContentLocationTypes.GET_CONTENT_LOCATION_SUCCEEDED]: fetchContentLocationSucceeded,
		[getContentLocationTypes.GET_CONTENT_LOCATION_FAILED]: fetchContentLocationFailed,

		[getContentCustomFieldsTypes.GET_CONTENT_CUSTOM_FIELDS_REQUEST]:
			fetchContentCustomFieldsRequest,
		[getContentCustomFieldsTypes.GET_CONTENT_CUSTOM_FIELDS_SUCCEEDED]:
			fetchContentCustomFieldsSucceeded,
		[getContentCustomFieldsTypes.GET_CONTENT_CUSTOM_FIELDS_FAILED]:
			fetchContentCustomFieldsFailed,

		[contentEmojiTypes.FETCH_CONTENT_EMOJI_REQUEST]: fetchContentEmojiRequest,
		[contentEmojiTypes.FETCH_CONTENT_EMOJI_SUCCEEDED]: fetchContentEmojiSucceeded,
		[contentEmojiTypes.FETCH_CONTENT_EMOJI_FAILED]: fetchContentEmojiFailed,

		[getContentTagsTypes.GET_CONTENT_TAGS_REQUEST]: fetchContentTagsRequest,
		[getContentTagsTypes.GET_CONTENT_TAGS_SUCCEEDED]: fetchContentTagsSucceeded,
		[getContentTagsTypes.GET_CONTENT_TAGS_FAILED]: fetchContentTagsFailed,

		[ratioAndResolutionTypes.GET_RATIO_RESOLUTION_REQUEST]: fetchRatioResolutionRequest,
		[ratioAndResolutionTypes.GET_RATIO_RESOLUTION_SUCCEEDED]: fetchRatioResolutionSucceeded,
		[ratioAndResolutionTypes.GET_RATIO_RESOLUTION_FAILED]: fetchRatioResolutionFailed,

		[storeDataTypes.STORE_DATA]: storeData,

		[updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_REQUEST]: changeFilterRequest,
		[updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_SUCCEEDED]:
			changeFilterSucceeded,
		[updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_FAILED]: changeFilterFailed,
		[updateFilterContentDetailTypes.UPDATE_FILTER_CONTENT_DETAIL_END]: changeFilterEnd,

		[getTermsContentTypes.GET_TERMS_CONTENT_REQUEST]: fetchTermsContentRequest,
		[getTermsContentTypes.GET_TERMS_CONTENT_SUCCEEDED]: fetchTermsContentSucceeded,
		[getTermsContentTypes.GET_TERMS_CONTENT_FAILED]: fetchTermsContentFailed,

		[getPublishLogTypes.GET_PUBLISH_LOG_REQUEST]: fetchPublishLogContentRequest,
		[getPublishLogTypes.GET_PUBLISH_LOG_SUCCEEDED]: fetchPublishLogContentSucceeded,
		[getPublishLogTypes.GET_PUBLISH_LOG_FAILED]: fetchPublishLogContentFailed,

		[contentTotalTypes.FETCH_TOTAL_CONTENT_REQUEST]: fetchTotalContentRequest,
		[contentTotalTypes.FETCH_TOTAL_CONTENT_SUCCEEDED]: fetchTotalContentSucceeded,
		[contentTotalTypes.FETCH_TOTAL_CONTENT_FAILED]: fetchTotalContentFailed,

		[createPublishLogTypes.CREATE_PUBLISH_LOG_REQUEST]: createPublishLogRequest,
		[createPublishLogTypes.CREATE_PUBLISH_LOG_SUCCEEDED]: createPublishLogSucceeded,
		[createPublishLogTypes.CREATE_PUBLISH_LOG_FAILED]: createPublishLogFailed,
		[createPublishLogTypes.CREATE_PUBLISH_LOG_END]: createPublishLogEnd,

		[updatePublishLogTypes.UPDATE_PUBLISH_LOG_REQUEST]: changePublishLogRequest,
		[updatePublishLogTypes.UPDATE_PUBLISH_LOG_SUCCEEDED]: changePublishLogSucceeded,
		[updatePublishLogTypes.UPDATE_PUBLISH_LOG_FAILED]: changePublishLogFailed,
		[updatePublishLogTypes.UPDATE_PUBLISH_LOG_END]: changePublishLogEnd,

		[removePublishLogTypes.REMOVE_PUBLISH_LOG_REQUEST]: removePublishLogRequest,
		[removePublishLogTypes.REMOVE_PUBLISH_LOG_SUCCEEDED]: removePublishLogSucceeded,
		[removePublishLogTypes.REMOVE_PUBLISH_LOG_FAILED]: removePublishLogFailed,
		[removePublishLogTypes.REMOVE_PUBLISH_LOG_END]: removePublishLogEnd,

		[markFavoriteContentTypes.MARK_FAVORITE_CONTENT_REQUEST]: markFavoriteContentRequest,
		[markFavoriteContentTypes.MARK_FAVORITE_CONTENT_SUCCEEDED]: markFavoriteContentSucceeded,
		[markFavoriteContentTypes.MARK_FAVORITE_CONTENT_FAILED]: markFavoriteContentFailed,
		[markFavoriteContentTypes.MARK_FAVORITE_CONTENT_END]: markFavoriteContentEnd,

		[updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_REQUEST]: updateAccountSettingRequest,
		[updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_SUCCEEDED]: updateAccountSettingSucceeded,
		[updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_FAILED]: updateAccountSettingFailed,
		[updateAccountSettingTypes.UPDATE_ACCOUNT_SETTING_END]: updateAccountSettingEnd,
		[updateAccountSettingTypes.CHANGE_INFO_ACCOUNT_SETTING]: changeInfoAccountSetting,

		[updateEmojiTypes.UPDATE_EMOJI_REQUEST]: updateEmojiRequest,
		[updateEmojiTypes.UPDATE_EMOJI_SUCCEEDED]: updateEmojiSucceeded,
		[updateEmojiTypes.UPDATE_EMOJI_FAILED]: updateEmojiFailed,
		[updateEmojiTypes.UPDATE_EMOJI_END]: updateEmojiEnd,

		[contentImgVersionTypes.FETCH_VERSION_IMG_REQUEST]: getImgVersionRequest,
		[contentImgVersionTypes.FETCH_VERSION_IMG_SUCCEEDED]: getImgVersionSucceeded,
		[contentImgVersionTypes.FETCH_VERSION_IMG_FAILED]: getImgVersionFailed,

		[createImgVersionTypes.CREATE_IMG_VERSION_REQUEST]: createImgVersionRequest,
		[createImgVersionTypes.CREATE_IMG_VERSION_SUCCEEDED]: createImgVersionSucceeded,
		[createImgVersionTypes.CREATE_IMG_VERSION_FAILED]: createImgVersionFailed,
		[createImgVersionTypes.CREATE_IMG_VERSION_END]: createImgVersionEnd,

		[updateImgVersionTypes.UPDATE_IMG_VERSION_REQUEST]: updateImgVersionRequest,
		[updateImgVersionTypes.UPDATE_IMG_VERSION_SUCCEEDED]: updateImgVersionSucceeded,
		[updateImgVersionTypes.UPDATE_IMG_VERSION_FAILED]: updateImgVersionFailed,
		[updateImgVersionTypes.UPDATE_IMG_VERSION_END]: updateImgVersionEnd,

		[rotateImgTypes.ROTATE_IMAGE_REQUEST]: rotateImgRequest,
		[rotateImgTypes.ROTATE_IMAGE_SUCCEEDED]: rotateImgSucceeded,
		[rotateImgTypes.ROTATE_IMAGE_FAILED]: rotateImgFailed,
		[rotateImgTypes.ROTATE_IMAGE_END]: rotateImgEnd,

		[getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_REQUEST]:
			getDetectionServiceListRequest,
		[getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_SUCCEEDED]:
			getDetectionServiceListSucceeded,
		[getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_FAILED]:
			getDetectionServiceListFailed,
		[getDetectionServiceListTypes.GET_DETECTION_SERVICE_LIST_END]: getDetectionServiceListEnd,

		[estimateCostTypes.ESTIMATE_COST_REQUEST]: estimateCostRequest,
		[estimateCostTypes.ESTIMATE_COST_SUCCEEDED]: estimateCostSucceeded,
		[estimateCostTypes.ESTIMATE_COST_FAILED]: estimateCostFailed,
		[estimateCostTypes.ESTIMATE_COST_END]: estimateCostEnd,

		[submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_REQUEST]: submitDetectionOrderRequest,
		[submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_SUCCEEDED]: submitDetectionOrderSucceeded,
		[submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_FAILED]: submitDetectionOrderFailed,
		[submitDetectionOrderTypes.SUBMIT_DETECTION_ORDER_END]: submitDetectionOrderEnd,

		[getMediaDetectionTypes.GET_MEDIA_DETECTION_REQUEST]: getMediaDetectionRequest,
		[getMediaDetectionTypes.GET_MEDIA_DETECTION_SUCCEEDED]: getMediaDetectionSucceeded,
		[getMediaDetectionTypes.GET_MEDIA_DETECTION_FAILED]: getMediaDetectionFailed,
		[getMediaDetectionTypes.GET_MEDIA_DETECTION_END]: getMediaDetectionEnd,

		[getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_REQUEST]: getDetectionLabelListRequest,
		[getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_SUCCEEDED]:
			getDetectionLabelListSucceeded,
		[getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_FAILED]: getDetectionLabelListFailed,
		[getDetectionLabelListTypes.GET_DETECTION_LABEL_LIST_END]: getDetectionLabelListEnd,

		[saveUploadContentTypes.SAVE_STATUS_UPLOAD_CONTENT]: saveUploadContent,
		[saveUploadContentTypes.RESET_LIST_CONTENT_AFTER_UPLOAD]: resetListContentAfterUpload,
		[saveUploadContentTypes.REMOVE_SINGLE_UPLOAD_CONTENT]: removeSingleContentStatus,

		[fetchDeviceMakeTypes.FETCH_DEVICE_MAKE_REQUEST]: getDeviceMakeRequest,
		[fetchDeviceMakeTypes.FETCH_DEVICE_MAKE_SUCCEEDED]: getDeviceMakeSucceeded,
		[fetchDeviceMakeTypes.FETCH_DEVICE_MAKE_FAILED]: getDeviceMakeFailed,

		[fetchDeviceModelTypes.FETCH_DEVICE_MODEL_REQUEST]: getDeviceModelRequest,
		[fetchDeviceModelTypes.FETCH_DEVICE_MODEL_SUCCEEDED]: getDeviceModelSucceeded,
		[fetchDeviceModelTypes.FETCH_DEVICE_MODEL_FAILED]: getDeviceModelFailed,

		[fetchShotCountriesTypes.FETCH_SHOT_COUNTRIES_REQUEST]: getShotCountriesRequest,
		[fetchShotCountriesTypes.FETCH_SHOT_COUNTRIES_SUCCEEDED]: getShotCountriesSucceeded,
		[fetchShotCountriesTypes.FETCH_SHOT_COUNTRIES_FAILED]: getShotCountriesFailed,
	},
	initialState,
);

export default contentReducers;
