import { TextSVG } from '@assets/icons/svg/grapesjs';

export const TextBlock = (bm: any, label: string) => {
	bm.add('text', {
		label: `<div>${label}</div>`,
		media: TextSVG,
		content: {
			type: 'text',
			content: 'Insert your text here',
		},
	});
};

export default (domc: any) => {
	const textType = domc.getType('text');
	const textView = textType.view;

	domc.addType('text', {
		extend: 'text',
		model: {
			defaults: {
				'custom-name': 'Text',
				tagName: 'p',
				droppable: true,
				editable: true,
			},
		},
		view: textView,
	});
};
