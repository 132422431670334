import ActionBar from '@components/Settings/ContentLicensing/ActionBar';
import LicensingSummary from '@components/Settings/ContentLicensing/LicensingSummary';
import { BODY_TEMPLATE, PARAM_TEMPLATE } from '@constants/settings/contentLicensing';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import ContentLicensingContext from '@contexts/Settings/ContentLicensing';
import { LoadingWrapper } from '@cores/index';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import {
	BodyTemplateTypes,
	ParamTemplateTypes,
	TemplateTypes,
} from '@models/settings/contentLicensing';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';
import {
	changeDefaultTemplateEnd,
	changeFieldDataContentLicensing,
	duplicateTemplateTermsEnd,
	fetchListTemplateRequest,
} from '@stores/actions';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const ContentLicensing = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		statusCreateTemplate,
		error,
		loadingTemplate,
		dataWidgetLive,
		templates,
		statusDuplicateTemplateTerms,
		statusChangeDefaultTemplate,
	}: ContentLicensingStoreDto = useSelector((state: any) => state?.contentLicensing);
	const { loadingWidget }: WidgetManagerStoreDto = useSelector(
		(state: any) => state?.widgetManager,
	);

	const [typeAction, setTypeAction] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [paramTemplate, setParamTemplate] = useState<ParamTemplateTypes>(PARAM_TEMPLATE);
	const [bodyTemplate, setBodyTemplate] = useState<BodyTemplateTypes>({
		...BODY_TEMPLATE,
		isShowApproved: true,
		isShowDrafts: true,
	});
	const [listTemplate, setListTemplate] = useState<TemplateTypes[]>([]);
	const [idTemplateAction, setIdTemplateAction] = useState<number | null>(null);
	const [loadingOther, setLoadingOther] = useState<boolean>(false);

	useEffect(() => {
		if (paramTemplate?.page === 1) {
			setListTemplate([]);
		}
		const bodyRequest = { ...bodyTemplate };
		const { isShowApproved, isShowArchived, isShowDrafts } = bodyTemplate;
		if (!isShowApproved && !isShowArchived && !isShowDrafts) {
			bodyRequest.isShowApproved = true;
			bodyRequest.isShowArchived = true;
			bodyRequest.isShowDrafts = true;
		}
		dispatch(fetchListTemplateRequest({ param: paramTemplate, body: bodyRequest }));
	}, [bodyTemplate, paramTemplate]);

	useEffect(() => {
		return () => {
			setIdTemplateAction(null);
			dispatch(
				changeFieldDataContentLicensing({
					templates: [],
				}),
			);
			setListTemplate([]);
		};
	}, []);

	useEffect(() => {
		if (paramTemplate?.page === 1) {
			setListTemplate(templates);
		} else {
			setListTemplate([...listTemplate, ...templates]);
		}
	}, [templates]);

	useEffect(() => {
		const listStatus = [
			statusCreateTemplate,
			statusDuplicateTemplateTerms,
			statusChangeDefaultTemplate,
		];
		if (listStatus.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus.includes(FAILED) && error) {
			message.error(error);
		}
		if (listStatus.includes(SUCCEEDED) || listStatus.includes(FAILED)) {
			setIsLoading(false);
		}
	}, [statusCreateTemplate, statusDuplicateTemplateTerms, statusChangeDefaultTemplate]);

	useEffect(() => {
		if (statusDuplicateTemplateTerms === SUCCEEDED) {
			setParamTemplate({ ...paramTemplate, page: 1 });
			message.success(
				t('message.duplicate_success', { val: t('content_licensing.template') }),
			);
		}
		if (statusDuplicateTemplateTerms === SUCCEEDED || statusDuplicateTemplateTerms === FAILED) {
			dispatch(duplicateTemplateTermsEnd());
		}
	}, [statusDuplicateTemplateTerms]);

	useEffect(() => {
		if (statusChangeDefaultTemplate === SUCCEEDED) {
			const newList = listTemplate.map((item) => {
				if (item?.id === idTemplateAction) {
					return { ...item, isDefault: true };
				}
				if (item?.isDefault) {
					return { ...item, isDefault: false };
				}
				return item;
			});
			setListTemplate(newList);

			message.success(t('content_licensing.message.change_default_success'));
		}
		if (statusChangeDefaultTemplate === SUCCEEDED || statusChangeDefaultTemplate === FAILED) {
			dispatch(changeDefaultTemplateEnd());
			setIdTemplateAction(null);
		}
	}, [statusChangeDefaultTemplate]);

	const valueContext = {
		setTypeAction,
		listTemplate,
		setParamTemplate,
		paramTemplate,
		bodyTemplate,
		setBodyTemplate,
		setIdTemplateAction,
		setIsLoading,
		loadingOther,
		setLoadingOther,
	};

	return (
		<ContentLicensingContext.Provider value={valueContext}>
			<LoadingWrapper
				isLoading={loadingTemplate || loadingWidget || loadingOther || isLoading}>
				<ActionBar />
				<LicensingSummary />
			</LoadingWrapper>
		</ContentLicensingContext.Provider>
	);
};

export default ContentLicensing;
