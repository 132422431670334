import { IconInstagram, IconTikTok, IconYoutubeBase } from '@assets/icons';
import { DEFAULT_VERSION_ID, SOCIAL_SOURCE } from '@constants/content/contentLibrary';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { StyledButtonSocialNetwork } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { handleRedirectLink } from '@utils/common';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
	permalink: string;
	socialSource?: string;
};

const HighLightSource = (props: PropTypes) => {
	const { versionImgSelected } = useContext(ContentDetailContext);
	const { t } = useTranslation();
	const { permalink, socialSource } = props;
	const [focusBtn, setFocusBtn] = useState<boolean>(false);

	const goToSocialLink = (url: string) => {
		if (url) {
			handleRedirectLink(url);
		}
	};

	const isVersionOrigin = versionImgSelected?.id === DEFAULT_VERSION_ID;
	const elmTiktok =
		isVersionOrigin && socialSource === SOCIAL_SOURCE.TIKTOK ? (
			<StyledButtonSocialNetwork
				className="color_path"
				onMouseEnter={() => setFocusBtn(true)}
				onClick={() => goToSocialLink(permalink || '')}
				onMouseLeave={() => setFocusBtn(false)}>
				{t(`button.${focusBtn ? 'go_tiktok_post' : 'shared_from_tiktok'}`)}
				<StyledIcon margin="0 0 0 5px">
					<IconTikTok />
				</StyledIcon>
			</StyledButtonSocialNetwork>
		) : null;
	const elmYoutube =
		elmTiktok == null && isVersionOrigin && socialSource === SOCIAL_SOURCE.YOUTUBE ? (
			<StyledButtonSocialNetwork
				onMouseEnter={() => setFocusBtn(true)}
				className="color_path"
				onClick={() => goToSocialLink(permalink || '')}
				onMouseLeave={() => setFocusBtn(false)}>
				{t(`button.${focusBtn ? 'view_on_youtube' : 'shared_from_youtube'}`)}
				<StyledIcon margin="0 0 0 5px">
					<IconYoutubeBase />
				</StyledIcon>
			</StyledButtonSocialNetwork>
		) : null;
	const elmInstagram =
		elmTiktok == null &&
		elmYoutube == null &&
		isVersionOrigin &&
		socialSource === SOCIAL_SOURCE.INSTAGRAM ? (
			<StyledButtonSocialNetwork
				className="color_path"
				onMouseEnter={() => setFocusBtn(true)}
				onClick={() => goToSocialLink(permalink || '')}
				onMouseLeave={() => setFocusBtn(false)}>
				{t(`button.${focusBtn ? 'go_instagram_post' : 'shared_from_instagram'}`)}
				<StyledIcon margin="0 0 0 5px">
					<IconInstagram />
				</StyledIcon>
			</StyledButtonSocialNetwork>
		) : null;
	return (
		<>
			{elmTiktok}
			{elmYoutube}
			{elmInstagram}
		</>
	);
};

export default HighLightSource;
