import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IconSort } from '@assets/icons';
import {
	StyledAlert,
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledContent,
	StyledContentFilter,
	StyledFooterFilter,
	StyledFormItem,
	StyledSelect,
	StyledTextTitle,
	StyledWrapperPopup,
} from '@styled/Messages/MessageLogStyled';
import { Button, DatePicker, Form, message, Select } from 'antd';
import styled from 'styled-components';
import { EMAIL_FILTER_TYPE, SORT_BY_EMAIL } from '@constants/messages';
import { StyledTitle } from '@styled/Content/ContentLibrary/ContentStyled';
import { useTranslation } from 'react-i18next';
import { RefEmailFilter } from '@models/messages/ref';
import { validateEmail } from '@utils/common';

const { RangePicker } = DatePicker;

const { Option } = Select;

const StyledMaskFilter = styled.div``;

type PropsEmailLogFilter = {
	visibleFilter: boolean;
	setVisibleFilter: (val: boolean) => void;
	handleApplyFilter: (val: any, isClear?: boolean) => void;
	setIsSearch: (val: boolean) => void;
	defaultFilter: any;
};

type FilterKeyDto = 'failed' | 'automated' | 'manually sent';

// Dummy data
const sortBy = [
	{ name: SORT_BY_EMAIL.LATEST_FIRST.label, value: SORT_BY_EMAIL.LATEST_FIRST.value },
	{ name: SORT_BY_EMAIL.OLDEST_FIRST.label, value: SORT_BY_EMAIL.OLDEST_FIRST.value },
];

const filterTypes = [
	{ name: EMAIL_FILTER_TYPE.FAILED.label, value: EMAIL_FILTER_TYPE.FAILED.value },
	{ name: EMAIL_FILTER_TYPE.AUTOMATED.label, value: EMAIL_FILTER_TYPE.AUTOMATED.value },
	{ name: EMAIL_FILTER_TYPE.MANUALLY_SENT.label, value: EMAIL_FILTER_TYPE.MANUALLY_SENT.value },
];

const EmailLogFilter = forwardRef((props: PropsEmailLogFilter, ref: Ref<RefEmailFilter>) => {
	const { t } = useTranslation();

	const {
		visibleFilter = false,
		setVisibleFilter,
		handleApplyFilter,
		setIsSearch,
		defaultFilter,
	} = props;

	const [form] = Form.useForm();

	const refFilter = useRef<any>(null);

	const [creatorEmail, setCreatorEmail] = useState<string[]>([]);

	const [keyAutomateBefore, setKeyAutomateBefore] = useState<string>('');

	useEffect(() => {
		if (refFilter?.current && visibleFilter) {
			window.addEventListener('click', handleCheckClickOutSide);
			return () => {
				window.removeEventListener('click', handleCheckClickOutSide);
			};
		}
		return () => {};
	}, [refFilter?.current, visibleFilter]);

	useImperativeHandle(ref, () => ({
		onClearForm,
	}));

	const handleCheckClickOutSide = (e: any) => {
		const filterElement = refFilter?.current;
		const targetElement = e.target;
		const targetDateElement = document.getElementsByClassName('dropdown_date_filter')[0];
		if (!targetElement.isConnected) return;
		if (
			(filterElement && filterElement.contains(targetElement)) ||
			(targetDateElement && targetDateElement.contains(targetElement))
		) {
			return;
		}

		if (visibleFilter) {
			setVisibleFilter(false);
		}
	};

	const onSubmitForm = (values: any) => {
		setIsSearch(false);
		setVisibleFilter(false);
		handleApplyFilter({ ...values }, true);
	};

	const onChangeForm = (name: any) => {
		const key = Object.keys(name)[0];

		if (key === 'creatorEmail') {
			const emailValid = name[key]?.filter((email: string) => validateEmail(email));
			if (name[key]?.length !== emailValid?.length) {
				message.error(t('email_log.logs.invalid_email'));
			}
			setCreatorEmail(emailValid);
			form.setFieldsValue({ creatorEmail: emailValid });
		}

		if (key === 'filterBy') {
			let listAutomate = [filterTypes[1]?.value, filterTypes[2]?.value];
			if (keyAutomateBefore) {
				listAutomate = listAutomate?.filter((item) => item !== keyAutomateBefore);
			}
			let listFilterBy = name?.filterBy || [];
			const valueNewKey = listFilterBy?.find((item: FilterKeyDto) =>
				listAutomate?.includes(item),
			);
			if (valueNewKey) {
				setKeyAutomateBefore(valueNewKey);
				listFilterBy = listFilterBy?.filter(
					(item: FilterKeyDto) => item !== keyAutomateBefore,
				);
			}
			form.setFieldsValue({ filterBy: listFilterBy });
		}
	};

	const onClearForm = () => {
		form.setFieldsValue(defaultFilter);
		handleApplyFilter(defaultFilter);
		setIsSearch(true);
		setVisibleFilter(false);
	};

	const renderLabel = (icon: any, text: any) => (
		<StyledTitle>
			{icon}
			<StyledTextTitle fontSize="1rem">{text}</StyledTextTitle>
		</StyledTitle>
	);

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	return (
		<StyledWrapperPopup className={`${visibleFilter ? 'show_filter' : 'hidden_filter'}`}>
			<StyledContentFilter ref={refFilter} className="content_filter">
				<StyledContent>
					<StyledAlert
						message={t('email_log.logs.message_filter_notify_info')}
						type="info"
						showIcon
					/>
					<Form
						form={form}
						onFinish={onSubmitForm}
						layout="vertical"
						onValuesChange={onChangeForm}
						initialValues={defaultFilter}>
						<StyledFormItem
							name="sortBy"
							label={renderLabel(<IconSort />, t('email_log.logs.label_sort_by'))}>
							<StyledSelect
								showArrow
								getPopupContainer={(triggerNode: HTMLElement) =>
									triggerNode.parentNode as HTMLElement
								}
								virtual={false}
								defaultValue={sortBy[0].value}
								dropdownClassName="dropdown-menu dropdown-custom-content">
								{sortBy.map((item) => {
									const { name, value } = item;
									return (
										<Option key={value} value={value}>
											{name}
										</Option>
									);
								})}
							</StyledSelect>
						</StyledFormItem>
						<StyledFormItem
							name="filterBy"
							label={renderLabel(null, t('email_log.logs.label_filter_by'))}>
							<StyledCheckboxGroup className="checkbox-group_filter">
								{filterTypes.map((checkbox: any) => (
									<StyledCheckbox
										value={checkbox.value}
										key={checkbox.name}
										disabled={checkbox.disabled}
										fontSize="0.857rem">
										{checkbox.name}
									</StyledCheckbox>
								))}
							</StyledCheckboxGroup>
						</StyledFormItem>
						<StyledFormItem
							name="creatorEmail"
							label={renderLabel(null, t('email_log.logs.label_creator_name'))}>
							<StyledSelect
								mode={'tags'}
								showArrow={true}
								value={creatorEmail}
								notFoundContent={notFoundContent()}
								getPopupContainer={(triggerNode: HTMLElement) =>
									triggerNode.parentNode as HTMLElement
								}
								virtual={false}
								optionFilterProp="label"
								options={[]}
								placeholder={t('email_log.logs.placeholder_filter_creator')}
								dropdownClassName="dropdown-menu dropdown-custom-content"
							/>
						</StyledFormItem>
						<StyledFormItem
							name="rangeDate"
							label={t('email_log.logs.label_filter_date')}>
							<RangePicker dropdownClassName="dropdown_date_filter" />
						</StyledFormItem>
					</Form>
					<StyledFooterFilter>
						<Button key="back" onClick={onClearForm}>
							{t('button.cancel')}
						</Button>
						<Button
							key="submit"
							type="primary"
							htmlType="submit"
							onClick={() => form.submit()}>
							{t('email_log.logs.apply_filter')}
						</Button>
					</StyledFooterFilter>
				</StyledContent>
			</StyledContentFilter>
			<StyledMaskFilter className="mask_filter" />
		</StyledWrapperPopup>
	);
});

export default EmailLogFilter;
