import { Form, Input, Select, Space } from 'antd';
import { forwardRef, useContext, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

// Styled
import AccountUserContext from '@contexts/Settings/AccountUser';
import { SelectOptionDto } from '@models/common/summary';
import { StyledButton, StyledSection } from '@styled/Common/CommonStyled';
import { StyledForm, StyledSelect } from '@styled/Settings/AccountUserStyled';

const { Option } = Select;

const InviteUser = forwardRef((_, ref) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { roles, handleInviteUser } = useContext(AccountUserContext);

	const resetData = () => {
		form.resetFields();
	};

	useImperativeHandle(ref, () => ({
		resetData,
	}));

	return (
		<StyledForm form={form} onFinish={handleInviteUser} requiredMark={false}>
			<Space>
				<Form.Item
					name="email"
					label={t('setting.account_user.send_invite')}
					rules={[
						{
							required: true,
							message: t('setting.account_user.validation.required', {
								field: 'Email',
							}),
						},
						{
							type: 'email',
							message: t('setting.account_user.validation.invalid_email'),
						},
					]}>
					<Input
						style={{ width: 300 }}
						placeholder={t('setting.account_user.placeholder.send_invite')}
					/>
				</Form.Item>

				<Form.Item
					name="roleName"
					label={t('setting.account_user.select_role')}
					rules={[
						{
							required: true,
							message: t('setting.account_user.validation.required', {
								field: 'Role',
							}),
						},
					]}>
					<StyledSelect dropdownClassName="custom-select" style={{ width: 170 }}>
						{roles.map((item: SelectOptionDto) => {
							const { value, label } = item;

							return <Option value={value}>{label}</Option>;
						})}
					</StyledSelect>
				</Form.Item>

				<StyledButton
					htmlType="submit"
					type="primary"
					padding="6px 16px"
					margin="0 0 -8px 0">
					{t('button.invite')}
				</StyledButton>
			</Space>

			<StyledSection
				width="433px"
				title={t('setting.account_user.team_members_desc')}
				className="fixed-section overflow"
				style={{ maxWidth: 'calc(100% - 590px)' }}
				padding="5px 13px"
				margin="0"
				bgColor="rgba(24, 144, 255, 0.1)">
				{t('setting.account_user.team_members_desc')}
			</StyledSection>
		</StyledForm>
	);
});

export default InviteUser;
