import styled from 'styled-components';
import THEME from '@constants/themes/themes';
import Wrapper from '@cores/Wrapper';
import {
	Select,
	Radio,
	Checkbox,
	Form,
	Input,
	Popover,
	Popconfirm,
	Slider,
	InputNumber,
} from 'antd';

const StyledFormItem = styled(Form.Item)`
	margin-bottom: 20px;

	.ant-form-item-label {
		height: 22px;
		padding: 0;
		margin: 0 0 6px 0;
	}
`;

const StyledSliderWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 20px;
	display: grid;
	grid-template-columns: 20% auto 20%;
	column-gap: 15px;
	.ant-form-item-label {
		height: 22px;
		padding: 0;
		margin: 0 0 6px 0;
	}
`;

const StyledInputNumber = styled(InputNumber)`
	margin: 0 !important;
`;

const StyledFilter = styled.div`
	margin-bottom: 16px;
`;

const StyledText = styled.span<{ fontSize?: string }>`
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
`;

const StyledContainer = styled(Wrapper)`
	padding: 26px 24px;
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 250px);
`;

const StyledRadioButton = styled(Radio.Group)<{ fontSize?: string }>`
	.ant-radio-wrapper {
		span {
			color: ${THEME.colors.gray3};
			font-size: ${({ fontSize }) => fontSize || '1rem'};
		}

		.ant-radio-inner {
			border-color: ${THEME.colors.gray3};
		}

		.ant-radio-checked {
			.ant-radio-inner {
				border-color: ${THEME.colors.orangeBase};
			}
		}
	}

	.ant-radio-inner,
	.ant-radio-button-wrapper {
		background-color: ${THEME.colors.darkBlue2};
	}
`;

const StyledSelect = styled(Select)`
	padding: 0;
	width: 100%;
	.ant-select-dropdown {
		&.dropdown-custom-content {
			overflow: auto !important;
			.rc-virtual-list-holder {
				max-height: calc(50vh - 160px) !important;
				.rc-virtual-list-holder-inner {
					transform: translateY(0px) !important;
				}
			}
			.rc-virtual-list-scrollbar {
				display: none !important;
			}
		}
	}
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-disabled .ant-checkbox-inner {
		border-color: ${THEME.colors.darkBlue5} !important;
		background-color: transparent !important;
	}
`;

const StyledCheckbox = styled(Checkbox)<{ fontSize?: string }>`
	width: 45%;
	margin-left: 0 !important;
	margin-bottom: 12px;
	color: ${THEME.colors.gray3};

	.ant-checkbox + span {
		font-size: ${({ fontSize }) => fontSize || '1rem'};
	}

	.ant-checkbox-inner {
	}

	.ant-checkbox-disabled + span {
		color: ${THEME.colors.darkBlue5} !important;
	}
`;

const StyledSlider = styled(Slider)`
	.ant-slider-rail {
		height: 1px;
	}
	.ant-slider-handle {
		margin-top: -4px;
		border-color: ${THEME.colors.orangeBase};
	}
	.ant-slider-track {
		height: 3px;
	}
`;

const StyledTextArea = styled(Input.TextArea)``;

const StyledContentPopover = styled.div``;

const StyledPopover = styled(Popover)``;

const StyledPopconfirm = styled(Popconfirm)``;

export {
	StyledFormItem,
	StyledFilter,
	StyledText,
	StyledContainer,
	StyledRadioButton,
	StyledSelect,
	StyledCheckboxGroup,
	StyledCheckbox,
	StyledTextArea,
	StyledPopover,
	StyledContentPopover,
	StyledPopconfirm,
	StyledSlider,
	StyledSliderWrapper,
	StyledInputNumber,
};
