import { INTEGRATIONS_TAB } from '@constants/settings/integrations';
import React from 'react';

type SettingIntegrationsTypes = {
	typeAction: string;
	setTypeAction: (val: string) => void;
	setActiveTab: (val: string) => void;
	activeTab: string;
	isLoading: boolean;
	setIsLoading: (val: boolean) => void;
};

const values: SettingIntegrationsTypes = {
	typeAction: '',
	setTypeAction: () => {},
	setActiveTab: () => {},
	activeTab: INTEGRATIONS_TAB.TREMENDOUS,
	isLoading: false,
	setIsLoading: () => {},
};

const SettingIntegrationsContext = React.createContext(values);
export default SettingIntegrationsContext;
