import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import styled from 'styled-components';
import { getUserToken, setClientSettings, setFolderSystem } from '@helpers/userHelpers';
import { clientSettingsRequest, folderSystemRequest } from '@stores/actions';

export const StyledHeader = styled(({ collapse, ...props }) => <Layout.Header {...props} />)`
	height: unset;
	position: fixed;
	z-index: 99;
	width: 100%;
	padding: ${({ collapse }) => (collapse ? '0 7.85rem 0 0' : '0 16.5rem 0 0')};
`;

type HeaderProps = {
	children?: JSX.Element;
};

const Header = ({ children, ...rest }: HeaderProps) => {
	const { isCollapsedMenu, clientSettings, folderSystem } = useSelector(
		(state: any) => state.sidebar,
	);
	const dispatch = useDispatch();
	useEffect(() => {
		const userToken = getUserToken();
		if (userToken) {
			dispatch(clientSettingsRequest());
		}
	}, []);

	return (
		<StyledHeader collapse={isCollapsedMenu} {...rest}>
			{children}
		</StyledHeader>
	);
};

Header.defaultProps = {};

export default Header;
