import { Form, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ListItem from '@components/Settings/Content/ListItem';
import { ConfirmModal, LoadingWrapper } from '@cores/index';

// Redux
import { updateFlags, updateFlagsEnd } from '@stores/settings/settings.actions';

// Model
import { SettingRootStateDto } from '@models/settings/setting';

// Styled
import {
	StyleSubTitle,
	StyledButton,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledInput,
	StyledListWrapper,
	StyledTitle,
	StyledWrapper,
	StyledWrapperBox,
} from '@styled/Settings/ContentStyled';

// Icons
import { IconAdd } from '@assets/icons';

// Constant
import { PATTERNS } from '@constants/common';
import { ADD, DELETE, UPDATE } from '@constants/settings';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';

const Flags: React.FC = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { loadingFlags, flags = [] } = useSelector(
		(state: SettingRootStateDto) => state.settings.content,
	);
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isRequired, setIsRequired] = useState<boolean>(true);
	const [selectedFlag, setSelectedFlag] = useState<string>('');
	const [actionType, setActionType] = useState<string>('');
	const nameParent = 'flag';

	useEffect(() => {
		if (loadingFlags === SUCCEEDED) {
			setIsOpenModal(false);
			form.resetFields();
			if (actionType !== '') {
				message.success(t(`setting.message.${actionType.toLowerCase()}_flag_success`));
			}
		}
		if (loadingFlags === FAILED) {
			if (actionType !== '') {
				message.error(t(`setting.message.${actionType.toLowerCase()}_flag_failed`));
			} else {
				message.error(t('setting.message.get_flag_failed'));
			}
		}
		if (loadingFlags === FAILED || loadingFlags === SUCCEEDED) {
			dispatch(updateFlagsEnd());
		}
	}, [loadingFlags]);

	const onToggleOpenModal = (flag: string) => {
		setSelectedFlag(isOpenModal ? '' : flag);
		setIsOpenModal(!isOpenModal);
	};

	const onSubmitDelete = () => {
		const payload = flags.filter((flag: string) => flag !== selectedFlag);
		dispatch(updateFlags(payload));
		setActionType(DELETE);
	};

	const onFinish = (value: any) => {
		const newFlag = value.flag.trim();
		const payload = [newFlag, ...flags];
		dispatch(updateFlags(payload));
		setActionType(ADD);
	};

	const checkDuplicateFlag = (value: string) => {
		const result = flags.some((item: any) => item.trim() === value.trim());
		return result;
	};

	const onEditFolderName = (value: string, index: number) => {
		const check = checkDuplicateFlag(value);
		if (check) {
			message.error(t('setting.validation.duplicate_flag'));
		} else {
			const cloneFlags = [...flags];
			cloneFlags.splice(index, 1, value);
			dispatch(updateFlags(cloneFlags));
			setActionType(UPDATE);
		}
	};

	const renderConfirmModal = () => {
		return (
			<ConfirmModal
				width={400}
				confirmLoading={loadingFlags === IN_PROGRESS}
				okText={t('button.delete')}
				isOpen={isOpenModal}
				onSubmit={onSubmitDelete}
				onClose={onToggleOpenModal}>
				{t('setting.confirm.delete_flag')}
			</ConfirmModal>
		);
	};

	const renderAddForm = () => (
		<StyledForm
			form={form}
			onFinish={onFinish}
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}>
			<StyledFlex>
				<StyledFormItem
					label={
						<StyleSubTitle fontWeight={600}>
							{t('setting.content.add_flags')}
						</StyleSubTitle>
					}
					name="flag"
					rules={[
						{
							required: isRequired,
							validateTrigger: 'onsubmit',
							message: t('setting.validation.flag_required'),
						},
						{
							pattern: PATTERNS.FLAG,
							message: t('setting.validation.no_special_character_required'),
						},
						() => ({
							validator(_: any, value: string) {
								if (!value || !checkDuplicateFlag(value)) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error(t('setting.validation.duplicate_flag')),
								);
							},
						}),
					]}>
					<StyledInput placeholder={t('setting.content.add_flag_placeholder')} />
				</StyledFormItem>
				<StyledFormItem width="fit-content">
					<StyledButton
						onClick={() => {
							const isEmpty = form.getFieldValue('flag') === undefined;
							if (isEmpty) {
								setIsRequired(true);
								setTimeout(() => {
									setIsRequired(false);
									form.validateFields(['flag']);
								}, 2000);
							}
						}}
						htmlType="submit"
						icon={<IconAdd />}
					/>
				</StyledFormItem>
			</StyledFlex>
		</StyledForm>
	);

	const renderFlags = () => (
		<StyledListWrapper padding="0">
			{flags.map((flag: string, index: number) => (
				<ListItem
					nameParent={nameParent}
					index={index}
					onClickAction={onToggleOpenModal}
					onEdit={onEditFolderName}
					key={`${index + 1}`}>
					{flag}
				</ListItem>
			))}
		</StyledListWrapper>
	);

	return (
		<StyledWrapper>
			<StyledTitle fontSize="1.143rem" fontWeight="normal">
				{t('setting.content.flags')}
			</StyledTitle>
			<StyledWrapperBox>
				{renderAddForm()}
				<LoadingWrapper isLoading={loadingFlags === IN_PROGRESS}>
					{renderFlags()}
				</LoadingWrapper>
			</StyledWrapperBox>
			{renderConfirmModal()}
		</StyledWrapper>
	);
};

export default Flags;
