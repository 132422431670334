// ico
import {
	IconAnalytics,
	IconCRM,
	IconCampaignOutLine,
	IconEmail,
	IconEmojiEvents,
	IconGallery,
	IconImage,
	IconSearch,
	IconSettings,
} from '@assets/icons';
import { NAME_NAVIGATION, ROUTE_PATH } from './common';

export type SidebarTypes = {
	name: string;
	path: string;
	ico?: any;
	hidden?: boolean;
	child?: SidebarTypes[];
	keyActive?: string;
	isSession?: boolean;
	headerName?: string;
};

const Sidebars: SidebarTypes[] = [
	{ name: NAME_NAVIGATION.DASHBOARD, path: ROUTE_PATH.DASHBOARD, ico: <IconAnalytics /> },
	{ name: NAME_NAVIGATION.COLLECT, path: '/collect', isSession: true },
	{
		name: NAME_NAVIGATION.ACTIVATIONS,
		path: '',
		ico: <IconCampaignOutLine />,
		child: [
			{ name: NAME_NAVIGATION.CAMPAIGNS, path: ROUTE_PATH.CAMPAIGNS },
			{ name: NAME_NAVIGATION.WIDGET_MANAGER, path: ROUTE_PATH.WIDGET_MANAGER },
			{
				name: NAME_NAVIGATION.LANDING_PAGES,
				path: ROUTE_PATH.LANDING_PAGES,
			},
			{
				name: NAME_NAVIGATION.WIDGET_DETAIL,
				path: `${ROUTE_PATH.WIDGET_MANAGER}/`,
				keyActive: ROUTE_PATH.WIDGET_MANAGER,
				hidden: true,
			},
			{
				name: NAME_NAVIGATION.CAMPAIGNS,
				path: `${ROUTE_PATH.CAMPAIGNS}/`,
				keyActive: ROUTE_PATH.CAMPAIGNS,
				hidden: true,
			},
		],
	},
	{
		name: NAME_NAVIGATION.SOCIAL_SEARCH,
		path: '',
		ico: <IconSearch />,
		child: [
			{
				name: NAME_NAVIGATION.FEEDS,
				path: ROUTE_PATH.FEEDS,
			},
			{
				name: NAME_NAVIGATION.FEEDS,
				path: `${ROUTE_PATH.FEEDS}/`,
				keyActive: ROUTE_PATH.FEEDS,
				hidden: true,
			},
			// { name: NAME_NAVIGATION.DISCOVERY, path: ROUTE_PATH.SOCIAL_SEARCH },
			{ name: NAME_NAVIGATION.BOOKMARKS, path: ROUTE_PATH.BOOKMARKS },
		],
	},
	{ name: NAME_NAVIGATION.ORGANIZE, path: '/organize', isSession: true },
	{
		name: NAME_NAVIGATION.CONTENT_LIBRARY,
		path: '',
		ico: <IconImage />,
		child: [
			{ name: NAME_NAVIGATION.SUBMISSIONS, path: ROUTE_PATH.SUBMISSIONS },
			{
				name: NAME_NAVIGATION.FAVORITES,
				keyActive: ROUTE_PATH.FAVORITE,
				path: ROUTE_PATH.FAVORITE,
			},
			{ name: NAME_NAVIGATION.ARCHIVE, path: ROUTE_PATH.ARCHIVE },
			{ name: NAME_NAVIGATION.ALBUMS, path: ROUTE_PATH.ALBUMS },
			{
				name: NAME_NAVIGATION.ALBUMS,
				path: `${ROUTE_PATH.ALBUMS}/`,
				keyActive: ROUTE_PATH.ALBUMS,
				hidden: true,
			},
		],
	},
	{ name: NAME_NAVIGATION.PUBLISH, path: '/publish', isSession: true },
	{ name: NAME_NAVIGATION.GALLERY, path: ROUTE_PATH.GALLERY, ico: <IconGallery /> },
	{ name: NAME_NAVIGATION.MANAGE_CREATORS, path: '/manage-creator', isSession: true },

	{
		name: NAME_NAVIGATION.CRM,
		path: '',
		ico: <IconCRM />,
		child: [
			{
				name: NAME_NAVIGATION.AMBASSADOR_DASHBOARD,
				path: ROUTE_PATH.AMBASSADOR_DASHBOARD,
			},
			{ name: NAME_NAVIGATION.CREATORS_SUMMARY, path: ROUTE_PATH.CREATORS_SUMMARY },
			{ name: NAME_NAVIGATION.CREATORS_SEGMENTS, path: ROUTE_PATH.CREATORS_SEGMENTS },
			{
				name: NAME_NAVIGATION.CREATORS_SUMMARY,
				path: ROUTE_PATH.CREATOR_DETAIL_EMPTY,
				hidden: true,
				keyActive: ROUTE_PATH.CREATORS_SUMMARY,
			},
		],
	},
	{
		name: NAME_NAVIGATION.MESSAGING,
		path: '',
		ico: <IconEmail />,
		child: [
			{ name: NAME_NAVIGATION.MESSAGE_LOG, path: ROUTE_PATH.MESSAGE_LOG },
			{ name: NAME_NAVIGATION.EMAIL_BUILDER, path: ROUTE_PATH.EMAIL_BUILDER },
			{ name: NAME_NAVIGATION.EMAIL_TEMPLATES, path: ROUTE_PATH.EMAIL_TEMPLATES },
		],
	},
	{
		name: NAME_NAVIGATION.REWARDS,
		path: '',
		ico: <IconEmojiEvents />,
		child: [
			{ name: NAME_NAVIGATION.REWARDS_HISTORY, path: ROUTE_PATH.REWARDS_HISTORY },
			{ name: NAME_NAVIGATION.REWARDS_INVENTORY, path: ROUTE_PATH.REWARDS_INVENTORY },
		],
	},
	{
		name: NAME_NAVIGATION.SETTINGS,
		path: '',
		ico: <IconSettings />,
		child: [
			{
				name: NAME_NAVIGATION.SETTINGS_ACCOUNT_USERS,
				path: ROUTE_PATH.SETTINGS_ACCOUNT_USERS,
			},
			{
				name: NAME_NAVIGATION.SETTING_GUEST_PORTALS,
				path: ROUTE_PATH.SETTING_GUEST_PORTALS,
			},
			{ name: NAME_NAVIGATION.SETTINGS_USER_PROFILE, path: ROUTE_PATH.SETTINGS_USER_PROFILE },
			{
				name: NAME_NAVIGATION.TASK_MANAGER,
				path: ROUTE_PATH.TASK_MANAGER,
			},
			// {
			// 	name: NAME_NAVIGATION.SETTINGS_SOCIAL_SEARCH,
			// 	path: ROUTE_PATH.SETTINGS_SOCIAL_SEARCH,
			// },
			{
				name: NAME_NAVIGATION.SETTINGS_INTEGRATIONS,
				path: ROUTE_PATH.SETTINGS_INTEGRATIONS,
			},
			{
				name: NAME_NAVIGATION.SETTINGS_SOCIAL_AGGREGATOR,
				path: ROUTE_PATH.SETTINGS_SOCIAL_AGGREGATOR,
			},
			{ name: NAME_NAVIGATION.SETTINGS_CONTENT, path: ROUTE_PATH.SETTINGS_CONTENT },
			{
				name: NAME_NAVIGATION.CONTENT_LICENSING,
				path: ROUTE_PATH.CONTENT_LICENSING,
				headerName: NAME_NAVIGATION.CONTENT_LICENSING_HEADER,
			},
			{
				name: NAME_NAVIGATION.CONTENT_LICENSING,
				path: `${ROUTE_PATH.CONTENT_LICENSING}/`,
				keyActive: ROUTE_PATH.CONTENT_LICENSING,
				hidden: true,
			},
		],
	},

	// {
	// 	name: 'sidebar.help_center',
	// 	path: '/help-center',
	// 	ico: <IconHelp />,
	// },
];

export default Sidebars;
