const NAME_FILTER_CONTENT = {
	QUERY_OPERATOR: 'queryOperator',
	MEDIA_TYPE: 'mediaType',
	RANGE_RATING: 'rangeRating',
	RATING: 'rating',
	NOT_RATE: 'notRate',
	TAGS: 'tags',
	EXCLUDED_TAGS: 'excludedTags',
	STATUS: 'status',
	RIGHT_CLEAR: 'rightClear',
	PUBLISHED: 'published',
	GALLERIES: 'galleries',
	CAMPAIGNS: 'campaigns',
	UPLOAD_DATE: 'uploadDate',
	UPLOAD_LOCATION: 'locations',
	KEYWORD: 'keyword',
	CREATORS: 'creatorInfo',
	CREATOR_SEGMENT: 'creatorSegments',
	ASSOCIATED_REWARDS: 'rewards',
	LABELS: 'labels',
	FLAGS: 'flags',
	GOOGLE_AI_TAGS: 'googleAIHashtags',
	EXCLUDED_LABELS: 'excludedLabels',
	EXCLUDED_FLAGS: 'excludedFlags',
	EXCLUDED_GOOGLE_AI_TAGS: 'excludedGoogleAIHashtags',
	ASPECT_RATIOS: 'aspectRatios',
	RESOLUTIONS: 'resolutions',
	SOCIAL_CHANNEL: 'socialChannels',
	DOWNLOADED: 'downloaded',
	RESOLUTION_QUALITY: 'resolutionQuality',
	LOGOS_DETECTED: 'logoDetectionLabels',
	VIDEO_LABEL_DETECTED: 'videoDetectionLabels',
	CONTENT_SOURCES: 'contentSources',
	DEVICE_MAKES: 'deviceMakers',
	DEVICE_MODELS: 'deviceModels',
	SHOT_LOCATION: 'shotCountries',
	CUSTOM_FIELDS: 'customFields',
	GROUP_CUSTOM_FIELDS: 'customFields',
};

const CONTENT_SOURCES = {
	ALL_DIRECT_UPLOAD: 'ALL_DIRECT_UPLOAD',
	SHARED_FROM_GOOGLE_DRIVE: 'SHARED_FROM_GOOGLE_DRIVE',
	SHARED_FROM_YOUTUBE: 'SHARED_FROM_YOUTUBE',
	SHARED_FROM_TIKTOK: 'SHARED_FROM_TIKTOK',
	SHARED_FROM_INSTAGRAM: 'SHARED_FROM_INSTAGRAM',
	MANUAL_IMPORT: 'MANUAL_IMPORT',
	SCRIPT_IMPORT: 'SCRIPT_IMPORT',
};

const DEFAULT_RANGE_RATING = [0, 5];

const MARK_RATING = {
	0: '0',
	1: '1',
	2: '2',
	3: '3',
	4: '4',
	5: '5',
};

const TYPE_FILTER = {
	ALL: 'all',
	PHOTO: 'image',
	VIDEO: 'video',
};

const STATUS_FILTER = {
	ANY: 'any',
	NEEDS_REVIEW: 'needsReview',
	REVIEWED: 'Reviewed',
};

const RIGHT_CLEAR_FILTER = {
	NONE: 'None',
	PENDING: 'Pending',
	APPROVED: 'Approved',
	CUSTOM_TERMS: 'CustomTerms',
};

const FILTER_PUBLISHED = {
	ANY: 'any',
	YES: 'yes',
	NO: 'no',
};

const FILTER_SOCIAL = {
	INSTAGRAM: 'INSTAGRAM',
	TWITTER: 'TWITTER',
	TIKTOK: 'TIKTOK',
	YOUTUBE: 'YOUTUBE',
};

const SORT_DIRECTION = {
	ASC: 'ASC',
	DESC: 'DESC',
};

const TYPE_SORT = {
	SORT_DATE: 'createdAt',
	SORT_RATING: 'star',
};

const SAVED_CONTENT_FILTER_TYPES = {
	SYSTEM_PENDING_CONTENT: 'SYSTEM_PENDING_CONTENT',
	SYSTEM_OUTDATED_CONTENT: 'SYSTEM_OUTDATED_CONTENT',
	CUSTOM: 'CUSTOM',
};

const RESOLUTION_RANK = {
	HIGH: 'HIGH',
	MEDIUM: 'MEDIUM',
	LOW: 'LOW',
};

const SORT_BY = {
	TIME_STAMP: 'timestamp',
	FOLLOWERS: 'followers',
};

export {
	NAME_FILTER_CONTENT,
	TYPE_FILTER,
	STATUS_FILTER,
	RIGHT_CLEAR_FILTER,
	FILTER_PUBLISHED,
	FILTER_SOCIAL,
	TYPE_SORT,
	SORT_DIRECTION,
	SAVED_CONTENT_FILTER_TYPES,
	RESOLUTION_RANK,
	SORT_BY,
	DEFAULT_RANGE_RATING,
	MARK_RATING,
	CONTENT_SOURCES,
};
