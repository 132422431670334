import { createAction } from 'redux-actions';
import {
	checkLimitNotificationTypes,
	getAllNotificationTypes,
	getNotifyAccountTypes,
	getTaskNotificationTypes,
	markTaskNotificationAsReadTypes,
	toggleTaskNotificationStatusTypes,
	updateNotificationInfoTypes,
	updateStatusNotificationTypes,
} from './notification.types';

// get all notification
export const getAllNotificationRequest = createAction(
	getAllNotificationTypes.GET_NOTIFICATION_REQUEST,
);
export const getAllNotificationSucceeded = createAction(
	getAllNotificationTypes.GET_NOTIFICATION_SUCCEEDED,
);
export const getAllNotificationFailed = createAction(
	getAllNotificationTypes.GET_NOTIFICATION_FAILED,
);

// get notification account
export const fetchNotificationAccountRequest = createAction(
	getNotifyAccountTypes.GET_NOTIFICATION_ACCOUNT_REQUEST,
);
export const fetchNotificationAccountSucceeded = createAction(
	getNotifyAccountTypes.GET_NOTIFICATION_ACCOUNT_SUCCEEDED,
);
export const fetchNotificationAccountFailed = createAction(
	getNotifyAccountTypes.GET_NOTIFICATION_ACCOUNT_FAILED,
);

// reset notify account
export const resetNotificationAccount = createAction(getNotifyAccountTypes.RESET_NOTIFY_ACCOUNT);

// update status notification
export const updateStatusNotificationRequest = createAction(
	updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_REQUEST,
);
export const updateStatusNotificationSucceeded = createAction(
	updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_SUCCEEDED,
);
export const updateStatusNotificationFailed = createAction(
	updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_FAILED,
);
export const updateStatusNotificationEnd = createAction(
	updateStatusNotificationTypes.UPDATE_STATUS_NOTIFICATION_END,
);

// update notification info for state
export const updateNotificationInfo = createAction(
	updateNotificationInfoTypes.UPDATE_NOTIFICATION_INFO,
);
export const changeFieldsStoreNotifyData = createAction(
	updateNotificationInfoTypes.CHANGE_FIELDS_STORE_NOTIFY,
);

// Check limit notification
export const checkLimitNotificationRequest = createAction(
	checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_REQUEST,
);
export const checkLimitNotificationSucceeded = createAction(
	checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_SUCCEEDED,
);
export const checkLimitNotificationFailed = createAction(
	checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_FAILED,
);
export const checkLimitNotificationEnd = createAction(
	checkLimitNotificationTypes.CHECK_LIMIT_NOTIFICATION_END,
);

// Get task notification
export const getTaskNotificationRequest = createAction(
	getTaskNotificationTypes.GET_TASK_NOTIFICATION_REQUEST,
);
export const getTaskNotificationSucceeded = createAction(
	getTaskNotificationTypes.GET_TASK_NOTIFICATION_SUCCEEDED,
);
export const getTaskNotificationFailed = createAction(
	getTaskNotificationTypes.GET_TASK_NOTIFICATION_FAILED,
);
export const getTaskNotificationEnd = createAction(
	getTaskNotificationTypes.GET_TASK_NOTIFICATION_END,
);

// Toggle task notification
export const toggleTaskNotificationStatusRequest = createAction(
	toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_REQUEST,
);
export const toggleTaskNotificationStatusSucceeded = createAction(
	toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_SUCCEEDED,
);
export const toggleTaskNotificationStatusFailed = createAction(
	toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_FAILED,
);
export const toggleTaskNotificationStatusEnd = createAction(
	toggleTaskNotificationStatusTypes.TOGGLE_TASK_NOTIFICATION_STATUS_END,
);

// Mark task notification as read
export const markTaskNotificationAsReadRequest = createAction(
	markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_REQUEST,
);
export const markTaskNotificationAsReadSucceeded = createAction(
	markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_SUCCEEDED,
);
export const markTaskNotificationAsReadFailed = createAction(
	markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_FAILED,
);
export const markTaskNotificationAsReadEnd = createAction(
	markTaskNotificationAsReadTypes.MARK_TASK_NOTIFICATION_AS_READ_END,
);
