import { takeLatest, put } from 'redux-saga/effects';
import { getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import genericApiSaga from '@helpers/genericApiSaga';
import {
	userLoginCompleted,
	userLoginFailed,
	USER_LOGIN,
	userLogoutCompleted,
	userLogoutFailed,
	USER_LOGOUT,
	GET_ACCOUNT,
	SWITCH_CLIENT,
	CREATE_ACCOUNT,
	ACTIVE_ACCOUNT,
	CHANGE_PASSWORD,
	UPDATE_PROFILE,
	updateProfileSucceeded,
	SEND_EMAIL_RESET_PWD,
	CHECK_TOKEN_RESET,
	GET_EMAIL_RESET,
	RESET_PASSWORD,
	RENEW_INVITE_ACCOUNT,
	GET_ACCOUNT_USER_PERMISSION,
	getAccountUserPermissionFailed,
	getAccountUserPermissionSucceeded,
	verifyPasswordSucceeded,
	verifyPasswordFailed,
	VERIFY_PASSWORD_REQUEST,
} from './user.actions';
import * as actionTypes from './user.actions';

import {
	CHANGE_PASSWORD_API,
	LIST_ACCOUNT,
	STATUSCODE,
	USER_LOGIN as USER_LOGIN_API,
	USER_LOGOUT as USER_LOGOUT_API,
	ACTIVE_ACCOUNT as ACTIVE_ACCOUNT_API,
	UPDATE_PROFILE_USER,
	REGISTER_ACCOUNT,
	FORGOT_PASSWORD,
	RENEW_INVITE_ACCOUNT as RENEW_INVITE_ACCOUNT_API,
	ACCOUNT_USER_PERMISSION,
	VERIFY_PASSWORD,
	PAIR_AUTHENTICATOR_APP,
	CONFIRM_MFA_CODE,
	LOGIN_WITH_MFA,
	DISABLE_MFA,
	ACCOUNT_INFO_API,
} from '@constants/APIs';
import { getUserToken } from '@helpers/userHelpers';
import { Action } from 'redux-actions';
import { DEFAULT_ERROR } from '@constants/errors';
import { initPendoSucceeded } from '@stores/actions';
import { convertParamSearch, revokeGoogleRefreshToken } from '@utils/common';

function* userLogin({ payload: { params } }: any) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${USER_LOGIN_API}`, params),
		*completed(response: any) {
			yield put(userLoginCompleted(response.data));
		},
		*failed(response: any) {
			yield put(userLoginFailed(response));
		},
	});
}

function* userLogout() {
	const userToken = getUserToken();

	yield genericApiSaga({
		gatewayCall: () => getRequest(`${USER_LOGOUT_API}?token=${userToken}`),
		*completed(response: any) {
			revokeGoogleRefreshToken(); // revoke Google's refresh token
			yield put(userLogoutCompleted(response.data));
			yield put(initPendoSucceeded(null));
		},
		*failed(response: any) {
			yield put(userLogoutFailed(response));
		},
	});
}

function* getAccount({ payload }: any) {
	const { getClientSucceeded, getClientFailed } = payload;

	yield genericApiSaga({
		gatewayCall: () => getRequest(LIST_ACCOUNT),
		completed(response: any) {
			getClientSucceeded(response.data.result);
		},
		failed(response: any) {
			getClientFailed(response.message);
		},
	});
}

function* switchClient({ payload }: any) {
	const { url, switchClientSucceeded, switchClientFailed } = payload;

	yield genericApiSaga({
		gatewayCall: () => postRequest(url),
		*completed(response: any) {
			switchClientSucceeded(response.data.result);
			yield put(userLoginCompleted(response.data));
			yield put(initPendoSucceeded(null));
		},
		*failed(response: any) {
			switchClientFailed(response.data.error);
		},
	});
}

function* activeAccount({ payload }: any) {
	const { params, checkStatusFailed, checkNewAccount, checkExistingAccount } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(ACTIVE_ACCOUNT_API, params, undefined, undefined),
		*completed(response: any) {
			const userData = response.data.result[0];
			if (userData.id) {
				checkExistingAccount(userData);
			} else {
				checkNewAccount(userData);
			}
		},
		*failed(response: any) {
			checkStatusFailed(response.data.status.code);
		},
	});
}

function* renewInviteAccount({ payload }: any) {
	const { params, renewInviteSucceeded, renewInviteFailed } = payload;
	const url = `${RENEW_INVITE_ACCOUNT_API}?token=${params.token}`;
	yield genericApiSaga({
		gatewayCall: () => postRequest(url),
		*completed(response: any) {
			renewInviteSucceeded();
		},
		*failed(response: any) {
			renewInviteFailed(response.data.status.code);
		},
	});
}

function* createAccount({ payload }: any) {
	const { params, createAccountSucceeded, createAccountFailed } = payload;
	const { alternativeToken, clientId, userData } = params;
	const url = `${REGISTER_ACCOUNT}?clientId=${clientId}&token=${alternativeToken}`;
	yield genericApiSaga({
		gatewayCall: () => postRequest(url, userData, undefined, undefined),
		*completed(response: any) {
			createAccountSucceeded();
		},
		*failed(response: any) {
			createAccountFailed();
		},
	});
}

function* changePassword({ payload }: Action<any>) {
	const { params, changePasswordSucceded, changePasswordFailed } = payload;

	yield genericApiSaga({
		gatewayCall: () => putRequest(CHANGE_PASSWORD_API, params),
		*completed(response: any) {
			if (response) {
				const {
					status: { code = 0 },
					result,
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					changePasswordSucceded(result);
				}
			}
		},
		*failed(response: any) {
			if (response) {
				const { status = {} } = response.data;
				let { error = DEFAULT_ERROR } = response.data;

				error = error === null ? `${status.code} ${status.status}` : error;
				changePasswordFailed(error);
			}
		},
	});
}

function* sendEmailResetPwd({ payload }: Action<any>) {
	const {
		params: { email },
		onSucceeded,
		onFailed,
	} = payload;

	const url = `${FORGOT_PASSWORD}/send-email?email=${encodeURIComponent(email)}`;

	yield genericApiSaga({
		gatewayCall: () => postRequest(url),
		*completed(response: any) {
			if (response) {
				const {
					status: { code = 0 },
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					onSucceeded();
				}
			}
		},
		*failed(response: any) {
			if (response) {
				const { status = {} } = response.data;
				let { error = DEFAULT_ERROR } = response.data;

				error = error === null ? `${status.code} ${status.status}` : error;
				onFailed(error);
			}
		},
	});
}

function* checkTokenReset({ payload }: Action<any>) {
	const {
		params: { token },
		onSucceeded,
		onFailed,
	} = payload;

	const url = `${FORGOT_PASSWORD}/check-token?token=${token}`;

	yield genericApiSaga({
		gatewayCall: () => postRequest(url),
		*completed(response: any) {
			if (response) {
				onSucceeded(response);
			}
		},
		*failed(response: any) {
			if (response) {
				onFailed(response);
			}
		},
	});
}

function* getEmailReset({ payload }: Action<any>) {
	const {
		params: { token },
		onSucceeded,
		onFailed,
	} = payload;

	const url = `${FORGOT_PASSWORD}/get-email?token=${token}`;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			if (response) {
				const {
					status: { code = 0 },
					result,
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					onSucceeded(result);
				}
			}
		},
		*failed(response: any) {
			if (response) {
				const { status = {} } = response.data;
				let { error = DEFAULT_ERROR } = response.data;

				error = error === null ? `${status.code} ${status.status}` : error;
				onFailed(error);
			}
		},
	});
}

function* resetPassword({ payload }: Action<any>) {
	const { params, onSucceeded, onFailed } = payload;

	const url = `${FORGOT_PASSWORD}/reset-pwd`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, params),
		*completed(response: any) {
			if (response) {
				const {
					status: { code = 0 },
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					onSucceeded();
				}
			}
		},
		*failed(response: any) {
			if (response) {
				const { status = {} } = response.data;
				let { error = DEFAULT_ERROR } = response.data;

				error = error === null ? `${status.code} ${status.status}` : error;
				onFailed(error);
			}
		},
	});
}

function* updateProfile({ payload }: Action<any>) {
	const { params, onSucceeded, onFailed } = payload;

	yield genericApiSaga({
		gatewayCall: () => putRequest(UPDATE_PROFILE_USER, params),
		*completed(response: any) {
			if (response) {
				const {
					status: { code = 0 },
					result,
				} = response.data;

				// TODO, will be update once we have offical response data format from BE
				if (code === STATUSCODE.SUCCESS) {
					onSucceeded(result);
					yield put(updateProfileSucceeded(response.data));
				}
			}
		},
		*failed(response: any) {
			if (response) {
				const { status = {} } = response.data;
				let { error = DEFAULT_ERROR } = response.data;

				error = error === null ? `${status.code} ${status.status}` : error;
				onFailed(error);
			}
		},
	});
}

export function* fetchAccountUserPermissionRequest() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(ACCOUNT_USER_PERMISSION),
		*completed(response: any) {
			yield put(getAccountUserPermissionSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(getAccountUserPermissionFailed(response));
		},
	});
}

function* verifyPassword({ payload }: Action<{ password: string }>) {
	const { password } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${VERIFY_PASSWORD}?password=${password}`),
		*completed(response: any) {
			yield put(verifyPasswordSucceeded(response));
		},
		*failed(response: any) {
			yield put(verifyPasswordFailed(response));
		},
	});
}

function* pairAuthenticatorApp() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(PAIR_AUTHENTICATOR_APP),
		*completed(response: any) {
			yield put(actionTypes.pairAuthenticatorAppSucceeded(response));
		},
		*failed(response: any) {
			yield put(actionTypes.pairAuthenticatorAppFailed(response));
		},
	});
}

function* confirmMFAPairing({ payload }: Action<{ secretKey: string; code: string }>) {
	const searchParams = convertParamSearch(payload);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${CONFIRM_MFA_CODE}?${searchParams}`),
		*completed(response: any) {
			yield put(actionTypes.confirmMFAPairingSucceeded(response));
		},
		*failed(response: any) {
			yield put(actionTypes.confirmMFAPairingFailed(response));
		},
	});
}

function* loginWithMFA({ payload }: Action<{ token: string; code: string }>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(LOGIN_WITH_MFA, { ...payload }),
		*completed(response: any) {
			yield put(userLoginCompleted(response.data));
		},
		*failed(response: any) {
			yield put(userLoginFailed(response));
		},
	});
}

function* disableMFA() {
	yield genericApiSaga({
		gatewayCall: () => putRequest(DISABLE_MFA),
		*completed(response: any) {
			yield put(actionTypes.disableMFASucceeded(response));
		},
		*failed(response: any) {
			yield put(actionTypes.disableMFAFailed(response));
		},
	});
}

function* getInfoUser() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(ACCOUNT_INFO_API),
		*completed(response: any) {
			yield put(actionTypes.getInfoUserSucceeded(response.data));
		},
		*failed(response: any) {
			yield put(actionTypes.getInfoUserFailed(response));
		},
	});
}

function* createUserSagas() {
	yield takeLatest(USER_LOGIN, userLogin);
	yield takeLatest(USER_LOGOUT, userLogout);
	yield takeLatest(GET_ACCOUNT, getAccount);
	yield takeLatest(SWITCH_CLIENT, switchClient);
	yield takeLatest(ACTIVE_ACCOUNT, activeAccount);
	yield takeLatest(CREATE_ACCOUNT, createAccount);
	yield takeLatest(RENEW_INVITE_ACCOUNT, renewInviteAccount);
	yield takeLatest(CHANGE_PASSWORD, changePassword);
	yield takeLatest(UPDATE_PROFILE, updateProfile);
	yield takeLatest(SEND_EMAIL_RESET_PWD, sendEmailResetPwd);
	yield takeLatest(CHECK_TOKEN_RESET, checkTokenReset);
	yield takeLatest(GET_EMAIL_RESET, getEmailReset);
	yield takeLatest(RESET_PASSWORD, resetPassword);
	yield takeLatest(GET_ACCOUNT_USER_PERMISSION, fetchAccountUserPermissionRequest);
	yield takeLatest(VERIFY_PASSWORD_REQUEST, verifyPassword);
	yield takeLatest(actionTypes.PAIR_AUTHENTICATOR_APP_REQUEST, pairAuthenticatorApp);
	yield takeLatest(actionTypes.CONFIRM_MFA_PAIRING_REQUEST, confirmMFAPairing);
	yield takeLatest(actionTypes.LOGIN_WITH_MFA_REQUEST, loginWithMFA);
	yield takeLatest(actionTypes.DISABLE_MFA_REQUEST, disableMFA);
	yield takeLatest(actionTypes.GET_INFO_USER_REQUEST, getInfoUser);
}

export default createUserSagas;
