import THEME from '@constants/themes/themes';
import { Form, Tooltip } from 'antd';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)``;

export const StyledFormItem = styled(({ color, isDisabled, ...props }) => <Form.Item {...props} />)`
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};
	opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};

	margin-bottom: 38px;

	.ant-form-item-label {
		label {
			color: ${({ color }) => color || 'unset'};
		}
	}

	.ant-form-item-explain,
	.ant-form-item-explain-error {
		position: absolute;
		right: 0;
		bottom: -10px;
	}

	.ant-form-item-children-icon {
		:not(.anticon-close-circle) {
			svg {
				fill: none;
			}
		}
		.anticon-close-circle {
			svg {
				fill: ${THEME.colors.redBase};
			}
		}
	}

	.error-tooltip {
		.ant-tooltip-inner {
			border-radius: none !important;
		}
	}
`;
