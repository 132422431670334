export const GOOGLE_FONT = {
	IBM_FLEX_SERIF:
		'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet',
	IBM_FLEX_SANS:
		'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap',
	ROPA_SANS:
		'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Ropa+Sans:ital@0;1&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap',
};

export const BOOTSTRAP_CDN = {
	CSS: 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css',
	BUNDLE: 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js',
	POPPER: 'https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js',
	MAIN: 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js',
};

export const canvasCss = `
	/* Layout */
	.gjs-dashed .container, .container-md, .gjs-dashed .container-fluid,
	.gjs-dashed [class^="col-"] {
	min-height: 1.5rem !important;
	}

	.gjs-dashed .w-100 {
	min-height: .25rem !important;
	background-color: rgba(0,0,0,0.1);
	}
	
	.gjs-dashed img {
	min-width: 25px;
	min-height: 25px;
	}
`;

export const ELEMENT_SELECTOR = {
	GJS_FRAME: 'gjs-frame',
	UPLOADER_MODAL: 'entribe-popup_uploader-modal-root',
	UPLOADER_BTN: 'elm_render_widget_id_',
};

export const cssText =
	'body * { font-size: 18px; font-family: IBM Plex Sans, sans-serif; } body { padding: 15px; overflow: auto !important; } p { margin: 0; padding: 0; } h1,h2,h3,h4 { font-family: IBM Plex Serif, sans-serif; font-weight: 600;} h1 { font-size: 60px; } h2 { font-size: 44px; } h3 { font-size: 32px; } h4 { font-size: 24px; } .entribe_gallery_v100_2023 h1 { font-weight: normal; } @media only screen and (max-width: 768px) { body * { font-size: 16px; } h1 { font-size: 33px; } h2 { font-size: 28px; } h3 { font-size: 23px; } h4 { font-size: 19px; } img:not(.footer img, .slick-slide img) { max-width: 100%; height: auto !important; } } @media only screen and (max-width: 576px) { button { max-width: 100%; } .container { width: 100% !important; }}';

export const CONFIRMATION_MODAL_TYPES = {
	CLEAR_ALL: 'CLEAR_ALL',
};

export const GJS_COMMAND = {
	CANVAS_CLEAR: 'canvas-clear',
};

export const ACTION_TYPE_ON_LANDING_PAGE = {
	BUILD_A_NEW_LANDING_PAGE: 'BUILD_A_NEW_LANDING_PAGE',
	EDIT_LANDING_PAGE: 'EDIT_LANDING_PAGE',
	EDIT_LANDING_PAGE_WITH_REFERENCE: 'EDIT_LANDING_PAGE_WITH_REFERENCE',
	DELETE: 'DELETE',
	SET_AS_DEFAULT: 'SET_AS_DEFAULT',
	SAVE_DRAFT: 'SAVE_DRAFT',
	PUBLISH_CHANGES: 'PUBLISH_CHANGES',
	UPDATE_LANDING_PAGE_INFORMATION: 'UPDATE_LANDING_PAGE_INFORMATION',
	UPDATE_LANDING_PAGE_INFORMATION_WITH_URL: 'UPDATE_LANDING_PAGE_INFORMATION_WITH_URL',
	UNPUBLISH: 'UNPUBLISH',
	DUPLICATE: 'DUPLICATE',
};

export const LANDING_PAGE_STATUS = {
	DRAFT: 'DRAFT',
	PUBLISHED: 'PUBLISHED',
};

export const DEFAULT_PAYLOAD_GETTING_LIST = {
	page: 1,
	maxRecords: 50,
	sortDirection: 'DESC',
	statuses: [LANDING_PAGE_STATUS.PUBLISHED],
};

export const DEFAULT_LANDING_PAGE_VALUES = {
	description: '',
	metadata: {
		metaTitle: '{{Brand Name}} Community Landing Page',
		metaDescription:
			'Join the growing community of advocates and ambassadors for {{Brand Name}}',
	},
};

export const LANDING_PAGE_SELECTORS = {
	LANDING_PAGE_LIST_ID: 'landing-page-list',
	LANDING_PAGE_NAME: 'landing-page-name',
};

export const FIELD_NAME = {
	NAME: 'name',
	DESCRIPTION: 'description',
	LANDING_PAGE_URL: 'url',
	META_TITLE: 'metaTitle',
	META_DESCRIPTION: 'metaDescription',
};

export const ADDITIONAL_FONTS = [
	{
		value: 'IBM Plex Serif, sans-serif',
		name: 'IBM Plex Serif',
	},
	{
		value: 'IBM Plex Sans, sans-serif',
		name: 'IBM Plex Sans',
	},
	{
		value: 'Ropa Sans, sans-serif',
		name: 'Ropa Sans',
	},
];
