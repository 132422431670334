import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { STATUSCODE } from '@constants/APIs';
import { EMAIL_TEMPLATE_FOLDER_TYPES, emailTemplate } from '@constants/messages';
import { ConfirmModal, Empty } from '@cores/index';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import {
	enableTemplateAction,
	getDynamicFieldsRequest,
	getEmailTemplatesRequest,
	selectAutomatedEmailTemplate,
	updateEmailTemplatesRequest,
} from '@stores/actions';
import { StyledCenterContainer } from '@styled/Messages/EmailTemplatesStyled';
import EditTemplateModal from '../../EditTemplateModal';
import MenuTemplates from '../MenuTemplates';

const Automated: React.FC = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		emailTemplate: {
			getEmailTemplateStatus = false,
			restoreTemplateStatus = 0,
			automated: {
				templates = [],
				editTemplateSuccessfully = 0,
				trigger = '',
				isEnabled = null,
				isArchived = false,
				turnOnOffAction = false,
			} = {},
		} = {},
	} = useSelector((state: any) => state.messages);
	const [visible, setVisible] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [template, setTemplate] = useState<EmailTemplatesDataDto>(emailTemplate);
	const [payloadArchived, setPayloadArchived] = useState<any>({});

	useEffect(() => {
		if (editTemplateSuccessfully === STATUSCODE.SUCCESS) {
			if (isArchived) {
				message.success(t('email_templates.notification.archived_successfully'));
			} else {
				if (turnOnOffAction) {
					displayMessage();
				} else {
					message.success(t('email_templates.notification.save_changes_successfully'));
					setVisible(false);
				}
			}

			dispatch(getEmailTemplatesRequest({ type: EMAIL_TEMPLATE_FOLDER_TYPES.DEFAULT }));
			setOpenModal(false);
		}
	}, [editTemplateSuccessfully, turnOnOffAction]);

	useEffect(() => {
		if (restoreTemplateStatus === STATUSCODE.SUCCESS) {
			updateTemplateStateAfterEdit();
		} else {
			updateTemplateStateAfterEdit();
		}
	}, [getEmailTemplateStatus, restoreTemplateStatus]);

	const updateTemplateStateAfterEdit = () => {
		const findTemplate = templates.find(
			(item: EmailTemplatesDataDto) => item.id === template.id,
		);

		if (findTemplate) {
			setTemplate(findTemplate);
		}
	};

	const displayMessage = () => {
		message.info({
			content: (
				<>
					<div>
						{t('email_templates.notification.automation_trigger', {
							status: isEnabled
								? t('email_templates.notification.enabled')
								: t('email_templates.notification.disabled'),
						})}
					</div>
					{isEnabled ? (
						<>
							<div>{t('email_templates.notification.email_notification')}</div>
							<div>{trigger}</div>
						</>
					) : (
						<div>{t('email_templates.notification.disable_email_notification')}</div>
					)}
				</>
			),
			className: 'styledMessage',
		});
	};

	const onSelectItem = (selectId: number) => {
		dispatch(selectAutomatedEmailTemplate({ selectId }));
	};

	const onEdit = (id: number) => {
		const findTemplate = templates.find(
			(template: EmailTemplatesDataDto) => template.id === id,
		);
		setTemplate(findTemplate);
		dispatch(getDynamicFieldsRequest());
		setVisible(true);
	};

	const onClickAction = (data: EmailTemplatesDataDto, action: string) => {
		const payloadSwitch = {
			...data,
			enabled: !data.enabled,
		};

		const payloadArchived = {
			...data,
			type: EMAIL_TEMPLATE_FOLDER_TYPES.ARCHIVED,
		};

		switch (action) {
			case 'switch':
				dispatch(updateEmailTemplatesRequest(payloadSwitch));
				dispatch(enableTemplateAction(true));
				break;

			case 'archived':
				setOpenModal(true);
				setPayloadArchived(payloadArchived);
				break;
			default:
				break;
		}
	};

	const onSubmitArchived = () => {
		dispatch(updateEmailTemplatesRequest(payloadArchived));
	};

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			{templates.length > 0 ? (
				<MenuTemplates
					onEdit={onEdit}
					onClickAction={onClickAction}
					onSelectItem={onSelectItem}
					dataTemplate={templates}
					isAutomated={true}
				/>
			) : (
				<StyledCenterContainer>
					<Empty />
				</StyledCenterContainer>
			)}
			<EditTemplateModal visible={visible} template={template} handleCancel={handleCancel} />
			<ConfirmModal
				width={400}
				confirmLoading={getEmailTemplateStatus}
				okText={t('button.yes')}
				cancelText={t('button.no')}
				isOpen={openModal}
				onSubmit={onSubmitArchived}
				onClose={() => setOpenModal(false)}>
				{t('email_templates.confirm.archived_template')}
			</ConfirmModal>
		</>
	);
};

export default Automated;
