import * as React from 'react';

export type MessageLogContextType = {
	isSelectSentTab: boolean;
	onChangeIsSelectSentTab: (value: boolean) => void;
	eventEmailPayload: any;
	onChangeEventEmailPayload: (value: any) => void;
	selectedEmail: any | undefined;
	onChangeSelectedEmail: (email: any) => void;
	selectedEmailList: number[];
	onChangeSelectedEmailList: (checked: boolean, id: number, onlyOne?: boolean) => void;
	onResetSelectedEmailList: () => void;
	isSelectDraftEmail: boolean;
	onIsSelectDraftEmail: (value: boolean) => void;
};

// Default context values
const values: MessageLogContextType = {
	isSelectSentTab: true,
	onChangeIsSelectSentTab: () => {},
	eventEmailPayload: {},
	onChangeEventEmailPayload: () => {},
	selectedEmail: 0,
	onChangeSelectedEmail: () => {},
	selectedEmailList: [],
	onChangeSelectedEmailList: () => {},
	onResetSelectedEmailList: () => {},
	isSelectDraftEmail: false,
	onIsSelectDraftEmail: () => {},
};

const MessageLogContext = React.createContext(values);
export default MessageLogContext;
