import Column from '@components/LandingPageBuilder/Column';
import Container from '@components/LandingPageBuilder/Container';
import GalleryTag from '@components/LandingPageBuilder/GalleryTag';
import Heading from '@components/LandingPageBuilder/Heading';
import List from '@components/LandingPageBuilder/List';
import Text from '@components/LandingPageBuilder/Text';
import UploaderButton from '@components/LandingPageBuilder/UploaderButton';

export default (editor: any) => {
	const domc = editor.DomComponents;

	Column(domc, editor);
	Heading(domc);
	UploaderButton(domc);
	GalleryTag(domc, editor);
	Container(domc);
	Text(domc);
	List(domc);
};
