import * as React from 'react';
import styled from 'styled-components';

// Component
import { Spin, Tooltip } from 'antd';

// Hook
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import CreatorDashboardContext from '@contexts/Creators/Dashboard';

// Icons
import {
	IconStar,
	IconMoney,
	IconPeopleGroup,
	IconSchedule,
	IconRewardDiscount,
} from '@assets/icons';

// Constant
import THEME from '@constants/themes/themes';
// utils
import { abbreviateNumber } from '@utils/common';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '@constants/common';

// styled
const StyledIcon = styled.div<{
	color: string;
}>`
	svg {
		fill: ${({ color }) => `${color}` || 'red'} !important;
		width: 40px;
		height: 40px;
	}
`;

const StyledInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 12px;
	width: calc(100% - 52px);
`;

const StyledTitle = styled.div`
	display: block;
	height: 24px;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledCount = styled.div`
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 34px;
	font-size: 1.714rem;
`;

const StyledCard = styled.div`
	&.card-count {
		width: calc(20% - 12.8px);
	}
`;

const StyledSpin = styled(Spin)`
	text-align: left;
`;

type CountTypes = {
	name: string;
	count: number | string;
	icon: any;
	color: string;
	click?: () => void;
	sub?: string;
};

const TopContent = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const {
		isFetchingCreatorStatisticData,
		creatorStatisticData,
		contentStatisticData,
		isFilterByCampaign,
	} = useContext(CreatorDashboardContext);

	const { creators = 0, estimatedTimeSaved = 0, rewards = 0 } = creatorStatisticData || {};

	const { verifiedContentValue = 0, verifiedContent = 0 } = contentStatisticData || {};

	const counts: CountTypes[] = [
		{
			name: 'content_detail.rights_granted',
			count: verifiedContent,
			icon: IconStar,
			color: THEME.colors.yellowBase,
			sub: t('dashboard.top_content.sub_approved_content'),
			click: () => {
				history.push({
					pathname: ROUTE_PATH.SUBMISSIONS,
					state: { customTerms: true, verified: true },
				});
			},
		},
		{
			name: 'dashboard.top_content.content_value',
			count: `$${abbreviateNumber(verifiedContentValue)}`,
			icon: IconMoney,
			color: THEME.colors.greenBase,
			sub: t('dashboard.top_content.sub_content_value'),
			click: () => {
				history.push(ROUTE_PATH.SETTINGS_CONTENT);
			},
		},
		{
			name: 'dashboard.top_content.active_creators',
			count: creators,
			icon: IconPeopleGroup,
			color: THEME.colors.blueBase,
			sub: t('dashboard.top_content.sub_creators'),
			click: () => {
				history.push({
					pathname: ROUTE_PATH.CREATORS_SUMMARY,
					state: { isActive: true },
				});
			},
		},
		{
			name: 'dashboard.top_content.rewards',
			count: `$${abbreviateNumber(rewards)}`,
			icon: IconRewardDiscount,
			color: THEME.colors.redBase,
			sub: t('dashboard.top_content.sub_rewards'),
			click: () => {
				history.push(ROUTE_PATH.REWARDS_HISTORY);
			},
		},
		{
			name: 'dashboard.top_content.estimated_time_saving',
			count: `${abbreviateNumber(estimatedTimeSaved)} hrs`,
			icon: IconSchedule,
			color: THEME.colors.violetBase,
		},
	];

	return (
		<div className="card-content top-content">
			{counts.map((item: CountTypes) => {
				const { name, count } = item;

				let className: string = '';
				let value: number | string = count;

				if (isFilterByCampaign) {
					if (
						name !== 'dashboard.top_content.rewards' &&
						name !== 'dashboard.top_content.estimated_time_saving'
					) {
						className += 'overlay';
					} else {
						value = '---';
						className += 'grey-out';
					}
				}

				const otherProp: any = {};
				if (item?.click) {
					otherProp.style = {
						cursor: 'pointer',
					};
					otherProp.onClick = item?.click;
				}

				const child = (
					<StyledCard
						className={`card-count ${className}`}
						key={item.name}
						{...otherProp}>
						<StyledIcon color={item.color}>
							<item.icon />
						</StyledIcon>

						<StyledInfo>
							<StyledTitle title={t(item.name)}>{t(item.name)}</StyledTitle>
							{isFetchingCreatorStatisticData ? (
								<StyledSpin />
							) : (
								<StyledCount title={value.toString()}>{value}</StyledCount>
							)}
						</StyledInfo>
					</StyledCard>
				);
				return item?.sub ? (
					<Tooltip placement="bottom" title={item?.sub}>
						{child}
					</Tooltip>
				) : (
					child
				);
			})}
		</div>
	);
};

export default React.memo(TopContent);
