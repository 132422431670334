import { Button, Col, DatePicker, Form, Input, Radio, Row, Table, message } from 'antd';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
// Helper
import { renderSelectedSegment } from '@helpers/formHelper';
// Constant
import { ACTIVE, ARCHIVED, DRAFT, FAILED, FETCHING, GIFTCARD, SUCCESSFUL } from '@constants/reward';
// Icon
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
// Redux
import { getSegmentReward } from '@stores/actions';
import {
	createReward,
	goLiveDraftReward,
	saveDraftReward,
	setInventoryState,
	updateReward,
} from '@stores/rewards/rewards.actions';
// Styled
import { TYPE_FORMAT_DATE } from '@constants/common';
import { StyledInput, StyledInputNumber } from '@styled/Common/CommonStyled';
import {
	StyledBlock,
	StyledColBorder,
	StyledForm,
	StyledIconInfo,
	StyledModal,
	StyledSelect,
	StyledStepItem,
	StyledSteps,
	StyledSwitch,
	StyledTitle,
} from '@styled/Rewards/RewardsStyled';
import { StyledEmpty } from '@styled/Rewards/StyledTable';
import { validateInputNumber } from '@utils/common';
import { EventValue } from 'rc-picker/lib/interface';
import { SPECIAL_CHARS } from '@constants/settings';

type ModalProps = {
	editedReward?: any;
	visible: boolean;
	onClose: () => void;
};

const { RangePicker } = DatePicker;

const StyledTable = styled(Table)`
	.ant-table {
		display: block;
		max-width: -moz-fit-content;
		max-width: fit-content;
		margin: 0 auto;
		overflow-x: auto;
		white-space: nowrap;
		.ant-table-selection-column {
			width: 50px;
		}
	}

	.ant-spin-nested-loading {
		> div {
			> .ant-spin {
				max-height: unset;
			}
		}
	}
`;

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

const GiftCardModal = ({ visible, onClose, editedReward }: ModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const rewards = useSelector((state: any) => state.rewards);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [currentSegmentPage, setCurrentSegmentPage] = useState<number>(1);
	const [isLimitTime, setIsLimitTime] = useState<boolean>(false);
	const [limitOnePerCustomer, setLimitOnePerCustomer] = useState<boolean>(false);
	const [segmentType, setSegmentType] = useState<string>('allowedSegmentIds');
	const [submitData, setSubmitData] = useState<any>({});
	const {
		fetchingSegment,
		fetchingSaveDraft,
		fetchingCreateReward,
		segmentList = null,
	} = rewards;
	const isEditing = Object.keys(editedReward).length !== 0;
	const isArchived = editedReward.status === ARCHIVED;
	const isDrafted = editedReward.status === DRAFT;
	const currentTime = new Date();
	const defaultTimer: [EventValue<Moment>, EventValue<Moment>] = [
		moment(currentTime),
		moment(currentTime.setFullYear(new Date().getFullYear() + 1)),
	];
	const columns: any[] = [
		{
			title: t('rewards.creator_segments'),
			dataIndex: 'title',
			show: true,
		},
		{
			title: t('rewards.creators'),
			dataIndex: 'totalCreatorProfiles',
			show: true,
			align: 'right',
		},
	];

	// Handle close modal
	useEffect(() => {
		if (fetchingCreateReward === SUCCESSFUL) {
			handleCancel();
		}
		if (fetchingSaveDraft === SUCCESSFUL) {
			handleCancel();
		}
	}, [fetchingCreateReward, fetchingSaveDraft]);

	useEffect(() => {
		// Fill data for updating reward
		fillEditingData();
	}, [visible]);

	const fillEditingData = () => {
		if (visible === true && isEditing) {
			const {
				allowedSegmentIds,
				excludedSegmentIds,
				limitOnePerCustomer: responseLimit,
				title,
				estimatedCashValue,
				usageLimit,
				dateStarts,
				dateExpires,
			} = editedReward;
			if (allowedSegmentIds.length > 0) {
				setSegmentType('allowedSegmentIds');
				setSelectedRowKeys(allowedSegmentIds);
			}
			if (excludedSegmentIds.length > 0) {
				setSegmentType('excludedSegmentIds');
				setSelectedRowKeys(excludedSegmentIds);
			}
			if (dateExpires) {
				setIsLimitTime(true);
			}
			form.setFieldsValue({
				title,
				usageLimit,
				estimatedCashValue,
				limitOnePerCustomer,
				limitDate: dateExpires ? [moment(dateStarts), moment(dateExpires)] : defaultTimer,
			});

			// Handle step 2
			setLimitOnePerCustomer(responseLimit);
			// submitData.limitOnePerCustomer = limitOnePerCustomer;
			submitData.dateStarts = dateStarts;
			submitData.dateExpires = dateExpires;
			setSubmitData(submitData);
		}
	};

	useEffect(() => {
		const node = document.querySelector<HTMLElement>('#giftCardSegmentList .ant-table-body');
		if (node && currentStep === 1) {
			const handleScroll = () => {
				if (node.scrollHeight === node.scrollTop + node.clientHeight && !fetchingSegment) {
					onScrollBottomSegmentList();
					node.scrollTop = node.scrollHeight;
				}
			};
			node.addEventListener('scroll', handleScroll);
			return () => node.removeEventListener('scroll', handleScroll);
		}
		return () => {};
	}, [currentSegmentPage, fetchingSegment]);

	const checkRequiredValidation = () => {
		const { usageLimit, estimatedCashValue } = submitData;
		const isError = usageLimit || estimatedCashValue;
		return isError ? false : true;
	};

	const onScrollBottomSegmentList = () => {
		const params = {
			page: currentSegmentPage + 1,
			pageRecords: 7,
		};
		dispatch(getSegmentReward(params));
		setCurrentSegmentPage(currentSegmentPage + 1);
	};

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const onChangeRewardLimitTime = (checked: any) => {
		setIsLimitTime(checked);
	};

	const onChangeStep = (current: number) => {
		handleFormatSubmitData();
		setCurrentStep(current);
	};

	const handleCancel = () => {
		onClose();

		// Clean up
		rewards.fetchingCreateReward = FAILED;
		rewards.segmentList = [];
		dispatch(setInventoryState(rewards));
		form.resetFields();
		setCurrentStep(0);
		setCurrentSegmentPage(1);
		setSelectedRowKeys([]);
		setSubmitData({});
		setSegmentType('allowedSegmentIds');
		setIsLimitTime(false);
	};

	const handleChangeSegmentType = (e: any) => {
		setSegmentType(e.target.value);
	};

	const handleChangeLimitOnePerCustomer = (value: any) => {
		setLimitOnePerCustomer(value);
	};

	const handleCheckFormValid = () => {
		const usageLimit = form.getFieldValue('usageLimit');
		const estimatedCashValue = form.getFieldValue('estimatedCashValue');

		if (
			(!usageLimit && usageLimit !== 0) ||
			(!estimatedCashValue && estimatedCashValue !== 0)
		) {
			return false;
		}
		return true;
	};

	const handleNextStep = () => {
		if (!handleCheckFormValid()) {
			form.submit();
			return;
		}

		handleFormatSubmitData();
		if (currentStep !== 2) {
			const step = currentStep + 1;
			setCurrentStep(step);
		}
	};

	const handleClickSubmit = () => {
		handleFormatSubmitData();

		form.validateFields().then(() => {
			const payload = { type: GIFTCARD, params: { ...submitData, status: ACTIVE } };
			dispatch(createReward(payload));
		});
	};

	const handleUpdateReward = () => {
		const { status, id } = editedReward;
		handleFormatSubmitData();

		form.validateFields().then(() => {
			const payload = {
				type: GIFTCARD,
				params: { ...submitData, id, status },
			};
			dispatch(updateReward(payload));
		});
	};

	const handleClickSaveDraft = () => {
		if (!handleCheckFormValid()) {
			form.submit();
			return;
		}

		handleFormatSubmitData();
		const params = { ...submitData, status: DRAFT };
		if (isEditing) {
			Object.assign(params, { id: editedReward.id });
		}
		if (checkRequiredValidation()) {
			message.error(t('rewards.message.at_least_one_value'));
		} else {
			const payload = { type: GIFTCARD, params };
			dispatch(saveDraftReward(payload));
		}
	};

	const handleGoLiveDraftReward = () => {
		handleFormatSubmitData();
		const params = { ...submitData, status: DRAFT };
		if (isEditing) {
			Object.assign(params, { id: editedReward.id });
		}
		form.validateFields().then(() => {
			const payload = { type: GIFTCARD, params };
			dispatch(goLiveDraftReward(payload));
		});
	};

	const handleFormatSubmitData = () => {
		const values = form.getFieldsValue();

		if (currentStep === 0) {
			// submitData.limitOnePerCustomer = editedReward.limitOnePerCustomer ?? false;
			submitData.estimatedCashValue = parseInt(values.estimatedCashValue, 10);
			submitData.usageLimit = parseInt(values.usageLimit, 10);
		}
		if (currentStep === 1) {
			// submitData.limitOnePerCustomer = values.limitOnePerCustomer ?? false;
			if (isLimitTime) {
				if (values.limitDate !== undefined) {
					submitData.dateStarts = values.limitDate[0].valueOf() ?? '';
					submitData.dateExpires = values.limitDate[1].valueOf() ?? '';
				}
			}
			if (!isLimitTime) {
				delete submitData.dateStarts;
				delete submitData.dateExpires;
			}
		}

		submitData.limitOnePerCustomer = limitOnePerCustomer;
		submitData[segmentType] = selectedRowKeys;
		if (segmentType === 'allowedSegmentIds' || submitData.excludedSegmentIds.length === 0) {
			delete submitData.excludedSegmentIds;
		}
		if (segmentType === 'excludedSegmentIds' || submitData.allowedSegmentIds.length === 0) {
			delete submitData.allowedSegmentIds;
		}
		setSubmitData(submitData);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: () => ({
			disabled: isArchived,
		}),
	};

	const renderSteps = () => {
		return (
			<StyledSteps
				current={currentStep}
				percent={60}
				labelPlacement="vertical"
				onChange={onChangeStep}>
				<StyledStepItem title={t('rewards.steps.details')} />
				<StyledStepItem title={t('rewards.steps.eligibility')} />
				<StyledStepItem title={t('rewards.steps.confirmation')} />
			</StyledSteps>
		);
	};

	const renderStep1 = () => {
		return (
			<>
				<StyledTitle level={4}>{t('rewards.modal.configure_gift_card')}</StyledTitle>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item
							label={t('rewards.modal.gift_card_value')}
							name="estimatedCashValue"
							rules={[
								{
									required: true,
									message: t('rewards.modal.required_not_field'),
								},
							]}>
							<StyledInput
								onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
									validateInputNumber(event, true);
								}}
								disabled={isArchived}
								suffix={SPECIAL_CHARS.DOLLAR}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={t('rewards.modal.gift_card_offer_ask')}
							name="usageLimit"
							rules={[
								{
									required: true,
									message: t('rewards.modal.required_not_field'),
								},
							]}>
							<StyledInputNumber
								disabled={isArchived}
								onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
									validateInputNumber(event);
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</>
		);
	};

	const renderStep2 = () => {
		const values = form.getFieldsValue();
		return (
			<>
				<StyledTitle level={4}>{t('rewards.modal.configure_fixed_discount')}</StyledTitle>
				<Row gutter={24}>
					<Col span={12}>
						<p>
							{t('rewards.modal.eligible_for_discount')} <StyledIconInfo />
						</p>
						<p>{t('rewards.modal.following_segment')}</p>
						<Radio.Group
							value={segmentType}
							onChange={handleChangeSegmentType}
							disabled={isArchived}>
							<Radio value="allowedSegmentIds">{t('rewards.modal.eligible')}</Radio>
							<Radio value="excludedSegmentIds">{t('rewards.modal.excluded')}</Radio>
						</Radio.Group>
						<br />
						<StyledTable
							columns={columns}
							dataSource={segmentList}
							id="giftCardSegmentList"
							pagination={false}
							scroll={{
								scrollToFirstRowOnChange: false,
								y: 200,
							}}
							rowSelection={rowSelection}
						/>
					</Col>
					<Col span={12}>
						<StyledBlock>
							<span>{t('rewards.modal.limit_time')}</span>
							<StyledSwitch
								disabled={isArchived}
								onChange={onChangeRewardLimitTime}
								checked={isLimitTime}
							/>
						</StyledBlock>
						{isLimitTime && (
							<Form.Item name="limitDate">
								<RangePicker
									disabled={isArchived}
									format={TYPE_FORMAT_DATE.MONTH_DAY}
									defaultValue={defaultTimer}
								/>
							</Form.Item>
						)}
						<StyledBlock>
							<span>{t('rewards.modal.limit_one_customer')}</span>
							<Form.Item name="limitOnePerCustomer" valuePropName="checked">
								<StyledSwitch
									disabled={isArchived}
									checked={limitOnePerCustomer}
									defaultChecked={false}
									onChange={handleChangeLimitOnePerCustomer}
								/>
							</Form.Item>
						</StyledBlock>
					</Col>
				</Row>
			</>
		);
	};

	const renderStep3 = () => {
		const values = form.getFieldsValue();
		return (
			<>
				<StyledTitle level={4}>{t('rewards.modal.here_reward')}</StyledTitle>
				<Row gutter={24}>
					<Col span={12}>
						<StyledColBorder>
							<p>{t('rewards.modal.reward_details')}</p>
							<Form.Item
								label={t('rewards.modal.amount')}
								name="estimatedCashValue"
								rules={[
									{
										required: true,
										message: t('rewards.modal.required', {
											field: t('rewards.modal.amount'),
										}),
									},
								]}>
								<Input disabled suffix={SPECIAL_CHARS.DOLLAR} />
							</Form.Item>
							<Form.Item
								label={t('rewards.modal.offer_limit')}
								name="usageLimit"
								rules={[
									{
										required: true,
										message: t('rewards.modal.required', {
											field: t('rewards.modal.offer_limit'),
										}),
									},
								]}>
								<Input disabled />
							</Form.Item>
						</StyledColBorder>
					</Col>
					<Col span={12}>
						<StyledColBorder>
							<p>{t('rewards.modal.eligibility')}</p>
							<Form.Item label={t('rewards.modal.eligible_segments')}>
								<StyledSelect
									disabled
									mode="multiple"
									showArrow={true}
									defaultValue={renderSelectedSegment(
										segmentList,
										selectedRowKeys,
									)}
									style={{ width: '100%' }}
								/>
							</Form.Item>
							<Form.Item
								label={t('rewards.modal.offer_end_date')}
								// name="title"
							>
								<Input
									disabled
									value={
										submitData.dateExpires || isLimitTime
											? moment(submitData.dateExpires).format(
													TYPE_FORMAT_DATE.MONTH_DAY,
											  )
											: ''
									}
								/>
							</Form.Item>
							<Form.Item label={t('rewards.modal.limit')}>
								<Input
									disabled
									value={submitData.limitOnePerCustomer ? 'One per customer' : ''}
								/>
							</Form.Item>
						</StyledColBorder>
					</Col>
				</Row>
			</>
		);
	};

	const renderFooterPrimaryButton = () => {
		if (currentStep === 2) {
			if (isDrafted) {
				return (
					<Button
						onClick={handleGoLiveDraftReward}
						key="submit"
						type="primary"
						loading={fetchingCreateReward === FETCHING}>
						{t('button.go_live')}
					</Button>
				);
			}
			if (isEditing) {
				return (
					<Button
						onClick={handleUpdateReward}
						key="submit"
						type="primary"
						loading={fetchingCreateReward === FETCHING}>
						{t('button.save')}
					</Button>
				);
			} else {
				return (
					<Button
						onClick={handleClickSubmit}
						key="submit"
						type="primary"
						loading={fetchingCreateReward === FETCHING}>
						{t('button.go_live')}
					</Button>
				);
			}
		} else {
			return (
				<Button onClick={handleNextStep} key="submit" type="primary">
					{t('button.next')}
				</Button>
			);
		}
	};

	const renderFooterSecondaryButton = () => {
		if (!isEditing || isDrafted) {
			return (
				<Button
					key="back"
					onClick={handleClickSaveDraft}
					loading={fetchingSaveDraft === FETCHING}>
					{t('button.save_draft')}
				</Button>
			);
		}
		return <></>;
	};

	return (
		<StyledModal
			visible={visible}
			width={800}
			onCancel={handleCancel}
			footer={!isArchived && [renderFooterSecondaryButton(), renderFooterPrimaryButton()]}>
			{renderSteps()}

			<StyledForm
				form={form}
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				requiredMark={false}>
				{currentStep === 0 && renderStep1()}

				{currentStep === 1 && renderStep2()}

				{currentStep === 2 && renderStep3()}
			</StyledForm>
		</StyledModal>
	);
};

export default React.memo(GiftCardModal);
