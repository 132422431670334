import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';
import rootSaga from './sagas';

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

const configMiddleware = () => {
	if (process.env.NODE_ENV === 'production') {
		return applyMiddleware(sagaMiddleware);
	} else {
		return applyMiddleware(sagaMiddleware, loggerMiddleware);
	}
};

const composeEnhancers = composeWithDevTools({
	// Specify name here
});

const configStore = () => {
	const store = createStore(rootReducer, composeEnhancers(configMiddleware()));

	sagaMiddleware.run(rootSaga);

	return store;
};

export default configStore;
