import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
/**
 * @prettier
 */

import genericApiSaga from '@helpers/genericApiSaga';
import { Action } from 'redux-actions';
import { takeLatest, put } from 'redux-saga/effects';
import {
	changeDefaultTemplateTypes,
	changeTemplateStatusTypes,
	changeTemplateToWidgetsTypes,
	createTemplateTypes,
	duplicateTemplateTermsTypes,
	fetchDetailTemplateTypes,
	fetchEntribeStandardTermsTypes,
	fetchListTemplateTypes,
	fetchListWidgetLiveTypes,
	fetchTemplateDefaultTypes,
	fetchTemplateHistoryTypes,
	fetchTemplateWidgetTypes,
	fetchWidgetInTemplateTypes,
	updateTemplateTermsTypes,
} from './contentLicensing.types';
import {
	CHANGE_DEFAULT_TEMPLATE_API,
	CHANGE_TEMPLATE_STATUS_API,
	CREATE_TEMPLATE_API,
	DETAIL_TEMPLATE_API,
	DUPLICATE_TEMPLATE_API,
	ENTRIBE_STANDARD_TERMS_API,
	LIST_TEMPLATE_API,
	LIST_WIDGET_LIVE_API,
	TEMPLATE_DEFAULT_API,
	TEMPLATE_HISTORY_API,
	TEMPLATE_WIDGET_API,
	WIDGET_IN_TEMPLATE_API,
	WIDGET_TEMPLATE_ASSIGN_API,
} from '@constants/APIs';
import * as actions from './contentLicensing.action';
import { convertParamSearch } from '@utils/common';
import {
	BodyTemplateTypes,
	ParamRequestHistoryTemplateTypes,
	ParamTemplateTypes,
	ParamUpdateTemplateTypes,
} from '@models/settings/contentLicensing';
import {
	DataRequestChangeTemplateStatusTypes,
	DataRequestChangeTemplateToWidgetTypes,
} from '@models/settings/contentLicensing/stores';
import { MANAGE_TERMS_TEMPLATE_WIDGET } from '@constants/licensingTemplate';

export function* createTemplate({ payload }: Action<string>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${CREATE_TEMPLATE_API}?accountEmail=${payload}`),
		*completed(response: any) {
			yield put(actions.createTemplateSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.createTemplateFailed(response));
		},
	});
}

export function* updateTemplate({ payload }: Action<ParamUpdateTemplateTypes>) {
	const { accountEmail, body, termsTemplateId, noSave, noChangeTerms = false } = payload;
	const paramConvert = convertParamSearch({ termsTemplateId, accountEmail });

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${CREATE_TEMPLATE_API}?${paramConvert}`, body),
		*completed(response: any) {
			yield put(actions.updateTemplateTermsSucceeded({ ...response, noSave, noChangeTerms }));
		},
		*failed(response: any) {
			yield put(actions.updateTemplateTermsFailed(response));
		},
	});
}

export function* fetchTemplate({ payload }: Action<number>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${DETAIL_TEMPLATE_API}?termsTemplateId=${payload}`),
		*completed(response: any) {
			yield put(actions.fetchDetailTemplateSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchDetailTemplateFailed(response));
		},
	});
}

export function* fetchListTemplate({
	payload,
}: Action<{ param: ParamTemplateTypes; body: BodyTemplateTypes }>) {
	const { param, body } = payload;
	const paramConvert = convertParamSearch(param);
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${LIST_TEMPLATE_API}?${paramConvert}`, body),
		*completed(response: any) {
			yield put(actions.fetchListTemplateSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchListTemplateFailed(response));
		},
	});
}

export function* fetchTemplateHistory({ payload }: Action<ParamRequestHistoryTemplateTypes>) {
	const paramConvert = convertParamSearch(payload);

	yield genericApiSaga({
		gatewayCall: () => getRequest(`${TEMPLATE_HISTORY_API}?${paramConvert}`),
		*completed(response: any) {
			yield put(actions.fetchTemplateHistorySucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchTemplateHistoryFailed(response));
		},
	});
}

export function* fetchWidgetInTemplate({ payload }: Action<number>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${WIDGET_IN_TEMPLATE_API}?termsTemplateId=${payload}`),
		*completed(response: any) {
			yield put(actions.fetchWidgetInTemplateSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchWidgetInTemplateFailed(response));
		},
	});
}

export function* fetchListWidgetLive({ payload }: Action<{ page: number; maxRecords: number }>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(LIST_WIDGET_LIVE_API, payload),
		*completed(response: any) {
			yield put(actions.fetchListWidgetLiveSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchListWidgetLiveFailed(response));
		},
	});
}

export function* fetchEntribeStandardTerms() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(ENTRIBE_STANDARD_TERMS_API),
		*completed(response: any) {
			yield put(actions.fetchEntribeStandardTermsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchEntribeStandardTermsFailed(response));
		},
	});
}

export function* changeTemplateToWidget({
	payload,
}: Action<DataRequestChangeTemplateToWidgetTypes>) {
	const { body, param } = payload;
	const { templateInWidget } = payload;
	const paramConvert = convertParamSearch(param);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${WIDGET_TEMPLATE_ASSIGN_API}?${paramConvert}`, body),
		*completed(response: any) {
			yield put(
				actions.changeTemplateToWidgetsSucceeded({
					...response,
					templateInWidget,
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.changeTemplateToWidgetsFailed(response));
		},
	});
}

export function* changeTemplateStatus({ payload }: Action<DataRequestChangeTemplateStatusTypes>) {
	const { param, body } = payload;
	const { templateStatusEnum } = param;
	const paramConvert = convertParamSearch(param);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${CHANGE_TEMPLATE_STATUS_API}?${paramConvert}`, body),
		*completed(response: any) {
			yield put(
				actions.changeTemplateStatusSucceeded({
					...response,
					templateStatusEnum,
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.changeTemplateStatusFailed(response));
		},
	});
}

export function* duplicateTemplateTerms({
	payload,
}: Action<{ accountEmail: string; termsTemplateId: number }>) {
	const paramConvert = convertParamSearch(payload);

	yield genericApiSaga({
		gatewayCall: () => postRequest(`${DUPLICATE_TEMPLATE_API}?${paramConvert}`),
		*completed(response: any) {
			yield put(actions.duplicateTemplateTermsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.duplicateTemplateTermsFailed(response));
		},
	});
}

export function* changeDefaultTemplate({
	payload,
}: Action<{ accountEmail: string; termsTemplateId: number }>) {
	const paramConvert = convertParamSearch(payload);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${CHANGE_DEFAULT_TEMPLATE_API}?${paramConvert}`),
		*completed(response: any) {
			yield put(
				actions.changeDefaultTemplateSucceeded({
					...response,
					id: payload.termsTemplateId,
				}),
			);
		},
		*failed(response: any) {
			yield put(actions.changeDefaultTemplateFailed(response));
		},
	});
}

export function* fetchTemplateWidget({ payload }: Action<{ page: number; maxRecords: number }>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(TEMPLATE_WIDGET_API, payload),
		*completed(response: any) {
			yield put(actions.fetchTemplateWidgetSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchTemplateWidgetFailed(response));
		},
	});
}

export function* fetchTemplateDefault() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(TEMPLATE_DEFAULT_API),
		*completed(response: any) {
			yield put(actions.fetchTemplateDefaultSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchTemplateDefaultFailed(response));
		},
	});
}

export function* contentLicensingSaga() {
	yield takeLatest(createTemplateTypes.CREATE_TEMPLATE_REQUEST, createTemplate);
	yield takeLatest(updateTemplateTermsTypes.UPDATE_TEMPLATE_TERMS_REQUEST, updateTemplate);
	yield takeLatest(fetchDetailTemplateTypes.FETCH_DETAIL_TEMPLATE_REQUEST, fetchTemplate);
	yield takeLatest(fetchListTemplateTypes.FETCH_LIST_TEMPLATE_REQUEST, fetchListTemplate);
	yield takeLatest(
		fetchTemplateHistoryTypes.FETCH_TEMPLATE_HISTORY_REQUEST,
		fetchTemplateHistory,
	);
	yield takeLatest(
		fetchWidgetInTemplateTypes.FETCH_WIDGET_IN_TEMPLATE_REQUEST,
		fetchWidgetInTemplate,
	);
	yield takeLatest(
		fetchListWidgetLiveTypes.FETCH_LIST_WIDGET_LIVE_TEMPLATE_REQUEST,
		fetchListWidgetLive,
	);
	yield takeLatest(
		fetchEntribeStandardTermsTypes.FETCH_ENTRIBE_STANDARD_TERMS_REQUEST,
		fetchEntribeStandardTerms,
	);
	yield takeLatest(
		changeTemplateToWidgetsTypes.CHANGE_TEMPLATE_TO_WIDGETS_REQUEST,
		changeTemplateToWidget,
	);
	yield takeLatest(
		changeTemplateStatusTypes.CHANGE_TEMPLATE_STATUS_REQUEST,
		changeTemplateStatus,
	);
	yield takeLatest(
		duplicateTemplateTermsTypes.DUPLICATE_TEMPLATE_TERMS_REQUEST,
		duplicateTemplateTerms,
	);
	yield takeLatest(
		changeDefaultTemplateTypes.CHANGE_DEFAULT_TEMPLATE_REQUEST,
		changeDefaultTemplate,
	);
	yield takeLatest(fetchTemplateWidgetTypes.FETCH_TEMPLATE_WIDGET_REQUEST, fetchTemplateWidget);
	yield takeLatest(
		fetchTemplateDefaultTypes.FETCH_TEMPLATE_DEFAULT_REQUEST,
		fetchTemplateDefault,
	);
}
