import { CAMPAIGN_DETAIL_FIELD_NAME, CREATOR_ACTIONS, RANGE_GOALS } from '@constants/campaign';
import { ROUTE_PATH } from '@constants/common';
import { SPECIAL_CHARS } from '@constants/settings';
import THEME from '@constants/themes/themes';
import CampaignDetailContext from '@contexts/Campaign/CampaignDetail';
import { CampaignDto, DropdownListItemDto } from '@models/campaign';
import { WidgetLinkDto } from '@models/common/summary';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import { SocialSearchDto } from '@models/settings/socialSearch';
import { FeedDto } from '@models/settings/socialSearch/socialAggregator/summary';
import {
	StyledCampaignOption,
	StyledFormItemCampaign,
} from '@styled/Campaign/CampaignDetailStyled';
import { StyledButtonPreview, StyledCardModalSelect } from '@styled/Campaign/CampaignStyled';
import {
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledCol,
	StyledLinkHref,
	StyledRow,
	StyledText,
	StyledTooltip,
} from '@styled/Common/CommonStyled';
import { handleConvertHash } from '@utils/campaign';
import history from '@utils/history';
import { InputNumber, Select } from 'antd';
import _ from 'lodash';
import React, { ReactNode, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type CreatorInteractionProps = {
	onRedirectLinkWidget: () => void;
	form: any;
};

type CheckboxItem = {
	label: string;
	value: string;
	component: ReactNode;
	disabled: boolean;
};

const CreatorInteraction = (props: CreatorInteractionProps) => {
	const { t } = useTranslation();
	const { onRedirectLinkWidget, form } = props;
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { widgetLinks = [] } = clientSettings;
	const { listFeedActive }: SocialSearchDto = useSelector(
		(state: any) => state.settings.socialSearch,
	);
	const { campaignDetail }: CampaignDto = useSelector((state: any) => state.campaign);

	const {
		creatorActions,
		setCreatorActions,
		setHashtagFooter,
		campaignEmailTemplate = {},
		onPreviewTemplate,
		disabledFields = [],
	} = useContext(CampaignDetailContext);

	const hashtagMentionList = useMemo(() => {
		let hashtagList: string[] = [];
		let mentionList: string[] = [];

		if (listFeedActive) {
			const { hashtags = '', mentionUsernames = '' } = campaignDetail || {};

			const hashtagsTemp = hashtags ? handleConvertHash(hashtags) : [];
			const mentionUsernamesTemp = mentionUsernames
				? handleConvertHash(mentionUsernames)
				: [];

			listFeedActive.forEach((item: FeedDto) => {
				const { hashtags, mentionUsernames } = item;

				hashtagList = _.union(hashtagList, hashtags);
				mentionList = _.union(mentionList, mentionUsernames);
			});

			// In case hashtag or mentionUsername exists in a deleted feed
			hashtagList = _.union(hashtagList, hashtagsTemp);
			mentionList = _.union(mentionList, mentionUsernamesTemp);

			hashtagList = hashtagList.map((item: string) => {
				return `${SPECIAL_CHARS.HASH}${item}`;
			});
			mentionList = mentionList.map((item: string) => {
				return `${SPECIAL_CHARS.AT}${item}`;
			});
		}

		return [...hashtagList, ...mentionList];
	}, [campaignDetail, listFeedActive]);

	const ruleGoal: any = {
		type: 'number',
		min: RANGE_GOALS.MIN,
		max: RANGE_GOALS.MAX,
		message: t('campaign.validate.range_value', {
			min: RANGE_GOALS.MIN,
			max: RANGE_GOALS.MAX,
		}),
	};

	const renderPostAndTagSection = () => {
		const tooltipProps = {
			i18nKey: 'campaign.modal.add_new_hashtag_tooltip',
			components: {
				linkTo: (
					<StyledLinkHref
						onClick={() => {
							history.push(ROUTE_PATH.SETTINGS_SOCIAL_AGGREGATOR);
						}}
						color={THEME.colors.orangeBase}
					/>
				),
			},
		};

		return (
			creatorActions.includes(CREATOR_ACTIONS.POST_TAG) && (
				<StyledCampaignOption>
					<StyledFormItemCampaign
						name={CAMPAIGN_DETAIL_FIELD_NAME.SOCIAL_POST_GOALS}
						label={t('campaign.modal.how_many_social_posts')}
						rules={[{ ...ruleGoal }]}>
						<InputNumber />
					</StyledFormItemCampaign>
					<StyledFormItemCampaign
						name={CAMPAIGN_DETAIL_FIELD_NAME.TERMS}
						label={t('campaign.modal.tag_on_social')}
						rules={[
							{
								required: true,
								message: t('campaign.validate.required', {
									field: t('campaign.modal.hashtag_mention'),
								}),
							},
						]}>
						<StyledCardModalSelect
							mode="multiple"
							allowClear
							showArrow={true}
							style={{ width: '100%' }}
							placeholder={t('campaign.placeholder.hashtag_mention')}>
							{hashtagMentionList.map((item) => {
								return (
									<Select.Option key={item} value={item}>
										{item}
									</Select.Option>
								);
							})}
						</StyledCardModalSelect>
					</StyledFormItemCampaign>
					<StyledTooltip title={<Trans {...tooltipProps} />} trigger={['click']}>
						<StyledText
							className="underline"
							cursor="pointer"
							fontSize="12px"
							color={THEME.colors.gray3}>
							{t('campaign.modal.add_new_hashtag')}
						</StyledText>
					</StyledTooltip>
				</StyledCampaignOption>
			)
		);
	};

	const formatOptions = (options: EmailTemplatesDataDto[] | WidgetLinkDto[], type: string) => {
		return options.map((item: any) => {
			const optionItem = {
				label: item.title,
				value: item.id,
			};
			if (type === t('campaign.modal.assigned_uploader')) {
				optionItem.label = item.name;
			}

			return optionItem;
		});
	};

	const renderUploadContent = () => {
		const dropdownList: DropdownListItemDto[] = [
			{
				label: t('campaign.modal.assigned_uploader'),
				fieldName: CAMPAIGN_DETAIL_FIELD_NAME.WIDGET_ID,
				options: widgetLinks,
				action: onRedirectLinkWidget,
			},
			{
				label: t('campaign.modal.confirmation_email'),
				fieldName: CAMPAIGN_DETAIL_FIELD_NAME.CONFIRMATION_TEMPLATE_ID,
				options: campaignEmailTemplate?.confirmation || [],
				action: onPreviewTemplate,
			},
			{
				label: t('campaign.modal.rejection_email'),
				fieldName: CAMPAIGN_DETAIL_FIELD_NAME.REJECTION_TEMPLATE_ID,
				options: campaignEmailTemplate?.rejection || [],
				action: onPreviewTemplate,
			},
		];

		return (
			<StyledCampaignOption margin="0 0 20px 0">
				<StyledFormItemCampaign
					name={CAMPAIGN_DETAIL_FIELD_NAME.SUBMISSION_GOALS}
					label={t('campaign.modal.submissions_receive')}
					rules={[
						{
							required: true,
							message: t('campaign.validate.required', {
								field: t('campaign.modal.submissions_goals'),
							}),
						},
						{ ...ruleGoal },
					]}>
					<InputNumber />
				</StyledFormItemCampaign>
				{dropdownList.map((formItem, index) => {
					const key = `${index}_dropdown`;

					return (
						<StyledRow key={key} gutter={16} align="bottom">
							<StyledCol lg={16} xxl={18}>
								<StyledFormItemCampaign
									className="form_item_end"
									name={formItem.fieldName}
									label={formItem.label}
									margin={index === dropdownList.length - 1 ? '0' : '0 0 8px 0'}>
									<StyledCardModalSelect
										style={{ width: '100%' }}
										getPopupContainer={(triggerNode: HTMLElement) =>
											triggerNode.parentNode as HTMLElement
										}
										virtual={false}
										showArrow
										showSearch
										optionFilterProp="label"
										options={formatOptions(formItem.options, formItem.label)}
									/>
								</StyledFormItemCampaign>
							</StyledCol>

							<StyledCol span={6}>
								<StyledButtonPreview
									isDisabled={disabledFields.includes(formItem.fieldName)}
									margin={index === dropdownList.length - 1 ? '0' : '0 0 8px 0'}
									onClick={
										formItem.label !== t('campaign.modal.assigned_uploader')
											? () => formItem.action(formItem.fieldName)
											: formItem.action
									}
									type="primary">
									{t('campaign.modal.view')}
								</StyledButtonPreview>
							</StyledCol>
						</StyledRow>
					);
				})}
			</StyledCampaignOption>
		);
	};

	const checkboxList = [
		{
			label: t('campaign.modal.upload_content'),
			value: CREATOR_ACTIONS.UPLOAD_CONTENT_DIRECTLY,
			component: renderUploadContent(),
			disabled: true,
		},
		{
			label: t('campaign.modal.post_tag'),
			value: CREATOR_ACTIONS.POST_TAG,
			component: renderPostAndTagSection(),
			disabled: false,
		},
	];

	const handleChangeCheckboxOption = (values: any) => {
		setCreatorActions(values);
		if (values.length && !values.includes(CREATOR_ACTIONS.POST_TAG)) {
			form.setFieldsValue({
				terms: undefined,
				socialPostsGoals: RANGE_GOALS.SOCIAL_POSTS,
			});
			setHashtagFooter([]);
		}
	};

	return (
		<StyledCheckboxGroup
			width="100%"
			value={creatorActions}
			onChange={handleChangeCheckboxOption}>
			{checkboxList.map((item: CheckboxItem, index: number) => {
				const { disabled, value, label, component } = item;
				const key = `${index}_container`;

				return (
					<React.Fragment key={key}>
						<StyledCheckbox
							className={disabled ? 'disabled' : ''}
							margin="0 0 12px 0"
							value={value}>
							{label}
						</StyledCheckbox>
						{component}
					</React.Fragment>
				);
			})}
		</StyledCheckboxGroup>
	);
};

export default CreatorInteraction;
