import { IconDelete, IconRemove, IconRemoveCircle } from '@assets/icons';
import RadioGroup from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed/RadioGroup';
import { UPLOAD_SUPPORT } from '@config/index';
import { CREATE_CREATOR, MANUAL_UPLOAD_CONTENT } from '@constants/APIs';
import { PARAM_ALL, PATTERNS } from '@constants/common';
import {
	ACCEPT_FILES,
	KEY_IMPORT_CONTENT,
	LIST_OPTION_PERMISSION,
	NOT_PUBLISH_TERMS,
	PERMISSION_PUBLISH,
	VALIDATE_FILE,
} from '@constants/content/contentLibrary';
import { CREATED_SOURCE_ENUM } from '@constants/creator';
import { DEFAULT_ERROR } from '@constants/errors';
import THEME from '@constants/themes/themes';
import { OptionTypes } from '@cores/SelectSearch/SelectSingle';
import SelectSingleAddNew from '@cores/SelectSearch/SelectSingleAddNew';
import UploadMultiFile from '@cores/Upload/UploadMultiFile';
import { LoadingWrapper } from '@cores/index';
import { postRequest } from '@helpers/requestHelpers';
import { getUser } from '@helpers/userHelpers';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import { CreatorLiteType } from '@models/creator/profile';
import {
	fetchCreatorListLiteRequest,
	saveUploadContentStatus,
	storeCreatorData,
} from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledIcon,
	StyledLink,
	StyledLinkHref,
	StyledMarkAll,
	StyledModal,
	StyledTextArea,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledFormItem } from '@styled/Content/AlbumManager/AlbumModalStyled';
import { convertParamSearch, getVideoDuration, validateSizeFileImage } from '@utils/common';
import { convertMultiFileUploads } from '@utils/funcHelper';
import { Form, ModalProps, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { isEqual } from 'lodash';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledContainer = styled.div``;

export const StyledListFile = styled.div`
	max-height: 130px;
	overflow: hidden auto;
	width: 100%;
	border: 1px solid ${THEME.colors.darkBlue4};
	border-radius: 2px;
	padding: 4px 0px 4px 12px;
	margin-bottom: 12px;
`;

export const StyledFileItem = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 5px;
	&:hover {
		.unset_height {
			color: ${THEME.colors.orangeBase} !important;
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
`;

const StyledDivider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${THEME.colors.darkBlue4};
	margin-bottom: 12px;
`;

type PropTypes = {
	handleCancel: () => void;
};
const ModalImportContent = (props: PropTypes & ModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { loadingCreatorListLite, creatorListLite }: UserStoreType = useSelector(
		(state: any) => state.creator,
	);

	const { visible, handleCancel } = props;
	const [form] = Form.useForm();
	const refUpload = useRef<any>(false);
	const refClickUploadContent = useRef<any>({});
	const refClickUploadPdf = useRef<any>({});

	const user = getUser();

	const [statusPublish, setStatusPublish] = useState<string>(PERMISSION_PUBLISH.PUBLISH);
	const [fileContentUpload, setFileContentUpload] = useState<any[]>([]);
	const [filePdfUpload, setFilePdfUpload] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [creatorAdds, setCreatorAdds] = useState<OptionTypes[]>([]);
	const [hasNote, setHasNote] = useState<boolean>(false);
	const [paramCreator, setParamCreator] = useState({
		page: 1,
		maxRecords: 200,
		search: '',
	});
	const [loadingSearch, setLoadingSearch] = useState(false);
	const [creatorsLite, setCreatorsLite] = useState<CreatorLiteType[]>([]);

	const initialValues = useMemo(() => {
		const result: any = {};
		return result;
	}, []);

	useEffect(() => {
		if (paramCreator.page === 1) {
			setCreatorsLite(creatorListLite.records);
		} else {
			setCreatorsLite([...creatorsLite, ...creatorListLite.records]);
		}
	}, [creatorListLite]);

	const creatorsFormat = useMemo(() => {
		return creatorsLite.map((cr) => {
			let label: string = cr.email || '';
			if (!label) {
				if (cr.firstName || cr.firstName) {
					label = `${cr.firstName || ''} ${cr.lastName}`;
				}
				if (!label) {
					label = cr.instagramUsername ? cr.instagramUsername : cr.twitterUsername || '';
				}
				if (!label) {
					label = cr.tiktokUsername || '';
				}
			}
			return { value: cr.creatorId, label };
		});
	}, [creatorsLite]);

	useEffect(() => {
		if (!visible) {
			onClear();
		}
	}, [visible]);

	useEffect(() => {
		if (visible) {
			dispatch(fetchCreatorListLiteRequest({ ...paramCreator }));
		}
	}, [paramCreator, visible]);

	const onClear = () => {
		setCreatorAdds([]);
		setIsLoading(false);
		setFilePdfUpload([]);
		setFileContentUpload([]);
		setParamCreator({ page: 1, search: '', maxRecords: 200 });
		setStatusPublish(PERMISSION_PUBLISH.PUBLISH);
		form.resetFields();
		refUpload.current = false;
		setHasNote(false);
	};

	const onUploadFileContent = async (values: any, fileList: any) => {
		if (!refUpload.current) {
			refUpload.current = true;

			const newListFormat = fileList.filter(
				(f: any) =>
					((f.type.indexOf('image/') === 0 || !f.type) &&
						f.size <= VALIDATE_FILE.sizeDetail150mb) ||
					(f.type.indexOf('video/') === 0 && f.size <= VALIDATE_FILE.sizeDetail3Gb),
			);
			let mess = '';

			const addMessage = (isLimit?: boolean) => {
				if (!mess) {
					mess = isLimit
						? t('content_detail.message.limit_upload', {
								limit: VALIDATE_FILE.limitFile,
						  })
						: t('content_detail.message.file_type_upload', {
								val: t('content_detail.message.accept_img_video'),
						  });
					message.warning(mess);
				}
			};

			if (newListFormat.length < fileList.length) {
				addMessage();
			}
			if (newListFormat.length > 0) {
				setIsLoading(true);

				await convertMultiFileUploads(newListFormat)
					.then(async (fileConverts) => {
						const newList: any[] = [];

						const handlePushFile = (file: any, isAdd: boolean) => {
							newList.push({ file, isAdd });
							if (newList.length === fileConverts.length) {
								const fileAdd: any[] = [];
								newList.forEach((item) => {
									if (item.isAdd) {
										fileAdd.push(item.file);
									}
								});
								const filesChange = [...fileContentUpload, ...fileAdd];

								if (filesChange.length > VALIDATE_FILE.limitFile) {
									addMessage(true);
								}
								if (fileAdd.length < fileConverts.length) {
									addMessage();
								}

								setFileContentUpload(filesChange.slice(0, VALIDATE_FILE.limitFile));
							}
						};

						fileConverts.forEach(async (file, idx) => {
							if (file.type.indexOf('image/') === 0 || !file.type) {
								const resultValidate = await validateSizeFileImage(file, {
									size: VALIDATE_FILE.sizeDetail150mb,
									textSize: t('content_detail.type_mb'),
								});

								handlePushFile(file, resultValidate?.valid);
							} else if (file.type.indexOf('video/') === 0) {
								const duration: any = await getVideoDuration(file);

								handlePushFile(
									file,
									!duration || duration <= VALIDATE_FILE.videoLength90m,
								);
							} else {
								handlePushFile(file, true);
							}
						});
					})
					.catch((e) => {
						console.log('error', e);
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
			setTimeout(() => {
				refUpload.current = false;
			}, 0);
		}
		return false;
	};

	const onUploadFilePdf = (values: any, fileList: any) => {
		if (!refUpload.current) {
			refUpload.current = true;

			const newListFormat = fileList.filter(
				(f: any) =>
					f.type.indexOf('application/pdf') === 0 &&
					f.size <= VALIDATE_FILE.sizeDetail3Gb,
			);
			let mess = '';

			const filesChange = [...filePdfUpload, ...newListFormat];
			const files = filesChange.slice(0, VALIDATE_FILE.limitFile);
			if (filesChange.length > VALIDATE_FILE.limitFile) {
				mess = t('content_detail.message.limit_upload', {
					limit: VALIDATE_FILE.limitFile,
				});
			}
			if (newListFormat.length < fileList.length) {
				mess = t('content_detail.message.file_type_upload', {
					val: t('content_detail.message.accept_pdf'),
				});
			}
			if (mess) {
				message.warning(mess);
			}

			setFilePdfUpload(files);
			setTimeout(() => {
				refUpload.current = false;
			}, 0);
		}
		return false;
	};

	const onUploadContent = (e: any) => {
		const { files = [] } = e.target;

		onUploadFileContent(null, Array.from(files));
	};

	const onUploadPdfFiles = (e: any) => {
		const { files = [] } = e.target;

		onUploadFilePdf(null, Array.from(files));
	};

	const renderMoreFile = (
		files: any[],
		ref: any,
		accept: string,
		onChange: (val: any) => void,
	) => {
		const qualityFile = files.length;

		return (
			<StyledWrapperAction>
				<input
					style={{ display: 'none' }}
					ref={ref}
					accept={accept}
					onChange={onChange}
					type="file"
					multiple={true}
				/>
				<StyledTitle className="unset_height" fontSize="14px">
					{t('content.import_modal.file_selected', {
						val: qualityFile,
						more: qualityFile !== 1 ? 's' : '',
					})}
				</StyledTitle>
				<StyledLinkHref
					href=""
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						e.preventDefault();
						e.stopPropagation();

						if (ref.current) {
							ref.current.click();
						}
					}}
					margin="0 0 0 8px"
					className="underline"
					color={THEME.colors.gray5}>
					{t('button.add_more')}
				</StyledLinkHref>
			</StyledWrapperAction>
		);
	};

	const renderUploadContent = () => {
		const qualityFile = fileContentUpload.length;
		const accept = `${ACCEPT_FILES.IMAGE}, ${ACCEPT_FILES.VIDEO}`;
		if (qualityFile > 0) {
			return renderMoreFile(
				fileContentUpload,
				refClickUploadContent,
				accept,
				onUploadContent,
			);
		}
		return (
			<UploadMultiFile
				multiple
				textUpload={
					<StyledTitle color={THEME.colors.white} className="unset_height">
						{t('content.select_content')}
					</StyledTitle>
				}
				accept={accept}
				beforeUpload={onUploadFileContent}
				fillIcon={THEME.colors.white}
				fileList={fileContentUpload}
				subText={
					<StyledTitle
						fontSize="12px"
						className="unset_height"
						color={THEME.colors.gray1}>
						<Trans
							t={t}
							components={{
								linkTo: (
									<StyledLinkHref
										fontWeight="normal"
										margin="0 2px"
										underline
										href={UPLOAD_SUPPORT}
										color="inherit"
										target="_blank"
										onClick={(e: React.MouseEvent<HTMLElement>) => {
											e.stopPropagation();
										}}
									/>
								),
							}}
							i18nKey="content.sub_select_content"
						/>
					</StyledTitle>
				}
			/>
		);
	};

	const renderUploadPdf = () => {
		const qualityFile = filePdfUpload.length;
		const accept = ACCEPT_FILES.PDF;
		if (qualityFile > 0) {
			return renderMoreFile(filePdfUpload, refClickUploadPdf, accept, onUploadPdfFiles);
		}
		return (
			<UploadMultiFile
				multiple
				textUpload={
					<StyledTitle className="unset_height" color={THEME.colors.gray1}>
						{t('content.upload_pdf')}
					</StyledTitle>
				}
				beforeUpload={onUploadFilePdf}
				height={48}
				accept={accept}
			/>
		);
	};

	const onAddCreator = (creator: OptionTypes) => {
		setCreatorAdds([creator]);
		form.setFieldsValue({ [KEY_IMPORT_CONTENT.CREATOR]: creator.value });
	};
	const validateAdd = (creator: OptionTypes) => {
		const email = creator?.label ? creator?.label.toString()?.trim() : '';
		return PATTERNS.EMAIL.test(email);
	};
	const renderCreator = () => {
		return (
			<SelectSingleAddNew
				hasApiSearch
				placeholder={t('content.import_modal.placeholder_creator')}
				options={[...creatorAdds, ...creatorsFormat]}
				onAddOption={onAddCreator}
				loading={loadingCreatorListLite || loadingSearch}
				validateAdd={validateAdd}
				onSearch={(search: string) => {
					setParamCreator({ ...paramCreator, page: 1, search });
				}}
				setLoading={setLoadingSearch}
				onSelect={(op) => {
					if (!creatorAdds?.some((cr) => cr?.value === op?.value)) {
						setCreatorAdds([]);
					}
				}}
				onLoadMore={() => {
					if (creatorListLite.records.length > 0) {
						setParamCreator({ ...paramCreator, page: paramCreator.page + 1 });
					}
				}}
				valueIsNumber
			/>
		);
	};

	const onValuesChange = (val: any) => {
		const keyName = Object.keys(val)[0];
		if (keyName === KEY_IMPORT_CONTENT.NOTE) {
			setHasNote(!!val[keyName]);
		}
	};

	const createCreator = (email: string) => {
		setIsLoading(true);
		const values = form.getFieldsValue();
		const url = `${CREATE_CREATOR}?${convertParamSearch({
			createdSourceEnum: CREATED_SOURCE_ENUM.MANUAL_IMPORT_UPLOADED,
		})}`;

		postRequest(url, { email })
			.then((response) => {
				if (response.body) {
					if (response.status === 200) {
						const creator = response.body.data?.result;
						const listFormData = formatFormData(values, creator);
						dispatch(saveUploadContentStatus({ list: listFormData, isAdd: true }));
						dispatch(
							storeCreatorData({
								creatorListLite: {
									...creatorListLite,
									records: [creator, ...creatorListLite.records],
								},
							}),
						);
						onClear();
						handleCancel();
					} else {
						message.error(response.body.data.error || DEFAULT_ERROR);
					}
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const formatFormData = (values: any, creator?: any) => {
		const startId = new Date().getTime();

		const formList = fileContentUpload.map((file, idx) => {
			const formData = new FormData();
			formData.append('accountEmail', user?.email);
			if (statusPublish === PERMISSION_PUBLISH.PUBLISH) {
				if (values[KEY_IMPORT_CONTENT.NOTE]) {
					formData.append('termsOfUse', values[KEY_IMPORT_CONTENT.NOTE]);
				}

				if (filePdfUpload.length > 0) {
					filePdfUpload.forEach((f) => {
						formData.append('filesTerms', f);
					});
				}
			} else {
				formData.append('termsOfUse', NOT_PUBLISH_TERMS);
			}
			const creatorInfo =
				creator ||
				creatorsLite.find((item) => item?.creatorId === values[KEY_IMPORT_CONTENT.CREATOR]);
			if (creatorInfo) {
				formData.append('email', creatorInfo.email || '');
				formData.append('firstName', creatorInfo.firstName || '');
				formData.append('lastName', creatorInfo.lastName || '');
				formData.append('instagramUsername', creatorInfo.instagramUsername || '');
				formData.append('twitterUsername', creatorInfo.twitterUsername || '');
				formData.append('tiktokUsername', creatorInfo.tiktokUsername || '');
			}
			formData.append('fileMedia', file);
			return {
				data: formData,
				id: startId + idx + 1,
				uploading: false,
				creatorId: creatorInfo?.creatorId,
				customTerms: statusPublish === PERMISSION_PUBLISH.PUBLISH,
			};
		});
		return formList;
	};

	const handleFinishForm = (values: any) => {
		const creatorInfo = creatorsLite.find(
			(item) => item?.creatorId === values[KEY_IMPORT_CONTENT.CREATOR],
		);

		if (!creatorInfo) {
			createCreator(creatorAdds[0]?.label?.toString() || '');
		} else {
			const listFormData = formatFormData(values);

			dispatch(saveUploadContentStatus({ list: listFormData, isAdd: true }));
			onClear();
			handleCancel();
		}
	};

	const listFormItem = [
		{
			title: '',
			content: renderUploadContent(),
			key: KEY_IMPORT_CONTENT.FILE_CONTENT,
		},
		{
			key: KEY_IMPORT_CONTENT.CREATOR,
			title: t('content.import_modal.who_authored'),
			content: renderCreator(),
			subTitle: t('content.import_modal.content_associated'),
			rules: [{ required: true, message: t('validate.required') }],
		},
		{
			key: KEY_IMPORT_CONTENT.PUBLISH,
			title: t('content.import_modal.do_have_permission'),
			content: renderUploadPdf(),
			moreContent: (
				<StyledTextArea
					placeholder={t('content.import_modal.placeholder_note')}
					autoSize={{ minRows: 5, maxRows: 5 }}
				/>
			),
			subTitle: t('content.import_modal.track_agreements'),
		},
	];

	const renderLabel = (title: ReactNode, subTitle?: ReactNode) => {
		return (
			<StyledWrapperContent>
				<StyledTitle className="unset_height">{title}</StyledTitle>
				{subTitle && (
					<StyledTitle
						fontSize="12px"
						color={THEME.colors.gray3}
						className="unset_height">
						{subTitle}
					</StyledTitle>
				)}
			</StyledWrapperContent>
		);
	};

	const renderListFile = (
		files: UploadFile<any>[],
		setFiles: (val: UploadFile<any>[]) => void,
		key?: string,
	) => {
		return (
			<StyledListFile className="custom_scroll_bar">
				{files.map((f, idx) => (
					<StyledFileItem key={Math.random()}>
						<StyledTitle
							maxWidth="calc(100% - 32px)"
							className="overflow_text unset_height">
							{f.name}
						</StyledTitle>
						<StyledIcon
							size={20}
							className="absolute icon_hover"
							top="0"
							onClick={() => {
								const listFile = [...files.slice(0, idx), ...files.slice(idx + 1)];
								setFiles(listFile);
								if (key) {
									form.setFieldsValue({ [key]: listFile });
								}
							}}
							right="0">
							<IconRemove />
						</StyledIcon>
					</StyledFileItem>
				))}
			</StyledListFile>
		);
	};

	return (
		<StyledModal
			centered={true}
			visible={visible}
			onCancel={handleCancel}
			closable={true}
			onOk={() => form.submit()}
			okText={t('button.import')}
			maxHeight="75vh"
			title={t('button.import_content')}>
			<StyledContainer>
				<LoadingWrapper isLoading={isLoading}>
					<Form
						form={form}
						initialValues={initialValues}
						onValuesChange={onValuesChange}
						wrapperCol={{ span: 24 }}
						labelCol={{ span: 24 }}
						onFinish={handleFinishForm}>
						{listFormItem.map((item) => {
							if (item?.key === KEY_IMPORT_CONTENT.PUBLISH) {
								return (
									<StyledWrapperContent key={item.key}>
										<StyledWrapperContent margin="0 0 12px 0">
											{renderLabel(item.title, item.subTitle)}
										</StyledWrapperContent>
										<StyledWrapperContent margin="0 0 12px 0">
											<RadioGroup
												direction="vertical"
												radioValue={statusPublish}
												onChange={(e: any) => {
													setStatusPublish(e.target.value);
												}}
												list={LIST_OPTION_PERMISSION.map((item) => ({
													...item,
													name: t(item.name),
												}))}
											/>
										</StyledWrapperContent>
										{statusPublish === PERMISSION_PUBLISH.PUBLISH && (
											<>
												<StyledWrapperContent
													margin={
														filePdfUpload.length > 0
															? '0 0 8px 0'
															: '0 0 12px 0'
													}>
													{item.content}
												</StyledWrapperContent>
												{filePdfUpload.length > 0 &&
													renderListFile(filePdfUpload, setFilePdfUpload)}
												<StyledFormItem
													name={KEY_IMPORT_CONTENT.NOTE}
													rules={[
														{
															required:
																!hasNote &&
																filePdfUpload.length === 0,
															message: t(
																'content.import_modal.required_pdf_note',
															),
														},
													]}
													margin="0"
													className="unset_height">
													{item?.moreContent}
												</StyledFormItem>
											</>
										)}
									</StyledWrapperContent>
								);
							}
							if (item?.key === KEY_IMPORT_CONTENT.FILE_CONTENT) {
								return (
									<StyledWrapperContent key={item.key}>
										<StyledFormItem
											name={KEY_IMPORT_CONTENT.FILE_CONTENT}
											rules={[
												{
													required: fileContentUpload.length === 0,
													message: t(
														'content.import_modal.required_content',
													),
												},
											]}
											margin={
												fileContentUpload.length > 0 ? '0' : '0 0 12px 0'
											}
											className="unset_height">
											{item?.content}
										</StyledFormItem>

										{fileContentUpload.length > 0 &&
											renderListFile(
												fileContentUpload,
												setFileContentUpload,
												KEY_IMPORT_CONTENT.FILE_CONTENT,
											)}
										<StyledDivider />
									</StyledWrapperContent>
								);
							}
							return (
								<StyledWrapperContent key={item.key}>
									<StyledFormItem
										margin="0 0 12px 0"
										className={'unset_height'}
										name={item.key}
										rules={item?.rules || []}
										label={
											item.title
												? renderLabel(item.title, item.subTitle)
												: undefined
										}>
										{item?.content}
									</StyledFormItem>
									<StyledDivider />
								</StyledWrapperContent>
							);
						})}
					</Form>
				</LoadingWrapper>
			</StyledContainer>
		</StyledModal>
	);
};

export default ModalImportContent;
