import { apiURL } from '@config/index';

const GALLERY_TAG_CLASS = 'entribe_gallery_v100_2023';

const TYPE_THEME = {
	GRID: 'grid',
	SLIDER: 'slider',
	MOSAIC: 'mosaic',
	SIGNAGE: 'signage',
};

const TYPE_UPLOADER = {
	NONE: 'none',
	HYPERLINK: 'hyperlink',
};

const TAB_GALLERY = {
	preview: 'preview',
	manage_content: 'manage_content',
};

const SIZE_IFRAME = {
	width: 'width',
	height: 'height',
};

const RANGE_COLUMN = {
	min: 1,
	max: 3,
	default: 2,
};

const DEFAULT_SIZE_IFRAME = {
	width: 100,
	deviant: 40,
	height: 650,
	GRID_NO_LINK: {
		height: 400,
	},
	SLIDER: {
		height: 375,
	},
	SLIDER_NO_LINK: {
		height: 325,
	},
	MOSAIC: {
		height: 625,
	},
	MOSAIC_NO_LINK: {
		height: 565,
	},
	SIGNAGE: {
		height: 625,
	},
};

const POSITION_SELECT = 20;

const PARAMS_BASIC = {
	page: 1,
	maxRecords: 50,
};

const PARAMS_DEFAULT_CONTENT = {
	...PARAMS_BASIC,
	sortBy: 'createdAt',
	folder: '',
};

const PARAMS_CREATE_DEFAULT = {
	GALLERY_NAME: 'New Gallery',
};

const MIN_CONTENT = {
	SLIDER: 5,
	GRID: 4,
	S_GRID: 3,
	MOSAIC: 5,
};

const TOTAL_COLUMN = {
	MOSAIC: 3,
	S_4: 4,
	S_3: 3,
	S_2: 2,
};

const TOTAL_ROW = {
	MOSAIC: 2,
	GRID: 2,
	SLIDER: 1,
};

const DEFAULT_CENTER = {
	NORMAL: 2,
	MIN: 1,
};

const DEFAULT_COLUMN = 4;

const MAX_SIZE_IFRAME = {
	width: 100,
	height: 3000,
};

const DEFAULT_UPLOADER_LINK = {
	name: 'ADD YOUR PHOTO',
};

const PATH_GALLERY = '/galleries';

const TYPE_GET_CONTENT = {
	VIEW: 'view',
	UPDATE: 'update',
};

const OPTION_IMG_PADDING = {
	custom: 'custom',
	standard: 'standard',
};

const OPTION_DETAIL_HOVER = {
	showInstagramName: 'showInstagramName',
	showTwitterName: 'showTwitterName',
};

const OPTION_LIGHTBOX = {
	showInstagramUsername: 'showInstagramUsername',
	showTwitterUsername: 'showTwitterUsername',
	showCaption: 'showCaption',
	customDetails: 'customDetails',
	customFields: 'customFields',
	showFirstNameLastInitial: 'showFirstNameLastInitial',
};

const OPTION_CALL_TO_ACTION = {
	message: 'message',
	QRCode: 'QRCode',
};

const NAME_GROUP_HYPERLINK = {
	WIDGET: 'WIDGET',
	CAMPAIGN: 'CAMPAIGN',
};

const QUANTITY_THEME_MOSAIC = {
	NORMAL: 5,
	SMALL: 3,
	FULL: 6,
};

const KEY_TURN_OFF = {
	showDetailOnHover: 'showDetailOnHover',
	showDetailLightBox: 'showDetailLightBox',
	uploaderLink: 'uploaderLink',
	autoScroll: 'autoScroll',
	videoAutoPlay: 'videoAutoPlay',
	galleryBackground: 'galleryBackground',
	showBg: 'showBg',
	customProperties: 'customProperties',
	galleryFilters: 'galleryFilters',
};

const GALLERY_EDITOR_CONFIG = {
	CONTENT_STYLE:
		'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; margin: 0; background: red;}',
	PLUGINS: [
		'advlist autolink lists link image imagetools charmap print preview anchor',
		'searchreplace visualblocks code fullscreen',
		'insertdatetime media table paste code help wordcount',
	],
	TOOLBAR: 'italic underline bold ' + 'link reset save',
	ICONS: {
		RESET: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.99967 4.16659C13.683 4.16659 16.6663 7.14992 16.6663 10.8333C16.6663 14.2333 14.1247 17.0333 10.833 17.4416V15.7583C13.1913 15.3583 14.9997 13.3083 14.9997 10.8333C14.9997 8.07492 12.758 5.83325 9.99967 5.83325C9.97979 5.83325 9.95801 5.83515 9.93523 5.83713C9.91028 5.83931 9.88415 5.84159 9.85801 5.84159L10.758 6.74159L9.58301 7.91659L6.66634 4.99992L9.58301 2.08325L10.758 3.26659L9.84967 4.17492C9.87467 4.17492 9.89967 4.17284 9.92467 4.17075C9.94967 4.16867 9.97467 4.16659 9.99967 4.16659ZM6.46634 7.29992C5.55801 8.20825 4.99967 9.45825 4.99967 10.8333C4.99967 13.3083 6.80801 15.3583 9.16634 15.7583V17.4416C5.87467 17.0333 3.33301 14.2333 3.33301 10.8333C3.33301 8.99159 4.08301 7.32492 5.28301 6.11659L6.46634 7.29992Z" fill="#E0E3E5"/></svg>',
	},
	TOOLBAR_WIDGET: 'link',
	TOOLBAR_TASK: 'italic underline bold bullist numlist ' + 'link reset save',
};

const MEDIA_SIZE_DEFAULT = 595;
// const MEDIA_SIZE_DEFAULT = 591 + 0.0001;

const HEIGHT_THEME_GALLERY = 175;

const SCALE_DEFAULT_GALLERY = 2;

const ROW_TEXTAREA = {
	GALLERY_DESC: 1.5,
};

const AUTOPLAY_SPEED_DEFAULT = 5;

enum ViewGalleryType {
	mobile = 1,
	default,
	fullWidth,
}

const SUB_HEIGH_FULL = 330;

const COLOR_FIELD = {
	GALLERY_BACKGROUND: 'galleryBackground',
	CTA_BACKGROUND: 'ctaBackground',
	FONT_QR_CODE_COLOR: 'fontQRCodeColor',
	IS_FAILED_CONTRAST: 'isFailedContrast',
};

const CONTRAST_CHECKER = {
	FIELD: {
		AA_LARGE: 'AALarge',
		AAA_LARGE: 'AAALarge',
	},
	VALUE: {
		FAIL: 'fail',
		PASS: 'pass',
	},
};

const DEFAULT_FILTERS = {
	CREATOR: {
		id: 'creator',
		fieldName: 'creator',
		isDefault: true,
		name: 'Filter by Creator',
	},
};

const ELEMENT_SELECTOR = {
	GALLERY_WRAPPER: 'gallery_wrapper_slider',
};

const SPACE_LAYOUT = {
	S_6: 6,
	S_12: 12,
	S_20: 20,
};

export {
	TYPE_THEME,
	TYPE_UPLOADER,
	TAB_GALLERY,
	SIZE_IFRAME,
	POSITION_SELECT,
	PARAMS_DEFAULT_CONTENT,
	PARAMS_CREATE_DEFAULT,
	MIN_CONTENT,
	DEFAULT_COLUMN,
	DEFAULT_SIZE_IFRAME,
	MAX_SIZE_IFRAME,
	DEFAULT_UPLOADER_LINK,
	PATH_GALLERY,
	TYPE_GET_CONTENT,
	QUANTITY_THEME_MOSAIC,
	TOTAL_COLUMN,
	TOTAL_ROW,
	DEFAULT_CENTER,
	PARAMS_BASIC,
	RANGE_COLUMN,
	OPTION_IMG_PADDING,
	OPTION_DETAIL_HOVER,
	OPTION_LIGHTBOX,
	KEY_TURN_OFF,
	MEDIA_SIZE_DEFAULT,
	GALLERY_EDITOR_CONFIG,
	HEIGHT_THEME_GALLERY,
	SCALE_DEFAULT_GALLERY,
	ROW_TEXTAREA,
	ViewGalleryType,
	OPTION_CALL_TO_ACTION,
	AUTOPLAY_SPEED_DEFAULT,
	SUB_HEIGH_FULL,
	GALLERY_TAG_CLASS,
	NAME_GROUP_HYPERLINK,
	COLOR_FIELD,
	CONTRAST_CHECKER,
	DEFAULT_FILTERS,
	ELEMENT_SELECTOR,
	SPACE_LAYOUT,
};
