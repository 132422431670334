/**
 * @prettier
 */
import THEME from '@constants/themes/themes';
import { Col, Row } from 'antd';
import React, {
	forwardRef,
	Ref,
	RefObject,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Libraries
import { createSelectable, SelectableGroup } from 'react-selectable-fast';

// Components
import MediaItem from '@components/Content/ContentLibrary/Media/MediaItem';

// Models
import { GUTTER_LIST_CONTENT, SYSTEM_FOLDER_LIST } from '@constants/content/contentLibrary';
import CreatorContentContext from '@contexts/Creators/Content';
import { FolderItemDto } from '@models/common/summary';
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
} from '@models/content/contentLibrary/summary';
import { RefContentProfile } from '@models/creator/ref';
import { storeContentData } from '@stores/actions';
import {
	convertToSingleContent,
	getContentByType,
	getSocialS3URL,
	getThumbnailBehavior,
} from '@utils/common';
import { useTranslation } from 'react-i18next';
import CustomSelection from '@components/CustomLibrary/CustomSelection';
import { SelectionCustomRef } from '@models/common/ref';
import { handleSelectItemWithLib } from '@utils/funcHelper';

const SelectableItem = createSelectable(MediaItem);

const StyledRow = styled(Row)`
	row-gap: 15px !important;
`;

const StyledCol = styled(Col)`
	text-align: center;
	position: relative;

	.hoverText {
		visibility: hidden;
		height: 0;
		width: 0;
		bottom: 0;
		left: 8px;
		opacity: 0;
	}

	&:hover {
		.hoverText {
			visibility: visible;
			height: auto;
			width: auto;
			position: absolute;
			bottom: 12px;
			left: 20px;
			opacity: 1;

			color: #fff;
			transition: all 0.3s ease-in-out;
			text-align: left;

			&_title {
				font-size: 1rem;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: normal;
			}
			&_subtitle {
				font-size: 0.857rem;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.67;
				letter-spacing: normal;
			}
		}
	}
`;

type ContentListProps = {
	colCount: number;
	isToggle: boolean;
	handleSelecting: (contentList: ContentContainerDto[]) => void;
	updateContentList: (contentList: ContentContainerDto[]) => void;
	handleScrollbars: (scrollbarTop: number) => void;
	selectContentList: any[];
	updateAfterReviewingContent: (val: ContentReviewDto[]) => void;
	setContentIdMove: (val: number | null) => void;
};

const ContentList = forwardRef((props: ContentListProps, ref: Ref<RefContentProfile>) => {
	// Props
	const { handleSelecting, colCount, isToggle, handleScrollbars } = props;

	const dispatch = useDispatch();
	const { t } = useTranslation();
	// Reducers
	const { moveContentStatus, deleteMultiContentsStatus } = useSelector(
		(state: any) => state.content,
	);
	const { isCollapsedMenu } = useSelector((state: any) => state.sidebar);
	const {
		markAsFavoriteContent,
		handleContentDetails,
		setContentList,
		contentList,
		param,
		tabType,
		selectContentList,
	} = useContext(CreatorContentContext);

	const contentRef = useRef<any>({});
	const scrollbars = useRef<any>({});
	const selectionRef = useRef<any>({});
	const refEventSelect = useRef<
		SelectionCustomRef | undefined
	>() as RefObject<SelectionCustomRef>;

	const [widthContent, setWidthContent] = useState<number>(0);

	useImperativeHandle(ref, () => ({
		clearSelecting,
	}));

	useEffect(() => {
		const thumbnailBehavior = getThumbnailBehavior();
		dispatch(storeContentData({ thumbnailBehavior }));
	}, []);

	useEffect(() => {
		if (contentList.length) {
			handleSetWidthContent();
		}
	}, [contentList]);

	useEffect(() => {
		handleSetWidthContent();
	}, [colCount]);

	useEffect(() => {
		setTimeout(() => {
			handleSetWidthContent();
		}, 400);
	}, [isToggle, isCollapsedMenu]);

	useEffect(() => {
		if (
			(moveContentStatus && moveContentStatus === 2) ||
			(deleteMultiContentsStatus && deleteMultiContentsStatus == 2)
		) {
			clearSelectionUsingRef();
		}
	}, [moveContentStatus, deleteMultiContentsStatus]);

	// Functions

	const handleSetWidthContent = () => {
		if (contentRef.current && contentRef.current.offsetWidth !== null) {
			setWidthContent(contentRef.current.offsetWidth);
		}
	};

	const handleSelection = (selectingItems: any) => {
		const selectContent: Array<ContentContainerDto> = [];
		selectingItems.map((selectingItem: any) => {
			const { props } = selectingItem;
			selectContent.push(props.content);
		});

		if (
			refEventSelect?.current?.handleSectionCustom &&
			typeof refEventSelect.current.handleSectionCustom === 'function'
		) {
			refEventSelect.current.handleSectionCustom(
				convertToSingleContent(selectContent).map((item) => item.id),
			);
		}

		if (!refEventSelect?.current?.isShift) {
			handleSelecting(selectContent);
		}
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		const contents: ContentContainerDto[] = [];
		contentList.forEach((item) => {
			const contentTemp: ContentDto = getContentByType(item);
			if (list.includes(contentTemp.id)) {
				contents.push(item);
			}
		});
		handleSelecting(contents);
	};

	const clearSelectionUsingRef = () => {
		if (selectionRef) {
			selectionRef.current.clearSelection();
		}
	};

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			handleScrollbars(scrollbarTop);
		}
	};

	// Render components

	const renderContent = () => {
		return contentList.map((data: any, index: number) => {
			let contentTemp: ContentDto = getContentByType(data);
			const thumbnail: string = getSocialS3URL(contentTemp.thumbnail);
			contentTemp = { ...contentTemp, thumbnail };

			return renderCol(index, props.colCount, data);
		});
	};

	const renderCol = (index: number, colCount: number, fullContent: ContentContainerDto) => {
		const padding: number = 16;
		const otherProps = {
			contentList,
			setContentList,
			namePage: SYSTEM_FOLDER_LIST.find((item: FolderItemDto) => param.activeType === item.id)
				?.name,
		};

		const size = {
			height: (3 / 4) * widthContent - padding + 'px',
			width: widthContent + 'px',
		};

		if (colCount === 6) {
			size.height = widthContent - padding + 'px';
		}

		const idContent = getContentByType(fullContent).id;
		if (tabType.isPublished) {
			return (
				<StyledCol
					ref={(element) => {
						contentRef.current = element;
					}}
					span={24 / colCount}>
					{widthContent > 0 && (
						<SelectableItem
							selectContent={(content: ContentContainerDto) => {
								handleContentDetails(index);
							}}
							selectContentUpdate={() =>
								handleSelectItemWithLib(selectionRef, idContent)
							}
							widthContent={widthContent - GUTTER_LIST_CONTENT}
							content={fullContent}
							sizeImage={size}
							id={idContent}
							markAsFavoriteContent={markAsFavoriteContent}
							{...otherProps}
						/>
					)}
					<div className="hoverText">
						<div className="hoverText_title">Holiday 2021</div>
						<div className="hoverText_subtitle">Instagram - 12/04/2021</div>
					</div>
				</StyledCol>
			);
		}

		return (
			<StyledCol
				ref={(element) => {
					contentRef.current = element;
				}}
				key={index}
				span={24 / colCount}>
				{widthContent > 0 && (
					<SelectableItem
						selectContent={(content: ContentContainerDto) => {
							handleContentDetails(index);
						}}
						selectContentUpdate={() => handleSelectItemWithLib(selectionRef, idContent)}
						widthContent={widthContent - GUTTER_LIST_CONTENT}
						content={fullContent}
						sizeImage={size}
						id={idContent}
						markAsFavoriteContent={markAsFavoriteContent}
						{...otherProps}
					/>
				)}
			</StyledCol>
		);
	};

	const clearSelecting = () => {
		handleSelecting([]);
		clearSelectionUsingRef();
	};

	const renderThumbVertical = (style: any, props: any) => (
		<div
			{...props}
			style={{
				...style,
				backgroundColor: THEME.colors.gray2,
				borderRadius: '20px',
				overflowX: 'hidden',
			}}
		/>
	);

	return (
		<React.Fragment>
			<CustomSelection
				ref={refEventSelect}
				ids={convertToSingleContent(contentList).map((item) => item.id)}
				selectIds={convertToSingleContent(selectContentList as ContentContainerDto[]).map(
					(item) => item.id,
				)}
				refSelection={selectionRef}
				handleSelectNewList={handleSelectNewList}
			/>
			<Scrollbars
				ref={(el) => {
					scrollbars.current = el;
				}}
				className="media-list-scrollbar"
				onScrollStop={onScrollStop}
				autoHeight
				autoHeightMin={100}
				autoHeightMax="calc(100vh - 170px)"
				renderThumbHorizontal={() => <div style={{ background: 'transparent' }} />}
				renderTrackVertical={() => <div />}
				renderThumbVertical={({ style, ...props }) => renderThumbVertical(style, props)}>
				<SelectableGroup
					className="selectable-group"
					clickClassName="tick"
					ref={selectionRef}
					enableDeselect={true}
					tolerance={0}
					deselectOnEsc={true}
					allowClickWithoutSelected={true}
					selectOnClick={false}
					onSelectionFinish={handleSelection}>
					<StyledRow gutter={GUTTER_LIST_CONTENT}>{renderContent()}</StyledRow>
				</SelectableGroup>
			</Scrollbars>
		</React.Fragment>
	);
});

export default ContentList;
