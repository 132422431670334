import { IconEdit } from '@assets/icons';
import CheckRedirectDetail from '@components/Content/WidgetManager/DetailWidget/CheckRedirectDetail';
import THEME from '@constants/themes/themes';
import { RefCheckRedirect } from '@models/content/widgetManager/ref';
import { StyledIcon } from '@styled/Common/CommonStyled';
import React, { RefObject, useRef } from 'react';

type PropTypes = {
	widgetId: number;
	setLoading?: (val: boolean) => void;
};
const ActionEdit = (props: PropTypes) => {
	const { widgetId, setLoading } = props;
	const refAction = useRef<RefCheckRedirect | undefined>() as RefObject<RefCheckRedirect>;

	const handleEdit = () => {
		if (refAction && refAction?.current?.onClickCheck) {
			refAction.current.onClickCheck();
		}
	};

	return (
		<CheckRedirectDetail widgetId={widgetId} ref={refAction} setLoading={setLoading}>
			<StyledIcon
				fillPath={THEME.colors.gray3}
				size={18}
				onClick={() => handleEdit()}
				className="icon_hover"
				margin="0 0 0 12px">
				<IconEdit />
			</StyledIcon>
		</CheckRedirectDetail>
	);
};

export default ActionEdit;
