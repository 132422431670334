import { HEIGHT_THUMBNAIL_ALBUM } from '@constants/content/albums';
import THEME from '@constants/themes/themes';
import { Button } from 'antd';
import styled from 'styled-components';

const WIDTH_THEME = 730;

const StyleWrapperAlbumItem = styled.div<{ isSelected?: boolean; disabled?: boolean }>`
	background: ${THEME.colors.darkBlue3};
	border-radius: 12px;

	padding: 23px;
	display: flex;
	align-items: center;
	position: relative;
	border: 2px solid ${THEME.colors.darkBlue3};
	${({ isSelected }) => isSelected && `border-color: ${THEME.colors.orangeBase}`};
	${({ disabled }) => disabled && 'pointer-events: none'};
	transition: 0.05s ease-out;
	overflow: hidden;
	&.suggest_album_item {
		background: ${THEME.colors.orangeBase};
		border-color: ${THEME.colors.orangeBase};
		opacity: 0.8;
		.color_change {
			color: ${THEME.colors.white};
		}
	}
`;

const StyledContainerAlbumItem = styled.div`
	position: relative;
	border-radius: 12px;
	overflow: hidden;
	&:not(:first-child) {
		margin-top: 16px;
	}
	&:hover {
		.mark_suggest {
			opacity: 1;
			z-index: 80;
		}
		.suggest_album_item {
			background: ${THEME.colors.darkBlue3};
			border-color: ${THEME.colors.darkBlue3};
		}
	}
`;

const StyledMarkSelectedAlbum = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: ${THEME.colors.orangeBase};
	opacity: 0.1;
`;

const StyledWrapperName = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
`;

const StyledName = styled.div`
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	color: ${THEME.colors.gray5};
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledCount = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${THEME.colors.gray1};
`;

const StyledDescription = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: ${THEME.colors.white};
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
`;

const StyledWrapperListTheme = styled.div<{ justifyContent?: string }>`
	display: flex;
	height: 100%;
	align-items: center;
	font-size: 16px;
	justify-content: ${({ justifyContent }) => justifyContent || 'end'};
`;
const StyledWrapperTheme = styled.div`
	height: ${HEIGHT_THUMBNAIL_ALBUM}px;
	overflow: hidden;
	width: 100%;
	position: relative;
	&.item_theme {
		width: 20%;
		padding-right: 5px;
		&.small_screen {
			width: 50%;
		}
	}
	&.hover_none {
		&:hover {
			img {
				border-radius: 8px;
			}
		}
	}
`;

const StyledThemeItem = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 4.6976px;
	overflow: hidden;
	position: relative;
`;

const StyledImgTheme = styled.img<{ rotate?: number; scale?: number }>`
	object-fit: cover;
	width: 100%;
	height: 100%;
	transform: rotate(${({ rotate }) => rotate || 0}deg) scale(${({ scale }) => scale || 1});
`;

const StyledMarkTheme = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: ${THEME.colors.darkBlue4};
	color: ${THEME.colors.white};
	opacity: 0.8;
	display: flex;
	align-items: center;
	text-align: center;
	font-weight: 700;
	font-size: 20px;
	line-height: 22px;
	cursor: pointer;
	border-radius: 4.6976px;
	transition: 0.15s;
	&.opacity_full {
		&:hover {
			background: ${THEME.colors.darkBlue4};
		}
	}
	&:hover {
		opacity: 1;
		background: ${THEME.colors.darkBlue2};
	}
`;

const StyledLoadingSingle = styled.div<{
	isLoading?: boolean;
	bgColorLoading?: string;
	width?: string;
}>`
	display: ${({ isLoading }) => (isLoading === true ? 'flex' : 'none')};
	z-index: 99;
	height: 20px;
	width: ${({ width }) => width || '32px'};
	background-color: ${({ bgColorLoading }) => bgColorLoading || 'rgba(32, 50, 65, 0.1)'};
	align-items: center;
	justify-content: center;
`;

const StyledIconLoading = styled.img``;

const StyledContainerDetail = styled.div`
	display: flex;
	width: 100%;
`;

const StyledWrapperInfo = styled.div`
	width: calc(100% - ${WIDTH_THEME}px);
	&.small_screen {
		width: calc(100% - ${(WIDTH_THEME - 10) / 2}px);
	}
`;

const StyledWrapperView = styled.div`
	width: ${WIDTH_THEME}px;
	padding-left: 17px;
	display: inline-flex;
	align-items: center;
	&.small_screen {
		width: ${(WIDTH_THEME - 10) / 2}px;
	}
`;

const StyledMarkSuggestHover = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 12px;
	background-color: rgba(1, 22, 39, 0.9);
	opacity: 0;
	transition: 0.15s;
	z-index: -1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 23px;
	.opacity_full {
		opacity: 1;
	}
`;

const StyledWrapperAction = styled.div`
	display: inline-flex;
	align-items: center;
`;

const StyledWrapperViewSuggest = styled.div<{ width: number }>`
	height: ${HEIGHT_THUMBNAIL_ALBUM}px;
	width: ${({ width }) => width}px;
	position: relative;
	margin-right: 7px;
`;

const StyledSubHover = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${THEME.colors.gray5};
	margin-left: 15px;
`;

const StyledButton = styled(Button)`
	height: 50px;
	padding: 0 42px;
	display: inline-flex;
	align-items: center;
	&.btn_gray {
		background-color: ${THEME.colors.darkBlue3};
	}
`;

export {
	StyleWrapperAlbumItem,
	StyledWrapperName,
	StyledName,
	StyledCount,
	StyledDescription,
	StyledWrapperTheme,
	StyledThemeItem,
	StyledWrapperListTheme,
	StyledMarkTheme,
	StyledLoadingSingle,
	StyledIconLoading,
	StyledMarkSelectedAlbum,
	StyledWrapperInfo,
	StyledWrapperView,
	StyledContainerDetail,
	StyledMarkSuggestHover,
	StyledContainerAlbumItem,
	StyledWrapperAction,
	StyledWrapperViewSuggest,
	StyledButton,
	StyledSubHover,
	StyledImgTheme,
};
