import THEME from '@constants/themes/themes';
import { Drawer } from 'antd';
import styled from 'styled-components';

const StyledGraphWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	padding: 12px 0px 12px 12px;
	overflow: hidden;
`;

const StyledActionGraph = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	display: flex;
`;

const StyledWrapperSession = styled.div<{ height?: string; bgColor?: string }>`
	background-color: ${({ bgColor }) => bgColor || THEME.colors.darkBlue2};
	border-radius: 3px;
	width: 100%;
	height: ${({ height }) => height || '255px'};
	min-height: 100%;
	.ant-empty-normal {
		margin: 24px 0;
		.ant-empty-image {
			height: 28px;
		}
	}
`;

const StyledInfoType = styled.div`
	display: flex;
	align-items: center;
	font-size: 10px;
	color: ${THEME.colors.gray5};
	line-height: 12px;
	cursor: pointer;
	margin-right: 6px;
	&.hide_line {
		opacity: 0.7;
	}
	&:last-child {
		margin-right: 0;
	}
`;

const StyledDot = styled.span<{ bgColor?: string }>`
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-color: ${({ bgColor }) => bgColor || THEME.colors.orangeBase};
	margin-right: 4px;
`;

const StyledDrawerGraphConfig = styled(Drawer)`
	position: absolute;

	.ant-drawer-header {
		padding: 8px 15px;
	}
	.ant-drawer-close {
		padding: 6px;
		top: 5px;
		right: 4px;
	}
	.ant-drawer-body {
		padding: 20px 16px 8px 16px;
	}
	&.drawer_none {
		.ant-drawer-body {
			padding: 0px;
		}
	}
`;

const StyledWrapperActionConfig = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 16px;
	display: flex;
	justify-content: end;
`;

const StyledContainerSocial = styled.div`
	height: calc(100% - 66px);
	width: 100%;
	position: relative;
	overflow: hidden;
	padding: 4px 6px 0px 6px;
`;

const StyledWrapperBoxRecent = styled.div`
	border-radius: 4px;
	border: 2px solid transparent;
	transition: 0.15s ease-out;
	min-height: 100%;
	&.active_box {
		border: 2px solid ${THEME.colors.orangeBase};
	}
`;

const StyledWrapperRightAction = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: end;
	margin-left: 16px;
`;

const StyledWrapperRefreshMetric = styled.div`
	display: inline;
	margin-right: 8px;
`;

const StyledTitleTab = styled.div`
	font-size: 20px;
	color: ${THEME.colors.white};
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-bottom: 3px;
	&.active {
		border-bottom: 2px solid ${THEME.colors.orangeBase};
		cursor: unset;
	}
`;

export {
	StyledGraphWrapper,
	StyledWrapperSession,
	StyledActionGraph,
	StyledInfoType,
	StyledDot,
	StyledDrawerGraphConfig,
	StyledWrapperActionConfig,
	StyledContainerSocial,
	StyledWrapperBoxRecent,
	StyledWrapperRefreshMetric,
	StyledWrapperRightAction,
	StyledTitleTab,
};
