import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
/**
 * @prettier
 */

import genericApiSaga from '@helpers/genericApiSaga';
import { Action } from 'redux-actions';
import { takeLatest, put } from 'redux-saga/effects';
import {
	createAlbumTypes,
	deleteGuestTypes,
	exportAlbumContentsTypes,
	fetchAlbumsSuggestTypes,
	fetchAlbumsTypes,
	fetchDetailAlbumTypes,
	fetchGuestListTypes,
	getAlbumListLiteTypes,
	removeAlbumsTypes,
	removeSuggestAlbumTypes,
	sendGuestPassCodeTypes,
	updateAlbumTypes,
} from './albumManager.types';
import {
	ALBUM_LIST_LITE_API,
	EXPORT_ALBUM_CONTENT_API,
	FOLDER_ALBUM,
	FOLDER_ALBUM_SUGGEST,
	GUEST,
	SEND_GUEST_PASS_CODE,
} from '@constants/APIs';
import * as actions from './albumManager.action';
import { convertParamSearch } from '@utils/common';
import { ParamExportAlbumContentTypes } from '@models/content/albums/stores';
import { ParamRequestContentDto } from '@models/publishing/galleries';

export function* fetchAlbumManager({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(FOLDER_ALBUM, payload),
		*completed(response: any) {
			yield put(actions.fetchAlbumsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchAlbumsFailed(response));
		},
	});
}

export function* fetchAlbumSuggest({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => getRequest(FOLDER_ALBUM_SUGGEST, payload),
		*completed(response: any) {
			yield put(actions.fetchAlbumsSuggestSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchAlbumsSuggestFailed(response));
		},
	});
}

export function* createAlbum({ payload }: Action<any>) {
	const { callback, hideMessage = false, ...otherParam } = payload;
	yield genericApiSaga({
		gatewayCall: () => postRequest(FOLDER_ALBUM, otherParam),
		*completed(response: any) {
			if (callback && typeof callback === 'function') {
				callback(response?.data?.result);
			}
			yield put(actions.createAlbumSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.createAlbumFailed(response));
		},
	});
}

export function* updateAlbum({ payload }: Action<any>) {
	const { hideMessage, ...other } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(FOLDER_ALBUM, other),
		*completed(response: any) {
			yield put(actions.updateAlbumSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateAlbumFailed(response));
		},
	});
}

export function* removeAlbums({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(FOLDER_ALBUM, payload),
		*completed(response: any) {
			yield put(actions.removeAlbumsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.removeAlbumsFailed(response));
		},
	});
}

export function* removeSuggestAlbum({ payload }: Action<any>) {
	const { callback, ...otherParam } = payload;
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(FOLDER_ALBUM_SUGGEST, otherParam),
		*completed(response: any) {
			if (callback && typeof callback === 'function') {
				callback(otherParam?.id);
			}
			yield put(actions.removeSuggestAlbumSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.removeSuggestAlbumFailed(response));
		},
	});
}

export function* exportAlbumContents({
	payload,
}: Action<{ params: ParamExportAlbumContentTypes; body: ParamRequestContentDto }>) {
	const { params, body } = payload;
	yield genericApiSaga({
		gatewayCall: () =>
			putRequest(`${EXPORT_ALBUM_CONTENT_API}?${convertParamSearch(params)}`, body),
		*completed(response: any) {
			yield put(actions.exportAlbumContentsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.exportAlbumContentsFailed(response));
		},
	});
}

export function* fetchAlbumDetail({ payload }: Action<any>) {
	const { albumId, params } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${FOLDER_ALBUM}/${albumId}`, params),
		*completed(response: any) {
			yield put(actions.fetchAlbumsSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchAlbumsFailed(response));
		},
	});
}

export function* fetchGuestList({ payload }: Action<any>) {
	const searchParams = convertParamSearch(payload);

	yield genericApiSaga({
		gatewayCall: () => getRequest(`${GUEST}?${searchParams}`),
		*completed(response: any) {
			yield put(actions.fetchGuestListSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchGuestListFailed(response));
		},
	});
}

export function* sendGuestPassCode({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(SEND_GUEST_PASS_CODE, payload),
		*completed(response: any) {
			yield put(actions.sendGuestPassCodeSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.sendGuestPassCodeFailed(response));
		},
	});
}

export function* deleteGuest({ payload }: Action<any>) {
	const { clientId, params } = payload;
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${GUEST}?clientId=${clientId}`, params),
		*completed(response: any) {
			yield put(actions.deleteGuestSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.deleteGuestFailed(response));
		},
	});
}

function* getAlbumListLite({ payload }: Action<any>) {
	const { clientId, params } = payload;
	const searchParams = convertParamSearch(params);
	const customHeaders = {
		'x-client-id': clientId,
	};
	yield genericApiSaga({
		gatewayCall: () =>
			getRequest(
				`${ALBUM_LIST_LITE_API}/${clientId}?${searchParams}`,
				undefined,
				undefined,
				undefined,
				undefined,
				{
					customHeaders,
				},
			),
		*completed(response: any) {
			yield put(actions.getAlbumListLiteSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getAlbumListLiteFailed(response));
		},
	});
}

export function* createAlbumSaga() {
	yield takeLatest(fetchAlbumsTypes.FETCH_ALBUMS_REQUEST, fetchAlbumManager);
	yield takeLatest(fetchAlbumsSuggestTypes.FETCH_ALBUMS_SUGGEST_REQUEST, fetchAlbumSuggest);
	yield takeLatest(createAlbumTypes.CREATE_ALBUM_REQUEST, createAlbum);
	yield takeLatest(removeAlbumsTypes.REMOVE_ALBUM_REQUEST, removeAlbums);
	yield takeLatest(removeSuggestAlbumTypes.REMOVE_SUGGEST_ALBUM_REQUEST, removeSuggestAlbum);
	yield takeLatest(exportAlbumContentsTypes.EXPORT_ALBUM_CONTENTS_REQUEST, exportAlbumContents);
	yield takeLatest(updateAlbumTypes.UPDATE_ALBUM_REQUEST, updateAlbum);
	yield takeLatest(fetchDetailAlbumTypes.FETCH_DETAIL_ALBUM_REQUEST, fetchAlbumDetail);
	yield takeLatest(fetchGuestListTypes.FETCH_GUEST_LIST_REQUEST, fetchGuestList);
	yield takeLatest(sendGuestPassCodeTypes.SEND_GUEST_PASS_CODE_REQUEST, sendGuestPassCode);
	yield takeLatest(deleteGuestTypes.DELETE_GUEST_REQUEST, deleteGuest);
	yield takeLatest(getAlbumListLiteTypes.GET_ALBUM_LIST_LITE_REQUEST, getAlbumListLite);
}
