import { IconAddCircle, IconRemoveCircle } from '@assets/icons';
import { TYPE_CUSTOM_FIELD } from '@constants/content/widgetManager';
import { NAME_FILTER_CONTENT } from '@constants/filter/filterContent';
import { ContentRootStateDto, CustomFieldItemDto } from '@models/content/contentLibrary/store';
import {
	StyledRemovableLabels,
	StyledSelect,
	StyledText,
} from '@styled/Content/ContentLibrary/FilterStyled';
import { StyleIconRemove, StyledButtonText } from '@styled/Creators/ModalStyled';
import { StyledFormItem } from '@styled/Filter/FilterContentStyled';
import { StyledTitle } from '@styled/Header';
import { changeAllTitleCase } from '@utils/content';
import { notFoundContent } from '@utils/renderComponent';
import { Form, Select, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { OptGroup, Option } = Select;

type PropTypes = {
	form: any;
	fieldsEdit?: CustomFieldItemDto[];
	hasFilter?: boolean;
};
const CustomFieldFilter = (props: PropTypes) => {
	const { form, fieldsEdit, hasFilter } = props;
	const { t } = useTranslation();
	const { contentCustomFields } = useSelector((state: ContentRootStateDto) => state.content);
	const [currentFields, setCurrentFields] = useState<string[]>([]);

	const contentCustomOptions = useMemo(() => {
		const arrKey = Object.keys(contentCustomFields).sort();

		const result = arrKey.map((key, idx) => ({
			id: idx + 1,
			name:
				key === TYPE_CUSTOM_FIELD.shortAnswer
					? t('filter.filter_content.short_answer')
					: changeAllTitleCase(key),
			value: key,
			child: contentCustomFields[key] as CustomFieldItemDto[],
		}));
		if (fieldsEdit && fieldsEdit.length > 0) {
			fieldsEdit.forEach((f) => {
				const idxGroup = result.findIndex((g) => g.name === f.type);
				const groupFind = idxGroup !== -1 ? result[idxGroup] : null;
				if (groupFind) {
					const idxChild = groupFind.child.findIndex((ch) => ch.name === f.name);
					const itemFind = idxChild !== -1 ? groupFind.child[idxChild] : null;
					if (itemFind) {
						const values = itemFind.values;
						f.values.forEach((v) => {
							if (!values.includes(v)) {
								values.push(v);
							}
						});
						result[idxGroup].child[idxChild].values = values;
					} else {
						result[idxGroup].child.push(f);
					}
				}
			});
		}
		return result.filter((ca) => ca.child.length !== 0);
	}, [contentCustomFields, fieldsEdit]);

	const resetForm = (index: any, value: any) => {
		const fieldData = form.getFieldsValue();
		const { customFields = [] } = fieldData;

		Object.assign(customFields[index], value);
		form.setFieldsValue({ customFields });
	};

	useEffect(() => {
		if (fieldsEdit && fieldsEdit.length > 0) {
			handleFieldData();
			setCurrentFields(
				fieldsEdit.map((f) => JSON.stringify({ name: f.name, type: f.type || null })),
			);
		}
	}, [fieldsEdit]);

	const handleFieldData = () => {
		const customFields = (fieldsEdit || []).map((f) => {
			const field = JSON.stringify({
				name: f?.name,
				type: f?.type || null,
			});
			return { field: field, values: f.values };
		});
		form.setFieldsValue({ customFields });
	};

	const handleChangeSelect = (value: any, name: number) => {
		const tempArray: string[] = [...currentFields];

		tempArray[name] = value;
		setCurrentFields([...tempArray]);
		resetForm(name, { field: value, values: [] });
	};

	const handleClickRemove = (name: number) => {
		const tempArray: any = [...currentFields];
		tempArray.splice(name, 1);
		setCurrentFields([...tempArray]);
	};

	const renderValueField = (name: number) => {
		let arrOptions: string[] = [];
		let typeKey = '';
		try {
			const obj = JSON.parse(currentFields[name]);
			const nameOption = obj.name;
			typeKey = obj.type;
			const itemFind = contentCustomFields[typeKey].find((op: any) => op.name === nameOption);
			arrOptions = itemFind ? itemFind?.values : [];
		} catch (error) {
			console.log('error', error);
		}

		const isDropdown = typeKey === TYPE_CUSTOM_FIELD.dropdown;

		return (
			<StyledSelect
				mode={isDropdown ? 'multiple' : 'tags'}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				style={{ width: hasFilter ? '140px' : '150px' }}
				virtual={false}
				optionFilterProp="label"
				showArrow
				className="no_bg"
				notFoundContent={notFoundContent(t)}
				placeholder={
					isDropdown
						? t('filter.filter_content.select')
						: t('filter.filter_content.enter_values')
				}
				disabled={!currentFields[name]}
				options={arrOptions.map((item) => ({ label: item, value: item }))}
				dropdownClassName="dropdown-menu dropdown-custom-content"
			/>
		);
	};

	return (
		<StyledRemovableLabels
			className={hasFilter ? 'has_filter' : ''}
			margin="36px 0 14px 0px"
			style={{ width: '100%' }}
			left="8px">
			<StyledTitle className="remove-labels no_action">
				<StyledText className="label-change_tag" fontSize="1rem">
					{t('gallery_manager.modal.custom_fields')}
				</StyledText>
			</StyledTitle>
			<Form.List name={NAME_FILTER_CONTENT.GROUP_CUSTOM_FIELDS}>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => (
							<Space
								align="baseline"
								key={key}
								style={{ display: 'flex', marginBottom: 8 }}>
								<StyledFormItem
									{...restField}
									name={[name, 'field']}
									rules={[
										{
											required: true,
											message: t('validate.required'),
										},
									]}>
									<StyledSelect
										mode={undefined}
										showSearch
										virtual={false}
										allowClear
										optionFilterProp={'children'}
										notFoundContent={notFoundContent(t)}
										getPopupContainer={(triggerNode: HTMLElement) =>
											triggerNode.parentNode as HTMLElement
										}
										placeholder={t('filter.filter_content.any_field')}
										showArrow
										className="select_group_tags no_bg"
										onChange={(value: any) => {
											handleChangeSelect(value, name);
										}}
										style={{ width: hasFilter ? '140px' : '150px' }}
										dropdownClassName="dropdown-menu dropdown-custom-content">
										{contentCustomOptions.map((group, index: number) => (
											<OptGroup key={group?.name} label={group?.name}>
												{group.child.map(
													(option: CustomFieldItemDto & any) => {
														const val = JSON.stringify({
															name: option?.name,
															type: option?.type || null,
														});

														return (
															<Option key={val} value={val}>
																{option?.name}
															</Option>
														);
													},
												)}
											</OptGroup>
										))}
									</StyledSelect>
								</StyledFormItem>

								<StyledFormItem
									{...restField}
									name={[name, 'values']}
									rules={[
										{
											required: true,
											message: t('validate.required'),
										},
									]}>
									{renderValueField(name)}
								</StyledFormItem>

								<StyleIconRemove>
									<IconRemoveCircle
										onClick={() => {
											remove(name);
											handleClickRemove(name);
										}}
									/>
								</StyleIconRemove>
							</Space>
						))}
						<StyledButtonText
							type="text"
							style={{ marginBottom: '4px' }}
							onClick={() => add()}
							block
							icon={<IconAddCircle />}>
							{t('filter.filter_content.add_field')}
						</StyledButtonText>
					</>
				)}
			</Form.List>
		</StyledRemovableLabels>
	);
};

export default CustomFieldFilter;
