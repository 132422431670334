import { Collapse } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

// Model

// Components
import CreatorItem from '@components/Messages/MessageLog/Logs/CreatorItem';

// Styled
import { StyledCreatorListWrapper, StyledLinkButton } from '@styled/Messages/MessageLogStyled';

// Redux
import { EMAIL_LOG } from '@constants/messages';

type SegmentListProps = { data: any; onLoadMore: Function; showPreviewFirstCreator?: boolean };

const { Panel } = Collapse;

const CreatorNoSegmentList = (props: SegmentListProps) => {
	const dispatch = useDispatch();
	const [page, setPage] = useState<number>(1);
	// const { emailLog = {} } = useSelector((state: any) => state.messages);

	const { data, onLoadMore, showPreviewFirstCreator = false } = props;
	const { eventId = 0, pagingCreator = {}, body = '', updateAt = 0, segments = [] } = data;

	const cloneObj = { ...pagingCreator } || {};
	const { totalPages = 0, creators = [] } = cloneObj;

	const onClickSeeMore = () => {
		const payload = {
			page: page + 1,
			pageRecords: EMAIL_LOG.DEFAULT_PAGE_RECORD,
			id: eventId,
			segmentId: undefined,
		};
		onLoadMore(payload);
		setPage((prevState) => prevState + 1);
	};

	const renderOtherPropsCreatorItem = (item: any) => {
		if (showPreviewFirstCreator && creators?.length > 0 && item?.id === creators[0].id) {
			return { openFirstItem: item };
		}
		return {};
	};

	const renderCreator = () =>
		!creators
			? null
			: creators.map((item: any) => (
					<CreatorItem
						{...renderOtherPropsCreatorItem(item)}
						key={item?.id}
						data={{ ...item, body, segments, updateAt, eventId }}
					/>
			  ));

	return (
		<StyledCreatorListWrapper>
			{renderCreator()}
			{page !== totalPages && totalPages !== 0 && (
				<StyledLinkButton onClick={onClickSeeMore}>See more</StyledLinkButton>
			)}
		</StyledCreatorListWrapper>
	);
};

export default CreatorNoSegmentList;
