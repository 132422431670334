import Button from '@cores/Button';
import { Modal as AntModal } from 'antd';
import styled from 'styled-components';
import THEME from '@constants/themes/themes';

const StyledConfirmModal = styled(AntModal)<{
	isAdditional?: boolean;
	padding?: string;
	textAlign?: string;
}>`
	.ant-modal-content {
		background-color: ${THEME.colors.darkBlue3};

		.ant-modal-body {
			padding: ${({ padding }) => padding || '24px'};
			text-align: ${({ textAlign }) => textAlign || 'center'};
		}

		.ant-modal-footer {
			padding: 0 24px 24px 24px;
			border-top: none;
			justify-content: ${({ isAdditional }) => (isAdditional ? 'center' : 'flex-end')};

			.ant-btn {
				&.ant-btn-ghost {
					border: none;
					background-color: ${THEME.colors.darkBlue5};

					:hover {
						color: unset;
					}
				}

				&.ant-btn-primary {
					&:disabled {
						background-color: ${THEME.colors.orangeBase};
						color: ${THEME.colors.gray5};
					}
				}
			}

			.ant-btn-loading-icon {
				svg {
					fill: #e0e3e5;
				}
			}
		}
	}
`;

type TypeBtn = 'primary' | 'default' | 'ghost' | 'dashed' | 'link' | 'text' | undefined;

type Props = {
	isOpen: boolean;
	closable?: boolean;
	confirmLoading?: boolean;
	title?: string;
	onSubmit?: Function;
	onClose?: Function;
	width?: number;
	okText?: string;
	cancelText?: string;
	otherConfirmText?: string;
	onOtherConfirm?: Function;
	padding?: string;
	onClickBtnCancel?: Function;
	confirmOtherLoading?: boolean;
	footer?: React.ReactNode;
	maskClosable?: boolean;
	typeCancel?: TypeBtn;
	typeOk?: TypeBtn;
	typeOtherOk?: TypeBtn;
	textAlign?: string;
	cancelLoading?: boolean;
};

const ConfirmModal: React.FC<Props> = ({
	children,
	isOpen,
	closable,
	confirmLoading,
	title,
	onSubmit,
	onClose,
	width,
	okText,
	cancelText,
	otherConfirmText,
	onOtherConfirm,
	padding,
	onClickBtnCancel,
	confirmOtherLoading,
	footer,
	maskClosable = false,
	typeCancel,
	typeOk,
	typeOtherOk,
	textAlign,
	cancelLoading = false,
}) => {
	const handleOk = () => {
		if (typeof onSubmit === 'function') {
			onSubmit();
		}
	};

	const handleCancel = () => {
		if (typeof onClose === 'function') {
			onClose();
		}
	};

	const handleOtherConfirmFunc = () => {
		if (typeof onOtherConfirm === 'function') {
			onOtherConfirm();
		}
	};

	const handleActionBtnCancel = () => {
		if (onClickBtnCancel && typeof onClickBtnCancel === 'function') {
			onClickBtnCancel();
		} else {
			handleCancel();
		}
	};

	return (
		<StyledConfirmModal
			maskClosable={!!maskClosable}
			centered={true}
			width={width}
			title={title || null}
			closable={closable}
			visible={isOpen}
			onOk={handleOk}
			onCancel={handleCancel}
			isAdditional={!!otherConfirmText}
			padding={padding}
			zIndex={1025}
			textAlign={textAlign}
			footer={
				footer || [
					<Button
						loading={cancelLoading}
						type={typeCancel || 'ghost'}
						onClick={handleActionBtnCancel}
						disabled={confirmLoading || confirmOtherLoading}>
						{cancelText}
					</Button>,
					<Button
						loading={confirmLoading}
						type={typeOk || 'primary'}
						onClick={handleOk}
						disabled={confirmOtherLoading}>
						{okText}
					</Button>,
					otherConfirmText && (
						<Button
							disabled={confirmLoading}
							className="other-confirm-button"
							loading={confirmOtherLoading}
							type={typeOtherOk || 'primary'}
							onClick={handleOtherConfirmFunc}>
							{otherConfirmText}
						</Button>
					),
				]
			}>
			{children}
		</StyledConfirmModal>
	);
};

ConfirmModal.defaultProps = {
	onSubmit: () => {},
	onClose: () => {},
	closable: false,
	confirmLoading: false,
	width: 400,
	okText: 'Yes',
	cancelText: 'Cancel',
	otherConfirmText: '',
	confirmOtherLoading: false,
};

export default ConfirmModal;
