import {
	Button,
	Col,
	ConfigProvider,
	DatePicker,
	Form,
	InputNumber,
	Radio,
	RadioChangeEvent,
	Row,
	Table,
	message,
} from 'antd';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import XLSX from 'xlsx';

// Helper
import { handleDownloadRewardsTemplate } from '@helpers/base64Helper';
import { formatListCode, renderSelectedSegment } from '@helpers/formHelper';

// Constant
import {
	ACTIVE,
	ARCHIVED,
	DISCOUNT,
	DRAFT,
	FAILED,
	FETCHING,
	FIXED,
	PERCENTAGE,
	REWARD_TYPES,
	SUCCESSFUL,
} from '@constants/reward';

// Icon
import { IconAdd, IconDownload } from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';

// Redux
import { getSegmentReward } from '@stores/actions';
import {
	createReward,
	goLiveDraftReward,
	saveDraftReward,
	setInventoryState,
	updateReward,
} from '@stores/rewards/rewards.actions';

// Styled
import { KEYBOARD, PATTERNS, TYPE_FORMAT_DATE } from '@constants/common';
import THEME from '@constants/themes/themes';
import { ColumnDto } from '@models/common/summary';
import { CodeItemDto } from '@models/rewards/inventory';
import { StyledFlex, StyledFormItem, StyledIcon, StyledInput } from '@styled/Common/CommonStyled';
import {
	StyledBlock,
	StyledColBorder,
	StyledFilePreview,
	StyledForm,
	StyledIconCancel,
	StyledIconInfo,
	StyledModal,
	StyledSelect,
	StyledStepItem,
	StyledSteps,
	StyledSwitch,
	StyledTitle,
	StyledUpload,
} from '@styled/Rewards/RewardsStyled';
import { StyledEmpty } from '@styled/Rewards/StyledTable';
import { validateInputNumber } from '@utils/common';
import { EventValue } from 'rc-picker/lib/interface';
import { SPECIAL_CHARS } from '@constants/settings';

type ModalProps = {
	editedReward?: any;
	visible: boolean;
	onClose: () => void;
	type?: string;
};

const { RangePicker } = DatePicker;

const StyledTable = styled(Table)`
	.ant-table {
		display: block;
		max-width: -moz-fit-content;
		max-width: fit-content;
		margin: 0 auto;
		overflow-x: auto;
		white-space: nowrap;
		.ant-table-selection-column {
			width: 50px;
		}
	}

	.ant-spin-nested-loading {
		> div {
			> .ant-spin {
				max-height: unset;
			}
		}
	}
`;

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

const InventoryDiscountModal = ({ visible, onClose, editedReward, type }: ModalProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const rewards = useSelector((state: any) => state.rewards);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [currentSegmentPage, setCurrentSegmentPage] = useState<number>(1);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedCodeRowKeys, setSelectedCodeRowKeys] = useState<React.Key[]>([]);
	const [isLimitTime, setIsLimitTime] = useState<boolean>(false);
	const [limitOnePerCustomer, setLimitOnePerCustomer] = useState<boolean>(false);
	const [uploadedFile, setUploadedFile] = useState<any>([]);
	const [segmentType, setSegmentType] = useState<string>('allowedSegmentIds');
	const [codeData, setCodeData] = useState<any>([]);
	const [submitData, setSubmitData] = useState<any>({});
	const {
		fetchingSaveDraft,
		fetchingCreateReward,
		segmentList = null,
		fetchingSegment,
	} = rewards;
	const isEditing = Object.keys(editedReward).length !== 0;
	const isArchived = editedReward.status === ARCHIVED;
	const isDrafted = editedReward.status === DRAFT;
	const currentTime = new Date();
	const defaultTimer: [EventValue<Moment>, EventValue<Moment>] = [
		moment(currentTime),
		moment(currentTime.setFullYear(new Date().getFullYear() + 1)),
	];

	const reward = React.useMemo(() => {
		return {
			percentageDiscount: type === REWARD_TYPES.PERCENTAGE_DISCOUNT,
			fixedDiscount: type === REWARD_TYPES.FIXED_DISCOUNT,
			title:
				type === REWARD_TYPES.PERCENTAGE_DISCOUNT
					? t('rewards.modal.configure_percentage_discount')
					: t('rewards.modal.configure_fixed_discount'),
		};
	}, [type]);

	const columns: ColumnDto[] = [
		{
			title: t('rewards.creator_segments'),
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: t('rewards.creators'),
			dataIndex: 'totalCreatorProfiles',
			key: 'totalCreatorProfiles',
			align: 'right',
		},
	];

	const codeColumns = [
		{
			title: t('rewards.code'),
			dataIndex: 'CODE',
			show: true,
		},
	];

	// Handle close modal
	useEffect(() => {
		if (fetchingCreateReward === SUCCESSFUL) {
			handleCancel();
		}
		if (fetchingSaveDraft === SUCCESSFUL) {
			handleCancel();
		}
	}, [fetchingCreateReward, fetchingSaveDraft]);

	useEffect(() => {
		// Fill data for updating reward
		fillEditingData();
	}, [visible]);

	const fillEditingData = () => {
		if (visible === true && isEditing) {
			const {
				allowedSegmentIds,
				excludedSegmentIds,
				limitOnePerCustomer: responseLimit,
				title,
				estimatedCashValue,
				usageLimit,
				discountAmount,
				dateStarts,
				dateExpires,
			} = editedReward;
			if (allowedSegmentIds.length > 0) {
				setSegmentType('allowedSegmentIds');
				setSelectedRowKeys(allowedSegmentIds);
			}
			if (excludedSegmentIds.length > 0) {
				setSegmentType('excludedSegmentIds');
				setSelectedRowKeys(excludedSegmentIds);
			}
			if (dateExpires) {
				setIsLimitTime(true);
			}
			form.setFieldsValue({
				title,
				usageLimit,
				estimatedCashValue,
				discountAmount,
				limitOnePerCustomer,
				limitDate: dateExpires ? [moment(dateStarts), moment(dateExpires)] : defaultTimer,
			});

			// Handle step 2
			setLimitOnePerCustomer(responseLimit);
			// submitData.limitOnePerCustomer = limitOnePerCustomer;
			submitData.dateStarts = dateStarts;
			submitData.dateExpires = dateExpires;
			setSubmitData(submitData);
		}
	};

	useEffect(() => {
		const node = document.querySelector<HTMLElement>('#percentageSegmentList .ant-table-body');
		if (node && currentStep === 1) {
			const handleScroll = () => {
				if (node.scrollHeight === node.scrollTop + node.clientHeight && !fetchingSegment) {
					onScrollBottomSegmentList();
					node.scrollTop = node.scrollHeight;
				}
			};
			node.addEventListener('scroll', handleScroll);
			return () => node.removeEventListener('scroll', handleScroll);
		}
		return () => {};
	}, [currentSegmentPage, fetchingSegment]);

	useEffect(() => {
		if (fetchingCreateReward === SUCCESSFUL) {
			handleCancel();
		}
	}, [fetchingCreateReward]);

	const checkRequiredValidation = () => {
		const { title, usageLimit, discountAmount, estimatedCashValue } = submitData;
		const isError = title || usageLimit || discountAmount || estimatedCashValue;
		return isError ? false : true;
	};

	const onScrollBottomSegmentList = () => {
		const params = {
			page: currentSegmentPage + 1,
			pageRecords: 7,
		};
		dispatch(getSegmentReward(params));
		setCurrentSegmentPage(currentSegmentPage + 1);
	};

	const handleChangeUploadFile = ({ fileList }: any) => {
		setCodeData([]);
		setUploadedFile(fileList);
		const file = fileList[0].originFileObj;
		const fileReader = new FileReader();
		fileReader.readAsArrayBuffer(file);
		fileReader.onload = (e: any) => {
			const bufferArray = e.target.result;
			const wb = XLSX.read(bufferArray, { type: 'buffer' });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data: CodeItemDto[] = XLSX.utils.sheet_to_json(ws);
			processCodeList(data);
		};
	};

	const processCodeList = (data: CodeItemDto[]) => {
		let codeListTemp = [...codeData];
		data = data.map((item: CodeItemDto) => {
			// Filter codes which are not duplicated when entering manually
			codeListTemp = codeListTemp.filter(
				(codeItem: CodeItemDto) => codeItem.CODE !== item.CODE,
			);
			return {
				...item,
				key: item.CODE,
				isManual: false,
			};
		});
		const newCodeList = [...codeListTemp, ...data];
		const selectedCode = newCodeList.map((item: CodeItemDto) => item.CODE);
		setSelectedCodeRowKeys(selectedCode);
		setCodeData(newCodeList);
	};

	const handleCancel = () => {
		onClose();

		// Clean up
		rewards.fetchingCreateReward = FAILED;
		rewards.segmentList = [];
		dispatch(setInventoryState(rewards));
		form.resetFields();
		setCurrentStep(0);
		setCurrentSegmentPage(1);
		setSelectedRowKeys([]);
		setSelectedCodeRowKeys([]);
		setCodeData([]);
		setSubmitData({});
		setSegmentType('allowedSegmentIds');
		setIsLimitTime(false);
		setUploadedFile([]);
	};

	const handleChangeSegmentType = (e: RadioChangeEvent) => {
		setSegmentType(e.target.value);
	};

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const onSelectChangeCodeRow = (selectedRowKeys: React.Key[]) => {
		setSelectedCodeRowKeys(selectedRowKeys);
	};

	const onChangeRewardLimitTime = (checked: any) => {
		setIsLimitTime(checked);
	};

	const onChangeStep = (current: number) => {
		handleFormatSubmitData();
		setCurrentStep(current);
	};

	const handleChangeLimitOnePerCustomer = (value: any) => {
		setLimitOnePerCustomer(value);
	};

	const handleNextStep = () => {
		form.validateFields()
			.then(() => {
				handleFormatSubmitData();
				if (currentStep !== 3) {
					const step = currentStep + 1;
					setCurrentStep(step);
				}
			})
			.catch(() => {});
	};

	const handleClickSubmit = async () => {
		handleFormatSubmitData();
		form.validateFields()
			.then(() => {
				const payload = { type: DISCOUNT, params: { ...submitData, status: ACTIVE } };
				dispatch(createReward(payload));
			})
			.catch(() => {});
	};

	const handleUpdateReward = () => {
		const { status, id } = editedReward;
		handleFormatSubmitData();

		form.validateFields()
			.then(() => {
				const payload = {
					type: DISCOUNT,
					params: { ...submitData, id, status },
				};
				dispatch(updateReward(payload));
			})
			.catch(() => {});
	};

	const handleClickSaveDraft = () => {
		form.validateFields()
			.then(() => {
				handleFormatSubmitData();
				const params = { ...submitData, status: DRAFT };
				if (isEditing) {
					Object.assign(params, { id: editedReward.id });
				}

				if (checkRequiredValidation()) {
					message.error(t('rewards.message.at_least_one_value'));
				} else {
					const payload = { type: DISCOUNT, params };
					dispatch(saveDraftReward(payload));
				}
			})
			.catch(() => {});
	};

	const handleGoLiveDraftReward = () => {
		handleFormatSubmitData();
		const params = { ...submitData, status: DRAFT };
		if (isEditing) {
			Object.assign(params, { id: editedReward.id });
		}
		form.validateFields()
			.then(() => {
				const payload = { type: DISCOUNT, params };
				dispatch(goLiveDraftReward(payload));
			})
			.catch(() => {});
	};

	const handleFormatSubmitData = () => {
		const values = form.getFieldsValue();

		if (currentStep === 0) {
			// submitData.limitOnePerCustomer = editedReward.limitOnePerCustomer ?? false;
			submitData.title = values.title;
			submitData.discountType = reward.percentageDiscount ? PERCENTAGE : FIXED;
			submitData.discountAmount = values.discountAmount;
			submitData.estimatedCashValue = reward.percentageDiscount
				? values.estimatedCashValue
				: values.discountAmount;
			submitData.usageLimit = parseInt(values.usageLimit, 10);
		}
		if (currentStep === 1) {
			// submitData.limitOnePerCustomer = values.limitOnePerCustomer ?? false;
			if (isLimitTime && values.limitDate) {
				submitData.dateStarts = values.limitDate[0].valueOf() ?? '';
				submitData.dateExpires = values.limitDate[1].valueOf() ?? '';
			}
			if (!isLimitTime) {
				delete submitData.dateStarts;
				delete submitData.dateExpires;
			}
		}
		if (currentStep === 2) {
			submitData.listCode = formatListCode(codeData, selectedCodeRowKeys);
		}
		submitData.limitOnePerCustomer = limitOnePerCustomer;
		submitData[segmentType] = selectedRowKeys;
		if (segmentType === 'allowedSegmentIds' || submitData.excludedSegmentIds.length === 0) {
			delete submitData.excludedSegmentIds;
		}
		if (segmentType === 'excludedSegmentIds' || submitData.allowedSegmentIds.length === 0) {
			delete submitData.allowedSegmentIds;
		}
		if (submitData.title === '') {
			delete submitData.title;
		}
		setSubmitData(submitData);
	};

	const handleRemoveUploadedFile = (event: any) => {
		setUploadedFile([]);
		const codeDataTemp = codeData.filter((item: any) => item.isManual);
		setCodeData(codeDataTemp);
		event.stopPropagation();
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: () => ({
			disabled: isArchived,
		}),
	};

	const codeRowSelection = {
		selectedRowKeys: selectedCodeRowKeys,
		onChange: onSelectChangeCodeRow,
	};

	const renderSteps = () => {
		return (
			<StyledSteps
				current={currentStep}
				percent={60}
				labelPlacement="vertical"
				onChange={onChangeStep}>
				<StyledStepItem title={t('rewards.steps.details')} />
				<StyledStepItem title={t('rewards.steps.eligibility')} />
				<StyledStepItem title={t('rewards.steps.import_codes')} />
				<StyledStepItem title={t('rewards.steps.confirmation')} />
			</StyledSteps>
		);
	};

	const renderStep1 = () => {
		return (
			<>
				<StyledTitle level={4}>{reward.title}</StyledTitle>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item
							label={t('rewards.modal.title_ask')}
							name="title"
							rules={[
								{
									required: true,
									message: t('rewards.modal.required_not_field'),
								},
							]}>
							<StyledInput disabled={isArchived} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={t('rewards.modal.amount_offer_ask')}
							name="usageLimit"
							rules={[
								{
									required: true,
									message: t('rewards.modal.required_not_field'),
								},
							]}>
							<InputNumber
								disabled={isArchived}
								onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
									validateInputNumber(event);
								}}
							/>
						</Form.Item>
					</Col>
					{reward.percentageDiscount && (
						<Col span={12}>
							<Form.Item
								label={t('rewards.modal.estimated_cash_value_ask')}
								name="estimatedCashValue"
								rules={[
									{
										required: true,
										message: t('rewards.modal.required_not_field'),
									},
								]}>
								<StyledInput
									onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
										validateInputNumber(event, true);
									}}
									suffix={SPECIAL_CHARS.DOLLAR}
									disabled={isArchived}
								/>
							</Form.Item>
						</Col>
					)}
					<Col span={12}>
						<Form.Item
							label={
								reward.percentageDiscount
									? t('rewards.modal.percentage_amount_ask')
									: t('rewards.modal.discount_amount_ask')
							}
							name="discountAmount"
							rules={[
								{
									required: true,
									message: t('rewards.modal.required_not_field'),
								},
							]}>
							<StyledInput
								onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
									validateInputNumber(event, true);
								}}
								suffix={
									reward.percentageDiscount
										? SPECIAL_CHARS.PERCENTAGE
										: SPECIAL_CHARS.DOLLAR
								}
								disabled={isArchived}
							/>
						</Form.Item>
					</Col>
				</Row>
			</>
		);
	};

	const renderStep2 = () => {
		return (
			<>
				<StyledTitle level={4}>
					{t('rewards.modal.configure_percentage_discount')}
				</StyledTitle>
				<Row gutter={24}>
					<Col span={12}>
						<p>
							{t('rewards.modal.eligible_for_discount')} <StyledIconInfo />
						</p>
						<p>{t('rewards.modal.following_segment')}</p>
						<Radio.Group
							value={segmentType}
							onChange={handleChangeSegmentType}
							disabled={isArchived}>
							<Radio value="allowedSegmentIds">{t('rewards.modal.eligible')}</Radio>
							<Radio value="excludedSegmentIds">{t('rewards.modal.excluded')}</Radio>
						</Radio.Group>
						<br />
						<StyledTable
							columns={columns}
							dataSource={segmentList}
							id="percentageSegmentList"
							pagination={false}
							scroll={{
								scrollToFirstRowOnChange: false,
								y: 200,
							}}
							rowSelection={rowSelection}
						/>
					</Col>
					<Col span={12}>
						<StyledBlock>
							<span>{t('rewards.modal.limit_time')}</span>
							<StyledSwitch
								disabled={isArchived}
								onChange={onChangeRewardLimitTime}
								checked={isLimitTime}
							/>
						</StyledBlock>
						{isLimitTime && (
							<Form.Item name="limitDate">
								<RangePicker
									disabled={isArchived}
									format={TYPE_FORMAT_DATE.MONTH_DAY}
									defaultValue={defaultTimer}
								/>
							</Form.Item>
						)}
						<StyledBlock>
							<span>{t('rewards.modal.limit_one_customer')}</span>
							<Form.Item name="limitOnePerCustomer" valuePropName="checked">
								<StyledSwitch
									disabled={isArchived}
									checked={limitOnePerCustomer}
									defaultChecked={false}
									onChange={handleChangeLimitOnePerCustomer}
								/>
							</Form.Item>
						</StyledBlock>
					</Col>
				</Row>
			</>
		);
	};

	const inputCodeManually = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === KEYBOARD.ENTER.STR) {
			const { value } = e.target as any;
			const code = value.trim();
			const regex = PATTERNS.REWARD_DISCOUNT_CODE;

			if (code && !checkDuplicateCode(code) && regex.test(code)) {
				const dataTemp = [...codeData];
				dataTemp.push({
					key: code,
					CODE: code,
					isManual: true,
				});

				setCodeData(dataTemp);
				setSelectedCodeRowKeys([...selectedCodeRowKeys, code]);
				form.setFieldsValue({
					code: '',
				});
			}
		}
	};

	const checkDuplicateCode = (value: string) => {
		const result = codeData.some((item: any) => item.CODE === value);
		return result;
	};

	const renderStep3 = () => {
		return (
			<>
				<StyledTitle level={4}>{t('rewards.modal.import_codes')}</StyledTitle>
				<Row gutter={24}>
					<Col span={12}>
						{uploadedFile.length !== 1 && (
							<p>{t('rewards.modal.import_instruction')}</p>
						)}
						<StyledUpload
							showUploadList={false}
							maxCount={1}
							beforeUpload={() => false}
							onChange={handleChangeUploadFile}>
							{uploadedFile.length === 0 ? (
								<StyledFlex align="center" justify="center">
									<StyledIcon margin="0 6px 0 0" fillPath={THEME.colors.gray5}>
										<IconAdd />
									</StyledIcon>
									{t('rewards.modal.upload_list_of_codes')}
								</StyledFlex>
							) : (
								<StyledFilePreview>
									{uploadedFile[0].name}{' '}
									<StyledIconCancel onClick={handleRemoveUploadedFile} />
								</StyledFilePreview>
							)}
						</StyledUpload>
						<Button type="primary" onClick={handleDownloadRewardsTemplate}>
							<IconDownload /> {t('rewards.modal.download_template')}
						</Button>
					</Col>
					<Col span={12}>
						<StyledFormItem
							margin="0 0 24px 0"
							marginHasError="0 0 48px 0"
							name="code"
							rules={[
								{
									pattern: PATTERNS.REWARD_DISCOUNT_CODE,
									message: t('email_builder.validation.discount_code_rule'),
								},
								() => ({
									validator(_: any, value: string) {
										if (!value || !checkDuplicateCode(value.trim())) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												t('rewards.message.duplicated', {
													fieldName: t('email_builder.discount_code'),
												}),
											),
										);
									},
								}),
							]}>
							<StyledInput
								placeholder={t('rewards.modal.input_code')}
								onKeyDown={inputCodeManually}
							/>
						</StyledFormItem>
						<StyledTable
							columns={codeColumns}
							dataSource={codeData}
							pagination={false}
							scroll={{ y: 300 }}
							rowSelection={codeRowSelection}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const renderStep4 = () => {
		return (
			<>
				<StyledTitle level={4}>{t('rewards.modal.here_reward')}</StyledTitle>
				<Row gutter={24}>
					<Col span={12}>
						<StyledColBorder>
							<p>{t('rewards.modal.reward_details')}</p>
							<Form.Item
								label={t('rewards.modal.discount_title')}
								name="title"
								rules={[
									{
										required: true,
										message: t('rewards.modal.required', {
											field: t('rewards.modal.discount_title'),
										}),
									},
								]}>
								<StyledInput disabled />
							</Form.Item>
							<Form.Item
								label={
									reward.percentageDiscount
										? t('rewards.modal.percentage_discount')
										: t('rewards.modal.fixed_discount')
								}
								name="discountAmount"
								rules={[
									{
										required: true,
										message: t('rewards.modal.required', {
											field: reward.percentageDiscount
												? t('rewards.modal.percentage_discount')
												: t('rewards.modal.fixed_discount'),
										}),
									},
								]}>
								<StyledInput
									disabled
									suffix={
										reward.percentageDiscount
											? SPECIAL_CHARS.PERCENTAGE
											: SPECIAL_CHARS.DOLLAR
									}
								/>
							</Form.Item>
							{reward.percentageDiscount && (
								<Form.Item
									label={t('rewards.modal.estimated_cash_value')}
									name="estimatedCashValue"
									rules={[
										{
											required: true,
											message: t('rewards.modal.required', {
												field: t('rewards.modal.estimated_cash_value'),
											}),
										},
									]}>
									<StyledInput disabled suffix={SPECIAL_CHARS.DOLLAR} />
								</Form.Item>
							)}
							<Form.Item
								label={t('rewards.modal.offer_limit')}
								name="usageLimit"
								rules={[
									{
										required: true,
										message: t('rewards.modal.required', {
											field: t('rewards.modal.offer_limit'),
										}),
									},
								]}>
								<StyledInput disabled />
							</Form.Item>
						</StyledColBorder>
					</Col>
					<Col span={12}>
						<StyledColBorder>
							<p>{t('rewards.modal.eligibility')}</p>
							<Form.Item label={t('rewards.modal.eligible_segments')}>
								<StyledSelect
									disabled
									mode="multiple"
									showArrow={true}
									defaultValue={renderSelectedSegment(
										segmentList,
										selectedRowKeys,
									)}
									style={{ width: '100%' }}
								/>
							</Form.Item>
							<Form.Item label={t('rewards.modal.offer_end_date')}>
								<StyledInput
									disabled
									value={
										submitData.dateExpires || isLimitTime
											? moment(submitData.dateExpires).format(
													TYPE_FORMAT_DATE.MONTH_DAY,
											  )
											: ''
									}
								/>
							</Form.Item>
							<Form.Item label={t('rewards.modal.limit')}>
								<StyledInput
									disabled
									value={submitData.limitOnePerCustomer ? 'One per customer' : ''}
								/>
							</Form.Item>
						</StyledColBorder>
					</Col>
				</Row>
			</>
		);
	};

	const renderFooterPrimaryButton = () => {
		if (currentStep === 3) {
			if (isDrafted) {
				return (
					<Button
						onClick={handleGoLiveDraftReward}
						key="submit"
						type="primary"
						loading={fetchingCreateReward === FETCHING}>
						{t('button.go_live')}
					</Button>
				);
			}
			if (isEditing) {
				return (
					<Button
						onClick={handleUpdateReward}
						key="submit"
						type="primary"
						loading={fetchingCreateReward === FETCHING}>
						{t('button.save')}
					</Button>
				);
			} else {
				return (
					<Button
						onClick={handleClickSubmit}
						key="submit"
						type="primary"
						loading={fetchingCreateReward === FETCHING}>
						{t('button.go_live')}
					</Button>
				);
			}
		}
		if (currentStep === 2 && uploadedFile.length === 0) {
			return (
				<Button onClick={handleNextStep} key="submit" type="primary">
					{t('button.skip')}
				</Button>
			);
		} else {
			return (
				<Button onClick={handleNextStep} key="submit" type="primary">
					{t('button.next')}
				</Button>
			);
		}
	};

	const renderFooterSecondaryButton = () => {
		if (!isEditing || isDrafted) {
			return (
				<Button
					key="back"
					onClick={handleClickSaveDraft}
					loading={fetchingSaveDraft === FETCHING}>
					{t('button.save_draft')}
				</Button>
			);
		}
		return <></>;
	};

	return (
		<ConfigProvider renderEmpty={customizeRenderEmpty}>
			<StyledModal
				visible={visible}
				width={800}
				onCancel={handleCancel}
				footer={
					!isArchived && [renderFooterSecondaryButton(), renderFooterPrimaryButton()]
				}>
				{renderSteps()}
				<StyledForm
					form={form}
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }}
					requiredMark={false}>
					{currentStep === 0 && renderStep1()}
					{currentStep === 1 && renderStep2()}
					{currentStep === 2 && renderStep3()}
					{currentStep === 3 && renderStep4()}
				</StyledForm>
			</StyledModal>
		</ConfigProvider>
	);
};

export default React.memo(InventoryDiscountModal);
