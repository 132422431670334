const TASK_STATUS = {
	TO_DO: 'TO_DO',
	IN_PROGRESS: 'IN_PROGRESS',
	DONE: 'DONE',
};

const TASK_DEFAULT_PAYLOAD = {
	page: 1,
	maxRecords: 200,
};

const TASK_PARAMS_DEFAULT = {
	...TASK_DEFAULT_PAYLOAD,
	sortBy: 'updateAt',
	sortDirection: 'DESC',
};

const TASK_MANAGER_FIELD_NAME = {
	STATUS: 'status',
	ASSIGNED_TO_EMAILS: 'assignedToEmails',
	ASSIGNED_BY_EMAILS: 'assignedByEmails',
};

const TASK_ACTION_TYPE = {
	OPEN_DETAILS: 'OPEN_DETAILS',
	ADD_NEW_TASK: 'ADD_NEW_TASK',
	COMMENT_ADDED: 'COMMENT_ADDED',
	TASK_ASSIGNED: 'TASK_ASSIGNED',
	TASK_CREATED: 'TASK_CREATED',
	CLOSE_DETAILS: 'CLOSE_DETAILS',
};

const TASK_DETAIL_MODAL_TYPES = {
	DELETE_TASK: 'DELETE_TASK',
	DELETE_COMMENT: 'DELETE_COMMENT',
	SEND_REMINDER_EMAIL: 'SEND_REMINDER_EMAIL',
	EDIT_COMMENT: 'EDIT_COMMENT',
	UNSAVED_CHANGES: 'UNSAVED_CHANGES',
};

const DEFAULT_TASK_FILTERS = {
	assignedByEmails: null,
	assignedToEmails: null,
	status: null,
};

const KEYWORDS = {
	MENTIONED_USER: 'data-email',
};

const ELEMENT_SELECTOR = {
	TASK_STATUS: 'task_status_dropdown',
	ASSIGNED_USER_SELECT_DROPDOWN: 'assigned_user_select_dropdown',
	TASK_LIST_ID: 'task_list',
	ELEMENT_TO_FOCUS_ID: 'elementToFocusId',
	SAVE_COMMENT_BUTTON_ID: 'save_comment_btn_id',
	CUSTOM_MENTION_DROPDOWN_ID: 'custom_mention_dropdown_id',
	TASK_DETAIL_ARROW_BTN: 'task_detail_arrow_btn',
	MODAL_WRAP: 'ant-modal-wrap',
};

export {
	TASK_STATUS,
	TASK_PARAMS_DEFAULT,
	TASK_MANAGER_FIELD_NAME,
	TASK_ACTION_TYPE,
	TASK_DEFAULT_PAYLOAD,
	TASK_DETAIL_MODAL_TYPES,
	DEFAULT_TASK_FILTERS,
	KEYWORDS,
	ELEMENT_SELECTOR,
};
