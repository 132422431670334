const getDynamicFieldsTypes = {
	GET_DYNAMIC_FIELDS_REQUEST: 'GET_DYNAMIC_FIELDS_REQUEST',
	GET_DYNAMIC_FIELDS_SUCCEEDED: 'GET_DYNAMIC_FIELDS_SUCCEEDED',
	GET_DYNAMIC_FIELDS_FAILED: 'GET_DYNAMIC_FIELDS_FAILED',
};

const getTemplatesTypes = {
	GET_TEMPLATES_REQUEST: 'GET_TEMPLATES_REQUEST',
	GET_TEMPLATES_SUCCEEDED: 'GET_TEMPLATES_SUCCEEDED',
	GET_TEMPLATES_FAILED: 'GET_TEMPLATES_FAILED',
};

const updateTemplateTypes = {
	UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
	UPDATE_TEMPLATE_SUCCEEDED: 'UPDATE_TEMPLATE_SUCCEEDED',
	UPDATE_TEMPLATE_FAILED: 'UPDATE_TEMPLATE_FAILED',
};

const sendTestMailTemplate = {
	SEND_TEST_MAIL_REQUEST: 'SEND_TEST_MAIL_REQUEST',
	SEND_TEST_MAIL_SUCCEEDED: 'SEND_TEST_MAIL_SUCCEEDED',
	SEND_TEST_MAIL_FAILED: 'SEND_TEST_MAIL_FAILED',
	SEND_TEST_MAIL_END: 'SEND_TEST_MAIL_END',
};

const sendEmailRewardTypes = {
	SEND_EMAIL_REWARD_REQUEST: 'SEND_EMAIL_REWARD_REQUEST',
	SEND_EMAIL_REWARD_SUCCEEDED: 'SEND_EMAIL_REWARD_SUCCEEDED',
	SEND_EMAIL_REWARD_FAILED: 'SEND_EMAIL_REWARD_FAILED',
	SEND_EMAIL_REWARD_END: 'SEND_EMAIL_REWARD_END',
};

const saveDraftEmailTypes = {
	SAVE_DRAFT_EMAIL_REQUEST: 'SAVE_DRAFT_EMAIL_REQUEST',
	SAVE_DRAFT_EMAIL_SUCCEEDED: 'SAVE_DRAFT_EMAIL_SUCCEEDED',
	SAVE_DRAFT_EMAIL_FAILED: 'SAVE_DRAFT_EMAIL_FAILED',
	SAVE_DRAFT_EMAIL_END: 'SAVE_DRAFT_EMAIL_END',
};

const getEmailEventTypes = {
	GET_EMAIL_EVENT: 'GET_EMAIL_EVENT',
	GET_EMAIL_EVENT_SUCCEEDED: 'GET_EMAIL_EVENT_SUCCEEDED',
	GET_EMAIL_EVENT_FAILED: 'GET_EMAIL_EVENT_FAILED',
};

const getEmailEvenDetailTypes = {
	GET_EMAIL_EVENT_DETAIL: 'GET_EMAIL_EVENT_DETAIL',
	GET_EMAIL_EVENT_DETAIL_SUCCEEDED: 'GET_EMAIL_EVENT_DETAIL_SUCCEEDED',
	GET_EMAIL_EVENT_DETAIL_FAILED: 'GET_EMAIL_EVENT_DETAIL_FAILED',
	RESET_DB_CLICK_GET_MAIL: 'RESET_STATUS_DB_CLICK_GET_MAIL',
};

const getEventCreatorTypes = {
	GET_EVENT_CREATOR: 'GET_EVENT_CREATOR',
	GET_EVENT_CREATOR_SUCCEEDED: 'GET_EVENT_CREATOR_SUCCEEDED',
	GET_EVENT_CREATOR_FAILED: 'GET_EVENT_CREATOR_FAILED',
};

const deleteEmailTypes = {
	DELETE_EMAIL: 'DELETE_EMAIL',
	DELETE_EMAIL_SUCCEEDED: 'DELETE_EMAIL_SUCCEEDED',
	DELETE_EMAIL_FAILED: 'DELETE_EMAIL_FAILED',
};

const generateCodeTypes = {
	GENERATE_CODE_REQUEST: 'GENERATE_CODE_REQUEST',
	GENERATE_CODE_SUCCEEDED: 'GENERATE_CODE_SUCCEEDED',
	GENERATE_CODE_FAILED: 'GENERATE_CODE_FAILED',
	GENERATE_CODE_END: 'GENERATE_CODE_END',
};

const getRewardOffersTypes = {
	GET_REWARD_OFFERS_REQUEST: 'GET_REWARD_OFFERS_REQUEST',
	GET_REWARD_OFFERS_SUCCEEDED: 'GET_REWARD_OFFERS_SUCCEEDED',
	GET_REWARD_OFFERS_FAILED: 'GET_REWARD_OFFERS_FAILED',
	GET_REWARD_OFFERS_END: 'GET_REWARD_OFFERS_END',
};

// ******************* MESSAGE - EMAIL TEMPLATES *******************
const emailTemplateTypes = {
	SELECT_TAB_FOLDER_EMAIL_TEMPLATE: 'SELECT_TAB_FOLDER_EMAIL_TEMPLATE',
	SELECT_AUTOMATED_EMAIL_TEMPLATE: 'SELECT_AUTOMATED_EMAIL_TEMPLATE',
	SELECT_CUSTOM_EMAIL_TEMPLATE: 'SELECT_CUSTOM_EMAIL_TEMPLATE',
	SELECT_ARCHIVED_EMAIL_TEMPLATE: 'SELECT_ARCHIVED_EMAIL_TEMPLATE',

	GET_EMAIL_TEMPLATES_REQUEST: 'GET_EMAIL_TEMPLATES_REQUEST',
	UPDATE_EMAIL_TEMPLATES_REQUEST: 'UPDATE_EMAIL_TEMPLATES_REQUEST',
	CREATE_EMAIL_TEMPLATES_REQUEST: 'CREATE_EMAIL_TEMPLATES_REQUEST',
	ENABLE_TEMPLATES_ACTION_REQUEST: 'ENABLE_TEMPLATES_ACTION_REQUEST',
	UPDATE_EMAIL_TEMPLATES_SUCCEEDED: 'UPDATE_EMAIL_TEMPLATES_SUCCEEDED',
	RESTORE_EMAIL_TEMPLATES_REQUEST: 'RESTORE_EMAIL_TEMPLATES_REQUEST',
	RESTORE_EMAIL_TEMPLATES_SUCCEEDED: 'RESTORE_EMAIL_TEMPLATES_SUCCEEDED',
	RESTORE_EMAIL_TEMPLATES_FAILED: 'RESTORE_EMAIL_TEMPLATES_FAILED',
	GET_EMAIL_TEMPLATES_SUCCEEDED: 'GET_EMAIL_TEMPLATES_SUCCEEDED',
	CREATE_EMAIL_TEMPLATES_SUCCEEDED: 'CREATE_EMAIL_TEMPLATES_SUCCEEDED',
	GET_EMAIL_TEMPLATES_FAILED: 'GET_EMAIL_TEMPLATES_FAILED',
};

const saveAsTemplateTypes = {
	SAVE_AS_TEMPLATE_REQUEST: 'SAVE_AS_TEMPLATE_REQUEST',
	SAVE_AS_TEMPLATE_SUCCEEDED: 'SAVE_AS_TEMPLATE_SUCCEEDED',
	SAVE_AS_TEMPLATE_FAILED: 'SAVE_AS_TEMPLATE_FAILED',
	SAVE_AS_TEMPLATE_END: 'SAVE_AS_TEMPLATE_END',
};

// Direct message
const getDirectMessageTypes = {
	GET_DIRECT_MESSAGE_REQUEST: 'GET_DIRECT_MESSAGE_REQUEST',
	GET_DIRECT_MESSAGE_SUCCEEDED: 'GET_DIRECT_MESSAGE_SUCCEEDED',
	GET_DIRECT_MESSAGE_FAILED: 'GET_DIRECT_MESSAGE_FAILED',
	GET_DIRECT_MESSAGE_END: 'GET_DIRECT_MESSAGE_END',
};

// Direct message detail
const getDirectMessageDetailTypes = {
	GET_DIRECT_MESSAGE_DETAIL: 'GET_DIRECT_MESSAGE_DETAIL',
	GET_DIRECT_MESSAGE_DETAIL_SUCCEEDED: 'GET_DIRECT_MESSAGE_DETAIL_SUCCEEDED',
	GET_DIRECT_MESSAGE_DETAIL_FAILED: 'GET_DIRECT_MESSAGE_DETAIL_FAILED',
	GET_DIRECT_MESSAGE_DETAIL_END: 'GET_DIRECT_MESSAGE_DETAIL_END',
};

// Update message
const updateDirectMessageTypes = {
	UPDATE_DIRECT_MESSAGE_REQUEST: 'UPDATE_DIRECT_MESSAGE_REQUEST',
	UPDATE_DIRECT_MESSAGE_SUCCEEDED: 'UPDATE_DIRECT_MESSAGE_SUCCEEDED',
	UPDATE_DIRECT_MESSAGE_FAILED: 'UPDATE_DIRECT_MESSAGE_FAILED',
	UPDATE_DIRECT_MESSAGE_END: 'UPDATE_DIRECT_MESSAGE_END',
};

// Create direct message
const createDirectMessageTypes = {
	CREATE_DIRECT_MESSAGE_REQUEST: 'CREATE_DIRECT_MESSAGE_REQUEST',
	CREATE_DIRECT_MESSAGE_SUCCEEDED: 'CREATE_DIRECT_MESSAGE_SUCCEEDED',
	CREATE_DIRECT_MESSAGE_FAILED: 'CREATE_DIRECT_MESSAGE_FAILED',
};

// ******************* MESSAGE - DIRECT Message *******************

const getDirectMessageTemplate = {
	GET_DIRECT_MESSAGE_TEMPLATE_REQUEST: 'GET_DIRECT_MESSAGE_TEMPLATE_REQUEST',
	GET_DIRECT_MESSAGE_TEMPLATE_SUCCESSED: 'GET_DIRECT_MESSAGE_TEMPLATE_SUCCESSED',
	GET_DIRECT_MESSAGE_TEMPLATE_FAILED: 'GET_DIRECT_MESSAGE_TEMPLATE_FAILED',
	GET_SELECTED_KEY_DM_TEMPLATE: 'GET_SELECTED_KEY_DM_TEMPLATE',

	CREATE_DM_TEMPLATE_REQUEST: 'CREATE_DM_TEMPLATE_REQUEST',
	CREATE_DM_TEMPLATE_SUCCESSED: 'CREATE_DM_TEMPLATE_SUCCESSED',
	CREATE_DM_TEMPLATE_FAILED: 'CREATE_DM_TEMPLATE_FAILED',
};

const deleteDirectMessageTemplateTypes = {
	DELETE_DM_TEMPLATE_REQUEST: 'DELETE_DM_TEMPLATE_REQUEST',
	DELETE_DM_TEMPLATE_SUCCEEDED: 'DELETE_DM_TEMPLATE_SUCCEEDED',
	DELETE_DM_TEMPLATE_FAILED: 'DELETE_DM_TEMPLATE_FAILED',
	DELETE_DM_TEMPLATE_END: 'DELETE_DM_TEMPLATE_END',
};

const searchMessageTemplateTypes = {
	SEARCH_MESSAGE_TEMPLATE: 'SEARCH_MESSAGE_TEMPLATE',
};

const storeMessageTemplateDataTypes = {
	STORE_MESSAGE_TEMPLATE_DATA: 'STORE_MESSAGE_TEMPLATE_DATA',
};

export {
	getDynamicFieldsTypes,
	getTemplatesTypes,
	updateTemplateTypes,
	sendEmailRewardTypes,
	saveDraftEmailTypes,
	emailTemplateTypes,
	sendTestMailTemplate,
	getEmailEventTypes,
	getEmailEvenDetailTypes,
	getEventCreatorTypes,
	generateCodeTypes,
	getRewardOffersTypes,
	saveAsTemplateTypes,
	deleteEmailTypes,
	getDirectMessageTypes,
	updateDirectMessageTypes,
	createDirectMessageTypes,
	getDirectMessageTemplate,
	getDirectMessageDetailTypes,
	storeMessageTemplateDataTypes,
	searchMessageTemplateTypes,
	deleteDirectMessageTemplateTypes,
};
