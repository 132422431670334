import DetectThumbnailContent from '@components/ContentGlobal/DetectThumbnailContent';
import { TYPE_FILE } from '@constants/common';
import { MEDIA_TYPE } from '@constants/content/contentLibrary';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { getSocialS3URL } from '@utils/common';
import { memo, useEffect, useState } from 'react';

type PropTypes = {
	content: ContentDto;
	isGif?: boolean;
	[other: string]: any;
};

const ImageTheme = (props: PropTypes) => {
	const { content, isGif, ...other } = props;

	const handleConvertThumbnail = () => {
		if (content?.mediaType === MEDIA_TYPE.VIDEO) {
			const gif = TYPE_FILE.GIF;
			const png = TYPE_FILE.PNG;
			const positionType = content?.thumbnail?.lastIndexOf('.');
			const urlNotType = content?.thumbnail?.slice(0, positionType);
			const type = content?.thumbnail?.slice(positionType);
			const renderUrl = (tail: string) => {
				return urlNotType + tail;
			};

			if (!isGif) {
				if (type === gif) {
					const url = renderUrl(png);
					return url;
				} else {
					return content?.thumbnail;
				}
			} else {
				return renderUrl(gif);
			}
		}
		return content?.thumbnail;
	};

	const [thumbnail, setThumbnail] = useState<string>(handleConvertThumbnail());

	useEffect(() => {
		const newUrl = handleConvertThumbnail();
		if (content?.mediaType === MEDIA_TYPE.VIDEO && newUrl !== thumbnail) {
			setThumbnail(newUrl);
		}
	}, [isGif]);

	return (
		<DetectThumbnailContent
			thumbnail={getSocialS3URL(thumbnail)}
			dynamicThumbnail={getSocialS3URL(content?.dynamicThumbnail)}
			isVideo={content?.mediaType === MEDIA_TYPE.VIDEO}
			contentExternalUrl={content?.contentExternalUrl}
			social={content?.social}
			className="placeholder_bg_img"
			{...other}
		/>
	);
};

export default memo(ImageTheme);
