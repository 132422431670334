import { ImageCampaignTremendous } from '@assets/images';
import { tremendousCampaignsURL } from '@config/index';
import { KEY_POPUP_TREMENDOUS } from '@constants/settings/integrations';
import THEME from '@constants/themes/themes';
import TremendousModalContext from '@contexts/Settings/SettingIntegrations/TremendousModal';
import { RefTremendousStep } from '@models/settings/tremendous/ref';
import { TremendousStoreDto } from '@models/settings/tremendous/store';
import {
	StyledFormItem,
	StyledLinkHref,
	StyledSelect,
	StyledTitle,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { validateFormAllFieldHelper } from '@utils/funcHelper';
import { notFoundContent } from '@utils/renderComponent';
import { Form } from 'antd';
import React, { Ref, forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type PropTypes = {
	handleStep: () => void;
};

const StepTremendous2 = forwardRef((props: PropTypes, ref: Ref<RefTremendousStep>) => {
	const { t } = useTranslation();
	const { handleStep } = props;

	const { tremendousDefault }: TremendousStoreDto = useSelector((state: any) => state.tremendous);
	const { campaigns = [] } = tremendousDefault || {};

	const { orderDetail, setOrderDetail } = useContext(TremendousModalContext);

	const [form] = Form.useForm();

	useImperativeHandle(ref, () => ({ validateAllField }));

	useEffect(() => {
		if (orderDetail?.campaignId) {
			form.setFieldsValue({ [KEY_POPUP_TREMENDOUS.CAMPAIGN]: orderDetail.campaignId });
		}
	}, [orderDetail]);

	const validateAllField = () => {
		validateFormAllFieldHelper(form);
	};

	const onValuesChange = (obj: any) => {
		setOrderDetail({ ...orderDetail, campaignId: obj[KEY_POPUP_TREMENDOUS.CAMPAIGN] });
	};

	const handleFinishForm = () => {
		handleStep();
	};

	return (
		<>
			<StyledTitle className="unset_height" fontSize="20px">
				{t('tremendous.select_campaign_template')}
			</StyledTitle>
			<StyledWrapperTitle margin="16px 0">
				<StyledTitle
					width="calc(100% - 118px)"
					margin="0 16px 0 0"
					className="unset_height">
					{t('tremendous.sub_campaign_tremendous')}
				</StyledTitle>
				<img
					style={{ width: '102px', height: '100%', objectFit: 'cover' }}
					src={ImageCampaignTremendous}
					alt="campaign"
				/>
			</StyledWrapperTitle>
			<Form
				initialValues={{}}
				form={form}
				onValuesChange={onValuesChange}
				onFinish={handleFinishForm}>
				<StyledFormItem
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: t('validate.required'),
						},
					]}
					className="hidden_start_required"
					labelHeight={'18px'}
					name={KEY_POPUP_TREMENDOUS.CAMPAIGN}
					label={t('tremendous.choose_campaign_template')}>
					<StyledSelect
						mode={undefined}
						options={campaigns.map((item) => ({
							label: t('tremendous.template_label', { name: item.name, id: item.id }),
							value: item.id,
						}))}
						showSearch
						virtual={false}
						allowClear={false}
						optionFilterProp={'label'}
						notFoundContent={notFoundContent(t)}
						getPopupContainer={(triggerNode: HTMLElement) =>
							triggerNode.parentNode as HTMLElement
						}
						showArrow
						className="select_group_tags select_detail_terms"
						dropdownClassName="dropdown-menu dropdown-custom-content"
					/>
				</StyledFormItem>
			</Form>
			<StyledLinkHref
				margin="8px 0 0 0"
				hoverColor={THEME.colors.orangeBase}
				fontWeight="normal"
				underline
				href={tremendousCampaignsURL}
				color={THEME.colors.gray3}
				target="_blank"
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					e.stopPropagation();
				}}>
				{t('tremendous.view_templates')}
			</StyledLinkHref>
		</>
	);
});

export default StepTremendous2;
