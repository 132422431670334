import { CAMPAIGN_DETAIL_FIELD_NAME } from '@constants/campaign';
import { INPUT_TYPE } from '@constants/common';
import { MAX_LENGTH_CHARACTER } from '@constants/content/contentLibrary';
import THEME from '@constants/themes/themes';
import { StyledCollapse, StyledFormItemCampaign } from '@styled/Campaign/CampaignDetailStyled';
import { StyledInput, StyledText } from '@styled/Common/CommonStyled';
import { validateInputNumber } from '@utils/common';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

const AdvancedInformation = () => {
	const { t } = useTranslation();

	const fieldList = [
		{
			label: t('campaign.modal.project_name'),
			fieldName: CAMPAIGN_DETAIL_FIELD_NAME.PROJECT_NAME,
		},
		{
			label: t('campaign.modal.job_number'),
			fieldName: CAMPAIGN_DETAIL_FIELD_NAME.JOB_NUMBER,
			inputType: INPUT_TYPE.NUMBER,
		},
	];

	const renderHeader = (text: string) => {
		return (
			<StyledText fontSize="16px" color={THEME.colors.gray5}>
				{text}
			</StyledText>
		);
	};

	return (
		<StyledCollapse bordered={false} expandIconPosition="right">
			<Collapse.Panel header={renderHeader(t('content_detail.other_details'))} key="0">
				{fieldList.map((item) => {
					const isNumberInput = item?.inputType === INPUT_TYPE.NUMBER;
					return (
						<StyledFormItemCampaign label={item.label} name={item.fieldName}>
							{isNumberInput ? (
								<StyledInput
									onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
										validateInputNumber(event, true)
									}
								/>
							) : (
								<StyledInput maxLength={MAX_LENGTH_CHARACTER} />
							)}
						</StyledFormItemCampaign>
					);
				})}
			</Collapse.Panel>
		</StyledCollapse>
	);
};

export default AdvancedInformation;
