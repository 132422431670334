import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Descriptions, message } from 'antd';
import styled from 'styled-components';
// Components

// Models
import { ValueDto, TableValuesDto } from '@models/settings/content';
import { SettingRootStateDto } from '@models/settings/setting';

// Constant
import { CONTENT_VALUE, EDIT } from '@constants/settings';

// Styled
import {
	StyledRowWrapper,
	StyledColWrapper,
	StyledRate,
	StyledContentValueInput,
} from '@styled/Settings/ContentStyled';
import { setContentValues, updateContentValues } from '@stores/actions';

const StyledDescriptions = styled(Descriptions)`
	display: flex;
	flex-wrap: no-wrap;
	overflow-x: auto;
`;

type ContentValueTableProps = {
	data: ValueDto[];
	type: string;
	handleSetActionType: (type: string) => void;
};

const ratingValue = [1, 2, 3, 4, 5];

const ContentValueTable = (props: ContentValueTableProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { contentValues = { verified: [], selects: [] } } = useSelector(
		(state: SettingRootStateDto) => state.settings.content,
	);
	const [tableValues, setTableValues] = useState<TableValuesDto>({ photos: [], videos: [] });
	const [previousValue, setPreviousValue] = useState<number>(0);
	const [actionType, setActionType] = useState<string>('');

	const { data = [], type, handleSetActionType } = props;

	useEffect(() => {
		setInitValue();
	}, [data]);

	const setInitValue = () => {
		const newTableValues = { ...tableValues };
		newTableValues.photos = formatData(CONTENT_VALUE.IMAGE);
		newTableValues.videos = formatData(CONTENT_VALUE.VIDEO);
		setTableValues(newTableValues);
	};

	const formatData = (type: string) => {
		const result = data
			.filter((item: ValueDto) => item.mediaType === type.toLowerCase())
			.sort((a: ValueDto, b: ValueDto) => a.rating - b.rating);
		return result;
	};

	const onChange = (e: any, id: number, type: string) => {
		const {
			target: { value: newValue },
		} = e;
		contentValues[type] = contentValues[type].map((item: ValueDto) =>
			item.id === id ? { ...item, value: parseInt(newValue || 0, 10) } : item,
		);

		dispatch(setContentValues(contentValues));
	};

	const onBlur = (e: any, id: number) => {
		const {
			target: { value },
		} = e;

		const formatValue = (value: number) => {
			if (value >= 5000) {
				message.warning(t('setting.message.content_value_range'));
				return 5000;
			}
			if (value < 0) {
				message.warning(t('setting.message.content_value_range'));
				return 0;
			}
			return value;
		};

		const payload = {
			id: id,
			value: formatValue(parseInt(value, 10)),
		};

		// if (value <= 5000 && value >= 0) {
		if (previousValue !== value) {
			dispatch(updateContentValues(payload));
			handleSetActionType(EDIT);
		}
	};

	const onFocus = (e: any) => {
		setPreviousValue(e.target.value);
	};

	return (
		<StyledDescriptions bordered column={2}>
			<Descriptions.Item label={t('setting.content.ratings')} span={22}>
				<StyledRowWrapper>
					{ratingValue.map((rate: number) => (
						<StyledColWrapper>
							<StyledRate disabled defaultValue={rate} />
						</StyledColWrapper>
					))}
				</StyledRowWrapper>
			</Descriptions.Item>
			<Descriptions.Item label={t('setting.content.photos')} span={22}>
				<StyledRowWrapper>
					{tableValues.photos.map((photo: ValueDto) => (
						<StyledColWrapper>
							<StyledContentValueInput
								type="number"
								prefix="$"
								value={photo.value}
								min="0"
								max="5000"
								onChange={(e) => onChange(e, photo.id, type.toLowerCase())}
								onBlur={(e) => onBlur(e, photo.id)}
								onFocus={onFocus}
							/>
						</StyledColWrapper>
					))}
				</StyledRowWrapper>
			</Descriptions.Item>
			<Descriptions.Item label={t('setting.content.videos')} span={22}>
				<StyledRowWrapper>
					{tableValues.videos.map((video: ValueDto) => (
						<StyledColWrapper>
							<StyledContentValueInput
								type="number"
								prefix="$"
								value={video.value}
								onChange={(e) => onChange(e, video.id, type.toLowerCase())}
								onBlur={(e) => onBlur(e, video.id)}
								onFocus={onFocus}
							/>
						</StyledColWrapper>
					))}
				</StyledRowWrapper>
			</Descriptions.Item>
		</StyledDescriptions>
	);
};

export default ContentValueTable;
