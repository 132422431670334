import { IconAltAdd, IconCancel, IconDelete, IconNote } from '@assets/icons';
import { TYPE_IMAGE, TYPE_UPLOADS } from '@constants/common';
import {
	GROUP_HIGH_LIGHT,
	KEY_HIGH_LIGHT_GROUP,
	SIZE_IMAGE_RESOLUTION,
	STATUS_UPLOAD_FILE_WIDGET,
} from '@constants/content/widgetManager';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import {
	uploadFileWidgetBuilderEnd,
	uploadFileWidgetBuilderRequest,
	uploadFontBuilderRequest,
} from '@stores/actions';
import {
	StyledEmptyUpload,
	StyledFilePreview,
	StyledNameUpload,
	StyledUpload,
} from '@styled/Content/WidgetManager';
import { StyledLink } from '@styled/Content/WidgetManager/DetailWidgetStyled';
import {
	StyledLabelItem,
	StyledMessageWarning,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { validateSizeFileImage } from '@utils/common';
import { Upload, message } from 'antd';
import { Ref, forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RefConfigWidgetBuilderStep } from '@models/content/widgetManager/ref';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { getFileNameFromUrl } from '@utils/funcHelper';
import CustomColorItem from './CustomColorItem';
import CustomFonts from './CustomFonts';

const StyledStep = styled.div``;

const StyledSelectBackground = styled.div`
	padding: 16px 24px;
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
`;

const StyledIcon = styled.span`
	margin-right: 4px;
	display: inline-flex;
`;

const StyledCustomizeButton = styled.div`
	padding: 16px 24px;
`;

type CustomizeColorTypes = {
	title: string;
	color: string;
	keyPopup: string;
	placement?: string;
	disabled?: boolean;
	hidden?: boolean;
};

type PropTypes = {
	handleActionStep: () => void;
};

const SettingStep2 = forwardRef((props: PropTypes, ref: Ref<RefConfigWidgetBuilderStep>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { statusUploadBgFile, urlFile, statusButtonFile, error } = useSelector(
		(state: any) => state?.widgetManager,
	);
	const { detailWidget, setDetailWidget, setIsLoading } = useContext(WidgetBuilderContext);

	const { metadata } = detailWidget;
	const {
		bgUrl = '',
		nameImageBg = '',
		bgSubmit,
		bgFileDropZone,
		highlight,
		bgColor,
		colorUploadIcon = THEME?.colors.orangeBase,
		formBackground = THEME?.colors.white,
		buttonUrl = '',
		fileFontUploads,
	} = metadata?.colors || {};

	const { handleActionStep } = props;

	const [nameFile, setNameFile] = useState<string>('');
	const [imageResolutionInvalid, setImageResolutionInvalid] = useState<boolean>(true);
	const [showMore, setShowMore] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		validateAllFieldCustom: validateCustomField,
	}));

	const validateCustomField = () => {
		const formData = new FormData();
		fileFontUploads.forEach((file: any) => {
			formData.append('files', file);
		});
		setIsLoading(true);
		dispatch(uploadFontBuilderRequest({ data: formData, widgetId: detailWidget.id }));
	};

	const listCustomizeButton: CustomizeColorTypes[] = [
		{
			title: t('content.widget_manager.widget_builder.submit_button'),
			color: bgSubmit,
			keyPopup: 'bgSubmit',
			placement: 'topRight',
			disabled: !!buttonUrl,
		},

		{
			title: t('content.widget_manager.widget_builder.highlights'),
			color: highlight,
			keyPopup: KEY_HIGH_LIGHT_GROUP,
			placement: 'topRight',
			hidden: showMore,
		},
	];

	const moreCustom: CustomizeColorTypes[] = [
		{
			title: t('content.widget_manager.widget_builder.hyperlinks'),
			color: highlight,
			keyPopup: GROUP_HIGH_LIGHT.highlight,
			placement: 'topRight',
		},
		{
			title: t('content.widget_manager.widget_builder.form_background'),
			color: formBackground,
			keyPopup: GROUP_HIGH_LIGHT.formBackground,
			placement: 'topRight',
		},
		{
			title: t('content.widget_manager.widget_builder.file_dropzone'),
			color: bgFileDropZone,
			keyPopup: 'bgFileDropZone',
			placement: 'topRight',
		},
		{
			title: t('content.widget_manager.widget_builder.upload_icon'),
			color: colorUploadIcon,
			keyPopup: GROUP_HIGH_LIGHT.iconUpload,
			placement: 'topRight',
		},
	];

	const listCustomRender = () => {
		let result = [...listCustomizeButton];
		if (showMore) {
			result = [...result, ...moreCustom];
		}
		return result;
	};

	const handleChangeUploadFile = async ({ fileList, file }: any) => {
		const formData = new FormData();

		formData.append('widgetId', detailWidget?.id);
		formData.append('file', file);

		const resultValidate = await validateSizeFileImage(file, {
			textSize: t('content_detail.type_mb'),
		});
		if (resultValidate?.valid) {
			setNameFile(file?.name);
			dispatch(
				uploadFileWidgetBuilderRequest({
					formData,
					keyStatus: STATUS_UPLOAD_FILE_WIDGET.bgFile,
				}),
			);
		} else {
			message.error(t(resultValidate.err, resultValidate?.params));
		}
	};

	const handleChangeUploadButton = async ({ fileList, file }: any) => {
		const formData = new FormData();

		formData.append('widgetId', detailWidget?.id);
		formData.append('file', file);

		const resultValidate = await validateSizeFileImage(file, {
			textSize: t('content_detail.type_mb'),
		});

		if (resultValidate?.valid) {
			dispatch(
				uploadFileWidgetBuilderRequest({
					formData,
					keyStatus: STATUS_UPLOAD_FILE_WIDGET.buttonFile,
				}),
			);
		} else {
			message.error(t(resultValidate.err, resultValidate?.params));
		}
	};

	useEffect(() => {
		const listStatus = [statusButtonFile, statusUploadBgFile];
		if (listStatus.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus.includes(FAILED)) {
			message.error(error);
		}
		if (listStatus.includes(SUCCEEDED) || listStatus.includes(FAILED)) {
			dispatch(uploadFileWidgetBuilderEnd());
			setNameFile('');
			setIsLoading(false);
		}
	}, [statusButtonFile, statusUploadBgFile]);

	useEffect(() => {
		if (statusUploadBgFile === SUCCEEDED && urlFile) {
			setDetailWidget((prev: WidgetBuilderDetailDto) => ({
				...prev,
				metadata: {
					...prev.metadata,
					colors: {
						...prev.metadata.colors,
						bgUrl: urlFile,
						nameImageBg: nameFile || 'bg.png',
					},
				},
			}));
		}
	}, [statusUploadBgFile]);

	useEffect(() => {
		if (statusButtonFile === SUCCEEDED && urlFile) {
			setDetailWidget((prev: WidgetBuilderDetailDto) => ({
				...prev,
				metadata: {
					...prev.metadata,
					colors: {
						...prev.metadata.colors,
						buttonUrl: urlFile,
					},
				},
			}));
		}
	}, [statusButtonFile]);

	useEffect(() => {
		if (bgUrl) {
			const contentImage: HTMLImageElement = new window.Image();
			contentImage.src = bgUrl;
			contentImage.onload = () => {
				const width: number = contentImage.width;
				const height: number = contentImage.height;
				if (width < SIZE_IMAGE_RESOLUTION.width || height < SIZE_IMAGE_RESOLUTION.height) {
					setImageResolutionInvalid(false);
				}
			};
		} else {
			setImageResolutionInvalid(true);
		}
	}, [bgUrl]);

	const handleRemoveUploadedFile = (other: any) => {
		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				colors: { ...prev.metadata.colors, ...other },
			},
		}));
	};

	const propsImage = (otherType?: string[]) => {
		return {
			beforeUpload: (file: any) => {
				const arrType = otherType ? otherType : [];
				let checkTypeImg =
					file.type === 'image/png' ||
					file.type === 'image/jpeg' ||
					file.type === 'image/jpg';

				arrType.forEach((type) => {
					checkTypeImg = checkTypeImg || file.type === type;
				});
				if (!checkTypeImg) {
					message.error(
						t('content.widget_manager.message.upload_failed', { fileName: file.name }),
					);
				}
				return checkTypeImg ? true : Upload.LIST_IGNORE;
			},
			showUploadList: {
				removeIcon: <IconDelete />,
			},
		};
	};

	const renderUpload = ({
		otherProp,
		onUpload,
		url,
		title,
		nameFile,
		onRemove,
		accept,
		height,
	}: {
		otherProp: any;
		onUpload: (val: any) => void;
		url?: string;
		title: string;
		nameFile: string;
		onRemove: () => void;
		accept: string;
		height?: string;
	}) => {
		return (
			<StyledUpload
				{...otherProp}
				showUploadList={false}
				maxCount={1}
				accept={accept}
				height={height}
				beforeUpload={() => false}
				onChange={onUpload}>
				{!url ? (
					<StyledEmptyUpload>
						<StyledIcon>
							<IconAltAdd />
						</StyledIcon>
						{title}
					</StyledEmptyUpload>
				) : (
					<StyledFilePreview>
						<StyledIcon>
							<IconNote />
						</StyledIcon>
						<StyledNameUpload line={2}>{nameFile}</StyledNameUpload>
						<IconCancel
							style={{ width: '16px', height: '16px', marginLeft: '6px' }}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onRemove();
							}}
						/>
					</StyledFilePreview>
				)}
			</StyledUpload>
		);
	};

	return (
		<StyledStep>
			<StyledSelectBackground>
				<StyledLabelItem>
					{t('content.widget_manager.widget_builder.select_background')}
				</StyledLabelItem>
				{!imageResolutionInvalid && (
					<StyledMessageWarning>
						{t('content.widget_manager.message.warning_size_image', {
							height: SIZE_IMAGE_RESOLUTION.height,
							width: SIZE_IMAGE_RESOLUTION.width,
						})}
					</StyledMessageWarning>
				)}
				{renderUpload({
					nameFile: nameImageBg,
					onRemove: () => handleRemoveUploadedFile({ bgUrl: '', nameImageBg: '' }),
					onUpload: handleChangeUploadFile,
					otherProp: propsImage(),
					title: t('content.widget_manager.widget_builder.upload_background_image'),
					url: bgUrl,
					accept: TYPE_IMAGE,
				})}

				<CustomColorItem
					color={bgColor}
					title={t('content.widget_manager.widget_builder.solid_bg_color')}
					marginTop="16px"
					keyPopup={'bgColor'}
					disabled={bgUrl ? true : false}
				/>
			</StyledSelectBackground>
			<StyledCustomizeButton>
				<StyledLabelItem>
					{t('content.widget_manager.widget_builder.label_group_custom_color')}
				</StyledLabelItem>
				{listCustomRender()?.map((item: any, idx) => {
					if (item?.hidden) {
						return null;
					}
					return (
						<CustomColorItem
							key={item?.title}
							marginTop={`${idx === 0 ? '8px' : '16px'}`}
							{...item}
						/>
					);
				})}

				{showMore && (
					<>
						<CustomFonts handleActionStep={handleActionStep} />
						<StyledWrapperContent padding="16px 0px">
							<StyledLabelItem>
								{t('content.widget_manager.widget_builder.add_custom_button')}
							</StyledLabelItem>
							{renderUpload({
								height: '60px',
								nameFile: getFileNameFromUrl(buttonUrl),
								onRemove: () => handleRemoveUploadedFile({ buttonUrl: '' }),
								onUpload: handleChangeUploadButton,
								otherProp: propsImage(['image/svg+xml']),
								title: t(
									'content.widget_manager.widget_builder.upload_background_button',
								),
								accept: TYPE_UPLOADS.IMG_AND_SVG,
								url: buttonUrl,
							})}
						</StyledWrapperContent>
					</>
				)}
				<div style={{ marginTop: '8px' }}>
					<StyledLink onClick={() => setShowMore(!showMore)}>
						{t(
							`content.widget_manager.widget_builder.${
								showMore ? 'hide_options' : 'more_options'
							}`,
						)}
					</StyledLink>
				</div>
			</StyledCustomizeButton>
		</StyledStep>
	);
});

export default SettingStep2;
