import { Action, handleActions } from 'redux-actions';
import * as actions from './creator.actions';
import { UserStoreType } from './creator.types';
import { DEFAULT_ERROR } from '@constants/errors';
import { STATUSCODE } from '@constants/APIs';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { SummaryDto } from '@models/creator/summary';
import { ResponsePayloadDto } from '@models/common/store';
import { DEFAULT_HISTORY } from '@constants/creator';

const initialState: UserStoreType = {
	fetchingProfiles: false,
	isResetSelectedRows: false,
	fetchingCreatorProfile: false,
	fetchingSegment: false,
	fetchingImport: false,
	segmentData: [],
	profilesData: [],
	creatorProfile: null,
	importData: null,
	totalRecords: 0,
	totalSegments: null,
	error: null,
	uploadAWS: false,
	isBlocking: false,
	statusExport: false,
	listCampaign: [],
	fetchingCampaignSimple: false,
	loadingLocationCreator: false,
	creatorLocation: [],
	fetchingUser: false,
	updateCreatorProfileStatus: NONE,
	updateRecentlyAddStatus: NONE,
	user: null,
	creatorIdsUnviewedContentStatusRecentlyAdded: [],
	updateBasicInformationCreator: false,
	updateCreatorStatusState: NONE,
	createSegmentStatus: NONE,
	updateSegmentStatus: NONE,
	completedActionsStatus: NONE,
	getCompletedActionsStatus: NONE,
	creatorBlockStatus: NONE,
	invalidateSocialUsernameStatus: NONE,
	socialStats: null,
	fetchingSocialStats: false,
	refreshSocialStatsStatus: NONE,
	refreshSocialStatsResult: null,
	segmentList: [],
	downloadTemplateCreatorStatus: NONE,
	base64TemplateCreator: '',
	loadingMessageHistoryCreator: false,
	historyCreator: DEFAULT_HISTORY,
	loadingCreatorListLite: false,
	creatorListLite: {
		records: [],
		totalPages: 1,
		totalRecords: 0,
	},
};

const resetSelectedRows = (state: UserStoreType, payload: any) => {
	return {
		...state,
		isResetSelectedRows: payload,
	};
};

const findProfiles = (state: UserStoreType) => ({
	...state,
	fetchingProfiles: true,
});

const findProfilesCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload;
	const { records = [], totalRecords = 0 } = result || {};

	if (code === 200) {
		return {
			...state,
			fetchingProfiles: false,
			profilesData: records || [],
			totalRecords,
			error: null,
		};
	}

	return {
		...state,
		fetchingProfiles: false,
		profilesData: [],
		totalRecords,
		error: status,
	};
};

const findProfilesFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	fetchingProfiles: false,
	error: payload,
});

const getProfile = (state: UserStoreType) => ({
	...state,
	fetchingCreatorProfile: true,
});

const getProfileCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload;

	if (code === 200) {
		return {
			...state,
			fetchingCreatorProfile: false,
			creatorProfile: result,
			error: null,
		};
	}

	return {
		...state,
		fetchingCreatorProfile: false,
		creatorProfile: null,
		error: status,
	};
};

const getProfileFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	fetchingCreatorProfile: false,
	error: payload,
});

// fetch creator list lite

const getCreatorListLiteRequest = (state: UserStoreType) => ({
	...state,
	loadingCreatorListLite: true,
});

const getCreatorListLiteCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload;

	if (code === 200) {
		return {
			...state,
			loadingCreatorListLite: false,
			creatorListLite: result,
			error: null,
		};
	}

	return {
		...state,
		loadingCreatorListLite: false,
		error: status,
	};
};

const getCreatorListLiteFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	loadingCreatorListLite: false,
	error: payload,
});

const creatorExportProfiles = (state: UserStoreType) => ({
	...state,
	fetchingCreatorProfile: true,
	statusExport: true,
	isResetSelectedRows: false,
});

const creatorExportProfilesCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: { uploadAWS = false } = {},
	} = payload;

	if (code === 200) {
		return {
			...state,
			fetchingCreatorProfile: false,
			isResetSelectedRows: true,
			statusExport: false,
			error: null,
			uploadAWS,
		};
	}

	return {
		...state,
		isResetSelectedRows: false,
		fetchingCreatorProfile: false,
		statusExport: false,
		error: status,
		uploadAWS: false,
	};
};

const creatorExportProfilesFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	isResetSelectedRows: false,
	fetchingCreatorProfile: false,
	statusExport: false,
	error: payload,
});

const creatorChangeStatus = (state: UserStoreType, { payload }: any) => {
	const { noWaitFollow, creatorStatusValue } = payload;
	const creatorProfile = state?.creatorProfile || {};
	const status = state?.creatorProfile?.status || {};

	if (noWaitFollow) {
		return {
			...state,
			fetchingProfiles: true,
			isResetSelectedRows: false,
			creatorProfile: {
				...creatorProfile,
				status: {
					...status,
					followed: creatorStatusValue,
				},
			},
			updateCreatorStatusState: SUCCEEDED,
		};
	}
	return {
		...state,
		fetchingProfiles: true,
		isResetSelectedRows: false,
		updateCreatorStatusState: SUCCEEDED,
	};
};

const creatorChangeStatusCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = {},
		noWaitFollow,
	} = payload;
	const creatorProfile = state?.creatorProfile || {};

	if (code === 200) {
		if (noWaitFollow && typeof result === 'object') {
			return {
				...state,
				fetchingProfiles: false,
				isResetSelectedRows: true,
				creatorProfile: {
					...creatorProfile,
					status: {
						...result,
					},
				},
				error: null,
			};
		} else {
			return {
				...state,
				fetchingProfiles: false,
				isResetSelectedRows: true,
				error: null,
			};
		}
	}

	return {
		...state,
		fetchingProfiles: false,
		isResetSelectedRows: false,
		error: status,
	};
};

const creatorChangeStatusFailed = (state: UserStoreType) => ({
	...state,
	fetchingProfiles: false,
	isResetSelectedRows: false,
	updateCreatorStatusState: FAILED,
});

const creatorBlock = (state: UserStoreType) => ({
	...state,
	fetchingProfiles: true,
	creatorBlockStatus: IN_PROGRESS,
	isBlocking: true,
});

const creatorBlockCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = {},
	} = payload;
	const creatorProfile = state?.creatorProfile || {};

	if (code === 200) {
		return {
			...state,
			fetchingProfiles: false,
			isBlocking: false,
			creatorBlockStatus: SUCCEEDED,
			creatorProfile: {
				...creatorProfile,
				status: {
					...result,
				},
			},
			error: null,
		};
	}

	return {
		...state,
		fetchingProfiles: false,
		isBlocking: false,
		creatorBlockStatus: FAILED,
		error: status,
	};
};

const creatorBlockFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	fetchingProfiles: false,
	isBlocking: false,
	creatorBlockStatus: FAILED,
	error: payload,
});

const creatorBlockEnd = (state: UserStoreType) => ({
	...state,
	fetchingProfiles: false,
	isBlocking: false,
	creatorBlockStatus: NONE,
});

const creatorDelete = (state: UserStoreType) => ({
	...state,
	fetchingProfiles: true,
	isResetSelectedRows: false,
});

const creatorDeleteCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload;
	// const { creatorProfile } = state;

	if (code === 200) {
		return {
			...state,
			fetchingProfiles: false,
			isResetSelectedRows: true,
			error: null,
		};
	}

	return {
		...state,
		fetchingProfiles: false,
		isResetSelectedRows: false,
		error: status,
	};
};

const creatorDeleteFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	fetchingProfiles: false,
	isResetSelectedRows: false,
	error: payload,
});

const creatorImport = (state: UserStoreType) => ({
	...state,
	fetchingImport: true,
});

const creatorImportCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: { base64 = '', totalSuccess = 0, totalFail = 0 } = {},
	} = payload;
	// const { creatorProfile } = state;

	if (code === 200) {
		return {
			...state,
			fetchingImport: false,
			importData: {
				base64,
				totalSuccess,
				totalFail,
			},
			error: null,
		};
	}

	return {
		...state,
		fetchingImport: false,
		importData: null,
		error: status,
	};
};

const creatorImportFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	fetchingImport: false,
	importData: null,
	error: payload,
});

const creatorGetSegment = (state: UserStoreType) => ({
	...state,
	fetchingSegment: true,
});

const creatorGetSegmentCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: { segments = [], total: totalSegments },
	} = payload;
	// const { creatorProfile } = state;

	if (code === 200) {
		return {
			...state,
			fetchingSegment: false,
			segmentData: segments,
			totalSegments,
			error: null,
		};
	}

	return {
		...state,
		fetchingSegment: false,
		error: status,
	};
};

const creatorGetSegmentFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	fetchingSegment: false,
	error: payload,
});

const getListCampaignSimpleRequest = (state: UserStoreType) => ({
	...state,
	fetchingCampaignSimple: true,
});

const getListCampaignSimpleCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = [],
	} = payload;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			listCampaign: result,
			fetchingCampaignSimple: false,
			error: null,
		};
	}

	return {
		...state,
		listCampaign: [],
		fetchingCampaignSimple: false,
		error: status,
	};
};

const getListCampaignSimpleFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	error: payload,
	fetchingCampaignSimple: false,
});

const creatorResetUploadAWS = (state: UserStoreType, action: any) => {
	return { ...state, ...action.payload };
};

const getSegment = (state: any) => ({
	...state,
	getSegmentStatus: IN_PROGRESS,
});

const getSegmentCompleted = (state: any, { payload }: Action<any>) => {
	const {
		status: { code = NONE },
		result: segmentList = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			segmentList,
			getSegmentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		getSegmentStatus: FAILED,
		segmentList: [],
	};
};

const getSegmentFailed = (state: any, { payload }: Action<any>) => {
	const result = {
		...state,
		getSegmentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

// fetch location creator
const fetchCreatorLocationRequest = (state: any) => ({
	...state,
	loadingLocationCreator: true,
});

const fetchCreatorLocationSucceeded = (state: any, { payload }: Action<any>) => {
	const {
		status: { code = NONE },
		result: creatorLocation = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			creatorLocation,
			loadingLocationCreator: false,
		};
	}

	return {
		...state,
		loadingLocationCreator: false,
		creatorLocation: [],
	};
};

const fetchCreatorLocationFailed = (state: any, { payload }: Action<any>) => {
	const result = {
		...state,
		loadingLocationCreator: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const createSegment = (state: UserStoreType) => ({
	...state,
	createSegmentStatus: IN_PROGRESS,
});

const createSegmentCompleted = (state: UserStoreType, { payload }: Action<any>) => {
	const {
		status: { code = NONE },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			createSegmentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		createSegmentStatus: FAILED,
	};
};

const createSegmentFailed = (state: UserStoreType, { payload }: Action<any>) => {
	const result = {
		...state,
		createSegmentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const createSegmentEnd = (state: UserStoreType) => {
	const result = {
		...state,
		createSegmentStatus: NONE,
		error: null,
	};

	return result;
};

const updateSegment = (state: UserStoreType) => ({
	...state,
	updateSegmentStatus: IN_PROGRESS,
});

const updateSegmentCompleted = (state: UserStoreType, { payload }: Action<any>) => {
	const {
		status: { code = NONE },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateSegmentStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		updateSegmentStatus: FAILED,
	};
};

const updateSegmentFailed = (state: UserStoreType, { payload }: Action<any>) => {
	const result = {
		...state,
		updateSegmentStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const updateSegmentEnd = (state: UserStoreType) => {
	const result = {
		...state,
		updateSegmentStatus: NONE,
	};

	return result;
};

const updateStatusRecentlyAddedRequest = (state: UserStoreType) => {
	const profilesData: SummaryDto[] = state?.profilesData?.map((item: SummaryDto) => {
		const status = item?.status ? { ...item.status, new: false } : undefined;
		return {
			...item,
			status,
		};
	});

	return {
		...state,
		creatorIdsUnviewedContentStatusRecentlyAdded: [],
		updateRecentlyAddStatus: IN_PROGRESS,
		profilesData,
	};
};

const updateStatusRecentlyAddedCompleted = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload?.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			error: null,
			updateRecentlyAddStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		updateRecentlyAddStatus: FAILED,
		error: status,
	};
};

const updateStatusRecentlyAddedFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	error: payload,
	updateRecentlyAddStatus: FAILED,
});

const updateStatusRecentlyAddedEnd = (state: UserStoreType) => ({
	...state,
	updateRecentlyAddStatus: NONE,
});

const getCreatorUnviewedStatusRecentlyAddedRequest = (state: UserStoreType) => ({
	...state,
	getCreatorUnviewedStatusRecentlyAddedStatus: IN_PROGRESS,
});

const getCreatorUnviewedStatusRecentlyAddedSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: creatorIdsUnviewedContentStatusRecentlyAdded = [],
	} = payload.data;
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			creatorIdsUnviewedContentStatusRecentlyAdded,
			getCreatorUnviewedStatusRecentlyAddedStatus: false,
		};
	}

	return {
		...state,
		creatorIdsUnviewedContentStatusRecentlyAdded: [],
		getCreatorUnviewedStatusRecentlyAddedStatus: false,
	};
};

const getCreatorUnviewedStatusRecentlyAddedFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	getCreatorUnviewedStatusRecentlyAddedStatus: false,
	error: payload,
});

const getCreatorUnviewedStatusRecentlyAddedEnd = (state: UserStoreType) => ({
	...state,
	creatorIdsUnviewedContentStatusRecentlyAdded: [],
	getCreatorUnviewedStatusRecentlyAddedStatus: NONE,
});

const updateCreatorProfileRequest = (state: UserStoreType, { payload }: any) => {
	let creatorProfile = state?.creatorProfile || {};
	if (payload?.skipRes) {
		creatorProfile = { ...creatorProfile, ...payload?.dataRequest };
	}
	return {
		...state,
		creatorProfile,
		updateCreatorProfileStatus: IN_PROGRESS,
	};
};

const updateCreatorProfileSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: creatorProfile = {},
	} = payload;
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			creatorProfile,
			updateCreatorProfileStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		updateCreatorProfileStatus: FAILED,
	};
};

const updateCreatorProfileFailed = (
	state: UserStoreType,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	updateCreatorProfileStatus: FAILED,
	error: payload,
});

const updateCreatorProfileEnd = (state: UserStoreType) => ({
	...state,
	updateCreatorProfileStatus: NONE,
});

const getCompletedActionsRequest = (state: UserStoreType) => {
	return {
		...state,
		getCompletedActionsStatus: IN_PROGRESS,
	};
};

const getCompletedActionsSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0 },
		result: completedActions = {},
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			completedActions,
			getCompletedActionsStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		getCompletedActionsStatus: FAILED,
	};
};

const getCompletedActionsFailed = (
	state: UserStoreType,
	{ payload }: Action<ResponsePayloadDto>,
) => {
	const result = {
		...state,
		getCompletedActionsStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const getCompletedActionsEnd = (state: UserStoreType) => ({
	...state,
	getCompletedActionsStatus: NONE,
});

const invalidateSocialUsernameRequest = (state: UserStoreType) => ({
	...state,
	invalidateSocialUsernameStatus: IN_PROGRESS,
});

const invalidateSocialUsernameSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0 },
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			invalidateSocialUsernameStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		invalidateSocialUsernameStatus: FAILED,
	};
};

const invalidateSocialUsernameFailed = (
	state: UserStoreType,
	{ payload }: Action<ResponsePayloadDto>,
) => {
	const result = {
		...state,
		invalidateSocialUsernameStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const invalidateSocialUsernameEnd = (state: UserStoreType) => ({
	...state,
	invalidateSocialUsernameStatus: NONE,
	error: null,
});

const getSocialStatsRequest = (state: UserStoreType) => {
	return {
		...state,
		fetchingSocialStats: true,
	};
};

const getSocialStatsSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0 },
		result: socialStats = {},
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialStats,
			fetchingSocialStats: false,
		};
	}

	return {
		...state,
		fetchingSocialStats: false,
	};
};

const getSocialStatsFailed = (state: UserStoreType, { payload }: Action<ResponsePayloadDto>) => {
	const result = {
		...state,
		fetchingSocialStats: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const getSocialStatsEnd = (state: UserStoreType) => ({
	...state,
	fetchingSocialStats: false,
});

const refreshSocialStatsRequest = (state: UserStoreType) => {
	return {
		...state,
		refreshSocialStatsStatus: IN_PROGRESS,
	};
};

const refreshSocialStatsSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0 },
		result: refreshSocialStatsResult,
	} = payload.data;
	const { socialStats } = state || {};

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			refreshSocialStatsResult,
			refreshSocialStatsStatus: SUCCEEDED,
			socialStats: {
				...socialStats,
				metricsRefreshAt: refreshSocialStatsResult.ambassadorMetricsRefreshUpdateAt || 0,
				ambassadorRefreshMetricsEnabled:
					refreshSocialStatsResult.ambassadorRefreshMetricsEnabled,
			},
		};
	}

	return {
		...state,
		refreshSocialStatsStatus: FAILED,
	};
};

const refreshSocialStatsFailed = (
	state: UserStoreType,
	{ payload }: Action<ResponsePayloadDto>,
) => {
	const result = {
		...state,
		refreshSocialStatsStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const refreshSocialStatsEnd = (state: UserStoreType) => ({
	...state,
	refreshSocialStatsStatus: NONE,
	refreshSocialStatsResult: null,
});

// download template
const downloadTemplateRequest = (state: UserStoreType) => {
	return {
		...state,
		downloadTemplateCreatorStatus: IN_PROGRESS,
	};
};

const downloadTemplateSucceeded = (state: UserStoreType, { payload }: any) => {
	const {
		status: { code = 0 },
		result: base64TemplateCreator,
	} = payload.data;
	const { socialStats } = state || {};

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			base64TemplateCreator,
			downloadTemplateCreatorStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		base64TemplateCreator: '',
		downloadTemplateCreatorStatus: FAILED,
	};
};

const downloadTemplateFailed = (state: UserStoreType, { payload }: Action<ResponsePayloadDto>) => {
	const result = {
		...state,
		downloadTemplateCreatorStatus: FAILED,
		base64TemplateCreator: '',
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const downloadTemplateEnd = (state: UserStoreType) => ({
	...state,
	downloadTemplateCreatorStatus: NONE,
	base64TemplateCreator: '',
});

// fetch message history creator
const fetchMessageHistoryCreatorRequest = (state: UserStoreType) => ({
	...state,
	loadingMessageHistoryCreator: true,
});

const fetchMessageHistoryCreatorSucceeded = (state: UserStoreType, { payload }: Action<any>) => {
	const {
		status: { code = NONE },
		result: historyCreator,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			historyCreator,
			loadingMessageHistoryCreator: false,
		};
	}

	return {
		...state,
		loadingMessageHistoryCreator: false,
	};
};

const fetchMessageHistoryCreatorFailed = (state: UserStoreType, { payload }: Action<any>) => {
	const result = {
		...state,
		loadingMessageHistoryCreator: false,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const updateApprovalStatusRequest = (state: UserStoreType) => ({
	...state,
	updateApprovalStatus: IN_PROGRESS,
});

const updateApprovalStatusSucceeded = (state: UserStoreType, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateApprovalStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		updateApprovalStatus: FAILED,
		error: status,
	};
};

const updateApprovalStatusFailed = (state: UserStoreType, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			updateApprovalStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		updateApprovalStatus: FAILED,
	};
};

const updateApprovalStatusEnd = (state: UserStoreType) => ({
	...state,
	updateApprovalStatus: NONE,
});

const storeCreatorData = (state: UserStoreType, { payload }: Action<any>) => ({
	...state,
	...payload,
});

export default handleActions<any>(
	{
		[actions.RESET_SELECTED_ROWS]: resetSelectedRows,
		[actions.CREATOR_FIND_PROFILES]: findProfiles,
		[actions.CREATOR_FIND_PROFILES_COMPLETED]: findProfilesCompleted,
		[actions.CREATOR_FIND_PROFILES_FAILED]: findProfilesFailed,
		[actions.CREATOR_EXPORT_PROFILES]: creatorExportProfiles,
		[actions.CREATOR_EXPORT_PROFILES_COMPLETED]: creatorExportProfilesCompleted,
		[actions.CREATOR_EXPORT_PROFILES_FAILED]: creatorExportProfilesFailed,
		[actions.CREATOR_GET_PROFILE]: getProfile,
		[actions.CREATOR_GET_PROFILE_COMPLETED]: getProfileCompleted,
		[actions.CREATOR_GET_PROFILE_FAILED]: getProfileFailed,
		[actions.CREATOR_CHANGE_STATUS]: creatorChangeStatus,
		[actions.CREATOR_CHANGE_STATUS_COMPLETED]: creatorChangeStatusCompleted,
		[actions.CREATOR_CHANGE_STATUS_FAILED]: creatorChangeStatusFailed,
		[actions.CREATOR_BLOCK]: creatorBlock,
		[actions.CREATOR_BLOCK_COMPLETED]: creatorBlockCompleted,
		[actions.CREATOR_BLOCK_FAILED]: creatorBlockFailed,
		[actions.CREATOR_BLOCK_END]: creatorBlockEnd,

		[actions.FETCH_CREATOR_LITE_REQUEST]: getCreatorListLiteRequest,
		[actions.FETCH_CREATOR_LITE_COMPLETED]: getCreatorListLiteCompleted,
		[actions.FETCH_CREATOR_LITE_FAILED]: getCreatorListLiteFailed,

		[actions.CREATOR_DELETE]: creatorDelete,
		[actions.CREATOR_DELETE_COMPLETED]: creatorDeleteCompleted,
		[actions.CREATOR_DELETE_FAILED]: creatorDeleteFailed,
		[actions.CREATOR_IMPORT]: creatorImport,
		[actions.CREATOR_IMPORT_COMPLETED]: creatorImportCompleted,
		[actions.CREATOR_IMPORT_FAILED]: creatorImportFailed,
		[actions.CREATOR_GET_SEGMENT]: creatorGetSegment,
		[actions.CREATOR_GET_SEGMENT_COMPLETED]: creatorGetSegmentCompleted,
		[actions.CREATOR_GET_SEGMENT_FAILED]: creatorGetSegmentFailed,

		[actions.GET_LIST_CAMPAIGN_SIMPLE_REQUEST]: getListCampaignSimpleRequest,
		[actions.GET_LIST_CAMPAIGN_SIMPLE_COMPLETED]: getListCampaignSimpleCompleted,
		[actions.GET_LIST_CAMPAIGN_SIMPLE_FAILED]: getListCampaignSimpleFailed,
		[actions.CREATOR_RESET_UPLOAD_AWS]: creatorResetUploadAWS,

		[actions.GET_SEGMENT]: getSegment,
		[actions.GET_SEGMENT_COMPLETED]: getSegmentCompleted,
		[actions.GET_SEGMENT_FAILED]: getSegmentFailed,

		[actions.GET_LOCATION_CREATOR_REQUEST]: fetchCreatorLocationRequest,
		[actions.GET_LOCATION_CREATOR_SUCCEEDED]: fetchCreatorLocationSucceeded,
		[actions.GET_LOCATION_CREATOR_FAILED]: fetchCreatorLocationFailed,

		[actions.CREATE_SEGMENT]: createSegment,
		[actions.CREATE_SEGMENT_COMPLETED]: createSegmentCompleted,
		[actions.CREATE_SEGMENT_FAILED]: createSegmentFailed,
		[actions.CREATE_SEGMENT_END]: createSegmentEnd,

		[actions.UPDATE_SEGMENT]: updateSegment,
		[actions.UPDATE_SEGMENT_COMPLETED]: updateSegmentCompleted,
		[actions.UPDATE_SEGMENT_FAILED]: updateSegmentFailed,
		[actions.UPDATE_SEGMENT_END]: updateSegmentEnd,

		[actions.UPDATE_STATUS_RECENTLY_ADDED_REQUEST]: updateStatusRecentlyAddedRequest,
		[actions.UPDATE_STATUS_RECENTLY_ADDED_COMPLETED]: updateStatusRecentlyAddedCompleted,
		[actions.UPDATE_STATUS_RECENTLY_ADDED_FAILED]: updateStatusRecentlyAddedFailed,
		[actions.UPDATE_STATUS_RECENTLY_ADDED_END]: updateStatusRecentlyAddedEnd,

		[actions.GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST]:
			getCreatorUnviewedStatusRecentlyAddedRequest,
		[actions.GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_SUCCEEDED]:
			getCreatorUnviewedStatusRecentlyAddedSucceeded,
		[actions.GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_FAILED]:
			getCreatorUnviewedStatusRecentlyAddedFailed,
		[actions.GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_END]:
			getCreatorUnviewedStatusRecentlyAddedEnd,

		[actions.UPDATE_CREATOR_PROFILE_REQUEST]: updateCreatorProfileRequest,
		[actions.UPDATE_CREATOR_PROFILE_SUCCEEDED]: updateCreatorProfileSucceeded,
		[actions.UPDATE_CREATOR_PROFILE_FAILED]: updateCreatorProfileFailed,
		[actions.UPDATE_CREATOR_PROFILE_END]: updateCreatorProfileEnd,

		[actions.GET_COMPLETED_ACTIONS_REQUEST]: getCompletedActionsRequest,
		[actions.GET_COMPLETED_ACTIONS_SUCCEEDED]: getCompletedActionsSucceeded,
		[actions.GET_COMPLETED_ACTIONS_FAILED]: getCompletedActionsFailed,
		[actions.GET_COMPLETED_ACTIONS_END]: getCompletedActionsEnd,

		[actions.INVALIDATE_SOCIAL_USERNAME_REQUEST]: invalidateSocialUsernameRequest,
		[actions.INVALIDATE_SOCIAL_USERNAME_SUCCEEDED]: invalidateSocialUsernameSucceeded,
		[actions.INVALIDATE_SOCIAL_USERNAME_FAILED]: invalidateSocialUsernameFailed,
		[actions.INVALIDATE_SOCIAL_USERNAME_END]: invalidateSocialUsernameEnd,

		[actions.GET_SOCIAL_STATS_REQUEST]: getSocialStatsRequest,
		[actions.GET_SOCIAL_STATS_SUCCEEDED]: getSocialStatsSucceeded,
		[actions.GET_SOCIAL_STATS_FAILED]: getSocialStatsFailed,
		[actions.GET_SOCIAL_STATS_END]: getSocialStatsEnd,

		[actions.REFRESH_SOCIAL_STATS_REQUEST]: refreshSocialStatsRequest,
		[actions.REFRESH_SOCIAL_STATS_SUCCEEDED]: refreshSocialStatsSucceeded,
		[actions.REFRESH_SOCIAL_STATS_FAILED]: refreshSocialStatsFailed,
		[actions.REFRESH_SOCIAL_STATS_END]: refreshSocialStatsEnd,

		[actions.downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_REQUEST]: downloadTemplateRequest,
		[actions.downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_SUCCEEDED]:
			downloadTemplateSucceeded,
		[actions.downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_FAILED]: downloadTemplateFailed,
		[actions.downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_END]: downloadTemplateEnd,

		[actions.FETCH_MESSAGE_HISTORY_CREATOR_REQUEST]: fetchMessageHistoryCreatorRequest,
		[actions.FETCH_MESSAGE_HISTORY_CREATOR_SUCCEEDED]: fetchMessageHistoryCreatorSucceeded,
		[actions.FETCH_MESSAGE_HISTORY_CREATOR_FAILED]: fetchMessageHistoryCreatorFailed,

		[actions.updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_REQUEST]:
			updateApprovalStatusRequest,
		[actions.updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_SUCCEEDED]:
			updateApprovalStatusSucceeded,
		[actions.updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_FAILED]:
			updateApprovalStatusFailed,
		[actions.updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_END]: updateApprovalStatusEnd,

		[actions.STORE_CREATOR_DATA]: storeCreatorData,
	},
	initialState,
);
