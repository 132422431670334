import { IconExpandMore, IconMoreVert } from '@assets/icons';
import { FULL_RECORD, ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { TYPE_ALBUM } from '@constants/content/albums';
import {
	GALLERY_TAG_CLASS,
	TAB_GALLERY,
	TYPE_GET_CONTENT,
	TYPE_THEME,
	ViewGalleryType,
} from '@constants/publish/gallery';
import GalleryManagerContext from '@contexts/Publishing/Galleries';
import { getUser } from '@helpers/userHelpers';
import { AlbumRespDto, ParamRequestAlbumsDto } from '@models/content/albums/albumManager';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { fetchAlbumsRequest, updateDetailFolderGalleryRequest } from '@stores/actions';
import { StyledIcon, StyledRow, StyledSelect } from '@styled/Common/CommonStyled';
import { StyledDirectLink } from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledActionManage,
	StyledActionView,
	StyledButtonMore,
	StyledDropdown,
	StyledMessageAssignAlbumGallery,
	StyledModalPreviewFull,
	StyledPreviewLoadGallery,
	StyledSubTitleEmpty,
	StyledTabs,
	StyledText,
	StyledTitle,
	StyledTitleEmpty,
	StyledWrapperContainerPreview,
	StyledWrapperContentTabActive,
	StyledWrapperEmptyGallery,
	StyledWrapperListTheme,
	StyledWrapperPreviewLoadGallery,
	StyledWrapperTabGallery,
	StyledWrapperViewGallery,
} from '@styled/Publish/Gallery/GalleryManagerStyled';
import { encodeToBase64, runScriptTag } from '@utils/funcHelper';
import { renderThumbVertical } from '@utils/renderComponent';
import { Button, Menu, Tabs } from 'antd';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SortEnd } from 'react-sortable-hoc';

import UpdateListContentModal from '../UpdateListContentModal';
import ContentGallery from './ContentGallery';
import SortableGalleryCustom from './SortableGallery';

const { TabPane } = Tabs;
const { Option, OptGroup } = StyledSelect;

type ActionsTypes = {
	id: number;
	name: string;
	click: React.EventHandler<any>;
	disabled?: boolean;
};

const ViewGallery = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const user = getUser();
	const { clientId } = user;

	const {
		listContent = [],
		gallerySelect,
		setParamContent,
		setVisiblePreview,
		activeTabGallery,
		setActiveTabGallery,
		setTypeConfirm,
		typeGetContent,
		setTypeGetContent,
		setListFolderGallery,
		listFolderGallery,
		handleChangeGallerySelect,
		setListContent,
		albumSelect,
		setAlbumSelect,
		onAssignAlbumToGallery,
	} = useContext(GalleryManagerContext);

	const { loadingContentGallery } = useSelector((state: any) => state.galleryManager);
	const { albums } = useSelector((state: any) => state.albumManager);

	const [visibleChangeContent, setVisibleChangeContent] = useState<boolean>(false);
	const [showAssignAlbum, setShowAssignAlbum] = useState<boolean>(false);
	const [paramAlbum, setParamAlbum] = useState<ParamRequestAlbumsDto>({
		page: 1,
		maxRecords: FULL_RECORD,
	});
	const [smartAlbums, setSmartAlbums] = useState<AlbumRespDto[]>([]);
	const [albumOthers, setAlbumOthers] = useState<AlbumRespDto[]>([]);
	const [typeView, setTypeView] = useState<number>(ViewGalleryType.default);

	const scrollbars = useRef<any>({});
	const refAssignAlbum = useRef<any>({});

	const { theme, visibleBg, galleryBackground } = gallerySelect?.metadata || {};

	const hasAlbumAssign = useMemo(() => {
		return gallerySelect?.albumAssigned === 0 || !!gallerySelect?.albumAssigned;
	}, [gallerySelect?.albumAssigned]);

	useEffect(() => {
		if (!hasAlbumAssign) {
			setAlbumSelect(null);
		} else {
			setAlbumSelect(
				albums?.find(
					(item: AlbumRespDto) => item?.id === +(gallerySelect?.albumAssigned || 0),
				),
			);
		}
	}, [gallerySelect?.albumAssigned]);

	useEffect(() => {
		if (gallerySelect) {
			const otherParam: any = {
				galleryId: gallerySelect?.id,
				isVerified: true,
			};
			if (activeTabGallery === TAB_GALLERY.manage_content) {
				otherParam.isVerified = null;
			}
			setParamContent((prev: any) => ({
				...prev,
				...otherParam,
			}));
		}
	}, [activeTabGallery, gallerySelect?.id]);

	useEffect(() => {
		dispatch(fetchAlbumsRequest({ ...paramAlbum }));
	}, [paramAlbum]);

	useEffect(() => {
		const smarts: AlbumRespDto[] = [];
		const others: AlbumRespDto[] = [];
		albums?.forEach((item: AlbumRespDto) => {
			if (item?.type === TYPE_ALBUM.SMART_ALBUM) {
				smarts.push(item);
			} else {
				others.push(item);
			}
		});

		smarts.sort(compareObject);
		others.sort(compareObject);
		setSmartAlbums(smarts);
		setAlbumOthers(others);
	}, [albums]);

	useEffect(() => {
		if (gallerySelect && activeTabGallery === TAB_GALLERY.preview) {
			runScriptTag();
		}
	}, [gallerySelect, typeView, activeTabGallery]);

	useEffect(() => {
		if (refAssignAlbum?.current && showAssignAlbum) {
			window.addEventListener('click', handleCheckClickOutSide);
			return () => {
				window.removeEventListener('click', handleCheckClickOutSide);
			};
		}
		return () => {};
	}, [refAssignAlbum?.current, showAssignAlbum]);

	const listTabGallery = useMemo(
		() => [
			{ value: TAB_GALLERY.preview, title: t('gallery_manager.preview_gallery') },
			{ value: TAB_GALLERY.manage_content, title: t('gallery_manager.manage_content') },
		],
		[],
	);

	const compareObject = (a: AlbumRespDto, b: AlbumRespDto) => {
		const val1 = a.name?.toLowerCase() || '';
		const val2 = b.name?.toLowerCase() || '';
		if (val1 < val2) {
			return -1;
		}
		if (val1 > val2) {
			return 1;
		}
		return 0;
	};

	const handleCheckClickOutSide = (e: any) => {
		const assignElement = refAssignAlbum?.current;
		const targetElement = e.target;
		if (!targetElement.isConnected) return;
		if (assignElement && assignElement.contains(targetElement)) {
			return;
		}

		if (showAssignAlbum) {
			setShowAssignAlbum(false);
		}
	};

	const handleResetListContent = (countImg: number, countVid: number) => {
		const totalChange = countImg + countVid;

		handleChangeGallerySelect((prev: any) => ({
			...prev,
			totalContent: prev.totalContent + totalChange,
			numberOfImages: prev.numberOfImages + countImg,
			numberOfVideos: prev.numberOfVideos + countVid,
		}));
		const idx = listFolderGallery?.findIndex((item) => item?.id === gallerySelect?.id);
		if (idx !== -1) {
			const listFolderChange = [...listFolderGallery];
			listFolderChange[idx].totalContent += totalChange;
			listFolderChange[idx].numberOfImages += countImg;
			listFolderChange[idx].numberOfVideos += countVid;
			setListFolderGallery([...listFolderChange]);
		}

		setParamContent((prev: any) => ({ ...prev }));
	};

	const renderTabName = (item: any) => {
		const { title, value } = item;
		return (
			<StyledTitle>
				<StyledText
					fontWeight={`${value === activeTabGallery ? 'bold' : 'normal'}`}
					fontSize="1rem">
					{title}
				</StyledText>
			</StyledTitle>
		);
	};

	const handleDeleteGallery = () => {
		setTypeConfirm(TYPE_ACTIONS.REMOVE);
	};

	const onSortEnd = (val: SortEnd) => {
		const { oldIndex, newIndex } = val;
		if (oldIndex === newIndex) {
			return null;
		}

		const itemChange = { ...listContent[oldIndex] };
		let listChange: ContentDto[] = [...listContent];
		listChange = listChange?.filter((item: ContentDto) => item?.id !== itemChange?.id);

		const arrStart = listChange.slice(0, newIndex);
		const arrEnd = listChange.slice(newIndex);
		listChange = [...arrStart, itemChange, ...arrEnd];
		const arrIdOrder = listChange?.map((item) => item?.id);

		dispatch(
			updateDetailFolderGalleryRequest({
				params: {
					...gallerySelect,
					metadata: JSON.stringify(gallerySelect?.metadata),
					reorder: arrIdOrder,
				},
				noUpdateOrder: false,
				isAuto: false,
			}),
		);
		setListContent([...listChange]);
		return null;
	};

	const renderManageContent = () => {
		return (
			<StyledWrapperListTheme hasAlbumAssign={hasAlbumAssign}>
				<Scrollbars
					className="scrollbar_custom_wrapper scroll_bar_manage"
					renderTrackVertical={() => <div />}
					ref={(el) => {
						scrollbars.current = el;
					}}
					onScrollStop={() => {}}
					style={{ height: '100%' }}
					renderThumbVertical={renderThumbVertical}>
					{hasAlbumAssign ? (
						<StyledRow>
							{listContent?.map((item: ContentDto) => (
								<ContentGallery span={6} content={item} key={item?.id} />
							))}
						</StyledRow>
					) : (
						<SortableGalleryCustom list={listContent} onSortEnd={onSortEnd} />
					)}
				</Scrollbars>
			</StyledWrapperListTheme>
		);
	};

	const renderEmpty = (
		<StyledWrapperEmptyGallery>
			<StyledTitleEmpty>{t('gallery_manager.nothing_here_yet')}</StyledTitleEmpty>
			<StyledSubTitleEmpty>{t('gallery_manager.go_to_your_content')}</StyledSubTitleEmpty>
		</StyledWrapperEmptyGallery>
	);

	const onShowSelectContent = () => {
		setTypeGetContent(TYPE_GET_CONTENT.UPDATE);
		setVisibleChangeContent(true);
	};

	const onShowAssignAlbum = () => {
		if (hasAlbumAssign) {
			setTypeConfirm(TYPE_ACTIONS.UN_ASSIGN);
		} else {
			setShowAssignAlbum(true);
		}
	};

	const onRemoveAllContent = () => {
		setTypeConfirm(TYPE_ACTIONS.REMOVE_ALL);
	};
	const actionsObj: ActionsTypes[] = [
		{
			id: 1,
			name: 'gallery_manager.button.delete_gallery',
			click: handleDeleteGallery,
		},
	];

	const actionAddOrRemove: ActionsTypes[] = [
		{
			id: 1,
			name: 'gallery_manager.select_content_library',
			click: onShowSelectContent,
			disabled: hasAlbumAssign,
		},
		{
			id: 2,
			name: hasAlbumAssign
				? 'gallery_manager.un_assign_album'
				: 'gallery_manager.assign_album',
			click: onShowAssignAlbum,
		},
		{
			id: 3,
			name: 'gallery_manager.remove_all_content',
			click: onRemoveAllContent,
			disabled: hasAlbumAssign || listContent?.length === 0,
		},
	];

	const listOptionView: ActionsTypes[] = [
		{
			id: ViewGalleryType.default,
			name: t('gallery_manager.half_preview'),
			click: () => {},
		},
		{
			id: ViewGalleryType.mobile,
			name: t('gallery_manager.mobile_preview'),
			click: () => {},
		},
		{
			id: ViewGalleryType.fullWidth,
			name: t('gallery_manager.full_screen_width'),
			click: () => {},
		},
	];

	const menuActions = (
		<Menu>
			{actionsObj.map((item: ActionsTypes) => (
				<Menu.Item key={item.id} onClick={item.click}>
					{t(item.name)}
				</Menu.Item>
			))}
		</Menu>
	);

	const menuActionPreview = (
		<Menu>
			{listOptionView.map((item: ActionsTypes) => (
				<Menu.Item key={item.id} onClick={() => setTypeView(item?.id)}>
					{t(item.name)}
				</Menu.Item>
			))}
		</Menu>
	);

	const menuActionAddOrRemove = (
		<Menu>
			{actionAddOrRemove.map((item: ActionsTypes) => (
				<Menu.Item key={item.id} onClick={item.click} disabled={!!item?.disabled}>
					{t(item.name)}
				</Menu.Item>
			))}
		</Menu>
	);

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const handleDirectAlbum = () => {
		history.push(`${ROUTE_PATH.ALBUMS}/${albumSelect?.id}`);
	};

	const onSelectAlbumAssign = (id: any) => {
		const itemSelect: AlbumRespDto | null =
			albums?.find((item: AlbumRespDto) => item?.id === id) || null;
		setAlbumSelect(itemSelect);
		if (listContent.length === 0) {
			onAssignAlbumToGallery(itemSelect?.id);
		} else {
			setTypeConfirm(TYPE_ACTIONS.ASSIGN);
		}
	};

	const propsMessage: any = {
		values: { name: albumSelect?.name },
		i18nKey: 'gallery_manager.message.message_assign_album_gallery',
		components: {
			linkTo: <StyledDirectLink onClick={() => handleDirectAlbum()} />,
		},
	};

	const isEmptyVerify = useMemo(() => {
		if (!gallerySelect) {
			return true;
		}
		return !(gallerySelect?.numberOfImages + gallerySelect?.numberOfVideos);
	}, [gallerySelect]);

	const className = useMemo(() => {
		let result = '';
		if (hasAlbumAssign) {
			result = 'has_album ';
		}
		if (isEmptyVerify) {
			result += 'has_empty';
		}
		return result;
	}, [isEmptyVerify, hasAlbumAssign]);

	return (
		<StyledWrapperViewGallery>
			<StyledModalPreviewFull
				width={'100%'}
				footer={null}
				centered
				closable={false}
				visible={typeView === ViewGalleryType.fullWidth}
				onCancel={() => setTypeView(ViewGalleryType.default)}>
				<StyledWrapperPreviewLoadGallery>
					<StyledWrapperContainerPreview height="100%">
						<StyledPreviewLoadGallery
							data-client-id={encodeToBase64(clientId)}
							data-gallery-id={gallerySelect?.id}
							className={GALLERY_TAG_CLASS}
						/>
					</StyledWrapperContainerPreview>
				</StyledWrapperPreviewLoadGallery>
			</StyledModalPreviewFull>
			{gallerySelect && visibleChangeContent && (
				<UpdateListContentModal
					visible={visibleChangeContent}
					typeGetContent={typeGetContent}
					galleryId={gallerySelect?.id}
					handleResetData={handleResetListContent}
					onCancel={() => {
						setTypeGetContent(TYPE_GET_CONTENT.VIEW);
						setVisibleChangeContent(false);
					}}
				/>
			)}

			<StyledActionView>
				{activeTabGallery === TAB_GALLERY.preview && (
					<>
						<StyledDropdown
							className="dropdown_action"
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode as HTMLElement
							}
							placement="bottomLeft"
							overlay={menuActionPreview}
							trigger={['click']}>
							<Button style={{ marginRight: '8px' }}>
								{listOptionView?.find((item) => item?.id === typeView)?.name}
								<StyledIcon marginSvg="0" margin="0 0 0 5px">
									<IconExpandMore />
								</StyledIcon>
							</Button>
						</StyledDropdown>
						<Button
							onClick={() => setVisiblePreview(true)}
							disabled={!gallerySelect}
							type="primary">
							{t('gallery_manager.button.edit_design')}
						</Button>
						<StyledDropdown
							className="dropdown_action"
							overlay={menuActions}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode as HTMLElement
							}
							placement="bottomRight"
							trigger={['click']}>
							<StyledButtonMore>
								<IconMoreVert />
							</StyledButtonMore>
						</StyledDropdown>
					</>
				)}
				{activeTabGallery === TAB_GALLERY.manage_content && (
					<>
						{showAssignAlbum ? (
							<div ref={refAssignAlbum}>
								<StyledSelect
									showSearch
									width="250px"
									showArrow
									notFoundContent={notFoundContent()}
									getPopupContainer={(triggerNode: HTMLElement) =>
										triggerNode.parentNode as HTMLElement
									}
									value={albumSelect?.id || undefined}
									virtual={false}
									onSelect={onSelectAlbumAssign}
									className="select_group"
									placeholder={t('gallery_manager.select_album')}
									dropdownClassName={'dropdown-menu dropdown-custom-content'}
									optionFilterProp={'children'}>
									<OptGroup label={t('gallery_manager.smart_albums')}>
										{smartAlbums?.map((item: AlbumRespDto) => {
											return (
												<Option key={item?.id} value={item?.id}>
													{item?.name}
												</Option>
											);
										})}
									</OptGroup>
									<OptGroup label={t('gallery_manager.other_albums')}>
										{albumOthers?.map((item) => {
											return (
												<Option key={item?.id} value={item?.id}>
													{item?.name}
												</Option>
											);
										})}
									</OptGroup>
								</StyledSelect>
							</div>
						) : (
							<StyledDropdown
								className="dropdown_action"
								overlay={menuActionAddOrRemove}
								getPopupContainer={(triggerNode: HTMLElement) =>
									triggerNode as HTMLElement
								}
								placement="bottomRight"
								trigger={['click']}>
								<StyledActionManage>
									<Button>
										{t('gallery_manager.button.add_or_remove_content')}
									</Button>
								</StyledActionManage>
							</StyledDropdown>
						)}
					</>
				)}
			</StyledActionView>
			<StyledTabs
				activeKey={activeTabGallery}
				className="custom-tabs"
				onTabClick={(tab: string) => setActiveTabGallery(tab)}>
				{listTabGallery.map((item: any) => (
					<TabPane tab={renderTabName(item)} key={item.value} closable={false}>
						{gallerySelect && (
							<StyledWrapperTabGallery className="custom_scroll_bar">
								<StyledWrapperContentTabActive>
									{activeTabGallery === TAB_GALLERY.preview && (
										<>
											{hasAlbumAssign && (
												<StyledMessageAssignAlbumGallery>
													<Trans t={t} {...propsMessage} />
												</StyledMessageAssignAlbumGallery>
											)}
											{isEmptyVerify && renderEmpty}
											{typeView !== ViewGalleryType.fullWidth && (
												<StyledWrapperPreviewLoadGallery
													className={className}>
													<StyledWrapperContainerPreview
														height="100%"
														className={
															typeView === ViewGalleryType.mobile
																? 'mobile_view'
																: ''
														}>
														<StyledPreviewLoadGallery
															data-client-id={encodeToBase64(
																clientId,
															)}
															data-gallery-id={gallerySelect?.id}
															className={GALLERY_TAG_CLASS}
														/>
													</StyledWrapperContainerPreview>
												</StyledWrapperPreviewLoadGallery>
											)}
										</>
									)}
									{activeTabGallery === TAB_GALLERY.manage_content &&
										activeTabGallery === item?.value && (
											<>
												{hasAlbumAssign && (
													<StyledMessageAssignAlbumGallery>
														<Trans t={t} {...propsMessage} />
													</StyledMessageAssignAlbumGallery>
												)}
												{listContent?.length === 0 &&
													!loadingContentGallery &&
													renderEmpty}
												{listContent?.length !== 0 &&
													!loadingContentGallery &&
													renderManageContent()}
											</>
										)}
								</StyledWrapperContentTabActive>
							</StyledWrapperTabGallery>
						)}
					</TabPane>
				))}
			</StyledTabs>
		</StyledWrapperViewGallery>
	);
};

export default ViewGallery;
