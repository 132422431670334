import THEME from '@constants/themes/themes';
import { StyledFormItem, StyledInput } from '@styled/Common/CommonStyled';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TooltipContent } from '..';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import FormItemSelect from '../../FormItemSelect';
import { useSelector } from 'react-redux';
import { StateCampaign } from '@models/campaign';

const StyledContent = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray5};
	display: inline-block;
	margin-bottom: 12px;
	width: 100%;
	min-height: 20px;
`;

const StyledTagItem = styled.div`
	min-width: 50px;
	max-width: 180px;
	padding: 2px 6px;
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue3};
	color: ${THEME.colors.gray5};
	margin-right: 5px;
	margin-bottom: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
`;

type PropTypes = {
	name: string;
	values: string[];
	enableEdit?: boolean;
};
const FormDropdownField = (props: PropTypes) => {
	const { t } = useTranslation();
	const { setField } = useContext(ContentDetailContext);

	const { name, values, enableEdit } = props;
	const [isEdit, setIsEdit] = useState(false);

	return (
		<>
			{isEdit && (
				<FormItemSelect
					mode="tags"
					optionData={values || []}
					name={name}
					style={{ marginBottom: '5px' }}
				/>
			)}
			{!isEdit && (
				<StyledContent
					onClick={() => {
						if (enableEdit) {
							setIsEdit(true);
							setField({ [name]: values });
						}
					}}>
					{values?.map((val) => (
						<TooltipContent key={Math.random()} name={val}>
							<StyledTagItem>{val}</StyledTagItem>
						</TooltipContent>
					))}
				</StyledContent>
			)}
		</>
	);
};

export default FormDropdownField;
