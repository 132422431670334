import {
	CAMPAIGN_LANDING_PAGE,
	GET_CAMPAIGN_DETAIL,
	GET_CAMPAIGN_LIST_SETTING,
	GET_LIST_CONTENT_CAMPAIGN,
	GET_RECOMMENDATION_CAMPAIGN,
	SUGGEST_IMG_CAMPAIGN,
	UPLOAD_IMGS_CAMPAIGN,
} from '@constants/APIs';
import { CONTENT_TYPE_REQUEST } from '@constants/common';
import genericApiSaga from '@helpers/genericApiSaga';
import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
import { CampaignCommunityDto } from '@models/campaign';
import { convertParamSearch } from '@utils/common';
import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './campaign.actions';
import { CampaignTypes } from './campaign.types';

export function* getCampaignListAPI({ payload }: any) {
	const { params } = payload;

	yield genericApiSaga({
		gatewayCall: () => getRequest(`${GET_CAMPAIGN_LIST_SETTING}`, params),
		*completed(response: any) {
			yield put(actions.getCampaignListAPISuccess(response));
		},
		*failed(response: any) {
			yield put(actions.getCampaignListAPIFailed(response));
		},
	});
}

export function* getDetailCampaign({ payload }: Action<{ campaignId: number }>) {
	const { campaignId } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${GET_CAMPAIGN_DETAIL}?campaignId=${campaignId}`),
		*completed(response: any) {
			yield put(actions.getDetailCampaignSuccess(response?.data));
		},
		*failed(response: any) {
			yield put(actions.getDetailCampaignFailed(response));
		},
	});
}

export function* suggestImgCampaign({ payload }: Action<{ ids: number[] }>) {
	const { ids } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(SUGGEST_IMG_CAMPAIGN, ids),
		*completed(response: any) {
			yield put(actions.suggestImgCampaignSuccess(response?.data));
		},
		*failed(response: any) {
			yield put(actions.suggestImgCampaignFailed(response));
		},
	});
}

export function* uploadImgsCampaign({ payload }: Action<{ campaignId: number; files: any }>) {
	const { campaignId, files } = payload;
	let url = UPLOAD_IMGS_CAMPAIGN;
	if (campaignId) {
		url += `?campaignId=${campaignId}`;
	}
	yield genericApiSaga({
		gatewayCall: () => postRequest(url, files, CONTENT_TYPE_REQUEST.FORM_DATA),
		*completed(response: any) {
			yield put(actions.uploadImgsCampaignSuccess(response));
		},
		*failed(response: any) {
			yield put(actions.uploadImgsCampaignFailed(response));
		},
	});
}

export function* createCampaignAPI({ payload }: any) {
	const { dataQuery, formData, contentType, onGoLiveSuccessful, onUpdateCampaignFailed } =
		payload;
	const searchParams = convertParamSearch(dataQuery);

	const urlRequest = `${GET_CAMPAIGN_LIST_SETTING}?${searchParams}`;
	yield genericApiSaga({
		gatewayCall: () => postRequest(urlRequest, formData, contentType),
		*completed(response: any) {
			if (response.data.success) {
				yield put(actions.createCampaignAPISuccess(response.data));
				onGoLiveSuccessful(response.data.result[0].status);
			} else {
				onUpdateCampaignFailed(response.data);
			}
		},
		*failed(response: any) {
			yield put(actions.createCampaignAPIFailed(response.data));
			onUpdateCampaignFailed(response.data);
		},
	});
}

export function* createCampaign({ payload }: any) {
	const { dataRequest } = payload;

	yield genericApiSaga({
		gatewayCall: () => postRequest(GET_CAMPAIGN_LIST_SETTING, dataRequest),
		*completed(response: any) {
			yield put(actions.createCampaignAPISuccess(response));
		},
		*failed(response: any) {
			yield put(actions.createCampaignAPIFailed(response));
		},
	});
}

export function* updateCampaign({ payload }: any) {
	const { dataRequest } = payload;

	yield genericApiSaga({
		gatewayCall: () => putRequest(GET_CAMPAIGN_LIST_SETTING, dataRequest),
		*completed(response: any) {
			if (response.data.success) {
				yield put(actions.updateCampaignSuccess(response));
			}
		},
		*failed(response: any) {
			yield put(actions.updateCampaignFailed(response));
		},
	});
}

export function* fetchListContentCampaignAPI() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(`${GET_LIST_CONTENT_CAMPAIGN}`),
		*completed(response: any) {
			yield put(actions.getListContentCampaignAPISuccess(response.data));
		},
		*failed(response: any) {
			yield put(actions.getListContentCampaignAPIFailed(response));
		},
	});
}

export function* deleteCampaignAPI({ payload }: Action<{ campaignId: number }>) {
	const { campaignId } = payload;
	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${GET_CAMPAIGN_LIST_SETTING}?id=${campaignId}`),

		*completed(response: any) {
			yield put(actions.deleteCampaignAPISuccess(response));
		},
		*failed(response: any) {
			yield put(actions.deleteCampaignAPIFailed(response));
		},
	});
}

export function* getRecommendationCampaign({
	payload,
}: Action<{ campaignIds: number[]; isOpenCampaignTab: boolean }>) {
	const { campaignIds = [], isOpenCampaignTab = false } = payload;

	yield genericApiSaga({
		gatewayCall: () =>
			putRequest(`${GET_RECOMMENDATION_CAMPAIGN}?isOpenCampaignTab=${isOpenCampaignTab}`, [
				...campaignIds,
			]),
		*completed(response: any) {
			yield put(actions.getRecommendationCampaignSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getRecommendationCampaignFailed(response));
		},
	});
}

export function* updateCampaignLandingPage({ payload }: Action<CampaignCommunityDto>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(CAMPAIGN_LANDING_PAGE, payload),
		*completed(response: any) {
			yield put(actions.updateCampaignLandingPageSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateCampaignLandingPageFailed(response));
		},
	});
}

export function* watcherCampaignSaga() {
	yield takeLatest(CampaignTypes.GET_CAMPAIGN_LIST, getCampaignListAPI);
	yield takeLatest(CampaignTypes.GET_DETAIL_CAMPAIGN_REQUEST, getDetailCampaign);
	yield takeLatest(CampaignTypes.CREATE_CAMPAIGN_REQUEST, createCampaign);
	yield takeLatest(CampaignTypes.UPDATE_CAMPAIGN_REQUEST, updateCampaign);
	yield takeLatest(CampaignTypes.GET_LIST_CONTENT_CAMPAIGN, fetchListContentCampaignAPI);
	yield takeLatest(CampaignTypes.DELETE_CAMPAIGN_REQUEST, deleteCampaignAPI);
	yield takeLatest(CampaignTypes.SUGGEST_IMG_CAMPAIGN_REQUEST, suggestImgCampaign);
	yield takeLatest(CampaignTypes.UPLOAD_IMGS_CAMPAIGN_REQUEST, uploadImgsCampaign);
	yield takeLatest(CampaignTypes.GET_RECOMMENDATION_CAMPAIGN_REQUEST, getRecommendationCampaign);
	yield takeLatest(CampaignTypes.UPDATE_CAMPAIGN_LANDING_PAGE_REQUEST, updateCampaignLandingPage);
}
