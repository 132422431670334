import { IconDragHandle, IconKeyboardArrowLeft } from '@assets/icons';
import { KEY_FIELD_FORM_WIDGET } from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { FieldItemWidgetDto } from '@models/content/widgetManager';
import {
	StyledIcon,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledIconRemoveCircle } from '@styled/Content/WidgetManager';
import { StyledCheckbox } from '@styled/Content/WidgetManager/DetailWidgetStyled';
import {
	StyledCustomFieldItem,
	StyledFieldItem,
	StyledFormItem,
	StyledInputCustomField,
	StyledLabelField,
	StyledLineBox,
	StyledWrapperLabel,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
	handleChangeRequired: () => void;
	field: FieldItemWidgetDto;
	keyName?: string;
	keyIndex: number;
	handleRemoveField: () => void;
	setFieldsValue: (val: any) => void;
	getValueField: (val: string) => any;
	isExist?: boolean;
	propSort: any;
};
const CustomAddressField = (props: PropTypes) => {
	const {
		handleChangeRequired,
		field,
		keyName: keyNameDefault,
		keyIndex,
		handleRemoveField,
		setFieldsValue,
		getValueField,
		isExist,
		propSort,
		...other
	} = props;
	const { detailWidget } = useContext(WidgetBuilderContext);
	const { otherInfo = {} } = detailWidget?.metadata;
	const { street, state, city, zipCode } = field;

	const { t } = useTranslation();
	const keyName = keyNameDefault || `${KEY_FIELD_FORM_WIDGET.item}${keyIndex}`;
	const nameStreet = `${KEY_FIELD_FORM_WIDGET.street}${keyIndex}`;
	const nameState = `${KEY_FIELD_FORM_WIDGET.state}${keyIndex}`;
	const nameCity = `${KEY_FIELD_FORM_WIDGET.city}${keyIndex}`;
	const nameZipCode = `${KEY_FIELD_FORM_WIDGET.zip_code}${keyIndex}`;

	const [showMore, setShowMore] = useState(true);

	useEffect(() => {
		setFieldsValue({
			[nameStreet]: street,
			[nameState]: state,
			[nameCity]: city,
			[nameZipCode]: zipCode,
		});
	}, [field]);

	const arrField = [
		{
			id: 1,
			name: nameStreet,
			label: 'street',
		},
		{
			id: 2,
			name: nameState,
			label: 'state',
		},
		{
			id: 3,
			name: nameCity,
			label: 'city',
		},
		{
			id: 5,
			name: nameZipCode,
			label: 'zip_code',
		},
	];

	const renderStatusShowMore = () => {
		return (
			<StyledTitle
				cursor="pointer"
				className="unset_height"
				display="flex"
				fontSize="10px"
				height="20px"
				onClick={() => setShowMore(!showMore)}
				color={THEME.colors.gray2}
				style={{ alignItems: 'center', textTransform: 'uppercase' }}>
				{showMore ? t('button.show_less') : t('button.show_more')}
				<StyledIcon size={12} marginSvg={'0'} rotate={showMore ? 90 : -90}>
					<IconKeyboardArrowLeft />
				</StyledIcon>
			</StyledTitle>
		);
	};

	const renderContainerLabel = (label: string) => {
		return (
			<StyledWrapperContent width="100%">
				<StyledLabelField
					maxWidth={'calc(100% - 74px)'}
					className={`custom_label ${showMore ? '' : 'overflow_text'}`}>
					{label}
				</StyledLabelField>
				{!showMore && renderStatusShowMore()}
			</StyledWrapperContent>
		);
	};

	return (
		<StyledFieldItem {...other}>
			<StyledCustomFieldItem>
				<StyledWrapperLabel>
					{renderContainerLabel(
						t('content.widget_manager.widget_builder.label_field.mailing_address'),
					)}

					<StyledWrapperAction height="24px">
						{propSort && (
							<StyledIcon
								{...propSort}
								className="icon_drag"
								style={{ margin: '0 6px 0 0' }}>
								<IconDragHandle />
							</StyledIcon>
						)}
						<StyledCheckbox
							onChange={(e: any) => {
								e.stopPropagation();

								handleChangeRequired();
							}}
							disabled={field?.defaultRequired}
							checked={field?.required}
						/>
					</StyledWrapperAction>
				</StyledWrapperLabel>
				<StyledWrapperContent
					className={showMore ? '' : 'hidden'}
					padding="0 12px"
					position="relative">
					<StyledLineBox />
					{arrField.map((item) => (
						<StyledFormItem
							name={item.name}
							key={item.id}
							label={t(
								`content.widget_manager.widget_builder.label_field.${item.label}`,
							)}
							rules={[
								{
									required: true,
									message: t('content.widget_manager.widget_builder.required'),
								},
							]}>
							<StyledInputCustomField
								maxLength={otherInfo?.maxLengthShortText || 255}
								id={item.name}
								placeholder={t(
									'content.widget_manager.widget_builder.enter_display_uploader',
								)}
							/>
						</StyledFormItem>
					))}
				</StyledWrapperContent>
				<StyledWrapperTitle>{showMore && renderStatusShowMore()}</StyledWrapperTitle>
			</StyledCustomFieldItem>
			{!field?.defaultRequired && <StyledIconRemoveCircle onClick={handleRemoveField} />}
		</StyledFieldItem>
	);
};

export default CustomAddressField;
