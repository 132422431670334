import { STATUSCODE } from '@constants/APIs';
import { call } from 'redux-saga/effects';

type GenericSaga = {
	completed?: any;
	failed: any;
	gatewayCall: any;
};

function* genericApiSaga({
	completed,
	failed,
	gatewayCall,
}: GenericSaga): Generator<any, any, any> {
	try {
		const response: any = yield call(gatewayCall);

		switch (response.status) {
			case STATUSCODE.FAILED404:
				if (failed) {
					yield failed(response.body);
				}
				break;

			case STATUSCODE.FAILED400:
				if (failed) {
					yield failed(response.body);
				}
				break;

			case STATUSCODE.FAILED401:
				if (failed) {
					yield failed(response.body);
				}
				break;

			case STATUSCODE.SUCCESS:
				if (completed) {
					yield completed(response.body);
				}
				break;

			default:
				if (failed) {
					yield failed(response.body);
				}
				break;
		}
	} catch (error) {
		yield failed({ message: 'System error' });
	}
}

export default genericApiSaga;
