import { IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@assets/icons';
import { DURATION, TYPE_ACTIONS } from '@constants/common';
import { IN_PROGRESS, SUCCEEDED } from '@constants/status';
import LoadingWrapper from '@cores/LoadingWrapper';
import { ReminderEmailDto } from '@models/taskmanager';
import { TaskManagerStoreDto } from '@models/taskmanager/store';
import {
	sendReminderEmailEnd,
	sendReminderEmailRequest,
} from '@stores/taskmanager/taskmanager.actions';
import {
	StyledCol,
	StyledRow,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledReminderEmailModal, StyledTaskArrow } from '@styled/TaskManager/TaskManagerStyled';
import { checkPositionContent } from '@utils/funcHelper';
import { message } from 'antd';
import { Key, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type ReminderEmailProps = {
	isDetails?: boolean;
	taskIds: number[] | Key[];
	visible: boolean;
	onCancel: () => void;
};

const ReminderEmail = (props: ReminderEmailProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { taskIds, visible, onCancel, isDetails } = props;
	const { reminderEmail, sendReminderEmailStatus }: TaskManagerStoreDto = useSelector(
		(state: any) => state.taskManager,
	);

	const [reminderEmailList, setReminderEmailList] = useState<ReminderEmailDto[]>([]);
	const [currentReminderEmail, setCurrentReminderEmail] = useState<ReminderEmailDto>();

	useEffect(() => {
		if (sendReminderEmailStatus === SUCCEEDED && reminderEmail?.isSend) {
			message
				.success(
					t('message.action_success', {
						action: t('action.sent'),
					}),
					DURATION.S_1,
				)
				.then(() => {
					dispatch(sendReminderEmailEnd());
					if (isDetails) {
						onCancel();
					}
				});
		}

		if (reminderEmail?.template && !reminderEmail?.isSend) {
			setReminderEmailList(reminderEmail?.template);
		}
	}, [sendReminderEmailStatus, reminderEmail, isDetails]);

	useEffect(() => {
		if (reminderEmailList.length) {
			setCurrentReminderEmail(reminderEmailList[0]);
		}
	}, [reminderEmailList]);

	useEffect(() => {
		if (taskIds.length && visible) {
			getReminderEmail(); // to get template
		}

		return () => {
			dispatch(sendReminderEmailEnd());
			setReminderEmailList([]);
			setCurrentReminderEmail(undefined);
		};
	}, [visible]);

	const getReminderEmail = () => {
		dispatch(
			sendReminderEmailRequest({
				taskIds,
				send: false,
			}),
		);
	};

	const handleSendReminderEmail = () => {
		if (currentReminderEmail) {
			dispatch(
				sendReminderEmailRequest({
					taskIds: [currentReminderEmail.taskId],
					send: true,
				}),
			);
		}
	};

	const reminderEmailPosition = useMemo(() => {
		let positionRes = { isLast: false, isFirst: false };

		if (reminderEmailList) {
			const index = reminderEmailList.findIndex(
				(item: ReminderEmailDto) => item.taskId === currentReminderEmail?.taskId,
			);
			if (index !== -1) {
				positionRes = checkPositionContent(index, reminderEmailList.length || 0);
			}
		}

		return { ...positionRes };
	}, [reminderEmail, currentReminderEmail]);

	const handleSwitchTemplate = (action: string) => {
		if (reminderEmailList) {
			const currentIndex = reminderEmailList?.findIndex(
				(item: ReminderEmailDto) => item.taskId === currentReminderEmail?.taskId,
			);

			if (currentIndex !== -1) {
				const switchedIndex =
					action === TYPE_ACTIONS.NEXT ? currentIndex + 1 : currentIndex - 1;
				const switchedTemplate = reminderEmailList[switchedIndex];
				setCurrentReminderEmail(switchedTemplate);
			}
		}
	};

	return (
		<StyledReminderEmailModal
			width="fit-content"
			visible={visible}
			title={<StyledText fontSize="20px">{t('task_manager.send_reminder_email')}</StyledText>}
			centered
			okText={t('button.send_email')}
			onOk={handleSendReminderEmail}
			onCancel={onCancel}>
			{reminderEmailList && reminderEmailList?.length > 1 && (
				<>
					{!reminderEmailPosition.isFirst && (
						<StyledTaskArrow
							positionArrow="left"
							onClick={() => {
								handleSwitchTemplate(TYPE_ACTIONS.PREV);
							}}>
							<IconKeyboardArrowLeft />
						</StyledTaskArrow>
					)}
					{!reminderEmailPosition.isLast && (
						<StyledTaskArrow
							positionArrow="right"
							onClick={() => {
								handleSwitchTemplate(TYPE_ACTIONS.NEXT);
							}}>
							<IconKeyboardArrowRight />
						</StyledTaskArrow>
					)}
				</>
			)}

			<LoadingWrapper isLoading={sendReminderEmailStatus === IN_PROGRESS}>
				<StyledRow padding="24px" gutter={[12, 12]}>
					<StyledCol span={8}>
						<StyledWrapperContent
							className="custom_scroll_bar"
							overflow="auto"
							maxHeight="calc(100vh - 300px)">
							<StyledText fontSize="16px">
								{t('task_manager.reminder_email.inform_msg')}
							</StyledText>
							<StyledText margin="18px 0" fontSize="16px">
								{t('email_builder.subject')} {currentReminderEmail?.subject}
							</StyledText>
							<StyledText fontSize="16px">
								{t('email_builder.recipients')}:{' '}
							</StyledText>
							{currentReminderEmail?.recipients?.map((item: string) => {
								return <StyledText fontSize="16px">{item}</StyledText>;
							})}
							<StyledText margin="18px 0" fontSize="16px">
								{t('task_manager.reminder_email.preview_msg')}
							</StyledText>
						</StyledWrapperContent>
					</StyledCol>
					<StyledCol span={16}>
						{reminderEmail && (
							<StyledWrapperContent overflow="auto" maxHeight="calc(100vh - 300px)">
								<StyledWrapperContent
									dangerouslySetInnerHTML={{
										__html: currentReminderEmail?.body || '',
									}}
									pointerEvents="none"
								/>
							</StyledWrapperContent>
						)}
					</StyledCol>
				</StyledRow>
			</LoadingWrapper>
		</StyledReminderEmailModal>
	);
};

export default ReminderEmail;
