import THEME from '@constants/themes/themes';
import {
	FieldItemWidgetDto,
	MetadataWidgetBuilder,
	TermConditionItemDto,
	WidgetBuilderDetailNoIdDto,
} from '@models/content/widgetManager';
import { EmailOptInDto } from '@models/content/widgetManager/summary';

const TYPE_CHANGE = {
	back: 'BACK',
	next: 'NEXT',
};

const TAB_WIDGET = {
	live: 'active',
	draft: 'inactive',
	custom: 'custom',
};

const DEFAULT_STORE_CUSTOM_FIELD = {
	content: [],
	creator: [],
};

const ALL_FIELDS_WIDGET = ['firstName', 'lastName', 'email'];

const KEY_CREATE = 'create';

const HEIGHT_DRAG_AND_DROP = {
	mobile: 150,
	desktop: 215,
};

const SIZE_IMAGE_RESOLUTION = {
	height: 1080,
	width: 1920,
};

const REDUNDANT = 39;

const PADDING_WIDGET = {
	desktop: 48,
	mobile: 20,
};

const MAX_SIZE_SCREEN_DETAIL_WIDGET = {
	widthDesktop: 700,
	widgetMobile: 350,
};

const MAX_SIZE_LOGO = {
	widthDesktop: MAX_SIZE_SCREEN_DETAIL_WIDGET.widthDesktop - PADDING_WIDGET.desktop * 2,
	widgetMobile: MAX_SIZE_SCREEN_DETAIL_WIDGET.widgetMobile - PADDING_WIDGET.mobile * 2,
};

const STATUS_UPLOAD_FILE_WIDGET = {
	bgFile: 'statusUploadBgFile',
	logoFile: 'statusUploadLogo',
	buttonFile: 'statusButtonFile',
};

const DEFAULT_ADVANCE_SETTING_WIDGET = {
	GOOGLE_TRANSLATE: 'Translate this Page',
	CONFIRMATION_MESSAGE: 'Thank You for Submitting!',
	CONFIRMATION_BUTTON_TEXT: 'Upload More',
	SOCIAL_MESSAGE: 'Want to share it on social?',
};

const KEY_ADVANCE_SETTING = {
	GOOGLE_TRANSLATE: 'googleTranslate',
	CONFIRMATION_MESSAGE: 'confirmationMessage',
	CONFIRMATION_BUTTON_TEXT: 'confirmationButtonText',
	VISIBLE_SOCIAL_BUTTONS: 'visibleSocialButtons',
	SOCIAL_MESSAGE: 'socialMessage',
};

const TYPE_CUSTOM_FIELD = {
	email: 'email',
	instagram: 'instagram',
	tiktok: 'tiktok',
	twitter: 'twitter',
	phone: 'phone',
	dropdown: 'dropdown',
	shortAnswer: 'shortAnswer',
	paragraph: 'paragraph',
	mailAddress: 'mailAddress',
};

const FIELD_NAME_DEFAULT = {
	firstName: 'first_Name',
	lastName: 'last_Name',
	emailAddress: 'emailAddress',
};

const LIST_BASIC_CUSTOM_FIELD: FieldItemWidgetDto[] = [
	{
		name: 'First Name',
		required: true,
		type: 'shortAnswer',
		fieldName: FIELD_NAME_DEFAULT.firstName,
	},
	{
		name: 'Last Name',
		required: true,
		type: 'shortAnswer',
		fieldName: FIELD_NAME_DEFAULT.lastName,
	},
];

const TYPE_TERM = {
	link: 'link',
	popupFile: 'popupFile',
	popupText: 'popupText',
	text: 'text',
};

const FIELD_NAME_TERM = 'term';

const DEFAULT_TERMS: TermConditionItemDto = {
	text: 'I agree to the',
	textLink: 'Terms & Conditions',
	url: 'https://www.entribe.com/terms',
	type: TYPE_TERM.link,
	isDefault: true,
	required: true,
	fieldName: `${FIELD_NAME_TERM}_0`,
};

const DEFAULT_TEXT_BUTTON_TERM = 'I agree to these terms';

const KEY_FIELDS = {
	LIST_FIELDS: 'listFields',
	BASIC_FIELDS: 'basicFields',
};

const EMAIL_OPT_IN: EmailOptInDto = {
	NOT_ADD_USER: {
		name: 'NOT_ADD_USER',
		method: '',
	},
	OPT_IN_AUTOMATICALLY: {
		name: 'OPT_IN_AUTOMATICALLY',
		method: 'SUBMISSION_WITHOUT_CONSENT',
	},
	OPT_IN_SUBMISSION: {
		name: 'OPT_IN_SUBMISSION',
		method: 'PASSIVE_CONSENT',
	},
	ASK_USER: {
		name: 'ASK_USER',
		method: 'ACTIVE_CONSENT',
	},
	FIELD_NAME: 'creatorOptInEmail',
};

const EMAIL_OPT_IN_FIELD_VALUES = [EMAIL_OPT_IN.OPT_IN_SUBMISSION.name, EMAIL_OPT_IN.ASK_USER.name];

const CLIENT_NAME = '{{Client Name}}';

const DEFAULT_EMAIL_OPT_IN = {
	name: EMAIL_OPT_IN.OPT_IN_SUBMISSION.name,
	optInMethod: EMAIL_OPT_IN.OPT_IN_SUBMISSION.method,
	fieldValues: {
		text: `By submitting this form, I consent to receiving news, offers, services and more from ${CLIENT_NAME} and its affiliates and partners, including through direct mail, digital media or digital communications.`,
		textLink: '',
		url: '',
		type: TYPE_TERM.link,
		fieldName: EMAIL_OPT_IN.FIELD_NAME,
	},
};

const METADATA_WIDGET: MetadataWidgetBuilder = {
	header: {
		title: 'Welcome!',
		message: 'Use the form below to upload photo(s) or video.',
		srcLogo: '',
		nameImage: '',
		dropzoneText: 'Drop files here or click to browse',
		sizeLogo: undefined,
	},
	body: {
		basicFields: [
			{
				name: 'Email Address',
				required: true,
				type: TYPE_CUSTOM_FIELD.email,
				defaultRequired: true,
				fieldName: FIELD_NAME_DEFAULT.emailAddress,
			},
			...LIST_BASIC_CUSTOM_FIELD,
		],
		listFields: [],
		termsConditions: [],
		advancedSettings: {
			googleTranslate: DEFAULT_ADVANCE_SETTING_WIDGET.GOOGLE_TRANSLATE,
			confirmationMessage: DEFAULT_ADVANCE_SETTING_WIDGET.CONFIRMATION_MESSAGE,
			confirmationButtonText: DEFAULT_ADVANCE_SETTING_WIDGET.CONFIRMATION_BUTTON_TEXT,
			visibleSocialButtons: false,
			socialMessage: DEFAULT_ADVANCE_SETTING_WIDGET.SOCIAL_MESSAGE,
		},
		emailOptIn: DEFAULT_EMAIL_OPT_IN,
	},
	colors: {
		bgUrl: '',
		nameImageBg: '',
		bgColor: THEME.colors.gray5,
		bgSubmit: THEME.colors.orangeBase,
		bgFileDropZone: '#F5F9FD',
		highlight: THEME.colors.orangeBase,
		colorUploadIcon: THEME.colors.orangeBase,
		colorUploadText: THEME.colors.gray1,
		enableSharingGoogleDrive: true,
		enableCustomFontFamily: false,
		fileFontUrls: [],
	},
	otherInfo: {
		maxLengthShortText: 255,
		maxLengthParagraph: 8000,
	},
	thumbnail: '',
};

const KEY_HIGH_LIGHT_GROUP = 'highlightGroup';

const GROUP_HIGH_LIGHT = {
	highlight: 'highlight',
	iconUpload: 'colorUploadIcon',
	formBackground: 'formBackground',
};

const GROUP_CUSTOM_FIELD = {
	CUSTOM_FIELD: 'CUSTOM_FIELD',
	CUSTOM_CREATOR_FIELD: 'CUSTOM_CREATOR_FIELD',
	CUSTOM_CONTENT_FIELD: 'CUSTOM_CONTENT_FIELD',
};

const MAPPING_FIELD = {
	CONTENT: 'CONTENT',
	CREATOR: 'CREATOR',
};

const DEFAULT_WIDGET_BUILDER: WidgetBuilderDetailNoIdDto = {
	metadata: METADATA_WIDGET,
	status: TAB_WIDGET.draft,
	url: '',
	name: '',
	isDefault: false,
	referenceId: null,
};

const KEY_REPLACE_SPECIAL_CHARACTER = '_';

const KEY_FIELD_FORM_WIDGET = {
	item: 'field_item_',
	child: 'child_form_',
	dropdown: 'dropdown_',
	label: 'label_name_',
	checkbox_dropdown: 'checkbox_dropdown_',
	mapping_type: 'mapping_type_',
	street: 'street_',
	state: 'state_',
	city: 'city_',
	zip_code: 'zip_code_',
};

const KEY_FIELD_FORM_TERM = {
	text: 'text_term_',
	textLink: 'text_link_term_',
	valueTextLink: 'value_link_term_',
	buttonText: 'button_text_',
	licensingPeriod: 'licensingPeriod_',
	approvedCountries: 'approvedCountries_',
	approvedMarketingChannels: 'approvedMarketingChannels_',
};

const LIMIT_FIELD_WARNING = 5;

const ALL_COUNTRY = 'ALL';

const LIST_CUSTOM_FIELD: FieldItemWidgetDto[] = [
	{
		type: TYPE_CUSTOM_FIELD.instagram,
		name: '',
		required: true,
		fieldName: '',
	},
	{
		type: TYPE_CUSTOM_FIELD.tiktok,
		name: '',
		required: true,
		fieldName: '',
	},
	{
		type: TYPE_CUSTOM_FIELD.twitter,
		name: '',
		required: true,
		fieldName: '',
	},
	{
		type: TYPE_CUSTOM_FIELD.phone,
		name: '',
		required: true,
		fieldName: '',
	},
	{
		type: TYPE_CUSTOM_FIELD.paragraph,
		name: '',
		required: true,
		fieldName: '',
		isContentDescription: true,
	},
	{
		type: TYPE_CUSTOM_FIELD.mailAddress,
		name: '',
		street: 'Street',
		state: 'State',
		city: 'City',
		zipCode: 'Zip code',
		required: true,
		fieldName: '',
	},
];

const LIST_TERM_CONDITION: TermConditionItemDto[] = [
	{
		type: TYPE_TERM.popupFile,
		text: '',
		textLink: '',
		url: '',
		nameFile: '',
		required: true,
	},
	{
		type: TYPE_TERM.link,
		text: '',
		textLink: '',
		url: '',
		required: true,
	},
	{
		type: TYPE_TERM.text,
		text: '',
		textLink: '',
		url: '',
		required: true,
	},
];

const DEFAULT_LABEL_FIELD = {
	DROPDOWN: '',
	SHORT_ANSWER: '',
	PARAGRAPH: '',
};

const TYPE_ADD_FIELDS = {
	CUSTOM: 'CUSTOM',
	STANDARD: 'STANDARD',
};

const ANSWER_TYPES = {
	MULTIPLE: 'MULTIPLE',
	SINGLE: 'SINGLE',
};

const LIST_FIELD_GROUP_CUSTOM: FieldItemWidgetDto[] = [
	{
		type: TYPE_CUSTOM_FIELD.dropdown,
		name: '',
		required: true,
		listOption: [],
		fieldName: '',
		mapping: MAPPING_FIELD.CONTENT,
		label: DEFAULT_LABEL_FIELD.DROPDOWN,
		typeAdd: TYPE_ADD_FIELDS.CUSTOM,
		answerType: ANSWER_TYPES.MULTIPLE,
	},
	{
		type: TYPE_CUSTOM_FIELD.shortAnswer,
		name: '',
		required: true,
		fieldName: '',
		mapping: MAPPING_FIELD.CONTENT,
		label: DEFAULT_LABEL_FIELD.SHORT_ANSWER,
		typeAdd: TYPE_ADD_FIELDS.CUSTOM,
	},
	{
		type: TYPE_CUSTOM_FIELD.paragraph,
		name: '',
		required: true,
		fieldName: '',
		mapping: MAPPING_FIELD.CONTENT,
		label: DEFAULT_LABEL_FIELD.PARAGRAPH,
		typeAdd: TYPE_ADD_FIELDS.CUSTOM,
	},
];

const ANSWER_DROPDOWN = [
	{
		value: ANSWER_TYPES.MULTIPLE,
		name: 'content.widget_manager.widget_builder.select_all_apply',
	},
	{
		value: ANSWER_TYPES.SINGLE,
		name: 'content.widget_manager.widget_builder.must_choose_one',
	},
];

const GROUP_DEFAULT_CUSTOM = [
	{
		group: GROUP_CUSTOM_FIELD.CUSTOM_FIELD,
		options: LIST_FIELD_GROUP_CUSTOM,
	},
];

const KEY_VALIDATE_STEP_1 = {
	title: 'title',
	message: 'message',
	logo: 'logo',
	progressLogo: 'progress_logo',
	dropzoneText: 'dropzoneText',
};

const FONT_FAMILY_FORMAT = [
	{ type: '.ttf', format: 'truetype' },
	{
		type: '.eot?#',
		format: 'embedded-opentype',
	},
	{
		type: '.woff2',
		format: 'woff2',
	},
	{
		type: '.woff',
		format: 'woff',
	},
	{
		type: '.svg#',
		format: 'svg',
	},
];

const OTHER_FONT_FORMAT = {
	type: 'eot',
};

const FONT_STYLE = {
	ITALIC: 'italic',
	NORMAL: 'normal',
	OBLIQUE: 'oblique',
};

const FONT_WEIGHT = {
	BOLD: 'bold',
	NORMAL: 'normal',
};

const REGEX_POSITIVE_NUMBER = /^(\d*[0-9]\d*(\.\d+)?|0*\.\d*[0-9]\d*)$/;

const OPACITY_UPLOAD = 0.15;

const DEFAULT_TIME_OUT_WIDGET = 10;

const STATUS_SETTING = {
	ACTIVE: 0,
	DISABLED: 1,
	CLOSED: 2,
};

const MORE_OPTION_WIDGET = {
	maxRecords: 10,
	page: 1,
	widgetIds: [],
};

const ID_GOOGLE_TRANSLATE = 'google_translate_widget';
const ID_FONT_FAMILY_WIDGET = 'font_family_widget';
const FONT_FAMILY_WIDGET_NAME = 'fontWidgetEntribe';

const DEFAULT_SIZE_IFRAME_WIDGET = {
	width: 100,
	height: 625,
};

const MARKETING_CHANNELS = [
	'ALL MEDIA',
	'DIGITAL',
	'OUT OF HOME',
	'PHOTOGRAPHY',
	'POP-POS',
	'PRINT',
	'TV-BROADCAST',
	'SOCIAL',
	'MEDIA NO USAGE',
	'RADIO',
	'INTERNAL USE ONLY',
	'WEB',
	'BTL',
];

const UPLOADER_FIELD_NAME = {
	ENABLE_SHARING_TIKTOK: 'enableSharing',
	ENABLE_SHARING_GOOGLE_DRIVE: 'enableSharingGoogleDrive',
	ENABLE_SHARING_YOUTUBE: 'enableSharingYouTube',
	ENABLE_SHARING_INSTAGRAM: 'enableSharingInstagram',
	ENABLE_CUSTOM_FONT_FAMILY: 'enableCustomFontFamily',
	FILE_FONT_URLS: 'fileFontUrls',
	FILE_FONT_UPLOADS: 'fileFontUploads',
};

const SCREEN_VIEW = {
	DESKTOP: 'DESKTOP',
	MOBILE: 'MOBILE',
};

export {
	ALL_COUNTRY,
	ALL_FIELDS_WIDGET,
	ANSWER_DROPDOWN,
	ANSWER_TYPES,
	CLIENT_NAME,
	DEFAULT_ADVANCE_SETTING_WIDGET,
	DEFAULT_EMAIL_OPT_IN,
	DEFAULT_LABEL_FIELD,
	DEFAULT_SIZE_IFRAME_WIDGET,
	DEFAULT_STORE_CUSTOM_FIELD,
	DEFAULT_TERMS,
	DEFAULT_TEXT_BUTTON_TERM,
	DEFAULT_TIME_OUT_WIDGET,
	DEFAULT_WIDGET_BUILDER,
	EMAIL_OPT_IN,
	EMAIL_OPT_IN_FIELD_VALUES,
	FIELD_NAME_DEFAULT,
	FIELD_NAME_TERM,
	FONT_FAMILY_FORMAT,
	FONT_FAMILY_WIDGET_NAME,
	FONT_STYLE,
	FONT_WEIGHT,
	GROUP_CUSTOM_FIELD,
	GROUP_DEFAULT_CUSTOM,
	GROUP_HIGH_LIGHT,
	HEIGHT_DRAG_AND_DROP,
	ID_FONT_FAMILY_WIDGET,
	ID_GOOGLE_TRANSLATE,
	KEY_ADVANCE_SETTING,
	KEY_CREATE,
	KEY_FIELDS,
	KEY_FIELD_FORM_TERM,
	KEY_FIELD_FORM_WIDGET,
	KEY_HIGH_LIGHT_GROUP,
	KEY_REPLACE_SPECIAL_CHARACTER,
	KEY_VALIDATE_STEP_1,
	LIMIT_FIELD_WARNING,
	LIST_BASIC_CUSTOM_FIELD,
	LIST_CUSTOM_FIELD,
	LIST_FIELD_GROUP_CUSTOM,
	LIST_TERM_CONDITION,
	MAPPING_FIELD,
	MARKETING_CHANNELS,
	MAX_SIZE_LOGO,
	MAX_SIZE_SCREEN_DETAIL_WIDGET,
	MORE_OPTION_WIDGET,
	OPACITY_UPLOAD,
	OTHER_FONT_FORMAT,
	PADDING_WIDGET,
	REDUNDANT,
	REGEX_POSITIVE_NUMBER,
	SCREEN_VIEW,
	SIZE_IMAGE_RESOLUTION,
	STATUS_SETTING,
	STATUS_UPLOAD_FILE_WIDGET,
	TAB_WIDGET,
	TYPE_ADD_FIELDS,
	TYPE_CHANGE,
	TYPE_CUSTOM_FIELD,
	TYPE_TERM,
	UPLOADER_FIELD_NAME,
	METADATA_WIDGET,
};
