import { ImageDefaultWidget } from '@assets/images';
import CurrentDesign from '@components/Content/WidgetManager/CurrentDesign';
import ViewDetailWidget from '@components/Content/WidgetManager/ViewDetailWidget';
import {
	DEFAULT_WIDGET_BUILDER,
	MORE_OPTION_WIDGET,
	TAB_WIDGET,
} from '@constants/content/widgetManager';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import WidgetManagerContext from '@contexts/Content/WidgetManager';
import { ConfirmModal } from '@cores/Modal';
import { LoadingWrapper } from '@cores/index';
import {
	CampaignItemDto,
	FilterWidgetDto,
	MetadataWidgetBuilder,
	WidgetBuilderDetailNoIdDto,
	WidgetBuilderDetailNoIdRequestDto,
	WidgetBuilderDetailRequestDto,
} from '@models/content/widgetManager';
import { LocationStateDto } from '@models/messages/summary';
import {
	clientSettingsRequest,
	cloneLiveWidgetEnd,
	cloneLiveWidgetRequest,
	createDraftWidgetBuilderEnd,
	createDraftWidgetBuilderRequest,
	fetchDetailWidgetBuilderEnd,
	fetchListWidgetRequest,
	fetchTemplateDefaultRequest,
	removeWidgetBuilderEnd,
	removeWidgetBuilderRequest,
	resumeLiveDraftEnd,
	resumeLiveDraftRequest,
	saveDraftWidgetBuilderEnd,
	saveDraftWidgetBuilderRequest,
	updateStatusRecentlyUpdatedRequest,
	updateStoreWidget,
} from '@stores/actions';
import {
	StyledConfirmRemoveLiveWidget,
	StyledNameCampaign,
	StyledSubRemove,
	StyledWrapper,
	StyledWrapperMessage,
} from '@styled/Content/WidgetManager';
import { Row, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { GET_LIST_WIDGET_CAMPAIGN } from '@constants/APIs';
import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { getRequest } from '@helpers/requestHelpers';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';

const SettingWidgetManager = () => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const history = useHistory();

	const {
		fetchingWidgetManager,
		statusCreateWidgetBuilder,
		statusRemoveWidget,
		error = '',
		widgetManagers,
		statusDraftWidgetBuilder,
		statusGetWidgetDetail,
		widgetBuilder,
		resumeLiveDraftStatus,
		cloneLiveWidgetStatus,
		widgetDraftRes,
	}: WidgetManagerStoreDto = useSelector((state: any) => state.widgetManager);
	const { loadingTemplateDefault }: ContentLicensingStoreDto = useSelector(
		(state: any) => state.contentLicensing,
	);

	const [hideDetail, setHideDetail] = useState<boolean>(true);
	const [widgetSelect, setWidgetSelect] = useState<WidgetBuilderDetailRequestDto | null>(null);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [typeConfirm, setTypeConfirm] = useState<string>('');
	const [listWidgetManager, setListWidgetManagers] = useState<WidgetBuilderDetailRequestDto[]>(
		[],
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<FilterWidgetDto>({
		status: TAB_WIDGET.live,
		...MORE_OPTION_WIDGET,
	});
	const [listCampaign, setListCampaign] = useState<CampaignItemDto[]>([]);
	const { state: stateNotification } = useLocation<LocationStateDto>();

	const metadataWidgetSelect: MetadataWidgetBuilder | undefined = useMemo(() => {
		if (widgetSelect?.metadata) {
			return JSON.parse(widgetSelect?.metadata || '');
		}
		return undefined;
	}, [widgetSelect]);

	const handleConfirmPopup = () => {
		const dataRequest: any = {};
		onCloseWidgetConfirm();

		switch (typeConfirm) {
			case TYPE_ACTIONS.EDIT_DESIGN:
				return dispatch(resumeLiveDraftRequest({ widgetId: widgetSelect?.id }));
			case TYPE_ACTIONS.REMOVE:
				dataRequest.widgetId = widgetSelect?.id;
				return dispatch(removeWidgetBuilderRequest({ dataRequest }));
			default:
				break;
		}
		return null;
	};

	const handleCreateNewWidgetBuilder = async (
		paramsRequest?: WidgetBuilderDetailNoIdDto,
		templateId?: number,
	) => {
		setIsLoading(true);
		if (typeConfirm) {
			onCloseWidgetConfirm();
		}
		if (paramsRequest) {
			const dataRequest: WidgetBuilderDetailNoIdRequestDto = {
				...paramsRequest,
				metadata: JSON.stringify(paramsRequest?.metadata),
			};
			if (templateId) {
				dataRequest.termsTemplateId = templateId;
			}
			if (dataRequest.terms) {
				delete dataRequest.terms;
			}

			return dispatch(
				createDraftWidgetBuilderRequest({
					dataRequest,
				}),
			);
		}
		const { metadata, ...otherRequestDefault } = DEFAULT_WIDGET_BUILDER;
		await fetch(ImageDefaultWidget)
			.then((response) => response.blob())
			.then((blob) => {
				const reader = new FileReader();
				reader.readAsDataURL(blob);

				reader.onload = () => {
					const metaDataChange: MetadataWidgetBuilder = {
						...metadata,
						thumbnail: reader?.result,
						body: {
							...metadata.body,
							termsConditions: [],
						},
					};
					const dataRequest: WidgetBuilderDetailNoIdRequestDto = {
						...otherRequestDefault,
						metadata: JSON.stringify(metaDataChange),
					};
					if (templateId) {
						dataRequest.termsTemplateId = templateId;
					}
					dispatch(
						createDraftWidgetBuilderRequest({
							dataRequest,
						}),
					);
				};
				reader.onerror = (error) => {
					message.error(error);
					setIsLoading(false);
				};
			});
		return null;
	};

	const handleRedirectDraftWidget = (id: number) => {
		if (id) {
			setTimeout(() => {
				history.push(`${ROUTE_PATH.WIDGET_MANAGER}/${id}`);
			}, 0);
		}
	};

	const handleStartOverDraft = () => {
		onCloseWidgetConfirm();
		if (widgetSelect) {
			dispatch(
				cloneLiveWidgetRequest({
					widgetId: widgetSelect.id,
				}),
			);
		}
	};

	const handleUpdateListAfterRemove = (id: number) => {
		const keyIndex = listWidgetManager?.findIndex((item) => item?.id === id);

		const newList: WidgetBuilderDetailRequestDto[] = listWidgetManager?.filter(
			(item) => item?.id !== id,
		);
		let widgetSelectChange = null;
		if (newList?.length > 0) {
			if (keyIndex === -1) {
				widgetSelectChange = newList[0];
			} else {
				if (keyIndex >= newList?.length - 1) {
					widgetSelectChange = newList[newList?.length - 1];
				} else {
					widgetSelectChange = newList[keyIndex];
				}
			}
		}

		setListWidgetManagers(newList);
		setWidgetSelect(widgetSelectChange || null);
		if (!widgetSelectChange && !hideDetail) {
			setHideDetail(true);
		}
	};

	const onCloseWidgetConfirm = () => {
		setTypeConfirm('');
		setListCampaign([]);
	};

	const renderContentPopupConfirm = () => {
		if (typeConfirm === TYPE_ACTIONS.EDIT_DESIGN) {
			return (
				<>
					{t('content.widget_manager.popup.message_alert_edit')} <br />
					{t('content.widget_manager.popup.message_alert_question_edit')}
				</>
			);
		}
		if (typeConfirm === TYPE_ACTIONS.REMOVE) {
			if (widgetSelect?.status === TAB_WIDGET.draft) {
				return t('content.widget_manager.popup.message_remove');
			}
			if (listCampaign?.length === 0) {
				return (
					<StyledWrapperMessage>
						<StyledConfirmRemoveLiveWidget>
							{t('content.widget_manager.popup.message_remove_live_not_use')}
						</StyledConfirmRemoveLiveWidget>
						<StyledConfirmRemoveLiveWidget>
							{t('content.widget_manager.popup.note_remove_widget')}
						</StyledConfirmRemoveLiveWidget>
					</StyledWrapperMessage>
				);
			} else {
				let listCampaignRender: CampaignItemDto[] = [...listCampaign];
				if (listCampaign?.length > 10) {
					listCampaignRender = listCampaign?.slice(0, 10);
				}
				return (
					<StyledWrapperMessage>
						<StyledConfirmRemoveLiveWidget>
							{t('content.widget_manager.popup.message_remove_live_use')}
						</StyledConfirmRemoveLiveWidget>
						{listCampaignRender?.map((item: CampaignItemDto) => (
							<StyledNameCampaign key={item?.id}>{item?.name}</StyledNameCampaign>
						))}
						{listCampaign?.length > 10 && (
							<StyledNameCampaign>
								{t('content.widget_manager.popup.more_item')}
							</StyledNameCampaign>
						)}
						<StyledSubRemove>
							{t('content.widget_manager.popup.sub_continue')}
						</StyledSubRemove>
					</StyledWrapperMessage>
				);
			}
		}
		return '';
	};

	const otherPropConfirm = useMemo(() => {
		const result: any = {};
		if (typeConfirm === TYPE_ACTIONS.EDIT_DESIGN) {
			result.onClickBtnCancel = handleStartOverDraft;
			result.cancelText = t('content.widget_manager.button.start_over');
			result.okText = t('content.widget_manager.button.continue_draft');
			result.onSubmit = handleConfirmPopup;
		}
		if (typeConfirm === TYPE_ACTIONS.REMOVE) {
			result.cancelText = t('content.widget_manager.button.delete');
			result.okText = t('content.widget_manager.button.cancel');
			result.onClickBtnCancel = handleConfirmPopup;
			result.onSubmit = onCloseWidgetConfirm;
		}
		return result;
	}, [typeConfirm]);

	const handleList = () => {
		let resultListFilter: Array<WidgetBuilderDetailRequestDto> = [];
		if (filter?.status === TAB_WIDGET.draft) {
			widgetManagers?.forEach((item: WidgetBuilderDetailRequestDto) => {
				if (!item.referenceId) {
					resultListFilter.push({ ...item });
				}
			});
		} else {
			resultListFilter = [...widgetManagers];
		}
		const newList =
			filter?.page === 1
				? [...resultListFilter]
				: [...listWidgetManager, ...resultListFilter];

		setListWidgetManagers(newList);
		if (!widgetSelect) {
			setWidgetSelect(newList[0] || null);
		}
	};

	const handleResetList = () => {
		setWidgetSelect(null);
		setListWidgetManagers([]);
		dispatch(updateStoreWidget({ widgetManagers: [], widgetBuilder: null }));
	};

	const handleCheckMessageRemove = () => {
		if (widgetSelect?.status === TAB_WIDGET.draft) {
			return setTypeConfirm(TYPE_ACTIONS.REMOVE);
		}
		setIsLoading(true);
		getRequest(GET_LIST_WIDGET_CAMPAIGN, { widgetId: widgetSelect?.id })
			.then((response) => {
				setIsLoading(false);
				if (response.status === 200) {
					if (response.body.data?.result) {
						setListCampaign(response.body.data?.result);
					}
				}
			})
			.catch((err) => {
				setIsLoading(false);
				message.error(err);
			})
			.finally(() => {
				setTypeConfirm(TYPE_ACTIONS.REMOVE);
			});
		return null;
	};

	const handleSetDefaultWidget = () => {
		if (widgetSelect) {
			const dataRequest: WidgetBuilderDetailRequestDto = {
				...widgetSelect,
				isDefault: true,
			};
			const callBack = () => {
				message.success(t('content.widget_manager.message.change_default_success'));
				setWidgetSelect(dataRequest);
				const idx = listWidgetManager?.findIndex((item) => item?.id === widgetSelect?.id);
				const idxDefault = listWidgetManager?.findIndex((item) => item?.isDefault);

				const newList: Array<WidgetBuilderDetailRequestDto> = [...listWidgetManager];
				if (idx !== -1) {
					newList[idx].isDefault = true;
					if (idxDefault !== -1) {
						newList[idxDefault].isDefault = false;
					}

					setListWidgetManagers(newList);
					dispatch(clientSettingsRequest());
				}
			};

			dispatch(saveDraftWidgetBuilderRequest({ dataRequest, callBack }));
		}
	};

	const handleDuplicate = () => {
		if (widgetSelect) {
			const { metadata, name } = widgetSelect;
			const metaDataObj: MetadataWidgetBuilder = JSON.parse(metadata);

			const dataRequest: WidgetBuilderDetailNoIdDto = {
				...DEFAULT_WIDGET_BUILDER,
				metadata: metaDataObj,
				name: `${t('content.widget_manager.button.copy_of')} ${name}`,
			};
			dataRequest.metadata = {
				...dataRequest?.metadata,
				otherInfo: { ...dataRequest?.metadata?.otherInfo },
			};

			handleCreateNewWidgetBuilder(dataRequest);
		}
	};

	useEffect(() => {
		dispatch(fetchTemplateDefaultRequest());
		return () => {
			handleResetList();
		};
	}, []);
	useEffect(() => {
		if (filter?.page === 1) {
			handleResetList();
		}
		dispatch(fetchListWidgetRequest({ ...filter }));
	}, [filter]);

	useEffect(() => {
		handleList();
	}, [widgetManagers]);

	useEffect(() => {
		const listStatus = [
			statusDraftWidgetBuilder,
			statusGetWidgetDetail,
			statusRemoveWidget,
			statusCreateWidgetBuilder,
			resumeLiveDraftStatus,
			cloneLiveWidgetStatus,
		];

		if (listStatus?.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}

		if (listStatus?.includes(FAILED)) {
			message.error(error);
		}
		if (listStatus.includes(SUCCEEDED) || listStatus.includes(FAILED)) {
			setIsLoading(false);
		}
	}, [
		statusDraftWidgetBuilder,
		statusGetWidgetDetail,
		statusRemoveWidget,
		statusCreateWidgetBuilder,
		resumeLiveDraftStatus,
		cloneLiveWidgetStatus,
	]);

	useEffect(() => {
		if (statusRemoveWidget === SUCCEEDED && widgetSelect) {
			message.success(t('content.widget_manager.message.remove_success'));
			handleUpdateListAfterRemove(widgetSelect?.id);

			if (widgetSelect?.status === TAB_WIDGET.live) {
				dispatch(clientSettingsRequest());
			}
		}

		if (statusRemoveWidget === SUCCEEDED || statusRemoveWidget === FAILED) {
			dispatch(removeWidgetBuilderEnd());
		}
		if (statusDraftWidgetBuilder === SUCCEEDED || statusDraftWidgetBuilder === FAILED) {
			dispatch(saveDraftWidgetBuilderEnd());
		}
		if (statusGetWidgetDetail === SUCCEEDED || statusGetWidgetDetail === FAILED) {
			dispatch(fetchDetailWidgetBuilderEnd());
		}

		if (
			(resumeLiveDraftStatus === SUCCEEDED || cloneLiveWidgetStatus === SUCCEEDED) &&
			widgetDraftRes
		) {
			history.push(`${ROUTE_PATH.WIDGET_MANAGER}/${widgetDraftRes.id}`);
		}

		if (resumeLiveDraftStatus === SUCCEEDED || resumeLiveDraftStatus === FAILED) {
			dispatch(resumeLiveDraftEnd());
		}
		if (cloneLiveWidgetStatus === SUCCEEDED || cloneLiveWidgetStatus === FAILED) {
			dispatch(cloneLiveWidgetEnd());
		}
	}, [
		statusDraftWidgetBuilder,
		statusGetWidgetDetail,
		statusRemoveWidget,
		statusCreateWidgetBuilder,
		resumeLiveDraftStatus,
		cloneLiveWidgetStatus,
	]);

	useEffect(() => {
		if (statusCreateWidgetBuilder === SUCCEEDED) {
			if (widgetBuilder) {
				handleRedirectDraftWidget(widgetBuilder?.id);
			}
		}
		if (statusCreateWidgetBuilder === SUCCEEDED || statusCreateWidgetBuilder === FAILED) {
			dispatch(createDraftWidgetBuilderEnd());
			setIsLoading(false);
		}
	}, [statusCreateWidgetBuilder]);

	const valueContext = {
		hideDetail,
		setFilter,
		filter,
		setHideDetail,
		widgetSelect,
		setWidgetSelect,
		listWidget: listWidgetManager,
		setListWidgetManagers,
		isMobile,
		setIsMobile,
		handleCreateNewWidgetBuilder,
		setTypeConfirm,
		setIsLoading,
		setListCampaign,
		listCampaign,
		handleCheckMessageRemove,
		handleSetDefaultWidget,
		handleDuplicate,
	};

	useEffect(() => {
		if (stateNotification?.arrayId) {
			setFilter({
				...filter,
				page: 1,
				status: TAB_WIDGET.live,
				widgetIds: stateNotification.arrayId,
			});

			if (stateNotification?.arrayId?.length > 0) {
				dispatch(updateStatusRecentlyUpdatedRequest(stateNotification.arrayId));
			}
			history.replace({ state: null });
		}
	}, [stateNotification]);

	return (
		<WidgetManagerContext.Provider value={valueContext}>
			<LoadingWrapper
				isLoading={isLoading || fetchingWidgetManager || loadingTemplateDefault}>
				<Row gutter={[16, 16]}>
					<StyledWrapper span={16}>
						<ViewDetailWidget />
					</StyledWrapper>
					<StyledWrapper span={8}>
						<CurrentDesign />
					</StyledWrapper>
				</Row>
				<ConfirmModal
					width={450}
					maskClosable={true}
					isOpen={typeConfirm ? true : false}
					onClose={onCloseWidgetConfirm}
					{...otherPropConfirm}>
					{renderContentPopupConfirm()}
				</ConfirmModal>
			</LoadingWrapper>
		</WidgetManagerContext.Provider>
	);
};

export default SettingWidgetManager;
