import {
	DEFAULT_SIZE_IFRAME,
	PARAMS_BASIC,
	TAB_GALLERY,
	TYPE_GET_CONTENT,
} from '@constants/publish/gallery';
import { AlbumRespDto } from '@models/content/albums/albumManager';
import { ContentDto } from '@models/content/contentLibrary/summary';
import {
	FolderGalleryDto,
	ParamRequestContentGallery,
	ParamRequestFolderDto,
	SizeIframe,
} from '@models/publishing/galleries';
import * as React from 'react';

export type GalleryManagerContextType = {
	gallerySelect: FolderGalleryDto | null;
	handleChangeGallerySelect: (val: any) => void;
	setListFolderGallery: (val: any) => void;
	listFolderGallery: Array<FolderGalleryDto>;
	listContent: Array<ContentDto>;
	setParamContent: (val: any) => void;
	paramContent: ParamRequestContentGallery;
	setVisiblePreview: (val: boolean) => void;
	visiblePreview: boolean;
	setActiveTabGallery: (val: string) => void;
	activeTabGallery: string;
	setTypeConfirm: (val: string) => void;
	typeGetContent: string;
	setTypeGetContent: (val: string) => void;
	paramFolder: ParamRequestFolderDto;
	setParamFolder: (val: any) => void;
	setListContent: (val: Array<ContentDto>) => void;
	albumSelect: AlbumRespDto | null;
	setAlbumSelect: (val: AlbumRespDto | null) => void;
	onAssignAlbumToGallery: (id?: number) => void;
	setContentIdSelected: (id: number | null) => void;
};

// Default context values
const values: GalleryManagerContextType = {
	gallerySelect: null,
	handleChangeGallerySelect: () => {},
	listFolderGallery: [],
	listContent: [],
	setParamContent: () => {},
	setListFolderGallery: () => {},
	setVisiblePreview: () => {},
	visiblePreview: false,

	activeTabGallery: TAB_GALLERY.preview,
	setActiveTabGallery: () => {},
	setTypeConfirm: () => {},
	typeGetContent: TYPE_GET_CONTENT.VIEW,
	setTypeGetContent: () => {},

	paramContent: {
		isVerified: true,
		galleryId: null,
	},
	paramFolder: { ...PARAMS_BASIC },
	setParamFolder: () => {},
	setListContent: () => {},
	albumSelect: null,
	setAlbumSelect: () => {},
	onAssignAlbumToGallery: () => {},
	setContentIdSelected: () => {},
};

const GalleryManagerContext = React.createContext(values);
export default GalleryManagerContext;
