import THEME from '@constants/themes/themes';
import { Form, Rate, Select } from 'antd';
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

// Styled
import {
	StyledBtn,
	StyledSection,
	StyledTitle,
} from '@styled/Content/ContentLibrary/ContentStyled';
import {
	StyledContainer,
	StyledContentActions,
	StyledFormItem,
	StyledRemovableLabels,
	StyledSelect,
	StyledText,
	StyledTextArea,
} from '@styled/Content/ContentLibrary/FilterStyled';

// Icons
import {
	IconCampaign,
	IconCheckCircle,
	IconClose,
	IconFlag,
	IconGallery,
	IconLabel,
	IconNote,
	IconReward,
	IconSend,
} from '@assets/icons';
import { StyledBtnCustom } from '@styled/Content/ContentLibrary/ActionBarStyled';

// Models
import { PATTERNS } from '@constants/common';
import {
	CHANGED_DETAILS_CONTENT,
	MARK_AS,
	MAX_LENGTH_CHARACTER,
	SELECT_TAGS,
} from '@constants/content/contentLibrary';
import { filterGalleryUnAssign } from '@helpers/arrayHelper';
import { StateCampaign } from '@models/campaign';
import { RefContentActions } from '@models/content/contentLibrary/ref';
import { ContentFiltersDto, ContentReviewDto } from '@models/content/contentLibrary/summary';
import { FolderDto } from '@models/settings/content';
import { SettingRootStateDto } from '@models/settings/setting';
import { validateInputToPattern } from '@utils/common';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Option } = Select;

type ContentActionsProps = {
	closeActions: () => void;
	handleActionContents: (reviewValues: ContentReviewDto) => void;
	isHidden?: boolean;
	hiddenRewardAndMessage?: boolean;
	isBookmark?: boolean;
};

const ContentActions = forwardRef((props: ContentActionsProps, ref: Ref<RefContentActions>) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const filters: ContentFiltersDto = {
		markedAs: [],
		star: 0,
		labels: [],
		flags: [],
		campaigns: [],
		note: '',
		galleries: [],
	};

	// Props
	const {
		closeActions,
		handleActionContents,
		isHidden = false,
		hiddenRewardAndMessage = false,
		isBookmark,
	} = props;

	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);

	const { folders = [] } = useSelector((state: SettingRootStateDto) => state.settings.content);

	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { flags = [], labels = [] } = clientSettings;

	const [galleryList, setGalleryList] = useState<FolderDto[]>([]);

	const markedAsStatus = isBookmark
		? [MARK_AS.CONTACTED.label, MARK_AS.PENDING.additionalLabel]
		: [MARK_AS.CONTACTED.label, MARK_AS.PENDING.additionalLabel, MARK_AS.AFFILIATE.label];

	const formItemSelectTagsRemove = [
		{
			name: SELECT_TAGS.STATUS.name,
			head: {
				icon: <IconCheckCircle />,
				title: SELECT_TAGS.STATUS.title,
			},
			options: markedAsStatus,
		},
		{
			name: SELECT_TAGS.LABELS.name,
			head: {
				icon: <IconLabel />,
				title: SELECT_TAGS.LABELS.title,
			},
			options: labels || [],
		},
		{
			name: SELECT_TAGS.FLAGS.name,
			head: {
				icon: <IconFlag />,
				title: SELECT_TAGS.FLAGS.title,
			},
			options: flags || [],
		},
		{
			name: SELECT_TAGS.CAMPAIGNS.name,
			head: {
				icon: <IconCampaign />,
				title: SELECT_TAGS.CAMPAIGNS.title,
			},
			options: campaignListContent || [],
		},
		{
			name: SELECT_TAGS.GALLERIES.name,
			head: {
				icon: <IconGallery />,
				title: SELECT_TAGS.GALLERIES.title,
			},
			options: galleryList || [],
			hidden: !!isBookmark,
		},
	];

	const formItemSelectTags = [
		{
			name: CHANGED_DETAILS_CONTENT.NOTE.name,
			head: {
				icon: <IconNote />,
				title: CHANGED_DETAILS_CONTENT.NOTE.title,
			},
			options: [],
		},
	];

	const [changeRating, setChangeRating] = useState<boolean>(false);
	const [changeNote, setChangeNote] = useState<boolean>(false);
	const [isRemoveLabels, setIsRemoveLabels] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		resetFilters,
	}));

	useEffect(() => {
		if (folders?.length > 0) {
			setGalleryList(filterGalleryUnAssign(folders));
		}
	}, [folders]);

	const renderLabel = (icon: any, text: any, hasSub?: boolean) => {
		let textLabel: string = '';

		const field = { name: text };
		if (!hasSub) {
			textLabel = text;
		} else if (text === CHANGED_DETAILS_CONTENT.NOTE.title) {
			textLabel = t('content_management.add_a', field);
		} else if (!isRemoveLabels) {
			switch (text) {
				case SELECT_TAGS.CAMPAIGNS.title:
					textLabel = t('content_management.assign_to', field);
					break;
				case SELECT_TAGS.GALLERIES.title:
					textLabel = t('content_management.display_in', field);
					break;
				default:
					textLabel = t('content_management.add_label', field);
					break;
			}
		} else {
			if (text === SELECT_TAGS.CAMPAIGNS.title || text === SELECT_TAGS.GALLERIES.title) {
				textLabel = t('content_management.remove_form', field);
			} else {
				textLabel = t('content_management.remove_label', field);
			}
		}
		return (
			<StyledTitle>
				{icon}
				<StyledText fontSize="1rem">{textLabel}</StyledText>
			</StyledTitle>
		);
	};

	const removeLabels = (checked: boolean) => {
		setIsRemoveLabels(checked);
	};

	const onchange = (changedValue: React.ChangeEvent<JSX.Element>) => {
		if (Object.keys(changedValue)[0] === 'star') {
			setChangeRating(true);
		}

		if (Object.keys(changedValue)[0] === 'note') {
			setChangeNote(true);
		}
	};

	const onFinish = (values: ContentReviewDto) => {
		if (!changeRating) {
			delete values.star;
		}

		if (!changeNote) {
			delete values.note;
		}

		setChangeRating(false);
		handleActionContents({ ...values, isRemove: isRemoveLabels });
	};

	const resetFilters = () => {
		form.resetFields();
	};

	// Render components
	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const renderListOption = (options: any[], name: string) => {
		if (name === SELECT_TAGS.CAMPAIGNS.name) {
			return options?.map((option) => ({
				label: option?.name,
				value: option?.id.toString(),
			}));
		}
		if (name === SELECT_TAGS.GALLERIES.name) {
			return options?.map((option) => ({
				label: option?.name,
				value: option?.id.toString(),
			}));
		}
		return options?.map((option) => ({ label: option, value: option }));
	};

	return (
		<StyledContentActions className={`${isHidden ? 'hidden' : ''} filter-content`}>
			<StyledSection borderPosition="bottom" heightSection="54px" flexDirection="row">
				<StyledTitle fontSize="1.143rem" fontWeight="600">
					{t('content_management.mark_up_content')}
				</StyledTitle>

				<StyledTitle
					onClick={closeActions}
					cursor="pointer"
					fontSize="0.857rem"
					color={THEME.colors.gray3}>
					<IconClose />
				</StyledTitle>
			</StyledSection>

			<StyledContainer>
				<Form
					form={form}
					layout="vertical"
					initialValues={filters}
					onValuesChange={onchange}
					onFinish={onFinish}>
					<StyledFormItem
						className="form_rate_content"
						name={CHANGED_DETAILS_CONTENT.STAR.name}
						label={renderLabel(null, CHANGED_DETAILS_CONTENT.STAR.title)}>
						<Rate />
					</StyledFormItem>

					<StyledRemovableLabels right="8px">
						{formItemSelectTagsRemove.map((item) => {
							const { name, head, options } = item;
							if (item?.hidden) {
								return null;
							}

							return (
								<StyledFormItem
									key={name}
									name={name}
									label={renderLabel(head.icon, head.title, true)}>
									<StyledSelect
										mode={
											name === SELECT_TAGS.LABELS.name ||
											name === SELECT_TAGS.FLAGS.name
												? 'tags'
												: 'multiple'
										}
										showArrow={true}
										notFoundContent={notFoundContent()}
										getPopupContainer={(triggerNode: HTMLElement) =>
											triggerNode.parentNode as HTMLElement
										}
										virtual={false}
										optionFilterProp="label"
										options={renderListOption(options, name)}
										dropdownClassName="dropdown-menu dropdown-custom-content"
										onKeyDown={(
											event: React.KeyboardEvent<HTMLInputElement>,
										) => {
											if (name === SELECT_TAGS.FLAGS.name) {
												validateInputToPattern(event, PATTERNS.FLAG);
											}
										}}
									/>
								</StyledFormItem>
							);
						})}
						<StyledTitle className="remove-labels">
							<StyledText
								className="label-change_tag"
								onClick={() => setIsRemoveLabels(!isRemoveLabels)}
								fontSize="1rem">
								{!isRemoveLabels
									? t('content_management.remove_tags')
									: t('content_management.add_tags')}
							</StyledText>
						</StyledTitle>
					</StyledRemovableLabels>

					{formItemSelectTags.map((item) => {
						const { name, head, options } = item;

						if (name === CHANGED_DETAILS_CONTENT.NOTE.name) {
							return (
								<StyledFormItem
									key={name}
									name={name}
									label={renderLabel(head.icon, head.title, true)}>
									<StyledTextArea maxLength={MAX_LENGTH_CHARACTER} rows={1} />
								</StyledFormItem>
							);
						}

						return (
							<StyledFormItem
								key={name}
								name={name}
								label={renderLabel(head.icon, head.title)}>
								<StyledSelect
									mode="multiple"
									showArrow={true}
									notFoundContent={notFoundContent()}
									getPopupContainer={(triggerNode: HTMLElement) =>
										triggerNode.parentNode as HTMLElement
									}
									dropdownClassName="dropdown-menu">
									{options.map((option: string) => (
										<Option key={option} value={option}>
											{option}
										</Option>
									))}
								</StyledSelect>
							</StyledFormItem>
						);
					})}
				</Form>
			</StyledContainer>

			<StyledSection borderPosition="top" heightSection="64px" flexDirection="row">
				<StyledBtn onClick={() => form.submit()}>
					{t('content_management.save_changes')}
				</StyledBtn>
				{!hiddenRewardAndMessage && !isBookmark && (
					<>
						<StyledBtnCustom icon={<IconReward />} type="ghost">
							{t('button.reward')}
						</StyledBtnCustom>
						<StyledBtnCustom icon={<IconSend />} type="ghost">
							{t('button.message')}
						</StyledBtnCustom>
					</>
				)}
			</StyledSection>
		</StyledContentActions>
	);
});

export default ContentActions;
