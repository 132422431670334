import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { Radio, RadioChangeEvent, Space } from 'antd';

import {
	StyledBtn,
	StyledSection,
	StyledTitle,
} from '@styled/Content/ContentLibrary/ContentStyled';
import { IconClose } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { StyledContainer } from '@styled/Messages/EmailBuilderStyled';
import { StyledPopconfirm } from '@styled/Content/ContentLibrary/FilterStyled';
import { EmailTemplateDto } from '@models/messages/summary';
import EmailBuilderContext from '@contexts/Messages/EmailBuilder';

const StyledContainerMessage = styled.div`
	box-shadow: 0 -10px 30px 20px rgba(0, 0, 0, 0.25);
	height: 100%;
`;

const StyledRadio = styled(Radio.Group)`
	padding: 16px;

	p {
		margin: 0;
		font-size: 0.857rem;
	}

	.ant-radio-wrapper {
		width: 100%;
		margin: 0 0 6px 0;
		padding: 16px;
		border-radius: 5px;
		background-color: ${THEME.colors.darkBlue3};

		&.ant-radio-wrapper-checked {
			border: 1px solid ${THEME.colors.orangeBase};
		}
	}

	.ant-radio-inner,
	.ant-radio-button-wrapper {
		background-color: ${THEME.colors.darkBlue3};
	}
`;

const StyledSpace = styled(Space)`
	width: 100%;
`;

const StyledMessageTemplates = styled.div``;

type ChooseTemplateProps = {
	handleClose: () => void;
	handleTemplate: (template: any, isApply?: boolean) => void;
	template: any;
	originalTemplate: any;
};

const ChooseTemplate = (props: ChooseTemplateProps) => {
	const { t } = useTranslation();

	// Reducers
	const { templates } = useSelector((state: any) => state.messages);
	const { emailRequest } = useContext(EmailBuilderContext);

	const {
		handleClose,
		handleTemplate,
		template,
		originalTemplate: originalTemplateProps,
	} = props;

	const [currentTemplate, setCurrentTemplate] = useState<EmailTemplateDto>(template);
	const [originalTemplate, setOriginalTemplate] = useState<any>();
	const [templateIndex, setTemplateIndex] = useState<number>();

	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [isApplyTemplate, setIsApplyTemplate] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setOriginalTemplate(originalTemplateProps);
	}, []);

	useEffect(() => {
		if (!isApplyTemplate) {
			if (_.isUndefined(templateIndex)) {
				if (!_.isEmpty(template) && 'id' in template) {
					setOriginalTemplate(template);
				} else if (!_.isEmpty(template) && !('id' in template)) {
					setOriginalTemplate({
						body: emailRequest.body,
						subject: emailRequest.subject,
					});
				}
			}
		}
	}, [emailRequest]);

	const onChangeTemplate = (evt: RadioChangeEvent) => {
		const { value } = evt.target;
		const templateIndex = templates.findIndex((item: any) => item.id === Number(value));
		setCurrentTemplate(templates[templateIndex]);
		setTemplateIndex(templateIndex);
		handleTemplate(templates[templateIndex]);
	};

	const handlePopup = () => {
		setIsVisible(!isVisible);

		if (!isVisible) {
			setIsLoading(false);
		}
	};

	const onApplyTemplate = () => {
		setIsApplyTemplate(true);
		setIsLoading(true);
		handlePopup();
		handleClose();
	};

	const closeModal = () => {
		handleClose();

		if (!isApplyTemplate) {
			handleTemplate(originalTemplate);
		} else {
			handleTemplate(currentTemplate);
		}
	};

	return (
		<StyledContainerMessage>
			<StyledSection
				borderPosition="bottom"
				heightSection="54px"
				flexDirection="row"
				padding="16px 16px 16px 24px">
				<StyledTitle fontSize="1.143rem" fontWeight="600">
					{t('message_templates.title')}
				</StyledTitle>
				<IconClose className="icon-close" onClick={closeModal} />
			</StyledSection>

			<StyledMessageTemplates>
				<StyledContainer subtractedHeight={248}>
					<StyledRadio
						value={!_.isEmpty(currentTemplate) ? currentTemplate.id : -1}
						onChange={onChangeTemplate}>
						<StyledSpace direction="vertical">
							{templates &&
								templates.length > 0 &&
								templates.map((item: any) => (
									<Radio key={item.id} value={item.id}>
										<p>{item.title}</p>
									</Radio>
								))}
						</StyledSpace>
					</StyledRadio>
				</StyledContainer>
				<StyledSection
					width="100%"
					padding="16px"
					borderPosition="top"
					heightSection="64px"
					flexDirection="row"
					justify="flex-end">
					<StyledPopconfirm
						visible={isVisible}
						title={t('email_builder.apply_template_confirm')}
						overlayClassName="popconfirm-save-filters"
						okButtonProps={{ loading: isLoading }}
						// cancelButtonProps={{ className: cancelBtnClass }}
						onConfirm={() => onApplyTemplate()}
						onCancel={() => handlePopup()}
						okText="Apply"
						cancelText={t('button.cancel')}>
						<StyledBtn width="160px" onClick={handlePopup}>
							{t('button.apply_template')}
						</StyledBtn>
					</StyledPopconfirm>
				</StyledSection>
			</StyledMessageTemplates>
		</StyledContainerMessage>
	);
};

export default ChooseTemplate;
