export type UserType = {
	id: number | string;
	clientId: number | string;
	email: string;
	brandName: string;
	username: string;
	firstName: string;
	lastName: string;
	hashtags: string;
	subHashtags: string;
	role: 'CADMIN' | 'EADMIN';
	status: 'Active' | 'Deactive';
};

export type SegmentsDto = {
	key: number;
	id: number;
	title: string;
	totalCreatorProfiles: number;
};

export type UserStoreType = {
	fetchingUser: boolean;
	user: UserType | null;
	error: null | string;
	creatorProfile: any;
};

// Get Reward By Creator
const getRewardByCreatorTypes = {
	GET_REWARD_BY_CREATOR_REQUEST: 'GET_REWARD_BY_CREATOR_REQUEST',
	GET_REWARD_BY_CREATOR_SUCCEEDED: 'GET_REWARD_BY_CREATOR_SUCCEEDED',
	GET_REWARD_BY_CREATOR_FAILED: 'GET_REWARD_BY_CREATOR_FAILED',
	GET_REWARD_BY_CREATOR_END: 'GET_REWARD_BY_CREATOR_END',
};

// Update Reward Status
const updateRewardStatusTypes = {
	UPDATE_REWARD_STATUS_REQUEST: 'UPDATE_REWARD_STATUS_REQUEST',
	UPDATE_REWARD_STATUS_SUCCEEDED: 'UPDATE_REWARD_STATUS_SUCCEEDED',
	UPDATE_REWARD_STATUS_FAILED: 'UPDATE_REWARD_STATUS_FAILED',
	UPDATE_REWARD_STATUS_END: 'UPDATE_REWARD_STATUS_END',
};

// Log Reward
const logRewardTypes = {
	LOG_REWARD_REQUEST: 'LOG_REWARD_REQUEST',
	LOG_REWARD_SUCCEEDED: 'LOG_REWARD_SUCCEEDED',
	LOG_REWARD_FAILED: 'LOG_REWARD_FAILED',
	LOG_REWARD_END: 'LOG_REWARD_END',
};

// Store Reward Data
const storeRewardDataTypes = {
	STORE_REWARD_DATA_REQUEST: 'STORE_REWARD_DATA_REQUEST',
};

export { getRewardByCreatorTypes, updateRewardStatusTypes, logRewardTypes, storeRewardDataTypes };
