import { CustomHtmlSVG } from '@assets/icons/svg/grapesjs';

export const CustomHTMLBlock = (bm: any, label: string) => {
	bm.add('custom-code', {
		label,
		media: CustomHtmlSVG,
		activate: true,
		content: {
			type: 'custom-code',
		},
	});
};
