import { IconRemove } from '@assets/icons';
import {
	StyledFileItem,
	StyledListFile,
} from '@components/Content/ContentLibrary/ModalImportContent';
import CustomSwitch from '@components/CustomSwitch';
import { TYPE_ACTIONS } from '@constants/common';
import { ACCEPT_FILES } from '@constants/content/contentLibrary';
import { FONT_FAMILY_FORMAT, UPLOADER_FIELD_NAME } from '@constants/content/widgetManager';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import UploadMultiFile from '@cores/Upload/UploadMultiFile';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import { uploadFontBuilderEnd } from '@stores/actions';
import {
	StyledCol,
	StyledIcon,
	StyledLinkHref,
	StyledRow,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledLabelField } from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { getFileNameFromUrl } from '@utils/funcHelper';
import { message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type PropTypes = {
	handleActionStep: () => void;
};

const CustomFonts = (props: PropTypes) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		statusUploadLogo,
		statusUploadFontBuilder,
		urlFontBuilders,
		urlFile = '',
		error = '',
	}: WidgetManagerStoreDto = useSelector((state: any) => state?.widgetManager);
	const { detailWidget, setIsLoading, setDetailWidget } = useContext(WidgetBuilderContext);

	const { metadata } = detailWidget;
	const { enableCustomFontFamily, fileFontUrls, fileFontUploads = [] } = metadata?.colors || {};

	const { handleActionStep } = props;

	const refUpload = useRef<any>(false);
	const refClickUploadFont = useRef<any>({});
	const [typeAction, setTypeAction] = useState<string>('');

	useEffect(() => {
		if (statusUploadFontBuilder === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (statusUploadFontBuilder === SUCCEEDED && urlFontBuilders) {
			delete detailWidget?.metadata?.colors?.fileFontUploads;
			setDetailWidget((prev: WidgetBuilderDetailDto) => ({
				...prev,
				metadata: {
					...prev.metadata,
					colors: {
						...prev.metadata.colors,
						fileFontUrls: urlFontBuilders,
					},
				},
			}));
			setTimeout(() => {
				setTypeAction(TYPE_ACTIONS.UPLOAD_FONT);
			}, 1000);
		}
		if (statusUploadFontBuilder === FAILED) {
			message.error(error);
		}
		if (statusUploadFontBuilder === SUCCEEDED || statusUploadFontBuilder === FAILED) {
			dispatch(uploadFontBuilderEnd());
			setIsLoading(false);
		}
	}, [statusUploadFontBuilder]);

	useEffect(() => {
		if (typeAction === TYPE_ACTIONS.UPLOAD_FONT) {
			setTypeAction('');
			handleActionStep();
		}
	}, [typeAction]);

	const handleEnableOtherSources = (val: boolean, fieldName: string) => {
		const other: any = { [fieldName]: val };
		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				colors: {
					...prev.metadata.colors,
					...other,
				},
			},
		}));
	};

	const onSaveFileFonts = async (files: any[]) => {
		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				colors: {
					...prev.metadata.colors,
					fileFontUploads: files,
				},
			},
		}));
	};

	const onRemoveAllFonts = () => {
		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				colors: {
					...prev.metadata.colors,
					fileFontUrls: [],
				},
			},
		}));
	};

	const onBeforeUploadFont = (values: any, fileList: any) => {
		if (!refUpload.current) {
			refUpload.current = true;

			const fileFormat: any[] = [];
			fileList.forEach((file: any) => {
				if (FONT_FAMILY_FORMAT.some((f) => file.name.includes(f.type))) {
					fileFormat.push(file);
				}
			});
			let mess = '';

			const filesChange = [...fileFontUploads, ...fileFormat];

			if (fileFormat.length < fileList.length) {
				mess = t('content.widget_manager.widget_builder.file_type_upload', {
					val: t('content.widget_manager.widget_builder.accept_font_family'),
				});
			}
			if (mess) {
				message.warning(mess);
			}

			onSaveFileFonts(filesChange);
			setTimeout(() => {
				refUpload.current = false;
			}, 0);
		}
		return false;
	};

	const onUploadFontFiles = (e: any) => {
		const { files = [] } = e.target;

		onBeforeUploadFont(null, Array.from(files));
	};

	const renderMoreFile = (
		files: any[],
		ref: any,
		accept: string,
		onChange: (val: any) => void,
	) => {
		const qualityFile = files.length;

		return (
			<StyledWrapperAction>
				<input
					style={{ display: 'none' }}
					ref={ref}
					accept={accept}
					onChange={onChange}
					type="file"
					multiple={true}
				/>
				<StyledTitle className="unset_height" fontSize="14px">
					{t('content.import_modal.file_selected', {
						val: qualityFile,
						more: qualityFile !== 1 ? 's' : '',
					})}
				</StyledTitle>
				<StyledLinkHref
					href=""
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						e.preventDefault();
						e.stopPropagation();

						if (ref.current) {
							ref.current.click();
						}
					}}
					margin="0 0 0 8px"
					className="underline"
					color={THEME.colors.gray5}>
					{t('button.add_more')}
				</StyledLinkHref>
			</StyledWrapperAction>
		);
	};

	const renderListFile = (
		files: UploadFile<any>[],
		setFiles: (val: UploadFile<any>[]) => void,
		disabledRemove?: boolean,
	) => {
		return (
			<StyledListFile className="custom_scroll_bar">
				{files.map((f, idx) => (
					<StyledFileItem key={Math.random()}>
						<StyledTitle
							maxWidth="calc(100% - 32px)"
							className="overflow_text unset_height">
							{typeof f === 'string' ? getFileNameFromUrl(f) : f.name}
						</StyledTitle>
						{!disabledRemove && (
							<StyledIcon
								size={20}
								className="absolute icon_hover"
								top="0"
								onClick={() => {
									const listFile = [
										...files.slice(0, idx),
										...files.slice(idx + 1),
									];
									setFiles(listFile);
								}}
								right="0">
								<IconRemove />
							</StyledIcon>
						)}
					</StyledFileItem>
				))}
			</StyledListFile>
		);
	};

	const renderUploadFileFontFamily = () => {
		const qualityFile = fileFontUploads ? fileFontUploads?.length : 0;
		const accept = '*' || ACCEPT_FILES.FONT_FAMILY3;
		if (qualityFile > 0) {
			return renderMoreFile(fileFontUploads, refClickUploadFont, accept, onUploadFontFiles);
		}
		return (
			<UploadMultiFile
				multiple
				textUpload={
					<StyledTitle className="unset_height" color={THEME.colors.gray1}>
						{t('content.widget_manager.widget_builder.upload_font_family')}
					</StyledTitle>
				}
				beforeUpload={onBeforeUploadFont}
				height={48}
				accept={accept}
			/>
		);
	};

	return (
		<StyledWrapperContent>
			<StyledWrapperContent margin="16px 0 0 0">
				<StyledRow margin="12px 0 0 0">
					<StyledCol span={16}>
						<StyledLabelField style={{ marginRight: '24px' }}>
							{t('content.widget_manager.widget_builder.enable_upload_font_family')}
						</StyledLabelField>
					</StyledCol>
					<StyledCol>
						<CustomSwitch
							checked={enableCustomFontFamily}
							setChecked={(val: boolean) =>
								handleEnableOtherSources(
									val,
									UPLOADER_FIELD_NAME.ENABLE_CUSTOM_FONT_FAMILY,
								)
							}
						/>
					</StyledCol>
				</StyledRow>
			</StyledWrapperContent>
			{enableCustomFontFamily && (
				<>
					{fileFontUrls && fileFontUrls.length > 0 ? (
						<StyledTitle
							className="unset_height"
							cursor="pointer"
							onClick={() => onRemoveAllFonts()}
							margin={fileFontUrls && fileFontUrls.length > 0 ? '8px 0' : '12px 0'}
							color={THEME.colors.redBase}>
							{t('button.remove_all')}
						</StyledTitle>
					) : (
						<StyledWrapperContent
							margin={
								fileFontUploads && fileFontUploads.length > 0 ? '8px 0' : '12px 0'
							}>
							{renderUploadFileFontFamily()}
						</StyledWrapperContent>
					)}
					{fileFontUploads &&
						fileFontUploads.length > 0 &&
						renderListFile(fileFontUploads, onSaveFileFonts)}
					{fileFontUrls &&
						fileFontUrls.length > 0 &&
						renderListFile(fileFontUrls, () => {}, true)}
				</>
			)}
		</StyledWrapperContent>
	);
};

export default CustomFonts;
