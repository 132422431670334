// Compoents
import { IconDelete } from '@assets/icons';
import AccountGuestList from '@components/Settings/AccountUser/AccountGuestList';
import AccountUserList from '@components/Settings/AccountUser/AccountUserList';
import InviteGuest from '@components/Settings/AccountUser/InviteGuest';
import InviteUser from '@components/Settings/AccountUser/InviteUser';
import { CONFIG_PORTAL, DURATION, MESSAGE_TYPE } from '@constants/common';
import { Paginator } from '@constants/paginator';
import { ROLE, USER_ACCESS_RULES } from '@constants/settings';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import AccountUserContext from '@contexts/Settings/AccountUser';
import SearchBar from '@cores/SearchBar/SearchBar';
import { ConfirmModal, LoadingWrapper } from '@cores/index';
import { getUser } from '@helpers/userHelpers';
import { ResponsePayloadDto } from '@models/common/store';
import { TabPaneDto } from '@models/common/summary';
import { AlbumsStoreDto } from '@models/content/albums/stores';
import {
	AccountPayloadDto,
	InvitedUserDto,
} from '@models/settings/socialSearch/accountuser.ts/store';
import {
	deleteAccountUser,
	deleteGuestEnd,
	deleteGuestRequest,
	fetchGuestListRequest,
	getAccountList,
	inviteUser,
} from '@stores/actions';
import { StyledButton, StyledTabs } from '@styled/Common/CommonStyled';
import { StyledAction, StyledWrapper } from '@styled/Settings/AccountUserStyled';
import { userHasPermission } from '@utils/common';
import { generalMessage } from '@utils/renderComponent';
import { Tabs, message } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SettingsUserProfile = () => {
	const { t } = useTranslation();
	const inviteUserRef = useRef<any>();
	const dispatch = useDispatch();
	const { accountUserPermissions } = useSelector((state: any) => state.user);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const {
		fetchingGuestList = false,
		sendGuestPassCodeStatus = NONE,
		deleteGuestStatus = NONE,
	}: AlbumsStoreDto = useSelector((state: any) => state.albumManager);

	const user = getUser();
	const { clientId } = user;

	const roles = [];
	if (userHasPermission(USER_ACCESS_RULES.INVITE_USER_AS_REVIEWER, accountUserPermissions)) {
		roles.push(ROLE.REVIEWER);
	}
	if (userHasPermission(USER_ACCESS_RULES.INVITE_USER_AS_EREVIEWER, accountUserPermissions)) {
		roles.push(ROLE.EREVIEWER);
	}
	if (userHasPermission(USER_ACCESS_RULES.INVITE_USER_AS_CADMIN, accountUserPermissions)) {
		roles.push(ROLE.CADMIN);
	}

	const TAB_KEY = {
		MEMBER: 'MEMBER',
		GUEST: 'GUEST',
	};

	const defaultPayload = {
		page: Paginator.defaultPage,
		maxRecords: Paginator.pageSize,
		clientId,
	};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<string>(TAB_KEY.MEMBER);
	const [accountPayload, setAccountPayload] = useState<AccountPayloadDto>(defaultPayload);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isConfirm, setIsConfirm] = useState<boolean>(false);

	const isTeamMembersTab = useMemo(() => activeTab === TAB_KEY.MEMBER, [activeTab]);

	useEffect(() => {
		if (clientSettings?.configPortal === CONFIG_PORTAL.LITE) {
			generalMessage('setting.message.message_view_lite', MESSAGE_TYPE.INFO, {
				duration: DURATION.S_0,
				key: 'message_lite',
				values: { text: t('email.email_success') },
				href: `${t('email.mail_to')}${t('email.email_success')}`,
			});
		}
		return () => {
			message.destroy('message_lite');
		};
	}, [clientSettings]);

	const getAccountUserData = () => {
		setIsLoading(true);
		dispatch(getAccountList({ ...accountPayload }));
	};

	const fetchGuestList = () => {
		setSelectedRowKeys([]);
		dispatch(fetchGuestListRequest({ ...accountPayload }));
	};

	useEffect(() => {
		if (!isTeamMembersTab) {
			fetchGuestList();
		} else {
			getAccountUserData();
		}
	}, [accountPayload]);

	useEffect(() => {
		if (deleteGuestStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.deleted'),
				}),
			);
			resetData();
			handleConfirmModal();
		}

		if (deleteGuestStatus === FAILED || deleteGuestStatus === SUCCEEDED) {
			dispatch(deleteGuestEnd());
			setIsLoading(false);
		}
	}, [deleteGuestStatus]);

	const handleRefreshPage = () => {
		setAccountPayload(defaultPayload);
	};

	const handleSearch = (keyword: string) => {
		setAccountPayload({ ...accountPayload, page: Paginator.defaultPage, keyword });
	};

	const listTabPane: TabPaneDto[] = [
		{
			value: TAB_KEY.MEMBER,
			title: t('setting.account_user.team_members'),
			component: <AccountUserList />,
		},
		{
			value: TAB_KEY.GUEST,
			title: t('setting.account_user.guests'),
			component: <AccountGuestList />,
		},
	];

	const handleConfirmModal = () => {
		setIsConfirm(!isConfirm);
	};

	const resetData = () => {
		handleRefreshPage();
		setIsLoading(false);
		setSelectedRowKeys([]);
	};

	const onDeleteSucceeded = () => {
		resetData();
		handleConfirmModal();
		message.success(t('setting.message.remove_success'));
	};

	const onDeleteFailed = (error: string) => {
		setIsLoading(false);
		message.error(error);
	};

	const handleConfirmRemove = () => {
		setIsLoading(true);
		if (isTeamMembersTab) {
			const payload = {
				params: selectedRowKeys,
				onDeleteSucceeded,
				onDeleteFailed,
			};
			dispatch(deleteAccountUser(payload));
		} else {
			const payload = {
				clientId,
				params: selectedRowKeys,
			};
			dispatch(deleteGuestRequest(payload));
		}
	};

	const handleInviteUser = (values: InvitedUserDto) => {
		const { clientId } = user;
		const params = { ...values, clientId };
		const payload = {
			params,
			onInviteSucceeded,
			onInviteFailed,
		};

		setIsLoading(true);
		dispatch(inviteUser(payload));
	};

	const onInviteSucceeded = (response: ResponsePayloadDto, params: InvitedUserDto) => {
		if (response && params) {
			const { result } = response.data;
			const { brandName } = user;
			const { email } = params;

			if (!result || result.length === 0)
				message.error(
					t('message.invite_failed', {
						email,
						brandName,
					}),
				);
			else {
				message.success(t('message.invite_success'));
				resetData();
			}
		}
		if (inviteUserRef.current) {
			inviteUserRef?.current?.resetData();
		}
		setIsLoading(false);
	};

	const onInviteFailed = (error: string) => {
		message.error(error);
		setIsLoading(false);
	};

	const renderExtraActions = () => {
		return (
			<StyledAction margin="8px 16px 8px 0">
				<StyledAction>
					<SearchBar
						placeholder={t('setting.account_user.placeholder.search')}
						onSearch={handleSearch}
						enterButton
						style={{ marginRight: '15px' }}
					/>

					<StyledButton
						isDisabled={selectedRowKeys.length === 0}
						icon={<IconDelete />}
						onClick={handleConfirmModal}>
						{t('button.remove')}
					</StyledButton>
				</StyledAction>
			</StyledAction>
		);
	};

	const AccountUserValues = {
		roles,
		isLoading,
		setIsLoading,
		accountPayload,
		setAccountPayload,
		selectedRowKeys,
		setSelectedRowKeys,
		handleRefreshPage,
		handleInviteUser,
	};

	const isGeneralLoading =
		isLoading || fetchingGuestList || sendGuestPassCodeStatus === IN_PROGRESS;

	return (
		<AccountUserContext.Provider value={AccountUserValues}>
			<LoadingWrapper isLoading={isGeneralLoading}>
				<StyledWrapper height="98px" margin="0 0 16px 0" padding="8px 16px 24px 16px">
					{isTeamMembersTab ? <InviteUser ref={inviteUserRef} /> : <InviteGuest />}
				</StyledWrapper>
				<StyledWrapper>
					<StyledTabs
						height="100%"
						onTabClick={(tab: string) => {
							setActiveTab(tab);
							setAccountPayload(defaultPayload);
						}}
						tabBarExtraContent={renderExtraActions()}>
						{listTabPane.map((item: TabPaneDto) => {
							return (
								<Tabs.TabPane key={item.value} tab={item.title}>
									{activeTab === item?.value && item.component}
								</Tabs.TabPane>
							);
						})}
					</StyledTabs>
				</StyledWrapper>
			</LoadingWrapper>

			<ConfirmModal
				width={450}
				isOpen={isConfirm}
				confirmLoading={isLoading}
				onClose={handleConfirmModal}
				onSubmit={handleConfirmRemove}
				okText={t('button.remove')}>
				{t('setting.confirm.remove_account')}
			</ConfirmModal>
		</AccountUserContext.Provider>
	);
};

export default SettingsUserProfile;
