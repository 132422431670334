import sidebarTypes from '@stores/sidebar/sidebar.types';
import { createAction } from 'redux-actions';

// Action function
export const fetchCollapseMenuRequest = createAction(sidebarTypes.SIDEBAR_REQUEST);
export const fetchCollapseMenuSucceeded = createAction(sidebarTypes.SIDEBAR_SUCCEEDED);
export const fetchCollapseMenuFailed = createAction(sidebarTypes.SIDEBAR_FAILED);

export const clientSettingsRequest = createAction(sidebarTypes.CLIENT_SETTINGS_REQUEST);
export const clientSettingsSucceded = createAction(sidebarTypes.CLIENT_SETTINGS_COMPLETED);
export const clientSettingsFailed = createAction(sidebarTypes.CLIENT_SETTINGS_FAILED);
export const changeClientSetting = createAction(sidebarTypes.CHANGE_CLIENT_SETTING);
export const changeFieldsClientSetting = createAction(sidebarTypes.CHANGE_FIELDS_CLIENT_SETTING);

export const folderSystemRequest = createAction(sidebarTypes.FOLDER_SYSTEM_REQUEST);
export const folderSystemSucceeded = createAction(sidebarTypes.FOLDER_SYSTEM_COMPLETED);
export const folderSystemFailed = createAction(sidebarTypes.FOLDER_SYSTEM_FAILED);

export const initPendoRequest = createAction(sidebarTypes.PENDO_INIT_REQUEST);
export const initPendoSucceeded = createAction(sidebarTypes.PENDO_INIT_SUCCEEDED);
