import { Action, handleActions } from 'redux-actions';

import { SettingDto, SettingPayloadDto } from '@models/settings/setting';
import {
	socialSearchTypes,
	contentSettingsTypes,
	SET_CONTENT_VALUES,
	publishSettingsTypes,
	accountUserTypes,
	storeDataTypes,
	removeFolderTypes,
	replaceBlockUsernameType,
	replaceBlockTermsType,
} from './settings.types';
import { DEFAULT_ERROR } from '@constants/errors';
import { STATUSCODE } from '@constants/APIs';
import { NONE, SUCCEEDED, IN_PROGRESS, FAILED } from '@constants/status';
import { FeedDto } from '@models/settings/socialSearch/socialAggregator/summary';
import { STATUS_SETTING } from '@constants/settings/socialAggregator';
import {
	AccountUserDataDto,
	AccountUserDto,
} from '@models/settings/socialSearch/accountuser.ts/summary';
import { sortStringArray } from '@utils/funcHelper';

const initialState: SettingDto = {
	socialSearch: {
		loadingHashtag: false,
		loadingInActiveUsername: false,
		loadingBlockedUsername: false,
		loadingUsername: false,
		loadingBadWord: false,
		error: null,
		statusCode: null,
		hashTagList: [],
		subHashTagList: [],
		blockedUsernameList: [],
		usernameList: [],
		badWordList: [],
		inActiveHashtagList: [],
		inActiveUsernameList: [],
		replaceBlockUsernameStatus: NONE,
		replaceBlockTermsStatus: NONE,
		loadingListFeed: false,
		createFeedStatus: NONE,
		updateFeedStatus: NONE,
		feedResult: null,
		listFeed: [],
		listFeedActive: [],
		isDeleteFeed: false,
		statsSettingFeed: null,
		loadingStatsSettingFeed: false,
		usernameFeedImport: [],
		statusImportUsernameFeed: NONE,
	},
	content: {
		loadingLabels: NONE,
		loadingFlags: NONE,
		loadingFolder: NONE,
		addingFolder: NONE,
		loadingContentValues: NONE,
		labels: [],
		flags: [],
		folders: [],
		contentValues: {
			verified: [],
			selects: [],
		},
		error: {},
	},
	publish: {
		loadingChannel: false,
		loadingCalendar: false,
		error: null,
		statusCode: null,
		channelsList: [],
		calendarsList: [],
	},
	account: {
		loadingUpdate: NONE,
	},
	folder: {
		loadingRemoveFolder: NONE,
		error: '',
	},
	// accountData: {},
};

/* =========================== SOCIAL SEARCH =========================== */

const fetchHashTagRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingHashtag: true,
	},
});

const fetchHashTagSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		const subHashTagList = [...result.subHashtag, ...result.inactiveHashtag].filter(
			(v, i, a) => a.indexOf(v) === i && v !== result.hashtag,
		);
		subHashTagList.sort();
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingHashtag: false,
				statusCode: code,
				hashTagList: result.hashtag ? [result.hashtag] : [],
				subHashTagList: subHashTagList,
				inActiveHashtagList: result.inactiveHashtag,
				error: null,
			},
		};
	}
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingHashtag: false,
			error: status,
		},
	};
};

const fetchHashTagFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingHashtag: false,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingHashtag: false,
		},
	};
};

const updateInActiveUsernameRequest = (state: SettingDto) => {
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingInActiveUsername: true,
			statusCode: null,
		},
	};
};

const fetchInActiveUsernameRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingInActiveUsername: true,
	},
});

const fetchInActiveUsernameSucceeded = (
	state: SettingDto,
	{ payload }: Action<SettingPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		const usernameList = [...result.usernames, ...result.inactiveUsernames];
		usernameList.sort();
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				statusCode: code,
				loadingInActiveUsername: false,
				usernameList: usernameList,
				inActiveUsernameList: result.inactiveUsernames,
				error: null,
			},
		};
	}
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingInActiveUsername: false,
			error: status,
		},
	};
};

const fetchInActiveUsernameFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingInActiveUsername: false,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingInActiveUsername: false,
		},
	};
};

const updateSubHashTagRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingHashtag: true,
		statusCode: null,
	},
});

const fetchSocialBlockedUsername = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingBlockedUsername: true,
		statusCode: null,
	},
});

const modifySocialBlockedUsernameRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingBlockedUsername: true,
		statusCode: null,
	},
});

const fetchSocialBlockedUsernameSucceeded = (
	state: SettingDto,
	{ payload }: Action<SettingPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: blockedUsernameList = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingBlockedUsername: false,
				blockedUsernameList: sortStringArray(blockedUsernameList),
				statusCode: code,
				error: null,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingBlockedUsername: false,
			error: status,
		},
	};
};

const fetchSocialBlockedUsernameFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingBlockedUsername: false,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingBlockedUsername: false,
		},
	};
};

const fetchSocialUsername = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingUsername: true,
		statusCode: null,
	},
});

const modifySocialUsernameRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingUsername: true,
		statusCode: null,
	},
});

const fetchSocialUsernameSucceeded = (
	state: SettingDto,
	{ payload }: Action<SettingPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		const usernameList = [...result.usernames, ...result.inactiveUsernames];
		usernameList.sort();
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingUsername: false,
				usernameList: usernameList,
				inActiveUsernameList: result.inactiveUsernames,
				statusCode: code,
				error: null,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingUsername: false,
			error: status,
		},
	};
};

const fetchSocialUsernameFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingUsername: false,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingUsername: false,
		},
	};
};

const fetchBadWords = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingBadWord: true,
		statusCode: null,
	},
});

const modifyBadWords = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingBadWord: true,
		statusCode: null,
	},
});

const fetchBadWordsSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result: badWordList = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingBadWord: false,
				badWordList: sortStringArray(badWordList),
				statusCode: code,
				error: null,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingBadWord: false,
			error: status,
		},
	};
};

const fetchBadWordsFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingBadWord: false,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingBadWord: false,
		},
	};
};

// get list feed
const getListFeedRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingListFeed: true,
		listFeed: [],
		listFeedActive: [],
	},
});

const getListFeedSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result: listFeed = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingListFeed: false,
				listFeed,
				listFeedActive: listFeed?.filter(
					(item: FeedDto) => item?.status === STATUS_SETTING.LIVE,
				),
				statusCode: code,
				error: null,
			},
		};
	}
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingListFeed: false,
			error: status,
		},
	};
};

const getListFeedFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingListFeed: false,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingListFeed: false,
		},
	};
};

// create feed
const createFeedRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		createFeedStatus: IN_PROGRESS,
	},
});

const createFeedSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result: feedResult,
	} = payload.data;
	const listFeed = [...state.socialSearch.listFeed, feedResult];

	const listFeedActive = listFeed.filter((item: FeedDto) => item.status === STATUS_SETTING.LIVE);

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				createFeedStatus: SUCCEEDED,
				listFeed,
				listFeedActive,
				feedResult,
				statusCode: code,
				error: null,
			},
		};
	}
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			createFeedStatus: FAILED,
			error: status,
		},
	};
};

const createFeedFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				createFeedStatus: FAILED,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			createFeedStatus: FAILED,
		},
	};
};

const createFeedEnded = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		createFeedStatus: NONE,
		feedResult: null,
	},
});

// update feed
const updateFeedRequest = (state: SettingDto, { payload }: Action<FeedDto>) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		updateFeedStatus: IN_PROGRESS,
		isDeleteFeed: payload.status === STATUS_SETTING.DELETED,
	},
});

const updateFeedSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result: feedResult,
	} = payload.data;
	const { isDeleteFeed, listFeed } = state.socialSearch;
	const listFeedUpdate = isDeleteFeed
		? listFeed.filter((item) => item.id !== feedResult.id)
		: listFeed.map((item) => {
				if (item.id === feedResult.id) {
					return { ...feedResult };
				}
				return { ...item };
		  });

	const listFeedActive = listFeedUpdate.filter(
		(item: FeedDto) => item.status === STATUS_SETTING.LIVE,
	);

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				updateFeedStatus: SUCCEEDED,
				listFeed: listFeedUpdate,
				listFeedActive,
				feedResult,
				statusCode: code,
				error: null,
			},
		};
	}
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			updateFeedStatus: FAILED,
			error: status,
		},
	};
};

const updateFeedFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				updateFeedStatus: FAILED,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			updateFeedStatus: FAILED,
		},
	};
};

const updateFeedEnded = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		updateFeedStatus: NONE,
		feedResult: null,
		isDeleteFeed: false,
	},
});

// import username social feed
const importUsernameFeedRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		statusImportUsernameFeed: IN_PROGRESS,
	},
});

const importUsernameFeedSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result: usernameFeedImport,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				statusImportUsernameFeed: SUCCEEDED,
				usernameFeedImport,
				statusCode: code,
				error: null,
			},
		};
	}
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			statusImportUsernameFeed: FAILED,
			error: status,
		},
	};
};

const importUsernameFeedFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				statusImportUsernameFeed: FAILED,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			statusImportUsernameFeed: FAILED,
		},
	};
};

const importUsernameFeedEnded = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		statusImportUsernameFeed: NONE,
		usernameFeedImport: [],
	},
});

// get stats feed setting
const getStatsFeedSettingRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		loadingStatsSettingFeed: true,
	},
});

const getStatsFeedSettingSucceeded = (
	state: SettingDto,
	{ payload }: Action<SettingPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: statsSettingFeed = null,
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingStatsSettingFeed: false,
				statsSettingFeed,
				statusCode: code,
				error: null,
			},
		};
	}
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingStatsSettingFeed: false,
			statsSettingFeed: null,
			error: status,
		},
	};
};

const getStatsFeedSettingFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				loadingStatsSettingFeed: false,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			loadingStatsSettingFeed: false,
		},
	};
};

// reset setting
const resetSocialSearchSetting = (state: SettingDto, { payload }: Action<any>) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		...payload,
	},
});

/* =========================== CONTENT =========================== */

const setContentValues = (state: SettingDto, action: any) => ({
	...state,
	content: {
		...state.content,
		contentValues: { ...state.content.contentValues, ...action.payload },
	},
});

const getContentSettings = (state: any) => ({
	...state,
	content: {
		loadingLabels: IN_PROGRESS,
		loadingFlags: IN_PROGRESS,
		loadingFolder: IN_PROGRESS,
		loadingContentValues: IN_PROGRESS,
	},
});

const getContentSettingsSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result: { labels = {}, flags = [], folders = [], contentValues = {} },
	} = payload;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			content: {
				loadingLabels: SUCCEEDED,
				loadingFlags: SUCCEEDED,
				loadingFolder: SUCCEEDED,
				loadingContentValues: SUCCEEDED,
				labels,
				flags,
				folders,
				contentValues,
				error: null,
			},
		};
	}

	return {
		...state,
		content: {
			loadingLabels: FAILED,
			loadingFlags: FAILED,
			loadingFolder: FAILED,
			loadingContentValues: FAILED,
			error: status,
		},
	};
};

const getContentSettingsFailed = (
	state: SettingDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	content: {
		loadingLabels: FAILED,
		loadingFlags: FAILED,
		loadingFolder: FAILED,
		loadingContentValues: FAILED,
		error: payload,
	},
});

const updateContentValues = (state: any) => ({
	...state,
	content: {
		...state.content,
		loadingContentValues: IN_PROGRESS,
	},
});

const updateContentValuesSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = {},
	} = payload;

	const { content = {} } = state;
	const { contentValues = {} } = content;

	const newContentValues = contentValues[result.source].map((item: any) =>
		item.id === result.id ? result : item,
	);

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			content: {
				...content,
				contentValues: {
					...contentValues,
					[result.source]: newContentValues,
				},
				loadingContentValues: SUCCEEDED,
				error: null,
			},
		};
	}

	return {
		...state,
		content: {
			...state.content,
			loadingContentValues: FAILED,
			error: status,
		},
	};
};

const updateContentValuesFailed = (
	state: SettingDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	content: {
		...state.content,
		loadingContentValues: FAILED,
		error: payload,
	},
});

const updateFlags = (state: any) => ({
	...state,
	content: {
		...state.content,
		loadingFlags: IN_PROGRESS,
	},
});

const updateFlagsSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = [],
	} = payload;

	const { content = {} } = state;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			content: {
				...content,
				loadingFlags: SUCCEEDED,
				flags: [...result],
				error: null,
			},
		};
	}

	return {
		...state,
		content: {
			...state.content,
			loadingFlags: FAILED,
			error: status,
		},
	};
};

const updateFlagsFailed = (
	state: SettingDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	content: {
		...state.content,
		loadingFlags: FAILED,
		error: payload,
	},
});

const updateFlagsEnd = (state: any) => ({
	...state,
	content: {
		...state.content,
		loadingFlags: NONE,
	},
});

const updateLabels = (state: any) => ({
	...state,
	content: {
		...state.content,
		loadingLabels: IN_PROGRESS,
	},
});

const updateLabelsSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = [],
	} = payload;

	const { content = {} } = state;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			content: {
				...content,
				loadingLabels: SUCCEEDED,
				labels: [...result],
				error: null,
			},
		};
	}

	return {
		...state,
		content: {
			...state.content,
			loadingLabels: FAILED,
			error: status,
		},
	};
};

const updateLabelsFailed = (
	state: SettingDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	content: {
		...state.content,
		loadingLabels: FAILED,
		error: payload,
	},
});

const updateLabelsEnd = (state: any) => ({
	...state,
	content: {
		...state.content,
		loadingLabels: NONE,
	},
});

const createFolder = (state: any) => ({
	...state,
	content: {
		...state.content,
		loadingFolder: IN_PROGRESS,
		addingFolder: IN_PROGRESS,
	},
});

const createFolderSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = [],
	} = payload;

	const { content = {} } = state;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			content: {
				...content,
				loadingFolder: SUCCEEDED,
				addingFolder: SUCCEEDED,
				folders: [...result],
				error: null,
			},
		};
	}

	return {
		...state,
		content: {
			...state.content,
			loadingFolder: FAILED,
			addingFolder: FAILED,
			error: status,
		},
	};
};

const createFolderFailed = (
	state: SettingDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	content: {
		...state.content,
		loadingFolder: FAILED,
		addingFolder: FAILED,
		error: payload,
	},
});

const deleteFolder = (state: any) => ({
	...state,
	content: {
		...state.content,
		loadingFolder: IN_PROGRESS,
	},
});

const deleteFolderSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		id,
	} = payload;
	const { content = {} } = state;
	const { folders = [] } = content;
	const newFolderData = folders.filter((folder: any) => folder.id !== id);

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			content: {
				...content,
				loadingFolder: SUCCEEDED,
				folders: [...newFolderData],
				error: null,
			},
		};
	}

	return {
		...state,
		content: {
			...state.content,
			loadingFolder: FAILED,
			error: status,
		},
	};
};

const deleteFolderFailed = (
	state: SettingDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	content: {
		...state.content,
		loadingFolder: FAILED,
		error: payload,
	},
});

const updateFolder = (state: any) => ({
	...state,
	content: {
		...state.content,
		loadingFolder: IN_PROGRESS,
	},
});

const updateFolderSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = [],
	} = payload;
	const { content = {} } = state;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			content: {
				...content,
				loadingFolder: SUCCEEDED,
				folders: [...result],
				error: null,
			},
		};
	}

	return {
		...state,
		content: {
			...state.content,
			loadingFolder: FAILED,
			error: status,
		},
	};
};

const updateFolderFailed = (
	state: SettingDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	content: {
		...state.content,
		loadingFolder: FAILED,
		error: payload,
	},
});

const getFolderSettings = (state: any) => ({
	...state,
	content: {
		loadingFolder: IN_PROGRESS,
	},
});

const getFolderSettingsSucceeded = (state: any, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
		result = [],
	} = payload;
	const { content = {} } = state;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			content: {
				...content,
				loadingFolder: SUCCEEDED,
				folders: [...result],
				error: null,
			},
		};
	}

	return {
		...state,
		content: {
			...state.content,
			loadingFolder: FAILED,
			error: status,
		},
	};
};

const getFolderSettingsFailed = (
	state: SettingDto,
	{ payload = DEFAULT_ERROR }: { payload: string },
) => ({
	...state,
	content: {
		...state.content,
		loadingFolder: FAILED,
		error: payload,
	},
});

/* =========================== PUBLISH =========================== */

const fetchChannelsRequest = (state: SettingDto) => ({
	...state,
	publish: {
		...state.publish,
		loadingChannel: true,
	},
});

const modifyChannel = (state: SettingDto) => ({
	...state,
	publish: {
		...state.publish,
		loadingChannel: true,
		statusCode: null,
	},
});

const fetchChannelsSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result: channelsList = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			publish: {
				...state.publish,
				loadingChannel: false,
				channelsList,
				statusCode: code,
				error: null,
			},
		};
	}
	return {
		...state,
		publish: {
			...state.publish,
			loadingChannel: false,
			error: status,
		},
	};
};

const fetchChannelsFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			publish: {
				...state.publish,
				loadingChannel: false,
				error: error !== '' ? error : `${status} ${message}`,
				channelsList: [],
			},
		};
	}

	return {
		...state,
		publish: {
			...state.publish,
			loadingChannel: false,
		},
	};
};

const fetchCalendarRequest = (state: SettingDto) => ({
	...state,
	publish: {
		...state.publish,
		loadingCalendar: true,
	},
});

const modifyCalendar = (state: SettingDto) => ({
	...state,
	publish: {
		...state.publish,
		loadingCalendar: true,
		statusCode: null,
	},
});

const fetchCalendarSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result: calendarsList = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			publish: {
				...state.publish,
				loadingCalendar: false,
				error: null,
				statusCode: code,
				calendarsList,
			},
		};
	}
	return {
		...state,
		publish: {
			...state.publish,
			loadingCalendar: false,
			error: status,
		},
	};
};

const fetchCalendarFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = 0, error = '' } = payload.data;

		return {
			...state,
			publish: {
				...state.publish,
				loadingCalendar: false,
				error: error !== '' ? error : `${status} ${message}`,
				calendarsList: [],
			},
		};
	}

	return {
		...state,
		publish: {
			...state.publish,
			loadingCalendar: false,
		},
	};
};

const getAccountList = (state: any) => ({
	...state,
	getAccountListStatus: IN_PROGRESS,
});

const getAccountListSucceeded = (state: any, { payload }: Action<any>) => {
	const {
		status: { code = NONE },
		result = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const accountUserData: AccountUserDataDto = { ...result } as AccountUserDataDto;
		if (accountUserData && accountUserData?.accountResponses) {
			accountUserData?.accountResponses?.forEach((account: AccountUserDto) => {
				account.key = account.id;
			});
		}

		return {
			...state,
			accountUserData,
			getAccountListStatus: SUCCEEDED,
		};
	}

	return {
		...state,
		accountUserData: result,
		getAccountListStatus: FAILED,
	};
};

const getAccountListFailed = (state: any, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			getAccountListStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		getAccountListStatus: FAILED,
	};
};

const updateAccountUser = (state: any) => ({
	...state,
	account: {
		...state.account,
		loadingUpdate: IN_PROGRESS,
	},
});

const updateAccountUserSucceeded = (state: any, { payload }: Action<any>) => {
	const {
		status: { code = NONE },
		result = {},
	} = payload;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const listUser = [...state?.accountUserData?.accountResponses];
		const index = listUser?.findIndex((user) => user?.id === result?.id);
		if (index !== -1) {
			listUser[index] = { ...listUser[index], ...result };
		}

		return {
			...state,
			account: {
				...state.account,
				loadingUpdate: SUCCEEDED,
			},
			accountUserData: {
				...state.accountUserData,
				accountResponses: listUser,
			},
		};
	}

	return {
		...state,
		account: {
			...state.account,
			loadingUpdate: FAILED,
		},
	};
};

const updateAccountUserFailed = (state: SettingDto) => ({
	...state,
	account: {
		...state.account,
		loadingUpdate: FAILED,
	},
});

const updateAccountUserEnd = (state: SettingDto) => ({
	...state,
	account: {
		...state.account,
		loadingUpdate: NONE,
	},
});

const storeData = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		error: null,
	},
});

// remove folder
const removeFolderRequest = (state: SettingDto) => ({
	...state,
	loadingRemoveFolder: IN_PROGRESS,
});

const removeFolderSucceeded = (state: SettingDto, { payload }: any) => {
	const {
		status: { code = 0, status = '' },
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			folder: {
				...state.folder,
				loadingRemoveFolder: SUCCEEDED,
				error: '',
			},
		};
	}

	return {
		...state,
		folder: {
			...state.folder,
			loadingRemoveFolder: FAILED,
			error: status,
		},
	};
};

const removeFolderFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			folder: {
				...state.folder,
				loadingRemoveFolder: FAILED,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		folder: {
			...state.folder,
			loadingRemoveFolder: FAILED,
		},
	};
};

const removeFolderEnd = (state: SettingDto) => {
	return {
		...state,
		folder: {
			...state.folder,
			loadingRemoveFolder: NONE,
		},
	};
};

// replace block username
const replaceBlockUsernameRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		replaceBlockUsernameStatus: IN_PROGRESS,
	},
});

const replaceBlockUsernameSucceeded = (
	state: SettingDto,
	{ payload }: Action<SettingPayloadDto>,
) => {
	const {
		status: { code = 0, status = '' },
		result: blockedUsernameList = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				blockedUsernameList: sortStringArray(blockedUsernameList),
				replaceBlockUsernameStatus: SUCCEEDED,
				error: '',
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			replaceBlockUsernameStatus: FAILED,
			error: status,
		},
	};
};

const replaceBlockUsernameFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				replaceBlockUsernameStatus: FAILED,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			replaceBlockUsernameStatus: FAILED,
		},
	};
};

const replaceBlockUsernameEnd = (state: SettingDto) => {
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			replaceBlockUsernameStatus: NONE,
		},
	};
};

// replace block terms
const replaceBlockTermsRequest = (state: SettingDto) => ({
	...state,
	socialSearch: {
		...state.socialSearch,
		replaceBlockTermsStatus: IN_PROGRESS,
	},
});

const replaceBlockTermsSucceeded = (state: SettingDto, { payload }: Action<SettingPayloadDto>) => {
	const {
		status: { code = 0, status = '' },
		result: badWordList = [],
	} = payload.data;

	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				badWordList: sortStringArray(badWordList),
				replaceBlockTermsStatus: SUCCEEDED,
				error: '',
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			replaceBlockTermsStatus: FAILED,
			error: status,
		},
	};
};

const replaceBlockTermsFailed = (state: SettingDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			socialSearch: {
				...state.socialSearch,
				replaceBlockTermsStatus: FAILED,
				error: error !== '' ? error : `${status} ${message}`,
			},
		};
	}

	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			replaceBlockTermsStatus: FAILED,
		},
	};
};

const replaceBlockTermsEnd = (state: SettingDto) => {
	return {
		...state,
		socialSearch: {
			...state.socialSearch,
			replaceBlockTermsStatus: NONE,
		},
	};
};

const settingReducers = handleActions<any>(
	{
		[SET_CONTENT_VALUES]: setContentValues,

		[socialSearchTypes.GET_HASHTAG]: fetchHashTagRequest,
		[socialSearchTypes.GET_HASHTAG_SUCCEEDED]: fetchHashTagSucceeded,
		[socialSearchTypes.GET_HASHTAG_FAILED]: fetchHashTagFailed,

		[socialSearchTypes.UPDATE_SUB_HASHTAG]: updateSubHashTagRequest,

		[socialSearchTypes.GET_SOCIAL_USERNAME]: fetchSocialUsername,
		[socialSearchTypes.MODIFY_SOCIAL_USERNAME]: modifySocialUsernameRequest,
		[socialSearchTypes.GET_SOCIAL_USERNAME_SUCCEEDED]: fetchSocialUsernameSucceeded,
		[socialSearchTypes.GET_SOCIAL_USERNAME_FAILED]: fetchSocialUsernameFailed,

		[socialSearchTypes.GET_SOCIAL_BLOCK_USERNAME]: fetchSocialBlockedUsername,
		[socialSearchTypes.MODIFY_SOCIAL_BLOCK_USERNAME]: modifySocialBlockedUsernameRequest,
		[socialSearchTypes.GET_SOCIAL_BLOCK_USERNAME_SUCCEEDED]:
			fetchSocialBlockedUsernameSucceeded,
		[socialSearchTypes.GET_SOCIAL_BLOCK_USERNAME_FAILED]: fetchSocialBlockedUsernameFailed,

		[socialSearchTypes.GET_BAD_WORDS]: fetchBadWords,
		[socialSearchTypes.MODIFY_BAD_WORDS]: modifyBadWords,
		[socialSearchTypes.GET_BAD_WORDS_SUCCEEDED]: fetchBadWordsSucceeded,
		[socialSearchTypes.GET_BAD_WORDS_FAILED]: fetchBadWordsFailed,

		[socialSearchTypes.FETCH_INACTIVE_USERNAME]: fetchInActiveUsernameRequest,
		[socialSearchTypes.FETCH_INACTIVE_USERNAME_SUCCEEDED]: fetchInActiveUsernameSucceeded,
		[socialSearchTypes.FETCH_INACTIVE_USERNAME_FAILED]: fetchInActiveUsernameFailed,
		[socialSearchTypes.UPDATE_INACTIVE_USERNAME]: updateInActiveUsernameRequest,

		[socialSearchTypes.GET_LIST_FEED_REQUEST]: getListFeedRequest,
		[socialSearchTypes.GET_LIST_FEED_SUCCEEDED]: getListFeedSucceeded,
		[socialSearchTypes.GET_LIST_FEED_FAILED]: getListFeedFailed,

		[socialSearchTypes.CREATE_FEED_REQUEST]: createFeedRequest,
		[socialSearchTypes.CREATE_FEED_SUCCEEDED]: createFeedSucceeded,
		[socialSearchTypes.CREATE_FEED_FAILED]: createFeedFailed,
		[socialSearchTypes.CREATE_FEED_ENDED]: createFeedEnded,

		[socialSearchTypes.UPDATE_FEED_REQUEST]: updateFeedRequest,
		[socialSearchTypes.UPDATE_FEED_SUCCEEDED]: updateFeedSucceeded,
		[socialSearchTypes.UPDATE_FEED_FAILED]: updateFeedFailed,
		[socialSearchTypes.UPDATE_FEED_ENDED]: updateFeedEnded,

		[socialSearchTypes.IMPORT_USERNAME_FEED_REQUEST]: importUsernameFeedRequest,
		[socialSearchTypes.IMPORT_USERNAME_FEED_SUCCEEDED]: importUsernameFeedSucceeded,
		[socialSearchTypes.IMPORT_USERNAME_FEED_FAILED]: importUsernameFeedFailed,
		[socialSearchTypes.IMPORT_USERNAME_FEED_ENDED]: importUsernameFeedEnded,

		[socialSearchTypes.FETCH_STATS_SETTING_FEED_REQUEST]: getStatsFeedSettingRequest,
		[socialSearchTypes.FETCH_STATS_SETTING_FEED_SUCCEEDED]: getStatsFeedSettingSucceeded,
		[socialSearchTypes.FETCH_STATS_SETTING_FEED_FAILED]: getStatsFeedSettingFailed,

		[socialSearchTypes.RESET_SOCIAL_SEARCH_SETTING]: resetSocialSearchSetting,

		[contentSettingsTypes.GET_CONTENT_SETTINGS]: getContentSettings,
		[contentSettingsTypes.GET_CONTENT_SETTINGS_SUCCEEDED]: getContentSettingsSucceeded,
		[contentSettingsTypes.GET_CONTENT_SETTINGS_FAILED]: getContentSettingsFailed,

		[contentSettingsTypes.UPDATE_CONTENT_VALUES]: updateContentValues,
		[contentSettingsTypes.UPDATE_CONTENT_VALUES_SUCCEEDED]: updateContentValuesSucceeded,
		[contentSettingsTypes.UPDATE_CONTENT_VALUES_FAILED]: updateContentValuesFailed,

		[contentSettingsTypes.UPDATE_FLAGS]: updateFlags,
		[contentSettingsTypes.UPDATE_FLAGS_SUCCEEDED]: updateFlagsSucceeded,
		[contentSettingsTypes.UPDATE_FLAGS_FAILED]: updateFlagsFailed,
		[contentSettingsTypes.UPDATE_FLAGS_END]: updateFlagsEnd,

		[contentSettingsTypes.UPDATE_LABELS]: updateLabels,
		[contentSettingsTypes.UPDATE_LABELS_SUCCEEDED]: updateLabelsSucceeded,
		[contentSettingsTypes.UPDATE_LABELS_FAILED]: updateLabelsFailed,
		[contentSettingsTypes.UPDATE_LABELS_END]: updateLabelsEnd,

		[contentSettingsTypes.CREATE_FOLDER]: createFolder,
		[contentSettingsTypes.CREATE_FOLDER_SUCCEEDED]: createFolderSucceeded,
		[contentSettingsTypes.CREATE_FOLDER_FAILED]: createFolderFailed,

		[contentSettingsTypes.DELETE_FOLDER]: deleteFolder,
		[contentSettingsTypes.DELETE_FOLDER_SUCCEEDED]: deleteFolderSucceeded,
		[contentSettingsTypes.DELETE_FOLDER_FAILED]: deleteFolderFailed,

		[contentSettingsTypes.UPDATE_FOLDER]: updateFolder,
		[contentSettingsTypes.UPDATE_FOLDER_SUCCEEDED]: updateFolderSucceeded,
		[contentSettingsTypes.UPDATE_FOLDER_FAILED]: updateFolderFailed,

		[contentSettingsTypes.GET_FOLDER_SETTINGS]: getFolderSettings,
		[contentSettingsTypes.GET_FOLDER_SETTINGS_SUCCEEDED]: getFolderSettingsSucceeded,
		[contentSettingsTypes.GET_FOLDER_SETTINGS_FAILED]: getFolderSettingsFailed,

		[publishSettingsTypes.GET_CHANNELS_SETTINGS]: fetchChannelsRequest,
		[publishSettingsTypes.GET_CHANNELS_SETTINGS_SUCCEEDED]: fetchChannelsSucceeded,
		[publishSettingsTypes.GET_CHANNELS_SETTINGS_FAILED]: fetchChannelsFailed,
		[publishSettingsTypes.MODIFY_CHANNELS_SETTINGS]: modifyChannel,

		[publishSettingsTypes.GET_CALENDAR_SETTINGS]: fetchCalendarRequest,
		[publishSettingsTypes.GET_CALENDAR_SETTINGS_SUCCEEDED]: fetchCalendarSucceeded,
		[publishSettingsTypes.GET_CALENDAR_SETTINGS_FAILED]: fetchCalendarFailed,
		[publishSettingsTypes.MODIFY_CALENDAR_SETTINGS]: modifyCalendar,

		[accountUserTypes.GET_ACCOUNT_LIST]: getAccountList,
		[accountUserTypes.GET_ACCOUNT_LIST_SUCCEEDED]: getAccountListSucceeded,
		[accountUserTypes.GET_ACCOUNT_LIST_FAILED]: getAccountListFailed,

		[accountUserTypes.UPDATE_ACCOUNT_USER]: updateAccountUser,
		[accountUserTypes.UPDATE_ACCOUNT_USER_SUCCEEDED]: updateAccountUserSucceeded,
		[accountUserTypes.UPDATE_ACCOUNT_USER_FAILED]: updateAccountUserFailed,
		[accountUserTypes.UPDATE_ACCOUNT_USER_END]: updateAccountUserEnd,

		[storeDataTypes.STORE_DATA]: storeData,

		[removeFolderTypes.REMOVE_FOLDER_REQUEST]: removeFolderRequest,
		[removeFolderTypes.REMOVE_FOLDER_SUCCEEDED]: removeFolderSucceeded,
		[removeFolderTypes.REMOVE_FOLDER_FAILED]: removeFolderFailed,
		[removeFolderTypes.REMOVE_FOLDER_END]: removeFolderEnd,

		[replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_REQUEST]: replaceBlockUsernameRequest,
		[replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_SUCCEEDED]: replaceBlockUsernameSucceeded,
		[replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_FAILED]: replaceBlockUsernameFailed,
		[replaceBlockUsernameType.REPLACE_BLOCK_USERNAME_END]: replaceBlockUsernameEnd,

		[replaceBlockTermsType.REPLACE_BLOCK_TERMS_REQUEST]: replaceBlockTermsRequest,
		[replaceBlockTermsType.REPLACE_BLOCK_TERMS_SUCCEEDED]: replaceBlockTermsSucceeded,
		[replaceBlockTermsType.REPLACE_BLOCK_TERMS_FAILED]: replaceBlockTermsFailed,
		[replaceBlockTermsType.REPLACE_BLOCK_TERMS_END]: replaceBlockTermsEnd,
	},
	initialState,
);

export default settingReducers;
