import { IconDragHandle, IconKeyboardArrowLeft } from '@assets/icons';
import {
	KEY_FIELD_FORM_WIDGET,
	MAPPING_FIELD,
	TYPE_ADD_FIELDS,
	TYPE_CUSTOM_FIELD,
} from '@constants/content/widgetManager';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { FieldItemWidgetDto } from '@models/content/widgetManager';
import {
	StyledIcon,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledIconRemoveCircle } from '@styled/Content/WidgetManager';
import { StyledCheckbox } from '@styled/Content/WidgetManager/DetailWidgetStyled';
import {
	StyledCustomFieldItem,
	StyledFieldItem,
	StyledFormItem,
	StyledInputCustomField,
	StyledLabelField,
	StyledWrapperLabel,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwitchMapping from '../SwitchMapping';
import THEME from '@constants/themes/themes';

const CustomInputField = ({
	field,
	handleRemoveField,
	handleChangeRequired,
	getValueField,
	setFieldsValue,
	label,
	keyIndex,
	isExist,
	keyName: keyNameDefault,
	propSort,
	idFocus,
	clearIdFocus,
	...other
}: {
	field: FieldItemWidgetDto;
	handleRemoveField: () => void;
	handleChangeRequired: () => void;
	getValueField: (val: string) => any;
	setFieldsValue: (val: any) => void;
	keyIndex: number;
	label?: string;
	isExist?: boolean;
	keyName?: string;
	propSort?: any;
	idFocus?: string;
	clearIdFocus?: () => void;
	[other: string]: any;
}) => {
	const { t } = useTranslation();

	const { detailWidget } = useContext(WidgetBuilderContext);
	const { otherInfo = {} } = detailWidget?.metadata;
	const { label: labelField, typeAdd, name } = field;

	const keyName = keyNameDefault || `${KEY_FIELD_FORM_WIDGET.item}${keyIndex}`;
	const nameLabel = `${KEY_FIELD_FORM_WIDGET.label}${keyIndex}`;

	const isCustom = typeAdd === TYPE_ADD_FIELDS.CUSTOM;

	const [showMore, setShowMore] = useState(true);

	useEffect(() => {
		const otherCustom: any = {};
		if (isCustom) {
			otherCustom[nameLabel] = labelField;
		}

		setFieldsValue({
			[keyName]: name,
			...otherCustom,
		});
	}, [field, typeAdd]);

	useEffect(() => {
		if (idFocus && isCustom) {
			const elm = document.getElementById(nameLabel);
			if (elm) {
				elm.focus();
			}
			if (clearIdFocus && typeof clearIdFocus === 'function') {
				clearIdFocus();
			}
		}
	}, [idFocus]);

	useEffect(() => {
		return () => {
			setShowMore(true);
		};
	}, []);

	const renderLabel = () => {
		if (label) {
			return label;
		}
		let labelInput = 'short_answer';
		switch (field?.type) {
			case TYPE_CUSTOM_FIELD.email:
				labelInput = 'email';
				break;
			case TYPE_CUSTOM_FIELD.instagram:
				labelInput = 'instagram_username';
				break;
			case TYPE_CUSTOM_FIELD.tiktok:
				labelInput = 'tiktok_username';
				break;
			case TYPE_CUSTOM_FIELD.twitter:
				labelInput = 'twitter_username';
				break;
			case TYPE_CUSTOM_FIELD.shortAnswer:
				labelInput = 'short_answer';
				break;
			case TYPE_CUSTOM_FIELD.phone:
				labelInput = 'phone_number';
				break;
			case TYPE_CUSTOM_FIELD.paragraph:
				labelInput = 'content_description';
				if (isCustom) {
					labelInput = 'paragraph';
				}
				break;

			default:
				break;
		}
		return t(`content.widget_manager.widget_builder.label_field.${labelInput}`);
	};

	const renderStatusShowMore = () => {
		return (
			<StyledTitle
				cursor="pointer"
				className="unset_height"
				display="flex"
				fontSize="10px"
				height="20px"
				onClick={() => setShowMore(!showMore)}
				color={THEME.colors.gray2}
				style={{ alignItems: 'center', textTransform: 'uppercase' }}>
				{showMore ? t('button.show_less') : t('button.show_more')}
				<StyledIcon size={12} marginSvg={'0'} rotate={showMore ? 90 : -90}>
					<IconKeyboardArrowLeft />
				</StyledIcon>
			</StyledTitle>
		);
	};

	const renderContainerLabel = (label: string) => {
		return (
			<StyledWrapperContent width="100%">
				<StyledLabelField
					maxWidth={`calc(100% - 74px - ${isCustom ? '71px' : '0px'})`}
					className={`custom_label ${showMore ? '' : 'overflow_text'}`}>
					{label}
				</StyledLabelField>
				{!showMore && renderStatusShowMore()}
			</StyledWrapperContent>
		);
	};

	return (
		<StyledFieldItem {...other}>
			<StyledCustomFieldItem>
				<StyledWrapperLabel>
					{isCustom && (
						<StyledWrapperContent width="100%">
							{!showMore && renderContainerLabel(labelField || '')}
							<StyledFormItem
								className={showMore ? '' : 'hidden'}
								name={nameLabel}
								heightLabel={'unset'}
								label={renderContainerLabel(
									t('content.widget_manager.widget_builder.custom_property_name'),
								)}
								rules={[
									{
										required: true,
										message: t(
											'content.widget_manager.widget_builder.required',
										),
									},
								]}>
								<StyledInputCustomField
									maxLength={otherInfo?.maxLengthShortText || 255}
									value={labelField || ''}
									id={nameLabel}
									placeholder={t(
										'content.widget_manager.widget_builder.name_custom_property',
									)}
								/>
							</StyledFormItem>
						</StyledWrapperContent>
					)}
					{!isCustom && renderContainerLabel(renderLabel())}
					<StyledWrapperAction
						style={{ position: 'absolute', right: 0, top: 0 }}
						height="24px">
						{propSort && (
							<StyledIcon
								{...propSort}
								className="icon_drag"
								style={{ margin: '0 6px 0 0' }}>
								<IconDragHandle />
							</StyledIcon>
						)}
						{!showMore && isCustom && (
							<StyledWrapperContent margin="0 6px 0 0">
								<SwitchMapping
									checked={field?.mapping === MAPPING_FIELD.CONTENT}
									keyIndex={keyIndex}
									disabled={!!isExist}
									hideTitle
								/>
							</StyledWrapperContent>
						)}
						<StyledCheckbox
							onChange={(e: any) => {
								e.stopPropagation();

								handleChangeRequired();
							}}
							disabled={field?.defaultRequired}
							checked={field?.required}
						/>
					</StyledWrapperAction>
				</StyledWrapperLabel>
				<StyledFormItem
					name={keyName}
					className={showMore ? '' : 'hidden'}
					label={
						isCustom
							? t('content.widget_manager.widget_builder.form_placeholder_text')
							: undefined
					}
					rules={[
						{
							required: true,
							message: t('content.widget_manager.widget_builder.required'),
						},
					]}>
					<StyledInputCustomField
						maxLength={otherInfo?.maxLengthShortText || 255}
						id={keyName}
						placeholder={t(
							!isCustom && field.type === TYPE_CUSTOM_FIELD.paragraph
								? 'content.widget_manager.widget_builder.add_caption'
								: 'content.widget_manager.widget_builder.enter_display_uploader',
						)}
					/>
				</StyledFormItem>
				<StyledWrapperTitle>
					{showMore && renderStatusShowMore()}
					{isCustom && showMore && (
						<SwitchMapping
							checked={field?.mapping === MAPPING_FIELD.CONTENT}
							keyIndex={keyIndex}
							disabled={!!isExist}
						/>
					)}
				</StyledWrapperTitle>
			</StyledCustomFieldItem>
			{!field?.defaultRequired && <StyledIconRemoveCircle onClick={handleRemoveField} />}
		</StyledFieldItem>
	);
};

export default CustomInputField;
