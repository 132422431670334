import { IconCampaign } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import { StyledIcon, StyledText, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

type AssociatedCampaignChipProps = {
	template: EmailTemplatesDataDto;
};

const AssociatedCampaignChip = (props: AssociatedCampaignChipProps) => {
	const { t } = useTranslation();
	const { template } = props;
	const { campaignsName = [] } = template || {};

	const renderContentPopover = () => {
		return (
			<StyledWrapperContent margin="-4px" width="200px" maxHeight="200px" overflow="auto">
				{campaignsName.map((item: string, index: number) => {
					const displayedValue = `${index + 1}. ${item}`;

					return (
						<StyledText key={displayedValue} margin="0 0 4px 0">
							{displayedValue}
						</StyledText>
					);
				})}
			</StyledWrapperContent>
		);
	};

	return (
		<StyledWrapperContent
			display="flex"
			fontSize="12px"
			borderRadius="2px"
			padding="0px 6px"
			background={THEME.colors.darkBlue3}
			width="fit-content"
			lineHeight="22px"
			position="absolute"
			right="15px"
			bottom="18px">
			{t('email_templates.assigned_to_campaign')}
			<Popover overlayClassName="overlay_dark_blue3" content={renderContentPopover()}>
				<StyledIcon size={14} margin="0 0 0 4px" fillPath={THEME.colors.yellowBase}>
					<IconCampaign />
				</StyledIcon>
			</Popover>
		</StyledWrapperContent>
	);
};

export default AssociatedCampaignChip;
