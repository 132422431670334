import { s3URL } from '@config/index';
import { PATTERNS, TYPE_FORMAT_DATE } from '@constants/common';
import { EMAIL_BUILDER } from '@constants/messages';
import _ from 'lodash';
import moment from 'moment';
import XLSX from 'xlsx';
import { getUser } from './userHelpers';

const saveAs = (blob: any, fileName: string) => {
	const url = window.URL.createObjectURL(blob);
	const anchorElem = document.createElement('a');
	anchorElem.href = url;
	anchorElem.download = fileName;
	document.body.appendChild(anchorElem);
	anchorElem.click();
	document.body.removeChild(anchorElem);
	// On Edge, revokeObjectURL should be called only after
	// a.click() has completed, atleast on EdgeHTML 15.15048
	setTimeout(() => {
		window.URL.revokeObjectURL(url);
	}, 1000);
};

export const handleDownloadExcel = (base64: string, fileNamePrefix: string, otherName?: string) => {
	const byteCharacters = atob(base64);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i += 1) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob1 = new Blob([byteArray], { type: 'application/octet-stream' });
	const timeName = `${moment().format(TYPE_FORMAT_DATE.TIME)}.xlsx`;
	const fileName = otherName ? `${otherName}${timeName}` : `${fileNamePrefix} ${timeName}`;
	saveAs(blob1, fileName);
};

export const handleDownloadRewardsTemplate = () => {
	const workbook = XLSX.utils.book_new();
	const sheet = XLSX.utils.json_to_sheet([{}], {
		header: ['CODE', 'SOURCE'],
	});
	XLSX.utils.sheet_add_json(sheet, [], { origin: 'A1' });
	XLSX.utils.book_append_sheet(workbook, sheet);
	XLSX.writeFile(workbook, 'code_template.xlsx');
};

export const uploadImageTinyMCE = (cb: any) => {
	const user = getUser();
	const { clientId, token } = user;
	const input: HTMLInputElement | any = document.createElement('input');
	input.setAttribute('type', 'file');
	input.setAttribute('accept', 'image/*');
	if (!_.isUndefined(input) && input !== null) {
		input.onchange = () => {
			const file = input?.files[0];
			const reader: any = new FileReader();
			reader.onload = () => {
				const id = 'blobid' + new Date().getTime();
				const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
				const base64 = reader.result.split(',')[1];
				const blobInfo = blobCache.create(id, file, base64);
				blobCache.add(blobInfo);
				const url = EMAIL_BUILDER.API_UPLOAD;
				const data: any = {};
				data.content = base64;
				data.clientId = clientId;
				const json = JSON.stringify(data);

				try {
					/* eslint-disable */
					new Promise((resolve, reject) => {
						const xhr = new XMLHttpRequest();
						xhr.open('POST', url, true);
						xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
						xhr.setRequestHeader('Authorization', `Bearer ${token}`);
						xhr.onload = () => {
							const result = JSON.parse(xhr.response);
							cb(s3URL + result.result, { title: file.name });
							resolve(xhr.responseText);
						};
						xhr.onerror = () => {
							cb(blobInfo.blobUri(), { title: file.name });
							reject(xhr.statusText);
						};
						xhr.send(json);
					});
					/* eslint-disable */
				} catch (e) {
					console.log(e);
				}
			};
			reader.readAsDataURL(file);
		};
	}
	input.click();
};

export const handleCheckIsBase64 = (val: string) => {
	return val.startsWith('data:') && val?.includes('base64');
};

export const fileToBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const checkIsUrl = (val: string) => {
	const arrUrl = ['http://', 'https://'];
	return val.includes(arrUrl[0]) || val.includes(arrUrl[1]);
};

export const formatBase64Image = (val: string) => {
	if (checkIsUrl(val)) {
		return val;
	}
	return `data:application/octet-stream;base64, ${val}`;
};

export const formatBase64Pdf = (val: string) => {
	if (checkIsUrl(val)) {
		return val;
	}
	return `data:application/pdf;base64, ${val}`;
};
