import { IconMoreVert } from '@assets/icons';
import { TYPE_ACTIONS } from '@constants/common';
import { SAVED_CONTENT_FILTER_TYPES } from '@constants/filter/filterContent';
import THEME from '@constants/themes/themes';
import FilterContentContext from '@contexts/Filter/filterContent';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import {
	StyledSubMenu,
	StyledTitle,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledButtonMore, StyledDropdown } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { Menu } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type PropTypes = {
	handleActionEdit: () => void;
};

const SelectedFilterInfo = (props: PropTypes) => {
	const { t } = useTranslation();

	const { handleActionEdit } = props;
	const {
		handleOverwrite,
		handleActionConfirm,
		filterSelected,
		handleSelectFilter,
		hasChangeFilter,
		handleSelectFilterRemove,
		setVisibleCreateAlbum,
	} = useContext(FilterContentContext);
	const { filterList = [] } = useSelector((state: ContentRootStateDto) => state.content);

	const actionMoreObj: ActionsTypes[] = [
		{
			id: 1,
			name: t('button.create_smart_album'),
			click: () => {
				setVisibleCreateAlbum(true);
			},
		},
		{
			id: 2,
			name: t('button.save_as_new'),
			click: () => {
				handleActionConfirm(TYPE_ACTIONS.CREATE_FILTER);
			},
		},
		{
			id: 3,
			name: t('button.overwrite_this_filter'),
			click: () => handleOverwrite(),
			disabled:
				!hasChangeFilter || filterSelected?.type !== SAVED_CONTENT_FILTER_TYPES.CUSTOM,
		},
		{
			id: 4,
			name: t('button.select_another_filter'),
			click: () => {},
			children: filterList
				.filter((f) => f.id !== filterSelected?.id)
				.map((filter) => ({
					id: new Date().getTime() + filter.id,
					name: filter.name,
					click: () => {
						handleSelectFilter(filter.id);
					},
				})),
		},
		{
			id: 5,
			name: t('filter.filter_content.rename_filter'),
			click: () => handleActionEdit(),
			disabled: filterSelected?.type !== SAVED_CONTENT_FILTER_TYPES.CUSTOM,
		},
		{
			id: 6,
			name: t('filter.filter_content.delete_filter'),
			disabled: filterSelected?.type !== SAVED_CONTENT_FILTER_TYPES.CUSTOM,
			click: () => {
				if (filterSelected) {
					handleSelectFilterRemove(filterSelected);
				}
			},
		},
	];

	const renderMenuAction = (actionList: ActionsTypes[]) => {
		return (
			<Menu triggerSubMenuAction="click">
				{actionList.map((item: ActionsTypes) => {
					if (item?.hidden) {
						return null;
					}
					const menuItem = (action: ActionsTypes) => {
						return (
							<Menu.Item
								key={action.id}
								onClick={action.click}
								disabled={action?.disabled}>
								{action.name}
							</Menu.Item>
						);
					};
					if (item.children) {
						return (
							<StyledSubMenu
								key={item.id}
								popupOffset={[-5, 0]}
								popupClassName="mh_50vh overlay"
								className="hidden_icon sub_menu_item"
								title={item.name}>
								{item?.children.map((ch) => menuItem(ch))}
							</StyledSubMenu>
						);
					}
					return menuItem(item);
				})}
			</Menu>
		);
	};

	return (
		<>
			<StyledWrapperTitle>
				<StyledWrapperContent display="inline-flex" width="calc(100% - 37px)">
					<StyledTitle
						color={THEME.colors.grayCustom1}
						fontSize="16px"
						display="inline"
						className="unset_height">
						{t('filter.filter_content.editing_saved_filter')}
					</StyledTitle>
					<StyledTitle
						margin="0 0 0 5px"
						fontSize="16px"
						display="inline-block"
						maxWidth="calc(100% - 151px)"
						title={filterSelected?.name}
						className="unset_height overflow_text">
						{filterSelected?.name}
					</StyledTitle>
				</StyledWrapperContent>

				<StyledDropdown
					className="dropdown_action"
					overlay={renderMenuAction(actionMoreObj)}
					getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
					placement="bottomLeft"
					trigger={['click']}>
					<StyledButtonMore>
						<IconMoreVert />
					</StyledButtonMore>
				</StyledDropdown>
			</StyledWrapperTitle>
		</>
	);
};

export default SelectedFilterInfo;
