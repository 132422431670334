import { CaretRightOutlined } from '@ant-design/icons';
import DetectThumbnailContent from '@components/ContentGlobal/DetectThumbnailContent';
import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { FILTER_BY, MEDIA_TYPE } from '@constants/content/contentLibrary';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { StyledCol } from '@styled/Common/CommonStyled';
import { StyledThemeGallery } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledIconPlay } from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import { getSocialS3URL } from '@utils/common';
import { checkExistExplicit } from '@utils/content';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropThumbItem = {
	content: ContentDto;
	span?: number;
};
const ContentGallery = (props: PropThumbItem) => {
	const { content, span, ...other } = props;
	const { t } = useTranslation();
	const contentRef = useRef<any>({});
	const [widthContent, setWidthContent] = useState<number>(0);

	const renderIconVideo = (contentItem: ContentDto) => {
		if (contentItem?.mediaType === FILTER_BY.VIDEO.value) {
			return (
				<StyledIconPlay className="icon_play">
					<CaretRightOutlined />
				</StyledIconPlay>
			);
		}
		return '';
	};

	const handleSetWidthContent = () => {
		if (contentRef.current && contentRef.current.offsetWidth !== null) {
			setWidthContent(contentRef.current.offsetWidth);
		}
	};

	useEffect(() => {
		handleSetWidthContent();
	}, []);

	return (
		<StyledCol style={{ padding: 0 }} ref={contentRef} span={span || 6}>
			<StyledThemeGallery
				style={{
					width: `${widthContent}px`,
					display: 'inline-flex',
					transition: 'all 0s',
					padding: '4px',
				}}>
				{renderIconVideo(content)}

				<DetectThumbnailContent
					thumbnail={getSocialS3URL(content?.thumbnail)}
					dynamicThumbnail={getSocialS3URL(content?.dynamicThumbnail)}
					isVideo={content?.mediaType === MEDIA_TYPE.VIDEO}
					contentExternalUrl={content?.contentExternalUrl}
					social={content?.social}
					className="placeholder_bg_img"
				/>
				{checkExistExplicit(content?.review?.flags) && (
					<ExplicitContent zIndex={777} sizeIcon={48} fontSize="14px" />
				)}
			</StyledThemeGallery>
		</StyledCol>
	);
};

export default memo(ContentGallery);
