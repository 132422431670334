import { LIMIT } from '@constants/common';
import { IN_PROGRESS } from '@constants/status';
import RegistrationAuthenticatorAppContext from '@contexts/Settings/RegistrationAuthenticatorApp';
import { confirmMFAPairingRequest } from '@stores/actions';
import { UserStoreType } from '@stores/user/user.types';
import {
	StyledButton,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledText,
} from '@styled/Common/CommonStyled';
import { StyledInputMFACode } from '@styled/LoginStyled';
import { validateInputNumber } from '@utils/common';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const ConfirmPairing = () => {
	const { t } = useTranslation();
	const [form] = useForm();
	const dispatch = useDispatch();
	const { authenticatorAppInfo, confirmMFAPairingStatus }: UserStoreType = useSelector(
		(state: any) => state.user,
	);
	const { onCancel } = useContext(RegistrationAuthenticatorAppContext);
	const { secretKey = '' } = authenticatorAppInfo || {};

	const confirmPairingCode = (payload: { code: string; secretKey: string }) => {
		dispatch(confirmMFAPairingRequest(payload));
	};

	const handleOnFinish = (values: any) => {
		if (secretKey) {
			const payload = { ...values, secretKey };
			confirmPairingCode(payload);
		}
	};

	const isLoading = confirmMFAPairingStatus === IN_PROGRESS;

	return (
		<>
			<StyledText margin="0 0 12px 0">
				{t('setting.user_profile.enter_digit_authentication_code')}
			</StyledText>
			<StyledForm form={form} onFinish={handleOnFinish}>
				<StyledFormItem
					rules={[
						{
							required: true,
							message: t('validation.required', {
								field: t('setting.user_profile.message.authentication_code'),
							}),
						},
					]}
					name="code">
					<StyledInputMFACode
						autoFocus
						maxLength={LIMIT.PAIRING_CODE}
						onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
							validateInputNumber(event);
						}}
					/>
				</StyledFormItem>
				<StyledFlex justify="flex-end">
					<StyledButton margin="0 12px 0 0" onClick={onCancel}>
						{t('button.cancel')}
					</StyledButton>
					<StyledButton loading={isLoading} type="primary" onClick={form.submit}>
						{t('button.confirm')}
					</StyledButton>
				</StyledFlex>
			</StyledForm>
		</>
	);
};

export default ConfirmPairing;
