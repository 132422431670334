// Icons
import { CaretRightOutlined } from '@ant-design/icons';
import DetectThumbnailContent from '@components/ContentGlobal/DetectThumbnailContent';
import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { FavoriteBehavior } from '@components/ThumbnailBehavior/FavoriteBehavior';
import { HighlightPendingContent } from '@components/ThumbnailBehavior/HighlightPendingContent';
import { ThumbnailBehaviorChip } from '@components/ThumbnailBehavior/ThumbnailBehaviorChip';
import { CONTENT_FOLDER, FILTER_BY, FOLDER, MEDIA_TYPE } from '@constants/content/contentLibrary';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import { updateContentStatusRecentlyAddedRequest } from '@stores/actions';
// Styled
import {
	StyledIconPlay,
	StyledMediaItem,
	StyledMediaItemContainer,
} from '@styled/Content/ContentLibrary/MediaItemStyled';
import { getContentByType, getSocialS3URL } from '@utils/common';
import { checkExistExplicit } from '@utils/content';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Libraries
import { TSelectableItemProps } from 'react-selectable-fast';

type MediaItemProps = {
	content: ContentContainerDto;
	sizeImage: any;
	selectContent: (content: ContentContainerDto) => void;
	openContentDetail?: () => void;
	widthContent: number;
	selectContentUpdate?: (content: ContentContainerDto) => void;
	markAsFavoriteContent: (content: ContentDto) => void;
	namePage?: string;
	contentList: ContentContainerDto[];
	setContentList: (list: ContentContainerDto[]) => void;
	isMoreContent?: boolean;
	[other: string]: any;
};

const MediaItem = (props: TSelectableItemProps & MediaItemProps) => {
	const {
		selectableRef,
		content,
		isSelecting,
		isSelected,
		selectContent,
		selectContentUpdate,
		sizeImage,
		widthContent,
		markAsFavoriteContent,
		contentList,
		setContentList,
		namePage = '',
		isMoreContent,
	} = props;

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const mediaRef = useRef<any>(null);
	const convertHeight = parseInt(sizeImage.height.substring(0, sizeImage.height.length - 2), 10);

	const contentDetails = useMemo(() => {
		let contentTemp: ContentDto = getContentByType(content);
		const thumbnail: string =
			contentTemp?.thumbnailReplace || getSocialS3URL(contentTemp.thumbnail);
		contentTemp = { ...contentTemp, thumbnail };

		return contentTemp;
	}, [content]);

	// Functions
	const onChangeStatusRecentlySuccess = () => {
		contentList.forEach((item) => {
			const contentTemp = getContentByType(item);
			if (contentTemp.id === contentDetails?.review.contentId) {
				contentTemp.review.isRecentlyAdded = false;
			}
		});
		setContentList([...contentList]);
	};

	const onChangeStatusRecently = () => {
		if (contentDetails?.id) {
			onChangeStatusRecentlySuccess();
			handleUpdateStatusRecentlyAdded([contentDetails?.id]);
		}
	};

	const handleUpdateStatusRecentlyAdded = (contentIds: number[]) => {
		dispatch(updateContentStatusRecentlyAddedRequest({ contentIds }));
	};

	const classNames = [
		'media-item',
		isSelecting && 'media-selecting',
		isSelected && 'media-selected',
	]
		.filter(Boolean)
		.join(' ');

	const thumbnailChip = {
		content: contentDetails,
		convertHeight,
		onChangeStatusRecently,
		markAsFavoriteContent,
	};

	const ratioScale = sizeImage.widthNum / (sizeImage.heightNum || 1);
	const scale = ratioScale > 1 ? ratioScale : 1;

	return (
		<StyledMediaItem
			ref={selectableRef}
			className={classNames}
			height={sizeImage.height}
			onDoubleClick={() => {
				selectContent(content);
			}}
			onClick={() => (selectContentUpdate ? selectContentUpdate(content) : null)}>
			{sizeImage.height && (
				<StyledMediaItemContainer ref={mediaRef}>
					{contentDetails?.mediaType &&
						contentDetails?.mediaType === FILTER_BY.VIDEO.value && (
							<StyledIconPlay height={convertHeight}>
								<CaretRightOutlined />
							</StyledIconPlay>
						)}

					{contentDetails &&
						namePage !== CONTENT_FOLDER.ARCHIVE &&
						namePage !== FOLDER.REJECTED && <FavoriteBehavior {...thumbnailChip} />}
					{contentDetails && <ThumbnailBehaviorChip {...thumbnailChip} />}

					<DetectThumbnailContent
						scale={(contentDetails?.rotate || 0) % 180 === 0 ? 1 : scale}
						social={contentDetails?.social}
						thumbnail={getSocialS3URL(contentDetails?.thumbnail)}
						dynamicThumbnail={getSocialS3URL(contentDetails?.dynamicThumbnail)}
						id={contentDetails.id}
						rotate={contentDetails?.rotate}
						isVideo={contentDetails?.mediaType === MEDIA_TYPE.VIDEO}
						contentExternalUrl={contentDetails?.contentExternalUrl}
					/>

					<HighlightPendingContent content={contentDetails} />
				</StyledMediaItemContainer>
			)}
			{checkExistExplicit(contentDetails?.review?.flags) && (
				<ExplicitContent zIndex={88} sizeIcon={isMoreContent ? 48 : 72} fontSize="16px" />
			)}
		</StyledMediaItem>
	);
};

export default MediaItem;
