import THEME from '@constants/themes/themes';
import { StyledSection, StyledTag, StyledText } from '@styled/Common/CommonStyled';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
	caption: string;
	hashtags: string[];
	mentionUsernames: string[];
};

const SocialHashTagMention = (props: PropTypes) => {
	const { t } = useTranslation();
	const { caption, hashtags, mentionUsernames } = props;
	return (
		<>
			<StyledSection margin="16px 0 0 0">
				<StyledText margin="0 0 10px 0" color={THEME.colors.gray1}>
					{t('social_search.details.caption')}
				</StyledText>
				<StyledText>{`“ ${caption} ”`}</StyledText>
			</StyledSection>

			<StyledSection margin="16px 0 0 0">
				<StyledText margin="0 0 6px 0" color={THEME.colors.gray1}>
					{t('social_search.details.hashtags')}
				</StyledText>
				{hashtags.map((item: string, index: number) => {
					const key = `${item}_${index}`;

					return (
						<StyledTag key={key} margin="6px 6px 6px 0">
							{item}
						</StyledTag>
					);
				})}
			</StyledSection>

			<StyledSection margin="16px 0 0 0">
				<StyledText margin="0 0 6px 0" color={THEME.colors.gray1}>
					{t('social_search.details.mentions')}
				</StyledText>
				{mentionUsernames.map((item: string, index: number) => {
					const key = `${item}_${index}`;
					return <StyledTag key={key} margin="6px 6px 6px 0">{`@${item}`}</StyledTag>;
				})}
			</StyledSection>
		</>
	);
};

export default SocialHashTagMention;
