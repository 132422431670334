import { MORE_OPTION_WIDGET, TAB_WIDGET } from '@constants/content/widgetManager';
import {
	CampaignItemDto,
	FilterWidgetDto,
	WidgetBuilderDetailDto,
	WidgetBuilderDetailNoIdDto,
	WidgetBuilderDetailRequestDto,
} from '@models/content/widgetManager';
import * as React from 'react';

export type WidgetManagerContextType = {
	hideDetail: boolean;
	setHideDetail: (val: boolean) => void;
	setWidgetSelect: (item: WidgetBuilderDetailRequestDto) => void;
	widgetSelect: WidgetBuilderDetailRequestDto | null;
	listWidget: WidgetBuilderDetailRequestDto[];
	setIsMobile: (val: boolean) => void;
	isMobile: boolean;
	filter: FilterWidgetDto;
	setFilter: (val: FilterWidgetDto) => void;
	handleCreateNewWidgetBuilder: (
		dataRequest?: WidgetBuilderDetailNoIdDto,
		templateId?: number,
	) => void;
	setTypeConfirm: (val: string) => void;
	setIsLoading: (val: boolean) => void;
	listCampaign: CampaignItemDto[];
	setListCampaign: (val: CampaignItemDto[]) => void;
	setListWidgetManagers: (list: Array<WidgetBuilderDetailRequestDto>) => void;
	handleCheckMessageRemove: () => void;
	handleSetDefaultWidget: () => void;
	handleDuplicate: () => void;
};

// Default context values
const values: WidgetManagerContextType = {
	hideDetail: true,
	setHideDetail: () => {},
	setWidgetSelect: () => {},
	widgetSelect: null,
	listWidget: [],
	setIsMobile: () => {},
	isMobile: false,
	filter: { status: TAB_WIDGET.live, ...MORE_OPTION_WIDGET },
	setFilter: () => {},
	handleCreateNewWidgetBuilder: () => {},
	setTypeConfirm: () => {},
	setIsLoading: () => {},
	listCampaign: [],
	setListCampaign: () => {},
	setListWidgetManagers: () => {},
	handleCheckMessageRemove: () => {},
	handleSetDefaultWidget: () => {},
	handleDuplicate: () => {},
};

const WidgetManagerContext = React.createContext(values);
export default WidgetManagerContext;
