import { IconCopy } from '@assets/icons';
import { DEFAULT_WIDTH_COL } from '@constants/common';
import { Paginator } from '@constants/paginator';
import { GUEST_PORTAL_FIELD_NAME, GUEST_PORTAL_MODAL_TYPE } from '@constants/settings/guestPortal';
import THEME from '@constants/themes/themes';
import GuestPortalContext from '@contexts/Settings/GuestPortal';
import { ColumnDto } from '@models/common/summary';
import { GuestPortalItemDto, GuestPortalStoreDto } from '@models/settings/guestPortal';
import {
	StyledCol,
	StyledIcon,
	StyledRow,
	StyledTable,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledPagination } from '@styled/Settings/AccountUserStyled';
import { renderCopiedText } from '@utils/renderComponent';
import { Affix, Pagination } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GuestPortalStatus from '../GuestPortalStatus';

const GuestPortalList = () => {
	const {
		setGuestPortalDetail,
		selectedGuestPortals,
		setSelectedGuestPortals,
		setModalType,
		setConfirmationModalType,
		requestPayload,
		setRequestPayload,
		handleCopiedClipboardMessage,
	} = useContext(GuestPortalContext);
	const { t } = useTranslation();
	const { guestPortalResult }: GuestPortalStoreDto = useSelector(
		(state: any) => state.guestPortal,
	);

	const renderURL = (url: string) => {
		return (
			<StyledRow padding="0 4px" justify="space-between" align="middle">
				<StyledCol span={22}>
					<StyledText
						className="overflow"
						cursor="pointer"
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							e.stopPropagation();
							e.preventDefault();
							window.open(url, '_blank');
						}}
						color={THEME.colors.gray5}
						fontWeight="normal"
						lineHeight="normal"
						title={url}>
						{url}
					</StyledText>
				</StyledCol>
				<StyledCol span={2}>
					<StyledIcon
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopiedClipboardMessage(url);
						}}
						size={22}>
						<IconCopy />
					</StyledIcon>
				</StyledCol>
			</StyledRow>
		);
	};

	const renderStatus = (record: GuestPortalItemDto) => {
		return (
			<GuestPortalStatus
				guestPortalDetail={record}
				styles={{ margin: '0 auto' }}
				setConfirmationModalType={setConfirmationModalType}
				setGuestPortalDetail={setGuestPortalDetail}
			/>
		);
	};

	const renderTabStatus = (record: GuestPortalItemDto, fieldName: string) => {
		const { metadata: { tabConfiguration = {} } = {} } = record || {};
		let text = tabConfiguration[fieldName]?.status
			? t('setting.guest_portal.shown')
			: t('setting.guest_portal.hidden');

		if (fieldName === GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB) {
			text = t('setting.guest_portal.hidden');
			if (tabConfiguration[fieldName]?.status) {
				if (tabConfiguration[fieldName]?.[GUEST_PORTAL_FIELD_NAME.IS_ALL_ALBUMS]) {
					text = t('setting.guest_portal.all_public_albums');
				} else {
					text = t('setting.guest_portal.limited');
				}
			}
		}

		return <StyledText textTransform="capitalize">{text}</StyledText>;
	};

	const columns: ColumnDto[] = [
		{
			title: t('setting.guest_portal.name'),
			dataIndex: 'name',
			key: 'name',
			render: (val: string) => renderCopiedText(val),
			width: DEFAULT_WIDTH_COL,
		},
		{
			title: t('setting.guest_portal.url'),
			dataIndex: 'url',
			key: 'url',
			render: renderURL,
			width: DEFAULT_WIDTH_COL * 2.25,
		},
		{
			title: t('task_manager.task_list.status'),
			key: 'status',
			render: renderStatus,
			width: DEFAULT_WIDTH_COL - 25,
			align: 'center',
		},
		{
			title: t('task_manager.task_list.description'),
			dataIndex: 'description',
			key: 'description',
			render: (val: string) => renderCopiedText(val),
			width: DEFAULT_WIDTH_COL * 1.5,
		},
		{
			title: t('setting.guest_portal.media_library_tab'),
			key: 'mediaHubTab',
			render: (record: GuestPortalItemDto) =>
				renderTabStatus(record, GUEST_PORTAL_FIELD_NAME.MEDIA_HUB_TAB),
			align: 'center',
			width: DEFAULT_WIDTH_COL,
		},
		{
			title: t('setting.guest_portal.creators_tab'),
			key: 'creatorsTab',
			render: (record: GuestPortalItemDto) =>
				renderTabStatus(record, GUEST_PORTAL_FIELD_NAME.CREATORS_TAB),
			align: 'center',
			width: DEFAULT_WIDTH_COL,
		},
	];

	const onSelectGuestPortal = (selectedRowKeys: React.Key[]) => {
		const selectedListTemp: GuestPortalItemDto[] = [];
		selectedRowKeys.forEach((key) => {
			const selectedItem = guestPortalResult?.guestPortalResponses?.find(
				(item: GuestPortalItemDto) => item.id === key,
			);
			if (selectedItem) {
				selectedListTemp.push(selectedItem);
			}
		});
		setSelectedGuestPortals(selectedListTemp);
	};

	const rowSelection = {
		selectedRowKeys: selectedGuestPortals.map((guestPortal) => guestPortal.id),
		onChange: onSelectGuestPortal,
	};

	const onChangePage = (pageNumber: number) => {
		if (requestPayload) {
			setRequestPayload({ ...requestPayload, page: pageNumber });
		}
	};

	return (
		<StyledWrapperContent>
			<StyledTable
				className="table_row_action"
				dataSource={guestPortalResult?.guestPortalResponses || []}
				columns={columns}
				pagination={false}
				rowSelection={rowSelection}
				bodyTableHeight="calc(100vh - 265px)"
				scroll={{ y: 'calc(100vh - 265px)' }}
				onRow={(record: GuestPortalItemDto) => {
					return {
						onClick: () => {
							if (record) {
								setModalType(GUEST_PORTAL_MODAL_TYPE.ACTION_ON_GUEST);
								setGuestPortalDetail(record);
							}
						},
					};
				}}
				rowKey="id"
			/>
			<Affix offsetBottom={0}>
				<StyledPagination>
					<Pagination
						total={guestPortalResult?.totalRecords}
						pageSize={Paginator.pageSize}
						defaultPageSize={Paginator.pageSize}
						current={requestPayload.page}
						showSizeChanger={false}
						onChange={onChangePage}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${total || 0} ${t(
								`${total > 1 ? 'pagination.items' : 'pagination.item'}`,
							)}`
						}
					/>
				</StyledPagination>
			</Affix>
		</StyledWrapperContent>
	);
};

export default GuestPortalList;
