import { CLONE_LIVE_WIDGET, RESUME_LIVE_DRAFT, WIDGET_BUILDER_DETAIL } from '@constants/APIs';
import { ROUTE_PATH, TYPE_ACTIONS } from '@constants/common';
import { TAB_WIDGET } from '@constants/content/widgetManager';
import { DEFAULT_ERROR } from '@constants/errors';
import { ConfirmModal } from '@cores/Modal';
import { getRequest, postRequest } from '@helpers/requestHelpers';
import { WidgetBuilderDetailRequestDto } from '@models/content/widgetManager';
import { RefCheckRedirect } from '@models/content/widgetManager/ref';
import { message } from 'antd';
import { ReactNode, Ref, forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type PropTypes = {
	setLoading?: (val: boolean) => void;
	[other: string]: any;
	widgetId: number;
	children: ReactNode;
};
const CheckRedirectDetail = forwardRef((props: PropTypes, ref: Ref<RefCheckRedirect>) => {
	const { t } = useTranslation();
	const history = useHistory();

	const [typeConfirm, setTypeConfirm] = useState<string>('');
	const [widgetDetail, setWidgetDetail] = useState<WidgetBuilderDetailRequestDto | null>(null);

	const { setLoading, widgetId, children } = props;

	const handleLoading = (val: boolean) => {
		if (setLoading && typeof setLoading === 'function') {
			setLoading(val);
		}
	};

	useImperativeHandle(ref, () => ({ onClickCheck: handleFetchDetailWidget }));

	const handleRedirect = (id?: number) => {
		history.push(`${ROUTE_PATH.WIDGET_MANAGER}/${id}`, { step: 4 });
	};

	const handleFetchDetailWidget = () => {
		handleLoading(true);

		getRequest(WIDGET_BUILDER_DETAIL, { widgetId })
			.then((response: any) => {
				if (response.status === 200) {
					const detail: WidgetBuilderDetailRequestDto =
						response.body.data?.result || null;
					handleLoading(false);

					if (detail) {
						setWidgetDetail(detail);
						handleEditDesign(detail);
					} else {
						handleLoading(false);
					}
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
					handleLoading(false);
				}
			})
			.catch((err: any) => {
				message.error(DEFAULT_ERROR);
				handleLoading(false);
			});
	};

	const handleCreateNewWidgetBuilder = (widgetId?: number) => {
		handleApiCheckRedirect(`${CLONE_LIVE_WIDGET}/${widgetId}`);
	};

	const handleApiCheckRedirect = async (url: string) => {
		handleLoading(true);
		if (typeConfirm) {
			setTypeConfirm('');
		}

		await postRequest(url)
			.then((response: any) => {
				if (response.status === 200) {
					const widgetBuilder: WidgetBuilderDetailRequestDto =
						response.body.data?.result || null;

					if (widgetBuilder) {
						handleRedirect(widgetBuilder?.id);
					} else {
						message.error(response.body.data.error || DEFAULT_ERROR);
					}
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
			})
			.catch((err: any) => {
				message.error(DEFAULT_ERROR);
			})
			.finally(() => {
				handleLoading(false);
			});
	};

	const handleEditDesign = (widget: WidgetBuilderDetailRequestDto) => {
		if (widget?.status === TAB_WIDGET.live) {
			if (widget.referenceId) {
				handleLoading(false);
				setTypeConfirm(TYPE_ACTIONS.EDIT_DESIGN);
			} else {
				handleCreateNewWidgetBuilder(widget.id);
			}
		} else {
			handleRedirect(widget.id);
		}
	};

	const handleConfirmPopup = () => {
		handleApiCheckRedirect(`${RESUME_LIVE_DRAFT}/${widgetDetail?.id}`);
	};

	const renderContentPopupConfirm = () => {
		if (typeConfirm === TYPE_ACTIONS.EDIT_DESIGN) {
			return (
				<>
					{t('content.widget_manager.popup.message_alert_edit')} <br />
					{t('content.widget_manager.popup.message_alert_question_edit')}
				</>
			);
		}
		return null;
	};

	const otherPropConfirm = useMemo(() => {
		const result: any = {};
		if (typeConfirm === TYPE_ACTIONS.EDIT_DESIGN) {
			result.onClickBtnCancel = () => handleCreateNewWidgetBuilder(widgetDetail?.id);
			result.cancelText = t('content.widget_manager.button.start_over');
			result.okText = t('content.widget_manager.button.continue_draft');
			result.onSubmit = handleConfirmPopup;
		}

		return result;
	}, [typeConfirm]);

	return (
		<>
			<ConfirmModal
				width={450}
				maskClosable={true}
				isOpen={typeConfirm ? true : false}
				onClose={() => setTypeConfirm('')}
				{...otherPropConfirm}>
				{renderContentPopupConfirm()}
			</ConfirmModal>
			{children}
		</>
	);
});

export default CheckRedirectDetail;
