import { SEND_TYPE } from '@constants/settings/integrations';
import { TremendousOrderRequestTypes } from '@models/settings/tremendous';
import React from 'react';

type TremendousModalTypes = {
	currentStep: number;
	handleChangeAction: (val: number) => void;
	orderDetail: TremendousOrderRequestTypes;
	setOrderDetail: (val: TremendousOrderRequestTypes) => void;
	isLoading: boolean;
	setIsLoading: (val: boolean) => void;
	fundingSourceTotal: number;
};

const values: TremendousModalTypes = {
	currentStep: 1,
	handleChangeAction: () => {},
	orderDetail: { deliveryMethod: SEND_TYPE.EMAIL },
	setOrderDetail: () => {},
	isLoading: false,
	setIsLoading: () => {},
	fundingSourceTotal: 0,
};

const TremendousModalContext = React.createContext(values);
export default TremendousModalContext;
