const fetchSocialContentTypes = {
	FETCH_SOCIAL_CONTENT_REQUEST: 'FETCH_SOCIAL_CONTENT_REQUEST',
	FETCH_SOCIAL_CONTENT_SUCCEEDED: 'FETCH_SOCIAL_CONTENT_SUCCEEDED',
	FETCH_SOCIAL_CONTENT_FAILED: 'FETCH_SOCIAL_CONTENT_FAILED',
};

const blockSocialContentTypes = {
	BLOCK_SOCIAL_CONTENT_REQUEST: 'BLOCK_SOCIAL_CONTENT_REQUEST',
	BLOCK_SOCIAL_FEED_CONTENT_REQUEST: 'BLOCK_SOCIAL_FEED_CONTENT_REQUEST',

	BLOCK_SOCIAL_CONTENT_SUCCEEDED: 'BLOCK_SOCIAL_CONTENT_SUCCEEDED',
	BLOCK_SOCIAL_CONTENT_FAILED: 'BLOCK_SOCIAL_CONTENT_FAILED',
	BLOCK_SOCIAL_CONTENT_END: 'BLOCK_SOCIAL_CONTENT_END',
};

const blockSocialUsernameTypes = {
	BLOCK_SOCIAL_USERNAME_REQUEST: 'BLOCK_SOCIAL_USERNAME_REQUEST',
	BLOCK_SOCIAL_FEED_USERNAME_REQUEST: 'BLOCK_SOCIAL_FEED_USERNAME_REQUEST',

	BLOCK_SOCIAL_USERNAME_SUCCEEDED: 'BLOCK_SOCIAL_USERNAME_SUCCEEDED',
	BLOCK_SOCIAL_USERNAME_FAILED: 'BLOCK_SOCIAL_USERNAME_FAILED',
	BLOCK_SOCIAL_USERNAME_END: 'BLOCK_SOCIAL_USERNAME_END',
};

const typeUpdateStatusListSocial = {
	UPDATE_STATUS_SOCIAL_LIST: 'UPDATE_STATUS_SOCIAL_LIST',
};

const importSocialContentTypes = {
	IMPORT_SOCIAL_CONTENT_REQUEST: 'IMPORT_SOCIAL_CONTENT_REQUEST',
	IMPORT_SOCIAL_FEED_CONTENT_REQUEST: 'IMPORT_SOCIAL_FEED_CONTENT_REQUEST',

	IMPORT_SOCIAL_CONTENT_SUCCEEDED: 'IMPORT_SOCIAL_CONTENT_SUCCEEDED',
	IMPORT_SOCIAL_CONTENT_FAILED: 'IMPORT_SOCIAL_CONTENT_FAILED',
	IMPORT_SOCIAL_CONTENT_END: 'IMPORT_SOCIAL_CONTENT_END',
};

const getSocialSearchFilterTypes = {
	GET_SOCIAL_SEARCH_FILTER_REQUEST: 'GET_SOCIAL_SEARCH_FILTER_REQUEST',
	GET_SOCIAL_SEARCH_FILTER_SUCCEEDED: 'GET_SOCIAL_SEARCH_FILTER_SUCCEEDED',
	GET_SOCIAL_SEARCH_FILTER_FAILED: 'GET_SOCIAL_SEARCH_FILTER_FAILED',
};

const saveSocialSearchFilterTypes = {
	SAVE_SOCIAL_SEARCH_FILTER_REQUEST: 'SAVE_SOCIAL_CONTENT_FILTER_REQUEST',
	SAVE_SOCIAL_SEARCH_FILTER_SUCCEEDED: 'SAVE_SOCIAL_CONTENT_FILTER_SUCCEEDED',
	SAVE_SOCIAL_SEARCH_FILTER_FAILED: 'SAVE_SOCIAL_CONTENT_FILTER_FAILED',
	SAVE_SOCIAL_SEARCH_FILTER_END: 'SAVE_SOCIAL_CONTENT_FILTER_END',
};

const deleteSocialSearchFilterTypes = {
	DELETE_SOCIAL_SEARCH_FILTER_REQUEST: 'DELETE_SOCIAL_SEARCH_FILTER_REQUEST',
	DELETE_SOCIAL_SEARCH_FILTER_SUCCEEDED: 'DELETE_SOCIAL_SEARCH_FILTER_SUCCEEDED',
	DELETE_SOCIAL_SEARCH_FILTER_FAILED: 'DELETE_SOCIAL_SEARCH_FILTER_FAILED',
	DELETE_SOCIAL_SEARCH_FILTER_END: 'DELETE_SOCIAL_SEARCH_FILTER_END',
};

const saveFacebookTokenTypes = {
	SAVE_FACEBOOK_TOKEN_REQUEST: 'SAVE_FACEBOOK_TOKEN_REQUEST',
	SAVE_FACEBOOK_TOKEN_SUCCEEDED: 'SAVE_FACEBOOK_TOKEN_SUCCEEDED',
	SAVE_FACEBOOK_TOKEN_FAILED: 'SAVE_FACEBOOK_TOKEN_FAILED',
};

const getKeywordTypes = {
	GET_KEY_WORD_REQUEST: 'GET_KEY_WORD_REQUEST',
	GET_KEY_WORD_SUCCEEDED: 'GET_KEY_WORD_SUCCEEDED',
	GET_KEY_WORD_FAILED: 'GET_KEY_WORD_FAILED',
	GET_KEY_WORD_END: 'GET_KEY_WORD_END',
};

const getUrlPreviewTikTokTypes = {
	GET_URL_PREVIEW_TIKTOK_REQUEST: 'GET_URL_PREVIEW_TIKTOK_REQUEST',
	GET_URL_PREVIEW_TIKTOK_SUCCEEDED: 'GET_URL_PREVIEW_TIKTOK_SUCCEEDED',
	GET_URL_PREVIEW_TIKTOK_FAILED: 'GET_URL_PREVIEW_TIKTOK_FAILED',

	SAVE_URL_PREVIEW_TIKTOK: 'SAVE_URL_PREVIEW_TIKTOK',
};

const saveKeywordTypes = {
	SAVE_KEY_WORD_REQUEST: 'SAVE_KEY_WORD_REQUEST',
	SAVE_KEY_WORD_SUCCEEDED: 'SAVE_KEY_WORD_SUCCEEDED',
	SAVE_KEY_WORD_FAILED: 'SAVE_KEY_WORD_FAILED',
	SAVE_KEY_WORD_END: 'SAVE_KEY_WORD_END',
};

const resetSocialSearchStateTypes = {
	RESET_SOCIAL_SEARCH_STATE_REQUEST: 'RESET_SOCIAL_SEARCH_STATE_REQUEST',
	RESET_SOCIAL_SEARCH_FIELD: 'RESET_SOCIAL_SEARCH_FIELD',
};

const saveFeedDetailTypes = {
	SAVE_FEED_DETAIL: 'SAVE_FEED_DETAIL',
};

const contentFeedTypes = {
	FETCH_CONTENT_FEED_REQUEST: 'FETCH_CONTENT_FEED_REQUEST',
};

const fetchMetricFeedTypes = {
	FETCH_METRIC_FEED_REQUEST: 'FETCH_METRIC_FEED_REQUEST',
	FETCH_METRIC_FEED_SUCCEEDED: 'FETCH_METRIC_FEED_SUCCEEDED',
	FETCH_METRIC_FEED_FAILED: 'FETCH_METRIC_FEED_FAILED',
};

const fetchSocialPostTypes = {
	FETCH_SOCIAL_POST_REQUEST: 'FETCH_SOCIAL_POST_REQUEST',
	FETCH_SOCIAL_POST_SUCCEEDED: 'FETCH_SOCIAL_POST_SUCCEEDED',
	FETCH_SOCIAL_POST_FAILED: 'FETCH_SOCIAL_POST_FAILED',
	FETCH_SOCIAL_POST_END: 'FETCH_SOCIAL_POST_END',
};

const fetchRecentSocialPostTypes = {
	FETCH_RECENT_SOCIAL_POST_REQUEST: 'FETCH_RECENT_SOCIAL_POST_REQUEST',
	FETCH_RECENT_SOCIAL_POST_SUCCEEDED: 'FETCH_RECENT_SOCIAL_POST_SUCCEEDED',
	FETCH_RECENT_SOCIAL_POST_FAILED: 'FETCH_RECENT_SOCIAL_POST_FAILED',
	FETCH_RECENT_SOCIAL_POST_END: 'FETCH_RECENT_SOCIAL_POST_END',
};

export {
	fetchSocialContentTypes,
	blockSocialContentTypes,
	blockSocialUsernameTypes,
	typeUpdateStatusListSocial,
	deleteSocialSearchFilterTypes,
	saveSocialSearchFilterTypes,
	resetSocialSearchStateTypes,
	getSocialSearchFilterTypes,
	importSocialContentTypes,
	saveFacebookTokenTypes,
	getKeywordTypes,
	saveKeywordTypes,
	getUrlPreviewTikTokTypes,
	saveFeedDetailTypes,
	contentFeedTypes,
	fetchMetricFeedTypes,
	fetchSocialPostTypes,
	fetchRecentSocialPostTypes,
};
