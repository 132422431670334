import { IconKeyboardArrowRight } from '@assets/icons';
import {
	FIELD_NAME_DEFAULT,
	KEY_FIELDS,
	KEY_FIELD_FORM_WIDGET,
	KEY_REPLACE_SPECIAL_CHARACTER,
	LIMIT_FIELD_WARNING,
	MAPPING_FIELD,
	TYPE_ADD_FIELDS,
	TYPE_CUSTOM_FIELD,
} from '@constants/content/widgetManager';
import THEME from '@constants/themes/themes';
import WidgetBuilderContext from '@contexts/Content/WidgetManager/WidgetBuilder';
import { FieldItemWidgetDto, WidgetBuilderDetailDto } from '@models/content/widgetManager';
import { RefConfigWidgetBuilderStep } from '@models/content/widgetManager/ref';
import { StyledIcon, StyledWrapperTitle } from '@styled/Common/CommonStyled';
import { StyledIconRemoveCircle } from '@styled/Content/WidgetManager';
import { StyledCheckbox } from '@styled/Content/WidgetManager/DetailWidgetStyled';
import {
	StyledBasicInfo,
	StyledCustomInfo,
	StyledFieldItem,
	StyledFormItem,
	StyledInput,
	StyledLabelItem,
	StyledListField,
	StyledMessageWarning,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { replaceFirstCharacterToLowercase, replaceSpecialCharacters } from '@utils/common';
import { Form } from 'antd';
import {
	ReactNode,
	Ref,
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AddCustomField from './AddCustomField';
import CustomDropdownField from './CustomDropdownField';
import CustomInputField from './CustomInputField';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomFieldWidgetRequest } from '@stores/actions';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import CustomAddressField from './CustomAddressField';
import { Draggable } from 'react-beautiful-dnd';
import SortColumnWithChildren from '@components/CustomLibrary/CustomSortColumn';

const StyledStep = styled.div``;

const StyledSubTitle = styled.div`
	font-size: 0.714rem;
	line-height: 1.2;
	color: ${THEME.colors.gray1};
`;

type PropSettingStep3 = {
	handleActionStep: () => void;
};

type PropsItemTypes = {
	index: number;
	id: string;
	renderChildren: (val: any) => any;
};

const ItemSort = ({ index, id, renderChildren }: PropsItemTypes) => {
	return (
		<Draggable draggableId={id || ''} index={index}>
			{(provided) => (
				<div className={'mt_16'} ref={provided.innerRef} {...provided.draggableProps}>
					{renderChildren(provided.dragHandleProps)}
				</div>
			)}
		</Draggable>
	);
};

const InputItem = (propInput: {
	field: FieldItemWidgetDto;
	keyIndex: number;
	handleUpdateRequiredField: (
		field: FieldItemWidgetDto,
		keyIndex: number,
		isBasic?: boolean,
	) => void;
	onRemoveBasicField: (val: number) => void;
	[other: string]: any;
}) => {
	const { field, keyIndex, handleUpdateRequiredField, onRemoveBasicField } = propInput;
	const moreProps: any = {};
	if (!field?.defaultRequired) {
		moreProps.suffix = (
			<StyledCheckbox
				onChange={() => handleUpdateRequiredField(field, keyIndex, true)}
				checked={field?.required}
			/>
		);
	}
	return (
		<StyledFieldItem className="mb-12">
			<StyledInput
				value={field?.name}
				className="field_input_item"
				disabled
				placeholder={`${field?.name}${field?.defaultRequired ? '*' : ''}`}
				suffix={
					<StyledCheckbox
						onChange={() => handleUpdateRequiredField(field, keyIndex, true)}
						disabled={field?.defaultRequired}
						checked={field?.required}
					/>
				}
			/>
			{!field?.defaultRequired && (
				<StyledIconRemoveCircle onClick={() => onRemoveBasicField(keyIndex)} />
			)}
		</StyledFieldItem>
	);
};

const SettingStep3 = forwardRef((props: PropSettingStep3, ref: Ref<RefConfigWidgetBuilderStep>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { customFields }: WidgetManagerStoreDto = useSelector(
		(state: any) => state?.widgetManager,
	);
	const { detailWidget, setDetailWidget, setHasChangeCustomField } =
		useContext(WidgetBuilderContext);
	const { metadata } = detailWidget;

	const [isEditBasicField, setIsEditBasicField] = useState<boolean>(false);
	const [idFocus, setIdFocus] = useState<string>('');

	const { handleActionStep } = props;

	const [form] = Form.useForm();

	const { basicFields = [], listFields = [] } = metadata?.body || {};

	const initialValues = useMemo(() => {
		const result: any = {};
		if (listFields?.length > 0) {
			listFields?.map((field: FieldItemWidgetDto, idx: number) => {
				if (field?.type === TYPE_CUSTOM_FIELD.dropdown) {
					result[`${KEY_FIELD_FORM_WIDGET.dropdown}${idx}`] = field?.listOption || [];
					if (field?.typeAdd === TYPE_ADD_FIELDS.CUSTOM) {
						result[`${KEY_FIELD_FORM_WIDGET.checkbox_dropdown}${idx}`] =
							field.answerType;
					}
				}
				if (field.type === TYPE_CUSTOM_FIELD.mailAddress) {
					result[`${KEY_FIELD_FORM_WIDGET.street}${idx}`] = field?.street || '';
					result[`${KEY_FIELD_FORM_WIDGET.state}${idx}`] = field?.state || '';
					result[`${KEY_FIELD_FORM_WIDGET.city}${idx}`] = field?.city || '';
					result[`${KEY_FIELD_FORM_WIDGET.zip_code}${idx}`] = field?.zipCode || '';
				}
				if (field.typeAdd === TYPE_ADD_FIELDS.CUSTOM) {
					result[`${KEY_FIELD_FORM_WIDGET.label}${idx}`] = field?.label;
					result[`${KEY_FIELD_FORM_WIDGET.mapping_type}${idx}`] = field?.mapping;
				}
				result[`${KEY_FIELD_FORM_WIDGET.item}${idx}`] = field?.name || '';
			});
		}
		return result;
	}, []);

	useEffect(() => {
		dispatch(fetchCustomFieldWidgetRequest());
		return () => {
			setIdFocus('');
		};
	}, []);

	const onRemoveBasicField = (idx: number) => {
		const newList = [...basicFields?.slice(0, idx), ...basicFields?.slice(idx + 1)];
		handleUpdateListField(newList, KEY_FIELDS.BASIC_FIELDS);
	};

	const handleChangeRequired = (field: FieldItemWidgetDto, keyIndex: number, keyName: string) => {
		const newList: FieldItemWidgetDto[] = [...metadata?.body[keyName]];
		newList[keyIndex] = { ...field, required: !field?.required };
		handleUpdateListField(newList, keyName);
	};

	const validateCustomField = async () => {
		const values = form.getFieldsValue();
		const listKey = Object.keys(values)?.filter(
			(item) => item.indexOf(KEY_FIELD_FORM_WIDGET.item) === 0,
		);
		const dataFormat: any = {};
		listKey?.forEach((item) => {
			if (values[item] && typeof values[item] === 'string') {
				dataFormat[item] = values[item]?.trim();
			} else {
				dataFormat[item] = values[item];
			}
		});
		form.setFieldsValue(dataFormat);

		await form.validateFields();
		const newList: FieldItemWidgetDto[] = listFields?.map(
			(item: FieldItemWidgetDto, idx: number) => {
				return { ...item, fieldName: renderFieldName(item, idx) };
			},
		);

		handleUpdateFieldWidget(newList);
		form.submit();
	};

	const handleFinishForm = (values: any) => {
		handleActionStep();
	};

	const handleRemoveField = (idx: number) => {
		const newList: FieldItemWidgetDto[] = [
			...listFields.slice(0, idx),
			...listFields.slice(idx + 1),
		];

		handleUpdateFieldWidget(newList);
	};

	useImperativeHandle(ref, () => ({
		validateAllFieldCustom: validateCustomField,
	}));

	const listFieldInput = useMemo(() => {
		return [
			TYPE_CUSTOM_FIELD.email,
			TYPE_CUSTOM_FIELD.phone,
			TYPE_CUSTOM_FIELD.instagram,
			TYPE_CUSTOM_FIELD.tiktok,
			TYPE_CUSTOM_FIELD.twitter,
			TYPE_CUSTOM_FIELD.shortAnswer,
			TYPE_CUSTOM_FIELD.paragraph,
		];
	}, []);

	const renderFieldName = (field: FieldItemWidgetDto, idx: number) => {
		return (
			replaceFirstCharacterToLowercase(
				replaceSpecialCharacters(field?.name, KEY_REPLACE_SPECIAL_CHARACTER),
			) + `_${idx}`
		);
	};

	const handleUpdateFieldWidget = (list: FieldItemWidgetDto[]) => {
		handleUpdateListField(list, KEY_FIELDS.LIST_FIELDS);
	};

	const handleUpdateListField = (list: FieldItemWidgetDto[], key: string) => {
		setDetailWidget((prev: WidgetBuilderDetailDto) => ({
			...prev,
			metadata: {
				...prev.metadata,
				body: {
					...prev.metadata.body,
					[key]: list,
				},
			},
		}));
	};

	const handleUpdateFieldBasic = (item: FieldItemWidgetDto) => {
		form.setFieldsValue({ [item?.fieldName]: item?.name });
	};

	const onValuesChange = (val: any) => {
		const keyName = Object.keys(val)[0];
		const keyIndex = +keyName?.slice(keyName.lastIndexOf(KEY_REPLACE_SPECIAL_CHARACTER) + 1);

		const newList: FieldItemWidgetDto[] = listFields;

		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.item) === 0) {
			newList[keyIndex].name = val[keyName];
		}
		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.label) === 0) {
			newList[keyIndex].label = val[keyName];
		}
		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.checkbox_dropdown) === 0) {
			newList[keyIndex].answerType = val[keyName];
		}
		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.mapping_type) === 0) {
			newList[keyIndex].mapping = val[keyName]
				? MAPPING_FIELD.CONTENT
				: MAPPING_FIELD.CREATOR;
		}
		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.dropdown) === 0) {
			const newListTag: string[] = [];
			val[keyName]?.forEach((c: string) => {
				if (!newListTag.includes(c?.trim())) {
					newListTag.push(c?.trim());
				}
			});
			newList[keyIndex].listOption = newListTag;
		}
		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.street) === 0) {
			newList[keyIndex].street = val[keyName];
		}
		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.state) === 0) {
			newList[keyIndex].state = val[keyName];
		}
		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.city) === 0) {
			newList[keyIndex].city = val[keyName];
		}
		if (keyName.indexOf(KEY_FIELD_FORM_WIDGET.zip_code) === 0) {
			newList[keyIndex].zipCode = val[keyName];
		}
		const listKeyBasicField = Object.values(FIELD_NAME_DEFAULT);

		if (listKeyBasicField?.includes(keyName)) {
			const basicFieldsChange: FieldItemWidgetDto[] = [...basicFields];
			const idx = basicFieldsChange?.findIndex((item) => item?.fieldName === keyName);

			basicFieldsChange[idx].name = val[keyName];
			return handleUpdateListField(basicFieldsChange, KEY_FIELDS.BASIC_FIELDS);
		}

		handleCheckChangeCustom(newList[keyIndex]);

		return handleUpdateFieldWidget(newList);
	};

	const getValueField = (key: string) => {
		return form.getFieldValue(key) || '';
	};

	const handleCheckChangeCustom = (field: FieldItemWidgetDto) => {
		if (field.key && field.typeAdd === TYPE_ADD_FIELDS.CUSTOM) {
			let isExist = false;
			if (field.mapping === MAPPING_FIELD.CONTENT) {
				isExist = customFields.content.some((cus) => cus?.key === field.key);
			} else {
				isExist = customFields.creator.some((cus) => cus?.key === field.key);
			}
			if (isExist) {
				setHasChangeCustomField(true);
			}
		}
	};

	const handleUpdateRequiredField = (
		field: FieldItemWidgetDto,
		keyIndex: number,
		isBasic?: boolean,
	) => {
		if (!isBasic) {
			handleCheckChangeCustom(field);
		}
		handleChangeRequired(
			field,
			keyIndex,
			isBasic ? KEY_FIELDS.BASIC_FIELDS : KEY_FIELDS.LIST_FIELDS,
		);
	};

	const handleOpenChangeBasicField = () => {
		setIsEditBasicField(!isEditBasicField);
		const fieldBasic: any = {};
		basicFields?.forEach((item: FieldItemWidgetDto) => {
			fieldBasic[item?.fieldName] = item?.name;
		});
		form.setFieldsValue({ ...fieldBasic });
	};

	const onSortEnd = (list: Array<FieldItemWidgetDto>) => {
		const newList: Array<FieldItemWidgetDto> = [];
		list.forEach((item, idx) => {
			const fieldName =
				item.fieldName.slice(
					0,
					item.fieldName?.lastIndexOf(KEY_REPLACE_SPECIAL_CHARACTER) + 1,
				) + idx;
			newList.push({ ...item, fieldName });
		});

		handleUpdateFieldWidget(newList);
	};

	const renderFormContent = useMemo(() => {
		return (
			<StyledListField>
				<SortColumnWithChildren list={listFields} onSortEnd={onSortEnd}>
					{listFields?.map((item: FieldItemWidgetDto, idx: number) => {
						let ComponentChild: any = null;
						let isExist = false;
						if (item?.typeAdd === TYPE_ADD_FIELDS.CUSTOM) {
							if (item.mapping === MAPPING_FIELD.CONTENT) {
								isExist = customFields.content.some((cus) => cus?.key === item.key);
							} else {
								isExist = customFields.creator.some((cus) => cus?.key === item.key);
							}
						}

						if (listFieldInput.includes(item?.type)) {
							ComponentChild = CustomInputField;
						}
						if (item?.type === TYPE_CUSTOM_FIELD?.dropdown) {
							ComponentChild = CustomDropdownField;
						}
						if (item.type === TYPE_CUSTOM_FIELD.mailAddress) {
							ComponentChild = CustomAddressField;
						}

						if (ComponentChild) {
							const nameComponent = `${KEY_FIELD_FORM_WIDGET.child}${idx}`;

							if (item?.type !== TYPE_CUSTOM_FIELD?.dropdown) {
								return (
									<ItemSort
										id={nameComponent}
										index={idx}
										renderChildren={(val: any) => (
											<ComponentChild
												setFieldsValue={(val: any) =>
													form.setFieldsValue(val)
												}
												getValueField={(val: string) =>
													form.getFieldValue(val)
												}
												id={nameComponent}
												handleChangeRequired={() =>
													handleUpdateRequiredField(item, idx)
												}
												isExist={isExist}
												keyIndex={idx}
												handleRemoveField={() => handleRemoveField(idx)}
												field={item}
												key={nameComponent}
												propSort={val}
												idFocus={idFocus}
												clearIdFocus={() => setIdFocus('')}
												onValuesChange={onValuesChange}
											/>
										)}
									/>
								);
							}
							return (
								<ItemSort
									id={nameComponent}
									index={idx}
									renderChildren={(val: any) => (
										<ComponentChild
											key={nameComponent}
											setFieldsValue={(val: any) => form.setFieldsValue(val)}
											getValueField={(val: string) => form.getFieldValue(val)}
											id={nameComponent}
											onValuesChange={onValuesChange}
											keyIndex={idx}
											isExist={isExist}
											handleChangeRequired={() =>
												handleUpdateRequiredField(item, idx)
											}
											handleRemoveField={() => handleRemoveField(idx)}
											field={item}
											propSort={val}
											idFocus={idFocus}
											clearIdFocus={() => setIdFocus('')}
										/>
									)}
								/>
							);
						}
						return null;
					})}
				</SortColumnWithChildren>
			</StyledListField>
		);
	}, [listFields, customFields]);

	const renderBasicField = useMemo(() => {
		return basicFields?.map((item: FieldItemWidgetDto, idx: number) => {
			if (isEditBasicField) {
				const { fieldName = '' } = item;
				let label = fieldName === FIELD_NAME_DEFAULT.firstName ? 'first_name' : 'last_name';
				if (fieldName === FIELD_NAME_DEFAULT.emailAddress) {
					label = 'email_address';
				}

				return (
					<StyledFormItem
						name={fieldName}
						key={fieldName}
						rules={[
							{
								required: true,
								message: t('content.widget_manager.widget_builder.required'),
							},
						]}>
						<CustomInputField
							setFieldsValue={(val: any) => form.setFieldsValue(val)}
							getValueField={(val: string) => form.getFieldValue(val)}
							handleChangeRequired={() => handleUpdateRequiredField(item, idx, true)}
							label={t(`content.widget_manager.widget_builder.${label}`)}
							value={getValueField(fieldName)}
							keyName={fieldName}
							keyIndex={idx}
							handleRemoveField={() => onRemoveBasicField(idx)}
							field={{ ...item, defaultRequired: true }}
						/>
					</StyledFormItem>
				);
			}
			return (
				<InputItem
					onRemoveBasicField={onRemoveBasicField}
					handleUpdateRequiredField={handleUpdateRequiredField}
					key={Math.random()}
					field={{ ...item, defaultRequired: true }}
					keyIndex={idx}
				/>
			);
		});
	}, [basicFields, isEditBasicField]);

	return (
		<StyledStep>
			<Form
				form={form}
				initialValues={initialValues}
				onValuesChange={onValuesChange}
				onFinish={handleFinishForm}>
				<StyledBasicInfo>
					<StyledWrapperTitle>
						<StyledLabelItem>
							{t('content.widget_manager.widget_builder.basic_info')}
						</StyledLabelItem>
						<StyledIcon
							style={{ transform: `rotate(${isEditBasicField ? 90 : 0}deg)` }}
							onClick={handleOpenChangeBasicField}>
							<IconKeyboardArrowRight />
						</StyledIcon>
					</StyledWrapperTitle>
					<StyledListField className="mt-10">{renderBasicField}</StyledListField>
				</StyledBasicInfo>
				<StyledCustomInfo>
					{listFields?.length >= LIMIT_FIELD_WARNING && (
						<StyledMessageWarning>
							{t('content.widget_manager.widget_builder.notify_custom_fields', {
								count: LIMIT_FIELD_WARNING,
							})}
						</StyledMessageWarning>
					)}
					<StyledLabelItem>
						{t('content.widget_manager.widget_builder.add_own_fields')}
					</StyledLabelItem>
					<StyledSubTitle>
						{t('content.widget_manager.widget_builder.sub_custom_fields')}
					</StyledSubTitle>

					<>{renderFormContent}</>
					<AddCustomField
						handleUpdateFieldBasic={handleUpdateFieldBasic}
						handleUpdateListField={handleUpdateListField}
						setIdFocus={setIdFocus}
					/>
				</StyledCustomInfo>
			</Form>
		</StyledStep>
	);
});

export default SettingStep3;
