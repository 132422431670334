import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Styled
import {
	StyledCheckbox,
	StyledDate,
	StyledEmailWrapper,
	StyledFlex,
	StyledText,
	StyledTitle,
} from '@styled/Messages/MessageLogStyled';

// Icons
import { EventDto } from '@models/messages/log';

// Context
import MessageLogContext from '@contexts/Messages/Logs';

// Redux
import { getEmailEventDetail, resetStatusDBLickEmail } from '@stores/actions';
import { useTranslation } from 'react-i18next';
// constants
import { TIME_OUT, TYPE_FORMAT_DATE } from '@constants/common';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';

type EmailProps = {
	data: EventDto;
	onEditEmail: (emailDataDefault?: EventDto) => void;
};

const Email = (props: EmailProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isGetMailStatus, isFailedFilterEmailLog = false } = useSelector(
		(state: any) => state.messages,
	);

	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [countClick, setCountClick] = useState<number>(0);
	const { data, onEditEmail } = props;
	const { id = 0, subject = '', updateAt = 0 } = data;

	const {
		selectedEmail = {},
		onChangeSelectedEmail,
		onChangeSelectedEmailList,
		onIsSelectDraftEmail,
	} = useContext(MessageLogContext);

	const handleChangeCheckbox = (isDBClick?: boolean) => {
		const payloadChecked = typeof isDBClick === 'boolean' ? isDBClick : !isChecked;
		if (payloadChecked) {
			const payload: any = {
				id,
				isGetMailStatus: typeof isDBClick === 'boolean' ? true : false,
			};
			if (isFailedFilterEmailLog) {
				payload.isFailed = isFailedFilterEmailLog;
			}
			dispatch(getEmailEventDetail(payload));
		}
		setIsChecked(payloadChecked);
		onChangeSelectedEmailList(payloadChecked, id, true);
	};

	const onClickEmail = () => {
		onIsSelectDraftEmail(true);
		onChangeSelectedEmail(data);
	};

	const onSelectEmail = (e?: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		onClickEmail();
		handleChangeCheckbox();
	};

	const onCheckClickRow = () => {
		setCountClick(countClick + 1);
	};

	useEffect(() => {
		let timeOut: any;
		if (countClick === 1) {
			timeOut = setTimeout(() => {
				onSelectEmail();
				setCountClick(0);
			}, TIME_OUT.DELAY_SELECT);
		} else if (countClick === 2) {
			onClickEmail();
			handleChangeCheckbox(true);
		}

		return () => clearTimeout(timeOut);
	}, [countClick]);

	useEffect(() => {
		if (countClick === 2 && isGetMailStatus) {
			setCountClick(0);
			onEditEmail();
			dispatch(resetStatusDBLickEmail());
		}
	}, [countClick, isGetMailStatus]);

	return (
		<StyledEmailWrapper isSelected={selectedEmail.id === id} onClick={onCheckClickRow}>
			<StyledFlex justifyContent="flex-start">
				<StyledCheckbox checked={isChecked} onClick={onSelectEmail} />
				<StyledTitle>
					<StyledText weight={800}>
						{subject || `(${t('email_log.logs.no_subject')})`}
					</StyledText>
				</StyledTitle>
				<StyledDate>
					{convertUtcToLocalTimeWithFormat(updateAt, TYPE_FORMAT_DATE.TIME) || ''}
				</StyledDate>
			</StyledFlex>
		</StyledEmailWrapper>
	);
};

export default Email;
