import { OptionDto } from '@models/publishing/galleries';
import { StyledSelect } from '@styled/Content/ContentLibrary/FilterStyled';
import { notFoundContent } from '@utils/renderComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
	mode?: 'tags' | 'multiple' | undefined;
	placeholder?: string;
	options?: OptionDto[];
	[other: string]: any;
};
const SelectForm = (props: PropTypes) => {
	const { mode = undefined, placeholder = '', options = [], ...other } = props;
	const { t } = useTranslation();

	return (
		<StyledSelect
			mode={mode}
			showSearch
			notFoundContent={notFoundContent(t)}
			getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
			placeholder={placeholder}
			virtual={false}
			optionFilterProp="label"
			showArrow
			options={options}
			dropdownClassName="dropdown-menu dropdown-custom-content"
			{...other}
		/>
	);
};

export default SelectForm;
