import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import {
	Button,
	ConfigProvider,
	Empty,
	Form,
	Input,
	message,
	Modal,
	Radio,
	Table,
	Tooltip,
} from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Models
import { SegmentsDto, SegmentSortDto } from '@models/creator/segments';
import HeaderDto from '@models/header';

import { AddToSegmentProps } from '@models/creator/summary';

// API
import { postRequest } from '@helpers/requestHelpers';

// Redux
import { creatorGetSegment, resetSelectedRows } from '@stores/actions';
import { headerGetFilterSegments, headerSucceeded } from '@stores/header/header.actions';

// Constants
import { CREATOR_API_URL } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { Paginator as defaultPage } from '@constants/paginator';

// Helper
import { TYPE_FORMAT_DATE } from '@constants/common';
import { WIDTH_COLUMNS } from '@constants/creator';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';

const StyledEmpty = styled(Empty)`
	margin: 0;

	.ant-empty-image {
		width: 100%;
		height: unset;
	}
`;

const StyledPagination = styled.div`
	height: 32px;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
`;

const StyledRadioGroup = styled(Radio.Group)`
	margin-bottom: 15px;
`;

const StyledTable = styled((props) => <Table {...props} />)`
	margin-top: 50px;

	.ant-table {
		display: block;
		max-width: -moz-fit-content;
		max-width: fit-content;
		margin: 0 auto;
		overflow-x: auto;
		white-space: nowrap;
		.ant-table-selection-column {
			width: 50px;
		}
	}

	.ant-spin-nested-loading {
		> div {
			> .ant-spin {
				max-height: unset;
			}
		}
	}
`;

const StyledForm = styled(Form)`
	.ant-form-item {
		flex-direction: column;
		margin-bottom: 12px;
	}
	.ant-form-item-label {
		padding: 0;
	}
	.ant-form-item-explain {
		position: absolute;
		right: 0;
		bottom: -24px;
	}
`;

const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

const AddToSegmentModal = ({
	visible,
	onClose,
	selectedCreator,
	isSelectAll = false,
}: AddToSegmentProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [dataSource, setDataSource] = useState<SegmentsDto[]>([]);
	const [sortOrder, setSortOrder] = useState<string | undefined>(undefined);
	const [sortField, setSortField] = useState<string | undefined>(undefined);
	const [pageCurrent, setPageCurrent] = useState<number>(1);
	const [selectedRow, setSelectedRow] = useState<React.Key[]>([]);
	const [fetchingAddToSegment, setFetchingAddToSegment] = useState<boolean>(false);
	const [radioValue, setRadioValue] = React.useState<number>(1);
	const [segmentNameValue, setSegmentNameValue] = React.useState<string>('');

	const { fetchingSegment, segmentData = [] } = useSelector((state: any) => state.creator);
	const header: HeaderDto = useSelector((state: any) => state.header);

	const renderSegmentName = (name: string) => {
		return (
			<Tooltip placement="topLeft" title={name}>
				{name}
			</Tooltip>
		);
	};

	const columns: Array<any> = [
		{
			title: t('creator_segment.segment_name'),
			dataIndex: 'title',
			sorter: () => {},
			render: (title: string) => renderSegmentName(title),
		},
		{
			title: t('creator_segment.total_creators'),
			dataIndex: 'totalCreatorProfiles',
			sorter: () => {},
			width: WIDTH_COLUMNS,
		},
		{
			title: t('creator_segment.total_uploads'),
			dataIndex: 'totalUploads',
			sorter: () => {},
			width: WIDTH_COLUMNS,
		},
		{
			title: t('creator_segment.date_updated'),
			dataIndex: 'lastUpdate',
			sorter: () => {},
			render: (createAt: string) =>
				convertUtcToLocalTimeWithFormat(
					new Date(createAt).getTime(),
					TYPE_FORMAT_DATE.TIME,
				),
		},
	];

	useEffect((): any => {
		setPageCurrent(1);
		if (radioValue === 2) {
			getSegmentsData();
		}
	}, [sortField, sortOrder, radioValue]);

	useEffect((): any => {
		segmentData?.forEach((profile: SegmentsDto) => {
			profile.key = profile.id;
		});
		setDataSource(segmentData);
	}, [segmentData]);

	const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: SegmentsDto[]) => {
		setSelectedRow(selectedRowKeys);
	};

	const onChangeTable = (pagination: any, filters: any, sorter: any, extra: any) => {
		const { field, order } = sorter;

		const sortOrders: SegmentSortDto = {
			ascend: 'ASC',
			descend: 'DESC',
		};

		const sortFields: SegmentSortDto = {
			title: 'TITLE',
			lastUpdate: 'UPDATE_AT',
			totalCreatorProfiles: 'TOTAL_PROFLES',
			totalUploads: 'TOTAL_UPLOADS',
		};

		setSortOrder(sortOrders[order]);
		setSortField(sortFields[field]);
	};

	const getSegmentsData = (pageNumber?: number) => {
		const params = {
			pageRecords: defaultPage.pageSize,
			page: pageNumber ? pageNumber : defaultPage.defaultPage,
			sortDirection: sortOrder,
			sortField,
		};

		dispatch(creatorGetSegment(params));
	};

	const handleAddToSegment = () => {
		let url;
		const nameSegment = form.getFieldValue('nameSegment');

		if (radioValue === 1) {
			url = `${CREATOR_API_URL}/segment/add-profiles-new-segment?title=${nameSegment}`;
		} else {
			url = `${CREATOR_API_URL}/segment/add-profiles-segment?id=${selectedRow}`;
		}

		const paramsRequest = isSelectAll ? { isAll: true } : selectedCreator;

		setFetchingAddToSegment(true);
		postRequest(url, paramsRequest)
			.then((response) => {
				if (response.status === 200) {
					message.success(t('creator_summary.modal.add_to_segment_success'));

					// Clean up
					// setSelectedRow([]);
					dispatch(resetSelectedRows(true));
					dispatch(headerSucceeded(header));
					dispatch(headerGetFilterSegments(true));
					form.resetFields();
					onClose();
				} else {
					message.error(response.body.data.error || DEFAULT_ERROR);
				}
				setFetchingAddToSegment(false);
			})
			.catch((err) => {
				setFetchingAddToSegment(false);
				message.error(DEFAULT_ERROR);
			});
	};

	const handleCancel = () => {
		onClose();
		setSegmentNameValue('');
	};

	const handleChangeRadio = (e: any) => {
		const {
			target: { value },
		} = e;
		setSelectedRow([]);
		setRadioValue(value);
	};

	const rowSelection = {
		selectedRow,
		onChange: onSelectChange,
	};

	const onChangeSegmentName = (e: any) => {
		setSegmentNameValue(e.target.value);
	};

	const renderTable = () => (
		<ConfigProvider renderEmpty={customizeRenderEmpty}>
			<StyledTable
				loading={fetchingSegment}
				dataSource={dataSource}
				columns={columns}
				pagination={false}
				rowSelection={{ type: 'radio', ...rowSelection }}
				scroll={{ y: 300 }}
				onChange={onChangeTable}
			/>
		</ConfigProvider>
	);

	const renderInputCreateNewSegment = () => {
		return (
			<StyledForm form={form} onFinish={handleAddToSegment}>
				<Form.Item
					label={t('creator_segment.modal.segment_name')}
					name="nameSegment"
					rules={[
						{
							required: true,
							message: t('creator_segment.modal.required'),
						},
					]}>
					<Input onChange={onChangeSegmentName} />
				</Form.Item>
			</StyledForm>
		);
	};

	const onHandleSegmentByType = () => {
		if (radioValue === 1) {
			return form.submit();
		}
		return handleAddToSegment();
	};

	const disabledAddButton = () => {
		if (radioValue === 1 && segmentNameValue === '') {
			return true;
		}
		if (radioValue === 2 && selectedRow.length === 0) {
			return true;
		}
		return false;
	};

	return (
		<Modal
			title={t('creator_summary.modal.add_to_segment')}
			visible={visible}
			width={1000}
			onOk={handleAddToSegment}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					{t('button.cancel')}
				</Button>,
				<Button
					onClick={onHandleSegmentByType}
					key="submit"
					type="primary"
					disabled={disabledAddButton()}
					loading={fetchingAddToSegment}>
					{t('button.add')}
				</Button>,
			]}>
			<StyledRadioGroup onChange={handleChangeRadio} value={radioValue}>
				<Radio value={1}>{t('creator_summary.modal.create_a_new_segment')}</Radio>
				<Radio value={2}>{t('creator_summary.modal.choose_an_existing_segment')}</Radio>
			</StyledRadioGroup>

			{radioValue === 1 ? renderInputCreateNewSegment() : renderTable()}
		</Modal>
	);
};

export default React.memo(AddToSegmentModal);
