import { PATTERNS, ROUTE_PATH, STATUS_MODES } from '@constants/common';
import { GUEST_PORTAL_FIELD_NAME } from '@constants/settings/guestPortal';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import TagsInput from '@cores/TagsInput';
import { GuestPortalItemDto, GuestPortalStoreDto } from '@models/settings/guestPortal';
import { shareGuestPortalEnd, shareGuestPortalRequest } from '@stores/actions';
import {
	StyledButton,
	StyledCol,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledLinkHref,
	StyledModal,
	StyledRow,
	StyledText,
	StyledTextArea,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

type GuestPortalShareModalProps = {
	onCancel: () => void;
	guestPortalDetail: GuestPortalItemDto;
	handleCopiedClipboardMessage: (url: string) => void;
};

const GuestPortalShareModal = (props: GuestPortalShareModalProps) => {
	const { onCancel, guestPortalDetail, handleCopiedClipboardMessage } = props;
	const { t } = useTranslation();
	const [form] = useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const { shareGuestPortalStatus }: GuestPortalStoreDto = useSelector(
		(state: any) => state.guestPortal,
	);

	useEffect(() => {
		if (shareGuestPortalStatus === SUCCEEDED) {
			message.success(
				t('message.action_success', {
					action: t('action.sent'),
				}),
			);
			onCancel();
		}

		if (shareGuestPortalStatus === SUCCEEDED || shareGuestPortalStatus === FAILED) {
			dispatch(shareGuestPortalEnd());
		}
	}, [shareGuestPortalStatus]);

	const renderFooter = () => {
		return (
			<>
				<StyledButton
					onClick={() => {
						handleCopiedClipboardMessage(guestPortalDetail.url);
					}}>
					{t('button.copy_link_to_clipboard')}
				</StyledButton>
				<StyledFlex>
					<StyledButton onClick={onCancel}>{t('button.cancel')}</StyledButton>
					<StyledButton
						type="primary"
						onClick={form.submit}
						loading={shareGuestPortalStatus === IN_PROGRESS}>
						{t('button.share_via_email')}
					</StyledButton>
				</StyledFlex>
			</>
		);
	};

	const renderIncludedTabs = () => {
		const {
			metadata: { tabConfiguration = {} },
		} = guestPortalDetail || {};
		let tabs = '';
		const tabConfigurationKeys = Object.keys(tabConfiguration);

		tabConfigurationKeys.forEach((key, index) => {
			if (tabConfiguration?.[key]?.status) {
				tabs += `${t(`setting.guest_portal.${key}`)}${
					index === tabConfigurationKeys.length - 1 ? '' : ', '
				}`;
			}
		});

		return tabs;
	};

	const renderGuestPortalInfo = () => {
		const infoList = [
			{
				label: t('setting.guest_portal.name'),
				value: (
					<StyledText title={guestPortalDetail.name} lineClamp={1} fontStyle="italic">
						{guestPortalDetail.name}
					</StyledText>
				),
			},
			{
				label: t('task_manager.task_list.description'),
				value: (
					<StyledText
						title={guestPortalDetail.description}
						lineClamp={3}
						fontStyle="italic">
						{guestPortalDetail.description}
					</StyledText>
				),
			},
			{
				label: t('setting.guest_portal.url'),
				value: (
					<StyledLinkHref
						href={guestPortalDetail.url}
						target="_blank"
						color={THEME.colors.blueBase}
						underline={true}
						fontWeight="normal">
						{guestPortalDetail.url}
					</StyledLinkHref>
				),
			},
			{
				label: t('setting.guest_portal.included_tabs'),
				value: <StyledText>{renderIncludedTabs()}</StyledText>,
			},
		];

		const transProps: any = {
			i18nKey: 'setting.guest_portal.sharing_note',
			components: {
				linkTo: (
					<StyledLinkHref
						onClick={() => {
							history.push(ROUTE_PATH.SETTINGS_ACCOUNT_USERS);
						}}
						color={THEME.colors.gray5}
						underline={true}
					/>
				),
			},
		};

		return (
			<>
				<StyledWrapperContent
					padding="0 0 0 8px"
					borderLeft={`2px solid ${THEME.colors.gray1}`}>
					{infoList.map((item) => {
						return (
							<StyledRow margin="0 0 8px 0">
								<StyledCol>
									<StyledText
										width="fit-content"
										margin="0 8px 0 0">{`${item.label}: `}</StyledText>
								</StyledCol>
								<StyledCol span={18}>{item.value}</StyledCol>
							</StyledRow>
						);
					})}
				</StyledWrapperContent>
				<StyledWrapperContent
					margin="16px 0 24px 0"
					background="rgba(24, 144, 255, 0.10)"
					padding="8px 16px">
					<Trans {...transProps} />
				</StyledWrapperContent>
			</>
		);
	};

	const renderFormItem = () => {
		const tagsInputProps = {
			form,
			fieldName: GUEST_PORTAL_FIELD_NAME.EMAIL,
			isRequired: true,
			regex: PATTERNS.EMAIL,
			errorMessage: {
				invalidValue: t('validation.invalid_message', {
					field: t('creator_summary.email'),
				}),
				field: `${t('creator_summary.email')}(s)`,
			},
			placeholder: t('setting.guest_portal.placeholder.enter_recipient_email_address'),
		};

		return (
			<>
				<TagsInput {...tagsInputProps} />
				<StyledFormItem name={GUEST_PORTAL_FIELD_NAME.NOTE}>
					<StyledTextArea
						placeholder={t('setting.guest_portal.placeholder.add_a_note')}
						autoSize={{
							minRows: 4,
							maxRows: 4,
						}}
					/>
				</StyledFormItem>
			</>
		);
	};

	const shareViaEmail = (values: any) => {
		const requestPayload = {
			...values,
			note: values[GUEST_PORTAL_FIELD_NAME.NOTE] || '',
			guestPortalId: guestPortalDetail.id,
		};

		dispatch(shareGuestPortalRequest({ ...requestPayload }));
	};

	return (
		<StyledModal
			title={
				<StyledText fontSize="20px">
					{t('setting.guest_portal.share_a_guest_portal')}
				</StyledText>
			}
			visible={true}
			onCancel={onCancel}
			footer={renderFooter()}
			centered
			width={500}
			footerJustify="space-between"
			maskClosable={shareGuestPortalStatus !== IN_PROGRESS}>
			<StyledForm form={form} requiredMark={false} onFinish={shareViaEmail}>
				{renderGuestPortalInfo()}
				{renderFormItem()}
			</StyledForm>
		</StyledModal>
	);
};

export default GuestPortalShareModal;
