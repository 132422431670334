import { Action } from 'redux-actions';
import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import { getRequest, putRequest, postRequest } from '@helpers/requestHelpers';
import genericApiSaga from '@helpers/genericApiSaga';
import {
	getSegmentRewardCompleted,
	getSegmentRewardFailed,
	GET_SEGMENT_REWARDS,
	createRewardCompleted,
	createRewardFailed,
	CREATE_REWARD,
	updateRewardCompleted,
	updateRewardFailed,
	UPDATE_REWARD,
	getRewardsCompleted,
	getRewardsFailed,
	GET_REWARDS,
	getRewardsHistoryCompleted,
	getRewardsHistoryFailed,
	GET_REWARDS_HISTORY,
	saveDraftRewardCompleted,
	saveDraftRewardFailed,
	SAVE_DRAFT_REWARD,
	goLiveDraftRewardCompleted,
	goLiveDraftRewardFailed,
	GO_LIVE_DRAFT_REWARD,
} from './rewards.actions';
import { CREATOR_API_URL, REWARD_API_URL } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { ACTIVE, DRAFT } from '@constants/reward';
import * as actions from './rewards.actions';
import { message } from 'antd';
import { getRewardByCreatorTypes, logRewardTypes, updateRewardStatusTypes } from './rewards.types';
import { convertParamSearch } from '@utils/common';

function* getRewards({ payload }: any) {
	const url = `${REWARD_API_URL}/rule/`;
	const { params, isSetOnScroll } = payload;
	const { type, status, maxRecords, isResetList = false } = params;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url, params),
		*completed(response: any) {
			const responseData = {
				isResetList,
				isSetOnScroll,
				type,
				status,
				response: response.data,
				maxRecords,
			};
			yield put(getRewardsCompleted(responseData));
		},
		*failed(response: any) {
			yield put(getRewardsFailed(response?.messages));
			message.error(response.error || DEFAULT_ERROR);
		},
	});
}

function* getSegmentReward({ payload }: any) {
	const url = `${CREATOR_API_URL}/segment/segments-reward`;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url, payload),
		*completed(response: any) {
			yield put(getSegmentRewardCompleted(response.data));
		},
		*failed(response: any) {
			yield put(getSegmentRewardFailed(response?.messages));
			message.error(response.error || DEFAULT_ERROR);
		},
	});
}

function* createReward({ payload }: any) {
	const { type, params } = payload;
	const { status } = params;
	const url = `${REWARD_API_URL}/rule/?type=${type}`;
	const getRewardsParams = {
		payload: {
			params: {
				page: 1,
				maxRecords: 8,
				status,
				type,
			},
			isSetOnScroll: false,
			isResetList: true,
		},
	};
	yield genericApiSaga({
		gatewayCall: () => postRequest(url, params),
		*completed(response: any) {
			yield put(createRewardCompleted(response.data));
			yield call(getRewards, getRewardsParams);
			message.success('Reward created!');
		},
		*failed(response: any) {
			yield put(createRewardFailed(response?.messages));
			message.error(response.data.error || DEFAULT_ERROR);
		},
	});
}

function* updateReward({ payload }: any) {
	const { type, params } = payload;
	const { status } = params;
	const url = `${REWARD_API_URL}/rule/?type=${type}`;
	const getRewardsParams = {
		payload: {
			params: {
				page: 1,
				maxRecords: 8,
				status,
				type,
			},
			isSetOnScroll: false,
			isResetList: true,
		},
	};
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, params),
		*completed(response: any) {
			yield put(updateRewardCompleted(response.data));
			yield call(getRewards, getRewardsParams);
			if (status === ACTIVE) {
				message.success('Reward updated!');
			}
			if (status === DRAFT) {
				message.success('Draft saved!');
			}
		},
		*failed(response: any) {
			yield put(updateRewardFailed(response?.messages));
			message.error(response.data.error || DEFAULT_ERROR);
		},
	});
}

function* saveDraftReward({ payload }: any) {
	const { type, params } = payload;
	const { status } = params;
	const url = `${REWARD_API_URL}/rule/draft/?type=${type}`;
	const getRewardsParams = {
		payload: {
			params: {
				page: 1,
				maxRecords: 8,
				status,
				type,
			},
			isSetOnScroll: false,
			isResetList: true,
		},
	};
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, params),
		*completed(response: any) {
			yield put(saveDraftRewardCompleted(response.data));
			yield call(getRewards, getRewardsParams);
			if (status === DRAFT) {
				message.success('Draft saved!');
			}
		},
		*failed(response: any) {
			yield put(saveDraftRewardFailed(response?.messages));
			message.error(response.data.error || DEFAULT_ERROR);
		},
	});
}

function* goLiveDraftReward({ payload }: any) {
	const { type, params } = payload;
	const { status } = params;
	const url = `${REWARD_API_URL}/rule/live/?type=${type}`;
	const getRewardsParams = {
		payload: {
			params: {
				page: 1,
				maxRecords: 8,
				status,
				type,
			},
			isSetOnScroll: false,
			isResetList: true,
		},
	};
	yield genericApiSaga({
		gatewayCall: () => putRequest(url, params),
		*completed(response: any) {
			yield put(goLiveDraftRewardCompleted(response.data));
			yield call(getRewards, getRewardsParams);
			message.success('Reward is live!');
		},
		*failed(response: any) {
			yield put(goLiveDraftRewardFailed(response?.messages));
			message.error(response.data.error || DEFAULT_ERROR);
		},
	});
}

function* getRewardsHistory({ payload }: any) {
	const { params } = payload;
	const { page, maxRecords, filter, search, sortOrder, sortField } = params;
	const sortString = sortOrder !== undefined ? `&sortby=${sortField},${sortOrder}` : '';
	const searchString = search !== '' ? `&keyword=${search}` : '';
	const url = `${REWARD_API_URL}/?page=${page}&maxRecords=${maxRecords}${searchString}${sortString}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url, filter),
		*completed(response: any) {
			yield put(getRewardsHistoryCompleted(response.data));
		},
		*failed(response: any) {
			yield put(getRewardsHistoryFailed(response?.messages));
			message.error(response.error || DEFAULT_ERROR);
		},
	});
}

function* getRewardByCreator({ payload }: Action<{ creatorId: number }>) {
	const { creatorId } = payload;
	const url = `${REWARD_API_URL}/creator/${creatorId}`;

	yield genericApiSaga({
		gatewayCall: () => getRequest(url),
		*completed(response: any) {
			yield put(actions.getRewardByCreatorSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.getRewardByCreatorFailed(response));
		},
	});
}

function* updateRewardStatus({ payload }: Action<{ rewardId: number; status: string }>) {
	const { rewardId, status } = payload;
	const url = `${REWARD_API_URL}/${rewardId}/status?status=${status}`;

	yield genericApiSaga({
		gatewayCall: () => putRequest(url),
		*completed(response: any) {
			yield put(actions.updateRewardStatusSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateRewardStatusFailed(response));
		},
	});
}

function* logReward({ payload }: Action<any>) {
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${REWARD_API_URL}/`, payload),
		*completed(response: any) {
			yield put(actions.logRewardSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.logRewardFailed(response));
		},
	});
}

function* createRewardsSagas() {
	yield takeLatest(GET_SEGMENT_REWARDS, getSegmentReward);
	yield takeLatest(CREATE_REWARD, createReward);
	yield takeLatest(UPDATE_REWARD, updateReward);
	yield takeLatest(SAVE_DRAFT_REWARD, saveDraftReward);
	yield takeLatest(GO_LIVE_DRAFT_REWARD, goLiveDraftReward);
	yield takeEvery(GET_REWARDS, getRewards);
	yield takeLatest(GET_REWARDS_HISTORY, getRewardsHistory);
	yield takeLatest(getRewardByCreatorTypes.GET_REWARD_BY_CREATOR_REQUEST, getRewardByCreator);
	yield takeLatest(updateRewardStatusTypes.UPDATE_REWARD_STATUS_REQUEST, updateRewardStatus);
	yield takeLatest(logRewardTypes.LOG_REWARD_REQUEST, logReward);
}

export default createRewardsSagas;
