import { IconMoreVert, IconStar } from '@assets/icons';
import {
	ACTION_TYPE_ON_LANDING_PAGE,
	LANDING_PAGE_SELECTORS,
	LANDING_PAGE_STATUS,
} from '@constants/landingPageManager';
import { SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import LandingPageManagerContext from '@contexts/LandingPageManager';
import { LandingPageDto } from '@models/landingpagemanager';
import { LandingPageStoreDto } from '@models/landingpagemanager/stores';
import {
	StyledCol,
	StyledDropdown,
	StyledIcon,
	StyledRow,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledLandingPageCollapse } from '@styled/LandingPageManager/LandingPageManagerStyled';
import { isJSONString } from '@utils/common';
import { useScrollToLoadMore } from '@utils/customHooks';
import { menuActions } from '@utils/renderComponent';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LandingPageItem from './LandingPageItem';

const LandingPageList = () => {
	const { t } = useTranslation();
	const { createLandingPageStatus }: LandingPageStoreDto = useSelector(
		(state: any) => state.landingPage,
	);
	const {
		landingPageList,
		currentLandingPage,
		setCurrentLandingPage,
		handleActionOnLandingPageItem,
		setConfirmationModalType,
		setHandledLandingPageItem,
		handleLoadMoreLandingPageList,
	} = useContext(LandingPageManagerContext);

	const [panelKey, setPanelKey] = useState<string | string[] | any>('');
	const element = document.getElementById(LANDING_PAGE_SELECTORS.LANDING_PAGE_LIST_ID);

	useScrollToLoadMore(element, handleLoadMoreLandingPageList);

	useEffect(() => {
		if (currentLandingPage && currentLandingPage.id) {
			setPanelKey(currentLandingPage.id);
		}
	}, [currentLandingPage]);

	useEffect(() => {
		if (createLandingPageStatus === SUCCEEDED) {
			if (element) {
				element.scrollTop = 0;
			}
		}
	}, [createLandingPageStatus]);

	const actionsObj = (landingPageItem: LandingPageDto) => {
		return [
			{
				id: 1,
				name: t('button.set_default'),
				click: () => {
					handleActionOnLandingPageItem(
						landingPageItem,
						ACTION_TYPE_ON_LANDING_PAGE.SET_AS_DEFAULT,
					);
				},
				hidden: landingPageItem.status === LANDING_PAGE_STATUS.DRAFT,
			},
			{
				id: 2,
				name: t('landing_page_manager.button.publish_changes'),
				click: () => {
					handleActionOnItem(
						landingPageItem,
						ACTION_TYPE_ON_LANDING_PAGE.PUBLISH_CHANGES,
					);
				},
				hidden: landingPageItem.status === LANDING_PAGE_STATUS.PUBLISHED,
			},
			{
				id: 3,
				name: t('button.unpublish'),
				click: () => {
					handleActionOnItem(landingPageItem, ACTION_TYPE_ON_LANDING_PAGE.UNPUBLISH);
				},
				hidden: landingPageItem.status === LANDING_PAGE_STATUS.DRAFT,
			},
			{
				id: 4,
				name: t('button.duplicate'),
				click: () => {
					handleActionOnLandingPageItem(
						landingPageItem,
						ACTION_TYPE_ON_LANDING_PAGE.DUPLICATE,
					);
				},
			},
			{
				id: 5,
				name: t('button.delete'),
				click: () => {
					handleActionOnItem(landingPageItem, ACTION_TYPE_ON_LANDING_PAGE.DELETE);
				},
			},
		];
	};

	const handleActionOnItem = (landingPageItem: LandingPageDto, type: string) => {
		setHandledLandingPageItem(landingPageItem);
		setTimeout(() => {
			setConfirmationModalType(type);
		}, 0);
	};

	const renderDropdown = (landingPageItem: LandingPageDto) => {
		return (
			<StyledDropdown trigger={['click']} overlay={menuActions(actionsObj(landingPageItem))}>
				<StyledIcon
					padding="4px 0 0 0"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}>
					<IconMoreVert />
				</StyledIcon>
			</StyledDropdown>
		);
	};

	const renderCollapsePanelHeader = (landingPageItem: LandingPageDto) => {
		return (
			<StyledRow align="middle" justify="space-between">
				<StyledCol span={20}>
					{(+panelKey !== landingPageItem.id || !panelKey) && (
						<StyledWrapperContent width="100%" display="inline-flex">
							<StyledText
								className="overflow"
								padding="2px 0"
								color={THEME.colors.white}
								fontSize="20px"
								fontWeight={600}>
								{landingPageItem.name}
							</StyledText>
							{landingPageItem.isDefault && (
								<StyledIcon
									className="star"
									margin="-2px -2px 0 8px"
									fillPath={THEME.colors.yellowBase}>
									<IconStar />
								</StyledIcon>
							)}
						</StyledWrapperContent>
					)}
				</StyledCol>
				<StyledCol>
					<StyledWrapperContent display="inline-flex">
						{renderDropdown(landingPageItem)}
					</StyledWrapperContent>
				</StyledCol>
			</StyledRow>
		);
	};

	const onChangeLandingPage = (key: string | string[]) => {
		setPanelKey(key);
		const landingPageItem = landingPageList?.find((item: LandingPageDto) => item?.id === +key);
		if (landingPageItem) {
			setCurrentLandingPage(landingPageItem);
		}
	};

	return (
		<StyledWrapperContent
			id={LANDING_PAGE_SELECTORS.LANDING_PAGE_LIST_ID}
			height="calc(100% - 70px)"
			overflow="auto"
			background={THEME.colors.darkBlue2}
			padding="6px 18px">
			<StyledWrapperContent padding="16px 0">
				<StyledLandingPageCollapse
					padding="4px"
					bordered={false}
					accordion
					activeKey={[panelKey]}
					onChange={onChangeLandingPage}>
					{landingPageList.map((item: LandingPageDto) => {
						item = {
							...item,
							metadata: isJSONString(item.metadata)
								? JSON.parse(item.metadata)
								: item.metadata,
						};

						return (
							<CollapsePanel
								key={item.id}
								showArrow={false}
								header={renderCollapsePanelHeader(item)}>
								<LandingPageItem
									key={item.id}
									landingPageItem={item}
									panelKey={panelKey}
								/>
							</CollapsePanel>
						);
					})}
				</StyledLandingPageCollapse>
			</StyledWrapperContent>
		</StyledWrapperContent>
	);
};

export default LandingPageList;
