import styled from 'styled-components';
import THEME from '@constants/themes/themes';
import Wrapper from '@cores/Wrapper';
import { Select, Radio, Checkbox, Form, Input, Popover, Popconfirm, Space } from 'antd';

const StyledForm = styled(Form)`
	&.hidden {
		display: none;
	}
`;

const StyledFormItem = styled(Form.Item)`
	margin-bottom: 16px;

	.ant-form-item-label {
		padding: 0;
		margin: 0 0 6px 0;

		label {
			width: 100%;
		}
	}
	&.form_rate_content {
		flex-direction: row;
		align-items: center;
		margin-bottom: 36px;
		display: flex;
		.ant-form-item-label {
			margin: 0 8px 0 0;
		}
	}
	&.social_filter {
		.ant-select-dropdown {
			min-width: 120px !important;
		}
	}
`;

const StyleSocialFilters = styled.div`
	&.hidden {
		display: none;
	}
`;

const StyledSocialActions = styled.div`
	&.hidden {
		display: none;
	}
`;

const StyledContentFilters = styled.div`
	&.hidden {
		display: none;
	}
`;

const StyledContentActions = styled.div`
	&.hidden {
		display: none;
	}
`;

const StyledFilter = styled.div`
	margin-bottom: 16px;
`;

const StyledText = styled.span<{ fontSize?: string }>`
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
`;

const StyledContainer = styled(Wrapper)`
	padding: 26px 24px;
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 250px);
`;

const StyledRadioButton = styled(Radio.Group)<{ fontSize?: string }>`
	.ant-radio-wrapper {
		span {
			color: ${THEME.colors.gray3};
			font-size: ${({ fontSize }) => fontSize || '1rem'};
		}

		.ant-radio-inner {
			border-color: ${THEME.colors.gray3};
		}

		.ant-radio-checked {
			.ant-radio-inner {
				border-color: ${THEME.colors.orangeBase};
			}
		}
	}

	.ant-radio-inner,
	.ant-radio-button-wrapper {
		background-color: ${THEME.colors.darkBlue2};
	}
`;

const StyledSelect = styled(Select)<{ margin?: string }>`
	padding: 0;
	width: 100%;
	${({ margin }) => margin && `margin: ${margin}`};

	.ant-select-item-option-disabled {
		opacity: 0.7;
	}
	.ant-select-dropdown {
		&.dropdown-custom-content {
			overflow: auto !important;
			.rc-virtual-list-holder {
				max-height: calc(50vh - 160px) !important;
				.rc-virtual-list-holder-inner {
					transform: translateY(0px) !important;
				}
			}
			.rc-virtual-list-scrollbar {
				display: none !important;
			}
		}
	}
	&.select_gallery {
		.ant-select-selection-overflow-item {
			height: 40px;
			min-height: 22px;
			&.ant-select-selection-overflow-item-suffix {
				height: auto;
			}
		}
	}
	.ant-select-selection-placeholder {
		color: ${THEME.colors.gray1};
	}
	&.select_detail_terms {
		.ant-select-selector {
			background-color: ${THEME.colors.transparent};
		}
		.ant-select-selection-item {
			background-color: ${THEME.colors.darkBlue4};
		}
		.ant-select-clear {
			background-color: ${THEME.colors.transparent};
		}
		.ant-select-dropdown {
			background-color: ${THEME.colors.darkBlue2};
		}
	}

	&.select_group_tags {
		.ant-select-dropdown {
			.ant-select-item {
				&.ant-select-item-group {
					font-weight: bold;
					color: ${THEME.colors.gray1};
				}
			}
		}
	}
	&.h_item_28 {
		.ant-select-selection-overflow-item {
			height: 28px;
			.ant-select-selection-search {
				height: 28px;
			}
		}
	}

	.ant-select-selection-item {
		.icon_remove {
			display: none;
		}
	}
	.option_custom {
		padding-right: 24px;
		.icon_remove {
			opacity: 0;
			transition: 0.15s ease-out;
		}

		&.ant-select-item-option-active {
			background-color: ${THEME.colors.orangeBase};
			.icon_remove {
				opacity: 1;
			}
		}
	}
	&.no_bg {
		&.ant-select {
			.ant-select-selector {
				background-color: ${THEME.colors.transparent};
			}
		}
	}
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-disabled .ant-checkbox-inner {
		border-color: ${THEME.colors.darkBlue5} !important;
		background-color: transparent !important;
	}
	&.checkbox_group_full {
		width: 100%;
	}
	&.checkbox_group_half {
		display: grid;
		grid-template-columns: auto auto;
	}
	&.checkbox_group_custom {
		.ant-checkbox-checked {
			&.ant-checkbox-disabled .ant-checkbox-inner {
				background-color: ${THEME.colors.gray1} !important;
			}
		}
	}

	.checkbox_default {
		width: auto;
		margin-right: 10px;
		& > span {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			color: ${THEME.colors.gray3};
			padding-right: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}

	.checkbox_dark {
		.ant-checkbox + span {
			color: ${THEME.colors.gray3} !important;
		}
		.ant-checkbox-checked + span {
			color: ${THEME.colors.gray5} !important;
		}
	}
	.half_width {
		width: calc(50% - 10px);
	}
	&.vertical_checkbox {
		display: flex;
		flex-direction: column;
	}
`;

const StyledSpace = styled(Space)<{ gap?: string }>`
	${({ gap }) => gap && `gap: ${gap} !important`};
`;

const StyledCheckbox = styled(Checkbox)<{
	fontSize?: string;
	borderColorDisabled?: string;
	checkboxWidth?: string;
	color?: string;
}>`
	width: ${({ checkboxWidth }) => `${checkboxWidth} !important` || '45%'};
	margin-left: 0 !important;
	margin-bottom: 12px;
	color: ${({ color }) => `${color} !important` || `${THEME.colors.gray3}`};

	.ant-checkbox + span {
		font-size: ${({ fontSize }) => `${fontSize} !important` || '1rem'};
	}

	.ant-checkbox-inner {
	}

	.ant-checkbox-disabled + span {
		color: ${THEME.colors.darkBlue5};
	}
	&.checkbox_default {
		width: auto;
		margin-right: 10px;
		& > span {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			color: ${THEME.colors.gray3};
			padding-right: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&.checkbox_custom {
		max-width: 100%;
		& > span {
			&:not(:first-child) {
				overflow: hidden;
			}
		}
		.ant-checkbox-disabled {
			.ant-checkbox-inner {
				&::after {
					border-color: ${({ borderColorDisabled }) =>
						borderColorDisabled || THEME.colors.darkBlue5} !important;
				}
			}
		}
	}
	&.checkbox_dark {
		.ant-checkbox + span {
			color: ${({ color }) => `${color || THEME.colors.gray3} !important`};
		}
		.ant-checkbox-checked + span {
			color: ${THEME.colors.gray5} !important;
		}
	}
	&.half_width {
		width: calc(50% - 10px);
	}
	&.fix_content {
		width: fit-content;
	}
	&.mb_4 {
		margin-bottom: 4px;
	}
`;

const StyledTextArea = styled(Input.TextArea)``;

const StyledContentPopover = styled.div``;

const StyledPopover = styled(Popover)``;

const StyledPopconfirm = styled(Popconfirm)``;

const StyledRemovableLabels = styled.div<{ right?: string; left?: string; margin?: string }>`
	position: relative;
	margin: ${({ margin }) => margin || '20px 0 14px -10px'};
	width: calc(100% + 24px);
	padding: 24px 12px 0 12px;
	border-radius: 2px;
	border: 1px solid #344552;

	.remove-labels {
		cursor: pointer;
		padding: 0 4px;
		position: absolute;
		top: -19px;
		${({ right }) => right && `right: ${right}`};
		${({ left }) => left && `left: ${left}`};
		padding: 9px 14px;
		height: 38px;
		transition: 0.25s ease-out;
		border: solid 1px ${THEME.colors.darkBlue3};
		background-color: ${THEME.colors.darkBlue2};
		&:not(.no_action) {
			&:hover {
				border-color: ${THEME.colors.orangeBase};
				.label-change_tag {
					transition: 0.25s ease-out;
					color: ${THEME.colors.orangeBase};
				}
			}
		}
		&.no_action {
			cursor: unset;
		}
	}
	&.has_filter {
		.remove-labels {
			background-color: #131b28;
		}
	}
	.ant-space-align-baseline {
		margin-bottom: 8px !important;
	}
`;

export {
	StyledFormItem,
	StyledForm,
	StyledFilter,
	StyledText,
	StyledContainer,
	StyledRadioButton,
	StyledSelect,
	StyledCheckboxGroup,
	StyledCheckbox,
	StyledTextArea,
	StyledPopover,
	StyledContentPopover,
	StyledPopconfirm,
	StyleSocialFilters,
	StyledSocialActions,
	StyledContentFilters,
	StyledContentActions,
	StyledRemovableLabels,
	StyledSpace,
};
