import headerTypes from '@stores/header/header.types';
import HeaderDto   from '@models/header';

export const headerRequest = () => ({
	type: headerTypes.HEADER_REQUEST,
	payload: null
});

export const headerSucceeded = (data: HeaderDto) => ({
	type: headerTypes.HEADER_SUCCEEDED,
	payload: data
});

export const headerFailed = (error: any) => ({
	type: headerTypes.HEADER_FAILED,
	payload: error
});

export const headerGetFilterSegments = (isAddToNewSegment: boolean) => ({
	type: headerTypes.HEADER_GET_FILTER_SEGMENT,
	payload: isAddToNewSegment,
});
