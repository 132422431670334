export const dataSetMap = {
	dataSetCountries: {
		AFG: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ALA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ALB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		DZA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ASM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		AND: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		AGO: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		AIA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ATA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ATG: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ARG: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ARM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ABW: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		AUS: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		AUT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		AZE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BHS: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BHR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BGD: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BRB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BLR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BEL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BLZ: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BEN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BMU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BTN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BOL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BES: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BIH: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BWA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BVT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BRA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		IOT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BRN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BGR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BFA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BDI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CPV: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		KHM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CMR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CAN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CYM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TCD: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CHL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CHN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CXR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CCK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		COL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		COM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		COG: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		COD: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		COK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CRI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CIV: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		HRV: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CUB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CUW: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CYP: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CZE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		DNK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		DJI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		DMA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		DOM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ECU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		EGY: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SLV: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GNQ: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ERI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		EST: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SWZ: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ETH: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		FLK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		FRO: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		FJI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		FIN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		FRA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GUF: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PYF: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ATF: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GAB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GMB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GEO: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		DEU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GHA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GIB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GRC: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GRL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GRD: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GLP: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GUM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GTM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GGY: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GIN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GNB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GUY: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		HTI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		HMD: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		VAT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		HND: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		HKG: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		HUN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ISL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		IND: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		IDN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		IRN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		IRQ: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		IRL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		IMN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ISR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ITA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		JAM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		JPN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		JEY: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		JOR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		KAZ: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		KEN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		KIR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PRK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		KOR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		KWT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		KGZ: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LAO: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LVA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LBN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LSO: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LBR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LBY: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LIE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LTU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LUX: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MAC: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MKD: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MDG: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MWI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MYS: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MDV: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MLI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MLT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MHL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MTQ: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MRT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MUS: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MYT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MEX: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		FSM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MDA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MCO: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MNG: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MNE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MS: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MAR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MOZ: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MMR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NAM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NRU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NPL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NLD: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NCL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NZL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NIC: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NER: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NGA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NIU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NFK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MNP: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		NOR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		OMN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PAK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PLW: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PSE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PAN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PNG: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PRY: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PER: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PHL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PCN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		POL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PRT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		PRI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		QAT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		REU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ROU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		RUS: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		RWA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		BLM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SHN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		KNA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LCA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		MAF: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SPM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		VCT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		WSM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SMR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		STP: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SAU: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SEN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SRB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SYC: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SLE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SGP: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SXM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SVK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SVN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SLB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SOM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ZAF: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SGS: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SSD: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ESP: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		LKA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SDN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SUR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SJM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SWE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		CHE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		SYR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TWN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TJK: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TZA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		THA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TLS: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TGO: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TKL: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TON: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TTO: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TUN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TUR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TKM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TCA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		TUV: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		UGA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		UKR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ARE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		GBR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		USA: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		UMI: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		URY: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		UZB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		VUT: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		VEN: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		VNM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		VGB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		VIR: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		WLF: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ESH: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		YEM: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ZMB: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
		ZWE: {
			fillKey: 'Minor',
			numberOfThings: 0,
		},
	},
	dataSetUSA: {
		AZ: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'AZ',
		},
		CO: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'CO',
		},
		DE: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'DE',
		},
		FL: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'FL',
		},
		GA: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'GA',
		},
		HI: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'HI',
		},
		ID: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'ID',
		},
		IL: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'IL',
		},
		IN: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'IN',
		},
		IA: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'IA',
		},
		KS: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'KS',
		},
		KY: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'KY',
		},
		LA: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'LA',
		},
		MD: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'MD',
		},
		ME: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'ME',
		},
		MA: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'MA',
		},
		MN: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'MN',
		},
		MI: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'MI',
		},
		MS: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'MS',
		},
		MO: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'MO',
		},
		MT: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'MT',
		},
		NC: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'NC',
		},
		NE: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'NE',
		},
		NV: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'NV',
		},
		NH: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'NH',
		},
		NJ: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'NJ',
		},
		NY: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'NY',
		},
		ND: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'ND',
		},
		NM: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'NM',
		},
		OH: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'OH',
		},
		OK: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'OK',
		},
		OR: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'OR',
		},
		PA: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'PA',
		},
		RI: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'RI',
		},
		SC: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'SC',
		},
		SD: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'SD',
		},
		TN: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'TN',
		},
		TX: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'TX',
		},
		UT: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'UT',
		},
		WI: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'WI',
		},
		VA: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'VA',
		},
		VT: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'VT',
		},
		WA: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'WA',
		},
		WV: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'WV',
		},
		WY: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'WY',
		},
		CA: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'CA',
		},
		CT: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'CT',
		},
		AK: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'AK',
		},
		AR: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'AR',
		},
		AL: {
			fillKey: 'Minor',
			numberOfThings: 0,
			state: 'AL',
		},
	},
};
