import { CAMPAIGN_DETAIL_FIELD_NAME } from '@constants/campaign';
import THEME from '@constants/themes/themes';
import CampaignDetailContext from '@contexts/Campaign/CampaignDetail';
import { LandingPageDto } from '@models/landingpagemanager';
import { LandingPageStoreDto } from '@models/landingpagemanager/stores';
import { StyledFormItemCampaign } from '@styled/Campaign/CampaignDetailStyled';
import { StyledButtonPreview, StyledCardModalSelect } from '@styled/Campaign/CampaignStyled';
import { StyledCol, StyledRow, StyledSection, StyledText } from '@styled/Common/CommonStyled';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type LandingPageProps = {
	form: any;
};

const LandingPage = (props: LandingPageProps) => {
	const { form } = props;
	const { t } = useTranslation();
	const { landingPageListLiteResult }: LandingPageStoreDto = useSelector(
		(state: any) => state.landingPage,
	);

	const { disabledFields } = useContext(CampaignDetailContext);

	const landingPageOptions = useMemo(() => {
		const listTemp: any = landingPageListLiteResult ? [...landingPageListLiteResult] : [];
		listTemp.unshift({
			id: 0,
			name: 'None',
		});
		return listTemp;
	}, [landingPageListLiteResult]);

	const formatOptions = (options: LandingPageDto[]) => {
		return options.map((item: any) => {
			const optionItem = {
				label: item.name,
				value: item.id,
			};

			return optionItem;
		});
	};

	const viewLandingPage = () => {
		const communityId = form.getFieldValue(CAMPAIGN_DETAIL_FIELD_NAME.COMMUNITY_ID);
		const community = landingPageListLiteResult?.find((item) => item.id === communityId);
		if (community) {
			window.open(community.url, '_blank');
		}
	};

	return (
		<StyledSection>
			<StyledText margin="0 0 4px 0" color={THEME.colors.gray3}>
				{t('campaign.modal.landing_page')}
			</StyledText>
			<StyledRow gutter={16} align="bottom">
				<StyledCol span={18}>
					<StyledFormItemCampaign name={CAMPAIGN_DETAIL_FIELD_NAME.COMMUNITY_ID}>
						<StyledCardModalSelect options={formatOptions(landingPageOptions || [])} />
					</StyledFormItemCampaign>
				</StyledCol>

				<StyledCol span={6}>
					<StyledButtonPreview
						margin={'0 0 8px 0'}
						onClick={viewLandingPage}
						type="primary"
						isDisabled={disabledFields.includes(
							CAMPAIGN_DETAIL_FIELD_NAME.COMMUNITY_ID,
						)}>
						{t('campaign.modal.view')}
					</StyledButtonPreview>
				</StyledCol>
			</StyledRow>
		</StyledSection>
	);
};

export default LandingPage;
