import { CLASS_ELEMENT } from '@constants/content/contentLibrary';
import _ from 'lodash';
import { useEffect, useLayoutEffect, useState } from 'react';

export const useScroll = (
	scrollableElement: HTMLElement | null,
	func: (scrollBarPosition: number) => void,
	dependencies?: any,
) => {
	useEffect(() => {
		if (scrollableElement) {
			const handleScrollList = (event: Event) => {
				const { scrollTop } = event.target as HTMLDivElement;
				if (func && typeof func === 'function') {
					func(scrollTop);
				}
			};

			scrollableElement.addEventListener('scroll', handleScrollList);
			return () => scrollableElement.removeEventListener('scroll', handleScrollList);
		}

		return () => {};
	}, dependencies);
};

export const useScrollToLoadMore = (
	scrollableElement: HTMLElement | null,
	func: () => void,
	dependencies?: any,
) => {
	const debouncedLoadMore = _.debounce(func, 300); // 300ms debounce time

	useEffect(() => {
		if (scrollableElement) {
			const handleScrollList = (event: Event) => {
				const { clientHeight, scrollTop, scrollHeight } = event.target as HTMLDivElement;
				const scrollbarTop = (clientHeight + scrollTop) / scrollHeight;

				if (scrollbarTop >= 0.95) {
					if (func && typeof func === 'function') {
						// Prevent func is called many times.
						debouncedLoadMore();
					}
				}
			};

			scrollableElement.addEventListener('scroll', handleScrollList);
			return () => scrollableElement.removeEventListener('scroll', handleScrollList);
		}

		return () => {};
	}, dependencies);
};

export const useScaleToOverflow = (
	elementCheck: HTMLElement | null,
	arrElementSize: Array<HTMLElement | null>,
	dependencies: Array<any>,
	offsetSpaceAllElm?: number,
	handleChangeOverflow?: (val: boolean) => void,
	classScrollAdd?: string,
) => {
	const [isOverflow, setIsOverflow] = useState(false);
	const classScroll = classScrollAdd || CLASS_ELEMENT.CLASS_ADD_SCROLL;

	const getTotalSize = () => {
		let result = offsetSpaceAllElm || 40;
		arrElementSize.forEach((elm) => {
			if (elm) {
				result += elm.offsetWidth;
			}
		});
		return result;
	};

	const handleChange = (val: boolean) => {
		setIsOverflow(val);

		if (handleChangeOverflow && typeof handleChangeOverflow === 'function') {
			handleChangeOverflow(val);
		}
		if (val) {
			if (elementCheck && !elementCheck?.classList.contains(classScroll)) {
				elementCheck.classList.add(classScroll);
			}
		} else {
			if (elementCheck && elementCheck?.classList.contains(classScroll)) {
				elementCheck.classList.remove(classScroll);
			}
		}
	};

	const handleResize = () => {
		setTimeout(() => {
			if (elementCheck) {
				const totalSize = getTotalSize();
				const widthElm = elementCheck.offsetWidth;
				if (widthElm <= totalSize && !isOverflow) {
					handleChange(true);
				}
				if (widthElm > totalSize && isOverflow) {
					handleChange(false);
				}
			}
		}, 0);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [...dependencies, isOverflow]);
};

export const useWindowSize = () => {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
};
