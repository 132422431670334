import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { MAX_RECORD_ALL, TYPE_ALBUM } from '@constants/content/albums';
import THEME from '@constants/themes/themes';
import AlbumManagerContext from '@contexts/Content/AlbumManager';
import { AlbumDto } from '@models/content/albums/albumManager';
import { createAlbumRequest, removeSuggestAlbumRequest } from '@stores/actions';
import { StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledAlbumList,
	StyledDivider,
	StyledWrapperAlbumList,
} from '@styled/Content/AlbumManager/AlbumManagerStyled';
import { StyledEmpty } from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledWrapper } from '@styled/Content/ContentLibrary/ContentStyled';
import { renderThumbVertical } from '@utils/renderComponent';
import { RefObject, useContext, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlbumItem from './AlbumItem';
import CustomSelection from '@components/CustomLibrary/CustomSelection';
import { SelectionCustomRef } from '@models/common/ref';

const AlbumList = () => {
	const { loadingAlbums, albums } = useSelector((state: any) => state.albumManager);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const scrollbars = useRef<any>({});
	const refEventSelect = useRef<
		SelectionCustomRef | undefined
	>() as RefObject<SelectionCustomRef>;

	const {
		listAlbum,
		setParamAlbums,
		setAlbumsSelected,
		setAlbumsEnable,
		albumsSelected,
		albumsEnable,
		listSuggestAlbum,
		setListSuggestAlbum,
		paramAlbumSuggest,
		setAlbumSuggestSelect,
		showSuggest,
	} = useContext(AlbumManagerContext);

	const onScrollStop = () => {
		if (scrollbars.current !== null) {
			const scrollbarTop = scrollbars.current.getValues().top;
			if (scrollbarTop >= 0.75 && albums?.length > 0 && !loadingAlbums) {
				setParamAlbums((prev: any) => ({ ...prev, page: prev.page + 1 }));
			}
		}
	};

	const handleSelectAlbum = (album: AlbumDto) => {
		const { id, type } = album;
		const hasShare = album?.metadata?.share;
		const newListSelect = handlePushId(albumsSelected, id);
		if (
			refEventSelect?.current?.handleSectionCustom &&
			typeof refEventSelect.current.handleSectionCustom === 'function'
		) {
			refEventSelect.current.handleSectionCustom(newListSelect);
		}

		if (!refEventSelect?.current?.isShift) {
			setAlbumsSelected(newListSelect);
			if (hasShare) {
				setAlbumsEnable(handlePushId(albumsEnable, id));
			}
		}
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		const listAlb = [...listSuggestAlbum, ...listAlbum];
		const albEnable: number[] = [];
		listAlb.forEach((item) => {
			if (list.includes(item.id)) {
				const { id, type } = item;
				const hasShare = item?.metadata?.share;
				if (hasShare) {
					albEnable.push(id);
				}
			}
		});
		setAlbumsSelected(list as number[]);
		setAlbumsEnable(albEnable);
	};

	const handlePushId = (list: Array<number>, idSelect: number) => {
		let listSelectChange: Array<number> = [...list];
		if (listSelectChange?.includes(idSelect)) {
			listSelectChange = listSelectChange?.filter((id) => id !== idSelect);
		} else {
			listSelectChange.push(idSelect);
		}
		return listSelectChange;
	};

	const handleMaybeLaterAlbum = (albumSelect: AlbumDto) => {
		const newListChange = listSuggestAlbum?.filter((album) => album?.id !== albumSelect?.id);
		if (paramAlbumSuggest?.maxRecords === MAX_RECORD_ALL) {
			newListChange.push(albumSelect);
		}
		setListSuggestAlbum([...newListChange]);
	};

	const handleAfterRemove = (id: number) => {
		const newListChange = listSuggestAlbum?.filter((album) => album?.id !== id);
		setListSuggestAlbum([...newListChange]);
	};

	const handleNoThank = (albumsSelected: AlbumDto) => {
		const { listContents, contents, loading, ...otherParam } = albumsSelected;
		const dataRequest = {
			...otherParam,
			metadata: JSON.stringify(albumsSelected?.metadata),
			callback: handleAfterRemove,
		};
		dispatch(removeSuggestAlbumRequest(dataRequest));
	};

	const handleAddToAlbum = (albumsSelected: AlbumDto) => {
		const { listContents, contents, loading, ...otherParam } = albumsSelected;
		const dataRequest = {
			...otherParam,
			metadata: JSON.stringify(albumsSelected?.metadata),
			callback: () => handleAfterRemove(albumsSelected?.id),
		};
		setAlbumSuggestSelect(albumsSelected);
		dispatch(createAlbumRequest(dataRequest));
	};

	const renderListAlbum = (list: Array<AlbumDto>) => {
		return list?.map((item: AlbumDto) => (
			<AlbumItem
				onSelectAlbum={() => handleSelectAlbum(item)}
				isSelected={albumsSelected?.includes(item?.id)}
				key={item?.id}
				album={item}
				handleMaybeLaterAlbum={() => handleMaybeLaterAlbum(item)}
				handleNoThank={() => handleNoThank(item)}
				handleAddToAlbum={() => handleAddToAlbum(item)}
			/>
		));
	};

	const renderTitle = (label: string) => {
		if (!showSuggest) {
			return null;
		}
		return (
			<StyledTitle color={THEME.colors.white} fontSize="20px" margin="0 0 16px 0">
				{label}
			</StyledTitle>
		);
	};

	return (
		<StyledWrapperAlbumList>
			<CustomSelection
				ref={refEventSelect}
				ids={[...listSuggestAlbum, ...listAlbum].map((item) => item.id)}
				selectIds={albumsSelected}
				refSelection={undefined}
				handleSelectNewList={handleSelectNewList}
				noUseLibSelection={true}
			/>
			{[...listAlbum, ...listSuggestAlbum].length > 0 ? (
				<Scrollbars
					ref={(el) => {
						scrollbars.current = el;
					}}
					className="scrollbar_custom_wrapper"
					renderTrackVertical={() => <div />}
					onScrollStop={onScrollStop}
					style={{ height: 'calc(100% - 24px)' }}
					renderThumbVertical={renderThumbVertical}>
					<StyledAlbumList>
						{listSuggestAlbum?.length > 0 && renderTitle(t('albums.suggested_albums'))}
						{renderListAlbum(listSuggestAlbum)}
						{listSuggestAlbum?.length > 0 && showSuggest && <StyledDivider />}
						{renderTitle(t('albums.your_albums'))}
						{renderListAlbum(listAlbum)}
					</StyledAlbumList>
				</Scrollbars>
			) : (
				!loadingAlbums && (
					<StyledWrapper className="height_full" marginTop={'0'}>
						<StyledEmpty image={IconEmpty} />
					</StyledWrapper>
				)
			)}
		</StyledWrapperAlbumList>
	);
};

export default AlbumList;
