import { useState } from 'react';
import THEME from '@constants/themes/themes';
import { Modal } from '@cores/Modal';
import { Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {} from '@styled/Common/CommonStyled';
import { changePassword } from '@stores/actions';
import { StyledFormItem, StyledTooltip } from '@styled/ChangePasswordStyled';
import _ from 'lodash';
import { getUser, setUser } from '@helpers/userHelpers';
import { UserType } from '@stores/user/user.types';
import { renderErrorMessagePwd } from '@utils/renderComponent';
import { PATTERNS } from '@constants/common';

type ChangePasswordProps = {
	isOpen: boolean;
	handleModalChangePassword: () => void;
};

type ChangePasswordDto = {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
};

const ChangePassword = (props: ChangePasswordProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { isOpen, handleModalChangePassword } = props;

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleChangePassword = (values: ChangePasswordDto) => {
		setIsLoading(true);
		const payload = {
			params: values,
			changePasswordSucceded,
			changePasswordFailed,
		};
		dispatch(changePassword(payload));
	};

	const changePasswordSucceded = (userInfo: UserType) => {
		setIsLoading(false);

		message.success(t('change_password.message.change_success')).then(() => {
			const storedUser = getUser();
			const updatedUser = { ...storedUser, ...userInfo };
			setUser(updatedUser);
			form.resetFields();
			handleModalChangePassword();
		});
	};

	const changePasswordFailed = (error: string) => {
		setIsLoading(false);
		message.error(error);
	};

	const renderModalContent = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={handleChangePassword}
				requiredMark={false}>
				<StyledFormItem
					className="custom-form-item"
					name="oldPassword"
					label={t('change_password.old_password')}
					hasFeedback
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(
								t('change_password.validate.required_old_password'),
							),
						},
					]}>
					<Input.Password />
				</StyledFormItem>
				<StyledFormItem
					name="newPassword"
					label={t('change_password.new_password')}
					hasFeedback
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(
								t('change_password.validate.required_new_password'),
							),
						},
						{
							pattern: PATTERNS.PASSWORD,
							message: renderErrorMessagePwd(t('format_password')),
						},
					]}>
					<Input.Password />
				</StyledFormItem>
				<StyledFormItem
					name="confirmPassword"
					label={t('change_password.confirm_password')}
					hasFeedback
					rules={[
						{
							required: true,
							message: renderErrorMessagePwd(
								t('change_password.validate.required_confirm_password'),
							),
						},
						({ getFieldValue }: any) => ({
							validator(_: any, value: string) {
								if (!value || getFieldValue('newPassword') === value) {
									return Promise.resolve();
								}
								return Promise.reject(
									renderErrorMessagePwd(
										t('change_password.validate.password_not_match'),
									),
								);
							},
						}),
					]}>
					<Input.Password />
				</StyledFormItem>
			</Form>
		);
	};

	return (
		<Modal
			width={400}
			padding="24px 24px 0 24px"
			backgroundColor={THEME.colors.darkBlue2}
			title={t('change_password.title')}
			okText={t('change_password.title')}
			isOpen={isOpen}
			closable={true}
			isLoading={isLoading}
			onClose={() => {
				handleModalChangePassword();
				form.resetFields();
			}}
			onSubmit={() => form.submit()}
			destroyOnClose={true}>
			{renderModalContent()}
		</Modal>
	);
};

export default ChangePassword;
