import { IconMoreVert } from '@assets/icons';
import { CAMPAIGN_DETAIL_FIELD_NAME, RANGE_GOALS, TAB } from '@constants/campaign';
import { TYPE_ACTIONS } from '@constants/common';
import { MAX_LENGTH_CHARACTER } from '@constants/content/contentLibrary';
import CampaignDetailContext from '@contexts/Campaign/CampaignDetail';
import ButtonGroup from '@cores/ButtonGroup';
import { CampaignDto } from '@models/campaign';
import { WidgetLinkDto } from '@models/common/summary';
import { ActionsTypes } from '@models/content/albums/albumManager';
import {
	StyledCustomizedFormItem,
	StyledFormItemCampaign,
	StyledWrapperAction,
	StyledWrapperContainer,
	StyledWrapperContent,
	StyledWrapperHeader,
} from '@styled/Campaign/CampaignDetailStyled';
import { StyledButton } from '@styled/Common/CommonStyled';
import { StyledDividerLine } from '@styled/Filter/FilterContentStyled';
import { StyledButtonMore, StyledDropdown } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { Button, Input, InputNumber, Menu } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AdvancedInformation from './AdvancedInformation';
import CreatorInteraction from './CreatorInteraction';

type PropTypes = {
	form: any;
};

const Detail = (props: PropTypes) => {
	const { form } = props;
	const { t } = useTranslation();
	const { campaignDetail }: CampaignDto = useSelector((state: any) => state.campaign);

	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const {
		typeCampaign,
		handleReset,
		handleRedirectCampaignPage,
		handleSubmit,
		setTypeAction,
		hasChange,
	} = useContext(CampaignDetailContext);

	const { widgetLinks = [] } = clientSettings;

	const onRedirectLinkWidget = () => {
		const linkElement = document.createElement('a');
		const widgetId = form.getFieldValue('widgetId');

		const urlDefault =
			widgetLinks?.find((item: WidgetLinkDto) => item?.id === widgetId)?.url || '';

		linkElement.href = urlDefault;
		if (campaignDetail?.id) {
			linkElement.href =
				urlDefault.indexOf('?') >= 0
					? `${urlDefault}&c=${campaignDetail?.id}`
					: `${urlDefault}?c=${campaignDetail?.id}`;
		}
		linkElement.target = '_blank';
		document.body.appendChild(linkElement);
		linkElement.click();
		document.body.removeChild(linkElement);
	};

	const handleArchiveCampaign = () => {
		handleSubmit(TAB.archives.key);
	};

	const handleGoLive = () => {
		handleSubmit(TAB.active.key);
	};

	const handleRestore = () => {
		handleSubmit(TAB.inactive.key, true);
	};

	const handleSaveDraft = () => {
		handleSubmit(TAB.inactive.key);
	};

	const actionsObj: ActionsTypes[] = [
		{
			id: 2,
			name: t('button.go_live'),
			click: handleGoLive,
			hidden: typeCampaign !== TAB.inactive.key,
		},
		{
			id: 1,
			name: t('button.archive_campaign'),
			click: handleArchiveCampaign,
		},
	];

	const menuActions = (
		<Menu>
			{actionsObj.map((item: ActionsTypes) => {
				if (item?.hidden) {
					return null;
				}
				return (
					<Menu.Item key={item.id} onClick={item.click}>
						{item.name}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	const ruleGoal: any = {
		type: 'number',
		min: RANGE_GOALS.MIN,
		max: RANGE_GOALS.MAX,
		message: t('campaign.validate.range_value', {
			min: RANGE_GOALS.MIN,
			max: RANGE_GOALS.MAX,
		}),
	};

	return (
		<StyledWrapperContainer>
			<StyledWrapperHeader>{t('campaign_detail.goals')}</StyledWrapperHeader>
			<StyledDividerLine />
			<StyledWrapperContent
				className="custom_scroll_bar"
				style={typeCampaign === TAB.archives.key ? { pointerEvents: 'none' } : {}}>
				<StyledFormItemCampaign
					name={CAMPAIGN_DETAIL_FIELD_NAME.NAME}
					label={t('campaign.modal.campaign_nickname')}
					rules={[
						{
							required: true,
							message: t('campaign.validate.required', {
								field: t('campaign.modal.name_campaign'),
							}),
						},
					]}
					margin="0 0 24px 0"
					maxLength={MAX_LENGTH_CHARACTER}>
					<Input placeholder={t('campaign.placeholder.campaign_nickname')} />
				</StyledFormItemCampaign>
				<CreatorInteraction form={form} onRedirectLinkWidget={onRedirectLinkWidget} />
				<StyledCustomizedFormItem
					name={CAMPAIGN_DETAIL_FIELD_NAME.CREATOR_GOALS}
					label={t('campaign.modal.creators_participate')}
					rules={[
						{
							required: true,
							message: t('campaign.validate.required', {
								field: t('campaign.modal.creators_goals'),
							}),
						},
						{ ...ruleGoal },
					]}>
					<InputNumber />
				</StyledCustomizedFormItem>
				<StyledDividerLine margin="24px 0 8px 0" />
				<AdvancedInformation />
			</StyledWrapperContent>
			<StyledDividerLine />
			<StyledWrapperAction>
				{typeCampaign === TAB.archives.key && (
					<>
						<StyledButton
							onClick={() => setTypeAction(TYPE_ACTIONS.REMOVE)}
							margin="0 12px 0 0">
							{t('button.delete_campaign')}
						</StyledButton>
						<Button onClick={() => handleRestore()} type="primary">
							{t('button.restore')}
						</Button>
					</>
				)}
				{(typeCampaign === TAB.active.key || typeCampaign === TAB.inactive.key) && (
					<>
						<StyledDropdown
							className="dropdown_action"
							overlay={menuActions}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode as HTMLElement
							}
							placement="topRight"
							trigger={['click']}>
							<StyledButtonMore>
								<IconMoreVert />
							</StyledButtonMore>
						</StyledDropdown>
						<ButtonGroup
							hasChanges={hasChange}
							onSubmit={() => handleSubmit('')}
							onCancel={handleReset}
							margin="0 12px 0 16px"
							fontSizeText="13px"
						/>
					</>
				)}
				{typeCampaign === TYPE_ACTIONS.CREATE && (
					<>
						<StyledButton onClick={() => handleRedirectCampaignPage()}>
							{t('button.cancel')}
						</StyledButton>
						<StyledButton onClick={() => handleSaveDraft()} margin="0 12px">
							{t('button.save_draft')}
						</StyledButton>
						<Button onClick={() => handleGoLive()} type="primary">
							{t('button.go_live')}
						</Button>
					</>
				)}
			</StyledWrapperAction>
		</StyledWrapperContainer>
	);
};

export default Detail;
