/**
 * @prettier
 */

import styled from 'styled-components';
import THEME from '@constants/themes/themes';

const StyledIcon = styled.div`
	svg {
		width: 40px;
		height: 40px;
	}
`;

const StyledMetricsBlock = styled.div`
	margin-top: 16px;
	display: block;
`;

const StyledInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 24px;
	width: calc(100% - 64px);
`;

const StyledTitle = styled.div`
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 18px;
	font-size: 0.857rem;
	font-weight: 400;
	line-height: 1.5;
	font-stretch: normal;
	font-style: normal;
	text-transform: uppercase;
`;

const StyledCount = styled.div`
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 34px;
	font-size: 1.714rem;
	line-height: 1.42;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
`;

const StyledCol = styled.div`
	border-radius: 2px;
	display: flex;
	height: 80px;
	align-items: center;
	padding: 16px !important;
	background-color: ${THEME.colors.darkBlue2};
`;

export { StyledIcon, StyledInfo, StyledTitle, StyledCount, StyledCol, StyledMetricsBlock };
