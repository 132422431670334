import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { entribeLogo, ImageLogoTextWhite } from '@assets/images';
import { LoadingWrapper } from '../cores';

// layout
import { ROUTE_PATH } from '@constants/common';
import Header from '@layout/Header';
import Sidebar from '@layout/Sidebar';
import { UserRootStateDto } from '@stores/user/user.types';
import {
	StyledContent,
	StyledLayoutContainer,
	StyledLogo,
	StyledSibarContainer,
} from '@styled/Layout/StyledLayout';
import { useLocation } from 'react-router-dom';

// Styled

type PrivateLayoutProps = {
	component: any;
	rest: any;
};

const PrivateLayout = ({ component: Component, rest }: PrivateLayoutProps) => {
	const { isCollapsedMenu } = useSelector((state: any) => state.sidebar);
	const { t } = useTranslation();
	const { pathname } = useLocation();

	// Reducers
	const { fetchingUser } = useSelector((state: UserRootStateDto) => state.user);

	const renderLogo = () =>
		isCollapsedMenu ? (
			<StyledLogo>
				<img src={entribeLogo} alt={t('company_name')} />
			</StyledLogo>
		) : (
			<StyledLogo className="logo-text">
				<img src={ImageLogoTextWhite} alt={t('company_name')} />
			</StyledLogo>
		);

	return (
		<StyledLayoutContainer>
			<StyledSibarContainer>
				{renderLogo()}
				<Sidebar />
			</StyledSibarContainer>

			<Layout>
				<Header isCollapsedMenu={isCollapsedMenu} />
				<StyledContent>
					<LoadingWrapper
						isLoading={fetchingUser && !pathname.includes(ROUTE_PATH.TASK_MANAGER)}>
						<Component {...rest} />
					</LoadingWrapper>
				</StyledContent>
			</Layout>
		</StyledLayoutContainer>
	);
};

export default PrivateLayout;
