import { Modal } from '@cores/Modal';
import { sendEmailResetPwd } from '@stores/actions';
import { Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

type ForgotPasswordWithLoginProps = {
	visible: boolean;
	onClose: () => void;
};

const ForgotPasswordWithLogin = (props: ForgotPasswordWithLoginProps) => {
	const { t } = useTranslation();
	const [form] = useForm();
	const dispatch = useDispatch();
	const { visible, onClose } = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const resetData = () => {
		form.resetFields();
		onClose();
	};

	const onFailed = (error: string) => {
		setIsLoading(false);
		message.error(error);
	};

	const onSucceeded = () => {
		setIsLoading(false);
		message.info(t('forget_password.reset_link_success'));
		resetData();
	};

	const sendEmail = (values: any) => {
		const payload = {
			params: values,
			onSucceeded,
			onFailed,
		};

		setIsLoading(true);
		dispatch(sendEmailResetPwd(payload));
	};

	return (
		<Modal
			width={400}
			title={t('login.title_input_email')}
			okText={t('button.send')}
			cancelText={t('button.cancel')}
			isOpen={visible}
			closable={true}
			isLoading={isLoading}
			onClose={onClose}
			onSubmit={() => form.submit()}>
			<Form requiredMark={false} form={form} onFinish={sendEmail}>
				<Form.Item
					label="Email"
					name="email"
					rules={[
						{
							required: true,
							message: t('setting.account_user.validation.required', {
								field: 'Email',
							}),
						},
						{
							type: 'email',
							message: t('setting.account_user.validation.invalid_email'),
						},
					]}>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ForgotPasswordWithLogin;
