import { PARAMS_DEFAULT } from '@constants/common';
import { ParamSocialFeed } from '@models/socialsearch/summary';

const HEIGHT_CARD_DEFAULT = 177;

const ID_EMPTY = -1;

const PARAM_DEFAULT_FEED: ParamSocialFeed = {
	...PARAMS_DEFAULT,
	id: -1,
};

const ID_CONTAINER_FEED = 'scroll_container_feed';

const LINE_HEIGHT_DESC = 16;

const SOCIAL_TYPE = {
	SOCIAL_POST: 'SOCIAL_POST',
	SOCIAL_FEED: 'SOCIAL_FEED',
};

export {
	HEIGHT_CARD_DEFAULT,
	PARAM_DEFAULT_FEED,
	ID_EMPTY,
	LINE_HEIGHT_DESC,
	ID_CONTAINER_FEED,
	SOCIAL_TYPE,
};
