import { IconCheckActive } from '@assets/icons';
import THEME from '@constants/themes/themes';
import { CardMetricType } from '@models/socialsearch/summary';
import { StyledIcon, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledCardFeed,
	StyledInfoCard,
	StyledNameCard,
	StyledValueCard,
	StyledWrapperItemCard,
} from '@styled/SocialSearch/Feeds';
import { Spin, Tooltip } from 'antd';

type PropTypes = {
	card: CardMetricType;
	bgColor?: string;
	[other: string]: any;
};
const MetricCard = (props: PropTypes) => {
	const { card, bgColor, ...other } = props;

	const renderValueCard = () => {
		return (
			<>
				{card.value}
				{!!card?.subValue && (
					<StyledTitle
						fontSize="inherit"
						lineHeight="inherit"
						display="inline"
						className="unset_height"
						margin="0 0 0 8px"
						color={card?.colorSubValue || THEME.colors.greenBase}>
						{card.subValue}
					</StyledTitle>
				)}
			</>
		);
	};
	return (
		<StyledWrapperItemCard key={card.id} {...other}>
			<StyledCardFeed
				bgColor={bgColor || THEME.colors.darkBlue2}
				className={card.disabled ? 'disabled' : ''}>
				<StyledIcon size={36} position="relative" cursor="default" className="transparent">
					{card.icon}
					{!!card?.full && (
						<StyledIcon
							size={20}
							className="absolute"
							cursor="default"
							top="-6px"
							left="-8px">
							<IconCheckActive />
						</StyledIcon>
					)}
				</StyledIcon>
				<StyledInfoCard>
					<StyledNameCard title={card.name}>{card.name}</StyledNameCard>

					<StyledValueCard
						title={card?.loadingValue ? '' : `${card.value} ${card?.subValue || ''}`}>
						{card?.loadingValue ? (
							<Spin style={{ textAlign: 'left' }} />
						) : (
							renderValueCard()
						)}
					</StyledValueCard>
				</StyledInfoCard>
			</StyledCardFeed>
		</StyledWrapperItemCard>
	);
};

export default MetricCard;
