import { ROUTE_PATH } from '@constants/common';
import { FORM_CONTENT_DETAIL } from '@constants/content/contentLibrary';
import { TYPE_THEME } from '@constants/publish/gallery';
import { StyledCustomSelect } from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledFormItem } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import { StyledLabel } from '@styled/ContentLibrary/ContentDetailStyled';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import GalleryTag from './GalleryTag';

type PropFormItem = {
	label?: ReactNode;
	name: string;
	optionData: Array<any>;
	otherPropSelect?: any;
	disabled?: boolean;
	mode?: string;
	[other: string]: any;
};
const FormItemSelect = (props: PropFormItem) => {
	const {
		label = null,
		name,
		optionData,
		disabled,
		otherPropSelect = {},
		mode,
		...other
	} = props;
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const renderListOption = (options: any[], name: string) => {
		if (name === FORM_CONTENT_DETAIL.CAMPAIGNS) {
			return options?.map((option) => ({
				label: option?.name,
				value: option?.id.toString(),
			}));
		}

		if (name === FORM_CONTENT_DETAIL.GALLERIES) {
			return options?.map((option) => ({
				label: option?.name,
				value: option?.id,
			}));
		}

		return options?.map((option) => ({ label: option, value: option }));
	};

	const renderTagGallery = (val: any) => {
		const galleryItem = optionData?.find((item) => item?.id === val?.value);
		let theme = TYPE_THEME.GRID;
		if (galleryItem) {
			const metadataObj = JSON.parse(galleryItem?.metadata);
			if (metadataObj?.theme) {
				theme = metadataObj?.theme;
			}
		}
		return <GalleryTag theme={theme} {...val} />;
	};
	const renderOtherProp = () => {
		const result: any = {};
		if (name === FORM_CONTENT_DETAIL.GALLERIES) {
			result.tagRender = renderTagGallery;
		}
		return result;
	};

	const otherLabel: any = {};
	if (other?.id) {
		otherLabel.id = other.id;
	}

	return (
		<StyledFormItem
			{...other}
			name={name}
			label={label && <StyledLabel {...otherLabel}>{label}</StyledLabel>}>
			<StyledCustomSelect
				style={{ margin: '0' }}
				disabled={
					pathname === ROUTE_PATH.DASHBOARD ||
					pathname === ROUTE_PATH.AMBASSADOR_DASHBOARD ||
					!!disabled
				}
				mode={mode}
				showArrow={true}
				heightParentContains={0}
				notFoundContent={notFoundContent()}
				subtractedHeight={0}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				virtual={false}
				optionFilterProp="label"
				{...renderOtherProp()}
				options={renderListOption(optionData, name)}
				dropdownClassName={'dropdown-menu dropdown-custom-content_detail'}
				className={name === FORM_CONTENT_DETAIL.GALLERIES ? 'select_gallery' : ''}
				{...otherPropSelect}
			/>
		</StyledFormItem>
	);
};

export default FormItemSelect;
