import { IconExpandMore, IconFilter } from '@assets/icons';
import FilterSelect, { FilterSelectTypes } from '@components/Filter/FilterSelect';
import { STATUS_TYPE } from '@constants/campaign';
import THEME from '@constants/themes/themes';
import AmbassadorDashboardManagerContext from '@contexts/AmbassadorDashboard';
import { handleDownloadExcel } from '@helpers/base64Helper';
import { getUser } from '@helpers/userHelpers';
import { AmbassadorManagerStoreDto } from '@models/ambassador/stores';
import { CampaignSimpleDto } from '@models/campaign';
import { ActionNoClickTypes, ActionsTypes } from '@models/content/albums/albumManager';
import {
	getAmbassadorReportRequest,
	refreshMetricAmbassadorRequest,
	resetFieldsAmbassadorData,
} from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import { StyledWrapperRefreshMetric, StyledWrapperRightAction } from '@styled/AmbassadorDashboard';
import {
	StyledButton,
	StyledDropdown,
	StyledIcon,
	StyledTitle,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import {
	StyledContainerListOption,
	StyledNameOption,
	StyledOptionItem,
	StyledWrapperOverlayDropdown,
} from '@styled/Content/ContentLibrary/ActionBarStyled';
import { message } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import RefreshMetrics from '../RefreshMetrics';
import { useLocation } from 'react-router-dom';
import history from '@utils/history';
import FilterDropdownSelect, { ValueSelectIdTypes } from '@components/Filter/FilterDropdownSelect';
import { SUB_ID } from '@constants/ambassador';
import { SimpleSegmentsDto } from '@models/creator/segments';
import { TYPE_SEGMENT } from '@constants/creator';

interface CampaignGroupDropdown extends ActionsTypes {
	isGroup?: boolean;
	data?: ActionsTypes[];
}

type TopActionLocation = {
	campaign?: {
		value: number;
		name: string;
	} | null;
};

const TopAction = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { base64Report }: AmbassadorManagerStoreDto = useSelector(
		(state: any) => state.ambassadors,
	);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { listCampaign, segmentList }: UserStoreType = useSelector((state: any) => state.creator);
	const { state = {}, pathname } = useLocation<TopActionLocation>();

	const {
		ambassadorRefreshMetricsEnabled = false,
		ambassadorMetricsRefreshUpdateAt = 0,
		ambassadorMetricsRefreshInProgress = false,
	} = clientSettings || {};

	const {
		campaignSelectValue,
		setCampaignSelectValue,
		segmentSelectValue,
		setSegmentSelectValue,
		setFieldDisable,
		fieldDisabled,
	} = useContext(AmbassadorDashboardManagerContext);

	const user = getUser();

	const [openGeneral, setOpenGeneral] = useState<boolean>(false);
	const [campaignReport, setCampaignReport] = useState<CampaignSimpleDto | null>(null);

	useEffect(() => {
		window.addEventListener('beforeunload', () => {
			if (state && state?.campaign) {
				history.replace(pathname, null);
			}
		});
	}, []);

	useEffect(() => {
		if (base64Report) {
			setCampaignReport(null);
			const nameFile = campaignReport
				? `${t('ambassador.campaign_report')}_${campaignReport?.name}_`
				: `${t('ambassador.all_campaign_report')}_`;
			handleDownloadExcel(base64Report, '', nameFile);
			message.info(t('dashboard.message_generate_report'));
			dispatch(resetFieldsAmbassadorData({ base64Report: '' }));
		}
	}, [base64Report]);

	useEffect(() => {
		if (state && state?.campaign) {
			onChangeFilterCampaign(`${SUB_ID.CAMPAIGN}${state?.campaign?.value}`);
		}
	}, [state]);

	const handleRefreshMetric = () => {
		dispatch(refreshMetricAmbassadorRequest());
	};

	const handleSelectCampaign = (id?: number) => {
		setOpenGeneral(false);
		dispatch(getAmbassadorReportRequest(id));
	};

	const objDataCampaign = useMemo(() => {
		const listActive: CampaignSimpleDto[] = [];
		const listInactive: CampaignSimpleDto[] = [];
		listCampaign.forEach((campaign) => {
			if (campaign.status === STATUS_TYPE.ACTIVE) {
				listActive.push(campaign);
			} else {
				listInactive.push(campaign);
			}
		});
		return { active: listActive, inactive: listInactive };
	}, [listCampaign]);

	const objDataSegment = useMemo(() => {
		const listActive: SimpleSegmentsDto[] = [];
		const listInactive: SimpleSegmentsDto[] = [];
		const listOther: SimpleSegmentsDto[] = [];
		segmentList.forEach((segment) => {
			if (segment.type === TYPE_SEGMENT.DEFAULT) {
				if (segment.title === TYPE_SEGMENT.FOLLOWING) {
					listActive.push(segment);
				} else if (segment.title === TYPE_SEGMENT.NOT_FOLLOWING) {
					listInactive.push(segment);
				} else {
					listOther.push(segment);
				}
			} else {
				listOther.push(segment);
			}
		});
		return { following: listActive, notFollowing: listInactive, other: listOther };
	}, [segmentList]);

	const actionsObj = () => {
		const handleGroupItem = (list: CampaignSimpleDto[]) => {
			return list.map((item) => ({
				id: item.id,
				name: item.name,
				click: (e: any) => {
					e.preventDefault();
					e.stopPropagation();
					handleSelectCampaign(item?.id);
					setCampaignReport(item);
				},
			}));
		};

		const result: CampaignGroupDropdown[] = [
			{
				id: -1,
				name: t('ambassador.full_report'),
				click: () => {
					handleSelectCampaign();
					setCampaignReport(null);
				},
			},
			{
				id: 1,
				name: t('ambassador.active_campaigns'),
				click: () => {},
				isGroup: true,
				data: handleGroupItem(objDataCampaign.active),
			},
			{
				id: 2,
				name: t('ambassador.inactive_campaigns'),
				click: () => {},
				isGroup: true,
				data: handleGroupItem(objDataCampaign.inactive),
			},
		];

		return result;
	};

	const actionSegment: ActionNoClickTypes[] = useMemo(() => {
		const result: ActionNoClickTypes[] = [
			{
				id: SUB_ID.OTHER,
				name: t('ambassador.other_segment'),
				disabled: fieldDisabled.includes(SUB_ID.OTHER),
				children: objDataSegment.other?.map((item) => ({
					id: `${SUB_ID.SEGMENT}${item?.id}`,
					name: item?.title,
					click: () => {},
				})),
			},
		];
		if (objDataSegment.notFollowing.length > 0) {
			const itemNotFollowing = objDataSegment.notFollowing[0];
			result.unshift({
				id: `${SUB_ID.SEGMENT}${itemNotFollowing.id}`,
				name: itemNotFollowing?.title,
				isFollowing: false,
			});
		}
		if (objDataSegment.following.length > 0) {
			const itemFollowing = objDataSegment.following[0];
			result.unshift({
				id: `${SUB_ID.SEGMENT}${itemFollowing.id}`,
				name: itemFollowing?.title,
				isFollowing: true,
			});
		}
		return result;
	}, [objDataSegment, fieldDisabled]);

	const idCheckSegment: ValueSelectIdTypes[] = useMemo(() => {
		const idCheck: ValueSelectIdTypes[] = [];
		actionSegment.forEach((item) => {
			if (typeof item?.isFollowing === 'boolean') {
				idCheck.push(item.id);
			}
		});
		return idCheck;
	}, [actionSegment]);

	const onChangeFilterSegment = (val: ValueSelectIdTypes, group?: any) => {
		if (!idCheckSegment.includes(val) && val) {
			setCampaignSelectValue(null);
			setFieldDisable([SUB_ID.CAMPAIGN]);
		} else {
			setFieldDisable([]);
		}

		setSegmentSelectValue(val);
	};

	const onChangeFilterCampaign = (val: ValueSelectIdTypes, group?: any) => {
		setCampaignSelectValue(val);
		if (val) {
			setFieldDisable([SUB_ID.OTHER]);
			if (!idCheckSegment.includes(segmentSelectValue)) {
				setSegmentSelectValue(null);
			}
		} else {
			setFieldDisable([]);
		}
	};

	const menuActions = (
		<StyledWrapperOverlayDropdown>
			<StyledContainerListOption>
				{actionsObj().map((item: CampaignGroupDropdown) => {
					if (item?.hidden) {
						return null;
					}
					if (item?.isGroup) {
						const list = item?.data || [];
						return (
							<>
								<StyledTitle
									className="unset_height"
									color={THEME.colors.gray1}
									padding="6px 8px"
									fontSize="12px"
									fontWeight="bold">
									{item.name}
								</StyledTitle>
								{list.map((k) => (
									<StyledOptionItem
										padding="6px 8px 6px 24px"
										key={k.id}
										onClick={k.click}>
										<StyledNameOption>{k.name}</StyledNameOption>
									</StyledOptionItem>
								))}
							</>
						);
					}

					return (
						<StyledOptionItem key={item.id} onClick={item.click}>
							<StyledNameOption>{item.name}</StyledNameOption>
						</StyledOptionItem>
					);
				})}
			</StyledContainerListOption>
		</StyledWrapperOverlayDropdown>
	);

	return (
		<StyledWrapperTitle
			style={{ display: 'inline-flex', overflow: 'auto hidden' }}
			height="38px"
			className="overflow custom_scroll_bar"
			width="100%"
			position="relative">
			<StyledDropdown
				className="dropdown_assign_album"
				overlay={menuActions}
				visible={openGeneral}
				onVisibleChange={setOpenGeneral}
				placement="bottomLeft"
				trigger={['click']}>
				<StyledButton>
					{t('dashboard.generate_report')}
					<StyledIcon margin="0 0 0 5px">
						<IconExpandMore />
					</StyledIcon>
				</StyledButton>
			</StyledDropdown>

			<StyledWrapperRightAction>
				<StyledWrapperRefreshMetric>
					<RefreshMetrics
						refreshMetricsEnabled={ambassadorRefreshMetricsEnabled}
						metricsRefreshUpdateAt={ambassadorMetricsRefreshUpdateAt}
						ambassadorMetricsRefreshInProgress={ambassadorMetricsRefreshInProgress}
						onRefresh={handleRefreshMetric}
					/>
				</StyledWrapperRefreshMetric>

				<StyledWrapperContent display="inline-flex" alignItems="center" margin="0 8px 0 0">
					<FilterDropdownSelect
						actions={[
							{
								id: SUB_ID.ACTIVE,
								name: t('ambassador.active_campaigns'),
								children: objDataCampaign.active?.map((item) => ({
									id: `${SUB_ID.CAMPAIGN}${item?.id}`,
									name: item?.name,
									click: () => {},
								})),
							},
							{
								id: SUB_ID.IN_ACTIVE,
								name: t('ambassador.inactive_campaigns'),
								children: objDataCampaign.inactive?.map((item) => ({
									id: `${SUB_ID.CAMPAIGN}${item?.id}`,
									name: item?.name,
									click: () => {},
								})),
							},
						]}
						buttonNode={
							<>
								<StyledIcon margin="0 4px 0 0">
									<IconFilter />
								</StyledIcon>
								<StyledTitle className="unset_height" fontSize="14px">
									{t('ambassador.filter_by_campaign')}
								</StyledTitle>
							</>
						}
						placeholderDefault={t('ambassador.filter_by_campaign')}
						subApplyFilter={t('ambassador.filter_by_campaign_lb') + ' '}
						onChange={onChangeFilterCampaign}
						disabled={fieldDisabled.includes(SUB_ID.CAMPAIGN)}
						selectedId={campaignSelectValue}
						getPopupContainer={undefined}
					/>
				</StyledWrapperContent>
				<FilterDropdownSelect
					actions={actionSegment}
					buttonNode={
						<>
							<StyledIcon margin="0 4px 0 0">
								<IconFilter />
							</StyledIcon>
							<StyledTitle className="unset_height" fontSize="14px">
								{t('ambassador.filter_by_segment')}
							</StyledTitle>
						</>
					}
					placeholderDefault={t('ambassador.filter_by_segment')}
					subApplyFilter={t('ambassador.filter_by_segment_lb') + ' '}
					onChange={onChangeFilterSegment}
					selectedId={segmentSelectValue}
					getPopupContainer={undefined}
				/>
			</StyledWrapperRightAction>
		</StyledWrapperTitle>
	);
};

export default TopAction;
