import { IconCancel } from '@assets/icons';
import { SENDER_EMAIL_ADDRESS } from '@constants/messages';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import LoadingWrapper from '@cores/LoadingWrapper';
import {
	EmailPayloadDto,
	EmailTemplatesDataDto,
	SentTestEmailResultDto,
} from '@models/messages/summary';
import { sendTestMailEnd, sendTestMailRequest } from '@stores/actions';
import {
	StyledFormItem,
	StyledTable,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { renderContentWithPopover } from '@utils/renderComponent';
import { Button, Col, Form, Input, Modal, Row, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
	.ant-modal-body {
		height: auto;
		max-height: 400px;
		overflow: auto;
	}
`;

const StyledForm = styled(Form)`
	.form_email {
		margin-bottom: 0;
		.ant-input {
			&::-webkit-input-placeholder {
				color: ${THEME.colors.darkBlue5};
			}
		}
	}
`;

const StyledButtonSend = styled(Button)`
	&.btn_send_test {
		margin-left: 12px !important;
	}
`;

const StyledListEmail = styled.div`
	display: flex;
	margin-top: 4px;
	flex-wrap: wrap;
`;

const StyledEmail = styled.div<{ margin?: string }>`
	height: 28px;
	flex-grow: 0;
	margin: ${({ margin }) => margin || '0 8px 8px 0'};
	padding: 4px 10.6px 4px 11px;
	border-radius: 20px;
	background-color: ${THEME.colors.darkBlue3};
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	display: flex;
	algin-items: center;
	position: relative;
	width: fit-content;

	svg {
		visibility: hidden;
		cursor: pointer;
		position: absolute;
		top: -8px;
		right: -4px;
		width: 16px;
		transition: 0.2s ease-out;
	}
	&:hover {
		svg {
			visibility: unset;
		}
	}
`;

const StyledError = styled.div`
	color: ${THEME.colors.redBase};
	font-size: 14px;
	line-height: 22px;
	margin-top: 16px;
`;

type SendTestMailProps = {
	visible: boolean;
	handleCancel: () => void;
	template: EmailTemplatesDataDto;
};

type ListEmailCreatorDto = {
	email: string;
};

const SendTestEmailModal = (props: SendTestMailProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const RESULTS = {
		VALID: 'valid',
		INVALID: 'invalid',
	};

	const { sendTestEmailStatus, sentTestEmailResult, error } = useSelector(
		(state: any) => state.messages,
	);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { visible, handleCancel, template } = props;

	const [mailEmpty, setMailEmpty] = useState<boolean>(false);
	const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [listEmailCreator, setListEmailCreator] = useState<ListEmailCreatorDto[]>([]);

	useEffect(() => {
		return () => {
			dispatch(sendTestMailEnd());
		};
	}, [visible]);

	useEffect(() => {
		if (listEmailCreator?.length > 0 && mailEmpty) {
			setMailEmpty(false);
		}
	}, [listEmailCreator]);

	useEffect(() => {
		if (sendTestEmailStatus && sendTestEmailStatus === SUCCEEDED) {
			setIsLoading(false);
		}
		if (sendTestEmailStatus && sendTestEmailStatus === FAILED) {
			if (error) {
				message.error(error);
			}
			setIsLoading(false);
			handleCloseModal();
		}
	}, [sendTestEmailStatus]);

	const resultSendingTestEmail = useMemo(() => {
		const result: {
			succeed: number;
			failedList: SentTestEmailResultDto[];
			isReturned: boolean;
		} = {
			succeed: 0,
			failedList: [],
			isReturned: false,
		};

		if (sentTestEmailResult) {
			result.isReturned = true;
			sentTestEmailResult.forEach((item: SentTestEmailResultDto) => {
				if (item.result === RESULTS.VALID) {
					result.succeed += 1;
				} else {
					result.failedList.push(item);
				}
			});
		}

		return result;
	}, [sentTestEmailResult]);

	const handleSubmit = () => {
		form.validateFields();

		if (listEmailCreator?.length === 0) {
			return setMailEmpty(true);
		}

		const payload: EmailPayloadDto = {
			from: clientSettings.senderAddress
				? clientSettings.senderAddress
				: SENDER_EMAIL_ADDRESS,
			body: template?.body || '',
			creators: listEmailCreator,
			newUpdate: false,
			content: false,
			subject: template?.subject || '',
		};
		if (template?.campaignId) {
			payload.campaignId = template?.campaignId;
		}
		setIsLoading(true);
		message.info(t('email_templates.notification.initiated_test_email'));
		return dispatch(sendTestMailRequest(payload));
	};

	const handleCloseModal = () => {
		form.resetFields();
		handleCancel();
		setListEmailCreator([]);
		setMailEmpty(false);
		setIsDuplicate(false);
	};

	const handlePushEmailCreator = (e: any) => {
		const email = form.getFieldValue('email');
		const errorValidateEmail = form.getFieldError('email') || [];
		if (e.keyCode === 13 && errorValidateEmail?.length === 0 && email) {
			const newListCreator = [...listEmailCreator];
			if (!listEmailCreator?.find((item: any) => item?.email === email)) {
				newListCreator.push({ email });
				setListEmailCreator(newListCreator);
				form.resetFields();
			}
		}
	};

	const handleCheckEmail = () => {
		const email = form.getFieldValue('email');
		if (listEmailCreator.some((item) => item?.email === email)) {
			return setIsDuplicate(true);
		} else {
			if (isDuplicate) {
				setIsDuplicate(false);
			}
		}
		return null;
	};

	const handleRemoveEmail = (emailRemove: string) => () => {
		let newList = [...listEmailCreator];
		const email = form.getFieldValue('email');
		if (email === emailRemove && isDuplicate) {
			setIsDuplicate(false);
		}
		newList = newList?.filter((item) => item?.email !== emailRemove);
		setListEmailCreator(newList);
	};

	const renderResultSendingTestEmail = () => {
		const columns = [
			{
				title: t('email_templates.modal.email'),
				dataIndex: 'email',
				ellipsis: true,
				render: renderContentWithPopover,
			},
			{
				title: t('email_templates.modal.reason'),
				dataIndex: 'reason',
				ellipsis: true,
				render: renderContentWithPopover,
			},
		];

		const hasFailedValue = !!resultSendingTestEmail.failedList.length;

		return (
			<>
				<StyledText>
					{t('email_templates.notification.sent_email', {
						successfulEmail: resultSendingTestEmail.succeed,
					})}
					{hasFailedValue && t('email_templates.notification.failed_email')}
				</StyledText>
				{hasFailedValue && (
					<StyledWrapperContent margin="12px 0">
						<StyledTable
							dataSource={resultSendingTestEmail.failedList}
							pagination={false}
							scroll={{ y: '200px' }}
							columns={columns}
						/>
					</StyledWrapperContent>
				)}
			</>
		);
	};

	return (
		<StyledModal
			title={t('email_templates.modal.send_test_email')}
			visible={visible}
			width={470}
			centered
			onCancel={handleCloseModal}
			footer={
				resultSendingTestEmail.isReturned
					? [
							<Button key="back" onClick={handleCloseModal}>
								{t('button.close')}
							</Button>,
					  ]
					: [
							<Button key="back" onClick={handleCloseModal}>
								{t('button.cancel')}
							</Button>,
							<StyledButtonSend
								onClick={handleSubmit}
								key="submit"
								type="primary"
								className="btn_send_test"
								loading={isLoading}>
								{t('button.send_test')}
							</StyledButtonSend>,
					  ]
			}
			maskClosable={sendTestEmailStatus !== IN_PROGRESS}>
			<LoadingWrapper isLoading={isLoading}>
				{!resultSendingTestEmail.isReturned ? (
					<>
						<StyledForm
							form={form}
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							requiredMark={false}>
							<Row gutter={24}>
								<Col span={24}>
									<StyledFormItem
										height="56px"
										label={t('email_templates.modal.recipients')}
										name="email"
										className="form_email"
										rules={[
											{
												type: 'email',
												message: t('email_templates.modal.invalid_email'),
											},
										]}>
										<Input
											onKeyDown={handlePushEmailCreator}
											onChange={handleCheckEmail}
											placeholder={t(
												'email_templates.modal.placeholder_mail',
											)}
										/>
									</StyledFormItem>
								</Col>
							</Row>
						</StyledForm>
						{isDuplicate && (
							<StyledError>
								{t('email_templates.modal.message_duplicate')}
							</StyledError>
						)}
						{mailEmpty && (
							<StyledError>
								{t('email_templates.modal.required_enter_email')}
							</StyledError>
						)}
						<StyledListEmail>
							{listEmailCreator?.map((item: any) => (
								<StyledEmail key={item?.email}>
									{item?.email}
									<IconCancel onClick={handleRemoveEmail(item?.email)} />
								</StyledEmail>
							))}
						</StyledListEmail>
					</>
				) : (
					<>{renderResultSendingTestEmail()}</>
				)}
			</LoadingWrapper>
		</StyledModal>
	);
};

export default React.memo(SendTestEmailModal);
