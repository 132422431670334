import { ANALYZED_CONTENT_OPTIONS } from '@constants/content/contentLibrary';
import { SUCCEEDED } from '@constants/status';
import MediaRecognitionModalContext from '@contexts/Content/MediaRecognitionModal';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import { DetectionServiceDto } from '@models/content/contentLibrary/summary';
import { StyledDivider, StyledSection, StyledText } from '@styled/Common/CommonStyled';
import { StyledAnalyzeContent } from '@styled/Content/ContentLibrary/MediaRecognitionModalStyled';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Summary = () => {
	const { t } = useTranslation();
	const { submitDetectionOrderStatus }: ContentStoreDto = useSelector(
		(state: any) => state.content,
	);
	const { estimatedCost, selectedServiceList, detectionServiceDetail } = useContext(
		MediaRecognitionModalContext,
	);
	const { numberOfImages, numberOfVideos } = detectionServiceDetail;

	const checkHasInfo = useMemo(() => {
		const result = {
			video: false,
			image: false,
		};
		selectedServiceList.forEach((service) => {
			if (service.type === ANALYZED_CONTENT_OPTIONS.LOGO_DETECTION) {
				result.image = true;
			} else {
				result.video = true;
			}
		});
		return result;
	}, [detectionServiceDetail]);

	return (
		<StyledAnalyzeContent>
			<StyledSection padding="0" margin="24px 0">
				<StyledText fontSize="20px !important">
					{t('content_management.media_recognition.order_summary')}
				</StyledText>
				<br />
				{checkHasInfo.image && (
					<StyledText
						padding="4px 0"
						dangerouslySetInnerHTML={{
							__html: t('content_management.media_recognition.images_processed', {
								numberOfImages,
							}),
						}}
					/>
				)}
				{checkHasInfo.video && (
					<StyledText
						padding="4px 0"
						dangerouslySetInnerHTML={{
							__html: t('content_management.media_recognition.videos_processed', {
								numberOfVideos,
							}),
						}}
					/>
				)}
				<br />
				<StyledText>{t('content_management.media_recognition.services_to_use')}</StyledText>
				{selectedServiceList.map((service: DetectionServiceDto) => {
					return <StyledText>{service.name}</StyledText>;
				})}
				<br />
				<StyledText
					padding="4px 0"
					dangerouslySetInnerHTML={{
						__html: t('content_management.media_recognition.estimated_total_cost', {
							cost: estimatedCost,
						}),
					}}
				/>
			</StyledSection>
			<StyledDivider />
			{submitDetectionOrderStatus !== SUCCEEDED && (
				<StyledText>
					{t('content_management.media_recognition.submit_order_confirmation')}
				</StyledText>
			)}
		</StyledAnalyzeContent>
	);
};

export default Summary;
