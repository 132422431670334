/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Form, message } from 'antd';
import {
	StyledFooterModal,
	StyledBtn,
	StyledTemplateHeader,
	StyledTitleModal,
	StyledIconClose,
	StyledSubTitleModal,
	StyledTemplateInput,
	StyledTemplateTextArea,
	StyledTemplateDetails,
	StyledTemplateBody,
	StyledDynamicField,
	StyledNotificationBox,
	StyleFlex,
	StyledRowTemplateForm,
	StyledDynamicBtn,
} from '@styled/Messages/EmailTemplatesStyled';
import THEME from '@constants/themes/themes';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import { IconClose, IconInfo } from '@assets/icons';
import { ConfirmModal } from '@cores/index';
import {
	enableTemplateAction,
	getEmailTemplatesRequest,
	restoreEmailTemplatesRequest,
	updateEmailTemplatesRequest,
} from '@stores/actions';
import { STATUSCODE } from '@constants/APIs';
import { EMAIL_TEMPLATE_FOLDER_TYPES } from '@constants/messages';

interface IEmailTemplateRightPanel {
	template: EmailTemplatesDataDto;
	handleCancel: () => void;
	insertDynamicField: (field: string) => void;
}

const EmailTemplateRightPanel: React.FC<IEmailTemplateRightPanel> = ({
	template,
	handleCancel,
	insertDynamicField,
}) => {
	const dispatch = useDispatch();
	const {
		emailTemplate: {
			folder = '',
			restoreTemplateStatus = 0,
			getEmailTemplateStatus = false,
			restoreLoading = false,
			error = null,
			automated: { editTemplateSuccessfully = 0 } = {},
		} = {},
		dynamicFields = [],
	} = useSelector((state: any) => state.messages);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openModal2, setOpenModal2] = useState<boolean>(false);
	const { t } = useTranslation();

	const { enabled, id } = template;

	useEffect(() => {
		if (editTemplateSuccessfully === STATUSCODE.SUCCESS) {
			setOpenModal(false);
		}
	}, [editTemplateSuccessfully]);

	useEffect(() => {
		const getType = () => {
			if (folder === '1') {
				return EMAIL_TEMPLATE_FOLDER_TYPES.DEFAULT;
			} else if (folder === '2') {
				return EMAIL_TEMPLATE_FOLDER_TYPES.CUSTOM;
			}
			return EMAIL_TEMPLATE_FOLDER_TYPES.ARCHIVED;
		};

		const type = getType();
		if (restoreTemplateStatus === STATUSCODE.SUCCESS) {
			message.success(t('email_templates.notification.restore_default_successfully'));
			dispatch(getEmailTemplatesRequest({ type }));
			setOpenModal2(false);
		} else if (restoreTemplateStatus === STATUSCODE.FAILED400) {
			message.error(
				t('email_templates.notification.restore_default_failed', {
					restoreTemplateStatus,
					error,
				}),
			);
			setOpenModal2(false);
		}
	}, [restoreTemplateStatus]);

	const chooseDynamicField = (value: string) => {
		navigator.clipboard.writeText(value);
		insertDynamicField(value);
	};

	const renderInsertButtons = () => {
		return (
			<StyledDynamicField>
				{dynamicFields.map((item: any) => {
					return (
						<StyledDynamicBtn
							onClick={() => chooseDynamicField(item.field)}
							key={item.field}>
							{item.field}
						</StyledDynamicBtn>
					);
				})}
			</StyledDynamicField>
		);
	};

	const onTurnOnOffAutomation = () => {
		const payloadSwitch = {
			...template,
			enabled: !template.enabled,
		};

		dispatch(updateEmailTemplatesRequest(payloadSwitch));
		dispatch(enableTemplateAction(true));
	};

	const confirmRestoreDefault = () => {
		setOpenModal2(true);
	};

	const onRestoreDefault = () => {
		if (id) dispatch(restoreEmailTemplatesRequest({ id, isDefault: true }));
	};

	const renderNotificationBox = () => (
		<StyledNotificationBox>
			<div className="notificationBox">
				<StyleFlex>
					<IconInfo />
					<div style={{ width: '80%' }}>
						<div className="notificationBox__title">
							{t('email_templates.edit_templates_modal.notificationBox__title', {
								enabled: enabled
									? t('email_templates.edit_templates_modal.notificationBox__ON')
									: t(
											'email_templates.edit_templates_modal.notificationBox__OFF',
									  ),
							})}
						</div>
						{enabled ? (
							<>
								<div className="notificationBox__content">
									{t(
										'email_templates.edit_templates_modal.notificationBox__content',
									)}
								</div>
							</>
						) : (
							<div className="notificationBox__content">
								{t(
									'email_templates.edit_templates_modal.notificationBox__content2',
								)}
							</div>
						)}
						<div className="notificationBox__action" onClick={() => setOpenModal(true)}>
							{t('email_templates.edit_templates_modal.notificationBox__action', {
								enabled: enabled
									? t('email_templates.edit_templates_modal.notificationBox__OFF')
									: t('email_templates.edit_templates_modal.notificationBox__ON'),
							})}
						</div>
					</div>
				</StyleFlex>
			</div>
		</StyledNotificationBox>
	);

	return (
		<StyledTemplateDetails>
			<StyledTemplateHeader>
				<StyledTitleModal>
					{t('email_templates.edit_templates_modal.template_detail')}
				</StyledTitleModal>
				<StyledIconClose>
					<IconClose onClick={handleCancel} />
				</StyledIconClose>
			</StyledTemplateHeader>
			<StyledTemplateBody>
				<StyledRowTemplateForm>
					<Col span={24}>
						<Form.Item
							label={t('email_templates.edit_templates_modal.title_name')}
							name="title">
							<StyledTemplateInput />
						</Form.Item>
					</Col>
				</StyledRowTemplateForm>
				<StyledRowTemplateForm>
					<Col span={24}>
						<Form.Item
							label={t('email_templates.edit_templates_modal.title_description')}
							name="description">
							<StyledTemplateTextArea autoSize={{ minRows: 1, maxRows: 6 }} />
						</Form.Item>
					</Col>
				</StyledRowTemplateForm>

				<StyledTitleModal fontWeight="bold" paddingTop="16px">
					{t('email_templates.edit_templates_modal.insert_dynamic_fields')}
				</StyledTitleModal>
				<StyledSubTitleModal fontWeight="normal">
					{t('email_templates.edit_templates_modal.insert_dynamic_fields_sub_title')}
				</StyledSubTitleModal>
			</StyledTemplateBody>

			{renderInsertButtons()}
			<StyledTemplateBody>{folder === '1' && renderNotificationBox()}</StyledTemplateBody>

			<StyledFooterModal>
				<StyledBtn padding="6px 36px" onClick={handleCancel}>
					{t('button.cancel')}
				</StyledBtn>
				{folder === '1' && (
					<StyledBtn onClick={confirmRestoreDefault}>
						{t('email_templates.edit_templates_modal.restore_default_btn')}
					</StyledBtn>
				)}
				<StyledBtn
					padding="6px 36px"
					marginRight="0"
					bgColor={THEME.colors.orangeBase}
					htmlType="submit">
					{t('button.save')}
				</StyledBtn>
			</StyledFooterModal>

			<ConfirmModal
				width={400}
				confirmLoading={getEmailTemplateStatus}
				okText={t('button.yes')}
				cancelText={t('button.no')}
				isOpen={openModal}
				onSubmit={onTurnOnOffAutomation}
				onClose={() => setOpenModal(false)}>
				{t('email_templates.confirm.enabled_automation', {
					enabled: enabled
						? t('email_templates.confirm.disable')
						: t('email_templates.confirm.enable'),
				})}
			</ConfirmModal>
			<ConfirmModal
				width={400}
				confirmLoading={getEmailTemplateStatus || restoreLoading}
				okText={t('button.yes')}
				cancelText={t('button.no')}
				isOpen={openModal2}
				onSubmit={onRestoreDefault}
				onClose={() => setOpenModal2(false)}>
				{t('email_templates.confirm.restore_default_template')}
			</ConfirmModal>
		</StyledTemplateDetails>
	);
};

export default EmailTemplateRightPanel;
