import { IconEdit } from '@assets/icons';
import { TIME_OUT } from '@constants/common';
import { TYPE_CUSTOM_FIELD } from '@constants/content/widgetManager';
import { LANDING_PAGE_SELECTORS } from '@constants/landingPageManager';
import THEME from '@constants/themes/themes';
import { RefEditableElement } from '@models/common/ref';
import {
	StyledFormItem,
	StyledIcon,
	StyledInput,
	StyledText,
	StyledTextArea,
} from '@styled/Common/CommonStyled';
import TextArea from 'antd/lib/input/TextArea';
import { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

type EditableElementProps = {
	form?: any;
	info: any;
	placeholder?: string;
	inputWidth?: number;
	inputHeight?: number;
	onClick?: () => void;
	textColor?: string;
	onChangeValue?: (value: any) => void;
	fieldName?: string;
	rules?: any;
	editElement?: any;
	inputType?: string;
	isHiddenIcon?: boolean;
	otherIconProps?: any;
	[key: string]: any;
};

const StyledEditableText = styled(StyledText)<{ width?: string }>`
	position: relative;
	${({ width }) => width && `width: ${width}`};
	max-width: 100%;
	cursor: pointer;
	word-break: break-word;
	white-space: pre-line;

	&.line-clamp {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	${StyledIcon}:not(.star) {
		opacity: 0;
		transition: all 0.4s;
	}

	&.${LANDING_PAGE_SELECTORS.LANDING_PAGE_NAME} {
		display: inline-flex;
		width: 100%;
	}

	&:hover {
		${StyledIcon} {
			opacity: 1;
		}
	}
`;

const EditableElement = forwardRef((props: EditableElementProps, ref: Ref<RefEditableElement>) => {
	const {
		form,
		info,
		className = '',
		placeholder = '',
		inputWidth,
		inputHeight,
		onClick,
		textColor = THEME.colors.gray5,
		onChangeValue,
		fieldName,
		rules = [],
		editElement,
		inputType,
		isHiddenIcon,
		otherFormItemProps,
		otherIconProps,
		otherTextAreaProps,
		backgroundInput,
		...others
	} = props;
	const refTime = useRef<any>(null);
	const [isEdit, setIsEdit] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		onCancelEditing,
	}));

	useEffect(() => {
		if (others.effectFunc && typeof others.effectFunc === 'function') {
			others.effectFunc(isEdit);
		}
	}, [isEdit]);

	const onCancelEditing = () => {
		setIsEdit(false);
	};

	const renderCustomElement = () => {
		return (
			editElement && (
				<div
					onBlur={(event) => {
						if (!event.currentTarget.contains(event.relatedTarget)) {
							form.validateFields()
								.then(() => {
									setIsEdit(false);
								})
								.catch(() => {});
						}
					}}>
					{editElement}
				</div>
			)
		);
	};

	const handleOpenEditing = () => {
		if (form) {
			form.validateFields()
				.then(() => {
					setIsEdit(true);
				})
				.catch(() => {});
		} else {
			setIsEdit(true);
		}
	};

	const onBlur = (e: React.ChangeEvent<any>) => {
		const changedValue = e?.target?.value?.trim() || '';
		const hasError = form.getFieldError(fieldName).length;
		if (hasError) {
			return;
		}
		if (onChangeValue && typeof onChangeValue === 'function' && fieldName) {
			onChangeValue({ [fieldName]: changedValue });
		}
		setIsEdit(false);
	};

	const renderInputType = () => {
		const elementProps = {
			autoFocus: true,
			width: inputWidth,
			height: inputHeight,
			onBlur,
			placeholder,
			bgColor: backgroundInput,
		};

		switch (inputType) {
			case TYPE_CUSTOM_FIELD.paragraph: {
				return (
					<StyledTextArea
						{...elementProps}
						autoSize={{ minRows: 3, maxRows: 3 }}
						rows={3}
						{...otherTextAreaProps}
					/>
				);
			}

			default: {
				return <StyledInput {...elementProps} />;
			}
		}
	};

	const renderElement = () => {
		if (isEdit) {
			if (editElement) {
				return renderCustomElement();
			}

			return (
				<StyledFormItem
					rules={rules}
					style={{ marginBottom: '0', width: '100%' }}
					name={fieldName}
					{...otherFormItemProps}>
					{renderInputType()}
				</StyledFormItem>
			);
		}

		return (
			<StyledEditableText
				className={className}
				width={info ? 'fit-content' : '100%'}
				onClick={(e: React.MouseEvent<HTMLDivElement>) => {
					if (refTime.current) {
						clearTimeout(refTime.current);
					}
					e.preventDefault();
					e.stopPropagation();
					refTime.current = setTimeout(() => {
						if (e.detail === 1) {
							if (onClick && typeof onClick === 'function') {
								onClick();
							}
						}
					}, 250);

					if (e.detail === 2) {
						handleOpenEditing();
					}
				}}
				color={textColor}
				title={typeof info === 'string' ? info : ''}>
				{info}
				{!isHiddenIcon && (
					<StyledIcon
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							e.preventDefault();
							e.stopPropagation();
							handleOpenEditing();
						}}
						size={18}
						margin={info ? '0 0 0 8px' : 'unset'}
						{...otherIconProps}>
						<IconEdit />
					</StyledIcon>
				)}
			</StyledEditableText>
		);
	};

	return renderElement();
});

export default EditableElement;
