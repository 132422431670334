import { IconSend } from '@assets/icons';
import { TYPE_FORMAT_DATE } from '@constants/common';
import THEME from '@constants/themes/themes';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import {
	StyledActionPreview,
	StyledLeftTitle,
	StyledPreviewPanelHeader,
	StyledPreviewTitle,
	StyledTimeSub,
} from '@styled/Messages/EmailTemplatesStyled';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SendTestEmailModal from '../SendTestEmailModal';

interface IPreviewPanel {
	children: any;
	template: EmailTemplatesDataDto;
}

const StyledWrapper = styled.div`
	background-color: ${THEME.colors.white};
	color: black;
	height: calc(100% - 60px);
	overflow-x: scroll;
	padding: 1rem;
`;

const PreviewPanel: React.FC<IPreviewPanel> = ({ children, template }) => {
	const { t } = useTranslation();

	const [visibleModalSendMail, setVisibleModalSendMail] = useState<boolean>(false);

	const { title, updateAt = 0 } = template;

	return (
		<>
			<StyledPreviewPanelHeader>
				<StyledLeftTitle>
					<StyledPreviewTitle>{title}</StyledPreviewTitle>
					<StyledTimeSub>{`${t(
						'email_templates.last_update',
					)} ${convertUtcToLocalTimeWithFormat(
						updateAt,
						TYPE_FORMAT_DATE.TIME,
					)}`}</StyledTimeSub>
				</StyledLeftTitle>
				<StyledActionPreview>
					<Button onClick={() => setVisibleModalSendMail(true)} icon={<IconSend />}>
						{t('button.send_test')}
					</Button>
				</StyledActionPreview>
			</StyledPreviewPanelHeader>
			<StyledWrapper>{children}</StyledWrapper>
			<SendTestEmailModal
				handleCancel={() => setVisibleModalSendMail(false)}
				visible={visibleModalSendMail}
				template={template}
			/>
		</>
	);
};

export default PreviewPanel;
