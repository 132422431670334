import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Modal, Skeleton, Empty, Table, Pagination, Radio, message } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { handleDownloadExcel } from '@helpers/base64Helper';

// Models
import { SegmentsDto } from '@models/creator/segments';
import { ImportResultsProps, ImportItemProps } from '@models/creator/summary';

// API
import { postRequest } from '@helpers/requestHelpers';

// Constants
import { CREATOR_API_URL } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import THEME from '@constants/themes/themes';

const StyledModalContainer = styled.div`
	display: grid;
	grid-template-columns: 50% 50%;
	// column-gap: 20px;
`;

const StyledItemWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding: 0 15%;
`;

const StyledImportCount = styled.div`
	height: 55px;
	padding: 16px;
	border-radius: 5px;
	border: solid 1px ${THEME.colors.darkBlue3};
	background-color: ${THEME.colors.darkBlue3};
`;

const StyledMessage = styled.div`
	padding-top: 24px;
	font-size: 0.857rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
`;

const ImportItem = ({ title, count }: ImportItemProps) => {
	return (
		<StyledItemWrapper>
			<p>{title}</p>
			<StyledImportCount>{count}</StyledImportCount>
		</StyledItemWrapper>
	);
};

const ImportResultsModal = ({ visible, onClose }: ImportResultsProps) => {
	const { t } = useTranslation();
	const { fetchingImport, importData } = useSelector((state: any) => state.creator);

	const { base64, totalSuccess, totalFail } = importData || {};

	const handleDownload = () => {
		handleDownloadExcel(base64, 'EnTribe_Creator_Error_List');
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<Modal
			title={t('creator_summary.modal.import_results')}
			visible={visible}
			width={500}
			centered
			// onOk={handleAddToSegment}
			onCancel={handleCancel}
			footer={[
				<Button
					onClick={handleDownload}
					key="submit"
					type="primary"
					disabled={fetchingImport || totalFail === 0}>
					{t('button.download')}
				</Button>,
			]}>
			{fetchingImport ? (
				<Skeleton />
			) : (
				<>
					<StyledModalContainer>
						<ImportItem
							title={t('creator_summary.modal.successfully_imported')}
							count={totalSuccess}
						/>
						<ImportItem
							title={t('creator_summary.modal.failed_to_import')}
							count={totalFail}
						/>
					</StyledModalContainer>
					<StyledMessage>{t('creator_summary.modal.message')}</StyledMessage>
				</>
			)}
		</Modal>
	);
};

export default React.memo(ImportResultsModal);
