import { createAction } from 'redux-actions';
import {
	clonePublishedLandingPageTypes,
	createLandingPageTypes,
	deleteLandingPageTypes,
	getLandingPageDetailTypes,
	getLandingPageListLiteTypes,
	getLandingPageListTypes,
	publishLandingPageTypes,
	resumeLandingPageDraftTypes,
	storeLandingPageDataTypes,
	unPublishLandingPageTypes,
	updateLandingPageTypes,
} from './landingPageManager.types';

export const getLandingPageListRequest = createAction(
	getLandingPageListTypes.GET_LANDING_PAGE_LIST_REQUEST,
);
export const getLandingPageListSucceeded = createAction(
	getLandingPageListTypes.GET_LANDING_PAGE_LIST_SUCCEEDED,
);
export const getLandingPageListFailed = createAction(
	getLandingPageListTypes.GET_LANDING_PAGE_LIST_FAILED,
);
export const getLandingPageListEnded = createAction(
	getLandingPageListTypes.GET_LANDING_PAGE_LIST_ENDED,
);

export const getLandingPageListLiteRequest = createAction(
	getLandingPageListLiteTypes.GET_LANDING_PAGE_LIST_LITE_REQUEST,
);
export const getLandingPageListLiteSucceeded = createAction(
	getLandingPageListLiteTypes.GET_LANDING_PAGE_LIST_LITE_SUCCEEDED,
);
export const getLandingPageListLiteFailed = createAction(
	getLandingPageListLiteTypes.GET_LANDING_PAGE_LIST_LITE_FAILED,
);
export const getLandingPageListLiteEnded = createAction(
	getLandingPageListLiteTypes.GET_LANDING_PAGE_LIST_LITE_ENDED,
);

export const getLandingPageDetailRequest = createAction(
	getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_REQUEST,
);
export const getLandingPageDetailSucceeded = createAction(
	getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_SUCCEEDED,
);
export const getLandingPageDetailFailed = createAction(
	getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_FAILED,
);
export const getLandingPageDetailEnded = createAction(
	getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_ENDED,
);

export const updateLandingPageRequest = createAction(
	updateLandingPageTypes.UPDATE_LANDING_PAGE_REQUEST,
);
export const updateLandingPageSucceeded = createAction(
	updateLandingPageTypes.UPDATE_LANDING_PAGE_SUCCEEDED,
);
export const updateLandingPageFailed = createAction(
	updateLandingPageTypes.UPDATE_LANDING_PAGE_FAILED,
);
export const updateLandingPageEnded = createAction(
	updateLandingPageTypes.UPDATE_LANDING_PAGE_ENDED,
);

export const publishLandingPageRequest = createAction(
	publishLandingPageTypes.PUBLISH_LANDING_PAGE_REQUEST,
);
export const publishLandingPageSucceeded = createAction(
	publishLandingPageTypes.PUBLISH_LANDING_PAGE_SUCCEEDED,
);
export const publishLandingPageFailed = createAction(
	publishLandingPageTypes.PUBLISH_LANDING_PAGE_FAILED,
);
export const publishLandingPageEnded = createAction(
	publishLandingPageTypes.PUBLISH_LANDING_PAGE_ENDED,
);

export const createLandingPageRequest = createAction(
	createLandingPageTypes.CREATE_LANDING_PAGE_REQUEST,
);
export const createLandingPageSucceeded = createAction(
	createLandingPageTypes.CREATE_LANDING_PAGE_SUCCEEDED,
);
export const createLandingPageFailed = createAction(
	createLandingPageTypes.CREATE_LANDING_PAGE_FAILED,
);
export const createLandingPageEnd = createAction(createLandingPageTypes.CREATE_LANDING_PAGE_ENDED);

export const resumeLandingPageDraftRequest = createAction(
	resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_REQUEST,
);
export const resumeLandingPageDraftSucceeded = createAction(
	resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_SUCCEEDED,
);
export const resumeLandingPageDraftFailed = createAction(
	resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_FAILED,
);
export const resumeLandingPageDraftEnded = createAction(
	resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_ENDED,
);

export const clonePublishedLandingPageRequest = createAction(
	clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_REQUEST,
);
export const clonePublishedLandingPageSucceeded = createAction(
	clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_SUCCEEDED,
);
export const clonePublishedLandingPageFailed = createAction(
	clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_FAILED,
);
export const clonePublishedLandingPageEnded = createAction(
	clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_ENDED,
);

export const deleteLandingPageRequest = createAction(
	deleteLandingPageTypes.DELETE_LANDING_PAGE_REQUEST,
);
export const deleteLandingPageSucceeded = createAction(
	deleteLandingPageTypes.DELETE_LANDING_PAGE_SUCCEEDED,
);
export const deleteLandingPageFailed = createAction(
	deleteLandingPageTypes.DELETE_LANDING_PAGE_FAILED,
);
export const deleteLandingPageEnded = createAction(
	deleteLandingPageTypes.DELETE_LANDING_PAGE_ENDED,
);

export const unPublishLandingPageRequest = createAction(
	unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_REQUEST,
);
export const unPublishLandingPageSucceeded = createAction(
	unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_SUCCEEDED,
);
export const unPublishLandingPageFailed = createAction(
	unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_FAILED,
);
export const unPublishLandingPageEnded = createAction(
	unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_ENDED,
);

export const storeLandingPageDataRequest = createAction(
	storeLandingPageDataTypes.STORE_LANDING_PAGE_DATA_REQUEST,
);
