import {
	encodeToBase64,
	extractCLandGID,
	generateRandomElementIdentification,
} from '@utils/funcHelper';

export default (editor: any, config: any) => {
	const tm = editor.TraitManager;
	// Select trait that maps a class list to the select options.
	// The default select option is set if the input has a class, and class list is modified when select value changes.
	tm.addType('class_select', {
		events: {
			change: 'onChange', // trigger parent onChange method on input change
		},
		createInput({ trait }: any) {
			const md = this.model;
			const opts = md.get('options') || [];
			const input = document.createElement('select');
			const targetViewEl = this.target.view.el;

			for (let i = 0; i < opts.length; i += 1) {
				const option = document.createElement('option');
				let value = opts[i].value;
				if (value === '') {
					value = 'GJS_NO_CLASS';
				} // 'GJS_NO_CLASS' represents no class--empty string does not trigger value change
				option.text = opts[i].name;
				option.value = value;

				// Convert the Token List to an Array
				const css = Array.from(targetViewEl.classList);

				const valueA = value.split(' ');
				const intersection = css.filter((x) => valueA.includes(x));

				if (intersection.length === valueA.length) {
					option.setAttribute('selected', 'selected');
				}

				input.append(option);
			}
			return input;
		},
		onUpdate({ elInput, component }: any) {
			const classes = component.getClasses();
			const opts = this.model.get('options') || [];
			for (let i = 0; i < opts.length; i += 1) {
				const value = opts[i].value;
				if (value && classes.includes(value)) {
					elInput.value = value;
					return;
				}
			}
			elInput.value = 'GJS_NO_CLASS';
		},
		onEvent({ elInput, component, event }: any) {
			const classes = this.model.get('options').map((opt: any) => opt.value);
			for (let i = 0; i < classes.length; i += 1) {
				if (classes[i].length > 0) {
					const classesIA = classes[i].split(' ');
					for (let j = 0; j < classesIA.length; j += 1) {
						if (classesIA[j].length > 0) {
							component.removeClass(classesIA[j]);
						}
					}
				}
			}
			const value = this.model.get('value');

			// This piece of code removes the empty attribute name from attributes list
			const elAttributes = component.attributes.attributes;
			delete elAttributes[''];

			if (value.length > 0 && value !== 'GJS_NO_CLASS') {
				const valueA = value.split(' ');
				for (let i = 0; i < valueA.length; i += 1) {
					component.addClass(valueA[i]);
				}
			}
			component.em.trigger('component:toggled');
		},
	});

	const textTrait = tm.getType('text');

	tm.addType('content', {
		events: {
			keyup: 'onChange',
		},
		onValueChange: function () {
			const md = this.model;
			const target = md.target;
			target.set('content', md.get('value'));
		},
		getInputEl: function () {
			if (!this.inputEl) {
				this.inputEl = textTrait.prototype.getInputEl.bind(this)();
				this.inputEl.value = this.target.get('content');
			}
			return this.inputEl;
		},
	});

	tm.addType('uploader-link', {
		onEvent({ elInput, component }: any) {
			const uploaderLink = elInput.value;
			if (uploaderLink) {
				const id = generateRandomElementIdentification(6);
				const styles = component.getStyle();
				component.setAttributes({
					id: `widget_id_${id}`,
					'data-link-uploader': uploaderLink,
					onclick: `widgetUploader.popup('widget_id_${id}')`,
					'data-close-outside': 1,
				});
				component.setStyle(styles);
			}
		},
	});

	tm.addType('tr-gallery-tag', {
		onEvent({ elInput, component }: any) {
			const galleryLink = elInput.value;
			const { cl = 0, gid = 0 } = extractCLandGID(galleryLink) || {};
			const attributes: any = {};
			const styles = component.getStyle();

			if (cl) {
				attributes['data-client-id'] = encodeToBase64(cl);
			}
			if (gid) {
				attributes['data-gallery-id'] = gid;
			}
			component.setAttributes({
				...attributes,
			});
			component.setStyle(styles);

			// Refresh project data to re-render project
			const projectData = editor.getProjectData();
			editor.loadProjectData(projectData);
		},
	});
};
