import {
	DEFAULT_WIDTH_VIEW,
	HEIGHT_THUMBNAIL_ALBUM,
	LIST_TYPE_ALBUM_SUGGEST,
	TYPE_ALBUM,
} from '@constants/content/albums';
import { AlbumDto } from '@models/content/albums/albumManager';
import { ContentDto } from '@models/content/contentLibrary/summary';
import {
	StyleWrapperAlbumItem,
	StyledButton,
	StyledContainerAlbumItem,
	StyledContainerDetail,
	StyledCount,
	StyledDescription,
	StyledImgTheme,
	StyledLoadingSingle,
	StyledMarkSelectedAlbum,
	StyledMarkSuggestHover,
	StyledMarkTheme,
	StyledName,
	StyledSubHover,
	StyledThemeItem,
	StyledWrapperAction,
	StyledWrapperInfo,
	StyledWrapperListTheme,
	StyledWrapperName,
	StyledWrapperTheme,
	StyledWrapperView,
	StyledWrapperViewSuggest,
} from '@styled/Content/AlbumManager/AlbumItemStyled';
import {
	StyledCol,
	StyledFlexAlignCenter,
	StyledRow,
	StyledSubTooltip,
	StyledTitleTooltip,
	StyledWrapperTooltip,
} from '@styled/Content/AlbumManager/AlbumManagerStyled';
import { Spin } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSuggestAlbum } from '@assets/icons';
import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { ROUTE_PATH, TIME_OUT, UNIT_VALUE } from '@constants/common';
import { StyledIcon, StyledTooltip } from '@styled/Common/CommonStyled';
import { checkExistExplicit } from '@utils/content';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '@utils/customHooks';

type PropAlbumItem = {
	album: AlbumDto;
	isSelected: boolean;
	onSelectAlbum: () => void;
	handleMaybeLaterAlbum: () => void;
	handleNoThank: () => void;
	handleAddToAlbum: () => void;
	[other: string]: any;
};
const AlbumItem = (props: PropAlbumItem) => {
	const history = useHistory();

	const refView = useRef<any>({});
	const imgRef = useRef<any>({});
	const refTime = useRef<any>({});

	const {
		album,
		isSelected = false,
		onSelectAlbum,
		handleMaybeLaterAlbum,
		handleNoThank,
		handleAddToAlbum,
		...other
	} = props;
	const { metadata, loading, contents, totalContent, totalVideoContent } = album;
	const { description = '' } = metadata;
	const { t } = useTranslation();

	const isSuggestAlbum = LIST_TYPE_ALBUM_SUGGEST.includes(album?.type);

	const [countClick, setCountClick] = useState<number>(0);
	const [widthBtnView, setWidthBtnView] = useState<number>(DEFAULT_WIDTH_VIEW);

	const [widthWindow, heightWindow] = useWindowSize();

	const isSmallScreen = widthWindow && widthWindow <= UNIT_VALUE.S_1550;

	const contentViews = useMemo(() => {
		if (contents.length > UNIT_VALUE.S_3 && isSmallScreen) {
			return contents.slice(0, UNIT_VALUE.S_3);
		}
		return contents;
	}, [contents, widthWindow]);

	const renderTotalContent = () => {
		let textPhoto = 'albums.photo';
		let textVideo = 'albums.video';
		const totalPhoto = totalContent - totalVideoContent;
		if (totalVideoContent > 1 || totalVideoContent === 0) {
			textVideo = 'albums.videos';
		}
		if (totalPhoto > 1 || totalPhoto === 0) {
			textPhoto = 'albums.photos';
		}

		return `${totalPhoto} ${t(textPhoto)} / ${totalVideoContent} ${t(textVideo)}`;
	};

	const loadingContent = (
		<StyledLoadingSingle style={{ marginRight: '6px' }} width="100%" isLoading={loading}>
			<Spin size={'small'} />
		</StyledLoadingSingle>
	);

	const loadingText = (
		<StyledWrapperAction>
			{loadingContent} {t('albums.loading')}
		</StyledWrapperAction>
	);

	const renderContent = () => {
		if (contentViews?.length === 0) {
			return t('albums.message.this_album_empty');
		}
		return contentViews?.slice(0, contentViews?.length - 1)?.map((item: ContentDto) => (
			<StyledWrapperTheme
				key={item?.id}
				className={`item_theme ${isSmallScreen ? 'small_screen' : ''}`}>
				<StyledThemeItem>
					<StyledImgTheme
						style={checkExistExplicit(item?.review?.flags) ? { display: 'none' } : {}}
						src={item?.thumbnail}
						alt="theme_item"
					/>
					{checkExistExplicit(item?.review?.flags) && (
						<ExplicitContent zIndex={777} sizeIcon={30} fontSize="10px" />
					)}
				</StyledThemeItem>
			</StyledWrapperTheme>
		));
	};

	const renderTooltipMoreInfo = () => {
		return (
			<StyledWrapperTooltip>
				<StyledTitleTooltip>
					<StyledIcon>
						<IconSuggestAlbum />
					</StyledIcon>
					{t('albums.suggested_albums')}
				</StyledTitleTooltip>
				<StyledSubTooltip>
					{t('albums.tooltip.sub_album_suggest_1')}
					<br />
					<br />
					{t('albums.tooltip.sub_album_suggest_2')}
				</StyledSubTooltip>
			</StyledWrapperTooltip>
		);
	};

	const onRedirectDetailAlbum = () => {
		if (album?.type === TYPE_ALBUM.FAVORITE_ALBUM) {
			history.push(ROUTE_PATH.FAVORITE);
		} else {
			history.push(`${ROUTE_PATH.ALBUMS}/${album?.id}`);
		}
	};

	useEffect(() => {
		if (refTime.current) {
			clearTimeout(refTime.current);
		}
		refTime.current = setTimeout(() => {
			if (countClick === 1) {
				onSelectAlbum();
				setCountClick(0);
			}
		}, TIME_OUT.DELAY_SELECT);

		if (countClick === 2) {
			setCountClick(0);
			onRedirectDetailAlbum();
		}
	}, [countClick]);

	useEffect(() => {
		if (refView?.current) {
			setWidthBtnView(refView?.current?.offsetWidth);
		}
	}, [refView?.current]);

	return (
		<StyledContainerAlbumItem>
			{isSuggestAlbum && (
				<StyledMarkSuggestHover className="mark_suggest">
					<StyledWrapperAction>
						<StyledButton onClick={handleAddToAlbum} type="primary">
							{t('albums.button.add_to_my_album')}
						</StyledButton>
						<StyledButton
							onClick={handleMaybeLaterAlbum}
							className="btn_gray"
							style={{ marginLeft: '12px' }}>
							{t('albums.button.remind_later')}
						</StyledButton>
						<StyledButton
							onClick={handleNoThank}
							className="btn_gray"
							style={{ marginLeft: '12px' }}>
							{t('albums.button.no_thanks')}
						</StyledButton>
						<StyledTooltip
							overlayInnerStyle={{ minWidth: '400px' }}
							placement="topLeft"
							title={renderTooltipMoreInfo()}>
							<StyledSubHover>{t('albums.what_is_this')}</StyledSubHover>
						</StyledTooltip>
					</StyledWrapperAction>

					{/* temporarily hide */}
					{!isSuggestAlbum && (
						<StyledWrapperViewSuggest width={widthBtnView}>
							<StyledMarkTheme
								onClick={onRedirectDetailAlbum}
								className="opacity_full">
								{t('albums.preview_album')}
							</StyledMarkTheme>
						</StyledWrapperViewSuggest>
					)}
				</StyledMarkSuggestHover>
			)}
			<StyleWrapperAlbumItem
				onClick={() => {
					if (!isSuggestAlbum) {
						setCountClick((prev) => prev + 1);
					}
				}}
				className={isSuggestAlbum ? 'suggest_album_item' : ''}
				isSelected={isSelected}
				{...other}>
				{isSelected && <StyledMarkSelectedAlbum />}

				<StyledContainerDetail>
					<StyledWrapperInfo className={isSmallScreen ? 'small_screen' : ''}>
						<StyledRow gutter={[24, 24]}>
							<StyledCol span={8}>
								<StyledWrapperName>
									<StyledName title={album?.name} className="color_change">
										{album?.name}
									</StyledName>
									<StyledCount className="color_change">
										{loading ? loadingText : renderTotalContent()}
									</StyledCount>
								</StyledWrapperName>
							</StyledCol>
							<StyledCol span={16}>
								<StyledFlexAlignCenter>
									<StyledDescription title={description}>
										{description}
									</StyledDescription>
								</StyledFlexAlignCenter>
							</StyledCol>
						</StyledRow>
					</StyledWrapperInfo>

					<StyledWrapperView className={isSmallScreen ? 'small_screen' : ''}>
						<StyledRow
							style={{ height: `${HEIGHT_THUMBNAIL_ALBUM}px` }}
							gutter={[10, 10]}>
							<StyledCol
								className="col_color col_left"
								span={isSmallScreen ? 16 : 20}>
								<StyledWrapperListTheme
									className="color_change"
									justifyContent={contentViews?.length === 0 ? 'center' : 'end'}>
									{loading ? loadingContent : renderContent()}
								</StyledWrapperListTheme>
							</StyledCol>
							<StyledCol className="col_color col_right" span={isSmallScreen ? 8 : 4}>
								<StyledWrapperListTheme>
									<StyledWrapperTheme
										className="hover_none"
										onClick={onRedirectDetailAlbum}>
										{contentViews?.length > 0 && (
											<StyledThemeItem>
												<StyledImgTheme
													ref={imgRef}
													style={
														checkExistExplicit(
															contentViews[contentViews.length - 1]
																?.review?.flags,
														)
															? { display: 'none' }
															: {}
													}
													src={
														contentViews[contentViews?.length - 1]
															?.thumbnail
													}
													alt="theme_item"
												/>
												{checkExistExplicit(
													contentViews[contentViews.length - 1]?.review
														?.flags,
												) && (
													<ExplicitContent
														zIndex={2}
														sizeIcon={30}
														fontSize="10px"
														style={{ padding: '4px' }}
													/>
												)}
											</StyledThemeItem>
										)}
										{/* temporarily hide */}
										{!isSuggestAlbum && (
											<StyledMarkTheme style={{ zIndex: 3 }} ref={refView}>
												{t('albums.view_album')}
											</StyledMarkTheme>
										)}
									</StyledWrapperTheme>
								</StyledWrapperListTheme>
							</StyledCol>
						</StyledRow>
					</StyledWrapperView>
				</StyledContainerDetail>
			</StyleWrapperAlbumItem>
		</StyledContainerAlbumItem>
	);
};

export default AlbumItem;
