import { deleteRequest, getRequest, postRequest, putRequest } from '@helpers/requestHelpers';
/**
 * @prettier
 */

import genericApiSaga from '@helpers/genericApiSaga';
import { Action } from 'redux-actions';
import { takeLatest, put } from 'redux-saga/effects';
import {
	cloneLiveWidgetTypes,
	createDraftWidgetBuilderTypes,
	fetchCustomFieldWidgetTypes,
	fetchDetailBuilderTypes,
	fetchDetailLiveWidgetTypes,
	fetchDetailWidgetDefaultTypes,
	publishWidgetBuilderTypes,
	removeWidgetBuilderTypes,
	resumeLiveDraftTypes,
	saveDraftWidgetBuilderTypes,
	updateStatusRecentlyUpdatedTypes,
	uploadFileWidgetTypes,
	uploadFontBuilderTypes,
	widgetManagerTypes,
} from './widgetManager.types';
import {
	CLONE_LIVE_WIDGET,
	RESUME_LIVE_DRAFT,
	WIDGET_BUILDER,
	WIDGET_BUILDER_DETAIL,
	WIDGET_BUILDER_GET,
	WIDGET_BUILDER_UPDATE_STATUS_RECENTLY_UPDATED,
	WIDGET_CUSTOM_FIELD,
	WIDGET_DEFAULT_API,
	WIDGET_FONT_API,
} from '@constants/APIs';
import * as actions from './widgetManager.action';
import { convertParamSearch } from '@utils/common';
import { FilterWidgetDto } from '@models/content/widgetManager';

export function* fetchWidgetManager({ payload }: Action<FilterWidgetDto>) {
	const { widgetIds, ...other } = payload;
	const searchParams = convertParamSearch(other);

	yield genericApiSaga({
		gatewayCall: () => putRequest(`${WIDGET_BUILDER}/?${searchParams}`, widgetIds),
		*completed(response: any) {
			yield put(actions.fetchListWidgetSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchListWidgetFailed(response));
		},
	});
}

export function* createWidgetBuilder({ payload }: Action<any>) {
	const { dataRequest } = payload;

	yield genericApiSaga({
		gatewayCall: () => postRequest(WIDGET_BUILDER, dataRequest),
		*completed(response: any) {
			yield put(actions.createDraftWidgetBuilderSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.createDraftWidgetBuilderFailed(response));
		},
	});
}

export function* saveWidgetBuilder({ payload }: Action<any>) {
	const { dataRequest, callBack } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(WIDGET_BUILDER, dataRequest),
		*completed(response: any) {
			if (callBack && typeof callBack === 'function') {
				callBack(response?.data?.result?.id);
			}
			yield put(actions.saveDraftWidgetBuilderSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.saveDraftWidgetBuilderFailed(response));
		},
	});
}

export function* resumeLiveDraft({ payload }: Action<{ widgetId: number }>) {
	const { widgetId } = payload;

	yield genericApiSaga({
		gatewayCall: () => postRequest(`${RESUME_LIVE_DRAFT}/${widgetId}`),
		*completed(response: any) {
			yield put(actions.resumeLiveDraftSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.resumeLiveDraftFailed(response));
		},
	});
}

export function* cloneLiveWidget({ payload }: Action<{ widgetId: number }>) {
	const { widgetId } = payload;

	yield genericApiSaga({
		gatewayCall: () => postRequest(`${CLONE_LIVE_WIDGET}/${widgetId}`),
		*completed(response: any) {
			yield put(actions.cloneLiveWidgetSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.cloneLiveWidgetFailed(response));
		},
	});
}

export function* fetchDetailWidgetBuilder({ payload }: Action<any>) {
	const { widgetId, callBack } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(WIDGET_BUILDER_DETAIL, { widgetId }),
		*completed(response: any) {
			if (callBack && typeof callBack === 'function') {
				callBack(response?.data?.result);
			}
			yield put(actions.fetchDetailWidgetBuilderSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchDetailWidgetBuilderFailed(response));
		},
	});
}

export function* fetchDetailLiveWidget({ payload }: Action<any>) {
	const { widgetId, callBack } = payload;
	yield genericApiSaga({
		gatewayCall: () => getRequest(WIDGET_BUILDER_DETAIL, { widgetId }),
		*completed(response: any) {
			if (callBack && typeof callBack === 'function') {
				callBack(response?.data?.result);
			}
			yield put(actions.fetchDetailLiveWidgetSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchDetailLiveWidgetFailed(response));
		},
	});
}

export function* uploadFileWidget({ payload }: Action<any>) {
	const { formData } = payload;
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${WIDGET_BUILDER}/file`, formData),
		*completed(response: any) {
			yield put(actions.uploadFileWidgetBuilderSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.uploadFileWidgetBuilderFailed(response));
		},
	});
}

export function* uploadFontWidget({ payload }: Action<{ data: any; widgetId: number }>) {
	const { data, widgetId } = payload;
	yield genericApiSaga({
		gatewayCall: () => postRequest(`${WIDGET_FONT_API}?widgetId=${widgetId}`, data),
		*completed(response: any) {
			yield put(actions.uploadFontBuilderSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.uploadFontBuilderFailed(response));
		},
	});
}

export function* removeWidget({ payload }: Action<any>) {
	const { dataRequest } = payload;
	const searchParams = convertParamSearch(dataRequest);

	yield genericApiSaga({
		gatewayCall: () => deleteRequest(`${WIDGET_BUILDER}?${searchParams}`),
		*completed(response: any) {
			yield put(actions.removeWidgetBuilderSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.removeWidgetBuilderFailed(response));
		},
	});
}

export function* publishWidget({ payload }: Action<any>) {
	const webUrl = window.location.href || '';
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${WIDGET_BUILDER}/publish?webUrl=${webUrl}`, payload),
		*completed(response: any) {
			yield put(actions.publishWidgetBuilderSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.publishWidgetBuilderFailed(response));
		},
	});
}

export function* updateStatusRecentlyUpdated({ payload }: Action<number[]>) {
	yield genericApiSaga({
		gatewayCall: () => putRequest(`${WIDGET_BUILDER_UPDATE_STATUS_RECENTLY_UPDATED}`, payload),
		*completed(response: any) {
			yield put(actions.updateStatusRecentlyUpdatedSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.updateStatusRecentlyUpdatedFailed(response));
		},
	});
}

export function* fetchCustomFieldWidget() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(WIDGET_CUSTOM_FIELD),
		*completed(response: any) {
			yield put(actions.fetchCustomFieldWidgetSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchCustomFieldWidgetFailed(response));
		},
	});
}

export function* fetchDetailWidgetDefault() {
	yield genericApiSaga({
		gatewayCall: () => getRequest(WIDGET_DEFAULT_API),
		*completed(response: any) {
			yield put(actions.fetchDetailWidgetDefaultSucceeded(response));
		},
		*failed(response: any) {
			yield put(actions.fetchDetailWidgetDefaultFailed(response));
		},
	});
}

export function* createWidgetSaga() {
	yield takeLatest(widgetManagerTypes.FETCH_LIST_WIDGET_REQUEST, fetchWidgetManager);
	yield takeLatest(
		createDraftWidgetBuilderTypes.CREATE_DRAFT_WIDGET_BUILDER_REQUEST,
		createWidgetBuilder,
	);
	yield takeLatest(
		saveDraftWidgetBuilderTypes.SAVE_DRAFT_WIDGET_BUILDER_REQUEST,
		saveWidgetBuilder,
	);
	yield takeLatest(resumeLiveDraftTypes.RESUME_LIVE_DRAFT_REQUEST, resumeLiveDraft);
	yield takeLatest(cloneLiveWidgetTypes.CLONE_LIVE_WIDGET_REQUEST, cloneLiveWidget);
	yield takeLatest(
		fetchDetailBuilderTypes.FETCH_DETAIL_BUILDER_REQUEST,
		fetchDetailWidgetBuilder,
	);
	yield takeLatest(
		fetchDetailLiveWidgetTypes.FETCH_DETAIL_LIVE_WIDGET_REQUEST,
		fetchDetailLiveWidget,
	);
	yield takeLatest(uploadFileWidgetTypes.UPLOAD_FILE_WIDGET_REQUEST, uploadFileWidget);
	yield takeLatest(uploadFontBuilderTypes.UPLOAD_FONT_BUILDER_REQUEST, uploadFontWidget);

	yield takeLatest(removeWidgetBuilderTypes.REMOVE_WIDGET_BUILDER_REQUEST, removeWidget);
	yield takeLatest(publishWidgetBuilderTypes.PUBLISH_WIDGET_BUILDER_REQUEST, publishWidget);
	yield takeLatest(
		updateStatusRecentlyUpdatedTypes.UPDATE_STATUS_RECENTLY_UPDATED_REQUEST,
		updateStatusRecentlyUpdated,
	);
	yield takeLatest(
		fetchCustomFieldWidgetTypes.FETCH_CUSTOM_FIELD_WIDGET_REQUEST,
		fetchCustomFieldWidget,
	);
	yield takeLatest(
		fetchDetailWidgetDefaultTypes.FETCH_DETAIL_WIDGET_DEFAULT_REQUEST,
		fetchDetailWidgetDefault,
	);
}
