import {
	CONTRAST_CHECKER_API,
	GOOGLE_API,
	SOCIAL_INSTAGRAM_VIDEO,
	SOCIAL_REDIRECTED_URL,
	SOCIAL_TIKTOK_CONTENT,
	SOCIAL_YOUTUBE_CONTENT,
} from '@constants/APIs';
import { TYPE_ALBUM } from '@constants/content/albums';
import {
	CONTENT_FOLDER,
	CONTENT_TYPE,
	FOLDER,
	RATE_STAR,
	SOCIAL_SOURCE,
	THUMBNAIL_BEHAVIOR,
	UPLOADSOURCE,
	VALIDATE_FILE,
} from '@constants/content/contentLibrary';
import { ID_GOOGLE_TRANSLATE } from '@constants/content/widgetManager';
import { SPECIAL_CHARS } from '@constants/settings';
import { SOCIAL, SOCIAL_PROFILE } from '@constants/social_search';
import { postRequest } from '@helpers/requestHelpers';
import { getValue } from '@helpers/storageHelpers';
import { FolderItemDto } from '@models/common/summary';
import { ContentContainerDto, ContentDto } from '@models/content/contentLibrary/summary';
import { UserPermisionType } from '@stores/user/user.types';
import _, { remove } from 'lodash';

/**
 * @prettier
 */

import { DEFAULT_FILTERS, KEY_TURN_OFF } from '@constants/publish/gallery';
import { SocialContentContainerDto } from '@models/socialsearch/summary';
import { Env, keyPendo, s3URL, socialS3URL } from '../config';
import prod from '../config/prod.json';
import qa from '../config/qa.json';
import {
	KEYBOARD_ACTIONS,
	PATTERNS,
	PLACEHOLDER_IMAGE_IN_BASE64,
	TYPE_FILE,
} from '../constants/common';
import { SocialCardDto } from '../models/socialsearch/summary';
import { convertSocialId } from './socialSearch';
import React from 'react';

const isSocialS3Uri = (uri: string | undefined) => {
	if (!uri) {
		return false;
	}
	return (
		uri.startsWith('tiktok') ||
		uri.startsWith('/tiktok') ||
		uri.startsWith('instagram') ||
		uri.startsWith('/instagram') ||
		uri.startsWith('twitter') ||
		uri.startsWith('/twitter') ||
		uri.startsWith('youtube') ||
		uri.startsWith('/youtube')
	);
};

const getSocialS3URL = (uri: string | undefined, env: string = '') => {
	if (!uri) {
		return '';
	}
	if (isSocialS3Uri(uri)) {
		let scS3Url = socialS3URL;
		if (env) {
			scS3Url = env === 'prod' ? prod.SOCIAL_S3_URL : qa.SOCIAL_S3_URL;
		}
		if (uri.charAt(0) === '/') {
			return `${scS3Url}${uri}`;
		}
		return `${scS3Url}/${uri}`;
	}
	if (isS3ContentURL(uri)) {
		let s3Url = s3URL;
		if (env) {
			s3Url = env === 'prod' ? prod.S3_URL : qa.S3_URL;
		}
		return `${s3Url}${uri}`;
	}
	return uri;
};

const isS3ContentURL = (uri: string | undefined) => {
	return uri && uri.startsWith('/');
};

const getS3SocialContentURL = (uri: string, env: string = '') => {
	let scS3Url = socialS3URL;
	if (env) {
		scS3Url = env === 'prod' ? prod.SOCIAL_S3_URL : qa.SOCIAL_S3_URL;
	}
	if (uri.charAt(0) === '/') {
		return `${scS3Url}${uri}`;
	}
	return `${scS3Url}/${uri}`;
};

const getContentByType = (data: ContentContainerDto) => {
	let contentTemp: any = {};

	if (data.type === CONTENT_TYPE.VERIFIED || data.type === CONTENT_TYPE.CONTENT) {
		contentTemp = data.content;
	} else if (data.type === CONTENT_TYPE.SELECT) {
		contentTemp = data.select;
	}

	return contentTemp;
};

const convertContentToResp = (data: ContentDto[], list: ContentContainerDto[]) => {
	const result: ContentContainerDto[] = [];
	list?.forEach((item: ContentContainerDto) => {
		const itemConvert: ContentDto = getContentByType(item);
		if (data?.some((contentCheck: ContentDto) => contentCheck?.id === itemConvert?.id)) {
			result.push(item);
		}
	});
	return result;
};

const convertToSingleContent = (contentList: ContentContainerDto[]) => {
	const contentListTemp = contentList.map((content: ContentContainerDto) => {
		const contentTemp = getContentByType(content);
		return contentTemp;
	});
	return contentListTemp;
};

const filterContentList = (
	contentList: Array<ContentContainerDto>,
	selectContentList: Array<ContentContainerDto>,
) => {
	let contentListTemp: Array<ContentContainerDto> = [];
	contentListTemp = [...contentList];

	selectContentList.forEach((content) => {
		const selectContentTemp = getContentByType(content);
		contentListTemp = contentListTemp.filter((item) => {
			const contentTemp = getContentByType(item);
			return contentTemp.id !== selectContentTemp.id;
		});
	});

	return contentListTemp;
};

const filterContentListById = (
	contentList: Array<ContentContainerDto>,
	content: ContentDto | null | undefined,
) => {
	let contentListTemp: Array<ContentContainerDto> = [];
	contentListTemp = [...contentList];

	contentListTemp = contentListTemp.filter((item) => {
		const contentTemp = getContentByType(item);
		return contentTemp.id !== content?.id;
	});

	return contentListTemp;
};

const isJSONString = (str: string) => {
	try {
		return JSON.parse(str) && !!str;
	} catch (e) {
		return false;
	}
};

const SI_SYMBOL = ['', 'K', 'M', 'B'];

const abbreviateNumber = (value: number | string | null | undefined, numFixed?: number) => {
	const fixed = numFixed !== undefined ? numFixed : 2;
	if (!value) {
		return '0';
	}
	const number = +value;
	const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
	if (tier === 0 || number === 0) return number.toFixed(fixed).toString().replace(/\.0+$/, '');
	if (number < 1) return number.toFixed(fixed);
	const suffix = SI_SYMBOL[tier];
	const scale = Math.pow(10, tier * 3);
	const scaled = number / scale;

	return (+scaled.toFixed(fixed)).toString().replace(/\.0+$/, '') + suffix;
};

// 123456 => 123,456
const numberWithCommas = (
	value: number | string | null | undefined,
	numDecimal?: number,
	showDecimalRound?: boolean,
) => {
	if (!value) {
		return '0';
	}
	const num = +(value || 0);
	const pow = 10 ** (numDecimal || 0);
	const numberRound: number = numDecimal !== undefined ? Math.round(num * pow) / pow : num;
	const valueArr = numberRound.toString().split('.');
	if (showDecimalRound && numDecimal && (!valueArr[1] || valueArr[1].length < numDecimal)) {
		let loop = numDecimal - (valueArr[1] || '').length;
		while (loop > 0) {
			valueArr[1] = `${valueArr[1] || ''}0`;
			loop -= 1;
		}
	}

	return (
		valueArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (valueArr[1] ? `.${valueArr[1]}` : '')
	);
};

const kFormatter = (num: number, numDecimal?: number) => {
	const pow = 10 ** (numDecimal || 0);
	const numberRound: number = Math.round(num * pow) / pow;

	if (numberRound >= 1000 && numberRound < 10000) {
		const convertNumToString = numberRound.toString();
		const valueArr = convertNumToString.split('.');

		const getNumber = +valueArr[0];
		const firstNum = Math.floor(getNumber / 1000);
		const lengthFirstNum = firstNum.toString().length;
		const subNum = convertNumToString.slice(lengthFirstNum, lengthFirstNum + 3);
		const decimal = valueArr[1] || '';
		const value = `${firstNum},${subNum}${decimal ? `.${decimal}` : ''}`;

		return value;
	} else if (numberRound >= 10000) {
		const convertNumToString = (numberRound / 1000).toString();
		const value = convertNumToString.split('.')[0] + 'k';
		return value;
	} else {
		return numberRound;
	}
};

const handleImageLoadedError = async (
	valE: any,
	uri: string | undefined,
	urlOther?: string | null,
	socialType?: string | null,
	noSetTarget?: boolean,
) => {
	const e = valE || { target: { src: '', poster: '' } };

	if (!noSetTarget) {
		e.target.onError = null;
	}
	const handleSetTarget = (val: string) => {
		if (!noSetTarget) {
			e.target.src = val;
		}
	};
	if (!uri) {
		if (urlOther) {
			return urlOther;
		}
		return uri;
	}
	const j = uri.indexOf('://');
	let imageUrl = uri;
	if (j >= 0) {
		for (let i = j + 3; i < uri.length; i += 1) {
			if (uri.charAt(i) === '/') {
				imageUrl = uri.substring(i);
				break;
			}
		}
	}
	if (isSocialS3Uri(imageUrl)) {
		const srcNew = getS3SocialContentURL(uri, Env.includes('prod') ? 'prod' : 'qa');
		handleSetTarget(srcNew);
		return srcNew;
	}
	if (urlOther && isURl(urlOther)) {
		handleSetTarget(urlOther);
		return urlOther;
	}

	if (socialType === SOCIAL_SOURCE.INSTAGRAM) {
		const srcNew = await getInstagramImageData(uri);
		handleSetTarget(srcNew);

		return srcNew;
	}
	handleSetTarget(PLACEHOLDER_IMAGE_IN_BASE64);

	e.target.poster = `${PLACEHOLDER_IMAGE_IN_BASE64}`;

	return `${PLACEHOLDER_IMAGE_IN_BASE64}`;
};

const handleThumbImageLoadedError = async (
	e: any,
	socialType: string,
	permanlink?: string | undefined,
) => {
	e.target.onError = null;
	const defaultPlaceholder = PLACEHOLDER_IMAGE_IN_BASE64;
	if (!permanlink) {
		e.target.src = defaultPlaceholder;
		e.target.poster = defaultPlaceholder;
		return defaultPlaceholder;
	}

	let url = '';
	if (socialType === SOCIAL_SOURCE.INSTAGRAM) {
		url = await getInstagramImageData(permanlink);
	} else if (socialType === SOCIAL_SOURCE.TIKTOK) {
		url = await getTiktokMediaThumbnail(permanlink);
	}

	if (!url) {
		e.target.src = defaultPlaceholder;
		return defaultPlaceholder;
	}

	e.target.src = url;
	return url;
};

const handleVideoLoadedError = async (
	valE: any,
	socialType: string,
	permalink?: string | undefined,
	media?: any,
) => {
	const e = valE || { target: { src: '', poster: '' } };
	e.target.onError = null;
	const defaultPlaceholder = PLACEHOLDER_IMAGE_IN_BASE64;
	const initDefault = () => {
		e.target.src = `${defaultPlaceholder}`;
		e.target.poster = `${defaultPlaceholder}`;
		return `${defaultPlaceholder}`;
	};

	if (!permalink) {
		return initDefault();
	}

	let url = '';
	if (socialType === SOCIAL_SOURCE.INSTAGRAM) {
		url = await getInstagramVideoData(permalink, media);
	} else if (socialType === SOCIAL_SOURCE.TIKTOK) {
		url = await getTiktokMediaUrl(permalink, media);
	} else if (socialType === SOCIAL_SOURCE.YOUTUBE) {
		url = await getYoutubeMediaUrl(media.mediaId, media);
	}

	if (!url) {
		return initDefault();
	}

	return url;
};

const handleLoadedSocialError = async (
	e: any,
	socialContent: SocialContentContainerDto | SocialCardDto,
) => {
	let src;
	if (socialContent.socialSource.toUpperCase() === SOCIAL_SOURCE.TIKTOK.toUpperCase()) {
		src = await handleThumbImageLoadedError(e, SOCIAL_SOURCE.TIKTOK, socialContent.permalink);
	} else if (socialContent.socialSource.toUpperCase() === SOCIAL_SOURCE.INSTAGRAM.toUpperCase()) {
		src = await handleThumbImageLoadedError(
			e,
			SOCIAL_SOURCE.INSTAGRAM,
			socialContent?.thumbnail,
		);
	} else {
		src = await handleImageLoadedError(
			e,
			socialContent?.mediaType && socialContent?.mediaType.includes('video')
				? socialContent?.thumbnail
				: socialContent?.imgUrl,
		);
	}
	return src;
};

const handleLoadedVideoSocialError = async (e: any, socialContent: SocialContentContainerDto) => {
	let src;
	const socialSource = socialContent.socialSource.toUpperCase();
	if (
		socialSource === SOCIAL_SOURCE.TIKTOK.toUpperCase() ||
		socialSource === SOCIAL_SOURCE.YOUTUBE.toUpperCase()
	) {
		src = await handleVideoLoadedError(
			e,
			socialSource.toLowerCase(),
			socialContent.permalink,
			socialContent,
		);
	} else if (socialSource === SOCIAL_SOURCE.INSTAGRAM.toUpperCase()) {
		const permalink = `https://www.instagram.com/p/${socialContent.shortCode}`;
		src = await handleVideoLoadedError(e, SOCIAL_SOURCE.INSTAGRAM, permalink, socialContent);
	} else {
		src = await handleImageLoadedError(e, socialContent?.imgUrl, null, null, true);
	}
	return src;
};

const handleContentImageLoadedSocialError = async (
	e: any,
	socialContent: SocialContentContainerDto,
) => {
	let src;
	if (socialContent.socialSource.toUpperCase() === SOCIAL_SOURCE.TIKTOK.toUpperCase()) {
		src = await handleThumbImageLoadedError(e, SOCIAL_SOURCE.TIKTOK, socialContent.permalink);
	} else if (socialContent.socialSource.toUpperCase() === SOCIAL_SOURCE.INSTAGRAM.toUpperCase()) {
		src = await handleThumbImageLoadedError(e, SOCIAL_SOURCE.INSTAGRAM, socialContent?.imgUrl);
	} else {
		src = handleImageLoadedError(e, socialContent?.imgUrl);
	}
	return src;
};

const getUrlBypassCors = (url: string) => {
	const p = url.split('/');
	let t = '';
	for (let i = 0; i < p.length; i += 1) {
		if (i === 2) {
			t +=
				p[i].replaceAll('-', '--').replaceAll('.', '-') +
				atob('LnRyYW5zbGF0ZS5nb29n') +
				'/';
		} else {
			if (i !== p.length - 1) {
				t += p[i] + '/';
			} else {
				t += p[i];
			}
		}
	}
	const src = encodeURI(t);
	return src;
};

const getInstagramImageData = async (url: string) => {
	if (!url) {
		return null;
	}
	try {
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: url,
		};
		const result = await fetch(`${SOCIAL_REDIRECTED_URL}`, requestOptions);
		if (result.ok) {
			const response = await result.json();
			const contentUrl = response.result;
			return contentUrl;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const getTiktokMediaThumbnail = async (url: string) => {
	if (!url) {
		return null;
	}
	try {
		const result = await fetch(`https://www.tiktok.com/oembed?url=${url}`, { method: 'GET' });
		if (result.ok) {
			const response = await result.json();
			return response.thumbnail_url;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const getTiktokMediaUrl = async (url: string, media?: any) => {
	if (!url) {
		return '';
	}
	try {
		const result = await fetch(`${SOCIAL_TIKTOK_CONTENT}?url=${url}`, { method: 'GET' });
		if (result.ok) {
			const response = await result.json();
			const contentUrl = response.result;
			media.contentUrl = contentUrl;
			media.imgUrl = contentUrl;
			return contentUrl;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const getYoutubeMediaUrl = async (mediaId: string, media?: any) => {
	if (!mediaId) {
		return '';
	}
	try {
		const result = await fetch(`${SOCIAL_YOUTUBE_CONTENT}?mediaId=${mediaId}`, {
			method: 'GET',
		});
		if (result.ok) {
			const response = await result.json();
			const contentUrl = response.result;
			media.contentUrl = contentUrl;
			media.imgUrl = contentUrl;
			return contentUrl;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const getInstagramVideoData = async (url: string, media?: any) => {
	if (!url) {
		return '';
	}
	try {
		const result = await fetch(`${SOCIAL_INSTAGRAM_VIDEO}?url=${url}`, { method: 'GET' });
		if (result.ok) {
			const response = await result.json();
			const contentUrl = response.result;
			media.imgUrl = contentUrl;
			media.contentUrl = contentUrl;
			return contentUrl;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const sortKeyObject: any = (object: any) => {
	if (!object) {
		return object;
	}
	const sortedObj: any = {};
	const keys = Object.keys(object);

	keys.sort((key1, key2) => {
		key1 = key1.toLowerCase();
		key2 = key2.toLowerCase();
		if (key1 < key2) return -1;
		if (key1 > key2) return 1;
		return 0;
	});

	keys?.forEach((item) => {
		if (typeof object[item] == 'object' && !(object[item] instanceof Array)) {
			sortedObj[item] = sortKeyObject(object[item]);
		} else {
			sortedObj[item] = object[item];
		}
	});

	return sortedObj;
};

const urlExit = async (url: string) => {
	try {
		const result = await fetch(url, { method: 'HEAD' });
		return result.ok;
	} catch (e) {
		return null;
	}
};

const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

const replaceFirstCharacterToLowercase = (val: string) => {
	if (!val) return val;
	return val[0].toLowerCase() + val.slice(1);
};

const replaceSpecialCharacters = (val: string, characterReplace?: string) => {
	const character = characterReplace || '';
	return val.replace(/[^A-Z0-9]/gi, character);
};

const revokeGoogleRefreshToken = () => {
	const googleAuthToken = getValue('googleToken')
		? JSON.parse(getValue('googleToken') || '')
		: null; // an object contains refresh_token and access_token

	if (googleAuthToken) {
		const { refreshToken = '' } = googleAuthToken;
		postRequest(`${GOOGLE_API.REVOKE_TOKEN}=${refreshToken}`)
			.then(() => {
				remove('googleToken');
			})
			.catch((error) => {
				console.log('Error', error);
			});
	}
};

const isURl = (url?: string | null) => {
	return url?.startsWith('http://') || url?.startsWith('https://');
};

const formatUrl = (url?: string) => {
	if (!url || !url.trim()) {
		return '';
	}
	const newUrl = url?.trim();
	if (isURl(newUrl)) {
		return newUrl;
	}
	return `https://${newUrl}`;
};

const validateSpecialCharacters = (name: string) => {
	return name.replace(/[^a-zA-Z0-9_]/g, '');
};

const validateNumberTimeout = (name: any) => {
	if (typeof name !== 'string') {
		return false;
	}
	if (name.trim() === '') {
		return false;
	}

	return !Number.isNaN(Number(name));
};

const validateSizeFileImage = async (
	file: any,
	checkSize?: { size?: number; width?: number; height?: number; textSize?: string },
) => {
	const {
		size: sizeLimit = VALIDATE_FILE.sizeDetail150mb,
		width: widthLimit = VALIDATE_FILE.width,
		height: heightLimit = VALIDATE_FILE.height,
		textSize,
	} = checkSize || {};

	const sizeValidateImg: any = await checkSizeImage(file, checkSize);
	const params = {
		width: widthLimit,
		height: heightLimit,
		size: sizeLimit / 1024 / 1024,
		textSize,
	};

	if (sizeValidateImg) {
		return {
			valid: sizeValidateImg?.valid || false,
			err: sizeValidateImg?.valid
				? ''
				: 'content.widget_manager.message.message_limit_size_image',
			params,
			resolution: { width: sizeValidateImg.width, height: sizeValidateImg.height },
		};
	}
	return {
		valid: false,
		err: 'content.widget_manager.message.message_limit_size_image',
		params,
		resolution: { width: 0, height: 0 },
	};
};

const getVideoDuration = async (file: any) =>
	new Promise((resolve, reject) => {
		try {
			const video = document.createElement('video');
			video.preload = 'metadata';

			video.onloadedmetadata = () => {
				resolve(((+video.duration || 0) * 1000).toFixed(0));
			};
			video.onerror = () => {
				resolve(undefined);
			};
			video.src = window.URL.createObjectURL(file);
		} catch (e) {
			reject(e);
		}
	});

const checkSizeImage = (
	file: any,
	checkSize?: { size?: number; width?: number; height?: number },
) => {
	const {
		size: sizeLimit = VALIDATE_FILE.sizeDetail150mb,
		width: widthLimit = VALIDATE_FILE.width,
		height: heightLimit = VALIDATE_FILE.height,
	} = checkSize || {};

	const img = new Image();

	const promise = new Promise((resolve, reject) => {
		img.onload = () => {
			const { width, height } = img;
			if (file.size > sizeLimit || width > widthLimit || height > heightLimit) {
				resolve({ valid: false, width, height });
			}
			resolve({ valid: true, width, height });
		};
		// Reject promise on error
		img.onerror = reject;
	});
	img.src = window.URL.createObjectURL(file);
	return promise;
};

const validateSizeFile = (size: number) => {
	if (size > VALIDATE_FILE.sizeDetail150mb) {
		return {
			invalid: false,
			err: 'content.widget_manager.message.message_limit_size_file',
			params: {
				size: VALIDATE_FILE.size_150,
			},
		};
	}
	return { invalid: true, err: '' };
};

const hashCode = (s: string) => {
	let h = 0;

	for (let i = 0; i < s.length; i += 1) {
		h = Math.imul(31, h) + s.charCodeAt(i) || 0;
	}

	return h;
};

const convertToNumberArray = (array: string[]) => array.map((item: string) => Number(item));

const decode = (numbers: string) => {
	return numbers.replace(/.{3}/g, (c: string) => {
		return String.fromCharCode(parseInt(c, 10));
	});
};

const encode = (val: string | number) => {
	const string = val?.toString();
	return string.replace(/./g, (c: string) => {
		return ('00' + c.charCodeAt(0)).slice(-3);
	});
};

const userHasPermission = (rule: string, userPermisions: UserPermisionType[]) => {
	const roleRule = userPermisions.find((element) => {
		return element.rule.name === rule;
	});
	return roleRule && roleRule.allow;
};

function addMonths(date: any, months: number) {
	const month = (date.getMonth() + months) % 12;
	const last = new Date(date.getFullYear(), month + 1, 0);
	if (date.getDate() <= last.getDate()) {
		date.setMonth(month);
	} else {
		date.setMonth(month, last.getDate());
	}
	return date;
}

const randomId = () => {
	return Math.floor(Math.random() * 10 ** 10);
};

const goToSocialProfile = (username: string, type: string) => {
	if (!type) {
		return;
	}
	let link: string = '';
	const socialType = type?.toLocaleLowerCase() || '';

	if (socialType === SOCIAL.INSTAGRAM.toLocaleLowerCase()) {
		link = `${SOCIAL_PROFILE.INSTAGRAM}${username}/`;
	} else if (socialType === SOCIAL.TWITTER.toLocaleLowerCase()) {
		link = `${SOCIAL_PROFILE.TWTTER}${username}`;
	} else if (socialType === SOCIAL.TIKTOK.toLocaleLowerCase()) {
		link = `${SOCIAL_PROFILE.TIKTOK}${username}`;
	} else if (socialType === SOCIAL.YOUTUBE.toLocaleLowerCase()) {
		link = `${SOCIAL_PROFILE.YOUTUBE}${username}`;
	}

	window.open(link, '_blank');
};

const convertURLToBase64 = async (url: string) => {
	const result = await fetch(url).then((response) => response.blob());
	return result;
};

const getThumbnailBehavior = () => {
	let savedThumbnailBehavior = getValue(THUMBNAIL_BEHAVIOR.SAVED)
		? JSON.parse(getValue(THUMBNAIL_BEHAVIOR.SAVED) || '')
		: [];

	if (!savedThumbnailBehavior || savedThumbnailBehavior.length === 0) {
		savedThumbnailBehavior = [THUMBNAIL_BEHAVIOR.SHOW_RIGHTS_CLEARANCE];
	}

	return savedThumbnailBehavior;
};

const handleConvertGifToPng = (thumbnail?: string) => {
	if (!thumbnail) {
		return '';
	}
	const gif = TYPE_FILE.GIF;
	const png = TYPE_FILE.PNG;
	const positionType = thumbnail?.lastIndexOf('.');
	const urlNotType = thumbnail?.slice(0, positionType);
	const type = thumbnail?.slice(positionType);

	const renderUrl = (tail: string) => {
		return urlNotType + tail;
	};

	return type === gif ? renderUrl(png) : thumbnail;
};

/* eslint-disable */
function convertToRgbA(hex: string, opacity?: number) {
	if (hex?.length > 8) {
		const endKey = hex.lastIndexOf(',');
		const result = hex.slice(0, endKey);
		return result + `, ${typeof opacity === 'number' ? opacity : 1})`;
	}
	let c: any;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		return (
			'rgba(' +
			[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
			`,${typeof opacity === 'number' ? opacity : 1})`
		);
	}
	throw new Error('Bad Hex');
}
/* eslint-enable */

const colorContrastChecker = async (color1: string, color2: string) => {
	if (!color1 || !color2) {
		return null;
	}

	try {
		if (color1.startsWith(SPECIAL_CHARS.HASH)) {
			color1 = color1.substring(1);
		}
		if (color2.startsWith(SPECIAL_CHARS.HASH)) {
			color2 = color2.substring(1);
		}
		const url = `${CONTRAST_CHECKER_API}?fcolor=${color1}&bcolor=${color2}&api`;
		const result = await fetch(url);

		if (result.ok) {
			const response = await result.json();
			return response;
		}

		return null;
	} catch (error) {
		console.log('Contrast checker API error: ', error);
		return null;
	}
};

const getAlbumId = (content: ContentDto, folderList: FolderItemDto[]) => {
	const { uploadSource } = content;
	let folder;

	if (uploadSource === UPLOADSOURCE.DIRECT_UPLOAD) {
		folder = folderList.find(
			(item: FolderItemDto) => item.name === FOLDER.SUBMISSIONS.toLowerCase(),
		);
	} else
		folder = folderList.find(
			(item: FolderItemDto) => item.name === FOLDER.SELECTS.toLowerCase(),
		);

	return folder?.id;
};

const convertParamSearch = (params: any) => {
	if (typeof params !== 'object') {
		return '';
	}
	const searchParams = new URLSearchParams('');
	Object.keys(params).forEach((key: string) => {
		if (params[key] || typeof params[key] === 'boolean' || params[key] === 0) {
			searchParams.append(key, params[key]);
		}
	});
	return searchParams;
};

const sliceFirstSpecialChar = (text: string, specialChar: string) => {
	if (text.charAt(0) === specialChar) {
		text = text.replace(specialChar, SPECIAL_CHARS.NONE);
	}

	return text;
};

// Update content list after marking as favorite content
const updateFavoriteContentList = (
	favoriteContent: ContentDto,
	contentList: ContentContainerDto[],
	namePage?: string,
) => {
	let listTemp: ContentContainerDto[] = _.cloneDeep(contentList);

	if (
		namePage &&
		(namePage === CONTENT_FOLDER.FAVORITES || namePage === TYPE_ALBUM.FAVORITE_ALBUM)
	) {
		listTemp = listTemp.filter((content: ContentContainerDto) => {
			const convertedContent = getContentByType(content);
			return convertedContent.id !== favoriteContent.id;
		});
	} else {
		listTemp.forEach((content: ContentContainerDto) => {
			const convertedContent = getContentByType(content);

			if (convertedContent.id === favoriteContent.id) {
				convertedContent.review.favorite = !convertedContent.review.favorite;
				if (!convertedContent.review.star && convertedContent.review.favorite) {
					convertedContent.review.star = RATE_STAR.FIVE;
				}
			}
		});
	}

	return listTemp;
};

const updateFavoriteSocialList = (
	favoriteContent: ContentDto,
	socialList: SocialContentContainerDto[],
	idCustom: string | null,
) => {
	socialList.forEach((social: SocialContentContainerDto) => {
		if (idCustom === convertSocialId(social) && social.content) {
			const convertedContent = getContentByType(social.content);
			if (convertedContent.id === favoriteContent.id) {
				convertedContent.review.favorite = !convertedContent.review.favorite;
				if (!convertedContent.review.star && convertedContent.review.favorite) {
					convertedContent.review.star = RATE_STAR.FIVE;
				}
			}
		}
	});

	return socialList;
};

// Update content list after marking as favorite content type CONTENT_DTO
const updateFavoriteContentDTOList = (
	favoriteContent: ContentDto,
	contentList: ContentDto[],
	namePage?: string,
) => {
	let listTemp: ContentDto[] = _.cloneDeep(contentList);

	if (namePage && namePage === TYPE_ALBUM.FAVORITE_ALBUM) {
		listTemp = listTemp.filter((content: ContentDto) => {
			return content.id !== favoriteContent.id;
		});
	} else {
		listTemp.forEach((content: ContentDto) => {
			if (content.id === favoriteContent.id) {
				content.review.favorite = !content.review.favorite;
				if (!content.review.star && content.review.favorite) {
					content.review.star = RATE_STAR.FIVE;
				}
			}
		});
	}

	return listTemp;
};

const renderScaleRotateContent = (content: ContentDto, scale?: number) => {
	const { review } = content;
	const { orientation = '0' } = review || {};
	const orientationView = +(orientation || 0);
	const result = { scale: 1, rotate: orientationView };
	if (orientationView % 180 !== 0) {
		result.scale = scale || 1;
	}
	return result;
};

const sortListByKey = (list: Array<any>, keySort: string) => {
	const compare = (a: any, b: any) => {
		if (a[keySort] < b[keySort]) {
			return -1;
		}
		if (a[keySort] > b[keySort]) {
			return 1;
		}
		return 0;
	};
	return list.sort(compare);
};

const greatestCommonDivisor: (a: number, b: number) => number = (a: number, b: number) => {
	if (!b) {
		return a;
	}

	return greatestCommonDivisor(b, a % b);
};

/* eslint-disable */
const pendoFunc = (p: any, e: Document, n: string, d: string) => (apiKey: string) => {
	const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];

	let w;
	let x;
	const o: any = (p[d] = p[d] || {});
	o._q = o._q || [];
	for (w = 0, x = v.length; w < x; ++w)
		(function (m) {
			o[m] =
				o[m] ||
				function () {
					o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
				};
		})(v[w]);
	const y: any = e.createElement(n);
	y.async = !0;
	y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
	const z: any = e.getElementsByTagName(n)[0];

	z.parentNode.insertBefore(y, z);
};
/* eslint-enable */

const funcFirstPendo = () => {
	return pendoFunc(window, document, 'script', 'pendo')(keyPendo);
};

const replaceAtChar = (data: string) => {
	if (data && data.includes('@')) data = data.replace('@', '');
	return data;
};

const handleRedirectLink = (url: string) => {
	const linkElement = document.createElement('a');
	linkElement.href = url;
	linkElement.target = '_blank';
	document.body.appendChild(linkElement);
	linkElement.click();
	document.body.removeChild(linkElement);
};

const setDocumentCookie = (key: string, value: string) => {
	const expires = new Date();
	expires.setTime(expires.getTime());
	document.cookie = key + '=' + value;
};

const googleTranslateElementInit = () => {
	setDocumentCookie('googtrans', '/en/en');
	window.google.translate.TranslateElement(
		{
			pageLanguage: 'en',
			autoDisplay: false,
		},
		ID_GOOGLE_TRANSLATE,
	);
};

const googleTranslateScript = () => {
	const addScript = document.createElement('script');
	addScript.setAttribute(
		'src',
		'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
	);
	document.body.appendChild(addScript);
	if (window) {
		(window as any).googleTranslateElementInit = googleTranslateElementInit;
	}
};

const validateInputNumber = (
	event: React.KeyboardEvent<HTMLInputElement>,
	isDecimalNumber?: boolean,
) => {
	const { value } = event?.target as any;

	let regex = /^[0-9\b]+$/;
	if (isDecimalNumber) {
		regex = /^[0-9.\b]+$/;
	}
	if (
		(!regex.test(event.key) && !KEYBOARD_ACTIONS.includes(event.key)) ||
		(isDecimalNumber &&
			(value.includes(SPECIAL_CHARS.DOT) || !value) &&
			event.key === SPECIAL_CHARS.DOT)
	) {
		event.preventDefault();
	}
};

const validateInputToPattern = (event: React.KeyboardEvent<HTMLInputElement>, pattern: any) => {
	if (!pattern.test(event.key) && !KEYBOARD_ACTIONS.includes(event.key)) {
		event.preventDefault();
	}
};

const limitChars = (event: React.KeyboardEvent<HTMLInputElement>, limit: number) => {
	const eventTarget = event.target as any;
	if (eventTarget.value.length === limit && !KEYBOARD_ACTIONS.includes(event.key)) {
		event.preventDefault();
	}
};

/* eslint-disable */
function dataURLtoFile(data: string, filename: string) {
	const arr: any[] = data.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}
/* eslint-enable */

const convertOptions = (options: any[], name?: string) => {
	return options.map((item) => {
		return {
			label:
				name === KEY_TURN_OFF.customProperties &&
				PATTERNS.BASE64.test(item) &&
				atob(item) === DEFAULT_FILTERS.CREATOR.fieldName
					? DEFAULT_FILTERS.CREATOR.name
					: item,
			value: item,
		};
	});
};

const isEqualStringsReplacedSpace = (firstContent: string, secondContent: string) =>
	_.isEqual(firstContent.toString(), secondContent.toString());

export {
	abbreviateNumber,
	addMonths,
	checkSizeImage,
	colorContrastChecker,
	convertContentToResp,
	convertOptions,
	convertParamSearch,
	convertToNumberArray,
	convertToRgbA,
	convertToSingleContent,
	convertURLToBase64,
	dataURLtoFile,
	decode,
	encode,
	filterContentList,
	filterContentListById,
	formatUrl,
	funcFirstPendo,
	getAlbumId,
	getContentByType,
	getS3SocialContentURL,
	getSocialS3URL,
	getThumbnailBehavior,
	getTiktokMediaThumbnail,
	getTiktokMediaUrl,
	getUrlBypassCors,
	getVideoDuration,
	getYoutubeMediaUrl,
	goToSocialProfile,
	googleTranslateElementInit,
	googleTranslateScript,
	greatestCommonDivisor,
	handleContentImageLoadedSocialError,
	handleConvertGifToPng,
	handleImageLoadedError,
	handleLoadedSocialError,
	handleLoadedVideoSocialError,
	handleRedirectLink,
	handleThumbImageLoadedError,
	handleVideoLoadedError,
	hashCode,
	isEqualStringsReplacedSpace,
	isJSONString,
	isSocialS3Uri,
	isURl,
	kFormatter,
	limitChars,
	numberWithCommas,
	randomId,
	renderScaleRotateContent,
	replaceAtChar,
	replaceFirstCharacterToLowercase,
	replaceSpecialCharacters,
	revokeGoogleRefreshToken,
	sliceFirstSpecialChar,
	sortKeyObject,
	sortListByKey,
	updateFavoriteContentDTOList,
	updateFavoriteContentList,
	updateFavoriteSocialList,
	urlExit,
	userHasPermission,
	validateEmail,
	validateInputNumber,
	validateInputToPattern,
	validateNumberTimeout,
	validateSizeFile,
	validateSizeFileImage,
	validateSpecialCharacters,
};
