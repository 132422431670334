import { IconClose } from '@assets/icons';
import ContentLicensingTerms from '@components/Settings/ContentLicensing/DetailTemplate/ContainerTemplate/ContentLicensingTerms';
import HistoryTemplate from '@components/Settings/ContentLicensing/DetailTemplate/SidebarTemplate/HistoryTemplate';
import { PARAMS_DEFAULT, TYPE_FORMAT_DATE } from '@constants/common';
import {
	DEFAULT_HEIGHT_CDM_HISTORY,
	DEFAULT_HEIGHT_HISTORY,
	HEIGHT_MODAL_PREVIEW_TERMS,
} from '@constants/licensingTemplate';
import THEME from '@constants/themes/themes';
import PdfView from '@cores/PdfView';
import { formatBase64Pdf } from '@helpers/base64Helper';
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { TermsTemplateContentDto } from '@models/content/contentLibrary/summary';
import { ParamRequestHistoryTemplateTypes, TermsTypes } from '@models/settings/contentLicensing';
import {
	ContentLicensingStoreDto,
	TemplateHistoryTypes,
} from '@models/settings/contentLicensing/stores';
import { fetchTemplateHistoryRequest } from '@stores/actions';
import {
	StyledDivider,
	StyledIcon,
	StyledModal,
	StyledTitle,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import {
	StyledLineVertical,
	StyledPreviewFormTerms,
	StyledWrapperListTerms,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { StyledCollapse } from '@styled/ContentLibrary/ContentDetailStyled';
import { StyledSidebarInfo } from '@styled/ContentLibrary/PreviewModalStyled';
import { StyledWrapperNameTerms } from '@styled/LicensingTemplate/TemplateDetail';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { Panel } = StyledCollapse;

type PropTypes = {
	visible: boolean;
	onCancel: () => void;
	termsTemplateContent: TermsTemplateContentDto;
	fileName?: string;
	[other: string]: any;
};

const PreviewTermsCDMModal = (props: PropTypes) => {
	const { visible, onCancel, termsTemplateContent, fileName, ...other } = props;
	const {
		signedBy,
		signedContext,
		singerEmail,
		terms,
		termsTemplateId,
		termsTemplateName,
		time,
	} = termsTemplateContent;

	const { loadingTemplateHistory, templateHistory }: ContentLicensingStoreDto = useSelector(
		(state: any) => state?.contentLicensing,
	);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const refTime = useRef<any>({});
	const refWrapperContainer = useRef<any>({});

	const [termsSelected, setTermsSelected] = useState<TermsTypes | null>(null);
	const [heightHistory, setHeightHistory] = useState<string>(DEFAULT_HEIGHT_CDM_HISTORY);
	const [collapseActive, setCollapseActive] = useState<string[]>(['1']);
	const [isDetect, setIsDetect] = useState<boolean>(false);
	const [historyList, setHistoryList] = useState<TemplateHistoryTypes[]>([]);
	const [paramHistory, setParamHistory] = useState<ParamRequestHistoryTemplateTypes>({
		...PARAMS_DEFAULT,
		termsTemplateId,
	});

	const infoSigner = [
		{
			label: t('content_licensing.signed_by'),
			value: signedBy,
		},
		{
			label: t('content_licensing.signer_email'),
			value: singerEmail,
		},
		{
			label: t('content_licensing.date_time'),
			value: convertUtcToLocalTimeWithFormat(time, TYPE_FORMAT_DATE.TIME),
		},
	];

	useEffect(() => {
		return () => {
			setIsDetect(false);
			setHeightHistory(DEFAULT_HEIGHT_CDM_HISTORY);
			setTermsSelected(null);
			setCollapseActive(['1']);
			setParamHistory({ ...paramHistory, page: 1 });
			setHistoryList([]);
		};
	}, []);

	useEffect(() => {
		if (isDetect) {
			setTimeout(() => {
				setHeightHistory(getMaxHeightHistory());
				setIsDetect(false);
			}, 0);
		}
	}, [isDetect]);

	useEffect(() => {
		if (paramHistory?.page === 1) {
			setHistoryList(templateHistory);
		} else {
			setHistoryList([...historyList, ...templateHistory]);
		}
	}, [templateHistory]);

	useEffect(() => {
		if (paramHistory.termsTemplateId !== -1 && visible) {
			if (paramHistory?.page === 1) {
				setHistoryList([]);
			}
			dispatch(fetchTemplateHistoryRequest({ ...paramHistory }));
		}
	}, [paramHistory, visible]);

	const onLoadMoreHistory = () => {
		if (!loadingTemplateHistory && templateHistory.length > 0) {
			setParamHistory({ ...paramHistory, page: paramHistory.page + 1 });
		}
	};

	const getMaxHeightHistory = () => {
		if (refWrapperContainer && refWrapperContainer?.current) {
			const heightContainer = refWrapperContainer?.current?.offsetHeight || 0;
			const space = 16 * 3; // padding + divider + space
			const heightHeader = 53; // height header
			const heightHeaderCollapse = 24 + 8;
			const totalSpace = heightContainer + space + heightHeader + heightHeaderCollapse;
			const maxHeight = `calc(${HEIGHT_MODAL_PREVIEW_TERMS} - ${totalSpace || 0}px)`;

			return maxHeight;
		}
		return DEFAULT_HEIGHT_HISTORY;
	};

	const renderHeaderPanel = () => {
		return (
			<StyledWrapperNameTerms width="100%">
				<StyledTitle
					className="unset_height overflow_text"
					fontSize="16px"
					margin="0 0 0 4px"
					maxWidth="calc(100% - 24px)">
					{termsTemplateName}
				</StyledTitle>
			</StyledWrapperNameTerms>
		);
	};

	return (
		<StyledModal
			title={null}
			footer={null}
			className="modal_preview_terms"
			visible={visible}
			onCancel={onCancel}
			centered
			{...other}>
			<StyledWrapperContent
				overflow="hidden auto"
				className="custom_scroll_bar"
				height={HEIGHT_MODAL_PREVIEW_TERMS}
				display="flex">
				<StyledSidebarInfo>
					<StyledWrapperContent
						width="100%"
						display="flex"
						alignItems="center"
						padding="16px 32px">
						<StyledTitle className="unset_height" fontSize="16px">
							{t('content_licensing.content_licensing_agreement')}
						</StyledTitle>
					</StyledWrapperContent>
					<StyledDivider margin="0" />
					<StyledWrapperContent height="calc(100% - 53px)" width="100%" padding="16px">
						<StyledWrapperContent ref={refWrapperContainer} width="100%">
							<StyledWrapperContent>
								<StyledTitle className="unset_height" fontSize="16px">
									{t('content_licensing.signer_information')}
								</StyledTitle>
								{infoSigner.map((item, idx) => (
									<StyledWrapperContent
										margin={idx ? '11px 0 0 0' : '12px 0 0 0'}
										display="flex"
										key={item.label}
										width="100%">
										<StyledTitle
											color={THEME.colors.gray3}
											className="unset_height"
											width="96px"
											margin="0 6px 0 0"
											fontSize="14px">
											{item.label}
										</StyledTitle>
										<StyledTitle
											width="calc(100% - 102px)"
											margin="0 6px 0 0"
											className="unset_height"
											fontSize="14px">
											{item.value}
										</StyledTitle>
									</StyledWrapperContent>
								))}
							</StyledWrapperContent>
							<StyledDivider margin="16px 0" />
							<StyledTitle className="unset_height" fontSize="16px">
								{t('content_licensing.terms_detail')}
							</StyledTitle>
							<StyledWrapperContent margin="16px 0 0 0" width="100%">
								<StyledCollapse
									className="collapse_terms_detail has_arrow"
									expandIconPosition="right"
									style={{ marginBottom: '0' }}
									defaultActiveKey={collapseActive}
									activeKey={collapseActive}
									onChange={(val: any) => {
										setCollapseActive(val);
										if (val?.length > 0) {
											setHeightHistory(DEFAULT_HEIGHT_CDM_HISTORY);
										}
										if (refTime.current) {
											clearTimeout(refTime.current);
										}
										refTime.current = setTimeout(() => {
											setIsDetect(true);
										}, 200);
									}}>
									<Panel header={renderHeaderPanel()} key={'1'}>
										<StyledWrapperListTerms
											maxHeight="200px"
											className="custom_scroll_bar">
											<StyledLineVertical />
											<StyledWrapperContent
												margin="0 0 0 8px"
												width={'calc(100% - 13px)'}>
												{terms?.map((item: TermsTypes, idx: number) => (
													<StyledWrapperNameTerms
														key={item.id}
														margin={idx === 0 ? '0' : '8px 0 0 0'}
														width="100%"
														onClick={() => setTermsSelected(item)}
														className={
															termsSelected?.id === item.id
																? 'disabled active'
																: ''
														}>
														{`${idx + 1}. ${item.name}`}
													</StyledWrapperNameTerms>
												))}
											</StyledWrapperContent>
										</StyledWrapperListTerms>
									</Panel>
								</StyledCollapse>
							</StyledWrapperContent>
							<StyledDivider margin="16px 0" />
						</StyledWrapperContent>

						<StyledWrapperContent>
							<HistoryTemplate
								historyList={historyList}
								loadingTemplateHistory={loadingTemplateHistory}
								onLoadMore={onLoadMoreHistory}
								height={heightHistory}
							/>
						</StyledWrapperContent>
					</StyledWrapperContent>
				</StyledSidebarInfo>
				<StyledWrapperContent
					position="relative"
					padding="32px 24px 16px 24px"
					width={'calc(100% - 360px)'}>
					{termsSelected && (
						<StyledPreviewFormTerms>
							<StyledWrapperContent position="relative" width="100%" height="100%">
								<StyledIcon
									onClick={() => setTermsSelected(null)}
									size={24}
									className="absolute"
									top="24px"
									right="16px">
									<IconClose />
								</StyledIcon>
								<ContentLicensingTerms
									wrapperHeight={HEIGHT_MODAL_PREVIEW_TERMS}
									disabledEdit={true}
									moreSpace={79}
									terms={termsSelected}
								/>
							</StyledWrapperContent>
						</StyledPreviewFormTerms>
					)}
					{signedContext && (
						<PdfView data={formatBase64Pdf(signedContext)} fileName={fileName || ''} />
					)}
				</StyledWrapperContent>
			</StyledWrapperContent>
		</StyledModal>
	);
};

export default PreviewTermsCDMModal;
