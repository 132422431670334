import React from 'react';
import {
	StyledTag,
	StyledFlex,
	StyledIconTag,
	StyledSpanTag,
	StyledDefaultTag,
	StyledActiveTag,
	StyledItemWrapper,
	StyledActionTag,
	StyledSpanDefaultTag,
	StyledTagInfo,
	StyledActionDefault,
} from '@styled/Settings/SocialSearchStyled';
import { IconStar, IconDelete, IconSchedule } from '@assets/icons';
import THEME from '@constants/themes/themes';

interface IHashTag {
	children?: any;
	tagId: number;
	isSearchTerm?: boolean;
	isDefault?: boolean;
	inActive?: boolean;
	showActive?: boolean;
	onClickTag: (key: string, tagId: number, isDefault?: boolean) => void;
}

const HashTag: React.FC<IHashTag> = ({
	children,
	onClickTag,
	tagId,
	isSearchTerm = false,
	inActive = false,
	isDefault = false,
	showActive = false,
}) => {
	const onClickAction = (key: string, isDefault?: boolean) => {
		onClickTag(key, tagId, isDefault);
	};

	const renderActionTag = () => (
		<StyledActionTag width="fit-content">
			<StyledFlex>
				{showActive && (
					<StyledIconTag
						onClick={() => onClickAction('clock')}
						color={THEME.colors.orangeBase}
						icon={<IconSchedule />}
					/>
				)}
				{isSearchTerm && (
					<StyledIconTag
						onClick={() => onClickAction('default')}
						color={THEME.colors.orangeBase}
						icon={<IconStar />}
					/>
				)}
				<StyledIconTag
					onClick={() => onClickAction('delete')}
					color={THEME.colors.orangeBase}
					icon={<IconDelete />}
				/>
			</StyledFlex>
		</StyledActionTag>
	);

	const renderActionTagDefault = () => (
		<StyledActionTag width="fit-content">
			<StyledFlex>
				<StyledIconTag
					onClick={() => onClickAction('clock', isDefault)}
					color={THEME.colors.orangeBase}
					icon={<IconSchedule />}
				/>
				<StyledIconTag
					onClick={() => onClickAction('delete', isDefault)}
					color={THEME.colors.orangeBase}
					icon={<IconDelete />}
				/>
			</StyledFlex>
		</StyledActionTag>
	);

	// fix eslint
	const widthItem = showActive ? '76%' : '90%';

	return (
		<StyledItemWrapper maxWidth={isSearchTerm ? '64%' : widthItem}>
			<StyledFlex>
				{isDefault ? (
					<StyledDefaultTag>
						<StyledActiveTag>
							<StyledSpanDefaultTag>{children}</StyledSpanDefaultTag>
							{renderActionTagDefault()}
						</StyledActiveTag>
						<StyledActionDefault>
							{inActive && (
								<StyledIconTag
									className="icon_clock"
									color={THEME.colors.orangeBase}
									icon={<IconSchedule />}
								/>
							)}
							<StyledIconTag color={THEME.colors.yellowBase} icon={<IconStar />} />
						</StyledActionDefault>
					</StyledDefaultTag>
				) : (
					<StyledTagInfo>
						<StyledTag>
							<StyledSpanTag>{children}</StyledSpanTag>

							{renderActionTag()}
						</StyledTag>
						{inActive && (
							<StyledIconTag
								className="icon_clock"
								color={THEME.colors.orangeBase}
								icon={<IconSchedule />}
							/>
						)}
					</StyledTagInfo>
				)}
			</StyledFlex>
		</StyledItemWrapper>
	);
};

export default HashTag;
