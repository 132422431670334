import { ParamSummaryContactDto, SummaryColumnsDto } from '@models/creator/summary';
import { createContext } from 'react';
import { Paginator as defaultPage } from '@constants/paginator';

// Types
type CreatorSummaryContextTypes = {
	paramSummaryContact: ParamSummaryContactDto;
	handleChangeParamSummary: (val: ParamSummaryContactDto) => void;
	isAllSelectOverDb: boolean;
	selectOnView: number[];
};

// Default value
const data: CreatorSummaryContextTypes = {
	paramSummaryContact: {
		page: 1,
		maxRecords: defaultPage.pageSize,
		search: '',
	},
	handleChangeParamSummary: () => {},
	isAllSelectOverDb: false,
	selectOnView: [],
};

const CreatorSummaryContext = createContext(data);

export default CreatorSummaryContext;
