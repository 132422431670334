import styled from 'styled-components';
import { Row, Col, Tabs, Drawer, Empty, Layout } from 'antd';
import Wrapper from '@cores/Wrapper';
import Button from '@cores/Button';
import THEME from '@constants/themes/themes';
import { defaultSiderWidth } from '@constants/content/albums';

const { Sider, Header } = Layout;

const StyledContent = styled.div`
	position: relative;
`;

const StyledHeader = styled(Header)`
	padding: 0 20px 0 0;
	height: unset;
`;

const StyledRow = styled(Row)<{ margin?: string }>`
	margin-right: 0 !important;
	${({ margin }) => margin && `margin: ${margin}`};
`;

const StyledCol = styled(Col)<{ background?: boolean; padding?: string; margintop?: string }>`
	background-color: ${({ background }) => (background ? THEME.colors.darkBlue : 'unset')};
	padding: ${({ padding }) => `${padding} !important` || 'unset'};
	margin-top: ${({ margintop }) => margintop || 'unset'};
`;

const StyledSection = styled.div<{
	width?: string;
	borderPosition?: string;
	heightSection?: string;
	flexDirection?: string;
	fontSize?: string;
	padding?: string;
	isDisableAction?: boolean;
	justify?: string;
	displayType?: string;
	bottomCustom?: string;
}>`
	width: ${({ width }) => `${width} !important` || 'unset'};
	display: ${({ displayType }) => (displayType === 'block' ? 'block' : 'flex')};
	justify-content: ${({ justify }) => justify || 'space-between'};
	align-items: center;
	flex-direction: column;
	padding: ${({ padding }) => padding || '16px 16px 16px 24px'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	height: ${({ heightSection }) => heightSection || 'auto'};
	flex-direction: ${({ flexDirection }) => flexDirection || 'unset'};
	${({ borderPosition }) =>
		borderPosition && `border-${borderPosition}:  1px solid ${THEME.colors.darkBlue3}`};
	pointer-events: ${({ isDisableAction }) => (isDisableAction ? 'none' : 'unset')};

	p {
		font-weight: ${THEME.fontWeight.bold};
		margin: 0;
		text-transform: capitalize;
	}

	&.custom-section {
		width: 90%;
		position: absolute;
		bottom: ${({ bottomCustom }) => bottomCustom || '12px'};
		background-color: ${THEME.colors.darkBlue2};
		padding: ${({ padding }) => padding || 'unset'};
	}

	&.custom-section-header {
		opacity: ${({ isDisableAction }) => (isDisableAction ? '0.6' : 'unset')};
	}

	.icon-close {
		cursor: pointer;
	}
`;

const StyledWrapper = styled(Wrapper)<{
	isOpenSider?: boolean;
	marginTop?: string;
	height?: string;
}>`
	height: ${({ height }) => height || 'unset'};
	margin-top: ${({ marginTop }) => marginTop || '50px'};
	background-color: ${THEME.colors.darkBlue};
	max-width: ${({ isOpenSider }) =>
		isOpenSider ? `calc(100% - ${defaultSiderWidth}px)` : '100%'};
	&.height_full {
		height: ${({ height }) => height || '100%'};
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: ${({ marginTop }) => marginTop || '0px'};
	}
	position: relative;
`;

const StyledTabs = styled(
	({ margin, padding, paddingContainer, border, height, borderNav, ...props }) => (
		<Tabs {...props} />
	),
)`
	position: relative;
	background: ${THEME.colors.darkBlue};
	margin: 0;
	height: ${({ height }) => height || 'calc(100vh - 130px)'};
	border: ${({ border }) => border || 'unset'};
	border-radius: ${({ border }) => (border !== '' ? '2px' : 'unset')};

	.ant-tabs-nav {
		margin-bottom: 0 !important;
		border-bottom: 1px solid ${THEME.colors.darkBlue3};
		border-left: ${({ borderNav }) => borderNav || 'unset'};
		border-right: ${({ borderNav }) => borderNav || 'unset'};
		border-top: ${({ borderNav }) => borderNav || 'unset'};

		.ant-tabs-tab {
			padding: ${({ padding }) => padding || '12px 20px'};
			margin: ${({ margin }) => margin || 'unset'};
			background: none;
			border: none;
			border-bottom: 2px solid transparent;

			&.ant-tabs-tab-active {
				border-bottom: 2px solid ${THEME.colors.primary};
				opacity: 1;

				.ant-tabs-tab-btn {
					color: ${THEME.colors.gray5};

					svg {
						margin-right: 8px;
						filter: invert(40%) sepia(100%) saturate(1737%) hue-rotate(345deg)
							brightness(93%) contrast(91%);
					}
				}
			}
		}

		.ant-tabs-nav-add {
			background: none;
			border: none;
		}

		&:before {
			border-bottom: none;
		}

		.ant-tabs-nav-wrap {
			padding: ${({ paddingContainer }) => paddingContainer || '0 0 0 15px'};
			height: 54px;
		}
	}

	&.custom-tabs {
		.ant-tabs-ink-bar {
			background: none;
		}

		.ant-tabs-nav-wrap-ping-right,
		.ant-tabs-nav-wrap-ping-left {
			width: ${defaultSiderWidth}px;
		}
	}
`;

const StyledDrawer = styled(Drawer)`
	.ant-drawer-content-wrapper {
		width: 420px !important;

		.ant-drawer-content {
			.ant-drawer-body {
				padding: 0;
			}
		}
	}
`;

const StyledBtn = styled(Button)<{
	width?: string;
	backgroundColor?: string;
	pointer?: boolean;
	padding?: string;
	margin?: string;
}>`
	width: ${({ width }) => width || '110px'};
	background: ${({ backgroundColor }) => backgroundColor || `${THEME.colors.primary}`};
	border: none;
	padding: ${({ padding }) => padding || '0'};
	margin: ${({ margin }) => margin || 'unset'};
	pointer-events: ${({ pointer }) => pointer && 'none'};
	opacity: ${({ pointer }) => (pointer ? THEME.disabledOpacity : 1)};

	&:focus,
	&:hover {
		background-color: ${THEME.colors.primary};
		color: ${THEME.colors.gray5};
	}

	&.extra-btn {
		&-hidden {
			opacity: 0;
		}

		&:hover {
			opacity: 0;

			&.extra-btn-hidden {
				opacity: 1;
			}
		}
	}
	&.hidden {
		display: none;
	}
	&.ant-btn-primary {
		&[disabled] {
			background: ${THEME.colors.orangeBase};
			opacity: 0.6;
		}
	}
	&.text_overflow {
		span {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		&.mw_50 {
			max-width: 50%;
		}
	}
`;

const StyledBtnGhost = styled(({ margin, width, isDisabled, fontSize, ...props }) => (
	<Button {...props} />
))`
	border-color: ${THEME.colors.darkBlue4};
	margin: ${({ margin }) => margin || '0'};
	width: ${({ width }) => width || 'unset'};
	font-size: ${({ fontSize }) => fontSize || 'unset'};
	pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
	opacity: ${({ isDisabled }) => (isDisabled ? THEME.disabledOpacity : 1)};

	&:hover,
	&:focus {
		svg {
			filter: invert(40%) sepia(100%) saturate(1737%) hue-rotate(345deg) brightness(93%)
				contrast(91%);
		}
	}
`;

const StyledDrawerAction = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 54px;
`;

const StyledTitle = styled.div<{
	fontSize?: string;
	color?: string;
	margin?: string;
	cursor?: string;
	border?: string;
	fontWeight?: string;
	widthIcon?: string;
	height?: string;
}>`
	display: flex;
	align-items: center;
	height: ${({ height }) => height || '24px'};
	font-stretch: normal;
	font-style: normal;
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	line-height: 1.429rem;
	letter-spacing: normal;
	margin: ${({ margin }) => margin || '0'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	cursor: ${({ cursor }) => cursor};
	${({ border }) => border && `border-${border}: 1px solid ${THEME.colors.darkBlue3}`}

	svg {
		width: ${({ widthIcon }) => widthIcon || '20px'};
		margin-right: 6px;
	}
`;

const StyledEmpty = styled(Empty)<{ color?: string }>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	${({ color }) => `color: ${color}`};
`;

const StyledSider = styled(({ margin, ...props }) => <Sider {...props} />)`
	margin-top: 50px;
	margin-left: ${({ margin }) => (margin ? '16px' : 'unset')};
	transition: width 2s;
`;

const StyledDirectLink = styled.span`
	margin: 0 6px;
	color: ${THEME.colors.blueBase};
	cursor: pointer;
	text-align: left;
	text-decoration-line: underline;
	&:hover {
		color: ${THEME.colors.orangeBase};
	}
`;

const StyledWrapperIconClose = styled.div`
	position: relative;
	width: 16px;
	height: 16px;
	display: inline-block;
	.ico_close {
		cursor: pointer;
		&:hover {
			fill: ${THEME.colors.orangeBase};
		}
	}
`;

const StyledMessageMaskAll = styled.div`
	position: fixed;
	top: 10px;
	z-index: 995;
	left: 50%;
	transform: translateX(-50%);
	background-color: ${THEME.colors.darkBlue3};
	display: inline-flex;
	align-items: center;
	min-height: 44px;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
		0 9px 28px 8px rgb(0 0 0 / 5%);
	border-radius: 2px;
	padding: 11px 16px 11px 12px;
`;

const StyledDisplayingResults = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	margin: 14px 24px 0 0;
	font-style: italic;
`;

const StyledHeaderContent = styled.div`
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: end;
	padding: 8px 24px 8px 15px;
	font-style: italic;
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
`;

export {
	StyledContent,
	StyledRow,
	StyledCol,
	StyledWrapper,
	StyledSection,
	StyledTabs,
	StyledDrawer,
	StyledBtn,
	StyledBtnGhost,
	StyledDrawerAction,
	StyledTitle,
	StyledEmpty,
	StyledSider,
	StyledHeader,
	StyledDirectLink,
	StyledWrapperIconClose,
	StyledMessageMaskAll,
	StyledDisplayingResults,
	StyledHeaderContent,
};
