import { CLASS_KEEP_SHIFT, KEYBOARD } from '@constants/common';
import { SelectionCustomRef } from '@models/common/ref';
import { KeyType } from '@models/common/summary';
import { handleSelectMultiWithLib } from '@utils/funcHelper';
import React, {
	ReactElement,
	ReactNode,
	Ref,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';

type PropTypes = {
	ids: Array<KeyType>;
	selectIds: Array<KeyType>;
	handleSelectNewList: (val: Array<KeyType>) => void;
	refSelection: any;
	noUseLibSelection?: boolean;
	elmAddClass?: HTMLElement | null;
	setKeepShift?: (val: boolean) => void;
	keepShift?: boolean;
};

const CustomSelection = forwardRef((props: PropTypes, ref: Ref<SelectionCustomRef>) => {
	const {
		ids,
		selectIds,
		handleSelectNewList,
		refSelection,
		noUseLibSelection,
		elmAddClass,
		keepShift: propKeepShift,
		setKeepShift: onSetKeepShift,
	} = props;
	const [idSelectEnd, setIdSelectEnd] = useState<KeyType | null>(null);
	const [idSelectKeepEnd, setIdSelectKeepEnd] = useState<KeyType | null>(null);

	const [keepShift, setKeepShift] = useState(false);

	const keepShiftVal = useMemo(() => {
		if (propKeepShift !== undefined) {
			return propKeepShift;
		}
		return keepShift;
	}, [propKeepShift, keepShift]);

	const onChangeShift = (val: boolean) => {
		if (onSetKeepShift && typeof onSetKeepShift === 'function') {
			onSetKeepShift(val);
			return;
		}
		setKeepShift(val);
	};
	useImperativeHandle(ref, () => ({
		isShift: keepShiftVal,
		handleSectionCustom,
	}));

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (e.key === KEYBOARD.SHIFT.STR) {
				onChangeShift(true);

				if (elmAddClass && !elmAddClass?.classList.contains(CLASS_KEEP_SHIFT)) {
					elmAddClass.classList.add(CLASS_KEEP_SHIFT);
				}
			}
		};
		window.addEventListener('keydown', onKeyDown);

		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [keepShiftVal, keepShift, propKeepShift, idSelectKeepEnd]);

	useEffect(() => {
		const onKeyup = (e: KeyboardEvent) => {
			if (e.key === KEYBOARD.SHIFT.STR) {
				onChangeShift(false);

				if (elmAddClass && elmAddClass?.classList.contains(CLASS_KEEP_SHIFT)) {
					elmAddClass.classList.remove(CLASS_KEEP_SHIFT);
				}
				if (idSelectKeepEnd) {
					setIdSelectEnd(idSelectKeepEnd);
					setIdSelectKeepEnd(null);
				}
			}
		};

		window.addEventListener('keyup', onKeyup);

		return () => {
			window.removeEventListener('keyup', onKeyup);
		};
	}, [keepShiftVal, keepShift, propKeepShift, idSelectKeepEnd]);

	// remove shift event after tab
	useEffect(() => {
		const onFocus = () => {
			if (keepShiftVal) {
				onChangeShift(false);
			}
		};
		window.addEventListener('focus', onFocus);
		return () => {
			window.removeEventListener('focus', onFocus);
		};
	}, [keepShiftVal]);

	const removeTextSelection = () => {
		if ((window as any).getSelection) {
			if ((window as any).getSelection().empty) {
				// Chrome
				(window as any).getSelection().empty();
			} else if ((window as any).getSelection().removeAllRanges) {
				// Firefox
				(window as any).getSelection().removeAllRanges();
			}
		} else if ((document as any).selection) {
			// IE?
			(document as any).selection.empty();
		}
	};

	const handleSectionCustom = (list: Array<KeyType>, idEndSelection?: KeyType) => {
		const idEnd = idEndSelection || handleCheckEndItem(list);

		if (idEndSelection) {
			handleSaveKeepEnd(idEndSelection);
		}

		if (keepShiftVal) {
			removeTextSelection();
			const listSelect = handleCheckList(idSelectEnd, idEnd);

			if (!noUseLibSelection) {
				handleSelectMultiWithLib(refSelection, listSelect, false);
			}

			handleSelectNewList(listSelect);
		}
	};

	const sortList = (list: Array<KeyType>) => {
		const result: Array<KeyType> = [];

		ids.forEach((co) => {
			if (list.includes(co)) {
				result.push(co);
			}
		});

		return result;
	};

	const handleGetIdSelecting = (list1: Array<KeyType>, list2: Array<KeyType>) => {
		const result: Array<KeyType> = [];
		list1.forEach((item) => {
			if (!list2.includes(item)) {
				result.push(item);
			}
		});
		return result;
	};

	const handleCheckEndItem = (list: Array<KeyType>) => {
		let result = idSelectEnd;
		const arrListIdNEw: Array<KeyType> =
			keepShiftVal && list.length < selectIds.length
				? handleGetIdSelecting(selectIds, list)
				: handleGetIdSelecting(list, selectIds);

		const arrSort = sortList(arrListIdNEw);

		const listConvertSort = sortList(list);

		if (arrSort.length === 1) {
			result = arrSort[0];
		}
		if (arrSort.length > 1) {
			result = arrSort[arrSort.length - 1];
		}
		if (arrSort.length === 0) {
			if (result) {
				if (!listConvertSort.some((cv) => cv === result)) {
					result = listConvertSort[0] || null;
				}
			} else {
				result = listConvertSort[0] || null;
			}
		}
		handleSaveKeepEnd(result);

		return result;
	};

	const handleSaveKeepEnd = (val: KeyType | null) => {
		if (keepShiftVal) {
			setIdSelectKeepEnd(val);
		} else {
			setIdSelectEnd(val);
			setIdSelectKeepEnd(null);
		}
	};

	const handleCheckList = (val1: number | string | null, val2: number | string | null) => {
		const isNoData = (c: any) => {
			return c === null || c === '' || c === undefined;
		};
		if (isNoData(val1) && isNoData(val2)) {
			return [];
		}
		let start = val1;
		let end = val2;

		if (isNoData(val2)) {
			start = val2;
			end = val1;
		}

		if (isNoData(start) && !isNoData(end)) {
			const idx = ids.findIndex((item: number | string) => item === end);

			if (idx !== -1) {
				return ids.slice(0, idx + 1);
			}
		}
		if (!isNoData(end) && !isNoData(start)) {
			const idxStart = ids.indexOf(start as KeyType);
			const idxEnd = ids.indexOf(end as KeyType);

			return idxStart > idxEnd
				? ids.slice(idxEnd, idxStart + 1)
				: ids.slice(idxStart, idxEnd + 1);
		}
		return [];
	};

	return null;
});

export default CustomSelection;
