import { IconPublishDetail } from '@assets/icons';
import { ROUTE_PATH } from '@constants/common';
import FilterContentContext from '@contexts/Filter/filterContent';
import { AlbumFilterTypes } from '@models/filter';
import {
	StyledIcon,
	StyledTitle,
	StyledWrapperAction,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledChip, StyledTextChip } from '@styled/Filter/FilterContentStyled';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const SubFilterAlbumInfo = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const { filterSelected } = useContext(FilterContentContext);

	return (
		<>
			{filterSelected && filterSelected?.folders.length > 0 && (
				<StyledWrapperAction margin="8px 0 0 0">
					<StyledIcon size={16}>
						<IconPublishDetail />
					</StyledIcon>
					<StyledTitle margin="0 0 0 8px" fontSize="12px" className="unset_height">
						{t('filter.filter_content.sub_filter_albums')}
					</StyledTitle>
				</StyledWrapperAction>
			)}
			<StyledWrapperTitle margin="-8px 0 14px -8px" flexWrap={'wrap'} justifyContent="left">
				{(filterSelected?.folders || []).map((folder: AlbumFilterTypes) => (
					<StyledChip key={folder.smartAlbum.id} className="custom_chip">
						<StyledTextChip
							onClick={() =>
								history.push(`${ROUTE_PATH.ALBUMS}/${folder.smartAlbum.id}`)
							}
							className={folder.galleriesAlbum.length > 0 ? 'has_arrow' : ''}>
							{folder.smartAlbum.name}
						</StyledTextChip>

						{folder.galleriesAlbum.map((ga, idx) => (
							<React.Fragment key={ga.id}>
								<StyledTextChip
									onClick={() =>
										history.push({
											pathname: ROUTE_PATH.GALLERY,
											state: { priorityId: ga.id },
										})
									}
									style={{ marginLeft: '4px' }}
									key={ga.id}>
									{ga.name}
								</StyledTextChip>
								{!idx && folder.galleriesAlbum.length > 1 && ','}
								{!!idx && idx !== folder.galleriesAlbum.length - 1 && ','}
							</React.Fragment>
						))}
					</StyledChip>
				))}
			</StyledWrapperTitle>
		</>
	);
};

export default SubFilterAlbumInfo;
