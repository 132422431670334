const BLANK_CONDITIONS = ['is blank', 'is not blank', 'are valid', 'are invalid'];

const RANGE_CONDITIONS = {
	GREATER_EQUAL: 'greater or equal',
	LESS_EQUAL: 'less than or equal',
	BETWEEN: 'between',
};

const CREATOR_STATUS = {
	IS_BLOCKED: 'IS_BLOCKED',
} as const;
const SUCCESSFUL = 'SUCCESSFUL';
const FETCHING = 'FETCHING';
const FAILED = 'FAILED';

const STATUS_FIELD = {
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
	FOLLOWED: 'Followed',
	UNSUBCRIBED: 'Unsubscribed',
};
const AVG_CONTENT_RATING = 'Avg Content Rating';
const LOCATION = 'Upload Location';
const SUBMITTED_TO_CAMPAIGN = 'Submitted to Campaign';
const IS_WITHIN = 'is within';
const STATUS = 'Status';
const LONG = 'Long';
const INTEGER = 'Integer';
const STRING = 'String';
const GENDER = 'Gender';
const DATE = 'Date';
const CONTENT_TAGS = 'Content Tags';
const CONTACTS_MATCH = 'Contacts match';
const APPROVAL_STATUS = 'Approval Status';
const CONDITION_VALUE = [
	{
		name: 'creator_segment.modal.all',
		value: 'AND',
	},
	{
		name: 'creator_segment.modal.any',
		value: 'OR',
	},
];

const FORM_NAME_SEGMENT = {
	NAME: 'create-segment',
	FIELDS: 'fields',
};

const FIELD_CONDITIONS = {
	SOCIAL_MEDIA_FOLLOWERS: 'Social Media Followers',
	LIKES_COMMENTS: 'Likes/Comments',
	ENGAGEMENT: 'Engagement Rate',
};

const BETWEEN_FIELDS = [
	FIELD_CONDITIONS.SOCIAL_MEDIA_FOLLOWERS,
	FIELD_CONDITIONS.LIKES_COMMENTS,
	FIELD_CONDITIONS.ENGAGEMENT,
];

const GENDER_VALUE = [
	{
		name: 'creator_segment.modal.male',
		value: 'male',
	},
	{
		name: 'creator_segment.modal.female',
		value: 'female',
	},
	{
		name: 'creator_segment.modal.other',
		value: 'other',
	},
];

const STATUS_VALUE = [
	{
		name: 'creator_segment.modal.active',
		value: 'active',
	},
	{
		name: 'creator_segment.modal.inactive',
		value: 'inactive',
	},
	{
		name: 'creator_segment.modal.followed',
		value: 'followed',
	},
];

const RATING_VALUE = [
	{
		name: '1',
		value: 1,
	},
	{
		name: '2',
		value: 2,
	},
	{
		name: '3',
		value: 3,
	},
	{
		name: '4',
		value: 4,
	},
	{
		name: '5',
		value: 5,
	},
];

const WIDTH_COLUMNS: string | number = '16%';
const RECENTLY_ADDED = 'recentlyAdded';

const WIDTH_SELECT = 240;

const DATA_INDEX = {
	STATUS: 'status',
};

const TYPE_SEGMENT = {
	DEFAULT: 'DEFAULT',
	FOLLOWING: 'Following',
	NOT_FOLLOWING: 'Not Following',
};

const TITLE_SEGMENT = {
	FOLLOWING: 'Following',
};

const FILE_NAME_IMPORT = 'EnTribe_Bulk_Import_Template';

const DEFAULT_TOGGLE_COLUMN = [
	{
		dataIndex: DATA_INDEX.STATUS,
		show: true,
	},
	{
		dataIndex: 'firstName',
		show: true,
	},
	{
		dataIndex: 'lastName',
		show: true,
	},
	{
		dataIndex: 'email',
		show: true,
	},
	{
		dataIndex: 'approvalStatus',
		show: true,
	},
	{
		dataIndex: 'instagramUsername',
		show: true,
	},
	{
		dataIndex: 'twitterUsername',
		show: true,
	},
	{
		dataIndex: 'youtubeUsername',
		show: false,
	},
	{
		dataIndex: 'tiktokUsername',
		show: false,
	},
	{
		dataIndex: 'address',
		show: false,
	},
	{
		dataIndex: 'directUploads',
		show: true,
	},
	{
		dataIndex: 'totalVotes',
		show: true,
	},
	{
		dataIndex: 'socialUploads',
		show: false,
	},
	{
		dataIndex: 'followers',
		show: false,
	},
	{
		dataIndex: 'rewards',
		show: false,
	},
	{
		dataIndex: 'totalRedeemed',
		show: false,
	},
	{
		dataIndex: 'totalCashRedeemed',
		show: false,
	},
	{
		dataIndex: 'totalCashValueAllUploads',
		show: false,
	},
	{
		dataIndex: 'avgRatingUpload',
		show: false,
	},
	{
		dataIndex: 'location',
		show: true,
	},
	{
		dataIndex: 'createAt',
		show: true,
	},
];

const BASIC_CREATOR_INFO_FIELD = {
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	EMAIL: 'email',
	PHONE: 'phone',
	MAILING_ADDRESS: 'mailingAddress',
	ADDRESS: 'address',
	ZIP_CODE: 'zipCode',
};

const ADDRESS_CREATOR_INFO_FIELD = {
	STATE: 'state',
	CITY: 'city',
	STREET: 'street',
};

const SOCIAL_CREATOR_INFO_FIELD = {
	INSTAGRAM: 'instagramUsername',
	TWITTER: 'twitterUsername',
	YOUTUBE: 'youtubeUsername',
	TIKTOK: 'tiktokUsername',
	FB_PROFILE_URL: 'facebookProfileUrl',
};

const CUSTOM_CREATOR_FIELD = {
	PROPERTIES: 'creatorCustomProperties',
	VALUES: 'values',
};

const CREATOR_PROFILE_MODAL_TYPE = {
	VIEW_DETAILS: 'VIEW_DETAILS',
	CAMPAIGN_RECOMMENDATIONS: 'CAMPAIGN_RECOMMENDATIONS',
	VIEW_MESSAGE_HISTORY_DETAIL: 'VIEW_MESSAGE_HISTORY_DETAIL',
	MANAGE_REWARDS: 'MANAGE_REWARDS',
};

const HEIGHT_HISTORY_CREATOR = 178;

const CUSTOM_FIELD_SUFFIX = '_value';

const ID_ELM_CREATOR = {
	ID_CREATOR_SCROLL: 'elm_scroll_creator',
	ID_CREATOR_MESSAGE_HISTORY: 'creator_message_history',
	ID_FIRST_HISTORY: 'elm_first_history_creator',
};

const PARAM_MESSAGE_HISTORY = {
	page: 1,
	maxRecords: 5,
};

const TRIMMED_CREATOR_INFO_FIELD = [
	BASIC_CREATOR_INFO_FIELD.EMAIL,
	...Object.values(SOCIAL_CREATOR_INFO_FIELD),
];

const DEFAULT_HISTORY = {
	page: 1,
	pageRecords: 5,
	records: [],
	totalPages: 1,
	totalRecords: 0,
};

const CREATED_SOURCE_ENUM = {
	MANUAL_IMPORT_UPLOADED: 'MANUAL_IMPORT_UPLOADED',
};

const APPROVAL_STATUS_LIST = [
	{
		id: 'status.none',
		name: 'status.none',
		value: 'NONE',
	},
	{
		id: 'status.approved',
		name: 'status.approved',
		value: 'APPROVED',
	},
	{
		id: 'status.pending',
		name: 'status.pending',
		value: 'PENDING',
	},
	{
		id: 'status.rejected',
		name: 'status.rejected',
		value: 'REJECTED',
	},
];

export {
	ADDRESS_CREATOR_INFO_FIELD,
	APPROVAL_STATUS,
	APPROVAL_STATUS_LIST,
	AVG_CONTENT_RATING,
	BASIC_CREATOR_INFO_FIELD,
	BETWEEN_FIELDS,
	BLANK_CONDITIONS,
	CONDITION_VALUE,
	CONTACTS_MATCH,
	CONTENT_TAGS,
	CREATED_SOURCE_ENUM,
	CREATOR_PROFILE_MODAL_TYPE,
	CREATOR_STATUS,
	CUSTOM_CREATOR_FIELD,
	CUSTOM_FIELD_SUFFIX,
	DATA_INDEX,
	DATE,
	DEFAULT_HISTORY,
	DEFAULT_TOGGLE_COLUMN,
	FAILED,
	FETCHING,
	FIELD_CONDITIONS,
	FILE_NAME_IMPORT,
	FORM_NAME_SEGMENT,
	GENDER,
	GENDER_VALUE,
	HEIGHT_HISTORY_CREATOR,
	ID_ELM_CREATOR,
	INTEGER,
	IS_WITHIN,
	LOCATION,
	LONG,
	PARAM_MESSAGE_HISTORY,
	RANGE_CONDITIONS,
	RATING_VALUE,
	RECENTLY_ADDED,
	SOCIAL_CREATOR_INFO_FIELD,
	STATUS,
	STATUS_FIELD,
	STATUS_VALUE,
	STRING,
	SUBMITTED_TO_CAMPAIGN,
	SUCCESSFUL,
	TITLE_SEGMENT,
	TRIMMED_CREATOR_INFO_FIELD,
	TYPE_SEGMENT,
	WIDTH_COLUMNS,
	WIDTH_SELECT,
};
