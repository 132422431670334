import THEME from '@constants/themes/themes';
import Wrapper from '@cores/Wrapper';
import { StyledSection } from '@styled/Content/ContentLibrary/ContentStyled';
import { Input, Row, Radio } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled(Wrapper)<{ subtractedHeight?: number }>`
	position: relative;
	width: 100%;
	overflow: hidden auto;
	height: ${({ subtractedHeight }) =>
		subtractedHeight ? `calc(100vh - ${subtractedHeight}px)` : 'calc(100vh - 265px)'};
	&:hover {
		&::-webkit-scrollbar {
			opacity: 1;
		}
		&::-webkit-scrollbar-thumb {
			visibility: unset;
		}
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		visibility: hidden;
	}
`;

export const StyledDynamicField = styled.div`
	font-size: 0.857rem;
	display: inline-block;
	margin: 14px 14px auto auto;
	padding: 5px 5px 5px 6px;
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue3};
	cursor: pointer;
`;

export const StyledSenderAddress = styled.div`
	display: flex;
	width: 95%;
	margin: 16px auto;
	height: 42px;
	padding: 11px 16px 11px 12px;
	border-radius: 2px;
	background-color: rgba(24, 144, 255, 0.08);
	color: ${THEME.colors.blueBase};

	.info-icon {
		cursor: pointer;
	}

	svg {
		width: 20px;
		margin-right: 10px;
		fill: ${THEME.colors.blueBase} !important;
	}
`;

export const StyledRecipient = styled.div``;

export const StyledEmailAdress = styled.div`
	display: inline-flex;
	align-items: center;
	width: fit-content;
	padding: 4px 8px;
	margin-bottom: 12px;
	border-radius: 20px;
	background-color: ${THEME.colors.darkBlue3};
	max-width: 100%;
	.account-icon {
		margin-right: 6px;
	}
`;

export const StyledWrapperEmail = styled.div`
	margin: 18px 18px 0 18px;
`;

export const StyledHeaderPanel = styled.div`
	display: flex;
	color: ${THEME.colors.gray5};
	align-items: center;

	span {
		margin-left: 6px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 70%;
	}
`;

export const StyledSubject = styled(Row)`
	height: 54px;
	background-color: ${THEME.colors.darkBlue2};
	align-items: center;
	color: ${THEME.colors.gray5};
	padding: 0 16px;

	.subject-content {
		font-weight: bold;
		color: ${THEME.colors.white};
	}
`;

export const StyledInput = styled(Input)`
	width: 100%;
	border: none;
	padding: 0;
	font-weight: bold;

	&.ant-input {
		color: ${THEME.colors.white};
	}

	&:focus {
		border: none;
		box-shadow: none;
	}
`;

export const StyledRadio = styled(Radio)<{ isDisabled?: boolean }>`
	pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};
	opacity: ${({ isDisabled }) => (isDisabled ? 0.9 : 1)};
`;

export const StyledConfirmModalContent = styled.div`
	white-space: pre-line;
`;

export const StyledPanelFooter = styled(StyledSection)`
	width: 100%;
	overflow: auto hidden;
	display: inline-flex;
	& > {
		button {
			margin-right: 8px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
`;
