import React, { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	StyledEditModal,
	StyledRowModal,
	StyledColModal,
	StyledFormEditTemplateModal,
	StyledSubject,
	StyledTemplateInput,
	StyleFlex,
	EmailTemplateLeftPanel,
} from '@styled/Messages/EmailTemplatesStyled';
import EmailTemplateRightPanel from './EmailTemplateRightPanel';
import EmailTemplateEditor from './EmailTemplateEditor';
import { LoadingWrapper } from '@cores/index';
import { EmailTemplatesDataDto } from '@models/messages/summary';
import { getUser } from '@helpers/userHelpers';
import { updateEmailTemplatesRequest } from '@stores/actions';

interface IEditTemplateModal {
	visible: boolean;
	handleCancel: () => void;
	template: EmailTemplatesDataDto;
}

const EditTemplateModal: React.FC<IEditTemplateModal> = ({
	visible = false,
	template,
	handleCancel = () => {},
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const emailTemplateEditorRef = useRef<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [bodyEmail, setBodyEmail] = useState<string>('');
	const { emailTemplate: { getEmailTemplateStatus = false } = {} } = useSelector(
		(state: any) => state.messages,
	);
	const [form] = Form.useForm();
	const { subject = '', title = '', description = '', enabled = false, body: bodyOrigin = '' } = template;

	useEffect(() => {
		form.setFieldsValue({
			subject: template.subject,
			title: template.title,
			description: template.description,
		});
	}, [template]);

	const onFinish = (value: any) => {
		const { email = '' } = getUser();
		
		const payload = {
			...template,
			...value,
			body: bodyEmail || bodyOrigin,
			enabled,
			from: email,
		};

		dispatch(updateEmailTemplatesRequest(payload));
	}

	const handleLoading = (isLoading: boolean) => {
		setIsLoading(isLoading);
	};

	const handleChangeTemplate = (body: string) => {
		setBodyEmail(body);
	};

	const insertDynamicField = (field: string) => {
		if (!_.isEmpty(emailTemplateEditorRef.current)) {
			emailTemplateEditorRef.current.insertDynamicField(field);
		}
	};

	const renderEmailTemplateEditor = () => (
		<EmailTemplateEditor
			ref={emailTemplateEditorRef}
			handleLoading={handleLoading}
			template={template}
			handleChangeTemplate={handleChangeTemplate}
		/>
	);

	return (
		<StyledEditModal
			closable={false}
			destroyOnClose
			title={t('email_templates.edit_templates_modal.edit_templates_modal_title')}
			visible={visible}
			onCancel={handleCancel}
			footer={null}>
			<StyledFormEditTemplateModal
				form={form}
				onFinish={onFinish}
				initialValues={{
					subject,
					title,
					description,
				}}>
				<LoadingWrapper isLoading={isLoading || getEmailTemplateStatus}>
					<StyledRowModal justify="space-between">
						<StyledColModal span={14}>
							<StyleFlex>
								<EmailTemplateLeftPanel>
									<StyledSubject>
										<Col span={24}>
											<Form.Item
												label={`${t(
													'email_templates.edit_templates_modal.template_subject',
												)}:`}
												name="subject">
												<StyledTemplateInput border='none' />
											</Form.Item>
										</Col>
									</StyledSubject>
									{renderEmailTemplateEditor()}
								</EmailTemplateLeftPanel>
								<div
									style={{
										width: '16px',
										height: '100%',
										backgroundColor: 'black',
									}}
								/>
							</StyleFlex>
						</StyledColModal>
						<StyledColModal span={10}>
							<EmailTemplateRightPanel
								template={template}
								handleCancel={handleCancel}
								insertDynamicField={insertDynamicField}
							/>
						</StyledColModal>
					</StyledRowModal>
				</LoadingWrapper>
			</StyledFormEditTemplateModal>
		</StyledEditModal>
	);
};

export default EditTemplateModal;
