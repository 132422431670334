import { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

// import history      from '@utils/history';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// Pages
import forgotPassword from '@pages/ForgotPassword';
import login from '@pages/Login';
import pageNotFound from '@pages/PageNotFound';
import register from '@pages/Register';

import ambassadorDashboard from '@pages/Client/AmbassadorDashboard';
import campaigns from '@pages/Client/Campaigns';
import campaignDetail from '@pages/Client/Campaigns/CampaignDetail';
import albumManager from '@pages/Client/Content/Albums';
import albumDetail from '@pages/Client/Content/Albums/AlbumDetail';
import widgetManager from '@pages/Client/Content/WidgetManager';
import settingWidgetBuilder from '@pages/Client/Content/WidgetManager/WidgetBuilder';
import archive from '@pages/Client/ContentLibrary/Archive';
import submissions from '@pages/Client/ContentLibrary/Submissions';
import creatorProfile from '@pages/Client/Creators/Profile';
import creatorsSegments from '@pages/Client/Creators/Segments';
import creatorsSummary from '@pages/Client/Creators/Summary';
import dashboard from '@pages/Client/Dashboard';
import settingHelpCenter from '@pages/Client/HelpCenter';
import emailBuilder from '@pages/Client/Messages/EmailBuilder';
import emailTemplates from '@pages/Client/Messages/EmailTemplates';
import messageLog from '@pages/Client/Messages/MessageLog';
import galleryManager from '@pages/Client/Publishing/Galleries';
import rewardsHistory from '@pages/Client/Rewards/History';
import rewardsInventory from '@pages/Client/Rewards/Inventory';
import settingsAccountUsers from '@pages/Client/Settings/AccountUsers';
import settingsGuestPortal from '@pages/Client/Settings/GuestPortal';
import settingsActivityLog from '@pages/Client/Settings/ActivityLog';
import settingsAnalytics from '@pages/Client/Settings/Analytics';
import settingsContent from '@pages/Client/Settings/Content';
import contentLicensing from '@pages/Client/Settings/ContentLicensing';
import licensingTemplateDetail from '@pages/Client/Settings/ContentLicensing/LicensingTemplateDetail';
import settingsMessages from '@pages/Client/Settings/Messages';
import settingsIntegrations from '@pages/Client/Settings/SettingIntegrations';
import settingsSocialAggregator from '@pages/Client/Settings/SettingSocialAggregator';
import settingsUserProfile from '@pages/Client/Settings/UserProfile';
import bookmarks from '@pages/Client/SocialSearch/Bookmarks';
import feedDetail from '@pages/Client/SocialSearch/Feeds';
import taskManager from '@pages/Client/TaskManager';
import downloadTermsConditions from '@pages/DownloadTermsCondition';
import landingPages from '@pages/Client/LandingPageManager';

import { ROUTE_PATH } from '@constants/common';
import { funcFirstPendo } from '@utils/common';
import { useSelector } from 'react-redux';

const Route = () => {
	const { clientSettings } = useSelector((state: any) => state.sidebar);

	useEffect(() => {
		funcFirstPendo();
	}, []);

	return (
		<BrowserRouter>
			<Switch>
				<PublicRoute path={ROUTE_PATH.LOGIN} component={login} exact />
				<PublicRoute path={ROUTE_PATH.REGISTER} component={register} exact />
				<PublicRoute path={ROUTE_PATH.FORGOT_PASSWORD} component={forgotPassword} exact />
				<PublicRoute
					path={ROUTE_PATH.DOWNLOAD_TERMS_CONDITIONS}
					component={downloadTermsConditions}
					exact
				/>
				<PublicRoute
					path={ROUTE_PATH.PREVIEW_TERMS_CONDITIONS}
					component={downloadTermsConditions}
					exact
				/>

				<PrivateRoute path={ROUTE_PATH.DASHBOARD} component={dashboard} exact />
				<PrivateRoute path={ROUTE_PATH.TASK_MANAGER} component={taskManager} exact />
				<PrivateRoute path={ROUTE_PATH.FEEDS} component={feedDetail} exact />
				<PrivateRoute path={ROUTE_PATH.FEED_DETAIL} component={feedDetail} exact />

				{/* <PrivateRoute path={ROUTE_PATH.SOCIAL_SEARCH} component={socialSearch} exact /> */}
				<PrivateRoute path={ROUTE_PATH.BOOKMARKS} component={bookmarks} exact />
				<PrivateRoute path={ROUTE_PATH.SUBMISSIONS} component={submissions} exact />
				<PrivateRoute path={ROUTE_PATH.FAVORITE} component={albumDetail} exact />
				<PrivateRoute path={ROUTE_PATH.ARCHIVE} component={archive} exact />
				<PrivateRoute path={ROUTE_PATH.ALBUMS} component={albumManager} exact />
				<PrivateRoute path={ROUTE_PATH.ALBUM_DETAIL} component={albumDetail} exact />
				<PrivateRoute path={ROUTE_PATH.CAMPAIGNS} component={campaigns} exact />
				<PrivateRoute path={ROUTE_PATH.CAMPAIGN_DETAIL} component={campaignDetail} exact />
				<PrivateRoute path={ROUTE_PATH.EMAIL_BUILDER} component={emailBuilder} exact />
				{clientSettings?.ambassadorEnabled && (
					<PrivateRoute
						path={ROUTE_PATH.AMBASSADOR_DASHBOARD}
						component={ambassadorDashboard}
						exact
					/>
				)}
				<PrivateRoute
					path={ROUTE_PATH.CREATORS_SUMMARY}
					component={creatorsSummary}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.CREATORS_SEGMENTS}
					component={creatorsSegments}
					exact
				/>
				<PrivateRoute path={ROUTE_PATH.CREATOR_DETAIL} component={creatorProfile} exact />
				<PrivateRoute path={ROUTE_PATH.MESSAGE_LOG} component={messageLog} exact />
				<PrivateRoute path={ROUTE_PATH.EMAIL_TEMPLATES} component={emailTemplates} exact />
				<PrivateRoute
					path={ROUTE_PATH.REWARDS_INVENTORY}
					component={rewardsInventory}
					exact
				/>
				<PrivateRoute path={ROUTE_PATH.REWARDS_HISTORY} component={rewardsHistory} exact />
				<PrivateRoute path={ROUTE_PATH.GALLERY} component={galleryManager} exact />
				<PrivateRoute path={ROUTE_PATH.WIDGET_MANAGER} component={widgetManager} exact />
				<PrivateRoute path={ROUTE_PATH.LANDING_PAGES} component={landingPages} exact />

				<PrivateRoute
					path={ROUTE_PATH.SETTINGS_ACCOUNT_USERS}
					component={settingsAccountUsers}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.SETTING_GUEST_PORTALS}
					component={settingsGuestPortal}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.SETTINGS_USER_PROFILE}
					component={settingsUserProfile}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.SETTINGS_ANALYTICS}
					component={settingsAnalytics}
					exact
				/>
				{/* <PrivateRoute
					path={ROUTE_PATH.SETTINGS_SOCIAL_SEARCH}
					component={settingsSocialSearch}
					exact
				/> */}
				<PrivateRoute
					path={ROUTE_PATH.SETTINGS_SOCIAL_AGGREGATOR}
					component={settingsSocialAggregator}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.SETTINGS_INTEGRATIONS}
					component={settingsIntegrations}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.CONTENT_LICENSING}
					component={contentLicensing}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.CONTENT_LICENSING}
					component={contentLicensing}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.CONTENT_LICENSING_DETAIL}
					component={licensingTemplateDetail}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.SETTINGS_CONTENT}
					component={settingsContent}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.SETTINGS_MESSAGES}
					component={settingsMessages}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.SETTINGS_ACTIVITY_LOG}
					component={settingsActivityLog}
					exact
				/>
				<PrivateRoute
					path={ROUTE_PATH.WIDGET_DETAIL}
					component={settingWidgetBuilder}
					exact
				/>
				<PrivateRoute path={ROUTE_PATH.HELP_CENTER} component={settingHelpCenter} exact />
				{/* Default Route */}
				<PublicRoute path={ROUTE_PATH.DEFAULT} component={login} exact />
				{/* Page Not Found */}
				<PublicRoute path={ROUTE_PATH.ERROR} component={pageNotFound} exact />
				<PublicRoute component={pageNotFound} />
			</Switch>
		</BrowserRouter>
	);
};

export default Route;
