import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
import { IconAccount } from '@assets/icons';
import SkinCustom from '@assets/styles/components/messages/skin-email-builder/skin.min.css';
import { tinyMCEAPIKey } from '@config/index';
import { CSS_STYLE, KEYBOARD } from '@constants/common';
import { SPECIAL_CHARS } from '@constants/settings';
import { ELEMENT_SELECTOR } from '@constants/taskManager';
import THEME from '@constants/themes/themes';
import { uploadImageTinyMCE } from '@helpers/base64Helper';
import { StyledIcon } from '@styled/Common/CommonStyled';
import {
	StyledMentionDropdown,
	StyledMentionDropdownItem,
} from '@styled/TaskManager/TaskManagerStyled';
import { Editor } from '@tinymce/tinymce-react';
import { autoFocusAtEnd } from '@utils/editor';
import _, { escapeRegExp } from 'lodash';
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';

type PropTypes = {
	hasMentionFeat?: boolean;
	setEditorContent?: (val: string) => void;
	editorContent?: string;
	otherInit: any;
	contentStyle?: string;
	mentionList?: any[];
	fieldNames?: {
		detectionData: string;
		displayedLabel: string;
		filtered: string[];
	};
	otherSetup?: any;
	onCancel?: () => void;
	moreDependencies?: any[];
	onSaveWithEnter?: any;
	isOtherConditionsToSave?: boolean;
	isPressingShiftToEnter?: boolean;
	callbackInit?: () => void;
	isPressingEnterToSave?: boolean;
	[other: string]: any;
};

const EditorCustom = forwardRef((props: PropTypes, ref: any) => {
	const {
		setEditorContent,
		editorContent,
		otherInit = {},
		contentStyle = '',
		mentionList,
		fieldNames,
		otherSetup,
		hasMentionFeat = false,
		onCancel,
		moreDependencies = [],
		onSaveWithEnter,
		isOtherConditionsToSave = true,
		isPressingShiftToEnter = false,
		callbackInit,
		isPressingEnterToSave = false,
		...other
	} = props;
	const editorRef = useRef<any>({});
	const [mentionDropdownVisible, setMentionDropdownVisible] = useState(false);
	const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
	const [currentMentionText, setCurrentMentionText] = useState<string>('');
	const [changedContent, setChangedContent] = useState<string>('');

	useImperativeHandle(ref, () => ({
		clearEditorContent,
		getEditorContent,
	}));

	useEffect(() => {
		const editor = editorRef?.current;
		if (editor && !_.isEmpty(editor)) {
			const handleKeyDown = (e: any) => {
				if (e.key === KEYBOARD.ARROW_UP.STR && mentionDropdownVisible) {
					e.preventDefault();
					scrollDropdown(-1);
				} else if (e.key === KEYBOARD.ARROW_DOWN.STR && mentionDropdownVisible) {
					e.preventDefault();
					scrollDropdown(1);
				} else if (
					e.key === KEYBOARD.ENTER.STR &&
					mentionDropdownVisible &&
					selectedItemIndex !== null &&
					mentionSuggestions
				) {
					e.preventDefault();
					insertMentionIntoEditor(mentionSuggestions[selectedItemIndex]);
				} else if (
					e.key === KEYBOARD.ENTER.STR &&
					!e.shiftKey &&
					isOtherConditionsToSave &&
					isPressingEnterToSave
				) {
					if (onSaveWithEnter && typeof onSaveWithEnter === 'function') {
						onSaveWithEnter(editor);
					}
				} else if (
					((mentionSuggestions && !mentionSuggestions.length) ||
						e.key === KEYBOARD.ESCAPE.STR) &&
					mentionDropdownVisible
				) {
					hideMentionDropdown();
				}

				return null;
			};

			editor.on('keydown', handleKeyDown);

			return () => {
				editor.off('keydown', handleKeyDown);
			};
		}

		return () => {};
	}, [
		mentionDropdownVisible,
		selectedItemIndex,
		currentMentionText,
		isPressingEnterToSave,
		editorContent,
		...moreDependencies,
	]);

	const mentionSuggestions = useMemo(() => {
		let keyword = currentMentionText?.replaceAll('@', '') || '';
		keyword = keyword?.toLowerCase();
		const suggestions = mentionList;
		const filteredList = suggestions?.filter((item: any) => {
			let conditions = false;
			fieldNames?.filtered.forEach((field) => {
				conditions =
					conditions || (item[field] && item[field].toLowerCase().includes(keyword));
			});
			return conditions;
		});

		return currentMentionText ? filteredList : suggestions;
	}, [currentMentionText]);

	const scrollDropdown = (step: number) => {
		setSelectedItemIndex((prevIndex) => {
			const newIndex = prevIndex !== null ? prevIndex + step : 0;
			if (mentionSuggestions && newIndex >= 0 && newIndex < mentionSuggestions.length) {
				const item = document.getElementById(
					`${ELEMENT_SELECTOR.CUSTOM_MENTION_DROPDOWN_ID}_item_${newIndex}`,
				);
				if (item) {
					const dropdown = document.getElementById(
						ELEMENT_SELECTOR.CUSTOM_MENTION_DROPDOWN_ID,
					);
					if (dropdown) {
						const dropdownRect = dropdown.getBoundingClientRect();
						const itemRect = item.getBoundingClientRect();

						if (step === 1 && itemRect.bottom > dropdownRect.bottom) {
							dropdown.scrollTop += itemRect.bottom - dropdownRect.bottom;
						} else if (step === -1 && itemRect.top < dropdownRect.top) {
							dropdown.scrollTop -= dropdownRect.top - itemRect.top;
						}
					}
				}

				return newIndex;
			} else {
				return prevIndex;
			}
		});
	};

	const getCurrentWordBeforeAndAfter = (editor: any) => {
		const selection = editor.selection;
		const range = selection.getRng();

		// Get the text content from the beginning of the current word to the cursor position
		const textBeforeCursor = range.startContainer.textContent.substring(0, range.startOffset);

		// Get the text content from the cursor position to the end of the current word
		const textAfterCursor = range.startContainer.textContent.substring(range.startOffset);

		// Use regular expressions to extract the last word before and after the cursor
		const matchBefore = textBeforeCursor.match(/\b(\w+)$/);
		const matchAfter = textAfterCursor.match(/^(\w+)/);

		// If there's a match, the last word is in match[1]
		const currentWordBefore = matchBefore ? matchBefore[1] : '';
		const currentWordAfter = matchAfter ? matchAfter[1] : '';

		return {
			currentWordBefore,
			currentWordAfter,
		};
	};

	const handleEditorContent = (content: string) => {
		if (setEditorContent && typeof setEditorContent === 'function') {
			setEditorContent(content);
		}
	};

	const handleKeyDownInnerEditor = (e: React.KeyboardEvent) => {
		const dropdown = document.getElementById(ELEMENT_SELECTOR.CUSTOM_MENTION_DROPDOWN_ID);
		if (dropdown?.style.getPropertyValue('display') !== CSS_STYLE.DISPLAY.BLOCK) {
			if (e.key === SPECIAL_CHARS.AT) {
				showMentionDropdown();
			}
			if (e.key === KEYBOARD.ESCAPE.STR) {
				if (onCancel) {
					onCancel();
				}
			}
			if (e.key === KEYBOARD.ENTER.STR && !e.shiftKey && isPressingEnterToSave) {
				e.preventDefault();
			}
		} else {
			if (
				e.key === KEYBOARD.ENTER.STR ||
				e.key === KEYBOARD.ARROW_DOWN.STR ||
				e.key === KEYBOARD.ARROW_UP.STR
			) {
				e.preventDefault();
			}
		}
	};

	const showMentionDropdown = () => {
		const editor = editorRef.current;
		if (editor && hasMentionFeat) {
			const cursorPosition = editor.selection.getRng();
			const rect = cursorPosition.getBoundingClientRect();
			const { currentWordBefore, currentWordAfter } = getCurrentWordBeforeAndAfter(editor);

			if (!currentWordBefore && !currentWordAfter) {
				// The current word start with @
				setMentionDropdownVisible(true);
				const dropdown = document.getElementById(
					ELEMENT_SELECTOR.CUSTOM_MENTION_DROPDOWN_ID,
				);
				if (mentionSuggestions?.length) {
					setSelectedItemIndex(0);
					if (dropdown) {
						const top = rect.top + window.scrollY + 40;
						let left = rect.left + window.scrollX + 40;
						dropdown.style.display = 'block';
						dropdown.style.top = top + 'px';
						if (rect.left + window.scrollX + 40 > 110) {
							left = rect.left + window.scrollX - 120;
						}
						if (left < 0) {
							left = 0;
						}
						dropdown.style.left = left + 'px';
					}
				} else {
					setMentionDropdownVisible(false);
				}
			}
		}
	};

	const insertMentionIntoEditor = (mention: any) => {
		const editor = editorRef.current;
		const currentTimeStamp = new Date().valueOf();

		// Set initial content with HTML and inline CSS
		const htmlContent: any = `<span class="mceNonEditable mentioned_item" contenteditable="false" data-${
			fieldNames?.detectionData
		}=${mention[`${fieldNames?.detectionData}`]} data-time-stamp=${currentTimeStamp}>@${
			mention[`${fieldNames?.displayedLabel}`]
		}</span>`;

		if (editor) {
			const selection = editor.selection.getSel();
			const range = selection.getRangeAt(0);
			const cursorPosition = range.startOffset;
			const regexPattern = new RegExp(`${escapeRegExp(currentMentionText)}$`);

			// Get the content before and after the cursor position
			const contentBefore = range.startContainer.textContent.substring(0, cursorPosition);
			const contentAfter = range.startContainer.textContent.substring(range.endOffset);

			let trimmedContent = contentBefore;
			trimmedContent = trimmedContent.replace(regexPattern, '');
			const updatedContent = trimmedContent + htmlContent + contentAfter;

			// Set the updated content at the cursor position
			range.startContainer.textContent = '';
			editor.selection.setContent(`${updatedContent}`);
		}

		hideMentionDropdown();
	};

	const clearEditorContent = () => {
		if (editorRef.current) {
			editorRef.current.setContent('');
		}
	};

	const getEditorContent = (type?: string) => {
		let content = '';
		if (!_.isEmpty(editorRef.current) && editorRef.current) {
			content = editorRef?.current?.getContent(type ? { format: type } : {}) || '';
		}
		return content;
	};

	const hideMentionDropdown = () => {
		setMentionDropdownVisible(false);
		setSelectedItemIndex(null);
	};

	const mentionStyles = `.mentioned_item {
		color: ${THEME.colors.gray5}; 
		background-color: rgba(255,255,255,0.2); 
		border-radius: 20px; padding: 0px 0.4em 2px 0.4em; 
		pointer-events: none; 
		line-height: 1.714;
	}`;

	return (
		<>
			<Editor
				apiKey={tinyMCEAPIKey[Math.floor(Math.random() * tinyMCEAPIKey.length)]}
				onInit={(_, editor) => {
					editorRef.current = editor;
				}}
				onEditorChange={(val: string) => {
					handleEditorContent(val); // set value before changing state
					setChangedContent(val);
				}}
				value={editorContent}
				init={{
					skin_url: SkinCustom,
					content_style:
						[contentCss, contentUiCss].join('\n') +
						`body { color: ${THEME.colors.gray5}; font-size: 14px; } p { margin: 0; } a { color: ${THEME.colors.blueBase} } ${mentionStyles}`,
					body_class: 'editor_gallery',
					height: '100%',
					menubar: false,
					statusbar: false,
					object_resizing: true,
					theme_modern_toolbar_location: 'bottom',
					toolbar_location: 'bottom',
					toolbar_sticky: true,
					image_advtab: true,
					valid_children: '*[*]',
					extended_valid_elements: '*[*]',
					/* without images_upload_url set, Upload tab won't show up */
					automatic_uploads: true,
					file_picker_types: 'image',
					convert_urls: false,
					default_link_target: '_blank',
					...otherInit,
					file_picker_callback: (cb) => {
						uploadImageTinyMCE(cb);
					},
					setup: (editor: any) => {
						editor.on('init', () => {
							autoFocusAtEnd(editor);
							if (callbackInit && typeof callbackInit === 'function') {
								callbackInit();
							}
						});
						editor.on('keydown', (e: any) => {
							const keyCode = e.keyCode || e.which;
							if (
								(keyCode >= 65 && keyCode <= 90) || // Uppercase letters (A-Z)
								(keyCode >= 97 && keyCode <= 122) || // Lowercase letters (a-z)
								(keyCode >= 48 && keyCode <= 57) ||
								keyCode === 231 // Numbers (0-9) // 231 @
							) {
								setCurrentMentionText((prevText: string) => prevText + e.key); // Current mention text supports to replace after entering a mentioned item
							} else if (keyCode === 8) {
								// Backspace key pressed, update the mention text accordingly
								const newCurrentMentionText = currentMentionText.slice(0, -1);
								if (currentMentionText === '') {
									hideMentionDropdown();
								}
								setCurrentMentionText(newCurrentMentionText);
							} else if (keyCode === 32 || keyCode === 16) {
								setCurrentMentionText('');
								if (keyCode === 32) {
									hideMentionDropdown();
								}
							}
							if (
								handleKeyDownInnerEditor &&
								typeof handleKeyDownInnerEditor === 'function'
							) {
								handleKeyDownInnerEditor(e);
							}
						});
						if (otherSetup) {
							otherSetup(editor);
						}
					},
				}}
				{...other}
			/>

			{mentionDropdownVisible && !!mentionSuggestions?.length && (
				<StyledMentionDropdown id={ELEMENT_SELECTOR.CUSTOM_MENTION_DROPDOWN_ID}>
					{mentionSuggestions?.map((mention: any, index: number) => {
						const id = `${ELEMENT_SELECTOR.CUSTOM_MENTION_DROPDOWN_ID}_item_${index}`;

						return (
							<StyledMentionDropdownItem
								id={id}
								className={index === selectedItemIndex ? 'active' : ''}
								key={id}
								onClick={() => insertMentionIntoEditor(mention)}>
								<StyledIcon size={28} margin="0 6px 0 0">
									<IconAccount />
								</StyledIcon>
								{mention[`${fieldNames?.displayedLabel}`]}
							</StyledMentionDropdownItem>
						);
					})}
				</StyledMentionDropdown>
			)}
		</>
	);
});

export default EditorCustom;
