import { CaretRightOutlined } from '@ant-design/icons';
import { IconCloseXWhite } from '@assets/icons';
import DetectThumbnailContent from '@components/ContentGlobal/DetectThumbnailContent';
import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { TYPE_ACTIONS } from '@constants/common';
import { FILTER_BY, MEDIA_TYPE } from '@constants/content/contentLibrary';
import GalleryManagerContext from '@contexts/Publishing/Galleries';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { GalleryField } from '@models/publishing/galleries';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { StyledThemeGallery } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledIconPlay } from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import { getSocialS3URL } from '@utils/common';
import { checkExistExplicit } from '@utils/content';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

type PropThumbItem = {
	photo: GalleryField;
	listDefault: ContentDto[];
	items: GalleryField[];
};

const ThumbItem = (props: PropThumbItem) => {
	const { photo, items, listDefault, ...other } = props;
	const { t } = useTranslation();
	const { width } = photo;
	const { setContentIdSelected, setTypeConfirm } = useContext(GalleryManagerContext);
	// const

	const renderIconVideo = (contentItem: ContentDto) => {
		if (contentItem?.mediaType === FILTER_BY.VIDEO.value) {
			return (
				<StyledIconPlay className="icon_play">
					<CaretRightOutlined />
				</StyledIconPlay>
			);
		}
		return '';
	};

	const handleSelectUnassignContent = () => {
		setContentIdSelected(photo.id);
		setTypeConfirm(TYPE_ACTIONS.REMOVE_SINGLE_CONTENT);
	};

	return (
		<StyledThemeGallery
			style={{ width: `${width}px`, display: 'inline-flex', transition: 'all 0s' }}
			overFlow="unset"
			className={'theme-item no_animation'}>
			{renderIconVideo(photo)}

			<StyledIcon
				zIndex={1000}
				top="-10px"
				right="-8px"
				size={28}
				className="absolute ic_custom_cancel icon_hover"
				onClick={() => handleSelectUnassignContent()}>
				<IconCloseXWhite />
			</StyledIcon>
			<DetectThumbnailContent
				thumbnail={getSocialS3URL(photo?.thumbnail)}
				dynamicThumbnail={getSocialS3URL(photo?.dynamicThumbnail)}
				isVideo={photo?.mediaType === MEDIA_TYPE.VIDEO}
				contentExternalUrl={photo?.contentExternalUrl}
				social={photo?.social}
				className="placeholder_bg_img"
				callbackLoadError={(urlNew: string) => {
					if (urlNew) {
						listDefault.forEach((item, idx: number) => {
							if (item.id === photo.id) {
								item.thumbnail = urlNew;
								items[idx].thumbnail = urlNew;
								items[idx].src = urlNew;
							}
						});
					}
				}}
			/>
			{checkExistExplicit(photo?.review?.flags) && (
				<ExplicitContent zIndex={777} sizeIcon={48} fontSize="14px" />
			)}
		</StyledThemeGallery>
	);
};

export default memo(ThumbItem);
