import { IconClose, IconNoteMessage, IconTriangleWarning } from '@assets/icons';
// Icons
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import AlbumModal from '@components/Content/AlbumManager/AlbumModal';
import ShareMediaHubModal from '@components/Content/AlbumManager/ShareMediaHubModal';
import ActionBar from '@components/Content/ContentLibrary/ActionBar';
import ContentActions from '@components/Content/ContentLibrary/ContentActions';
import DownloadModal from '@components/Content/ContentLibrary/DownloadModal';
import MediaList from '@components/Content/ContentLibrary/Media/MediaList';
import MediaRecognitionModal from '@components/Content/ContentLibrary/MediaRecognitionModal';
import ModalAddFolder from '@components/Content/ContentLibrary/ModalAddFolder';
import FilterContentBookmarks from '@components/Filter/FilterBookmarks';
import FilterContent from '@components/Filter/FilterContent';
import {
	DURATION,
	FULL_RECORD,
	MESSAGE_KEY,
	OFFSET_MESSAGE_ALL,
	ROUTE_PATH,
	TOP_MESSAGE,
	TYPE_ACTIONS,
} from '@constants/common';
import {
	defaultSiderWidth,
	LIST_TYPE_ALBUM_SUGGEST,
	PARAMS_DEFAULT_ALBUMS,
	TYPE_ALBUM,
} from '@constants/content/albums';
import {
	CONTENT_FOLDER,
	FOLDER,
	KEY_PARAM,
	MARK_AS,
	MOVE_TO,
	SYSTEM_FOLDER_ALL,
	SYSTEM_FOLDER_LIST,
	TYPE_COLLAPSE_ACTION,
	TYPE_FOLDER,
	UPLOADSOURCE,
} from '@constants/content/contentLibrary';
import { PARAMS_DEFAULT_CONTENT } from '@constants/publish/gallery';
import { ROLE } from '@constants/settings';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import ContentSummaryContext from '@contexts/Content/Summary';
import Header from '@cores/Header';
// Components
import { LoadingWrapper } from '@cores/index';
import { ConfirmModal } from '@cores/Modal';
import { getUser } from '@helpers/userHelpers';
import { FolderItemDto } from '@models/common/summary';
import {
	AlbumRespDto,
	AnalyzeContentDto,
	ParamRequestAlbumsDto,
} from '@models/content/albums/albumManager';
import {
	RefContentActions,
	RefContentSearch,
	refGlobalContent,
	RefMediaList,
} from '@models/content/contentLibrary/ref';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
// Models
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
} from '@models/content/contentLibrary/summary';
import { FilterTypeDto } from '@models/filter';
import { RefResetFilterContent } from '@models/filter/ref';
import { LocationStateDto } from '@models/messages/summary';
import { ParamRequestContentDto } from '@models/publishing/galleries';
import { FolderDto } from '@models/settings/content';
import { SettingRootStateDto } from '@models/settings/setting';
// Integrate API
import {
	createFolder,
	deleteMultiContentsEnd,
	deleteMultiContentsRequest,
	fetchAlbumsRequest,
	fetchContentRequest,
	fetchDeviceMakeRequest,
	fetchDeviceModelRequest,
	fetchShotCountriesRequest,
	getContentUnviewedStatusRecentlyAddedRequest,
	getDetectionLabelListRequest,
	getTemplatesRequest,
	moveContentEnd,
	moveContentRequest,
	removeAlbumsEnd,
	removeAlbumsRequest,
	resetListContentAfterUpload,
	resetStateRequest,
	reviewContentEnd,
	reviewMultiContentsEnd,
	reviewMultiContentsRequest,
	rotateImageEnd,
	updateAccountSettingEnd,
	updateAlbumSelect,
	updateContentStatusRecentlyAddedEnd,
	updateContentStatusRecentlyAddedRequest,
} from '@stores/actions';
import { updateFlags, updateLabels } from '@stores/settings/settings.actions';
import { UserRootStateDto } from '@stores/user/user.types';
import {
	StyledIcon,
	StyledIconWrapper,
	StyledLinkHref,
	StyledMarkAll,
	StyledText,
} from '@styled/Common/CommonStyled';
// Styled
import {
	StyledContent,
	StyledDirectLink,
	StyledEmpty,
	StyledMessageMaskAll,
	StyledSider,
	StyledWrapper,
	StyledWrapperIconClose,
} from '@styled/Content/ContentLibrary/ContentStyled';
import { getContentByType, updateFavoriteContentList } from '@utils/common';
import { replaceThumbContentSelect } from '@utils/funcHelper';
import { Layout, message, notification } from 'antd';
import _ from 'lodash';
import {
	forwardRef,
	memo,
	Ref,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

type PropContentGlobal = {
	namePage: string;
};

const ContentGlobal = forwardRef((props: PropContentGlobal, ref: Ref<refGlobalContent>) => {
	const { namePage } = props;

	const history = useHistory();

	const user = getUser();
	const { role } = user;
	const isEReviewer = role.toLowerCase() === ROLE.EREVIEWER.value.toLowerCase();

	const { clientSettings, folderSystem = [] } = useSelector((state: any) => state.sidebar);
	const { loadingAlbums, albums, albumDetail, albumsSuggest, statusRemoveAlbum } = useSelector(
		(state: any) => state.albumManager,
	);

	const { limitNotificationMsg = null } = useSelector((state: any) => state.notification);
	const { fetchingUser } = useSelector((state: UserRootStateDto) => state.user);

	const findItem = (name: string) => {
		const itemResult = folderSystem?.find(
			(item: FolderItemDto) => item?.name.toLowerCase() === name,
		);
		if (itemResult) {
			return {
				...itemResult,
				name: itemResult.name?.charAt(0).toUpperCase() + itemResult.name.slice(1),
			};
		}
		return null;
	};

	const folderSystemToUppercase = useMemo(() => {
		if (folderSystem?.length > 0) {
			return [findItem('selects'), findItem('submissions'), findItem('rejected')]?.filter(
				(item: any) => item?.id,
			);
		}
		return [];
	}, [folderSystem]);

	const isAlbumSuggest = useMemo(() => {
		if (albumDetail) {
			return LIST_TYPE_ALBUM_SUGGEST.includes(albumDetail?.type);
		}
		return false;
	}, [albumDetail?.id]);

	const enableShare = useMemo(() => {
		if (albumDetail) {
			const { type } = albumDetail;

			try {
				const obj = JSON.parse(albumDetail?.metadata) || {};
				return obj?.share;
			} catch (error) {
				console.log(error);
			}
		}
		return false;
	}, [albumDetail?.id, albumDetail?.metadata]);

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const filterRef = useRef<RefResetFilterContent>(null);
	const actionsRef = useRef<RefContentActions>(null);
	const actionBarRef = useRef<RefContentSearch>(null);
	const mediaListRef = useRef<RefMediaList>(null);

	const [folderList, setFolderList] = useState<any[]>([
		SYSTEM_FOLDER_ALL,
		...folderSystemToUppercase,
	]);
	const [contentList, setContentList] = useState<ContentContainerDto[]>([]);
	const [selectContentList, setSelectContentList] = useState<ContentContainerDto[]>([]);
	const [filterRequest, setFilterRequest] = useState<any>({});
	const [isAddFolder, setAddFolder] = useState<boolean>(false);

	// Folder
	const [paramsContent, setParamsContent] = useState<ParamRequestContentDto>({
		...PARAMS_DEFAULT_CONTENT,
		search: '',
	});
	const [findSimilar, setFindSimilar] = useState<boolean>(false);
	const [moveToFolder, setMoveToFolder] = useState<string>('');
	const [moveToList, setMoveToList] = useState<string[]>(MOVE_TO);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [otherLoading, setOtherLoading] = useState<boolean>(false);
	const [isDownload, setIsDownload] = useState<boolean>(false);
	const [albumList, setAlbumList] = useState<AlbumRespDto[]>([]);
	const [typeAction, setTypeAction] = useState<string>('');
	const [albumsSelect, setAlbumsSelect] = useState<AlbumRespDto[]>([]);
	const [visibleAlbum, setVisibleAlbum] = useState<boolean>(false);
	const [folderActive, setFolderActive] = useState<FolderItemDto | null>(null);
	const [typeCollapse, setTypeCollapse] = useState<string>('');
	const [showMessageRecently, setShowMessageRecently] = useState<boolean>(false);
	const [paramAlbums, setParamAlbums] = useState<ParamRequestAlbumsDto>({
		...PARAMS_DEFAULT_ALBUMS,
		maxRecords: FULL_RECORD,
	});
	const [contentIdMove, setContentIdMove] = useState<number | null>(null);
	const [visibleAlbumPopup, setVisibleAlbumPopup] = useState<string>('');
	const [visibleShareModal, setVisibleShareModal] = useState<boolean>(false);
	const [analyzeContent, setAnalyzeContent] = useState<AnalyzeContentDto>({
		visible: false,
		isAllContents: false,
	});
	const [contentDetail, setContentDetail] = useState<ContentContainerDto | null>(null);

	// Reducers
	const {
		fetchingContent,
		content,
		error,
		moveContentStatus,
		movedContent,
		deleteMultiContentsStatus,
		reviewMultiContentsStatus,
		reviewedContents,
		updateStatusRecentlyAddedStatus,
		fetchRatioResolution,
		updateAccountStatus,
		loadingTotalContent,
		totalContent,
		gridConfiguration,
		submitDetectionOrderStatus,
		analyzedContentIds,
		resetContentAfterUpload,
		rotateImgStatus,
		isRequestDetail,
		rotateImgRes,
		contentIdsRequest,
		fetchingDeviceMake,
		fetchingDeviceModel,
		fetchingShotCountries,
	} = useSelector((state: ContentRootStateDto) => state.content);
	const {
		loadingFolder,
		folders = [],
		addingFolder,
	} = useSelector((state: SettingRootStateDto) => state.settings.content);
	const [customFolderNames, setCustomFolderNames] = useState<string[]>([]);

	const typeContent = useMemo(() => {
		return {
			isBookmark: namePage === CONTENT_FOLDER.BOOKMARKS,
			isSubmission: namePage === CONTENT_FOLDER.SUBMISSIONS,
			isArchive: namePage === CONTENT_FOLDER.ARCHIVE,
			isFavorite: namePage === CONTENT_FOLDER.FAVORITES,
			isAlbumDetail: namePage === CONTENT_FOLDER.ALBUM_DETAIL,
		};
	}, [namePage]);

	const isVerify = useMemo(() => {
		return albumDetail?.type === TYPE_ALBUM.VERIFIED_ALBUM && typeContent.isAlbumDetail;
	}, [typeContent, albumDetail?.type]);

	// notification
	const { state: statePath, pathname } = useLocation<LocationStateDto>();
	const location = useLocation<LocationStateDto>();

	const mediaIdParam = useMemo(() => {
		if (location.search.includes(KEY_PARAM.MEDIA_ID)) {
			const result = location.search.slice(`?${KEY_PARAM.MEDIA_ID}=`?.length);
			return result;
		}
		return null;
	}, [typeContent, pathname]);

	const contentIdSelect = useMemo(() => {
		return selectContentList?.map((itemContent: ContentContainerDto) => {
			const resultItem: ContentDto = getContentByType(itemContent);
			return resultItem?.id;
		});
	}, [selectContentList]);
	// Hook

	useEffect(() => {
		if (pathname.includes(ROUTE_PATH.ALBUMS)) {
			const id = pathname?.slice(pathname.lastIndexOf('/') + 1);

			if (pathname && id) {
				if (pathname.includes(ROUTE_PATH.FAVORITE)) {
					const favoriteAlbum = folderSystem?.find(
						(item: FolderItemDto) =>
							item?.type.toLowerCase() === TYPE_ALBUM.FAVORITE_ALBUM,
					);
					const idFavorite = (favoriteAlbum?.id || '').toString();

					if (idFavorite !== paramsContent?.folder) {
						setParamsContent((prev) => ({
							...prev,
							folder: idFavorite,
							page: 1,
							search: '',
						}));
						handleClearAll();
					}
				} else if (paramsContent?.folder !== id) {
					handleClearAll();
					setParamsContent((prev) => ({ ...prev, folder: id, page: 1, search: '' }));
				}
			}
		}
	}, [folderSystem, pathname]);

	useEffect(() => {
		if (albums) {
			setAlbumList([...albums]);
		}
	}, [albums]);

	const handleClearAll = () => {
		handleClearAction();
		handleResetFilter();
		handleResetSearch();
		setFilterRequest({});
	};

	useEffect(() => {
		if (paramsContent?.folder && (albums?.length > 0 || albumsSuggest?.length > 0)) {
			const albumDetailChange = [...albumsSuggest, ...albums]?.find(
				(album: AlbumRespDto) => album?.id === +paramsContent?.folder,
			);
			if (albumDetailChange) {
				dispatch(updateAlbumSelect(albumDetailChange));
			}
		}
	}, [paramsContent?.folder, albums, albumsSuggest]);

	useEffect(() => {
		if (namePage) {
			if (!typeContent.isAlbumDetail) {
				const folderSelect = folderSystem?.find(
					(item: FolderItemDto) => item?.name.toLowerCase() === namePage.toLowerCase(),
				);
				const folderId: string = folderSelect ? folderSelect?.id?.toString() : '';

				if (folderId && folderId !== paramsContent?.folder) {
					setFolderActive(folderSelect);

					setTimeout(() => {
						setParamsContent({ ...paramsContent, folder: folderId });
					}, 0);
				}
			}
		}

		return () => {
			setFolderActive(null);
		};
	}, [namePage, folderSystem]);

	useEffect(() => {
		const otherPayloadContent: any = {};
		if (typeContent.isBookmark) {
			otherPayloadContent.contentSources = [UPLOADSOURCE.SOCIAL_SEARCH_UPCASE];
		}

		const { folder } = paramsContent;

		if (folder) {
			if (
				folder !== SYSTEM_FOLDER_ALL.id ||
				(folder === SYSTEM_FOLDER_ALL.id && !_.isEmpty(filterRequest))
			) {
				dispatch(
					fetchContentRequest({
						...paramsContent,
						isAdvancedSearch:
							!typeContent.isBookmark && isEReviewer ? findSimilar : false,
						...filterRequest,
						...otherPayloadContent,
					}),
				);
			}
		}
		if (paramsContent?.page === 1) {
			handleClearSelect();
			setContentList([]);
		}
	}, [paramsContent, filterRequest]);

	useEffect(() => {
		let payload = {};
		if (typeContent.isBookmark) {
			payload = { isDirectUpload: false };
		}
		if (typeContent.isSubmission) {
			payload = { isDirectUpload: true };
		}
		if (Object.keys(payload)?.length > 0) {
			dispatch(getContentUnviewedStatusRecentlyAddedRequest(payload));
		}
	}, [typeContent]);

	useEffect(() => {
		if (typeContent.isSubmission && resetContentAfterUpload) {
			setParamsContent({ ...paramsContent, page: 1 });
			dispatch(resetListContentAfterUpload(false));
		}
	}, [typeContent, resetContentAfterUpload]);

	useEffect(() => {
		dispatch(getTemplatesRequest());
		if (!typeContent.isBookmark) {
			dispatch(fetchDeviceMakeRequest());
			dispatch(fetchDeviceModelRequest());
			dispatch(fetchShotCountriesRequest());
		}

		return () => {
			notification.close(MESSAGE_KEY.NOTIFICATION_LIMIT_CONTENT);
			onCloseMessageMarkAll();

			dispatch(resetStateRequest());
			setFolderActive(null);
			message.config({
				top: TOP_MESSAGE,
			});

			setParamsContent({
				...PARAMS_DEFAULT_CONTENT,
				search: '',
			});
			setFindSimilar(false);
			setFilterRequest({});
			dispatch(updateAlbumSelect(null));
		};
	}, []);

	useEffect(() => {
		if (paramsContent?.page === 1) {
			setContentList(content);
			if (mediaIdParam) {
				let blockLoop = false;

				content.forEach((item: ContentContainerDto, idx) => {
					const contentTemp: ContentDto = getContentByType(item);
					if (contentTemp?.social?.mediaId === mediaIdParam && !blockLoop) {
						setContentDetail(item);

						blockLoop = true;
					}
				});
				history.replace({
					search: '',
				});
			}
		} else {
			const newContentList = _.concat(contentList, content);
			setContentList(newContentList);
		}
	}, [content]);

	useEffect(() => {
		if (moveContentStatus === SUCCEEDED) {
			if (
				moveToFolder === FOLDER.REJECTED.toLowerCase() ||
				moveToFolder === FOLDER.UNREJECTED.toLowerCase()
			) {
				let contentListTemp: Array<ContentContainerDto> = [];
				contentListTemp = [...contentList];

				if (movedContent) {
					movedContent.forEach((content: number) => {
						contentListTemp = contentListTemp.filter((item) => {
							const contentTemp = getContentByType(item);
							return contentTemp.id !== content;
						});
					});
				}
				setContentList([...contentListTemp]);
			}
			onClose();
			handleClearSelect();
			if (moveToFolder) {
				let contentMessage = t('content_management.notification.move_success', {
					folder_name: moveToFolder,
				});

				if (moveToFolder === FOLDER.UNREJECTED.toLowerCase()) {
					contentMessage = t('content_management.notification.restore_success');
				}
				if (moveToFolder === FOLDER.REJECTED.toLowerCase()) {
					contentMessage = t('content_management.notification.archive_success');
				}
				message.success(contentMessage);
			}
		}

		if (moveContentStatus === FAILED || moveContentStatus === SUCCEEDED) {
			setIsLoading(false);
			onClose();
			dispatch(moveContentEnd());
			setMoveToFolder('');
		}
	}, [moveContentStatus]);

	useEffect(() => {
		if (deleteMultiContentsStatus === SUCCEEDED) {
			handleClearSelect();

			setParamsContent((prev) => ({ ...prev, page: 1 }));
			message.success(t('content_management.notification.delete_success'));
		}

		if (deleteMultiContentsStatus === FAILED || deleteMultiContentsStatus === SUCCEEDED) {
			setIsLoading(false);
			onClose();
			dispatch(deleteMultiContentsEnd());
		}
	}, [deleteMultiContentsStatus]);

	useEffect(() => {
		if (statusRemoveAlbum === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (statusRemoveAlbum === SUCCEEDED) {
			message.success(t('albums.message.remove_success'));
			handleAfterRemoveAlbum();
		}
		if (statusRemoveAlbum === SUCCEEDED || statusRemoveAlbum === FAILED) {
			setIsLoading(false);
			dispatch(removeAlbumsEnd());
		}
	}, [statusRemoveAlbum]);

	useEffect(() => {
		const listStatus = [moveContentStatus, deleteMultiContentsStatus, updateAccountStatus];
		if (listStatus?.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus?.includes(FAILED)) {
			message.error(error);
		}
	}, [moveContentStatus, deleteMultiContentsStatus, updateAccountStatus]);

	useEffect(() => {
		if (rotateImgStatus === SUCCEEDED) {
			const contentsConvert = replaceThumbContentSelect(
				contentList,
				rotateImgRes,
				!isRequestDetail,
			);

			setContentList([...contentsConvert]);
		}
		if (rotateImgStatus === SUCCEEDED || rotateImgStatus === FAILED) {
			setIsLoading(false);
			dispatch(rotateImageEnd());
		}
	}, [rotateImgStatus]);

	useEffect(() => {
		if (updateAccountStatus === SUCCEEDED || updateAccountStatus === FAILED) {
			dispatch(updateAccountSettingEnd());
			setIsLoading(false);
		}
	}, [updateAccountStatus]);

	useEffect(() => {
		if (reviewMultiContentsStatus === IN_PROGRESS) {
			setIsLoading(true);
		}

		if (reviewMultiContentsStatus === SUCCEEDED && reviewedContents) {
			if (typeAction === TYPE_ACTIONS.REMOVE_OUT_ALBUM) {
				message.success(t('albums.message.remove_success'));
				resetContentFilters();
			} else if (typeAction === TYPE_ACTIONS.ASSIGN) {
				renderMessageAssignAlbum([...albumsSelect]);
			} else {
				message.success(t('content_management.notification.review_success'));
			}

			updateAfterReviewingContent(reviewedContents);
		}
		if (reviewMultiContentsStatus === FAILED) {
			message.error(error);
			setIsLoading(false);
		}
		if (reviewMultiContentsStatus === SUCCEEDED || reviewMultiContentsStatus === FAILED) {
			dispatch(reviewMultiContentsEnd());
			setIsLoading(false);
			handleClearReview();
			setVisibleAlbum(false);
		}
	}, [reviewMultiContentsStatus]);

	useEffect(() => {
		if (!visibleAlbum && albumsSelect?.length > 0) {
			setAlbumsSelect([]);
		}
	}, [visibleAlbum]);

	useEffect(() => {
		if (limitNotificationMsg && pathname === ROUTE_PATH.SUBMISSIONS) {
			limitContentNotification();
		}
	}, [limitNotificationMsg, pathname]);

	// Update analyzed contents
	useEffect(() => {
		if (submitDetectionOrderStatus === SUCCEEDED && analyzedContentIds) {
			const contentListTemp: Array<ContentContainerDto> = [...contentList];
			contentListTemp.forEach((content: ContentContainerDto) => {
				const contentTemp = getContentByType(content);
				if (analyzedContentIds.includes(contentTemp.id)) {
					contentTemp.detected = true;
				}
			});

			setContentList([...contentListTemp]);
		}
	}, [submitDetectionOrderStatus]);

	// Handle after analyzing
	useEffect(() => {
		if (submitDetectionOrderStatus === SUCCEEDED && !analyzeContent.visible) {
			handleClearSelect();
			fetchDetectionServiceLabel();
		}
	}, [submitDetectionOrderStatus, analyzeContent]);

	useEffect(() => {
		fetchDetectionServiceLabel();
	}, [folderActive, albumDetail]);

	const fetchDetectionServiceLabel = () => {
		if (folderActive || albumDetail) {
			const folderId = folderActive?.id || albumDetail?.id;
			dispatch(getDetectionLabelListRequest({ folderId }));
		}
	};

	const limitContentNotification = () => {
		const propsMessage: any = {
			values: {},
			i18nKey: 'notification.limit_content_submission',
			components: {
				linkTo: <StyledLinkHref color={THEME.colors.orangeBase} />,
				head: <StyledText margin="0 0 16px 0" fontWeight="bold" />,
			},
		};
		notification.warning({
			message: '',
			description: <Trans t={t} {...propsMessage} />,
			key: MESSAGE_KEY.NOTIFICATION_LIMIT_CONTENT,
			closeIcon: <></>,
			duration: DURATION.S_0,
			className: 'notification_limit_content',
			icon: (
				<StyledIconWrapper margin="0 16px 0 0" width="26px" height="26px">
					<IconTriangleWarning />
				</StyledIconWrapper>
			),
		});
	};

	const handleDirectAlbum = (listAlbum: AlbumRespDto[]) => {
		message.destroy('message_assign_content');
		if (listAlbum?.length === 1) {
			history.push(`${ROUTE_PATH.ALBUMS}/${listAlbum[0]?.id}`);
		} else {
			history.push(ROUTE_PATH.ALBUMS);
		}
	};

	const renderMessageAssignAlbum = (listAlbum: AlbumRespDto[]) => {
		const nameAssign = listAlbum?.map((album) => album?.name)?.join(', ');
		let text = 'go_to_album';
		if (listAlbum?.length > 1) {
			text = 'go_to_album_manager';
		}

		const propsMessage: any = {
			values: { name: nameAssign, text: t(`albums.button.${text}`) },
			i18nKey: 'albums.message.assign_content_success',
			components: {
				linkTo: <StyledDirectLink onClick={() => handleDirectAlbum(listAlbum)} />,
			},
		};

		return message.success({
			content: <Trans t={t} {...propsMessage} />,
			duration: 3,
			key: 'message_assign_content',
		});
	};

	const updateAfterReviewingContent = (reviewedContents: ContentReviewDto[]) => {
		dispatch(reviewMultiContentsEnd());
		dispatch(reviewContentEnd());
		setIsLoading(false);

		let contentListTemp: Array<ContentContainerDto> = [];
		contentListTemp = [...contentList];

		contentListTemp.forEach((item) => {
			const content: ContentDto = getContentByType(item);

			reviewedContents.forEach((reviewed: ContentReviewDto) => {
				if (content.id === reviewed.contentId) {
					content.review = reviewed;

					content.review.isRecentlyAdded = false;
				}
			});
			const customCheck = content.review.custom;
			if (reviewedContents?.length === 1) {
				const customFieldUpdate = reviewedContents[0].custom;
				if (customFieldUpdate && customCheck) {
					customFieldUpdate.forEach((field) => {
						if (field.key && content.review.custom) {
							const idx = customCheck.findIndex((cField) => cField.key === field.key);
							if (idx !== -1) {
								const newCustoms = [...content.review.custom];
								newCustoms[idx].name = field.name;
								content.review.custom = newCustoms;
							}
						}
					});
				}
			}
		});

		if (contentIdMove) {
			contentListTemp = contentListTemp.filter((item) => {
				const contentTemp = getContentByType(item);
				return contentTemp.id !== contentIdMove;
			});
			handleClearSelect();
			setContentIdMove(null);
		}
		setContentList([...contentListTemp]);

		return null;
	};

	const isSytemNDefaultFolder = (folder: FolderDto) => {
		return (
			!folderSystemToUppercase?.some((item: FolderItemDto) => item?.id === folder?.id) &&
			(folder?.type === TYPE_FOLDER.SYSTEM || folder?.type === TYPE_FOLDER.CUSTOM)
		);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (typeCollapse === TYPE_COLLAPSE_ACTION.ACTION && selectContentList.length === 0) {
				setTypeCollapse('');
			}
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, [selectContentList]);

	useEffect(() => {
		if (loadingFolder !== SUCCEEDED || !folders) {
			return;
		}
		const folderListTemp: any[] = [SYSTEM_FOLDER_LIST[0], ...folderSystemToUppercase];
		const sortedFolders = [...folders];
		sortedFolders.sort((a, b) => a.id - b.id);
		const customFolderNamesTemp: string[] = [];
		sortedFolders.map((folder) => {
			if (isSytemNDefaultFolder(folder)) {
				customFolderNamesTemp.push(folder.name);
				folderListTemp.push({
					name: folder.name,
					type: folder.type,
					id: folder.id.toString(),
				});
			}
		});
		setCustomFolderNames(customFolderNamesTemp);
		setFolderList(folderListTemp);
		setMoveToList(getMoveToFolderList(customFolderNamesTemp));
	}, [loadingFolder, folders]);

	useEffect(() => {
		setIsLoading(false);
		if (addingFolder === FAILED) {
			message.error(t('setting.message.add_folder_failed'));
			return;
		}
		if (addingFolder === SUCCEEDED) {
			message.success(t('setting.message.add_folder_success'));
		}
	}, [addingFolder]);

	// Functions
	const handleScrollbars = (scrollbarTop: number) => {
		if (scrollbarTop >= 0.75) {
			handleLoadMore();
		}
	};

	const handleLoadMore = () => {
		if (content?.length > 0 && !fetchingContent) {
			setParamsContent((prev) => ({ ...prev, page: prev.page + 1 }));
		}
	};

	const getMoveToFolderList = (folderNames: string[] | null) => {
		const dropdownFolderList = [];
		const getFolderList = () => {
			if (folderNames && folderNames.length > 0) {
				dropdownFolderList.push(...folderNames);
			} else if (customFolderNames.length > 0) {
				dropdownFolderList.push(...customFolderNames);
			}
		};

		switch (folderActive?.name) {
			case CONTENT_FOLDER.ARCHIVE:
				dropdownFolderList.push(FOLDER.UNREJECTED);
				getFolderList();
				break;
			default:
				getFolderList();
				dropdownFolderList.push(FOLDER.REJECTED);
				break;
		}

		const tabList = dropdownFolderList.reduce((acc: string[], value: string) => {
			if (value.toLowerCase() !== folderActive?.name) {
				acc.push(value);
			}
			return acc;
		}, []);

		return tabList;
	};

	const getFolderListName = () => {
		const names: string[] = [];
		folderList.map((folder: any) => {
			names.push(folder.name);
		});
		return names;
	};

	const resetContentFilters = (isSwitchFolder?: boolean) => {
		handleResetFilter(true);
		setFilterRequest({});

		setParamsContent({ ...paramsContent, page: 1, search: '' });
		setFindSimilar(false);
	};

	const handleModalAddFolder = () => {
		setAddFolder(!isAddFolder);
	};

	const onSaveAddFolder = (folderName: string) => {
		handleModalAddFolder();
		setIsLoading(true);
		dispatch(createFolder({ folderName }));
	};

	// Drag to select
	const handleSelecting = (selectContent: Array<ContentContainerDto>) => {
		setSelectContentList(selectContent);
	};

	const handleFilters = () => {
		const val = typeCollapse !== TYPE_COLLAPSE_ACTION.FILTER ? TYPE_COLLAPSE_ACTION.FILTER : '';

		setTypeCollapse(val);
	};

	const handleOpenActions = () => {
		const val = typeCollapse !== TYPE_COLLAPSE_ACTION.ACTION ? TYPE_COLLAPSE_ACTION.ACTION : '';

		setTypeCollapse(val);
	};

	const onChangeMoveTo = (value: string) => {
		if (value) {
			setMoveToFolder(value);
		}
	};

	const handleMoveTo = () => {
		setTypeAction(TYPE_ACTIONS.MOVE_TO);
	};

	const onClose = () => {
		setIsLoading(false);
		setTypeAction('');
	};

	const handleConfirmMoveTo = () => {
		const selectContentListTemp: Array<number> = [];
		const requestData: any = { folder: moveToFolder };

		selectContentList.map((content) => {
			const contentTemp = getContentByType(content);
			selectContentListTemp.push(contentTemp.id);
		});

		if (moveToFolder === FOLDER.UNREJECTED.toLocaleLowerCase()) {
			requestData.folder = FOLDER.ALL.toLocaleLowerCase();
			requestData.unreject = true;
		}
		requestData.contents = selectContentListTemp;

		dispatch(moveContentRequest(requestData));
	};

	const onDelete = () => {
		setTypeAction(TYPE_ACTIONS.REMOVE);
	};

	const onConfirmDelete = () => {
		const selectContentListTemp: Array<number> = [];

		selectContentList.map((content) => {
			const contentTemp = getContentByType(content);
			selectContentListTemp.push(contentTemp.id);
		});

		const requestData = {
			contents: selectContentListTemp,
		};

		dispatch(deleteMultiContentsRequest(requestData));
		setIsLoading(true);
	};

	const handleActionContents = (reviewValues: ContentReviewDto) => {
		const { labels, flags, campaigns, galleries } = reviewValues;
		const { markedAs, star, note, isRemove } = reviewValues;

		const contacted = markedAs.includes(MARK_AS.CONTACTED.label);
		const verified = markedAs.includes(MARK_AS.VERIFIED.label);
		const published = markedAs.includes(MARK_AS.PUBLISHED.label);
		const affiliate = markedAs.includes(MARK_AS.AFFILIATE.label);
		const gallery = markedAs.includes(MARK_AS.GALLERY.label);
		const pending = isRemove ? true : markedAs.includes(MARK_AS.PENDING.additionalLabel);

		const objList: any = { contacted, verified, published, affiliate, gallery };
		const objArr: any = { labels, flags, campaigns, galleries };

		let requestData: any = {
			star,
			note,
			isRemove,
			contentIds: contentIdSelect,
			pending,
		};

		Object.keys(objList)?.forEach((item) => {
			if (objList[item]) {
				requestData = {
					...requestData,
					[item]: objList[item],
				};
			}
		});
		Object.keys(objArr)?.forEach((item) => {
			if (objArr[item] && objArr[item]?.length > 0) {
				requestData = {
					...requestData,
					[item]: objArr[item],
				};
			}
		});

		if (requestData.labels) {
			saveLabel(requestData.labels);
		}
		if (requestData.flags) {
			saveFlag(requestData.flags);
		}

		setIsLoading(true);
		dispatch(
			reviewMultiContentsRequest({
				params: requestData,
			}),
		);
	};
	const saveFlag = (flags: string[]) => {
		let newFlags: string[] = [...flags] || [];

		clientSettings.flags.forEach((existingFlag: string) => {
			newFlags = newFlags.filter((flag) => {
				return existingFlag !== flag;
			});
		});

		if (newFlags.length > 0) {
			const payload = newFlags.concat(clientSettings.flags);
			dispatch(updateFlags(payload));
		}
	};

	const saveLabel = (labels: string[]) => {
		let newLabels: string[] = [...labels] || [];

		clientSettings.labels.forEach((existingLabel: string) => {
			newLabels = newLabels.filter((label) => {
				return existingLabel !== label;
			});
		});

		if (newLabels.length > 0) {
			const payload = newLabels.concat(clientSettings.labels);
			dispatch(updateLabels(payload));
		}
	};

	const renderContentList = () => {
		return (
			<>
				{contentList.length > 0 ? (
					<MediaList
						ref={mediaListRef}
						mediaList={contentList}
						handleSelecting={(contentList: Array<ContentContainerDto>) =>
							handleSelecting(contentList)
						}
						colCount={gridConfiguration}
						isToggle={!!typeCollapse}
						updateContentList={updateContentList}
						handleScrollbars={handleScrollbars}
						updateAfterReviewingContent={updateAfterReviewingContent}
					/>
				) : (
					!fetchingContent && (
						<StyledWrapper height="calc(100% - 10px)" className="height_full">
							<StyledEmpty image={IconEmpty} />
						</StyledWrapper>
					)
				)}
			</>
		);
	};

	const renderSider = () => {
		let collapsed: boolean = false;
		let onCollapse: () => void;
		const isFilterTemp = typeCollapse === TYPE_COLLAPSE_ACTION.FILTER;

		const isActionsTemp = typeCollapse === TYPE_COLLAPSE_ACTION.ACTION;

		if (isFilterTemp) {
			collapsed = isFilterTemp;
			onCollapse = handleFilters;
		} else if (isActionsTemp) {
			collapsed = isActionsTemp;
			onCollapse = handleOpenActions;
		}

		return (
			<StyledSider
				width={`${defaultSiderWidth}px`}
				margin={collapsed}
				collapsedWidth="0"
				trigger={null}
				collapsible
				collapsed={!collapsed}
				onCollapse={() => onCollapse}>
				{typeContent.isBookmark ? (
					<FilterContentBookmarks
						isHidden={!isFilterTemp}
						ref={filterRef}
						closeFilters={() => setTypeCollapse('')}
						handleFilterContent={handleFilterContent}
					/>
				) : (
					<FilterContent
						isHidden={!isFilterTemp}
						ref={filterRef}
						closeFilters={() => setTypeCollapse('')}
						handleFilterContent={handleFilterContent}
						handleResetAlbum={handleResetAlbum}
						resetContentFilters={resetContentFilters}
						isVerify={isVerify}
					/>
				)}
				<ContentActions
					isHidden={!isActionsTemp}
					ref={actionsRef}
					closeActions={() => setTypeCollapse('')}
					handleActionContents={handleActionContents}
					isBookmark={typeContent.isBookmark}
					hiddenRewardAndMessage={typeContent.isAlbumDetail}
				/>
			</StyledSider>
		);
	};

	const handleRemoveContentOutAlbum = () => {
		dispatch(
			reviewMultiContentsRequest({
				params: {
					albums: [albumDetail?.id.toString()],
					isRemove: true,
					contentIds: contentIdSelect,
				},
			}),
		);
	};

	const renderConfirmModal = () => {
		let content: string = '';
		let onSubmit: () => void;
		let okText = t('button.move');
		const isOpen: boolean =
			typeAction === TYPE_ACTIONS.REMOVE ||
			typeAction === TYPE_ACTIONS.MOVE_TO ||
			typeAction === TYPE_ACTIONS.REMOVE_OUT_ALBUM ||
			typeAction === TYPE_ACTIONS.REMOVE_ALBUM;

		if (typeAction === TYPE_ACTIONS.REMOVE) {
			content = t('content_management.confirm.delete');
			onSubmit = onConfirmDelete;
			okText = t('button.delete');
		}

		if (typeAction === TYPE_ACTIONS.REMOVE_OUT_ALBUM) {
			content = t('albums.message.are_you_remove_content', {
				name: '',
			});
			okText = t('albums.button.remove');
			onSubmit = handleRemoveContentOutAlbum;
		}

		if (typeAction === TYPE_ACTIONS.MOVE_TO) {
			content = t('content_management.confirm.move_content', {
				folder_name: moveToFolder,
			});
			if (moveToFolder === FOLDER.REJECTED.toLowerCase()) {
				content = t('content_management.confirm.move_content_archive');
				okText = t('button.archive');
			}
			if (moveToFolder === FOLDER.UNREJECTED.toLowerCase()) {
				content = t('content_management.confirm.unreject_multi');
				okText = t('button.restore');
			}
			onSubmit = handleConfirmMoveTo;
		}

		if (typeAction === TYPE_ACTIONS.REMOVE_ALBUM) {
			content = t('albums.message.are_you_remove', { name: albumDetail?.name });
			okText = t('button.delete');
			onSubmit = handleRemoveAlbum;
		}

		return (
			<ConfirmModal
				confirmLoading={isLoading}
				okText={okText}
				isOpen={isOpen}
				onSubmit={() => onSubmit()}
				onClose={() => onClose()}>
				{content}
			</ConfirmModal>
		);
	};

	const updateContentList = (contentList: ContentContainerDto[]) => {
		setContentList(contentList);
	};

	const handleFilterContent = (requestData: FilterTypeDto) => {
		unstable_batchedUpdates(() => {
			setContentList([]);
			if (!_.isEmpty(requestData)) {
				handleResetSearch();
			}
			setSelectContentList([]);
			setMoveToFolder('');
			setFindSimilar(false);
			setFilterRequest({ ...requestData });

			setParamsContent((prev) => ({ ...prev, page: 1, search: '' }));
		});
	};

	const handleSearch = async (search: string) => {
		setParamsContent((prev) => ({ ...prev, page: 1, search }));

		const obj: any = {};
		if (isVerify) {
			obj.customTerms = true;
			obj.verified = true;
		}

		setFilterRequest(obj);
		handleResetFilter();
	};

	const onDownload = () => {
		setIsDownload(!isDownload);
	};

	const handleResetListAlbum = (albumRes: AlbumRespDto) => {
		if (albumRes) {
			setAlbumList([albumRes, ...albumList]);

			setAlbumsSelect([albumRes]);
			handleAssignContentToAlbum([albumRes]);
		}
	};

	const handleAssignContentToAlbum = (albums: AlbumRespDto[]) => {
		setTypeAction(TYPE_ACTIONS.ASSIGN);

		dispatch(
			reviewMultiContentsRequest({
				params: {
					albums: albums?.map((album) => album?.id?.toString()),
					isRemove: false,
					contentIds: contentIdSelect,
				},
			}),
		);
	};

	const fileNameDefault: string = '_upload source_date imported';

	const handleClearReview = () => {
		setAlbumsSelect([]);
		handleClearSelect();
		handleClearAction();
		setTypeAction('');
	};

	const handleClearAction = () => {
		if (actionsRef && actionsRef.current !== null && !_.isEmpty(actionsRef.current)) {
			actionsRef.current.resetFilters();
		}
	};

	const handleResetSearch = () => {
		if (actionBarRef?.current?.resetSearchValue) {
			actionBarRef?.current?.resetSearchValue();
		}
	};

	const handleResetFilter = (clickClear?: boolean) => {
		if (filterRef?.current?.clearFilterUsingRef) {
			filterRef?.current?.clearFilterUsingRef(!!clickClear);
		}
	};

	const handleClearSelect = () => {
		handleDeselectAllAction();
		setSelectContentList([]);
	};

	const handleDeselectAllAction = () => {
		if (mediaListRef?.current?.clearSelectionUsingRef) {
			mediaListRef.current.clearSelectionUsingRef();
		}
	};

	const handleUpdateStatusRecentlyAdded = (contentIds?: number[], isAll?: boolean) => {
		let payload;
		if (isAll) {
			let isDirectUpload: boolean = true;
			if (typeContent.isBookmark) {
				isDirectUpload = false;
			}
			payload = { isAll, isDirectUpload };
			handleUpdateAfterRecentlyAdd();
		} else {
			if (contentIds) {
				payload = { contentIds };
			}
		}
		dispatch(updateContentStatusRecentlyAddedRequest(payload));
	};

	useEffect(() => {
		if (updateStatusRecentlyAddedStatus === FAILED) {
			message.error(error);
		}
		if (
			updateStatusRecentlyAddedStatus === SUCCEEDED ||
			updateStatusRecentlyAddedStatus === FAILED
		) {
			dispatch(updateContentStatusRecentlyAddedEnd());
		}
	}, [updateStatusRecentlyAddedStatus]);

	useEffect(() => {
		dispatch(fetchAlbumsRequest({ ...paramAlbums }));
	}, [paramAlbums]);

	useEffect(() => {
		if (statePath) {
			if (statePath?.arrayId) {
				const contentIds = statePath.arrayId;

				setParamsContent((prev) => ({ ...prev, page: 1, contentIds }));
			}

			history.replace({ state: null });
		}
	}, [statePath]);

	const handleUpdateAfterRecentlyAdd = () => {
		const contentListTemp: Array<ContentContainerDto> = [...contentList];

		contentListTemp.forEach((item) => {
			const content = getContentByType(item);
			content.review.isRecentlyAdded = false;
		});

		setContentList(contentListTemp);
	};

	const handleClickMarkAll = () => {
		handleUpdateStatusRecentlyAdded(undefined, true);
		onCloseMessageMarkAll();
	};

	const onCloseMessageMarkAll = () => {
		setShowMessageRecently(false);
		message.config({
			top: TOP_MESSAGE,
		});
	};

	const handleResetAlbum = () => {
		setParamAlbums((prev) => ({ ...prev }));
	};

	const handleMessageContent = () => {
		message.config({
			top: TOP_MESSAGE + OFFSET_MESSAGE_ALL,
		});
		setShowMessageRecently(true);
	};

	useImperativeHandle(ref, () => ({
		handleMessageContent,
	}));

	const markAsFavoriteContent = (favoriteContent: ContentDto) => {
		const updatedList = updateFavoriteContentList(favoriteContent, contentList, namePage);
		setContentList([...updatedList]);
	};

	const handleUpdateAlbum = (val: any) => {
		setVisibleAlbumPopup('');
		setParamAlbums((prev) => ({ ...prev }));

		setParamsContent((prev) => ({ ...prev, page: 1 }));
	};

	const handleRemoveAlbum = () => {
		setVisibleAlbumPopup('');
		setTypeAction('');
		dispatch(removeAlbumsRequest([albumDetail?.id]));
	};

	const handleAfterRemoveAlbum = () => {
		dispatch(updateAlbumSelect(null));
		history.push(ROUTE_PATH.ALBUMS);
	};

	const propMaskMessage = {
		values: { text: t('content.mark_all_as_read') },
		i18nKey: 'content.message_mark_all',
		components: {
			linkTo: <StyledMarkAll onClick={() => handleClickMarkAll()} />,
			wrapperIcon: <StyledWrapperIconClose />,
			icon: (
				<IconClose
					onClick={onCloseMessageMarkAll}
					style={{
						height: '16px',
						width: '16px',
						position: 'absolute',
						top: 0,
						left: 0,
						cursor: 'pointer',
					}}
				/>
			),
		},
	};

	const contentContextValues = {
		updateAfterReviewingContent,
		albumList,
		handleAssignContentToAlbum,
		albumsSelect,
		setAlbumsSelect,
		visibleAlbum,
		setVisibleAlbum,
		isLoading,
		typeContent,
		contentList,
		setContentList,
		isFiltering: !_.isEmpty(filterRequest),
		resetContentFilters,
		setParamsContent,
		paramsContent,
		handleClickMarkAll,
		namePage,
		isAlbumSuggest,
		enableShare,
		setTypeAction,
		typeAction,
		markAsFavoriteContent,
		setContentIdMove,
		setVisibleAlbumPopup,
		setVisibleShareModal,
		setAnalyzeContent,
		selectContentList,
		setSelectContentList,
		contentDetail,
		setContentDetail,
		handleClearSelect,
		setIsLoading,
		contentIdSelect,
		otherLoading,
		setOtherLoading,
		findSimilar,
		setFindSimilar,
	};

	return (
		<ContentSummaryContext.Provider value={contentContextValues}>
			{showMessageRecently && (
				<StyledMessageMaskAll>
					<StyledIcon margin="0 6px 0 0">
						<IconNoteMessage />
					</StyledIcon>
					<Trans t={t} {...propMaskMessage} />
				</StyledMessageMaskAll>
			)}
			<StyledContent>
				<LoadingWrapper
					isLoading={
						fetchingUser
							? false
							: fetchingContent ||
							  isLoading ||
							  loadingAlbums ||
							  fetchRatioResolution ||
							  loadingTotalContent ||
							  otherLoading ||
							  fetchingDeviceMake ||
							  fetchingDeviceModel ||
							  fetchingShotCountries
					}>
					<Layout>
						<Header>
							<ActionBar
								ref={actionBarRef}
								selectContentList={selectContentList}
								handleFilters={handleFilters}
								handleOpenActions={handleOpenActions}
								onChangeMoveTo={(value: string) => onChangeMoveTo(value)}
								isMove={!!moveToFolder}
								handleMoveTo={handleMoveTo}
								moveToList={moveToList}
								onDelete={onDelete}
								handleSearch={handleSearch}
								onDownload={onDownload}
							/>
						</Header>
						<StyledWrapper
							style={{ height: 'calc(100vh - 130px)', paddingBottom: '12px' }}
							isOpenSider={!!typeCollapse}>
							{renderContentList()}
						</StyledWrapper>

						{renderSider()}
					</Layout>
				</LoadingWrapper>

				<ModalAddFolder
					isVisible={isAddFolder}
					folderList={getFolderListName()}
					onClose={() => handleModalAddFolder()}
					onSave={(folderName: string) => onSaveAddFolder(folderName)}
				/>

				{renderConfirmModal()}

				{isDownload && (
					<DownloadModal
						albumId={paramsContent.folder}
						fileNameDefault={fileNameDefault}
						onDownload={onDownload}
						isDownload={isDownload}
						selectContentList={selectContentList}
						clearSelection={handleDeselectAllAction}
					/>
				)}

				{!!visibleAlbumPopup && (
					<AlbumModal
						visible={!!visibleAlbumPopup}
						handleUpdateAlbum={handleUpdateAlbum}
						removeAlbum={setTypeAction}
						handleGetListAlbum={handleResetListAlbum}
						onCancel={() => setVisibleAlbumPopup('')}
						albumDetail={
							visibleAlbumPopup === TYPE_ACTIONS.EDIT_ALBUM && albumDetail
								? {
										...albumDetail,
										metadata: JSON.parse(albumDetail?.metadata),
								  }
								: null
						}
						hiddenMessage={visibleAlbumPopup === TYPE_ACTIONS.CREATE_ALBUM}
					/>
				)}

				{visibleShareModal && (
					<ShareMediaHubModal
						isAlbumDetail={visibleShareModal}
						visible={visibleShareModal}
						onCancel={() => setVisibleShareModal(false)}
						selectedAlbumIds={[albumDetail?.id]}
					/>
				)}
			</StyledContent>

			{analyzeContent.visible && (
				<MediaRecognitionModal
					visible={analyzeContent.visible}
					selectedContentList={selectContentList}
					onCancel={() => setAnalyzeContent({ visible: false, isAllContents: false })}
					isAllContents={analyzeContent.isAllContents}
				/>
			)}
		</ContentSummaryContext.Provider>
	);
});

export default memo(ContentGlobal, (prevState, nextState) => {
	if (prevState.namePage !== nextState.namePage) {
		return false;
	}
	return true;
});
