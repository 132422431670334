import { CaretRightOutlined } from '@ant-design/icons';
import {
	IconBackCarousel,
	IconInstagramWhite,
	IconNextCarousel,
	IconTwitterWhite,
} from '@assets/icons';
import ExplicitContent from '@components/ContentGlobal/ExplicitContent';
import { UNIT_VALUE } from '@constants/common';
import { FILTER_BY } from '@constants/content/contentLibrary';
import {
	DEFAULT_CENTER,
	MIN_CONTENT,
	OPTION_DETAIL_HOVER,
	SPACE_LAYOUT,
	TOTAL_COLUMN,
	TOTAL_ROW,
} from '@constants/publish/gallery';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { MetadataGalleryDto, URlAndNameInstagramTwitter } from '@models/publishing/galleries';
import { StyledIcon } from '@styled/Common/CommonStyled';
import {
	StyledBgEmpty,
	StyledIconPlay,
	StyledInfoItem,
	StyledMarkHoverThumbnail,
	StyledWrapperImageCarousel,
	StyledWrapperNextPrev,
	StyledWrapperSlider,
	StyledWrapperThumbnail,
} from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import { StyledWrapperUserName } from '@styled/Publish/Gallery/PreviewThumbnailModalStyled';
import { checkExistExplicit } from '@utils/content';
import { useWindowSize } from '@utils/customHooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import BlockThemeMosaic from '../BlockThemeMosaic';
import ImageTheme from '../ImageTheme';

type ThemeObjectType = {
	isSignage: boolean;
	isSlider: boolean;
	isGrid: boolean;
	isMosaic: boolean;
};
type PropTypes = {
	listGalleryCustom: Array<ContentDto | null>;
	refSlider: any;
	showDetailLightBox: Array<string> | null | undefined;
	setContentSelect: (val: ContentDto) => void;
	listGallery: ContentDto[];
	isScreenSmall: boolean;
	metadata: MetadataGalleryDto;
	themeType: ThemeObjectType;
	rows: number;
	theme: string;
	listGalleryMosaicCustom: ContentDto[][];
	isGif: boolean;
	showDetailOnHover: Array<string> | null | undefined;
	renderInfoUser: (content: ContentDto) => URlAndNameInstagramTwitter;
	autoScroll: boolean;
	idxCenter: number;
	setIdxCenter: (val: number) => void;
};
const SliderGallery = (props: PropTypes) => {
	const {
		listGalleryCustom,
		refSlider,
		setContentSelect,
		showDetailLightBox,
		listGallery,
		metadata,
		isScreenSmall,
		themeType,
		rows,
		theme,
		listGalleryMosaicCustom,
		isGif,
		showDetailOnHover,
		renderInfoUser,
		autoScroll,
		idxCenter,
		setIdxCenter,
	} = props;
	const [isDrag, setIsDrag] = useState<boolean>(false);

	const refWrapper = useRef<any>({});

	const [heightThumb, setHeightThumb] = useState<number>(UNIT_VALUE.S_200);
	const [autoplay, setAutoplay] = useState<boolean>(false);
	const [isFirstRender, setIsFirstRender] = useState<boolean>(false);

	const [widthWindow, heightWindow] = useWindowSize();

	const defaultCenter = useMemo(() => {
		if (isScreenSmall) {
			return DEFAULT_CENTER.MIN;
		}
		return DEFAULT_CENTER.NORMAL;
	}, [isScreenSmall]);

	const handleSelectContent = (content: ContentDto) => {
		if (!isDrag && showDetailLightBox) {
			setContentSelect(content);
		}
	};

	const handleDetectSize = () => {
		const offsetWidth = refWrapper.current.offsetWidth;

		// SPACE_LAYOUT.S_20: padding wrapper, SPACE_LAYOUT.S_12: space row
		if (themeType.isGrid) {
			const space = (settingSlider.slidesToShow - 1) * SPACE_LAYOUT.S_12 + SPACE_LAYOUT.S_20;
			const width = Math.floor((offsetWidth - space) / settingSlider.slidesToShow);

			setHeightThumb(width);
		}
		if (themeType.isMosaic || themeType.isSignage) {
			const space = TOTAL_COLUMN.S_3 * SPACE_LAYOUT.S_12 + SPACE_LAYOUT.S_20;
			const width = Math.floor(
				(offsetWidth - space) / (isScreenSmall ? TOTAL_COLUMN.S_3 : TOTAL_COLUMN.S_4),
			);

			setHeightThumb(width);
		}
		if (themeType.isSlider) {
			const width = Math.floor(
				offsetWidth / (isScreenSmall ? TOTAL_COLUMN.S_3 : MIN_CONTENT.SLIDER),
			);

			setHeightThumb(width);
		}
	};

	const beforeChange = (oldIdx: any, idx: any) => {
		const centerNow = idx + defaultCenter;
		const lengthGallery = listGallery?.length;

		if (centerNow >= lengthGallery && lengthGallery > settingSlider.slidesToShow) {
			setIdxCenter(centerNow - lengthGallery);
		} else {
			setIdxCenter(centerNow);
		}
	};

	const settings = useMemo(() => {
		if (autoplay) {
			if (refSlider?.current?.slickPlay) {
				refSlider?.current?.slickPlay();
			}
		}
		const isShowNavigationArrows = (autoplay && metadata?.navigationArrows) || !autoplay;

		let settingsTemp: any = {
			dots: false,
			infinite: listGallery?.length > MIN_CONTENT.SLIDER,
			initialSlide: 0,
			speed: 500,
			slidesToShow: isScreenSmall ? TOTAL_COLUMN.S_3 : MIN_CONTENT.SLIDER,
			adaptiveHeight: true,
			slidesToScroll: 1,
			className: 'slider_custom_gallery',
			arrows: true,
			autoplay,
			swipeToSlide: true,
			pauseOnHover: false,

			nextArrow: isShowNavigationArrows ? (
				<StyledWrapperNextPrev>
					<IconNextCarousel
						onClick={() => {
							setAutoplay(false);
						}}
					/>
				</StyledWrapperNextPrev>
			) : null,
			prevArrow: isShowNavigationArrows ? (
				<StyledWrapperNextPrev>
					<IconBackCarousel
						onClick={() => {
							setAutoplay(false);
						}}
					/>
				</StyledWrapperNextPrev>
			) : null,
		};

		if (autoplay && metadata?.autoplaySpeed) {
			const autoplaySpeed = metadata?.autoplaySpeed * 1000;
			settingsTemp = { ...settingsTemp, autoplaySpeed };
		}

		return { ...settingsTemp };
	}, [listGallery, autoplay, metadata, isScreenSmall]);

	const settingSlider = useMemo(() => {
		if (themeType.isGrid) {
			return {
				...settings,
				slidesToShow: isScreenSmall ? MIN_CONTENT.S_GRID : MIN_CONTENT.GRID,
				rows,
				infinite: listGallery?.length > MIN_CONTENT.GRID * rows,
				slidesPerRow: 1,
				centerPadding: '0px',
				adaptiveHeight: false,
			};
		}
		if (themeType.isMosaic || themeType.isSignage) {
			return {
				...settings,
				slidesToShow: 1,
				rows: 1,
				slidesPerRow: 1,
				adaptiveHeight: false,
				infinite: listGallery?.length > MIN_CONTENT.MOSAIC,
				swipeToSlide: false,
				centerPadding: '0px',
			};
		}

		return { ...settings };
	}, [theme, listGallery, listGalleryCustom, rows, settings, isScreenSmall]);

	useEffect(() => {
		if (refWrapper && refWrapper.current && refWrapper.current.offsetWidth) {
			handleDetectSize();
		}
	}, [settingSlider, widthWindow]);

	useEffect(() => {
		setAutoplay(autoScroll);
	}, [autoScroll]);

	useEffect(() => {
		if (!isFirstRender) {
			const idxFull = listGallery?.length - 1;

			if (isScreenSmall) {
				setIdxCenter(idxCenter ? idxCenter - 1 : idxFull);
			} else {
				setIdxCenter(idxCenter === idxFull ? 0 : idxCenter + 1);
			}
		}
		if (isFirstRender) {
			setIsFirstRender(false);
		}
	}, [isScreenSmall]);

	const renderClassName = (idx: number) => {
		let moreClass = 'image_carousel';
		if (listGalleryCustom?.length < settingSlider.slidesToShow) {
			return moreClass;
		}

		const lengthGallery = listGalleryCustom?.length;
		const idxCaLeft = idx + 1 >= lengthGallery ? idx + 1 - lengthGallery : idx + 1;
		const idxCaRight = (idx || lengthGallery) - 1;

		const idxImgLeft =
			idx + defaultCenter >= lengthGallery
				? idx + defaultCenter - lengthGallery
				: idx + defaultCenter;
		const idxImgRight =
			idx - defaultCenter < 0 ? lengthGallery - defaultCenter + idx : idx - defaultCenter;

		switch (idxCenter) {
			case idx:
				moreClass += ' image_carousel_center';
				break;
			case idxCaLeft:
				moreClass += ' image_carousel_left';
				break;
			case idxCaRight:
				moreClass += ' image_carousel_right';
				break;
			case idxImgLeft:
				moreClass += ' image_left';
				break;
			case idxImgRight:
				moreClass += ' image_right';
				break;

			default:
				break;
		}

		return moreClass;
	};

	const renderIconVideo = (item: ContentDto) => {
		if (item?.mediaType === FILTER_BY.VIDEO.value) {
			return (
				<StyledIconPlay className="icon_play">
					<CaretRightOutlined />
				</StyledIconPlay>
			);
		}
		return '';
	};

	const handleBlockEvent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.stopPropagation();
	};

	const renderMarkHover = (content: ContentDto) => {
		if (!showDetailOnHover) {
			return null;
		}
		const { hrefInstagram, hrefTwitter, instagramUsername, twitterUsername } =
			renderInfoUser(content);

		return (
			<StyledMarkHoverThumbnail className="mark_hover">
				{showDetailOnHover?.includes(OPTION_DETAIL_HOVER.showInstagramName) &&
					instagramUsername && (
						<StyledWrapperUserName className="size_16">
							<StyledInfoItem
								onClick={handleBlockEvent}
								target="_blank"
								className="link_info"
								href={hrefInstagram}>
								<StyledIcon>
									<IconInstagramWhite />
								</StyledIcon>
								{instagramUsername}
							</StyledInfoItem>
						</StyledWrapperUserName>
					)}
				{showDetailOnHover?.includes(OPTION_DETAIL_HOVER.showTwitterName) &&
					twitterUsername && (
						<StyledWrapperUserName className="size_16">
							<StyledInfoItem
								onClick={handleBlockEvent}
								href={hrefTwitter}
								className="link_info"
								target="_blank">
								<StyledIcon>
									<IconTwitterWhite />
								</StyledIcon>
								{twitterUsername}
							</StyledInfoItem>
						</StyledWrapperUserName>
					)}
			</StyledMarkHoverThumbnail>
		);
	};

	return (
		<StyledWrapperSlider ref={refWrapper} className="wrapper_slider">
			{listGalleryCustom?.length > 0 && (
				<Slider
					ref={refSlider}
					beforeChange={beforeChange}
					onSwipe={() => setIsDrag(true)}
					afterChange={() => setIsDrag(false)}
					{...settingSlider}>
					{(themeType.isMosaic || themeType.isSignage) &&
						listGalleryMosaicCustom?.map((item: ContentDto[], idx: number) => {
							let idxFull: number | undefined =
								((idx + 1) % TOTAL_COLUMN.MOSAIC) * TOTAL_ROW.MOSAIC;
							if (isScreenSmall) {
								if ((idx + 1) % 3 === 0) {
									idxFull = undefined;
								} else {
									if (idx % 3 === 0) {
										idxFull = 0;
									} else {
										idxFull = 2;
									}
								}
							}
							return (
								<BlockThemeMosaic
									isScreenSmall={isScreenSmall}
									idxFull={idxFull}
									heightThumb={heightThumb}
									key={Math.random()}
									listContent={item}
									isGif={isGif}
									handleSelectContent={handleSelectContent}
									renderIconVideo={renderIconVideo}
									cursor={showDetailLightBox ? 'pointer' : 'unset'}
									renderMarkHover={renderMarkHover}
									metadata={metadata}
								/>
							);
						})}

					{!themeType.isMosaic &&
						!themeType.isSignage &&
						listGalleryCustom?.map((item: ContentDto | null, idx) => {
							if (!item) {
								return (
									<StyledWrapperImageCarousel
										className={renderClassName(idx)}
										key={Math.random()}>
										<StyledWrapperThumbnail>
											<StyledBgEmpty />
										</StyledWrapperThumbnail>
									</StyledWrapperImageCarousel>
								);
							}
							return (
								<StyledWrapperImageCarousel
									className={renderClassName(idx)}
									key={item.id}>
									<StyledWrapperThumbnail
										cursor={showDetailLightBox ? 'pointer' : 'unset'}
										onClick={() => handleSelectContent(item)}>
										{renderIconVideo(item)}
										<ImageTheme content={item} isGif={isGif} />

										{renderMarkHover(item)}
										{checkExistExplicit(item?.review?.flags) && (
											<ExplicitContent
												zIndex={3335}
												sizeIcon={48}
												fontSize="14px"
											/>
										)}
									</StyledWrapperThumbnail>
								</StyledWrapperImageCarousel>
							);
						})}
				</Slider>
			)}
		</StyledWrapperSlider>
	);
};

export default SliderGallery;
