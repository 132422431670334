import { BASIC_CREATOR_INFO_FIELD, SOCIAL_CREATOR_INFO_FIELD } from '@constants/creator';
import { SOCIAL_PROFILE } from '@constants/social_search';

export const formatAddress = (address: string) => {
	let dataAddress;
	let result = { street: '', city: '', state: '' };
	try {
		dataAddress = JSON.parse(address);
	} catch (error) {
		dataAddress = address;
	}
	if (typeof dataAddress === 'object') {
		const { street = '', city = '', state = '' } = dataAddress || {};
		result = { street, city, state };
	} else {
		result = { ...result, street: dataAddress };
	}
	return result;
};

export const formatDisplayedAddress = (address: string, zipCode: string, hasStreet?: boolean) => {
	const listStr: string[] = [];
	const { street = '', city = '', state = '' } = formatAddress(address) || {};
	let str = '';

	if (hasStreet && street) {
		listStr.push(street);
	}
	if (city) {
		listStr.push(city);
	}
	if (state) {
		listStr.push(state);
	}
	if (zipCode) {
		listStr.push(zipCode);
	}
	listStr.forEach((item: string, idx: number) => {
		if (idx !== 0) {
			str = `${str}, ${item}`;
		} else {
			str = item;
		}
	});

	return str;
};

export const validateFBURL = (_: any, value: string, t: any) => {
	if (value) {
		const check = value.split('/');
		if (value.includes(SOCIAL_PROFILE.FACEBOOK) && check[3]) {
			return Promise.resolve();
		} else {
			return Promise.reject(new Error(t('creator_segment.modal.error_facebook')));
		}
	} else {
		return Promise.resolve();
	}
};

export const validateRequiredValue = (_: any, value: string, form: any, t: any) => {
	const requiredFieldList = [
		SOCIAL_CREATOR_INFO_FIELD.INSTAGRAM,
		SOCIAL_CREATOR_INFO_FIELD.TIKTOK,
		SOCIAL_CREATOR_INFO_FIELD.TWITTER,
		SOCIAL_CREATOR_INFO_FIELD.YOUTUBE,
		BASIC_CREATOR_INFO_FIELD.EMAIL,
	];

	const requiredValues = requiredFieldList.map((item) => form.getFieldValue(item));
	const existRequiredValue = requiredValues.some((item) => item);

	if (!existRequiredValue && !value) {
		return Promise.reject(new Error(t('creator_summary.modal.required')));
	}

	return Promise.resolve();
};
