import { ContainerSVG } from '@assets/icons/svg/grapesjs';

export const ContainerBlock = (bm: any, label: string) => {
	bm.add('container').set({
		label,
		media: ContainerSVG,
		content: {
			type: 'container',
			classes: ['container'],
		},
	});
};

export default (domc: any) => {
	const defaultType = domc.getType('default');
	const defaultModel = defaultType.model;
	const defaultView = defaultType.view;

	domc.addType('container', {
		model: {
			defaults: {
				'custom-name': 'Container',
				tagName: 'div',
				droppable: true,
				traits: [
					{
						type: 'class_select',
						options: [
							{ value: 'container', name: 'Fixed' },
							{ value: 'container-fluid', name: 'Fluid' },
						],
						label: 'Width',
					},
				].concat(defaultModel.prototype.defaults.traits),
			},
		},
		isComponent(el: any) {
			if (
				el &&
				el.classList &&
				(el.classList.contains('container') || el.classList.contains('container-fluid'))
			) {
				return { type: 'container' };
			}

			return false;
		},
		view: defaultView,
	});
};
