import { createAction } from 'redux-actions';

// Action constant
export const RESET_SELECTED_ROWS = 'RESET_SELECTED_ROWS';

export const CREATOR_FIND_PROFILES = 'CREATOR_FIND_PROFILES';
export const CREATOR_FIND_PROFILES_COMPLETED = 'CREATOR_FIND_PROFILES_COMPLETED';
export const CREATOR_FIND_PROFILES_FAILED = 'CREATOR_FIND_PROFILES_FAILED';

export const CREATOR_EXPORT_PROFILES = 'CREATOR_EXPORT_PROFILES';
export const CREATOR_EXPORT_PROFILES_COMPLETED = 'CREATOR_EXPORT_PROFILES_COMPLETED';
export const CREATOR_EXPORT_PROFILES_FAILED = 'CREATOR_EXPORT_PROFILES_FAILED';

export const CREATOR_GET_PROFILE = 'CREATOR_GET_PROFILE';
export const CREATOR_GET_PROFILE_COMPLETED = 'CREATOR_GET_PROFILE_COMPLETED';
export const CREATOR_GET_PROFILE_FAILED = 'CREATOR_GET_PROFILE_FAILED';

// fetch creator list lite
export const FETCH_CREATOR_LITE_REQUEST = 'FETCH_CREATOR_LITE_REQUEST';
export const FETCH_CREATOR_LITE_COMPLETED = 'FETCH_CREATOR_LITE_COMPLETED';
export const FETCH_CREATOR_LITE_FAILED = 'FETCH_CREATOR_LITE_FAILED';

export const CREATOR_CHANGE_STATUS = 'CREATOR_CHANGE_STATUS';
export const CREATOR_CHANGE_STATUS_COMPLETED = 'CREATOR_CHANGE_STATUS_COMPLETED';
export const CREATOR_CHANGE_STATUS_FAILED = 'CREATOR_CHANGE_STATUS_FAILED';

export const CREATOR_BLOCK = 'CREATOR_BLOCK';
export const CREATOR_BLOCK_COMPLETED = 'CREATOR_BLOCK_COMPLETED';
export const CREATOR_BLOCK_FAILED = 'CREATOR_BLOCK_FAILED';
export const CREATOR_BLOCK_END = 'CREATOR_BLOCK_END';

export const CREATOR_DELETE = 'CREATOR_DELETE';
export const CREATOR_DELETE_COMPLETED = 'CREATOR_DELETE_COMPLETED';
export const CREATOR_DELETE_FAILED = 'CREATOR_DELETE_FAILED';

export const CREATOR_IMPORT = 'CREATOR_IMPORT';
export const CREATOR_IMPORT_COMPLETED = 'CREATOR_IMPORT_COMPLETED';
export const CREATOR_IMPORT_FAILED = 'CREATOR_IMPORT_FAILED';

export const CREATOR_GET_SEGMENT = 'CREATOR_GET_SEGMENT';
export const CREATOR_GET_SEGMENT_COMPLETED = 'CREATOR_GET_SEGMENT_COMPLETED';
export const CREATOR_GET_SEGMENT_FAILED = 'CREATOR_GET_SEGMENT_FAILED';

export const GET_LIST_CAMPAIGN_SIMPLE_REQUEST = 'GET_LIST_CAMPAIGN_SIMPLE_REQUEST';
export const GET_LIST_CAMPAIGN_SIMPLE_COMPLETED = 'GET_LIST_CAMPAIGN_SIMPLE_COMPLETED';
export const GET_LIST_CAMPAIGN_SIMPLE_FAILED = 'GET_LIST_CAMPAIGN_SIMPLE_FAILED';

export const GET_SEGMENT = 'GET_SEGMENT';
export const GET_SEGMENT_COMPLETED = 'GET_SEGMENT_COMPLETED';
export const GET_SEGMENT_FAILED = 'GET_SEGMENT_FAILED';

export const CREATE_SEGMENT = 'CREATE_SEGMENT';
export const CREATE_SEGMENT_COMPLETED = 'CREATE_SEGMENT_COMPLETED';
export const CREATE_SEGMENT_FAILED = 'CREATE_SEGMENT_FAILED';
export const CREATE_SEGMENT_END = 'CREATE_SEGMENT_END';

export const UPDATE_SEGMENT = 'UPDATE_SEGMENT';
export const UPDATE_SEGMENT_COMPLETED = 'UPDATE_SEGMENT_COMPLETED';
export const UPDATE_SEGMENT_FAILED = 'UPDATE_SEGMENT_FAILED';
export const UPDATE_SEGMENT_END = 'UPDATE_SEGMENT_END';

export const CREATOR_RESET_UPLOAD_AWS = 'CREATOR_RESET_UPLOAD_AWS';

export const UPDATE_STATUS_RECENTLY_ADDED_REQUEST = 'UPDATE_STATUS_RECENTLY_ADDED_REQUEST';
export const UPDATE_STATUS_RECENTLY_ADDED_COMPLETED = 'UPDATE_STATUS_RECENTLY_ADDED_COMPLETED';
export const UPDATE_STATUS_RECENTLY_ADDED_FAILED = 'UPDATE_STATUS_RECENTLY_ADDED_FAILED';
export const UPDATE_STATUS_RECENTLY_ADDED_END = 'UPDATE_STATUS_RECENTLY_ADDED_END';

export const GET_LOCATION_CREATOR_REQUEST = 'GET_LOCATION_CREATOR_REQUEST';
export const GET_LOCATION_CREATOR_SUCCEEDED = 'GET_LOCATION_CREATOR_SUCCEEDED';
export const GET_LOCATION_CREATOR_FAILED = 'GET_LOCATION_CREATOR_FAILED';

export const GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST =
	'GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST';
export const GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_SUCCEEDED =
	'GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_SUCCEEDED';
export const GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_FAILED =
	'GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_FAILED';
export const GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_END =
	'GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_END';

export const UPDATE_CREATOR_PROFILE_REQUEST = 'UPDATE_CREATOR_PROFILE_REQUEST';
export const UPDATE_CREATOR_PROFILE_SUCCEEDED = 'UPDATE_CREATOR_PROFILE_SUCCEEDED';
export const UPDATE_CREATOR_PROFILE_FAILED = 'UPDATE_CREATOR_PROFILE_FAILED';
export const UPDATE_CREATOR_PROFILE_END = 'UPDATE_CREATOR_PROFILE_END';

export const INVALIDATE_SOCIAL_USERNAME_REQUEST = 'INVALIDATE_SOCIAL_USERNAME_REQUEST';
export const INVALIDATE_SOCIAL_USERNAME_SUCCEEDED = 'INVALIDATE_SOCIAL_USERNAME_SUCCEEDED';
export const INVALIDATE_SOCIAL_USERNAME_FAILED = 'INVALIDATE_SOCIAL_USERNAME_FAILED';
export const INVALIDATE_SOCIAL_USERNAME_END = 'INVALIDATE_SOCIAL_USERNAME_END';

export const GET_COMPLETED_ACTIONS_REQUEST = 'GET_COMPLETED_ACTIONS_REQUEST';
export const GET_COMPLETED_ACTIONS_SUCCEEDED = 'GET_COMPLETED_ACTIONS_SUCCEEDED';
export const GET_COMPLETED_ACTIONS_FAILED = 'GET_COMPLETED_ACTIONS_FAILED';
export const GET_COMPLETED_ACTIONS_END = 'GET_COMPLETED_ACTIONS_END';

export const GET_SOCIAL_STATS_REQUEST = 'GET_SOCIAL_STATS_REQUEST';
export const GET_SOCIAL_STATS_SUCCEEDED = 'GET_SOCIAL_STATS_SUCCEEDED';
export const GET_SOCIAL_STATS_FAILED = 'GET_SOCIAL_STATS_FAILED';
export const GET_SOCIAL_STATS_END = 'GET_SOCIAL_STATS_END';

export const REFRESH_SOCIAL_STATS_REQUEST = 'REFRESH_SOCIAL_STATS_REQUEST';
export const REFRESH_SOCIAL_STATS_SUCCEEDED = 'REFRESH_SOCIAL_STATS_SUCCEEDED';
export const REFRESH_SOCIAL_STATS_FAILED = 'REFRESH_SOCIAL_STATS_FAILED';
export const REFRESH_SOCIAL_STATS_END = 'REFRESH_SOCIAL_STATS_END';

export const FETCH_MESSAGE_HISTORY_CREATOR_REQUEST = 'FETCH_MESSAGE_HISTORY_CREATOR_REQUEST';
export const FETCH_MESSAGE_HISTORY_CREATOR_SUCCEEDED = 'FETCH_MESSAGE_HISTORY_CREATOR_SUCCEEDED';
export const FETCH_MESSAGE_HISTORY_CREATOR_FAILED = 'FETCH_MESSAGE_HISTORY_CREATOR_FAILED';

// Store creator profile data
export const STORE_CREATOR_DATA = 'STORE_CREATOR_DATA';

// download template
export const downloadTemplateTypes = {
	DOWNLOAD_TEMPLATE_CREATOR_REQUEST: 'DOWNLOAD_TEMPLATE_CREATOR_REQUEST',
	DOWNLOAD_TEMPLATE_CREATOR_SUCCEEDED: 'DOWNLOAD_TEMPLATE_CREATOR_SUCCEEDED',
	DOWNLOAD_TEMPLATE_CREATOR_FAILED: 'DOWNLOAD_TEMPLATE_CREATOR_FAILED',
	DOWNLOAD_TEMPLATE_CREATOR_END: 'DOWNLOAD_TEMPLATE_CREATOR_END',
};

// Update approval status
export const updateApprovalStatusTypes = {
	UPDATE_APPROVAL_STATUS_REQUEST: 'UPDATE_APPROVAL_STATUS_REQUEST',
	UPDATE_APPROVAL_STATUS_SUCCEEDED: 'UPDATE_APPROVAL_STATUS_SUCCEEDED',
	UPDATE_APPROVAL_STATUS_FAILED: 'UPDATE_APPROVAL_STATUS_FAILED',
	UPDATE_APPROVAL_STATUS_END: 'UPDATE_APPROVAL_STATUS_END',
};

// Action function
export const resetSelectedRows = createAction(RESET_SELECTED_ROWS);

export const creatorFindProfiles = createAction(CREATOR_FIND_PROFILES);
export const creatorFindProfilesCompleted = createAction(CREATOR_FIND_PROFILES_COMPLETED);
export const creatorFindProfilesFailed = createAction(CREATOR_FIND_PROFILES_FAILED);

export const creatorExportProfiles = createAction(CREATOR_EXPORT_PROFILES);
export const creatorExportProfilesCompleted = createAction(CREATOR_EXPORT_PROFILES_COMPLETED);
export const creatorExportProfilesFailed = createAction(CREATOR_EXPORT_PROFILES_FAILED);

export const creatorGetProfile = createAction(CREATOR_GET_PROFILE);
export const creatorGetProfileCompleted = createAction(CREATOR_GET_PROFILE_COMPLETED);
export const creatorGetProfileFailed = createAction(CREATOR_GET_PROFILE_FAILED);

// fetch creator list lite
export const fetchCreatorListLiteRequest = createAction(FETCH_CREATOR_LITE_REQUEST);
export const fetchCreatorListLiteCompleted = createAction(FETCH_CREATOR_LITE_COMPLETED);
export const fetchCreatorListLiteFailed = createAction(FETCH_CREATOR_LITE_FAILED);

export const creatorChangeStatus = createAction(CREATOR_CHANGE_STATUS);
export const creatorChangeStatusCompleted = createAction(CREATOR_CHANGE_STATUS_COMPLETED);
export const creatorChangeStatusFailed = createAction(CREATOR_CHANGE_STATUS_FAILED);

export const creatorBlock = createAction(CREATOR_BLOCK);
export const creatorBlockCompleted = createAction(CREATOR_BLOCK_COMPLETED);
export const creatorBlockFailed = createAction(CREATOR_BLOCK_FAILED);
export const creatorBlockEnd = createAction(CREATOR_BLOCK_END);

export const creatorDelete = createAction(CREATOR_DELETE);
export const creatorDeleteCompleted = createAction(CREATOR_DELETE_COMPLETED);
export const creatorDeleteFailed = createAction(CREATOR_DELETE_FAILED);

export const creatorImport = createAction(CREATOR_IMPORT);
export const creatorImportCompleted = createAction(CREATOR_IMPORT_COMPLETED);
export const creatorImportFailed = createAction(CREATOR_IMPORT_FAILED);

export const creatorGetSegment = createAction(CREATOR_GET_SEGMENT);
export const creatorGetSegmentCompleted = createAction(CREATOR_GET_SEGMENT_COMPLETED);
export const creatorGetSegmentFailed = createAction(CREATOR_GET_SEGMENT_FAILED);

export const getListCampaignSimpleRequest = createAction(GET_LIST_CAMPAIGN_SIMPLE_REQUEST);
export const getListCampaignSimpleCompleted = createAction(GET_LIST_CAMPAIGN_SIMPLE_COMPLETED);
export const getListCampaignSimpleFailed = createAction(GET_LIST_CAMPAIGN_SIMPLE_FAILED);

export const getSegment = createAction(GET_SEGMENT);
export const getSegmentCompleted = createAction(GET_SEGMENT_COMPLETED);
export const getSegmentFailed = createAction(GET_SEGMENT_FAILED);

export const createSegment = createAction(CREATE_SEGMENT);
export const createSegmentCompleted = createAction(CREATE_SEGMENT_COMPLETED);
export const createSegmentFailed = createAction(CREATE_SEGMENT_FAILED);
export const createSegmentEnd = createAction(CREATE_SEGMENT_END);

export const updateSegment = createAction(UPDATE_SEGMENT);
export const updateSegmentCompleted = createAction(UPDATE_SEGMENT_COMPLETED);
export const updateSegmentFailed = createAction(UPDATE_SEGMENT_FAILED);
export const updateSegmentEnd = createAction(UPDATE_SEGMENT_END);

export const creatorResetUploadAWS = createAction(CREATOR_RESET_UPLOAD_AWS);

export const updateStatusRecentlyAddedRequest = createAction(UPDATE_STATUS_RECENTLY_ADDED_REQUEST);
export const updateStatusRecentlyAddedCompleted = createAction(
	UPDATE_STATUS_RECENTLY_ADDED_COMPLETED,
);
export const updateStatusRecentlyAddedFailed = createAction(UPDATE_STATUS_RECENTLY_ADDED_FAILED);
export const updateStatusRecentlyAddedEnd = createAction(UPDATE_STATUS_RECENTLY_ADDED_END);

export const getCreatorLocationRequest = createAction(GET_LOCATION_CREATOR_REQUEST);
export const getCreatorLocationSucceeded = createAction(GET_LOCATION_CREATOR_SUCCEEDED);
export const getCreatorLocationFailed = createAction(GET_LOCATION_CREATOR_FAILED);

export const getCreatorUnviewedStatusRecentlyAddedRequest = createAction(
	GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_REQUEST,
);
export const getCreatorUnviewedStatusRecentlyAddedSucceeded = createAction(
	GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_SUCCEEDED,
);
export const getCreatorUnviewedStatusRecentlyAddedFailed = createAction(
	GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_FAILED,
);
export const getCreatorUnviewedStatusRecentlyAddedEnd = createAction(
	GET_CREATOR_UNVIEWED_STATUS_RECENTLY_ADDED_END,
);

// Update creator profile
export const updateCreatorProfileRequest = createAction(UPDATE_CREATOR_PROFILE_REQUEST);
export const updateCreatorProfileSucceeded = createAction(UPDATE_CREATOR_PROFILE_SUCCEEDED);
export const updateCreatorProfileFailed = createAction(UPDATE_CREATOR_PROFILE_FAILED);
export const updateCreatorProfileEnd = createAction(UPDATE_CREATOR_PROFILE_END);

// Invalidate creator social username
export const invalidateSocialUsernameRequest = createAction(INVALIDATE_SOCIAL_USERNAME_REQUEST);
export const invalidateSocialUsernameSucceeded = createAction(INVALIDATE_SOCIAL_USERNAME_SUCCEEDED);
export const invalidateSocialUsernameFailed = createAction(INVALIDATE_SOCIAL_USERNAME_FAILED);
export const invalidateSocialUsernameEnd = createAction(INVALIDATE_SOCIAL_USERNAME_END);

// Get completed actions
export const getCompletedActionsRequest = createAction(GET_COMPLETED_ACTIONS_REQUEST);
export const getCompletedActionsSucceeded = createAction(GET_COMPLETED_ACTIONS_SUCCEEDED);
export const getCompletedActionsFailed = createAction(GET_COMPLETED_ACTIONS_FAILED);
export const getCompletedActionsEnd = createAction(GET_COMPLETED_ACTIONS_END);

// Get social stats
export const getSocialStatsRequest = createAction(GET_SOCIAL_STATS_REQUEST);
export const getSocialStatsSucceeded = createAction(GET_SOCIAL_STATS_SUCCEEDED);
export const getSocialStatsFailed = createAction(GET_SOCIAL_STATS_FAILED);
export const getSocialStatsEnd = createAction(GET_SOCIAL_STATS_END);

// Refresh social stats
export const refreshSocialStatsRequest = createAction(REFRESH_SOCIAL_STATS_REQUEST);
export const refreshSocialStatsSucceeded = createAction(REFRESH_SOCIAL_STATS_SUCCEEDED);
export const refreshSocialStatsFailed = createAction(REFRESH_SOCIAL_STATS_FAILED);
export const refreshSocialStatsEnd = createAction(REFRESH_SOCIAL_STATS_END);

// download template creator
export const downloadTemplateCreatorRequest = createAction(
	downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_REQUEST,
);
export const downloadTemplateCreatorSucceeded = createAction(
	downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_SUCCEEDED,
);
export const downloadTemplateCreatorFailed = createAction(
	downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_FAILED,
);
export const downloadTemplateCreatorEnd = createAction(
	downloadTemplateTypes.DOWNLOAD_TEMPLATE_CREATOR_END,
);

// fetch message history creator
export const fetchMessageHistoryCreatorRequest = createAction(
	FETCH_MESSAGE_HISTORY_CREATOR_REQUEST,
);
export const fetchMessageHistoryCreatorSucceeded = createAction(
	FETCH_MESSAGE_HISTORY_CREATOR_SUCCEEDED,
);
export const fetchMessageHistoryCreatorFailed = createAction(FETCH_MESSAGE_HISTORY_CREATOR_FAILED);

// Change approval status
export const updateApprovalStatusRequest = createAction(
	updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_REQUEST,
);
export const updateApprovalStatusSucceeded = createAction(
	updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_SUCCEEDED,
);
export const updateApprovalStatusFailed = createAction(
	updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_FAILED,
);
export const updateApprovalStatusEnd = createAction(
	updateApprovalStatusTypes.UPDATE_APPROVAL_STATUS_END,
);

// Store creator profile data
export const storeCreatorData = createAction(STORE_CREATOR_DATA);
