import React from 'react';

export type TaskDetailContextType = {
	taskId?: number;
};

// Default context values
const values: TaskDetailContextType = {};

const TaskDetailContext = React.createContext(values);
export default TaskDetailContext;
