import { IconDislike, IconHeart, IconLike } from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { TYPE_FORMAT_DATE } from '@constants/common';
import { Paginator } from '@constants/paginator';
import { IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import { LoadingWrapper } from '@cores/index';
import {
	CompletedActionEmojiDto,
	CompletedActionItemDto,
	CreatorCompletedActionsDto,
} from '@models/creator/profile';
import { CompletedActionsRequestDto } from '@models/creator/summary';
import {
	getCompletedActionsEnd,
	getCompletedActionsRequest,
	storeCreatorData,
} from '@stores/actions';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledCol,
	StyledFlex,
	StyledIcon,
	StyledRow,
	StyledText,
} from '@styled/Common/CommonStyled';
import {
	StyledCompletedActionsContent,
	StyledCompletedActionsEmpty,
} from '@styled/Creators/CompletedActionsStyled';
import { StyledCollapse, StyledContactBlock } from '@styled/Creators/ContactCardStyled';
import { abbreviateNumber } from '@utils/common';
import { Collapse } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type CompletedActionsProps = {
	creatorId: number;
};

const CompletedActions = (props: CompletedActionsProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { creatorId } = props;
	const { completedActions, getCompletedActionsStatus }: UserStoreType = useSelector(
		(state: any) => state.creator,
	);
	const SECTION_ID = 'completedActionsContent';
	const DEFAULT_REQUEST_PAYLOAD = {
		page: Paginator.defaultPage,
		maxRecords: 10,
		creatorId: null,
	};
	const DEFAULT_COMPLETED_ACTIONS_DATA = {
		totalActions: 0,
		totalVotes: 0,
		actions: [],
	};

	const [requestPayload, setRequestPayload] =
		useState<CompletedActionsRequestDto>(DEFAULT_REQUEST_PAYLOAD);

	const [completedActionsData, setCompletedActionsData] = useState<CreatorCompletedActionsDto>(
		DEFAULT_COMPLETED_ACTIONS_DATA,
	);

	useEffect(() => {
		if (creatorId) {
			setRequestPayload({ ...DEFAULT_REQUEST_PAYLOAD, creatorId });
		}

		return () => {
			resetData();
		};
	}, [creatorId]);

	const resetData = () => {
		setCompletedActionsData(DEFAULT_COMPLETED_ACTIONS_DATA);
		dispatch(
			storeCreatorData({
				completedActions: null,
			}),
		);
	};

	useEffect(() => {
		if (completedActions) {
			const { actions = [] } = completedActions || {};
			let completedActionsDataTemp = { ...completedActionsData };

			if (requestPayload.page === Paginator.defaultPage) {
				completedActionsDataTemp = { ...completedActions };
			} else {
				completedActionsDataTemp = {
					...completedActions,
					actions: [...completedActionsData.actions, ...actions],
				};
			}
			setCompletedActionsData({
				...completedActionsDataTemp,
			});
		}
	}, [completedActions]);

	useEffect(() => {
		if (getCompletedActionsStatus === SUCCEEDED) {
			dispatch(getCompletedActionsEnd());
		}
	}, [getCompletedActionsStatus]);

	useEffect(() => {
		const checkedElement = document.querySelector(`#${SECTION_ID} .ant-collapse-content`);

		if (checkedElement) {
			const handleScrollList = (event: Event) => {
				const { clientHeight, scrollTop, scrollHeight } = event.target as HTMLDivElement;
				const scrollbarTop = (clientHeight + scrollTop) / scrollHeight;

				if (scrollbarTop === 1) {
					handleLoadMore();
				}
			};

			checkedElement.addEventListener('scroll', handleScrollList);
			return () => checkedElement.removeEventListener('scroll', handleScrollList);
		}
		return () => {};
	}, [completedActions, requestPayload]);

	const handleLoadMore = () => {
		const { actions = [] } = completedActions || {};
		if (actions && actions?.length > 0) {
			setRequestPayload({ ...requestPayload, page: requestPayload.page + 1 });
		}
	};

	useEffect(() => {
		if (requestPayload.creatorId) {
			dispatch(getCompletedActionsRequest(requestPayload));
		}
	}, [requestPayload]);

	const REACTION_TYPE = {
		LIKE: 'like',
		DISLIKE: 'dislike',
		LOVE: 'love',
	};

	const renderHeader = () => {
		const { totalVotes = 0, totalActions = 0 } = completedActionsData || {};

		return (
			<StyledFlex justify="flex-start">
				<StyledText
					margin="0 12px 0 0"
					dangerouslySetInnerHTML={{
						__html: t('creator_profile.completed_actions.header', {
							value: totalActions,
						}),
					}}
				/>

				<StyledText
					dangerouslySetInnerHTML={{
						__html: t('creator_profile.completed_actions.vote', {
							value: totalVotes > 0 ? `+${totalVotes}` : totalVotes,
						}),
					}}
				/>
			</StyledFlex>
		);
	};

	const renderReactionIcon = (type: string) => {
		switch (type) {
			case REACTION_TYPE.LIKE:
				return <IconLike />;

			case REACTION_TYPE.LOVE:
				return <IconHeart />;

			default:
				return <IconDislike />;
		}
	};

	const renderReaction = (values: CompletedActionEmojiDto) => {
		return (
			<StyledRow gutter={14}>
				{Object.keys(values).map((key: string) => {
					const otherProps: {
						fillPath?: string;
					} = {};
					if (values[key].total === 0) {
						otherProps.fillPath = THEME.colors.gray1;
					}
					return (
						<StyledCol span={8} key={key}>
							<StyledIcon {...otherProps} cursor="default" size={22}>
								{renderReactionIcon(key)}
								{
									<StyledText
										fontSize="10px"
										opacity={values[key].total === 0 ? 0 : 1}
										margin="0 0 0 4px">
										{abbreviateNumber(values[key].total)}
									</StyledText>
								}
							</StyledIcon>
						</StyledCol>
					);
				})}
			</StyledRow>
		);
	};

	return (
		<StyledContactBlock id={SECTION_ID}>
			<LoadingWrapper
				sizeLoading="medium"
				isLoading={getCompletedActionsStatus === IN_PROGRESS}>
				<StyledCollapse maxHeight="150px" bordered={false} expandIconPosition="right">
					<Collapse.Panel key={1} header={renderHeader()}>
						{completedActionsData.actions.length > 0
							? completedActionsData.actions.map(
									(item: CompletedActionItemDto, index) => {
										const { date = 0, message, emoji } = item || {};
										const key = `${message}_${index}`;
										const timeInfo = moment(date).format(
											TYPE_FORMAT_DATE.MONTH_DAY,
										);
										return (
											<StyledRow
												justify="space-between"
												margin={
													index ===
													completedActionsData.actions.length - 1
														? 'unset'
														: '0 0 20px 0'
												}
												key={key}>
												<StyledCol span={4}>
													<StyledCompletedActionsContent title={timeInfo}>
														{timeInfo}
													</StyledCompletedActionsContent>
												</StyledCol>
												<StyledCol span={14}>
													<StyledCompletedActionsContent title={message}>
														{message}
													</StyledCompletedActionsContent>
												</StyledCol>
												<StyledCol span={5}>
													{renderReaction(emoji)}
												</StyledCol>
											</StyledRow>
										);
									},
							  )
							: getCompletedActionsStatus !== IN_PROGRESS && (
									<StyledCompletedActionsEmpty image={IconEmpty} />
							  )}
					</Collapse.Panel>
				</StyledCollapse>
			</LoadingWrapper>
		</StyledContactBlock>
	);
};

export default React.memo(CompletedActions);
