import { StyledEmpty } from '@styled/Common/CommonStyled';
import styled from 'styled-components';

const StyledCompletedActionsContent = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledCompletedActionsEmpty = styled(StyledEmpty)`
	margin-top: -25px;
`;

export { StyledCompletedActionsContent, StyledCompletedActionsEmpty };
