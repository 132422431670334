import React from 'react';
import { Empty as EmptyIcon } from 'antd';
import styled from 'styled-components';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';

const StyledEmpty = styled(EmptyIcon)`
	margin: 0;

	.ant-empty-image {
		width: 100%;
		height: unset;
	}
`;

type EmptyProps = {
	image?: any;
};

const Empty = ({ ...rest }: EmptyProps) => {
	return <StyledEmpty {...rest} image={IconEmpty} />;
};

export default Empty;
