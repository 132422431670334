import { Action, handleActions } from 'redux-actions';
import {
	addCommentTypes,
	createTaskTypes,
	deleteCommentTypes,
	deleteTaskTypes,
	getAccountListLiteTypes,
	getTaskDetailsTypes,
	getTaskHistoryTypes,
	getTaskListTypes,
	markTaskAsDoneTypes,
	notifyMentionedUserTypes,
	sendReminderEmailTypes,
	storeDataTaskManagerTypes,
	updateCommentTypes,
	updateTaskTypes,
} from './taskmanager.types';
import { TaskManagerStoreDto } from '@models/taskmanager/store';
import { IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { STATUSCODE } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import {
	AccountItemLiteDto,
	ReminderEmailDto,
	ReminderEmailResponseDto,
} from '@models/taskmanager';
import { FAILED } from '@constants/creator';

const initialState: TaskManagerStoreDto = {};

const getTaskListRequest = (state: TaskManagerStoreDto) => ({
	...state,
	fetchingTaskList: true,
});

const getTaskListSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: taskData = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingTaskList: false,
			taskData,
			error: null,
		};
	}

	return {
		...state,
		fetchingTaskList: false,
		taskList: null,
		error: status,
	};
};

const getTaskListFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			fetchingTaskList: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingTaskList: false,
	};
};

const getAccountListLiteRequest = (state: TaskManagerStoreDto) => ({
	...state,
	fetchingAccountListLite: true,
});

const getAccountListLiteSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: accountListLite = [],
	} = payload.data;

	const formattedList = accountListLite.map((account: AccountItemLiteDto) => {
		const fullName = `${account?.firstName || ''} ${account?.lastName || ''}`;
		return {
			...account,
			fullName,
		};
	});

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			fetchingAccountListLite: false,
			accountListLite: formattedList,
			error: null,
		};
	}

	return {
		...state,
		fetchingAccountListLite: false,
		accountListLite: [],
		error: status,
	};
};

const getAccountListLiteFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			fetchingAccountListLite: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		fetchingAccountListLite: false,
	};
};

const updateTaskRequest = (state: TaskManagerStoreDto) => ({
	...state,
	updateTaskStatus: IN_PROGRESS,
});

const updateTaskSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: updatedTask = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateTaskStatus: SUCCEEDED,
			updatedTask,
		};
	}

	return {
		...state,
		updateTaskStatus: FAILED,
		error: status,
	};
};

const updateTaskFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			updateTaskStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		updateTaskStatus: FAILED,
	};
};

const updateTaskEnd = (state: TaskManagerStoreDto) => ({
	...state,
	updateTaskStatus: NONE,
	updatedTask: null,
});

const deleteTaskRequest = (state: TaskManagerStoreDto) => ({
	...state,
	deleteTaskStatus: IN_PROGRESS,
});

const deleteTaskSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deleteTaskStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		deleteTaskStatus: FAILED,
		error: status,
	};
};

const deleteTaskFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			deleteTaskStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		deleteTaskStatus: FAILED,
	};
};

const deleteTaskEnd = (state: TaskManagerStoreDto) => ({
	...state,
	deleteTaskStatus: NONE,
	error: null,
});

const markTaskAsDoneRequest = (state: TaskManagerStoreDto) => ({
	...state,
	markTaskAsDoneStatus: IN_PROGRESS,
});

const markTaskAsDoneSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			markTaskAsDoneStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		markTaskAsDoneStatus: FAILED,
		error: status,
	};
};

const markTaskAsDoneFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			markTaskAsDoneStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		markTaskAsDoneStatus: FAILED,
	};
};

const markTaskAsDoneEnd = (state: TaskManagerStoreDto) => ({
	...state,
	markTaskAsDoneStatus: NONE,
	error: null,
});

const getTaskDetailsRequest = (state: TaskManagerStoreDto) => ({
	...state,
	getTaskDetailsStatus: IN_PROGRESS,
});

const getTaskDetailsSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: taskDetails = {},
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			taskDetails,
			getTaskDetailsStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		taskDetails: null,
		getTaskDetailsStatus: FAILED,
		error: status,
	};
};

const getTaskDetailsFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			getTaskDetailsStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		getTaskDetailsStatus: FAILED,
	};
};

const getTaskDetailsEnd = (state: TaskManagerStoreDto) => ({
	...state,
	getTaskDetailsStatus: NONE,
});

const getTaskHistoryRequest = (state: TaskManagerStoreDto) => ({
	...state,
	getTaskHistoryStatus: IN_PROGRESS,
});

const getTaskHistorySucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: taskHistory = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			taskHistory,
			getTaskHistoryStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		taskHistory: null,
		getTaskHistoryStatus: FAILED,
		error: status,
	};
};

const getTaskHistoryFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			getTaskHistoryStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		getTaskHistoryStatus: FAILED,
	};
};

const getTaskHistoryEnd = (state: TaskManagerStoreDto) => ({
	...state,
	getTaskHistoryStatus: NONE,
});

const createTaskRequest = (state: TaskManagerStoreDto) => ({
	...state,
	createTaskStatus: IN_PROGRESS,
});

const createTaskSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			createTaskStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		createTaskStatus: FAILED,
		error: status,
	};
};

const createTaskFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			createTaskStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		createTaskStatus: FAILED,
	};
};

const createTaskEnd = (state: TaskManagerStoreDto) => ({
	...state,
	createTaskStatus: NONE,
});

const addCommentRequest = (state: TaskManagerStoreDto) => ({
	...state,
	addCommentStatus: IN_PROGRESS,
});

const addCommentSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			addCommentStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		addCommentStatus: FAILED,
		error: status,
	};
};

const addCommentFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			addCommentStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		addCommentStatus: FAILED,
	};
};

const addCommentEnd = (state: TaskManagerStoreDto) => ({
	...state,
	addCommentStatus: NONE,
});

const updateCommentRequest = (state: TaskManagerStoreDto) => ({
	...state,
	updateCommentStatus: IN_PROGRESS,
});

const updateCommentSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updateCommentStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		updateCommentStatus: FAILED,
		error: status,
	};
};

const updateCommentFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			updateCommentStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		updateCommentStatus: FAILED,
	};
};

const updateCommentEnd = (state: TaskManagerStoreDto) => ({
	...state,
	updateCommentStatus: NONE,
});

const deleteCommentRequest = (state: TaskManagerStoreDto) => ({
	...state,
	deleteCommentStatus: IN_PROGRESS,
});

const deleteCommentSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			deleteCommentStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		deleteCommentStatus: FAILED,
		error: status,
	};
};

const deleteCommentFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			deleteCommentStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		deleteCommentStatus: FAILED,
	};
};

const deleteCommentEnd = (state: TaskManagerStoreDto) => ({
	...state,
	deleteCommentStatus: NONE,
});

const sendReminderEmailRequest = (state: TaskManagerStoreDto) => ({
	...state,
	sendReminderEmailStatus: IN_PROGRESS,
});

const sendReminderEmailSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: template = [],
	} = payload.data;

	const reminderEmail: ReminderEmailResponseDto = { isSend: payload?.isSend };
	if (!payload?.isSend) {
		reminderEmail.template = template;
	}

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			sendReminderEmailStatus: SUCCEEDED,
			reminderEmail,
			error: null,
		};
	}

	return {
		...state,
		sendReminderEmailStatus: FAILED,
		error: status,
	};
};

const sendReminderEmailFailed = (state: TaskManagerStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			sendReminderEmailStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		sendReminderEmailStatus: FAILED,
	};
};

const sendReminderEmailEnd = (state: TaskManagerStoreDto) => ({
	...state,
	sendReminderEmailStatus: NONE,
});

const notifyMentionedUserRequest = (state: TaskManagerStoreDto) => ({
	...state,
	notifyMentionedUserStatus: IN_PROGRESS,
});

const notifyMentionedUserSucceeded = (state: TaskManagerStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			notifyMentionedUserStatus: SUCCEEDED,
			error: null,
		};
	}

	return {
		...state,
		notifyMentionedUserStatus: FAILED,
		error: status,
	};
};

const notifyMentionedUserFailed = (
	state: TaskManagerStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			notifyMentionedUserStatus: FAILED,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		notifyMentionedUserStatus: FAILED,
	};
};

const notifyMentionedUserEnd = (state: TaskManagerStoreDto) => ({
	...state,
	notifyMentionedUserStatus: NONE,
});

const storeDataTaskManager = (state: TaskManagerStoreDto, { payload }: Action<any>) => ({
	...state,
	...payload,
});

const taskManagerReducers = handleActions<any>(
	{
		[getTaskListTypes.GET_TASK_LIST_REQUEST]: getTaskListRequest,
		[getTaskListTypes.GET_TASK_LIST_SUCCEEDED]: getTaskListSucceeded,
		[getTaskListTypes.GET_TASK_LIST_FAILED]: getTaskListFailed,

		[getAccountListLiteTypes.GET_ACCOUNT_LIST_LITE_REQUEST]: getAccountListLiteRequest,
		[getAccountListLiteTypes.GET_ACCOUNT_LIST_LITE_SUCCEEDED]: getAccountListLiteSucceeded,
		[getAccountListLiteTypes.GET_ACCOUNT_LIST_LITE_FAILED]: getAccountListLiteFailed,

		[updateTaskTypes.UPDATE_TASK_REQUEST]: updateTaskRequest,
		[updateTaskTypes.UPDATE_TASK_SUCCEEDED]: updateTaskSucceeded,
		[updateTaskTypes.UPDATE_TASK_FAILED]: updateTaskFailed,
		[updateTaskTypes.UPDATE_TASK_END]: updateTaskEnd,

		[deleteTaskTypes.DELETE_TASK_REQUEST]: deleteTaskRequest,
		[deleteTaskTypes.DELETE_TASK_SUCCEEDED]: deleteTaskSucceeded,
		[deleteTaskTypes.DELETE_TASK_FAILED]: deleteTaskFailed,
		[deleteTaskTypes.DELETE_TASK_END]: deleteTaskEnd,

		[markTaskAsDoneTypes.MARK_TASK_AS_DONE_REQUEST]: markTaskAsDoneRequest,
		[markTaskAsDoneTypes.MARK_TASK_AS_DONE_SUCCEEDED]: markTaskAsDoneSucceeded,
		[markTaskAsDoneTypes.MARK_TASK_AS_DONE_FAILED]: markTaskAsDoneFailed,
		[markTaskAsDoneTypes.MARK_TASK_AS_DONE_END]: markTaskAsDoneEnd,

		[getTaskDetailsTypes.GET_TASK_DETAILS_REQUEST]: getTaskDetailsRequest,
		[getTaskDetailsTypes.GET_TASK_DETAILS_SUCCEEDED]: getTaskDetailsSucceeded,
		[getTaskDetailsTypes.GET_TASK_DETAILS_FAILED]: getTaskDetailsFailed,
		[getTaskDetailsTypes.GET_TASK_DETAILS_END]: getTaskDetailsEnd,

		[getTaskHistoryTypes.GET_TASK_HISTORY_REQUEST]: getTaskHistoryRequest,
		[getTaskHistoryTypes.GET_TASK_HISTORY_SUCCEEDED]: getTaskHistorySucceeded,
		[getTaskHistoryTypes.GET_TASK_HISTORY_FAILED]: getTaskHistoryFailed,
		[getTaskHistoryTypes.GET_TASK_HISTORY_END]: getTaskHistoryEnd,

		[createTaskTypes.CREATE_TASK_REQUEST]: createTaskRequest,
		[createTaskTypes.CREATE_TASK_SUCCEEDED]: createTaskSucceeded,
		[createTaskTypes.CREATE_TASK_FAILED]: createTaskFailed,
		[createTaskTypes.CREATE_TASK_END]: createTaskEnd,

		[addCommentTypes.ADD_COMMENT_REQUEST]: addCommentRequest,
		[addCommentTypes.ADD_COMMENT_SUCCEEDED]: addCommentSucceeded,
		[addCommentTypes.ADD_COMMENT_FAILED]: addCommentFailed,
		[addCommentTypes.ADD_COMMENT_END]: addCommentEnd,

		[updateCommentTypes.UPDATE_COMMENT_REQUEST]: updateCommentRequest,
		[updateCommentTypes.UPDATE_COMMENT_SUCCEEDED]: updateCommentSucceeded,
		[updateCommentTypes.UPDATE_COMMENT_FAILED]: updateCommentFailed,
		[updateCommentTypes.UPDATE_COMMENT_END]: updateCommentEnd,

		[deleteCommentTypes.DELETE_COMMENT_REQUEST]: deleteCommentRequest,
		[deleteCommentTypes.DELETE_COMMENT_SUCCEEDED]: deleteCommentSucceeded,
		[deleteCommentTypes.DELETE_COMMENT_FAILED]: deleteCommentFailed,
		[deleteCommentTypes.DELETE_COMMENT_END]: deleteCommentEnd,

		[sendReminderEmailTypes.SEND_REMINDER_EMAIL_REQUEST]: sendReminderEmailRequest,
		[sendReminderEmailTypes.SEND_REMINDER_EMAIL_SUCCEEDED]: sendReminderEmailSucceeded,
		[sendReminderEmailTypes.SEND_REMINDER_EMAIL_FAILED]: sendReminderEmailFailed,
		[sendReminderEmailTypes.SEND_REMINDER_EMAIL_END]: sendReminderEmailEnd,

		[notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_REQUEST]: notifyMentionedUserRequest,
		[notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_SUCCEEDED]: notifyMentionedUserSucceeded,
		[notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_FAILED]: notifyMentionedUserFailed,
		[notifyMentionedUserTypes.NOTIFY_MENTIONED_USER_END]: notifyMentionedUserEnd,

		[storeDataTaskManagerTypes.STORE_DATA_TASK_MANAGER]: storeDataTaskManager,
	},
	initialState,
);

export default taskManagerReducers;
