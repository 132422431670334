import ActionConfig from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/ActionConfig';
import FormDetailFeed from '@components/Settings/SocialAggregator/SocialFeedSetting/DetailFeedConfig/FormDetailFeed';
import EmptyConfig from '@components/Settings/SocialAggregator/SocialFeedSetting/EmptyConfig';
import GeneralFeeds from '@components/Settings/SocialAggregator/SocialFeedSetting/GeneralFeeds';
import UniversalBlockLists from '@components/Settings/SocialAggregator/SocialFeedSetting/UniversalBlockLists';
import TabSocial from '@components/Settings/SocialAggregator/TabSocial';
import { FULL_RECORD, PARAMS_DEFAULT, TYPE_ACTIONS } from '@constants/common';
import {
	CREATOR_TYPE_SETTING,
	DEFAULT_FORM_SETTING,
	NAME_SETTING_FEED,
	STATUS_SETTING,
	TAB_SOCIAL_SETTING,
} from '@constants/settings/socialAggregator';
import { OPERATOR } from '@constants/social_search';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import SettingSocialAggregatorContext from '@contexts/Settings/SettingSocialAggregator';
import { ConfirmModal, LoadingWrapper } from '@cores/index';
import { SocialSearchDto } from '@models/settings/socialSearch';
import {
	FeedDto,
	FieldFormSettingFeed,
} from '@models/settings/socialSearch/socialAggregator/summary';
import {
	createFeedEnded,
	createFeedRequest,
	fetchListFeedRequest,
	fetchStatsSettingFeedRequest,
	importUsernameFeedEnded,
	importUsernameFeedRequest,
	resetSocialSearchSetting,
	updateFeedEnded,
	updateFeedRequest,
} from '@stores/actions';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import { StyledContainerSettingAggregator } from '@styled/Settings/SettingAggregatorStyled';
import { initialFormValues, processRequestValues } from '@utils/socialSearch';
import { Col, Form, Row, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const SettingSocialAggregator = () => {
	const location = useLocation();
	const { state }: any = location;
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		listFeed,
		loadingListFeed,
		createFeedStatus,
		updateFeedStatus,
		isDeleteFeed,
		feedResult,
		statusImportUsernameFeed,
		usernameFeedImport,
		error,
	}: SocialSearchDto = useSelector((state: any) => state.settings.socialSearch);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [detailFeed, setDetailFeed] = useState<FeedDto | null>(null);
	const [hasChange, setHasChange] = useState(false);
	const [hasTerms, setHasTerms] = useState<boolean>(false);
	const [isCreate, setIsCreate] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(true);
	const [typeIncluded, setTypeIncluded] = useState<string>(OPERATOR.OR);
	const [typeAction, setTypeAction] = useState<string>('');
	const [fileCSVUpload, setFileCSVUpload] = useState<any>(null);

	const [form] = Form.useForm();
	const [activeTab, setActiveTab] = useState<string>(TAB_SOCIAL_SETTING.POSTS_ANALYZED);
	const [listSourceType, setListSourceType] = useState([
		...DEFAULT_FORM_SETTING[NAME_SETTING_FEED.SOURCES],
	]);
	const [listCaptionType, setListCaptionType] = useState([
		...DEFAULT_FORM_SETTING[NAME_SETTING_FEED.CAPTION_SENTIMENT],
	]);
	const [disabledCheck, setDisabledCheck] = useState<string[]>([]);
	const [typeCreator, setTypeCreator] = useState<string>(CREATOR_TYPE_SETTING.ALL);
	const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

	const onSaveChange = () => {
		form.submit();
	};

	const onChangeTab = (val: string) => {
		setActiveTab(val);
	};

	const handleFinishForm = (values: FieldFormSettingFeed) => {
		const dataRequest = processRequestValues(values, detailFeed, isActive, typeIncluded);

		if (detailFeed) {
			dispatch(updateFeedRequest({ ...detailFeed, ...dataRequest }));
		} else {
			dispatch(createFeedRequest({ ...dataRequest }));
		}
	};

	const onInputValueFormDefault = (val: FeedDto | null) => {
		setTypeIncluded(val?.queryOperator || OPERATOR.OR);
		let sourceTypes: string[] = DEFAULT_FORM_SETTING.sources;
		let captionTypes: string[] = DEFAULT_FORM_SETTING.captionSentiments;

		if (val) {
			setIsActive(val?.status === STATUS_SETTING.LIVE);
			const initialValues = initialFormValues(val, form);
			sourceTypes = initialValues.sourceTypes;
			captionTypes = initialValues.captionTypes;
			setDisabledCheck(initialValues.disabledField);
			setTypeCreator(initialValues.usernameConjunctionType);
		} else {
			setIsActive(true);
			form.resetFields();
			setDisabledCheck([]);
			setTypeCreator(CREATOR_TYPE_SETTING.ALL);
		}
		setListCaptionType(captionTypes);
		setListSourceType(sourceTypes);
	};

	const onCancelForm = () => {
		onClear();
		if (isCreate) {
			if (listFeed.length > 0) {
				onChangeDetail(listFeed[0]);
			} else {
				onChangeDetail(null);
			}
		} else {
			onChangeDetail(detailFeed);
		}
	};

	const onClear = () => {
		setIsCreate(false);
		setHasChange(false);
	};

	const onChangeDetail = (val: FeedDto | null) => {
		const { analysisLanguage } = val || {};
		if (val && analysisLanguage === undefined) {
			// By default checked
			val.analysisLanguage = true;
		}
		setDetailFeed(val);
		onInputValueFormDefault(val);
		if (!!val !== hasTerms) {
			setHasTerms(!!val);
		}
	};

	const onChangeStatusCreate = (val: boolean) => {
		setIsCreate(val);
		if (val) {
			onChangeDetail(null);
		}
	};

	const onCheckStateLocation = () => {
		if (state?.isCreate) {
			setActiveTab(TAB_SOCIAL_SETTING.SOCIAL_FEEDS);
			history.replace({ state: null });
			return onChangeStatusCreate(true);
		}

		if (state?.openSetting) {
			history.replace({ state: null });
			return setActiveTab(TAB_SOCIAL_SETTING.SOCIAL_FEEDS);
		}

		if (listFeed.length > 0 && !isCreate && !isFirstRender) {
			if (state?.feedId) {
				setActiveTab(TAB_SOCIAL_SETTING.SOCIAL_FEEDS);
				const item = listFeed?.find((feed) => feed.id === state?.feedId);
				history.replace({ state: null });
				if (item) {
					return onChangeDetail(item);
				}
			}

			onChangeDetail(listFeed[0]);
		}
		return null;
	};

	const onRemoveFeed = () => {
		dispatch(
			updateFeedRequest({
				...detailFeed,
				status: STATUS_SETTING.DELETED,
			}),
		);
	};

	const handleCancelUpload = () => {
		setTypeAction('');
		setFileCSVUpload(null);
	};

	const handleUploadFile = () => {
		const formData = new FormData();

		formData.append('file', fileCSVUpload);
		dispatch(importUsernameFeedRequest(formData));
	};

	const otherPropConfirm = useMemo(() => {
		const result: any = {};
		if (typeAction === TYPE_ACTIONS.REMOVE) {
			result.cancelText = t('content.widget_manager.button.cancel');
			result.okText = t('content.widget_manager.button.delete');
			result.onClickBtnCancel = () => setTypeAction('');
			result.onSubmit = onRemoveFeed;
		}
		if (typeAction === TYPE_ACTIONS.UPLOAD) {
			result.cancelText = t('button.cancel');
			result.okText = t('button.continue');
			result.onClickBtnCancel = handleCancelUpload;
			result.onSubmit = handleUploadFile;
		}
		return result;
	}, [typeAction]);

	const renderContentPopupConfirm = () => {
		if (typeAction === TYPE_ACTIONS.REMOVE) {
			return (
				<>
					{t('social_aggregator.message.message_remove')} <br />
				</>
			);
		}

		if (typeAction === TYPE_ACTIONS.UPLOAD) {
			return (
				<>
					{t('social_aggregator.message.message_override_upload')} <br />
				</>
			);
		}
		return '';
	};

	const onChangeStatus = () => {
		if (isCreate) {
			setIsActive(!isActive);
		} else {
			dispatch(
				updateFeedRequest({
					...detailFeed,
					status:
						detailFeed?.status === STATUS_SETTING.LIVE
							? STATUS_SETTING.DISABLED
							: STATUS_SETTING.LIVE,
				}),
			);
		}
	};

	useEffect(() => {
		setIsFirstRender(false);
		if (!detailFeed) {
			onCheckStateLocation();
		}
	}, [listFeed]);

	useEffect(() => {
		dispatch(fetchListFeedRequest({ ...PARAMS_DEFAULT, maxRecords: FULL_RECORD }));
		dispatch(fetchStatsSettingFeedRequest({}));
		return () => {
			dispatch(resetSocialSearchSetting({ listFeedActive: [], listFeed: [] }));
			history.replace({ state: null });
		};
	}, []);

	useEffect(() => {
		const listStatus = [statusImportUsernameFeed];
		if (listStatus.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus.includes(FAILED)) {
			message.error(error);
		}
		if (listStatus.includes(FAILED) || listStatus.includes(SUCCEEDED)) {
			setIsLoading(false);
			handleCancelUpload();
		}
	}, [statusImportUsernameFeed]);

	useEffect(() => {
		if (statusImportUsernameFeed === SUCCEEDED) {
			form.setFieldsValue({ [NAME_SETTING_FEED.CREATORS]: usernameFeedImport });
			setHasChange(true);
		}
		if (statusImportUsernameFeed === FAILED || statusImportUsernameFeed === SUCCEEDED) {
			dispatch(importUsernameFeedEnded());
		}
	}, [statusImportUsernameFeed]);

	useEffect(() => {
		if (createFeedStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (createFeedStatus === SUCCEEDED) {
			onChangeDetail(feedResult);
			onClear();
			message.success(t('social_aggregator.message.create_feed_success'));
		}
		if (createFeedStatus === FAILED || createFeedStatus === SUCCEEDED) {
			setIsLoading(false);
			dispatch(createFeedEnded());
		}
	}, [createFeedStatus]);

	useEffect(() => {
		if (updateFeedStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (updateFeedStatus === SUCCEEDED) {
			onClear();
			if (isDeleteFeed) {
				message.success(t('social_aggregator.message.remove_feed_success'));
				onChangeDetail(listFeed[0] || null);
				setTypeAction('');
			} else {
				onChangeDetail(feedResult);
				message.success(t('social_aggregator.message.update_feed_success'));
			}
		}
		if (updateFeedStatus === FAILED || updateFeedStatus === SUCCEEDED) {
			setIsLoading(false);
			dispatch(updateFeedEnded());
		}
	}, [updateFeedStatus]);

	const formDetailFeedProps = {
		detailFeed,
		handleFinishForm,
		hasTerms,
		setHasTerms,
		typeIncluded,
		setTypeIncluded,
		setHasChange,
		listCaptionType,
		setListCaptionType,
		listSourceType,
		setListSourceType,
		disabledCheck,
		setDisabledCheck,
		setTypeCreator,
		typeCreator,
		setFileCSVUpload,
		setTypeAction,
	};

	const valueContext = {
		detailFeed,
		onSaveChange,
		hasChange,
		handleFinishForm,
		hasTerms,
		setHasTerms,
		isCreate,
		setIsCreate,
		onCancelForm,
		setIsActive,
		isActive,
		setHasChange,
		onChangeStatusCreate,
		onChangeDetail,
		typeIncluded,
		setTypeIncluded,
		setTypeAction,
		onChangeTab,
		activeTab,
		onChangeStatus,
		setListSourceType,
		setListCaptionType,
		listCaptionType,
		listSourceType,
		disabledCheck,
		setDisabledCheck,
		typeCreator,
		setTypeCreator,
		setFileCSVUpload,
		fileCSVUpload,
	};

	return (
		<SettingSocialAggregatorContext.Provider value={valueContext}>
			<LoadingWrapper isLoading={isLoading || loadingListFeed}>
				<ConfirmModal
					width={450}
					isOpen={typeAction ? true : false}
					onClose={() => setTypeAction('')}
					confirmLoading={isLoading}
					{...otherPropConfirm}>
					{renderContentPopupConfirm()}
				</ConfirmModal>

				<StyledContainerSettingAggregator>
					<Row style={{ height: '100%' }} gutter={[24, 24]}>
						<Col style={{ height: '100%' }} span={6}>
							<TabSocial />
						</Col>
						<Col style={{ height: '100%' }} span={18}>
							<StyledWrapperContent height="100%">
								{activeTab === TAB_SOCIAL_SETTING.POSTS_ANALYZED && (
									<GeneralFeeds />
								)}
								{activeTab === TAB_SOCIAL_SETTING.SOCIAL_FEEDS && (
									<StyledWrapperContent height={'100%'} margin="0">
										{!loadingListFeed && (
											<>
												{!detailFeed && !isCreate && <EmptyConfig />}
												{(detailFeed || isCreate) && (
													<StyledWrapperContent
														borderRadius="2px"
														height={'100%'}
														padding="0 24px 0 36px"
														background={THEME.colors.darkBlue2}>
														<ActionConfig />
														<FormDetailFeed
															form={form}
															formDetailFeedProps={
																formDetailFeedProps
															}
														/>
													</StyledWrapperContent>
												)}
											</>
										)}
									</StyledWrapperContent>
								)}
								{activeTab === TAB_SOCIAL_SETTING.UNIVERSAL_BLOCK_LISTS && (
									<UniversalBlockLists />
								)}
							</StyledWrapperContent>
						</Col>
					</Row>
				</StyledContainerSettingAggregator>
			</LoadingWrapper>
		</SettingSocialAggregatorContext.Provider>
	);
};

export default SettingSocialAggregator;
