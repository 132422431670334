import { Button, Form, Input, message, Modal, Select, Space } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Style
import {
	StyledButtonText,
	StyledDatePicker,
	StyledFromItem,
	StyledInput,
	StyledInputNumber,
	StyledRangePicker,
	StyledSpan,
	StyledWrapperContactMatch,
	StyleIconRemove,
} from '@styled/Creators/ModalStyled';

// Models
import { SegmentModalProps } from '@models/creator/segments';

// API
import { postRequest, putRequest } from '@helpers/requestHelpers';

// Redux
import {
	createSegment,
	createSegmentEnd,
	getCreatorLocationRequest,
	getListCampaignSimpleRequest,
	updateSegment,
	updateSegmentEnd,
} from '@stores/actions';

// Icon
import { IconAddCircle, IconRemoveCircle } from '@assets/icons';

// Constants
import {
	APPROVAL_STATUS,
	APPROVAL_STATUS_LIST,
	AVG_CONTENT_RATING,
	BETWEEN_FIELDS,
	BLANK_CONDITIONS,
	CONDITION_VALUE,
	CONTACTS_MATCH,
	CONTENT_TAGS,
	DATE,
	FIELD_CONDITIONS,
	FORM_NAME_SEGMENT,
	GENDER,
	GENDER_VALUE,
	INTEGER,
	IS_WITHIN,
	LOCATION,
	LONG,
	RANGE_CONDITIONS,
	RATING_VALUE,
	STATUS,
	STATUS_FIELD,
	STATUS_VALUE,
	STRING,
	SUBMITTED_TO_CAMPAIGN,
	WIDTH_SELECT,
} from '@constants/creator';

// Helper
import { TYPE_ACTIONS, TYPE_FORMAT_DATE } from '@constants/common';
import { FAILED, SUCCEEDED } from '@constants/status';
import { checkNullValue, formatRequestData } from '@helpers/formHelper';
import { ContentLocationType } from '@models/content/contentLibrary/summary';
import { StyledSelect, StyledTitle } from '@styled/Common/CommonStyled';
import { notFoundContent } from '@utils/renderComponent';
import moment from 'moment';

const { OptGroup, Option } = Select;

const SegmentModal = ({
	visible,
	segments,
	editedSegment,
	onClose,
	onGetSegment,
	listOptionContentTags,
	typeAction,
	renderListOption,
}: SegmentModalProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [currentFields, setCurrentFields] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [rerender, setRerender] = useState<boolean>(false);
	const [boxRange, setBoxRange] = useState<number[]>([]);

	const {
		listCampaign = [],
		createSegmentStatus,
		updateSegmentStatus,
		creatorLocation = [],
		error,
	} = useSelector((state: any) => state.creator);
	const isEdit = editedSegment && typeAction === TYPE_ACTIONS.EDIT;

	useEffect(() => {
		if (isEdit) formatSegmentData();
	}, [editedSegment]);

	useEffect(() => {
		dispatch(getListCampaignSimpleRequest({}));
		dispatch(getCreatorLocationRequest());
	}, []);

	useEffect(() => {
		if (createSegmentStatus === SUCCEEDED || updateSegmentStatus === SUCCEEDED) {
			setIsLoading(false);
			handleCancel();
			onGetSegment();

			if (isEdit) {
				message.success(t('creator_segment.modal.update_success'));
				dispatch(updateSegmentEnd());
			} else {
				message.success(t('creator_segment.modal.create_success'));
				dispatch(createSegmentEnd());
			}
		}

		if (createSegmentStatus === FAILED || updateSegmentStatus === FAILED) {
			setIsLoading(false);
		}
	}, [createSegmentStatus, updateSegmentStatus]);

	useEffect(() => {
		if (error) {
			message.destroy();
			message.error(error).then(() => {
				dispatch(createSegmentEnd());
			});
		}
	}, [error]);

	const formatSegmentData = () => {
		const { title, joinKey, fields: unformattedFields } = editedSegment;

		const newBox: number[] = [];
		const fields = unformattedFields?.map((item: any, index: number) => {
			const values = formatFieldValue(item);
			let value = values;
			let valueMore;
			const isPercent = item?.field === FIELD_CONDITIONS.ENGAGEMENT;

			if (BETWEEN_FIELDS.includes(item.field)) {
				if (typeof values === 'object') {
					value = isPercent ? +values?.from * 100 : values?.from;
					valueMore = isPercent ? +values?.to * 100 : values?.to;
					newBox.push(index);
				} else {
					value = isPercent ? +values * 100 : +values;
				}
			}

			return {
				field: item.field,
				value,
				valueMore,
				condition: item.conditions[0].name,
			};
		});

		setBoxRange(newBox);
		setCurrentFields(unformattedFields?.map((item: any) => item.field));
		const initialValues = {
			title,
			joinKey,
			fields: fields,
		};
		form.setFieldsValue(initialValues);
	};

	const formatFieldValue = (item: any) => {
		const { conditions, value, type, field } = item;

		const condition = conditions[0].name;
		if (BETWEEN_FIELDS.includes(field) && typeof value === 'object') {
			return {
				from: value.from,
				to: value.to,
			};
		}
		if (BLANK_CONDITIONS.includes(condition)) {
			return ' ';
		}
		if (type === DATE) {
			if (condition === IS_WITHIN) {
				return [convertStringToDate(value.start), convertStringToDate(value.end)];
			}
			return convertStringToDate(value);
		}
		if (type === INTEGER) {
			return parseInt(value, 10);
		}
		if (field === STATUS) {
			if (value.field === STATUS_FIELD.ACTIVE) {
				return 'active';
			}
			if (value.field === STATUS_FIELD.INACTIVE) {
				return 'inactive';
			}
			if (value.field === STATUS_FIELD.FOLLOWED) {
				return 'followed';
			}
			if (value.field === STATUS_FIELD.UNSUBCRIBED) {
				return 'unsubscribed';
			}
			return value.toString();
		}
		if (field === SUBMITTED_TO_CAMPAIGN) {
			return +item?.value;
		}
		if (field === LOCATION) {
			let valFormat = { country: '', state: null };
			if (item?.value?.includes('-')) {
				const arr = item.value.split('-');
				valFormat = { country: arr[0], state: arr[1] };
			} else {
				valFormat.country = item?.value;
			}

			return JSON.stringify(valFormat);
		}
		return item.value;
	};

	const convertStringToDate = (value: string) => {
		const date = new Date(value);
		return moment(date, TYPE_FORMAT_DATE.MONTH_DAY);
	};

	const handleSegment = (params: any, method: any) => {
		let payload = {
			...params,
		};
		setIsLoading(true);

		if (isEdit) {
			payload = { ...payload, id: editedSegment.id };
			dispatch(updateSegment(payload));
		} else {
			dispatch(createSegment(payload));
		}
	};

	const convertDate = (val: string) => {
		return moment(new Date(val)).format('yyyy-MM-DD');
	};

	const handleSave = () => {
		form.validateFields().then((values) => {
			let data = formatRequestData(values);

			data = data.map((item: any) => {
				if (!item?.condition) {
					item.condition = '';
				}
				const conditionItem = segments?.find((sg) => sg.field === item?.field);
				if (conditionItem && conditionItem?.type === DATE && item?.value) {
					if (typeof item?.value === 'string') {
						item.value = convertDate(item?.value);
					} else {
						if (item.value.start) {
							item.value.start = convertDate(item.value.start);
						}
						if (item.value.end) {
							item.value.end = convertDate(item.value.end);
						}
					}
				}
				if (conditionItem?.isCustomProperties) {
					return { ...item, isCustomProperties: true };
				}
				return item;
			});

			// Cannot submit with empty field data
			if (data.length !== 0 && checkNullValue(data)) {
				handleSegment({ ...values, fields: data }, isEdit ? putRequest : postRequest);
			}
		});
	};

	const handleCancel = () => {
		onClose();
		form.resetFields();
		setCurrentFields([]);
		setBoxRange([]);
	};

	const onFinish = (values: any) => {
		const data = formatRequestData(values);
	};

	const getCondition = (index: number) => {
		// Get conditions
		const fieldData = form.getFieldsValue();
		const { fields } = fieldData;
		return fields[index]?.condition;
	};

	const resetForm = (index: any, value: any) => {
		const fieldData = form.getFieldsValue();
		const { fields = [] } = fieldData;
		Object.assign(fields[index], value);
		form.setFieldsValue({ fields });
	};

	const handleChangeSelect = (value: any, name: number) => {
		const tempArray: string[] = [...currentFields];
		tempArray[name] = value;
		setCurrentFields([...tempArray]);
		resetForm(name, { condition: null, value: null });
		handleChangeRangeBox(name);
	};

	const handleChangeRangeBox = (idx: number) => {
		const condition = getCondition(idx);
		const currentSegment = segments.filter((item) => item.field === currentFields[idx]);
		const field = currentSegment[0]?.field;
		const newBox = boxRange.filter((item) => item !== idx);
		if (condition === RANGE_CONDITIONS.BETWEEN && BETWEEN_FIELDS.includes(field)) {
			newBox.push(idx);
		}
		setBoxRange(newBox);
	};

	const handleChangeValue = (key: number) => {};

	const handleChangeSelectCondition = (name: number) => {
		const condition = getCondition(name);
		const resetValue = BLANK_CONDITIONS.includes(condition) ? ' ' : null;
		setRerender(!rerender);
		resetForm(name, { value: resetValue });
		handleChangeRangeBox(name);
	};

	const handleChangeSelectCampaign = (name: number, val: number) => {
		setRerender(!rerender);
		resetForm(name, { value: val });
	};

	const handleClickRemove = (name: number) => {
		const tempArray: any = [...currentFields];
		tempArray.splice(name, 1);
		setCurrentFields([...tempArray]);
		const newList = [...boxRange].filter((item) => item !== name);
		setBoxRange(
			newList.map((item) => {
				if (item > name) {
					return item - 1;
				}
				return item;
			}),
		);
	};

	const renderConditionsField = (name: number) => {
		const currentSegment = segments.filter((item) => item.field === currentFields[name]);
		const conditions = currentSegment[0]?.conditions;

		return (
			<Select
				placeholder=""
				style={{ width: WIDTH_SELECT }}
				disabled={currentFields[name] === SUBMITTED_TO_CAMPAIGN}
				onChange={() => handleChangeSelectCondition(name)}>
				{conditions?.map((item: any, index: number) => (
					<Select.Option value={item.name} key={item.name}>
						{item.name}
					</Select.Option>
				))}
			</Select>
		);
	};

	const renderOtherPropSelect = (name: string | number, positionIndex?: number) => {
		let otherProps = {};

		if (name === SUBMITTED_TO_CAMPAIGN) {
			if (positionIndex)
				otherProps = {
					...otherProps,
					onChange: (val: number) => handleChangeSelectCampaign(positionIndex, val),
				};
		}

		return otherProps;
	};

	const renderSelect = (list: Array<any>, name: string | number, positionIndex?: number) => {
		const options: any = renderListOption(list, name);

		return (
			<StyledSelect
				width={`${name === CONTACTS_MATCH ? WIDTH_SELECT - 140 : WIDTH_SELECT}px`}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				virtual={false}
				optionFilterProp="label"
				showArrow
				options={options}
				dropdownClassName="dropdown-menu dropdown-custom-content"
				{...renderOtherPropSelect(
					name,
					name === SUBMITTED_TO_CAMPAIGN ? positionIndex : undefined,
				)}
			/>
		);
	};

	const renderSelectLocation = (list: Array<ContentLocationType>) => {
		return (
			<StyledSelect
				mode={undefined}
				showSearch
				virtual={false}
				allowClear
				width={`${WIDTH_SELECT}px`}
				optionFilterProp={'children'}
				notFoundContent={notFoundContent(t)}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				placeholder={t('filter.filter_content.any')}
				showArrow
				className="select_group_tags"
				dropdownClassName="dropdown-menu dropdown-custom-content">
				{list?.map((item: ContentLocationType) => {
					if (!item?.state) {
						const val = item?.name
							? JSON.stringify({
									country: item?.abbreviation,
									state: null,
							  })
							: '';

						return (
							<Option key={val || Math.random()} value={val}>
								{item?.name}
							</Option>
						);
					}
					return (
						<OptGroup key={item?.abbreviation} label={item?.name}>
							{item?.state?.map((option) => {
								const val = JSON.stringify({
									country: item?.abbreviation,
									state: option?.abbreviation || null,
								});

								return (
									<Option key={val} value={val}>
										{option?.name}
									</Option>
								);
							})}
						</OptGroup>
					);
				})}
			</StyledSelect>
		);
	};

	const rangeValid = (start?: number | null, end?: number | null) => {
		const startVal = start || 0;
		const endVal = end || 0;
		return endVal > startVal;
	};

	const renderValidator = (
		start: number | string | null,
		end: number | string | null,
		isEnd?: boolean,
	) => {
		const value = isEnd ? end : start;
		const startVal = +(start || 0);
		const endVal = +(end || 0);
		if (startVal === endVal && end !== null && start !== null) {
			return Promise.reject(
				new Error(
					t('validation.invalid_message', {
						field: t('creator_segment.modal.value'),
					}),
				),
			);
		}

		if (!value && value !== '0' && value !== 0) {
			return Promise.resolve();
		}
		const valueCheck = isEnd ? startVal : endVal;
		if (valueCheck && !rangeValid(startVal, endVal)) {
			return Promise.reject(
				new Error(
					t('validation.invalid_message', {
						field: t('creator_segment.modal.value'),
					}),
				),
			);
		}
		return Promise.resolve();
	};

	const renderValueFieldMore = (name: number, restField: any) => {
		const currentSegment = segments.filter((item) => item.field === currentFields[name]);
		const type = currentSegment[0]?.type;
		const field = currentSegment[0]?.field;

		if (BETWEEN_FIELDS.includes(field) && boxRange.includes(name)) {
			return (
				<>
					<StyledTitle className="unset_height" display="inline" fontSize="14px">
						{t('creator_segment.modal.and')}
					</StyledTitle>
					<StyledFromItem
						{...restField}
						className={'form_input_range'}
						name={[name, 'valueMore']}
						rules={[
							{
								required: true,
								message: t('creator_segment.modal.required'),
							},
							({ getFieldValue }: any) => ({
								validator(_: any, value: any) {
									const values: any = form.getFieldsValue();
									const startVal = values?.fields[name]?.value || 0;
									const isRate = field === FIELD_CONDITIONS.ENGAGEMENT;
									let valueValidate =
										value && isRate ? Math.floor(+value * 10) / 10 : value;

									if (value && isRate) {
										const listFields = values?.fields;

										if (valueValidate < 0) {
											valueValidate = null;
										}
										if (+value !== valueValidate) {
											listFields[name].valueMore = valueValidate;
											form.setFieldsValue({
												fields: listFields,
											});
										}
									}

									return renderValidator(startVal, valueValidate, true);
								},
							}),
						]}>
						<StyledInput type="number" width={WIDTH_SELECT / 2 - 15} />
					</StyledFromItem>
				</>
			);
		}

		return null;
	};

	const renderOtherRule = (idx: number) => {
		const currentSegment = segments.filter((item) => item.field === currentFields[idx]);
		const field = currentSegment[0]?.field;
		if (BETWEEN_FIELDS.includes(field)) {
			return {
				func: {
					validator(_: any, value: any) {
						const values = form.getFieldsValue();
						const endVal = values?.fields[idx]?.valueMore || 0;
						const isRate = field === FIELD_CONDITIONS.ENGAGEMENT;

						let valueValidate = value && isRate ? Math.floor(+value * 10) / 10 : value;

						if (value && isRate) {
							const listFields = values?.fields;

							if (valueValidate < 0) {
								valueValidate = null;
							}
							if (+value !== valueValidate) {
								listFields[idx].value = valueValidate;
								form.setFieldsValue({
									fields: listFields,
								});
							}
						}
						if (boxRange.includes(idx)) {
							if (!value) {
								return Promise.resolve();
							}
							if (+value < 0) {
								return Promise.reject(
									new Error(
										t('validation.invalid_message', {
											field: t('creator_segment.modal.value'),
										}),
									),
								);
							}

							return renderValidator(valueValidate, endVal, false);
						}
						return Promise.resolve();
					},
				},
			};
		}
		return {
			func: {
				validator(_: any, value: any) {
					return Promise.resolve();
				},
			},
		};
	};

	const renderValueField = (name: number) => {
		// Get type
		const currentSegment = segments.filter((item) => item.field === currentFields[name]);
		const type = currentSegment[0]?.type;
		const field = currentSegment[0]?.field;
		const condition = getCondition(name);

		const child = (
			<>
				{listOptionContentTags?.map((item: any) => (
					<OptGroup key={item?.nameGroup} label={item?.label}>
						{item?.options?.map((option: any) => {
							const val = JSON.stringify({
								val: option?.value,
								group: item?.nameGroup,
							});
							return (
								<Option key={val} value={val}>
									{option?.name}
								</Option>
							);
						})}
					</OptGroup>
				))}
			</>
		);

		if (BLANK_CONDITIONS.includes(condition)) {
			return <StyledInput disabled />;
		}

		if (field === AVG_CONTENT_RATING) {
			return renderSelect(RATING_VALUE, field);
		}

		if (field === LOCATION) {
			return renderSelectLocation(creatorLocation);
		}

		if (field === SUBMITTED_TO_CAMPAIGN) {
			return renderSelect(listCampaign, field, name);
		}

		if (field === STATUS) {
			return renderSelect(STATUS_VALUE, field);
		}

		if (field === APPROVAL_STATUS) {
			return renderSelect(
				APPROVAL_STATUS_LIST.map((item) => ({
					value: item.value,
					label: t(`${item.name}`),
				})),
				field,
				name,
			);
		}

		if (field === CONTENT_TAGS) {
			return (
				<StyledSelect
					width={`${WIDTH_SELECT}px`}
					showSearch
					virtual={false}
					allowClear
					notFoundContent={notFoundContent(t)}
					getPopupContainer={(triggerNode: HTMLElement) =>
						triggerNode.parentNode as HTMLElement
					}
					showArrow
					className="select_group_tags"
					dropdownClassName="dropdown-menu dropdown-custom-content"
					onChange={(value: any) => {
						setRerender(!rerender);
						resetForm(name, { value: JSON.parse(value).val });
					}}>
					{child}
				</StyledSelect>
			);
		}

		if (BETWEEN_FIELDS.includes(field)) {
			return (
				<StyledInput
					type="number"
					width={boxRange.includes(name) ? WIDTH_SELECT / 2 - 15 : undefined}
				/>
			);
		}

		if (type === LONG || type === INTEGER) {
			return <StyledInputNumber />;
		}

		if (type === STRING) {
			if (field === GENDER) {
				return renderSelect(GENDER_VALUE, field);
			}
			return <StyledInput />;
		}

		if (type === DATE) {
			if (condition === IS_WITHIN) {
				return (
					<StyledRangePicker
						format={TYPE_FORMAT_DATE.MONTH_DAY}
						onChange={() => handleChangeValue(name)}
					/>
				);
			} else
				return (
					<StyledDatePicker
						format={TYPE_FORMAT_DATE.MONTH_DAY}
						onChange={() => handleChangeValue(name)}
					/>
				);
		}

		return <StyledInput disabled />;
	};

	return (
		<Modal
			title={
				isEdit
					? t('creator_segment.modal.edit_segment')
					: t('creator_segment.modal.create_segment')
			}
			visible={visible}
			onOk={handleSave}
			onCancel={handleCancel}
			width={840}
			footer={[
				<Button key="back" onClick={handleCancel}>
					{t('button.cancel')}
				</Button>,
				<Button onClick={handleSave} key="submit" type="primary" loading={isLoading}>
					{t('button.save')}
				</Button>,
			]}>
			<Form
				name={FORM_NAME_SEGMENT.NAME}
				onFinish={onFinish}
				form={form}
				requiredMark={false}>
				<Form.Item
					wrapperCol={{ span: 16 }}
					labelCol={{ span: 6 }}
					name="title"
					label={t('creator_segment.modal.segment_name')}
					rules={[{ required: true, message: t('creator_segment.modal.required') }]}>
					<Input />
				</Form.Item>
				<StyledWrapperContactMatch>
					<Form.Item
						wrapperCol={{ span: 16 }}
						labelCol={{ span: 6 }}
						name="joinKey"
						label={t('creator_segment.modal.contacts_match')}
						rules={[{ required: true, message: t('creator_segment.modal.required') }]}>
						{renderSelect(CONDITION_VALUE, CONTACTS_MATCH)}
					</Form.Item>
					<StyledSpan className="ant-form-text">
						{t('creator_segment.modal.following_condition')}
					</StyledSpan>
				</StyledWrapperContactMatch>
				<Form.List name={FORM_NAME_SEGMENT.FIELDS}>
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<Space
									key={key}
									style={{ display: 'flex', marginBottom: 8 }}
									align="baseline">
									<Form.Item
										{...restField}
										name={[name, 'field']}
										rules={[
											{
												required: true,
												message: t('creator_segment.modal.required'),
											},
										]}>
										<Select
											placeholder=""
											style={{ width: WIDTH_SELECT }}
											onChange={(value: any) =>
												handleChangeSelect(value, name)
											}>
											{name}
											{segments
												.filter(
													(item) =>
														item.field !== 'Age' &&
														item.field !== 'Gender' &&
														item.field !== 'Instagram Followers',
												)
												.map((segmentField: any, index: number) => (
													<Select.Option
														value={segmentField.field}
														key={segmentField.field}>
														{segmentField.field}
													</Select.Option>
												))}
										</Select>
									</Form.Item>

									<Form.Item
										{...restField}
										name={[name, 'condition']}
										rules={[
											{
												required:
													currentFields[name] === SUBMITTED_TO_CAMPAIGN
														? false
														: true,
												message: t('creator_segment.modal.required'),
											},
										]}>
										{renderConditionsField(name)}
									</Form.Item>

									<StyledFromItem
										{...restField}
										name={[name, 'value']}
										className={
											BETWEEN_FIELDS.includes(currentFields[name]) &&
											boxRange.includes(name)
												? 'form_input_range'
												: ''
										}
										rules={[
											{
												required: true,
												message: t('creator_segment.modal.required'),
											},
											{ ...renderOtherRule(name).func },
										]}>
										{renderValueField(name)}
									</StyledFromItem>

									{renderValueFieldMore(name, restField)}

									<StyleIconRemove>
										{fields.length !== 1 && (
											<IconRemoveCircle
												onClick={() => {
													remove(name);
													handleClickRemove(name);
												}}
											/>
										)}
									</StyleIconRemove>
								</Space>
							))}
							{fields.length < 7 && (
								<StyledButtonText
									type="text"
									onClick={() => add()}
									block
									icon={<IconAddCircle />}>
									{t('creator_segment.modal.add_condition')}
								</StyledButtonText>
							)}
						</>
					)}
				</Form.List>
			</Form>
		</Modal>
	);
};

export default React.memo(SegmentModal);
