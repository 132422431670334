import {
	fetchSocialContentTypes,
	blockSocialUsernameTypes,
	importSocialContentTypes,
	getSocialSearchFilterTypes,
	saveSocialSearchFilterTypes,
	deleteSocialSearchFilterTypes,
	resetSocialSearchStateTypes,
	blockSocialContentTypes,
	saveFacebookTokenTypes,
	getKeywordTypes,
	saveKeywordTypes,
	typeUpdateStatusListSocial,
	getUrlPreviewTikTokTypes,
	saveFeedDetailTypes,
	contentFeedTypes,
	fetchMetricFeedTypes,
	fetchSocialPostTypes,
	fetchRecentSocialPostTypes,
} from '@stores/socialsearch/socialsearch.types';
import { createAction } from 'redux-actions';

// Fetch function
export const fetchSocialContentRequest = createAction(
	fetchSocialContentTypes.FETCH_SOCIAL_CONTENT_REQUEST,
);
export const fetchSocialContentSucceeded = createAction(
	fetchSocialContentTypes.FETCH_SOCIAL_CONTENT_SUCCEEDED,
);
export const fetchSocialContentFailed = createAction(
	fetchSocialContentTypes.FETCH_SOCIAL_CONTENT_FAILED,
);

// Fetch function
export const fetchUrlPreviewTikTokRequest = createAction(
	getUrlPreviewTikTokTypes.GET_URL_PREVIEW_TIKTOK_REQUEST,
);
export const fetchUrlPreviewTikTokSucceeded = createAction(
	getUrlPreviewTikTokTypes.GET_URL_PREVIEW_TIKTOK_SUCCEEDED,
);
export const fetchUrlPreviewTikTokFailed = createAction(
	getUrlPreviewTikTokTypes.GET_URL_PREVIEW_TIKTOK_FAILED,
);

// save url preview
export const saveUrlPreviewTikTok = createAction(getUrlPreviewTikTokTypes.SAVE_URL_PREVIEW_TIKTOK);

// Block SocialContent
export const blockSocialContentRequest = createAction(
	blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_REQUEST,
);
export const blockSocialFeedContentRequest = createAction(
	blockSocialContentTypes.BLOCK_SOCIAL_FEED_CONTENT_REQUEST,
);
export const blockSocialContentSucceeded = createAction(
	blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_SUCCEEDED,
);
export const blockSocialContentFailed = createAction(
	blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_FAILED,
);
export const blockSocialContentEnd = createAction(blockSocialContentTypes.BLOCK_SOCIAL_CONTENT_END);

// Status after update SocialList

export const updateStatusSocialList = createAction(
	typeUpdateStatusListSocial.UPDATE_STATUS_SOCIAL_LIST,
);

// Block SocialUsername
export const blockSocialUsernameRequest = createAction(
	blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_REQUEST,
);
export const blockSocialFeedUsernameRequest = createAction(
	blockSocialUsernameTypes.BLOCK_SOCIAL_FEED_USERNAME_REQUEST,
);
export const blockSocialUsernameSucceeded = createAction(
	blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_SUCCEEDED,
);
export const blockSocialUsernameFailed = createAction(
	blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_FAILED,
);
export const blockSocialUsernameEnd = createAction(
	blockSocialUsernameTypes.BLOCK_SOCIAL_USERNAME_END,
);

// Import socialContent
export const importSocialContentRequest = createAction(
	importSocialContentTypes.IMPORT_SOCIAL_CONTENT_REQUEST,
);
export const importSocialFeedContentRequest = createAction(
	importSocialContentTypes.IMPORT_SOCIAL_FEED_CONTENT_REQUEST,
);
export const importSocialContentSucceeded = createAction(
	importSocialContentTypes.IMPORT_SOCIAL_CONTENT_SUCCEEDED,
);
export const importSocialContentFailed = createAction(
	importSocialContentTypes.IMPORT_SOCIAL_CONTENT_FAILED,
);
export const importSocialContentEnd = createAction(
	importSocialContentTypes.IMPORT_SOCIAL_CONTENT_END,
);

// Get Social Search filters
export const getSocialSearchFilterRequest = createAction(
	getSocialSearchFilterTypes.GET_SOCIAL_SEARCH_FILTER_REQUEST,
);
export const getSocialSearchFilterSucceeded = createAction(
	getSocialSearchFilterTypes.GET_SOCIAL_SEARCH_FILTER_SUCCEEDED,
);
export const getSocialSearchFilterFailed = createAction(
	getSocialSearchFilterTypes.GET_SOCIAL_SEARCH_FILTER_FAILED,
);

// Save filter
export const saveSocialSearchFilterRequest = createAction(
	saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_REQUEST,
);
export const saveSocialSearchFilterSucceeded = createAction(
	saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_SUCCEEDED,
);
export const saveSocialSearchFilterFailed = createAction(
	saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_FAILED,
);
export const saveSocialSearchFilterEnd = createAction(
	saveSocialSearchFilterTypes.SAVE_SOCIAL_SEARCH_FILTER_END,
);

// Delete filter
export const deleteSocialSearchFilterRequest = createAction(
	deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_REQUEST,
);
export const deleteSocialSearchFilterSucceeded = createAction(
	deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_SUCCEEDED,
);
export const deleteSocialSearchFilterFailed = createAction(
	deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_FAILED,
);
export const deleteSocialSearchFilterEnd = createAction(
	deleteSocialSearchFilterTypes.DELETE_SOCIAL_SEARCH_FILTER_END,
);

// Save Facebook token
export const saveFacebookTokenRequest = createAction(
	saveFacebookTokenTypes.SAVE_FACEBOOK_TOKEN_REQUEST,
);
export const saveFacebookTokenSuccess = createAction(
	saveFacebookTokenTypes.SAVE_FACEBOOK_TOKEN_SUCCEEDED,
);
export const saveFacebookTokenFailed = createAction(
	saveFacebookTokenTypes.SAVE_FACEBOOK_TOKEN_FAILED,
);

// Get keyword
export const getKeywordRequest = createAction(getKeywordTypes.GET_KEY_WORD_REQUEST);
export const getKeywordSucceeded = createAction(getKeywordTypes.GET_KEY_WORD_SUCCEEDED);
export const getKeywordFailed = createAction(getKeywordTypes.GET_KEY_WORD_SUCCEEDED);
export const getKeywordEnd = createAction(getKeywordTypes.GET_KEY_WORD_END);

// Save keyword
export const saveKeywordRequest = createAction(saveKeywordTypes.SAVE_KEY_WORD_REQUEST);
export const saveKeywordSucceeded = createAction(saveKeywordTypes.SAVE_KEY_WORD_SUCCEEDED);
export const saveKeywordFailed = createAction(saveKeywordTypes.SAVE_KEY_WORD_FAILED);
export const saveKeywordEnd = createAction(saveKeywordTypes.SAVE_KEY_WORD_END);

// Reset State
export const resetSocialSearchStateRequest = createAction(
	resetSocialSearchStateTypes.RESET_SOCIAL_SEARCH_STATE_REQUEST,
);
export const resetSocialSearchField = createAction(
	resetSocialSearchStateTypes.RESET_SOCIAL_SEARCH_FIELD,
);

// save feed detail
export const saveFeedDetail = createAction(saveFeedDetailTypes.SAVE_FEED_DETAIL);

// Get content feed
export const fetchContentFeedRequest = createAction(contentFeedTypes.FETCH_CONTENT_FEED_REQUEST);

// get metric feed
export const fetchMetricFeedRequest = createAction(fetchMetricFeedTypes.FETCH_METRIC_FEED_REQUEST);
export const fetchMetricFeedSucceeded = createAction(
	fetchMetricFeedTypes.FETCH_METRIC_FEED_SUCCEEDED,
);
export const fetchMetricFeedFailed = createAction(fetchMetricFeedTypes.FETCH_METRIC_FEED_FAILED);

// Update creator profile
export const fetchSocialPostRequest = createAction(fetchSocialPostTypes.FETCH_SOCIAL_POST_REQUEST);
export const fetchSocialPostSucceeded = createAction(
	fetchSocialPostTypes.FETCH_SOCIAL_POST_SUCCEEDED,
);
export const fetchSocialPostFailed = createAction(fetchSocialPostTypes.FETCH_SOCIAL_POST_FAILED);
export const fetchSocialPostEnd = createAction(fetchSocialPostTypes.FETCH_SOCIAL_POST_END);

// Get recent social post
export const fetchRecentSocialPostRequest = createAction(
	fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_REQUEST,
);
export const fetchRecentSocialPostSucceeded = createAction(
	fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_SUCCEEDED,
);
export const fetchRecentSocialPostFailed = createAction(
	fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_FAILED,
);
export const fetchRecentSocialPostEnd = createAction(
	fetchRecentSocialPostTypes.FETCH_RECENT_SOCIAL_POST_END,
);
