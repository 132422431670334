import { IconLinkUpload } from '@assets/icons';
import { ImageContentCampaignDefault } from '@assets/images';
import CampaignDetailContext from '@contexts/Campaign/CampaignDetail';
import {
	StyledBtnStatic,
	StyledDescMockup,
	StyledFooterStatic,
	StyledFrameLarge,
	StyledImgMockup,
	StyledStaticBtnIcon,
	StyledTitleMockup,
	StyledWrapperBox,
	StyledWrapperBtnStatic,
	StyledWrapperContainerMockup,
	StyledWrapperFooterStatic,
	StyledWrapperImgMockup,
} from '@styled/Campaign/CampaignDetailStyled';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { renderHashtag } from '@utils/campaign';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
	header: string;
	description: string;
	url1?: string;
	url2?: string;
};
const MockupShare = (props: PropTypes) => {
	const { header, description, url1, url2 } = props;
	const { t } = useTranslation();
	const { hashtagFooter } = useContext(CampaignDetailContext);

	const listImg = [
		{
			id: 1,
			className: 'img_1',
			url: url1,
			link: '',
		},
		{
			id: 2,
			className: 'img_2',
			url: url2,
		},
	];

	return (
		<StyledWrapperContainerMockup
			id={'mockup_share'}
			className={'share'}
			style={{ marginRight: '24px' }}>
			<StyledTitleMockup className="title_share">
				{header || t('campaign_detail.placeholder.header_cta')}
			</StyledTitleMockup>
			<StyledWrapperBox className="share">
				{listImg?.map((item) => (
					<StyledFrameLarge key={item?.id} className={item?.className}>
						<StyledWrapperImgMockup>
							{
								<StyledImgMockup
									crossOrigin={undefined}
									src={item?.url || ImageContentCampaignDefault}
									alt={item?.className}
								/>
							}
						</StyledWrapperImgMockup>
					</StyledFrameLarge>
				))}
			</StyledWrapperBox>
			<StyledDescMockup style={{ marginTop: '12px' }} className="desc_share">
				{description || t('campaign_detail.placeholder.body_cta')}
			</StyledDescMockup>
			<StyledWrapperFooterStatic>
				<StyledWrapperBtnStatic>
					<StyledStaticBtnIcon>
						<StyledBtnStatic>
							<StyledIcon margin="0 5px 0 0">
								<IconLinkUpload />
							</StyledIcon>
							{t('button.upload_here')}
						</StyledBtnStatic>
					</StyledStaticBtnIcon>
				</StyledWrapperBtnStatic>
				{!!hashtagFooter.length && (
					<StyledFooterStatic>
						{t('campaign_detail.or_tag_footer', { tag: renderHashtag(hashtagFooter) })}
					</StyledFooterStatic>
				)}
			</StyledWrapperFooterStatic>
		</StyledWrapperContainerMockup>
	);
};

export default MockupShare;
