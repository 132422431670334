import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Button, Spin } from 'antd';

// Model
import { EventDto, PagingCreatorDto } from '@models/messages/log';

// Components
import CreatorItem from '@components/Messages/MessageLog/Logs/CreatorItem';

// Styled
import { StyledLinkButton, StyledCreatorListWrapper } from '@styled/Messages/MessageLogStyled';

// Redux
import { EMAIL_LOG } from '@constants/messages';

type SegmentListProps = { data: any; onLoadMore: Function; showPreviewFirstCreator?: boolean };

const { Panel } = Collapse;

const CreatorSegmentList = (props: SegmentListProps) => {
	const dispatch = useDispatch();
	const [page, setPage] = useState<number>(1);
	// const { emailLog = {} } = useSelector((state: any) => state.messages);

	const { data, onLoadMore, showPreviewFirstCreator = false } = props;
	const {
		eventId = 0,
		segmentId = 0,
		segments = [],
		pagingCreator = {},
		body = '',
		updateAt = 0,
	} = data;

	const cloneObj = { ...pagingCreator } || {};
	const { totalPages = 0 } = cloneObj;

	const onClickSeeMore = () => {
		const payload = {
			page: page + 1,
			pageRecords: EMAIL_LOG.DEFAULT_PAGE_RECORD,
			segmentId: segmentId,
			id: eventId,
		};
		onLoadMore(payload);
		setPage((prevState) => prevState + 1);
	};

	const renderOtherPropsCreatorItem = (item: any) => {
		if (
			showPreviewFirstCreator &&
			pagingCreator?.creators?.length > 0 &&
			item?.id === pagingCreator?.creators[0].id
		) {
			return { openFirstItem: item };
		}
		return {};
	};

	const renderCreator = () =>
		!pagingCreator
			? null
			: pagingCreator.creators.map((item: any) => (
					<CreatorItem
						key={item?.id}
						{...renderOtherPropsCreatorItem(item)}
						data={{ ...item, body, segments, updateAt, eventId }}
					/>
			  ));

	return (
		<StyledCreatorListWrapper>
			{renderCreator()}
			{page !== totalPages && totalPages !== 0 && (
				<StyledLinkButton onClick={onClickSeeMore}>See more</StyledLinkButton>
			)}
		</StyledCreatorListWrapper>
	);
};

export default CreatorSegmentList;
