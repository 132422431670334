import { Popover } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Icon
import { IconCalendarToday, IconImage, IconMoney, IconRewardDiscount } from '@assets/icons';

// Types
import { MetricDto } from '@models/creator/profile';

// Styled
// Styled
import { convertUtcToLocalTimeWithFormat } from '@helpers/dateHelpers';
import { UserStoreType } from '@stores/creator/creator.types';
import {
	StyledIcon,
	StyledText,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import {
	StyledCol,
	StyledCount,
	StyledInfo,
	StyledMetricsBlock,
	StyledTitle,
} from '@styled/Creators/MetricCardsStyled';
import { abbreviateNumber } from '@utils/common';
import { useSelector } from 'react-redux';

const rewardTypeList = {
	product: 'product',
	discount: 'discount',
	giftcard: 'gift card',
};

const discountTypeList = {
	fixed: 'fixed',
	percentage: 'percentage',
};

type MetricCardsTypes = {};

type MetricCardsProps = {};

const MetricCards = (props: MetricCardsProps) => {
	const { t } = useTranslation();
	const { creatorProfile }: UserStoreType = useSelector((state: any) => state.creator);
	const NA = t('creator_profile.metric.value_empty');

	const {
		statistic: { totalDirectUploads = 0, totalSocialUploads = 0, totalArchived = 0 } = {},
		content,
		lastReward,
	} = creatorProfile || {};

	const {
		associatedRewardMessage = '',
		totalImageValue = 0,
		totalVideoValue = 0,
	} = content || {};
	const {
		estimatedCashValue = 0,
		rewardType = '',
		rewardDate = 0,
		discountType = '',
		discountAmount = 0,
		ruleTitle = '',
	} = lastReward || {};

	const totalUploadContent = () => {
		const valueList = [
			{
				title: 'creator_profile.metric.total_direct_upload',
				value: totalDirectUploads,
			},
			{
				title: 'creator_profile.metric.total_ambassador_post',
				value: totalSocialUploads,
			},
			{
				title: 'creator_profile.metric.total_archived_content',
				value: totalArchived,
			},
		];

		return (
			<div>
				{valueList.map((item, index) => (
					<StyledText margin={index === valueList.length - 1 ? 'unset' : '0 0 14px 0'}>
						{t(item.title, {
							value: item.value,
						})}
					</StyledText>
				))}
			</div>
		);
	};

	const totalContentValue = (
		<div>
			<p>
				{t('creator_profile.metric.photos')} - ${abbreviateNumber(totalImageValue)}
			</p>
			<span>
				{t('creator_profile.metric.videos')} - ${abbreviateNumber(totalVideoValue)}
			</span>
		</div>
	);

	const associatedMessage = (
		<div>
			<p>{associatedRewardMessage || 'None'}</p>
		</div>
	);

	const renderDate = (date: string | number) => {
		if (!date) {
			return NA;
		}
		const rawDate = new Date(date).getTime();
		return convertUtcToLocalTimeWithFormat(rawDate);
	};

	const titleCase = (str: string) => {
		const splitStr = str.toLowerCase().split(' ');
		// eslint error ++, so i use for-in
		for (const i in splitStr) {
			// fix eslint required add if
			if (true) {
				splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
			}
		}

		return splitStr.join(' ');
	};

	const renderLastReward = () => {
		let valueReward = NA;
		if (!rewardType) return valueReward;
		const titleRewardType = titleCase(rewardType);
		if (rewardType === rewardTypeList.discount) {
			if (!discountAmount) {
				valueReward = NA;
			} else if (discountType === discountTypeList.fixed) {
				valueReward = `$${abbreviateNumber(discountAmount)} off`;
			} else {
				valueReward = `${discountAmount}% off`;
			}
		} else if (rewardType === rewardTypeList.product) {
			if (ruleTitle) {
				valueReward = ruleTitle;
			}
		} else {
			if (estimatedCashValue) {
				valueReward = `$${abbreviateNumber(estimatedCashValue)}`;
			}
		}
		return `${titleRewardType}: ${valueReward}`;
	};

	const Metrics: Array<MetricDto[]> = [
		[
			{
				name: 'creator_profile.metric.content',
				value: abbreviateNumber(totalDirectUploads + totalSocialUploads + totalArchived),
				icon: IconImage,
				hoverContent: totalUploadContent,
				trigger: 'hover',
			},
			{
				name: 'creator_profile.metric.total_content_value',
				value: abbreviateNumber(totalImageValue + totalVideoValue),
				icon: IconMoney,
				hoverContent: totalContentValue,
				trigger: 'hover',
			},
		],
		[
			{
				name: 'creator_profile.metric.last_reward',
				value: renderLastReward(),
				icon: IconRewardDiscount,
				hoverContent: associatedMessage,
				trigger: 'hover',
			},
			{
				name: 'creator_profile.metric.date_last_reward',
				value: renderDate(rewardDate),
				icon: IconCalendarToday,
				hoverContent: undefined,
			},
		],
	];

	return (
		<StyledMetricsBlock>
			{Metrics.map((item: MetricDto[], idx) => {
				return (
					<StyledWrapperTitle
						margin={idx ? '16px 0 0 0' : '0px'}
						justifyContent="space-between"
						key={Math.random()}>
						{item.map((metric) => (
							<StyledWrapperContent width="calc(50% - 8px)">
								{metric.hoverContent ? (
									<Popover
										key={metric?.name}
										content={metric.hoverContent}
										trigger={`${metric.trigger}`}>
										<StyledCol key={metric.name}>
											<StyledIcon cursor="default" size={40}>
												<metric.icon />
											</StyledIcon>

											<StyledInfo>
												<StyledTitle title={t(metric.name)}>
													{t(metric.name)}
												</StyledTitle>
												<StyledCount title={metric.value.toString()}>
													{metric.value}
												</StyledCount>
											</StyledInfo>
										</StyledCol>
									</Popover>
								) : (
									<StyledCol key={metric.name}>
										<StyledIcon cursor="default" size={40}>
											<metric.icon />
										</StyledIcon>

										<StyledInfo>
											<StyledTitle title={t(metric.name)}>
												{t(metric.name)}
											</StyledTitle>
											<StyledCount title={metric.value.toString()}>
												{metric.value}
											</StyledCount>
										</StyledInfo>
									</StyledCol>
								)}
							</StyledWrapperContent>
						))}
					</StyledWrapperTitle>
				);
			})}
		</StyledMetricsBlock>
	);
};

export default React.memo(MetricCards);
