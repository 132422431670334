export const getDashboardTypes = {
	GET_DASHBOARD: 'GET_DASHBOARD',
};

export const generateReportTypes = {
	GENERATE_REPORT_REQUEST: 'GENERATE_REPORT_REQUEST',
	GENERATE_REPORT_COMPLETED: 'GENERATE_REPORT_COMPLETED',
	GENERATE_REPORT_FAILED: 'GENERATE_REPORT_FAILED',
	GENERATE_REPORT_END: 'GENERATE_REPORT_END',
};

export const saveFilterDashboardDefaultTypes = {
	SAVE_FILTER_DASHBOARD_DEFAULT: 'SAVE_FILTER_DASHBOARD_DEFAULT',
};

export const saveExpandDashboardTypes = {
	SAVE_EXPAND_DASHBOARD: 'SAVE_EXPAND_DASHBOARD',
};

export const saveGridColDashboardTypes = {
	SAVE_GRID_COL_DASHBOARD: 'SAVE_GRID_COL_DASHBOARD',
};
