import { Popover } from 'antd';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

// Styled
import { StyledCreatorWrapper } from '@styled/Messages/MessageLogStyled';

// Icons
import { IconAccount, IconError } from '@assets/icons';

// Context
import MessageLogContext from '@contexts/Messages/Logs';
import { ActionsTypes } from '@models/common/summary';
import { EventDto } from '@models/messages/log';
import { sendEmailRewardRequest } from '@stores/actions';
import { StyledCol, StyledDropdown, StyledIcon, StyledRow } from '@styled/Common/CommonStyled';
import { menuActions } from '@utils/renderComponent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SUCCEEDED } from '@constants/status';
import { FAILED } from '@constants/creator';

type CreatorItemProps = {
	data: any;
	openFirstItem?: any;
};

const StyledEmail = styled.span`
	font-weight: 500;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledIconAccount = styled(IconAccount)`
	height: 24px;
	margin-right: 5px;
`;

const CreatorItem = (props: CreatorItemProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { data, openFirstItem = {} } = props;
	const { id = 0, email = '', segments = [], success, error = '', creatorId } = data;
	const { emailLog: { events = [] } = {}, sendEmailStatus } = useSelector(
		(state: any) => state.messages,
	);
	const [visible, setVisible] = useState<boolean>(false);

	const handleVisibleDropdownMenu = () => {
		if (visible) {
			setVisible(false);
		}
	};

	useEffect(() => {
		const element = document.querySelector('.ant-tabs-content');
		if (element) {
			element.addEventListener('scroll', handleVisibleDropdownMenu);
			return () => element.removeEventListener('scroll', handleVisibleDropdownMenu);
		}

		return () => {};
	}, [visible]);

	useEffect(() => {
		if (sendEmailStatus === SUCCEEDED || sendEmailStatus === FAILED) {
			setVisible(false);
		}
	}, [sendEmailStatus]);

	const {
		selectedEmail = {},
		onChangeSelectedEmail,
		onIsSelectDraftEmail,
	} = useContext(MessageLogContext);

	const onClickEmail = () => {
		onIsSelectDraftEmail(false);
		onChangeSelectedEmail({ ...data, segments });
	};

	useEffect(() => {
		if (openFirstItem?.id) {
			onClickEmail();
		}
	}, [openFirstItem?.id]);

	const sendEmailWithoutValidation = () => {
		const emailDetail = events.find((item: EventDto) => item.id === data.eventId);
		if (emailDetail && email) {
			const { body, content, mailFrom: from, subject, segments } = emailDetail;
			const requestData = {
				body,
				content,
				from,
				subject,
				segments: null,
				newUpdate: true,
				disableValidation: true,
				creators: [{ id: creatorId, email }],
			};
			dispatch(sendEmailRewardRequest(requestData));
		}
	};

	const actions: ActionsTypes[] = [
		{
			name: t('email_log.logs.attempt_send_email'),
			click: sendEmailWithoutValidation,
		},
	];

	const renderInvalidIcon = () => {
		const icon = (
			<StyledIcon onClick={() => (email ? setVisible(true) : null)} opacity={email ? 1 : 0.6}>
				<IconError />
			</StyledIcon>
		);

		return email && error ? (
			<Popover trigger={['hover']} placement="topLeft" content={error}>
				<StyledDropdown
					visible={visible}
					arrow
					overlay={menuActions(actions)}
					trigger={['click']}
					placement="bottomLeft"
					onVisibleChange={(status: boolean) => {
						if (!status) {
							setVisible(status);
						}
					}}>
					{icon}
				</StyledDropdown>
			</Popover>
		) : (
			icon
		);
	};

	return (
		<StyledRow margin="0 0 5px 0" align="middle" gutter={26}>
			<StyledCol display="flex" span={1}>
				{!success && (
					<Popover trigger={['hover']} placement="topLeft" content={error}>
						{renderInvalidIcon()}
					</Popover>
				)}
			</StyledCol>
			<StyledCol>
				<StyledCreatorWrapper isSelected={selectedEmail.id === id} onClick={onClickEmail}>
					<StyledIconAccount />
					<StyledEmail>{email}</StyledEmail>
				</StyledCreatorWrapper>
			</StyledCol>
		</StyledRow>
	);
};

export default CreatorItem;
