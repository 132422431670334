import { IconQuestionCircle } from '@assets/icons';
import { ImageLogoTextBlack } from '@assets/images';
import THEME from '@constants/themes/themes';
import { WidgetBuilderDetailDto } from '@models/content/widgetManager';
import { StyledIcon } from '@styled/Common/CommonStyled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledFooterWidget = styled.div<{ isMobile?: boolean }>`
	margin: ${({ isMobile }) => (isMobile ? '64px' : '40px')} auto 24px;
	text-align: center;
	position: relative;
`;

const StyledSubFooter = styled.i<{ isMobile?: boolean }>`
	color: ${THEME.colors.black};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${({ isMobile }) => (isMobile ? '0.801rem' : '0.857rem')};
	img {
		height: ${({ isMobile }) => (isMobile ? '21px' : '14px')};
	}
`;

const StyledImageLogoFooter = styled.img`
	margin-left: 7px;
`;

const StyledHelper = styled.div<{ isMobile?: boolean }>`
	position: absolute;
	top: ${({ isMobile }) => (isMobile ? '-42px' : '-7px')};
	right: 0;
`;

type PropsFooterWidget = {
	isMobile?: boolean;
	detailWidget: WidgetBuilderDetailDto;
	isConfirmationScreen?: boolean;
};

const FooterWidgetDefault = (props: PropsFooterWidget) => {
	const { t } = useTranslation();
	const { isMobile = false, detailWidget, isConfirmationScreen } = props;
	const { metadata } = detailWidget;
	const { colors } = metadata;
	const { highlight } = colors;

	return (
		<StyledFooterWidget isMobile={isMobile}>
			<StyledSubFooter
				isMobile={isMobile}
				style={{ color: THEME.colors.black, fontSize: '0.857rem' }}>
				{t('content.widget_manager.widget_detail_setting.powered_by')}
				<StyledImageLogoFooter src={ImageLogoTextBlack} />
			</StyledSubFooter>
			{!isConfirmationScreen && (
				<StyledHelper isMobile={isMobile}>
					<StyledIcon size={isMobile ? 48 : 36} fillPath={highlight} cursor="unset">
						<IconQuestionCircle />
					</StyledIcon>
				</StyledHelper>
			)}
		</StyledFooterWidget>
	);
};

export default FooterWidgetDefault;
