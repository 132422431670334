import { IconCheck, IconMoreVert, IconReset } from '@assets/icons';
import { mediaHubURL } from '@config/index';
import { ROUTE_PATH, TYPE_ACTIONS, TYPE_FILE } from '@constants/common';
import {
	ACCEPT_FILES,
	ACTIONS,
	KEY_MESSAGE,
	MEDIA_TYPE,
	METHOD_VERSION,
	VALIDATE_FILE,
} from '@constants/content/contentLibrary';
import { STATE_TYPE } from '@constants/messages';
import THEME from '@constants/themes/themes';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { getUser } from '@helpers/userHelpers';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { ContentRootStateDto, ImgVersionDto } from '@models/content/contentLibrary/store';
import { ContentDto } from '@models/content/contentLibrary/summary';
import { createImgVersionRequest } from '@stores/actions';
import { StyledIcon, StyledTitle } from '@styled/Common/CommonStyled';
import {
	StyledContainerListOption,
	StyledNameOption,
	StyledOptionItem,
	StyledWrapperOverlayDropdown,
} from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledSection } from '@styled/Content/ContentLibrary/ContentStyled';
import { StyledButtonAnimation } from '@styled/ContentLibrary/ContentDetailStyled';
import { StyledButtonMore, StyledDropdown } from '@styled/Publish/Gallery/GalleryManagerStyled';
import { getVideoDuration, validateSizeFileImage } from '@utils/common';
import { convertFileUploads, removeTimeStampUrl } from '@utils/funcHelper';
import { menuActions, setDownloadMenuItem } from '@utils/renderComponent';
import { Button, message } from 'antd';
import _ from 'lodash';
import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

type PropsFooterAction = {
	onSaveChanges: () => void;
	handleDirectMessage: () => void;
	currentMedia: ContentDto;
	onFullScreen: () => void;
	setTypeConfirm: (val: string) => void;
	onViewPost: () => void;
	onDownload: () => void;
	onShowRequestRight: () => void;
	handleResetData: () => void;
	markAsFavorite: (content: ContentDto, isFavorite: boolean) => void;
};

const FooterAction = (props: PropsFooterAction) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const {
		onSaveChanges,
		handleDirectMessage,
		currentMedia,
		onFullScreen,
		setTypeConfirm,
		onViewPost,
		onDownload,
		onShowRequestRight,
		handleResetData,
		markAsFavorite,
	} = props;

	const { imgVersions = [] } = useSelector((state: ContentRootStateDto) => state.content);

	const {
		isDisableAction,
		typeContent,
		setTypeAction,
		versionImgSelected,
		handleChangeVersion,
		CDMBlockCreatorDisabled,
		isDashboardAmbassador,
		setIdContentUpdate,
		setIsLoading,
		enableRotateImage,
		handleRotateImage,
		disableActionPage,
		handleActionEditImg,
	} = useContext(ContentDetailContext);

	const { review, canBlock, blocked, mediaType } = currentMedia;
	const { rejected, favorite } = review || {};

	const { isSubmission, isBookmark, isArchive } = typeContent;

	const socialSource: string =
		!_.isEmpty(currentMedia.social) && !isSubmission ? currentMedia.social.socialSource : '';

	const refUpload = useRef<any>({});

	const handleSendReward = () => {
		history.push({
			pathname: ROUTE_PATH.EMAIL_BUILDER,
			state: { type: STATE_TYPE.REWARD, content: [currentMedia] },
		});
	};

	const handleOpenUpload = () => {
		if (refUpload?.current) {
			refUpload?.current?.click();
		}
	};

	const enableRotate = useMemo(() => {
		if (currentMedia) {
			if (currentMedia?.mediaType === MEDIA_TYPE.IMAGE) {
				const fileExtension =
					`.${currentMedia?.contentMetadata?.fileExtension}`.toLowerCase();
				const arrNoSp = [TYPE_FILE.GIF, TYPE_FILE.WEBP];
				const contentUrl = removeTimeStampUrl(currentMedia?.contentUrl);
				const positionType = contentUrl?.lastIndexOf('.');
				const type = contentUrl?.slice(positionType);
				return !arrNoSp.includes(type) && !arrNoSp.includes(fileExtension);
			}
		}
		return false;
	}, [currentMedia?.contentUrl]);

	const actionMore: ActionsTypes[] = [
		{
			id: 27,
			name: t('button.rotate'),
			click: handleRotateImage,
			hidden: !enableRotate || !enableRotateImage,
		},
		{
			id: 26,
			name: t('content_detail.view_social_post'),
			click: onViewPost,
			hidden: !socialSource,
		},
		{
			id: 1,
			name: t('content_detail.email_creator'),
			click: () => handleDirectMessage(),
			hidden: !isSubmission || disableActionPage,
			disabled: isArchive || disableActionPage || !currentMedia?.creatorProfile?.email,
		},
		{
			id: 2,
			name: t('content_detail.send_reward'),
			click: handleSendReward,
			hidden: !isSubmission || disableActionPage,
			disabled: isArchive || disableActionPage,
		},
		{
			id: 3,
			name: !favorite
				? t('content_detail.add_favorites')
				: t('content_detail.remove_favorites'),
			click: () => {
				markAsFavorite(currentMedia, !favorite);
			},
			hidden: isArchive,
			disabled: !!isDashboardAmbassador,
		},
		{
			id: 4,
			name: t('content_detail.add_album'),
			click: () => {},
			hidden: true,
		},
		{
			id: 5,
			name: t('content_detail.view_full_screen'),
			click: onFullScreen,
		},
		// {
		// 	id: 6,
		// 	name: t('content_detail.rotate'),
		// 	click: onRotateImage,
		// 	hidden: !isSubmission,
		// },
		{
			id: 7,
			name: rejected ? t('button.restore') : t('button.archive'),
			click: () => setTypeConfirm(rejected ? ACTIONS.UNREJECT : ACTIONS.REJECT),
			hidden: !isSubmission,
		},
		{
			id: 8,
			name: t('content_detail.block_creator'),
			click: () => setTypeConfirm(blocked ? ACTIONS.UNBLOCK : ACTIONS.BLOCK),
			disabled: isDisableAction || !canBlock || !!CDMBlockCreatorDisabled,
		},
		{
			id: 10,
			name: t('button.edit'),
			click: () => handleActionEditImg(),
			hidden:
				isArchive ||
				disableActionPage ||
				currentMedia?.mediaType === MEDIA_TYPE.VIDEO ||
				isBookmark,
			disabled: !!isDashboardAmbassador,
		},
		{
			id: 10,
			name: t('button.upload_new_version'),
			click: () => handleOpenUpload(),
			hidden: isArchive || disableActionPage || isBookmark,

			disabled: !!isDashboardAmbassador,
		},
		{
			id: 9,
			name: t('button.delete'),
			click: () => setTypeConfirm(ACTIONS.DELETE),
			hidden: !isSubmission,
		},
	];

	const getSharableLink = () => {
		const user = getUser();
		if (!_.isEmpty(user)) {
			const base64ContentDetail = btoa(`${user.clientId}_${currentMedia?.id.toString()}`);
			const textarea = document.createElement('textarea');
			textarea.value = `${mediaHubURL}/c/${base64ContentDetail}`;
			document.body.appendChild(textarea);
			textarea.select();
			document.execCommand('copy');
			message.success(t('message.get_shareable_link'));
		}
	};

	const actionShare = () => {
		const additionalItem = {
			id: 3,
			name: t('button.get_sharable_link'),
			click: () => {},
		};
		const menuItems: ActionsTypes[] = [...setDownloadMenuItem(t)];
		menuItems.push(additionalItem);

		return menuItems?.map((item: ActionsTypes) => {
			const action =
				item?.name === t('button.get_sharable_link') ? getSharableLink : onDownload;
			const isContentDetailModal = true;

			return {
				...item,
				disabled:
					(item?.name === t('button.download') && isArchive) ||
					(item?.name === t('button.get_sharable_link') && !isContentDetailModal),
				click: action,
			};
		});
	};

	const acceptUploadVersion = useMemo(() => {
		return mediaType === MEDIA_TYPE.VIDEO ? ACCEPT_FILES.VIDEO : ACCEPT_FILES.IMAGE;
	}, [mediaType]);

	const onUploadMedia = async (e: any) => {
		const { files = [] } = e.target;

		const file = files[0];
		if (file) {
			const { type, size, name } = file;

			const isValid =
				mediaType === MEDIA_TYPE.IMAGE
					? type.indexOf('image/') === 0 || !type
					: type.indexOf('video/') === 0;
			e.target.value = null;

			if (!isValid) {
				return message.warning(
					t('content_detail.message.file_type_upload', {
						val:
							mediaType === MEDIA_TYPE.IMAGE
								? t('content_detail.message.accept_img')
								: t('content_detail.message.accept_video'),
					}),
				);
			}

			const handleRequest = (fileRequest?: any) => {
				const formData = new FormData();
				formData.append('file', fileRequest || file);
				dispatch(
					createImgVersionRequest({
						params: {
							contentId: currentMedia?.id,
							method: METHOD_VERSION.UPLOAD,
							isDefault: true,
						},
						isUploadVideo: mediaType !== MEDIA_TYPE.IMAGE,
						dataRequest: formData,
					}),
				);
			};
			if (mediaType === MEDIA_TYPE.IMAGE) {
				if (size > VALIDATE_FILE.sizeDetail150mb) {
					return message.error(
						t('content_detail.warning_limit', {
							size: VALIDATE_FILE.size_150,
							type: t('content_detail.type_mb'),
						}),
					);
				}
				setIsLoading(true);
				await convertFileUploads(file)
					.then(async (fileConvert) => {
						const resultValidate = await validateSizeFileImage(fileConvert, {
							size: VALIDATE_FILE.sizeDetail150mb,
							textSize: t('content_detail.type_mb'),
						});

						if (resultValidate?.valid) {
							handleRequest(fileConvert);
						} else {
							message.error(t(resultValidate.err, resultValidate?.params));
						}
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else {
				if (size > VALIDATE_FILE.sizeDetail3Gb) {
					return message.error(
						t('content_detail.warning_limit', {
							size: VALIDATE_FILE.size_3,
							type: t('content_detail.type_gb'),
						}),
					);
				}
				const duration: any = await getVideoDuration(file);
				if (duration > VALIDATE_FILE.videoLength90m) {
					return message.error(
						t('content_detail.warning_limit', {
							size: VALIDATE_FILE.size_90,
							type: t('content_detail.minutes'),
						}),
					);
				}

				handleRequest();
			}
		}

		return null;
	};

	const actionVersion = useMemo(() => {
		return imgVersions?.map((item: ImgVersionDto) => ({
			id: item?.id,
			name: item?.name,
			click: () => handleChangeVersion(item),
		}));
	}, [imgVersions]);

	const renderOverLayVersion = (
		<StyledWrapperOverlayDropdown width="120px" bgColor={THEME.colors.darkBlue2}>
			<StyledTitle
				padding="2px 4px"
				fontSize="10px"
				fontWeight="700"
				color={THEME.colors.gray1}>
				{t('content_detail.view_other_version')}
			</StyledTitle>
			<StyledContainerListOption>
				{actionVersion.map((item: ActionsTypes) => {
					if (item?.hidden) {
						return null;
					}
					const isActive = item?.id === versionImgSelected?.id;
					return (
						<StyledOptionItem
							bgColor={THEME.colors.darkBlue2}
							key={item.id}
							className={isActive ? 'not_action' : ''}
							onClick={isActive ? undefined : item.click}>
							<StyledNameOption>{item.name}</StyledNameOption>
							{isActive && (
								<StyledIcon fillPath={THEME.colors.gray2}>
									<IconCheck />
								</StyledIcon>
							)}
						</StyledOptionItem>
					);
				})}
			</StyledContainerListOption>
		</StyledWrapperOverlayDropdown>
	);

	return (
		<StyledSection
			className="custom-section"
			padding="0 0 0 -24px"
			borderPosition="top"
			heightSection="64px"
			flexDirection="row"
			bottomCustom="0px"
			isDisableAction={isDisableAction}>
			<StyledSection padding="0" flexDirection="row">
				<input
					onChange={onUploadMedia}
					ref={refUpload}
					style={{ display: 'none' }}
					type="file"
					accept={acceptUploadVersion}
				/>
				{!!versionImgSelected && !isBookmark && (
					<StyledDropdown
						className="dropdown_action"
						overlay={renderOverLayVersion}
						getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
						placement="topLeft"
						trigger={['click']}>
						<StyledButtonAnimation
							isDisabled={isDisableAction || isDashboardAmbassador}>
							{versionImgSelected?.name}
						</StyledButtonAnimation>
					</StyledDropdown>
				)}
				{isSubmission && (
					<StyledDropdown
						className="dropdown_action"
						overlay={menuActions(actionShare())}
						getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
						placement="topLeft"
						trigger={['click']}>
						<Button type="primary">{t('button.export_share')}</Button>
					</StyledDropdown>
				)}

				{isBookmark && (
					<Button
						style={{ marginLeft: '5px' }}
						type="primary"
						onClick={onShowRequestRight}>
						{t('button.request_rights')}
					</Button>
				)}
				<StyledDropdown
					className="dropdown_action"
					overlay={menuActions(actionMore)}
					getPopupContainer={(triggerNode: HTMLElement) => triggerNode as HTMLElement}
					placement="topLeft"
					trigger={['click']}>
					<StyledButtonMore>
						<IconMoreVert />
					</StyledButtonMore>
				</StyledDropdown>
			</StyledSection>
			<StyledSection padding="0 5px">
				<StyledIcon
					className="icon_hover"
					onClick={handleResetData}
					style={{ marginRight: '7px' }}>
					<IconReset />
				</StyledIcon>
				<Button onClick={onSaveChanges}>{t('content_management.save_changes')}</Button>
			</StyledSection>
		</StyledSection>
	);
};

export default FooterAction;
