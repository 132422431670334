import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Import layout
import { Layout, Menu, message } from 'antd';
import styled from 'styled-components';

// Constants
import Sidebar from '@constants/sidebar';
import THEME from '@constants/themes/themes';

import { IconAccount, IconExpandMore } from '@assets/icons';
import ChangePassword from '@components/ChangePassword';
import { SWITCH_ACCOUNT } from '@constants/APIs';
import {
	COL_GRID,
	DEFAULT_ACCOUNT_SETTING,
	NAME_NAVIGATION,
	PATH_WIDGET_MANAGER,
	RESOURCE_NAME,
	ROUTE_PATH,
	SUB_PATH,
	TIME_RELOAD_GET_ALL_NOTIFICATION_UNREAD,
} from '@constants/common';
import { LIST_TYPE_ALBUM_SUGGEST, TYPE_ALBUM } from '@constants/content/albums';
import { DEFAULT_ERROR } from '@constants/errors';
import { PATH_GALLERY } from '@constants/publish/gallery';
import { getUser, setClientSettings, setFolderSystem, setUser } from '@helpers/userHelpers';
import { AlbumBasicDto, AlbumRespDto } from '@models/content/albums/albumManager';
import { LocationStateDto } from '@models/messages/summary';
import { UserDto } from '@models/user/user';
import {
	changeInfoAccountSetting,
	checkLimitNotificationRequest,
	clientSettingsRequest,
	createTemplateEnd,
	createTemplateRequest,
	fetchListTemplateRequest,
	folderSystemRequest,
	getAccount,
	getAccountUserPermissionRequest,
	getAllNotificationRequest,
	storeUserData,
	switchClient,
	userLogout,
} from '@stores/actions';
import { CampaignDto, CampaignSimpleDto } from '@models/campaign';
import Notification from './Notification';
import { StyledIcon } from '@styled/Layout/Notify';
import {
	StyledAccount,
	StyledBrandName,
	StyledContainerProfile,
	StyledDropdown,
	StyledHeader,
	StyledInfoProfile,
	StyledLeftArrow,
	StyledLinkView,
	StyledName,
	StyledNameTitle,
	StyledProfile,
	StyledSearch,
	StyledSelect,
	StyledSwitchAccount,
	StyledTitle,
	StyledTitleSearch,
	StyledWrapperHeaderFeeds,
	StyledWrapperTitle,
} from '@styled/Header';
import { SocialContentStoreDto } from '@models/socialsearch/store';
import { FeedDto } from '@models/settings/socialSearch/socialAggregator/summary';
import { SocialSearchDto } from '@models/settings/socialSearch';
import { heightOverlayDropdown, renderNameSearchTerm } from '@utils/funcHelper';
import SearchCreator from './Filter/Creators/SearchCreator';
import { ContentStoreDto } from '@models/content/contentLibrary/store';
import { isEqual } from 'lodash';
import { getTokenCookie } from '@helpers/cookies';
import UploadContentInfo from '@components/Content/ContentLibrary/ModalImportContent/UploadContentInfo';
import { ContentLicensingStoreDto } from '@models/settings/contentLicensing/stores';
import DropdownLoadMore from '@cores/DropdownLoadMore';
import { ParamTemplateTypes, TemplateTypes } from '@models/settings/contentLicensing';
import { BODY_TEMPLATE, PARAM_TEMPLATE } from '@constants/settings/contentLicensing';
import { StyledCenterContainer } from '@styled/Messages/EmailTemplatesStyled';
import { Empty } from '@cores/index';
import { FAILED, SUCCEEDED } from '@constants/status';

type HeaderProps = {
	isCollapsedMenu: boolean;
};

const Header = (props: HeaderProps) => {
	const { t } = useTranslation();
	const { pathname, search: searchParam = '' } = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { gallerySelectDetail } = useSelector((state: any) => state.galleryManager);
	const { creatorToggleColumn, gridConfiguration, contentUploading }: ContentStoreDto =
		useSelector((state: any) => state.content);

	const { albumDetail, albums } = useSelector((state: any) => state.albumManager);
	const {
		templateDetail,
		templates,
		loadingTemplate,
		statusCreateTemplate,
		statusUpdateTemplate,
		templateCreate,
	}: ContentLicensingStoreDto = useSelector((state: any) => state.contentLicensing);
	const { detailFeed }: SocialContentStoreDto = useSelector((state: any) => state.socialContent);
	const { listFeedActive }: SocialSearchDto = useSelector(
		(state: any) => state.settings.socialSearch,
	);

	const { campaignDetail }: CampaignDto = useSelector((state: any) => state.campaign);

	const { listCampaign: simpleCampaigns = [] } = useSelector((state: any) => state.creator);
	const { state } = useLocation<LocationStateDto>();
	const PATH_ALBUM = ROUTE_PATH.ALBUMS;
	const isCampaignCreate = pathname.includes(`${ROUTE_PATH.CAMPAIGNS}/${SUB_PATH.CREATE}`);

	const refSwitchAccount = useRef<any>({});
	const refProfile = useRef<any>({});

	const [clientList, setClientList] = useState<UserDto[]>([]);
	const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
	const [showSwitchAccount, setShowSwitchAccount] = useState<boolean>(false);
	const [visiblePopupProfile, setVisiblePopupProfile] = useState<boolean>(false);
	const [paramTemplate, setParamTemplate] = useState<ParamTemplateTypes>(PARAM_TEMPLATE);
	const [listTemplate, setListTemplate] = useState<TemplateTypes[]>([]);

	let currentPath: any;
	// const isChildHeader = pathname.includes(ROUTE_PATH.CREATOR_DETAIL_EMPTY);
	// const fontSize = isChildHeader ? 5 : 3;
	const { clientSettings, folderSystem } = useSelector((state: any) => state.sidebar);

	const { isCollapsedMenu } = props;

	const user = getUser();
	const token = getTokenCookie();

	const accountSettings = user?.accountSettings || DEFAULT_ACCOUNT_SETTING;

	const { clientId, accountId } = user;

	Sidebar.forEach((item) => {
		if (item.child) {
			const subPath = item.child?.find(
				(sub) => sub.path === pathname || pathname.includes(sub.path),
			);
			if (subPath) {
				currentPath = subPath;
			}
		} else {
			if (item.path === pathname) {
				currentPath = item;
			}
		}
	});

	const handleCheckClickOutSide = (e: any) => {
		const switchElement = refSwitchAccount?.current;
		const profileElement = refProfile?.current;
		const targetElement = e.target;
		if (!targetElement.isConnected) return;
		if (
			(switchElement && switchElement.contains(targetElement)) ||
			(profileElement && profileElement.contains(targetElement))
		) {
			return;
		}

		if (showSwitchAccount) {
			setShowSwitchAccount(false);
		}
	};

	const funcSystem = () => {
		getListNotification();
		checkLimitRequest();
		dispatch(clientSettingsRequest());
		dispatch(folderSystemRequest());
	};

	const checkLimitRequest = () => {
		dispatch(
			checkLimitNotificationRequest({
				resourceName: RESOURCE_NAME.MEDIA,
			}),
		);
	};

	useEffect(() => {
		if (
			pathname.includes(ROUTE_PATH.CONTENT_LICENSING) &&
			pathname?.slice(ROUTE_PATH.CONTENT_LICENSING.length + 1) &&
			templateDetail?.id
		) {
			if (paramTemplate?.page === 1) {
				setListTemplate([]);
			}
			dispatch(fetchListTemplateRequest({ param: paramTemplate, body: BODY_TEMPLATE }));
		}
	}, [paramTemplate, templateDetail?.id]);

	useEffect(() => {
		if (paramTemplate?.page === 1) {
			setListTemplate(templates);
		} else {
			setListTemplate([...listTemplate, ...templates]);
		}
	}, [templates]);

	useEffect(() => {
		funcSystem();
		getClientList();

		const getAllNotification = setInterval(() => {
			dispatch(getAllNotificationRequest());
		}, TIME_RELOAD_GET_ALL_NOTIFICATION_UNREAD);
		return () => clearInterval(getAllNotification);
	}, []);

	useEffect(() => {
		if (clientSettings) {
			setClientSettings(clientSettings);
		}
	}, [clientSettings]);

	useEffect(() => {
		if (folderSystem) {
			setFolderSystem(folderSystem);
		}
	}, [folderSystem]);

	useEffect(() => {
		const listOld = {
			...accountSettings,
			creatorToggleColumn,
			gridConfiguration,
		};

		if (!isEqual(listOld, accountSettings)) {
			dispatch(changeInfoAccountSetting(accountSettings));
		}
	}, [accountSettings]);

	useEffect(() => {
		if (refSwitchAccount?.current && showSwitchAccount) {
			window.addEventListener('click', handleCheckClickOutSide);
			return () => {
				window.removeEventListener('click', handleCheckClickOutSide);
			};
		}
		return () => {};
	}, [refSwitchAccount?.current, showSwitchAccount]);

	useEffect(() => {
		if (searchParam) {
			const params: any = new Proxy(new URLSearchParams(searchParam), {
				get: (searchParams, prop: string) => searchParams.get(prop),
			});
			if (params?.clientId) {
				if (params?.clientId !== clientId) {
					onSwitchClient(params.clientId);
				}
				history.push(pathname);
			}
		}
	}, [searchParam]);

	useEffect(() => {
		if (statusCreateTemplate === SUCCEEDED || statusCreateTemplate === FAILED) {
			dispatch(createTemplateEnd());
		}
		if (statusCreateTemplate === SUCCEEDED && templateCreate) {
			history.push(`${ROUTE_PATH.CONTENT_LICENSING}/${templateCreate.id}`);
		}
	}, [statusCreateTemplate]);

	useEffect(() => {
		if (statusUpdateTemplate === SUCCEEDED && templateDetail) {
			setListTemplate(
				listTemplate.map((template) => {
					if (template.id === templateDetail.id) {
						return templateDetail;
					}
					return template;
				}),
			);
		}
	}, [statusUpdateTemplate]);

	const getListNotification = () => {
		dispatch(getAllNotificationRequest());
	};

	const getClientList = () => {
		const getClientSucceeded = (data: any) => {
			const clientFind = data.find((cl: UserDto) => cl.id === user?.clientId) || null;

			if (!data) {
				return;
			}
			data.sort((a: any, b: any) =>
				a.brandName.toLowerCase().localeCompare(b.brandName.toLowerCase()),
			);
			dispatch(
				storeUserData({
					currentClient: clientFind,
				}),
			);
			setClientList(data);
		};

		const getClientFailed = (error: any) => {
			message.error(error || DEFAULT_ERROR);
		};

		const payload = {
			getClientSucceeded: (data: any) => getClientSucceeded(data),
			getClientFailed: (error: any) => getClientFailed(error),
		};

		dispatch(getAccount(payload));
	};

	const onSwitchClient = (id: string) => {
		const clientFind = clientList.find((cl) => cl.id === +id) || null;
		if (parseInt(id, 10) === clientId) {
			return;
		}

		const payload = {
			url: `${SWITCH_ACCOUNT}?clientId=${id}`,
			switchClientSucceeded: (data: any) => switchClientSucceeded(data),
			switchClientFailed: (error: any) => switchClientFailed(error),
		};

		const switchClientSucceeded = async (data: any) => {
			const storedUser = getUser();
			const updatedUser = { ...storedUser, ...data };
			setUser(updatedUser);
			funcSystem();
			dispatch(folderSystemRequest());
			dispatch(getAccountUserPermissionRequest());
			dispatch(
				storeUserData({
					currentClient: clientFind,
				}),
			);
			if (pathname === ROUTE_PATH.DASHBOARD) {
				history.go(0);
			} else {
				history.push(ROUTE_PATH.DASHBOARD);
			}
		};

		const switchClientFailed = (error: any) => {
			message.error(error || DEFAULT_ERROR);
		};
		dispatch(switchClient(payload));
	};

	const onClickItem = ({ key }: any) => {
		onSwitchClient(key);
		return null;
	};

	const onClickUserItem = ({ key }: any) => {
		if (Number(key) === 3) {
			if (token) {
				dispatch(userLogout());
			} else {
				history.push(ROUTE_PATH.LOGIN);
			}
		} else if (Number(key) === 2) {
			handleModalChangePassword();
		} else {
			history.push(ROUTE_PATH.SETTINGS_USER_PROFILE);
		}

		return null;
	};

	const menu = (
		<Menu onClick={onClickUserItem}>
			<Menu.Item key="1">{t(NAME_NAVIGATION.PROFILE)}</Menu.Item>
			<Menu.Item key="2">{t(NAME_NAVIGATION.CHANGE_PASSWORD)}</Menu.Item>
			<Menu.Item key="3">{t(NAME_NAVIGATION.LOGOUT)}</Menu.Item>
		</Menu>
	);

	const onSelectAlbum = (album: AlbumBasicDto) => {
		if (album.type === TYPE_ALBUM.FAVORITE_ALBUM) {
			history.push(ROUTE_PATH.FAVORITE);
		} else {
			history.push(`${PATH_ALBUM}/${album.id}`);
		}
	};

	const onSelectCampaign = (campaign: CampaignSimpleDto) => {
		history.push(`${ROUTE_PATH.CAMPAIGNS}/${campaign?.id}`);
	};

	const listAlbumShow = useMemo(() => {
		if (albumDetail && LIST_TYPE_ALBUM_SUGGEST.includes(albumDetail?.type)) {
			return [albumDetail, ...albums];
		}
		return albums;
	}, [albumDetail, albums]);

	const isAlbumDetail = useMemo(() => {
		if (
			pathname.includes(`${PATH_ALBUM}`) &&
			pathname?.slice(PATH_ALBUM.length + 1) &&
			albumDetail?.name
		) {
			return true;
		}
		return false;
	}, [pathname, albumDetail]);

	const isTemplateTermsDetail = useMemo(() => {
		if (
			pathname.includes(`${ROUTE_PATH.CONTENT_LICENSING}`) &&
			pathname?.slice(ROUTE_PATH.CONTENT_LICENSING.length + 1) &&
			templateDetail?.name
		) {
			return true;
		}
		return false;
	}, [pathname, templateDetail]);

	const isCampaignDetail = useMemo(() => {
		return (
			pathname.includes(`${ROUTE_PATH.CAMPAIGNS}/`) &&
			pathname?.slice(ROUTE_PATH.CAMPAIGNS.length + 1) &&
			(isCampaignCreate || campaignDetail)
		);
	}, [pathname, campaignDetail]);

	const menuActions = (
		<Menu>
			{listAlbumShow?.map((item: AlbumRespDto) => (
				<Menu.Item onClick={() => onSelectAlbum(item)} key={item?.id}>
					{item?.name}
				</Menu.Item>
			))}
		</Menu>
	);

	const onSelectFeed = (feed: FeedDto) => {
		history.push(`${ROUTE_PATH.FEEDS}/${feed.id}`);
	};

	const menuActionFeed = (
		<Menu>
			{listFeedActive?.map((item: FeedDto) => (
				<Menu.Item
					className={item.id === detailFeed?.id ? 'active_menu_item' : ''}
					onClick={() => onSelectFeed(item)}
					key={item?.id}>
					{renderNameSearchTerm(item)}
				</Menu.Item>
			))}
		</Menu>
	);

	const menuActionCampaign = (
		<Menu>
			{simpleCampaigns?.map((item: CampaignSimpleDto) => (
				<Menu.Item onClick={() => onSelectCampaign(item)} key={item?.id}>
					{item?.name}
				</Menu.Item>
			))}
			{!isCampaignCreate && (
				<Menu.Item
					onClick={() => history.push(`${ROUTE_PATH.CAMPAIGNS}/${SUB_PATH.CREATE}`)}>
					{t('button.add_a_campaign')}
				</Menu.Item>
			)}
		</Menu>
	);

	const renderPrevRoute = () => {
		if (
			pathname?.includes(PATH_WIDGET_MANAGER) &&
			pathname?.slice(PATH_WIDGET_MANAGER.length + 1)
		) {
			return t(NAME_NAVIGATION.WIDGET_DETAIL);
		}

		if (pathname?.includes(ROUTE_PATH.FAVORITE)) {
			return t(NAME_NAVIGATION.ALBUMS);
		}

		if (pathname?.includes(ROUTE_PATH.FEEDS)) {
			return t(NAME_NAVIGATION.SOCIAL_SEARCH);
		}

		if (pathname?.includes(ROUTE_PATH.CREATOR_DETAIL_EMPTY)) {
			return t(NAME_NAVIGATION.CRM);
		}

		if (pathname === ROUTE_PATH.AMBASSADOR_DASHBOARD) {
			return t('sidebar.ambassador_dashboard');
		}

		if (currentPath.path === ROUTE_PATH.CAMPAIGNS) {
			return t(NAME_NAVIGATION.CAMPAIGNS);
		} else {
			const hasSub = pathname.slice(currentPath.path.length + 1);

			return t(hasSub ? currentPath?.name : currentPath?.headerName || currentPath?.name);
		}
	};

	const handleModalChangePassword = () => {
		setIsChangePassword(!isChangePassword);
	};

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const checkIsDetail = (pathCheck: string) => {
		if (pathname.includes(pathCheck)) {
			const idCheck = pathname.slice(pathCheck.length);

			return !!idCheck;
		}
		return false;
	};

	const otherPropTitle = () => {
		const result: any = {};
		if (checkIsDetail(`${PATH_ALBUM}/`)) {
			result.onClick = () => history.push(PATH_ALBUM);
			result.className = 'has_event';
		}
		if (checkIsDetail(`${ROUTE_PATH.CAMPAIGNS}/`)) {
			result.onClick = () => history.push(ROUTE_PATH.CAMPAIGNS);
			result.className = 'has_event';
		}
		if (checkIsDetail(ROUTE_PATH.CREATOR_DETAIL_EMPTY)) {
			result.onClick = () => history.push(ROUTE_PATH.CREATORS_SUMMARY);
			result.className = 'has_event';
		}
		if (checkIsDetail(`${ROUTE_PATH.CONTENT_LICENSING}/`)) {
			result.onClick = () => history.push(ROUTE_PATH.CONTENT_LICENSING);
			result.className = 'has_event';
		}
		return result;
	};

	const handleRedirectFeedConfig = () => {
		const state: any = {};
		if (detailFeed) {
			state.feedId = detailFeed.id;
		} else {
			state.openSetting = true;
		}
		history.push({
			pathname: ROUTE_PATH.SETTINGS_SOCIAL_AGGREGATOR,
			state,
		});
	};

	const menuActionTemplate = (
		<Menu>
			<Menu.Item
				key={Math.random()}
				onClick={() => dispatch(createTemplateRequest(user?.email))}>
				{t('button.add_a_template')}
			</Menu.Item>

			{listTemplate.map((item) => {
				return (
					<Menu.Item
						className={templateDetail?.id === item?.id ? 'active' : ''}
						key={item.id}
						onClick={() => history.push(`${ROUTE_PATH.CONTENT_LICENSING}/${item.id}`)}>
						{item.name}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	const onLoadMoreTemplate = () => {
		if (!loadingTemplate && templates?.length > 0) {
			setParamTemplate({ ...paramTemplate, page: paramTemplate.page + 1 });
		}
	};

	const renderDropdownList = () => {
		if (isAlbumDetail && albumDetail) {
			return (
				<StyledDropdown
					className="dropdown_album"
					getPopupContainer={(triggerNode: HTMLElement) =>
						triggerNode?.parentNode as HTMLElement
					}
					placement="bottomLeft"
					overlayClassName="overlay_album"
					overlay={menuActions}
					trigger={['click']}>
					<StyledWrapperTitle>
						{`/ ${albumDetail?.name}`}
						<IconExpandMore
							style={{ position: 'absolute', top: '2px', right: 0, zIndex: 10 }}
						/>
					</StyledWrapperTitle>
				</StyledDropdown>
			);
		}

		if (isCampaignDetail) {
			return (
				<StyledDropdown
					className="dropdown_album"
					getPopupContainer={(triggerNode: HTMLElement) =>
						triggerNode?.parentNode as HTMLElement
					}
					placement="bottomLeft"
					overlayClassName="overlay_album"
					overlay={isCampaignCreate ? <></> : menuActionCampaign}
					trigger={['click']}>
					<StyledWrapperTitle>
						{`/ ${
							isCampaignCreate
								? t('campaign_detail.add_new_campaign')
								: campaignDetail?.name
						}`}
						{!isCampaignCreate && (
							<IconExpandMore
								style={{ position: 'absolute', top: '2px', right: 0, zIndex: 10 }}
							/>
						)}
					</StyledWrapperTitle>
				</StyledDropdown>
			);
		}

		if (isTemplateTermsDetail) {
			return (
				<DropdownLoadMore
					heightOverlay={heightOverlayDropdown(listTemplate.length + 1)}
					overlay={menuActionTemplate}
					isLoading={loadingTemplate}
					onLoadMore={onLoadMoreTemplate}
					getPopupContainer={(triggerNode: HTMLElement) =>
						triggerNode?.parentNode as HTMLElement
					}>
					<StyledWrapperTitle>
						{`/ ${templateDetail?.name}`}

						<IconExpandMore
							style={{ position: 'absolute', top: '2px', right: 0, zIndex: 10 }}
						/>
					</StyledWrapperTitle>
				</DropdownLoadMore>
			);
		}

		if (pathname.includes(PATH_GALLERY) && gallerySelectDetail?.name) {
			return <StyledWrapperTitle>{`/ ${gallerySelectDetail?.name}`}</StyledWrapperTitle>;
		}

		if (pathname.includes(ROUTE_PATH.CREATOR_DETAIL_EMPTY)) {
			return (
				<StyledWrapperTitle margin="0 0 0 5px" cursor="auto">{`/ ${t(
					'sidebar.creator_profile',
				)}`}</StyledWrapperTitle>
			);
		}

		if (pathname.includes(ROUTE_PATH.FEEDS)) {
			return (
				<StyledWrapperHeaderFeeds>
					{detailFeed && (
						<StyledDropdown
							className="dropdown_album"
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							placement="bottomLeft"
							overlayClassName="overlay_album"
							overlay={menuActionFeed}
							trigger={['click']}>
							<StyledWrapperTitle>
								{` / ${renderNameSearchTerm(detailFeed)}`}
								<IconExpandMore
									style={{
										position: 'absolute',
										top: '2px',
										right: 0,
										zIndex: 10,
									}}
								/>
							</StyledWrapperTitle>
						</StyledDropdown>
					)}
					<StyledLinkView margin="0 0 0 14px" onClick={() => handleRedirectFeedConfig()}>
						{t('feeds.view_update_feed_settings')}
					</StyledLinkView>
				</StyledWrapperHeaderFeeds>
			);
		}

		return null;
	};

	const renderMoreAction = () => {
		if (pathname.includes(ROUTE_PATH.CREATOR_DETAIL_EMPTY)) {
			return <SearchCreator />;
		}
		return null;
	};

	return (
		<StyledHeader collapse={isCollapsedMenu} className="site-layout-background">
			{contentUploading.length > 0 && <UploadContentInfo />}
			<StyledTitleSearch>
				<StyledTitle level={3}>
					<StyledNameTitle
						{...otherPropTitle()}>{`${renderPrevRoute()} `}</StyledNameTitle>

					{renderDropdownList()}
					{renderMoreAction()}
				</StyledTitle>
			</StyledTitleSearch>

			<StyledAccount>
				<StyledIcon>
					<Notification />
				</StyledIcon>
				{clientList?.length > 1 ? (
					<StyledSwitchAccount
						ref={refSwitchAccount}
						className={showSwitchAccount ? 'show_switch' : ''}>
						<StyledSelect
							notFoundContent={notFoundContent()}
							placeholder={'Switch Account'}
							onSelect={(key: any) => onClickItem({ key })}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							optionFilterProp={'label'}
							options={clientList.map((item: any) => {
								return { label: item?.brandName, value: item?.id };
							})}
							showSearch
						/>
					</StyledSwitchAccount>
				) : null}
				<StyledProfile ref={refProfile}>
					<StyledDropdown
						overlayClassName="auth-dropdown"
						overlay={menu}
						visible={visiblePopupProfile}
						onVisibleChange={(val: boolean) => {
							if (val && clientList?.length > 1) {
								setShowSwitchAccount(val);
							}
							setVisiblePopupProfile(val);
						}}
						placement="bottomRight"
						trigger={['click']}>
						<StyledContainerProfile>
							<StyledIcon>
								<IconAccount />
							</StyledIcon>
							<StyledInfoProfile>
								<StyledName>{`${user.firstName} ${user.lastName}`}</StyledName>
								<StyledBrandName>
									{user?.brandName} <IconExpandMore />
								</StyledBrandName>
							</StyledInfoProfile>
						</StyledContainerProfile>
					</StyledDropdown>
				</StyledProfile>
			</StyledAccount>

			<ChangePassword
				isOpen={isChangePassword}
				handleModalChangePassword={handleModalChangePassword}
			/>
		</StyledHeader>
	);
};

export default Header;
