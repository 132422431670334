import { IconCopy, IconError, IconEye } from '@assets/icons';
import CustomSwitch from '@components/CustomSwitch';
import { tremendousURL } from '@config/index';
import { TYPE_ACTIONS } from '@constants/common';
import { MAX_LENGTH_CHARACTER } from '@constants/content/contentLibrary';
import { ROLE } from '@constants/settings';
import {
	KEY_FIELD_TREMENDOUS,
	STATUS_TREMENDOUS,
	WIDTH_API_KEY,
} from '@constants/settings/integrations';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import THEME from '@constants/themes/themes';
import SettingIntegrationsContext from '@contexts/Settings/SettingIntegrations';
import { getUser } from '@helpers/userHelpers';
import { OptionDto } from '@models/publishing/galleries';
import {
	TremendousCampaignTypes,
	TremendousDataTypes,
	TremendousFundingSourcesTypes,
	TremendousRequestDefaultTypes,
	TremendousStoreDto,
} from '@models/settings/tremendous/store';
import {
	changeFieldsClientSetting,
	changeTremendousField,
	fetchTremendousInfoRequest,
	saveTremendousDefaultEnd,
	saveTremendousDefaultRequest,
} from '@stores/actions';
import {
	StyledDivider,
	StyledFormItem,
	StyledIcon,
	StyledInput,
	StyledLinkHref,
	StyledSelect,
	StyledTitle,
	StyledWrapperContent,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledInputPassword } from '@styled/Settings/IntegrationsStyled';
import { numberWithCommas } from '@utils/common';
import { capitalizeFirstLetter, limitNumberInput, onCopyValue } from '@utils/funcHelper';
import { notFoundContent } from '@utils/renderComponent';
import { Button, Col, Form, Row, message } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type FormFieldTypes = {
	rewardAmount: number;
	campaignId: string;
	fundingSourceId: string;
};

const Tremendous = () => {
	const { t } = useTranslation();

	const user = getUser();
	const { role } = user;

	const isEReviewer = role.toLowerCase() === ROLE.EREVIEWER.value.toLowerCase();
	const isCAdmin = role.toLowerCase() === ROLE.CADMIN.value.toLowerCase();

	const enableTremendous = isEReviewer || isCAdmin;

	const { isLoading, setIsLoading } = useContext(SettingIntegrationsContext);

	const {
		fetchingTremendous,
		tremendousInfo,
		tremendousDefault,
		saveTremendousDefaultStatus,
		error,
	}: TremendousStoreDto = useSelector((state: any) => state.tremendous);
	const dispatch = useDispatch();

	const [form] = Form.useForm();
	const [formKey] = Form.useForm();

	const refChange = useRef<any>(null);

	const [enabled, setEnabled] = useState<boolean>(false);
	const [showKey, setShowKey] = useState<boolean>(false);
	const [hasChange, setHasChange] = useState<boolean>(false);
	const [waitAction, setWaitAction] = useState<string>('');
	const [campaignList, setCampaignList] = useState<TremendousCampaignTypes[]>([]);
	const [fundingSourceList, setFundingSourceList] = useState<TremendousFundingSourcesTypes[]>([]);
	const [hasChangeKey, setHasChangeKey] = useState<boolean>(false);
	const [hasApiKey, setHasApiKey] = useState<boolean>(false);

	useEffect(() => {
		fetchTremendousInfoWithApiKey({ isDefault: true });
		return () => {
			dispatch(changeTremendousField({ tremendousDefault: null, tremendousInfo: null }));
		};
	}, []);

	useEffect(() => {
		if (!fetchingTremendous) {
			if (!error) {
				setHasChangeKey(false);
			}
			setIsLoading(false);
		}
	}, [fetchingTremendous]);

	useEffect(() => {
		if (saveTremendousDefaultStatus === IN_PROGRESS) {
			setIsLoading(true);
		}
		if (saveTremendousDefaultStatus === FAILED) {
			message.error(error);
			setIsLoading(false);
		}
		if (saveTremendousDefaultStatus === SUCCEEDED) {
			fetchTremendousInfoWithApiKey({
				isDefault: true,
				isClearAll: hasApiKey ? false : true,
			});
			message.success(t('message.save_changes_successfully'));

			dispatch(
				changeFieldsClientSetting({
					tremendousEnabled: enabled && hasApiKey,
				}),
			);
			setHasChange(false);
			setHasChangeKey(false);
		}
		if (saveTremendousDefaultStatus === SUCCEEDED || saveTremendousDefaultStatus === FAILED) {
			dispatch(saveTremendousDefaultEnd());
		}
	}, [saveTremendousDefaultStatus]);

	useEffect(() => {
		if (tremendousInfo) {
			if (waitAction === TYPE_ACTIONS.SAVE_CHANGES) {
				setWaitAction('');
				form.submit();
			}
			handleFieldData(tremendousInfo);
		} else {
			resetField();
		}
	}, [tremendousInfo]);

	const handleFieldData = (info: TremendousDataTypes) => {
		const { campaigns, fundingSources, tremendousSettings } = info;

		setCampaignList(campaigns);
		setFundingSourceList(fundingSources);
		if (tremendousSettings) {
			const { apiKey, campaignId, enabled, fundingSourceId, rewardAmount } =
				tremendousSettings;
			form.setFieldsValue({ campaignId, fundingSourceId, rewardAmount });
			formKey.setFieldsValue({ apiKey });
			setHasApiKey(!!apiKey);
			setEnabled(!!enabled);
			refChange.current = apiKey;
		}
	};

	const fetchTremendousInfoWithApiKey = ({
		apiKey,
		isDefault,
		isClearAll,
	}: {
		apiKey?: string;
		isDefault?: boolean;
		isClearAll?: boolean;
	}) => {
		dispatch(fetchTremendousInfoRequest({ apiKey, isDefault, isClearAll }));
	};

	const resetField = () => {
		if (!tremendousDefault?.tremendousSettings) {
			form.resetFields();
			formKey.resetFields();
			setHasApiKey(false);
			setEnabled(false);
		}

		if (tremendousDefault) {
			handleFieldData(tremendousDefault);
		} else {
			setCampaignList([]);
			setFundingSourceList([]);
		}
	};
	const onRemoveAllField = () => {
		form.setFieldsValue({
			[KEY_FIELD_TREMENDOUS.AMOUNT]: null,
			[KEY_FIELD_TREMENDOUS.CAMPAIGN]: null,
			[KEY_FIELD_TREMENDOUS.FUNDING_SOURCE]: null,
		});
	};

	const onValuesChangeFormKey = (obj: any) => {
		if (!enabled) {
			formKey.setFieldsValue({ [KEY_FIELD_TREMENDOUS.API_KEY]: refChange.current });
			setHasApiKey(!!refChange.current);
		} else {
			refChange.current = obj[KEY_FIELD_TREMENDOUS.API_KEY];
			setHasChangeKey(true);
			setHasChange(true);
			setHasApiKey(!!obj[KEY_FIELD_TREMENDOUS.API_KEY]);
			onRemoveAllField();
			setCampaignList([]);
			setFundingSourceList([]);
		}
	};

	const onBeforeInputFormKey = (val: React.ChangeEvent<HTMLFormElement>) => {
		refChange.current = val.target.value;
	};

	const onValuesChange = (obj: any) => {
		const key = Object.keys(obj)[0];
		if (key === KEY_FIELD_TREMENDOUS.AMOUNT) {
			const amount = Math.floor(+obj[key] * 100) / 100;
			const res = limitNumberInput(amount) || 0;

			if (res !== +obj[key]) {
				form.setFieldsValue({ [KEY_FIELD_TREMENDOUS.AMOUNT]: res.toString() });
			}
		}
		if (!hasChange) {
			setHasChange(true);
		}
	};

	const handleFinishFormKey = (values: { apiKey: string }) => {
		onRemoveAllField();
		fetchTremendousInfoWithApiKey({ apiKey: values.apiKey });
	};

	const handleFinishForm = (values: FormFieldTypes) => {
		const { campaignId, fundingSourceId, rewardAmount } = values;
		const apiKey = formKey.getFieldValue(KEY_FIELD_TREMENDOUS.API_KEY);

		const dataRequest: TremendousRequestDefaultTypes = {
			apiKey,
			campaignId,
			enabled,
			fundingSourceId,
			rewardAmount: +(rewardAmount || 0),
		};
		dispatch(saveTremendousDefaultRequest(dataRequest));
	};

	const onCancelTemplate = () => {
		setHasChange(false);
		setHasChangeKey(false);
		resetField();
	};
	const onSubmitFormTemplate = () => {
		const apiKey = formKey.getFieldValue(KEY_FIELD_TREMENDOUS.API_KEY);

		if (hasChangeKey && apiKey) {
			formKey.submit();
			setWaitAction(TYPE_ACTIONS.SAVE_CHANGES);
			return;
		}
		setWaitAction('');
		form.submit();
	};

	const onCopy = () => {
		const apiKey = formKey.getFieldValue(KEY_FIELD_TREMENDOUS.API_KEY);

		onCopyValue(apiKey);
		message.success(t('campaign.message.copy_success'));
	};

	const listInfo = [
		{
			name: t('tremendous.organization_lb'),
			value: tremendousInfo?.organization || '',
		},
		{
			name: t('tremendous.website_lb'),
			value: tremendousInfo?.website || '',
		},
		{
			name: t('tremendous.tremendous_account_status_lb'),
			value: capitalizeFirstLetter(tremendousInfo?.status || ''),
		},
	];

	const renderSelect = (options: OptionDto[]) => {
		return (
			<StyledSelect
				showSearch
				notFoundContent={notFoundContent(t)}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				virtual={false}
				optionFilterProp="label"
				showArrow
				options={options}
				dropdownClassName="dropdown-menu dropdown-custom-content"
			/>
		);
	};

	const formField = [
		{
			name: KEY_FIELD_TREMENDOUS.AMOUNT,
			options: [],
			label: t('tremendous.reward_amount'),
			span: 7,
			rules: [
				{
					required: hasApiKey ? true : false,
					message: t('validate.required'),
				},
				() => ({
					validator(_: any, val: string) {
						const value = +val;

						if (value < 0) {
							form.setFieldsValue({
								[KEY_FIELD_TREMENDOUS.AMOUNT]: Math.abs(value),
							});
							return Promise.resolve();
						}
						if (value === 0 && val) {
							return Promise.reject(
								new Error(t('validate.value_greater', { min: 0 })),
							);
						}
						return Promise.resolve();
					},
				}),
			],
			content: (
				<StyledInput
					placeholder={t('placeholder.unit_value')}
					height={32}
					className="hidden_arrow"
					type="number"
					prefix={'$'}
				/>
			),
		},
		{
			name: KEY_FIELD_TREMENDOUS.CAMPAIGN,
			options: [],
			label: t('tremendous.campaign'),
			span: 7,
			content: renderSelect(
				campaignList.map((item) => ({
					label: t('tremendous.template_label', { name: item.name, id: item.id }),
					value: item.id,
				})),
			),
			rules: hasApiKey
				? [
						{
							required: true,
							message: t('validate.required'),
						},
				  ]
				: [],
		},
		{
			name: KEY_FIELD_TREMENDOUS.FUNDING_SOURCE,
			options: [],
			label: t('tremendous.funding_source'),
			span: 10,
			content: renderSelect(
				fundingSourceList.map((item) => ({
					label: `${capitalizeFirstLetter(item.method)} (${t('tremendous.value_unit', {
						val: numberWithCommas(+item.meta.available_cents / 100, 2, true),
					})})`,
					value: item.id,
				})),
			),
			rules: hasApiKey
				? [
						{
							required: true,
							message: t('validate.required'),
						},
				  ]
				: [],
		},
	];

	return (
		<StyledWrapperContent
			padding="30px"
			width="100%"
			className={enableTremendous ? '' : 'disabled'}
			background={THEME.colors.darkBlue2}
			opacity={1}
			borderRadius="2px">
			<StyledWrapperTitle justifyContent="start">
				<StyledTitle
					margin="0 8px 0 0"
					className="unset_height"
					fontSize="24px"
					color={THEME.colors.white}>
					{t('tremendous.tab_tremendous')}
				</StyledTitle>
				<CustomSwitch
					checked={enabled}
					setChecked={(val: boolean) => {
						setEnabled(val);
					}}
				/>
			</StyledWrapperTitle>

			<StyledTitle
				style={enableTremendous ? {} : { pointerEvents: 'auto' }}
				className="unset_height"
				color={THEME.colors.gray3}
				margin="8px 0 0 0">
				<Trans
					t={t}
					components={{
						elm: (
							<StyledLinkHref
								hoverColor={THEME.colors.orangeBase}
								fontWeight="normal"
								margin="0 2px"
								underline
								href={tremendousURL}
								color="inherit"
								target="_blank"
								onClick={(e: React.MouseEvent<HTMLElement>) => {
									e.stopPropagation();
								}}
							/>
						),
					}}
					i18nKey="tremendous.sub_tremendous"
				/>
			</StyledTitle>
			<Form
				initialValues={{}}
				form={formKey}
				style={{ position: 'relative' }}
				onValuesChange={onValuesChangeFormKey}
				onFinish={handleFinishFormKey}
				autoComplete="off"
				onBeforeInput={onBeforeInputFormKey}>
				<StyledFormItem
					className="hidden_start_required"
					labelCol={{ span: 24 }}
					labelHeight={'18px'}
					name={KEY_FIELD_TREMENDOUS.API_KEY}
					rules={[]}
					label={t('tremendous.api_key')}>
					<StyledInputPassword
						maxLength={MAX_LENGTH_CHARACTER}
						autoComplete={'off'}
						suffix={
							<StyledIcon
								onClick={() => setShowKey(!showKey)}
								size={20}
								margin="0 0 0 4px">
								<IconEye />
							</StyledIcon>
						}
						type="password"
					/>
				</StyledFormItem>
				<StyledIcon
					className="absolute icon_hover"
					top="34px"
					left={`${WIDTH_API_KEY + 10}px`}
					onClick={() => onCopy()}>
					<IconCopy />
				</StyledIcon>
			</Form>

			{listInfo.map((item) => (
				<StyledTitle margin="8px 0 0 0" className="unset_height">
					{item.name} <b>{item.value}</b>
				</StyledTitle>
			))}
			{tremendousDefault?.status &&
				tremendousDefault?.status !== STATUS_TREMENDOUS.APPROVED && (
					<StyledWrapperTitle margin="8px 0 0 0" justifyContent="left">
						<StyledIcon>
							<IconError />
						</StyledIcon>
						<StyledTitle
							color={THEME.colors.redBase}
							margin="0 0 0 4px"
							className="unset_height">
							{t('tremendous.message.account_must_approved')}
						</StyledTitle>
					</StyledWrapperTitle>
				)}
			<StyledDivider borderColor={THEME.colors.darkBlue3} margin="16px 0" />
			<StyledTitle className="unset_height" color={THEME.colors.white} fontSize="16px">
				{t('tremendous.defaults')}
			</StyledTitle>
			<StyledTitle className="unset_height" color={THEME.colors.gray3} margin="8px 0">
				{t('tremendous.sub_default')}
			</StyledTitle>
			<Form
				initialValues={{}}
				form={form}
				onValuesChange={onValuesChange}
				onFinish={handleFinishForm}>
				<Row gutter={[16, 16]}>
					{formField.map((item) => (
						<Col span={item.span} key={item.name}>
							<StyledFormItem
								className="hidden_start_required"
								labelCol={{ span: 24 }}
								labelHeight={'18px'}
								name={item.name}
								rules={item.rules}
								label={item.label}>
								{item.content}
							</StyledFormItem>
						</Col>
					))}
				</Row>
			</Form>
			{(hasChange || !!tremendousInfo?.tremendousSettings?.enabled !== enabled) && (
				<StyledWrapperContent
					margin="8px 0 0 0"
					width="100%"
					display="flex"
					alignItems="center"
					justifyContent="end">
					<Button onClick={() => onCancelTemplate()}>{t('button.cancel')}</Button>
					<StyledTitle
						margin="0 13px"
						className="unset_height"
						color={THEME.colors.gray1}
						fontSize="14px"
						display="inline-flex">
						{t('social_aggregator.unsaved_changes')}
					</StyledTitle>
					<Button onClick={() => onSubmitFormTemplate()} type="primary">
						{t('button.save_changes')}
					</Button>
				</StyledWrapperContent>
			)}
		</StyledWrapperContent>
	);
};

export default Tremendous;
