import { combineReducers } from 'redux';

// Reducers
import userReducers from '@stores/user/user.reducer';
import headerReducers from '@stores/header/header.reducers';
import contentReducers from '@stores/content/contentLibrary/content.reducers';
import socialSearchReducers from '@stores/socialsearch/socialsearch.reducers';
import creatorReducers from '@stores/creator/creator.reducer';
import rewardsReducers from '@stores/rewards/rewards.reducer';
import sidebarReducers from '@stores/sidebar/sidebar.reducers';
import messagesReducers from '@stores/messages/messages.reducers';
import settingReducers from '@stores/settings/settings.reducers';
import campaignReducers from '@stores/campaign/campaign.reducers';
import dashboardReducers from '@stores/dashboard/dashboard.reducers';
import widgetManagerReducers from '@stores/content/widgetManager/widgetManager.reducers';
import galleryManagerReducers from '@stores/publishing/galleries/galleryManager.reducers';
import albumManagerReducers from '@stores/content/albumManager/albumManager.reducers';
import notificationReducers from '@stores/notification/notification.reducers';
import ambassadorReducers from './ambassador/ambassador.reducers';
import taskManagerReducers from './taskmanager/taskmanager.reducers';
import contentLicensingReducers from './contentLicensing/contentLicensing.reducers';
import tremendousReducers from './tremendous/tremendous.reducers';
import landingPageManagerReducers from './landingPageManager/landingPageManager.reducers';
import guestPortalReducers from './guestportal/guestportal.reducers';

export default combineReducers({
	user: userReducers,
	header: headerReducers,
	content: contentReducers,
	socialContent: socialSearchReducers,
	creator: creatorReducers,
	rewards: rewardsReducers,
	sidebar: sidebarReducers,
	messages: messagesReducers,
	settings: settingReducers,
	campaign: campaignReducers,
	dashboard: dashboardReducers,
	widgetManager: widgetManagerReducers,
	galleryManager: galleryManagerReducers,
	albumManager: albumManagerReducers,
	notification: notificationReducers,
	ambassadors: ambassadorReducers,
	taskManager: taskManagerReducers,
	contentLicensing: contentLicensingReducers,
	tremendous: tremendousReducers,
	landingPage: landingPageManagerReducers,
	guestPortal: guestPortalReducers,
});
