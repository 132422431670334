import { Empty, Collapse, Tabs } from 'antd';
import styled from 'styled-components';
import THEME from '@constants/themes/themes';

const StyledEmpty = styled(Empty)`
	margin: 0;

	.ant-empty-image {
		width: 100%;
		height: unset;
	}
`;

const StyledWrapper = styled.div`
	.ant-table-wrapper {
		margin-top: 50px;

		.ant-checkbox {
			margin-bottom: 5px;
		}

		.ant-table-column-title {
			margin-right: 10px;
		}

		.ant-table {
			.ant-table-selection-column {
				width: 50px;
			}
			.ant-table-body {
				height: calc(100vh - 242px);
			}
		}
	}
`;

const StyledPagination = styled.div`
	background-color: ${THEME.colors.darkBlue1};
	width: 100%;
	display: flex;
	height: 32px;
	align-items: center;
	justify-content: center;
	// position: fixed;
	// bottom: 0;
`;

const StyledCollapse = styled(Collapse)`
	background-color: ${THEME.colors.darkBlue2};

	.ant-collapse-header {
		color: ${THEME.colors.gray5} !important;
		font-weight: 600;
	}
	.ant-collapse-content {
		background-color: ${THEME.colors.darkBlue2};
	}
	.ant-collapse-item {
		border: none;
	}
	&.hidden_content {
		.ant-collapse-content {
			display: none;
		}
	}
`;

const StyledTabs = styled(Tabs)`
	background: ${THEME.colors.darkBlue};
	margin: 0;

	.ant-tabs-nav {
		margin-bottom: 0 !important;
		border-bottom: 1px solid ${THEME.colors.darkBlue3};

		.ant-tabs-tab {
			padding: 12px 20px;
			background: none;
			border: none;
			border-bottom: 2px solid transparent;

			&.ant-tabs-tab-active {
				border-bottom: 2px solid ${THEME.colors.primary};
				opacity: 1;

				.ant-tabs-tab-btn {
					color: ${THEME.colors.gray5};
				}
			}
		}

		&:before {
			border-top: none;
			border-bottom: 0.9px solid ${THEME.colors.darkBlue3};
		}

		.ant-tabs-nav-wrap {
			padding-left: 15px;
			height: 36px;
		}
	}

	.ant-tabs-content {
		overflow-y: auto;
	}
`;

const StyledMarginLeft = styled.div`
	margin-left: 20px;
`;

export {
	StyledTabs,
	StyledWrapper,
	StyledEmpty,
	StyledPagination,
	StyledCollapse,
	StyledMarginLeft,
};
