import { entribeLogo } from '@assets/images';
import THEME from '@constants/themes/themes';
import { LoginWithMFADto } from '@models/user/user';
import { loginWithMFAEnd, loginWithMFARequest } from '@stores/actions';
import { UserStoreType } from '@stores/user/user.types';
import {
	StyledButton,
	StyledCustomTitle,
	StyledFlex,
	StyledForm,
	StyledFormItem,
	StyledLinkHref,
	StyledText,
	StyledTooltip,
} from '@styled/Common/CommonStyled';
import { StyledImg, StyledInputMFACode, StyledLogo } from '@styled/LoginStyled';
import { validateInputNumber } from '@utils/common';
import { Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type LoginWithMFAProps = {
	visible: boolean;
	onGoBack: () => void;
};

const LoginWithMFA = (props: LoginWithMFAProps) => {
	const [form] = useForm();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { visible, onGoBack } = props;
	const { fetchingUser, mfaLoginToken }: UserStoreType = useSelector((state: any) => state.user);

	useEffect(() => {
		return () => {
			resetData();
		};
	}, [visible]);

	const resetData = () => {
		form.resetFields();
		dispatch(loginWithMFAEnd());
	};

	const loginWithMFA = (payload: LoginWithMFADto) => {
		dispatch(loginWithMFARequest(payload));
	};

	const onFinish = (values: any) => {
		if (mfaLoginToken) {
			const payload = { ...values, token: mfaLoginToken };
			loginWithMFA(payload);
		}
	};

	const havingTroubleTransProps = {
		i18nKey: 'login.having_trouble_tooltip',
		components: {
			linkTo: <StyledLinkHref color={THEME.colors.orangeBase} />,
		},
	};

	return (
		<Modal visible={visible} footer={false} centered closable={false}>
			<StyledLogo>
				<StyledImg src={entribeLogo} alt={t('company_name')} />
			</StyledLogo>
			<StyledCustomTitle
				className="login-title"
				fontSize="1.714rem"
				fontWeight="700"
				margin="0 0 32px 0">
				{t('login.verification_required')}
			</StyledCustomTitle>
			<StyledText margin="0 0 12px 0">
				{t('setting.user_profile.enter_digit_authentication_code')}
			</StyledText>
			<StyledForm form={form} onFinish={onFinish}>
				<StyledFormItem
					rules={[
						{
							required: true,
							message: t('validation.required', {
								field: t('setting.user_profile.message.authentication_code'),
							}),
						},
					]}
					name="code">
					<StyledInputMFACode
						autoFocus
						maxLength={6}
						onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
							validateInputNumber(event);
						}}
					/>
				</StyledFormItem>
			</StyledForm>
			<StyledFlex justify="flex-end" padding="46px 0 0 0">
				<StyledTooltip trigger={['click']} title={<Trans {...havingTroubleTransProps} />}>
					<StyledText margin="0 12px 0 0" cursor="pointer">
						<u>{t('login.having_trouble')}</u>
					</StyledText>
				</StyledTooltip>
				<StyledButton margin="0 12px 0 0" onClick={onGoBack}>
					{t('button.go_back')}
				</StyledButton>
				<StyledButton loading={fetchingUser} type="primary" onClick={form.submit}>
					{t('button.confirm')}
				</StyledButton>
			</StyledFlex>
		</Modal>
	);
};

export default LoginWithMFA;
