import { Affix, ConfigProvider, Dropdown, Pagination, Select, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Styled
import { IconMoreVert } from '@assets/icons';
import IconEmpty from '@assets/icons/svg/general_icon_empty.svg';
import { StyledTable } from '@components/CustomTable';
import { Paginator as defaultPage } from '@constants/paginator';
import { ACCOUNT_STATUS, ROLE, USER_ACCESS_RULES } from '@constants/settings';
import { IN_PROGRESS, SUCCEEDED } from '@constants/status';
import AccountUserContext from '@contexts/Settings/AccountUser';
import { ConfirmModal } from '@cores/Modal';
import { getObject } from '@helpers/storageHelpers';
import { getUser } from '@helpers/userHelpers';
import { ColumnDto, SelectOptionDto } from '@models/common/summary';
import { AccountUserDto, ActionsDto } from '@models/settings/socialSearch/accountuser.ts/summary';
import { ActionsTypes } from '@models/socialsearch/summary';
import { resetNotificationAccount, updateAccountUser, updateAccountUserEnd } from '@stores/actions';
import { StyledEmpty, StyledIconWrapper } from '@styled/Common/CommonStyled';
import {
	StyledPagination,
	StyledSelect,
	StyledSwitchStatus,
	StyledWrapperTable,
} from '@styled/Settings/AccountUserStyled';
import { userHasPermission, validateEmail } from '@utils/common';
import { menuActions, renderCopiedText } from '@utils/renderComponent';
import NotificationModal from '../NotificationModal';

const { Option } = Select;

const AccountUserList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { setIsLoading, accountPayload, setAccountPayload, selectedRowKeys, setSelectedRowKeys } =
		useContext(AccountUserContext);
	const {
		accountUserData,
		getAccountListStatus,
		account: { loadingUpdate },
	} = useSelector((state: any) => state.settings);
	const { accountUserPermissions } = useSelector((state: any) => state.user);
	const infoUser = getObject('user');

	const { brandName = '' } = infoUser;

	const [userSelect, setUserSelect] = useState<any>(null);
	const [roleChange, setRoleChange] = useState<any>('');
	const [visiblePopupStatus, setVisiblePopupStatus] = useState<boolean>(false);
	const [moreActions, setMoreActions] = useState<ActionsDto>({
		isNotification: false,
	});

	const user = getUser();
	const widthColumnDefault = 150;
	const accountUserColumns: ColumnDto[] = [
		{
			title: t('setting.account_user.info.first_name'),
			dataIndex: 'firstName',
			render: (firstName: string) => renderCopiedText(firstName),
			key: 'firstName',
		},
		{
			title: t('setting.account_user.info.last_name'),
			dataIndex: 'lastName',
			render: (lastName: string) => renderCopiedText(lastName),
			key: 'lastName',
		},
		{
			title: t('setting.account_user.info.email'),
			dataIndex: 'email',
			width: widthColumnDefault + 100,
			key: 'email',
			render: (val: string) => renderCopiedText(val),
		},
		{
			title: t('setting.account_user.info.status'),
			dataIndex: 'status',
			render: (status: string, record: AccountUserDto) => renderStatus(status, record),
			key: 'status',
		},
		{
			title: t('setting.account_user.info.roles'),
			dataIndex: 'role',
			width: widthColumnDefault + 100,
			render: (role: string, record: AccountUserDto) => renderRoles(role, record),
			key: 'role',
		},
	];

	const actionsObj: ActionsTypes[] = [
		{
			name: t('setting.account_user.actions.view_notification'),
			click: () => {
				setMoreActions({
					isNotification: true,
				});
			},
		},
	];

	if (user.role === ROLE.EREVIEWER.value) {
		const moreActions = {
			title: '',
			width: widthColumnDefault,
			key: 'actions',
			render: (record: AccountUserDto) => renderActionColumn(record),
		};
		accountUserColumns.push(moreActions);
	}

	useEffect(() => {
		if (getAccountListStatus && getAccountListStatus === SUCCEEDED) {
			setIsLoading(false);
		}
	}, [getAccountListStatus]);

	useEffect(() => {
		if (loadingUpdate && loadingUpdate !== IN_PROGRESS) {
			setIsLoading(false);
		}
		if (loadingUpdate && loadingUpdate === SUCCEEDED) {
			setUserSelect(null);
			setRoleChange('');
			dispatch(updateAccountUserEnd());
			message.success(t('setting.message.update_user_success'));
		}
	}, [loadingUpdate]);

	const renderActionColumn = (record: AccountUserDto) => {
		return (
			<Dropdown overlay={menuActions(actionsObj)} placement="topRight" trigger={['click']}>
				<StyledIconWrapper
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						e.preventDefault();
						e.stopPropagation();
						setUserSelect(record);
					}}
					cursor="pointer"
					margin="0 8px">
					<IconMoreVert />
				</StyledIconWrapper>
			</Dropdown>
		);
	};

	const renderStatus = (val: string, record: AccountUserDto) => {
		if (val === ACCOUNT_STATUS.PENDING) {
			return <span>{t('setting.account_user.info.pending')}</span>;
		}
		const status = val === ACCOUNT_STATUS.ACTIVE ? true : false;

		return (
			<StyledSwitchStatus
				onClick={(record, e: any) => {
					e.preventDefault();
					e.stopPropagation();
				}}
				onChange={() => {
					setUserSelect(record);
					setVisiblePopupStatus(true);
				}}
				checked={status}
			/>
		);
	};

	const onUpdateAccount = () => {
		const { key, username = '', email = '', ...otherData } = userSelect;
		const dataRequest = {
			...otherData,
			username: validateEmail(username) ? username : email,
			email,
		};
		if (roleChange) {
			dataRequest.role = roleChange;
		} else {
			dataRequest.status =
				otherData?.status === ACCOUNT_STATUS.ACTIVE
					? ACCOUNT_STATUS.INACTIVE
					: ACCOUNT_STATUS.ACTIVE;
		}
		setIsLoading(true);
		setVisiblePopupStatus(false);
		dispatch(updateAccountUser(dataRequest));
	};

	const resetData = () => {
		setUserSelect(null);
		setMoreActions({ ...moreActions, isNotification: false });
		dispatch(resetNotificationAccount());
	};

	const renderConfirmModalStatus = () => {
		const possibleRoles = [ROLE.CADMIN, ROLE.EREVIEWER, ROLE.REVIEWER];
		const params: any = {};
		if (roleChange) {
			params.role = possibleRoles?.find((role) => role?.value === roleChange)?.label;
		} else {
			if (userSelect) {
				const { firstName = '', lastName = '' } = userSelect;
				params.fullName = `${firstName} ${lastName}`;
				params.brandName = brandName;
			}
		}

		return (
			<ConfirmModal
				width={400}
				okText={t('button.yes')}
				isOpen={visiblePopupStatus}
				onSubmit={onUpdateAccount}
				onClose={() => {
					setUserSelect(null);
					setRoleChange('');
					setVisiblePopupStatus(false);
				}}>
				{t(
					`setting.account_user.${
						roleChange
							? 'message_change_role'
							: `${
									userSelect?.status === ACCOUNT_STATUS.ACTIVE ? 'in' : ''
							  }active_status`
					}`,
					params,
				)}
			</ConfirmModal>
		);
	};

	const renderRoles = (role: string, record: AccountUserDto) => {
		const allowedRoles = roles2BeChanged(record.role);
		return (
			<StyledSelect
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
				disabled={record?.status === ACCOUNT_STATUS.PENDING}
				dropdownClassName="custom-select"
				onChange={(val) => {
					setUserSelect(record);
					setRoleChange(val);
					setVisiblePopupStatus(true);
				}}
				value={role}
				style={{ width: 170 }}>
				{allowedRoles.map((item: SelectOptionDto) => {
					const { value, label } = item;

					return (
						<Option key={value} value={value}>
							{label}
						</Option>
					);
				})}
			</StyledSelect>
		);
	};

	const roles2BeChanged = (role: String) => {
		const selectedRoles = [];
		if (role === ROLE.CADMIN.value) {
			selectedRoles.push(ROLE.CADMIN);
			if (
				userHasPermission(
					USER_ACCESS_RULES.CHANGE_CADMIN_2_EREVIEWER,
					accountUserPermissions,
				)
			) {
				selectedRoles.push(ROLE.EREVIEWER);
			}
			if (
				userHasPermission(
					USER_ACCESS_RULES.CHANGE_CADMIN_2_REVIEWER,
					accountUserPermissions,
				)
			) {
				selectedRoles.push(ROLE.REVIEWER);
			}
		} else if (role === ROLE.EREVIEWER.value) {
			selectedRoles.push(ROLE.EREVIEWER);
			if (
				userHasPermission(
					USER_ACCESS_RULES.CHANGE_EREVIEWER_2_CADMIN,
					accountUserPermissions,
				)
			) {
				selectedRoles.push(ROLE.CADMIN);
			}
			if (
				userHasPermission(
					USER_ACCESS_RULES.CHANGE_EREVIEWER_2_REVIEWER,
					accountUserPermissions,
				)
			) {
				selectedRoles.push(ROLE.REVIEWER);
			}
		} else if (role === ROLE.REVIEWER.value) {
			selectedRoles.push(ROLE.REVIEWER);
			if (
				userHasPermission(
					USER_ACCESS_RULES.CHANGE_REVIEWER_2_CADMIN,
					accountUserPermissions,
				)
			) {
				selectedRoles.push(ROLE.CADMIN);
			}
			if (
				userHasPermission(
					USER_ACCESS_RULES.CHANGE_REVIEWER_2_EREVIEWER,
					accountUserPermissions,
				)
			) {
				selectedRoles.push(ROLE.EREVIEWER);
			}
		}
		return selectedRoles;
	};

	const isRemovalEnabled = (role: String) => {
		if (role === ROLE.CADMIN.value) {
			return userHasPermission(USER_ACCESS_RULES.REMOVE_CADMIN, accountUserPermissions);
		}
		if (role === ROLE.EREVIEWER.value) {
			return userHasPermission(USER_ACCESS_RULES.REMOVE_EREVIEWER, accountUserPermissions);
		}
		if (role === ROLE.REVIEWER.value) {
			return userHasPermission(USER_ACCESS_RULES.REMOVE_REVIEWER, accountUserPermissions);
		}
		return false;
	};

	const onSelectChange = (selectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const onChangePage = (pageNumber: number) => {
		if (accountPayload) {
			setAccountPayload({ ...accountPayload, page: pageNumber });
		}
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const handleSelection = (
		selectingItems: any[],
		isShift?: boolean,
		callBack?: (val: Array<number | string>) => void,
	) => {
		const listKey: any[] = [];
		for (const selectItem of selectingItems) {
			const itemSelect = accountUserData?.accountResponses?.find(
				(dataIndex: any) => dataIndex?.id === selectItem?.props?.id,
			);
			listKey.push(itemSelect?.id || '');
		}
		if (callBack && typeof callBack === 'function') {
			callBack(listKey);
		}

		if (!isShift) {
			onSelectChange(listKey);
		}
	};

	const handleSelectNewList = (list: Array<number | string>) => {
		onSelectChange(list);
	};

	const customizeRenderEmpty = () => <StyledEmpty image={IconEmpty} />;

	return (
		<>
			<StyledWrapperTable>
				<StyledTable
					rowClassName={(record: any) =>
						record.id === user.accountId || !isRemovalEnabled(record.role)
							? 'disabled-row'
							: ''
					}
					dataSource={accountUserData?.accountResponses || []}
					columns={accountUserColumns}
					pagination={false}
					rowSelection={rowSelection}
					scroll={{ y: 'calc(100vh - 370px)' }}
				/>
			</StyledWrapperTable>
			<Affix offsetBottom={0}>
				<StyledPagination>
					<Pagination
						total={accountUserData?.totalRecords}
						pageSize={defaultPage.pageSize}
						defaultPageSize={defaultPage.pageSize}
						current={accountPayload?.page}
						showSizeChanger={false}
						showQuickJumper
						onChange={onChangePage}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1] || 0} ${t('pagination.of')} ${total || 0} ${t(
								`${total > 1 ? 'pagination.items' : 'pagination.item'}`,
							)}`
						}
					/>
				</StyledPagination>
			</Affix>
			{renderConfirmModalStatus()}
			{userSelect && moreActions.isNotification && (
				<NotificationModal account={userSelect} onCancel={resetData} />
			)}
		</>
	);
};

export default AccountUserList;
