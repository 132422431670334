import { KEYBOARD } from '@constants/common';
import { GALLERY_EDITOR_CONFIG } from '@constants/publish/gallery';
import THEME from '@constants/themes/themes';
import EditorCustom from '@cores/EditorCustom';
import { StyledText, StyledTextSelection, StyledWrapperContent } from '@styled/Common/CommonStyled';
import {
	StyledDescription,
	StyledDescriptionWrapper,
	StyledTaskEditorWrapper,
} from '@styled/TaskManager/TaskManagerStyled';
import _ from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TaskDescriptionProps = {
	description?: string;
	onChange?: (value: string) => void;
	className?: string;
	isDetails?: boolean;
	isCreating?: boolean;
};

const TaskDescription = forwardRef((props: TaskDescriptionProps, ref: any) => {
	const { t } = useTranslation();
	const { onChange, description, className, isDetails = false, isCreating } = props;
	const editorRef = useRef<any>();

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [textValue, setTextValue] = useState<string>('');

	useEffect(() => {
		if (description) {
			setTextValue(description);
		}
	}, [description]);

	useEffect(() => {
		if (description) {
			setTextValue(description);
		}
	}, [description]);

	const hasChanges = useMemo(
		() => !_.isEqual(description, textValue) && isEdit,
		[description, textValue, isEdit],
	);

	useImperativeHandle(ref, () => ({
		hasDescriptionChanges: hasChanges,
		cancelEditing: reset,
	}));

	const reset = () => {
		setIsEdit(false);
		setTextValue(description || '');
	};

	const onSave = () => {
		if (onChange) {
			onChange(editorRef.current.getContent());
			setIsEdit(false);
		}
	};

	const handleEditorContent = (content: string) => {
		setTextValue(content);
	};

	return (
		<StyledDescriptionWrapper
			height={isDetails ? '130px' : '50px'}
			onDoubleClick={(e) => {
				if (isDetails) {
					e.preventDefault();
					e.stopPropagation();
					setIsEdit(true);
					setTextValue(description || '');
				}
			}}
			className={isDetails && isEdit ? 'description_in_details' : ''}>
			{!isEdit && !isCreating && (
				<StyledDescription className={`${className} custom_scroll_bar`}>
					{description && (
						<StyledTextSelection
							dangerouslySetInnerHTML={{
								__html: description,
							}}
							onMouseDown={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
							onMouseDownCapture={(e) => {
								e.stopPropagation();
							}}
							onClick={(e) => {
								if (!isDetails) {
									e.stopPropagation();
								}
							}}
						/>
					)}
					{!description && isDetails && (
						<StyledText
							color={THEME.colors.gray3}
							onMouseDown={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}>
							{t('task_manager.placeholder.enter_a_description')}
						</StyledText>
					)}
				</StyledDescription>
			)}
			{((isDetails && isEdit) || isCreating) && (
				<StyledWrapperContent className="dark_bg" width="100%" height="100%">
					<StyledTaskEditorWrapper>
						<EditorCustom
							isPressingEnterToSave
							onInit={(evt: any, editor: any) => {
								editorRef.current = editor;
							}}
							onEditorChange={handleEditorContent}
							value={textValue}
							otherInit={{
								auto_focus: true,
								plugins: GALLERY_EDITOR_CONFIG.PLUGINS,
								toolbar: GALLERY_EDITOR_CONFIG.TOOLBAR_TASK,
							}}
							otherSetup={(editor: any) => {
								editor.on('keydown', (e: any) => {
									if (e.key === KEYBOARD.ESCAPE.STR) {
										reset();
									}
									if (e.key === KEYBOARD.ENTER.STR && !e.shiftKey) {
										onSave();
									}
								});
								editor.on('blur', () => {
									onSave();
								});
							}}
						/>
					</StyledTaskEditorWrapper>
				</StyledWrapperContent>
			)}
		</StyledDescriptionWrapper>
	);
});

export default TaskDescription;
