import { IconCopy, IconKeyboardArrowRight } from '@assets/icons';
import ContentItem from '@components/Publish/Gallery/SidebarGalleries/ContentItem';
import { urlUploaderSupport, urlUploaderTag } from '@config/index';
import { DEFAULT_SIZE_IFRAME_WIDGET } from '@constants/content/widgetManager';
import { SIZE_IFRAME } from '@constants/publish/gallery';
import THEME from '@constants/themes/themes';
import { WidgetBuilderDetailRequestDto } from '@models/content/widgetManager';
import { SizeIframe } from '@models/publishing/galleries';
import { StyledIcon, StyledTitle, StyledWrapperContent } from '@styled/Common/CommonStyled';
import {
	StyledEmbedUploaderModal,
	StyledHref,
	StyledLink,
	StyledSessionInfo,
	StyledWrapperLink,
	StyledWrapperQrCode,
} from '@styled/Content/WidgetManager/CurrentDesignStyled';
import { StyledCollapse, StyledCollapseInfo } from '@styled/ContentLibrary/ContentDetailStyled';
import { StyledDividerLine } from '@styled/Filter/FilterContentStyled';
import {
	StyledBoxContent,
	StyledInfoEmbed,
	StyledInput,
} from '@styled/Publish/Gallery/GalleryManagerStyled';
import { StyledButtonGray, StyledLinkInfo } from '@styled/Publish/Gallery/PreviewEmbedModalStyled';
import {
	captureDOM,
	encodeToBase64,
	onChangeSizeIframe,
	onCopyValue,
	renderStringIframe,
} from '@utils/funcHelper';
import { Button, Col, message } from 'antd';
import { QRCodeSVG } from 'qrcode.react';

import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const { Panel } = StyledCollapse;

type PropTypes = {
	widgetSelect: WidgetBuilderDetailRequestDto;
	[other: string]: any;
};
const EmbedUploaderModal = (props: PropTypes) => {
	const { widgetSelect, ...other } = props;
	const { t } = useTranslation();

	const [sizeFormIframe, setSizeFormIframe] = useState<SizeIframe>({
		...DEFAULT_SIZE_IFRAME_WIDGET,
	});
	const [iframeString, setIframeString] = useState<string>(
		renderStringIframe(DEFAULT_SIZE_IFRAME_WIDGET, widgetSelect.url, widgetSelect.name, false),
	);

	useEffect(() => {
		onGenerateIframe();
	}, [widgetSelect]);

	const onCopy = (val: string) => {
		onCopyValue(val);
		message.success(t('campaign.message.copy_success'));
	};

	const downloadQR = () => {
		captureDOM({
			id: 'qr-code_widget_download',
			name: t('content.widget_manager.detail.image_qr_widget'),
		});
	};
	const renderHeader = (val: string) => {
		return (
			<StyledCollapseInfo margin="0">
				<StyledTitle
					className="unset_height"
					fontSize="16px"
					color={THEME.colors.white}
					lineHeight="22px"
					margin="0 40px 0 0"
					display="inline">
					{val}
				</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	const renderSub = (val: ReactNode, style?: React.CSSProperties) => {
		return (
			<StyledTitle
				className="unset_height"
				fontSize="14px"
				lineHeight="19px"
				display="inline-block"
				style={style || {}}>
				{val}
			</StyledTitle>
		);
	};

	const renderBoxCode = (val: string) => {
		return (
			<StyledBoxContent style={{ minHeight: '50px' }} className="font_courier box_copy">
				{val}
				<StyledIcon
					onClick={() => onCopy(val)}
					className="icon_hover absolute"
					top="25px"
					right="12px">
					<IconCopy />
				</StyledIcon>
			</StyledBoxContent>
		);
	};

	const onGenerateIframe = () => {
		setIframeString(renderStringIframe(sizeFormIframe, widgetSelect.url, widgetSelect.name));
	};

	const renderInput = (name: string) => {
		const typePercent = name === SIZE_IFRAME.width;

		return (
			<StyledInput
				height={'35px'}
				className="number_input"
				value={sizeFormIframe[name]}
				name={name}
				suffix={typePercent ? '%' : 'px'}
				onChange={(e) => {
					const obj = onChangeSizeIframe(e);

					setSizeFormIframe((prev) => ({ ...prev, ...obj }));
				}}
			/>
		);
	};

	const contentUploader = useMemo(() => {
		return (
			<StyledWrapperContent margin="21px 0 0 0">
				{renderSub(t('content.widget_manager.sub.embed_uploader'))}
				<StyledWrapperContent margin="0 0 8px 0">
					<StyledInfoEmbed gutter={[24, 8]}>
						<Col span={7}>
							<ContentItem
								label={t('gallery_manager.width_frame')}
								content={renderInput('width')}
							/>
						</Col>
						<Col span={7}>
							<ContentItem
								label={t('gallery_manager.height_frame')}
								content={renderInput('height')}
							/>
						</Col>
						<Col style={{ display: 'flex', alignItems: 'end' }} span={10}>
							<StyledButtonGray
								onClick={() => onGenerateIframe()}
								style={{ width: '100%' }}
								type="primary">
								{t('button.refresh_iframe_code')}
							</StyledButtonGray>
						</Col>
					</StyledInfoEmbed>
				</StyledWrapperContent>
				<StyledTitle
					fontSize="12px"
					lineHeight="18px"
					margin="16px 0 7px 0"
					color={THEME.colors.gray1}
					className="unset_height">
					{t('content.widget_manager.iFrame_code')}
				</StyledTitle>
				{renderBoxCode(iframeString)}
			</StyledWrapperContent>
		);
	}, [iframeString, sizeFormIframe]);

	const contentPopup = useMemo(() => {
		const idWidget = `widget_id_${encodeToBase64(Math.random())}`;
		const requiredProps = `id="${idWidget}" data-link-uploader="${widgetSelect.url}" data-close-outside="1" onclick="widgetUploader.popup('${idWidget}')"`;
		const scriptTag = `<script defer src="${urlUploaderTag}"></script>`;
		const code = `${scriptTag} <button  ${requiredProps}>Upload Here</button>`;
		return (
			<StyledWrapperContent margin="21px 0 0 0">
				{renderSub(t('content.widget_manager.sub.add_button'), { margin: '0 0 16px 0' })}

				{renderBoxCode(code)}

				{renderSub(
					<Trans
						t={t}
						values={{}}
						i18nKey={'content.widget_manager.sub.guide_props'}
						components={{
							elm: <StyledHref target="_blank" href={urlUploaderSupport} />,
						}}
					/>,
					{ margin: '16px 0' },
				)}
				{renderBoxCode(requiredProps)}
				{renderSub(t('content.widget_manager.sub.note_script'), { margin: '16px 0' })}
				{renderBoxCode(scriptTag)}
			</StyledWrapperContent>
		);
	}, [widgetSelect.url]);

	const listInfo = [
		{
			id: '1',
			header: renderHeader(t('content.widget_manager.embed_uploader_iFrame')),
			content: contentUploader,
		},
		{
			id: '2',
			header: renderHeader(t('content.widget_manager.display_popup')),
			content: contentPopup,
		},
	];

	return (
		<StyledEmbedUploaderModal
			className="modal_custom"
			centered
			width={600}
			footer={null}
			zIndex={1010}
			title={
				<>
					<StyledTitle fontSize="20px" lineHeight="27px" className="unset_height">
						{t('content.widget_manager.embed_your_uploader')}
					</StyledTitle>
					<StyledDividerLine margin="8px 0 0 0" />
				</>
			}
			{...other}>
			<StyledWrapperContent padding="32px 0">
				<StyledTitle
					fontSize="16px"
					lineHeight="22px"
					className="unset_height"
					margin="0 0 16px 0">
					{t('content.widget_manager.landing_page')}
				</StyledTitle>
				<StyledWrapperContent display="flex" justifyContent="space-between">
					<StyledSessionInfo width="calc(100% - 225px)" padding="0 16px 0 0">
						<StyledTitle fontSize="14px" lineHeight="19px" className="unset_height">
							{t('content.widget_manager.popup.sub_direct_visitors')}
						</StyledTitle>
						<StyledTitle
							fontSize="12px"
							lineHeight="18px"
							margin="16px 0 7px 0"
							color={THEME.colors.gray1}
							className="unset_height">
							{t('content.widget_manager.detail.widget_base')}
						</StyledTitle>
						<StyledWrapperLink padding="0 40px 0 0">
							<StyledLink
								margin="0"
								display="inline"
								target="_blank"
								href={widgetSelect?.url}>
								{widgetSelect.url}
							</StyledLink>
							<StyledIcon
								onClick={() => onCopy(widgetSelect.url)}
								className="icon_hover absolute"
								top="0px"
								right="0px">
								<IconCopy />
							</StyledIcon>
						</StyledWrapperLink>
						<Button
							style={{ width: '100%', marginTop: '24px' }}
							onClick={downloadQR}
							type="primary">
							{t('content.widget_manager.button.download_qr_code')}{' '}
						</Button>
					</StyledSessionInfo>
					<StyledSessionInfo padding="0 14px">
						<StyledWrapperQrCode id="qr-code_widget_download">
							<QRCodeSVG
								size={177}
								width={177}
								height={177}
								value={widgetSelect.url}
							/>
						</StyledWrapperQrCode>
					</StyledSessionInfo>
				</StyledWrapperContent>
			</StyledWrapperContent>
			{listInfo.map((item) => (
				<StyledWrapperContent key={item.id}>
					<StyledDividerLine />
					<StyledWrapperContent padding="24px 0">
						<StyledCollapse
							style={{ margin: '0' }}
							className="collapse_content_detail"
							expandIconPosition="right"
							defaultActiveKey={[item.id]}>
							<Panel header={item.header} key={item.id}>
								{item.content}
							</Panel>
						</StyledCollapse>
					</StyledWrapperContent>
				</StyledWrapperContent>
			))}

			<StyledDividerLine />
			<StyledTitle
				className="unset_height"
				fontSize="16px"
				color={THEME.colors.white}
				lineHeight="22px"
				display="inline-block"
				margin="30px 0 0 0">
				<Trans
					t={t}
					values={{}}
					i18nKey={'gallery_manager.sub_support'}
					components={{
						elm: <StyledLinkInfo href={urlUploaderSupport} target="_blank" />,
					}}
				/>
			</StyledTitle>
		</StyledEmbedUploaderModal>
	);
};

export default EmbedUploaderModal;
