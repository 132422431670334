import BlockedUsernames from '@components/Settings/SocialSearch/BlockedUsernames';
import ExcludedSearchTerms from '@components/Settings/SocialSearch/ExcludedSearchTerms';
import { MESSAGE_TYPE } from '@constants/common';
import THEME from '@constants/themes/themes';
import { SettingRootStateDto } from '@models/settings/setting';
import {
	fetchBadWords,
	fetchSocialBlockedUsername,
	resetSocialSearchSetting,
	storeData,
} from '@stores/actions';
import { StyledTitle, StyledWrapperContent } from '@styled/Common/CommonStyled';
import { generalMessage } from '@utils/renderComponent';
import { Col, Row, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const UniversalBlockLists = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { error = null } = useSelector(
		(state: SettingRootStateDto) => state.settings.socialSearch,
	);

	useEffect(() => {
		dispatch(fetchSocialBlockedUsername());
		dispatch(fetchBadWords());
		return () => {
			dispatch(
				resetSocialSearchSetting({
					blockedUsernameList: [],
					badWordList: [],
				}),
			);
		};
	}, []);

	useEffect(() => {
		if (error) {
			generalMessage(error, MESSAGE_TYPE.ERROR);
			dispatch(storeData()); // reset error
		}
	}, [error]);

	return (
		<StyledWrapperContent height={'100%'} margin="0">
			<StyledWrapperContent
				borderRadius="2px"
				width="100%"
				background={THEME.colors.darkBlue2}
				padding="25px 27px">
				<StyledTitle fontSize="20px" lineHeight="27px" className="unset_height">
					{t('social_aggregator.blocked_creators_terms')}
				</StyledTitle>
				<StyledTitle
					margin="13px 0 0 0"
					fontSize="12px"
					lineHeight="16px"
					className="unset_height">
					{t('social_aggregator.content_use')}
					<Tooltip title={t('social_aggregator.sub_why')}>
						<StyledTitle
							className="unset_height"
							display="inline"
							fontSize="12px"
							lineHeight="16px"
							margin="0 0 0 4px"
							fontWeight="bold">
							{t('social_aggregator.why_use_this')}
						</StyledTitle>
					</Tooltip>
				</StyledTitle>
			</StyledWrapperContent>
			<StyledWrapperContent height="calc(100% - 128px)" width="100%" margin="16px 0 0 0">
				<Row style={{ height: '100%' }} gutter={[16, 16]}>
					<Col style={{ height: '100%' }} span={12}>
						<BlockedUsernames />
					</Col>
					<Col style={{ height: '100%' }} span={12}>
						<ExcludedSearchTerms />
					</Col>
				</Row>
			</StyledWrapperContent>
		</StyledWrapperContent>
	);
};

export default UniversalBlockLists;
