type Props = { src: string; alt?: string; width?: number | string; height?: number | string };

const Image: React.FC<Props> = ({ src, width, height, alt }) => {
  // TODO, apply lazy loading image, handle loading state and default image
  return <img src={src} width={width} height={height} alt={alt} />;
};

Image.defaultProps = {
  alt: '',
  width: 50,
  height: 50,
};

export default Image;
