import { StyledWrapperContent } from '@styled/Common/CommonStyled';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import GraphItem from '../GraphItem';
import { StyledWrapperSession } from '@styled/AmbassadorDashboard';
import { useTranslation } from 'react-i18next';
import { DEFAULT_FORM_CONFIG, INTERVAL, NAME_CONFIG } from '@constants/ambassador';
import { ConfigGraphTypes } from '@models/ambassador';
import { useDispatch, useSelector } from 'react-redux';
import { getAmbassadorGraphPostsRequest, updateAccountSettingRequest } from '@stores/actions';
import { LoadingWrapper } from '@cores/index';
import { AmbassadorManagerStoreDto } from '@models/ambassador/stores';
import { SOCIAL_UPCASE } from '@constants/social_search';
import AmbassadorDashboardManagerContext from '@contexts/AmbassadorDashboard';
import THEME from '@constants/themes/themes';
import { AccountSettingsDto, AccountSettingsRequest } from '@models/user/user';
import { getUser } from '@helpers/userHelpers';

const GraphSocialPost = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { loadingAmbassadorGraphPosts, graphPosts }: AmbassadorManagerStoreDto = useSelector(
		(state: any) => state?.ambassadors,
	);
	const { clientSettings } = useSelector((state: any) => state.sidebar);

	const storedUser: any = getUser();
	const accountSettings: AccountSettingsDto = storedUser?.accountSettings;
	const { ambassadorPostsGraphConfigure } = accountSettings;
	const {
		sources: sourcesDefaultConfig,
		interval: intervalDefaultConfig,
		isShowCumulativeData: isShowCumulativeDataDefaultConfig,
		isDynamicScale: isDynamicScaleDefaultConfig,
	} = ambassadorPostsGraphConfigure || {};

	const { ambassadorMetricsRefreshUpdateAt = 0 } = clientSettings || {};

	const { campaignIdSelected, segmentIdSelected } = useContext(AmbassadorDashboardManagerContext);

	const sourceDefault = useMemo(() => {
		if (sourcesDefaultConfig) {
			return sourcesDefaultConfig.map((key) => key.toUpperCase());
		}
		return [...DEFAULT_FORM_CONFIG[NAME_CONFIG.SOURCES], SOCIAL_UPCASE.SUBMISSIONS];
	}, [sourcesDefaultConfig]);

	const [configSource, setConfigSource] = useState<string[]>(sourceDefault);
	const [configInterval, setConfigInterval] = useState<string>(
		intervalDefaultConfig || DEFAULT_FORM_CONFIG[NAME_CONFIG.INTERVAL],
	);
	const [isShowCumulativeData, setIsShowCumulativeData] = useState<boolean>(
		isShowCumulativeDataDefaultConfig || DEFAULT_FORM_CONFIG[NAME_CONFIG.SHOW_CUMULATIVE_DATA],
	);
	const [isShowDynamicScale, setIsShowDynamicScale] = useState<boolean>(
		isDynamicScaleDefaultConfig || DEFAULT_FORM_CONFIG[NAME_CONFIG.SHOW_DYNAMIC_SCALE],
	);

	const hasFilter = campaignIdSelected || segmentIdSelected;

	useEffect(() => {
		const otherParam: any = {};

		dispatch(
			getAmbassadorGraphPostsRequest({
				interval: configInterval,
				sources: [
					SOCIAL_UPCASE.INSTAGRAM,
					SOCIAL_UPCASE.TIKTOK,
					SOCIAL_UPCASE.TWITTER,
					SOCIAL_UPCASE.YOUTUBE,
					SOCIAL_UPCASE.SUBMISSIONS,
				],
				campaignId: campaignIdSelected,
				segmentId: segmentIdSelected,
				isShowCumulativeData,
			}),
		);
	}, [configInterval, campaignIdSelected, segmentIdSelected, isShowCumulativeData]);

	const handleUpdateConfig = (values: ConfigGraphTypes) => {
		setConfigSource(values.sources);
		setConfigInterval(values.interval);
		setIsShowCumulativeData(values.isShowCumulativeData);
		setIsShowDynamicScale(values.isDynamicScale);
		const dataRequest: AccountSettingsRequest = {
			ambassadorPostsGraphConfigure: {
				interval: values.interval,
				isShowCumulativeData: values.isShowCumulativeData,
				sources: values.sources,
				isDynamicScale: values.isDynamicScale,
			},
		};
		dispatch(
			updateAccountSettingRequest({
				...dataRequest,
			}),
		);
	};

	return (
		<LoadingWrapper isLoading={loadingAmbassadorGraphPosts} sizeLoading={'normal'}>
			<StyledWrapperSession
				height="302px"
				bgColor={hasFilter ? THEME.colors.darkBlue6 : undefined}>
				<GraphItem
					isSocial={true}
					configSource={configSource}
					configInterval={configInterval}
					isShowCumulativeData={isShowCumulativeData}
					isShowDynamicScale={isShowDynamicScale}
					handleUpdateConfig={handleUpdateConfig}
					title={t('ambassador.submissions_social_posts')}
					dataGraph={graphPosts}
					loading={loadingAmbassadorGraphPosts}
				/>
			</StyledWrapperSession>
		</LoadingWrapper>
	);
};

export default GraphSocialPost;
