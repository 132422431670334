const MFA_SECTION = {
	ENTER_PASSWORD: 'ENTER_PASSWORD',
	PAIR_AUTHENTICATOR_APP: 'PAIR_AUTHENTICATOR_APP',
	CONFIRM_PAIRING: 'CONFIRM_PAIRING',
};

const MFA_ACTION_TYPES = {
	PASSWORD_VERIFICATION: 'PASSWORD_VERIFICATION',
	DISABLE_MFA: 'DISABLE_MFA',
};

export { MFA_SECTION, MFA_ACTION_TYPES };
