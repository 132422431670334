import { IconEye, IconLock, IconNotInterested, IconTags } from '@assets/icons';
import { TYPE_ACTIONS } from '@constants/common';
import { TYPE_COLLAPSE_ACTION } from '@constants/content/contentLibrary';
import THEME from '@constants/themes/themes';
import FeedsManagerContext from '@contexts/SocialSearch/Feeds';
import { ActionsTypes } from '@models/content/albums/albumManager';
import { TypeSortDto } from '@models/filter';
import { StyledTitle, StyledWrapperAction } from '@styled/Common/CommonStyled';
import { StyledBtn, StyledBtnCustom } from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledClearSelection, StyledWrapperActionBar } from '@styled/SocialSearch/Feeds';
import { menuActions } from '@utils/renderComponent';
import { Dropdown } from 'antd';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const ActionBarFeeds = () => {
	const { t } = useTranslation();

	const {
		setTypeCollapse,
		selectContentList,
		handleClearSelect,
		setTypeAction,
		handleImportAction,
	} = useContext(FeedsManagerContext);

	const actionsObj: ActionsTypes[] = [
		{
			id: 1,
			name: t('social_search.actions.bookmark_without_tagging'),
			click: () => handleImportAction(),
		},
		{
			id: 2,
			name: t('social_search.actions.tag_rate'),
			click: () => setTypeCollapse(TYPE_COLLAPSE_ACTION.BOOKMARK_POST),
		},
	];

	return (
		<StyledWrapperActionBar className={selectContentList?.length > 0 ? '' : 'hidden'}>
			<StyledWrapperAction>
				{selectContentList.length > 0 && (
					<>
						<StyledTitle
							color={THEME.colors.white}
							fontSize="12px"
							lineHeight="16px"
							display="inline-flex">
							<Trans
								t={t}
								values={{ num: selectContentList.length }}
								i18nKey={
									selectContentList?.length === 1
										? 'social_aggregator.post_selected'
										: 'social_aggregator.posts_selected'
								}
								components={{
									elm: (
										<StyledClearSelection onClick={() => handleClearSelect()} />
									),
								}}
							/>
						</StyledTitle>

						<StyledBtn
							icon={<IconEye />}
							type="ghost"
							onClick={() => setTypeAction(TYPE_ACTIONS.BLOCK_CONTENT)}>
							{t('button.hide_posts')}
						</StyledBtn>
						<StyledBtn
							icon={<IconLock />}
							type="ghost"
							onClick={() => setTypeAction(TYPE_ACTIONS.BLOCK_CREATOR)}>
							{t('button.block_creators')}
						</StyledBtn>

						<Dropdown
							overlay={menuActions(actionsObj)}
							getPopupContainer={(triggerNode: HTMLElement) =>
								triggerNode.parentNode as HTMLElement
							}
							placement="bottomLeft"
							trigger={['click']}>
							<StyledBtn icon={<IconTags />} type="ghost">
								{t('button.bookmark_post')}
							</StyledBtn>
						</Dropdown>
					</>
				)}
			</StyledWrapperAction>
		</StyledWrapperActionBar>
	);
};

export default ActionBarFeeds;
