import THEME from '@constants/themes/themes';
import { Tabs, Collapse, Input, Button, Checkbox, Form, Select, Alert } from 'antd';
import styled from 'styled-components';

export const StyledSection = styled.div<{
	width?: string;
	borderPosition?: string;
	heightSection?: string;
	flexDirection?: string;
	fontSize?: string;
	padding?: string;
	isDisableAction?: boolean;
	justify?: string;
	displayType?: string;
}>`
	width: ${({ width }) => `${width} !important` || 'unset'};
	display: ${({ displayType }) => (displayType === 'block' ? 'block' : 'flex')};
	justify-content: ${({ justify }) => justify || 'space-between'};
	align-items: center;
	flex-direction: column;
	padding: ${({ padding }) => padding || '16px'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	height: ${({ heightSection }) => heightSection || 'auto'};
	flex-direction: ${({ flexDirection }) => flexDirection || 'unset'};
	${({ borderPosition }) =>
		borderPosition && `border-${borderPosition}:  1px solid ${THEME.colors.darkBlue3}`};
	pointer-events: ${({ isDisableAction }) => (isDisableAction ? 'none' : 'unset')};
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
	p {
		font-weight: ${THEME.fontWeight.bold};
		margin: 0;
		text-transform: capitalize;
	}
`;

export const StyledTabs = styled(Tabs)`
	background: ${THEME.colors.darkBlue};
	margin: 0;

	.ant-tabs-nav {
		margin-bottom: 0 !important;
		border-bottom: 1px solid ${THEME.colors.darkBlue3};

		.ant-tabs-nav-wrap {
			height: 47px !important;
		}
		.ant-tabs-tab {
			padding: 12px 20px;
			background: none;
			border: none;
			border-bottom: 2px solid transparent;

			&.ant-tabs-tab-active {
				border-bottom: 2px solid ${THEME.colors.primary};
				opacity: 1;

				.ant-tabs-tab-btn {
					color: ${THEME.colors.gray5};
				}
			}
		}

		.ant-tabs-nav-add {
			background: none;
			border: none;
		}

		&:before {
			border-bottom: none;
		}

		.ant-tabs-nav-wrap {
			padding-left: 15px;
			height: 54px;
		}
	}

	.ant-tabs-content {
		height: calc(100vh - 238px);
		overflow-y: auto;
		&:hover {
			&::-webkit-scrollbar-thumb {
				visibility: unset;
			}
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			visibility: hidden;
		}
	}
`;

export const StyledCollapse = styled(Collapse)<{ isEvent?: boolean }>`
	&.ant-collapse {
		border: none;
		color: ${THEME.colors.gray5} !important;
	}

	.ant-collapse-arrow {
		top: ${({ isEvent }) => (isEvent ? '32%' : '50%')} !important;
	}

	.ant-collapse-item {
		border-bottom: ${({ isEvent }) =>
			isEvent ? `0.5px solid ${THEME.colors.darkBlue4};` : 'none'};
	}

	.ant-collapse-header {
		display: flex;
		min-height: ${({ isEvent }) => (isEvent ? '70px' : 'auto')} !important;
		color: ${THEME.colors.gray5} !important;
		padding: ${({ isEvent }) => (isEvent ? '0' : '0')} !important;
	}

	.ant-collapse-content {
		border-top: none;
		.ant-collapse-content-box {
			padding-top: 0;
		}
	}
`;

export const StyledTitle = styled.div`
	line-break: auto;
`;

export const StyledText = styled.span<{ weight?: number; color?: string }>`
	font-weight: ${({ weight }) => weight || 500};
	color: ${({ color }) => color || 'white'};
`;

export const StyledFlex = styled.div<{
	justifyContent?: string;
	alignItem?: string;
	padding?: string;
}>`
	width: 100%;
	${({ padding }) => `padding: ${padding} !important` || ''};
	display: flex;
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
	align-items: ${({ alignItem }) => alignItem || 'center'};
`;

export const StyledIconTitle = styled.span`
	margin-right: 15px;
	justify-content: center;
	display: flex;
	align-items: center;
	svg {
		height: 20px !important;
		width: 20px !important;
	}
`;

export const StyledDate = styled.span`
	position: absolute;
	right: 16px;
	bottom: 5px;
	font-size: 12px;
`;

export const StyledSegmentsWrapper = styled.div`
	border: 0.5px solid ${THEME.colors.darkBlue4};
	padding: 12px;
`;

export const StyledCreatorWrapper = styled.div<{ isSelected?: boolean }>`
	height: 28px;
	width: fit-content;
	// max-width: 100%;
	border-radius: 14px;
	background-color: ${({ isSelected }) =>
		isSelected ? THEME.colors.orangeBase : THEME.colors.darkBlue4};
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 3px;
	padding-right: 10px;
	cursor: pointer;
	svg {
		fill: white !important;
		height: 24px;
	}
`;

export const StyledPagination = styled.div`
	background-color: ${THEME.colors.darkBlue2};
	border-top: 0.5px solid ${THEME.colors.darkBlue4};
	height: 50px;
	align-items: center;
	justify-content: center;
	margin: 0;
	position: absolute;
	bottom: 0;
	z-index: 333;

	ul {
		li:not(.ant-pagination-item-active) {
			background-color: ${THEME.colors.darkBlue2};
			border: none;
		}

		.anticon-double-right {
			svg {
				margin-right: 10px;
			}
		}
		.ant-pagination-item-link {
			background-color: ${THEME.colors.darkBlue2};
			border: none;
		}
	}
	.ant-pagination-item {
		display: inline-flex;
		align-items: center;
	}
`;

export const StyledSearch = styled(Input)`
	width: 100% !important;
	height: 32px;
	// margin-right: 15px;
`;

export const StyledInput = styled(Input)`
	&::-webkit-input-placeholder {
		color: ${THEME.colors.gray2};
	}

	&:-ms-input-placeholder {
		color: ${THEME.colors.gray2};
	}

	&::placeholder {
		color: ${THEME.colors.gray2};
	}
`;

export const StyledButton = styled(Button)`
	height: 32px;
	width: 36px !important;

	margin-left: 10px;
	border: 1px solid ${THEME.colors.darkBlue4};
`;

export const StyledEventWrapper = styled.div`
	// min-height: 70px;
`;

export const StyledEmailWrapper = styled.div<{ isSelected?: boolean }>`
	position: relative;
	display: flex;
	background-color: ${({ isSelected }) =>
		isSelected ? THEME.colors.orangeBase : THEME.colors.darkBlue2};
	justify-content: flex-start;
	cursor: pointer;
	align-items: center;
	height: 70px;
	padding: 16px;
	border-bottom: 0.5px solid ${THEME.colors.darkBlue4};
`;

export const StyledLinkButton = styled.div`
	margin-top: 5px;
	color: ${THEME.colors.orangeBase};
	cursor: pointer;
`;

export const StyledCreatorListWrapper = styled.div`
	height: auto;
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
	margin: 5px 0 0 0;
`;

export const StyledFormItem = styled(Form.Item)`
	margin-bottom: 16px;

	.ant-form-item-label {
		height: 22px;
		padding: 0;
		margin: 0 0 6px 0;

		label {
			width: 100%;
		}
	}
`;

export const StyledTextTitle = styled.span<{ fontSize?: string }>`
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
`;

export const StyledSelect = styled(Select)`
	padding: 0;
	width: 100%;

	.ant-select-dropdown {
		&.dropdown-custom-content {
			overflow: auto !important;
			.rc-virtual-list-holder {
				max-height: calc(50vh - 160px) !important;
				.rc-virtual-list-holder-inner {
					transform: translateY(0px) !important;
				}
			}
			.rc-virtual-list-scrollbar {
				display: none !important;
			}
		}
	}
`;

export const StyledCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-disabled .ant-checkbox-inner {
		border-color: ${THEME.colors.darkBlue5} !important;
		background-color: transparent !important;
	}
	&.checkbox-group_filter {
		display: flex;
		justify-content: space-between;
	}
`;

export const StyledCheckbox = styled(Checkbox)<{ fontSize?: string }>`
	width: auto;
	margin-right: 15px;
	margin-left: 0 !important;
	color: ${THEME.colors.gray3};

	.ant-checkbox + span {
		font-size: ${({ fontSize }) => fontSize || '1rem'};
	}

	.ant-checkbox-inner {
	}

	.ant-checkbox-disabled + span {
		color: ${THEME.colors.darkBlue5} !important;
	}
`;

export const StyledAlert = styled(Alert)`
	&.ant-alert {
		border-radius: 2px;
		margin-bottom: 16px;
		border: 0;
		background-color: rgba(24, 144, 255, 0.08);
		.ant-alert-message {
			color: #1890ff;
			line-height: 1.43;
			height: 20px;
			font-size: 1rem;
		}
		svg {
			fill: ${THEME.colors.blueBase};
		}
	}
`;

export const StyledContent = styled.div`
	border-radius: 2px;
	border: solid 1px ${THEME.colors.darkBlue3};
	padding: 16px;
	input {
		&::-webkit-input-placeholder {
			color: ${THEME.colors.gray2};
		}

		&:-ms-input-placeholder {
			color: ${THEME.colors.gray2};
		}

		&::placeholder {
			color: ${THEME.colors.gray2};
		}
	}
`;

export const StyledWrapperFilter = styled.div`
	width: 100%;
	.ant-dropdown {
		background: ${THEME.colors.darkBlue1};
		&:not(.ant-dropdown-hidden) {
			margin-left: 12px;
		}
	}
	.icon_filter {
		cursor: pointer;
		&:hover {
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
		&.active_icon {
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}
`;

export const StyledWrapperPopup = styled.div`
	position: absolute;
	left: 0;
	overflow: hidden;
	visibility: visible;
	opacity: 1;
	z-index: 1005;
	width: 100%;
	transition: 0.25s ease-out;
	.content_filter,
	.mask_filter {
		visibility: visible;
		background-color: ${THEME.colors.darkBlue2};
		transition: 0.25s ease-out;
	}

	&.hidden_filter {
		height: 0;
		.content_filter,
		.mask_filter {
			height: 0;
			opacity: 0;
			visibility: hidden;
		}
	}
	&.show_filter {
		height: 100%;
		.content_filter {
			opacity: 1;
		}
		.mask_filter {
			opacity: 0.9;
			height: 100%;
		}
	}
`;

export const StyledContentFilter = styled.div`
	padding: 8px 16px;
`;

export const StyledFooterFilter = styled.div`
	display: flex;
	justify-content: end;
	.ant-btn {
		margin-left: 12px;
	}
`;
