import { COOKIE_DOMAIN } from '@config/index';
import { COOKIES_EXPIRES_IN, COOKIES_NAME } from '@constants/common';
import Cookies from 'js-cookie';

const getMoreOption = () => {
	return process.env.NODE_ENV === 'development' ? {} : { domain: COOKIE_DOMAIN };
};

const setCookie = ({ name, value }: { name: string; value: any }) => {
	removeAllCookieOther();
	Cookies.set(name, value, { expires: COOKIES_EXPIRES_IN, ...getMoreOption() });
};

const getCookie = (name: string) => {
	return Cookies.get(name) || '';
};

const getAllCookie = () => {
	return Cookies.get();
};

const removeAllCookieOther = () => {
	const cookies = document.cookie.split(';');

	for (let i = 0; i < cookies.length; i += 1) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
};

const removeCookie = (name: string) => {
	Cookies.remove(name, { ...getMoreOption() });
	removeAllCookieOther();
};

const setTokenCookie = (value: any) => {
	setCookie({ name: COOKIES_NAME.TOKEN, value });
};

const getTokenCookie = () => {
	return getCookie(COOKIES_NAME.TOKEN);
};

const removeTokenCookie = () => {
	removeCookie(COOKIES_NAME.TOKEN);
};

export {
	setCookie,
	getCookie,
	getAllCookie,
	removeCookie,
	setTokenCookie,
	getTokenCookie,
	removeTokenCookie,
};
