const getLandingPageListTypes = {
	GET_LANDING_PAGE_LIST_REQUEST: 'GET_LANDING_PAGE_LIST_REQUEST',
	GET_LANDING_PAGE_LIST_SUCCEEDED: 'GET_LANDING_PAGE_LIST_SUCCEEDED',
	GET_LANDING_PAGE_LIST_FAILED: 'GET_LANDING_PAGE_LIST_FAILED',
	GET_LANDING_PAGE_LIST_ENDED: 'GET_LANDING_PAGE_LIST_ENDED',
};

const getLandingPageListLiteTypes = {
	GET_LANDING_PAGE_LIST_LITE_REQUEST: 'GET_LANDING_PAGE_LIST_LITE_REQUEST',
	GET_LANDING_PAGE_LIST_LITE_SUCCEEDED: 'GET_LANDING_PAGE_LIST_LITE_SUCCEEDED',
	GET_LANDING_PAGE_LIST_LITE_FAILED: 'GET_LANDING_PAGE_LIST_LITE_FAILED',
	GET_LANDING_PAGE_LIST_LITE_ENDED: 'GET_LANDING_PAGE_LIST_LITE_ENDED',
};

const getLandingPageDetailTypes = {
	GET_LANDING_PAGE_DETAIL_REQUEST: 'GET_LANDING_PAGE_DETAIL_REQUEST',
	GET_LANDING_PAGE_DETAIL_SUCCEEDED: 'GET_LANDING_PAGE_DETAIL_SUCCEEDED',
	GET_LANDING_PAGE_DETAIL_FAILED: 'GET_LANDING_PAGE_DETAIL_FAILED',
	GET_LANDING_PAGE_DETAIL_ENDED: 'GET_LANDING_PAGE_DETAIL_ENDED',
};

const updateLandingPageTypes = {
	UPDATE_LANDING_PAGE_REQUEST: 'UPDATE_LANDING_PAGE_REQUEST',
	UPDATE_LANDING_PAGE_SUCCEEDED: 'UPDATE_LANDING_PAGE_SUCCEEDED',
	UPDATE_LANDING_PAGE_FAILED: 'UPDATE_LANDING_PAGE_FAILED',
	UPDATE_LANDING_PAGE_ENDED: 'UPDATE_LANDING_PAGE_ENDED',
};

const publishLandingPageTypes = {
	PUBLISH_LANDING_PAGE_REQUEST: 'PUBLISH_LANDING_PAGE_REQUEST',
	PUBLISH_LANDING_PAGE_SUCCEEDED: 'PUBLISH_LANDING_PAGE_SUCCEEDED',
	PUBLISH_LANDING_PAGE_FAILED: 'PUBLISH_LANDING_PAGE_FAILED',
	PUBLISH_LANDING_PAGE_ENDED: 'PUBLISH_LANDING_PAGE_ENDED',
};

const createLandingPageTypes = {
	CREATE_LANDING_PAGE_REQUEST: 'CREATE_LANDING_PAGE_REQUEST',
	CREATE_LANDING_PAGE_SUCCEEDED: 'CREATE_LANDING_PAGE_SUCCEEDED',
	CREATE_LANDING_PAGE_FAILED: 'CREATE_LANDING_PAGE_FAILED',
	CREATE_LANDING_PAGE_ENDED: 'CREATE_LANDING_PAGE_ENDED',
};

const resumeLandingPageDraftTypes = {
	RESUME_LANDING_PAGE_DRAFT_REQUEST: 'RESUME_LANDING_PAGE_DRAFT_REQUEST',
	RESUME_LANDING_PAGE_DRAFT_SUCCEEDED: 'RESUME_LANDING_PAGE_DRAFT_SUCCEEDED',
	RESUME_LANDING_PAGE_DRAFT_FAILED: 'RESUME_LANDING_PAGE_DRAFT_FAILED',
	RESUME_LANDING_PAGE_DRAFT_ENDED: 'RESUME_LANDING_PAGE_DRAFT_ENDED',
};

const clonePublishedLandingPageTypes = {
	CLONE_PUBLISHED_LANDING_PAGE_REQUEST: 'CLONE_PUBLISHED_LANDING_PAGE_REQUEST',
	CLONE_PUBLISHED_LANDING_PAGE_SUCCEEDED: 'CLONE_PUBLISHED_LANDING_PAGE_SUCCEEDED',
	CLONE_PUBLISHED_LANDING_PAGE_FAILED: 'CLONE_PUBLISHED_LANDING_PAGE_FAILED',
	CLONE_PUBLISHED_LANDING_PAGE_ENDED: 'CLONE_PUBLISHED_LANDING_PAGE_ENDED',
};

const deleteLandingPageTypes = {
	DELETE_LANDING_PAGE_REQUEST: 'DELETE_LANDING_PAGE_REQUEST',
	DELETE_LANDING_PAGE_SUCCEEDED: 'DELETE_LANDING_PAGE_SUCCEEDED',
	DELETE_LANDING_PAGE_FAILED: 'DELETE_LANDING_PAGE_FAILED',
	DELETE_LANDING_PAGE_ENDED: 'DELETE_LANDING_PAGE_ENDED',
};

const unPublishLandingPageTypes = {
	UNPUBLISH_LANDING_PAGE_REQUEST: 'UNPUBLISH_LANDING_PAGE_REQUEST',
	UNPUBLISH_LANDING_PAGE_SUCCEEDED: 'UNPUBLISH_LANDING_PAGE_SUCCEEDED',
	UNPUBLISH_LANDING_PAGE_FAILED: 'UNPUBLISH_LANDING_PAGE_FAILED',
	UNPUBLISH_LANDING_PAGE_ENDED: 'UNPUBLISH_LANDING_PAGE_ENDED',
};

const storeLandingPageDataTypes = {
	STORE_LANDING_PAGE_DATA_REQUEST: 'STORE_LANDING_PAGE_DATA_REQUEST',
};

export {
	getLandingPageListTypes,
	getLandingPageListLiteTypes,
	getLandingPageDetailTypes,
	updateLandingPageTypes,
	publishLandingPageTypes,
	createLandingPageTypes,
	resumeLandingPageDraftTypes,
	clonePublishedLandingPageTypes,
	deleteLandingPageTypes,
	unPublishLandingPageTypes,
	storeLandingPageDataTypes,
};
