export default (editor: any) => {
	const rte = editor.RichTextEditor;

	rte.remove('link');

	rte.add('superscript', {
		icon: '<b>S<sup>s</sup></b>',
		attributes: { title: 'Superscript' },
		result: (rte: any) => rte.exec('superscript'),
	});

	rte.add('subscript', {
		icon: '<b>S<sub>s</sub></b>',
		attributes: { title: 'Subscript' },
		result: (rte: any) => rte.exec('subscript'),
	});

	rte.add('hyperlink', {
		icon: '&#128279;',
		attributes: { title: 'Hyperlink' },
		result: (rte: any) => {
			const component = editor.getSelected();

			if (component.is('link')) {
				component.replaceWith(`${component.get('content')}`);
			} else {
				let range = rte.selection().getRangeAt(0);

				let container = range.commonAncestorContainer;
				if (container.nodeType == 3) container = container.parentNode;

				if (container.nodeName === 'A') {
					const sel = rte.selection();
					sel.removeAllRanges();
					range = document.createRange();
					range.selectNodeContents(container);
					sel.addRange(range);
					rte.exec('unlink');
				} else {
					const url = window.prompt('Enter the URL to link to:');
					if (url)
						rte.insertHTML(
							`<a target='_blank' class="link" href="${url}">${rte.selection()}</a>`,
						);
				}
			}
		},
	});

	rte.add('indent', {
		icon: '&#8594;',
		attributes: { title: 'Indent' },
		result: (rte: any) => rte.exec('indent'),
	});

	rte.add('outdent', {
		icon: '&#8592;',
		attributes: { title: 'Outdent' },
		result: (rte: any) => rte.exec('outdent'),
	});

	rte.add('orderedList', {
		icon: '1.',
		attributes: { title: 'Ordered List' },
		result: (rte: any) => rte.exec('insertOrderedList'),
	});

	rte.add('unorderedList', {
		icon: '&#8226;',
		attributes: { title: 'Unordered List' },
		result: (rte: any) => rte.exec('insertUnorderedList'),
	});
};
