import { BulletedListSVG, TextSVG } from '@assets/icons/svg/grapesjs';

export const ListBlock = (bm: any, label?: string) => {
	bm.add('list', {
		label: 'Bulleted List',
		media: BulletedListSVG,
		content:
			'<ul data-gjs-type="list"><li data-gjs-type="text">Item 1</li><li>Item 2</li><li>Item 3</li><li>Item 4</li> <!-- Add more items as needed --></ul>',
	});
};

export default (domc: any) => {
	const defaultType = domc.getType('default');
	const defaultModel = defaultType.model;
	const defaultView = defaultType.view;

	domc.addType('list', {
		model: {
			defaults: {
				'custom-name': 'List',
				tagName: 'ul',
				editable: true,
				traits: [
					{
						type: 'select',
						options: [
							{ value: 'ul', name: 'No' },
							{ value: 'ol', name: 'Yes' },
						],
						label: 'Ordered',
						name: 'tagName',
						changeProp: true,
					},
				].concat(defaultModel.prototype.defaults.traits),
			},
			isComponent: function (el: any) {
				if (el && ['UL', 'OL'].includes(el.tagName)) {
					return { type: 'list' };
				}

				return false;
			},
		},
		view: defaultView,
	});
};
