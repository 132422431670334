import { IconClose, IconGridLayout, IconMosaicTheme, IconSliderLayout } from '@assets/icons';
import { TYPE_THEME } from '@constants/publish/gallery';
import { StyledIcon } from '@styled/Common/CommonStyled';
import { StyledGalleryTag, StyledNameTag } from '@styled/ContentLibrary/ContentDetailStyled';
import React, { useMemo } from 'react';

type PropGalleryTag = {
	label: string;
	onClose: (val: any) => void;
	theme: string;
	[other: string]: any;
};

const GalleryTag = (props: PropGalleryTag) => {
	const { label, onClose, theme } = props;

	const renderIcon = useMemo(() => {
		let result = <IconGridLayout />;
		if (theme === TYPE_THEME.MOSAIC) {
			result = <IconMosaicTheme />;
		}
		if (theme == TYPE_THEME.SLIDER) {
			result = <IconSliderLayout />;
		}
		return result;
	}, [theme]);

	return (
		<StyledGalleryTag>
			<StyledIcon className="icon_theme_gallery">{renderIcon}</StyledIcon>
			<StyledNameTag>{label}</StyledNameTag>
			<StyledIcon className="icon_close" onClick={onClose}>
				<IconClose />
			</StyledIcon>
		</StyledGalleryTag>
	);
};

export default GalleryTag;
