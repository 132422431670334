import styled from 'styled-components';

const StyledWrapperHeader = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	overflow: auto hidden;
	white-space: nowrap;
`;

export { StyledWrapperHeader };
