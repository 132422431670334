import { Action, handleActions } from 'redux-actions';

import { STATUSCODE } from '@constants/APIs';
import { DEFAULT_ERROR } from '@constants/errors';
import { FAILED, IN_PROGRESS, NONE, SUCCEEDED } from '@constants/status';
import { UpdateLandingPageDto } from '@models/landingpagemanager';
import { LandingPageStoreDto } from '@models/landingpagemanager/stores';
import {
	clonePublishedLandingPageTypes,
	createLandingPageTypes,
	deleteLandingPageTypes,
	getLandingPageDetailTypes,
	getLandingPageListLiteTypes,
	getLandingPageListTypes,
	publishLandingPageTypes,
	resumeLandingPageDraftTypes,
	storeLandingPageDataTypes,
	unPublishLandingPageTypes,
	updateLandingPageTypes,
} from './landingPageManager.types';

const initialState: LandingPageStoreDto = {
	gettingLandingPageList: false,
	createLandingPageStatus: NONE,
	clonePublishedLandingPageStatus: NONE,
	resumeLandingPageDraftStatus: NONE,
	updateLandingPageStatus: NONE,
	publishLandingPageStatus: NONE,
	deleteLandingPageStatus: NONE,
	unPublishLandingPageStatus: NONE,
};

const getLandingPageListRequest = (state: LandingPageStoreDto) => ({
	...state,
	gettingLandingPageList: true,
});

const getLandingPageListSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: landingPageListResult = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			gettingLandingPageList: false,
			landingPageListResult,
			error: '',
		};
	}

	return {
		...state,
		gettingLandingPageList: false,
		landingPageListResult: [],
		error: status,
	};
};

const getLandingPageListFailed = (state: LandingPageStoreDto, { payload = DEFAULT_ERROR }: any) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			gettingLandingPageList: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		gettingLandingPageList: false,
	};
};

const getLandingPageListLiteRequest = (state: LandingPageStoreDto) => ({
	...state,
	gettingLandingPageList: true,
});

const getLandingPageListLiteSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: landingPageListLiteResult = [],
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			gettingLandingPageList: false,
			landingPageListLiteResult,
			error: '',
		};
	}

	return {
		...state,
		gettingLandingPageList: false,
		landingPageListResult: [],
		error: status,
	};
};

const getLandingPageListLiteFailed = (
	state: LandingPageStoreDto,
	{ payload = DEFAULT_ERROR }: any,
) => {
	if (payload.data) {
		const { message = '', status = NONE, error = '' } = payload.data;

		return {
			...state,
			gettingLandingPageList: false,
			error: error !== '' ? error : `${status} ${message}`,
		};
	}

	return {
		...state,
		gettingLandingPageList: false,
	};
};

const createLandingPageRequest = (state: LandingPageStoreDto) => ({
	...state,
	createLandingPageStatus: IN_PROGRESS,
});

const createLandingPageSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: landingPageDetailResult,
		isDuplicated,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const result = {
			...state,
			createLandingPageStatus: SUCCEEDED,
			error: '',
		};
		if (!isDuplicated) {
			result.landingPageDetailResult = landingPageDetailResult; // landingPageDetailResult is used to check and show the editor modal
		} else {
			result.duplicatedLandingPageDetailResult = landingPageDetailResult;
		}
		return {
			...result,
		};
	}

	return {
		...state,
		createLandingPageStatus: FAILED,
		error: status,
	};
};

const createLandingPageFailed = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		createLandingPageStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const createLandingPageEnd = (state: LandingPageStoreDto) => ({
	...state,
	createLandingPageStatus: NONE,
	duplicatedLandingPageDetailResult: null,
});

const clonePublishedLandingPageRequest = (state: LandingPageStoreDto) => ({
	...state,
	clonePublishedLandingPageStatus: IN_PROGRESS,
});

const clonePublishedLandingPageSucceeded = (
	state: LandingPageStoreDto,
	{ payload }: Action<any>,
) => {
	const {
		status: { code = NONE, status = '' },
		result: landingPageDetailResult,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			clonePublishedLandingPageStatus: SUCCEEDED,
			landingPageDetailResult,
			error: '',
		};
	}

	return {
		...state,
		clonePublishedLandingPageStatus: FAILED,
		error: status,
	};
};

const clonePublishedLandingPageFailed = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		clonePublishedLandingPageStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const clonePublishedLandingPageEnded = (state: LandingPageStoreDto) => ({
	...state,
	clonePublishedLandingPageStatus: NONE,
});

const resumeLandingPageDraftRequest = (state: LandingPageStoreDto) => ({
	...state,
	resumeLandingPageDraftStatus: IN_PROGRESS,
});

const resumeLandingPageDraftSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: landingPageDetailResult,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			resumeLandingPageDraftStatus: SUCCEEDED,
			landingPageDetailResult,
			error: '',
		};
	}

	return {
		...state,
		resumeLandingPageDraftStatus: FAILED,
		error: status,
	};
};

const resumeLandingPageDraftFailed = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		resumeLandingPageDraftStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const resumeLandingPageDraftEnded = (state: LandingPageStoreDto) => ({
	...state,
	resumeLandingPageDraftStatus: NONE,
});

const getLandingPageDetailRequest = (state: LandingPageStoreDto) => ({
	...state,
	getLandingPageDetailStatus: IN_PROGRESS,
});

const getLandingPageDetailSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: landingPageDetailResult,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			getLandingPageDetailStatus: SUCCEEDED,
			landingPageDetailResult,
			error: '',
		};
	}

	return {
		...state,
		getLandingPageDetailStatus: FAILED,
		error: status,
	};
};

const getLandingPageDetailFailed = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		getLandingPageDetailStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const getLandingPageDetailEnd = (state: LandingPageStoreDto) => ({
	...state,
	getLandingPageDetailStatus: NONE,
});

const updateLandingPageRequest = (
	state: LandingPageStoreDto,
	{ payload }: Action<UpdateLandingPageDto>,
) => {
	return {
		...state,
		isSettingDefaultLandingPage: payload.isSetDefault,
		updateLandingPageStatus: IN_PROGRESS,
	};
};

const updateLandingPageSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		const returnedValues = {
			...state,
			updateLandingPageStatus: SUCCEEDED,
			error: '',
		};

		if (result) {
			returnedValues.updatedLandingPage = result;
		}

		return {
			...returnedValues,
		};
	}

	return {
		...state,
		updateLandingPageStatus: FAILED,
		error: status,
	};
};

const updateLandingPageFailed = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		updateLandingPageStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const updateLandingPageEnd = (state: LandingPageStoreDto) => ({
	...state,
	updateLandingPageStatus: NONE,
});

const publishLandingPageRequest = (state: LandingPageStoreDto) => ({
	...state,
	publishLandingPageStatus: IN_PROGRESS,
});

const publishLandingPageSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: publishedLandingPageResult,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			publishLandingPageStatus: SUCCEEDED,
			publishedLandingPageResult,
			error: '',
		};
	}

	return {
		...state,
		publishLandingPageStatus: FAILED,
		error: status,
	};
};

const publishLandingPageFailed = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		publishLandingPageStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const publishLandingPageEnd = (state: LandingPageStoreDto) => ({
	...state,
	publishLandingPageStatus: NONE,
});

const deleteLandingPageRequest = (state: LandingPageStoreDto) => ({
	...state,
	deleteLandingPageStatus: IN_PROGRESS,
});

const deleteLandingPageSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
		result: updatedLandingPage,
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			updatedLandingPage,
			deleteLandingPageStatus: SUCCEEDED,
			error: '',
		};
	}

	return {
		...state,
		deleteLandingPageStatus: FAILED,
		error: status,
	};
};

const deleteLandingPageFailed = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		deleteLandingPageStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const deleteLandingPageEnd = (state: LandingPageStoreDto) => ({
	...state,
	deleteLandingPageStatus: NONE,
	updatedLandingPage: null,
});

const unPublishLandingPageRequest = (state: LandingPageStoreDto) => ({
	...state,
	unPublishLandingPageStatus: IN_PROGRESS,
});

const unPublishLandingPageSucceeded = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const {
		status: { code = NONE, status = '' },
	} = payload.data;

	// TODO, will be update once we have offical response data format from BE
	if (code === STATUSCODE.SUCCESS) {
		return {
			...state,
			unPublishLandingPageStatus: SUCCEEDED,
			error: '',
		};
	}

	return {
		...state,
		unPublishLandingPageStatus: FAILED,
		error: status,
	};
};

const unPublishLandingPageFailed = (state: LandingPageStoreDto, { payload }: Action<any>) => {
	const result = {
		...state,
		unPublishLandingPageStatus: FAILED,
		error: DEFAULT_ERROR,
	};

	if (payload.data) {
		const { error } = payload.data;

		if (error) {
			result.error = error;
		}
	}

	return result;
};

const unPublishLandingPageEnd = (state: LandingPageStoreDto) => ({
	...state,
	unPublishLandingPageStatus: NONE,
});

const storeLandingPageDataRequest = (state: LandingPageStoreDto, { payload }: Action<any>) => ({
	...state,
	...payload,
});

const landingPageManagerReducers = handleActions<any>(
	{
		[getLandingPageListTypes.GET_LANDING_PAGE_LIST_REQUEST]: getLandingPageListRequest,
		[getLandingPageListTypes.GET_LANDING_PAGE_LIST_SUCCEEDED]: getLandingPageListSucceeded,
		[getLandingPageListTypes.GET_LANDING_PAGE_LIST_FAILED]: getLandingPageListFailed,

		[getLandingPageListLiteTypes.GET_LANDING_PAGE_LIST_LITE_REQUEST]:
			getLandingPageListLiteRequest,
		[getLandingPageListLiteTypes.GET_LANDING_PAGE_LIST_LITE_SUCCEEDED]:
			getLandingPageListLiteSucceeded,
		[getLandingPageListLiteTypes.GET_LANDING_PAGE_LIST_LITE_FAILED]:
			getLandingPageListLiteFailed,

		[createLandingPageTypes.CREATE_LANDING_PAGE_REQUEST]: createLandingPageRequest,
		[createLandingPageTypes.CREATE_LANDING_PAGE_SUCCEEDED]: createLandingPageSucceeded,
		[createLandingPageTypes.CREATE_LANDING_PAGE_FAILED]: createLandingPageFailed,
		[createLandingPageTypes.CREATE_LANDING_PAGE_ENDED]: createLandingPageEnd,

		[clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_REQUEST]:
			clonePublishedLandingPageRequest,
		[clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_SUCCEEDED]:
			clonePublishedLandingPageSucceeded,
		[clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_FAILED]:
			clonePublishedLandingPageFailed,
		[clonePublishedLandingPageTypes.CLONE_PUBLISHED_LANDING_PAGE_ENDED]:
			clonePublishedLandingPageEnded,

		[resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_REQUEST]:
			resumeLandingPageDraftRequest,
		[resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_SUCCEEDED]:
			resumeLandingPageDraftSucceeded,
		[resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_FAILED]:
			resumeLandingPageDraftFailed,
		[resumeLandingPageDraftTypes.RESUME_LANDING_PAGE_DRAFT_ENDED]: resumeLandingPageDraftEnded,

		[getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_REQUEST]: getLandingPageDetailRequest,
		[getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_SUCCEEDED]:
			getLandingPageDetailSucceeded,
		[getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_FAILED]: getLandingPageDetailFailed,
		[getLandingPageDetailTypes.GET_LANDING_PAGE_DETAIL_ENDED]: getLandingPageDetailEnd,

		[updateLandingPageTypes.UPDATE_LANDING_PAGE_REQUEST]: updateLandingPageRequest,
		[updateLandingPageTypes.UPDATE_LANDING_PAGE_SUCCEEDED]: updateLandingPageSucceeded,
		[updateLandingPageTypes.UPDATE_LANDING_PAGE_FAILED]: updateLandingPageFailed,
		[updateLandingPageTypes.UPDATE_LANDING_PAGE_ENDED]: updateLandingPageEnd,

		[publishLandingPageTypes.PUBLISH_LANDING_PAGE_REQUEST]: publishLandingPageRequest,
		[publishLandingPageTypes.PUBLISH_LANDING_PAGE_SUCCEEDED]: publishLandingPageSucceeded,
		[publishLandingPageTypes.PUBLISH_LANDING_PAGE_FAILED]: publishLandingPageFailed,
		[publishLandingPageTypes.PUBLISH_LANDING_PAGE_ENDED]: publishLandingPageEnd,

		[deleteLandingPageTypes.DELETE_LANDING_PAGE_REQUEST]: deleteLandingPageRequest,
		[deleteLandingPageTypes.DELETE_LANDING_PAGE_SUCCEEDED]: deleteLandingPageSucceeded,
		[deleteLandingPageTypes.DELETE_LANDING_PAGE_FAILED]: deleteLandingPageFailed,
		[deleteLandingPageTypes.DELETE_LANDING_PAGE_ENDED]: deleteLandingPageEnd,

		[unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_REQUEST]: unPublishLandingPageRequest,
		[unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_SUCCEEDED]: unPublishLandingPageSucceeded,
		[unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_FAILED]: unPublishLandingPageFailed,
		[unPublishLandingPageTypes.UNPUBLISH_LANDING_PAGE_ENDED]: unPublishLandingPageEnd,

		[storeLandingPageDataTypes.STORE_LANDING_PAGE_DATA_REQUEST]: storeLandingPageDataRequest,
	},
	initialState,
);
export default landingPageManagerReducers;
