import styled from 'styled-components';
import { Row, Col, Form, Tabs, Switch, Menu, Button, Input, Modal } from 'antd';
import { IconEdit, IconArchive2 } from '@assets/icons';
import Wrapper from '@cores/Wrapper';
import THEME from '@constants/themes/themes';
import { StyledWrapperContent } from '@styled/Common/CommonStyled';

const breakpoint = {
	device: {
		xs: '320px',
		sm: '768px',
		lg: '1366px',
		xl: '1661px',
	},
};

const StyledWrapper = styled(({ ...props }) => <Wrapper {...props} />)<{
	bgColor?: string;
}>`
	height: calc(100vh - 82px);
	background-color: ${THEME.colors.darkBlue};
	background-color: ${({ bgColor }) => bgColor || THEME.colors.darkBlue};
`;

const StyledCol = styled(Col)<{
	background?: boolean;
	padding?: string;
	margintop?: string;
	paddingLeft?: string;
	paddingRight?: string;
}>`
	background-color: ${({ background }) => (background ? THEME.colors.darkBlue : 'unset')};
	padding: ${({ padding }) => `${padding} !important` || 'unset'};
	margin-top: ${({ margintop }) => margintop || 'unset'};
	padding-left: ${({ paddingLeft }) => `${paddingLeft} !important` || 'unset'};
	padding-right: ${({ paddingRight }) => `${paddingRight} !important` || 'unset'};
`;

const fontText = () => `
	width: 100%;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	font-weight: normal;
	color: ${THEME.colors.gray5};

	display: flex;
	align-items: center;
`;

const StyledForm = styled(({ ...props }) => <Form {...props} />)<{
	isCustom?: boolean;
}>`
	display: flex;
	align-items: center;
	.ant-form-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: ${({ isCustom }) => (isCustom ? '0 16px' : '0')};
	}
	.ant-form-item-label {
		padding: 0;
	}
`;

const StyledFormItem = styled(({ width, ...props }) => <Form.Item {...props} />)`
	width: ${({ width }) => `${width} !important` || 'auto'};
	&.email-template-input {
		padding-left: 16px;
		padding-right: 0px;
	}

	.ant-form-item-label > label {
		${fontText()}
		font-size: 1rem;
		line-height: 1.43;
		font-weight: bold;
	}

	.ant-form-item-label
		> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}

	.ant-input-affix-wrapper,
	.ant-input {
		&::placeholder {
			font-size: 1rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: normal;
			text-align: left;
			color: ${THEME.colors.darkBlue5};
		}
	}
`;

const StyledInput = styled(({ ...props }) => <Input {...props} />)`
	height: 32px;
`;

const StyledButtonAdd = styled(({ ...props }) => <Button {...props} />)`
	margin-top: 32px;
`;

const StyledSearchBlock = styled.div`
	padding: 16px;
	width: 100%;
`;

const StyledTabs = styled(({ ...props }) => <Tabs {...props} />)`
	.ant-tabs-nav-wrap {
		padding: 0 16px;
	}

	.ant-tabs-tab {
		padding: 12px 14px;
	}

	.ant-tabs-content {
		height: calc(100vh - 212px) !important;
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab-btn {
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: center;
		color: ${THEME.colors.white};
	}

	.ant-tabs-nav::before {
		border-bottom: 1px solid ${THEME.colors.darkBlue3};
	}
`;

const StyledAutomatedActions = styled(StyledWrapperContent)`
	width: fit-content;
`;

const StyledActionFlex = styled.div<{ gap?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	${({ gap }) => gap && `gap: ${gap}`};

	svg {
		width: 22px !important;
		height: 22px !important;
	}

	.ant-btn-circle {
		border: none;

		&:hover {
			box-shadow: none;
			background: transparent;
		}
	}

	.ant-switch-checked {
		// equivalent (THEME.colors.orangeBase + opacity: 0.4)
		background-color: #5e322c;
	}

	.ant-switch-checked .ant-switch-handle::before {
		background-color: ${THEME.colors.orangeBase};
	}
`;

const StyledActionSwitch = styled(({ ...props }) => <Switch {...props} />)`
	// equivalent (THEME.colors.gray5 + opacity: 0.4)
	background-color: #5b6873;
	margin-right: 16px;

	.ant-switch-handle::before {
		background-color: ${THEME.colors.gray5};
	}

	@media screen and (max-width: ${breakpoint.device.lg}) {
		margin-right: 0;
	}
`;

const StyledIconEdit = styled(({ ...props }) => <IconEdit {...props} />)`
	cursor: pointer;
	@media screen and (max-width: ${breakpoint.device.lg}) {
		margin-right: 2px;
		transform: scale(0.9);
	}

	@media screen and (max-width: 986px) {
		margin-right: 0;
		margin-left: -4px;
		transform: scale(0.7);
	}
`;

const StyledIconArchive = styled(({ ...props }) => <IconArchive2 {...props} />)`
	cursor: pointer;
	@media screen and (max-width: 986px) {
		transform: scale(0.88);
	}
`;

const StyledMenu = styled(({ ...props }) => <Menu {...props} />)<{
	isCustom?: boolean;
}>`
	max-height: ${({ isCustom }) => (isCustom ? 'calc(100vh - 324px)' : 'calc(100vh - 240px)')};
	overflow: hidden auto;
	&:hover {
		&::-webkit-scrollbar {
			opacity: 1;
		}
		&::-webkit-scrollbar-thumb {
			visibility: unset;
		}
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		visibility: hidden;
	}
	.ant-menu-item {
		height: max-content;
		flex-direction: row-reverse;
		padding-left: 0 !important;
		padding: 18px;
		border-bottom: 1px solid ${THEME.colors.darkBlue3};
	}

	.ant-menu-item-selected {
		.ant-menu-title-content {
			div.last-updated {
				color: ${THEME.colors.gray5} !important;
			}
		}

		.ant-switch {
			// equivalent (#5b6873+ opacity: 0.4)
			background-color: #5b687366;
		}

		.ant-switch-checked {
			// equivalent (THEME.colors.orangeBase + opacity: 0.4)
			background-color: #f09c84;
		}

		${StyledIconArchive} {
			path {
				fill: ${THEME.colors.gray5} !important;
			}
		}
		.ant-btn {
			border: 1px solid ${THEME.colors.gray5};

			svg {
				fill: ${THEME.colors.gray5} !important;
				path {
					fill: ${THEME.colors.gray5} !important;
				}
			}
		}
	}

	.ant-menu-item {
		& {
			.ant-menu-item-icon {
			}
			svg.email-template-icon-edit:hover {
				fill: ${THEME.colors.primary} !important;
			}
			svg.email-template-icon-archive:hover {
				fill: ${THEME.colors.primary} !important;
			}
		}

		&.email-template-menu-item {
			&:active {
				background-color: transparent !important;
			}
		}
	}

	.ant-menu-item-selected {
		& {
			.ant-menu-item-icon {
			}
			svg.email-template-icon-edit:hover {
				fill: ${THEME.colors.gray5} !important;
			}
			svg.email-template-icon-archive:hover {
				fill: ${THEME.colors.gray5} !important;
			}
		}
		&:hover .ant-btn {
			border: 1px solid ${THEME.colors.gray5};
		}
	}

	.ant-menu-title-content {
		padding-right: 24px;
	}

	.ant-menu-item .ant-menu-item-icon + span,
	.ant-menu-submenu-title .ant-menu-item-icon + span,
	.ant-menu-item .anticon + span,
	.ant-menu-submenu-title .anticon + span {
		margin-left: 16px;
	}

	.ant-menu-item-icon {
		.ant-switch {
			min-width: 44px;
			min-height: 22px;

			.ant-switch-handle {
				height: 18px;
				width: 18px;
			}
		}
	}

	@media screen and (max-width: ${breakpoint.device.lg}) {
		.ant-menu-item {
			padding-right: 8px !important;
		}
		.ant-menu-title-content {
			padding-right: 0;
		}
		.ant-menu-item-icon {
			.ant-switch {
				transform: scale(0.7);
			}
		}

		.automated-date {
			font-size: 0.7rem;
		}
	}
	@media screen and (max-width: 986px) {
		.ant-menu-item-icon {
			.ant-switch {
				transform: scale(0.6);
			}
		}
	}
`;

const StyledText = styled.div<{
	fontSize?: string;
	fontWeight?: string | number;
	color?: string | number;
}>`
	white-space: break-spaces;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	padding: 4px 0;

	color: ${({ color }) => `${color} !important` || '#fff'};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	&.overflow {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	@media screen and (max-width: ${breakpoint.device.lg}) {
		font-size: 0.8rem;
	}
`;

const StyledButtonRestore = styled(({ ...props }) => <Button {...props} />)`
	span {
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: center;
		color: ${THEME.colors.gray5};
	}

	&:hover {
		background-color: ${THEME.colors.orangeBase};
		svg {
			fill: ${THEME.colors.gray5} !important;
			path {
				fill: ${THEME.colors.gray5} !important;
			}
		}
	}
`;

const StyledEditModal = styled(({ ...props }) => <Modal {...props} />)`
	min-width: 1500px;
	top: 12px;

	.ant-modal-body {
		padding: 0;
		background-color: ${THEME.colors.dark};
	}

	.ant-modal-header {
		padding: 8px 16px;

		.ant-modal-title {
			text-transform: capitalize;
		}
	}
`;

const StyledTemplateDetails = styled.div`
	width: 100%;
	min-height: 500px;
	height: 100%;
	position: relative;
`;

const StyledTemplateBody = styled.div`
	padding: 16px;
`;

const StyledRowModal = styled(({ ...props }) => <Row {...props} />)`
	background-color: ${THEME.colors.dark};
`;

const StyledRowTemplateForm = styled(({ ...props }) => <Row {...props} />)`
	padding-bottom: 16px;

	.ant-form-item {
		display: flex;
		flex-direction: column;
	}
`;

const StyledColModal = styled(({ ...props }) => <Col {...props} />)`
	background-color: ${THEME.colors.darkBlue};
`;

const StyledFooterModal = styled.div`
	width: 100%;
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 16px 24px;
	border-top: 1px solid ${THEME.colors.darkBlue3};
`;

const StyledBtn = styled(({ bgColor, marginRight, padding, ...props }) => <Button {...props} />)`
	width: auto;
	text-transform: capitalize;
	margin-right: ${({ marginRight }) => marginRight || '16px'};
	background-color: ${({ bgColor }) => bgColor || 'transparent'};
	padding: ${({ padding }) => padding || null};
`;

const StyledTemplateHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border-bottom: 1px solid ${THEME.colors.darkBlue4};
`;

const StyledIconClose = styled.span`
	cursor: pointer;
	height: 24px;
`;

const StyledTitleModal = styled.div<{ fontWeight?: string; paddingTop?: string }>`
	${fontText()}
	font-size: 1.143rem;
	line-height: 24px;
	padding-top: ${({ paddingTop }) => paddingTop || '8px'};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
`;

const StyledSubTitleModal = styled.span<{
	fontWeight?: string | number;
	color?: string | number;
}>`
	${fontText()}
	width: fit-content;
	padding-top: 12px;
	padding-bottom: 24px;
	color: ${({ color }) => color || `${THEME.colors.gray5}`};
	font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
`;

const StyledFormEditTemplateModal = styled(({ ...props }) => <Form {...props} />)`
	.ant-form-item-label > label {
		${fontText()};
		font-size: 0.857rem;
		line-height: 1.5;
		color: ${THEME.colors.gray1};
	}

	.ant-form-item {
		margin-bottom: 0;
	}
`;

const StyledTemplateInput = styled(({ border, ...props }) => <Input {...props} />)`
	height: 32px;
	${fontText()}
	line-height: 1.43px;
	font-size: 1rem;
	border: ${({ border }) => border || `1px solid ${THEME.colors.darkBlue4}`};
	padding: 6px 12px;

	&:focus {
		outline: none;
		box-shadow: none;
	}
`;

const StyledTemplateTextArea = styled(({ ...props }) => <Input.TextArea {...props} />)`
	min-height: 32px;
	${fontText()}
	line-height: 24px;

	&:focus {
		outline: none;
		box-shadow: none;
	}
`;

const StyledDynamicField = styled.div`
	width: 100%;
	height: 200px;
	overflow: auto;
	padding: 0 16px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 16px;
	padding-bottom: 24px;
	border-bottom: 1px solid ${THEME.colors.darkBlue3};
`;

const StyledDynamicBtn = styled.div`
	font-size: 0.857rem;
	display: inline-block;
	padding: 5px 5px 5px 6px;
	border-radius: 2px;
	background-color: ${THEME.colors.darkBlue3};
	cursor: pointer;
`;

const StyleFlex = styled.div`
	width: 100%;
	display: flex;
	height: 100%;
`;

const EmailTemplateLeftPanel = styled.div`
	width: 100%;
`;

const StyledSubject = styled(Row)`
	height: 54px;
	background-color: ${THEME.colors.darkBlue2};
	align-items: center;
	color: ${THEME.colors.gray5};
	padding: 0 16px;
`;

const StyledNotificationBox = styled.div`
	width: 100%;
	position: absolute;
	bottom: 100px;
	right: 0;
	padding: 0 16px;

	.notificationBox {
		background-color: #0b2036;
		padding: 12px;
		${fontText()}
		font-size: 1rem;
		line-height: 1.43;

		&__title {
			font-weight: 600;
			color: ${THEME.colors.blueBase};
			padding-bottom: 13px;
		}

		&__content {
			color: ${THEME.colors.blueBase};
		}

		&__action {
			color: ${THEME.colors.redBase};
			font-weight: 600;
			cursor: pointer;
			padding-top: 24px;
		}

		svg {
			margin-right: 8px;
			fill: ${THEME.colors.blue};
		}
	}
`;

const StyledPreview = styled.div`
	width: 100%;
	background: transparent;
`;

const StyledPreviewPanelHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px;
`;

const StyledLeftTitle = styled.div`
	flex-direction: column;
	// align-items: center;
	justify-content: center;
`;

const StyledTimeSub = styled.div`
	margin-top: 4px;
	font-size: 0.857rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: ${THEME.colors.gray1};
`;

const StyledButtonEdit = styled(Button)`
	margin-right: 16px;
	&:hover {
		svg {
			path {
				fill: ${THEME.colors.orangeBase};
			}
		}
	}
	svg {
		path {
			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}
`;

const StyledActionPreview = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledPreviewTitle = styled.div`
	width: 100%;
	${fontText()}
	font-weight: bold;
`;

const StyledPreviewPanelBtns = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.send-btn {
		margin-left: 16px;
	}
`;

const StyledCenterContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export {
	StyledCol,
	StyledWrapper,
	StyledForm,
	StyledFormItem,
	StyledSearchBlock,
	StyledTabs,
	StyledAutomatedActions,
	StyledActionFlex,
	StyledActionSwitch,
	StyledIconEdit,
	StyledIconArchive,
	StyledMenu,
	StyledText,
	StyledButtonRestore,
	StyledButtonAdd,
	StyledInput,
	StyledEditModal,
	StyledFooterModal,
	StyledBtn,
	StyledRowModal,
	StyledColModal,
	StyledRowTemplateForm,
	StyledTemplateHeader,
	StyledIconClose,
	StyledTitleModal,
	StyledSubTitleModal,
	StyledTemplateInput,
	StyledTemplateTextArea,
	StyledFormEditTemplateModal,
	StyledDynamicField,
	StyledDynamicBtn,
	StyledTemplateDetails,
	StyledTemplateBody,
	StyleFlex,
	EmailTemplateLeftPanel,
	StyledSubject,
	StyledNotificationBox,
	StyledPreview,
	StyledPreviewPanelHeader,
	StyledPreviewPanelBtns,
	StyledPreviewTitle,
	StyledActionPreview,
	StyledButtonEdit,
	StyledLeftTitle,
	StyledTimeSub,
	StyledCenterContainer,
};
