import { UploaderButtonSVG } from '@assets/icons/svg/grapesjs';

export const UploaderButtonBlock = (bm: any, label: string) => {
	bm.add('upload-widget', {
		label,
		media: UploaderButtonSVG,
		content:
			'<button data-gjs-type="uploader-button" data-close-outside="1" type="button"><p>Uploader Button</p></button>',
	});
};

export default (domc: any, editor?: any) => {
	domc.addType('uploader-button', {
		model: {
			defaults: {
				'custom-name': 'Uploader Button',
				editable: true,
				resizable: true,
				attributes: {
					class: 'custom_widget btn btn-primary',
				},
				traits: [
					{
						type: 'uploader-link',
						label: 'Uploader',
						name: 'uploaderLink',
						placeholder: ' Input an uploader link',
						changeProp: true,
					},
				],
			},
		},
	});
};
