import { PARAMS_DEFAULT_CONTENT } from '@constants/publish/gallery';
import { AlbumRespDto, AnalyzeContentDto } from '@models/content/albums/albumManager';
import {
	ContentContainerDto,
	ContentDto,
	ContentReviewDto,
} from '@models/content/contentLibrary/summary';
import { ParamRequestContentDto } from '@models/publishing/galleries';
import * as React from 'react';

export type ContentSummary = {
	updateAfterReviewingContent: (reviewedContents: ContentReviewDto[]) => void;
	albumList: AlbumRespDto[];
	setVisibleAlbumPopup: (val: string) => void;
	handleAssignContentToAlbum: (val: AlbumRespDto[]) => void;
	albumsSelect: AlbumRespDto[];
	setAlbumsSelect: (val: AlbumRespDto[]) => void;
	visibleAlbum: boolean;
	setVisibleAlbum: (val: boolean) => void;
	isLoading: boolean;
	typeContent: {
		isBookmark: boolean;
		isSubmission: boolean;
		isArchive: boolean;
		isFavorite: boolean;
		isAlbumDetail: boolean;
	};
	contentList: ContentContainerDto[];
	setContentList: (val: ContentContainerDto[]) => void;
	isFiltering: boolean;
	resetContentFilters: (val?: boolean) => void;
	setParamsContent: (val: any) => void;
	paramsContent: ParamRequestContentDto;
	handleClickMarkAll: () => void;
	namePage?: string;
	isAlbumSuggest: boolean;
	setTypeAction: (val: string) => void;
	typeAction: string;
	markAsFavoriteContent: (content: ContentDto) => void;
	setContentIdMove: (val: number | null) => void;
	enableShare: boolean;
	setVisibleShareModal: (visible: boolean) => void;
	setAnalyzeContent: (object: AnalyzeContentDto) => void;
	setSelectContentList: (val: ContentContainerDto[]) => void;
	selectContentList: ContentContainerDto[];
	contentDetail: ContentContainerDto | null;
	setContentDetail: (val: ContentContainerDto | null) => void;
	handleClearSelect: () => void;
	setIsLoading: (val: boolean) => void;
	otherLoading: boolean;
	setOtherLoading: (val: boolean) => void;
	contentIdSelect: number[];
	findSimilar: boolean;
	setFindSimilar: (val: boolean) => void;
};

// Default context values
const values: ContentSummary = {
	updateAfterReviewingContent: () => {},
	albumList: [],
	setVisibleAlbumPopup: () => {},
	handleAssignContentToAlbum: () => {},
	albumsSelect: [],
	setAlbumsSelect: () => {},
	visibleAlbum: false,
	setVisibleAlbum: () => {},
	isLoading: false,
	typeContent: {
		isBookmark: false,
		isSubmission: false,
		isArchive: false,
		isFavorite: false,
		isAlbumDetail: false,
	},
	contentList: [],
	setContentList: () => {},
	isFiltering: false,
	resetContentFilters: () => {},
	setParamsContent: () => {},
	paramsContent: PARAMS_DEFAULT_CONTENT,
	handleClickMarkAll: () => {},
	isAlbumSuggest: false,
	typeAction: '',
	setTypeAction: () => {},
	markAsFavoriteContent: () => {},
	setContentIdMove: () => {},
	enableShare: false,
	setVisibleShareModal: () => {},
	setAnalyzeContent: () => {},
	setSelectContentList: () => {},
	selectContentList: [],
	contentDetail: null,
	setContentDetail: () => {},
	handleClearSelect: () => {},
	setIsLoading: () => {},
	otherLoading: false,
	setOtherLoading: () => {},
	contentIdSelect: [],
	findSimilar: false,
	setFindSimilar: () => {},
};

const ContentSummaryContext = React.createContext(values);
export default ContentSummaryContext;
