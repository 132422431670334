import { IconLightBulb } from '@assets/icons';
import {
	DEFAULT_VERSION_ID,
	FORM_CONTENT_DETAIL,
	MEDIA_TYPE,
	SELECTOR_ELEMENTS,
} from '@constants/content/contentLibrary';
import THEME from '@constants/themes/themes';
import ContentDetailContext from '@contexts/Content/ContentDetail';
import { ContentRootStateDto, ContentStoreDto } from '@models/content/contentLibrary/store';
import { ContentDto } from '@models/content/contentLibrary/summary';
import {
	StyledFlex,
	StyledIcon,
	StyledText,
	StyledWrapperContent,
} from '@styled/Common/CommonStyled';
import { StyledCustomSelect } from '@styled/Content/ContentLibrary/ActionBarStyled';
import { StyledFormItem, StyledRate } from '@styled/Content/ContentLibrary/ContentDetailsStyled';
import {
	StyledLabel,
	StyledTagAI,
	SwapperSessionDetail,
} from '@styled/ContentLibrary/ContentDetailStyled';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import VideoLabelDetection, { DataDetectionItemTypes } from '../VideoLabelDetection';
import { sortNumber } from '@utils/funcHelper';

const { Option, OptGroup } = StyledCustomSelect;

type PropDetailRateAndTag = {
	optionLabels: Array<string | number>;
	currentMedia: ContentDto;
	otherPropSelectTag: any;
};
const DetailRateAndTag = (props: PropDetailRateAndTag) => {
	const { t } = useTranslation();

	const { optionLabels, currentMedia, otherPropSelectTag } = props;
	const {
		isDisableAction,
		setShowDetectedLogo,
		versionImgSelected,
		showDetectedLogo,
		isSocialContent,
	} = useContext(ContentDetailContext);
	const { contentDetection }: ContentStoreDto = useSelector(
		(state: ContentRootStateDto) => state.content,
	);

	const { logoDetection, videoLabelDetection } = contentDetection || {};
	const { googleAIHashtags, detected, mediaType } = currentMedia;

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const handleShowDetectedLogo = () => {
		setShowDetectedLogo(true);
	};

	const renderDetectedLogo = () => {
		return (
			<StyledFlex justify="flex-start" align="center">
				<StyledIcon
					fillPath={showDetectedLogo ? THEME.colors.yellowBase : THEME.colors.gray5}
					size={24}
					margin="0 6px 0 0"
					bgColor="#161114"
					padding="2px">
					<IconLightBulb />
				</StyledIcon>

				{t('content_detail.logo_detected')}
				<StyledText
					onClick={handleShowDetectedLogo}
					cursor="pointer"
					padding="0 4px"
					fontWeight={700}>
					{t('content_detail.show_me_where')}
				</StyledText>
			</StyledFlex>
		);
	};

	const dataVideoLogoDetect = useMemo(() => {
		if (logoDetection) {
			const result: DataDetectionItemTypes[] = [];
			logoDetection.forEach((logo) => {
				const idx = result.findIndex((item) => item?.description === logo.label);
				if (idx === -1) {
					result.push({ times: [logo.timestamp], description: logo.label });
				} else {
					if (!result[idx].times.includes(logo.timestamp)) {
						result[idx].times.push(logo.timestamp);
					}
				}
			});

			result.forEach((sItem) => {
				sItem.times.sort(sortNumber);
			});
			return result;
		}
		return null;
	}, [logoDetection]);

	return (
		<SwapperSessionDetail id={'star_detail_content'}>
			<StyledFormItem className="form-item-star" name={FORM_CONTENT_DETAIL.STAR}>
				<StyledRate className="rate_content" disabled={isDisableAction} />
			</StyledFormItem>
			{!isSocialContent && (
				<>
					{detected &&
						logoDetection &&
						logoDetection?.length > 0 &&
						((versionImgSelected?.id === DEFAULT_VERSION_ID &&
							mediaType === MEDIA_TYPE.IMAGE) ||
							mediaType === MEDIA_TYPE.VIDEO) &&
						renderDetectedLogo()}
					{showDetectedLogo && mediaType === MEDIA_TYPE.VIDEO && dataVideoLogoDetect && (
						<StyledWrapperContent margin="8px 0 0 0">
							<VideoLabelDetection
								idElm={SELECTOR_ELEMENTS.VIDEO_CONTENT_DETAIL_ID}
								dataDetections={dataVideoLogoDetect}
							/>
						</StyledWrapperContent>
					)}
				</>
			)}
			<StyledFormItem
				margin="16px 0 10px 0"
				name={FORM_CONTENT_DETAIL.TAGS}
				label={<StyledLabel>{t('content_detail.tags')}</StyledLabel>}>
				<StyledCustomSelect
					disabled={isDisableAction}
					mode={'tags'}
					showArrow={true}
					heightParentContains={0}
					notFoundContent={notFoundContent()}
					subtractedHeight={82}
					getPopupContainer={(triggerNode: HTMLElement) =>
						triggerNode.parentNode as HTMLElement
					}
					virtual={false}
					className="select_group_tags"
					optionFilterProp={'children'}
					style={{ margin: '0' }}
					{...otherPropSelectTag}
					dropdownClassName={'dropdown-menu dropdown-custom-content_detail'}>
					<OptGroup label={t('content_detail.labels')}>
						{optionLabels?.map((item) => {
							const value = JSON.stringify({
								val: item,
								group: FORM_CONTENT_DETAIL.LABELS,
							});
							return (
								<Option key={value} value={value}>
									{item}
								</Option>
							);
						})}
					</OptGroup>
				</StyledCustomSelect>
			</StyledFormItem>

			{!isSocialContent && (
				<>
					{googleAIHashtags?.map((item) => (
						<StyledTagAI key={Math.random()}>{item}</StyledTagAI>
					))}

					{videoLabelDetection &&
						detected &&
						currentMedia.mediaType === MEDIA_TYPE.VIDEO && (
							<VideoLabelDetection
								idElm={SELECTOR_ELEMENTS.VIDEO_CONTENT_DETAIL_ID}
								dataDetections={videoLabelDetection.map((item) => ({
									times: item.segments
										.map((seg) => seg.startTime)
										.sort(sortNumber),
									description: item.description,
								}))}
							/>
						)}
				</>
			)}
		</SwapperSessionDetail>
	);
};

export default DetailRateAndTag;
