import ContainerTemplate from '@components/Settings/ContentLicensing/DetailTemplate/ContainerTemplate';
import SidebarTemplate from '@components/Settings/ContentLicensing/DetailTemplate/SidebarTemplate';
import { PARAMS_DEFAULT } from '@constants/common';
import { DISPLAY_TYPES, KEY_LICENSING_TERMS, STATUS_TEMPLATE } from '@constants/licensingTemplate';
import { FAILED, IN_PROGRESS, SUCCEEDED } from '@constants/status';
import LicensingTemplateDetailContext from '@contexts/Settings/ContentLicensing/LicensingTemplateDetail';
import { LoadingWrapper, Wrapper } from '@cores/index';
import { WidgetBuilderTemplateDto } from '@models/content/widgetManager';
import { WidgetManagerStoreDto } from '@models/content/widgetManager/stores';
import {
	ParamRequestHistoryTemplateTypes,
	ParamRequestLiveWidgetTemplateTypes,
	TermsFieldValidateTypes,
	TermsTypes,
} from '@models/settings/contentLicensing';
import { RefContainerTemplate } from '@models/settings/contentLicensing/ref';
import {
	ContentLicensingStoreDto,
	TemplateHistoryTypes,
} from '@models/settings/contentLicensing/stores';
import {
	changeFieldDataContentLicensing,
	changeTemplateStatusEnd,
	fetchDetailTemplateRequest,
	fetchDetailWidgetDefaultRequest,
	fetchEntribeStandardTermsRequest,
	fetchListWidgetLiveRequest,
	fetchTemplateHistoryRequest,
	fetchWidgetInTemplateRequest,
	updateTemplateTermsEnd,
} from '@stores/actions';
import { UserRootStateDto } from '@stores/user/user.types';
import {
	StyledSiderTemplate,
	StyledWrapperTemplateDetail,
} from '@styled/LicensingTemplate/TemplateDetail';
import { Layout, message } from 'antd';
import { cloneDeep, isNaN } from 'lodash';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const { Sider, Header } = Layout;

const LicensingTemplateDetail = () => {
	const location = useLocation();
	const { pathname } = location;
	const dispatch = useDispatch();
	const {
		loadingDetail,
		templateDetail,
		loadingWidgetInTemplate,
		statusUpdateTemplate,
		loadingEntribeStandardTerms,
		dataWidgetLive,
		statusCreateTemplate,
		statusChangeTemplateToWidget,
		statusChangeTemplateStatus,
		templateHistory,
		templateInWidget,
		error,
	}: ContentLicensingStoreDto = useSelector((state: any) => state?.contentLicensing);
	const { loadingWidget, loadingDetailWidgetDefault }: WidgetManagerStoreDto = useSelector(
		(state: any) => state?.widgetManager,
	);
	const { fetchingUser } = useSelector((state: UserRootStateDto) => state.user);

	const refContainerTemplate = useRef<
		RefContainerTemplate | undefined
	>() as RefObject<RefContainerTemplate>;
	const { t } = useTranslation();

	const [templateNotFound, setTemplateNotFound] = useState<boolean>(true);
	const [listTerms, setListTerms] = useState<TermsTypes[]>([]);
	const [listOldTerms, setListOldTerms] = useState<TermsTypes[]>([]);
	const [idTermsSelected, setIdTermsSelected] = useState<number | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [paramWidgetLive, setParamWidgetLive] = useState<ParamRequestLiveWidgetTemplateTypes>({
		...PARAMS_DEFAULT,
		excludedTermsTemplateId: -1,
	});
	const [paramHistory, setParamHistory] = useState<ParamRequestHistoryTemplateTypes>({
		...PARAMS_DEFAULT,
		termsTemplateId: -1,
	});
	const [listWidgetLive, setListWidgetLive] = useState<WidgetBuilderTemplateDto[]>([]);
	const [historyList, setHistoryList] = useState<TemplateHistoryTypes[]>([]);
	const [typeConfirm, setTypeConfirm] = useState<string>('');
	const [hasChangeTerms, setHasChangeTerms] = useState<boolean>(false);
	const [isSaveName, setIsSaveName] = useState<boolean>(false);
	const [isDetectHeight, setIsDetectHeight] = useState<boolean>(false);
	const [showValidate, setShowValidate] = useState<boolean>(false);
	const [isCancel, setIsCancel] = useState(false);

	const statusTemplate = useMemo(() => {
		const { status } = templateDetail || {};
		return {
			isDraft: status === STATUS_TEMPLATE.DRAFT,
			isApproved: status === STATUS_TEMPLATE.APPROVED,
			isArchived: status === STATUS_TEMPLATE.ARCHIVED,
		};
	}, [templateDetail]);

	useEffect(() => {
		const id = pathname?.slice(pathname.lastIndexOf('/') + 1);
		if (pathname && id) {
			const idNum = +id;
			if (isNaN(idNum)) {
				setTemplateNotFound(true);
				message.error(t('message.template_not_found'));
			} else {
				setTemplateNotFound(false);
				dispatch(fetchDetailTemplateRequest(idNum));

				setParamHistory({ ...paramHistory, termsTemplateId: idNum, page: 1 });
				setParamWidgetLive({ ...paramWidgetLive, excludedTermsTemplateId: idNum });
			}
		}

		return () => {
			setTemplateNotFound(true);
		};
	}, [pathname]);

	useEffect(() => {
		dispatch(fetchEntribeStandardTermsRequest());
		dispatch(fetchDetailWidgetDefaultRequest());
		return () => {
			setIsSaveName(false);
			dispatch(
				changeFieldDataContentLicensing({
					templateDetail: null,
					dataWidgetLive: [],
					templateInWidget: [],
					templates: [],
					templateHistory: [],
				}),
			);
		};
	}, []);

	useEffect(() => {
		setListTerms(cloneDeep(templateDetail?.terms || []));
		setListOldTerms(templateDetail?.terms || []);
		setIsDetectHeight(true);
	}, [templateDetail?.terms]);

	useEffect(() => {
		setIsDetectHeight(true);
	}, [templateInWidget]);

	useEffect(() => {
		setIsDetectHeight(true);
	}, [hasChangeTerms]);

	useEffect(() => {
		if (statusTemplate.isApproved && templateDetail?.id) {
			dispatch(fetchWidgetInTemplateRequest(templateDetail.id));
		} else {
			dispatch(changeFieldDataContentLicensing({ templateInWidget: [] }));
		}
	}, [templateDetail?.id, templateDetail?.status]);

	useEffect(() => {
		setShowValidate(false);
		setHasChangeTerms(false);
	}, [templateDetail?.id]);

	useEffect(() => {
		if (paramWidgetLive?.page === 1) {
			setListWidgetLive(dataWidgetLive);
		} else {
			setListWidgetLive([...listWidgetLive, ...dataWidgetLive]);
		}
	}, [dataWidgetLive]);

	useEffect(() => {
		if (paramWidgetLive.excludedTermsTemplateId !== -1 && statusTemplate.isApproved) {
			if (paramWidgetLive?.page === 1) {
				setListWidgetLive([]);
			}
			dispatch(fetchListWidgetLiveRequest({ ...paramWidgetLive }));
		}
	}, [paramWidgetLive, templateDetail?.status, templateDetail?.id]);

	useEffect(() => {
		if (paramHistory?.page === 1) {
			setHistoryList(templateHistory);
		} else {
			setHistoryList([...historyList, ...templateHistory]);
		}
	}, [templateHistory]);

	useEffect(() => {
		if (paramHistory.termsTemplateId !== -1) {
			if (paramHistory?.page === 1) {
				setHistoryList([]);
			}
			dispatch(fetchTemplateHistoryRequest({ ...paramHistory }));
		}
	}, [paramHistory]);

	useEffect(() => {
		const listStatus = [
			statusUpdateTemplate,
			statusCreateTemplate,
			statusChangeTemplateToWidget,
			statusChangeTemplateStatus,
		];
		if (listStatus.includes(IN_PROGRESS)) {
			setIsLoading(true);
		}
		if (listStatus.includes(FAILED)) {
			message.error(error);
		}
		if (listStatus.includes(SUCCEEDED) || listStatus.includes(FAILED)) {
			setIsLoading(false);
		}
	}, [
		statusUpdateTemplate,
		statusCreateTemplate,
		statusChangeTemplateToWidget,
		statusChangeTemplateStatus,
	]);

	useEffect(() => {
		if (statusChangeTemplateStatus === SUCCEEDED) {
			message.success(t('message.change_status_success'));
			setHasChangeTerms(false);
			setShowValidate(false);
			setIsDetectHeight(true);
			setParamHistory({ ...paramHistory, page: 1 });
		}
		if (statusChangeTemplateStatus === SUCCEEDED || statusChangeTemplateStatus === FAILED) {
			dispatch(changeTemplateStatusEnd());
			setTypeConfirm('');
		}
	}, [statusChangeTemplateStatus]);

	useEffect(() => {
		if (statusUpdateTemplate === SUCCEEDED) {
			message.success(t('message.save_success'));
			if (!isSaveName) {
				setShowValidate(false);
				setHasChangeTerms(false);
			}
			setIsSaveName(false);
			setIsDetectHeight(true);
			setParamHistory({ ...paramHistory, page: 1 });
		}
		if (statusUpdateTemplate === SUCCEEDED || statusUpdateTemplate === FAILED) {
			dispatch(updateTemplateTermsEnd());
		}
	}, [statusUpdateTemplate]);

	const onSubmitFormTemplate = () => {
		if (refContainerTemplate && refContainerTemplate?.current?.onSubmit) {
			refContainerTemplate.current.onSubmit();
		}
	};

	const onCancelTemplate = () => {
		setHasChangeTerms(false);
		setListTerms(cloneDeep(listOldTerms));
		setIsCancel(true);
		setIsSaveName(false);
		setIsDetectHeight(true);
	};

	const removeKey = (terms: TermsTypes, listKey: string[]) => {
		listKey.forEach((key) => {
			if (Object.keys(terms).includes(key)) {
				delete (terms as any)[key];
			}
		});
	};

	const getListTerm = (data: TermsTypes[]) => {
		let errorRequired = false;
		const termsValidates: TermsFieldValidateTypes[] = [];
		const listFormat = data.map((terms: TermsTypes) => {
			const {
				displayType,
				agreementMessage,
				documentHeader,
				hyperlinkText,
				hyperlinkUrl,
				documentBody,
				textMustView,
				isMustView,
			} = terms;
			const termsCheckValidate: TermsFieldValidateTypes = { id: terms.id, fields: [] };
			if (displayType === DISPLAY_TYPES.POPUP) {
				removeKey(terms, [KEY_LICENSING_TERMS.HYPERLINK_URL]);
				const arrKeyValidate = [
					KEY_LICENSING_TERMS.AGREEMENT_MESSAGE,
					KEY_LICENSING_TERMS.DOCUMENT_HEADER,
					KEY_LICENSING_TERMS.HYPERLINK_TEXT,
					KEY_LICENSING_TERMS.DOCUMENT_BODY,
				];
				if (isMustView) {
					arrKeyValidate.push(KEY_LICENSING_TERMS.TEXT_MUST_VIEW);
				}
				arrKeyValidate.forEach((key) => {
					if (!terms[key]) {
						errorRequired = true;
						termsCheckValidate.fields.push(key);
					}
				});
			}

			if (displayType === DISPLAY_TYPES.IN_LINE) {
				removeKey(terms, [
					KEY_LICENSING_TERMS.HYPERLINK_URL,
					KEY_LICENSING_TERMS.HYPERLINK_TEXT,
					KEY_LICENSING_TERMS.DOCUMENT_HEADER,
					KEY_LICENSING_TERMS.DOCUMENT_BODY,
					KEY_LICENSING_TERMS.TEXT_MUST_VIEW,
				]);
				if (!agreementMessage) {
					termsCheckValidate.fields.push(KEY_LICENSING_TERMS.AGREEMENT_MESSAGE);
					errorRequired = true;
				}
			}
			if (displayType === DISPLAY_TYPES.REDIRECT) {
				removeKey(terms, [
					KEY_LICENSING_TERMS.DOCUMENT_HEADER,
					KEY_LICENSING_TERMS.DOCUMENT_BODY,
					KEY_LICENSING_TERMS.TEXT_MUST_VIEW,
				]);
				const arrKeyValidate = [
					KEY_LICENSING_TERMS.AGREEMENT_MESSAGE,
					KEY_LICENSING_TERMS.HYPERLINK_URL,
					KEY_LICENSING_TERMS.HYPERLINK_TEXT,
				];
				arrKeyValidate.forEach((key) => {
					if (!terms[key]) {
						errorRequired = true;
						termsCheckValidate.fields.push(key);
					}
				});
			}
			termsValidates.push(termsCheckValidate);
			return terms;
		});
		return { data: listFormat, errorRequired, termsValidates };
	};

	const getFormatListTerms = () => {
		const data = cloneDeep(listTerms);
		return getListTerm(data);
	};

	const handleCheckValidateField = (id: number, key: string, isCheckList?: boolean) => {
		const dataFormat = getFormatListTerms();
		const { termsValidates } = dataFormat;
		const termsFind = termsValidates.find((item) => item?.id === id);
		if (!showValidate) {
			return false;
		}

		if (termsFind) {
			if (isCheckList) {
				return termsFind?.fields?.length > 0;
			}
			return termsFind?.fields.includes(key);
		}
		return false;
	};

	const handleChangeFieldTerms = (id: number, field: any, noChange?: boolean) => {
		if (!noChange) {
			setHasChangeTerms(true);
		}
		if (Object.keys(field).includes(KEY_LICENSING_TERMS.DOCUMENT_BODY)) {
			listTerms.map((item) => {
				if (item?.id === id) {
					item.documentBody = field?.documentBody;
					if (!noChange) {
						item.isEntribeStandardTerms = false;
					}
				}
			});
			return;
		}

		const newList = listTerms.map((terms) => {
			if (terms.id === id) {
				return { ...terms, ...field, isEntribeStandardTerms: false };
			}
			return terms;
		});

		setListTerms(newList);
	};

	const handleRemoveTerms = (id: number) => {
		setIsDetectHeight(true);
		setListTerms(listTerms.filter((terms) => terms.id !== id));
	};

	const handleAddTerms = (terms: TermsTypes) => {
		setIsDetectHeight(true);
		setListTerms([...listTerms, terms]);
	};

	const termsSelected = useMemo(() => {
		if (listTerms.length === 0 || !idTermsSelected) {
			return null;
		}
		return listTerms.find((terms) => terms.id === idTermsSelected) || null;
	}, [listTerms, idTermsSelected]);

	const valueContext = {
		onSubmitFormTemplate,
		listTerms,
		setListTerms,
		handleChangeFieldTerms,
		handleRemoveTerms,
		handleAddTerms,
		setIdTermsSelected,
		termsSelected,
		getFormatListTerms,
		hasChangeTerms,
		setHasChangeTerms,
		setParamWidgetLive,
		paramWidgetLive,
		listWidgetLive,
		setListWidgetLive,
		statusTemplate,
		setTypeConfirm,
		typeConfirm,
		setIsLoading,
		isLoading,
		historyList,
		paramHistory,
		setParamHistory,
		onCancelTemplate,
		setIsSaveName,
		setIsDetectHeight,
		isDetectHeight,
		showValidate,
		setShowValidate,
		handleCheckValidateField,
		setIsCancel,
		isCancel,
	};

	const loading =
		loadingDetail ||
		loadingWidgetInTemplate ||
		loadingEntribeStandardTerms ||
		loadingWidget ||
		loadingDetailWidgetDefault ||
		isLoading;

	return (
		<LicensingTemplateDetailContext.Provider value={valueContext}>
			<StyledWrapperTemplateDetail>
				<LoadingWrapper isLoading={fetchingUser ? false : loading}>
					<Layout>
						{!templateNotFound && (
							<>
								<StyledSiderTemplate
									width={'380px'}
									collapsedWidth="0"
									trigger={null}
									style={{ margin: '0 16px 0 0' }}
									collapsible
									collapsed={false}>
									<SidebarTemplate />
								</StyledSiderTemplate>
								<Wrapper
									style={{
										height: 'calc(100% - 130px)',
										paddingBottom: '12px',
										width: 'calc(100% - 396px)',
									}}>
									<ContainerTemplate ref={refContainerTemplate} />
								</Wrapper>
							</>
						)}
					</Layout>
				</LoadingWrapper>
			</StyledWrapperTemplateDetail>
		</LicensingTemplateDetailContext.Provider>
	);
};

export default LicensingTemplateDetail;
