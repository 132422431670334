import styled from 'styled-components';
import THEMES from '@constants/themes/themes';
import { Image } from 'antd';
import { PLACEHOLDER_IMAGE_IN_BASE64 } from '@constants/common';

const minHeight = 126;

const StyledMediaItem = styled.div<{ height?: string; isSelecting?: boolean }>`
	height: ${({ height }) => height};
	touch-action: none;
	overflow: hidden;
`;

const StyledMediaItemContainer = styled.div<{ isPending?: boolean }>`
	position: relative;
	height: 100%;

	.overlay {
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${THEMES.colors.gray1};
		opacity: 0.8;
	}

	.empty-favorite-icon {
		opacity: 0;
		transition: opacity 0.2s;
	}

	&:hover {
		.empty-favorite-icon {
			opacity: 1;
		}
	}
`;

const StyledMarkExplicit = styled.div<{ zIndex?: number }>`
	position: absolute;
	z-index: ${({ zIndex }) => zIndex || 1};
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(1, 22, 39, 0.8);
	backdrop-filter: blur(40px);
	display: flex;
	align-items: center;
	padding: 16px;
	justify-content: center;
`;

const StyledImage = styled(Image)<{ rotate?: number; scale?: number; visibleImage?: boolean }>`
	position: relative;
	object-fit: cover;
	border-radius: 2px;
	cursor: pointer;
	transform: rotate(${({ rotate }) => rotate || 0}deg) scale(${({ scale }) => scale || 1});
	background: ${({ visibleImage }) =>
		visibleImage
			? `url('${PLACEHOLDER_IMAGE_IN_BASE64}')
		no-repeat scroll 0 0`
			: 'none !important'};
	background-size: cover;
	width: 100%;
	height: 100%;
	&.placeholder_bg_img {
		background: ${({}) =>
			`url('${PLACEHOLDER_IMAGE_IN_BASE64}') no-repeat scroll 0 0`} !important;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
`;

const StyledIconPlay = styled.div<{ height?: any }>`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 9;
	height: ${({ height }) => (height > minHeight ? '30px' : '22px')};
	width: ${({ height }) => (height > minHeight ? '30px' : '22px')};
	background-color: ${THEMES.colors.darkBlue2};
	border-radius: 50%;
	line-height: ${({ height }) => (height > minHeight ? '30px' : '22px')};
	cursor: pointer;
	transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
	&.center_child {
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	svg {
		fill: ${THEMES.colors.gray5};
		width: ${({ height }) => (height > minHeight ? '16px' : '12px')} !important;
	}
`;

const StyledMarkAs = styled.div<{ height?: any }>`
	position: absolute;
	bottom: ${({ height }) => (height > minHeight ? '5px' : '2px')};
	right: ${({ height }) => (height > minHeight ? '5px' : '2px')};
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap-reverse;
	max-width: calc(100% - 38px);
	margin-left: 6px;
`;

const StyledFavoriteContent = styled.div<{ height?: any }>`
	position: absolute;
	bottom: 5px;
	left: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap-reverse;
	z-index: 9;
`;

const StyledTextIcon = styled.p`
	margin: 0;
	padding: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledMarkAsItem = styled.div<{ height?: any }>`
	display: flex;
	align-items: center;
	height: ${({ height }) => (height > minHeight ? '22px' : '16px')};
	padding: ${({ height }) => (height > minHeight ? '0 6px' : '0 4px')};
	border-radius: 2px;
	max-width: 100%;
	background-color: #011627;
	font-size: ${({ height }) => (height > minHeight ? '0.785rem' : '0.65rem')};
	color: #e0e3e5;
	z-index: 9;
	width: fit-content;
	margin-top: 2px;
	transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
	text-transform: capitalize;
	cursor: pointer;

	svg {
		margin-left: 4px;
		width: 14px;
		fill: ${THEMES.colors.greenBase} !important;

		&.published-svg {
			filter: invert(43%) sepia(96%) saturate(2656%) hue-rotate(192deg) brightness(103%)
				contrast(101%);
		}
	}
	&.recently-added-svg {
		&:hover {
			svg {
				path {
					fill: #e2582b !important;
					d: path(
						'M11.9999 21.5999C17.3019 21.5999 21.5999 17.3019 21.5999 11.9999C21.5999 6.69797 17.3019 2.3999 11.9999 2.3999C6.69797 2.3999 2.3999 6.69797 2.3999 11.9999C2.3999 17.3019 6.69797 21.5999 11.9999 21.5999ZM10.4484 8.75137C9.9798 8.28275 9.22001 8.28275 8.75137 8.75137C8.28275 9.22001 8.28275 9.9798 8.75137 10.4484L10.3028 11.9999L8.75137 13.5514C8.28275 14.02 8.28275 14.7798 8.75137 15.2484C9.22001 15.717 9.9798 15.717 10.4484 15.2484L11.9999 13.6969L13.5514 15.2484C14.02 15.717 14.7798 15.717 15.2484 15.2484C15.717 14.7798 15.717 14.02 15.2484 13.5514L13.6969 11.9999L15.2484 10.4484C15.717 9.9798 15.717 9.22001 15.2484 8.75137C14.7798 8.28275 14.02 8.28275 13.5514 8.75137L11.9999 10.3028L10.4484 8.75137Z'
					) !important;
				}
			}
		}
	}
`;

export {
	StyledMediaItem,
	StyledMediaItemContainer,
	StyledImage,
	StyledIconPlay,
	StyledMarkAs,
	StyledMarkAsItem,
	StyledFavoriteContent,
	StyledMarkExplicit,
	StyledTextIcon,
};
