import { Input } from 'antd';

const { Search } = Input;

type Props = {
  defaultValue?: string;
  borderColor?: string;
  placeholder?: string;
  width?: number;
  onChange?: (event: any) => void;
  onClickSearch: () => void;
};

const InputSearch: React.FC<Props> = ({ onClickSearch, ...rest }) => {
  return <Search {...rest} placeholder="input search text" onSearch={onClickSearch} />;
};

InputSearch.defaultProps = {
  onChange: () => {},
  onClickSearch: () => {},
};

export default InputSearch;
