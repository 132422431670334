import { IconKeyboardArrowRight, IconQuestionCircle } from '@assets/icons';
import { ROUTE_PATH } from '@constants/common';
import { ALL_COUNTRY, MARKETING_CHANNELS } from '@constants/content/widgetManager';
import { DISPLAY_TYPES, KEY_LICENSING_TERMS, RULES_TERMS } from '@constants/licensingTemplate';
import { ROLE } from '@constants/settings';
import THEME from '@constants/themes/themes';
import EditName from '@cores/EditName';
import { getUser } from '@helpers/userHelpers';
import { SelectOptionDto } from '@models/common/summary';
import { OptionTypeItem } from '@models/filter';
import {
	FormatDataTermsTypes,
	ParamUpdateTemplateTypes,
	TermsTypes,
} from '@models/settings/contentLicensing';
import { RefContainerTemplate } from '@models/settings/contentLicensing/ref';
import { updateTemplateTermsRequest } from '@stores/actions';
import {
	StyledCheckbox,
	StyledCheckboxGroup,
	StyledDivider,
	StyledFormItem,
	StyledIcon,
	StyledInput,
	StyledLinkHref,
	StyledRadioGroup,
	StyledTextArea,
	StyledTitle,
	StyledWrapperTitle,
} from '@styled/Common/CommonStyled';
import { StyledSelect } from '@styled/Content/ContentLibrary/FilterStyled';
import { StyledInputCustomField } from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { StyledCollapse, StyledCollapseInfo } from '@styled/ContentLibrary/ContentDetailStyled';
import {
	StyledBodyLicensingTerms,
	StyledWrapperContentLicensingTerms,
} from '@styled/LicensingTemplate/TemplateDetail';
import {
	notFoundContent,
	renderListDisplayContentLicensing,
	renderListRulesContentLicensing,
} from '@utils/renderComponent';
import { Col, Form, Radio, Row, Space, Tooltip } from 'antd';
import { countries } from 'countries-list';
import React, {
	Ref,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorValidateIcon from '../../SidebarTemplate/ErrorValidateIcon';
import EditorDocumentBody from './EditorDocumentBody';

const { Panel } = StyledCollapse;

type PropTypes = {
	disabledEdit?: boolean;
	terms: TermsTypes;
	handleChangeFieldTerms?: (id: number, field: any, noChange?: boolean) => void;
	getFormatListTerms?: () => FormatDataTermsTypes;
	templateId?: number;
	space?: number;
	moreSpace?: number;
	showTooltipRedirect?: boolean;
	className?: string;
	wrapperHeight?: string;
	handleCheckValidateField?: (id: number, key: string) => boolean;
	isCancel?: boolean;
	setIsCancel?: (val: boolean) => void;
};

const ContentLicensingTerms = forwardRef((props: PropTypes, ref: Ref<RefContainerTemplate>) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = getUser();
	const history = useHistory();
	const disabled = user.role !== ROLE.EREVIEWER.value;

	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayType, setDisplayType] = useState<string>(DISPLAY_TYPES.POPUP);
	const [errorBody, setErrorBody] = useState<boolean>(false);
	const refUpdate = useRef<any>(false);
	const [documentChange, setDocumentChange] = useState<string>('');
	const [showButtonRead, setShowButtonRead] = useState<boolean>(false);
	const [termsId, setTermsId] = useState<number | null>(null);

	const {
		disabledEdit = false,
		terms,
		handleChangeFieldTerms,
		getFormatListTerms,
		templateId,
		space = 0,
		moreSpace = 0,
		showTooltipRedirect = false,
		className = '',
		wrapperHeight = '100vh',
		handleCheckValidateField,
		isCancel,
		setIsCancel,
	} = props;
	const { documentBody } = terms;

	const listDisplay = renderListDisplayContentLicensing(t);
	const listRules = renderListRulesContentLicensing(t);

	useImperativeHandle(ref, () => ({
		onSubmit: () => {
			// if (!errorBody && !documentBody && displayType === DISPLAY_TYPES.POPUP) {
			// 	setErrorBody(true);
			// }

			form.submit();
		},
	}));

	useEffect(() => {
		const objForm: any = {
			[KEY_LICENSING_TERMS.AGREEMENT_MESSAGE]: terms.agreementMessage,
			[KEY_LICENSING_TERMS.HYPERLINK_TEXT]: terms.hyperlinkText,
			[KEY_LICENSING_TERMS.HYPERLINK_URL]: terms.hyperlinkUrl,
			[KEY_LICENSING_TERMS.DOCUMENT_HEADER]: terms.documentHeader,
			[KEY_LICENSING_TERMS.DISPLAY_TYPE]: terms.displayType,
			[KEY_LICENSING_TERMS.RULES]: [],
			[KEY_LICENSING_TERMS.APPROVED_MARKETING_CHANNELS]:
				terms.tags?.approvedMarketingChannels || [],

			[KEY_LICENSING_TERMS.APPROVED_COUNTRIES]: terms.tags?.approvedCountries || [],
			[KEY_LICENSING_TERMS.LICENSING_PERIOD]: terms.tags?.licensingPeriod,
		};
		let readViewBtn = false;
		if (terms.isMustView) {
			objForm[KEY_LICENSING_TERMS.RULES] = [
				...objForm[KEY_LICENSING_TERMS.RULES],
				RULES_TERMS.READ,
			];
			if (terms.displayType === DISPLAY_TYPES.POPUP) {
				readViewBtn = true;
				objForm[KEY_LICENSING_TERMS.TEXT_MUST_VIEW] = terms.textMustView;
			}
		}
		if (terms.isTermsMandated) {
			objForm[KEY_LICENSING_TERMS.RULES] = [
				...objForm[KEY_LICENSING_TERMS.RULES],
				RULES_TERMS.AGREE,
			];
		}
		setShowButtonRead(readViewBtn);
		setDisplayType(terms.displayType);
		form.setFieldsValue(objForm);
		setDocumentChange(documentBody || '');
		setTermsId(terms.id);
		refUpdate.current = true;
	}, [terms]);

	useEffect(() => {
		if (isCancel) {
			setTimeout(() => {
				setDocumentChange(terms.documentBody || '');

				if (setIsCancel && typeof setIsCancel === 'function') {
					setIsCancel(false);
				}
			}, 0);
		}
	}, [isCancel]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setErrorBody(false);
	// 	}, 0);
	// }, [terms.id]);

	const handleCheckValidate = (key: string) => {
		if (handleCheckValidateField && typeof handleCheckValidateField === 'function') {
			return handleCheckValidateField(terms.id, key);
		}
		return false;
	};
	const handleUpdateFieldTerms = (field: any, noChange?: boolean) => {
		if (handleChangeFieldTerms && typeof handleChangeFieldTerms === 'function') {
			handleChangeFieldTerms(terms.id, field, noChange);
		}
	};

	const onValuesChange = (val: any, valMore: any) => {
		const keyName = Object.keys(val)[0];

		const values = val[keyName];
		if (keyName === KEY_LICENSING_TERMS.DISPLAY_TYPE) {
			setDisplayType(values);
			setShowButtonRead(
				values === DISPLAY_TYPES.POPUP &&
					valMore[KEY_LICENSING_TERMS.RULES].includes(RULES_TERMS.READ),
			);
			// if (values !== DISPLAY_TYPES.POPUP) {
			// 	setErrorBody(false);
			// }
		}
		if (keyName === KEY_LICENSING_TERMS.RULES) {
			const obj = {
				[KEY_LICENSING_TERMS.IS_TERMS_MANDATED]: values.includes(RULES_TERMS.AGREE),
				[KEY_LICENSING_TERMS.IS_MUST_VIEW]: values.includes(RULES_TERMS.READ),
			};
			setShowButtonRead(
				obj[KEY_LICENSING_TERMS.IS_MUST_VIEW] && displayType === DISPLAY_TYPES.POPUP,
			);
			handleUpdateFieldTerms({ ...obj });

			return;
		}
		const arrKeyTag = [
			KEY_LICENSING_TERMS.LICENSING_PERIOD,
			KEY_LICENSING_TERMS.APPROVED_COUNTRIES,
			KEY_LICENSING_TERMS.APPROVED_MARKETING_CHANNELS,
		];
		if (arrKeyTag.includes(keyName)) {
			const newObj: any = {
				[KEY_LICENSING_TERMS.LICENSING_PERIOD]: terms.tags?.licensingPeriod || null,
				[KEY_LICENSING_TERMS.APPROVED_COUNTRIES]: terms.tags?.approvedCountries || null,
				[KEY_LICENSING_TERMS.APPROVED_MARKETING_CHANNELS]:
					terms.tags?.approvedMarketingChannels || null,
			};
			newObj[keyName] = values;
			handleUpdateFieldTerms({
				tags: {
					...newObj,
				},
			});
			return;
		}
		handleUpdateFieldTerms({ [keyName]: values });
	};

	const getFormatData = () => {
		if (getFormatListTerms && typeof getFormatListTerms === 'function') {
			return getFormatListTerms();
		}
		return { data: [], errorRequired: false };
	};
	const handleFinishForm = (values: any) => {
		const formatData = getFormatData();

		// if (displayType === DISPLAY_TYPES.POPUP && (errorBody || !documentBody)) {
		// 	return;
		// }

		// if (formatData.errorRequired) {
		// 	message.error(t('message.required_some_field'));
		// 	return;
		// }
		const dataListTerms = formatData.data.map((item) => {
			const { isAdd, id, ...other } = item;

			if (isAdd) {
				return other;
			}
			return item;
		});
		const dataRequest: ParamUpdateTemplateTypes = {
			accountEmail: user?.email,
			termsTemplateId: templateId || 0,
			body: {
				terms: dataListTerms,
			},
		};
		dispatch(updateTemplateTermsRequest(dataRequest));
	};

	const renderInputField = (placeholder: string) => {
		return <StyledInput className="field_input_transparent" placeholder={placeholder} />;
	};

	const renderTextAreaField = (placeholder: string) => {
		return (
			<StyledTextArea
				className="textarea_content_licensing"
				placeholder={placeholder}
				rows={3}
				disabled={disabledEdit}
			/>
		);
	};

	const renderInput = (name: string, placeholder: string) => {
		return (
			<StyledInputCustomField
				placeholder={placeholder}
				value={form.getFieldValue(name)}
				id={name}
				type="number"
				disabled={disabled}
				className="input_hidden_box"
			/>
		);
	};

	const renderSelect = (name: string, options: SelectOptionDto[], placeholder: string) => {
		return (
			<StyledSelect
				mode={'multiple'}
				options={options}
				showSearch
				virtual={false}
				allowClear
				optionFilterProp={'label'}
				notFoundContent={notFoundContent(t)}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode.parentNode as HTMLElement
				}
				disabled={disabled}
				value={form.getFieldValue(name)}
				placeholder={placeholder}
				showArrow
				className="select_group_tags select_detail_terms"
				dropdownClassName="dropdown-menu dropdown-custom-content"
			/>
		);
	};

	const renderDisplayType = () => {
		return (
			<StyledRadioGroup value={displayType} fontSize={'12px'} className="radio_light">
				<Space style={{ flexWrap: 'wrap' }} direction="horizontal">
					{listDisplay?.map((item) => (
						<Radio key={item?.val} value={item?.val}>
							{item?.title}
						</Radio>
					))}
				</Space>
			</StyledRadioGroup>
		);
	};

	const renderGroupCheckbox = (options: OptionTypeItem[], valDisabled: string) => {
		return (
			<StyledCheckboxGroup>
				<Space direction="vertical">
					{options.map((checkbox: OptionTypeItem) => (
						<StyledCheckbox
							disabled={
								checkbox.value === valDisabled &&
								displayType !== DISPLAY_TYPES.POPUP
							}
							value={checkbox.value}
							key={checkbox.value}
							className="checkbox_default checkbox_custom">
							{checkbox.name}
						</StyledCheckbox>
					))}
				</Space>
			</StyledCheckboxGroup>
		);
	};

	const listCountry = useMemo(() => {
		const country: any = countries;
		const listKey: string[] = Object.keys(countries);
		const result = listKey.map((key) => {
			return { value: key, label: country[key].name };
		});
		return [
			{ value: ALL_COUNTRY, label: t('content.widget_manager.widget_builder.global') },
			...result,
		];
	}, [countries]);

	const listFormText = [
		{
			id: 1,
			title: (
				<>
					{t('content_licensing.agreement_message')}{' '}
					{handleCheckValidate(KEY_LICENSING_TERMS.AGREEMENT_MESSAGE) && (
						<ErrorValidateIcon />
					)}
				</>
			),
			content:
				displayType === DISPLAY_TYPES.IN_LINE
					? renderTextAreaField(t('content_licensing.pl_agreement'))
					: renderInputField(t('content_licensing.pl_agreement')),
			key: KEY_LICENSING_TERMS.AGREEMENT_MESSAGE,
		},
		{
			id: 2,
			title: (
				<>
					{t('content.widget_manager.widget_builder.hyperlink_text')}{' '}
					{handleCheckValidate(KEY_LICENSING_TERMS.HYPERLINK_TEXT) && (
						<ErrorValidateIcon />
					)}
				</>
			),
			content: renderInputField(t('content_licensing.pl_terms')),
			key: KEY_LICENSING_TERMS.HYPERLINK_TEXT,
			hidden: displayType === DISPLAY_TYPES.IN_LINE,
		},
		{
			id: 3,
			title: (
				<>
					{t('content.widget_manager.widget_builder.hyperlink_url')}{' '}
					{handleCheckValidate(KEY_LICENSING_TERMS.HYPERLINK_URL) && (
						<ErrorValidateIcon />
					)}
				</>
			),
			content: renderInputField(t('content.widget_manager.widget_builder.placeholder_link')),
			key: KEY_LICENSING_TERMS.HYPERLINK_URL,
			hidden: displayType !== DISPLAY_TYPES.REDIRECT,
		},
		{
			id: 4,
			title: (
				<>
					{t('content_licensing.document_header')}{' '}
					{handleCheckValidate(KEY_LICENSING_TERMS.DOCUMENT_HEADER) && (
						<ErrorValidateIcon />
					)}
				</>
			),
			content: renderInputField(t('content_licensing.pl_terms')),
			key: KEY_LICENSING_TERMS.DOCUMENT_HEADER,
			hidden: displayType !== DISPLAY_TYPES.POPUP,
		},
	];

	const listFormRight = [
		{
			id: 1,
			title: t('content_licensing.display_type'),
			content: renderDisplayType(),
			key: KEY_LICENSING_TERMS.DISPLAY_TYPE,
		},
		{
			id: 2,
			title: t('content_licensing.rules'),
			content: renderGroupCheckbox(listRules, RULES_TERMS.READ),
			key: KEY_LICENSING_TERMS.RULES,
		},
		{
			id: 3,
			title: (
				<>
					{t('content.widget_manager.widget_builder.button_text_inside_popup')}{' '}
					{handleCheckValidate(KEY_LICENSING_TERMS.TEXT_MUST_VIEW) && (
						<ErrorValidateIcon />
					)}
				</>
			),
			content: renderInputField(t('content_licensing.placeholder.button_agree')),
			key: KEY_LICENSING_TERMS.TEXT_MUST_VIEW,
			hidden: !showButtonRead,
		},
	];

	const arrTags = [
		{
			id: 1,
			content: renderSelect(
				KEY_LICENSING_TERMS.APPROVED_MARKETING_CHANNELS,
				MARKETING_CHANNELS.map((mar) => ({ value: mar, label: mar })),
				t('content.widget_manager.widget_builder.marketing_channels'),
			),
			title: t('content.widget_manager.widget_builder.approved_marketing'),
			tooltipTitle: t('content.widget_manager.widget_builder.sub_marketing'),
			name: KEY_LICENSING_TERMS.APPROVED_MARKETING_CHANNELS,
		},
		{
			id: 2,
			content: renderSelect(
				KEY_LICENSING_TERMS.APPROVED_COUNTRIES,
				listCountry,
				t('content.widget_manager.widget_builder.global'),
			),
			title: t('content.widget_manager.widget_builder.approved_countries'),
			tooltipTitle: t('content.widget_manager.widget_builder.sub_country'),
			name: KEY_LICENSING_TERMS.APPROVED_COUNTRIES,
		},
		{
			id: 3,
			content: renderInput(
				KEY_LICENSING_TERMS.LICENSING_PERIOD,
				t('content.widget_manager.widget_builder.in_perpetuity'),
			),
			title: t('content.widget_manager.widget_builder.licensing_period'),
			tooltipTitle: t('content.widget_manager.widget_builder.sub_licensing'),
			rules: [
				{
					validator: (_: any, value: any) => {
						const valNum = +value;

						if (valNum < 0) {
							form.setFieldsValue({
								[KEY_LICENSING_TERMS.LICENSING_PERIOD]: -valNum.toString(),
							});
						}
						return Promise.resolve();
					},
				},
			],
			name: KEY_LICENSING_TERMS.LICENSING_PERIOD,
		},
	];

	const renderHeaderPanel = () => {
		return (
			<StyledCollapseInfo>
				<StyledTitle className="unset_height">
					{t('content_licensing.tags_optional')}
				</StyledTitle>
				<StyledIcon className="icon_arrow">
					<IconKeyboardArrowRight />
				</StyledIcon>
			</StyledCollapseInfo>
		);
	};

	return (
		<StyledWrapperContentLicensingTerms className={className} space={space}>
			<StyledWrapperTitle height="70px" padding="20px 22px">
				<EditName
					hidePrefix
					hiddenOverflow
					style={{ fontSize: '20px' }}
					name={terms.name}
					handleSave={(val: string) => {
						handleUpdateFieldTerms({ name: val });
					}}
					width="calc(100% - 270px)"
					sizeIcon={24}
					disabled={disabledEdit}
					borderColor={THEME.colors.orangeBase}
				/>
				{showTooltipRedirect && (
					<Tooltip
						trigger={['click']}
						placement="bottomLeft"
						title={
							<StyledTitle fontSize="14px" className="unset_height">
								<Trans
									t={t}
									components={{
										elm: (
											<StyledLinkHref
												fontWeight="normal"
												underline
												href={''}
												color="inherit"
												onClick={(e: React.MouseEvent<HTMLElement>) => {
													e.preventDefault();
													e.stopPropagation();
													history.push(
														`${ROUTE_PATH.CONTENT_LICENSING}/${templateId}`,
														{ termsIdRedirect: terms.id },
													);
												}}
											/>
										),
									}}
									i18nKey="content_licensing.content_licensing_redirect"
								/>
							</StyledTitle>
						}>
						<StyledTitle
							margin="0 46px 0 0"
							textDecoration={'underline'}
							fontSize="14px"
							lineHeight="20px"
							className="unset_height text_action">
							{t('content_licensing.quest_edit')}
						</StyledTitle>
					</Tooltip>
				)}
			</StyledWrapperTitle>
			<StyledDivider margin=" 0" />
			<StyledBodyLicensingTerms
				wrapperHeight={wrapperHeight}
				space={space}
				moreSpace={moreSpace}
				className="custom_scroll_bar">
				<Form
					style={disabledEdit ? { pointerEvents: 'none' } : {}}
					form={form}
					initialValues={{}}
					onValuesChange={onValuesChange}
					wrapperCol={{ span: 24 }}
					labelCol={{ span: 24 }}
					onFinish={handleFinishForm}>
					<Row gutter={[16, 0]}>
						<Col span={16}>
							{listFormText.map((item) => {
								if (item?.hidden) {
									return null;
								}
								return (
									<StyledFormItem
										labelHeight={'20px'}
										name={item?.key}
										key={item.id}
										label={item.title}>
										{item.content}
									</StyledFormItem>
								);
							})}
						</Col>
						<Col span={8}>
							<StyledTitle
								margin="0 0 16px 0"
								fontSize="14px"
								lineHeight="20px"
								className="unset_height">
								{t('content_licensing.display_rules')}
							</StyledTitle>
							{listFormRight.map((item) => {
								if (item?.hidden) {
									return null;
								}
								return (
									<StyledFormItem
										name={item.key}
										key={item.id}
										labelHeight={'16px'}
										fontSizeLabel={'12px'}
										color={THEME.colors.gray3}
										label={item.title}>
										{item.content}
									</StyledFormItem>
								);
							})}
						</Col>
						{displayType === DISPLAY_TYPES.POPUP && (
							<>
								<StyledTitle
									margin="0 0 8px 0"
									fontSize="14px"
									lineHeight="20px"
									display="inline-flex"
									className="unset_height">
									{t('content_licensing.document_body')}{' '}
									{handleCheckValidate(KEY_LICENSING_TERMS.DOCUMENT_BODY) && (
										<ErrorValidateIcon />
									)}
								</StyledTitle>

								<>
									{termsId === terms.id && (
										<EditorDocumentBody
											disabledEdit={disabledEdit}
											documentChange={documentChange || ''}
											errorBody={errorBody}
											onEdit={(val: string, noChange?: boolean) => {
												setDocumentChange(val);

												handleUpdateFieldTerms(
													{
														[KEY_LICENSING_TERMS.DOCUMENT_BODY]: val,
													},
													noChange,
												);
											}}
											refUpdate={refUpdate}
										/>
									)}
									{/* {errorBody && (
										<StyledTitle
											margin="4px 0 0 0"
											fontSize="14px"
											lineHeight="20px"
											className="unset_height"
											color={THEME.colors.redBase}>
											{t('validate.required')}
										</StyledTitle>
									)} */}
								</>
							</>
						)}

						<StyledCollapse
							className="collapse_content_detail"
							expandIconPosition="right"
							style={{
								margin: '24px 0 0 0',
								width: '100%',
								background: THEME.colors.transparent,
								pointerEvents: 'unset',
							}}
							defaultActiveKey={'1'}>
							<Panel header={renderHeaderPanel()} key={'1'}>
								<Row
									style={{ pointerEvents: disabledEdit ? 'none' : 'unset' }}
									gutter={[12, 12]}>
									{arrTags.map((item) => (
										<Col span={8} key={item.id}>
											<StyledFormItem
												name={item.name}
												label={
													<StyledWrapperTitle
														fontSize="14px"
														lineHeight="20px"
														justifyContent="left">
														{item?.title}
														<Tooltip title={item?.tooltipTitle}>
															<StyledIcon
																fillPath={THEME.colors.gray1}
																size={16}>
																<IconQuestionCircle />
															</StyledIcon>
														</Tooltip>
													</StyledWrapperTitle>
												}
												rules={item?.rules || []}>
												{item.content}
											</StyledFormItem>
										</Col>
									))}
								</Row>
							</Panel>
						</StyledCollapse>
					</Row>
				</Form>
			</StyledBodyLicensingTerms>
		</StyledWrapperContentLicensingTerms>
	);
});

export default ContentLicensingTerms;
