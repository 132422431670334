import THEME from '@constants/themes/themes';
import { Button, Collapse } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

const StyledOverview = styled.div`
	padding-top: 16px;
	pointer-events: all;
`;

const StyledCollapse = styled(Collapse)`
	&.collapse_content_detail {
		margin-bottom: 16px;
		.ant-collapse-arrow {
			display: none !important;
		}
		& > .ant-collapse-item {
			background: transparent;
			padding: 0;
			.icon_arrow {
				transition: 0.3s;
				transform: rotate(90deg);
			}
			&.ant-collapse-item-active {
				.icon_arrow {
					transform: rotate(0deg);
				}
			}
		}

		&_flagged {
			margin-bottom: 44px;
		}
	}
	&.collapse_filter {
		.ant-collapse-header {
			margin-bottom: 12px;
			&:hover {
				.title_hover {
					color: ${THEME.colors.orangeBase};
				}
				.icon_arrow {
					svg {
						fill: ${THEME.colors.orangeBase} !important;
					}
				}
			}
		}
	}
	&.collapse_terms_detail {
		margin-bottom: 16px;
		background-color: ${THEME.colors.transparent};

		&:not(.has_arrow) {
			.ant-collapse-arrow {
				display: none !important;
			}
		}
		& > .ant-collapse-item {
			background: transparent;
			padding: 0;
			.icon_arrow {
				transition: 0.3s;
				transform: rotate(90deg);
			}
			&.ant-collapse-item-active {
				.icon_arrow {
					transform: rotate(0deg);
				}
			}
		}
	}
	border: 0;
	& > .ant-collapse-item {
		&:not(:first-child) {
			margin-top: 24px;
		}
		padding: 20px;
		background: ${THEME.colors.darkBlue3};
		border-radius: 16px;
		border: 0;
		.ant-collapse-content {
			border: 0;
			background: transparent;
			.ant-collapse-content-box {
				padding: 0;
			}
		}
		.ant-collapse-header {
			padding: 0;
			.anticon {
				svg {
					transform: rotate(-90deg);
				}
			}
		}
	}
`;

const StyledWrapperTitle = styled.div`
	display: flex;
	align-items: center;

	&.no_action {
		cursor: text;
	}
`;

const StyledName = styled.div`
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	color: ${THEME.colors.gray5};
	margin: 0 8px;
`;

const StyledPointScore = styled.div`
	width: 29px;
	height: 29px;
	margin-left: 3.5px;
	border-radius: 50px;
	font-weight: 700;
	font-size: 16px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: ${THEME.colors.darkBlue1};
	background-color: ${THEME.colors.gray5};

	&.yellow {
		background-color: ${THEME.colors.yellowBase};
	}
	&.red {
		background-color: ${THEME.colors.redBase};
	}
	&.green {
		background-color: ${THEME.colors.greenBase};
	}

	.ant-spin {
		&.ant-spin-spinning {
			line-height: 0;
		}
	}
`;

const SwapperSessionDetail = styled.div`
	padding: 16px 0;
	border-top: 1px solid ${THEME.colors.darkBlue3};
`;

const StyledLabel = styled.div`
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray1};
`;

const StyledTagAI = styled.div`
	border-radius: 2px;
	padding: 0px 5px;
	height: 22px;
	display: inline-flex;
	align-items: center;
	background-color: ${THEME.colors.darkBlue3};
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: ${THEME.colors.gray3};
	margin-right: 8px;
	margin-top: 8px;
`;

const StyledTextArea = styled(TextArea)`
	padding: 6px 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	color: ${THEME.colors.gray5};
	background: transparent;
	border: 1px solid ${THEME.colors.darkBlue4};

	&.textarea_content {
		&.ant-input-disabled {
			background: transparent !important;
		}
	}

	&::placeholder {
		color: ${THEME.colors.gray1};
	}
	&:-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}

	&::-ms-input-placeholder {
		color: ${THEME.colors.gray1};
	}
`;

const StyledLink = styled.a`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.blueBase};
	text-decoration-line: underline;
	&.disabled {
		pointer-events: none;
	}
`;

const StyledGalleryTag = styled.div`
	display: inline-flex;
	align-items: center;
	position: relative;
	background-color: ${THEME.colors.darkBlue3};
	border-radius: 6.41674px;
	max-width: 140px;
	padding: 4px;
	margin-right: 4px;
	.icon_theme_gallery {
		width: 32px;
		height: 32px;
	}
	.icon_close {
		position: absolute;
		top: 4px;
		right: 4px;
		cursor: pointer;
		svg {
			width: 12px;
			height: 12px;
		}
	}
`;

const StyledNameTag = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: ${THEME.colors.gray5};
	width: calc(100% - 48px);
	overflow: hidden;
	display: -webkit-box;
	margin-left: 4px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	min-width: 60px;
`;

const StyledDescription = styled.div`
	margin-bottom: 16px;
`;

const StyledSubDesc = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${THEME.colors.gray5};
	white-space: pre-line !important;
`;

const StyledCollapseInfo = styled.div<{ margin?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: ${({ margin }) => margin || '0 0 8px 0'};
`;

const StyledOptionInfo = styled.div`
	display: flex;
	margin-top: 10px;
	&:first-child {
		margin-top: 4px;
	}
`;

const StyledLabelInfo = styled.div`
	min-width: 118px;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.gray1};
	margin-right: 6px;
`;

const StyledInfoItem = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: ${THEME.colors.white};
`;

const StyledAdditionalInfo = styled.div`
	margin-bottom: 20px;
`;

const StyledWrapperPublishLog = styled.div`
	margin: 16px 0px;
`;

const StyledWrapperPublishList = styled.div`
	.ant-table {
		.ant-table-body {
			&::-webkit-scrollbar {
				width: 0px;
				height: 0px;
			}
			&:hover {
				&::-webkit-scrollbar-thumb {
					visibility: unset;
				}
				&::-webkit-scrollbar {
					width: 6px;
					height: 6px;
				}
			}

			&::-webkit-scrollbar-thumb {
				background-color: ${THEME.colors.gray2};
				border-radius: 20px;
				visibility: hidden;
				border: 3px solid ${THEME.colors.gray2};
			}
		}
	}
`;

const StyledContentFlags = styled.div`
	margin: 16px 0;
`;

const StyledContentFlagged = styled.div`
	margin: 46px 4px 0 4px;
`;

const StyledFlagged = styled.div`
	position: absolute;
	bottom: -32px;
	left: 4px;
	color: ${THEME.colors.white};
`;

const StyledButtonAnimation = styled(Button)<{
	isDisabled?: boolean;
	bgColor?: string;
	color?: string;
}>`
	&.ant-btn {
		border: 0;
		background-color: ${({ bgColor }) => bgColor || THEME.colors.yellow1};
		pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
		opacity: ${({ isDisabled }) => (isDisabled ? THEME.disabledOpacity : 1)};
		color: ${({ color }) => color || THEME.colors.white};
		&[ant-click-animating-without-extra-node='true']::after,
		&.ant-click-animating-node {
			opacity: 0.5;
			box-shadow: 0 0 0 0 ${THEME.colors.yellow1};
		}
	}
`;

export {
	StyledAdditionalInfo,
	StyledCollapse,
	StyledCollapseInfo,
	StyledContentFlagged,
	StyledContentFlags,
	StyledDescription,
	StyledFlagged,
	StyledGalleryTag,
	StyledInfoItem,
	StyledLabel,
	StyledLabelInfo,
	StyledLink,
	StyledName,
	StyledNameTag,
	StyledOptionInfo,
	StyledOverview,
	StyledPointScore,
	StyledSubDesc,
	StyledTagAI,
	StyledTextArea,
	StyledWrapperPublishList,
	StyledWrapperPublishLog,
	StyledWrapperTitle,
	SwapperSessionDetail,
	StyledButtonAnimation,
};
