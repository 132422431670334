import { OFFERS_REWARD } from '@constants/messages';
import {
	ListRuleRewardDto,
	ProductRequestDto,
	RewardContainerDto,
	RewardPayloadDto,
} from '@models/messages/summary';
import _ from 'lodash';

const getOfferList = (rewardOffers: any) => {
	const getReward = (type: string) => {
		let rewardTemp: any = {};

		if (rewardOffers.length) {
			rewardOffers.forEach((reward: any) => {
				if (reward.rewardType === type) rewardTemp = reward;
			});
		}

		return rewardTemp;
	};

	const convertRewardList = (rewardType: string) => {
		const reward = getReward(rewardType);
		let rewardListTemp: any = [];

		if (!_.isEmpty(reward)) {
			const { listRule, rewardType } = reward;

			rewardListTemp = listRule.map((rewardItem: any) => {
				return {
					label:
						rewardType === OFFERS_REWARD.GIFT_CARD.value
							? `$ ${rewardItem.estimatedCashValue}`
							: rewardItem.title,
					value: rewardItem.id,
				};
			});
		}

		return rewardListTemp;
	};

	const percentageList: ListRuleRewardDto[] = convertRewardList(OFFERS_REWARD.PERCENTAGE.value);
	const fixedList: ListRuleRewardDto[] = convertRewardList(OFFERS_REWARD.FIXED.value);
	const giftCardList: ListRuleRewardDto[] = convertRewardList(OFFERS_REWARD.GIFT_CARD.value);
	const productList: ListRuleRewardDto[] = convertRewardList(OFFERS_REWARD.PRODUCT.value);

	const offersList = [
		{
			value: OFFERS_REWARD.PERCENTAGE.value,
			label: OFFERS_REWARD.PERCENTAGE.label,
			disabled: percentageList.length === 0,
			children: percentageList,
		},
		{
			value: OFFERS_REWARD.FIXED.value,
			label: OFFERS_REWARD.FIXED.label,
			disabled: fixedList.length === 0,
			children: fixedList,
		},
		{
			value: OFFERS_REWARD.GIFT_CARD.value,
			label: OFFERS_REWARD.GIFT_CARD.label,
			disabled: giftCardList.length === 0,
			children: giftCardList,
		},
		{
			value: OFFERS_REWARD.PRODUCT.value,
			label: OFFERS_REWARD.PRODUCT.label,
			disabled: productList.length === 0,
			children: productList,
		},
	];

	return offersList;
};

const setRewardValues = (configureReward: RewardContainerDto, rewardList: RewardPayloadDto[]) => {
	const rewardsTemp: RewardPayloadDto[] = [];
	let { rewardValue } = configureReward;
	const { rewardType } = configureReward;
	let productRequest: ProductRequestDto | undefined | null = null;

	if (rewardType === OFFERS_REWARD.PRODUCT.value) {
		const { product } = rewardValue;
		productRequest = product;

		rewardValue = { ...rewardValue, codeValue: '' };
		delete rewardValue?.productDescription;
		delete rewardValue?.productUrl;
	}

	const {
		title,
		description,
		listRuleCode,
		allowedCreatorIds,
		allowedSegmentIds,
		excludedSegmentIds,
		excludedCreatorIds,
		product,
		...other
	} = rewardValue;

	rewardValue = {
		...other,
		productRequest,
		rewardType,
		ruleId: rewardValue.id,
		ruleTitle: rewardValue.title,
		ruleDescription: rewardValue.description,
	};

	rewardList.forEach((rewardEl: RewardPayloadDto) => {
		rewardsTemp.push({ ...rewardEl, ...rewardValue });
	});

	return rewardsTemp;
};

export { getOfferList, setRewardValues };
