import {
	IconExpandMore,
	IconGridActiveLayout,
	IconGridLayout,
	IconMosaicTheme,
	IconMosaicThemeActive,
	IconSignageTheme,
	IconSignageThemeActive,
	IconSliderActiveLayout,
	IconSliderLayout,
} from '@assets/icons';
import CustomPickerColor from '@components/CustomPickerColor';
import CustomSwitch from '@components/CustomSwitch';
import { urlGallerySupport } from '@config/index';
import { MAX_TEXTAREA } from '@constants/content/contentLibrary';
import {
	AUTOPLAY_SPEED_DEFAULT,
	COLOR_FIELD,
	CONTRAST_CHECKER,
	DEFAULT_UPLOADER_LINK,
	KEY_TURN_OFF,
	NAME_GROUP_HYPERLINK,
	OPTION_CALL_TO_ACTION,
	OPTION_DETAIL_HOVER,
	OPTION_LIGHTBOX,
	RANGE_COLUMN,
	TOTAL_ROW,
	TYPE_THEME,
} from '@constants/publish/gallery';
import THEME from '@constants/themes/themes';
import LoadingWrapper from '@cores/LoadingWrapper';
import { StateCampaign } from '@models/campaign';
import { WidgetLinkDto } from '@models/common/summary';
import { ContentRootStateDto } from '@models/content/contentLibrary/store';
import { ContentDto } from '@models/content/contentLibrary/summary';
import {
	ColorComponentDto,
	FolderGalleryDto,
	MetadataGalleryDto,
	OptionDto,
	OptionWidgetTag,
	UploaderLinkDto,
	ValueOptionWidget,
} from '@models/publishing/galleries';
import { FolderGalleryStoreDto } from '@models/publishing/galleries/stores';
import { updateDetailFolderGalleryRequest } from '@stores/actions';
import {
	StyledCol,
	StyledLinkHref,
	StyledSection,
	StyledText,
	StyledTitle,
	StyledTooltip,
} from '@styled/Common/CommonStyled';
import { StyledRow } from '@styled/Content/ContentLibrary/ContentStyled';
import { StyledSlider } from '@styled/Content/WidgetManager';
import {
	StyledBoxColor,
	StyledResultColor,
} from '@styled/Content/WidgetManager/WidgetBuilderStyled';
import { StyledRadioGroup } from '@styled/Publish/Gallery/GalleryManagerStyled';
import {
	StyleLabelForm,
	StyleNameGallery,
	StyledActionFooter,
	StyledCheckboxGroup,
	StyledContainerLabel,
	StyledContainerPreview,
	StyledCustomGallery,
	StyledCustomHyperLink,
	StyledCustomItem,
	StyledForm,
	StyledFormItem,
	StyledIframeUploader,
	StyledInput,
	StyledInputSpeed,
	StyledNameCustom,
	StyledNameTheme,
	StyledPreviewGalleryModal,
	StyledPreviewUploaderModal,
	StyledSelect,
	StyledSidebarPreview,
	StyledSubLabel,
	StyledTextArea,
	StyledTheme,
	StyledThemeItem,
	StyledTitleLabel,
	StyledWrapperLabelForm,
	StyledWrapperSelectBg,
	StyledWrapperUploadLink,
} from '@styled/Publish/Gallery/PreviewGalleryModalStyled';
import {
	colorContrastChecker,
	convertOptions,
	formatUrl,
	validateInputNumber,
} from '@utils/common';
import { Button, Checkbox, Form, Radio, Select, Space } from 'antd';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PreviewGallery from '../PreviewGallery';

const { OptGroup, Option } = Select;

type PropsPreviewGalleryModal = {
	[other: string]: any;
	contentList: ContentDto[];
	visible: boolean;
	detailGallery: FolderGalleryDto;
	onCancel: () => void;
	setListContent: (val: ContentDto[]) => void;
};

const keyForm = {
	text: 'text',
	widget: 'widget',
	rows: 'rows',
	navigationArrows: 'navigationArrows',
	autoplaySpeed: 'autoplaySpeed',
	message: 'message',
	isUploaderPopup: 'isUploaderPopup',
	galleryFilters: 'galleryFilters',
	customProperties: 'customProperties',
};

const PreviewGalleryModal = (props: PropsPreviewGalleryModal) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { contentList, visible, detailGallery, onCancel, setListContent, ...other } = props;

	const { fetchingContent } = useSelector((state: ContentRootStateDto) => state.content);
	const { clientSettings } = useSelector((state: any) => state.sidebar);
	const { campaignListContent = [] } = useSelector((state: StateCampaign) => state.campaign);
	const { customPropertiesList = [] }: FolderGalleryStoreDto = useSelector(
		(state: any) => state.galleryManager,
	);
	const { widgetLinks = [] } = clientSettings;

	const defaultWidget = useMemo(() => {
		return widgetLinks?.find((item: WidgetLinkDto) => item?.isDefault) || null;
	}, [widgetLinks]);

	const [form] = Form.useForm();

	const [visibleDetailHover, setVisibleDetailHover] = useState<boolean>(false);
	const [visibleLightBox, setVisibleLightBox] = useState<boolean>(false);
	const [visibleUploadLink, setVisibleUploadLink] = useState<boolean>(false);
	const [visibleAutoScroll, setVisibleAutoScroll] = useState<boolean>(false);
	const [visibleAutoVideo, setVisibleAutoVideo] = useState<boolean>(false);
	const [visibleBg, setVisibleBg] = useState<boolean>(false);
	const [visibleGalleryFilters, setVisibleGalleryFilters] = useState<boolean>(false);

	const [detailGalleryChange, setDetailGalleryChange] = useState<FolderGalleryDto>(detailGallery);
	const [isReset, setIsReset] = useState<boolean>(false);
	const [openUploader, setOpenUploader] = useState<boolean>(false);

	const { theme = 'grid', videoAutoPlay: videoAutoPlayChange } = detailGalleryChange?.metadata;

	const listTheme = [
		{
			name: t('gallery_manager.modal.grid'),
			icon:
				!theme || theme === TYPE_THEME.GRID ? <IconGridActiveLayout /> : <IconGridLayout />,
			type: TYPE_THEME.GRID,
		},
		{
			name: t('gallery_manager.modal.slider'),
			icon: theme === TYPE_THEME.SLIDER ? <IconSliderActiveLayout /> : <IconSliderLayout />,
			type: TYPE_THEME.SLIDER,
		},
		{
			name: t('gallery_manager.modal.mosaic'),
			icon: theme === TYPE_THEME.MOSAIC ? <IconMosaicThemeActive /> : <IconMosaicTheme />,
			type: TYPE_THEME.MOSAIC,
		},
		{
			name: t('gallery_manager.modal.signage'),
			icon: theme === TYPE_THEME.SIGNAGE ? <IconSignageThemeActive /> : <IconSignageTheme />,
			type: TYPE_THEME.SIGNAGE,
		},
	];

	const optionDetailHover: OptionDto[] = [
		{
			label: t('gallery_manager.modal.instagram_name'),
			value: OPTION_DETAIL_HOVER.showInstagramName,
		},
		{
			label: t('gallery_manager.modal.twitter_name'),
			value: OPTION_DETAIL_HOVER.showTwitterName,
		},
	];

	const optionLightBox: OptionDto[] = [
		{
			label: t('gallery_manager.modal.instagram_username'),
			value: OPTION_LIGHTBOX.showInstagramUsername,
		},
		{
			label: t('gallery_manager.modal.twitter_username'),
			value: OPTION_LIGHTBOX.showTwitterUsername,
		},
		{
			label: t('gallery_manager.modal.custom_fields'),
			value: OPTION_LIGHTBOX.customFields,
		},
		{
			label: t('gallery_manager.modal.formatted_text_block'),
			value: OPTION_LIGHTBOX.customDetails,
		},
		{
			label: t('gallery_manager.modal.caption'),
			value: OPTION_LIGHTBOX.showCaption,
		},
		{
			label: t('gallery_manager.modal.first_name_last_initial'),
			value: OPTION_LIGHTBOX.showFirstNameLastInitial,
		},
	];

	const optionCallToAction: OptionDto[] = [
		{
			label: t('gallery_manager.modal.add_a_message'),
			value: OPTION_CALL_TO_ACTION.message,
		},
		{
			label: t('gallery_manager.modal.add_a_QR_code'),
			value: OPTION_CALL_TO_ACTION.QRCode,
		},
	];

	const renderValueFormWidget = (
		idWG: number | null | undefined,
		idCP?: number | null | string,
	) => {
		return JSON.stringify({ idWG, idCP: idCP || '' });
	};

	const findUrlWidget = (widgetId?: number | null, campaignId?: number | null | string) => {
		const widgetLinkCampaign = widgetLinks.find(
			(widgetLink: any) => widgetLink.id === widgetId,
		)?.url;
		if (campaignId) {
			return renderWidgetWidthCampaign(widgetLinkCampaign, campaignId);
		}
		return widgetLinkCampaign || '';
	};

	const renderWidgetWidthCampaign = (link: string, id: number | string) => {
		if (!link || !id) {
			return '';
		}
		return link?.indexOf('?') >= 0 ? `${link}&c=${id}` : `${link}?c=${id}`;
	};

	const renderListOption = (options: Array<any>, isCampaign?: boolean) => {
		return options?.map((item) => {
			if (isCampaign) {
				return { name: item?.name, idWG: item?.widgetId, idCP: item.id };
			}
			return { name: item?.name, idWG: item?.id, idCP: '' };
		});
	};

	const listOptionHyperlink: OptionWidgetTag[] = [
		{
			nameGroup: NAME_GROUP_HYPERLINK.WIDGET,
			options: renderListOption(widgetLinks || []),
			label: t('gallery_manager.modal.general_links'),
		},
		{
			nameGroup: NAME_GROUP_HYPERLINK.CAMPAIGN,
			options: renderListOption(campaignListContent || [], true),
			label: t('gallery_manager.modal.campaign_links'),
		},
	];

	useEffect(() => {
		if (visible) {
			handleDefaultForm();
			handleChangeType();
		}
	}, [detailGallery, visible]);

	useEffect(() => {
		if (visible) {
			setIsReset(true);
		} else {
			setIsReset(false);
		}
	}, [visible]);

	const handleChangeType = () => {
		const { metadata } = detailGallery;
		const {
			uploaderLink = null,
			showDetailLightBox,
			showDetailOnHover,
			autoScroll,
			videoAutoPlay,
			visibleBg: visibleBgBefore,
			galleryFilters,
		} = metadata;

		setVisibleLightBox(!!showDetailLightBox);
		setVisibleDetailHover(!!showDetailOnHover);
		setVisibleUploadLink(!!uploaderLink);
		setVisibleAutoScroll(!!autoScroll);
		setVisibleBg(!!visibleBgBefore);
		setVisibleGalleryFilters(!!galleryFilters);
		setVisibleAutoVideo(videoAutoPlay || videoAutoPlay === undefined);
	};

	const handleDefaultForm = () => {
		const { metadata } = detailGallery;
		const {
			uploaderLink = null,
			rows,
			showDetailLightBox,
			showDetailOnHover,
			autoScroll,
			autoplaySpeed,
			navigationArrows,
			isUploaderPopup,
			galleryFilters = null,
		} = metadata || {};

		const params = renderParamChange();
		let detailGalleryTemp: any = {
			...detailGallery,
		};
		const otherParams: any = {
			showDetailLightBox: showDetailLightBox || null,
			showDetailOnHover: showDetailOnHover || null,
			uploaderLink: uploaderLink?.options || [],
			autoScroll: autoScroll || false,
			autoplaySpeed: autoplaySpeed || AUTOPLAY_SPEED_DEFAULT,
			navigationArrows: navigationArrows || false,
			isUploaderPopup: isUploaderPopup || false,
		};

		if (theme === TYPE_THEME.GRID || rows) {
			otherParams.rows = rows || 2;
		}

		if (uploaderLink) {
			detailGalleryTemp = {
				...detailGalleryTemp,
				metadata: {
					...detailGalleryTemp.metadata,
					uploaderLink: {
						...detailGalleryTemp?.metadata.uploaderLink,
						...params,
					},
				},
			};
		}

		if (autoScroll) {
			detailGalleryTemp = {
				...detailGalleryTemp,
				metadata: {
					...detailGalleryTemp.metadata,
					autoplaySpeed: autoplaySpeed || AUTOPLAY_SPEED_DEFAULT,
					navigationArrows: navigationArrows || false,
				},
			};
		}

		if (galleryFilters?.customProperties) {
			otherParams[keyForm.customProperties] =
				galleryFilters?.customProperties || customPropertiesList;
		}

		form.setFieldsValue({
			text: params.text,
			widget: renderValueFormWidget(params.widgetId, params.campaignId),
			message: params.message,
			...otherParams,
		});

		setDetailGalleryChange({ ...detailGalleryTemp });
	};

	const ctaColor = useMemo(() => {
		return {
			[COLOR_FIELD.CTA_BACKGROUND]:
				detailGalleryChange?.metadata[COLOR_FIELD.CTA_BACKGROUND] || THEME.colors.white,
			[COLOR_FIELD.FONT_QR_CODE_COLOR]:
				detailGalleryChange?.metadata[COLOR_FIELD.FONT_QR_CODE_COLOR] || THEME.colors.black,
			[COLOR_FIELD.IS_FAILED_CONTRAST]:
				detailGalleryChange?.metadata[COLOR_FIELD.IS_FAILED_CONTRAST] || false,
		};
	}, [detailGalleryChange]);

	const resetConfigurationSignageTheme = (metadata: MetadataGalleryDto) => {
		let fieldList = Object.values(COLOR_FIELD);
		fieldList = fieldList.filter((item: string) => item !== COLOR_FIELD.GALLERY_BACKGROUND);

		fieldList.forEach((item: string) => {
			delete metadata[item];
		});
	};

	const renderParamChange = () => {
		const { metadata } = detailGallery;
		const { uploaderLink = null } = metadata;
		const params: UploaderLinkDto = {
			text: DEFAULT_UPLOADER_LINK.name,
			widgetId: defaultWidget?.id || null,
			url: defaultWidget?.url || '',
			campaignId: '',
		};
		if (theme === TYPE_THEME.SIGNAGE) {
			// Init value when opening gallery details
			const message = metadata?.uploaderLink?.message;
			params.message = message;
		}
		if (uploaderLink) {
			const { text = '', widgetId = null, campaignId = '' } = uploaderLink;
			params.text = text || params.text;
			params.widgetId = widgetId ? +widgetId : params.widgetId;
			params.campaignId = campaignId;
			params.url = findUrlWidget(params.widgetId, campaignId);
		}
		return params;
	};

	const onValuesChange = (val: any) => {
		const key = Object.keys(val)[0];
		const params: any = {};

		if (key === keyForm.widget) {
			const obj: ValueOptionWidget = JSON.parse(val[key]);

			params.widgetId = obj.idWG;
			params.campaignId = obj.idCP;
			params.url = findUrlWidget(obj.idWG, obj.idCP);
		}
		if (key === keyForm.rows) {
			if (val[key] === 0) {
				form.setFieldsValue({ [key]: RANGE_COLUMN.min });
				params[key] = RANGE_COLUMN.min;
			}
		}
		let metadataChange: MetadataGalleryDto = { ...detailGalleryChange?.metadata };

		if (key === keyForm.text || key === keyForm.widget || key === keyForm.message) {
			const { widget, ...other } = val;
			metadataChange.uploaderLink = {
				...metadataChange.uploaderLink,
				...other,
				...params,
			};
		} else if (key === KEY_TURN_OFF.uploaderLink) {
			if (val[key].includes(OPTION_CALL_TO_ACTION.QRCode)) {
				const { url = defaultWidget?.url, widgetId = defaultWidget?.id } =
					metadataChange.uploaderLink || {};
				params.url = url;
				params.widgetId = widgetId;
				form.setFieldsValue({
					widget: renderValueFormWidget(
						widgetId,
						detailGallery?.metadata?.uploaderLink?.campaignId,
					),
				});
			}

			if (val[key].includes(OPTION_CALL_TO_ACTION.message)) {
				const message =
					metadataChange.uploaderLink?.message ||
					t('gallery_manager.modal.call_to_action_message');
				metadataChange.uploaderLink = {
					...metadataChange.uploaderLink,
					message,
				};
				form.setFieldsValue({ message });
			}

			metadataChange.uploaderLink = {
				...metadataChange.uploaderLink,
				...params,
				options: [...val[key]],
			};
		} else if (key === KEY_TURN_OFF.customProperties) {
			const galleryFilters = {
				customProperties: val[key],
			};
			metadataChange = { ...metadataChange, ...params, galleryFilters };
		} else {
			metadataChange = { ...metadataChange, ...val, ...params };
		}

		handleChangeMetadata(metadataChange);
	};

	const onChangeTheme = (type: string) => {
		let metadataChange: any = { ...detailGalleryChange?.metadata, theme: type };
		if (type !== TYPE_THEME.SIGNAGE) {
			const callToActionMsg =
				metadataChange?.uploaderLink?.text || DEFAULT_UPLOADER_LINK.name;
			metadataChange = {
				...metadataChange,
				uploaderLink: {
					...metadataChange?.uploaderLink,
					text: callToActionMsg,
					options: [],
					message: null,
				},
			};

			form.setFieldsValue({
				uploaderLink: [],
				text: callToActionMsg,
			});
		}

		handleChangeMetadata(metadataChange);
	};

	const onChangeTypeUploader = (show: boolean) => {
		const params = renderParamChange();
		setVisibleUploadLink(show);
		let valChange: UploaderLinkDto | null = null;
		if (show) {
			valChange = { ...detailGalleryChange?.metadata?.uploaderLink, ...params };
		}

		const metadataChange = { ...detailGalleryChange?.metadata, uploaderLink: valChange };
		handleChangeMetadata(metadataChange);
		form.setFieldsValue({
			text: params.text,
			widget: renderValueFormWidget(params.widgetId, params.campaignId),
		});
	};

	const onClearForm = () => {
		form.resetFields();
		onCancel();
		setVisibleAutoScroll(false);
		setVisibleDetailHover(false);
		setVisibleLightBox(false);
		setVisibleBg(false);
		setVisibleUploadLink(false);
		setVisibleAutoVideo(false);
		setVisibleGalleryFilters(false);
	};

	const handleFinishForm = () => {
		const { reorder, ...otherDetail } = detailGalleryChange || {};

		if (theme !== TYPE_THEME.SIGNAGE) {
			resetConfigurationSignageTheme(detailGalleryChange?.metadata);
		}
		handleRemoveCustomProperties();
		dispatch(
			updateDetailFolderGalleryRequest({
				params: {
					...otherDetail,
					metadata: JSON.stringify(detailGalleryChange?.metadata),
				},
			}),
		);
		onClearForm();
	};

	// Remove custom properties changed name and not existing in the returned list of custom properties
	const handleRemoveCustomProperties = () => {
		const { metadata } = detailGalleryChange;
		const { galleryFilters } = metadata;

		if (
			galleryFilters &&
			galleryFilters?.customProperties &&
			galleryFilters?.customProperties.length
		) {
			galleryFilters.customProperties = galleryFilters[keyForm.customProperties].filter(
				(item: string) =>
					customPropertiesList.some((returnedProp: string) => item === returnedProp),
			);
		}
	};

	const onSaveChange = () => {
		if (visibleUploadLink) {
			return form.submit();
		}

		return handleFinishForm();
	};

	const onToggleShow = (val: boolean, key: string, callback: (val: boolean) => void) => {
		callback(val);
		const { metadata } = detailGallery;
		let valChange: any = null;

		if (val) {
			valChange = metadata[key] || [];
		}

		let metadataChange = { ...detailGalleryChange?.metadata, [key]: valChange };

		if (key === KEY_TURN_OFF.uploaderLink) {
			const params = renderParamChange();
			form.setFieldsValue({
				text: params.text,
				widget: renderValueFormWidget(params.widgetId, params.campaignId),
			});
		} else if (key === keyForm.galleryFilters) {
			const changedFilters = val
				? {
						[keyForm.customProperties]: customPropertiesList,
				  }
				: null;
			metadataChange = {
				...metadataChange,
				[keyForm.galleryFilters]: changedFilters,
			};
			form.setFieldsValue({
				[keyForm.customProperties]: changedFilters ? customPropertiesList : [],
			});
		} else {
			form.setFieldsValue({ [key]: valChange });
		}

		handleChangeMetadata(metadataChange);
	};

	const onToggleScroll = (val: boolean) => {
		let metadataChange: any = {
			...detailGalleryChange?.metadata,
			autoScroll: val,
		};

		if (!val) {
			const defaultValues = {
				navigationArrows: false,
				autoplaySpeed: AUTOPLAY_SPEED_DEFAULT,
			};

			form.setFieldsValue({ ...defaultValues });
			metadataChange = { ...metadataChange, ...defaultValues };
		}

		setVisibleAutoScroll(val);
		handleChangeMetadata(metadataChange);
	};

	const onToggleVideoAutoPlay = (val: boolean) => {
		setVisibleAutoVideo(val);
		const metadataChange = { ...detailGalleryChange?.metadata, videoAutoPlay: val };
		handleChangeMetadata(metadataChange);
	};

	const onToggleBg = (val: boolean) => {
		setVisibleBg(val);
		const metadataChange = { ...detailGalleryChange?.metadata, visibleBg: val };
		handleChangeMetadata(metadataChange);
	};

	const handleChangeMetadata = (metadataChange: MetadataGalleryDto) => {
		setDetailGalleryChange((prev) => ({
			...prev,
			metadata: metadataChange,
		}));
	};

	const notFoundContent = () => {
		return (
			<div className="select-empty">
				<span className="select-empty-text">{t('common.no_data')}</span>
			</div>
		);
	};

	const renderAdjust = () => {
		if (theme && theme !== TYPE_THEME.GRID) {
			return null;
		}
		return (
			<StyledFormItem
				name={keyForm.rows}
				className="custom_form_preview"
				label={<StyledTitleLabel>{t('gallery_manager.modal.rows')}</StyledTitleLabel>}>
				<StyledSlider
					className="slider_row"
					min={0}
					defaultValue={RANGE_COLUMN.default}
					getTooltipPopupContainer={(triggerNode) =>
						triggerNode.parentNode as HTMLElement
					}
					max={RANGE_COLUMN.max}
					tipFormatter={(val) => val}
					tooltipVisible
				/>
			</StyledFormItem>
		);
	};

	const renderSignageConfiguration = () => {
		const colorComponents: ColorComponentDto[] = [
			{
				label: t('gallery_manager.modal.cta_background'),
				color: ctaColor[COLOR_FIELD.CTA_BACKGROUND],
				fieldName: COLOR_FIELD.CTA_BACKGROUND,
			},
			{
				label: t('gallery_manager.modal.font_qr_code'),
				color: ctaColor[COLOR_FIELD.FONT_QR_CODE_COLOR],
				fieldName: COLOR_FIELD.FONT_QR_CODE_COLOR,
			},
		];

		return (
			<StyledSection margin="0 0 20px 0">
				<StyledText className="italic" fontSize="12px" margin="0 0 8px 0">
					{ctaColor[COLOR_FIELD.IS_FAILED_CONTRAST] &&
						t('gallery_manager.modal.contrast_checker_message')}
				</StyledText>
				{colorComponents.map((item: ColorComponentDto) => {
					const { label, color, fieldName } = item;

					return (
						<StyledRow key={label} justify="space-between" margin="0 0 8px 0">
							<StyledCol>
								<StyledText color={THEME.colors.gray1} fontSize="12px">
									{label}
								</StyledText>
								<StyledText>{color}</StyledText>
							</StyledCol>
							<StyledCol>
								<CustomPickerColor
									onChange={(val: string) => onChangeColor(val, fieldName)}
									color={color}
									placement={'top'}>
									<StyledBoxColor>
										<StyledResultColor bgColor={color} />
										<IconExpandMore />
									</StyledBoxColor>
								</CustomPickerColor>
							</StyledCol>
						</StyledRow>
					);
				})}
			</StyledSection>
		);
	};

	const renderCustomCheckbox = () => {
		return (
			<Checkbox.Group>
				{theme === TYPE_THEME.SIGNAGE && renderSignageConfiguration()}
				<StyledRow>
					<StyledCol margin="0 0 4px 0" span={24}>
						<Checkbox
							key={OPTION_CALL_TO_ACTION.message}
							value={OPTION_CALL_TO_ACTION.message}>
							{t('gallery_manager.modal.add_a_message')}
						</Checkbox>
					</StyledCol>
					<StyledCol margin="0 0 4px 0" span={24}>
						{detailGalleryChange.metadata.uploaderLink?.options &&
							detailGalleryChange.metadata.uploaderLink?.options.includes(
								OPTION_CALL_TO_ACTION.message,
							) && (
								<StyledFormItem
									rules={[
										{
											required: true,
											message: t('gallery_manager.required'),
										},
									]}
									className="custom_form_preview"
									name={keyForm.message}
									label={
										<StyleLabelForm>
											{t('gallery_manager.modal.displayed_text')}
										</StyleLabelForm>
									}>
									<StyledTextArea
										rows={3}
										placeholder={t('gallery_manager.input_field_name')}
										maxLength={MAX_TEXTAREA.MESSAGE_SIGNAGE}
									/>
								</StyledFormItem>
							)}
					</StyledCol>
					<StyledCol margin="0 0 4px 0" span={24}>
						<Checkbox
							key={OPTION_CALL_TO_ACTION.QRCode}
							value={OPTION_CALL_TO_ACTION.QRCode}>
							{t('gallery_manager.modal.add_a_QR_code')}
						</Checkbox>
					</StyledCol>
					{detailGalleryChange.metadata.uploaderLink?.options &&
						detailGalleryChange.metadata.uploaderLink?.options.includes(
							OPTION_CALL_TO_ACTION.QRCode,
						) && <StyledCol span={24}>{renderWidgetHyperlink()}</StyledCol>}
				</StyledRow>
			</Checkbox.Group>
		);
	};

	const renderCallToAction = (options: OptionDto[], name: string) => {
		let className = 'custom_checkbox ';

		if (name === KEY_TURN_OFF.customProperties) {
			className += 'overflow';
		}

		if (name === KEY_TURN_OFF.uploaderLink) {
			return renderCustomCheckbox();
		}

		return <StyledCheckboxGroup className={className} options={options} />;
	};

	const renderListCheckbox = (
		options: OptionDto[],
		name: string,
		label: string | ReactNode,
		objMore: { checked: boolean; func: (val: boolean) => void },
	) => {
		const { checked, func } = objMore;

		return (
			<>
				<StyledFormItem
					name={name}
					className="custom_form_preview"
					label={
						<StyledContainerLabel>
							<StyledWrapperLabelForm marginBottom={checked ? '16px' : '0'}>
								<StyledTitleLabel>{label}</StyledTitleLabel>
								<CustomSwitch checked={checked} setChecked={func} />
							</StyledWrapperLabelForm>
							{name === KEY_TURN_OFF.showDetailLightBox && checked && (
								<StyledSubLabel>
									{t('gallery_manager.modal.show_detail_lightbox')}
								</StyledSubLabel>
							)}
						</StyledContainerLabel>
					}>
					{checked ? renderCallToAction(options, name) : null}
				</StyledFormItem>
				{name === KEY_TURN_OFF.customProperties && checked && (
					<StyledTooltip
						placement="rightBottom"
						overlayInnerStyle={{ width: '400px' }}
						title={() => (
							<StyledText
								dangerouslySetInnerHTML={{
									__html: t('gallery_manager.modal.gallery_filters_desc'),
								}}
							/>
						)}>
						<StyleLabelForm margin="12px 0">
							{t('gallery_manager.modal.more_filters')}
						</StyleLabelForm>
					</StyledTooltip>
				)}
			</>
		);
	};

	const renderWidgetHyperlink = () => {
		return (
			<StyledFormItem
				rules={[
					{
						required: true,
						message: t('gallery_manager.required'),
					},
				]}
				className="custom_form_preview"
				name={keyForm.widget}
				label={<StyleLabelForm>{t('gallery_manager.modal.hyperlink_to')}</StyleLabelForm>}>
				<StyledSelect
					width="100%"
					margin={'6px 0 0 0'}
					placeholder={t('gallery_manager.placeholder_select')}
					notFoundContent={notFoundContent()}
					getPopupContainer={(triggerNode: HTMLElement) =>
						triggerNode.parentNode as HTMLElement
					}
					virtual={false}
					optionFilterProp={'children'}
					dropdownClassName={'dropdown-menu'}>
					{listOptionHyperlink?.map((item) => (
						<OptGroup key={item?.nameGroup} label={item?.label}>
							{item?.options?.map((option) => {
								const val = renderValueFormWidget(option.idWG, option.idCP);
								return (
									<Option key={val} value={val}>
										{option?.name}
									</Option>
								);
							})}
						</OptGroup>
					))}
				</StyledSelect>
			</StyledFormItem>
		);
	};

	const renderCustomHyperLink = () => {
		return (
			<StyledCustomHyperLink>
				<StyledFormItem
					rules={[
						{
							required: true,
							message: t('gallery_manager.required'),
						},
					]}
					className="custom_form_preview"
					name={keyForm.text}
					label={
						<StyleLabelForm>{t('gallery_manager.modal.displayed_text')}</StyleLabelForm>
					}>
					<StyledInput placeholder={t('gallery_manager.input_field_name')} />
				</StyledFormItem>

				<StyledFormItem name={keyForm.isUploaderPopup}>
					<StyledRadioGroup defaultValue={false}>
						<Space direction="vertical">
							<Radio value={true}>{t('gallery_manager.modal.uploader_popup')}</Radio>
							<Radio value={false}>
								{t('gallery_manager.modal.uploader_new_tab')}
							</Radio>
						</Space>
					</StyledRadioGroup>
				</StyledFormItem>
				{detailGalleryChange?.metadata?.isUploaderPopup && (
					<StyledText
						color={THEME.colors.gray5}
						lineHeight="16px"
						margin="0 0 8px 0"
						fontSize="12px"
						className="italic">
						{t('gallery_manager.modal.sub_info_uploader')}
						<StyledLinkHref
							color={THEME.colors.blueBase}
							margin="0 0 0 4px"
							hoverColor={THEME.colors.orangeBase}
							href={urlGallerySupport}
							target="_blank"
							fontWeight="normal">
							{urlGallerySupport}
						</StyledLinkHref>
					</StyledText>
				)}
				{renderWidgetHyperlink()}
			</StyledCustomHyperLink>
		);
	};

	const listCustomFields = [
		{
			value: 'Products Used',
			label: 'Products Used',
		},
		{
			value: 'Products Depicted',
			label: 'Products Depicted',
		},
	];

	const renderCustomTurnoff = (
		checked: boolean,
		setChecked: (val: boolean) => void,
		name: string,
	) => {
		const isBg = name === KEY_TURN_OFF.showBg;
		let label =
			name === KEY_TURN_OFF.uploaderLink
				? t('gallery_manager.modal.uploader_link')
				: t('gallery_manager.modal.auto_scroll');
		if (name === KEY_TURN_OFF.videoAutoPlay) {
			label = t('gallery_manager.modal.video_auto_play');
		}
		if (name === KEY_TURN_OFF.showBg) {
			label = t('gallery_manager.modal.gallery_bg');
		}
		return (
			<StyledWrapperUploadLink style={isBg ? { marginTop: '24px' } : {}}>
				<StyledWrapperLabelForm marginBottom={checked && !isBg ? '16px' : '0'}>
					<StyledTitleLabel style={isBg ? { fontSize: '14px' } : {}}>
						{label}
					</StyledTitleLabel>
					<CustomSwitch checked={checked} setChecked={setChecked} />
				</StyledWrapperLabelForm>
				{name === KEY_TURN_OFF.showBg && checked && renderSelectBg()}
				{name === KEY_TURN_OFF.autoScroll && checked && renderAutoScroll()}
				{name === KEY_TURN_OFF.uploaderLink && checked && renderCustomHyperLink()}
			</StyledWrapperUploadLink>
		);
	};

	const contrastChecker = async (fontColor: string, backgroundColor: string) => {
		const res = await colorContrastChecker(fontColor, backgroundColor);
		const isFailed =
			res && res[CONTRAST_CHECKER.FIELD.AAA_LARGE] === CONTRAST_CHECKER.VALUE.FAIL;
		return isFailed;
	};

	const onChangeColor = async (val: string, fieldName: string) => {
		let metadataChangeTemp = { ...detailGalleryChange?.metadata, [fieldName]: val };

		if (theme === TYPE_THEME.SIGNAGE) {
			let isFailedContrast = false;
			if (fieldName === COLOR_FIELD.FONT_QR_CODE_COLOR) {
				isFailedContrast = await contrastChecker(val, ctaColor.ctaBackground);
			}
			if (fieldName === COLOR_FIELD.CTA_BACKGROUND) {
				isFailedContrast = await contrastChecker(ctaColor.fontQRCodeColor, val);
			}
			metadataChangeTemp = { ...metadataChangeTemp, isFailedContrast };
		}

		handleChangeMetadata(metadataChangeTemp);
	};

	const renderSelectBg = () => {
		const color = detailGalleryChange?.metadata?.galleryBackground || THEME.colors.white;
		return (
			<StyledWrapperSelectBg>
				<StyledTitle>{color}</StyledTitle>
				<CustomPickerColor
					onChange={(val: string) => onChangeColor(val, COLOR_FIELD.GALLERY_BACKGROUND)}
					color={color}
					placement={'top'}>
					<StyledBoxColor>
						<StyledResultColor bgColor={color} />
						<IconExpandMore />
					</StyledBoxColor>
				</CustomPickerColor>
			</StyledWrapperSelectBg>
		);
	};

	const renderAutoScroll = () => {
		return (
			<StyledCustomHyperLink>
				<StyledFormItem
					name={keyForm.navigationArrows}
					label={
						<StyleLabelForm>
							{t('gallery_manager.modal.navigation_arrows')}
						</StyleLabelForm>
					}>
					<StyledRadioGroup defaultValue={false}>
						<Radio value={true}>{t('gallery_manager.modal.show_arrows')}</Radio>
						<Radio value={false}>{t('gallery_manager.modal.hide_arrows')}</Radio>
					</StyledRadioGroup>
				</StyledFormItem>
				<StyledFormItem
					name={keyForm.autoplaySpeed}
					label={
						<StyleLabelForm>
							{t('gallery_manager.modal.auto_scroll_time')}
						</StyleLabelForm>
					}>
					<StyledInputSpeed
						onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
							validateInputNumber(event, true)
						}
						defaultValue={AUTOPLAY_SPEED_DEFAULT}
						min={0}
					/>
				</StyledFormItem>
			</StyledCustomHyperLink>
		);
	};

	const listCustomRender = [
		{
			id: 1,
			content: renderAdjust(),
		},
		{
			id: KEY_TURN_OFF.showDetailOnHover,
			content: renderListCheckbox(
				optionDetailHover,
				KEY_TURN_OFF.showDetailOnHover,
				t('gallery_manager.modal.show_detail_hover'),
				{
					checked: visibleDetailHover,
					func: (val: boolean) =>
						onToggleShow(val, KEY_TURN_OFF.showDetailOnHover, setVisibleDetailHover),
				},
			),
		},
		{
			id: KEY_TURN_OFF.showDetailLightBox,
			content: renderListCheckbox(
				optionLightBox,
				KEY_TURN_OFF.showDetailLightBox,
				t('gallery_manager.modal.lightbox'),
				{
					checked: visibleLightBox,
					func: (val: boolean) =>
						onToggleShow(val, KEY_TURN_OFF.showDetailLightBox, setVisibleLightBox),
				},
			),
		},
		{
			id: KEY_TURN_OFF.autoScroll,
			content: renderCustomTurnoff(
				visibleAutoScroll,
				onToggleScroll,
				KEY_TURN_OFF.autoScroll,
			),
		},
		{
			id: KEY_TURN_OFF.galleryFilters,
			content: renderListCheckbox(
				convertOptions(customPropertiesList, KEY_TURN_OFF.customProperties),
				KEY_TURN_OFF.customProperties,
				t('gallery_manager.modal.gallery_filters'),
				{
					checked: visibleGalleryFilters,
					func: (val: boolean) =>
						onToggleShow(val, KEY_TURN_OFF.galleryFilters, setVisibleGalleryFilters),
				},
			),
		},
		{
			id: KEY_TURN_OFF.uploaderLink,
			content:
				theme === TYPE_THEME.SIGNAGE
					? renderListCheckbox(
							optionCallToAction,
							KEY_TURN_OFF.uploaderLink,
							t('gallery_manager.modal.uploader_link'),
							{
								checked: visibleUploadLink,
								func: (val: boolean) =>
									onToggleShow(
										val,
										KEY_TURN_OFF.uploaderLink,
										setVisibleUploadLink,
									),
							},
					  )
					: renderCustomTurnoff(
							visibleUploadLink,
							onChangeTypeUploader,
							KEY_TURN_OFF.uploaderLink,
					  ),
		},
		{
			id: KEY_TURN_OFF.videoAutoPlay,
			content: renderCustomTurnoff(
				visibleAutoVideo,
				onToggleVideoAutoPlay,
				KEY_TURN_OFF.videoAutoPlay,
			),
		},
		{
			id: KEY_TURN_OFF.showBg,
			content: renderCustomTurnoff(visibleBg, onToggleBg, KEY_TURN_OFF.showBg),
		},
	];

	return (
		<StyledPreviewGalleryModal
			footer={null}
			zIndex={1010}
			visible={visible}
			onCancel={onClearForm}
			width={'calc(100% - 200px)'}
			{...other}>
			{openUploader && (
				<StyledPreviewUploaderModal
					visible={openUploader && !!detailGalleryChange?.metadata?.uploaderLink}
					zIndex={2023}
					width={800}
					footer={null}
					centered
					onCancel={() => setOpenUploader(false)}>
					<StyledIframeUploader
						src={formatUrl(detailGalleryChange?.metadata?.uploaderLink?.url)}
					/>
				</StyledPreviewUploaderModal>
			)}
			<StyledSidebarPreview>
				<StyleNameGallery>{detailGallery?.name}</StyleNameGallery>
				<StyledForm
					className="custom_form_preview"
					form={form}
					initialValues={{
						[keyForm.text]: '',
						[keyForm.widget]: null,
					}}
					onValuesChange={onValuesChange}
					onFinish={handleFinishForm}>
					<StyledCustomGallery>
						<StyledCustomItem margin="0 0 20px 0">
							<StyledNameCustom>
								{t('gallery_manager.modal.choose_theme')}
							</StyledNameCustom>
							<StyledTheme>
								{listTheme?.map((item) => (
									<StyledThemeItem
										onClick={() => onChangeTheme(item?.type)}
										key={Math.random()}>
										{item?.icon}
										<StyledNameTheme>{item?.name}</StyledNameTheme>
									</StyledThemeItem>
								))}
							</StyledTheme>
						</StyledCustomItem>

						{listCustomRender?.map((item) => item?.content)}
					</StyledCustomGallery>
				</StyledForm>
				<StyledActionFooter>
					<Button onClick={onClearForm}>{t('gallery_manager.button.cancel')}</Button>
					<Button
						onClick={() => onSaveChange()}
						style={{ marginLeft: '8px' }}
						type="primary">
						{t('gallery_manager.button.publish_change')}
					</Button>
				</StyledActionFooter>
			</StyledSidebarPreview>
			<StyledContainerPreview>
				<LoadingWrapper isLoading={fetchingContent}>
					<PreviewGallery
						theme={theme}
						isReset={isReset}
						uploaderLink={detailGalleryChange?.metadata?.uploaderLink || null}
						rows={detailGalleryChange?.metadata?.rows || TOTAL_ROW.GRID}
						listGallery={contentList}
						showDetailOnHover={detailGalleryChange?.metadata?.showDetailOnHover}
						showDetailLightBox={detailGalleryChange?.metadata?.showDetailLightBox}
						autoScroll={detailGalleryChange?.metadata?.autoScroll || false}
						setListContent={setListContent}
						metadata={detailGalleryChange?.metadata}
						isGif={videoAutoPlayChange || videoAutoPlayChange === undefined}
						setOpenUploader={setOpenUploader}
					/>
				</LoadingWrapper>
			</StyledContainerPreview>
		</StyledPreviewGalleryModal>
	);
};

export default PreviewGalleryModal;
